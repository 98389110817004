import {
  type AccountingCategoriesDto,
  type AccountCategoryDto,
  type AccountingCategoriesModel,
  type CategoriesByTypes
} from 'api/types/responses'

const parseAccountingCategoriesDto = (
  data: AccountingCategoriesDto
): AccountingCategoriesModel => {
  return {
    ...data,
    groupedByTypes: groupCategoriesByType(data.items)
  }
}

export const groupCategoriesByType = (
  categories: AccountCategoryDto[]
): CategoriesByTypes => {
  return categories.reduce((items: CategoriesByTypes, { accountTypeId, ...listItem }) => {
    const typeItems = items[accountTypeId]
    items[accountTypeId] = typeItems != null ? [...typeItems, listItem] : [listItem]

    return items
  }, {})
}

export default parseAccountingCategoriesDto
