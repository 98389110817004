import { css } from '@emotion/css'

export default css(`  
  && {
    display: flex;
    flex-direction: column;
      
    .content {
      width: 100%;
      min-width: 200px;
      display: table; // for responsive ellipsis
      table-layout: fixed;
      white-space: nowrap;
    }

    p {
      display: table-cell; // for responsive ellipsis
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .cf-entity-name {
      color: #101010;
    }

    .cf-account-name {
      color: rgba(33, 33, 33, 0.8);
    }
  }
`)
