import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  contentWrapper: {
    display: 'flex',

    '& .MuiTypography-root': {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  textMake: {
    paddingRight: '4px',
    fontWeight: 600
  },
  textCounter: {
    color: '#101010',
    opacity: 0.5
  }
})

export default useStyles
