import { css } from '@emotion/css'

export default css(`
  min-width: 350px;
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 16px;
  min-height: 80vh;
  
  .cf-timeline-title {
    font-size: 20px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 24px;
  }
  
  .cf-input-container {
    min-height: 100px;
    max-height: 100px;

    .cf-input {
      height: 100px;
      box-sizing: border-box;
    }
  }

  .cf-tooltip-container > p {
    font-size: 14px;
  }

  .cf-comment-date {
    display: flex;
    justify-content: center;
    height: 24px;
    color: #21212180;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin: 16px 0px;

    :after, :before {
      content: '';
      display: inline;
      width: 100%;
      height: 1px;
      background: #0000001F;
    }

    :after {
      margin: 12.5px 0px 12.5% 16px;
    }

    :before {
      margin: 12.5px 16px 12.5px 0px;
    }
  }
  
  .cf-add-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    
    .cf-add-btn {
      text-decoration: none;
      border: 1px solid #C99B86;
      border-radius: 8px;
      min-width: 140px;
      
      &.cf-button-disabled {
        border: 1px solid rgba(0, 0, 0, 0.30);
      }
      
      &.cf-button-root.cf-button-variant-text .cf-button-content {
        font-size: 14px;
        font-family: Roboto;
        font-weight: 500;
      }
    }
  }
  
  .cf-comment-list {
    height: auto;
    overflow-y: hidden;
  }

  .cf-no-data-block {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    color: #21212180;
  }
`)
