import { type FC, useCallback } from 'react'
import { cnx, FormNumberInput, Dropdown2 } from '@carfluent/common'
import get from 'lodash-es/get'

import { CommissionType } from 'api/types/responses'
import { joinPartsToStr } from 'utils/view_helper'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'
import { MAX_PRICE_VALUE } from 'constants/validation'

import { type CommissionFieldsSetProps } from './types'
import { COMMISSION_TYPES, COMMISSION_TYPES_IDS } from './constants'
import CLASS_NAME from './styles'

const CommissionFieldsSet: FC<CommissionFieldsSetProps> = ({
  className,
  errors,
  isLoading = false,
  onBlur,
  onChange,
  originalValues,
  pathPrefix,
  touched,
  values
}) => {
  const isPercent = values[pathPrefix].salesCommissionTypeId === CommissionType.Percent
  const commissionFieldId = isPercent ? 'percent' : 'fixed'
  const prefixize = (id: string): string => joinPartsToStr('.', pathPrefix, id)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onChangeCommissionType = useCallback((id, value) => {
    onChange(id, value)

    /**
     * Restore fields values for previous commission type
     */
    const prevCommissionFieldId = commissionFieldId // deps are old at this moment
    const prevCommissionFieldFullPath = prefixize(prevCommissionFieldId)
    const minAmountFieldFullPath = prefixize('minAmount')
    onChange(prevCommissionFieldFullPath, get(originalValues, prevCommissionFieldId, null))
    onChange(minAmountFieldFullPath, get(originalValues, 'minAmount', null))
  }, [
    commissionFieldId,
    onChange,
    onBlur,
    originalValues,
    prefixize
  ])

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <BlockSkeleton isLoading={isLoading} className='field-loader'>
        <Dropdown2
          dataTestId={prefixize('salesCommissionTypeId')}
          disableClearable
          formatDisplayValue={formatCommissionType}
          id={prefixize('salesCommissionTypeId')}
          label='Type'
          mode='select'
          onBlur={onBlur}
          onChange={onChangeCommissionType}
          options={COMMISSION_TYPES_IDS}
          renderOption={formatCommissionType}
          value={values[pathPrefix].salesCommissionTypeId}
        />
      </BlockSkeleton>

      <BlockSkeleton isLoading={isLoading} className='field-loader'>
        {isPercent && (
          <FormNumberInput
            blurComplementMode='min-scale' // AZ-TODO: update defaults for `percent` and remove
            error={errors[pathPrefix][commissionFieldId]}
            dataTestId={prefixize(commissionFieldId)}
            id={prefixize(commissionFieldId)}
            isEmptyAllowed
            label='Commission'
            mountComplementMode='min-scale' // AZ-TODO: update defaults for `percent` and remove
            onBlur={onBlur}
            onChange={onChange}
            preset='percent'
            value={values[pathPrefix][commissionFieldId]}
            touched={touched[pathPrefix][commissionFieldId]}
          />
        )}

        {!isPercent && (
          <FormNumberInput
            error={errors[pathPrefix][commissionFieldId]}
            dataTestId={prefixize(commissionFieldId)}
            id={prefixize(commissionFieldId)}
            isEmptyAllowed
            label='Commission'
            max={MAX_PRICE_VALUE}
            onBlur={onBlur}
            onChange={onChange}
            preset='price'
            value={values[pathPrefix][commissionFieldId]}
            touched={touched[pathPrefix][commissionFieldId]}
          />
        )}
      </BlockSkeleton>

      <BlockSkeleton isLoading={isLoading} className='field-loader'>
        {isPercent && (
          <FormNumberInput
            error={errors[pathPrefix].minAmount}
            dataTestId={prefixize('minAmount')}
            id={prefixize('minAmount')}
            isEmptyAllowed
            label='Min amount'
            max={MAX_PRICE_VALUE}
            onChange={onChange}
            onBlur={onBlur}
            preset='price'
            touched={touched[pathPrefix].minAmount}
            value={values[pathPrefix].minAmount}
          />
        )}

        {!isPercent && (
          <FormNumberInput
            error={null}
            dataTestId={prefixize('minAmount')}
            disabled={!isPercent}
            id={prefixize('minAmount')}
            isEmptyAllowed
            label='Min amount'
            max={MAX_PRICE_VALUE}
            onBlur={onBlur}
            onChange={onChange}
            preset='price'
            touched={false}
            value={null}
          />
        )}
      </BlockSkeleton>
    </div>
  )
}

export default CommissionFieldsSet

// ========================================== //

const formatCommissionType = (value: number | null): string => {
  return COMMISSION_TYPES.find(x => x.id === value)?.name ?? ''
}
