import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { TimelineLogTypes } from 'api/types'
import { TimelineTextTitles } from 'types'
import { DealView, VehicleDetails } from 'constants/route_helper'
import Avatar from 'components/common/NameWithAvatar'
import TooltipItem from 'components/common/TooltipItem'
import IconSVG from 'components/inlineImages'
import HTMLContent from 'components/common/HTMLContent'

import { type MultilineContentProps } from './types'
import CLASS_NAME from './styles'

const MultilineContent: FC<MultilineContentProps> = ({
  activityCompleteOption,
  activityCompleteSubOptions,
  className,
  clickedAction,
  dealId,
  feedbackActions,
  isMessage,
  labelValue,
  logTypeId,
  name,
  openedAction,
  salesperson,
  textItems,
  vehicleId
}) => {
  return (
    <>
      <div className={cnx(CLASS_NAME, 'timeline-item-content-header', className)}>
        {(name != null) && (
          <div className='name-wrapper'>
            {
              (logTypeId === TimelineLogTypes.Comment) ||
              ((logTypeId === TimelineLogTypes.LeadReceived) && (name === 'Unassigned'))
                ? null
                : <Avatar showAvatar name={name} avatarIcon={name.toLowerCase() === 'system' ? <IconSVG.Bot /> : null} />
            }

            {(activityCompleteSubOptions != null) && (
              <TooltipItem
                className='timeline-item-user-state-label'
                placement='bottom-end'
                isWideTooltip
                value={activityCompleteSubOptions.join(', ')}
              />
            )}

            {(feedbackActions != null) && (
              <TooltipItem
                className='timeline-item-user-state-label'
                placement='bottom-end'
                isWideTooltip
                value={feedbackActions}
              />
            )}

            {(activityCompleteOption != null) && (
              <TooltipItem
                className='timeline-item-user-state-label'
                placement='bottom-end'
                isWideTooltip
                value={activityCompleteOption}
              />
            )}

            {(openedAction != null) && (
              <TooltipItem
                className='timeline-item-user-state-label'
                placement='bottom-end'
                isWideTooltip
                value={openedAction}
              />
            )}

            {(clickedAction != null) && (
              <TooltipItem
                className='timeline-item-user-state-label'
                placement='bottom-end'
                isWideTooltip
                value={clickedAction}
              />
            )}
          </div>
        )}

        {(labelValue != null) && (labelValue !== '') && (
          <TooltipItem
            className='timeline-item-user-state-label'
            placement='bottom-end'
            isWideTooltip
            value={labelValue}
          />
        )}
      </div>

      {textItems.map((item, index) => {
        const { name, text } = item
        const isSalesperson = name === TimelineTextTitles.Salesperson
        const isDeal = (name === TimelineTextTitles.Deal) || (name === TimelineTextTitles.Reason)
        const isCar = name === TimelineTextTitles.Car

        const dealLink = createLink(dealId, DealView)
        const carLink = createLink(vehicleId, VehicleDetails)

        const isShouldConvertWithLink = isDeal || isCar || (logTypeId === TimelineLogTypes.Comment) || isMessage
        const linkText = (isDeal || isCar) ? text : undefined

        const getContentText = (): string => {
          switch (name) {
            case TimelineTextTitles.Deal:
            case TimelineTextTitles.Reason: {
              return dealLink
            }
            case TimelineTextTitles.Car: {
              return carLink
            }
            case TimelineTextTitles.Salesperson: {
              return ''
            }
            default: return text
          }
        }

        return (
          <HTMLContent
            key={index}
            isShouldConvertWithLink={isShouldConvertWithLink}
            linkText={linkText}
            text={getContentText()}
            name={name}
            salesperson={isSalesperson ? salesperson : null}
          />
        )
      })}
    </>
  )
}

export default MultilineContent

// ========================================== //

const createLink = (
  id: number | null | undefined,
  viewFunction: Function
): string => (
  id != null ? viewFunction(id) : '#'
)
