import { css } from '@emotion/css'

export default css(`
  padding-top: var(--content-margin-default);

  h6 {
    margin-top: 0;
    margin-bottom: 16px;
  }

  .cf-button-root.cf-button-content-sized {
    height: 26px;
    min-width: 70px;
    padding: 4px 18px;
    border-radius: 8px;
  }

  .cf-button-start-adornment, .cf-button-end-adornment {
    display: none;
  }

  .payment-section-text {
    font-size: 16px;
    color: #21212180;
    line-height: 24px;
    font-family: Roboto;
    letter-spacing: 0.5px;
  }

  .payment-section-chip-container {
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    min-height: 35px;
    margin-top: 16px;
  }

  .payment-section-title {
    display: flex;
    justify-content: space-between;
  }
`)
