import { useCallback, useEffect, useState } from 'react'
import { PRESET_LABELS } from '@carfluent/common'

import useTableApi from 'hooks/useTableApi'
import useDragScroll from 'hooks/useDragScroll'
import type { DateType, PaginatedResult, DictionaryItem } from 'api/types'

import type { SaleRecordModel, SaleReportDto, SaleSummaryModel, SaleBreakdownModel } from 'api/types/responses'
import { getDealSaleReport } from 'api/combine.api'
import CustomersCoreApiProvider from 'api/customersCore.api'
import type { GenerateSaleReportDto } from 'api/types/requests'

import { getPageOverlayContainer } from 'utils/html'
import { downloadBlob } from 'utils/general'
import fileNames from 'utils/reports/fileNames'

import type { UseReportDealSaleReportListReturn } from './types'

import {
  Messages,
  DEFAULT_SORTING,
  API_CALL_DELAY_SEARCH,
  getPresets
} from './constants'

import serializeFilters from './serializer'

const presets = getPresets()
const THIS_MONTH = presets.find(p => p.name === PRESET_LABELS.THIS_MONTH)
export const REPORT_FILE_NAMES = fileNames('DealsSaleReport')

const useReportDealSaleReport = (): UseReportDealSaleReportListReturn => {
  const [dateRange, setDateRange] = useState<DateType>({
    from: THIS_MONTH?.startDate ?? new Date(),
    to: THIS_MONTH?.endDate ?? new Date()
  })
  const [accountingStateIds, setAccountingStateIds] = useState<number[]>([])
  const [defaultReport, setDefaultReport] = useState<SaleReportDto | null>(null)
  const [isLoadingInvisible, setLoadingInvisible] = useState(false)
  const [isFiltersChange, setFiltersChange] = useState(false)
  const [summary, setSummary] = useState<SaleSummaryModel | null>(null)
  const [breakdown, setBreakdown] = useState<SaleBreakdownModel | null>(null)
  const [isShowSkeleton, setShowSkeleton] = useState(false)

  const containerElement = getPageOverlayContainer()

  const getReports = useCallback(async (payload): Promise<PaginatedResult<SaleRecordModel>> => {
    try {
      setFiltersChange(true)
      const response = await getDealSaleReport(payload)
      setDefaultReport(response.defaultReport)
      setSummary(response.defaultReport.summary)
      setBreakdown(response.defaultReport.breakdown)

      return { items: response.parsedDeport, count: response.parsedDeport.length }
    } catch (err) {
      return { items: [], count: 0 }
    } finally {
      setFiltersChange(false)
      setLoadingInvisible(false)
      setShowSkeleton(false)
    }
  }, [])

  const {
    rows,
    search,
    sorting,
    onSearch,
    isLoading,
    setSearch,
    setFilters,
    setSorting,
    emptyTableMessage
  } = useTableApi<SaleRecordModel, GenerateSaleReportDto, SaleRecordModel>({
    containerElement,
    shouldRunOnCall: true,
    defaultFilters: { dateRange, accountingStateIds, take: 0, skip: 0 },
    defaultSorting: DEFAULT_SORTING,
    getList: getReports,
    serializeFilters: serializeFilters,
    apiCallDelay: API_CALL_DELAY_SEARCH,
    emptyTableMessage: Messages.emptyTableState,
    nothingFoundMessage: Messages.nothingFoundState
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //
  const onDateFilterChange = useCallback(async (dateRange: DateType) => {
    void setFilters({ dateRange })
    setDateRange(dateRange)
  }, [setFilters])

  const onAccountingStateFilterChange = useCallback(async (accountingStateIds: DictionaryItem[]) => {
    const filters = accountingStateIds.map(({ id }) => id)
    void setFilters({ accountingStateIds: filters })
    setAccountingStateIds(filters)
  }, [setFilters])

  const onExportExcel = async (): Promise<void> => {
    try {
      if (defaultReport != null) {
        const response = await CustomersCoreApiProvider.downloadDealSaleReportXlsx(defaultReport)
        downloadBlob(response, REPORT_FILE_NAMES.xlsx)
      }
    } catch (err) {
      console.error(err)
    }
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useDragScroll({ className: '.cf-table-wrapper' })

  useEffect(() => {
    /**
     * Set skeleton table at the same scroll position as main table
     * to align columns at this two tables
     */
    if (isLoading) {
      const outerDiv = document.querySelector('.cf-table-wrapper') as HTMLDivElement
      const innerDiv = document.querySelector('.skeleton-table-wrapper') as HTMLDivElement

      if (outerDiv != null && innerDiv != null) {
        innerDiv.scrollLeft = outerDiv.scrollLeft
      }
    }
  }, [isLoading])

  useEffect(() => {
    setShowSkeleton(true)
  }, [])
  // ========================================== //

  return {
    period: { startDate: dateRange.from, endDate: dateRange.to },
    presets,
    rows,
    search,
    sorting,
    containerElement,
    emptyTableMessage,
    summary,
    breakdown,
    isFiltersChange: isFiltersChange && !isLoadingInvisible,
    isLoading: isLoading && !isLoadingInvisible,
    isShowSkeleton,
    onDateFilterChange,
    onExportExcel,
    onFiltersChange: setFilters,
    onSearch,
    onSearchChange: setSearch,
    onSortingChange: setSorting,
    onAccountingStateFilterChange
  }
}

export default useReportDealSaleReport
