import { useCallback, useRef } from 'react'
import { type ClassicEditor } from '@ckeditor/ckeditor5-editor-classic'

import { type UseRichEditorProps, type UseRichEditorReturn } from './types'

const useRichEditor = ({
  id,
  onBlur: _onBlur,
  onChange: _onChange
}: UseRichEditorProps): UseRichEditorReturn => {
  const refEditor = useRef<ClassicEditor | null>(null)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onReady = useCallback((editor: ClassicEditor): void => {
    // editor.data.processor = new HtmlDataProcessor(document as any) // AZ-TODO: fix editor imports, to use this
    refEditor.current = editor
  }, [])

  const onChange = useCallback((event) => {
    const data = refEditor.current?.getData()
    _onChange(id, data ?? '')
  }, [id, _onChange])

  const onBlur = useCallback((event) => {
    _onBlur?.(id)
  }, [id, _onBlur])

  // ========================================== //

  return {
    onBlur,
    onChange,
    onReady
  }
}

export default useRichEditor
