import { makeStyles } from '@material-ui/core/styles'
import { ScreenWidthBreakpoints } from 'constants/constants'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    minHeight: '124px',
    display: 'grid',
    justifyContent: 'space-evenly',
    gridTemplateColumns: 'repeat(8, minmax(160px, 1fr))',
    gridTemplateRows: '160px',
    gridRowGap: '16px',
    gridColumnGap: '16px',

    '& *': {
      outline: 'none'
    },

    [theme.breakpoints.down(ScreenWidthBreakpoints.Medium)]: {
      gridTemplateColumns: 'repeat(8, minmax(150px, 1fr))',
      gridTemplateRows: '150px'
    },

    [theme.breakpoints.down(1480)]: {
      gridTemplateColumns: 'repeat(8, minmax(124px, 1fr))',
      gridTemplateRows: '124px'
    }
  }

})
)

export default useStyles
