import { css } from '@emotion/css'

export default css(`
  --max-height: 40px;

  &.g-filters-panel {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    background-color: transparent;
    grid-column-gap: 24px;

    margin-bottom: 16px;
  }

  .g-filters-panel-left-part {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 8px;
    position: relative;

    &.with-title {
      justify-content: space-between;
    }
  }

  .g-filters-panel-right-part {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 24px;
  }

  .g-panel-right-full-width {
    justify-content: space-between;
    grid-column: 1 / 3;
  }
  
  .g-filters-panel-title {
    text-wrap: nowrap;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.18px;
    text-align: left;
    color: #101010;

    display: flex;
    align-items: center;
  }

  .g-filters-panel-filters {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    align-items: center;
    grid-column-gap: 8px;
    width: max-content;
    padding: 4px 0px;

    .g-filter-item {
      width: max-content;
      border-radius: 8px;

      &.white {
        background-color: #fff;
      }

      &.gray {
        background-color: #f3f3f3;
      }

      &, > * {
        height: 32px;
        position: relative;

        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-align: left;
      }

      svg {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }

      .checkmark svg {
        margin-left: 0px;
      }

      .cf-select-display-container, .cf-form-field, .cf-dropdown-root, .period-button, .action-bar, .cf-input-container {
        height: 100%;
        background-color: transparent;
      }

      .cf-select-display-container, .cf-form-field, .period-button, .cf-dropdown-root, .action-bar {
        border: none;
      }

      .cf-input-container {
        padding: 6px 8px;

        .cf-input-adornment {
          padding: 0px;
          
          .cf-dropdown-trigger-root > div {
            height: 20px;
            display: flex;
            align-items: center;
          }
        }

        input {
          padding: 0px;
          height: 20px;
        }
      }

      .cf-select-display-container {
        padding: 6px 8px;
        align-items: center;

        .cf-display {
          padding: 0px;
          height: 20px;
        }

        .cf-end-adornment {
          height: 16px;
          padding: 0px;
        }
      }

      .period-button {
        padding: 6px 8px;
        margin: 0px;
      }

      .cf-display, .period-button {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
      }

      &.range button {
        margin: 0px;
        padding: 6px 8px;
        height: 32px;

        .cf-button-content {
          display: flex;
          align-items: center;
        }
      }
    }

    .checkbox {
      display: flex;
      align-items: center;
      padding: 6px 8px;
    }
  }

  .g-filters-panel-search {
    width: 360px;

    .cf-input-container {
      height: var(--max-height);
      background-color: #fff;
      border: 0px;

      .cf-input {
        padding: 8px 16px;
        height: 24px;
      }

      .cf-start-adornment, .cf-end-adornment {
        padding: 8px 0px;
      }
    }

    &.gray .cf-input-container {
      background-color: #fafafa;
    }
  }

  .g-filters-panel-actions {
    .cf-button-content {
      font-size: 14px;
      font-weight: 500;
    }

    &, & > div {
      height: var(--max-height);
      position: relative;
      display: flex;
      align-items: center;

      button {
        min-width: 150px;
        width: auto;
        height: 100%;
      }
    }
  }
`)
