export const parseNumber = (value: string | number): number => {
  if (typeof value === 'string') {
    return parseFloat(value)
  }
  return value
}

export const limitMaxValueNumber = (val: number): number => {
  const [int, decimal] = val.toString().split('.')
  const maxValue = 5000

  if ((int != null && int.length >= 4) || (decimal != null && decimal.length >= 2)) {
    if (+int > maxValue) {
      return maxValue
    }
    return val
  }

  const intValue = Math.trunc(val)
  let floatValue = Math.round((val - intValue) * 100)

  if (floatValue >= 60) {
    floatValue = 59
  }

  const totalValue = intValue + floatValue / 100

  if (totalValue >= maxValue) {
    return maxValue
  }

  return Number(totalValue.toFixed(2))
}

export const convertNumberToTimeForDisplay = (number: number): string => {
  const [hours, minutes] = number.toFixed(2).toString().split('.').map(Number)
  const paddedMinutes = minutes.toString().padStart(2, '0')

  return `${hours.toString()}:${paddedMinutes}`
}
