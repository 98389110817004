import { css } from '@emotion/css'

export default css(`
  width: 100%;
  padding: 16px;
  background: #fff;
  border-radius: 12px;

  .MuiSkeleton-text {
    height: 16px;
  }

  .title-skeleton {
    display: flex;
    padding: 4px 0;

    span:first-of-type {
      width: 24px;
      margin-right: 16px;
    }

    span:last-of-type {
      width: 128px;
    }
  }

  .content-skeleton {
    margin-top: 16px;
    display: grid;
    gap: 0px 0px;
    grid-auto-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 8px 24px;
    grid-template-columns: 99px 1fr 1fr 160px;

    .car-photo-skeleton {
      grid-area: 1 / 1 / 3 / 2;

      .MuiSkeleton-text {
        width: 99px;
        height: 64px;
      }
    }

    .car-info-skeleton {
      display: flex;
      flex-direction: column;
      justify-content: center;

      span:first-of-type {
        margin-bottom: 8px;
      }

      span:last-of-type {
        width: calc(100% - 45px)
      }
    }

    .car-info-1 {
      grid-area: 1 / 2 / 3 / 3;
    }

    .car-info-2 { grid-area: 1 / 3 / 3 / 4; }

    .car-info-3 {
      margin-right: 49px;
      grid-area: 1 / 4 / 3 / 5;

      span:last-of-type {
        width: calc(100% - 37px)
      }
    }
  }
`)
