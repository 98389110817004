import { css } from '@emotion/css'

export default css(` 
  .cf-table-row.cf-skeleton-row .cf-table-cell .cf-cell-wrapper {
    overflow: hidden;
    border-left: 4px solid transparent;
    padding-left: 8px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background: #fff;
  }
  
  .cf-table-row {
    .cf-table-cell:first-child  .cell-description > div { min-width: 205px; max-width: 17vw; }

    .cf-table-cell:nth-last-child(2) .cell-description > div { min-width: 205px; max-width: 17vw; }
  }

  .cf-cell-wrapper p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .cf-table-header-cell { height: 48px; }

  .cf-no-data-block.hide-header {
    margin-top: 0px;
    border-top: none;
  }
`)
