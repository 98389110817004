import { type FC } from 'react'
import { cnx, isOk } from '@carfluent/common'

import UnsavedChangesDialog from 'components/dialogs/UnsavedChanges'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import CLASS_NAME from './styles'

interface ActionsFooterAndUnsavedChangesProps {
  isActionsFooterShown: boolean
  isSubmitting?: boolean
  isUnsavedChangesShown: boolean
  onSubmit: () => Promise<boolean>
  onClose: () => void
  onResetTabNavigation: () => void
  onDontSaveAndClose?: () => void
  unsavedChangesTitle?: string
  submitUnsavedChangesTitle?: string
  shouldCallClose?: boolean
  onResetForm: () => void
  proceedTransition: () => void
  resetTransition: () => void
}

export const ActionsFooterAndUnsavedChanges: FC<ActionsFooterAndUnsavedChangesProps> = ({
  isSubmitting = false,
  isUnsavedChangesShown,
  isActionsFooterShown,
  onSubmit: _onSubmit,
  onClose,
  onDontSaveAndClose: _onDontSaveAndClose,
  onResetTabNavigation,
  unsavedChangesTitle,
  submitUnsavedChangesTitle,
  onResetForm,
  shouldCallClose = true,
  proceedTransition,
  resetTransition
}) => {
  const onDontSaveAndClose = (): void => {
    _onDontSaveAndClose?.()
    if (shouldCallClose) {
      onClose()
    }

    proceedTransition()
  }

  const onSubmitFromModal = async (): Promise<void> => {
    const res = await _onSubmit()

    if (res) {
      proceedTransition()

      if (isUnsavedChangesShown) {
        _onDontSaveAndClose?.()
      }
    } else {
      resetTransition()
      onResetTabNavigation()
    }

    onClose()
  }

  const onSubmitFromFooter = async (): Promise<void> => {
    await _onSubmit()
    resetTransition()
    onResetTabNavigation()
  }

  return (
    <>
      <UnsavedChangesDialog
        onCloseDialog={onClose}
        title={unsavedChangesTitle}
        isSubmitting={isSubmitting}
        isOpen={isUnsavedChangesShown}
        onSaveAndClose={onSubmitFromModal}
        onDontSaveAndClose={onDontSaveAndClose}
        submitTitle={submitUnsavedChangesTitle}
        content="Changes done to this car will be lost if you don't save them."
      />

      <div className={cnx(CLASS_NAME, 'tab-footer', isActionsFooterShown && 'is-shown')} role='footer'>
        <CancelSubmitActionsFooter
          isLoading={isSubmitting}
          onSubmit={onSubmitFromFooter}
          submitTitle='SAVE'
          cancelTitle='DISCARD'
          onClose={onResetForm}
        />
      </div>
    </>
  )
}

export default ActionsFooterAndUnsavedChanges
