import type { ColumnDef } from '@carfluent/common/dist/UI'

import { DateCellHOC } from 'components/common/Table/cells/DateCell'
import TextCellHOC from 'components/common/Table/cells/TextCell'

import type { VehicleDocumentDto } from 'api/types'

import FileNameCell from './components/FileNameCell'
import UploadedByCell from './components/UploadedByCell'

export interface VehicleDocumentLoaded extends VehicleDocumentDto {
  loading?: boolean
}

interface GetColumnDefinitionsProps {
  onDelete: (id: number) => void
  onRedirect: (id: number) => Promise<void>
}

export default ({
  onDelete,
  onRedirect
}: GetColumnDefinitionsProps): Array<ColumnDef<VehicleDocumentLoaded | VehicleDocumentDto>> => [
  {
    accessorFn: ({ fileName, loading, fileId }: VehicleDocumentLoaded) => ({ name: fileName, loading, fileId }),
    id: 'name',
    cell: (props) => <FileNameCell {...props} onRedirect={onRedirect} />,
    header: TextCellHOC({ text: 'Name' }),
    sortable: false,
    size: 744
  },
  {
    accessorKey: 'createdDate',
    cell: DateCellHOC({ emptyValue: '' }),
    header: TextCellHOC({ text: 'Date' }),
    sortable: false,
    size: 200
  },
  {
    id: 'uploadedBy',
    accessorFn: ({ createdBy, loading, id }: VehicleDocumentLoaded) => ({ name: createdBy, loading, id }),
    cell: (props) => <UploadedByCell {...props} onDelete={onDelete} />,
    header: TextCellHOC({ text: 'Uploaded by' }),
    sortable: false,
    size: 336
  }
]
