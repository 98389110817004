import { DraftDecorator, ContentBlock } from 'draft-js'

import type { TextRange } from 'components/wysiwyg/types'
import CarBlock from 'components/wysiwyg/editor/CarBlock'
import { CAR_BLOCK_START, CAR_BLOCK_END } from 'utils/wysiwyg'

export const findCarInfoBlock = (text: string): TextRange | null => {
  const startIdx = text.indexOf(CAR_BLOCK_START)
  const endIdx = text.indexOf(CAR_BLOCK_END)

  if ((startIdx > -1) && (endIdx > -1)) {
    return {
      start: startIdx,
      startLength: CAR_BLOCK_START.length,
      end: endIdx,
      endLength: CAR_BLOCK_END.length
    }
  }

  return null
}

const findCarInfoBlockStrategy = (
  block: ContentBlock,
  callback: (start: number, end: number) => void
): void => {
  const range = findCarInfoBlock(block.getText())

  if (range != null) {
    callback(range.start, range.end + range.endLength)
  }
}

const carDecorator: DraftDecorator = {
  strategy: findCarInfoBlockStrategy,
  component: CarBlock
}

export const getCarDecoratorWithProps = (props: DraftDecorator['props']): DraftDecorator => ({
  ...carDecorator,
  props
})

export default carDecorator
