import { css } from '@emotion/css'

const UNWRAPPED_TOOLBAR_WIDTH = 1342

export const PAGE_CLASSES = css(`
  .cf-header-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    margin: 0 0 var(--content-margin-default);
    transition: 0.3s;

    &.smaller-indents {
      margin: 16px 0 24px 0;
    }
  }

  .cf-template-and-subject-inputs-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 24px;
    margin-bottom: var(--content-margin-default);

    .cf-form-field {
      width: 100%;
    }
  }

  > .cf-loader {
    position: absolute;
    inset: 0px;
    inset-inline: 0%;
    // important -> to "hover" labels inside inputs
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.75);
  }

  .cf-content {
    margin-bottom: 128px;
  }

  .cf-button-container {
    display: grid;
    grid-template-columns: 150px 150px;
    justify-content: flex-end;
    margin-top: 16px;
    grid-gap: 25px;

    padding: 12px 40px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    background: #FFFFFF;
    box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.12);
    z-index: 200;
    width: 100%;

    button.cf-button-root {
      margin: 0px;
    }

    .cf-button-content {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .cf-table-header-title-wrapper {
    display: flex;
    flex: 2;
    align-items: center;
  }

  .cf-button-link span {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .cf-email-editor .text-editor-toolbar {
    > :nth-last-child(3) {
      margin-right: 260px;

      @media screen and (min-width: ${UNWRAPPED_TOOLBAR_WIDTH}px) {
        margin-right: auto;
      }
    }

    > :nth-last-child(2) {
      min-width: 194px;
    }

    > :last-child {
      margin-left: 16px;
      @media screen and (min-width: ${UNWRAPPED_TOOLBAR_WIDTH}px) {
        margin-right: 0;
      }
    }

    .togglable-toolbar-button {
      height: 40px;
    }
  }
`)

export const DELETE_MODAL_CLASSES = css(`
  .delete-template-content {
    p {
      font-family: Roboto, sans-serif;
      word-wrap: break-word;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
      color: #101010;
    }
  }
`)
