import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  .main-header-logo {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .main-header-title {
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: #101010;
    user-select: none;
    padding-left: 24px;
  }
  
  .main-header-logout.cf-button-root.cf-button-variant-text {
    color: #101010;
    text-decoration: none;
  }

  .cf-dropdown-root {
    width: 340px;
    padding: 12px 0px;

    .cf-select-display-container {
      align-items: center;
      height: 40px;
      padding: 0 12px;

      > div {
        height: 24px;
        padding: 0;
      }

      .cf-start-adornment {
        margin-right: 8px;
      }
    }
  }

  .main-header-actions-menu {
    padding: 12px 24px;
    .cf-menu-item {
      min-width: 200px;
    }
  }

  .main-header-manage-account-icon-btn {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 12px;
    background-color: #FAFAFA;
    cursor: pointer;

    :hover {
      background-color: rgba(33, 33, 33, 0.08);
    }

    &.menu-open {
      background-color: rgba(33, 33, 33, 0.08);
    }
  }
`)

export const ACCESS_CHANGED_MODAL_CLASSES = css(`
  text-align: center;

  .title {
    font-family: Roboto, sans-serif;
    font-size: 34px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: center;
    color: #101010;
  }
  
  .text {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
    color: #101010;
    margin-top: 32px;
  }
  
  .cf-button-root {
    width: 100%;
    margin-top: 48px;

    .cf-button-content {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-align: center;
      text-transform: uppercase;
    }
  }
`)
