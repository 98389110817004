import { css } from '@emotion/css'

export default css(`
  margin-right: 24px;
  margin-bottom: 24px;

  @media screen and (max-width: 1279px) {
    width: auto;
    overflow-x: auto !important;
  }

  .summary-wrapper {
    display: grid;
    gap: 24px;
    grid-template-columns: minmax(max-content, 430px) minmax(max-content, 430px) 1fr;

    @media screen and (max-width: 1279px) {
      min-width: 1280px;
      overflow: scroll;
    }
   
    .summary-item {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      background: #F7FAFF;
      gap: 8px;
      padding: 8px 16px;

      .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--Text-Black, #101010);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        gap: 8px;

        .summary-item__value,
        .summary-item__label {
          width: 100%;

          &.bold > div{
            font-weight: 500;
          }
        }

        .is-loading {
          height: 16px;

          span {
            height: 100%;
          }
        }

        .summary-item__value {
          text-align: right;
          align-items: flex-end;
          display: flex;
          justify-content: flex-end;

          .is-loading { width: 60px; }
        }
      }
      
      &.sum {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 20px;
          font-weight: 500;
        }

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 140px;

          .is-loading { width: 100%; }

          &:first-child {
            align-items: flex-start;
            text-align: start;

            .summary-item__value {
              text-align: left;
            }
          }

          &:last-child {
            align-items: flex-end;
            text-align: end;

            .summary-item__value {
              text-align: right;
            }
          }
          
          .summary-item__label {
            color: rgba(33, 33, 33, 0.50);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;
          }

          .summary-item__value {
            color: #101010;
            justify-content: center;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.4px;
          }
        }
      }
    }
  }
`)
