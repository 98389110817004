import { css } from '@emotion/css'

export default css(`
  display: flex;
  color: #101010;
  align-items: center;
  justify-content: center;

  .legend-item {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 20px;
    margin-right: 16px;
    align-items: center;
    letter-spacing: 0.25px;
    justify-content: center;
  }

  .web-leads-icon {
    height: 4px;
    width: 16px;
    margin-right: 8px;
    border-radius: 1px;
    background: #0047FF;
  }

  .price-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 4px;
    background: #7CDF984D;
  }
`)
