import type { EntityBalance, AccountBalance, EntityListItem, AccountListItem } from 'api/types'
import { EntityTypeId } from 'api/types'
import _getEntityName from 'utils/accounting/getEntityName'
import { isPersonalType } from 'utils/deals/workflowTypes'

export const getEntityName = (el: EntityBalance): string => {
  return _getEntityName(el.customer, el.vendor, '')
}

export const getBusinessName = (el: EntityBalance): string => {
  const isPersonal = isPersonalType(el?.customer?.customerTypeId)
  const isBusiness = !isPersonal

  return isBusiness ? (el.customer?.businessName ?? '') : _getEntityName(el.customer, el.vendor, '')
}

export const getEntityId = (el: EntityBalance): number => {
  return el.customerId ?? el.vendorId ?? 0
}

export const getAcountName = (el: AccountBalance): string => {
  return `${el.account.number ?? ''} - ${el.account?.name}`
}

export const getAcountId = (el: AccountBalance): number => {
  return el.account.number ?? 0
}

export const entityListItemToBalanceEntity = (data: EntityListItem): EntityBalance => {
  const isCustomer = data.entityType === EntityTypeId.Customer

  return {
    customerId: isCustomer ? data.id : null,
    vendorId: isCustomer ? null : data.id,
    vendor: null,
    customer: null,
    balance: 0
  }
}

export const accountToAccountBalance = (data: AccountListItem): AccountBalance => {
  return {
    account: data,
    balance: 0
  }
}
