import { useEffect } from 'react'
import { type NavigateFunction } from 'react-router-dom'
import { NAVIGATE_EVENT } from './useNavigate'

const useNavigateSubscription = (doAction: (...args: Parameters<NavigateFunction>) => void): void => {
  useEffect(() => {
    const handler = (evt: Event): void => {
      const detail = (evt as CustomEvent<Parameters<NavigateFunction>>).detail
      doAction(...detail)
    }

    window.addEventListener(NAVIGATE_EVENT, handler)

    return () => {
      window.removeEventListener(NAVIGATE_EVENT, handler)
    }
  }, [doAction])
}

export default useNavigateSubscription
