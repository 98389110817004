import { type ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, isFail } from '@carfluent/common'

import { UserRoles } from 'api/types'
import AccountingApiProvider from 'api/accounting.api'
import type { AccountListItem, VehicleDetailsByVin, TransactionLineVendorDto } from 'api/types'
import CalcRoutes from 'constants/route_helper'
import { scrollToError } from 'utils/validation'
import getCurrentUser from 'utils/getCurrentUser'
import useAsyncEffect from 'hooks/useAsyncEffect'
import useDropdown from 'hooks/useDropdown'
import useUsersList from 'hooks/useUsersList'
import SettingsCTX from 'store/settings'
import AuthCTX from 'store/auth'

import type { AddNewVehicleFormData, ErrTouchShortcuts, UseNewVehicleProps, UseNewVehicleReturnType } from './types'
import { GET_DEFAULT_FORM_DATA } from './constants'
import parseVehicleDetails from './parser'
import useAddNewVehicleAPI from './useApiClient'
import validationRules from './validator'

const DEFAULT_FORM_DATA = GET_DEFAULT_FORM_DATA()
const SCROLL_TO_ERROR_DELAY = 200

const FIELD_TRANSFORMS = {
  stock: (value: string) => value.replace(/[^a-zA-Z0-9 ]/g, '')
}

function useNewVehicle ({ onClose, isOpen }: UseNewVehicleProps): UseNewVehicleReturnType {
  const currentUser = getCurrentUser()
  const users = useUsersList(currentUser)
  const navigate = useNavigate()
  const [purchaseAccountOptions, setPurchaseAccountOptions] = useState<AccountListItem[] | []>([])
  const API = useAddNewVehicleAPI()
  const refVehicleId = useRef<number | null>(null)
  const { isAccountingEnabled } = useContext(SettingsCTX)
  const { userRoles } = useContext(AuthCTX)

  const isMechanic = userRoles.includes(UserRoles.Mechanic)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onSubmitNewVehicle = useCallback(async (values): Promise<void> => {
    const vehicleId = await API.addNewVehicle(values, setFieldError)
    refVehicleId.current = vehicleId
  }, [API.addNewVehicle])

  const onSubmitResult = useCallback(async (result, resetForm) => {
    if (isFail(result)) {
      setTimeout(scrollToError, SCROLL_TO_ERROR_DELAY)
      return
    }

    const vehicleId = refVehicleId.current
    if (vehicleId != null) {
      onClose()
      resetForm(DEFAULT_FORM_DATA)
      navigate(CalcRoutes.VehicleDetails(vehicleId))
    }
  }, [navigate, onClose])

  const form = useForm<AddNewVehicleFormData, ErrTouchShortcuts>({
    baseValues: DEFAULT_FORM_DATA,
    onActionResult: onSubmitResult,
    submitAction: onSubmitNewVehicle,
    transforms: FIELD_TRANSFORMS,
    validationRules
  })

  const {
    onChange,
    setFieldError,
    setValues,
    values
  } = form

  const vendorProps = useDropdown<TransactionLineVendorDto>({
    getItemsList: AccountingApiProvider.getVendors,
    id: 'vendor',
    value: values.vendor
  })

  const onAddVendor = useCallback(async (vendor: TransactionLineVendorDto) => {
    await vendorProps.onScroll('', true)
    onChange('vendor', vendor)
  }, [onChange, vendorProps])

  const onCancelClick: () => void = useCallback(() => {
    onClose()
    API.resetAPIErrors()
    form.resetForm(DEFAULT_FORM_DATA)
  }, [onClose, form.resetForm, API.resetAPIErrors])

  const onApplyClick = useCallback(async (vin: string): Promise<VehicleDetailsByVin | null> => {
    const vehicle = await API.loadVehicleByVin(vin)

    if (vehicle != null) {
      const parsedVehicle = parseVehicleDetails(values, vehicle, API.dictionaries)
      setValues(parsedVehicle, true)
    }
    return vehicle
  }, [
    API,
    setValues,
    values
  ])

  const onChangeAccountId = (_: ChangeEvent<HTMLInputElement>, value: string, id: string): void => {
    onChange(id, value)
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useAsyncEffect(async () => {
    if (isOpen) {
      try {
        if (isMechanic) {
          const stockNumber = await API.getStockNumber()

          onChange('stock', stockNumber)
        } else {
          const [stockNumber, options] = await Promise.all([
            API.getStockNumber(),
            API.getVehiclePaymentAccountsList(),
            API.loadInventoryDictionaries()
          ])

          setPurchaseAccountOptions(options)
          onChange('stock', stockNumber)
          onChange('accountId', options[0].id)
        }
      } catch { /** silent error catching */ }
    } else {
      form.resetForm(DEFAULT_FORM_DATA)
    }
  }, [
    isOpen,
    API.getStockNumber,
    API.loadInventoryDictionaries,
    form.resetForm
  ])

  useEffect(() => {
    onChange('disabilityEquipped', API.dictionaries?.DisabilityEquipped[1])
  }, [
    API.dictionaries,
    onChange
  ])

  // ========================================== //

  return {
    ...form,
    onChange,
    isLoading: API.isLoading,
    apiErrors: API.apiErrors,
    dictionaries: API.dictionaries,
    onApplyClick,
    onCancelClick,
    purchaseAccountOptions,
    onAddVendor,
    vendorProps,
    isAccountingEnabled,
    onChangeAccountId,
    users
  }
}

export default useNewVehicle
