import { convert } from 'number-words'
import { formatters as F } from '@carfluent/common'

import formatCurrencyLabel from 'utils/accounting/formatCurrencyLabel'
import { capitalizeString, isTruthy } from 'utils/general'
import { type TransactionLineRow } from 'types'
import {
  type ControlListItem,
  type TransactionLineCustomerDto,
  type TransactionLineVendorDto
} from 'api/types'

import type { TransactionFormData } from './types'
import { joinPartsBySpace } from 'utils/view_helper'

export type Entity = TransactionLineCustomerDto | TransactionLineVendorDto

export interface PrintCheckConfig {
  printCheckNumber: boolean
  printHorizontalOffset: number
  printVerticalOffset: number
  printHeaderCheckNumberRowMarginBottom: number
  printHeaderDateRowMarginBottom: number
  printHeaderAmountRowMarginBottom: number
  printHeaderAmountWordsRowMarginBottom: number
  printHeaderEntityWithAddressRowMarginBottom: number
  printTable1MarginTop: number
  printTable1MarginBottom: number
}

export interface PrintCheckReturn {
  close: () => void
  focus: () => void
  showLoader: () => void
  showCheck: (data: CheckInfo) => void
}

export interface CheckInfo {
  control: ControlListItem | null
  entity: Entity
  form: TransactionFormData
  isEmptyCheck: boolean
  lines: TransactionLineRow[]
}

const PAGE_TOP_PADDING = 50 /* default vertical offset, allows to have `0` in settings  */
const DETAILED_ROWS_NUM = 6

const CheckWindow = (conf: PrintCheckConfig): PrintCheckReturn => {
  const wnd = window.open(
    '',
    '_blank',
    'popup=1,toolbar=0,location=0,directories=0,status=0,menubar=0,scrollbars=1,resizable=1,fullscreen=yes'
  )

  const focus = () => {
    wnd?.focus()
  }

  let loaderTimerId: number

  const showLoader = () => {
    if (wnd == null) {
      return
    }

    let dots = 0

    loaderTimerId = wnd.setInterval(() => {
      wnd.document.body.innerHTML = getLoaderHtml(dots)
      dots = dots >= 3 ? 0 : (dots + 1)
    }, 500)
  }

  const showCheck = (data: CheckInfo) => {
    try {
      wnd?.clearInterval(loaderTimerId)
      wnd?.document.write(getCheckHtml(data, conf))
      wnd?.document.close()
    } catch (err) {
      console.error(err)
    }
  }

  const close = (): void => {
    wnd?.close()
  }

  return {
    close,
    focus,
    showLoader,
    showCheck
  }
}

export default CheckWindow

// ========================================== //

const withPrefix = false

const getLoaderHtml = (dots = 1): string => {
  return `Processing${'.'.repeat(dots)}`
}

const getCheckHtml = (data: CheckInfo, config: PrintCheckConfig): string => {
  return `
    <!DOCTYPE html>
    <html lang="en">
      ${getHtmlHead({ ...config, isEmptyCheck: data.isEmptyCheck })}
      ${getHtmlBody(data)}
    </html>
  `
}

const getHtmlBody = (data: CheckInfo): string => {
  const table = getTable(data)

  return `
    <body>
      <div class="btn-print">
        <button onclick="window.print()">Print</button>
      </div>

      <div class="page-wrapper">
        ${getBodyHeader(data)}
        ${table}
        ${table}
      </div>

      <script>window.print()</script>
    </body>
  `
}

const getBodyHeader = ({ entity, form, lines }: CheckInfo): string => {
  const amount = getTotalAmount(lines)

  return `
    <div class="block-header">
      <div class="block-header-inner">
        <div class="header-row-check-number">${form.checkNumber ?? ''}</div>
        <div class="header-row-date">${F.formatLocalDate(form.dateTime, 'MMM d, yyyy')}</div>

        <div class="header-row-amount">
          <div class="header-entity">${form.receivableEntity?.name}</div>
          <div class="total-amount-number">${isTruthy(amount) ? formatCurrencyLabel(amount, false) ?? '' : ''}</div>
        </div>

        <div class="header-row-amount-words">
          <div>${formatNumberToWords(getTotalAmount(lines))}</div>
        </div>

        <div class="header-row-entity-with-address">
          <div>${form.receivableEntity?.name ?? ' '}</div>
          <div>${formatAddress1(entity) ?? ' '}</div>
          <div>${formatAddress2(entity) ?? ' '}</div>
        </div>

        <div class="header-row-memo">
          ${form.memo ?? ''}
        </div>
      </div>
    </div>
  `
}

const getTable = ({
  form,
  lines = [],
  isEmptyCheck
}: CheckInfo): string => {
  return `
    <div class="block-transactions">
      <div class="transactions-row-check-number">${form.checkNumber ?? ''}</div>
      ${getTableDateLine(form, isEmptyCheck)}

      <table class="transaction-lines-table">
        <thead>
          <tr>
            <th class="header-cell account-cell"><p>ACCOUNT</p></th>
            <th class="header-cell control-cell"><p>CONTROL</p></th>
            <th class="header-cell description-cell"><p>DESCRIPTION</p></th>
            <th class="header-cell amount-cell"><p>AMOUNT</p></th>
          </tr>
        </thead>

        <tbody>
          ${getTableRows(lines.slice(0, DETAILED_ROWS_NUM))}
          ${getAggregatedRow(lines.slice(DETAILED_ROWS_NUM))}
        </tbody>
      </table>

      ${getTableTotalLine(form, lines)}
      ${getTableBottomLine(form)}
    </div>
  `
}

const getTableDateLine = (form: TransactionFormData, isEmptyCheck: boolean): string => {
  return `
    <div class="transactions-row-date">
      ${!isEmptyCheck ? '<div>' + F.formatLocalDate(form.dateTime, 'MMM d, yyyy') + '</div>' : ''}
      <div>${form.receivableEntity?.name ?? ''}</div>
      ${isEmptyCheck ? '<div></div>' : ''}
      <div class="check-control">${form.receivableControl?.name ?? ''}</div>
    </div>
  `
}

const getTableTotalLine = (form: TransactionFormData, lines: TransactionLineRow[]): string => {
  const total = formatCurrencyLabel(getTotalAmount(lines), withPrefix)
  return `
    <div class="table-total-line">
      <div>${form.receivableAccount?.name ?? ''}</div>
      <div class="total-amount">
        <div class="total-amount-title">CHECK TOTAL</div>
        <p class="total-amount-value">${(total != null && Number(total) !== 0) ? total : ''}</p>
      </div>
    </div>
  `
}

const getTableBottomLine = (form: TransactionFormData): string => {
  return form.memo != null
    ? `
    <div class="table-bottom-line">
      <p>${form.memo}</p>
    </div>
  `
    : ''
}

const getTableRows = (rows: TransactionLineRow[]): string => {
  return rows.map(l => {
    const amount = formatCurrencyLabel(getAmount(l.debits, l.credits), withPrefix)

    return (`
      <tr>
        <td class="account-cell"><p>${l.account?.number ?? ''}</p></td>
        <td class="control-cell"><p>${l.control?.controlId ?? ''}</p></td>
        <td class="description-cell"><p>${l.notes ?? ''}</p></td>
        <td class="amount-cell"><p>${(amount != null && Number(amount) !== 0) ? amount : ''}</p></td>
      </tr>
    `)
  }).join('\n')
}

const getAggregatedRow = (rows: TransactionLineRow[]): string => {
  const amount = rows.reduce((acc, r) => acc + getAmount(r.debits, r.credits), 0)

  return rows.length >= 1
    ? `
      <tr class="table-aggregated-row">
        <td colspan="3"><p>${rows.length} ADDITIONAL LINES</p></td>
        <td class="amount-cell"><p>${formatCurrencyLabel(amount)}</p></td>
      </tr>
    `
    : ''
}

const getAmount = (debit: number, credit: number): number => {
  if (debit !== 0) {
    return debit
  }

  if (credit !== 0) {
    return -credit
  }

  return 0
}

const getTotalAmount = (lines: TransactionLineRow[]): number => {
  return lines.reduce((acc, r) => acc + getAmount(r.debits, r.credits), 0)
}

const formatNumberToWords = (num: number): string => {
  if (!isNaN(num)) {
    const [dollars, cents = 0] = num.toString().split('.').map(Number)
    const dollarsStr = capitalizeString(convert(dollars).replace(' and', ' '))

    return `${dollarsStr} and ${cents.toString().padEnd(2, '0')}/100`
  } else {
    return ''
  }
}

const formatAddress1 = (entity: Entity): string => {
  return joinPartsBySpace(entity?.address)
}

const formatAddress2 = (entity: Entity): string => {
  return joinPartsBySpace(entity?.city, entity?.state, entity?.zipCode)
}

export interface PrintCheckHeaderConfig extends PrintCheckConfig {
  isEmptyCheck: boolean
}

const getHtmlHead = ({
  isEmptyCheck,
  printCheckNumber,
  printHorizontalOffset,
  printVerticalOffset,
  printHeaderCheckNumberRowMarginBottom = 24,
  printHeaderDateRowMarginBottom = 28,
  printHeaderAmountRowMarginBottom = 3, // moves amount (in words) vertically
  printHeaderAmountWordsRowMarginBottom = 32, // moves memo words vertically
  printHeaderEntityWithAddressRowMarginBottom = 16,
  printTable1MarginTop = 0,
  printTable1MarginBottom = 0
}: PrintCheckHeaderConfig): string => {
  return `
    <head>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content="CarFluent Dealer Application" />
      <title>CarFluent_Check</title>
      <link rel='preload' as='style' href='https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap'>
      <link rel='stylesheet' href='https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap'>
      <style>
        :root {
          --page-width: 100%;
          --block-height: 264px;
          --account-cell-width: 60px;
          --control-cell-width: 112px;
          --description-cell-width: 292px;
          --amount-cell-width: 80px;
        }

        * {
          font-family: Roboto, sans-serif;
          box-sizing: border-box;
        }

        body {
          margin: 0px auto;
          padding: 0px;
          color: #101010;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          max-width: var(--page-width);
          box-sizing: border-box;
          width: 595px;
          overflow-x: hidden;
          position: relative;
        }

        .page-wrapper {
          padding: 0px;
          margin: 0px;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .block-header {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          box-sizing: border-box;
          block-size: 25em;
          min-height: var(--block-height);
          width: var(--page-width);
          position: relative;
          overflow: hidden;
        }

        .block-header-inner {
          width: 100%;
          height: 100%;
          margin: 0px;
          position: absolute;
          top: ${printVerticalOffset}px; /* configurable */
          right: ${printHorizontalOffset}px; /* configurable */
          padding-top: ${PAGE_TOP_PADDING}px; /* default for Dan printer */
          padding-right: 33px; /* moves date and amount horizontally */
          padding-bottom: 34px;
          padding-left: 67px;
        }

        .header-row-check-number {
          color: #101010;
          font-size: 12px;
          font-weight: 600;
          line-height: 150%;
          text-align: end;
          margin-bottom: ${printHeaderCheckNumberRowMarginBottom}px;
          visibility: ${printCheckNumber ? 'unset' : 'hidden'};
        }

        .header-row-date {
          text-align: end;
          margin-bottom: ${printHeaderDateRowMarginBottom}px;
          visibility: ${isEmptyCheck ? 'hidden' : 'unset'};
        }

        .header-row-amount {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: ${printHeaderAmountRowMarginBottom}px;
          padding-left: 36px;
        }

        .header-row-amount .total-amount-number {
          min-width: 64px;
          width: min-content;
        }

        .header-row-amount-words {
          width: 100%;
          margin-bottom: ${printHeaderAmountWordsRowMarginBottom}px;
          padding-left: 13px;
          min-height: 18px;
        }

        .header-row-entity-with-address {
          padding-left: 12px; /* moves memo horizontally */
          margin-bottom: ${printHeaderEntityWithAddressRowMarginBottom}px;
          font-size: 12px;
          line-height: 130%;
        }

        .header-row-memo {
          color: #101010;
          font-size: 12px;
          font-weight: 400;
          line-height: 140%;
          max-width: 327px;
          text-overflow: ellipsis;
          overflow : hidden;
          white-space: nowrap;
        }

        /**
         * Table
         */

        .total-amount-title {
          padding-right: 16px;
        }

        .total-amount-value {
          min-width: 42px;
          margin: 0;
        }

        .block-transactions {
          height: calc(var(--block-height) + 72px);
          padding: 13px 21px 0px 22px;
        }

        .block-transactions:nth-child(2) {
          margin-top: ${printTable1MarginTop}px;
        }

        .block-transactions:last-child {
          margin-top: ${printTable1MarginBottom}px;
        }

        .transactions-row-check-number {
          color: #101010;
          font-size: 12px;
          font-weight: 600;
          line-height: 150%;
          text-align: end;
          visibility: ${printCheckNumber ? 'unset' : 'hidden'};
          margin-bottom: 2px;
        }

        .transactions-row-date {
          display: grid;
          grid-template-columns: 94px 1fr 120px;
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          width: 100%;
          margin-bottom: 2px;
        }

        .transactions-row-date .check-control {
          text-align: end;
        }

        .transaction-lines-table {
          border-collapse: collapse;
          width: 100%;
          text-align: left;
        }

        .transaction-lines-table tr th {
          text-align: left;
        }
        
        .transaction-lines-table tr td {
          border-bottom: 1px solid rgba(0, 0, 0, 0.38);
          text-align: left;
        }

        .transaction-lines-table tr p {
          font-size: 10px;
          line-height: 16px;
          padding: 0;
          margin: 0;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .transaction-lines-table tr:first-of-type td {
          border-top: 1px solid black;
        }

        .transaction-lines-table tr:last-of-type td {
          border-bottom: 1px solid black;
        }

        .cf-table-header-cellp {
          font-weight: 600;
          text-align: start;
          text-transform: uppercase;
        }

        .account-cell p {
          width: var(--account-cell-width);
        }

        .control-cell p {
          width: var(--control-cell-width);
        }

        .description-cell p {
          width: var(--description-cell-width);
        }

        .amount-cell p {
          text-align: end;
          width: var(--amount-cell-width);
          min-width: 100%;
          min-height: 16px;
        }

        .table-total-line {
          display: grid;
          grid-template-columns: 1fr 1fr;
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          width: 100%;
          margin-top: 4px;
        }

        .table-total-line .total-amount {
          display: flex;
          justify-content: flex-end;
          text-align: end;
        }

        .table-aggregated-row {
          margin-top: 4px;
          text-align: center;
        }

        .table-bottom-line {
          display: flex;
          font-size: 10px;
          line-height: 18px;
          margin-top: 8px;
          margin-top: 8px;
          min-width: 100%;
        }

        .table-bottom-line p {
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .btn-print {
          position: absolute;
          top: 8px;
          left: 8px;
          display: flex;
          justify-content: flex-start;
          z-index: 100;
        }

        .btn-print button {
          cursor: pointer;
          padding: 8px;
          width: 60px;
        }

        @media print {
          .btn-print {
            display: none !important;
          }

          @page {
            margin-top: 0;
            margin-bottom: 0;
          }

          body {
            width: var(--page-width);
          }
        }
      </style>
    </head>
  `
}
