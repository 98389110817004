import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { DealNextActionMap } from 'constants/names'
import CLASS_NAME from './styles'

export interface DealActionProps {
  action: number | null
}

const DealNextAction: FC<DealActionProps> = ({ action }) => {
  const name = DealNextActionMap[action ?? -1] ?? null

  if (name == null) {
    return null
  }

  return (
    <span className={cnx(CLASS_NAME, `deal-action-${name.toLowerCase()}`)}>
      {name}
    </span>
  )
}

export default DealNextAction
