import type { FC } from 'react'

import CLASS_NAME from './styles'

const ReconStepsTableHeader: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <p className='cf-title'> Step </p>
      <label className='cf-label-daysInStepUntilFirstAlert'>DIS (yel/h)</label>
      <label className='cf-label-daysInStepUntilSecondAlert'>DIS (red/h)</label>
      <label className='cf-label-daysInReconUntilFirstAlert'>DIR (yel/h)</label>
      <label className='cf-label-daysInReconUntilSecondAlert'>DIR (red/h)</label>
      <p className='cf-title message'>Message notifications</p>
    </div>
  )
}

export default ReconStepsTableHeader
