import { css } from '@emotion/css'

export default css`
  .main-container {
    margin-top: 16px;
    display: grid;
    row-gap: 32px;
    padding-bottom: 80px;
  }
  
  .products-wrapper {
    height: 376px;
    display: grid;
    border: 1px solid rgba(0, 0, 0, 0.12);
    grid-template-columns: 1fr 1px 1fr;
    column-gap: 10px;
    padding: 16px;
    border-radius: 12px;
    
    .divider {
      background: rgba(0, 0, 0, 0.12);
    }
    
    .product-column {
      display: flex;
      flex-direction: column;

      .simple-text {
        &.MuiSkeleton-text {
          width: 136px;
        }
      }
      
      .drag-block {
        margin-top: 8px;
      }
      
      .horizontal-divider {
        height: 1px;
        background: rgba(0, 0, 0, 0.12);
        margin-top: 8px;
      }
    }
    
    > * {
      height: 100%;
    }
  }
  
  .package-customization {
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 16px;
    
    .grid-row {
      display: grid;
      gap: 8px;
      grid-template-columns: 1fr 176px 176px 176px;
      align-items: center;
      
      &.body {
        grid-template-rows: 56px;
        align-items: center;
        
        > div {
          display: flex;
          justify-content: center;
          
          &.name {
            flex-direction: column;
            
            .simple-text {
              &:first-child {
                width: 50%;
                margin-bottom: 2px;
              }
            }
          }
        }
      }
      
      .header {
        &.MuiSkeleton-text {
          width: 50%;
        }
      }
    }
  }
  
  .title {
    margin-bottom: 16px;

    &.MuiSkeleton-text {
      height: 26px;
      width: 136px;
    }
  }
  
  .simple-text {
    &.MuiSkeleton-text {
      height: 16px;
      width: 100%;
    }
  }
  
  .drag-block {
    &.MuiSkeleton-text {
      height: 40px;
      width: 100%;
      border-radius: 12px;
    }
  }
  
  .checkbox {
    &.MuiSkeleton-text {
      height: 16px;
      width: 16px;
    }
  }
  
  .label {
    &.MuiSkeleton-text {
      border-radius: 12px;
      height: 48px;
    }
  }
  
  .button {
    &.MuiSkeleton-text {
      height: 26px;
      width: 80px;
      border-radius: 8px;
    }
  }
  
  .title-with-button {
    display: flex;
    justify-content: space-between;
  }
  
  .blocks-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    justify-content: space-between;

    .template-item {
      &.MuiSkeleton-text {
        border-radius: 12px;
        padding-bottom: 107%;
        height: auto;
      }
    }
  }
`
