import { css } from '@emotion/css'

export default css(`
  .costs-row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 0.6fr 0.4fr;
    column-gap: 16px;
    height: min-content;
  }

  .btn-remove {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    .cf-button-variant-text:hover {
      text-decoration: underline;
      background: none;
    }
  }
`)
