import mapValues from 'lodash-es/mapValues'

import {
  type TransactionFormData,
  type TransactionFormErrors,
  type TransactionLineRow
} from 'types'

export { TRANSACTION_TYPE_IDS_WITH_DEFAULT_VALUE } from '../components/TransactionDetailsContainer/constants'

export const DEFAULT_FORM_DATA = {
  accountId: null,
  amount: null,
  checkNumber: null,
  customerId: null,
  costInventoryId: null,
  dateTime: null,
  dueDate: null,
  description: '',
  endDate: null,
  isLocked: false,
  isVehicleCost: false,
  isVehiclePayment: false,
  lastPrintDate: null,
  memo: null,
  referenceNumber: null,
  receivableAccount: null,
  receivableEntity: null,
  receivableVendor: null,
  receivableControl: null,
  receivableLineId: null,
  recurringInterval: null,
  recurringTransactionTemplateId: null,
  startDate: null,
  transactionTypeId: null,
  vendorId: null
}

export const DEFAULT_ROW: TransactionLineRow = {
  account: null,
  control: null,
  dealId: null,
  credits: 0,
  debits: 0,
  entity: null,
  notes: '',
  disabled: false
}

export const getDefaultFormData = (forceNulls = false): TransactionFormData => ({
  ...DEFAULT_FORM_DATA,
  dateTime: forceNulls ? null : new Date(),
  dueDate: forceNulls ? null : new Date()
})

export const getDefaultFormErrors = (errors: Partial<TransactionFormErrors>): TransactionFormErrors => ({
  ...mapValues(DEFAULT_FORM_DATA, () => null),
  ...errors
})

export const getDefaultRow = (): TransactionLineRow => ({ ...DEFAULT_ROW })
export const getDefaultRows = (): TransactionLineRow[] => ([getDefaultRow()])

export enum PrintTitle {
  Print = 'PRINT CHECK',
  RePrint = 'RE-PRINT CHECK'
}
