import { css } from '@emotion/css'

export default css`
  .cf-modal-content-scroll-wrapper .cf-modal-root {
    width: 1280px;
    max-width: 1280px;
    
    .loader-content {
      height: 853px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
