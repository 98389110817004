import { useState, type FC, useEffect } from 'react'
import { cnx } from '@carfluent/common'
import pDebounce from 'p-debounce'

import IconSVG from 'components/inlineImages'
import { scrollToElement } from 'utils/scrollTo'
import ProgressBarCircle from 'components/common/ProgressBarCircle'
import { type IntakeInspectionQuestionCategoriesDto } from 'api/types/responses'

import CLASS_NAME from './styles'

export interface NavigationItemProps extends IntakeInspectionQuestionCategoriesDto {
  pass: number
  fail: number
  comment: number
  percentage: number
}

interface NavigationBlockProps {
  blocks: NavigationItemProps[]
  isSubmitted: boolean
  shouldFilterQuestion: boolean
}

const NavigationBlock: FC<NavigationBlockProps> = ({ shouldFilterQuestion, blocks, isSubmitted }) => {
  const [activeId, setActiveId] = useState<number | string | null>(null)

  const scrollToSection = (sectionId: number): void => {
    scrollToElement(`#section-${sectionId}`)
    setActiveId(sectionId)
  }

  useEffect(() => {
    const container = document.getElementsByClassName('intake-inspection-list')[0]
    /**
     * OP-NOTE: use debounce since IntersectionObserver can run too often
     * to avoid unnecessary state's updates
     * */
    const debouncedSetActiveId = pDebounce(setActiveId, 10)

    const observer = new IntersectionObserver((entries) => {
      const visibleEntries = entries.filter(entry => entry.isIntersecting)

      if (visibleEntries.length > 0) {
        const mostVisibleEntry = visibleEntries.reduce((prev, current) => {
          return (prev.intersectionRatio > current.intersectionRatio) ? prev : current
        })

        const match = mostVisibleEntry.target.id.match(/(\d+)/)

        if (entries.length > 1 && match != null) {
          void debouncedSetActiveId(Number(match[1]))
        }
      }
    },
    {
      threshold: [...Array.from({ length: 101 }, (_, i) => i * 0.01), 1]
    }
    )
    const nodes = container.querySelectorAll('.inspection-list')
    nodes.forEach(node => observer.observe(node))

    return () => {
      nodes.forEach(node => observer.unobserve(node))
    }
    /**
     * OP-NOTE: since we have filter we need to update observer
     * each time user switch it, to correctly track the active scroll position
     * */
  }, [shouldFilterQuestion])

  useEffect(() => {
    if (activeId === null && blocks[0]?.id != null) {
      setActiveId(blocks[0].id)
    }
  }, [blocks])

  return (
    <ul className={CLASS_NAME}>
      {
        blocks.map(block => (
          <li className={cnx('navigation-block-container', activeId === block.id && 'active')} key={block.id} onClick={() => scrollToSection(block.id)}>
            <div>
              <div className='navigation-block-progress-bar'><ProgressBarCircle isCompleted={isSubmitted} progressPercentage={block.percentage} /></div>
              <div className='navigation-block-name'>{block.name}</div>
              <div className='navigation-block-intake-inspection'>
                <span><IconSVG.ThumbUp color='#ddd' /> {block.pass}</span>
                <span><IconSVG.Fail color='#ddd' /> {block.fail}</span>
                <span><IconSVG.Comment color='#ddd' /> {block.comment}</span>
              </div>
            </div>
          </li>
        ))
      }
    </ul>
  )
}

export default NavigationBlock
