import { useState } from 'react'
import { useRefUpdater } from '@carfluent/common'

import { type DealModel } from 'api/types/responses'
import { type EntityWithName } from 'components/common/FormFilesUpload'
import { type DocumentDetailsDto, FileType, DocumentsFolderName } from 'api/types'
import DocumentsApiProvider from 'api/documents.api'
import CustomersCoreApiProvider from 'api/customersCore.api'
import useCloudAccessToken from 'hooks/useCloudAccessToken'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

import { type UploadedDealDocument, getFileItem } from './utils'

export interface UseDealDocumentsProps {
  documents?: DocumentDetailsDto[]
  dealId: string | number
  onSaveChanges: (deal: DealModel) => void
  dealRowVersion?: string | null
}

interface UseDealDocumentsReturn {
  onChangeFiles: (_: string, files: EntityWithName[]) => Promise<void>
  error?: string
  onError: (error?: string) => void
  onClickFile: (id: number) => Promise<void>
  documents: UploadedDealDocument[]
  setDeleteFileId: (id: number | null) => void
  deleteFileId: number | null
  onSubmitRemoveFile: () => Promise<void>
}

export const MAX_FILE_NUMBER = 100
export const MAX_LIMIT_ERROR = '100 files maximum'

export const useDealDocuments = ({
  documents = [],
  dealId,
  onSaveChanges,
  dealRowVersion
}: UseDealDocumentsProps): UseDealDocumentsReturn => {
  const cloudAccessToken = useCloudAccessToken()
  const [error, setError] = useState<string | undefined>(undefined)
  const [uploadingData, setUploadingData] = useState<UploadedDealDocument[]>([])
  const [deleteFileId, setDeleteFileId] = useState<number | null>(null)
  const { showAlert } = useCustomSnackbar()

  const documentsRef = useRefUpdater(documents)
  const uploadingDataRef = useRefUpdater(uploadingData)

  const onChangeFiles = async (_: string, files: EntityWithName[]): Promise<void> => {
    const formData = new FormData()
    const _uploadingData: UploadedDealDocument[] = []
    let filesToUpload = [...files]

    // calculate and validate files length
    const existingDocs = documentsRef.current.length +
      uploadingDataRef.current.length

    const totalFileLength = filesToUpload.length + existingDocs

    if (totalFileLength > MAX_FILE_NUMBER) {
      filesToUpload = filesToUpload.slice(0, MAX_FILE_NUMBER - existingDocs)
      setError(MAX_LIMIT_ERROR)
    }

    if (filesToUpload.length === 0) {
      return
    }

    filesToUpload.forEach((file, i) => {
      formData.append(`request[${i}].File`, file as File)
      formData.append(`request[${i}].FolderName`, DocumentsFolderName.Other)
      formData.append(`request[${i}].FileTypeId`, FileType.Other)
      _uploadingData.push(getFileItem({ fileName: file.name }))
    })

    try {
      setUploadingData(prev => [..._uploadingData, ...prev])
      const { files } = await DocumentsApiProvider.postFiles(formData)
      const deal = await CustomersCoreApiProvider.postDocumentDeal({
        dealId,
        data: { documents: files, isClientArea: false },
        rowVersion: dealRowVersion
      })

      onSaveChanges(deal)
      showAlert('Documents uploaded successfully.', { variant: 'success' })
    } catch (e) {
      showAlert(e)
    } finally {
      setUploadingData((prev) =>
        prev.filter(({ id }) =>
          !_uploadingData.some((item) => item.id === id))
      )
    }
  }

  const onSubmitRemoveFile = async (): Promise<void> => {
    if (deleteFileId == null) {
      return
    }

    try {
      const deal = await CustomersCoreApiProvider.deleteDocumentDeal({
        dealId,
        data: { documentsIds: [deleteFileId] },
        rowVersion: dealRowVersion
      })

      onSaveChanges(deal)
      showAlert('The file has been deleted.', { variant: 'success' })
    } catch (e) {
      showAlert(e)
    }
  }

  const onClickFile = async (id: number): Promise<void> => {
    try {
      const file = await DocumentsApiProvider.getFile(id)
      window.open(`${file.uri}?${cloudAccessToken?.token ?? ''}`, '_blank')
    } catch (e) {
      showAlert(e)
    }
  }

  return {
    error,
    onClickFile,
    deleteFileId,
    onChangeFiles,
    setDeleteFileId,
    onError: setError,
    onSubmitRemoveFile,
    documents: [...uploadingData, ...documents]
  }
}
