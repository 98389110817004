
import { type FC } from 'react'
import { type IconProps } from 'types'

const IconSVGCheckedCircle: FC<IconProps> = ({ color = '#101010', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.0026 1.33594C4.3226 1.33594 1.33594 4.3226 1.33594 8.0026C1.33594 11.6826 4.3226 14.6693 8.0026 14.6693C11.6826 14.6693 14.6693 11.6826 14.6693 8.0026C14.6693 4.3226 11.6826 1.33594 8.0026 1.33594ZM6.66927 11.3373L3.33594 8.00396L4.27594 7.06396L6.66927 9.45063L11.7293 4.39062L12.6693 5.33729L6.66927 11.3373Z'
        fill={color}
      />
    </svg>
  )
}

export default IconSVGCheckedCircle
