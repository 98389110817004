import { makeStyles } from '@material-ui/core/styles'
import { Responsive } from 'constants/constants'

export const useStyles = makeStyles((theme) => ({
  typographyRoot: {
    fontSize: 34,

    [theme.breakpoints.down(Responsive.Mobile)]: {
      fontSize: 24
    }
  },
  titleContent: {
    position: 'relative',
    paddingRight: 40,

    '&.cf-no-icon-padding': {
      paddingRight: 0
    },

    '& p': {
      lineHeight: '36px'
    },

    [theme.breakpoints.down(Responsive.Mobile)]: {
      '&:after': {
        content: 'close-quote',
        position: 'absolute',
        height: 1,
        left: -20,
        right: -20,
        bottom: -20,
        backgroundColor: 'rgba(0, 0, 0, 0.24)'
      }
    }
  },
  closeButton: {
    position: 'absolute',
    top: '50%',
    right: -12,
    transform: 'translate(0, -50%)',
    color: '#000',
    width: 36,
    height: 36
  }
}))
