import { makeStyles } from '@material-ui/core/styles'
import { Responsive } from 'constants/constants'

export interface StylesConfig {
  maxWidth?: number
  contentPadding?: number | string
  titleBottomMargin?: number
  isClosable?: boolean
  withTitle?: boolean
}

export const useStyles = makeStyles(theme => ({
  root: {
    backdropFilter: 'blur(7px)',
    position: 'relative'
  },

  dialogPaper: ({ maxWidth = 774, isClosable }: StylesConfig) => ({
    borderRadius: 12,
    margin: 'auto',
    maxWidth,

    [theme.breakpoints.down((maxWidth ?? 0) + 20)]: {
      '&&': {
        maxWidth: 'calc(100% - 4vw)',
        margin: '2vw'
      }
    },

    [theme.breakpoints.down(isClosable === true ? 480 : 0)]: {
      '&&': {
        maxWidth: 'unset',
        width: '100vw',
        margin: '0 auto',
        height: '100vh',
        borderRadius: 0
      }
    }
  }),

  dialogTitle: ({ titleBottomMargin }: StylesConfig) => ({
    marginBottom: titleBottomMargin ?? 32,
    padding: '40px 40px 0',
    [theme.breakpoints.down(Responsive.Mobile)]: {
      padding: 20,
      marginBottom: titleBottomMargin ?? 24
    }
  }),

  dialogContent: ({ contentPadding, withTitle }: StylesConfig) => ({
    '&&': {
      padding: contentPadding != null ? contentPadding : 40,
      paddingTop: withTitle === true ? 0 : 40,

      [theme.breakpoints.down(Responsive.Mobile)]: {
        padding: contentPadding != null ? contentPadding : 20,
        paddingTop: withTitle === true ? 0 : 20
      }
    }
  })
}))
