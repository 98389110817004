import { type FC, type ReactNode, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { cnx } from '@carfluent/common'

import { type TransactionLineVendorDto } from 'api/types'
import VendorDetails from 'pages/accounting/VendorDetails'
import Dropdown, { DropdownProps } from 'components/form/components/dropdown'
import Icon from 'components/common/Icon'

import useVendorDropdown, { SpecialId } from './hook'
import CLASS_NAME from './styles'

export interface VendorDropdownProps extends DropdownProps<TransactionLineVendorDto, TransactionLineVendorDto | null> {
  onAddVendor: (value: TransactionLineVendorDto) => Promise<void>
}

const VendorDropdown: FC<VendorDropdownProps> = (props) => {
  const {
    onAddVendor,
    classes,
    renderOption,
    ...parentDropdownProps
  } = props

  const { dropdownProps, vendorModalProps } = useVendorDropdown(props)

  const vendorClasses = useMemo(() => ({
    ...classes,
    option: cnx(CLASS_NAME.vendorOptions, classes?.option)
  }), [classes])

  const renderVendorOption = useCallback((opt: TransactionLineVendorDto): ReactNode => {
    if (opt.id === SpecialId.AddVendor) {
      return (
        <div className='cf-button-option'>
          <Icon className='cf-plus-icon' name='plus' />
          <p className='cf-option'>{opt.name}</p>
        </div>
      )
    }

    if (renderOption == null) {
      return (<p className='cf-option'>{opt.name}</p>)
    }

    return renderOption(opt)
  }, [renderOption])

  return (
    <>
      <Dropdown
        {...parentDropdownProps}
        {...dropdownProps}
        classes={vendorClasses}
        renderOption={renderVendorOption}
      />

      <VendorDetails {...vendorModalProps} />
    </>
  )
}

export default observer(VendorDropdown)
