import type { FC } from 'react'
import { Banner, FormDatePicker } from '@carfluent/common'

import { DATE_MONTH_YEAR_FORMAT } from 'constants/constants'
import AddressFields from 'components/common/AddressFields'

import type { PreviousHousingProps } from '../../types'

const LENDERS_HOUSING_MESSAGE = 'Lenders typically require two years of housing history'

const PreviousHousing: FC<PreviousHousingProps> = ({
  isFormDisabled,
  pathPrefix,

  addressData,
  addressDataErrors,
  addressDataTouched,

  appartmentMoveInDate,
  appartmentMoveInDateErrors,
  appartmentMoveInDateTouched,
  onChange,
  onBlur
}) => {
  return (
    <div className='credit-app-section no-collapse prev-housing'>
      <Banner message={LENDERS_HOUSING_MESSAGE} />

      <AddressFields
        id={`${pathPrefix}.addressData`}
        values={addressData}
        errors={addressDataErrors}
        touched={addressDataTouched}
        disabled={isFormDisabled}
        useNormalAptKey
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormDatePicker
        id={`${pathPrefix}.apartmentMoveInDate`}
        label='Move-in date'
        value={appartmentMoveInDate}
        error={appartmentMoveInDateErrors}
        touched={appartmentMoveInDateTouched}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
        format={DATE_MONTH_YEAR_FORMAT}
      />
    </div>
  )
}

export default PreviousHousing
