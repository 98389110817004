import { isAxiosError } from 'axios'
import { ErrorCode } from 'api/types'

const isLockedLienholderError = (err: any): boolean => {
  if (isAxiosError(err)) {
    return err.response?.data?.code === ErrorCode.LockedLienholder
  }

  return false
}

export default isLockedLienholderError
