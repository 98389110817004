import type { FC } from 'react'
import { cn, CellWrapper } from '@carfluent/common'
import CLASS_NAME from './styles'

interface ReconDaysInStatusCellProps {
  getValue: () => {
    value: number
    status?: string
  }
}

const ReconDaysInStatusCell: FC<ReconDaysInStatusCellProps> = ({ getValue }) => {
  const { value, status } = getValue()

  return (
    <CellWrapper className={cn(CLASS_NAME, status)}>
      <p>{value}</p>
    </CellWrapper>
  )
}

export default ReconDaysInStatusCell
