import type { FC } from 'react'
import { FormInput, FormMaskedInput } from '@carfluent/common'
import { CHECKING_ACCOUNT_NUMBER_MASK } from 'constants/constants'
import type { BankDetailsSectionProps } from '../../types'

const BankDetails: FC<BankDetailsSectionProps> = ({
  values,
  errors,
  touched,
  isFormDisabled,
  onChange,
  onBlur
}) => {
  return (
    <div className='credit-app-section no-collapse bank-details'>
      <FormInput
        id='bankDetails.name'
        label='Bank name'
        value={values.name}
        error={errors?.name}
        touched={touched?.name}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormMaskedInput
        id='bankDetails.checkingAccountNumber'
        label='Checking account number'
        mask={CHECKING_ACCOUNT_NUMBER_MASK}
        disabled={isFormDisabled}
        value={values.checkingAccountNumber}
        error={errors?.checkingAccountNumber}
        touched={touched?.checkingAccountNumber}
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormInput
        id='bankDetails.contactName'
        label='Bank contact name'
        value={values.contactName}
        error={errors?.contactName}
        touched={touched?.contactName}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormMaskedInput
        id='bankDetails.contactPhone'
        label='Contact phone number'
        mask='phone'
        value={values.contactPhone}
        error={errors?.contactPhone}
        touched={touched?.contactPhone}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  )
}

export default BankDetails
