import { css } from '@emotion/css'

export default css(`
  width: 100%;
  border-radius: 12px;
  background: white;
  padding: 16px;

  .cf-item-value {
    line-height: 20px;
  }

  .cf-with-copy-button {
    display: grid;
    align-items: center;
    column-gap: 8px;
    height: 20px;
    grid-template-columns: repeat(3, max-content);

    .copy-button {
      margin-left: 0px;
    }
  }

  .cf-car-value-item {
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;
    column-gap: 8px;
    height: 20px;
  }

  .cf-vin {
    grid-area: vin;
  }

  .cf-stock {
    grid-area: stock;
  }

  .cf-mileage {
    grid-area: mileage;
  }

  .cf-dms {
    grid-area: dms;
  }

  .cf-dis {
    grid-area: dis;
  }

  .cf-dir {
    grid-area: dir;
  }
  
  .cf-car-info-panel {
    margin-bottom: 16px;
    display: flex;
    width: 100%;
  }
  
  .car-title-wrapper {
    width: max-content;
    position: relative;
    display: flex;
    width: 100%;

    .cf-tooltip-container {
      color: #101010;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      max-width: calc(90% - 32px);

      :has(.with-label) {
        max-width: calc(90% - 132px);
      }
    }
    
    .deposited-label, .complete-incomplete-label {
      margin-left: 8px;
    }
  }
  
  .cf-car-info-block {
    display: flex;
    max-width: 1024px;

    > div:first-of-type {
      height: 64px;
    }
  }
  
  .cf-car-info {
    display: grid;
    row-gap: 8px;
    grid-template-areas: "vin dms" "stock dir" "mileage dis";
    padding: 0px 0px 0px 24px;
    grid-template-rows: 20px 20px;
    grid-template-columns: minmax(230px, 1fr) 1fr 1fr;
    column-gap: 24px;
    width: 100%;
   
    .cf-item-label {
      font-size: 14px;
      color: #21212166;
      min-width: 50px;
    }

    .cf-dis, .cf-dir {
      .cf-item-label {
        min-width: 35px;
      }

      .cf-item-value {
        margin: 0px auto;
      }
    }

    .cf-item-value {
      font-size: 14px;
      color: #101010;

      a {
        color: #458FFF;
      }
    }
  }

  .cf-dms {
    .cf-item-label {
      min-width: 35px;
    }

    .cf-item-value {
      margin: 0px auto;
    }
  }

  @media (min-width: 1280px) {
    .cf-car-info {
      grid-template-areas: "vin mileage dir" "stock dms dis";
      padding: 8px 0px 8px 24px;
    
      .cf-dms .cf-item-label {
        min-width: unset;
      }

      .cf-dms .cf-item-label {
        min-width: 50px;
      }

      .cf-dis, .cf-dir, .cf-dms {
        .cf-item-value {
          margin: 0px;
        }
      }
    }
  }
`)

const MARGIN_LEFT = 8

export const getTruncatedClassName = (width: number | null): string => css(`
  white-space: nowrap;
  max-width: calc(100% - ${MARGIN_LEFT}px - ${width ?? 0}px);
  margin-left: ${MARGIN_LEFT}px;

  .badge {
    margin-top: 0;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .cf-popover {
    display: flex;
    flex-wrap: wrap;

    .badge {
      margin-top: 6px;
    }
  }
`)
