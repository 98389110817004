import type { FC } from 'react'
import { CellWrapper, formatCurrencyDecimal } from '@carfluent/common'

import { joinPartsBySpace } from 'utils/view_helper'
import TooltipedText from 'components/common/TooltipedText'
import CarImage from 'components/common/CarImage'
import CLASS_NAME from './styles'

export interface VehicleCellProps {
  getValue: () => VehicleData
}

export interface VehicleData {
  imageUrl: string | null
  model: string | null
  price: number | null
  year: number | null
  isDeposited: boolean
  isCarDeleted: boolean
}

const VehicleCell: FC<VehicleCellProps> = ({ getValue }) => {
  const {
    imageUrl,
    model,
    price,
    year,
    isDeposited,
    isCarDeleted
  } = getValue()

  const tooltipText = isCarDeleted ? 'Car was deleted.' : 'Car was deposited by another customer.'

  return (
    <CellWrapper className={CLASS_NAME}>
      <div className='vehicle-img-block'>
        <CarImage
          className='vehicle-img'
          height={52}
          vehicleImageUrl={imageUrl}
          width={80}
          isDeposited={isDeposited}
          isCarDeleted={isCarDeleted}
          iconWidth={20}
          iconHeight={20}
          tooltipTitle={tooltipText}
        />
      </div>

      <div className='vehicle-text-block'>
        {((model != null) || (year != null)) && (
          <TooltipedText value={joinPartsBySpace(year, model)} />
        )}

        {(price != null) && (
          <TooltipedText
            className='bold-text'
            value={formatCurrencyDecimal(price)}
          />
        )}
      </div>
    </CellWrapper>
  )
}

export default VehicleCell
