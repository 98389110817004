import type { FC } from 'react'
import { Button } from '@carfluent/common'

import OverlayedPageHOC from 'components/PageOverlay/OverlayedPageHOC'
import BackToTop from 'components/common/BackToTop'
import Table from 'components/common/Table'
import FiltersPanel from 'components/common/FiltersPanel'

import useMessageTemplatesList from './hook'

import CLASS_NAME from './styles'

const MessageTemplatesList: FC = () => {
  const {
    isLoading,
    isTemplateAdding,
    rows,
    columns,
    emptyTableMessage,
    search,
    sorting,
    onAddNewTemplate,
    onOpenMessageTemplateDetails,
    onSearchChange,
    onBottomReached,
    onSearch
  } = useMessageTemplatesList()

  return (
    <div className={CLASS_NAME}>
      <FiltersPanel
        title='Message templates'
        searchProps={{
          isLoading,
          onChange: onSearchChange,
          value: search,
          placeholder: 'Search by name',
          withDeleteIcon: true,
          onSearch
        }}
        renderActions={() => (
          <Button
            onClick={onAddNewTemplate}
            isLoading={isTemplateAdding}
          >
            NEW TEMPLATE
          </Button>
        )}
      />

      <Table
        columns={columns}
        data={rows}
        emptyTableMessage={emptyTableMessage}
        isLoading={isLoading}
        sorting={sorting}
        onBottomReached={onBottomReached}
        onRowClick={onOpenMessageTemplateDetails}
        headerDataTestId='message-templates-table-header'
        bodyDataTestId='message-templates-table-body'
      />

      <BackToTop />
    </div>
  )
}

export default OverlayedPageHOC(MessageTemplatesList)
