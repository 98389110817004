import { css } from '@emotion/css'

export default css(`
  padding-bottom: 60px;
  background-color: #f3f3f3;

  .cf-no-data-block {
    display: flex;
    padding: 16px;
    background: #fff;
    justify-content: center;
    border-top: 8px solid #F3F3F3;
  }

  > .cf-loader {
    position: absolute;
    inset: 0px;
    inset-inline: 0%;
    // important -> to "hover" labels inside inputs
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.75);
  }

  .header-block {
    gap: 16px 0;
    width: 100%;
    display: flex;
    margin: 0 0 16px 0;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;

    .cf-button-variant-text {
      text-decoration: none;

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-align: right;
        letter-spacing: 1.25px;
      }
    }
  }

  .left-panel {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .filters-block {
    margin-right: 24px;
  }

  .search-block {
    max-width: 376px;
    width: 100%;
  }

  .btn-new-item {
    width: 150px;
    height: 56px;
  }

  .cf-table-header-title p {
    font-weight: 600;
    color: #101010;
  }

  .empty-table-class-name {
    width: 100%;
    height: 64px;
    display: flex;
    background: #fff;
    align-items: center;
    justify-content: center;

    span {
      color: #101010;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.15px;
    }
  }
`)
