import type { FC, ReactNode } from 'react'
import Typography from '@material-ui/core/Typography'

import { UI } from '@carfluent/common'

export interface ValueCellProps {
  getValue: () => ReactNode
}

const { CellWrapper } = UI

const ValueCell: FC<ValueCellProps> = (props) => {
  return (
    <CellWrapper>
      <Typography>{props.getValue() != null && (props.getValue() ?? '-')}</Typography>
    </CellWrapper>
  )
}

export default ValueCell
