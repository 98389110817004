import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  actionBlock: {
    background: 'transparent',
    padding: '8px 0 40px 0',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
})
