import type { FormValidation } from '@carfluent/common'

import { requiredField, startDateNotPast } from 'utils/validationPresets'
import { SendScheduleType } from 'api/types/responses'
import { type ReviewFormData } from './types'

export const DEPENDENT_VALIDATIONS = {
  type: ['date', 'time']
}

export const validationRules: FormValidation<ReviewFormData> = {
  date: (value: Date | null, ctx?: ReviewFormData): string | null => {
    return ctx?.type === SendScheduleType.Schedule
      ? startDateNotPast(true)(value)
      : null
  },
  time: (value: string, ctx?: ReviewFormData): string | null => {
    return ctx?.type === SendScheduleType.Schedule
      ? requiredField(value)
      : null
  }
}
