import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  
  .cf-files-section {
    display: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    &.is-visible {
      display: block;
    }

    .cf-file-error-message {
      height: 16px;
      padding: 0px 16px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #B00020;

      &.with-content {
        margin-top: 4px;
        margin-bottom: 8px;
      }
    }

    .file-zone {
      background-color: #fff;
      padding: 0px 16px;
      display: flex;
      flex-wrap: wrap;
      
      .cf-file-thumbnail {
        display: inline-block;
        position: relative;
        width: 48px;
        height: 48px;
        margin-right: 16px;
        margin-top: 16px;
          
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }

        > button {
          width: 16px;
          height: 16px;
          border: 1px solid #fff;
          background-color: #000;
          position: absolute;
          top: 0px;
          right: 0px;
          z-index: 10;
          transform: translate(50%, -50%);

          svg {
            width: 12px;
            height: 12px;
            min-width: 12px;

            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
  
  textarea {
    padding-right: 112px;
    padding-left: 48px;
    background: #fff;
    
    &::placeholder {
      color: rgba(33, 33, 33, 0.4);
    }
  }
  
  .cf-textarea-section {
    position: relative;

    .clip-icon {
      left: 0px;
    }

    .templates-icon {
      right: 50px;

      &.disabled {
        cursor: default;
      }
    }

    .send-message-icon {
      right: 0px;

      &.disabled {
        cursor: default;
        pointer-events: none;
      }
    }

    .vertical-line {
      position: absolute;
      top: 0px;
      right: 38px;
      border: none;
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      height: 24px;
      width: 1px;
      margin: 16px 12px;
      padding-top: 14px;
    }
  }

  .clip-icon, .templates-icon, .send-message-icon {
    position: absolute;
    top: 0px;
    padding: 16px 12px;
    padding-top: 14px;
    height: 56px;
    cursor: pointer;
  }

  .cf-max-symbols-error {
    width: 380px;
    background: #FFFFFF;
    z-index: 2;
    margin-top: -24px;
    padding: 4px 16px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 8px;
    color: #B00020;
    user-select: none;
  }

  .cf-attach-files-content {
    &.is-disabled {
      cursor: default;

      svg path {
        fill: rgba(33, 33, 33, 0.6);
      }
    }
  }
`)

export const POPOVER_CLASS_NAME = css(`
  &.cf-popover-container {
    display: block;
    align-items: flex-start;
    height: 28px;
    background-color: #212121;
    padding: 4px 12px;
    color: #fff;
    z-index: 150000;
    border-radius: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`)
