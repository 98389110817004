import { css } from '@emotion/css'

export default css(`
  &.campaign-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 80px;
  }

  .container {
    display: flex;
    gap: 16px;

    .inputs-wrapper, .letter-preview {
      flex: 1;
    }
  }

  .inputs-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

  }

  .letter-preview {
    border-radius: 12px;
    background: #F7FAFF;
    overflow: hidden;
  }

  .skeleton-item {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 9px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    &.active-item {
      background: #FFF;
    }

    > div {
      min-width: 80px;

      &:nth-child(2) {
        width: 80px;
      }

      &:nth-child(3) {
        width: 120px;
      }

      &:last-child {
        width: 182px;
      }
      
      > p {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(33, 33, 33, 0.50);

        &.pre-header {
          font-weight: 400;
        }
  
        &.active {
          color: #101010;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .input-container {
    position: relative;
    .label {
      position: absolute;
      bottom: -20px;
      left: 16px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: rgba(33, 33, 33, 0.50);
      transition: all 0.3s;
    }
  }

  .campaign-content-editor {
    --editorContentMinHeight: 302px;
  }
`)
