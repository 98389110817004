import type { MessageTemplateFormData, MessageTemplatePayload } from './types'

export const getDefaultFormData = (): MessageTemplateFormData => ({
  name: '',
  body: null
})

export const getDefaultMessageTemplatePayloadData = (): MessageTemplatePayload => ({
  id: 0,
  name: '',
  body: null,
  dealerId: 0
})

export enum FieldIds {
  Name = 'name',
  Body = 'body'
}

export enum Messages {
  SuccessUpdate = 'Changes successfully saved.',
  FailAddSnippet = 'Message is too long, can’t add snippet.'
}

export const MAX_NAME_SYMBOLS_LENGTH = 100
export const MAX_BODY_SYMBOLS_LENGTH = 600
export const BODY_LINE_HEIGHT = 16

export const ADD_SNIPPET_LABEL = 'ADD SNIPPET'

export const TEMPLATE_IN_USE_CODE = '10001'
