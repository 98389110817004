import { useCallback, useState } from 'react'
import { useMediator } from '@carfluent/common'
import Events from 'constants/events'
import type { UseBankStatementsListReturn } from './types'

const useBankStatementsList = (): UseBankStatementsListReturn => {
  const [activeAccountId, setActiveAccountId] = useState<number | null>(null)
  const [isFiltersChanged, setIsFiltersChanged] = useState<boolean>(false)
  const { send } = useMediator()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onSelectAccount = useCallback((accountId: number | null) => {
    setActiveAccountId(accountId)
    setIsFiltersChanged(false)
  }, [])

  const onExcludeBankStatementRow = useCallback(() => {
    void send(Events.BankStatementsDecreaseTabCounter, null)
  }, [send])

  const onUndoExcludeClick = useCallback(() => {
    void send(Events.BankStatementsIncreaseTabCounter, null)
  }, [send])

  // ========================================== //

  return {
    activeAccountId,
    isFiltersChanged,
    onCategorizeBankStatementRow: onExcludeBankStatementRow, // identical post-processing, for now
    onExcludeBankStatementRow,
    onUndoExcludeClick,
    onSelectAccount,
    setIsFiltersChanged
  }
}

export default useBankStatementsList
