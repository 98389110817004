import type { FC } from 'react'
import type { TooltipProps } from 'recharts'

import CLASS_NAME from './styles'
import { formatUTCDateTimeForView } from 'utils/view_helper'

const DATE_PART = 'MM/dd/yy'

interface PriceListProps {
  name: string
  prices: number[]
}

const PriceList: FC<PriceListProps> = ({ prices, name }) => (
  <ul className='price-list'>
    {prices.map((price, i) => (
      <li key={i}>
        <p>
          {prices.length > 1 ? `${name} ${i + 1}` : name}: <span className='bold-text'>${price.toLocaleString()}</span>
        </p>
      </li>
    ))}
  </ul>
)

interface CustomTooltipProps extends TooltipProps<number, string> {}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active == null || (payload == null) || payload.length === 0) {
    return null
  }

  return (
    <div className={CLASS_NAME}>
      <p className='bold-text'>{formatUTCDateTimeForView(label, DATE_PART)}</p>
      {payload.map((entry, i) => (
        entry.name === 'Price'
          ? (
            <PriceList key={i} prices={entry.payload.prices} name={entry.name} />
            )
          : (
            <p key={i}>
              Inquiries: <span className='bold-text'>{entry.value}</span>
            </p>
            )
      ))}
    </div>
  )
}

export default CustomTooltip
