import { css } from '@emotion/css'

export default css(`
  &.MuiFormControl-root {
    .cf-disabled {
      .MuiInputLabel-root {
        color: rgba(33, 33, 33, 0.4);
      } 
    }
  }
`)
