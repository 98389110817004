import type { UpdateDealerAccountPayload } from 'api/types'
import type { UpdateDealerAccountFormData } from './types'

const serializeData = ({
  dealerFirstName,
  dealerLastName,
  roleId,
  dealerIds,
  phoneNumber
}: UpdateDealerAccountFormData): UpdateDealerAccountPayload => ({
  dealerFirstName: dealerFirstName ?? '',
  dealerLastName: dealerLastName ?? '',
  roleId: Number(roleId),
  dealerIds: dealerIds ?? [],
  phoneNumber: phoneNumber ?? null
})

export default serializeData
