import { UI } from '@carfluent/common'

import type { ReportLeadBySourceItem } from 'api/types'

import CLASS_NAME from './styles'

const GroupContent = UI.GroupContentHOC<ReportLeadBySourceItem, string>((group) => {
  return (
    <div className={CLASS_NAME}>
      <p className='group-name'>{group.meta} source</p>
      <p className='text-counter'>
        ({group.amount})
      </p>
    </div>
  )
})

export default GroupContent
