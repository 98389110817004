import type { FC } from 'react'
import { Typography } from '@material-ui/core'
import { formatters, UI, cn } from '@carfluent/common'
import type { CellProps, KeyVal } from 'types'

const { CellWrapper } = UI
const { formatDecimal } = formatters

export interface CurrencyCellProps extends CellProps<KeyVal> {
  isWrong: boolean
}

const CurrencyCell: FC<CurrencyCellProps> = ({ getValue, isWrong }) => {
  const value = getValue() as number | string | null

  return (
    <CellWrapper className={cn(isWrong && 'cf-wrong-cell')}>
      {(value != null) && (
        <Typography>${formatDecimal(value, '0.00')}</Typography>
      )}
    </CellWrapper>
  )
}

export default CurrencyCell
