import { toDateOrNull } from 'utils/parse_date'
import { type CostDetail } from 'api/types'
import { type CostDetailsListItem } from './types'

export const parseData = (data: CostDetail[]): CostDetailsListItem[] =>
  data.map(({
    dateTime,
    amount,
    description,
    id,
    costTypeId,
    transactionTypeId,
    referenceNumber
  }) => ({
    description: {
      title: referenceNumber,
      text: description
    },
    amount,
    date: toDateOrNull(dateTime),
    id,
    costTypeId,
    transactionTypeId
  }))
