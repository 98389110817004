import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getEnhancedResultHandler, useForm, useModal } from '@carfluent/common'

import ReconAPIProvider from 'api/recon.api'
import { downloadBlob, parseFileNameFromContentDisposition } from 'utils/general'
import useAsyncEffect from 'hooks/useAsyncEffect'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import type { ActionOptions } from 'components/common/ActionsMenu'
import type { FilterItem, FilterType } from 'components/common/FiltersPanel'
import { ReconInspectionStates, type VehicleInspectionQuestionPayload } from 'api/types'

import validationRules from './validator'
import type { NavigationItemProps } from '../components/NavigationBlock'
import { parseNavigationSection, parseQuestionsSections } from './parser'
import { GENERAL_BLOCK_DATA_DEFAULT } from './constants'
import { type SectionProps, type UseIntakeInspectionsListReturn } from './types'
import { getIntakeInspectionData, getSubmittedStatusLabel, updateIntakeInspectionDate, updateNavigationData } from './utils'

const BASE_VALUES: SectionProps[] = []

const useIntakeInspectionsList = (): UseIntakeInspectionsListReturn => {
  const navigate = useNavigate()
  const { showAlert } = useCustomSnackbar()
  const { id: vehicleId = '' } = useParams()
  const { isModalOpen: isResetModalOpen, onOpenModal: onOpenResetModal, onCloseModal: onCloseResetModal } = useModal()
  const { isModalOpen: isUnlockModalOpen, onOpenModal: onOpenUnlockModal, onCloseModal: onCloseUnlockModal } = useModal()

  const [pageStatus, setPageStatus] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [inspectionId, setInspectionId] = useState<number | string>('')
  const [shouldFilterQuestion, setShouldFilterQuestion] = useState(false)
  const [navigationData, setNavigationData] = useState<NavigationItemProps[]>([])
  const [generalBlockData, setGeneralBlockData] = useState(GENERAL_BLOCK_DATA_DEFAULT)

  const onExportExcel = useCallback(async () => {
    try {
      const response = await ReconAPIProvider.downloadVehicleInspectionsReportXlsx(inspectionId)
      const fileName = parseFileNameFromContentDisposition(response.headers['content-disposition'])

      downloadBlob(response.data, fileName)
    } catch (err) {
      showAlert(err)
    }
  }, [inspectionId])

  const topMenuActions: ActionOptions[] = useMemo(() => {
    return ([
      {
        title: 'Export XLS',
        handleOnClick: onExportExcel
      },
      {
        title: 'Reset all',
        placement: 'top-end',
        optionType: 'highlighted',
        handleOnClick: onOpenResetModal,
        disabled: generalBlockData.isCompleted,
        tooltip: generalBlockData.isCompleted ? 'Submitted Intake Inspection cannot be reset.' : ''
      }
    ])
  }, [generalBlockData.isCompleted, onExportExcel, onOpenResetModal])

  const filterItems: Array<FilterItem<FilterType, any>> = useMemo(() => [{
    filterType: 'checkbox',
    componentProps: {
      label: 'Failed items only',
      onChange: setShouldFilterQuestion,
      defaultValue: shouldFilterQuestion
    }
  }], [shouldFilterQuestion])

  const onGoBack = (): void => navigate(-1)

  const onVehicleInspectionSubmit = async (): Promise<void> => {
    setIsSubmitting(true)
    await ReconAPIProvider.submitVehicleInspection(inspectionId)
  }

  const _onActionResult = useCallback(() => {
    navigate(-1)
    setIsSubmitting(false)
  }, [navigate])

  const onActionResult = useMemo(() => {
    return getEnhancedResultHandler<SectionProps[], {}>(_onActionResult, showAlert)
  }, [_onActionResult, showAlert])

  const {
    values,
    errors,
    touched,
    onSubmit,
    resetForm,
    setValues
  } = useForm<SectionProps[]>({
    onActionResult,
    validationRules,
    baseValues: BASE_VALUES,
    submitAction: onVehicleInspectionSubmit
  })

  const data = useMemo(() => {
    return getIntakeInspectionData(values, shouldFilterQuestion)
  }, [values, shouldFilterQuestion])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const resetVehicleInspection = async (): Promise<void> => {
    await ReconAPIProvider.resetVehicleInspection(inspectionId)
    await onInitInspection()

    try {
      await ReconAPIProvider.unlockVehicleInspection(inspectionId)
      await onInitInspection()
      showAlert('All items reset successfully.', { variant: 'success' })
    } catch (err) {
      showAlert(err)
    } finally {
      onCloseResetModal()
    }
  }

  const unlockVehicleInspection = async (): Promise<void> => {
    try {
      await ReconAPIProvider.unlockVehicleInspection(inspectionId)
      await onInitInspection()
      showAlert('Intake inspection successfully unlocked.', { variant: 'success' })
    } catch (err) {
      showAlert(err)
    } finally {
      onCloseUnlockModal()
    }
  }

  const onQuestionUpdate = async ({
    data,
    questionId,
    questionCategoryId
  }: {
    questionId: number | string
    questionCategoryId: number | string
    data: VehicleInspectionQuestionPayload
  }): Promise<void> => {
    setGeneralBlockData({
      ...generalBlockData,
      isUpdating: true
    })

    const updatedIntakeInspectionData = updateIntakeInspectionDate(values, data, questionId, questionCategoryId)

    setValues(updatedIntakeInspectionData)
    setNavigationData(updateNavigationData(navigationData, updatedIntakeInspectionData))

    await ReconAPIProvider.updateVehicleInspectionQuestion({
      data,
      questionId,
      inspectionId
    })

    const updatedGeneralBlockData = await ReconAPIProvider.summaryVehicleInspection(vehicleId)

    setGeneralBlockData({
      ...updatedGeneralBlockData,
      isLoading: false,
      isUpdating: false,
      isCompleted: false,
      statusInfo: 'In progress'
    })
  }

  const onInitInspection = useCallback(async (): Promise<void> => {
    const [{ items: inspectionStates }, { items: questionCategories }, intakeInspection, generalBlockData] = await Promise.all([
      ReconAPIProvider.vehicleInspectionStates(),
      ReconAPIProvider.getQuestionCategories(),
      ReconAPIProvider.getVehicleIntakeInspection(vehicleId),
      ReconAPIProvider.summaryVehicleInspection(vehicleId)
    ])
    const isCompleted = inspectionStates.find(el => el.id === intakeInspection?.inspectionStateId)?.name === ReconInspectionStates.Submitted
    setGeneralBlockData({
      isCompleted,
      isLoading: false,
      isUpdating: false,
      ...generalBlockData,
      statusInfo: isCompleted ? 'Submitted' : 'In progress'
    })
    setPageStatus(
      isCompleted
        ? getSubmittedStatusLabel(generalBlockData.submittedDateTime, generalBlockData.submittedByUser)
        : '')
    setInspectionId(intakeInspection.id)
    resetForm(parseQuestionsSections(questionCategories, intakeInspection))
    setNavigationData(parseNavigationSection(questionCategories, intakeInspection))
    setIsLoading(false)
  }, [])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useAsyncEffect(async () => {
    await onInitInspection()
  }, [onInitInspection])

  // ========================================== //

  return {
    data,
    onGoBack,
    isLoading,
    pageStatus,
    filterItems,
    topMenuActions,
    navigationData,
    onQuestionUpdate,
    listValidation: {
      errors,
      touched
    },
    generalBlockData: {
      ...generalBlockData
    },
    shouldFilterQuestion,
    resetModalProps: {
      isOpen: isResetModalOpen,
      onClose: onCloseResetModal,
      onConfirm: resetVehicleInspection
    },
    unlockModalProps: {
      isOpen: isUnlockModalOpen,
      onClose: onCloseUnlockModal,
      onConfirm: unlockVehicleInspection
    },
    bottomPanelProps: {
      isSubmitting: isSubmitting || isLoading,
      titleSaveButton: generalBlockData.isCompleted ? 'UNLOCK' : 'SUBMIT',
      handleSubmit: generalBlockData.isCompleted ? onOpenUnlockModal : onSubmit
    }
  }
}

export default useIntakeInspectionsList
