import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import CRMApiProvider from 'api/crm.api'
import { DictionaryItem, PaginatedResult } from 'api/types'
import { type CampaignListRowModel, type CampaignTotalDto } from 'api/types/responses'
import { type GetCampaignsListDto } from 'api/types/requests'
import { Notifications } from 'constants/names'
import { Routes, CRMMarketingCampaignDetails } from 'constants/route_helper'
import useTableApi from 'hooks/useTableApi'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { WebSocketsCrm as ws } from 'services/web_sockets'
import { calcTableHeight } from 'utils/math'
import { TABLE_MIN_HEIGHT } from 'constants/constants'

import type { CampaignRow, UseMarketingCampaignListReturn, CampaignListFilters } from './types'
import { CANCEL_LOAD_DATA, EmptyTableMsg, DEFAULT_SORTING, getDefaultCounters } from './constants'
import columnDefinitions from './columns'

const DEFAULT_COUNTERS = getDefaultCounters()

const useMarketingCampaignList = (): UseMarketingCampaignListReturn => {
  const { showAlert } = useCustomSnackbar()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [isCountersLoading, setIsCountersLoading] = useState(false)
  const [counters, setCounters] = useState<CampaignTotalDto>(DEFAULT_COUNTERS)
  const [statuses, setStatuses] = useState<DictionaryItem[]>([])
  const [isFiltersChange, setFiltersChange] = useState(false)
  const [selectedStatuses, setSelectedStatuses] = useState<DictionaryItem[]>([])

  const getCampaignList = useCallback(async (payload: GetCampaignsListDto): Promise<PaginatedResult<CampaignListRowModel>> => {
    try {
      setFiltersChange(true)
      setIsCountersLoading(true)
      const [countersResp, listResp] = await Promise.all([
        CRMApiProvider.getCampaignTotal(payload.search ?? ''),
        CRMApiProvider.getCampaignList({ ...payload })
      ])
      setCounters(countersResp)
      setStatuses(listResp.statusList)

      return { items: listResp.campaigns, count: 0 }
    } catch (err) {
      setCounters(DEFAULT_COUNTERS)
      return { items: [], count: 0 }
    } finally {
      setFiltersChange(false)
      setIsCountersLoading(false)
    }
  }, [])

  const {
    isLoading,
    rows,
    search,
    sorting,
    emptyTableMessage,
    loadRows,
    setSearch,
    onSearch,
    setFilters,
    setSorting
  } = useTableApi<CampaignListRowModel, CampaignListFilters, CampaignListRowModel>({
    defaultFilters: { campaignStatuses: statuses.map(el => el.id) ?? [] },
    defaultSorting: DEFAULT_SORTING,
    getList: getCampaignList,
    nothingFoundMessage: EmptyTableMsg.NoItemsFound,
    emptyTableMessage: EmptyTableMsg.NoItems,
    cancelationOptions: CANCEL_LOAD_DATA
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onAddNewCampaign = useCallback((): void => {
    navigate(Routes.CRMNewMarketingCampaign)
  }, [navigate, showAlert])

  const onOpenCampaignDetails = useCallback((row?: CampaignRow) => {
    const id = row?.original.id as number
    navigate(CRMMarketingCampaignDetails(id))
  }, [navigate])

  const onBottomReached = useCallback(async () => {
    if (!isLoading && TABLE_MIN_HEIGHT <= calcTableHeight(rows.length)) {
      await loadRows({ forceRefresh: false })
    }
  }, [isLoading, loadRows, rows.length])

  const onStatusFilterChange = useCallback(async (_: string, statusIds: DictionaryItem[] | null): Promise<void> => {
    setSelectedStatuses(statusIds ?? [])
    void setFilters({ campaignStatuses: statusIds?.map(el => el.id) ?? [] })
  }, [setFilters])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (statuses.length !== 0) {
      setSelectedStatuses(statuses)
    }
  }, [statuses])

  useEffect(() => {
    if (pathname === Routes.CRMMarketingCampaigns) {
      void loadRows()
    }
  }, [pathname, loadRows])

  const throttledGroupId = 'campaignListUpdate'

  useEffect(() => {
    const onCampaignCreatedHandler = { name: 'campaign_CampaignCreated', action: loadRows }
    const onCampaignUpdatedHandler = { name: 'campaign_CampaignUpdated', action: loadRows }
    const onCampaignDeletedHandler = { name: 'campaign_CampaignDeleted', action: loadRows }
    // const onLeadUpdateHandler = { name: 'leads_leadUpdatedAction', action: loadRows }

    ws.on(Notifications.CampaignCreated, onCampaignCreatedHandler, { throttledGroupId })
    ws.on(Notifications.CampaignUpdated, onCampaignUpdatedHandler, { throttledGroupId })
    ws.on(Notifications.CampaignDeleted, onCampaignDeletedHandler, { throttledGroupId })

    return () => {
      ws.off(Notifications.CampaignCreated, onCampaignCreatedHandler.name)
      ws.off(Notifications.CampaignUpdated, onCampaignUpdatedHandler.name)
      ws.off(Notifications.CampaignDeleted, onCampaignDeletedHandler.name)
    }
  }, [loadRows])

  // ========================================== //

  return {
    isLoading,
    emptyTableMessage,
    rows,
    columns: columnDefinitions,
    search,
    counters,
    isFiltersChange,
    isCountersLoading,
    statusList: statuses,
    selectedStatuses,
    sorting,
    onStatusFilterChange,
    onAddNewCampaign,
    onOpenCampaignDetails,
    onSearchChange: setSearch,
    onBottomReached,
    onSearch,
    setSorting
  }
}

export default useMarketingCampaignList
