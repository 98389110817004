import { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm, isOk, type UseFormReturn, type FormValidation, type Result } from '@carfluent/common'
import axios from 'axios'
import queryString from 'query-string'

import { UserRoles, type ResetPassword } from 'api/types'
import { type KeyVal, type PickedFormDataProps } from 'types'
import IdentityApiProvider from 'api/identity.api'
import { AuthInstance } from 'store/auth'
import { navigateToUserDefaultRoute } from 'pages/auth/utils'
import { passwordResetField } from 'utils/validationPresets'

export interface NewPasswordForm {
  password: string
}

export interface UseNewPasswordReturn extends Pick<UseFormReturn<NewPasswordForm>, PickedFormDataProps | 'onSubmit' | 'isSubmitting'> {
  apiErrors: KeyVal | null
  showPassword: boolean
  toggleShowHidePassword: () => void
}

const baseValues = {
  password: ''
}

const validationRules: FormValidation<NewPasswordForm> = {
  password: passwordResetField(true)
}

const useNewPassword = (): UseNewPasswordReturn => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { email, token } = queryString.parse(search)

  const [apiErrors, setAPIErrors] = useState<KeyVal | null>(null)
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowHidePassword = (): void => {
    setShowPassword(prev => !prev)
  }

  const onActionResult = useCallback((res: Result<UserRoles[]>) => {
    if (isOk<UserRoles[]>(res)) {
      navigateToUserDefaultRoute(res.result, navigate)
    } else if (axios.isAxiosError(res.result)) {
      setAPIErrors(res.result?.response?.data ?? null)
    }
  }, [navigate])

  const submitAction = useCallback(async (values: NewPasswordForm): Promise<UserRoles[] | undefined> => {
    setAPIErrors(null)

    const payload: ResetPassword = {
      email: email as string,
      password: values.password,
      token: token as string
    }

    await IdentityApiProvider.resetPassword(payload)

    const loginPayload = {
      username: email as string,
      password: values.password
    }

    return await AuthInstance.loginAction(loginPayload)
  }, [])

  const {
    isSubmitting,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onSubmit
  } = useForm<NewPasswordForm>({
    baseValues,
    validationRules,
    submitAction,
    onActionResult
  })

  return {
    isSubmitting,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onSubmit,
    apiErrors,
    showPassword,
    toggleShowHidePassword
  }
}

export default useNewPassword
