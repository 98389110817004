import {
  AccountingTypesResponse,
  TransactionDetails,
  OpenBalancesStatistics,
  OpenBalancesListWithStatistics,
  OpeningBalance
} from 'api/types/accounting.types'

import {
  type AccountingCategoriesDto
} from 'api/types/responses'

import def from 'utils/def'

export const GET_DEFAULT_TRANSACTION = (): TransactionDetails => ({
  amount: 0,
  date: def('dateString'),
  dueDate: def('dateString'),
  dealId: null,
  description: '',
  id: 0,
  lines: [],
  referenceNumber: '',
  transactionTypeId: 0,
  memo: null,
  isLocked: false,
  isVehiclePayment: false,
  checkNumber: null,
  isSystemCreated: false,
  transactionStateId: 0
})

export const GET_DEFAULT_OPEN_BALANCES_STATISTICS = (): OpenBalancesStatistics => {
  return new OpenBalancesStatistics()
}

export const GET_DEFAULT_OPEN_BALANCES_WITH_STATISTICS = (): OpenBalancesListWithStatistics => {
  return new OpenBalancesListWithStatistics()
}

export const GET_DEFAULT_OPENING_BALANCE = (): OpeningBalance => {
  return new OpeningBalance()
}

export const GET_DEFAULT_ACCOUNTING_CATEGORIES = (): AccountingCategoriesDto => ({
  items: []
})

export const GET_DEFAULT_ACCOUNTING_TYPES = (): AccountingTypesResponse => ({
  items: []
})
