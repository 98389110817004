import type { FC } from 'react'

import { LogType } from 'pages/crm/LeadDetailsView/hook/types'
import callLogIcon from 'assets/activity-call.svg'
import visitLogIcon from 'assets/activity-visit.svg'
import otherLogIcon from 'assets/activity-other.svg'

import CLASS_NAME from './styes'

export type LeadActivityType = 'reminder' | 'appointment' | 'follow-up'

export interface LogTitleProps {
  logTypeId: LogType
}

const iconMap: Record<number, string> = {
  1: callLogIcon,
  2: visitLogIcon,
  3: otherLogIcon
}

const TaskTitle: FC<LogTitleProps> = ({
  logTypeId
}) => {
  return (
    <div className={CLASS_NAME}>
      <img src={iconMap[logTypeId]} alt='' />
      <label>{LogType[logTypeId]} - Log task</label>
    </div>
  )
}

export default TaskTitle
