import {
  type FC,
  type ReactNode,
  useMemo
} from 'react'
import { cnx } from '@carfluent/common'

import { createStyleClass } from './styles'

export interface FIProductCardProps {
  headerContent: ReactNode
  hasPadding?: boolean
  color?: string
  isButtonDisabled?: boolean
}

const FIProductCard: FC<FIProductCardProps> = ({
  children,
  headerContent,
  hasPadding = false,
  color = '#ECF4FF'
}) => {
  const className = useMemo(() => {
    return createStyleClass(color)
  }, [color])

  return (
    <div
      className={cnx(
        className, 'fi-product-card',
        hasPadding && 'with-padding'
      )}
    >
      <div className='fi-product-card-header'>
        <div className='header-content'>{headerContent}</div>
      </div>

      <div className='fi-product-card-body'>
        {children}
      </div>
    </div>
  )
}

export default FIProductCard
