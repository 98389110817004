import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  backToTop: {
    position: 'fixed',
    right: 32,
    bottom: 44,
    opacity: 1,
    zIndex: 99,
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer'
  }
})
