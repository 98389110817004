import { css } from '@emotion/css'

export default css(`
  &.cf-modal-fullscreen.cf-modal-container {
    .cf-modal-root.cf-modal-root {
      > div:not(.cf-modal-footer) {
        max-width: 900px;
        min-width: 900px;

        &.cf-modal-title { padding: 40px 0 0;}
        &.cf-modal-content { padding: 0px 0px 90px; }
      }

      .cf-modal-content {
        .cf-modal-form-wrapper {
          grid-template-columns: 1fr 1fr 1fr;
          grid-column-gap: 16px;

          .source-field { grid-row: 4; }
          .address-fields { grid-column: 1 / 4; }
        }
      }

      .cf-modal-footer { padding-right: 0; }
    }

    .loader-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 96px;

      .cf-loader { margin-bottom: 16px; }

      p {
        color: rgba(33, 33, 33, 0.50);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
      }
    }

    .existing-leads-table-wrapper {
      .header-section {
        display: flex;
        align-items: center;
        margin: 32px 0;
    
        h5 { 
          font-weight: 400;
    
          :first-of-type { margin-right: 8px; }
        }
      }

      .cf-table-wrapper {
        .cf-table {
          table-layout: fixed;

          .cf-table-header { display: none; }

          .cf-table-row {
            border-top: 8px solid #FFF;
            cursor: default;

            :first-child { border-top: none; }
            :last-child { border-bottom: 8px solid #FFF; }
            :hover { background-color: unset; }

            .cf-table-cell {
              background-color: #FFF; 

              :nth-child(2) > div { padding: 16px 10px; }
              :first-child > div { border-radius: 12px 0 0 12px; padding-right: 0; }
              :last-child > div { border-radius: 0 12px 12px 0; padding-left: 0; }

              .cf-cell-wrapper {
                height: 88px;
                padding: 16px;
                background-color: #FAFAFA;
                border-left: none;

                .label { 
                  flex-shrink: 0; 
                  color: rgba(33, 33, 33, 0.80);
                  margin-right: 4px; 
                }

                .table-cell-text {
                  flex-shrink: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }
`)
