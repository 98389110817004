import type { FormValidation } from '@carfluent/common'
import {
  phoneField,
  emailField,
  alphanumericWithSymbolsField,
  zipCodeField,
  alphanumericFieldWithOutSymbols
} from 'utils/validationPresets'

import type { LienholderFormData } from './types'

const validationRules: FormValidation<LienholderFormData> = {
  name: alphanumericWithSymbolsField(true),
  phoneNumber: phoneField(),
  email: emailField(),
  corpCode: alphanumericFieldWithOutSymbols(false),
  'address.zipCode': zipCodeField()
}

export default validationRules
