import { useMemo, useRef, useState } from 'react'
import { noop } from '@carfluent/common'

import useClickOutside from 'hooks/useClickOutside'
import useEffectOnce from 'hooks/useEffectOnce'

import { calculateConfig, calculateConfigWithoutAnchor } from './utils'
import type { ConfigProps, Coords, PlacementProps } from './types'
import {
  DEFAULT_PLACEMENT,
  DEFAULT_TRANSFORM,
  OUTSIDE_CLICK_CONFIG,
  DEFAULT_WITHOUT_ANCHOR_PLACEMENT
} from './constants'

export interface UsePopoverProps {
  onClose?: (e: Event) => void
  placement?: Partial<PlacementProps>
  transform?: Partial<Coords>
  maxHeight?: 'auto' | number
  minWidth?: number
  maxWidth?: number
  anchorEl?: HTMLElement | null
  disablePortal?: boolean
}

interface UsePopoverReturn {
  popover: HTMLDivElement
  config: ConfigProps
}

export const usePopover = ({
  onClose = noop,
  transform,
  placement,
  anchorEl,
  maxHeight,
  minWidth,
  maxWidth,
  disablePortal
}: UsePopoverProps): UsePopoverReturn => {
  const portalRef = useRef(document.createElement('div'))
  const [portalWidth, setPortalWidth] = useState<number>(0)

  useClickOutside(portalRef, onClose, OUTSIDE_CLICK_CONFIG)

  useEffectOnce(() => {
    const root = document.body

    root.appendChild(portalRef.current)

    const { width } = portalRef.current.getBoundingClientRect()

    setPortalWidth(width)
    return () => {
      root.removeChild(portalRef.current)
    }
  }, [setPortalWidth])

  const anchorRect = useMemo(() => anchorEl?.getBoundingClientRect(), [])

  const config = useMemo(() => {
    return anchorRect == null
      ? calculateConfigWithoutAnchor({
        ...DEFAULT_TRANSFORM,
        ...transform,
        ...DEFAULT_WITHOUT_ANCHOR_PLACEMENT,
        ...placement,
        maxHeight,
        minWidth,
        maxWidth
      })
      : calculateConfig({
        ...DEFAULT_TRANSFORM,
        ...transform,
        ...DEFAULT_PLACEMENT,
        ...placement,
        anchorRect,
        maxHeight,
        minWidth,
        maxWidth,
        portalWidth
      })
  }, [
    anchorRect, portalWidth, transform, placement,
    maxHeight, minWidth, maxWidth
  ])

  return {
    popover: portalRef.current,
    config
  }
}
