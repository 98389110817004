import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import Divider from 'components/common/Divider'
import formattedValue from 'utils/formatters/formatDealRecapValue'

import { type DealRecapFrontGrossProps } from './types'
import CLASS_NAME from './styles'

const DealRecapFrontGross: FC<DealRecapFrontGrossProps> = ({
  className,
  dealRecapData,
  isForModal = true,
  isShowCarDelivery = false
}) => {
  return (
    <div className={cnx(CLASS_NAME, 'deal-recap-front-gross', className)}>
      <div className='front-gross-rows'>
        <div className='row'>
          <div className='item-label'>Sale Price</div>
          <div className='item-value'>{formattedValue(dealRecapData?.salePrice)}</div>
        </div>

        <div className='row'>
          <div className='item-label'>Sale cost</div>
          <div className='item-value'>{formattedValue(dealRecapData?.saleCost)}</div>
        </div>

        {(dealRecapData?.costs ?? []).map((item) => {
          return (
            <div className='row indented-left' key={`${item.name ?? ''}-${item.amount ?? ''}`}>
              <div className='item-label'>{item.name}</div>
              <div className='item-value'>{formattedValue(item.amount)}</div>
            </div>
          )
        })}

        {isShowCarDelivery && (
          <div className='row'>
            <div className='item-label'>Car delivery</div>
            <div className='item-value'>
              {formattedValue(dealRecapData?.carDelivery, '-')}
            </div>
          </div>
        )}

        <Divider />

        <div className='row'>
          <div className='item-label'>Trade in allowance</div>
          <div className='item-value'>{formattedValue(dealRecapData?.tradeinAllowance)}</div>
        </div>

        {isForModal && <Divider />}
      </div>

      <div className='front-gross-rows'>
        {!isForModal && <Divider />}

        <div className='row front-gross'>
          <div className='item-label bold'>Front Gross</div>
          <div className='item-value bold'>{formattedValue(dealRecapData?.frontGross)}</div>
        </div>
      </div>
    </div>
  )
}

export default DealRecapFrontGross
