import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-wrap: nowrap;

  &.cf-no-activity .cf-table-cell-text > p {
    color: rgba(33, 33, 33, 0.50);
  }
  
  img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
`)
