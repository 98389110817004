import { DictionaryItem, TaskAssigneeType, TaskTypesNames } from 'api/types'

import type { CrmSettingsFormData, UserItem } from './types'
import type { DeepRequired } from '@carfluent/common'

export enum DURATION_IDS {
  MINUTES = 1,
  HOURS = 2,
  DAYS = 3,
}

export const DURATION_OPTIONS = [
  { id: DURATION_IDS.MINUTES, name: 'Minutes' },
  { id: DURATION_IDS.HOURS, name: 'Hours' },
  { id: DURATION_IDS.DAYS, name: 'Days' }
]

export const OWNER_OPTION = {
  id: -TaskAssigneeType.Owner,
  name: TaskTypesNames.Owner
}

export const ROUND_ROBIN_OPTION = {
  id: -TaskAssigneeType.RoundRobin,
  name: TaskTypesNames.RoundRobin
}

export const ROUND_ROBIN_BDC_OPTION = {
  id: -TaskAssigneeType.RoundRobinBDC,
  name: TaskTypesNames.RoundRobinBDC
}

export const ROUND_ROBIN_SALES_OPTION = {
  id: -TaskAssigneeType.RoundRobinSales,
  name: TaskTypesNames.RoundRobinSales
}

export const DEFAULT_OPTIONS = [
  ROUND_ROBIN_OPTION,
  ROUND_ROBIN_BDC_OPTION,
  ROUND_ROBIN_SALES_OPTION,
  OWNER_OPTION
]

export const DEFAULT_PRICE_DROP_THRESHOLD = 100

export const REMIND_TIME_OPTIONS = [
  {
    id: 15,
    name: '15 minutes before'
  },
  {
    id: 30,
    name: '30 minutes before'
  },
  {
    id: 60,
    name: '1 hour before'
  },
  {
    id: 120,
    name: '2 hours before'
  },
  {
    id: 3,
    name: '3 hours before'
  },
  {
    id: 24,
    name: '24 hours before'
  }
]

export enum AdditionalRecipientsIds {
  AppointmentTaskAssignee = -1,
  LeadOwner = -2
}

export const APPOINTMENT_RECIPIENTS_ASSIGNEE_OPTION: UserItem = {
  id: AdditionalRecipientsIds.AppointmentTaskAssignee,
  name: 'Task assignee'
}

export const LEAD_OWNER_RECIPIENTS_OPTION: UserItem = {
  id: AdditionalRecipientsIds.LeadOwner,
  name: 'Lead owner'
}

export const getDefaultFormData = (): DeepRequired<CrmSettingsFormData> => ({
  roundRobinEnabled: false,
  userIds: [],
  roundRobinIntervalMinutes: 15,
  duration: DURATION_OPTIONS[0],
  autoReplyEnabled: false,
  vehicleAvailableEmailTemplate: null,
  vehicleUnavailableEmailTemplate: null,
  vehicleAvailableMessageTemplate: null,
  vehicleUnavailableMessageTemplate: null,
  webLeadAssignedUserId: null,
  messageReceivedAssignedUserId: ROUND_ROBIN_OPTION,
  emailReceivedAssignedUserId: ROUND_ROBIN_OPTION,
  creditAppAssignedUserId: ROUND_ROBIN_OPTION,
  creditAppTasksEnabled: false,
  isEmailOnPriceDropEnabled: false,
  emailOnPriceDropEmailTemplate: null,
  emailOnPriceDropThreshold: null,
  isMessageOnPriceDropEnabled: false,
  messageOnPriceDropEmailTemplate: null,
  messageOnPriceDropThreshold: null,
  salesAppReminderEnabled: false,
  salesAppReminderMinutes: null,
  appointmentReminderUsers: [],
  customerAppReminderEnabled: false,
  customerAppReminderMinutes: null,
  customerAppReminderMessageTemplate: null
})
