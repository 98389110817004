import { UI, type Preset, DEFAULT_MIN_DATE, DEFAULT_MAX_DATE } from '@carfluent/common'

import {
  type DictionaryItem,
  DealFiltersState,
  TaskStateEnum,
  ViewDealerRoles,
  DealActions,
  DealStates
} from 'api/types'

import { DealsPeopleName, DealStateName, DealDetailsTabs } from 'constants/names'

export const TIME_HH_MM_FORMAT = 'hh:mm'
export const TIME_12H_MIN_AMPM_FORMAT = 'hh:mm aaa'
export const TIME_12H_MIN_AMPM_COMPACT_FORMAT = 'hh:mmaaa'
export const TIME_12H_MIN_SPACE_AMPM_FORMAT = 'hh:mm a'
export const DATETIME_ISO_EXTENDED_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss'
export const DATE_US_FORMAT = 'MM/dd/yyyy'
export const DATE_MONTH_YEAR_FORMAT = 'MM/yyyy'
export const DATETIME_US_12H_MIN_AMPM_FORMAT = 'MM/dd/yyyy hh:mm aaa'
export const DATE_US_SHORT_YEAR_FORMAT = 'MM/dd/yy'
export const DATETIME_US_SHORT_YEAR_FORMAT = 'MM/dd/yy hh:mm aaa'
export const DATE_MONTH_NAME_YEAR_FORMAT = 'MMMM yyyy'
export const DATE_DAY_SHORT_MONTH_YEAR_FORMAT = 'dd MMM yyyy'
export const DATE_US_SHORT_HOUR_AMPM_FORMAT = 'MM/dd/yyyy h:mm aa'
export const DATE_US_12H_MIN_AMPM_FORMAT = 'MM/dd/yyyy hh:mm aa'

export const SECS_IN_MIN = 60
export const MIN_IN_HOUR = 60
export const HOURS_IN_DAY = 24

export const px = (x: number): string => `${x}px`

// DD-TODO: move to Common Lib
export const EIN_MASK = '00-0000000'
export const CHECKING_ACCOUNT_NUMBER_MASK = '000000000000000'

export enum Responsive {
  Mobile = 576,
  Tablet = 1200,
  Laptop = 1440
}

/**
 * constants & breakpoints taken from new design and should be used everywhere on admin
 * https://dev.azure.com/carfluent/CarFluent/_wiki/wikis/CarFluent.wiki/112/Admin-tool
 */

export enum ScreenWidthBreakpoints {
  SettingsDefault = 1600,
  SettingsFullHD = 1920,
  Small = 1281,
  Medium = 1572,
  Large = 1728
}

export enum ContentLayoutWidth {
  MinSideMargins = 48,
  SettingsSideMenu = 212,
  NavDrawer = 64,
  MinSettingsContent = 956,
  MaxSettingsContent = 1244,
  MinContent = 1169,
  MaxContent = 1600,
  WideMaxContent = 1600
}

/**
 * default page size is 20
 * height of one row is 68px
 * gap is 8px
 * so, minHeight = (20 rows * (68px + 8px)) - 8px(no bottom margin)
 */
export const PAGE_SIZE = 30
export const GET_TABLE_MIN_HEIGHT = (
  rowsNum = PAGE_SIZE,
  rowHeight = 68,
  rowGap = 8
): number => {
  return rowsNum * (rowHeight + rowGap) - rowGap
}

/**
 * @DEPRECATED - use `GET_TABLE_MIN_HEIGHT()` instead
 */
export const TABLE_MIN_HEIGHT = 1512

export const NAV_DRAWER_WIDTH = 64
export const PAGE_TITLE_HEIGHT = 64
export const PAGE_TITLE_HEIGHT_FIX_OVERFLOW_Y = 65
export const BOTTOM_ACTIONS_HEIGHT = 80
export const SIDEBAR_WIDTH = 64

export const MSG_NOT_FOUND = 'Nothing found'
export const API_CALL_DELAY = 1000
export const ONE_LOAD_PAGE_SIZE = 1000

export const DEALS_FILTER_LIST = [
  { id: DealFiltersState.Incomplete, name: DealStateName.Incomplete },
  { id: DealFiltersState.Sold, name: DealStateName.Sold }
]

export const CRM_TASKS_TAB_FILTERS = [
  {
    id: TaskStateEnum.NewLeads,
    name: 'New Leads'
  },
  {
    id: TaskStateEnum.CreditAppReceived,
    name: 'Credit Apps',
    serverName: 'CreditAppReceived'
  },
  {
    id: TaskStateEnum.Inbox,
    name: 'Inbox'
  },
  {
    id: TaskStateEnum.Workplan,
    name: 'Workplan'
  },
  {
    id: TaskStateEnum.Appointments,
    name: 'Appts'
  }
]

export const DEALS_VIEW_TABS_OLD = [
  {
    id: DealsPeopleName.CustomerDetails,
    name: 'Buyer'
  },
  {
    id: DealsPeopleName.CoBuyer,
    name: DealsPeopleName.CoBuyer
  }
]

export const DEALS_VIEW_TABS = [
  {
    id: DealDetailsTabs.Deal,
    name: DealDetailsTabs.Deal
  },
  {
    id: DealDetailsTabs.Buyer,
    name: DealDetailsTabs.Buyer
  },
  {
    id: DealDetailsTabs.Vehicles,
    name: DealDetailsTabs.Vehicles
  },
  {
    id: DealDetailsTabs.Forms,
    name: DealDetailsTabs.Forms
  },
  {
    id: DealDetailsTabs.Documents,
    name: DealDetailsTabs.Documents
  },
  {
    id: DealDetailsTabs.Finances,
    name: DealDetailsTabs.Finances
  }
]

export const MODAL_MIN_WIDTH = 680

export const ACTIVE_OPACITY = 0.4
export const DEFAULT_OPACITY = 0.1

export const DEFAULT_AVATAR_COLOR = `rgba(33, 33, 33, ${ACTIVE_OPACITY})`
export const DEFAULT_BOT_AVATAR_COLOR = 'rgba(255, 243, 236, 1)'

export const AVATAR_COLORS = [
  '#3593EA',
  '#F15F94',
  '#C057E5',
  '#467EEB',
  '#80BE00',
  '#E26D2B',
  '#D4B300',
  '#8D45E8',
  '#EB1789',
  '#1EC2E7'
]

export enum LabelColors {
  Hot = '#F84A40',
  Warm = '#FFA451',
  Cold = '#458FFF'
}

export enum ReconLabelColors {
  BGYellow = '#FFF1E6',
  BGRed = '#FFE7EB',
  TextYellow = '#DA700F',
  TextRed = '#D52A49',
}

export const WORK_SCHEDULE_MODAL_CONSTRAINT_ID = 'work-schedule-modal-constraint'
export const COMMON_MODAL_FORM_CLS = 'cf-modal-form-wrapper'

export const UNASSIGNED_LEAD_ID = 0

export const UNASSIGNED_LEAD = {
  name: 'Unassigned lead',
  id: UNASSIGNED_LEAD_ID
}

export const UNASSIGNED_OPTION_TASKS_GRID = {
  name: 'Unassigned',
  id: UNASSIGNED_LEAD_ID
}

export const getAssignedToMeLead = (id: number): DictionaryItem<string> => ({
  name: 'Assigned to me',
  id
})

export enum TransactionActionNames {
  JournalEntry = 'Journal entry',
  PayBillOrCheck = 'Pay bill/Check',
  Check = 'Check',
  PayBill = 'Pay bill',
  Receivable = 'Receivable',
  Receive = 'Receive',
  Payable = 'Payable'
}

export const userRolesWithCRMPermission = [ViewDealerRoles.DealerSuperAdmin, ViewDealerRoles.DealerAdmin, ViewDealerRoles.Sales, ViewDealerRoles.BDC]

export const ALL_USER_ROLES = [
  ViewDealerRoles.DealerSuperAdmin,
  ViewDealerRoles.DealerAdmin, ViewDealerRoles.Sales,
  ViewDealerRoles.BDC,
  ViewDealerRoles.Buyer,
  ViewDealerRoles.Mechanic
]

export const VIEW_DEALER_ROLE_NAMES = {
  [ViewDealerRoles.DealerSuperAdmin]: 'Super Admin',
  [ViewDealerRoles.DealerAdmin]: 'Admin',
  [ViewDealerRoles.Sales]: 'Sales',
  [ViewDealerRoles.BDC]: 'BDC',
  [ViewDealerRoles.Buyer]: 'Buyer',
  [ViewDealerRoles.Mechanic]: 'Mechanic'
}

export const GET_DEFAULT_PRESETS = (): Preset[] => UI.getDefaultDateRangePresets(CALENDAR_MIN_MAX_RANGE.minDate, CALENDAR_MIN_MAX_RANGE.maxDate)

export enum AccountTypeId {
  BankChecking = 11
}

export const asyncNoop = async (): Promise<void> => {}

export const CALENDAR_MIN_MAX_RANGE = {
  minDate: DEFAULT_MIN_DATE,
  maxDate: DEFAULT_MAX_DATE
}

export const DEAL_ACTIONS = [
  {
    id: DealActions.Sign,
    name: 'Sign'
  },
  {
    id: DealActions.Review,
    name: 'Review'
  },
  {
    id: DealActions.Submit,
    name: 'Submit'
  }
]

export const STATE_OPTIONS = [
  {
    id: DealStates.All,
    name: 'All',
    className: 'all'
  },
  {
    id: DealStates.FullyPaid,
    name: 'Fully paid',
    className: 'fully-paid'
  },
  {
    id: DealStates.NotPaid,
    name: 'Not paid',
    className: 'not-paid'
  }
]
