import { type SerializedDate } from 'utils/filters/types'
import serializeDateFilters from 'utils/filters/serializeDateFilters'
import serializeLocalDateTime from 'utils/filters/serializeLocalDateTime'
import type { DateType } from 'api/types'

const serializeDateFiltersLocal = (
  dates: DateType
): SerializedDate | null => {
  return serializeDateFilters(dates, serializeLocalDateTime)
}

export default serializeDateFiltersLocal
