import { type FC, Fragment } from 'react'
import { cnx } from '@carfluent/common'

import { type ReconVehicleAmount, type TotalStepsAmountDtoDto } from 'api/types'

import IconSvg from 'components/inlineImages'
import MultilineTextEllipsis from 'components/common/MultilineTextEllipsis'
import Divider from 'components/common/Divider'

import LoaderScreen from './components/LoaderScreen'

import CLASS_NAME, { COLOR_MAP, TOOLTIP_CLASS_NAME } from './styles'

interface ReconStatusFiltersProps {
  onChangeStep: (stepId: number | undefined) => void
  selectedStep: number | undefined
  statusFiltersProps: {
    statuses: ReconVehicleAmount[]
    total: TotalStepsAmountDtoDto
    isLoading: boolean
  }
  className?: string | null
}

const formatStatusStep = (val: number): string => (val == null || val === 0) ? '-' : val.toString()
const TOOLTIP_PROPS = { popperClassName: TOOLTIP_CLASS_NAME }

const renderStatusItem = (
  v: ReconVehicleAmount,
  selectedStep: number | undefined,
  onChangeStep: (stepId: number | undefined) => void,
  className?: string | null
): JSX.Element => {
  return (
    <div
      key={v.stepId}
      className={cnx('cf-recon-step-filter-item', selectedStep === v.stepId ? 'selected' : '', className)}
      onClick={() => { onChangeStep(v.stepId > 0 ? v.stepId : undefined) }}
    >
      <span>
        <MultilineTextEllipsis
          maxLines={2}
          withTooltip
          isCollapsible={false}
          tooltipProps={TOOLTIP_PROPS}
        >
          {v.stepName}
        </MultilineTextEllipsis>
      </span>
      <span className='no-alert-bg'>{formatStatusStep(v.noAlertCount)}</span>
      <span className='first-alert-bg'>{formatStatusStep(v.firstAlertCount)}</span>
      <span className='second-alert-bg'>{formatStatusStep(v.secondAlertCount)}</span>
    </div>
  )
}

const ReconStatusFilters: FC<ReconStatusFiltersProps> = ({ onChangeStep, selectedStep, statusFiltersProps, className }) => {
  const allStatus = statusFiltersProps.statuses.length > 0
    ? {
        stepId: 0,
        stepName: 'All cars in Recon',
        ...statusFiltersProps.total
      }
    : null

  return (
    <div className={cnx(CLASS_NAME, className, 'cf-recon-list-step-filters g-non-printable-block')}>
      <div className='no-alert-line' />
      <div className='first-alert-line' />
      <div className='second-alert-line' />

      <div className='cf-recon-step-filters-header'>
        <span />
        <div className='car-block-wrapper no-alert-bg'>
          <IconSvg.Car color={COLOR_MAP.black} />
        </div>

        <div className='car-block-wrapper first-alert-bg'>
          <IconSvg.Car color={COLOR_MAP.yellow} />
        </div>

        <div className='car-block-wrapper second-alert-bg'>
          <IconSvg.Car color={COLOR_MAP.red} />
        </div>
      </div>

      <div className='cf-recon-step-filters-list'>
        {statusFiltersProps.isLoading
          ? <LoaderScreen />
          : (
            <>
              {allStatus != null && (renderStatusItem(allStatus, selectedStep, onChangeStep))}
              {
                statusFiltersProps.statuses.map((v, index) => (
                  <Fragment key={index}>
                    {renderStatusItem(v, selectedStep, onChangeStep)}
                    {index === statusFiltersProps.statuses.length - 2 && <Divider className='divider' />}
                  </Fragment>
                ))
              }
            </>
            )}
      </div>
    </div>
  )
}

export default ReconStatusFilters
