import type { AxiosResponse } from 'axios'
import { WrapperRequest } from 'api/wrapper.api'

import {
  type IntakeInspectionQuestionDto,
  type IntakeInspectionQuestionCategoriesDto,
  type ReconInspectionStatesDto,
  type SummaryVehicleInspectionDto,
  type VehicleIntakeInspectionDto
} from 'api/types/responses'

import type {
  ListResponse,
  PaginatedResult,
  ReconListPayload,
  ReconStep,
  ReconVehicleTotals,
  UpdateReconStepsPayload,
  UpdateReconDetailsPayload,
  ReconVehicleDetailsResponseDto,
  ReconVehicleListItemResponseDto,
  ReconLogDataResponseDto,
  VehicleInspectionQuestionPayload
} from './types'

class ReconAPI extends WrapperRequest {
  url = process.env.REACT_APP_RECON ?? ''

  getReconStepsList = async (): Promise<ListResponse<ReconStep>> => {
    return await this.get(`${this.url}/api/v1/ReconSteps`)
  }

  getReconVehiclesTotal = async (): Promise<ReconVehicleTotals> => {
    return await this.get(`${this.url}/api/v1/ReconSteps/vehicles/total`)
  }

  getReconVehiclesList = async (payload: ReconListPayload): Promise<PaginatedResult<ReconVehicleListItemResponseDto>> => {
    return await this.post(`${this.url}/api/v1/Vehicles/list`, payload)
  }

  printReconVehiclesList = async (payload: ReconListPayload): Promise<Blob> => {
    return await this.post(`${this.url}/api/v1/Vehicles/list/pdf`, {
      data: payload,
      additionalParams: { responseType: 'blob' }
    })
  }

  updateReconStepsList = async (payload: UpdateReconStepsPayload): Promise<ListResponse<ReconStep>> => {
    return await this.post(`${this.url}/api/v1/ReconSteps/change-list`, payload)
  }

  updateReconFavorite = async (id: number | string, isFavorite: boolean): Promise<void> => {
    return await this.patch(`${this.url}/api/v1/Vehicles/${id}/favorite`, { data: isFavorite })
  }

  updateReconDetails = async (id: number | string, payload: Partial<UpdateReconDetailsPayload>): Promise<ReconVehicleDetailsResponseDto> => {
    return await this.patch(`${this.url}/api/v1/Vehicles/${id}`, payload)
  }

  getReconDetails = async (id: number | string): Promise<ReconVehicleDetailsResponseDto> => {
    return await this.get(`${this.url}/api/v1/Vehicles/${id}`)
  }

  postReconComment = async (id: number | string, body: { comment: string }): Promise<ReconLogDataResponseDto> => {
    return await this.post(`${this.url}/api/v1/Vehicles/${id}/comment`, { data: body })
  }

  getQuestionCategories = async (): Promise<ListResponse<IntakeInspectionQuestionCategoriesDto>> => {
    return await this.get(`${this.url}/api/v1/Reference/questionCategories`)
  }

  getVehicleIntakeInspection = async (id: number | string): Promise<VehicleIntakeInspectionDto> => {
    return await this.get(`${this.url}/api/v1/VehicleInspections/${id}/intake`)
  }

  updateVehicleInspectionQuestion = async ({
    data,
    questionId,
    inspectionId
  }: {
    questionId: number | string
    inspectionId: number | string
    data: VehicleInspectionQuestionPayload
  }): Promise<IntakeInspectionQuestionDto> => {
    return await this.patch(`${this.url}/api/v1/VehicleInspections/${inspectionId}/question/${questionId}`, { data })
  }

  submitVehicleInspection = async (id: number | string): Promise<void> => {
    return await this.patch(`${this.url}/api/v1/VehicleInspections/${id}/submit`)
  }

  resetVehicleInspection = async (id: number | string): Promise<void> => {
    return await this.patch(`${this.url}/api/v1/VehicleInspections/${id}/reset`)
  }

  summaryVehicleInspection = async (vehicleId: number | string): Promise<SummaryVehicleInspectionDto> => {
    return await this.get(`${this.url}/api/v1/VehicleInspections/${vehicleId}/intake/summary`)
  }

  vehicleInspectionStates = async (): Promise<ListResponse<ReconInspectionStatesDto>> => {
    return await this.get(`${this.url}/api/v1/Reference/inspectionStates`)
  }

  unlockVehicleInspection = async (id: number | string): Promise<void> => {
    return await this.patch(`${this.url}/api/v1/VehicleInspections/${id}/unlock`)
  }

  downloadVehicleInspectionsReportXlsx = async (id: number | string): Promise<AxiosResponse<Blob>> => {
    return await this.patch(`${this.url}/api/v1/VehicleInspections/${id}/xlsx`, {
      headers: { 'X-Special-Request': true },
      additionalParams: { responseType: 'blob' }
    })
  }
}

export const ReconAPIProvider = new ReconAPI()
export default ReconAPIProvider
