import type { TransactionsReport } from './types'
import fileNames from 'utils/reports/fileNames'

export const getDefaultData = (): TransactionsReport => ({
  totalCredits: 0,
  totalDebits: 0,
  transactions: []
})

export const REPORT_FILE_NAMES = fileNames('TransactionsReport')
