import { useEffect } from 'react'

import type { DealsListItem } from 'api/types/customersCore.types'
import { Notifications } from 'constants/names'
import ws from 'services/web_sockets'

const ONE_SECOND = 1000

export interface UseWSUpdatesProps {
  onDealAdded?: (dealId: DealsListItem) => void
  onDealUpdated?: () => Promise<void>
}

const useWSUpdates = ({
  onDealAdded,
  onDealUpdated
}: UseWSUpdatesProps): void => {
  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    ws.on(Notifications.DealUpdated, {
      name: 'dealsList_dealUpdated',
      action: (args) => {
        if (args?.dealId != null) {
          void onDealUpdated?.()
        }
      }
    }, {
      throttledGroupId: Notifications.DealUpdated,
      throttledTimeout: ONE_SECOND
    })

    return () => {
      ws.off(Notifications.DealUpdated, 'dealsList_dealUpdated')
    }
  }, [onDealUpdated])

  useEffect(() => {
    ws.on(Notifications.NewDealCreated, {
      name: 'dealsList_dealCreated',
      action: (args) => {
        const deal: DealsListItem | undefined = args?.dealDetails
        if (deal != null) {
          onDealAdded?.(deal)
        }
      }
    })

    return () => {
      ws.off(Notifications.NewDealCreated, 'dealsList_dealCreated')
    }
  }, [onDealAdded])
}

export default useWSUpdates
