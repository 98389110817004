import { type FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Modal, cnx } from '@carfluent/common'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'

import CLASS_NAME from './styles'

interface DialogProps {
  isOpen: boolean
  isSubmitting?: boolean
  isSubmittingCanceled?: boolean
  title?: string
  content: string
  onDontSaveAndClose: () => void
  onCloseDialog?: () => void
  onSaveAndClose: () => void
  className?: string
  submitTitle?: string
  cancelTitle?: string
}

const MODAL_MIN_WIDTH = 680

const UnsavedChangesDialog: FC<DialogProps> = ({
  isOpen,
  isSubmitting,
  isSubmittingCanceled,
  title = 'You have unsaved changes',
  content,
  onDontSaveAndClose,
  onCloseDialog,
  onSaveAndClose,
  className,
  submitTitle = 'SAVE',
  cancelTitle = 'DON’T SAVE'
}) => {
  const renderFooter = (): JSX.Element => {
    return (
      <CancelSubmitActionsFooter
        isLoading={isSubmitting}
        isDisabled={isSubmittingCanceled}
        onSubmit={onSaveAndClose}
        onClose={onDontSaveAndClose}
        cancelTitle={cancelTitle}
        submitTitle={submitTitle}
      />
    )
  }

  return (
    <Modal
      className={cnx(CLASS_NAME, className)}
      closeByBackdropClick={false}
      isOpen={isOpen}
      minWidth={MODAL_MIN_WIDTH}
      onClose={onCloseDialog}
      renderFooterActions={renderFooter}
      title={title}
    >
      <p>{content}</p>
    </Modal>
  )
}

export default observer(UnsavedChangesDialog)
