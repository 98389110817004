import { css } from '@emotion/css'

export default css(`
  .cf-no-data-block {
    display: flex;
    justify-content: center;
    padding: 30px;
    background: #fff;
    border-top: 8px solid #F3F3F3;
  }

  .btn-new-email-template {
    width: 150px;

    span { white-space: nowrap; }
  }

  .cf-table { table-layout: fixed; }

  .cf-table-cell:last-of-type {
    max-width: 265px;

    .cf-cell-wrapper > div { max-width: 100%; }

    .cf-tooltip-container { max-width: 90%; }
  }

  .cf-header-cell-title > .cf-header-cell-content { color: #101010; }

  .cf-cell-wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
`)
