import { css } from '@emotion/css'

export default css(`
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;

  .scrollable {
    display: flex;
    gap: 16px;
    width: 100%;
    padding: 24px;
    position: absolute;
    top: 0px;
    bottom: calc(-1 * var(--scrollbar-height));
    left: 0px;
    right: 0px;
    overflow-x: auto;
    overflow-y: hidden;
    overscroll-behavior: contain;
  }

  &.visible-scroll .scrollable {
    bottom: 0px;
  }
`)
