import type { FC } from 'react'
import { Modal } from '@carfluent/common'

import screenFinancing from 'assets/screenFinancing.png'
import screenMainPage from 'assets/screenMainPage.png'
import CLASS_NAME from './styles'

interface DialogProps {
  open: boolean
  onClose?: () => void
}

const MODAL_MAX_WIDTH = 881

const LogoInstructions: FC<DialogProps> = ({ open, onClose }) => {
  return (
    <Modal
      title='Logo instructions'
      isOpen={open}
      onClose={onClose}
      maxWidth={MODAL_MAX_WIDTH}
    >
      <div className={CLASS_NAME}>
        <div className='header'>
          <div className='prompt-block'>
            Your dealership(s) logo will be displayed here
            <div className='arrow' />
          </div>

          <ul className='restriction-list'>
            <div className='list-item-block'>
              <li className='list-item'>PNG, JPEG format</li>
            </div>

            <div className='list-item-block'>
              <li className='list-item'>Max 3 MB</li>
            </div>
          </ul>
        </div>

        <img src={screenMainPage} alt='Main Page screen' />
        <img src={screenFinancing} alt='Financing screen' />
      </div>
    </Modal>
  )
}

export default LogoInstructions
