import { css } from '@emotion/css'

export default css`
  .MuiSkeleton-text {
    height: 16px;
  }

  .skeleton-section {
    margin-bottom: 20px;
  }

  .skeleton-header,
  .skeleton-question {
    border-radius: 2px;
    margin-bottom: 4px;
  }

  .skeleton-header {
    width: 100%;
    padding: 16px 8px;
    background: #FAFAFA;

    .MuiSkeleton-text {
      width: 180px
    }
  }

  .skeleton-question {
    background: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr 300px;
    // grid-template-rows: 1fr 1fr;
    grid-auto-columns: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "skeleton-question-name skeleton-question-name skeleton-question-status"
      "skeleton-question-comment skeleton-question-comment .";
  }

  .skeleton-question-name {
    padding: 16px 8px;
    grid-area: skeleton-question-name;

    .MuiSkeleton-text {
      width: 320px;
    }
  }

  .skeleton-question-comment {
    padding: 20px 8px 24px;
    grid-area: skeleton-question-comment;

    .MuiSkeleton-text {
      max-width: 540px;
    }
  }

  .skeleton-question-status {
    display: flex;
    max-width: 300px;
    grid-area: skeleton-question-status;

    .MuiSkeleton-text {
      margin: 16px 32px 16px 8px;
      width: 60px;
    }
  }
`
