import { css } from '@emotion/css'

import { ColumnsSizes } from '../../columns'

const dynamicColumnWidth = `calc(100% - ${ColumnsSizes.Date + ColumnsSizes.Amount}px)`

export const createStyleClass = (): string => {
  return css(`
    & .MuiTableCell-root:nth-child(2) {
      min-width: ${dynamicColumnWidth};
      max-width: ${dynamicColumnWidth};
    }
  `)
}
