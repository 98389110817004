import { TimelineLogTypes } from 'api/types'

const splitText = (text: string, chunkLength: number): string[] => {
  const lines: string[] = []

  let startIndex = 0
  while (startIndex < text.length) {
    const chunk = text.substring(startIndex, chunkLength)
    lines.push(chunk)
    startIndex += chunkLength
  }

  return lines
}

export default splitText

export const getVisibleLines = (logTypeId: TimelineLogTypes): number => {
  switch (logTypeId) {
    case TimelineLogTypes.Comment:
      return 3

    case TimelineLogTypes.MessageSent:
    case TimelineLogTypes.MessageReceived:
    case TimelineLogTypes.MessageSentAutomatically:
      return 3

    default:
      return 5
  }
}

export const isGreyedRecord = (logTypeId: TimelineLogTypes): boolean => {
  return logTypeId === TimelineLogTypes.LeadAutomaticallyCreated ||
    logTypeId === TimelineLogTypes.CarOfInterestAdded ||
    logTypeId === TimelineLogTypes.LeadAssigned ||
    logTypeId === TimelineLogTypes.UnableToSendMessage ||
    logTypeId === TimelineLogTypes.UnableToSendEmail ||
    logTypeId === TimelineLogTypes.CarOfInterestIsDeposited
}

export const getMaxVisibleContentHeight = (logTypeId: TimelineLogTypes): number => {
  switch (logTypeId) {
    case TimelineLogTypes.Comment:
      return 72

    default:
      return 156
  }
}
