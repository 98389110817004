import { UserRoles } from 'api/types'
import { Routes } from 'constants/route_helper'

export const routesPerRoles: Record<UserRoles, { hidden: Routes[] }> = {
  [UserRoles.SuperAdmin]: { hidden: [] },
  [UserRoles.Admin]: { hidden: [] },
  [UserRoles.Sales]: { hidden: [Routes.Accounting] },
  [UserRoles.BDC]: {
    hidden: [
      Routes.Settings, Routes.Accounting, Routes.SettingsFormPacks,
      Routes.CRMGeneralSettings,
      Routes.Recon, Routes.Reports, Routes.Dashboard
    ]
  },
  [UserRoles.Buyer]: {
    hidden: [
      Routes.Deals, Routes.CRM,
      Routes.Accounting, Routes.SettingsFormPacks, Routes.Settings,
      Routes.Reports, Routes.Dashboard
    ]
  },
  [UserRoles.Mechanic]: {
    hidden: [
      Routes.Deals, Routes.CRM,
      Routes.ReconSettings, Routes.Accounting, Routes.SettingsFormPacks,
      Routes.Settings, Routes.Reports, Routes.Dashboard
    ]
  }
}

/**
 * If user has multiple roles we follow more restrictive approach.
 * Less-inclusive roles win over more-inclusive.
 * Example: SuperAdmin with BDC would have BDC permissions.
 */
export const isRouteForbiddenPerRoles = (route: Routes, roles: UserRoles[]): boolean => {
  return roles.some(role => routesPerRoles[role]?.hidden.includes(route))
}

export const isPathnameHiddenPerRoles = (pathname: string, roles: UserRoles[]): boolean =>
  roles.some(role =>
    routesPerRoles[role]?.hidden.some(route => pathname.startsWith(route)))
