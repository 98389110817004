import { type FC, useCallback } from 'react'

import { Checkbox } from 'components/form'
import CheckBoxIcon from 'components/accounting/CheckBoxIcon'

import type { UseLeadLogsFormReturn } from 'pages/crm/LeadDetailsView/components/LeadDetailsLogActivity/hook'
import { VISIT_SUB_OPTIONS } from 'pages/crm/LeadDetailsView/components/LeadDetailsLogActivity/hook/constants'
import { FieldIds } from 'pages/crm/LeadDetailsView/components/LeadDetailsLogActivity/hook/types'

export type LogVisitFormProps = Pick<UseLeadLogsFormReturn, 'values' | 'errors'| 'touched' |'onChangeSelectedCheckbox'>

const LogVisitForm: FC<UseLeadLogsFormReturn> = ({ values, errors, touched, onChangeSelectedCheckbox }) => {
  const isSubOptionChecked = useCallback(
    (name: string): boolean => (
      values.activityCompleteSubOptions.find(opt => opt === name) != null
    ), [values.activityCompleteSubOptions])

  const showCheckboxError = touched[FieldIds.ActivityCompleteOptionId] &&
  (values.activityCompleteSubOptions.length === 0) && (errors[FieldIds.ActivityCompleteSubOptions])

  return (
    <div className='suboptions'>
      {VISIT_SUB_OPTIONS.map(option => (
        <Checkbox
          classes={{ control: 'suboption-checkbox' }}
          value={isSubOptionChecked(option.name)}
          icon={<CheckBoxIcon />}
          onChange={onChangeSelectedCheckbox}
          id={option.id}
          key={option.id}
          label={option.name}
        />
      ))}
      <p className='error-text'>{showCheckboxError}</p>
    </div>
  )
}

export default LogVisitForm
