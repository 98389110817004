import type { FC } from 'react'
import { NavLink } from 'react-router-dom'

import { type LinkItem } from 'constants/route_helper'
import ArrowListToggleIcon from 'components/inlineImages/ArrowListToggleIcon'
import NavIcon from 'components/layout/NavIcon'

export interface MenuListItemProps {
  hasSubLinks?: boolean
  isActive?: boolean
  isSubLinksShown?: boolean
  item: LinkItem
}

const MenuListItem: FC<MenuListItemProps> = ({
  hasSubLinks = false,
  isActive = false,
  isSubLinksShown = false,
  item
}) => {
  const content = (
    <div className='nav-menu-list-item'>
      <NavIcon icon={item.icon} isActive={isActive} />
      <span className='g-subtitle-1 nav-menu-list-item-title'>{item.title}</span>

      {hasSubLinks && (
        <ArrowListToggleIcon
          isActive={isActive}
          isToggleToOpen={!isSubLinksShown}
        />
      )}
    </div>
  )

  return hasSubLinks
    ? content
    : <NavLink to={item.to}>{content}</NavLink>
}

export default MenuListItem
