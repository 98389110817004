import { serializeCompleteTaskData, serializeRespondOnLeadTaskData } from 'pages/crm/LeadDetailsView/hook/serializer'
import { useCallback, useState } from 'react'

import { type FCHook, type TasksFormData } from 'types'
import { TimelineLogTypes } from 'api/types'
import CRMApiProvider from 'api/crm.api'

import { ScheduleButtonTaskType, ActivityButtonTaskType, LogType, type RespondOnLeadTaskFormData } from './types'
import type {
  OpenTaskFormData,
  ResponseOnAutoTaskFormData,
  UseLeadTasksProps,
  UseLeadTasksReturn,
  CompleteTaskFormData,
  LogActivityFormData
} from './types'
import { activityTypeIdMap, getDefaultFieldsForNewTask, taskTypeIdMap } from './utils'
import {
  serializeCallActivityData,
  serializeOtherActivityData,
  serializeTaskData,
  serializeVisitActivityData,
  serializeCompleteAutoTaskData
} from './serializer'
import { parseLeadTasks } from './parser'
import useLeadWSUpdates from './useLeadWSUpdates'

const useLeadTasks: FCHook<UseLeadTasksProps, UseLeadTasksReturn> = ({
  reminderSubjects,
  leadTemperature,
  leadId,
  leadTimelines,
  onViewEmailClick: _onViewEmailClick
}) => {
  const [tasks, setTasks] = useState<TasksFormData[]>([])
  const [logTypeId, setLogTypeId] = useState<LogType | null>(null)

  const isTasksExist = tasks?.length > 0
  const isCompletedOrOpenTasksExist = leadTimelines.some(item => (
    item.logTypeId === TimelineLogTypes.Call ||
    item.logTypeId === TimelineLogTypes.Appointment ||
    item.logTypeId === TimelineLogTypes.Reminder)) || isTasksExist

  const firstTaskId = tasks[0]?.id

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const setTypeOfNewTask = useCallback((taskTypeId: number) => {
    setTasks(prevTasks => {
      return [
        { ...prevTasks[0], taskTypeId },
        ...prevTasks.slice(1)
      ]
    })
  }, [])

  const onInsertTask = useCallback((task: TasksFormData): void => {
    setTasks(tasks => [task, ...tasks])
  }, [])

  const removeTask = useCallback((taskId: number | null): void => {
    setTasks(tasks => tasks.filter(task => task.id !== taskId))
  }, [])

  const updateTasks = useCallback((leadTasks: TasksFormData[]): void => {
    if (leadTasks?.length >= 0) {
      setTasks(leadTasks)
    }
  }, [])

  const onSubmitActivityLog = useCallback(async (values: LogActivityFormData, logTypeId: LogType) => {
    switch (logTypeId) {
      case LogType.Call:
        await CRMApiProvider.createCallActivity(serializeCallActivityData(values, leadId))
        break
      case LogType['Store visit']:
        await CRMApiProvider.createVisitActivity(serializeVisitActivityData(values, leadId))
        break
      case LogType.Other:
        await CRMApiProvider.createOtherActivity(serializeOtherActivityData(values, leadId))
        break
    }
  }, [leadId])

  const onSubmitCompleteTask = useCallback(async (values: CompleteTaskFormData, taskId: number) => {
    await CRMApiProvider.completeTask(serializeCompleteTaskData(values, taskId))
  }, [])

  const onSubmitRespondTask = useCallback(async (values: ResponseOnAutoTaskFormData, taskId: number) => {
    await CRMApiProvider.completeRespondOnAutoTask(serializeCompleteAutoTaskData(values, taskId))
  }, [])

  const onSubmitRespondOnLeadTask = useCallback(async (values: RespondOnLeadTaskFormData, taskId: number) => {
    await CRMApiProvider.completeRespondOnLeadTask(serializeRespondOnLeadTaskData(values, taskId))
  }, [])

  const onSubmitTask = useCallback(async (taskId: number | null, values: OpenTaskFormData & { taskTypeId: number }) => {
    const data = serializeTaskData({ leadId, ...values })

    if (taskId !== null) {
      await CRMApiProvider.updateTask({ ...data, id: taskId })
    } else {
      await CRMApiProvider.createTask(data)
    }
  }, [leadId])

  const onDeleteTask = useCallback(async (taskId: number) => {
    await CRMApiProvider.deleteTask(taskId)
  }, [])

  /**
   * onScheduleTaskButtonClick handler basically need to correctly handle
   * new task creation and new task type switch
   */
  const onScheduleTaskButtonClick = useCallback((taskTypeString: ScheduleButtonTaskType) => {
    const defaultFields = getDefaultFieldsForNewTask({
      isTasksExist: isCompletedOrOpenTasksExist,
      leadTemperature
    })

    if (firstTaskId != null || !isTasksExist) {
      onInsertTask({ ...defaultFields, taskTypeId: taskTypeIdMap[taskTypeString] })
    } else {
      setTypeOfNewTask(taskTypeIdMap[taskTypeString])
    }
  }, [
    firstTaskId, isCompletedOrOpenTasksExist, leadTemperature,
    onInsertTask, setTypeOfNewTask, isTasksExist
  ])

  const onActivityTaskButtonClick = useCallback((taskTypeString: ActivityButtonTaskType) => {
    setLogTypeId(taskTypeString != null ? activityTypeIdMap[taskTypeString] : null)
  }, [])

  const onCloseActivity = useCallback((): void => setLogTypeId(null), [])

  const onViewEmailClick = useCallback(async (taskId: number): Promise<void> => {
    const emailId = leadTimelines.find(logItem => taskId === logItem.taskId)?.emailId

    if (emailId == null) {
      return
    }

    await _onViewEmailClick(emailId)
  }, [_onViewEmailClick, leadTimelines])

  const onTasksUpdated = useCallback(async (leadId: number): Promise<void> => {
    try {
      const { items } = await CRMApiProvider.getLeadTasks(leadId)
      const tasks = parseLeadTasks(items, reminderSubjects)
      updateTasks(tasks)
    } catch (e) {
      // DO NOTHING
    }
  }, [updateTasks, reminderSubjects])

  const onTasksDeleted = useCallback((taskIds: number[]) => {
    setTasks(tasks => tasks.filter(({ id }) => !taskIds.includes(id ?? -1)))
  }, [])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useLeadWSUpdates({
    onTasksDeleted,
    onTasksUpdated,
    leadId: Number(leadId)
  })

  // ========================================== //

  return {
    leadTemperature,
    isTaskListEmpty: !isTasksExist,
    leadTasks: tasks,
    logTypeId,
    onCloseActivity,
    onScheduleTaskButtonClick,
    onActivityTaskButtonClick,
    removeTask,
    updateTasks,
    onSubmitTask,
    onSubmitActivityLog,
    onSubmitCompleteTask,
    onSubmitRespondTask,
    onSubmitRespondOnLeadTask,
    onViewEmailClick,
    onDeleteTask,
    setTasks
  }
}

export default useLeadTasks
