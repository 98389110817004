import type { FC } from 'react'
import { Outlet } from 'react-router-dom'

import AuthLayout from 'components/layout/AuthLayout'

const ResetPasswordRouter: FC = () => (
  <AuthLayout>
    <Outlet />
  </AuthLayout>
)

export default ResetPasswordRouter
