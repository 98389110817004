import { PaymentTypeMap } from 'constants/names'

const getPaymentTypeName = (paymentType: number | null | undefined): string | null => {
  if (paymentType == null) {
    return null
  }

  return PaymentTypeMap[paymentType] ?? null
}

export default getPaymentTypeName
