import type { ColumnDef } from '@carfluent/common/dist/UI'

import BalanceCell from 'components/common/Table/cells/BalanceCell'
import type { AccountItem } from './types'
import TextCellHOC from '../components/cells/textСell'
import ValueCell from '../components/cells/valueCell'

export interface Columns {
  number: JSX.Element
  name: JSX.Element
  type: JSX.Element
  category: JSX.Element
  balance: JSX.Element
}

const columnDefinitions: Array<ColumnDef<AccountItem>> = [
  {
    accessorKey: 'number',
    cell: ValueCell,
    header: TextCellHOC('Number'),
    size: 160
  },
  {
    accessorKey: 'name',
    cell: ValueCell,
    header: TextCellHOC('Name'),
    minSize: 380,
    size: 600
  },
  {
    id: 'accountType',
    accessorFn: row => row.accountTypeName,
    cell: ValueCell,
    header: TextCellHOC('Type'),
    size: 260
  },
  {
    id: 'accountCategory',
    accessorFn: row => row.accountCategoryName,
    cell: ValueCell,
    header: TextCellHOC('Category'),
    size: 260
  },
  {
    accessorKey: 'balance',
    cell: BalanceCell,
    header: TextCellHOC('Balance'),
    size: 180
  }
]

export default columnDefinitions
