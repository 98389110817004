import {
  dateField,
  dictionaryItemDropdownField,
  transactionDescriptionField,
  transactionDueDate,
  transactionReferenceField
} from 'utils/validationPresets'

export default {
  dateTime: dateField(true),
  dueDate: transactionDueDate(true),
  referenceNumber: transactionReferenceField(),
  description: transactionDescriptionField(true),
  receivableAccount: dictionaryItemDropdownField(true),
  receivableEntity: dictionaryItemDropdownField(false),
  receivableControl: dictionaryItemDropdownField(false),
  memo: transactionDescriptionField()
}
