import { useMemo, type FC, type ReactNode } from 'react'
import { type SelectDisplayProps } from '@carfluent/common/dist/UI/Dropdown/types'

import FiltersPanel, { FilterItem, TabItem } from 'components/common/FiltersPanel'

import { TaskFilterEnum, type DictionaryItem } from 'api/types'
import OverlayedPageHOC from 'components/PageOverlay/OverlayedPageHOC'
import { CRM_TASKS_TAB_FILTERS } from 'constants/constants'
import BackToTop from 'components/common/BackToTop'
import Table from 'components/common/Table'
import IconSVGLeads from 'components/inlineImages/IconSVGLeads'
import IconSVGCreditApps from 'components/inlineImages/IconSVGCreditApps'
import IconSVGInbox from 'components/inlineImages/IconSVGInbox'
import IconSVGWorkplan from 'components/inlineImages/IconSVGWorkplan'
import IconSVGAppointments from 'components/inlineImages/IconSVGAppointments'
import { getDisplayItem, renderOptionWithCounter } from 'utils/selectHelpers'
import getFilterCounter from 'utils/filters/getFilterCounter'

import { DEFAULT_FILTER } from './hook/constants'
import useTasksList from './hook'

import { CLASS_NAME, POPOVER_CLASS_NAME, FILTERS_PANEL_CLASS_NAME } from './styles'
const renderSelectDisplay = ({ value }: SelectDisplayProps<DictionaryItem>): ReactNode => getDisplayItem(value)

const FILTER_ICONS_DICT: Record<number, ReactNode> = {
  1: <IconSVGAppointments />,
  2: <IconSVGLeads />,
  3: <IconSVGCreditApps />,
  4: <IconSVGInbox />,
  5: <IconSVGWorkplan />
}

const TasksList: FC = () => {
  const {
    isLoading,
    isFiltersChange,
    columns,
    rows,
    counters,
    emptyState,
    search,
    sorting,
    tabFilter,
    taskFilter,
    onSelectTab,
    onOpenLead,
    setSearch,
    setSorting,
    isInitLoading,
    onBottomReached,
    onSearch,
    onTaskFilterChange,
    taskListFiltersOptions
  } = useTasksList()

  const renderOption = useMemo(() => ({ name, id }: DictionaryItem): ReactNode => {
    const enumKey = TaskFilterEnum[id ?? DEFAULT_FILTER] as keyof typeof TaskFilterEnum

    return renderOptionWithCounter({
      isLoading,
      counter: counters.filters[enumKey],
      value: name
    })
  }, [counters, isLoading])

  const renderTabIcon = (tab: TabItem): ReactNode => {
    return FILTER_ICONS_DICT[tab.id] ?? null
  }

  const tasksFilter: FilterItem<'single-select', DictionaryItem<string>> = {
    filterType: 'single-select',
    componentProps: {
      id: 'tasks',
      mode: 'select',
      disableClearable: true,
      value: taskListFiltersOptions[taskFilter ?? DEFAULT_FILTER],
      options: taskListFiltersOptions,
      popoverClassName: POPOVER_CLASS_NAME,
      onChange: onTaskFilterChange,
      renderSelectDisplay,
      renderOption
    }
  }

  return (
    <div className={CLASS_NAME}>
      <FiltersPanel
        className={FILTERS_PANEL_CLASS_NAME}
        searchProps={{
          isLoading,
          onChange: setSearch,
          value: search,
          withDeleteIcon: true,
          onSearch,
          placeholder: 'Name, email, phone, car'
        }}
        filterProps={{ filters: [tasksFilter] }}
        tabProps={{
          tabs: CRM_TASKS_TAB_FILTERS.map((item) => {
            return ({
              ...item,
              isLoading: isInitLoading || (isLoading && item.id === tabFilter),
              counter: (
                item.id === tabFilter
                  ? counters.filters[TaskFilterEnum[taskFilter ?? DEFAULT_FILTER] as keyof typeof TaskFilterEnum]
                  : getFilterCounter(item, counters.states)
              )
            })
          }),
          activeTab: tabFilter,
          isLoading: isFiltersChange,
          showActiveIndicator: false,
          renderTabIcon,
          onSelectTab
        }}
      />

      <Table
        columns={columns}
        data={rows}
        emptyTableMessage={emptyState}
        isLoading={isLoading}
        isTabChanges={isFiltersChange}
        onBottomReached={onBottomReached}
        onSortingChange={setSorting}
        onRowClick={onOpenLead}
        sorting={sorting}
        headerDataTestId='tasks-table-header'
        bodyDataTestId='tasks-table-body'
      />

      <BackToTop />
    </div>
  )
}

export default OverlayedPageHOC(TasksList)
