import type { FC } from 'react'
import { cnx, FormInput, FormNumberInput } from '@carfluent/common'

import Collapse, { renderAddRemoveBtn } from 'components/common/Collapse'
import VinInput from 'components/common/VinInput'
import { MAX_PRICE_VALUE, ValidationLength } from 'constants/validation'
import type { TradeInSectionProps } from './types'

/**
 * @deprecated.
 * Used on Credit App.
 * It should be replaced with the TradeInDetailsWithDropdowns eventually
 */
const TradeInDetails: FC<TradeInSectionProps> = ({
  className,
  values,
  errors,
  touched,
  isTradeInSectionVisible,
  isFormDisabled,
  showAcvField = false,
  getCollapseBtnText,
  onApplyVin,
  onToggleIsTradeInSectionVisible,
  onChange,
  onBlur
}) => {
  return (
    <Collapse
      className={cnx(className, 'trade-in')}
      title='Trade In'
      isDisabled={isFormDisabled}
      isExpanded={isTradeInSectionVisible}
      getBtnText={getCollapseBtnText}
      renderCollapseBtn={renderAddRemoveBtn}
      toggleIsExpanded={onToggleIsTradeInSectionVisible}
    >
      <VinInput
        id='tradeInDetails.tradeInVehicleVIN'
        value={values.tradeInVehicleVIN}
        error={errors?.tradeInVehicleVIN}
        touched={touched?.tradeInVehicleVIN}
        disabled={isFormDisabled}
        onBlur={onBlur}
        onChange={onChange}
        onApply={onApplyVin}
      />
      <FormNumberInput
        id='tradeInDetails.tradeInVehicleYear'
        label='Year'
        thousandSeparator={null}
        value={values.tradeInVehicleYear}
        error={errors?.tradeInVehicleYear}
        touched={touched?.tradeInVehicleYear}
        disabled={isFormDisabled}
        onBlur={onBlur}
        onChange={onChange}
        maxLength={ValidationLength.YEAR_MAX}
      />
      <FormInput
        id='tradeInDetails.tradeInVehicleMake'
        label='Make'
        value={values.tradeInVehicleMake}
        error={errors?.tradeInVehicleMake}
        touched={touched?.tradeInVehicleMake}
        disabled={isFormDisabled}
        onBlur={onBlur}
        onChange={onChange}
        maxLength={ValidationLength.MAKE_MAX}
      />
      <FormInput
        id='tradeInDetails.tradeInVehicleModel'
        label='Model'
        value={values.tradeInVehicleModel}
        error={errors?.tradeInVehicleModel}
        touched={touched?.tradeInVehicleModel}
        disabled={isFormDisabled}
        onBlur={onBlur}
        onChange={onChange}
        maxLength={ValidationLength.MODEL_MAX}
      />
      <FormInput
        id='tradeInDetails.tradeInVehicleTrim'
        label='Trim'
        value={values.tradeInVehicleTrim}
        error={errors?.tradeInVehicleTrim}
        touched={touched?.tradeInVehicleTrim}
        disabled={isFormDisabled}
        onBlur={onBlur}
        onChange={onChange}
        maxLength={ValidationLength.TRIM_MAX}
      />
      <FormNumberInput
        id='tradeInDetails.tradeInVehicleMileage'
        label='Mileage'
        value={values.tradeInVehicleMileage}
        error={errors?.tradeInVehicleMileage}
        touched={touched?.tradeInVehicleMileage}
        disabled={isFormDisabled}
        onBlur={onBlur}
        onChange={onChange}
        maxLength={ValidationLength.MILEAGE_MAX}
      />
      <FormNumberInput
        id='tradeInDetails.tradeInCredit'
        preset='financial'
        label='Trade in credit'
        isNegativeAllowed={false}
        value={values.tradeInCredit}
        error={errors?.tradeInCredit}
        touched={touched?.tradeInCredit}
        disabled={isFormDisabled}
        max={MAX_PRICE_VALUE}
        onBlur={onBlur}
        onChange={onChange}
      />
      <FormNumberInput
        id='tradeInDetails.tradeInPayoff'
        label='Trade in payoff'
        preset='financial'
        isNegativeAllowed={false}
        value={values.tradeInPayoff}
        error={errors?.tradeInPayoff}
        touched={touched?.tradeInPayoff}
        disabled={isFormDisabled}
        max={MAX_PRICE_VALUE}
        onBlur={onBlur}
        onChange={onChange}
      />
      {
        showAcvField && (
          <FormNumberInput
            id='tradeInDetails.acv'
            label='Trade in ACV'
            preset='financial'
            isNegativeAllowed={false}
            value={values.acv}
            error={errors?.acv}
            touched={touched?.acv}
            disabled={isFormDisabled}
            max={MAX_PRICE_VALUE}
            onBlur={onBlur}
            onChange={onChange}
          />
        )
      }
    </Collapse>
  )
}

export default TradeInDetails
