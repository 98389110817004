import { type FC } from 'react'
import { FormNumberInput, Button, formatCurrencyDecimal } from '@carfluent/common'

import { decimalToPercent } from 'utils/math'
import CLASS_NAME from './styles'

export const INPUT_TAX_PRECISION = 3

export interface TaxEditorProps {
  disabled?: boolean
  helperAmountValue: number
  label: string
  onChangeInput: (id: string, value: number) => void
  onOverrideData: () => void
  taxValue: number | null
}

const TaxEditor: FC<TaxEditorProps> = ({
  disabled = false,
  helperAmountValue,
  label,
  onChangeInput,
  onOverrideData,
  taxValue
}) => {
  const salesTaxStr = formatCurrencyDecimal(helperAmountValue)

  return (
    <div className={CLASS_NAME}>
      <div className='form-fields'>
        <FormNumberInput
          className='sales-tax-input'
          id='tax'
          label={label}
          preset='percent'
          isEmptyAllowed={false}
          blurComplementMode='min-scale'
          mountComplementMode='min-scale'
          decimalPrecision={INPUT_TAX_PRECISION}
          value={decimalToPercent(taxValue ?? 0)}
          onChange={onChangeInput}
          disabled={disabled}
        />

        <p className='sales-tax-label'>
          Tax {salesTaxStr}
        </p>
      </div>

      <div className='apply-button-wrapper'>
        <Button
          onClick={onOverrideData}
          className='btn-apply'
          variant='contained'
          isDisabled={disabled}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}

export default TaxEditor
