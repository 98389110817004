import { type FC } from 'react'
import { FormDropdown, FormInput, FormMaskedInput, FormNumberInput, FormDatePicker } from '@carfluent/common'

import { isStringEmpty } from 'utils/parse_string'
import { DATE_MONTH_YEAR_FORMAT } from 'constants/constants'
import { ValidationLength } from 'constants/validation'
import { StatePicker } from 'components/form'
import InputSSN2 from 'components/form/components/InputSSN2'
import AddressFields from 'components/common/AddressFields'
import Collapse, { renderAddRemoveBtn } from 'components/common/Collapse'
import Checkbox from 'components/common/CheckboxNoMui'
import { isAddressDataEqual } from 'utils/address'
import { isPreviousEmploymentOrHousingNeeded } from 'utils/creditApplication'

import type { CoapplicantDetailsSectionProps } from '../../types'
import PreviousHousing from '../PreviousHousing'
import EmploymentDetails from '../EmploymentDetails'

const CoapplicantDetails: FC<CoapplicantDetailsSectionProps> = ({
  isFormDisabled,
  isCoApplicantSectionVisible,
  isPersonal,
  values,
  errors,
  touched,
  coApplicantTypes,
  housingStatuses,
  employmentStatuses,
  applicantAddressDetails,
  filterCoApplicantTypes,
  onChange,
  onBlur,
  onToggleIsCoapplicantSectionVisible
}) => {
  const noDriverLicense = isStringEmpty(values.driverLicenseNumber)
  const isDriverLicenseAndStateOptional = noDriverLicense && isStringEmpty(values.stateOfIssue)
  const coApplicantType = coApplicantTypes.find((item) => item.id === values.coApplicantType) ?? null
  const housingStatus = housingStatuses.find((item) => item.id === values.housingStatus) ?? null

  // DD-WARNING: passing filterOptions does not filter dropdown for the first time
  const coApplicantTypesFiltered = filterCoApplicantTypes(coApplicantTypes)

  const sameAddressAsMainApplicant = isAddressDataEqual(
    values.currentAddressDetails?.addressData,
    applicantAddressDetails
  )

  return (
    <Collapse
      className='credit-app-section co-applicant'
      isDisabled={isFormDisabled}
      isExpanded={isCoApplicantSectionVisible}
      title={isPersonal ? 'Co-buyer' : 'Co-buyer/Guarantor'}
      renderCollapseBtn={renderAddRemoveBtn}
      toggleIsExpanded={onToggleIsCoapplicantSectionVisible}
    >
      <FormInput
        id='coApplicantFinancialDetails.firstName'
        label='First name'
        value={values.firstName}
        error={errors?.firstName}
        touched={touched?.firstName}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormInput
        id='coApplicantFinancialDetails.lastName'
        label='Last name'
        value={values.lastName}
        error={errors?.lastName}
        touched={touched?.lastName}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormInput
        id='coApplicantFinancialDetails.email'
        label='Email'
        value={values.email}
        error={errors?.email}
        touched={touched?.email}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormMaskedInput
        id='coApplicantFinancialDetails.phoneNumber'
        label='Phone number'
        mask='phone'
        value={values.phoneNumber}
        error={errors?.phoneNumber}
        touched={touched?.phoneNumber}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormDatePicker
        id='coApplicantFinancialDetails.birthDate'
        label='Date of birth'
        isHidden={isFormDisabled}
        value={values.birthDate}
        error={errors?.birthDate}
        touched={touched?.birthDate}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
      />

      <InputSSN2
        id='coApplicantFinancialDetails.socialSecurityNumber'
        label='Social Security Number'
        value={values.socialSecurityNumber}
        error={errors?.socialSecurityNumber}
        touched={touched?.socialSecurityNumber}
        isDisabled={isFormDisabled}
        isInitialSsnVisible
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormInput
        id='coApplicantFinancialDetails.driverLicenseNumber'
        label={`Driver's license number ${isDriverLicenseAndStateOptional ? '(optional)' : ''}`}
        value={isFormDisabled
          ? values.driverLicenseNumber?.replace(/[^/]/g, '*') ?? null
          : values.driverLicenseNumber}
        error={errors?.driverLicenseNumber}
        touched={touched?.driverLicenseNumber}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={ValidationLength.DRIVER_LICENSE_MAX}
      />

      <StatePicker
        id='coApplicantFinancialDetails.stateOfIssue'
        label={`State of issue ${isDriverLicenseAndStateOptional ? '(optional)' : ''}`}
        value={values.stateOfIssue}
        error={errors?.stateOfIssue}
        touched={touched?.stateOfIssue}
        disabled={isFormDisabled || noDriverLicense}
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormDropdown
        id='coApplicantFinancialDetails.coApplicantType'
        label='Relationship'
        disabled={isFormDisabled}
        value={coApplicantType}
        error={errors?.coApplicantType}
        touched={touched?.coApplicantType}
        options={coApplicantTypesFiltered}
        onChange={(fieldId, val) => onChange(fieldId, val?.id)}
        onBlur={onBlur}
      />

      <hr className='credit-application-divider no-margin' />

      <h6>Co-buyer housing & employment</h6>

      <FormDropdown
        id='coApplicantFinancialDetails.housingStatus'
        className='coapp-housing-status'
        label='Housing'
        disabled={isFormDisabled}
        value={housingStatus}
        error={errors?.housingStatus}
        touched={touched?.housingStatus}
        options={housingStatuses}
        onChange={(fieldId, val) => onChange(fieldId, val?.id)}
        onBlur={onBlur}
      />

      <Checkbox
        label='Same address as main applicant'
        className='coapp-same-address'
        isDisabled={isFormDisabled}
        value={sameAddressAsMainApplicant}
        onChange={(val: boolean) => {
          onChange(
            'coApplicantFinancialDetails.currentAddressDetails.addressData',
            val ? applicantAddressDetails : null
          )
        }}
      />

      <AddressFields
        id='coApplicantFinancialDetails.currentAddressDetails.addressData'
        values={values.currentAddressDetails?.addressData ?? null}
        errors={errors?.currentAddressDetails?.addressData}
        touched={touched?.currentAddressDetails?.addressData}
        disabled={isFormDisabled}
        useNormalAptKey
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormDatePicker
        id='coApplicantFinancialDetails.currentAddressDetails.apartmentMoveInDate'
        label='Move-in date'
        disabled={isFormDisabled}
        value={values.currentAddressDetails?.apartmentMoveInDate ?? null}
        error={errors?.currentAddressDetails?.apartmentMoveInDate}
        touched={touched?.currentAddressDetails?.apartmentMoveInDate}
        onChange={onChange}
        onBlur={onBlur}
        format={DATE_MONTH_YEAR_FORMAT}
      />

      <FormNumberInput
        id='coApplicantFinancialDetails.currentAddressDetails.apartmentPayment'
        label='Payment'
        preset='financial'
        mode='integer'
        isNegativeAllowed={false}
        value={values.currentAddressDetails?.apartmentPayment ?? null}
        error={errors?.currentAddressDetails?.apartmentPayment}
        touched={touched?.currentAddressDetails?.apartmentPayment}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
        endAdornment='/month'
        maxLength={ValidationLength.MONTHLY_PAYMENT_MAX_LENGTH}
      />

      {isPreviousEmploymentOrHousingNeeded(values.currentAddressDetails?.apartmentMoveInDate) && (
        <PreviousHousing
          isFormDisabled={isFormDisabled}
          pathPrefix='coApplicantFinancialDetails.previousAddressDetails'
          addressData={values.previousAddressDetails?.addressData ?? null}
          addressDataErrors={errors?.previousAddressDetails?.addressData ?? null}
          addressDataTouched={touched?.previousAddressDetails?.addressData ?? null}
          appartmentMoveInDate={values.previousAddressDetails?.apartmentMoveInDate ?? null}
          appartmentMoveInDateErrors={errors?.previousAddressDetails?.apartmentMoveInDate ?? null}
          appartmentMoveInDateTouched={touched?.previousAddressDetails?.apartmentMoveInDate}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}

      <EmploymentDetails
        pathPrefix='coApplicantFinancialDetails'
        employmentStatuses={employmentStatuses}
        isFormDisabled={isFormDisabled}
        currentEmploymentDetails={values.currentEmploymentDetails}
        previousEmploymentDetails={values.previousEmploymentDetails}
        currentEmploymentDetailsErrors={errors?.currentEmploymentDetails}
        previousEmploymentDetailsErrors={errors?.previousEmploymentDetails}
        currentEmploymentDetailsTouched={touched?.currentEmploymentDetails}
        previousEmploymentDetailsTouched={touched?.previousEmploymentDetails}
        onChange={onChange}
        onBlur={onBlur}
      />

      <hr className='credit-application-divider no-margin' />

      <h3 className='text-info-header'>Co-buyer other monthly income</h3>
      <p className='text-info'>
        In accordance with the General Terms and Conditions, you are required to read the
        following statement to the applicant before you can request from the applicant the
        amount, if any, of "Other Income" and the "Source of Other Income". Alimony, child
        support, or separate maintenance income need not be disclosed if you do not wish
        to have it considered as a basis for repaying this obligation.
      </p>

      <FormInput
        id='coApplicantFinancialDetails.incomeOption'
        label='Income options'
        disabled={isFormDisabled}
        value={values.incomeOption}
        error={errors?.incomeOption}
        touched={touched?.incomeOption}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={ValidationLength.INCOME_OPTIONS_MAX}
      />

      <FormNumberInput
        id='coApplicantFinancialDetails.otherIncome'
        label='Income'
        preset='financial'
        mode='integer'
        isNegativeAllowed={false}
        value={values.otherIncome}
        error={errors?.otherIncome}
        touched={touched?.otherIncome}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
        endAdornment='/month'
        maxLength={ValidationLength.INCOME_MAX_LENGTH}
      />
    </Collapse>
  )
}

export default CoapplicantDetails
