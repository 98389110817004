import type { FC } from 'react'

const LogoIcon: FC = () => {
  return (
    <svg width='130' height='32' viewBox='0 0 130 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_22470_25465)'>
        <path d='M10.3681 20.1998L7.94001 28.8832L4.67017 25.972L7.88255 15.1889C8.59952 12.782 10.4835 11.5166 13.987 11.5166H29.76L29.7672 14.6505H13.987C12.593 14.6505 11.8978 15.3494 11.8978 16.7399V17.264H20.6742V20.3981L10.3681 20.2V20.1998Z' fill='#fff' />
        <path fillRule='evenodd' clipRule='evenodd' d='M16 0.000447127C20.2569 0.000447127 24.3036 1.67673 27.3137 4.68701C28.4847 5.85804 29.4536 7.186 30.201 8.62218H26.427C26.0125 8.03756 25.5466 7.48446 25.0315 6.96937C22.6284 4.56629 19.3988 3.22871 16.0004 3.22871C12.6018 3.22871 9.37223 4.56629 6.96915 6.96937C4.56606 9.37246 3.22871 12.6021 3.22871 16.0004C3.22871 19.3991 4.56629 22.6287 6.96915 25.0315C9.37223 27.4346 12.6018 28.7722 16.0004 28.7722C19.3988 28.7722 22.6287 27.4346 25.0315 25.0315C26.4102 23.6528 27.4382 22.002 28.0639 20.2037H31.4413C30.7179 22.8685 29.3087 25.319 27.3139 27.3139C24.3038 30.3239 20.2571 32.0004 16.0002 32.0004C11.7434 32.0004 7.69663 30.3242 4.68656 27.3139C1.6765 24.3038 0 20.2571 0 16.0002C0 11.7433 1.67628 7.69662 4.68656 4.68656C7.69663 1.6765 11.7434 0 16.0002 0L16 0.000447127ZM28.6993 14.6338C28.5848 13.5623 28.3367 12.5171 27.9636 11.5205H31.3633C31.6571 12.5303 31.8518 13.5725 31.9421 14.6338H28.6991H28.6993Z' fill='#fff' />
        <path d='M43.8604 20.3581V24.9045H40.553V17.4625C40.553 14.7082 42.2067 13.3281 45.5143 13.3281H53.783L53.7886 15.8088H45.5143C44.4108 15.8088 43.8606 16.3619 43.8606 17.4625V17.8774H53.783V20.3581H43.8606H43.8604Z' fill='#fff' />
        <path d='M57.7747 20.7711C57.7747 21.8746 58.3249 22.4248 59.4284 22.4248H67.6971V24.9055H59.4284C56.121 24.9055 54.467 23.5283 54.467 20.7711V13.3293H57.7744V20.7711H57.7747Z' fill='#fff' />
        <path d='M80.0973 13.3293H83.4021V21.5981C83.4021 23.8022 81.751 24.9055 78.4436 24.9055H73.4823C70.1749 24.9055 68.521 23.5283 68.521 20.7711V13.3293H71.8284V20.7711C71.8284 21.8746 72.3786 22.4248 73.4821 22.4248H78.4434C79.544 22.4248 80.0971 21.8746 80.0971 20.7711V13.3293H80.0973Z' fill='#fff' />
        <path d='M87.5369 20.3591V20.7711C87.5369 21.8746 88.0871 22.4248 89.1906 22.4248H97.4593V24.9055H89.1906C85.8832 24.9055 84.2292 23.5283 84.2292 20.7711V17.4637C84.2292 14.7094 85.8829 13.3293 89.1906 13.3293H97.4593L97.4649 15.81H89.1906C88.0871 15.81 87.5369 16.3631 87.5369 17.4637V17.8786H97.4593V20.3593H87.5369V20.3591Z' fill='#fff' />
        <path d='M102.036 16.6367C101.74 16.6367 101.591 16.7751 101.591 17.0517V24.9057H98.2832V17.0517C98.2832 14.571 99.6604 13.3293 102.418 13.3293C103.631 13.3293 104.593 13.8824 105.31 14.983L109.445 21.186C109.721 21.4597 109.987 21.5981 110.238 21.5981C110.537 21.5981 110.686 21.4597 110.686 21.186V13.3293H113.991V21.186C113.991 23.6667 112.614 24.9055 109.859 24.9055C108.646 24.9055 107.681 24.3553 106.964 23.2518L102.829 17.0517C102.561 16.7751 102.296 16.6367 102.036 16.6367Z' fill='#fff' />
        <path d='M123.914 15.81V24.9057H120.606V15.81H114.818V13.3293H129.699V15.81H123.914Z' fill='#fff' />
        <path d='M41.2597 9.42918C41.2597 8.43231 41.9854 7.76989 42.8559 7.76989C43.4805 7.76989 43.9663 8.01581 44.2755 8.40704V7.58053C43.9286 7.2776 43.4995 7.09473 42.8559 7.09473C41.5058 7.09473 40.553 8.07885 40.553 9.42918C40.553 10.7793 41.5056 11.7636 42.8559 11.7636C43.4995 11.7636 43.9286 11.5805 44.2755 11.2778V10.4513C43.9663 10.8425 43.4805 11.0885 42.8559 11.0885C41.9854 11.0885 41.2597 10.426 41.2597 9.42918V9.42918Z' fill='#fff' />
        <path d='M47.7965 8.435L48.4337 9.8041H47.1591L47.7963 8.435H47.7965ZM47.7965 6.96484L45.5566 11.6337H46.3139L46.869 10.435H48.7239L49.2792 11.6337H50.0364L47.7965 6.96484V6.96484Z' fill='#fff' />
        <path d='M52.0743 7.84745H52.7367C53.2353 7.84745 53.5442 8.09337 53.5442 8.57291C53.5442 9.05246 53.2351 9.2986 52.7367 9.2986H52.0743V7.84745ZM51.3804 7.21655V11.6333H52.0743V9.92972H52.5161L53.6833 11.6333H54.5036L53.2795 9.84767C53.8474 9.67106 54.2386 9.20426 54.2386 8.57314C54.2386 7.75914 53.5887 7.21655 52.737 7.21655H51.3804V7.21655Z' fill='#fff' />
      </g>
      <defs>
        <clipPath id='clip0_22470_25465'>
          <rect width='129.699' height='32' fill='#fff' />
        </clipPath>
      </defs>
    </svg>

  )
}

export default LogoIcon
