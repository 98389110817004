import type { ShiftDetailsFormData } from './types'

const defaultStartTime = '08:00 AM'
const defaultEndTime = '05:00 PM'

const getShiftInitialValues = (date?: Date | null): ShiftDetailsFormData => {
  const startTime = defaultStartTime
  const endTime = defaultEndTime

  const dateValue = date ?? new Date()

  return {
    date: dateValue,
    startTime,
    endTime,
    person: null
  }
}

export default getShiftInitialValues
