import { css } from '@emotion/css'

export const CONTAINER_STYLES = css(`
  .complete-task {
    display: grid;
    grid-template-areas:
      'dueToDate assignedTo priority'
      'description description description'
      'buttons buttons buttons';
    column-gap: 16px;
    row-gap: 0 !important;

    .lead-tasks-result-block {
      .label {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(33, 33, 33, 0.5);
      }

      img {
        height: 25px;
        position: relative;
        right: 5px;
      }
    }

    .task-dueToDate { grid-area: dueToDate; }
    .task-assignedTo { grid-area: assignedTo; }
    .task-priority { grid-area: priority; }
    .task-description { grid-area: description; }
  }

  .delete-lead-btn {
    display: flex;
    justify-content: center;
  }

  .lead-tasks-action-buttons {
    margin-top: 8px;
    line-height: 22px;
    width: max-content;
    border: 1px solid #C99B86;
    height: 26px;
    color: #000;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1.25px;
    } 
  }

  .radio-group {
    margin-top: 0;
    row-gap: 16px;

    .radio-item {
      margin-right: 32px !important;

      .MuiIconButton-root { padding: 0 9px 0 9px; }

      .MuiTypography-body1 { color: #101010; }
    }
  }

  .radio-group + .radios-title-error {
    .error-text:not(:empty) {
      margin-top: 8px;
    }
  }
`)

export const FORM_STYLES = css(`
  padding: 0 16px 16px 16px;

  .radios-title-error {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .error-text {
      color: #B00020;
      font-size: 12px;
      letter-spacing: 0.4px;
    }
  }

  .form-subtitle {
    margin: 16px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #101010;
  }

  .checkbox-group {
    margin-top: 4px;
    display: grid;
    row-gap: 4px;
    grid-template-columns: 1fr 1fr;

    .checkbox-item {
      max-width: fit-content;
    }

    &.no-radio-btn-option {
      display: flex;
      flex-direction: column;
    }
  }

  .column { grid-template-columns: 1fr; }

  .radio-group {
    margin-top: -12px;
    display: flex;

    .radio-item {
      max-width: fit-content;
      padding: 0;
      margin-right: 48px;
    }
  }

  .notes-field { margin-top: 16px; }

  .form {
    display: grid;
    row-gap: 24px;
    column-gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    'date time'
    'notes notes';

    .due-date {
      grid-area: date;  
      width: 100%;
    }

    .notes-field {
      grid-area: notes;

      .MuiFormLabel-root { color: rgba(33, 33, 33, 0.8); }
      
      .MuiFilledInput-root {  height: 78px; }
    }
  }

  .lead-tasks-action-buttons {
    grid-area: buttons;
    line-height: 22px;
    width: max-content;
    border: 1px solid #C99B86;
    height: 26px;
    margin-top: 16px;
    color: #000;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1.25px;
    }
  }

  &.no-option-selected {
    row-gap: 0;
    margin-top: 6px;
  }
`)
