import type { FC } from 'react'
import { Fragment } from 'react'
import { cn, formatters as fmt } from '@carfluent/common'

import { TransactionStateId } from 'api/types'
import { formatCurrencyDecimal } from 'utils/format_number'

export interface TransactionsReportLine {
  accountNumber: number | null
  accountName: string | null
  note: string | null
  referenceNo: string | null
  control: string
  debits: number | null
  credits: number | null
}

export interface TransactionsTableData {
  date: Date | string | null
  description: string | null
  transactionStateId: TransactionStateId | null
  lines: TransactionsReportLine[]
  total?: boolean
}

export interface TransactionsTableProps extends TransactionsTableData {
  className?: string
}

const TransactionsTable: FC<TransactionsTableProps> = ({
  className,
  date,
  description,
  transactionStateId,
  total,
  lines
}) => {
  const FilledTransactionStateId = transactionStateId === TransactionStateId.Filled

  return (
    <tbody className={cn('cf-lines-table', className)}>
      {lines.map((l, lIdx) => (
        <Fragment key={lIdx}>
          {
            (total == null && lIdx === 0) && (
              <tr className='cf-grouped-row'>
                <td className='cf-date-cell'>
                  {fmt.formatLocalDate(date, 'M/d/yyyy')}
                </td>
                <td colSpan={5} className='cf-description-cell'>{description}</td>
                {/* for PDF report needs empty td for correct display */}
                <td colSpan={2} />
              </tr>
            )
          }
          <tr>
            <td className='cf-account-cell'>{total === true ? 'Total' : l.accountNumber}</td>
            <td className='cf-name-cell'>{l.accountName}</td>
            <td className='cf-reference-cell'>{l.referenceNo}</td>
            <td className='cf-control-cell'>{l.control}</td>
            <td className='cf-notes-cell'>{l.note}</td>
            <td className='cf-money-cell'>{FilledTransactionStateId ? formatCurrencyDecimal(l.debits) : ''}</td>
            <td className='cf-money-cell'>{FilledTransactionStateId ? formatCurrencyDecimal(l.credits) : ''}</td>
          </tr>
        </Fragment>
      ))}
    </tbody>
  )
}

export default TransactionsTable
