import { css } from '@emotion/css'

export default css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;

  .image-wrapper {
    width: 100%;
    max-width: 174px;
    margin-bottom: 32px;
  }

  .title {
    margin-bottom: 16px;
    color: #101010;
  }

  .text {
    line-height: 16px;
    letter-spacing: 0.4px;
  }
`
