import IconSVGClose from './IconSVGClose'
import IconSVGLock from './IconSVGLock'
import IconSVGUnlock from './IconSVGUnlock'
import IconSVGClip from './IconSVGClip'
import IconSVGReply from './IconSVGReply'
import IconSVGForwardToInbox from './IconSVGForwardToInbox'
import IconSVGMail from './IconSVGMail'
import IconSVGAddTemplate from './IconSVGAddTemplate'
import IconSVGTick from './IconSVGTick'
import IconSVGImage from './IconSVGImage'
import IconSVGUploadFile from './IconSVGUploadFile'
import IconSVGManageAccount from './IconSVGManageAccount'
import IconSVGLogout from './IconSVGLogout'
import IconSVGUserManagement from './IconSVGUserManagement'
import IconSVGMapLocation from './IconSVGMapLocation'
import IconSVGCheckboxChecked from './IconSVGCheckboxChecked'
import IconSVGCheckboxUnchecked from './IconSVGCheckboxUnchecked'
import IconSVGCheckboxIndeterminate from './IconSVGCheckboxIndeterminate'
import IconSVGDownload from './IconSVGDownload'
import IconSVGDocument from './IconSvgDocument'
import IconSVGCheckFilled from './IconSVGCheckFilled'
import IconSVGDeleteIcon from './IconSVGDeleteIcon'
import IconSVGSearchIcon from './IconSVGSearchIcon'
import IconSVGExclamationMark from './IconSVGExclamationMark'
import IconSVGDropdownArrowDown from './IconSVGDropdownArrowDown'
import IconSVGDropdownArrowUp from './IconSVGDropdownArrowUp'
import DragNDropIcon from './DragNDrop'
import IconSVGCross from './IconSVGCross'
import IconSVGAdd from './IconSVGAdd'
import IconSvgAssignment from './IconSvgAssignment'
import IconSVGBank from './IconSVGBank'
import IconSVGBankStatement from './IconSVGBankStatement'
import IconSVGStatusCheckmark from './IconSVGStatusCheckmark'
import IconSVGStatusPending from './IconSVGStatusPending'
import IconSVGCheckedCircle from './IconSVGCheckedCircle'
import IconSVGArrowRight from './IconSVGArrowRight'
import IconSVGArrowLeft from './IconSVGArrowLeft'
import IconSVGCheck from './IconSvgCheck'
import IncreaseArrowIcon from './IncreaseArrowIcon'
import DecreaseArrowIcon from './DecreaseArrowIcon'
import AutomaticallyIcon from './AutomaticallyIcon'
import IconSVGCrmIcon from './IconSVGCrmIcon'
import IconSVGAccounting from './IconSVGAccounting'
import IconNotirized from './IconNotirized'
import IconCheckedDoc from './IconCheckedDoc'
import IconErrorDoc from './IconErrorDoc'
import IconUserDocuments from './IconUserDocuments'
import IconSVGPencil from './IconSVGPencil'
import IconSVGPrint from './IconSVGPrint'
import IconSvgTrashBasket from './IconSvgTrashBasket'
import IconSvgPlayer from './IconSvgPlayer'
import IconSvgCrown from './IconSvgCrown'
import IconSVGBot from './IconSvgBot'
import IconSVGUnableToEmail from './IconSVGUnableToEmail'
import IconSVGUnableToMessage from './IconSVGUnableToMessage'
import IconSVGMessage from './IconSVGMessage'
import IconSVGArrowStep from './IconSVGArrowStep'
import IconSVGPeople from './IconSVGPeople'
import IconSVGThumbUp from './IconSVGThumbUp'
import IconSVGFail from './IconSVGFail'
import IconSVGComment from './IconSVGComment'
import IconSVGNaIcon from './IconSVGNaIcon'
import IconSVGBlock from './IconSVGBlock'
import CheckFilledIcon from './CheckFilledIcon'
import IconSVGWarrantyServiceContract from './IconSVGWarrantyServiceContract'
import IconSVGWarrantyAppearanceProtection from './IconSVGWarrantyAppearanceProtection'
import IconSVGWarrantyGapInsurance from './IconSVGWarrantyGapInsurance'
import IconSVGWarrantyTireAndWheel from './IconSVGWarrantyTireAndWheel'
import IconSVGWarrantyTheftProtection from './IconSVGWarrantyTheftProtection'
import IconSVGServiceContract from './IconSVGServiceContract'
import IconSVGAppearanceProtection from './IconSVGAppearanceProtection'
import IconSVGGAPInsurance from './IconSVGGAPInsurance'
import IconSVGTireWheel from './IconSVGTireWheel'
import IconSVGTheftInsurance from './IconSVGTheftInsurance'
import IconSVFCar from './IconSVFCar'
import IconSVGInfo from './IconSVGInfo'
import IconSVGSuitcase from './IconSVGSuitcase'
import IconSVGMuteBell from './IconSVGMuteBell'
import IconSVGBell from './IconSVGBell'
import IconSVGSound from './IconSVGSound'

const IconSVG = (): null => null

IconSVG.Bank = IconSVGBank
IconSVG.Close = IconSVGClose
IconSVG.Lock = IconSVGLock
IconSVG.Unlock = IconSVGUnlock
IconSVG.Clip = IconSVGClip
IconSVG.Reply = IconSVGReply
IconSVG.EmailOutbound = IconSVGForwardToInbox
IconSVG.EmailInbound = IconSVGMail
IconSVG.AddTemplate = IconSVGAddTemplate
IconSVG.Tick = IconSVGTick
IconSVG.Image = IconSVGImage
IconSVG.UploadFile = IconSVGUploadFile
IconSVG.ManageAccount = IconSVGManageAccount
IconSVG.UserManagement = IconSVGUserManagement
IconSVG.Logout = IconSVGLogout
IconSVG.MapLocation = IconSVGMapLocation
IconSVG.CheckboxChecked = IconSVGCheckboxChecked
IconSVG.CheckboxUnchecked = IconSVGCheckboxUnchecked
IconSVG.CheckboxIndeterminate = IconSVGCheckboxIndeterminate
IconSVG.Download = IconSVGDownload
IconSVG.CheckFilled = IconSVGCheckFilled
IconSVG.DeleteIcon = IconSVGDeleteIcon
IconSVG.SearchIcon = IconSVGSearchIcon
IconSVG.ExclamationMark = IconSVGExclamationMark
IconSVG.DropdownArrowDown = IconSVGDropdownArrowDown
IconSVG.DropdownArrowUp = IconSVGDropdownArrowUp
IconSVG.DragNDropIcon = DragNDropIcon
IconSVG.Cross = IconSVGCross
IconSVG.StatusCheckmark = IconSVGStatusCheckmark
IconSVG.StatusPending = IconSVGStatusPending
IconSVG.Bank = IconSVGBank
IconSVG.BankStatement = IconSVGBankStatement
IconSVG.IconSvgAssignment = IconSvgAssignment
IconSVG.CheckedCircle = IconSVGCheckedCircle
IconSVG.Add = IconSVGAdd
IconSVG.IconSVGDocument = IconSVGDocument
IconSVG.IconSVGArrowRight = IconSVGArrowRight
IconSVG.ArrowRight = IconSVGArrowRight
IconSVG.IconSVGArrowLeft = IconSVGArrowLeft
IconSVG.IconSVGCheck = IconSVGCheck
IconSVG.IconSVGCrmIcon = IconSVGCrmIcon
IconSVG.IconSVGAccounting = IconSVGAccounting
IconSVG.IncreaseArrowIcon = IncreaseArrowIcon
IconSVG.DecreaseArrowIcon = DecreaseArrowIcon
IconSVG.AutomaticallyIcon = AutomaticallyIcon
IconSVG.Pencil = IconSVGPencil
IconSVG.Notirized = IconNotirized
IconSVG.CheckedDoc = IconCheckedDoc
IconSVG.ErrorDoc = IconErrorDoc
IconSVG.UserDocuments = IconUserDocuments
IconSVG.Print = IconSVGPrint
IconSVG.TrashBasket = IconSvgTrashBasket
IconSVG.Player = IconSvgPlayer
IconSVG.Crown = IconSvgCrown
IconSVG.Bot = IconSVGBot
IconSVG.UnableToEmail = IconSVGUnableToEmail
IconSVG.UnableToMessage = IconSVGUnableToMessage
IconSVG.Message = IconSVGMessage
IconSVG.ArrowStep = IconSVGArrowStep
IconSVG.People = IconSVGPeople
IconSVG.ThumbUp = IconSVGThumbUp
IconSVG.Fail = IconSVGFail
IconSVG.Comment = IconSVGComment
IconSVG.NaIcon = IconSVGNaIcon
IconSVG.Block = IconSVGBlock
IconSVG.WarrantyServiceContract = IconSVGWarrantyServiceContract
IconSVG.WarrantyAppearanceProtection = IconSVGWarrantyAppearanceProtection
IconSVG.WarrantyGapInsurance = IconSVGWarrantyGapInsurance
IconSVG.WarrantyTireAndWheel = IconSVGWarrantyTireAndWheel
IconSVG.WarrantyTheftProtection = IconSVGWarrantyTheftProtection
IconSVG.CheckFilled = CheckFilledIcon
IconSVG.ServiceContract = IconSVGServiceContract
IconSVG.AppearanceProtection = IconSVGAppearanceProtection
IconSVG.GAPInsurance = IconSVGGAPInsurance
IconSVG.TireWheel = IconSVGTireWheel
IconSVG.TheftInsurance = IconSVGTheftInsurance
IconSVG.Car = IconSVFCar
IconSVG.Info = IconSVGInfo
IconSVG.Suitcase = IconSVGSuitcase
IconSVG.MuteBell = IconSVGMuteBell
IconSVG.Bell = IconSVGBell
IconSVG.Sound = IconSVGSound

export default IconSVG
