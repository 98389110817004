import { css } from '@emotion/css'

export default css(`
  opacity: 0px;
  margin: 16px 0;
  background: #fff;
  border-radius: 12px;
  padding: 24px 16px 16px 16px;

  .MuiSkeleton-text {
    height: 16px;
  }

  .history-header-skeleton {
    padding: 8px;
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;

    .MuiSkeleton-text {
      max-width: 200px;
    }
  }

  .table-container-skeleton {
    width: 100%;
    padding: 8px;
    display: flex;
    border-radius: 4px;
    background: #F3F3F3;
    flex-direction: column;
  }

  .row-skeleton {
    display: flex;

    :not(:last-of-type) {
      margin-bottom: 8px;
    }

    :first-of-type {
      margin-bottom: 8px;
      background: #FAFAFA;

      .cell-skeleton {
        background: #FAFAFA;
      }
    }
  }

  .cell-skeleton {
    flex: 1;
    height: 50px;
    display: flex;
    background: #fff;
    padding: 16px 8px;
    align-items: center;

    .MuiSkeleton-text {
      max-width: 100px;
    }
  }

  .helper-text-skeleton {
    font-size: 14px;
    margin-top: 16px;
    line-height: 20px;
    color: #21212150;
    letter-spacing: 0.25px;
  }
`)
