import { type FC } from 'react'
import { type MenuIconProps } from 'types'

const ReportsMenuIcon: FC<MenuIconProps> = ({
  isActive = false
}) => {
  const pictogramFill = isActive ? '#fff' : '#101010'

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_85423_189355)'>
        <path
          d='M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM9 17H7V10H9V17ZM13 17H11V7H13V17ZM17 17H15V13H17V17Z'
          fill={pictogramFill}
        />
      </g>

      <defs>
        <clipPath id='clip0_85423_189355'>
          <rect width='24' height='24' fill={pictogramFill} />
        </clipPath>
      </defs>
    </svg>

  )
}

export default ReportsMenuIcon
