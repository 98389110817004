import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  margin-left: 24px;
  position: relative;

  .action-bar {
    display: inline-flex!important;
    opacity: 1!important;
  }

  .no-data {
    width: 100%;
    opacity: 1!important;
    display: inline-flex!important;
    height: 64px;
    padding: 12px 8px;
    margin-top: 8px;
    align-items: center;
    justify-content: center;

    p {
      text-wrap: nowrap;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }

  .summary-info {
    display: flex!important;
    opacity: 1!important;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.12);

    &-item {
      height: 60px;
      display: flex;
      padding: 8px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 1 0 0;

      .cf-tooltip-container > p, .info-value {
        font-weight: 500;
        letter-spacing: 0.4px;
      }
    }
  }

  .cf-table {
    margin-right: 24px;
    background: #F3F3F3;
    border-radius: 0 12px 0 0;

    tr:first-of-type th:last-of-type {
      border-radius: 0 12px 0 0;
    }

    .tooltip-container {
      height: 36px;
      padding: 8px!important;
    }

    tbody {
      tr {
        border-top: 1px solid #0000001F;
        border-bottom: 1px solid #0000001F;
        height: 32px;

        &.cf-table-row-hover:hover {
          .cf-cell-wrapper > a {
            background: transparent;
          }

          > td:first-child > div {
            border-left-color: transparent;
          }
        }
        .cf-table-header-title {
          box-shadow: 4px 0px 12px 2px #0000000D;
        }
        td {
          :nth-of-type(1) {
            div {
              &.cf-cell-wrapper {
                padding: 12px 8px 12px 16px;
              }
            }
          }
          :nth-of-type(2),
          :nth-of-type(3),
          :nth-of-type(4) {
            div {
              &.cf-cell-wrapper {
                padding: 12px 8px;
              }
            }
          }
          :nth-of-type(5) {
            div.cf-cell-wrapper {
              padding-left: 16px;
            }
          }

          div {
            text-wrap: nowrap;

            &.cf-cell-wrapper {
              padding: 12px 8px;
            }
          }
        }
      }
      .cf-table-group-cell {
        box-shadow: 4px 0px 12px 2px #0000000D;
      }
    }

    thead {
      tr {
        th {
          &.cf-table-header-cell {
            height: 36px;

            .cf-table-header-title {
              height: 36px;
              > div {
                span, p {
                  font-size: 14px;
                  font-weight: 500;
                  letter-spacing: 0.4px;
                }
              }
            }
          }
        }
      }
    }
  }

  .MuiTableContainer-root.cf-table-wrapper {
    width: auto;
    overflow-x: auto!important;
    position: relative;
  }

  .MuiTableContainer-root.skeleton-table-wrapper {
    position: sticky;
    left: 0;
  }

  .cf-table-header-row:nth-of-type(1) th {
    padding-left: 16px;

    &:nth-of-type(1) {
      left: 0;
      z-index: 3;
      position: sticky;
      background: #F3F3F3;
    }
  }

  .cf-table-header {
    .cf-table-header-row:nth-of-type(1){
      display: none;
    }
  }

  .cf-table-footer {
    .cf-table-footer-row {

      &:nth-of-type(1) {
        td {
          background-color: #fff;
          border-bottom: 0;

          :nth-of-type(1) {
            left: 0;
            z-index: 3;
            position: sticky;
            
          }
      
          :nth-of-type(2) {
            z-index: 3;
            left: 120px;
            position: sticky;
          }
      
          :nth-of-type(3) {
            z-index: 4;
            left: 220px;
            position: sticky;
          }
      
          :nth-of-type(4) {
            padding: 0;
            z-index: 3;
            left: 460px;
            position: sticky;
            overflow-y: clip;

            div {
              width: 100%;
              height: 48px;
              box-shadow: rgba(0, 0, 0, 0.05) 4px 0px 12px 2px;
            }
           
          }
        }
      }

      &:nth-of-type(2) {
        display: none;
      }
    }
    .cf-table-footer-cell {
      color: #101010;
      text-align: left;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      padding: 12px 8px;
      letter-spacing: 0.5px;

      :not(:nth-of-type(5)) {
        text-align: right;
        padding-right: 7px;
      }

      :nth-of-type(5) {
        padding-left: 16px;
      }
    }
  }

  .cf-table-header-row:nth-of-type(2) th {
    border-radius: 12px 12px 0 0;
  }

  /**
   * Add box-shadow for the fixed table side end
   */

  .cf-divider-left,
  .cf-divider-right {
    display: none;
  }

  .cf-group-title {
    padding: 0px 16px;
    box-sizing: border-box;

    p {
      font-weight: 500;
    }
  }

  .cf-table-wrapper{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    ::-webkit-scrollbar {
      display: none; /* For Webkit browsers */
    }

    .cf-no-data-block {
      left: 0;
      border-top: 0;
      display: flex;
      padding: 16px;
      font-size: 16px;
      position: sticky;
      background: #fff;
      line-height: 24px;
      letter-spacing: 0.5px;
      justify-content: flex-start;
      border-top: 1px solid #0000001F;
      border-bottom: 1px solid #0000001F;
    }
  }

  /**
   * Fix the titles of the left side of the table
   */
  .cf-table-header-row:nth-of-type(2) th {
    background: #F3F3F3;

    :nth-of-type(1) {
      left: 0;
      z-index: 3;
      position: sticky;
    }

    :nth-of-type(2) {
      z-index: 3;
      left: 120px;
      position: sticky;
    }

    :nth-of-type(3) {
      z-index: 4;
      left: 220px;
      position: sticky;
    }

    :nth-of-type(4) {
      z-index: 3;
      left: 460px;
      position: sticky;

      div {
        width: 100%;
        box-shadow: 4px 0px 12px 2px #0000000D;
      }
    }
  }

  /**
   * Fix the cells of the left side of the table
   */
  .cf-table-row td,
  .cf-table-group-row td {
    :nth-of-type(1) {
      left: 0;
      z-index: 3;
      position: sticky;
    }

    :nth-of-type(2) {
      z-index: 3;
      left: 120px;
      position: sticky;
    }

    :nth-of-type(3) {
      z-index: 4;
      left: 220px;
      position: sticky;
    }

    :nth-of-type(4) {
      z-index: 3;
      left: 460px;
      position: sticky;
      overflow-y: clip;

      > div {
        box-shadow: 4px 0px 12px 2px #0000000D;
      }
    }
  }

  .cf-table-group-row td.cf-table-group-cell {
    > div {
      height: 32px;
      box-shadow: rgba(0, 0, 0, 0.05) 4px 0px 12px 2px;
    }
  }

  .table-footer {
    display: flex;
    position: absolute;
    left: 0;

    .empty-footer-block {
      width: 560px;
    }

    .table-sum-row {
      display: flex;
      padding: 12px 16px; 
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.5px;

      .sum-column {
        width: 480px;
      }

      > div {
        min-width: 140px;
      }
    }
  }

  .right-align {
    text-align: right;

    .cf-table-header-title {
      justify-content: end;
    }
  }

  .left-padding {
    .tooltip-container {
      padding-left: 16px!important;
    }
  }
`)

export const ROOT_CLASS_NAME = css(`
  margin-left: 0!important;

  .cf-md {
    margin: 0 24px;
    width: calc(100% - 48px);
  }

  .cf-report-top-actions {
    margin-top: 24px;
    margin-bottom: 24px!important;
  }
`)
