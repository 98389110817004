import { onlyNumbersAndDot, onlyNumbers } from './format_number'

/**
 * Removes anything except numbers and dot character from parsed string,
 * then converts it to Number
 */
export const parseNumberSafe = (value: string): number => {
  if (value === '') {
    return 0
  }

  return Number(onlyNumbersAndDot(value))
}

/**
 * Removes anything except numbers from parsed string,
 * then converts it to Number
 */
export const parseIntegerSafe = (value: string): number => Number(onlyNumbers(value))

export const toNumberOrNull = (value: number | string | null | undefined): number | null => {
  if (typeof value === 'number') {
    return value
  }

  if ((value == null) || (value === '')) {
    return null
  }

  const result = parseIntegerSafe(value)
  if (isNaN(result)) {
    return null
  }

  return result
}

export const isStringEmpty = (val?: string | null): boolean => val == null || val === ''

export const isStringNotEmpty = (val?: string | null): boolean => !isStringEmpty(val)
