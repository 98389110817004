import type { FC } from 'react'
import Carousel from 'react-material-ui-carousel'
import { Button, Modal } from '@carfluent/common'

import IconSVG from 'components/inlineImages'
import { type DealerProductCoverageTemplateDto } from 'api/types/responses'

import CLASS_NAME from './styles'

export interface TemplatePreviewsProps {
  setOpenedView: (value: number | null) => void
  coverageTemplates: DealerProductCoverageTemplateDto[]
  openedView: number | null
  dealerCoverageViewTemplateId: number | null
  onChangeTemplate: (value: number) => void
}

const NAV_BUTTONS_WRAPPER_PROPS = { className: 'nav-button-wrapper' }
const NAV_BUTTONS_PROPS = { className: 'nav-button' }
const MODAL_MIN_WIDTH = 800

const TemplatePreviews: FC<TemplatePreviewsProps> = ({
  setOpenedView,
  coverageTemplates,
  openedView,
  dealerCoverageViewTemplateId,
  onChangeTemplate
}) => {
  return (
    <Modal
      isOpen={openedView != null}
      onClose={() => setOpenedView(null)}
      title='Templates'
      minWidth={MODAL_MIN_WIDTH}
    >
      <div className={CLASS_NAME}>
        <Carousel
          className='carousel'
          autoPlay={false}
          navButtonsWrapperProps={NAV_BUTTONS_WRAPPER_PROPS}
          navButtonsProps={NAV_BUTTONS_PROPS}
          fullHeightHover={false}
          navButtonsAlwaysVisible
          indicators={false}
          animation='slide'
          index={openedView ?? 0}
          NextIcon={<IconSVG.IconSVGArrowRight />}
          PrevIcon={<IconSVG.IconSVGArrowLeft />}
        >
          {
            coverageTemplates.map(({ imageUrl, id, name, description }, index) => {
              const isSelected = dealerCoverageViewTemplateId === id

              return (
                <div className='main-container' key={`template-modal-${id}`}>
                  <div className='view-img-wrapper'>
                    <img
                      className='view-img'
                      src={imageUrl}
                      alt={`View ${index}`}
                    />
                  </div>
                  <p className='name-of-view'>{name}</p>
                  <div className='description'>
                    {description}
                  </div>

                  <Button
                    isDisabled={isSelected}
                    className='select-btn'
                    onClick={() => {
                      onChangeTemplate(id)
                      setOpenedView(null)
                    }}
                  >
                    {isSelected ? 'Applied' : 'Apply'}
                  </Button>
                </div>
              )
            })
          }
        </Carousel>
      </div>
    </Modal>
  )
}

export default TemplatePreviews
