import type { FC } from 'react'
import type { IconProps } from 'types'

const IconCheckedDoc: FC<IconProps> = ({
  width = 24,
  height = 24
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 16.9981L5 11.9981L6.41 10.5881L10 14.1681L17.59 6.57812L19 7.99813L10 16.9981Z" fill="#00C4B4"/>
    </svg>
  )
}

export default IconCheckedDoc
