import { type Preset, dateToString, PRESET_LABELS, DEFAULT_MIN_DATE, DEFAULT_MAX_DATE } from '@carfluent/common'
import type { DateType } from 'api/types'
import type { PeriodFilterProps } from './types'

export const getBaseValueData = (preset: Preset | null, appliedFilters?: DateType): PeriodFilterProps => {
  const isAllPresetSelected = preset?.name === PRESET_LABELS.ALL
  const isAllTillTodayPresetSelected = preset?.name === PRESET_LABELS.ALL_TILL_TODAY

  return {
    activeDatePreset: preset,
    endDate: isAllPresetSelected ? '' : dateToString(preset?.endDate ?? appliedFilters?.to),
    appliedFilters: {
      to: preset?.endDate ?? appliedFilters?.to ?? DEFAULT_MAX_DATE,
      from: preset?.startDate ?? appliedFilters?.from ?? DEFAULT_MIN_DATE
    },
    startDate: isAllTillTodayPresetSelected || isAllPresetSelected ? '' : dateToString(preset?.startDate ?? appliedFilters?.from)
  }
}
