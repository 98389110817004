import { parsers, dates } from '@carfluent/common'
import { formatDate as _formatDate } from 'utils/parse_date'

const { parseDateStringUTC } = parsers

export const DATE_PART = 'MM/dd/yy'
export const HOURS_PART = 'hh:mm'
const FORMAT_DATE = `${DATE_PART} ${HOURS_PART} aa`
const EMPTY_DATE_VALUE = '-'

const formatDate = (date: string): string => {
  const parsedDate = parseDateStringUTC(date)

  return parsedDate != null
    ? _formatDate(parsedDate, FORMAT_DATE) ?? EMPTY_DATE_VALUE
    : EMPTY_DATE_VALUE
}

/**
 * Transforms Object dates to UTC string type: "04/21/ 23 12: 54 PM"
 */

export const convertDateToUTCString = (initialDate: string): string | undefined => {
  const convertedDate = dates.convertToUTC(initialDate)
  if (convertedDate != null) return formatDate(convertedDate.toUTCString())
}
