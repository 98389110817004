import { type FC, useCallback, useMemo } from 'react'
import { FormInput } from '@carfluent/common'

import { Checkbox } from 'components/form'
import CheckBoxIcon from 'components/accounting/CheckBoxIcon'
import RadioGroup from 'components/common/RadioGroup'
import ActionBar from 'components/crm/ActionBar'
import { TaskCompleteOptions } from 'pages/crm/LeadDetailsView/hook/types'
import DateTimeInputs from 'pages/crm/LeadDetailsView/components/LeadDetailsTasks/components/DateTimeInputs/index'
import type { UseCompleteTaskFormReturn } from 'pages/crm/LeadDetailsView/components/LeadDetailsTasks/components/TaskComplete/hook/types'
import { APPT_RADIO_OPTIONS, FieldIds } from './constants'

import CLASS_NAME from './styles'

const CompleteAppointmentTaskForm: FC<UseCompleteTaskFormReturn> = ({
  values,
  errors,
  touched,
  taskMode,
  isSubmitting,
  onBlur,
  onChange,
  onChangeSelectedCheckbox,
  onSubmit,
  onCloseTask
}) => {
  const { date, time, taskCompleteOptionId, taskCompleteSubOptionIds, workNotes } = values

  const radioSubOptions = useMemo(() => (
    APPT_RADIO_OPTIONS.find(opt => opt.value === taskCompleteOptionId)?.subOptions
  ), [taskCompleteOptionId])

  const toShowShowedUpOn = taskCompleteOptionId === TaskCompleteOptions.Yes ||
    taskCompleteOptionId === TaskCompleteOptions.UnreportedShow

  const showRadioError = touched[FieldIds.TaskCompleteOptionId] && errors[FieldIds.TaskCompleteOptionId]
  const showCheckboxError = touched[FieldIds.TaskCompleteOptionId] && (taskCompleteSubOptionIds.length === 0) && (errors[FieldIds.TaskCompleteSubOptionIds])

  const errorText = taskCompleteOptionId == null ? showRadioError : showCheckboxError

  const isRadioBtnSelected = taskCompleteOptionId != null
  const isNoRadioBtnSelected = taskCompleteOptionId === TaskCompleteOptions.No

  const onChangeRadioOption = useCallback((_: any, value: string, id: string): void => {
    onChange(id, value)
    onChange(FieldIds.TaskCompleteSubOptionIds, [])
  }, [onChange])

  const onCancel = useCallback((): void => {
    onCloseTask(null)
  }, [onCloseTask])

  const isSubOptionChecked = useCallback((id: string): boolean => (
    Boolean(values.taskCompleteSubOptionIds?.find(opt => opt.id === id))
  ), [values.taskCompleteSubOptionIds])

  return (
    <div className={CLASS_NAME}>
      <div className='radios-title-error'>
        <p className='form-subtitle'>Did make it into the store?</p>
        <p className='error-text'>{errorText}</p>
      </div>

      <RadioGroup
        items={APPT_RADIO_OPTIONS}
        value={taskCompleteOptionId}
        onChange={onChangeRadioOption}
        id={FieldIds.TaskCompleteOptionId}
        classes={{ root: 'radio-group', item: 'radio-item' }}
      />

      {isRadioBtnSelected && (
        <div className={`checkbox-group ${isNoRadioBtnSelected ? 'no-radio-btn-option' : ''}`}>
          {radioSubOptions?.map(option => (
            <Checkbox
              classes={{ control: 'checkbox-item' }}
              value={isSubOptionChecked(option.id)}
              onChange={onChangeSelectedCheckbox}
              icon={<CheckBoxIcon />}
              label={option.name}
              id={option.id}
              key={option.id}
            />
          ))}
        </div>)}

      {toShowShowedUpOn && (
        <>
          <p className='showed-up-on-title'>
            Showed up on
          </p>

          <div className='complete-task-form'>
            <DateTimeInputs
              shouldGetCurrentDate
              dueDateTouched={touched.date}
              dueDateError={errors.date}
              timeError={errors.time}
              dateId={FieldIds.Date}
              timeId={FieldIds.Time}
              dueDate={date}
              time={time}
              onDateChange={onChange}
              onTimeChange={onChange}
              onBlur={onBlur}
            />
          </div>
        </>
      )}

      <FormInput
        value={workNotes}
        onChange={onChange}
        id={FieldIds.Notes}
        label='Work notes'
        className={`notes-field ${!toShowShowedUpOn ? 'notes-field-margin' : ''}`}
      />

      <div className='lead-complete-task-action-buttons-container'>
        <ActionBar
          buttonsClassName='lead-tasks-action-buttons'
          taskMode={taskMode}
          onSubmit={onSubmit}
          onCancel={onCancel}
          isLoading={isSubmitting}
        />
      </div>
    </div>
  )
}

export default CompleteAppointmentTaskForm
