import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'
import DealAction from 'components/common/DealAction'

import CLASS_NAME from './styles'

export interface ActionCellProps {
  getValue: () => number
}

export interface DealStatus {
  nextAction: number
}

const ActionCell: FC<ActionCellProps> = ({ getValue }) => {
  const nextAction = getValue()

  return (
    <CellWrapper className={CLASS_NAME}>
      <DealAction action={nextAction} />
    </CellWrapper>
  )
}

export default ActionCell
