import type { FC } from 'react'
import { GalleryItems, Modal } from '@carfluent/common'

import Header from './components/Header'

import CLASS_NAME from './styles'

interface GalleryModalProps {
  selected?: number
  setSelected: (index: number | undefined) => void
  images: string[]
  isOpen: boolean
  onToggle: (state: boolean) => void
}

const GalleryModal: FC<GalleryModalProps> = ({ selected, setSelected, images, isOpen, onToggle }) => {
  return (
    <Modal
      isOpen={isOpen}
      className={CLASS_NAME}
    >
      <Header
        onClose={() => onToggle(false)}
        counter={`${(selected ?? 0) + 1}/${images.length}`}
      />

      <div className='cf-gallery-sections'>
        <GalleryItems
          imageUrls={images}
          selected={selected}
          className='cf-full-image-section'
          onSelect={setSelected}
          itemWidth='100%'
          imgBackgroundType='contain'
          showArrows
        />

        <GalleryItems
          imageUrls={images}
          className='cf-thumbnails-section'
          selected={selected}
          onSelect={setSelected}
          showArrows
        />
      </div>
    </Modal>

  )
}

export default GalleryModal
