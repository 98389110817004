import { FC } from 'react'
import { Button, FormNumberInput, PopoverV2 } from '@carfluent/common'

import { PriceConfigModes } from 'types'
import RadioGroup from 'components/common/RadioGroup'
import { MAX_PRICE_VALUE } from 'constants/validation'
import IconSVGPencil from 'components/inlineImages/IconSVGPencil'

import usePriceConfig from './hook'
import { getPriceConfigTitle } from './utils'
import { type UsePriceConfigProps } from './hook/types'
import { MAX_PERCENT_VALUE, PRICE_CONFIG_MODES } from './hook/constants'

import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

const PriceConfiguration: FC<UsePriceConfigProps> = ({ markup, ...rest }) => {
  const {
    values,
    errors,
    onBlur,
    touched,
    anchorEl,
    onChange,
    onSubmit,
    configMode,
    isConfigModalOpen,
    onChangeConfigMode,
    onOpenIsConfigModalOpen,
    onCloseIsConfigModalOpen
  } = usePriceConfig({ markup, ...rest })

  return (
    <div
      className={CLASS_NAME}
    >
      <span onClick={onOpenIsConfigModalOpen}>
        {getPriceConfigTitle(markup)}
      </span>

      <div ref={anchorEl} className='edit-action' onClick={onOpenIsConfigModalOpen}>
        <IconSVGPencil width={16} height={16} className='btn-edit-coverages' />
      </div>

      <PopoverV2
        anchorEl={anchorEl}
        isOpen={isConfigModalOpen}
        className={POPOVER_CLASS_NAME}
        onClose={onCloseIsConfigModalOpen}
      >
        <RadioGroup
          value={configMode}
          id='priceConfigMode'
          items={PRICE_CONFIG_MODES}
          className='price-config-mode'
          onChange={(_, value) => onChangeConfigMode(value as PriceConfigModes)}
        />

        {
          configMode === PriceConfigModes.Markup && (
            <div className='inputs-container markup-inputs'>
              <FormNumberInput
                isEmptyAllowed
                preset='percent'
                label='Markup, %'
                id='markupPercent'
                onChange={onChange}
                max={MAX_PERCENT_VALUE}
                isNegativeAllowed={false}
                value={values.markupPercent}
                blurComplementMode='min-scale'
                mountComplementMode='min-scale'
              />

              <span>+</span>

              <FormNumberInput
                isEmptyAllowed
                label='Markup, $'
                id='markupAmount'
                startAdornment='$'
                onChange={onChange}
                decimalPrecision={2}
                max={MAX_PRICE_VALUE}
                isNegativeAllowed={false}
                value={values.markupAmount}
                blurComplementMode='min-scale'
                mountComplementMode='min-scale'
              />
            </div>
          )
        }

        {
          configMode === PriceConfigModes.Fixed && (
            <div className='inputs-container'>
              <FormNumberInput
                isEmptyAllowed
                onBlur={onBlur}
                id='markupFixed'
                label='Amount, $'
                startAdornment='$'
                onChange={onChange}
                decimalPrecision={2}
                max={MAX_PRICE_VALUE}
                isNegativeAllowed={false}
                value={values.markupFixed}
                error={errors.markupFixed}
                touched={touched.markupFixed}
                blurComplementMode='min-scale'
                mountComplementMode='min-scale'
              />
            </div>
          )
        }

        <div className='apply-button-wrapper'>
          <Button
            onClick={onSubmit}
            variant='contained'
            className='btn-apply'
          >
            Apply
          </Button>
        </div>
      </PopoverV2>
    </div>
  )
}

export default PriceConfiguration
