import { AccountListItem } from 'api/types'
import type { VehiclePurchasePaymentsFormData } from './types'

const parseFormData = (data: AccountListItem[]): VehiclePurchasePaymentsFormData => {
  const templateObject = {
    firstAccount: null,
    secondAccount: null,
    thirdAccount: null,
    fourthAccount: null,
    fifthAccount: null
  }

  const keys = Object.keys(templateObject)

  return keys.reduce((acc, key, index) => {
    return {
      ...acc,
      [key]: data[index] ?? null
    }
  }, templateObject)
}

export default parseFormData
