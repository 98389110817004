import type { FC } from 'react'
import {
  cn,
  Loader,
  Button,
  FormInput,
  FormTextArea
} from '@carfluent/common'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import Divider from 'components/common/Divider'
import DeleteModal from 'components/dialogs/DeleteModal'

import SnippetsDropdown from './components/SnippetsDropdown'
import {
  FieldIds,
  MAX_NAME_SYMBOLS_LENGTH,
  MAX_BODY_SYMBOLS_LENGTH,
  ADD_SNIPPET_LABEL
} from './hook/constants'
import useMessageTemplateForm from './hook'

import { PAGE_CLASSES, DELETE_MODAL_CLASSES } from './styles'

const MessageTemplateForm: FC = () => {
  const {
    isLoading,
    isFormSubmitting,
    isDisabledActionBtn,
    templateDeleteProps,
    addSnippetError,
    snippets,
    values,
    errors,
    touched,
    onDeleteTemplate,
    onNameChange,
    onChange,
    onSubmit,
    onBlur,
    onCancel
  } = useMessageTemplateForm()

  const { isModalOpen, onCloseModal } = templateDeleteProps

  return (
    <div className={cn('g-submenu-content', 'g-margin-top-big', PAGE_CLASSES)}>
      {isLoading && <Loader size='large' />}

      <div className='cf-content'>
        <div className='cf-header-block'>
          <h5 className='cf-title'>
            Message Template
          </h5>

          <Button
            className='cf-button-link'
            dataTestId='btn-delete-template'
            onClick={() => onDeleteTemplate(false)}
            variant='text'
          >
            Delete template
          </Button>

          <DeleteModal
            className={DELETE_MODAL_CLASSES}
            content={
              <div className='delete-template-content'>
                <p>
                  Please note, this step cannot be undone. If the template is in use for auto-replies
                </p>
                <p>
                  or rules, no further emails or messages will be sent.
                </p>
              </div>
            }
            isOpen={isModalOpen}
            onClose={onCloseModal}
            onConfirm={() => onDeleteTemplate(true)}
            title='Delete template?'
          />
        </div>

        <div className='cf-template-inputs-block'>
          <FormInput
            id={FieldIds.Name}
            label='Template Name'
            value={values[FieldIds.Name]}
            error={errors[FieldIds.Name]}
            touched={touched[FieldIds.Name]}
            maxLength={MAX_NAME_SYMBOLS_LENGTH + 1}
            onChange={onNameChange}
            onBlur={onBlur}
            dataTestId='message-template-name-field'
          />
        </div>

        <div className='cf-message-template-body'>
          <div className='cf-top-part'>
            <SnippetsDropdown
              label={ADD_SNIPPET_LABEL}
              options={snippets}
              bodyId={FieldIds.Body}
              maxSymbols={MAX_BODY_SYMBOLS_LENGTH}
              errorMessage={addSnippetError}
              onChange={onChange}
            />

            <p className='cf-symbols-count'>
              Symbols {values[FieldIds.Body]?.length ?? 0}/{MAX_BODY_SYMBOLS_LENGTH}
            </p>
          </div>

          <Divider />

          <FormTextArea
            id={FieldIds.Body}
            onBlur={onBlur}
            onChange={onChange}
            maxLength={MAX_BODY_SYMBOLS_LENGTH}
            value={values[FieldIds.Body]}
            className='cf-bottom-part'
          />
        </div>
      </div>

      <div className='cf-button-container'>
        <CancelSubmitActionsFooter
          isDisabled={isDisabledActionBtn}
          isLoading={isFormSubmitting}
          onSubmit={onSubmit}
          onClose={onCancel}
          submitTitle='SAVE'
        />
      </div>
    </div>
  )
}

export default MessageTemplateForm
