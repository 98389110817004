import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, type Result, ok, fail, useModal } from '@carfluent/common'
import { useNavigate } from 'react-router-dom'

import { isFalsy } from 'utils/general'

import { type ContentFormData, type UseStepCampaignContentReturn, type UseStepCampaignContentProps } from './types'
import { mapModelOrDefaultToForm, mapFormToModel } from './utils'
import formValidation from './validation'

const VALIDATION_RULES = {}

const useStepCampaignContent = ({
  campaign,
  hasUnsavedChanges,
  onChangeUnsavedChanges,
  onRequestNextStep
}: UseStepCampaignContentProps): UseStepCampaignContentReturn => {
  const navigate = useNavigate()
  const [globalError, setGlobalError] = useState<boolean>(false)

  const modalUnsavedChanges = useModal()

  const baseValues = useMemo(() => {
    return mapModelOrDefaultToForm(campaign)
  }, [campaign])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onCancel = useCallback(() => {
    navigate(-1)
  }, [])

  const form = useForm<ContentFormData>({
    baseValues,
    isTrackingChanges: true,
    validationRules: VALIDATION_RULES
  })

  const onGoNext = useCallback(async (): Promise<Result<unknown>> => {
    const action = async (values: ContentFormData): Promise<Result<unknown>> => {
      if (isFalsy(values.body)) {
        setGlobalError(true)
        return fail(new Error('Validation error'))
      } else {
        onRequestNextStep(mapFormToModel(values))
        return ok(null)
      }
    }

    return await form.onSubmit(undefined, formValidation, action)
  }, [form.onSubmit, onRequestNextStep])

  const onBlur = useCallback((e) => {
    form.onBlur(e)
    form.validateForm()
    setGlobalError(false)
  }, [form.onBlur, form.validateForm])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Form initialization.
   */
  useEffect(() => {
    if (campaign != null) {
      form.resetForm(campaign.contentDetails)
    }
  }, [campaign, form.resetForm])

  /**
   * Track if form was changed.
   */
  useEffect(() => {
    if (form.isTouched) {
      onChangeUnsavedChanges(form.hasChanges)
    }
  }, [
    form.hasChanges,
    form.isTouched,
    onChangeUnsavedChanges
  ])

  /**
   * Some clean-up on unmount.
   */
  useEffect(() => {
    return () => {
      form.resetForm(mapModelOrDefaultToForm())
    }
  }, [form.resetForm])

  // ========================================== //

  return {
    ...form,
    id: null,
    onCancel,
    globalError,
    onGoNext,
    onBlur,
    unsavedChangesProps: {
      ...modalUnsavedChanges,
      shouldBlock: hasUnsavedChanges
    }
  }
}

export default useStepCampaignContent
