import { WrapperRequest } from './wrapper.api'
import {
  type VehicleListPayload,
  type VehicleList,
  type VehicleImage,
  type GetBodyTypes,
  type GetCylinderCountsTypes,
  type GetDoorCountsTypes,
  type GetDriveTrainTypes,
  type GetFuelTypes,
  type GetGenericColorsTypes,
  type GetGenericInteriorsTypes,
  type GetStockNumber,
  type GetTitleTypes,
  type GetTransmissionTypes,
  type GetInventoryStates,
  type GetTags,
  type VehicleDetailsByVin,
  type VehicleById,
  type VehicleNotesUpdate,
  type GetInventoryMakes,
  type SuggestedVehicles,
  type Vehicle,
  type TitleReportItemGetDto,
  type VehicleByIdPatch,
  type VehicleListCountersPayload,
  type VehicleCounters,
  type VehicleStateResponse,
  VehicleStatus,
  GetTitleStagesDto,
  GetRegistrationStageDto,
  type VehicleDocumentDto,
  type VehicleDocumentPatchRequestDto,
  type CarRepricingReportsRequestDto
} from 'api/types/vehicles.types'

import { type VehiclesDashboardDto } from 'api/types/responses'

import type {
  DictionaryItem,
  ListResponse,
  MakesAmountGetRequestDto,
  MakesAmountRequestDto,
  ReportCarRepricingItemDto,
  TitleReportsRequestDto,
  VehiclePrices
} from './types'
import { VehiclePostData } from '../pages/inventory/NewVehicle/hook/serializer' // AZ-TODO: fix incorrect import direction

const BASE_URL = process.env.REACT_APP_INVENTORY ?? ''

class VehiclesApi extends WrapperRequest {
  get vehicleUrl (): string {
    return `${BASE_URL}/api/v1/Vehicles`
  }

  get referenceUrl (): string {
    return `${BASE_URL}/api/v1/Reference`
  }

  get reportsUrl (): string {
    return `${BASE_URL}/api/v1/Reports`
  }

  get importUrl (): string {
    return `${BASE_URL}/api/v1/Vehicles/import`
  }

  get templateUrl (): string {
    return `${BASE_URL}/api/v1/Vehicles/import-template`
  }

  get inventoryUrl (): string {
    return `${BASE_URL}/api/v1/Inventory`
  }

  get dashboardUrl (): string {
    return `${BASE_URL}/api/v1/Dashboard`
  }

  getVehicles = async (payload: VehicleListPayload): Promise<VehicleList> => {
    return await this.post(`${this.vehicleUrl}/list`, payload)
  }

  postVehicle = async (payload: VehiclePostData): Promise<number> => {
    return await this.post(this.vehicleUrl, payload)
  }

  deleteVehicle = async (vehicleId: string | number): Promise<void> => {
    return await this.delete(`${this.vehicleUrl}/${vehicleId}`)
  }

  postNotes = async (payload: VehicleNotesUpdate): Promise<{ rowVersion: string }> => {
    const { id, notes } = payload
    return await this.post(`${this.vehicleUrl}/${id}/notes`, { notes })
  }

  patchVehicle = async (payload: VehicleByIdPatch): Promise<VehicleById> => {
    return await this.patch(`${this.vehicleUrl}/${payload.id}`, payload)
  }

  getVehicleById = async (vehicleId: number | string): Promise<VehicleById> => {
    return await this.get(`${this.vehicleUrl}/${vehicleId}`)
  }

  getVehicleByVin = async (vin: string): Promise<VehicleDetailsByVin> => {
    return await this.get(`${this.inventoryUrl}/vin/${vin}`)
  }

  getStockNumber = async (): Promise<GetStockNumber> => {
    return await this.get(`${this.vehicleUrl}/stockNumber`)
  }

  getVehicleState = async (vehicleId: number | string): Promise<VehicleStateResponse> => {
    return await this.get(`${this.vehicleUrl}/${vehicleId}/state`)
  }

  getTitleTypes = async (): Promise<GetTitleTypes> => {
    return await this.get(`${this.referenceUrl}/titleTypes`, {
      additionalParams: { cache: true }
    })
  }

  getBodyTypes = async (): Promise<GetBodyTypes> => {
    return await this.get(`${this.referenceUrl}/bodyTypes`, {
      additionalParams: { cache: true }
    })
  }

  getCylinderCountsTypes = async (): Promise<GetCylinderCountsTypes> => {
    return await this.get(`${this.referenceUrl}/cylinderCounts`, {
      additionalParams: { cache: true }
    })
  }

  getDoorCountsTypes = async (): Promise<GetDoorCountsTypes> => {
    return await this.get(`${this.referenceUrl}/doorCounts`, {
      additionalParams: { cache: true }
    })
  }

  getDriveTrainTypes = async (): Promise<GetDriveTrainTypes> => {
    return await this.get(`${this.referenceUrl}/drivetrainTypes`, {
      additionalParams: { cache: true }
    })
  }

  getFuelTypes = async (): Promise<GetFuelTypes> => {
    return await this.get(`${this.referenceUrl}/fuelTypes`, {
      additionalParams: { cache: true }
    })
  }

  getGenericColorsTypes = async (): Promise<GetGenericColorsTypes> => {
    return await this.get(`${this.referenceUrl}/genericColors`, {
      additionalParams: { cache: true }
    })
  }

  getGenericInteriorsTypes = async (): Promise<GetGenericInteriorsTypes> => {
    return await this.get(`${this.referenceUrl}/genericInteriors`, {
      additionalParams: { cache: true }
    })
  }

  getTransmissionTypes = async (): Promise<GetTransmissionTypes> => {
    return await this.get(`${this.referenceUrl}/transmissionTypes`, {
      additionalParams: { cache: true }
    })
  }

  getInventoryStates = async (): Promise<GetInventoryStates> => {
    return await this.get(`${this.referenceUrl}/states`, {
      additionalParams: { cache: true }
    })
  }

  getTags = async (): Promise<GetTags> => {
    return await this.get(`${this.referenceUrl}/tags`)
  }

  getMakes = async (): Promise<GetInventoryMakes> => {
    return await this.get(`${this.referenceUrl}/vehicleMakes`, {
      additionalParams: { cache: true }
    })
  }

  getTitleStages = async (): Promise<GetTitleStagesDto> => {
    return await this.get(`${this.referenceUrl}/titleStages`)
  }

  getRegistrationStages = async (): Promise<GetRegistrationStageDto> => {
    return await this.get(`${this.referenceUrl}/registrationStages`)
  }

  addTag = async (name: string): Promise<DictionaryItem> => {
    return await this.post(`${BASE_URL}/api/v1/Tags`, { name })
  }

  updateVehicleStatus = async (id: number | string, status: VehicleStatus): Promise<VehicleById> => {
    return await this.patch(`${this.vehicleUrl}/${id}/status`, { data: status })
  }

  importFile = async (file: File, cancellationKey?: string): Promise<void> => {
    const formData = new FormData()
    formData.append('file', file)

    return await this.post(
      this.importUrl,
      {
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      },
      { key: cancellationKey })
  }

  downloadTemplate = async (): Promise<string> => {
    return await this.get(this.templateUrl)
  }

  getVehicleImages = async (vehicleId: number | string): Promise<ListResponse<VehicleImage>> => {
    return await this.get(`${this.vehicleUrl}/${vehicleId}/images`)
  }

  getSuggestedVehicles = async (body: SuggestedVehicles): Promise<ListResponse<Vehicle>> => {
    return await this.post(`${this.vehicleUrl}/suggested`, body)
  }

  getTitleReports = async (payload: TitleReportsRequestDto): Promise<ListResponse<TitleReportItemGetDto>> => {
    return await this.post(`${this.reportsUrl}/title`, payload)
  }

  getCarRepricingReports = async (payload: CarRepricingReportsRequestDto): Promise<ListResponse<ReportCarRepricingItemDto>> => {
    return await this.post(`${this.reportsUrl}/repricing`, payload)
  }

  downloadCarRepricingReportXlsx = async (data: CarRepricingReportsRequestDto): Promise<Blob> => {
    return await this.post(`${this.reportsUrl}/repricing/xlsx`, {
      data,
      additionalParams: {
        responseType: 'blob'
      }
    })
  }

  getMakesAmount = async (data: MakesAmountRequestDto): Promise<ListResponse<MakesAmountGetRequestDto>> => {
    return await this.post(`${this.vehicleUrl}/makes-amount`, data)
  }

  downloadTitleReportXlsx = async (data: TitleReportsRequestDto): Promise<Blob> => {
    return await this.post(`${this.reportsUrl}/title/xlsx`, {
      data,
      additionalParams: {
        responseType: 'blob'
      }
    })
  }

  generateAIDescription = async (id: string | number): Promise<{ description: string }> => {
    return await this.get(`${this.vehicleUrl}/${id}/ai-description`)
  }

  getCounters = async (payload: VehicleListCountersPayload): Promise<VehicleCounters> => {
    return await this.post(`${this.vehicleUrl}/list/total`, payload)
  }

  /**
   * Checks if a car exists in the inventory based on its VIN.
   * It sends a GET request to the vehicle service using the provided VIN.
   * If the car exists (i.e., the GET request is successful), the function resolves to true.
   * If the car does not exist or the request fails (e.g., the car with the given VIN is not found),
   * the function catches the error and resolves to false.
   */
  isCarExistenceByVin = async (vin: string): Promise<boolean> => {
    try {
      await this.get(`${this.vehicleUrl}/vin/${vin}`)
      return true
    } catch (e) {
      return false
    }
  }

  checkIfVehicleCanBeDeleted = async (id: number): Promise<boolean> => {
    try {
      await this.get(`${this.vehicleUrl}/${id}/can-be-deleted`)
      return true
    } catch (e) {
      return false
    }
  }

  patchDocuments = async (id: string, data: { documents: VehicleDocumentPatchRequestDto[] }): Promise<ListResponse<VehicleDocumentDto>> => {
    return await this.post(`${this.vehicleUrl}/${id}/documents`, data)
  }

  deleteDocument = async (id: number): Promise<void> => {
    return await this.delete(`${this.vehicleUrl}/documents/${id}`)
  }

  getPriceHistory = async (id: string): Promise<ListResponse<VehiclePrices>> => {
    return await this.get(`${this.vehicleUrl}/${id}/price-statistics`)
  }

  isCarCanBeDeleted = async (id: string): Promise<{ canBeDeleted: boolean }> => {
    return await this.get(`${this.vehicleUrl}/${id}/can-be-deleted`)
  }

  getVehicleDashboard = async (): Promise<VehiclesDashboardDto> => {
    return await this.get(`${this.dashboardUrl}/vehicles`)
  }
}

export const VehiclesApiProvider = new VehiclesApi()
export default VehiclesApiProvider
