import { type FC } from 'react'
import { FormNumberInput, Banner, cnx, formatCurrencyAccounting, FormDatePicker, Loader } from '@carfluent/common'
import { Link } from 'react-router-dom'

import { MAX_PRICE_VALUE } from 'constants/validation'
import { Routes } from 'constants/route_helper'
import { DATE_US_FORMAT, DATE_DAY_SHORT_MONTH_YEAR_FORMAT } from 'constants/constants'
import { formatDate } from 'utils/parse_date'

import IconSVG from 'components/inlineImages'
import SaveCloseButtonsPanel from 'components/common/SaveCloseButtonsPanel'
import AccountingHOK from 'pages/accounting/AccountingHOC'

import useReconciliationSetup from './hook'
import CLASS_NAME from './styles'

const ReconciliationSetup: FC = () => {
  const {
    errors,
    touched,
    values,
    onChange,
    onBlur,
    minDate,
    isLoading,
    accountName,
    lastSessionId,
    inProgressSessionId,
    lastFinishedSession,
    openingBalance,
    shouldDisableMonth,
    onCancel,
    onSubmitSaveReconciliationSession
  } = useReconciliationSetup()

  return (
    <div className={cnx('g-content-layout', CLASS_NAME)}>
      <div className='wrapper'>
        <h5>Reconciliation {accountName} </h5>
        {isLoading
          ? <Loader className='section-loader' size='large' />
          : (
            <>
              <div className='reconciled-info'>
                {lastSessionId != null
                  ? (
                    <>
                      <div>
                        <IconSVG.CheckedCircle />
                        &nbsp;Last reconciled: {formatDate(new Date(lastFinishedSession?.processEndDate ?? ''), DATE_US_FORMAT)} | &nbsp;
                        {formatDate(new Date(lastFinishedSession?.beginningDate ?? ''), DATE_DAY_SHORT_MONTH_YEAR_FORMAT)}
                        &nbsp;-&nbsp;
                        {formatDate(new Date(lastFinishedSession?.endDate ?? ''), DATE_DAY_SHORT_MONTH_YEAR_FORMAT)}
                      </div>
                    </>
                    )
                  : (
                    <div>
                      Last reconciled: -
                    </div>
                    )}
              </div>

              <Banner
                isOpen={inProgressSessionId == null && lastSessionId == null}
                message={
                  <div>
                    Opening balance cannot be edited once transactions are reconciled. <br />
                    Please make sure opening balances is correct before reconciling any transaction.
                  </div>
                }
                type='info'
                endAdornment={
                  <Link to={Routes.AccountingSettingsOpeningBalances}>
                    Opening balances
                  </Link>
                }
              />

              <div className='content'>

                <div className='beginning-balance'>
                  <div className='title'>Beginning balance</div>
                  <div className='price'>{formatCurrencyAccounting(lastFinishedSession == null ? openingBalance : lastFinishedSession.endBalance, { allowNegative: true })}</div>
                </div>

                {
                  (inProgressSessionId == null) && (
                    <div className='form-container'>
                      <FormNumberInput
                        value={values.endingBalance ?? ''}
                        error={errors.endingBalance}
                        touched={touched.endingBalance}
                        id='endingBalance'
                        label='Ending balance'
                        onBlur={onBlur}
                        onChange={onChange}
                        max={MAX_PRICE_VALUE}
                        dataTestId='ending-balance'
                        mode='decimal'
                        isNegativeAllowed
                        startAdornment='$'
                        isEmptyAllowed
                      />

                      <FormDatePicker
                        id='endDate'
                        label='End date'
                        className='date-picker'
                        minDate={minDate}
                        shouldDisableMonth={shouldDisableMonth}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={values.endDate}
                        error={errors.endDate}
                        touched={touched.endDate}
                      />
                    </div>
                  )
                }
              </div>
            </>
            )}
      </div>

      <SaveCloseButtonsPanel
        className='button-panel-container'
        handleReset={onCancel}
        handleSubmit={onSubmitSaveReconciliationSession}
        isSubmitting={isLoading}
        titleCloseButton='CANCEL'
        titleSaveButton={inProgressSessionId != null ? 'RESUME' : 'NEXT'}
      />

    </div>
  )
}

export default AccountingHOK(ReconciliationSetup)
