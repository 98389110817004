import { type FC } from 'react'
import { Button, FormInput } from '@carfluent/common'

import IconSVG from 'components/inlineImages'
import SearchInput from 'components/common/SearchInput'

import type { UseMarketplacePromoReturn } from '../../hook'
import { MAX_HEADER_LENGTH, MAX_AD_HEADER_LENGTH, CAR_ADS_SECTIONS, FieldId } from './constants'

import CLASS_NAME from './styles'
import { FILE_EXTENSIONS } from '../../hook/constants'

const getHelperLabel = (value: string, acceptableLength = MAX_HEADER_LENGTH): string => {
  return `${(value ?? '').length}/${acceptableLength} symbols`
}

const getAdHeading = (enteredVal: string, headerNumber: number = 1): string => {
  return enteredVal !== '' ? enteredVal : `<Heading ${headerNumber}>`
}

export interface HomePageProps extends Omit<UseMarketplacePromoReturn, 'isValid' | 'onSubmit' | 'resetForm' | 'isChanged'> {}

const HomePage: FC<HomePageProps> = ({
  values: {
    heroData,
    carAds
  },
  errors,
  onBlur,
  onChange,
  touched,
  onUploadFiles,
  heroImage
}) => {
  return (
    <div className={CLASS_NAME}>
      <div className='section-info'>
        <h4>
          Home page image
        </h4>
        <p>
          Home page headings and images are for a global website's main page. Upload PNG, JPG or JPEG image at least 600 pixels high for optimal quality.
        </p>
      </div>

      <div
        className='home-page-hero' style={{
          background: `url(${heroImage != null ? heroImage : ''}) center center / cover no-repeat`
        }}
      >

        <div className='home-page-hero-overlay'>
          <h3>{getAdHeading(heroData.header)}</h3>
          <h1>{getAdHeading(heroData.description, 2)}</h1>
        </div>

        <div className='home-page-search-bar'>
          <SearchInput onChange={() => { /* DO NOTHING */ }} disabled placeholder='Search by make, model and year' />

          <Button
            isDisabled
            className='btn-new-deal'
            onClick={() => { /* DO NOTHING */ }}
          >
            SEARCH
          </Button>
        </div>

        <input
          type='file'
          id='image-upload'
          onChange={onUploadFiles}
          style={{ display: 'none' }}
          accept={[...FILE_EXTENSIONS].join(',')}
        />

        <label htmlFor='image-upload' className='edit-button'>
          <IconSVG.Pencil width={24} height={24} color='#000' opacity={1} />
        </label>
      </div>

      <div className='hero-headings'>
        <FormInput
          onBlur={onBlur}
          label='Heading 1'
          onChange={onChange}
          id='heroData.header'
          value={heroData.header}
          maxLength={MAX_HEADER_LENGTH}
          error={errors?.heroData.header ?? null}
          helper={getHelperLabel(heroData.header)}
          touched={touched?.heroData.header ?? false}
        />
        <FormInput
          onBlur={onBlur}
          label='Heading 2'
          onChange={onChange}
          id='heroData.description'
          value={heroData.description}
          maxLength={MAX_HEADER_LENGTH}
          error={errors?.heroData.description ?? null}
          helper={getHelperLabel(heroData.description)}
          touched={touched?.heroData.description ?? false}
        />
      </div>

      <div className='section-info'>
        <h4>
          Car detail page content
        </h4>
        <p>
          This content will be dedicated to each dealership individually.
        </p>
      </div>
      <div className='car-ads-wrapper'>

        {
          CAR_ADS_SECTIONS.map(({ fieldId, icon }) => {
            const key = fieldId as FieldId

            return (
              <div key={fieldId} className='car-ad-container'>
                <div className='ad-preview'>
                  <img src={icon} alt='' />

                  <div>
                    <h4>{getAdHeading(carAds[key].header)}</h4>
                    <p>{getAdHeading(carAds[key].description, 2)}</p>
                  </div>
                </div>
                <div className='input-container'>
                  <FormInput
                    onBlur={onBlur}
                    label='Heading 1'
                    onChange={onChange}
                    value={carAds[key].header}
                    id={`carAds.${key}.header`}
                    maxLength={MAX_AD_HEADER_LENGTH}
                    error={errors?.carAds[key].header ?? null}
                    touched={touched?.carAds[key].header ?? false}
                    helper={getHelperLabel(carAds[key].header, MAX_AD_HEADER_LENGTH)}
                  />

                  <FormInput
                    onBlur={onBlur}
                    label='Heading 2'
                    onChange={onChange}
                    maxLength={MAX_HEADER_LENGTH}
                    value={carAds[key].description}
                    id={`carAds.${key}.description`}
                    error={errors?.carAds[key].description ?? null}
                    helper={getHelperLabel(carAds[key].description)}
                    touched={touched?.carAds[key].description ?? false}
                  />
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default HomePage
