import { css } from '@emotion/css'

interface PopoverStylesProps {
  maxWidth?: number
}

export default ({ maxWidth }: PopoverStylesProps) => css(`
  padding: 24px;

  .cf-pop-up-title {
    margin-bottom: 8px;
    color: #101010;
  }

  .cf-popper {
    width: 256px;
    color: inherit;
  }

  .cf-all-items-block {
    padding-left: 4px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    margin-bottom: 8px;
  }

  .cf-item-block {
    padding-left: 4px;

    p {
      ${maxWidth != null ? `max-width: ${maxWidth - 80}px;` : ''}
    }
  }
`)

export const createPopoverStyles = ({ maxWidth }: PopoverStylesProps): string => css(`
  padding: 24px;

  .cf-pop-up-title {
    margin-bottom: 8px;
    color: #101010;
  }

  .cf-popper {
    width: 256px;
    color: inherit;
  }

  .cf-all-items-block {
    padding-left: 4px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    margin-bottom: 8px;
  }

  .cf-item-block {
    padding-left: 4px;

    p {
      ${maxWidth != null ? `max-width: ${maxWidth - 80}px;` : ''}
    }
  }
`)
