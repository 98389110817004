import type { FC } from 'react'
import { observer } from 'mobx-react-lite'

import Comment from './components/Comment'
import Header from './components/Header'
import Item from './components/Item'
import type { WebChatInfo } from '../MessangerPreview'
import type { UseTimelineReturn } from './hook'
import CLASS_NAME from './styles'

export interface TimelineProps extends Omit<UseTimelineReturn, 'loadTimelines'> {
  onOpenMessages: () => void
  setWebChatInfo: (info: WebChatInfo | null) => void
}

const Timeline: FC<TimelineProps> = ({
  groupedTimelines,
  headerProps,
  commentProps,
  onOpenMessages,
  showThreadBtnProps,
  setWebChatInfo
}) => {
  return (
    <div className={CLASS_NAME}>
      <Comment {...commentProps} />

      <Header {...headerProps} />

      <div className='timeline-comments-list'>
        {Object.entries(groupedTimelines).map(([date, timelines], index) => (
          <div key={`${date}-${index}`}>
            <div className='day-separator'>
              <span />
              <span>{date}</span>
              <span />
            </div>

            <div>
              {
                timelines.length > 0
                  ? timelines.map((item) =>
                    <Item
                      {...item}
                      key={item.id}
                      onOpenMessages={onOpenMessages}
                      showThreadBtnProps={showThreadBtnProps}
                      setWebChatInfo={setWebChatInfo}
                    />
                  )
                  : <p className='no-timelines'>No timelines</p>
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default observer(Timeline)
