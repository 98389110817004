import { type FC } from 'react'
import { formatters as F } from '@carfluent/common'

import { type ReconciliationSessionDto } from 'api/types'
import IconSVG from 'components/inlineImages'
import CLASS_NAME from './styles'

export interface LastReconciliationDatesProps {
  data: ReconciliationSessionDto | null
}

const LastReconciliationDates: FC<LastReconciliationDatesProps> = ({ data }) => {
  const lastReconciledDate = F.formatLocalDate(data?.processEndDate, 'MM/dd/yy')
  const startDate = F.formatLocalDate(data?.beginningDate, 'd MMM yyyy')
  const endDate = F.formatLocalDate(data?.endDate, 'd MMM yyyy')

  return (
    <div className={CLASS_NAME}>
      {(data != null) && (
        <>
          <IconSVG.CheckedCircle />
          <div>Last reconciled: {lastReconciledDate}<span>|</span>{startDate} - {endDate}</div>
        </>
      )}

      {(data == null) && (<div>Last reconciled: -</div>)}
    </div>
  )
}

export default LastReconciliationDates
