import { type FC, useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import { FormInput, FormDropdown } from '@carfluent/common'
import MemoizedFormDatePicker from '../MemoizedFormDatePicker'
import InputsRowLayout from 'components/inventory/InputsRowLayout'
import { useForm } from 'components/form'
import { ValidationLength } from 'constants/validation'
import { isCyrillic } from 'utils/format_number'
import type { TitleDictionaries } from 'pages/inventory/VehicleDetails/hooks/types' // AZ-TODO: wrong import, improve

interface VehicleTitlesFormControlsProps {
  dictionaries: TitleDictionaries
  isDisabled: boolean
}

const VehicleTitlesFormControls: FC<VehicleTitlesFormControlsProps> = ({
  dictionaries,
  isDisabled
}) => {
  const { setFieldValue, handleBlur, values, touched, errors } = useForm()
  const handleChange = useCallback((id, value): void => {
    if (value !== values[id]) {
      void setFieldValue(id, value)
    }
  }, [setFieldValue, values])

  return (
    <>
      <InputsRowLayout>
        <FormDropdown
          id='titleStage'
          label='Title stage'
          onBlur={handleBlur}
          disabled={isDisabled}
          onChange={handleChange}
          value={values.titleStage}
          options={dictionaries.TitleStage}
          error={errors.titleStage as string}
          touched={touched.titleStage as boolean}
        />

        <FormDropdown
          onBlur={handleBlur}
          disabled={isDisabled}
          id='registrationStage'
          onChange={handleChange}
          label='Registration stage'
          value={values.registrationStage}
          options={dictionaries.RegistrationStage}
        />

        <FormInput
          label='Note'
          id='titleNote'
          maxLength={255}
          onBlur={handleBlur}
          disabled={isDisabled}
          onChange={handleChange}
          value={values.titleNote}
        />

        <MemoizedFormDatePicker
          id='titleReceived'
          label='Title received'
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.titleReceived}
          touched={Boolean(touched.titleReceived)}
          error={errors.titleReceived as string}
          disabled={isDisabled}
        />

        <MemoizedFormDatePicker
          id='titleSent'
          label='Title sent'
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.titleSent}
          touched={Boolean(touched.titleSent)}
          error={errors.titleSent as string}
          disabled={isDisabled}
        />

        <FormDropdown
          disableClearable
          id='titleState'
          label='Title state'
          onBlur={handleBlur}
          onChange={handleChange}
          options={dictionaries.InventoryState}
          disabled={isDisabled}
          value={values.titleState}
          error={errors.titleState as string}
          touched={touched.titleState as boolean}
        />

        <FormInput
          id='titleNumber'
          label='Title number'
          maxLength={ValidationLength.TITLE_NUMBER_MAX}
          onKeyDown={e => {
            if (isCyrillic(e.key ?? '')) {
              e.preventDefault()
            }
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.titleNumber as boolean}
          error={errors.titleNumber as string}
          value={values.titleNumber}
          disabled={isDisabled}
        />

        <FormDropdown
          disableClearable
          id='titleStatus'
          label='Title status'
          onBlur={handleBlur}
          onChange={handleChange}
          options={dictionaries.TitleStatus}
          value={values.titleStatus}
          error={errors.titleStatus as string}
          touched={touched.titleStatus as boolean}
          disabled={isDisabled}
        />
      </InputsRowLayout>
    </>
  )
}

export default observer(VehicleTitlesFormControls)
