import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'

import { type KeyVal } from 'types'
import { type VehicleById } from 'api/types'
import { type ImageDescriptor } from 'components/inventory/ImageItem'
import { GET_DEFAULT_VEHICLE_BY_ID } from 'api/defaults'

import { TabIds } from './constants'

class VehicleDetails {
  apiErrors: KeyVal | null = null
  selectedTab: number = TabIds.General
  isOpenUnsavedChanges: boolean = false
  requestedNextTab: number | null = null

  /**
   * Shared parts of the original vehicle data
   * (data loaded by one tab, but used in several tabs)
   */

  originalVehicle: VehicleById = GET_DEFAULT_VEHICLE_BY_ID()
  originalParsedMediaFiles: ImageDescriptor[] = []

  setOriginalVehicle = (data: VehicleById): void => {
    this.originalVehicle = { ...data }
  }

  setOriginalMediaFiles = (files: ImageDescriptor[]): void => {
    this.originalParsedMediaFiles = files
  }

  updateOriginalVehicle = (data: Partial<VehicleById>): void => {
    this.originalVehicle = { ...this.originalVehicle, ...data }
  }

  // ========================================== //

  setApiErrors = (errors: KeyVal | null): void => {
    this.apiErrors = errors
  }

  setSelectedTab = (tab: TabIds): void => {
    this.selectedTab = tab
  }

  setOpenUnsavedChanges = (isOpen: boolean): void => {
    this.isOpenUnsavedChanges = isOpen
  }

  openUnsavedChanges = (nextTabIdx?: number): void => {
    this.requestedNextTab = nextTabIdx ?? null
    this.setOpenUnsavedChanges(true)
  }

  closeUnsavedChanges = (): void => {
    this.setOpenUnsavedChanges(false)
  }

  cleanUp = (): void => {
    this.setApiErrors(null)
    this.setSelectedTab(TabIds.General)
    this.setOpenUnsavedChanges(false)
    this.originalVehicle = GET_DEFAULT_VEHICLE_BY_ID()
    this.originalParsedMediaFiles = []
    this.requestedNextTab = null
  }

  constructor () {
    makeAutoObservable(this)
  }
}

export const VehicleDetailsInstance = new VehicleDetails()
export const VehicleDetailsCTX = createContext(VehicleDetailsInstance)
export default VehicleDetailsCTX
