import { css } from '@emotion/css'

export default css(`
  height: 100%;

  .cf-details-layout {
    display: grid;
    column-gap: 16px;
    position: relative;
    height: calc(100% - 56px);

    grid-template-columns: minmax(0, 65%) minmax(350px, 35%)
  }

  .cf-images-block {
    padding: 16px;
    background: white;
    border-radius: 12px;

    > .MuiSkeleton-text {
      height: 16px;
      width: 100px;
      margin-bottom: 20px;
    }

    .cf-image-wrapper {
      display: grid;
      grid-template-columns: repeat(3, minmax(30%, 1fr));
      grid-template-rows: 207px;
      column-gap: 16px;
      row-gap: 16px;
      width: 100%;
      
      .cf-item-wrapper {
        min-height: 207px;
        max-height: 207px;
        height: 207px;
        cursor: pointer;
      }
      
      .cf-image-buttons-control {
        justify-content: flex-end;
      }
    }
    
    .cf-images-block-title {
      color: #101010;
      font-size: 20px;
      font-family: Roboto;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 24px;
    }
  }
  
  .dropzone-content {
    padding-top: 40px;
    background: white;
  }
  
  .cf-dnd-overlay {
    background: white;
    opacity: 0.9;
  }
  
  .cf-content-wrapper {
    &.cf-content-hidden {
      visibility: visible;
    }
  }

  .cf-recon-top-bar {
    height: 24px;
  }
`)
