import { toDateOrNull } from 'utils/parse_date'
import isClearedTransaction from 'utils/accounting/isClearedTransaction'
import IconSVG from 'components/inlineImages'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import ValueCell, { ValueCellHOC } from 'components/common/Table/cells/ValueCell'
import { ReconcileStatusCellHOC } from 'components/common/Table/cells/ReconcileStatusCell'
import { DateCellHOC } from 'components/common/Table/cells/DateCell'
import BalanceCell from 'pages/accounting/AccountTrialBalance/components/cells/BalanceCell'

import {
  type ColumnDef,
  type ReconciliationDraftRowData,
  type Row
} from './types'

export interface ColumnsConfig {
  onToggleReconcile: (rowIdx: number, rowId: number, currentStatus: number) => Promise<void>
}

const getColumnDefinitions = ({
  onToggleReconcile
}: ColumnsConfig): Array<ColumnDef<ReconciliationDraftRowData>> => ([
  {
    id: 'date',
    accessorFn: (row: ReconciliationDraftRowData) => toDateOrNull(row.date),
    cell: DateCellHOC({ format: 'MM/dd/yy' }),
    header: TextCellHOC({ text: 'Date' }),
    size: 110
  },
  {
    id: 'clearedDate',
    accessorFn: (row: ReconciliationDraftRowData) => toDateOrNull(row.clearedDate),
    cell: DateCellHOC({ format: 'MM/dd/yy', emptyValue: '' }),
    header: TextCellHOC({ text: 'Cleared' }),
    size: 110
  },
  {
    accessorKey: 'referenceNumber',
    header: TextCellHOC({ text: 'Reference #' }),
    cell: ValueCell,
    size: 140
  },
  {
    accessorKey: 'account',
    cell: ValueCellHOC({
      className: 'cell-account',
      renderStartAdornment: ({ row }: { row: Row<ReconciliationDraftRowData> }) => {
        return isClearedTransaction(row.original.reconcileStatus)
          ? <IconSVG.Bank />
          : null
      }
    }),
    header: TextCellHOC({ text: 'Account' }),
    minSize: 252,
    sortable: false
  },
  {
    accessorKey: 'entity',
    header: TextCellHOC({ text: 'Entity' }),
    cell: ValueCell,
    size: 160,
    sortable: false
  },
  {
    accessorKey: 'description',
    header: TextCellHOC({ text: 'Description' }),
    cell: ValueCell,
    minSize: 252,
    sortable: false
  },
  {
    accessorKey: 'deposit',
    cell: BalanceCell,
    header: TextCellHOC({ text: 'Deposit', className: 'aligned-right' }),
    size: 160,
    sortable: false
  },
  {
    accessorKey: 'withdrawal',
    cell: BalanceCell,
    header: TextCellHOC({ text: 'Withdrawal', className: 'aligned-right' }),
    size: 160,
    sortable: false
  },
  {
    id: 'reconcileStatus',
    accessorFn: ({ id, reconcileStatus }: ReconciliationDraftRowData, index: number) => ({
      id,
      rowIdx: index,
      status: reconcileStatus
    }),
    cell: ReconcileStatusCellHOC({ onToggleReconcile }),
    header: TextCellHOC({ text: '' }),
    size: 84,
    sortable: false
  }
])

export default getColumnDefinitions
