import { css } from '@emotion/css'

export default css(`
  && {
    width: 100%;
    height: 100%;
    display: flex;

    &.tooltip-container {
      max-width: 100%;
      padding: 12px 8px;
    }

    .truncated-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  
    p {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    &.cf-header-cell-title {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      min-width: max-content;
      max-width: fit-content;
      max-width: 100%!important;
    }

    &.aligned-right {
      width: 100%;
      text-align: right;
      max-width: 100%;
      justify-content: flex-end;
      padding-right: 8px;
    }

    &.cf-header-cell-title > p {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5;
      color: inherit;
    }
  }
`)
