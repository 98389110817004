import type { DeepRequired } from '@carfluent/common'
import type { TransactionLineVendorDto } from 'api/types'
import { fullAddress } from 'utils/address'
import { namifyAccount } from 'utils/accounting/namifyAccount'

import type { VendorFormData } from './types'

const parseVendorData = (data: TransactionLineVendorDto): DeepRequired<VendorFormData> => {
  const address = {
    address: data.address,
    city: data.city,
    zipCode: data.zipCode,
    state: data.state,
    county: null,
    addressLong: null
  }

  const mailingAddress = {
    address: data.mailingAddress,
    city: data.mailingCity,
    zipCode: data.mailingZipCode,
    state: data.mailingState,
    county: null,
    addressLong: null
  }

  const hasMailingAddress = Object.values(mailingAddress).some(Boolean)
  const addressStr = fullAddress(address)
  const mailingAddressStr = fullAddress(mailingAddress)

  return {
    id: data.id ?? null,
    name: data.name,
    phoneNumber: data.phoneNumber,
    email: data.email,
    ssn: data.ssn ?? null,
    ein: data.ein,
    isDifferentMailingAddress: hasMailingAddress && (addressStr !== mailingAddressStr),
    isTrack1099Payments: data.track1099Payments,
    address,
    mailingAddress,
    payableAccount: data?.account != null ? namifyAccount(data?.account) : null
  }
}

export default parseVendorData
