import { type ColumnDef } from '@carfluent/common'

import LeadLabel from 'components/common/LeadLabel'
import DateCell from 'components/common/Table/cells/DateCell'
import { PhoneCellHOC } from 'components/common/Table/cells/PhoneCell'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import ButtonCell from 'components/common/Table/cells/ButtonCell'
import { LEAD_SOURCE_LABEL_TEXTS, LEAD_STATUS_TEXT } from 'constants/names'
import ValueCell, { CellAdornmentProps, ValueCellHOC } from 'components/common/Table/cells/ValueCell'
import type { InterestedLeadsListData } from 'pages/inventory/VehicleDetails/hooks/useLeadsTab/types'

import { onOpenLeadDetails } from './utils'

export interface ColumnsProps {
  totalLeads?: number
}

export const columns = ({
  totalLeads
}: ColumnsProps): Array<ColumnDef<InterestedLeadsListData>> => ([
  {
    id: 'name',
    cell: ValueCellHOC({
      renderEndAdornment: ({ row }: CellAdornmentProps<InterestedLeadsListData>) => {
        return (
          <LeadLabel
            value={row.original.label}
            className='label-item'
          />
        )
      }
    }),
    accessorKey: 'name',
    header: TextCellHOC({
      text: (
        <>
          Leads ({totalLeads})
        </>)
    }),
    loading: 'big_line',
    className: 'lead-label-container',
    sortable: true,
    minSize: 195,
    maxSize: 375
  },
  {
    accessorFn: (item) => item.phoneNumber ?? '-',
    id: 'phoneNumber',
    cell: PhoneCellHOC({ withDash: true }),
    header: TextCellHOC({ text: 'Phone' }),
    loading: 'big_line',
    minSize: 140,
    sortable: false
  },
  {
    accessorFn: (item) => item.email ?? '-',
    id: 'email',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Email' }),
    loading: 'big_line',
    size: 220,
    sortable: false
  },
  {
    accessorFn: (item) => LEAD_SOURCE_LABEL_TEXTS[item.leadSourceId],
    id: 'leadSourceId',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Source' }),
    loading: 'big_line',
    size: 160,
    sortable: false
  },
  {
    accessorFn: (item) => LEAD_STATUS_TEXT[item.leadStatusId],
    id: 'leadStatusId',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Status' }),
    loading: 'big_line',
    size: 120,
    sortable: false
  },
  {
    accessorFn: (item) => item.age ?? '-',
    id: 'age',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Age' }),
    loading: 'big_line',
    size: 80,
    sortable: true
  },
  {
    accessorFn: (item) => item.lastActivityDate ?? '-',
    id: 'lastActivityDate',
    cell: DateCell,
    header: TextCellHOC({ text: 'Last activity' }),
    loading: 'big_line',
    minSize: 160,
    sortable: true
  },
  {
    accessorFn: (row) => ({
      onClick: () => onOpenLeadDetails(row.id)
    }),
    accessorKey: 'id',
    cell: ButtonCell,
    minSize: 140,
    maxSize: 140,
    header: undefined,
    sortable: false
  }
])
