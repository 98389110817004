const parseLeadEmailString = (email: string | undefined): string[] =>
  email?.split(';').reduce((data: string[], item) => {
    const trimmedValue = item.trim()
    const isExist = Boolean(trimmedValue)

    if (isExist) {
      data.push(trimmedValue)
    }

    return data
  }, []) ?? []

export default parseLeadEmailString
