import { type FC, useEffect, useRef, useLayoutEffect, useContext } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { isUrlMatching } from 'utils/route_helper'

import { SubmenuVisibilityCtx } from './contexts'

export interface RoutesWithPagesOverlayProps {
  isPageWithSubmenu?: boolean
  routesUrls: string | string[]
  className?: string
  onMount?: () => Promise<void>
  onUnmount?: () => Promise<void>
}

const RoutesWithPagesOverlay: FC<RoutesWithPagesOverlayProps> = ({
  isPageWithSubmenu = false,
  children,
  routesUrls,
  className,
  onMount,
  onUnmount
}) => {
  const { pathname } = useLocation()
  const isSubmenuVisible = useContext(SubmenuVisibilityCtx) ?? isPageWithSubmenu

  /**
   * when we work with an array of routes as base pages we might get into a situation when
   * scrolling one also scrolls the second on return. To Fix this we store and apply scroll position
   * of each route separately.
   */
  const scrollPosRef = useRef<Record<string, number>>({})
  const prevUrlRef = useRef(pathname)
  const elRef = useRef<HTMLDivElement>(null)

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useLayoutEffect(() => {
    const el = elRef.current

    if (el == null) {
      return
    }

    const prevUrl = prevUrlRef.current
    prevUrlRef.current = pathname

    /**
     * when we leave the base page we need to store its scroll position
     */
    if (isUrlMatching(prevUrl, routesUrls)) {
      scrollPosRef.current[prevUrl] = window.pageYOffset
    }

    /**
     * on each fresh navigation in the pages group controlled by this wrapper
     * we need to reset document scroll position
     * so that later on we could properly scroll into scroll anchor.
     *
     * DD-NOTE: Without these lines it was not working stably. But I made so many different iterations
     * that there is no guarantee I am not confused. So, if you make more testing than me ->
     * please refactor this.
     */

    document.documentElement.scrollLeft = 0
    document.documentElement.scrollTop = 0

    /**
     * it is important grid page content is wrapped in a div and goes as a first child of content layout.
     * we use it as a selector.
     */
    const itemsList = el.firstElementChild as HTMLElement

    if (itemsList == null) {
      return
    }

    if (isUrlMatching(pathname, routesUrls)) {
      /**
       * when we are on the base page we scroll to whatever position we stored before.
       */
      requestAnimationFrame(() => {
        /*
          * OP-NOTE: Using setTimeout with a delay of 0 ensures that the scroll position is set
          * after the browser has completed any pending layout recalculations or rendering updates.
          * This prevents the scroll logic from being executed too early, which could cause
          * incorrect scroll behavior.
          *
          * Example: https://dev.azure.com/carfluent/CarFluent/_sprints/taskboard/Team%202/CarFluent/Team%202%20Sprint%2069?workitem=14038
        */
        setTimeout(() => {
          itemsList.style.position = 'relative'
          window.scrollTo(0, scrollPosRef.current[pathname] ?? 0)
        }, 0)
      })
    } else {
      /**
       * when we are on a nested route page we need to make base content absolute
       * so that it would not affect the layout because in other case it jumps up.
       */
      requestAnimationFrame(() => {
        itemsList.style.position = 'absolute'
      })
    }
  }, [pathname, routesUrls])

  /**
   * on each such routes start we need to start from the top
   */
  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0)
    })
  }, [])

  useEffect(() => {
    onMount?.()

    return () => {
      onUnmount?.()
    }
  }, [onMount, onUnmount])

  // ========================================== //

  return (
    <div className={`${isSubmenuVisible ? 'g-submenu-content' : 'g-content-layout'} ${className ?? ''}`} ref={elRef}>
      {/* It is used as a container for Outlet. But children approach left for versatility. */}
      {children == null ? <Outlet /> : children}
    </div>
  )
}

export default RoutesWithPagesOverlay
