import { useCallback, useEffect, useMemo } from 'react'
import { useForm, type UseFormReturn } from '@carfluent/common'

import type { CopyMonthFormData } from 'types'
import type { CopyMonthModalProps } from 'components/crm/CopyMonthModal'

import { getInitialCopyMonthFormData } from './constants'
import { copyMonthValidator } from './validator'

interface UseCopyMonthModalReturn extends UseFormReturn<CopyMonthFormData> {
  onChangeDate: (id: string, date: Date) => void
}

const useCopyMonthModal = (props: CopyMonthModalProps): UseCopyMonthModalReturn => {
  const { currentMonth, onSubmit, isModalOpen } = props

  const INITIAL_VALUES = useMemo(() => getInitialCopyMonthFormData(currentMonth), [currentMonth])

  const form = useForm<CopyMonthFormData>({
    baseValues: INITIAL_VALUES,
    submitAction: onSubmit,
    validationRules: copyMonthValidator
  })

  const { setValues, onChange, validateForm } = form

  const onChangeDate = useCallback((id: string, value: Date): void => {
    const date = new Date(value)
    onChange(id, date)
    validateForm()
  }, [onChange, validateForm])

  useEffect(() => {
    setValues(INITIAL_VALUES)
  }, [currentMonth, INITIAL_VALUES, setValues, isModalOpen])

  return { ...form, onChangeDate }
}

export default useCopyMonthModal
