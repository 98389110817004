import type { FormValidation } from '@carfluent/common'

import { dictionaryItemDropdownField, dateField } from 'utils/validationPresets'

import type { ShiftDetailsFormData } from './types'

const dictionaryItemField = dictionaryItemDropdownField(true)
const date = dateField('append')

const validationRules: FormValidation<ShiftDetailsFormData> = {
  person: dictionaryItemField,
  date
}

export default validationRules
