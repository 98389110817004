import type { FC, MouseEventHandler } from 'react'
import type { IconProps } from 'types'

interface IconSVGCloseProps extends IconProps {
  onClick?: MouseEventHandler<SVGSVGElement>
  className?: string
}

const IconSVGClose: FC<IconSVGCloseProps> = ({
  onClick,
  color = '#101010',
  width = 24,
  height = 24,
  className
}) => {
  return (
    <svg className={className} onClick={onClick} xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'>
      <path
        d='M14.087 12L20.317 5.76969C20.7225 5.36443 20.7225 4.70919 20.317 4.30394C19.9118 3.89869 19.2565 3.89869 18.8513 4.30394L12.621 10.5342L6.39092 4.30394C5.98548 3.89869 5.33043 3.89869 4.92518 4.30394C4.51973 4.70919 4.51973 5.36443 4.92518 5.76969L11.1553 12L4.92518 18.2302C4.51973 18.6355 4.51973 19.2907 4.92518 19.696C5.12714 19.8981 5.39269 19.9997 5.65805 19.9997C5.92341 19.9997 6.18877 19.8981 6.39092 19.696L12.621 13.4657L18.8513 19.696C19.0534 19.8981 19.3188 19.9997 19.5842 19.9997C19.8495 19.9997 20.1149 19.8981 20.317 19.696C20.7225 19.2907 20.7225 18.6355 20.317 18.2302L14.087 12Z'
        fill={color}
      />
    </svg>
  )
}

export default IconSVGClose
