
import type { FC } from 'react'
import { Skeleton } from '@material-ui/lab'

import IconSVG from 'components/inlineImages'

const MailSkeleton: FC = () => (
  <div className='skeleton-item'>
    <IconSVG.Message />
    <Skeleton variant='text' width={80} height={14} animation={false} />
    <Skeleton variant='text' width={120} height={14} animation={false} />
    <Skeleton variant='text' width={182} height={14} animation={false} />
  </div>
)

export default MailSkeleton
