import type { DictionaryItems } from 'api/types'
import { getParsedToken } from 'services/storage.service'
import { UNASSIGNED_LEAD, getAssignedToMeLead } from 'constants/constants'

const expandAssignedToOptions = (assignedTo: DictionaryItems<string>): DictionaryItems<string> => {
  const currentUserId = Number(getParsedToken()?.sub)
  const assignedToMeIndex = assignedTo.findIndex(({ id }) => currentUserId === id)

  if (assignedToMeIndex > -1) {
    assignedTo.splice(assignedToMeIndex, 1)
  }

  const assignedToMeLead = getAssignedToMeLead(currentUserId)

  return [assignedToMeLead, UNASSIGNED_LEAD, ...assignedTo]
}

export default expandAssignedToOptions
