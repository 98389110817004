import type { FC } from 'react'
import type { IconProps } from 'types'

const IconErrorDoc: FC<IconProps> = ({
  width = 24,
  height = 24
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z" fill="#FFA451"/>
    </svg>
  )
}

export default IconErrorDoc
