import type { FC } from 'react'

import { Button, Typography } from '@material-ui/core'

import { FileInfoProps } from 'components/dialogs/ImportCsvModal/types'
import { useStyles } from './styles'

export const FileInfo: FC<FileInfoProps> = ({ file, deleteFile }) => {
  const styles = useStyles()

  return (
    <div className={styles.fileExistContent}>
      <Typography variant='body1'>
        {file?.name}
      </Typography>
      <Button onClick={deleteFile} variant='text'>
        Delete
      </Button>
    </div>
  )
}
