import { type ColumnDef, formatCurrencyAccounting } from '@carfluent/common'

import TextCellHOC from 'components/common/Table/cells/TextCell'
import TooltipCell from 'components/common/Table/cells/TooltipCell'
import LinkCell, { LinkCellHOC } from 'components/common/Table/cells/LinkCell'
import TooltipedText from 'components/common/TooltipedText'

import type { SaleRecordModel } from 'api/types/responses'

import { joinPartsBySpace } from 'utils/view_helper'
import { isPersonalType } from 'utils/deals/workflowTypes'
import { formatDate, toDateOrNull } from 'utils/parse_date'

export const AMOUNT_FORMAT = { emptyValues: '-', allowNegative: true }

const columnDefinitions: Array<ColumnDef<SaleRecordModel>> = [
  {
    size: 120,
    minSize: 120,
    accessorKey: 'saleDate',
    loading: 'big_line',
    header: TextCellHOC({ text: 'Sale date' }),
    cell: (props) => {
      const date = typeof props.row.original.saleDate === 'string' ? toDateOrNull(props.row.original.saleDate) : props.row.original.saleDate
      const value = date != null ? formatDate(date, 'MM/dd/yy') ?? '-' : '-'
      return <TooltipedText value={value} />
    }
  },
  {
    size: 80,
    minSize: 80,
    sortable: false,
    loading: 'big_line',
    id: 'dealId',
    accessorFn: (row) => ({
      path: `/deals/${row.dealId ?? ''}`,
      title: row.dealId
    }),
    header: TextCellHOC({ text: 'Deal id' }),
    cell: LinkCellHOC({ withTooltip: false, emptyCellValue: '-' })
  },
  {
    size: 160,
    minSize: 160,
    cell: TooltipCell,
    loading: 'big_line',
    id: 'dealTypeId',
    accessorFn: (row) => row.dealTypeName ?? '',
    header: TextCellHOC({ text: 'Type' })
  },
  {
    size: 200,
    minSize: 200,
    cell: TooltipCell,
    sortable: false,
    loading: 'big_line',
    accessorKey: 'dealAccountingStateName',
    header: TextCellHOC({ text: 'Status' })
  },
  {
    size: 140,
    cell: TooltipCell,
    sortable: false,
    loading: 'big_line',
    accessorKey: 'dealFinanceTypeName',
    header: TextCellHOC({ text: 'Payment type', className: 'payment-type-cell-wrapper' })
  },
  {
    size: 200,
    sortable: false,
    cell: (props) => <TooltipCell {...props} className='lender-wrapper' />,
    loading: 'big_line',
    accessorKey: 'lenderName',
    header: TextCellHOC({ text: 'Lender' })
  },
  {
    size: 100,
    minSize: 100,
    cell: (props) => <TooltipCell {...props} className='stock-wrapper' />,
    sortable: false,
    loading: 'big_line',
    id: 'stock',
    accessorFn: (row) => row.inventoryVehicle.stock ?? '',
    header: TextCellHOC({ text: 'Stock #' })
  },
  {
    size: 160,
    minSize: 160,
    sortable: false,
    cell: (props) => isPersonalType(Number(props.row.original.dealTypeId))
      ? <LinkCell {...props} className='salesperson-wrapper' withTooltip={false} emptyCellValue='-' />
      : <TooltipCell {...props} className='salesperson-wrapper' />,
    loading: 'big_line',
    id: 'customer',
    header: TextCellHOC({ text: 'Customer' }),
    accessorFn: (row) => isPersonalType(Number(row.dealTypeId))
      ? ({
          path: `/crm/leads/${row.leadId ?? ''}`,
          title: isPersonalType(Number(row.dealTypeId))
            ? joinPartsBySpace(row.personalDetails.firstName, row.personalDetails.lastName)
            : row.businessDetails.businessName
        })
      : row.businessDetails.businessName
  },
  {
    size: 260,
    minSize: 260,
    sortable: false,
    cell: LinkCellHOC({ withTooltip: false, emptyCellValue: '-' }),
    loading: 'big_line',
    id: 'car',
    header: TextCellHOC({ text: 'Car' }),
    accessorFn: (row) => ({
      path: `/vehicles/${row.inventoryVehicle.id ?? ''}`,
      title: joinPartsBySpace(row.inventoryVehicle.year, row.inventoryVehicle.make, row.inventoryVehicle.model)
    })
  },
  {
    size: 130,
    minSize: 130,
    cell: (props) => <TooltipCell {...props} className='right-alignment' />,
    loading: 'big_line',
    id: 'frontGross',
    accessorFn: (row) => formatCurrencyAccounting(row.frontGross, AMOUNT_FORMAT),
    header: TextCellHOC({ text: 'Front gross' })
  },
  {
    size: 130,
    minSize: 130,
    cell: (props) => <TooltipCell {...props} className='right-alignment' />,
    loading: 'big_line',
    id: 'backGross',
    accessorFn: (row) => formatCurrencyAccounting(row.backGross, AMOUNT_FORMAT),
    header: TextCellHOC({ text: 'Back gross' })
  },
  {
    size: 140,
    minSize: 140,
    cell: (props) => <TooltipCell {...props} className='right-alignment' />,
    loading: 'big_line',
    id: 'salesCommission',
    accessorFn: (row) => formatCurrencyAccounting(row.salesCommission, AMOUNT_FORMAT),
    header: TextCellHOC({ text: 'Commission' })
  },
  {
    size: 130,
    minSize: 130,
    cell: (props) => <TooltipCell {...props} className='right-alignment' />,
    loading: 'big_line',
    id: 'netProfit',
    accessorFn: (row) => formatCurrencyAccounting(row.netProfit, AMOUNT_FORMAT),
    header: TextCellHOC({ text: 'Net profit' })
  },
  {
    size: 160,
    minSize: 160,
    cell: (props) => <TooltipCell {...props} className='salesperson-wrapper' />,
    sortable: false,
    loading: 'big_line',
    id: 'salesperson',
    accessorFn: (row) => joinPartsBySpace(row.salesperson?.dealerFirstName ?? '-', row.salesperson?.dealerLastName ?? '') ?? '',
    header: TextCellHOC({ text: 'Salesperson' })
  }
]

export default columnDefinitions
