import { css } from '@emotion/css'

export default css(`
  min-height: 54px;
  display: flex;
  flex: 1;
  
  &.is-loading .text-skeleton {
    min-height: 54px;
    max-height: 54px;
  }
  
  &:not(.is-loading) {
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .container {

    .label {
      color: rgba(33, 33, 33, 0.50);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
  
    .value {
      color:  #101010;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.4px;

      .percent {
        color:  #101010;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.4px;
      }
    }

  }

`)
