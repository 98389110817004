import type { FC, ReactNode } from 'react'
import { cn } from '@carfluent/common'

import ActionsMenu from 'components/common/ActionsMenu'
import AccountingHOK from 'pages/accounting/AccountingHOC'
import FiltersPanel from 'components/common/FiltersPanel'
import TransactionDetails from 'pages/accounting/TransactionDetails'

import useSchedulesDashboard, { TABS, TabIds } from './hook'
import SchedulesTab from './components/SchedulesTab'
import CLASS_NAME from './styles'

const SchedulesDashboard: FC = () => {
  const {
    cmdPayablesReset,
    cmdReceivablesReset,
    counters,
    loaders,
    onCreateBalanceTransaction,
    onPayablesLoaded,
    onReceivablesLoaded,
    onTabSelected,
    onViewBalanceTransaction,
    selectedTabIdx,
    topPanelActions,
    transactionModalProps
  } = useSchedulesDashboard()

  const tabProps = {
    tabs: TABS.map((item, index) => ({
      ...item,
      name: item.title,
      counter: counters[item.id] ?? 0,
      isLoading: loaders[index].isLoading
    })),
    activeTab: selectedTabIdx,
    onSelectTab: onTabSelected
  }

  return (
    <div className={cn('g-content-layout', CLASS_NAME)}>
      <FiltersPanel
        renderActions={(): ReactNode => (
          <ActionsMenu
            actions={topPanelActions}
            className='cf-actions-menu'
            buttonTitle='NEW ENTRY'
          />
        )}
        tabProps={tabProps}
      />

      <SchedulesTab
        isPayables
        isSelected={selectedTabIdx === TabIds.Payables}
        onCreateBalanceTransaction={onCreateBalanceTransaction}
        onLoadOpenBalances={onPayablesLoaded}
        onStartLoader={loaders[TabIds.Payables].startLoader}
        onStopLoader={loaders[TabIds.Payables].stopLoader}
        onViewBalanceTransaction={onViewBalanceTransaction}
        resetCommand={cmdPayablesReset}
      />

      <SchedulesTab
        isSelected={selectedTabIdx === TabIds.Receivables}
        onCreateBalanceTransaction={onCreateBalanceTransaction}
        onLoadOpenBalances={onReceivablesLoaded}
        onStartLoader={loaders[TabIds.Receivables].startLoader}
        onStopLoader={loaders[TabIds.Receivables].stopLoader}
        onViewBalanceTransaction={onViewBalanceTransaction}
        resetCommand={cmdReceivablesReset}
      />

      <TransactionDetails {...transactionModalProps} />
    </div>
  )
}

export default AccountingHOK(SchedulesDashboard)
