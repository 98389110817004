import { css } from '@emotion/css'

export const TOGGLER_HEIGHT = 16
export const DEFAULT_FIXED_HEIGHT = 95
export const DEFAULT_BG_COLOR = '#ffffff'

export interface ClassesConfig {
  fixedHeight?: number
  buttonBg?: string
}

const getClasses = ({
  fixedHeight = DEFAULT_FIXED_HEIGHT,
  buttonBg = DEFAULT_BG_COLOR
}: ClassesConfig): string => css(`
  width: 100%;
  position: relative;

  .cf-content {
    transition: none;
    overflow: hidden;
    max-height: ${fixedHeight - TOGGLER_HEIGHT}px;
  }

  &.cf-expanded .cf-content {
    max-height: 1000vh;
    transition: all 1s ease-in-out; // AZ-TODO: replace "all" by appropriate css property (min-height does not work)
  }

  .cf-toggler-button {
    position: absolute;
    bottom: 8px;
    right: 0;
    background-color: ${buttonBg};

    display: flex;
    justify-content: flex-end;
    user-select: none;
    cursor: pointer;

    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #101010;

    .cf-ellipsis {
      padding-right: 4px;
      padding-left: 6px;
    }

    .cf-link {
      text-decoration-line: underline;
    }
  }
`)

export default getClasses
