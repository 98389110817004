import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import IconSVG from 'components/inlineImages'
import CLASS_NAME from './styles'

interface StepperProps {
  currentStep: number
  numberOfSteps: number
  steps: string[]
}

const Stepper: FC<StepperProps> = ({ currentStep, numberOfSteps, steps }) => {
  const activeClass = (index: number): string => currentStep >= index ? 'active' : 'default'
  const currentActiveItem = (index: number): string => currentStep === index ? 'current-active-item' : ''
  const isFinalStep = (index: number): boolean => index === numberOfSteps - 1

  return (
    <div className={CLASS_NAME}>
      {steps.map((el, index) => (
        <div className={cnx('item', activeClass(index), currentActiveItem(index))} key={index}>
          <div className='name'>{el}</div>
          {isFinalStep(index) ? null : <IconSVG.ArrowStep />}
        </div>
      )
      )}
    </div>
  )
}

export default Stepper
