import { css } from '@emotion/css'

export default css(`
  --columnGap: 24px;
  background-color: #fff;
  padding: 24px 16px;
  display: grid;
  grid-template-columns: repeat(4, minmax(calc(25% - var(--columnGap)), 1fr));
  grid-template-rows: auto 1px auto 1px auto;
  grid-template-areas:
    "cell-1-1 cell-1-2 cell-1-3 cell-1-4"
    "cell-2-1 cell-2-1 cell-2-1 cell-1-4"
    "cell-3-1 cell-3-1 cell-3-1 cell-1-4"
    "cell-4-1 cell-4-1 cell-4-1 cell-1-4"
    "cell-5-1 cell-5-1 cell-5-1 cell-1-4";
  column-gap: var(--columnGap);
  row-gap: 24px;
  
  .cell-1-1 { grid-area: cell-1-1; }
  .cell-1-2 { grid-area: cell-1-2; }
  .cell-1-3 { grid-area: cell-1-3; }
  .cell-1-4 { grid-area: cell-1-4; } 
  .cell-2-1 { grid-area: cell-2-1; } 
  .cell-3-1 { grid-area: cell-3-1; }
  .cell-3-4 { grid-area: cell-3-4; }
  .cell-4-1 { grid-area: cell-4-1; }
  .cell-5-1 { grid-area: cell-5-1; }
  .cell-5-4 { grid-area: cell-5-4; }

  input { text-overflow: ellipsis; }
  
  .divider {
    display: flex;
    align-items: center;
    
    .cf-divider { width: 100%; }
  }
  
  .section-title {
    user-select: none;
  }
    
  .fields-column {
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    .fields {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
  
  .fields-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    .fields {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 16px;
      
      .block-skeleton,
      .field-container {
        box-sizing: border-box;
        max-width: calc(20% - 16px);
        width: calc(20% - 16px);
        min-height: 56px;
      }
    }
  }
  
  .block-skeleton.is-loading {
    &.section-title { width: 180px; }
   .text-skeleton { height: 16px; }
  }
  
  .labeled-skeleton:not(.is-loading),
  .labeled-field {
    display: flex;
    gap: 8px;
    align-items: center;
    
    label {
      min-width: 107px;
      color: #000;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      cursor: pointer;
    }
    
    .cf-form-field {
      width: 100%;
      
      .cf-form-field-error {
        letter-spacing: 0.3px;
      }
    }
  }
`)
