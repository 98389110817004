import type { FC, ReactNode } from 'react'
import { cnx } from '@carfluent/common'

import getColorForString from 'utils/getColorForString'
import shortenNameForAvatar from 'utils/shortenNameForAvatar'
import { AVATAR_COLORS, DEFAULT_AVATAR_COLOR, DEFAULT_BOT_AVATAR_COLOR } from 'constants/constants'
import TooltipedText from 'components/common/TooltipedText'

import CLASS_NAME from './styles'

interface NameWithAvatarProps {
  name?: string | null
  showAvatar: boolean
  avatarOnly?: boolean
  className?: string
  avatarStyle?: { backgroundColor: string }
  avatarIcon?: ReactNode | null
}

const NameWithAvatar: FC<NameWithAvatarProps> = ({
  name,
  showAvatar,
  className,
  avatarStyle: _avatarStyle,
  avatarOnly = false,
  avatarIcon = null
}) => {
  const avatarStyle = {
    backgroundColor: avatarIcon != null
      ? DEFAULT_BOT_AVATAR_COLOR
      : name != null
        ? getColorForString(name, AVATAR_COLORS, DEFAULT_AVATAR_COLOR)
        : DEFAULT_AVATAR_COLOR
  }

  const avatarName = shortenNameForAvatar(name)

  if (avatarOnly) {
    return (
      <div className={cnx(CLASS_NAME, className)}>
        <div className='avatar' style={_avatarStyle ?? avatarStyle}>
          {avatarName}
        </div>
      </div>
    )
  }

  return (
    <div className={cnx(CLASS_NAME, className)}>
      {
        showAvatar && (
          <div className='avatar' style={_avatarStyle ?? avatarStyle}>
            {avatarIcon ?? avatarName}
          </div>
        )
      }
      <TooltipedText
        value={name ?? '-'}
      />
    </div>
  )
}

export default NameWithAvatar
