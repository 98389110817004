import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGCrmIcon: FC<IconProps> = ({ color = '#101010', width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_40751_4252)'>
        <path fillRule='evenodd' clipRule='evenodd' d='M16.6699 13.1299C18.0399 14.0599 18.9999 15.3199 18.9999 16.9999V19.9999H21.9999C22.5499 19.9999 22.9999 19.5499 22.9999 18.9999V16.9999C22.9999 14.8199 19.4299 13.5299 16.6699 13.1299Z' fill={color} />
        <path d='M9 12C11.2091 12 13 10.2091 13 8C13 5.79086 11.2091 4 9 4C6.79086 4 5 5.79086 5 8C5 10.2091 6.79086 12 9 12Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M14.9999 12C17.2099 12 18.9999 10.21 18.9999 8C18.9999 5.79 17.2099 4 14.9999 4C14.5299 4 14.0899 4.1 13.6699 4.24C14.4999 5.27 14.9999 6.58 14.9999 8C14.9999 9.42 14.4999 10.73 13.6699 11.76C14.0899 11.9 14.5299 12 14.9999 12Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M9 13C6.33 13 1 14.34 1 17V19C1 19.55 1.45 20 2 20H16C16.55 20 17 19.55 17 19V17C17 14.34 11.67 13 9 13Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_40751_4252'>
          <rect width='24' height='24' fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGCrmIcon
