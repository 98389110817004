import type { FormValidation, DeepRequired } from '@carfluent/common'

import {
  required,
  leadCmsEmails
} from 'utils/validationPresets'

import type { SendEmailFormData } from './types'

export const createFormValidation: FormValidation<DeepRequired<SendEmailFormData>> = {
  to: required,
  cc: (value) => leadCmsEmails({ value, isRequired: false }),
  subject: required
}

export const createFormValidationWithoutSubject: FormValidation<DeepRequired<SendEmailFormData>> = {
  cc: (value) => leadCmsEmails({ value, isRequired: false })
}
