import type { FormValidation } from '@carfluent/common'
import {
  phoneField,
  emailField,
  zipCodeField,
  businessNameByCtx,
  businessEinByCtxOptional,
  personalSsnByCtx,
  personalDriverLicenseByCtx,
  personalDriverLicenseStateByCtx,
  firstNameField,
  lastNameField
} from 'utils/validationPresets'

import type { CustomerFormData } from './types'
import { isPersonalType } from 'utils/deals/workflowTypes'

export const DEPENDENT_VALIDATIONS = {
  customerTypeId: ['firstName', 'lastName', 'ein', 'businessName']
}

export const validationRules: FormValidation<CustomerFormData> = {
  firstName: (val: number | null, ctx?: CustomerFormData): string | null => {
    return firstNameField(isPersonalType(ctx?.customerTypeId))(val)
  },
  lastName: (val: number | null, ctx?: CustomerFormData): string | null => {
    return lastNameField(isPersonalType(ctx?.customerTypeId))(val)
  },
  phoneNumber: phoneField(),
  email: emailField(),
  driverLicense: personalDriverLicenseByCtx,
  stateOfIssue: personalDriverLicenseStateByCtx,
  ssn: personalSsnByCtx,
  'address.zipCode': zipCodeField(),
  'mailingAddress.zipCode': zipCodeField(),
  ein: businessEinByCtxOptional,
  businessName: businessNameByCtx
}
