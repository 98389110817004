import { css } from '@emotion/css'

export default css`
  --table-background-color: #F3F3F3;
  --table-row-border: 8px solid var(--table-background-color);
  --table-item-background-color: #FFFFFF;
  --table-padding: 16px;

  width: 100%;
  height: 100%;

  background: #fff;
  padding: 24px 16px;
  border-radius: 2px 12px 12px 12px;

  .form-pack-content {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .form-pack-table-wrapper {
    background: #F3F3F3;
    border-radius: 4px;
    padding: 8px;
    
    .form-pack-table-wrapper-header {
      height: 48px;
      background: #FAFAFA;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      padding: 0 16px;
      font-weight: 500;
    }
    
    .form-pack-table-body {
      display: grid;
      row-gap: 8px;
    }
    
    .envelop-loader-wrapper {
      height: 184px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .empty-list {
      display: flex;
      height: 56px;
      justify-content: center;
      align-items: center;
      background: white;
    }
  }
  
  .manage-form-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 16px;
  }

  .contract-sent-table {
     margin-bottom: 24px;

    .cf-no-data-block {
      border: 8px solid #F3F3F3;
      border-top: none;
      color: rgba(33, 33, 33, 0.50);
      letter-spacing: 0.5px;
      padding: 16px;
    }

    .cf-table {
      overflow: hidden;
    }

    th * {
      color: #101010
    }

    tr {
      display: flex;
      align-items: center;
      box-sizing: content-box;
      border: var(--table-row-border);
      background-color: var(--table-item-background-color);
      border-radius: 12px;

      .cf-cell-wrapper {
        background-color: var(--table-item-background-color);
      }
    }

    thead th:last-child > div {
      justify-content: flex-end;
    }

    .MuiTableContainer-root {
      border-radius: 4px;
      
      table {
        display: flex;
        flex-direction: column;
        background-color: var(--table-background-color);

        th * {
          font-weight: 500;
        }

        th, td {
          border-bottom: 0px;

          div {
            border: 0;
          }
        }

        td:first-child > div, th:first-child > div {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        td:last-child > div, th:last-child > div {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        
        th:last-child .cf-header-cell-title {
          width: 100%;

          p {
            text-align: left;
          }
        }

      }

      .MuiTableBody-root {
        .MuiTableRow-root.MuiTableRow-hover:hover {
          background: white;
          
          td {
            background: white;
          }
          
          &.cf-table-row-hover:hover > td > div {
            background: white;
          }
        }

        tr {
          border-top: 0px;

          &:hover td div.cf-cell-wrapper {
            border: 0;
          }
          
          td:last-child > div {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }
`

export const CONFIRM_MODAL_CLASS_NAME = css`
  .cf-modal-content-scroll-wrapper .cf-modal-root {
    width: 680px;
    max-width: 680px;
  }
`
