import { type FC } from 'react'
import email_expired from 'assets/email_expired.svg'
import CLASS_NAME from './styles'

const ExpiredLinkPage: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <div className='empty-header' />
      <div className='wrapper'>
        <img alt='expired email' src={email_expired} className='image-wrapper' />
        <h5 className='title'>
          Link has been expired
        </h5>
        <p className='text'>
          Please ask you manager to send a new invite
        </p>
      </div>
    </div>
  )
}

export default ExpiredLinkPage
