import { css } from '@emotion/css'

export default css(`
  display: flex;
  font-size: 16px;
  color: #21212180;
  font-weight: 500;
  line-height: 24px;
  padding: 6px 4px;
  letter-spacing: 0.4px;
  flex-direction: column;
  width: calc(100% - 112px);

  .name-container .tooltip-content {
    color: #101010;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .description-container .tooltip-content {
    font-size: 14px;
    color: #21212180;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`)
