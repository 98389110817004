import type { ItemWithId, KeyVal } from 'types'
import replaceById from 'utils/common/replaceById'

interface DictionaryProps {
  dictionaryItems: ItemWithId[]
  foreignKey: string
}

function replaceAllById<TRes extends KeyVal> (
  list: KeyVal[],
  dictionaries: DictionaryProps[]
): TRes[] {
  return list.map(item => dictionaries.reduce((acc, el) => replaceById<TRes>(acc, el.dictionaryItems, el.foreignKey), item)) as TRes[]
}

export default replaceAllById
