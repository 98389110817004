/**
 * This is a thin wrapper around MUI Tooltip to reduce
 * direct imports from MUI.
 */

import type { FC, ReactNode } from 'react'
import { cnx } from '@carfluent/common'
import {
  Tooltip as MuiTooltip,
  type TooltipProps as MuiTooltipProps
} from '@material-ui/core'

import CLASS_NAME from './styles'

export interface TooltipProps {
  children?: JSX.Element | JSX.Element[]
  className?: string
  isOpen?: boolean
  title: NonNullable<ReactNode>
  placement?: MuiTooltipProps['placement']
  popperClassName?: string
}

const Tooltip: FC<TooltipProps> = ({
  children,
  className = '',
  isOpen,
  placement = 'top',
  title,
  popperClassName
}) => {
  return (
    <MuiTooltip
      placement={placement}
      open={isOpen}
      title={title}
      classes={{
        tooltip: cnx(CLASS_NAME, className),
        popper: popperClassName
      }}
    >
      <div>{children}</div>
    </MuiTooltip>
  )
}

export default Tooltip
