import { useCallback, useEffect, useMemo } from 'react'
import { type Result, isOk, useForm, useModal, useSubscribe } from '@carfluent/common'

import useCustomSnackbar from 'hooks/useCustomSnackbar'
import VehiclesApiProvider from 'api/vehicles.api'
import { INVENTORY_DUPLICATE_ERROR } from 'constants/errors'
import Events from 'constants/events'
import { DealState, type ListPayload, type PaginatedResult, type VehicleDetailsByVin } from 'api/types'
import { GET_DEFAULT_TRADE_IN_MODEL } from 'api/defaults/tradeInDetailsModel'
import { GET_DEFAULT_DEAL_VEHICLE_MODEL, GET_DEFAULT_VEHICLES_SORTING } from 'api/defaults/vehicles'
import { VehicleOption } from 'components/common/VehiclesDropdownOption/types'
import CustomersCoreApiProvider from 'api/customersCore.api'

import type { UseVehiclesTabProps, UseVehiclesTabReturn, VehiclesTabState } from './types'
import validationRules, { DEPENDENT_VALIDATIONS } from './validation'

const ERROR_MSG = 'Changes failed to save.'
const SUCCESS_MSG = 'Changes successfully saved.'

const useVehiclesTab = ({
  dealId,
  dealRowVersion,
  isSelected,
  tradeInDetails: _tradeInDetails,
  vehicle: _vehicle,
  nextTab,
  tabIdx,
  onLoadSalesCommission,
  onTrySwitchTab,
  onSaveChanges,
  dealState,
  isDeposited
}: UseVehiclesTabProps): UseVehiclesTabReturn => {
  const { showAlert } = useCustomSnackbar()
  const { isModalOpen: isUnsavedChangesShown, onOpenModal, onCloseModal } = useModal()
  const isRestrictedChangeVehicle = ((dealState === DealState.Completed) || isDeposited) ?? false

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const baseValues = useMemo(() => {
    const tradeInDetails = _tradeInDetails ?? GET_DEFAULT_TRADE_IN_MODEL()

    return {
      tradeInDetails,
      vehicle: _vehicle ?? GET_DEFAULT_DEAL_VEHICLE_MODEL(),
      // we need initial null to differentiate between initial and updated state
      isTradeInSectionVisible: tradeInDetails.id > 0 ? true : null,
      actualMileage: _vehicle?.odometer ?? null
    }
  }, [dealRowVersion, _vehicle, _tradeInDetails])

  const submitAction = useCallback(async (_values: VehiclesTabState): Promise<VehiclesTabState | null> => {
    try {
      if (dealRowVersion == null || _values.vehicle.id == null) {
        return null
      }

      const data = {
        vehicle: {
          vehicleId: _values.vehicle.id,
          actualMileage: _values.actualMileage ?? 0
        },
        tradeInDetails: (_values.isTradeInSectionVisible === true) ? _values.tradeInDetails : null
      }
      const payload = { dealId, rowVersion: dealRowVersion, data }

      const res = await CustomersCoreApiProvider.patchDealVehicle(payload)
      await onLoadSalesCommission(dealId)

      onSaveChanges(res)
      showAlert(SUCCESS_MSG, { variant: 'success' })

      return {
        actualMileage: _values.actualMileage,
        isTradeInSectionVisible: _values.isTradeInSectionVisible,
        tradeInDetails: res.tradeInDetails,
        vehicle: res.vehicle
      }
    } catch (e: any) {
      showAlert(e?.response?.data?.message ?? ERROR_MSG)
      return null
    }
  }, [
    dealId,
    dealRowVersion,
    showAlert,
    onLoadSalesCommission,
    onSaveChanges
  ])

  const onActionResult = useCallback((res: Result<VehiclesTabState>, resetForm: (vals: VehiclesTabState) => void) => {
    if (isOk(res)) {
      resetForm(res.result)
    }
  }, [])

  const {
    values,
    errors,
    touched,
    isSubmitting,
    hasChanges,
    onBlur,
    onSubmit,
    onChange,
    validateForm,
    resetForm
  } = useForm<VehiclesTabState>({
    baseValues,
    validationRules,
    dependentValidations: DEPENDENT_VALIDATIONS,
    isTrackingChanges: true,
    submitAction,
    onActionResult
  })

  const showConfirmation = (msg: string): void => {
    showAlert(msg, { variant: 'success' })
  }

  const onApplyVin = async (vin: string): Promise<VehicleDetailsByVin | null> => {
    try {
      // if result of request is 404
      // it means the car is not in system and we can do request to decode vin
      const isCarExistence = await VehiclesApiProvider.isCarExistenceByVin(vin)

      if (isCarExistence) {
        showAlert(INVENTORY_DUPLICATE_ERROR)
        return null
      }

      const data = await VehiclesApiProvider.getVehicleByVin(vin)
      onChange('tradeInDetails', {
        ...values.tradeInDetails,
        vehicleYear: data.year,
        vehicleMake: data.make,
        vehicleModel: data.model,
        vehicleTrim: data.trim
      })
      return data
    } catch (e: any) {
      showAlert(e?.response?.data?.message ?? 'Error while loading trade in details.')
      return null
    }
  }

  const onVehicleChange = (field: string, val: VehicleOption | null): void => {
    if (val == null) {
      return
    }

    onChange(field, val)
    onBlur(field)

    if (val.mileage != null) {
      onChange('actualMileage', val.mileage)
    }
  }

  const onChangeSectionVisibility = (): void => {
    if (isRestrictedChangeVehicle) {
      return
    }
    onChange('isTradeInSectionVisible', !(values.isTradeInSectionVisible ?? false))
    onBlur('isTradeInSectionVisible')

    // DD-NOTE: unfortunately for now form actions are not properly synchronous
    // form internals must be rewritten to support this
    setTimeout(() => {
      validateForm()
    }, 0)
  }

  const getVehicles = useCallback(async (payload: ListPayload): Promise<PaginatedResult<VehicleOption>> => {
    try {
      const value = payload.search != null && payload.search !== '' ? payload.search.toLowerCase() : values.vehicle.vin ?? ''
      const response = await VehiclesApiProvider.getVehicles({ ...payload, search: value, ...GET_DEFAULT_VEHICLES_SORTING() })

      return {
        ...response,
        items: response.items
      }
    } catch (e) {
      showAlert('Cars failed to load')
      return { items: [], count: 0 }
    }
  }, [values.vehicle, showAlert])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const isUnsavedChangesShown = (nextTab.id !== tabIdx) && hasChanges

    if (isUnsavedChangesShown) {
      onOpenModal()
    } else {
      onCloseModal()
      onTrySwitchTab()
    }
  }, [nextTab, hasChanges, onTrySwitchTab, onOpenModal, onCloseModal])

  useSubscribe(Events.DealSaveRequested, async (_, skip) => {
    if (!isSelected || !hasChanges) {
      return skip()
    }

    const result = await onSubmit()
    return isOk(result)
  })

  // ========================================== //

  return {
    values,
    errors,
    touched,
    isSubmitting,
    hasChanges,
    isUnsavedChangesShown,
    onOpenUnsavedChanges: onOpenModal,
    onCloseUnsavedChanges: onCloseModal,
    onBlur,
    onSubmit,
    onChange,
    resetForm,
    onVehicleChange,
    onApplyVin,
    getVehicles,
    onChangeSectionVisibility,
    showConfirmation,
    isRestrictedChangeVehicle
  }
}

export default useVehiclesTab
