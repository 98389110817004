import { noop } from '@carfluent/common'
import type { ColumnDef } from '@carfluent/common/dist/UI'
import { ControlOrDeal } from 'constants/route_helper'

import TextCellHOC from 'components/common/Table/cells/TextCell'
import EditableCellHOK from 'components/common/Table/cells/editable_cell'
import CheckboxCell from 'components/common/Table/cells/CheckboxCell'
import LinkCell from 'components/common/Table/cells/LinkCell'
import { ControlIdentifierCellHOC } from 'components/common/Table/cells/IdentifierCell'
import { AmountCellHOC } from 'components/common/Table/cells/AmountCell'
import { DateCellHOC } from 'components/common/Table/cells/DateCell'
import { TextListHOC } from 'components/common/Table/cells/TextListCell'
import { LinksListHOC } from 'components/common/Table/cells/LinkButtonsListCell'
import { EntityWithAccountCellHOC } from 'components/common/Table/cells/EntityWithAccountCell'

import type { ColumnsConfig, OpenBalanceRow } from './types'

function getColumnDefinitions ({
  onToggleRowCheckbox,
  onOpenTransaction
}: ColumnsConfig): Array<ColumnDef<OpenBalanceRow>> {
  return [
    {
      accessorKey: 'checked',
      cell: EditableCellHOK<OpenBalanceRow>(
        {
          className: 'cf-checkbox-cell',
          onChange: onToggleRowCheckbox ?? noop
        },
        CheckboxCell
      ),
      header: TextCellHOC({ text: '' }),
      sortable: false,
      size: 56
    },
    {
      accessorKey: 'date',
      cell: DateCellHOC({ className: 'cf-date-cell', format: 'MM/dd/yyyy' }),
      header: TextCellHOC({ text: 'Date' }),
      sortable: true,
      size: 112
    },
    {
      id: 'entityAccount',
      accessorFn: (row) => ({
        account: row.account,
        customer: row.customer,
        vendor: row.vendor
      }),
      cell: EntityWithAccountCellHOC({ className: 'cf-account-cell' }),
      header: TextCellHOC({ text: 'Account' }),
      sortable: false,
      maxSize: 220
    },
    {
      id: 'transaction',
      accessorFn: (row: OpenBalanceRow) => row.transactions.map(t => ({
        id: t.id,
        onClick: () => onOpenTransaction?.(t.id, t.transactionTypeId),
        title: 'View Transaction'
      })),
      cell: LinksListHOC({ className: 'cf-transaction-cell' }),
      header: TextCellHOC({ text: 'Transaction' }),
      sortable: false,
      maxSize: 160
    },
    {
      id: 'reference',
      accessorFn: (row: OpenBalanceRow) => row.transactions.map(t => t.referenceNumber ?? ''),
      cell: TextListHOC({ className: 'cf-reference-cell' }),
      header: TextCellHOC({ text: 'Reference #' }),
      sortable: false,
      maxSize: 144
    },
    {
      id: 'control',
      accessorFn: ({ controlId, controlEntity, deal }: OpenBalanceRow) => {
        const path = ControlOrDeal(controlEntity, deal)

        if (path == null) {
          return null
        }

        return {
          title: controlId,
          path
        }
      },
      cell: LinkCell,
      header: TextCellHOC({ text: 'Control' }),
      sortable: false,
      maxSize: 140
    },
    {
      id: 'identifier',
      accessorFn: (row: OpenBalanceRow) => row.controlEntity ?? row.deal?.vehicle,
      cell: ControlIdentifierCellHOC({ className: 'cf-identifier-cell' }),
      header: TextCellHOC({ text: 'Identifier' }),
      sortable: false,
      maxSize: 304,
      minSize: 154
    },
    {
      accessorKey: 'amount',
      cell: AmountCellHOC({ className: 'cf-amount-cell', isAlignedRight: true }),
      header: TextCellHOC({ text: 'Amount', className: 'cf-amount-header-cell', isAlignedRight: true }),
      sortable: false,
      maxSize: 184
    }
  ]
}

export default getColumnDefinitions
