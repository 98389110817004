import { type FC, useState, type ReactNode } from 'react'
import { FormDropdown } from '@carfluent/common'

import type { DictionaryItem } from 'api/types'
import { getDisplayItem } from 'utils/selectHelpers'
import { GET_DEFAULT_ACCOUNTING_STATES } from 'pages/reports/ReportGrossProfit/hook/constants'
import type { PickedFiltersProps } from 'pages/reports/ReportGrossProfit/components/Filters'

import { CLASS_NAME, POPOVER_CLASS_NAME } from './styles'

export interface DealStatusFilterProps {
  setFilters: PickedFiltersProps['setFilters']
}

const ALL_SELECTED_LENGTH = 3
const dealStatusFilterTitle = 'Deal status'
const dealStatusOptions = GET_DEFAULT_ACCOUNTING_STATES()

const DealStatusFilter: FC<DealStatusFilterProps> = ({ setFilters }) => {
  const [selectedItems, setSelectedItems] = useState<DictionaryItem[]>(dealStatusOptions)

  const isAllSelected = selectedItems.length === ALL_SELECTED_LENGTH
  const isNoneSelected = selectedItems.length === 0

  const onChange = (_: string, v: DictionaryItem[] | null): void => {
    const newSelectedItems = v ?? []

    setSelectedItems(newSelectedItems)
    const accountingStateIds = newSelectedItems.length === ALL_SELECTED_LENGTH || newSelectedItems.length === 0
      ? dealStatusOptions.map(({ id }) => id)
      : newSelectedItems.map(({ id }) => id)

    void setFilters({ accountingStateIds }, true)
  }

  const renderSelectDisplay = (): ReactNode => {
    if (isNoneSelected) {
      return dealStatusFilterTitle
    }

    const txt = `${dealStatusFilterTitle} (${isAllSelected ? 'All' : `${selectedItems.length}`})`
    return getDisplayItem(txt)
  }

  return (
    <div className={CLASS_NAME}>
      <div className='action-bar'>
        <FormDropdown
          className='deal-status-filter'
          id='dealStatus'
          isMultiselect
          options={dealStatusOptions}
          value={selectedItems}
          popoverClassName={POPOVER_CLASS_NAME}
          onChange={onChange}
          disableClearable={isAllSelected}
          renderSelectDisplay={renderSelectDisplay}
        />
      </div>
    </div>
  )
}

export default DealStatusFilter
