import { useContext, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'

import AlertCTX from 'store/alert'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'

interface AlertDisplayProps {
  alert: Alert.Item
}

const AlertDisplay = observer(({ alert }: AlertDisplayProps): JSX.Element => {
  const { showAlert } = useCustomSnackbar()

  useEffect(() => {
    showAlert(alert.data, alert.options, alert.key)
  }, [alert])

  return <></>
})

const AlertPresenter = observer((): JSX.Element => {
  const { alerts } = useContext(AlertCTX)
  const cntrRef = useRef(0)

  return (
    <>
      {Array.from(alerts).map(val =>
        <AlertDisplay key={cntrRef.current++} alert={val[1]} />)}
    </>
  )
})

export default AlertPresenter
