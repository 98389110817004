import { isAxiosError } from 'axios'
import { ErrorCode } from 'api/types'

const isLockedPeriodError = (err: any): boolean => {
  if (isAxiosError(err)) {
    return err.response?.data?.code === ErrorCode.LockedAccountingPeriod
  }

  return false
}

export default isLockedPeriodError
