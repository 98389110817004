import type { AccountFormData } from './hook/types'

export enum AccountFields {
  Number = 'number',
  Name = 'name',
  Type = 'accountType',
  Category = 'accountCategory',
  Description = 'description'
}

export enum AccountFieldLabels {
  Number = 'Account number',
  Name = 'Account name',
  Type = 'Type',
  Category = 'Category',
  Description = 'Description'
}

export enum Messages {
  SuccessCreate = 'Account was created successfully.',
  SuccessUpdate = 'Account was updated successfully.',
}

export const getWindowTitle = (isEdit: boolean): string => isEdit ? 'Edit account' : 'Add account'

export const DEFAULT_FORM_DATA: AccountFormData = {
  [AccountFields.Name]: null,
  [AccountFields.Number]: null,
  [AccountFields.Type]: null,
  [AccountFields.Category]: null,
  [AccountFields.Description]: null
}

export const getDefaultFormData = (): AccountFormData => ({ ...DEFAULT_FORM_DATA })

export const DISABLED_CATEGORY = {
  name: 'Select type first...',
  id: -1
}

export const ACCOUNT_NUMBER_MAX_LENGTH = 9
