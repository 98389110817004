import { css } from '@emotion/css'
import { px, ScreenWidthBreakpoints } from 'constants/constants'

export default css(`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px 40px;

  .source-link {
    color: #458FFF;
    text-decoration: none;

    :hover { text-decoration: none; }
  }

  &-assigned-to p {
    min-width: 164px;
    max-width: 12vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media screen and (min-width: ${px(ScreenWidthBreakpoints.Medium)}) {
      max-width: 296px;
    }
  }
`)
