import type { SalesTaxReportFromApi, SaleTaxRowData } from './types'

const parseListData = (data: SalesTaxReportFromApi[]): SaleTaxRowData[] => {
  return data.map(parseRowData)
}

const parseRowData = (item: SalesTaxReportFromApi): SaleTaxRowData => {
  return {
    ...item,
    car: {
      path: `/vehicles/${item.vehicleId}`,
      title: `${item.year} ${item.make} ${item.model}`
    },
    deal: {
      path: `/deals/${item.dealId}`,
      title: `${item.dealId}`
    }
  }
}

export default parseListData
