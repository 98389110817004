import { css } from '@emotion/css'

export default css(`
  min-width: auto!important;

  .cost-tab {
    z-index: 101;
  }

  .cf-banner-appeared {
    :after {
      height: 16px;
    }
  }

  .short-info-wrapper {
    display: flex;
    margin-bottom: 16px;

    .vehicle-card-wrapper {
      overflow: visible;
      position: relative;
    }
  }
  
  .car-state-banner-adornment {
    display: flex;
    
    .view-transaction-button {
      &.cf-button-root.cf-button-variant-text {
        margin-right: 16px;
        text-decoration: none;
        
        .cf-button-content {
          text-decoration: underline;
          font-weight: 500;
          text-underline-position: from-font;
        }
      }
    }
  }

  .vehicle-details-section-tab-container {
    margin-bottom: 44px;

    .tab-selected .cf-loader {
      display: none;
    }

    .cost-tab {
      justify-content: center;

      :hover {
        opacity: 1;
        background: rgba(255,255,255, 0.5);
        color: rgba(0, 0, 0, .5);
        
        &.tab-selected {
          background: rgba(201, 155, 134, 0.5);
          color: rgba(255,255,255, 0.5);
        }
      }
    }

    .file-tab { justify-content: center; }

    .tab-list .cf-animated-wrapper {
      color #C99B86;
    }
  }

  /**
   * This magic allows left column to resize parent's height,
   * but does not allow it for the right column (notes).
   *
   * https://stackoverflow.com/questions/34194042/one-flex-grid-item-sets-the-size-limit-for-siblings
   */
  .short-info-left-column {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    max-width: calc(100% - 16px - 308px);

    @media (min-width: 1361px) {
      max-width: calc(100% - 16px - 368px);
    }
  }

  .short-info-right-column {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 12px;
    background-color: #fff;
    width: 308px;

    @media (min-width: 1361px) {
      width: 368px;
    }
  }

  .vehicle-card-wrapper {
    padding: 16px;
    border-radius: 12px;
    height: 100%;
    flex: 1;
  }
  
  .history-table {
    width: 416px;
    padding: 16px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 8px;

      &-item { color: rgba(33, 33, 33, 0.5); }
    }

    &-body { margin-bottom: 16px; }
  }

  .g-panel {
    box-shadow: none;
    padding: 24px 16px;
  }
`)
