import { type FC } from 'react'

import { type FinancingApplicationLenderDecisionDetailsDto } from 'api/types'

import ModalFullscreen from 'components/dialogs/ModalFullscreen'
import LenderDecisionCard from 'components/deals/LenderDecisionCard'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'

import CLASS_NAME, { MODAL_CLASSES } from './styles'

interface LenderDecisionsModalProps {
  isOpen: boolean
  lenderDecisionDetails?: FinancingApplicationLenderDecisionDetailsDto[] |null
  onClose: () => void
}

const TITLE = 'Lender decisions'
const TOP_ROW_LABELS = [
  'Lender',
  'Amount',
  'Buy rate',
  'Term',
  'Status',
  'Comments'
]

const LenderDecisionsModal: FC<LenderDecisionsModalProps> = ({
  isOpen,
  lenderDecisionDetails = null,
  onClose: _onClose
}) => {
  return (
    <ModalFullscreen
      onClose={_onClose}
      isOpen={isOpen}
      title={TITLE}
      isFullscreen
      className={MODAL_CLASSES}
      renderFooterActions={(
        <CancelSubmitActionsFooter
          onClose={_onClose}
          submitTitle=''
        />
      )}
    >
      <div className={CLASS_NAME}>
        <div className='lender-decision-top-row-wrapper'>
          <div className='lender-decision-items-wrapper'>
            {TOP_ROW_LABELS.map(name => (
              <p key={name} className='lender-decision-top-row-label'>{name}</p>
            ))}
          </div>
        </div>

        <div className='lender-decision-bottom-row-wrapper'>
          {lenderDecisionDetails?.map((decision) => (
            <LenderDecisionCard key={decision.id} decision={decision} />
          ))}
        </div>
      </div>

    </ModalFullscreen>
  )
}

export default LenderDecisionsModal
