import { type FocusEvent, type KeyboardEvent, type ChangeEvent, useRef } from 'react'
import { UI } from '@carfluent/common'
import { observer } from 'mobx-react-lite'
import { STATES_US, type StateItem } from 'utils/states_us'

import Input from 'components/common/Input'
import { onAfterAutocompleteAutofillInputFix } from 'utils/onAfterAutocompleteAutofillInputFix'
import { isKeyPrintable } from 'utils/keyCodeChecks'

const { Autocomplete } = UI

export interface AutocompleteStatesProps {
  onChange: (item: StateItem | null) => void
  onBlur?: (evt: FocusEvent<HTMLDivElement>) => void
  onFocus?: (evt: FocusEvent<HTMLDivElement>) => void
  value: string | null
  id?: string
  label?: string
  disabled?: boolean
  error?: boolean
}

const AutocompleteStates = observer((props: AutocompleteStatesProps) => {
  const { onChange, value, label, error, ...restProps } = props
  const refIsPasting = useRef(false)
  const refLastKeyPressed = useRef('')

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>): void => {
    refLastKeyPressed.current = e.key
  }

  const onInputChange = (e: ChangeEvent<{}>): void => {
    /**
     * we need to trigger fix only for autofill.
     * Current autofill detection based by event data is not reliable.
     * Some non printable keys like 'Backspace' or 'Delete' are also detected as autofill ->
     * which leads to the suggestions list forced closing.
     */

    if (refIsPasting.current || !isKeyPrintable(refLastKeyPressed.current)) {
      refIsPasting.current = false
      return
    }
    onAfterAutocompleteAutofillInputFix(
      e,
      (val: string) => {
        // we need to trigger onChange to update field form state
        const value = {
          name: STATES_US.find(s => s.abbreviation === val)?.name ?? '',
          abbreviation: val
        }
        onChange(value)
      }
    )
  }

  const selectedItem = STATES_US.find((item) => {
    return item.abbreviation === value
  }) ?? null

  return (
    <Autocomplete
      {...restProps}
      getOptionLabel={option => option.name}
      onKeyUp={onKeyUp}
      onInputChange={onInputChange}
      onPaste={(): void => { refIsPasting.current = true }}
      onChange={(_, newValue) => { onChange(newValue) }}
      options={STATES_US}
      renderInput={(params) => (
        <Input label={label} variant='filled' {...params} error={error} />
      )}
      value={selectedItem}
    />
  )
})

export default AutocompleteStates
