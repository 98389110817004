import { DURATION_IDS } from './constants'

export enum TIME_INTERVAL {
  HOUR = 60,
  DAY = 24
}

const transformMinutesToHours = (minutes: number): number => {
  return minutes / TIME_INTERVAL.HOUR
}

const transformMinutesToDays = (minutes: number): number => {
  return minutes / TIME_INTERVAL.HOUR / TIME_INTERVAL.DAY
}

const transformHoursToMinutes = (hours: number): number => {
  return hours * TIME_INTERVAL.HOUR
}

const transformDaysToMinutes = (days: number): number => {
  return days * TIME_INTERVAL.HOUR * TIME_INTERVAL.DAY
}

export const getDurationUnit = (minutes: number): DURATION_IDS => {
  if (transformMinutesToHours(minutes) % TIME_INTERVAL.DAY === 0) return DURATION_IDS.DAYS
  if (minutes % TIME_INTERVAL.HOUR === 0) return DURATION_IDS.HOURS

  return DURATION_IDS.MINUTES
}

export const transformMinutesByDuration = (duration: number, minutes: number, toMinutes: boolean = false): number => {
  if (duration === DURATION_IDS.DAYS) {
    return toMinutes ? transformMinutesToDays(minutes) : transformDaysToMinutes(minutes)
  }
  if (duration === DURATION_IDS.HOURS) {
    return toMinutes ? transformMinutesToHours(minutes) : transformHoursToMinutes(minutes)
  }

  return minutes
}
