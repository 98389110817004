/**
 * https://en.wikipedia.org/wiki/Compound_interest#Exact_formula_for_monthly_payment
 *
 * Show monthlyPayment w/o rounding and show only 2 decimal places.
 * Examples:
 *   Input: 275.4583333333333 - Result: 275.45
 *   Input: 3305.5 - Result: 3305.5
 *   Input: 734.5555555555555 - Result: 734.55
 *   Input: 100.16666666666667 - Result: 100.16
 *   Input: 1101.8333333333333 - Result: 1101.83
 */
const getMonthlyPayment = (amountFinanced: number, interestRate: number, financingTerm: number): number => {
  const P = amountFinanced
  const r = Number(interestRate) / 100 / 12
  const n = financingTerm ?? 0

  const value = n > 0
    ? `${interestRate ?? 0}` === '0'
      ? P / n
      : (P * r) / (1 - 1 / Math.pow(1 + r, n))
    : ''

  const amountInCents = Number(value) * 100
  const roundedAmountInCents = Math.floor(amountInCents)
  return roundedAmountInCents / 100
}

export default getMonthlyPayment
