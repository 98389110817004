import { css } from '@emotion/css'

export const POPOVER_CLASS_NAME = css(`
  padding: 16px 24px !important;
  width: 180px;
  z-index: 1000!important;
  
  .MuiFormControlLabel-root {
    margin-left: -9px;

    .cf-checkbox-icon {
      padding: 8px;
    }
  }

  .cf-filter-items {
    margin-top: 8px;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  
  .cf-left-custom-icon {
    span {
      display: inline-block;
      margin-left: 8px;
      color: rgba(0, 0, 0, 0.30);
    }
  }
`)

export default css(`
  .cf-action-bar {
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    background: white;
    
    .cf-filter-button {
      margin-left: 0px;

      &.cf-button-root {
        background: rgb(243, 243, 243);
        padding: 6px;
        border-radius: 8px;
        height: 32px;
        background: white;
        
        img {
          width: 10px;
          margin-left: 4px;
        }
        
        &.cf-button-variant-text {
          color: rgba(16, 16, 16);
          text-decoration: none;
          font-size: 14px;
        }
      }
    }
  }
`)
