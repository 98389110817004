import {
  dateField,
  dealApprovedRateField,
  dealApprovedTermField,
  dealLienholderField,
  dictionaryItemDropdownField,
  firstPaymentDate,
  requiredNonNegativeField,
  tradeInCreditField,
  requiredField,
  requiredNonZeroField
} from 'utils/validationPresets'

export default {
  'activeLenderDecision.approvedRate': dealApprovedRateField(),
  'activeLenderDecision.approvedTerm': dealApprovedTermField(),
  'activeLenderDecision.lienholder': dealLienholderField(),
  'dealFees.buyerTag': requiredNonNegativeField(),
  'dealFees.carDelivery': requiredNonNegativeField(),
  'dealFees.dealerHandlingFee': requiredNonNegativeField(),
  'dealFees.dealerInventoryTax': requiredNonNegativeField(),
  'dealFees.emissions': requiredNonNegativeField(),
  'dealFees.inspectionFee': requiredNonNegativeField(),
  'dealFees.plateTransferFee': requiredNonNegativeField(),
  'dealFees.registrationFee': requiredNonNegativeField(),
  'dealFees.titleFee': requiredNonNegativeField(),
  'dealFees.others.[*].description': requiredField,
  'dealFees.others.[*].amount': requiredNonZeroField(),
  'tradeInDetails.credit': tradeInCreditField(),
  firstPaymentDate: firstPaymentDate(true),
  saleDate: dateField(true),
  salesperson: dictionaryItemDropdownField(true),
  'vehicle.listPrice': requiredNonNegativeField()
}
