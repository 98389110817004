export function bodyLogin (username: string, password: string): string {
  return `grant_type=password&client_id=spa_ropf&client_secret=carFluentSPA&scope=api offline_access&username=${username}&password=${password}`
}

export function changeDealerRequestToken (newDealerId: string | number, accessToken: string): string {
  return `grant_type=dealer_id&client_id=spa_ropf&client_secret=carFluentSPA&scope=api offline_access&authToken=${accessToken}&dealerId=${newDealerId}`
}

/**
 * For more details check
 * https://identityserver4.readthedocs.io/en/latest/topics/refresh_tokens.html#requesting-an-access-token-using-a-refresh-token
 */
export function bodyRefresh (refreshToken: string): string {
  return `grant_type=refresh_token&client_id=spa_ropf&client_secret=carFluentSPA&refresh_token=${refreshToken}`
}
