import { type FC } from 'react'
import { cnx, FormInput, Banner } from '@carfluent/common'

import RichEditor from 'components/common/RichEditor'

import ActionsFooterAndUnsavedChanges from '../ActionsFooterAndUnsavedChanges'
import EmailRowPreview from '../EmailRowPreview'
import MailSkeleton from '../MailSkeleton'
import useStepCampaignContent from './hook'
import { type UseStepCampaignContentProps } from './hook/types'
import CLASS_NAME from './styles'

interface StepCampaignContentProps extends UseStepCampaignContentProps {
  className?: string
  canDeleteCampaign: boolean
  onDeleteCampaign: () => void
}

const StepCampaignContent: FC<StepCampaignContentProps> = ({
  className,
  canDeleteCampaign,
  onDeleteCampaign,
  ...hookProps
}) => {
  const {
    errors,
    onBlur,
    onChange,
    touched,
    values,
    globalError,
    onCancel,
    onGoNext,
    unsavedChangesProps
  } = useStepCampaignContent(hookProps)

  return (
    <>
      <Banner
        isOpen={globalError}
        message='Email body cannot be empty'
        type='error'
      />

      <div className={cnx(CLASS_NAME, 'campaign-form', className)}>
        <div className='container'>
          <div className='inputs-wrapper'>
            <FormInput
              dataTestId='input-name'
              error={errors.name}
              id='name'
              label='Campaign name'
              onChange={onChange}
              onBlur={onBlur}
              touched={touched.name}
              value={values.name}
            />

            <FormInput
              dataTestId='input-senderName'
              error={errors.senderName}
              id='senderName'
              label='Sender name'
              onChange={onChange}
              onBlur={onBlur}
              touched={touched.senderName}
              value={values.senderName}
            />
          </div>

          <div className='letter-preview'>
            <MailSkeleton />
            <EmailRowPreview {...values} />
            <MailSkeleton />
            <MailSkeleton />
          </div>
        </div>

        <FormInput
          dataTestId='input-emailSubject'
          error={errors.emailSubject}
          id='emailSubject'
          label='Email subject'
          onChange={onChange}
          onBlur={onBlur}
          touched={touched.emailSubject}
          value={values.emailSubject}
        />

        <div className='input-container'>
          <FormInput
            dataTestId='input-preHeader'
            error={errors.preHeader}
            id='preHeader'
            label='Preheader (optional)'
            onChange={onChange}
            onBlur={onBlur}
            touched={touched.preHeader}
            value={values.preHeader}
          />

          {errors.preHeader === null && (
            <div className='label'>
              Short text that follows the subject line of your emails
            </div>
          )}
        </div>

        <RichEditor
          className='campaign-content-editor'
          id='body'
          onBlur={onBlur}
          onChange={onChange}
          value={values.body}
        />
      </div>

      <ActionsFooterAndUnsavedChanges
        isDeleteShown={canDeleteCampaign}
        onClose={onCancel}
        onDelete={onDeleteCampaign}
        onSubmit={onGoNext}
        unsavedChangesProps={unsavedChangesProps}
      />
    </>
  )
}

export default StepCampaignContent
