import type { FC } from 'react'
import { Modal } from '@carfluent/common'

import DeleteEntityButton from 'components/dialogs/DeleteEntityButton'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import DealerAccountDetailsForm from './DealerAccountDetailsForm'
import type { DealerAccountDetailsModalProps } from './hook/types'
import useDealerAccountDetailsForm from './hook'

import CLASS_NAME from './styles'

const TITLE = 'Edit user'
const SUBMIT_BTN_TEXT = 'Save'
const DEFAULT_MIN_WIDTH = 680

const DealerAccountDetailsModal: FC<DealerAccountDetailsModalProps> = (props) => {
  const {
    isOpen,
    isLoading,
    dealers,
    selectedDealers,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onDealerSelect,
    onRoleChange,
    onSubmit,
    onDeleteDealerAccount,
    onClose
  } = useDealerAccountDetailsForm(props)

  const renderFooter = (): JSX.Element => {
    const renderDeleteButton = (): JSX.Element => (
      <DeleteEntityButton
        onConfirm={onDeleteDealerAccount}
        submitTitle='REMOVE'
        titleButton='REMOVE USER'
        titleWindow='Remove user?'
        titleContent={
          <div>
            <p>It will revoke access to the dealership for this user.</p>
            <p>All assigned tasks will remain assigned to this user.</p>
          </div>
        }
      />
    )

    return (
      <CancelSubmitActionsFooter
        onSubmit={onSubmit}
        isLoading={isLoading}
        onClose={onClose}
        submitTitle={SUBMIT_BTN_TEXT}
        renderDeleteButton={renderDeleteButton}
      />
    )
  }

  return (
    <Modal
      keepContentMounted
      className={CLASS_NAME}
      isOpen={isOpen}
      minWidth={DEFAULT_MIN_WIDTH}
      dataTestId='dealer-account-popup-update'
      onClose={onClose}
      renderFooterActions={renderFooter}
      title={TITLE}
    >
      <DealerAccountDetailsForm
        dealers={dealers}
        selectedDealers={selectedDealers}
        values={values}
        errors={errors}
        touched={touched}
        onChange={onChange}
        onBlur={onBlur}
        onDealerSelect={onDealerSelect}
        onRoleChange={onRoleChange}
      />
    </Modal>
  )
}

export default DealerAccountDetailsModal
