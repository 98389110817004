import { type FC } from 'react'
import { type IconProps } from 'types'

const IconSVGDownload: FC<IconProps> = ({ color = '#212121', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${height} ${width}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_66469_17235)'>
        <path d='M12.0003 10.0013V12.0013H4.00033V10.0013H2.66699V12.0013C2.66699 12.7346 3.26699 13.3346 4.00033 13.3346H12.0003C12.7337 13.3346 13.3337 12.7346 13.3337 12.0013V10.0013H12.0003ZM11.3337 7.33464L10.3937 6.39464L8.66699 8.11463V2.66797H7.33366V8.11463L5.60699 6.39464L4.66699 7.33464L8.00033 10.668L11.3337 7.33464Z' fill={color} fillOpacity='0.8' />
      </g>

      <defs>
        <clipPath id='clip0_66469_17235'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGDownload
