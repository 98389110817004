import { type FC, useContext, useState, createContext } from 'react'
import { Outlet, useLocation, matchPath } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { cnx, noop } from '@carfluent/common'

import { UserRoles } from 'api/types'
import { WORK_SCHEDULE_MODAL_CONSTRAINT_ID } from 'constants/constants'
import { Routes, CRMSettingsSubRoutes } from 'constants/route_helper'
import AuthCTX from 'store/auth'
import { asRoute } from 'utils/route_helper'
import PageSubmenu from 'components/layout/PageSubmenu'
import PageOverlaySettings from 'components/PageOverlay/PageOverlaySettings'
import CLASS_NAME from './styles'

enum LinksNames {
  GeneralSettings = 'General',
  EmailTemplates = 'Email templates',
  MessageTemplates = 'Message templates',
  Rules = 'Workplan',
  WorkSchedule = 'Work schedule',
  WebsiteChat = 'Website chat'
}

export const SubmenuVisibilityCTX =
  createContext<(value: boolean) => void>(noop)

const CRMSettings: FC = () => {
  const location = useLocation()
  const routeMatch = matchPath(Routes.CRMMarketingCampaignDetails, location.pathname)
  const isCampaignViewScreen = (routeMatch !== null) && (routeMatch?.params?.id !== 'new')

  const { userRoles } = useContext(AuthCTX)
  const isBDCUser = userRoles.includes(UserRoles.BDC)
  const LINKS = [
    { name: LinksNames.GeneralSettings, url: asRoute(CRMSettingsSubRoutes.GeneralSettings), hidden: isBDCUser },
    { name: LinksNames.EmailTemplates, url: asRoute(CRMSettingsSubRoutes.EmailTemplates) },
    { name: LinksNames.MessageTemplates, url: asRoute(CRMSettingsSubRoutes.MessageTemplates) },
    { name: LinksNames.Rules, url: asRoute(CRMSettingsSubRoutes.Rules), hidden: isBDCUser },
    { name: LinksNames.WorkSchedule, url: asRoute(CRMSettingsSubRoutes.WorkSchedule) },
    { name: LinksNames.WebsiteChat, url: asRoute(CRMSettingsSubRoutes.WebsiteChat), hidden: isBDCUser }
  ]

  const [isSubmenuVisible, setIsSubmenuVisible] = useState(true)

  // ========================================== //

  return (
    <SubmenuVisibilityCTX.Provider value={setIsSubmenuVisible}>
      <PageOverlaySettings isSubmenuVisible={isSubmenuVisible}>
        <div className={cnx(CLASS_NAME, isSubmenuVisible && 'g-content-layout g-overrides')}>
          <div id={WORK_SCHEDULE_MODAL_CONSTRAINT_ID} className='g-settings-body-wrapper'>
            {isSubmenuVisible && (
              <PageSubmenu
                basePath={Routes.CRMSettings}
                links={LINKS}
                isWithoutFooter={isCampaignViewScreen}
              />
            )}

            <Outlet />
          </div>
        </div>
      </PageOverlaySettings>
    </SubmenuVisibilityCTX.Provider>
  )
}

export default observer(CRMSettings)
