import { type ReactNode, type FC, Fragment, useMemo } from 'react'
import {
  FormDatePicker,
  FormDropdown,
  FormInput,
  FormMaskedInput,
  FormNumberInput
} from '@carfluent/common'

import { VehicleState, type DuplicateLeadDto, type WorkflowTypeDto, PaymentTypeId } from 'api/types'
import { EIN_MASK } from 'constants/constants'
import { ValidationLength } from 'constants/validation'
import { LEAD_STATUS_TEXT } from 'constants/names'
import { formatPhoneNumberForView } from 'utils/view_helper'
import { type VehicleLike } from 'utils/vehicleDropdownHelpers'
import optional from 'utils/common/optionalLabel'
import { isBusinessType, isPersonalType, isWholesaleType } from 'utils/deals/workflowTypes'

import ModalFullscreen from 'components/dialogs/ModalFullscreen'
import AddressFields from 'components/common/AddressFields'
import ErrorsBlock from 'components/common/ErrorsBlock'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import { renderNoVehiclesOption, renderVehicleOption } from 'components/optionRenderers/vehiclesDropdown'
import IconSVG from 'components/inlineImages'

import { getDefaultNewDealFormValues, ADDRESS_OPTIONALITY_LABELS } from './hook/constants'
import { type VehicleOption, type UseAddNewDealProps } from './hook/types'
import useAddNewDeal, { PAYMENT_NAME_MAPPER, PAYMENT_OPTIONS } from './hook'
import { CLASS_NAME, CUSTOMER_DROPDOWN_POPOVER_CLASS_NAME, DROPDOWN_POPOVER_CLASS_NAME } from './styles'

export interface NewDealModalProps extends UseAddNewDealProps {
  workflowTypes?: WorkflowTypeDto[]
}

const NewDealModal: FC<NewDealModalProps> = (props) => {
  const {
    apiErrors,
    isFormSubmitting,
    users,
    sources,
    isAddNewCustomer,
    isDisabledPriceOrMileageInput,
    isVehicleDropdownSearchHasValue,
    isCustomerDropdownSearchHasValue,
    isDealCreationFromLead,
    values,
    errors,
    touched,
    onBlur,
    onClose,
    onSubmit,
    onChange,

    vehicleValue,
    onWorkflowTypeChange,
    onAddCustomer,
    getVehicles,
    getCustomers,
    onCustomerChange,
    onCustomerBlur,
    onVehicleChange,
    onVehicleBlur
  } = useAddNewDeal(props)

  const renderFooter = (): JSX.Element => {
    return (
      <CancelSubmitActionsFooter
        isLoading={isFormSubmitting}
        onSubmit={onSubmit}
        onClose={onClose}
        submitTitle='CREATE DEAL'
      />
    )
  }

  const isPersonal = isPersonalType(values.workflowType?.id)
  const isWholesale = isWholesaleType(values.workflowType?.id)
  const isBusiness = isBusinessType(values.workflowType?.id) || isWholesale
  const workflowTypesOptions = props?.workflowTypes ?? [DEFAULT_CREATE_NEW_DEAL?.workflowType as WorkflowTypeDto] ?? []

  const customerDropdownAction = useMemo(() => {
    if (isDealCreationFromLead) {
      return []
    }

    return [{ id: '+ Add new customer', onClick: onAddCustomer }]
  }, [isDealCreationFromLead])

  if (!props.isOpen) {
    return null
  }

  return (
    <ModalFullscreen
      className={CLASS_NAME}
      isOpen={props.isOpen}
      onClose={onClose}
      renderFooterActions={renderFooter}
      title='New deal'
    >
      {(apiErrors != null) && (
        <ErrorsBlock errors={apiErrors} className='new-deal-api-errors' />
      )}

      <div className='new-deal-row'>
        <FormDropdown
          id='workflowType'
          label='Deal type'
          className='double-col'
          blurMode='select-first'
          disabled={isDealCreationFromLead}
          options={workflowTypesOptions}
          renderOption={renderWorkflowTypeOption}
          value={values.workflowType}
          error={errors.workflowType}
          touched={touched.workflowType}
          onChange={onWorkflowTypeChange}
          onBlur={onBlur}
          dataTestId='deal-details-workflow-type'
        />
      </div>

      <p className='new-deal-title customer-title'>Customer</p>

      <div className='new-deal-row'>
        {isBusiness && (
          <FormInput
            id='businessDetails.businessName'
            label='Business name'
            className='double-col'
            onChange={onChange}
            onBlur={onBlur}
            value={values.businessDetails.businessName}
            error={errors.businessDetails.businessName}
            touched={touched.businessDetails.businessName}
            dataTestId='business-details-name'
          />
        )}

        {isPersonal && (
          <FormDropdown<DuplicateLeadDto>
            id='customer'
            placeholder='Search customer by name, phone number, email'
            mode='search'
            blurMode='clear'
            className='double-col customer-input'
            value={values.customer}
            fetchHandler={getCustomers}
            error={errors.customer}
            touched={Boolean(touched.customer)}
            disabled={isDealCreationFromLead}
            onChange={onCustomerChange}
            onBlur={onCustomerBlur}
            actions={customerDropdownAction}
            renderOption={renderCustomerOption}
            renderAction={renderAddNewCustomer}
            formatDisplayValue={formatCustomerDisplayName}
            startAdornment={<span> <IconSVG.SearchIcon /> </span>}
            helper={isCustomerDropdownSearchHasValue && !isAddNewCustomer && 'Existing customer selected'}
            popoverClassName={CUSTOMER_DROPDOWN_POPOVER_CLASS_NAME}
          />
        )}
      </div>

      {(!isPersonal || isCustomerDropdownSearchHasValue || isAddNewCustomer) && (
        <div className='new-deal-row'>
          <FormInput
            id='customerFirstName'
            label={isBusiness ? optional('Rep. First name') : 'First name'}
            onChange={onChange}
            onBlur={onBlur}
            value={values.customerFirstName}
            error={errors.customerFirstName}
            touched={touched.customerFirstName}
            dataTestId='customer-details-customer-first-name'
          />

          <FormInput
            id='customerLastName'
            label={isBusiness ? optional('Rep. Last name') : 'Last name'}
            onChange={onChange}
            onBlur={onBlur}
            value={values.customerLastName}
            error={errors.customerLastName}
            touched={touched.customerLastName}
            dataTestId='customer-details-customer-last-name'
          />

          <FormInput
            id='customerEmail'
            label={optional('Email')}
            onChange={onChange}
            onBlur={onBlur}
            value={values.customerEmail}
            error={errors.customerEmail}
            touched={touched.customerEmail}
            dataTestId='customer-details-customer-email'
            className='resized-double-col'
          />

          <FormMaskedInput
            id='customerPhoneNumber'
            label='Phone number'
            mask='phone'
            onChange={onChange}
            onBlur={onBlur}
            value={values.customerPhoneNumber}
            error={errors.customerPhoneNumber}
            touched={touched.customerPhoneNumber}
          />

          <FormDatePicker
            id='customerBirthDate'
            label={optional('Date of birth')}
            value={values.customerBirthDate}
            error={errors.customerBirthDate}
            touched={touched.customerBirthDate}
            onChange={onChange}
            onBlur={onBlur}
          />
        </div>
      )}

      <AddressFields
        values={values.addressData}
        errors={errors.addressData}
        touched={touched.addressData}
        onChange={onChange}
        onBlur={onBlur}
        optionals={ADDRESS_OPTIONALITY_LABELS}
        useNormalAptKey
      >
        <FormDropdown
          id='paymentType'
          label='Payment'
          blurMode='select-first'
          options={PAYMENT_OPTIONS}
          renderOption={(option) => renderPaymentOption(option.id)}
          disabled={isWholesale}
          value={values.paymentType}
          error={errors.paymentType}
          touched={touched.paymentType}
          onChange={onChange}
          onBlur={onBlur}
        />

        {isBusiness && (
          <FormMaskedInput
            id='businessDetails.ein'
            label={optional('EIN')}
            mask={EIN_MASK}
            onChange={onChange}
            onBlur={onBlur}
            value={values.businessDetails.ein}
            error={errors.businessDetails.ein}
            touched={touched.businessDetails.ein}
          />
        )}

        <FormDropdown
          id='salesperson'
          label='Salesperson'
          blurMode='select-first'
          options={users}
          value={values.salesperson}
          error={errors.salesperson}
          touched={touched.salesperson}
          onChange={onChange}
          onBlur={onBlur}
        />

        {isPersonal && (
          <FormDropdown
            id='source'
            label='Source'
            options={sources}
            onChange={onChange}
            onBlur={onBlur}
            blurMode='select-first'
            value={values.source}
            error={errors.source}
            touched={touched.source}
            disabled={isDealCreationFromLead || (isCustomerDropdownSearchHasValue && !isAddNewCustomer)}
          />
        )}
      </AddressFields>

      <div className='new-deal-car-info-block'>
        <p className='new-deal-title'>Car</p>

        <div className='new-deal-row car-info'>
          <FormDropdown<VehicleOption>
            id='vehicle.vin'
            className='double-col'
            placeholder='Add car by VIN, Stock #, Make, Model'
            startAdornment={<span> <IconSVG.SearchIcon /> </span>}
            onChange={onVehicleChange}
            blurMode='clear'
            disableClearable={!isVehicleDropdownSearchHasValue}
            value={vehicleValue as VehicleOption}
            fetchHandler={getVehicles}
            onBlur={onVehicleBlur}
            formatDisplayValue={formatVehicleDisplayName}
            renderNoOptions={renderNoVehiclesOption}
            isOptionDisabled={isOptionDisabled}
            renderOption={renderVehicleOption}
            popoverClassName={DROPDOWN_POPOVER_CLASS_NAME}
            error={errors.vehicle?.vin}
            touched={touched.vehicle?.vin}
          />

          <FormNumberInput
            id='vehicle.salePrice'
            label='Sale price'
            preset='price'
            isEmptyAllowed
            decimalPrecision={0}
            value={values.vehicle?.salePrice}
            error={errors.vehicle?.salePrice}
            touched={touched.vehicle?.salePrice}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isDisabledPriceOrMileageInput}
          />

          <FormNumberInput
            id='vehicle.mileage'
            label='Actual mileage'
            value={values.vehicle?.mileage}
            error={errors.vehicle?.mileage}
            touched={touched.vehicle?.mileage}
            onChange={onChange}
            onBlur={onBlur}
            maxLength={ValidationLength.MILEAGE_MAX}
            disabled={isDisabledPriceOrMileageInput}
          />
        </div>

        {vehicleValue.make != null && (
          <div className='car-info-banner'>
            <div>
              <span>Car:</span> {vehicleValue.year} {vehicleValue.make} {vehicleValue.model} {vehicleValue.trim}
            </div>
            <div>
              <span>VIN:</span> {vehicleValue.vin ?? '-'}
            </div>
            <div>
              <span>Stock #:</span> {vehicleValue.stock ?? '-'}
            </div>
          </div>
        )}
      </div>
    </ModalFullscreen>
  )
}

export default NewDealModal

const renderAddNewCustomer = (): ReactNode => {
  return (
    <div className='option-render no-options'>
      <span>
        <IconSVG.Add />
        Add new customer
      </span>
    </div>
  )
}

const renderCustomerOption = (opt: DuplicateLeadDto): ReactNode => {
  const { carOfInterest } = opt
  const leadStatus = LEAD_STATUS_TEXT[opt.leadStatusId as keyof typeof LEAD_STATUS_TEXT]

  const name = `${opt.firstName ?? ''} ${opt.lastName ?? ''}`

  const carInfo = `${carOfInterest?.year ?? ''} ${carOfInterest?.make ?? ''} ${carOfInterest?.model ?? ''}`.trim()
  const phone = formatPhoneNumberForView(opt.phoneNumber)
  const customerData = [name, phone ?? '', opt.email ?? ''].filter(Boolean)
  const customerDataSecondLine = [leadStatus, carInfo].filter(Boolean)

  return (
    <div className='option-render'>
      <div className='data-line'>{renderDataLine(customerData)}</div>
      <div className='data-line'>{renderDataLine(customerDataSecondLine, true)}</div>
    </div>
  )
}

const renderDataLine = (data: string[], isLastLine = false): ReactNode => (
  <>
    {data.map((el, i) => (
      <Fragment key={el}>
        <span className='option-data-container'>{el}</span>
        {i !== data.length - 1 && <span className='option-data-divider' />}
      </Fragment>
    ))}
    {!isLastLine && <div className='line-divider' />}
  </>
)

const formatCustomerDisplayName = (customer: DuplicateLeadDto | null): string => {
  if (customer == null) {
    return ''
  }

  return `${customer?.firstName ?? ''} ${customer?.lastName ?? ''}`
}

// ========================================== //

const renderPaymentOption = (option: PaymentTypeId): string => {
  return PAYMENT_NAME_MAPPER[option]
}

const renderWorkflowTypeOption = (option: WorkflowTypeDto): string => {
  return option.name
}
interface DropDownOptionItem {
  id: string
  value: VehicleOption
}

const isOptionDisabled = (option: DropDownOptionItem): boolean =>
  option.value.vehicleState === VehicleState.Sold ||
  option.value.vehicleState === VehicleState.Deleted

const DEFAULT_CREATE_NEW_DEAL = getDefaultNewDealFormValues()

export const formatVehicleDisplayName = (vehicle: VehicleLike | null): string => {
  if (vehicle == null) {
    return ''
  }

  if (vehicle.stock == null && vehicle.vin != null) {
    return vehicle.vin
  }

  return vehicle.stock == null ? '' : `${vehicle.stock ?? ''} - ${vehicle.year ?? ''} ${vehicle.make ?? ''} ${vehicle.model ?? ''}`
}
