import { NodeEnvironment } from 'constants/names'

export interface BuildInfo {
  isSentryUsed: boolean
  releaseName: string
}

const getBuildInfo = (): BuildInfo => {
  const LOCAL_URLS = ['localhost', '127.0.0.1', '0.0.0.0']
  const isOnLocalMachine = LOCAL_URLS.includes(window.location.host)
  const isProductionBuild = process.env.NODE_ENV === NodeEnvironment.Production
  const releaseName = process.env.REACT_APP_COMMIT ?? ''
  const hasReleaseName = Boolean(releaseName)

  return {
    releaseName,
    isSentryUsed: isProductionBuild && hasReleaseName && !isOnLocalMachine
  }
}

export default getBuildInfo
