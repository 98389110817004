import { type FC, type ReactNode } from 'react'
import { cnx } from '@carfluent/common'

import FilterTabsSection from './components/FilterTabsSection'
import FilterSection from './components/FilterSection'
import SearchInput from '../SearchInput'

import { isStringEmpty } from 'utils/parse_string'

import type { FiltersPanelProps } from './types'
import CLASS_NAME from './styles'

const FiltersPanel: FC<FiltersPanelProps> = ({
  className,
  title,
  searchProps,
  tabProps,
  filterProps,
  componentBackground = 'white',
  renderActions
}) => {
  const withTitle = !isStringEmpty(title)
  const withLeftContent = tabProps != null || filterProps != null || withTitle

  const renderTabsAndFilters = (): ReactNode => {
    return (
      <>
        {
          tabProps != null && (
            <div className='g-filters-panel-tabs'>
              {tabProps.render != null
                ? tabProps.render()
                : <FilterTabsSection {...tabProps} />}
            </div>
          )
        }
        {
          filterProps != null && (
            <div className='g-filters-panel-filters'>
              {filterProps.render != null
                ? filterProps.render()
                : <FilterSection
                    filters={filterProps.filters}
                    filterBackground={componentBackground}
                  />}
            </div>
          )
        }
      </>
    )
  }

  return (
    <section className={cnx(CLASS_NAME, className, 'g-filters-panel')}>
      {
        withLeftContent && (
          <div className={cnx('g-filters-panel-left-part', withTitle && 'with-title')}>
            {
              !isStringEmpty(title) && (
                <div className='g-filters-panel-title'>
                  <h2>
                    {title}
                  </h2>
                </div>
              )
            }
            {renderTabsAndFilters()}
          </div>
        )
      }

      <div className={cnx('g-filters-panel-right-part', !withLeftContent && 'g-panel-right-full-width')}>
        {
          searchProps != null && (
            <div className={cnx('g-filters-panel-search', componentBackground ?? '')}>
              <SearchInput {...searchProps} />
            </div>
          )
        }

        {
          renderActions != null && (
            <div className='g-filters-panel-actions'>
              {renderActions()}
            </div>
          )
        }
      </div>
    </section>
  )
}

export default FiltersPanel
