import { css } from '@emotion/css'

const createStyleClass = (): string => {
  return css(`
    & {
      .cf-trial-balance-table {
        border-collapse: collapse;
        margin-top: 24px;
        width: 100%;
        table-layout: fixed;
      }

      thead {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      }

      /* needed for correct pdf table creation */
      tbody {
        border: 1px solid #fff;
      }
      
      tr { min-height: 64px; }
      tr:hover { background: #FAFAFA; }
      thead tr:hover { background: #FFFFFF; }

      td, th {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.15px;
        color: #101010;
        text-align: left;
        padding: 8px 0 8px 16px;
        vertical-align: middle;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 64px;
      }

      th {
        font-weight: 600;
        padding: 12px 0 12px 16px;
      }

      td:last-child, th:last-child {
        padding-right: 16px;
      }
      
      .cf-account-cell {
        max-width: 100px;
        min-width: 100px;
        width: 100px;
      }

      .cf-name-cell {
        max-width: 560px;
        min-width: 560px;
        width: 560px;
      }

      .cf-money-cell {
        text-align: right;
        max-width: 160px;
        min-width: 160px;
        width: 160px;
      }

      .cf-row-summary {
        border-top: 1px solid rgba(0, 0, 0, 0.3);
        background: #FFFFFF;
        &:hover { background: #FFFFFF; }

        td {
          padding: 20px 0 20px 16px;
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
    
    .cf-top-wrapper {
      margin-bottom: 24px;
    }
  `)
}

export default createStyleClass
