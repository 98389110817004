import type { FC } from 'react'

import type { DictionaryItem } from 'api/types'
import CLASS_NAME from './styles'

export interface AddRuleHeaderCellProps {
  onClick: (leadStatus: DictionaryItem) => void
  leadStatus: DictionaryItem
}

const AddRuleHeaderCell: FC<AddRuleHeaderCellProps> = ({ onClick: _onClick, leadStatus }) => {
  const onClick = (): void => {
    _onClick(leadStatus)
  }

  return (
    <p className={CLASS_NAME} onClick={onClick}>Add event</p>
  )
}

export default AddRuleHeaderCell
