import { css } from '@emotion/css'

export default css(`
  .skeleton-row {
    height: 56px;
    display: flex;
    background: #fff;
    margin-bottom: 8px;

    > div {
      :first-of-type {
        width: 32px;
        height: 56px;
        padding: 20px 8px
      }

      :last-of-type {
        height: 56px;
        width: 367px;
        padding: 20px 8px
      }
    }
  }
`)
