import { css } from '@emotion/css'

export default css(`
  .logo-title {
    margin-bottom: var(--content-margin-small);
  }

  .where-displayed {
    &.hidden { visibility: hidden; }

    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 8px;

    p {
      line-height: 16px;
      letter-spacing: 0.4px;
      text-decoration-line: underline;
      color: #101010;
      cursor: pointer;
      font-family: Roboto, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .content > div {
    display: flex;
    align-items: flex-start;
  }

  .cf-dropzone {
    height: 110px;
    width: 180px;
    margin-bottom: 8px;
  }

  .logo-skeleton {
    width: 180px;
    height: 110px;

    span {
      width: 100%;
      height: 100%;
    }
  }

  .logo-preview {
    background-color: #000;
    border-radius: 8px;
    width: 180px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    img {
      width: 162px;
      height: 60%;
      object-fit: contain;
    }
  }

  .cf-dropzone-block-button {
    height: 48px;
    width: 132px;
    flex-direction: column;

    .cf-dropzone-btn-upload {
      margin-left: 0;
      margin-top: 8px;
      text-wrap: nowrap;
    }
  }

  .input-file {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .drag-title {
    font-size: 16px;
    line-height: 24px;
  }

  .root-btn {
    text-decoration-line: underline;
    background: none;
    font-size: 12px;
    line-height: 16px;
    width: 45px;
    padding: 0;

    > span {
      text-transform: capitalize;
      font-family: Roboto, sans-serif;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
    }

    :hover { background: #fff; }
  }


  .restriction-list {
    display: flex;
    width: 180px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    color: #212121CC;

    p {
      font-family: Roboto, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
  }

  .logo-actions {
    display: flex;
    justify-content: center;
    align-items: center;

    button:first-of-type {
      margin-right: 48px;
    }
  }

  .logo {
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }
`)
