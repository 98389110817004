import { css } from '@emotion/css'

export default css(`
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    
    .cf-button-root.cf-button-variant-text {
      color: #458FFF;
      text-decoration: none;
      margin-left: 8px;
    }
  }
`)
