import { type ReactNode, type FC, useMemo } from 'react'
import { cnx } from '@carfluent/common'

import OverlayedPageHOC from 'components/PageOverlay/OverlayedPageHOC'
import BackToTop from 'components/common/BackToTop'
import ImportCSVModal from 'components/dialogs/ImportCsvModal'
import Table from 'components/common/Table'
import ActionsMenu from 'components/common/ActionsMenu'
import FiltersPanel, { FilterProps } from 'components/common/FiltersPanel'

import StatusFilter from './components/StatusFilter'
import AddNewVehicleModal from '../NewVehicle'

import GroupRow from './components/GroupRow'
import useVehicles from './hook'

import { CLASS_NAME } from './styles'

const Vehicles: FC = () => {
  const {
    isLoading,
    columns,
    rows,
    search,
    sorting,
    counters,
    isFiltersChange,
    isModalOpen,
    topPanelActions,
    emptyTableMessage,
    isOpenCSVModal,
    handleOpenVehicle,
    onStatusFilterChange,
    onSearchChange,
    groupBy,
    onSearch,
    onCloseModal,
    onSortingChange,
    onBottomReached,
    onToggleCsvModal,
    loadRows,
    onDepositCheckboxChange
  } = useVehicles()

  const filterProps: FilterProps = useMemo(() => {
    return {
      filters: [{
        filterType: 'custom',
        componentProps: {
          render: () => {
            return (
              <>
                <StatusFilter
                  isLoading={isFiltersChange}
                  counters={counters}
                  onChange={onStatusFilterChange}
                />

              </>
            )
          }
        }
      }, {
        filterType: 'checkbox',
        componentProps: {
          label: 'On deposit',
          defaultValue: false,
          onChange: onDepositCheckboxChange
        }
      }]
    }
  }, [counters, isFiltersChange, onDepositCheckboxChange, onStatusFilterChange])

  return (
    <div className={cnx(CLASS_NAME, isLoading && 'loading')}>
      <FiltersPanel
        searchProps={{
          isLoading,
          disabled: isLoading,
          onChange: onSearchChange,
          value: search,
          withDeleteIcon: true,
          onSearch,
          placeholder: 'VIN, make, stock #'
        }}
        filterProps={filterProps}
        renderActions={(): ReactNode => (
          <ActionsMenu className='actions-menu' actions={topPanelActions} />
        )}
      />

      <Table
        columns={columns}
        data={rows}
        isLoading={isLoading}
        groupBy={groupBy}
        emptyTableMessage={emptyTableMessage}
        onSortingChange={onSortingChange}
        onBottomReached={onBottomReached}
        onRowClick={handleOpenVehicle}
        renderGroup={GroupRow}
        sorting={sorting}
        headerDataTestId='vehicle-tasks-table-header'
        bodyDataTestId='vehicle-tasks-table-body'
      />

      <ImportCSVModal
        isOpen={isOpenCSVModal}
        toggleModal={onToggleCsvModal}
        onSubmit={async () => await loadRows()}
      />

      <BackToTop />
      <AddNewVehicleModal
        isOpen={isModalOpen}
        onClose={onCloseModal}
      />
    </div>
  )
}

export default OverlayedPageHOC(Vehicles)
