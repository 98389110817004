import type { FC } from 'react'
import type { IconProps } from 'types'

export const TireWheel: FC<IconProps> = ({ color = '#458FFF', width = 29, height = 28 }) => (
  <svg width={width} height={height} viewBox='0 0 29 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_87357_160352)'>
      <path d='M9.81094 0C9.81094 9.30234 9.81094 18.6047 9.81094 27.9234C9.26953 27.9234 8.73906 27.9234 8.17578 27.9234C8.17578 18.6156 8.17578 9.30781 8.17578 0C8.71719 0 9.26406 0 9.81094 0Z' fill={color} />
      <path d='M12.7617 0C12.9805 0.0546875 13.1992 0.103906 13.4125 0.164062C15.1078 0.650781 16.3383 2.23672 16.3547 3.97031C16.3547 4.07969 16.3055 4.23281 16.2234 4.2875C14.6758 5.34844 13.1172 6.40391 11.5586 7.45391C11.5367 7.47031 11.5094 7.47031 11.4492 7.49766C11.4492 4.9875 11.4492 2.49375 11.4492 0C11.8867 0 12.3242 0 12.7617 0Z' fill={color} />
      <path d='M6.53634 0C6.53634 2.48828 6.53634 4.97656 6.53634 7.49766C6.46525 7.45938 6.40509 7.44297 6.36134 7.41016C4.84103 6.38203 3.32619 5.34844 1.80587 4.32578C1.66916 4.23281 1.61994 4.13438 1.62541 3.97031C1.65275 2.07266 3.12384 0.377344 5.0215 0.0546875C5.09259 0.04375 5.15822 0.0164063 5.22384 0C5.66134 0 6.09884 0 6.53634 0Z' fill={color} />
      <path d='M16.5664 27.6695C16.818 27.2867 17.0805 26.9312 17.2992 26.5484C17.7641 25.7336 17.9828 24.8531 17.9937 23.918C17.9992 23.3711 18.0047 22.8242 17.9883 22.2828C17.9828 22.0805 18.0539 21.9875 18.2289 21.8891C20.6734 20.4781 22.2156 18.3945 22.7461 15.6164C23.4734 11.832 21.6852 8.04766 18.3164 6.17188C18.0703 6.03516 17.9828 5.89297 17.9883 5.60859C17.9992 4.89219 18.0047 4.17031 17.9445 3.45391C17.857 2.38203 17.425 1.44141 16.7687 0.59375C16.6977 0.500781 16.6266 0.407812 16.5117 0.265625C18.4094 0.626563 20.0938 1.32656 21.6523 2.36563C23.2109 3.40469 24.4797 4.72813 25.4969 6.34141C24.5781 6.87187 23.6594 7.40234 22.7133 7.95469C22.9867 8.425 23.2492 8.88437 23.5281 9.37109C24.4633 8.83516 25.382 8.30469 26.3281 7.75781C28.3297 11.9359 28.3023 16.0867 26.3336 20.2703C25.3875 19.7234 24.4687 19.1984 23.5281 18.657C23.2547 19.1328 22.9867 19.5922 22.7133 20.0734C23.6484 20.6148 24.5672 21.1453 25.4805 21.6758C24.0805 24.3664 19.3828 27.5273 16.5664 27.6695Z' fill={color} />
      <path d='M11.4467 27.9437C11.4467 26.9977 11.4467 26.0898 11.4467 25.182C11.4467 24.4109 11.4412 23.6398 11.4521 22.8633C11.4521 22.7648 11.5014 22.6227 11.5779 22.5734C13.1365 21.5234 14.7006 20.4844 16.2646 19.4398C16.2811 19.4289 16.2975 19.4289 16.3412 19.418C16.3467 19.5 16.3576 19.5711 16.3576 19.6477C16.3576 21.0367 16.3631 22.4258 16.3576 23.8203C16.3467 26.1281 14.5475 27.9273 12.2396 27.9437C11.9881 27.9437 11.7365 27.9437 11.4467 27.9437Z' fill={color} />
      <path d='M6.525 27.9195C3.76328 28.2477 2.06797 26.4922 1.70703 24.6711C1.67422 24.4906 1.63047 24.3102 1.63047 24.1297C1.625 22.5656 1.625 21.0016 1.625 19.4102C1.70156 19.4484 1.76172 19.4703 1.81641 19.5086C3.32578 20.5148 4.84063 21.5211 6.34453 22.5383C6.43203 22.5984 6.51953 22.7297 6.51953 22.8281C6.53047 24.4797 6.525 26.1367 6.525 27.7883C6.53594 27.8375 6.53047 27.8813 6.525 27.9195Z' fill={color} />
      <path d='M6.53359 20.6844C6.20547 20.4656 5.92656 20.2797 5.64766 20.0938C4.37891 19.2461 3.11016 18.3984 1.83594 17.5563C1.68281 17.4578 1.61719 17.3539 1.61719 17.1625C1.62266 15.8008 1.62266 14.4391 1.62266 13.0719C1.62266 12.9898 1.62266 12.9133 1.62266 12.793C1.72109 12.8477 1.78672 12.8805 1.85234 12.9242C3.33984 13.9141 4.82734 14.9094 6.32031 15.8992C6.47344 15.9977 6.53906 16.1016 6.53906 16.293C6.52812 17.6656 6.53359 19.0383 6.53359 20.4109C6.53359 20.4875 6.53359 20.5586 6.53359 20.6844Z' fill={color} />
      <path d='M16.36 12.8008C16.36 13.6758 16.36 14.5234 16.36 15.3656C16.36 15.9836 16.3654 16.6016 16.3545 17.2195C16.3545 17.3125 16.3053 17.4383 16.2342 17.4875C14.6646 18.543 13.0896 19.5875 11.5201 20.632C11.5146 20.6375 11.5037 20.632 11.4709 20.6375C11.4654 20.5719 11.4545 20.5062 11.4545 20.4406C11.4545 19.0516 11.449 17.657 11.46 16.268C11.46 16.1641 11.5201 16.0164 11.6021 15.9562C13.1443 14.9172 14.6975 13.8836 16.2506 12.85C16.267 12.8391 16.2943 12.8281 16.36 12.8008Z' fill={color} />
      <path d='M1.62266 6.22656C1.98359 6.46719 2.28984 6.66953 2.60156 6.87734C3.84297 7.70312 5.07891 8.53437 6.32031 9.35469C6.47344 9.45312 6.53906 9.55703 6.53906 9.74297C6.52812 11.0992 6.53359 12.45 6.53359 13.8062C6.53359 13.8773 6.53359 13.9484 6.53359 14.0633C6.42969 14.0031 6.35312 13.9594 6.28203 13.9156C4.8 12.9258 3.31797 11.9359 1.83594 10.957C1.68828 10.8586 1.61719 10.7602 1.61719 10.5687C1.62812 9.2125 1.62266 7.86172 1.62266 6.50547C1.62266 6.42891 1.62266 6.35781 1.62266 6.22656Z' fill={color} />
      <path d='M16.3576 6.26562C16.3576 7.46328 16.3576 8.64453 16.3576 9.82578C16.3576 10.1539 16.4451 10.5258 16.3084 10.7883C16.1771 11.0398 15.8162 11.1656 15.5537 11.3461C14.2795 12.1992 12.9998 13.0469 11.7201 13.9C11.6436 13.9492 11.567 13.993 11.4631 14.0586C11.4576 13.9766 11.4467 13.9164 11.4467 13.8617C11.4467 12.4781 11.4412 11.1 11.4521 9.71641C11.4521 9.61797 11.5014 9.47578 11.5779 9.42109C13.1365 8.37109 14.7006 7.32656 16.2701 6.2875C16.2811 6.27656 16.3029 6.27656 16.3576 6.26562Z' fill={color} />
      <path d='M19.8568 18.4117C19.2389 18.0563 18.6865 17.7391 18.1451 17.4164C18.074 17.3727 18.0029 17.2687 18.0029 17.1977C17.992 16.618 17.9975 16.0383 17.9975 15.3984C18.8889 15.9125 19.7475 16.4047 20.6498 16.9297C20.3873 17.4164 20.1248 17.9086 19.8568 18.4117Z' fill={color} />
      <path d='M20.6594 11.018C19.757 11.5375 18.8984 12.0352 17.9961 12.5547C17.9961 11.9258 17.9961 11.3461 18.0016 10.7664C18.0016 10.7172 18.0453 10.6406 18.0891 10.6187C18.6578 10.2852 19.232 9.95703 19.8445 9.60156C20.118 10.0773 20.3805 10.5367 20.6594 11.018Z' fill={color} />
      <path d='M21.1463 12.6328C21.3213 13.5461 21.3213 14.4047 21.1354 15.307C20.6432 15.0227 20.1783 14.7547 19.7135 14.4812C19.6752 14.4594 19.6369 14.3992 19.6369 14.3555C19.6314 14.1039 19.6314 13.8469 19.6369 13.5953C19.6369 13.5516 19.6752 13.4914 19.7135 13.4695C20.1729 13.1906 20.6432 12.9227 21.1463 12.6328Z' fill={color} />
      <path d='M18.0195 7.89062C18.2492 8.04922 18.457 8.19141 18.7031 8.36094C18.4625 8.50312 18.2602 8.61797 18.0195 8.75469C18.0195 8.46484 18.0195 8.20234 18.0195 7.89062Z' fill={color} />
      <path d='M18.6977 19.6289C18.4516 19.8039 18.2492 19.9406 18.0195 20.1047C18.0195 19.8094 18.0195 19.5469 18.0195 19.2461C18.2383 19.3719 18.4461 19.4922 18.6977 19.6289Z' fill={color} />
    </g>
    <defs>
      <clipPath id='clip0_87357_160352'>
        <rect width='28' height='28' fill='white' transform='translate(0.75)' />
      </clipPath>
    </defs>
  </svg>
)

export default TireWheel
