import { useContext } from 'react'
import { formatCurrencyDecimal, formatters as F } from '@carfluent/common'

import CalcRoutes from 'constants/route_helper'
import SettingsCTX from 'store/settings'
import { getParsedToken } from 'services/storage.service'
import { toNumberOrNull } from 'utils/parse_string'
import getPersonFullName from 'utils/common/getPersonFullName'
import getPaymentTypeName from 'utils/deals/getPaymentTypeName'
import getVehicleName from 'utils/inventory/getVehicleName'
import getWorkflowStepName from 'utils/deals/getWorkflowStepName'
import { linkToCar } from 'utils/website'
import { PaymentStatusId } from 'types/number_enums'
import {
  DriverLicenseUploadStatusType,
  DealState,
  FileType,
  PaymentMethod
} from 'api/types'

import { type UseDealCardProps, type UseDealCardReturn } from './types'
import getDealStepName from 'utils/deals/getDealStepName'
import isFinancing from 'utils/deals/isFinancing'
import { joinPartsBySpace } from 'utils/view_helper'

const DEAL_STEP_FORMAT = 'MM/dd/yyyy hh:mm aaa'
const MONTHLY_PAYMENT_FORMAT = { emptyValues: '', postfix: '/mo' }

const useDealCard = ({ deal, dealId }: UseDealCardProps): UseDealCardReturn => {
  const { dealer } = useContext(SettingsCTX)
  const vehicle = deal?.vehicle ?? null
  const inventoryVehicleId = vehicle?.id ?? null
  const documents = deal?.documents ?? []
  const dealState = deal?.dealStateId ?? null
  const dealPaidState = deal?.accountingDetails.dealPaidStatusId ?? null
  const isDealSold = dealState === DealState.Completed
  const dealAccountingStateId = deal?.dealAccountingStateId ?? null
  const nextDealAction = deal?.nextAction ?? null
  const payments = deal?.paymentDetails ?? []

  const currentWorkflowStep = deal?.currentDealStep ?? null
  const currentWorkflowStepId = currentWorkflowStep?.templateStepId ?? null

  const token = getParsedToken()
  const dealerId: string = token?.dealer ?? ''
  const inventoryUrl = inventoryVehicleId != null ? CalcRoutes.VehicleDetails(inventoryVehicleId) : null

  const websiteBaseUrl = `${dealer?.dealerFeeMarkupSettings.websiteBaseUrl ?? ''}dealer/${dealerId}`
  const websiteUrl = ((inventoryVehicleId != null) && !isDealSold)
    ? linkToCar(websiteBaseUrl, { id: inventoryVehicleId })
    : null

  const creditApp = deal?.creditApplication
  const creditAppLinkName = creditApp == null
    ? null
    : creditApp.submittedDate == null ? 'View' : 'Submitted'

  const deposit = payments
    .filter(p => (p.paymentMethodId === PaymentMethod.Deposit) || (p.paymentMethodId === PaymentMethod.DownPayment))
  const isDepositPaid = deposit.find((item) => item.paymentStatusId === PaymentStatusId.Completed)
  const hasDepositLabel = (isDepositPaid != null) && dealState !== DealState.Completed

  const driverLicenses = documents.filter(d => d.fileTypeId === FileType.DriverLicense)
  const isPersonaPassed = driverLicenses.length > 0
    ? driverLicenses.every(d => d.documentVerificationDetails?.status === DriverLicenseUploadStatusType.Completed)
    : null

  const dealStepName = isDealSold
    ? getDealStepName(dealAccountingStateId)
    : getWorkflowStepName(currentWorkflowStepId)

  const dealStepDate = isDealSold
    ? F.formatLocalDate(deal?.accountingStateChangeDate, DEAL_STEP_FORMAT)
    : F.formatLocalDate(deal?.currentWorkflowStoppedAt, DEAL_STEP_FORMAT)

  const paymentMethod = getPaymentTypeName(deal?.dealFinanceTypeId)
  const monthlyPayment = formatCurrencyDecimal(deal?.financeInfo?.monthlyPayment, MONTHLY_PAYMENT_FORMAT)
  const paymentInfo = isFinancing(deal?.dealFinanceTypeId)
    ? joinPartsBySpace(paymentMethod, monthlyPayment)
    : paymentMethod

  const buyerName = deal?.businessDetails?.businessName != null ? deal?.businessDetails?.businessName : getPersonFullName(deal?.personalDetails ?? null)

  // ========================================== //

  return {
    buyerName,
    carImageUrl: vehicle?.mainImageUrl ?? null,
    carName: getVehicleName(vehicle, 'model'),
    creditAppLinkName,
    dealId: toNumberOrNull(dealId),
    dealState,
    dealStepName,
    dealStepDate,
    hasDepositLabel,
    inventoryUrl,
    isDealSold,
    isPersonaPassed,
    nextDealAction,
    paymentInfo,
    stockNumber: vehicle?.stock ?? null,
    vin: vehicle?.vin ?? null,
    websiteUrl,
    dealPaidState
  }
}

export default useDealCard
