import { v4 as uuid } from 'uuid'

import { type VehicleImage, VehicleMediaTypeId } from 'api/types'

const getFileSize = async (url: string): Promise<number> => {
  const res = await fetch(url, {
    mode: 'no-cors',
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  })

  const blob = await res.blob()

  return blob.size
}

const DEFAULT_ORDER = 0

export const getVehicleImages = async (urls: string[]): Promise<VehicleImage[]> => {
  const images: VehicleImage[] = []

  for (const url of urls) {
    if (url == null) {
      continue
    }

    const originalSizeKb = await getFileSize(url)
    const uniqName = uuid()

    images.push({
      originalUrl: url,
      thumbnailUrl: url,
      order: DEFAULT_ORDER,
      fileName: uniqName,
      originalSizeKb,
      // adaptation for existing attachment logic
      id: `${uniqName}${DEFAULT_ORDER}`,
      fileId: null,
      vehicleId: null,
      vehicleMediaTypeId: VehicleMediaTypeId.Image
    })
  }

  return images
}
