export const HOUSING_FIELDS = [
  'housingStatus', 'apartmentMoveInDate', 'apartmentPayment', 'addressData',
  'address', 'city', 'state', 'zipCode'
] as const

export type HousingField = typeof HOUSING_FIELDS[number]

export const EMPLOYMENT_FIELDS = [
  'employmentStatus',
  'jobTitle',
  'employerName',
  'employerPhoneNumber',
  'workingStartDate',
  'workingEndDate',
  'income'
] as const

export type EmploymentField = typeof EMPLOYMENT_FIELDS[number]
