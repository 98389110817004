export interface ShapeProps {
  cx: number
  cy: number
  innerRadius: number
  outerRadius: number
  startAngle: number
  endAngle: number
  midAngle: number
  fill: string
  value: number
  dateRange: {
    range: number
    from: string
    to: string
  }
  isHover?: boolean
  isActive?: boolean
  onClick: () => void
  onMouseLeave: () => void
  onMouseEnter: () => void
}

export enum RangePeriod {
  'Due in 1+ days' = 0,
  '0-30 Days' = 1,
  '31-60 Days',
  '61-90 Days',
  '90+ Days'
}

export enum ColorPeriod {
  '#458FFF' = 0,
  '#80BE00' = 1,
  '#FFCA28',
  '#E26D2B',
  '#B00020',
}
