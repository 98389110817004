import { useRef, type FC, useState } from 'react'
import { PopoverV2, Button } from '@carfluent/common'

import CheckboxLabel from 'components/common/CheckboxLabel'
import TooltipedText from 'components/common/TooltipedText'
import SearchInput from 'components/common/SearchInput'

import { ALL_ITEM, POPOVER_OPTIONS, REGISTRATION_STAGE_NOT_SELECTED, REGISTRATION_STAGE_NOT_SELECTED_ID } from './constants'

import IconSVG from 'components/inlineImages'
import { type FiltersProps, VariantFilterType } from './types'
import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

const Filters: FC<FiltersProps> = ({
  search,
  onSearch,
  isLoading,
  titleStages,
  onSearchChange,
  titleStageIds = [],
  onTitleStageChange,
  registrationStages = [],
  registrationStageIds = [],
  onRegistrationStageChange
}) => {
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const anchorElForTitle = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenTitleStageModal, setIsOpenTitleStageModal] = useState(false)
  const isRegistrationSelectedAll = (registrationStageIds ?? []).length === [...(registrationStages ?? []), REGISTRATION_STAGE_NOT_SELECTED_ID].length
  const isRegistrationIntermediate = !isRegistrationSelectedAll && (registrationStageIds ?? []).length > 0
  const isTitleStageSelectedAll = (titleStageIds ?? []).length === [...(titleStages ?? [])].length
  const isTitleStageIntermediate = !isTitleStageSelectedAll && (titleStageIds ?? []).length > 0

  const onChange = (id: number, variant: VariantFilterType) => () => {
    const isRegistration = variant === 'registrationStage'
    const newItems = [...(isRegistration ? registrationStageIds : titleStageIds) ?? []]
    const selectedIndex = newItems.findIndex(item => item === id)

    if (selectedIndex > -1) {
      newItems.splice(selectedIndex, 1)
    } else {
      newItems.push(id)
    }

    isRegistration ? onRegistrationStageChange(newItems) : onTitleStageChange(newItems)
  }

  const onSelectAll = (variant: VariantFilterType): void => {
    const isRegistration = variant === 'registrationStage'
    const selectedAll = isRegistration ? isRegistrationSelectedAll : isTitleStageSelectedAll
    const newItems = selectedAll
      ? []
      : [...((isRegistration ? registrationStages : titleStages) ?? [])
          .map(el => el.id)]

    if (!selectedAll && isRegistration) {
      newItems.push(REGISTRATION_STAGE_NOT_SELECTED_ID)
    }

    isRegistration ? onRegistrationStageChange(newItems) : onTitleStageChange(newItems)
  }

  const titleStageIdsLength = titleStageIds?.length ?? 0
  const registrationStageIdsLength = registrationStageIds?.length ?? 0

  return (
    <div className={CLASS_NAME}>
      <div className='filter-block'>
        <div className='filter-button-wrapper' ref={anchorElForTitle}>
          <Button
            className='filter-button'
            variant='text'
            onClick={() => setIsOpenTitleStageModal(!isOpenTitleStageModal)}
            endAdornment={<IconSVG.DropdownArrowDown width={16} height={16} />}
          >
            <TooltipedText
              value={`Title stage ${titleStageIdsLength > 0 ? isTitleStageSelectedAll ? '(All)' : `(${titleStageIdsLength})` : ''}`}
            />
          </Button>
        </div>
      </div>

      <PopoverV2
        isOpen={isOpenTitleStageModal}
        anchorEl={anchorElForTitle}
        className={POPOVER_CLASS_NAME}
        options={POPOVER_OPTIONS}
        onClose={() => setIsOpenTitleStageModal(false)}
      >
        <p className='title'>Title stage:</p>

        <CheckboxLabel
          onChange={() => onSelectAll('titleStage')}
          value={{
            indeterminate: isTitleStageIntermediate,
            checked: isTitleStageSelectedAll,
            ...ALL_ITEM
          }}
        />

        <div className='cf-filter-items'>
          {
              titleStages?.map(el =>
                <CheckboxLabel
                  key={el.id}
                  value={{
                    checked: titleStageIds?.includes(el.id) ?? false,
                    name: el.name,
                    id: el.id
                  }}
                  onChange={onChange(el.id, 'titleStage')}
                />
              )
            }
        </div>
      </PopoverV2>

      <div className='filter-block'>
        <div className='filter-button-wrapper' ref={anchorEl}>
          <Button
            className='filter-button'
            variant='text'
            onClick={() => setIsOpen(!isOpen)}
            endAdornment={<IconSVG.DropdownArrowDown width={16} height={16} />}
          >
            <TooltipedText
              value={`Registration stage ${registrationStageIdsLength > 0 ? isRegistrationSelectedAll ? '(All)' : `(${registrationStageIdsLength})` : ''}`}
            />
          </Button>
        </div>
      </div>

      <PopoverV2
        isOpen={isOpen}
        anchorEl={anchorEl}
        className={POPOVER_CLASS_NAME}
        options={POPOVER_OPTIONS}
        onClose={() => setIsOpen(false)}
      >
        <p className='title'>Registration stage:</p>

        <CheckboxLabel
          onChange={() => onSelectAll('registrationStage')}
          value={{
            indeterminate: isRegistrationIntermediate,
            checked: isRegistrationSelectedAll,
            ...ALL_ITEM
          }}
        />

        <div className='cf-filter-items'>
          {
              registrationStages?.map(el =>
                <CheckboxLabel
                  key={el.id}
                  value={{
                    checked: registrationStageIds?.includes(el.id) ?? false,
                    name: el.name,
                    id: el.id
                  }}
                  onChange={onChange(el.id, 'registrationStage')}
                />
              )
            }
          <CheckboxLabel
            onChange={onChange(REGISTRATION_STAGE_NOT_SELECTED_ID, 'registrationStage')}
            value={{
              checked: registrationStageIds?.includes(REGISTRATION_STAGE_NOT_SELECTED_ID) ?? false,
              ...REGISTRATION_STAGE_NOT_SELECTED
            }}
          />
        </div>
      </PopoverV2>

      <SearchInput
        withDeleteIcon
        value={search}
        onSearch={onSearch}
        disabled={isLoading}
        isLoading={isLoading}
        className='search-block'
        onChange={onSearchChange}
        placeholder='Car, stock #, VIN'
      />
    </div>
  )
}

export default Filters
