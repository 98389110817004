import { WrapperRequest } from './wrapper.api'

import {
  type ListPayload,
  type DictionaryItem,
  type FileIdsDto,
  type CloudAccessTokenDto,
  type FileDto,
  type UploadedAssetWithThumbnailDto,
  type UploadedAttachmentDto,
  type UploadedVehicleDocumentDto,
  type ListResponse,
  type DealFormEnvelopeDto,
  type SendForSignInRequestDto,
  type GetTemplateUrlDto,
  type DocumentFormPackDto,
  type DocumentFormPackCreateRequestDto,
  type DocumentFormDto,
  type DocumentFormPackRequestDto, CancelationOptions
} from './types'
import type { EntityId } from 'types'

class DocumentsApi extends WrapperRequest {
  url = process.env.REACT_APP_DOCUMENTS ?? ''

  postFiles = async (data: FormData): Promise<FileIdsDto> => {
    return await this.post(`${this.url}/api/v1/Files`, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })
  }

  getCloudAccessToken = async (): Promise<CloudAccessTokenDto> => {
    return await this.get(`${this.url}/api/v1/Files/token`)
  }

  postLogo = async (data: FormData): Promise<UploadedAssetWithThumbnailDto> => {
    return await this.post(`${this.url}/api/v1/Assets/logo`, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })
  }

  postVehiclePhotos = async (data: FormData, cancellationOptions?: CancelationOptions): Promise<UploadedAssetWithThumbnailDto> => {
    return await this.post(`${this.url}/api/v1/Assets/vehicleimages`, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    }, cancellationOptions)
  }

  postVehicleDocument = async (data: FormData): Promise<{ files: UploadedVehicleDocumentDto[] }> => {
    return await this.post(`${this.url}/api/v1/Files/vehicle-documents`, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })
  }

  postEmailAttachments = async (data: FormData): Promise<UploadedAttachmentDto> => {
    return await this.post(`${this.url}/api/v1/Assets/private/email-attachments`, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })
  }

  async getFile (id: number): Promise<FileDto> {
    return await this.get(`${this.url}/api/v1/Files/${id}`)
  }

  getTemplateUrl = async (id: number): Promise<GetTemplateUrlDto> => {
    return await this.get(`${this.url}/api/v1/Templates/${id}/url`)
  }

  getTemplateList = async (): Promise<DictionaryItem[]> => {
    return await this.get(`${this.url}/api/v1/Templates`)
  }

  addNewTemplate = async (templateName: string): Promise<void> => {
    return await this.post(`${this.url}/api/v1/Templates`, { templateName })
  }

  deleteTemplate = async (id: number): Promise<void> => {
    return await this.delete(`${this.url}/api/v1/Templates/${id}`)
  }

  patchTemplate = async (template: DictionaryItem): Promise<void> => {
    return await this.patch(`${this.url}/api/v1/Templates/${template.id}`, template)
  }

  getDocumentDealForms = async (dealId: EntityId): Promise<ListResponse<DocumentFormDto>> => {
    return await this.get(`${this.url}/api/v1/DealForms/${dealId}`)
  }

  notarizeDocumentDealForms = async (dealId: EntityId, ids: number[]): Promise<void> => {
    return await this.post(`${this.url}/api/v1/DealForms/${dealId}/notarize`, { ids })
  }

  sendDocumentDealForms = async (dealId: EntityId, data: SendForSignInRequestDto): Promise<void> => {
    return await this.post(`${this.url}/api/v1/DealForms/${dealId}/send`, data)
  }

  downloadDocumentDealForms = async (dealId: EntityId, ids: number[]): Promise<BlobPart> => {
    return await this.post(`${this.url}/api/v1/DealForms/${dealId}/download`, {
      data: { ids },
      additionalParams: { responseType: 'blob' }
    })
  }

  downloadBillOfSale = async (dealId: EntityId): Promise<BlobPart> => {
    return await this.get(`${this.url}/api/v1/DealForms/${dealId}/bill-of-sale/download`, {
      additionalParams: { responseType: 'blob' }
    })
  }

  downloadBuyersGuide = async (vehicleId: EntityId): Promise<BlobPart> => {
    return await this.get(`${this.url}/api/v1/DealForms/${vehicleId}/buyers-guide/download`, {
      additionalParams: { responseType: 'blob' }
    })
  }

  getEnvelopes = async (dealId: EntityId): Promise<ListResponse<DealFormEnvelopeDto>> => {
    return await this.get(`${this.url}/api/v1/Envelopes/deal/${dealId}`)
  }

  voidEnvelop = async (envelopId: number): Promise<void> => {
    return await this.patch(`${this.url}/api/v1/Envelopes/${envelopId}/void`)
  }

  resendEnvelop = async (envelopId: number): Promise<void> => {
    return await this.patch(`${this.url}/api/v1/Envelopes/${envelopId}/resend`)
  }

  getEnvelopeSignUrl = async (dealId: EntityId, envelopId: number): Promise<{ url: string }> => {
    return await this.get(`${this.url}/api/v1/Envelopes/deal/${dealId}/envelope/${envelopId}/signUrl`)
  }

  patchDocumentDealForms = async (dealId: EntityId, ids: number[]): Promise<void> => {
    return await this.patch(`${this.url}/api/v1/DealForms/${dealId}`, { ids })
  }

  // ========================================== //
  //                Form Packs                  //
  // ========================================== //

  getForms = async (data: ListPayload): Promise<ListResponse<DocumentFormDto>> => {
    return await this.post(`${this.url}/api/v1/Forms/search`, data)
  }

  downloadForm = async (id: EntityId): Promise<string> => {
    return await this.get(`${this.url}/api/v1/Forms/${id}/download`, {
      additionalParams: { responseType: 'blob' }
    })
  }

  getFormPacks = async (data: ListPayload): Promise<ListResponse<DocumentFormPackDto>> => {
    return await this.post(`${this.url}/api/v1/FormPacks/search`, data)
  }

  getFormPack = async (id: EntityId): Promise<DocumentFormPackDto> => {
    return await this.get(`${this.url}/api/v1/FormPacks/${id}`)
  }

  patchFormPack = async (formPack: DocumentFormPackRequestDto): Promise<DocumentFormPackDto> => {
    return await this.patch(`${this.url}/api/v1/FormPacks/${formPack?.id}`, formPack)
  }

  deleteFormPack = async (id: EntityId): Promise<void> => {
    return await this.delete(`${this.url}/api/v1/FormPacks/${id}`)
  }

  postFormPack = async (data: DocumentFormPackCreateRequestDto): Promise<DocumentFormPackDto> => {
    return await this.post(`${this.url}/api/v1/FormPacks`, data)
  }
}

const DocumentsApiProvider = new DocumentsApi()

export default DocumentsApiProvider
