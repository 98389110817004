import type { FC } from 'react'
import type { CellProps, CellFC } from 'types'

import ApplyPropsHOK from 'components/common/ApplyPropsHOC'

export type EditableCellFC<TData, TValue = unknown> =
  FC<CellProps<TData, TValue> & EditableCellProps<TValue>>

export interface EditableCellProps <TValue = unknown> {
  className?: string
  label?: string
  showTooltip?: boolean
  onChange: (rowIdx: number, columnId: string, value: TValue) => void
  onBlur?: (rowIdx: number, columnId: string) => void
}

/**
 * Adds "editability" to the cell renderer (which basically means support of `onChange` property).
 * Check an example of usage in the `New Journal Entry" modal window (column "Account").
 */
function EditableCellHOK<TData, TValue = unknown> (
  config: EditableCellProps<TValue>,
  Comp: EditableCellFC<TData, TValue>
): CellFC<TData, TValue> {
  return ApplyPropsHOK(config, Comp)
}

export default EditableCellHOK
