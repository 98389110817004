import { type FC } from 'react'
import { FormDropdown, FormInput, FormNumberInput, Loader } from '@carfluent/common'

import { MAX_PRICE_VALUE } from 'constants/validation'
import formatTermValue from 'utils/coverage/formatTermValue'
import parseTermValue from 'utils/coverage/parseTermValue'

import { type CoverageManualFormProps } from './types'
import { FIELD_MARGIN } from './styles'
import {
  COVERAGE_NAME_MAX_LENGTH,
  TERM_OPTIONS,
  DEDUCTIBLE_AMOUNT_MAX_VALUE
} from './constants'
import { CoverageProvider } from 'types/coverage'

const CoverageManualForm: FC<CoverageManualFormProps> = ({
  disabled = false,
  errors,
  fieldPathPrefix,
  isCoveragesVisible = true,
  isDeductibleVisible = true,
  onBlur,
  onChange,
  touched,
  product,
  values
}) => {
  if (product == null) {
    return <Loader /> // waiting for dealer products
  }

  const selectedVendor = product?.find(el => el.id === values?.providerId)

  return (
    <>
      <FormDropdown
        mode='search'
        label='Vendor'
        onBlur={onBlur}
        value={selectedVendor}
        dataTestId='manual-vendor'
        options={product}
        disabled={disabled}
        className={FIELD_MARGIN}
        id={`${fieldPathPrefix}.providerId`}
        error={errors?.providerId}
        touched={touched?.providerId}
        onChange={(_, value: CoverageProvider | null) => {
          onChange(fieldPathPrefix, {
            ...values,
            isFormDisabled: value == null,
            cost: value == null ? null : values?.cost,
            providerId: value == null ? null : value.id,
            termMiles: value == null ? null : values?.termMiles,
            totalPrice: value == null ? null : values?.totalPrice,
            termMonths: value == null ? null : values?.termMonths,
            coverageName: value == null ? null : values?.coverageName,
            deductibleId: value == null ? null : values?.deductibleId,
            deductibleAmount: value == null ? null : values?.deductibleAmount,
            productCoverageId: value == null ? null : values?.productCoverageId
          })
        }}
      />

      <FormInput
        className={FIELD_MARGIN}
        disabled={disabled}
        error={errors?.coverageName ?? null}
        id={`${fieldPathPrefix}.coverageName`}
        label='Type'
        maxLength={COVERAGE_NAME_MAX_LENGTH}
        onBlur={onBlur}
        onChange={onChange}
        touched={touched?.coverageName ?? false}
        value={values?.coverageName ?? ''}
      />

      {isCoveragesVisible && (
        <FormDropdown
          className={FIELD_MARGIN}
          error={errors?.termMonths ?? null}
          disableClearable
          disabled={disabled}
          id={`${fieldPathPrefix}.termMonths`}
          label='Term'
          onBlur={onBlur}
          onChange={(id, value) => {
            const { termMonths, termMiles } = parseTermValue(value)
            onChange(id, termMonths)
            onChange(`${fieldPathPrefix}.termMiles`, termMiles)
          }}
          options={TERM_OPTIONS}
          touched={touched?.termMonths ?? false}
          value={formatTermValue(values?.termMonths ?? null, values?.termMiles ?? null)}
        />
      )}

      {isDeductibleVisible && (
        <FormNumberInput
          className={FIELD_MARGIN}
          error={errors?.deductibleAmount ?? null}
          id={`${fieldPathPrefix}.deductibleAmount`}
          isNegativeAllowed={false}
          label='Deductible'
          mode='integer'
          disabled={disabled}
          max={DEDUCTIBLE_AMOUNT_MAX_VALUE}
          onBlur={onBlur}
          onChange={onChange}
          preset='financial'
          touched={touched?.deductibleAmount ?? false}
          value={values?.deductibleAmount ?? ''}
        />
      )}

      <FormNumberInput
        className={FIELD_MARGIN}
        disabled={disabled}
        error={errors?.totalPrice ?? null}
        id={`${fieldPathPrefix}.totalPrice`}
        isEmptyAllowed={false}
        isNegativeAllowed={false}
        label='Total price'
        max={MAX_PRICE_VALUE}
        onBlur={onBlur}
        onChange={onChange}
        preset='financial'
        touched={touched?.totalPrice ?? false}
        value={values?.totalPrice ?? ''}
      />

      <FormNumberInput
        className={FIELD_MARGIN}
        disabled={disabled}
        error={errors?.cost ?? null}
        id={`${fieldPathPrefix}.cost`}
        isNegativeAllowed={false}
        label='Costs'
        max={MAX_PRICE_VALUE}
        onBlur={onBlur}
        onChange={onChange}
        preset='financial'
        touched={touched?.cost ?? false}
        value={values?.cost ?? ''}
      />
    </>
  )
}

export default CoverageManualForm
