import { css } from '@emotion/css'

export default css(`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;

  .cf-buttons-group {
     display: flex;
     align-items: center;
  }

  .cf-right-group {
     justify-content: flex-end;
  }

  .cf-button {
    min-width: 150px;
    max-width: 150px;

    span {
      text-transform: uppercase;
    }

    &.cf-button-print {
      padding: 20px 12px;
      text-align: center;
      white-space: nowrap;
    }

  }

  .print-period {
    color: rgba(0, 0, 0, 0.38);
    font-size: 0.868rem;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-left: 24px;
  }

  .cf-button-delete.cf-button-color-primary.cf-button-root {
    margin-right: 24px;

    &, &:hover {
      border: 1px solid #B00020;

      .cf-button-content {
        color: #B00020;
      }
    }

    &.cf-button-disabled {
      border: 1px solid rgba(33, 33, 33, 0.4);

      .cf-button-content {
        color: rgba(33, 33, 33, 0.4);
      }
    }
  }

  .cf-button-cancel {
    margin-right: 24px;
  }
`)

export const POPOVER_CLASS_NAME = css(`
  &.cf-popover-container {
    display: block;
    background-color: #212121;
    padding: 4px 12px;
    color: #fff;
    z-index: 150000;
    border-radius: 4px;
    font-size: 14px;

    &.delete {
      margin-top: 15px;
      left: 520px;
    }

    &.small-delete {
      margin-top: -30px;
      // left: 220px;
    }

    &.update {
      margin-top: -30px;
    }
  }
`)
