import { css } from '@emotion/css'

export const GENERATE_AI_MODAL_CLASS_NAME = css(`
  .g-modal-ai-content {
    * {
      line-height: 24px;
      letter-spacing: 0.15px;
    }

    ul {
      padding-left: 24px;
      margin-bottom: 32px;
    }
  }
  
  .cf-modal-footer {
    padding: 0 40px;
  }
`)

export const editorWrapperStyles = css(`
    width: 100%;
    display: block;
    
    &.editor-disabled {
      opacity: 0.38;
    }

    .rdw-editor-toolbar {
      padding: 16px;
      margin-bottom: 8px;
    }

    .rdw-option-wrapper {
      border: none;
    }

    .rdw-dropdown-wrapper {
      width: 120px;
      border: none;
      border-radius: 8px;
      background: rgb(243, 243, 243);

      a {
        padding: 0 8px;

        span {
          font-family: Roboto, sans-serif;
          font-size: 12px;
        }
      }

      &.rdw-fontsize-dropdown {
        width: 60px;
      }

      .rdw-dropdown-optionwrapper {
        margin-top: 5px;

        li {
          font-family: Roboto, sans-serif;
          padding: 10px;
        }
      }
    }

    && {
      .text-editor-container {
        border: 1px solid #0000001F;
        border-radius: 10px;
        padding: 10px;
        min-height: 180px;
        max-height: 320px;
        margin-bottom: 16px;

        span > span {
          font-weight: inherit;
          font-family: inherit;
        }

        img {
          max-width: 100%;
        }

        .public-DraftStyleDefault-block {
          margin: 0.25em 0;
        }
      }
    }
  }
`)

export default css(`
  display: flex;
  justify-content: space-between;
  position: relative;

  .cf-left-panel {
    > div {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  };

  .cf-right-panel {
    margin-left: 20px;
    max-width: 208px;
    min-width: 208px;
  };

  .cf-description-section {
    display: flex;
    flex-direction: column;
    position: relative;
    
    .g-generate-ai-button {
      position: absolute;
      top: 0;
      right: 0;
      text-decoration: none;
      padding: 5px 18px;
      border-radius: 8px;
      border: 1px solid #C99B86;
      font-size: 14px;
      
      &.cf-button-disabled {
        border: 1px solid rgba(0, 0, 0, 0.38);
      }
    }
    
    :last-child {
      border-bottom: none;
    }
  };

  .cf-vehicle-update-errors-wrapper {
    margin-bottom: 20px;
  };

  .cf-status-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    margin-top: 28px;
    margin-bottom: 16px;
  };

  .cf-expected-wrapper {
    margin-top: 24px;
    margin-bottom: 16px;

    .MuiFormControlLabel-root {
      margin-left: 0;
    }
  };
  
  .form-control-checkbox {
    margin-left: 0;
  }

  .cf-vehicle-update-checkbox-icon {
    color: #000000;
    padding: 0 8px 0 0px;
  }

  .cf-clickable-input-adornment {
    font-family: Roboto;
    cursor: pointer;
    text-decoration: underline;
    color: #000000;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    min-height: 40px;
    min-width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .cf-inventory-sale-price {
    min-width: 148px;
  }
  
  .end-adornment {
    color: rgba(33, 33, 33, 0.40);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .notes {
    height: 184px;
    box-shadow: none;
    border-bottom: none!important;
  }
`)
