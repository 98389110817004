import { values } from 'lodash-es'

import { RowProps, RowVariants } from './components/Row'

interface DefaultDataProps {
  types: Record<string, string>
  titles: Record<string, string>
}

interface GetRowClassNameProps {
  variant: RowVariants
  name?: string
}

const getVariantRowClass = (variant: RowVariants): string => `cf-row-${variant}`

const variantClassNameGetter = {
  [RowVariants.Title]: getVariantRowClass(RowVariants.Title),
  [RowVariants.Info]: getVariantRowClass(RowVariants.Info),
  [RowVariants.Subtitle]: getVariantRowClass(RowVariants.Subtitle),
  [RowVariants.Total]: getVariantRowClass(RowVariants.Total),
  [RowVariants.Empty]: getVariantRowClass(RowVariants.Empty)
}

export const getRowClassName = ({
  variant,
  name
}: GetRowClassNameProps): string =>
  `${variantClassNameGetter[variant]} ${name != null ? `cf-${name}` : ''}`

export const getDefaultData = ({
  types,
  titles
}: DefaultDataProps): RowProps[] =>
  values(types).reduce((res: RowProps[], type) => [
    ...res,
    {
      variant: RowVariants.Title,
      value: 'Balance',
      text: titles[type]
    },
    {
      variant: RowVariants.Total,
      value: '',
      text: `Total ${titles[type]}`
    }
  ], [])
