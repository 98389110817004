import type { FC } from 'react'

import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

import { SKELETON_CLASS_NAME } from './styles'

const Skeleton: FC = () => {
  return (
    <div className={SKELETON_CLASS_NAME}>
      <BlockSkeleton isLoading className='skeleton-title' />
      <BlockSkeleton isLoading className='skeleton-subtitle' />
      <div className='checkboxes'>
        <div className='column'>
          <div className='skeleton-checkbox-container'>
            <BlockSkeleton isLoading className='check' />
            <BlockSkeleton isLoading className='label' />
          </div>
          <div className='skeleton-checkbox-container'>
            <BlockSkeleton isLoading className='check' />
            <BlockSkeleton isLoading className='label' />
          </div>
          <div className='skeleton-checkbox-container'>
            <BlockSkeleton isLoading className='check' />
            <BlockSkeleton isLoading className='label' />
          </div>
        </div>
        <div className='column'>
          <div className='skeleton-checkbox-container'>
            <BlockSkeleton isLoading className='check' />
            <BlockSkeleton isLoading className='label' />
          </div>
          <div className='skeleton-checkbox-container'>
            <BlockSkeleton isLoading className='check' />
            <BlockSkeleton isLoading className='label' />
          </div>
          <div className='skeleton-checkbox-container'>
            <BlockSkeleton isLoading className='check' />
            <BlockSkeleton isLoading className='label' />
          </div>
        </div>
      </div>
      <BlockSkeleton isLoading className='skeleton-subtitle' />
      <div className='checkboxes'>
        <div className='column'>
          <div className='skeleton-checkbox-container'>
            <BlockSkeleton isLoading className='check' />
            <BlockSkeleton isLoading className='label' />
          </div>
          <div className='skeleton-checkbox-container'>
            <BlockSkeleton isLoading className='check' />
            <BlockSkeleton isLoading className='label' />
          </div>
          <div className='skeleton-checkbox-container'>
            <BlockSkeleton isLoading className='check' />
            <BlockSkeleton isLoading className='label' />
          </div>
        </div>
        <div className='column'>
          <div className='skeleton-checkbox-container'>
            <BlockSkeleton isLoading className='check' />
            <BlockSkeleton isLoading className='label' />
          </div>
          <div className='skeleton-checkbox-container'>
            <BlockSkeleton isLoading className='check' />
            <BlockSkeleton isLoading className='label' />
          </div>
          <div className='skeleton-checkbox-container'>
            <BlockSkeleton isLoading className='check' />
            <BlockSkeleton isLoading className='label' />
          </div>
        </div>
      </div>

      <div className='skeleton-field-container'>
        <BlockSkeleton isLoading className='skeleton-title' />
        <div className='container'>
          <BlockSkeleton isLoading className='skeleton-field small' />
          <BlockSkeleton isLoading className='skeleton-field small' />
        </div>
      </div>

      <div className='skeleton-field-container'>
        <BlockSkeleton isLoading className='skeleton-title' />
        <div className='container'>
          <BlockSkeleton isLoading className='skeleton-field' />
          <BlockSkeleton isLoading className='skeleton-field' />
          <BlockSkeleton isLoading className='skeleton-field' />
        </div>
      </div>

      <div className='skeleton-field-container'>
        <BlockSkeleton isLoading className='skeleton-title' />
        <div className='container'>
          <BlockSkeleton isLoading className='skeleton-field' />
        </div>
      </div>

      <BlockSkeleton isLoading className='skeleton-title' />
      <BlockSkeleton isLoading className='skeleton-text' />

      <div className='skeleton-field-container'>
        <BlockSkeleton isLoading className='skeleton-title' />
        <div className='container'>
          <BlockSkeleton isLoading className='skeleton-field' />
          <BlockSkeleton isLoading className='skeleton-field' />
        </div>
      </div>

      <div className='skeleton-field-container'>
        <BlockSkeleton isLoading className='skeleton-title' />
        <div className='container'>
          <BlockSkeleton isLoading className='skeleton-field' />
          <BlockSkeleton isLoading className='skeleton-field' />
        </div>
      </div>

      <BlockSkeleton isLoading className='skeleton-title' />
      <BlockSkeleton isLoading className='skeleton-text' />

      <div className='skeleton-field-container'>
        <BlockSkeleton isLoading className='skeleton-title' />
        <div className='container'>
          <BlockSkeleton isLoading className='skeleton-field' />
          <BlockSkeleton isLoading className='skeleton-field' />
        </div>
      </div>

      <div className='skeleton-field-container'>
        <BlockSkeleton isLoading className='skeleton-title' />
        <div className='container'>
          <BlockSkeleton isLoading className='skeleton-field' />
          <BlockSkeleton isLoading className='skeleton-field' />
        </div>
      </div>
    </div>
  )
}

export default Skeleton
