import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGComment: FC<IconProps> = ({ color = '#458FFF', width = 21, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_86082_291010)'>
        <path d='M18.9913 3.33464C18.9913 2.41797 18.2497 1.66797 17.333 1.66797H3.99967C3.08301 1.66797 2.33301 2.41797 2.33301 3.33464V13.3346C2.33301 14.2513 3.08301 15.0013 3.99967 15.0013H15.6663L18.9997 18.3346L18.9913 3.33464ZM15.6663 11.668H5.66634V10.0013H15.6663V11.668ZM15.6663 9.16797H5.66634V7.5013H15.6663V9.16797ZM15.6663 6.66797H5.66634V5.0013H15.6663V6.66797Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_86082_291010'>
          <rect width={width} height={height} fill='white' transform='translate(0.666016)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGComment
