import { type BankStatementInfo, type EntityId, type TransactionLineRow } from 'types'
import { type AccountingFiscalYearsResponse } from 'api/types'

export interface BannersProps {
  bankStatements: BankStatementInfo[]
  closeYearError: string | null
  depositDealId: number | null
  inventoryId: EntityId | null
  isGeneratedTransaction: boolean
  isLocked: boolean
  isReadonly: boolean
  isSystemCreated: boolean
  isVehiclePayment: boolean
  isVehicleCost: boolean
  hasLineWithCompletedReconciliation: boolean
  lockedDate: Date | null
  lockedInfo: AccountingFiscalYearsResponse | null
  onCloseModal: () => void
  packInventoryAssetAccountNumber: number | null
  retailVehicleCostsAccountNumber: number | null
  tableData: TransactionLineRow[]
  transactionStateId: number | null
}

export enum ReadonlyMessageType {
  ReconciledTransaction = 1,
  LinkedTransaction,
  LockedYearError,
  LockedYear,
  LockedMonth,
  VoidedReceipt,
  RefundReceipt,
  CostTransaction,
  OtherSystemCreated
}
