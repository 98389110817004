import { CellWrapper } from '@carfluent/common'

import type { DictionaryItems } from 'api/types/utility.types'
import TruncatedItemsList from 'components/common/TruncatedItemsList'

import { mapToTagItems } from './utils'

interface TagsCellHOCProps {
  items: DictionaryItems<string>
}

interface TagsCellProps {
  getValue: () => number[]
}

const TagsCellHOC = ({ items }: TagsCellHOCProps) => (props: TagsCellProps) => {
  const mappedItems = mapToTagItems(items, props.getValue())

  return (
    <CellWrapper className='cf-tag-cell'>
      <TruncatedItemsList items={mappedItems} symbolWidthKoef={0.45} />
    </CellWrapper>
  )
}

export default TagsCellHOC
