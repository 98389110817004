import { css } from '@emotion/css'
import { LabelColors } from 'constants/constants'

export default css(`
  display: flex;
  
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #101010;
  }

  .cf-hot {
    background: ${LabelColors.Hot};
  }

  .cf-warm {
    background: ${LabelColors.Warm};
  }

  .cf-cold {
    background: ${LabelColors.Cold};
  }
`)
