import type { FC } from 'react'
import callIcon from 'assets/log-activity-call-icon.svg'
import appointmentIcon from 'assets/log-activity-appointment-icon.svg'
import reminderIcon from 'assets/log-activity-reminder-icon.svg'
import emailIcon from 'assets/log-activity-email-icon.svg'
import messageIcon from 'assets/log-activity-message-icon.svg'
import storeVisitIcon from 'assets/log-activity-store-visit-icon.svg'
import otherIcon from 'assets/log-activity-other-icon.svg'

export const CallIcon: FC = () => {
  return (
    <img src={callIcon} alt='Call Icon' />
  )
}

export const ApptIcon: FC = () => {
  return (
    <img src={appointmentIcon} alt='Appointment Icon' />
  )
}

export const ReminderIcon: FC = () => {
  return (
    <img src={reminderIcon} alt='Reminder Icon' />
  )
}

export const EmailIcon: FC = () => {
  return (
    <img src={emailIcon} alt='Email Icon' />
  )
}

export const MessageIcon: FC = () => {
  return (
    <img src={messageIcon} alt='Message Icon' />
  )
}

export const StoreVisitIcon: FC = () => {
  return (
    <img src={storeVisitIcon} alt='Store Visit Icon' />
  )
}

export const OtherIcon: FC = () => {
  return (
    <img src={otherIcon} alt='Other Icon' />
  )
}
