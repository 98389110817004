import { type FCHook } from 'types'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { type MessageSnippetDto } from 'api/types/responses'

import type { UseSnippetsDropdownProps, UseSnippetsDropdownReturn } from './types'

const useSnippetsDropdown: FCHook<UseSnippetsDropdownProps, UseSnippetsDropdownReturn> = ({
  bodyId,
  maxSymbols,
  errorMessage,
  onChange: setTextAreaValue
}) => {
  const { showAlert } = useCustomSnackbar()

  const onChange = (_: string, value: MessageSnippetDto | null): void => {
    if (value == null) {
      return
    }

    const textarea = document.getElementById(bodyId) as HTMLTextAreaElement | null
    if (textarea == null) {
      return
    }

    const { selectionStart, selectionEnd, value: textareaValue } = textarea

    const snippetText = `${value.placeholder} `
    const newText = `${textareaValue.slice(
        0,
        selectionStart
      )}${snippetText}${textareaValue.slice(selectionEnd)}`

    textarea.selectionStart = selectionStart + snippetText.length
    textarea.selectionEnd = selectionStart + snippetText.length

    if (newText.length <= maxSymbols) {
      setTextAreaValue?.(bodyId, newText)
    } else {
      showAlert(errorMessage)
    }
  }

  return {
    onChange
  }
}

export default useSnippetsDropdown
