import { useCallback, useMemo, useState, useEffect, type MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { type DeepRequired, fail, ok, type Result, useForm, useModal, useRefUpdater } from '@carfluent/common'
import pDebounce from 'p-debounce'

import { RecipientsFilterOptions } from 'types/enums'
import CRMApiProvider from 'api/crm.api'
import { Routes } from 'constants/route_helper'
import { type DictionaryItem } from 'api/types'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import {
  GET_DEFAULT_LEAD_STATUS_VALUES,
  GET_DEFAULT_LEAD_SOURCE_VALUES,
  GET_DEFAULT_LEAD_TEMPERATURE_VALUES,
  GET_DEFAULT_LEAD_WITH_SCHEDULED_APPOINTMENTS_VALUES
} from 'api/defaults'

import {
  type UseStepRecipientsReturn,
  type UseStepRecipientsProps,
  type RecipientsFormData,
  type ErrTouchShortcuts,
  type ErrTypeMap
} from './types'

import { getFilterItems, LOST_STATUS_ITEM } from './constants'
import formValidation from './validation'
import { mapModelOrDefaultToForm, mapFormToModel } from './utils'
import serializeFormData from './serializer'
import { isFalsy, isTruthy } from 'utils/general'

const SAVE_FIELD_DELAY = 1500
const VALIDATION_RULES = {}

const useStepRecipients = ({
  campaign,
  hasUnsavedChanges,
  onChangeUnsavedChanges,
  onRequestNextStep,
  onRequestPreviousStep
}: UseStepRecipientsProps): UseStepRecipientsReturn => {
  const navigate = useNavigate()
  const { showAlert } = useCustomSnackbar()
  const modalUnsavedChanges = useModal()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedFilter, setSelectedFilter] = useState<number | null>(null)
  const [statuses, setStatuses] = useState<DictionaryItem[]>([])
  const [temperatures, setTemperatures] = useState<DictionaryItem[]>([])
  const [sources, setSources] = useState<DictionaryItem[]>([])
  const [recipientsCount, setRecipientsCount] = useState<number | null>(null)
  const [globalError, setGlobalError] = useState<string | null>(null)
  const [isCounterLoading, setIsCounterLoading] = useState(true)

  const baseValues = useMemo(() => {
    return mapModelOrDefaultToForm(campaign)
  }, [campaign]) as DeepRequired<RecipientsFormData>

  const form = useForm<RecipientsFormData, ErrTouchShortcuts, never, ErrTypeMap>({
    baseValues,
    isTrackingChanges: true,
    validationRules: VALIDATION_RULES
  })

  const { values, onChange } = form

  const isDisabledAddFilterButton = useMemo(() => {
    return Object.values(values).every((value) => value != null)
  }, [values])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const updateCounter = useMemo(() => pDebounce(async () => {
    try {
      setIsCounterLoading(true)
      const payload = serializeFormData(form.values)
      const result = await CRMApiProvider.getRecipientsCount(payload)
      setRecipientsCount(result.total)

      if (isTruthy(result.total)) {
        setGlobalError(null)
      }
    } catch (err) {
      showAlert(err)
    } finally {
      setIsCounterLoading(false)
    }
  }, SAVE_FIELD_DELAY), [form.values])

  const onCancel = useCallback(() => {
    navigate(Routes.CRMMarketingCampaigns)
  }, [])

  const onAddFilter = useCallback(({ currentTarget }: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(currentTarget)
  }, [])

  const onCloseFilter = useCallback(() => {
    setAnchorEl(null)
    setSelectedFilter(null)
  }, [])

  const onSelectFilter = useCallback((id: number) => {
    setSelectedFilter(id)

    switch (id) {
      case RecipientsFilterOptions.LeadSource: {
        form.setFieldValue('filterLeadSource', GET_DEFAULT_LEAD_SOURCE_VALUES())
        form.setFieldTouched('filterLeadSource', true)
        return
      }

      case RecipientsFilterOptions.LeadStatus: {
        form.setFieldValue('filterLeadStatus', GET_DEFAULT_LEAD_STATUS_VALUES())
        form.setFieldTouched('filterLeadStatus', true)
        return
      }

      case RecipientsFilterOptions.LeadTemperature: {
        form.setFieldValue('filterLeadTemperature', GET_DEFAULT_LEAD_TEMPERATURE_VALUES())
        form.setFieldTouched('filterLeadTemperature', true)
        return
      }

      case RecipientsFilterOptions.LeadWithAppointments: {
        form.setFieldValue(
          'filterLeadWithScheduledAppointments',
          GET_DEFAULT_LEAD_WITH_SCHEDULED_APPOINTMENTS_VALUES()
        )

        form.setFieldTouched('filterLeadWithScheduledAppointments', true)
      }
    }
  }, [form.setFieldTouched])

  const onRemoveFilter = useCallback((id: string) => {
    form.onChange(id, null)
    form.setFieldTouched(id, true)
  }, [
    form.setFieldTouched,
    form.onChange
  ])

  const filterItems = useMemo(() => getFilterItems({
    onCloseFilter,
    onChange: onSelectFilter,
    formValues: form.values
  }), [onCloseFilter, form.values, onSelectFilter])

  const onGoBack = useCallback(() => {
    onRequestPreviousStep()
  }, [onRequestPreviousStep])

  const onGoNext = useCallback(async (): Promise<Result<unknown>> => {
    const action = async (values: RecipientsFormData): Promise<Result<unknown>> => {
      if (isFalsy(values.recipientsCounter)) {
        setGlobalError('You cannot send a campaign to 0 recipients.')
        return fail(new Error('Validation error'))
      } else {
        onRequestNextStep(mapFormToModel(values))
        return ok(null)
      }
    }

    return await form.onSubmit(undefined, formValidation, action)
  }, [
    form.onSubmit,
    onRequestNextStep
  ])

  const onBlur = useCallback((e) => {
    form.onBlur(e.target.id)
    form.validateForm()
    setGlobalError(null)
  }, [
    form.onBlur,
    form.validateForm
  ])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  const refUpdateCounter = useRefUpdater(updateCounter)
  useEffect(() => {
    void refUpdateCounter.current()
  }, [
    values.filterLeadSource,
    values.filterLeadStatus,
    values.filterLeadTemperature,
    values.filterLeadWithScheduledAppointments
  ])

  useEffect(() => {
    if (values.recipientsCounter !== recipientsCount) {
      void onChange('recipientsCounter', recipientsCount)
    }
  }, [
    recipientsCount,
    values.recipientsCounter,
    onChange
  ])

  useEffect(() => {
    const getItems = async (): Promise<void> => {
      const [
        statusesResponse,
        temperaturesResponse,
        sourcesResponse
      ] = await Promise.all([
        CRMApiProvider.getLeadStatuses(),
        CRMApiProvider.getLeadTemperature(),
        CRMApiProvider.getLeadSources()
      ])

      setStatuses([...statusesResponse.items, LOST_STATUS_ITEM])
      setTemperatures(temperaturesResponse.items)
      setSources(sourcesResponse.items)
    }

    void getItems()
  }, [])

  /**
   * Track if form was changed.
   */
  useEffect(() => {
    if (form.isTouched) {
      onChangeUnsavedChanges(form.hasChanges)
    }
  }, [
    form.hasChanges,
    form.isTouched,
    onChangeUnsavedChanges
  ])

  // ========================================== //

  return {
    ...form,
    onBlur,
    isCounterLoading,
    anchorEl,
    filterItems,
    selectedFilter,
    isDisabledAddFilterButton,
    statuses,
    temperatures,
    sources,
    recipientsCount,
    error: globalError,
    onAddFilter,
    onCloseFilter,
    onCancel,
    onGoBack,
    onRemoveFilter,
    onGoNext,
    unsavedChangesProps: {
      ...modalUnsavedChanges,
      shouldBlock: hasUnsavedChanges
    }
  }
}

export default useStepRecipients
