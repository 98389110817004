import { type FC } from 'react'
import { observer } from 'mobx-react-lite'

import { TransactionTypeId } from 'api/types'
import CostsForm, { type CostsFormProps } from 'pages/accounting/CostsForm'
import TransactionDetails, { type TransactionDetailsProps } from 'pages/accounting/TransactionDetails'

export interface TransactionDialogControllerProps {
  costsProps: CostsFormProps | null
  costTypeId?: number | null
  transactionProps: Omit<TransactionDetailsProps, 'transactionTypeId'> | null
  transactionTypeId: number | null
}

const GENERAL_TRANSACTION_TYPE_IDS: Set<number | null> = new Set([
  TransactionTypeId.JournalEntry,
  TransactionTypeId.Receivable,
  TransactionTypeId.Receive,
  TransactionTypeId.Payable,
  TransactionTypeId.Check,
  TransactionTypeId.PayBill
])

/**
 * @deprecated
 * This component is not needed anymore, since we now show CostForm only inside Costs Tab of Inventory.
 * In all other places we should use TransactionDetails component directly.
 */
const TransactionDialogController: FC<TransactionDialogControllerProps> = ({
  costsProps,
  transactionProps,
  transactionTypeId
}) => {
  const isGeneralTransactionType = GENERAL_TRANSACTION_TYPE_IDS.has(transactionTypeId)

  if ((transactionProps != null) && isGeneralTransactionType) {
    return (
      <TransactionDetails
        transactionTypeId={transactionTypeId}
        {...transactionProps}
      />
    )
  }

  /**
   * `TransactionTypeId.Cost` is removed on BE, and will be removed on FE in next PRs
   */
  if ((transactionTypeId === TransactionTypeId.Cost) && (costsProps != null)) {
    return <CostsForm {...costsProps} />
  }

  return null
}

export default observer(TransactionDialogController)
