import { css } from '@emotion/css'

export default css(`
  .button-new-campaign {
    font-size: 14px;
  }

  .submenu-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.18px;
  }

  .status-cell {
    text-align: center;

    .cf-header-cell-title {
      width: 100%;
      justify-content: center;
      padding: 0;
    }

    .cf-cell-wrapper {
      padding: 16px 0;
    }
  }

  .date-cell {
    .cf-cell-wrapper {

      .cf-multiline-datetime {
        .cf-table-cell-text {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.4px;
        }
      }
    }
  }

  .cf-table {
    .cf-table-cell {
      .cf-cell-wrapper {
        padding: 4px 8px;
        display: flex;
        align-items: center;
      }

      .cf-tooltip-container >p {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.4px;
      }
    }
    .cf-table-header-title {
      .cf-header-cell-title >p {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.4px;
      }
    }
  }

  .status-filter-dropdown {
    .cf-dropdown-root .cf-select-display-container {
      height: 32px;
      background: white;
      border: none;
      border-radius: 8px;
      padding: 8px;
      padding-right: 4px;
      display: flex;
      align-items: center;

      .cf-display {
        font-size: 14px;
        padding: 0;
        line-height: 20px;
        display: flex;
        align-items: center;
      }
    }

    .cf-end-adornment {
      display: flex;
    }
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;

    button {
      height: 40px;
    }
  }
`)

export const POPOVER_CLASS_NAME = css(`
  &.cf-popover-content { min-width: 248px; }

  .cf-dropdown-listbox { border-radius: 8px; }

  .cf-dropdown-option { 
    color: #101010;

    .counter {
      display: inline-block;
      margin-left: 8px;
      color: rgba(33, 33, 33, 0.5);
    }
  }
`)
