import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSvgAssignment: FC<IconProps> = ({
  width = 24,
  height = 24,
  color = '#101010'
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_68920_54986)">
        <path
          d="M19 2.99414H14.82C14.4 1.83414 13.3 0.994141 12 0.994141C10.7 0.994141 9.6 1.83414 9.18 2.99414H5C3.9 2.99414 3 3.89414 3 4.99414V18.9941C3 20.0941 3.9 20.9941 5 20.9941H19C20.1 20.9941 21 20.0941 21 18.9941V4.99414C21 3.89414 20.1 2.99414 19 2.99414ZM12 2.99414C12.55 2.99414 13 3.44414 13 3.99414C13 4.54414 12.55 4.99414 12 4.99414C11.45 4.99414 11 4.54414 11 3.99414C11 3.44414 11.45 2.99414 12 2.99414ZM14 16.9941H7V14.9941H14V16.9941ZM17 12.9941H7V10.9941H17V12.9941ZM17 8.99414H7V6.99414H17V8.99414Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_68920_54986">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSvgAssignment
