import type { FC } from 'react'
import { Modal, FormInput, FormNumberInput, FormDropdown, Banner } from '@carfluent/common'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import { type DictionaryItem } from 'api/types'
import { ValidationLength, MAX_PRICE_VALUE } from 'constants/validation'
import { COMMON_MODAL_FORM_CLS, MODAL_MIN_WIDTH } from 'constants/constants'

import {
  AccountFields,
  AccountFieldLabels,
  getWindowTitle,
  DISABLED_CATEGORY,
  ACCOUNT_NUMBER_MAX_LENGTH
} from './constants'
import { type UseAccountDetailsProps } from './hook/types'
import useAccountDetails from './hook'
import CLASS_NAME from './styles'

export type AccountDetailsProps = UseAccountDetailsProps

const AccountDetails: FC<AccountDetailsProps> = (props) => {
  const {
    categories,
    errors,
    isCategoriesDisabled,
    isEdit,
    isLoading,
    isModalOpen,
    isReadonly,
    onBlur,
    onCloseModal,
    onChange,
    onSubmit,
    onTypeChange,
    touched,
    types = [],
    values
  } = useAccountDetails(props)

  const renderFooter = (): JSX.Element => {
    return (
      <CancelSubmitActionsFooter
        isLoading={isLoading}
        onSubmit={onSubmit}
        onClose={onCloseModal}
        submitTitle={isEdit ? 'SAVE' : 'ADD'}
      />
    )
  }

  return (
    <Modal
      className={CLASS_NAME}
      isOpen={isModalOpen}
      onClose={onCloseModal}
      minWidth={MODAL_MIN_WIDTH}
      renderFooterActions={renderFooter}
      title={getWindowTitle(isEdit)}
    >
      <div>
        {isReadonly && (
          <Banner
            message='Note: This is a default account that is used for deal recap journal entry.'
            type='info'
          />
        )}

        <div className={COMMON_MODAL_FORM_CLS}>
          <FormNumberInput
            disabled={isReadonly}
            value={values[AccountFields.Number]}
            error={errors[AccountFields.Number]}
            touched={touched[AccountFields.Number]}
            max={MAX_PRICE_VALUE}
            id={AccountFields.Number}
            label={AccountFieldLabels.Number}
            onChange={onChange}
            onBlur={onBlur}
            dataTestId='number'
            thousandSeparator={null}
            maxLength={ACCOUNT_NUMBER_MAX_LENGTH}
          />

          <FormInput
            value={values[AccountFields.Name]}
            error={errors[AccountFields.Name]}
            touched={touched[AccountFields.Name]}
            className='cf-input-field'
            maxLength={ValidationLength.TEXT_FIELDS_MAX}
            id={AccountFields.Name}
            label={AccountFieldLabels.Name}
            onChange={onChange}
            onBlur={onBlur}
            dataTestId='name'
          />

          <FormDropdown
            className='cf-input-field'
            disabled={types.length === 0}
            id={AccountFields.Type}
            label={AccountFieldLabels.Type}
            options={types}
            value={values[AccountFields.Type]}
            error={errors[AccountFields.Type]}
            touched={touched[AccountFields.Type]}
            onChange={onTypeChange}
            onBlur={onBlur}
          />

          <FormDropdown<DictionaryItem>
            className='cf-input-field'
            disabled={isCategoriesDisabled}
            id={AccountFields.Category}
            label={AccountFieldLabels.Category}
            options={categories}
            onChange={onChange}
            onBlur={onBlur}
            value={values[AccountFields.Category]}
            isOptionDisabled={({ value }) => value.id === DISABLED_CATEGORY.id}
          />

          <FormInput
            className='g-full-width'
            value={values[AccountFields.Description]}
            error={errors[AccountFields.Description]}
            touched={touched[AccountFields.Description]}
            maxLength={ValidationLength.TEXT_FIELDS_MAX}
            id={AccountFields.Description}
            label={AccountFieldLabels.Description}
            onChange={onChange}
            onBlur={onBlur}
            dataTestId='description'
          />
        </div>
      </div>
    </Modal>
  )
}

export default AccountDetails
