import { css } from '@emotion/css'

export default css(`
  height: 214px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  letter-spacing: 0.4px;

  .history-table {
    &-header {
      gap: 10px;
      display: flex;
      font-size: 12px;
      line-height: 16px;
      padding-right: 6px;
      margin-bottom: 8px;
      flex-direction: row;
      letter-spacing: 0.4px;
      justify-content: space-between;

      &-item {
        text-align: left;
        color: rgba(33, 33, 33, 0.5);

        &:first-child {
          width: 94px;
          min-width: 81px;
        }

        &:nth-child(2) {
          width: 164px;
          min-width: 140px;
        }

        &:nth-child(3) {
          width: 134px;
          min-width: 114px;
        }

        &:last-child {
          width: 60px;
        }
      }
    }

    &-body {
      width: 100%;
      display: flex;
      overflow-y: auto;
      max-height: 190px;
      flex-direction: column;
      scrollbar-gutter: stable;
    }

    &-row {
      gap: 10px;
      width: 100%;
      height: 20px;
      display: flex;
      margin-bottom: 4px;
      align-items: center;
      justify-content: space-between;

      &-item {
        height: 20px;
        display: flex;
        color: #101010;
        font-size: 14px;
        text-align: left;
        font-weight: 400;
        overflow : hidden;
        line-height: 20px;
        white-space: nowrap;
        align-items: center;
        letter-spacing: 0.25px;
        text-overflow: ellipsis;

        .cf-tooltip-container p {
          color: #101010;
          font-size: 14px;
          text-align: left;
          font-weight: 400;
          line-height: 20px;
          white-space: nowrap;
          align-items: center;
          letter-spacing: 0.25px;
          text-overflow: ellipsis;
        }

        &:first-child {
          width: 94px;
          min-width: 81px;
        }

        &:nth-child(2) {
          width: 164px;
          min-width: 140px;
        }

        &:nth-child(3) {
          width: 134px;
          min-width: 114px;
        }

        &:last-child {
          width: 60px;
        }
      }
      &.no-price {
        font-size: 16px;
        color: #21212180;
        margin-top: 12px;
        line-height: 24px;
        letter-spacing: 0.15px;
        justify-content: center;
      }
    }
  }
`)
