import { type FC, useMemo } from 'react'

import { createStylesCls } from './styles'

export interface ProgressBarCircleProps {
  progressPercentage: number
  width?: number
  height?: number
  isUpdating?: boolean
  isCompleted?: boolean
}

const FAULT_SIZE = 2.5

const ProgressBarCircle: FC<ProgressBarCircleProps> = ({
  progressPercentage,
  width = 43,
  height = 43,
  isUpdating = false,
  isCompleted = false
}) => {
  const radius = width / 2
  const circumference = 2 * Math.PI * (radius - FAULT_SIZE)
  const progress = circumference - (progressPercentage / 100) * circumference
  const translateCheckedIcon = (radius / 2) - 1.25

  const className = useMemo(() => {
    return createStylesCls(progressPercentage)
  }, [progressPercentage])

  const progressBarContent = (
    isCompleted
      ? (
        <g transform={`translate(${translateCheckedIcon}, ${translateCheckedIcon})`}>
          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
            <g clipPath='url(#clip0_86012_90039)'>
              <path
                d='M9.00012 16.2003L5.50012 12.7003C5.11012 12.3103 4.49012 12.3103 4.10012 12.7003C3.71012 13.0903 3.71012 13.7103 4.10012 14.1003L8.29012 18.2903C8.68012 18.6803 9.31012 18.6803 9.70012 18.2903L20.3001 7.70031C20.6901 7.31031 20.6901 6.69031 20.3001 6.30031C19.9101 5.91031 19.2901 5.91031 18.9001 6.30031L9.00012 16.2003Z'
                fill='#219653'
              />
            </g>
            <defs>
              <clipPath id='clip0_86012_90039'>
                <rect width='24' height='24' fill='white' />
              </clipPath>
            </defs>
          </svg>
        </g>
        )
      : (
        <text
          className='progress-bar-circle-text'
          x={radius + 1.5}
          y={radius + 1}
          textAnchor='middle'
          dominantBaseline='middle'
        >
          {progressPercentage}%
        </text>
        )
  )

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <circle
        className='progress-bar-circle-background'
        cx={radius}
        cy={radius}
        r={radius - FAULT_SIZE}
      />

      <circle
        className='progress-bar-circle-unfilled'
        cx={radius}
        cy={radius}
        r={radius - FAULT_SIZE}
      />

      <circle
        className='progress-bar-circle-fill'
        cx={radius}
        cy={radius}
        r={radius - FAULT_SIZE}
        strokeDasharray={circumference}
        strokeDashoffset={progress}
      />

      {
        !isUpdating && progressBarContent
      }
    </svg>
  )
}

export default ProgressBarCircle
