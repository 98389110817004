import type { FC } from 'react'
import { Banner } from '@carfluent/common'
import EmploymentForm from './EmploymentForm'
import { isPreviousEmploymentOrHousingNeeded } from 'utils/creditApplication'
import type { BaseListItem } from 'types'
import { EmploymentStatus } from 'utils/creditApplication/enums'

import type { EmploymentDetailsProps } from '../../types'

const LENDERS_WORK_MESSAGE = 'Lenders typically require two years of work history'

const INCLUDED_PREV_EMPLOYMENT_STATUSES = [
  EmploymentStatus.FullTime,
  EmploymentStatus.SelfEmployed,
  EmploymentStatus.ActiveMilitary,
  EmploymentStatus.Other
]

const getPreviousEmploymentStatuses = (employmentStatuses: BaseListItem[]): BaseListItem[] => {
  return employmentStatuses.filter(({ id }) => INCLUDED_PREV_EMPLOYMENT_STATUSES.includes(id as EmploymentStatus))
}

const EmploymentDetails: FC<EmploymentDetailsProps> = ({
  pathPrefix,
  employmentStatuses,
  isFormDisabled,
  currentEmploymentDetails,
  previousEmploymentDetails,
  currentEmploymentDetailsErrors,
  previousEmploymentDetailsErrors,
  currentEmploymentDetailsTouched,
  previousEmploymentDetailsTouched,
  onBlur,
  onChange
}) => {
  const currentStartDate: Date | null = currentEmploymentDetails?.workingStartDate ?? null
  const employmentStatus: number | null = currentEmploymentDetails?.employmentStatus ?? null

  return (
    <div className='credit-app-section no-collapse employment-details'>
      <EmploymentForm
        employmentStatuses={employmentStatuses}
        isFormDisabled={isFormDisabled}
        pathPrefix={`${pathPrefix}.currentEmploymentDetails`}
        values={currentEmploymentDetails}
        touched={currentEmploymentDetailsTouched}
        errors={currentEmploymentDetailsErrors}
        isForPreviousPeriod={false}
        onChange={onChange}
        onBlur={onBlur}
      />

      {isPreviousEmploymentOrHousingNeeded(currentStartDate, employmentStatus) && (
        <>
          <Banner message={LENDERS_WORK_MESSAGE} />

          <EmploymentForm
            employmentStatuses={getPreviousEmploymentStatuses(employmentStatuses)}
            isFormDisabled={isFormDisabled}
            pathPrefix={`${pathPrefix}.previousEmploymentDetails`}
            values={previousEmploymentDetails}
            touched={previousEmploymentDetailsTouched}
            errors={previousEmploymentDetailsErrors}
            isForPreviousPeriod
            onChange={onChange}
            onBlur={onBlur}
          />
        </>
      )}
    </div>
  )
}

export default EmploymentDetails
