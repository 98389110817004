import { type FC } from 'react'
import { cnx, Button } from '@carfluent/common'

import OverlayedPageHOC from 'components/PageOverlay/OverlayedPageHOC'
import BackToTop from 'components/common/BackToTop'
import Table from 'components/common/Table'
import LienholderDetails from 'pages/settings/LienholderDetails'
import FiltersPanel from 'components/common/FiltersPanel'

import useLienholderList from './hook'
import CLASS_NAME from './styles'

const LienholderList: FC = () => {
  const {
    isLoading,
    search,
    emptyTableMessage,
    columns,
    rows,
    sorting,
    isNewLienholderModalOpen,
    selectedLienholderId,
    setSorting,
    onOpenNewLienholderModal,
    onSearch,
    onSearchChange,
    onBottomReached,
    onOpenLienholderDetails,
    onCloseNewLienholderModal,
    onAddLienholder,
    onDeleteLienholder
  } = useLienholderList()

  return (
    <div className={cnx('grey-background-page', CLASS_NAME)}>
      <FiltersPanel
        searchProps={{
          isLoading,
          onChange: onSearchChange,
          value: search,
          placeholder: 'Name, email, phone, corp code',
          withDeleteIcon: true,
          onSearch
        }}
        renderActions={() => (
          <Button
            className='btn-new-form-pack'
            onClick={onOpenNewLienholderModal}
          >
            New lienholder
          </Button>
        )}
      />

      <Table
        isLoading={isLoading}
        emptyTableMessage={emptyTableMessage}
        columns={columns}
        data={rows}
        sorting={sorting}
        onSortingChange={setSorting}
        onBottomReached={onBottomReached}
        onRowClick={onOpenLienholderDetails}
        headerDataTestId='form-packs-table-header'
        bodyDataTestId='form-packs-table-body'
      />

      <LienholderDetails
        lienholderId={selectedLienholderId}
        isOpen={isNewLienholderModalOpen}
        onClose={onCloseNewLienholderModal}
        onSubmit={onAddLienholder}
        onDeleteLienholder={onDeleteLienholder}
      />

      <BackToTop />
    </div>
  )
}

export default OverlayedPageHOC(LienholderList)
