import {
  type FocusEvent, type ReactNode, type KeyboardEvent,
  useCallback, useMemo, useRef
} from 'react'
import { UI } from '@carfluent/common'

import Input from 'components/common/Input'

import usePlacesAutocomplete from './hook'
import { FullAddressParts } from 'types/address'
import { areAddressesEqual, fullAddress } from 'utils/address'
import { containsTruthy } from 'utils/general'
import { onAfterAutocompleteAutofillInputFix } from 'utils/onAfterAutocompleteAutofillInputFix'
import { isKeyPrintable } from 'utils/keyCodeChecks'

const { Autocomplete } = UI

export type AddressError = Record<keyof FullAddressParts, string | null> | null

export type AddressValue<
  FreeSolo extends boolean | undefined = undefined
> = UI.AutocompleteFreeSoloValueMapping<FreeSolo> | FullAddressParts | null

export interface AddressAutocompleteProps<FreeSolo extends boolean | undefined = undefined> extends Partial<{
  className: string
  label: string
  disabled: boolean
  error: boolean | string | AddressError
  freeSolo: FreeSolo
  dataTestId?: string
  renderInput: (params: UI.AutocompleteRenderInputParams) => ReactNode
  onBlur: (evt: FocusEvent<HTMLDivElement>) => void
  onChange: (id: string, data: AddressValue<FreeSolo>) => void
  onBlurChange: (id: string, data: string) => void
  onKeyPress: (evt: KeyboardEvent<HTMLInputElement>) => void
}> {
  value?: AddressValue<FreeSolo>
  fullAddressValue?: FullAddressParts | null
  id: string
}

const AddressAutocomplete = <FreeSolo extends boolean | undefined = undefined>({
  value,
  fullAddressValue,
  id,
  disabled,
  onChange,
  onBlur,
  onBlurChange,
  onKeyPress,
  renderInput,
  label = 'Address',
  error,
  freeSolo,
  dataTestId
}: AddressAutocompleteProps<FreeSolo>): JSX.Element => {
  const refIsPasting = useRef(false)
  const refLastKeyPressed = useRef('')

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading
  } = usePlacesAutocomplete({ apiKey: process.env.REACT_APP_GOOGLE ?? '' })

  /**
   * @param _value - is always a FullAddressParts or null object because suggestions are full address objects.
   * freeSolo mode is an exclusion only to allow free input
   */
  const handleChange = useCallback((_evt, _value: AddressValue<FreeSolo>, reason: UI.AutocompleteChangeReason) => {
    if (areAddressesEqual(_value as FullAddressParts,
      freeSolo === true
        ? fullAddressValue
        : value as AddressValue<false>)
    ) {
      return
    }

    if (freeSolo === true && reason === 'clear') {
      onBlurChange?.(id, '')
    } else {
      onChange?.(id, _value)
    }
  }, [
    id, value, fullAddressValue,
    onChange, onBlurChange, freeSolo
  ])

  const handleBlur = useCallback((evt: FocusEvent<HTMLDivElement>, inputVal?: string) => {
    /**
     * freeSolo mode allows string as a valid value type
     * In freeSolo mode we need to trigger change of the string typed into input
     */
    if (freeSolo === true) {
      onBlurChange?.(id, inputVal ?? '')
    }
    onBlur?.(evt)
  }, [
    id, value, freeSolo,
    onBlur, onBlurChange
  ])

  const defaultRenderInput = useMemo(() => (params: UI.AutocompleteRenderInputParams): JSX.Element => {
    const err = containsTruthy(error)
    return <Input label={label} name={id} variant='filled' {...params} error={err} />
  }, [label, error, id])

  const filterOptions = useCallback((options: FullAddressParts[]) => options, [])

  /**
   * Seriazlises value to be rendered in input
   */
  const getOptionLabel = useCallback((option: AddressValue<FreeSolo>): string => {
    return freeSolo === true
      ? typeof option === 'string'
        ? option
        : option?.address ?? ''
      : fullAddress(option)
  }, [freeSolo])

  const handleInputChange = useCallback(async (_evt, value) => {
    await getPlacePredictions({ input: value })

    /**
     * we need to trigger fix only for autofill.
     * Current autofill detection based by event data is not reliable.
     * Some non printable keys like 'Backspace' or 'Delete' are also detected as autofill ->
     * which leads to the suggestions list forced closing.
     */

    if (refIsPasting.current || !isKeyPrintable(refLastKeyPressed.current)) {
      refIsPasting.current = false
      return
    }

    onAfterAutocompleteAutofillInputFix(
      _evt,
      (val: string) => {
        // we need to trigger onBlurChange to update field form state
        onBlurChange?.(id, val)
      }
    )
  }, [id, getPlacePredictions])

  const onInputPaste = (): void => {
    refIsPasting.current = true
  }

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>): void => {
    refLastKeyPressed.current = e.key
  }

  const handleFocus = useCallback(async (_evt) => {
    const inputValue = typeof value === 'string'
      ? value
      : fullAddress(value)

    await getPlacePredictions({ input: inputValue })
  }, [getPlacePredictions, value])

  const getOptionSelected = useCallback((option: FullAddressParts, value?: FullAddressParts) => {
    return areAddressesEqual(option, freeSolo === true ? fullAddressValue : value)
  }, [fullAddressValue, freeSolo])

  const renderOption = useCallback((option: FullAddressParts): ReactNode => {
    return <span>{fullAddress(option)}</span>
  }, [])

  return (
    <Autocomplete<FullAddressParts, false, false, FreeSolo>
      freeSolo={freeSolo}
      disabled={disabled}
      filterOptions={filterOptions} // required to show all place predictions
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      getOptionSelected={getOptionSelected}
      id={id}
      loading={isPlacePredictionsLoading}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
      onPaste={onInputPaste}
      onInputChange={handleInputChange}
      onKeyPress={onKeyPress}
      onKeyUp={onKeyUp}
      options={placePredictions}
      renderInput={renderInput ?? defaultRenderInput}
      formatValue={freeSolo === true ? fullAddress : undefined}
      value={value}
      data-test-id={dataTestId}
    />
  )
}

export default AddressAutocomplete
