import { type FC } from 'react'
import { CellWrapper, cnx } from '@carfluent/common'

import isVoidedTransaction from 'utils/accounting/isVoidedTransaction'
import TooltipedText from 'components/common/TooltipedText'
import CLASS_NAME from './styles'

export interface ReferenceData {
  referenceNumber: string | null
  transactionStateId?: number | null
}

interface ReferenceNumberCellProps {
  getValue: () => ReferenceData
}

const ReferenceNumberCell: FC<ReferenceNumberCellProps> = (props) => {
  const { referenceNumber, transactionStateId } = props.getValue()
  const isVoided = isVoidedTransaction((transactionStateId))

  return (
    <CellWrapper className={cnx(CLASS_NAME, 'cell-reference-number')}>
      {isVoided && (
        <span className='text-void-reference'>VOID</span>
      )}

      {!isVoided && (
        <TooltipedText
          rootClassName='text-reference-number'
          value={referenceNumber ?? ''}
        />
      )}
    </CellWrapper>
  )
}

export default ReferenceNumberCell
