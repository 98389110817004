import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  default: {
    textDecoration: 'none'
  },

  whiteBtn: {
    background: '#fff',
    border: '1px solid #000000',
    borderRadius: 12,
    color: '#101010',
    '&:hover': {
      background: '#fff',
      opacity: 0.5
    }
  }
})
