import { PRESET_LABELS, type Preset } from '@carfluent/common'
import {
  type DateType,
  type ReportAccountsAgingRecordDto,
  type ReportAccountsAgingTotalsDto
} from 'api/types'

export interface UseReportAccountsAgingProps {
  schedulesType: AccountAgingReportTypes
}

export interface AccountAgingReportFilters {
  period: DateType | null
}

export interface UseReportAccountsAgingReturn {
  rows: ReportAccountsAgingRecordDto[]
  initialPresetName: PRESET_LABELS | string
  isLoading: boolean
  onExportExcel: () => Promise<void>
  onChangeDatesFilter: (period: DateType) => Promise<void>
  presets: Preset[]
  title: string
  totals: ReportAccountsAgingTotalsDto | null
  search: string | null
  isReceivable: boolean
  onSearchChange: (value: string) => void
  onSearch: () => void
}

export enum AccountAgingReportTypes {
  Receivable = 'receivable',
  Payable = 'payable'
}

export enum RecordRangeIndex {
  Current = 0,
  One = 1,
  ThirtyOne = 2,
  SixtyOne = 3,
  NinetyOne = 4
}
