import type { AddCustomerPayload } from 'api/types'
import { isPersonalType } from 'utils/deals/workflowTypes'
import type { CustomerFormData } from './types'

const serializeAddCustomerForm = (data: CustomerFormData): AddCustomerPayload => {
  const { address, mailingAddress } = data
  const customerTypeSpecificPayload = isPersonalType(data.customerTypeId)
    ? {
        ssn: data.ssn ?? null,
        driverLicense: data.driverLicense ?? null,
        stateOfIssue: data.stateOfIssue?.abbreviation ?? null
      }
    : {
        ein: data.ein,
        businessName: data.businessName
      }

  const resolvedMailingAddress = data.isDifferentMailingAddress
    ? (mailingAddress ?? null)
    : null

  return {
    phoneNumber: data.phoneNumber ?? null,
    email: data.email ?? null,
    address: address?.address ?? address?.addressLong ?? null,
    city: address?.city ?? null,
    state: address?.state ?? null,
    zipCode: address?.zipCode ?? null,
    mailingAddress: resolvedMailingAddress?.address ?? null,
    mailingCity: resolvedMailingAddress?.city ?? null,
    mailingState: resolvedMailingAddress?.state ?? null,
    mailingZipCode: resolvedMailingAddress?.zipCode ?? null,
    customerTypeId: data.customerTypeId,
    firstName: data.firstName,
    lastName: data.lastName,
    ...customerTypeSpecificPayload
  }
}

export default serializeAddCustomerForm
