import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGForwardToInbox: FC<IconProps> = ({ color = 'rgba(33, 33, 33, 0.8)', width = 24, height = 24 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'>
      <g clipPath='url(#clip0_55238_93383)'>
        <path
          d='M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H13V18H4V8L10.94 12.34C11.59 12.75 12.41 12.75 13.06 12.34L20 8V13H22V6C22 4.9 21.1 4 20 4ZM12 11L4 6H20L12 11ZM19 16.21C19 15.76 19.54 15.54 19.85 15.86L22.64 18.65C22.84 18.85 22.84 19.16 22.64 19.36L19.85 22.15C19.54 22.46 19 22.24 19 21.79V20H16C15.45 20 15 19.55 15 19C15 18.45 15.45 18 16 18H19V16.21Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_55238_93383'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGForwardToInbox
