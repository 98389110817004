import { type CheckoutDetailsDto } from 'api/types/responses'
import { type DictionaryItem } from 'api/types/utility.types'

/**
 * FE-only enum.
 */
export enum DeliveryType {
  PickUp = 0,
  Delivery = 1
}

export type CheckoutModel = Omit<CheckoutDetailsDto, 'pickupDeliveryDate' | 'hasDelivery'> & {
  deliveryType: DictionaryItem | null
  pickupDeliveryDate: Date | null
  pickupDeliveryTime: string | null
}
