import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import IconSVG from 'components/inlineImages'

import CLASS_NAME from './styles'

export interface CoverageTemplateItemProps {
  isSelected: boolean
  onClick: () => void
  name: string
  imgSrc: string
}

const CoverageTemplateItem: FC<CoverageTemplateItemProps> = ({
  onClick,
  isSelected,
  name,
  imgSrc
}) => {
  return (
    <div
      onClick={onClick}
      className={cnx(CLASS_NAME, isSelected && 'selected')}
    >
      <p className='template-name'>
        {isSelected ? <IconSVG.CheckFilled /> : <div className='unchecked' />}

        {name}
      </p>

      <img
        className='template-img'
        src={imgSrc}
        alt='template_image'
      />
    </div>
  )
}

export default CoverageTemplateItem
