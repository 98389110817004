import type { DictionaryItem, WorkPlanEventDto } from 'api/types'

export const parseTableInfo = (
  leadStatuses: DictionaryItem[],
  events: WorkPlanEventDto[]
): Map<DictionaryItem, WorkPlanEventDto[]> => {
  const sortedRules = events.reduce((res: Record<number, WorkPlanEventDto[]>, item) => {
    if (item.leadStatusId == null) {
      return res
    }

    const statusRules = res[item.leadStatusId]
    res[item.leadStatusId] = statusRules != null ? [...statusRules, item] : [item]

    return res
  }, {})

  const res = leadStatuses.reduce((res: Map<DictionaryItem, WorkPlanEventDto[]>, item) => {
    res.set(item, sortedRules[item.id] != null ? sortedRules[item.id] : [])

    return res
  }, new Map())

  return res
}
