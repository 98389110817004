import { type FC, useState } from 'react'
import { Modal } from '@carfluent/common'
import { useNavigate } from 'react-router-dom'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import type { DealId } from 'types'
import { Routes } from 'constants/route_helper'

const MODAL_MIN_WIDTH = 680

interface DialogProps {
  dealId: DealId
  isOpen: boolean
  onConfirm: (dealId: DealId) => Promise<void>
  onClose: () => void
}

const DealDeleteModal: FC<DialogProps> = (props) => {
  const { dealId, isOpen = true, onClose, onConfirm } = props
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const { showAlert } = useCustomSnackbar()

  const onSubmit = async (): Promise<void> => {
    try {
      setIsLoading(true)
      await onConfirm(dealId)
      showAlert('Deal was canceled.', { variant: 'success' })
      navigate(Routes.Deals)
      onClose()
    } finally {
      setIsLoading(false)
    }
  }

  const renderFooter = (): JSX.Element => {
    return (
      <CancelSubmitActionsFooter
        isLoading={isLoading}
        onSubmit={onSubmit}
        onClose={onClose}
        submitTitle='yes, cancel'
        cancelTitle='no, cancel'
      />
    )
  }

  return (
    <Modal
      title='Cancel deal'
      isOpen={isOpen}
      onClose={onClose}
      renderFooterActions={renderFooter}
      minWidth={MODAL_MIN_WIDTH}
    >
      <p style={{ lineHeight: '24px' }}>
        Please note: canceled deal cannot be restored.
        If there is an associated deposit or downpayment, it will remain in the system.
      </p>
    </Modal>
  )
}

export default DealDeleteModal
