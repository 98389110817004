import { css } from '@emotion/css'

export default css(`  
  && {
    p {
      font-family: Roboto;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`)
