import type { FC, ChangeEvent } from 'react'
import { observer } from 'mobx-react-lite'
import MUICheckbox, { type CheckboxProps as MUICheckboxProps } from '@material-ui/core/Checkbox'

import IconSVG from 'components/inlineImages'
import { cnx } from '@carfluent/common'

export interface CheckboxProps extends MUICheckboxProps {
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  className?: string
}

const Checkbox: FC<CheckboxProps> = ({
  checked,
  onChange,
  classes,
  className,
  icon,
  checkedIcon,
  disabled,
  ...rest
}) => {
  return (
    <MUICheckbox
      {...rest}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      className={cnx(classes?.root, className)}
      checkedIcon={<IconSVG.CheckboxChecked isDisabled={disabled} />}
      icon={<IconSVG.CheckboxUnchecked isDisabled={disabled} />}
    />
  )
}

export default observer(Checkbox)
