import { type FC, useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps
} from 'recharts'
import { Loader } from '@carfluent/common'
import { type VehiclesByAgeDto, BucketDays } from 'api/types/responses'

interface InventoryChartProps {
  data: VehiclesByAgeDto[]
  isLoading: boolean
}

interface CustomTooltipProps extends TooltipProps<number, string> {}

const renderTickFormatter = (value: number): string => {
  switch (value) {
    case BucketDays.Bucket0To15Days:
      return '0-15'
    case BucketDays.Bucket16To30Days:
      return '16-30'
    case BucketDays.Bucket31To45Days:
      return '31-45'
    case BucketDays.Bucket46To60Days:
      return '46-60'
    case BucketDays.Bucket61To90Days:
      return '61-90'
    case BucketDays.Bucket91To120Days:
      return '91-120'
    case BucketDays.Bucket121PlusDays:
      return '121+'
    default:
      return ''
  }
}

const CustomTooltip: FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active != null && (payload != null) && (payload.length > 0)) {
    return (
      <div className='custom-tooltip'>
        {payload.map((item) => (
          <p key={item.name} className='label'>
            {item?.name}:&nbsp;
            <span className='value'>{item.value}</span>
          </p>
        ))}
        <p className='label'>Age (days): <span className='value'>{renderTickFormatter(label)}</span></p>
      </div>
    )
  }

  return null
}

const InventoryChart: FC<InventoryChartProps> = ({
  data,
  isLoading
}) => {
  const [hover, setHover] = useState(null)

  return (
    <>
      {isLoading && <Loader className='loader' size='large' />}
      {data.length === 0 && !isLoading && <div className='no-data'>No data yet</div>}
      {data.length > 0 && !isLoading && (
        <ResponsiveContainer width='100%' height={232} className='inventory-chart'>
          <BarChart
            data={data}
            margin={{
              top: 8,
              right: 0,
              left: -25,
              bottom: 0
            }}
          >
            <CartesianGrid
              strokeDasharray='0'
              vertical={false}
              stroke='#000'
              strokeOpacity={0.12}
            />
            <XAxis
              dataKey='ageBucket'
              axisLine={false}
              fill='#212121'
              fillOpacity='0.5'
              tickLine={false}
              tickFormatter={renderTickFormatter}
              padding={{ left: 8, right: 8 }}
              style={{ fontSize: '14px' }}
            />

            <YAxis
              axisLine={false}
              fill='#212121'
              fillOpacity='0.5'
              tickLine={false}
              style={{ fontSize: '14px' }}
            />
            <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
            <Bar
              dataKey='count'
              name='Cars'
              background={{ fill: '#F7FAFF', radius: 8 }}
              radius={8}
              maxBarSize={53}
              shape={(props) => {
                return (
                  <rect
                    {...props}
                    onMouseEnter={() => setHover(props.index)}
                    onMouseLeave={() => setHover(null)}
                    fill={hover === props.index ? '#3172D2' : '#458FFF'}
                    rx={8}
                    ry={8}
                  />
                )
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </>

  )
}

export default InventoryChart
