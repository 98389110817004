import { type Preset, UI, dates, PRESET_LABELS, DEFAULT_MIN_DATE, DEFAULT_MAX_DATE } from '@carfluent/common'
import addMonths from 'date-fns/addMonths'
import addQuarters from 'date-fns/addQuarters'
import fileNames from 'utils/reports/fileNames'

export const DEFAULT_SORTING: UI.SortingInfo = { id: 'date', order: 'desc' }

export const API_CALL_DELAY_SEARCH = 3000

export const presetLabels = [
  PRESET_LABELS.ALL,
  PRESET_LABELS.PREVIOUS_MONTH,
  PRESET_LABELS.PREVIOUS_QUARTER
]

export const getPresets = (): Preset[] => {
  const date = new Date()
  const defaultPresets = UI.getDefaultDateRangePresets(DEFAULT_MIN_DATE, DEFAULT_MAX_DATE)
  const allPeriod = { ...defaultPresets[0], title: 'All period' }
  const prevMonthStart = addMonths(dates.getMonthStart(date), -1)
  const prevMonthEnd = dates.getMonthEnd(prevMonthStart)
  const prevQuarterStart = addQuarters(dates.getQuarterStart(date), -1)
  const prevQuarterEnd = dates.getQuarterEnd(prevQuarterStart)

  const presets = [
    allPeriod,
    { name: PRESET_LABELS.PREVIOUS_MONTH, startDate: prevMonthStart, endDate: prevMonthEnd },
    { name: PRESET_LABELS.PREVIOUS_QUARTER, startDate: prevQuarterStart, endDate: prevQuarterEnd }
  ]

  return presets.filter(period => presetLabels.includes(period.name as PRESET_LABELS))
}

const amountOfLoadingRows = 8
export const LOADING_ROWS = Array(amountOfLoadingRows).fill({})
export const GROUP_ROW_FIXED_COL_SPAN = 4

export enum Messages {
  EmptyTableState = 'No results yet',
  NothingFoundState = 'No results found'
}

export const DEFAULT_SUM_VALUES = {
  totalSalePrice: 0,
  totalTradeInCredit: 0,
  totalServiceContract: 0,
  totalGap: 0,
  totalAftermarketsProduct: 0,
  totalCarDelivery: 0,
  totalDealerHandlingFee: 0,
  totalBuyerTag: 0,
  totalEmissions: 0,
  totalInspectionFee: 0,
  totalPlateTransferFee: 0,
  totalRegistrationFee: 0,
  totalTitleFee: 0,
  totalTaxableAmount: 0,
  totalAdditionalCosts: 0,
  totalTotalSalesTaxPerReport: 0,
  totalTotalSalesTaxPerDms: 0,
  totalDifference: 0,
  totalDealerInventoryTax: 0
}

export const REPORT_FILE_NAMES = fileNames('SalesTax')
