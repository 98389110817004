import type { UpdateCoverageRequestDto } from 'api/types'
import type { CoverageDefaultSection } from 'types/coverage'
import { type DealerDefaultDto } from 'api/types/responses'
import { type UpdateDealerCoverageDto } from 'api/types/requests'

const serializeCoverageDetails = (
  addedProducts: number[],
  dealId: string | number,
  rowVersion: string,
  updatedDealProducts: DealerDefaultDto[]
): UpdateCoverageRequestDto => {
  return {
    dealId,
    rowVersion,
    coverages: addedProducts.map(el => ({
      dealCoverageDefaultId: updatedDealProducts.find(product => product.productTypeId === el)?.id as number
    }))
  }
}

export const serializeDefaults = (data: CoverageDefaultSection[]): UpdateDealerCoverageDto[] => {
  return data.map(el => {
    const coverageId = (
      (el.forms.PEN?.id != null && el.forms.PEN.id !== 0)
        ? { id: el.forms.PEN.id }
        : (el.forms.Manual?.id != null && el.forms.Manual.id !== 0)
            ? { id: el.forms.Manual.id }
            : {}
    )

    return ({
      ...coverageId,
      productTypeId: el.productTypeId,
      cost: el.forms[el.coverageType]?.cost ?? 0,
      termMiles: el.forms[el.coverageType]?.termMiles ?? 0,
      termMonths: el.forms[el.coverageType]?.termMonths ?? 0,
      totalPrice: el.forms[el.coverageType]?.totalPrice ?? 0,
      providerId: el.forms[el.coverageType]?.providerId ?? 0,
      coverageName: el.forms[el.coverageType]?.coverageName ?? '',
      deductibleId: el.forms[el.coverageType]?.deductibleId ?? null,
      deductibleAmount: el.forms[el.coverageType]?.deductibleAmount ?? null
    })
  }).filter(el => el.providerId)
}

export default serializeCoverageDetails
