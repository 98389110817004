import type { FC } from 'react'
import { FormInput, FormNumberInput, FormMaskedInput, FormDropdown, FormDatePicker } from '@carfluent/common'

import { DATE_MONTH_YEAR_FORMAT } from 'constants/constants'
import { ValidationLength } from 'constants/validation'
import { EmploymentFields, isEmploymentFieldVisible } from 'utils/creditApplication'
import type { EmploymentDetailsFormProps } from '../../types'

const EmploymentForm: FC<EmploymentDetailsFormProps> = ({
  values,
  touched,
  errors,
  isFormDisabled,
  employmentStatuses,
  pathPrefix,
  isForPreviousPeriod,
  onChange,
  onBlur
}) => {
  if (values == null) {
    return null
  }

  const employmentStatus = employmentStatuses.find((item) => item.id === values.employmentStatus) ?? null

  return (
    <>
      <FormDropdown
        id={`${pathPrefix}.employmentStatus`}
        label='Employment status'
        disabled={isFormDisabled}
        value={employmentStatus}
        error={errors?.employmentStatus}
        touched={touched?.employmentStatus}
        onChange={(fieldId, val) => onChange(fieldId, val?.id)}
        onBlur={onBlur}
        options={employmentStatuses}
      />

      {isEmploymentFieldVisible(values.employmentStatus, EmploymentFields.JobTitle) && (
        <FormInput
          id={`${pathPrefix}.jobTitle`}
          label='Job title'
          value={values.jobTitle}
          error={errors?.jobTitle}
          touched={touched?.jobTitle}
          disabled={isFormDisabled}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}

      {isEmploymentFieldVisible(values.employmentStatus, EmploymentFields.EmployerName) && (
        <FormInput
          id={`${pathPrefix}.employerName`}
          label='Employer name'
          value={values.employerName}
          error={errors?.employerName}
          touched={touched?.employerName}
          disabled={isFormDisabled}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}

      {isEmploymentFieldVisible(values.employmentStatus, EmploymentFields.PhoneNumber) && (
        <FormMaskedInput
          id={`${pathPrefix}.employerPhoneNumber`}
          label='Employer phone number'
          mask='phone'
          value={values.employerPhoneNumber}
          error={errors?.employerPhoneNumber}
          touched={touched?.employerPhoneNumber}
          disabled={isFormDisabled}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}

      {isEmploymentFieldVisible(values.employmentStatus, EmploymentFields.StartDate) && (
        <FormDatePicker
          id={`${pathPrefix}.workingStartDate`}
          label='Start date'
          value={values.workingStartDate}
          error={errors?.workingStartDate}
          touched={touched?.workingStartDate}
          disabled={isFormDisabled}
          onChange={onChange}
          onBlur={onBlur}
          format={DATE_MONTH_YEAR_FORMAT}
        />
      )}

      {(isForPreviousPeriod && isEmploymentFieldVisible(values.employmentStatus, EmploymentFields.EndDate)) && (
        <FormDatePicker
          id={`${pathPrefix}.workingEndDate`}
          label='End date'
          value={values.workingEndDate}
          error={errors?.workingEndDate}
          touched={touched?.workingEndDate}
          disabled={isFormDisabled}
          onChange={onChange}
          onBlur={onBlur}
          format={DATE_MONTH_YEAR_FORMAT}
        />
      )}

      {isEmploymentFieldVisible(values.employmentStatus, EmploymentFields.Income) && (
        <FormNumberInput
          id={`${pathPrefix}.income`}
          label='Income'
          preset='financial'
          mode='integer'
          isNegativeAllowed={false}
          endAdornment='/year'
          value={values.income}
          error={errors?.income}
          touched={touched?.income}
          disabled={isFormDisabled}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={ValidationLength.INCOME_MAX_LENGTH}
        />
      )}
    </>
  )
}

export default EmploymentForm
