import { useCallback } from 'react'
import { useLoader } from '@carfluent/common'

import {
  type EntityId,
  type TransactionLineRow,
  type UseTransactionFormReturn
} from 'types'

import {
  type AccountingFiscalYearsResponse,
  type RecurringTransactionTemplatesDetails,
  type TransactionDetails as ApiTransactionDetails
} from 'api/types'

import AccountingApiProvider from 'api/accounting.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import parseError from 'utils/parseErrors'

import type { TransactionDetails, TransactionFormData } from './types'
import useSerializer from './useSerializer'
import { serializeRecurringTemplate } from './useSerializer/recurringTemplateSerializer'
import { parseTransactionTemplate } from './parser/recurringTemplateParser'
import getParser from './parser'
import { getRecurringIntervals } from './dictionaries'
import { getDefaultFormErrors } from './constants'

enum AlertMessages {
  FailedLoadTransaction = 'Failed to load journal entry data.',
  CreateTransactionSuccess = 'Journal entry created successfully.',
  DeleteTransactionSuccess = 'Journal entry deleted successfully.',
  UpdateTransactionSuccess = 'Journal entry updated successfully.',
  CreateRecurringTransactionSuccess = 'Recurring entry created successfully',
  UpdateRecurringTransactionSuccess = 'Recurring entry updated successfully',
  DeleteRecurringTransactionSuccess = 'Recurring entry deleted successfully',
}

export interface UseTransactionApiClientProps {
  originalTransaction: ApiTransactionDetails | null
  originalTemplate: RecurringTransactionTemplatesDetails | null
  rows: TransactionLineRow[]
  setCloseYearError: (error: string | null) => void
  setErrors?: UseTransactionFormReturn['setErrors']
  setFormValue: UseTransactionFormReturn['setValues']
  setLockedInfo: (data: AccountingFiscalYearsResponse | null) => void
  setOriginalTemplate: (data: RecurringTransactionTemplatesDetails) => void
  setOriginalTransaction: (data: ApiTransactionDetails) => void
  setParsedTransaction: (data: TransactionDetails) => void
  setRows: (rows: TransactionLineRow[]) => void
  transactionFormData: TransactionFormData
  transactionTypeId?: number | null
}

export interface UseTransactionApiClientReturn {
  isLoading: boolean
  createBankStatementMatch: (bankStatementId?: EntityId | null, isPrint?: boolean) => Promise<boolean>
  createTransaction: (dealId?: EntityId | null, isPrint?: boolean) => Promise<boolean>
  updateTransaction: (dealId?: EntityId | null, isPrint?: boolean) => Promise<boolean>
  createTransactionTemplate: () => Promise<boolean>
  updateTransactionTemplate: () => Promise<boolean>
  deleteTransaction: () => Promise<boolean>
  deleteTransactionTemplate: () => Promise<boolean>
  loadTransaction: (id: number) => Promise<TransactionDetails | null>
  loadTransactionTemplate: (id: number) => Promise<TransactionDetails | null>
  printCheckAsBankStatement: (id: EntityId) => Promise<boolean>
}

const useTransactionApiClient = ({
  originalTransaction,
  originalTemplate,
  rows,
  setCloseYearError,
  setErrors,
  setFormValue,
  setLockedInfo,
  setOriginalTemplate,
  setOriginalTransaction,
  setParsedTransaction,
  setRows,
  transactionFormData,
  transactionTypeId
}: UseTransactionApiClientProps): UseTransactionApiClientReturn => {
  const { showAlert, showSuccess } = useCustomSnackbar()
  const { isLoading, startLoader, stopLoader } = useLoader()
  // const { rows, setRows } = useContext(LinesTableStoreCTX)

  const serializeTransaction = useSerializer(transactionTypeId)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const createTransaction = useCallback(async (dealId?: EntityId | null, isPrint?: boolean): Promise<boolean> => {
    try {
      startLoader()
      const payload = serializeTransaction({ formData: transactionFormData, rows, dealId, isPrint })
      await AccountingApiProvider.createTransaction(payload)
      showSuccess(AlertMessages.CreateTransactionSuccess)
      return true
    } catch (err: any) {
      const errors = parseError(err)
      if (errors?.date != null) {
        setCloseYearError(errors?.date)
      }

      setErrors?.(getDefaultFormErrors(errors))

      return false
    } finally {
      stopLoader()
    }
  }, [
    transactionFormData, rows,
    serializeTransaction, setErrors,
    showSuccess, startLoader, stopLoader,
    setCloseYearError
  ])

  const createBankStatementMatch = useCallback(async (bankStatementId?: EntityId | null, isPrint?: boolean): Promise<boolean> => {
    try {
      if (bankStatementId == null) {
        return false
      }

      startLoader()
      const payload = serializeTransaction({ formData: transactionFormData, rows, isPrint })
      await AccountingApiProvider.createBankStatementMatch(bankStatementId, payload)
      showSuccess(AlertMessages.CreateTransactionSuccess)
      return true
    } catch (err: any) {
      const errors = parseError(err)
      if (errors?.date != null) {
        setCloseYearError(errors?.date)
      }

      setErrors?.(getDefaultFormErrors(errors))

      return false
    } finally {
      stopLoader()
    }
  }, [
    transactionFormData, rows,
    serializeTransaction, setErrors,
    showSuccess, startLoader, stopLoader,
    setCloseYearError
  ])

  const updateTransaction = useCallback(async (_, isPrint?: boolean): Promise<boolean> => {
    if (originalTransaction == null) {
      return false
    }

    try {
      startLoader()
      const payload = {
        ...serializeTransaction({ formData: transactionFormData, rows, isPrint }),
        id: originalTransaction.id
      }

      await AccountingApiProvider.updateTransaction(payload)
      showSuccess(AlertMessages.UpdateTransactionSuccess)
      return true
    } catch (err: any) {
      const errors = parseError(err)
      if (errors?.date != null) {
        setCloseYearError(errors?.date)
      }

      if (errors?.['transactionDto.Date'] != null) {
        setCloseYearError(errors?.['transactionDto.Date'])
      }

      setErrors?.(getDefaultFormErrors(errors))
      return false
    } finally {
      stopLoader()
    }
  }, [
    transactionFormData, rows, originalTransaction,
    setCloseYearError, serializeTransaction, showSuccess,
    startLoader, stopLoader, setErrors
  ])

  const deleteTransaction = useCallback(async (): Promise<boolean> => {
    if (originalTransaction == null) {
      return false
    }

    try {
      startLoader()
      await AccountingApiProvider.removeTransaction(originalTransaction.id)
      showSuccess(AlertMessages.DeleteTransactionSuccess)
      return true
    } catch (err) {
      showAlert(err)
      return false
    } finally {
      stopLoader()
    }
  }, [originalTransaction, startLoader, stopLoader])

  const loadTransaction = useCallback(async (id: number): Promise<TransactionDetails | null> => {
    try {
      startLoader()
      const transaction = await AccountingApiProvider.getTransaction(id)

      if (transaction?.isLocked) {
        const lockedInfo = transaction?.date != null
          ? await AccountingApiProvider.getLockedYearOrMonthByDate(transaction?.date)
          : null

        setLockedInfo(lockedInfo)
      }

      const parseTransaction = getParser(transaction.transactionTypeId)
      const parsedTransaction = parseTransaction(transaction)
      const {
        bankStatements,
        clearedAccounts,
        isSystemCreated,
        rows,
        reconciledAccounts,
        transactionStateId,
        ...transactionFormData
      } = parsedTransaction

      setOriginalTransaction(transaction)
      setFormValue(transactionFormData)
      setParsedTransaction(parsedTransaction)
      setRows(rows)

      return parsedTransaction
    } catch (err) {
      showAlert(AlertMessages.FailedLoadTransaction)
      return null
    } finally {
      stopLoader()
    }
  }, [
    setFormValue,
    setRows,
    startLoader,
    stopLoader,
    setOriginalTransaction,
    getParser,
    setParsedTransaction
  ])

  const createTransactionTemplate = useCallback(async (dealId?: EntityId | null): Promise<boolean> => {
    try {
      startLoader()

      const payload = serializeRecurringTemplate({
        formData: transactionFormData,
        rows,
        dealId,
        transactionTypeId
      })

      await AccountingApiProvider.createRecurringTransactionTemplates(payload)
      showSuccess(AlertMessages.CreateRecurringTransactionSuccess)
      return true
    } catch (err: any) {
      const errors = parseError(err)
      if (errors?.endDate != null) {
        if (errors?.endDate.includes('Unable to create journal entry')) {
          setCloseYearError(errors?.endDate)
        }
      } else if (errors.startDate != null) {
        if (errors.startDate.includes('Unable to create journal entry')) {
          setCloseYearError(errors?.startDate)
        }
      } else {
        setErrors?.(getDefaultFormErrors(errors))
      }

      return false
    } finally {
      stopLoader()
    }
  }, [
    transactionFormData, rows,
    setCloseYearError, setErrors, showSuccess,
    startLoader, stopLoader, transactionTypeId
  ])

  const loadTransactionTemplate = useCallback(async (id: number): Promise<TransactionDetails | null> => {
    try {
      startLoader()
      const transaction = await AccountingApiProvider.getRecurringEntriesItem(id)
      const { items: intervals } = await getRecurringIntervals()
      if (transaction?.templateTransaction?.isLocked ?? false) {
        const lockedInfo = transaction?.templateTransaction.date != null
          ? await AccountingApiProvider.getLockedYearOrMonthByDate(transaction?.templateTransaction.date)
          : null
        setLockedInfo(lockedInfo)
      }

      const parsedTransaction = parseTransactionTemplate(transaction, intervals)
      const { rows, ...transactionFormData } = parsedTransaction

      setOriginalTemplate(transaction)
      setFormValue(transactionFormData)
      setRows(rows)

      return parsedTransaction
    } catch (err) {
      showAlert(AlertMessages.FailedLoadTransaction)
      return null
    } finally {
      stopLoader()
    }
  }, [
    startLoader,
    setFormValue,
    setRows,
    setLockedInfo,
    showAlert,
    stopLoader,
    setOriginalTemplate
  ])

  const updateTransactionTemplate = useCallback(async (): Promise<boolean> => {
    if (originalTemplate == null) {
      return false
    }

    try {
      startLoader()

      const payload = {
        ...serializeRecurringTemplate({ formData: transactionFormData, rows }),
        id: Number(originalTemplate.id)
      }

      await AccountingApiProvider.updateRecurringTransactionTemplates(payload)
      showSuccess(AlertMessages.UpdateRecurringTransactionSuccess)
      return true
    } catch (err: any) {
      const errors = parseError(err)
      if (errors?.endDate != null) {
        if (errors?.endDate.includes('Unable to create journal entry')) {
          setCloseYearError(errors?.endDate)
        }
      } else if (errors.startDate != null) {
        if (errors.startDate.includes('Unable to create journal entry')) {
          setCloseYearError(errors?.startDate)
        }
      } else {
        setErrors?.(getDefaultFormErrors(errors))
      }

      return false
    } finally {
      stopLoader()
    }
  }, [
    originalTemplate, startLoader, transactionFormData,
    rows, showSuccess, setErrors,
    setCloseYearError, stopLoader
  ])

  const deleteTransactionTemplate = useCallback(async (): Promise<boolean> => {
    if (originalTemplate == null) {
      return false
    }

    try {
      startLoader()
      await AccountingApiProvider.removeTransactionTemplate(originalTemplate.id)
      showSuccess(AlertMessages.DeleteRecurringTransactionSuccess)
      return true
    } catch (err) {
      showAlert(err)
      return false
    } finally {
      stopLoader()
    }
  }, [originalTemplate, showAlert, showSuccess, startLoader, stopLoader])

  const printCheckAsBankStatement = useCallback(async (id: EntityId): Promise<boolean> => {
    try {
      startLoader()
      await AccountingApiProvider.printTransaction(id)
      return true
    } catch (err) {
      showAlert(err)
      return false
    } finally {
      stopLoader()
    }
  }, [startLoader, showAlert, stopLoader])

  // ========================================== //

  return {
    isLoading,
    createBankStatementMatch,
    createTransaction,
    createTransactionTemplate,
    deleteTransaction,
    deleteTransactionTemplate,
    updateTransaction,
    updateTransactionTemplate,
    loadTransaction,
    loadTransactionTemplate,
    printCheckAsBankStatement
  }
}

export default useTransactionApiClient
