import { UI } from '@carfluent/common'

import { BankStatementTransactionState, type BankStatementTransactionsTotal } from 'api/types'
import { GET_DEFAULT_FILTERS } from 'constants/accounting'
import { type BankStatementsListFilters } from './types'

export const DEFAULT_SORTING: UI.SortingInfo = { id: 'date', order: 'desc' }
export const DEFAULT_FILTERS: BankStatementsListFilters = GET_DEFAULT_FILTERS()

export const DEFAULT_COUNTERS: BankStatementTransactionsTotal = {
  forReview: 0,
  categorized: 0,
  excluded: 0
}

export const API_CALL_DELAY_SEARCH = 2000

export enum Messages {
  CategorizeSuccess = 'Moved to Categorized tab successfully.',
  UndoExcludeSuccess = 'Moved to For review tab successfully.',
  EmptyTableState = 'No transactions yet',
  NothingFoundState = 'No records found'
}

export const TABS = [
  { id: BankStatementTransactionState.ForReview, title: 'For review' },
  { id: BankStatementTransactionState.Categorized, title: 'Categorized' },
  { id: BankStatementTransactionState.Excluded, title: 'Excluded' }
]

export const TabCounterIds: Array<keyof BankStatementTransactionsTotal> = [
  'forReview',
  'categorized',
  'excluded'
]
