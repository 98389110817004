import { type UseFormReturn, type Dropdown2Props } from '@carfluent/common'

import {
  type AccountListItem,
  type AccountListPayload,
  type ControlListItem,
  type EntityListItem,
  type PaginatedResult
} from 'api/types'

export enum ReviewCategory {
  Categorize = 'Categorize',
  FindMatch = 'FindMatch',
  RecordAsTransfer = 'RecordAsTransfer',
  RecordAsCreditCard = 'RecordAsCreditCard'
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ErrTouchShortcuts = {
  account: true
  entity: true
  control: true
}

export interface FormViewProps extends UseFormReturn<ReviewFormData, ErrTouchShortcuts> {
  getAccounts: (payload: AccountListPayload) => Promise<PaginatedResult<AccountListItem>>
  isDeposit: boolean
  onClickExclude: () => void
  onClickFindMatch: () => void
  reviewCategory: ReviewCategory | string
  entityActions: Dropdown2Props<EntityListItem, false>['actions']
}

export interface ReviewFormData {
  account: AccountListItem | null
  control: ControlListItem | null
  description: string
  entity: EntityListItem | null
  referenceNumber: string
}
