import type { FC } from 'react'
import { CellWrapper, formatCurrencyDecimal } from '@carfluent/common'
import { type BasicValueCellProps } from 'types'
import TooltipedText from 'components/common/TooltipedText'

const PriceCell: FC<BasicValueCellProps> = (props) => {
  const value = parseFloat(`${props.getValue() as number | string}`)

  return (
    <CellWrapper>
      <TooltipedText
        value={formatCurrencyDecimal(value)}
      />
    </CellWrapper>
  )
}

export default PriceCell
