import type { FC } from 'react'
import { Droppable } from 'react-beautiful-dnd'

import type { ReconStepsDroppableTableProps } from 'pages/reconditioning/Settings/Steps/components/ReconStepsTable/types'
import DraggableRow from '../DraggableRow'

import { CLASS_NAME } from './styles'

const DroppableTable: FC<ReconStepsDroppableTableProps> = ({
  values,
  onOpenDeleteStepModal,
  ...formProps
}) => {
  const {
    touched,
    errors,
    onBlur,
    onChange,
    dealerAccounts
  } = formProps

  return (
    <Droppable droppableId='reconSteps'>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className={CLASS_NAME}>
          {values.reconSteps.map((item, index) => (
            <DraggableRow
              key={index}
              item={item}
              index={index}
              touched={touched.reconSteps[index]}
              errors={errors.reconSteps[index]}
              onChange={onChange}
              onBlur={onBlur}
              onOpenDeleteStepModal={onOpenDeleteStepModal}
              dealerAccounts={dealerAccounts}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default DroppableTable
