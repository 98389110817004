import { type DictionaryItem, TaskAssigneeType } from 'api/types'

import type { CrmSettingsFormData, ParsedCrmSettingsProps, UserItem } from './types'
import { getDurationUnit, transformMinutesByDuration } from './utils'
import {
  OWNER_OPTION,
  DURATION_OPTIONS,
  ROUND_ROBIN_OPTION,
  ROUND_ROBIN_BDC_OPTION,
  ROUND_ROBIN_SALES_OPTION, REMIND_TIME_OPTIONS, LEAD_OWNER_RECIPIENTS_OPTION, APPOINTMENT_RECIPIENTS_ASSIGNEE_OPTION
} from './constants'
import type { DeepRequired } from '@carfluent/common'

interface ParseAssigneeProps {
  id: number | null
  taskId: number | null
  users: DictionaryItem[]
}

const parseAssignee = ({
  id,
  taskId,
  users
}: ParseAssigneeProps): DictionaryItem | null => {
  if (taskId === TaskAssigneeType.User && id != null) {
    return users.find((user) => user.id === id) ?? ROUND_ROBIN_OPTION
  }

  switch (taskId) {
    case TaskAssigneeType.Owner:
      return OWNER_OPTION
    case TaskAssigneeType.RoundRobin:
      return ROUND_ROBIN_OPTION
    case TaskAssigneeType.RoundRobinBDC:
      return ROUND_ROBIN_BDC_OPTION
    case TaskAssigneeType.RoundRobinSales:
      return ROUND_ROBIN_SALES_OPTION
    default:
      return ROUND_ROBIN_OPTION
  }
}

export const parseCrmSettings = ({
  bdcSalesUsersList,
  emailTemplates,
  messageTemplates,
  userIds,
  roundRobinEnabled,
  roundRobinIntervalMinutes,
  autoReplyEnabled,
  vehicleAvailableEmailTemplateId,
  vehicleAvailableMessageTemplateId,
  vehicleUnavailableEmailTemplateId,
  vehicleUnavailableMessageTemplateId,
  allUsers,
  webLeadAssignedUserId,
  webLeadTaskAssigneeTypeId,
  messageReceivedAssignedUserId,
  messageReceivedTaskAssigneeTypeId,
  emailReceivedAssignedUserId,
  emailReceivedTaskAssigneeTypeId,
  creditAppAssignedUserId,
  creditAppTaskAssigneeTypeId,
  creditAppTasksEnabled,
  isEmailOnPriceDropEnabled,
  emailOnPriceDropEmailTemplateId,
  emailOnPriceDropThreshold,
  isMessageOnPriceDropEnabled,
  messageOnPriceDropThreshold,
  messageOnPriceDropEmailTemplateId,
  salesAppReminderEnabled,
  salesAppReminderMinutes,
  customerAppReminderEnabled,
  customerAppReminderMinutes,
  customerAppReminderMessageTemplateId,
  appointmentReminderUsers,
  appReminderSendToLeadOwner,
  appReminderSendToAssignee
}: ParsedCrmSettingsProps): DeepRequired<CrmSettingsFormData> => {
  const roundRobinUsers = bdcSalesUsersList.map((user) => {
    if (userIds?.includes(user.id)) {
      return { ...user, checked: true }
    }

    return { ...user, checked: false }
  })

  const emailOnPriceDropEmailTemplate = emailTemplates.find((el) =>
    el.id === emailOnPriceDropEmailTemplateId) ?? null
  const messageOnPriceDropEmailTemplate = messageTemplates.find((el) =>
    el.id === messageOnPriceDropEmailTemplateId) ?? null
  const vehicleAvailableEmailTemplate = emailTemplates.find((el) =>
    el.id === vehicleAvailableEmailTemplateId) ?? null
  const vehicleUnavailableEmailTemplate = emailTemplates.find((el) =>
    el.id === vehicleUnavailableEmailTemplateId) ?? null
  const vehicleAvailableMessageTemplate = messageTemplates.find((el) =>
    el.id === vehicleAvailableMessageTemplateId) ?? null
  const vehicleUnavailableMessageTemplate = messageTemplates.find((el) =>
    el.id === vehicleUnavailableMessageTemplateId) ?? null
  const customerAppReminderMessageTemplate = messageTemplates.find((el) =>
    el.id === customerAppReminderMessageTemplateId) ?? null

  const _salesAppReminderMinutes = REMIND_TIME_OPTIONS.find(({ id }) => id === salesAppReminderMinutes) ?? null
  const _customerAppReminderMinutes = REMIND_TIME_OPTIONS.find(({ id }) => id === customerAppReminderMinutes) ?? null

  const duration = getDurationUnit(roundRobinIntervalMinutes)
  const transformedIntervalMinutes = transformMinutesByDuration(duration, roundRobinIntervalMinutes, true)
  const _appointmentReminderUsers = [
    ...(appReminderSendToAssignee ? [APPOINTMENT_RECIPIENTS_ASSIGNEE_OPTION] : []),
    ...(appReminderSendToLeadOwner ? [LEAD_OWNER_RECIPIENTS_OPTION] : []),
    ...allUsers.reduce<UserItem[]>((res, user) => {
      const isUserExist = appointmentReminderUsers?.find(({ id }) => id === user.id) != null

      if (isUserExist) {
        return [...res, {
          id: user.id,
          name: user.name,
          phoneNumber: user.phoneNumber
        }]
      }

      return res
    }, [])
  ]

  return {
    userIds: roundRobinUsers,
    roundRobinEnabled,
    roundRobinIntervalMinutes: transformedIntervalMinutes,
    duration: DURATION_OPTIONS.find(({ id }) => id === duration) ?? DURATION_OPTIONS[0],
    autoReplyEnabled,
    vehicleAvailableEmailTemplate,
    vehicleAvailableMessageTemplate,
    vehicleUnavailableEmailTemplate,
    vehicleUnavailableMessageTemplate,
    creditAppTasksEnabled,
    creditAppAssignedUserId: parseAssignee({
      id: creditAppAssignedUserId,
      taskId: creditAppTaskAssigneeTypeId,
      users: allUsers
    }),
    webLeadAssignedUserId: parseAssignee({
      id: webLeadAssignedUserId,
      taskId: webLeadTaskAssigneeTypeId,
      users: allUsers
    }),
    messageReceivedAssignedUserId: parseAssignee({
      id: messageReceivedAssignedUserId,
      taskId: messageReceivedTaskAssigneeTypeId,
      users: allUsers
    }),
    emailReceivedAssignedUserId: parseAssignee({
      id: emailReceivedAssignedUserId,
      taskId: emailReceivedTaskAssigneeTypeId,
      users: allUsers
    }),
    isEmailOnPriceDropEnabled,
    emailOnPriceDropEmailTemplate,
    emailOnPriceDropThreshold,
    isMessageOnPriceDropEnabled,
    messageOnPriceDropThreshold,
    messageOnPriceDropEmailTemplate,
    salesAppReminderEnabled,
    salesAppReminderMinutes: _salesAppReminderMinutes,
    appointmentReminderUsers: _appointmentReminderUsers as Array<DeepRequired<UserItem>>,
    customerAppReminderEnabled,
    customerAppReminderMinutes: _customerAppReminderMinutes,
    customerAppReminderMessageTemplate
  }
}
