import { useCallback, useContext, useMemo } from 'react'

import { type RequestVehicleId } from 'api/types'
import AccountingApiProvider from 'api/accounting.api'
import useAsyncEffect from 'hooks/useAsyncEffect'
import CostsCTX from 'store/costs'
import SettingsCTX from 'store/settings'

import { parseData } from './parser'
import { type UseCostsSummaryReturn } from './types'

export type { UseCostsSummaryReturn, CostSummaryListItem } from './types'

const useCostsSummary = (vehicleId: RequestVehicleId): UseCostsSummaryReturn => {
  const { isAccountingEnabled } = useContext(SettingsCTX)
  const { costsSummary, setCostsSummary } = useContext(CostsCTX)

  const getSummary = useCallback(async () => {
    if (vehicleId == null) {
      return
    }

    try {
      const res = await AccountingApiProvider.getCostsSummary(vehicleId)

      setCostsSummary(res.items)
    } catch {
      console.error('Fetching costs summary error')
    }
  }, [vehicleId])

  useAsyncEffect(async () => {
    if (isAccountingEnabled) {
      await getSummary()
    }
  }, [getSummary, isAccountingEnabled])

  return useMemo(() => ({
    items: parseData(costsSummary),
    getSummary
  }), [costsSummary, getSummary])
}

export default useCostsSummary
