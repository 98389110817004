import { css } from '@emotion/css'
import { px, ScreenWidthBreakpoints } from 'constants/constants'

export default css(`
  display: flex;
  height: 130px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;

  img {
    margin-bottom: 8px;
      
    :hover {
      opacity: 0.8;
    }
  }
    
  .vertical-divider {
    width: 1px;
    height: 98px;
    margin: 16px 0;
    background-color: rgba(0, 0, 0, 0.12);
  }

  .schedule-section, .contact-section, .log-section {
    padding: 16px;

    @media screen and (min-width: ${px(ScreenWidthBreakpoints.Medium)}) {
      padding: 16px 28px;
    }

    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .schedule-section, .log-section {
    width: 348px;
    height: 130px;
  }

  .contact-section {
    width: 232px;
  }

  .buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .title {
    font-weight: 500;
    line-height: 24px;
    color: #101010;
  }

  .caption {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #101010;
  }

  .disabled-button {
    opacity: .4;
  }
  
`)

export const TOOLTIP_CLASS_NAME = css(`
  && {
    margin-bottom: 4px; 
    min-width: 137px;
    white-space: nowrap;
  }
`)
