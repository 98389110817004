import type { FC } from 'react'
import { utils } from '@carfluent/common'

import TopPanelActions from 'components/common/TopPanelActions'
import { ImageItem } from 'components/inventory/ImageItem'
import DragHereMessage from 'components/common/DragHereMessage'
import NoVehicleImagesText from 'components/inventory/NoVehicleImagesText'
import InspectionGeneralBlock from 'components/reconditioning/InspectionGeneralBlock'

import Timeline from './components/Timeline'
import InfoPanel from './components/InfoPanel'
import HistoryTable from './components/HistoryTable'
import GalleryModal from './components/GalleryModal'
import TextSkeleton from 'components/common/Skeletons/TextSkeleton'
import DropzoneContentLoaderSkeleton from './components/DropzoneContentLoaderSkeleton'

import FormFilesUpload, { type FormFilesUploadValue } from 'components/common/FormFilesUpload'

import { useReconDetails } from './hook'
import CLASS_NAME from './styles'

const FILE_EXTENSIONS = ['jpeg', 'jpg', 'png']
const EMPTY_IMAGE_VALUE: FormFilesUploadValue = []

const ReconDetails: FC = () => {
  const {
    onGoBack,
    dropzone: {
      onAddFiles,
      maxFiles,
      disabled
    },
    details,
    onUpdateFavorite,
    onUpdateStep,
    isStepLoading,
    historyProps,
    timelineProps,
    images,
    onDeletePhoto,
    selected,
    setSelected,
    deletingItemIdx,
    isGalleryOpen,
    setIsGalleryOpen,
    uploadImageError,
    generalBlockData,
    initLoading
  } = useReconDetails()

  return (
    <div className={CLASS_NAME}>
      <TopPanelActions
        backButtonName='Back'
        className='cf-recon-top-bar'
        onGoBack={onGoBack}
      />

      <div className='cf-details-layout'>
        <div className='cf-details-info-side'>
          <InfoPanel
            {...details}
            initLoading={initLoading}
            onUpdateFavorite={onUpdateFavorite}
          />

          <HistoryTable
            historyRows={historyProps.historyRows}
            createdDate={historyProps.createdDate}
            reconStep={details.reconStep}
            onUpdateStep={onUpdateStep}
            initLoading={initLoading}
            isStepLoading={isStepLoading}
          />

          <div className='cf-images-block'>
            {
              initLoading
                ? <TextSkeleton />
                : <p className='cf-images-block-title'>Media</p>
            }

            <FormFilesUpload
              id='files'
              acceptedExtensions={null}
              maxFileSize={utils.UNLIMITED_FILES_SIZE}
              maxFileNumber={maxFiles}
              maxFilesTotalSize={utils.UNLIMITED_FILES_SIZE}
              onChange={(_, photos) => {
                void onAddFiles?.(photos as File[])
              }}
              disabled={disabled}
              suggestedExtensions={FILE_EXTENSIONS}
              value={EMPTY_IMAGE_VALUE}
              dragHereMessage={<DragHereMessage />}
              errorMessage={uploadImageError}
              showError={uploadImageError != null && uploadImageError !== ''}
            >
              {
                initLoading
                  ? <DropzoneContentLoaderSkeleton />
                  : (
                    <div className='dropzone-content'>
                      {(images.length > 0)
                        ? (
                          <div className='cf-image-wrapper'>
                            {images.map((item, i) => {
                              const onClick = (): void => {
                                setSelected(i)
                                setIsGalleryOpen(true)
                              }

                              return (
                                <ImageItem
                                  isDeleting={deletingItemIdx === i}
                                  onClick={onClick}
                                  onDelete={(): void => { void onDeletePhoto(i) }}
                                  key={item.thumbnailUrl ?? item.originalUrl}
                                  image={item}
                                />
                              )
                            })}
                          </div>
                          )
                        : <NoVehicleImagesText />}
                    </div>
                    )
              }
            </FormFilesUpload>
          </div>
        </div>

        <div>
          <InspectionGeneralBlock
            {...generalBlockData}
          />

          <Timeline {...timelineProps} />
        </div>
      </div>

      <GalleryModal
        images={images.map(({ originalUrl, thumbnailUrl }) => (originalUrl ?? thumbnailUrl))}
        selected={selected}
        setSelected={setSelected}
        isOpen={isGalleryOpen}
        onToggle={setIsGalleryOpen}
      />
    </div>
  )
}

export default ReconDetails
