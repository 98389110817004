import { type FC, useState, useRef } from 'react'
import { PopoverV2 } from '@carfluent/common'

import CLASS_NAME from './styles'

interface TransactionLinkProps {
  title: string | null
  onClick: () => void
}

const TransactionLink: FC<TransactionLinkProps> = ({ onClick, title }) => {
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <div
        className={CLASS_NAME}
        onClick={onClick}
        ref={anchorEl}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <p>{title}</p>
      </div>
      <PopoverV2
        isOpen={isOpen}
        anchorEl={anchorEl}
        className={CLASS_NAME}
      >
        {title}
      </PopoverV2>
    </>

  )
}

export default TransactionLink
