import { type FC, type MouseEvent, useRef, useState } from 'react'
import { cnx, useLoader, Loader, PopoverV2 } from '@carfluent/common'
import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

interface UndoActionCellProps {
  disabled: boolean
  isReconciled: boolean
  executeUndo: () => Promise<void>
}

const UndoActionCell: FC<UndoActionCellProps> = ({ executeUndo, disabled, isReconciled }) => {
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const { isLoading, startLoader, stopLoader } = useLoader()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const blockClickPropagation = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation()
  }

  const onClickUndo = (): void => {
    const runUndo = async () => {
      startLoader()
      await executeUndo()
      stopLoader()
    }

    void runUndo()
  }

  // ========================================== //

  return (
    <>
      <div
        ref={anchorEl}
        className={cnx(CLASS_NAME, disabled && 'btn-disabled')}
        onClick={blockClickPropagation}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        {isLoading && (<Loader size='medium' color='dark' />)}
        {!isLoading && (<div className='link-button' onClick={onClickUndo}>Undo</div>)}
      </div>

      <PopoverV2
        isOpen={disabled && isOpen}
        anchorEl={anchorEl}
        className={POPOVER_CLASS_NAME}
        options={{
          placement: 'bottom-end'
        }}
      >
        {isReconciled
          ? (<>This transaction was reconciled.</>)
          : (
            <>
              This date is locked in accounting.<br />
              Unlock it in accounting settings if needed.
            </>)}

      </PopoverV2>
    </>
  )
}

export default UndoActionCell
