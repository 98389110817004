import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import InfoField from 'components/common/InfoField'
import { formatPhoneNumberForView, joinPartsBySpace, joinPartsToStr } from 'utils/view_helper'
import LeadLabel from 'components/common/LeadLabel'
import NameWithAvatar from 'components/common/NameWithAvatar'

import { type LeadDetailsProps } from './types'
import CLASS_NAME from './styles'

const LeadDetailsPanel: FC<LeadDetailsProps> = ({
  className,
  lead,
  onCopy,
  sources,
  temperatures
}) => {
  const address = joinPartsToStr(', ', lead.address, joinPartsBySpace(lead.city, lead.state), lead.zipCode)

  const leadTemperature = temperatures
    .find((el) => el.id === lead.leadTemperatureId)?.name
    ?.split(' ')[0] ?? ''

  const assignedUserName = (): string => {
    if (lead.assignedUserId != null && lead.assignedUser != null) {
      if (lead?.assignedUser.firstName != null && lead?.assignedUser.lastName != null) {
        return `${lead?.assignedUser.firstName} ${lead?.assignedUser.lastName}`
      } else {
        return lead?.assignedUser?.email ?? ''
      }
    }
    return 'Unassigned'
  }

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, 'lead-details', className)}>
      <InfoField
        label='Phone number'
        value={(lead.phoneNumber != null)
          ? <a href={`tel:${lead.phoneNumber}`}>{formatPhoneNumberForView(lead.phoneNumber)}</a>
          : '-'}
        isCopied={lead.phoneNumber != null}
        onCopy={onCopy}
        nameOfField='Phone'
        className='lead-details-phone-number'
      />

      <InfoField
        label='Label'
        value={<LeadLabel value={leadTemperature}/>}
      />

      <InfoField
        label='Email'
        value={lead.email}
        isCopied={lead.email != null}
        onCopy={onCopy}
        nameOfField='Email'
      />

      <InfoField
        label='Source'
        value={sources.find((el) => el.id === lead.leadSourceId)?.name}
      />

      <InfoField
        label='Address'
        value={address !== '' ? address : '-'}
        isLongString
      />

      <InfoField
        label='Lead owner'
        value={<NameWithAvatar name={assignedUserName()} showAvatar/>}
        className='lead-details-assigned-to'
      />
    </div>
  )
}

export default LeadDetailsPanel
