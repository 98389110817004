import type { FC } from 'react'
import Tooltip, { TooltipProps } from '../Tooltip'

interface WithTooltipProps extends Omit<TooltipProps, 'isOpen'> {
  withTooltip: boolean
}

const WithTooltip: FC<WithTooltipProps> = ({ children, withTooltip, ...tooltipProps }) => {
  return withTooltip
    ? (
      <Tooltip {...tooltipProps}>
        {children}
      </Tooltip>
      )
    : (
      <>
        {children}
      </>
      )
}

export default WithTooltip
