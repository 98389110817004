import type { DocumentFormPackDto } from 'api/types'

export enum Messages {
  EmptyTableState = 'No records yet',
  NothingFoundState = 'No results found',
  SuccessMessage = 'Changes successfully saved.',
  FormSuccessfullyDeletedMessage = 'Form pack deleted successfully.'
}

export const DEFAULT_FORM_DATA: DocumentFormPackDto = {
  id: 0,
  name: '',
  forms: null,
  description: null
}
