import type { FC } from 'react'
import { Modal } from '@carfluent/common'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'

import { FormText } from './hook/constants'
import useVehiclePurchasePayments from './hook'
import type { UseVehiclePurchasePaymentsProps } from './hook/types'
import VehiclePurchasePaymentsForm from './components/VehiclePurchasePaymentsForm'

import CLASS_NAME from './styles'

const VehiclePurchasePayments: FC<UseVehiclePurchasePaymentsProps> = (props) => {
  const { paymentSectionType } = props
  const {
    isOpen,
    onClose,
    isLoading,
    ...form
  } = useVehiclePurchasePayments(props)

  const { onSubmit } = form

  return (
    <Modal
      className={CLASS_NAME}
      onClose={onClose}
      isOpen={isOpen}
      renderFooterActions={() => (
        <CancelSubmitActionsFooter
          onClose={onClose}
          submitTitle='SAVE'
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      )}
      title={FormText[paymentSectionType].title}
    >
      <p>
        {FormText[paymentSectionType].description}
      </p>
      <VehiclePurchasePaymentsForm {...form} />
    </Modal>
  )
}

export default VehiclePurchasePayments
