import { useMemo, useState } from 'react'

interface UseHeightObserverReturn {
  observer: ResizeObserver
  height: number
}

const DEFAULT_HEIGHT = 56

const useHeightObserver = (): UseHeightObserverReturn => {
  const [height, setHeight] = useState(DEFAULT_HEIGHT)

  const observer = useMemo(() => new ResizeObserver(([{ contentRect: { height } }]) => {
    setHeight(height)
  }), [])

  return {
    observer,
    height
  }
}

export default useHeightObserver
