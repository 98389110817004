import { css } from '@emotion/css'

const createStyleClass = (): string => {
  return css(`
    & {
      .cf-transactions-table {
        border-collapse: collapse;
        margin-top: 24px;
        width: 100%;
        table-layout: fixed;

        thead {
          border-bottom: 2px solid rgba(0, 0, 0, 0.3);
        }

        /* needed for correct pdf table creation */
        tbody {
          border: 1px solid #fff;
        }

        td, th {
          font-family: Roboto;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 0.15px;
          color: #101010;
          text-align: left;
          padding: 8px 0 8px 16px;

          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        th {
          font-weight: 600;
          padding: 12px 0 12px 16px;
        }

        td:last-child, th:last-child {
          padding-right: 16px;
        }

        .cf-account-cell {
          max-width: 145px;
          min-width: 145px;
          width: 145px;
        }

        .cf-name-cell {
          max-width: 200px;
          min-width: 200px;
          width: 200px;
        }

        .cf-reference-cell {
          max-width: 160px;
          min-width: 160px;
          width: 160px;
        }

        .cf-control-cell {
          max-width: 235px;
          min-width: 235px;
          width: 235px;
        }

        .cf-notes-cell {
          max-width: 240px;
          min-width: 240px;
          width: 240px;
        }

        .cf-money-cell {
          text-align: right;
          max-width: 160px;
          min-width: 160px;
          width: 160px;
        }

        .cf-date-cell {
          text-align: left;
          max-width: 160px;
          min-width: 160px;
          width: 160px;
        }

        .cf-description-cell {
          text-align: left;
          max-width: 850px;
          min-width: 850px;
          width: 850px;
        }
      }

      tr.cf-grouped-row td {
        background: #DDDDDD;
        font-weight: 500;
        font-size: 16px;
      }

      .cf-transactions-table td.cf-no-transactions {
        padding: 0;
        padding-right: 0;
      }

      .cf-no-transactions-content {
        width: 100%;
        margin: 16px 0;
        padding: 32px 0;
        padding-right: 0;
        background: #F3F3F3;
        text-align: center;
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.15px;
        color: #101010;
      }

      .cf-lines-table {
        background: #F3F3F3;
        &:hover { background: #FAFAFA; }
      }

      .cf-row-filler {
        background: #FFFFFF;
      }

      tbody.cf-row-filler + tbody.cf-row-filler {
        tr td {
          height: 0;
          padding: 0;
        }
      }

      .cf-row-summary {
        border-top: 2px solid rgba(0, 0, 0, 0.3) !important;
        background: #FFFFFF;
        &:hover { background: #FFFFFF; }

        td, th {
          padding: 20px 16px;
          font-weight: 600;
          font-size: 20px;
          text-overflow: clip;
        }

        .cf-money-cell {
          padding: 20px 16px 20px 0;
        }
      }

      .cf-top-wrapper {
        margin-bottom: 24px;
      }
    }
  `)
}

export default createStyleClass
