import type { ReactNode, FC } from 'react'
import { cnx } from '@carfluent/common'

import TabItem from '../TabItem'
import CLASS_NAME from './styles'

const DEFAULT_TAB_HEADERS: string[] = []

export type TabHeaderItem = string | {
  name: string
  isLoading?: boolean
  counter?: number
  endAdornment?: ReactNode
  isDisabled?: boolean
} | ((index: number) => ReactNode)

export interface TabsProps {
  ariaLabel?: string
  className?: string
  selected: number
  tabHeaders?: TabHeaderItem[]
  onSelect: (index: number) => void
}

const Tabs: FC<TabsProps> = ({
  ariaLabel,
  children,
  className,
  selected,
  tabHeaders = DEFAULT_TAB_HEADERS,
  onSelect
}) => {
  return (
    <div className={cnx(CLASS_NAME, 'tabs', className)}>
      <div className='tabs-header' role='tablist' aria-label={ariaLabel ?? 'tabs'}>
        {
          tabHeaders.map((_tab: TabHeaderItem, index: number) => {
            if (typeof _tab === 'function') {
              return _tab(index)
            }

            const { name, ...tabData } = typeof _tab === 'string' ? { name: _tab } : _tab

            return (
              <TabItem
                {...tabData}
                key={name}
                id={name}
                isSelected={index === selected}
                onClick={() => {
                  onSelect(index)
                }}
              >
                {name}
              </TabItem>
            )
          })
        }
      </div>

      {typeof children === 'function' ? children(selected) : children}
    </div>
  )
}

export default Tabs
