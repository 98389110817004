import { getHoursMinutesTime } from 'utils/parse_date'
import type { LogActivityFormData } from './types'

export const getDefaultFormData = (): LogActivityFormData => {
  const initialDate = new Date()
  const initialTime = getHoursMinutesTime(initialDate)
  return {
    date: initialDate,
    time: initialTime,
    activityCompleteOption: null,
    activityCompleteSubOptions: [],
    assignedUser: null,
    subject: null,
    workNotes: '',
    isInbound: null
  }
}

enum OutboundTitle {
  Outbound = 'Outbound',
  Inbound = 'Inbound'
}

enum IsBoundSubOptionsIds {
  'SpokeToCustomer' = '1',
  'LeftMessage' = '2',
  'BadPhone' = '3',
  'UnableLeaveMessage' = '4',
  'MissedPhone' = '5'
}

export const CALL_RADIOS = [
  {
    id: '0',
    label: OutboundTitle.Outbound,
    value: '0',
    subOptions: [
      {
        label: 'Spoke to customer',
        id: IsBoundSubOptionsIds.SpokeToCustomer,
        value: '1'
      },
      {
        label: 'Left message',
        id: IsBoundSubOptionsIds.LeftMessage,
        value: '2'
      },
      {
        label: 'Bad phone number',
        id: IsBoundSubOptionsIds.BadPhone,
        value: '3'
      },
      {
        label: 'Unable to leave message',
        id: IsBoundSubOptionsIds.UnableLeaveMessage,
        value: '4'
      }
    ]
  },
  {
    id: '1',
    label: OutboundTitle.Inbound,
    value: '1',
    subOptions: [
      {
        label: 'Spoke to customer',
        id: IsBoundSubOptionsIds.SpokeToCustomer,
        value: '1'
      },
      {
        label: 'Missed phone call',
        id: IsBoundSubOptionsIds.MissedPhone,
        value: '5'
      }
    ]
  }
]

export const VISIT_SUB_OPTIONS = [
  { id: '1', name: 'Demoed vehicle(s)' },
  { id: '4', name: 'Sold a vehicle' },
  { id: '2', name: 'Completed a write up' },
  { id: '5', name: 'Turn' },
  { id: '3', name: 'Sent to F&I' }
]
