import {
  type FC,
  type ReactNode
} from 'react'
import { renderToString } from 'react-dom/server'
import { cnx } from '@carfluent/common'
import TooltipedText from 'components/common/TooltipedText'

import CopyIcon from './copyIcon'

import CLASS_NAME from './styles'

export interface InfoFieldProps {
  isCopied?: boolean
  isLongString?: boolean
  className?: string
  label: string
  value: string | ReactNode | ReactNode[]
  nameOfField?: string
  onCopy?: (nameOfFiled: string) => void
}

const InfoField: FC<InfoFieldProps> = ({
  isCopied = false,
  isLongString = false,
  label,
  value,
  className,
  nameOfField = '',
  onCopy
}) => {
  const onClick = (): void => {
    if (typeof value !== 'string') {
      const _value = renderToString(<>{value}</>).replace(/<[^>]+>/g, '')
      void navigator.clipboard.writeText(_value)
    }

    if (typeof value === 'string') {
      void navigator.clipboard.writeText(value)
    }
    onCopy?.(nameOfField)
  }

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <div className='info-label'>{label}</div>
      <div className='info-value'>
        {typeof value === 'string' && !isLongString
          ? <TooltipedText className='info-value-width' value={value} />
          : value ?? '-'}
        {isCopied &&
          <div
            className='copy-button'
            onClick={onClick}
          >
            <CopyIcon />
          </div>}
      </div>
    </div>
  )
}

export default InfoField
