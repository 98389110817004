import { useCallback, useEffect, useState, useMemo, useRef, type MouseEvent } from 'react'
import { isAxiosError } from 'axios'
import { type Dropdown2Props, useForm, isOk, isFail, useRefUpdater, useLoader } from '@carfluent/common'

import { type KeyVal } from 'types'
import AccountingApiProvider from 'api/accounting.api'
import parseError from 'utils/parseErrors'
import isLockedPeriodError from 'utils/accounting/isLockedPeriodError'

import {
  type AccountListPayload,
  type AccountListItem,
  type BankStatementTransactionMatchItem,
  type PaginatedResult,
  type EntityListItem,
  AccountTypeId,
  BankStatementTransactionClearMethod,
  AccountCategoryId
} from 'api/types'

import parseCustomer from 'utils/accounting/parseCustomer'
import parseVendor from 'utils/accounting/parseVendor'

import {
  type BankStatementRowData,
  type UseRowForReviewProps,
  type UseRowForReviewReturn,
  type RowForReviewState,
  type ReviewFormData,
  ReviewCategory
} from './types'
import type { ErrTouchShortcuts } from '../components/FormView/types'

import getValidationRules from './validator'
import serialize from './serializer'
import { GET_DEFAULT_FORM_DATA } from './constants'

export enum SpecialId {
  AddVendor = '___ADD_VENDOR___',
  AddCustomer = '___ADD_CUSTOMER___'
}

const useRowForReview = <V extends BankStatementRowData>({
  accountId,
  onCategorize,
  onExclude,
  onRowClick: _onRowClick,
  onViewTransaction: _onViewTransaction,
  onViewPreloadedTransaction,
  refRowStates,
  row,
  showError,
  onOpenCustomerModal,
  onOpenVendorModal
}: UseRowForReviewProps<V>): UseRowForReviewReturn => {
  const matchesNum = row.original.matches.length ?? 0
  const [isExpanded, setIsExpanded] = useState(false)
  const [isExcluded, setIsExcluded] = useState(false)
  const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false)
  const { isLoading, startLoader, stopLoader } = useLoader()
  const [reviewCategory, setReviewCategory] = useState<string>(matchesNum > 0 ? ReviewCategory.FindMatch : ReviewCategory.Categorize)
  const [apiErrors, setApiErrors] = useState<KeyVal | null>(null)

  const transactionId = row.original.id
  const lastLoadTS = row.original.lastLoadTS
  const isLazyLoadReason = row.original.isLazyLoadReason
  const isDeposit = row.original.change > 0
  const isCategorize = reviewCategory === ReviewCategory.Categorize
  const isFindMatch = reviewCategory === ReviewCategory.FindMatch
  const isRecordAsTransfer = reviewCategory === ReviewCategory.RecordAsTransfer
  const isRecordAsCreditCard = reviewCategory === ReviewCategory.RecordAsCreditCard

  /**
   * AZ-NOTE: In same cases we need to reset Row's internal state,
   * if table data was reloaded - even if new data contains the same row
   * that was already rendered.
   * For example: when user changes filters - we should reset Row's state,
   * even if result of filtration will return the same rows that we already rendered.
   * We use `refPrevLastLoadTS` to detect such situations.
   */
  const refPrevLastLoadTS = useRef(lastLoadTS)
  const refPrevTransactionId = useRef(transactionId)
  const refMatchesNum = useRefUpdater(matchesNum)

  const baseValues = useMemo(() => {
    return GET_DEFAULT_FORM_DATA(row.original.description ?? '')
  }, [row.original.description])

  const validationRules = useMemo(() => getValidationRules({
    forbiddenAccountNumber: accountId
  }), [accountId])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const updateRefState = useCallback((newState: Partial<RowForReviewState>): void => {
    if (refRowStates?.current == null) {
      return
    }

    if (refRowStates.current?.[transactionId] == null) {
      refRowStates.current[transactionId] = {}
    }

    refRowStates.current[transactionId] = {
      ...refRowStates.current[transactionId],
      ...newState
    }
  }, [refRowStates, transactionId])

  const hideRecordFromList = useCallback(() => {
    setIsExcluded(true)
    updateRefState({ isExcluded: true })
  }, [updateRefState])

  const submitAction = useCallback(async (values: ReviewFormData) => {
    if (transactionId == null) {
      return
    }

    const payload = serialize(values)

    if (isCategorize || isFindMatch) {
      await AccountingApiProvider.categorizeBankStatementTransaction(transactionId, payload)
    }

    if (isRecordAsTransfer) {
      await AccountingApiProvider.recordOfTransferBankStatementTransaction(transactionId, payload)
    }

    if (isRecordAsCreditCard) {
      await AccountingApiProvider.recordOfCreditCardPaymentBankStatementTransaction(transactionId, payload)
    }
  }, [
    isCategorize,
    isFindMatch,
    isRecordAsTransfer,
    isRecordAsCreditCard,
    transactionId
  ])

  const onActionResult = useCallback((res) => {
    if (isOk(res)) {
      hideRecordFromList()
      onCategorize?.()
    }

    if (isFail(res)) {
      setApiErrors(parseError(res.result))

      if (isLockedPeriodError(res.result)) {
        setIsBannerErrorVisible(true) // AZ-NOTE: banner will be "removed" only by page leaving or by form re-submit.
      }
    }
  }, [onCategorize, hideRecordFromList])

  const form = useForm<ReviewFormData, ErrTouchShortcuts>({
    baseValues,
    onActionResult,
    submitAction,
    validationRules
  })

  const { setErrors, onChange, resetForm } = form

  /**
   * Toggles expanded/collapsed state of the current row.
   * If the current row is expanding, a previously expanded row should be collapsed.
   */
  const onRowClick = useCallback((evt: MouseEvent<HTMLTableRowElement>) => {
    const reactEvtTrElem: HTMLTableRowElement | null | undefined =
      (evt.target as HTMLElement | null)?.closest('tr.row-expander')

    setIsExpanded(prev => {
      const wasCollapsedBeforeClick = !prev
      updateRefState({ isExpanded: !prev })

      /**
       * START OF KOSTYL
       *
       * On row click - subscribe to click of any row, to collapse the current row,
       * if any of those clicks will occur.
       *
       * AZ-TODO: move to global atomic storage.
       */
      const handler = (evt: Event): void => {
        /**
         * Is case when we open modal window from the row,
         * row can be silently re-mounted by Table.
         * In this case all references will be wasted.
         *
         * So we need to re-select the original row somehow.
         * And in case if it was re-mounted - we can only hide
         * its visual DOM representation.
         */
        let resolvedTrElem: HTMLElement | null = null
        let wasUnmounted = false

        /**
         * Trying to reach original DOM node of the target's enclosing <tr />.
         * If it's already re-mounted - uses CSS selector of unmounted DOM node,
         * to get the new native DOM node, that currently corresponds to the virtual DOM's <tr />.
         */
        if (reactEvtTrElem?.isConnected === true) {
          resolvedTrElem = reactEvtTrElem
        } else if (reactEvtTrElem != null) {
          const classes = [...(reactEvtTrElem.classList.values() ?? [])].join('.')
          const id = reactEvtTrElem.getAttribute('data-id') ?? ''
          const selector = `.${classes}[data-id="${id}"]`
          resolvedTrElem = document.querySelector(selector)
          wasUnmounted = true
        }

        const domEventTrElem = (evt.target as HTMLElement | null)?.closest('tr.row-expander')
        const isClickOnExpander = domEventTrElem != null
        const isClickOnOtherRow = (resolvedTrElem !== domEventTrElem) &&
          (domEventTrElem != null) &&
          (resolvedTrElem?.contains(domEventTrElem) !== true)

        if (isClickOnExpander && isClickOnOtherRow) {
          /**
           * Hides "expanding" part of the previously expanded row.
           * Uses DOM API if node was re-mounted, or React API if it's not re-mounted.
           */

          if (wasUnmounted) {
            resolvedTrElem?.classList.remove('is-expanded')
            resolvedTrElem?.nextElementSibling?.classList.remove('is-expanded')
            const resolvedExpandingPart = resolvedTrElem?.nextElementSibling as HTMLElement | null
            const shouldHide = (resolvedExpandingPart?.classList.contains('row-expandable') ?? false) &&
              (resolvedExpandingPart?.style?.display != null)

            if (shouldHide) {
              resolvedExpandingPart.style.display = 'none'
            }
          } else {
            setIsExpanded(prev => {
              updateRefState({ isExpanded: false })
              return false
            })

            document
              .querySelector('.bank-statements-table > table > tbody')
              ?.removeEventListener('click', handler)
          }
          resetForm(baseValues)
        }
      }

      /**
       * Subscribe to click on the next expander only if
       * we expanded the current row (and do nothing if we collapsed it).
       */
      if (wasCollapsedBeforeClick) {
        const elem = document.querySelector('.bank-statements-table > table > tbody')

        elem?.removeEventListener('click', handler)
        elem?.addEventListener('click', handler)
      }
      /** END OF KOSTYL. */

      return !prev
    })

    _onRowClick?.(evt)
  }, [_onRowClick, updateRefState, resetForm, baseValues])

  const onClickExclude = useCallback(async () => {
    try {
      await AccountingApiProvider.excludeBankStatementTransaction(transactionId)
      hideRecordFromList()
      onExclude?.()
    } catch (err) {
      if (isAxiosError(err)) {
        showError?.(err.response?.data?.message)
      }
    }
  }, [transactionId, onExclude, hideRecordFromList, showError])

  const getAccounts = useCallback(async (
    payload: AccountListPayload = { take: 20, skip: 0 }
  ): Promise<PaginatedResult<AccountListItem>> => {
    const categoryIds = isRecordAsCreditCard
      ? [
          AccountCategoryId.CreditCard
        ]
      : [
          AccountCategoryId.Banks,
          AccountCategoryId.CreditCard,
          AccountCategoryId.BankChecking
        ]
    const resp = AccountingApiProvider.getAccounts({
      ...payload,
      suggestedTypeIds: [isDeposit ? AccountTypeId.Income : AccountTypeId.Expense],
      suggestedCategoryIds: isCategorize ? [] : categoryIds
    })

    return await resp
  }, [isDeposit, isRecordAsCreditCard, isCategorize])

  const onClearMethodClick = useCallback(async (match: BankStatementTransactionMatchItem) => {
    try {
      const clearMethod = match.clearMethodId

      if (clearMethod === BankStatementTransactionClearMethod.Link) {
        await AccountingApiProvider.linkBankStatementTransaction(transactionId, match.id)
      } else if (clearMethod === BankStatementTransactionClearMethod.Pay) {
        await AccountingApiProvider.payBankStatementTransaction(transactionId, match.id)
      } else if (clearMethod === BankStatementTransactionClearMethod.Receive) {
        await AccountingApiProvider.receiveBankStatementTransaction(transactionId, match.id)
      }

      hideRecordFromList()
      onCategorize?.()
    } catch (err) {
      if (isLockedPeriodError(err)) {
        updateRefState({ isBannerErrorVisible: true })
        setIsBannerErrorVisible(true)
      } else if (isAxiosError(err)) {
        showError?.(err.response?.data?.message)
      }

      console.error(err)
    }
  }, [
    hideRecordFromList,
    onCategorize,
    showError,
    transactionId,
    updateRefState
  ])

  const onViewTransaction = useCallback((match: BankStatementTransactionMatchItem) => {
    _onViewTransaction(match.transactionId, match.transactionTypeId)
  }, [_onViewTransaction])

  const onClickFindMatch = useCallback(async () => {
    try {
      startLoader()
      const { transaction } = await AccountingApiProvider.getTransactionByBankStatement(row.original.id)
      const saveResult = await onViewPreloadedTransaction(transaction, row.original.id, row.original.matches)

      updateRefState({ isBannerErrorVisible: false })
      setIsBannerErrorVisible(false)

      if (saveResult.type === 'submit-create-match') {
        hideRecordFromList()
        onCategorize?.()
        return
      }

      if (saveResult.type === 'bank-statement-clear-method') {
        await onClearMethodClick(saveResult.payload)
      }
    } catch (err) {
      console.error(err)
    } finally {
      stopLoader()
    }
  }, [
    hideRecordFromList,
    onCategorize,
    onClearMethodClick,
    onViewPreloadedTransaction,
    updateRefState,
    row.original.id,
    row.original.matches
  ])

  /**
   * This action will unmount Row's instance (because of modal),
   * so we need to save state using `updateRefState`
   */
  const onAddCustomer = useCallback(async () => {
    try {
      startLoader()

      const saveResult = await onOpenCustomerModal()

      if (saveResult !== null) {
        const entity = parseCustomer(saveResult)
        if (entity == null) {
          return
        }

        updateRefState({ values: { ...form.values, entity } })
        onChange('entity', entity)
      }
    } catch (err) {
      console.error(err)
    } finally {
      stopLoader()
    }
  }, [
    updateRefState,
    startLoader,
    stopLoader,
    onChange,
    onOpenCustomerModal,
    form.values
  ])

  /**
   * This action will unmount Row's instance (because of modal),
   * so we need to save state using `updateRefState`
   */
  const onAddVendor = useCallback(async () => {
    try {
      startLoader()

      const saveResult = await onOpenVendorModal()

      if (saveResult !== null) {
        const entity = parseVendor(saveResult)
        if (entity == null) {
          return
        }

        updateRefState({ values: { ...form.values, entity } })
        onChange('entity', entity)
      }
    } catch (err) {
      console.error(err)
    } finally {
      stopLoader()
    }
  }, [
    stopLoader,
    startLoader,
    onChange,
    onOpenVendorModal,
    form.values,
    updateRefState])

  const ENTITY_ACTIONS: Dropdown2Props<EntityListItem, false>['actions'] = useMemo(() => ([
    { id: SpecialId.AddCustomer, name: 'Add Customer', onClick: onAddCustomer },
    { id: SpecialId.AddVendor, name: 'Add Vendor', onClick: onAddVendor }
  ]), [
    onAddCustomer,
    onAddVendor
  ])

  const onChangeReviewCategory = useCallback(async (_, value: string) => {
    if ((matchesNum === 0) && (value === ReviewCategory.FindMatch)) {
      await onClickFindMatch()
      return
    }

    updateRefState({ reviewCategory: value })
    setReviewCategory(value)
    form.resetForm(baseValues)
  }, [
    matchesNum,
    onClickFindMatch,
    onViewPreloadedTransaction,
    startLoader,
    stopLoader,
    row.original.id,
    form.resetForm
  ])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Restores backup-ed internal row's state from ref, hosted
   * in the parent component.
   * Since our rows are re-mounted on each lazy load, we need to save
   * row's state outside of it and restore during next mount.
   */
  useEffect(() => {
    const cleanUp = (): void => {}

    const isRowDataReloaded = refPrevLastLoadTS.current !== lastLoadTS
    if (isRowDataReloaded && !isLazyLoadReason) {
      refPrevTransactionId.current = transactionId
      refPrevLastLoadTS.current = lastLoadTS
      updateRefState({})
      setIsExcluded(false)
      setIsExpanded(false)
      setReviewCategory(refMatchesNum.current > 0 ? ReviewCategory.FindMatch : ReviewCategory.Categorize)
      setIsBannerErrorVisible(false)
      form.resetForm(GET_DEFAULT_FORM_DATA(row.original.description ?? ''))
      return cleanUp
    }

    const rowState = refRowStates?.current?.[transactionId]
    if (rowState == null) {
      return cleanUp
    }

    if (rowState.isExcluded != null) {
      setIsExcluded(rowState.isExcluded)
    }

    if (rowState.isExpanded != null) {
      setIsExpanded(rowState.isExpanded)
    }

    if (rowState.values != null) {
      form.resetForm(rowState.values)
    }

    if (rowState.reviewCategory != null) {
      setReviewCategory(rowState.reviewCategory)
    }

    if (rowState.isBannerErrorVisible != null) {
      setIsBannerErrorVisible(rowState.isBannerErrorVisible)
    }

    return cleanUp
  }, [
    refRowStates, transactionId, form.resetForm,
    lastLoadTS, isLazyLoadReason
  ])

  useEffect(() => {
    updateRefState({ values: form.values })
  }, [form.values])

  /**
   * START OF KOSTYL
   * DD-CHECK: we need `setErrors` available from `onActionResult`.
   */

  useEffect(() => {
    if (apiErrors != null) {
      setErrors(apiErrors as any)
      setApiErrors(null)
    }
  }, [apiErrors, setErrors])

  /** END OF KOSTYL. */

  // ========================================== //

  return {
    ...form,
    getAccounts,
    isBannerErrorVisible,
    isDeposit,
    isExpanded,
    isExcluded,
    isLoading,
    hasMatches: matchesNum > 0,
    entityActions: ENTITY_ACTIONS,
    onChangeReviewCategory,
    onClearMethodClick,
    onClickExclude,
    onClickFindMatch,
    onRowClick,
    onViewTransaction,
    reviewCategory
  }
}

export default useRowForReview
