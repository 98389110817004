import { Routes } from 'constants/route_helper'

const createIdentityMapping = (routes: typeof Routes): Record<string, string> => {
  const mapping: Record<string, string> = {}

  for (const route of Object.values(routes)) {
    mapping[route] = route
  }

  return mapping
}

const identityMappedRoutes = createIdentityMapping(Routes)

/**
 * for list + details pages
 * and for update + view state pages
 */
const overrides: Partial<Record<Routes, Routes>> = {
  [Routes.DealItem]: Routes.Deals,
  [Routes.DealItemUpdate]: Routes.Deals,
  [Routes.VehiclesItem]: Routes.Vehicles,
  [Routes.CRMLeadItem]: Routes.CRMLeads,
  [Routes.CRMTaskLeadsItem]: Routes.CRMTasks,
  [Routes.ReconDetails]: Routes.Recon,

  [Routes.SettingsGeneralUpdate]: Routes.SettingsGeneral,
  [Routes.SettingsFiUpdate]: Routes.SettingsFi
}

/**
 * By sorting the strings by length and checking the longer strings first,
 * we ensure that if we're on a more specific route like /vehicles/appraisals
 * it gets matched before the less specific /vehicles.
 */
const mappedRoutes = Object.values({
  ...identityMappedRoutes,
  ...overrides
}).sort((a, b) => b.length - a.length)

export default mappedRoutes
