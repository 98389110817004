import {
  type UploadAdapter,
  type UploadResponse,
  type FileLoader
} from '@ckeditor/ckeditor5-upload/src/filerepository'

import FilesApiProvider from 'api/files.api'

class ImageUploadAdapter implements UploadAdapter {
  private readonly loader: FileLoader
  private cancellationKey: string | null = null

  constructor (loader: FileLoader) {
    this.loader = loader
  }

  async upload (): Promise<UploadResponse> {
    const file = await this.loader.file
    if (file == null) {
      throw new Error('file is not found')
    }

    this.cancellationKey = `${file.name}__${Math.random()}`
    const cancellationOptions = { key: this.cancellationKey, cancelPrevious: true }

    const resp = await FilesApiProvider.uploadImageForEmail(file, cancellationOptions)

    return {
      default: `${FilesApiProvider.getEmailImagesUrl(true)}/${resp.id ?? ''}`
    }
  }

  abort (): void {
    if (this.cancellationKey != null) {
      FilesApiProvider.cancelRequest(this.cancellationKey)
    }
  }
}

export default ImageUploadAdapter
