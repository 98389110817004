import type { FC } from 'react'
import { Modal } from '@carfluent/common'

import type { SummaryShift } from 'api/types'
import type { Coords } from 'types'
import { WORK_SCHEDULE_MODAL_CONSTRAINT_ID } from 'constants/constants'
import SummaryChip from 'components/crm/Chips/SummaryChip'

import CLASS_NAME from './styles'

interface ShowSummaryModalProps {
  summaryShifts: SummaryShift[]
  isModalOpen: boolean
  onCloseModal: () => void
  summaryModalInitialPosition: Coords
}

const DEFAULT_WIDTH = 272

const ShowSummaryModal: FC<ShowSummaryModalProps> = ({
  summaryShifts,
  isModalOpen,
  onCloseModal,
  summaryModalInitialPosition
}) => {
  return (
    <Modal
      onClose={onCloseModal}
      isOpen={isModalOpen}
      className={CLASS_NAME}
      minWidth={DEFAULT_WIDTH}
      maxWidth={DEFAULT_WIDTH}
      transform={summaryModalInitialPosition}
      isDraggable
      constraintId={WORK_SCHEDULE_MODAL_CONSTRAINT_ID}
    >
      {summaryShifts?.map(summaryShift => (
        <SummaryChip
          key={summaryShift.userName}
          summaryShift={summaryShift}
        />
      ))}
    </Modal>
  )
}

export default ShowSummaryModal
