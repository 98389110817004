import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateAreas: `
'address address apt'
'city state zipCode'
`,
    gridTemplateColumns: '2fr 2fr 1fr',
    width: '100%',
    gridRowGap: 24,
    gridColumnGap: 12,
    padding: 12,

    '&.hidden-apt': {
      gridTemplateAreas: `
        'address address address'
        'city state zipCode'
        `
    }
  },

  address: {
    gridArea: 'address'
  },

  apt: {
    gridArea: 'apt'
  },

  city: {
    gridArea: 'city'
  },

  state: {
    gridArea: 'state'
  },

  zipCode: {
    gridArea: 'zipCode'
  }
})
