import { css } from '@emotion/css'

export default css`
  display: flex;
  flex-direction: column;
  width: 400px;
  max-height: 312px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 24px 38px 0 rgba(58, 71, 78, 0.10);
  padding-bottom: 16px;
  
  .input-wrapper {
    padding: 16px;
    
    .cf-end-adornment.cf-input-adornment {
      display: flex;
      align-items: center;
      height: 100%;

      .close-icon {
        cursor: pointer;
        height: 16px;
      }
    }
  }
  
  .list-box {
    max-height: calc(100% - 88px);
    overflow-y: auto;
    
    .list-item {
      min-height: 56px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 16px;
      cursor: pointer;
      
      :hover {
        background: #F3F3F3;
      }
    }
  }
  
  .cf-loader {
    height: 40px;
  }
`
