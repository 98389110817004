import type { FC, ReactNode } from 'react'

import CLASS_NAME from './styles'

interface NoVehicleImagesTextProps {
  title?: ReactNode | string
}

const NoVehicleImagesText: FC<NoVehicleImagesTextProps> = ({ title = 'No vehicle images yet' }) => {
  return (
    <p className={CLASS_NAME}>
      {title}
    </p>
  )
}

export default NoVehicleImagesText
