import { type FC } from 'react'
import type { Preset, PRESET_LABELS } from '@carfluent/common'

import DateRangePicker from 'components/common/DateRangePicker'
import { type UseReportGrossProfitReturn } from 'pages/reports/ReportGrossProfit/hook/types'

import BuyerFilter from './BuyerFilter'
import { getPresets } from './constants'
import DealStatusFilter from './DealStatusFilter'

type FiltersProps =
  | 'setFilters'
  | 'onDateFilterChange'

export type PickedFiltersProps = Pick<UseReportGrossProfitReturn, FiltersProps> & {
  presets?: Preset[]
  presetLabel?: string
  defaultPresetName: PRESET_LABELS
}

const defaultPresets = getPresets()

const Filters: FC<PickedFiltersProps> = ({
  setFilters,
  presets = defaultPresets,
  onDateFilterChange,
  presetLabel,
  defaultPresetName
}) => (
  <div className='filters-wrapper'>
    <DealStatusFilter setFilters={setFilters} />
    <BuyerFilter setFilters={setFilters} />
    <DateRangePicker
      presets={presets}
      presetLabel={presetLabel}
      onFilterChange={onDateFilterChange}
      defaultPresetName={defaultPresetName}
    />
  </div>
)

export default Filters
