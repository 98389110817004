import type { ListPayload } from './utility.types'
import type { DealerAccount } from './dealers.types'
import { VehicleStateNames } from 'constants/names'

export interface ReconStep {
  id: number
  order: number
  name: string
  isReadonly: boolean
  daysInStepUntilFirstAlert: string
  daysInStepUntilSecondAlert: string
  daysInReconUntilFirstAlert: string
  daysInReconUntilSecondAlert: string
  subscribers: DealerAccount[]
  // is not used
  usedInVehicle: boolean
}

export interface ReconStepPayload extends Omit<ReconStep, 'subscribers'>{
  subscribers: number[]
}

export interface UpdateReconStepsPayload {
  reconStepsToCreate: ReconStepPayload[]
  reconStepsToUpdate: ReconStepPayload[]
  reconStepIdsToDelete: number[]
}

export interface ImageUpdatePayload extends Omit<ReconImage, 'id'> {
  id?: number
}

export interface UpdateReconDetailsPayload {
  reconStepId: number
  comments: [{ body: string }]
  vehicleImages: ImageUpdatePayload[]
}

export interface ReconVehicleAmount {
  stepId: number
  stepName: string
  noAlertCount: number
  firstAlertCount: number
  secondAlertCount: number
}

export interface TotalStepsAmountDtoDto {
  noAlertCount: number
  firstAlertCount: number
  secondAlertCount: number
}

export interface ReconVehicleTotals {
  amounts: ReconVehicleAmount[]
  total: TotalStepsAmountDtoDto
}

export interface ReconVehicleComment {
  id: number
  logTypeId: ReconLogTypeEnum
  body: string
  userName: string
  createdDate: string
  logData: logDataDto
}

export interface ReconImage {
  id: number
  fileName: string
  originalUrl: string
  thumbnailUrl?: string
  order: number
}

export interface ReconListPayload extends ListPayload {
  reconStepId?: string
  isFavorite?: true | null
}

export interface StepChange {
  createdDate: string
  stepName: string | null
  userName: string | null
}

export type ReconVehicleStatus = 'NoAlert' | 'FirstAlert' | 'SecondAlert'

export enum ReconLogTypeEnum {
  Comment = 1,
  ChangedStep = 2,
  SubmitInspection = 3,
  UnlockInspection = 4
}

export interface logDataDto {
  stepName?: string
  comment?: string
  commentsCount?: number
  failedCount?: number
  passedCount?: number
  userFirstName: string
  userLastName: string
}

export interface ReconLogDataResponseDto {
  id: number
  logTypeId: ReconLogTypeEnum
  vehicleId: number
  logData: logDataDto
  date: string
}

export interface ReconVehicleListItemResponseDto {
  id: number
  stepName: string
  vin: string
  year: number
  make: string
  model: string
  trim: string
  mileage: number
  stock: string
  salePrice: number
  mainImageUrl: string
  isFavorite: boolean
  isDeposited: boolean
  vehicleState: VehicleStateNames
  comments: ReconVehicleComment[] | null
  tags: string[]
  stepDate: string
  createdDate: string
  inventoryDate: string
  reconStep: ReconStep
  disState: ReconVehicleStatus
  dirState: ReconVehicleStatus
}

export type ReconVehicleLogTimeline = Array<{date: string, logs: ReconLogDataResponseDto[]}>
export interface ReconVehicleDetailsResponseDto extends Omit<ReconVehicleListItemResponseDto, 'comments'> {
  stepChanges: StepChange[]
  vehicleImages: ReconImage[]
  logs: ReconVehicleLogTimeline
}

export interface VehicleInspectionQuestionPayload {
  comments?: string
  questionStateId?: number | null
}

export enum ReconInspectionStates {
  Submitted = 'Submitted',
  InProgress = 'InProgress'
}

export enum InspectionStatus {
  Pass = 1,
  Fail = 2,
  Unavailable = 3
}
