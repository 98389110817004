import type { Group, Groups, Rows } from '@carfluent/common'
import type { TitleReportItem } from './types'

export const groupByMake = (makesAmount: Record<string, number>) => (rows: Rows<TitleReportItem>): Groups<TitleReportItem, string> => {
  const groups = rows.reduce((acc: Map<string, Group<TitleReportItem, string>>, row) => {
    const groupId = row.original.make.make ?? ''
    const group = acc.get(groupId) ?? { rows: [], key: '' }

    acc.set(groupId, {
      rows: [...group.rows, row],
      key: `${group.key},${row.id}`,
      amount: makesAmount[groupId] ?? 0,
      meta: groupId
    })

    return acc
  }, new Map())

  return [...groups.values()]
}
