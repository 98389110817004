import type { FC } from 'react'
import { Modal, Button } from '@carfluent/common'

import EmailListItem from './components/EmailListItem'
import EmailThreadForm from './components/EmailThreadForm'
import type { EmailThreadModalProps } from './hook/types'
import useEmailThreadModal from './hook'

import {
  EMAIL_THREAD_MODAL_STYLES,
  REPLY_BTN_STYLES,
  EMAIL_LIST_ITEM_WRAPPER
} from './styles'

const MODAL_MIN_WIDTH = 1034

enum FormTitle { EmailThread = 'Email thread' }

const EmailThreadModal: FC<EmailThreadModalProps> = ({
  leadId,
  isEmailThreadModalOpen,
  emailThread,
  carsOfInterest,
  emailTemplates,
  linkToWebsite,
  suggestedCars,
  primaryCarOfInterestId,
  onCloseEmailThreadModal,
  onSubmitLeadUpdate
}) => {
  const {
    lastEmailListIndex,
    responseEmailIndex,
    onClickSetIsResponseEmail,
    replyBtnProps,
    emailThreadFormProps
  } = useEmailThreadModal({
    emailList: emailThread?.emails,
    isEmailThreadModalOpen,
    leadEmail: emailThread?.leadEmail,
    leadId,
    subject: emailThread?.subject
  })

  if (emailThread == null) return null

  const {
    subject,
    leadEmail,
    emails
  } = emailThread

  const {
    isActiveReplyBtnMode,
    onClickSetReplyBtnMode
  } = replyBtnProps

  return (
    <Modal
      className={EMAIL_THREAD_MODAL_STYLES}
      isOpen={isEmailThreadModalOpen}
      minWidth={MODAL_MIN_WIDTH}
      onClose={onCloseEmailThreadModal}
      title={FormTitle.EmailThread}
      closeByBackdropClick={false}
    >
      <div className='cf-modal-content-header'>
        <span className='cf-header-subject'>Subject: {subject}</span>
        <span className='cf-header-email'>{leadEmail}</span>
      </div>
      <div className='cf-modal-content-body'>
        {emails.map((email, index) => {
          const isResponseEmailFormOpen = responseEmailIndex === index

          return (
            <div className={EMAIL_LIST_ITEM_WRAPPER} key={email.id}>
              <EmailListItem
                {...email}
                emailIndex={index}
                lastEmailIndex={lastEmailListIndex}
                onClickSetIsResponseEmail={onClickSetIsResponseEmail}
              />
              {isResponseEmailFormOpen && (
                <EmailThreadForm
                  {...emailThreadFormProps}
                  emailTemplates={emailTemplates}
                  carsOfInterest={carsOfInterest}
                  suggestedCars={suggestedCars}
                  primaryCarOfInterestId={primaryCarOfInterestId}
                  linkToWebsite={linkToWebsite}
                  displayName={email.displayName}
                  onCloseEmailThreadModal={onCloseEmailThreadModal}
                  onSubmitLeadUpdate={onSubmitLeadUpdate}
                />
              )}
            </div>
          )
        })}
      </div>
      <div className='cf-modal-content-footer'>
        {!isActiveReplyBtnMode &&
          <Button
            className={REPLY_BTN_STYLES}
            onClick={onClickSetReplyBtnMode}
            variant='outlined'
            isDisabled={leadEmail == null}
          >Reply
          </Button>}
      </div>
    </Modal>
  )
}

export default EmailThreadModal
