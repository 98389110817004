import { type FC } from 'react'
import { FormNumberInput, Button, noop } from '@carfluent/common'

import { RadioGroup } from 'components/common/RadioGroup'
import { MAX_PRICE_VALUE } from 'constants/validation'
import { PercentOrAmountModes } from 'types'

import { type CalculatedOrManualProps } from './types'
import usePercentOrAmountForm from './hook'
import CLASS_NAME from './styles'

const FORM_MODES = [
  PercentOrAmountModes.Auto,
  PercentOrAmountModes.Manual
]

const CalculatedOrManualForm: FC<CalculatedOrManualProps> = ({
  formatRadioLabel,
  getCalculatedAmount,
  label,
  ...hookProps
}) => {
  const {
    formMode,
    isCalculatedValueHidden,
    onChangeManualAmount,
    onChangeFormMode,
    onSubmit,
    valueManual
  } = usePercentOrAmountForm(hookProps)

  return (
    <div className={CLASS_NAME}>
      <div className='form-fields'>
        <RadioGroup
          className='form-mode-switch'
          data-test-id={`form-mode-switch-${hookProps.fieldPathManual}`}
          id='formMode'
          items={FORM_MODES.map(x => ({ value: x, label: formatRadioLabel(x) }))}
          onChange={(_, value) => onChangeFormMode(value)}
          value={formMode}
        />

        {(formMode === PercentOrAmountModes.Auto) && (
          <FormNumberInput
            blurComplementMode='min-scale'
            className='calculated-or-manual-input'
            disabled
            id='calculated'
            isEmptyAllowed
            label={label}
            max={MAX_PRICE_VALUE}
            mountComplementMode='min-scale'
            onChange={noop}
            preset='price'
            value={isCalculatedValueHidden ? null : getCalculatedAmount()}
          />
        )}

        {(formMode === PercentOrAmountModes.Manual) && (
          <>
            <FormNumberInput
              blurComplementMode='min-scale'
              className='calculated-or-manual-input'
              disabled={hookProps.disabled}
              id='manual'
              isEmptyAllowed
              label={label}
              max={MAX_PRICE_VALUE}
              mountComplementMode='min-scale'
              onChange={onChangeManualAmount}
              preset='price'
              value={valueManual}
            />
          </>
        )}
      </div>

      <div className='apply-button-wrapper'>
        <Button
            onClick={onSubmit}
            className='btn-apply'
            variant='contained'
            isDisabled={hookProps.disabled}
          >
            Apply
          </Button>
      </div>
    </div>
  )
}

export default CalculatedOrManualForm
