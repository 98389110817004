import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import TooltipItem from 'components/common/TooltipItem'

import { ChipProps } from './types'
import CLASS_NAME from './styles'

const Chip: FC<ChipProps> = ({ label }) => {
  return (
    <TooltipItem value={label} className={cnx(CLASS_NAME, 'chip-container')} />
  )
}

export default Chip
