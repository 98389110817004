import { type FC, useCallback, useMemo } from 'react'

import RadioGroup from 'components/common/RadioGroup'
import type { UseLeadLogsFormReturn } from 'pages/crm/LeadDetailsView/components/LeadDetailsLogActivity/hook'
import { CALL_RADIOS } from 'pages/crm/LeadDetailsView/components/LeadDetailsLogActivity/hook/constants'
import { FieldIds } from 'pages/crm/LeadDetailsView/components/LeadDetailsLogActivity/hook/types'

export interface SubOption {
  label: string
  id: string
  value: string
}

export type LogCallFormProps = Pick<UseLeadLogsFormReturn, 'values' | 'onChange' | 'touched' | 'errors'>

const LogCallForm: FC<LogCallFormProps> = ({ values, onChange, touched, errors }) => {
  const isInboundId = values.isInbound
  const isActivityOption = values.activityCompleteOption

  const subOptions: SubOption[] = useMemo(() => (
    CALL_RADIOS.find(radio => radio.id === isInboundId)?.subOptions as SubOption[]
  ), [isInboundId])

  const onChangeRadio = useCallback((_, value: string, id: string) => {
    onChange(id, value)
  }, [onChange])

  const isInboundSelected = isInboundId != null
  const isActivityOptionSelected = isActivityOption != null

  const showRadioError = touched[FieldIds.IsInbound] && !isInboundSelected && errors[FieldIds.IsInbound]
  const showSubRadioError = touched[FieldIds.ActivityCompleteOptionId] && !isActivityOptionSelected && errors[FieldIds.ActivityCompleteOptionId]

  return (
    <>
      <div className='isInbound'>
        <p className='error-text'>{!isInboundSelected && showRadioError}</p>
        <RadioGroup
          classes={{ root: 'isInbound', item: 'radioGroup-item' }}
          id={FieldIds.IsInbound}
          items={CALL_RADIOS}
          onChange={onChangeRadio}
          value={values.isInbound}
        />
      </div>
      {isInboundId != null &&
        <div className='complete-option'>
          <div className='radios-title-error'>
            <p className='form-subtitle'>Call result</p>
            <p className='error-text'>{isInboundSelected && showSubRadioError}</p>
          </div>
          <RadioGroup
            classes={{ item: 'complete-item' }}
            id={FieldIds.ActivityCompleteOptionId}
            items={subOptions}
            onChange={(_, value, id) => onChange(id, value)}
            value={values.activityCompleteOption}
          />
        </div>}
    </>
  )
}

export default LogCallForm
