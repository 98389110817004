import { type FiltersFormData } from 'types/filters'
import { type FiltersType, type SerializeFiltersConfig } from 'utils/filters/types'
import serializeAmountFilters from 'utils/filters/serializeAmountFilters'
import serializeDateFilters from 'utils/filters/serializeDateFilters'

const serializeFilters = (
  payload: FiltersFormData,
  config: SerializeFiltersConfig
): FiltersType => {
  if (payload == null) {
    return payload
  }

  const { amount, date } = payload

  const res: FiltersType = {
    amount: serializeAmountFilters(amount),
    date: serializeDateFilters(date, config.serializeDate)
  }

  if (payload.transactionType != null) {
    res.transactionType = Number(payload.transactionType?.id)
  }

  return res
}

export default serializeFilters
