import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { DealState as ApiDealState } from 'api/types'
import { DealState } from 'constants/names'

import CLASS_NAME from './styles'

export interface DealStatusProps {
  dealState: string | number | null
  className?: string
  getTitle?: (name: string) => string
}

export interface DealStatusItem {
  name: string
  status: string
}

const DealStatus: FC<DealStatusProps> = ({ getTitle, className, ...props }) => {
  const dealStatusItem = getDealStatusItem(props)

  return (
    <span className={cnx(CLASS_NAME, `deal-status-${dealStatusItem.status}`, className)}>
      {getTitle != null ? getTitle(dealStatusItem.name) : dealStatusItem.name}
    </span>
  )
}

export default DealStatus

const getDealStatusItem = ({ dealState }: DealStatusProps): DealStatusItem => {
  if ((dealState === DealState.Completed) || (dealState === ApiDealState.Completed)) {
    return {
      name: 'Sold',
      status: 'complete'
    }
  }

  if ((dealState === DealState.Canceled) || (dealState === ApiDealState.Canceled)) {
    return {
      name: DealState.Canceled,
      status: 'canceled'
    }
  }

  return {
    name: 'Incomplete',
    status: 'incomplete'
  }
}
