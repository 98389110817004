import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import Button from 'components/common/Button'

import CLASS_NAME from './styles'

interface ActionBarProps {
  isDisabled?: boolean
  onDelete?: () => void
  onSubmit: () => void
  onCancel: () => void
  isLoading: boolean
  buttonsClassName?: string
}

const ActionBar: FC<ActionBarProps> = ({
  buttonsClassName = '',
  isLoading,
  isDisabled = false,
  onSubmit,
  onCancel
}) => {
  return (
    <>
      <div className={CLASS_NAME}>
        <Button
          className={cnx(buttonsClassName, 'cancel-button')}
          disabled={isLoading}
          type='secondary'
          onClick={onCancel}
          data-test-id='activity-task-btn-cancel'
        >
          CANCEL
        </Button>
        <Button
          className={buttonsClassName}
          disabled={isDisabled || isLoading}
          type='secondary'
          onClick={onSubmit}
          data-test-id='activity-task-btn-submit'
        >
          LOG TASK
        </Button>
      </div>
    </>
  )
}
export default ActionBar
