import { parsers } from '@carfluent/common'

import { TIME_12H_MIN_AMPM_FORMAT } from 'constants/constants'
import { formatUTCDateTimeForView } from 'utils/view_helper'
import { type LeadFormData, type TasksFormData } from 'types'
import {
  type DictionaryItem,
  type AssignedUser,
  type LeadData,
  type LeadMessageDto,
  type LeadTask,
  type LeadMessageMediaDto,
  MessageTypeId,
  MessageMediaTypeId
} from 'api/types'

import getDownloadFileUrl from 'utils/getDonwloadFileUrl'
import { isTruthy } from 'utils/general'
import { type MessageProps } from 'components/common/Messenger/components/Message'

import { mapPriorityNumberToObject } from './utils'

const { parseDateStringUTC } = parsers

export const parseLeadTasks = (data: LeadTask[], reminderSubjects: DictionaryItem[]): TasksFormData[] => {
  return data.map(task => ({
    ...task,
    taskPriority: mapPriorityNumberToObject(task.taskPriorityId),
    subject: reminderSubjects?.find(opt => opt.name === task.subject) ?? null,
    dueDate: parseDateStringUTC(task.dueDate),
    time: formatUTCDateTimeForView(task.dueDate, TIME_12H_MIN_AMPM_FORMAT)
  }))
}

export const parseLeadDetails = (data: LeadData, reminderSubjects: DictionaryItem[]): LeadFormData => {
  return {
    ...data,
    birthDate: parseDateStringUTC(data.birthDate),
    carsOfInterest: data.carsOfInterest.map(item => ({ ...item, isPrimary: item.id === data.primaryCarOfInterestId })),
    tasks: data.tasks.map(task => ({
      ...task,
      taskPriority: mapPriorityNumberToObject(task.taskPriorityId),
      subject: reminderSubjects?.find(opt => opt.name === task.subject) ?? null,
      dueDate: parseDateStringUTC(task.dueDate),
      time: formatUTCDateTimeForView(task.dueDate, TIME_12H_MIN_AMPM_FORMAT)
    }))
  }
}

export const parseAssignedUser = (assignedUser?: AssignedUser | DictionaryItem | null): DictionaryItem | null => {
  if (assignedUser == null) {
    return null
  }

  if ('name' in assignedUser) {
    return assignedUser
  }

  const { id, firstName, lastName } = assignedUser
  const fullName = (firstName != null && lastName != null) ? `${firstName} ${lastName}` : ''

  return { id, name: fullName }
}

export const parseMessage = ({
  id,
  messageTypeId,
  body: message,
  date,
  leadFirstName,
  leadLastName,
  adminLastName,
  adminFirstName,
  media: _media
}: LeadMessageDto): MessageProps => {
  const media = _media ?? []
  const isReceived = messageTypeId === MessageTypeId.Received

  let name = isReceived
    ? `${leadFirstName ?? ''} ${leadLastName ?? ''}`
    : `${adminFirstName ?? ''}${adminLastName != null ? ` ${adminLastName}` : ''}`

  name = name.replace(/\(\)/g, '')

  const imageUrls = media.filter(isImage).map(x => x.url ?? '')
    .filter(isTruthy)

  const audioUrls = media
    .filter(isAudio)
    .map(x => (
      x?.fileId == null
        ? ''
        : getDownloadFileUrl('crm-lead-audio', x.fileId)
    ))
    .filter(isTruthy) ?? []

  return {
    audioUrls,
    date,
    id,
    imageUrls,
    isReceived,
    message,
    name
  }
}

// ========================================== //

const isImage = (media: LeadMessageMediaDto): boolean => {
  return media.mediaTypeId === MessageMediaTypeId.Image
}

const isAudio = (media: LeadMessageMediaDto): boolean => {
  return media.mediaTypeId === MessageMediaTypeId.Audio
}
