import { type FC, KeyboardEvent } from 'react'
import { FormNumberInput } from '@carfluent/common'

import Toggle from 'components/common/Toggle'
import { ValidationLength } from 'constants/validation'
import InfoIconWithTooltip from 'components/common/InfoIconWithTooltip'

import type { UseMarketplacePromoReturn } from '../../hook'
import { INFO_TEXT, MAX_PERCENT_NUMBER } from '../../hook/constants'

interface MonthlyPaymentsProps extends Omit<UseMarketplacePromoReturn, 'isValid' | 'onSubmit' | 'resetForm' | 'isChanged'> {}

const onKeyPress = (e: KeyboardEvent): void => {
  if (e.key === '-') {
    e.preventDefault()
  }
}

const MonthlyPayments: FC<MonthlyPaymentsProps> = ({
  values,
  onBlur,
  errors,
  touched,
  onChange,
  onChangeCashDownDollar,
  onChangeCashDownPercent
}) => {
  return (
    <>
      <div className='promo-header'>
        <div className='promo-title'>
          <h2>
            Display estimated monthly payments

            <InfoIconWithTooltip
              placement='top-end'
              description={INFO_TEXT}
            />
          </h2>

          <Toggle
            inversed
            className='cf-toggle'
            toggled={values.isEstimatedMonthlyPaymentEnabled}
            onClick={(isEnabled) => onChange('isEstimatedMonthlyPaymentEnabled', isEnabled)}
          />
        </div>
      </div>

      {
        values.isEstimatedMonthlyPaymentEnabled &&
          <div className='promo-content monthly-payment-content'>
            <FormNumberInput
              onBlur={onBlur}
              startAdornment='$'
              id='cashDownAmount'
              label='Cash down, $'
              decimalPrecision={2}
              onKeyPress={onKeyPress}
              value={values.cashDownAmount}
              error={errors.cashDownAmount}
              touched={touched.cashDownAmount}
              onChange={onChangeCashDownDollar}
              maxLength={ValidationLength.MAX_NUMBER_LENGTH_OF_FIELD}
            />

            or

            <FormNumberInput
              id='cashDownPercentage'
              label='Cash down, %'
              preset='percent'
              isEmptyAllowed
              value={values.cashDownPercentage}
              error={errors.cashDownPercentage}
              touched={touched.cashDownPercentage}
              onChange={onChangeCashDownPercent}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
              max={MAX_PERCENT_NUMBER}
            />

            <div className='divider' />

            <FormNumberInput
              id='apr'
              label='APR'
              isEmptyAllowed
              preset='percent'
              onBlur={onBlur}
              value={values.apr}
              error={errors.apr}
              onChange={onChange}
              touched={touched.apr}
              onKeyPress={onKeyPress}
              max={MAX_PERCENT_NUMBER}
            />

            <FormNumberInput
              id='term'
              label='Term'
              onBlur={onBlur}
              onChange={onChange}
              value={values.term}
              error={errors.term}
              endAdornment='months'
              touched={touched.term}
              onKeyPress={onKeyPress}
              max={MAX_PERCENT_NUMBER}
            />
          </div>
      }
    </>
  )
}

export default MonthlyPayments
