import { useCallback, useState } from 'react'
import { useLoader, useRefUpdater, useSubscribe } from '@carfluent/common'

import AccountingApiProvider from 'api/accounting.api'
import useEffectOnce from 'hooks/useEffectOnce'
import Events from 'constants/events'

import parseAccountBalances from './parser'
import {
  type UseAccountsListSectionProps,
  type UseAccountsListSectionReturn,
  type AccountBankStatement
} from './types'

const findAccountIndex = (accounts: AccountBankStatement[], activeAccountNum: number | null): number | null => {
  return activeAccountNum !== null ? accounts.findIndex(a => `${a.number}` === `${activeAccountNum}`) : null
}

const useAccountsListSection = ({
  onSelectAccount: _onSelectAccount
}: UseAccountsListSectionProps): UseAccountsListSectionReturn => {
  const [accounts, setAccounts] = useState<AccountBankStatement[]>([])
  const [activeAccountNum, setActiveAccountNum] = useState<number | null>(null)
  const refAccounts = useRefUpdater(accounts) // AZ-NOTE: needed because of batch update race condition
  const { startLoader, stopLoader, isLoading } = useLoader()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onSelectAccount = useCallback((accountNum: number | null): void => {
    setActiveAccountNum(accountNum)

    if (accountNum != null) {
      const account = refAccounts.current.find(a => `${a.number}` === `${accountNum}`)
      if (account != null) {
        _onSelectAccount(account.id)
      }
    }
  }, [_onSelectAccount])

  const loadAccounts = useCallback(async () => {
    try {
      startLoader()
      const resp = await AccountingApiProvider.getAccountBankStatements()
      const parsedAccounts = parseAccountBalances(resp.items)
      const activeAccount = parsedAccounts[0] ?? null

      setAccounts(parsedAccounts)
      onSelectAccount(activeAccount?.number ?? null)
    } catch (err) {
      setAccounts([])
    } finally {
      stopLoader()
    }
  }, [startLoader, stopLoader, onSelectAccount])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffectOnce(() => {
    void loadAccounts()
  }, [loadAccounts])

  useSubscribe(Events.BankStatementsDecreaseTabCounter, async () => {
    if (activeAccountNum == null) {
      return
    }

    const activeAccountIdx = findAccountIndex(refAccounts.current, activeAccountNum)

    if (activeAccountIdx != null) {
      const currAccount = refAccounts.current[activeAccountIdx]
      const nextAccount = {
        ...currAccount,
        numberOfTransactionToReview: Math.max(currAccount.numberOfTransactionToReview - 1, 0)
      }

      refAccounts.current[activeAccountIdx] = nextAccount

      setAccounts(prev => {
        const next = [...prev]
        next[activeAccountIdx] = nextAccount
        return next
      })
    }
  })

  useSubscribe(Events.BankStatementsIncreaseTabCounter, async () => {
    if (activeAccountNum == null) {
      return
    }

    const activeAccountIdx = findAccountIndex(refAccounts.current, activeAccountNum)

    if (activeAccountIdx != null) {
      const currAccount = refAccounts.current[activeAccountIdx]
      const nextAccount = {
        ...currAccount,
        numberOfTransactionToReview: currAccount.numberOfTransactionToReview + 1
      }

      refAccounts.current[activeAccountIdx] = nextAccount

      setAccounts(prev => {
        const next = [...prev]
        next[activeAccountIdx] = nextAccount
        return next
      })
    }
  })

  // ========================================== //

  return {
    accounts,
    activeAccountNum,
    isLoading,
    onSelectAccount
  }
}

export default useAccountsListSection
