import { css } from '@emotion/css'

export default css(`
  .top-panel-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .print-pdf-button {
    .cf-button-content {
      font-weight: 700;
      display: flex;
      align-items: center;
      
      .cf-loader {
        margin-left: 8px;
      }
    }
  }

  .recon-list {
    min-width: 280px;
    max-width: 280px;
  }

  .cf-recon-list-section {
    display: flex;

    .cf-recon-list-step-filters {
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100vh - 136px);
      background: white;
    }

    .cf-table-wrapper {
      border-radius: 2px 0px 0px 0px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-bottom: 32px;
      max-height: calc(100vh - 136px); // 64 - header; 56 - search; 16 - margins;
      position: relative;
      background: #f3f3f3;
    }

    .cf-table {
      max-width: 100%;
      table-layout: fixed;
      
      .cf-table-header-row .cf-header-cell-title { padding: 0 }

      .cf-table-row > td, .cf-table-row > td > div {
        background: #fff;
      }

      .cf-table-row:hover > td > div {
        background-color: #fafafa;
      }

      .cf-table-row:hover > td:last-child {
        background-color: #fafafa;
      }

      .cf-table-row:hover > td:first-child > div {
        border-left: 4px solid #212121;
        border-top-left-radius: 3.5px;
        border-bottom-left-radius: 3.5px;
      }

      .cf-table-cell {
        height: 156px;
        border: none;
        padding: 0px;

        p {  white-space: normal; }

        > div {
          height: 100%;
          padding: 8px 8px;
          border: none;
          box-sizing: border-box;
          overflow: visible;
        }

        &:first-child > div {
          padding: 8px 4px;
          border-left: 4px solid transparent;
          
          svg {
            min-width: 24px;
            min-height: 24px;
          }
        }

        &:last-child > div {
          min-width: 230px;
        }
      }

      thead {
        position: sticky;
        top: 0px;
        background: #fff;
        z-index: 10;

        th {
          padding-left: 8px;
          padding-right: 8px;

          :first-child div[class*="sortIcon"] {
            margin: 8px;
          }
        }
      }
    }

    @media (max-width: 1600px) {
      .cf-table {
        .cf-table-cell > div {
          padding: 8px 4px;
        }

        thead th {
          padding-left: 4px;
          padding-right: 4px;
        }
      }
    }

    @media (max-width: 1350px) {
      .cf-table {
        &.MuiTable-root {
          width: unset;
        }
      }
    }
    
    @media print {
      .cf-table-wrapper {
        max-height: none!important;
        padding-bottom: 100%!important;

        table {
          max-width: 200px;
          
          * {
            font-size: 14px;
          }
          
          tr {
            td:nth-child(2) {
              max-width: 225px!important;
            }
          }
          
          .cf-recon-car-comment-text {
            p {
              font-size: 10px;
            }
          }

          .cf-recon-car-specs {
            span {
              :first-child {
                padding-right: 6px;
                min-width: 55px!important;
                max-width: 55px!important;
              }
              font-size: 8px;
            }
          }
        }
      }
    }
  }
`)
