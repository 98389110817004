import { css } from '@emotion/css'

export default css(`
  .top-block-wrapper {
    background: #FFF;
    margin-bottom: var(--content-margin-default);
    border-radius: 12px;
    position: relative;
  }
 
  .cf-no-data-block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    background: #fff;
    border-top: 8px solid #F3F3F3;
  }

  .cf-table-header {
    tr > th:last-child > div { justify-content: flex-end; }
  }

  .cf-cell-wrapper {
    height: 56px;
    padding: 16px 8px;
    box-sizing: border-box;
    background: #fff;
    
    &.aligned-right {
      text-align: right;
    }
  }

  .cf-table-row {
    .cell-account {
      display: flex;
      gap: 8px;
    }
    
    /**
     * account, description columns
     */
    td:nth-child(2), td:nth-child(4) > div {
      p {
        max-width: 12vw;
        @media (min-width: 1540px) {
          max-width: 14vw;
        }
        @media (min-width: 1640px) {
          max-width: 16vw;
        }
      }
    }

    /**
     * debit, credit columns
     */
    td:nth-child(5),td:nth-child(6) > div {
      p {
        max-width: 8vw;
        @media (min-width: 1540px) {
          max-width: 10vw;
        }
      }
    }

    td:last-child > div {
      display: flex;
      justify-content: flex-end;  
    }
  }

  .cf-table-row.cf-skeleton-row {
    .cf-table-cell > div {
      height: 56px;
      background-color: #fff;
    }
  }
`)
