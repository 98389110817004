import { css } from '@emotion/css'

export default css(`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  
  .cf-remove-button {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-right: 32px;
  }
  
  .cf-result-button {
    word-wrap: nowrap;
  }
`)
