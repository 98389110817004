import {
  type TransactionDetails as ApiTransactionDetails,
  type TransactionLine as ApiTransactionLine
} from 'api/types'
import { type TransactionLineRow } from 'types'
import { trimNumber } from 'utils/math'
import { namifyAccount } from 'utils/accounting/namifyAccount'
import { toDate } from 'utils/parse_date'
import parseCustomer from 'utils/accounting/parseCustomer'
import parseVendor from 'utils/accounting/parseVendor'

import type { TransactionDetails } from '../types'
import { getDefaultFormData } from '../constants'
import { parseBankingInfo, parseLineToControl, parseCommonParts } from './utils'

export const parseTransaction = (transaction: ApiTransactionDetails): TransactionDetails => {
  const {
    date,
    dueDate,
    description,
    lines,
    transactionTypeId,
    referenceNumber,
    memo = null,
    isLocked,
    isVehiclePayment,
    recurringTransactionTemplateId = null,
    isSystemCreated,
    transactionStateId
  } = transaction

  return {
    ...getDefaultFormData(),
    dateTime: toDate(date),
    dueDate: toDate(dueDate),
    description: description ?? '',
    rows: lines.map(parseTransactionLine),
    transactionTypeId: transactionTypeId ?? null,
    referenceNumber,
    memo,
    isLocked: isLocked ?? false,
    isVehiclePayment,
    recurringTransactionTemplateId,
    isSystemCreated,
    transactionStateId,
    ...parseCommonParts(transaction),
    ...parseBankingInfo(lines)
  }
}

export const parseTransactionLine = (line: ApiTransactionLine): TransactionLineRow => {
  const {
    notes,
    change,
    account,
    customer,
    vendor,
    id,
    bankStatementTransaction,
    lineEntryReconciliation
  } = line
  const parsedChange = trimNumber(change, 2)

  return {
    id,
    account: account != null ? namifyAccount(account) : null,
    control: parseLineToControl(line),
    entity: parseVendor(vendor) ?? parseCustomer(customer) ?? null,
    notes: notes ?? '',
    debits: change > 0 ? parsedChange : 0,
    credits: change < 0 ? Math.abs(parsedChange) : 0,
    bankStatementTransaction,
    lineEntryReconciliation
  }
}
