import type { FC, ChangeEvent } from 'react'
import { useRef, useMemo } from 'react'
import { cn, useClickOutside, noop } from '@carfluent/common'
import CheckboxLabel from 'components/common/CheckboxLabel'

import createUseStyles from './styles'
import { CheckboxType } from 'types'

interface ContentProps {
  popUpTitle: string
  isSelectAllAllowed: boolean
  isIndeterminate: boolean
  isSelectedAll: boolean
  onChangeAll: () => void
  filterItems: CheckboxType[]
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  isUniqItem: boolean
  popoverClassName: string
  maxWidth?: number
  onClose?: () => void
  isDisablePortal?: boolean
}

const ALL_ITEM_VALUE = { name: 'All', id: 0 }

const Content: FC<ContentProps> = ({
  popUpTitle,
  isSelectAllAllowed,
  isIndeterminate,
  isSelectedAll,
  onChangeAll,
  filterItems,
  onChange,
  isUniqItem,
  popoverClassName,
  maxWidth,
  onClose = noop,
  isDisablePortal = false
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null)
  const className = useMemo(() => createUseStyles({ maxWidth }), [])

  useClickOutside(contentRef, isDisablePortal ? onClose : noop, { capture: true })

  return (
    <div ref={contentRef} className={cn(className, popoverClassName, 'cf-content')}>
      <p className='cf-pop-up-title'>{popUpTitle}</p>
      {isSelectAllAllowed && (
        <div className='cf-all-items-block'>
          <CheckboxLabel
            value={{
              indeterminate: isIndeterminate,
              checked: isSelectedAll,
              ...ALL_ITEM_VALUE
            }}
            onChange={onChangeAll}
          />
        </div>
      )}
      <div className='cf-items-list'>
        {filterItems.map(item =>
          <div
            key={item.name}
            className={cn(
              'cf-item-block',
              isUniqItem ? `cf-${item.name.toLowerCase()}` : ''
            )}
          >
            <CheckboxLabel onChange={onChange} value={item} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Content
