import { serializers as S } from '@carfluent/common'

import { type RangeFilter } from 'types'
import type { DateType } from 'api/types'
import { type GetLeadsDashboardDto } from 'api/types/requests'
import serializeDateFilters from 'utils/filters/serializeDateFilters'

const serializeFilters = (filters: RangeFilter<Date | null>): GetLeadsDashboardDto | null => {
  const dateRange = serializeDateFilters(filters)

  if (dateRange == null) {
    return null
  }

  return {
    dateRange: serializeDateFilters(filters, S.serializeDateTimeUTC) as DateType
  }
}

export default serializeFilters
