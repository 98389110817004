import { WrapperRequest } from './wrapper.api'
import type { BaseListItem, DealId, EntityId } from 'types'
import type {
  CreditApplication,
  DealRequestConfig,
  Deal,
  DealCreateRequestDto,
  DealNoteUpdate,
  DealsListItem,
  DealsCounters,
  GetDealsListPayload,
  PaginatedResult,
  CancelationOptions,
  Payment,
  DealAccountingState,
  DealCashPayload,
  EditPaymentPayload,
  DeleteDocumentPayload,
  DocumentDealPayload,
  RequestDocumentsPayload,
  PaymentIntentPayload,
  GrossProfitReport,
  GrossProfitReportPayload,
  GrossProfitReportXlsxDownloadPayload,
  ListResponse,
  SalesTaxReportDto,
  SalesTaxReportRequestDto,
  LienholderCreateRequestDto,
  LienholderItemDto,
  LienholderUpdateRequestDto,
  ListPayload
} from 'api/types'

import {
  type DashboardDealsModel,
  type DealModel,
  type DealRecapDto,
  type DealerFeeMarkupSettingsDto,
  type DealFeesAndCoveragesSettingsModel,
  type SaleReportDto,
  type SalesCommissionDto
} from 'api/types/responses'

import {
  type GetDashboardDealsDto,
  type UpdateFinancingFeesDto,
  type UpdateDealDto,
  type UpdateSalesTaxDto,
  type GenerateSaleReportDto
} from 'api/types/requests'

import type {
  BusinessTypeDto,
  CreditAppRequestDto,
  WorkflowTypeDto,
  DealsTotalGetRequestDto,
  PurchasingDetailsDto,
  DealVehiclePatchRequestDto,
  DealBuyerPatchRequestDto,
  UpdateCoverageRequestDto
} from 'api/types/customersCore.types'

import {
  parseDashboardDealsDto,
  parseDealDto,
  parseDealFeesSettingsDto
} from 'api/mappers/responses'

class CustomersCoreAPI extends WrapperRequest {
  postNewDeal = async (data: DealCreateRequestDto): Promise<string> => {
    return await this.post('/api/v1/Dealers/deal', data)
  }

  getDeals = async (payload: GetDealsListPayload = { take: 20, skip: 0 }, cancelationOptions?: CancelationOptions): Promise<PaginatedResult<DealsListItem>> => {
    return await this.post('/api/v1/Dealers/deals', payload, cancelationOptions)
  }

  getDealsTotal = async ({
    search = '',
    workflowAccountingStates = null,
    isDeposited,
    date = null,
    cancelationOptions,
    nextAction,
    dealPaidStatusId
  }: DealsTotalGetRequestDto): Promise<DealsCounters> => {
    return await this.post('/api/v1/Dealers/deals/total', {
      search,
      workflowAccountingStates,
      isDeposited,
      date,
      nextAction,
      dealPaidStatusId
    }, cancelationOptions)
  }

  /**
   * @deprecated - use `getDeal2`
   */
  getDeal = async (dealId: number | string): Promise<Deal> => {
    return await this.get(`/api/v1/Dealers/deals/${dealId}`)
  }

  getDeal2 = async (dealId: number | string): Promise<DealModel> => {
    const response = await this.get(`/api/v1/Deals/${dealId}`)
    return parseDealDto(response)
  }

  getDepositedDeals = async (inventoryVehicleId: number | string): Promise<ListResponse<number>> => {
    return await this.get(`/api/v1/Dealers/deals/deposited?inventoryVehicleId=${inventoryVehicleId}`)
  }

  getDealForDashboard = async (dealId: number | string): Promise<DealsListItem> => {
    return await this.get(`/api/v1/Dealers/deals/${dealId}/dashboardInfo`)
  }

  patchDeal2 = async (config: DealRequestConfig<UpdateDealDto>): Promise<DealModel> => {
    const { dealId, rowVersion, data } = config
    const parameters = {
      data,
      headers: {
        WorkflowId: dealId,
        WorkflowVersion: rowVersion
      }
    }

    const response = await this.patch(`/api/v1/Deals/${dealId}`, parameters)
    return parseDealDto(response)
  }

  patchDealSalesTax = async (config: DealRequestConfig<UpdateSalesTaxDto>): Promise<DealModel> => {
    const { dealId, rowVersion, data } = config
    const parameters = {
      data,
      headers: {
        WorkflowId: dealId,
        WorkflowVersion: rowVersion
      }
    }

    const response = await this.patch(`/api/v1/Deals/${dealId}/sales-tax`, parameters)
    return parseDealDto(response)
  }

  patchDealInventoryTax = async (config: DealRequestConfig<number>): Promise<DealModel> => {
    const { dealId, rowVersion } = config
    const parameters = {
      data: {
        dealerInventoryTax: config.data
      },
      headers: {
        WorkflowId: dealId,
        WorkflowVersion: rowVersion
      }
    }

    const response = await this.patch(`/api/v1/Deals/${dealId}/dealer-inventory-tax`, parameters)
    return parseDealDto(response)
  }

  patchDealVehicle = async (config: DealRequestConfig<DealVehiclePatchRequestDto>): Promise<DealModel> => {
    const { dealId, rowVersion, data } = config
    const parameters = {
      data,
      headers: {
        WorkflowId: dealId,
        WorkflowVersion: rowVersion
      }
    }

    const response = await this.patch(`/api/v1/Deals/${dealId}/vehicles`, parameters)
    return parseDealDto(response)
  }

  patchDealBuyer = async (config: DealRequestConfig<DealBuyerPatchRequestDto>): Promise<DealModel> => {
    const { dealId, rowVersion, data } = config
    const parameters = {
      data,
      headers: {
        WorkflowId: dealId,
        WorkflowVersion: rowVersion
      }
    }

    const response = await this.patch(`/api/v1/Deals/${dealId}/buyer`, parameters)
    return parseDealDto(response)
  }

  updateDealNotes = async (payload: DealNoteUpdate): Promise<{ rowVersion: string }> => {
    const { dealId, notes } = payload

    return await this.post(`/api/v1/Dealers/deal/${dealId}/notes`, { notes })
  }

  patchDealForCreditApp = async (config: CreditAppRequestDto): Promise<CreditApplication> => {
    const { dealId, rowVersion, data } = config

    const parameters = {
      data,
      headers: {
        WorkflowId: dealId,
        WorkflowVersion: rowVersion
      }
    }

    return await this.patch(`/api/v1/Dealers/deal/${dealId}/credit-application`, parameters)
  }

  dealCheck = async (dealId: EntityId): Promise<void> => {
    return await this.patch(`/api/v1/Dealers/deal/${dealId}/check`)
  }

  updateDealCash = async ({ dealId, data }: DealCashPayload): Promise<DealModel> => {
    const response = await this.patch(`/api/v1/Dealers/deal/${dealId}/cash`, data)
    return parseDealDto(response)
  }

  postDocumentDeal = async ({ dealId, data, rowVersion }: DocumentDealPayload): Promise<DealModel> => {
    const parameters = {
      data,
      headers: {
        WorkflowId: dealId,
        WorkflowVersion: rowVersion
      }
    }

    const response = await this.post(`/api/v1/Dealers/deal/${dealId}/documents`, parameters)
    return parseDealDto(response)
  }

  deleteDocumentDeal = async ({ dealId, data, rowVersion }: DeleteDocumentPayload): Promise<DealModel> => {
    const parameters = {
      data,
      headers: {
        WorkflowId: dealId,
        WorkflowVersion: rowVersion
      }
    }

    const response = await this.delete(`/api/v1/Dealers/deal/${dealId}/documents`, parameters)
    return parseDealDto(response)
  }

  postRequestDocumentsDeal = async ({ dealId, data }: RequestDocumentsPayload): Promise<void> => {
    return await this.post(`/api/v1/Dealers/deal/${dealId}/documents/request`, data)
  }

  submitCreditApplication = async (config: DealRequestConfig): Promise<void> => {
    const { dealId, rowVersion } = config
    const parameters = {
      headers: {
        WorkflowId: dealId,
        WorkflowVersion: rowVersion
      }
    }

    return await this.post(`/api/v1/Dealers/deal/${dealId}/credit-application/submit`, parameters)
  }

  setDealStatus = async (dealId: DealId, state: string): Promise<void> => {
    return await this.patch(`/api/v1/Dealers/deals/${dealId}/state?state=${state}`)
  }

  setDealAccountingStatus = async (dealId: EntityId, rowVersion: string, state: DealAccountingState): Promise<void> => {
    const parameters = {
      headers: {
        WorkflowId: dealId,
        WorkflowVersion: rowVersion
      }
    }

    return await this.patch(`/api/v1/Dealers/deals/${dealId}/accounting-state?state=${state}`, parameters)
  }

  getDealRecap = async (dealId: DealId): Promise<DealRecapDto> => {
    return await this.get(`/api/v1/Dealers/deal/${dealId}/recap`)
  }

  updateDealFinancingFees = async (payload: UpdateFinancingFeesDto): Promise<DealModel> => {
    const { dealId, rowVersion, ...data } = payload
    const parameters = {
      data,
      headers: {
        WorkflowId: dealId,
        WorkflowVersion: rowVersion
      }
    }
    const response = await this.patch(`/api/v1/Deals/${dealId}/financing-fees`, parameters)
    return parseDealDto(response)
  }

  createPayment = async (dealId: number | string, body: Payment): Promise<{ receiptFileId: number | null }> => {
    return await this.post(`/api/v1/Dealers/deals/${dealId}/payments`, body)
  }

  updatePayment = async (dealId: number | string, receiptId: number, body: EditPaymentPayload): Promise<{ receiptFileId: number | null }> => {
    return await this.patch(`/api/v1/Dealers/deals/${dealId}/receipt/${receiptId}`, body)
  }

  voidReceipt = async (dealId: number | string, receiptId: number): Promise<void> => {
    return await this.patch(`/api/v1/Dealers/deals/${dealId}/receipt/${receiptId}/void`)
  }

  refundReceipt = async (dealId: number | string, receiptId: number): Promise<void> => {
    return await this.patch(`/api/v1/Dealers/deals/${dealId}/receipt/${receiptId}/refund`)
  }

  updateCoverage = async ({ dealId, rowVersion, coverages }: UpdateCoverageRequestDto): Promise<DealModel> => {
    const parameters = {
      data: {
        coverages
      },
      headers: {
        WorkflowId: dealId,
        WorkflowVersion: rowVersion
      }
    }
    const response = await this.patch(`api/v1/Deals/${dealId}/coverage`, parameters)
    return parseDealDto(response)
  }

  getDealFeesSettings = async (dealId: number | string): Promise<DealFeesAndCoveragesSettingsModel> => {
    const response = await this.get(`/api/v1/CoverageFeeSettings/${dealId}`)
    return parseDealFeesSettingsDto(response)
  }

  /**
   * Sales Commission
   */

  getDealSalesCommission = async (dealId: number | string): Promise<SalesCommissionDto> => {
    return await this.get(`/api/v1/Deals/${dealId}/sales-commission`)
  }

  patchDealSalesCommission = async (config: DealRequestConfig<number | null>): Promise<DealModel> => {
    const { dealId, rowVersion } = config
    const parameters = {
      data: {
        overridenSalesCommission: config.data
      },
      headers: {
        WorkflowId: dealId,
        WorkflowVersion: rowVersion
      }
    }

    const response = await this.patch(`/api/v1/Deals/${dealId}/sales-commission`, parameters)
    return parseDealDto(response)
  }

  // ========================================== //
  //                Dictionaries                //
  // ========================================== //

  getHousingStatuses = async (): Promise<BaseListItem[]> => {
    return await this.get('/api/v1/Financing/housing-statuses')
  }

  getEmploymentStatuses = async (): Promise<BaseListItem[]> => {
    return await this.get('/api/v1/Financing/employment-status-types')
  }

  getCoApplicantTypes = async (): Promise<BaseListItem[]> => {
    return await this.get<BaseListItem[]>('/api/v1/Financing/coapplicant-types')
  }

  getWholesaleSourceTypes = async (): Promise<BaseListItem[]> => {
    return await this.get('/api/v1/Financing/wholesale-source-types')
  }

  // ========================================== //
  //                  Payments                  //
  // ========================================== //

  async paymentIntent (payload: PaymentIntentPayload): Promise<{clientSecret: string}> {
    return await this.post('/api/v1/Payments/dealer/intent', payload)
  }

  async paymentAccount (dealerId: string | number): Promise<{ accountId: string }> {
    return await this.get(`/api/v1/Payments/account/${dealerId}`)
  }

  // ========================================== //
  //                  Reports                   //
  // ========================================== //

  getGrossProfitReport = async (payload: GrossProfitReportPayload, cancelationOptions?: CancelationOptions): Promise<GrossProfitReport> => {
    return await this.post('/api/v1/Reports/gross-profit', payload, cancelationOptions)
  }

  downloadGrossProfitReportXlsx = async (data: GrossProfitReportXlsxDownloadPayload): Promise<Blob> => {
    return await this.post('/api/v1/Reports/gross-profit/xlsx', {
      data,
      additionalParams: {
        responseType: 'blob'
      }
    })
  }

  getSalesTaxReport = async (payload: SalesTaxReportRequestDto, cancelationOptions?: CancelationOptions): Promise<SalesTaxReportDto> => {
    return await this.post('/api/v1/Reports/sales-tax', payload, cancelationOptions)
  }

  downloadSalesTaxReportXlsx = async (data: SalesTaxReportDto): Promise<Blob> => {
    return await this.post('/api/v1/Reports/sales-tax/xlsx', {
      data,
      additionalParams: {
        responseType: 'blob'
      }
    })
  }

  getDealSaleReport = async (
    payload: GenerateSaleReportDto,
    cancelationOptions?: CancelationOptions
  ): Promise<SaleReportDto> => {
    const { take, skip, ...restPayload } = payload
    return await this.post('/api/v1/Reports/sale', restPayload, cancelationOptions)
  }

  downloadDealSaleReportXlsx = async (data: SaleReportDto): Promise<Blob> => {
    return await this.post('/api/v1/Reports/sale/xlsx', {
      data,
      additionalParams: {
        responseType: 'blob'
      }
    })
  }

  // ========================================== //
  //                References                  //
  // ========================================== //

  getWorkflowTypes = async (): Promise<ListResponse<WorkflowTypeDto>> => {
    return await this.get('/api/v1/Reference/workflow-types')
  }

  getBusinessTypes = async (): Promise<ListResponse<BusinessTypeDto>> => {
    return await this.get('/api/v1/Reference/business-types')
  }

  getPurchasingDetails = async (id: number): Promise<PurchasingDetailsDto> => {
    return await this.get(`/api/v1/Vehicles/${id}/purchasing-details`)
  }

  // ========================================== //
  //                  Lienholders                  //
  // ========================================== //

  createLienholder = async (payload: LienholderCreateRequestDto): Promise<LienholderItemDto> => {
    return await this.post('/api/v1/Lienholders', payload)
  }

  getLienholderList = async (payload: ListPayload = { take: 20, skip: 0 }, cancellationOptions?: CancelationOptions): Promise<PaginatedResult<LienholderItemDto>> => {
    return await this.post('/api/v1/Lienholders/list', payload, cancellationOptions)
  }

  getLienholder = async (id: number): Promise<LienholderItemDto> => {
    return await this.get(`/api/v1/Lienholders/${id}`)
  }

  updateLienholder = async ({ id, ...payloadProps }: LienholderUpdateRequestDto): Promise<LienholderItemDto> => {
    return await this.patch(`/api/v1/Lienholders/${id}`, payloadProps)
  }

  removeLienholder = async (id: number): Promise<void> => {
    return await this.delete(`/api/v1/Lienholders/${id}`)
  }

  getSettings = async (): Promise<DealerFeeMarkupSettingsDto> => {
    return await this.get('/api/v1/DealerFeeMarkupSettings')
  }

  patchSettings = async (payload: DealerFeeMarkupSettingsDto): Promise<DealerFeeMarkupSettingsDto> => {
    return await this.patch('/api/v1/DealerFeeMarkupSettings', payload)
  }

  // ========================================== //
  //                Dashboard                   //
  // ========================================== //

  getDashboardDeals = async (
    payload: GetDashboardDealsDto,
    cancellationOptions?: CancelationOptions
  ): Promise<DashboardDealsModel> => {
    const resp = await this.post('/api/v1/Dashboard/deals', payload, cancellationOptions)
    return parseDashboardDealsDto(resp)
  }

  // ========================================== //
}

const CustomersCoreApiProvider = new CustomersCoreAPI()
export default CustomersCoreApiProvider
