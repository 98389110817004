import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import DocumentsApiProvider from 'api/documents.api'
import getUniqueName from 'utils/getUniqueName'

import type { DealFormState } from './types'

export interface UseDealFormsTabProps {
  dealId: string
  isDealApproved: boolean
}

export interface UseDealFormsTabReturn {
  state: DealFormState
  setState: Dispatch<SetStateAction<DealFormState>>
}

const useDealFormsTab = ({ dealId, isDealApproved }: UseDealFormsTabProps): UseDealFormsTabReturn => {
  const [state, setState] = useState<DealFormState>({
    documents: [],
    envelopes: []
  })

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      const [forms, envelopes] = await Promise.all([
        DocumentsApiProvider.getDocumentDealForms(dealId),
        DocumentsApiProvider.getEnvelopes(dealId)
      ])

      const uniqItems = forms.items.map((item) => ({
        ...item,
        uniqId: getUniqueName(`${item.id}${item.name}`)
      }))

      setState({
        documents: uniqItems,
        envelopes: envelopes.items
      })
    }

    void runEffect()
  }, [dealId, isDealApproved])

  return { state, setState }
}

export default useDealFormsTab
