import type { FC } from 'react'

import { TimelineLogTypes } from 'api/types'
import type { IconProps } from 'types'

import CallIcon from './CallIcon'
import AppointmentIcon from './AppointmentIcon'
import SendIcon from './SendIcon'
import StoreVisitIcon from './StoreVisitIcon'
import SwappedTiresIcon from './SwappedTiresIcon'
import MessageSentIcon from './MessageSentIcon'
import MessageIcon from './MessageIcon'
import EmailIcon from './EmailIcon'
import EmailSentIcon from './EmailSentIcon'
import CommentTimelineIcon from './CommentTimelineIcon'
import PersonIcon from './PersonIcon'
import CarIcon from './CarIcon'
import CaseIcon from './CaseIcon'
import UnableToEmail from './UnableToEmail'
import UnableToMessage from './UnableToMessage'
import CreditAppReceivedIcon from './CreditAppReceivedIcon'
import LeadLostIcon from './LeadLostIcon'
import IconSVGMuteBell from '../IconSVGMuteBell'
import IconSVGBell from '../IconSVGBell'

interface TimelineIconsProps extends IconProps {
  icon: TimelineLogTypes
}

const TimelineIcons: FC<TimelineIconsProps> = ({ icon, ...props }) => {
  switch (icon) {
    case TimelineLogTypes.Call:
      return <CallIcon {...props} />
    case TimelineLogTypes.UnplannedCall:
      return <CallIcon {...props} />
    case TimelineLogTypes.Appointment:
      return <AppointmentIcon {...props} />
    case TimelineLogTypes.Reminder:
      return <SendIcon {...props} />
    case TimelineLogTypes.StoreVisit:
      return <StoreVisitIcon {...props} />
    case TimelineLogTypes.SwappedTired:
      return <SwappedTiresIcon {...props} />
    case TimelineLogTypes.MessageSent:
      return <MessageSentIcon {...props} />
    case TimelineLogTypes.MessageReceived:
      return <MessageIcon {...props} />
    case TimelineLogTypes.EmailReceived:
      return <EmailIcon {...props} />
    case TimelineLogTypes.EmailSent:
      return <EmailSentIcon {...props} />
    case TimelineLogTypes.Comment:
      return <CommentTimelineIcon {...props} />
    case TimelineLogTypes.LeadAssigned:
      return <PersonIcon {...props} />
    case TimelineLogTypes.LeadManuallyCreated:
      return <PersonIcon {...props} />
    case TimelineLogTypes.LeadReceived:
      return <PersonIcon {...props} />
    case TimelineLogTypes.LeadLost:
      return <LeadLostIcon {...props} />
    case TimelineLogTypes.CarOfInterestIsDeposited:
    case TimelineLogTypes.CarOfInterestAdded:
      return <CarIcon {...props} />
    case TimelineLogTypes.DealManuallyCreated:
      return <CaseIcon {...props} />
    case TimelineLogTypes.LeadAutomaticallyCreated:
      return <PersonIcon {...props} />
    case TimelineLogTypes.DealStatusUpdated:
      return <CaseIcon {...props} />
    case TimelineLogTypes.EmailSentAutomatically:
      return <EmailSentIcon {...props} />
    case TimelineLogTypes.UnableToSendEmail:
      return <UnableToEmail {...props} />
    case TimelineLogTypes.UnableToSendMessage:
      return <UnableToMessage {...props} />
    case TimelineLogTypes.MessageSentAutomatically:
      return <MessageSentIcon {...props} />
    case TimelineLogTypes.CreditAppReceived:
      return <CreditAppReceivedIcon {...props} />
    case TimelineLogTypes.AutomatedMessagesPaused:
      return <IconSVGMuteBell width={16} height={16} color='#21212180' {...props} />
    case TimelineLogTypes.AutomatedMessagesResumed:
      return <IconSVGBell color='#21212180' {...props} />

    default:
      return null
  }
}

export default TimelineIcons
