import type { FC } from 'react'

import Button from 'components/common/Button'

import CLASS_NAME from './styles'

interface ActionBarProps {
  onRespondedSubmit: () => void
  onNoResponseNeededSubmit: () => void
  onViewEmailClick: () => void
  isLoading: boolean
  buttonsClassName?: string
}

const ActionBar: FC<ActionBarProps> = ({
  buttonsClassName = '',
  isLoading,
  onRespondedSubmit,
  onNoResponseNeededSubmit,
  onViewEmailClick
}) => {
  const taskSpecificButtons = [
    {
      label: 'I RESPONDED',
      onClick: onRespondedSubmit,
      'data-test-id': 'schedule-task-btn-i-responded'
    },
    {
      label: 'NO RESPONSE NEEDED',
      onClick: onNoResponseNeededSubmit,
      'data-test-id': 'schedule-task-btn-no-response-needed'
    },
    {
      label: 'VIEW EMAIL',
      onClick: onViewEmailClick,
      'data-test-id': 'schedule-task-btn-view-email'
    }
  ]

  return (
    <div className={CLASS_NAME}>
      {taskSpecificButtons.map(({
        onClick,
        'data-test-id': dataTestId,
        label
      }, index) => (
        <Button
          key={index}
          className={buttonsClassName}
          disabled={isLoading}
          type='secondary'
          onClick={onClick}
          data-test-id={dataTestId}
        >
          {label}
        </Button>
      ))}
    </div>
  )
}

export default ActionBar
