import { useContext, useEffect, useState, useCallback } from 'react'

import { type BaseListItem } from 'types'
import { type ListResponse } from 'api/types'
import AccountingApiProvider from 'api/accounting.api'
import SettingsCTX from 'store/settings'

export interface UseCostTypesReturn {
  getCostTypes: () => Promise<ListResponse>
  costTypes: BaseListItem[]
}

const useCostTypes = (): UseCostTypesReturn => {
  const [types, setTypes] = useState<BaseListItem[]>([])
  const { isAccountingEnabled } = useContext(SettingsCTX)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const getTypes = useCallback(async (): Promise<ListResponse> => {
    if (!isAccountingEnabled) {
      return { items: [] }
    }

    return await AccountingApiProvider.getCostTypes() // cacheable
  }, [isAccountingEnabled])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (!isAccountingEnabled) {
      return
    }

    const load = async (): Promise<void> => {
      try {
        const res = await AccountingApiProvider.getCostTypes()
        setTypes(res.items)
      } catch {
        console.error('Cost types failed to load')
      }
    }

    void load()
  }, [isAccountingEnabled])

  // ========================================== //

  return {
    costTypes: types,
    getCostTypes: getTypes
  }
}

export default useCostTypes
