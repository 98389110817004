import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGMessage: FC<IconProps> = ({ color = '#000', width = 16, height = 16, opacity = '0.3' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_86093_1701)'>
        <path
          d='M13.333 2.66797H2.66634C1.93301 2.66797 1.33967 3.26797 1.33967 4.0013L1.33301 12.0013C1.33301 12.7346 1.93301 13.3346 2.66634 13.3346H13.333C14.0663 13.3346 14.6663 12.7346 14.6663 12.0013V4.0013C14.6663 3.26797 14.0663 2.66797 13.333 2.66797ZM13.333 12.0013H2.66634V5.33464L7.99967 8.66797L13.333 5.33464V12.0013ZM7.99967 7.33464L2.66634 4.0013H13.333L7.99967 7.33464Z'
          fill={color}
          fillOpacity={opacity}
        />
      </g>
      <defs>
        <clipPath id='clip0_86093_1701'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGMessage
