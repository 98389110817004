import { css } from '@emotion/css'

export default css(`
  display: flex;
  justify-content: flex-end;
  
  &.btn-disabled {
    .link-button {
      color: rgba(33, 33, 33, 0.40);
      pointer-events: none;
    }
  }
  
  .link-button {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-decoration-line: underline;
    text-align: right;
  }
`)

export const POPOVER_CLASS_NAME = css(`
  &&.cf-popover-content {
    padding: 4px 12px;
    max-width: 330px;
    background-color: #212121;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`)
