import { css } from '@emotion/css'

export const createStyleClass = (): string => {
  return css(`
    --table-background-color: #F3F3F3;
    --table-row-border: 8px solid var(--table-background-color);
    --table-item-background-color: #FFFFFF;
    --table-cell-height: 56px;
    --table-padding: 16px;
    
    .cf-item-disabled {
      opacity: 0.4;
      
      * {
        pointer-events: none;
      }
    }

    & {
      display: grid;
      grid-template-columns: minmax(0, 610px) minmax(0, 1000px);
      grid-row-gap: 32px;
      grid-column-gap: 16px;

      & .all-costs-title, .cf-cost-details-title {
        font-family: Roboto, sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 24px;
      }

      & .breakdown-component {
        justify-self: center;
        grid-column: 0;
      }

      & .cf-journal-section {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & .all-costs-table, .cf-cost-details-table, .cf-cost-summary-table {
        margin-bottom: 24px;
        .cf-table {
          overflow: hidden;
        }

        & th * {
          color: #101010
        }

        &.cf-cost-details-table, .all-costs-table {
          & * {
            color: #101010;
          }
        }

        &.cf-cost-details-table, .cf-cost-summary-table {
          max-width: 750px;
          & .cf-empty-embedded-block-message {
            color: rgba(33, 33, 33, 0.5);
          }
        }

        & tr {
          display: flex;
          align-items: center;
          height: var(--table-cell-height);
          box-sizing: content-box;
          border: var(--table-row-border);
          background-color: var(--table-item-background-color);
          border-radius: 12px;

          .cf-cell-wrapper {
            background-color: var(--table-item-background-color);
          }
        }

        & thead th:last-child > div {
          justify-content: flex-end;
        }

        & .MuiTableContainer-root {
          border-radius: 4px;
          
          & table {
            display: flex;
            flex-direction: column;
            background-color: var(--table-background-color);

            & th * {
              font-weight: 500;
            }

            & th, & td {
              border-bottom: 0px;
              height: 100%;

              & div {
                height: 100%;
                border: 0;
              }
            }

            & td:first-child > div, & th:first-child > div {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }

            & td:last-child > div, & th:last-child > div {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }

          & .MuiTableBody-root {
            & tr {
              border-top: 0px;

              &:hover td div.cf-cell-wrapper {
                background-color: #FAFAFA;
                border: 0;
              }

              & [class*=cf-cell-wrapper] {
                height: var(--table-cell-height);
              }

              & td:last-child > div {
                display: flex;
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }

    .cf-empty-embedded-block-wrapper {
      display: flex;
      border: 8px solid #f3f3f3;
      margin-bottom: -1px;
      border-radius: 4px 4px 0 0;
      height: 56px;
      box-sizing: content-box;
      background-color: #f3f3f3;
  
      .cf-empty-embedded-block-content {
        flex: 1;
        background-color: #fff;
        border-radius: 2px;
        padding-left: 16px;
        display: flex;
        align-items: center;
      }
  
      
      .cf-empty-embedded-block-message {
        font-family: Roboto, sans-serif;
        color: rgba(33, 33, 33, 0.5);
        font-size: 16px;
      }
    }

    .add-new-row {
      height: 56px;
      border: var(--table-row-border);
      background-color: var(--table-background-color);
      display: flex;
      border-top: 0px;
      border-radius: 0px 0px 4px 4px;
      margin-top: -1px;
      padding-left: var(--table-padding);
      box-sizing: content-box;

      .cf-button-root {
        border: none;
        width: 100%;
        border-radius: 0;
        justify-content: flex-start;
        padding: 0px 0px 0px var(--table-padding);
        width: calc(100% + var(--table-padding));
        margin: 0 0 0 calc(-1*var(--table-padding));
        
        & * {
          font-weight: 500;
          font-size: 16px;
        }
        
        :hover span {
          opacity: 0.5;
          background: #fff;
        }

        .cf-button-start-adornment {
          height: 20px;
          margin-right: 0;
          margin-left: 0;
          margin-left: -4px;
          margin-right: 8px;
        }
        
        .cf-button-content {
          max-width: 96px;
          text-transform: none;
          font-weight: 600;
        }
      }
    }
  `)
}
