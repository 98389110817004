import { PaymentTransactionTypeEnum } from 'types/payments'
import { PaymentStatusId } from 'types/number_enums'
import { type PaymentDetailsDto, PaymentMethod } from 'api/types'
import { type FinancesPaymentDetailsModel } from 'api/types/responses'

const getPaymentReduceDefaultValue = (): FinancesPaymentDetailsModel => ({
  cashPaymentDetails: null,
  payments: [],
  totalAmount: 0
})

export const GET_FINANCES_PAYMENT_DETAILS_MODEL = (
  paymentDetails: PaymentDetailsDto[]
): FinancesPaymentDetailsModel => {
  return paymentDetails.reduce<FinancesPaymentDetailsModel>((res, payment) => {
    const isCashPayment = payment.transactionTypeId === PaymentTransactionTypeEnum.CASH

    if (isCashPayment) {
      res.cashPaymentDetails = payment
    }

    if (payment.paymentMethodId === PaymentMethod.Full) {
      res.payments.push(payment)
    }

    if (payment.paymentStatusId === PaymentStatusId.Pending) {
      res.totalAmount = res.totalAmount + payment.amount
    }

    return res
  }, getPaymentReduceDefaultValue())
}
