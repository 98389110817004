import { type FC } from 'react'
import { type MenuIconProps } from 'types'

const CrmTasksMenuIcon: FC<MenuIconProps> = ({
  isActive = false
}) => {
  const pictogramFill = isActive ? '#fff' : '#101010'

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 3H9V7H3V3ZM15 10H21V14H15V10ZM15 17H21V21H15V17ZM13 13H7V18H13V20H5V9H7V11H13V13Z'
        fill={pictogramFill}
      />
    </svg>
  )
}

export default CrmTasksMenuIcon
