enum Events {
  DealSaveRequested = '@DEALS/EVENT_DEAL_SAVE_REQUESTED',
  DealShowWarranties = '@DEALS/EVENT_DEAL_SHOW_WARRANTIES',
  DealShowSignForm = '@DEALS/EVENT_DEAL_SHOW_SIGN_FORM',
  ScheduleBalanceDateRequested = '@SCHEDULES/EVENT_SCHEDULE_BALANCE_DATE_REQUESTED',
  BankStatementsIncreaseTabCounter = '@BANK_STATEMENTS/INCREASE_TAB_COUNTER',
  BankStatementsDecreaseTabCounter = '@BANK_STATEMENTS/DECREASE_TAB_COUNTER'
}

export default Events
