import { css } from '@emotion/css'

export default css`
  position: relative;
  margin-left: 0!important;

  .cf-md {
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    
    .g-filters-panel {
      margin-bottom: 24px;
    }
    
    @media screen and (max-width: 1360px) {
      width: 100%;
      margin: 0;
      max-width: 100%;
    }
    
    @media screen and (min-width: 1440px) {
      width: 100%;
      margin: 0 auto;
    }

    @media screen and (min-width: 1500px) {
      width: 1480px;
      margin: 0 auto;
    }
  }
  
  .cf-report-top-actions {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .cf-top-wrapper {
    margin-bottom: 24px;
  }
  
  .table-wrapper {
    .total-panel {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 16px;
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      margin-bottom: 24px;
    }

    .cf-table-wrapper {
      .cf-no-data-block {
        border-top: none;
      }
    }

    .cf-table {
      .cf-table-header-row {
        border: none;
        background: #F3F3F3;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        .cf-table-header-cell {
          :nth-child(1) {
            max-width: 175px!important;

            .cf-header-cell-title {
              min-width: 140px!important;
            }
            
            @media screen and (max-width: 1360px) {
              max-width: 80px!important;

              .cf-header-cell-title {
                min-width: 40px!important;
              }
            }
          }

          :nth-child(2) {
            max-width: 70px!important;

            .cf-header-cell-title {
              min-width: 40px!important;
            }
          }

          :nth-child(8) {
            max-width: 120px!important;

            .cf-header-cell-title {
              min-width: 100px!important;
              padding: 2px;
            }

            .cf-sort-icon-wrapper {
              margin-left: 2px!important;
            }
          }

          :nth-child(6) {
            max-width: 130px!important;

            .cf-header-cell-title {
              min-width: 110px!important;
              padding: 2px;
            }

            .cf-sort-icon-wrapper {
              margin-left: 2px!important;
            }
          }

          :nth-child(11) {
            max-width: 110px!important;

            .cf-header-cell-title {
              min-width: 100px!important;
            }

            .cf-sort-icon-wrapper {
              margin-left: 0px!important;
            }
          }

          :nth-child(9), :nth-child(10) {
            max-width: 108px!important;

            .cf-header-cell-title {
              min-width: 84px!important;
              padding: 2px!important;
            }
          }
        }
      }

      .cf-table-row-hover:hover>td:first-child>div {
        border-left: none;
        padding-left: 16px;
      }


      .MuiTableBody-root {
        .cf-table-row {
          border: none;

          .cf-table-cell {
            :nth-child(1) {
              max-width: 175px!important;
              
              .cf-cell-wrapper {
                a {
                  background: transparent;
                }

                span {
                  white-space: nowrap;
                }
              }
              
              @media screen and (max-width: 1360px) {
                max-width: 80px!important;
              }
            }

            :nth-child(8) {
              max-width: 100px!important;
            }

            :nth-child(6) {
              max-width: 110px!important;
            }

            :nth-child(2) {
              max-width: 70px!important;
            }

            :nth-child(7) {
              max-width: 120px!important;
            }

            :nth-child(9), :nth-child(10) {
              max-width: 108px!important;
            }

            :nth-child(11) {
              max-width: 120px!important;
            }
          }
        }

        .cf-table-group-row {
          height: 31px;
          box-sizing: border-box;
          border: none;

          .cf-divider-left, .cf-divider-right {
            display: none;
          }

          .cf-table-group-cell {
            height: 32px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
`
