import type { FC, ReactNode } from 'react'
import { cnx, CellWrapper } from '@carfluent/common'

import CLASS_NAME from './styles'
import TooltipItem from 'components/common/TooltipItem'

export interface TextCellHOCProps {
  className?: string
  isAlignedRight?: boolean
  isHeader?: boolean
  text: ReactNode | string
  withTooltip?: boolean
}

export interface TextCellProps extends TextCellHOCProps {}

const TextCellHOC = (props: TextCellHOCProps) => () => <TextCell {...props} />

const TextCell: FC<TextCellProps> = ({
  className,
  isAlignedRight = false,
  isHeader = true,
  text,
  withTooltip = false
}) => {
  if (isHeader) {
    return (
      withTooltip && typeof text === 'string'
        ? (
          <div className={cnx(CLASS_NAME, className, 'tooltip-container')}>
            <TooltipItem
              className={cnx(className, 'truncated-text')}
              value={text}
            />
          </div>
          )
        : (
          <div className={cnx(CLASS_NAME, 'cf-header-cell-title', isAlignedRight && 'aligned-right', className)}>
            <p>{text}</p>
          </div>
          )
    )
  }

  return (
    <CellWrapper className={cnx(isAlignedRight && 'aligned-right', className)}>
      <p>{text}</p>
    </CellWrapper>
  )
}

export default TextCellHOC
