export const upperCaseLettersRegex = /[A-Z]/g
export const lowerCaseLettersRegex = /[a-z]/g
export const onlyLatinCharactersRegex = /^[a-zA-Z]+[a-zA-Z\s-]*$/
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).*$/
export const aptRegex = /^[a-zA-Z0-9]*(?:[/-][a-zA-Z0-9]+)*$/
export const vinRegex = /\b[(A-H|J-N|P|R-Z|0-9)]{17}\b/i
export const addressRegex = /^(#?[\d-]+) (.+)$/
export const guidRegex = /(([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12}))/
export const nameRegexp = /^[a-zA-Z'\s.-]+$/
export const alphanumericWithSymbolsAndApostropheRegex = /^[0-9a-zA-Z'\s.\-:,/#]+$/
export const alphanumericWithSymbolsRegex = /^[0-9a-zA-Z\s.\-:,/#]+$/
export const alphanumericRegex = /^[a-zA-Z0-9]+$/
export const cyrillicRegex = /\p{Script_Extensions=Cyrillic}/u
export const linkRegex = /https?:\/\/\S+/g
export const imgRegex = /<img (.*?)>/g
export const iframeRegex = /<iframe (.*?)>/g
export const aRegex = /<a (.*?)>/g
export const imgInCarBlockRegex = /data-car-img-block/g

export const isAnyUpperCase = (value: string): boolean => {
  return value.match(upperCaseLettersRegex) != null
}

export const isAnyLowerCase = (value: string): boolean => {
  return value.match(lowerCaseLettersRegex) != null
}

export const isVin = (value: string): boolean => {
  return vinRegex.test(value ?? '')
}
