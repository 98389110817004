import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import type { SortingInfo } from '@carfluent/common'
import omit from 'lodash-es/omit'
import type { Row } from '@tanstack/react-table'

import CRMApiProvider from 'api/crm.api'
import { getTasksListDebounced, getTasksListTotalDebounced } from 'api/helpers'
import {
  type DictionaryItem,
  type TaskListPayload,
  type ListResponse,
  type Task,
  TaskStateEnum,
  TaskFilterEnum
} from 'api/types'
import { TasksLeadView } from 'constants/route_helper'
import { calcTableHeight } from 'utils/math'
import { API_CALL_DELAY, PAGE_SIZE, TABLE_MIN_HEIGHT } from 'constants/constants'
import useAsyncEffect from 'hooks/useAsyncEffect'
import useTableApi from 'hooks/useTableApi'

import type {
  LoadCountersDebounced,
  LoadListDebounced,
  RowId,
  TaskListFilters,
  TasksListCounters,
  UseTasksListReturn
} from './types'
import {
  CANCEL_LOAD_COUNTERS,
  CANCEL_LOAD_DATA,
  DEFAULT_COUNTERS,
  DEFAULT_FILTER,
  DEFAULT_FILTERS,
  DEFAULT_SORTING,
  DEFAULT_SORTING_COMPLETED_FILTER,
  DEFAULT_SORTING_TODAY_AND_OLDER_FILTER,
  Errors,
  FiltersKeys,
  GET_DEFAULT_TASK_FILTERS
} from './constants'
import getColumnsDefinition from './columns'
import useWSUpdates from './useWSUpdates'
import { serializeFilters } from './serializer'
import parseCountersData from './parser'
import expandAssignedToOptions from './utils'

const EMPTY_STATE_TEXT = 'No tasks found'

const loadCountersDebounced: LoadCountersDebounced = async (payload, cancelationOptions) =>
  await getTasksListTotalDebounced(payload, cancelationOptions)

const loadListDebounced: LoadListDebounced = async (payload, cancelationOptions) =>
  await getTasksListDebounced(payload, cancelationOptions)

const useTasksList = (): UseTasksListReturn => {
  const [counters, setCounters] = useState<TasksListCounters>(DEFAULT_COUNTERS)
  const [leadStatusList, setLeadStatusList] = useState<DictionaryItem[]>([])
  const [assignedToList, setAssignedToList] = useState<DictionaryItem[]>([])
  const [isFiltersChange, setFiltersChange] = useState(false)
  const [isScrolling, setScrolling] = useState(false)
  const [isInitLoading, setIsInitLoading] = useState(true)
  /**
   * For web sockets updates we need to hide loader and skeleton
   */
  const [isLoadingInvisible, setLoadingInvisible] = useState(false)

  const navigate = useNavigate()

  const getTasksList = useCallback(async (payload: TaskListPayload): Promise<ListResponse<Task>> => {
    try {
      setFiltersChange(true)

      const [countersResp, listResp] = await Promise.all([
        loadCountersDebounced(omit(payload, 'sortField', 'sortOrder', 'skip', 'take'), CANCEL_LOAD_COUNTERS),
        loadListDebounced(payload, CANCEL_LOAD_DATA)
      ])

      const parsedData = parseCountersData(countersResp)
      setCounters(parsedData)

      return { ...listResp }
    } catch (err) {
      setCounters(DEFAULT_COUNTERS)
      return { items: [] }
    } finally {
      setFiltersChange(false)
      setLoadingInvisible(false)
      setIsInitLoading(false)
    }
  }, [])

  const {
    rows,
    search,
    sorting,
    filters,
    isLoading,
    loadRows,
    setRows,
    setFilters,
    setSorting,
    setSearch,
    startLoader,
    stopLoader,
    onSearch
  } = useTableApi<Task, TaskListFilters>({
    getList: getTasksList,
    serializeFilters,
    cancelationOptions: CANCEL_LOAD_DATA,
    defaultFilters: DEFAULT_FILTERS,
    defaultSorting: DEFAULT_SORTING,
    apiCallDelaySearch: API_CALL_DELAY
  })

  const determineSorting = (currentFilterValue: number, sortingInfo: SortingInfo): SortingInfo => {
    if (currentFilterValue === TaskFilterEnum.Completed) {
      return DEFAULT_SORTING_COMPLETED_FILTER
    }

    if (currentFilterValue === TaskFilterEnum.Today) {
      return DEFAULT_SORTING_TODAY_AND_OLDER_FILTER
    }

    if (currentFilterValue === TaskFilterEnum.All) {
      return DEFAULT_SORTING
    }

    return sortingInfo
  }

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const getLeadStatusIds = useCallback(async () => {
    try {
      const { items } = await CRMApiProvider.getStatusList()
      setLeadStatusList(items)
    } catch {
      console.error(Errors.Statuses)
    }
  }, [])

  const getAssigneeIds = useCallback(async () => {
    try {
      const { items } = await CRMApiProvider.getAssigneeList()
      const expandedList = expandAssignedToOptions(items)

      setAssignedToList(expandedList)
    } catch {
      console.error(Errors.AssignedTo)
    }
  }, [])

  const onTableFiltersChange = useCallback((column: FiltersKeys) => async (ids: number[]) => {
    await setFilters({ [column]: ids, search })
  }, [search, setFilters])

  const onTaskFilterChange = useCallback((_, value: DictionaryItem | null) => {
    const resolvedValueId = value?.id ?? taskListFiltersOptions[0].id
    const newSorting = determineSorting(resolvedValueId, sorting)

    void Promise.all([
      setFilters({ taskFilterEnum: resolvedValueId, search }),
      setSorting(newSorting)
    ])
  }, [search, sorting, setFilters, setSorting])

  const onSelectTab = useCallback(async (taskStateFilterId: TaskStateEnum | null) => {
    if (taskStateFilterId != null) {
      const payload: Partial<TaskListFilters> = { taskStateEnum: taskStateFilterId, search, taskFilterEnum: DEFAULT_FILTER }

      void Promise.all([
        setFilters(payload),
        setSorting(DEFAULT_SORTING)
      ])
    }
  }, [search, filters.taskFilterEnum, setFilters, setSorting])

  const onOpenLead = useCallback((row: Row<Task>) => {
    navigate(TasksLeadView(row.original.leadId))
  }, [navigate])

  const onBottomReached = useCallback(async () => {
    if (!isLoading && TABLE_MIN_HEIGHT <= calcTableHeight(rows.length)) {
      setScrolling(true)
      await loadRows({ forceRefresh: false })
      setScrolling(false)
    }
  }, [isLoading, loadRows, rows.length])

  /**
   * Updates Tasks table by WebSocket.
   * For create and update events.
   */
  const onTasksUpdated = useCallback(async () => {
    setLoadingInvisible(true)
    const take = rows.length < PAGE_SIZE ? PAGE_SIZE : rows.length

    await loadRows({ forceRefresh: true, pageSize: take })
  }, [rows.length, loadRows])

  const onTasksDeleted = useCallback((taskIds: RowId[]) => {
    setLoadingInvisible(true)
    const nextRows = rows.filter(({ id }) => !taskIds.includes(id))

    setRows(nextRows)
  }, [rows, setRows])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Initial data loading.
   */
  useAsyncEffect(async () => {
    startLoader()

    await Promise.all([
      getLeadStatusIds(),
      getAssigneeIds(),
      loadRows()
    ])

    stopLoader()
  }, [startLoader, stopLoader])

  /**
   * Updates Tasks table by WebSocket events.
   */
  useWSUpdates({
    onTasksDeleted,
    onTasksUpdated
  })

  // ========================================== //
  const { taskStateEnum: tabFilter, taskFilterEnum: taskFilter, ...columnsFilters } = filters

  const columns = useMemo(() => getColumnsDefinition({
    assignedToList,
    leadStatusList,
    filters: columnsFilters,
    onChange: onTableFiltersChange,
    taskFilter
  }), [
    assignedToList, leadStatusList, columnsFilters,
    onTableFiltersChange, taskFilter
  ])

  const taskListFiltersOptions = useMemo(() => {
    return GET_DEFAULT_TASK_FILTERS(filters.taskStateEnum === TaskStateEnum.Appointments)
  }, [filters.taskStateEnum])

  return {
    columns,
    rows,
    counters,
    search,
    sorting,
    tabFilter,
    taskFilter,
    emptyState: EMPTY_STATE_TEXT,
    isLoading: isLoading && !isLoadingInvisible,
    isFiltersChange: isFiltersChange && !isScrolling && !isLoadingInvisible,
    onOpenLead,
    onSelectTab,
    setSearch,
    setSorting,
    onBottomReached,
    onSearch,
    onTaskFilterChange,
    taskListFiltersOptions,
    isInitLoading
  }
}

export default useTasksList
