import { css } from '@emotion/css'

export default css(`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: 92px;
  max-width: 92px;
  padding: 5px 12px;
  height: 24px;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0.25px;
  text-align: center;
  border: none;
  user-select: none;

  &.deal-status-pending {
    background-color: #FFA451;
    color: #FFFFFF;
  }

  &.deal-status-incomplete {
    background-color: #DDDDDD;
    color: rgba(33, 33, 33, 0.8);
  }

  &.deal-status-complete {
    background-color: #CFE6D9;
    color: #0e4c29;
  }

  &.deal-status-canceled {
    background-color: rgba(33, 33, 33, 0.08);
    color: rgba(33, 33, 33, 0.40);
  }
`)
