import type { FC } from 'react'
import type { IconProps } from 'types'

const CarIcon: FC<IconProps> = ({ color = '#21212180', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_86546_28390)'>
        <path d='M12.6133 4.00683C12.48 3.6135 12.1067 3.3335 11.6667 3.3335H4.33333C3.89333 3.3335 3.52667 3.6135 3.38667 4.00683L2.07333 7.78683C2.02667 7.92683 2 8.0735 2 8.22683V13.0002C2 13.5535 2.44667 14.0002 3 14.0002C3.55333 14.0002 4 13.5535 4 13.0002V12.6668H12V13.0002C12 13.5468 12.4467 14.0002 13 14.0002C13.5467 14.0002 14 13.5535 14 13.0002V8.22683C14 8.08016 13.9733 7.92683 13.9267 7.78683L12.6133 4.00683ZM4.33333 10.6668C3.78 10.6668 3.33333 10.2202 3.33333 9.66683C3.33333 9.1135 3.78 8.66683 4.33333 8.66683C4.88667 8.66683 5.33333 9.1135 5.33333 9.66683C5.33333 10.2202 4.88667 10.6668 4.33333 10.6668ZM11.6667 10.6668C11.1133 10.6668 10.6667 10.2202 10.6667 9.66683C10.6667 9.1135 11.1133 8.66683 11.6667 8.66683C12.22 8.66683 12.6667 9.1135 12.6667 9.66683C12.6667 10.2202 12.22 10.6668 11.6667 10.6668ZM3.33333 7.3335L4.18 4.78683C4.27333 4.52016 4.52667 4.3335 4.81333 4.3335H11.1867C11.4733 4.3335 11.7267 4.52016 11.82 4.78683L12.6667 7.3335H3.33333Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_86546_28390'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CarIcon
