import type { FC } from 'react'

interface TwoArrowToggleIconProps {
  isToggleToOpen: boolean
}

const TwoArrowToggleIcon: FC<TwoArrowToggleIconProps> = ({
  isToggleToOpen
}) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: isToggleToOpen ? 'rotate(0deg)' : 'rotate(180deg)' }}
    >
      <path
        d='M6.4 18L5 16.6L9.575 12L5 7.4L6.4 6L12.4 12L6.4 18ZM13 18L11.6 16.6L16.175 12L11.6 7.4L13 6L19 12L13 18Z'
        fill='#101010'
      />
    </svg>
  )
}

export default TwoArrowToggleIcon
