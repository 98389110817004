import { type FC, useState, useCallback } from 'react'
import { Modal } from '@carfluent/common'

import type { ReceiptItem } from 'api/types'
import CustomersCoreApiProvider from 'api/customersCore.api'
import DocumentsApiProvider from 'api/documents.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useCloudAccessToken from 'hooks/useCloudAccessToken'
import Table from 'components/common/Table'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'

import PaymentForm from 'components/deals/PaymentForm'

import {
  ModalTitles,
  ModalContents,
  SubmitBtnNames,
  MIN_GLOBAL_MODAL_WIDTH,
  MIN_CONFIRM_MODAL_WIDTH
} from './constants'
import getColumnDefs from './columns'
import CLASS_NAME from './styles'

interface ReceiptsInfoProps {
  isOpen: boolean
  onClose: () => void
  receipts: ReceiptItem[]
  dealId: string | number
  setIsFormSubmitted: (isSubmitted: boolean) => void
  onRefreshDeal: () => Promise<void>
  vin: string
  dealerId: string | number
}

const ReceiptsInfo: FC<ReceiptsInfoProps> = ({
  isOpen,
  onClose,
  receipts,
  dealId,
  setIsFormSubmitted,
  onRefreshDeal,
  vin,
  dealerId
}) => {
  const [refundId, setRefundId] = useState<number | null>(null)
  const [voidId, setVoidId] = useState<number | null>(null)
  const [isRefundLoading, setIsRefundLoading] = useState(false)
  const [isVoidLoading, setIsVoidLoading] = useState(false)
  const { showAlert } = useCustomSnackbar()
  const { token = '' } = useCloudAccessToken() ?? {}
  const [editedReceipt, setEditedReceipt] = useState<ReceiptItem | null>(null)

  const onRefundSubmit = async (): Promise<void> => {
    if (refundId == null) {
      return
    }

    try {
      setIsFormSubmitted(true)
      setIsRefundLoading(true)
      await CustomersCoreApiProvider.refundReceipt(dealId, refundId)
      await onRefreshDeal()
      setRefundId(null)
    } catch {
      showAlert('Cannot refund receipt.')
      setIsFormSubmitted(false)
    } finally {
      setIsRefundLoading(false)
    }
  }

  const onVoidSubmit = async (): Promise<void> => {
    if (voidId == null) {
      return
    }

    try {
      setIsFormSubmitted(true)
      setIsVoidLoading(true)
      await CustomersCoreApiProvider.voidReceipt(dealId, voidId)
      await onRefreshDeal()
      setVoidId(null)
    } catch {
      setIsFormSubmitted(false)
      showAlert('Cannot refund receipt.')
    } finally {
      setIsVoidLoading(false)
    }
  }

  const onCloseRefund = (): void => setRefundId(null)
  const onCloseVoid = (): void => setVoidId(null)
  const onRefund = (id: number): void => setRefundId(id)
  const onVoid = (id: number): void => setVoidId(id)
  const onPrint = async (fileId: number): Promise<void> => {
    const { uri } = await DocumentsApiProvider.getFile(fileId)
    window.open(`${uri}?${token}`, '_blank')
  }

  const onCloseEditForm = useCallback((): void => {
    setEditedReceipt(null)
  }, [])

  const columns = getColumnDefs({ onRefund, onPrint, onEdit: setEditedReceipt, onVoid })

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={CLASS_NAME}
      minWidth={MIN_GLOBAL_MODAL_WIDTH}
      title='Receipts'
    >
      <Table<ReceiptItem>
        columns={columns}
        data={receipts}
      />

      <Modal
        isOpen={refundId != null}
        onClose={onCloseRefund}
        title={ModalTitles.Refund}
        minWidth={MIN_CONFIRM_MODAL_WIDTH}
        renderFooterActions={(
          <CancelSubmitActionsFooter
            onSubmit={onRefundSubmit}
            isLoading={isRefundLoading}
            onClose={onCloseRefund}
            submitTitle={SubmitBtnNames.Refund}
          />
        )}
      >
        {ModalContents.Refund}
      </Modal>

      <Modal
        isOpen={voidId != null}
        onClose={onCloseVoid}
        title={ModalTitles.Void}
        minWidth={MIN_CONFIRM_MODAL_WIDTH}
        renderFooterActions={(
          <CancelSubmitActionsFooter
            onSubmit={onVoidSubmit}
            isLoading={isVoidLoading}
            onClose={onCloseVoid}
            submitTitle={SubmitBtnNames.Void}
          />
        )}
      >
        {ModalContents.Void}
      </Modal>

      <PaymentForm
        isOpen={editedReceipt != null}
        onClose={onCloseEditForm}
        onSubmit={onRefreshDeal}
        vin={vin}
        dealId={dealId}
        dealerId={dealerId}
        paymentMethod={editedReceipt?.paymentMethodId ?? null}
        setIsFormSubmitted={setIsFormSubmitted}
        defaultValues={editedReceipt}
        id={editedReceipt?.id ?? null}
      />
    </Modal>
  )
}

export default ReceiptsInfo
