import { DraftDecorator, ContentBlock } from 'draft-js'

import type { TextRange } from 'components/wysiwyg/types'
import UploadVideoLink from 'components/wysiwyg/editor/UploadVideoLink'
import { VIDEO_BLOCK_START, VIDEO_BLOCK_END } from 'utils/wysiwyg'

export const findVideoBlock = (text: string): TextRange | null => {
  const startIdx = text.indexOf(VIDEO_BLOCK_START)
  const endIdx = text.indexOf(VIDEO_BLOCK_END)

  if ((startIdx > -1) && (endIdx > -1)) {
    return {
      start: startIdx,
      startLength: VIDEO_BLOCK_START.length,
      end: endIdx,
      endLength: VIDEO_BLOCK_END.length
    }
  }

  return null
}

const findVideoBlockStrategy = (
  block: ContentBlock,
  callback: (start: number, end: number) => void
): void => {
  const range = findVideoBlock(block.getText())

  if (range != null) {
    callback(range.start, range.end + range.endLength)
  }
}

const videoDecorator: DraftDecorator = {
  strategy: findVideoBlockStrategy,
  component: UploadVideoLink
}

export const getVideoDecoratorWithProps = (props: DraftDecorator['props']): DraftDecorator => ({
  ...videoDecorator,
  props
})

export default videoDecorator
