import { css } from '@emotion/css'

export default css`
  background: #fff;
  padding: 23px;
  border-radius: 2px 12px 12px 12px;
  
  .additional-info {
    font-size: 12px;
    color: rgba(33, 33, 33, 0.50);
  }
  
  .cf-dropzone {
    padding: 1px;

    .cf-dnd-overlay {
      margin: 1px;
    }
  }

  .cf-dropzone .cf-content-wrapper {
    background: white;
    padding: 1px;
    overflow: hidden;
  }

  .cf-drop-zone-content {
    .cf-drag-capture {
      width: auto;
    }
  }

  .cf-dnd-overlay {
    background: white;
    opacity: 0.9;
    border: 2px dashed #C99B86;
  }
  
  .empty-doc {
    height: 56px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    color: rgba(33, 33, 33, 0.50);
  }

  .deal-doc-table-wrapper {
    background: #F3F3F3;
    border-radius: 4px;
    padding: 8px;
    margin-top: 16px;
    
    .doc-table-header {
      height: 48px;
      background: #FAFAFA;
      display: grid;
      grid-template-columns: 1fr 190px 336px;
      align-items: center;
      padding-left: 16px;

      > div {
        font-weight: 500;
        color: #101010;
      }
    }
  }
`
