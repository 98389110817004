import { Routes } from 'constants/route_helper'
import { matchRoutes, RouteMatch, RouteObject } from 'react-router-dom'

export const isPageAllowUnauthorized = (pathname: string): boolean => {
  const allowUnauthorizedRoutes = [
    Routes.ExpiredLink,
    Routes.Reset,
    Routes.SetupAccount
  ]

  return allowUnauthorizedRoutes.some(route => pathname.includes(route))
}

export const joinPaths = (url1: string, url2: string): string => {
  return `${url1}/${url2}`.replace(/\/\//g, '/')
}

export const asRoute = (path: string): string => `/${path}`

export const matchRouteParent = (routes: RouteObject[], pathname: string): RouteMatch[] => {
  const matches = routes.filter(route => {
    const routePathParts = route.path?.split('/').filter(path => path !== '')
    const pathnameParts = pathname.split('/').filter(path => path !== '')

    return routePathParts?.every((part, i) => {
      return part === pathnameParts[i]
    })
  })

  return matches.map(match => ({
    params: {},
    pathname,
    pathnameBase: '',
    route: match
  }))
}

export const matchRouteOrParent = (routes: RouteObject[], location: Partial<Location>): RouteMatch[] => {
  return matchRoutes(routes, location) ?? matchRouteParent(routes, location.pathname ?? '')
}

export const isUrlMatching = (url: string, routesUrls: string | string[]): boolean => {
  return typeof routesUrls === 'string'
    ? routesUrls === url
    : routesUrls.includes(url)
}
