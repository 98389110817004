import {
  type WebsiteSettingsDto,
  type WebsiteSettingsModel
} from 'api/types/responses'

const parseWebsiteSettingsDto = (data: WebsiteSettingsDto): WebsiteSettingsModel => ({
  apr: data.apr,
  term: data.term,
  cashDownAmount: data.cashDownAmount,
  cashDownPercentage: data.cashDownPercentage,
  isEstimatedMonthlyPaymentEnabled: data.isEstimatedMonthlyPaymentEnabled,

  heroData: {
    header: data.homePageHeading1,
    description: data.homePageHeading2
  },
  carAds: {
    ads1: {
      header: data.vdpIcon1Heading1,
      description: data.vdpIcon1Heading2
    },
    ads2: {
      header: data.vdpIcon2Heading1,
      description: data.vdpIcon2Heading2
    },
    ads3: {
      header: data.vdpIcon3Heading1,
      description: data.vdpIcon3Heading2
    }
  }
})

export default parseWebsiteSettingsDto
