import { type FC, useCallback, useEffect, useRef, useState } from 'react'
import { cnx } from '@carfluent/common'
import { EditorState as DraftEditorState } from 'draft-js'
import { Editor, EditorState } from 'react-draft-wysiwyg'
import type { EditorProps } from 'react-draft-wysiwyg'
import debounce from 'lodash-es/debounce'

import CLASS_NAME from './styles'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

interface EditorComponentProps {
  description?: EditorState | null
  className?: string
  customBlockRenderFunc?: EditorProps['customBlockRenderFunc']
  customDecorators?: EditorProps['customDecorators']
  onChange: (value: EditorState) => void
  placeholder?: string
  toolbarCustomButtons?: EditorProps['toolbarCustomButtons']
  isReadOnly?: boolean
}

export const EDITOR_CONTAINER_CLASS = 'text-editor-container'
const UPDATE_DELAY = 500

const EditorComponent: FC<EditorComponentProps> = ({
  className,
  customBlockRenderFunc,
  customDecorators,
  description,
  onChange,
  placeholder,
  toolbarCustomButtons,
  isReadOnly = false
}) => {
  const [editorState, setEditorState] = useState<EditorState>(DraftEditorState.createEmpty())
  const [focused, setFocused] = useState<boolean>(false)
  const isEditorSelectionFixedRef = useRef(false)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onFocus = useCallback(() => {
    setFocused(prev => !prev)
  }, [])

  const onFieldValueChange = useCallback(debounce(onChange, UPDATE_DELAY), [onChange])

  const onEditorStateChange = useCallback((nextState: EditorState) => {
    setEditorState(nextState)
    onFieldValueChange(nextState)
  }, [onFieldValueChange, editorState])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * We need to update forceSelection only the first time editor is focused.
   */
  useEffect(() => {
    if ((focused != null) && !isEditorSelectionFixedRef.current && editorState != null) {
      isEditorSelectionFixedRef.current = true

      setEditorState(DraftEditorState.forceSelection(
        editorState, editorState.getSelection()
      ))
    }
  }, [editorState, focused])

  /**
   * Updates local state when props is changed.
   */
  useEffect(() => {
    void setEditorState(description ?? DraftEditorState.createEmpty())
  }, [description])

  return (
    <Editor
      wrapperClassName={cnx(CLASS_NAME, className)}
      editorClassName={EDITOR_CONTAINER_CLASS}
      toolbarClassName='text-editor-toolbar'
      onFocus={onFocus}
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      placeholder={placeholder}
      toolbarCustomButtons={toolbarCustomButtons}
      customBlockRenderFunc={customBlockRenderFunc}
      customDecorators={customDecorators}
      readOnly={isReadOnly}
    />
  )
}

export default EditorComponent
