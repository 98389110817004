import type { FC } from 'react'
import { FormDropdown, cnx, type UseFormReturn } from '@carfluent/common'
import type { OptionRendererConfig, Item } from '@carfluent/common/dist/UI/Dropdown/types'

import TooltipedText from 'components/common/TooltipedText'

import { formatPhoneNumberForView } from 'utils/view_helper'
import type { ReconStepItemFormData, UserItemNotifications } from 'pages/reconditioning/Settings/Steps/hook/types'

import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

interface UserCellProps {
  dealerAccounts: UserItemNotifications[]
  onChange: UseFormReturn<ReconStepItemFormData[]>['onChange']
  isEditMode: boolean
  id: string
  value: number[]
}

const renderOption = (data: UserItemNotifications, other: OptionRendererConfig): JSX.Element => {
  return (
    <div className='render-option'>
      {other.renderCheckbox(other.isSelected)}

      <div className='option-content'>
        <p>
          {data.name}
        </p>

        <span>
          {
            data.phoneNumber != null && data.phoneNumber.trim() !== ''
              ? formatPhoneNumberForView(data.phoneNumber)
              : 'No phone number'
          }
        </span>
      </div>
    </div>
  )
}

const isOptionDisabled = (item: Item<UserItemNotifications>): boolean => {
  return item.value.phoneNumber == null || item.value.phoneNumber.trim() === ''
}

const UserCell: FC<UserCellProps> = ({
  id,
  dealerAccounts,
  onChange: _onChange,
  value,
  isEditMode
}) => {
  const selectedAccounts = dealerAccounts.filter(({ id }) => value.includes(id))
  const onChange = (_: string, _val: UserItemNotifications[] | null): void => {
    const nextValue = _val?.map(({ id }) => id) ?? []
    _onChange(id, nextValue)
  }

  return (
    <div className={cnx(CLASS_NAME, !isEditMode && 'non-editable')}>
      {isEditMode
        ? (
          <FormDropdown
            id={id}
            label={selectedAccounts.length > 0 ? undefined : 'Select user'}
            options={dealerAccounts}
            onChange={onChange}
            renderOption={renderOption}
            isOptionDisabled={isOptionDisabled}
            popoverClassName={POPOVER_CLASS_NAME}
            value={selectedAccounts}
            isMultiselect
            disableSelectAll
          />
          )
        : (
          <TooltipedText
            value={
              selectedAccounts.map(({ name }, i) =>
                <span key={name}>{name}{i !== (selectedAccounts.length - 1) ? ', ' : ''}</span>)
            }
          />
          )}

    </div>
  )
}

export default UserCell
