import type { FC } from 'react'
import { memo } from 'react'
import { Menu, MenuItem } from '@material-ui/core'
import type { MenuProps } from '@material-ui/core'

import filterActiveIcon from 'assets/filter_active_icon.svg'
import type { UseHeaderProps } from './hook'
import { useHeader } from './hook'
import CLASS_NAME from './styles'

const ANCHOR_ORIGIN: MenuProps['anchorOrigin'] = {
  vertical: 'bottom',
  horizontal: 'right'
}
const TRANSFORM_ORIGIN: MenuProps['transformOrigin'] = {
  vertical: -5,
  horizontal: 'right'
}

const Header: FC<UseHeaderProps> = (props) => {
  const {
    onOpen,
    onClose,
    anchorEl,
    filterItems,
    selectedFilter,
    selectedFilterText
  } = useHeader(props)

  return (
    <div className={CLASS_NAME}>
      <p className='cf-timeline-title'>Timeline</p>
      <div>
        <button className='cf-timeline-button' onClick={onOpen}>
          <img src={filterActiveIcon} alt='' />
          <span className='cf-show-filters-button-label'>
            Show: {selectedFilterText}
          </span>
        </button>
        <Menu
          onClose={onClose}
          anchorEl={anchorEl}
          open={anchorEl != null}
          anchorOrigin={ANCHOR_ORIGIN}
          transformOrigin={TRANSFORM_ORIGIN}
          getContentAnchorEl={null}
        >
          {filterItems.map(({
            title,
            onClick,
            disabled = false,
            id
          }) => (
            <MenuItem
              selected={id === selectedFilter}
              key={title}
              disabled={disabled}
              onClick={onClick}
            >
              {title}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  )
}

export default memo(Header)
