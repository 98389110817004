import { type DeepRequired } from '@carfluent/common'

import { toDateOrNull } from 'utils/parse_date'
import { type BaseListItem } from 'types'
import { type VehicleCostDto, CostTypeId } from 'api/types'

import { type CostFormData } from './types'

export interface ParseCostFormProps {
  costDetails: VehicleCostDto
  costTypes: BaseListItem[]
}

export const parseCostDetails = ({
  costDetails,
  costTypes
}: ParseCostFormProps): DeepRequired<CostFormData> => {
  const costTypeItem = costTypes.find(c => c.id === costDetails.vehicleCostTypeId)
  const isPurchaseCostType = costDetails.vehicleCostTypeId === CostTypeId.Purchase

  return {
    ...costDetails,
    date: toDateOrNull(costDetails.date),
    costType: costTypeItem ?? null,
    creditAccountId: costDetails.creditAccount?.id ?? null,
    description: costDetails.description ?? '',
    debitAccountId: costDetails.debitAccount?.id ?? null,
    purchaseAccountId: isPurchaseCostType ? (costDetails.creditAccount?.id ?? null) : null,
    referenceNumber: costDetails.referenceNumber ?? ''
  }
}
