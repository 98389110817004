import { css } from '@emotion/css'

export default css(`
  .fee-item {
    display: flex;
    box-sizing: border-box;
    padding-bottom: 16px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: #FFF;
    height: 174px;

      .fee-field-settings-header {
        display: flex;
        padding: 8px 16px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        background: #FAFAFA;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        height: 40px;
      }

      &.active {
        .fee-field-settings-header {
          background: #ECF4FF;
        }
      }
      
      .fee-field-settings-content {
        height: 100px;
        width: 100%;
        padding: 0px 16px;
        display: grid;
        grid-template-areas:
          '.        . . .'
          'checkboxes . . .';
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 56px 20px;
        row-gap: 24px;
        column-gap: 16px;
        
        .cf-form-field {
          width: 100%;
        }
        
        .checkboxes {
          display: flex;
          align-items: center;
          gap: 32px;
          grid-area: checkboxes;

          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
  }
  
  /**
   * Toggle overrides.
   */
  .field-is-enabled {
    label { margin: 0px; }
    
    .cf-main-block {
      background-color: #0000004D;
      
      &.active { background-color: #219653; }
    }
  }
  
  /**
   * Checkbox field overrides.
   */
  .checkboxes {
    > label {
      margin: 0px;
    }

    .MuiFormControlLabel-label {
      margin-left: 4px;
    }

    .cf-checkbox-icon {
      padding: 0px;
    }
    
    .tooltip-content {
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px; 
    }
    
    svg {
      transform: scale(0.75, 0.75);
    }
  }

  &.fee-field-loading {
    height: 172px;

    span {
      height: 100%;
    }
  }
`)
