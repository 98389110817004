import { type FC, useState } from 'react'
import { Button, cnx, parsers } from '@carfluent/common'

import IconSVG from 'components/inlineImages'
import type { DealFormEnvelopeDto } from 'api/types'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { formatDate } from 'utils/parse_date'

import CLASS_NAME from './styles'

export interface EnvelopRowCbProps {
  onVoid: (id: number) => void
  onResend: (id: number) => void
  onSign: (id: number) => void
  onView: (ids: number[]) => Promise<void>
  onViewSignedContract: (id: number) => Promise<void>
}

export type EnvelopRowProps = EnvelopRowCbProps & DealFormEnvelopeDto

const SIGNERS_WITH_CO_BUYER = 4

enum SignersTypes {
  Sent = 0,
  Customer,
  CoBuyer,
  Dealer
}

const TITLES = {
  [SignersTypes.Sent]: 'Sent for signature',
  [SignersTypes.Customer]: 'Customer signature',
  [SignersTypes.CoBuyer]: 'Co-buyer signature',
  [SignersTypes.Dealer]: 'Dealership signature'
}

const ROLES = {
  [SignersTypes.Sent]: 'Sender',
  [SignersTypes.Customer]: 'Customer',
  [SignersTypes.CoBuyer]: 'Customer',
  [SignersTypes.Dealer]: 'Admin'
}

const EnvelopRow: FC<EnvelopRowProps> = ({
  onVoid,
  onResend,
  onSign,
  onView: _onView,
  onViewSignedContract: _onViewSignedContract,
  ...data
}) => {
  const { showAlert } = useCustomSnackbar()
  const [isViewing, setIsViewing] = useState(false)
  const [isViewingSigned, setIsViewingSigned] = useState(false)

  const signers = [
    {
      signerTypeId: SignersTypes.Sent,
      firstName: data.sentByUser.dealerFirstName,
      lastName: data.sentByUser.dealerLastName,
      signedDate: data.sentDate
    },
    ...data.signers
  ]

  signers.sort((a, b) => a.signerTypeId - b.signerTypeId)

  const isSignActive = signers.some(({
    signerTypeId,
    signedDate
  }) => signerTypeId === SignersTypes.Customer && signedDate != null)

  const isSignedContract = data.fileId != null

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onView = async (): Promise<void> => {
    try {
      setIsViewing(true)
      await _onView(data.forms.map(({ id }) => id))
      showAlert('Download successfully', { variant: 'success' })
    } catch {
      showAlert('Cannot download contract.')
    } finally {
      setIsViewing(false)
    }
  }

  const onViewSignedContract = async (id: number | null): Promise<void> => {
    if (id == null) {
      return
    }

    try {
      setIsViewingSigned(true)
      await _onViewSignedContract(id)
    } catch {
      showAlert('Cannot open signed contract.')
    } finally {
      setIsViewingSigned(false)
    }
  }

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, data.voidedByUser != null && 'is-voided')}>
      <div className='sub-row'>
        <div className='col-left-side'>
          <div
            className={cnx(
              'status-mark',
              isSignedContract && 'completed',
              data.voidedByUser != null && 'voided'
            )}
          />

          <div className='col-name-block'>
            <p>{data.name}</p>

            <p className='col-name-block-description'>
              {data.forms.map(({ name }) => name).join(', ')}
            </p>
          </div>
        </div>

        <div className='col-right-side'>
          {(data.voidedByUser != null) && (
            <>
              <p className='voided-text'>
                VOIDED {formattedDate(data.voidedDate, 'MM/dd/yy')} by {data.voidedByUser.dealerFirstName} {data.voidedByUser.dealerLastName}
              </p>

              <Button
                isLoading={isViewing}
                onClick={onView}
                variant='text'
              >
                Original contract
              </Button>

              <Button onClick={() => onResend(data.id)} variant='text'>
                Resend
              </Button>
            </>
          )}

          {(data.voidedByUser == null) && (
            <>
              {isSignedContract && (
                <Button
                  onClick={() => {
                    void onViewSignedContract(data.fileId)
                  }}
                  variant='text'
                  isLoading={isViewingSigned}
                >
                  Signed Contract
                </Button>
              )}

              {!isSignedContract && (
                <Button isDisabled={!isSignActive} onClick={() => onSign(data.id)} variant='text'>
                  Sign
                </Button>
              )}

              <Button
                isLoading={isViewing}
                onClick={onView}
                variant='text'
              >
                Original contract
              </Button>

              <Button onClick={() => onResend(data.id)} variant='text'>
                Resend
              </Button>

              <Button onClick={() => onVoid(data.id)} variant='text'>
                Void
              </Button>
            </>
          )}
        </div>
      </div>

      <div className={cnx('sign-content', signers.length === SIGNERS_WITH_CO_BUYER && 'with-co-buyer')}>
        {signers.map(({
          signerTypeId,
          signedDate,
          firstName,
          lastName
        }) => (
          <div key={`${firstName}_${lastName}_${formattedDate(signedDate)}`} className='sign-item'>
            <div className={cnx('sign-checked-block', signedDate != null && 'is-signed')}>
              <IconSVG.IconSVGCheck /> {TITLES[signerTypeId as SignersTypes]}
            </div>

            <div className={cnx('sign-item-text', 'text-sender')}>
              <p className='sign-item-text-label'>{ROLES[signerTypeId as SignersTypes]}:</p>
              <p className='sign-item-text-value'>{firstName} {lastName}</p>
            </div>

            <div className='sign-item-text'>
              <p className='sign-item-text-label'>Date& time:</p>
              <p className='sign-item-text-value'>{formattedDate(signedDate)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default EnvelopRow

// ========================================== //

const formattedDate = (date: string | null, format = 'MM/dd/yy hh:mm aa'): string => {
  const parsedDate = parsers.parseDateStringUTC(date)

  if (parsedDate == null) {
    return '-'
  }

  return formatDate(parsedDate, format)
}
