import { type FC } from 'react'
import Tab from 'components/common/Tabs/components/Tabs/Tab'

import { type TabDealProps } from './types'
import DealForm from './DealForm'

const TabDeal: FC<TabDealProps> = (props) => {
  return (
    <Tab isSelected={props.isSelected}>
      <DealForm {...props} />
    </Tab>
  )
}

export default TabDeal
