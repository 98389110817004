import { css } from '@emotion/css'

export default css(`
  grid-area: buttons;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: flex-end;
  
  .cancel-button { margin-right: 16px; }

  .MuiButton-root {
    border: 1px solid #C99B86;

    :hover {
      background-color: #FFF;
      border: 1px solid #DDB3A0;
      opacity: 1;
    }
  }
`)
