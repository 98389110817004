import { type FC } from 'react'
import { cnx, Loader } from '@carfluent/common'

import Tabs, { type TabHeaderItem } from 'components/common/Tabs/components/Tabs'
import { DealDetailsTabs } from 'constants/names'

import DealCard from './components/DealCard'
import BannersPanelActionsAndModals from './components/BannersPanelActionsAndModals'
import TabDealForms from './components/TabDealForms'
import TabVehicles from './components/TabVehicles'
import TabBuyerCobuyer from './components/TabBuyerCobuyer'
import TabDeal from './components/TabDeal'
import TabDealDocument from './components/TabDealDocuments'
import TabDealFinances from './components/TabDealFinances'

import { TabsId } from './constants'
import useDealDetails from './hook'
import PAGE_CLASSES from './styles'

const DealDetails: FC = () => {
  const {
    coApplicantTypes,
    deal,
    dealId,
    dealerId,
    depositedCarId,
    feesAndCoveragesSettings,
    isDealApproved,
    isDealCompleted,
    isLoading,
    linkToWebsite,
    loadDeal,
    loadSalesCommission,
    nextTab,
    onApproveDeal,
    onResetTabNavigation,
    onSaveChanges,
    onSelectTab,
    onSelectDealFormsTab,
    onTrySwitchTab,
    updateDeal,
    updateDealStatus,
    salesCommission,
    selectedTabId,
    vehicleInfo
  } = useDealDetails()

  const tabHeaders = getDealDetailsTabNames({
    documentAmount: deal?.documents.length ?? 0,
    isLoading
  })

  return (
    <div className={cnx('card-content', 'g-deal-view', PAGE_CLASSES)}>
      <BannersPanelActionsAndModals
        dealId={dealId}
        deal={deal}
        isLoading={isLoading}
        vehicleInfo={vehicleInfo}
        depositedCarId={depositedCarId}
        onLoadDeal={loadDeal}
        onApproveDeal={onApproveDeal}
        onSelectDealFormsTab={onSelectDealFormsTab}
        onUpdateDeal={updateDeal}
        onUpdateDealStatus={updateDealStatus}
        linkToWebsite={linkToWebsite}
        isShowCarDelivery={feesAndCoveragesSettings?.fees.carDeliverySetting.isEnabled ?? false}
      />

      <section className='cf-deal-view-top-section-wrapper'>
        <DealCard deal={deal} dealId={dealId} isLoading={isLoading} />
      </section>

      <Tabs
        ariaLabel='deal view'
        selected={selectedTabId}
        onSelect={onSelectTab}
        tabHeaders={tabHeaders}
      >
        <TabDeal
          deal={deal}
          dealId={dealId}
          dealerId={dealerId}
          isLoading={isLoading}
          isSelected={selectedTabId === TabsId.Deal}
          feesAndCoveragesSettings={feesAndCoveragesSettings}
          nextTab={nextTab}
          onLoadDeal={loadDeal}
          onLoadSalesCommission={loadSalesCommission}
          onTrySwitchTab={onTrySwitchTab}
          onResetTabNavigation={onResetTabNavigation}
          onUpdateDeal={updateDeal}
          salesCommission={salesCommission}
          tabIdx={TabsId.Deal}
        />

        <TabBuyerCobuyer
          isSelected={selectedTabId === TabsId.BuyerCobuyer}
          deal={deal}
          dealId={dealId}
          coApplicantTypes={coApplicantTypes}
          tabIdx={TabsId.BuyerCobuyer}
          nextTab={nextTab}
          onSaveChanges={onSaveChanges}
          onTrySwitchTab={onTrySwitchTab}
          onResetTabNavigation={onResetTabNavigation}
        />

        <TabVehicles
          isSelected={selectedTabId === TabsId.Vehicles}
          dealId={dealId}
          dealRowVersion={deal?.rowVersion}
          tabIdx={TabsId.Vehicles}
          nextTab={nextTab}
          vehicle={deal?.vehicle ?? null}
          tradeInDetails={deal?.tradeInDetails ?? null}
          onLoadSalesCommission={loadSalesCommission}
          onSaveChanges={onSaveChanges}
          onTrySwitchTab={onTrySwitchTab}
          onResetTabNavigation={onResetTabNavigation}
          dealState={deal?.dealStateId}
          isDeposited={deal?.isDeposited}
        />

        <TabDealForms
          isSelected={selectedTabId === TabsId.DealForms}
          isDealApproved={isDealApproved}
          deal={deal}
          dealId={dealId}
        />

        <TabDealDocument
          dealId={dealId}
          dealRowVersion={deal?.rowVersion}
          documents={deal?.documents}
          isSelected={selectedTabId === TabsId.DealDocument}
          onSaveChanges={onSaveChanges}
          personalDetails={deal?.personalDetails}
        />

        <TabDealFinances
          deal={deal}
          dealId={dealId}
          nextTab={nextTab}
          isDealCompleted={isDealCompleted}
          isSelected={selectedTabId === TabsId.DealFinances}
          isShowCarDelivery={feesAndCoveragesSettings?.fees.carDeliverySetting.isEnabled ?? false}
          onLoadSalesCommission={loadSalesCommission}
          onResetTabNavigation={onResetTabNavigation}
          onSaveChanges={onSaveChanges}
          onTrySwitchTab={onTrySwitchTab}
          paymentDetails={deal?.paymentDetails}
          tabIdx={TabsId.DealFinances}
        />
      </Tabs>
    </div>
  )
}

export default DealDetails

// ========================================== //

interface GetDealDetailsTabNamesProps {
  documentAmount: number
  isLoading: boolean
}

const getDealDetailsTabNames = ({
  documentAmount, isLoading
}: GetDealDetailsTabNamesProps): TabHeaderItem[] => [
  DealDetailsTabs.Deal,
  DealDetailsTabs.Buyer,
  DealDetailsTabs.Vehicles,
  DealDetailsTabs.Forms,
  {
    name: DealDetailsTabs.Documents,
    endAdornment: isLoading
      ? <Loader className='counter-loader' size='small' />
      : <span className='counter'> {documentAmount}</span>
  },
  DealDetailsTabs.Finances
]
