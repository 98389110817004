import { ColumnDef } from '@carfluent/common/dist/UI'

import {
  type DictionaryItem,
  type WorkPlanEventDto,
  WorkPlanEventTypeId,
  TaskAssigneeType,
  TaskTypesNames
} from 'api/types'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import NameCell from 'components/common/Table/cells/NameCell'
import AddRuleHeaderCell from 'components/crm/cells/AddRuleHeaderCell'
import InfoCell from 'components/crm/cells/InfoCell'
import RuleCell from 'components/crm/cells/RuleCell'
import TooltipCell from 'components/common/Table/cells/TooltipCell'

import { WORK_PLAN_EVENT_TYPES } from 'constants/workPlan'
import IconSVG from 'components/inlineImages'

interface GetColumnDefsProps {
  name: string
  id: number
  onOpenModal: (leadStatus: DictionaryItem) => void
  toggleActivate: (leadStatus: DictionaryItem, event: WorkPlanEventDto) => Promise<void>
  onOpenDeleteModal: (event: WorkPlanEventDto) => void
}

interface GetColumnsDefsProps extends Omit<GetColumnDefsProps, 'id' | 'name'> {
  tableItem: Map<DictionaryItem, WorkPlanEventDto[]>
}

const getTaskAssignee = (data: WorkPlanEventDto): string => {
  if (data.workplanEventTypeId !== WorkPlanEventTypeId.Task && data.taskAssigneeTypeId !== TaskAssigneeType.User) {
    return TaskTypesNames.System
  } else if (data.taskAssigneeTypeId === TaskAssigneeType.RoundRobin) {
    return TaskTypesNames.RoundRobin
  } else if (data.taskAssigneeTypeId === TaskAssigneeType.Owner) {
    return TaskTypesNames.Owner
  } else if (data.taskAssigneeTypeId === TaskAssigneeType.RoundRobinBDC) {
    return TaskTypesNames.RoundRobinBDC
  } else if (data.taskAssigneeTypeId === TaskAssigneeType.RoundRobinSales) {
    return TaskTypesNames.RoundRobinSales
  } else {
    return data.taskAssigneeTypeId === TaskAssigneeType.RoundRobin
      ? TaskTypesNames.RoundRobin
      : `${data.assignedUser?.firstName ?? ''} ${data.assignedUser?.lastName ?? ''}`
  }
}

const getTaskTemplateName = (data: WorkPlanEventDto): string => {
  return `${WORK_PLAN_EVENT_TYPES[data.workplanEventTypeId]} ${`- ${data.templateName ?? ''}`}`
}

export const getColumnDefs = ({
  onOpenModal,
  toggleActivate,
  onOpenDeleteModal,
  ...leadStatus
}: GetColumnDefsProps): Array<ColumnDef<WorkPlanEventDto>> => {
  return ([
    {
      accessorKey: 'title',
      cell: (props) => (
        <TooltipCell
          {...props}
          className={(props.row.original.isActive ?? false) ? '' : 'isDisabled'}
        />
      ),
      header: TextCellHOC({ text: leadStatus.name.toUpperCase(), className: 'header' }),
      sortable: false,
      size: 375
    },
    {
      accessorFn: (data) => getTaskTemplateName(data),
      id: 'workplanEventTypeId',
      cell: (props) => (
        <TooltipCell
          {...props}
          className={(props.row.original.isActive ?? false) ? '' : 'isDisabled'}
        />
      ),
      header: undefined,
      sortable: false,
      className: 'workplan-event-column',
      size: 260
    },
    {
      accessorFn: (data) => data,
      id: 'executionDay',
      cell: (props) => (
        <InfoCell
          {...props}
          className={(props.row.original.isActive ?? false) ? '' : 'isDisabled'}
        />
      ),
      header: undefined,
      sortable: false,
      size: 100
    },
    {
      accessorFn: (data) => ({
        name: getTaskAssignee(data),
        showAvatar: true,
        className: (data.isActive ?? false) ? '' : 'isDisabled',
        avatarIcon: data.assignedUser == null ? <IconSVG.Bot /> : null
      }),
      id: 'reminderSubject',
      cell: NameCell,
      header: undefined,
      sortable: false,
      size: 240
    },
    {
      id: 'rule',
      accessorFn: (data) => data,
      cell: (props) => (
        <RuleCell
          {...props}
          className={(props.row.original.isActive ?? false) ? '' : ' isDisabled'}
          leadStatus={leadStatus}
          toggleActivate={toggleActivate}
          onOpenDeleteModal={onOpenDeleteModal}
        />
      ),
      header: () => (
        <AddRuleHeaderCell
          onClick={onOpenModal}
          leadStatus={leadStatus}
        />
      ),
      className: 'rule-column',
      sortable: false,
      size: 130
    }
  ])
}

export const getColumnsDef = ({ tableItem, ...props }: GetColumnsDefsProps): Record<number, Array<ColumnDef<WorkPlanEventDto>>> => {
  return Array
    .from(tableItem.keys())
    .reduce((res: Record<number, Array<ColumnDef<WorkPlanEventDto>>>, item) => {
      res[item.id] = getColumnDefs({
        ...item,
        ...props
      })

      return res
    }, {})
}
