import {
  type BuyerModel,
  type CheckoutModel,
  type CobuyerModel,
  type DealFeeFieldSettingsModel,
  type DealFeesAndCoveragesSettingsModel,
  type DealFeesModel
} from 'api/types/responses'

import {
  type BankDetailsDto,
  type BusinessDetailsDto,
  type DealDto,
  type DealFeesDto,
  type ExecutiveDetailsDto,
  PaymentTypeId,
  type TradeInDetails,
  WorkflowStep,
  WorkflowTypeEnum,
  CoverageDetails
} from 'api/types'
import { CoverageDefault } from 'types/coverage'

/**
 * DD-TODO:
 * remove redundant default values and switch app places to use Models instead of DTOs
 */

export const DEFAULT_BUSINESS_DETAILS: BusinessDetailsDto = {
  businessName: '',
  businessTypeId: 0,
  ein: ''
}

export const DEFAULT_BANK_DETAILS: BankDetailsDto = {
  name: '',
  contactName: '',
  contactPhone: '',
  checkingAccountNumber: ''
}

export const DEFAULT_EXECUTIVE_DETAILS: ExecutiveDetailsDto = {
  firstName: '',
  lastName: '',
  title: '',
  ownership: 0,
  birthDate: null,
  socialSecurityNumber: '',
  address: '',
  city: '',
  county: '',
  state: '',
  zipCode: '',
  apt: null
}

export const GET_DEFAULT_TRADE_IN = (): TradeInDetails => ({
  tradeInDetailsId: null,
  tradeInVehicleYear: null,
  tradeInVehicleMake: null,
  tradeInVehicleModel: null,
  tradeInVehicleTrim: null,
  tradeInVehicleVIN: null,
  tradeInVehicleMileage: null,
  tradeInCredit: null,
  tradeInPayoff: null,
  inventoryVehicleId: null,
  acv: null // actual car value
})

export const GET_DEFAULT_BUYER_MODEL = (): BuyerModel => ({
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  birthDate: null,
  title: null,
  addressDetails: {
    address: '',
    secondaryAddress: null,
    city: '',
    state: '',
    apt: null,
    zipCode: '',
    county: null
  },
  socialSecurityNumber: null
})

export const GET_DEFAULT_BUSINESS_DETAILS = (): BusinessDetailsDto => ({
  businessName: '',
  businessTypeId: 0,
  ein: ''
})

export const GET_DEFAULT_COBUYER_MODEL = (): CobuyerModel => ({
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  birthDate: null,
  coApplicantType: 0,
  currentAddressDetails: {
    id: 0,
    address: '',
    secondaryAddress: null,
    city: '',
    state: '',
    apt: null,
    zipCode: '',
    county: null
  },
  socialSecurityNumber: null,
  sameAddressAsMainApplicant: false
})

export const GET_DEFAULT_DEAL = (): DealDto => ({
  dealState: 'Pending',
  dealFees: GET_DEFAULT_DEAL_FEES(),
  nextAction: null,
  currentWorkflowStepName: WorkflowStep.PersonalDetails,
  currentWorkflowStepStartedAt: '',
  accountingStateChangeDate: '',
  createdDate: null,
  saleDate: null,
  firstPaymentDate: null,
  rowVersion: '',
  creditScore: null,
  creditScoreLink: null,
  isPaperworkPendingSigning: false,
  customerId: 0,
  totalPrice: 0,
  registrationTax: 0,
  recapTransactionId: null,
  documentFee: 0,
  transportationCost: 0,
  other1: null,
  other2: null,
  other3: null,
  totalDown: null,
  other1Description: null,
  other2Description: null,
  other3Description: null,
  isCreditApplicationSubmitted: false,
  creditApplicationSubmittedDate: null,
  areTemplateDocumentsAvailable: false,
  notes: null,
  isVehicleDeposited: false,
  workflowAccountingStateId: 0,
  workflowTypeId: WorkflowTypeEnum.Business,
  salesperson: null,
  receipts: [],
  soldDate: null,
  resultSalesTax: null,
  leadId: null,
  vehicleDetails: {
    inventoryId: '',
    vehicleDetailsId: 0,
    vehicleMake: '',
    vehicleModel: '',
    vehicleModelYear: 0,
    vehicleTrim: '',
    vehicleImageUrl: '',
    vehicleMileage: 0,
    vehiclePrice: 0,
    vehicleVIN: '',
    vehicleStockNumber: '',
    vehicleState: null
  },
  customerDetails: {
    customerDetailsId: null,
    customerFirstName: null,
    customerLastName: null,
    customerEmail: null,
    customerBirthDate: null,
    customerPhoneNumber: null,
    customerAddress: null,
    customerSecondaryAddress: null,
    customerCity: null,
    customerCounty: null,
    customerState: null,
    customerZipCode: null,
    customerApt: null,
    socialSecurityNumber: null,
    title: null
  },
  salesTaxAmount: null,
  salesTax: null,
  overridenSalesTaxAmount: null,
  overridenSalesTaxPercent: null,
  salesCommission: null,
  tradeInDetails: GET_DEFAULT_TRADE_IN(),
  financingDetails: {
    financingDetailsId: 0,
    paymentType: null,
    buyRate: null,
    interestRate: null,
    downPayment: null,
    financingTerm: null,
    amountFinanced: null,
    monthlyPayment: null,
    fundedAmount: null,
    approvalStatus: null,
    comments: null,
    stipulations: [],
    additionalInformation: [],
    reserve: null,
    lienholderId: null,
    lienholder: null
  },
  lenderDecisions: [],
  applicantFinancialDetails: {
    id: 0,
    hasCoApplicant: false,
    creditApplicationConsentAccepted: false,
    applicationAgreementAccepted: false,
    apartmentMoveInDate: null,
    apartmentPayment: 0,
    previousAddressDetails: {
      id: 0,
      apartmentMoveInDate: null,
      apartmentMoveOutDate: null,
      address: null,
      secondaryAddress: null,
      city: null,
      state: null,
      apt: null,
      zipCode: null,
      county: null
    },
    stateOfIssue: null,
    housingStatus: 0,
    driverLicenseNumber: null,
    socialSecurityNumber: null,
    hasOtherIncomeSources: false,
    otherIncome: null,
    incomeOption: null,
    wholesaleValue: null,
    wholesaleSourceType: 0,
    currentEmploymentDetails: {
      id: 0,
      employmentStatus: 0,
      employerName: null,
      employerPhoneNumber: null,
      jobTitle: null,
      workingStartDate: null,
      income: null
    },
    previousEmploymentDetails: {
      id: 0,
      employmentStatus: 0,
      employerName: null,
      employerPhoneNumber: null,
      jobTitle: null,
      workingStartDate: null,
      workingEndDate: null,
      income: null
    }
  },
  coApplicantFinancialDetails: {
    id: 0,
    coApplicantType: 0,
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    birthDate: null,
    creditApplicationConsentAccepted: false,
    applicationAgreementAccepted: false,
    primaryApplicantState: null,
    socialSecurityNumber: null,
    driverLicenseNumber: null,
    stateOfIssue: null,
    housingStatus: 0,
    hasOtherIncomeSources: false,
    otherIncome: null,
    incomeOption: null,
    currentAddressDetails: {
      id: 0,
      apartmentMoveInDate: null,
      apartmentPayment: 0,
      address: null,
      secondaryAddress: null,
      city: null,
      state: null,
      apt: null,
      zipCode: null,
      county: null
    },
    previousAddressDetails: {
      id: 0,
      apartmentMoveInDate: null,
      apartmentMoveOutDate: null,
      address: null,
      secondaryAddress: null,
      city: null,
      state: null,
      apt: null,
      zipCode: null,
      county: null
    },
    currentEmploymentDetails: {
      id: 0,
      employmentStatus: 0,
      employerName: null,
      employerPhoneNumber: null,
      jobTitle: null,
      workingStartDate: null,
      income: null
    },
    previousEmploymentDetails: {
      id: 0,
      employmentStatus: 0,
      employerName: null,
      employerPhoneNumber: null,
      jobTitle: null,
      workingStartDate: null,
      workingEndDate: null,
      income: null
    }
  },
  financingCalculatorDetails: {
    downPayment: 0,
    creditScore: 0,
    term: 0,
    amountFinanced: null,
    monthlyPayment: null
  },
  checkoutDetails: {
    checkoutDetailsId: null,
    hasDelivery: null,
    pickupDeliveryDate: null,
    deliveryAddress: null,
    deliveryState: null,
    deliveryCity: null,
    deliveryZipCode: null
  },
  coverageDetails: [],
  documentDetails: [],
  paymentDetails: [],
  businessDetails: DEFAULT_BUSINESS_DETAILS,
  executiveDetails: DEFAULT_EXECUTIVE_DETAILS,
  bankDetails: DEFAULT_BANK_DETAILS,
  name: null,
  dealPaidStatusId: 0
})

export const GET_DEFAULT_COVERAGE_DETAILS = (): CoverageDefault => ({
  coverageName: '',
  cost: null,
  deductibleAmount: null,
  deductibleId: null,
  totalPrice: null,
  id: 0,
  providerId: null,
  termMonths: null,
  termMiles: null,
  productTypeId: 0,
  isFormDisabled: false,
  productCoverageId: null
})

export const GET_DEFAULT_COVERAGE_DETAILS_OLD = (): Omit<CoverageDetails, 'productTypeId' | 'dealCoverageDefaultId'> => ({
  coverageName: '',
  dealerCost: null,
  deductibleAmount: null,
  deductibleDescription: null,
  deductibleId: null,
  dealerRetailPrice: null,
  fullProductName: '',
  id: 0,
  productName: null,
  productType: null,
  providerName: null,
  termMonths: null,
  termMiles: null,
  workflowStepId: 0
})

export const GET_CHECKOUT_DETAILS = (): CheckoutModel => ({
  id: null,
  dealId: 0,
  deliveryAddress: null,
  deliveryCity: null,
  deliveryState: null,
  deliveryZipCode: null,
  deliveryType: null,
  pickupDeliveryDate: null,
  pickupDeliveryTime: null
})

export const GET_DEFAULT_DEAL_FEES = (data?: DealFeesDto): DealFeesDto => ({
  buyerTag: 0,
  carDelivery: 0,
  dealerHandlingFee: 0,
  dealerInventoryTax: 0,
  emissions: 0,
  inspectionFee: 0,
  plateTransferFee: 0,
  registrationFee: 0,
  titleFee: 0,
  overridenSalesCommission: null,
  overridenSalesTaxAmount: 0,
  overridenSalesTaxPercent: 0,
  stateSalesTaxPercent: 0,
  other1: null,
  other1Description: null,
  other2: null,
  other2Description: null,
  other3: null,
  other3Description: null
})

export const GET_DEFAULT_DEAL_FIELD_FEE_BY_CONFIG = (paymentType: PaymentTypeId) =>
  (config: DealFeeFieldSettingsModel): number => {
    return paymentType === PaymentTypeId.Cash
      ? config.defaultCash ?? config.defaultValue ?? 0
      : config.defaultFinancing ?? config.defaultValue ?? 0
  }

export const GET_DEFAULT_DEAL_FEES_BY_CONFIG = (
  paymentType: PaymentTypeId,
  config: DealFeesAndCoveragesSettingsModel
): Partial<DealFeesModel> => {
  const getFeeValue = GET_DEFAULT_DEAL_FIELD_FEE_BY_CONFIG(paymentType)

  return {
    buyerTag: getFeeValue(config.fees.buyerTagSetting),
    carDelivery: getFeeValue(config.fees.carDeliverySetting),
    dealerHandlingFee: getFeeValue(config.fees.dealerHandlingFeeSetting),
    dealerInventoryTax: getFeeValue(config.fees.dealerInventoryTaxSetting),
    emissions: getFeeValue(config.fees.emissionsSetting),
    inspectionFee: getFeeValue(config.fees.inspectionFeeSetting),
    plateTransferFee: getFeeValue(config.fees.plateTransferFeeSetting),
    registrationFee: getFeeValue(config.fees.registrationFeeSetting),
    titleFee: getFeeValue(config.fees.titleFeeSetting)
  }
}
