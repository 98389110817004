import { css } from '@emotion/css'

export default css(`
  display: flex;
  
  .btn-add-cost {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    max-width: fit-content;
    
    h3 {
      color: #101010;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
    }
    
    &.disabled {
      opacity: 0.4;
    }
  }
  
  &.fields-row {
    .fields {
      flex-wrap: wrap;
      row-gap: 24px !important;
      margin-bottom: 8px;
    }
  }
  
  .field-container {
    overflow-x: clip;
  }
  
  .additional-cost-line {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
    width: 100%;
    
    .field-container {
      width: 20%;
      min-height: 56px;
      display: flex;
      align-items: center;
    }
    
    &:first-of-type {
      margin-top: 8px;
    }
    
    .cf-button-root.cf-button-variant-text.additional-costs-remove-btn {
      height: 16px;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-underline-position: auto;
    }
  }
`)
