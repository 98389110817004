import { css } from '@emotion/css'

export const DRAWER_TRANSITION_DURATION = 550

export default css(`
  &.cf-collapse {
    .cf-collapse-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .cf-collapse-section {
      // height: 0px;
      // min-height: 0px;
      // overflow: hidden;
      // transition: height ${DRAWER_TRANSITION_DURATION}ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
`)
