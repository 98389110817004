import { useCallback, useEffect, useMemo, useState } from 'react'

import type { DocumentFormDto } from 'api/types'
import useTableApi from 'hooks/useTableApi'
import { calcTableHeight } from 'utils/math'
import DocumentsApiProvider from 'api/documents.api'
import { TABLE_MIN_HEIGHT } from 'constants/constants'

import type {
  UseAddFormModalProps,
  UseAddFormModalReturn
} from './types'
import {
  API_CALL_DELAY_SEARCH,
  DEFAULT_SORTING,
  Messages
} from './constants'
import getColumnDefinitions from './columns'

const useAddFormModal = ({ onAddForms, isOpen }: UseAddFormModalProps): UseAddFormModalReturn => {
  const [selectedForms, setSelectedForms] = useState<DocumentFormDto[]>([])

  const {
    rows,
    search,
    getRows,
    loadRows,
    onSearch,
    isLoading,
    setSearch,
    emptyTableMessage
  } = useTableApi({
    defaultFilters: {},
    shouldRunOnCall: true,
    defaultSorting: DEFAULT_SORTING,
    apiCallDelay: API_CALL_DELAY_SEARCH,
    getList: DocumentsApiProvider.getForms,
    emptyTableMessage: Messages.EmptyTableState,
    nothingFoundMessage: Messages.NothingFoundState
  })

  const data = useMemo(() => {
    return rows.map(el => ({
      ...el,
      checked: selectedForms.find(form => form.id === el.id) !== undefined
    }))
  }, [rows, selectedForms])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onSubmit = (): void => {
    onAddForms(selectedForms)
  }

  const onToggleRowCheckbox = useCallback((rowIdx: number, _: string, nextValue: unknown) => {
    const item = getRows()[rowIdx]

    if (nextValue === true) {
      setSelectedForms([...selectedForms, item])
    } else {
      setSelectedForms([...selectedForms].filter(el => el.id !== item.id))
    }
  }, [selectedForms])

  const columns = useMemo(() => {
    return getColumnDefinitions({ onToggleRowCheckbox })
  }, [onToggleRowCheckbox])

  const onBottomReached = useCallback(async () => {
    if (!isLoading && TABLE_MIN_HEIGHT <= calcTableHeight(rows.length)) {
      await loadRows({ forceRefresh: false })
    }
  }, [isLoading, loadRows, rows.length])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (!isOpen) {
      setSelectedForms([])
      if (search.length > 0) {
        setSearch('', true)
        void loadRows()
      }
    }
  }, [isOpen])

  // ========================================== //

  return {
    data,
    search,
    columns,
    onSearch,
    onSubmit,
    isLoading,
    onBottomReached,
    emptyTableMessage,
    onSearchChange: setSearch,
    amountOfForms: selectedForms.length
  }
}

export default useAddFormModal
