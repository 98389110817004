import type { FC } from 'react'
import { Droppable } from 'react-beautiful-dnd'

import type { DocumentFormDto } from 'api/types'

import DraggableRow from '../DraggableRow'

import { CLASS_NAME } from './styles'

interface DroppableTableProps {
  data: DocumentFormDto[] | null
  onDeleteForm: (idx: number) => void
}

const DroppableTable: FC<DroppableTableProps> = ({ data, onDeleteForm }) => {
  return (
    <Droppable droppableId='exactPage'>
      {(provided) => {
        return (
          <div {...provided.droppableProps} ref={provided.innerRef} className={CLASS_NAME}>
            {data?.map((item, index) => (
              <DraggableRow
                key={index}
                item={item}
                index={index}
                onDeleteForm={onDeleteForm}
              />
            ))}
            {provided.placeholder}
          </div>
        )
      }}
    </Droppable>
  )
}

export default DroppableTable
