import type { DictionaryItem } from 'api/types'
import { getParsedToken } from 'services/storage.service'

const getCurrentUser = (): DictionaryItem => {
  const token = getParsedToken()

  return {
    id: Number(token?.sub),
    name: token?.name
  }
}

export default getCurrentUser
