import { type FC } from 'react'
import { Button, cnx, formatCurrencyDecimal, formatInteger, formatPercentages, noop, useModal } from '@carfluent/common'

import { isTruthy } from 'utils/general'
import getDealTotalPriceRounded from 'utils/deals/priceHelpers/getDealTotalPriceRounded'
import getMonthlyPayment from 'utils/deals/priceHelpers/getMonthlyPayment'
import { getFeesAndTaxesSumRounded } from 'utils/deals/priceHelpers/getFeesAndTaxesSum'
import { getNetTradInValueRounded } from 'utils/deals/priceHelpers/getNetTradInValue'
import Divider from 'components/common/Divider'
import IconSVG from 'components/inlineImages'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'
import { Notes } from 'components/common/Notes'
import ReceiptsInfo from 'components/deals/ReceiptsInfo'

import { type SummarySectionProps } from './types'
import CLASS_NAME from './styles'

const EMPTY_FORMAT = { emptyValues: '-' }
const NEGATIVE_FORMAT = { emptyValues: '-', allowNegative: true }
const MONTHS_FORMAT = { emptyValues: '-', postfix: ' months' }
const MONTHLY_PAYMENT_FORMAT = { emptyValues: '-', postfix: '/mo' }
const INTEREST_RATE_FORMAT = { emptyValues: '-', mantissa: 2 }

const SummarySection: FC<SummarySectionProps> = ({
  className,
  deal,
  dealerId,
  dealId,
  feesAndCoveragesSettings,
  isFinancing,
  isLoading = false,
  onRefreshDeal,
  onUpdateNotes,
  onViewBillOfSale,
  values
}) => {
  const modalReceiptProps = useModal()

  const hasReceipts = isTruthy(deal?.receipts.length)
  const hasTradeIn = isTruthy(deal?.tradeInDetails?.id)
  const hasCoverages = isTruthy(deal?.coverageDetails.length)
  const financeInfo = deal?.financeInfo ?? null
  const { tradeInDetails } = values
  const activeLenderDecision = values?.activeLenderDecision
  const dealTotalPrice = getDealTotalPriceRounded(values, deal?.coverageDetails, feesAndCoveragesSettings)
  const netTradeInValue = getNetTradInValueRounded(tradeInDetails.credit, tradeInDetails.paymentAmount)

  const feesAndTaxes = getFeesAndTaxesSumRounded(
    values?.vehicle.listPrice ?? 0,
    values.dealFees,
    feesAndCoveragesSettings
  )

  const amountFinanced = activeLenderDecision?.lienholder != null
    ? dealTotalPrice - (activeLenderDecision?.downPayment ?? 0)
    : null

  const monthlyPayment = getMonthlyPayment(
    amountFinanced ?? 0,
    values.activeLenderDecision?.approvedRate ?? 0,
    values.activeLenderDecision?.approvedTerm ?? 0
  )

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <BlockSkeleton className='summary-block bill-of-sale' isLoading={isLoading}>
        <div className='section-title'>
          <h6>Bill of sale</h6>

          <div
            className='download-action'
            onClick={onViewBillOfSale}
          >
            <IconSVG.Print color='#458FFF' />
          </div>
        </div>

        <div className={cnx('fields', !isFinancing && 'with-padding-bottom')}>
          <div className='labeled-field'>
            <label>Sale price</label>
            <div>{formatCurrencyDecimal(values.vehicle.listPrice, EMPTY_FORMAT)}</div>
          </div>

          <div className='labeled-field'>
            <label>Fees and taxes</label>
            <div>{formatCurrencyDecimal(feesAndTaxes, EMPTY_FORMAT)}</div>
          </div>

          {hasTradeIn && (
            <>
              <Divider/>

              <div className='labeled-field'>
                <label>Net Trade In value</label>
                <div>{formatCurrencyDecimal(netTradeInValue, NEGATIVE_FORMAT)}</div>
              </div>
            </>
          )}

          {hasCoverages && (
            <>
              <Divider/>

              <div className='labeled-field'>
                <label>Coverages</label>
                <div>{formatCurrencyDecimal(financeInfo?.coverages, EMPTY_FORMAT)}</div>
              </div>
            </>
          )}

          <Divider/>

          <div className='labeled-field bold'>
            <label>Total price</label>
            <div className='big-number'>{formatCurrencyDecimal(dealTotalPrice, EMPTY_FORMAT)}</div>
          </div>
        </div>

        {isFinancing && (
          <div className='fields financing'>
            <div className='labeled-field'>
              <label>Term</label>
              <div>{formatInteger(activeLenderDecision?.approvedTerm, MONTHS_FORMAT)}</div>
            </div>

            <div className='labeled-field'>
              <label>Lender</label>
              <div>{activeLenderDecision?.lienholder?.name ?? '-'}</div>
            </div>

            <div className='labeled-field'>
              <label>Interest rate</label>
              <div>{formatPercentages(activeLenderDecision?.approvedRate, INTEREST_RATE_FORMAT)}</div>
            </div>

            <div className='labeled-field'>
              <label>Down payment</label>
              <div>{formatCurrencyDecimal(activeLenderDecision?.downPayment, EMPTY_FORMAT)}</div>
            </div>

            <div className='labeled-field'>
              <label>Total financed</label>
              <div>{formatCurrencyDecimal(amountFinanced, EMPTY_FORMAT)}</div>
            </div>

            <Divider />

            <div className='labeled-field bold monthly-payment'>
              <label>Monthly payment</label>
              <div className='big-number'>{formatCurrencyDecimal(monthlyPayment, MONTHLY_PAYMENT_FORMAT)}</div>
            </div>
          </div>
        )}
      </BlockSkeleton>

      <BlockSkeleton className='summary-block payments' isLoading={isLoading}>
        <div className='section-title'>
          <h6>Payments</h6>
        </div>

        <div className='fields with-padding-bottom'>
          <div className='labeled-field'>
            <label>Deposit</label>
            <div>{formatCurrencyDecimal(financeInfo?.payments?.deposit, EMPTY_FORMAT)}</div>
          </div>

          {isFinancing && (
            <div className='labeled-field'>
              <label>Down payment</label>
              <div>{formatCurrencyDecimal(financeInfo?.payments?.downPayment, EMPTY_FORMAT)}</div>
            </div>
          )}

          <Divider />

          <div className='labeled-field bold total-downpayment'>
            <label>Total down payment</label>
            <div className='big-number'>{formatCurrencyDecimal(financeInfo?.payments?.totalDownPayment, EMPTY_FORMAT)}</div>
          </div>

          <Button
            className={cnx('receipt-button', !hasReceipts && 'with-tooltip', 'bold')}
            variant='text'
            isDisabled={!hasReceipts}
            onClick={modalReceiptProps.onOpenModal}
          >
            Show receipts
          </Button>
        </div>
      </BlockSkeleton>

      {!isLoading && (
        <Notes
          handleUpdateNotes={onUpdateNotes}
          notes={deal?.notes ?? ''}
          placeholderName='deal'
        />
      )}

      <ReceiptsInfo
        dealerId={dealerId ?? -1}
        dealId={dealId}
        isOpen={modalReceiptProps.isModalOpen}
        onClose={modalReceiptProps.onCloseModal}
        onRefreshDeal={onRefreshDeal}
        receipts={deal?.receipts ?? []}
        setIsFormSubmitted={noop} // AZ-TODO: check if needed in new Deal
        vin={deal?.vehicle?.vin ?? ''}
      />
    </div>
  )
}

export default SummarySection
