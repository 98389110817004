import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'

class AlertStore {
  alerts: Map<number, Alert.Item> = new Map()

  addAlert = (alert: Alert.Item): void => {
    if (alert.key != null && !this.alerts.has(alert.key)) {
      this.alerts.set(alert.key, alert)
    }
  }

  removeAlert = (key?: number): void => {
    if (key != null && this.alerts.has(key)) {
      this.alerts.delete(key)
    }
  }

  constructor () {
    makeAutoObservable(this)
  }
}

export const AlertInstance = new AlertStore()
export const AlertCTX = createContext(AlertInstance)

export default AlertCTX
