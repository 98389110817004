import { type GetCampaignRecipientsCountRequestDto } from 'api/types'
import { type RecipientsFormData } from './types'
import { LOST_STATUS_ITEM } from './constants'

const serializeFormData = (data: RecipientsFormData): GetCampaignRecipientsCountRequestDto => {
  const isLostStatus = !((data?.filterLeadStatus?.options
    ?.find((el) => el?.id === LOST_STATUS_ITEM.id)) == null)

  const leadStatuses = data?.filterLeadStatus?.options
    ?.filter((el) => el?.id !== LOST_STATUS_ITEM.id)
    .map((el) => el?.id) ?? null

  return {
    leadStatusFilterId: data?.filterLeadStatus?.filter?.id ?? 0,
    leadSourceFilterId: data?.filterLeadSource?.filter?.id ?? 0,
    leadTemperatureFilterId: data?.filterLeadTemperature?.filter?.id ?? 0,
    leadTemperatures: data?.filterLeadTemperature?.options?.map((el) => el?.id) ?? null,
    leadSources: data?.filterLeadSource?.options?.map((el) => el?.id) ?? null,
    leadStatuses,
    isLostStatusSelected: isLostStatus ?? false,
    leadAppointmentsFilterId: data?.filterLeadWithScheduledAppointments?.filter?.id ?? 0
  }
}

export default serializeFormData
