import { css } from '@emotion/css'

export default css(`
    width: 500px;
    padding: 16px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;

    .history-table {
        &-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 8px;
          margin-bottom: 8px;

          &-item {
            max-width: 146px;
            text-align: left;
            color: rgba(33, 33, 33, 0.5);

            &:first-child {
              width: 120px;
            }

            &:nth-child(2) {
              width: 146px;
            }

            &:last-child {
              width: 130px;
            }
          }

        }
    
        &-body {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-height: 232px;
          overflow-y: auto;
          margin-bottom: 8px;
        }

        &-row {
          display: flex;
          gap: 8px;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 8px;

          &-item {
            text-align: left;
            text-overflow: ellipsis;
            overflow : hidden;
            white-space: nowrap;
            font-size: 14px;
            
            .not-changed-mark {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: 16px;
              height: 16px;
              margin-right: 4px;
              
              div {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.12);
              }
            }
            
            &.with-icon {
              svg {
                margin-right: 4px;
              }
            }
            
            &.name-col {
              display: flex;
              align-items: center;
              
              svg {
                margin-right: 4px;
              }
              
              .avatar-wrapper {
                max-width: 100%;

                .cf-tooltip-container {
                  max-width: calc(100% - 24px);

                  .tooltip-content {
                    font-size: 14px;
                  }
                }
              }
            }

            &:first-child {
              width: 120px;
            }

            &:nth-child(2) {
              width: 146px;
            }

            &:last-child {
              width: 130px;
            }

          }

          &.no-price {
            justify-content: center;
          }
          
          .repricing-description {
            font-size: 14px;
            color: rgba(33, 33, 33, 0.40);
          }
        }

        &-button {
          color: #212121;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.15px;
          text-decoration-line: underline;
          text-align: right;

          &:hover {
            cursor: pointer;
          }
        }
    }

  .history-table-row {
    > div {
      display: flex;
      align-items: center;
    }
  }
`)
