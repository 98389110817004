import { css } from '@emotion/css'

import { RowVariants } from 'components/reports/ReportsBSPLTable/components/Row'

export const createStyleClass = (): string => {
  return css(`
    .cf-bs-pl-table {
      .cf-row-${RowVariants.Empty}.cf-total-block-divider td {
        padding: 12px 0;
      }

      .cf-row-${RowVariants.Empty}.cf-title-row-divider td {
        padding: 14px 0;
      }

      .cf-row-${RowVariants.Subtitle}.cf-total-row-item td {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .cf-top-wrapper {
      margin-bottom: 24px;
    }
  `)
}
