import { toJS } from 'mobx'

import type { AddVendorPayload } from 'api/types'
import type { VendorFormData } from './types'

const serializeVendorForm = (formData: VendorFormData): AddVendorPayload => {
  const data = toJS(formData)
  const { address, mailingAddress } = data

  return {
    name: data?.name ?? null,
    phoneNumber: data?.phoneNumber ?? null,
    email: data?.email ?? null,
    ein: data?.ein ?? null,
    ssn: data?.ssn ?? null,
    address: address?.address ?? address?.addressLong ?? null,
    city: address?.city ?? null,
    state: address?.state ?? null,
    zipCode: address?.zipCode ?? null,
    mailingAddress: mailingAddress?.address ?? null,
    mailingCity: mailingAddress?.city ?? null,
    mailingState: mailingAddress?.state ?? null,
    mailingZipCode: mailingAddress?.zipCode ?? null,
    track1099Payments: data?.isTrack1099Payments ?? false,
    accountId: Number(data.payableAccount?.id)
  }
}

export default serializeVendorForm
