import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import PersonaIcon from 'components/deals/PersonaIcon'
import { type PersonaStatusProps } from './types'
import CLASS_NAME from './styles'

const PersonaStatus: FC<PersonaStatusProps> = ({
  className,
  isPassed
}) => {
  return (
    <div className={cnx(CLASS_NAME, className)}>
      {isPassed ? 'Passed' : 'Failed'}
      <PersonaIcon isPassed={isPassed} />
    </div>
  )
}

export default PersonaStatus
