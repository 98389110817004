import type { FC } from 'react'
import { cnx, FormInput } from '@carfluent/common'

import type { FIFormProps } from 'pages/settings/FISettings/components/FIPackageSetup/hook/types'

import CLASS_NAME from './styles'

const getHelperLabel = (value: string): string => {
  return `${(value ?? '').length}/16 symbols`
}

const MAX_PACKAGE_NAME_LENGTH = 16

const FIPackageNames: FC<FIFormProps> = ({
  errors,
  onBlur,
  touched,
  onChange,
  values
}) => {
  return (
    <div className={cnx(CLASS_NAME, 'fi-package-setup-form')}>
      <FormInput
        onBlur={onBlur}
        onChange={onChange}
        id='firstPackageName'
        label='Name of package 1'
        error={errors.firstPackageName}
        value={values.firstPackageName}
        touched={touched.firstPackageName}
        dataTestId='input-name-of-package-1'
        maxLength={MAX_PACKAGE_NAME_LENGTH}
        helper={getHelperLabel(values.firstPackageName)}
      />

      <FormInput
        onBlur={onBlur}
        onChange={onChange}
        id='secondPackageName'
        label='Name of package 2'
        error={errors.secondPackageName}
        value={values.secondPackageName}
        maxLength={MAX_PACKAGE_NAME_LENGTH}
        touched={touched.secondPackageName}
        dataTestId='input-name-of-package-2'
        helper={getHelperLabel(values.secondPackageName)}
      />

      <FormInput
        onBlur={onBlur}
        onChange={onChange}
        id='thirdPackageName'
        label='Name of package 3'
        error={errors.thirdPackageName}
        value={values.thirdPackageName}
        touched={touched.thirdPackageName}
        maxLength={MAX_PACKAGE_NAME_LENGTH}
        dataTestId='input-name-of-package-3'
        helper={getHelperLabel(values.thirdPackageName)}
      />
    </div>
  )
}

export default FIPackageNames
