import { type FC, type MouseEvent, type ReactNode } from 'react'
import { cn } from '@carfluent/common'

import CLASS_NAME from './styles'

export interface ToggleProps {
  activeLabel?: ReactNode
  inactiveLabel?: string
  inversed?: boolean // actually inversed is the correct position. TODO: fix it
  toggled: boolean
  dataTestId?: string
  onClick?: (toggled: boolean) => void
  className?: string
  isDisabled?: boolean
}

const Toggle: FC<ToggleProps> = ({
  className = '',
  activeLabel,
  inactiveLabel,
  inversed = false,
  toggled,
  dataTestId,
  onClick: _onClick,
  isDisabled = false
}) => {
  const onClick = (): void => {
    if (isDisabled) {
      return
    }

    _onClick?.(!toggled)
  }

  const onLabelClick = (e: MouseEvent<HTMLLabelElement>): void => e.stopPropagation()

  return (
    <div
      className={cn(CLASS_NAME, className, inversed && 'toggle-inversed', isDisabled && 'disabled')}
      data-test-id={dataTestId}
    >
      <p
        className={toggled ? 'active' : 'inactive'}
        onClick={onClick}
      >
        {activeLabel}
      </p>

      <label onClick={onLabelClick}>
        <input
          type='checkbox'
          checked={toggled}
          onChange={onClick}
        />

        <span className={cn('cf-main-block', toggled ? 'active' : '')} />
      </label>

      <p
        className={!toggled ? 'active' : 'inactive'}
        onClick={onClick}
      >
        {inactiveLabel}
      </p>
    </div>
  )
}

export default Toggle
