import { type FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Banner } from '@carfluent/common'

import { type EntityId } from 'types'
import CalcRoutes from 'constants/route_helper'
import Button from 'components/common/Button' // AZ-TODO: refactor

import type { BannersListProps } from './types'

const CREDIT_APPLICATION_WAITING_MESSAGE = 'Submit the Credit application.'
const CREDIT_APPLICATION_PENDING_MESSAGE = 'Credit application waiting for review and to be sent to lender.'
const DOCS_SELECTION_PENDING_MESSAGE = 'Review deal and select forms (contracts) that should be signed by Customer.'
const DEALER_SIGNATURE_PENDING_MESSAGE = 'Dealer signature is pending.'
const DEAL_IS_RECORDED_MESSAGE = 'Deal recorded to accounting. To update deal delete related journal entry.'
const CAR_ALREADY_SOLD_MESSAGE = 'This car is already sold.'
const CAR_WAS_DELETED_MESSAGE = 'This car was deleted'
const CAR_DEPOSITED_MESSAGE = 'This car was deposited by another customer.'

const BannersList: FC<BannersListProps> = ({
  dealId,
  isDealWaitingForCreditAppSubmit = false,
  isDealWaitingForCreditAppApprove = false,
  isDealWaitingForDocsSelection = false,
  isDealIsRecordedVisible = false,
  isDealerSignaturePendingVisible = false,
  isCarDepositedVisible = false,
  isCarAlreadySoldVisible = false,
  isCarDeletedVisible = false,
  onOpenSelectTemplateModal,
  onSignPaperwork,
  onViewTransaction,
  onViewDealOnDeposit,
  deletedCarMassageInfo
}) => {
  const creditAppEndAdornment = getCreditAppLink(dealId, 'Submit')

  const signPaperworkEndAdornment = (
    <Button onClick={onSignPaperwork}>
      Sign
    </Button>
  )

  const selectFormEndAdornment = (
    <Button onClick={onOpenSelectTemplateModal}>
      Select forms
    </Button>
  )

  const viewTransactionEndAdornment = (
    <Button onClick={onViewTransaction}>
      View transaction
    </Button>
  )

  const viewDealEndAdornment = (
    <Button onClick={onViewDealOnDeposit}>
      View
    </Button>
  )

  return (
    <>
      <Banner
        endAdornment={viewDealEndAdornment}
        isOpen={isCarDepositedVisible && !isCarAlreadySoldVisible}
        message={CAR_DEPOSITED_MESSAGE}
        type='error'
      />

      <Banner
        isOpen={isCarDeletedVisible && (!isCarDepositedVisible || !isCarAlreadySoldVisible)}
        message={`${CAR_WAS_DELETED_MESSAGE}${deletedCarMassageInfo != null ? ` ${deletedCarMassageInfo}` : ''}.`}
        type='error'
      />

      <Banner
        isOpen={isCarAlreadySoldVisible}
        message={CAR_ALREADY_SOLD_MESSAGE}
        type='error'
      />

      <Banner
        endAdornment={creditAppEndAdornment}
        isOpen={isDealWaitingForCreditAppSubmit}
        message={CREDIT_APPLICATION_WAITING_MESSAGE}
      />

      <Banner
        endAdornment={creditAppEndAdornment}
        isOpen={isDealWaitingForCreditAppApprove}
        message={CREDIT_APPLICATION_PENDING_MESSAGE}
      />

      <Banner
        endAdornment={selectFormEndAdornment}
        isOpen={isDealWaitingForDocsSelection}
        message={DOCS_SELECTION_PENDING_MESSAGE}
      />

      <Banner
        endAdornment={signPaperworkEndAdornment}
        isOpen={isDealerSignaturePendingVisible}
        message={DEALER_SIGNATURE_PENDING_MESSAGE}
      />

      <Banner
        endAdornment={viewTransactionEndAdornment}
        isOpen={isDealIsRecordedVisible}
        message={DEAL_IS_RECORDED_MESSAGE}
        type='success'
      />
    </>
  )
}

export default BannersList

// ========================================== //

const getCreditAppLink = (
  dealId: EntityId,
  title: string
): JSX.Element => <NavLink to={CalcRoutes.CreditApplication(dealId)}>{title}</NavLink>
