import type { FC } from 'react'
import { Button, cnx } from '@carfluent/common'

import CustomerDetails from 'pages/accounting/CustomerDetails'
import BackToTop from 'components/common/BackToTop'
import Table from 'components/common/Table'
import FiltersPanel from 'components/common/FiltersPanel'

import useCustomers from './hook'

import CLASS_NAME from './styles'

const SettingsCustomers: FC = () => {
  const {
    columns, isModalOpen, selectedCustomerId,
    emptyState, search,
    rows, isLoading,
    onAddCustomer,
    onOpenCustomerDetails, onSearchChange,
    onCloseCustomerDetails, onDeleteCustomer,
    onSearch, onBottomReached
  } = useCustomers()

  return (
    <div className={cnx(CLASS_NAME, 'g-submenu-content')}>
      <FiltersPanel
        searchProps={{
          isLoading,
          onChange: onSearchChange,
          value: search,
          placeholder: 'Name, email, phone',
          withDeleteIcon: true,
          onSearch
        }}
        renderActions={() => (
          <Button onClick={() => onOpenCustomerDetails()}>
            NEW CUSTOMER
          </Button>
        )}
      />

      <Table
        columns={columns}
        data={rows}
        emptyTableMessage={emptyState}
        isLoading={isLoading}
        onRowClick={onOpenCustomerDetails}
        onBottomReached={onBottomReached}
      />

      <CustomerDetails
        customerId={selectedCustomerId}
        isOpen={isModalOpen}
        onClose={onCloseCustomerDetails}
        onDeleteCustomer={onDeleteCustomer}
        onSubmit={onAddCustomer}
      />

      <BackToTop />
    </div>
  )
}

export default SettingsCustomers
