import { TaskCompleteOptions, TaskType } from 'pages/crm/LeadDetailsView/hook/types'

export const RADIO_OPTIONS = [
  {
    label: 'Spoke to customer',
    value: TaskCompleteOptions.SpokeToCustomer,
    taskTypeId: TaskType['Credit app received']
  },
  {
    label: 'Left message',
    value: TaskCompleteOptions.LeftMessage,
    taskTypeId: TaskType['Credit app received']
  },
  {
    label: 'Unable to leave message',
    value: TaskCompleteOptions.UnableToLeaveMessage,
    taskTypeId: TaskType['Credit app received']
  }
]
