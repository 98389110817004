import type { FC } from 'react'

import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

import CLASS_NAME from './styles'

const LoaderSkeleton: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <div className='title-skeleton'>
        <TextSkeleton />
        <TextSkeleton />
      </div>
      <div className='content-skeleton'>
        <div className='car-photo-skeleton'>
          <TextSkeleton />
        </div>
        <div className='car-info-skeleton car-info-1'>
          <TextSkeleton />
          <TextSkeleton />
        </div>
        <div className='car-info-skeleton car-info-2'>
          <TextSkeleton />
          <TextSkeleton />
        </div>
        <div className='car-info-skeleton car-info-3'>
          <TextSkeleton />
          <TextSkeleton />
        </div>
      </div>
    </div>
  )
}

export default LoaderSkeleton
