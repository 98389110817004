import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'

import { joinPartsToStr } from 'utils/view_helper'
import type { CellProps } from '../types'

const AddressCell: FC<CellProps> = (props) => {
  const row = props.row.original
  const displayAddress = joinPartsToStr(', ', row.address, row.city, row.state, row.zipCode)

  return (
    <CellWrapper>
      <p>{displayAddress}</p>
    </CellWrapper>
  )
}

export default AddressCell
