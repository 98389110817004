import { type ReconVehicleDetailsResponseDto } from 'api/types'
import type { InspectionGeneralBlockProps } from 'components/reconditioning/InspectionGeneralBlock'
import { VehicleStateNames } from 'constants/names'

export enum Messages {
  FailedUpdateFavorite = 'Update priority request failed.',
  FailedUpdateStep = 'Update step request failed.',
  FailedUpdateImages = 'Update images request failed.',
  ImageSuccessfullyDeleted = 'Image was successfully deleted.',
  ImagesLoading = 'Pleaze wait while other images are loading.',
  NotFoundUpdateReconDetail = 'Vehicle step can not be updated because vehicle with this VIN code is not available'
}

export const getDefaultGeneralBlockData = (): InspectionGeneralBlockProps => ({
  statusInfo: '',
  passedCount: 0,
  failedCount: 0,
  isLoading: true,
  commentsCount: 0,
  isCompleted: false,
  progressPercentage: 0
})

export const getDefaultDetails = (): ReconVehicleDetailsResponseDto => ({
  id: 0,
  stepName: '',
  vin: '',
  year: 0,
  make: '',
  model: '',
  trim: '',
  mileage: 0,
  stock: '',
  salePrice: 0,
  mainImageUrl: '',
  isDeposited: false,
  vehicleState: VehicleStateNames.Inactive,
  isFavorite: false,
  logs: [],
  tags: [],
  stepDate: '',
  createdDate: '',
  inventoryDate: '',
  reconStep: {
    id: 0,
    order: 0,
    name: '',
    isReadonly: false,
    daysInStepUntilFirstAlert: '',
    daysInStepUntilSecondAlert: '',
    daysInReconUntilFirstAlert: '',
    daysInReconUntilSecondAlert: '',
    usedInVehicle: false,
    subscribers: []
  },
  disState: 'NoAlert',
  dirState: 'NoAlert',
  stepChanges: [],
  vehicleImages: []
})

export const DATE_FORMAT = 'MM/dd/yy'
