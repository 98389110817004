import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import Toggle from 'components/common/Toggle'
import UnsavedChangesDialog from 'components/dialogs/UnsavedChanges'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import FilterTabsSection from 'components/common/FiltersPanel/components/FilterTabsSection'

import Skeleton from './Skeleton'
import { TABS } from './hooks/constants'
import useWebsiteChatSettingsForm from './hooks'
import QuestionSection from './components/QuestionSection'

import CLASS_NAME from './styles'

const WebsiteChatSettingsForm: FC = () => {
  const {
    errors,
    onBlur,
    touched,
    formData,
    onChange,
    onSubmit,
    activeTab,
    hasChanges,
    isInitLoading,
    onToggleBot,
    setActiveTab,
    isSubmitting,
    onDiscardChanges,
    unsavedChangesModalProps
  } = useWebsiteChatSettingsForm()

  return (
    <div className={cnx('g-submenu-content g-margin-top-big general-settings-page', CLASS_NAME)}>
      {
       isInitLoading && <Skeleton />
      }
      {
        !isInitLoading && (
          <>
            <Toggle
              inversed
              className='toggle'
              onClick={onToggleBot}
              toggled={formData.botEnabled}
              activeLabel='Website chat with AI assistant'
            />

            {
              formData.botEnabled && (
                <>
                  <FilterTabsSection onSelectTab={(activeTab) => setActiveTab(activeTab)} tabs={TABS} activeTab={activeTab} />

                  <div className='form-container'>
                    {
                      formData.botMetadata[activeTab]?.map((question, index) => (
                        <QuestionSection
                          onBlur={onBlur}
                          question={question}
                          onChange={onChange}
                          key={question.question}
                          fieldPrefix={`botMetadata.${activeTab}.[${index}]`}
                          error={errors.botMetadata?.[activeTab][index].answer}
                          touched={touched.botMetadata?.[activeTab][index].answer}
                        />
                      ))
                    }
                  </div>
                </>
              )
            }

            <div className={cnx('tab-footer', hasChanges && 'is-shown')} role='footer'>
              <CancelSubmitActionsFooter
                submitTitle='SAVE'
                onSubmit={onSubmit}
                cancelTitle='DISCARD'
                isLoading={isSubmitting}
                onClose={onDiscardChanges}
              />
            </div>

            <UnsavedChangesDialog
              {...unsavedChangesModalProps}
              isSubmitting={isSubmitting}
              content='Your changes will be lost if you don’t save them.'
            />
          </>
        )
      }
    </div>
  )
}

export default WebsiteChatSettingsForm
