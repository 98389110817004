import { type FC, ReactNode } from 'react'
import { cnx } from '@carfluent/common'
import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

import CLASS_NAME from './styles'

export interface LabeledSkeletonProps {
  className?: string
  children?: ReactNode | ReactNode[]
  isLoading?: boolean
}

const LabeledSkeleton: FC<LabeledSkeletonProps> = ({
  children,
  className,
  isLoading = false
}) => {
  return (
    <div className={cnx(CLASS_NAME, 'labeled-skeleton', isLoading && 'is-loading', className)}>
      {isLoading && (
        <>
          <TextSkeleton className='label-skeleton' />
          <TextSkeleton className='field-skeleton' />
        </>
      )}

      {!isLoading && children}
    </div>
  )
}

export default LabeledSkeleton
