import { css } from '@emotion/css'

export const MAIN_CLASSES = css(`  
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;

  .content {
    width: 100%;
    min-width: 150px;
    display: table; // for responsive ellipsis
    table-layout: fixed;
    white-space: nowrap;
  }

  .cf-identifier,
  .cf-vin {
    display: table-cell; // for responsive ellipsis
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cf-identifier {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #101010;
    max-width: 220px;
    min-width: 150px;
  }

  .cf-vin {
    color: rgba(33, 33, 33, 0.80);
  }
`)
