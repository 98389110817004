import { css } from '@emotion/css'

export default css(`
  max-width: 130px;
  font-family: Roboto;
  color: #101010;
  line-height: 24px;
  letter-spacing: 0.5px;
  word-break: break-word;
  white-space: pre-wrap;
  text-decoration: underline;
  
  &:hover {
    text-decoration: none;
  }
`)
