import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGBank: FC<IconProps> = ({ bgColor = '#F3F3F3', color = '#212121' }) => {
  const WRAPPER_STYLE = {
    borderRadius: '50%',
    background: bgColor,
    display: 'flex',
    flex: '1 1 24px',
    justifyContent: 'center',
    alignItems: 'center',
    height: 24,
    minWidth: 24,
    maxWidth: 24
  }

  return (
    <div style={WRAPPER_STYLE}>
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.66927 0.664062L1.33594 3.9974V5.33073H14.0026V3.9974M10.6693 6.66406V11.3307H12.6693V6.66406M1.33594 14.6641H14.0026V12.6641H1.33594M6.66927 6.66406V11.3307H8.66927V6.66406M2.66927 6.66406V11.3307H4.66927V6.66406H2.66927Z'
          fill={color}
          fillOpacity='0.5'
        />
      </svg>
    </div>
  )
}

export default IconSVGBank
