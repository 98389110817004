import { useState, useEffect } from 'react'

import IdentityApiProvider from 'api/identity.api'
import { type DictionaryItem, ViewDealerRoles } from 'api/types'
import { ALL_USER_ROLES } from 'constants/constants'
import parseUsers from 'utils/parseUsers'

/**
 * @default [currentUser] - null
 * @default [userRoles] - allUserRoles
 * @default [parser] - parseUsers from 'utils/parseUsers'
 */
const useUsersList = (
  currentUser: DictionaryItem | null = null,
  userRoles: ViewDealerRoles[] = ALL_USER_ROLES,
  parser: (...args: any[]) => DictionaryItem[] = parseUsers
): DictionaryItem[] => {
  const [users, setUsers] = useState<DictionaryItem[]>([])

  useEffect(() => {
    const getUsers = async (): Promise<void> => {
      try {
        const { items } = await IdentityApiProvider.getUsers(userRoles)
        const parsedUsers = parser(items)

        if (currentUser != null) {
          /**
           * We should place the current user at the top of the list
           * and remove it from the retrieved list
           */
          setUsers([currentUser, ...parsedUsers.filter((user) => user.id !== currentUser.id)])
        } else {
          setUsers(parsedUsers)
        }
      } catch (e) {
        console.error(e)
      }
    }

    void getUsers()
  }, [])

  return users
}

export default useUsersList
