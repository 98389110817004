import type { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { DndContext, DragOverlay } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import { cn } from '@carfluent/common'

import Sortable from 'components/common/Sortable'
import ImageItem from 'components/inventory/ImageItem'
import useImageList, { UseImageListProps } from './hook'
import useStyles from './styles'

export interface ImageListProps extends UseImageListProps {
  mainImageUrl: string | null
  onSetMainPhoto?: (photoIdx: number) => void
  onDeleteFile: (photoIdx: number) => void
  onEditFile?: (photoIdx: number, fileType: string) => void
  imageItemClassName?: string
}

export const ImageList: FC<ImageListProps> = (props) => {
  const styles = useStyles()
  const { images, mainImageUrl, onSetMainPhoto, onDeleteFile, onEditFile, imageItemClassName = '' } = props
  const {
    dndContext, sortingContext, draggedItem,
    setNodeRef, dropAnimation
  } = useImageList(props)

  return (
    <DndContext {...dndContext}>
      <SortableContext {...sortingContext}>
        <div ref={setNodeRef} className={cn(styles.wrapper, 'cf-image-wrapper')}>
          {images.map((item, idx) => {
            const isMainItem = mainImageUrl === item.originalUrl
            const handleSetMain = onSetMainPhoto != null ? (): void => onSetMainPhoto(idx) : undefined
            const handleDelete = (): void => onDeleteFile(idx)
            const handleEdit = onEditFile != null ? (): void => onEditFile(idx, item.fileExtension ?? '') : undefined

            const imgUrl = item.uniqueName ?? item.thumbnailUrl ?? item.originalUrl

            return (
              <Sortable id={imgUrl} key={imgUrl}>
                <ImageItem
                  image={item}
                  isMain={isMainItem}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                  onSetMain={handleSetMain}
                  className={imageItemClassName}
                />
              </Sortable>
            )
          })}
        </div>
      </SortableContext>

      <DragOverlay adjustScale dropAnimation={dropAnimation}>
        {(draggedItem != null) && (
          <ImageItem
            image={draggedItem}
            isMain={mainImageUrl === draggedItem.originalUrl}
            className={imageItemClassName}
          />
        )}
      </DragOverlay>
    </DndContext>
  )
}

export default observer(ImageList)
