import { css } from '@emotion/css'

export default css(`
  .dropzone-container {
    padding-top: 40px;
    background: white;
  }
  
  .cf-dnd-overlay {
    background: white;
    opacity: 0.9;
    border: 2px dashed #C99B86;
  }
  
  .cf-content-wrapper {
    &.cf-content-hidden {
      visibility: visible;
    }
  }
`)
