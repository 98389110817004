import type { ColumnDef } from '@carfluent/common/dist/UI'

import TextCellHOC from 'components/common/Table/cells/TextCell'
import ValueCell from 'components/common/Table/cells/ValueCell'
import AmountCell from 'components/common/Table/cells/AmountCell'
import { DateCellHOC } from 'components/common/Table/cells/DateCell'
import NameCell from 'components/common/Table/cells/NameCell'

import { joinPartsToStr } from 'utils/view_helper'

import type { RecurringEntryRowData } from './types'

const columnDefinitions: Array<ColumnDef<RecurringEntryRowData>> = [
  {
    accessorKey: 'description',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Description' }),
    size: 320,
    sortable: false
  },
  {
    accessorFn: (row) => row.transactionTypeFilterId?.name ?? '-',
    id: 'transactionTypeFilterId',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Type' }),
    size: 184,
    sortable: false
  },
  {
    accessorKey: 'amount',
    cell: AmountCell,
    header: TextCellHOC({ text: 'Amount' }),
    size: 184,
    sortable: false
  },
  {
    accessorFn: (row) => row.recurringIntervalId?.name ?? '-',
    id: 'recurringIntervalId',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Repeats' }),
    size: 184,
    sortable: false
  },
  {
    accessorKey: 'createdDate',
    cell: DateCellHOC({ format: 'MM/dd/yyyy' }),
    header: TextCellHOC({ text: 'Created on' }),
    size: 184,
    sortable: false
  },
  {
    accessorFn: (row) => ({
      name: joinPartsToStr(' ', row.createdByFirstName, row.createdByLastName),
      showAvatar: true
    }),
    id: 'createdBy',
    cell: NameCell,
    header: TextCellHOC({ text: 'Created by' }),
    size: 184,
    sortable: false
  }
]

export default columnDefinitions
