import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  align-items: center;
  justify-content: center;
`)
