import type { DeepRequired } from '@carfluent/common'

import type { CustomerDto } from 'api/types'
import type { CustomerFormData } from './types'
import STATES_US from 'utils/states_us'
import { fullAddress } from 'utils/address'

const parseCustomerData = (data: CustomerDto): DeepRequired<CustomerFormData> => {
  const address = {
    address: data.address,
    city: data.city,
    zipCode: data.zipCode,
    state: data.state,
    county: null,
    addressLong: null
  }

  const mailingAddress = {
    address: data.mailingAddress,
    city: data.mailingCity,
    zipCode: data.mailingZipCode,
    state: data.mailingState,
    county: null,
    addressLong: null
  }

  const hasMailingAddress = Object.values(mailingAddress).some(Boolean)
  const addressStr = fullAddress(address)
  const mailingAddressStr = fullAddress(mailingAddress)

  return {
    id: data.id ?? null,
    firstName: data.firstName ?? null,
    lastName: data.lastName ?? null,
    email: data.email,
    phoneNumber: data.phoneNumber,
    ssn: data.ssn ?? null,
    driverLicense: data.driverLicense ?? null,
    stateOfIssue: STATES_US.find(s => s.abbreviation === data.stateOfIssue) ?? null,
    isDifferentMailingAddress: hasMailingAddress && (addressStr !== mailingAddressStr),
    address,
    mailingAddress,
    customerTypeId: data.customerTypeId,
    ein: data.ein ?? null,
    businessName: data.businessName ?? null
  }
}

export default parseCustomerData
