import { css } from '@emotion/css'
import { px, ContentLayoutWidth as CLW } from 'constants/constants'

export default css(`
  --footer-height: 80px;
  padding-bottom: 136px;

  .section-title {
    margin: 24px 0 16px 0;
    display: flex;
    font-size: 18px;

    :first-of-type { margin-top: 0; }
  }

  .content {
    display: grid;
    width: 100%;
    padding-bottom: 32px;
    max-width: ${px(CLW.MaxSettingsContent)};
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 24px;
  }

  .title-and-button {
    h5 { margin-right: 12px; }
  }

  .interest-rate-content {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: column;
  }

  .warranty-item-row {
    display: flex;
    align-items: center;
    column-gap: 18px;
    margin-top: 16px;
  }

  .two-fields-row {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);

    .cf-form-field { width: 100%; }
  }

  .info-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    &.excellent-status { color: #219653; }
    &.good-status { color: #719621; }
    &.average-status { color: #E16003; }
    &.below-average-status { color: #B00020; }
  }

  .info-value {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
  }

  .cf-form-field {
    width: 100%;

    label { width: max-content; }

    .cf-end-adornment, .cf-start-adornment {
      font-size: 1rem;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      line-height: 1.5;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .tab-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: var(--footer-height);
    box-shadow: 0px -2px 12px 0px #0000001F;
    background-color: #fff;
    
    position: fixed;
    z-index: 10;
    left: 0px;
    right: 0px;
    bottom: calc(var(--footer-height) * -1);
    padding-right: 64px;
    transition: bottom 0.3s ease;

    &.is-shown {
      bottom: 0px;
    }

    button {
      margin: 0px;

      &:first-child {
        margin-right: 24px;
      }
    }
  }

  &&.sales-monthly-target-tooltip,
  &&.trade-in-tooltip, {
    .description {
      font-size: 14px;
    }
  }
  
  .sales-commission-container {
    margin-bottom: 32px;
  }
  
  .fee-fields-container {
    margin-bottom: 32px;
  } 

  .sub-title-container {
    display: flex;
    flex-direction: column;
    
    h3 {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 8px;
      max-width: 200px;
    }

    .sub-title {
      color: rgba(33, 33, 33, 0.50);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      max-width: 800px;
    }
  }
  
  .cf-form-field {
    .disabled {
      .cf-input-adornment {
        color: rgba(33, 33, 33, 0.40);
      }
    }
  }

  .tab-footer {
    z-index: calc(var(--main-menu-zindex) - 1);
    transition: bottom 0.3s ease;
  }
  
  .field-loader {
    max-width: 297px;
    min-width: 225px;
    height: 56px;

    span {
      height: 100%;
    }
  }

  .title-skeleton {
    max-width: 200px;

    &.is-loading.with-space {
      margin-bottom: 16px;
    }
  }
`)
