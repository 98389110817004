import type { FC } from 'react'
import type { IconProps } from 'types'

const IconNotirized: FC<IconProps> = ({
  width = 24,
  height = 24
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" fill="#1C77CE"/>
      <path d="M15.8115 7.04688V17H14.4854L9.47461 9.32324V17H8.15527V7.04688H9.47461L14.5059 14.7441V7.04688H15.8115Z" fill="white"/>
    </svg>
  )
}

export default IconNotirized
