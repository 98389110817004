import { css } from '@emotion/css'

export default css(`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: 77px;
  padding: 0 8px;
  height: 24px;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0.25px;
  text-align: center;
  border: none;
  user-select: none;

  &.deal-action-sign {
    background-color: #D6E5FB;
    color: #173A70;
  }

  &.deal-action-review {
    background-color: #E3D8FB;
    color: #3E207D;
  }

  &.deal-action-submit {
    background-color: #FBE9D8;
    color: #60400D;
  }
`)
