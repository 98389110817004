import { css } from '@emotion/css'

export default css(`  
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: flex-start;

  .cf-label-more.cf-label-active,
  .cf-label-more:hover {
    background: #212121;
    color: white;
  }
`)
