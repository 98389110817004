import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import pDebounce from 'p-debounce'

import type { DateType } from 'api/types'
import CustomersCoreApiProvider from 'api/customersCore.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { downloadBlob } from 'utils/general'
import { asyncNoop } from 'constants/constants'

import {
  REPORT_FILE_NAMES,
  API_CALL_DELAY,
  GET_DEFAULT_FILTERS
} from './constants'
import type {
  UseReportGrossProfitReturn,
  GrossProfitReport,
  GrossProfitFilters
} from './types'
import serializeFilters from './serializer'

const DEFAULT_FILTERS = GET_DEFAULT_FILTERS()

const useReportGrossProfit = (): UseReportGrossProfitReturn => {
  const { showAlert } = useCustomSnackbar()

  const [data, setData] = useState<GrossProfitReport | null>(null)
  const [filters, _setFilters] = useState<GrossProfitFilters>(DEFAULT_FILTERS)
  const [isLoading, setIsLoading] = useState(false)

  const totalSale = data != null ? data.records.reduce((sum, record) => sum + record.salePrice, 0) : 0
  const totalGross = data != null ? data.records.reduce((sum, record) => sum + record.totalGross, 0) : 0
  const numberOfSales = data != null ? data.records.length : 0
  const averageGross = totalGross / numberOfSales

  const refFilters = useRef(filters)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const loadRows = useCallback(async (): Promise<void> => {
    setIsLoading(true)

    try {
      const payload = serializeFilters(refFilters.current)
      const response = await CustomersCoreApiProvider.getGrossProfitReport(payload, { cancelPrevious: true })

      setData(response)
    } catch (e) {
      showAlert(e)
    } finally {
      setIsLoading(false)
    }
  }, [showAlert])

  const loadRowsWithDelayLock = useMemo(() => pDebounce(loadRows, API_CALL_DELAY), [loadRows])

  const setFilters = useCallback(async (
    filters: Partial<GrossProfitFilters>,
    isDelayed = false
  ) => {
    refFilters.current = { ...refFilters.current, ...filters }
    _setFilters({ ...refFilters.current })

    if (isDelayed === true) {
      await loadRowsWithDelayLock()
    } else {
      await loadRows()
    }
  }, [loadRowsWithDelayLock, loadRows])

  const onDateFilterChange = useCallback(async (date: DateType) => {
    void setFilters({ dateFrom: date.from, dateTo: date.to })
  }, [setFilters])

  const onExportExcel = useCallback(async (): Promise<void> => {
    try {
      if (data == null) {
        return
      }

      const res = await CustomersCoreApiProvider.downloadGrossProfitReportXlsx(data)

      downloadBlob(res, REPORT_FILE_NAMES.xlsx)
    } catch (err) {
      showAlert(err)
    }
  }, [data, showAlert])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    void loadRows()
  }, [loadRows])

  // ========================================== //

  return {
    rows: data?.records ?? [],
    isLoading,
    onExportExcel,
    onDateFilterChange,
    onChangeDatesFilter: asyncNoop,
    setFilters,
    totalSale,
    totalGross,
    averageGross,
    numberOfSales
  }
}

export default useReportGrossProfit
