import { css } from '@emotion/css'

export const createStyleClass = (color: string): string => css`
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  
  &.with-padding {
    padding: 16px;

    .fi-product-card-header {
      border-radius: 12px;
    }
    
    .fi-product-card-body {
      padding: 0;
    }
  }

  .fi-product-card-header {
    border-radius: 12px 12px 0 0;
    background: ${color};
    padding: 8px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .header-content {
      width: 100%;
      line-height: 24px;
      color: #101010;
      font-weight: 500;
    }
  }

  .fi-product-card-body {
    padding: 0 24px 24px;
  }
`
