import type { FC } from 'react'
import { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import PageSubmenu from 'components/layout/PageSubmenu'
import AccountingHOK from 'pages/accounting/AccountingHOC'

import { Routes, AccountingSettingsSubRoutes } from 'constants/route_helper'
import { asRoute } from 'utils/route_helper'
import SettingsCTX from 'store/settings'

const LINKS = [
  { name: 'General', url: asRoute(AccountingSettingsSubRoutes.General) },
  { name: 'Opening balances', url: asRoute(AccountingSettingsSubRoutes.OpeningBalances) },
  { name: 'Recurring entry', url: asRoute(AccountingSettingsSubRoutes.RecurringEntries) },
  { name: 'List of vendors', url: asRoute(AccountingSettingsSubRoutes.Vendors) },
  { name: 'List of customers', url: asRoute(AccountingSettingsSubRoutes.Customers) }
]

export const Settings: FC = observer(() => {
  const {
    isAccountingEnabled
  } = useContext(SettingsCTX)

  return (
    <div className='g-content-layout g-overrides'>
      <div className='g-settings-body-wrapper'>
        <PageSubmenu basePath={Routes.AccountingSettings} links={LINKS} isDisabled={!isAccountingEnabled} />
        <Outlet />
      </div>
    </div>
  )
})

export default AccountingHOK(Settings)
