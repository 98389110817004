import { type FC } from 'react'
import { observer } from 'mobx-react-lite'
import { cnx } from '@carfluent/common'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import UnsavedChangesDialog from 'components/dialogs/UnsavedChanges'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

import GeneralSettingsFields from './components/GeneralSettingsFields'
import FeeFields from './components/FeeFields'
import Taxability from './components/Taxability'
import SalesCommissionFields from './components/SalesCommissionFields'
import useGeneralSettings from './hook'
import CLASS_NAME from './styles'

const GeneralSettings: FC = () => {
  const {
    isSubmitting,
    values,
    errors,
    touched,
    hasChanges,
    isOpenUnsavedChanges,
    isLoading,
    onBlur,
    onChange,
    onSubmit,
    onFormReset,
    onCloseUnsavedDialog,
    onDontSaveAndClose,
    onSaveAndClose,
    settings
  } = useGeneralSettings()

  const _className = cnx(
    'g-submenu-content',
    'g-margin-top-big',
    'cf-modal-content-scroll-wrapper',
    CLASS_NAME
  )

  return (
    <div className={_className}>

      <div className='title-and-button'>
        <BlockSkeleton isLoading={isLoading} className='title-skeleton'>
          <h5>General</h5>
        </BlockSkeleton>
      </div>

      <GeneralSettingsFields
        errors={errors}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        onBlur={onBlur}
        onChange={onChange}
        onFormReset={onFormReset}
        touched={touched}
        values={values}
      />

      <SalesCommissionFields
        backGrossSalesCommissionSetting={settings.backGrossSalesCommissionSetting}
        className='sales-commission-container'
        errors={errors}
        frontGrossSalesCommissionSetting={settings.frontGrossSalesCommissionSetting}
        isLoading={isLoading}
        onBlur={onBlur}
        onChange={onChange}
        touched={touched}
        values={values}
      />

      <FeeFields
        className='fee-fields-container'
        errors={errors}
        isLoading={isLoading}
        onBlur={onBlur}
        onChange={onChange}
        touched={touched}
        values={values}
      />

      <Taxability
        isLoading={isLoading}
        onChange={onChange}
        values={values}
      />

      <div className={cnx('tab-footer', hasChanges && 'is-shown')} role='footer'>
        <CancelSubmitActionsFooter
          isLoading={isSubmitting}
          onSubmit={onSubmit}
          onClose={onFormReset}
          cancelTitle='DISCARD'
          submitTitle='SAVE'
        />
      </div>

      <UnsavedChangesDialog
        content='Your changes will be lost if you dont save them.'
        isOpen={isOpenUnsavedChanges}
        onCloseDialog={onCloseUnsavedDialog}
        onDontSaveAndClose={onDontSaveAndClose}
        onSaveAndClose={onSaveAndClose}
        isSubmitting={isSubmitting}
      />
    </div>
  )
}

export default observer(GeneralSettings)
