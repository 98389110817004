import type { FC } from 'react'

import { cnx } from '@carfluent/common'
import PriorityIcon from '../Table/cells/TaskPriorityCell/PriorityIcons'

import CLASS_NAME from './styles'

interface TaskPriorityProps {
  value?: number | null
  iconsOnly?: boolean
}

function mapToPriority (num: number): string {
  switch (num) {
    case 1:
      return 'Low'
    case 2:
      return 'Medium'
    case 3:
      return 'High'
    default:
      return ''
  }
}
const TaskPriority: FC<TaskPriorityProps> = ({ value, iconsOnly = false }) => {
  if (value == null) {
    return null
  }

  return (
    <div className={cnx(CLASS_NAME, 'task-priority-fires')}>
      {iconsOnly
        ? <PriorityIcon id={value} />
        : (
          <>
            <PriorityIcon id={value} />
            <p>{mapToPriority(value)}</p>
          </>
          )}
    </div>
  )
}

export default TaskPriority
