import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'

import StarIcon from 'components/inlineImages/StarIcon'

import CLASS_NAME from './styles'

interface ReconCarCellHeaderProps {
  isFavorite: boolean
  title: string
  onChange: () => void
}

const ReconCarCellHeader: FC<ReconCarCellHeaderProps> = ({ isFavorite, title, onChange }) => {
  return (
    <CellWrapper className={CLASS_NAME}>
      <StarIcon
        onClick={(e) => {
          e.stopPropagation()
          onChange()
        }}
        activeColor='#101010'
        isActive={isFavorite}
      /> {title}
    </CellWrapper>
  )
}

export default ReconCarCellHeader
