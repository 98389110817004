import { type FC } from 'react'
import { cnx, isOk, noop, type Result } from '@carfluent/common'

import useTransitionBlocker from 'hooks/useTransitionBlocker'
import UnsavedChangesDialog from 'components/dialogs/UnsavedChanges'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import CLASS_NAME from './styles'

interface ActionsFooterAndUnsavedChangesProps {
  cancelUnsavedChangesTitle?: string
  isActionsFooterShown: boolean
  isSubmitting?: boolean
  isUnsavedChangesShown: boolean
  onSubmit: () => Promise<Result<unknown>>
  onCancel?: () => void
  onClose: () => void
  onOpen: () => void
  onResetTabNavigation: () => void
  onDontSaveAndClose?: () => void
  unsavedChangesTitle?: string
  unsavedChangesContent?: string
  submitUnsavedChangesTitle?: string
  shouldBlock: boolean
  shouldCallClose?: boolean
}

export const ActionsFooterAndUnsavedChanges: FC<ActionsFooterAndUnsavedChangesProps> = ({
  isSubmitting = false,
  isUnsavedChangesShown,
  isActionsFooterShown,
  onSubmit: _onSubmit,
  onCancel = noop,
  onOpen,
  onClose,
  onDontSaveAndClose: _onDontSaveAndClose,
  onResetTabNavigation,
  unsavedChangesTitle,
  unsavedChangesContent,
  submitUnsavedChangesTitle,
  cancelUnsavedChangesTitle,
  shouldBlock,
  shouldCallClose = true
}) => {
  const { proceedTransition, resetTransition } = useTransitionBlocker({
    shouldBlock,
    onBlock: onOpen
  })

  const onDontSaveAndClose = (): void => {
    _onDontSaveAndClose?.()
    if (shouldCallClose) {
      onClose()
    }

    proceedTransition()
  }

  const onCloseModal = (): void => {
    onClose()
    resetTransition()
    onResetTabNavigation()
  }

  const onSubmitFromModal = async (): Promise<void> => {
    const res = await _onSubmit()

    if (isOk(res)) {
      proceedTransition()
    } else {
      resetTransition()
      onResetTabNavigation()
    }

    onClose()
  }

  const onSubmitFromFooter = async (): Promise<void> => {
    await _onSubmit()
    resetTransition()
    onResetTabNavigation()
  }

  return (
    <>
      <UnsavedChangesDialog
        content={
          unsavedChangesContent ?? 'Changes done to this deal will be lost if you don\'t save them.'
        }
        isOpen={isUnsavedChangesShown}
        isSubmitting={isSubmitting}
        onCloseDialog={onCloseModal}
        onDontSaveAndClose={onDontSaveAndClose}
        onSaveAndClose={onSubmitFromModal}
        title={unsavedChangesTitle}
        submitTitle={submitUnsavedChangesTitle}
        cancelTitle={cancelUnsavedChangesTitle}
      />

      <div className={cnx(CLASS_NAME, 'tab-footer', isActionsFooterShown && 'is-shown')} role='footer'>
        <CancelSubmitActionsFooter
          isLoading={isSubmitting}
          onSubmit={onSubmitFromFooter}
          onClose={onCancel}
          cancelTitle='DISCARD'
          submitTitle='SAVE'
        />
      </div>
    </>
  )
}

export default ActionsFooterAndUnsavedChanges
