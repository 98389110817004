import { useCallback, useState } from 'react'
import CRMApiProvider from 'api/crm.api'
import type { DownloadLeadsByVehicleReportPayload, LeadsByVehiclePayload } from 'api/types'
import { downloadBlob } from 'utils/general'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

import { REPORT_FILE_NAMES } from './constants'
import type { UseReportLeadsByVehicleApiClientReturn } from './types'

const useReportLeadsByVehicleApiClient = (): UseReportLeadsByVehicleApiClientReturn => {
  const [makesAmount, setMakesAmount] = useState<Record<string, number>>({})
  const { showAlert } = useCustomSnackbar()

  const getReportLeadsByVehicles = useCallback(async (payload: LeadsByVehiclePayload) => {
    const [reportLeadsByVehicles, makesAmount] = await Promise.all([
      CRMApiProvider.getLeadsByVehicleReport(payload),
      CRMApiProvider.getMakesAmount({
        search: payload.search ?? '',
        dateRange: payload.dateRange ?? {
          from: null, to: null
        }
      })
    ])
    setMakesAmount(makesAmount.items.reduce((acc, item) => ({
      ...acc,
      [item.make]: item.amount
    }), {}))
    return reportLeadsByVehicles
  }, [])

  const downloadLeadsByVehicleReportXlsx = useCallback(async (payload: DownloadLeadsByVehicleReportPayload) => {
    try {
      const response = await CRMApiProvider.downloadLeadsByVehicleReportXlsx(payload)
      downloadBlob(response, REPORT_FILE_NAMES.xlsx)
    } catch (err) {
      showAlert(err)
    }
  }, [])

  return {
    makesAmount,
    getReportLeadsByVehicles,
    downloadLeadsByVehicleReportXlsx
  }
}

export default useReportLeadsByVehicleApiClient
