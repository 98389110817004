import { FC, useCallback, useState } from 'react'
import { Modal } from '@carfluent/common'

import Button from 'components/common/Button'
import CheckboxLabel from 'components/common/CheckboxLabel'

import CLASS_NAME from './styles'

interface DeleteModalWithStateProps {
  title: string
  content?: string
  isOpen: boolean
  handleConfirm: () => (Promise<void> | void)
  handleClose: () => void
  submitButtonTitle?: string
}

const MIN_WIDTH = 680

const DeleteModalWithState: FC<DeleteModalWithStateProps> = (props) => {
  const {
    title,
    isOpen,
    handleConfirm: onConfirm,
    content = 'I confirm and agree that this action cannot be undone.',
    submitButtonTitle = 'Delete',
    handleClose: _onClose
  } = props
  const [isConfirmed, setIsConfirmed] = useState(false)

  const checkboxLabelValue = {
    checked: isConfirmed,
    id: 0,
    name: content
  }

  const onToggleCheckbox = useCallback((): void => {
    setIsConfirmed(value => !value)
  }, [])

  const onClose = useCallback((): void => {
    setIsConfirmed(false)
    _onClose()
  }, [_onClose])

  const onSubmit = useCallback(async (): Promise<void> => {
    await onConfirm()
    onClose()
  }, [onClose, onConfirm])

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      minWidth={MIN_WIDTH}
      title={title}
    >
      <div className={CLASS_NAME}>
        <div className='cf-delete-modal-content'>
          <CheckboxLabel
            value={checkboxLabelValue}
            onChange={onToggleCheckbox}
          />
        </div>
        <div className='cf-delete-modal-buttons-wrapper'>
          <Button
            className='cf-delete-modal-cancel-button'
            data-test-id='btn-delete-modal-cancel'
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            className='cf-delete-modal-submit-button'
            disabled={!isConfirmed}
            data-test-id='btn-delete-modal-delete'
            onClick={onSubmit}
          >
            {submitButtonTitle}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModalWithState
