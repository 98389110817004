import { css } from '@emotion/css'

export const ROOT_CLASS_NAME = css(`
  position: relative;
  margin-left: 0!important;

  .cf-md {
    width: calc(100% - 48px);
    margin: 0px 24px;
    max-width: 100%;
    
    @media screen and (max-width: 1360px) {
      width: calc(100% - 48px - 64px);
      margin: 0px 24px;
      max-width: 100%;
    }
    
    @media screen and (min-width: 1440px) {
      width: 1300px;
      margin: 0 auto;
    }
  }
  
  .cf-report-top-actions {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`)

export const CONTENT_CLASS_NAME = css(`
  .cf-action-bar {
    display: none;
    opacity: 0;
  }

  .table-accounts-aging {
    border-collapse: separate;
    border-spacing: 0; // works only for 'separate'
    width: 100%;
    table-layout: fixed;
    margin-top: 24px;
  }
  
  td, th {
    font-family: Roboto;
    font-style: normal;
    letter-spacing: 0.15px;
    color: #101010;
    vertical-align: middle;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
  }

  thead tr {
    th.cell {
      height: 32px;
      padding: 4px 8px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #101010;
      background: #f3f3f3;

      &:first-of-type,
      &:nth-of-type(2),
      &:last-of-type {
        background: #fafafa;
      }

      &.past-group {
        color: rgba(33, 33, 33, 0.50);
      }
    }
  }
  
  thead .first-head-row {
    th.cell {
      &:first-of-type {
        border-top-left-radius: 10px;
      }

      &:last-of-type {
        border-top-right-radius: 10px;
      }
    }
  }

  thead .last-head-row {
    th.cell {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }
  }
  
  tfoot tr th.cell {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    font-size: 20px;
  }
  
  tfoot {
    tr > th { font-weight: 500; }
    tr:hover { background: #ffffff; }
  }

  tbody {
    border: 1px solid #fff;
  }
  
  tr:hover {
    background: #fafafa;
  }
  
  .cell {
    box-sizing: border-box;
    min-width: 100px;
    max-width: 170px;
    width: 13%;
    min-height: 64px;
    height: 64px;
    padding: 12px 8px;
    &:first-of-type {
      padding: 12px 8px 12px 16px;
    }

    &:last-of-type {
      padding: 12px 16px 12px 8px;
    }
    
    @media screen and (max-width: 1360px) {
      box-sizing: border-box;
      width: 14%;
    }
  }
  
  .cell.aligned-right {
    text-align: right;
    
    * {
      text-align: right;
    }
  }
  
  .cell.cell-entity {
    box-sizing: border-box;
    max-width: 280px;
    min-width: 150px;
    width: 21%;
    
    @media screen and (max-width: 1360px) {
      box-sizing: border-box;
      width: 14%;
    }
    
    p {
      font-weight: 500;
    }   
  }  
  
  tr.no-results {
    background: #FFF;
    
    .cell {
      background: #FFF;
      text-align: center;
      padding: 32px 16px 24px 16px;
    }
  }
  
  .loading-row {
    width: 100%;
    height: 16px;
    background: rgba(33, 33, 33, 0.08);
    border-radius: 2px;
  }
`)
