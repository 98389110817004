import { css } from '@emotion/css'

export default css(`
  --grid-gap: 16px;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: var(--grid-gap);
  row-gap: 24px;

  @media (max-width: 1359px) {
    grid-template-columns: repeat(5, 1fr);
  }

  &.cf-bottom-margin {
    margin-bottom: var(--grid-gap);
  }
`)
