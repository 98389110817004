import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'
import set from 'lodash-es/set'

import type { AccountingSettingsPayload, Warnings } from 'api/types'
import type { AccountingSettings as GlobalSettings } from 'store/settings'
import { GeneralSettings, ChartType } from '../types'

const DEFAULT_GENERAL_SETTINGS: GeneralSettings = {
  startDate: null,
  fiscalYearStartMonth: 1,
  printCheckNumber: true,
  printHorizontalOffset: 0,
  printVerticalOffset: 0,

  typeOfChart: ChartType.Default,
  fileUrl: null,
  file: null
}

/**
 * AZ-TODO: remove, convert to local state or to global store
 */
class AccountingSettings {
  generalSettings: GeneralSettings = DEFAULT_GENERAL_SETTINGS
  enableAccountingError: string | null = null
  warnings: Warnings = {}

  updateGeneralSettings = (rawFieldId: string, value: unknown): void => {
    set(this.generalSettings, rawFieldId, value)
  }

  setEnableAccountingError = (error: string | null): void => {
    this.enableAccountingError = error
  }

  setGeneralSettings = (globalSettings: GlobalSettings): void => {
    this.generalSettings.fiscalYearStartMonth = globalSettings.fiscalYearStartMonth
    this.generalSettings.printCheckNumber = globalSettings.printCheckNumber
    this.generalSettings.printVerticalOffset = globalSettings.printVerticalOffset
    this.generalSettings.printHorizontalOffset = globalSettings.printHorizontalOffset
  }

  setWarnings = (warnings: Warnings): void => {
    this.warnings = warnings
  }

  getPrintSettings = (): AccountingSettingsPayload => {
    return {
      printCheckNumber: this.generalSettings.printCheckNumber,
      printVerticalOffset: this.generalSettings.printVerticalOffset ?? 0,
      printHorizontalOffset: this.generalSettings.printHorizontalOffset ?? 0
    }
  }

  constructor () {
    makeAutoObservable(this)
  }
}

export const AccountingSettingsInstance = new AccountingSettings()
export const AccountingSettingsCTX = createContext(AccountingSettingsInstance)
export default AccountingSettingsCTX
