import type { ChangeEvent, FC } from 'react'
import { FormDropdown, FormInput, FormNumberInput, cnx } from '@carfluent/common'

import { MAX_PRICE_VALUE } from 'constants/validation'
import parseTermValue from 'utils/coverage/parseTermValue'
import formatTermValue from 'utils/coverage/formatTermValue'
import { GET_MANUAL_DEFAULT } from 'api/defaults/coverageDefault'
import getTermDescription from 'utils/coverage/getTermDescription'
import type { FICoverageProps } from 'pages/settings/FISettings/components/FIPackageSetup/hook/types'
import type { CoverageDefault, CoverageDefaultSection, CoverageProvider, CoverageTerm, CoverageWithTerms } from 'types/coverage'
import { COVERAGE_NAME_MAX_LENGTH, DEDUCTIBLE_AMOUNT_MAX_VALUE, TERM_OPTIONS } from 'components/deals/CoverageManualForm/constants'

import useCoverageFormReturn from './hook'
import { formatDeductibleOption } from './utils'
import type { PenFormAdditionalProps } from '../..'
import CoverageTypeSelector from '../CoverageTypeSelector'

import CLASS_NAME from './styles'

export interface CoverageFormProps extends FICoverageProps {
  idx: number
  isAdded?: boolean
  isDealForm?: boolean
  isTermVisible: boolean
  fieldPathPrefix: string
  isTermPenVisible?: boolean
  isProductDisabled?: boolean
  penPackages: CoverageProvider[]
  isDeductiblePenVisible?: boolean
  isDeductibleManualVisible: boolean
  onProductAdd?: (num: number) => void
  coverageSections: CoverageDefaultSection[]
  penFormAdditionalProps?: PenFormAdditionalProps
  onChangeManualVendor: (field: string, value: CoverageDefault) => void
}

const CoverageForm: FC<CoverageFormProps> = ({
  idx,
  errors,
  onBlur,
  touched,
  onChange,
  penPackages,
  onProductAdd,
  isTermVisible,
  validateField,
  isAdded = false,
  fieldPathPrefix,
  coverageSections,
  isDealForm = false,
  onChangeManualVendor,
  penFormAdditionalProps,
  isTermPenVisible = true,
  isProductDisabled = false,
  isDeductibleManualVisible,
  isDeductiblePenVisible = true
}) => {
  const {
    termList,
    disabledPen,
    deductibles,
    selectedTerm,
    currentPackage,
    selectedVendor,
    currentCoverage,
    filteredVendors,
    selectedCoverage,
    selectedDeductible
  } = useCoverageFormReturn({
    idx,
    isDealForm,
    penPackages,
    coverageSections
  })

  const isPenFormDisabled = (currentCoverage.forms.PEN === null || currentCoverage.forms.PEN.isFormDisabled) || isProductDisabled
  const isManualFormDisabled = currentCoverage.forms.Manual.isFormDisabled || isProductDisabled

  return (
    <div className={cnx(CLASS_NAME, isProductDisabled && 'disabled-product')}>
      <CoverageTypeSelector
        isProductDisabled={isProductDisabled}
        disabledPen={disabledPen}
        onChange={() => {
          if (isAdded) {
            onProductAdd?.(currentCoverage.productTypeId)
          }
          onChange(`${fieldPathPrefix}.coverageType`, currentCoverage.coverageType === 'PEN' ? 'Manual' : 'PEN')
        }}
        value={currentCoverage.coverageType}
      />

      {
        currentCoverage.coverageType === 'PEN'
          ? (
            <div className='coverage-form-wrapper'>
              <div className='field-wrapper'>
                <div className='row-wrapper'>
                  <FormDropdown
                    mode='search'
                    label='Vendor'
                    onBlur={onBlur}
                    value={selectedVendor}
                    dataTestId='pen-vendor'
                    options={filteredVendors}
                    disabled={isProductDisabled}
                    className='field-pen-dropdown'
                    id={`${fieldPathPrefix}.forms.PEN.providerId`}
                    error={errors[idx]?.forms.PEN?.providerId}
                    touched={touched[idx]?.forms.PEN?.providerId}
                    onChange={(_, value: CoverageProvider | null) => {
                      const selectedCoverage = value?.coverages[0]
                      const deductible = selectedCoverage?.termList[0]?.deductibles?.[0] ?? deductibles[0]
                      const cost = deductible.dealerCost
                      const totalPrice = deductible.dealerRetailPrice
                      const deductibleAmount = deductible.amount

                      if (isAdded && value == null) {
                        onProductAdd?.(currentCoverage.productTypeId)
                      }

                      onChange(`${fieldPathPrefix}.forms.PEN`, {
                        cost: value == null ? null : cost,
                        deductibleAmount: value == null ? null : deductibleAmount,
                        totalPrice: value == null ? null : totalPrice,
                        providerId: value == null ? null : value.id,
                        isFormDisabled: value == null,
                        productTypeId: value == null ? null : currentCoverage?.forms.PEN?.productTypeId,
                        id: value == null ? null : currentCoverage?.forms.PEN?.id,
                        coverageName: value == null ? null : selectedCoverage?.coverageName,
                        termMiles: value == null ? null : selectedCoverage?.termList[0].termMiles,
                        termMonths: value == null ? null : selectedCoverage?.termList[0].termMonths,
                        deductibleId: value == null ? null : deductible.id,
                        productCoverageId: value == null ? null : selectedCoverage?.termList[0].id
                      })
                    }}
                  />

                  <FormDropdown
                    label='Type'
                    onBlur={onBlur}
                    options={currentPackage}
                    value={selectedCoverage}
                    renderOption={el => el.coverageName}
                    id={`${fieldPathPrefix}.forms.PEN.coverageName`}
                    formatDisplayValue={el => el?.coverageName ?? ''}
                    disabled={isPenFormDisabled}
                    error={errors[idx]?.forms.PEN?.coverageName}
                    touched={touched[idx]?.forms.PEN?.coverageName}
                    onChange={(_, value: CoverageWithTerms | null) => {
                      const selectedCoverage = value
                      const deductible = selectedCoverage?.termList[0]?.deductibles?.[0] ?? deductibles[0]
                      const cost = penFormAdditionalProps?.isCostVisible === true ? deductible?.dealerCost : currentCoverage?.forms.PEN?.cost
                      const totalPrice = penFormAdditionalProps?.isTotalVisible === true ? deductible?.dealerRetailPrice : currentCoverage?.forms.PEN?.totalPrice

                      onChange(`${fieldPathPrefix}.forms.PEN`, {
                        ...currentCoverage?.forms.PEN as CoverageDefault,
                        cost: value == null ? null : cost,
                        totalPrice: value == null ? null : totalPrice,
                        coverageName: value == null ? null : selectedCoverage?.coverageName,
                        termMiles: value == null ? null : selectedCoverage?.termList[0].termMiles,
                        termMonths: value == null ? null : selectedCoverage?.termList[0].termMonths,
                        productCoverageId: value == null ? null : selectedCoverage?.termList[0].id,
                        deductibleId: value == null ? null : (deductible.id ?? null),
                        deductibleAmount: value == null ? null : (deductible.amount ?? null)
                      })
                    }}
                  />
                </div>

                <div className='row-wrapper'>
                  {
                  isTermPenVisible &&
                    <FormDropdown
                      label='Term'
                      onBlur={onBlur}
                      options={termList}
                      value={selectedTerm}
                      renderOption={getTermDescription}
                      formatDisplayValue={getTermDescription}
                      id={`${fieldPathPrefix}.forms.PEN.termMonths`}
                      disabled={isPenFormDisabled}
                      onChange={(_, value: CoverageTerm | null) => {
                        const deductible = value?.deductibles?.[0] ?? deductibles[0]
                        const cost = penFormAdditionalProps?.isCostVisible === true ? deductible.dealerCost : currentCoverage?.forms.PEN?.cost
                        const totalPrice = penFormAdditionalProps?.isTotalVisible === true ? deductible.dealerRetailPrice : currentCoverage?.forms.PEN?.totalPrice

                        onChange(`${fieldPathPrefix}.forms.PEN`, {
                          ...currentCoverage?.forms.PEN as CoverageDefault,
                          cost: value == null ? null : cost,
                          totalPrice: value == null ? null : totalPrice,
                          productCoverageId: value == null ? null : value.id,
                          termMiles: value == null ? null : value.termMiles,
                          termMonths: value == null ? null : value.termMonths,
                          deductibleId: value == null ? null : (deductible.id ?? null),
                          deductibleAmount: value == null ? null : (deductible.amount ?? null)
                        })
                      }}
                      error={errors[idx]?.forms.PEN?.termMonths}
                      touched={touched[idx]?.forms.PEN?.termMonths}
                    />
                  }

                  {
                    isDeductiblePenVisible && (
                      <FormDropdown
                        onBlur={onBlur}
                        label='Deductible'
                        options={deductibles}
                        value={selectedDeductible}
                        renderOption={formatDeductibleOption}
                        formatDisplayValue={formatDeductibleOption}
                        disabled={isPenFormDisabled}
                        id={`${fieldPathPrefix}.forms.PEN.deductibleAmount`}
                        error={errors[idx]?.forms.PEN?.deductibleAmount}
                        touched={touched[idx]?.forms.PEN?.deductibleAmount}
                        onChange={(_, value) => {
                          const cost = penFormAdditionalProps?.isCostVisible === true && value !== null ? value?.dealerCost : currentCoverage?.forms.PEN?.cost
                          const totalPrice = penFormAdditionalProps?.isTotalVisible === true && value !== null ? value?.dealerRetailPrice : currentCoverage?.forms.PEN?.totalPrice

                          onChange(`${fieldPathPrefix}.forms.PEN`, {
                            ...currentCoverage.forms.PEN,
                            deductibleAmount: value == null ? null : value?.amount,
                            deductibleId: value == null ? null : value?.id,
                            cost: value == null ? null : cost,
                            totalPrice: value == null ? null : totalPrice
                          })
                        }}
                      />
                    )
                  }
                </div>

                <div className='row-wrapper'>
                  {
                  penFormAdditionalProps?.isCostVisible === true &&
                    <FormNumberInput
                      disabled
                      label='Costs'
                      onBlur={onBlur}
                      startAdornment='$'
                      onChange={onChange}
                      decimalPrecision={2}
                      max={MAX_PRICE_VALUE}
                      isNegativeAllowed={false}
                      error={errors[idx]?.forms.Manual?.cost}
                      touched={touched[idx]?.forms.PEN?.cost}
                      id={`${fieldPathPrefix}.forms.PEN.cost`}
                      value={currentCoverage?.forms.PEN?.cost ?? ''}
                    />
                  }
                  {
                    penFormAdditionalProps?.isTotalVisible === true &&
                      <FormNumberInput
                        onBlur={onBlur}
                        startAdornment='$'
                        onChange={onChange}
                        label='Total price'
                        decimalPrecision={2}
                        isNegativeAllowed={false}
                        max={DEDUCTIBLE_AMOUNT_MAX_VALUE}
                        error={errors[idx]?.forms.PEN?.totalPrice}
                        touched={touched[idx]?.forms.PEN?.totalPrice}
                        id={`${fieldPathPrefix}.forms.PEN.totalPrice`}
                        value={currentCoverage.forms.PEN?.totalPrice ?? ''}
                        disabled={isPenFormDisabled}
                      />
                  }

                </div>
              </div>
            </div>
            )
          : (
            <div className='coverage-form-wrapper'>
              <div className='field-wrapper'>
                <div className='row-wrapper'>
                  <FormDropdown
                    mode='search'
                    label='Vendor'
                    onBlur={onBlur}
                    options={penPackages}
                    value={selectedVendor}
                    dataTestId='pen-vendor'
                    disabled={isProductDisabled}
                    className='field-pen-dropdown'
                    id={`${fieldPathPrefix}.forms.Manual.providerId`}
                    error={errors[idx]?.forms.Manual?.providerId}
                    touched={touched[idx]?.forms.Manual?.providerId}
                    onChange={(_, value: CoverageProvider | null) => {
                      if (isAdded && value == null) {
                        onProductAdd?.(currentCoverage.productTypeId)
                      }

                      if (value == null) {
                        onChangeManualVendor(`${fieldPathPrefix}.forms.Manual`, GET_MANUAL_DEFAULT())
                        return
                      }

                      onChangeManualVendor(`${fieldPathPrefix}.forms.Manual`, {
                        ...currentCoverage.forms.Manual,
                        isFormDisabled: false,
                        providerId: value?.id
                      })
                    }}
                  />

                  <FormInput
                    label='Type'
                    onBlur={onBlur}
                    maxLength={COVERAGE_NAME_MAX_LENGTH}
                    id={`${fieldPathPrefix}.forms.Manual.coverageName`}
                    disabled={isManualFormDisabled}
                    value={currentCoverage?.forms.Manual.coverageName ?? ''}
                    error={errors[idx]?.forms.Manual?.coverageName}
                    touched={touched[idx]?.forms.Manual?.coverageName}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      onChange(`${fieldPathPrefix}.forms.Manual.coverageName`, evt.target.value ?? null)
                    }}
                  />
                </div>

                <div className='row-wrapper'>
                  {
                  isTermVisible && (
                    <FormDropdown
                      label='Term'
                      onBlur={onBlur}
                      id={`${fieldPathPrefix}.forms.Manual.termMonths`}
                      disabled={isManualFormDisabled}
                      error={errors[idx]?.forms.Manual?.termMonths}
                      touched={touched[idx]?.forms.Manual?.termMonths}
                      value={currentCoverage.forms.Manual?.termMonths !== null && currentCoverage.forms.Manual?.termMonths > 0 ? formatTermValue(currentCoverage.forms.Manual?.termMonths ?? null, currentCoverage.forms.Manual?.termMiles) : null}
                      options={TERM_OPTIONS}
                      onChange={(id, value) => {
                        const { termMonths, termMiles } = parseTermValue(value)
                        onChange(`${fieldPathPrefix}.forms.Manual`, {
                          ...currentCoverage?.forms.Manual,
                          termMiles: termMiles,
                          termMonths: termMonths
                        })

                        validateField(`${fieldPathPrefix}.forms.Manual.termMiles`)
                        validateField(`${fieldPathPrefix}.forms.Manual.termMonths`)
                      }}
                    />)
                  }

                  {isDeductibleManualVisible && (
                    <FormNumberInput
                      mode='integer'
                      onBlur={onBlur}
                      label='Deductible'
                      preset='financial'
                      onChange={onChange}
                      isNegativeAllowed={false}
                      max={DEDUCTIBLE_AMOUNT_MAX_VALUE}
                      value={currentCoverage.forms.Manual.deductibleAmount}
                      disabled={isManualFormDisabled}
                      id={`${fieldPathPrefix}.forms.Manual.deductibleAmount`}
                      error={errors[idx]?.forms.Manual?.deductibleAmount}
                      touched={touched[idx]?.forms.Manual?.deductibleAmount}
                    />
                  )}
                </div>

                <div className='row-wrapper'>
                  <FormNumberInput
                    label='Costs'
                    onBlur={onBlur}
                    startAdornment='$'
                    onChange={onChange}
                    decimalPrecision={2}
                    max={MAX_PRICE_VALUE}
                    isNegativeAllowed={false}
                    value={currentCoverage?.forms.Manual.cost}
                    id={`${fieldPathPrefix}.forms.Manual.cost`}
                    disabled={isManualFormDisabled}
                    error={errors[idx]?.forms.Manual?.cost}
                    touched={touched[idx]?.forms.Manual?.cost}
                  />

                  <FormNumberInput
                    onBlur={onBlur}
                    startAdornment='$'
                    label='Total price'
                    onChange={onChange}
                    decimalPrecision={2}
                    isNegativeAllowed={false}
                    max={DEDUCTIBLE_AMOUNT_MAX_VALUE}
                    value={currentCoverage.forms.Manual.totalPrice}
                    id={`${fieldPathPrefix}.forms.Manual.totalPrice`}
                    disabled={isManualFormDisabled}
                    error={errors[idx]?.forms.Manual?.totalPrice}
                    touched={touched[idx]?.forms.Manual?.totalPrice}
                  />
                </div>
              </div>
            </div>
            )
      }
    </div>

  )
}

export default CoverageForm
