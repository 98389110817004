export enum CreditAppParts {
  ApplicantDetails = 'applicantFinancialDetails',
  CurrentEmployment = 'currentEmploymentDetails',
  PrevEmployment = 'previousEmploymentDetails',
  PrevHousing = 'previousAddressDetails',
  CoApplicantDetails = 'coApplicantFinancialDetails'
}

export enum EmploymentFields {
  Id = 'id',
  Status = 'employmentStatus',
  JobTitle = 'jobTitle',
  EmployerName = 'employerName',
  PhoneNumber= 'employerPhoneNumber',
  Income = 'income',
  StartDate = 'workingStartDate',
  EndDate = 'workingEndDate'
}

// export enum EmploymentStatus {
//   FullTime = 'Full time',
//   SelfEmployed = 'Self employed',
//   Retired = 'Retired',
//   Student = 'Student',
//   ActiveMilitary = 'Active military',
//   RetiredMilitary = 'Retired military',
//   Unemployed = 'Unemployed',
//   Other = 'Other'
// }

/**
 * DD-WARNING: EmployementStatus enums are used in the backend and should not be changed
 */

export enum EmploymentStatusIdWithPrevData {
  Retired = 3,
  Student = 4,
  Unemployed = 6,
  RetiredMilitary = 8
}

export enum EmploymentStatus {
  FullTime = 1,
  SelfEmployed = 2,
  Retired = 3,
  Student = 4,
  ActiveMilitary = 5,
  Unemployed = 6,
  Other = 7,
  RetiredMilitary = 8
}

// DD-NOTE: to be removed when coApplicantForm is refactored

export enum ApplicantFields {
  id = 'id',
  hasCoapplicant = 'hasCoapplicant',
  driverLicenseNumber = 'driverLicenseNumber',
  stateOfIssue = 'stateOfIssue',
  socialSecurityNumber = 'socialSecurityNumber',
  housingStatus = 'housingStatus',
  apartmentMoveInDate = 'apartmentMoveInDate',
  apartmentPayment = 'apartmentPayment',
  currentEmployment = 'currentEmploymentDetails',
  previousEmployment = 'previousEmploymentDetails',
  previousAddress = 'previousAddressDetails',
  incomeOption = 'incomeOption',
  otherIncome = 'otherIncome',
  hasOtherIncomeSources = 'hasOtherIncomeSources',
  wholesaleValue = 'wholesaleValue',
  wholesaleSourceType = 'wholesaleSourceType'
}

export enum CoApplicantFields {
  id = 'id',
  coApplicantType = 'coApplicantType',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phoneNumber = 'phoneNumber',
  birthDate = 'birthDate',
  driverLicenseNumber = 'driverLicenseNumber',
  stateOfIssue = 'stateOfIssue',
  socialSecurityNumber = 'socialSecurityNumber',
  housingStatus = 'housingStatus',
  currentAddressDetails = 'currentAddressDetails',
  previousAddressDetails = 'previousAddressDetails',
  sameAddressAsMainApplicant = 'sameAddressAsMainApplicant',
  currentEmploymentDetails = 'currentEmploymentDetails',
  previousEmploymentDetails = 'previousEmploymentDetails',
  incomeOption = 'incomeOption',
  otherIncome = 'otherIncome',
  hasOtherIncomeSources = 'hasOtherIncomeSources'
}

export enum AddressFields {
  id = 'id',
  address = 'address',
  secondaryAddress = 'secondaryAddress',
  city = 'city',
  state = 'state',
  zipCode = 'zipCode',
  county = 'county',
  apt = 'apt',
  apartmentMoveInDate = 'apartmentMoveInDate',
  apartmentPayment = 'apartmentPayment',
  addressData = 'addressData',
}
