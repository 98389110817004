import {
  type UIEvent,
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo
} from 'react'
import { useLoader } from '@carfluent/common'

import type { EntityId } from 'types'
import useEffectOnce from 'hooks/useEffectOnce'
import NotificationsAPIProvider from 'api/notifications.api'
import FilesApiProvider from 'api/files.api'
import type {
  MessagePayload,
  MessageTemplateItem,
  ListResponse,
  UploadedAssetWithThumbnailDto,
  VehicleImage
} from 'api/types'
import { Notifications } from 'constants/names'
import { DEFAULT_MAX_FILES_NUM, KiB } from 'constants/files'
import { ONE_LOAD_PAGE_SIZE } from 'constants/constants'
import { WebSocketsCrm as ws } from 'services/web_sockets'
import useFilesUpload, { type UseFilesUploadReturn } from 'hooks/useFilesUpload'

import type { PhotoAttachmentsProps } from 'pages/crm/LeadDetailsView/hook/types'
import type { MessageProps } from '../components/Message'
import type { ContentProps } from '../components/Content'
import getSendingMessage from './utils'

const MAX_ITEMS_LENGTH = 15

enum ActionTypes {
  Sent = 'Sent',
  Receive = 'Receive'
}

interface UseMessengerReturn extends Omit<ContentProps, 'onAddFiles' | 'isFilesNumLimitReached'> {
  attachmentsUploadProps: UseFilesUploadReturn & {
    isAttachmentsNumLimitReached: boolean
  }
  photoAttachmentsProps: PhotoAttachmentsProps
  messageTemplateItems: MessageTemplateItem[]
  leadId: EntityId
  photos: VehicleImage[]
  onSubmit: (message: string) => Promise<void>
  onDeletePhoto: (id: string) => void
}

export interface Action<M> {
  type: ActionTypes
  data: M
}

export interface UseMessengerProps<M> {
  leadId: EntityId
  config?: {
    sendingMessageName?: string | null
  }
  onAction?: (data?: Action<M>) => void
  transport: {
    getMessages: (payload?: string | null) => Promise<ListResponse<M>>
    sendMessage: (payload: MessagePayload) => Promise<M>
    getMessage: (id: string, leadId: number) => Promise<M | null>
  }
  parser: (item: M) => MessageProps
  photoAttachmentsProps: PhotoAttachmentsProps
  messages?: M[] | null
  isUnsubscribed: boolean
}

function useMessenger <M> ({
  leadId,
  onAction,
  parser,
  config,
  photoAttachmentsProps,
  isUnsubscribed,
  transport: {
    getMessages,
    sendMessage,
    getMessage
  },
  messages: _messages
}: UseMessengerProps<M>): UseMessengerReturn {
  const {
    vehiclePhotoAttachments: photos,
    onDeletePhoto,
    setAttachmentsInfo,
    setVehiclePhotoAttachments,
    setVehicleVideoAttachments
  } = photoAttachmentsProps

  const allHistoryFetched = useRef<boolean>(false)
  const loadingMessagesRef = useRef<MessageProps[]>([])
  const [messages, setMessages] = useState<MessageProps[]>([])
  const [loadingMessages, setLoadedMessage] = useState<MessageProps[]>([])
  const [messageTemplateItems, setMessageTemplateItems] = useState<MessageTemplateItem[]>([])

  const { isLoading, stopLoader, startLoader } = useLoader()

  const photosSizeKb = useMemo(() => (
    photos.reduce((acc, photo) => acc + photo.originalSizeKb, 0)
  ), [photos])

  const filesUploadProps = useFilesUpload<UploadedAssetWithThumbnailDto>({
    photosSizeKb,
    photosNum: photos.length,
    isEnabled: !isUnsubscribed,
    uploadAssets: FilesApiProvider.uploadFile
  })
  const { files, resetData } = filesUploadProps

  const attachments = useMemo(() => (
    [...files, ...photos]
  ), [files, photos])

  const attachmentsTotalSizeMb = useMemo(() => {
    const filesSizeKb = files.reduce((acc, { file }) => acc + file.size / KiB, 0)

    return (filesSizeKb + photosSizeKb) / KiB
  }, [files, photosSizeKb])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onFetch = useCallback(async (): Promise<void> => {
    try {
      startLoader()

      const { items } = await getMessages(messages[messages.length - 1].date)
      allHistoryFetched.current = items.length < MAX_ITEMS_LENGTH
      setMessages([...messages, ...items.map(parser)])
    } finally {
      stopLoader()
    }
  }, [messages, parser, getMessages, startLoader, stopLoader])

  const onScroll = useCallback(({ currentTarget }: UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = currentTarget
    /**
     * AS-NOTE: due to sub pixel precision, when we zoom out, scrollHeight can be 1 pixel less than
     */
    const isReadyToFetch = scrollHeight - 1 < (Math.abs(scrollTop) + clientHeight)

    if (isReadyToFetch && !isLoading && !allHistoryFetched.current) {
      void onFetch()
    }
  }, [onFetch, isLoading])

  const onSubmit = useCallback(async (message: string) => {
    const _imageUrls = attachments.map(v =>
      ('originalUrl' in v) ? v.originalUrl : (v.url ?? '')
    ).filter(v => v.length > 0)

    /**
     * we block sending while not all files loaded
     */
    if (_imageUrls.length !== attachments.length) {
      return
    }

    const isLimit = _imageUrls.length > 10

    const imageUrls = isLimit ? _imageUrls.slice(0, 10) : _imageUrls
    const sendingMessage = getSendingMessage(message, imageUrls, config?.sendingMessageName)

    loadingMessagesRef.current.unshift(sendingMessage)
    setLoadedMessage([...loadingMessagesRef.current])

    /**
     * intentionally cleaning images before successful sending.
     * It gives better UX.
     */
    resetData()
    setVehiclePhotoAttachments([])
    setVehicleVideoAttachments?.([])
    const item = await sendMessage({ message, imageUrls })

    const index = loadingMessagesRef.current.findIndex(({ id }) => id === sendingMessage.id)
    loadingMessagesRef.current.splice(index, 1)

    setLoadedMessage([...loadingMessagesRef.current])
    /**
     * intentionally adding local imageUrls for fast display in messages
     * and to avoid refetching those from the server.
     */
    setMessages(prev => [parser({ ...item, imageUrls }), ...prev])
    onAction?.({
      type: ActionTypes.Sent,
      data: item
    })
  }, [
    config, attachments, parser, sendMessage,
    onAction, resetData, setVehiclePhotoAttachments, setVehicleVideoAttachments
  ])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    setAttachmentsInfo({
      attachments,
      totalSize: attachmentsTotalSizeMb
    })
  }, [setAttachmentsInfo, attachments, attachmentsTotalSizeMb])

  useEffectOnce(() => {
    ws.on(Notifications.MessageReceived, {
      name: 'MessageReceived__Lead',
      action: (args) => {
        if (args.messageId != null) {
          const runRequest = async (): Promise<void> => {
            try {
              const data = await getMessage(args.messageId, args.leadId)

              if (data == null) {
                return
              }

              setMessages(prev => [parser(data), ...prev])

              onAction?.({
                type: ActionTypes.Receive,
                data
              })
            } catch (e) {
              console.error(e)
            }
          }

          void runRequest()
        }
      }
    })
  }, [onAction, parser, getMessage])

  useEffect(() => () => {
    ws.off(Notifications.MessageReceived)
  }, [])

  useEffectOnce(() => {
    const runEffect = async (): Promise<void> => {
      const [
        { items: messages },
        { items: messageTemplates }
      ] = await Promise.all([
        getMessages(),
        await NotificationsAPIProvider.getMessageTemplates({ skip: 0, take: ONE_LOAD_PAGE_SIZE })
      ])
      setMessageTemplateItems(messageTemplates)

      const parsedMessageItems = messages.map(parser)
      allHistoryFetched.current = messages.length < MAX_ITEMS_LENGTH
      setMessages(parsedMessageItems)
    }

    void runEffect()
  }, [getMessages])

  useEffect(() => {
    if (_messages != null && _messages?.length > 0) {
      const parsedMessageItems = _messages.map(parser)
      setMessages(parsedMessageItems)
    }
  }, [_messages])

  // ========================================== //

  return {
    leadId,
    loadingMessages,
    isUnsubscribed,
    messages,
    isLoading,
    attachmentsUploadProps: {
      ...filesUploadProps,
      isAttachmentsNumLimitReached: attachments.length >= DEFAULT_MAX_FILES_NUM
    },
    photoAttachmentsProps,
    messageTemplateItems,
    photos,
    onScroll,
    onSubmit,
    onDeletePhoto
  }
}

export default useMessenger
