import type { FC } from 'react'
import { UI } from '@carfluent/common'
import Textarea from 'components/common/Textarea'
import CLASS_NAME from './styles'

const { Loader } = UI

interface CommentProps {
  comment: string
  isLoading: boolean
  disabled: boolean
  onChange: (id: string | undefined, value: string) => void
  onSubmit: () => void
}

const Comment: FC<CommentProps> = ({
  disabled,
  isLoading,
  onChange,
  comment,
  onSubmit
}) => (
  <div className={CLASS_NAME}>
    <Textarea
      placeholder='Your comment'
      onChange={onChange}
      value={comment}
      disabled={isLoading}
      maxRows={1}
    />
    <div className='cf-comment-button-wrapper'>
      <button
        disabled={disabled || isLoading}
        className='cf-add-comment-btn'
        onClick={onSubmit}
      >
        {
          isLoading
            ? <Loader size={20} color='inherit' />
            : 'ADD COMMENT'
        }
      </button>
    </div>
  </div>
)

export default Comment
