import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'

import { UserRoles, type Login } from 'api/types'
import IdentityApiProvider from 'api/identity.api'
import { bodyLogin, changeDealerRequestToken } from 'constants/store_helpers'
import { Routes } from 'constants/route_helper'
import { getAccessToken, getUserRoles, setAccessToken, setRefreshToken } from 'services/storage.service'
import ws from 'services/web_sockets'
import { isPageAllowUnauthorized } from 'utils/route_helper'
import { isTokenValid } from 'utils/authHelpers'

class Auth {
  userRoles: UserRoles[] = getUserRoles()
  auth = false

  updateUserRoles = (): void => {
    this.userRoles = getUserRoles()
  }

  switchOn = async (): Promise<boolean> => {
    const isAuthPage = window.location.pathname === Routes.Login

    // TODO: Refactor this logic.
    // token validity and permissions as well as possible redirects should be checked on the Route level
    // using some navigation context where all this logic is isolated
    // so that it could be checked on each navigation
    // switchOn is used only on the app start so it is not consistent for this logic

    const handleTokenValid = async (): Promise<void> => {
      this.auth = true

      if (isAuthPage) {
        window.location.pathname = Routes.SettingsProfile
      }
    }

    const handleTokenInvalid = async (): Promise<void> => {
      this.auth = false
      await ws.shutdown()

      if (isAuthPage || isPageAllowUnauthorized(window.location.pathname)) {
        return
      }

      window.location.pathname = Routes.Login
    }

    if (isTokenValid(getAccessToken())) {
      await handleTokenValid()
    } else {
      await handleTokenInvalid()
    }

    return this.auth
  }

  loginAction = async ({ username, password }: Login): Promise<UserRoles[] | undefined> => {
    const data = bodyLogin(username, password)
    const result = await IdentityApiProvider.loginUser(data)

    if (result !== null && isTokenValid(result.access_token)) {
      setAccessToken(result.access_token)
      setRefreshToken(result.refresh_token)

      this.auth = true
      this.userRoles = getUserRoles()
      return [...this.userRoles]
    }
  }

  changeDealer = async (newDealerId: string | number, accessToken: string): Promise<UserRoles[] | undefined> => {
    const data = changeDealerRequestToken(newDealerId, accessToken)
    const result = await IdentityApiProvider.loginUser(data)

    if (result !== null && isTokenValid(result.access_token)) {
      setAccessToken(result.access_token)
      setRefreshToken(result.refresh_token)

      this.auth = true
      this.userRoles = getUserRoles()
      return [...this.userRoles]
    }
  }

  constructor () {
    makeAutoObservable(this)
  }
}

export const AuthInstance = new Auth()

export const AuthCTX = createContext(AuthInstance)
export default AuthCTX
