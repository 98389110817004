import {
  type FISettingsDto,
  type FISettingsModel
} from 'api/types/responses'
import parseFISetupMarkups from './parseFISetupMarkups'

const parseFISettingsDto = (settings: FISettingsDto): FISettingsModel => {
  const selectedTemplate = settings.coverageViewTemplates.find(({ isSelected }) => isSelected)

  return {
    coverageViewTemplates: settings.coverageViewTemplates,
    selectedTemplate: selectedTemplate != null
      ? {
          id: selectedTemplate.id,
          template: selectedTemplate
        }
      : null,
    packages: settings.packages,
    markups: parseFISetupMarkups(settings.markups)
  }
}

export default parseFISettingsDto
