import { type FC } from 'react'
import { cnx, formatInteger, formatCurrencyDecimal } from '@carfluent/common'

import { type CampaignModel } from 'api/types/responses'
import { EXCLUDED_FILTER_OPTION } from 'api/defaults/campaigns'
import { formatLocalDate } from 'utils/filters/filterPanel'
import IconSVG from 'components/inlineImages'
import TooltipedText from 'components/common/TooltipedText'
import StatusBadgeHOC from 'components/common/StatusBadge'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

import { getStatusesInfo, getStringOrDash, formatRateOfCount, formatCount } from './utils'
import FilterChips from '../FilterChips'
import CLASS_NAME from './styles'
import formatUserName from 'utils/formatters/formatUserName'

const DEFAULT_APPTS_FILTER = EXCLUDED_FILTER_OPTION()

export interface StepReviewProps {
  className?: string
  campaign: CampaignModel
  isLoading: boolean
  recipientsCount: number | null
}

const StatusBadge = StatusBadgeHOC(getStatusesInfo)
const SCHEDULED_FORMAT = 'MM/dd/yyyy hh:mm aaa'

const StepReview: FC<StepReviewProps> = ({
  className,
  campaign,
  isLoading,
  recipientsCount
}) => {
  const { statistics } = campaign
  const hasNoFilters = Object.values(campaign.filters).every(x => x == null)

  return (
    <div className={cnx(CLASS_NAME, 'review-screen-wrapper', className)}>
      <div className='panels-list'>
        <div className='panel top'>
          <div className='left-part'>
            <BlockSkeleton className='campaign-title' isLoading={isLoading}>
              <TooltipedText
                className='tooltiped-text'
                value={campaign.contentDetails.name}
              />
            </BlockSkeleton>

            <BlockSkeleton className='campaign-status' isLoading={isLoading}>
              <StatusBadge status={campaign.campaignStatusId} />
            </BlockSkeleton>
          </div>

          <BlockSkeleton className='right-part' isLoading={isLoading}>
            <IconSVG.People />

            <div className='campaign-field-value bold'>
              {formatInteger(statistics.totalSentCount ?? recipientsCount)}
            </div>
          </BlockSkeleton>
        </div>

        <div className='panel statistics'>
          <BlockSkeleton className='panel-title' isLoading={isLoading}>
            <h6>Statistics</h6>
          </BlockSkeleton>

          <div className='panel-content'>
            <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
              Total sent:
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
              <TooltipedText
                className='tooltiped-text'
                value={getStringOrDash(formatInteger(statistics.totalSentCount))}
              />
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
              Failed emails:
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
              <TooltipedText
                className='tooltiped-text'
                value={getStringOrDash(formatInteger(statistics.failedCount))}
              />
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
              Open rate:
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
              <TooltipedText
                className='tooltiped-text'
                value={formatRateOfCount(statistics.openRate, statistics.openCount)}
              />
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
              Click rate:
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
              <TooltipedText
                className='tooltiped-text'
                value={formatRateOfCount(statistics.clickRate, statistics.clickCount)}
              />
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
              Unsubscribed:
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
              <TooltipedText
                className='tooltiped-text'
                value={formatCount(statistics.unsubscribedCount)}
              />
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
              Appointments:
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
              <TooltipedText
                className='tooltiped-text'
                value={getStringOrDash(formatInteger(statistics.appointmentsCount))}
              />
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
              Sales:
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
              <TooltipedText
                className='tooltiped-text'
                value={getStringOrDash(formatInteger(statistics.salesCount))}
              />
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
              Total gross:
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
              <TooltipedText
                className='tooltiped-text'
                value={getStringOrDash(formatCurrencyDecimal(statistics.totalGrossAmount, { allowNegative: true }))}
              />
            </BlockSkeleton>
          </div>
        </div>

        <div className='panel details'>
          <BlockSkeleton className='panel-title' isLoading={isLoading}>
            <h6>Campaign details</h6>
          </BlockSkeleton>

          <div className='panel-content'>
            <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
              Sender name:
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
              <TooltipedText
                className='tooltiped-text'
                value={getStringOrDash(campaign.contentDetails.senderName)}
              />
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
              Sender email:
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
              <TooltipedText
                className='tooltiped-text'
                value={getStringOrDash(campaign.contentDetails.senderEmail)}
              />
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
              Scheduled on:
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
              <TooltipedText
                className='tooltiped-text'
                value={getStringOrDash(formatLocalDate(campaign.schedule.date, SCHEDULED_FORMAT))}
              />
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
              Created by:
            </BlockSkeleton>

            <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
              <TooltipedText
                className='tooltiped-text'
                value={formatUserName(campaign.createdByUser)}
              />
            </BlockSkeleton>
          </div>

          <BlockSkeleton className='panel-title' isLoading={isLoading}>
            <h6>Recipient segment</h6>
          </BlockSkeleton>

          <div className='panel-content'>
            {(campaign.filters.filterLeadStatus != null) && (
              <>
                <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
                  Lead status:
                </BlockSkeleton>

                <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
                  <FilterChips {...campaign.filters.filterLeadStatus} />
                </BlockSkeleton>
              </>
            )}

            {(campaign.filters.filterLeadSource != null) && (
              <>
                <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
                  Lead source:
                </BlockSkeleton>

                <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
                  <FilterChips {...campaign.filters.filterLeadSource} />
                </BlockSkeleton>
              </>
            )}

            {(campaign.filters.filterLeadTemperature != null) && (
              <>
                <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
                  Lead temperature:
                </BlockSkeleton>

                <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
                  <FilterChips {...campaign.filters.filterLeadTemperature} />
                </BlockSkeleton>
              </>
            )}

            {(campaign.filters.filterLeadWithScheduledAppointments != null) && (
              <>
                <BlockSkeleton className='campaign-field-title' isLoading={isLoading}>
                  Lead with appts:
                </BlockSkeleton>

                <BlockSkeleton className='campaign-field-value' isLoading={isLoading}>
                  <FilterChips
                    filter={campaign.filters.filterLeadWithScheduledAppointments?.filter ?? DEFAULT_APPTS_FILTER}
                    options={campaign.filters.filterLeadWithScheduledAppointments?.options ?? []}
                  />
                </BlockSkeleton>
              </>
            )}

            {!isLoading && hasNoFilters && (
              <div className='empty-filters-info'>No segment selected</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepReview
