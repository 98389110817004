import { type FC } from 'react'
import { formatCurrencyAccounting, formatters as F } from '@carfluent/common'

import IconSVG from 'components/inlineImages'
import TooltipedText from 'components/common/TooltipedText'

import CLASS_NAME from './styles'

export interface BankingAccountInfoProps {
  accountName: string
  change: number
  isReconciled: boolean
  reconciledOnDate?: Date | null
}

const BankingAccountInfo: FC<BankingAccountInfoProps> = ({
  accountName,
  change,
  isReconciled,
  reconciledOnDate
}) => {
  return (
    <div className={CLASS_NAME}>
      <div className='icon-container'>
        <IconSVG.StatusCheckmark color='#219653' />
        <IconSVG.StatusCheckmark color={isReconciled ? '#219653' : '#212121'} opacity={!isReconciled ? 0.4 : 1} />
      </div>

      <div className='text-container'>
        <b><TooltipedText className='banking-info' value={accountName} /> </b> for <b> {formatCurrencyAccounting(change, { allowNegative: true })}</b>
      </div>

      {(reconciledOnDate != null) && (
        <div className='date-container'>
          <IconSVG.CheckedCircle />
          <b>Reconciled on: {F.formatLocalDate(reconciledOnDate, 'MM/dd/yy')}</b>
        </div>
      )}
    </div>
  )
}
export default BankingAccountInfo
