import { css } from '@emotion/css'

export const createUseStyles = css(`
  width: 100%;
  display: flex;
  align-items: center;

  > p {
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    color: #000;
  }
`)

export const createPopoverStyles = css(`
  padding: 24px;

  .cf-pop-up-title {
    margin-bottom: 8px;
    color: #101010;
  }

  .cf-popper {
    width: 256px;
    color: inherit;
  }

  .cf-all-items-block {
    padding-left: 4px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    margin-bottom: 8px;
  }

  .cf-item-block {
    padding-left: 4px;
  }
`)
