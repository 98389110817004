import { useCallback, useState, useEffect } from 'react'

import type { DateType, AccountListItem } from 'api/types'
import type { TransactionsReport as ApiTransactionsReport } from 'api/types/accounting.types'
import { AccountingApiProvider } from 'api/accounting.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { downloadBlob } from 'utils/general'
import { type Period } from 'utils/filters/types'

import type { TransactionsReport } from './types'
import { getDefaultData, REPORT_FILE_NAMES } from './constants'
import parseTransactions from './parser'
import { serializeFilters, SerializeFiltersProps, serializeReportForXslx } from './serializer'

export interface UseReportTransactionsReturn {
  data: TransactionsReport
  onChangeDatesFilter: (period: DateType) => Promise<void>
  onExportExcel: () => Promise<void>
  setAccount: (account: AccountListItem | null) => void
  account: AccountListItem | null
  isLoading: boolean
}

const useReportTransactions = (): UseReportTransactionsReturn => {
  const { showAlert } = useCustomSnackbar()
  const [data, setData] = useState<TransactionsReport>(getDefaultData())
  const [report, setReport] = useState<ApiTransactionsReport | null>(null)
  const [period, setPeriod] = useState<Period | null>(null)
  const [account, setAccount] = useState<AccountListItem | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onChangeDatesFilter = async ({ from, to }: DateType): Promise<void> => {
    setPeriod({ startDate: from, endDate: to })
  }

  const onLoadReport = useCallback(async (props: SerializeFiltersProps) => {
    try {
      setIsLoading(true)
      const payload = serializeFilters(props)
      const response = await AccountingApiProvider.getTransactionsReport(payload)
      const data = parseTransactions(response)
      setData(data)
      setReport(response)
    } catch (err) {
      showAlert(err)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const onExportExcel = useCallback(async () => {
    try {
      if (report != null) {
        const payload = serializeReportForXslx(report)
        const response = await AccountingApiProvider.downloadTransactionsReportXlsx(payload)
        downloadBlob(response, REPORT_FILE_NAMES.xlsx)
      }
    } catch (err) {
      showAlert(err)
    }
  }, [report])

  useEffect(() => {
    if (period == null) {
      return
    }

    void onLoadReport({ account, period })
  }, [account, period, onLoadReport])

  // ========================================== //

  return {
    data,
    onChangeDatesFilter,
    onExportExcel,
    setAccount,
    account,
    isLoading
  }
}

export default useReportTransactions
