import { serializers as S } from '@carfluent/common'
import type { CostsReportRequestDto } from 'api/types'
import serializeDateFilters from 'utils/filters/serializeDateFilters'
import type { InventoryCostFilters } from './types'
import { ALL_SELECTED_ITEMS } from './constants'

const serializeFilters = (filters: InventoryCostFilters): CostsReportRequestDto => {
  const dates = serializeDateFilters({
    from: filters.dateFrom,
    to: filters.dateTo
  }, S.serializeDate)

  return {
    vehicleStatuses: filters.vehicleStatuses.length !== 0 ? filters.vehicleStatuses : ALL_SELECTED_ITEMS,
    dateTo: dates?.to ?? ''
  }
}

export default serializeFilters
