import { useCallback, useEffect, useMemo, useState } from 'react'
import { PRESET_LABELS } from '@carfluent/common'

import useTableApi from 'hooks/useTableApi'
import { calcTableHeight } from 'utils/math'
import useDragScroll from 'hooks/useDragScroll'
import { convertSortingOrder } from 'utils/general'
import type { DateType, ReportLeadsByVehiclesItem } from 'api/types'
import { getPageOverlayContainer } from 'utils/html'

import type {
  ReportLeadsByVehicleRowData,
  ReportLeadsByVehiclesListFilters,
  UseReportLeadsByVehiclesListReturn
} from './types'

import {
  Messages,
  DEFAULT_SORTING,
  TABLE_MIN_HEIGHT,
  API_CALL_DELAY_SEARCH
} from './constants'

import parseListData from './parser'
import { groupByMake } from './utils'
import { getPresets } from '../constants'
import serializeFilters from './serializer'
import useReportLeadsByVehicleApiClient from './api_client'

const presets = getPresets()
const LAST_30_DAYS = presets.find(p => p.name === PRESET_LABELS.LAST_30_DAYS)

const useReportLeadsByVehicle = (): UseReportLeadsByVehiclesListReturn => {
  const [dateRange, setDateRange] = useState<DateType>({ from: LAST_30_DAYS?.startDate ?? new Date(), to: LAST_30_DAYS?.endDate ?? new Date() })
  useDragScroll({ className: '.cf-table-wrapper' })

  const containerElement = getPageOverlayContainer()

  const {
    downloadLeadsByVehicleReportXlsx,
    makesAmount,
    getReportLeadsByVehicles
  } = useReportLeadsByVehicleApiClient()

  const {
    rows,
    search,
    sorting,
    loadRows,
    onSearch,
    isLoading,
    setSearch,
    setFilters,
    setSorting,
    emptyTableMessage
  } = useTableApi<ReportLeadsByVehiclesItem, ReportLeadsByVehiclesListFilters, ReportLeadsByVehicleRowData>({
    containerElement,
    parseListData,
    shouldRunOnCall: true,
    defaultFilters: { dateRange },
    defaultSorting: DEFAULT_SORTING,
    getList: getReportLeadsByVehicles,
    serializeFilters: serializeFilters,
    apiCallDelay: API_CALL_DELAY_SEARCH,
    emptyTableMessage: Messages.emptyTableState,
    nothingFoundMessage: Messages.nothingFoundState
  })

  const groupBy = useMemo(() => (sorting.id === 'make') ? groupByMake(makesAmount) : undefined, [sorting.id, makesAmount])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //
  const onDateFilterChange = useCallback(async (dateRange: DateType) => {
    void setFilters({ dateRange })
    setDateRange(dateRange)
  }, [setFilters])

  const onExportExcel = async (): Promise<void> => {
    await downloadLeadsByVehicleReportXlsx({
      search: search,
      sortField: sorting.id,
      sortOrder: convertSortingOrder(sorting.order),
      ...serializeFilters({ dateRange })
    })
  }

  const onBottomReached = useCallback(async () => {
    if (!isLoading && TABLE_MIN_HEIGHT <= calcTableHeight(rows.length)) {
      await loadRows({ forceRefresh: false })
    }
  }, [isLoading, loadRows, rows.length])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    /**
     * Set skeleton table at the same scroll position as main table
     * to align columns at this two tables
     */
    if (isLoading) {
      const outerDiv = document.querySelector('.cf-table-wrapper') as HTMLDivElement
      const innerDiv = document.querySelector('.skeleton-table-wrapper') as HTMLDivElement

      if (outerDiv != null && innerDiv != null) {
        innerDiv.scrollLeft = outerDiv.scrollLeft
      }
    }
  }, [isLoading])
  // ========================================== //

  return {
    containerElement,
    emptyTableMessage,
    groupBy,
    isLoading,
    onBottomReached,
    onDateFilterChange,
    onExportExcel,
    onFiltersChange: setFilters,
    onSearch,
    onSearchChange: setSearch,
    onSortingChange: setSorting,
    period: { startDate: dateRange.from, endDate: dateRange.to },
    presets,
    rows,
    search,
    sorting
  }
}

export default useReportLeadsByVehicle
