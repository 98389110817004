import { css } from '@emotion/css'

export default css(`
  &&.video-modal {
      .cf-modal-root {
        padding: 0px 0px 24px;
        .cf-modal-content,
        .cf-modal-title {
          padding: 24px 24px 0;
        }
      }
  }

  .modal-content {
    text-align: center;
  }

  .video-container { 
    max-height: 767px;
    min-width: 100%; 
    min-height: 100%; 
    width: 100%;
    height: auto;
  }
`)
