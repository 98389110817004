import { type UseFormReturn } from '@carfluent/common'

import { type WebsiteChatModel } from 'api/types/responses'
import { type CarOfInterest } from 'types/vehicles'
import {
  type AssignedUser,
  type DictionaryItem,
  type LeadData,
  type LeadTask,
  type ShiftDto
} from 'api/types'

export interface Shift extends Omit<ShiftDto, 'startDate' | 'endDate'> {
  startDate: Date
  endDate: Date
}

export interface Coords {
  x?: number
  y?: number
}

export interface CopyMonthFormData {
  sourceMonth: Date
  monthToFill: Date
}

export type WebsiteChatSettingsFormType = UseFormReturn<WebsiteChatModel>

export enum WebsiteChatAnswerTypes {
  None,
  Text,
  YesNo,
  MultipleChoice,
  Phone,
  Number
}

export enum TimelineTextTitles {
  Date = 'Date',
  Description = 'Description',
  ShowedUpOn = 'Showed up on',
  HeldOn = 'Held on',
  Source = 'Source',
  WorkNote = 'Work notes',
  From = 'From',
  EmailSubject = 'Email Subject',
  CustomerComment = 'Customer comment',
  DateTime = 'Preferred date & time',
  Deal = 'Deal',
  Car = 'Car',
  Salesperson = 'Salesperson',
  Reason = 'Reason',
  Type = 'Type'
}

export interface LeadFormData extends Omit<LeadData, 'tasks' | 'carsOfInterest' | 'birthDate'> {
  // TODO: add props
  carsOfInterest: CarOfInterest[]
  tasks: TasksFormData[]
  birthDate: Date | null
}

export interface TasksFormData extends Omit<LeadTask, 'id' | 'dueDate' | 'assignedUser' | 'subject' | 'taskPriorityId'> {
  id: number | null
  assignedUser: AssignedUser | null
  taskPriority: DictionaryItem | null
  dueDate: Date | null
  time: string | null
  subject: DictionaryItem | null
}
