import { type FC, useState } from 'react'
import { cn } from '@carfluent/common'
import { Draggable } from 'react-beautiful-dnd'

import Popover from 'components/common/Popover'
import IconButton from 'components/common/IconButton'
import Icon from 'components/common/Icon'
import type { ReconStepsDraggableRowProps } from 'pages/reconditioning/Settings/Steps/components/ReconStepsTable/types'
import ReconStepsTableRow from '../Row'

import { POPOVER_CLASS_NAME } from '../DroppableTable/styles'

const POPOVER_WIDTH = 324
const COORDS_TO_SHOW_ABOVE_ROW = { x: 50, y: -68 }

const FRONTLINE_STEP = 'Frontline'
const SOLD_STEP = 'Sold'

const FRONTLINE_STEP_TEXT = 'Frontline step is reconditioning default step. Cars on this step are moved automatically once car is moved to Frontline.'
const SOLD_STEP_TEXT = 'Sold step is reconditioning default step. Cars on this step are moved automatically once car is sold.'

const DraggableRow: FC<ReconStepsDraggableRowProps> = ({
  item,
  index,
  onOpenDeleteStepModal,
  dealerAccounts,
  ...formProps
}) => {
  const {
    touched,
    errors,
    onBlur,
    onChange
  } = formProps

  const draggableId = `${item.id}${index}`

  const onDeleteStepBtnClick = (): void => {
    onOpenDeleteStepModal(item.id)
  }

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className='cf-draggable-row'
        >
          <div className='cf-draggable-area' {...provided.dragHandleProps}>
            <Icon name='drag' />
          </div>
          <div
            className='cf-popover-area'
            onMouseEnter={(e) => {
              if (e.currentTarget.contains(e.target as Node)) {
                setAnchorEl(e.currentTarget)
              }
            }}
            onMouseOver={(e) => {
              if (!e.currentTarget.contains(e.target as Node)) {
                setAnchorEl(null)
              } else {
                setAnchorEl(e.currentTarget)
              }
            }}
            onMouseLeave={() => setAnchorEl(null)}
          >
            <ReconStepsTableRow
              isEditMode
              item={item}
              touched={touched}
              errors={errors}
              onBlur={onBlur}
              onChange={onChange}
              dealerAccounts={dealerAccounts}
              index={index}
            />
            {item.isReadonly === true && (
              <>
                <Popover
                  open={anchorEl != null}
                  anchorEl={anchorEl}
                  className={cn(POPOVER_CLASS_NAME, 'cf-popover-container')}
                  placement={{ vertical: 'top', horizontal: 'center' }}
                  transform={COORDS_TO_SHOW_ABOVE_ROW}
                  minWidth={POPOVER_WIDTH}
                  maxWidth={POPOVER_WIDTH}
                >
                  {item.name === FRONTLINE_STEP && FRONTLINE_STEP_TEXT}
                  {item.name === SOLD_STEP && SOLD_STEP_TEXT}
                </Popover>
              </>
            )}
          </div>

          <div className='cf-close-btn-area'>
            {item.isReadonly === true
              ? (<Icon name='close' className='disabled' />)
              : (<IconButton aria-label='close' onClick={onDeleteStepBtnClick} name='close' />)}
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default DraggableRow
