import { css } from '@emotion/css'

export default css(`
  width: 100%;
  padding: 0 24px;
  
  .cf-breakdown-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    
    & p {
      font-family: Roboto, sans-serif;
      color: #101010;
      line-height: 24px;
      font-weight: 400;
      font-size: 16px;

      &.cf-breakdown-label {
        color: rgba(33, 33, 33, 0.5);
      }
    }

    &.cf-breakdown-total {
      margin-bottom: 0px;

      & p {
        font-weight: 500;
        font-size: 24px;
      }
    }
  }

  hr {
    color: rgba(0, 0, 0, 0.3);
    margin-bottom: 16px;
  }
`)
