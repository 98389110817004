import { css } from '@emotion/css'

export default css(`
  display: flex;
  min-height: 56px;
  border-radius: 2px;
  padding-left: 32px;
  position: relative;
  flex-direction: row;
  align-items: center;
  background-color: #FFFFFF;
  justify-content: space-between;
  border-left: 4px solid transparent;

  :hover {
    background-color: #FAFAFA;
    border-top-left-radius: 3.5px;
    border-left: 4px solid #212121;
    border-bottom-left-radius: 3.5px;
  }

  .draggable-area {
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 32px;
    display: flex;
    margin-left: -4px;
    position: absolute;
    align-items: flex-start;
    justify-content: center;
    
    img {
      top: 22px;
      position: absolute;
    }
  }
`)
