import { CheckboxType } from 'types'
import { DictionaryItems } from 'api/types/utility.types'

export const mapToCheckboxes = (tags: DictionaryItems<string>, values: number[] = []): CheckboxType[] => {
  return tags.map(item => {
    return {
      ...item,
      label: item.name,
      checked: values.includes(item.id)
    }
  })
}
