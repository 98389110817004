import { useCallback, useState, useRef } from 'react'
import type { FC } from 'react'
import { cn, Button, PopoverV2 } from '@carfluent/common'

import CLASSES, { POPOVER_CLASS_NAME } from './styles'

export interface FooterActionsFSProps {
  disabled?: boolean
  isDeleteDisabled?: boolean
  isLoading?: boolean
  isPrintDisabled?: boolean
  isSubmitDisabled?: boolean
  onCancel: () => void
  titleCancel?: string
  titleOk?: string
  className?: string
  onDelete?: () => void
  onOk: () => Promise<void>
  onPrint?: () => Promise<void>
  titleDelete?: string
  titlePrint?: string
  showTooltip?: boolean
  tooltipMessage?: string | null
  printedDateTitle?: string | null
  deleteTooltipMessage?: string | null
}

const FooterActions: FC<FooterActionsFSProps> = ({
  className,
  disabled = false,
  isDeleteDisabled,
  isPrintDisabled,
  isSubmitDisabled,
  onCancel,
  onDelete,
  onOk: _onOk,
  onPrint: _onPrint,
  titleCancel = 'CANCEL',
  titleDelete = 'DELETE',
  titleOk = 'CREATE',
  titlePrint = 'PRINT',
  showTooltip = false,
  tooltipMessage = null,
  deleteTooltipMessage = null,
  printedDateTitle
}) => {
  const deleteAnchorEl = useRef<HTMLDivElement | null>(null)
  const successAnchorEl = useRef<HTMLDivElement | null>(null)
  const [isOpenDeleteAnchorEl, setIsOpenDeleteAnchorEl] = useState(false)
  const [isOpenSuccessAnchorEl, setIsSuccessAnchorEl] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)

  const onOk = useCallback(async () => {
    if (isSaving) {
      return
    }

    try {
      setIsSaving(true)
      await _onOk()
    } catch {
      // DO NOTHING
    } finally {
      setIsSaving(false)
    }
  }, [isSaving, _onOk])

  const onPrint = useCallback(async () => {
    if (isPrinting) {
      return
    }

    try {
      setIsPrinting(true)
      await _onPrint?.()
    } catch {
      // DO NOTHING
    } finally {
      setIsPrinting(false)
    }
  }, [isPrinting, _onPrint])

  return (
    <div className={cn(CLASSES, 'cf-footer-actions', className)}>
      <div className='cf-buttons-group'>
        {(onDelete != null) && (
          <div
            ref={deleteAnchorEl}
            onMouseEnter={() => setIsOpenDeleteAnchorEl(true)}
            onMouseLeave={() => setIsOpenDeleteAnchorEl(false)}
          >
            <Button
              className='cf-button cf-button-delete'
              isDisabled={isDeleteDisabled ?? disabled}
              onClick={onDelete}
              variant='outlined'
            >
              {titleDelete}
            </Button>
          </div>

        )}

        {(_onPrint != null) && (
          <>
            <Button
              className='cf-button cf-button-print'
              isDisabled={isPrintDisabled ?? disabled}
              isLoading={isPrinting}
              onClick={onPrint}
              variant='outlined'
            >
              {titlePrint}
            </Button>

            {printedDateTitle != null && <div className='print-period'>Last printed: {printedDateTitle}</div>}
          </>
        )}
      </div>

      <div className='cf-buttons-group cf-right-group'>
        <Button
          className='cf-button cf-button-cancel'
          onClick={onCancel}
          variant='outlined'
        >
          {titleCancel}
        </Button>

        <div
          ref={successAnchorEl}
          onMouseEnter={() => setIsSuccessAnchorEl(true)}
          onMouseLeave={() => setIsSuccessAnchorEl(false)}
        >
          <Button
            isDisabled={isSubmitDisabled ?? disabled}
            isLoading={isSaving || isPrinting}
            onClick={onOk}
          >
            {titleOk}
          </Button>
        </div>

      </div>

      {showTooltip && (
        <>

          <PopoverV2
            isOpen={isOpenDeleteAnchorEl}
            anchorEl={deleteAnchorEl}
            className={cn(POPOVER_CLASS_NAME, 'cf-popover-container', deleteTooltipMessage == null && 'delete', deleteTooltipMessage != null && 'small-delete')}
            options={{ placement: 'top-start' }}
          >
            {deleteTooltipMessage ?? 'Created by system automatically. To delete, manage the car in the Inventory section.'}
          </PopoverV2>

          <PopoverV2
            isOpen={isOpenSuccessAnchorEl}
            anchorEl={successAnchorEl}
            className={cn(POPOVER_CLASS_NAME, 'cf-popover-container', 'update')}
            options={{ placement: 'top-end' }}
          >
            {tooltipMessage ?? 'Created by system automatically. To update, manage the car in the Inventory section.'}
          </PopoverV2>
        </>
      )}

    </div>
  )
}

export default FooterActions
