import convertTimeSpanToNumber from 'utils/recon/convertTimeSpanToNumber'
import type { ReconStep } from 'api/types'

import type { ReconStepItemFormData } from './types'

const parseReconSteps = (steps: ReconStep[]): ReconStepItemFormData[] => {
  return steps.map((step: ReconStep) => ({
    ...step,
    subscribers: step.subscribers.map(({ id }) => id),
    daysInReconUntilFirstAlert: convertTimeSpanToNumber(step.daysInReconUntilFirstAlert),
    daysInReconUntilSecondAlert: convertTimeSpanToNumber(step.daysInReconUntilSecondAlert),
    daysInStepUntilFirstAlert: convertTimeSpanToNumber(step.daysInStepUntilFirstAlert),
    daysInStepUntilSecondAlert: convertTimeSpanToNumber(step.daysInStepUntilSecondAlert)
  }))
}

export default parseReconSteps
