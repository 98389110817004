import { useCallback, useMemo, useState } from 'react'
import type { Row } from '@tanstack/react-table'
import { useModal, UI, type Preset, SortingInfo, PRESET_LABELS, DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from '@carfluent/common'
import { useNavigate } from 'react-router-dom'

import {
  type WorkflowTypeDto,
  type DealsListItem,
  type DateType,
  DealFiltersState,
  DictionaryItem,
  DealsCounters,
  PaginatedResult
} from 'api/types'
import CustomersCoreApiProvider from 'api/customersCore.api'

import Routes from 'constants/route_helper'
import { calcTableHeight } from 'utils/math'
import { API_CALL_DELAY, PAGE_SIZE, TABLE_MIN_HEIGHT } from 'constants/constants'

import useTableApi from 'hooks/useTableApi'
import useAsyncEffect from 'hooks/useAsyncEffect'

import useWSUpdates from './useWSUpdates'
import { parseRowData, parseListData } from './parser'
import { getCancelFilterStateColumn, getAdvancedColumn } from './columns'
import { presetList, defaultSorting, defaultSoldSorting, defaultFilters, defaultCounters, SEARCH_OPTIONS } from './constants'
import serializeFilters from './serializer'
import {
  type DealRowData,
  type DealsListFilters,
  type FiltersKeys,
  type UseDealsListReturn
} from './types'

const DEFAULT_PRESETS: Preset[] = UI.getDefaultDateRangePresets(DEFAULT_MIN_DATE, DEFAULT_MAX_DATE)
  .filter(el => presetList.includes(el.name as PRESET_LABELS))

const DEFAULT_FILTERS = defaultFilters()
const DEFAULT_SORTING = defaultSorting()
const DEFAULT_SOLD_SORTING = defaultSoldSorting()
const DEFAULT_COUNTERS = defaultCounters()
const INIT_PRESET = DEFAULT_PRESETS[0]

const useDealsList = (): UseDealsListReturn => {
  const navigate = useNavigate()
  const addDealProps = useModal()
  const [workflowTypes, setWorkflowTypes] = useState<WorkflowTypeDto[]>([])
  const [tagIds, setTagIds] = useState<number[]>([])
  const [counters, setCounters] = useState<DealsCounters>(DEFAULT_COUNTERS)
  const [activeDatePreset, setActiveDatePreset] = useState<Preset | null>(INIT_PRESET)
  const [activeSearchOption, setActiveSearchOption] = useState<DictionaryItem>(SEARCH_OPTIONS[0])
  const [isFiltersChange, setFiltersChange] = useState(false)
  const [isScrolling, setScrolling] = useState(false)

  /**
   * For web sockets updates we need to hide loader and skeleton
   */
  const [isLoadingInvisible, setLoadingInvisible] = useState(false)

  const getDealList = useCallback(async (payload): Promise<PaginatedResult<DealsListItem>> => {
    try {
      setFiltersChange(true)
      const isSold = payload.workflowSearchState === DealFiltersState.Sold
      const [countersResp, listResp] = await Promise.all([
        CustomersCoreApiProvider.getDealsTotal({
          search: payload.search ?? null,
          workflowAccountingStates: payload.workflowAccountingStates,
          isDeposited: payload.isDeposited ?? false,
          nextAction: payload.nextAction,
          date: (isSold ? payload.saleDate : payload.date) ?? null,
          dealPaidStatusId: payload.dealPaidStatusId
        }),
        CustomersCoreApiProvider.getDeals(payload)
      ])

      const parsedCounters: DealsCounters = Object.entries(countersResp).reduce((acc, [key, value]) => {
        return { ...acc, [DealFiltersState[key as keyof typeof DealFiltersState]]: value }
      }, { ...DEFAULT_COUNTERS })

      setCounters(parsedCounters)
      return { ...listResp }
    } catch (err) {
      setCounters(DEFAULT_COUNTERS)
      return { items: [], count: 0 }
    } finally {
      setFiltersChange(false)
    }
  }, [])

  const {
    emptyTableMessage,
    rows,
    search,
    sorting,
    filters,
    isLoading,
    loadRows,
    setRows,
    setFilters,
    setSorting,
    setSearch,
    startLoader,
    stopLoader,
    onSearch
  } = useTableApi<DealsListItem, DealsListFilters, DealRowData>({
    getList: getDealList,
    serializeFilters,
    parseListData,
    defaultFilters: DEFAULT_FILTERS,
    defaultSorting: DEFAULT_SORTING,
    apiCallDelaySearch: API_CALL_DELAY
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onSortingChange = useCallback(async (sorting: SortingInfo) => {
    void setSorting(sorting)
  }, [setSorting])

  const onTableFiltersChange = useCallback((column: FiltersKeys) => async (ids: number[]) => {
    if (column === 'workflowAccountingStates') setTagIds(ids)
    await setFilters({ [column]: ids })
  }, [setFilters])

  const onFiltersChange = useCallback(async (filterId: DealFiltersState | null) => {
    void setFilters({
      ...DEFAULT_FILTERS,
      workflowSearchState: filterId,
      isDeposited: filterId === DealFiltersState.Sold ? false : filters.isDeposited
    })

    if (filterId === DealFiltersState.Sold) {
      void setSorting(DEFAULT_SOLD_SORTING)
    } else {
      void setSorting(DEFAULT_SORTING)
    }
  }, [setFilters, filters.isDeposited, setSorting])

  const onDateFiltersChange = useCallback(async (date: DateType) => {
    void setFilters({
      date
    })
  }, [setFilters])

  const onSearchOptionChange = useCallback(async (props: DictionaryItem) => {
    if (props.id === DealFiltersState.Incomplete) {
      setActiveSearchOption(SEARCH_OPTIONS[0])
    } else {
      setActiveSearchOption(props)
    }
    setTagIds([])
    void setFilters({
      date: props.id === DealFiltersState.Canceled ? { from: null, to: null } : filters.date,
      workflowSearchState: props.id,
      workflowAccountingStates: []
    })
  }, [setFilters, filters.date])

  const onDepositCheckboxChange = useCallback(async (isDeposited: boolean) => {
    void setFilters({
      isDeposited,
      workflowAccountingStates: []
    })
  }, [activeSearchOption])

  const onOpenDealDetails = useCallback((row?: Row<DealRowData>) => {
    const dealId = row?.original.id ?? null
    if (dealId != null) {
      navigate(Routes.DealView(dealId))
    }
  }, [navigate])

  /**
   * Increases counter of the appropriate tab
   * and adds newly created deal if tab matches to the deal status.
   *
   * The tab matches to any new deal.
   */
  const onDealAdded = useCallback((deal: DealsListItem) => {
    const { workflowSearchState: activeFilter } = filters
    const parsedDeal = parseRowData(deal)

    if (activeFilter === null) {
      setRows([parsedDeal].concat(rows))
    }
  }, [
    filters, rows, setRows
  ])

  const onDealUpdated = useCallback(async () => {
    setLoadingInvisible(true)
    const take = rows.length < PAGE_SIZE ? PAGE_SIZE : rows.length

    await loadRows({ forceRefresh: true, pageSize: take, forceScrollToSkeleton: false })
    setLoadingInvisible(false)
  }, [rows.length, loadRows])

  const onBottomReached = useCallback(async () => {
    if (!isLoading && TABLE_MIN_HEIGHT <= calcTableHeight(rows.length)) {
      setScrolling(true)
      await loadRows({ forceRefresh: false })
      setScrolling(false)
    }
  }, [isLoading, loadRows, rows.length])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Initial data loading.
   */
  useAsyncEffect(async () => {
    startLoader()

    const loadWorkflowTypes = async (): Promise<void> => {
      try {
        const response = await CustomersCoreApiProvider.getWorkflowTypes()
        setWorkflowTypes(response.items)
      } catch {
        // DO NOTHING
      }
    }

    await Promise.all([
      loadWorkflowTypes(),
      loadRows()
    ])

    stopLoader()
  }, [startLoader, stopLoader, loadRows])

  /**
   * Updates Deals table by WebSocket events.
   */
  useWSUpdates({
    onDealAdded,
    onDealUpdated
  })

  // ========================================== //

  const basicColumns = useMemo(() => {
    const { workflowSearchState } = filters
    return (workflowSearchState === DealFiltersState.Canceled)
      ? getCancelFilterStateColumn()
      : getAdvancedColumn({
        filters,
        onChange: onTableFiltersChange
      })
  }, [onTableFiltersChange, filters, tagIds])

  return {
    columns: basicColumns,
    counters,
    emptyState: emptyTableMessage,
    isAddDealOpen: addDealProps.isModalOpen,
    isLoading: isLoading && !isLoadingInvisible,
    isFiltersChange: isFiltersChange && !isScrolling && !isLoadingInvisible,
    filters,
    dateFiltersProps: {
      initPreset: INIT_PRESET,
      activeDatePreset,
      presets: DEFAULT_PRESETS,
      onFilterChange: onDateFiltersChange,
      onPresetChange: setActiveDatePreset
    },
    activeSearchOption,
    onSearchOptionChange,
    onDepositCheckboxChange,
    onCloseAddDeal: addDealProps.onCloseModal,
    onOpenAddDeal: addDealProps.onOpenModal,
    onOpenDealDetails,
    onFiltersChange,
    onSearchChange: setSearch,
    onSortingChange,
    onSearch,
    onBottomReached,
    rows,
    search,
    sorting,
    workflowTypes
  }
}

export default useDealsList
