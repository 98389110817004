import { FC, ChangeEvent, useMemo } from 'react'
import { RadioGroup as MuiRadioGroup } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { cn } from '@carfluent/common'
import partialRight from 'lodash-es/partialRight'

import RadioLabelControl from '../RadioLabelControl'

import CLASS_NAME from './styles'

export interface RadioGroupProps {
  classes?: {
    root?: string
    item?: string
  }
  className?: string
  disabled?: boolean
  id: string
  items?: RadioItem[]
  onChange: (evt: ChangeEvent<HTMLInputElement>, value: string, id: string) => void
  value: string | null
}

export interface RadioItem {
  label: string
  value: string
}

export const RadioGroup: FC<RadioGroupProps> = ({
  classes,
  className,
  disabled,
  id,
  items = [],
  onChange,
  value,
  children
}) => {
  const handleOnChange = useMemo(() => partialRight(onChange, id), [onChange, id])

  return (
    <MuiRadioGroup
      aria-label={id}
      className={cn(CLASS_NAME, className, classes?.root)}
      name={id}
      onChange={handleOnChange}
      value={value}
    >
      {children}
      {items.map((props) => {
        return (
          <RadioLabelControl
            {...props}
            key={`${props.label}-${props.value}`}
            disabled={disabled}
            className={classes?.item}
          />
        )
      })}
    </MuiRadioGroup>
  )
}

export default observer(RadioGroup)
