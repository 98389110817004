import { css } from '@emotion/css'

export default css(`
  .bank-statement-banner-container {
    display: flex;
    width: 100%;
    padding: 8px 9px;
    flex-direction: column;
    gap: 8px;
    border-radius: 12px;
    background: #ECF4FF;
  }

  .accounts-info-container {
    .title {
      color: #101010;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
      margin-bottom: 4px;
    }
  }
`)
