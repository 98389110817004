import { css } from '@emotion/css'

const UNDERLINED_TEXT = `
  * > span, * > p  {
    text-decoration: underline;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
`

export default css(`
  && {
    width: 100%;
    min-width: 956px;
    max-width: 1244px;
  }

  .cf-calendar-section {
    position: relative;
  }
  
  .cf-header-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
  }

  .cf-table-header-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: rgba(0, 0, 0, 0.87);
  }

  .cf-filters-block {
    display: flex;
    height: 24px;
    margin: 16px 0 24px 0;
    align-items: center;
    ${UNDERLINED_TEXT}
  }
`)
