import { css } from '@emotion/css'

export const LABEL_CLASS_NAME = css(`
  display: inline-flex;
  align-items: center;
  padding: 2px 8px 2px 8px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #212121; 
  line-height: 16px;
  font-weight: 400;
  font-size: 12px;
  color: #212121;
  
  img {
    margin-left: 8px;
  }
`)

export const POPOVER_CLASS_NAME = css(`
  padding: 0;
`)

export const CONTENT_CLASS_NAME = css(`
  padding: 16px;
  width: 100%;
  height: 100%;
`)
