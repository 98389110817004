import { dates, formatCurrencyDecimal, formatters, parsers } from '@carfluent/common'

import { WorkflowStep } from 'api/types'
import { PaymentStatusId, DealWorkflowAccountingStateId } from 'types/number_enums'
import {
  NavigationStep,
  PaymentName,
  PaymentStatus,
  DealWorkflowAccountingStateNames,
  WorkflowStepToNavigationStepMap
} from 'constants/names'
import { DATE_US_FORMAT, DATETIME_US_12H_MIN_AMPM_FORMAT } from 'constants/constants'
import { PaymentTransactionTypeEnum } from '../types/payments'
import { formatInteger } from './format_number'

const { parseDateStringUTC } = parsers
const { formatLocalDate } = formatters
const { isValidDate } = dates

export const joinPartsToStr = (sep: string, ...args: Array<string | number | null | undefined>): string => {
  return args.filter(Boolean).join(sep).trim()
}

export const joinPartsBySpace = joinPartsToStr.bind(null, ' ')

export const formatPhoneNumberForView = (value: string | null): string | null => {
  const patternNumber = '(###)###-####'

  if (value == null || value.length === 0) {
    return null
  }

  let i = 0
  const formattedPhoneNumber = patternNumber.replace(/#/g, _ => value[i++] ?? '')

  return formattedPhoneNumber
}

export const formatLocalDateTimeForView = (
  date: Date | string | null,
  pattern: string = DATETIME_US_12H_MIN_AMPM_FORMAT
): string | null => {
  return formatLocalDate(date, pattern)
}

/**
 * For now we use this method only for BE-generated fields,
 * like `currentWorkflowStepStartedAt` and `creditApplicationSubmittedDate`.
 *
 * For more details please check
 * https://dev.azure.com/carfluent/CarFluent/_wiki/wikis/CarFluent.wiki/65/Date-and-DateTimes-coversions
 *
 * DON'T USE THIS FOR DATES THAT ARE CREATED ON FRONT-END!
 */
export const formatUTCDateTimeForView = (
  date: string | null | undefined,
  pattern: string = DATE_US_FORMAT
): string | null => {
  const dateUtc = parseDateStringUTC(date)
  if (!isValidDate(dateUtc)) {
    return null
  }

  return formatLocalDate(dateUtc, pattern)
}

export const formatInventoryDateWithAgeForView = (
  date: string | null,
  age: string | number,
  pattern: string = DATE_US_FORMAT
): string | null => {
  const formatterDate = formatLocalDate(date, pattern)
  return formatterDate == null ? null : `${formatterDate} (${age} days)`
}

export const formatCurrencyDecimalForView = (value: number | null): string | null => {
  if (value === null || value === 0) {
    return null
  }

  return formatCurrencyDecimal(value)
}

export const formatIntegerForView = (value: string | number | null, suffix: string = ''): string | null => {
  if (value === null || value === 0) {
    return null
  }

  const valueInteger = formatInteger(value)

  return `${valueInteger}${suffix}`
}

export const formatPaymentMethodForView = (paymentMethodId: PaymentTransactionTypeEnum): string => {
  switch (paymentMethodId) {
    case PaymentTransactionTypeEnum.CARD:
      return PaymentName.CreditCard
    case PaymentTransactionTypeEnum.ACH:
      return PaymentName.BankTransfer
    case PaymentTransactionTypeEnum.CASH:
      return PaymentName.Cash
    case PaymentTransactionTypeEnum.COINBASE:
      return PaymentName.Cryptocurrency
    default:
      return ''
  }
}

export const formatPaymentStatusForView = (paymentStatusId: PaymentStatusId): PaymentStatus => {
  switch (paymentStatusId) {
    case PaymentStatusId.Completed:
      return PaymentStatus.Paid
    case PaymentStatusId.Processing:
      return PaymentStatus.Processing
    case PaymentStatusId.Pending:
      return PaymentStatus.Pending
  }
}

export const formatWorkflowStepNameForView = (
  workflowStepName: WorkflowStep | null,
  dealList: boolean = false
): string => {
  if (workflowStepName === null) {
    return NavigationStep.Payment
  }

  const navigationStep = WorkflowStepToNavigationStepMap[workflowStepName]

  if (dealList && (workflowStepName === WorkflowStep.Initial || workflowStepName === WorkflowStep.PersonalDetails)) {
    return 'Details'
  }

  return navigationStep ?? NavigationStep.Payment
}

export const formatWorkflowAccountingStateName = (
  workflowAccountingStateId: number | null
): string => {
  switch (workflowAccountingStateId) {
    case DealWorkflowAccountingStateId.Sold:
      return DealWorkflowAccountingStateNames.Sold
    case DealWorkflowAccountingStateId.PushedToAccounting:
      return DealWorkflowAccountingStateNames.PushedToAccounting
    case DealWorkflowAccountingStateId.Recorded:
      return DealWorkflowAccountingStateNames.Recorded
    default:
      return ''
  }
}

export interface Person {
  firstName?: string | null
  lastName?: string | null
}

export const formatFullName = (person: Person | null): string => {
  return joinPartsBySpace(person?.firstName, person?.lastName)
}

export const formatVehicleNameForView = (
  vehicleYear: string | number | null,
  vehicleMake: string | null,
  vehicleModel: string | null,
  vehicleTrim: string | null
): string => {
  return joinPartsBySpace(vehicleYear, vehicleMake, vehicleModel, vehicleTrim)
}

export const formatEntryIfPresent = ({ label, value, labelStyles }: {label: string, value?: unknown, labelStyles?: string}): string => {
  if (typeof value === 'string' || typeof value === 'number') {
    if (labelStyles != null) {
      return `<span style="${labelStyles}">${label}:</span> ${value}\n`
    }
    return `${label} ${value}\n`
  }
  return ''
}
