import type { ColumnDef } from '@carfluent/common/dist/UI'
import type { TransactionLineVendorDto } from 'api/types'

import ValueCell from 'components/common/Table/cells/ValueCell'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import PhoneCell from 'components/common/Table/cells/PhoneCell'
import AddressCell from '../components/cells/AddressCell'

const columnDefinitions: Array<ColumnDef<TransactionLineVendorDto>> = [
  {
    accessorKey: 'name',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Name', isHeader: false }),
    size: 320,
    sortable: false
  },
  {
    accessorKey: 'phoneNumber',
    cell: PhoneCell,
    header: TextCellHOC({ text: 'Phone', isHeader: false }),
    size: 200,
    sortable: false
  },
  {
    id: 'email',
    accessorFn: (row) => row.email != null ? row.email : '',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Email', isHeader: false }),
    size: 300,
    sortable: false
  },
  {
    accessorKey: 'address',
    cell: AddressCell,
    header: TextCellHOC({ text: 'Address', isHeader: false }),
    size: 464,
    sortable: false
  }
]

export default columnDefinitions
