import {
  type FISettingsModel,
  type FISetupMarkupsModel,
  type SelectedTemplateModel
} from 'api/types/responses'

export const DEFAULT_COVERAGE_TEMPLATE = (): SelectedTemplateModel => ({ id: 0, template: null })

export const DEFAULT_MARKUPS = (): FISetupMarkupsModel => [...Array(5)].reduce((_, i: number) => ({
  markupFixed: null,
  markupAmount: null,
  markupPercent: null,
  productTypeId: i + 1
}))

export const DEFAULT_SETTINGS = (): FISettingsModel => ({
  selectedTemplate: DEFAULT_COVERAGE_TEMPLATE(),
  coverageViewTemplates: [],
  packages: [],
  markups: DEFAULT_MARKUPS()
})
