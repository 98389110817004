import { useCallback, useMemo } from 'react'
import { useFormik, FormikConfig } from 'formik'
import { noop } from '@carfluent/common'

import type { KeyVal, Optional } from 'types'
import type { UseFormikReturnType } from 'components/form'
import { isFieldName } from 'utils/general'

export interface MobXFormikConfig<T extends KeyVal> extends FormikConfig<T>{
  setStoreField: (key: keyof T, value: any) => void
  setStoreValues?: (values: Partial<T>) => void
  isFieldId?: (fieldId: any, values: T) => fieldId is keyof T
}

export type RelaxedFormikConfig<T extends KeyVal> = Optional<MobXFormikConfig<T>, 'onSubmit'>

function useMobXFormik<T extends KeyVal = KeyVal> (
  config: RelaxedFormikConfig<T>
): UseFormikReturnType<T> {
  const {
    setStoreField,
    setStoreValues,
    isFieldId = isFieldName,
    onSubmit = noop,
    ...rest
  } = config

  const formik = useFormik({ ...rest, onSubmit })
  const {
    values,
    errors,
    touched,
    isValid,
    setValues,
    setFieldValue
  } = formik

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const handleSetField: UseFormikReturnType<T>['setFieldValue'] =
    useCallback(async (fieldId, value, ...rest) => {
      if (isFieldId(fieldId, config.initialValues)) {
        setStoreField(fieldId, value)
        await setFieldValue(fieldId, value, ...rest)
      }
    }, [setStoreField, setFieldValue, config.initialValues])

  const handleSetValues: UseFormikReturnType<T>['setValues'] =
    useCallback(async (values, shouldValidate) => {
      if (typeof values === 'object') {
        setStoreValues?.(values)
        await setValues(values, shouldValidate)
      }
    }, [setStoreValues, setValues])

  // ========================================== //

  return useMemo(() => ({
    ...formik,
    setFieldValue: handleSetField,
    setValues: handleSetValues
  }), [
    values,
    errors,
    touched,
    isValid,
    handleSetField,
    handleSetValues
  ])
}

export default useMobXFormik

export { isFieldName, isFieldNameCurried } from 'utils/general' // LEGACY
