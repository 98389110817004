import { type ColumnDef } from '@carfluent/common/dist/UI'

import { type CostSummaryListItem } from 'hooks/useCostsSummary'
import { type CostDetailsListItem } from 'hooks/useCostDetails'
import LinkCell from './components/Cells/LinkCell'
import TooltipCell from './components/Cells/TooltipCell'
import AmountCell from 'components/common/Table/cells/AmountCell'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import ValueCell from 'components/common/Table/cells/ValueCell'
import { DateCellHOC } from 'components/common/Table/cells/DateCell'
import DescriptionCell from './components/Cells/DescriptionCell'

export interface CostListItem {
  id: number | string
  type: string
  amount: number
  date: Date | null
}

export enum ColumnsSizes {
  Date = 150,
  Amount = 150,
  Type = 300,
  Description = 230,
  Account = 270
}

export const columnCostsDefinitions: Array<ColumnDef<CostListItem>> = [
  {
    accessorKey: 'date',
    cell: DateCellHOC({ format: 'MM/dd/yyyy' }),
    header: TextCellHOC({ text: 'Date' }),
    sortable: false,
    size: ColumnsSizes.Date
  },
  {
    accessorKey: 'type',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Type' }),
    sortable: false,
    size: ColumnsSizes.Type
  },
  {
    accessorKey: 'amount',
    cell: AmountCell,
    header: TextCellHOC({ text: 'Amount' }),
    sortable: false,
    size: ColumnsSizes.Amount
  }
]

export const columnCostsDetailsDefinitions: Array<ColumnDef<CostDetailsListItem>> = [
  {
    accessorKey: 'date',
    cell: DateCellHOC({ format: 'MM/dd/yyyy' }),
    header: TextCellHOC({ text: 'Date' }),
    sortable: false,
    size: ColumnsSizes.Date
  },
  {
    accessorKey: 'description',
    cell: DescriptionCell,
    header: TextCellHOC({ text: 'Description' }),
    sortable: false,
    size: ColumnsSizes.Description
  },
  {
    accessorKey: 'amount',
    cell: AmountCell,
    header: TextCellHOC({ text: 'Amount' }),
    sortable: false,
    size: ColumnsSizes.Amount
  }
]

export const columnCostsSummaryDefinitions: Array<ColumnDef<CostSummaryListItem>> = [
  {
    accessorKey: 'account',
    cell: LinkCell,
    header: TextCellHOC({ text: 'Account' }),
    sortable: false,
    size: ColumnsSizes.Account
  },
  {
    accessorKey: 'type',
    cell: TooltipCell,
    header: TextCellHOC({ text: 'Type' }),
    sortable: false,
    size: ColumnsSizes.Description
  },
  {
    accessorKey: 'amount',
    cell: AmountCell,
    header: TextCellHOC({ text: 'Amount' }),
    sortable: false,
    size: ColumnsSizes.Amount
  }
]
