import type { FC } from 'react'
import { FormInput } from '@carfluent/common'

import RadioGroup from 'components/common/RadioGroup'
import ActionBar from 'components/crm/ActionBar'
import type {
  UseCompleteTaskFormReturn
} from 'pages/crm/LeadDetailsView/components/LeadDetailsTasks/components/TaskComplete/hook/types'

import { RADIO_OPTIONS } from './constants'

import CLASS_NAME from './styles'

const CreditAppTask: FC<UseCompleteTaskFormReturn> = ({
  isSubmitting,
  values,
  touched,
  errors,
  onChange,
  onSubmit,
  onCloseTask,
  taskMode
}) => {
  return (
    <div className={CLASS_NAME}>
      <div className='radios-title-error'>
        <p className='form-subtitle'>Contact result</p>
        <p className='error-text'>{touched.taskCompleteOptionId && errors.taskCompleteOptionId}</p>
      </div>

      <RadioGroup
        items={RADIO_OPTIONS}
        value={values.taskCompleteOptionId}
        onChange={(_, value: string, id: string): void => {
          onChange(id, value)
        }}
        id='taskCompleteOptionId'
        classes={{ root: 'radio-group', item: 'radio-item' }}
      />

      <FormInput
        value={values.workNotes}
        onChange={onChange}
        id='workNotes'
        className='notes-field'
        label='Work notes'
      />

      <div className='lead-complete-task-action-buttons-container'>
        <ActionBar
          buttonsClassName='lead-tasks-action-buttons'
          taskMode={taskMode}
          onSubmit={onSubmit}
          onCancel={() => onCloseTask(null)}
          isLoading={isSubmitting}
        />
      </div>
    </div>
  )
}

export default CreditAppTask
