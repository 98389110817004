import negate from 'lodash-es/negate'

import { isEmptyString } from 'utils/validation'
import { isFalsy, keys } from 'utils/general'
import { type TransactionLineRow } from 'types'

import { DEFAULT_ROW } from './constants'

export const isEmptyRow = (item: TransactionLineRow): boolean => {
  return (item.account === null) &&
    (item.control === null) &&
    (item.entity === null) &&
    isEmptyString(item.notes) &&
    isFalsy(item.credits) &&
    isFalsy(item.debits)
}

export const isNotEmptyRow = negate(isEmptyRow)

export const isDefaultLine = (line: TransactionLineRow): boolean => {
  for (const key of keys(DEFAULT_ROW)) {
    if (line[key] !== DEFAULT_ROW[key]) {
      return false
    }
  }

  return true
}
