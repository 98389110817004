import { css } from '@emotion/css'

export default css(`
  .deal-status {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
  }
  
  .deal-pending, .deal-canceled {
    color: #FFFFFF;
    padding: 2px 9px;
    font-size: 14px;
    min-width: 92px;
    background-color: #FFA451;
    border-radius: 4px;
    text-align: center;
    font-family: Roboto, sans-serif;
    line-height: 20px;
    letter-spacing: 0.25px;  
  }

  .deal-canceled {
    background-color: rgba(33, 33, 33, 0.08);
    color: rgba(33, 33, 33, 0.40);
  }

  .deposited-label {
    max-width: 92px;
    height: 24px;
  }
`)
