import type { SortingInfo } from '@carfluent/common'

export const API_CALL_DELAY_SEARCH = 3000

export enum Messages {
  EmptyTableState = 'No records yet',
  NothingFoundState = 'No results found',
}

export const DEFAULT_SORTING: SortingInfo = { id: 'name', order: 'asc' }
