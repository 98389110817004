import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGArrowRight: FC<IconProps> = ({
  width = 24,
  height = 24,
  color = '#101010'
}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'>
      <path fill-rule='evenodd' clip-rule='evenodd' d='M10.0001 6L8.59009 7.41L13.1701 12L8.59009 16.59L10.0001 18L16.0001 12L10.0001 6Z' fill={color} />
    </svg>
  )
}

export default IconSVGArrowRight
