import { useState, useCallback, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { type Result, getEnhancedResultHandler, isOk, useForm } from '@carfluent/common'

import type { KeyVal } from 'types'
import { Routes } from 'constants/route_helper'
import DocumentsApiProvider from 'api/documents.api'
import serializeAddNewFormPackData from 'components/dialogs/NewFormPack/hook/serializer'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

import type { FormPacksFormData, UseNewFormPackModalProps, UseNewFormPackModalReturn } from './types'
import { GET_DEFAULT_CREATE_NEW_FORM_PACK, Messages } from './constants'
import validationRules from './validation'

const DEFAULT_CREATE_NEW_FORM_PACK = GET_DEFAULT_CREATE_NEW_FORM_PACK()

const useNewFormPack = ({ onClose: _onClose }: UseNewFormPackModalProps): UseNewFormPackModalReturn => {
  const { showAlert } = useCustomSnackbar()
  const navigate = useNavigate()

  const [apiErrors, setAPIErrors] = useState<KeyVal | null>(null)

  const resetFormState = useCallback((resetForm: () => void): void => {
    resetForm()

    setAPIErrors(null)
  }, [])

  const onActionResult = useMemo(() => {
    const action = (res: Result<string>, resetForm: () => void): void => {
      if (isOk<string>(res)) {
        _onClose?.()
        resetFormState(resetForm)
        navigate(`${Routes.SettingsFormPacks}/${res.result}`)
      } else if (axios.isAxiosError(res.result)) {
        setAPIErrors(res.result?.response?.data ?? null)
      }
    }

    return getEnhancedResultHandler<FormPacksFormData, never, string>(action, showAlert, undefined, Messages.SuccessMessage)
  }, [navigate, showAlert, resetFormState, _onClose])

  const submitAction = useCallback(async (values: FormPacksFormData): Promise<string | null> => {
    const payload = serializeAddNewFormPackData(values)
    const { id } = await DocumentsApiProvider.postFormPack(payload)
    return id.toString()
  }, [])

  const {
    values,
    errors,
    touched,
    isSubmitting,
    onBlur,
    onSubmit,
    onChange,
    resetForm,
    setFieldError
  } = useForm<FormPacksFormData, never>({
    baseValues: DEFAULT_CREATE_NEW_FORM_PACK,
    onActionResult,
    submitAction,
    validationRules
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onClose = (): void => {
    if (_onClose != null) {
      _onClose()
      resetForm()
      setAPIErrors(null)
    }
  }
  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (apiErrors != null) {
      setFieldError('name', apiErrors.message)
    }
  }, [apiErrors, setFieldError])

  // ========================================== //

  return {
    isSubmitting,
    values,
    errors,
    touched,
    onBlur,
    onClose,
    onChange,
    onSubmit
  }
}

export default useNewFormPack
