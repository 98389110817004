import { useCallback, useState } from 'react'
import { serializers as S } from '@carfluent/common'

import AccountingApiProvider from 'api/accounting.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { downloadBlob } from 'utils/general'
import {
  type DateType,
  type ReportAccountsAgingDto,
  type ReportAccountsAgingTotalsDto,
  type PaginatedResult,
  type ReportAccountsAgingRecordDto
} from 'api/types'
import useTableApi from 'hooks/useTableApi'

import {
  getPresets,
  getReportFileNames,
  getReportTitle,
  PRESET_END_OF_LAST_MONTH,
  API_CALL_DELAY_SEARCH,
  Messages
} from './constants'

import {
  AccountAgingReportTypes,
  type UseReportAccountsAgingProps,
  type UseReportAccountsAgingReturn,
  type AccountAgingReportFilters
} from './types'

const PRESETS = getPresets()
const INITIAL_PRESET = PRESETS.find(el => el.name === PRESET_END_OF_LAST_MONTH) ?? null
const DEFAULT_PERIOD = INITIAL_PRESET != null
  ? {
      from: INITIAL_PRESET.startDate,
      to: INITIAL_PRESET.endDate
    }
  : null

const useReportAccountsReceivable = ({ schedulesType }: UseReportAccountsAgingProps): UseReportAccountsAgingReturn => {
  const { showAlert } = useCustomSnackbar()

  const [totals, setTotals] = useState<ReportAccountsAgingTotalsDto | null>(null)
  const [originalReportData, setOriginalReportData] = useState<ReportAccountsAgingDto | null>(null)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const isReceivable = schedulesType === AccountAgingReportTypes.Receivable

  const getList = useCallback(async (payload): Promise<PaginatedResult<ReportAccountsAgingRecordDto>> => {
    const { search, period } = payload
    const date = S.serializeDate(period?.to)
    if (date == null) {
      return { items: [], count: 0 }
    }

    try {
      const request = isReceivable
        ? AccountingApiProvider.getReportAccountsAgingReceivable
        : AccountingApiProvider.getReportAccountsAgingPayable

      const response = await request({ date, search })
      const { records, totals } = response

      setOriginalReportData(response)
      setTotals(totals)
      return { items: records, count: records.length }
    } catch (err) {
      showAlert(err)
      return { items: [], count: 0 }
    }
  }, [showAlert, isReceivable])

  const {
    rows,
    search,
    onSearch,
    isLoading,
    setSearch,
    setFilters
  } = useTableApi<ReportAccountsAgingRecordDto, AccountAgingReportFilters>({
    shouldRunOnCall: true,
    defaultFilters: { period: DEFAULT_PERIOD },
    getList,
    apiCallDelay: API_CALL_DELAY_SEARCH,
    emptyTableMessage: Messages.emptyTableState,
    nothingFoundMessage: Messages.nothingFoundState
  })

  const onChangeDatesFilter = useCallback(async (period: DateType) => {
    void setFilters({ period })
  }, [setFilters])

  const onExportExcel = useCallback(async (): Promise<void> => {
    if (originalReportData == null) {
      return
    }

    try {
      const request = isReceivable
        ? AccountingApiProvider.downloadReportAccountsAgingReceivableXlsx
        : AccountingApiProvider.downloadReportAccountsAgingPayableXlsx

      const res = await request(originalReportData)
      const fileNames = getReportFileNames(schedulesType)
      downloadBlob(res, fileNames.xlsx)
    } catch (err) {
      showAlert(err)
    }
  }, [originalReportData, isReceivable, schedulesType, showAlert])

  return {
    rows,
    initialPresetName: INITIAL_PRESET?.name ?? PRESET_END_OF_LAST_MONTH,
    isLoading,
    onChangeDatesFilter,
    onExportExcel,
    presets: PRESETS,
    title: getReportTitle(schedulesType),
    totals,
    search,
    isReceivable,
    onSearchChange: setSearch,
    onSearch
  }
}

export default useReportAccountsReceivable
