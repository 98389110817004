import { css } from '@emotion/css'

export default css(`
  .cf-input-container {
    .cf-end-adornment span {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #101010;
      cursor: pointer;
      user-select: none;
      text-decoration: underline;
    }

    &.disabled .cf-end-adornment span {
      color: rgba(0, 0, 0, 0.38);
      cursor: default;
    }
  }
`)
