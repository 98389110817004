import { WrapperRequest } from './wrapper.api'

import { getRefreshToken, setAccessToken, setRefreshToken } from 'services/storage.service'
import { bodyRefresh } from 'constants/store_helpers'
import type {
  ResetPassword,
  SendResetPasswordEmail,
  TokenResponse,
  User,
  DealerAccountInvites,
  DealerInvitesPayload,
  DealerInvite,
  CreateDealerAccountPayload,
  UpdateDealerAccountPayload,
  ListResponse,
  DictionaryItem,
  ListPayload
} from 'api/types'

const PAGE_SIZE = 500

class IdentityAPI extends WrapperRequest {
  identityUrl = process.env.REACT_APP_IDENTITY ?? ''

  loginUser = async (data: string): Promise<TokenResponse> => {
    return await this.post(`${this.identityUrl}/connect/token`, {
      data,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
  }

  refreshAccessToken = async (): Promise<TokenResponse> => {
    const res = await this.post(`${this.identityUrl}/connect/token`, {
      data: bodyRefresh(getRefreshToken()),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    setAccessToken(res.access_token)
    setRefreshToken(res.refresh_token)

    return res
  }

  sendResetPasswordEmail = async (data: SendResetPasswordEmail): Promise<void> => {
    return await this.post(`${this.identityUrl}/api/v1/account/sendReset`, data)
  }

  resetPassword = async (data: ResetPassword): Promise<void> => {
    return await this.post(`${this.identityUrl}/api/v1/account/resetPassword`, data)
  }

  getUsers = async (roleIds: number[], otherPayload: Partial<ListPayload> = {}): Promise<ListResponse<User>> => {
    return await this.post(`${this.identityUrl}/api/v1/Users/list`, { take: PAGE_SIZE, roleIds, ...otherPayload })
  }

  // ========================================== //
  //               Dealer account               //
  // ========================================== //

  getDealerAccountInvites = async (): Promise<DealerAccountInvites> => {
    return await this.get(`${this.identityUrl}/api/v1/DealerAccount`)
  }

  getInvite = async (token: string): Promise<DealerInvite> => {
    return await this.get(`${this.identityUrl}/api/v1/DealerAccount/invite?token=${token}`)
  }

  postDealerAccount = async (payload: CreateDealerAccountPayload): Promise<void> => {
    return await this.post(`${this.identityUrl}/api/v1/DealerAccount/account`, payload)
  }

  postDealerInvites = async (payload: DealerInvitesPayload): Promise<DealerInvite[]> => {
    return await this.post(`${this.identityUrl}/api/v1/DealerAccount/invite`, payload)
  }

  deleteDealerAccount = async (userId: number): Promise<{}> => {
    return await this.delete(`${this.identityUrl}/api/v1/DealerAccount/account/${userId}`)
  }

  deleteDealerInvite = async (inviteId: number): Promise<{}> => {
    return await this.delete(`${this.identityUrl}/api/v1/DealerAccount/invite/${inviteId}`)
  }

  updateDealerAccount = async (id: number, payload: UpdateDealerAccountPayload): Promise<{}> => {
    return await this.patch(`${this.identityUrl}/api/v1/Users/${id}`, payload)
  }

  getAvailableDealers = async (): Promise<ListResponse<DictionaryItem>> => {
    return await this.get(`${this.identityUrl}/api/v1/Dealer/available`)
  }
}

const IdentityApiProvider = new IdentityAPI()

export default IdentityApiProvider
