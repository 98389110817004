import type { ReportLeadsByVehiclesItem } from 'api/types'
import type { ReportLeadsByVehicleRowData } from './types'

const parseListData = (data: ReportLeadsByVehiclesItem[]): ReportLeadsByVehicleRowData[] => {
  return data.map(parseRowData)
}

const parseRowData = ({ vehicle, interestedLeadsAmount, interestedLeadsAmountPerSource }: ReportLeadsByVehiclesItem): ReportLeadsByVehicleRowData => {
  return {
    make: {
      make: vehicle.make,
      path: `/vehicles/${vehicle.id}`,
      title: `${vehicle.year} ${vehicle.make} ${vehicle.model}`
    },
    id: vehicle.id,
    age: vehicle.age,
    stock: vehicle.stock,
    interestedLeadsAmount,
    ...interestedLeadsAmountPerSource
  }
}

export default parseListData
