import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'

import { WorkPlanEventDto } from 'api/types'

interface InfoCellProps {
  getValue: () => WorkPlanEventDto
  className?: string
}

const InfoCell: FC<InfoCellProps> = ({ getValue, className }) => {
  const { executionDay } = getValue?.()
  return (
    <CellWrapper className={className}>
      Day {executionDay ?? 0}
    </CellWrapper>
  )
}

export default InfoCell
