import { css } from '@emotion/css'

export default css(`
  width: 100%;

  .cf-content-wrapper {
    max-width: 544px;
    margin: 214px auto auto auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .cf-content-title {
    font-weight: 700;
    font-size: 44px;
    line-height: 56px;
    color: #101010;
    margin-top: 60px;
    text-align: center;
  }

  .cf-content-text {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #101010;
    margin-top: 20px;
  }
`)
