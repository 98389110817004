import { useEffect, useRef, useState, type FC, type ReactNode } from 'react'
import { Collapse } from '@material-ui/core'
import { cnx } from '@carfluent/common'
import { type RenderCollapseBtnFunc, renderAddRemoveBtn } from './renderers'
import CLASS_NAME from './styles'

export {
  renderAddRemoveBtn
}

// DD-TODO: make decent NoMUI Collapse and move to Common Lib
export interface CollapseProps {
  children: ReactNode
  className?: string
  isDisabled?: boolean
  isExpanded: boolean
  title?: string | ReactNode
  timeout?: number
  getBtnText?: (isExpanded: boolean) => string
  toggleIsExpanded?: () => void
  renderCollapseBtn?: RenderCollapseBtnFunc
}

// 0 delay not always scheduled correctly because of how browsers handle small timeouts
// const animate = (clb: () => void, delay = 10): void => {
//   setTimeout(() => {
//     window.requestAnimationFrame(() => {
//       clb()
//     })
//   }, delay)
// }

const CollapseFutureNoMui: FC<CollapseProps> = ({
  children,
  className,
  isDisabled = false,
  isExpanded: _isExpanded = false,
  title,
  timeout = 300,
  getBtnText,
  renderCollapseBtn,
  toggleIsExpanded
}) => {
  /**
   * it is needed to control last height before collapse for smooth close animation
   * and therefore we need to duplicated outer isExpanded into local state.
   */
  const [isExpanded, setIsExpanded] = useState(_isExpanded)

  const refSection = useRef<HTMLDivElement | null>(null)
  // const refCollapseTracker = useRef(0)
  const refLastHeight = useRef<number>(0)
  // const refTransitionEndHandler = useRef(
  //   (event: TransitionEvent): void => {
  //     if (event.propertyName !== 'height') {
  //       return
  //     }

  //     const el = refSection.current

  //     if (el == null) {
  //       return
  //     }

  //     if (refCollapseTracker.current >= 0) {
  //       el.style.height = 'auto'
  //     }
  //   }
  // )

  // useEffect(() => {
  //   const el = refSection.current

  //   if (el == null) {
  //     return
  //   }

  //   el.addEventListener('transitionend', refTransitionEndHandler.current)
  //   return () => {
  //     el.removeEventListener('transitionend', refTransitionEndHandler.current)
  //   }
  // }, [])

  useEffect(() => {
    setIsExpanded(_isExpanded)
    refLastHeight.current = refSection.current?.scrollHeight ?? 0
  }, [_isExpanded])

  // useEffect(() => {
  //   const el = refSection.current

  //   if (el == null) {
  //     return
  //   }

  //   if (isExpanded) {
  //     refCollapseTracker.current += 1

  //     if (el.scrollHeight > 0) {
  //       animate(() => { el.style.height = `${el.scrollHeight}px` })
  //     }
  //   } else {
  //     refCollapseTracker.current -= 1

  //     // Set the height to its current pixel height before collapsing to make animation work
  //     el.style.height = `${refLastHeight.current}px`

  //     animate(() => { el.style.height = '0px' })
  //   }
  // }, [isExpanded, title])

  return (
    <div className={cnx('cf-collapse', CLASS_NAME, className, isExpanded && 'is-expanded')}>
      <div className='cf-collapse-header'>
        {
          typeof title === 'string'
            ? <h3>{title}</h3>
            : title
        }
        {/* TODO: add more appropriate default renderer according to designs */}
        {renderCollapseBtn?.(isExpanded, isDisabled, toggleIsExpanded, getBtnText)}
      </div>

      <Collapse in={isExpanded} timeout={timeout}>
        <div
          ref={refSection}
          className='cf-collapse-section'
        >
          {children}
        </div>
      </Collapse>
    </div>
  )
}

export default CollapseFutureNoMui
