import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .basic-skeleton {
    width: clamp(80px, 70%, 110px);
    height: 24px;
  }
`)
