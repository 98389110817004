import {
  type FC,
  type ChangeEvent,
  useMemo,
  useRef,
  useState
} from 'react'
import { cnx } from '@carfluent/common'

import ActionsMenu, { type MenuButtonProps } from 'components/common/ActionsMenu'
import { TOOLTIPS_CONFIG } from 'components/common/Messenger/components/TextAndFilesArea/constants'
import Popover from 'components/common/Popover'
import { DEFAULT_FILE_MAX_SIZE, DEFAULT_MAX_FILES_NUM, DEFAULT_MAX_TOTAL_FILE_SIZE } from 'constants/files'
import { filterFiles, type FileFilterData } from 'utils/fileFilter'

import IconSVG from 'components/inlineImages'
import { POPOVER_CLASS_NAME } from 'components/common/Messenger/components/TextAndFilesArea/styles'
import CLASS_NAME from './styles'

interface AttachMediaMenuProps {
  className?: string
  acceptedExtensions?: string[]
  isDisabled: boolean
  maxFileSize?: number
  maxFilesTotalSize?: number
  maxFileNumber?: number
  attachmentsLength?: number
  onOpenAttachPhotosModal: () => void
  onAddFiles: (data: FileFilterData) => void
  isAddingAttachmentsDisabled?: boolean
}

const AttachMediaMenuButton: FC<MenuButtonProps> = ({ onClick, isDisabled }) => {
  const isMenuButtonEnabled = isDisabled === false

  return (
    <div
      onClick={(evt) => isMenuButtonEnabled && onClick(evt)}
      className='cf-icon-container'
    >
      <IconSVG.Clip color={isMenuButtonEnabled ? '#000000' : '#00000030'} />
    </div>
  )
}

const AttachMediaMenu: FC<AttachMediaMenuProps> = ({
  isDisabled,
  acceptedExtensions,
  maxFileSize = DEFAULT_FILE_MAX_SIZE,
  maxFilesTotalSize = DEFAULT_MAX_TOTAL_FILE_SIZE,
  maxFileNumber = DEFAULT_MAX_FILES_NUM,
  attachmentsLength = 0,
  onOpenAttachPhotosModal,
  onAddFiles,
  isAddingAttachmentsDisabled
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [addAttachmentsAnchorEl, setAddAttachmentsAnchorEl] = useState<HTMLElement | null>(null)
  const [isPopoverCanBeOpen, setPopoverClosed] = useState<boolean>(true)

  const onActionsMenuOpen = (): void => {
    setPopoverClosed(false)
    setAddAttachmentsAnchorEl(prevEl => prevEl ?? null)
  }

  const onActionsMenuClose = (): void => {
    setPopoverClosed(true)
    setAddAttachmentsAnchorEl(null)
  }

  const onFilesSelected = (e: ChangeEvent<HTMLInputElement>): void => {
    const res = filterFiles(
      Array.from(e.target.files ?? []),
      maxFileSize,
      maxFilesTotalSize,
      maxFileNumber,
      acceptedExtensions,
      attachmentsLength
    )

    onAddFiles(res)

    if (fileInputRef.current != null) {
      fileInputRef.current.value = ''
    }
  }

  const actionList = useMemo(() => ([
    {
      title: 'Inventory media',
      icon: <IconSVG.Image />,
      handleOnClick: onOpenAttachPhotosModal
    },
    {
      title: 'Photos from computer',
      icon: <IconSVG.UploadFile />,
      handleOnClick: () => fileInputRef.current?.click(),
      disabled: isAddingAttachmentsDisabled
    }
  ]), [onOpenAttachPhotosModal, isAddingAttachmentsDisabled])

  return (
    <>
      <Popover
        open={addAttachmentsAnchorEl != null && isPopoverCanBeOpen && !isDisabled}
        anchorEl={addAttachmentsAnchorEl}
        className={cnx(POPOVER_CLASS_NAME, 'cf-popover-container')}
        {...TOOLTIPS_CONFIG.addAttachmentsBtn}
      >
        Attach media
      </Popover>
      <div className={cnx(CLASS_NAME, 'clip-icon', isDisabled && 'disabled')}>
        <div
          onMouseEnter={({ currentTarget }) => setAddAttachmentsAnchorEl(currentTarget)}
          onMouseLeave={() => setAddAttachmentsAnchorEl(null)}
        >
          <ActionsMenu
            className='cf-attach-media-menu'
            MenuButtonComponent={AttachMediaMenuButton}
            actions={actionList}
            isDisabled={isDisabled}
            onMenuOpen={onActionsMenuOpen}
            onMenuClose={onActionsMenuClose}
          />
        </div>

        <input
          type='file'
          multiple
          style={{ display: 'none' }}
          ref={fileInputRef}
          accept={acceptedExtensions?.map(v => `.${v}`).join(', ')}
          onChange={onFilesSelected}
        />
      </div>
    </>
  )
}

export default AttachMediaMenu
