import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  wrapper: {
    '& .MuiFormLabel-root': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: 'calc(100% - 40px)'
    },
    '& .MuiInputLabel-shrink': {
      maxWidth: '100%'
    }
  }
})

export default useStyles
