import { type CoverageProductTypesDto, type DealerProviderCoverageDto } from 'api/types/responses'
import type { CoverageProducts, CoverageWithTerms } from 'types/coverage'

export function mergeProductPackages (products: CoverageProductTypesDto[], providers: DealerProviderCoverageDto[]): CoverageProducts {
  const combined: CoverageProducts = {}

  products.forEach(product => {
    combined[product.id] = providers.map(provider => {
      const coverageMap: { [key: string]: CoverageWithTerms } = {}

      provider.coverages
        .filter(coverage => coverage.productTypeId === product.id)
        .forEach(coverage => {
          if (coverageMap[coverage.coverageName] === undefined) {
            coverageMap[coverage.coverageName] = {
              coverageName: coverage.coverageName,
              termList: []
            }
          }
          coverageMap[coverage.coverageName].termList.push({
            id: coverage.id,
            termMiles: coverage.termMiles,
            termMonths: coverage.termMonths,
            deductibles: []
          })
        })

      const providerCoverages = Object.values(coverageMap)

      return {
        id: provider.id,
        name: provider.name,
        coverages: providerCoverages
      }
    })
  })

  return combined
}
