import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'
import DealPaidState from 'components/common/DealPaidState'

import CLASS_NAME from './styles'

export interface ActionCellProps {
  getValue: () => number
}

export interface DealStatus {
  paidId: number
}

const StateCell: FC<ActionCellProps> = ({ getValue }) => {
  const paidId = getValue()

  return (
    <CellWrapper className={CLASS_NAME}>
      <DealPaidState state={paidId} />
    </CellWrapper>
  )
}

export default StateCell
