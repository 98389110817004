import type { FC } from 'react'

const CopyIcon: FC = () => {
  return (
    <svg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8.99967 0.666992H1.66634C0.933008 0.666992 0.333008 1.26699 0.333008 2.00033V10.667C0.333008 11.0337 0.633008 11.3337 0.999674 11.3337C1.36634 11.3337 1.66634 11.0337 1.66634 10.667V2.66699C1.66634 2.30033 1.96634 2.00033 2.33301 2.00033H8.99967C9.36634 2.00033 9.66634 1.70033 9.66634 1.33366C9.66634 0.966992 9.36634 0.666992 8.99967 0.666992ZM11.6663 3.33366H4.33301C3.59967 3.33366 2.99967 3.93366 2.99967 4.66699V14.0003C2.99967 14.7337 3.59967 15.3337 4.33301 15.3337H11.6663C12.3997 15.3337 12.9997 14.7337 12.9997 14.0003V4.66699C12.9997 3.93366 12.3997 3.33366 11.6663 3.33366ZM10.9997 14.0003H4.99967C4.63301 14.0003 4.33301 13.7003 4.33301 13.3337V5.33366C4.33301 4.96699 4.63301 4.66699 4.99967 4.66699H10.9997C11.3663 4.66699 11.6663 4.96699 11.6663 5.33366V13.3337C11.6663 13.7003 11.3663 14.0003 10.9997 14.0003Z' fill='black' />
    </svg>
  )
}

export default CopyIcon
