export const getButtonText = (
  name: string, formatter: (v: number | string) => string,
  _from: number | null, _to: number | null, _exact?: number | null
): string => {
  if (_exact != null) {
    const exact = formatter(_exact ?? 0)
    return `${name}: ${exact}`
  }

  if (_from == null && _to == null) {
    return name
  }

  const from = formatter(_from ?? 0)
  const to = formatter(_to ?? 0)

  let rangeStr = ''

  if (_from == null) {
    rangeStr = `up to ${to}`
  } else if (_to == null) {
    rangeStr = `from ${from}`
  } else {
    rangeStr = from === to ? from : `${from} - ${to}`
  }

  return `${name}: ${rangeStr}`
}
