import { type FC } from 'react'
import { type IconProps } from 'types'

const IconSVGBell: FC<IconProps> = ({
  width = 16,
  height = 16,
  color = '#fff',
  opacity = 1
}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_93709_43549)'>
        <path
          d='M8.0013 14.668C8.73463 14.668 9.33463 14.068 9.33463 13.3346H6.66797C6.66797 14.068 7.26797 14.668 8.0013 14.668ZM12.0013 10.668V7.33464C12.0013 5.28797 10.9146 3.57464 9.0013 3.1213V2.66797C9.0013 2.11464 8.55464 1.66797 8.0013 1.66797C7.44797 1.66797 7.0013 2.11464 7.0013 2.66797V3.1213C5.09464 3.57464 4.0013 5.2813 4.0013 7.33464V10.668L2.66797 12.0013V12.668H13.3346V12.0013L12.0013 10.668ZM10.668 11.3346H5.33464V7.33464C5.33464 5.6813 6.3413 4.33464 8.0013 4.33464C9.6613 4.33464 10.668 5.6813 10.668 7.33464V11.3346Z'
          fill={color}
          fillOpacity={opacity}
        />
      </g>
      <defs>
        <clipPath id='clip0_93709_43549'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGBell
