import { css } from '@emotion/css'

export default css(`
  & .MuiFormGroup-root {
    flex-direction: column;
    align-items: baseline;
  }

  .radio-btn-in-row .MuiFormGroup-root  {
    flex-direction: row;
  }

  .g-horizontal-line {
    margin-top: 0;
  }
`)
