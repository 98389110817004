import { type FC } from 'react'

import type { DictionaryItem, AssignedUser } from 'api/types'
import { TaskMode } from 'pages/crm/LeadDetailsView/hook/types'

import NameWithAvatar from 'components/common/NameWithAvatar'
import PriorityIcon from 'components/common/Table/cells/TaskPriorityCell/PriorityIcons'

import ActionBar from '../ActionBar'

export interface CreatedCompleteLayoutProps {
  dueTo?: string | null
  assignedTo: AssignedUser | null
  priority: DictionaryItem | null
  description?: string | null
  showActionBar?: boolean
  onSubmit?: () => void
  onCancel?: () => void
  cancelButtonTitle?: string
  isShowCancelButton?: boolean
}

const CreatedCompleteLayout: FC<CreatedCompleteLayoutProps> = ({
  priority,
  dueTo,
  assignedTo,
  description,
  showActionBar = true,
  onSubmit,
  onCancel,
  cancelButtonTitle,
  isShowCancelButton
}) => {
  const isDescription = description != null && description !== ''
  const fullName = assignedTo?.firstName != null && assignedTo?.lastName != null
    ? `${assignedTo.firstName} ${assignedTo.lastName}`
    : 'Unassigned'

  return (
    <>
      <div className={`created-task disabled ${isDescription ? '' : 'no-description'}`}>

        <div className='lead-tasks-result-block task-dueToDate'>
          <p className='label'>Due date</p>
          <p className='due-to'>{dueTo}</p>
        </div>

        <div className='lead-tasks-result-block task-assignedTo'>
          <p className='label'>Assigned to</p>
          <NameWithAvatar showAvatar name={fullName} />
        </div>

        <div className='lead-tasks-result-block task-priority'>
          <p className='label'>Priority</p>
          <div className='priority-value'>
            <PriorityIcon id={priority?.id} />
            <p>{priority?.name}</p>
          </div>
        </div>

        {isDescription && (
          <div className='lead-tasks-result-block task-description'>
            <p className='label'>Description</p>
            <p>{description}</p>
          </div>)}

        {showActionBar && onSubmit != null && (
          <ActionBar
            buttonsClassName='lead-tasks-action-buttons'
            taskMode={TaskMode.ViewTask}
            onSubmit={onSubmit}
            onCancel={onCancel}
            cancelButtonTitle={cancelButtonTitle}
            isShowCancelButton={isShowCancelButton}
            isLoading={false}
          />
        )}
      </div>
    </>
  )
}

export default CreatedCompleteLayout
