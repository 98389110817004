import { type FC, useCallback } from 'react'
import { FormInput, FormDropdown } from '@carfluent/common'

import type { DictionaryItem } from 'api/types'
import ActionBar from 'components/crm/ActionBar'
import type { UseCompleteTaskFormReturn } from 'pages/crm/LeadDetailsView/components/LeadDetailsTasks/components/TaskComplete/hook/types'
import { FieldIds } from 'pages/crm/LeadDetailsView/components/LeadDetailsTasks/components/TaskComplete/hook/constants'

import CLASS_NAME from './styles'

const CompleteReminderTaskForm: FC<UseCompleteTaskFormReturn> = ({
  taskMode,
  onCloseTask,
  reminderSubjects,
  isSubmitting,
  values,
  errors,
  touched,
  onBlur,
  onChange,
  onSubmit
}) => {
  const onCancel = useCallback((): void => {
    onCloseTask(null)
  }, [onCloseTask])

  return (
    <div className={CLASS_NAME}>
      <p className='form-subtitle'>Reminder</p>

      <div className='form'>
        <FormDropdown<DictionaryItem>
          className='subject-field'
          id={FieldIds.Subject}
          options={reminderSubjects}
          label='Subject'
          mode='select'
          blurMode='select-first'
          disableClearable
          value={values[FieldIds.Subject]}
          error={errors[FieldIds.Subject]}
          touched={touched[FieldIds.Subject]}
          onChange={onChange}
          onBlur={onBlur}
          dataTestId='lead-complete-task-field-subject'
        />

        <FormInput
          value={values.workNotes}
          onChange={onChange}
          id={FieldIds.Notes}
          label='Work notes'
          className='notes-field'
        />
      </div>

      <div className='lead-complete-task-action-buttons-container'>
        <ActionBar
          buttonsClassName='lead-tasks-action-buttons'
          taskMode={taskMode}
          onSubmit={onSubmit}
          onCancel={onCancel}
          isLoading={isSubmitting}
        />
      </div>
    </div>
  )
}

export default CompleteReminderTaskForm
