import ValueCell from 'components/common/Table/cells/ValueCell'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import PhoneCell from 'components/common/Table/cells/PhoneCell'
import { joinPartsToStr, joinPartsBySpace } from 'utils/view_helper'

import type { ColumnDefinitions } from './types'

const columnDefinitions: ColumnDefinitions = [
  {
    accessorKey: 'name',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Name' }),
    maxSize: 340,
    minSize: 300,
    sortable: false
  },
  {
    accessorKey: 'phone',
    cell: PhoneCell,
    header: TextCellHOC({ text: 'Phone' }),
    maxSize: 200,
    minSize: 180,
    sortable: false
  },
  {
    accessorKey: 'email',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Email' }),
    maxSize: 300,
    minSize: 180,
    sortable: false
  },
  {
    id: 'address',
    accessorFn: (lienholder) => {
      return joinPartsToStr(', ', lienholder.address, joinPartsBySpace(lienholder.city, lienholder.state), lienholder.zipCode)
    },
    cell: ValueCell,
    header: TextCellHOC({ text: 'Address' }),
    maxSize: 444,
    minSize: 300,
    sortable: false
  }
]

export default columnDefinitions
