
import { FC, useCallback, useRef } from 'react'

import getColorForString from 'utils/getColorForString'

import {
  AVATAR_COLORS,
  DEFAULT_AVATAR_COLOR
} from 'constants/constants'

import type { CalendarChipProps } from 'components/crm/Chips/types'
import { displayHours, getCoords } from 'components/crm/Chips/helpers'
import Chip from 'components/crm/Chips'

import createStyleClass from './styles'

const CalendarChip: FC<CalendarChipProps> = ({
  shift,
  isEdit,
  onClick,
  changeChipPosition
}) => {
  const { userName, startDate, endDate } = shift

  const shiftInfo = displayHours(startDate, endDate)
  const shiftColorStyle = {
    backgroundColor: getColorForString(userName, AVATAR_COLORS, DEFAULT_AVATAR_COLOR)
  }

  const elementRef = useRef(null)

  const onChipClick = useCallback(() => {
    onClick(shift)
    changeChipPosition(getCoords(elementRef.current as unknown as HTMLElement))
  }, [onClick, shift, changeChipPosition])

  const CLASS_NAME = createStyleClass({ bgColor: shiftColorStyle.backgroundColor, isEdit })

  return (
    <div className={CLASS_NAME} onClick={onChipClick} ref={elementRef}>
      <Chip
        shiftColorStyle={shiftColorStyle}
        userName={userName}
        shiftInfo={shiftInfo}
      />
      <p className='cf-shift-hours'>{shiftInfo}</p>
    </div>
  )
}

export default CalendarChip
