import { useCallback, useMemo, useState, useRef } from 'react'
import { useLoader, useModal, useCommand } from '@carfluent/common'

import { type OpenBalancesListWithStatistics, TransactionTypeId } from 'api/types'
import { type FiltersChangePayload, type ReconciliationId } from 'components/accounting/OpenBalancesTable'
import { TransactionActionNames } from 'constants/constants'

import { TabIds } from './constants'
import type { UseSchedulesDashboardReturn } from './types'
export { TabIds, TABS } from './constants'

const useSchedulesDashboard = (): UseSchedulesDashboardReturn => {
  const [selectedTabIdx, setSelectedTabIdx] = useState<number>(TabIds.Payables)
  const [selectedTransactionId, setSelectedTransactionId] = useState<number | null>(null)
  const [selectedTransactionTypeId, setTransactionTypeId] = useState<number>(TransactionTypeId.JournalEntry)
  const [checkedOpenBalances, setCheckedOpenBalances] = useState<Set<ReconciliationId>>(new Set())
  const [openBalancesFilters, setOpenBalancesFilters] = useState<FiltersChangePayload | null>(null)

  /**
   * AZ-TODO: consider to add sub-hook `useTabState`, if we will need 3 and more tabs.
   */
  const cmdPayablesReset = useCommand()
  const cmdReceivablesReset = useCommand()
  const loaderPayables = useLoader()
  const loaderReceivables = useLoader()

  const transactionDetailsModal = useModal()
  const refSelectedTab = useRef(selectedTabIdx)

  const [counters, setCounters] = useState({
    [TabIds.Payables]: 0,
    [TabIds.Receivables]: 0
  })

  const refResetCommands = useRef(new Map([
    [TabIds.Payables, cmdPayablesReset],
    [TabIds.Receivables, cmdReceivablesReset]
  ]))

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onResetOpenBalancesState = useCallback(() => {
    setCheckedOpenBalances(new Set())
    setOpenBalancesFilters(null)
  }, [])

  const onViewBalanceTransaction = useCallback((transactionId: number, transactionTypeId: number) => {
    transactionDetailsModal.onCloseModal()
    setSelectedTransactionId(transactionId)
    setTransactionTypeId(transactionTypeId)
    setTimeout(transactionDetailsModal.onOpenModal, 0) // prevents set state race condition
  }, [
    transactionDetailsModal.onCloseModal,
    transactionDetailsModal.onOpenModal
  ])

  const onCreateBalanceTransaction = useCallback((
    transactionTypeId: number,
    checkedOpenBalances: Set<ReconciliationId>,
    openBalancesFilters: FiltersChangePayload | null
  ) => {
    transactionDetailsModal.onCloseModal()
    setCheckedOpenBalances(checkedOpenBalances)
    setSelectedTransactionId(null)
    setTransactionTypeId(transactionTypeId)
    setOpenBalancesFilters(openBalancesFilters)
    setTimeout(transactionDetailsModal.onOpenModal, 0) // prevents set state race condition
  }, [
    transactionDetailsModal.onCloseModal,
    transactionDetailsModal.onOpenModal
  ])

  const onSubmitViewedTransaction = useCallback(async () => {
    setSelectedTransactionId(null)
    setTransactionTypeId(TransactionTypeId.JournalEntry)
    refResetCommands.current.get(refSelectedTab.current)?.send()
    onResetOpenBalancesState()
  }, [onResetOpenBalancesState])

  const onCloseTransaction = useCallback(async () => {
    transactionDetailsModal.onCloseModal()
    setSelectedTransactionId(null)
    setTransactionTypeId(TransactionTypeId.JournalEntry)
    onResetOpenBalancesState()
  }, [transactionDetailsModal.onCloseModal, onResetOpenBalancesState])

  const onOpenTransactionDetails = useCallback((transactionTypeId: number) => {
    setTransactionTypeId(transactionTypeId)
    transactionDetailsModal.onOpenModal()
  }, [transactionDetailsModal.onOpenModal])

  const onTabSelected = useCallback((tabIdx: number) => {
    if (tabIdx === refSelectedTab.current) {
      return
    }

    const prevSelectedTab = refSelectedTab.current
    refResetCommands.current.get(prevSelectedTab)?.send()

    refSelectedTab.current = tabIdx
    setSelectedTabIdx(tabIdx)
  }, [])

  const onPayablesLoaded = useCallback((data: OpenBalancesListWithStatistics) => {
    setCounters(prev => ({
      ...prev,
      [TabIds.Payables]: data.items.length
    }))
  }, [])

  const onReceivablesLoaded = useCallback((data: OpenBalancesListWithStatistics) => {
    setCounters(prev => ({
      ...prev,
      [TabIds.Receivables]: data.items.length
    }))
  }, [])

  // ========================================== //

  const topPanelActions = useMemo(() => {
    return [
      {
        title: TransactionActionNames.JournalEntry,
        handleOnClick: () => onOpenTransactionDetails(TransactionTypeId.JournalEntry)
      },
      {
        title: TransactionActionNames.Payable,
        handleOnClick: () => onOpenTransactionDetails(TransactionTypeId.Payable)
      },
      {
        title: TransactionActionNames.PayBill,
        handleOnClick: () => onOpenTransactionDetails(TransactionTypeId.PayBill)
      },
      {
        title: TransactionActionNames.Check,
        handleOnClick: () => onOpenTransactionDetails(TransactionTypeId.Check)
      },
      {
        title: TransactionActionNames.Receivable,
        handleOnClick: () => onOpenTransactionDetails(TransactionTypeId.Receivable)
      },
      {
        title: TransactionActionNames.Receive,
        handleOnClick: () => onOpenTransactionDetails(TransactionTypeId.Receive)
      }
    ]
  }, [onOpenTransactionDetails])

  const loaders = {
    [TabIds.Payables]: loaderPayables,
    [TabIds.Receivables]: loaderReceivables
  }

  return {
    counters,
    cmdPayablesReset: cmdPayablesReset.signal,
    cmdReceivablesReset: cmdReceivablesReset.signal,
    loaders,
    onCreateBalanceTransaction,
    onPayablesLoaded,
    onReceivablesLoaded,
    onTabSelected,
    onViewBalanceTransaction,
    selectedTabIdx,
    topPanelActions,
    transactionModalProps: {
      ...transactionDetailsModal,
      checkedOpenBalances,
      openBalancesFilters,
      onSubmit: onSubmitViewedTransaction,
      onCloseModal: onCloseTransaction,
      onViewNextTransaction: onViewBalanceTransaction,
      transactionId: selectedTransactionId,
      transactionTypeId: selectedTransactionTypeId
    }
  }
}

export default useSchedulesDashboard
