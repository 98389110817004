import type { FC, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export interface SortableProps {
  id: string
  children: ReactNode
}

export const Sortable: FC<SortableProps> = (props) => {
  const { id, children } = props
  const {
    attributes, listeners, isDragging,
    transition, transform, setNodeRef
  } = useSortable({ id })

  return (
    <div
      style={{
        opacity: isDragging ? 0.1 : undefined,
        transition,
        transform: CSS.Translate.toString(transform)
      }}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      {children}
    </div>
  )
}

export default observer(Sortable)
