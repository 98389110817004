import { css } from '@emotion/css'

export default css(`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;

  .filter-block {
    display: flex;
    align-items: center;
    margin-right: 8px;

    .title {
      margin-right: 4px;
    }

    > p {
      margin-right: 4px;
    }
  }

  .filter-button-wrapper {
    .filter-button {
      background: #fafafa;
      padding: 6px 8px;
      border-radius: 8px;
      height: 32px;
      text-decoration: none;
      max-width: 335px;

      > span:nth-of-type(2) {
        width: 95%;
      }
    }
  }

  .cf-button-content .cf-tooltip-container .tooltip-content {
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px;
  }

  .cf-select-display-container  {
    height: 36px;
    background: #F3F3F3;
    border: none;
    border-radius: 8px;
    padding: 8px;
    padding-right: 4px;
    display: flex;
    align-items: center;

    .cf-display {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.25px;
      border: none;
      outline: none;
      resize: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0;
    }

    .cf-end-adornment {
      height: 100%;
      padding: 0;
    }
  }

  .search-block {
    width: 100%;
    max-width: 360px;
    height: 40px;
    margin: 0px 24px 0px auto;

    .cf-input-container {
      height: 100%;
    }

    .cf-input {
      padding: 11px 0;
      height: 16px;
    }

    .cf-start-adornment {
      padding: 6px 4px 6px 0px
    }

    .cf-end-adornment {
      padding: 7px 0 7px 4px;
      font-size: inherit;
    }
  }
`)

export const POPOVER_CLASS_NAME = css(`
  padding: 16px 24px !important;
  width:340px;
  z-index: 1000!important;
  
  .title {
    margin-bottom: 16px;
  }
  .MuiFormControlLabel-root {
    margin-left: -9px;

    .cf-checkbox-icon {
      padding: 8px;
    }
  }

  .cf-filter-items {
    margin-top: 8px;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  
  .cf-left-custom-icon {
    span {
      display: inline-block;
      margin-left: 8px;
      color: rgba(0, 0, 0, 0.30);
    }
  }
`)

export const DROPDOWN_POPOVER_CLASS_NAME = css(`
  min-width: 320px;
  margin-left: 100px;
`)
