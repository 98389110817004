import { useCallback, useContext, useState, type FocusEvent } from 'react'
import { useForm, isOk, type UseFormReturn, type FormValidation, type Result } from '@carfluent/common'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import { type Login, UserRoles } from 'api/types'
import { type PickedFormDataProps } from 'types'
import AuthCTX from 'store/auth'
import { navigateToUserDefaultRoute } from 'pages/auth/utils'
import { emailField, passwordField } from 'utils/validationPresets'

export interface UseLoginReturn extends Pick<UseFormReturn<Login>, PickedFormDataProps | 'onSubmit' | 'isSubmitting'> {
  apiError: string | null
  showPassword: boolean
  toggleShowHidePassword: () => void
}

const baseValues = {
  username: '',
  password: ''
}

const validationRules: FormValidation<Login> = {
  username: emailField(true),
  password: passwordField(true)
}

const API_LOGIN_ERROR = 'Invalid username or password'

const useLogin = (): UseLoginReturn => {
  const [apiError, setAPIError] = useState<string | null>(null)
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowHidePassword = (): void => {
    setShowPassword(prev => !prev)
  }
  const navigate = useNavigate()
  const { loginAction } = useContext(AuthCTX)

  const onActionResult = useCallback((res: Result<UserRoles[]>) => {
    if (isOk<UserRoles[]>(res)) {
      navigateToUserDefaultRoute(res.result, navigate)
    } else if (axios.isAxiosError(res.result)) {
      setAPIError(API_LOGIN_ERROR)
    }
  }, [navigate])

  const submitAction = useCallback(async (values: Login): Promise<UserRoles[] | undefined> => {
    return await loginAction(values)
  }, [])

  const {
    isSubmitting,
    values,
    errors,
    touched,
    onChange,
    onBlur: _onBlur,
    onSubmit
  } = useForm<Login>({
    baseValues,
    validationRules,
    submitAction,
    onActionResult
  })

  const onBlur = (evOrField: FocusEvent<HTMLElement> | string): void => {
    const id = typeof evOrField === 'string'
      ? evOrField
      : evOrField.target.getAttribute('id')

    if (id == null) {
      return
    }

    setAPIError(null)
    _onBlur(id)
  }

  // ========================================== //

  return {
    isSubmitting,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onSubmit,
    apiError,
    showPassword,
    toggleShowHidePassword
  }
}

export default useLogin
