import type { FC } from 'react'
import { useState } from 'react'
import type { UseModalReturn } from '@carfluent/common'
import { Modal } from '@carfluent/common'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import CheckboxLabel from 'components/common/CheckboxLabel'
import { MODAL_MIN_WIDTH } from 'constants/constants'

export interface ConfirmDialogProps extends UseModalReturn {
  title: string
  saveButtonName: string
  checkboxLabelName: string
  minWidth?: number
  onSuccess: () => void
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  isModalOpen,
  title,
  onCloseModal: _onCloseModal,
  onSuccess,
  saveButtonName,
  checkboxLabelName,
  minWidth = MODAL_MIN_WIDTH
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false)
  const checkboxLabelValue = {
    checked: isConfirmed,
    id: 0,
    name: checkboxLabelName
  }

  const onCloseModal = (): void => {
    setIsConfirmed(false)
    _onCloseModal()
  }

  const renderFooter = (): JSX.Element => {
    return (
      <CancelSubmitActionsFooter
        isDisabled={!isConfirmed}
        onSubmit={() => {
          onSuccess()
          onCloseModal()
        }}
        onClose={onCloseModal}
        submitTitle={saveButtonName}
      />
    )
  }

  const onToggleCheckbox = (): void => {
    setIsConfirmed(value => !value)
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onCloseModal}
      renderFooterActions={renderFooter}
      title={title}
      minWidth={minWidth}
    >
      <CheckboxLabel
        value={checkboxLabelValue}
        onChange={onToggleCheckbox}
      />
    </Modal>
  )
}

export default ConfirmDialog
