import { roundCurrency } from 'utils/math'

export const getNetTradInValue = (
  tradeInCredit: number | null | undefined,
  tradeInPayoff: number | null | undefined
): number => {
  return (tradeInCredit ?? 0) * (-1) + (tradeInPayoff ?? 0)
}

export const getNetTradInValueRounded = (
  tradeInCredit: number | null | undefined,
  tradeInPayoff: number | null | undefined
): number => {
  return roundCurrency(getNetTradInValue(tradeInCredit, tradeInPayoff))
}
