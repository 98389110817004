import { css } from '@emotion/css'
import { px, ContentLayoutWidth as CLW } from 'constants/constants'

export default css(`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  
  .sub-title-container .section-title {
    margin: 0px;
    margin-bottom: 0px;
  }
  
  .sales-commission-fields-container {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    max-width: ${px(CLW.MaxSettingsContent)};
  }
  
  .sub-section-title {
    margin: none;
  }
  
  .fields-set-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .title-loader {
    &.is-loading {
      max-width: 200px;
    }
  }
  
  .fields-set-title {
    color: #101010;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px; 
  }
`)
