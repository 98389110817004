import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFF'
  },
  header: {},
  description: {
    color: 'rgba(33, 33, 33, 0.8)',
    padding: '24px 0 32px'
  },
  button: {
    width: '150px'
  }
})

export default useStyles
