import { css } from '@emotion/css'

const BACKGROUND_COLOR = '#FAFAFA'
const HOVER_MENU_BACKGROUND_COLOR = '#FFF'
const HOVERED_TOP_ITEM_BACKGROUND_COLOR = '#EDEDED'
const SELECTED_TOP_ITEM_BACKGROUND_COLOR = '#101010'
const NAV_DRAWER_WIDTH = 64
const NAV_DRAWER_WIDTH_OPEN = 250
const LOGO_CLOSED_WIDTH = 36
const DRAWER_PADDING = 8
export const ITEM_GAP = 4
export const DRAWER_TRANSITION_DURATION = 300
export const MENU_ITEM_HEIGHT = 48

export default css(`
  white-space: nowrap;
  background: ${BACKGROUND_COLOR};
  box-shadow: none;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: ${DRAWER_PADDING}px;
  padding-top: 16px;
  position: fixed;
  z-index: var(--main-menu-zindex);
  top: var(--header-height);
  left: 0px;
  bottom: 0px;
  transition: width ${DRAWER_TRANSITION_DURATION}ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    position: relative;
  }

  .cf-drawer-header {
    padding: 21px 0px 21px ${(NAV_DRAWER_WIDTH - LOGO_CLOSED_WIDTH) / 2 - DRAWER_PADDING / 2}px;
    overflow: hidden;
    width: ${LOGO_CLOSED_WIDTH}px;
    box-sizing: content-box;
    min-height: 36px;

    svg {
      min-width: 130px;
    }
  }

  .nav-sub-group {
    overflow: hidden;
    height: 0px; /* Initially collapse the section */
    transition: height ${DRAWER_TRANSITION_DURATION}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .nav-menu-list-item > svg ~ * {
    display: none;
  }
  
  .nav-menu-list-item-title {
    user-select: none;
  }
     
  .nav-item > .nav-menu-list-item,
  .nav-item > a > .nav-menu-list-item,
  .nav-sub-item .nav-menu-list-item {
    cursor: pointer;
  }

  &.cf-drawer-open {
    background: ${BACKGROUND_COLOR};
    width: ${NAV_DRAWER_WIDTH_OPEN}px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255,255,255, 0.75);
      border: 2px solid transparent;
      border-radius: 4px;
      background-clip: content-box;
    }

    .cf-drawer-header {
      width: unset;
    }

    .nav-sub-group.cf-menu-expanded {
      // real height must be set in component after calculation.
      // max-height animation trick is not smooth.
      height: auto;
    }

    &.cf-drawer-fully-opened .nav-menu-list-item > svg ~ * {
      display: block;
    }  
    
    /**
     * Top-level menu items, opened drawer.
     */
    
    .nav-item > .nav-menu-list-item:hover,
    .nav-item > a > .nav-menu-list-item:hover {
      background: ${HOVERED_TOP_ITEM_BACKGROUND_COLOR};
    }
    
    .nav-item-selected > .nav-menu-list-item,
    .nav-item-selected > a > .nav-menu-list-item,
    .nav-item-selected > .nav-menu-list-item:hover,
    .nav-item-selected > a > .nav-menu-list-item:hover {
      background: ${SELECTED_TOP_ITEM_BACKGROUND_COLOR};

      .nav-menu-list-item-title {
        color: #FFF;
      }
    }
    
    /**
     * Sub-menu items, opened drawer.
     */
     
    .nav-sub-item > a > .nav-menu-list-item:hover {
      background: #F3F3F3;
    }
    
    .nav-sub-item.nav-item-selected > a > .nav-menu-list-item,
    .nav-sub-item.nav-item-selected > a > .nav-menu-list-item:hover {
      background: #EBEBEB;
      
      .nav-menu-list-item-title {
        color: #212121;
      }
    }
  }

  &.cf-drawer-close {
    background: ${BACKGROUND_COLOR};
    width: ${NAV_DRAWER_WIDTH}px;

    li.single-nav-item:hover::after {
      content: attr(data-title);
      display: block;
      position: absolute;
      background: rgba(33, 33, 33, 1);
      color: #fff;
      padding: 5px;
      border-radius: 4px;
      top: 100%;
      left: 75%;
      z-index: 1;
      white-space: nowrap;
    }

    .hover-nav-menu.cf-menu-expanded {
      &, &:hover {
        display: block;
      }
    }
    
    /**
     * Top-level menu items, closed drawer.
     */
     
    .nav-item-hovered > .nav-menu-list-item,
    .nav-item-hovered > a > .nav-menu-list-item {
      background: ${HOVERED_TOP_ITEM_BACKGROUND_COLOR};
    }
    
    .nav-item-selected > .nav-menu-list-item,
    .nav-item-selected > a > .nav-menu-list-item {
      background: ${SELECTED_TOP_ITEM_BACKGROUND_COLOR};
    }
    
    /**
     * Sub-menu items, closed drawer.
     */
     
    .nav-sub-item .nav-menu-list-item:hover {
      background: #FAFAFA;
    }
    
    .nav-sub-item.nav-item-selected .nav-menu-list-item {
      background: #F3F3F3;
    }
  }

  .hover-nav-menu {
    display: none;
    position: absolute;
    z-index: var(--main-menu-zindex);
    right: 0px;
    top: 0px;
    transform: translateX(100%);
    padding: 0px 12px;
    background: transparent;
    
    > .hover-menu-inner-block {
      padding: 8px;
      min-width: 240px;
      max-width: 240px;
      border-radius: 12px;
      background: ${HOVER_MENU_BACKGROUND_COLOR};
      box-shadow: 0px 4px 16px 5px rgba(0, 0, 0, 0.32);
      
      .hover-menu-title {
        user-select: none;
        color: #101010;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.15px;
      }

      .nav-menu-list-item > svg ~ * {
        display: block;
      }

      > p {
        margin: 0px;
        padding: 12px;
        height: 48px;
      }
    }
  }

  .cf-nav-toggle {
    display: flex;
    justify-content: center;
    margin: auto 0px 24px auto;
    border-radius: 12px;
    padding: 12px;

    :hover {
      cursor: pointer;
      background: #F3F3F3;
    }
  }

  .cf-nav-menu {
    font-weight: 500;
  }

  .nav-menu-list-item {
    color: inherit;
    border-radius: 12px;
    min-height: ${MENU_ITEM_HEIGHT}px;
    padding: 12px;
    margin-bottom: ${ITEM_GAP}px;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 24px 1fr 24px;
    align-items: center;
    background: transparent;

    > svg {
      justify-self: center;
    }
  }

  .cf-nav-menu {
    span, a {
      line-height: 24px;
      flex: 1;
    }

    a {
      color: inherit;

      :visited, :active, :hover {
        color: inherit;
        text-decoration: none;
      }
    }
  }
`)
