import { ColumnDef } from '@carfluent/common/dist/UI'

import TextCellHOC from 'components/common/Table/cells/TextCell'
import TooltipCell from 'components/common/Table/cells/TooltipCell'
import { LinkCellHOC } from 'components/common/Table/cells/LinkCell'

import type { TitleReportItem } from './types'

export const columnDefinitions: Array<ColumnDef<TitleReportItem>> = [
  {
    size: 240,
    accessorKey: 'make',
    loading: 'big_line',
    header: TextCellHOC({ text: 'Car' }),
    cell: LinkCellHOC({ withTooltip: true })
  },
  {
    size: 100,
    sortable: false,
    cell: TooltipCell,
    loading: 'big_line',
    accessorKey: 'stock',
    header: TextCellHOC({ text: 'Stock#' })
  },
  {
    size: 200,
    sortable: false,
    cell: TooltipCell,
    loading: 'big_line',
    accessorKey: 'vin',
    header: TextCellHOC({ text: 'VIN' })
  },
  {
    size: 240,
    sortable: false,
    cell: TooltipCell,
    loading: 'big_line',
    accessorKey: 'titleStage',
    className: 'header-indent',
    header: TextCellHOC({ text: 'Title stage' })
  },
  {
    size: 340,
    sortable: false,
    cell: TooltipCell,
    loading: 'big_line',
    accessorKey: 'registrationStage',
    header: TextCellHOC({ text: 'Registration stage' })
  },
  {
    size: 200,
    sortable: false,
    loading: 'big_line',
    accessorKey: 'soldTo',
    cell: LinkCellHOC({ withTooltip: true, emptyCellValue: '-' }),
    header: TextCellHOC({ text: 'Sold to' })
  },
  {
    size: 240,
    sortable: false,
    cell: TooltipCell,
    loading: 'big_line',
    accessorKey: 'source',
    header: TextCellHOC({ text: 'Source' })
  },
  {
    size: 140,
    sortable: false,
    cell: TooltipCell,
    loading: 'big_line',
    accessorKey: 'inventoryDate',
    header: TextCellHOC({ text: 'Inventory date' })
  },
  {
    size: 100,
    sortable: false,
    cell: TooltipCell,
    loading: 'big_line',
    accessorKey: 'age',
    header: TextCellHOC({ text: 'Age' })
  },
  {
    size: 124,
    sortable: false,
    cell: TooltipCell,
    loading: 'big_line',
    className: 'days-pending-class-name',
    accessorKey: 'daysPendingRegistration',
    header: TextCellHOC({ text: 'Days pending registration' })
  },
  {
    size: 320,
    sortable: false,
    cell: TooltipCell,
    loading: 'big_line',
    accessorKey: 'titleNote',
    header: TextCellHOC({ text: 'Note' })
  }
]
