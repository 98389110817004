/**
 * Hex with opacity is not supported, thus we need to convert it to RGBA
 * @param hex (e.g. #ff0000)
 * @param opacity (from 0 to 1)
 * @returns RGBA color string
 */
function hexToRGBA (hex: string, opacity: number): string {
  // Convert the hexadecimal color to RGB values
  const r = parseInt(hex.substring(1, 3), 16)
  const g = parseInt(hex.substring(3, 5), 16)
  const b = parseInt(hex.substring(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export default hexToRGBA
