import { type AccountListItem } from 'api/types'
import { type UpdateDealerFeeMarkupSettingsDto } from 'api/types/requests'
import { CommissionType, type CoverageFeeSettingsDto } from 'api/types/responses'
import { type GeneralSettingsFormData } from 'types'
import { percentToDecimal } from 'utils/math'
import { toJS } from 'mobx'

const serializeSettings = (
  _values: GeneralSettingsFormData
): Omit<UpdateDealerFeeMarkupSettingsDto, 'id' | 'websiteBaseUrl'> => {
  const values = toJS(_values)

  const {
    coverageFeeSettings,
    salesMonthlyTarget,
    backGrossSalesCommissionSetting,
    frontGrossSalesCommissionSetting,
    ...props
  } = values

  const isBackGrossCommissionPercent =
    backGrossSalesCommissionSetting.salesCommissionTypeId === CommissionType.Percent

  const isFrontGrossCommissionPercent =
    frontGrossSalesCommissionSetting.salesCommissionTypeId === CommissionType.Percent

  const serializeCoverageFeeSettings = (): CoverageFeeSettingsDto => {
    const getAccountId = (account: AccountListItem | null): number | null => {
      return account != null ? Number(account.id) : null
    }

    const getLabelName = (label: string | null): string | null => {
      return (label !== '' && label != null) ? label : null
    }

    return ({
      buyerTagSetting: {
        ...coverageFeeSettings.buyerTagSetting,
        labelName: getLabelName(coverageFeeSettings.buyerTagSetting.labelName),
        accountId: getAccountId(coverageFeeSettings.buyerTagSetting.account),
        defaultCashAmount: coverageFeeSettings.buyerTagSetting.defaultValueCash ?? 0,
        defaultFinancingAmount: coverageFeeSettings.buyerTagSetting.defaultValueFinancing ?? 0
      },
      carDeliverySetting: {
        ...coverageFeeSettings.carDeliverySetting,
        labelName: getLabelName(coverageFeeSettings.carDeliverySetting.labelName),
        accountId: getAccountId(coverageFeeSettings.carDeliverySetting.account),
        defaultAmount: coverageFeeSettings.carDeliverySetting.defaultValueCash ?? 0
      },
      dealerHandlingFeeSetting: {
        ...coverageFeeSettings.dealerHandlingFeeSetting,
        labelName: getLabelName(coverageFeeSettings.dealerHandlingFeeSetting.labelName),
        accountId: getAccountId(coverageFeeSettings.dealerHandlingFeeSetting.account),
        defaultAmount: coverageFeeSettings.dealerHandlingFeeSetting.defaultValueCash ?? 0
      },
      dealerInventoryTaxSetting: {
        ...coverageFeeSettings.dealerInventoryTaxSetting,
        labelName: getLabelName(coverageFeeSettings.dealerInventoryTaxSetting.labelName),
        accountId: getAccountId(coverageFeeSettings.dealerInventoryTaxSetting.account),
        defaultCashPercent: percentToDecimal(coverageFeeSettings.dealerInventoryTaxSetting.defaultValueCash ?? 0),
        defaultFinancingPercent: percentToDecimal(coverageFeeSettings.dealerInventoryTaxSetting.defaultValueFinancing ?? 0)
      },
      emissionsSetting: {
        ...coverageFeeSettings.emissionsSetting,
        labelName: getLabelName(coverageFeeSettings.emissionsSetting.labelName),
        accountId: getAccountId(coverageFeeSettings.emissionsSetting.account),
        defaultAmount: coverageFeeSettings.emissionsSetting.defaultValueCash ?? 0
      },
      inspectionFeeSetting: {
        ...coverageFeeSettings.inspectionFeeSetting,
        labelName: getLabelName(coverageFeeSettings.inspectionFeeSetting.labelName),
        accountId: getAccountId(coverageFeeSettings.inspectionFeeSetting.account),
        defaultCashAmount: coverageFeeSettings.inspectionFeeSetting.defaultValueCash ?? 0,
        defaultFinancingAmount: coverageFeeSettings.inspectionFeeSetting.defaultValueFinancing ?? 0
      },
      plateTransferFeeSetting: {
        ...coverageFeeSettings.plateTransferFeeSetting,
        labelName: getLabelName(coverageFeeSettings.plateTransferFeeSetting.labelName),
        accountId: getAccountId(coverageFeeSettings.plateTransferFeeSetting.account),
        defaultCashAmount: coverageFeeSettings.plateTransferFeeSetting.defaultValueCash ?? 0,
        defaultFinancingAmount: coverageFeeSettings.plateTransferFeeSetting.defaultValueFinancing ?? 0
      },
      registrationFeeSetting: {
        ...coverageFeeSettings.registrationFeeSetting,
        labelName: getLabelName(coverageFeeSettings.registrationFeeSetting.labelName),
        accountId: getAccountId(coverageFeeSettings.registrationFeeSetting.account),
        defaultCashAmount: coverageFeeSettings.registrationFeeSetting.defaultValueCash ?? 0,
        defaultFinancingAmount: coverageFeeSettings.registrationFeeSetting.defaultValueFinancing ?? 0
      },
      titleFeeSetting: {
        ...coverageFeeSettings.titleFeeSetting,
        labelName: getLabelName(coverageFeeSettings.titleFeeSetting.labelName),
        accountId: getAccountId(coverageFeeSettings.titleFeeSetting.account),
        defaultCashAmount: coverageFeeSettings.titleFeeSetting.defaultValueCash ?? 0,
        defaultFinancingAmount: coverageFeeSettings.titleFeeSetting.defaultValueFinancing ?? 0
      },
      serviceContractTaxable: coverageFeeSettings.serviceContractTaxable,
      gapTaxable: coverageFeeSettings.gapTaxable,
      appearanceProtectionTaxable: coverageFeeSettings.appearanceProtectionTaxable,
      tireAndWheelTaxable: coverageFeeSettings.tireAndWheelTaxable,
      theftTaxable: coverageFeeSettings.theftTaxable,
      backGrossSalesCommissionSetting: {
        ...backGrossSalesCommissionSetting,
        fixed: isBackGrossCommissionPercent ? null : backGrossSalesCommissionSetting.fixed,
        minAmount: isBackGrossCommissionPercent ? backGrossSalesCommissionSetting.minAmount : null,
        percent: isBackGrossCommissionPercent && (backGrossSalesCommissionSetting.percent != null)
          ? percentToDecimal(backGrossSalesCommissionSetting.percent)
          : null
      },
      frontGrossSalesCommissionSetting: {
        ...frontGrossSalesCommissionSetting,
        fixed: isFrontGrossCommissionPercent ? null : frontGrossSalesCommissionSetting.fixed,
        minAmount: isFrontGrossCommissionPercent ? frontGrossSalesCommissionSetting.minAmount : null,
        percent: isFrontGrossCommissionPercent && (frontGrossSalesCommissionSetting.percent != null)
          ? percentToDecimal(frontGrossSalesCommissionSetting.percent)
          : null
      }
    })
  }

  return {
    ...props,
    coverageFeeSettings: serializeCoverageFeeSettings(),
    maxCardPaymentAmount: values.maxCardPaymentAmount ?? 0,
    excellentInterestRate: values.excellentInterestRate ?? 0,
    goodInterestRate: values.goodInterestRate ?? 0,
    averageInterestRate: values.averageInterestRate ?? 0,
    belowAverageInterestRate: values.belowAverageInterestRate ?? 0,
    interestRateMarkup: values.interestRateMarkup ?? 0,
    tradeInAdjustment: values.tradeInAdjustment ?? 0,
    inventoryPackCost: values.inventoryPackCost ?? 0,
    salesTarget: salesMonthlyTarget ?? 0
  }
}

export default serializeSettings
