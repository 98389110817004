import { css } from '@emotion/css'

export default css(`
  &.labeled-skeleton.is-loading {
    display: flex;
    gap: 16px;
  }
  
  .label-skeleton { width: 35%; }
  .field-skeleton { width: calc(65% - 16px); }
`)
