import type { FC } from 'react'
import type { IconProps } from 'types'

const MessageSentIcon: FC<IconProps> = ({ color = '#21212180', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask id='mask0_86546_8253' maskUnits='userSpaceOnUse' x='0' y='0' width={width} height={height}>
        <rect width={width} height={height} fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_86546_8253)'>
        <path d='M3.33203 12.2598L1.8987 13.6931C1.78759 13.8042 1.66536 13.832 1.53203 13.7764C1.3987 13.7209 1.33203 13.6153 1.33203 13.4598V3.5931C1.33203 3.22643 1.46259 2.91254 1.7237 2.65143C1.98481 2.39032 2.2987 2.25977 2.66536 2.25977H11.9987C12.3654 2.25977 12.6793 2.39032 12.9404 2.65143C13.2015 2.91254 13.332 3.22643 13.332 3.5931V6.5931H11.9987V3.5931H2.66536V10.9264H7.9987V12.2598H3.33203ZM5.33203 6.5931H9.33203C9.52092 6.5931 9.67925 6.52921 9.80703 6.40143C9.93481 6.27365 9.9987 6.11532 9.9987 5.92643C9.9987 5.73754 9.93481 5.57921 9.80703 5.45143C9.67925 5.32365 9.52092 5.25977 9.33203 5.25977H5.33203C5.14314 5.25977 4.98481 5.32365 4.85703 5.45143C4.72925 5.57921 4.66536 5.73754 4.66536 5.92643C4.66536 6.11532 4.72925 6.27365 4.85703 6.40143C4.98481 6.52921 5.14314 6.5931 5.33203 6.5931ZM5.33203 9.25977H7.33203C7.52092 9.25977 7.67925 9.19588 7.80703 9.0681C7.93481 8.94032 7.9987 8.78199 7.9987 8.5931C7.9987 8.40421 7.93481 8.24588 7.80703 8.1181C7.67925 7.99032 7.52092 7.92643 7.33203 7.92643H5.33203C5.14314 7.92643 4.98481 7.99032 4.85703 8.1181C4.72925 8.24588 4.66536 8.40421 4.66536 8.5931C4.66536 8.78199 4.72925 8.94032 4.85703 9.0681C4.98481 9.19588 5.14314 9.25977 5.33203 9.25977Z' fill={color} />
        <path d='M9.9987 12.2669H12.1154L11.532 12.8502C11.4098 12.9835 11.3459 13.1419 11.3404 13.3252C11.3348 13.5085 11.3987 13.6669 11.532 13.8002C11.6654 13.9335 11.8209 14.0002 11.9987 14.0002C12.1765 14.0002 12.332 13.9335 12.4654 13.8002L14.1987 12.0669C14.332 11.9335 14.3987 11.778 14.3987 11.6002C14.3987 11.4224 14.332 11.2669 14.1987 11.1335L12.4654 9.40019C12.332 9.26686 12.1765 9.2002 11.9987 9.2002C11.8209 9.2002 11.6654 9.26686 11.532 9.40019C11.3987 9.53353 11.3348 9.69186 11.3404 9.8752C11.3459 10.0585 11.4098 10.2169 11.532 10.3502L12.1154 10.9335H9.9987C9.80981 10.9335 9.65148 10.9974 9.5237 11.1252C9.39592 11.253 9.33203 11.4113 9.33203 11.6002C9.33203 11.7891 9.39592 11.9474 9.5237 12.0752C9.65148 12.203 9.80981 12.2669 9.9987 12.2669Z' fill={color} />
      </g>
    </svg>
  )
}

export default MessageSentIcon
