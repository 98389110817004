import { css } from '@emotion/css'

export const ROOT_CLASS_NAME = css`
  position: relative;
  margin-left: 0!important;

  .cf-table-wrapper {
    border-radius: 12px 12px 2px 2px!important;
  }

  .cf-md {
    width: calc(100% - 48px);
    margin: 0px 24px;
    max-width: 100%;
    
    @media screen and (max-width: 1360px) {
      width: 100%;
      margin: 0;
      max-width: 100%;
    }
    
    @media screen and (min-width: 1440px) {
      width: 1300px;
      margin: 0 auto;
    }
  }
  
  .cf-report-top-actions {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .cf-top-wrapper {
    margin-bottom: 24px;
  }
`

export default css`
  .cf-no-data-block {
    border-top: none!important;
  }

  tbody tr td:first-child>div {
    padding-left: 16px!important;
  }

  .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: #FAFAFA;
  }

  .cf-table-row-hover:hover>td:first-child>div {
    border-top-left-radius: 2px!important;
    border-bottom-left-radius: 2px!important;
  }

  .cf-table {
    .cf-table-header {
      tr th >div>div:first-child {
        padding: 3.5px 0 4px !important;
      }
    }
    
    .cf-table-row {
      .cf-cell-wrapper {
        border-left: 0!important;

        .MuiSkeleton-root {
          width: 100% !important;
        }
      }
      
      .work-hours-group {
        min-width: 90px;
        max-width: 90px;
        position: relative;

        .cf-cell-wrapper {
          width: 100% !important;

          .MuiSkeleton-root {
            width: 100% !important;
          }
        }
      }

      .representative-group {
        min-width: 155px!important;
        max-width: 155px!important;
        .cell-text, .cf-cell-wrapper {
          width: 100%!important;
        }
      }
    }
    
    .cf-table-header-row {
      border: none;
      background: #F3F3F3;
      
      :first-child {
        .cf-table-header-title {
          padding-top: 8px!important;
        }
      }

      .cf-table-header-title {
        padding-left: 8px;
      }
      
      .cf-table-header-cell {
        height: 32px;
        min-width: 100px;
        max-width: 100px;

        &.representative-group {
          min-width: 150px!important;
          max-width: 150px!important;

          .cf-table-header-title {
            max-width: 164px!important;
            padding-left: 16px;
          }
        }

        &.work-hours-group {
          min-width: 90px;
          max-width: 90px;
          position: relative;
          
          .cf-table-header-title {
            position: absolute;
            top: -100%;
            left: 0;
            width: 100%;
            height: calc(100% * 2);
            
            p {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              max-width: 90px;
              height: 100%;
              display: flex;
              align-items: flex-end;
              padding: 4px 8px;
              color: #101010;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
              letter-spacing: 0.4px;
              background: #FAFAFA;
            }
          }
        }
      }
      
      .sent-group {
        background: #FAFAFA!important;
      }
      
      &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        
        .cf-header-cell-title {
          color: #101010;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.4px;
        }
      }
    }

    @media screen and (min-width: 1300px) {
      .cf-table-row {
        .representative-group {
          min-width: 164px!important;
          max-width: 186px!important;
          .cell-text, .cf-cell-wrapper {
            min-width: 100%!important;
            max-width: 100%!important;

            .MuiSkeleton-root {
              width: 100%!important;
            }
          }
        }
      }
    }

    @media screen and (min-width: 1480px) {
      .cf-table-row {
        .representative-group {
          min-width: 320px!important;
          max-width: 320px!important;
          .cell-text, .cf-cell-wrapper {
            min-width: 100%!important;
            max-width: 100%!important;
            
            
            .MuiSkeleton-root {
              width: 100%!important;
            }
          }
        }
      }

      .cf-table-header-row {
        .cf-table-header-cell {
          &.representative-group {
            min-width: 320px!important;
            max-width: 320px!important;
          }
        }
      }
    }
    .cf-table-row-hover:hover>td:first-child>div {
      border-left: none;
      padding-left: 16px;
    }
    
    .right-alignment {
      text-align: right;
    }
    
    .MuiTableBody-root {
      .cf-table-row {
        border: none;
      }

      .cf-table-group-row {
        height: 31px;
        box-sizing: border-box;
        border: none;
        
        .cf-divider-left, .cf-divider-right {
          display: none;
        }

        .cf-table-group-cell {
          height: 32px;
          box-sizing: border-box;
        }
      }
    }
  }
`
