import type { PopoverPropsV2 } from '@carfluent/common'

export const POPOVER_OPTIONS: PopoverPropsV2['options'] = {
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 8]
      }
    }
  ]
}

export const REGISTRATION_STAGE_NOT_SELECTED_ID = 0
export const ALL_ITEM_ID = -1

export const REGISTRATION_STAGE_NOT_SELECTED = {
  name: 'Registration stage not selected',
  id: REGISTRATION_STAGE_NOT_SELECTED_ID
}

export const ALL_ITEM = {
  name: 'All',
  id: ALL_ITEM_ID
}
