import type { FC } from 'react'
import { cnx, FormDropdown, FormNumberInput } from '@carfluent/common'

import Collapse, { renderAddRemoveBtn } from 'components/common/Collapse'
import VinInput from 'components/common/VinInput'
import { MAX_PRICE_VALUE, ValidationLength } from 'constants/validation'
import type { TradeInSectionProps2 } from './types'
import { getYearsForDropdownFieldAsNumbers } from 'utils/input'
import CarDetailsFields from 'components/common/CarDetailsFields'
import IconSVGPlus from 'components/inlineImages/IconSVGPlus'

const YEAR_OPTIONS = getYearsForDropdownFieldAsNumbers()

const prefixizeField = (field: string, prefix = ''): string => `${prefix}.${field}`

const GET_CAR_DETAILS_IDS = (prefix = ''): Record<string, string> => ({
  make: prefixizeField('vehicleMake', prefix),
  model: prefixizeField('vehicleModel', prefix),
  trim: prefixizeField('vehicleTrim', prefix)
})

/**
 * Used on Deal View Vehicles tab.
 * It is the latest updated section of the TradeInDetails component.
 * In separate tasks should be reused everywhere
 */
const TradeInDetailsWithDropdowns: FC<TradeInSectionProps2> = ({
  className,
  values,
  errors,
  touched,
  fieldPrefix = '',
  isTradeInSectionVisible,
  showAcvField = false,
  getCollapseBtnText,
  onApplyVin,
  onToggleIsExpanded,
  onChange,
  onBlur,
  isDisabled
}) => {
  return (
    <Collapse
      className={cnx(className, 'trade-in', isTradeInSectionVisible && 'is-expanded', isDisabled && 'trade-in-disabled')}
      timeout={5} // intentionally small value, because title change is not animated
      title={
        isTradeInSectionVisible
          ? 'Trade in'
          : (
            <h3
              className='toggle-trade-in'
              onClick={onToggleIsExpanded}
            >
              <IconSVGPlus color={isDisabled ? '#21212140' : '#101010'} /> Add Trade In
            </h3>
            )
      }
      isDisabled={isDisabled}
      isExpanded={isTradeInSectionVisible}
      getBtnText={getCollapseBtnText}
      renderCollapseBtn={renderAddRemoveBtn}
      toggleIsExpanded={onToggleIsExpanded}
    >
      <VinInput
        id={prefixizeField('vehicleVin', fieldPrefix)}
        value={values.vehicleVin}
        error={errors?.vehicleVin}
        touched={touched?.vehicleVin}
        disabled={isDisabled}
        onBlur={onBlur}
        onChange={onChange}
        onApply={onApplyVin}
      />
      <FormDropdown
        id={prefixizeField('vehicleYear', fieldPrefix)}
        error={errors?.vehicleYear}
        label='Year'
        onChange={onChange}
        onBlur={onBlur}
        options={YEAR_OPTIONS}
        touched={touched?.vehicleYear}
        value={values.vehicleYear}
        disabled={isDisabled}
      />
      {
        /**
         * this component triggers onChange onMount which makes the form changed
         * even if the field is not yet visible
         */
        isTradeInSectionVisible && (
          <CarDetailsFields
            ids={GET_CAR_DETAILS_IDS(fieldPrefix)}
            make={values.vehicleMake}
            model={values.vehicleModel}
            trim={values.vehicleTrim}
            makeError={errors?.vehicleMake}
            modelError={errors?.vehicleModel}
            trimError={errors?.vehicleTrim}
            makeTouched={touched?.vehicleMake}
            modelTouched={touched?.vehicleModel}
            trimTouched={touched?.vehicleTrim}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isDisabled}
          />
        )
}
      <FormNumberInput
        id={prefixizeField('mileage', fieldPrefix)}
        label='Mileage'
        value={values.mileage}
        error={errors?.mileage}
        touched={touched?.mileage}
        disabled={isDisabled}
        onBlur={onBlur}
        onChange={onChange}
        maxLength={ValidationLength.MILEAGE_MAX}
      />
      <FormNumberInput
        id={prefixizeField('credit', fieldPrefix)}
        preset='financial'
        label='Trade in credit'
        isNegativeAllowed={false}
        value={values.credit}
        error={errors?.credit}
        touched={touched?.credit}
        disabled={isDisabled}
        max={MAX_PRICE_VALUE}
        onBlur={onBlur}
        onChange={onChange}
      />
      <FormNumberInput
        id={prefixizeField('paymentAmount', fieldPrefix)}
        label='Trade in payoff'
        preset='financial'
        isNegativeAllowed={false}
        value={values.paymentAmount}
        error={errors?.paymentAmount}
        touched={touched?.paymentAmount}
        disabled={isDisabled}
        max={MAX_PRICE_VALUE}
        onBlur={onBlur}
        onChange={onChange}
      />
      {
        showAcvField && (
          <FormNumberInput
            id={prefixizeField('acv', fieldPrefix)}
            label='Trade in ACV'
            preset='financial'
            isNegativeAllowed={false}
            value={values.acv}
            error={errors?.acv}
            touched={touched?.acv}
            disabled={isDisabled}
            max={MAX_PRICE_VALUE}
            onBlur={onBlur}
            onChange={onChange}
          />
        )
      }
    </Collapse>
  )
}

export default TradeInDetailsWithDropdowns
