import type { FC } from 'react'
import { Skeleton } from '@material-ui/lab'

import CLASS_NAME from './styles'

const PurchaseAccountsSectionSkeleton: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <div className='purchase-accounts-skeleton'>
        <Skeleton width={24} height={24} variant='circle' />
        <Skeleton height={24} variant='text' />
      </div>
      <div className='purchase-accounts-skeleton'>
        <Skeleton width={24} height={24} variant='circle' />
        <Skeleton height={24} variant='text' />
      </div>
    </div>
  )
}

export default PurchaseAccountsSectionSkeleton
