import type { FormValidation } from '@carfluent/common'
import {
  dateField,
  taskTime,
  scheduleCompleteTaskOptionId,
  scheduleCompleteTaskSubOptionIds,
  dictionaryItemDropdownField
} from 'utils/validationPresets'
import type { LogActivityFormData } from './types'

const dictionaryItemField = dictionaryItemDropdownField()
const date = dateField('append')

export const activityLogFormValidation: FormValidation<LogActivityFormData> = {
  assignedUser: dictionaryItemField,
  date,
  time: taskTime,
  activityCompleteOption: scheduleCompleteTaskOptionId,
  isInbound: scheduleCompleteTaskOptionId
}

export const activityLogVisitFormValidation: FormValidation<LogActivityFormData> = {
  assignedUser: dictionaryItemField,
  date,
  time: taskTime,
  activityCompleteSubOptions: scheduleCompleteTaskSubOptionIds
}

export const activityLogOtherFormValidation: FormValidation<LogActivityFormData> = {
  subject: dictionaryItemDropdownField(true),
  date
}
