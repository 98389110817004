import { type FC } from 'react'

import CLASS_NAME from './styles'

const ExactPackTableHeader: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <div className='header-label'>Forms</div>
      <div className='header-label' />
    </div>
  )
}

export default ExactPackTableHeader
