import type { FC, ReactNode } from 'react'
import { cnx } from '@carfluent/common'

import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

import CLASS_NAME from './styles'

const textSkeletonList = (amount: number, className?: string): ReactNode =>
  <div className={cnx('skeleton-column-wrapper', className)}>
    {[...Array(amount)].map((el, i) => <TextSkeleton key={i} />)}
  </div>

const LoaderSkeleton: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <div>
        {textSkeletonList(6)}
      </div>

      <div>
        <div className='skeleton-bottom-padding'>
          <TextSkeleton className='skeleton-section-title' />
        </div>

        {textSkeletonList(6, 'skeleton-bottom-padding')}

        <div className='skeleton-bottom-padding'>
          <TextSkeleton className='skeleton-section-title' />
        </div>

        <div className='skeleton-checkbox-wrapper'>
          <TextSkeleton />
          <TextSkeleton />
        </div>
      </div>

      <div>
        <div className='skeleton-bottom-padding'>
          <TextSkeleton className='skeleton-section-title' />
        </div>

        {textSkeletonList(10, 'skeleton-bottom-padding')}

        <div className='skeleton-checkbox-wrapper skeleton-bottom-padding'>
          <TextSkeleton />
          <TextSkeleton />
        </div>
      </div>

      <div>
        <div className='skeleton-bottom-padding'>
          <TextSkeleton className='skeleton-section-title' />
        </div>

        <TextSkeleton className='skeleton-rectangle skeleton-bottom-padding' />
        <TextSkeleton className='skeleton-rectangle' />
      </div>

      <div>
        <TextSkeleton className='skeleton-section-title' />
      </div>

      <div>
        <div className='skeleton-bottom-padding'>
          <TextSkeleton className='skeleton-section-title' />
        </div>

        <TextSkeleton className='skeleton-rectangle' />
      </div>
    </div>
  )
}

export default LoaderSkeleton
