import { type FC } from 'react'
import identity from 'lodash-es/identity'
import { FormDropdown, FormInput, FormNumberInput, FormDatePicker, Loader, UI } from '@carfluent/common'

import { ValidationLength, MAX_PRICE_VALUE } from 'constants/validation'
import ModalFullscreen from 'components/dialogs/ModalFullscreen'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import ErrorsBlock from 'components/common/ErrorsBlock'
import { Dropdown } from 'components/form'
import VinInput from 'components/common/VinInput'
import MakeModelFields from 'components/common/CarDetailsFields'
import { getYearsForDropdownField } from 'utils/input'
import { getOptionSelected, isTruthy } from 'utils/general'
import RadioGroup from 'components/common/RadioGroup'
import RadioLabelControl from 'components/common/RadioLabelControl'
import VendorDropdown from 'components/legacy/VendorDropdownOld'

import useNewVehicle from './hook'
import CLASS_NAME from './styles'

const yearOptions = getYearsForDropdownField()

interface AddNewVehicleModalProps {
  isOpen: boolean
  onClose: () => void
}

const AddNewVehicleModal: FC<AddNewVehicleModalProps> = ({
  isOpen,
  onClose
}) => {
  const {
    apiErrors,
    isLoading,
    isSubmitting,
    dictionaries,
    onApplyClick,
    onCancelClick,
    onBlur,
    onChange,
    onSubmit,
    errors,
    touched,
    values,
    setFieldTouched,
    purchaseAccountOptions,
    onAddVendor,
    vendorProps,
    isAccountingEnabled,
    onChangeAccountId,
    users
  } = useNewVehicle({ onClose, isOpen })

  return (
    <ModalFullscreen
      className={CLASS_NAME}
      isOpen={isOpen}
      title='Add new car'
      keepContentMounted={false}
      onClose={onCancelClick}
      renderFooterActions={(
        <CancelSubmitActionsFooter
          isLoading={isSubmitting}
          onSubmit={onSubmit}
          onClose={onCancelClick}
          submitTitle='ADD NEW CAR'
        />
        )}
      dataTestId='add-new-car-popup'
      titleDataTestId='add-new-car-popup-title'
    >
      {isLoading && <Loader size='large' />}

      <ErrorsBlock errors={apiErrors} />

      <div className='new-deal-form-content'>
        <div className='new-car-row'>
          <VinInput
            value={values.vin}
            error={errors.vin}
            touched={touched.vin}
            onBlur={onBlur}
            onChange={onChange}
            onApply={onApplyClick}
            onClose={onCancelClick}
            className='double-col'
          />
        </div>

        <div className='new-car-row'>
          <Dropdown
            id='year'
            label='Year'
            onChange={onChange}
            touched={touched.year}
            value={values.year}
            error={errors.year}
            options={yearOptions}
          />

          <MakeModelFields
            onChange={onChange}
            make={values.make}
            model={values.model}
            trim={values.trim}
            makeError={errors.make}
            modelError={errors.model}
            trimError={errors.trim}
            makeTouched={touched.make}
            modelTouched={touched.model}
            trimTouched={touched.trim}
            setFieldTouched={setFieldTouched}
          />

          <FormNumberInput
            id='mileage'
            startAdornment={null}
            label='Mileage'
            maxLength={10}
            onChange={onChange}
            error={errors.mileage}
            touched={touched.mileage}
            value={values.mileage ?? ''}
            preset='financial'
            isNegativeAllowed={false}
          />
        </div>

        <p className='new-car-title'>Inventory details</p>

        <div className='new-car-row'>
          <FormInput
            id='stock'
            label='Stock #'
            maxLength={ValidationLength.STOCK_MAX}
            onBlur={onBlur}
            onChange={onChange}
            touched={touched.stock}
            error={errors.stock}
            value={values.stock}
          />

          <FormDatePicker
            id='inventoryDate'
            label='Inventory date'
            onBlur={onBlur}
            onChange={onChange}
            value={values.inventoryDate}
            touched={touched.inventoryDate}
            error={errors.inventoryDate}
          />

          <FormNumberInput
            id='salePrice'
            label='Sale price'
            max={MAX_PRICE_VALUE}
            onChange={onChange}
            value={values.salePrice ?? ''}
            touched={touched.salePrice}
            error={errors.salePrice}
            preset='financial'
            isNegativeAllowed={false}
          />

          <FormInput
            id='source'
            label='Source'
            onChange={onChange}
            value={values.source}
            maxLength={ValidationLength.MAX_SOURCE}
            className='double-col'
          />
        </div>

        <p className='new-car-title'>Purchase cost</p>

        <div className='new-car-row'>
          {isAccountingEnabled && (
            <FormNumberInput
              max={MAX_PRICE_VALUE}
              id='purchasePrice'
              preset='financial'
              onChange={onChange}
              label='Purchase price'
              error={errors.salePrice}
              isNegativeAllowed={false}
              touched={touched.salePrice}
              value={values.purchasePrice ?? ''}
            />
          )}

          <FormDropdown
            id='buyer'
            label='Buyer'
            blurMode='select-first'
            options={users}
            value={values.buyer}
            error={errors.buyer}
            touched={touched.buyer}
            onChange={onChange}
          />
        </div>

        {isTruthy(values.purchasePrice) && (
          <>
            <h3 className='g-section-title'>
              How did you pay for vehicle?
            </h3>

            <RadioGroup
              id='accountId'
              onChange={onChangeAccountId}
              value={values.accountId.toString()}
            >
              {
                purchaseAccountOptions.map(el =>
                  <RadioLabelControl value={el.id.toString()} key={el.id} label={el.name} />
                )
              }
            </RadioGroup>

            <div className='new-car-row'>
              <VendorDropdown
                {...vendorProps}
                id='vendor'
                label='Vendor'
                onChange={onChange}
                value={values.vendor}
                getOptionValue={identity}
                onAddVendor={onAddVendor}
                isAutocompleteBlurHandlerSet
                touched={touched.vendor}
                isOptionOfValue={getOptionSelected}
                mode={UI.DropdownMode.Autocomplete}
                error={errors.vendor}
                getOptionSelected={getOptionSelected}
              />
            </div>
          </>
        )}

        <p className='new-car-title'>Trim details</p>

        <div className='new-car-row'>
          <Dropdown
            disableClearable
            getOptionValue={identity}
            id='body'
            label='Body'
            onChange={onChange}
            options={dictionaries.Body}
            value={values.body}
          />

          <Dropdown
            disableClearable
            getOptionValue={identity}
            id='doors'
            label='Doors'
            onChange={onChange}
            options={dictionaries.Doors}
            value={values.doors}
          />

          <FormInput
            id='engine'
            label='Engine'
            onChange={onChange}
            value={values.engine}
            maxLength={50}
            onBlur={onBlur}
            touched={touched.engine}
            error={errors.engine}
          />

          <Dropdown
            disableClearable
            getOptionValue={identity}
            id='engineCylinders'
            label='Cylinders'
            onChange={onChange}
            options={dictionaries.Cylinders}
            value={values.engineCylinders}
          />

          <Dropdown
            disableClearable
            getOptionValue={identity}
            id='fuelType'
            label='Fuel'
            onChange={onChange}
            options={dictionaries.Fuel}
            value={values.fuelType}
          />

          <Dropdown
            disableClearable
            getOptionValue={identity}
            id='drivetrain'
            label='Drivetrain'
            onChange={onChange}
            options={dictionaries.Drivetrain}
            value={values.drivetrain}
          />

          <Dropdown
            disableClearable
            getOptionValue={identity}
            id='transmission'
            label='Transmission'
            onChange={onChange}
            options={dictionaries.Transmission}
            value={values.transmission}
          />

          <Dropdown
            disableClearable
            getOptionValue={identity}
            id='genericColor'
            label='Generic color'
            onChange={onChange}
            options={dictionaries.GenericColor}
            value={values.genericColor}
          />

          <Dropdown
            disableClearable
            getOptionValue={identity}
            id='genericInterior'
            label='Generic interior'
            onChange={onChange}
            options={dictionaries.GenericInterior}
            value={values.genericInterior}
          />

          <Dropdown
            disableClearable
            getOptionValue={identity}
            id='disabilityEquipped'
            label='Disability equipped'
            onChange={onChange}
            options={dictionaries.DisabilityEquipped}
            value={values.disabilityEquipped}
          />
        </div>
      </div>
    </ModalFullscreen>
  )
}

export default AddNewVehicleModal
