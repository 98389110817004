import { serializers as S } from '@carfluent/common'

import { type ReportPayload } from 'api/types'
import { type Period } from 'utils/filters/types'
import serializeDateFilters from 'utils/filters/serializeDateFilters'
import { isTruthy } from 'utils/general'

/**
 * Serializes startDate-endDate without converting to UTC+0.
 * So that date that user see in UI will be sent to BE.
 */
const serializePeriodLocal = (period: Period): ReportPayload => {
  const { startDate, endDate } = period
  const payload = {
    from: startDate,
    to: isTruthy(endDate) ? endDate : startDate
  }

  const dates = serializeDateFilters(payload, S.serializeDate)
  const dateFrom = dates?.from
  const dateTo = dates?.to

  return {
    dateFrom: dateFrom != null ? dateFrom + 'T00:00:00' : null,
    dateTo: dateTo != null ? dateTo + 'T23:59:59' : null
  }
}

export default serializePeriodLocal
