import { type UIEvent, type FC } from 'react'
import { FormInput, Loader, useAutocompleteInfiniteList } from '@carfluent/common'
import { type FetchHandler } from '@carfluent/common/dist/hooks/useAutocompleteInfiniteList'

import IconSVG from 'components/inlineImages'
import { type DocumentFormDto, type DocumentFormPackDto } from 'api/types'

import CLASS_NAME from './styles'

export interface SearchListProps {
  fetchHandler: FetchHandler<DocumentFormDto | DocumentFormPackDto>
  onSelect: (item: DocumentFormDto | DocumentFormPackDto) => Promise<void> | void
  onClose: () => void
}

const PAGE_SIZE = 10

// Moved to different component(without using dropdown) because @popper-js lib doesn't support nested popovers
const SearchList: FC<SearchListProps> = ({
  fetchHandler,
  onSelect,
  onClose
}) => {
  const { items, search, setSearch, getItems, isLoading } = useAutocompleteInfiniteList({
    isAutorun: true,
    fetch: fetchHandler,
    pageSize: PAGE_SIZE,
    propIdName: 'list-box'
  })

  const onScroll = ({ currentTarget }: UIEvent<HTMLDivElement>): void => {
    const { scrollHeight, scrollTop, clientHeight } = currentTarget
    const isReadyToFetch = scrollHeight === (Math.abs(scrollTop) + clientHeight)

    if (isReadyToFetch && !isLoading) {
      void getItems(search)
    }
  }

  return (
    <div className={CLASS_NAME}>
      <div className='input-wrapper'>
        <FormInput
          id='search-list'
          value={search}
          placeholder='Search by name, description'
          onChange={(_, value) => setSearch(value as string)}
          startAdornment={<IconSVG.SearchIcon />}
          endAdornment={
            search != null && search !== ''
              ? (
                <span
                  className='close-icon' onClick={(e) => {
                    e.stopPropagation()
                    setSearch('')
                  }}
                >
                  <IconSVG.Close width={16} height={16} />
                </span>
                )
              : null
          }
        />
      </div>

      <div onScroll={onScroll} id='list-box-popup' className='list-box'>
        {items.map((item) => {
          const { id, name, description } = item

          const onClick = (): void => {
            void onSelect(item)
            onClose()
          }

          return (
            <div
              onClick={onClick}
              key={id}
              className='list-item'
            >
              <p>{name}</p>

              {description != null && <p>{description}</p>}
            </div>
          )
        })}

        {isLoading && <Loader />}
      </div>
    </div>
  )
}

export default SearchList
