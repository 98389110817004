import type { FormValidation, ValidationRule } from '@carfluent/common'
import type { BuyerCobuyerTabState, ErrTypeMap } from './types'
import type { FullAddressValidation } from 'types/address'
import {
  correctRule,
  requiredField,
  firstNameField,
  lastNameField,
  phoneField,
  emailField,
  birthDate,
  addressDataFull,
  ssnField,
  businessNameField,
  businessEinField,
  zipCodeField
} from 'utils/validationPresets'

type ValidationResult = FormValidation<BuyerCobuyerTabState, never, ErrTypeMap>

const cobuyerFieldValidationRule = (validationRule: ValidationRule<unknown, string>) => (
  val: unknown, ctx?: BuyerCobuyerTabState
): string | null => {
  return ctx?.isCobuyerSectionVisible === true ? validationRule(val, ctx) : correctRule()
}

const cobuyerAddressFullValidationRule = (validationRule: ValidationRule<unknown, | FullAddressValidation>) => (
  val: unknown, ctx?: BuyerCobuyerTabState
): FullAddressValidation | null => {
  return ctx?.isCobuyerSectionVisible === true
    ? validationRule(val, ctx)
    : {
        city: null,
        zipCode: null,
        state: null,
        address: null
      }
}

const businessFieldValidationRule = (validationRule: ValidationRule<unknown, string>) => (
  val: unknown, ctx?: BuyerCobuyerTabState
): string | null => {
  return ctx?.isPersonal !== true ? validationRule(val, ctx) : correctRule()
}

const conditionallyRequiredFieldValidationRule = (validationRule: (isRequired: boolean) => ValidationRule<unknown, string>) => (
  val: unknown, ctx?: BuyerCobuyerTabState
): string | null => {
  return validationRule(ctx?.isPersonal === true)(val, ctx)
}

export const validationRules: ValidationResult = {
  'buyer.firstName': conditionallyRequiredFieldValidationRule(firstNameField),
  'buyer.lastName': conditionallyRequiredFieldValidationRule(lastNameField),
  'buyer.phoneNumber': phoneField(true),
  'buyer.email': emailField(false),
  'buyer.birthDate': birthDate(),
  'buyer.addressDetails.zipCode': zipCodeField(true),
  'buyer.socialSecurityNumber': ssnField(),
  'businessDetails.businessName': businessFieldValidationRule(businessNameField(true)),
  'businessDetails.ein': businessFieldValidationRule(businessEinField()),

  'cobuyer.firstName': cobuyerFieldValidationRule(firstNameField(true)),
  'cobuyer.lastName': cobuyerFieldValidationRule(lastNameField(true)),
  'cobuyer.phoneNumber': cobuyerFieldValidationRule(phoneField(true)),
  'cobuyer.email': cobuyerFieldValidationRule(emailField(true)),
  'cobuyer.birthDate': cobuyerFieldValidationRule(birthDate(true)),
  'cobuyer.coApplicantType': cobuyerFieldValidationRule(requiredField),
  'cobuyer.currentAddressDetails': cobuyerAddressFullValidationRule(addressDataFull()),
  'cobuyer.currentAddressDetails.address': cobuyerFieldValidationRule(requiredField),
  'cobuyer.currentAddressDetails.city': cobuyerFieldValidationRule(requiredField),
  'cobuyer.currentAddressDetails.state': cobuyerFieldValidationRule(requiredField),
  'cobuyer.currentAddressDetails.zipCode': cobuyerFieldValidationRule(requiredField),
  'cobuyer.socialSecurityNumber': cobuyerFieldValidationRule(ssnField())
}

export const DEPENDENT_VALIDATIONS = {
  'buyer.addressDetails.address': ['buyer.addressDetails.zipCode']
}
