import { type FC } from 'react'
import { observer } from 'mobx-react-lite'
import { utils } from '@carfluent/common'

import ImageList from 'components/inventory/ImagesList'
import NoVehicleImagesText from 'components/inventory/NoVehicleImagesText'
import EditModal from 'components/inventory/EditImageModal'
import VideoModal from 'components/inventory/PlayVideoModal'
import FormFilesUpload, { type FormFilesUploadValue } from 'components/common/FormFilesUpload'
import DragHereMessage from 'components/common/DragHereMessage'

import DisabledGalleryTab from './components/DisabledGalleryTab'
import { type UseGalleryTabReturn } from './hook/types'
import CLASS_NAME from './styles'

export type PropsToOmit = 'isTabDirty' | 'isTabSaving' | 'onProcessReset' | 'onProcessSave'

export interface VehicleImagesProps extends Omit<UseGalleryTabReturn, PropsToOmit> {
  isDisabled: boolean
}

const FILE_EXTENSIONS = ['jpeg', 'jpg', 'png', 'mp4', 'mov']
const EMPTY_IMAGE_VALUE: FormFilesUploadValue = []
const MAX_FILE_NUMBER = Infinity

export const VehicleImages: FC<VehicleImagesProps> = ({
  allowedPhotosNum,
  editPhoto,
  isPhotosUploading,
  isDisabled,
  isPlayingVideo,
  onUploadFiles,
  onUploadEditedPhoto,
  onCloseEditModal,
  uploadImageError,
  ...imageListProps
}) => {
  if (isDisabled) {
    return <DisabledGalleryTab images={imageListProps.mediaFiles} />
  }

  return (
    <>
      <FormFilesUpload
        acceptedExtensions={FILE_EXTENSIONS}
        id='files'
        maxFileSize={utils.UNLIMITED_FILES_SIZE}
        maxFilesTotalSize={utils.UNLIMITED_FILES_SIZE}
        onChange={(_, photos) => {
          void onUploadFiles(photos as File[])
        }}
        suggestedExtensions={FILE_EXTENSIONS}
        value={EMPTY_IMAGE_VALUE}
        dragHereMessage={<DragHereMessage />}
        className={CLASS_NAME}
        maxFileNumber={MAX_FILE_NUMBER}
        errorMessage={uploadImageError}
        showError={uploadImageError != null && uploadImageError !== ''}
      >
        <div className='dropzone-container'>
          {(imageListProps.mediaFiles.length > 0)
            ? <ImageList {...imageListProps} images={imageListProps.mediaFiles} />
            : <NoVehicleImagesText title={<>No vehicle media yet<br /> Drag or upload files</>} />}
        </div>
      </FormFilesUpload>

      <EditModal
        isOpen={editPhoto != null && !isPlayingVideo}
        closeModal={onCloseEditModal}
        editPhoto={editPhoto}
        updatePhoto={onUploadEditedPhoto}
      />

      <VideoModal
        onCloseModal={onCloseEditModal}
        isModalOpen={editPhoto != null && isPlayingVideo}
        file={editPhoto}
        onOpenModal={() => {}}
      />
    </>
  )
}

export default observer(VehicleImages)
