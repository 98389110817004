import { useMemo, type FC } from 'react'
import kebabCase from 'lodash-es/kebabCase'

import { TransactionTypeId } from 'api/types'
import { type UseTransactionDetailsReturn } from 'pages/accounting/TransactionDetails/hook'
import { TransactionActionNames } from 'constants/constants'
import Modal from 'components/dialogs/ModalFullscreen'
import FooterActions from 'components/dialogs/FooterActionsFullscreen'
import _isCheck from 'utils/accounting/isCheck'

import TransactionDetails from '../TransactionDetailsContainer'
import { PrintTitle } from '../../hook/constants'

const RECURRING_PAYABLE_TITLE = 'Recurring payable'
const RECURRING_JOURNAL_ENTRY_TITLE = 'Recurring journal entry'

const NewTransactionDialog: FC<UseTransactionDetailsReturn & { className?: string }> = ({
  className,
  isLoading,
  isModalOpen,
  onCloseModal,
  onCreateTransaction,
  onCancel,
  onPrintCheck,
  ...restProps
}) => {
  const isCheck = _isCheck(restProps.transactionTypeId)
  const { formProps } = restProps

  const renderFooterActions = (): JSX.Element => (
    <FooterActions
      onCancel={onCancel}
      onOk={onCreateTransaction}
      onPrint={isCheck ? onPrintCheck : undefined}
      titlePrint={formProps.values.lastPrintDate != null ? PrintTitle.RePrint : PrintTitle.Print}
    />
  )

  const modalTitle = useMemo(() => {
    switch (restProps.transactionTypeId) {
      case TransactionTypeId.JournalEntry: {
        return formProps.isRecurring ? RECURRING_JOURNAL_ENTRY_TITLE : TransactionActionNames.JournalEntry
      }

      case TransactionTypeId.Payable: {
        return formProps.isRecurring ? RECURRING_PAYABLE_TITLE : TransactionActionNames.Payable
      }

      case TransactionTypeId.Receivable: {
        return TransactionActionNames.Receivable
      }

      case TransactionTypeId.Receive: {
        return TransactionActionNames.Receive
      }

      case TransactionTypeId.PayBill: {
        return TransactionActionNames.PayBill
      }

      case TransactionTypeId.Check: {
        return TransactionActionNames.Check
      }

      default: {
        return TransactionActionNames.JournalEntry
      }
    }
  }, [restProps.transactionTypeId, formProps.isRecurring])

  return (
    <Modal
      className={className}
      data-test-id={kebabCase(modalTitle)}
      isFullscreen
      isOpen={isModalOpen}
      onClose={onCloseModal}
      renderFooterActions={renderFooterActions}
      title={modalTitle}
    >
      <TransactionDetails {...restProps} />
    </Modal>
  )
}

export default NewTransactionDialog
