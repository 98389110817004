import { type FC } from 'react'
import { Button, Loader, cnx } from '@carfluent/common'

import { type DocumentFormDto } from 'api/types'
import Icon from 'components/common/Icon'
import Checkbox from 'components/common/CheckboxNoMui'

import CLASS_NAME from './styles'

export interface FormRowItem extends DocumentFormDto {
  uniqId: string
}

export interface FormRowProps extends FormRowItem {
  onDelete?: () => void
  isChecked?: boolean
  onCheck?: () => void
  isDeleting?: boolean
  onView?: (id: string) => Promise<void>
  isViewing?: boolean
}

const FormRow: FC<FormRowProps> = ({
  name,
  description,
  onDelete,
  isChecked,
  onCheck,
  isDeleting = false,
  onView,
  isViewing = false,
  uniqId
}) => {
  return (
    <div className={cnx(CLASS_NAME, 'forms-table-row')}>
      <div className='form-table-row-col-left'>
        <Icon name='drag' />

        {isChecked != null && <Checkbox value={isChecked} onChange={onCheck} />}

        <div className='name-block'>
          <p>{name}</p>
          <p className='name-block-description'>{description}</p>
        </div>
      </div>

      <div className='form-table-row-col-right'>
        <Button
          onClick={() => {
            void onView?.(uniqId)
          }}
          isLoading={isViewing}
          variant='text'
        >
          View
        </Button>

        {isDeleting
          ? <Loader color='dark' size={16} />
          : (
            <Icon
              onClick={onDelete}
              className={cnx('form-delete-icon', onDelete == null && 'disabled')}
              name='close'
            />
            )}
      </div>
    </div>
  )
}

export default FormRow
