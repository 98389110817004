import { type FC } from 'react'

import type { DocumentFormDto } from 'api/types'
import Table from 'components/common/Table'
import SearchInput from 'components/common/SearchInput'
import ModalFullscreen from 'components/dialogs/ModalFullscreen'

import useAddFormModal from './hook'
import FooterActions from './component/FooterActions'
import CLASS_NAME, { TABLE_WRAPPER_CLASS_NAME } from './styles'

interface AddFormModalProps {
  isOpen: boolean
  onClose: () => void
  isSubmitting: boolean
  onAddForms: (forms: DocumentFormDto[]) => void
}

const AddFormModal: FC<AddFormModalProps> = ({
  isOpen,
  onClose,
  onAddForms,
  isSubmitting
}) => {
  const {
    data,
    search,
    columns,
    onSearch,
    onSubmit,
    isLoading,
    amountOfForms,
    onSearchChange,
    onBottomReached,
    emptyTableMessage
  } = useAddFormModal({ onAddForms, isOpen })

  return (
    <ModalFullscreen
      isOpen={isOpen}
      title='Add form'
      onClose={onClose}
      className={CLASS_NAME}
      renderFooterActions={(
        <FooterActions
          onClose={onClose}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          amountOfForms={amountOfForms}
        />
      )}
      keepContentMounted={false}
      dataTestId='add-forms-popup'
      titleDataTestId='add-forms-popup-title'
    >
      <SearchInput
        value={search}
        withDeleteIcon
        onSearch={onSearch}
        isLoading={isLoading}
        className='search-block'
        onChange={onSearchChange}
        dataTestId='add-form-modal'
        placeholder='Search by name, description'
      />

      <Table
        data={data}
        columns={columns}
        isLoading={isLoading}
        onBottomReached={onBottomReached}
        emptyTableMessage={emptyTableMessage}
        classes={{ wrapper: TABLE_WRAPPER_CLASS_NAME }}
      />
    </ModalFullscreen>
  )
}

export default AddFormModal
