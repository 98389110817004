import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGSuitcase: FC<IconProps> = ({ color = '#101010', width = 24, height = 24 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'>
      <path
        d='M20 7H16V5C16 3.89 15.11 3 14 3H10C8.89 3 8 3.89 8 5V7H4C2.89 7 2.01 7.89 2.01 9L2 20C2 21.11 2.89 22 4 22H20C21.11 22 22 21.11 22 20V9C22 7.89 21.11 7 20 7ZM14 7H10V5H14V7Z' 
        fill={color}
      />
    </svg>
  )
}

export default IconSVGSuitcase
