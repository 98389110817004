import { css } from '@emotion/css'

export default css(`
  padding-bottom: 8px;
  margin-bottom: 24px;

  .cf-block-title {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #101010;
  };
`)
