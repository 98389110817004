import { type FC } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { cnx } from '@carfluent/common'

import { joinPaths } from 'utils/route_helper'
import CLASS_NAMES from './styles'

export interface PageSubmenuProps {
  links: LinkConfig[]
  basePath?: string
  isDisabled?: boolean
  isWithoutFooter?: boolean
}

export interface LinkConfig {
  name: string
  url: string
  hidden?: boolean
}

const PageSubmenu: FC<PageSubmenuProps> = ({
  links,
  basePath = '',
  isDisabled = false,
  isWithoutFooter = false
}) => {
  const location = useLocation()

  return (
    <div
      className={cnx(CLASS_NAMES, 'g-submenu-container', isWithoutFooter && 'with-no-actions')}
    >
      {links.map(link => {
        if (link?.hidden === true) {
          return null
        }

        const fullUrl = joinPaths(basePath, link.url)

        return (
          <NavLink
            to={fullUrl}
            key={fullUrl}
            className={cnx('page-submenu-list-item', location.pathname.includes(fullUrl) && 'active', isDisabled && 'disable')}
          >
            <div className='cf-list-item'>
              <span className='g-subtitle-1 text-container'>{link.name}</span>
            </div>
          </NavLink>
        )
      })}
    </div>
  )
}

export default PageSubmenu
