import type { FC, ReactNode } from 'react'
import type { Row } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { CellWrapper, formatInteger } from '@carfluent/common'

import CompleteOrIncomplete from 'components/deals/CompleteOrIncomplete'
import DepositedLabel from 'components/common/DepositedLabel'
import StarIcon from 'components/inlineImages/StarIcon'
import Tooltip from 'components/common/Tooltip'
import TruncatedItemsList from 'components/common/TruncatedItemsList'

import { type ReconVehicleListItemResponseDto } from 'api/types'
import carPlaceholder from 'assets/placeholder_car.svg'
import { VehicleDetails } from 'constants/route_helper'
import { VehicleStateNames } from 'constants/names'

import CLASS_NAME from './styles'

export interface ReconCarCellProps {
  row: Row<ReconVehicleListItemResponseDto>
}

const getStatusLabel = (isSold: boolean, isDeposited: boolean): ReactNode => {
  if (isSold) {
    return <CompleteOrIncomplete isComplete/>
  }

  if (isDeposited) {
    return <DepositedLabel />
  }

  return null
}

const ReconCarCell: FC<ReconCarCellProps> = (props) => {
  const row = props.row.original
  const hasImage = Boolean(row.mainImageUrl)
  const imgUrl = hasImage ? row.mainImageUrl : carPlaceholder
  const isSold = row.vehicleState === VehicleStateNames.Sold

  return (
    <CellWrapper className={CLASS_NAME}>
      <p className='cf-recon-car-header'>
        <Tooltip title={row.isFavorite ? 'High priority' : 'Low priority'}>
          <StarIcon isActive={row.isFavorite} />
        </Tooltip>
        <span>{row.year} {row.make} {row.model}</span>
      </p>

      <div className='recon-car-step'>
        Step: <span>{row.stepName}</span>
      </div>

      <div className='cf-recon-car-content'>
        <img alt='' src={imgUrl ?? ''} className='cf-recon-car-img' />

        <div className='cf-recon-car-specs'>
          <Link
            to={VehicleDetails(row.id)}
            onClick={(event) => event.stopPropagation()}
          >
            {row.stock}
          </Link>
          <span>{row.vin}</span>
          <span>{formatInteger(row.mileage ?? 0)} mi</span>
        </div>
      </div>

      <div className='label-tags-block'>
        {getStatusLabel(isSold, row.isDeposited)}

        <TruncatedItemsList
          maxRows={1}
          items={row.tags}
          className='recon-car-tags'
        />
      </div>
    </CellWrapper>
  )
}

export default ReconCarCell
