import { addMonths } from 'date-fns'
import type { CopyMonthFormData } from 'types'

export const TITLE = 'Copy month'

export const getInitialCopyMonthFormData = (initialSource: Date): CopyMonthFormData => ({
  sourceMonth: initialSource,
  monthToFill: addMonths(initialSource, 1)
})

export enum FieldIds {
  SourceMonth = 'sourceMonth',
  MonthToFill = 'monthToFill'
}
