import { css } from '@emotion/css'
import { ScreenWidthBreakpoints, px } from 'constants/constants'

export default css(`
  .button {
    margin: 16px;

    button {
      word-break: no-break;
    }
  }

  .search-block {
    min-width: 420px;
    max-width: 420px;
    width: 100%;

    .MuiInputBase-root {
      background: #F3F3F3;
      border-radius: 12px;
      box-shadow: none;
      height: 56px;
    }
  }

  .cf-table-header-title p { color: #101010; }

  .cf-no-data-block {
    padding: 22px;
    height: 72px;
  }

  .cf-table-cell {
    .cf-cell-wrapper { 
      height: 64px;
      padding: 20px 8px;
    }

    :first-child,:nth-last-child(2) > .cf-cell-wrapper {
      .cf-table-cell-text { min-width: 215px; width: 14vw; max-width: 300px; }
    }

    :last-child .cf-cell-wrapper { padding-right: 16px; }
  }

  td:nth-of-type(1) {
    width: 25%;
  }

  td:nth-of-type(2) {
    width: 15%;
  }

  td:nth-of-type(3) {
    width: 25%;
  }

  .cf-table-cell:nth-last-child(1) {
    p {
      max-width: 400px;

      @media screen and (max-width: ${px(ScreenWidthBreakpoints.Small)}) {
        max-width: 350px;
      }
    }
  }
`)
