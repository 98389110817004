import { type FC } from 'react'
import { cnx, formatInteger } from '@carfluent/common'
import TooltipedText from 'components/common/TooltipedText'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

import { type NumberIndicatorProps } from './types'
import CLASS_NAME from './styles'

export const EMPTY_FORMAT_VALUE = { emptyValues: '-' }

const NumberIndicator: FC<NumberIndicatorProps> = ({
  className,
  isLoading = false,
  formatter = formatInteger,
  label,
  value,
  percent,
  emptyValue = EMPTY_FORMAT_VALUE
}) => {
  return (
    <BlockSkeleton
      className={cnx(CLASS_NAME, className)}
      isLoading={isLoading}
    >
      <div className='container'>
        <TooltipedText className='label' value={label} />
        <div className='value'>
          {formatter(value, emptyValue)}
          {percent != null && <span className='percent'> ({percent})</span>}
        </div>
      </div>

    </BlockSkeleton>
  )
}

export default NumberIndicator
