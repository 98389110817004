import type { FC } from 'react'
import type { CellProps } from 'types'

import ApplyPropsHOK from 'components/common/ApplyPropsHOC'

export type ConfigurableCellFC<TConf, TData, TValue = any> =
  FC<CellProps<TData, TValue> & TConf>

function ConfigurableCellHOK<TConf, TData = any, TValue = any> (
  Comp: ConfigurableCellFC<TConf, TData, TValue>,
  config: TConf
// ): CellFC<TData, TValue> {
) {
  return ApplyPropsHOK(config, Comp as any) // AZ-TODO: remove any, temporal
}

export default ConfigurableCellHOK
