import type { FC } from 'react'
import { useRef, useState } from 'react'
import { PopoverV2, Button, Loader } from '@carfluent/common'

import CheckboxLabel from 'components/common/CheckboxLabel'

import { VehicleState } from 'api/types'
import type { VehicleCounters } from 'api/types'
import arrowDown from 'assets/arrow_down.svg'

import {
  FilterItemNames,
  POPOVER_OPTIONS,
  ALL_SELECTED_ITEMS,
  ALL_ID,
  DEFAULT_SELECTED_ITEMS
} from './constants'
import { getFilterStatusTitle } from './utils'
import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

interface StatusFilterProps {
  onChange: (filters: VehicleState[]) => Promise<void>
  counters: VehicleCounters
  isLoading: boolean
}

interface GetCounterView {
  counter: number
  value: string
  isLoading: boolean
}

const getCounterView = ({
  value,
  isLoading,
  counter
}: GetCounterView): JSX.Element => {
  return (
    <>
      {value}
      <span>
        {isLoading
          ? <Loader color='dark' size='small' />
          : counter}
      </span>
    </>
  )
}

// TODO-DROPDOWN adapt-dropdown multi-select for adding adornmnet for options and for 'All' selected option
const StatusFilter: FC<StatusFilterProps> = ({
  onChange: _onChange,
  counters,
  isLoading
}) => {
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState<number[]>(DEFAULT_SELECTED_ITEMS)

  const isSelectedAll = selectedItems.length === ALL_SELECTED_ITEMS.length
  const isIntermediate = !isSelectedAll && selectedItems.length > 0
  const title = getFilterStatusTitle(selectedItems)

  const onChange = (id: number) => () => {
    const newItems = [...selectedItems]
    const selectedIndex = newItems.findIndex(item => item === id)

    if (selectedIndex > -1) {
      newItems.splice(selectedIndex, 1)
    } else {
      newItems.push(id)
    }

    void _onChange(newItems)
    setSelectedItems(newItems)
  }

  const onSelectAll = (): void => {
    const newItems = isSelectedAll ? [] : ALL_SELECTED_ITEMS

    void _onChange(newItems)
    setSelectedItems(newItems)
  }

  return (
    <div className={CLASS_NAME}>
      <div className='cf-action-bar'>
        <div className='cf-filter-button-wrapper' ref={anchorEl}>
          <Button
            className='cf-filter-button'
            variant='text'
            onClick={() => setIsOpen(!isOpen)}
            endAdornment={<img src={arrowDown} alt='' />}
          >
            {title}
          </Button>
        </div>
      </div>

      <PopoverV2
        isOpen={isOpen}
        anchorEl={anchorEl}
        className={POPOVER_CLASS_NAME}
        options={POPOVER_OPTIONS}
        onClose={() => setIsOpen(false)}
      >
        <CheckboxLabel
          onChange={onSelectAll}
          value={{
            indeterminate: isIntermediate,
            checked: isSelectedAll,
            name: '',
            icon: getCounterView({
              value: FilterItemNames.All,
              counter: counters.total,
              isLoading
            }),
            id: ALL_ID
          }}
        />

        <div className='cf-filter-items'>
          <CheckboxLabel
            value={{
              checked: selectedItems.includes(VehicleState.Active),
              name: '',
              icon: getCounterView({
                value: FilterItemNames.Active,
                counter: counters.active,
                isLoading
              }),
              id: VehicleState.Active
            }}
            onChange={onChange(VehicleState.Active)}
          />

          <CheckboxLabel
            value={{
              checked: selectedItems.includes(VehicleState.Inactive),
              name: '',
              icon: getCounterView({
                value: FilterItemNames.Inactive,
                counter: counters.inactive,
                isLoading
              }),
              id: VehicleState.Inactive
            }}
            onChange={onChange(VehicleState.Inactive)}
          />

          <CheckboxLabel
            value={{
              checked: selectedItems.includes(VehicleState.Sold),
              name: '',
              icon: getCounterView({
                value: FilterItemNames.Sold,
                counter: counters.sold,
                isLoading
              }),
              id: VehicleState.Sold
            }}
            onChange={onChange(VehicleState.Sold)}
          />

          <CheckboxLabel
            value={{
              checked: selectedItems.includes(VehicleState.Deleted),
              name: '',
              icon: getCounterView({
                value: FilterItemNames.Deleted,
                counter: counters.deleted,
                isLoading
              }),
              id: VehicleState.Deleted
            }}
            onChange={onChange(VehicleState.Deleted)}
          />
        </div>
      </PopoverV2>
    </div>
  )
}

export default StatusFilter
