import { serializers as S } from '@carfluent/common'
import { type ReportPayload } from 'api/types'
import { type Period } from 'utils/filters/types'
import serializeDateFilters from 'utils/filters/serializeDateFilters'
import { isTruthy } from 'utils/general'

/**
 * Serializes startDate-endDate WITH converting to UTC+0.
 * So that date that user see in UI - CAN BE DIFFERENT from what will be sent to BE.
 */
const serializePeriod = (period: Period): ReportPayload => {
  const { startDate, endDate } = period

  const dates = serializeDateFilters({
    from: startDate,
    to: isTruthy(endDate) ? endDate : startDate
  }, S.serializeDateTime)

  return {
    dateFrom: dates?.from ?? null,
    dateTo: dates?.to ?? null
  }
}

export default serializePeriod
