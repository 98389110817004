import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isOk, type Result, useForm, useRefUpdater } from '@carfluent/common'

import { type VehicleDetailsByVin } from 'api/types'
import { type UpdateSalesTaxDto } from 'api/types/requests'
import CustomersCoreApiProvider from 'api/customersCore.api'
import CoverageApiProvider from 'api/coverage.api'
import VehiclesApiProvider from 'api/vehicles.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { isPersonalType } from 'utils/deals/workflowTypes'
import { parseCreditApplicationData } from 'pages/deals/CreditApplication/parser'
import { generateCoverageSectionsData } from 'api/defaults/coverageDefault'
import serializeCoverageDetails, { serializeDefaults } from 'pages/deals/DealDetails/components/WarrantyFormModal/hook/serializer'

import { serializeCreditApplicationData } from './serializer'
import createValidationRules, { DEPENDENT_VALIDATIONS } from './validation'
import type {
  CreditApplicationFormData,
  ErrTouchShortcuts,
  ErrTypeMap,
  UseCreditAppFormProps,
  UseCreditAppFormReturn
} from '../../../types'

export enum Notifications {
  ValidationError = 'Please check your inputs.',
  SaveSuccess = 'Changes successfully saved.',
  SendSuccess = 'Credit application successfully sent.'
}

function useCreditAppForm ({
  creditAppData,
  dealId,
  feesAndCoveragesSettings,
  isTradeInSectionVisible,
  isWarrantySectionVisible,
  getCreditApplicationData,
  isCoApplicantSectionVisible,
  onToggleIsWarrantySectionVisible: _onToggleIsWarrantySectionVisible
}: UseCreditAppFormProps): UseCreditAppFormReturn {
  const navigate = useNavigate()
  const { showAlert } = useCustomSnackbar()

  const [isCloseDialogVisible, setCloseDialogVisible] = useState(false)
  const [apiErrors, setApiErrors] = useState<Record<string, any> | null>(null)

  const isBusiness = !isPersonalType(creditAppData.workflowTypeId)

  const refWorkflowRowVersion = useRef(creditAppData.rowVersion)
  const refIsSubmitting = useRef(false)
  const refSubmitSuccessMessage = useRef<string>(Notifications.SendSuccess)

  const onActionResult = useCallback((res: Result<string>): void => {
    setCloseDialogVisible(false)

    if (isOk<string>(res)) {
      showAlert(refSubmitSuccessMessage.current, { variant: 'success' })
      if (refIsSubmitting.current) {
        navigate(-1)
      }
    } else {
      const response = (res.result as any)?.response
      setApiErrors(response?.data ?? null)
    }
  }, [navigate, showAlert])

  const validationRules = useMemo(() => {
    return createValidationRules({
      isBusiness,
      isSubmitting: false,
      isTradeInSectionVisible,
      isWarrantySectionVisible,
      isCoApplicantSectionVisible
    })
  }, [isBusiness, isTradeInSectionVisible, isWarrantySectionVisible, isCoApplicantSectionVisible])

  const {
    values,
    errors,
    touched,
    isSubmitting,
    isFormChanged,
    resetForm,
    onChange,
    onBlur,
    onSubmit: _onSubmit,
    setFieldTouched,
    setTouched,
    validateForm
  } = useForm<CreditApplicationFormData, ErrTouchShortcuts, never, ErrTypeMap>({
    baseValues: creditAppData,
    dependentValidations: DEPENDENT_VALIDATIONS,
    onActionResult,
    validationRules
  })

  const isFormDisabled = values.isCreditApplicationSubmitted

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const refTouched = useRefUpdater(touched)
  const onToggleDealerProduct = useCallback((productTypeId: number, isNextChecked: boolean) => {

    const {
      addedProducts,
      initCoverageDetails,
      coverageDetails,
      penProducts
    } = values.coverageData

    const {
      coverageDetails: coverageDetailsTouched
    } = refTouched.current.coverageData

    if (!isNextChecked) {
      const coverageIdx = coverageDetails
        .findIndex(el => el.productTypeId === productTypeId)

      if (coverageIdx > -1) {
        const nextCoverageDetailsTouched = [...coverageDetailsTouched]
        nextCoverageDetailsTouched.splice(coverageIdx, 1)

        setTouched({
          ...refTouched.current,
          coverageData: {
            ...refTouched.current.coverageData,
            coverageDetails: nextCoverageDetailsTouched as unknown as typeof touched['coverageData']['coverageDetails']
          }
        })
      }

      onChange('coverageData.addedProducts', addedProducts.filter(el => el !== productTypeId))
      onChange('coverageData.coverageDetails', coverageDetails.filter(el => el.productTypeId !== productTypeId))
    } else {
      onChange('coverageData.addedProducts', [...addedProducts, productTypeId])

      const isPenEnabled = penProducts[productTypeId]?.some(el => el.coverages.length)
      const defaultCoverage = generateCoverageSectionsData('', productTypeId)
      const defaultCoverageType = isPenEnabled ? 'PEN' : 'Manual'

      const initCoverage = initCoverageDetails.find(el => el.productTypeId === productTypeId) ??
      ({
        ...generateCoverageSectionsData('', productTypeId),
        productTypeId,
        coverageType: isPenEnabled ? 'PEN' : 'Manual',
        isPenEnabled,
        forms: {
          ...defaultCoverage.forms,
          [defaultCoverageType]: {
            ...defaultCoverage.forms[defaultCoverageType],
            productTypeId,
            isFormDisabled: false
          }
        }
      })

      onChange('coverageData.coverageDetails', [...coverageDetails, initCoverage].sort((a, b) => {
        return a.productTypeId - b.productTypeId
      }))
    }

    /**
     * OP-NOTE: see:
     * 1. https://dev.azure.com/carfluent/CarFluent/_workitems/edit/12084
     * 2. https://dev.azure.com/carfluent/CarFluent/_workitems/edit/13877
     */
    setTimeout(validateForm, 0)
  }, [
    onChange,
    values.coverageData,
    setTouched,
    validateForm
  ])

  const onToggleIsWarrantySectionVisible = useCallback(() => {
    _onToggleIsWarrantySectionVisible()

    setTouched({
      ...refTouched.current,
      coverageData: {
        ...refTouched.current.coverageData,
        coverageDetails: []
      }
    })

    setTimeout(() => {
      onChange('coverageData', {
        ...values.coverageData,
        addedProducts: [],
        coverageDetails: []
      })
    }, 500) // to avoid blinking of hiding forms in block, that is closed with animation.
  }, [
    _onToggleIsWarrantySectionVisible,
    onChange,
    values.coverageData.initCoverageDetails,
    setTouched
  ])

  const onSaveCoverageData = useCallback(async (): Promise<void> => {
    const payloadDefault = serializeDefaults(values.coverageData.initCoverageDetails.map(el => {
      const updatedCoverage = values.coverageData.coverageDetails.find(item => item.productTypeId === el.productTypeId)
      if (updatedCoverage != null) {
        return updatedCoverage
      }

      return el
    }))

    const { items: dealDefaults } = await CoverageApiProvider.updateProductsDealDefaults({
      data: { coverageDefaults: payloadDefault },
      dealId: dealId ?? 0
    })
    const payload = serializeCoverageDetails(values.coverageData.addedProducts, dealId ?? '', refWorkflowRowVersion.current ?? '', dealDefaults)

    const { rowVersion } = await CustomersCoreApiProvider.updateCoverage(payload)
    await getCreditApplicationData(false)
    refWorkflowRowVersion.current = rowVersion as string
  }, [values])

  const onSendForm = useCallback(async () => {
    const action = async (values: CreditApplicationFormData): Promise<void> => {
      const rowVersion = refWorkflowRowVersion.current
      const data = serializeCreditApplicationData(values, rowVersion, isCoApplicantSectionVisible, isTradeInSectionVisible)

      refIsSubmitting.current = true
      refSubmitSuccessMessage.current = Notifications.SendSuccess
      const newData = await CustomersCoreApiProvider.patchDealForCreditApp({ dealId, rowVersion, data })

      refWorkflowRowVersion.current = newData.rowVersion
      await onSaveCoverageData()

      resetForm(parseCreditApplicationData(newData, feesAndCoveragesSettings, values.coverageData))

      if (newData.rowVersion == null) {
        return
      }

      await CustomersCoreApiProvider.submitCreditApplication({
        dealId,
        rowVersion: newData.rowVersion
      })
    }

    const validationRules = createValidationRules({
      isBusiness,
      isSubmitting: true,
      isTradeInSectionVisible,
      isWarrantySectionVisible,
      isCoApplicantSectionVisible
    })

    await _onSubmit(undefined, validationRules, action)
  }, [
    dealId,
    isBusiness,
    isTradeInSectionVisible,
    isWarrantySectionVisible,
    isCoApplicantSectionVisible,
    feesAndCoveragesSettings,
    resetForm,
    _onSubmit,
    onSaveCoverageData
  ])

  const onSaveForm = useCallback(async (): Promise<void> => {
    const action = async (values: CreditApplicationFormData): Promise<void> => {
      const rowVersion = refWorkflowRowVersion.current
      const data = serializeCreditApplicationData(
        values,
        rowVersion,
        isCoApplicantSectionVisible,
        isTradeInSectionVisible
      )

      refSubmitSuccessMessage.current = Notifications.SaveSuccess
      refIsSubmitting.current = false
      const newData = await CustomersCoreApiProvider.patchDealForCreditApp({ dealId, rowVersion, data })

      refWorkflowRowVersion.current = newData.rowVersion
      await onSaveCoverageData()

      resetForm(parseCreditApplicationData(newData, feesAndCoveragesSettings, values.coverageData))
    }

    await _onSubmit(undefined, undefined, action)
  }, [
    dealId,
    isCoApplicantSectionVisible,
    isTradeInSectionVisible,
    resetForm,
    _onSubmit,
    isWarrantySectionVisible,
    onSaveCoverageData
  ])

  const onApplyVin = useCallback(async (vin: string): Promise<VehicleDetailsByVin | null> => {
    try {
      const data = await VehiclesApiProvider.getVehicleByVin(vin)
      onChange('tradeInDetails', {
        ...values.tradeInDetails,
        tradeInVehicleMake: data.make,
        tradeInVehicleModel: data.model,
        tradeInVehicleYear: data.year,
        tradeInVehicleTrim: data.trim
      })
      return data
    } catch {
      showAlert('Error while loading trade in details.')
      return null
    }
  }, [onChange, values.tradeInDetails, showAlert])

  const onRequestCloseForm = useCallback(() => {
    if (isFormChanged()) {
      setCloseDialogVisible(true)
    } else {
      navigate(-1)
    }
  }, [isFormChanged, navigate])

  const onHideCloseDialog = useCallback(() => {
    setCloseDialogVisible(false)
  }, [])

  const onCloseForm = useCallback(() => {
    setCloseDialogVisible(false)
    navigate(-1)
  }, [navigate])

  const onGoBack = useCallback(() => {
    if (isFormDisabled && isFormChanged()) {
      onRequestCloseForm()
    } else {
      navigate(-1)
    }
  }, [isFormChanged, isFormDisabled, navigate, onRequestCloseForm])

  const onSubmitSalesTax = useCallback(async (data: UpdateSalesTaxDto): Promise<void> => {
    const nextDeal = await CustomersCoreApiProvider.patchDealSalesTax({
      dealId,
      data,
      rowVersion: refWorkflowRowVersion.current ?? ''
    })

    resetForm({
      ...values,
      overridenSalesTaxPercent: nextDeal.dealFees.overridenSalesTaxPercent ?? null,
      overridenSalesTaxAmount: nextDeal.dealFees.overridenSalesTaxAmount ?? 0,
      isManualSalesTax: nextDeal.dealFees.overridenSalesTaxPercent == null,
      rowVersion: nextDeal.rowVersion ?? ''
    })
  }, [dealId, values, resetForm])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    refWorkflowRowVersion.current = creditAppData.rowVersion
  }, [creditAppData.rowVersion])

  // ========================================== //

  return {
    values,
    errors,
    touched,
    apiErrors,
    isCloseDialogVisible,
    isSubmitting,
    isFormDisabled,
    isFormChanged,
    onChange,
    onBlur,
    onGoBack,
    onSaveForm,
    onSendForm,
    onRequestCloseForm,
    onCloseForm,
    onHideCloseDialog,
    onApplyVin,
    onSubmitSalesTax,
    onToggleDealerProduct,
    onToggleIsWarrantySectionVisible,
    setFieldTouched
  }
}

export default useCreditAppForm
