import type { FC } from 'react'
import { cnx } from '@carfluent/common'
import StarIcon from 'components/inlineImages/StarIcon'
import Tooltip from 'components/common/Tooltip'
import CLASS_NAME, { TOOLTIP_CLASS_NAME } from './styles'

const HIGH_PRIORITY = 'High Priority'
const LOW_PRIORITY = 'Low Priority'

interface PriorityProps {
  onUpdateFavorite: (isFavorite: boolean) => Promise<void>
  isFavorite: boolean
}

const Priority: FC<PriorityProps> = ({
  isFavorite,
  onUpdateFavorite
}) => {
  const tooltipTitle = isFavorite ? HIGH_PRIORITY : LOW_PRIORITY

  return (
    <div className={cnx(CLASS_NAME, isFavorite && 'is-active')}>
      <Tooltip
        key={tooltipTitle}
        title={tooltipTitle}
        popperClassName={TOOLTIP_CLASS_NAME}
      >
        <StarIcon
          className='cf-priority-main-img'
          onClick={async () => {
            void onUpdateFavorite(!isFavorite)
          }}
          isActive={isFavorite}
        />
      </Tooltip>
    </div>
  )
}

export default Priority
