import { type FC } from 'react'
import { Loader, Modal } from '@carfluent/common'

import { MODAL_MIN_WIDTH } from 'constants/constants'
import Table from 'components/common/Table'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import ModalFullscreen from 'components/dialogs/ModalFullscreen'

import LeadDetailsForm from './LeadDetailsForm'
import { FormTitle } from './hook/constants'
import type { UseLeadDetailsModalProps } from './hook/types'
import useLeadDetailsForm from './hook'

import CLASS_NAME from './styles'

const LeadDetailsFormModal: FC<UseLeadDetailsModalProps> = (props) => {
  const {
    isFormSubmitting,
    isOpen,
    isUpdate,
    assignedTo,
    temperatures,
    sources,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onClose,
    onSubmit,
    tableProps: {
      isLoading,
      counter,
      columns,
      rows
    }
  } = useLeadDetailsForm(props)

  const renderFooter = (): JSX.Element => {
    return (
      <CancelSubmitActionsFooter
        isLoading={isFormSubmitting}
        onSubmit={onSubmit}
        onClose={onClose}
        submitTitle={isUpdate
          ? 'SAVE'
          : counter > 0
            ? 'ADD ANYWAY'
            : 'ADD'}
      />
    )
  }

  const ModalComponent = isUpdate ? Modal : ModalFullscreen

  return (
    <ModalComponent
      className={CLASS_NAME}
      isOpen={isOpen}
      title={isUpdate ? FormTitle.UpdateLead : FormTitle.NewLead}
      keepContentMounted={false}
      minWidth={MODAL_MIN_WIDTH}
      onClose={onClose}
      renderFooterActions={renderFooter}
      dataTestId='lead-popup-create-update'
      titleDataTestId='lead-title-create-update'
    >
      <LeadDetailsForm
        values={values}
        errors={errors}
        touched={touched}
        temperatures={temperatures}
        sources={sources}
        assignedTo={assignedTo}
        onChange={onChange}
        onBlur={onBlur}
      />

      {!isUpdate && isLoading && counter === 0 && (
        <div className='loader-section'>
          <Loader size={32} />
          <p> Searching for duplicates </p>
        </div>)}

      {!isUpdate && counter > 0 &&
        (
          <div className='existing-leads-table-wrapper'>
            <div className='header-section'>
              <h5>Possible existing leads</h5>
              {isLoading
                ? <Loader color='dark' size='medium' />
                : <h5>({counter})</h5>}
            </div>

            <Table
              isLoading={isLoading}
              columns={columns}
              data={rows}
              headerDataTestId='existing-leads-table-header'
              bodyDataTestId='existing-leads-table-body'
            />
          </div>
        )}
    </ModalComponent>
  )
}

export default LeadDetailsFormModal
