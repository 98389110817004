import { ColumnDef } from '@carfluent/common/dist/UI'

import TextCellHOC from 'components/common/Table/cells/TextCell'
import ValueCell from 'components/common/Table/cells/ValueCell'
import DateCell from 'components/common/Table/cells/DateCell'

export interface HistoryRow {
  enteredOn: Date
  step: string
  timeInStep: string
  changedBy: string
}

export const columnDefinitions: Array<ColumnDef<HistoryRow>> = [
  {
    accessorKey: 'enteredOn',
    cell: DateCell,
    header: TextCellHOC({ text: 'Entered on' }),
    sortable: false
  },
  {
    accessorKey: 'step',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Step' }),
    sortable: false
  },
  {
    accessorKey: 'timeInStep',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Time in step' }),
    sortable: false,
    enableResizing: true
  },
  {
    accessorKey: 'changedBy',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Changed by' }),
    sortable: false
  }
]
