import { css } from '@emotion/css'

export default css(`
  display: flex;
  align-items: center;
  margin-left: 8px;
  
  .title {
    color: rgba(33, 33, 33, 0.80);
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-right: 4px;
  }
  
  .account-dropdown {
    width: max-content;
    
    &.is-loading .cf-select-display-container {
      cursor: not-allowed;
      
      svg {
        cursor: not-allowed;
      }
    }

    .cf-select-display-container {
      background: #F3F3F3;
      border: none;
      border-radius: 8px;
      padding: 8px;
      display: flex;
      align-items: center;
      max-width: max-content;
      height: auto;
      width: max-content;
      
      .close-icon-wrapper {
        padding-top: 4px;
      }

      .loader {
        margin-left: 4px;

        .cf-animated-wrapper {
          width: 16px;
          height: 16px;
        }

        svg {
          margin: unset;
          width: auto;
          height: auto;
        }
      }
    }
  }

`)

export const POPOVER_CLASS_NAME = css(`
  min-width: 320px;
`)
