import type { DictionaryItem, SalespersonDto } from 'api/types'
import { joinPartsBySpace } from 'utils/view_helper'

const namifySalesperson = (user: SalespersonDto | null): (SalespersonDto & DictionaryItem<string>) | null => {
  if (user == null) {
    return null
  }

  return {
    ...user,
    name: joinPartsBySpace(user.dealerFirstName, user.dealerLastName)
  }
}

export default namifySalesperson
