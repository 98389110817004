import { type FC, useCallback } from 'react'

import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { TaskCompleteOptions, TaskMode } from 'pages/crm/LeadDetailsView/hook/types'
import EditDeletePopover from 'pages/crm/LeadDetailsView/components/EditDeletePopover'
import { Messages } from 'pages/crm/LeadDetailsView/components/LeadDetailsTasks/components/TaskComplete/hook/constants'
import CreatedCompleteLayout, { type CreatedCompleteLayoutProps } from 'components/crm/CreatedCompleteLayout'
import ActionBar from './components/ActionBar'
import type { MessageReceivedTaskFormData } from './types'

import CLASS_NAME from './styles'

interface MessageReceivedTaskProps {
  completeLayoutProps: CreatedCompleteLayoutProps
  taskMode: TaskMode | null
  taskId: number
  onSubmit: (values: MessageReceivedTaskFormData, taskId: number) => Promise<void>
  onCloseTask: (taskId: number | null, taskMode?: TaskMode) => void
  onEditTaskClick: (taskId: number) => void
  onDeleteTaskClick: (taskId: number) => void
  onOpenViewChatPopover: () => void
}

const MessageReceivedTask: FC<MessageReceivedTaskProps> = ({ completeLayoutProps, ...props }) => {
  const {
    taskId,
    taskMode,
    onEditTaskClick,
    onDeleteTaskClick,
    onSubmit: _onSubmit,
    onCloseTask,
    onOpenViewChatPopover
  } = props
  const { showSuccess } = useCustomSnackbar()

  const onSubmit = useCallback(async (taskCompleteOptionId: TaskCompleteOptions) => {
    try {
      onCloseTask?.(taskId, taskMode as TaskMode)
      await _onSubmit({ taskCompleteOptionId }, taskId)
      showSuccess(Messages.SuccessTaskComplete)
    } catch (error) {
      // DO NOTHING
    }
  }, [
    onCloseTask, _onSubmit,
    showSuccess, taskId, taskMode
  ])

  return (
    <div className={CLASS_NAME}>
      <EditDeletePopover
        onDeleteTaskClick={onDeleteTaskClick}
        onEditTaskClick={onEditTaskClick}
        taskId={taskId}
      />
      <CreatedCompleteLayout {...completeLayoutProps} showActionBar={false} />

      <div className='lead-tasks-action-buttons-container'>
        <ActionBar
          taskId={taskId}
          buttonsClassName='lead-tasks-action-buttons'
          taskMode={taskMode}
          onSubmit={onSubmit}
          isLoading={false}
          onViewChat={onOpenViewChatPopover}
        />
      </div>
    </div>
  )
}

export default MessageReceivedTask
