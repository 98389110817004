import { type FC } from 'react'
import { cnx, Loader } from '@carfluent/common'

import CreditApplicationPageContent from './components/CreditApplicationContent'
import useCreditPageApp from './hook'
import CLASS_NAME from './styles'

const CreditApplicationPage: FC = () => {
  const {
    dealId,
    feesAndCoveragesSettings,
    isLoading,
    isTradeInSectionVisible,
    isWarrantySectionVisible,
    isCoApplicantSectionVisible,
    creditAppData,
    employmentStatuses,
    housingStatuses,
    coApplicantTypes,
    wholesaleSourceTypes,
    businessTypes,
    getCreditApplicationData,
    onToggleIsTradeInSectionVisible,
    onToggleIsWarrantySectionVisible,
    onToggleIsCoApplicantSectionVisible
  } = useCreditPageApp()

  return (
    <div className={cnx(CLASS_NAME, 'card-content credit-application-page')}>
      {isLoading && <Loader size='large' />}

      <CreditApplicationPageContent
        dealId={dealId}
        feesAndCoveragesSettings={feesAndCoveragesSettings}
        isTradeInSectionVisible={isTradeInSectionVisible}
        isWarrantySectionVisible={isWarrantySectionVisible}
        isCoApplicantSectionVisible={isCoApplicantSectionVisible}
        creditAppData={creditAppData}
        employmentStatuses={employmentStatuses}
        housingStatuses={housingStatuses}
        coApplicantTypes={coApplicantTypes}
        wholesaleSourceTypes={wholesaleSourceTypes}
        businessTypes={businessTypes}
        getCreditApplicationData={getCreditApplicationData}
        onToggleIsTradeInSectionVisible={onToggleIsTradeInSectionVisible}
        onToggleIsWarrantySectionVisible={onToggleIsWarrantySectionVisible}
        onToggleIsCoApplicantSectionVisible={onToggleIsCoApplicantSectionVisible}
      />
    </div>
  )
}

export default CreditApplicationPage
