import { RefObject, useCallback, useEffect, useRef } from 'react'

import useToggler from './useToggler'

const DEFAULT_TOOLTIP_DELAY = 500
const DEFAULT_NUMBER_OF_ROWS = 1

interface UseEllipsisHoverProps {
  tooltipDelay?: number
  numberOfRows?: number
}

interface UseEllipsisHoverReturn {
  isShowTooltip: boolean
  titleRef: RefObject<HTMLDivElement>
  onMouseEnter: () => void
  onMouseLeave: () => void
}

function useEllipsisHover ({
  tooltipDelay = DEFAULT_TOOLTIP_DELAY,
  numberOfRows = DEFAULT_NUMBER_OF_ROWS
}: UseEllipsisHoverProps = {}): UseEllipsisHoverReturn {
  const {
    value: isTitleHover,
    enable: enableHover,
    disable: disableHover
  } = useToggler(false)

  const {
    value: isShowTooltip,
    setValue: setIsShowTooltip
  } = useToggler(false)

  const titleRef = useRef<HTMLDivElement>(null)
  const hasToBeShown = useRef(true)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onMouseEnter = useCallback(() => {
    hasToBeShown.current = true

    setTimeout(() => {
      if (hasToBeShown.current) {
        enableHover()
      }
    }, tooltipDelay)
  }, [enableHover, tooltipDelay])

  const onMouseLeave = useCallback(() => {
    hasToBeShown.current = false
    disableHover()
  }, [disableHover])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Detects that text is elipsis
   */
  useEffect(() => {
    if (titleRef?.current != null) {
      const isOverflowedWidth = Math.round(titleRef.current.offsetWidth) < Math.round(titleRef?.current.scrollWidth)
      /** OP-NOTE:
       * use overflow height for ellipsis title with more than one line
       */
      const isOverflowedHeight = Math.round(titleRef.current.offsetHeight) < Math.round(titleRef?.current.scrollHeight)

      setIsShowTooltip((numberOfRows > 1 ? isOverflowedHeight : isOverflowedWidth) && isTitleHover)
    }
  }, [isTitleHover, numberOfRows])

  // ========================================== //

  return {
    isShowTooltip,
    titleRef,
    onMouseEnter,
    onMouseLeave
  }
}

export default useEllipsisHover
