import { type FC } from 'react'
import { FormInput, FormMaskedInput, FormDropdown, FormDatePicker } from '@carfluent/common'

import type { DictionaryItem } from 'api/types'
import { COMMON_MODAL_FORM_CLS } from 'constants/constants'
import NameWithAvatar from 'components/common/NameWithAvatar'
import AddressPicker from 'components/form/v2/FormAddressPicker'
import { renderAssignOption, renderNoUsersOption } from 'components/crm/common'

import type { UseLeadDetailsFormProps } from './hook/types'
import { FieldIds } from './hook/constants'

const LeadDetailsForm: FC<UseLeadDetailsFormProps> = ({
  values,
  errors,
  touched,
  temperatures,
  sources,
  assignedTo,
  onChange,
  onBlur
}) => {
  return (
    <div className={COMMON_MODAL_FORM_CLS}>
      <FormInput
        id={FieldIds.FirstName}
        label='First name'
        onChange={onChange}
        onBlur={onBlur}
        value={values[FieldIds.FirstName]}
        error={errors[FieldIds.FirstName]}
        touched={touched[FieldIds.FirstName]}
        dataTestId='lead-field-firstname'
      />

      <FormInput
        id={FieldIds.LastName}
        label='Last name'
        onChange={onChange}
        onBlur={onBlur}
        value={values[FieldIds.LastName]}
        error={errors[FieldIds.LastName]}
        touched={touched[FieldIds.LastName]}
        dataTestId='lead-field-lastname'
      />

      <FormDatePicker
        id={FieldIds.BirthDate}
        label='Date of birth (optional)'
        onChange={onChange}
        onBlur={onBlur}
        value={values[FieldIds.BirthDate]}
        error={errors[FieldIds.BirthDate]}
        touched={touched[FieldIds.BirthDate]}
      />

      <FormInput
        id={FieldIds.Email}
        label='Email'
        onChange={onChange}
        onBlur={onBlur}
        value={values[FieldIds.Email]}
        error={errors[FieldIds.Email]}
        touched={touched[FieldIds.Email]}
        dataTestId='lead-field-email'
      />

      <FormMaskedInput
        id={FieldIds.PhoneNumber}
        label='Phone number'
        mask='phone'
        onChange={onChange}
        onBlur={onBlur}
        value={values[FieldIds.PhoneNumber]}
        error={errors[FieldIds.PhoneNumber]}
        touched={touched[FieldIds.PhoneNumber]}
        dataTestId='lead-field-phonenumber'
      />

      <FormDropdown<DictionaryItem>
        className='source-field'
        id={FieldIds.Source}
        label='Source'
        options={sources}
        onChange={onChange}
        onBlur={onBlur}
        blurMode='select-first'
        value={values[FieldIds.Source]}
        error={errors[FieldIds.Source]}
        touched={touched[FieldIds.Source]}
        dataTestId='lead-field-source'
      />

      <AddressPicker
        id={FieldIds.AddressData}
        label='Address (optional)'
        className='address-fields'
        onChange={onChange}
        onBlur={onBlur}
        value={values[FieldIds.AddressData]}
        error={errors[FieldIds.AddressData]}
        dataTestId='lead-field-address'
      />

      <FormDropdown<DictionaryItem>
        id={FieldIds.Temperature}
        label='Temperature'
        options={temperatures}
        disableClearable
        onChange={onChange}
        onBlur={onBlur}
        value={values[FieldIds.Temperature]}
        error={errors[FieldIds.Temperature]}
        touched={touched[FieldIds.Temperature]}
        dataTestId='lead-field-temperature'
      />

      <FormDropdown<DictionaryItem>
        id={FieldIds.AssignedTo}
        label='Lead owner'
        blurMode='select-first'
        options={assignedTo}
        renderOption={renderAssignOption}
        renderNoOptions={renderNoUsersOption}
        startAdornment={<NameWithAvatar showAvatar avatarOnly name={values[FieldIds.AssignedTo]?.name} />}
        onChange={onChange}
        onBlur={onBlur}
        value={values[FieldIds.AssignedTo]}
        error={errors[FieldIds.AssignedTo]}
        touched={touched[FieldIds.AssignedTo]}
        dataTestId='lead-field-assignedTo'
      />
    </div>
  )
}

export default LeadDetailsForm
