import { cn, UI } from '@carfluent/common'

import type { Vehicle } from 'api/types/vehicles.types'
import useStyles from './styles'

const { GroupContentHOC } = UI

const GroupContent = GroupContentHOC<Vehicle, string>((group) => {
  const styles = useStyles()

  return (
    <div className={styles.contentWrapper}>
      <p className={styles.textMake}>{group.meta}</p>
      <p className={cn(styles.textCounter, 'cf-text-counter')}>
        ({group.rows.length})
      </p>
    </div>
  )
})

export default GroupContent
