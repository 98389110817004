import { useRefUpdater } from '@carfluent/common'

import useTransitionBlocker from 'hooks/useTransitionBlocker'

interface UseBlockerProps {
  shouldGeneralBlock: boolean
  shouldDocumentsBlock: boolean
  onBlockGeneral: () => void
  onBlockDocuments: () => void
}

interface UseBlockerReturn {
  proceedTransition: () => void
  resetTransition: () => void
}

export const useBlocker = ({
  shouldGeneralBlock,
  shouldDocumentsBlock,
  onBlockGeneral,
  onBlockDocuments
}: UseBlockerProps): UseBlockerReturn => {
  const generalBlockRef = useRefUpdater(shouldGeneralBlock)
  const documentsBlockRef = useRefUpdater(shouldDocumentsBlock)

  const { proceedTransition, resetTransition } = useTransitionBlocker({
    shouldBlock: shouldGeneralBlock || shouldDocumentsBlock,
    onBlock: () => {
      if (generalBlockRef.current) {
        onBlockGeneral()
      } else if (documentsBlockRef.current) {
        onBlockDocuments()
      }
    }
  })

  return { proceedTransition, resetTransition }
}
