import type { FC } from 'react'

import CLASS_NAME from './styles'

interface TotalColumnProps {
  value?: string | number
  label: string
}

const TotalColumn: FC<TotalColumnProps> = ({ value = '-', label }) => {
  return (
    <div className={CLASS_NAME}>
      <div className='col-title'>
        {label}
      </div>

      <div className='col-value'>
        {value}
      </div>
    </div>
  )
}

export default TotalColumn
