import type { DictionaryItem } from 'api/types'

const DEFAULT_GET_YEARS_PROPS = {
  from: 1991,
  to: new Date().getFullYear()
}

export interface GetYearsForDropdownFieldProps {
  from: number
  to: number
}

export const getYearsForDropdownFieldAsNumbers = ({
  from,
  to
}: GetYearsForDropdownFieldProps = DEFAULT_GET_YEARS_PROPS): number[] => {
  if (from >= to) {
    return []
  }

  const dictionary: number[] = []
  let firstEl: number | null = null

  while (firstEl !== from) {
    if (firstEl == null) {
      firstEl = to
      dictionary.push(firstEl)
    } else {
      const prevYear: number = firstEl - 1

      firstEl = prevYear
      dictionary.push(firstEl)
    }
  }

  return dictionary
}

// @deprecated
export const getYearsForDropdownField = ({
  from,
  to
}: GetYearsForDropdownFieldProps = DEFAULT_GET_YEARS_PROPS): DictionaryItem[] => {
  if (from >= to) {
    return []
  }

  const dictionary: DictionaryItem[] = []
  let firstEl: DictionaryItem | null = null

  while (firstEl?.id !== from) {
    if (firstEl == null) {
      firstEl = { id: to, name: to.toString() }
      dictionary.push(firstEl)
    } else {
      const prevYear: number = firstEl.id - 1

      firstEl = { id: prevYear, name: prevYear.toString() }

      dictionary.push(firstEl)
    }
  }

  return dictionary
}
