import { css } from '@emotion/css'

export default css(`
  --row-margin: 24px;
  --min-input-width: 189px;

  background-color: #fff;
  padding: var(--row-margin) 16px;

  > h6 {
    margin-bottom: 16px;
  }

  hr {
    margin: 24px 0px;
    border: 0px;
    border-top: 1px solid #0000001F;
  }

  .address-fields-component {
    padding: 0px;
    grid-column: span 5;
    grid-template-columns: repeat(5, minmax(var(--min-input-width), 1fr));
    grid-template-areas: 'address apt city state zipCode';
  }

  .tab-buyer-form {
    display: grid;
    grid-template-columns: repeat(5, minmax(var(--min-input-width), 1fr));
    grid-gap: var(--row-margin) 16px;
  }

  .tab-cobuyer-form .cf-collapse {
    display: grid;
    grid-template-rows: auto auto;

    .cf-collapse-header {
      h3 {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.15px;
        cursor: pointer;

        &.disabled-toggle {
          cursor: default;
          color: #21212140;
        }
      }

      button {
        height: 26px;
        width: 174px;
        border-radius: 8px;

        span {
          &.cf-button-content {
            position: absolute;
          }

          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
          letter-spacing: 1.25px;
        }
      }
    }

    .cf-collapse-section {
      display: grid;
      grid-template-columns: repeat(5, minmax(var(--min-input-width), 1fr));
      grid-gap: var(--row-margin) 16px;
      margin-top: 16px;

      .cobuyer-same-address {
        grid-column: span 5;
        width: max-content;
      }
    }
  }

  @media (min-width: 1441px) {
    .collspan-2 {
      grid-column: span 2;
    }

    .address-fields-component {
      grid-column: span 6;
      grid-template-columns: repeat(6, minmax(var(--min-input-width), 1fr));
      grid-template-areas: 'address address apt city state zipCode';
    }

    .tab-buyer-form {
      grid-template-columns: repeat(6, minmax(var(--min-input-width), 1fr));
    }

    .tab-cobuyer-form .cf-collapse .cf-collapse-section {
      grid-template-columns: repeat(6, minmax(var(--min-input-width), 1fr));

      .cobuyer-same-address {
        grid-column: span 5;
      }
    }
  }
`)
