import type { FC } from 'react'

import getColorForString from 'utils/getColorForString'
import { AVATAR_COLORS, DEFAULT_AVATAR_COLOR } from 'constants/constants'

import type { SummaryChipProps } from 'components/crm/Chips/types'
import Chip from 'components/crm/Chips'

import CLASS_NAME from './styles'

const SummaryChip: FC<SummaryChipProps> = ({ summaryShift }) => {
  const { userName, totalHoursPerMonth, totalShifts } = summaryShift

  const shiftInfo = `${totalHoursPerMonth} h/mo | ${totalShifts} shifts`

  const shiftColorStyle = {
    backgroundColor: getColorForString(userName, AVATAR_COLORS, DEFAULT_AVATAR_COLOR)
  }

  return (
    <div className={CLASS_NAME}>
      <Chip
        shiftColorStyle={shiftColorStyle}
        userName={userName}
        shiftInfo={shiftInfo}
      />

      <p className='cf-shift-total'>{shiftInfo}</p>
    </div>
  )
}

export default SummaryChip
