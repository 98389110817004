import {
  type ChangeEvent,
  type MouseEvent,
  type KeyboardEvent,
  useCallback,
  useState,
  useEffect
} from 'react'

import type { FCHook } from 'types'
import { ViewDealerRoles } from 'api/types'
import { isEmailValid } from 'utils/validation'
import mapToCheckboxes from 'utils/mapToCheckboxes'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import type { UseInviteUserProps, UseInviteUserReturn } from './types'

const ROLE_ERROR = 'Please select a role'
const NO_SELECTED_DEALER_ERROR = 'Select at least one dealership above'

const useInviteUser: FCHook<UseInviteUserProps, UseInviteUserReturn> = ({
  isOpen,
  isLoading: _isLoading,
  dealers,
  onClose: _onClose,
  patchDealerInvites: inviteHandler
}) => {
  const { showAlert } = useCustomSnackbar()

  const [emails, setEmails] = useState<string[]>([])
  const [value, setValue] = useState('')
  const [invitationRole, setInvitationRole] = useState<ViewDealerRoles | null>(null)
  const [emailError, setEmailError] = useState(false)
  const [roleError, setRoleError] = useState('')
  const [noDealerSelectedError, setNoDealerSelectedError] = useState('')
  const [isLoading, setIsLoading] = useState(_isLoading)
  const [_selectedDealers, setSelectedDealers] = useState<number[]>([])

  const selectedDealers = mapToCheckboxes(dealers ?? [], _selectedDealers)

  // ========================================== //
  //                  HANDLERS                  //
  // ========================================== //

  const onRoleChange = useCallback((_, val: ViewDealerRoles | null) => {
    setInvitationRole(val)

    if (Number(val) === ViewDealerRoles.DealerAdmin) {
      setSelectedDealers(dealers.map(dealer => dealer.id))
      setNoDealerSelectedError('')
    }

    setRoleError(val == null ? ROLE_ERROR : '')
  }, [dealers])

  const deleteEmail = useCallback((e: MouseEvent<HTMLElement>): void => {
    const idx = Number(e?.currentTarget.parentElement?.getAttribute('data-idx'))
    if (!isNaN(idx)) {
      setEmails(_emails => [..._emails.slice(0, idx), ..._emails.slice(idx + 1)])
    }
  }, [])

  const onClose = useCallback(() => {
    _onClose()

    // fix lag when close modal
    setTimeout(() => {
      setEmails([])
      setValue('')
      setInvitationRole(null)
      setSelectedDealers([])
      setEmailError(false)
      setRoleError('')
      setNoDealerSelectedError('')
    }, 500)
  }, [_onClose])

  const onSubmit = useCallback(async (): Promise<void> => {
    let error = false
    const emailsPayload = [...emails]

    if (isEmailValid(value)) {
      emailsPayload.push(value)
    }

    if (emailsPayload.length === 0) {
      setEmailError(true)
      error = true
    }

    if (_selectedDealers.length === 0 && dealers.length > 1) {
      setNoDealerSelectedError(NO_SELECTED_DEALER_ERROR)
      error = true
    }

    if (invitationRole == null) {
      setRoleError(ROLE_ERROR)
      return
    }

    if (error) {
      return
    }

    try {
      const payload = {
        emails: emailsPayload,
        roleId: Number(invitationRole ?? 0),
        dealerIds: dealers.length > 1 ? _selectedDealers : [dealers[0].id]
      }

      setIsLoading(true)
      await inviteHandler(payload)
      showAlert('Invite has been sent.', { variant: 'success' })
    } catch (e) {
      showAlert(e)
    } finally {
      setIsLoading(false)
    }

    onClose()
  }, [
    dealers, emails, invitationRole, value,
    _selectedDealers, inviteHandler, onClose, showAlert
  ])

  const onBlur = useCallback((): void => {
    const isValueValid = isEmailValid(value)

    if (isValueValid && !emails.includes(value)) {
      setEmails([...emails, value])
      setValue('')
      return
    }

    setEmailError(emails.length > 0 ? false : !isValueValid)
  }, [emails, value])

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.value

    /**
     * prevents showing space and comma after another email chip is added
     */
    if (value !== ' ' && value !== ',') {
      setValue(value)
    }
  }, [])

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === ' ' || e.key === ',' || e.key === 'Enter') {
      if (isEmailValid(value)) {
        setEmails([...emails, value])
        setValue('')
      } else {
        setEmailError(true)
      }
      return
    }
    setEmailError(false)
  }, [emails, value])

  const onDealerSelect = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    const id = Number(name)
    const nextValue = checked
      ? [..._selectedDealers, id]
      : _selectedDealers.filter(item => item !== id)

    setSelectedDealers(nextValue)
    setNoDealerSelectedError('')
  }, [_selectedDealers])

  // ========================================== //
  //                  EFFECTS                   //
  // ========================================== //

  useEffect(() => {
    if (_selectedDealers.length === 0 && isOpen) {
      setNoDealerSelectedError(NO_SELECTED_DEALER_ERROR)
    }

    /**
     * isOpen is not included - thus when we first open the modal
     * we don't want to show the error
     */
  }, [_selectedDealers.length])

  // ========================================== //

  return {
    isOpen,
    isLoading,
    invitationRole,
    emails,
    emailError,
    roleError,
    noDealerSelectedError,
    dealers,
    selectedDealers,
    value,
    onChange,
    onBlur,
    onKeyDown,
    onRoleChange,
    deleteEmail,
    onClose,
    onSubmit,
    onDealerSelect
  }
}

export default useInviteUser
