import type { FC, MouseEvent, ChangeEvent } from 'react'
import { useCallback, useState, useMemo, useRef, useEffect } from 'react'
import { cn } from '@carfluent/common'

import type { CheckboxType } from 'types'
import type { DictionaryItems } from 'api/types/utility.types'

import Popover from 'components/common/Popover'
import CheckboxLabel from 'components/common/CheckboxLabel'

import { FilterButtonState } from 'pages/crm/WorkSchedule/hook/types'

import mapToCheckboxes from 'utils/mapToCheckboxes'

import { createUseStyles as CLASS_NAME, createPopoverStyles as POPOVER_CLASS_NAME } from './styles'

export interface ListFilterPopupProps {
  filters: number[]
  onChange: (val: number[]) => Promise<void>
  items: DictionaryItems<string> | null
  itemMapper?: (items: DictionaryItems<string>, values: number[]) => CheckboxType[]
  isUniqItem?: boolean
}

const ALL_ITEM_VALUE = {
  name: 'All',
  id: 0
}

export const ListFilterPopup: FC<ListFilterPopupProps> = ({
  filters,
  onChange,
  items,
  itemMapper = mapToCheckboxes,
  isUniqItem = false
}) => {
  const initedFilters = useRef<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedFilters, setSelectedFilters] = useState<number[]>([])
  const filterItems = useMemo(() => itemMapper(items ?? [], selectedFilters), [itemMapper, items, selectedFilters])
  const isSelectedFilterExist = selectedFilters.length > 0
  const isSelectedAll = selectedFilters.length === items?.length && isSelectedFilterExist
  const isIndeterminate = filterItems.some(({ checked }) => !checked) && isSelectedFilterExist
  const listFilterTitle = isIndeterminate ? FilterButtonState.SelectedMembers : FilterButtonState.AllTeam

  const checkboxLabelValue = useMemo(() => {
    return {
      indeterminate: isIndeterminate,
      checked: isSelectedAll,
      ...ALL_ITEM_VALUE
    }
  }, [isIndeterminate, isSelectedAll])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onClick = useCallback((e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const onClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const _onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    const id = Number(name)
    const nextValue = checked
      ? [...selectedFilters, id]
      : selectedFilters.filter(item => item !== id)

    setSelectedFilters(nextValue)
    void onChange(nextValue)
  }, [onChange, selectedFilters])

  const onChangeAll = useCallback(() => {
    const isShouldSelectAll = selectedFilters.length === 0 || items?.length !== selectedFilters.length
    const itemsToCheck = isShouldSelectAll ? (items ?? []).map(({ id }) => id) : []

    setSelectedFilters(itemsToCheck)
    void onChange(itemsToCheck)
  }, [selectedFilters.length, items, onChange])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Setting default filters
   * */
  useEffect(() => {
    const shouldInitFilters = !initedFilters.current &&
      selectedFilters.length !== filters.length

    if (shouldInitFilters) {
      setSelectedFilters(filters)
      initedFilters.current = true
    }
  }, [filters, selectedFilters])

  // ========================================== //

  return (
    <div className={CLASS_NAME}>
      <p className='cf-title' onClick={onClick}>
        {`Show: ${listFilterTitle}`}
      </p>

      <Popover
        open={anchorEl != null}
        onClose={onClose}
        anchorEl={anchorEl}
        minWidth={260}
        transform={{ x: 70 }}
      >
        <div className={POPOVER_CLASS_NAME}>
          <p className='cf-pop-up-title'>Show</p>

          <div className='cf-all-items-block'>
            <CheckboxLabel
              value={checkboxLabelValue}
              onChange={onChangeAll}
            />
          </div>

          <div className='cf-items-list'>
            {filterItems.map(item =>
              <div
                key={item.name}
                className={cn('cf-item-block', isUniqItem && `cf-${item.name.toLowerCase()}`)}
              >
                <CheckboxLabel onChange={_onChange} value={item} />
              </div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default ListFilterPopup
