import { css } from '@emotion/css'
import { px, ScreenWidthBreakpoints } from 'constants/constants'

export default css(`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 16px;
  background: #FFF;
  column-gap: 16px;
  row-gap: 24px;
  border-radius: 12px;

  @media screen and (min-width: ${px(ScreenWidthBreakpoints.Medium)}) { 
    grid-template-columns: 1fr 1fr 1fr;
  }

  .radios-title-error {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .error-text {
    color: #B00020;
    font-size: 12px;
  }

  .form-subtitle {
    font-weight: 500;
    font-size: 16px;
    color: #101010;
  }
  
  &.call-form {
    grid-template-areas:
    'dueDate time'
    'assigned assigned'
    'isInbound isInbound'
    'notes notes'
    'buttons buttons';

    @media screen and (min-width: ${px(ScreenWidthBreakpoints.Medium)}) {
      grid-template-areas:
      'dueDate time assigned'
      'isInbound isInbound isInbound'
      'notes notes notes'
      'buttons buttons buttons';
    }

    .MuiFormGroup-root {
      row-gap: 16px;
      align-content: center;
      
      .radioGroup-item, .complete-item {
        padding: 0;
        margin: 0 32px 0 -9px;
        height: 24px;
      }
    }
  }

  &.call-form-with-suboptions {
    grid-template-areas:
    'dueDate time'
    'assigned assigned'
    'isInbound isInbound'
    'completeOption completeOption'
    'notes notes'
    'buttons buttons';

    @media screen and (min-width: ${px(ScreenWidthBreakpoints.Medium)}) {
      grid-template-areas:
        'dueDate time assigned'
        'isInbound isInbound isInbound'
        'completeOption completeOption completeOption'
        'notes notes notes'
        'buttons buttons buttons';
    }

    .complete-option { grid-area: completeOption; }
  }

  .isInbound {
    grid-area: isInbound;
    height: 24px;
    align-content: center;
  }
    
  .radioGroup-item, .radioSubGroup-item { margin-right: 0; }

  &.visit-form {
    grid-template-areas:
    'dueDate time'
    'assigned assigned'
    'subOptions subOptions'
    'notes notes'
    'buttons buttons';

    @media screen and (min-width: ${px(ScreenWidthBreakpoints.Medium)}) {
      grid-template-areas:
        'dueDate time assigned'
        'subOptions subOptions subOptions'
        'notes notes notes'
        'buttons buttons buttons';
    }

    .suboptions {
      display: grid;
      grid-area: subOptions;
      grid-template-columns: 1fr 1fr;
      row-gap: 24px;

      .suboption-checkbox {  
        label {
          max-width: fit-content;
          margin-left: -9px;
          height: 24px;
        }
      }   

      .error-text {
        display: flex;
        align-items: center;
      }
    }
  }

  &.other-form {
    grid-template-areas:
    'subject subject'
    'dueDate time'
    'assigned assigned'
    'notes notes'
    'buttons buttons';

    @media screen and (min-width: ${px(ScreenWidthBreakpoints.Medium)}) {
      grid-template-areas:
        'subject subject subject'
        'dueDate time assigned'
        'notes notes notes'
        'buttons buttons buttons';
    }
      
    .subject { grid-area: subject; }
  }

  .time, .assigned, .subject {
    label {
      letter-spacing: 0.4px;
      color: rgba(33, 33, 33, 0.8);
    }

    input {
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #101010;
    }
  }

  .due-date {
    max-width: 307px;
    grid-area: dueDate;
  }

  .time {
    max-width: 307px;
    grid-area: time;

    input { text-transform: uppercase; }
  }

  .assigned {
    grid-area: assigned;
    width: 100%;

    .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]{ padding-left: 12px; }
  }

  .notes { grid-area: notes; }

  .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-endAdornment {
    button {
      padding: 8px 12px;
      margin-right: 8px;
      width: 12px;
    }
  }
`)
