// AZ-TODO: move all shared enums to this module

export enum LeadSource {
  WalkIn = 1,
  Web = 2,
  GeneralReferral = 3,
  ServiceReferral = 4,
  DealerMgmtSys = 5,
  ListImport = 6,
  Manufacturer = 7,
  Phone = 8,
  CoBuyer = 9,
  AutoTrader = 10,
  CarGurus = 11,
  CarsCom = 12,
  Ebay = 13,
  TrueCar = 14,
  CarFax = 15,
  DealerRater = 16,
  Edmunds = 17,
  HomeNet = 18,
  DealerCenter = 19,
  Ove = 20,
  WebsiteFinancing = 21,
  WebsiteTradeIn = 22,
  WebsiteAsk = 23,
  WebsiteTestDrive = 24,
  WebsiteDeal = 25,
  OfferUp = 26,
  Sasdesk = 27,
  CarCode = 28,
  CarZing = 29,
  CapitalOne = 30,
  Goxee = 31,
  CommercialTruckTrader = 32,
  Autolist = 33,
  WestLakeFinancial = 34,
  AutoSweet = 35,
  Other = 36,
  WebChat = 37
}

export enum LeadStatus {
  Unqualified = 1,
  Contacted = 2,
  DealCreated = 5,
  Sold = 6
}

export enum TitleStage {
  NotReceived = 'Not received',
  Received = 'Received',
  TitleFlippedToDealership = 'Title flipped to dealership'
}

export enum RegistrationStage {
  PendingRegistration = 'Pending Registration',
  SentToDMVOrAgency = 'Sent to DMV or Agency',
  RegistrationReceived = 'Registration Received',
  SentOrPickedUpByCustomer = 'Sent or Picked up by Customer'
}

export enum CampaignSteps {
  CampaignInformation = 0,
  Recipients,
  ReviewSend,
  Statistics,
}

export enum RecipientsFilterOptions {
  LeadStatus = 0,
  LeadSource,
  LeadTemperature,
  LeadWithAppointments
}
