import { useCallback, useState } from 'react'
import { useModal } from '@carfluent/common'

import { AccountingApiProvider } from 'api/accounting.api'

import useEffectOnce from 'hooks/useEffectOnce'
import useTableApi from 'hooks/useTableApi'

import { API_CALL_DELAY, PAGE_SIZE } from 'constants/constants'

import {
  type UseCustomersReturn,
  type Customer,
  type Row
} from './types'
import columnDefinitions from './columns'

const DEFAULT_FILTERS = {}

const useCustomers = (): UseCustomersReturn => {
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(null)

  const {
    emptyTableMessage,
    isLoading,
    loadRows,
    onSearch,
    rows,
    search,
    setSearch
  } = useTableApi({
    apiCallDelay: API_CALL_DELAY,
    defaultFilters: DEFAULT_FILTERS,
    getList: AccountingApiProvider.getCustomers,
    pageSize: PAGE_SIZE,
    shouldRunOnCall: true
  })

  const { isModalOpen, onOpenModal, onCloseModal } = useModal()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onAddCustomer = useCallback(async () => {
    await loadRows({ forceRefresh: true })
  }, [loadRows])

  const onDeleteCustomer = useCallback(async () => {
    await loadRows({ forceRefresh: true })
  }, [loadRows])

  const onOpenCustomerDetails = useCallback((row?: Row<Customer>) => {
    setSelectedCustomerId(row?.original.id ?? null)
    onOpenModal()
  }, [onOpenModal, setSelectedCustomerId])

  const onCloseCustomerDetails = useCallback(() => {
    onCloseModal()
  }, [onCloseModal])

  const onBottomReached = useCallback(async () => {
    if (!isLoading) {
      await loadRows({ forceRefresh: false })
    }
  }, [isLoading, loadRows])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffectOnce(() => {
    window.scrollTo({ top: 0 })
    void loadRows({ forceRefresh: true })
  }, [loadRows])

  // ========================================== //

  return {
    isModalOpen,
    emptyState: emptyTableMessage,
    isLoading,
    columns: columnDefinitions,
    rows,
    search,
    selectedCustomerId,
    onAddCustomer,
    onDeleteCustomer,
    onCloseCustomerDetails,
    onOpenCustomerDetails,
    onSearchChange: setSearch,
    onBottomReached,
    onSearch
  }
}

export default useCustomers
