import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGCross: FC<IconProps> = ({
  disabled = false,
  hidden = false,
  width = 24,
  height = 24
}) => {
  const opacity = hidden
    ? 0
    : disabled ? 0.4 : 1

  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.4659 12L19.6959 5.76969C20.1014 5.36443 20.1014 4.70919 19.6959 4.30394C19.2907 3.89869 18.6355 3.89869 18.2302 4.30394L11.9999 10.5342L5.76983 4.30394C5.36439 3.89869 4.70934 3.89869 4.30408 4.30394C3.89864 4.70919 3.89864 5.36443 4.30408 5.76969L10.5342 12L4.30408 18.2302C3.89864 18.6355 3.89864 19.2907 4.30408 19.696C4.50605 19.8981 4.7716 19.9997 5.03696 19.9997C5.30232 19.9997 5.56768 19.8981 5.76983 19.696L11.9999 13.4657L18.2302 19.696C18.4324 19.8981 18.6977 19.9997 18.9631 19.9997C19.2284 19.9997 19.4938 19.8981 19.6959 19.696C20.1014 19.2907 20.1014 18.6355 19.6959 18.2302L13.4659 12Z'
        fill={disabled ? 'black' : '#101010'}
        fillOpacity={opacity}
      />
    </svg>
  )
}

export default IconSVGCross
