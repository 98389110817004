import { type FC } from 'react'
import { type MenuIconProps } from 'types'

const AccountingTransactionsMenuIcon: FC<MenuIconProps> = ({
  isActive = false
}) => {
  const pictogramFill = isActive ? '#fff' : '#101010'

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 22C10.303 22 8.74242 21.6136 7.31818 20.8409C5.89394 20.0682 4.72727 19.0379 3.81818 17.75V20.1818H2V14.7273H7.45455V16.5455H5.20455C5.93182 17.6364 6.8903 18.5152 8.08 19.1818C9.2697 19.8485 10.5764 20.1818 12 20.1818C13.1364 20.1818 14.2009 19.9658 15.1936 19.5336C16.1864 19.1015 17.05 18.5185 17.7845 17.7845C18.5191 17.0494 19.1024 16.1858 19.5345 15.1936C19.9667 14.2015 20.1824 13.137 20.1818 12H22C22 13.3788 21.7385 14.6742 21.2155 15.8864C20.6924 17.0985 19.9767 18.1591 19.0682 19.0682C18.1591 19.9773 17.0985 20.6933 15.8864 21.2164C14.6742 21.7394 13.3788 22.0006 12 22ZM2 12C2 10.6212 2.26152 9.32576 2.78455 8.11364C3.30758 6.90151 4.02333 5.84091 4.93182 4.93182C5.84091 4.02273 6.90152 3.30697 8.11364 2.78455C9.32576 2.26212 10.6212 2.00061 12 2C13.697 2 15.2576 2.38636 16.6818 3.15909C18.1061 3.93182 19.2727 4.96212 20.1818 6.25V3.81818H22V9.27273H16.5455V7.45455H18.7955C18.0682 6.36364 17.1097 5.48485 15.92 4.81818C14.7303 4.15151 13.4236 3.81818 12 3.81818C10.8636 3.81818 9.79909 4.03424 8.80636 4.46636C7.81364 4.89848 6.95 5.48151 6.21545 6.21545C5.48152 6.95061 4.89879 7.81454 4.46727 8.80727C4.03576 9.8 3.81939 10.8642 3.81818 12H2ZM12 16.5455L10.5909 13.4091L7.45455 12L10.5909 10.5682L12 7.45455L13.4318 10.5682L16.5455 12L13.4318 13.4091L12 16.5455Z'
        fill={pictogramFill}
      />
    </svg>

  )
}

export default AccountingTransactionsMenuIcon
