import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  .MuiPaper-root.cf-table-wrapper {
    border-radius: 2px 12px 2px 2px;

    .cf-table { table-layout: fixed; }
    
    .cf-table-header-row:first-child .cf-header-cell-title:first-child { padding-left: 8px; }

    .cf-table-cell {
      :first-child .cf-cell-wrapper { padding-left: 4px; }
    }

    .cf-cell-wrapper {
      height: 60px;
      padding: 4px 8px;

      &.cf-tag-cell {
        overflow: visible;
        > div { margin-top: -6px; }
      }
    }
  }
  
  .cf-no-data-block {
    display: flex;
    justify-content: center;
    padding: 30px;
    background: #fff;
    border-top: 8px solid #F3F3F3;
  }
  
  .car-filter-wrapper {
    display: flex;
    align-items: center;
  
    .title { margin-right: 8px; }

    .cf-select-display-container  {
      height: 32px;
      background: white;
      border: none;
      border-radius: 8px;
      padding: 8px;
      padding-right: 4px;
      display: flex;
      align-items: center;

      .cf-display {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.25px;
        border: none;
        outline: none;
        resize: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .cf-end-adornment {
        display: flex;
        align-items: center;

        > div { height: 24px; }

        :has(.cf-dropdown-clear-button) { .cf-dropdown-trigger-button { display:none; } }

        .cf-dropdown-clear-button {
          margin-top: 2px;

          svg {
            width: 20px;
            height: 20px;
          }
        }

        .cf-dropdown-trigger-button {
          margin-top: 4px;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .make-column {
    .cf-tooltip-container p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #101010;
    }
  }
  
  .total-cars-wrapper {
    color: rgba(0, 0, 0, 0.30);
    margin-left: 6px;
  }

  .cf-divider-left {
    display: none;
  }

  .cf-group-title {
    padding-left: 4px;
  }

  .cf-table-row {
    .cf-table-cell:first-child .cell-description > div { min-width: 75px!important; }
  }

  .cf-cell-wrapper {
    display: flex;
  }

  .cf-cell-wrapper .cf-tooltip-container {
    display: flex;
    align-items: center;
  }

  .status-column {
    .cf-cell-wrapper {
      align-items: flex-start;
    }
  }

  .cf-toggler {
    position: static;
  }

  .cf-table-group-row {
    height: 32px;
  }

  .cf-table-group-cell {
    > div {
      height: 24px;
    }
  }


  &.loading .cf-cell-wrapper {
    align-items: center;
    padding-left: 0!important;
    padding-right: 32px!important;

    .cell-description {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > div:last-of-type {
        display: flex;
        justify-content: end;

        .MuiSkeleton-root {
          width: 32px;
        }
      }
    }
  }
`)
