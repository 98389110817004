import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import { Button, cn, Input } from '@carfluent/common'

import type { ReconStep } from 'api/types'
import ReconAPIProvider from 'api/recon.api'
import arrowDown from 'assets/arrow_down_step.svg'
import useClickOutside from 'hooks/useClickOutside'

import CLASS_NAME from './styles'

interface StepProps {
  step: ReconStep
  onUpdateStep: (id: number) => Promise<void>
  isLoading: boolean
}

const Step: FC<StepProps> = ({
  step,
  onUpdateStep,
  isLoading
}) => {
  const [search, setSearch] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [steps, setSteps] = useState<ReconStep[]>([])

  const popoverRef = useRef<HTMLDivElement | null>(null)

  const onClose = (): void => setIsOpen(false)
  const onTogglePopover = (): void => setIsOpen(prev => !prev)

  const onUpdate = (id: number): void => {
    if (step.id !== id) {
      void onUpdateStep(id)
    }

    setIsOpen(false)
  }

  useClickOutside(popoverRef, onClose, { capture: true })

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      const { items } = await ReconAPIProvider.getReconStepsList()

      setSteps(items)
    }

    void runEffect()
  }, [])

  useEffect(() => {
    if (!isOpen) {
      setSearch('')
    }
  }, [isOpen])

  const stepsToShow = steps.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase().trim()))

  return (
    <div ref={popoverRef} className={CLASS_NAME}>
      <Button
        onClick={onTogglePopover}
        variant='text'
        endAdornment={isLoading
          ? null
          : <img src={arrowDown} alt='arrow_down' />}
        isLoading={isLoading}
        isDisabled={isLoading}
      >
        {step.name}
      </Button>

      {isOpen && (
        <div className='cf-step-popup'>
          <div className='cf-search-wrapper'>
            <Input
              id='search'
              label='Search step'
              value={search}
              onChange={(_, value) => setSearch(value)}
            />
          </div>

          <div className='cf-steps-wrapper'>
            {stepsToShow.length > 0
              ? stepsToShow.map(({ id, name }) => (
                <div
                  key={id}
                  className={cn('cf-step-item', step.id === id && 'cf-active')}
                  onClick={() => onUpdate(id)}
                >
                  {name}
                </div>
              ))
              : (
                <div className='cf-no-steps'>
                  No steps
                </div>
                )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Step
