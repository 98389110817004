import { useContext, useMemo } from 'react'
import { toJS } from 'mobx'

import type { EntityId } from 'types'
import type { AccountingTypesResponse } from 'api/types'
import AccountingApiProvider from 'api/accounting.api'
import SettingsCTX from 'store/settings'
import usePrefetchable from 'hooks/usePrefetchable'

export interface UseAccountCategoriesReturn {
  getTypes: (isForced?: boolean, shouldUseCached?: boolean) => Promise<Map<EntityId, string>>
  isLoading: boolean
}

const useAccountTypes = (shouldPrefetch: boolean = false): UseAccountCategoriesReturn => {
  const { _accountTypes, setAccountTypes } = useContext(SettingsCTX)

  const {
    getData:  getTypes,
    isLoading
  } = usePrefetchable({
    getEmptyState,
    getData: AccountingApiProvider.getTypes,
    getFromStore: () => toJS(_accountTypes),
    parseData: parseTypes,
    saveToStore: setAccountTypes,
    shouldPrefetch
  })

  // ========================================== //

  return useMemo(() => ({
    getTypes,
    isLoading
  }), [
    getTypes,
    isLoading
  ])
}

export default useAccountTypes


// ========================================== //

const getEmptyState = () => new Map<number, string>()

const parseTypes = (data: AccountingTypesResponse): Map<EntityId, string> => {
  const result = new Map<EntityId, string>()

  for (const item of data.items) {
    result.set(item.id, item.name ?? '')
  }

  return result
}
