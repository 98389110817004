import { EditorState, Modifier, SelectionState } from 'draft-js'

import { type FCHook } from 'types'
import { type EmailSnippetDto } from 'api/types/responses'
import { CAR_OF_INTEREST_TAG, CAR_OF_INTEREST_VIDEO_TAG, SUGGESTED_CARS_TAG } from 'utils/wysiwyg'

import type { UseSnippetsDropdownProps, UseSnippetsDropdownReturn } from './types'

const useSnippetsDropdown: FCHook<UseSnippetsDropdownProps, UseSnippetsDropdownReturn> = ({
  editorState,
  shouldUseDefaultOnChange = true,
  onDropdownChange,
  onChange: setEditorState
}) => {
  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const defaultOnDropdownChange = (_: string, value: EmailSnippetDto | null): void => {
    if (editorState == null || value == null) {
      return
    }

    const contentState = editorState.getCurrentContent()
    const selection = editorState.getSelection()

    const specialPlaceholders = [CAR_OF_INTEREST_VIDEO_TAG, CAR_OF_INTEREST_TAG, SUGGESTED_CARS_TAG]
    const isSpecialPlaceholder = specialPlaceholders.includes(value.placeholder)
    const insertText = value.placeholder

    const currentBlock = contentState.getBlockForKey(selection.getStartKey())
    const blockText = currentBlock.getText()

    /**
      * If it's a special placeholder, and not on the empty line
      * split the block and insert text in separate paragraphs
     */
    if (isSpecialPlaceholder && blockText !== '') {
      let newContentState = contentState

      // Split the block at the cursor position
      newContentState = Modifier.splitBlock(newContentState, selection)

      // Update the selection to the new block
      const newSelection = SelectionState.createEmpty(newContentState.getKeyAfter(selection.getStartKey()))

      // Insert the placeholder text into the new block
      newContentState = Modifier.insertText(newContentState, newSelection, insertText)

      // Insert a new line after the placeholder
      newContentState = Modifier.splitBlock(newContentState, newContentState.getSelectionAfter())

      // Move the cursor to the new line after the inserted text
      const updatedSelection = newContentState.getSelectionAfter()
      const newEditorState = EditorState.forceSelection(
        EditorState.push(editorState, newContentState, 'insert-characters'),
        updatedSelection
      )

      setEditorState?.(newEditorState)
    } else {
      let contentState = editorState.getCurrentContent()

      const formattedText = `${insertText} `

      contentState = Modifier.replaceText(
        contentState,
        selection,
        formattedText,
        editorState.getCurrentInlineStyle()
      )

      const newSelection = contentState.getSelectionAfter()
      const newEditorState = EditorState.forceSelection(
        EditorState.push(editorState, contentState, 'insert-characters'),
        newSelection
      )

      setEditorState?.(newEditorState)
    }
  }
  const onChange = (shouldUseDefaultOnChange || (onDropdownChange == null))
    ? defaultOnDropdownChange
    : onDropdownChange

  // ========================================== //

  return {
    onChange
  }
}

export default useSnippetsDropdown
