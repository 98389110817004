import {
  type FC,
  useEffect,
  useState,
  useCallback,
  useMemo
} from 'react'
import { cnx, Button, Loader } from '@carfluent/common'

import { isTruthy } from 'utils/general'
import { type EmailAttachment, type UploadedAttachmentDto } from 'api/types'
import FilesApiProvider from 'api/files.api'
import { DEFAULT_MAX_TOTAL_FILE_SIZE, KiB, UNLIMITED_FILES_SIZE } from 'constants/files'
import useFilesUpload from 'hooks/useFilesUpload'
import type { PhotoAttachmentsProps } from 'pages/crm/LeadDetailsView/hook/types'
import AddFilesButton from 'components/common/AddFilesButton'
import AttachmentItems from 'components/common/AttachmentItems'

import CLASS_NAME from './styles'

const EMAIL_ATTACHMENTS_LOADER_SIZE = 32

export interface EmailAttachmentsProps {
  className?: string
  setAttachmentsField: (value: EmailAttachment[]) => void
  onOpenAttachPhotosModal: () => void
  photoAttachmentsProps: PhotoAttachmentsProps
}

const EmailAttachments: FC<EmailAttachmentsProps> = ({
  className,
  setAttachmentsField,
  onOpenAttachPhotosModal,
  photoAttachmentsProps
}) => {
  const {
    vehiclePhotoAttachments,
    vehicleVideoAttachments,
    onDeletePhoto,
    setAttachmentsInfo
  } = photoAttachmentsProps

  const photos = useMemo(() => vehiclePhotoAttachments.map(({
    id,
    order,
    fileName,
    originalUrl,
    originalSizeKb
  }) => ({
    id: (id != null && isTruthy(id)) ? id : order,
    fileName,
    originalSizeKb,
    url: originalUrl
  })), [vehiclePhotoAttachments])

  const {
    files,
    onAddFiles,
    onDeleteFile
  } = useFilesUpload<UploadedAttachmentDto>({
    isUnlimitedFilesNum: true,
    uploadAssets: FilesApiProvider.uploadFileForEmailAttachment
  })

  const [attachments, setAttachments] = useState<EmailAttachment[]>(photos)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const areAttachmentsEmpty = attachments.length === 0

  const attachmentsTotalSizeMb = useMemo(() => {
    const filesSizeKb = files.reduce((acc, { file }) => acc + file.size / KiB, 0)
    const photosSizeKb = photos.reduce((acc, photo) => acc + photo.originalSizeKb, 0)

    return (filesSizeKb + photosSizeKb) / KiB
  }, [files, photos])

  const onDeleteAttachment = useCallback((id: string | number): void => {
    const isVehiclePhoto = photos.some(photo => photo.id === id)

    if (isVehiclePhoto) {
      onDeletePhoto(id)
    } else {
      onDeleteFile(id)
    }

    setAttachments(prev => prev.filter(att => att.id !== id))
  }, [photos, onDeleteFile, onDeletePhoto])

  useEffect(() => {
    const filesUploading = files.some((file) => file.url == null || file.url === '')

    if (files.length > 0 && !filesUploading) {
      const fileAttachments: EmailAttachment[] = files.map(({ id, file, url }) => ({
        id: id,
        fileName: file.name,
        url: url ?? ''
      }))

      const allAttachments = [...photos, ...fileAttachments]

      setAttachments(allAttachments)
      setAttachmentsField(allAttachments)
      setAttachmentsInfo({
        attachments: allAttachments,
        totalSize: attachmentsTotalSizeMb
      })
    } else if (files.length === 0) {
      setAttachments(photos)
      setAttachmentsField(photos)
      setAttachmentsInfo({
        attachments: photos,
        totalSize: attachmentsTotalSizeMb
      })
    }

    setIsLoading(filesUploading)
  }, [files, photos, attachmentsTotalSizeMb, setAttachmentsInfo, setAttachmentsField])

  return (
    <div className={cnx(CLASS_NAME, className)}>
      {isLoading && <Loader className='section-loader' size={EMAIL_ATTACHMENTS_LOADER_SIZE} />}
      <div className='email-attachments-top-section'>
        <p className='title'>
          Attachments (total files size 20MB):
        </p>

        <div className='actions'>
          <Button
            className='attach-inventory-photo-button'
            variant='text'
            onClick={onOpenAttachPhotosModal}
          >
            Attach Inventory Media
          </Button>

          <AddFilesButton
            onAddFiles={onAddFiles}
            maxFileNumber={UNLIMITED_FILES_SIZE}
            maxFileSize={DEFAULT_MAX_TOTAL_FILE_SIZE}
            maxFilesTotalSize={DEFAULT_MAX_TOTAL_FILE_SIZE}
            isDisabled={isLoading}
          />
        </div>
      </div>

      {!areAttachmentsEmpty && (
        <>
          <div className='attachments-divider' />
          <div className='file-zone'>
            <AttachmentItems
              items={attachments}
              onDeleteAttachment={onDeleteAttachment}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default EmailAttachments
