import type { SortingInfo } from '@carfluent/common/dist/UI'
import { type CancelationOptions, type DictionaryItem, type TaskFilterName, TaskStateEnum, TaskFilterEnum } from 'api/types'
import type {
  TaskFilter,
  TaskListFilters,
  TaskTabFilters,
  TasksListCounters,
  TaskColumnsFilters
} from './types'

export const enum Errors {
  Counters = 'Tasks counters fetching failed',
  Statuses = 'Tasks statuses fetching failed',
  AssignedTo = 'Tasks assigned to fetching failed'
}

export const DEFAULT_SORTING: SortingInfo = { id: 'dueDate', order: 'desc' }
export const DEFAULT_SORTING_TODAY_AND_OLDER_FILTER: SortingInfo = { id: 'dueDate', order: 'desc' }
export const DEFAULT_SORTING_COMPLETED_FILTER: SortingInfo = { id: 'completionDate', order: 'desc' }

export const DEFAULT_FILTER = TaskFilterEnum.Today

export const DEFAULT_TAB_FILTERS: TaskTabFilters = {
  taskStateEnum: TaskStateEnum.NewLeads
}

export const DEFAULT_TASK_FILTERS: TaskFilter = {
  taskFilterEnum: DEFAULT_FILTER
}

export const enum FiltersKeys {
  LeadStatus = 'leadStatusIds',
  AssignedTo = 'assigneeIds'
}

export const DEFAULT_TABLE_FILTERS: TaskColumnsFilters = {
  [FiltersKeys.LeadStatus]: [],
  [FiltersKeys.AssignedTo]: []
}

export const DEFAULT_FILTERS: TaskListFilters = {
  ...DEFAULT_TASK_FILTERS,
  ...DEFAULT_TAB_FILTERS,
  ...DEFAULT_TABLE_FILTERS,
  search: ''
}

export const DEFAULT_FILTERS_COUNTERS: TasksListCounters['filters'] = {
  All: 0,
  Today: 0,
  Completed: 0
}

export const DEFAULT_STATE_COUNTERS: TasksListCounters['states'] = {
  newleads: 0,
  creditappreceived: 0,
  inbox: 0,
  workplan: 0,
  appts: 0
}

export const DEFAULT_COUNTERS = { filters: DEFAULT_FILTERS_COUNTERS, states: DEFAULT_STATE_COUNTERS }

export const CANCEL_LOAD_DATA: CancelationOptions = { key: 'load-tasks-data', cancelPrevious: true }
export const CANCEL_LOAD_COUNTERS: CancelationOptions = { key: 'load-tasks-counters', cancelPrevious: true }

export const enum ColumnTitles {
  LeadStatus = 'Status',
  AssignedTo = 'Assigned to'
}

export const enum ColumnPopUpTitles {
  LeadStatus = 'Show Status:',
  AssignedTo = 'Show Assigned to:'
}

export const enum RowSubject {
  NoActivity = '',
  CreditAppReceived = 'Credit app received',
  Appointment = 'Appointment',
  FollowUp = 'Follow up call',
  WebLead = 'Web lead',
  MessageReceived = 'Message received',
  EmailReceived = 'Email received'
}

export const enum RowType {
  NoActivity = 'no-activity',
  Appointment = 'appointment',
  CreditAppReceived = 'credit-app-received',
  FollowUp = 'follow-up',
  WebLead = 'web-lead',
  MessageReceived = 'message-received',
  EmailReceived = 'email-received',
  Reminder = 'reminder'
}

export const enum RowText {
  NoActivity = 'No activity',
  Appointment = 'Appointment',
  CreditAppReceived = 'Credit app received',
  FollowUp = 'Follow up call',
  WebLead = 'Web lead',
  MessageReceived = 'Message received',
  EmailReceived = 'Email received'
}

export const TaskFilterEnumName: { [key in TaskFilterName]: string } = {
  All: 'All open tasks',
  Today: 'Today&Older',
  Completed: 'Completed tasks'
}

export const reverseTaskFilterEnumName: { [name: string]: TaskFilterName } = {}

for (const key of Object.keys(TaskFilterEnumName)) {
  const name = TaskFilterEnumName[key as keyof typeof TaskFilterEnumName]
  reverseTaskFilterEnumName[name] = key as TaskFilterName
}

export const GET_DEFAULT_TASK_FILTERS = (isApptsTab: boolean): DictionaryItem[] => [
  {
    id: TaskFilterEnum.All,
    name: TaskFilterEnumName.All
  },
  {
    id: TaskFilterEnum.Today,
    name: isApptsTab ? 'Today&Future' : TaskFilterEnumName.Today
  },
  {
    id: TaskFilterEnum.Completed,
    name: TaskFilterEnumName.Completed
  }
]
