import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGLock: FC<IconProps> = ({ color = '#101010', width = 16, height = 16, opacity = 0.4 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'>
      {(width <= 16) && (
        <g clipPath='url(#iconPath16)'>
          <path
            d='M11.9998 5.33464H11.3332V4.0013C11.3332 2.1613 9.83984 0.667969 7.99984 0.667969C6.15984 0.667969 4.6665 2.1613 4.6665 4.0013V5.33464H3.99984C3.2665 5.33464 2.6665 5.93464 2.6665 6.66797V13.3346C2.6665 14.068 3.2665 14.668 3.99984 14.668H11.9998C12.7332 14.668 13.3332 14.068 13.3332 13.3346V6.66797C13.3332 5.93464 12.7332 5.33464 11.9998 5.33464ZM7.99984 11.3346C7.2665 11.3346 6.6665 10.7346 6.6665 10.0013C6.6665 9.26797 7.2665 8.66797 7.99984 8.66797C8.73317 8.66797 9.33317 9.26797 9.33317 10.0013C9.33317 10.7346 8.73317 11.3346 7.99984 11.3346ZM10.0665 5.33464H5.93317V4.0013C5.93317 2.8613 6.85984 1.93464 7.99984 1.93464C9.13984 1.93464 10.0665 2.8613 10.0665 4.0013V5.33464Z'
            fill={color}
            fillOpacity={opacity}
          />
        </g>
      )}

      {(width > 16) && (
        <g clipPath='url(#iconPath24)'>
          <path
            d='M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM12 17C10.9 17 10 16.1 10 15C10 13.9 10.9 13 12 13C13.1 13 14 13.9 14 15C14 16.1 13.1 17 12 17ZM15.1 8H8.9V6C8.9 4.29 10.29 2.9 12 2.9C13.71 2.9 15.1 4.29 15.1 6V8Z'
            fill={color}
            fillOpacity={opacity}
          />
        </g>
      )}

      <defs>
        <clipPath id='iconPath'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGLock
