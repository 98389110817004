import type { FC } from 'react'
import { FormInput } from '@carfluent/common'

import { type RespondOnLeadTaskFormData, TaskMode } from 'pages/crm/LeadDetailsView/hook/types'
import CreatedCompleteLayout, { type CreatedCompleteLayoutProps } from 'components/crm/CreatedCompleteLayout'
import RadioGroup from 'components/common/RadioGroup'
import ActionBar from 'components/crm/ActionBar'
import EditDeletePopover from 'pages/crm/LeadDetailsView/components/EditDeletePopover'
import { RADIO_OPTIONS, FieldIds } from './hook/constants'
import useRespondOnLeadForm from './hook'

import { CONTAINER_STYLES, FORM_STYLES } from './styles'

interface CompleteTaskProps {
  completeLayoutProps: CreatedCompleteLayoutProps
  taskMode: TaskMode | null
  taskId: number
  onSubmit: (values: RespondOnLeadTaskFormData, taskId: number) => Promise<void>
  onCloseTask: (taskId: number | null, taskMode?: TaskMode) => void
  onEditTaskClick: (taskId: number) => void
  onDeleteTaskClick: (taskId: number) => void
}

const RespondOnLeadTask: FC<CompleteTaskProps> = ({ completeLayoutProps, ...props }) => {
  const { values, onChange, touched, errors, taskMode, onSubmit, isLoading } = useRespondOnLeadForm(props)
  const { taskCompleteOptionId, workNotes } = values
  const { taskId, onEditTaskClick, onDeleteTaskClick } = props

  const onChangeRadioOption = (_: any, value: string, id: string): void => {
    onChange(id, value)
  }

  const isTaskCompleteOptionUnSelected = taskCompleteOptionId == null
  const showRadioError = touched[FieldIds.TaskCompleteOptionId] && isTaskCompleteOptionUnSelected && errors[FieldIds.TaskCompleteOptionId]

  return (
    <div className={CONTAINER_STYLES}>
      <EditDeletePopover
        onDeleteTaskClick={onDeleteTaskClick}
        onEditTaskClick={onEditTaskClick}
        taskId={taskId}
      />
      <CreatedCompleteLayout {...completeLayoutProps} showActionBar={false} />
      <div className={FORM_STYLES}>
        <RadioGroup
          items={RADIO_OPTIONS}
          value={taskCompleteOptionId}
          onChange={onChangeRadioOption}
          id={FieldIds.TaskCompleteOptionId}
          classes={{ root: 'radio-group', item: 'radio-item' }}
        />
        <div className='radios-title-error'>
          <p className='error-text'>{isTaskCompleteOptionUnSelected && showRadioError}</p>
        </div>

        <FormInput
          value={workNotes}
          onChange={onChange}
          id={FieldIds.Notes}
          label='Work notes'
          className='notes-field'
        />

        <ActionBar
          buttonsClassName='lead-tasks-action-buttons'
          taskMode={taskMode}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default RespondOnLeadTask
