import { useCallback, useMemo, type ChangeEvent, type KeyboardEvent, type FC } from 'react'
import { observer } from 'mobx-react-lite'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { cn, FormInput } from '@carfluent/common'

import { isCyrillic } from 'utils/validation'

import CLASS_NAME from './styles'
import IconSVG from 'components/inlineImages'

interface DocumentFieldProps {
  className?: string
  id: number
  inputError: string
  inputTouched: boolean
  inputValue: string
  isChecked: boolean
  isOther: boolean
  label: string
  onToggleCheckbox: (id: number) => void
  onInputChange: (value: string) => void
}

const DocumentField: FC<DocumentFieldProps> = ({
  className,
  id,
  inputError,
  inputTouched,
  inputValue,
  isChecked,
  isOther,
  label,
  onInputChange,
  onToggleCheckbox
}) => {
  const labelClasses = useMemo(() => ({
    root: 'cf-label-control-root',
    label: 'cf-label-control-text'
  }), [])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onToggle = useCallback(() => {
    onToggleCheckbox(id)
  }, [id, onToggleCheckbox])

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onInputChange(e.target.value)
  }, [onInputChange])

  const onKeyDown = useCallback((evt: KeyboardEvent) => {
    if (isCyrillic(evt.key)) {
      evt.preventDefault()
    }
  }, [])

  // ========================================== //

  return (
    <div className={cn(CLASS_NAME, 'cf-document-field', className)}>
      <FormControlLabel
        classes={labelClasses}
        control={
          <Checkbox
            checked={isChecked}
            checkedIcon={<IconSVG.CheckboxChecked />}
            icon={<IconSVG.CheckboxUnchecked />}
            name={label}
            onChange={onToggle}
          />
        }
        label={label}
      />

      {isOther && isChecked && (
        <div className='cf-input-wrapper'>
          <FormInput
            id='otherDocuments'
            label='Document name'
            maxLength={256}
            error={inputError}
            touched={inputTouched}
            value={inputValue}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
        </div>
      )}
    </div>
  )
}

export default observer(DocumentField)
