import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  .inner-container {
    display: flex;
    border-radius: 12px;
    border: 1px solid var(--Outline, rgba(0, 0, 0, 0.12));
    padding: 16px;
    
    &.no-borders {
      border: none;
      padding: 0px;
      
      .fields {
        gap: 24px !important;
      }
    }
    
    .fields {
      gap: 16px !important;
    }
    
    .section-title {
      display: flex;
      justify-content: space-between;
      
      .btn-edit-coverages {
        cursor: pointer;
      }
    }
  }
  
  .no-fni {
    color: var(--Text-Disabled, rgba(33, 33, 33, 0.40));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
  }
  
  .coverage-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    color: var(--Text-Black, #101010);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    
    label {
      font-weight: 500;
    }
    
    span {
      font-weight: 400;
    }
    
    .product-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    
    .coverage-field {
      display: flex;
      gap: 16px;
      
      label {
        min-width: 86px;
        max-width: 86px;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  
  .edit-action {
    cursor: pointer;
    position: relative;
    width: 24px;
    height: 24px;

    &.disabled {
      cursor: default;
      
      svg {
        path {
          fill: #a6a6a6;
          fill-opacity: 1;
        }
      }

      &: hover {
        &:after {
          opacity: 0;
        }
      }
    }

    svg {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;

      path {
        fill-opacity: 1;
      }
    }

    &:after {
      content: '';
      opacity: 0;
      width: 36px;
      height: 36px;
      position: absolute;
      background-color: #EDEDED;
      top: -6px;
      left: -6px;
      border-radius: 50%;
      transition: all .6s;
    }

    &:hover {
      &:after {
        opacity: 1;
        transition: all .6s;
      }
    }
  }
`)

export const TOOLTIP_CLASS_NAME = css(`
  &.cf-tooltip-container {
    max-width: calc(100% - 86px - 16px); 
  }
  
  .tooltip-content {
    &, label, span {
      color: #101010;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      line-height: 24px; /* 150% */
      letter-spacing: 0.5px;
    }
    
    label {
      font-weight: 500;
    }
    
    span {
      font-weight: 400;
    }
  }
`)
