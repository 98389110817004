import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGBot: FC<IconProps> = ({ color = '#DDB3A0', width = 16, height = 16 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 16 16' fill='none'>
      <path
        d='M14.6182 7.7406C14.4835 7.41527 14.1948 7.33727 13.9995 7.3186V5.33594C13.9995 4.6006 13.4015 4.0026 12.6662 4.0026H8.66617V3.07594C8.86951 2.89327 8.99951 2.6306 8.99951 2.33594C8.99951 2.07072 8.89415 1.81637 8.70661 1.62883C8.51908 1.44129 8.26472 1.33594 7.99951 1.33594C7.73429 1.33594 7.47994 1.44129 7.2924 1.62883C7.10486 1.81637 6.99951 2.07072 6.99951 2.33594C6.99951 2.6306 7.12951 2.89327 7.33284 3.07594V4.0026H3.33284C2.59751 4.0026 1.99951 4.6006 1.99951 5.33594V7.33394L1.94484 7.33794C1.77682 7.35007 1.61962 7.42535 1.50485 7.54866C1.39008 7.67196 1.32624 7.83415 1.32617 8.0026V9.33594C1.32617 9.51275 1.39641 9.68232 1.52143 9.80734C1.64646 9.93237 1.81603 10.0026 1.99284 10.0026H1.99951V13.3359C1.99951 14.0713 2.59751 14.6693 3.33284 14.6693H12.6662C13.4015 14.6693 13.9995 14.0713 13.9995 13.3359V10.0026C14.1763 10.0026 14.3459 9.93237 14.4709 9.80734C14.5959 9.68232 14.6662 9.51275 14.6662 9.33594V8.04394C14.6738 7.94047 14.6574 7.83664 14.6182 7.7406ZM3.33284 13.3359V5.33594H12.6662L12.6668 7.99994L12.6662 8.0026V9.33594L12.6668 9.33927L12.6675 13.3359H3.33284Z'
        fill={color}
      />
      <path
        d='M5.66602 9.33073C6.2183 9.33073 6.66602 8.73378 6.66602 7.9974C6.66602 7.26102 6.2183 6.66406 5.66602 6.66406C5.11373 6.66406 4.66602 7.26102 4.66602 7.9974C4.66602 8.73378 5.11373 9.33073 5.66602 9.33073Z'
        fill={color}
      />
      <path
        d='M10.334 9.33073C10.8863 9.33073 11.334 8.73378 11.334 7.9974C11.334 7.26102 10.8863 6.66406 10.334 6.66406C9.7817 6.66406 9.33398 7.26102 9.33398 7.9974C9.33398 8.73378 9.7817 9.33073 10.334 9.33073Z'
        fill={color}
      />
      <path
        d='M5.33398 10.6641H10.6673V11.9974H5.33398V10.6641Z'
        fill={color}
      />
    </svg>
  )
}

export default IconSVGBot
