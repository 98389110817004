import { css } from '@emotion/css'
import { HEADER_HEIGHT } from '../Messenger/components/Header/styles'

export const CLASS_NAME = css(`
  &.disabled {
    cursor: default;
  }

  .cf-content-wrapper {
    max-height: 384px;
    overflow: auto;
    position: absolute;
    width: 400px;
    background: #FFFFFF;
    box-shadow: 0px 24px 38px rgba(58, 71, 78, 0.1);
    border-radius: 8px;
    left: 0;
    bottom: -10px;
    transform: translate(0, 100%);
    z-index: 101;
    padding-bottom: 16px;
  }
  
  .cf-input-wrapper {
    padding: 16px;
  }
`)

const TOP_MARGIN = '32'

export const POPOVER_CLASS_NAME = css(`
  position: absolute;
  display: flex;
  width: 240px;
  height: 432px;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  max-height: calc(100dvh - ${TOP_MARGIN}px);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  transform: none;
  transition: none;

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 14px 14px transparent;
    margin: 8px 0;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 14px 14px #f3f3f3;
    border: solid 4px #FFF;
    border-radius: 14px;
    
    :hover {
      box-shadow: inset 0 0 14px 14px #10101033;
    }
  }

  .badge {
    display: flex;
    padding: 12px 16px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    
    :hover {
      cursor: pointer;
      background: #F3F3F3;
    }
  }

  .cf-content-section {
    height: calc(100% - ${HEADER_HEIGHT});
    position: relative;
  }
`)
