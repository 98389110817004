import type { FC } from 'react'
import { Button, cnx } from '@carfluent/common'

import { ProductType } from 'api/types'
import IconSVG from 'components/inlineImages'
import { COVERAGE_ORDER } from 'constants/coverage'
import FIProductCard from 'components/settings/FIProductCard'
import type { DealerProductCoverageMarkupDto, FISetupMarkupsModel } from 'api/types/responses'
import type { CoverageDefault, CoverageDefaultSection, CoverageProducts } from 'types/coverage'
import { FICoverageProps } from 'pages/settings/FISettings/components/FIPackageSetup/hook/types'

import CoverageForm from './components/CoverageForm'
import PriceConfiguration from './components/PriceConfiguration'

import CLASS_NAME from './styles'

export interface PenFormAdditionalProps {
  isCostVisible: boolean
  isTotalVisible: boolean
}

export interface FIProductSetupProps extends FICoverageProps {
  isDealForm?: boolean
  isCashPayment?: boolean
  fieldPathPrefix?: string
  addedProducts?: number[]
  isProductDisabled?: boolean
  penPackages: CoverageProducts
  markups?: FISetupMarkupsModel | null
  onProductAdd?: (num: number) => void
  coverageSections: CoverageDefaultSection[]
  penFormAdditionalProps?: PenFormAdditionalProps
  onChangeManualVendor: (field: string, value: CoverageDefault) => void
  onApplyMarkupChanges?: (markup: DealerProductCoverageMarkupDto) => void
}

const PRODUCT_CARDS = [
  {
    id: COVERAGE_ORDER[ProductType.ServiceContract],
    title: 'Service contract',
    icon: <IconSVG.ServiceContract />,
    coverageFormProps: {
      isTermVisible: true,
      isDeductibleManualVisible: true
    }
  },
  {
    id: COVERAGE_ORDER[ProductType.GapInsurance],
    title: 'GAP insurance',
    icon: <IconSVG.GAPInsurance />,
    coverageFormProps: {
      isTermVisible: true,
      isDeductiblePenVisible: false,
      isDeductibleManualVisible: false
    }
  },
  {
    id: COVERAGE_ORDER[ProductType.AppearanceProtection],
    title: 'Appearance protection',
    icon: <IconSVG.AppearanceProtection />,
    coverageFormProps: {
      isTermVisible: false,
      isTermPenVisible: false,
      isDeductiblePenVisible: false,
      isDeductibleManualVisible: false
    }
  },
  {
    id: COVERAGE_ORDER[ProductType.TireAndWheel],
    title: 'Tire & Wheel',
    icon: <IconSVG.TireWheel />,
    coverageFormProps: {
      isTermVisible: true,
      isDeductiblePenVisible: false,
      isDeductibleManualVisible: false
    }
  },
  {
    id: COVERAGE_ORDER[ProductType.Theft],
    title: 'Theft insurance',
    icon: <IconSVG.TheftInsurance />,
    coverageFormProps: {
      isTermVisible: true,
      isDeductiblePenVisible: false,
      isDeductibleManualVisible: false
    }
  }
]

const FIProductSetup: FC<FIProductSetupProps> = ({ onApplyMarkupChanges, markups, isDealForm = false, isCashPayment = false, isProductDisabled = false, penFormAdditionalProps, addedProducts = [], onProductAdd, fieldPathPrefix = '', penPackages, coverageSections, ...form }) => {
  return (
    <div className={CLASS_NAME}>
      <div className='fiproduct-card-wrapper'>
        {
          PRODUCT_CARDS.filter(el => !isCashPayment || el.id !== COVERAGE_ORDER[ProductType.GapInsurance]).map((card, idx) => {
            const isProductAdded = addedProducts.includes(coverageSections[idx].productTypeId)
            const isButtonDisabled = coverageSections[idx].forms[coverageSections[idx].coverageType]?.providerId == null || isProductDisabled

            return (
              <FIProductCard
                key={idx}
                color={(!isDealForm || isProductAdded) ? '#ECF4FF' : '#FAFAFA'}
                headerContent={
                  <div className='fiproduct-card-headerContent'>
                    {card.icon}
                    <div>
                      <h4>{card.title}</h4>

                      {!isDealForm && markups != null && <PriceConfiguration onApplyMarkupChanges={onApplyMarkupChanges} markup={markups[card.id]} />}
                    </div>
                  </div>
              }
              >
                <CoverageForm
                  idx={idx}
                  {...form}
                  isDealForm={isDealForm}
                  {...card.coverageFormProps}
                  onProductAdd={onProductAdd}
                  penPackages={penPackages[card.id]}
                  coverageSections={coverageSections}
                  isProductDisabled={isProductDisabled}
                  fieldPathPrefix={`${fieldPathPrefix}[${idx}]`}
                  penFormAdditionalProps={penFormAdditionalProps}
                  isAdded={addedProducts.includes(coverageSections[idx].productTypeId)}
                />

                {onProductAdd != null && (
                  <Button
                    isDisabled={isButtonDisabled}
                    onClick={() => onProductAdd(coverageSections[idx].productTypeId)}
                    className={cnx('add-product-button', isProductAdded && 'is-added')}
                  >
                    {isProductAdded ? 'Added to deal' : 'Select'}
                  </Button>
                )}
              </FIProductCard>
            )
          })
        }
      </div>
    </div>
  )
}

export default FIProductSetup
