import { useState, useCallback, useMemo } from 'react'
import { isOk, type Result, useForm, useModal } from '@carfluent/common'

import CustomersCoreApiProvider from 'api/customersCore.api'
import { type DealModel, type FinancingFeesModel } from 'api/types/responses'
import { PaymentMethod } from 'api/types'
import { GET_FINANCING_FEES_MODEL, GET_FINANCES_PAYMENT_DETAILS_MODEL } from 'api/defaults/parsed'
import { PaymentStatusId } from 'types/number_enums'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useDealRecap from 'hooks/deals/useDealRecap'
import useUnsavedChanges from 'hooks/deals/useUnsavedChanges'
import _isFinancing from 'utils/deals/isFinancing'
import { roundCurrency } from 'utils/math'

import validationRules from './validator'
import serializeFinancingFees from './serializer'
import { type UseDealFinancesProps, type UseDealFinancesReturn } from './types'

const useDealFinances = ({
  cachedDealFinances,
  dealId,
  deal,
  isDealCompleted,
  onLoadSalesCommission,
  onSaveChanges,
  onTrySwitchTab,
  nextTab,
  paymentDetails = [],
  saveLoadedFinancesToCache,
  tabIdx
}: UseDealFinancesProps): UseDealFinancesReturn => {
  const [amount, setAmount] =
    useState<number | null>(null)

  const [isSubmittingPayment, setIsSubmittingPayment] =
    useState(false)

  const { showAlert, showSuccess } = useCustomSnackbar()

  const {
    dealFinancesData,
    isLoading: isDealRecapDataLoading,
    refreshData
  } = useDealRecap({
    onSuccess: saveLoadedFinancesToCache,
    shouldLoadData: true
  })

  const {
    cashPaymentDetails,
    ...otherPaymentDetails
  } = GET_FINANCES_PAYMENT_DETAILS_MODEL(paymentDetails)

  const isDisabledConfirm = (amount == null) ||
    (cashPaymentDetails?.amount == null) ||
    (amount !== cashPaymentDetails.amount)

  const shouldBeConfirmed = !isDealCompleted &&
    ((cashPaymentDetails?.paymentMethodId === PaymentMethod.Full) &&
      (cashPaymentDetails?.paymentStatusId === PaymentStatusId.Pending))

  const isFinancing = _isFinancing(deal?.dealFinanceTypeId)

  const baseValues = useMemo(() => {
    return GET_FINANCING_FEES_MODEL(deal?.financingFees)
  }, [deal])

  const submitAction = useCallback(async (values: FinancingFeesModel): Promise<DealModel | null> => {
    if (deal?.rowVersion == null) {
      return null
    }

    const payload = serializeFinancingFees(dealId, deal.rowVersion, values)
    const result = await CustomersCoreApiProvider.updateDealFinancingFees(payload)
    await onLoadSalesCommission(dealId)

    return result
  }, [dealId, deal?.rowVersion])

  const onActionResult = useCallback((res: Result<DealModel>, resetForm: (val: FinancingFeesModel) => void): void => {
    if (isOk(res)) {
      onSaveChanges(res.result)
      resetForm(GET_FINANCING_FEES_MODEL(res.result?.financingFees))
      showSuccess('Changes successfully saved.')
      refreshData()
    }
  }, [onSaveChanges, showSuccess, refreshData])

  const form = useForm<FinancingFeesModel>({
    baseValues,
    isTrackingChanges: true,
    onActionResult,
    submitAction,
    validationRules
  })

  const modalUnsavedChangesProps = useUnsavedChanges({
    onTrySwitchTab,
    shouldShowUnsavedChanges: (nextTab.id !== tabIdx) && form.hasChanges,
    trigger: nextTab
  })

  const modalCashPaymentProps = useModal()

  const _dealFinances = dealFinancesData // ?? cachedDealFinances
  const _reserve = _dealFinances?.reserve ?? null
  const _backGross = _dealFinances?.backGross
  const _totalGross = _dealFinances?.totalGross
  const dealFinances = _dealFinances == null
    ? null
    : {
        ..._dealFinances,
        dealRecap: {
          ..._dealFinances,
          buyRate: form.values.buyRate ?? _dealFinances?.buyRate,
          backGross: _backGross == null
            ? null
            : roundCurrency(_backGross - (_reserve ?? 0) + (form.values.reserve ?? 0)),
          reserve: form.values.reserve ?? _reserve,
          totalGross: _totalGross == null
            ? null
            : roundCurrency(_totalGross - (_reserve ?? 0) + (form.values.reserve ?? 0))
        }
      }

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onCloseCashPayment = useCallback(() => {
    setAmount(null)
    modalCashPaymentProps.onCloseModal()
  }, [modalCashPaymentProps.onCloseModal])

  const onConfirmCashPayment = useCallback(async (): Promise<void> => {
    if (amount == null) {
      return
    }

    try {
      setIsSubmittingPayment(true)
      const deal = await CustomersCoreApiProvider.updateDealCash({ dealId, data: { amount } })
      onSaveChanges(deal)
      onCloseCashPayment()

      showAlert('Cash payment has been confirmed successfully.', { variant: 'success' })
    } catch (err) {
      showAlert(err)
    } finally {
      setIsSubmittingPayment(false)
    }
  }, [
    amount,
    onSaveChanges,
    onCloseCashPayment,
    showAlert
  ])

  const onChangeConfirmAmount = useCallback((_: string, value: number | null): void => {
    setAmount(value)
  }, [])

  // ========================================== //

  return {
    ...otherPaymentDetails,
    amount,
    dealFinances,
    formProps: form,
    isDealRecapDataLoading,
    isFinancing,
    modalUnsavedChangesProps,
    modalCashPaymentProps: {
      ...modalCashPaymentProps,
      isDisabled: isDisabledConfirm,
      isSubmitting: isSubmittingPayment,
      onChange: onChangeConfirmAmount,
      onSubmit: onConfirmCashPayment,
      onCloseModal: onCloseCashPayment
    },
    shouldBeConfirmed
  }
}

export default useDealFinances
