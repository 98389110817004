import { css } from '@emotion/css'

export default css(`
  display: flex;

  .cf-form-field {
    flex: 1;

    :not(:last-of-type) {
      margin-right: 16px;
    }
  }
`)
