import { useCallback, useEffect, useMemo } from 'react'
import {
  type UseFormReturn,
  getEnhancedResultHandler,
  useForm,
  FormValidation,
  isOk
} from '@carfluent/common'

import type { PickedFormDataProps } from 'types'
import { getParsedToken } from 'services/storage.service'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { LogType } from 'pages/crm/LeadDetailsView/hook/types'
import { Messages } from 'pages/crm/LeadDetailsView/components/LeadDetailsTasks/components/TaskComplete/hook/constants'

import { ErrTouchShortcuts, FieldIds, type LogActivityFormData } from './types'
import { getDefaultFormData, VISIT_SUB_OPTIONS } from './constants'
import {
  activityLogFormValidation,
  activityLogOtherFormValidation,
  activityLogVisitFormValidation
} from './validator'
import { getCurrentDateTime } from './utils'

export type PickedUseLeadLogsFormReturn = Pick<
UseFormReturn<LogActivityFormData, ErrTouchShortcuts>,
PickedFormDataProps | 'isSubmitting' | 'onSubmit' |'setErrors'
>

export interface UseLeadLogsFormReturn extends PickedUseLeadLogsFormReturn {
  onChangeSelectedCheckbox: (id: string, value: boolean) => void
}

interface UseLeadLogsProps {
  logTypeId: LogType
  onCloseActivity: () => void
  onSubmitActivityLog: (values: LogActivityFormData, logTypeId: LogType) => void
}

const ValidatorMap: Record<LogType, FormValidation<LogActivityFormData>> = {
  [LogType.Call]: activityLogFormValidation,
  [LogType['Store visit']]: activityLogVisitFormValidation,
  [LogType.Other]: activityLogOtherFormValidation
}

const DEFAULT_DATA = getDefaultFormData()

const useLeadLogs = ({
  logTypeId,
  onCloseActivity,
  onSubmitActivityLog
}: UseLeadLogsProps): UseLeadLogsFormReturn => {
  const { showAlert } = useCustomSnackbar()

  const validator = useMemo(() => ValidatorMap[logTypeId], [logTypeId])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const submitAction = useCallback(async (values: LogActivityFormData): Promise<void> => {
    void onSubmitActivityLog(values, logTypeId)
  }, [onSubmitActivityLog, logTypeId])

  const _onActionResult = useCallback((res) => {
    if (isOk(res)) {
      onCloseActivity()
    }
  }, [onCloseActivity])

  const onActionResult = useMemo(() => {
    return getEnhancedResultHandler<LogActivityFormData, ErrTouchShortcuts>(
      _onActionResult, showAlert, undefined, Messages.SuccessLogCreate
    )
  }, [_onActionResult, showAlert])

  const form = useForm<LogActivityFormData, ErrTouchShortcuts>({
    baseValues: DEFAULT_DATA,
    submitAction,
    onActionResult,
    validationRules: validator
  })

  const {
    isSubmitting,
    values,
    errors,
    touched,
    onBlur,
    onChange,
    onSubmit,
    setErrors,
    resetForm
  } = form

  const onChangeSelectedCheckbox = useCallback((id: string, value: boolean): void => {
    const option = VISIT_SUB_OPTIONS.find(opt => opt.id === id)

    if (value) {
      const result = [...values.activityCompleteSubOptions, option?.name]
      onChange(FieldIds.ActivityCompleteSubOptions, result)

      return
    }

    const result = values.activityCompleteSubOptions.filter(item => item !== option?.name)
    onChange(FieldIds.ActivityCompleteSubOptions, result)
  }, [onChange, values.activityCompleteSubOptions])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const dateTime = getCurrentDateTime()
    resetForm({ ...DEFAULT_DATA, ...dateTime })
  }, [resetForm, logTypeId])

  useEffect(() => {
    const token = getParsedToken()
    const LOGGED_USER_OPTION = { name: String(token?.name), id: Number(token?.sub) }

    if (values.assignedUser == null) {
      onChange(FieldIds.AssignedTo, LOGGED_USER_OPTION)
    }
  }, [logTypeId, values.assignedUser, onChange])

  // ========================================== //

  return {
    isSubmitting,
    values,
    errors,
    touched,
    onBlur,
    onChange,
    onSubmit,
    setErrors,
    onChangeSelectedCheckbox
  }
}

export default useLeadLogs
