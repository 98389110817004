import { EntityTypeId, TransactionLineCustomerDto, EntityListItem } from 'api/types'

const parseCustomer = (customer: TransactionLineCustomerDto | null): EntityListItem | null => {
  if (customer == null) {
    return null
  }

  return {
    id: customer.id,
    name: customer.businessName ?? `${customer.firstName ?? ''} ${customer.lastName ?? ''}`.trim(),
    email: customer.email,
    phoneNumber: customer.phoneNumber,
    entityType: EntityTypeId.Customer
  }
}

export default parseCustomer
