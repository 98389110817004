import type { ConfirmedPayment, PaymentMethod } from './types/stripe.types'

interface BillingDetails {
  cardNumber: string
  cardHolderName: string
  cardExpirationMonth: string
  caedExpirationYear: string
  cardCvv: string
  zipCode?: string
}

class StripeApi {
  async getStripePaymentMethods (accountId: string, details: BillingDetails): Promise<PaymentMethod> {
    const cardDetails: Record<string, string> = {
      'card[number]': details.cardNumber,
      'card[exp_month]': details.cardExpirationMonth,
      'card[exp_year]': details.caedExpirationYear,
      'card[cvc]': details.cardCvv,
      'billing_details[name]': details.cardHolderName
    }

    if (details.zipCode != null && details.zipCode !== '') {
      cardDetails['billing_details[address][postal_code]'] = details.zipCode
    }

    const paymentMethodPayload = new URLSearchParams()

    Object.keys(cardDetails).forEach(key => {
      paymentMethodPayload.append(key, cardDetails[key])
    })

    const paymentMethodRes = await fetch('https://api.stripe.com/v1/payment_methods', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_KEY ?? ''}`,
        'Stripe-Account': accountId,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `type=card&${paymentMethodPayload.toString()}`
    })

    const paymentMethodData = await paymentMethodRes.json()
    return paymentMethodData
  }

  async confirmStripePayment (
    accountId: string, clientSecret: string, paymentMethodId: string
  ): Promise<ConfirmedPayment> {
    const confirmRes = await fetch(`https://api.stripe.com/v1/payment_intents/${clientSecret.split('_secret_')[0]}/confirm`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_KEY ?? ''}`,
        'Stripe-Account': accountId,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `payment_method=${paymentMethodId}&client_secret=${clientSecret}`
    })
    const confirmData = await confirmRes.json()
    return confirmData
  }
}

export const StripeApiProvider = new StripeApi()
