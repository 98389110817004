import { useState, useMemo, useCallback, useEffect } from 'react'
import { useLoader } from '@carfluent/common'
import type { Row } from '@tanstack/react-table'
import type { ColumnDef } from '@carfluent/common/dist/UI'

import type { DictionaryItem, WorkPlanEventDto, TaskTypeFormOptions } from 'api/types'
import CRMApiProvider from 'api/crm.api'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'

import { type SubmitReason } from '../components/WorkPlanEventModal/hook/types'
import {
  ERROR_MESSAGE,
  MAX_RULES,
  MAX_RULES_ERROR,
  RULE_DELETE_SUCCESS_MESSAGE
} from '../components/WorkPlanEventModal/hook/constants'
import { getRulesPerStatus, getUpdatedMap } from './utils'
import { parseTableInfo } from './parser'
import { getColumnsDef } from './columns'

interface UseRulesReturn {
  onCloseModal: () => void
  leadStatus: DictionaryItem | null
  onConfirmDelete: () => Promise<void>
  onDeleteRule: () => Promise<void>
  onCloseDeleteModal: () => void
  isLoading: boolean
  onRowClick: (row: Row<WorkPlanEventDto>) => void
  dataToUpdate: WorkPlanEventDto | null
  onSubmitRuleForm: (rule: WorkPlanEventDto, reason: SubmitReason) => void
  columnDefs: Record<number, Array<ColumnDef<WorkPlanEventDto>>>
  tableInfo: Map<DictionaryItem, WorkPlanEventDto[]>
  isDeleteModalOpened: boolean
}

export const useRules = (): UseRulesReturn => {
  const { showAlert } = useCustomSnackbar()
  const [tableInfo, setTableInfo] = useState<Map<DictionaryItem, WorkPlanEventDto[]>>(new Map())
  const [leadStatus, setLeadStatus] = useState<DictionaryItem | null>(null)
  const [leadStatuses, setLeadStatuses] = useState<DictionaryItem[]>([])
  const [taskTypes, setTaskTypes] = useState<TaskTypeFormOptions[]>([])
  const [deletedRule, setDeletedRule] = useState<WorkPlanEventDto | null>(null)
  const [dataToUpdate, setDataToUpdate] = useState<WorkPlanEventDto | null>(null)
  const { stopLoader, startLoader, isLoading } = useLoader()

  const toggleActivate = useCallback(async (leadStatus: DictionaryItem, rule: WorkPlanEventDto) => {
    setTableInfo((prev) => getUpdatedMap({
      map: prev,
      leadStatus,
      rule,
      reason: 'update'
    }))

    try {
      await CRMApiProvider.updateEvent(rule)
    } catch {
      setTableInfo((prev) => getUpdatedMap({
        map: prev,
        leadStatus,
        rule: { ...rule, isActive: !(rule.isActive === true) },
        reason: 'update'
      }))

      showAlert(ERROR_MESSAGE)
    }
  }, [showAlert])

  const onOpenDeleteModal = useCallback((rule: WorkPlanEventDto) => {
    setDeletedRule(rule)
  }, [])

  const onCloseDeleteModal = useCallback(() => {
    setDeletedRule(null)
  }, [])

  const onOpenModal = useCallback((leadStatus: DictionaryItem) => {
    const rulesPerStatus = getRulesPerStatus(tableInfo, leadStatus)

    if (dataToUpdate == null && rulesPerStatus?.length === MAX_RULES) {
      showAlert(MAX_RULES_ERROR)

      return
    }

    setLeadStatus(leadStatus)
  }, [dataToUpdate, tableInfo, showAlert])

  const onCloseModal = useCallback(() => {
    setLeadStatus(null)
    setDataToUpdate(null)
  }, [])

  const onDelete = useCallback(async (rule, leadStatus) => {
    try {
      await CRMApiProvider.deleteEvent(rule.id)

      setTableInfo((prev) => getUpdatedMap({
        map: prev,
        leadStatus,
        rule,
        reason: 'delete'
      }))

      showAlert(RULE_DELETE_SUCCESS_MESSAGE, { variant: 'success' })
      onCloseDeleteModal()
      onCloseModal()
    } catch {
      showAlert(ERROR_MESSAGE)
    }
  }, [onCloseModal, onCloseDeleteModal, showAlert])

  const onConfirmDelete = useCallback(async () => {
    if (deletedRule == null) {
      return
    }

    const leadStatus = leadStatuses.find(({ id }) => deletedRule.leadStatusId === id)

    startLoader()
    await onDelete(deletedRule, leadStatus)
    stopLoader()
  }, [
    deletedRule,
    leadStatuses,
    onDelete,
    stopLoader,
    startLoader
  ])

  const onDeleteRule = useCallback(async () => {
    if (dataToUpdate == null && leadStatus == null) {
      return
    }

    await onDelete(dataToUpdate, leadStatus)
  }, [dataToUpdate, leadStatus, onDelete])

  const onRowClick = useCallback(({ original: event }: Row<WorkPlanEventDto>) => {
    setDataToUpdate(event)

    const leadStatus = leadStatuses.find(({ id }) => id === event.leadStatusId)

    if (leadStatus != null) {
      setLeadStatus(leadStatus)
    }
  }, [leadStatuses])

  const onSubmitRuleForm = useCallback((rule: WorkPlanEventDto, reason: SubmitReason) => {
    if (reason === 'create') {
      setTableInfo((prev) => getUpdatedMap({
        map: prev,
        leadStatus,
        rule: {
          ...rule,
          templateName: rule.templateName === null
            ? taskTypes?.find((type) => type.id === rule.taskTypeId)?.name ?? ''
            : rule.templateName
        },
        reason: 'add'
      }))
    } else {
      setTableInfo((prev) => getUpdatedMap({
        map: prev,
        leadStatus,
        rule: {
          ...rule,
          templateName: rule.templateName === null
            ? taskTypes?.find((type) => type.id === rule.taskTypeId)?.name ?? ''
            : rule.templateName
        },
        reason: 'update'
      }))
    }

    onCloseModal()
  }, [onCloseModal, leadStatus])

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      const [
        { items: statuses },
        { events },
        { items: taskTypes }
      ] = await Promise.all([
        CRMApiProvider.getLeadStatuses(),
        CRMApiProvider.getEvents(),
        CRMApiProvider.getTaskTypes()
      ])

      const updatedEvents = events.map((event) => ({
        ...event,
        templateName: event.templateName === null
          ? taskTypes?.find((type) => type.id === event.taskTypeId)?.name ?? ''
          : event.templateName
      }))

      const data = parseTableInfo(statuses, updatedEvents)

      setTableInfo(data)
      setLeadStatuses(statuses)
      setTaskTypes(taskTypes)
    }

    void runEffect()
  }, [])

  const columnDefs = useMemo(() => {
    return getColumnsDef({
      tableItem: tableInfo,
      onOpenModal,
      toggleActivate,
      onOpenDeleteModal
    })
  }, [tableInfo, toggleActivate, onOpenModal, onOpenDeleteModal])

  return {
    onCloseModal,
    leadStatus,
    onCloseDeleteModal,
    onConfirmDelete,
    isLoading,
    onRowClick,
    dataToUpdate,
    onSubmitRuleForm,
    columnDefs,
    tableInfo,
    onDeleteRule,
    isDeleteModalOpened: deletedRule != null
  }
}
