import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'
import set from 'lodash-es/set'

import { type FloorPlanDto, type PackCostDto } from 'api/types'
import { type FloorplanFormData, type PackCostFormData } from './types'
import { GET_DEFAULT_FLOORPLAN, GET_DEFAULT_PACK_COST } from '../../components/CostsTabPanel'

class CostsTabPanel {
  isTabDirty: boolean = false

  setTabDirty = (isDirty: boolean): void => {
    this.isTabDirty = isDirty
  }

  /**
   * Pack Costs
   */

  originalPackCost: PackCostDto | null = null
  packCostFormData: PackCostFormData = GET_DEFAULT_PACK_COST()
  packCostResetTS: number | null = null
  packCostTouchifyTS: number | null = null
  isPackCostFormValid = true
  isPackCostDirty = false

  setOriginalPackCost = (value: PackCostDto | null): void => { this.originalPackCost = value }
  setPackFormData = (value: PackCostFormData): void => { this.packCostFormData = value }
  setPackFormIsValid = (isValid: boolean): void => { this.isPackCostFormValid = isValid }
  askPackFormReset = (): void => { this.packCostResetTS = Date.now() }
  askPackFormShowErrors = (): void => { this.packCostTouchifyTS = Date.now() }

  updatePackFormData = (id: string, value: unknown): void => {
    set(this.packCostFormData, id, value)
    this.isPackCostDirty = true
    this.setTabDirty(true)
  }

  /**
   * Floorplan
   */

  originalFloorplan: FloorPlanDto | null = null
  floorplanFormData: FloorplanFormData = GET_DEFAULT_FLOORPLAN()
  floorplanResetTS: number | null = null
  floorplanTouchifyTS: number | null = null
  isFloorplanFormValid = true
  isFloorplanDirty = false

  setOriginalFloorplan = (value: FloorPlanDto | null): void => { this.originalFloorplan = value }
  setFloorplanFormData = (value: FloorplanFormData): void => { this.floorplanFormData = value }
  setFloorplanIsValid = (isValid: boolean): void => { this.isFloorplanFormValid = isValid }
  askFloorplanFormReset = (): void => { this.floorplanResetTS = Date.now() }
  askFloorplanFormShowErrors = (): void => { this.floorplanTouchifyTS = Date.now() }

  updateFloorplanFormData = (id: string, value: unknown): void => {
    set(this.floorplanFormData, id, value)
    this.isFloorplanDirty = true
    this.setTabDirty(true)
  }

  // ========================================== //

  cleanUp = (): void => {
    this.setTabDirty(false)

    this.setOriginalFloorplan(null)
    this.setFloorplanFormData(GET_DEFAULT_FLOORPLAN())
    this.setFloorplanIsValid(true)
    this.floorplanResetTS = null
    this.floorplanTouchifyTS = null
    this.isFloorplanDirty = false

    this.setOriginalPackCost(null)
    this.setPackFormData(GET_DEFAULT_PACK_COST())
    this.setPackFormIsValid(true)
    this.packCostResetTS = null
    this.packCostTouchifyTS = null
    this.isPackCostDirty = false
  }

  constructor () {
    makeAutoObservable(this)
  }
}

export const CostsTabPanelInstance = new CostsTabPanel()
export const CostsTabPanelCTX = createContext(CostsTabPanelInstance)
export default CostsTabPanelCTX
