import type { ArrayReducerAction } from './types'

interface ReducerState<T> {
  items: T[]
}

interface ItemWithId {
  id?: number
}

function reducer<T extends ItemWithId> (draft: ReducerState<T>, { type, payload }: ArrayReducerAction<T>): void {
  switch (type) {
    case 'SET_ITEMS': {
      draft.items = payload
      break
    }
    case 'ADD_ITEM': {
      draft.items.push(payload)
      break
    }
    case 'EDIT_ITEM': {
      const shiftIdx = draft.items.findIndex((s) => s.id === payload?.id)
      if (shiftIdx > -1) {
        draft.items[shiftIdx] = { ...draft.items[shiftIdx], ...payload }
      }
      break
    }
    case 'REMOVE_ITEM': {
      draft.items = draft.items.filter((s) => s.id !== payload?.id)
      break
    }
    default: {
      throw new Error('Unsupported action type')
    }
  }
}

export default reducer
