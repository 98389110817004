import { serializers } from '@carfluent/common'
import pick from 'lodash-es/pick'
import { toJS } from 'mobx'

import {
  type DictionaryItem,
  type VehicleByIdPatch,
  type VehicleImage,
  type VehicleRepricingDetails,
  VehicleMediaTypeId
} from 'api/types'
import type { ImageDescriptor } from 'components/inventory/ImageItem'
import { keys } from 'utils/general'
import { serializeNumber } from 'utils/serializers'
import { serializeDescriptionToHtml, serializeDescriptionToPlainText } from 'utils/wysiwyg'
import { serializeTrim } from 'components/common/CarDetailsFields/hook/serializer'

import type { UpdateVehicleFormData } from './types'
import { DEFAULT_VEHICLE } from './constants'

const { serializeDate, serializeDateTime } = serializers

const serializeInventoryDate = (d: Date | null, defaultDate: string | null): string => {
  const date = serializeDate(d)

  if (date == null) {
    return ''
  }

  const serializedUpdatedDate = serializeDateTime(d)
  const isDateChanged = serializedUpdatedDate !== defaultDate

  if (isDateChanged || defaultDate == null) {
    const now = new Date()
    const hours = String(now.getUTCHours()).padStart(2, '0')
    const minutes = String(now.getUTCMinutes()).padStart(2, '0')
    const seconds = String(now.getUTCSeconds()).padStart(2, '0')

    return `${date}T${hours}:${minutes}:${seconds}`
  }

  return defaultDate
}

const serializeVehicle = (
  formData: UpdateVehicleFormData,
  photos: ImageDescriptor[],
  defaultDate: string | null,
  defaultRepricing: VehicleRepricingDetails | null
): VehicleByIdPatch => {
  const data = toJS(formData)
  const vehicleImages = toJS(photos).map(serializeFileDescriptor)

  const mainImageUrl = vehicleImages.find(item => item.originalUrl === data.mainImageUrl)?.originalUrl ??
    vehicleImages.filter((item) => item.vehicleMediaTypeId === VehicleMediaTypeId.Image)[0]?.originalUrl ?? null

  const vehicleRepricingDetails = defaultRepricing == null && data.vehicleRepricingDetails?.isEnabled !== true
    ? null
    : data.vehicleRepricingDetails

  return {
    ...pick(data, keys(DEFAULT_VEHICLE).filter(key => key !== 'buyer')),
    year: serializeNumber(data.year),
    salePrice: serializeNumber(data.salePrice),
    mileage: serializeNumber(data.mileage),
    vehicleRepricingDetails,
    /**
     * we want to preserve dateTime format for inventoryDate. We do not need to use UTC serialization here
     * as it should already be in UTC, so we do not need to offset it.
     */
    inventoryDate: serializeInventoryDate(data.inventoryDate, defaultDate),
    titleReceived: serializeDate(data.titleReceived),
    titleSent: serializeDate(data.titleSent),
    titleType: data.titleStatus?.id ?? null,
    titleStageId: data.titleStage?.id ?? null,
    registrationStageId: data.registrationStage?.id ?? null,
    titleStateId: data.titleState?.id ?? null,
    bodyType: data.body?.id ?? null,
    doorCount: data.doors?.id ?? null,
    fuelType: data.fuelType?.id ?? null,
    cylinderCount: data.cylinderCounts?.id ?? null,
    transmissionType: data.transmission?.id ?? null,
    drivetrainType: data.drivetrain?.id ?? null,
    genericColor: data.genericColor?.id ?? null,
    genericInterior: data.genericInterior?.id ?? null,
    isDisabilityEquipped: Boolean(data.disabilityEquipped?.id),
    description: serializeDescriptionToHtml(data.description),
    descriptionText: serializeDescriptionToPlainText(data.description),
    vehicleImages,
    mainImageUrl,
    trim: serializeTrim(data.trim),
    buyerId: (data.buyer as DictionaryItem)?.id ?? null,
    titleNote: data.titleNote
  }
}

export default serializeVehicle

const serializeFileDescriptor = (file: ImageDescriptor, idx: number): VehicleImage => {
  const isVideo = ['.mp4', '.mov'].includes(file?.fileExtension?.toLowerCase() ?? '')

  return isVideo
    ? {
        originalUrl: '',
        fileName: file.fileName,
        order: idx,
        originalSizeKb: file.originalSizeKb ?? 0,
        fileId: file.id ?? file.fileId ?? null,
        vehicleMediaTypeId: VehicleMediaTypeId.Video
      }
    : {
        originalUrl: file.originalUrl,
        thumbnailUrl: file.thumbnailUrl,
        fileName: file.fileName,
        order: idx,
        originalSizeKb: file.originalSizeKb ?? 0,
        fileId: null,
        vehicleMediaTypeId: VehicleMediaTypeId.Image
      }
}
