import {
  EntityTypeId,
  type TransactionLineVendorDto,
  type EntityListItem
} from 'api/types'

const parseVendor = (vendor: TransactionLineVendorDto | null): EntityListItem | null => {
  if (vendor == null) {
    return null
  }

  return {
    id: vendor.id,
    name: vendor.name ?? '',
    email: vendor.email,
    phoneNumber: vendor.phoneNumber,
    entityType: EntityTypeId.Vendor
  }
}

export default parseVendor
