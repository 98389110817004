import { css } from '@emotion/css'

export default css(`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  max-height: 84px;

  .cell-image {
    margin-right: 10px;
    object-fit: cover;
    min-width: 80px;
    max-width: 80px;
    max-height: 50px;
    height: 100%;
    flex: 1;
  }
  
  .cell-description {
    padding: 4px 0;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .car-name-wrapper {
    max-width: 16vw;
    min-width: 218px;
  }

  .bold-text {
    font-weight: 500;
  }
  
  .mileage-wrapper {
    display: flex;
    align-items: center;
  }
  
  .deposit-label {
    margin-left: 8px;
  }

  .cell-info-icon {
    padding: 0 6px;

    > div {
      width: 36px;
      height: 36px;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #E2EEFF60;
      }
    }
  }
`)

export const TOOLTIP_CLASS_NAME = css(`
  .car-info-container {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .car-info-item {
    display: grid;
    grid-template-columns: 64px auto;
    gap: 8px;
    margin-bottom: 8px;
  }

  .car-info-title {
    color: #BCBCBC;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
  }

  .car-info-value {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
  }
`)
