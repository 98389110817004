import { useState, useCallback, useContext, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

import ws from 'services/web_sockets'
import { UserRoles, type DictionaryItem } from 'api/types'
import IdentityApiProvider from 'api/identity.api'
import AuthCTX from 'store/auth'
import SettingsCTX from 'store/settings'
import { startSpinner, stopSpinner } from 'store/global_ui'
import { getAccessToken, getParsedToken } from 'services/storage.service'
import { navigateToUserDefaultRoute } from 'pages/auth/utils'
import { isFalsy } from 'utils/general'
import mappedRoutes from 'constants/redirectHelper'
import { Routes } from 'constants/route_helper'

export interface UseHeaderReturn {
  isAdmin: boolean
  isAccessChangedModalOpen: boolean
  dealers: DictionaryItem[]
  activeDealer: DictionaryItem | null
  onChange: (id: string, value: DictionaryItem | null) => void
  onOkClick: () => void
  onOpenUserManagement: () => void
}

const useHeader = (): UseHeaderReturn => {
  const navigate = useNavigate()
  const accessToken = getAccessToken()
  const location = useLocation()
  const { userRoles, changeDealer } = useContext(AuthCTX)
  const { dealer, dealersActionWithLoading, dealersAction } = useContext(SettingsCTX)
  const { subscriptionTypeName } = dealer ?? { subscriptionTypeName: null }

  const [dealers, setDealers] = useState<DictionaryItem[]>([])
  const [isAccessChangedModalOpen, setAccessChangedModalOpen] = useState<boolean>(false)
  const [okClickCount, setOkClickCount] = useState(0)
  const refActiveDealer = useRef<DictionaryItem | null>(null)

  const isAdmin = userRoles.includes(UserRoles.SuperAdmin) || userRoles.includes(UserRoles.Admin)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const setAnotherDealer = useCallback(async (dealerId: string | number, accessToken: string) => {
    try {
      return await changeDealer(dealerId, accessToken)
    } catch (e) {
      /**
       * AS-NOTE:
       * now such case is not handled properly in wrapper.api
       * 401 error for 'connect/token' request
       * rework this when new api wrapper will be ready
       */
      if (axios.isAxiosError(e) && e.response?.status === 401) {
        const res = await IdentityApiProvider.refreshAccessToken()
        return await changeDealer(dealerId, res.access_token)
      }
      throw e
    }
  }, [changeDealer])

  const redirect = useCallback(async (loggedUserRoles: UserRoles[] | undefined): Promise<void> => {
    const redirectToMappedRoute = mappedRoutes.find(path => location.pathname.includes(path))

    if (redirectToMappedRoute != null) {
      navigate(redirectToMappedRoute)
    } else {
      navigateToUserDefaultRoute(loggedUserRoles, navigate)
    }
  }, [location.pathname, navigate])

  const onChangeDealer = useCallback(async (dealerId: string | number): Promise<void> => {
    startSpinner()

    try {
      const loggedUserRoles = await setAnotherDealer(dealerId, accessToken)
      await redirect(loggedUserRoles)
      await ws.shutdown()

      await dealersActionWithLoading()
    } catch (e) {
      setAccessChangedModalOpen(true)
    } finally {
      stopSpinner()
    }
  }, [setAnotherDealer, accessToken, redirect, dealersActionWithLoading])

  const onOkClick = useCallback(() => {
    setAccessChangedModalOpen(false)
    setOkClickCount(prevCount => prevCount + 1)
  }, [])

  const onChange = useCallback((_, { id: dealerId }) => {
    void onChangeDealer(dealerId)
  }, [onChangeDealer])

  const onOpenUserManagement = useCallback(() => {
    navigate(Routes.UserManagement)
  }, [navigate])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const token = getParsedToken()
    if (token == null || token.available_dealer_ids.length <= 1) {
      setDealers([])
      return
    }

    const fetchAndUpdateDealers = async (): Promise<void> => {
      const { items } = await IdentityApiProvider.getAvailableDealers()
      const updatedDealers = items
      refActiveDealer.current = getCurrentDealerFromToken(token, updatedDealers)

      /**
         * we manually add current dealer where user was
         * use case:
         * when user lost access to current dealer and tried
         * to switch to dealer where he also lost access
         */
      if (items.every(dealer => dealer.id !== refActiveDealer.current?.id)) {
        updatedDealers.push(refActiveDealer.current)
      }

      updatedDealers.sort((a, b) => a.name.localeCompare(b.name))
      setDealers(updatedDealers)
    }

    void fetchAndUpdateDealers()

    return () => {} // suppresses warnings
  }, [okClickCount])

  useEffect(() => {
    if (userRoles.length === 0) {
      return
    }

    const runEffect = async (): Promise<void> => {
      if (isFalsy(subscriptionTypeName)) {
        await dealersAction()
      }
    }

    void runEffect()
  }, [subscriptionTypeName, userRoles, dealersAction])

  // ========================================== //

  return {
    isAdmin,
    isAccessChangedModalOpen,
    dealers,
    activeDealer: refActiveDealer.current,
    onChange,
    onOkClick,
    onOpenUserManagement
  }
}

export default useHeader

function getCurrentDealerFromToken (token: Record<string, any>, dealers: DictionaryItem[]): DictionaryItem {
  return dealers.find(({ id }) => id === Number(token.dealer)) ??
    { id: Number(token.dealer), name: token.dealer_name }
}
