import { useCallback, useState } from 'react'
import { CellWrapper, cn, FormNumberInput } from '@carfluent/common'

import type { CellFC, CellProps, KeyVal } from 'types'
import type { EditableCellProps } from 'components/common/Table/cells/editable_cell'
import ApplyPropsHOK from 'components/common/ApplyPropsHOC'
import Tooltip from 'components/common/Tooltip'

import CLASS_NAME, { TOOLTIP_POPOVER_CLASSES } from './styles'

export type InputCellHOCProps = EditableCellProps<string | number> & {
  isDecimal?: boolean
  maxValue?: number
  maxLength?: number
  isEmptyAllowed?: boolean
  isNegativeAllowed?: boolean
  isSelectedOnFocus?: boolean
  dataTestId?: string
  tooltipMessage?: string
}

export type InputCellProps<TRow extends KeyVal> = CellProps<TRow> & InputCellHOCProps

function InputCell<TRow extends KeyVal> ({
  className,
  column,
  getValue,
  label,
  maxValue,
  onChange,
  row,
  isEmptyAllowed = true,
  isDecimal = false,
  isNegativeAllowed = false,
  isSelectedOnFocus = false,
  dataTestId,
  tooltipMessage,
  maxLength
}: InputCellProps<TRow>): JSX.Element {
  const value = getValue() as string | number | null

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleChange = useCallback((_, value) => {
    onChange(row.index, column.id, value)
  }, [onChange, column.id, row.index])

  return (

    <CellWrapper
      className={cn(CLASS_NAME, 'cf-number-input-cell', className)}
    >
      <Tooltip
        isOpen={isOpen && row.original.isReconciled}
        popperClassName={TOOLTIP_POPOVER_CLASSES}
        title={tooltipMessage ?? ''}
        placement='top-end'
      >
        <div
          onMouseOver={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          <FormNumberInput
            mode={isDecimal ? 'decimal' : 'integer'}
            id={column.id}
            isEmptyAllowed={isEmptyAllowed}
            max={maxValue}
            onChange={handleChange}
          // TODO - move onBlur to transactions details hook
            onBlur={() => {
              if (!isEmptyAllowed && value == null) {
                onChange(row.index, column.id, 0)
              }
            }}
            maxLength={maxLength}
            placeholder={label}
            value={value}
            isNegativeAllowed={isNegativeAllowed}
            isSelectedOnFocus={isSelectedOnFocus}
            dataTestId={dataTestId}
            disabled={row.original.isReconciled}
          />
        </div>
      </Tooltip>

    </CellWrapper>

  )
}

function InputNumberCellHOK<TData extends KeyVal, TValue = unknown> (
  config: InputCellHOCProps
): CellFC<TData, TValue> {
  return ApplyPropsHOK(config, InputCell)
}

export default InputNumberCellHOK
