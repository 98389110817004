import { type FC, type ReactNode, useCallback, useMemo } from 'react'
import {
  cnx,
  FormDropdown,
  type Dropdown2Props,
  type DropdownAction,
  type FormCompatible,
  useModal
} from '@carfluent/common'

import { type TransactionLineVendorDto } from 'api/types'
import VendorDetails from 'pages/accounting/VendorDetails'
import Icon from 'components/common/Icon'

import { CLASS_NAME, POPOVER_CLASS_NAME } from './styles'

export enum SpecialId {
  AddVendor = 'AddVendor'
}

export type VendorValue = TransactionLineVendorDto | null

export interface VendorDropdownProps extends FormCompatible<Dropdown2Props<VendorValue, false>, VendorValue> {
  onAddVendor: (value: TransactionLineVendorDto) => Promise<void>
}

const VendorDropdown: FC<VendorDropdownProps> = ({
  className,
  onAddVendor,
  popoverClassName,
  renderAction,
  ...restProps
}) => {
  const vendorModalProps = useModal()

  const actions = useMemo(() => ([
    { id: SpecialId.AddVendor, name: 'Add Vendor', onClick: vendorModalProps.onOpenModal }
  ]), [vendorModalProps.onOpenModal])

  // ========================================== //
  //                    RENDER                  //
  // ========================================== //

  const renderVendorOption = useCallback((opt: DropdownAction): ReactNode => {
    if (opt.id === SpecialId.AddVendor) {
      return (
        <div className='cf-dropdown-option action action-add-vendor'>
          <Icon className='action-icon' name='plus' />
          <p className='action-name'>{opt.name}</p>
        </div>
      )
    }

    if (renderAction == null) {
      return (<p className='cf-option'>{opt.name}</p>)
    }

    return renderAction(opt)
  }, [renderAction])

  return (
    <>
      <FormDropdown
        {...restProps}
        actions={actions}
        popoverClassName={cnx(POPOVER_CLASS_NAME, popoverClassName)}
        className={cnx(CLASS_NAME, className)}
        renderAction={renderVendorOption}
      />

      <VendorDetails
        isOpen={vendorModalProps.isModalOpen}
        onClose={vendorModalProps.onCloseModal}
        onSubmit={onAddVendor}
      />
    </>
  )
}

export default VendorDropdown
