import type { FC } from 'react'

import Tab from 'components/common/Tabs/components/Tabs/Tab'

import DealDocument, { type DealDocumentsProps } from './DealDocument'

interface DealDocumentTabProps extends DealDocumentsProps {
  isSelected: boolean
}

const TabDealDocuments: FC<DealDocumentTabProps> = ({ isSelected, ...props }) => {
  return (
    <Tab isSelected={isSelected}>
      <DealDocument {...props} />
    </Tab>
  )
}

export default TabDealDocuments
