import { css } from '@emotion/css'
import { px, Responsive, ScreenWidthBreakpoints as SWB } from 'constants/constants'

export default css(`
  margin-bottom: 16px;

  .text-content {
    line-height: 20px!important;

    > div {
      line-height: 20px;

      &:not(:last-of-type, :empty) {
        margin-bottom: 4px;
      }
    }

    .tooltip-content {
      font-size: 14px;
    }
  }

  &.greyed-record {
    .timeline-item-header .timeline-item-title, p, span,
    .tooltip-content {
      color: #21212150;
    }
  } 
  
  .timeline-item-wrapper {
    margin-left: 8px;
    margin-top: 4px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);

    &.new-lead-received,
    &.lead-assigned-automatically {
      .name-wrapper {
        @media screen and (max-width: ${px(Responsive.Laptop)}) {
          overflow: hidden;
        }
      }
    }
    
    .timeline-item-content {
      min-width: 520px;
      max-width: calc((100vw / 2) - 125px);
      padding-left: 16px;

      @media screen and (max-width: ${px(SWB.Small)}) {
        max-width: 520px;
      }
      @media screen and (min-width: ${px(Responsive.Laptop)}) {
        max-width: calc((100vw / 2) - 125px);
      }
      @media screen and (min-width: ${px(SWB.Medium)}) {
        max-width: unset;
      }
    }

    .show-btn-wrapper {
      display: flex;
      height: 20px;
      justify-content: flex-end;

      button {
        font-size: 14px;
        letter-spacing: 0.4px;
        text-decoration: underline;
        text-underline-offset: 1px;
        padding-right: 0;
        padding-left: 0;
        background: none;
      }
    }

    .cf-button-variant-text {
      font-size: 14px;
    }  
  }
    
  .insert-video-link {
    display: block;
    height: 223px;
    width: 385px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`)
