import { css } from '@emotion/css'

export default css(`
  .disabled { opacity: 0.5; }

  .complete-task {
    display: grid;
    grid-template-areas:
      'dueToDate assignedTo priority'
      'description description description'
      'buttons buttons buttons';
    column-gap: 16px;
    row-gap: 0 !important;

    .lead-tasks-result-block {
      .label {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(33, 33, 33, 0.5);
      }

      img {
        height: 25px;
        position: relative;
        right: 5px;
      }
    }

    .task-dueToDate { grid-area: dueToDate; }
    .task-assignedTo { grid-area: assignedTo; }
    .task-priority { grid-area: priority; }
    .task-description { grid-area: description; }
  }

    .delete-lead-btn {
      display: flex;
      justify-content: center;
    }

    .lead-complete-task-action-buttons-container { margin-top: 16px; }

    .lead-tasks-action-buttons {
      line-height: 22px;
      width: max-content;
      border: 1px solid #C99B86;
      height: 26px;
      color: #000;
      
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
      }
    }
`)
