import { type FC, type ReactNode, useMemo } from 'react'
import { cnx } from '@carfluent/common'
import { Tooltip } from '@material-ui/core'

import useEllipsisHover from 'hooks/useEllipsisHover'

import createStyleClass from './styles'

interface TooltipedTextProps {
  value: ReactNode
  className?: string
  numberOfRows?: number
  rootClassName?: string
}

const TooltipedText: FC<TooltipedTextProps> = ({
  value,
  numberOfRows = 1,
  rootClassName = '',
  className = ''
}) => {
  const styles = useMemo(() => createStyleClass(numberOfRows), [numberOfRows])
  const tooltipClasses = useMemo(() => ({
    popper: styles.popper,
    tooltip: styles.tooltip
  }), [styles])

  const {
    isShowTooltip,
    titleRef,
    onMouseEnter,
    onMouseLeave
  } = useEllipsisHover({
    numberOfRows
  })

  return (
    <Tooltip
      open={isShowTooltip}
      title={value ?? ''}
      placement='top'
      classes={tooltipClasses}
    >
      <div
        className={cnx('cf-tooltip-container', styles.content, rootClassName)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <p
          ref={titleRef}
          className={cnx('tooltip-content', className)}
        >
          {value}
        </p>
      </div>
    </Tooltip>
  )
}

export default TooltipedText
