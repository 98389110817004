import type { FC } from 'react'

const NoContent: FC = () => {
  return (
    <tbody className='cf-row-filler cf-top-filler'>
      <tr>
        <td colSpan={8} className='cf-no-transactions'>
          <div className='cf-no-transactions-content'>No transactions yet</div>
        </td>
      </tr>
    </tbody>
  )
}

export default NoContent
