import type { FormValidation } from '@carfluent/common'
import type { OpenTaskFormData } from './types'

import {
  dateField,
  taskTime,
  dictionaryItemDropdownField
} from 'utils/validationPresets'

const dictionaryItemField = dictionaryItemDropdownField()
const dueDate = dateField('append')

export const createFormValidation: FormValidation<OpenTaskFormData> = {
  assignedUser: dictionaryItemField,
  dueDate,
  time: taskTime,
  taskPriority: dictionaryItemField
}

export const reminderFormValidation: FormValidation<OpenTaskFormData> = {
  ...createFormValidation,
  subject: dictionaryItemDropdownField(true)
}
