import { type FC } from 'react'
import { cn } from '@carfluent/common'

import Badge from 'components/common/Badge'

import { useTruncatedItems } from './hooks'
import type { UseTruncatedItemsProps } from './hooks'

import CLASS_NAME from './styles'

interface TruncatedItemsProps extends UseTruncatedItemsProps {
  className?: string
}

const TruncatedItems: FC<TruncatedItemsProps> = ({ className = '', ...props }) => {
  const {
    isOpen,
    isTruncatedButtonExist,
    popoverRef,
    onPopoverClick,
    onTogglePopover,
    itemsToRender,
    items,
    wrapperRef,
    onDelete
  } = useTruncatedItems(props)

  return (
    <div ref={wrapperRef} className={cn(CLASS_NAME, className)}>
      {items.slice(0, itemsToRender).map((item, index) => {
        return (
          <Badge
            key={`${index}_${item}`}
            message={item}
            onDelete={onDelete}
          />
        )
      })}

      {isTruncatedButtonExist && (
        <Badge
          className='cf-toggler'
          message='...'
          onClick={onTogglePopover}
        />
      )}

      {isOpen && (
        <div
          onClick={onPopoverClick}
          ref={popoverRef}
          className='cf-popover'
        >
          {items?.slice(itemsToRender)?.map((item) => (
            <Badge
              key={item}
              message={item}
              onDelete={onDelete}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default TruncatedItems
