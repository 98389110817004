import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'

import { formatPhoneNumber } from 'pages/accounting/Settings/components/utils'

interface PhoneCellHOCProps {
  withDash?: boolean
}

interface PhoneCellProps extends PhoneCellHOCProps {
  getValue: () => string
}

const PhoneCell: FC<PhoneCellProps> = (props) => {
  const value = formatPhoneNumber(props.getValue())

  return (
    <CellWrapper>
      <p>{props.withDash === true && value == null ? '-' : value}</p>
    </CellWrapper>
  )
}

export const PhoneCellHOC = ({ ...hocProps }: PhoneCellHOCProps) =>
  ({ ...props }: PhoneCellProps) => <PhoneCell {...hocProps} {...props} />

export default PhoneCell
