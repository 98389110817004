import {
  type DictionaryItem,
  type DictionaryItems,
  type VehicleDetailsByVin,
  type VehiclePartsDictionaries,
  CylinderCountsTypes,
  DriveTrainTypes,
  TransmissionTypes
} from 'api/types'

import { parseTrim } from 'components/common/CarDetailsFields/hook/parser'
import type { AddNewVehicleFormData } from './types'

export const parseTransmissionToForm = (dictionary: DictionaryItems<TransmissionTypes>, transmission: string | null): DictionaryItem<TransmissionTypes> | null => {
  return dictionary.find(d => transmission?.includes(d.name)) ?? null
}

export const parseDriveTrainToForm = (dictionary: DictionaryItems<DriveTrainTypes>, drivetrain: string | null): DictionaryItem<DriveTrainTypes> | null => {
  if (drivetrain === '4WD') {
    return dictionary
      .find(d => d.name.toLocaleLowerCase() === DriveTrainTypes.FourWheelDrive.toLocaleLowerCase()) ?? null
  }

  if (drivetrain === 'FWD') {
    return dictionary
      .find(d => d.name.toLocaleLowerCase() === DriveTrainTypes.FrontWheelDrive.toLocaleLowerCase()) ?? null
  }

  const driveTrains = dictionary
    .filter(d => d.name.split(' ')
      .map(word => word[0])
      .join('').toLocaleLowerCase() === drivetrain?.toLocaleLowerCase()
    )

  return driveTrains.length > 1 ? null : driveTrains[0]
}

export const parseCylindersToForm = (dictionary: DictionaryItems<CylinderCountsTypes>, engine: string | null): DictionaryItem<CylinderCountsTypes> | null => {
  /** V followed by numbers (numbers are cylinders count) */
  const cylindersMatch = engine?.match('V[0-9]+')

  if (cylindersMatch == null) {
    return dictionary
      .find(d => d.name === CylinderCountsTypes.Unspecified) ?? null
  }

  const cylinders = cylindersMatch[0]?.replace('V', '')
  return dictionary
    .find(d => d.name === cylinders) ?? null
}

/**
 * AZ-TODO: remove this  and all other "parsers".
 * It seems, API format changed and now we have proper "BaseListItems" for all these dicts.
 * WARNING: types here are wrong right now, need to update across the whole app.
 */
export const parseDictItem = <T>(
  dictionary: DictionaryItems<T>,
  itemId: number | null
): DictionaryItem<T> | null => {
  if (itemId == null) {
    return null
  }

  return dictionary.find(x => x.id === Number(itemId)) ?? null
}

export const parseVehicleDetailsToForm = (
  vehicle: AddNewVehicleFormData,
  data: VehicleDetailsByVin,
  dictionaries: VehiclePartsDictionaries
): AddNewVehicleFormData => {
  return {
    ...vehicle,
    body: parseDictItem(dictionaries.Body, data.bodyType),
    doors: parseDictItem(dictionaries.Doors, data.doorCount),
    drivetrain: parseDictItem(dictionaries.Drivetrain, data.drivetrainType),
    engine: data.engine,
    engineCylinders: parseDictItem(dictionaries.Cylinders, data.cylinderCount),
    fuelType: parseDictItem(dictionaries.Fuel, data.fuelType),
    genericColor: parseDictItem(dictionaries.GenericColor, data.genericColor),
    genericInterior: parseDictItem(dictionaries.GenericInterior, data.genericInterior),
    make: data.make,
    model: data.model,
    transmission: parseDictItem(dictionaries.Transmission, data.transmissionType),
    trim: parseTrim(data.trim),
    year: data.year
  }
}

export default parseVehicleDetailsToForm
