import { type AccountTransaction, type Account } from 'api/types'
import { type AccountTransactionRow, type AccountShortInfo } from './types'

export const parseTransactions = (rows: AccountTransaction[]): AccountTransactionRow[] => {
  return rows.map(parseTransactionLine)
}

export const parseTransactionLine = (line: AccountTransaction): AccountTransactionRow => {
  const { change, closingBalance, ...rest } = line

  return {
    ...rest,
    change,
    balance: closingBalance,
    debit: change > 0 ? change : 0,
    credit: change < 0 ? Math.abs(change) : 0
  }
}

export const parseAccountShortInfo = (account: Account): AccountShortInfo => {
  return {
    name: account.number != null ? `${account.number} - ${account.name}` : account.name,
    description: account.description,
    closingBalance: account.closingBalance,
    monthOpeningBalance: account.monthOpeningBalance,
    currentMonthBalance: account.currentMonthBalance
  }
}
