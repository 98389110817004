import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'

import TooltipedText from 'components/common/TooltipedText'

export interface OutcomeData {
  className?: string
  text: string | null
  numberOfRows?: number
}

interface OutcomeCellProps {
  getValue: () => OutcomeData
}

const OutcomeCell: FC<OutcomeCellProps> = ({ getValue }) => {
  const { className, text, numberOfRows = 1 } = getValue()

  return (
    <CellWrapper>
      <TooltipedText
        value={text ?? '-'}
        className={className}
        numberOfRows={numberOfRows}
      />
    </CellWrapper>
  )
}

export default OutcomeCell
