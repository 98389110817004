import { css } from '@emotion/css'

export default css(`
  --recon-width: 338px;
  --row1-height: 432px;
  --row2-height: 440px;
  
  &.g-content-layout {
    display: grid;
    grid-template-areas:
      'deals inventory recon'
      'crm   crm       recon';
    grid-template-columns: 1fr 1fr var(--recon-width);
    grid-template-rows: var(--row1-height) var(--row2-height);
    column-gap: 24px;
    row-gap: 24px;
  }
  
  .widget-deals { grid-area: deals; min-width: 319px; }
  .widget-inventory { grid-area: inventory; min-width: 319px; }
  .widget-recon { grid-area: recon; padding: 16px 0!important; }
  .widget-crm { grid-area: crm; }
  
  .widget { 
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px;
    background-color: #FFF;
  }
`)
