import { css } from '@emotion/css'

export const TOOLTIP_POPOVER_CLASSES = css(`
  &.MuiTooltip-popper[role='tooltip'] {
    z-index: 1200;
  }
`)

export const TOOLTIP_CONTENT_CLASSES = css(`
  &.MuiTooltip-tooltip {
    width: 410px;
    max-width: 410px;
  }
`)
