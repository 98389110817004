import type { DocumentFormPackDto } from 'api/types'
import type { FormPacksFormData } from './types'

const parseListData = (data: DocumentFormPackDto[]): FormPacksFormData[] => {
  return data.map(parseRowData)
}

const parseRowData = (data: DocumentFormPackDto): FormPacksFormData => {
  return {
    id: data.id,
    name: data.name ?? '',
    description: data.description ?? ''
  }
}

export default parseListData
