import type { FC } from 'react'
import type { IconProps } from 'types'

const PersonIcon: FC<IconProps> = ({ color = '#21212180', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_86546_2175)'>
        <path d='M8.0013 7.99984C9.47464 7.99984 10.668 6.8065 10.668 5.33317C10.668 3.85984 9.47464 2.6665 8.0013 2.6665C6.52797 2.6665 5.33464 3.85984 5.33464 5.33317C5.33464 6.8065 6.52797 7.99984 8.0013 7.99984ZM8.0013 9.33317C6.2213 9.33317 2.66797 10.2265 2.66797 11.9998V12.6665C2.66797 13.0332 2.96797 13.3332 3.33464 13.3332H12.668C13.0346 13.3332 13.3346 13.0332 13.3346 12.6665V11.9998C13.3346 10.2265 9.7813 9.33317 8.0013 9.33317Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_86546_2175'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PersonIcon
