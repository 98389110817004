import { css } from '@emotion/css'

export default css(`
  margin-top: 16px;

  .fi-product-card {
    display: flex;
    min-height: 356px;
    flex-direction: column;
  }

  .fi-product-card-body {
    flex: 1;
  }

  .fiproduct-card-wrapper {
    gap: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .fi-product-card-header {
      margin-bottom: 7px;
    }  

    @media(max-width: 1359px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .fiproduct-card-headerContent {
    display: flex;
    align-items: center;

    > svg {
      margin-right: 8px;
    }

    > div {
      h4 {
        font-size: 16px;
        color: #101010;
        font-weight: 500;
        text-align: left;
        line-height: 24px;
        letter-spacing: 0.4px;
      }
    }
  }

  .add-product-button {
    margin-top: 24px;

    &.cf-button-root.cf-button-variant-contained.cf-button-color-primary {
      &.is-added {
        border: none;
        background-color: #00C4B4;

        .cf-button-content {
          color: #ffffff;
        }
      }

      &.cf-button-disabled {
        &.is-added {
          border: none;
          background: #00C4B4;

          .cf-button-content {
            color: #ffffff;
          }
        }

        .cf-button-content {
          color: #21212140;
        }
      }

      border: 1px solid #C99B86;
      background-color: #ffffff;
      border-radius: 12px;
      width: 100%;
      min-width: auto;
      line-height: 16px;
      text-transform: uppercase;
      padding: 12px;
      height: 40px;

      .cf-button-content {
        color: #101010;
      }
    }
  }

`)
