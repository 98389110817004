import type { FC, ReactNode } from 'react'
import { cn } from '@carfluent/common'
import MuiDialogTitle, { DialogTitleProps as MuiDialogTitleProps } from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { useStyles } from './styles'

interface DialogTitleProps {
  classes?: MuiDialogTitleProps['classes']
  children: ReactNode | string
  isCloseIconVisible?: boolean
  titleDataTestId?: string
  onClose?: () => void
}

const DialogTitle: FC<DialogTitleProps> = ({
  children,
  classes,
  isCloseIconVisible = true,
  titleDataTestId,
  onClose
}) => {
  const styles = useStyles()
  const titleClasses = cn(
    styles.titleContent,
    'cf-dialog-title',
    !isCloseIconVisible && 'cf-no-icon-padding'
  )

  return (
    <MuiDialogTitle classes={classes}>
      <div className={titleClasses}>
        <Typography
          className={cn(styles.typographyRoot, 'cf-dialog-title-content')}
          data-test-id={titleDataTestId}
        >
          {children}
        </Typography>

        {(onClose != null) && isCloseIconVisible && (
          <IconButton
            aria-label='close'
            className={styles.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </MuiDialogTitle>
  )
}

export default DialogTitle
