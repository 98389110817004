import { type FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Skeleton } from '@material-ui/lab'
import { cnx } from '@carfluent/common'

import useToggler from 'hooks/useToggler'
import carPlaceholder from 'assets/vehicle-details-photo.png'
import Tooltip from 'components/common/Tooltip'
import IconSVG from 'components/inlineImages'

import createStyleClass from './styles'

export interface CarImageProps {
  vehicleImageUrl: string | null
  width: number
  height: number
  className?: string
  withLock?: boolean
  withExclamationMark?: boolean
  tooltipTitle?: string
  isDeposited?: boolean
  iconWidth?: number
  iconHeight?: number
  isCarDeleted?: boolean
}

const CarImage: FC<CarImageProps> = ({
  vehicleImageUrl,
  width,
  height,
  className,
  withLock = false,
  withExclamationMark = false,
  tooltipTitle = 'No photos in gallery.',
  isDeposited = false,
  iconWidth = 24,
  iconHeight = 24,
  isCarDeleted = false
}) => {
  const CLASS_NAME = createStyleClass({ width, height })
  const [vehicleSrcUrl, setVehicleSrcUrl] = useState(carPlaceholder)

  const {
    value: isSkeletonVisible,
    disable: hideSkeleton
  } = useToggler(true)

  useEffect(() => {
    if (vehicleImageUrl != null) {
      setVehicleSrcUrl(vehicleImageUrl)
    }
    return () => setVehicleSrcUrl(carPlaceholder)
  }, [vehicleImageUrl])

  const showOverlay = isDeposited || isCarDeleted

  return (
    <div className={cnx(CLASS_NAME, withLock && 'disabled', showOverlay && 'deposited', className)}>
      {isSkeletonVisible && <Skeleton className='skeleton' variant='rect' />}

      {withLock && (
        <div className='icon-lock'>
          <IconSVG.Lock
            width={24}
            height={24}
            color='#FFFFFF'
            opacity={1}
          />
        </div>
      )}

      {(showOverlay && vehicleImageUrl != null) && (<div className='deposit-overlay' />)}

      {((vehicleImageUrl == null && withExclamationMark) || showOverlay) && (
        <div className='icon-exclamation-mark'>
          <Tooltip title={tooltipTitle}>
            <div className='round-of-icon'>
              <IconSVG.ExclamationMark
                width={iconWidth}
                height={iconHeight}
              />
            </div>
          </Tooltip>
        </div>
      )}

      <img
        alt='vehicle'
        className={`vehicle-image ${isSkeletonVisible ? 'hidden' : ''}`}
        onError={() => setVehicleSrcUrl(carPlaceholder)}
        onLoad={hideSkeleton}
        src={vehicleSrcUrl}
      />
    </div>
  )
}

export default observer(CarImage)
