import { type FC } from 'react'

import TaxEditor from './components/TaxEditor'
import PopperTrigger from './components/PopperTrigger'
import { type UseTaxEditorProps } from './types'
import useTaxEditor from './hook'

export interface TaxInputProps extends UseTaxEditorProps {
  error: string | null
  disabled?: boolean
  label: string
  touched: boolean | null
}

export const TaxInput: FC<TaxInputProps> = ({
  disabled,
  label,
  ...hookProps
}) => {
  const {
    amountValue,
    isModalOpen,
    onChangeInput,
    onCloseModal,
    onOpenModal,
    onOverrideData,
    taxValue
  } = useTaxEditor(hookProps)

  return (
    <PopperTrigger
      amountValue={amountValue}
      disabled={disabled}
      isOpen={isModalOpen}
      label={label}
      onClose={onCloseModal}
      onOpen={onOpenModal}
      taxValue={taxValue}
    >
      <TaxEditor
        disabled={disabled}
        helperAmountValue={amountValue}
        label={label}
        onChangeInput={onChangeInput}
        onOverrideData={onOverrideData}
        taxValue={taxValue}
      />
    </PopperTrigger>
  )
}

export default TaxInput
