import { useState, useCallback, useRef, useMemo, useEffect, type FocusEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { getEnhancedResultHandler, isOk, type Result, useForm } from '@carfluent/common'

import VehiclesApiProvider from 'api/vehicles.api'
import CustomersCoreApiProvider from 'api/customersCore.api'
import CRMApiProvider from 'api/crm.api'
import { GET_DEFAULT_VEHICLES_SORTING } from 'api/defaults'
import {
  type DictionaryItem,
  type DuplicateLeadDto,
  type ListPayload,
  type PaginatedResult,
  type WorkflowTypeDto,
  PaymentTypeId
} from 'api/types'
import type { NewDealFormData, KeyVal } from 'types'
import { PaymentTypeAPI } from 'constants/names'
import { Routes } from 'constants/route_helper'
import getCurrentUser from 'utils/getCurrentUser'
import { isWholesaleType } from 'utils/deals/workflowTypes'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import useUsersList from 'hooks/useUsersList'

import { createValidationRules, DEPENDENT_VALIDATIONS } from './validation'
import { serializeAddNewDealData } from './serializer'
import { getDefaultNewDealFormValues, FIELD_TRANSFORMS, DEFAULT_SOURCE } from './constants'
import type {
  UseAddNewDealProps,
  UseAddNewDealReturn,
  ErrTypeMap,
  ErrTouchShortcuts,
  VehicleOption,
  ShortVinObject
} from './types'
import { parseCustomerData, parseLeadData } from './parser'

export const PAYMENT_OPTIONS = [
  {
    id: PaymentTypeId.Finance,
    name: PaymentTypeAPI.Finance
  },
  {
    id: PaymentTypeId.Cash,
    name: PaymentTypeAPI.Cash
  }
]

export const PAYMENT_NAME_MAPPER = {
  [PaymentTypeId.Finance]: 'Finance',
  [PaymentTypeId.Cash]: 'Cash'
}

const useAddNewDeal = ({
  defaultValues,
  isDealCreationFromLead = false,
  isOpen,
  leadId,
  onClose: _onClose
}: UseAddNewDealProps): UseAddNewDealReturn => {
  const [apiErrors, setAPIErrors] = useState<KeyVal | null>(null)
  const [sources, setSources] = useState<DictionaryItem[]>([])
  const [isAddNewCustomer, setAddNewCustomer] = useState(false)
  const baseValues = useMemo(() => getDefaultNewDealFormValues(defaultValues), [defaultValues])

  const refIsCurrentUserSet = useRef(false)
  const refVehicleItemsLength = useRef(0)
  const refIsFirstLoad = useRef(true)
  const refSearch = useRef('')

  const currentUser = getCurrentUser()
  const users = useUsersList(currentUser)

  const navigate = useNavigate()
  const { showAlert } = useCustomSnackbar()

  const resetFormState = useCallback((resetForm: (values: NewDealFormData) => void): void => {
    refIsCurrentUserSet.current = false

    resetForm(getDefaultNewDealFormValues(defaultValues))
    setAPIErrors(null)
    setAddNewCustomer(false)
  }, [defaultValues])

  const onActionResult = useMemo(() => {
    const action = (res: Result<string>, resetForm: () => void): void => {
      if (isOk<string>(res)) {
        _onClose?.()
        resetFormState(resetForm)
        navigate(`${Routes.Deals}/${res.result}`)
      } else if (axios.isAxiosError(res.result)) {
        setAPIErrors(res.result?.response?.data ?? null)
      }
    }

    return getEnhancedResultHandler<NewDealFormData, ErrTouchShortcuts, string>(action, showAlert)
  }, [_onClose, showAlert, resetFormState])

  const submitAction = useCallback(async (values: NewDealFormData): Promise<string | null> => {
    const payload = serializeAddNewDealData(values)
    return await CustomersCoreApiProvider.postNewDeal(payload)
  }, [])

  const validationRules = useMemo(() => {
    return createValidationRules({ isAddNewCustomer })
  }, [isAddNewCustomer])

  const {
    values,
    errors,
    touched,
    isSubmitting,
    onBlur,
    onSubmit,
    onChange,
    resetForm,
    validateField
  } = useForm<NewDealFormData, ErrTouchShortcuts, never, ErrTypeMap>({
    transforms: FIELD_TRANSFORMS,
    baseValues,
    dependentValidations: DEPENDENT_VALIDATIONS,
    onActionResult,
    submitAction,
    validationRules
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const getVehicles = useCallback(async (payload: ListPayload): Promise<PaginatedResult<VehicleOption>> => {
    refIsFirstLoad.current = false
    refVehicleItemsLength.current = 0

    try {
      const value = payload.search != null && payload.search !== '' ? payload.search.toLowerCase() : values.vehicle.vin ?? ''
      const response = await VehiclesApiProvider.getVehicles({ ...payload, search: value, ...GET_DEFAULT_VEHICLES_SORTING() })

      refVehicleItemsLength.current += response.items.length

      if (response.items.length === 0 && refSearch.current !== value) {
        refIsFirstLoad.current = true
        return { items: [], count: 0 }
      }

      refSearch.current = value
      return {
        ...response,
        items: response.items
      }
    } catch (e) {
      showAlert('Cars failed to load')
      return { items: [], count: 0 }
    }
  }, [values.vehicle])

  const getCustomers = useCallback(async (payload: ListPayload): Promise<PaginatedResult<DuplicateLeadDto>> => {
    try {
      const response = await CRMApiProvider.getDuplicateLeads({ ...payload, search: payload.search?.toLowerCase() ?? '' })
      return {
        ...response,
        items: response.items
      }
    } catch (e) {
      showAlert('Customers failed to load')
      return { items: [], count: 0 }
    }
  }, [])

  const onVehicleChange = useCallback((_, value: VehicleOption | null | ShortVinObject) => {
    let newValue = value as Partial<VehicleOption>

    if (value == null) {
      newValue = {
        vin: null,
        stock: null,
        salePrice: null,
        mileage: null
      }
    }

    onChange('vehicle', newValue)
  }, [onChange])

  const onVehicleBlur = useCallback((evt: FocusEvent<HTMLDivElement>) => {
    const fieldId = evt.target.id ?? ''

    onBlur(fieldId)
  }, [onBlur])

  const onClose = useCallback((): void => {
    _onClose?.()
    setAPIErrors(null)
    resetFormState(resetForm)
  }, [_onClose, resetFormState, resetForm])

  const onWorkflowTypeChange = useCallback((id: string, value: WorkflowTypeDto | null) => {
    onChange(id, value)

    if (isWholesaleType(value?.id)) {
      onChange('paymentType', PAYMENT_OPTIONS.find((item) => item.id === PaymentTypeId.Cash) ?? null)
    }
  }, [onChange])

  const resetCustomerData = useCallback((value: DuplicateLeadDto | null): void => {
    resetForm({
      ...values,
      salesperson: currentUser,
      ...parseCustomerData(value /** , defaultValues */), // AZ-TODO: uncomment if products decide to use inventory data for leads without Car of interest
      source: value == null ? DEFAULT_SOURCE : (sources.find(s => s.id === value?.leadSourceId) ?? null)
    })
  }, [currentUser, values, sources, resetForm/** , defaultValues */])

  const onCustomerBlur = useCallback((evt: FocusEvent<HTMLInputElement>): void => {
    const fieldId = evt.target.id ?? ''

    if (values.customer != null) {
      return
    }

    resetCustomerData(null)
    onBlur(fieldId)
  }, [values.customer, onBlur, resetCustomerData])

  const onAddCustomer = useCallback(() => {
    setAddNewCustomer(true)
    resetCustomerData(null)
    onBlur('customer')
    validateField('customer')
  }, [onBlur, validateField, resetCustomerData])

  const onCustomerChange = useCallback((_, value: DuplicateLeadDto | null) => {
    if (value == null) {
      resetCustomerData(null)
      return
    }

    setAddNewCustomer(false)
    resetCustomerData(value)
  }, [resetCustomerData])

  const getLead = useCallback(async (id: number): Promise<DuplicateLeadDto | null> => {
    try {
      const rawLead = await CRMApiProvider.getLead(id)
      return parseLeadData(rawLead)
    } catch (e) {
      return null
    }
  }, [leadId])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (!refIsCurrentUserSet.current && currentUser != null && isOpen) {
      onChange('salesperson', currentUser)
      refIsCurrentUserSet.current = true
    }
  }, [onChange, currentUser, isOpen])

  useEffect(() => {
    if (isOpen) {
      onChange('vehicle.vin', defaultValues?.vehicle?.vin ?? null)
      onChange('source', sources.find((el) => el.id === DEFAULT_SOURCE.id) ?? null)

      if (isDealCreationFromLead && leadId != null) {
        const getLeadData = async (): Promise<void> => {
          const leadData = await getLead(leadId)

          resetCustomerData(leadData)
        }
        void getLeadData()
      }
    }
  }, [isDealCreationFromLead, isOpen, getLead, defaultValues, sources])

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      const { items } = await CRMApiProvider.getLeadSources()
      setSources(items)
    }

    void runEffect()
  }, [])

  // ========================================== //

  return {
    values,
    errors,
    touched,
    apiErrors,
    users,
    sources,
    isFormSubmitting: isSubmitting,
    isDisabledPriceOrMileageInput: errors.vehicle.vin != null,
    onBlur,
    onChange,
    onClose,
    onSubmit,

    vehicleValue: values.vehicle,
    onAddCustomer,
    getVehicles,
    getCustomers,
    onCustomerChange,
    onCustomerBlur,
    onVehicleChange,
    onVehicleBlur,
    onWorkflowTypeChange,
    isVehicleDropdownSearchHasValue: Boolean(values.vehicle?.vin),
    isCustomerDropdownSearchHasValue: Boolean(values.customer) && values.customer?.id != null,
    isAddNewCustomer,
    isDealCreationFromLead
  }
}

export default useAddNewDeal
