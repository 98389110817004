import { type FC } from 'react'
import { type IconProps } from 'types'

const IconSVGDropdownArrowDown: FC<IconProps> = ({ color = '#000', width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.41 8.29688L12 12.8769L16.59 8.29688L18 9.70687L12 15.7069L6 9.70687L7.41 8.29688Z'
        fill={color}
        fillOpacity='0.87'
      />
    </svg>

  )
}

export default IconSVGDropdownArrowDown
