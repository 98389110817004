import type { FC } from 'react'
import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

const EMPTY_ROWS_COUNT = 10

export interface LoadingRowsProps {
  rowNumbers?: number
}

const LoadingRows: FC<LoadingRowsProps> = ({ rowNumbers = EMPTY_ROWS_COUNT }) => (
  <>
    {new Array(rowNumbers).fill(null).map((_, i) => (
      <tr key={i}>
        <td className='cell cell-entity'><TextSkeleton className='loading-row' /></td>
        <td className='cell'><TextSkeleton className='loading-row' /></td>
        <td className='cell'><TextSkeleton className='loading-row' /></td>
        <td className='cell'><TextSkeleton className='loading-row' /></td>
        <td className='cell'><TextSkeleton className='loading-row' /></td>
        <td className='cell'><TextSkeleton className='loading-row' /></td>
        <td className='cell'><TextSkeleton className='loading-row' /></td>
      </tr>
    ))}
  </>
)

export default LoadingRows
