import { PaymentSectionType } from 'pages/accounting/Settings/components/SettingsGeneral/components/PaymentSection/types'
import type { VehiclePurchasePaymentsFormData } from './types'

export const getDefaultFormData = (): VehiclePurchasePaymentsFormData => ({
  firstAccount: null,
  secondAccount: null,
  thirdAccount: null,
  fourthAccount: null,
  fifthAccount: null
})

export const FormText = {
  [PaymentSectionType.VehicleCost]: {
    title: 'Payments for vehicle added costs',
    description: 'When you add a cost it will be paid in a variety of ways. You may wish to credit Account Payable, or credit A/P Credit card, or credit any of bank accounts. You can select up to five accounts and these account will be available to you when you enter a cost.'
  },
  [PaymentSectionType.VehiclePurchase]: {
    title: 'Payments for vehicle purchases',
    description: 'When you make a purchase you will pay for it in a variety of ways. You may wish to credit Accounts Payable, or credit A/P Credit card, or whatever. You can select accounts you want to be able to credit here (up to five and then these accounts will be available to you when your enter your purchase).'
  }
}
