import { formatCurrencyDecimal, type ColumnDef } from '@carfluent/common'

import { type ReceiptItem, PaymentMethod } from 'api/types'

import ValueCell from 'components/common/Table/cells/ValueCell'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import { DateCellHOC } from 'components/common/Table/cells/DateCell'

import ActionsCell, { type ActionCellProps } from './components/ActionsCell'

const PAYMENT_METHOD_MAP = {
  [PaymentMethod.DownPayment]: 'Down payment',
  [PaymentMethod.Deposit]: 'Deposit',
  [PaymentMethod.Full]: 'Full'
}

const getColumnDefs = (actionCellProps: Omit<ActionCellProps, 'getValue'>): Array<ColumnDef<ReceiptItem>> => [
  {
    accessorFn: (item) => item.createdDate,
    id: 'created',
    cell: DateCellHOC({ format: 'MM/dd/yy' }),
    header: TextCellHOC({ text: 'Created' }),
    loading: 'big_line',
    size: 140,
    minSize: 140,
    sortable: false
  },
  {
    accessorFn: (item) => PAYMENT_METHOD_MAP[item.paymentMethodId],
    id: 'receipt',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Receipt' }),
    loading: 'big_line',
    size: 140,
    minSize: 160,
    sortable: false
  },
  {
    accessorFn: (item) => formatCurrencyDecimal(item.amount),
    id: 'amount',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Amount' }),
    loading: 'big_line',
    size: 140,
    minSize: 136,
    sortable: false
  },
  {
    accessorFn: ({ createdByUser }) => `${createdByUser?.dealerFirstName ?? ''} ${createdByUser?.dealerLastName ?? ''}`,
    id: 'addedBy',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Added by' }),
    loading: 'big_line',
    size: 140,
    minSize: 360,
    sortable: false
  },
  {
    accessorFn: (item) => item,
    id: 'actions',
    cell: (props) => <ActionsCell {...props} {...actionCellProps} />,
    header: TextCellHOC({ text: 'Actions' }),
    loading: 'big_line',
    size: 140,
    minSize: 400,
    sortable: false
  }
]

export default getColumnDefs
