import { useCallback, useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import type { UploadedAssetWithThumbnailDto, UploadedAttachmentDto } from 'api/types'
import {
  DEFAULT_MAX_FILES_NUM,
  DEFAULT_MAX_TOTAL_FILE_SIZE,
  FILE_NUMBER_ERROR,
  KiB,
  TOTAL_FILE_SIZE_ERROR
} from 'constants/files'
import type { FileFilterData } from 'utils/fileFilter'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

export interface FileData {
  file: File
  id: string
  url?: string
}

export interface UseFilesUploadProps<T> {
  uploadAssets: (file: File) => Promise<T>
  photosSizeKb?: number
  photosNum?: number
  isUnlimitedFilesNum?: boolean
  isEnabled?: boolean
}

export interface UseFilesUploadReturn {
  isFilesNumLimitReached?: boolean
  files: FileData[]
  onAddFiles: (data: FileFilterData) => void
  onDeleteFile: (id: string | number) => void
  resetData: () => void
}

const useFilesUpload = <T extends UploadedAssetWithThumbnailDto | UploadedAttachmentDto>({
  isUnlimitedFilesNum = false,
  photosSizeKb = 0,
  photosNum = 0,
  uploadAssets,
  isEnabled = true
}: UseFilesUploadProps<T>): UseFilesUploadReturn => {
  const { showAlert } = useCustomSnackbar()
  const totalPhotosSize = photosSizeKb * KiB

  const lastLengthBeforeUploadRef = useRef(0)
  const [files, setFiles] = useState<FileData[]>([])
  const [filesToUpload, setFilesToUpload] = useState<FileData[]>([])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onAddFiles = useCallback(({
    files: newFiles,
    error = ''
  }: FileFilterData): void => {
    if (isEnabled) {
      const currentTotalAttachments = files.length + photosNum

      if (!isUnlimitedFilesNum) {
        const spaceLeft = DEFAULT_MAX_FILES_NUM - currentTotalAttachments
        if (newFiles.length > spaceLeft) {
          showAlert(FILE_NUMBER_ERROR)
          newFiles = newFiles.slice(0, spaceLeft)
        }
      }

      setFiles(prevFiles => {
        if (error !== '') {
          showAlert(error)
        }

        const totalSizePrevFiles = prevFiles.reduce((totalSize, fileData) => {
          return totalSize + fileData.file.size
        }, 0)

        const totalSizeNewFiles = newFiles.reduce((totalSize, file) => {
          return totalSize + file.size
        }, 0)

        const totalSizeAllFiles = totalPhotosSize + totalSizePrevFiles + totalSizeNewFiles

        if (totalSizeAllFiles > DEFAULT_MAX_TOTAL_FILE_SIZE) {
          showAlert(TOTAL_FILE_SIZE_ERROR())
        }

        const returnedFiles = totalSizeAllFiles > DEFAULT_MAX_TOTAL_FILE_SIZE
          ? prevFiles
          : [...prevFiles, ...newFiles.map(v => ({ file: v, id: uuid() }))]

        return isUnlimitedFilesNum ? returnedFiles : returnedFiles.slice(0, DEFAULT_MAX_FILES_NUM)
      })
    }
  }, [files.length, photosNum, showAlert, totalPhotosSize, isUnlimitedFilesNum])

  const onDeleteFile = useCallback((id: string | number): void => {
    setFiles(files => {
      const idx = files.findIndex(v => v.id === id)
      if (idx > -1) {
        return files.slice(0, idx).concat(files.slice(idx + 1))
      }
      return files
    })
  }, [])

  const resetData = useCallback(() => {
    setFiles([])
  }, [])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const newFiles = files.slice(lastLengthBeforeUploadRef.current)
    setFilesToUpload(prev => [...prev, ...newFiles])
    lastLengthBeforeUploadRef.current = files.length
  }, [files])

  useEffect(() => {
    const uploadFiles = async (): Promise<void> => {
      if (filesToUpload.length === 0) {
        return
      }

      const currFile = filesToUpload[0]
      try {
        const response = await uploadAssets(currFile.file)
        if (isUploadedAssetWithThumbnail(response)) {
          setFiles(prevFiles => prevFiles.map(v => v.id === currFile.id ? { ...v, url: response.originalUrl } : v))
        } else if (isUploadedAttachment(response)) {
          setFiles(prevFiles => prevFiles.map(v => v.id === currFile.id ? { ...v, url: response.fileUrl } : v))
        }

        // Remove the successfully uploaded file from the 'filesToUpload' state
        setFilesToUpload(prev => prev.slice(1))
      } catch (e) {
        showAlert('Some files failed to load')
        onDeleteFile(currFile.id)

        // Remove the file that failed to upload from the 'filesToUpload' state
        setFilesToUpload(prev => prev.slice(1))
      }
    }

    void uploadFiles()
  }, [filesToUpload, uploadAssets, showAlert, onDeleteFile])

  // ========================================== //

  return {
    files,
    onAddFiles,
    onDeleteFile,
    resetData
  }
}

export default useFilesUpload

function isUploadedAssetWithThumbnail (response: any): response is UploadedAssetWithThumbnailDto {
  return 'originalUrl' in response
}

function isUploadedAttachment (response: any): response is UploadedAttachmentDto {
  return 'fileUrl' in response
}
