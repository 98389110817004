import { type FC } from 'react'
import { type MenuIconProps } from 'types'

const InventoryListMenuIcon: FC<MenuIconProps> = ({
  isActive = false
}) => {
  const pictogramFill = isActive ? '#fff' : '#101010'

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.14292 8.46587C3.25296 8.14125 3.56108 7.91016 3.92422 7.91016H7.72574C8.08888 7.91016 7.13292 9.97189 7.24847 10.2965L11.9023 11.7617V15.5465L10.2517 15.6132H3.64911V16.1634C3.64911 16.466 3.40152 16.7136 3.0989 16.7136H2.54868C2.24607 16.7136 1.99847 16.466 1.99847 16.1634V11.7617L3.14292 8.46587ZM7.60689 9.01059H4.1223L3.52807 10.7218H7.12962L7.60689 9.01059ZM3.0989 14.5127H10.8019V11.7617H3.0989V14.5127Z'
        fill={pictogramFill}
      />

      <path
        d='M21.8571 8.46587C21.747 8.14125 21.4389 7.91016 21.0758 7.91016H17.2743C16.9111 7.91016 17.8671 9.97189 17.7515 10.2965L13.0977 11.7617V15.5465L14.7483 15.6132H21.3509V16.1634C21.3509 16.466 21.5985 16.7136 21.9011 16.7136H22.4513C22.7539 16.7136 23.0015 16.466 23.0015 16.1634V11.7617L21.8571 8.46587ZM17.3931 9.01059H20.8777L21.4719 10.7218H17.8704L17.3931 9.01059ZM21.9011 14.5127H14.1981V11.7617H21.9011V14.5127Z'
        fill={pictogramFill}
      />

      <path
        d='M17.4559 6.72307C17.3127 6.30068 16.9118 6 16.4393 6H8.56428C8.09178 6 7.69803 6.30068 7.54768 6.72307L6.05859 11.0114V16.7386C6.05859 17.1324 6.38075 17.4545 6.7745 17.4545H7.49041C7.88416 17.4545 8.20632 17.1324 8.20632 16.7386V16.0227H16.7972V16.7386C16.7972 17.1324 17.1194 17.4545 17.5131 17.4545H18.229C18.6228 17.4545 18.945 17.1324 18.945 16.7386V11.0114L17.4559 6.72307ZM8.56428 13.875C7.97007 13.875 7.49041 13.3953 7.49041 12.8011C7.49041 12.2069 7.97007 11.7273 8.56428 11.7273C9.15848 11.7273 9.63814 12.2069 9.63814 12.8011C9.63814 13.3953 9.15848 13.875 8.56428 13.875ZM16.4393 13.875C15.8451 13.875 15.3654 13.3953 15.3654 12.8011C15.3654 12.2069 15.8451 11.7273 16.4393 11.7273C17.0335 11.7273 17.5131 12.2069 17.5131 12.8011C17.5131 13.3953 17.0335 13.875 16.4393 13.875ZM7.49041 10.2955L8.56428 7.07386H16.4393L17.5131 10.2955H7.49041Z'
        fill={pictogramFill}
      />
    </svg>

  )
}

export default InventoryListMenuIcon
