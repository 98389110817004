import type { FC } from 'react'
import { Button, cnx } from '@carfluent/common'

import BackToTop from 'components/common/BackToTop'
import Table from 'components/common/Table'
import VendorDetails from 'pages/accounting/VendorDetails'
import FiltersPanel from 'components/common/FiltersPanel'

import useVendors from './hook'
import CLASS_NAME from './styles'

const SettingsVendors: FC = () => {
  const {
    columns,
    emptyState,
    isLoading,
    isModalOpen,
    onAddVendor,
    onBottomReached,
    onCloseVendorDetails,
    onDeleteVendor,
    onOpenVendorDetails,
    onSearchChange,
    onSearch,
    rows,
    search,
    selectedVendorId
  } = useVendors()

  return (
    <div className={cnx(CLASS_NAME, 'g-submenu-content')}>
      <FiltersPanel
        searchProps={{
          isLoading,
          onChange: onSearchChange,
          value: search,
          placeholder: 'Name, email, phone',
          withDeleteIcon: true,
          onSearch
        }}
        renderActions={() => (
          <Button onClick={() => onOpenVendorDetails()}>
            NEW VENDOR
          </Button>
        )}
      />

      <Table
        columns={columns}
        data={rows}
        emptyTableMessage={emptyState}
        isLoading={isLoading}
        onBottomReached={onBottomReached}
        onRowClick={onOpenVendorDetails}
      />

      <VendorDetails
        vendorId={selectedVendorId}
        isOpen={isModalOpen}
        onClose={onCloseVendorDetails}
        onDeleteVendor={onDeleteVendor}
        onSubmit={onAddVendor}
      />

      <BackToTop />
    </div>
  )
}

export default SettingsVendors
