import { css } from '@emotion/css'

export default css(`
  padding: 8px;
  margin: 0 0 12px 0;
  border-radius: 4px;
  border: 2px solid #F3F3F3;
  background: rgba(33, 33, 33, 0.08);

  .cf-no-data-block {
    padding: 14px 30px;
    border-top: 6px solid #F3F3F3;
  }

  .cf-table {
    table-layout: fixed;

    .cf-table-header-cell { 
      border-radius: 4px;
      color: #101010; 

      .cf-header-cell-title.account { padding-left: 32px; }
      .cf-header-cell-title.control { padding-left: 20px; }
      .cf-header-cell-title.notes { padding-left: 20px; }
    }

    .cf-table-row {
      border-bottom: 8px solid #f3f3f3;

      &.cf-table-row-hover > td > div { background: #fff; }

      :hover { background-color: #FAFAFA; }

      > td:first-child > div {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-left: 4px solid transparent;
        padding-left: 8px;
      }

      > td:last-child > div {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }

      :hover .MuiInputBase-root {
        background-color: #FAFAFA!important;
        border: 1px solid rgba(0, 0, 0, 0.12);
      }

      :hover .cf-input-container {
        background-color: #FAFAFA;
        border: 1px solid rgba(0, 0, 0, 0.12);
      }

      :hover > td > div {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        background-color: #FAFAFA;
      }

      :hover > td:first-child > div {
        border-left: 4px solid #212121;
      }
    }

    .cf-table-cell {
      > .cf-cell-wrapper { 
        height: 56px;
        padding: 2px 4px;

        &.read-only-column-value {
          padding: 15px 8px;
        }
        
        &.read-only-column-value.account {
          padding: 15px 8px 15px 24px;
        }
      }

      .MuiInputBase-root {
        background-color: #fff !important;
      }

      .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
        padding-right: 52px!important;
      }
    }
  }

  .text-style {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;

    > .cf-option-name {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
      color: #101010
    }

    > .cf-option-sub-name {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: rgba(33, 33, 33, 0.5);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: auto;
    }
  }


  .add-new-row {
    margin: 0;
    
    .cf-button-root {
      border: none;
      height: 50px;
      width: 100%;
      border-radius: 0;
      justify-content: flex-start;

      :hover span {
        opacity: 0.5;
        background: #fff;
      }
    }

    .cf-button-start-adornment {
      height: 20px;
      margin-right: 0;
      margin-left: 0;
      margin-left: -4px;
      margin-right: 8px;
    }
    
    .cf-button-content {
      max-width: 96px;
      text-transform: none;
      font-weight: 600;
    }
  }
`)
