import { css } from '@emotion/css'

export default css(`
  --footer-height: 80px;

  &.tab-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: var(--footer-height);
    box-shadow: 0px -2px 12px 0px #0000001F;
    background-color: #fff;
    
    position: fixed;
    z-index: 10;
    left: 0px;
    right: 0px;
    bottom: calc(var(--footer-height) * -1);
    padding-right: 64px;
    transition: bottom 0.3s ease;

    &.is-shown {
      bottom: 0px;
    }

    button {
      margin: 0px;

      &:first-child {
        margin-right: 24px;
      }
    }
  }
`)
