
import { serializers } from '@carfluent/common'
import type { DateType } from 'api/types'
import serializeDateFilters from 'utils/filters/serializeDateFilters'

import type { ReportLeadsByVehiclesListFilters } from './types'

const serializeFilters = (filters: ReportLeadsByVehiclesListFilters): ReportLeadsByVehiclesListFilters => {
  return {
    dateRange: serializeDateFilters(filters.dateRange, serializers.serializeDateTimeUTC) as DateType
  }
}

export default serializeFilters
