import { FC } from 'react'

import closeIcon from 'assets/close_white.svg'

import CLASS_NAME from './styles'

interface HeaderProps {
  counter: string
  onClose: () => void
}

const Header: FC<HeaderProps> = ({ counter, onClose }) => {
  return (
    <div className={CLASS_NAME}>
      <p className='cf-header-counter'>{counter}</p>
      <img
        onClick={onClose}
        src={closeIcon}
        alt='close icon'
      />
    </div>
  )
}

export default Header
