import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { type Row } from '@tanstack/react-table'
import { type SortingInfo, useRefUpdater } from '@carfluent/common'

import ReconAPIProvider from 'api/recon.api'
import { PAGE_SIZE } from 'constants/constants'
import { type ReconVehicleAmount, type ReconVehicleListItemResponseDto, type TotalStepsAmountDtoDto } from 'api/types'
import { Routes } from 'constants/route_helper'
import { calcTableHeight } from 'utils/math'
import { convertSortingOrder } from 'utils/general'
import useTableApi from 'hooks/useTableApi'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useAsyncEffect from 'hooks/useAsyncEffect'

import useWSUpdates from './useWSUpdates'
import getColumnDefinitions from '../columns'
import { type UseReconListReturn } from './types'

import {
  EMPTY_MESSAGE,
  EXPORT_XLS_PAGE_SIZE,
  DEFAULT_FILTERS,
  DEFAULT_SORTING,
  ROW_GAP,
  ROW_HEIGHT,
  TABLE_MIN_HEIGHT,
  TABLE_WRAPPER_UNIQUE_CLASSNAME
} from './constants'
import useEffectOnce from 'hooks/useEffectOnce'

const useReconList = (): UseReconListReturn => {
  const { pathname, state } = useLocation()
  const navigate = useNavigate()
  const [selectedStep, setSelectedStep] = useState<number | undefined>(0)
  const [refreshCommentTrigger, setRefreshCommentTrigger] = useState<number>(0)
  const [isPrintLoading, setIsPrintLoading] = useState(false)
  const { showAlert } = useCustomSnackbar()
  const [isFiltersLoading, setIsFiltersLoadingLoading] = useState(false)
  const [statuses, setStatuses] = useState<ReconVehicleAmount[]>([])
  const [statusTotal, setStatusesTotal] = useState<TotalStepsAmountDtoDto>({ noAlertCount: 0, firstAlertCount: 0, secondAlertCount: 0 })
  const containerElement = document.querySelector(`.${TABLE_WRAPPER_UNIQUE_CLASSNAME}`) as HTMLDivElement

  const loadStatusFilters = useCallback(async (): Promise<void> => {
    try {
      setIsFiltersLoadingLoading(true)
      const res = await ReconAPIProvider.getReconVehiclesTotal()
      setStatuses(res.amounts)
      setStatusesTotal(res.total)
    } catch {
      console.error('reconditioning amounts failed to load')
    } finally {
      setIsFiltersLoadingLoading(false)
    }
  }, [])

  const {
    emptyTableMessage,
    isLoading,
    rows,
    search,
    setSearch,
    sorting,
    setSorting,
    setFilters,
    loadRows,
    onSearch,
    filters
  } = useTableApi<ReconVehicleListItemResponseDto, { reconStepId?: number, isFavorite: true | null }>({
    containerElement,
    defaultFilters: DEFAULT_FILTERS,
    defaultSorting: DEFAULT_SORTING,
    getList: ReconAPIProvider.getReconVehiclesList,
    emptyTableMessage: EMPTY_MESSAGE,
    nothingFoundMessage: EMPTY_MESSAGE,
    shouldRunOnCall: false
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onPrint = async (): Promise<void> => {
    try {
      const listPayload = {
        reconStepId: typeof filters.reconStepId === 'number' ? filters.reconStepId.toString() : undefined,
        take: EXPORT_XLS_PAGE_SIZE,
        skip: 0,
        sortField: sorting.id,
        sortOrder: convertSortingOrder(sorting.order),
        search,
        isFavorite: filters.isFavorite
      }

      setIsPrintLoading(true)
      const res = await ReconAPIProvider.printReconVehiclesList(listPayload)
      const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }))
      window.open(url, '_blank')
    } catch (e) {
      showAlert(showAlert)
    } finally {
      setIsPrintLoading(false)
    }
  }

  const onToggleIsFavorite = useCallback(() => {
    void setFilters({
      ...filters,
      isFavorite: filters.isFavorite === true ? null : true
    })
  }, [setFilters, filters])

  const columns = useMemo(() => {
    return getColumnDefinitions({
      onChangeFavoriteFilter: onToggleIsFavorite,
      isFavoriteFilter: filters.isFavorite ?? false,
      refreshCommentTrigger
    })
  }, [refreshCommentTrigger, onToggleIsFavorite])

  const onReconUpdated = useCallback(async () => {
    const take = rows.length < PAGE_SIZE ? PAGE_SIZE : rows.length

    await Promise.all([
      loadRows({ forceRefresh: true, pageSize: take }),
      loadStatusFilters()
    ])
  }, [rows.length, loadRows])

  const onSetSorting = useCallback(async (sorting: SortingInfo) => {
    await setSorting(sorting)
  }, [])

  const onChangeStep = useCallback((stepId: number | undefined) => {
    setSelectedStep(stepId)
    void setFilters({ reconStepId: stepId }, true, true)
  }, [setFilters])

  const onBottomReached = useCallback(async () => {
    if (!isLoading && TABLE_MIN_HEIGHT <= calcTableHeight(rows.length, ROW_HEIGHT, ROW_GAP)) {
      await loadRows({ forceRefresh: false })
    }
  }, [isLoading, loadRows, rows.length])

  const onRowClick = useCallback(({ original: { id } }: Row<ReconVehicleListItemResponseDto>) => {
    navigate(`${Routes.Recon}/${id}`)
  }, [navigate])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffectOnce(() => {
    if (state?.reconStepId != null) {
      onChangeStep(state.reconStepId)
    } else {
      void loadRows({ forceRefresh: true })
    }
  }, [state?.reconStepId, onChangeStep])

  useEffect(() => {
    if (pathname === Routes.Recon) {
      document.body.style.overflowY = 'hidden'
    }

    return () => {
      /**
       * if needed to control body overflow differently for different Recon routes please adjust this place
       */
      document.body.style.overflowY = 'auto'
    }
  }, [pathname])

  useEffect(() => {
    setRefreshCommentTrigger(prev => prev + 1)
  }, [rows]) // AZ-NOTE: why this dependency? Add comment, if you know

  const refLoadStatusFilters = useRefUpdater(loadStatusFilters)
  useAsyncEffect(async () => {
    await refLoadStatusFilters.current()
  }, [])

  /**
   * Updates Recon table by WebSocket events.
   */
  useWSUpdates({
    onReconUpdated
  })

  // ========================================== //

  return {
    containerElement,
    emptyTableMessage,
    isLoading,
    onChangeStep,
    onBottomReached,
    rows,
    selectedStep,
    search,
    setSearch,
    sorting,
    setSorting: onSetSorting,
    onRowClick,
    onSearch,
    onPrint,
    isPrintLoading,
    columns,
    statusFiltersProps: {
      statuses,
      total: statusTotal,
      isLoading: isFiltersLoading
    }
  }
}

export default useReconList
