import type { FormValidation } from '@carfluent/common'

import type { DictionaryItem } from 'api/types'
import { required, executionDay as _executionDay, executionDayGreaterZero, ruleTitle, requiredDescription, dictionaryItemDropdownField } from 'utils/validationPresets'
import { TaskType } from 'pages/crm/LeadDetailsView/hook/types'

import { FieldIds } from './constants'
import { WorkPlanEventType } from './types'

export interface FormValues {
  title: string
  assignedUser: DictionaryItem | null
  taskType: DictionaryItem | null
  reminderSubject: DictionaryItem | null
  taskPriority: DictionaryItem | null
  executionDay: number
  description: string | null
  workplanEventTypeId: number | null
  emailTemplateId: DictionaryItem | null
  messageTemplateId: DictionaryItem | null
  runAtTime: string
}

const dictionaryItemField = dictionaryItemDropdownField(true)

const createValidationRules = (): FormValidation<FormValues> => {
  return {
    title: ruleTitle,
    assignedUser: (val, values) => {
      if (values?.[FieldIds.WorkPlanEventTypeId] === WorkPlanEventType.Task) {
        return dictionaryItemField(val)
      }

      return null
    },
    emailTemplateId: (val, values) => {
      if (values?.[FieldIds.WorkPlanEventTypeId] === WorkPlanEventType.Email) {
        return dictionaryItemField(val)
      }

      return null
    },
    messageTemplateId: (val, values) => {
      if (values?.[FieldIds.WorkPlanEventTypeId] === WorkPlanEventType.Message) {
        return dictionaryItemField(val)
      }

      return null
    },
    taskType: (val, values) => {
      if (values?.[FieldIds.WorkPlanEventTypeId] === WorkPlanEventType.Task) {
        return dictionaryItemField(val)
      }

      return null
    },
    taskPriority: (val, values) => {
      if (values?.[FieldIds.WorkPlanEventTypeId] === WorkPlanEventType.Task) {
        return dictionaryItemField(val)
      }

      return null
    },
    reminderSubject: (val, values) => {
      if (values?.[FieldIds.WorkPlanEventTypeId] === WorkPlanEventType.Task && values?.[FieldIds.TaskType]?.id === TaskType.Reminder) {
        return required(val)
      }

      return null
    },
    executionDay: (val, values) => {
      if (values?.[FieldIds.WorkPlanEventTypeId] === WorkPlanEventType.Task) {
        return _executionDay(val)
      } else {
        return executionDayGreaterZero(val)
      }
    },
    description: (val) => {
      if (val == null || val?.trim() === '') {
        return null
      }

      return requiredDescription(val)
    }
  }
}

export default createValidationRules()
