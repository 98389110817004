import type { FC, ReactNode } from 'react'
import { Modal } from '@carfluent/common'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import CLASS_NAME from './styles'


interface DeleteRuleProps {
  onSubmit: () => Promise<void>
  onClose: () => void
  isOpen: boolean
  isLoading: boolean
}

const DeleteRule: FC<DeleteRuleProps> = ({
  onClose,
  isOpen,
  onSubmit,
  isLoading
}) => {
  const renderFooter = (): ReactNode => (
    <CancelSubmitActionsFooter
      onSubmit={onSubmit}
      onClose={onClose}
      isLoading={isLoading}
      submitTitle='DELETE'
    />
  )

  return (
    <Modal
      title='Delete this rule?'
      onClose={onClose}
      isOpen={isOpen}
      renderFooterActions={renderFooter}
      className={CLASS_NAME}
    >
      <p>Please note, this step could not be undone</p>
    </Modal>
  )
}

export default DeleteRule
