import { type FC } from 'react'
import { RangePickerMode, PRESET_LABELS, formatCurrencyAccounting } from '@carfluent/common'

import ReportLayout from 'components/reports/ReportLayout'
import BackToTop from 'components/common/BackToTop'
import TooltipedText from 'components/common/TooltipedText'
import FiltersPanel, { type FilterItem } from 'components/common/FiltersPanel'

import { asyncNoop } from 'constants/constants'

import LoadingRows from './components/LoadingRows'
import { AMOUNT_FORMAT } from './hook/constants'
import useReportAccountsReceivable from './hook'
import { RecordRangeIndex, type UseReportAccountsAgingProps } from './hook/types'
import { CONTENT_CLASS_NAME, ROOT_CLASS_NAME } from './styles'

export type ReportAccountsReceivableProps = UseReportAccountsAgingProps

const ReportAccountsReceivable: FC<ReportAccountsReceivableProps> = (props) => {
  const {
    rows,
    isLoading,
    totals,
    search,
    initialPresetName,
    presets,
    isReceivable,
    onSearchChange,
    onSearch,
    onChangeDatesFilter,
    onExportExcel
  } = useReportAccountsReceivable(props)

  const totalBalances = totals?.balances ?? null

  // ========================================== //

  const searchProps = {
    value: search ?? '',
    onSearch,
    disabled: isLoading,
    isLoading,
    onChange: onSearchChange,
    placeholder: 'Search by entity'
  }

  const dateFilters: FilterItem<'date'> = {
    filterType: 'date',
    componentProps: {
      presets,
      onFilterChange: onChangeDatesFilter,
      rangePickerMode: RangePickerMode.Single,
      defaultPresetName: initialPresetName as PRESET_LABELS,
      monthsNumber: 1
    }
  }

  return (
    <ReportLayout
      className={CONTENT_CLASS_NAME}
      rootClassName={ROOT_CLASS_NAME}
      title={isReceivable ? 'A/R aging report' : 'A/P aging report'}
      isDateFilterEnabled={false}
      onExportExcel={onExportExcel}
      onChangeDatesFilter={asyncNoop}
    >
      <FiltersPanel
        componentBackground='gray'
        searchProps={searchProps}
        filterProps={{ filters: [dateFilters] }}
      />
      <table className='table-accounts-aging'>
        <thead>
          <tr className='first-head-row'>
            <th className='cell cell-entity' />
            <th className='cell aligned-right' />
            <th className='cell aligned-right' />
            <th className='cell aligned-right' />
            <th className='cell aligned-right' />
            <th className='cell aligned-right past-group'>Past due</th>
            <th className='cell aligned-right' />
          </tr>
          <tr className='last-head-row'>
            <th className='cell cell-entity'>Entity</th>
            <th className='cell aligned-right'>Current due</th>
            <th className='cell aligned-right'>1-30 days</th>
            <th className='cell aligned-right'>31-60 days</th>
            <th className='cell aligned-right'>61-90 days</th>
            <th className='cell aligned-right'>91+ days</th>
            <th className='cell aligned-right'>Total due</th>
          </tr>
        </thead>

        <tbody>
          {isLoading && <LoadingRows />}

          {!isLoading && (rows.length > 0) && (
            <>
              {rows.map((item, idx) => {
                const { balances, entityName, totalBalance } = item

                return (
                  <tr key={item.entityName}>
                    <td className='cell cell-entity'>
                      <TooltipedText value={entityName} />
                    </td>

                    <td className='cell aligned-right'>{formatCurrencyAccounting(balances[RecordRangeIndex.Current].balance, AMOUNT_FORMAT)}</td>
                    <td className='cell aligned-right'>{formatCurrencyAccounting(balances[RecordRangeIndex.One].balance, AMOUNT_FORMAT)}</td>
                    <td className='cell aligned-right'>{formatCurrencyAccounting(balances[RecordRangeIndex.ThirtyOne].balance, AMOUNT_FORMAT)}</td>
                    <td className='cell aligned-right'>{formatCurrencyAccounting(balances[RecordRangeIndex.SixtyOne].balance, AMOUNT_FORMAT)}</td>
                    <td className='cell aligned-right'>{formatCurrencyAccounting(balances[RecordRangeIndex.NinetyOne].balance, AMOUNT_FORMAT)}</td>
                    <th className='cell aligned-right'>{formatCurrencyAccounting(totalBalance, AMOUNT_FORMAT)}</th>
                  </tr>
                )
              })}
            </>
          )}

          {!isLoading && (rows.length === 0) && (
            <tr className='no-results'>
              <td colSpan={7} className='cell'>No results found</td>
            </tr>
          )}
        </tbody>

        {(isLoading || (rows.length > 0)) && (
          <tfoot>
            <tr>
              <th className='cell cell-entity'>Total</th>
              <th className='cell aligned-right'>{formatCurrencyAccounting(totalBalances?.[RecordRangeIndex.Current]?.balance, AMOUNT_FORMAT)}</th>
              <th className='cell aligned-right'>{formatCurrencyAccounting(totalBalances?.[RecordRangeIndex.One]?.balance, AMOUNT_FORMAT)}</th>
              <th className='cell aligned-right'>{formatCurrencyAccounting(totalBalances?.[RecordRangeIndex.ThirtyOne]?.balance, AMOUNT_FORMAT)}</th>
              <th className='cell aligned-right'>{formatCurrencyAccounting(totalBalances?.[RecordRangeIndex.SixtyOne]?.balance, AMOUNT_FORMAT)}</th>
              <th className='cell aligned-right'>{formatCurrencyAccounting(totalBalances?.[RecordRangeIndex.NinetyOne]?.balance, AMOUNT_FORMAT)}</th>
              <th className='cell aligned-right'>{formatCurrencyAccounting(totals?.totalBalance, AMOUNT_FORMAT)}</th>
            </tr>
          </tfoot>
        )}
      </table>

      <BackToTop />
    </ReportLayout>
  )
}

export default ReportAccountsReceivable
