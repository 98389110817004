import type { FC } from 'react'

import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

import CLASS_NAME from './styles'

const TIMELINE_RECORDS_AMOUNT = [...Array(4)]
const TIMELINE_RECORD_LINES_AMOUNT = [...Array(5)]

const LoaderSkeleton: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <div className='timeline-header-skeleton'>
        <TextSkeleton />

        <div className="comment-skeleton">
          <TextSkeleton />

          <span className='comment-btn-skeleton'>ADD COMMENT</span>
        </div>
      </div>

      <div className='timeline-container-skeleton'>
        {
          TIMELINE_RECORDS_AMOUNT.map((el, i) => (
            <div key={i} className='timeline-record-skeleton'>
              {
                TIMELINE_RECORD_LINES_AMOUNT.map((el, i) => (
                  <div key={i}>
                    <TextSkeleton />
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default LoaderSkeleton
