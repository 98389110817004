import type { ShiftState, WorkScheduleFilters } from './types'

export const defaultFilters = (): WorkScheduleFilters => ({ date: new Date(), userIds: [] })
export const initialState = (): ShiftState => ({
  items: []
})

export const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const SUCCESS_CREATE_SHIFT_MESSAGE = 'Shift added successfully.'
export const SUCCESS_DELETE_SHIFT_MESSAGE = 'Shift deleted successfully.'
export const SUCCESS_UPDATE_SHIFT_MESSAGE = 'Shift edited.'
export const MONTH_NUM = 1 // number for starting month indexing from 1

export const SHIFT_MODAL_WIDTH = 544
export const SHIFT_MODAL_HEIGHT = 280
