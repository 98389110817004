import type { FC } from 'react'
import { cn, CellWrapper, Button } from '@carfluent/common'

import type { EntityId } from 'types'
import ConfigurableCellHOC from 'components/common/Table/cells/ConfigurableCellHOC'
import CLASS_NAME from './styles'

export interface LinksListHOCProps {
  className?: string
}

export interface LinksListCellProps extends LinksListHOCProps {
  getValue: () => LinkData[]
}

export interface LinkData {
  id: EntityId
  onClick?: (id: EntityId) => void
  title: string
}

export const LinksListCell: FC<LinksListCellProps> = ({
  className,
  getValue
}) => {
  const lines = getValue() ?? []

  return (
    <CellWrapper className={cn(CLASS_NAME, 'cf-cell-text-list', className)}>
      {lines.map(item => (
        <Button
          key={item.id}
          onClick={() => item.onClick?.(item.id)}
          variant='text'
        >
          {item.title}
        </Button>
      ))}
    </CellWrapper>
  )
}

export const LinksListHOC = (hocProps: LinksListHOCProps) => ConfigurableCellHOC(LinksListCell, hocProps)
