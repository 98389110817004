import { css } from '@emotion/css'

export default css`
  background: #F3F3F3;
  position: relative;
  box-sizing: border-box;
  
  .cf-modal-title {
    max-width: 1600px!important;
    padding: 40px 24px 0!important;
  }
  
  .cf-modal-content {
    max-width: 1600px!important;
    padding: 0px 24px 120px!important;
  }
  
  .new-deal-form-content {
    display: grid;
    row-gap: 24px;
  }
  
  .new-car-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 16px;
    row-gap: 24px;
    
    .double-col {
      grid-column: span 2;
    }
    
    @media screen and (min-width: 1360px) {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  > .cf-loader {
    position: absolute;
    inset: 0px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.75);
  }

  .cf-button-root:first-child {
    margin: 0px 12px 0px 0px;
  }

  .cf-button-root:last-child {
    margin: 0px;
  }
  
  .g-section-title {
    margin: 0;
  }
  
  .new-car-title {
    color: #101010;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: -8px;
  }

  .radio-item {
    margin-left: -7px;
    padding-right: 8px;
    margin-right: 24px;

    & > span:first-of-type {
      padding: 8px;
    }
  }

  .cf-banner-content {
    padding-left: 36px;
  }
`
