import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'

import NameWithAvatar from 'components/common/NameWithAvatar'
import IconSVG from 'components/inlineImages'

import CLASS_NAME from './styles'

interface ValueProps {
  name: string
  loading?: boolean
  id: number
}

interface UploadedByCellProps {
  getValue: () => ValueProps
  onDelete: (id: number) => void
}

const UploadedByCell: FC<UploadedByCellProps> = ({ getValue, onDelete }) => {
  const { name, id, loading = false } = getValue()

  return (
    <CellWrapper className={CLASS_NAME}>
      <div className='wrapper'>
        {!loading && (
          <>
            <NameWithAvatar showAvatar name={name} />

            <div
              className='close-wrapper'
              onClick={() => {
                void onDelete(id)
              }}
            >
              <IconSVG.Close />
            </div>
          </>
        )}
      </div>
    </CellWrapper>
  )
}

export default UploadedByCell
