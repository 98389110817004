import { GET_DEFAULT_VEHICLE } from 'api/defaults'

import type { VehicleWithGroup } from './types'

export const GROUP_CARS_OF_INTEREST = 'Car(s) of interest'
export const GROUP_FROM_INVENTORY = 'From inventory'
export const photosNumLimit = 10

export const enum EmptyState {
  EmptyGallery = 'This car does not have uploaded media',
  NoVehicle = 'No vehicle selected',
}

export enum GroupCarsIds {
  CarOfInterestGroup = -1,
  FromInventoryGroup = -2
}

export const optionTextMap = {
  [GroupCarsIds.CarOfInterestGroup]: GROUP_CARS_OF_INTEREST,
  [GroupCarsIds.FromInventoryGroup]: GROUP_FROM_INVENTORY
}

export const groupPrependMap = new Map<string, GroupCarsIds>([
  [GROUP_FROM_INVENTORY, GroupCarsIds.FromInventoryGroup],
  [GROUP_CARS_OF_INTEREST, GroupCarsIds.CarOfInterestGroup]
])

export const prependGroupNameOptions = (groupArray: VehicleWithGroup[], groupName: string): VehicleWithGroup[] => {
  const prependId = groupPrependMap.get(groupName)

  if (prependId != null && groupArray.length > 0) {
    return [{ ...GET_DEFAULT_VEHICLE(), id: prependId, group: '' }, ...groupArray]
  }

  return groupArray
}
