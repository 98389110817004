import { getAccessToken } from 'services/storage.service'

const copyAuthToken = async (): Promise<void> => {
  try {
    const token = getAccessToken()
    await navigator.clipboard.writeText(`Bearer ${token}`)
    window.console.log('Token is copied')
  } catch (err) {
    window.console.error('Writing to clipboard is not supported by your browser, or HTTPS connection is needed.')
  }
}

export default copyAuthToken
