export const DROPDOWN_OPTIONS = ['Yes', 'No']

export enum TabIds {
  General = 1,
  Financing,
  AddOns
}

export const ADDITIONAL_COMMENT_LENGTH = 400
export const NAME_LENGTH = 255

export const TABS = [
  {
    id: TabIds.General,
    name: 'General',
    withoutCounter: true
  },
  {
    id: TabIds.Financing,
    name: 'Financing',
    withoutCounter: true
  },
  {
    id: TabIds.AddOns,
    name: 'Add ons',
    withoutCounter: true
  }
]
