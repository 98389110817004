import type { FC } from 'react'
import { cnx, formatCurrency, formatInteger } from '@carfluent/common'
import { observer } from 'mobx-react-lite'

import type { LeadCarOfInterest } from 'api/types'
import { VehicleStateNames } from 'constants/names'
import { linkToCar } from 'utils/website'
import CalcRoutes from 'constants/route_helper'
import TooltipedText from 'components/common/TooltipedText'
import CopyIcon from 'components/common/InfoField/copyIcon'

import CarStatus from '../CarStatus'

import carPlaceholderImg from 'assets/placeholder_car.svg'
import CLASS_NAME from './styles'

export interface CarInfoProps {
  car: CarOfInterest
  className?: string
  linkToWebsite: string
  onDelete: (car: CarOfInterest) => void
  onMakePrimary: (car: CarOfInterest) => void
  onCopyCarLink: (nameOfFiled: string) => void
}

export interface CarOfInterest extends LeadCarOfInterest {
  isPrimary: boolean
}

const CarInfo: FC<CarInfoProps> = ({
  car, className,
  linkToWebsite,
  onDelete: _onDelete,
  onMakePrimary: _onMakePrimary,
  onCopyCarLink: _onCopyCarLink
}) => {
  const isActive = car.vehicleState === VehicleStateNames.Active
  const linkToWebsiteCar = linkToCar(linkToWebsite, car)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onCopyCarLink = async (): Promise<void> => {
    if (linkToWebsiteCar != null) {
      await navigator.clipboard.writeText(linkToWebsiteCar)
      _onCopyCarLink?.('Website URL')
    }
  }

  // ========================================== //
  //                    RENDER                  //
  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, 'car-item', className)}>
      <div className={`car-photo ${car.vehicleState?.toLowerCase() ?? ''}`}>
        <img
          alt={car.titleType}
          className={cnx('car-image', (car.mainImageUrl == null) && 'placeholder')}
          src={car.mainImageUrl ?? carPlaceholderImg}
        />

        {/* Active / Inactive / Deleted / Sold */}
        <div className='car-photo-label'>
          {car.vehicleState}
        </div>
      </div>

      <div className='car-info'>
        <div className='car-info-price row'>
          <span>{formatCurrency(car.salePrice)}</span>
          <CarStatus id={car.id} />
        </div>

        <div className='car-info-additional'>
          <div className='car-info-additional-column'>
            <div className='car-info-additional-row row-left'>
              <div className='car-info-additional-label'>Stock #:</div>
              <div className='car-info-additional-value'>
                <a href={CalcRoutes.VehicleDetails(car.id)}>
                  {car.stock}
                </a>
              </div>
            </div>

            <div className='car-info-additional-row row-left'>
              <div className='car-info-additional-label'>VIN Number:</div>
              <div className='car-info-additional-value'>{car.vin}</div>
            </div>

            <div className='car-info-additional-row row-left'>
              <div className='car-info-additional-label'>Engine:</div>
              <TooltipedText
                rootClassName='car-info-additional-value engine'
                value={car.engine ?? '-'}
              />
            </div>

            <div className='car-info-additional-row row-left'>
              <div className='car-info-additional-label'>Odometer:</div>
              <div className='car-info-additional-value'>
                {formatInteger(car.mileage, { emptyValues: '-', postfix: ' miles' })}
              </div>
            </div>

            <div className='car-info-additional-row row-left'>
              <div className='car-info-additional-label'>Age:</div>
              <div className='car-info-additional-value'>
                {formatInteger(car.age, { emptyValues: '-', postfix: ' days' })}
              </div>
            </div>
          </div>
          <div className='car-info-additional-column'>
            <div className='car-info-additional-row row-right'>
              <div className='car-info-additional-label'>Fuel:</div>
              <TooltipedText
                rootClassName='car-info-additional-value fuel'
                value={car.fuelType ?? '-'}
              />
            </div>

            <div className='car-info-additional-row row-right'>
              <div className='car-info-additional-label'>Transmission:</div>
              <TooltipedText
                rootClassName='car-info-additional-value transmission'
                value={car.transmissionType ?? '-'}
              />
            </div>

            <div className='car-info-additional-row row-right'>
              <div className='car-info-additional-label'>Exterior Color:</div>
              <TooltipedText
                rootClassName='car-info-additional-value generic-color'
                value={car.genericColor ?? '-'}
              />
            </div>

            <div className='car-info-additional-row row-right'>
              <div className='car-info-additional-label'>Interior Color:</div>
              <TooltipedText
                rootClassName='car-info-additional-value generic-interior'
                value={car.genericInterior ?? '-'}
              />
            </div>

            <div className='car-info-additional-row row-right website-link-row'>
              <div className='car-info-additional-label'>Website URL:</div>
              {isActive
                ? (
                  <div className='copy-car-link' onClick={onCopyCarLink}>
                    <a href={linkToWebsiteCar ?? '#'} target='_blank' rel='noreferrer'>
                      Link
                    </a>
                    <CopyIcon />
                  </div>
                  )
                : <p>-</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(CarInfo)
