import { css } from '@emotion/css'

export default css(`
  display: grid;
  grid-template-areas: 
    "name closingBalance"
    "description closingBalance"
    "searchAndFilters closingBalance";
  grid-template-columns: minmax(800px, 1fr) fit-content(400px);
  margin: 0px;
  padding: 16px;
  position: relative;
  min-height: 155px;

  .name {
    grid-area: name;
    
    .cf-tooltip-container p {
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.18px;
      color: #101010;
      margin-bottom: 8px;
    }
  }
  
  .description {
    grid-area: description;
    color: #212121CC;
    margin-bottom: 16px;
  }
  
  .search-and-filters {
    grid-area: searchAndFilters;
    align-self: end;
    display: flex;
    height: min-content;

    .g-filters-panel {
      margin-bottom: 0px;
    }

    .g-filters-panel-right-part {
      order: 1;
    }

    .g-filters-panel-left-part {
      order: 2;
    }
  }

  .closing-balance {
    grid-area: closingBalance;
    justify-self: end;
    width: fit-content;
    max-width: min(fit-content, 400px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .reconcile-button {
      text-decoration: none;
      padding: 5px 18px;
      border-radius: 8px;
      border: 1px solid #C99B86;
      font-size: 14px;
      
      span {
        font-weight: 500;
      }
    }
  }
  
  .closing-balance-label {
    margin-bottom: 8px;
    font-size: 16px;
    color: rgba(33, 33, 33, 0.80);
  }
  
  .month-balance {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.38);
    margin-bottom: 8px;
    
    &:last-child {
      margin-bottom: 0px;
    }
  }
  
  .closing-balance-value {
    padding-left: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: #101010;
  }
`)
