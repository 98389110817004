import type { FC } from 'react'
import { CellWrapper, cnx } from '@carfluent/common'

import DepositedLabel from 'components/common/DepositedLabel'

import CLASS_NAME from './styles'

export interface ValueProps {
  statusName: string
  isDeposited: boolean
  soldDealId: number | null
}

export interface StatusCellProps {
  getValue: () => ValueProps
}

const StatusCell: FC<StatusCellProps> = ({ getValue }) => {
  const {
    statusName,
    soldDealId,
    isDeposited
  } = getValue()

  return (
    <CellWrapper className={CLASS_NAME}>
      <span className={cnx('status-container', `status-${statusName != null ? statusName.toLowerCase() : 'no-action'}`)}>
        {statusName}
      </span>
      {(isDeposited && soldDealId === null) && <DepositedLabel className='deposit-label' />}
    </CellWrapper>
  )
}

export default StatusCell
