import type { FC, ReactNode } from 'react'

import { type WarrantySectionProps } from 'pages/deals/CreditApplication/types'
import Collapse, { renderAddRemoveBtn } from 'components/common/Collapse'
import CoverageCheckboxesList from 'components/deals/CoverageCheckboxesList'
import isCoverageSectionChecked from 'utils/coverage/isCoverageSectionChecked'

import WarrantyItem from './WarrantyItem'

const Warranty: FC<WarrantySectionProps> = ({
  isWarrantySectionVisible,
  isFormDisabled,
  errors,
  touched,
  onToggleDealerProduct,
  onToggleIsWarrantySectionVisible,
  onChange,
  onBlur,
  coverageData: {
    penProducts,
    addedProducts,
    coverageDetails
  }
}) => {
  return (
    <Collapse
      isDisabled={isFormDisabled}
      title='Coverage'
      className='credit-app-section warranty'
      isExpanded={isWarrantySectionVisible}
      renderCollapseBtn={renderAddRemoveBtn}
      toggleIsExpanded={onToggleIsWarrantySectionVisible}
    >
      <CoverageCheckboxesList
        className='credit-app-coverages-checklist'
        productsIds={Object.keys(penProducts).map(Number)}
        disabled={isFormDisabled}
        getIsChecked={
          (productId: number) => isCoverageSectionChecked(productId, addedProducts)
        }
        onToggleItem={
          (productId: number, isChecked: boolean) => onToggleDealerProduct(productId, isChecked)
        }
      />

      {coverageDetails.map((item, idx): ReactNode => {
        return (
          <WarrantyItem
            className='warranty-coverage'
            errors={errors[idx]}
            fieldPathPrefix={`coverageData.coverageDetails.[${idx}]`}
            isFormDisabled={isFormDisabled}
            key={item.productTypeId}
            onBlur={onBlur}
            onChange={onChange}
            product={penProducts[item.productTypeId]}
            touched={touched[idx]}
            values={item}
          />
        )
      })}
    </Collapse>
  )
}

export default Warranty
