import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Routes, ReconciliationSetup } from 'constants/route_helper'
import type { UseHeaderSectionReturn, UseHeaderSectionProps } from './types'

const useHeaderSection = ({ accountId: _accountId }: UseHeaderSectionProps): UseHeaderSectionReturn => {
  const navigate = useNavigate()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onImportBankStatement = useCallback(() => {
    navigate(Routes.AccountingBankingUpload)
  }, [navigate])

  const onReconcileNavigate = useCallback((accountId: number | null) => {
    navigate(ReconciliationSetup(Number(accountId)))
  }, [navigate])

  // ========================================== //

  const topPanelActions = useMemo(() => ([
    { title: 'Import bank statement', handleOnClick: onImportBankStatement },
    { title: 'Reconcile', handleOnClick: () => onReconcileNavigate(_accountId) }
  ]), [
    onImportBankStatement, _accountId
  ])

  return {
    topPanelActions
  }
}

export default useHeaderSection
