import { useEffect, useRef, EffectCallback, DependencyList } from 'react'

const useEffectOnce = (effect: EffectCallback, deps: DependencyList): void => {
  const refCalled = useRef(false)
  const refEffect = useRef(effect)

  useEffect(() => {
    if (refCalled.current) {
      return
    }

    refCalled.current = true
    return refEffect.current?.()
  // eslint-disable-next-line
  }, deps)
}

export default useEffectOnce
