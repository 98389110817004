import { useMemo } from 'react'

import AttachInventoryPhotosModal from 'components/crm/AttachInventoryPhotosModal'
import type { UseAttachInventoryPhotosModalProps } from 'components/crm/AttachInventoryPhotosModal/hook/types'
import Popover, { type PopoverProps } from 'components/common/Popover'

import Header from './components/Header'
import Content from './components/Content'
import TextAndFilesArea from './components/TextAndFilesArea'
import useMessenger, { type UseMessengerProps } from './hook/useMessenger'
import useHeightObserver from './hook/useHeightObserver'
import createUseStyles from './styles'

const POPOVER_PROPS: Pick<PopoverProps, 'placement' | 'transform'> = {
  placement: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transform: { x: 16, y: 16 }
}

export interface MessagesProps<M> extends UseMessengerProps<M>{
  isOpen: boolean
  leadPhoneNumber: string | null
  onClose: () => void
  onOpenAttachPhotosModal: () => void
  attachInventoryPhotosModalProps: Omit<UseAttachInventoryPhotosModalProps, 'isPhotosNumLimited'>
  isUnsubscribed: boolean
}

function Messenger <M> ({
  isOpen,
  leadPhoneNumber,
  onClose,
  onOpenAttachPhotosModal,
  attachInventoryPhotosModalProps,
  ...otherProps
}: MessagesProps<M>): JSX.Element {
  const { observer, height } = useHeightObserver()
  const rootCls = useMemo(() => createUseStyles(height), [height])

  const {
    leadId,
    attachmentsUploadProps,
    messageTemplateItems,
    onSubmit,
    photoAttachmentsProps,
    ...contentProps
  } = useMessenger(otherProps)

  const {
    isAttachmentsNumLimitReached,
    files,
    onAddFiles,
    onDeleteFile
  } = attachmentsUploadProps

  return (
    <Popover
      open={isOpen}
      className={rootCls}
      {...POPOVER_PROPS}
    >
      <Header onClose={onClose} />

      <section className='cf-content-section'>
        <Content
          {...contentProps}
          onAddFiles={onAddFiles}
          isFilesNumLimitReached={isAttachmentsNumLimitReached}
        />
      </section>

      <TextAndFilesArea
        leadId={leadId}
        heightObserver={observer}
        messageTemplateItems={messageTemplateItems}
        files={files}
        isLeadUnsubscribed={otherProps.isUnsubscribed}
        onAddFiles={onAddFiles}
        onDeleteFile={onDeleteFile}
        leadPhoneNumber={leadPhoneNumber}
        onSubmit={onSubmit}
        photoAttachmentsProps={photoAttachmentsProps}
        onOpenAttachPhotosModal={onOpenAttachPhotosModal}
      />

      <AttachInventoryPhotosModal
        {...attachInventoryPhotosModalProps}
        isPhotosNumLimited
      />
    </Popover>
  )
}

export default Messenger
