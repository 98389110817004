import { type ReviewFormData } from './types'

export const GET_DEFAULT_FORM_DATA = (description = ''): ReviewFormData => ({
  account: null,
  control: null,
  description,
  entity: null,
  referenceNumber: ''
})

export enum Messages {
  ForbiddenAccount = 'Cannot select the same account',
  Required = 'Cannot be empty'
}
