import type { FC } from 'react'
import { Button, CellWrapper } from '@carfluent/common'

import CLASS_NAME from './styles'

export interface ButtonCellProps {
  getValue: () => ButtonCellPropsCellData
}

export interface ButtonCellPropsCellData {
  label?: string
  onClick: () => void
}

const ButtonCell: FC<ButtonCellProps> = (props) => {
  const { label = 'Go to lead', onClick } = props.getValue()

  return (
    <CellWrapper className={CLASS_NAME}>
      <Button
        onClick={onClick}
        variant='outlined'
        color='secondary'
      >
        {label}
      </Button>
    </CellWrapper>
  )
}

export default ButtonCell
