import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-wrap: nowrap;

  &.no-activity .table-cell-text > p {
    color: #FFA451;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }

  &&.subject-cell {
    border-top-left-radius: 3.5px;
    border-bottom-left-radius: 3.5px;

    &.overdue-border-color { border-left: 4px solid #B00020; }

    &.border-color { border-left: 4px solid #FFCA28; } 

    &.future-task-border-color { border-left:  4px solid #DDD}

    &.completed-tab { border-left: 4px solid transparent; }
  }
`)
