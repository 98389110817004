import { NavigateFunction } from 'react-router-dom'
import { UserRoles } from 'api/types'
import { Routes } from 'constants/route_helper'
import { fullTasksUrl } from 'routing/constants'
import { isTruthy } from 'utils/general'

const defaultRoute = Routes.Deals

const roleToRouteMap: { [key in UserRoles]: string } = {
  [UserRoles.BDC]: fullTasksUrl,
  [UserRoles.Buyer]: Routes.Vehicles,
  [UserRoles.Mechanic]: Routes.Recon,
  [UserRoles.SuperAdmin]: Routes.Dashboard,
  [UserRoles.Admin]: Routes.Dashboard,
  [UserRoles.Sales]: Routes.Dashboard
}

export const navigateToUserDefaultRoute = (loggedUser: UserRoles[] | undefined, navigate: NavigateFunction): void => {
  const navigateToRoleRoute = (role: UserRoles): void => {
    const route = roleToRouteMap[role]
    if (isTruthy(route)) {
      navigate(route)
    } else {
      navigate(defaultRoute)
    }
  }

  const foundRole = Object.keys(roleToRouteMap).find((role) => loggedUser?.includes(role as UserRoles))

  if (foundRole != null) {
    navigateToRoleRoute(foundRole as UserRoles)
  } else {
    navigate(defaultRoute)
  }
}
