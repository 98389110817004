import { type FC, useCallback, useMemo, useRef, useState } from 'react'
import Popover from '@material-ui/core/Popover'
import { cnx, FormNumberInput, noop, type NumberInputProps } from '@carfluent/common'

import { MAX_PRICE_VALUE } from 'constants/validation'
import { CLASS_NAME, POPOVER_CLASS_NAME } from './styles'

const POPOVER_ANCHOR_ORIGIN = { vertical: 'bottom', horizontal: 'left' } as const

interface PopperWrapperProps extends Omit<NumberInputProps, 'id' | 'onChange' | 'label' | 'value'> {
  amountValue: number | null
  isOpen: boolean
  label: string
  onClose: () => void
  onOpen: () => void
  taxValue: number | null
}

const PopperTrigger: FC<PopperWrapperProps> = ({
  amountValue,
  disabled = false,
  isOpen,
  label,
  children,
  onClose: _onClose,
  onOpen: _onOpen,
  taxValue
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const salesTaxStr = new Intl.NumberFormat('en-IN', {
    maximumFractionDigits: 3,
    minimumFractionDigits: 2
  }).format((taxValue ?? 0) * 100)

  const onOpen = useCallback(() => {
    _onOpen()
    setAnchorEl(containerRef.current)
  }, [_onOpen])

  const onClose = useCallback(() => {
    _onClose()
    setAnchorEl(null)
  }, [_onClose])

  const endAdornment = useMemo(() => (
    <span
      onClick={disabled ? noop : onOpen}
      className={cnx('clickable-input-adornment', disabled && 'disabled')}
      aria-describedby='popper-wrapper'
    >
      Edit
    </span>
  ), [onOpen, disabled])

  // ========================================== //

  return (
    <div className={CLASS_NAME} ref={containerRef}>
      <div
        className={cnx('backdrop', !disabled && 'not-disabled')}
        onClick={onOpen}
      />

      <FormNumberInput
        blurComplementMode='min-scale'
        className={cnx('clickable-input', !disabled && 'not-disabled')}
        disabled
        endAdornment={endAdornment}
        id='readonly-sales-tax-amount'
        isEmptyAllowed={false}
        label={label}
        onChange={noop}
        preset='price'
        max={MAX_PRICE_VALUE}
        mountComplementMode='min-scale'
        value={amountValue}
      />

      <p className='tax-label'>
        Tax {salesTaxStr}%
      </p>

      <Popover
        id='popper-wrapper'
        className={POPOVER_CLASS_NAME}
        open={isOpen}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={POPOVER_ANCHOR_ORIGIN}
      >
        <div className='popover-content'>
          {children}
        </div>
      </Popover>
    </div>
  )
}

export default PopperTrigger
