import { css } from '@emotion/css'

export default css(`
  --editorContentMinHeight: 480px;
  
  display: flex;
  margin-top: 8px;
  
  .ck.ck-editor {
    width: 100%;
    
    .ck.ck-editor__top {
      .ck-sticky-panel .ck-sticky-panel__content {
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 12px;
        padding: 9px 4px;
        margin-bottom: 16px;
        position: static !important;
      }
    }
    
    .ck.ck-editor__main {
      .ck.ck-content.ck-editor__editable.ck-focused {
        box-shadow: none;
      }
      
      .ck.ck-content {
        padding: 16px;
        min-height: var(--editorContentMinHeight);
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 12px;
      }
      
      h2 {
        margin-bottom: 0;
        font-size: 2.1rem;
      }

      h3 {
        font-size: 1.6rem;
      }
      
      h4 {
        font-size: 1.4rem;
      }
      
      ul {
        margin-left: 1em;
      }
      
      ol {
        margin-left: 1em;
      }
      
      strong {
        font-weight: 500;
      }
      
      sup {
        vertical-align: super;
        font-size: smaller;
      }
      
      sub {
        vertical-align: sub;
        font-size: smaller;
      }
    }
    
    .ck.ck-powered-by {
      display: none !important;
    }
  }
`)
