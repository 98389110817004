import { CoverageProductTypeIds } from 'api/types/responses'

export const PACKAGES_LIST = [
  {
    name: 'Service Contract',
    id: CoverageProductTypeIds.ServiceContract
  },
  {
    name: 'GAP Insurance',
    id: CoverageProductTypeIds.GapInsurance
  },
  {
    name: 'Appearance Protection',
    id: CoverageProductTypeIds.AppearanceProtection
  },
  {
    name: 'Tire and Wheel',
    id: CoverageProductTypeIds.TireAndWheel
  },
  {
    name: 'Theft',
    id: CoverageProductTypeIds.Theft
  }
]

export const COVERAGES_NAMES = {
  [CoverageProductTypeIds.ServiceContract]: 'Service Contract',
  [CoverageProductTypeIds.GapInsurance]: 'GAP Insurance',
  [CoverageProductTypeIds.AppearanceProtection]: 'Appearance Protection',
  [CoverageProductTypeIds.TireAndWheel]: 'Tire and Wheel',
  [CoverageProductTypeIds.Theft]: 'Theft'
}
