import { type FormValidation, type DeepRequired } from '@carfluent/common'
import { AccountListItem } from 'api/types'

import { buildPreset } from 'utils/validationPresets'
import { buildRule, ruleRequired, ruleRevalidationTrigger } from 'utils/validationRules'
import { Messages } from './constants'
import { type ReviewFormData } from './types'

export const ruleForbinedAccountNumber = buildRule((
  val: AccountListItem | null,
  _,
  forbiddenAccountNumber?: number | null
) => {
  return Number(val?.id) !== forbiddenAccountNumber
})

export interface ValidationRulesConfig {
  forbiddenAccountNumber: number | null
}

const getValidationRules = ({
  forbiddenAccountNumber
}: ValidationRulesConfig): FormValidation<DeepRequired<ReviewFormData>> => {
  const accountRule = buildPreset([
    ruleRequired(Messages.Required),
    ruleForbinedAccountNumber(Messages.ForbiddenAccount, forbiddenAccountNumber)
  ])

  return {
    account: accountRule(),
    description: ruleRequired(Messages.Required),
    referenceNumber: ruleRevalidationTrigger('') // DD-CHECK: if field is not described here - it's error is not erased after onChange
  }
}

export default getValidationRules
