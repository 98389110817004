import type { ParseBankStatementsDto } from 'api/types'
import { toDate } from 'utils/parse_date'

import type { StatementPreviewFormData } from './types'

export const parseBankStatements = (data: ParseBankStatementsDto): StatementPreviewFormData[] => {
  const { transactions = [] } = data

  return transactions?.map((item) => {
    const hasError = [
      item.date?.hasError,
      item.description?.hasError,
      item.deposit?.hasError,
      item.withdrawal?.hasError,
      item.isDuplicate
    ].some(Boolean)

    return ({
      ...item,
      date: {
        error: item?.date?.error ?? null,
        hasError: item?.date?.hasError ?? false,
        rawValue: item?.date?.rawValue != null ? toDate(item?.date?.rawValue.toString()) : null
      },
      checked: !hasError && !item.isPending,
      hasError: hasError
    })
  }) ?? []
}

export default parseBankStatements
