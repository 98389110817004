import { css } from '@emotion/css'

export default css(`
  .amount-block {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #000000;
    }

    .cf-input-container {
      width: 130px;
    }
  }
`)
