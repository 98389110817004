import type { FC } from 'react'
import type { IconProps } from 'types'

const IconUserDocuments: FC<IconProps> = ({ width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#FFF2EC"/>
      <path
        d="M12 6.66406C12.7072 6.66406 13.3855 6.94501 13.8856 7.44511C14.3857 7.94521 14.6666 8.62349 14.6666 9.33073C14.6666 10.038 14.3857 10.7163 13.8856 11.2163C13.3855 11.7164 12.7072 11.9974 12 11.9974C11.2927 11.9974 10.6144 11.7164 10.1143 11.2163C9.61424 10.7163 9.33329 10.038 9.33329 9.33073C9.33329 8.62349 9.61424 7.94521 10.1143 7.44511C10.6144 6.94501 11.2927 6.66406 12 6.66406ZM12 13.3307C14.9466 13.3307 17.3333 14.5241 17.3333 15.9974V17.3307H6.66663V15.9974C6.66663 14.5241 9.05329 13.3307 12 13.3307Z"
        fill="#DDB3A0"
      />
    </svg>
  )
}

export default IconUserDocuments
