import { type EntityId } from 'types'
import { type PaginatedResult } from 'api/types'
import { type OpeningBalanceRowData, type OpeningBalanceFromApi } from './types'

export interface ParserConfig {
  categoriesMap: Map<EntityId, string>
  typesMap: Map<EntityId, string>
}

const parseListData = (
  config: ParserConfig,
  data: PaginatedResult<OpeningBalanceFromApi>
): PaginatedResult<OpeningBalanceRowData> => {
  const { items, ...rest } = data
  return {
    ...rest,
    items: items.map(parseRowData(config))
  }
}

const parseRowData = (config: ParserConfig) =>
  (data: OpeningBalanceFromApi): OpeningBalanceRowData => {
    return {
      ...data,
      accountCategory: config.categoriesMap.get(data.accountCategory ?? -1) ?? '',
      accountType: config.typesMap.get(data.accountType) ?? ''
    }
  }

export default parseListData
