import { useLayoutEffect, useRef, type FC } from 'react'
import { cnx } from '@carfluent/common'

import type { LinkItem } from 'constants/route_helper'
import MenuListItem from './MenuListItem'
import { ITEM_GAP, MENU_ITEM_HEIGHT } from '../styles'

interface MenuItemProps {
  item: LinkItem
  expandedSection: string | null
  matchedPathname?: string
  isSubLinksShown?: boolean
  isClosedMode?: boolean
  setIsSubLinksShown?: (itemTo: string | null) => void
}

const EXPANDED_CLS = 'cf-menu-expanded'

const MenuItem: FC<MenuItemProps> = ({
  item,
  matchedPathname = '',
  expandedSection,
  isClosedMode = false,
  setIsSubLinksShown
}) => {
  const subMenuRef = useRef<HTMLUListElement>(null)

  const isActiveItem = matchedPathname.startsWith(item.to)
  const isExpanded = expandedSection === item.to
  const subLinks = item.subLinks ?? []
  const hasSubLinks = subLinks.length > 0

  // ========================================== //
  //                  HANDLERS                  //
  // ========================================== //

  const renderSubLink = (subLink: LinkItem): JSX.Element => {
    const isActiveSubItem = matchedPathname === subLink.to
    const subItemCls = cnx('nav-sub-item', 'nav-item', isActiveSubItem && 'nav-item-selected')

    return (
      <li key={subLink.to} className={subItemCls}>
        <MenuListItem item={subLink} hasSubLinks={false} />
      </li>
    )
  }

  const onItemClick = (): void => {
    const isExpanded = expandedSection === item.to
    setIsSubLinksShown?.((isClosedMode || !isExpanded) ? item.to : null)
  }

  const onMouseEnter = (): void => {
    if (isClosedMode) {
      setIsSubLinksShown?.(item.to)
    }
  }

  const onMouseLeave = (): void => {
    if (isClosedMode) {
      setIsSubLinksShown?.(null)
    }
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useLayoutEffect(() => {
    const el = subMenuRef.current

    if (el == null) {
      return
    }

    if (isClosedMode) {
      el.style.height = '0px'
      return
    }

    if (isExpanded) {
      const height = subLinks.length * (MENU_ITEM_HEIGHT + ITEM_GAP)
      el.style.height = `${height}px`
    } else {
      el.style.height = '0px'
    }
  }, [isExpanded, isClosedMode, subLinks.length])

  // ========================================== //

  const rootClasses = cnx(
    'nav-item',
    isActiveItem && 'nav-item-selected',
    isExpanded && 'nav-item-hovered',
    !hasSubLinks && 'single-nav-item'
  )

  return (
    <li
      key={item.to}
      className={rootClasses}
      data-title={item.title}
      onClick={onItemClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <MenuListItem
        hasSubLinks={hasSubLinks}
        isActive={isActiveItem}
        isSubLinksShown={isExpanded}
        item={item}
      />
      {
        hasSubLinks && (
          <>
            <ul ref={subMenuRef} className={cnx('nav-sub-group', (isExpanded && !isClosedMode) && EXPANDED_CLS)}>
              {subLinks.map(renderSubLink)}
            </ul>

            <div className={cnx('hover-nav-menu', isExpanded && EXPANDED_CLS)}>
              <div className='hover-menu-inner-block'>
                <p className='hover-menu-title'>{item.title}</p>
                <ul>{subLinks.map(renderSubLink)}</ul>
              </div>
            </div>
          </>
        )
      }
    </li>
  )
}

export default MenuItem
