/**
 * Returns a unique name based on the given name.
 * If the given name is already used, a counter is appended to the name.
 * Function is not idempotent which means that it will always increment the counter for the same name.
 */
const getUniqueName = (() => {
  const names = new Set<string>()
  let counter = 0

  return (name: string) => {
    let uniqueName = name
    if (names.has(name)) {
      uniqueName = `${name}-${counter++}`
    }

    names.add(uniqueName)
    return uniqueName
  }
})()

export default getUniqueName
