import { ProductType } from 'api/types'
import { CoverageProductTypeIds } from 'api/types/responses'

export const COVERAGE_LABELS: Record<string, string> = {
  [ProductType.ServiceContract]: 'Service contract',
  [ProductType.GapInsurance]: 'GAP',
  [ProductType.AppearanceProtection]: 'Appearance Protection',
  [ProductType.TireAndWheel]: 'Tire and Wheel',
  [ProductType.Theft]: 'Theft'
}

export const COVERAGE_ORDER: Record<string, number> = {
  [ProductType.ServiceContract]: 1,
  [ProductType.GapInsurance]: 2,
  [ProductType.AppearanceProtection]: 3,
  [ProductType.TireAndWheel]: 4,
  [ProductType.Theft]: 5
}

export const COVERAGE_NAME_BY_ID: Record<number, string> = {
  [CoverageProductTypeIds.ServiceContract]: 'Service contract',
  [CoverageProductTypeIds.GapInsurance]: 'GAP',
  [CoverageProductTypeIds.AppearanceProtection]: 'Appearance Protection',
  [CoverageProductTypeIds.TireAndWheel]: 'Tire and Wheel',
  [CoverageProductTypeIds.Theft]: 'Theft'
}
