import { useCallback, useContext, useState, useMemo } from 'react'
import { Row } from '@tanstack/react-table'

import { type RequestVehicleId } from 'api/types'
import AccountingApiProvider from 'api/accounting.api'
import useAsyncEffect from 'hooks/useAsyncEffect'
import CostsCTX from 'store/costs'
import SettingsCTX from 'store/settings'

import { type CostDetailsListItem, type UseCostsDetailsReturn } from './types'
import { parseData } from './parser'

export { type CostDetailsListItem, type UseCostsDetailsReturn } from './types'

const useCostsDetails = (vehicleId: RequestVehicleId): UseCostsDetailsReturn => {
  const { setCostsDetails, costsDetails } = useContext(CostsCTX)
  const { isAccountingEnabled } = useContext(SettingsCTX)

  const [selectedCostDetails, setSelectedCostDetails] = useState<number | null>(null)
  const [costTypeId, setCostTypeId] = useState<number | null>(null)
  const [transactionTypeId, setTransactionTypeId] = useState<number | null>(null)
  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const getDetails = useCallback(async () => {
    if (vehicleId == null) {
      return
    }

    try {
      const res = await AccountingApiProvider.getCostsDetails(vehicleId)
      setCostsDetails(res.items)
    } catch {
      console.error('Fetching costs details error')
    }
  }, [vehicleId, isAccountingEnabled])

  const onViewNextTransaction = useCallback((transactionId: number, transactionTypeId: number, costTypeId?: number) => {
    setModalOpen(false)

    setTimeout(() => {
      setSelectedCostDetails(transactionId)
      setCostTypeId(costTypeId ?? transactionTypeId)
      setTransactionTypeId(transactionTypeId)
      setModalOpen(true)
    }, 0)
  }, [])

  const onRowClick = useCallback(({
    original: { id, costTypeId, transactionTypeId }
  }: Row<CostDetailsListItem>) => {
    onViewNextTransaction(id, transactionTypeId, costTypeId)
  }, [onViewNextTransaction])

  const onCostDetailsModalClose = useCallback(() => {
    setModalOpen(false)
    setSelectedCostDetails(null)
    setCostTypeId(null)
    setTransactionTypeId(null)
  }, [])

  useAsyncEffect(async () => {
    if (isAccountingEnabled) {
      await getDetails()
    }
  }, [getDetails])

  return useMemo(() => ({
    items: parseData(costsDetails),
    getDetails,
    onRowClick,
    isModalOpen,
    onCostDetailsModalClose,
    onViewNextTransaction,
    selectedCostDetails,
    costTypeId,
    typeId: transactionTypeId
  }), [
    costsDetails,
    getDetails,
    onRowClick,
    isModalOpen,
    onCostDetailsModalClose,
    onViewNextTransaction,
    selectedCostDetails,
    costTypeId,
    transactionTypeId
  ])
}

export default useCostsDetails
