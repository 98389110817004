import { css } from '@emotion/css'

interface UseStyleProps {
  isFilterTitleShouldBeBold: boolean
  isDisablePortal: boolean
}

export const createUseStyles = ({ isFilterTitleShouldBeBold, isDisablePortal }: UseStyleProps): string => css(`
  ${isDisablePortal
? `
    position: relative;
    
    .cf-content {
       position: absolute;
       top: 30px;
       left: 0;
       background: white;
       z-index: 20;
       max-height: 400px;
       overflow-y: auto;
       box-shadow: 0px 24px 38px rgba(58, 71, 78, 0.1);
       border-radius: 12px;
    }
  `
: ''}
  
  .cf-title {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color: ${isFilterTitleShouldBeBold ? '#000' : 'inherit'};
    
    &.cf-title-disabled {
      opacity: 0.38;
      cursor: not-allowed;
    }

    img {
      margin-left: 8px;
    }

    &:hover {
      color: #000;
    }
  }
`)
