import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  .action-bar {
    display: inline-flex!important;
    opacity: 1!important;
  }

  .no-data {
    width: 100%;
    opacity: 1!important;
    display: inline-flex!important;
    height: 64px;
    padding: 12px 16px;
    margin-top: 8px;
    align-items: center;
    justify-content: center;

    p {
      text-wrap: nowrap;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }

  .filters-wrapper {
    display: flex!important;
    opacity: 1!important;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
  }

  .summary-info {
    display: flex!important;
    opacity: 1!important;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.12);

    &-item {
      height: 60px;
      display: flex;
      padding: 8px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 1 0 0;

      .cf-tooltip-container > p, .info-value {
        font-weight: 500;
        letter-spacing: 0.4px;
      }
    }
  }
`)

export const ROOT_CLASS_NAME = css(`
  position: relative;
  margin-left: 0!important;

  .cf-md {
    width: calc(100% - 48px);
    margin: 0 24px;
    max-width: 100%;
    
    @media screen and (min-width: 1440px) {
      width: 1300px;
      margin: 0 auto;
    }
  }

  .cf-report-top-actions {
    margin-top: 24px;
    margin-bottom: 24px!important;
  }
  
  .cf-report-content {
    th { font-weight: 500; }
    td, th { font-size: 16px; }
    th:first-child, td:first-child { padding-left: 16px; }
    tbody tr { height: 64px; } 

    .date-cell { width: 100px; max-width: 100%; }
    .stock-cell { width: 100px; max-width: 100%; }
    .car-cell { min-width: 120px; max-width: 100%; }

    .sale-price-cell,
    .front-gross-cell,
    .fi-gross-cell {
      width: 130px;
      max-width: 100%;
      text-align: right;
    }
  }
`)

export const REPORT_CLASS_NAME = css(`
  .gross-profit-table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin-top: 24px;
  }

  td, th {
    font-family: Roboto;
    font-style: normal;
    letter-spacing: 0.15px;
    color: #101010;
    padding: 12px 8px;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }

  th:last-child, td:last-child { padding-right: 16px; }

  thead {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    tr { 
      min-height: 48px;

      :hover { background-color: #ffffff; }
    }
  }

  tbody { 
    border: 1px solid #fff;

    tr { 
      min-height: 64px;

      :hover { background-color: rgba(250, 250, 250, 1); }
    }
  }
  
  .buyer-cell,
  .sales-person-cell,
  .sold-to-cell { 
    width: 120px;
  }

  .date-cell { max-width: 78px; }
  .stock-cell { max-width: 68px; }
  .car-cell { min-width: 100px; max-width: 120px; }

  .sale-price-cell,
  .front-gross-cell,
  .fi-gross-cell {
    max-width: 130px;
    text-align: right;
  }

  .total-gross-cell {
    max-width: 160px;
    text-align: right;
  }

  .loading-row {
    height: 16px;
    background: rgba(33, 33, 33, 0.08);
    border-radius: 2px;
  }

  .vehicle-link {
    .tooltip-content {
      color: rgb(69, 143, 255);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      &:hover {
        color: rgb(69, 143, 255);
        text-decoration: none;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }
`)
