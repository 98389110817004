import type { FC } from 'react'
import type { TextFieldProps } from '@material-ui/core/TextField/TextField'
import { TextField } from '@material-ui/core'

export type InputProps = TextFieldProps & { 'data-test-id'?: string }

/**
 * @deprecated - use Input from Common Library
 */
const Input: FC<InputProps> = TextField

export default Input
