import {
  type AccountOrInvite,
  type DealerAccount,
  type DealerAccountInvites,
  type DealerInvite,
  type DealerAccountWithRoleId,
  ViewDealerRoles
} from 'api/types'
import { VIEW_DEALER_ROLE_NAMES } from 'constants/constants'

export const isInviteGuard = (val: AccountOrInvite): val is DealerInvite => {
  return 'isInvite' in val
}

export const DEALER_ROLE_KEYS = Object.keys(VIEW_DEALER_ROLE_NAMES) as unknown[] as ViewDealerRoles[]

export const mapDealerInvite = (val: DealerInvite): AccountOrInvite => {
  return {
    ...val,
    isInvite: true,
    dealerRole: DEALER_ROLE_KEYS.find(key => VIEW_DEALER_ROLE_NAMES[key] === val.role)
  }
}

export const mapDealerAccount = (val: DealerAccount): AccountOrInvite => {
  return {
    ...val,
    phoneNumber: val.phoneNumber ?? null,
    dealerRole: DEALER_ROLE_KEYS.find(key => VIEW_DEALER_ROLE_NAMES[key] === val.dealerRoleName),
    email: val.dealerEmail,
    name: (val.dealerFirstName != null && val.dealerLastName != null)
      ? `${val.dealerFirstName} ${val.dealerLastName}`
      : ''
  }
}

export const mapDealerAccountsAndInvites = (val: DealerAccountInvites): AccountOrInvite[] => {
  const { dealerAccounts, dealerInvites } = val

  return [
    ...dealerAccounts.map(mapDealerAccount),
    ...dealerInvites.map(mapDealerInvite)
  ]
}

export const parseDealerAccounts = (val: DealerAccountInvites): DealerAccountWithRoleId[] => {
  const { dealerAccounts } = val

  return dealerAccounts.map((dealerAccount: DealerAccount) => {
    return {
      id: dealerAccount.id,
      dealerFirstName: dealerAccount.dealerFirstName ?? null,
      dealerLastName: dealerAccount.dealerLastName ?? null,
      dealerIds: dealerAccount.dealerIds ?? [],
      roleId: Number(DEALER_ROLE_KEYS.find(key => VIEW_DEALER_ROLE_NAMES[key] === dealerAccount.dealerRoleName)) ?? null,
      phoneNumber: dealerAccount.phoneNumber
    }
  })
}
