import { css } from '@emotion/css'
import { px, ScreenWidthBreakpoints } from 'constants/constants'

export default css(`
  margin-bottom: 24px;
  box-sizing: border-box;
  display: grid;
  height: 100%;
  width: 100%;
  min-height: 124px;
  grid-template-columns: repeat(6, minmax(200px, 1fr));
  grid-template-rows: 200px;
  grid-gap: 24px;
  justify-content: space-evenly;
  align-items: stretch;

  // remove scary blue highlighting on select 
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .cf-photo-item {
    position: relative;
    height: 200px;
    border-radius: 12px;
    outline: none;
    overflow: hidden;
    cursor: pointer;

    img {
      pointer-events: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.selected .cf-icon-tick {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(33, 33, 33, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      
      svg {
        position: absolute;
      }
    }

    &.disabled {
      pointer-events: none;
      
      .disabled-bg {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.6);
      }
    }

    &.exceeded-size-limit {
      pointer-events: none;
    }

    .cf-size-limit-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.9);
    }

    .cf-size-limit {
      color: rgba(33, 33, 33, 0.8);
      margin: 0;
    }
  }

  .icon-player {
    position: absolute;
    top: 50%;
    display: block;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    left: 50%;
    background: rgb(0 0 0 / 60%);
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  

  @media screen and (max-width: ${px(ScreenWidthBreakpoints.Medium)}) {
    grid-template-columns: repeat(6, minmax(180px, 1fr));
    grid-template-rows: 180px;

    .cf-photo-item {
      height: 180px; 
    }
  }
`)
