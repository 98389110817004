
import { type LeadsDashboardCrmDto, type LeadsDashboardCrmModel } from 'api/types/responses'

import { parseDateStringUTC } from '@carfluent/common'

const parseDashboardLeadsDto = (data: LeadsDashboardCrmDto): LeadsDashboardCrmModel => {
  return {
    ...data,
    leadsByDate: data.leadsByDate?.map((item) => ({
      ...item,
      date: parseDateStringUTC(item.date)
    })) ?? []
  }
}

export default parseDashboardLeadsDto
