import { css } from '@emotion/css'

export default css(`
  display: grid;
  grid-template-columns: auto minmax(10px, auto) 145px minmax(10px, auto);
  justify-content: flex-end;
  width: 100%;
  height: 24px;

  .cf-icon-btn {
    cursor: pointer;
    align-self: center;
  }

  .cf-date-btn {
    margin: 0 auto;
  }
  
  .MuiButton-root {
    :hover {
      background-color: transparent;
    }
  }

  .cf-today-btn {
    margin-right: 24px;
  }
`)
