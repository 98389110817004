import {
  type KeyboardEvent,
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
  useMemo
} from 'react'

import { type EntityId } from 'types'
import { type VehicleImage } from 'api/types'
import { type FileData } from 'hooks/useFilesUpload'
import CRMApiProvider from 'api/crm.api'
import SettingsCTX from 'store/settings'
import { getVideoLinkToWebsite } from 'utils/website'
import { getParsedToken } from 'services/storage.service'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { DEFAULT_MAX_FILES_NUM, FILE_NUMBER_ERROR } from 'constants/files'
import { type PhotoAttachmentsProps } from 'pages/crm/LeadDetailsView/hook/types'

import { MAX_MESSAGE_LENGTH, KeyCode, FILE_PREFIX, PHOTO_PREFIX } from './constants'
import { getVehicleImages } from './utils'

export interface UseTextAndFilesAreaProps {
  files: FileData[]
  isLeadUnsubscribed: boolean
  heightObserver: ResizeObserver
  leadId: EntityId
  leadPhoneNumber: string | null
  onDeleteFile: (fileName: string) => void
  onSubmit: (message: string) => Promise<void>
  photoAttachmentsProps: PhotoAttachmentsProps
}

interface UseTextAndFilesAreaReturn {
  attachments: Attachments
  isAddingAttachmentsDisabled: boolean
  isDisabled: boolean
  isMaxLengthValue: boolean
  onAddTemplate: (id: number) => Promise<void>
  onAddVideoLinks: (files: VehicleImage[]) => void
  onChange: (id: string | undefined, value: string) => void
  onDeleteAttachment: (id: string) => void
  onKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void
  onSubmit: () => void
  textareaWrapperEl: RefObject<HTMLDivElement>
  value: string
}

export type Attachments = Array<FileData & { id: string } | VehicleImage & { id: string }>

const MAX_ATTACHMENTS_AMOUNT = 10

const useTextarea = ({
  files,
  heightObserver,
  isLeadUnsubscribed,
  leadId,
  leadPhoneNumber,
  photoAttachmentsProps: {
    vehicleVideoAttachments: videos,
    vehiclePhotoAttachments: photos,
    setVehiclePhotoAttachments,
    onDeletePhoto
  },
  onDeleteFile,
  onSubmit: _onSubmit
}: UseTextAndFilesAreaProps): UseTextAndFilesAreaReturn => {
  const { dealer } = useContext(SettingsCTX)
  const { showAlert } = useCustomSnackbar()

  const textareaWrapperEl = useRef<HTMLDivElement>(null)
  const [isMaxLengthValue, setIsMaxLengthValue] = useState(false)
  const [value, setValue] = useState<string>('')
  const valueRef = useRef<string>('')

  const generatedAttachments = useMemo<Attachments>(() => [
    ...files.map(file => ({ ...file, id: `${FILE_PREFIX}${file.id}` })),
    ...photos.map(photo => ({ ...photo, id: `${PHOTO_PREFIX}${photo.fileName}${photo.order}` }))
  ], [files, photos])

  const [attachments, setAttachments] = useState(generatedAttachments)

  const token = getParsedToken()
  const dealerId: string = token?.dealer ?? ''
  const attachmentsAmount = attachments.length
  const areAttachmentsEmpty = attachments.length === 0
  const linkToWebsite = `${dealer?.dealerFeeMarkupSettings.websiteBaseUrl ?? ''}/dealer/${dealerId}`

  const isDisabled = useMemo(() => {
    const isDisabledByFilesLoading = files.some(v => v.url == null || v.url === '')
    const val = value.trim()

    return ((val === '' && areAttachmentsEmpty) || (val.length > MAX_MESSAGE_LENGTH)) ||
      isDisabledByFilesLoading
  }, [value, files, areAttachmentsEmpty])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onChange = useCallback((_id: EntityId | undefined, value: string): void => {
    const isMaxLength = value.length >= MAX_MESSAGE_LENGTH

    setValue(value)
    setIsMaxLengthValue(isMaxLength)
  }, [])

  const onSubmit = useCallback(async (): Promise<void> => {
    if (isDisabled || (leadPhoneNumber == null)) {
      return
    }

    valueRef.current = value
    setValue('')

    try {
      await _onSubmit(valueRef.current)
    } catch {
      setValue(valueRef.current)
    } finally {
      valueRef.current = ''
    }
  }, [
    isDisabled,
    leadPhoneNumber,
    _onSubmit,
    value
  ])

  const onSubmitAndClear = useCallback(() => {
    if (!isLeadUnsubscribed) {
      setAttachments([])
      void onSubmit()
    }
  }, [onSubmit, isLeadUnsubscribed])

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLTextAreaElement>): void => {
    const isEnterKey = e.key === KeyCode.Enter

    if (isEnterKey) {
      e.preventDefault()

      const isBrakeLine = e.metaKey || e.altKey || e.shiftKey || e.ctrlKey

      if (isBrakeLine) {
        setValue(value + '\n')
      } else {
        void onSubmit()
      }
    }
  }, [onSubmit])

  const onAddTemplate = useCallback(async (id: number) => {
    try {
      const rawTemplate = await CRMApiProvider.getMessageTemplates(leadId, id)
      const templateText = rawTemplate.body ?? ''

      const textarea =
        textareaWrapperEl.current?.querySelector('textarea') as HTMLTextAreaElement

      const { selectionStart, selectionEnd, value } = textarea

      const newText =
        `${value.slice(0, selectionStart)}\n${templateText}${value.slice(selectionEnd)}`.trim()

      textarea.selectionStart = value.length
      textarea.selectionEnd = value.length
      textarea.focus()

      if (rawTemplate.imageUrls.length > 0) {
        const allowedAmount = MAX_ATTACHMENTS_AMOUNT - attachmentsAmount

        if (allowedAmount <= 0) {
          showAlert(FILE_NUMBER_ERROR)
        } else {
          const imageUrlsToLoad = allowedAmount > rawTemplate.imageUrls.length
            ? rawTemplate.imageUrls
            : rawTemplate.imageUrls.slice(0, allowedAmount)

          const vehicleImages = await getVehicleImages(imageUrlsToLoad)

          setVehiclePhotoAttachments((prev) => {
            return [
              ...prev,
              ...vehicleImages
            ]
          })
        }
      }

      onChange(id, newText)
      textarea.scrollTop = textarea.scrollHeight
    } catch (err) {
      console.error('error', err)
    }
  }, [leadId, onChange, setVehiclePhotoAttachments, attachmentsAmount])

  const onAddVideoLinks = useCallback((files: VehicleImage[]) => {
    const templateText = files.map(file =>
      getVideoLinkToWebsite(linkToWebsite, file?.vehicleId?.toString() ?? '', file?.id ?? '')
    ).join('\n')

    const textarea = textareaWrapperEl.current?.querySelector('textarea') as HTMLTextAreaElement
    const { selectionStart, selectionEnd, value } = textarea

    const newText = `${value.slice(0, selectionStart)}\n${templateText}${value.slice(selectionEnd)}`.trim()

    textarea.selectionStart = value.length
    textarea.selectionEnd = value.length
    textarea.focus()

    onChange('', newText)
    textarea.scrollTop = textarea.scrollHeight
  }, [onChange])

  const onDeleteAttachment = useCallback((id: string): void => {
    if (id.startsWith(PHOTO_PREFIX)) {
      onDeletePhoto(id.replace(PHOTO_PREFIX, ''))
    } else if (id.startsWith(FILE_PREFIX)) {
      onDeleteFile(id.replace(FILE_PREFIX, ''))
    }
  }, [onDeleteFile, onDeletePhoto])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (videos != null && videos.length > 0) {
      onAddVideoLinks(videos)
    }
  }, [onAddVideoLinks, videos])

  useEffect(() => {
    if (textareaWrapperEl.current != null) {
      heightObserver.observe(textareaWrapperEl.current)
    }

    return () => {
      if (textareaWrapperEl.current != null) {
        heightObserver.unobserve(textareaWrapperEl.current)
      }
    }
  }, [heightObserver])

  useEffect(() => {
    if (!isLeadUnsubscribed) {
      setAttachments(generatedAttachments)
    }
  }, [generatedAttachments, isLeadUnsubscribed])

  // ========================================== //

  return {
    attachments,
    isAddingAttachmentsDisabled: attachments.length >= DEFAULT_MAX_FILES_NUM,
    isDisabled,
    isMaxLengthValue,
    onAddTemplate,
    onAddVideoLinks,
    onChange,
    onDeleteAttachment,
    onKeyDown,
    onSubmit: onSubmitAndClear,
    textareaWrapperEl,
    value
  }
}

export default useTextarea
