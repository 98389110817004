import type { FC } from 'react'
import { useMemo } from 'react'

import Approved from 'assets/approved.svg'
import Declined from 'assets/declined.svg'
import CounterOffer from 'assets/counterOffer.svg'
import { ApprovalStatus } from 'constants/names'

import useStyles from './styles'

interface DecisionStatusProps {
  approvalStatus: ApprovalStatus | null
}

const DecisionStatus: FC<DecisionStatusProps> = ({ approvalStatus }) => {
  const styles = useStyles()

  const getApprovalStatus = useMemo(() => {
    switch (approvalStatus) {
      case ApprovalStatus.Approved:
        return <><img src={Approved} alt='' />Approved</>
      case ApprovalStatus.Declined:
        return <><img src={Declined} alt='' />Declined</>
      case ApprovalStatus.Counteroffer:
        return <><img src={CounterOffer} alt='' />Counter offer</>
      default:
        return <>-</>
    }
  }, [approvalStatus])

  return (
    <div className={styles.status}>
      {getApprovalStatus}
    </div>
  )
}

export default DecisionStatus
