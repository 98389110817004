import type { Dispatch, SetStateAction } from 'react'
import type {
  GetShiftsPayload,
  SummaryShift,
  DictionaryItems,
  CreateShiftRequestDto,
  UpdateShiftRequestDto
} from 'api/types'
import type { Coords, Shift, CopyMonthFormData } from 'types'

export interface WorkScheduleFilters {
  date: Date
  userIds: number[]
}

export interface WorkScheduleFiltersForApi extends GetShiftsPayload {}
export interface DatePayload extends Pick<GetShiftsPayload, 'month' | 'year'> {}

export interface ShiftState {
  items: Shift[]
}

export type ArrayReducerAction<T> =
  | { type: 'SET_ITEMS', payload: T[]}
  | { type: 'ADD_ITEM', payload: T }
  | { type: 'EDIT_ITEM', payload: T }
  | { type: 'REMOVE_ITEM', payload: { id: number } }

export enum FilterButtonState {
  AllTeam = 'All team',
  SelectedMembers = 'Selected members'
}

export interface UseWorkScheduleReturn {
  date: Date
  setDate: Dispatch<SetStateAction<Date>>
  isBDC: boolean
  shifts: Shift[]
  isLoading: boolean
  shiftDetailsSubmit: (shift: UpdateShiftRequestDto | CreateShiftRequestDto) => Promise<void>
  onRemoveShift: (id: number) => Promise<void>
  onDateChange: (date: Date) => Promise<void>
  changeChipPosition: (pos: Coords) => void
  shift: Shift | null
  initialDate: Date | null
  isRemoving: boolean
  isShiftModalOpen: boolean
  onOpenShift: (shiftData: Shift | Date) => void
  onCloseShiftForm: () => void
  shiftsModalPosition: Coords | undefined
  copyMonthModalProps: {
    currentMonth: Date
    isModalOpen: boolean
    onCloseModal: () => void
    onSubmit: (formData: CopyMonthFormData) => Promise<void>
  }
  usersListFilter: {
    filters: number[]
    items: DictionaryItems<string> | null
    onChange: (userIds: number[]) => Promise<void>
  }
  showSummaryModalProps: {
    summaryShifts: SummaryShift[]
    isModalOpen: boolean
    onCloseModal: () => void
    summaryModalInitialPosition: Coords
  }
  onOpenSummaryModal: () => void
  clearMonthModalProps: {
    isModalOpen: boolean
    onCloseModal: () => void
    onCancel: () => void
    onSubmit: () => Promise<void>
  }
  onOpenClearMonthModal: () => void
  onOpenCopyMonthModal: () => void
}
