import { useNavigate as _useNavigate, type NavigateFunction } from 'react-router-dom'
import { useCallback } from 'react'

export const NAVIGATE_EVENT = 'NAVIGATE_EVENT'

const useNavigate = (): NavigateFunction => {
  const _navigate = _useNavigate()

  const navigate = useCallback((...args: Parameters<NavigateFunction>) => {
    window.dispatchEvent(new CustomEvent(NAVIGATE_EVENT, { detail: args }))
    _navigate(...args)
  }, [_navigate])

  return navigate as NavigateFunction
}

export default useNavigate
