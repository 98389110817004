import { useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import omit from 'lodash-es/omit'
import { useModal, useRefUpdater } from '@carfluent/common'

import type {
  LeadListItem,
  LeadListCounters,
  LeadState,
  LeadListPayload,
  ListResponse
} from 'api/types'
import { getLeadsListTotalDebounced, getLeadsListDebounced } from 'api/helpers'
import Routes from 'constants/route_helper'
import { calcTableHeight } from 'utils/math'
import { API_CALL_DELAY, PAGE_SIZE, GET_TABLE_MIN_HEIGHT } from 'constants/constants'
import useEffectOnce from 'hooks/useEffectOnce'
import useTableApi from 'hooks/useTableApi'
import type { FCHook } from 'types'
import CRMApiProvider from 'api/crm.api'

import type {
  RowId,
  Filters,
  LeadRow,
  UseLeadsListReturn,
  UseLeadsList,
  LoadCountersDebounced,
  LoadListDebounced
} from './types'
import LeadsListDataCTX from './store'
import { serializeCounters, serializeFilters } from './serializer'
import {
  FiltersKeys,
  Errors,
  DEFAULT_COUNTERS,
  DEFAULT_FILTERS,
  DEFAULT_SORTING,
  CANCEL_LOAD_DATA,
  CANCEL_LOAD_COUNTERS
} from './constants'
import getColumnsDefinition from './columns'
import parseCountersData from './parser'
import useWSUpdates from './useWSUpdates'

const EMPTY_STATE_TEXT = 'No leads found'
const TABLE_MIN_HEIGHT = GET_TABLE_MIN_HEIGHT(PAGE_SIZE, 64, 8)

const loadCountersDebounced: LoadCountersDebounced = async (payload, cancelationOptions) =>
  await getLeadsListTotalDebounced(payload, cancelationOptions)

const loadListDebounced: LoadListDebounced = async (payload, cancelationOptions) =>
  await getLeadsListDebounced(payload, cancelationOptions)

const useLeadsList: FCHook<UseLeadsList, UseLeadsListReturn> = ({ labelPopoverCls }) => {
  const [counters, setCounters] = useState<LeadListCounters>(DEFAULT_COUNTERS)
  const [isFiltersChange, setFiltersChange] = useState(false)
  const [isScrolling, setScrolling] = useState(false)
  /**
   * For web sockets updates we need to hide loader and skeleton
   */
  const [isLoadingInvisible, setLoadingInvisible] = useState(false)

  const {
    temperatures,
    sources,
    statuses,
    assignedTo,
    setAssignedTo,
    setStatuses
  } = useContext(LeadsListDataCTX)

  const getLeadsList = useCallback(async (payload: LeadListPayload): Promise<ListResponse<LeadListItem>> => {
    try {
      setFiltersChange(true)

      const [countersResp, listResp] = await Promise.all([
        loadCountersDebounced(omit(payload, 'leadState', 'sortField', 'sortOrder', 'skip', 'take'), CANCEL_LOAD_COUNTERS),
        loadListDebounced(payload, CANCEL_LOAD_DATA)
      ])

      const parsedData = parseCountersData(countersResp)
      setCounters(parsedData)

      return { ...listResp }
    } catch (err) {
      setCounters(DEFAULT_COUNTERS)
      return { items: [] }
    } finally {
      setFiltersChange(false)
      setLoadingInvisible(false)
    }
  }, [])

  const {
    rows,
    search,
    sorting,
    filters,
    isLoading,
    loadRows,
    getRows,
    setRows,
    setFilters,
    setSorting,
    setSearch,
    startLoader,
    stopLoader,
    onSearch
  } = useTableApi<LeadListItem, Filters>({
    getList: getLeadsList,
    serializeFilters,
    cancelationOptions: CANCEL_LOAD_DATA,
    defaultFilters: DEFAULT_FILTERS,
    defaultSorting: DEFAULT_SORTING,
    apiCallDelaySearch: API_CALL_DELAY
  })

  const navigate = useNavigate()

  const {
    isModalOpen,
    onOpenModal: _onOpenLeadDetails,
    onCloseModal: onCloseLeadDetails
  } = useModal()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const loadAssignedToMe = useCallback(async () => {
    try {
      const { items } = await CRMApiProvider.getLeadAssignedTo()

      setAssignedTo(items)
    } catch {
      console.error(Errors.AssignedTo)
    }
  }, [setAssignedTo])

  const loadStatuses = useCallback(async () => {
    try {
      const { items } = await CRMApiProvider.getLeadStatuses()

      setStatuses(items)
    } catch {
      console.error(Errors.Statuses)
    }
  }, [setStatuses])

  const loadCounters = useCallback(async () => {
    try {
      setFiltersChange(true)

      const payload = serializeCounters({ ...filters, search })
      const counters = await loadCountersDebounced(payload, CANCEL_LOAD_COUNTERS)
      const parsedData = parseCountersData(counters)

      setCounters(parsedData)
    } catch {
      setCounters(DEFAULT_COUNTERS)
      console.error(Errors.Counters)
    } finally {
      setFiltersChange(false)
      setLoadingInvisible(false)
    }
  }, [search, filters])

  const onSelectTab = useCallback(async (filterId: LeadState | null) => {
    if (filterId != null) {
      await setFilters({ tab: filterId, search })
    }
  }, [search, setFilters])

  const onTableFiltersChange = useCallback((column: FiltersKeys) => async (ids: number[]) => {
    await setFilters({ [column]: ids })
  }, [setFilters])

  const onOpenLeadDetails = useCallback((row?: LeadRow) => {
    const leadId = row?.original.id ?? null

    if (leadId != null) {
      navigate(Routes.LeadView(leadId))
    } else {
      _onOpenLeadDetails()
    }
  }, [_onOpenLeadDetails, navigate])

  const onCreateLead = useCallback((leadId: string | number): void => {
    navigate(Routes.LeadView(leadId), { state: { isLeadFreshlyCreated: true } })
  }, [navigate])

  const refLoadRows = useRefUpdater(loadRows)
  const onBottomReached = useCallback(async (): Promise<void> => {
    if (!isLoading && (TABLE_MIN_HEIGHT <= calcTableHeight(getRows().length))) {
      setScrolling(true)
      await refLoadRows.current({ forceRefresh: false })
      setScrolling(false)
    }
  }, [isLoading, loadRows, getRows])

  /**
   * Updates Leads table by WebSocket.
   * For create and update events.
   */
  const onLeadUpdated = useCallback(async () => {
    setLoadingInvisible(true)
    const take = rows.length < PAGE_SIZE ? PAGE_SIZE : rows.length
    await loadRows({ forceRefresh: true, pageSize: take })
  }, [rows.length, loadRows])

  const onLeadDeleted = useCallback((leadId: RowId) => {
    setLoadingInvisible(true)
    const nextRows = rows.filter(({ id }) => leadId !== id)

    setRows(nextRows)
    void loadCounters()
  }, [rows, loadCounters, setRows])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Initial data loading
   */
  useEffectOnce(() => {
    const fetchDataForFilters = async (): Promise<void> => {
      try {
        startLoader()

        await Promise.all([
          loadRows(),
          loadAssignedToMe(),
          loadStatuses()
        ])
      } finally {
        stopLoader()
      }
    }

    void fetchDataForFilters()
  }, [
    loadCounters,
    loadAssignedToMe,
    loadStatuses,
    startLoader,
    stopLoader,
    loadRows
  ])

  /**
   * Updates Leads table by WebSocket events.
   */
  useWSUpdates({
    onLeadDeleted,
    onLeadUpdated
  })

  const { tab: tabFilter, ..._filters } = filters

  const columns = useMemo(() => getColumnsDefinition({
    temperatures,
    sources,
    assignedTo,
    statuses,
    onChange: onTableFiltersChange,
    filters: _filters,
    labelPopoverCls
  }), [
    labelPopoverCls,
    temperatures,
    sources,
    assignedTo,
    statuses,
    onTableFiltersChange,
    _filters
  ])

  // ========================================== //

  const parsedCounters = useMemo(() => parseCountersData(counters), [counters])

  return {
    columns,
    rows,
    counters: parsedCounters,
    search,
    sorting,
    tabFilter,
    emptyState: EMPTY_STATE_TEXT,
    isModalOpen,
    isFiltersChange: isFiltersChange && !isScrolling && !isLoadingInvisible,
    isLoading: isLoading && !isLoadingInvisible,
    onBottomReached,
    onCreateLead,
    onCloseLeadDetails,
    onOpenLeadDetails,
    onSelectTab,
    setSearch,
    setSorting,
    onSearch
  }
}

export default useLeadsList
