import { SnippetTypeIds, SnippetTypeNames } from 'api/types'
import { type MessageSnippetDto } from 'api/types/responses'

interface GroupedSnippets {
  carDetails: MessageSnippetDto[]
  leadDetails: MessageSnippetDto[]
  dealershipDetails: MessageSnippetDto[]
}

let uniqId = 0
const getUniqId = (): number => --uniqId

interface GetEmptySnippetProps {
  name: string
  snippetTypeId: number
}

const getEmptySnippet = ({
  name,
  snippetTypeId
}: GetEmptySnippetProps): MessageSnippetDto => {
  return {
    order: 0,
    id: getUniqId(),
    name,
    placeholder: '',
    snippetTypeId
  }
}

interface ConcatSnippetsProps {
  snippets: MessageSnippetDto[]
  snippetTypeId: SnippetTypeIds
  name: SnippetTypeNames
}

const concatSnippets = ({
  snippets,
  snippetTypeId,
  name
}: ConcatSnippetsProps): MessageSnippetDto[] => {
  return snippets.length > 0
    ? [
        getEmptySnippet({
          snippetTypeId,
          name
        }),
        ...snippets
      ]
    : []
}

export const configurateSnippets = (snippets: MessageSnippetDto[]): MessageSnippetDto[] => {
  snippets.sort((a, b) => {
    return a.order - b.order
  })

  const {
    carDetails,
    leadDetails,
    dealershipDetails
  } = snippets.reduce<GroupedSnippets>((res, item) => {
    switch (item.snippetTypeId) {
      case SnippetTypeIds.CarDetails:
        res.carDetails.push(item)
        break
      case SnippetTypeIds.LeadDetails:
        res.leadDetails.push(item)
        break
      case SnippetTypeIds.DealershipDetails:
        res.dealershipDetails.push(item)
    }

    return res
  }, {
    carDetails: [],
    leadDetails: [],
    dealershipDetails: []
  })

  return [
    ...concatSnippets({
      snippets: carDetails,
      snippetTypeId: SnippetTypeIds.CarDetails,
      name: SnippetTypeNames.CarDetails
    }),
    ...concatSnippets({
      snippets: leadDetails,
      snippetTypeId: SnippetTypeIds.LeadDetails,
      name: SnippetTypeNames.LeadDetails
    }),
    ...concatSnippets({
      snippets: dealershipDetails,
      snippetTypeId: SnippetTypeIds.DealershipDetails,
      name: SnippetTypeNames.DealershipDetails
    })
  ]
}
