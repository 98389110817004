import type { FC } from 'react'
import type { IconProps } from 'types'

const CallIcon: FC<IconProps> = ({ color = '#21212180', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_86546_15929)'>
        <path d='M8.58474 3.36684C10.7047 3.6335 12.3647 5.2935 12.6314 7.4135C12.6714 7.7535 12.9581 8.00017 13.2914 8.00017C13.3181 8.00017 13.3447 8.00017 13.3714 7.9935C13.7381 7.94684 13.9981 7.6135 13.9514 7.24684C13.6114 4.52017 11.4714 2.38017 8.74474 2.04017C8.37807 2.00017 8.04474 2.26017 8.00474 2.62684C7.95807 2.9935 8.21807 3.32684 8.58474 3.36684ZM8.83807 4.7735C8.48474 4.68017 8.11807 4.8935 8.02474 5.2535C7.93141 5.6135 8.14474 5.9735 8.50474 6.06684C9.20474 6.24684 9.75141 6.7935 9.93807 7.50017C10.0181 7.80017 10.2847 8.00017 10.5847 8.00017C10.6381 8.00017 10.6981 7.9935 10.7514 7.98017C11.1047 7.88684 11.3181 7.52017 11.2314 7.16684C10.9181 5.98684 10.0047 5.0735 8.83807 4.7735ZM12.8181 10.1735L11.1247 9.98017C10.7181 9.9335 10.3181 10.0735 10.0314 10.3602L8.80474 11.5868C6.91807 10.6268 5.37141 9.08684 4.41141 7.1935L5.64474 5.96017C5.93141 5.6735 6.07141 5.2735 6.02474 4.86684L5.83141 3.18684C5.75141 2.5135 5.18474 2.00684 4.50474 2.00684H3.35141C2.59807 2.00684 1.97141 2.6335 2.01807 3.38684C2.37141 9.08017 6.92474 13.6268 12.6114 13.9802C13.3647 14.0268 13.9914 13.4002 13.9914 12.6468V11.4935C13.9981 10.8202 13.4914 10.2535 12.8181 10.1735Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_86546_15929'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CallIcon
