import { css } from '@emotion/css'

export default css(`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
  "avatar name"
  "avatar total";
  grid-column-gap: 8px;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;

  .cf-shift-avatar {
    grid-area: avatar;
    display: flex;

    > div {
      max-width: 32px;
      width: 32px;
      height: 32px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }
  
  .cf-shift-name {
    grid-area: name;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #101010;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .cf-shift-total {
    grid-area: total;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(33, 33, 33, 0.5);
    white-space: nowrap;
  }
`)
