import { css } from '@emotion/css'

export default css`
  left: 0;
  display: flex;
  position: sticky;
  
  .group-name {
    color: #101010;
    font-weight: 500;
  }

  .text-counter {
    opacity: 0.5;
    color: #101010;
    margin-left: 3px;
  }
`
