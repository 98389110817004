import type { FormPacksFormData } from './types'

export const GET_DEFAULT_CREATE_NEW_FORM_PACK = (): FormPacksFormData => ({
  id: null,
  name: '',
  description: ''
})

export enum Messages {
  SuccessMessage = 'Form pack created successfully.',
}
