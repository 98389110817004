import { css } from '@emotion/css'

export default css(`  
  && {
    .cf-text-line {
      width: 100%;
      min-height: 24px;
    }

    p {
      font-family: Roboto;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`)
