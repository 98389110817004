import type { FC } from 'react'
import { Button } from '@carfluent/common'
import { DragDropContext } from 'react-beautiful-dnd'

import Icon from 'components/common/Icon'
import DroppableTable from './components/DroppableTable'
import ReconStepsTableHeader from './components/Header'
import LoadingRow from './components/LoadingRow'
import type { ReconStepsTableProps } from './types'

import CLASS_NAME from './styles'

const ReconStepsTable: FC<ReconStepsTableProps> = ({
  isLoading,
  values,
  onAddReconStep,
  onDragEnd,
  onOpenDeleteStepModal,
  ...formProps
}) => {
  return (
    <div className={CLASS_NAME}>
      <ReconStepsTableHeader />

      <Button
        variant='text'
        dataTestId='btn-add-recon-step'
        startAdornment={<Icon name='plus' />}
        className='cf-btn-add-recon-step'
        onClick={onAddReconStep}
      >
        Add recon step
      </Button>

      <DragDropContext onDragEnd={onDragEnd}>
        <DroppableTable
          values={values}
          onOpenDeleteStepModal={onOpenDeleteStepModal}
          {...formProps}
        />
      </DragDropContext>

      <div>
        {isLoading && Array(5).fill({}).map((_, index) => (<LoadingRow key={index} />))}
      </div>
    </div>
  )
}

export default ReconStepsTable
