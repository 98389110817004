import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  
  .filter-chip {
    border-radius: 4px;
    padding: 0px 4px;
    color: #101010;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  
  .chip-included {
    background: #E6F3E6;
    color: #0E4C29;
  }
  
  .chip-excluded {
    background: #FFF1F4;
    color: #101010;
    text-decoration-line: line-through;
  }
`)
