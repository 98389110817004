import { useState, useEffect, useMemo } from 'react'
import {
  type SortingInfo,
  type Rows,
  type Groups,
  PRESET_LABELS
} from '@carfluent/common'
import pDebounce from 'p-debounce'

import type {
  DateType,
  DictionaryItems,
  ReportLeadBySourceItem,
  ReportLeadsBySourceDto
} from 'api/types'
import CRMApiProvider from 'api/crm.api'
import { DEFAULT_PRESETS } from 'components/reports/ReportLayout/utils'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { downloadBlob } from 'utils/general'

import { getAmount, groupByCategory } from './utils'
import { type SerializePayloadProps, serializePayload } from './serializer'

interface UseReportLeadBySourceReturn {
  onExportExcel: () => Promise<void>
  onChangeDatesFilter: (period: DateType) => Promise<void>
  sources: DictionaryItems<string>
  leadSources: DictionaryItems<string>
  setLeadSources: (sources: DictionaryItems<string>) => void
  sorting?: SortingInfo
  setSorting: (sorting: SortingInfo) => void
  rows: ReportLeadBySourceItem[]
  isLoading: boolean
  reportInfo: Omit<ReportLeadsBySourceDto, 'items'> | null
  groupBy?: (rows: Rows<ReportLeadBySourceItem>) => Groups<ReportLeadBySourceItem, string>
}

const REQUEST_DELAY = 1000
const THIS_MONTH_PRESET = DEFAULT_PRESETS.find(p => p.name === PRESET_LABELS.THIS_MONTH)
const DEFAULT_DATE_RANGE = {
  from: THIS_MONTH_PRESET?.startDate ?? null,
  to: THIS_MONTH_PRESET?.endDate ?? null
}

export const useReportLeadBySource = (): UseReportLeadBySourceReturn => {
  const [sources, setSources] = useState<DictionaryItems<string>>([])
  const [dateRange, setDateRange] = useState<DateType>(DEFAULT_DATE_RANGE)
  const [sorting, setSorting] = useState<SortingInfo | undefined>(undefined)
  const [leadSources, setLeadSources] = useState<DictionaryItems<string>>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isStatusLoading, setIsStatusLoading] = useState(true)
  const [rows, setRows] = useState<ReportLeadBySourceItem[]>([])
  const [reportInfo, setReportInfo] = useState<Omit<ReportLeadsBySourceDto, 'items'> | null>(null)
  const [categoryAmount, setCategoryAmount] = useState<Record<string, number>>({})
  const { showAlert } = useCustomSnackbar()

  const onExportExcel = async (): Promise<void> => {
    try {
      if (reportInfo != null) {
        const serializedPayload = serializePayload({
          sorting,
          leadSources,
          dateRange
        })

        const response = await CRMApiProvider.downloadLeadsByVehicleSourceXlsx({
          ...reportInfo,
          items: rows,
          dateRange: serializedPayload.dateRange,
          leadSources: serializedPayload.leadSources
        })

        downloadBlob(response, 'ReportVehicleBySource.xlsx')
      }
    } catch (e) {
      showAlert(e)
    }
  }

  const onChangeDatesFilter = async (period: DateType): Promise<void> => {
    setDateRange(period)
  }

  const loadRows = useMemo(() => pDebounce(async (props: SerializePayloadProps) => {
    try {
      setIsLoading(true)
      const { items, ...otherProps } = await CRMApiProvider.getLeadBySourceReport(serializePayload(props))
      setCategoryAmount(getAmount(items))
      setRows(items)
      setReportInfo(otherProps)
    } catch (e) {
      showAlert(e)
    } finally {
      setIsLoading(false)
    }
  }, REQUEST_DELAY), [showAlert])

  const groupBy = useMemo(() => {
    return sorting == null ? groupByCategory(categoryAmount) : undefined
  }, [categoryAmount, sorting])

  useEffect(() => {
    if (isStatusLoading) {
      return
    }

    void loadRows({ sorting, leadSources, dateRange })
  }, [
    sorting,
    leadSources,
    dateRange,
    isStatusLoading,
    loadRows
  ])

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      try {
        setIsStatusLoading(true)
        const { items } = await CRMApiProvider.getLeadSources()
        setSources(items)
        setLeadSources(items)
      } catch (e) {
        showAlert(e)
      } finally {
        setIsStatusLoading(false)
      }
    }
    void runEffect()
  }, [showAlert])

  return {
    onExportExcel,
    onChangeDatesFilter,
    sources,
    leadSources,
    setLeadSources,
    sorting,
    setSorting,
    rows,
    isLoading,
    reportInfo,
    groupBy
  }
}
