import { css } from '@emotion/css'

export default css(`
  .top-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--content-margin-default);
    padding: 16px;
    background: #FFFFFF;
    border-radius: 12px;
    position: relative;
  }
  
  .g-filters-panel-actions button {
    width: auto;
  }

  .actions-menu {
    min-width: 159px;

    > .cf-menu-button {
      min-height: 56px;
    }
  }

  .search-and-filters-block {
    display: flex;
    align-items: center;
  }

  .button {
    width: 210px;
    margin: 16px;
    border-radius: 12px;

    > button {
      height: 56px;
    }
  }

  .search-block {
    min-width: 420px;
    max-width: 420px;
    width: 100%;

    .MuiInputBase-root {
      background: #F3F3F3;
      border-radius: 12px;
      box-shadow: none;
      height: 56px;
    }
  }

  .cf-no-data-block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    background: #FFF;
    margin-top: 8px;
    border-radius: 2px;
  }


  .cf-table-header {
    tr > th:last-child > div { justify-content: flex-end; }
  
    .cf-table-header-title {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      white-space: nowrap;

      p { font-weight: 700; }
    }
  }
  
  .cf-table-row {
    height: 64px;

    .cf-table-cell {
      :first-child { background: #F3F3F3; }

      :last-child > div {
        display: flex;
        justify-content: flex-end;
        padding-right: 16px;
      }
    }
  }

  .cf-table-cell {
    :nth-child(6) {
      max-width: 210px;
      min-width: 145px;
    }
    :nth-child(7) {
      max-width: 180px;
      min-width: 145px;
    }
  }
  
  .cf-table-cell {
    height: 1px;

    .cf-cell-wrapper {
      height: 100%;
      padding: 20px 8px;
      min-height: 64px;
      max-height: 64px;
      box-sizing: border-box;

      .cf-cell-wrapper > p, p {
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .cf-cell-wrapper.cf-multiple-items {
      padding: 8px;
      height: 100%;
    }
    
    .cf-control-identifier-badge {
      .content {
        min-width: 180px;
      }
    }
  }
`)
