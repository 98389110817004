import type { FormValidation } from '@carfluent/common'
import { requiredField, dateField, vinField, vehicleYearField, buildPreset } from 'utils/validationPresets'
import { Messages, type AddNewVehicleFormData } from './types'
import { isFalsy } from 'utils/general'
import { ruleRequired } from 'utils/validationRules'

const vendorRule = buildPreset([
  ruleRequired(Messages.Required)
])

const validationRules: FormValidation<AddNewVehicleFormData> = {
  inventoryDate: dateField('append'),
  make: requiredField,
  model: requiredField,
  trim: requiredField,
  vin: vinField(true),
  year: vehicleYearField(true),
  stock: requiredField,
  vendor: (val, values) => {
    // Validate `vendor` field only in case the purchase price field is edited
    if (isFalsy(values?.purchasePrice)) {
      return null
    }

    return vendorRule()(val, values)
  }
}

export default validationRules
