import { css } from '@emotion/css'

export default css(`
  margin-top: -32px;
  padding: 0 16px 16px 16px;

  .radios-title-error {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    .error-text {
      color: #B00020;
      font-size: 12px;
      letter-spacing: 0.4px;
    }
  }

  .form-subtitle {
    margin: 16px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #101010;
  }

  .showed-up-on-title {
    margin: 24px 0 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #101010;
  }

  .checkbox-group {
    display: grid;
    margin-top: 24px;
    row-gap: 24px;
    grid-template-columns: 1fr 1fr;

    > div {
      height: 24px;

      label {
        max-width: fit-content;
        margin-left: -9px;
        height: 24px;
      }
    }

    &.no-radio-btn-option {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
    }
  }

  .column { grid-template-columns: 1fr; }

  .radio-group {
    height: 24px;
    display: flex;
    align-content: center;

    .radio-item {
      max-width: fit-content;
      padding: 0;
      margin: 0px 32px 0px -9px;
      height: 24px;
    }
  }
  
  .notes-field-margin { margin-top: 16px; }

  .complete-task-form {
    display: grid;
    grid-template-areas:
    'date time'
    'notes notes';
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 24px;

    .due-date {
      grid-area: date;  
      width: 100%;
    }

    .notes-field { grid-area: notes; }

    .lead-tasks-action-buttons {
      grid-area: buttons;
      line-height: 22px;
      width: max-content;
      border: 1px solid #C99B86;
      height: 26px;
      margin-top: 16px;
      color: #000;

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
      }
    }

    .prefilled-value .MuiInputBase-root[class*="MuiFilledInput-root"] {
      box-sizing: border-box;
      background-color: #FAFAFA;
      border: 1px solid transparent;
  
      :hover { border: 1px solid #C99B86; }
    }
  }

  .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-endAdornment {
    button {
      padding: 8px 12px;
      margin-right: 8px;
      width: 12px;
    }
  }
`)
