import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Loader, cnx } from '@carfluent/common'

import ProgressBarCircle, { type ProgressBarCircleProps } from 'components/common/ProgressBarCircle'
import TooltipedText from 'components/common/TooltipedText'
import IconSVG from 'components/inlineImages'

import LoaderSkeleton from './components/LoaderSkeleton'

import CLASS_NAME from './styles'

export interface InspectionGeneralBlockProps extends ProgressBarCircleProps {
  to?: string
  passedCount: number
  failedCount: number
  commentsCount: number
  statusInfo: string
  isLoading?: boolean
}

const InspectionGeneralBlock: FC<InspectionGeneralBlockProps> = ({
  to,
  statusInfo,
  passedCount,
  failedCount,
  commentsCount,
  progressPercentage,
  isCompleted,
  isLoading = false,
  isUpdating
}) => {
  const navigate = useNavigate()

  if (isLoading) {
    return <LoaderSkeleton />
  }

  const onClick = (): void => {
    if (to == null) {
      return
    }

    navigate(to)
  }

  return (
    <div onClick={onClick} className={cnx(CLASS_NAME, to != null && 'pointer')}>
      <div className='block-info'>
        <div className='block-info-titles'>
          <p className='block-info-header'>Intake Inspection </p>

          <TooltipedText
            value={(
              <span className='block-info-status'>
                {statusInfo}
              </span>
            )}
          />
        </div>

        {
          isUpdating === true &&
            <Loader size='medium' color='dark' />
        }
        <ProgressBarCircle
          isUpdating={isUpdating}
          progressPercentage={progressPercentage}
          isCompleted={isCompleted}
        />
      </div>

      <div className='block-statistics'>
        <div>
          <IconSVG.ThumbUp />

          {
            isUpdating === true
              ? (
                <div className='loader-container'>
                  <Loader size='medium' color='dark' />
                </div>
                )
              : (
                <span className='block-statistic-count'>
                  {passedCount}
                </span>
                )
          }

        </div>

        <div>
          <IconSVG.Fail />

          {
            isUpdating === true
              ? (
                <div className='loader-container'>
                  <Loader size='medium' color='dark' />
                </div>
                )
              : (
                <span className='block-statistic-count'>
                  {failedCount}
                </span>
                )
          }
        </div>

        <div>
          <IconSVG.Comment />
          {
            isUpdating === true
              ? (
                <div className='loader-container'>
                  <Loader size='medium' color='dark' />
                </div>
                )
              : (
                <span className='block-statistic-count'>{commentsCount}</span>
                )
          }
        </div>
      </div>
    </div>
  )
}

export default InspectionGeneralBlock
