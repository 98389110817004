import { type FC } from 'react'
import { Link } from 'react-router-dom'
import { FormInput, Button } from '@carfluent/common'
import { observer } from 'mobx-react-lite'

import AuthLayout from 'components/layout/AuthLayout'

import useLogin from './hook'
import CLASS_NAME from './styles'

const Login: FC = () => {
  const {
    isSubmitting,
    values,
    errors,
    touched,
    onSubmit,
    onChange,
    onBlur,
    apiError,
    showPassword,
    toggleShowHidePassword
  } = useLogin()

  const passwordError = (errors.password ?? apiError) ?? false

  return (
    <AuthLayout>
      <div className={CLASS_NAME}>
        <p
          className='title-wrapper'
          data-test-id='header-text-login'
        >
          Log in
        </p>

        <FormInput
          data-test-id='field-user-name'
          id='username'
          label='Email'
          onChange={onChange}
          onBlur={onBlur}
          value={values.username}
          error={errors.username}
          touched={touched.username}
        />

        <FormInput
          id='password'
          label='Password'
          type={showPassword ? 'text' : 'password'}
          onChange={onChange}
          onBlur={onBlur}
          value={values.password}
          touched={touched.password}
          error={passwordError}
          endAdornment={(
            <span
              className='show-hide'
              onClick={toggleShowHidePassword}
            >
              {showPassword ? 'Hide' : 'Show'}
            </span>
          )}
        />

        <p
          className='helper'
          data-test-id='body-text-login'
        >
          Forgot password?
          <Link to='/reset'>
            <span className='link-reset' data-test-id='url-reset'>
              Reset
            </span>
          </Link>
        </p>

        <Button
          className='submit-btn'
          dataTestId='btn-login'
          isLoading={isSubmitting}
          onClick={() => { void onSubmit() }}
        >
          Log in
        </Button>
      </div>
    </AuthLayout>
  )
}

export default observer(Login)
