import { type FC } from 'react'
import { cn } from '@carfluent/common'

import ActionsMenu from 'components/common/ActionsMenu'
import BackToTop from 'components/common/BackToTop'
import FiltersPanel from 'components/common/FiltersPanel'
import TransactionDialogController from 'components/accounting/TransactionDialogController'
import Table from 'components/common/Table'

import useRecurringEntriesList from './hook'
import CLASS_NAME from './styles'

const RecurringEntriesList: FC = () => {
  const {
    columns,
    emptyTableMessage,
    isLoading,
    onClickRecurringEntryRow,
    onSearchChange,
    onSortingChange,
    rows,
    search,
    sorting,
    topPanelActions,
    transactionDialogControllerProps,
    onSearch
  } = useRecurringEntriesList()

  return (
    <div className={cn('g-submenu-content', CLASS_NAME)}>
      <FiltersPanel
        searchProps={{
          isLoading,
          onChange: onSearchChange,
          value: search,
          placeholder: 'Search by description',
          withDeleteIcon: true,
          onSearch
        }}
        renderActions={() => (
          <ActionsMenu
            actions={topPanelActions}
            buttonTitle='NEW ENTRY'
          />
        )}
      />

      <Table
        columns={columns}
        data={rows}
        emptyTableMessage={emptyTableMessage}
        isLoading={isLoading}
        onRowClick={onClickRecurringEntryRow}
        onSortingChange={onSortingChange}
        sorting={sorting}
      />

      <TransactionDialogController {...transactionDialogControllerProps} />

      <BackToTop />
    </div>
  )
}

export default RecurringEntriesList
