import { useCallback, useContext, useEffect, useState } from 'react'
import { UI, useModal } from '@carfluent/common'

import type { BaseListItem } from 'types'
import type { AccountingFiscalYearsResponse, AccountingUpdateLockStateForMonthPayload } from 'api/types'
import AccountingApiProvider from 'api/accounting.api'
import SettingsCTX from 'store/settings'
import { downloadBlob } from 'utils/general'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

import { ChartType, type SettingsGeneralProps } from '../types'
import StoreCTX from './store'
import isGeneralSettingsValid from './validate'
import useAsyncEffect from 'hooks/useAsyncEffect'
import pDebounce from 'p-debounce'

const { defaultParseData: parseErrors } = UI

export enum Alerts {
  enableSuccess = 'Accounting enabled successfully.',
  importSuccess = 'Table imported successfully.',
  lockMonth = 'Month was locked.',
  unlockMonth = 'Month was unlocked.',
  yearClosedSuccess = 'Year has been closed successfully.'
}

export interface UseAccountingSettings {
  generalSettingsTab: SettingsGeneralProps
}

const SAVE_FIELD_DELAY = 1500
const DEFAULT_SELECT_ITEM = {
  id: 0,
  year: null,
  isClosed: false,
  closedDateTime: null,
  fiscalYearMonths: []
}

const useAccountingSettings = (): UseAccountingSettings => {
  const { showAlert } = useCustomSnackbar()
  const [fiscalYearStartMonths, setFiscalYearStartMonths] = useState<BaseListItem[]>([])
  const [fiscalYearList, setFiscalYearList] = useState<AccountingFiscalYearsResponse[]>([])
  const [activeItem, setActiveItem] = useState<AccountingFiscalYearsResponse>(DEFAULT_SELECT_ITEM)
  const [isLoading, setIsLoading] = useState<boolean | null>(null)

  const {
    isModalOpen: isWarningsModalOpen,
    onCloseModal: onCloseWarningsModal,
    onOpenModal: onOpenWarningsModal
  } = useModal()

  const confirmModal = useModal()

  const {
    accounting, isAccountingEnabled, setAccountingRaw
  } = useContext(SettingsCTX)

  const {
    generalSettings,
    getPrintSettings,
    enableAccountingError,
    setGeneralSettings,
    setEnableAccountingError,
    updateGeneralSettings,
    setWarnings,
    warnings
  } = useContext(StoreCTX)

  // ========================================== //
  //                  HANDLERS                  //
  // ========================================== //

  const onLoadFiscalYearStartMonths = useCallback(async () => {
    const res = await AccountingApiProvider.getFiscalYearStartMonths()
    setFiscalYearStartMonths(res.items)
  }, [])

  const onLoadFiscalYear = useCallback(async () => {
    try {
      setIsLoading(true)
      const res = await AccountingApiProvider.getFiscalYears()
      setFiscalYearList(res.items)
      if (res.items.length > 0) {
        setActiveItem(res.items[0])
      }
    } catch (err) {
      const errorMsg = parseErrors(err)
      console.error('onLoadFiscalYear', errorMsg)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const onEnableAccounting = useCallback(async () => {
    const { typeOfChart, fileUrl, fiscalYearStartMonth } = generalSettings
    const isDefaultChart = typeOfChart === ChartType.Default

    if (fiscalYearStartMonth === null) {
      return
    }

    try {
      setIsLoading(true)
      setEnableAccountingError(null)

      const resp = await AccountingApiProvider.enableAccounting({
        fiscalYearStartMonth,
        useDefaultAccounts: isDefaultChart,
        uploadedAccountsFileUrl: isDefaultChart ? null : fileUrl
      })

      const settings = await AccountingApiProvider.getSettings()
      const hasWarnings = Object.keys(resp.warnings).length > 0

      setAccountingRaw(settings)
      setWarnings(resp.warnings)

      if (hasWarnings) {
        onOpenWarningsModal()
      } else {
        showAlert(
          isDefaultChart ? Alerts.enableSuccess : Alerts.importSuccess,
          { variant: 'success' }
        )
      }
    } catch (err) {
      const errorMsg = parseErrors(err)
      setEnableAccountingError(errorMsg.length > 0 ? errorMsg : null)
    } finally {
      setIsLoading(false)
    }
  }, [accounting, generalSettings, setAccountingRaw, showAlert, setWarnings])

  const onDownloadTemplate = useCallback(async () => {
    try {
      const resp = await fetch(`${process.env.PUBLIC_URL}/templates/chart_of_accounts.csv`)
      const data = await resp.blob()
      downloadBlob(data, 'template.csv')
    } catch (err) {}
  }, [])

  const onUpdateLockStatus = useCallback(async ({
    yearId, monthId, isLocked
  }: AccountingUpdateLockStateForMonthPayload) => {
    try {
      setIsLoading(true)
      setEnableAccountingError(null)

      await AccountingApiProvider.updateLockStateForMonth({ yearId, monthId, isLocked })
      await onLoadFiscalYear()

      showAlert(
        isLocked ? Alerts.lockMonth : Alerts.unlockMonth,
        { variant: 'success' }
      )
    } catch (err) {
      const errorMsg = parseErrors(err)
      setEnableAccountingError(errorMsg.length > 0 ? errorMsg : null)
    } finally {
      setIsLoading(false)
    }
  }, [onLoadFiscalYear, setEnableAccountingError, showAlert])

  const _onSaveField = useCallback(pDebounce(async () => {
    const resp = await AccountingApiProvider.patchSettings(getPrintSettings())
    setAccountingRaw(resp)
  }, SAVE_FIELD_DELAY), [getPrintSettings, setAccountingRaw])

  const onSaveField = useCallback((id: string, value: unknown) => {
    updateGeneralSettings(id, value)
    void _onSaveField()
  }, [_onSaveField, updateGeneralSettings])

  const onCloseYear = useCallback(async (yearId: number, isClosed: boolean) => {
    try {
      setIsLoading(true)
      setEnableAccountingError(null)

      await AccountingApiProvider.lockFiscalYears(yearId, isClosed)
      await onLoadFiscalYear()

      showAlert(
        Alerts.yearClosedSuccess,
        { variant: 'success' }
      )
    } catch (err) {
      const errorMsg = parseErrors(err)
      setEnableAccountingError(errorMsg.length > 0 ? errorMsg : null)
    } finally {
      setIsLoading(false)
    }
  }, [onLoadFiscalYear, setEnableAccountingError, showAlert])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useAsyncEffect(async () => {
    await onLoadFiscalYearStartMonths()
    if (isAccountingEnabled) {
      await onLoadFiscalYear()
    }
  }, [onLoadFiscalYearStartMonths, isAccountingEnabled, onLoadFiscalYear])

  useEffect(() => {
    const needToSetup = Boolean(accounting?.id) &&
      (accounting?.accountingStartDate !== generalSettings.startDate)

    if (needToSetup) {
      setGeneralSettings(accounting)
    }
  }, [accounting, generalSettings, setGeneralSettings])

  useEffect(() => {
    if (activeItem != null) {
      const updatedSelectedItem = fiscalYearList.find((el) => el.id === activeItem.id) ?? fiscalYearList[0]
      setActiveItem(updatedSelectedItem)
    }
  }, [activeItem, fiscalYearList])

  // ========================================== //

  return {
    generalSettingsTab: {
      fiscalYearStartMonths,
      fiscalYearList,
      error: enableAccountingError,
      isAccountingEnabled,
      isWarningsModalOpen,
      values: generalSettings,
      warnings,
      isLoading,
      activeItem,
      confirmModal,
      isValid: isGeneralSettingsValid(generalSettings),
      onChange: updateGeneralSettings,
      onChangeActiveItem: (item: AccountingFiscalYearsResponse) => setActiveItem(item),
      onCloseYear,
      onCloseWarningsModal,
      onDownload: onDownloadTemplate,
      onEnable: onEnableAccounting,
      onSaveField,
      onUpdateLockStatus
    }
  }
}

export default useAccountingSettings
