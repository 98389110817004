import type { FormValidation } from '@carfluent/common'
import { scheduleCompleteTaskOptionId, scheduleCompleteTaskSubOptionIds } from 'utils/validationPresets'
import type { CompleteTaskFormData } from './types'

export const completeFormValidation: FormValidation<CompleteTaskFormData> = {
  taskCompleteOptionId: scheduleCompleteTaskOptionId
}

export const completeAppointmentFormValidation: FormValidation<CompleteTaskFormData> = {
  ...completeFormValidation,
  taskCompleteSubOptionIds: scheduleCompleteTaskSubOptionIds
}
