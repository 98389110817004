import { type FC } from 'react'
import { FormDropdown, FormNumberInput } from '@carfluent/common'

import getDealSalesTaxAmountRounded from 'utils/deals/priceHelpers/getDealSalesTaxAmount'
import { MAX_CAR_PRICE_VALUE, MAX_FEE_VALUE, MAX_TERM_VALUE, MAX_PRICE_VALUE } from 'constants/validation'
import SalesTaxInput from 'components/deals/SalesTaxInput'
import { type FinancingDetailsSectionProps } from 'pages/deals/CreditApplication/types'
import { COVERAGES_NAMES } from 'pages/settings/FISettings/components/PackageCustomization/constants'
import { type CoverageProductTypeIds } from 'api/types/responses'

const Financing: FC<FinancingDetailsSectionProps> = ({
  feesAndCoveragesSettings,
  values,
  errors,
  touched,
  isFormDisabled,
  wholesaleSourceTypes,
  onChange,
  onBlur,
  onSubmitSalesTax,
  setFieldTouched
}) => {
  const wholesaleSourceType = wholesaleSourceTypes.find((item) =>
    item.id === values.applicantFinancialDetails.wholesaleSourceType) ?? null

  /**
   * AZ-TODO: this piece of sh*t will be improved in next PRs,
   * when we will add support of configurable fees for CreditApp.
   */
  const getSalesTaxAmount = (salesTax: number | null): number => getDealSalesTaxAmountRounded({
    coverages: values.coverageData.coverageDetails.map(x => ({
      productType: COVERAGES_NAMES[x.productTypeId as CoverageProductTypeIds],
      dealerRetailPrice: x.coverageType === 'PEN'
        ? x.forms?.PEN?.totalPrice ?? 0
        : x.forms.Manual.totalPrice ?? 0
    })),
    feesAndCoveragesSettings,
    salesTax,
    values: {
      dealFees: {
        ...values.dealFees,
        carDelivery: values.transportationCost ?? 0,
        dealerHandlingFee: values.documentFee ?? 0,
        others: [
          { amount: values.other1 },
          { amount: values.other2 },
          { amount: values.other3 }
        ]
      },
      tradeInDetails: {
        credit: values.tradeInDetails.tradeInCredit
      },
      vehicle: {
        listPrice: values.vehicleDetails.vehiclePrice
      }
    }
  })

  return (
    <div className='credit-app-section no-collapse financing'>
      <h3>Financing</h3>
      <FormNumberInput
        id='applicantFinancialDetails.wholesaleValue'
        label='Wholesale value'
        preset='financial'
        isNegativeAllowed={false}
        value={values.applicantFinancialDetails.wholesaleValue}
        error={errors.applicantFinancialDetails?.wholesaleValue}
        touched={touched.applicantFinancialDetails?.wholesaleValue}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
        max={MAX_CAR_PRICE_VALUE}
      />

      <FormDropdown
        disableClearable={false}
        disabled={isFormDisabled}
        id='applicantFinancialDetails.wholesaleSourceType'
        label='Wholesale source'
        value={wholesaleSourceType}
        error={errors.applicantFinancialDetails?.wholesaleSourceType}
        touched={touched.applicantFinancialDetails?.wholesaleSourceType}
        options={wholesaleSourceTypes}
        onChange={(fieldId, val) => onChange(fieldId, val?.id)}
        onBlur={onBlur}
      />

      <FormNumberInput
        id='vehicleDetails.vehiclePrice'
        label='Car price'
        preset='financial'
        isNegativeAllowed={false}
        value={values.vehicleDetails.vehiclePrice}
        error={errors.vehicleDetails?.vehiclePrice}
        touched={touched.vehicleDetails?.vehiclePrice}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
        max={MAX_CAR_PRICE_VALUE}
      />

      <SalesTaxInput
        disabled={isFormDisabled}
        error={errors.overridenSalesTaxAmount}
        getSalesTaxAmount={getSalesTaxAmount}
        isManualSalesTax={values.isManualSalesTax}
        label='Sales tax amount'
        onToucheField={(id: string) => setFieldTouched(id, true)}
        onChange={onChange}
        onSubmitSalesTax={onSubmitSalesTax}
        salesTax={values.overridenSalesTaxPercent}
        salesTaxAmountId='overridenSalesTaxAmount'
        salesTaxId='overridenSalesTaxPercent'
        touched={touched.overridenSalesTaxAmount}
        value={values.overridenSalesTaxAmount}
      />

      <FormNumberInput
        id='registrationTax'
        label='Registration'
        preset='financial'
        isNegativeAllowed={false}
        value={values.registrationTax}
        error={errors.registrationTax}
        touched={touched.registrationTax}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
        max={MAX_FEE_VALUE}
      />

      <FormNumberInput
        id='documentFee'
        label='Document fees'
        preset='financial'
        isNegativeAllowed={false}
        value={values.documentFee}
        error={errors.documentFee}
        touched={touched.documentFee}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
        max={MAX_FEE_VALUE}
      />

      <FormNumberInput
        id='transportationCost'
        label='Transportation'
        preset='financial'
        isNegativeAllowed={false}
        value={values.transportationCost}
        error={errors.transportationCost}
        touched={touched.transportationCost}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
        max={MAX_PRICE_VALUE}
      />

      <FormNumberInput
        id='financingCalculatorDetails.downPayment'
        label='Down payment'
        preset='financial'
        isNegativeAllowed={false}
        value={values.financingCalculatorDetails.downPayment}
        error={errors.financingCalculatorDetails?.downPayment}
        touched={touched.financingCalculatorDetails?.downPayment}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
        max={MAX_PRICE_VALUE}
      />

      <FormNumberInput
        id='financingCalculatorDetails.term'
        label='Terms'
        endAdornment='months'
        isNegativeAllowed={false}
        value={values.financingCalculatorDetails.term}
        error={errors.financingCalculatorDetails?.term}
        touched={touched.financingCalculatorDetails?.term}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
        max={MAX_TERM_VALUE}
      />
    </div>
  )
}

export default Financing
