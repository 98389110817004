import {
  HubConnectionBuilder,
  HttpTransportType,
  LogLevel,
  HubConnectionState,
  HubConnection
} from '@microsoft/signalr'
import { Notifications } from 'constants/names'

import { getAccessToken } from 'services/storage.service'

const customerScoreHubUrl = `${process.env.REACT_APP_CUSTOMERSCORE ?? ''}/api/v1/hubs/dealer`
const crmHubUrl = `${process.env.REACT_APP_CRM ?? ''}/api/v1/hubs/admin`
const inventoryHubUrl = `${process.env.REACT_APP_INVENTORY ?? ''}/api/v1/hubs/inventory`
const reconHubUrl = `${process.env.REACT_APP_RECON ?? ''}/api/v1/hubs/recon`
const RESTART_TIMEOUT = 5000
const THROTTLE_TIMEOUT_DELAY = 15 * 1000

type CloseReason = 'planned'
type GroupId = string
type WSHandlerAction = (...args: any[]) => void

export interface BaseWS {
  shutdown: (reason?: CloseReason) => Promise<void>
  on: WsOnHandler
  off: (eventName: Notifications, handlerName?: string) => void
}

export interface WsHandler {
  name: string
  action: WSHandlerAction
}

type WsOnHandler = (eventName: Notifications, handler: WsHandler, config?: SubscriptionConfig) => void

type NotificationKey = keyof typeof Notifications
type EventSubList = Record<NotificationKey, Record<string, WSHandlerAction>>

export interface SubscriptionConfig {
  throttledGroupId?: GroupId
  throttledTimeout?: number
  accumulateEvents?: boolean
  resetTimerOnNewEvent?: boolean
}

const WebSocketEventBus = (notificationsHubUrl: string): BaseWS => {
  let closeReason: CloseReason | null = null
  let connection: HubConnection | null = null
  let connectionHandle: Promise<void> | null = null
  const throttledEventsData: Map<GroupId, { timer: NodeJS.Timeout, events: any[] }> = new Map()

  const notificationKeys = Object.keys(Notifications) as NotificationKey[]
  const defaultSubs = {} as unknown as EventSubList

  const subs: EventSubList = notificationKeys
    .reduce((acc, curr) => {
      acc[curr] = {}
      return acc
    }, defaultSubs)

  const startWSConnection = async (): Promise<void> => {
    await init()

    try {
      if (connection?.state === HubConnectionState.Disconnected) {
        await connection?.start()
        closeReason = null
      }
    } catch (err) {
      setTimeout(() => {
        void startWSConnection()
      }, RESTART_TIMEOUT)
    }
  }

  const shutDownWSConnection = async (reason: CloseReason = 'planned'): Promise<void> => {
    closeReason = reason
    unsubFromAll()
    await connection?.stop()
    connectionHandle = null
  }

  const on: WsOnHandler = (eventName: Notifications, handler: WsHandler, config?: SubscriptionConfig) => {
    const runOn = async (): Promise<void> => {
      if (connectionHandle == null) {
        connectionHandle = startWSConnection()
      }

      await connectionHandle

      if (connection == null) {
        return
      }

      if (subs[eventName][handler.name] == null) {
        const eventHandler = (config?.throttledGroupId != null)
          ? createThrottledHandler(handler.action, config)
          : handler.action

        connection?.on(eventName, eventHandler)
        subs[eventName][handler.name] = eventHandler
      }
    }

    void runOn()
  }

  /**
   * Removes one or all listener from the specified event
   * @param eventName Event name from which we're going to unsubscribe one or all listeners
   * @param handlerName Handler name to unsubscribe from the event
   */
  const off = (eventName: NotificationKey, handlerName?: string): void => {
    if (handlerName != null) {
      if (subs[eventName][handlerName] != null) {
        const { [handlerName]: action, ...otherHandlers } = subs[eventName]
        connection?.off(eventName, action)
        subs[eventName] = otherHandlers
      }
    } else {
      connection?.off(eventName)
      subs[eventName] = {}
    }
  }

  const unsubFromAll = (): void => {
    for (const key of notificationKeys) {
      off(key)
    }
  }

  const init = async (): Promise<void> => {
    connection = await new HubConnectionBuilder()
      .withUrl(notificationsHubUrl, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: getAccessToken
      })
      .configureLogging(LogLevel.Information)
      .build()

    connection.onclose(() => {
      if (closeReason !== 'planned') {
        void startWSConnection()
      }
    })
  }

  const createThrottledHandler = (action: WSHandlerAction, {
    throttledGroupId = '',
    throttledTimeout = THROTTLE_TIMEOUT_DELAY,
    accumulateEvents = false,
    resetTimerOnNewEvent = false
  }: SubscriptionConfig): WSHandlerAction => {
    const executeAction = (data: { timer: NodeJS.Timeout, events: any[] }): void => {
      if (accumulateEvents) {
        action(data.events)
      } else {
        action(...data.events[data.events.length - 1])
      }
      throttledEventsData.delete(throttledGroupId)
    }

    return (...args: any[]) => {
      let data = throttledEventsData.get(throttledGroupId)

      const scheduleAction = (): NodeJS.Timeout => {
        const timer = setTimeout(() => {
          if (data != null) {
            executeAction(data)
          }
        }, throttledTimeout)
        return timer
      }

      if (data == null) {
        data = {
          timer: scheduleAction(),
          events: [args]
        }
        throttledEventsData.set(throttledGroupId, data)
      } else {
        if (accumulateEvents) {
          data.events.push(args)
        }

        if (resetTimerOnNewEvent) {
          clearTimeout(data.timer)
          data.timer = scheduleAction()
        }
      }
    }
  }
  return {
    shutdown: shutDownWSConnection,
    on,
    off
  }
}

const WebSockets = WebSocketEventBus(customerScoreHubUrl)

export const WebSocketsCrm = WebSocketEventBus(crmHubUrl)
export const WebSocketsRecon = WebSocketEventBus(reconHubUrl)
export const WebSocketsInventory = WebSocketEventBus(inventoryHubUrl)

export default WebSockets
