import { css } from '@emotion/css'

export const HEADER_HEIGHT = '56px'

export default css(`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${HEADER_HEIGHT};
  padding: 16px;
  font-size: 20px;
  font-weight: 500;

  img {
    width: 16px;
    cursor: pointer;
  }
`)
