import { type FC } from 'react'
import { type DealModel } from 'api/types/responses'
import Tab from 'components/common/Tabs/components/Tabs/Tab'
import useDealFormsTab from './hooks/useDealFormsTab'
import DealForms from './DealForms'

interface DealFormsTabProps {
  isSelected: boolean
  deal: DealModel | null
  dealId: string
  isDealApproved: boolean
}

const DealFormsTab: FC<DealFormsTabProps> = ({
  dealId,
  deal,
  isSelected,
  isDealApproved = false
}) => {
  const { state, setState } = useDealFormsTab({ dealId, isDealApproved })

  if (deal == null) {
    return null
  }

  return (
    <Tab isSelected={isSelected}>
      <DealForms
        dealId={dealId}
        state={state}
        setState={setState}
        email={deal.buyer.email}
        coBuyerEmail={deal.cobuyer.email}
        make={deal.vehicle.make}
        model={deal.vehicle.model}
        year={deal.vehicle.year}
      />
    </Tab>
  )
}

export default DealFormsTab
