import { type FC, useMemo } from 'react'
import { Banner, cnx, formatCurrencyAccounting } from '@carfluent/common'

import { type TransactionLineRow } from 'types'
import Table from 'components/common/Table'
import IconSVG from 'components/inlineImages'
import { transactionDifferenceMsg } from 'constants/errors'
import isSpecialTransaction from 'utils/accounting/isSpecialTransaction'
import subtractMoney from 'utils/common/subtractMoney'

import { type TransactionSummaryProps } from './types'
import getColumns from './columns'
import CLASS_NAME from './styles'

const TransactionSummary: FC<TransactionSummaryProps> = ({
  amount,
  children,
  getWidths,
  isBankStatementMatch,
  isTableAmountDirty,
  tableData,
  transactionTypeId
}) => {
  const totalCredits = tableData.reduce((acc: number, row: TransactionLineRow) => (acc + Number(row.credits ?? 0)), 0)
  const totalDebits = tableData.reduce((acc: number, row: TransactionLineRow) => (acc + Number(row.debits ?? 0)), 0)
  const difference = subtractMoney(totalDebits, totalCredits)

  /**
   * Lines difference for special transactions does not include a system-created line
   * (invisible line that is used to prefill header form),
   * so we should to add it explicitly for the case of "Bank Statement Match".
   * This is needed because we want to force user to enter Bank Statement amount
   * as a total amount of all visible lines.
   */
  const bankStatementTotal = totalDebits !== 0 ? totalDebits : totalCredits
  const bankStatementMatchDifference = (amount ?? 0) - bankStatementTotal
  const bankStatementMatchDifferenceStr = formatCurrencyAccounting(bankStatementMatchDifference, { allowNegative: true })

  const netTotal = totalCredits !== 0 ? totalCredits : totalDebits

  const tableConfig = useMemo(() => {
    const isWrong = difference !== 0
    const columns = getColumns(getWidths, isWrong, transactionTypeId)
    const debits = difference > 0 ? difference : null
    const credits = difference < 0
      ? Math.abs(difference)
      : isWrong ? null : 0

    const data = [
      { entity: 'Total', credits: totalCredits, debits: totalDebits },
      { entity: 'Difference', credits, debits }
    ]

    return { columns, data }
  }, [totalCredits, totalDebits, difference])

  // ========================================== //
  //                    RENDER                  //
  // ========================================== //

  if (isSpecialTransaction(transactionTypeId)) {
    return (
      <div className={cnx(CLASS_NAME, 'memo-container')}>
        {children}

        <div className={cnx('total-container', isBankStatementMatch && 'bank-statement-match-total')}>
          <div className='total'>
            <div className='total-title'>Net total</div>
            <div className={cnx('total-sum', 'payable-total-sum')}>
              {formatCurrencyAccounting(netTotal, { allowNegative: true })}
            </div>
          </div>

          {isBankStatementMatch && (
            <div className={cnx('difference', (bankStatementMatchDifference !== 0) && 'error-text')}>
              <div className='total-title'>Difference</div>

              <div className={cnx('total-sum', 'payable-total-sum')}>
                {bankStatementMatchDifferenceStr}
              </div>
            </div>
          )}

          <Banner
            className='g-concise-error-banner'
            message={transactionDifferenceMsg(amount ?? 0)}
            isOpen={isBankStatementMatch && (bankStatementMatchDifference !== 0) && isTableAmountDirty}
            type='error'
            startAdornment={<IconSVG.Lock color='#B00020' opacity={1} />}
          />
        </div>
      </div>
    )
  }

  return (
    <div
      className={cnx(CLASS_NAME, 'je-summary-table')}
      data-test-id='tx-summary-section'
    >
      <Table
        columns={tableConfig.columns}
        data={tableConfig.data}
        hideHeader
      />
    </div>
  )
}

export default TransactionSummary
