import { type FC } from 'react'
import { formatCurrencyDecimal } from '@carfluent/common'

import InfoField from 'components/common/InfoField'

export interface SummaryInfoProps {
  totalSale: number
  totalGross: number
  averageGross: number
  numberOfSales: number
}

const SummaryInfo: FC<SummaryInfoProps> = ({
  totalSale,
  totalGross,
  averageGross,
  numberOfSales
}) => {
  const totalSaleValue = totalSale === 0 ? '-' : formatCurrencyDecimal(totalSale, { allowNegative: true })
  const totalGrossValue = totalGross === 0 ? '-' : formatCurrencyDecimal(totalGross, { allowNegative: true })
  const averageGrossValue = averageGross === 0 ? '-' : formatCurrencyDecimal(averageGross, { allowNegative: true })
  const numberOfSalesValue = numberOfSales === 0 ? '-' : numberOfSales

  return (
    <>
      <div className='summary-info'>
        <InfoField
          label='Total sale:'
          value={totalSaleValue}
          className='summary-info-item'
        />
        <InfoField
          label='Total gross:'
          value={totalGrossValue}
          className='summary-info-item'
        />
        <InfoField
          label='Average gross:'
          value={averageGrossValue}
          className='summary-info-item'
        />
        <InfoField
          label='Number of sales:'
          value={numberOfSalesValue}
          className='summary-info-item'
        />
      </div>
    </>
  )
}

export default SummaryInfo
