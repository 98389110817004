import type { FC } from 'react'
import { observer } from 'mobx-react-lite'
import Skeleton from '@material-ui/lab/Skeleton'
import { Tooltip } from '@material-ui/core'
import { cnx, Loader } from '@carfluent/common'

import IconSVG from 'components/inlineImages'
import playIcon from 'assets/play_circle_outline.png'

import useStyles from './styles'

export interface ImageItemProps {
  image: ImageDescriptor
  isMain?: boolean
  className?: string
  isDeleting?: boolean
  onSetMain?: () => void
  onDelete?: () => void
  onEdit?: () => void
  onClick?: () => void
}

export enum ImageState {
  Uploaded,
  Uploading,
  Failed
}

export interface ImageDescriptor {
  id?: number
  fileId?: number | null
  originalUrl: string
  thumbnailUrl?: string
  fileName: string
  state: ImageState
  originalSizeKb?: number
  uniqueName?: string
  fileExtension?: string
  videoUrl?: string
  contentType?: string
}

export const ImageItem: FC<ImageItemProps> = ({
  className = '',
  isMain = false,
  isDeleting = false,
  image,
  onEdit,
  onDelete,
  onSetMain,
  onClick
}) => {
  const styles = useStyles()

  const isFailed = image.state === ImageState.Failed
  const fileExtension = image?.fileExtension?.toLowerCase() ?? ''
  const isVideo = ['.mp4', '.mov'].includes(fileExtension)
  const isLoading = image.state === ImageState.Uploading

  return (
    <div className={cnx(styles.item, 'cf-item-wrapper', className, isFailed && 'cf-img-failed')} onClick={onClick}>

      {(image.state === ImageState.Uploaded && !isVideo) && (
        <img src={image.thumbnailUrl ?? image.originalUrl} alt='Vehicle photo' />
      )}

      {(image.state === ImageState.Uploaded && isVideo) && (
        <div className={styles.posterContainer}>
          <img src={image.thumbnailUrl} alt='Vehicle video' />
          <div className={styles.iconPlayer}>
            <img src={playIcon} alt='Play video' />
          </div>
        </div>
      )}

      {isLoading && (
        <Skeleton variant='rect' width='100%' height='100%' />
      )}

      {isFailed && (
        <p className='cf-img-failed-msg'>Failed to upload</p>
      )}

      <div className={cnx(styles.blockControls, 'hoverable', { [styles.mainItem]: isMain })}>
        <div className={cnx(styles.buttonsList, 'cf-image-buttons-control', isLoading && 'is-loading')}>
          {!isLoading && (
            <>
              <div className={styles.container}>
                {(onSetMain != null && !isVideo) && (
                  <Tooltip title='Make primary' classes={{ tooltip: styles.tooltip }}>
                    <div
                      className={styles.buttonItem} onClick={(e) => {
                        e.stopPropagation()
                        onSetMain()
                      }}
                    >
                      <IconSVG.Crown color={isMain ? '#DDB3A0' : '#fff'} />
                    </div>
                  </Tooltip>
                )}
              </div>

              {(onEdit != null) && (
                <Tooltip title={isVideo ? 'Play' : 'Edit'} classes={{ tooltip: styles.tooltip }}>
                  <div
                    className={styles.buttonItem} onClick={(e) => {
                      e.stopPropagation()
                      onEdit()
                    }}
                  >
                    {isVideo ? <IconSVG.Player /> : <IconSVG.Pencil color='#fff' opacity={0.8} />}
                  </div>
                </Tooltip>
              )}
            </>
          )}

          <div className={cnx(styles.load, 'loading-container')}>
            {isLoading && (
              <div className={styles.buttonItem}>
                <Loader color='light' size='small' />
              </div>

            )}

            <Tooltip title='Delete' classes={{ tooltip: styles.tooltip }}>
              <div
                className={styles.buttonItem} onClick={(e) => {
                  e.stopPropagation()
                  onDelete?.()
                }}
              >
                {isDeleting
                  ? <Loader color='light' size='small' />
                  : <IconSVG.TrashBasket color='#fff' />}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(ImageItem)
