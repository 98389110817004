import type { FC } from 'react'
import { Modal, cn } from '@carfluent/common'
import { Chip, TextField } from '@material-ui/core'

import Icon from 'components/common/Icon'
import CheckboxLabel from 'components/common/CheckboxLabel'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import DealerRolesDropdown from 'components/userManagement/DealerRolesDropdown'

import useInviteUser from './hook'
import type { InviteUserModalProps } from './hook/types'
import CLASS_NAME from './styles'
import { ViewDealerRoles } from 'api/types'

const TITLE = 'Invite user?'
const DEFAULT_MIN_WIDTH = 680
const SUBMIT_BTN_TEXT = 'Invite'

const DeleteIcon = <Icon name='chipDelete' className='delete-icon' alt='X' />

const InviteUserModal: FC<InviteUserModalProps> = (props) => {
  const {
    isOpen,
    isLoading,
    invitationRole,
    emails,
    emailError,
    roleError,
    noDealerSelectedError,
    selectedDealers,
    dealers,
    value,
    onChange,
    onBlur,
    onKeyDown,
    onRoleChange,
    deleteEmail,
    onClose,
    onSubmit,
    onDealerSelect
  } = useInviteUser(props)

  const renderFooter = (): JSX.Element => {
    return (
      <CancelSubmitActionsFooter
        isLoading={isLoading}
        onSubmit={onSubmit}
        onClose={onClose}
        submitTitle={SUBMIT_BTN_TEXT}
      />
    )
  }

  return (
    <Modal
      className={CLASS_NAME}
      isOpen={isOpen}
      minWidth={DEFAULT_MIN_WIDTH}
      onClose={onClose}
      renderFooterActions={renderFooter}
      title={TITLE}
    >
      <p>Type emails of people you want to invite. Use comma for multiply emails.</p>
      <div className={cn('user-management-invite-input', CLASS_NAME)}>
        <TextField
          InputProps={{
            className: cn(
              'invite-email-input',
              emails.length > 0 ? 'column' : 'row',
              emailError ? 'field-error' : ''
            ),
            startAdornment: (
              <div className='email-wrapper'>
                <p className='email-wrapper-label'>To:</p>
                <div>
                  {
                    emails.map((item, index) => (
                      <Chip
                        key={`${item}${index}`}
                        tabIndex={-1}
                        data-idx={index}
                        className='invite-email-chip'
                        label={item}
                        onDelete={deleteEmail}
                        deleteIcon={DeleteIcon}
                      />
                    ))
                  }
                </div>
              </div>
            )
          }}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
        {
          emailError && (
            <p className='error-text email-error'>
              Please enter valid email address
            </p>
          )
        }
        <DealerRolesDropdown
          error={roleError}
          onChange={onRoleChange}
          value={invitationRole}
        />

        {dealers.length > 1 && (
          <div className='checkboxes-wrapper'>
            <p className='checkboxes-title'>Invite to dealership(s):</p>
            <div className='items-list'>
              {selectedDealers.map((dealer) => (
                <div
                  key={dealer.id}
                  className='item-block'
                >
                  <CheckboxLabel
                    onChange={onDealerSelect}
                    value={dealer}
                    disabled={Number(invitationRole) === ViewDealerRoles.DealerAdmin}
                    checked={Number(invitationRole) === ViewDealerRoles.DealerAdmin ? true : dealer.checked}
                  />
                </div>
              ))}
            </div>
            {noDealerSelectedError !== '' && (
              <p className='error-text'>
                {noDealerSelectedError}
              </p>
            )}
          </div>)}
      </div>
    </Modal>
  )
}

export default InviteUserModal
