import type { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import CLASS_NAME from './styles'

/**
 * It is important this and similar components have this structure.
 * Page content must go first and must be wrapped in any block-scoped element.
 * Outlet for nested routes must go second.
 */
const OverlayedPageHOC = (Comp: FC): FC => {
  return observer(() => (
    <>
      <section className={CLASS_NAME}>
        <Comp />
      </section>
      <Outlet />
    </>
  ))
}

export default OverlayedPageHOC
