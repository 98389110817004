import { type FC, type UIEvent, useCallback, useState } from 'react'
import { cn, GalleryModal, Loader, useGallery } from '@carfluent/common'

import {
  DEFAULT_FILE_MAX_SIZE,
  DEFAULT_FILE_WITHOUT_VIDEO_EXTENSIONS,
  DEFAULT_MAX_FILES_NUM,
  UNLIMITED_FILES_SIZE
} from 'constants/files'
import useFilesDragAndDrop from 'hooks/useFilesDragAndDrop'
import type { FileFilterData } from 'utils/fileFilter'
import IconSVG from 'components/inlineImages'

import Message, { type MessageProps } from '../Message'
import { CLASS_NAME, GALLERY_CLASS_NAME } from './styles'

export interface ContentProps {
  messages: MessageProps[]
  loadingMessages: MessageProps[]
  onScroll: (e: UIEvent<HTMLDivElement>) => void
  onAddFiles: (data: FileFilterData) => void
  isLoading: boolean
  isFilesNumLimitReached: boolean
  isUnsubscribed: boolean
}

const Content: FC<ContentProps> = ({
  messages,
  onScroll,
  isLoading,
  isFilesNumLimitReached,
  loadingMessages,
  onAddFiles,
  isUnsubscribed
}) => {
  const [galleryImages, setGalleryImages] =
    useState<string[]>([])

  const { isDragging, ...dragProps } = useFilesDragAndDrop(
    {
      acceptedExtensions: DEFAULT_FILE_WITHOUT_VIDEO_EXTENSIONS,
      isDisabled: isFilesNumLimitReached,
      maxFileSize: DEFAULT_FILE_MAX_SIZE,
      maxFilesTotalSize: UNLIMITED_FILES_SIZE,
      maxFileNumber: DEFAULT_MAX_FILES_NUM,
      onAddFiles
    }
  )

  const isEmptyMessages = (loadingMessages.length === 0) && (messages.length === 0)

  const {
    isModalOpen,
    selectedIdx,
    toggleModal,
    setSelectedIdx
  } = useGallery()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onOpenGallery = useCallback((images: string[], selectedImageIdx: number): void => {
    setGalleryImages(images)
    setSelectedIdx(selectedImageIdx)
    toggleModal()
  }, [toggleModal])

  // ========================================== //

  return (
    <div
      {...dragProps}
      onScroll={onScroll}
      className={cn(CLASS_NAME, isDragging ? 'is-dragging' : '')}
    >
      {isUnsubscribed && <p className='unsubscribed'><IconSVG.Block />&nbsp; Lead unsubscribed</p>}
      {isDragging && !isUnsubscribed ? <p className='cf-dnd-overlay'>Drag files here</p> : null}
      {isEmptyMessages && <p className='cf-no-messages'>No messages</p>}

      {loadingMessages.map(({ id, ...props }) => (
        <Message key={id} {...props} />
      ))}

      {messages.map(({ id, ...props }) => (
        <Message key={id} {...props} onOpenGallery={onOpenGallery} />
      ))}

      {isLoading && (
        <div className='cf-loader'>
          <Loader color='dark' />
        </div>
      )}

      <GalleryModal
        isOpen={isModalOpen}
        imageUrls={galleryImages}
        selected={selectedIdx}
        setSelected={setSelectedIdx}
        onToggle={toggleModal}
        className={GALLERY_CLASS_NAME}
      />
    </div>
  )
}

export default Content
