import { css } from '@emotion/css'

export default css`
  padding: 16px;
  display: grid;
  row-gap: 16px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #FAFAFA;

  .deal-recap-payment-item {
    display: grid;
    grid-template-columns: 1fr 80px 120px;
    column-gap: 24px;
      
    &.is-large {
      column-gap: 16px;
    }
        
    .payment-type {
      font-size: 14px;
      line-height: 20px;
      color: #101010;
      font-weight: 400;
        
      &.is-large {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
      }
    }
      
    .status-item-large {
      display: flex;
      align-items: center;
        
      .status-name {
        color: #101010;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
        margin-right: 4px;
      }
        
      > div {
        max-height: 20px;
        font-weight: 500;
      }
    }
        
    .status-item-small {
      display: flex;
      align-items: center;

      .status-name {
        color: #101010;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        margin-left: 4px;
      }

      > div {
        max-height: 20px;
        font-weight: 500;
      }
    }
      
    .amount-item {
      color: rgba(33, 33, 33, 0.80);
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.1px;
    }
  }

  .deal-recap-payment-total-item {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    color: #101010;
    
    > span {
      font-weight: 500;
    }
  }
  
  .divider {
    height: 1px;
    background: rgba(0, 0, 0, 0.12);
  }
`
