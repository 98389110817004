import { type FC } from 'react'
import type { ContentState } from 'draft-js'
import CLASS_NAME from './styles'

interface FeedbackLinkProps {
  contentState: ContentState
  entityKey?: string
  entityData?: Record<string, unknown>
}

/**
 * We need inline styles for serializing markup to HTML for sending emails.
 */

export const FeedbackLinkContent: FC = () => {
  return (
    <span
      style={{
        color: '#101010',
        background: 'transparent',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '1.25px',
        fontFamily: 'inherit',
        display: 'inline-block'
      }}
    >
      PROVIDE FEEDBACK
    </span>
  )
}

const FeedbackLink: FC<FeedbackLinkProps> = ({ contentState, entityKey, children, entityData }) => {
  const { url } = entityKey != null
    ? contentState.getEntity(entityKey).getData()
    : (entityData ?? {})

  return (
    <a
      href={url}
      className={CLASS_NAME}
      style={{
        display: 'inline-block',
        width: '294px',
        height: '56px',
        boxSizing: 'border-box',
        lineHeight: '16px',
        background: '#fff',
        border: '2px solid #101010',
        borderRadius: '12px',
        padding: '18px',
        textAlign: 'center',
        textDecoration: 'none'
      }}
    >
      {children ?? <FeedbackLinkContent />}
    </a>
  )
}

export default FeedbackLink
