import { css } from '@emotion/css'

export default css(`
  &.modal-body-container {
    .container {
      display: flex;
      flex-direction: row;
      gap: 0 80px;

      .left-side, .right-side {
        min-height: 378px;
      } 
    }

    & .banner-appeared {
      margin-bottom: 32px;

      :after { display: none }
    }
  }
`)
