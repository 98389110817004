import { type FC, MutableRefObject, useCallback, useEffect, useState } from 'react'
import { cn } from '@carfluent/common'

import { useStyles } from './styles'

export interface BackToTopProps {
  className?: string
  scrollYTrigger?: number
  refScrollable?: MutableRefObject<Element | null>
}

const BackToTop: FC<BackToTopProps> = ({
  className,
  refScrollable,
  scrollYTrigger = 300
}) => {
  const styles = useStyles()
  const [isShown, setIsShown] = useState(false)
  const [isHover, setIsHover] = useState(false)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const handleClick = useCallback(() => {
    const scrollable = refScrollable?.current ?? window
    scrollable.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const scrollable = refScrollable?.current ?? window

    function scrollHandler (evt: any): void {
      const scrollY = (refScrollable?.current == null) ? window.scrollY : evt.target.scrollTop
      const isShown = scrollY > scrollYTrigger

      setIsShown(isShown)

      if (!isShown) {
        setIsHover(false)
      }
    }

    scrollable.addEventListener('scroll', scrollHandler)

    return () => {
      scrollable.removeEventListener('scroll', scrollHandler)
    }
  }, [scrollYTrigger])

  // ========================================== //

  if (!isShown) {
    return null
  }

  return (
    <button className={cn(styles.backToTop, 'cf-back-to-top', className)}>
      <svg
        width='52'
        height='53'
        viewBox='0 0 52 53'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        onClick={handleClick}
        onMouseEnter={() => { setIsHover(true) }}
        onMouseLeave={() => { setIsHover(false) }}
      >
        <g filter='url(#filter0_d_56115_97343)'>
          <circle cx='26' cy='24.8735' r='22' fill='white'/>
        </g>

        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M26 20.8735L20 26.8735L21.41 28.2835L26 23.7035L30.59 28.2835L32 26.8735L26 20.8735Z'
          fill='#212121'
          fillOpacity={isHover ? 1 : 0.5}
        />

        <defs>
          <filter id='filter0_d_56115_97343' x='0' y='0.873535' width='52' height='52' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity='0' result='BackgroundImageFix'/>
            <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
            <feOffset dy='2'/>
            <feGaussianBlur stdDeviation='2'/>
            <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'/>
            <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_56115_97343'/>
            <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_56115_97343' result='shape'/>
          </filter>
        </defs>
      </svg>
    </button>
  )
}

export default BackToTop
