import { type FC } from 'react'
import CLASS_NAME from './styles'

interface CounterLineProps {
  totalLinesCount: number
  duplicateLinesCount: number
  successfulLinesCount: number
  errorLinesCount: number
  pendingLinesCount: number
}

const CounterLine: FC<CounterLineProps> = ({
  totalLinesCount,
  duplicateLinesCount,
  successfulLinesCount,
  errorLinesCount,
  pendingLinesCount
}) => {
  const isPending = pendingLinesCount > 0
  return (
    <div className={CLASS_NAME}>
      <div className='line-item'>
        <span>{successfulLinesCount}</span>
        <p>lines from</p>
      </div>

      <div className='line-item'>
        <span>{totalLinesCount}</span>
        <p>uploaded successfully. </p>
      </div>

      <div className='line-item'>
        <span>{errorLinesCount}</span>
        <p>lines with errors,</p>
      </div>

      <div className='line-item'>
        <span>{duplicateLinesCount}</span>
        <p>duplicates{isPending ? ',' : '.'}</p>
      </div>
      {isPending && (
        <div className='line-item'>
          <span>{pendingLinesCount}</span>
          <p>pending transactions.</p>
        </div>
      )}

    </div>
  )
}

export default CounterLine
