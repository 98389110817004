import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import type { IconProps } from 'types'

const IconSVGAppointments: FC<IconProps> = ({
  width = 32,
  height = 32,
  className
}) => {
  return (
    <svg
      className={cnx(className, 'cf-icon')}
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width={width} height={height} rx='8' fill='#FAFAFA' />
      <g clipPath='url(#clip0_81842_236151)'>
        <path d='M20.1667 15.9987H16V20.1654H20.1667V15.9987ZM19.3333 6.83203V8.4987H12.6667V6.83203H11V8.4987H10.1667C9.24167 8.4987 8.50833 9.2487 8.50833 10.1654L8.5 21.832C8.5 22.7487 9.24167 23.4987 10.1667 23.4987H21.8333C22.75 23.4987 23.5 22.7487 23.5 21.832V10.1654C23.5 9.2487 22.75 8.4987 21.8333 8.4987H21V6.83203H19.3333ZM21.8333 21.832H10.1667V12.6654H21.8333V21.832Z' fill='#101010' />
      </g>
      <defs>
        <clipPath id='clip0_81842_236151'>
          <rect width='20' height='20' fill='#FAFAFA' transform='translate(6 6)' />
        </clipPath>
      </defs>
    </svg>

  )
}

export default IconSVGAppointments
