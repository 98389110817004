import { type FC } from 'react'
import type { VehiclesTabProps } from './types'
import Tab from 'components/common/Tabs/components/Tabs/Tab'
import VehiclesForm from './VehiclesForm'

const VehiclesTab: FC<VehiclesTabProps> = (props) => {
  return (
    <Tab isSelected={props.isSelected}>
      <VehiclesForm {...props} />
    </Tab>
  )
}

export default VehiclesTab
