import { FC, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import kebabCase from 'lodash-es/kebabCase'

import { intercalate } from 'utils/general'
import ModalWindow, { DialogProps } from 'components/dialogs/ModalWindow'
import Button from 'components/common/Button'
import createStyleClass from './styles'

const MODAL_TITLE = 'Table imported'
const SEPARATOR = '{0}'

export interface WarningsDialogProps {
  isModalOpen: boolean
  onCloseModal: () => void
  warnings: Record<string, number[]>
}

const WarningsDialog: FC<WarningsDialogProps> = ({
  isModalOpen,
  onCloseModal: _onCloseModal,
  warnings
}) => {
  const classes = useMemo((): DialogProps['classes'] => {
    const styles = createStyleClass()
    return { paper: styles.dialogPaper }
  }, [])

  const onCloseModal = useCallback((_, reason?: string) => {
    if (reason !== 'backdropClick') {
      _onCloseModal()
    }
  }, [_onCloseModal])

  const renderFooterActions = useCallback(() => (
    <div className='cf-footer-panel'>
      <div className='cf-footer-actions'>
        <Button className='cf-btn-ok' onClick={onCloseModal}>
          OK
        </Button>
      </div>
    </div>
  ), [onCloseModal])

  const warningsElem = useMemo(() => (
    Object.entries(warnings).map(([key, value]) => {
      const parts = key.split(SEPARATOR).map(part => (<p className='cf-text' key={part}>{part}</p>))
      const resolvedParts = intercalate(<p className='cf-text cf-rows'>{value.join(', ')}</p>, parts)

      return (
        <div key={key} className='cf-warning-line'>
          {resolvedParts}
        </div>
      )
    })
  ), [warnings])

  return (
    <ModalWindow
      classes={classes}
      data-test-id={kebabCase(MODAL_TITLE)}
      isCloseIconVisible={false}
      open={isModalOpen}
      onClose={onCloseModal}
      renderFooterActions={renderFooterActions}
      title={MODAL_TITLE}
    >
      <div className='cf-content-header'>Note:</div>

      {warningsElem}
    </ModalWindow>
  )
}

export default observer(WarningsDialog)
