import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import CLASS_NAME from './styles'

export interface LeadLabelProps {
  className?: string
  value?: string
}

const LeadLabel: FC<LeadLabelProps> = ({
  value = '',
  className
}) => {
  return (
    <div className={cnx(CLASS_NAME, value?.toLowerCase(), className)}>
      {value?.toUpperCase()}
    </div>
  )
}

export default LeadLabel
