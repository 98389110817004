import { css } from '@emotion/css'
import { px, ContentLayoutWidth as CLW } from 'constants/constants'

export default css(`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  max-width: ${px(CLW.MaxSettingsContent)};
  
  .sub-title-container {
    gap: 8px;
    
    .section-title {
      margin: 0px;
      margin-bottom: 0px;
    }
  }
  
  .sub-title {
    .block-skeleton.is-loading {
      max-width: 600px;
    }
  }
`)
