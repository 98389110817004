import { css } from '@emotion/css'
import { px, ScreenWidthBreakpoints } from 'constants/constants'

export default css(`
  display: flex;
  gap: 24px;
  min-height: 200px;
  
  &.g-panel {
    padding: 16px;
  }
  
  .block {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: #FAFAFA;
    flex: 1 1 auto;
    
    .block-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1 1 calc(100% / 3);
      gap: 8px;
      
      color: #101010;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
    
    .block-row--underscored {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    
    .skeleton-amount.is-loading {
      width: 130px;
      justify-content: flex-end;
    }
    
    .padding-right {
      h6 {
        padding-right: 42px;
      }
    }
    
    .cell {
      display: flex;
      flex: 1 1 fit-content;
      justify-content: flex-start;
      flex-direction: column;
    }
    
    .cell--right {
      text-align: right;
    }
    
    .cell--center {
      text-align: center;
    }
    
    .cell--sign {
      flex: 1 1 100%;
      
      h6 {
        width: 100%;
        text-align: center;
      }
    }
    
    .cell-number {
      color: #101010;
      font-family: Roboto;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
    }
    
    .cell-subtitle {
      color: rgba(33, 33, 33, 0.50);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      white-space: nowrap;
    }    
  }
  
  .block-cleared,
  .block-uncleared {
    flex-basis: 400px;
    padding: 0px 16px;
  }
  
  .block-difference {
    flex-basis: 580px;
    background: #FFFFFF;
    
    .error {
      color: var(--color-error);
    }
  }
  
  @media screen and (max-width: ${px(ScreenWidthBreakpoints.Medium)}) {
    .long-text-with-items-number {
      span {
        letter-spacing: 0.15px;
      }
    }
  }
`)
