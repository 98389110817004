import { type FC, useCallback, useState } from 'react'
import { Button, Loader, Modal, cnx } from '@carfluent/common'
import { FormHelperText } from '@material-ui/core'

import DocumentsApiProvider from 'api/documents.api'
import { isFalsy, isTruthy } from 'utils/general'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { Dropzone, mb } from 'components/common/DropZone'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

import Preview from '../Preview'
import LogoInstructions from '../LogoInstruction'
import CLASS_NAME from './styles'

const MB_SIZE = 3
const MAX_KB_SIZE = mb(MB_SIZE)
const ALLOWED_TYPES = ['image/png', 'image/jpeg']
const MODAL_MIN_WIDTH = 800

export interface LogoDropzoneProps {
  disabledForm: boolean
  logoPreviewUrl: string
  logoUrl: string
  isLoading?: boolean
  setLogoAndPreviewUrl: (url: string) => void
  setFieldValue: (fieldId: string, value: any) => void
}

const LogoDropzone: FC<LogoDropzoneProps> = ({
  setFieldValue,
  setLogoAndPreviewUrl,
  logoPreviewUrl,
  logoUrl,
  disabledForm: isFormDisabled = true,
  isLoading = true
}) => {
  const { showAlert } = useCustomSnackbar()
  const [hasError, setError] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isLogoLoading, setIsLogoLoading] = useState(false)
  const [isPreviewOpen, setPreviewOpen] = useState(false)

  const resolvedLogoUrl = isTruthy(logoPreviewUrl) ? logoPreviewUrl : logoUrl

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onChangeLogo = useCallback(async (filesToUpload: File[]): Promise<void> => {
    setError(false)
    setIsLogoLoading(true)
    const file = filesToUpload?.[0]

    if (file == null) {
      return
    }

    if (!ALLOWED_TYPES.includes(file.type)) {
      setError(true)
      return
    }

    if (file.size > MAX_KB_SIZE) {
      return
    }

    setLogoAndPreviewUrl(URL.createObjectURL(file))

    const data = new FormData()
    data.append('FolderName', 'logo')
    data.append('File', file)

    try {
      const res = await DocumentsApiProvider.postLogo(data)
      const imageUrl = res.thumbnailUrl ?? res.originalUrl

      setLogoAndPreviewUrl(imageUrl)
      setFieldValue('logoUrl', imageUrl)
    } catch (err) {
      showAlert('Can\'t upload dealer logo. Please, try later again')
    } finally {
      setIsLogoLoading(false)
    }
  }, [setLogoAndPreviewUrl, setFieldValue, showAlert])

  const onDeleteLogo = useCallback(() => {
    setLogoAndPreviewUrl('')
    setFieldValue('logoUrl', '')
  }, [setLogoAndPreviewUrl, setFieldValue])

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, 'logo')}>
      <BlockSkeleton isLoading={isLoading} className='logo-title'>
        <h6 className='logo-title'>Logo</h6>
      </BlockSkeleton>

      {isFalsy(resolvedLogoUrl) && (
        <div className='content'>
          <Dropzone
            onAddFiles={onChangeLogo}
            multiple={false}
            maxSize={MAX_KB_SIZE}
            disabled={isFormDisabled}
            titleButton='Upload file'
            ErrorMessages={{ FileTooLarge: `Maximum size ${MB_SIZE}MB` }}
          />
        </div>
      )}

      {isLogoLoading && (
        <div className='logo-preview'>
          <Loader size='medium' color='light' />
        </div>)}

      {isTruthy(resolvedLogoUrl) && !isLogoLoading && (
        <BlockSkeleton isLoading={isLoading} className='logo-skeleton'>
          <div className='logo-preview'>
            <img src={resolvedLogoUrl} alt='logo' className='logo' />
          </div>
        </BlockSkeleton>)}

      {!isLoading && hasError && (
        <FormHelperText>
          Should be png or jpeg format
        </FormHelperText>
      )}

      {!isLoading && isTruthy(resolvedLogoUrl) && (
        <div className='logo-actions'>
          <Button
            className='root-btn'
            onClick={() => setPreviewOpen(true)}
            isDisabled={isLogoLoading}
            variant='text'
          >
            Preview
          </Button>

          <Modal
            isOpen={isPreviewOpen}
            keepContentMounted
            minWidth={MODAL_MIN_WIDTH}
            onClose={() => setPreviewOpen(false)}
            title='Preview'
          >
            <Preview logoUrl={resolvedLogoUrl} />
          </Modal>

          <Button
            className='root-btn'
            onClick={onDeleteLogo}
            isDisabled={isLogoLoading}
            variant='text'
          >
            Delete
          </Button>
        </div>
      )}

      {!isLoading && isFalsy(resolvedLogoUrl) && (
        <div className='restriction-list'>
          <p>PNG, JPEG format</p>
          <p>Max 3 MB</p>
        </div>
      )}

      {isFalsy(resolvedLogoUrl) && (
        <div
          className={`where-displayed ${isLoading ? 'hidden' : ''}`}
          onClick={() => setIsOpen(true)}
        >
          <p> Where it will be displayed? </p>
        </div>)}

      <LogoInstructions
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  )
}

export default LogoDropzone
