import type { DictionaryItem, DictionaryItems, VehicleFeature, VehicleOption } from 'api/types'
import { TitleStage } from 'types/enums'

export const getTitleStageValue = (items: DictionaryItems<TitleStage>, titleStageId: number | null, isTitleReceived: boolean): DictionaryItem<TitleStage> | null => {
  const titleStage = items.find(el => el.id === titleStageId)

  return titleStage ?? items.find(el => el.id === (isTitleReceived ? 2 : 1)) ?? null
}

interface GetRepriceTooltip {
  isDeposited: boolean
  isInactive: boolean
  isSalePriceZero: boolean
}

export const getDisabledRepriceTooltip = ({
  isDeposited,
  isInactive,
  isSalePriceZero
}: GetRepriceTooltip): string | null => {
  if (isDeposited) {
    return 'Auto repricing is disabled because the car is on deposit.'
  }

  if (isInactive) {
    return 'Auto repricing is disabled for inactive cars.'
  }

  if (isSalePriceZero) {
    return 'Auto repricing is disabled for cars with a zero price.'
  }

  return null
}

const MIN_API_UI_DELAY = 1500

export const stopSpinnerWithDelay = (stopLoader: (isLoading: boolean) => void): void => {
  /**
   * DD-NOTE: delaying stopSpinner was here before as some kind of hot-fix
   */
  setTimeout(() => {
    stopLoader(false)
  }, MIN_API_UI_DELAY)
}

export const cloneFeatureOptionData = (_options: Record<string, Array<(VehicleFeature | VehicleOption)>> = {}): Record<string, Array<(VehicleFeature | VehicleOption)>> => {
  return Object.keys(_options).reduce((
    newOptions: Record<string, Array<(VehicleFeature | VehicleOption)>>,
    key
  ) => {
    newOptions[key] = _options[key].map(item => ({ ...item }))
    return newOptions
  }, {})
}
