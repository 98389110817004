import { useCallback, useEffect, useRef, useMemo } from 'react'
import { useForm, useModal } from '@carfluent/common'
import type { FormValidation } from '@carfluent/common'
import debounce from 'lodash-es/debounce'
import get from 'lodash-es/get'
import set from 'lodash-es/set'
import { toJS } from 'mobx'

import type { FCHook } from 'types'
import type { AccountListItem } from 'api/types'
import AccountingApiProvider from 'api/accounting.api'
import useDropdown from 'hooks/useDropdown'
import { assertFieldName, containsTruthy, isTruthy, keys } from 'utils/general'
import { Errors, requiredNumber } from 'utils/validationPresets'

import { PackCostFields, AccountFields, ErrTouchShortcuts } from './types'
import type { UsePackCostProps, UsePackCostReturn, PackCostFormData } from './types'

export const GET_DEFAULT_PACK_COST = (): PackCostFormData => ({
  id: null,
  isPackEnabled: false,
  packHoldingAccount: null,
  packInventoryAssetAccount: null,
  packCost: 0
})

const PARENT_UPDATE_DELAY = 300
const DEFAULT_FORM_DATA: PackCostFormData = GET_DEFAULT_PACK_COST()

const usePackCostForm: FCHook<UsePackCostProps, UsePackCostReturn> = ({
  lastResetTS,
  lastTouchifyTS,
  onDeletePackCost: _onDeletePackCost,
  setFieldValue: _setFieldValue,
  setFormIsValid,
  values: parentValues
}) => {
  const deleteModalProps = useModal()
  const refValues = useRef<PackCostFormData>(GET_DEFAULT_PACK_COST())
  const refLastReset = useRef<number>(0)
  const refLastTouchify = useRef<number>(0)

  const form = useForm<PackCostFormData, ErrTouchShortcuts>({
    baseValues: DEFAULT_FORM_DATA,
    validationRules,
    submitAction
  })

  const {
    errors,
    isValid,
    onBlur,
    onChange,
    resetForm,
    setFieldTouched,
    touched,
    values
  } = form

  const checkboxValue = useMemo(() => ({
    id: PackCostFields.IsPackEnabled,
    name: 'Include pack as cost',
    checked: values.isPackEnabled
  }), [values.isPackEnabled])

  const debitAccountProps = useDropdown<AccountListItem>({
    id: PackCostFields.PackInventoryAssetAccount,
    formatInputValue: formatAccountName,
    getItemsList: AccountingApiProvider.getAccounts,
    onBlur,
    value: values.packInventoryAssetAccount
  })

  const creditAccountProps = useDropdown({
    id: PackCostFields.PackHoldingAccount,
    formatInputValue: formatAccountName,
    getItemsList: AccountingApiProvider.getAccounts,
    onBlur,
    value: values.packHoldingAccount
  })

  const debitAccountsOptions = useMemo(() => {
    const currentValue = parentValues.packInventoryAssetAccount

    if (currentValue != null) {
      if (debitAccountProps.options.every(item => item.id !== currentValue?.id)) {
        return [currentValue, ...debitAccountProps.options]
      }
    }

    return debitAccountProps.options
  }, [
    parentValues.packInventoryAssetAccount,
    debitAccountProps.options
  ])

  const creditAccountsOptions = useMemo(() => {
    const currentValue = parentValues.packHoldingAccount

    if (currentValue != null) {
      if (creditAccountProps.options.every(item => item.id !== currentValue?.id)) {
        return [currentValue, ...creditAccountProps.options]
      }
    }
    return creditAccountProps.options
  }, [
    parentValues.packHoldingAccount,
    creditAccountProps.options
  ])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onResetForm = useCallback((value = GET_DEFAULT_PACK_COST()) => {
    refValues.current = value
    resetForm({ ...value })
    setFieldTouched(PackCostFields.PackHoldingAccount, false)
    setFieldTouched(PackCostFields.PackInventoryAssetAccount, false)
  }, [resetForm, setFieldTouched])

  const onSetParentValue = useCallback(
    debounce(_setFieldValue, PARENT_UPDATE_DELAY), [_setFieldValue])

  const onSetLocalValue = useCallback((id: string, value: unknown, markTouched = true) => {
    set(refValues.current, id, value)
    onChange(id, value)

    if (markTouched) {
      setFieldTouched(id, true)
    }
  }, [onChange])

  /**
   * Updates local state and then updates parent state in debounced manner.
   */
  const onSetFieldValue = useCallback((id: string, value: unknown) => {
    assertFieldName(id, DEFAULT_FORM_DATA)
    onSetLocalValue(id, value)
    void onSetParentValue(id, value)
  }, [onSetLocalValue, onSetParentValue])

  const onDeletePackCostConfirm = useCallback(async () => {
    await _onDeletePackCost()
    onResetForm()
    deleteModalProps.onCloseModal()
  }, [
    _onDeletePackCost,
    onResetForm,
    deleteModalProps.onCloseModal
  ])

  const onTogglePackCost = useCallback(() => {
    const packCostId = refValues.current.id
    const isNextEnabled = !refValues.current.isPackEnabled
    const isPackAlreadyExists = packCostId != null

    if (isPackAlreadyExists && !isNextEnabled) {
      deleteModalProps.onOpenModal()
      return
    }

    if (!isNextEnabled) {
      /**
       * Updates chexbox state just to hide section, before real state
       * update will arrive from parent.
       */
      onSetLocalValue(PackCostFields.IsPackEnabled, false)

      void _onDeletePackCost()
      return
    }

    onSetFieldValue(PackCostFields.IsPackEnabled, isNextEnabled)
  }, [
    _onDeletePackCost,
    onSetFieldValue,
    onSetLocalValue,
    deleteModalProps.onOpenModal
  ])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Observes parent state (only) and updates local state if needed.
   */
  useEffect(() => {
    for (const key of keys(parentValues)) {
      const parentValue = parentValues[key]
      const refValue = refValues.current[key]
      const parentValueId = get(parentValue, 'id')
      const isIdChanged = (parentValueId !== undefined) && (parentValueId !== get(refValue, 'id'))

      if (isIdChanged || (parentValue !== refValue)) {
        onSetLocalValue(key, toJS(parentValue), false)
      }
    }
  }, [
    parentValues.id,
    parentValues.packInventoryAssetAccount?.id,
    parentValues.packHoldingAccount?.id,
    parentValues.packCost,
    onSetLocalValue
  ])

  useEffect(() => {
    setFormIsValid(isValid || !containsTruthy(touched))
  }, [isValid, errors, touched, setFormIsValid])

  useEffect(() => {
    if ((lastResetTS != null) && (lastResetTS > refLastReset.current)) {
      refLastReset.current = lastResetTS
      onResetForm(parentValues)
    }
  }, [lastResetTS, parentValues, onResetForm])

  useEffect(() => {
    if ((lastTouchifyTS != null) && (lastTouchifyTS > refLastTouchify.current)) {
      refLastTouchify.current = lastTouchifyTS

      /**
       * AZ-TODO: replace by touchify from common lib (when will be implemented).
       */
      setFieldTouched(PackCostFields.PackCost, true)
      setFieldTouched(PackCostFields.PackHoldingAccount, true)
      setFieldTouched(PackCostFields.PackInventoryAssetAccount, true)
    }
  }, [lastTouchifyTS, touched, setFieldTouched])

  // ========================================== //

  return {
    ...form,
    checkboxValue,
    creditAccountProps: {
      ...creditAccountProps,
      options: creditAccountsOptions
    },
    debitAccountProps: {
      ...debitAccountProps,
      options: debitAccountsOptions
    },
    deleteModalProps: {
      isOpen: deleteModalProps.isModalOpen,
      onClose: deleteModalProps.onCloseModal,
      onConfirm: onDeletePackCostConfirm
    },
    isLastLineErrorVisible: isTruthy(touched.packCost) && isTruthy(form.errors.packCost),
    onTogglePackCost,
    setFieldValue: onSetFieldValue
  }
}

export default usePackCostForm

// ========================================== //

export const formatAccountName = (option: AccountListItem | null): string => {
  if (option == null) {
    return ''
  }

  return `${option.number ?? ''} - ${option.name ?? ''}`
}

const submitAction = async (): Promise<void> => {}

const accountValidationRule = (otherId: AccountFields) => (value: AccountListItem | null, ctx?: PackCostFormData): string | null => {
  const ERROR_ACCOUNT_ID = 'Credit/Debit account shouldn\'t be the same'

  if (value == null) {
    return Errors.Empty
  }

  if (value.id === ctx?.[otherId]?.id) {
    return ERROR_ACCOUNT_ID
  }

  return null
}

const validationRules: FormValidation<PackCostFormData> = {
  [PackCostFields.PackInventoryAssetAccount]: accountValidationRule(PackCostFields.PackHoldingAccount),
  [PackCostFields.PackHoldingAccount]: accountValidationRule(PackCostFields.PackInventoryAssetAccount),
  [PackCostFields.PackCost]: requiredNumber
}
