import {
  type DealFeesAndCoveragesSettingsModel,
  CoverageProductTypeIds
} from 'api/types/responses'

import type {
  AddressDetails,
  CreditApplication as APICreditApplication,
  CurrentAddressDetails as APICurrentAddressDetails,
  EmploymentDetails as APIEmploymentDetails,
  PreviousAddressDetails as APIPreviousAddressDetails,
  PreviousEmploymentDetails as APIPreviousEmploymentDetails,
  CoverageDetails
} from 'api/types'

import {
  GET_DEFAULT_DEAL,
  DEFAULT_EXECUTIVE_DETAILS,
  DEFAULT_BUSINESS_DETAILS,
  DEFAULT_BANK_DETAILS
} from 'api/defaults'

import { type AddressData } from 'types/address'
import { getTypedOrNull, toDate } from 'utils/creditApplication'
import getDealSalesTaxAmountRounded from 'utils/deals/priceHelpers/getDealSalesTaxAmount'

import type {
  EmploymentDetails,
  CreditApplicationFormData,
  CurrentAddressDetails,
  PreviousAddressDetails,
  AdditionalCost,
  CoverageData
} from './types'
import { COVERAGES_NAMES } from 'pages/settings/FISettings/components/PackageCustomization/constants'
import { CoverageDetailsSection } from 'types'
import isManualCoverage from 'utils/coverage/isManualCoverage'
import { getDefaultManualFormData } from 'components/deals/CoverageManualForm/constants'

export const parseCreditApplicationData = (
  rawData: APICreditApplication,
  feesAndCoveragesSettings: DealFeesAndCoveragesSettingsModel | null,
  coverageData: CoverageData
): CreditApplicationFormData => {
  const DEFAULT = GET_DEFAULT_DEAL()
  const {
    salesTax,
    vehicleDetails,
    customerDetails,
    applicantFinancialDetails,
    coApplicantFinancialDetails,
    tradeInDetails,
    financingCalculatorDetails,
    coverageDetails,
    creditApplicationSubmittedDate,
    executiveDetails: _executiveDetails,
    bankDetails: _bankDetails,
    businessDetails: _businessDetails,
    ...restRawData
  } = rawData

  const {
    customerBirthDate,
    customerAddress,
    customerCity,
    customerZipCode,
    customerState,
    customerCounty,
    customerApt
  } = customerDetails

  const { apartmentMoveInDate } = applicantFinancialDetails
  const currentEmployment = applicantFinancialDetails.currentEmploymentDetails
  const previousEmployment = applicantFinancialDetails.previousEmploymentDetails

  const {
    birthDate: coApplicantBirthDate,
    previousAddressDetails: coApplicantPreviousAddressDetails,
    currentEmploymentDetails: coApplicantCurrentEmployment,
    previousEmploymentDetails: coApplicantPreviousEmployment
  } = coApplicantFinancialDetails

  const coApplicantCurrentAddressDetails = getCurrentAddress(
    coApplicantFinancialDetails.currentAddressDetails ??
    DEFAULT.coApplicantFinancialDetails.currentAddressDetails
  )

  const addressData = {
    addressLong: customerAddress,
    address: customerAddress,
    city: customerCity,
    zipCode: customerZipCode,
    state: customerState,
    county: customerCounty,
    apt: customerApt
  }

  const previousAddress = applicantFinancialDetails.previousAddressDetails
  const executiveDetails = _executiveDetails ?? DEFAULT_EXECUTIVE_DETAILS
  const businessDetails = _businessDetails ?? DEFAULT_BUSINESS_DETAILS
  const bankDetails = _bankDetails ?? DEFAULT_BANK_DETAILS
  const isManualSalesTax = restRawData.overridenSalesTaxPercent == null

  const parsed = {
    ...restRawData,
    businessDetails,
    bankDetails,
    executiveDetails: {
      ...executiveDetails,
      birthDate: getTypedOrNull(toDate, executiveDetails.birthDate),
      addressData: {
        addressLong: executiveDetails.address,
        address: executiveDetails.address,
        city: executiveDetails.city,
        zipCode: executiveDetails.zipCode,
        state: executiveDetails.state,
        county: executiveDetails.county,
        apt: executiveDetails.apt
      }
    },
    creditApplicationSubmittedDate: getTypedOrNull(toDate, creditApplicationSubmittedDate),
    vehicleDetails,
    customerDetails: {
      ...customerDetails,
      customerBirthDate: getTypedOrNull(toDate, customerBirthDate),
      addressData
    },
    salesTax: (salesTax ?? 0) * 100,
    isManualSalesTax,
    applicantFinancialDetails: {
      ...applicantFinancialDetails,
      socialSecurityNumber: applicantFinancialDetails.socialSecurityNumber ?? customerDetails.socialSecurityNumber ?? '',
      apartmentMoveInDate: getTypedOrNull(toDate, apartmentMoveInDate),
      incomeOption: applicantFinancialDetails.incomeOption ?? 'None',
      wholesaleSourceType: applicantFinancialDetails?.wholesaleSourceType ?? DEFAULT.applicantFinancialDetails.wholesaleSourceType,
      currentEmploymentDetails: getCurrentEmployment(
        currentEmployment ?? DEFAULT.applicantFinancialDetails.currentEmploymentDetails
      ),
      previousEmploymentDetails: getPreviousEmployment(
        previousEmployment ?? DEFAULT.applicantFinancialDetails.previousEmploymentDetails
      ),
      previousAddressDetails: getPreviousAddress(
        previousAddress ?? DEFAULT.applicantFinancialDetails.previousAddressDetails
      )
    },
    tradeInDetails,
    coApplicantFinancialDetails: {
      ...coApplicantFinancialDetails,
      birthDate: getTypedOrNull(toDate, coApplicantBirthDate),
      housingStatus: coApplicantFinancialDetails?.housingStatus ?? DEFAULT.coApplicantFinancialDetails.housingStatus,
      coApplicantType: coApplicantFinancialDetails?.coApplicantType ?? DEFAULT.coApplicantFinancialDetails.coApplicantType,
      currentAddressDetails: coApplicantCurrentAddressDetails,
      previousAddressDetails: getPreviousAddress(
        coApplicantPreviousAddressDetails ?? DEFAULT.coApplicantFinancialDetails.previousAddressDetails
      ),
      currentEmploymentDetails: getCurrentEmployment(
        coApplicantCurrentEmployment ?? DEFAULT.coApplicantFinancialDetails.currentEmploymentDetails
      ),
      previousEmploymentDetails: getPreviousEmployment(
        coApplicantPreviousEmployment ?? DEFAULT.coApplicantFinancialDetails.previousEmploymentDetails
      )
    },
    financingCalculatorDetails,
    coverageDetails: parseCoverageDetails(coverageDetails ?? []),
    additionalCosts: getAdditionalCosts(rawData)
  }

  /**
   * AZ-TODO: this piece of sh*t will be improved in next PRs,
   * when we will add support of configurable fees for CreditApp.
   */
  const _overridenSalesTaxAmount = getDealSalesTaxAmountRounded({
    coverages: coverageData.coverageDetails.map(x => ({
      productType: COVERAGES_NAMES[x.productTypeId as CoverageProductTypeIds],
      dealerRetailPrice: x.coverageType === 'PEN'
        ? x.forms?.PEN?.totalPrice ?? 0
        : x.forms.Manual.totalPrice ?? 0
    })),
    feesAndCoveragesSettings,
    salesTax,
    values: {
      dealFees: {
        ...parsed.dealFees,
        carDelivery: parsed.transportationCost ?? 0,
        dealerHandlingFee: parsed.documentFee ?? 0,
        others: [
          { amount: parsed.other1 },
          { amount: parsed.other2 },
          { amount: parsed.other3 }
        ]
      },
      tradeInDetails: {
        credit: parsed.tradeInDetails.tradeInCredit
      },
      vehicle: {
        listPrice: parsed.vehicleDetails.vehiclePrice
      }
    }
  })

  const overridenSalesTaxAmount = isManualSalesTax
    ? restRawData.overridenSalesTaxAmount
    : _overridenSalesTaxAmount

  return {
    ...parsed,
    coverageData,
    overridenSalesTaxAmount
  }
}

const getAdditionalCosts = (data: APICreditApplication): AdditionalCost[] => {
  const { other1Description, other1, other2Description, other2, other3Description, other3 } = data
  const res: AdditionalCost[] = []

  if (other1Description != null) {
    res.push({ description: other1Description, amount: other1 })
  }

  if (other2Description != null) {
    res.push({ description: other2Description, amount: other2 })
  }

  if (other3Description != null) {
    res.push({ description: other3Description, amount: other3 })
  }

  return res
}

const getCurrentEmployment = (
  employmentDetails: APIEmploymentDetails | null
): EmploymentDetails | null => {
  if (employmentDetails == null) {
    return null
  }

  return getTypedOrNull(data => ({
    ...data,
    workingStartDate: getTypedOrNull(toDate, data.workingStartDate),
    workingEndDate: null
  }), employmentDetails)
}

const getPreviousEmployment = (
  data: APIPreviousEmploymentDetails | null
): EmploymentDetails | null => {
  const employmentDetails = getCurrentEmployment(data)

  if ((employmentDetails == null) || (data == null)) {
    return null
  }

  return {
    ...employmentDetails,
    workingEndDate: getTypedOrNull(toDate, data.workingEndDate)
  }
}

const getFullAddressData = ({ address, city, zipCode, state, county, apt }: AddressDetails): AddressData => {
  return {
    addressLong: address,
    address,
    city,
    zipCode,
    state,
    county,
    apt
  }
}

const getCurrentAddress = (
  addressDetails: APICurrentAddressDetails | null
): CurrentAddressDetails | null => {
  if (addressDetails == null) {
    return null
  }

  return getTypedOrNull(data => {
    return {
      ...data,
      apartmentMoveInDate: getTypedOrNull(toDate, data.apartmentMoveInDate),
      addressData: getFullAddressData(data)
    }
  }, addressDetails)
}

const getPreviousAddress = (
  addressDetails: APIPreviousAddressDetails | null
): PreviousAddressDetails | null => {
  if (addressDetails == null) {
    return null
  }

  return getTypedOrNull(data => {
    return {
      ...data,
      apartmentMoveInDate: getTypedOrNull(toDate, data.apartmentMoveInDate),
      apartmentMoveOutDate: getTypedOrNull(toDate, data.apartmentMoveOutDate),
      addressData: getFullAddressData(data)
    }
  }, addressDetails)
}

const parseCoverageDetails = (coverageDetails: CoverageDetails[]): CoverageDetailsSection[] => {
  return coverageDetails.map(item => {
    const isManual = isManualCoverage(item)

    return {
      coverageType: isManual ? 'Manual' : 'PEN',
      forms: {
        PEN: isManual ? null : { ...item },
        Manual: isManual ? { ...item } : getDefaultManualFormData(item)
      },
      productName: item.productName ?? '',
      productType: item.productType ?? '' // AZ-TODO: check API types, it should not be nullable
    }
  })
}
