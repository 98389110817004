import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGWarrantyTireAndWheel: FC<IconProps> = ({ color = '#458FFF', width = 28, height = 28 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 28 28' fill='none'>
      <g clipPath="url(#clip0_87888_128052)">
        <path
          d="M9.06094 0C9.06094 9.30234 9.06094 18.6047 9.06094 27.9234C8.51953 27.9234 7.98906 27.9234 7.42578 27.9234C7.42578 18.6156 7.42578 9.30781 7.42578 0C7.96719 0 8.51406 0 9.06094 0Z"
          fill={color} />
        <path
          d="M12.0117 0C12.2305 0.0546875 12.4492 0.103906 12.6625 0.164062C14.3578 0.650781 15.5883 2.23672 15.6047 3.97031C15.6047 4.07969 15.5555 4.23281 15.4734 4.2875C13.9258 5.34844 12.3672 6.40391 10.8086 7.45391C10.7867 7.47031 10.7594 7.47031 10.6992 7.49766C10.6992 4.9875 10.6992 2.49375 10.6992 0C11.1367 0 11.5742 0 12.0117 0Z"
          fill={color} />
        <path
          d="M5.78634 0C5.78634 2.48828 5.78634 4.97656 5.78634 7.49766C5.71525 7.45938 5.65509 7.44297 5.61134 7.41016C4.09103 6.38203 2.57619 5.34844 1.05587 4.32578C0.919156 4.23281 0.869937 4.13438 0.875406 3.97031C0.90275 2.07266 2.37384 0.377344 4.2715 0.0546875C4.34259 0.04375 4.40822 0.0164063 4.47384 0C4.91134 0 5.34884 0 5.78634 0Z"
          fill={color} />
        <path
          d="M15.8164 27.6676C16.068 27.2848 16.3305 26.9293 16.5492 26.5465C17.0141 25.7316 17.2328 24.8512 17.2437 23.916C17.2492 23.3691 17.2547 22.8223 17.2383 22.2809C17.2328 22.0785 17.3039 21.9855 17.4789 21.8871C19.9234 20.4762 21.4656 18.3926 21.9961 15.6145C22.7234 11.8301 20.9352 8.0457 17.5664 6.16992C17.3203 6.0332 17.2328 5.89102 17.2383 5.60664C17.2492 4.89023 17.2547 4.16836 17.1945 3.45195C17.107 2.38008 16.675 1.43945 16.0187 0.591797C15.9477 0.498828 15.8766 0.405859 15.7617 0.263672C17.6594 0.624609 19.3438 1.32461 20.9023 2.36367C22.4609 3.40273 23.7297 4.72617 24.7469 6.33945C23.8281 6.86992 22.9094 7.40039 21.9633 7.95273C22.2367 8.42305 22.4992 8.88242 22.7781 9.36914C23.7133 8.8332 24.632 8.30273 25.5781 7.75586C27.5797 11.934 27.5523 16.0848 25.5836 20.2684C24.6375 19.7215 23.7187 19.1965 22.7781 18.6551C22.5047 19.1309 22.2367 19.5902 21.9633 20.0715C22.8984 20.6129 23.8172 21.1434 24.7305 21.6738C23.3305 24.3645 18.6328 27.5254 15.8164 27.6676Z"
          fill={color} />
        <path
          d="M10.6967 27.9437C10.6967 26.9977 10.6967 26.0898 10.6967 25.182C10.6967 24.4109 10.6912 23.6398 10.7021 22.8633C10.7021 22.7648 10.7514 22.6227 10.8279 22.5734C12.3865 21.5234 13.9506 20.4844 15.5146 19.4398C15.5311 19.4289 15.5475 19.4289 15.5912 19.418C15.5967 19.5 15.6076 19.5711 15.6076 19.6477C15.6076 21.0367 15.6131 22.4258 15.6076 23.8203C15.5967 26.1281 13.7975 27.9273 11.4896 27.9437C11.2381 27.9437 10.9865 27.9437 10.6967 27.9437Z"
          fill={color} />
        <path
          d="M5.775 27.9195C3.01328 28.2477 1.31797 26.4922 0.957031 24.6711C0.924219 24.4906 0.880469 24.3102 0.880469 24.1297C0.875 22.5656 0.875 21.0016 0.875 19.4102C0.951563 19.4484 1.01172 19.4703 1.06641 19.5086C2.57578 20.5148 4.09063 21.5211 5.59453 22.5383C5.68203 22.5984 5.76953 22.7297 5.76953 22.8281C5.78047 24.4797 5.775 26.1367 5.775 27.7883C5.78594 27.8375 5.78047 27.8813 5.775 27.9195Z"
          fill={color} />
        <path
          d="M5.78359 20.6844C5.45547 20.4656 5.17656 20.2797 4.89766 20.0938C3.62891 19.2461 2.36016 18.3984 1.08594 17.5563C0.932813 17.4578 0.867188 17.3539 0.867188 17.1625C0.872656 15.8008 0.872656 14.4391 0.872656 13.0719C0.872656 12.9898 0.872656 12.9133 0.872656 12.793C0.971094 12.8477 1.03672 12.8805 1.10234 12.9242C2.58984 13.9141 4.07734 14.9094 5.57031 15.8992C5.72344 15.9977 5.78906 16.1016 5.78906 16.293C5.77812 17.6656 5.78359 19.0383 5.78359 20.4109C5.78359 20.4875 5.78359 20.5586 5.78359 20.6844Z"
          fill={color} />
        <path
          d="M15.61 12.8008C15.61 13.6758 15.61 14.5234 15.61 15.3656C15.61 15.9836 15.6154 16.6016 15.6045 17.2195C15.6045 17.3125 15.5553 17.4383 15.4842 17.4875C13.9146 18.543 12.3396 19.5875 10.7701 20.632C10.7646 20.6375 10.7537 20.632 10.7209 20.6375C10.7154 20.5719 10.7045 20.5062 10.7045 20.4406C10.7045 19.0516 10.699 17.657 10.71 16.268C10.71 16.1641 10.7701 16.0164 10.8521 15.9562C12.3943 14.9172 13.9475 13.8836 15.5006 12.85C15.517 12.8391 15.5443 12.8281 15.61 12.8008Z"
          fill={color} />
        <path
          d="M0.872656 6.22461C1.23359 6.46523 1.53984 6.66758 1.85156 6.87539C3.09297 7.70117 4.32891 8.53242 5.57031 9.35273C5.72344 9.45117 5.78906 9.55508 5.78906 9.74102C5.77812 11.0973 5.78359 12.448 5.78359 13.8043C5.78359 13.8754 5.78359 13.9465 5.78359 14.0613C5.67969 14.0012 5.60312 13.9574 5.53203 13.9137C4.05 12.9238 2.56797 11.934 1.08594 10.9551C0.938281 10.8566 0.867188 10.7582 0.867188 10.5668C0.878125 9.21055 0.872656 7.85977 0.872656 6.50352C0.872656 6.42695 0.872656 6.35586 0.872656 6.22461Z"
          fill={color} />
        <path
          d="M15.6076 6.26562C15.6076 7.46328 15.6076 8.64453 15.6076 9.82578C15.6076 10.1539 15.6951 10.5258 15.5584 10.7883C15.4271 11.0398 15.0662 11.1656 14.8037 11.3461C13.5295 12.1992 12.2498 13.0469 10.9701 13.9C10.8936 13.9492 10.817 13.993 10.7131 14.0586C10.7076 13.9766 10.6967 13.9164 10.6967 13.8617C10.6967 12.4781 10.6912 11.1 10.7021 9.71641C10.7021 9.61797 10.7514 9.47578 10.8279 9.42109C12.3865 8.37109 13.9506 7.32656 15.5201 6.2875C15.5311 6.27656 15.5529 6.27656 15.6076 6.26562Z"
          fill={color} />
        <path
          d="M19.1068 18.4117C18.4889 18.0563 17.9365 17.7391 17.3951 17.4164C17.324 17.3727 17.2529 17.2687 17.2529 17.1977C17.242 16.618 17.2475 16.0383 17.2475 15.3984C18.1389 15.9125 18.9975 16.4047 19.8998 16.9297C19.6373 17.4164 19.3748 17.9086 19.1068 18.4117Z"
          fill={color} />
        <path
          d="M19.9094 11.018C19.007 11.5375 18.1484 12.0352 17.2461 12.5547C17.2461 11.9258 17.2461 11.3461 17.2516 10.7664C17.2516 10.7172 17.2953 10.6406 17.3391 10.6187C17.9078 10.2852 18.482 9.95703 19.0945 9.60156C19.368 10.0773 19.6305 10.5367 19.9094 11.018Z"
          fill={color} />
        <path
          d="M20.3963 12.6328C20.5713 13.5461 20.5713 14.4047 20.3854 15.307C19.8932 15.0227 19.4283 14.7547 18.9635 14.4812C18.9252 14.4594 18.8869 14.3992 18.8869 14.3555C18.8814 14.1039 18.8814 13.8469 18.8869 13.5953C18.8869 13.5516 18.9252 13.4914 18.9635 13.4695C19.4229 13.1906 19.8932 12.9227 20.3963 12.6328Z"
          fill={color} />
        <path
          d="M17.2695 7.89258C17.4992 8.05117 17.707 8.19336 17.9531 8.36289C17.7125 8.50508 17.5102 8.61992 17.2695 8.75664C17.2695 8.4668 17.2695 8.2043 17.2695 7.89258Z"
          fill={color} />
        <path
          d="M17.9477 19.6289C17.7016 19.8039 17.4992 19.9406 17.2695 20.1047C17.2695 19.8094 17.2695 19.5469 17.2695 19.2461C17.4883 19.3719 17.6961 19.4922 17.9477 19.6289Z"
          fill={color} />
      </g>

      <defs>
        <clipPath id='clip0_87888_128052'>
          <rect width={width} height={height} fill='white'/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGWarrantyTireAndWheel
