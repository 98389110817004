import { type RespondOnLeadTaskFormData, TaskCompleteOptions, TaskType } from 'pages/crm/LeadDetailsView/hook/types'

export const getDefaultFormData = (): RespondOnLeadTaskFormData => ({
  taskCompleteOptionId: null,
  workNotes: null
})

export enum FieldIds {
  TaskCompleteOptionId = 'taskCompleteOptionId',
  Notes = 'workNotes'
}

export const RADIO_OPTIONS = [
  {
    id: TaskCompleteOptions.SpokeToCustomer,
    name: 'Spoke to customer',
    taskTypeId: TaskType['Web lead'],
    label: 'Spoke to customer',
    value: TaskCompleteOptions.SpokeToCustomer
  },
  {
    id: TaskCompleteOptions.SentEmail,
    name: 'Sent email',
    taskTypeId: TaskType['Web lead'],
    label: 'Sent email',
    value: TaskCompleteOptions.SentEmail
  },
  {
    id: TaskCompleteOptions.SentMessage,
    name: 'Left message',
    taskTypeId: TaskType['Web lead'],
    label: 'Left message',
    value: TaskCompleteOptions.SentMessage
  }
]
