import { css } from '@emotion/css'

export default css(`
  .cf-form-title {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.38);
  }

  .cf-document-field {
    width: 50%;
  }
  
  .cf-banner-appeared {
    &:after {
      content: '';
      height: 32px;
    }
  }
`)
