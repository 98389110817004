import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGUploadFile: FC<IconProps> = ({ color = '#101010', width = 24, height = 24 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'>
      <path
        d='M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C3.46957 19 2.96086 18.7893 2.58579 18.4142C2.21071 18.0391 2 17.5304 2 17V12ZM12 2L6.46 7.46L7.88 8.88L11 5.75V15H13V5.75L16.13 8.88L17.55 7.45L12 2Z'
        fill={color}
      />
    </svg>
  )
}

export default IconSVGUploadFile
