import { useMemo, type FC } from 'react'
import { FormNumberInput, FormDropdown, Loader } from '@carfluent/common'

import formatPackageOption from 'utils/coverage/formatPackageOption'
import getTermDescription from 'utils/coverage/getTermDescription'
import formatDeductibleOption from 'utils/coverage/formatDeductibleOption'
import findCoverage from 'utils/coverage/findCoverages'
import findDeductible from 'utils/coverage/findDeductible'
import { MAX_PRICE_VALUE } from 'constants/validation'
import type { CoverageProvider, CoverageWithTerms } from 'types/coverage'

import { type CoveragePenFormProps } from './types'
import { FIELD_MARGIN } from './styles'
import isFieldSupported from 'utils/coverage/isFieldSupported'

const CoveragePenForm: FC<CoveragePenFormProps> = ({
  disabled = false,
  errors,
  fieldPathPrefix,
  onBlur,
  onChange,
  product,
  touched,
  values
}) => {
  if (product == null) {
    return <Loader /> // waiting for dealer products
  }

  const isCostVisible = isFieldSupported(values?.productTypeId, 'PEN', 'cost', true)
  const isTermPenVisible = isFieldSupported(values?.productTypeId, 'PEN', 'termMonths', true)
  const isDeductiblePenVisible = isFieldSupported(values?.productTypeId, 'PEN', 'deductibleAmount', true)

  const filteredVendors = useMemo(() => {
    return product.filter(el => el.coverages.length > 0)
  }, [product])

  const selectedVendor = useMemo(() => {
    return product.find(el => el.id === values?.providerId)
  }, [product, values?.providerId])

  const currentPackage = useMemo(() => {
    return selectedVendor?.coverages.find(el => el.coverageName === values?.coverageName) ?? null
  }, [selectedVendor?.coverages, values?.coverageName])

  const currentCoverage = useMemo(() => {
    return findCoverage(values?.termMiles, values?.termMonths, currentPackage)
  }, [values?.termMiles, values?.termMonths, currentPackage])

  const deductibles = useMemo(() => {
    return currentCoverage?.deductibles ?? []
  }, [currentCoverage?.deductibles, values])

  const currentDeductible = useMemo(() => {
    return findDeductible(values?.deductibleId, deductibles) ?? null
  }, [values?.deductibleId, deductibles])

  return (
    <>
      <FormDropdown
        mode='search'
        label='Vendor'
        onBlur={onBlur}
        value={selectedVendor}
        dataTestId='pen-vendor'
        options={filteredVendors}
        className={FIELD_MARGIN}
        disabled={disabled}
        id={`${fieldPathPrefix}.providerId`}
        error={errors?.providerId}
        touched={touched?.providerId}
        onChange={(_, value: CoverageProvider | null) => {
          const selectedCoverage = value?.coverages[0]
          const deductible = selectedCoverage?.termList[0]?.deductibles?.[0]
          const cost = deductible?.dealerCost
          const totalPrice = deductible?.dealerRetailPrice
          const deductibleAmount = deductible?.amount

          onChange(fieldPathPrefix, {
            ...values,
            cost: value == null ? null : cost,
            deductibleAmount: value == null ? null : deductibleAmount,
            totalPrice: value == null ? null : totalPrice,
            providerId: value == null ? null : value.id,
            isFormDisabled: value == null,
            coverageName: value == null ? null : selectedCoverage?.coverageName,
            termMiles: value == null ? null : selectedCoverage?.termList[0].termMiles,
            termMonths: value == null ? null : selectedCoverage?.termList[0].termMonths,
            deductibleId: value == null ? null : deductible?.id,
            productCoverageId: value == null ? null : selectedCoverage?.termList[0].id
          })
        }}
      />

      <FormDropdown
        className={FIELD_MARGIN}
        disableClearable
        disabled={disabled}
        id={`${fieldPathPrefix}.coverageName`}
        label='Type'
        onBlur={onBlur}
        value={currentPackage}
        error={errors?.coverageName}
        touched={touched?.coverageName}
        options={selectedVendor?.coverages ?? []}
        formatDisplayValue={formatPackageOption}
        renderOption={formatPackageOption}
        onChange={(_, value: CoverageWithTerms | null) => {
          const selectedCoverage = value?.termList[0]
          const defaultDeductible = selectedCoverage?.deductibles?.[0]

          onChange(fieldPathPrefix, {
            ...values,
            coverageName: value == null ? '' : value?.coverageName,
            dealerCost: value == null ? null : defaultDeductible?.dealerCost,
            dealerRetailPrice: value == null ? null : defaultDeductible?.dealerRetailPrice,
            deductibleId: value == null ? null : defaultDeductible?.id,
            deductibleDescription: value == null ? null : defaultDeductible?.description,
            deductibleAmount: value == null ? null : defaultDeductible?.amount,
            termMonths: value == null ? null : selectedCoverage?.termMonths,
            termMiles: value == null ? null : selectedCoverage?.termMiles
          })
        }}
      />

      {isTermPenVisible && (
        <FormDropdown
          className={FIELD_MARGIN}
          disabled={disabled}
          disableClearable
          onBlur={onBlur}
          id={`${fieldPathPrefix}.termMonths`}
          label='Term'
          value={currentCoverage}
          error={errors?.termMonths}
          touched={touched?.termMonths}
          options={currentPackage?.termList ?? []}
          formatDisplayValue={getTermDescription}
          renderOption={getTermDescription}
          onChange={(_, value) => {
            const defaultDeductible = value?.deductibles?.[0]

            onChange(fieldPathPrefix, {
              ...values,
              dealerCost: value == null ? null : defaultDeductible?.dealerCost,
              dealerRetailPrice: value == null ? null : defaultDeductible?.dealerRetailPrice,
              deductibleId: value == null ? null : defaultDeductible?.id,
              deductibleDescription: value == null ? null : defaultDeductible?.description,
              deductibleAmount: value == null ? null : defaultDeductible?.amount,
              termMonths: value == null ? null : value?.termMonths,
              termMiles: value == null ? null : value?.termMiles
            })
          }}
        />
      )}

      {isDeductiblePenVisible && (
        <FormDropdown
          className={FIELD_MARGIN}
          disabled={disabled}
          disableClearable
          id={`${fieldPathPrefix}.deductibleAmount`}
          label='Deductible'
          onBlur={onBlur}
          error={errors?.deductibleAmount}
          touched={touched?.deductibleAmount}
          value={currentDeductible}
          options={deductibles ?? []}
          formatDisplayValue={formatDeductibleOption}
          renderOption={formatDeductibleOption}
          onChange={(_, value) => {
            onChange(fieldPathPrefix, {
              ...values,
              dealerCost: value == null ? null : value?.dealerCost,
              dealerRetailPrice: value == null ? null : value?.dealerRetailPrice,
              deductibleAmount: value == null ? null : value?.amount,
              deductibleDescription: value == null ? null : value?.description,
              deductibleId: value == null ? null : value?.id
            })
          }}
        />
      )}

      {
        isCostVisible &&
          <>
            <FormNumberInput
              className={FIELD_MARGIN}
              disabled={disabled}
              error={errors?.totalPrice}
              id={`${fieldPathPrefix}.totalPrice`}
              label='Total price'
              preset='financial'
              isNegativeAllowed={false}
              onBlur={onBlur}
              onChange={onChange}
              max={MAX_PRICE_VALUE}
              value={values?.totalPrice ?? ''}
              touched={touched?.totalPrice ?? false}
            />

            <FormNumberInput
              className={FIELD_MARGIN}
              disabled
              error={errors?.cost}
              id={`${fieldPathPrefix}.dealerCost`}
              isNegativeAllowed={false}
              label='Costs'
              max={MAX_PRICE_VALUE}
              preset='financial'
              onBlur={onBlur}
              onChange={onChange}
              touched={touched?.cost}
              value={values?.cost ?? ''}
            />
          </>
      }
    </>
  )
}

export default CoveragePenForm
