import { FC, ReactNode, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { cn } from '@carfluent/common'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

import DialogTitle from './components/dialog_title'
import FooterActionsPanel from './components/footer_actions_panel'
import { useStyles, StylesConfig } from './styles'

export interface DialogProps {
  classes?: {
    paper?: string
    root?: string
    title?: string
    container?: string
  }
  config?: StylesConfig
  isCloseIconVisible?: boolean
  open: boolean
  title?: string
  popupDataTestId?: string
  titleDataTestId?: string
  renderFooterActions?: () => ReactNode | ReactNode[]
  onClose?: (event?: object, reason?: string) => void
}

const ModalWindow: FC<DialogProps> = ({
  children,
  classes,
  config,
  isCloseIconVisible,
  open,
  popupDataTestId,
  titleDataTestId,
  onClose,
  renderFooterActions,
  title
}) => {
  const nodeRef = useRef()
  const withTitle = (title != null) || (onClose != null)
  const showActions = renderFooterActions != null

  const styles = useStyles({
    ...config,
    withTitle,
    isClosable: onClose != null
  })

  const dialogClasses = {
    paper: cn(styles.dialogPaper, classes?.paper),
    root: cn(styles.root, classes?.root),
    container: classes?.container
  }

  const titleClasses = { root: cn(styles.dialogTitle, classes?.title) }

  return (
    <Dialog
      fullWidth
      onClose={onClose}
      open={open}
      classes={dialogClasses}
      maxWidth={false}
      scroll='body'
      disableEnforceFocus
      ref={nodeRef}
      data-test-id={popupDataTestId}
    >
      {withTitle && (
        <DialogTitle
          classes={titleClasses}
          isCloseIconVisible={isCloseIconVisible}
          onClose={onClose}
          titleDataTestId={titleDataTestId}
        >
          {title}
        </DialogTitle>
      )}

      <DialogContent className={styles.dialogContent}>
        {children}
      </DialogContent>

      {showActions && (
        <FooterActionsPanel>
          {renderFooterActions?.()}
        </FooterActionsPanel>
      )}
    </Dialog>
  )
}

export default observer(ModalWindow)
