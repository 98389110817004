import { useCallback, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

import { type VehicleStateResponse, DealState } from 'api/types'
import { type BaseListItem } from 'types'
import CustomersCoreApiProvider from 'api/customersCore.api'
import VehiclesApiProvider from 'api/vehicles.api'
import useAsyncEffect from 'hooks/useAsyncEffect'
import { NOT_FOUND_STATUS, Routes } from 'constants/route_helper'
import { getParsedToken } from 'services/storage.service'
import SettingsCTX from 'store/settings'
import getDepositedCarId from 'utils/deals/getDepositedCarId'
import { filterCoApplicantTypes } from 'utils/creditApplication'
import {
  type DealFeesAndCoveragesSettingsModel,
  type DealModel,
  type SalesCommissionModel
} from 'api/types/responses'

import { TabsId } from './constants'

export interface UseDealDetailsReturn {
  coApplicantTypes: BaseListItem[]
  dealId: string
  dealerId: string
  deal: DealModel | null
  depositedCarId: number | null
  feesAndCoveragesSettings: DealFeesAndCoveragesSettingsModel | null
  isDealApproved: boolean
  isDealCompleted: boolean
  isLoading: boolean
  linkToWebsite?: string
  loadDeal: (dealId: string | number) => Promise<void>
  loadSalesCommission: (dealId: string | number) => Promise<SalesCommissionModel>
  nextTab: { id: number }
  onApproveDeal: () => void
  onResetTabNavigation: () => void
  onSaveChanges: (deal: DealModel) => void
  onSelectTab: (tabId: number) => void
  onSelectDealFormsTab: () => void
  onTrySwitchTab: () => void
  salesCommission: SalesCommissionModel | null
  selectedTabId: number
  updateDealStatus: (state: string, stateId: number) => void
  updateDeal: (fieldIdOrSubform: string | Partial<DealModel>, value?: any) => void
  vehicleInfo: VehicleStateResponse | null
}

const isBlockableTab = (tabId: number): boolean => {
  return [
    TabsId.Deal,
    TabsId.BuyerCobuyer,
    TabsId.Vehicles,
    TabsId.DealFinances
  ].includes(tabId)
}

const getCoApplicantTypes = async (customerState: string | null): Promise<BaseListItem[]> => {
  const res = await CustomersCoreApiProvider.getCoApplicantTypes()
  return filterCoApplicantTypes(res, customerState)
}

const useDealDetails = (): UseDealDetailsReturn => {
  const navigate = useNavigate()

  const token = getParsedToken()
  const dealerId: string = token?.dealer ?? ''
  const { id: dealId = '' } = useParams<{ id: string }>()

  const { dealer } = useContext(SettingsCTX)

  const [isLoading, setIsLoading] = useState(true)
  const [deal, setDeal] = useState<DealModel | null>(null)
  const [isDealApproved, setIsDealApproved] = useState(false)
  const [vehicleInfo, setVehicleInfo] = useState<VehicleStateResponse | null>(null)
  const [depositedCarId, setDepositedCarId] = useState<number | null>(null)
  const [coApplicantTypes, setCoApplicantTypes] = useState<BaseListItem[]>([])
  const [selectedTabId, setSelectedTabId] = useState(0)
  const isDealCompleted = deal?.dealStateId === DealState.Completed

  const [feesAndCoveragesSettings, setFeesAndCoveragesSettings] =
    useState<DealFeesAndCoveragesSettingsModel | null>(null)

  const [salesCommission, setSalesCommission] =
    useState<SalesCommissionModel | null>(null)

  // DD-NOTE: nextTabId is an object to always trigger intention to switch
  const [nextTab, setNextTab] = useState({ id: 0 })

  const linkToWebsite = `${dealer?.dealerFeeMarkupSettings.websiteBaseUrl ?? ''}dealer/${dealerId}`

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const updateDealStatus = useCallback((state: string, stateId: number): void => {
    setDeal((prevDeal) => {
      if (prevDeal == null) {
        return null
      }

      return {
        ...prevDeal,
        dealState: state,
        dealStateId: stateId
      }
    })
  }, [])

  const updateDeal = useCallback((fieldIdOrSubform: string | Partial<DealModel>, value?: any): void => {
    setDeal((prevDeal) => {
      if (prevDeal == null) {
        return null
      }

      const base = { ...prevDeal }

      if (typeof fieldIdOrSubform === 'object') {
        return { ...base, ...fieldIdOrSubform }
      }

      return {
        ...base,
        [fieldIdOrSubform]: value
      }
    })
  }, [])

  const onSaveChanges = useCallback(async (deal: DealModel) => {
    setDeal(deal)

    if (nextTab.id !== selectedTabId) {
      setSelectedTabId(nextTab.id)
    }
  }, [nextTab, selectedTabId])

  const onTrySwitchTab = useCallback(async () => {
    if (nextTab.id !== selectedTabId) {
      setSelectedTabId(nextTab.id)
    }
  }, [nextTab, selectedTabId])

  const onSelectTab = useCallback((tabId: number) => {
    setSelectedTabId(curr => isBlockableTab(curr) ? curr : tabId)
    setNextTab({ id: tabId })
  }, [])

  const onSelectDealFormsTab = useCallback(() => {
    onSelectTab(TabsId.DealForms)
  }, [onSelectTab])

  const loadSalesCommission = useCallback(async (dealId: string | number): Promise<SalesCommissionModel> => {
    const salesCommission = await CustomersCoreApiProvider.getDealSalesCommission(dealId)
    setSalesCommission(salesCommission)
    return salesCommission
  }, [])

  const loadDeal = useCallback(async (dealId: string | number): Promise<void> => {
    try {
      const [
        deal,
        feesAndCoveragesSettings
      ] = await Promise.all([
        CustomersCoreApiProvider.getDeal2(dealId),
        CustomersCoreApiProvider.getDealFeesSettings(dealId),
        loadSalesCommission(dealId)
      ])

      // DD-TODO: check if it is possible the deal to not have vehicleId
      const vehicleId = deal.vehicle?.id ?? null

      const [
        vehicleInfo,
        depositedCarId,
        coapplicantTypes
      ] = await Promise.all([
        vehicleId != null ? VehiclesApiProvider.getVehicleState(vehicleId) : null,
        vehicleId != null ? getDepositedCarId(dealId, vehicleId) : null,
        getCoApplicantTypes(deal.buyer.addressDetails.state)
      ])

      // DD-TODO: check if this would still be batched by react
      setFeesAndCoveragesSettings(feesAndCoveragesSettings)
      setDeal(deal)
      setVehicleInfo(vehicleInfo)
      setDepositedCarId(depositedCarId)
      setCoApplicantTypes(coapplicantTypes)
    } catch (err) {
      console.error(err)
      if (axios.isAxiosError(err) && err?.response?.status === NOT_FOUND_STATUS) {
        throw err
      }
    }
  }, [loadSalesCommission])

  const onApproveDeal = (): void => {
    setIsDealApproved(true)
  }

  const onResetTabNavigation = (): void => {
    setNextTab({ id: selectedTabId })
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useAsyncEffect(async (): Promise<void> => {
    try {
      setIsLoading(true)
      await loadDeal(dealId)
    } catch (err) {
      if (axios.isAxiosError(err) && err?.response?.status === NOT_FOUND_STATUS) {
        navigate(Routes.NoDealerAndVin)
      }
    } finally {
      setIsLoading(false)
    }
  }, [dealId, loadDeal, navigate])

  // ========================================== //

  return {
    coApplicantTypes,
    deal,
    dealId,
    dealerId,
    depositedCarId,
    feesAndCoveragesSettings,
    isDealApproved,
    isDealCompleted,
    isLoading,
    linkToWebsite,
    loadDeal,
    loadSalesCommission,
    nextTab,
    onApproveDeal,
    onResetTabNavigation,
    onSaveChanges,
    onSelectTab,
    onSelectDealFormsTab,
    onTrySwitchTab,
    salesCommission,
    selectedTabId,
    updateDealStatus,
    updateDeal,
    vehicleInfo
  }
}

export default useDealDetails
