import { css } from '@emotion/css'

export const UNSOLD_MODAL_CLASSES = css(`
  p {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #101010;
    min-width: 600px;
  }

  label {
    margin-top: 32px;
  }

  &.cf-message-dialog .cf-modal-footer {
    margin-top: 48px;

    .cf-button-root {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1.25px;
      color: #101010;
    }
  }
`)

export const CANNOT_UNSOLD_MODAL_CLASSES = css(`
  &.cf-modal-container .cf-modal-root {
    max-width: 480px;
    text-align: center;

    .cf-modal-title {
      grid-template-columns: 1fr;
    }

    .cf-modal-content {
      padding: 32px 80px 0;
    }
  }
  p {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #101010;
  }

  &.cf-message-dialog .cf-modal-footer {
    margin-top: 48px;

    .cf-button-root {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1.25px;
      color: #101010;
      width: calc(100% - 80px);
    }
  }
`)

export const HELPER_TEXT_CLASSES = css(`
  color: rgba(33, 33, 33, 0.50);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
`)

export const CONTAINER_CLASSES = css(`
  .banner-container {
    &:has(.cf-banner-appeared){
      margin-bottom: 8px;
    }
  
    .cf-banner-appeared:not(:last-child) {
      &:after {
        height: 8px;
      }
    }

    .cf-banner-appeared:last-child) {
      &:after {
        height: 16px;
      }
    }
  }
    
  .date-info-title {
    max-width: fit-content;
    
    &.is-loading {
      min-width: 247px;
      height: 24px;
    }
  }

  position: relative;

  > .cf-loader {
    position: fixed;
    inset: 0px;
    left: 64px;
    z-index: 101!important;
    background-color: rgba(255, 255, 255, 0.75);
  }
`)
