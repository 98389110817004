import { css } from '@emotion/css'

export default css(`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  cursor: pointer;
  
  &.cf-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  
  .cf-radio-icon {
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.87);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
   
    ::after {
      content: "";
      width: 9px;
      height: 9px;
      background: rgba(0, 0, 0, 0.87);
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.3s;
    } 
    
    &.cf-checked {
      ::after {
        opacity: 1;
      }
    }
  }
`)
