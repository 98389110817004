import generateUniqueId from 'utils/generateUniqueId'
import type { ReconStepItemFormData, BaseValuesProps } from './types'

export const MAX_NAME_LENGTH = 32

export enum Messages {
  SuccessUpdate = 'Changes successfully saved.',
  ErrorUpdate = 'Failed to update'
}

export const enum DeleteModalInfo {
  Title = 'Delete this step from list',
  Content = 'Please note that cars on this step (if any) will be transferred to the very first reconditioning step after saving the changes.'
}

export const DEFAULT_STEP = (): ReconStepItemFormData => ({
  id: generateUniqueId(),
  order: 0,
  name: '',
  subscribers: [],
  isReadonly: false,
  usedInVehicle: null,
  daysInStepUntilFirstAlert: 0,
  daysInStepUntilSecondAlert: 0,
  daysInReconUntilFirstAlert: 0,
  daysInReconUntilSecondAlert: 0
})

export const DEFAULT_VALUES: BaseValuesProps = {
  reconSteps: []
}
