import { useCallback, FC, MouseEvent } from 'react'
import { cn } from '@carfluent/common'

import Icon, { IconProps } from 'components/common/Icon'

import CLASS_NAME from './styles'

export interface IconButtonProps extends IconProps {
  className?: string
  isDisabled?: boolean
  onClick?: (evt: MouseEvent<HTMLElement>) => void
  onExecute?: () => void
}

const IconButton: FC<IconButtonProps> = ({
  alt,
  className,
  isDisabled: disabled = false,
  name,
  onClick: _onClick
}) => {
  const onClick = useCallback((evt: MouseEvent<HTMLElement>) => {
    if (!disabled) {
      _onClick?.(evt)
    }
  }, [disabled, _onClick])

  return (
    <div
      className={cn(CLASS_NAME, 'cf-icon-button', disabled && 'cf-icon-button-disabled', className)}
      onClick={onClick}
    >
      <Icon alt={alt} name={name} />
    </div>
  )
}

export default IconButton
