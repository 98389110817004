import type { FC } from 'react'
import { formatCurrencyDecimal } from '@carfluent/common'

import type { BlockRendererProps } from 'components/wysiwyg/types'
import { PLACEHOLDER_CAR_URL } from 'api/defaults/assets'
import { linkToCar } from 'utils/website'
import { formatVehicleNameForView } from 'utils/view_helper'
import { CAR_BLOCK_START, CAR_BLOCK_END } from 'utils/wysiwyg'
import type { CarBlockData } from 'components/wysiwyg/toolbar/AddCarDropdown/types'
import closeIcon from 'assets/close_black.svg'
import { isStringEmpty } from 'utils/parse_string'

export const TABLE_CLASS_CONTAINER_NAME = 'cf-car-of-interest-table'
export const TABLE_TITLE_CLASS_NAME = 'cf-table-name-of-car'

const CONTENT_BLOCK_HEIGHT = '132px'
const IMG_WIDTH = '176px'

/**
 * This component is intentionally implemented using `table`, since
 * it should be rendered inside email.
 * Also, `style` is used by intention too (for the same reason).
 */
const CarBlock: FC<BlockRendererProps> = ({
  decoratedText = '',
  contentState,
  entityKey,
  blockKey,
  onRemove: _onRemove,
  isRemovable = true
}) => {
  let data: CarBlockData | null

  /**
   * The block's data can be extracted either from the decorated text, which is basically a serialied JSON,
   * or from metadata of the related Entity.
   */

  /**
   * Step 1: trying to get block data from the decorated text.
   */
  try {
    data = JSON.parse(decoratedText.replace(CAR_BLOCK_START, '').replace(CAR_BLOCK_END, ''))
  } catch (err) {
    data = null
  }

  /**
   * Step 2: trying to get block data from the entity metadata.
   */
  if ((data == null) && (contentState != null) && (entityKey != null)) {
    data = contentState.getEntity(entityKey).getData()
  }

  if (data == null) {
    return null
  }

  const { car, linkToWebsite } = data
  const linkToWebsiteCar = linkToCar(linkToWebsite, car) ?? '#'

  const onRemove = (): void => {
    if (!isRemovable || contentState == null || blockKey == null) {
      return
    }

    _onRemove?.(blockKey)
  }

  return (
    <div className={TABLE_CLASS_CONTAINER_NAME} style={{ marginBottom: '8px' }} data-car-info-block>
      <table
        contentEditable={false}
        cellPadding='0'
        cellSpacing='0'
        role='none'
        className='es-content'
        style={{
          borderCollapse: 'collapse',
          borderSpacing: '0px',
          tableLayout: 'fixed',
          maxWidth: '600px',
          width: '100%'
        }}
      >
        <tbody>
          <tr>
            <td
              align='left'
              style={{
                margin: '0px',
                padding: '10px 20px',
                backgroundColor: '#fafafa'
              }}
            >
              {/* CAR IMAGE */}
              <table
                cellPadding='0'
                cellSpacing='0'
                role='none'
                className='es-car-img'
                style={{
                  borderCollapse: 'collapse',
                  borderSpacing: '0px',
                  float: 'left'
                }}
              >
                <tbody>
                  <tr>
                    <td
                      align='left'
                      style={{ padding: '0px', margin: '0px', width: IMG_WIDTH, height: CONTENT_BLOCK_HEIGHT }}
                    >
                      <table
                        cellPadding='0' cellSpacing='0' width='100%' role='presentation'
                        style={{ borderCollapse: 'collapse', borderSpacing: '0px', height: '100%' }}
                      >
                        <tbody>
                          <tr>
                            <td align='center' style={{ padding: '0px', margin: '0px' }}>
                              <img
                                src={car.mainImageUrl ?? PLACEHOLDER_CAR_URL}
                                alt=''
                                data-car-img-block
                                className='es-adapt-img'
                                style={{
                                  display: 'block',
                                  border: '0px',
                                  outline: 'none',
                                  textDecoration: 'none',
                                  minWidth: IMG_WIDTH,
                                  minHeight: CONTENT_BLOCK_HEIGHT,
                                  maxHeight: CONTENT_BLOCK_HEIGHT,
                                  width: '100%',
                                  height: 'auto'
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* CAR INFO */}
              <table
                cellPadding='0'
                cellSpacing='0'
                role='none'
                className='es-car-info'
                style={{
                  borderCollapse: 'collapse',
                  borderSpacing: '0px',
                  float: 'right'
                }}
              >
                <tbody>
                  <tr>
                    <td align='left' style={{ padding: '0px', margin: '0px', width: '364px' }}>
                      <table
                        cellPadding='0' cellSpacing='0' width='100%' role='presentation'
                        style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}
                      >
                        <tbody>
                          <tr>
                            <td align='left' style={{ margin: '0px', width: '100%', padding: '0px 0px 4px 0px' }}>
                              <h4
                                className={TABLE_TITLE_CLASS_NAME}
                                style={{ margin: '0px 20px 0px 0px', color: '#101010', lineHeight: '24px', display: 'inline', fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif', fontWeight: 500, fontSize: '16px' }}
                                data-car-info-name={formatVehicleNameForView(car.year, car.make, car.model, car.trim)}
                              >
                                {formatVehicleNameForView(car.year, car.make, car.model, car.trim)}
                              </h4>
                              {
                                _onRemove != null && isRemovable && (
                                  <img
                                    style={{
                                      float: 'right',
                                      cursor: 'pointer',
                                      marginTop: '3px'
                                    }}
                                    onClick={onRemove}
                                    src={closeIcon}
                                    alt='close_icon'
                                  />
                                )
                              }
                            </td>
                          </tr>
                          <tr>
                            <td align='left' style={{ padding: '0px 0px 4px 0px', margin: '0px' }}>
                              <p style={{ margin: '0px', fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif', lineHeight: '24px', color: '#101010', fontSize: '16px' }}>
                                {formatCurrencyDecimal(car.salePrice, { mantissa: 0, emptyValues: '-' })}
                                <span style={{ color: '#B6B6B6' }}>&nbsp;&nbsp;|&nbsp;&nbsp;{formatCurrencyDecimal(car.mileage, { mantissa: 0, postfix: ' mi', prefix: '' })}</span>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td align='left' style={{ padding: '0px', margin: '0px' }}>
                              <p style={{ margin: '0px', fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif', lineHeight: '20px', color: '#101010', fontSize: '14px' }}>
                                <span style={{ fontSize: '14px', color: '#B6B6B6' }}>Transmission:&nbsp;</span>{isStringEmpty(car.transmissionType) ? '-' : car.transmissionType}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td align='left' style={{ padding: '0px', margin: '0px' }}>
                              <p style={{ margin: '0px', fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif', lineHeight: '20px', color: '#101010', fontSize: '14px' }}>
                                <span style={{ fontSize: '14px', color: '#B6B6B6' }}>Engine:&nbsp;</span>{isStringEmpty(car.engine) ? '-' : car.engine}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td align='left' style={{ padding: '8px 0px 0px 0px', margin: '0px' }}>
                              <a
                                href={linkToWebsiteCar}
                                rel='noreferrer'
                                target='_blank'
                                className='es-button'
                                style={{
                                  color: '#101010',
                                  fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
                                  fontSize: '14px',
                                  textDecoration: 'none',
                                  fontWeight: 400,
                                  lineHeight: '20px',
                                  textAlign: 'center',
                                  letterSpacing: '0.5px',
                                  display: 'inline-block',
                                  padding: '3px 24px',
                                  border: 'solid 1px #101010',
                                  background: '#fafafa',
                                  borderRadius: '8px',
                                  width: 'auto'
                                }}
                              >
                                VIEW DETAILS
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default CarBlock
