import { type FC, type ReactNode, type FocusEvent, useState, useRef } from 'react'
import { cnx, noop } from '@carfluent/common'
import CheckedIcon from './CheckedIcon'

/**
 * DD-TODO: move to Common Lib
 */

export interface CheckboxProps {
  id?: string
  className?: string
  label?: ReactNode
  isDisabled?: boolean
  onChange?: (val: boolean) => void
  onBlur?: (e: FocusEvent<HTMLElement>) => void
  value?: boolean
  defaultValue?: boolean
  dataTestId?: string
}

const Checkbox: FC<CheckboxProps> = ({
  id,
  className,
  label,
  value,
  defaultValue,
  isDisabled = false,
  onChange,
  onBlur,
  dataTestId
}) => {
  const refEl = useRef<HTMLDivElement | null>(null)
  const isControlled = value !== undefined
  /**
   * we duplicate value into local state so that we could see changes immediately.
   * So-called optimistic update.
   */
  const [checked, setChecked] = useState(value ?? defaultValue ?? false)

  const onClick = (): void => {
    if (isDisabled) {
      return
    }
    if (isControlled) {
      onChange?.(!value)
    } else {
      setChecked(prevChecked => {
        // Schedule the onChange callback to run after the state update
        // to avoid potential warnings with state updates during render.
        setTimeout(() => onChange?.(!prevChecked), 0)
        return !prevChecked
      })
    }

    refEl.current?.blur()
  }

  /**
   * if controlled, use value from props
   */
  const resolvedValue = isControlled ? value : checked

  return (
    <div
      id={id}
      ref={refEl}
      data-test-id={dataTestId}
      className={cnx(
        'g-checkbox',
        resolvedValue && 'is-checked',
        isDisabled && 'is-disabled',
        className
      )}
      onClick={onClick}
      onBlur={onBlur}
      tabIndex={0}
      role='checkbox'
    >
      <div className='checkmark'>
        <CheckedIcon />
      </div>
      <span className='checkmark-label'>{label}</span>
    </div>
  )
}

export default Checkbox
