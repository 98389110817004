import type { FC } from 'react'
import { Skeleton } from '@material-ui/lab'
import { CellWrapper } from '@carfluent/common'

import CLASS_NAME from './styles'

const CircleImageWithLine: FC = () => {
  return (
    <CellWrapper className={CLASS_NAME}>
      <Skeleton className='cell-image' variant='circle' />
      <Skeleton className='basic-skeleton' variant='text' />
    </CellWrapper>
  )
}

export default CircleImageWithLine
