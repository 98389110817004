import { type FC, type ReactNode, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Button, cnx, PRESET_LABELS, type Preset, RangePickerMode } from '@carfluent/common'

import FiltersPanel, { type FilterItem, type FilterType } from 'components/common/FiltersPanel'
import TopPanelActions from 'components/common/TopPanelActions'

import type { DateType } from 'api/types'
import { asyncNoop } from 'constants/constants'

import { DEFAULT_PRESETS } from './utils'
import createStyleClass from './styles'

export interface ReportLayoutProps {
  children?: ReactNode | ReactNode[]
  additionalFiltersNodes?: ReactNode
  className?: string
  initialPresetName?: PRESET_LABELS | string
  isDateFilterEnabled?: boolean
  onChangeDatesFilter?: (period: DateType) => Promise<void>
  onExportExcel?: () => Promise<void>
  presets?: Preset[]
  rootClassName?: string
  size?: 'sm' | 'md' | 'lg'
  title: string
  titleDateFilter?: string
  withChildOutsideContainer?: boolean
  monthsNumber?: number
  rangePickerMode?: RangePickerMode
  presetLabel?: string
  excludePresets?: PRESET_LABELS[]
}

const ReportLayout: FC<ReportLayoutProps> = ({
  children,
  className,
  initialPresetName,
  onChangeDatesFilter = asyncNoop,
  onExportExcel,
  rootClassName = '',
  presets = DEFAULT_PRESETS,
  excludePresets = [],
  title,
  titleDateFilter,
  size = 'md',
  additionalFiltersNodes,
  presetLabel,
  /**
   * OP-NOTE: Use this prop to be able to display children at their full width
   */
  withChildOutsideContainer = false,
  isDateFilterEnabled = true,
  monthsNumber = 2,
  rangePickerMode
}) => {
  const styles = useMemo(createStyleClass, [])
  const navigate = useNavigate()
  const filteredPresets = useMemo(() => {
    return presets.filter(el => !excludePresets.includes(el.name as PRESET_LABELS))
  }, [presets])

  const onGoBack = useCallback((): void => {
    navigate(-1)
  }, [navigate])

  const filterProps: Array<FilterItem<FilterType>> = []

  if (isDateFilterEnabled) {
    filterProps.push({
      filterType: 'date',
      componentProps: {
        monthsNumber,
        defaultPresetName: initialPresetName as PRESET_LABELS,
        presets: filteredPresets,
        notifyOnMount: true,
        rangePickerMode,
        onFilterChange: onChangeDatesFilter,
        title: titleDateFilter,
        presetLabel
      }
    })
  }

  if (additionalFiltersNodes != null) {
    filterProps.push({
      filterType: 'custom',
      componentProps: {
        render: () => additionalFiltersNodes
      }
    })
  }

  return (
    <>
      <div className={cnx(styles.pageWrapper, rootClassName)}>
        <div className={cnx(styles.reportContainer, `cf-${size}`)}>
          <TopPanelActions
            className='g-top-panel-actions g-top-panel-actions-small-height'
            backButtonName='back'
            onGoBack={onGoBack}
          />

          <div className='cf-report-top-actions g-full-width'>
            <div className='cf-top-wrapper'>
              <div className='cf-block'>
                <p className='cf-title'>{title}</p>
              </div>

              {onExportExcel != null && (
                <div className='cf-block'>
                  <Button
                    className='cf-link-button'
                    onClick={onExportExcel}
                    variant='text'
                  >
                    Export XLS
                  </Button>
                </div>
              )}
            </div>

            <div className='cf-filters-wrapper'>
              {
                filterProps.length > 0 && (
                  <FiltersPanel
                    componentBackground='gray'
                    filterProps={{ filters: filterProps }}
                  />
                )
              }
            </div>
          </div>

          <div className={cnx('cf-report-content', className)}>
            {!withChildOutsideContainer && children}
          </div>
        </div>

        {withChildOutsideContainer && children}
      </div>
    </>
  )
}

export default observer(ReportLayout)
