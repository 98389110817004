import {
  type SortingInfo,
  type Preset,
  UI,
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE
} from '@carfluent/common'
import { GET_DEFAULT_FILTERS } from 'constants/accounting'

import { type TransactionsListFilters } from './types'

export const enum Errors {
  Types = 'Types filters fetching failed'
}

export const DEFAULT_SORTING: SortingInfo = { id: 'date', order: 'desc' }

export const getPresets = (): Preset[] => {
  return UI.getDefaultDateRangePresets(DEFAULT_MIN_DATE, DEFAULT_MAX_DATE)
}

export const getDefaultFilters = (): TransactionsListFilters => {
  const baseFilters = GET_DEFAULT_FILTERS()

  return {
    ...baseFilters,
    skip: 0,
    take: 20,
    transactionTypeFilters: []
  }
}
