import { type FC } from 'react'
import { FormDatePicker, FormDropdown, FormInput, FormMaskedInput, cnx } from '@carfluent/common'
import AddressFields from 'components/common/AddressFields'
import InputSSN2 from 'components/form/components/InputSSN2'
import Collapse, { renderAddRemoveBtn } from 'components/common/Collapse'
import Checkbox from 'components/common/CheckboxNoMui'
import IconSVGPlus from 'components/inlineImages/IconSVGPlus'
import { isAddressDataEqual } from 'utils/address'
import type { CobuyerFormProps } from '../types'

const GET_COLLAPSE_BTN_TEXT = (): string => 'DELETE CO-BUYER'

const BuyerCobuyerTab: FC<CobuyerFormProps> = ({
  values, errors, touched, buyerAddress, isCobuyerSectionVisible, coApplicantTypes,
  onBlur, onChange, onToggleIsCobuyerVisible, isDisabled
}) => {
  const coApplicantType = coApplicantTypes.find((item) => item.id === values.coApplicantType) ?? null
  const sameAddressAsMainApplicant = isAddressDataEqual(
    buyerAddress,
    values.currentAddressDetails
  )

  return (
    <section className='tab-cobuyer-form'>
      <Collapse
        className='cobuyer'
        timeout={5} // intentionally small value, because title change is not animated
        title={
          isCobuyerSectionVisible
            ? 'Co-buyer'
            : (
              <h3
                className={cnx('toggle-cobuyer', isDisabled && 'disabled-toggle')}
                onClick={onToggleIsCobuyerVisible}
              >
                <IconSVGPlus color={isDisabled ? '#21212140' : '#101010'} /> Add Co-buyer
              </h3>
              )
        }
        isDisabled={isDisabled}
        isExpanded={isCobuyerSectionVisible}
        getBtnText={GET_COLLAPSE_BTN_TEXT}
        renderCollapseBtn={isCobuyerSectionVisible ? renderAddRemoveBtn : undefined}
        toggleIsExpanded={onToggleIsCobuyerVisible}
      >
        <FormInput
          id='firstName'
          label='First name'
          value={values.firstName}
          error={errors.firstName}
          touched={touched.firstName}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isDisabled}
        />
        <FormInput
          id='lastName'
          label='Last name'
          value={values.lastName}
          error={errors.lastName}
          touched={touched.lastName}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isDisabled}
        />
        <FormMaskedInput
          id='phoneNumber'
          label='Phone number'
          mask='phone'
          value={values.phoneNumber}
          error={errors.phoneNumber}
          touched={touched.phoneNumber}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isDisabled}
        />

        <FormDatePicker
          id='birthDate'
          label='Date of birth'
          value={values.birthDate}
          error={errors.birthDate}
          touched={touched.birthDate}
          onBlur={onBlur}
          onChange={onChange}
          disabled={isDisabled}
        />

        <FormInput
          id='email'
          label='Email'
          className='collspan-2'
          value={values.email}
          error={errors.email}
          touched={touched.email}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isDisabled}
        />

        <FormDropdown
          id='coApplicantType'
          mode='select'
          label='Relationship'
          value={coApplicantType}
          error={errors?.coApplicantType}
          touched={touched?.coApplicantType}
          options={coApplicantTypes}
          disableClearable
          onChange={(fieldId, val) => onChange(fieldId, val?.id)}
          onBlur={onBlur}
          disabled={isDisabled}
        />

        <InputSSN2
          id='socialSecurityNumber'
          label='Social Security Number'
          value={values.socialSecurityNumber}
          error={errors.socialSecurityNumber}
          touched={touched.socialSecurityNumber}
          onChange={onChange}
          onBlur={onBlur}
          isDisabled={isDisabled}
        />

        <Checkbox
          id='sameAddressAsMainApplicant'
          label='Same address as main applicant'
          className='cobuyer-same-address'
          value={sameAddressAsMainApplicant}
          onChange={(val: boolean) => {
            onChange('currentAddressDetails', val ? buyerAddress : null)
          }}
          onBlur={onBlur}
          isDisabled={isDisabled}
        />
        <AddressFields
          id='currentAddressDetails'
          values={values.currentAddressDetails}
          errors={errors.currentAddressDetails}
          touched={touched.currentAddressDetails}
          onChange={onChange}
          onBlur={onBlur}
          useNormalAptKey
          disabled={isDisabled}
        />
      </Collapse>
    </section>
  )
}

export default BuyerCobuyerTab
