import { UserRoles } from 'api/types'
import { ACCOUNTING_LINKS, CRM_LINKS, LinkItem, NavIcons, RECON_LINKS, Routes } from 'constants/route_helper'
import { isRouteForbiddenPerRoles } from 'constants/routesPerRoles'

export const configureMenuItems = (userRoles: UserRoles[]): LinkItem[] => {
  return [
    {
      icon: NavIcons.Dashboard,
      isForbidden: isRouteForbiddenPerRoles(Routes.Dashboard, userRoles),
      title: 'Dashboard',
      to: Routes.Dashboard,
      subLinks: []
    },
    {
      icon: NavIcons.Deals,
      isForbidden: isRouteForbiddenPerRoles(Routes.Deals, userRoles),
      title: 'Deals',
      to: Routes.Deals,
      subLinks: []
    },
    {
      icon: NavIcons.Inventory,
      isForbidden: isRouteForbiddenPerRoles(Routes.Vehicles, userRoles),
      title: 'Inventory',
      to: Routes.Vehicles,
      subLinks: []
    },
    {
      icon: NavIcons.Recon,
      isForbidden: isRouteForbiddenPerRoles(Routes.Recon, userRoles),
      title: 'Reconditioning',
      to: Routes.Recon,
      subLinks: RECON_LINKS.map((link) => ({
        ...link,
        isForbidden: isRouteForbiddenPerRoles(link.to, userRoles)
      }))
    },
    {
      icon: NavIcons.Crm,
      isForbidden: isRouteForbiddenPerRoles(Routes.CRM, userRoles),
      title: 'CRM',
      to: Routes.CRM,
      subLinks: CRM_LINKS.map((link) => ({
        ...link,
        isForbidden: isRouteForbiddenPerRoles(link.to, userRoles)
      }))
    },
    {
      icon: NavIcons.Accounting,
      isForbidden: isRouteForbiddenPerRoles(Routes.Accounting, userRoles),
      title: 'Accounting',
      to: Routes.Accounting,
      subLinks: ACCOUNTING_LINKS
    },
    {
      icon: NavIcons.Reports,
      isForbidden: isRouteForbiddenPerRoles(Routes.Reports, userRoles),
      title: 'Reports',
      to: Routes.Reports
    },
    {
      icon: NavIcons.Settings,
      isForbidden: isRouteForbiddenPerRoles(Routes.Settings, userRoles),
      title: 'Settings',
      to: Routes.Settings,
      subLinks: []
    }
  ]
}
