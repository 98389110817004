import {
  FILE_NUMBER_ERROR,
  FILE_SIZE_ERROR,
  TOTAL_FILE_SIZE_ERROR
} from 'constants/files'

export interface FileFilterData {
  files: File[]
  filesNumberRequested: number
  error?: string
}

export const filterFiles = (
  files: File[],
  maxFileSize: number,
  maxFilesTotalSize: number,
  maxFileNumber: number,
  acceptedExtensions?: string[],
  currentAttachments: number = 0
): FileFilterData => {
  let error: string | undefined

  const availableSlots = maxFileNumber - currentAttachments

  if (availableSlots < files.length) {
    files = files.slice(0, availableSlots)
    error = FILE_NUMBER_ERROR
  }

  const totalSize = files.reduce((sum, file) => sum + file.size, 0)

  if (totalSize > maxFilesTotalSize) {
    return { files: [], error: TOTAL_FILE_SIZE_ERROR(maxFilesTotalSize), filesNumberRequested: 0 }
  }

  const hasValidExtension = (filename: string): boolean => {
    const extension = filename.split('.').pop()?.toLowerCase()
    return acceptedExtensions == null || (extension != null && acceptedExtensions.includes(extension))
  }

  const selectedFiles = []
  for (const file of files) {
    if (file.size > maxFileSize) {
      return { files: selectedFiles, error: FILE_SIZE_ERROR(maxFileSize), filesNumberRequested: files.length }
    }

    if (hasValidExtension(file.name)) {
      selectedFiles.push(file)
    }
  }

  return { files: selectedFiles, error: error, filesNumberRequested: files.length }
}
