import { GET_DEFAULT_VEHICLE } from 'api/defaults'
import { type Vehicle } from 'api/types'

import type { VehicleWithGroup } from './types'

export const GROUP_SUGGESTED_CARS = 'Suggested cars'
export const GROUP_CARS_OF_INTEREST = 'Car(s) of interest'
export const GROUP_FROM_INVENTORY = 'From inventory'
export const RACE_CONDITIONS_GODFATHER = 100

/**
 * in order not to confuse with Vehicle's id, and do not expand Vehicle interface
 * used negative ids for groups
 */
export enum GroupCarIds {
  NoCarOfInterest = -1,
  NoSuggestedCars = -2,
  CarOfInterestGroup = -3,
  SuggestedCarsGroup = -4,
  FromInventoryGroup = -5
}

export enum MockedUniqStock {
  NoCarOfInterest = 'empty_cars_of_interest',
  NoSuggestedCars = 'empty_suggested_cars'
}

export const optionTextMap = {
  [GroupCarIds.CarOfInterestGroup]: GROUP_CARS_OF_INTEREST,
  [GroupCarIds.SuggestedCarsGroup]: GROUP_SUGGESTED_CARS,
  [GroupCarIds.FromInventoryGroup]: GROUP_FROM_INVENTORY,
  [GroupCarIds.NoCarOfInterest]: 'Car(s) of interest (not selected)',
  [GroupCarIds.NoSuggestedCars]: 'Suggested cars (no car of interest)'
}

export const groupPrependMap = new Map<string, GroupCarIds>([
  [GROUP_FROM_INVENTORY, GroupCarIds.FromInventoryGroup],
  [GROUP_SUGGESTED_CARS, GroupCarIds.SuggestedCarsGroup],
  [GROUP_CARS_OF_INTEREST, GroupCarIds.CarOfInterestGroup]
])

export const getEmptyCarOfInterestGroup = (): VehicleWithGroup[] => {
  return [{
    ...GET_DEFAULT_VEHICLE(),
    id: GroupCarIds.NoCarOfInterest,
    stock: MockedUniqStock.NoCarOfInterest,
    group: ''
  }]
}

export const getEmptySuggestedCarsGroup = (): VehicleWithGroup[] => {
  return [{
    ...GET_DEFAULT_VEHICLE(),
    id: GroupCarIds.NoSuggestedCars,
    stock: MockedUniqStock.NoSuggestedCars,
    group: ''
  }]
}

export const suggestedCarsGroup = (car: Vehicle): VehicleWithGroup => ({
  ...car,
  group: GROUP_SUGGESTED_CARS
})

export const prependGroupNameOptions = (groupArray: VehicleWithGroup[], groupName: string): VehicleWithGroup[] => {
  const prependId = groupPrependMap.get(groupName)

  if (prependId == null) {
    return groupArray
  }

  if (groupArray.length > 0) {
    const defaultItem = { ...GET_DEFAULT_VEHICLE(), id: prependId, group: '' }
    return [defaultItem, ...groupArray]
  }

  return []
}
