import { type FC, type ReactNode, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { useModal, UI } from '@carfluent/common'

import DeleteModal from 'components/dialogs/DeleteModal'

const { Button } = UI

const DELETE_ITEM_TITLE = 'Delete item'

export interface DeleteEntityButtonProps {
  isDisabled?: boolean
  isOpen?: boolean
  onClose?: () => void
  onConfirm: () => Promise<void>
  onOpen?: () => void
  titleButton?: string
  titleContent?: ReactNode
  titleWindow?: string
  submitTitle?: string
}

const DeleteEntityButton: FC<DeleteEntityButtonProps> = ({
  isDisabled = false,
  isOpen,
  onClose: _onClose,
  onConfirm: _onConfirm,
  onOpen: _onOpen,
  titleButton = DELETE_ITEM_TITLE,
  titleContent = 'Please note, this step could not be undone',
  titleWindow = DELETE_ITEM_TITLE,
  submitTitle = 'DELETE'
}) => {
  const { isModalOpen, onCloseModal, onOpenModal } = useModal()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onClose = useCallback(() => {
    _onClose?.()
    onCloseModal()
  }, [_onClose, onCloseModal])

  const onOpen = useCallback(() => {
    if (!isDisabled) {
      _onOpen?.()
      onOpenModal()
    }
  }, [_onOpen, onOpenModal, isDisabled])

  const onConfirm = useCallback(async () => {
    onCloseModal()
    await _onConfirm?.()
  }, [_onConfirm, onCloseModal])

  // ========================================== //
  return (
    <>
      <Button
        className='cf-delete-button'
        isDisabled={isDisabled}
        variant='text'
        onClick={onOpen}
      >
        {titleButton}
      </Button>

      <DeleteModal
        title={titleWindow}
        content={titleContent}
        isOpen={isOpen ?? isModalOpen}
        onConfirm={onConfirm}
        onClose={onClose}
        submitTitle={submitTitle}
      />
    </>
  )
}

export default observer(DeleteEntityButton)
