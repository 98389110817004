import { css } from '@emotion/css'

export default css(`
  --table-background-color: #f3f3f3;
  --table-row-border: 8px solid var(--table-background-color);
  --table-item-background-color: #fafafa;
  --table-cell-height: 56px;
  --table-padding: 16px;
  
  padding: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
  border-radius: 12px;
  background: #ffffff;
  
  .cf-vehicle-total-time {
    display: grid;
    column-gap: 4px;
    grid-template-columns: max-content max-content;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #101010;
    
    span:first-child {
      color: rgba(33, 33, 33, 0.50);
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
    }

    span {
      text-wrap: nowrap;
    }

    span:last-child {
      font-weight: 500;
    }
  }

  .history-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    > div:first-child {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 8px;
      align-items: center;

      .cf-item-label, .cf-item-value .cf-button-variant-text .cf-button-content {
        color: #101010;
        font-weight: 500;
        font-size: 16px;
      }

      .cf-item-value {
        background: #F1F7FF;
        border-radius: 4px;
        padding: 4px 8px;

        .cf-button-content {
          min-width:180px;
          text-align: left;
        }

        .cf-button-variant-text {
          text-decoration: none;
        }
      }
    }
  }
  
  .cf-empty-embedded-block-wrapper {
    border-radius: 4px;
    
    .cf-empty-embedded-block-content {
      justify-content: center;
    }
  }

  .MuiPaper-root.cf-table-wrapper {
    border-radius: 4px;
    
    .cf-table {
      table-layout: fixed;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background-color: var(--table-background-color);
      
      th * {
        font-weight: 500;
      }

      thead tr {
        height: 48px;
      }

      th, td {
        border-bottom: 0px;
        height: 100%;
        
        :last-child {
          min-width: 220px;
          max-width: 220px;
        }

        div {
          height: 100%;
          border: 0;
        }
      }

      tr {
        td:first-child, th:first-child {
          min-width: 110px;
          max-width: 110px;
        }

        td:nth-child(2), th:nth-child(2) {
          min-width: 120px;
          max-width: 120px;
        }

        td:nth-child(3), th:nth-child(3) {
          min-width: 128px;
          max-width: 128px;
        }

        td:last-child, th:last-child {
          min-width: 140px;
          max-width: unset;
          width: 100%;
        }

        td p, td span {
          font-size: 14px;
          font-weight: 400;
        }
      }

      @media (min-width: 1280px) {
        tr {
          td:first-child, th:first-child {
            min-width: 160px;
            max-width: 160px;
          }

          td:nth-child(2), th:nth-child(2) {
            min-width: 185px;
            max-width: 185px;
          }
        }
      }

      @media (min-width: 1600px) {
        tr {
          td:nth-child(3), th:nth-child(3) {
            min-width: 225px;
            max-width: 225px;
          }
        }
      }

      td:first-child > div, th:first-child > div {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      td:last-child > div, th:last-child > div {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    th * {
      color: #101010;
    }
  
    * {
        color: #101010;
    }
  
    tr {
      display: flex;
      align-items: center;
      height: var(--table-cell-height);
      box-sizing: content-box;
      border: var(--table-row-border);
      background-color: var(--table-item-background-color);
      border-radius: 12px;
    }
    
    .cf-table-header-row {
      th {
        width: 25%;
      }
    }

    .MuiTableBody-root {
      tr {
        border-top: 0px;

        td div {
          padding: 8px 4px;
        }

        :hover td div {
          background-color: var(--table-item-background-color);
          border: 0;
        }
        
        td:first-child {
          .cf-cell-wrapper {
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 16px;
            display: flex;
            align-items: center;
            border-left: 0px solid transparent;

            p { white-space: wrap; }
          }
        }

        [class*=cf-cell-wrapper] {
          height: var(--table-cell-height);
          background: white;

          > div {
            display: flex;
          }
        }

        td:last-child {
          .cf-cell-wrapper {
            padding-right: 12px;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }
  
  .cf-helper-text {
    color: rgba(33, 33, 33, 0.50);
    font-size: 14px;
    font-family: Roboto;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-top: 16px;
  }
`)
