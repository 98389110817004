import type { FormValidation } from '@carfluent/common'

import { firstAccountField, accountField } from 'utils/validationPresets'
import type { VehiclePurchasePaymentsFormData } from './types'

const createFormValidation = (): FormValidation<VehiclePurchasePaymentsFormData> => {
  return {
    firstAccount: firstAccountField(),
    secondAccount: accountField(),
    thirdAccount: accountField(),
    fourthAccount: accountField(),
    fifthAccount: accountField()
  }
}

export default createFormValidation()
