import { type FC } from 'react'
import { cnx, formatCurrencyDecimal } from '@carfluent/common'

import { Routes } from 'constants/route_helper'
import Header from '../Header'
import NumberIndicator from '../NumberIndicator'

import CLASS_NAME from './styles'
import InventoryChart from './InventoryCart'
import useDashboardIntentory from './hook/index'

interface InventoryDashboardProps {
  className?: string
}

export const EMPTY_FORMAT_VALUE = { emptyValues: '0' }

const InventoryDashboard: FC<InventoryDashboardProps> = ({
  className
}) => {
  const { data, isLoading } = useDashboardIntentory()

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <Header
        withFilter={false}
        isLoading={isLoading}
        title='Inventory'
        routes={Routes.Vehicles}
      />

      <div className='info-block'>
        <NumberIndicator
          isLoading={isLoading}
          label='Current inventory'
          value={data?.currentInventoryCount ?? 0}
          emptyValue={EMPTY_FORMAT_VALUE}
        />

        <NumberIndicator
          isLoading={isLoading}
          label='On deposit'
          value={data?.onDepositCount ?? 0}
          emptyValue={EMPTY_FORMAT_VALUE}
        />

        <NumberIndicator
          isLoading={isLoading}
          label='No photos'
          value={data?.noPhotosCount ?? 0}
          emptyValue={EMPTY_FORMAT_VALUE}
        />

        <NumberIndicator
          isLoading={isLoading}
          label='Total costs'
          value={data?.totalCosts ?? 0}
          formatter={formatCurrencyDecimal}
          emptyValue={EMPTY_FORMAT_VALUE}
        />

        <NumberIndicator
          isLoading={isLoading}
          label='Average costs'
          value={data?.averageCosts ?? 0}
          formatter={formatCurrencyDecimal}
          emptyValue={EMPTY_FORMAT_VALUE}
        />

        <NumberIndicator
          isLoading={isLoading}
          label='Average days'
          value={data?.averageAge ?? 0}
          emptyValue={EMPTY_FORMAT_VALUE}
        />
      </div>

      <InventoryChart data={data?.vehiclesByAge ?? []} isLoading={isLoading} />
    </div>

  )
}

export default InventoryDashboard
