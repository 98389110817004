import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'

import type { DealsListItem } from 'api/types'
import { isDealUnchecked } from 'services/storage.service'

class DealNotifications {
  notifications: DealsListItem[] = []

  checkDeal = (id: number): void => {
    this.notifications = this.notifications.filter(notification => notification.workflowId !== id)
  }

  setNotifications = (notifications: DealsListItem[]): void => {
    this.notifications = notifications
  }

  get uncheckedDeals (): DealsListItem[] {
    return this.notifications.filter(item => isDealUnchecked(item.workflowId, item.requiresCheck))
  }

  constructor () {
    makeAutoObservable(this)
  }
}

export const DealNotificationInstance = new DealNotifications()
export const DealNotificationCTX = createContext(DealNotificationInstance)
export default DealNotificationCTX
