import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  content: {
    width: '100%'
  },
  wrapper: {
    maxWidth: 600,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  title: {
    fontWeight: 'bold',
    fontSize: 44,
    lineHeight: '56px',
    color: '#000000',
    marginTop: 50,
    textAlign: 'center'
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: 0.15,
    color: '#101010',
    marginTop: 20
  }
})
