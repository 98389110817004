import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLoader, useModal, useRefUpdater } from '@carfluent/common'

import VehiclesApiProvider from 'api/vehicles.api'
import { VehicleState, type VehicleDetailsByVin } from 'api/types'
import { type ImageDescriptor } from 'components/inventory/ImageItem'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useAsyncEffect from 'hooks/useAsyncEffect'
import useMobXFormik from 'hooks/useMobXFormik'
import { useLayout } from 'components/layout/hook'
import { parseDescription } from 'utils/wysiwyg'
import { scrollToError } from 'utils/validation'
import { containsTruthy } from 'utils/general'

import type { TransactionDealDetails, UpdateVehicleFormData, UseGeneralTabProps, UseGeneralTabReturn } from './types'
import getValidationFn from './validator'
import useVehicleDetailsAPI from './apiClient'
import { parsePriceHistory, parseVehicleById } from './parser'
import VehicleDetailsCTX from '../store'
import GeneralTabCTX from './store'
import { Alerts, ALL_FORM_TOUCHED, DEFAULT_FORM_DATA, GET_DEFAULT_TRANSACTION_DEAL_DETAILS } from './constants'
import { stopSpinnerWithDelay, getDisabledRepriceTooltip, cloneFeatureOptionData } from './utils'

const SCROLL_TO_ERROR_DELAY = 300
const DATE_FIELDS = ['inventoryDate', 'titleReceived', 'titleSent']

/**
 * AZ-NOTES: for future refactoring - we can't just remove store from this form,
 * since it leads to page freezes. Instead, we should make all tab-level hooks
 * independent first (remove import of these hooks into root hook).
 * This will allow us to not re-render every tab on each `onChange`, freezes should gone.
 */
const useGeneralTab = ({
  setApiErrors,
  vehicleId,
  fetchLeadsTab
}: UseGeneralTabProps): UseGeneralTabReturn => {
  const { showAlert } = useCustomSnackbar()
  const [isTabSaving, setIsTabSaving] = useState(false)
  const [isTabDirty, setIsTabDirty] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [transactionDealDetails, setTransactionDealDetails] =
    useState<TransactionDealDetails>(GET_DEFAULT_TRANSACTION_DEAL_DETAILS)

  const { isLoading: isGlobalLoading } = useLayout()
  const { originalVehicle, originalParsedMediaFiles } = useContext(VehicleDetailsCTX)

  const {
    cleanUp,
    dictionaries,
    updateFormData,
    vehicleFormData
  } = useContext(GeneralTabCTX)

  const validate =
    useCallback(
      getValidationFn(
        vehicleFormData.vehicleState,
        vehicleFormData.vehicleRepricingDetails?.isEnabled ?? false
      ),
      [
        vehicleFormData.vehicleState,
        vehicleFormData.vehicleRepricingDetails?.isEnabled
      ]
    )

  const carInfoDictionaries = useMemo(() => ({
    Body: dictionaries.Body,
    Cylinders: dictionaries.Cylinders,
    Doors: dictionaries.Doors,
    Drivetrain: dictionaries.Drivetrain,
    Fuel: dictionaries.Fuel,
    GenericColor: dictionaries.GenericColor,
    GenericInterior: dictionaries.GenericInterior,
    Transmission: dictionaries.Transmission,
    DisabilityEquipped: dictionaries.DisabilityEquipped
  }), [dictionaries])

  const titleDictionaries = useMemo(() => ({
    TitleStatus: dictionaries.TitleStatus,
    InventoryState: dictionaries.InventoryState,
    TitleStage: dictionaries.TitleStage,
    RegistrationStage: dictionaries.RegistrationStage
  }), [dictionaries])

  const formik = useMobXFormik<UpdateVehicleFormData>({
    setStoreField: updateFormData,
    setStoreValues: updateFormData,
    initialValues: DEFAULT_FORM_DATA,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validate
  })

  const { setFieldValue, setFieldTouched } = formik

  const apiConfig = useMemo(() => ({
    setApiErrors,
    fetchLeadsTab,
    setFormData: formik.setValues,
    setFieldError: formik.setFieldError,
    setTransactionDealDetails
  }), [fetchLeadsTab, formik.setValues, formik.setFieldError, setApiErrors])

  const API = useVehicleDetailsAPI(apiConfig)
  const generateAiLoader = useLoader()
  const generateAiModalProps = useModal()

  const isDisabledTab = originalVehicle.vehicleState === VehicleState.Deleted

  const priceHistory = useMemo(() => {
    return parsePriceHistory({
      priceHistory: originalVehicle.vehiclePrices,
      repriceHistory: originalVehicle.vehicleRepricingDetails?.history ?? []
    })
  }, [
    originalVehicle.vehiclePrices,
    originalVehicle.vehicleRepricingDetails?.history
  ])

  const repriceTooltip = useMemo(() => getDisabledRepriceTooltip({
    isDeposited: originalVehicle.isDeposited,
    isInactive: vehicleFormData.vehicleState === VehicleState.Inactive,
    isSalePriceZero: vehicleFormData.salePrice === 0
  }), [
    originalVehicle.isDeposited,
    vehicleFormData.vehicleState,
    vehicleFormData.salePrice
  ])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onSetFieldValue: typeof setFieldValue = useCallback(async (fieldId, v) => {
    const value = v ?? null
    setApiErrors(null)

    /**
     * AZ-NOTE:
     * In our EditorComponent (DraftJS wrapper) we have an effect, that forces selection
     * on mount (don't know why, looks like some bugfix).
     * This leads to the unexpected `onChange` from the EditorComponent.
     * So here we need to check if the `onChange` means change in the editor's content.
     * Without this check, tab is marked as Dirty even when user just focuses inside editor.
     */
    if (fieldId === 'description') {
      const currentContent = vehicleFormData.description?.getCurrentContent()
      const nextContent = value?.getCurrentContent()

      /**
       * DraftJS uses Immutable, so we can detect change just by coparing reference.
       */
      if (currentContent === nextContent) {
        return
      }
    }

    switch (fieldId as keyof UpdateVehicleFormData) {
      case 'stock': {
        await setFieldValue(fieldId, value.replace(/[^a-zA-Z0-9 ]/g, ''))
        break
      }

      case 'vehicleState': {
        await setFieldValue(fieldId, Number(value))
        await formik.setTouched(ALL_FORM_TOUCHED, true) // re-validate form on status change
        break
      }

      default:
        await setFieldValue(fieldId, value)
    }

    if (fieldId !== 'vehicleState' && !DATE_FIELDS.includes(fieldId)) {
      await setFieldTouched(fieldId, false)
    }

    if (fieldId !== 'vehicleStatus' && fieldId !== 'rowVersion') {
      setIsTabDirty(true)
    }
  }, [
    setApiErrors,
    setFieldValue,
    setFieldTouched,
    formik.validateForm,
    vehicleFormData.description
  ])

  const onChangeFeature = useCallback((category: string, id: number): void => {
    const newFeatures = cloneFeatureOptionData(vehicleFormData.features)
    const featureIndex = newFeatures[category].findIndex(obj => obj.id === id)

    newFeatures[category][featureIndex].isEnabled = !newFeatures[category][featureIndex].isEnabled

    void onSetFieldValue('features', newFeatures)
  }, [onSetFieldValue, vehicleFormData.features])

  const onChangeOption = useCallback((category: string, id: number): void => {
    const newOptions = cloneFeatureOptionData(vehicleFormData.options)
    const featureIndex = newOptions[category].findIndex(obj => obj.id === id)

    newOptions[category][featureIndex].isEnabled = !newOptions[category][featureIndex].isEnabled

    void onSetFieldValue('options', newOptions)
  }, [onSetFieldValue, vehicleFormData.options])

  const onGenerateAIDescription = useCallback(async (): Promise<void> => {
    generateAiLoader.startLoader()

    try {
      const { description } = await VehiclesApiProvider.generateAIDescription(vehicleId)
      await onSetFieldValue('description', parseDescription(description))
      generateAiModalProps.onCloseModal()
    } catch (err) {
      showAlert(err)
    } finally {
      generateAiLoader.stopLoader()
    }
  }, [
    generateAiLoader.stopLoader,
    generateAiLoader.startLoader,
    generateAiModalProps.onCloseModal,
    onSetFieldValue,
    showAlert,
    vehicleId
  ])

  const onProcessSave = useCallback(async (
    data?: Partial<UpdateVehicleFormData>,
    mediaFiles?: ImageDescriptor[]
  ) => {
    if (isTabSaving) {
      return false
    }

    const errors = await formik.validateForm()
    const isVehicleFormValid = !containsTruthy(errors)

    if (isVehicleFormValid) {
      setIsTabSaving(true)

      const isSuccess = await API.updateVehicle(data, mediaFiles ?? originalParsedMediaFiles ?? [])
      setIsTabSaving(false)

      if (isSuccess) {
        setIsTabDirty(false)
        showAlert(Alerts.saveSuccess, { variant: 'success' })
        stopSpinnerWithDelay(setIsLoading)

        return isSuccess
      }
    }

    await formik.setTouched(ALL_FORM_TOUCHED, false)
    setTimeout(scrollToError, SCROLL_TO_ERROR_DELAY)
    return false
  }, [
    API.updateVehicle,
    isTabSaving,
    showAlert,
    formik.setTouched,
    formik.validateForm,
    originalParsedMediaFiles
  ])

  /**
   * AZ-NOTE: for some reason `originalVehicle` value is not refreshed here,
   * so reset restores pre-previous value. MobX?
   */
  const refOriginalVehicle = useRefUpdater(originalVehicle)
  const onProcessReset = useCallback(async () => {
    const values = parseVehicleById({
      ...refOriginalVehicle.current,
      vehicleStatus: formik.values.vehicleStatus
    }, dictionaries)

    await formik.setValues(values)
    setIsTabDirty(false)
  }, [
    formik.setValues,
    dictionaries,
    originalVehicle,
    formik.values.vehicleStatus
  ])

  const onApplyVin = useCallback(async (vin: string): Promise<VehicleDetailsByVin | null> => {
    return await API.loadVehicleByVIN(vin)
  }, [API.loadVehicleByVIN])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useAsyncEffect(async (): Promise<void> => {
    try {
      setIsLoading(true)
      await Promise.all([
        API.loadDictionaries(),
        API.loadVehicleById(vehicleId)
      ])
    } catch {
      throw new Error('Failed to load vehicle')
    } finally {
      stopSpinnerWithDelay(setIsLoading)
    }
  }, [
    vehicleId,
    API.loadDictionaries,
    API.loadVehicleById
  ])

  /**
   * Updates form after dictionaries update (to fill dropdowns' selected items).
   */
  useAsyncEffect(async (): Promise<void> => {
    const hasId = Boolean(originalVehicle.id)

    if (hasId) {
      const parsedVehicle = parseVehicleById(originalVehicle, dictionaries)
      await formik.setValues(parsedVehicle)
    }
  }, [
    originalVehicle.id,
    dictionaries,
    formik.setValues
  ])

  const onSetFieldValueRef = useRefUpdater(setFieldValue)
  useEffect(() => {
    if (repriceTooltip != null) {
      void onSetFieldValueRef.current('vehicleRepricingDetails.isEnabled', false)
    }
  }, [repriceTooltip])

  useEffect(() => {
    if (isGlobalLoading) {
      setIsTabDirty(false)
    }
  }, [isGlobalLoading])

  /**
   * Some clean-up on unmount.
   */
  useEffect(() => {
    return () => {
      cleanUp()
      void formik.setValues(DEFAULT_FORM_DATA)
    }
  }, [cleanUp, formik.setValues])

  // ========================================== //

  return useMemo(() => ({
    ...formik,
    values: vehicleFormData,
    dictionaries,
    isLoading,
    isTabDirty,
    isTabSaving,
    onApplyVin,
    onProcessReset,
    onProcessSave,
    originalVehicle,
    tags: dictionaries.Tags,
    titleDictionaries,
    carInfoDictionaries,
    makeDictionaries: dictionaries.Make ?? [],
    priceHistory,
    setFieldValue: onSetFieldValue,
    updateFormData,
    transactionDealDetails,
    generateAiModalProps,
    onGenerateAIDescription,
    isGenerateAiLoading: generateAiLoader.isLoading,
    onChangeFeature,
    onChangeOption,
    repriceTooltip,
    isDisabledTab
  }), [
    carInfoDictionaries,
    dictionaries.Tags,
    dictionaries.Make,
    isLoading,
    isTabDirty,
    isTabSaving,
    formik.errors,
    formik.isValid,
    formik.touched,
    onApplyVin,
    onProcessReset,
    onProcessSave,
    originalVehicle,
    onSetFieldValue,
    titleDictionaries,
    updateFormData,
    vehicleFormData,
    transactionDealDetails,
    generateAiModalProps,
    onGenerateAIDescription,
    generateAiLoader.isLoading,
    onChangeFeature,
    onChangeOption,
    repriceTooltip,
    isDisabledTab,
    priceHistory
  ])
}

export default useGeneralTab
