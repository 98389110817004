import CustomersCoreApiProvider from 'api/customersCore.api'

const getDepositedCarId = async (dealId: string | number, vehicleId: number | null): Promise<number | null> => {
  if (vehicleId == null) {
    return null
  }

  try {
    const { items } = await CustomersCoreApiProvider.getDepositedDeals(vehicleId)
    const differentItem = items.find(item => item !== Number(dealId))
    return differentItem ?? null
  } catch {
    return null
  }
}

export default getDepositedCarId
