import type { FC, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { CellWrapper, cnx } from '@carfluent/common'

import CLASS_NAME from './styles'
import TooltipItem from 'components/common/TooltipItem'

interface LinkCellHOCProps {
  withTooltip?: boolean
  emptyCellValue?: string
}

export interface LinkCellProps extends LinkCellHOCProps {
  getValue: () => LinkData | null
  className?: string
}

export interface LinkData {
  path: string
  title: string
}

export const LinkCellHOC = (props: LinkCellHOCProps) => (linkCellProps: LinkCellProps) => <LinkCell {...props} {...linkCellProps} />

const LinkCell: FC<LinkCellProps> = ({ getValue, withTooltip = false, emptyCellValue = '', className = '' }) => {
  const link = getValue()
  const handleLinkClick = (event: MouseEvent<Element>): void => {
    event?.stopPropagation()
  }

  if (link == null) {
    return (
      <CellWrapper>{emptyCellValue}</CellWrapper>)
  }

  return (
    <CellWrapper className={className}>
      <Link className={cnx(CLASS_NAME)} to={link.path} onClick={handleLinkClick}>
        {
          withTooltip
            ? <TooltipItem
                value={link.title}
              />
            : <p>{link.title}</p>
        }
      </Link>
    </CellWrapper>
  )
}

export default LinkCell
