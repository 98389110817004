import type { FC } from 'react'
import { cn } from '@carfluent/common'

import CLASS_NAME from './styles'

interface TabPanelProps {
  className?: string
  isSelected?: boolean
  isComponentShouldUnmount?: boolean
  isDisabled?: boolean
}

const TabPanel: FC<TabPanelProps> = ({
  children,
  className,
  isComponentShouldUnmount = false,
  isSelected = false,
  isDisabled = false
}) => {
  if (isComponentShouldUnmount && !isSelected) {
    return null
  }

  return (
    <div className={cn(CLASS_NAME, 'g-panel', 'tab-panel', isSelected && 'active', className, isDisabled && 'cf-tab-disabled')}>
      {children}
    </div>
  )
}

export default TabPanel
