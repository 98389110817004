import { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { defer, useModal, useRefUpdater } from '@carfluent/common'
import { type Row } from '@tanstack/react-table'
import { isAxiosError } from 'axios'

import {
  type TransactionDetails,
  type CustomerDto,
  type TransactionLineVendorDto,
  TransactionTypeId,
  BankStatementTransactionState,
  BankStatementTransactionMatchItem
} from 'api/types'

import { type TransactionCreationMode } from 'pages/accounting/TransactionDetails'
import { type FiltersFormData } from 'types/filters'
import AccountingApiProvider from 'api/accounting.api'
import useCostTypes from 'hooks/useCostTypes'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { keys } from 'utils/general'
import { PAGE_OVERLAY_ID } from 'components/PageOverlay/constants'

import {
  type BankStatementRowData,
  type RowForReviewState,
  type UseBankStatementsListProps,
  type UseBankStatementsListReturn,
  type ViewTransactionResult
} from './types'

import {
  DEFAULT_FILTERS,
  DEFAULT_SORTING,
  DEFAULT_COUNTERS,
  Messages
} from './constants'

import getColumnDefinitions from './columns'
import useBankStatementsTable, { PAGE_SIZE } from './useTable'

const useBankStatementsList = ({
  accountId,
  isFiltersChanged,
  onCategorizeRow: _onCategorizeRow,
  onExcludeRow: _onExcludeRow,
  onUndoExcludeClick: _onUndoExcludeClick,
  setIsFiltersChanged
}: UseBankStatementsListProps): UseBankStatementsListReturn => {
  const [tabCounters, setTabCounters] = useState(DEFAULT_COUNTERS)
  const [transactionId, setTransactionId] = useState<number | null>(null)
  const [transactionTypeId, setTransactionTypeId] = useState<number>(TransactionTypeId.JournalEntry)
  const [bankStatementId, setBankStatementId] = useState<number | null>(null)
  const [bankStatementMatches, setBankStatementMatches] = useState<BankStatementTransactionMatchItem[]>([])
  const [transactionCreationMode, setTransactionCreationMode] = useState<TransactionCreationMode>('bank-statement-match')
  const [transaction, setTransaction] = useState<TransactionDetails | null>(null)
  const [, setCostTypeId] = useState<number | null>(null)
  const refRowStates = useRef<Record<number, RowForReviewState>>({})
  const { showError, showSuccess } = useCustomSnackbar()
  const transactionModal = useModal()

  useCostTypes()

  /**
   * used to be reupdated to object literal to trigger reset of DatePicker.
   * DatePicker is done incorrectly and does not work properly
   * if we just pass updated applied date filters
   */
  const [dateFiltersResetMarker, setDateFiltersResetMarker] = useState<object>({})

  const refFindMatchCreationResult = useRef(defer<ViewTransactionResult>())
  const customerModal = useModal()
  const vendorModal = useModal()

  const refVendorCreationResult = useRef(defer<TransactionLineVendorDto | null>())
  const refCustomerCreationResult = useRef(defer<CustomerDto | null>())
  const containerElement = document.getElementById(PAGE_OVERLAY_ID) as HTMLDivElement

  const {
    filters,
    rows,
    search,
    sorting,
    isLoading,
    getRows,
    getPageSize,
    loadRows,
    onSearch,
    setFilters,
    setSorting,
    setSearch
  } = useBankStatementsTable({
    accountId,
    setIsFiltersChanged,
    setTabCounters
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onSelectTab = useCallback((tabId: number): void => {
    refRowStates.current = {}
    void setFilters({ transactionState: tabId }, true)
  }, [setFilters, setIsFiltersChanged])

  const onFiltersPanelChange = useCallback(async (data: Partial<FiltersFormData>) => {
    refRowStates.current = {}
    void setFilters(data, true)
  }, [setFilters, setIsFiltersChanged])

  const onSearchPanelChange = useCallback(async (value: string) => {
    void setSearch(value)
  }, [setSearch])

  const refLoadRows = useRefUpdater(loadRows)
  const onCategorizeRow = useCallback(() => {
    let nextForReview = 0

    setTabCounters((prev) => {
      nextForReview = prev.forReview - 1

      return {
        ...prev,
        forReview: prev.forReview - 1,
        categorized: prev.categorized + 1
      }
    })

    showSuccess(Messages.CategorizeSuccess)
    _onCategorizeRow?.()

    const visibleRowsNum = document.querySelectorAll('.cf-table-row.row-expander').length
    if (visibleRowsNum < PAGE_SIZE) {
      void refLoadRows.current({
        forceRefresh: nextForReview <= PAGE_SIZE, // we have only one page in DB, so re-fetch current page
        forceScrollToSkeleton: false
      })
    }
  }, [_onCategorizeRow, showSuccess])

  const onExcludeRow = useCallback(() => {
    let nextForReview = 0

    setTabCounters((prev) => {
      nextForReview = prev.forReview - 1

      return {
        ...prev,
        forReview: nextForReview,
        excluded: Number(prev.excluded) + 1
      }
    })

    _onExcludeRow?.()

    const visibleRowsNum = document.querySelectorAll('.cf-table-row.row-expander').length
    if (visibleRowsNum < PAGE_SIZE) {
      void refLoadRows.current({
        forceRefresh: nextForReview <= PAGE_SIZE, // we have only one page in DB, so re-fetch current page
        forceScrollToSkeleton: false
      })
    }
  }, [_onExcludeRow])

  const onMoveTransactionForReviewTab = useCallback(async (id: number) => {
    try {
      await AccountingApiProvider.forReviewBankStatementTransaction(id)
      void loadRows({ forceRefresh: true, forceScrollToSkeleton: false })
      _onUndoExcludeClick?.()
      showSuccess(Messages.UndoExcludeSuccess)
    } catch (err) {
      if (isAxiosError(err)) {
        showError?.(err.response?.data?.message)
      }
    }
  }, [showError, showSuccess, loadRows, _onUndoExcludeClick])

  /**
   * Sets `isExpanding` to `false` for all previously expanded rows,
   * except of the click's target row.
   */
  const onRowClick = useCallback((row: Row<BankStatementRowData>) => {
    for (const key of keys(refRowStates.current)) {
      if (`${key}` !== `${row.original.id}`) {
        if (refRowStates.current[key].isExpanded === true) {
          refRowStates.current[key].isExpanded = false
        }
      }
    }
  }, [])

  const onViewNextTransaction = useCallback((
    transactionId: number,
    typeId: number,
    costTypeId: number | null = null
  ) => {
    transactionModal.onCloseModal()

    setTimeout(() => {
      setTransactionCreationMode('regular')
      setTransactionId(transactionId)
      setTransaction(null)
      setTransactionTypeId(typeId)
      setCostTypeId(costTypeId)
      transactionModal.onOpenModal()
    }, 0)
  }, [transactionModal.onOpenModal])

  const onAddVendor = useCallback(async (): Promise<TransactionLineVendorDto | null> => {
    refVendorCreationResult.current = defer<TransactionLineVendorDto | null>()
    vendorModal.onOpenModal()

    return await refVendorCreationResult.current.promise
  }, [vendorModal.onOpenModal])

  const onAddCustomer = useCallback(async (): Promise<CustomerDto | null> => {
    refCustomerCreationResult.current = defer<CustomerDto | null>()
    customerModal.onOpenModal()

    return await refCustomerCreationResult.current.promise
  }, [customerModal.onOpenModal])

  const onSubmitAddCustomer = useCallback(async (data: CustomerDto) => {
    refCustomerCreationResult.current.resolve(data)
  }, [])

  const onSubmitAddVendor = useCallback(async (data: TransactionLineVendorDto) => {
    refVendorCreationResult.current.resolve(data)
  }, [])

  const onCloseAddCustomer = useCallback(() => {
    /**
     * Allows the submit fn to resolve promise first, if `onClose` is called from `onSubmit`.
     */
    setTimeout(() => {
      refCustomerCreationResult.current.resolve(null)
    }, 0)

    customerModal.onCloseModal()
  }, [customerModal])

  const onCloseAddVendor = useCallback(() => {
    /**
     * Allows the submit fn to resolve promise first, if `onClose` is called from `onSubmit`.
     */
    setTimeout(() => {
      refVendorCreationResult.current.resolve(null)
    }, 0)

    vendorModal.onCloseModal()
  }, [vendorModal])

  const onViewPreloadedTransaction = useCallback(async (
    transaction: TransactionDetails,
    bankStatementId: number,
    matches: BankStatementTransactionMatchItem[]
  ): Promise<ViewTransactionResult> => {
    refFindMatchCreationResult.current = defer<ViewTransactionResult>()

    setTransactionCreationMode('bank-statement-match')
    setTransaction(transaction)
    setTransactionId(null)
    setTransactionTypeId(transaction.transactionTypeId)
    setBankStatementId(bankStatementId)
    setBankStatementMatches(matches)
    setCostTypeId(null)
    transactionModal.onOpenModal()

    return await refFindMatchCreationResult.current.promise
  }, [transactionModal.onOpenModal])

  const onSubmitPreloadedOrChainedTransaction = useCallback(async (): Promise<void> => {
    const result = transactionCreationMode === 'bank-statement-match'
      ? 'submit-create-match'
      : 'submit-update-existing'

    refFindMatchCreationResult.current.resolve({ type: result, payload: null })
    void loadRows()
  }, [loadRows, transactionCreationMode])

  const onBankStatementClear = useCallback((match: BankStatementTransactionMatchItem): void => {
    refFindMatchCreationResult.current.resolve({ type: 'bank-statement-clear-method', payload: match })
    transactionModal.onCloseModal()
  }, [transactionModal.onCloseModal])

  const onCancelPreloadedTransaction = useCallback(() => {
    refFindMatchCreationResult.current.resolve({ type: 'cancel', payload: null })
  }, [])

  const onClosePreloadedOrChainedTransaction = useCallback(() => {
    /**
     * Allows the submit fn to resolve promise first, if `onClose` is called from `onSubmit`.
     */
    setTimeout(() => {
      refFindMatchCreationResult.current.resolve({ type: 'cancel', payload: null })
    }, 0)
    transactionModal.onCloseModal()
  }, [transactionModal.onCloseModal])

  const onBottomReached = useCallback(async (): Promise<void> => {
    if (!isLoading) {
      /**
       * Sometimes `onBottomReached` is triggered when table shows a page with
       * skeletons only (without rows).
       * For example, when filter forces refresh.
       * In this case we should not try to scroll to skeleton, to avoid page "jumping".
       * #lazyload, #bottom-reached
       */
      const skipScroll = getRows().length < getPageSize()
      await loadRows({ forceRefresh: false, forceScrollToSkeleton: !skipScroll })
    }
  }, [loadRows, isLoading, getRows, getPageSize])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (accountId != null) {
      const SKIP_LOADING = true
      refRowStates.current = {} // reset rows' state on account switch

      void setFilters(DEFAULT_FILTERS, false, false, SKIP_LOADING)
      void setSorting(DEFAULT_SORTING, SKIP_LOADING)
      setSearch('', SKIP_LOADING)
      setDateFiltersResetMarker({})
      void loadRows({ forceRefresh: true, forceScrollToSkeleton: false })
    }
  }, [
    accountId, loadRows,
    setFilters, setSorting, setSearch
  ])

  // ========================================== //

  const columnDefinitions = useMemo(() => getColumnDefinitions({
    transactionStateId: filters.transactionState ?? BankStatementTransactionState.ForReview,
    onUndoExcludeClick: async (id: number): Promise<void> => await onMoveTransactionForReviewTab(id),
    onOpenTransaction: onViewNextTransaction
  }), [filters.transactionState, onMoveTransactionForReviewTab, onViewNextTransaction])

  return {
    accountId,
    columns: columnDefinitions,
    containerElement,
    filters,
    emptyTableMessage: isFiltersChanged ? Messages.NothingFoundState : Messages.EmptyTableState,
    isLoading: isLoading ?? (accountId == null),
    dateFiltersResetMarker,
    onBottomReached,
    onCategorizeRow,
    onExcludeRow,
    onFiltersPanelChange,
    onSearch,
    onSearchChange: onSearchPanelChange,
    onSelectTab,
    onRowClick,
    onSortingChange: setSorting,
    onViewPreloadedTransaction,
    refRowStates,
    rows,
    search,
    selectedTabId: filters.transactionState ?? 0,
    showError,
    sorting,
    tabCounters,
    transactionDetailsProps: {
      ...transactionModal,
      bankStatementId,
      bankStatementMatches,
      onCancel: onCancelPreloadedTransaction,
      onCloseModal: onClosePreloadedOrChainedTransaction,
      onBankStatementClear,
      onSubmit: onSubmitPreloadedOrChainedTransaction,
      onViewNextTransaction,
      preloadedTransactionData: transaction,
      transactionCreationMode,
      transactionId,
      transactionTypeId
    },
    addCustomerProps: {
      isOpen: customerModal.isModalOpen,
      onClose: onCloseAddCustomer,
      onOpenModal: onAddCustomer,
      onSubmit: onSubmitAddCustomer
    },
    addVendorProps: {
      isOpen: vendorModal.isModalOpen,
      onClose: onCloseAddVendor,
      onOpenModal: onAddVendor,
      onSubmit: onSubmitAddVendor
    }
  }
}

export default useBankStatementsList
