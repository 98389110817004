import { type FC } from 'react'
import { FormDropdown } from '@carfluent/common'

import type { DictionaryItems } from 'api/types'
import { getDisplayItem } from 'utils/selectHelpers'

import { CLASS_NAME, POPOVER_CLASS_NAME } from './styles'

const TITLE = 'Source type'

interface StatusTypeFilterProps {
  options: DictionaryItems<string>
  selectedItems: DictionaryItems<string>
  onChange: (values: DictionaryItems<string>) => void
}

const StatusTypeFilter: FC<StatusTypeFilterProps> = ({
  options,
  selectedItems,
  onChange
}) => {
  const isAllSelected = options.length === selectedItems.length

  return (
    <div className={CLASS_NAME}>
      <FormDropdown
        className='status-type-filter'
        id='sourceType'
        isMultiselect
        options={options}
        value={selectedItems}
        popoverClassName={POPOVER_CLASS_NAME}
        onChange={(_m, value) => {
          onChange(value == null ? [] : value)
        }}
        disableClearable={isAllSelected}
        renderSelectDisplay={() => {
          const title = isAllSelected
            ? `${TITLE} (All)`
            : selectedItems.length === 0
              ? TITLE
              : `${TITLE} (${selectedItems.length})`

          return getDisplayItem(title)
        }}
      />
    </div>
  )
}

export default StatusTypeFilter
