import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  formHelperText: {
    color: '#B00020',
    paddingLeft: 12,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 0.4,
    position: 'absolute',
    top: '100%',
    width: '100%'
  }
})
