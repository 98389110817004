import { cn } from '@carfluent/common'
import { Skeleton } from '@material-ui/lab'
import range from 'lodash-es/range'

import formatCurrencyLabel from 'utils/accounting/formatCurrencyLabel'

import CLASS_NAME from './styles'

export interface ListOfBalanceProps<T> {
  activeItem: T | null
  getItemId: (item: T) => number
  getItemName: (item: T) => string
  getItemBusinessName?: (item: T) => string
  isLoading?: boolean
  items: T[]
  listTitle: string
  onClickItem?: (item: T) => void
}

export interface ItemWithBalance {
  balance: number
}

const VISIBLE_ELEMENTS_NUM = 8

function ListOfBalance <T extends ItemWithBalance> ({
  activeItem,
  getItemId,
  getItemName,
  getItemBusinessName,
  isLoading = false,
  items,
  listTitle,
  onClickItem
}: ListOfBalanceProps<T>): JSX.Element {
  const activeItemId = activeItem != null ? getItemId(activeItem) : null

  return (
    <div className={cn(CLASS_NAME, 'cf-list-of-balance')}>
      <div className='list-title'>{listTitle}</div>
      <div className='list'>
        {isLoading && (range(0, VISIBLE_ELEMENTS_NUM)).map((_, idx) => {
          return (
            <div key={idx} className='item cf-skeleton-item'>
              <Skeleton className='cf-skeleton-left-part' variant='text' />
              <Skeleton className='cf-skeleton-right-part' variant='text' />
            </div>
          )
        })}

        {!isLoading && (items ?? []).map((item) => {
          const id = getItemId(item)
          const name = getItemName(item)
          const businessName = getItemBusinessName?.(item)

          return (
            <div
              key={id}
              className={cn('item', (activeItemId === id) && 'active')}
              onClick={() => onClickItem?.(item)}
            >
              <div className='item-name'>{businessName ?? name}</div>
              <div className='item-price'>{formatCurrencyLabel(item.balance)}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ListOfBalance
