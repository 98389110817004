import { type FC, Fragment, useMemo } from 'react'
import { cn } from '@carfluent/common'

import { TransactionStateId } from 'api/types'

import type { TransactionsReportLine, TransactionsTableData } from './components/LinesTable'
import LinesTable from './components/LinesTable'
import NoContent from './components/NoContent'

export type { TransactionsReportLine } from './components/LinesTable'

export interface TransactionData {
  date: Date | string | null
  description: string | null
  transactionStateId: TransactionStateId | null
  lines: TransactionsReportLine[]
}
export interface TransactionsReport {
  totalCredits: number
  totalDebits: number
  transactions: TransactionData[]
}

export interface TransactionsTableProps {
  className?: string
  data: TransactionsReport
}

const TransactionsTable: FC<TransactionsTableProps> = ({
  className,
  data
}) => {
  const { transactions, totalCredits, totalDebits } = data
  const totalByLines = useMemo((): TransactionsTableData => ({
    date: '',
    description: '',
    transactionStateId: TransactionStateId.Filled,
    lines: [{
      accountNumber: null,
      accountName: '',
      note: '',
      referenceNo: '',
      control: '',
      debits: totalDebits,
      credits: totalCredits
    }]
  }), [totalCredits, totalDebits])

  const isEmpty = transactions.length === 0

  return (
    <table className={cn('cf-transactions-table', className)}>
      <thead>
        <tr>
          <th className='cf-account-cell'>Account</th>
          <th className='cf-name-cell'>Name</th>
          <th className='cf-reference-cell'>Reference&nbsp;#</th>
          <th className='cf-control-cell'>Control</th>
          <th className='cf-notes-cell'>Notes</th>
          <th className='cf-money-cell'>Debits</th>
          <th className='cf-money-cell'>Credits</th>
        </tr>
      </thead>

      {isEmpty && (<NoContent />)}

      {!isEmpty && (transactions.map((t, tIdx) => (
        <Fragment key={tIdx}>
          <tbody className='cf-row-filler cf-top-filler'>
            <tr><td colSpan={8} /></tr>
          </tbody>

          <LinesTable lines={t.lines} date={t.date} description={t.description} transactionStateId={t.transactionStateId} />

          <tbody className='cf-row-filler cf-bottom-filler'>
            <tr><td colSpan={8} /></tr>
          </tbody>
        </Fragment>
      )))}

      <LinesTable className='cf-row-summary' total {...totalByLines} />
    </table>
  )
}

export default TransactionsTable
