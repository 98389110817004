import type { FC } from 'react'
import { CellWrapper, cnx } from '@carfluent/common'

import CLASS_NAME from './styles'

export interface StatusCellProps {
  getValue: () => string
}

const StatusCell: FC<StatusCellProps> = ({ getValue }) => {
  const name = getValue()

  return (
    <CellWrapper className={CLASS_NAME}>
      <span className={cnx('status-container', `status-${name != null ? name.toLowerCase() : 'no-action'}`)}>
        {name}
      </span>
    </CellWrapper>
  )
}

export default StatusCell
