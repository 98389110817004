import { css } from '@emotion/css'

export default css(`
  &.copy-button {
    margin-left: 9px;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
`)
