import { css } from '@emotion/css'

export default css(`
  .inspection-list > h2 {
    color: #101010;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 8px;
    line-height: 16px;
    background: #fafafa;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    border-radius: 2px 2px 0 0;
  }

  .inspection-list {
    margin-bottom: 16px;
  }

  .empty-block {
    display: flex;
    justify-content: center;

    padding: 18px;
    border-radius: 2px;
    background: #FFFFFF;

    color: #101010;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`)
