import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { type BaseListItem } from 'types'
import { type DealFeesAndCoveragesSettingsModel } from 'api/types/responses'
import { type BusinessTypeDto } from 'api/types'
import CustomersCoreApiProvider from 'api/customersCore.api'
import CoverageApiProvider from 'api/coverage.api'
import { GET_DEFAULT_DEAL } from 'api/defaults'
import useEffectOnce from 'hooks/useEffectOnce'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

import type { CreditApplicationFormData } from './types'
import { parseCreditApplicationData } from './parser'

export interface CreditAppPageReturn {
  dealId: string
  feesAndCoveragesSettings: DealFeesAndCoveragesSettingsModel | null
  isLoading: boolean
  isTradeInSectionVisible: boolean
  isWarrantySectionVisible: boolean
  isCoApplicantSectionVisible: boolean
  creditAppData: CreditApplicationFormData
  employmentStatuses: BaseListItem[]
  housingStatuses: BaseListItem[]
  coApplicantTypes: BaseListItem[]
  wholesaleSourceTypes: BaseListItem[]
  businessTypes: BusinessTypeDto[]
  onToggleIsTradeInSectionVisible: () => void
  onToggleIsWarrantySectionVisible: () => void
  onToggleIsCoApplicantSectionVisible: () => void
  getCreditApplicationData: (withLoading: boolean) => Promise<void>
}

function useCreditAppPage (): CreditAppPageReturn {
  const { id: dealId = '' } = useParams<{ id: string }>()
  const { showAlert } = useCustomSnackbar()

  const [isLoading, setIsLoading] = useState(false)

  const [creditAppData, setCreditAppData] = useState(() => parseCreditApplicationData(GET_DEFAULT_DEAL(), null, {
    penProducts: {
      1: [],
      2: [],
      3: [],
      4: [],
      5: []
    },
    addedProducts: [],
    coverageDetails: [],
    initCoverageDetails: []
  }))

  const [feesAndCoveragesSettings, setFeesAndCoveragesSettings] =
    useState<DealFeesAndCoveragesSettingsModel | null>(null)

  const [employmentStatuses, setEmploymentStatuses] = useState<BaseListItem[]>([])
  const [housingStatuses, setHousingStatuses] = useState<BaseListItem[]>([])
  const [coApplicantTypes, setCoApplicantTypes] = useState<BaseListItem[]>([])
  const [wholesaleSourceTypes, setWholesaleSourceTypes] = useState<BaseListItem[]>([])
  const [businessTypes, setBusinessTypes] = useState<BusinessTypeDto[]>([])

  const [isTradeInSectionVisible, setIsTradeInSectionVisible] = useState(false)
  const [isWarrantySectionVisible, setIsWarrantySectionVisible] = useState(false)
  const [isCoApplicantSectionVisible, setIsCoApplicantSectionVisible] = useState(false)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onToggleIsTradeInSectionVisible = useCallback(() => {
    setIsTradeInSectionVisible((prev) => !prev)
  }, [])

  const onToggleIsWarrantySectionVisible = useCallback(() => {
    setIsWarrantySectionVisible((prev) => !prev)
  }, [])

  const onToggleIsCoApplicantSectionVisible = useCallback(() => {
    setIsCoApplicantSectionVisible((prev) => !prev)
  }, [])

  const getCreditApplicationData = useCallback(async (withLoading: boolean): Promise<void> => {
    if (withLoading) {
      setIsLoading(true)
    }

    try {
      const [data, feesAndCoveragesSettings, {
        penProducts,
        coverageDetails
      }] = await Promise.all([
        CustomersCoreApiProvider.getDeal(dealId),
        CustomersCoreApiProvider.getDealFeesSettings(dealId),
        CoverageApiProvider.getDealCoverages(dealId, false)
      ])

      const addedProducts = data.coverageDetails.filter(el => el.dealCoverageDefaultId !== null)
        .map(el => el.productTypeId)
      const parsedData = parseCreditApplicationData(data, feesAndCoveragesSettings, {
        penProducts,
        addedProducts,
        coverageDetails: coverageDetails.filter(el => addedProducts.includes(el.productTypeId)),
        initCoverageDetails: coverageDetails
      })

      setIsTradeInSectionVisible(parsedData.tradeInDetails.tradeInDetailsId != null)
      setIsWarrantySectionVisible(data.coverageDetails?.length > 0)
      setIsCoApplicantSectionVisible(parsedData.coApplicantFinancialDetails.coApplicantType > 0)

      setCreditAppData(parsedData)
      setFeesAndCoveragesSettings(feesAndCoveragesSettings)
    } catch {
      showAlert('Error while loading credit application data.')
    } finally {
      if (withLoading) {
        setIsLoading(false)
      }
    }
  }, [dealId])

  // ========================================== //
  //                DICTIONARIES                //
  // ========================================== //

  const getHousingStatuses = async (): Promise<void> => {
    try {
      const data = await CustomersCoreApiProvider.getHousingStatuses()
      setHousingStatuses(data)
    } catch {
      showAlert('Error while loading housing statuses.')
    }
  }

  const getEmploymentStatuses = async (): Promise<void> => {
    try {
      const data = await CustomersCoreApiProvider.getEmploymentStatuses()
      setEmploymentStatuses(data)
    } catch {
      showAlert('Error while loading employment statuses.')
    }
  }

  const getCoApplicantTypes = async (): Promise<void> => {
    try {
      const data = await CustomersCoreApiProvider.getCoApplicantTypes()
      setCoApplicantTypes(data)
    } catch {
      showAlert('Error while loading coapplicant types.')
    }
  }

  const getWholesaleSourceTypes = async (): Promise<void> => {
    try {
      const data = await CustomersCoreApiProvider.getWholesaleSourceTypes()
      setWholesaleSourceTypes(data)
    } catch {
      showAlert('Error while loading wholesale source types.')
    }
  }

  const getBusinessTypes = async (): Promise<void> => {
    try {
      const response = await CustomersCoreApiProvider.getBusinessTypes()
      setBusinessTypes(response.items)
    } catch {
      // DO NOTHING
    }
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffectOnce(() => {
    void getCreditApplicationData(true)
    void getHousingStatuses()
    void getEmploymentStatuses()
    void getCoApplicantTypes()
    void getWholesaleSourceTypes()
    void getBusinessTypes()
  }, [])

  // ========================================== //

  return {
    dealId,
    feesAndCoveragesSettings,
    isLoading,
    isTradeInSectionVisible,
    isWarrantySectionVisible,
    isCoApplicantSectionVisible,
    creditAppData,
    employmentStatuses,
    housingStatuses,
    coApplicantTypes,
    wholesaleSourceTypes,
    businessTypes,
    getCreditApplicationData,
    onToggleIsTradeInSectionVisible,
    onToggleIsWarrantySectionVisible,
    onToggleIsCoApplicantSectionVisible
  }
}

export default useCreditAppPage
