import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGClip: FC<IconProps> = ({ color = '#000000', width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18 16.5L6.5 16.5C4.29 16.5 2.5 14.71 2.5 12.5C2.5 10.29 4.29 8.5 6.5 8.5L19 8.5C20.38 8.5 21.5 9.62 21.5 11C21.5 12.38 20.38 13.5 19 13.5L8.5 13.5C7.95 13.5 7.5 13.05 7.5 12.5C7.5 11.95 7.95 11.5 8.5 11.5L18 11.5L18 10L8.5 10C7.12 10 6 11.12 6 12.5C6 13.88 7.12 15 8.5 15L19 15C21.21 15 23 13.21 23 11C23 8.79 21.21 7 19 7L6.5 7C3.46 7 1 9.46 1 12.5C1 15.54 3.46 18 6.5 18L18 18L18 16.5Z'
        fill={color}
      />
    </svg>
  )
}

export default IconSVGClip
