import { useRef, useState, useEffect, useMemo, type FC, type ReactNode } from 'react'
import { cnx, formatInteger } from '@carfluent/common'
import { Link } from 'react-router-dom'

import SectionItem from 'components/common/SectionItem'
import CarImage from 'components/common/CarImage'
import TruncatedItemsList from 'components/common/TruncatedItemsList'
import CopyButton from 'components/common/CopyButton'
import DepositedLabel from 'components/common/DepositedLabel'
import CompleteOrIncomplete from 'components/deals/CompleteOrIncomplete'

import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { type ReconVehicleDetailsResponseDto } from 'api/types'
import { VehicleDetails } from 'constants/route_helper'
import { VehicleStateNames } from 'constants/names'

import DisDirLabel from './DisDirLabel'
import Priority from './Priority'
import LoaderSkeleton from './components/LoaderSkeleton'

import CLASS_NAME, { getTruncatedClassName } from './styles'
import TooltipedText from 'components/common/TooltipedText'

const MAX_ROWS = 1

enum ImageSize {
  Width = 99,
  Height = 64
}

interface InfoPanelProps extends ReconVehicleDetailsResponseDto {
  onUpdateFavorite: (isFavorite: boolean) => Promise<void>
  initLoading: boolean
}

const getStatusLabel = (isSold: boolean, isDeposited: boolean): ReactNode => {
  if (isSold) {
    return <CompleteOrIncomplete isComplete />
  }

  if (isDeposited) {
    return <DepositedLabel />
  }

  return null
}

const InfoPanel: FC<InfoPanelProps> = ({
  id,
  mileage,
  vin,
  stepDate,
  createdDate,
  dirState,
  disState,
  stock,
  inventoryDate,
  mainImageUrl,
  onUpdateFavorite,
  isFavorite,
  isDeposited,
  vehicleState,
  make,
  model,
  year,
  trim,
  tags,
  initLoading
}) => {
  const [titleWidth, setTitleWidth] = useState<number | null>(null)
  const titleRef = useRef<HTMLDivElement | null>(null)

  const truncatedClassName = useMemo(() => getTruncatedClassName(titleWidth), [titleWidth])
  const { showSuccess } = useCustomSnackbar()

  useEffect(() => {
    if (titleRef.current != null) {
      const { width } = titleRef.current.getBoundingClientRect()
      setTitleWidth(width)
    }
  }, [year, make, model, trim])

  if (initLoading) {
    return <LoaderSkeleton />
  }

  const isSold = vehicleState === VehicleStateNames.Sold

  return (
    <div className={CLASS_NAME}>
      <div className='cf-car-info-panel'>
        <div ref={titleRef} className='car-title-wrapper'>
          <Priority
            isFavorite={isFavorite}
            onUpdateFavorite={onUpdateFavorite}
          />
          <TooltipedText
            className={cnx((isDeposited || isSold) && 'with-label')}
            value={`
              ${(year !== 0 && year !== null) ? year : ''} ${make ?? ''} ${model ?? ''} ${trim ?? ''}
            `}
          />

          {getStatusLabel(isSold, isDeposited)}
          <TruncatedItemsList
            maxRows={MAX_ROWS}
            items={tags}
            className={truncatedClassName}
          />
        </div>

      </div>

      <div className='cf-car-info-block'>
        <CarImage
          height={ImageSize.Height}
          width={ImageSize.Width}
          vehicleImageUrl={mainImageUrl}
        />

        <div className='cf-car-info'>
          <SectionItem
            label='VIN:'
            value={vin}
            className='cf-vin cf-with-copy-button'
            extraContent={<CopyButton nameOfField='VIN' value={vin} showConfirmation={showSuccess} />}
          />

          <SectionItem
            label='Stock #:'
            value={<Link to={VehicleDetails(id)}>{stock}</Link>}
            className='cf-stock cf-with-copy-button'
            extraContent={<CopyButton nameOfField='Stock #' value={stock} showConfirmation={showSuccess} />}
          />

          <SectionItem
            label='Mileage:'
            className='cf-mileage cf-car-value-item'
            value={(mileage != null && mileage !== 0)
              ? `${formatInteger(mileage)} mi`
              : '-'}
          />

          <SectionItem
            label='DMS:'
            className='cf-dms cf-car-value-item'
            value={<DisDirLabel value={inventoryDate} />}
          />

          <SectionItem
            label='DIS:'
            className='cf-dis cf-car-value-item'
            value={<DisDirLabel value={stepDate} status={disState} />}
          />

          <SectionItem
            label='DIR:'
            className='cf-dir cf-car-value-item'
            value={<DisDirLabel value={createdDate} status={dirState} />}
          />
        </div>
      </div>
    </div>
  )
}

export default InfoPanel
