import type { FC } from 'react'
import type { ChangeEventHandler } from '@carfluent/common/dist/UI/Dropdown/types'
import type { EditorState } from 'draft-js'

import { type EmailSnippetDto } from 'api/types/responses'

import useTogglableButton, { type UseTogglableButtonProps } from './hook'

export interface TogglableContentProps extends UseTogglableButtonProps {
  label: string
  editorState?: EditorState
  isOpen?: boolean
  onChange?: (change: EditorState) => void
  onDropdownChange?: ChangeEventHandler<EmailSnippetDto | null>
}

export type TogglableButtonProps<T extends TogglableContentProps> = T & {
  renderContent: FC<T>
}

function TogglableButton <T extends TogglableContentProps> ({
  renderContent: Togglable,
  ...props
}: TogglableButtonProps<T>): JSX.Element {
  const { isOpen, onToggle } = useTogglableButton(props)

  return (
    <div className='togglable-toolbar-button'>
      <div onClickCapture={onToggle}>
        <Togglable {...props as unknown as T} isOpen={isOpen} />
      </div>
    </div>
  )
}

const TogglableButtonHOC = <T extends TogglableContentProps>(Component: FC<T>) => (props: T) => (
  <TogglableButton renderContent={Component} {...props} />
)

export default TogglableButtonHOC
