import { type FC } from 'react'
import { cnx, Button, MessageDialog } from '@carfluent/common'

import AccountingHOK from 'pages/accounting/AccountingHOC'
import BackToTop from 'components/common/BackToTop'
import RowForReconciliation from 'components/accounting/RowForReconciliation'
import SaveCloseButtonsPanel from 'components/common/SaveCloseButtonsPanel'
import Table from 'components/common/Table'
import TransactionDialogController from 'components/accounting/TransactionDialogController'

import LastReconciliationDates from './components/LastReconciliationDates'
import ReconciliationBalances from './components/ReconciliationBalances'
import EditFormModal from './components/EditForm'
import useReconciliationDraft from './hook'
import { Messages } from './hook/constants'
import CLASS_NAME from './styles'

const ReconciliationDraft: FC = () => {
  const {
    rows,
    sorting,
    accountName,
    columns,
    difference,
    differenceError,
    emptyTableMessage,
    isGlobalLoading,
    isSubmitDisabled,
    isTableLoading,
    editDialogProps,
    prevReconciliationSession,
    reconciliationSummary,
    sessionFinishDialogProps,
    transactionDialogControllerProps,
    onSubmitEditReconciliation,
    onCloseReconciliation,
    onFinishReconciliationStart,
    onFinishReconciliationSubmit,
    onOpenTransactionDetails,
    onSortingChange
  } = useReconciliationDraft()

  return (
    <div className={cnx('g-content-layout', CLASS_NAME)}>
      <div className='header-block'>
        <div className='left-panel'>
          <h5>{accountName}</h5>
        </div>

        <div className='right-panel'>
          <LastReconciliationDates data={prevReconciliationSession} />

          <Button
            className='g-button-edit'
            dataTestId='btn-update'
            isContentSized
            color='secondary'
            variant='outlined'
            onClick={() => editDialogProps.onOpenModal()}
          >
            Edit
          </Button>
        </div>
      </div>

      <ReconciliationBalances
        difference={difference}
        isLoading={isGlobalLoading || isTableLoading}
        summary={reconciliationSummary}
      />

      <Table
        columns={columns}
        data={rows}
        emptyTableMessage={emptyTableMessage}
        isLoading={isTableLoading}
        onSortingChange={onSortingChange}
        onRowClick={onOpenTransactionDetails}
        RowComponent={RowForReconciliation}
        sorting={sorting}
      />

      <SaveCloseButtonsPanel
        handleReset={onCloseReconciliation}
        handleSubmit={onFinishReconciliationStart}
        isSubmitting={isGlobalLoading}
        isSubmitDisabled={isSubmitDisabled}
        titleCloseButton='CLOSE'
        titleSaveButton='FINISH'
        tooltipSaveButton={isSubmitDisabled ? Messages.ReconciliationCannotBeFinished : ''}
        validationMessage={
          <h6 className={cnx('validation-message', (difference !== 0) && 'error-text')}>{differenceError}</h6>
        }
      />

      <EditFormModal
        editModal={editDialogProps}
        prevReconciliationSession={prevReconciliationSession}
        onSubmitEditReconciliation={onSubmitEditReconciliation}
      />

      <MessageDialog
        isOpen={sessionFinishDialogProps.isModalOpen}
        onClose={sessionFinishDialogProps?.onCloseModal}
        onCancel={sessionFinishDialogProps?.onCloseModal}
        onSubmit={onFinishReconciliationSubmit}
        title='Finish reconciliation?'
        textSubmit='YES, FINISH'
      >
        <p>
          By finishing reconciliation, you will confirm the matched transactions.
          Are you sure you want to proceed?
        </p>
      </MessageDialog>

      <TransactionDialogController {...transactionDialogControllerProps} />

      <BackToTop />
    </div>
  )
}

export default AccountingHOK(ReconciliationDraft)
