import { css } from '@emotion/css'

export default css(`
  max-width: 100%;
  gap: 16px;
  display: grid;
  grid-template-rows: 300px auto;
  grid-template-columns: calc(60% - 24px) calc(40% + 8px);
  grid-template-areas:
    "graph-view price-history"
    "leads-table leads-table";

  .border-styles {
    border-radius: 8px;
    border: 1px solid #0000001F
  }
  
  .graph-view {
    height: 300px;
    display: flex;
    padding-top: 16px;
    align-items: center;
    grid-area: graph-view;
    justify-content: center;
  }

  .no-data-label {
    font-size: 16px;
    color: #21212180;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
  }

  .price-history {
    gap: 8px;
    display: grid;
    grid-area: price-history;
    grid-template-rows: 60px 1fr;
    grid-template-columns: repeat(3, 1fr);

    .page-visits { grid-area: 1 / 1 / 2 / 2; }
    .leads-for-days { grid-area: 1 / 2 / 2 / 3; }
    .total-leads {
      grid-area: 1 / 3 / 2 / 4;

      h6 {
        display: flex;

        div {
          margin-left: 4px;
        }
      }
    }
    .price-history-table {
      padding: 16px;
      padding-bottom: 0;
      position: relative;
      grid-area: 2 / 1 / 3 / 4;
    }

    > div {
      padding: 8px;
    }

    h6 {
      font-size: 12px;
      color: #21212180;
      font-weight: 400;
      line-height: 16px;
      line-height: 16px;
      letter-spacing: 0.4px;
      letter-spacing: 0.4px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
    }
  }
  .price-history-table {
    grid-area: price-history-table;
  }

  .leads-table {
    max-width: 100%;
    grid-area: leads-table;
  }

  div.cf-no-data-block, .cf-table-row {
    border-top: none;
  }

  .lead-label-container {
    display: flex;

    > div {
      align-items: center;
    }
  }

  .cf-table-cell {
    :first-of-type {
      width: auto;

      .tooltip-content {
        font-weight: 500;
      }

      .label-item {
        margin-left: 5px;
      }
    }

    :nth-of-type(2) {
      min-width: 120px;
    }

    :nth-of-type(3) {
      min-width: 160px;
    }

    :nth-of-type(4) {
      min-width: 132px;
    }

    :nth-of-type(5) {
      min-width: 100px;
    }

    :nth-of-type(6) {
      min-width: 80px;
    }

    :nth-of-type(7) {
      min-width: 160px;
    }

    :last-of-type {
      min-width: 140px;

      > div {
        padding-right: 8px;
      }
    }
  }
`)

export const TOOLTIP_CLASS_NAME = css(`
  min-width: 329px;
`)
