import { css } from '@emotion/css'

export default css(`
  .wrapper {
    margin: 0 auto;
    min-width: 900px;
    align-items: flex-start;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  h5 {
    margin-bottom: 24px;
  }

  .reconciled-info {
    display: flex;
    margin-bottom: 24px;

    div {
      display: flex;
      align-items: center;
    }
  }

  .beginning-balance {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.12);

    .title {
      color: #000;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      margin-bottom: 8px;
    }

    .price {
      color: #101010;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
    }
  }

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 320px;
    .date-picker {
      .MuiInputLabel-formControl {
        color: rgba(33, 33, 33, 0.8);
        &.Mui-error {
          color: #B00020;
        }
      }
    }
  }

  .button-panel-container {
    width: calc(100% - 64px);
  }
`)
