import { type FC, type ReactNode } from 'react'
import { Button, Modal, UI, FormDatePicker, FormDropdown } from '@carfluent/common'
import { type TimeSelectOption } from '@carfluent/common/dist/UI/MultiDateTimeSelect'
import { addHours, parse } from 'date-fns'

import type { DictionaryItem } from 'api/types'
import { WORK_SCHEDULE_MODAL_CONSTRAINT_ID, TIME_12H_MIN_AMPM_FORMAT } from 'constants/constants'
import { isValidDate } from 'utils/validation'
import NameWithAvatar from 'components/common/NameWithAvatar'
import ArrowBottomIcon from 'components/common/ArrowBottomIcon'
import { renderAssignOption as renderOption, renderNoUsersOption } from 'components/crm/common'

import { FieldIds, type ShiftDetailsModalProps } from './hook/types'
import { formatTime } from './hook/utils'
import useShiftDetailsModal from './hook'

import { MODAL_CLASS, END_TIME_OPTIONS_CONTAINER } from './styles'

const { MultiDateTimeSelect, TimeSelect } = UI

const MODAL_MIN_WIDTH = 544

const ShiftDetailsModal: FC<ShiftDetailsModalProps> = ({
  isOpen,
  isRemoving,
  shift,
  initialDate,
  position,
  onClose,
  onRemoveShift,
  shiftDetailsSubmit
}) => {
  const {
    isSubmitting,
    isFormChanged,
    options,
    values,
    errors,
    touched,
    onSubmit,
    onChange,
    onChangeTime,
    onBlur,
    countHours,
    onRemoveShiftClick
  } = useShiftDetailsModal({
    shift,
    isOpen,
    initialDate,
    shiftDetailsSubmit,
    onRemoveShift
  })
  const resolvedDate = isValidDate(values.date) ? values.date : initialDate ?? new Date()

  const title = shift == null ? 'Add shift' : 'Edit shift'

  const renderFooter = (): ReactNode => {
    if (shift == null) {
      return (
        <Button
          className='cf-add-btn'
          onClick={onSubmit}
          variant='text'
        >
          Add shift
        </Button>
      )
    }

    return (
      <>
        <Button
          className='cf-remove-btn'
          onClick={onRemoveShiftClick}
          variant='text'
        >
          Remove shift
        </Button>
        <Button
          className='cf-save-btn'
          onClick={onSubmit}
          isDisabled={!isFormChanged()}
          variant='text'
        >
          Save shift
        </Button>
      </>
    )
  }

  const renderEndTimeOption = (option: TimeSelectOption): ReactNode => {
    const value = option.date
    const placeholder = value.getDate() === values.date?.getDate()
      ? `(${countHours(value)}h)`
      : `(${countHours(value)}h - next day)`

    return (
      <div className={END_TIME_OPTIONS_CONTAINER}>
        {formatTime(value)}
        <span>
          {placeholder}
        </span>
      </div>
    )
  }

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      className={MODAL_CLASS}
      minWidth={MODAL_MIN_WIDTH}
      isDraggable
      isLoading={isRemoving || isSubmitting}
      transform={position}
      renderFooterActions={renderFooter}
      constraintId={WORK_SCHEDULE_MODAL_CONSTRAINT_ID}
    >
      <FormDropdown<DictionaryItem>
        id={FieldIds.Person}
        label='Person'
        blurMode='select-first'
        options={options}
        renderOption={renderOption}
        renderNoOptions={renderNoUsersOption}
        startAdornment={<NameWithAvatar showAvatar avatarOnly name={values[FieldIds.Person]?.name} />}
        onChange={onChange}
        onBlur={onBlur}
        value={values[FieldIds.Person]}
        error={errors[FieldIds.Person]}
        touched={touched[FieldIds.Person]}
        dataTestId='shift-modal-person'
      />
      <FormDatePicker
        id={FieldIds.Date}
        label='Start date'
        onChange={onChange}
        onBlur={onBlur}
        value={values.date}
        error={errors.date}
        touched={touched.date}
        data-test-id='shift-modal-due-date'
      />
      <TimeSelect
        classes={{
          root: 'cf-startTime-select'
        }}
        value={values.startTime}
        id={FieldIds.StartTime}
        label='Start time'
        onChange={onChangeTime}
        data-test-id='shift-modal-time'
        opening='12:00 AM'
        DropdownIconComponent={ArrowBottomIcon}
      />
      <MultiDateTimeSelect
        classes={{
          root: 'cf-endTime-select'
        }}
        id={FieldIds.EndTime}
        value={{ time: values.endTime, date: resolvedDate }}
        startDate={resolvedDate}
        label='End time'
        onChange={({ time }) => { onChange(FieldIds.EndTime, time) }}
        data-test-id='shift-modal-end-time'
        step={15}
        renderOption={renderEndTimeOption}
        isNextDay
        opening={formatTime(addHours(parse(values.startTime, TIME_12H_MIN_AMPM_FORMAT, resolvedDate), 1))}
        closing={values.startTime}
        DropdownIconComponent={ArrowBottomIcon}
      />
    </Modal>
  )
}

export default ShiftDetailsModal
