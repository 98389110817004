import { GET_DEFAULT_COVERAGE_DETAILS_OLD } from 'api/defaults/deals'
import { CoverageDetails } from 'api/types'

export const TERM_OPTIONS = [
  '3 months / 3,000 miles',
  '6 months / 6,000 miles',
  '9 months / 9,000 miles',
  '12 months / 12,000 miles',
  '15 months / 15,000 miles',
  '18 months / 18,000 miles',
  '21 months / 21,000 miles',
  '24 months / 24,000 miles',
  '27 months / 27,000 miles',
  '30 months / 30,000 miles',
  '33 months / 33,000 miles',
  '36 months / 36,000 miles',
  '39 months / 39,000 miles',
  '42 months / 42,000 miles',
  '45 months / 45,000 miles',
  '48 months / 48,000 miles',
  '51 months / 51,000 miles',
  '54 months / 57,000 miles',
  '57 months / 57,000 miles',
  '60 months / 60,000 miles',
  '63 months / 63,000 miles',
  '66 months / 66,000 miles',
  '69 months / 69,000 miles',
  '72 months / 72,000 miles'
]

export const COVERAGE_NAME_MAX_LENGTH = 255

export const DEDUCTIBLE_AMOUNT_MAX_VALUE = 9999999

export interface DealerProductMin {
  productType: string | null
  productName: string | null
}
export const getDefaultManualFormData = (product: DealerProductMin | null): Omit<CoverageDetails, 'productTypeId' | 'dealCoverageDefaultId'> => ({
  ...GET_DEFAULT_COVERAGE_DETAILS_OLD(),
  coverageName: '',
  dealerCost: null,
  deductibleAmount: null,
  dealerRetailPrice: null,
  productName: product?.productName ?? null,
  productType: product?.productType ?? null,
  termMonths: null,
  termMiles: null
})
