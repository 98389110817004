import type { FC } from 'react'
import {
  cnx,
  FormDropdown,
  FormNumberInput
} from '@carfluent/common'

import InfoIconWithTooltip from 'components/common/InfoIconWithTooltip'
import UnsavedChangesDialog from 'components/dialogs/UnsavedChanges'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import CheckboxLabel from 'components/common/CheckboxLabel'
import Toggle from 'components/common/Toggle'

import Skeleton from './Skeleton'

import useGeneralSettings from './hook'
import {
  AdditionalRecipientsIds,
  DEFAULT_PRICE_DROP_THRESHOLD,
  DURATION_OPTIONS,
  REMIND_TIME_OPTIONS
} from './hook/constants'
import CLASS_NAME, { POPOVER_CLASS_NAME, TOOLTIP_CLASS_NAME } from './styles'
import { Item, OptionRendererConfig } from '@carfluent/common/dist/UI/Dropdown/types'
import { formatPhoneNumberForView } from '../../../utils/view_helper'
import type { UserItem } from './hook/types'

const TOOLTIP_DESCRIPTION = 'Round Robin is a re-assign algorithm CRM uses to shuffle tasks among selected users. Unchecked users will not participate in auto-assign.'

const renderOption = (data: UserItem, other: OptionRendererConfig): JSX.Element => {
  const getPhoneNumber = (): string | null => {
    if (data.phoneNumber === undefined) {
      return '-'
    }

    return data.phoneNumber != null && data.phoneNumber.trim() !== ''
      ? formatPhoneNumberForView(data.phoneNumber)
      : 'No phone number'
  }
  return (
    <div className='render-option'>
      {other.renderCheckbox(other.isSelected)}

      <div className='option-content'>
        <p>
          {data.name}
        </p>

        <span>
          {getPhoneNumber()}
        </span>
      </div>
    </div>
  )
}

const isOptionDisabled = (item: Item<UserItem>): boolean => {
  return (item.value.phoneNumber == null || item.value.phoneNumber.trim() === '') &&
    item.value.id !== AdditionalRecipientsIds.LeadOwner &&
    item.value.id !== AdditionalRecipientsIds.AppointmentTaskAssignee
}

const CRMGeneralSettings: FC = () => {
  const {
    isLoading,
    unsavedChangesModalProps,
    isSubmitting,
    emailTemplates,
    messageTemplates,
    values,
    errors,
    touched,
    usersOptions,
    bdcUsers,
    salesTeamUsers,
    hasChanges,
    onChange,
    onBlur,
    onSubmit,
    onCancelChangeForm,
    onUpdateRoundRobinUsers,
    recipients,
    setFieldTouched
  } = useGeneralSettings()

  return (
    <div className={cnx('g-submenu-content g-margin-top-big general-settings-page', CLASS_NAME)}>
      <div className='form-block'>
        {isLoading && <Skeleton />}
        <div className='toggle-with-edit'>
          <Toggle
            toggled={values.roundRobinEnabled}
            activeLabel={
              <>
                Round Robin auto assign <InfoIconWithTooltip tooltipClassName={TOOLTIP_CLASS_NAME} placement='top' description={TOOLTIP_DESCRIPTION} />
              </>
              }
            onClick={(value) => onChange('roundRobinEnabled', value)}
            className='toggle with-tooltip'
          />
        </div>

        <div className={cnx('checkbox-wrapper', !values.roundRobinEnabled && 'hidden')}>
          {bdcUsers.length > 0 && (
            <>

              <div className='checkbox-title'>
                BDC team
              </div>

              <div className='checkbox-list'>
                {bdcUsers.map((user) => (

                  <CheckboxLabel
                    key={user.id}
                    value={user}
                    onChange={(e) => onUpdateRoundRobinUsers(user.id, e.target.checked)}
                  />

                ))}
              </div>
            </>
          )}

          {salesTeamUsers.length > 0 && (
            <>

              <div className='checkbox-title'>
                Sales team
              </div>

              <div className='checkbox-list'>
                {salesTeamUsers.map((user) => (

                  <CheckboxLabel
                    key={user.id}
                    value={user}
                    onChange={(e) => onUpdateRoundRobinUsers(user.id, e.target.checked)}
                  />

                ))}
              </div>
            </>
          )}
        </div>

        <p className={cnx('sub-title with-top', !values.roundRobinEnabled && 'hidden')}>
          If task not taken, auto reassign in:
        </p>

        <div className={cnx('input-wrapper', !values.roundRobinEnabled && 'hidden')}>
          <div className='input-wrapper-col'>
            <FormNumberInput
              id='roundRobinIntervalMinutes'
              label='Interval'
              value={values.roundRobinIntervalMinutes}
              onBlur={onBlur}
              onChange={onChange}
              error={errors.roundRobinIntervalMinutes}
              touched={touched.roundRobinIntervalMinutes}
              className='cf-interval'
              maxLength={4}
            />

            <FormDropdown
              id='duration'
              label='Duration'
              value={values.duration}
              options={DURATION_OPTIONS}
              onChange={onChange}
              onBlur={onBlur}
              className='cf-duration'
              disableClearable
            />

          </div>
        </div>
      </div>

      <div className='form-block'>

        <Toggle
          toggled
          activeLabel='System tasks assignee'
          className='toggle inactive'
        />

        <div className='input-wrapper'>

          <FormDropdown
            id='webLeadAssignedUserId'
            label='Web lead assignee'
            value={values.webLeadAssignedUserId}
            error={errors.webLeadAssignedUserId}
            touched={touched.webLeadAssignedUserId}
            options={usersOptions}
            onChange={onChange}
            onBlur={onBlur}
            className='cf-duration'
            popoverClassName={POPOVER_CLASS_NAME}
          />

          <FormDropdown
            id='messageReceivedAssignedUserId'
            label='Message received assignee'
            value={values.messageReceivedAssignedUserId}
            error={errors.messageReceivedAssignedUserId}
            touched={touched.messageReceivedAssignedUserId}
            options={usersOptions}
            onChange={onChange}
            onBlur={onBlur}
            className='cf-duration'
            popoverClassName={POPOVER_CLASS_NAME}
          />

          <FormDropdown
            id='emailReceivedAssignedUserId'
            label='Email received assignee'
            value={values.emailReceivedAssignedUserId}
            error={errors.emailReceivedAssignedUserId}
            touched={touched.emailReceivedAssignedUserId}
            options={usersOptions}
            onChange={onChange}
            onBlur={onBlur}
            className='cf-duration'
            popoverClassName={POPOVER_CLASS_NAME}
          />

        </div>
      </div>

      <div className='form-block'>

        <Toggle
          toggled={values.creditAppTasksEnabled}
          activeLabel='Credit app received task'
          className='toggle'
          onClick={(value) => onChange('creditAppTasksEnabled', value)}
        />

        <div className={cnx('input-wrapper', !values.creditAppTasksEnabled && 'hidden')}>

          <FormDropdown
            id='creditAppAssignedUserId'
            label='Assign Credit app received tasks to'
            value={values.creditAppAssignedUserId}
            error={errors.creditAppAssignedUserId}
            touched={touched.creditAppAssignedUserId}
            options={usersOptions}
            onChange={onChange}
            onBlur={onBlur}
            className='cf-duration'
            popoverClassName={POPOVER_CLASS_NAME}
          />

        </div>
      </div>

      <div className='form-block'>

        <Toggle
          toggled={values.autoReplyEnabled}
          activeLabel='Web lead task auto-reply'
          onClick={(value: boolean) => onChange('autoReplyEnabled', value)}
          className='toggle'
        />

        <p className={cnx('toggle-subtitle', !values.autoReplyEnabled && 'hidden')}>
          Lead auto-reply for default availability request from web resources.
        </p>

        <div className={cnx('sub-title', !values.autoReplyEnabled && 'hidden')}>Email auto-reply templates:</div>

        <div className={cnx('input-wrapper', !values.autoReplyEnabled && 'hidden')}>
          <FormDropdown
            id='vehicleAvailableEmailTemplate'
            label='Car is available'
            value={values.vehicleAvailableEmailTemplate}
            options={emailTemplates ?? []}
            onChange={onChange}
            onBlur={onBlur}
            className='cf-duration'
            error={errors.vehicleAvailableEmailTemplate}
            touched={touched.vehicleAvailableEmailTemplate}
            popoverClassName={POPOVER_CLASS_NAME}
          />

          <FormDropdown
            id='vehicleUnavailableEmailTemplate'
            label='Car is unavailable'
            value={values.vehicleUnavailableEmailTemplate}
            options={emailTemplates ?? []}
            onChange={onChange}
            onBlur={onBlur}
            className='cf-duration'
            popoverClassName={POPOVER_CLASS_NAME}
            error={errors.vehicleUnavailableEmailTemplate}
            touched={touched.vehicleUnavailableEmailTemplate}
          />

        </div>

        <div className={cnx('sub-title with-top', !values.autoReplyEnabled && 'hidden')}>
          Message auto-reply templates:
        </div>

        <div className={cnx('input-wrapper', !values.autoReplyEnabled && 'hidden')}>
          <FormDropdown
            id='vehicleAvailableMessageTemplate'
            label='Car is available'
            value={values.vehicleAvailableMessageTemplate}
            options={messageTemplates ?? []}
            onChange={onChange}
            onBlur={onBlur}
            className='cf-duration'
            error={errors.vehicleAvailableMessageTemplate}
            touched={touched.vehicleAvailableMessageTemplate}
            popoverClassName={POPOVER_CLASS_NAME}
          />

          <FormDropdown
            id='vehicleUnavailableMessageTemplate'
            label='Car is unavailable'
            value={values.vehicleUnavailableMessageTemplate}
            options={messageTemplates ?? []}
            onChange={onChange}
            onBlur={onBlur}
            className='cf-duration'
            error={errors.vehicleUnavailableMessageTemplate}
            touched={touched.vehicleUnavailableMessageTemplate}
            popoverClassName={POPOVER_CLASS_NAME}
          />
        </div>
      </div>

      <div className='form-block'>
        <Toggle
          toggled
          className='toggle inactive'
          activeLabel='Appointment reminders'
        />

        <p className='toggle-subtitle'>
          System sends message reminders about appointments scheduled
        </p>

        <Toggle
          activeLabel='Remind Sales team via message'
          toggled={values.salesAppReminderEnabled}
          className='toggle subtitle'
          onClick={(value) => {
            onChange('salesAppReminderEnabled', value)

            if (!value) {
              setFieldTouched('salesAppReminderMinutes', false)
              setFieldTouched('appointmentReminderUsers', false)
            }
          }}
        />

        <div className={cnx('input-wrapper', !values.salesAppReminderEnabled && 'hidden')}>
          <FormDropdown
            id='salesAppReminderMinutes'
            label='When'
            value={values.salesAppReminderMinutes}
            error={errors.salesAppReminderMinutes}
            touched={touched.salesAppReminderMinutes}
            options={REMIND_TIME_OPTIONS}
            onChange={onChange}
            onBlur={onBlur}
            className='cf-duration'
            popoverClassName={POPOVER_CLASS_NAME}
          />

          <div className={cnx(errors.appointmentReminderUsers == null && 'recipients-wrapper')}>
            <FormDropdown
              id='appointmentReminderUsers'
              label='Recipients'
              isMultiselect
              isOptionDisabled={isOptionDisabled}
              renderOption={renderOption}
              value={values.appointmentReminderUsers}
              error={errors.appointmentReminderUsers}
              touched={touched.appointmentReminderUsers}
              onChange={onChange}
              onBlur={onBlur}
              options={recipients}
              className='cf-interval'
              popoverClassName={cnx(POPOVER_CLASS_NAME, 'multiselect')}
              disableSelectAll
            />
          </div>
        </div>

        <Toggle
          activeLabel='Remind Customer via message'
          toggled={values.customerAppReminderEnabled}
          className='toggle subtitle with-top'
          onClick={(value) => {
            onChange('customerAppReminderEnabled', value)

            if (!value) {
              setFieldTouched('customerAppReminderMinutes', false)
              setFieldTouched('customerAppReminderMessageTemplate', false)
            }
          }}
        />

        <div className={cnx('input-wrapper', !values.customerAppReminderEnabled && 'hidden')}>
          <FormDropdown
            id='customerAppReminderMinutes'
            label='When'
            value={values.customerAppReminderMinutes}
            error={errors.customerAppReminderMinutes}
            touched={touched.customerAppReminderMinutes}
            options={REMIND_TIME_OPTIONS}
            onChange={onChange}
            onBlur={onBlur}
            className='cf-duration'
            popoverClassName={POPOVER_CLASS_NAME}
          />

          <FormDropdown
            id='customerAppReminderMessageTemplate'
            label='Message template'
            value={values.customerAppReminderMessageTemplate}
            error={errors.customerAppReminderMessageTemplate}
            touched={touched.customerAppReminderMessageTemplate}
            options={messageTemplates ?? []}
            onChange={onChange}
            onBlur={onBlur}
            className='cf-duration'
            popoverClassName={POPOVER_CLASS_NAME}
          />
        </div>
      </div>

      <div className='form-block'>
        <Toggle
          toggled
          className='toggle inactive'
          activeLabel='Price drop alert'
        />

        <p className='toggle-subtitle'>
          When the price of the car of interest drops by a specified amount or more, the system automatically sends a message notification. The message will be sent on any price change if no amount is set. Cost of each message sending will be $0.01 for SMS and $0.02 for MMS.
        </p>

        <Toggle
          activeLabel='Email alert'
          toggled={values.isEmailOnPriceDropEnabled}
          className='toggle subtitle'
          onClick={(value) => {
            onChange('isEmailOnPriceDropEnabled', value)

            if (values.emailOnPriceDropThreshold == null) {
              onChange('emailOnPriceDropThreshold', DEFAULT_PRICE_DROP_THRESHOLD)
            }
          }}
        />

        <div className={cnx('input-wrapper', !values.isEmailOnPriceDropEnabled && 'hidden')}>

          <FormDropdown
            id='emailOnPriceDropEmailTemplate'
            label='Email template'
            value={values.emailOnPriceDropEmailTemplate}
            error={errors.emailOnPriceDropEmailTemplate}
            touched={touched.emailOnPriceDropEmailTemplate}
            options={emailTemplates ?? []}
            onChange={onChange}
            onBlur={onBlur}
            className='cf-duration'
            popoverClassName={POPOVER_CLASS_NAME}
          />

          <FormNumberInput
            id='emailOnPriceDropThreshold'
            label='Price change amount'
            isNegativeAllowed={false}
            value={values.emailOnPriceDropThreshold}
            error={errors.emailOnPriceDropThreshold}
            touched={touched.emailOnPriceDropThreshold}
            onChange={onChange}
            className='cf-interval'
            preset='financial'
          />
        </div>

        <Toggle
          activeLabel='Message alert'
          toggled={values.isMessageOnPriceDropEnabled}
          className='toggle subtitle with-top'
          onClick={(value) => {
            onChange('isMessageOnPriceDropEnabled', value)

            if (values.messageOnPriceDropThreshold == null) {
              onChange('messageOnPriceDropThreshold', DEFAULT_PRICE_DROP_THRESHOLD)
            }
          }}
        />

        <div className={cnx('input-wrapper', !values.isMessageOnPriceDropEnabled && 'hidden')}>
          <FormDropdown
            id='messageOnPriceDropEmailTemplate'
            label='Message template'
            value={values.messageOnPriceDropEmailTemplate}
            error={errors.messageOnPriceDropEmailTemplate}
            touched={touched.messageOnPriceDropEmailTemplate}
            options={messageTemplates ?? []}
            onChange={onChange}
            onBlur={onBlur}
            className='cf-duration'
            popoverClassName={POPOVER_CLASS_NAME}
          />

          <FormNumberInput
            id='messageOnPriceDropThreshold'
            label='Price change amount'
            isNegativeAllowed={false}
            value={values.messageOnPriceDropThreshold}
            error={errors.messageOnPriceDropThreshold}
            touched={touched.messageOnPriceDropThreshold}
            onChange={onChange}
            className='cf-interval'
            preset='financial'
          />

        </div>
      </div>

      <div className={cnx('tab-footer', hasChanges && 'is-shown')} role='footer'>
        <CancelSubmitActionsFooter
          isLoading={isSubmitting}
          onSubmit={onSubmit}
          onClose={onCancelChangeForm}
          cancelTitle='DISCARD'
          submitTitle='SAVE'
        />
      </div>

      <UnsavedChangesDialog
        content='Your changes will be lost if you don’t save them.'
        {...unsavedChangesModalProps}
        isSubmitting={isSubmitting}
      />
    </div>
  )
}

export default CRMGeneralSettings
