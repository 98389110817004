import type { CheckboxType } from 'types'
import type { DictionaryItems } from 'api/types'

const mapToCheckboxes = (items: DictionaryItems<string>, values: number[] = []): CheckboxType[] => {
  return items.map(item => {
    return {
      ...item,
      label: item.name,
      checked: values.includes(item.id)
    }
  })
}

export default mapToCheckboxes
