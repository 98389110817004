import { css } from '@emotion/css'

export default css(`
  .dropdown-container {
    width: 328px;
  }

  .section-title {
    margin-bottom: 16px;
  }

  .toggle-print-check-number {
    label {
      margin-left: 0;
    }

    .cf-main-block {
      background: #101010 
    }

    .cf-main-block.active {
      background: rgba(0, 0, 0, 0.30); 
    }

    .inactive {
      color: #101010;
    }
  }

  .print-check-info {
    width: 488px;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 24px;
    color: rgba(33, 33, 33, 0.50);

    span {
      font-weight: 500;
      letter-spacing: 0.1px;
      color: #101010;
    }
  }

  .print-check-form {
    display: flex;
    gap: 16px;

    .cf-form-field { width: 156px; }
  }

  .btn-enable-accounting {
    margin: 0px;
    margin-top: 32px;
    min-height: 56px;
    width: 212px;
  }

  .payment-section-container {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: var(--content-margin-default);

    .cf-button-content {
      font-size: 14px;
      font-weight: 500;
    }
  }
`)
