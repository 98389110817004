export interface WithUniqId {
  id: number
  uniqId: string
}

export const getFormIdsByUniqIds = (items: WithUniqId[], checkedItems: string[]): number[] => {
  return items.reduce<number[]>((res, item) => {
    if (checkedItems.includes(item.uniqId)) {
      res.push(item.id)
    }

    return res
  }, [])
}

export const getFormIdByUniqId = (items: WithUniqId[], id: string): number | null => {
  return items.find(({ uniqId }) => uniqId === id)?.id ?? null
}
