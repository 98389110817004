import { type DictionaryItem } from 'api/types'
import { type SaleRecordDto, SaleRecordModel } from 'api/types/responses'
import type { WorkflowTypeDto } from 'api/types/customersCore.types'

import { DealWorkflowAccountingStateNames, PaymentTypeMap } from 'constants/names'
import { DealWorkflowAccountingStateId } from 'types/number_enums'

export const GET_DEFAULT_ACCOUNTING_STATES = (): DictionaryItem[] => [
  {
    id: DealWorkflowAccountingStateId.Sold,
    name: DealWorkflowAccountingStateNames.Sold
  },
  {
    id: DealWorkflowAccountingStateId.PushedToAccounting,
    name: DealWorkflowAccountingStateNames.PushedToAccounting
  },
  {
    id: DealWorkflowAccountingStateId.Recorded,
    name: DealWorkflowAccountingStateNames.Recorded
  }
]

export const parserDealSaleReportDto = (records: SaleRecordDto[], types: WorkflowTypeDto[]): SaleRecordModel[] => {
  return records.map((record) => (
    {
      ...record,
      dealAccountingStateName: GET_DEFAULT_ACCOUNTING_STATES().find((state) => state.id === Number(record.dealAccountingStateId))?.name ?? '',
      dealTypeName: types?.find((type) => type.id === Number(record.dealTypeId))?.name ?? '',
      dealFinanceTypeName: record.dealFinanceTypeId != null ? PaymentTypeMap[Number(record?.dealFinanceTypeId)] : '-'
    }
  ))
}
