import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { dates, useLoader } from '@carfluent/common'
import queryString from 'query-string'
import { endOfDay } from 'date-fns'

import CRMApiProvider from 'api/crm.api'
import { type RangeFilter } from 'types'
import { type LeadsDashboardCrmModel } from 'api/types/responses'
import useAsyncEffect from 'hooks/useAsyncEffect'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

import { type UseDashboardCrmReturn, FilterTypesId, type DateRangeOption } from './types'
import serializeFilters from './serializer'
import { DEALS_FILTERS } from './constants'

const useDashboardCrm = (): UseDashboardCrmReturn => {
  const { search } = useLocation()
  const { isLoading: _isLoading } = queryString.parse(search)
  const { showAlert } = useCustomSnackbar()
  const { isLoading, startLoader, stopLoader } = useLoader()

  const [dateRangeFilter, setDateRangeFilter] =
    useState<DateRangeOption | null>(DEALS_FILTERS[2])

  const [data, setData] =
    useState<LeadsDashboardCrmModel | null>(null)

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useAsyncEffect(async () => {
    try {
      startLoader()

      const payload = serializeFilters(getFilterDateRange(dateRangeFilter?.id ?? null))
      if (payload != null) {
        const resp = await CRMApiProvider.getLeadsDashboard(payload)
        setData(resp)
      }
    } catch (err) {
      showAlert(err)
    } finally {
      stopLoader()
    }
  }, [
    dateRangeFilter,
    showAlert,
    startLoader,
    stopLoader
  ])

  // ========================================== //

  return {
    data,
    dateRange: dateRangeFilter,
    dateRangeOptions: DEALS_FILTERS,
    isLoading: isLoading || (_isLoading === 'true'),
    onChangeDateRange: setDateRangeFilter
  }
}

export default useDashboardCrm

// ========================================== //

const getFilterDateRange = (id: number | null): RangeFilter<Date | null> => {
  const date = new Date()

  switch (id) {
    case FilterTypesId.Yesterday: {
      const yesterdayStart = dates.getYesterday()
      const yesterdayEnd = endOfDay(yesterdayStart)

      return {
        from: yesterdayStart,
        to: yesterdayEnd
      }
    }

    case FilterTypesId.Last7Days: {
      return {
        from: dates.addDays(-6, date),
        to: date
      }
    }

    case FilterTypesId.Last14Days: {
      return {
        from: dates.addDays(-13, date),
        to: date
      }
    }

    case FilterTypesId.Last30Days: {
      return {
        from: dates.addDays(-29, date),
        to: date
      }
    }

    case FilterTypesId.Today:
    default: {
      const todayEnd = endOfDay(date)

      return {
        from: date,
        to: todayEnd
      }
    }
  }
}
