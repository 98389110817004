import { serializers } from '@carfluent/common'

import { type DictionaryItem, DisabilityEquipped } from 'api/types'
import { serializeTrim } from 'components/common/CarDetailsFields/hook/serializer'
import { isTruthy } from 'utils/general'
import type { AddNewVehicleFormData } from './types'

const { serializeDateTimeUTC } = serializers

export interface VehiclePostData {
  vin: string | null
  year: number | null
  make: string | null
  model: string | null
  trim: string | null
  mileage: number | null
  stock: string | null
  inventoryDate: string
  salePrice: number | null
  engine: string | null
  isDisabilityEquipped: boolean
  bodyType: number | null
  doorCount: number | null
  fuelType: number | null
  cylinderCount: number | null
  transmissionType: number | null
  drivetrainType: number | null
  genericColor: number | null
  genericInterior: number | null
  source: string | null
  accountId: number | null
  purchasePrice: number | null
  vendorId?: number | null
  buyerId: number | null
}

const serializeVehicleData = (data: AddNewVehicleFormData): VehiclePostData => {
  return {
    bodyType: data.body?.id ?? null,
    cylinderCount: data.engineCylinders?.id ?? null,
    doorCount: data.doors?.id ?? null,
    drivetrainType: data.drivetrain?.id ?? null,
    engine: data.engine,
    fuelType: data.fuelType?.id ?? null,
    genericColor: data.genericColor?.id ?? null,
    genericInterior: data.genericInterior?.id ?? null,
    inventoryDate: serializeDateTimeUTC(data.inventoryDate) ?? '',
    isDisabilityEquipped: data.disabilityEquipped?.name === DisabilityEquipped.Yes,
    make: data.make,
    mileage: parseInt(data.mileage ?? ''),
    model: data.model,
    salePrice: parseFloat((data.salePrice ?? 0).toString() ?? ''),
    stock: data.stock ?? '',
    transmissionType: data.transmission?.id ?? null,
    trim: serializeTrim(data.trim),
    vin: data.vin,
    year: data.year,
    source: data.source,
    purchasePrice: isTruthy(data.purchasePrice) ? data.purchasePrice : null,
    accountId: isTruthy(data.purchasePrice) ? Number(data.accountId) : null,
    vendorId: isTruthy(data.purchasePrice) ? data.vendor?.id : null,
    buyerId: (data.buyer as DictionaryItem)?.id ?? null// in form this field can be null, but it's required for submit
  }
}

export default serializeVehicleData
