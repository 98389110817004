import type { FormValidation, ValidationRule } from '@carfluent/common'

import { Errors, isIntervalLessOrGreater, required } from 'utils/validationPresets'
import type { CrmSettingsFormData } from './types'

const requiredCreditAppAssignedUserIdEnableSelected: ValidationRule<CrmSettingsFormData> = (
  value,
  values
) =>
  values?.creditAppTasksEnabled === true
    ? required(value)
    : null

const requiredRoundRobinEnableSelected: ValidationRule<CrmSettingsFormData> = (
  value,
  values
) =>
  values?.roundRobinEnabled === true
    ? isIntervalLessOrGreater(value)
    : null

const requiredRemindSalesMessageTime: ValidationRule<CrmSettingsFormData> = (
  value,
  values
) =>
  values?.salesAppReminderEnabled === true
    ? required(value)
    : null

const requiredRemindSalesViaMessageUsers: ValidationRule<CrmSettingsFormData> = (
  value,
  values
) =>
  values?.salesAppReminderEnabled === true
    ? (value?.length > 0 ? null : Errors.Empty)
    : null

export const requiredAppCustomerField: ValidationRule<CrmSettingsFormData> = (
  value,
  values
) =>
  values?.customerAppReminderEnabled === true
    ? required(value)
    : null

export const validationRules: FormValidation<CrmSettingsFormData> = {
  roundRobinIntervalMinutes: requiredRoundRobinEnableSelected,
  creditAppAssignedUserId: requiredCreditAppAssignedUserIdEnableSelected,
  webLeadAssignedUserId: required,
  messageReceivedAssignedUserId: required,
  emailReceivedAssignedUserId: required,
  salesAppReminderMinutes: requiredRemindSalesMessageTime,
  appointmentReminderUsers: requiredRemindSalesViaMessageUsers,
  customerAppReminderMinutes: requiredAppCustomerField,
  customerAppReminderMessageTemplate: requiredAppCustomerField
}
