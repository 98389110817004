import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import type { InspectionListProps } from './types'
import InspectionItem from './components/InspectionItem'

import CLASS_NAME from './styles'

const InspectionList: FC<InspectionListProps> = ({ isSubmitted, listValidation, lists, onQuestionUpdate }) => {
  return (
    <div className='intake-inspection-list'>
      <div className={cnx('cf-modal-content-scroll-wrapper', CLASS_NAME)}>
        {
          (lists != null) && (lists.length > 0)
            ? lists.map((list, index) => (
              <div
                key={list.title}
                data-id={list.id}
                id={`section-${list.id}`}
                className='inspection-list'
              >
                <h2>{list.title}</h2>

                {list.questions.map((item, i) => (
                  <InspectionItem
                    key={item.id}
                    {...item}
                    isSubmitted={isSubmitted}
                    onQuestionUpdate={onQuestionUpdate}
                    error={listValidation?.errors[index]?.questions[i].questionStateId}
                    touched={listValidation?.touched[index]?.questions[i].questionStateId}
                  />
                ))}
              </div>
            ))
            : <div className='empty-block'>No items found</div>
      }
      </div>
    </div>
  )
}

export default InspectionList
