import type { FC } from 'react'

import formatCurrencyLabel from 'utils/accounting/formatCurrencyLabel'
import { ShapeProps, RangePeriod } from './types'

const LabelShape: FC<ShapeProps> = ({
  cx,
  cy,
  outerRadius,
  midAngle,
  fill,
  value,
  dateRange,
  isActive = true
}) => {
  const RADIAN = Math.PI / 180

  const sin: number = Math.sin(-RADIAN * midAngle)
  const cos: number = Math.cos(-RADIAN * midAngle)

  const pathSx = cx + (outerRadius + 20) * cos
  const pathSy = cy + (outerRadius + 20) * sin
  const mx = cx + (outerRadius + 35) * cos
  const my = cy + (outerRadius + 35) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <path d={`M${pathSx},${pathSy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} textAnchor={textAnchor} fill='#999' fontSize='10'>
        {RangePeriod[dateRange.range]}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 8}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill='#000'
        fontSize='14'
        fillOpacity={!isActive ? 0.5 : 1}
      >
        {formatCurrencyLabel(value)}
      </text>
    </g>
  )
}

export default LabelShape
