import type { BaseListItem, EntityId } from 'types'
import type {
  Account,
  AccountListItem,
  AccountBalanceListItem,
  AccountBankStatementDetailsDto,
  AccountBankStatementListItem,
  AccountListPayload,
  AddCustomerPayload,
  AccountingCostListResponse,
  AccountingTypesResponse,
  AccountingFiscalYearsResponse,
  AccountingFiscalYearsLockedDto,
  AccountingUpdateLockStateForMonthPayload,
  AddAccountPayload,
  AccountTransaction,
  AddTransactionRequest,
  AccountingSettingsPayload,
  AccountingSettingsResponse,
  AccountingEnableRequest,
  AccountingEnableResponse,
  AddRecurringTransactionTemplatesPayload,
  AddVendorPayload,
  BalanceSheetReport,
  BankStatementListItem,
  BankStatementListPayload,
  BankStatementTransactionsCategorizePayload,
  BankStatementTransactionsListItem,
  BankStatementTransactionsListPayload,
  BankStatementTransactionsTotal,
  BankStatementTransactionsTotalPayload,
  ControlListItem,
  CostDetail,
  CostSummary,
  Customer,
  CustomerListPayload,
  DateRangeResponse,
  EntityListItem,
  ListPayload,
  ListResponse,
  OpenBalancesListWithStatistics,
  OpenBalancesListPayload,
  OpeningBalance,
  OpeningBalancePayload,
  ProfitLossReport,
  PaginatedResult,
  ReconciliationSessionDetailsDto,
  ReconciliationSessionDetailsRequestDto,
  RecurringEntriesListItem,
  ReportPayload,
  RequestVehicleId,
  ReportAccountsAgingGetRequestDto,
  ReportAccountsAgingDto,
  ReportAccountsAgingXlsxGetRequestDto,
  TransactionDetails,
  TransactionsListGetRequestDto,
  TransactionListItem,
  TransactionLineVendorDto,
  TransactionsReport,
  TrialBalanceReport,
  UpdateVendorPayload,
  UpdateAccountPayload,
  UpdateTransactionRequest,
  UpdateCustomerPayload,
  VendorListPayload,
  GetTransactionTypesPayload,
  UpdateRecurringTransactionTemplatesPayload,
  RecurringTransactionTemplatesDetails,
  ParseBankStatementsRequestDto,
  ParseBankStatementsDto,
  ImportBankStatementsPayload,
  ReconciliationSessionSummaryDto,
  VehicleCostCreateRequestDto,
  VehicleCostDto,
  VehicleCostUpdateRequestDto,
  VehiclePaymentsDto,
  VehiclePaymentsUpdateRequestDto,
  ReconciliationSessionCreateRequestDto,
  ReconciliationSessionUpdateRequestDto,
  AccountingReconciliationSessionDto,
  CostsReportRequestDto,
  CostReportXlsxDownloadRequestDto,
  CostsReportDto
} from 'api/types'

import {
  type AccountModel,
  type AccountingCategoriesModel
} from 'api/types/responses'

import { type TransactionsReportPayload } from 'api/types'
import { parseAccountingCategoriesDto } from 'api/mappers/responses'
import { trimSlashEnd } from 'utils/general'
import { WrapperRequest } from './wrapper.api'

class AccountingApi extends WrapperRequest {
  url = trimSlashEnd(process.env.REACT_APP_ACCOUNTING ?? '')

  getTypes = async (): Promise<AccountingTypesResponse> => {
    return await this.get(`${this.url}/api/v1/Reference/account-types`, {
      additionalParams: { cache: true }
    })
  }

  getTransactionTypes = async (): Promise<GetTransactionTypesPayload> => {
    return await this.get(`${this.url}/api/v1/Reference/transaction-type-filters`, {
      additionalParams: { cache: true }
    })
  }

  getCostTypes = async (): Promise<ListResponse<BaseListItem>> => {
    return await this.get(`${this.url}/api/v1/Reference/cost-types`, {
      additionalParams: { cache: true }
    })
  }

  getCosts = async (control: RequestVehicleId): Promise<AccountingCostListResponse> => {
    return await this.get(`${this.url}/api/v1/Transactions/control/${control}/costs`)
  }

  getCategories = async (): Promise<AccountingCategoriesModel> => {
    const response = await this.get(`${this.url}/api/v1/Reference/account-categories`, {
      additionalParams: { cache: true }
    })

    return parseAccountingCategoriesDto(response)
  }

  getFiscalYearStartMonths = async (): Promise<ListResponse<BaseListItem>> => {
    return await this.get(`${this.url}/api/v1/Reference/fiscal-year-start-months`, {
      additionalParams: { cache: true }
    })
  }

  getRecurringIntervals = async (): Promise<ListResponse<BaseListItem>> => {
    return await this.get(`${this.url}/api/v1/Reference/recurring-intervals`, {
      additionalParams: { cache: true }
    })
  }

  getBanks = async (): Promise<ListResponse<BaseListItem>> => {
    return await this.get(`${this.url}/api/v1/Reference/banks`, {
      additionalParams: { cache: true }
    })
  }

  getAccounts = async (
    payload: AccountListPayload = { take: 20, skip: 0 }
  ): Promise<PaginatedResult<AccountListItem>> => {
    return await this.post(`${this.url}/api/v1/Accounting/account/list`, payload)
  }

  getAccountsWithBalances = async (
    payload: ListPayload = { take: 20, skip: 0 }
  ): Promise<PaginatedResult<AccountBalanceListItem>> => {
    return await this.post(`${this.url}/api/v1/Accounting/account/balances/list`, payload)
  }

  getAccount = async (id: number): Promise<AccountModel> => {
    return await this.get(`${this.url}/api/v1/Accounting/account/${id}`)
  }

  // we need to have get params like this 'ids=1&ids=2&ids=3'
  // for this we need change array of ids to string
  // axios can do this in paramsSerializer, but we can't use it here
  // by default axios do this ids[0]=1&ids[1]=2&ids[2]=3
  getAccountByIds = async (ids: number[]): Promise<PaginatedResult<AccountListItem>> => {
    return await this.get(`${this.url}/api/v1/Accounting/account/by-ids?${ids.map((n) => `ids=${n}`).join('&')}`)
  }

  getAccountTransactions = async (payload: ListPayload): Promise<PaginatedResult<AccountTransaction>> => {
    const { id = 0, ...listPayload } = payload
    return await this.post(`${this.url}/api/v1/Accounting/account/${id}/lines`, { ...listPayload })
  }

  createAccount = async (data: Omit<AddAccountPayload, 'id'>): Promise<Account> => {
    return await this.post(`${this.url}/api/v1/Accounting/account`, data)
  }

  updateAccount = async (data: UpdateAccountPayload): Promise<Account> => {
    return await this.patch(`${this.url}/api/v1/Accounting/account/${data.id}`, data)
  }

  getSettings = async (): Promise<AccountingSettingsResponse> => {
    return await this.get(`${this.url}/api/v1/Settings`)
  }

  patchSettings = async (payload: AccountingSettingsPayload): Promise<AccountingSettingsResponse> => {
    return await this.patch(`${this.url}/api/v1/Settings`, payload)
  }

  getFiscalYears = async (): Promise<ListResponse<AccountingFiscalYearsResponse>> => {
    return await this.get(`${this.url}/api/v1/FiscalYears`)
  }

  getLockedYearOrMonth = async (): Promise<ListResponse<AccountingFiscalYearsLockedDto>> => {
    return await this.get(`${this.url}/api/v1/FiscalYears/locked`)
  }

  getLockedYearOrMonthByDate = async (date: string): Promise<AccountingFiscalYearsResponse> => {
    return await this.get(`${this.url}/api/v1/FiscalYears/by-date/${date}`)
  }

  lockFiscalYears = async (yearId: number, isClosed: boolean): Promise<ListResponse<AccountingFiscalYearsResponse>> => {
    return await this.post(`${this.url}/api/v1/FiscalYears/${yearId}`, { isClosed })
  }

  updateLockStateForMonth = async ({
    yearId, monthId, isLocked
  }: AccountingUpdateLockStateForMonthPayload): Promise<ListResponse<AccountingFiscalYearsResponse>> => {
    return await this.post(`${this.url}/api/v1/FiscalYears/${yearId}/fiscal-year-months/${monthId}`, { isLocked })
  }

  enableAccounting = async (data: AccountingEnableRequest): Promise<AccountingEnableResponse> => {
    return await this.post(`${this.url}/api/v1/Settings/status`, data)
  }

  getTransactions = async (payload: TransactionsListGetRequestDto): Promise<PaginatedResult<TransactionListItem>> => {
    return await this.post(`${this.url}/api/v1/Transactions/list`, payload)
  }

  getVendors = async (payload: VendorListPayload = { take: 20, skip: 0 }): Promise<PaginatedResult<TransactionLineVendorDto>> => {
    return await this.post(`${this.url}/api/v1/Vendors/list`, payload)
  }

  getCustomers = async (payload: CustomerListPayload = { take: 20, skip: 0 }): Promise<PaginatedResult<Customer>> => {
    return await this.post(`${this.url}/api/v1/Customers/list`, payload)
  }

  getEntities = async (payload: ListPayload = { take: 20, skip: 0 }): Promise<PaginatedResult<EntityListItem>> => {
    return await this.post(`${this.url}/api/v1/Entities/list`, payload)
  }

  getControls = async (payload: ListPayload = { take: 20, skip: 0 }): Promise<PaginatedResult<ControlListItem>> => {
    return await this.post(`${this.url}/api/v1/Control/list`, payload)
  }

  createVendor = async (data: AddVendorPayload): Promise<TransactionLineVendorDto> => {
    return await this.post(`${this.url}/api/v1/Vendors`, data)
  }

  getVendor = async (id: number): Promise<TransactionLineVendorDto> => {
    return await this.get(`${this.url}/api/v1/Vendors/${id}`)
  }

  updateVendor = async (data: UpdateVendorPayload): Promise<TransactionLineVendorDto> => {
    return await this.patch(`${this.url}/api/v1/Vendors/${data.id}`, data)
  }

  removeVendor = async (id: number): Promise<void> => {
    return await this.delete(`${this.url}/api/v1/Vendors/${id}`)
  }

  createTransaction = async (data: AddTransactionRequest): Promise<void> => {
    return await this.post(`${this.url}/api/v1/Transactions`, data)
  }

  removeTransaction = async (id: number): Promise<void> => {
    return await this.delete(`${this.url}/api/v1/Transactions/${id}`)
  }

  getTransaction = async (id: number): Promise<TransactionDetails> => {
    return await this.get(`${this.url}/api/v1/Transactions/${id}`)
  }

  createTransactionCost = async (payload: VehicleCostCreateRequestDto): Promise<VehicleCostDto> => {
    return await this.post(`${this.url}/api/v1/Transactions/costs`, payload)
  }

  updateTransactionCost = async (id: EntityId, payload: VehicleCostUpdateRequestDto): Promise<VehicleCostDto> => {
    return await this.patch(`${this.url}/api/v1/Transactions/costs/${id}`, payload)
  }

  removeTransactionCost = async (id: EntityId): Promise<void> => {
    return await this.delete(`${this.url}/api/v1/Transactions/costs/${id}`)
  }

  getTransactionCost = async (id: EntityId): Promise<VehicleCostDto> => {
    return await this.get(`${this.url}/api/v1/Transactions/costs/${id}`)
  }

  getVehiclePayments = async (id: EntityId): Promise<VehiclePaymentsDto> => {
    return await this.get(`${this.url}/api/v1/VehiclePayments/${id}`)
  }

  updateVehiclePayments = async (id: EntityId, payload: VehiclePaymentsUpdateRequestDto): Promise<void> => {
    return await this.patch(`${this.url}/api/v1/VehiclePayments/${id}`, payload)
  }

  updateTransaction = async (data: UpdateTransactionRequest): Promise<void> => {
    return await this.patch(`${this.url}/api/v1/Transactions/${data.id}`, data)
  }

  createCustomer = async (data: AddCustomerPayload): Promise<Customer> => {
    return await this.post(`${this.url}/api/v1/Customers`, data)
  }

  getCustomer = async (id: number): Promise<Customer> => {
    return await this.get(`${this.url}/api/v1/Customers/${id}`)
  }

  updateCustomer = async (data: UpdateCustomerPayload): Promise<Customer> => {
    return await this.patch(`${this.url}/api/v1/Customers/${data.id}`, data)
  }

  removeCustomer = async (id: number): Promise<void> => {
    return await this.delete(`${this.url}/api/v1/Customers/${id}`)
  }

  getCostsDetails = async (control: RequestVehicleId): Promise<ListResponse<CostDetail>> => {
    return await this.get(`${this.url}/api/v1/Transactions/control/${control}/details`)
  }

  getCostsSummary = async (control: RequestVehicleId): Promise<ListResponse<CostSummary>> => {
    return await this.get(`${this.url}/api/v1/Transactions/control/${control}/summary`)
  }

  getTransactionsReport = async (data: TransactionsReportPayload): Promise<TransactionsReport> => {
    return await this.post(`${this.url}/api/v1/Reports/transactions`, data)
  }

  downloadTransactionsReportXlsx = async (data: TransactionsReport): Promise<Blob> => {
    return await this.post(`${this.url}/api/v1/Reports/transactions/xlsx`, {
      data,
      additionalParams: { responseType: 'blob' }
    })
  }

  getTrialBalanceReport = async (data: ReportPayload): Promise<TrialBalanceReport> => {
    return await this.post(`${this.url}/api/v1/Reports/trial-balance`, data)
  }

  downloadTrialBalanceReportXlsx = async (data: TrialBalanceReport): Promise<Blob> => {
    return await this.post(`${this.url}/api/v1/Reports/trial-balance/xlsx`, {
      data,
      additionalParams: { responseType: 'blob' }
    })
  }

  getBalanceSheetReport = async (data: ReportPayload): Promise<BalanceSheetReport> => {
    return await this.post(`${this.url}/api/v1/Reports/balance`, data)
  }

  downloadBalanceSheetReportXlsx = async (data: BalanceSheetReport): Promise<Blob> => {
    return await this.post(`${this.url}/api/v1/Reports/balance/xlsx`, {
      data,
      additionalParams: { responseType: 'blob' }
    })
  }

  getProfitLossReport = async (data: ReportPayload): Promise<ProfitLossReport> => {
    return await this.post(`${this.url}/api/v1/Reports/profit-loss`, data)
  }

  downloadProfitLossReportXlsx = async (data: ProfitLossReport): Promise<Blob> => {
    return await this.post(`${this.url}/api/v1/Reports/profit-loss/xlsx`, {
      data,
      additionalParams: { responseType: 'blob' }
    })
  }

  getTransactionByDeal = async (dealId: EntityId): Promise<TransactionDetails> => {
    return await this.get(`${this.url}/api/v1/Transactions/deal/${dealId}`)
  }

  getOpenBalances = async (
    data: OpenBalancesListPayload,
    isPayables = false
  ): Promise<OpenBalancesListWithStatistics> => {
    if (isPayables) {
      return await this.post(`${this.url}/api/v1/Schedules/payables`, data)
    }

    return await this.post(`${this.url}/api/v1/Schedules/receivables`, data)
  }

  getOpeningBalances = async (): Promise<PaginatedResult<OpeningBalance>> => {
    return await this.get(`${this.url}/api/v1/Accounting/account/opening-balances`)
  }

  updateOpeningBalances = async (data: OpeningBalancePayload): Promise<PaginatedResult<OpeningBalance>> => {
    return await this.post(`${this.url}/api/v1/Accounting/account/opening-balances`, data)
  }

  getRecurringEntries = async (payload: ListPayload = { take: 20, skip: 0 }): Promise<PaginatedResult<RecurringEntriesListItem>> => {
    return await this.post(`${this.url}/api/v1/RecurringTransactionTemplates/list`, payload)
  }

  getRecurringEntriesItem = async (id: number): Promise<RecurringTransactionTemplatesDetails> => {
    return await this.get(`${this.url}/api/v1/RecurringTransactionTemplates/${id}`)
  }

  createRecurringTransactionTemplates = async (data: AddRecurringTransactionTemplatesPayload): Promise<Customer> => {
    return await this.post(`${this.url}/api/v1/RecurringTransactionTemplates`, data)
  }

  updateRecurringTransactionTemplates = async (data: UpdateRecurringTransactionTemplatesPayload): Promise<void> => {
    const { id, ...props } = data
    return await this.patch(`${this.url}/api/v1/RecurringTransactionTemplates/${id}`, { data: props })
  }

  removeTransactionTemplate = async (id: number): Promise<void> => {
    return await this.delete(`${this.url}/api/v1/RecurringTransactionTemplates/${id}`)
  }

  getAccountBankStatements = async (): Promise<PaginatedResult<AccountBankStatementListItem>> => {
    return await this.post(`${this.url}/api/v1/AccountBankStatements/list`)
  }

  getAccountReconciliationSession = async (payload: ReconciliationSessionDetailsRequestDto): Promise<ReconciliationSessionDetailsDto> => {
    const { accountId, ...rest } = payload
    return await this.post(`${this.url}/api/v1/AccountBankStatements/${accountId}/reconciliation-session`, rest)
  }

  getReconciliationSession = async (id: number): Promise<AccountBankStatementDetailsDto> => {
    return await this.get(`${this.url}/api/v1/AccountBankStatements/${id}`)
  }

  getBankStatements = async (
    data?: BankStatementListPayload
  ): Promise<ListResponse<BankStatementListItem>> => {
    return await this.get(`${this.url}/api/v1/BankStatements`, data)
  }

  parseBankStatements = async (data: ParseBankStatementsRequestDto): Promise<ParseBankStatementsDto> => {
    return await this.post(`${this.url}/api/v1/BankStatements/parse`, data)
  }

  importBankStatements = async (data: ImportBankStatementsPayload): Promise<void> => {
    return await this.post(`${this.url}/api/v1/BankStatements`, data)
  }

  getBankStatementTransactions = async (
    payload: BankStatementTransactionsListPayload = { take: 20, skip: 0 }
  ): Promise<PaginatedResult<BankStatementTransactionsListItem>> => {
    return await this.post(`${this.url}/api/v1/BankStatementTransactions/list`, payload)
  }

  getBankStatementTransactionsTotal = async (
    payload: BankStatementTransactionsTotalPayload = { take: 20, skip: 0 }
  ): Promise<BankStatementTransactionsTotal> => {
    return await this.post(`${this.url}/api/v1/BankStatementTransactions/list/total`, payload)
  }

  excludeBankStatementTransaction = async (id: EntityId): Promise<DateRangeResponse> => {
    return await this.post(`${this.url}/api/v1/BankStatementTransactions/${id}/exclude`)
  }

  categorizeBankStatementTransaction = async (
    id: EntityId,
    payload: BankStatementTransactionsCategorizePayload
  ): Promise<BankStatementTransactionsListItem> => {
    return await this.post(`${this.url}/api/v1/BankStatementTransactions/${id}/categorize`, payload)
  }

  recordOfTransferBankStatementTransaction = async (
    id: EntityId,
    payload: BankStatementTransactionsCategorizePayload
  ): Promise<BankStatementTransactionsListItem> => {
    return await this.post(`${this.url}/api/v1/BankStatementTransactions/${id}/transfer`, payload)
  }

  recordOfCreditCardPaymentBankStatementTransaction = async (
    id: EntityId,
    payload: BankStatementTransactionsCategorizePayload
  ): Promise<BankStatementTransactionsListItem> => {
    return await this.post(`${this.url}/api/v1/BankStatementTransactions/${id}/credit-card-payment`, payload)
  }

  forReviewBankStatementTransaction = async (id: EntityId): Promise<DateRangeResponse> => {
    return await this.post(`${this.url}/api/v1/BankStatementTransactions/${id}/for-review`)
  }

  linkBankStatementTransaction = async (
    bankStatementId: EntityId,
    matchId: EntityId
  ): Promise<BankStatementTransactionsListItem> => {
    return await this.post(`${this.url}/api/v1/BankStatementTransactions/${bankStatementId}/link/${matchId}`)
  }

  payBankStatementTransaction = async (
    bankStatementId: EntityId,
    matchId: EntityId
  ): Promise<BankStatementTransactionsListItem> => {
    return await this.post(`${this.url}/api/v1/BankStatementTransactions/${bankStatementId}/pay/${matchId}`)
  }

  receiveBankStatementTransaction = async (
    bankStatementId: EntityId,
    matchId: EntityId
  ): Promise<BankStatementTransactionsListItem> => {
    return await this.post(`${this.url}/api/v1/BankStatementTransactions/${bankStatementId}/receive/${matchId}`)
  }

  getTransactionByBankStatement = async (
    bankStatementId: EntityId
  ): Promise<{ transaction: TransactionDetails }> => {
    return await this.get(`${this.url}/api/v1/BankStatementTransactions/${bankStatementId}/transaction`)
  }

  createBankStatementMatch = async (
    bankStatementId: EntityId,
    payload: AddTransactionRequest
  ): Promise<{ transaction: TransactionDetails }> => {
    return await this.post(`${this.url}/api/v1/BankStatementTransactions/${bankStatementId}/match`, payload)
  }

  getVehiclePaymentAccountsList = async (): Promise<ListResponse<AccountListItem>> => {
    return await this.get(`${this.url}/api/v1/VehiclePurchaseCostAccounts`)
  }

  getVehicleCostPaymentAccounts = async (): Promise<ListResponse<AccountListItem>> => {
    return await this.get(`${this.url}/api/v1/VehicleCostAccounts`)
  }

  updateVehiclePaymentAccountsList = async (data: {ids: EntityId[]}): Promise<ListResponse<AccountListItem>> => {
    return await this.post(`${this.url}/api/v1/VehiclePurchaseCostAccounts`, data)
  }

  updateVehicleCostPaymentAccounts = async (data: {ids: EntityId[]}): Promise<ListResponse<AccountListItem>> => {
    return await this.post(`${this.url}/api/v1/VehicleCostAccounts`, data)
  }

  printTransaction = async (id: EntityId): Promise<boolean> => {
    return await this.post(`${this.url}/api/v1/Transactions/${id}/print`)
  }

  saveReconciliationSession = async (data: ReconciliationSessionCreateRequestDto): Promise<ReconciliationSessionDetailsDto> => {
    return await this.post(`${this.url}/api/v1/ReconciliationSession`, data)
  }

  checkTransactionAsReconciled = async (sessionId: EntityId, transactionId: EntityId): Promise<ReconciliationSessionSummaryDto> => {
    return await this.post(`${this.url}/api/v1/ReconciliationSession/${sessionId}/lineEntry/${transactionId}`)
  }

  uncheckTransactionAsReconciled = async (sessionId: EntityId, transactionId: EntityId): Promise<ReconciliationSessionSummaryDto> => {
    return await this.delete(`${this.url}/api/v1/ReconciliationSession/${sessionId}/lineEntry/${transactionId}`)
  }

  completeReconciliationSession = async (sessionId: EntityId): Promise<void> => {
    return await this.post(`${this.url}/api/v1/ReconciliationSession/${sessionId}/complete`)
  }

  getReconciliationSessionInfo = async (sessionId: EntityId): Promise<AccountingReconciliationSessionDto> => {
    return await this.get(`${this.url}/api/v1/ReconciliationSession/${sessionId}`)
  }

  updateReconciliationSession = async (data: ReconciliationSessionUpdateRequestDto, sessionId: EntityId): Promise<void> => {
    return await this.patch(`${this.url}/api/v1/ReconciliationSession/${sessionId}`, data)
  }

  getReportAccountsAgingReceivable = async (data: ReportAccountsAgingGetRequestDto): Promise<ReportAccountsAgingDto> => {
    return await this.post(`${this.url}/api/v1/Reports/receivable-aging`, data)
  }

  getReportAccountsAgingPayable = async (data: ReportAccountsAgingGetRequestDto): Promise<ReportAccountsAgingDto> => {
    return await this.post(`${this.url}/api/v1/Reports/payable-aging`, data)
  }

  downloadReportAccountsAgingReceivableXlsx = async (data: ReportAccountsAgingXlsxGetRequestDto): Promise<Blob> => {
    return await this.post(`${this.url}/api/v1/Reports/receivable-aging/xlsx`, {
      data,
      additionalParams: { responseType: 'blob' }
    })
  }

  downloadReportAccountsAgingPayableXlsx = async (data: ReportAccountsAgingXlsxGetRequestDto): Promise<Blob> => {
    return await this.post(`${this.url}/api/v1/Reports/payable-aging/xlsx`, {
      data,
      additionalParams: { responseType: 'blob' }
    })
  }

  getInventoryCostsReport = async (payload: CostsReportRequestDto): Promise<CostsReportDto> => {
    return await this.post(`${this.url}/api/v1/Reports/costs`, payload)
  }

  downloadInventoryCostsReportXlsx = async (data: CostReportXlsxDownloadRequestDto): Promise<Blob> => {
    return await this.post(`${this.url}/api/v1/Reports/costs/xlsx`, {
      data,
      additionalParams: { responseType: 'blob' }
    })
  }
}

export const AccountingApiProvider = new AccountingApi()
export default AccountingApiProvider
