import { css } from '@emotion/css'

export default css(`
  color: rgba(16, 16, 16, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;

  span {
    word-break: break-word;
  }

  &.wrapped-line {
    span {
      overflow-wrap: break-word;
    }
  }
  
  .item-name-text {
    float: left;
    margin-right: 4px;
    color: rgba(33, 33, 33, 0.5);
  }

  a {
    word-wrap: break-word;
    color: rgba(69, 143, 255, 1);
  }

  p:empty {
    display: none;
  }
`)
