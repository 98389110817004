import { RangePickerMode } from '@carfluent/common'
import { periodFilterFrom, periodFilterTo } from 'utils/validationPresets'

export const dateValidator = {
  [RangePickerMode.Single]: {
    startDate: periodFilterTo(),
    endDate: periodFilterTo()
  },
  [RangePickerMode.Range]: {
    startDate: periodFilterFrom(),
    endDate: periodFilterTo()
  }
}
