import { useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { type FormValidation, useForm } from '@carfluent/common'

import { StripeApiProvider } from 'api/stripe.api'
import DocumentsApiProvider from 'api/documents.api'
import CustomersCoreApiProvider from 'api/customersCore.api'

import {
  cardholderNameField,
  nonNegativeNumber,
  required,
  requiredNonNegativeNumber,
  addressDataRequired,
  dateField
} from 'utils/validationPresets'
import { formatCurrencyDecimal } from 'utils/format_number'

import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useCloudAccessToken from 'hooks/useCloudAccessToken'

import { PaymentTransactionTypeEnum } from 'types/payments'

import {
  type UsePaymentFormProps,
  type PaymentFormData,
  type UsePaymentFormReturn
} from './types'
import DEFAULT_PAYMENT_FORM_DATA from './constants'
import { serializeData, serializeEditData } from './serializer'
import { parseData } from './parser'

/**
 * DD-TODO:
 * make form validation rules understand this syntax:
 * 'payments.*' or 'billingDetails.*'
 */

const STRIPE_SUCCESS_STATUS = 'succeeded'

const paymentRules = Object.keys(DEFAULT_PAYMENT_FORM_DATA.payments)
  .reduce<Record<string, typeof nonNegativeNumber>>((acc, curr) => {
  acc[`payments.${curr}`] = nonNegativeNumber
  return acc
}, {})

const cardHolderNameValidator = cardholderNameField()

const ifManualValidator = (validator: (val: any, ctx?: unknown) => string | null) =>
  (value: any, formData?: PaymentFormData) => {
    return formData?.isManual === true ? null : validator(value, formData)
  }

export const getRemainingSum = (
  amount: number,
  payments: Array<string | number | null>
): number => {
  let sum = payments.reduce<number>((acc, val) => acc + Number(val), 0)
  sum = isNaN(sum) ? 0 : sum

  return amount - sum
}

const validationRules: FormValidation<PaymentFormData> = {
  amount: requiredNonNegativeNumber,
  paymentDate: dateField('append'),
  description: required,
  transactionTypeId: (v, values) => {
    if (values?.isManual === true) {
      return required(v)
    }

    return null
  },
  ...paymentRules,
  'billingDetails.cardNumber': ifManualValidator(required),
  'billingDetails.cardHolderName': ifManualValidator(cardHolderNameValidator),
  'billingDetails.expirationDate': ifManualValidator(required),
  'billingDetails.cvv': ifManualValidator(required),
  'billingDetails.address': (value, values) => {
    if (values?.isManual === false && values.isBillingAddressProvided) {
      return addressDataRequired(value)
    }

    return null
  }
}

export const usePaymentForm = ({
  dealId,
  dealerId,
  description,
  onSubmit: _onSubmit,
  paymentMethod,
  onClose,
  isOpen,
  defaultValues,
  id
}: UsePaymentFormProps): UsePaymentFormReturn => {
  const isEditMode = id != null
  const { showAlert } = useCustomSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const { token = '' } = useCloudAccessToken() ?? {}
  const [isSubmitRemainingAmountError, setIsSubmitRemainingAmountError] = useState(false)
  const [isDateError, setIsDateError] = useState(false)

  const onSubmitEditRequest = useCallback(async (values: PaymentFormData, receiptId: number) => {
    const data = serializeEditData(
      {
        ...values,
        dealId,
        paymentMethod
      },
      receiptId
    )

    const { receiptFileId } = await CustomersCoreApiProvider.updatePayment(dealId, receiptId, data)
    return receiptFileId
  }, [
    dealId,
    paymentMethod
  ])

  const onSubmitRequest = useCallback(async (values: PaymentFormData): Promise<number | null> => {
    const data = serializeData({
      ...values,
      dealId,
      paymentMethod
    })

    const { receiptFileId } = await CustomersCoreApiProvider.createPayment(dealId, data)
    return receiptFileId
  }, [dealId, paymentMethod])

  const onSubmit = useCallback(async (values: PaymentFormData) => {
    setIsDateError(false)
    const remainingAmount = getRemainingSum(values.amount, Object.values(values.payments))
    const isManualMultiple = values.isManual &&
      values.transactionTypeId === PaymentTransactionTypeEnum.MULTIPLE

    if (remainingAmount !== 0 && isManualMultiple) {
      setIsSubmitRemainingAmountError(true)
      return
    }

    setIsLoading(true)

    try {
      let receiptFileId: number | null = null

      if (isEditMode) {
        receiptFileId = await onSubmitEditRequest(values, id)
      } else {
        receiptFileId = await onSubmitRequest(values)
      }

      await _onSubmit()

      if (values.printReceiptCheck && receiptFileId != null) {
        const { uri } = await DocumentsApiProvider.getFile(receiptFileId)
        window.open(`${uri}?${token}`, '_blank')
      }

      onClose()
    } catch (e) {
      if (isAxiosError(e) && e?.response?.data?.errors?.date != null) {
        setIsDateError(true)
      } else {
        showAlert('Request is failed')
      }
    } finally {
      setIsLoading(false)
    }
  }, [
    showAlert,
    onClose,
    token,
    onSubmitRequest,
    onSubmitEditRequest,
    isEditMode,
    id
  ])

  const onSubmitElectronicPayment = useCallback(async (values: PaymentFormData) => {
    /** STRIPE FLOW if not manual */
    /**
     * DD-TODO:
     * make payment provider injectable instead of direct import
     */

    setIsLoading(true)
    try {
      const details = values.billingDetails
      if (details == null || details.expirationDate == null) {
        throw new Error('Billing details are required')
      }

      const { accountId } = await CustomersCoreApiProvider.paymentAccount(dealerId)
      const paymentMethod = await StripeApiProvider.getStripePaymentMethods(
        accountId,
        {
          cardNumber: details.cardNumber,
          cardHolderName: details.cardHolderName,
          /**
           * expirationDate is a number here because it is unmasked. So, instead of '12/22' it is '1222'
           */
          cardExpirationMonth: details.expirationDate.slice(0, 2),
          caedExpirationYear: details.expirationDate.slice(2),
          cardCvv: details.cvv,
          zipCode: details.address?.zipCode
        }
      )

      if (paymentMethod.error != null) {
        showAlert(paymentMethod.error.message)
        setIsLoading(false)
        return
      }

      const { clientSecret } = await CustomersCoreApiProvider.paymentIntent({ amount: values.amount, dealId })
      const confirmData = await StripeApiProvider.confirmStripePayment(
        accountId,
        clientSecret,
        paymentMethod.id
      )

      /**
       * https://stripe.com/docs/api/payment_intents/confirm
       *
       * This is the way to understand if payment is successful for automatic capture method
       */
      if (confirmData?.status !== STRIPE_SUCCESS_STATUS) {
        throw new Error(confirmData.error?.message)
      }

      await onSubmit(values)
    } catch (err: any) {
      const resolvedMessage = err?.message === 'Your card has insufficient funds.'
        ? err.message
        : 'Error processing Stripe payment'

      setIsLoading(false)
      showAlert(resolvedMessage)
    }
  }, [showAlert, dealId, dealerId, onSubmit])

  const submitAction = useCallback(async (values: PaymentFormData): Promise<void> => {
    const isElectronicRequest = !values.isManual && !isEditMode

    if (isElectronicRequest) {
      await onSubmitElectronicPayment(values)
    } else {
      await onSubmit(values)
    }
  }, [onSubmitElectronicPayment, onSubmit, isEditMode])

  const formProps = useForm<PaymentFormData>({
    baseValues: DEFAULT_PAYMENT_FORM_DATA,
    validationRules,
    submitAction
  })

  const remainingAmount = getRemainingSum(
    formProps.values.amount,
    Object.values(formProps.values.payments)
  )

  /**
   * sets initial default values
   */
  useEffect(() => {
    if (defaultValues != null) {
      formProps.setValues(parseData(defaultValues))
      return
    }
    if (isOpen) {
      formProps.onChange('description', description)
    }
  }, [
    description,
    isOpen,
    formProps.onChange,
    defaultValues,
    formProps.setValues
  ])

  useEffect(() => {
    setIsSubmitRemainingAmountError(false)
  }, [remainingAmount])

  useEffect(() => {
    if (!isOpen) {
      formProps.resetForm()
      setIsDateError(false)
    }
  }, [isOpen, formProps.resetForm])

  const isRemainingAmountError = formProps.values.isManual &&
    formProps.values.transactionTypeId === PaymentTransactionTypeEnum.MULTIPLE &&
    ((remainingAmount < 0) || isSubmitRemainingAmountError)

  return {
    ...formProps,
    isLoading,
    remainingAmount: formatCurrencyDecimal(remainingAmount),
    isRemainingAmountError,
    isDateError
  }
}
