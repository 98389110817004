import { serializers as S } from '@carfluent/common'
import { toJS } from 'mobx'

import { type EntityId, type TransactionLineRow } from 'types'
import {
  type LinePayload,
  type AddTransactionRequest,
  EntityTypeId,
  TransactionTypeId,
  TransactionControlTypeId
} from 'api/types'
import { isTruthy } from 'utils/general'

import type { TransactionFormData } from '../types'
import { isNotEmptyRow } from '../utils'

export const serializeJournalEntry = (
  formData: TransactionFormData,
  rows: TransactionLineRow[],
  dealId?: EntityId | null
): AddTransactionRequest => {
  const data = toJS(formData)
  const lines = rows.map(item => toJS(item)).filter(isNotEmptyRow)

  const payload = {
    date: S.serializeDate(data.dateTime) ?? '',
    description: data.description,
    referenceNumber: data.referenceNumber ?? null,
    lines: lines.map(serializeLine),
    transactionTypeId: data.transactionTypeId ?? TransactionTypeId.JournalEntry
  }

  if (dealId != null) {
    return { ...payload, dealId }
  }

  return payload
}

const serializeLine = (line: TransactionLineRow): LinePayload => {
  const { entity, control, id } = line
  const isVendor = entity?.entityType === EntityTypeId.Vendor
  const isDealControl = control?.controlType === TransactionControlTypeId.Deal
  const isVehicleControl = control?.controlType === TransactionControlTypeId.Vehicle
  const isCustomer = !isVendor
  const entityId = entity?.id ?? null

  return {
    id: id ?? null,
    accountId: Number(line.account?.id ?? -1),
    change: line.credits !== 0 ? (-line.credits) : line.debits,
    control: isVehicleControl ? control.id : null,
    dealId: isDealControl ? control.id : null,
    customerId: isCustomer ? entityId : null,
    notes: isTruthy(line.notes) ? line.notes : null,
    vendorId: isVendor ? entityId : null
  }
}
