import { type FC } from 'react'
import { FormNumberInput, Button } from '@carfluent/common'

import { RadioGroup } from 'components/common/RadioGroup'
import { MAX_PRICE_VALUE } from 'constants/validation'
import { decimalToPercent } from 'utils/math'
import { PercentOrAmountModes } from 'types'

import { type PercentOrAmountFormProps } from './types'
import usePercentOrAmountForm from './hook'
import CLASS_NAME from './styles'

const FORM_MODES = [
  PercentOrAmountModes.Auto,
  PercentOrAmountModes.Manual
]

const PercentOrAmountForm: FC<PercentOrAmountFormProps> = ({
  formatRadioLabel,
  labelAmount,
  labelPercent,
  ...hookProps
}) => {
  const {
    helperText,
    formMode,
    onChangeAmount,
    onChangeFormMode,
    onChangePercent,
    onSubmit,
    valueAmount,
    valuePercent
  } = usePercentOrAmountForm(hookProps)

  return (
    <div className={CLASS_NAME}>
      <div className='form-fields'>
        <RadioGroup
          className='form-mode-switch'
          data-test-id={`form-mode-switch-${hookProps.fieldPathPercent}`}
          id='formMode'
          items={FORM_MODES.map(x => ({ value: x, label: formatRadioLabel(x) }))}
          onChange={(_, value) => onChangeFormMode(value)}
          value={formMode}
        />

        {(formMode === PercentOrAmountModes.Manual) && (
          <FormNumberInput
            className='percent-or-amount-input'
            id='amount'
            label={labelAmount}
            preset='price'
            blurComplementMode='min-scale'
            mountComplementMode='min-scale'
            isEmptyAllowed
            value={valueAmount}
            onChange={onChangeAmount}
            max={MAX_PRICE_VALUE}
            disabled={hookProps.disabled}
          />
        )}

        {(formMode === PercentOrAmountModes.Auto) && (
          <>
            <FormNumberInput
              className='percent-or-amount-input'
              id='percent'
              label={labelPercent}
              preset='percent'
              isEmptyAllowed={false}
              blurComplementMode='min-scale'
              mountComplementMode='min-scale'
              decimalPrecision={3}
              value={decimalToPercent(valuePercent ?? 0)}
              onChange={onChangePercent}
              disabled={hookProps.disabled}
            />

            {(helperText != null) && (
              <p className='percent-or-amount-helper-text'>
                {helperText}
              </p>
            )}
          </>
        )}
      </div>

      <div className='apply-button-wrapper'>
        <Button
            onClick={onSubmit}
            className='btn-apply'
            variant='contained'
            isDisabled={hookProps.disabled}
          >
            Apply
          </Button>
      </div>
    </div>
  )
}

export default PercentOrAmountForm
