import convertNumberToTimeSpan from 'utils/recon/convertNumberToTimeSpan'
import { ReconStepPayload, UpdateReconStepsPayload } from 'api/types'

import type { ReconStepItemFormData } from './types'

export interface ReconStepsToSerialize {
  reconStepsToCreate: ReconStepItemFormData[]
  reconStepsToUpdate: ReconStepItemFormData[]
  reconStepIdsToDelete: number[]
}

const serializeReconStep = (step: ReconStepItemFormData): ReconStepPayload => {
  return {
    ...step,
    isReadonly: step.isReadonly ?? false,
    usedInVehicle: step.usedInVehicle ?? false,
    daysInStepUntilFirstAlert: convertNumberToTimeSpan(step.daysInStepUntilFirstAlert ?? 0),
    daysInStepUntilSecondAlert: convertNumberToTimeSpan(step.daysInStepUntilSecondAlert ?? 0),
    daysInReconUntilFirstAlert: convertNumberToTimeSpan(step.daysInReconUntilFirstAlert ?? 0),
    daysInReconUntilSecondAlert: convertNumberToTimeSpan(step.daysInReconUntilSecondAlert ?? 0)
  }
}

const serializeReconSteps = (steps: ReconStepsToSerialize): UpdateReconStepsPayload => {
  const {
    reconStepsToCreate,
    reconStepsToUpdate,
    reconStepIdsToDelete
  } = steps

  return {
    reconStepsToCreate: reconStepsToCreate.map((step) => serializeReconStep(step)),
    reconStepsToUpdate: reconStepsToUpdate.map((step) => serializeReconStep(step)),
    reconStepIdsToDelete
  }
}

export default serializeReconSteps
