import { useCallback, useState } from 'react'
import type { Row } from '@tanstack/react-table'

import { type UseCostsSummaryReturn } from 'hooks/useCostsSummary'
import { type UseCostsDetailsReturn } from 'hooks/useCostDetails'
import type { TransactionDialogControllerProps } from 'components/accounting/TransactionDialogController'
import type { CostsDetailsProps } from '../components/CostsDetails'
import type { SummaryProps } from '../components/Summary'
import type { AllCostBreakdownProps } from '../components/AllCostBreakdown'
import type { AdditionalCostsPanelData, CostPanelBaseProps } from '../types'

import { formattedReturnParams } from './formattedReturnParams'
import type { CostListItem } from '../columns'

export interface IncludePackAndCostProps {
  checked: boolean
  inventoryAssetAccountOptions: any
  holdingAccount: any
  onChecked: () => void
}

export interface UseCostPanelReturn {
  formControllerProps: TransactionDialogControllerProps
  handleOpenTransactionsDetails: () => void
  costsPanelDetailsProps: CostsDetailsProps
  costsPanelSummaryProps: SummaryProps
  costPanelProps: {
    data: CostListItem[]
    onRowClick: (row: Row<CostListItem>) => void
    onAddRow: () => void
  }
  costsBreakdown: AllCostBreakdownProps
  includePackAndCost: IncludePackAndCostProps
}

export interface CostsPanelProps {
  costsPanelProps: CostPanelBaseProps & AdditionalCostsPanelData
  costsPanelDetailsProps: UseCostsDetailsReturn
  costsPanelSummaryProps: UseCostsSummaryReturn
}

export const useCostsPanel = ({
  costsPanelProps: {
    items,
    costsBreakdown,
    onRowClick,
    onOpenModal: onAllCostsModalOpen,
    getCosts,
    selectedJournalEntry,
    onCloseModalJournalEntry,
    onOpenModalJournalEntry,
    ...allCostsDialogProps
  },
  costsPanelDetailsProps: {
    getDetails,
    items: costDetailsItems,
    onRowClick: onCostDetailsRowClick,
    ...costsPanelDetailsProps
  },
  costsPanelSummaryProps: {
    getSummary,
    ...costsPanelSummaryProps
  }
}: CostsPanelProps): UseCostPanelReturn => {
  const [isOpenNewTransactionDetails, setIsOpenNewTransactionDetails] = useState(false)
  const [isIncludePackAndCost, setIsIncludePackAndCost] = useState(false)

  const handleOpenTransactionsDetails = useCallback(
    () => setIsOpenNewTransactionDetails(true), []
  )
  const onCloseModal = useCallback(
    () => setIsOpenNewTransactionDetails(false), []
  )

  const onLoadTransactionList = useCallback(async () => {
    await Promise.all([
      getSummary(),
      getDetails(),
      getCosts?.()
    ])
  }, [getSummary, getDetails, getCosts])

  const commonProps = {
    costsBreakdown,
    costsPanelDetailsProps: {
      items: costDetailsItems,
      onAddJournal: handleOpenTransactionsDetails,
      onRowClick: onCostDetailsRowClick
    },
    costPanelProps: {
      data: items,
      onRowClick,
      onAddRow: onAllCostsModalOpen
    },
    costsPanelSummaryProps: {
      ...costsPanelSummaryProps,
      onAddJournal: handleOpenTransactionsDetails
    },
    handleOpenTransactionsDetails,
    includePackAndCost: {
      checked: isIncludePackAndCost,
      inventoryAssetAccountOptions: [],
      holdingAccount: [],
      onChecked: () => setIsIncludePackAndCost((prev) => !prev)
    }
  }

  return formattedReturnParams({
    costsPanelDetailsProps,
    allCostsDialogProps,
    commonProps,
    isOpenNewTransactionDetails,
    onCloseModal,
    onLoadTransactionList,
    handleOpenTransactionsDetails,
    editJournalEntryDialog: {
      selectedJournalEntry,
      onCloseModalJournalEntry,
      onOpenModalJournalEntry
    }
  })
}
