import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'

import DealersApiProvider from 'api/dealers.api'
import { toDateOrNull } from 'utils/parse_date'

import {
  type AccountingCategoriesModel,
  type DealerSettingsModel
} from 'api/types/responses'

import {
  type AccountingTypesResponse,
  type AccountingSettingsResponse,
  type DealerData
} from 'api/types'

import { type AccountingSettings } from './types'
import { GET_DEFAULT_ACCOUNTING, GET_DEFAULT_DEALER_DATA } from './constants'

export type { AccountingSettings } from './types'

class Settings {
  isLoading = false
  isLoadingDealersAction = true
  accounting: AccountingSettings = GET_DEFAULT_ACCOUNTING()
  isStarterEnabled: boolean = false
  dealer: DealerData = GET_DEFAULT_DEALER_DATA()

  /**
   * Dictionaries
   */
  _accountTypes: AccountingTypesResponse | null = null
  _accountCategories: AccountingCategoriesModel | null = null

  get isAccountingEnabled (): boolean {
    return this.accounting.accountingStartDate != null
  }

  setAccountingRaw = (accounting: AccountingSettingsResponse): void => {
    const { accountingStartDate } = accounting
    this.accounting = {
      ...accounting,
      accountingStartDate: toDateOrNull(accountingStartDate)
    }
    /**
     * DD-NOTE: dealer id is not set correctly during app load without this.
     */
    this.dealer.dealerFeeMarkupSettings.id = accounting.id
  }

  setAccounting = (accounting: AccountingSettings): void => {
    this.accounting = accounting
  }

  setAccountCategories = (data: AccountingCategoriesModel): void => {
    this._accountCategories = data
  }

  setAccountTypes = (data: AccountingTypesResponse): void => {
    this._accountTypes = data
  }

  /**
   * Enables "Accounting setup" pages.
   * AZ-TODO: improve naming.
   */
  enableStarter = (): void => {
    this.isStarterEnabled = true
  }

  setDealerSettings = (settings: DealerSettingsModel): void => {
    if (this.dealer != null) {
      this.dealer.dealerFeeMarkupSettings = {
        ...this.dealer.dealerFeeMarkupSettings,
        ...settings
      }
    }
  }

  dealersAction = async (): Promise<DealerData | null> => {
    try {
      this.isLoadingDealersAction = true
      const response = await DealersApiProvider.getDealer()

      window.dataLayer?.push({
        event: 'DealerId',
        DealerId: response.dealerFeeMarkupSettings.id
      })

      this.dealer = response

      return response
    } catch (e) {
      return null
    } finally {
      this.isLoadingDealersAction = false
    }
  }

  dealersActionWithLoading = async (): Promise<DealerData | null> => {
    try {
      this.isLoading = true
      return await this.dealersAction()
    } catch (e) {
      return null
    } finally {
      this.isLoading = false
    }
  }

  constructor () {
    makeAutoObservable(this)
  }
}

export const SettingsInstance = new Settings()

export const SettingsCTX = createContext(SettingsInstance)
export default SettingsCTX
