import { type DictionaryItem } from 'api/types'
import { RecipientsFilter, type RecipientFilterValues } from 'api/types/responses'
import { EXCLUDED_FILTER_OPTION, INCLUDED_FILTER_OPTION } from 'api/defaults/campaigns'
import { isFalsy } from 'utils/general'

export const LOST_STATUS_ITEM = { id: 7, name: 'Lost' }

export const parseCampaignFilter = (
  filterId: number | null | undefined,
  selectedIds: number[] = [],
  _options: DictionaryItem[] = [],
  isLostStatusSelected: boolean = false,
  isStatusField: boolean = false
): RecipientFilterValues | null => {
  if (isFalsy(filterId)) {
    return null
  }

  const filter = parseCampaignFilterType(filterId)
  const filteredOptions = _options
    .filter(item => selectedIds.includes(item.id))

  const options = (isStatusField && isLostStatusSelected) ? [...filteredOptions, LOST_STATUS_ITEM] : filteredOptions

  return { filter, options }
}

export const parseCampaignFilterType = (filterId: number | null | undefined): DictionaryItem | null => {
  return filterId === RecipientsFilter.Included
    ? INCLUDED_FILTER_OPTION()
    : filterId === RecipientsFilter.Excluded
      ? EXCLUDED_FILTER_OPTION()
      : null
}
