import { type ReactNode } from 'react'
import { cnx } from '@carfluent/common'

import PlusIcon from 'assets/plus_dark.svg'
import OPTION_CLASS_NAME from 'components/accounting/EntityOption/styles'
import CLASS_NAME from './styles'

export interface EntityActionProps {
  action: { id: string, name?: ReactNode, onClick: (id: string) => void }
  className?: string
}

const EntityAction = ({ action, className }: EntityActionProps): JSX.Element | null => {
  return (
    <div className={cnx(OPTION_CLASS_NAME, CLASS_NAME, 'cf-dropdown-option', className)}>
      <div className='cf-button-option'>
        <img className='cf-plus-icon' src={PlusIcon} alt='plus' />
        <div className='cf-button-title'>{action.name ?? ''}</div>
      </div>
    </div>
  )
}

export default EntityAction
