import { css } from '@emotion/css'

export default css(`
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #458FFF;
    white-space: nowrap;
    width: 290px;
  }
  
  &&.cf-popover-content {
    background-color: #212121;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`)
