import { RecipientsFilterOptions } from 'types/enums'

import {
  type GetFilterItems,
  type FilterItem
} from './types'

export const getFilterItems = ({ onCloseFilter, onChange, formValues }: GetFilterItems): FilterItem[] => {
  const getAction = (id: number): () => void => () => {
    onChange(id)
    onCloseFilter()
  }

  return [
    {
      onClick: getAction(RecipientsFilterOptions.LeadStatus),
      title: 'Lead status',
      id: RecipientsFilterOptions.LeadStatus,
      disabled: formValues.filterLeadStatus != null
    },
    {
      onClick: getAction(RecipientsFilterOptions.LeadSource),
      title: 'Lead source',
      id: RecipientsFilterOptions.LeadSource,
      disabled: formValues.filterLeadSource != null
    },
    {
      onClick: getAction(RecipientsFilterOptions.LeadTemperature),
      title: 'Lead temperature',
      id: RecipientsFilterOptions.LeadTemperature,
      disabled: formValues.filterLeadTemperature != null
    },
    {
      onClick: getAction(RecipientsFilterOptions.LeadWithAppointments),
      title: 'Lead with appointments',
      id: RecipientsFilterOptions.LeadWithAppointments,
      disabled: formValues.filterLeadWithScheduledAppointments != null
    }
  ]
}

// AZ-TODO: move to parser?
export const LOST_STATUS_ITEM = { id: 7, name: 'Lost' }
