import type { FC } from 'react'
import { formatInteger, formatCurrency } from '@carfluent/common'

import Checkbox from 'components/common/CheckboxNoMui'
import TooltipedText from 'components/common/TooltipedText'

import {
  CoveragePackageTypeIds,
  CoverageProductTypeIds,
  type DealerDefaultCoverageDto,
  type DealerProductCoveragePackageSettingsDto
} from 'api/types/responses'

import { COVERAGES_NAMES } from './constants'

import CLASS_NAME from './styles'

export interface PackageCustomizationProps {
  packages: DealerProductCoveragePackageSettingsDto[]
  onChange: (packages: DealerProductCoveragePackageSettingsDto[]) => void
  defaultCoverages: DealerDefaultCoverageDto[]
}

interface GetPackageIndexesReturn {
  basicIndex: number
  platinumIndex: number
  goldIndex: number
}

const getPackageIndexes = (
  packages: DealerProductCoveragePackageSettingsDto[]
): GetPackageIndexesReturn => {
  return {
    basicIndex: packages.findIndex(({ packageTypeId }) =>
      packageTypeId === CoveragePackageTypeIds.Basic),
    goldIndex: packages.findIndex(({ packageTypeId }) =>
      packageTypeId === CoveragePackageTypeIds.Gold),
    platinumIndex: packages.findIndex(({ packageTypeId }) =>
      packageTypeId === CoveragePackageTypeIds.Platinum)
  }
}

interface OnChangeProps {
  id: CoverageProductTypeIds
  index: number
}

const getInfo = (coverage: DealerDefaultCoverageDto): string => {
  return `${
    coverage.coverageName != null ? `${coverage.coverageName}` : ''
  }${
    coverage.termMonths !== 0 ? ` | ${coverage.termMonths} mo` : ''
  }${
    coverage.termMiles !== 0 ? ` | ${formatInteger(coverage.termMiles)} mi` : ''
  }${
    coverage.productTypeId === CoverageProductTypeIds.ServiceContract ? ` | ${formatCurrency(coverage.deductibleAmount)}` : ''
  }`
}

const PackageCustomization: FC<PackageCustomizationProps> = ({
  packages,
  onChange: _onChange,
  defaultCoverages
}) => {
  const { basicIndex, goldIndex, platinumIndex } = getPackageIndexes(packages)
  const basicPackage = packages[basicIndex]
  const goldPackage = packages[goldIndex]
  const platinumPackage = packages[platinumIndex]

  const onChange = ({ id, index }: OnChangeProps): void => {
    const newPackages = [...packages]

    if (newPackages[index] == null) {
      return
    }

    const newPackage = { ...newPackages[index] }
    const isExist = newPackage.productTypeIds?.includes(id)

    if (!isExist) {
      const newProductTypeIds = [...newPackage.productTypeIds]
      newProductTypeIds.push(id)
      newPackage.productTypeIds = newProductTypeIds
    } else {
      newPackage.productTypeIds = newPackage
        .productTypeIds
        .filter((_id) => _id !== id)
    }

    newPackages[index] = newPackage

    _onChange(newPackages)
  }

  return (
    <div className={CLASS_NAME}>
      <div className='grid-row'>
        <div className='header-title'>Default product selection:</div>

        <div className='package-label platinum'>
          {platinumPackage?.packageName}
        </div>
        <div className='package-label gold'>
          {goldPackage?.packageName}
        </div>
        <div className='package-label basic'>
          {basicPackage?.packageName}
        </div>
      </div>

      {defaultCoverages.length > 0
        ? (
          <>
            {defaultCoverages.map((coverage) => {
              const isBasicChecked = basicPackage?.productTypeIds.includes(coverage.productTypeId)
              const isGoldChecked = goldPackage?.productTypeIds.includes(coverage.productTypeId)
              const isPlatinumChecked = platinumPackage?.productTypeIds.includes(coverage.productTypeId)

              return (
                <div key={coverage.productTypeId} className='grid-row package-item'>
                  <div className='name-wrapper'>
                    <div className='coverage-name'>
                      {COVERAGES_NAMES[coverage.productTypeId as CoverageProductTypeIds]}
                    </div>
                    <div className='coverage-info'>
                      <TooltipedText
                        value={getInfo(coverage)}
                      />
                    </div>
                  </div>

                  <div className='checkbox-wrapper'>
                    <Checkbox
                      value={isPlatinumChecked}
                      onChange={() => onChange({ id: coverage.productTypeId, index: platinumIndex })}
                    />
                  </div>

                  <div className='checkbox-wrapper'>
                    <Checkbox
                      value={isGoldChecked}
                      onChange={() => onChange({ id: coverage.productTypeId, index: goldIndex })}
                    />
                  </div>

                  <div className='checkbox-wrapper'>
                    <Checkbox
                      value={isBasicChecked}
                      onChange={() => onChange({ id: coverage.productTypeId, index: basicIndex })}
                    />
                  </div>
                </div>
              )
            })}
          </>
          )
        : (
          <div className='empty-block'>
            No products added
          </div>
          )
      }
    </div>
  )
}

export default PackageCustomization
