import type { FC } from 'react'
import type { IconProps } from 'types'

const CreditAppReceivedIcon: FC<IconProps> = ({ color = '#21212180', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_86546_48)'>
        <path d='M2.66536 4.00016H1.33203V13.3335C1.33203 14.0668 1.93203 14.6668 2.66536 14.6668H11.9987V13.3335H2.66536V4.00016ZM13.332 1.3335H5.33203C4.5987 1.3335 3.9987 1.9335 3.9987 2.66683V10.6668C3.9987 11.4002 4.5987 12.0002 5.33203 12.0002H13.332C14.0654 12.0002 14.6654 11.4002 14.6654 10.6668V2.66683C14.6654 1.9335 14.0654 1.3335 13.332 1.3335ZM12.6654 7.3335H5.9987V6.00016H12.6654V7.3335ZM9.9987 10.0002H5.9987V8.66683H9.9987V10.0002ZM12.6654 4.66683H5.9987V3.3335H12.6654V4.66683Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_86546_48'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CreditAppReceivedIcon
