import { css } from '@emotion/css'

export default css`
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  overflow: hidden;

  :hover {
    border: 1px solid rgba(0, 0, 0, 0.4);
  }

  &.selected {
    border: 1px solid #C99B86;
  }

  .template-name {
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    padding: 16px;
    
    .unchecked {
      width: 24px;
      height: 24px;
      border: 1px solid #212121;
      border-radius: 50%;
    }

    svg, .unchecked {
      margin-right: 10px;
    }
  }

  .template-img {
    width: 100%;
  }
`
