import { css } from '@emotion/css'

import { LeadLabel } from 'api/types'
import { LabelColors } from 'constants/constants'
import { LABEL_COMMON_CSS } from 'components/common/LeadLabel/styles'
import { LABEL_TEXTS } from './hook/constants'

const HOT_CLASS_NAME_TEXT = LABEL_TEXTS[LeadLabel.Hot].toLowerCase()
const WARM_CLASS_NAME_TEXT = LABEL_TEXTS[LeadLabel.Warm].toLowerCase()
const COLD_CLASS_NAME_TEXT = LABEL_TEXTS[LeadLabel.Cold].toLowerCase()

export default css(`
  .cf-no-data-block {
    display: flex;
    justify-content: center;
    padding: 30px;
    background: #fff;
    border-top: 8px solid #F3F3F3;
  }

  .MuiPaper-root.cf-table-wrapper {
    border-radius: 2px 12px 2px 2px;
    
    .cf-table { table-layout: fixed; }

    .cf-table-header-title {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      white-space: nowrap;
    }

    .cf-table-row .cf-table-cell:first-child > div { padding-left: 8px; }

    .cf-cell-wrapper {
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .cf-table-cell .cf-cell-wrapper .cf-tooltip-container {
      min-width: 80px;
    }
  }
`)

export const LABEL_POPOVER_CLASS_NAME = css(`
  .cf-item-block {
    &.cf-${HOT_CLASS_NAME_TEXT} {
      .MuiFormControlLabel-label p {
        ${LABEL_COMMON_CSS}
        background-color: ${LabelColors.Hot};
      }
    }
    
    &.cf-${WARM_CLASS_NAME_TEXT} {
      .MuiFormControlLabel-label p {
        ${LABEL_COMMON_CSS}
        background-color: ${LabelColors.Warm};
      }
    }
    
    &.cf-${COLD_CLASS_NAME_TEXT} {
      .MuiFormControlLabel-label p {
        ${LABEL_COMMON_CSS}
        background-color: ${LabelColors.Cold};
      }
    }
  }
`)
