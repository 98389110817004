import type { User } from 'api/types'
import type { UserItem } from '../pages/crm/GeneralSettings/hook/types'

const parseUsers = (users: User[], defaultName: string = ''): UserItem[] =>
  users.map(({ id, firstName, lastName, phoneNumber }) => ({
    id,
    name: firstName != null && lastName != null ? `${firstName} ${lastName}` : defaultName,
    phoneNumber
  }))

export default parseUsers
