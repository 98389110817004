import { type FC } from 'react'
import { cn, PRESET_LABELS } from '@carfluent/common'

import FiltersPanel, { type FilterItem } from 'components/common/FiltersPanel'
import Table from 'components/common/Table'

import type { UseOpenBalancesTableProps } from './hook/types'
import useOpenBalancesTable from './hook'
import useDateFilters from 'hooks/useDateFilters'

import { formatWithRemoveEmptyDecimal } from 'utils/filters/filterPanel'
import { GET_DEFAULT_PRESETS } from './hook/constants'
import { AccountListItem, DateType, EntityListItem } from 'api/types'
import { joinPartsToStr } from 'utils/view_helper'
import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

export type { FiltersChangePayload, OpenBalanceRow, ReconciliationId } from './hook/types'
export * from './hook/constants'

const DEFAULT_PRESETS = GET_DEFAULT_PRESETS()

export interface OpenBalancesTableProps extends UseOpenBalancesTableProps {
  onChangeDateFilters?: () => void
  className?: string
  filtersTitle?: string
}

const getAccountOptionLabel = (option: AccountListItem | null): string => (
  joinPartsToStr(' - ', option?.number, option?.name)
)

const renderAcountOption = (option: AccountListItem | null): JSX.Element => (
  <>{getAccountOptionLabel(option)}</>
)

const getEntityOptionLabel = (option: EntityListItem | null): string => (
  option?.name ?? ''
)

const renderEntityOption = (option: EntityListItem | null): JSX.Element => (
  <>{getEntityOptionLabel(option)}</>
)

const OpenBalancesTable: FC<OpenBalancesTableProps> = ({
  className,
  filtersTitle,
  onChangeDateFilters,
  ...hookProps
}) => {
  const {
    columns,
    emptyTableMessage,
    isTableLoading,
    filtersProps,
    getAccounts,
    getEntities,
    onSearchChange,
    onSortingChange,
    rows,
    search,
    sorting,
    onSearch
  } = useOpenBalancesTable(hookProps)

  const isSearchLoading = isTableLoading || hookProps.isLoading

  const onDateFiltersChange = async (date: DateType): Promise<void> => {
    await filtersProps.onFilterChange({ date })
    onChangeDateFilters?.()
  }

  const {
    presets,
    setActiveDatePreset
  } = useDateFilters({
    defaultPreset: DEFAULT_PRESETS[0],
    setDateFilters: onDateFiltersChange
  })

  const dataFilters: FilterItem<'date'> = {
    filterType: 'date',
    componentProps: {
      presets,
      onPresetChange: setActiveDatePreset,
      onFilterChange: onDateFiltersChange,
      defaultPresetName: PRESET_LABELS.ALL_TILL_TODAY
    }
  }

  const accountFilters: FilterItem<'search-select', AccountListItem | null> = {
    filterType: 'search-select',
    componentProps: {
      name: 'Account',
      dataTestId: 'filter-account',
      id: 'account',
      mode: 'search',
      fetchHandler: getAccounts,
      renderOption: renderAcountOption,
      formatDisplayValue: getAccountOptionLabel,
      popoverClassName: POPOVER_CLASS_NAME,
      value: filtersProps.appliedFilters?.account ?? null,
      onChange: (_id, value) => {
        void filtersProps.onFilterChange({
          account: value
        })
      }
    }
  }

  const entityFilters: FilterItem<'search-select', EntityListItem | null> = {
    filterType: 'search-select',
    componentProps: {
      name: 'Entity',
      dataTestId: 'filter-entity',
      id: 'entity',
      mode: 'search',
      fetchHandler: getEntities,
      renderOption: renderEntityOption,
      formatDisplayValue: getEntityOptionLabel,
      popoverClassName: POPOVER_CLASS_NAME,
      value: filtersProps.appliedFilters?.entity ?? null,
      onChange: (_id, value) => {
        void filtersProps.onFilterChange({
          entity: value
        })
      }
    }
  }

  const amountFilters: FilterItem<'range'> = {
    filterType: 'range',
    componentProps: {
      onApply: (range) => {
        void filtersProps.onFilterChange({
          amount: {
            from: range?.from ?? null,
            to: range?.to ?? null,
            equal: range?.exact ?? null
          }
        })
      },
      popoverClassName: POPOVER_CLASS_NAME,
      formatter: formatWithRemoveEmptyDecimal,
      preset: 'price',
      name: 'Amount',
      hasExact: true
    }
  }

  return (
    <div className={cn(CLASS_NAME, 'cf-open-balances-table-root', className)}>
      <FiltersPanel
        title={filtersTitle}
        searchProps={{
          disabled: isSearchLoading,
          isLoading: isSearchLoading,
          onChange: onSearchChange,
          onSearch,
          value: search,
          placeholder: 'Reference #, control, description',
          dataTestId: 'open-balances-filter-search'
        }}
        filterProps={{
          filters: [
            dataFilters,
            accountFilters,
            entityFilters,
            amountFilters
          ]
        }}
      />

      <Table
        columns={columns}
        data={isTableLoading ? [] : rows}
        emptyTableMessage={emptyTableMessage}
        isLoading={isTableLoading || hookProps.isLoading}
        isMemoizedByOriginal
        sorting={sorting}
        onSortingChange={onSortingChange}
      />
    </div>
  )
}

export default OpenBalancesTable
