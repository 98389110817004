import type { FC } from 'react'
import type { ColumnDef } from '@carfluent/common/dist/UI'
import type { TableColumnsWidths } from '@carfluent/common/dist/utils/ui'

import type { CellProps as BaseCellProps, KeyVal } from 'types'
import ApplyPropsHOK from 'components/common/ApplyPropsHOC'

import TitleCell, { TitleCellProps } from './components/TitleCell'
import CurrencyCell, { CurrencyCellProps } from './components/CurrencyCell'

type CellProps = TitleCellProps | CurrencyCellProps
interface AppliedProps { isWrong: boolean }

const EmptyCell: FC<BaseCellProps<any>> = () => (<div />)

function getColumns (
  getWidths: (transactionTypeId?: number | null) => Map<string, TableColumnsWidths>,
  isWrong: boolean,
  transactionTypeId?: number | null
): Array<ColumnDef<KeyVal>> {
  const result: Array<ColumnDef<KeyVal>> = []
  const widths = getWidths(transactionTypeId)

  const Cells: Record<string, FC<CellProps>> = {
    credits: CurrencyCell,
    debits: CurrencyCell,
    entity: TitleCell
  }

  for (const key of widths.keys()) {
    const Component = Cells[key] ?? EmptyCell

    result.push({
      accessorKey: key,
      cell: ApplyPropsHOK<AppliedProps, BaseCellProps<KeyVal>>({ isWrong }, Component),
      sortable: false,
      ...widths.get(key)
    })
  }

  return result
}

export default getColumns
