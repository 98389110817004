import { css } from '@emotion/css'

export default css(`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 8px;
  
  :hover {
    .cf-tooltip {
      display: block;
    }
  }
  
  .cf-tooltip {
    position: absolute;
    padding: 4px 12px;
    border-radius: 4px;
    background: #212121;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    top: -30px;
    left: 50%;
    transform: translate(-50%, 0);
    display: none;
    text-transform: initial;
  }
`)
