import { CLOUD_ACCESS_TOKEN, type CloudAccessTokenDto, UserRoles } from 'api/types'
import type { KeyVal } from 'types'

const ACCESS_TOKEN = 'access_token'
const REFRESH_TOKEN = 'refresh_token'
const CHECKED_PENDING_DEAL_IDS = 'checkPendingDealIds'

export function getAccessToken (): string {
  return localStorage.getItem(ACCESS_TOKEN) ?? ''
}

export function setAccessToken (token: string): void {
  localStorage.setItem(ACCESS_TOKEN, token)
}

export function getRefreshToken (): string {
  return localStorage.getItem(REFRESH_TOKEN) ?? ''
}

export function setRefreshToken (token: string): void {
  localStorage.setItem(REFRESH_TOKEN, token)
}

export function clearLocalStorage (): void {
  localStorage.clear()
}

export function getParsedToken (): KeyVal | null {
  try {
    const token = getAccessToken()
    const parsed = JSON.parse(atob(token.split('.')[1]))

    return {
      ...parsed,
      available_dealer_ids: Array.isArray(parsed.available_dealer_ids)
        ? parsed.available_dealer_ids
        : [parsed.available_dealer_ids]
    }
  } catch (e) {
    return null
  }
}

export function getParsedCloudAccessToken (): CloudAccessTokenDto | null {
  try {
    const cloudAccessToken = localStorage.getItem(CLOUD_ACCESS_TOKEN)
    if (cloudAccessToken == null) {
      return null
    }
    return JSON.parse(cloudAccessToken)
  } catch (e) {
    return null
  }
}

export function getUserRoles (): UserRoles[] {
  const token = getParsedToken()

  if (token == null) {
    return []
  }

  return Array.isArray(token.role)
    ? token.role
    : [token.role]
}

export function getCheckedDealIds (): number[] {
  try {
    const checkedDealIds: string = localStorage.getItem(CHECKED_PENDING_DEAL_IDS) ?? ''
    return JSON.parse(checkedDealIds) ?? []
  } catch (err: any) {
    return []
  }
}

export function appendCheckedDealId (dealId: number): void {
  localStorage.setItem(
    CHECKED_PENDING_DEAL_IDS,
    JSON.stringify([...getCheckedDealIds(), dealId])
  )
}

export function isDealUnchecked (workflowId: number, requiresCheck: boolean): boolean {
  const checkedDealIds = getCheckedDealIds()
  return !checkedDealIds.includes(workflowId) && requiresCheck
}
