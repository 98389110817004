import { css } from '@emotion/css'

export default css(`
  display: flex;

  .cell-image {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }

  .basic-skeleton {
    width: clamp(80px, 70%, 110px);
    height: 24px;
  }
`)
