import { type FC } from 'react'
import { type MenuIconProps } from 'types'

const AccountingChartOfAccountsMenuIcon: FC<MenuIconProps> = ({
  isActive = false
}) => {
  const pictogramFill = isActive ? '#fff' : '#101010'

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11 9C11 10.66 9.66 12 8 12C6.34 12 5 10.66 5 9C5 7.34 6.34 6 8 6C9.66 6 11 7.34 11 9ZM14 19C14 19.5523 13.5523 20 13 20H3C2.44772 20 2 19.5523 2 19V18C2 15.79 4.69 14 8 14C11.31 14 14 15.79 14 18M22 12V14H13V12M22 8V10H13V8M22 4V6H13V4H22Z'
        fill={pictogramFill}
      />
    </svg>

  )
}

export default AccountingChartOfAccountsMenuIcon
