import { useCallback, useEffect, useMemo } from 'react'
import { type Result, useForm, useModal, isOk, useSubscribe } from '@carfluent/common'

import CustomersCoreApiProvider from 'api/customersCore.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { GET_DEFAULT_BUYER_MODEL, GET_DEFAULT_COBUYER_MODEL, GET_DEFAULT_BUSINESS_DETAILS } from 'api/defaults/deals'
import Events from 'constants/events'
import isDealTransactionRecorded from 'utils/deals/isDealTransactionRecorded'

import type { BuyerCobuyerTabState, ErrTypeMap, UseBuyerCobuyerTabProps, UseBuyerCobuyerTabReturn } from './types'
import { validationRules, DEPENDENT_VALIDATIONS } from './validation'
import serializeTabBuyerCobuyer from './serializer'

const DEFAULT_VALUES: BuyerCobuyerTabState = {
  buyer: GET_DEFAULT_BUYER_MODEL(),
  cobuyer: GET_DEFAULT_COBUYER_MODEL(),
  businessDetails: GET_DEFAULT_BUSINESS_DETAILS(),
  isCobuyerSectionVisible: false,
  isPersonal: true
}

const ERROR_MSG = 'Changes failed to save.'
const SUCCESS_MSG = 'Changes successfully saved.'

const useBuyerCobuyerTab = ({
  dealId,
  deal,
  isSelected,
  nextTab,
  tabIdx,
  onTrySwitchTab,
  onSaveChanges
}: UseBuyerCobuyerTabProps): UseBuyerCobuyerTabReturn => {
  const { showAlert } = useCustomSnackbar()
  const { isModalOpen: isUnsavedChangesShown, onOpenModal, onCloseModal } = useModal()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const isRecorded = isDealTransactionRecorded(deal?.dealAccountingStateId ?? null)

  const baseValues = useMemo(() => {
    return deal == null
      ? DEFAULT_VALUES
      : {
          buyer: deal.buyer,
          cobuyer: deal.cobuyer,
          businessDetails: deal.businessDetails ?? GET_DEFAULT_BUSINESS_DETAILS(),
          isCobuyerSectionVisible: deal.isCobuyerSectionVisible,
          isPersonal: deal.isPersonal
        }
  }, [deal])

  const submitAction = useCallback(async (_values: BuyerCobuyerTabState): Promise<BuyerCobuyerTabState | null> => {
    try {
      if (deal?.rowVersion == null) {
        return null
      }

      const data = serializeTabBuyerCobuyer(_values, deal?.isPersonal)
      const payload = { dealId, rowVersion: deal?.rowVersion, data }

      const res = await CustomersCoreApiProvider.patchDealBuyer(payload)

      onSaveChanges(res)
      showAlert(SUCCESS_MSG, { variant: 'success' })

      return {
        businessDetails: _values.businessDetails,
        isCobuyerSectionVisible: _values.isCobuyerSectionVisible,
        cobuyer: {
          ...res.cobuyer,
          sameAddressAsMainApplicant: _values.cobuyer.sameAddressAsMainApplicant
        },
        buyer: res.buyer,
        isPersonal: res.isPersonal
      }
    } catch (e: any) {
      showAlert(e?.response?.data?.message ?? ERROR_MSG)
      return null
    }
  }, [dealId, deal?.isPersonal, deal?.rowVersion, deal?.cobuyer?.currentAddressDetails?.id, showAlert, onSaveChanges])

  const onActionResult = useCallback((res: Result<BuyerCobuyerTabState>, resetForm: (vals: BuyerCobuyerTabState) => void) => {
    if (isOk(res)) {
      resetForm(res.result)
    }
  }, [])

  const {
    values,
    errors,
    touched,
    isSubmitting,
    hasChanges,
    onBlur,
    onSubmit,
    onChange,
    validateForm,
    resetForm
  } = useForm<BuyerCobuyerTabState, {}, never, ErrTypeMap>({
    baseValues,
    dependentValidations: DEPENDENT_VALIDATIONS,
    validationRules,
    isTrackingChanges: true,
    submitAction,
    onActionResult
  })

  const onChangeSectionVisibility = (): void => {
    if (isRecorded) {
      return
    }
    onChange('isCobuyerSectionVisible', !(values.isCobuyerSectionVisible ?? false))
    onChange('cobuyer', GET_DEFAULT_COBUYER_MODEL())
    onBlur('isCobuyerSectionVisible')

    // DD-NOTE: unfortunately for now form actions are not properly synchronous
    // form internals must be rewritten to support this
    setTimeout(() => {
      validateForm()
    }, 0)
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const isUnsavedChangesShown = (nextTab.id !== tabIdx) && hasChanges

    if (isUnsavedChangesShown) {
      onOpenModal()
    } else {
      onCloseModal()
      onTrySwitchTab()
    }
  }, [nextTab, hasChanges, onTrySwitchTab, onOpenModal, onCloseModal])

  useSubscribe(Events.DealSaveRequested, async (_, skip) => {
    if (!isSelected || !hasChanges) {
      return skip()
    }

    const result = await onSubmit()
    return isOk(result)
  })

  // ========================================== //

  return {
    values,
    errors,
    touched,
    isSubmitting,
    hasChanges,
    isPersonal: deal?.isPersonal ?? false,
    isUnsavedChangesShown,
    onOpenUnsavedChanges: onOpenModal,
    onCloseUnsavedChanges: onCloseModal,
    onBlur,
    onSubmit,
    onChange,
    resetForm,
    onChangeSectionVisibility,
    isRecorded
  }
}

export default useBuyerCobuyerTab
