import type { DeepRequired } from '@carfluent/common'

import type { LienholderItemDto } from 'api/types'
import type { FullAddressParts } from 'types/address'
import type { LienholderFormData, MailingAddressParts } from './types'

export interface ParsedCustomerData {
  formData: LienholderFormData
  address: FullAddressParts | null
  mailingAddress: MailingAddressParts | null
}

const parseCustomerData = (data: LienholderItemDto): DeepRequired<LienholderFormData> => {
  const address = {
    address: data.address,
    city: data.city,
    zipCode: data.zipCode,
    state: data.state,
    county: null,
    addressLong: null
  }

  return {
    id: data.id ?? null,
    name: data.name,
    email: data.email,
    phoneNumber: data.phoneNumber,
    address,
    corpCode: data.corpCode
  }
}

export default parseCustomerData
