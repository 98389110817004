import type { FC } from 'react'
import { cn, CellWrapper } from '@carfluent/common'

import type { ControlRenderable, DealRenderable } from 'components/common/ControlIdentifierBadge'
import ControlIdentifierBadge from 'components/common/ControlIdentifierBadge'
import ConfigurableCellHOC from 'components/common/Table/cells/ConfigurableCellHOC'
import CLASS_NAME from './styles'

export interface ControlIdentifierHOCProps {
  className?: string
}

export interface ControlIdentifierCellProps extends ControlIdentifierHOCProps {
  getValue: () => ControlRenderable | DealRenderable | null
}

export const ControlIdentifierCell: FC<ControlIdentifierCellProps> = ({
  className,
  getValue
}) => {
  const control = getValue()

  return (
    <CellWrapper className={cn(CLASS_NAME, 'cf-control-identifier-cell', className)}>
      <ControlIdentifierBadge control={control} />
    </CellWrapper>
  )
}

export const ControlIdentifierCellHOC = (hocProps: ControlIdentifierHOCProps) =>
  ConfigurableCellHOC(ControlIdentifierCell, hocProps)

export default ControlIdentifierCell // AZ-TODO: remove
