import camelCase from 'lodash-es/camelCase'

const getKeyWithPrefix = (key: string, prefix: string = ''): [string, string] => {
  const key1 = camelCase(`${prefix}${key}`)
  const key2 = camelCase(key)

  return [key1, key2]
}

export default getKeyWithPrefix
