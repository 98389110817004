import { parsers } from '@carfluent/common'

import type { ReceiptItem } from 'api/types'
import { type PaymentAmounts, type PaymentFormData, PaymentTransactionTypeEnum } from 'types'

import DEFAULT_PAYMENT_FORM_DATA from './constants'

export const parseData = (data: ReceiptItem): PaymentFormData => {
  const isMultipleTransaction = data.paymentDetails.length > 1
  const isManual = data.paymentDetails[0].isManual

  return {
    isManual,
    amount: data.amount,
    transactionTypeId: isMultipleTransaction
      ? PaymentTransactionTypeEnum.MULTIPLE
      : data.paymentDetails[0].transactionTypeId,
    description: data.description,
    isBillingAddressProvided: false,
    billingDetails: null,
    paymentDate: parsers.parseDateStringUTC(data.receiptDate) ?? new Date(),
    printReceiptCheck: true,
    payments: data.paymentDetails.reduce<PaymentAmounts>((res, item) => {
      switch (item.transactionTypeId) {
        case PaymentTransactionTypeEnum.CASH:
          res.CASH = item.amount
          break
        case PaymentTransactionTypeEnum.ACH:
          res.ACH = item.amount
          break
        case PaymentTransactionTypeEnum.CARD:
          res.CARD = item.amount
          break
        case PaymentTransactionTypeEnum.CHECK:
          res.CHECK = item.amount
          break
        case PaymentTransactionTypeEnum.ZELLE:
          res.ZELLE = item.amount
          break
      }

      return res
    }, { ...DEFAULT_PAYMENT_FORM_DATA.payments })
  }
}
