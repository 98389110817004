import { useContext, useMemo } from 'react'

import { type EntityId } from 'types'
import { type AccountingCategoriesModel } from 'api/types/responses'
import AccountingApiProvider from 'api/accounting.api'
import SettingsCTX from 'store/settings'
import usePrefetchable from 'hooks/usePrefetchable'
import { toJS } from 'mobx'

export interface UseAccountCategoriesReturn {
  getCategories: (isForced?: boolean, shouldUseCached?: boolean) => Promise<Map<EntityId, string>>
  isLoading: boolean
}

const useAccountCategories = (shouldPrefetch: boolean = false): UseAccountCategoriesReturn => {
  const { _accountCategories, setAccountCategories } = useContext(SettingsCTX)

  const {
    getData: getCategories,
    isLoading
  } = usePrefetchable({
    getEmptyState,
    getData: AccountingApiProvider.getCategories,
    getFromStore: () => toJS(_accountCategories),
    parseData: parseCategories,
    saveToStore: setAccountCategories,
    shouldPrefetch
  })

  // ========================================== //

  return useMemo(() => ({
    getCategories,
    isLoading
  }), [
    getCategories,
    isLoading
  ])
}

export default useAccountCategories

// ========================================== //

const getEmptyState = (): Map<number, string> => new Map<number, string>()

const parseCategories = (categories: AccountingCategoriesModel): Map<EntityId, string> => {
  const result = new Map<EntityId, string>()

  for (const item of categories.items) {
    result.set(item.id, item.name ?? '')
  }

  return result
}
