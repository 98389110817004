import { type FC, useCallback } from 'react'
import { cnx, Dropdown2 } from '@carfluent/common'
import { Link } from 'react-router-dom'

import IconSVG from 'components/inlineImages'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

import { type HeaderProps, type DateRangeOption } from './types'
import { POPOVER_CONFIG } from './constants'
import { CLASS_NAME, DEAL_FILTERS_POPOVER_CLASS_NAME } from './styles'

const Header: FC<HeaderProps> = ({
  className,
  dateRange,
  isLoading = false,
  title,
  routes,
  dateRangeOptions,
  withFilter = true,
  onChangeDateRange: _onChangeDateRange
}) => {
  const onChangeDateRange = useCallback((_, value: DateRangeOption | null): void => {
    _onChangeDateRange?.(value)
  }, [_onChangeDateRange])

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, 'header', className)}>
      <BlockSkeleton className='link-block' isLoading={isLoading}>
        <Link to={routes} className='link g-link'>
          <h6>{title}</h6>
          <IconSVG.ArrowStep color='#101010' width={24} height={24} opacity={1} />
        </Link>
      </BlockSkeleton>

      {withFilter && (
        <BlockSkeleton className='filter-block' isLoading={isLoading}>
          <Dropdown2
            id='filter'
            blurMode='select-first'
            className='filter'
            popoverClassName={DEAL_FILTERS_POPOVER_CLASS_NAME}
            popoverOptions={POPOVER_CONFIG}
            mode='select'
            disableClearable
            options={dateRangeOptions}
            value={dateRange}
            onChange={onChangeDateRange}
          />
        </BlockSkeleton>
      )}
    </div>
  )
}

export default Header
