import { type CoverageDetails } from 'api/types'

export type CoverageDetailsLike = Pick<CoverageDetails, 'deductibleId'>

/**
 * Determines if the coverage is manual. A coverage is considered manual
 * if it does not contain a deductibleId.
 */
const isManualCoverage = (coverage: CoverageDetailsLike): boolean => {
  return (coverage.deductibleId === null) || (coverage.deductibleId === 0)
}

export default isManualCoverage
