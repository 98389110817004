import { type DealerProductDeductibleDto } from 'api/types/responses'

const findDeductible = (
  deductibleId: undefined | number | null,
  deductibles: DealerProductDeductibleDto[]
): DealerProductDeductibleDto | null => {
  return deductibles.find(item => item.id === deductibleId) ?? null
}

export default findDeductible
