import { setupCache } from 'axios-cache-interceptor'
import Axios, {
  AxiosHeaders,
  AxiosResponse,
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type Method
} from 'axios'

import { bodyRefresh } from 'constants/store_helpers'
import { GlobalUIInstance } from 'store/global_ui'
import ws from 'services/web_sockets'

import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken
} from 'services/storage.service'
import { logout } from 'utils/authHelpers'
import { trimSlashEnd } from 'utils/general'
import type { CancelationOptions, TokenResponse } from './types'
import { AuthInstance } from 'store/auth'

type RequestParams = {
  isAuthErrorRetried?: boolean
  useDefaultHeaders?: boolean
} & Record<string, any>

const previousRequests: any = {}
const baseURL = process.env.REACT_APP_CUSTOMERSCORE ?? ''

const DEFAULT_HEADERS = {
  'Access-Control-Expose-Headers': 'Content-Disposition',
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  'Carfluent-Client': 'CarFluent.Dealers.Web'
}

let tokenReconnectRequestCache: Promise<TokenResponse> | null

const ACCESS_TOKEN_URL = `${trimSlashEnd(process.env.REACT_APP_IDENTITY ?? '')}/connect/token`

export const isCancelled = (error: any): boolean => {
  /**
   * DD-NOTE: axios interceptor Promise.reject breaks error type and axios does not recognize then
   * it as a cancellation error.
   */
  return error instanceof Axios.Cancel
}
export class WrapperRequest {
  post = async <T = any>(url: string, params = {}, cancelationOptions: CancelationOptions = {}): Promise<T> =>
    await this.makeRequest<T>('post', url, params, cancelationOptions)

  put = async <T = any>(url: string, params = {}, cancelationOptions: CancelationOptions = {}): Promise<T> =>
    await this.makeRequest<T>('put', url, params, cancelationOptions)

  patch = async <T = any>(url: string, params = {}, cancelationOptions: CancelationOptions = {}): Promise<T> =>
    await this.makeRequest<T>('patch', url, params, cancelationOptions)

  get = async <T = any>(url: string, params = {}, cancelationOptions: CancelationOptions = {}): Promise<T> =>
    await this.makeRequest<T>('get', url, params, cancelationOptions)

  delete = async <T = any>(url: string, params = {}, cancelationOptions: CancelationOptions = {}): Promise<T> =>
    await this.makeRequest<T>('delete', url, params, cancelationOptions)

  cancelRequest = (key: string): void => {
    if (previousRequests[key] != null) {
      previousRequests[key].cancel()
      previousRequests[key] = null
    }
  }

  private readonly instance: AxiosInstance

  constructor () {
    this.instance = setupCache(Axios.create({ baseURL }))

    this.instance.interceptors.response.use(
      (res: AxiosResponse): AxiosResponse => {
        const hasData = Boolean(res.data)
        /**
         * OP-NOTE: the X-Special-Request header is used to mark certain HTTP requests as special,
         * allowing our Axios interceptor to identify and handle these requests differently.
         * It is useful when we need to have an access to the whole response not just response data.
         * e.g. Need to take blob file name from response headers.
         */
        const isSpecialRequest = res.config.headers['X-Special-Request'] === 'true'

        if (isSpecialRequest) {
          return res
        }

        return hasData ? res.data : res // AZ-TODO: research if it's ok. Returns response for 204
      },
      async (error: AxiosError) => {
        if (error.response == null) {
          return await Promise.reject(error)
        }

        const { status, config } = error.response
        const isAuthError = status === 401
        const isConflict = status === 409
        const isTokenRestorationRequest = config.url === ACCESS_TOKEN_URL

        const isAuthErrorRetried = (config as any).isAuthErrorRetried as boolean ?? true
        const cancellationKey = (config as any).cancellationKey as string ?? ''

        /**
         * In case of auth or token expiration error, try to restore access token
         * from refresh token and re-send original failed request.
          */
        if (isAuthError && !isTokenRestorationRequest && isAuthErrorRetried) {
          try {
            /**
             * we need to remove request from cache so that after receiving token it would not be cancelled during retry
             */
            previousRequests[cancellationKey] = null

            const data = bodyRefresh(getRefreshToken())
            const authConfig = {
              ...config,
              responseType: 'json' as 'json'
            }

            authConfig.headers = new AxiosHeaders({
              'Content-Type': 'application/x-www-form-urlencoded'
            })

            if (tokenReconnectRequestCache == null) {
              tokenReconnectRequestCache = this.instance.post(ACCESS_TOKEN_URL, data, authConfig)
            }

            const response = await tokenReconnectRequestCache

            setAccessToken(response.access_token)
            setRefreshToken(response.refresh_token)

            AuthInstance.updateUserRoles()

            config.headers.Authorization = `Bearer ${response.access_token}`
          } catch (err: any) {
            /**
             * OP-NOTE: This catch block is necessary only for the refresh token request.
             * Other requests have their own try/catch blocks in the components, hooks, API services, etc.
             */
            console.error(err)
            await ws.shutdown()
            logout()
            return
          } finally {
            tokenReconnectRequestCache = null
          }

          const res = await this.instance.request(config)
          return res
        }

        if (isConflict) {
          GlobalUIInstance.showRecordWasUpdated()
        }

        return await Promise.reject(error)
      }
    )
  }

  async makeRequest<T>(
    method: Method,
    url: string,
    _parameters: RequestParams,
    cancelationOptions: CancelationOptions = {}
  ): Promise<T> {
    const isUpdatingMethod = method === 'post' || method === 'patch' || method === 'put'
    const cancellationKey = cancelationOptions.key ?? url

    if (cancelationOptions.cancelPrevious === true && previousRequests[cancellationKey] != null) {
      previousRequests[cancellationKey].cancel()
    }

    let {
      additionalParams = {},
      isAuthErrorRetried = true,
      useDefaultHeaders = true,
      ...parameters
    } = _parameters

    let data
    let customHeaders = {}

    /**
     * DD-NOTE: any type is the same as in axios docs.
     * Should be reworked after new API wrapper is implemented.
     */
    let params: any = {}

    if (isUpdatingMethod) {
      data = parameters
    } else if (method === 'get') {
      params = parameters
    }

    if ((parameters.data != null) || (parameters.headers != null)) {
      data = parameters.data
      customHeaders = parameters.headers
    } else {
      data = parameters
    }

    if (parameters.additionalParams != null) {
      additionalParams = parameters.additionalParams
    }

    const headers = {
      ...(useDefaultHeaders ? DEFAULT_HEADERS : undefined),
      Authorization: `Bearer ${getAccessToken()}`,
      ...customHeaders
    }

    const config: AxiosRequestConfig = {
      cache: false,
      method,
      baseURL,
      url,
      params,
      headers,
      data,
      cancelToken: new Axios.CancelToken((c) => {
        if (previousRequests[cancellationKey] == null) {
          previousRequests[cancellationKey] = {}
        }
        previousRequests[cancellationKey].cancel = c
      }),
      ...additionalParams,
      cancellationKey,
      isAuthErrorRetried
    }

    return await this.instance.request(config)
  }
}
