export interface TermValue {
  termMiles: string | null
  termMonths: string | null
}

const parseTermValue = (value: string | null): TermValue => {
  if (value == null) {
    return {
      termMiles: null,
      termMonths: null
    }
  }

  const [months, miles] = value
    .split('/')
    .map((token) => token.trim().split(' ')[0])

  return {
    termMiles: miles.replace(',', '') ?? null,
    termMonths: months ?? null
  }
}

export default parseTermValue
