import { parsers as P } from '@carfluent/common'
import { type Deal, PaymentTypeId } from 'api/types'
import { DealState, VehicleStateNames } from 'constants/names'
import { isDealPending, isDeposited } from 'utils/deals'
import isDealWaitingForCreditAppApprove from 'utils/deals/isDealWaitingForCreditAppApprove'
import { joinPartsBySpace } from 'utils/view_helper'

import type { DealRowData, DealFromApi } from './types'

export const parseListData = (data: DealFromApi[]): DealRowData[] => {
  return data.map(parseRowData)
}

export const PAYMENT_NAME_MAPPER = {
  [PaymentTypeId.Finance]: 'Finance',
  [PaymentTypeId.Cash]: 'Cash'
}

export const parseRowData = (data: DealFromApi): DealRowData => {
  const {
    createdDate,
    currentWorkflowStepName,
    currentWorkflowStepStartedAt,
    customerState,
    customerCity,
    customerEmail,
    customerFirstName,
    customerLastName,
    customerPhoneNumber,
    dealState,
    isDeposited,
    isVehicleDeposited,
    vehicleState,
    monthlyPayment,
    dealFinanceTypeId,
    vehicleImageUrl,
    vehicleModel,
    vehicleModelYear,
    vehiclePrice,
    workflowId,
    workflowAccountingState,
    accountingStateChangeDate,
    nextAction,
    name,
    saleDate
  } = data

  return {
    id: workflowId,
    client: {
      firstName: customerFirstName,
      lastName: customerLastName,
      address: joinPartsBySpace(customerCity, customerState),
      name
    },
    contacts: {
      email: customerEmail,
      phone: customerPhoneNumber
    },
    date: P.parseDateStringUTC(createdDate),
    saleDate: saleDate != null ? new Date(saleDate) : null,
    status: {
      status: dealState,
      isPending: isDealPending(data),
      isCompleted: dealState === DealState.Completed,
      isDeposited,
      isCanceled: dealState === DealState.Canceled
    },
    nextAction,
    payment: {
      name: dealFinanceTypeId !== null ? PAYMENT_NAME_MAPPER[dealFinanceTypeId] ?? '' : '',
      value: monthlyPayment
    },
    step: {
      startedAt: P.parseDateStringUTC(currentWorkflowStepStartedAt),
      stepName: currentWorkflowStepName
    },
    workflowAccountingState: {
      startedAt: P.parseDateStringUTC(accountingStateChangeDate),
      workflowId: workflowAccountingState
    },
    vehicle: {
      imageUrl: vehicleImageUrl,
      model: vehicleModel,
      price: vehiclePrice,
      year: vehicleModelYear,
      isDeposited: isVehicleDeposited && !isDeposited,
      isCarDeleted: vehicleState === VehicleStateNames.Deleted
    },
    dealPaidStatusId: data.dealPaidStatusId
  }
}

export const parseDealToDealRowData = (data: Deal, dealId: string | number): DealRowData => {
  return {
    id: Number(dealId),
    client: {
      firstName: data.customerDetails.customerFirstName,
      lastName: data.customerDetails.customerLastName,
      address: data.customerDetails.customerAddress,
      name: data.name
    },
    date: P.parseDateStringUTC(data.createdDate),
    saleDate: P.parseDateStringUTC(data.saleDate),
    status: {
      status: data.dealState,
      isPending: isDealWaitingForCreditAppApprove(data), // AZ-TODO: check if this works as expected
      isCompleted: data.dealState === DealState.Completed,
      isDeposited: isDeposited(data),
      isCanceled: data.dealState === DealState.Canceled
    },
    nextAction: data.nextAction ?? 0,
    vehicle: {
      year: data.vehicleDetails.vehicleModelYear,
      imageUrl: data.vehicleDetails.vehicleImageUrl,
      model: data.vehicleDetails.vehicleModel,
      price: data.vehicleDetails.vehiclePrice,
      isDeposited: data.isVehicleDeposited && !isDeposited(data),
      isCarDeleted: data.vehicleDetails.vehicleState === VehicleStateNames.Deleted
    },
    payment: {
      name: data.financingDetails.paymentType,
      value: data.financingDetails.monthlyPayment
    },
    step: {
      startedAt: P.parseDateStringUTC(data.currentWorkflowStepStartedAt),
      stepName: data.currentWorkflowStepName
    },
    workflowAccountingState: {
      startedAt: P.parseDateStringUTC(data.accountingStateChangeDate),
      workflowId: data.workflowAccountingStateId
    },
    contacts: {
      email: data.customerDetails.customerEmail,
      phone: data.customerDetails.customerPhoneNumber
    },
    dealPaidStatusId: data.dealPaidStatusId
  }
}
