import { css } from '@emotion/css'

export default css(`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .cf-dropdown-root {
    width: 320px;
  }

  .field-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .dropzone-content {
    height: 112px;
    width: 100%;
    border: 1px solid black;
    position: relative;
  }

  .banner-error:after,
  .banner-duplicates:after {
    display: none;
  }
`)
