import { type FC, useEffect, useMemo } from 'react'
import { cnx, TimeSelect, FormDatePicker, FormDropdown, FormInput } from '@carfluent/common'

import type { DictionaryItem } from 'api/types'
import { ValidationLength } from 'constants/validation'
import { LogType } from 'pages/crm/LeadDetailsView/hook/types'
import useLeadLogs, { type UseLeadLogsFormReturn } from 'pages/crm/LeadDetailsView/components/LeadDetailsLogActivity/hook'
import { FieldIds, type LogActivityProps } from 'pages/crm/LeadDetailsView/components/LeadDetailsLogActivity/hook/types'
import { UNASSIGNED_OPTION } from 'pages/crm/LeadDetailsView/hook/constants'
import NameWithAvatar from 'components/common/NameWithAvatar'
import DropdownIcon from 'components/common/DropdownIcon'
import { renderAssignOption, renderNoUsersOption } from 'components/crm/common'

import ActionBar from '../ActionBar'
import LogCallForm from '../LogCallForm'
import LogVisitForm from '../LogVisitForm'

import CLASS_NAME from './styles'

const LogActivityComponentMap: Record<LogType, FC<UseLeadLogsFormReturn>> = {
  [LogType.Call]: LogCallForm,
  [LogType['Store visit']]: LogVisitForm,
  [LogType.Other]: () => <></>
}

const FormClassNameMap: Record<LogType, string> = {
  [LogType.Call]: 'call-form',
  [LogType['Store visit']]: 'visit-form',
  [LogType.Other]: 'other-form'
}

const LogActivityLayout: FC<LogActivityProps> = ({
  logTypeId,
  assignedTo,
  reminderSubjects,
  onCloseActivity,
  onSubmitActivityLog
}) => {
  const formProps = useLeadLogs({
    logTypeId,
    onCloseActivity,
    onSubmitActivityLog
  })

  const {
    isSubmitting,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onSubmit,
    setErrors
  } = formProps

  const isSubject = logTypeId === LogType.Other

  const formClassName = FormClassNameMap[logTypeId]

  const isTimeValue = (values.time != null) && (values.time !== '')

  const FormComponent = useMemo(() => LogActivityComponentMap[logTypeId], [logTypeId])

  useEffect(() => {
    setErrors({
      activityCompleteOption: null,
      activityCompleteSubOptions: null,
      assignedUser: null,
      date: null,
      isInbound: null,
      subject: null,
      time: null,
      workNotes: null
    })
  }, [logTypeId, setErrors])

  return (
    <div className={cnx(CLASS_NAME, formClassName, values.isInbound != null && 'call-form-with-suboptions')}>
      {isSubject
        ? <FormDropdown<DictionaryItem>
            className='subject'
            id={FieldIds.Subject}
            options={reminderSubjects}
            label='Subject'
            mode='search'
            blurMode='select-first'
            disableClearable
            value={values[FieldIds.Subject]}
            touched={touched[FieldIds.Subject]}
            error={errors[FieldIds.Subject]}
            onChange={onChange}
            onBlur={onBlur}
            dataTestId='lead-log-task-field-subject'
          />
        : null}

      <div className='due-date prefilled-value'>
        <FormDatePicker
          id={FieldIds.Date}
          label='Date'
          value={values.date}
          touched={touched.date}
          error={errors.date}
          onChange={onChange}
          onBlur={onBlur}
          data-test-id='lead-task-due-date'
        />
      </div>

      <div className={`time ${isTimeValue ? 'prefilled-value' : ''}`}>
        <TimeSelect
          id={FieldIds.Time}
          classes={{ option: 'time-option' }}
          label='Time'
          onChange={(val) => { onChange(FieldIds.Time, val) }}
          value={values.time}
          error='Invalid time'
          data-test-id='lead-task-time'
          DropdownIconComponent={DropdownIcon}
        />
      </div>

      <FormDropdown<DictionaryItem>
        className='assigned'
        id={FieldIds.AssignedTo}
        label='Assigned to'
        mode='search'
        blurMode='select-first'
        disableClearable
        options={[UNASSIGNED_OPTION, ...assignedTo]}
        renderOption={renderAssignOption}
        renderNoOptions={renderNoUsersOption}
        startAdornment={<NameWithAvatar showAvatar avatarOnly name={values[FieldIds.AssignedTo]?.name} />}
        value={values[FieldIds.AssignedTo]}
        error={errors[FieldIds.AssignedTo]}
        touched={touched[FieldIds.AssignedTo]}
        onChange={onChange}
        onBlur={onBlur}
        dataTestId='lead-log-task-field-assigned-to'
      />

      <FormComponent {...formProps} />

      <FormInput
        className='notes'
        id={FieldIds.WorkNotes}
        onChange={onChange}
        label='Work notes'
        value={values[FieldIds.WorkNotes]}
        maxLength={ValidationLength.TEXT_FIELDS_MAX}
      />

      <ActionBar
        buttonsClassName='activity-tasks-action-buttons'
        onSubmit={onSubmit}
        onCancel={onCloseActivity}
        isLoading={isSubmitting}
      />
    </div>
  )
}

export default LogActivityLayout
