import { type FC } from 'react'
import { type IconProps } from 'types'

const IconSVGPencil: FC<IconProps> = ({
  color = '#212121',
  className,
  width = 24,
  height = 24,
  opacity = 0.4
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 17.2553V21.0053H6.75L17.81 9.94531L14.06 6.19531L3 17.2553ZM20.7128 7.0425C21.1028 6.6525 21.1028 6.0225 20.7128 5.6325L18.3728 3.2925C17.9828 2.9025 17.3528 2.9025 16.9628 3.2925L15.1328 5.1225L18.8828 8.8725L20.7128 7.0425Z'
        fill={color}
        fillOpacity={opacity}
      />
    </svg>

  )
}

export default IconSVGPencil
