import {
  useState,
  useCallback,
  ChangeEvent,
  ReactNode,
  FC,
  useMemo
} from 'react'

import { Tabs, Tab } from '@material-ui/core'

import TabPanel, { a11yProps } from './TabPanel'
import { TabIndexes, TabLabels } from './constants'
import { createStyleClass, createIndicatorClass } from './styles'

interface DetailsSummaryTabsProps {
  detailsTabComponent: ReactNode
  summaryTabComponent: ReactNode
  isDisabled: boolean
}

const DetailsSummaryTabs: FC<DetailsSummaryTabsProps> = ({
  summaryTabComponent,
  detailsTabComponent,
  isDisabled
}) => {
  const [activeIndex, setActiveIndex] = useState(TabIndexes.Summary)
  const classes = useMemo(() => ({
    flexContainer: createStyleClass(),
    indicator: createIndicatorClass()
  }), [])

  const handleChange = useCallback((e: ChangeEvent<{}>, i: number) => {
    setActiveIndex(i)
  }, [])

  return (
    <Tabs
      value={activeIndex}
      onChange={handleChange}
      classes={classes}
    >
      <Tab label={TabLabels.Summary} {...a11yProps(TabIndexes.Summary)} />
      <Tab label={TabLabels.Details} {...a11yProps(TabIndexes.Details)} />

      <TabPanel
        value={activeIndex}
        index={TabIndexes.Summary}
        isDisabled={isDisabled}
      >
        {summaryTabComponent}
      </TabPanel>

      <TabPanel
        value={activeIndex}
        index={TabIndexes.Details}
        isDisabled={isDisabled}
      >
        {detailsTabComponent}
      </TabPanel>
    </Tabs>
  )
}

export default DetailsSummaryTabs
