import type { FC } from 'react'
import { FormDropdown, FormNumberInput, FormDatePicker } from '@carfluent/common'

import { DATE_MONTH_YEAR_FORMAT } from 'constants/constants'
import { ValidationLength } from 'constants/validation'
import { isPreviousEmploymentOrHousingNeeded } from 'utils/creditApplication'

import type { HousingAndEmploymentSectionProps } from '../../types'
import PreviousHousing from '../PreviousHousing'
import EmploymentDetails from '../EmploymentDetails'

const ApplicantHousingAndEmployment: FC<HousingAndEmploymentSectionProps> = ({
  housingStatuses,
  employmentStatuses,
  isFormDisabled,
  values,
  errors,
  touched,
  onBlur,
  onChange
}) => {
  const housingStatus = housingStatuses.find((item) => item.id === values.housingStatus) ?? null

  return (
    <div className='credit-app-section no-collapse applicant-housing-and-employment'>
      <h3>Housing & Employment</h3>

      <FormDropdown
        id='applicantFinancialDetails.housingStatus'
        label='Housing status'
        value={housingStatus}
        error={errors?.housingStatus}
        touched={touched?.housingStatus}
        disabled={isFormDisabled}
        onChange={(fieldId, val) => onChange(fieldId, val?.id)}
        onBlur={onBlur}
        options={housingStatuses}
      />

      <FormDatePicker
        id='applicantFinancialDetails.apartmentMoveInDate'
        label='Move-in date'
        value={values.apartmentMoveInDate}
        error={errors?.apartmentMoveInDate}
        touched={touched?.apartmentMoveInDate}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
        format={DATE_MONTH_YEAR_FORMAT}
      />

      <FormNumberInput
        id='applicantFinancialDetails.apartmentPayment'
        label='Payment'
        preset='financial'
        mode='integer'
        isNegativeAllowed={false}
        endAdornment='/month'
        value={values.apartmentPayment}
        error={errors?.apartmentPayment}
        touched={touched?.apartmentPayment}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={ValidationLength.MONTHLY_PAYMENT_MAX_LENGTH}
      />

      {isPreviousEmploymentOrHousingNeeded(values.apartmentMoveInDate) && (
        <PreviousHousing
          isFormDisabled={isFormDisabled}
          pathPrefix='applicantFinancialDetails.previousAddressDetails'
          addressData={values.previousAddressDetails?.addressData ?? null}
          addressDataErrors={errors?.previousAddressDetails?.addressData ?? null}
          addressDataTouched={touched?.previousAddressDetails?.addressData ?? null}
          appartmentMoveInDate={values.previousAddressDetails?.apartmentMoveInDate ?? null}
          appartmentMoveInDateErrors={errors?.previousAddressDetails?.apartmentMoveInDate ?? null}
          appartmentMoveInDateTouched={touched?.previousAddressDetails?.apartmentMoveInDate}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}

      <EmploymentDetails
        pathPrefix='applicantFinancialDetails'
        employmentStatuses={employmentStatuses}
        isFormDisabled={isFormDisabled}
        currentEmploymentDetails={values.currentEmploymentDetails}
        previousEmploymentDetails={values.previousEmploymentDetails}
        currentEmploymentDetailsErrors={errors?.currentEmploymentDetails}
        previousEmploymentDetailsErrors={errors?.previousEmploymentDetails}
        currentEmploymentDetailsTouched={touched?.currentEmploymentDetails}
        previousEmploymentDetailsTouched={touched?.previousEmploymentDetails}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  )
}

export default ApplicantHousingAndEmployment
