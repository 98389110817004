import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGUnableToMessage: FC<IconProps> = ({ color = '#212121', width = 24, height = 24, opacity = '0.8' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'>
      <mask
        id='mask0_85349_16038'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='24'
        height='24'
      >
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_85349_16038)'>
        <path
          d='M6 18L3.85 20.15C3.68333 20.3167 3.5 20.3583 3.3 20.275C3.1 20.1917 3 20.0333 3 19.8V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H17C17.55 4 18.0208 4.19583 18.4125 4.5875C18.8042 4.97917 19 5.45 19 6V11.075C18.8333 11.0417 18.6667 11.0208 18.5 11.0125C18.3333 11.0042 18.1667 11 18 11C17.8333 11 17.6667 11.0042 17.5 11.0125C17.3333 11.0208 17.1667 11.0417 17 11.075V6H5V16H12.075C12.0417 16.1667 12.0208 16.3333 12.0125 16.5C12.0042 16.6667 12 16.8333 12 17C12 17.1667 12.0042 17.3333 12.0125 17.5C12.0208 17.6667 12.0417 17.8333 12.075 18H6ZM8 10H14C14.2833 10 14.5208 9.90417 14.7125 9.7125C14.9042 9.52083 15 9.28333 15 9C15 8.71667 14.9042 8.47917 14.7125 8.2875C14.5208 8.09583 14.2833 8 14 8H8C7.71667 8 7.47917 8.09583 7.2875 8.2875C7.09583 8.47917 7 8.71667 7 9C7 9.28333 7.09583 9.52083 7.2875 9.7125C7.47917 9.90417 7.71667 10 8 10ZM8 14H11C11.2833 14 11.5208 13.9042 11.7125 13.7125C11.9042 13.5208 12 13.2833 12 13C12 12.7167 11.9042 12.4792 11.7125 12.2875C11.5208 12.0958 11.2833 12 11 12H8C7.71667 12 7.47917 12.0958 7.2875 12.2875C7.09583 12.4792 7 12.7167 7 13C7 13.2833 7.09583 13.5208 7.2875 13.7125C7.47917 13.9042 7.71667 14 8 14Z'
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d='M16.5494 17L14.7816 15.2322C14.3911 14.8417 14.3911 14.2085 14.7816 13.818C15.1721 13.4275 15.8053 13.4275 16.1958 13.818L17.9636 15.5858L19.7313 13.818C20.1219 13.4275 20.755 13.4275 21.1456 13.818C21.5361 14.2085 21.5361 14.8417 21.1456 15.2322L19.3778 17L21.1456 18.7678C21.5361 19.1583 21.5361 19.7915 21.1456 20.182C20.755 20.5725 20.1219 20.5725 19.7313 20.182L17.9636 18.4142L16.1958 20.182C15.8053 20.5725 15.1721 20.5725 14.7816 20.182C14.3911 19.7915 14.3911 19.1583 14.7816 18.7678L16.5494 17Z'
          fill={color}
          fillOpacity={opacity}
        />
      </g>
    </svg>
  )
}

export default IconSVGUnableToMessage
