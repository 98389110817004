import { css } from '@emotion/css'

export const ROOT_CLASS_NAME = css`
  position: relative;
  margin-left: 0!important;

  .cf-md {
    width: calc(100% - 48px);
    margin: 0px 24px;
    max-width: 100%;
    
    @media screen and (max-width: 1360px) {
      width: 100%;
      margin: 0;
      max-width: 100%;
    }
    
    @media screen and (min-width: 1440px) {
      width: 1300px;
      margin: 0 auto;
    }
  }
  
  .cf-report-top-actions {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .cf-top-wrapper {
    margin-bottom: 24px;
  }
`

export default css`
  .total-panel {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 16px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 24px;
  }
  
  .cf-no-data-block {
    border-top: none;
  }

  .cf-table {
    .cf-table-header-row {
      border: none;
      background: #F3F3F3;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    .cf-table-row-hover:hover>td:first-child>div {
      border-left: none;
      padding-left: 16px;
    }
    
    .right-alignment {
      text-align: right;
    }
    
    .MuiTableBody-root {
      .cf-table-row {
        border: none;
      }

      .cf-table-group-row {
        height: 31px;
        box-sizing: border-box;
        border: none;
        
        .cf-divider-left, .cf-divider-right {
          display: none;
        }

        .cf-table-group-cell {
          height: 32px;
          box-sizing: border-box;
        }
      }
    }
  }
`
