import { css } from '@emotion/css'

export default css(`
  &.campaign-form {
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
  }

  .notes {
    color: rgba(33, 33, 33, 0.50);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 16px;
  }

  .current-recipients-title {
    display: flex;
    gap: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-bottom: 16px;
    color: #101010;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    justify-content: space-between;
    margin-bottom: 24px;

    h4 {
      font-weight: 500;
    }

    .recipients-counter {
      align-items: center;
      display: flex;
      gap: 8px;

      span {
        font-weight: 500;
      }

      .loader {
        .cf-animated-wrapper {
          color: #000;
        }
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;

    .inputs-wrapper, .letter-preview {
      flex: 1;
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .filter-row {
        &:last-child {
          margin-bottom: 24px;
        }
      }
    }

    .filter-row {
      display: flex;
      gap: 16px;
      align-items: center;

      .cf-form-field {
        width: 656px;

        &:first-child {
          &.lead-with-scheduled-appointments {
            width: 100%;
          }
          width: 160px;
        }
      }

      .btn-remove {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.25px;
        text-decoration-line: underline;
        
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .button-container {
    display: flex;

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    .filter-button {
      background: none;
      display: flex;
      align-items: center;
      gap: 4px;
      color: #000;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.4px;
      padding: 0;

      span {
        font-weight: 500;
      }

      .plus {
        position: relative;
        width: 24px;
        height: 24px;

        span {
          position: absolute;
          width: 14px;
          height: 2px;
          background-color: #000;
          top: 50%;
          transform: translate(0, -50%);
          left: 0;
          right: 0;
          margin: 0 auto;

          &:last-child {
            transform: translate(0, -50%) rotate(90deg);
          }
        }
      }
    }
  }
`)
