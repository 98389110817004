import { css } from '@emotion/css'

export default css(`
  display: flex;

  .cf-button-root {
    padding: 0;
    height: 26px;
    max-width: 124px;
    min-width: 124px;
    border-radius: 8px;
    align-self: flex-end;

    .cf-button-variant-outlined { background-color: inherit; }

    .cf-button-content {
      font-weight: 500;
      text-wrap: nowrap;
      padding: 5px 18px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
    }
  }
`)
