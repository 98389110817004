import type { ReactNode } from 'react'
import { cnx, Loader } from '@carfluent/common'
import { LoaderProps } from '@carfluent/common/dist/UI'

import CLASS_NAME from './styles'

interface TabItemProps<T> {
  children: ReactNode[] | ReactNode
  id: T
  isLoading?: boolean
  isSelected?: boolean
  onClick: (id: T) => void
  counter?: number
  endAdornment?: ReactNode
  className?: string
  isDisabled?: boolean
  loaderColor?: LoaderProps['color']
}

const TabItem = <T = string | number>({
  children,
  counter,
  id,
  isLoading = false,
  isSelected = false,
  onClick: _onClick,
  endAdornment = null,
  className = '',
  isDisabled = false,
  loaderColor = 'dark'
}: TabItemProps<T>): JSX.Element => {
  return (
    <div
      className={cnx(
        CLASS_NAME,
        'tab',
        isSelected && 'tab-selected',
        isLoading && 'tab-loading',
        className,
        isDisabled && 'tab-disabled'
      )}
      role='tab'
      aria-selected={isSelected}
      tabIndex={isSelected ? 0 : -1}
      onClick={(): void => _onClick(id)}
    >
      {children}
      {endAdornment != null && endAdornment}
      {isLoading && <Loader size='small' color={isSelected ? 'light' : loaderColor} />}
      {!isLoading && (counter != null) && <span className='counter'>{counter}</span>}
    </div>
  )
}

export default TabItem
