import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { type Result, useForm, useLoader, isOk, getEnhancedResultHandler, isFail } from '@carfluent/common'

import { type DealModel } from 'api/types/responses'
import CoverageApiProvider from 'api/coverage.api'
import CustomersCoreApiProvider from 'api/customersCore.api'
import { generateCoverageSectionsData } from 'api/defaults/coverageDefault'

import { PaymentTypeId } from 'api/types'
import parseError from 'utils/parseErrors'
import useAsyncEffect from 'hooks/useAsyncEffect'
import { COVERAGE_ORDER } from 'constants/coverage'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import type { CoverageDefault, CoverageDefaultSection, CoverageProducts } from 'types/coverage'
import { isDeal2Completed as _isDealCompleted, isDealCanceled } from 'utils/deals/isDealCompleted'

import { DEPENDENT_VALIDATIONS, validationRules } from './validator'
import serializeCoverageDetails, { serializeDefaults } from './serializer'
import { type UseWarrantyProps, type UseWarrantyReturn, ErrTouchShortcuts } from './types'

const DEFAULT_FORM_DATA = Array(5).fill(null).map(generateCoverageSectionsData)

export enum Notifications {
  ValidationError = 'Please check your inputs.',
  SaveSuccess = 'Changes successfully saved.'
}

const useWarranty = ({
  deal,
  isOpen,
  onClose: _onClose,
  redirectToCoverage,
  onUpdateDeal
}: UseWarrantyProps): UseWarrantyReturn => {
  const warrantiesLoader = useLoader()
  const { showAlert } = useCustomSnackbar()
  const isCashPayment = deal?.dealFinanceTypeId === PaymentTypeId.Cash
  const [addedProducts, setAddedProducts] = useState<number[]>([])
  const [penPackages, setPenPackages] = useState<CoverageProducts>({})
  const isDisplayPressedRef = useRef(false)

  const onAction = useCallback(async (res: Result<DealModel>): Promise<void> => {
    if (isOk<DealModel>(res)) {
      if (isDisplayPressedRef.current) {
        await redirectToCoverage()
      } else {
        onClose()
      }

      const response = res.result
      onUpdateDeal(response)
    } else if (isFail(res)) {
      const response = (res.result as any)?.response
      parseError(response?.data ?? null)
    }
    isDisplayPressedRef.current = false
    warrantiesLoader.stopLoader()
  }, [warrantiesLoader.stopLoader, redirectToCoverage])

  const onActionResult = useMemo(() => {
    const action = (res: Result<DealModel>): void => {
      void onAction(res)
    }
    return getEnhancedResultHandler<CoverageDefaultSection[], ErrTouchShortcuts, DealModel>(action, showAlert, undefined, Notifications.SaveSuccess)
  }, [onAction, showAlert])

  const onProductAdd = (productId: number): void => {
    setAddedProducts((prevProps) => {
      if (productId === 0) return prevProps
      const copiedProps = [...prevProps]

      if (copiedProps.includes(productId)) {
        return copiedProps.filter(el => el !== productId)
      }

      return [...copiedProps, productId]
    })
  }

  const submitAction = useCallback(async (values: CoverageDefaultSection[]): Promise<DealModel> => {
    warrantiesLoader.startLoader()
    const payloadDefault = serializeDefaults(values)

    const dealDefaults = await CoverageApiProvider.updateProductsDealDefaults({
      data: { coverageDefaults: payloadDefault },
      dealId: deal?.id ?? 0
    })
    const payload = serializeCoverageDetails(addedProducts, deal?.id ?? '', deal?.rowVersion ?? '', dealDefaults.items)

    const coverageRes = await CustomersCoreApiProvider.updateCoverage(payload)
    return coverageRes
  }, [deal?.id, deal?.rowVersion, addedProducts])

  const form = useForm<CoverageDefaultSection[], ErrTouchShortcuts, never>({
    onActionResult,
    submitAction,
    baseValues: DEFAULT_FORM_DATA,
    validationRules,
    dependentValidations: DEPENDENT_VALIDATIONS
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const { setValues, resetForm, onSubmit, setFieldTouched } = form

  const loadDealerProducts = useCallback(async (dealId: number | string): Promise<void> => {
    const {
      penProducts,
      coverageDetails
    } = await CoverageApiProvider.getDealCoverages(dealId, isCashPayment)

    setValues(coverageDetails)
    setPenPackages(penProducts)
  }, [setValues, isCashPayment])

  const onDisplayFI = useCallback(async () => {
    isDisplayPressedRef.current = true
    await onSubmit()
  }, [onSubmit])

  const onClose = useCallback(() => {
    _onClose()
    setTimeout(() => {
      resetForm(DEFAULT_FORM_DATA)
      setAddedProducts([])
    }, 0)
  }, [_onClose, resetForm])

  const onChangeManualVendor = useCallback((field: string, value: CoverageDefault) => {
    form.onChange(field, value)
    if (value.providerId === null) {
      const keys = Object.keys(value)
      keys.forEach(fieldId => {
        setFieldTouched(`${field}.${fieldId}`, false)
      })
    }
  }, [form.onChange])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useAsyncEffect(async () => {
    if (deal?.id != null && isOpen) {
      warrantiesLoader.startLoader()
      await loadDealerProducts(deal?.id)
    }

    warrantiesLoader.stopLoader()
  }, [isOpen, warrantiesLoader.startLoader, warrantiesLoader.stopLoader])

  useEffect(() => {
    setAddedProducts(
      deal?.coverageDetails
        ?.filter(el => el.dealCoverageDefaultId !== null && el.productType != null)
        .map(el => COVERAGE_ORDER[el.productType as string]) ?? []
    )
  }, [deal])

  const isDealComplete = _isDealCompleted(deal)
  const isDealCancel = isDealCanceled(deal)

  // ========================================== //

  return {
    ...form,
    isLoading: warrantiesLoader.isLoading,
    onProductAdd,
    addedProducts,
    penPackages,
    onClose,
    isCashPayment,
    onDisplayFI,
    isDealComplete,
    isDealCancel,
    onChangeManualVendor
  }
}

export default useWarranty
