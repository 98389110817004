import type { FC } from 'react'

export interface DeleteIconType {
  color?: string
  width?: number
  height?: number
}

export const DeleteIcon: FC<DeleteIconType> = ({ color = '#000000', width = 12, height = 12 }) => (
  <svg
    fill='none'
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color}
      d='M11.25 0.758309C10.925 0.433309 10.4 0.433309 10.075 0.758309L6.00001 4.82498L1.92501 0.749976C1.60001 0.424976 1.07501 0.424976 0.750006 0.749976C0.425006 1.07498 0.425006 1.59998 0.750006 1.92498L4.82501 5.99998L0.750006 10.075C0.425006 10.4 0.425006 10.925 0.750006 11.25C1.07501 11.575 1.60001 11.575 1.92501 11.25L6.00001 7.17497L10.075 11.25C10.4 11.575 10.925 11.575 11.25 11.25C11.575 10.925 11.575 10.4 11.25 10.075L7.17501 5.99998L11.25 1.92498C11.5667 1.60831 11.5667 1.07498 11.25 0.758309Z'
    />
  </svg>
)

export default DeleteIcon
