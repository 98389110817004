import { css } from '@emotion/css'

export const TOOLTIP_CLASS_NAME = css`
  width: 600px;
  
  > div {
    max-width: 100%;
  }
`

export const POPOVER_CLASS_NAME = css`
  .cf-dropdown-option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.multiselect {
    .render-option {
      display: flex;
      justify-content: space-between;

      .option-content {
        flex-direction: column;
        width: calc(100% - 32px);
      }

      .display-value {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      p {
        color: #101010;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
      }

      span {
        color: rgba(33, 33, 33, 0.50);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
      }
    }
  }
`

export const SKELETON_CLASS_NAME = css`
  .skeleton-title {
    max-width: 320px;
    margin-bottom: 16px;
  }

  .skeleton-field {
    max-width: 404px;
    min-width: 30%;
    height: 56px;
    margin-bottom: 16px;

    &.small {
      max-width: 196px;
      min-width: 196px;
    }

    span {
      height: 100%;
    }
  }
  
  .skeleton-checkbox-container {
    display: flex;
    margin-bottom: 16px;

    .check {
      width: 16px;
      height: 16px;
      margin-right: 16px;

      span {
        height: 100%;
      }
    }
    
    .label {
      width: 120px;
      height: 16px;

      span {
        height: 100%;
      }
    }
  }

  .skeleton-subtitle {
    max-width: 160px;
    margin-bottom: 16px;
  }
  
  .skeleton-text {
    max-width: 700px;
    height: 24px;
    margin-bottom: 16px;

    span {
      height: 100%;
    }
  }

  .skeleton-field-container {
    margin-bottom: 16px;

    .container {
      display: flex;
      gap: 16px;
    }
  }

  .checkboxes {
    display: flex;
    gap: 16px;

    .column {
      min-width: 404px;
    }
  }
`

export default css`
  padding: 16px 24px 80px;
  --footer-height: 80px;
  
  .recipients-wrapper {
    position: relative;
    overflow: hidden;
  }

  &.disabled {
    .cf-tooltip-container, .sub-title, .checkbox-title {
      opacity: 0.5;
    }
  }
  
  .hidden {
    display: none!important;
  }
  
  .form-block {
    margin-bottom: 32px;
  }

  .toggle-with-edit {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;

    .toggle {
      margin: 0;
    }
  }

  .toggle-subtitle {
    margin: 16px 0px 16px;
    color: #21212180;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
  
  .edit-button {
    &.cf-button-root.cf-button-content-sized {
      background-color: transparent;
      padding: 4px 5px;
      min-width: unset;
      height: unset;
      border: 1px solid #C99B86;
      border-radius: 8px;

      .cf-button-content {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1.25px;
      }
    }
  }
  
  .checkbox-wrapper {
    display: grid;
    row-gap: 16px;
  }
  
  .sub-title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    
    &.with-top {
      margin-top: 24px;
    }
  }
  
  .toggle {
    margin: 0 0 16px 0;
    
    &.with-top {
      margin-top: 24px;
    }
    
    &.with-tooltip {
      p {
        display: flex;
      }
    }
    
    &.inactive {
      label {
        display: none;
      }
    }

    input {
      :checked + span {
        background: #219653;
        border: 1px solid rgba(0, 0, 0, 0.12);

        :before {
          transform: translate(15px, 1px);
        }
      }
    }
  
    span {
      background: rgba(0, 0, 0, 0.3);
      border: 1px solid rgba(0, 0, 0, 0.12);
  
      :before {
        transform: translate(-1px, 1px);
      }
    }
    
    &.subtitle {
      p {
        font-size: 16px
      }
    }
  
    p {
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.15px;
      color: #101010;

      &.inactive {
        color: #101010;
      }
    }
  }
  
  .checkbox-title {
    color: rgba(33, 33, 33, 0.50);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  
  .input-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px
  }
  
  .input-wrapper-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px
  }

  .checkbox-list {
    width: 808px;
    display: grid;
    grid-template-columns: 400px 400px;
    row-gap: 16px;
    column-gap: 16px;

    .cf-checkbox-icon {
      padding: 0 8px;
    }
  }

  .tab-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: var(--footer-height);
    box-shadow: 0px -2px 12px 0px #0000001F;
    background-color: #fff;
    
    position: fixed;
    z-index: 1102;
    left: 0px;
    right: 0px;
    bottom: calc(var(--footer-height) * -1);
    padding-right: 64px;
    transition: bottom 0.3s ease;

    &.is-shown {
      bottom: 0px;
    }

    button {
      margin: 0px;

      &:first-child {
        margin-right: 24px;
      }
    }
  }
`
