import { css } from '@emotion/css'
import { px, Responsive, ScreenWidthBreakpoints as SWB } from 'constants/constants'

export default css(`
  --maxTotalDownpaymentWidth: 152px;
  
  .block-skeleton.is-loading {
    &.summary-block {
      height: 168px;
      
     .text-skeleton { height: 100%; }
    }
    
    &.bill-of-sale {
      height: 296px;
    }
  }
  
  .summary-block:not(.is-loading) {
    border-radius: 12px;
    background: rgba(236, 244, 255, 0.44);
    
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 100%;
    
    .section-title {
      display: flex;
      padding: 16px;
      padding-bottom: 0px;
      justify-content: space-between;
    }

    .download-action {
      cursor: pointer;
      position: relative;
      width: 24px;
      height: 24px;

      &.disabled {
        cursor: default;
        &:hover {
          &:after {
            opacity: 0;
          }
        }
      }

      svg {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
      }

      &:after {
        content: '';
        opacity: 0;
        width: 36px;
        height: 36px;
        position: absolute;
        background-color: #E2EEFF;
        top: -6px;
        left: -6px;
        border-radius: 50%;
        transition: all .6s;
      }

      &:hover {
        &:after {
          opacity: 1;
          transition: all .6s;
        }
      }
    }
    
    .fields {
      padding: 0px 16px;
      gap: 8px;
      
      &.with-padding-bottom {
        padding-bottom: 16px;
      }
    
      &.financing {
        padding: 16px;
        border-radius: 12px;
        background: #ECF4FF;
      }
    }
    
    .labeled-field {
      display: flex;
      gap: 0px;
      justify-content: space-between;
      max-width: 100%;
      
      &.bold label, &.bold div { font-weight: 500; }
      label, div { font-weight: 400; }
      
      &.monthly-payment,
      &.total-downpayment {
        @media screen and (max-width: ${px(Responsive.Laptop)}) {
          align-items: flex-end;
          
          label {
            max-width: 100px;
            min-width: 0px;
          }
        }
      }
      
      label {
        cursor: default;
        max-width: var(--maxTotalDownpaymentWidth);
        min-width: fit-content;
      }
      
      .big-number {
        font-size: 20px;
        line-height: 24px;
      }
      
      .total-down-payment {
        max-width: calc(100% - var(--maxTotalDownpaymentWidth));
      }

      div {
        max-width: 139px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    
    .bold .cf-button-content {
      font-weight: 500;
    }
  }
  
  .notes {
    height: 184px;
    box-shadow: none;
  }
`)
