// AZ-TODO-REMOVE: replace imports from this legacy module by direct imports from common lib
import { utils } from '@carfluent/common'

export const {
  formatInteger,
  formatPercentages
} = utils.formatters

export const onlyNumbersAndDot = (x: string | null): string | null =>
  x == null ? null : x.replace(/[^0-9.]/g, '')

export const onlyNumbers = (x: string): string => x?.replace(/\D/g, '') ?? ''

export const isCyrillic = (x: string): boolean => (/([\u0400-\u04FF]+)/gm).test(x)

export interface FormatterConfig {
  allowNegative?: boolean
  allowZero?: boolean
  emptyValues?: string | Partial<EmptyValuesConfig>
  mantissa?: number
  postfix?: string | Partial<Postfixes>
  prefix?: string | Partial<Prefixes>
}

export interface EmptyValuesConfig {
  emptyString: string
  negativeValue: string
  nullValue: string
  zeroValue: string
}

export interface Prefixes {
  negative: string
  positive: string
}

export type Postfixes = Prefixes

export const DEFAULT_EMPTY_VALUES: EmptyValuesConfig = {
  emptyString: '',
  negativeValue: '',
  nullValue: '',
  zeroValue: ''
}

export const DEFAULT_PREFIXES: Prefixes = { negative: '', positive: '' }
export const DEFAULT_POSTFIXES: Postfixes = { negative: '', positive: '' }

export const DEFAULT_FORMATTER_CONFIG: Required<FormatterConfig> = {
  allowNegative: true,
  allowZero: true,
  emptyValues: DEFAULT_EMPTY_VALUES,
  mantissa: 2,
  postfix: DEFAULT_POSTFIXES,
  prefix: DEFAULT_PREFIXES
}

const DEFAULT_LOCALE = 'en-US'

export const formatNumber2 = (
  x: number | string | null | undefined,
  config?: FormatterConfig
): string => {
  const {
    allowNegative = DEFAULT_FORMATTER_CONFIG.allowNegative,
    allowZero = DEFAULT_FORMATTER_CONFIG.allowZero,
    emptyValues: _emptyValues,
    mantissa = DEFAULT_FORMATTER_CONFIG.mantissa,
    postfix: _postfix,
    prefix: _prefix
  } = config ?? {}

  const emptyValues = (typeof _emptyValues === 'string')
    ? {
        emptyString: _emptyValues,
        negativeValue: _emptyValues,
        nullValue: _emptyValues,
        zeroValue: _emptyValues
      }
    : { ...DEFAULT_EMPTY_VALUES, ..._emptyValues }

  const prefix = (typeof _prefix === 'string')
    ? { negative: _prefix, positive: _prefix }
    : { ...DEFAULT_PREFIXES, ..._prefix }

  const postfix = (typeof _postfix === 'string')
    ? { negative: _postfix, positive: _postfix }
    : { ...DEFAULT_POSTFIXES, ..._postfix }

  if (x == null) {
    return emptyValues.nullValue
  }

  if (x === '') {
    return emptyValues.emptyString
  }

  const num = Number(x)
  const isZero = num === 0
  const isNegative = num < 0

  if (isZero && !allowZero) {
    return emptyValues.zeroValue
  }

  if (isNegative && !allowNegative) {
    return emptyValues.negativeValue
  }

  const numberFormatterConfig = {
    minimumFractionDigits: mantissa,
    maximumFractionDigits: mantissa
  }

  const postfixStr = isNegative ? postfix.negative : postfix.positive
  const prefixStr = isNegative ? prefix.negative : prefix.positive
  const formattedValue = new Intl.NumberFormat(DEFAULT_LOCALE, numberFormatterConfig).format(Math.abs(num))

  return `${prefixStr}${formattedValue}${postfixStr}`
}

/**
 * Used in reports
 */
export const formatCurrencyDecimal = (
  x: number | string | null | undefined
): string => {
  return formatNumber2(x, {
    prefix: { positive: '$', negative: '($' },
    postfix: { positive: '', negative: ')' }
  })
}

export const formatIntegerDecimal = (
  value: number | string | null,
  emptyValue: string | number = '0.00'
): string | number => {
  const isEmptyValue = value == null || (typeof value === 'number' && isNaN(value))

  if (isEmptyValue) {
    return emptyValue
  }

  const [int, decimal] = value.toString().split('.')

  if (decimal != null && decimal !== '') {
    return `${formatInteger(int)}.${decimal}`
  }

  return formatInteger(int)
}
