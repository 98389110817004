import { css } from '@emotion/css'

export default css(`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  button.cf-button-root {
    margin-left: 16px;
  }
  
  > p {
    text-transform: uppercase;
    color: rgba(33, 33, 33, 0.50);
  }
  
  .print-button {
    .cf-button-content {
      display: flex;
      
      .cf-loader {
        margin-left: 8px;
      }
    }
  }
`)

export const createTooltipCls = (isHidden: boolean): string => css(`
  display: ${isHidden ? 'none' : 'block'};
  z-index: 1500!important;
  
  .MuiTooltip-tooltip {
    max-width: none;
  }
`)
