import type { FC } from 'react'

export enum RowVariants {
  Title = 'title',
  Subtitle = 'subtitle',
  Info = 'info',
  Total = 'total',
  Empty = 'empty'
}

export interface RowProps {
  variant: RowVariants
  text?: string
  value?: string
  className?: string
  name?: string
  key?: string
}

const Row: FC<RowProps> = ({
  variant,
  text = '',
  value = '',
  className = ''
}) => {
  return (
    <tr className={className}>
      {(variant !== RowVariants.Empty) && (
        <>
          <td className={`cf-row-text-${variant}`}>{text}</td>
          <td className={`cf-row-value-${variant}`}>{value}</td>
        </>
      )}
    </tr>
  )
}

export default Row
