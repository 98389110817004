import { useCallback, useMemo, useState } from 'react'
import { useModal } from '@carfluent/common'
import { type Row } from '@tanstack/react-table'

import AccountingApiProvider from 'api/accounting.api'
import { TransactionActionNames } from 'constants/constants'
import { type ListPayload, type PaginatedResult, TransactionTypeId } from 'api/types'
import useTableApi from 'hooks/useTableApi'
import useScrolledToBottom from 'hooks/useScrolledToBottom'
import useEffectOnce from 'hooks/useEffectOnce'
import { eventToNull } from 'utils/general'
import replaceAllById from 'utils/common/replaceAllById'

import { DEFAULT_FILTERS, DEFAULT_SORTING, Messages } from './constants'
import columnDefinitions from './columns'
import parseListData from './parser'

import {
  type ButtonClickEvent,
  type RecurringEntriesListFilters,
  type RecurringEntryFromApi,
  type RecurringEntryRowData,
  type RowId,
  type UseRecurringEntriesListReturn
} from './types'

const useRecurringEntriesList = (): UseRecurringEntriesListReturn => {
  const transactionDetailsModal = useModal()
  const [selectedTransactionTypeId, setTransactionTypeId] = useState<number>(TransactionTypeId.JournalEntry)
  const [transactionId, setTransactionId] = useState<RowId | null>(null)

  const getRecurringEntriesList = useCallback(async (payload: ListPayload): Promise<PaginatedResult<RecurringEntryFromApi>> => {
    const [tableData, recurringInterval, transactionType] = await Promise.all([
      AccountingApiProvider.getRecurringEntries(payload),
      AccountingApiProvider.getRecurringIntervals(),
      AccountingApiProvider.getTransactionTypes()
    ])

    return {
      count: tableData.count,
      items: replaceAllById<RecurringEntryFromApi>(tableData.items, [
        { dictionaryItems: transactionType.items, foreignKey: 'transactionTypeFilterId' },
        { dictionaryItems: recurringInterval.items, foreignKey: 'recurringIntervalId' }
      ])
    }
  }, [])

  const {
    emptyTableMessage,
    rows,
    search,
    sorting,
    isLoading,
    loadRows,
    setFilters,
    setSorting,
    setSearch,
    onSearch
  } = useTableApi<RecurringEntryFromApi, RecurringEntriesListFilters, RecurringEntryRowData>({
    defaultFilters: DEFAULT_FILTERS,
    defaultSorting: DEFAULT_SORTING,
    emptyTableMessage: Messages.emptyTableState,
    getList: getRecurringEntriesList,
    nothingFoundMessage: Messages.nothingFoundState,
    parseListData
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onOpenTransactionDetails = useCallback((
    transactionTypeId: number,
    transactionId: number | null = null
  ) => {
    setTransactionTypeId(transactionTypeId)
    setTransactionId(transactionId)
    transactionDetailsModal.onOpenModal()
  }, [transactionDetailsModal.onOpenModal])

  const onCloseTransactionDetails = useCallback((): void => {
    transactionDetailsModal.onCloseModal()
    setTransactionTypeId(TransactionTypeId.JournalEntry)
    setTransactionId(null)
  }, [transactionDetailsModal.onCloseModal])

  const onClickRecurringEntryRow = useCallback(eventToNull<Row<RecurringEntryRowData>, ButtonClickEvent>((row) => {
    const rowId = row?.original.id
    const transactionTypeId = Number(row?.original.transactionTypeFilterId?.id)

    if ((rowId != null) && (transactionTypeId != null)) {
      onOpenTransactionDetails(transactionTypeId, rowId)
    }
  }), [onOpenTransactionDetails])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffectOnce(() => {
    void loadRows({ forceRefresh: true })
  }, [loadRows])

  /**
   * Cheap'n'dirty lazy loading.
   */
  useScrolledToBottom(() => {
    if (!isLoading) {
      void loadRows()
    }
  }, [loadRows, isLoading])

  // ========================================== //

  const topPanelActions = useMemo(() => {
    return [
      {
        title: TransactionActionNames.JournalEntry,
        handleOnClick: () => onOpenTransactionDetails(TransactionTypeId.JournalEntry)
      },
      {
        title: TransactionActionNames.Payable,
        handleOnClick: () => onOpenTransactionDetails(TransactionTypeId.Payable)
      }
    ]
  }, [onOpenTransactionDetails])

  return {
    columns: columnDefinitions,
    emptyTableMessage,
    isLoading,
    onClickRecurringEntryRow,
    onFiltersChange: setFilters,
    onSearchChange: setSearch,
    onSortingChange: setSorting,
    rows,
    search,
    sorting,
    topPanelActions,
    transactionDialogControllerProps: {
      costsProps: null,
      costTypeId: null,
      transactionTypeId: selectedTransactionTypeId,
      transactionProps: {
        ...transactionDetailsModal,
        onCloseModal: onCloseTransactionDetails,
        onSubmit: () => { void loadRows() },
        transactionId,
        transactionCreationMode: 'recurring'
      }
    },
    onSearch
  }
}

export default useRecurringEntriesList
