import { css } from '@emotion/css'

export default css(`
  text-align: center;

  .cf-title {
    font-family: Roboto, sans-serif;
    font-size: 34px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: center;
    color: #101010;
  }
  
  .cf-text {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
    color: #101010;
    margin-top: 24px;
  }
  
  .cf-btn.cf-button-root {
    width:100%;
    margin-top: 48px;

    .cf-button-content {
      font-family:  Roboto, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-align: center;
      text-transform: uppercase;
    }
  }
`)

export const MODAL_CLASS_NAME = css(`
  .cf-modal-content {
    border-radius: 12px;
  }
`)
