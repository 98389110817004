import { css } from '@emotion/css'

export default css(`
  width: 100%;
  display: flex;
  padding-left: 32px;
  margin-bottom: 8px;
  background: #FAFAFA;
  border-radius: 12px 12px 2px 2px;

  .header-label {
    color: #101010;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;

    :first-of-type {
      padding: 12px 4px;
      width: calc(100% - 112px)
    }

    :last-of-type {
      padding: 12px 16px;
      width: 112px
    }
  }
`)
