import { serializers } from '@carfluent/common'

import type { NewLeadApi } from 'api/types'
import type { UpdateLeadFormData } from './types'

const { serializeDate } = serializers

const getStringOrNull = (value: string | null): string | null => value?.trim() === '' ? null : value

const serializeData = ({
  addressData,
  birthDate,
  firstName,
  lastName,
  phoneNumber,
  email,
  leadSource,
  leadTemperature,
  assignedUser,
  ...otherProps
}: UpdateLeadFormData): NewLeadApi => {
  return {
    address: addressData?.address ?? null,
    city: addressData?.city ?? null,
    state: addressData?.state ?? null,
    zipCode: addressData?.zipCode ?? null,
    birthDate: serializeDate(birthDate),
    firstName: getStringOrNull(firstName),
    lastName: getStringOrNull(lastName),
    phoneNumber: getStringOrNull(phoneNumber),
    email: getStringOrNull(email),
    assignedUserId: (assignedUser?.id === 0 || assignedUser?.id == null) ? null : assignedUser.id,
    leadSourceId: leadSource?.id ?? null,
    leadTemperatureId: leadTemperature?.id ?? null,
    ...otherProps
  }
}

export default serializeData
