import { useCallback, useState, type FC } from 'react'
import type { UseModalReturn } from '@carfluent/common'
import { cn } from '@carfluent/common'
import { Skeleton } from '@material-ui/lab'
import range from 'lodash-es/range'

import type { AccountingUpdateLockStateForMonthPayload, AccountingFiscalYearsResponse, FiscalYearMonths } from 'api/types'
import ConfirmDialog from 'components/dialogs/ConfirmDialog'
import IconSVG from 'components/inlineImages'
import { convertDateToUTCString } from 'components/common/EmailThreadModal/hook/utils'

import CLASS_NAME from './styles'

const MONTH_SEPTEMBER = 9

export interface ListOfBalanceProps<T> {
  activeItem: T
  isLoading?: boolean | null
  items: AccountingFiscalYearsResponse[]
  onCloseYear: (yearId: number, isClosed: boolean) => void
  onUpdateLockStatus: ({
    yearId, monthId, isLocked
  }: AccountingUpdateLockStateForMonthPayload) => void
  onChangeActiveItem: (item: T) => void
  confirmModal: UseModalReturn
}

const VISIBLE_ELEMENTS_NUM = 12

const ListOfItem: FC <ListOfBalanceProps<AccountingFiscalYearsResponse>> = ({
  activeItem,
  isLoading = true,
  items,
  confirmModal,
  onChangeActiveItem,
  onCloseYear,
  onUpdateLockStatus
}) => {
  const { isModalOpen, onCloseModal, onOpenModal } = confirmModal
  const [selectedMonth, setSelectedMonth] = useState<FiscalYearMonths | null>(null)

  const activeItemId = activeItem?.id
  const lockedAllMonth = activeItem?.fiscalYearMonths.every((item) => item.isLocked) ?? false
  const confirmDialogTitle = activeItem?.isClosed ? 'Unlock month from closed year?' : `Close ${activeItem?.year} fiscal year?`
  const saveButtonName = activeItem?.isClosed ? 'UNLOCK' : 'CLOSE'

  const onUpdateLockState = useCallback((item: FiscalYearMonths) => {
    if (activeItem?.isClosed) {
      setSelectedMonth(item)
      onOpenModal()
    } else {
      onUpdateLockStatus({ yearId: activeItemId, monthId: item.id, isLocked: !item.isLocked })
    }
  }, [activeItem?.isClosed, activeItemId, onOpenModal, onUpdateLockStatus])

  const onSuccessAction = useCallback(() => {
    if (activeItem?.isClosed && selectedMonth != null) {
      onUpdateLockStatus({ yearId: activeItemId, monthId: selectedMonth?.id, isLocked: !selectedMonth?.isLocked })
    } else {
      onCloseYear(activeItemId, !activeItem?.isClosed)
    }
    setSelectedMonth(null)
  }, [activeItem?.isClosed, activeItemId, onCloseYear, onUpdateLockStatus, selectedMonth])

  return (
    <div className={CLASS_NAME}>
      <div className='cf-list-of-items'>
        <div className='list-title'>Year</div>
        <div className='list'>
          {(isLoading != null && isLoading) && (range(0, VISIBLE_ELEMENTS_NUM)).map((_, idx) => {
            return (
              <div key={idx} className='item cf-skeleton-item'>
                <Skeleton className='cf-skeleton-left-part' variant='text' />
                <Skeleton className='cf-skeleton-right-part' variant='text' />
              </div>
            )
          })}

          {(isLoading != null && !isLoading) && (items ?? []).map((item) => {
            return (
              <div
                key={item.id}
                className={cn('item', (activeItemId === item.id) && 'active', item.isClosed && 'closed')}
                onClick={() => onChangeActiveItem(item)}
              >
                <div className={cn('item-info-container year', item.isClosed && 'closed')}>
                  <div className='item-name'>FY{item.year}</div>
                  <div className='item-closed-time'>{item.closedDateTime != null && convertDateToUTCString(item.closedDateTime)}</div>
                </div>

                <div className='item-action-open'>{item.isClosed ? 'Closed' : 'Open'}</div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='cf-list-of-items'>
        <div className='list-title'>Month</div>
        <div className='list'>
          {(isLoading != null && isLoading) && (range(0, VISIBLE_ELEMENTS_NUM)).map((_, idx) => {
            return (
              <div key={idx} className='item cf-skeleton-item'>
                <Skeleton className='cf-skeleton-left-part' variant='text' />
                <Skeleton className='cf-skeleton-right-part' variant='text' />
              </div>
            )
          })}
          {(isLoading != null && !isLoading) && (
            <>
              {(activeItem?.fiscalYearMonths ?? []).map((item) => {
                return (
                  <div
                    key={item.id}
                    className={cn('item', item.isLocked && 'closed')}
                  >
                    <div className={cn('item-info-container', item.isLocked && 'closed')}>
                      <div className='item-name'>
                        {item.isLocked ? <IconSVG.Lock color='#212121' /> : <IconSVG.Unlock />}
                        {item.month}
                      </div>
                      <div className='item-closed-time'>{item.lockedDateTime != null && convertDateToUTCString(item.lockedDateTime)}</div>
                    </div>
                    <div
                      className='item-action-lock'
                      onClick={() => onUpdateLockState(item)}
                    >{item.isLocked ? 'Unlock' : 'Lock'}
                    </div>
                  </div>
                )
              })}
              <div
                className={cn('cf-close-year-btn', (lockedAllMonth && !activeItem?.isClosed) && 'active')}
                onClick={onOpenModal}
              >
                Close year
              </div>
            </>
          )}
        </div>
      </div>
      <ConfirmDialog
        isModalOpen={isModalOpen}
        onCloseModal={() => {
          onCloseModal()
          setSelectedMonth(null)
        }}
        checkboxLabelName='I confirm and agree with this action.'
        onSuccess={onSuccessAction}
        onOpenModal={onOpenModal}
        title={confirmDialogTitle}
        saveButtonName={saveButtonName}
      />
    </div>
  )
}

export default ListOfItem
