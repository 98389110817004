import type { FC } from 'react'
import { useMemo } from 'react'
import { createPortal } from 'react-dom'
import { cn } from '@carfluent/common'

import { usePopover } from './hook'
import type { UsePopoverProps } from './hook'
import { useAnimatedToggle } from './hook/useAnimatedToggler'
import { createPopoverStyles, ANIMATED_POPOVER_STYLE } from './styles'

export interface PopoverProps extends UsePopoverProps {
  className?: string
}

export interface PopoverHOCProps extends Omit<PopoverProps, 'anchorEl'> {
  anchorEl?: HTMLElement | null
  open: boolean
}

const PopoverHOC = (Popover: FC<PopoverProps>): FC<PopoverHOCProps> => ({
  open,
  className,
  ...otherProps
}) => {
  const { isOpen, isAnimatedOpen } = useAnimatedToggle(open)

  return isOpen
    ? (
      <Popover
        {...otherProps}
        className={cn(
          ANIMATED_POPOVER_STYLE,
          isAnimatedOpen ? 'cf-animated-open' : '',
          className
        )}
      />
      )
    : null
}

const Popover: FC<PopoverProps> = ({
  children,
  className,
  ...otherProps
}) => {
  const { popover, config } = usePopover(otherProps)

  const popoverCls = useMemo(() => {
    return createPopoverStyles(config)
  }, [config])

  popover.className = cn(popoverCls, className)

  return createPortal(children, popover)
}

export default PopoverHOC(Popover)
