import { css } from '@emotion/css'

export default css(`
  display: flex;
  margin-bottom: 24px;

  .line {
    &-item {
      display: flex;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      margin-right: 4px;

      span {
        font-weight: 500;
        color: #101010;
        padding-right: 4px;
      }

      p {
        color: rgba(33, 33, 33, 0.80);
      }
    }
  }
`)
