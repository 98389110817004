import { type Preset, UI, dates, PRESET_LABELS, DEFAULT_MIN_DATE, DEFAULT_MAX_DATE } from '@carfluent/common'
import addMonths from 'date-fns/addMonths'
import addQuarters from 'date-fns/addQuarters'

export const getPresets = (): Preset[] => {
  const date = new Date()
  const defaultPresets = UI.getDefaultDateRangePresets(DEFAULT_MIN_DATE, DEFAULT_MAX_DATE)
  const allPeriod = { ...defaultPresets[0], title: 'All period' }
  const prevMonday = dates.addDays(-7, dates.getWeekStart(date))
  const prevSunday = dates.addDays(6, prevMonday)
  const prevMonthStart = addMonths(dates.getMonthStart(date), -1)
  const prevMonthEnd = dates.getMonthEnd(prevMonthStart)
  const prevQuarterStart = addQuarters(dates.getQuarterStart(date), -1)
  const prevQuarterEnd = dates.getQuarterEnd(prevQuarterStart)

  return [
    allPeriod,
    ...defaultPresets.slice(2)
  ].concat([
    { name: PRESET_LABELS.PREVIOUS_WEEK, startDate: prevMonday, endDate: prevSunday },
    { name: PRESET_LABELS.PREVIOUS_MONTH, startDate: prevMonthStart, endDate: prevMonthEnd },
    { name: PRESET_LABELS.PREVIOUS_QUARTER, startDate: prevQuarterStart, endDate: prevQuarterEnd }
  ])
}
