import { type FC } from 'react'
import { cnx, FormNumberInput } from '@carfluent/common'

import { MAX_PRICE_VALUE } from 'constants/validation'
import InfoIconWithTooltip from 'components/common/InfoIconWithTooltip'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

import renderDisplayField from '../renderHelper'
import { type GeneralSettingsFieldsProps } from './types'
import CLASS_NAME from './styles'
import {
  INTEREST_RATES,
  LABEL_ADJUSTMENT,
  LABEL_CREDIT_CARD_LIMIT,
  LABEL_INTEREST_RATE,
  LABEL_INVENTORY_PACK_COST,
  LABEL_MONTHLY_TARGET,
  MIN_TRADE_IN_ADJUSTMENT_VALUE,
  TRADE_IN_DESCRIPTION,
  SALES_MONTHLY_TARGET_DESCRIPTION
} from './constants'

const GeneralSettingsFields: FC<GeneralSettingsFieldsProps> = ({
  className,
  values,
  errors,
  touched,
  isLoading,
  onBlur,
  onChange
}) => {
  return (
    <div className={cnx(CLASS_NAME, 'content', className)}>
      <div>
        <BlockSkeleton isLoading={isLoading} className='title-loader'>
          <h6 className='section-title h6-title'>
            Sales monthly target
            <InfoIconWithTooltip
              description={SALES_MONTHLY_TARGET_DESCRIPTION}
              size={14}
              placement='top'
              tooltipClassName='sales-monthly-target-tooltip'
            />
          </h6>
        </BlockSkeleton>

        <div className='two-fields-row'>
          <BlockSkeleton isLoading={isLoading} className='field-loader'>
            <FormNumberInput
              id='salesMonthlyTarget'
              label={LABEL_MONTHLY_TARGET}
              className='sales-monthly-target'
              max={MAX_PRICE_VALUE}
              isEmptyAllowed
              isNegativeAllowed={false}
              onChange={onChange}
              onBlur={onBlur}
              value={values.salesMonthlyTarget}
              error={errors.salesMonthlyTarget}
              touched={touched.salesMonthlyTarget}
            />
          </BlockSkeleton>

          {isLoading && (
            <BlockSkeleton isLoading className='field-loader' />
          )}
        </div>

        <BlockSkeleton isLoading={isLoading} className='title-loader'>
          <h6 className='section-title h6-title'>
            Credit card limit & inventory pack cost
          </h6>
        </BlockSkeleton>

        <div className='two-fields-row'>
          <BlockSkeleton isLoading={isLoading} className='field-loader'>
            <FormNumberInput
              id='maxCardPaymentAmount'
              label={LABEL_CREDIT_CARD_LIMIT}
              preset='price'
              max={MAX_PRICE_VALUE}
              onChange={onChange}
              onBlur={onBlur}
              value={values.maxCardPaymentAmount}
              error={errors.maxCardPaymentAmount}
              touched={touched.maxCardPaymentAmount}
            />
          </BlockSkeleton>

          <BlockSkeleton isLoading={isLoading} className='field-loader'>
            <FormNumberInput
              id='inventoryPackCost'
              label={LABEL_INVENTORY_PACK_COST}
              preset='price'
              max={MAX_PRICE_VALUE}
              onChange={onChange}
              onBlur={onBlur}
              value={values.inventoryPackCost}
              error={errors.inventoryPackCost}
              touched={touched.inventoryPackCost}
            />
          </BlockSkeleton>
        </div>

        <div className='two-fields-row'>
          <div>
            <BlockSkeleton isLoading={isLoading} className='title-loader'>
              <h6 className='section-title h6-title'>
                Trade in adjustment
                <InfoIconWithTooltip
                  description={TRADE_IN_DESCRIPTION}
                  size={14}
                  placement='top'
                  tooltipClassName='trade-in-tooltip'
                />
              </h6>
            </BlockSkeleton>

            <BlockSkeleton isLoading={isLoading} className='field-loader'>
              <FormNumberInput
                id='tradeInAdjustment'
                label={LABEL_ADJUSTMENT}
                className='trade-in-adjustment'
                preset='percent'
                min={MIN_TRADE_IN_ADJUSTMENT_VALUE}
                onChange={onChange}
                onBlur={onBlur}
                value={values.tradeInAdjustment}
                error={errors.tradeInAdjustment}
                touched={touched.tradeInAdjustment}
              />
            </BlockSkeleton>
          </div>

          <div>
            {isLoading && <div className='title-filler' />}
            {!isLoading && (<h6 className='section-title h6-title markups-title'>Markups</h6>)}

            <BlockSkeleton isLoading={isLoading} className='field-loader'>
              <FormNumberInput
                id='interestRateMarkup'
                label='Interest markup'
                preset='percent'
                className='interest-rate-markup'
                onChange={onChange}
                onBlur={onBlur}
                value={values.interestRateMarkup}
                error={errors.interestRateMarkup}
                touched={touched.interestRateMarkup}
              />
            </BlockSkeleton>
          </div>
        </div>

      </div>

      <div>
        <BlockSkeleton isLoading={isLoading} className='title-loader'>
          <h6 className='section-title h6-title'>Interest rates for calculations</h6>
        </BlockSkeleton>

        <div className='interest-rate-content'>
          {INTEREST_RATES.map(({ name, amount, className }, idx) => (
            <BlockSkeleton isLoading={isLoading} className='field-loader' key={idx}>
              {renderDisplayField(name, amount, className)}
            </BlockSkeleton>
          ))}

          <BlockSkeleton isLoading={isLoading} className='field-loader'>
            <FormNumberInput
              id='excellentInterestRate'
              label={LABEL_INTEREST_RATE}
              preset='percent'
              onChange={onChange}
              onBlur={onBlur}
              value={values.excellentInterestRate}
              error={errors.excellentInterestRate}
              touched={touched.excellentInterestRate}
            />
          </BlockSkeleton>

          <BlockSkeleton isLoading={isLoading} className='field-loader'>
            <FormNumberInput
              id='goodInterestRate'
              label={LABEL_INTEREST_RATE}
              preset='percent'
              onChange={onChange}
              onBlur={onBlur}
              value={values.goodInterestRate}
              error={errors.goodInterestRate}
              touched={touched.goodInterestRate}
            />
          </BlockSkeleton>

          <BlockSkeleton isLoading={isLoading} className='field-loader'>
            <FormNumberInput
              id='averageInterestRate'
              label={LABEL_INTEREST_RATE}
              preset='percent'
              onChange={onChange}
              onBlur={onBlur}
              value={values.averageInterestRate}
              error={errors.averageInterestRate}
              touched={touched.averageInterestRate}
            />
          </BlockSkeleton>

          <BlockSkeleton isLoading={isLoading} className='field-loader'>
            <FormNumberInput
              id='belowAverageInterestRate'
              label={LABEL_INTEREST_RATE}
              preset='percent'
              onChange={onChange}
              onBlur={onBlur}
              value={values.belowAverageInterestRate}
              error={errors.belowAverageInterestRate}
              touched={touched.belowAverageInterestRate}
            />
          </BlockSkeleton>
        </div>
      </div>
    </div>
  )
}

export default GeneralSettingsFields
