import { useCallback, useState, useEffect } from 'react'
import { useLoader } from '@carfluent/common'
import { useParams } from 'react-router-dom'

import { type EntityId } from 'types'
import { type DealRecapDto } from 'api/types/responses'
import CustomersCoreApiProvider from 'api/customersCore.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

export interface UseDealRecapProps {
  dealId?: EntityId | null
  onError?: (err: Error) => void
  onSuccess?: (data: DealRecapDto) => void
  shouldLoadData: boolean
}

export interface UseDealRecapReturn {
  dealFinancesData: DealRecapDto | null
  isLoading: boolean
  refreshData: () => void
}

const useDealRecap = ({
  dealId: _dealId = null,
  onError,
  onSuccess,
  shouldLoadData
}: UseDealRecapProps): UseDealRecapReturn => {
  const { id: dealIdFromUrl = '' } = useParams<{ id: string }>()
  const { isLoading, stopLoader, startLoader } = useLoader()
  const { showAlert } = useCustomSnackbar()

  const [lastLoadTs, setLastLoadTs] =
    useState(Date.now())

  const [dealId, setDealId] =
    useState<EntityId | null>(dealIdFromUrl ?? _dealId)

  const [dealFinancesData, setDealFinancesData] =
    useState<DealRecapDto | null>(null)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const loadDealRecap = useCallback(async (dealId: number | string): Promise<DealRecapDto | null> => {
    try {
      startLoader()
      const recapData = await CustomersCoreApiProvider.getDealRecap(dealId)
      setDealFinancesData(recapData)
      onSuccess?.(recapData)

      return recapData
    } catch (err: any) {
      showAlert(err)
      onError?.(err)
      return null
    } finally {
      stopLoader()
    }
  }, [
    onError,
    onSuccess,
    showAlert,
    stopLoader,
    startLoader
  ])

  const refreshData = useCallback(() => {
    setLastLoadTs(Date.now())
  }, [])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if ((_dealId != null) && (_dealId !== dealId)) {
      setDealId(_dealId)
    }
  }, [_dealId, dealId])

  useEffect(() => {
    if ((dealId != null) && shouldLoadData) {
      void loadDealRecap(dealId)
    }
  }, [
    dealId,
    lastLoadTs,
    loadDealRecap,
    shouldLoadData
  ])

  // ========================================== //

  return {
    dealFinancesData,
    isLoading,
    refreshData
  }
}

export default useDealRecap
