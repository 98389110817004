import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 90px;

  .row {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 2fr 7fr 2fr 2fr;

    .column {
      display: flex;
      gap: 4px;
      flex-direction: row;
      padding: 8px 0px 8px 8px;
      white-space: nowrap;
      max-height: 40px;

      .text-container {
        width: 340px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .pending-flag {
        display: flex;
        padding: 2px 8px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 4px;
        background: #FFFAEA;
        color: #D09600;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
      }
      
      &:nth-last-child(-n+2) {
        padding-right: 16px;
        text-align: right;
      }
    }
  }

  .header {
    background-color: #FAFAFA;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: rgba(33, 33, 33, 0.50);
    margin-bottom: 8px;
  }

  .line-container {
    width: 100%;
    height: 100%;
    min-width: 100px;
    min-height: 640px;
  }

  .line {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #101010;
    margin-bottom: 8px;
    border-radius: 2px;
    &:hover {
      background: #FAFAFA;
    }

    &.error {
      background: #FFF9FA;
    }

    &.duplicated {
      background: #FAFAFA;
    }
  }
  
  .upload-line-checkbox {
    padding: 0;
    height: 24px;
    width: 24px;
  }
`)

export const TOOLTIP_CONTENT_CLASSES = css(`
  &.MuiTooltip-tooltip {
    max-width: 305px;
  }
`)
