import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'

import { type BasicValueCellProps } from 'types'
import PriorityIcon from './PriorityIcons'

import CLASS_NAME from './styles'

const TaskPriorityCell: FC<BasicValueCellProps> = (props) => {
  const value = props.getValue() as number

  return (
    <CellWrapper className={CLASS_NAME}>
      <PriorityIcon id={value} />
    </CellWrapper>
  )
}

export default TaskPriorityCell
