import { css } from '@emotion/css'
import { px, Responsive } from 'constants/constants'

export default css`
  width: 498px;
  display: grid;
  grid-auto-rows: min-content;
  gap: 24px;

  .title-wrapper {
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.5px;
    margin-bottom: 30px;

    @media screen and (max-width: ${px(Responsive.Tablet)}) {
      font-size: 34px;
      line-height: 36px;
      margin-bottom: 24px;
    }
  }

  .text {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(33, 33, 33, 0.8);
  }

  .show-hide {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.38);
    cursor: pointer;
  }

  .password-reset-api-errors { margin: -24px 0 ; }
  .password-wrapper > div:last-child { margin: 10px 0 12px 0; }
  .submit-btn span { text-transform: uppercase; }
  .password-reset .cf-form-field-error { display: none; }
`
