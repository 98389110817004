/* eslint-disable no-template-curly-in-string */
import { toJS } from 'mobx'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import isValid from 'date-fns/isValid'
import addYears from 'date-fns/addYears'
import subYears from 'date-fns/subYears'
import { FormikErrors, yupToFormErrors } from 'formik'
import * as Yup from 'yup'

import { type FullAddressParts } from 'types/address'
import {
  ValidationLength,
  MAX_YEARS_OLD,
  MIN_YEARS_OLD,
  MAX_YEARS_AMOUNT
} from 'constants/validation'

import { addressRegex } from './regex_helper'
import {
  isAlphanumericAndSpecialSymbols,
  isDate,
  isDefined,
  isEmailValid,
  isNumbersOnly,
  isValidDate
} from './validation'

function requiredOrNot<T extends Yup.BaseSchema> (this: T, required: boolean = true): T {
  return required
    ? this.required(ErrorMsg.required)
    : this.optional()
}

Yup.addMethod<Yup.StringSchema>(Yup.string, 'requiredOrNot', requiredOrNot)
Yup.addMethod<Yup.NumberSchema>(Yup.number, 'requiredOrNot', requiredOrNot)
Yup.addMethod<Yup.DateSchema>(Yup.date, 'requiredOrNot', requiredOrNot)

export const stringOfCorrectLength = (maxLen: number) => (value?: string): boolean => isDefined(value)
  ? value.length === maxLen
  : true

export const getStringOrEmpty = (value: any): string => (typeof value === 'string') ? value : ''

export const emptyAndNaNToNull = (value: any): any => (value === '') || (Number.isNaN(value)) ? null : value

export type Validator<T> = (data: T) => FormikErrors<T>

export const getValidator = <T>(schema: Yup.AnySchema, showLogs = false, showData = false): Validator<T> => {
  return (data) => {
    try {
      schema.validateSync(data, { abortEarly: false })

      if (showLogs) {
        console.info('form is valid')
      }

      if (showData) {
        console.info('data :: ', toJS(data))
      }

      return {}
    } catch (err) {
      if (showLogs) {
        console.info(err)
        console.info(yupToFormErrors(err))
      }

      if (showData) {
        console.info('data :: ', toJS(data))
      }

      return yupToFormErrors(err)
    }
  }
}

export const ErrorMsg = {
  required: 'Cannot be empty',
  invalidDate: 'Invalid date',
  invalidFormat: 'Invalid format',
  onlyNumbers: 'Should contain only numbers',
  onlyLatin: 'Latin alphabet only',
  positive: 'Should be greater than zero',
  exactLength: 'Should contain ${length} characters',
  minLengthShort: 'Min ${min} characters',
  minLength: 'Should contain at least ${min} characters',
  maxLength: 'Should contain at most ${max} characters',
  maxLengthShort: 'Max ${max} characters',
  lessThan: 'Cannot be less than ${min}',
  biggerThan: 'Cannot be bigger than ${max}',
  minMonths: 'Cannot be less than ${min} months',
  maxMonths: 'Cannot be more than ${max} months',
  exactNumberLength: 'Should contain ${length} numbers',
  minNumberLength: 'Should contain at least ${min} numbers',
  maxNumberLength: 'Should contain at most ${max} numbers',
  zipCode: 'ZipCode should contain 5 numbers',
  zipCodeShort: 'Enter 5 digits',
  nonSymbols: 'Should not contain symbols',
  birthdayMax: `You need to be ${MIN_YEARS_OLD} years or older`,
  birthdayMin: `You need to be ${MAX_YEARS_OLD} years or younger`,
  afterBirthDate: 'Date should be after the date of birth',
  afterStartDate: 'Date should be after the working start date',
  incorrectEndDate: 'Date should be after source date',
  beforeCurrentStartDate: 'Should not exceed the current start date',
  notInFuture: 'Date cannot be in the future',
  maxPercent: 'Value cannot exceed 100%',
  onlyAlphanumeric: 'Field can include only alphanumeric characters',
  driverLicense: 'Please enter valid driver license number',
  nameAlreadyTaken: 'The name is already taken. Please choose another name',
  nameEmpty: 'Please enter the name',
  invalidAddress: 'Address should have state, city, address and zip code',
  invalidApt: 'Field is invalid',
  lessThenOneMonth: 'Cannot be less than 1 month',
  addressInvalid: 'Address should be in format: street number and then street name',
  streetNameTooLong: 'Street name should not exceed ${max} symbols',
  invalidStreetName: 'Street name is invalid',
  invalidCityName: 'City name is invalid',
  invalidStateName: 'State name is invalid',
  cityNameTooLong: 'City name should not exceed ${max} symbols',
  incorrectDate: 'Date seems to be incorrect',
  invalidVINcode: 'Wrong VIN number. Please check.',
  costBiggerThan: 'Pack cost field Max value is $${max}',
  betweenMinMax: 'Should be between 0 and ${max}',
  zeroAmount: 'Cannot be zero',
  longName: 'Name is too long'
}

// ========================================== //

export type TestReturn<T> = [string, string, (val: T, context: Yup.TestContext<any>) => boolean]

/**
 * Same as Yup.date().min(), but for the case
 * when your date fields is stored as string.
 */
export const getMinDateStringTest = (min: Date, msg?: string): TestReturn<string | null | undefined> => {
  const minStr = format(min, 'MM/dd/yyyy')

  return [
    'minDateString',
    msg?.replace(/\$\{min\}/g, minStr) ?? `Cannot be before ${minStr}`,
    (val): boolean => {
      return (val == null) || !isBefore(new Date(val), min)
    }
  ]
}

export const getMaxDateStringTest = (max: Date, msg?: string): TestReturn<string | null | undefined> => {
  const maxStr = format(max, 'MM/dd/yyyy')

  return [
    'maxDateString',
    msg?.replace(/\$\{max\}/g, maxStr) ?? `Cannot be after ${maxStr}`,
    (val): boolean => {
      return (val == null) || !isAfter(new Date(val), max)
    }
  ]
}

// ========================================== //

export const stringField = (): Yup.StringSchema => Yup.string()
  .transform(getStringOrEmpty)

export const numberField = (): Yup.NumberSchema<number | null | undefined> => Yup.number()
  .nullable()
  .transform(emptyAndNaNToNull)
  .typeError(ErrorMsg.invalidFormat)

export const dateField = (): Yup.DateSchema<Date | null | undefined> => Yup.date()
  .nullable()
  .typeError(ErrorMsg.invalidDate)

/**
 * Describes a date field that is stored as string.
 */
const minDateString = getMinDateStringTest(addYears(new Date(), -100))
const maxDateString = getMaxDateStringTest(addYears(new Date(), 100))
export const dateStringField = (): Yup.StringSchema<string | null | undefined> => Yup.string()
  .nullable()
  .transform((_, value) => value)
  .test('validDate', ErrorMsg.invalidFormat, (val) => {
    const date = val == null ? null : new Date(val)
    return isDate(date) ? isValid(date) : true
  })
  .typeError(ErrorMsg.invalidFormat)
  .test(...minDateString)
  .test(...maxDateString)

export const addressData = (required = true): Yup.ObjectSchema<{}> => Yup.object()
  .transform((val: FullAddressParts | null) => val ?? {})
  .test('addressData', ErrorMsg.invalidAddress, ({ city, zipCode, state, address }: FullAddressParts) => {
    if (!required && !(Boolean(city) || Boolean(zipCode) || Boolean(state) || Boolean(address))) {
      return true
    }
    return Boolean(city) && Boolean(zipCode) && Boolean(state) && Boolean(address)
  })

export const address = (
  isRequired = true,
  isRelaxed = false
): Yup.StringSchema => Yup.string()
  .transform(getStringOrEmpty)
  .requiredOrNot(isRequired)
  .test('symbols', ErrorMsg.invalidStreetName, isAlphanumericAndSpecialSymbols)
  .test('address', function (val = '') {
    if ((val === '') || isRelaxed) {
      return true
    }

    const parts = val.match(addressRegex)
    /**
     * parts[0] is always for the whole match
     */

    if (parts == null || parts.length < 3) {
      return this.createError({
        message: ErrorMsg.addressInvalid
      })
    }

    if (parts[2].length > ValidationLength.STREET_NAME_MAX) {
      return this.createError({
        message: ErrorMsg.streetNameTooLong.replace('${max}', ValidationLength.STREET_NAME_MAX.toString())
      })
    }

    return true
  })

export const zipCode = (required = true): Yup.StringSchema => {
  const base = Yup.string()
    .transform(getStringOrEmpty)
    .requiredOrNot(required)
    .test('numbers', ErrorMsg.onlyNumbers, isNumbersOnly)

  return required
    ? base.length(ValidationLength.ZIP_CODE, ErrorMsg.zipCodeShort)
    : base.test('zipCode', ErrorMsg.zipCodeShort, stringOfCorrectLength(ValidationLength.ZIP_CODE))
}

export const vinField = (): Yup.StringSchema => stringField()
  .required(ErrorMsg.required)
  .test('isVin', ErrorMsg.invalidVINcode, (value) => {
    const regVIN = /\b[(A-H|J-N|P|R-Z|0-9)]{17}\b/gm
    return value != null && regVIN.test(value)
  })
  .min(ValidationLength.VIN_LENGTH, ErrorMsg.minLength)
  .max(ValidationLength.VIN_LENGTH, ErrorMsg.maxLength)

export const yearField = (required = true): Yup.NumberSchema<number | null | undefined> => numberField()
  .requiredOrNot(required)
  .test('length', ErrorMsg.invalidFormat, (value) => value != null
    ? /^\d{4}$/.test(`${value}`)
    : true
  )
  .test('notInFuture', ErrorMsg.notInFuture, (value) => value != null
    ? value <= new Date().getFullYear()
    : true
  )

export const makeField = (required = true): Yup.StringSchema => stringField()
  .requiredOrNot(required)
  .max(ValidationLength.MAKE_MAX, ErrorMsg.maxLength)

export const modelField = (required = true): Yup.StringSchema => stringField()
  .requiredOrNot(required)
  .max(ValidationLength.MODEL_MAX, ErrorMsg.maxLength)

/**
 * Trim validation rule
 * @param required - false by default
 */
export const trimField = (required = false): Yup.StringSchema => stringField()
  .requiredOrNot(required)
  .max(ValidationLength.TRIM_MAX, ErrorMsg.maxLength)

export const requiredDate = (): Yup.DateSchema<Date | null | undefined> => dateField()
  .test('required', ErrorMsg.required, Boolean)

export const requiredString = (): Yup.StringSchema => {
  return stringField().required(ErrorMsg.required).trim(ErrorMsg.required)
}

export const requiredNumber = (): Yup.NumberSchema<number | null | undefined> => {
  return numberField().required(ErrorMsg.required)
}

export const dictionaryObjectItem = (required = true): Yup.AnyObjectSchema => {
  return required
    ? Yup.object().typeError(ErrorMsg.required).required(ErrorMsg.required)
    : Yup.object().nullable()
}

export const maxNumberField = (value: number, required: boolean = true, errorMessage: string = ErrorMsg.biggerThan): Yup.NumberSchema<number | null | undefined> => {
  if (required) {
    return numberField()
      .max(value, errorMessage)
      .requiredOrNot(true)
  }

  return numberField()
    .max(value, errorMessage)
}

const isEmailValidWithEmptyState = (value?: string | null): boolean => {
  if (value === '' || value == null) {
    return true
  } else {
    return isEmailValid(value)
  }
}

/**
 * Don't use this rule for validation email in signup\login pages,
 * since it allows spaces before\after email.
 */
export const email = (required = true): Yup.StringSchema => Yup.string()
  .requiredOrNot(required)
  .transform(getStringOrEmpty)
  .transform((value: string) => value.trim())
  .max(ValidationLength.EMAIL_MAX, 'Email seems to be too long')
  .test('email', 'Invalid email', required ? isEmailValid : isEmailValidWithEmptyState)

export const validateDate = (cb: typeof isBefore | typeof isAfter, years: number) => (val: Date | null | undefined) => {
  return !isValidDate(val) || !cb(val, subYears(new Date(), years))
}

export const hundredYearsDateValidator = (msg: string = ErrorMsg.incorrectDate): Yup.DateSchema<Date | null | undefined> => requiredDate()
  .test('req', msg, validateDate(isBefore, MAX_YEARS_AMOUNT))
  .test('req', msg, validateDate(isAfter, -MAX_YEARS_AMOUNT))
