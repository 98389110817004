import { DealNextAction } from 'api/types'
import isDealCompleted, { isDeal2Completed } from 'utils/deals/isDealCompleted'

export interface DealLike {
  dealState: string
  nextAction: number | null
}
export interface Deal2Like {
  dealStateId: number
  nextAction: number | null
}

const isDealWaitingForCreditAppApprove = (deal: DealLike): boolean => {
  return !isDealCompleted(deal) && (deal.nextAction === DealNextAction.ReviewCreditApplication)
}
export const isDeal2WaitingForCreditAppApprove = (deal: Deal2Like | null): boolean => {
  return !isDeal2Completed(deal) && (deal?.nextAction === DealNextAction.ReviewCreditApplication)
}

export default isDealWaitingForCreditAppApprove
