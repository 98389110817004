import type { ReactNode } from 'react'

import type { TransactionLineVendorDto } from 'api/types'
import { formatPhoneNumberForView } from 'utils/view_helper'

import CLASS_NAME from './styles'

const VendorOption = (vendor: TransactionLineVendorDto | string | null): ReactNode => {
  if (vendor == null || typeof vendor === 'string') {
    return null
  }

  const { name, email, phoneNumber } = vendor
  const hasEmail = email != null && email.length > 0
  const hasPhone = phoneNumber != null && phoneNumber.length > 0
  const hasDivider = hasEmail && hasPhone

  return (
    <div className={CLASS_NAME}>
      <div className='entity-option'>
        <p>{name}</p>
        <div className='entity-subtitles'>
          {hasEmail && (<p className='entity-subtitle'>{email}</p>)}
          {hasDivider && (<div className='entity-divider' />)}
          {hasPhone && (
            <p className='entity-subtitle'>{formatPhoneNumberForView(phoneNumber)}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default VendorOption
