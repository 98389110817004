import { VehicleStatus } from 'api/types/vehicles.types'

export enum VehicleStatusesName {
  PurchaseAction = 'Purchase/Auction',
  Recon = 'Reconditioning',
  ReadyForSale = 'Ready for sale'
}

export const statusesToChoose = [
  {
    id: VehicleStatus.Recon,
    name: VehicleStatusesName.Recon
  },
  {
    id: VehicleStatus.ReadyForSale,
    name: VehicleStatusesName.ReadyForSale
  }
]
