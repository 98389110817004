import type { FC, ReactNode } from 'react'
import { cnx, Loader, parsers } from '@carfluent/common'

import Tooltip from 'components/common/Tooltip'
import IconSVG from 'components/inlineImages'
import NameWithAvatar from 'components/common/NameWithAvatar'
import TooltipedText from 'components/common/TooltipedText'

import type { DocumentDetailsDto, DocumentVerificationDetails } from 'api/types'
import { DriverLicenseUploadStatusType, FileType } from 'api/types'
import { formatDate as _formatDate } from 'utils/parse_date'

import CLASS_NAME, { TOOLTIP_CLASS_NAME } from './styles'

interface DealDocumentRowProps extends DocumentDetailsDto {
  onRemove: () => void
  onClick: () => void
  isLoading?: boolean
  customerName: string | null
}

const formatDate = (date: string | null): string => {
  const parsedDate = parsers.parseDateStringUTC(date)

  return parsedDate != null
    ? _formatDate(parsedDate, 'MM/dd/yy hh:mm aa') ?? '-'
    : '-'
}

const DealDocumentRow: FC<DealDocumentRowProps> = ({
  isLoading = false,
  documentVerificationDetails,
  fileTypeId,
  createdDate,
  fileName,
  uploadedByUser,
  onClick,
  onRemove: _onRemove,
  customerName
}) => {
  const isDisabledRemoved = fileTypeId !== FileType.Other
  const onRemove = (): void => {
    if (isDisabledRemoved) {
      return
    }

    _onRemove()
  }

  return (
    <div className={CLASS_NAME}>
      <div onClick={onClick} className={cnx('name-column', isLoading && 'is-loading')}>
        {isLoading
          ? <Loader color='dark' size='small'/>
          : getIconName(fileTypeId, documentVerificationDetails)
        }

        <div className='file-name'>
          {fileName}
        </div>
      </div>

      <div>{formatDate(createdDate)}</div>

      <div className={cnx('user-column', isDisabledRemoved && 'disabled')}>
        {getUserInfo({
          user: uploadedByUser,
          customerName,
          fileType: fileTypeId,
          isLoading
        })}

        {!isLoading && <IconSVG.Close className='close-icon' onClick={onRemove} />}
      </div>
    </div>
  )
}

const getTooltipContent = (isFailed: boolean, reasons?: string[]): JSX.Element | string => {
  if (!isFailed) {
    return 'Verification passed'
  }

  if (reasons != null && reasons.length > 1) {
    return (
      <ul>
        {reasons.map((message) =>
          <li key={message}>{message}</li>)}
      </ul>
    )
  }

  return reasons?.[0] ?? 'Verification failed'
}

const getIconName = (fileType: FileType, details?: DocumentVerificationDetails | null): ReactNode => {
  const isDriverSuccess = fileType === FileType.DriverLicense

  if (isDriverSuccess) {
    const isFailed = details?.status !== DriverLicenseUploadStatusType.Completed

    return (
      <Tooltip
        popperClassName={TOOLTIP_CLASS_NAME}
        placement='top-start'
        title={getTooltipContent(isFailed, details?.verificationFailedReasons)}
      >
        {isFailed ? <IconSVG.ErrorDoc /> : <IconSVG.CheckedDoc />}
      </Tooltip>
    )
  }

  return fileType === FileType.NotarizedPaperwork
    ? (
      <Tooltip
        popperClassName={TOOLTIP_CLASS_NAME}
        placement='top-start'
        title='Notarization passed'
      >
        <IconSVG.Notirized />
      </Tooltip>
      )
    : <IconSVG.IconSVGDocument width={24} />
}

interface GetIconUser {
  user: DocumentDetailsDto['uploadedByUser']
  fileType: FileType
  customerName: string | null
  isLoading: boolean
}

const getUserInfo = ({
  user,
  fileType,
  customerName,
  isLoading
}: GetIconUser): ReactNode => {
  if (isLoading) {
    return '-'
  }

  if (user == null || (fileType === FileType.DriverLicense)) {
    return (
      <div className='driver-licence-wrapper'>
        <IconSVG.UserDocuments />

        <TooltipedText value={customerName ?? '-'} />
      </div>
    )
  }

  return (
    <NameWithAvatar
      className='name-with-avatar'
      showAvatar
      name={`${user.dealerFirstName} ${user.dealerLastName}`}
    />
  )
}

export default DealDocumentRow
