import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: row;
  gap: 4px;
  color: #458FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
    
  b {
    font-weight: 500;
  }
`)
