import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  padding: 0 60px 0 24px;
  border-radius: 2px;
  background-color: #FFFFFF;
  margin: 8px 0;

  :last-child {
    margin-bottom: 0;
  }
  
  div {
    height: 56px;
    min-width: 105px;
    max-width: 120px;

    :first-child {
      min-width: 212px;
      max-width: 276px;
      margin-right: 16px;
    }

    :last-child {
      min-width: 200px;
      max-width: 348px;
    }
  }
`)
