import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { AuthCTX } from 'store/auth'
import { GlobalUICTX, SnackbarConfig } from 'store/global_ui'
import { Routes } from 'constants/route_helper'
import useAppLoading from 'hooks/useAppLoading'
import { isPathnameHiddenPerRoles } from 'constants/routesPerRoles'
import SettingsCTX from 'store/settings'

interface UseLayoutReturn {
  isAppLoading: boolean
  isLoading: boolean
  isRecordWasUpdatedVisible: boolean
  snackbarConfig: SnackbarConfig
  hideSnackbar: () => void
  onRecordUpdatedClick: () => void
  isSettingsLoading: boolean
  auth: boolean
}

export const useLayout = (): UseLayoutReturn => {
  const { pathname } = useLocation()

  const { auth, userRoles } = useContext(AuthCTX)
  const {
    isLoading,
    isRecordWasUpdatedVisible,
    snackbarConfig,
    hideSnackbar,
    hideRecordWasUpdated
  } = useContext(GlobalUICTX)
  const { isLoading: isSettingsLoading } = useContext(SettingsCTX)

  const isAppLoading = useAppLoading()

  const navigate = useNavigate()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onRecordUpdatedClick = (): void => {
    hideRecordWasUpdated()
    window.location.reload()
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Route checking for correct permissions
   */
  useEffect(() => {
    /**
     * if pathname is blocked we navigate to base url a.k.a. Home page
     * which must be enabled for any role.
     *
     * DD-WARNING: if this behaviour changes this must be adjusted to avoid
     * infinite loop.
     */
    if (isPathnameHiddenPerRoles(pathname, userRoles)) {
      navigate(Routes.ForbiddenAccess, { replace: true })
    }
  }, [userRoles, pathname, navigate])

  // ========================================== //

  return {
    isLoading,
    isRecordWasUpdatedVisible,
    snackbarConfig,
    hideSnackbar,
    onRecordUpdatedClick,
    isAppLoading,
    isSettingsLoading,
    auth
  }
}
