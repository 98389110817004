import { type FC, useContext, useMemo, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Banner, cnx } from '@carfluent/common'

import DeleteModal from 'components/dialogs/DeleteModal'
import ErrorsBlock from 'components/common/ErrorsBlock'
import { TabItem, TabList, TabPanel } from 'components/common/Tabs'
import TopPanelActions from 'components/common/TopPanelActions'
import NewDealModal from 'pages/deals/NewDeal'
import TransactionDetails from 'pages/accounting/TransactionDetails'

import { UserRoles, VehicleState } from 'api/types'
import AuthCTX from 'store/auth'

import useVehicleDetails from './hooks'
import { Messages, TabIds } from './hooks/constants'
import { getCarStateBannerAdornment, getCarStateBannerText } from './hooks/utils'
import VehicleShortInfoPanel from './components/VehicleShortInfoPanel'
import GeneralTabPanel from './components/GeneralTabPanel'
import GalleryTabPanel from './components/GalleryTabPanel'
import CostsTabPanel from './components/CostsTabPanel'
import CostsAlert from './components/CostsAlert'
import FilesTab from './components/FilesTab'
import LeadsPanel from './components/LeadsPanel'
import { statusesToChoose } from './components/VehicleStatusSection/constants'
import VehicleStatusSection from './components/VehicleStatusSection'
import ActionsFooterAndUnsavedChanges from './components/ActionsFooterAndUnsavedChanges'

import CLASS_NAME from './styles'

export * from './hooks/types'

const VehicleDetails: FC = () => {
  const { userRoles } = useContext(AuthCTX)
  const {
    selectedTab,
    apiErrors,
    linkToWebsite,
    isAccountingEnabled,
    isCarSold,
    isCarDeleted,
    isNotificationVisible,
    isOpenUnsavedChanges,
    isSaving,
    onTabSwitchRequest,
    onSavePage,
    topMenuActions,
    workflowTypes,
    newDealModalProps,
    newDealDefaultValues,

    costsPanelProps,
    costsPanelDetailsProps,
    costsPanelSummaryProps,
    deleteVehicleModalProps,
    galleryTabProps,
    generalTabProps,
    isDeleteChecking,

    handleCloseUnsavedChanges,
    handleDontSaveAndCloseForm,
    handleGoBack,
    handleUpdateVehicleNotes,
    handleDelete,
    onResetTabNavigation,

    onAddTag,
    isTagLoading,
    onDeleteTagFromVehicle,
    onAddTagInVehicle,
    tagDictionaries,

    statusToChange,
    onConfirmStatus,
    onResetStatus,
    onChangeStatus,
    transactionDetailsProps,
    leadsPanel,
    filesTabProps,
    proceedTransition,
    isUploadingDocumentsBlocked,
    setIsUploadingDocumentsBlocked,

    onResetDirtyTabs,
    resetTransition,
    isAnyTabDirty
  } = useVehicleDetails()

  const {
    originalVehicle,
    values: vehicleDetails,
    setFieldValue,
    isLoading,
    transactionDealDetails: {
      isDealLoaded,
      recapTransactionId,
      workflowAccountingStateId
    }
  } = generalTabProps

  const { isPhotosUploading, mediaFiles } = galleryTabProps
  const isNotAllMediaUploaded = mediaFiles.some(file => file?.thumbnailUrl === undefined)

  const isActionsFooterHidden = (!isSaving && !isAnyTabDirty) ||
    isPhotosUploading || isNotAllMediaUploaded

  const hasRole = useMemo(() => (role: UserRoles): boolean => userRoles.includes(role), [userRoles])

  const TABS = useMemo(() => ([
    { id: TabIds.General, title: 'General' },
    { id: TabIds.Media, title: 'Media' },
    { id: TabIds.Costs, title: 'Costs', hidden: !isAccountingEnabled || hasRole(UserRoles.BDC) || hasRole(UserRoles.Buyer) || hasRole(UserRoles.Mechanic) },
    { id: TabIds.Leads, title: 'Leads', hidden: hasRole(UserRoles.Mechanic) },
    { id: TabIds.Files, title: 'Files' }
  ]), [hasRole, isAccountingEnabled])

  const isCostsEmpty = costsPanelProps.costsBreakdown.total === 0
  const isCostsTabDisabled = isCarDeleted

  const counter = useMemo<{ [key: number]: number | undefined }>(() => ({
    [TabIds.Media]: mediaFiles.length,
    [TabIds.Leads]: leadsPanel.priceHistoryProps.totalLeads ?? 0,
    [TabIds.Files]: filesTabProps.documents.length
  }), [leadsPanel.priceHistoryProps.totalLeads, mediaFiles.length, filesTabProps.documents.length])

  const onChange = useCallback((toggled: boolean) => {
    void setFieldValue('vehicleState', toggled ? VehicleState.Active : VehicleState.Inactive)
  }, [setFieldValue])

  const statusValue = useMemo(() => statusesToChoose.find(el => el.id === vehicleDetails.vehicleStatus) ?? statusesToChoose[0], [vehicleDetails.vehicleStatus])

  const isCarWebSiteLinkHidden =
    generalTabProps.values.vehicleState === VehicleState.Inactive ||
    isCarSold ||
    isCarDeleted

  return (
    <div className={cnx('card-content', 'vehicle-details', CLASS_NAME)}>
      <TopPanelActions
        actions={topMenuActions}
        backButtonName='back'
        className='g-top-panel-actions g-top-panel-actions-big-height '
        isActionBtnLoading={generalTabProps.isLoading || isDeleteChecking}
        onGoBack={handleGoBack}
      />

      <Banner
        message={Messages.VehicleField}
        isOpen={isNotificationVisible}
      />

      <Banner
        message={getCarStateBannerText(
          isCarSold,
          workflowAccountingStateId,
          originalVehicle.deletedByUser ?? originalVehicle.soldByUser,
          originalVehicle.soldDate,
          originalVehicle.deletedDate
        )}
        isOpen={isCarSold || isCarDeleted}
        type={isCarSold ? 'success' : 'info'}
        endAdornment={getCarStateBannerAdornment(
          isCarSold,
          workflowAccountingStateId,
          transactionDetailsProps.onOpenModal,
          isDealLoaded,
          originalVehicle.soldDealId
        )}
      />

      <div className='short-info-wrapper'>
        <VehicleShortInfoPanel
          isLoading={isLoading || isTagLoading}
          className='vehicle-card-wrapper short-info-left-column'
          vehicleDetails={originalVehicle}
          linkToWebsite={linkToWebsite}
          tags={tagDictionaries}
          selectedTags={vehicleDetails.tags}
          onAddTag={onAddTag}
          onDeleteTagFromVehicle={onDeleteTagFromVehicle}
          onAddTagInVehicle={onAddTagInVehicle}
          isCarOnDeposit={vehicleDetails.isDeposited && vehicleDetails.soldDealId === null}
          isCarWebSiteLinkHidden={isCarWebSiteLinkHidden}
          isCarReconLinkHidden={isCarDeleted}
        />

        <div className='short-info-right-column'>
          <VehicleStatusSection
            value={statusValue}
            onChange={onChange}
            isCarSold={isCarSold}
            isCarDeleted={isCarDeleted}
            onChangeStatus={onChangeStatus}
            isLoading={generalTabProps.isLoading}
            toggled={generalTabProps.values.vehicleState === VehicleState.Active}
          />
        </div>
      </div>

      <ErrorsBlock errors={apiErrors} />

      <div className='vehicle-details-section-tab-container'>
        <TabList>
          {TABS.filter(tab => tab?.hidden !== true).map((item, index) => {
            const isCostsTab = item.id === TabIds.Costs
            const isFileTab = item.id === TabIds.Files

            return (
              <TabItem
                id={item.id}
                onClick={onTabSwitchRequest}
                isSelected={selectedTab === item.id}
                key={item.id}
                isLoading={isLoading}
                className={cnx(
                  isCostsTab ? 'cost-tab' : '',
                  isFileTab ? 'file-tab' : ''
                )}
                loaderColor='primary'
                counter={counter[item.id]}
                endAdornment={(isCostsTab && isCostsEmpty && !isLoading && !isCarDeleted) && (
                  <CostsAlert isSelected={selectedTab === index} />
                )}
              >
                {item.title}
              </TabItem>
            )
          })}
        </TabList>

        <TabPanel isSelected={selectedTab === TabIds.General}>
          <GeneralTabPanel
            {...generalTabProps}
            notes={vehicleDetails.notes}
            handleUpdateNotes={handleUpdateVehicleNotes}
            isCarSold={isCarSold}
          />
        </TabPanel>

        <TabPanel isSelected={selectedTab === TabIds.Media}>
          <GalleryTabPanel {...galleryTabProps} isDisabled={isCarDeleted} />
        </TabPanel>

        <TabPanel isSelected={selectedTab === TabIds.Leads} isComponentShouldUnmount>
          <LeadsPanel {...leadsPanel} />
        </TabPanel>

        {isAccountingEnabled && (
          <TabPanel isSelected={selectedTab === TabIds.Costs}>
            <CostsTabPanel
              isDisabled={isCostsTabDisabled}
              costsPanelProps={costsPanelProps}
              costsPanelDetailsProps={costsPanelDetailsProps}
              costsPanelSummaryProps={costsPanelSummaryProps}
            />
          </TabPanel>
        )}

        <TabPanel isSelected={selectedTab === TabIds.Files} isComponentShouldUnmount>
          <FilesTab {...filesTabProps} />
        </TabPanel>
      </div>

      <TransactionDetails
        {...transactionDetailsProps}
        transactionId={recapTransactionId}
      />

      <DeleteModal
        isOpen={statusToChange != null}
        content={null}
        title='Change car status?'
        onConfirm={onConfirmStatus}
        onClose={onResetStatus}
        submitTitle='CHANGE'
      />

      <DeleteModal
        content='Are you sure you want to delete the car from the inventory?'
        isOpen={deleteVehicleModalProps.isModalOpen}
        onClose={deleteVehicleModalProps.onCloseModal}
        onConfirm={handleDelete}
        title='Delete car'
        submitTitle='YES, DELETE'
      />

      <ActionsFooterAndUnsavedChanges
        onSubmit={onSavePage}
        isSubmitting={isSaving}
        onResetForm={onResetDirtyTabs}
        resetTransition={resetTransition}
        onClose={handleCloseUnsavedChanges}
        proceedTransition={proceedTransition}
        isUnsavedChangesShown={isOpenUnsavedChanges}
        isActionsFooterShown={!isActionsFooterHidden}
        onDontSaveAndClose={handleDontSaveAndCloseForm}
        onResetTabNavigation={onResetTabNavigation}
      />

      <DeleteModal
        content='Do you want to skip uploading and leave page?'
        isOpen={isUploadingDocumentsBlocked}
        onClose={() => setIsUploadingDocumentsBlocked(false)}
        onConfirm={proceedTransition}
        title='Upload in progress'
        submitTitle='LEAVE'
        cancelTitle='BACK'
      />

      {
        newDealModalProps.isModalOpen && (
          <NewDealModal
            defaultValues={newDealDefaultValues}
            isOpen={newDealModalProps.isModalOpen}
            onClose={newDealModalProps.onCloseModal}
            workflowTypes={workflowTypes}
          />
        )
      }
    </div>
  )
}

export default observer(VehicleDetails)
