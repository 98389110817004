import { dates, type Preset, UI, PRESET_LABELS } from '@carfluent/common'
import addMonths from 'date-fns/addMonths'

import fileNames, { type ReportFileNames } from 'utils/reports/fileNames'
import { capitalizeString } from 'utils/general'
import { AccountAgingReportTypes } from './types'

export const AMOUNT_FORMAT = { emptyValues: '-', allowNegative: true }

export const API_CALL_DELAY_SEARCH = 3000

export const PRESET_END_OF_LAST_MONTH: string = PRESET_LABELS.PRESET_END_OF_LAST_MONTH

export enum Messages {
  emptyTableState = 'No records',
  nothingFoundState = 'No results found'
}

export const getPresets = (): Preset[] => {
  const today = new Date()
  const prevMonthStart = addMonths(dates.getMonthStart(today), -1)
  const prevMonthEnd = dates.getMonthEnd(prevMonthStart)
  const defaultPresets = UI.getDefaultDateRangePresets(today, prevMonthEnd) // any maxDate will be OK
  const todayRange = defaultPresets.filter(p => p.name === UI.PRESET_LABELS.TODAY)
  const yesterdayRange = defaultPresets.filter(p => p.name === UI.PRESET_LABELS.YESTERDAY)

  return todayRange
    .concat(yesterdayRange)
    .concat([{ name: PRESET_END_OF_LAST_MONTH, startDate: prevMonthEnd, endDate: prevMonthEnd }])
}

export const getReportFileNames = (reportType: string): ReportFileNames =>
  fileNames('AccountsAging' + capitalizeString(reportType))

export const getReportTitle = (schedulesType: string): string =>
  `A/${schedulesType === AccountAgingReportTypes.Payable ? 'P' : 'R'} aging report`
