const EMAIL_BODY_ID = '__email-body__'

export const unwrapBodyWithCarsOfInterest = (body: string | null | undefined): string => {
  if (body == null) {
    return ''
  }

  const bodyTag = `<body id="${EMAIL_BODY_ID}">`
  const bodyStartIdx = body.indexOf(bodyTag)

  if (bodyStartIdx === -1) {
    return body
  }

  const bodyEndIdx = body.indexOf('</body>', bodyStartIdx)

  return bodyEndIdx === -1
    ? body
    : body.slice(bodyStartIdx + bodyTag.length, bodyEndIdx)
}

export const wrapBodyWithCarsOfInterest = (body: string): string => {
  return `
  <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html dir="ltr" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="EN">
  <head>
    <meta charset="UTF-8">
    <meta content="width=device-width, initial-scale=1" name="viewport">
    <meta name="x-apple-disable-message-reformatting">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta content="telephone=no" name="format-detection">
  
    <link href="https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i" rel="stylesheet">
  
    <style type="text/css">
      .es-button {
        text-decoration: none !important;
        padding: 3px 24px !important;
      }

      @media (max-width:1200px) {
        .es-car-img,
        .es-car-info {
          width: 100% !important;
        }

        .es-car-img {
          margin-bottom: 20px !important;
        }

        .es-content table,
        .es-content {
          width: 100% !important;
          max-width: 600px !important;
        }

        .es-adapt-img {
          width: 100% !important;
          height: auto !important;
          max-height: unset !important;
        }
      }
    </style>
  </head>
  <body id="${EMAIL_BODY_ID}">
    ${body}
  </body>
</html>
`
}
