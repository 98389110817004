import { useModal, useLoader } from '@carfluent/common'

import type { FCHook } from 'types'
import useToggler from 'hooks/useToggler'
import { useCallback } from 'react'

const NOT_BLINK_DELAY = 1000

export interface UseUnsoldDialogProps {
  onSubmit: () => Promise<void>
  agreedTogglerDefault?: boolean
}

export interface UseUnsoldDialogReturn {
  checkboxProps: {
    checked: boolean
    onChange: () => void
  }
  isLoading: boolean
  isOpen: boolean
  isSubmitDisabled: boolean
  onCancel: () => void
  onClose: () => void
  onOpen: () => void
  onSubmit: () => void
}

const useUnsoldDialog: FCHook<UseUnsoldDialogProps, UseUnsoldDialogReturn> = ({
  onSubmit: _onSubmit,
  agreedTogglerDefault = false
}) => {
  const { isLoading, startLoader, stopLoader } = useLoader()
  const modalUnsoldProps = useModal()
  const { onCloseModal } = modalUnsoldProps
  const agreedToggler = useToggler(agreedTogglerDefault)

  const onClose = useCallback(() => {
    onCloseModal()
    setTimeout(() => agreedToggler.setValue(agreedTogglerDefault), NOT_BLINK_DELAY)
  }, [
    onCloseModal,
    agreedTogglerDefault,
    agreedToggler.setValue
  ])

  const onSubmit = useCallback(async () => {
    startLoader()
    await _onSubmit()
    stopLoader()
    onClose()
  }, [
    _onSubmit,
    onClose,
    startLoader,
    stopLoader
  ])

  return {
    ...modalUnsoldProps,
    checkboxProps: {
      checked: agreedToggler.value,
      onChange: agreedToggler.toggle
    },
    isLoading,
    isOpen: modalUnsoldProps.isModalOpen,
    isSubmitDisabled: !agreedToggler.value,
    onCancel: onClose,
    onClose: onClose,
    onOpen: modalUnsoldProps.onOpenModal,
    onSubmit
  }
}

export default useUnsoldDialog
