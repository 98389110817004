import { type FC } from 'react'
import { FormDropdown } from '@carfluent/common'

import { type FilterRowOptionsProps } from './types'
import { FILTER_OPTIONS } from './constants'
import { renderTemplateOption, renderMultiOption } from './utils'

const FilterRowOptions: FC<FilterRowOptionsProps> = ({
  onChange,
  onBlur,
  values,
  errors,
  touched,
  leadFilterLabel,
  leadFilterOptions,
  filterOptionName,
  disableSelectAll = false,
  onRemoveFilter
}) => {
  const isLeadWithScheduledAppointments = filterOptionName === 'filterLeadWithScheduledAppointments'

  return (
    <div className='filter-row'>
      {!isLeadWithScheduledAppointments && (
        <FormDropdown
          id={`${filterOptionName}.filter`}
          label='Filter'
          options={FILTER_OPTIONS}
          renderOption={renderTemplateOption}
          onChange={onChange}
          onBlur={onBlur}
          blurMode='select-first'
          disableClearable
          value={values?.filter}
          error={errors?.filter}
          touched={touched?.filter}
        />
      )}

      <FormDropdown
        id={isLeadWithScheduledAppointments ? `${filterOptionName}.filter` : `${filterOptionName}.options`}
        label={leadFilterLabel}
        options={isLeadWithScheduledAppointments ? FILTER_OPTIONS : leadFilterOptions}
        renderOption={isLeadWithScheduledAppointments ? renderTemplateOption : renderMultiOption}
        onChange={onChange}
        isMultiselect={!isLeadWithScheduledAppointments}
        onBlur={onBlur}
        blurMode='select-first'
        disableClearable={isLeadWithScheduledAppointments}
        className={isLeadWithScheduledAppointments ? 'lead-with-scheduled-appointments' : ''}
        disableSelectAll={disableSelectAll}
        value={isLeadWithScheduledAppointments ? values?.filter : values?.options}
        error={isLeadWithScheduledAppointments ? errors?.filter : errors?.options}
        touched={isLeadWithScheduledAppointments ? touched?.filter : touched?.options}
      />

      <div className='btn-remove'>
        <div onClick={() => onRemoveFilter(filterOptionName)}>
          Remove
        </div>
      </div>
    </div>
  )
}

export default FilterRowOptions
