import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useModal } from '@carfluent/common'
import type { Row } from '@tanstack/react-table'

import { VehiclesApiProvider } from 'api/vehicles.api'
import {
  VehicleState,
  type Vehicle, VehicleCounters,
  type VehicleListPayload,
  PaginatedResult,
  DictionaryItems
} from 'api/types'

import CalcRoutes from 'constants/route_helper'
import { API_CALL_DELAY, PAGE_SIZE, TABLE_MIN_HEIGHT } from 'constants/constants'

import useTableApi from 'hooks/useTableApi'
import useEffectOnce from 'hooks/useEffectOnce'
import { calcTableHeight } from 'utils/math'

import { type TagIds, UseVehiclesReturn } from './types'
import { getTopPanelActionsButton, groupByMake, mapToCheckboxes, getTotalCars } from './utils'

import useWSUpdates from './useWSUpdates'
import getColumnDefinitions from './columns'
import { getDefaultCounters, DEFAULT_SORTING } from './constants'
import { DEFAULT_SELECTED_ITEMS } from '../components/StatusFilter/constants'

const DEFAULT_COUNTERS = getDefaultCounters()

const useVehicles = (): UseVehiclesReturn => {
  const [isOpenCSVModal, toggleCSVModal] = useState(false)
  const navigate = useNavigate()
  const [isCountersLoading, setIsCountersLoading] = useState(false)
  const [counters, setCounters] = useState<VehicleCounters>(DEFAULT_COUNTERS)
  const [isFiltersChange, setFiltersChange] = useState(false)
  const [isScrolling, setScrolling] = useState(false)
  const [tags, setTags] = useState<DictionaryItems<string> | null>(null)
  const [tagIds, setTagIds] = useState<TagIds>([])

  const getVehiclesList = useCallback(async (payload: VehicleListPayload): Promise<PaginatedResult<Vehicle>> => {
    try {
      setFiltersChange(true)
      const [countersResp, listResp] = await Promise.all([
        VehiclesApiProvider.getCounters({
          search: payload.search ?? '',
          tagIds: payload.tagIds,
          isDeposited: payload.isDeposited,
          isPhotoPresent: payload.isPhotoPresent
        }),
        VehiclesApiProvider.getVehicles({ ...payload })
      ])
      setCounters(countersResp)

      return { ...listResp }
    } catch (err) {
      setCounters(DEFAULT_COUNTERS)
      return { items: [], count: 0 }
    } finally {
      setFiltersChange(false)
      setIsCountersLoading(false)
    }
  }, [])

  const getTagsDictionary = useCallback(async () => {
    try {
      const { items } = await VehiclesApiProvider.getTags()
      setTags(items)
    } catch {}
  }, [])

  const {
    emptyTableMessage,
    isLoading,
    rows,
    search,
    sorting,
    filters,
    loadRows,
    onSearch,
    setSearch,
    setSorting,
    setFilters
  } = useTableApi<Vehicle, VehicleListPayload, any>({
    apiCallDelay: API_CALL_DELAY,
    defaultFilters: {
      vehicleStateIds: DEFAULT_SELECTED_ITEMS ?? [],
      tagIds
    },
    defaultSorting: DEFAULT_SORTING,
    getList: getVehiclesList,
    shouldRunOnCall: true,
    emptyTableMessage: 'No vehicles found'
  })

  const hasSearch = Boolean(search)

  const {
    isModalOpen,
    onOpenModal,
    onCloseModal
  } = useModal()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onVehicleUpdated = useCallback(async () => {
    const take = rows.length < PAGE_SIZE ? PAGE_SIZE : rows.length

    await Promise.all([
      getTagsDictionary(),
      loadRows({ forceRefresh: true, pageSize: take })
    ])
  }, [rows.length, loadRows])

  const onToggleCsvModal = (isOpen?: boolean): void =>
    toggleCSVModal(isOpen != null ? isOpen : !isOpenCSVModal)

  const groupBy = ((sorting.id === 'make') && !hasSearch) ? groupByMake : undefined

  const onStatusFilterChange = useCallback(async (vehicleStateIds: VehicleState[]) => {
    void setFilters({ vehicleStateIds })
  }, [setFilters])

  const onDepositCheckboxChange = useCallback((isDeposited: boolean) => {
    void setFilters({
      ...filters,
      isDeposited
    })
  }, [setFilters, filters])

  const handleTagsChange = useCallback(async (tagIds: TagIds) => {
    setIsCountersLoading(true)
    const selectedAll = tags?.length === tagIds.length

    setTagIds(selectedAll ? [] : tagIds)
    void setFilters({ tagIds: selectedAll ? [] : tagIds })
  }, [setTagIds, setFilters, tags])

  const handleOpenVehicle = useCallback((row: Row<Vehicle>) => {
    navigate(CalcRoutes.VehicleDetails(row.original.id))
  }, [navigate])

  const onBottomReached = useCallback(async () => {
    if (!isLoading && TABLE_MIN_HEIGHT <= calcTableHeight(rows.length)) {
      setScrolling(true)
      await loadRows({ forceRefresh: false })
      setScrolling(false)
    }
  }, [isLoading, loadRows, rows.length])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffectOnce(() => {
    const runEffect = async (): Promise<void> => {
      await getTagsDictionary()
    }

    void runEffect()
  }, [loadRows, getTagsDictionary])

  /**
   * Updates Vehicle table by WebSocket events.
   */
  useWSUpdates({
    onVehicleUpdated
  })

  // ========================================== //

  const basicColumns = useMemo(() => {
    return getColumnDefinitions({
      headerTagFilterProps: {
        title: 'Tags',
        popUpTitle: 'Show tags:',
        filters: tagIds,
        items: tags,
        onChange: handleTagsChange,
        itemMapper: mapToCheckboxes,
        isSelectAllAllowed: true,
        popoverProps: {
          minWidth: 245
        }
      },
      totalCars: getTotalCars(filters.vehicleStateIds ?? [], counters)
    })
  }, [
    handleTagsChange,
    tagIds,
    tags,
    handleTagsChange,
    filters,
    counters
  ])

  const topPanelActions = useMemo(() => {
    return getTopPanelActionsButton({
      onToggleCsvModal,
      onAddNewCar: onOpenModal
    })
  }, [onToggleCsvModal, onOpenModal])

  return {
    isLoading,
    columns: basicColumns,
    onDepositCheckboxChange,
    rows,
    isModalOpen,
    onCloseModal,
    counters,
    isFiltersChange: isFiltersChange && !isScrolling && !isCountersLoading,
    sorting,
    search,
    isOpenCSVModal,
    topPanelActions,
    emptyTableMessage,
    loadRows,
    onStatusFilterChange,
    handleOpenVehicle,
    groupBy,
    onToggleCsvModal,
    onSearchChange: setSearch,
    onSearch,
    onSortingChange: setSorting,
    onBottomReached
  }
}

export default useVehicles
