import { decimalToPercent } from 'utils/math'
import { type AccountListItem } from 'api/types'
import {
  type DealerFeeMarkupSettingsDto,
  type DealerSettingsModel
} from 'api/types/responses'

const parseFeeMarkupSettingsDto = (
  settings: DealerFeeMarkupSettingsDto,
  dictionaries: AccountListItem[]
): DealerSettingsModel => {
  const {
    coverageFeeSettings: {
      buyerTagSetting,
      carDeliverySetting,
      dealerHandlingFeeSetting,
      dealerInventoryTaxSetting,
      emissionsSetting,
      inspectionFeeSetting,
      plateTransferFeeSetting,
      registrationFeeSetting,
      titleFeeSetting,
      backGrossSalesCommissionSetting,
      frontGrossSalesCommissionSetting
    },
    salesTarget,
    ...settingsProps
  } = settings

  const getAccount = (accountId: number | null): AccountListItem | null => {
    if (accountId !== null) {
      return dictionaries.find((a) => a.id === accountId) ?? null
    } else {
      return null
    }
  }

  return {
    ...settingsProps,
    salesMonthlyTarget: salesTarget,
    coverageFeeSettings: {
      ...settings.coverageFeeSettings,
      buyerTagSetting: {
        ...buyerTagSetting,
        defaultValueFinancing: buyerTagSetting.defaultFinancingAmount ?? 0,
        defaultValueCash: buyerTagSetting.defaultCashAmount ?? 0,
        account: getAccount(buyerTagSetting.accountId)
      },
      carDeliverySetting: {
        ...carDeliverySetting,
        defaultValueCash: carDeliverySetting.defaultAmount,
        account: getAccount(carDeliverySetting.accountId)
      },
      dealerHandlingFeeSetting: {
        ...dealerHandlingFeeSetting,
        defaultValueCash: dealerHandlingFeeSetting.defaultAmount,
        account: getAccount(dealerHandlingFeeSetting.accountId)
      },
      dealerInventoryTaxSetting: {
        ...dealerInventoryTaxSetting,
        defaultValueCash: decimalToPercent(dealerInventoryTaxSetting.defaultCashPercent ?? 0),
        defaultValueFinancing: decimalToPercent(dealerInventoryTaxSetting.defaultFinancingPercent ?? 0),
        account: getAccount(dealerInventoryTaxSetting.accountId)
      },
      emissionsSetting: {
        ...emissionsSetting,
        defaultValueCash: emissionsSetting.defaultAmount,
        account: getAccount(emissionsSetting.accountId)
      },
      inspectionFeeSetting: {
        ...inspectionFeeSetting,
        defaultValueFinancing: inspectionFeeSetting.defaultFinancingAmount ?? 0,
        defaultValueCash: inspectionFeeSetting.defaultCashAmount ?? 0,
        account: getAccount(inspectionFeeSetting.accountId)
      },
      plateTransferFeeSetting: {
        ...plateTransferFeeSetting,
        defaultValueFinancing: plateTransferFeeSetting.defaultFinancingAmount ?? 0,
        defaultValueCash: plateTransferFeeSetting.defaultCashAmount ?? 0,
        account: getAccount(plateTransferFeeSetting.accountId)
      },
      registrationFeeSetting: {
        ...registrationFeeSetting,
        defaultValueFinancing: registrationFeeSetting.defaultFinancingAmount ?? 0,
        defaultValueCash: registrationFeeSetting.defaultCashAmount ?? 0,
        account: getAccount(registrationFeeSetting.accountId)
      },
      titleFeeSetting: {
        ...titleFeeSetting,
        defaultValueFinancing: titleFeeSetting.defaultFinancingAmount ?? 0,
        defaultValueCash: titleFeeSetting.defaultCashAmount ?? 0,
        account: getAccount(titleFeeSetting.accountId)
      }
    },
    frontGrossSalesCommissionSetting: {
      ...frontGrossSalesCommissionSetting,
      percent: frontGrossSalesCommissionSetting.percent != null
        ? decimalToPercent(frontGrossSalesCommissionSetting.percent)
        : null
    },
    backGrossSalesCommissionSetting: {
      ...backGrossSalesCommissionSetting,
      percent: backGrossSalesCommissionSetting.percent != null
        ? decimalToPercent(backGrossSalesCommissionSetting.percent)
        : null
    }
  }
}

export default parseFeeMarkupSettingsDto
