import { UI } from '@carfluent/common'

export const DEFAULT_SORTING: UI.SortingInfo = { id: 'number', order: 'desc' }

export enum Messages {
  ChangesDiscarded = 'Changes discarded',
  EmptyTableState = 'No open balances',
  NothingFoundState = 'No records found',
  SubmitSuccess = 'Opening balances saved successfully.'
}
