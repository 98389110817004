import type { DictionaryItem, WorkPlanEventDto } from 'api/types'

type Reason = 'update' | 'delete' | 'add'

interface GetUpdatedMap {
  map: Map<DictionaryItem, WorkPlanEventDto[]>
  leadStatus?: DictionaryItem | null
  rule: WorkPlanEventDto
  reason: Reason
}

const updateValue = (data: WorkPlanEventDto[], _event: WorkPlanEventDto): WorkPlanEventDto[] => {
  const newValue = data.map(event => {
    if (event.id === _event.id) {
      return _event
    }

    return event
  })

  newValue.sort((a, b) => (a.executionDay ?? 0) - (b.executionDay ?? 0))

  return newValue
}

const deleteValue = (data: WorkPlanEventDto[], _event: WorkPlanEventDto): WorkPlanEventDto[] => {
  const newData = [...data]
  const index = newData.findIndex(({ id }) => _event.id === id)
  newData.splice(index, 1)
  newData.sort((a, b) => (a.executionDay ?? 0) - (b.executionDay ?? 0))

  return newData
}

const addValue = (data: WorkPlanEventDto[], _event: WorkPlanEventDto): WorkPlanEventDto[] => {
  const newValue = [...data, _event]
  newValue.sort((a, b) => (a.executionDay ?? 0) - (b.executionDay ?? 0))

  return newValue
}

const updateMapper: Record<Reason, (data: WorkPlanEventDto[], rule: WorkPlanEventDto) => WorkPlanEventDto[]> = {
  update: updateValue,
  delete: deleteValue,
  add: addValue
}

export const getUpdatedMap = ({
  map,
  leadStatus,
  rule,
  reason
}: GetUpdatedMap): Map<DictionaryItem, WorkPlanEventDto[]> => {
  return Array.from(map.entries()).reduce((res: Map<DictionaryItem, WorkPlanEventDto[]>, [item, data]) => {
    if (item.id === leadStatus?.id) {
      res.set(item, updateMapper[reason](data, rule) ?? [])
    } else {
      res.set(item, data)
    }
    return res
  }, new Map())
}

export const getRulesPerStatus = (map: Map<DictionaryItem, WorkPlanEventDto[]>, leadStatus: DictionaryItem | null): WorkPlanEventDto[] | null => {
  return (
    Array
      .from(map.entries())
      .find(([item]) => item.id === leadStatus?.id) ?? []
  )[1] ?? null
}
