import { css } from '@emotion/css'

export default css(`
  border: 8px solid #F3F3F3;
  border-radius: 4px;
  background-color: #F3F3F3;
  margin-bottom: 24px;
  
  &.last-line-error {
    padding-bottom: 16px;
  }

  .cf-pack {
    &-checkbox {
      padding: 0;
      background-color: #fff;
      
      &-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #101010;
        margin-left: 10px;
      }
    }

    &-item-wrapper {
      padding: 6px;
      background-color: #fff;
      display: flex;
      flex-direction: row;

      .MuiFormControlLabel-root {
        margin: 0;
      }

      .cf-checkbox-icon {
        padding: 10px;
      }

    }
  }

  .MuiFilledInput-underline {
    background-color: #FFF !important;
  }
      
  .cf-item-wrapper-dropdown {
    margin: 8px 0;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .cf-pack-dropdown {
    p.Mui-error{
      position: inherit;
      margin-top: 0
    }
  }

  .cf-pack-input {
    p.MuiFormHelperText-root {
      position: inherit;
      margin-top: 0
    }
  }
`)
