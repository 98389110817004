import { type FC } from 'react'

import OverwritableInput from 'components/deals/OverwritableInput'

import CalculatedOrManualForm from './components/CalculatedOrManualForm'
import { type CalculatedOrManualInputProps } from './types'

export const CalculatedOrManualInput: FC<CalculatedOrManualInputProps> = ({
  manualValue,
  ...formProps
}) => {
  const { isOverriden, getCalculatedAmount } = formProps

  const getValue = (): number | null => {
    return isOverriden ? manualValue : getCalculatedAmount()
  }

  // ========================================== //

  return (
    <OverwritableInput
      {...formProps}
      editButtonEl='Edit'
      formatFieldHelper={formatFieldHelper}
      getValue={getValue}
      id={`calculated-or-manual-${formProps.fieldPathManual}`}
      renderForm={CalculatedOrManualForm}
      values={manualValue}
    />
  )
}

export default CalculatedOrManualInput

// ========================================== //

export const formatFieldHelper = (_: unknown, isManual: boolean): string | null => {
  if (isManual) {
    return 'Manually entered amount'
  }

  return null
}
