import { type FC, useState, useEffect, useRef } from 'react'
import type { Row } from '@tanstack/react-table'
import { CellWrapper, parsers } from '@carfluent/common'

import type { ReconVehicleComment, ReconVehicleListItemResponseDto } from 'api/types'
import { formatDate } from 'utils/parse_date'
import { DATE_US_SHORT_HOUR_AMPM_FORMAT } from 'constants/constants'
import CLASS_NAME, {
  CommentTextStyle,
  MAX_TOTAL_HEIGHT,
  HEADER_WITH_PADDING_HEIGHT,
  TEXT_LINE_HEIGHT,
  ROW_GAP,
  getMaxTextLines,
  getMaxTextHeight
} from './styles'

const { parseDateStringUTC } = parsers

export interface ReconCarCommentsCellProps {
  row: Row<ReconVehicleListItemResponseDto>
  refreshCommentTrigger: number
}

interface CommentForRender {
  item?: ReconVehicleComment
  styles?: CommentTextStyle
}

const renderCommentContent = (item: ReconVehicleComment, styles?: CommentTextStyle): JSX.Element => (
  <>
    <span className='cf-recon-car-comment-name'>{item.userName}</span>
    <span className='cf-recon-car-comment-date'>
      {formatDate(parseDateStringUTC(item.createdDate) ?? new Date(item.createdDate), DATE_US_SHORT_HOUR_AMPM_FORMAT)}
    </span>
    <div className='cf-recon-car-comment-text'>
      <p style={(styles != null ? { maxHeight: styles.textMaxHeight, WebkitLineClamp: styles.textMaxLines } : undefined)}>
        {item.body}
      </p>
    </div>
  </>
)

const ReconCarCommentsCell: FC<ReconCarCommentsCellProps> = ({ row, refreshCommentTrigger }) => {
  const comments = row.original.comments ?? []

  const refLastComment = useRef<HTMLDivElement>(null)
  const refHeightUsed = useRef<number>(0)
  const refRefreshCommentTrigger = useRef(refreshCommentTrigger)

  const [commentsForRender, setCommentsForRender] = useState<CommentForRender[]>([{ item: comments[0] }])

  useEffect(() => {
    if (refRefreshCommentTrigger.current !== refreshCommentTrigger) {
      refRefreshCommentTrigger.current = refreshCommentTrigger
      setCommentsForRender([{ item: comments[0] }])

      return
    }

    if (refLastComment.current == null || comments.length <= commentsForRender.length) {
      return
    }

    const { height } = refLastComment.current.getBoundingClientRect()

    refHeightUsed.current += height + ROW_GAP
    const heightLeft = MAX_TOTAL_HEIGHT - refHeightUsed.current

    /**
     * we calculate if it is enough space for another header and at least one line of comment
    */
    if (heightLeft > HEADER_WITH_PADDING_HEIGHT + TEXT_LINE_HEIGHT) {
      const heightLeftForText = heightLeft - HEADER_WITH_PADDING_HEIGHT
      const nextCommentIdx = commentsForRender.length

      setCommentsForRender(prev => {
        return prev.concat({
          item: comments[nextCommentIdx],
          styles: {
            containerMaxHeight: `${heightLeft}px`,
            textMaxHeight: `${getMaxTextHeight(heightLeftForText)}px`,
            textMaxLines: getMaxTextLines(heightLeftForText)
          }
        })
      })
    }
  }, [commentsForRender, comments, refreshCommentTrigger])

  if (comments.length === 0) {
    return null
  }

  return (
    <CellWrapper className={CLASS_NAME}>
      {
        commentsForRender.map((item, i) => {
          if (item.item == null) {
            return null
          }

          return (
            <div
              className='cf-recon-car-comment'
              key={item.item.id}
              ref={i === commentsForRender.length - 1 ? refLastComment : undefined}
              style={{ maxHeight: item.styles != null ? item.styles.containerMaxHeight : undefined }}
            >
              {renderCommentContent(item.item, item.styles)}
            </div>
          )
        })
      }
    </CellWrapper>
  )
}

export default ReconCarCommentsCell
