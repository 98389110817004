import { css } from '@emotion/css'
import { px, Responsive } from 'constants/constants'

export default css`
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  
  > * {
    grid-column: span 2;
  }
  
  .half-item {
    grid-column: span 1;
  }

  .title-wrapper {
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.5px;
    margin-bottom: 30px;

    @media screen and (max-width: ${px(Responsive.Tablet)}) {
      font-size: 34px;
      line-height: 36px;
      margin-bottom: 24px;
    }
  }
  
  .setup-account-api-errors { margin-bottom: 54px; }

  .show-hide {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.38);
    cursor: pointer;
  }

  .password-wrapper > div:last-child { margin: 10px 0 12px 0; }
  .submit-btn span { text-transform: uppercase; }
`
