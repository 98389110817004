import { ProfitLossSubtypes, ProfitLossTypes } from 'api/types/accounting.types'
import fileNames from 'utils/reports/fileNames'

export const GROUP_TITLES = {
  [ProfitLossTypes.Profit]: 'Profits',
  [ProfitLossTypes.Expense]: 'Expenses',
  [ProfitLossTypes.NetProfit]: 'Net Profit'
}

export const GROUP_SUBTITLES = {
  [ProfitLossSubtypes.CostOfGoodsSold]: 'Cost of Goods Sold',
  [ProfitLossSubtypes.Income]: 'Income'
}

export const DEFAULT_DATA_TABLE = {
  types: ProfitLossTypes,
  titles: GROUP_TITLES
}

export const REPORT_FILE_NAMES = fileNames('ProfitLossReport')
