import type { FC } from 'react'
import type { IconProps } from 'types'

const LeadLostIcon: FC<IconProps> = ({ color = '#21212180', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_86554_8660)'>
        <path d='M7.9987 1.3335C4.3187 1.3335 1.33203 4.32016 1.33203 8.00016C1.33203 11.6802 4.3187 14.6668 7.9987 14.6668C11.6787 14.6668 14.6654 11.6802 14.6654 8.00016C14.6654 4.32016 11.6787 1.3335 7.9987 1.3335ZM7.9987 13.3335C5.05203 13.3335 2.66536 10.9468 2.66536 8.00016C2.66536 6.76683 3.08536 5.6335 3.79203 4.7335L11.2654 12.2068C10.3654 12.9135 9.23203 13.3335 7.9987 13.3335ZM12.2054 11.2668L4.73203 3.7935C5.63203 3.08683 6.76536 2.66683 7.9987 2.66683C10.9454 2.66683 13.332 5.0535 13.332 8.00016C13.332 9.2335 12.912 10.3668 12.2054 11.2668Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_86554_8660'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LeadLostIcon
