import { type KeyVal } from 'types'
import { joinPartsBySpace } from 'utils/view_helper'
import getKeyWithPrefix from 'utils/common/getKeyWithPrefix'
import getValueByKeys from 'utils/common/getValueByKeys'

const YEAR = 'Year'
const MAKE = 'Make'
const MODEL = 'Model'
const TRIM = 'Trim'

/**
 * Bakes string with car description in traditional format:
 * `${YEAR} ${MAKE} ${MODEL} ${TRIM}`.
 *
 * Tries to find appropriate arguments with or without prefix, if first argument is an object.
 * Sends drones, launches space shuttles and brings coffee.
 * Sometimes to the correct destinations.
 */
function getVehicleName (year: string | number | null, make: string | null, model: string | null, trim: string | null): string | null
function getVehicleName (data: KeyVal | null, prefix?: string): string | null
function getVehicleName (firstArg: any, lastArg: string | null | undefined, ...rest: any[]): string | null {
  if (firstArg == null) {
    return null
  }

  if (typeof firstArg === 'object') {
    const prefix = lastArg ?? ''
    const year = getValueByKeys(getKeyWithPrefix(YEAR, prefix), firstArg)
    const make = getValueByKeys(getKeyWithPrefix(MAKE, prefix), firstArg)
    const model = getValueByKeys(getKeyWithPrefix(MODEL, prefix), firstArg)
    const trim = getValueByKeys(getKeyWithPrefix(TRIM, prefix), firstArg)

    return joinPartsBySpace(year, make, model, trim)
  }

  return joinPartsBySpace(firstArg, lastArg, ...rest)
}

export default getVehicleName
