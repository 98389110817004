import { useCallback, useState, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useModal, useLoader } from '@carfluent/common'

import CRMApiProvider from 'api/crm.api'
import { GET_DEFAULT_CAMPAIGN_DETAILS } from 'api/defaults'
import { type CampaignModel } from 'api/types/responses'
import { CampaignSteps } from 'types/enums'
import { Routes } from 'constants/route_helper'
import useAsyncEffect from 'hooks/useAsyncEffect'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import isCampaignSent from 'utils/crm/isCampaignSent'
import isCampaignStarted from 'utils/crm/isCampaignStarted'
import isCampaignPending from 'utils/crm/isCampaignPending'
import { type ActionOptions } from 'components/common/ActionsMenu'
import BlankWindow from 'components/common/BlankWindow'
import { SubmenuVisibilityCTX } from 'pages/crm/Settings'

import { type UseCampaignReturn } from './types'
import serializeCampaign from './serializer'
import { EMAIL_PREVIEW_CLASSES } from '../styles'
import {
  STEPS_ORDERED,
  GET_DEFAULT_STEP_STATE,
  GET_DEFAULT_FORM_CHANGES,
  INTENTIONALLY_WRONG_ID
} from './constants'

const useCampaign = (): UseCampaignReturn => {
  const navigate = useNavigate()
  const modalDeleteProps = useModal()
  const { isLoading, startLoader, stopLoader } = useLoader()
  const { showAlert, showSuccess } = useCustomSnackbar()
  const setSubmenuIsVisible = useContext(SubmenuVisibilityCTX)

  const { id: idSlug } = useParams<{ id: string }>()
  const isCreateMode = idSlug === 'new'

  const [currentStepId, setCurrentStepId] =
    useState<number>(isCreateMode ? CampaignSteps.CampaignInformation : CampaignSteps.Statistics)

  /**
   * Originally loaded campaign, needed for state reset.
   */
  const [originalCampaign, setOriginalCampaign] =
    useState(GET_DEFAULT_CAMPAIGN_DETAILS)

  /**
   * Current (unsaved) campaign, needed for state restoration,
   * when switch between screens.
   */
  const [currentCampaign, setCurrentCampaign] =
    useState(GET_DEFAULT_CAMPAIGN_DETAILS)

  const [timeZoneDisplayName, settImeZoneDisplayName] =
    useState<string | null>(null)

  const [recipientsCount, setRecipientsCount] =
    useState<number | null>(null)

  const [formsUnsavedChanges, setFormsUnsavedChanges] =
    useState(GET_DEFAULT_FORM_CHANGES)

  const hasUnsavedChanges = Object.values(formsUnsavedChanges).some(Boolean)
  const isEditMode = !isCreateMode && (currentStepId !== CampaignSteps.Statistics)

  const {
    campaignStatusId,
    contentDetails
  } = originalCampaign

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  /**
   * Switches to the step of choice and restores its last changes,
   * that were submitted by click on the step's "Next" button.
   */
  const restoreStep = useCallback((stepId: number) => {
    /**
     * TODO: enhance this logic when `UnsavedChanges` popup will be added.
     * This callback should only trigger start of chain, instead of immediate update of currentStepId
     */
    setCurrentStepId(stepId)

    const lastSavedStepData = GET_DEFAULT_STEP_STATE(stepId, currentCampaign)
    setCurrentCampaign(prev => ({ ...prev, ...lastSavedStepData }))
  }, [currentStepId, currentCampaign])

  /**
   * Click on the "Next" should save state of the current step
   * and open a next step.
   */
  const onRequestNextStep = useCallback((data?: Partial<CampaignModel>) => {
    const nextStepId: CampaignSteps = Math.min(currentStepId + 1, STEPS_ORDERED.length - 1)

    /**
     * TODO: enhance this logic when `UnsavedChanges` popup will be added.
     * This callback should only trigger start of chain, instead of immediate update of currentStepId
     */
    setCurrentStepId(nextStepId)

    if (data != null) {
      setCurrentCampaign((prev) => ({ ...prev, ...data }))
    }
  }, [currentStepId])

  /**
   * Click on "Previous" should reset state of the current step
   * and open previous step.
   */
  const onRequestPreviousStep = useCallback(() => {
    restoreStep(Math.max(currentStepId - 1, 0))
  }, [currentStepId, restoreStep])

  const onEditCampaignInformation = useCallback(() => {
    restoreStep(CampaignSteps.CampaignInformation)
  }, [restoreStep])

  const onEditRecipients = useCallback(() => {
    restoreStep(CampaignSteps.Recipients)
  }, [restoreStep])

  const onSendCampaign = useCallback(async (data: Partial<CampaignModel>): Promise<CampaignModel> => {
    const nextCurrentCampaign = { ...currentCampaign, ...data }
    const payload = serializeCampaign(nextCurrentCampaign)

    const result = (nextCurrentCampaign.id == null)
      ? await CRMApiProvider.createCampaign(payload)
      : await CRMApiProvider.updateCampaign(currentCampaign.id ?? INTENTIONALLY_WRONG_ID, payload)

    setCurrentCampaign(result)
    setFormsUnsavedChanges(GET_DEFAULT_FORM_CHANGES())
    return result
  }, [currentCampaign, showAlert])

  const onGoBack = useCallback(() => {
    navigate(Routes.CRMMarketingCampaigns)
  }, [navigate])

  const onDeleteCampaign = useCallback(() => {
    const doJob = async (): Promise<void> => {
      if (idSlug == null) {
        return
      }

      try {
        await CRMApiProvider.deleteCampaign(idSlug, originalCampaign.rowVersion)
        setFormsUnsavedChanges(GET_DEFAULT_FORM_CHANGES())
        modalDeleteProps.onCloseModal()
        showSuccess('Campaign was deleted.')
        navigate(Routes.CRMMarketingCampaigns)
      } catch (err) {
        showAlert(err)
      }
    }

    void doJob()
  }, [
    idSlug,
    modalDeleteProps.onCloseModal,
    originalCampaign.rowVersion,
    navigate,
    showSuccess,
    showAlert
  ])

  const onPreviewEmail = useCallback((): void => {
    const wnd = BlankWindow({ styles: EMAIL_PREVIEW_CLASSES })
    wnd.showBody(contentDetails.bodyHtml ?? '')
    wnd.focus()
  }, [contentDetails.bodyHtml])

  const onChangeUnsavedChanges = useCallback((hasChanges: boolean): void => {
    setFormsUnsavedChanges(prev => ({
      ...prev,
      [currentStepId]: hasChanges
    }))
  }, [currentStepId])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Loads campaign data or sets it to default state.
   */
  useAsyncEffect(async () => {
    try {
      let nextOriginalCampaign = GET_DEFAULT_CAMPAIGN_DETAILS()

      if (!isCreateMode && (idSlug != null)) {
        startLoader()

        nextOriginalCampaign = await CRMApiProvider.getCampaign(idSlug)
        if (nextOriginalCampaign.statistics.totalSentCount == null) {
          const recipientsCount = await CRMApiProvider.getCampaignRecipientsCount(idSlug)
          setRecipientsCount(recipientsCount)
        }

        stopLoader()
      }

      setOriginalCampaign(nextOriginalCampaign)
      setCurrentCampaign(nextOriginalCampaign)
    } catch (err) {
      showAlert(err)
    }
  }, [idSlug, isCreateMode, startLoader, stopLoader])

  /**
   * Loads TimeZone caption for review screen.
   * AZ-TODO: should be loaded in one place, globally.
   */
  useAsyncEffect(async (): Promise<void> => {
    try {
      const response = await CRMApiProvider.getCrmSettings()
      settImeZoneDisplayName(response.timeZoneDisplayName ?? null)
    } catch (err) {
      showAlert(err)
    }
  }, [startLoader, stopLoader])

  /**
   * Hides/shows Submenu from parent layout, depending on Campaign's mode.
   */
  useEffect(() => {
    setSubmenuIsVisible(!isCreateMode && !isEditMode)

    return () => { setSubmenuIsVisible(true) }
  }, [isCreateMode, isEditMode, setSubmenuIsVisible])

  // ========================================== //

  const isEditaDisabled = !isCampaignPending(campaignStatusId)
  const isEditTooltipVisible = isCampaignSent(campaignStatusId) || isCampaignStarted(campaignStatusId)
  const isDeleteDisabled = isCampaignSent(campaignStatusId) || isCampaignStarted(campaignStatusId)

  const topMenuActions: ActionOptions[] = [
    {
      disabled: isEditaDisabled,
      handleOnClick: onEditCampaignInformation,
      title: 'Edit',
      tooltip: isEditTooltipVisible
        ? 'Sent or Started campaign cannot be edited/deleted.'
        : null
    },
    {
      handleOnClick: onPreviewEmail,
      title: 'Preview email'
    },
    {
      disabled: isDeleteDisabled,
      handleOnClick: modalDeleteProps.onOpenModal,
      optionType: 'highlighted',
      title: 'Delete',
      tooltip: isDeleteDisabled
        ? 'Sent or Started campaign cannot be edited/deleted.'
        : null
    }
  ]

  return {
    actions: topMenuActions,
    campaign: currentCampaign,
    currentStepId,
    hasUnsavedChanges,
    isCreateMode,
    isEditMode,
    isLoading,
    modalDeleteProps,
    onChangeUnsavedChanges,
    onDeleteCampaign,
    onEditCampaignInformation,
    onEditRecipients,
    onGoBack,
    onRequestNextStep,
    onRequestPreviousStep,
    onSendCampaign,
    recipientsCount,
    timeZoneDisplayName
  }
}

export default useCampaign
