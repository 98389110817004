import { type FC } from 'react'
import { FormDropdown, FormNumberInput } from '@carfluent/common'

import TradeInDetailsWithDropdowns from 'components/deals/TradeInDetails/TradeInDetailsWithDropdowns'
import type { VehicleOption } from 'components/common/VehiclesDropdownOption/types'
import IconSVG from 'components/inlineImages'
import CopyButton from 'components/common/CopyButton'
import WithTooltip from 'components/common/WithTooltip'

import { renderNoVehiclesOption, renderVehicleOption } from 'components/optionRenderers/vehiclesDropdown'
import { formatVehicleDisplayName } from 'utils/vehicleDropdownHelpers'
import { isVehicleOptionDisabled } from 'utils/isVehicleOptionDisabled'
import { ValidationLength } from 'constants/validation'

import ActionsFooterAndUnsavedChanges from '../ActionsFooterAndUnsavedChanges'
import type { VehiclesFormProps } from './types'
import useVehiclesTab from './hook'
import CLASS_NAME from './styles'

const GET_COLLAPSE_BTN_TEXT = (): string => 'REMOVE TRADE IN'
const RESTRICT_CHANGE_VEHICLE_TITLE = 'Car cannot be changed because the deal is Sold or On deposit. Undo these actions first.'

const VehicleForm: FC<VehiclesFormProps> = ({ isDeposited = false, ...props }) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    hasChanges,
    isUnsavedChangesShown,
    onOpenUnsavedChanges,
    onCloseUnsavedChanges,
    onBlur,
    onChange,
    onVehicleChange,
    onSubmit,
    resetForm,
    onApplyVin,
    getVehicles,
    onChangeSectionVisibility,
    showConfirmation,
    isRestrictedChangeVehicle
  } = useVehiclesTab(props)

  const { vehicle, tradeInDetails } = values

  let carDetails = [vehicle.year, vehicle.make, vehicle.model, vehicle.trim].filter(v => v).join(' ')
  carDetails = carDetails === '' ? '-' : carDetails
  const carVim = vehicle.vin ?? '-'
  const carStock = vehicle.stock ?? '-'

  return (
    <div className={CLASS_NAME}>
      <div className='vehicle-inputs'>
        <WithTooltip
          withTooltip={isRestrictedChangeVehicle}
          title={RESTRICT_CHANGE_VEHICLE_TITLE}
        >
          <FormDropdown<VehicleOption>
            id='vehicle'
            className='deal-vehicle-dropdown'
            popoverClassName='deal-vehicle-dropdown-popover'
            placeholder='Add car by VIN, Stock #, Make, Model'
            startAdornment={<span> <IconSVG.SearchIcon /> </span>}
            onChange={onVehicleChange}
            isOptionDisabled={(option) => isVehicleOptionDisabled(option.value)}
            onBlur={onBlur}
            disableClearable
            blurMode='select-prev'
            value={vehicle as unknown as VehicleOption}
            fetchHandler={getVehicles}
            formatDisplayValue={formatVehicleDisplayName}
            renderNoOptions={renderNoVehiclesOption}
            renderOption={renderVehicleOption}
            disabled={isRestrictedChangeVehicle}
          />
        </WithTooltip>

        <FormNumberInput
          id='actualMileage'
          label='Actual mileage'
          value={values.actualMileage}
          error={errors.actualMileage}
          touched={touched.actualMileage}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isRestrictedChangeVehicle}
          maxLength={ValidationLength.MILEAGE_MAX}
        />
      </div>

      <div className='vehicle-info-section'>
        <p>
          <span>Car:</span>
          <span>{carDetails}</span>
        </p>
        <p>
          <span>VIN:</span>
          <span>{carVim}</span>
          <CopyButton nameOfField='VIN' value={carVim} showConfirmation={showConfirmation} />
        </p>
        <p>
          <span>Stock #:</span>
          <span>{carStock}</span>
          <CopyButton nameOfField='Stock #' value={carStock} showConfirmation={showConfirmation} />
        </p>
      </div>

      <hr />

      <div className='trade-in-section'>
        <TradeInDetailsWithDropdowns
          className='trade-in-details-content'
          fieldPrefix='tradeInDetails'
          values={tradeInDetails}
          errors={errors.tradeInDetails}
          touched={touched.tradeInDetails}
          isTradeInSectionVisible={values.isTradeInSectionVisible ?? false}
          isDisabled={isRestrictedChangeVehicle || isSubmitting}
          getCollapseBtnText={GET_COLLAPSE_BTN_TEXT}
          showAcvField
          onApplyVin={onApplyVin}
          onToggleIsExpanded={onChangeSectionVisibility}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>

      <ActionsFooterAndUnsavedChanges
        isSubmitting={isSubmitting}
        isUnsavedChangesShown={isUnsavedChangesShown}
        isActionsFooterShown={hasChanges}
        shouldBlock={hasChanges}
        onSubmit={onSubmit}
        onCancel={() => resetForm()}
        onOpen={onOpenUnsavedChanges}
        onClose={onCloseUnsavedChanges}
        onDontSaveAndClose={props.onTrySwitchTab}
        onResetTabNavigation={props.onResetTabNavigation}
      />
    </div>
  )
}

export default VehicleForm
