import { useCallback, useEffect, useRef, useState } from 'react'
import { useModal } from '@carfluent/common'

import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { decimalToPercent, percentToDecimal, roundCurrency } from 'utils/math'

import { type UseTaxEditorProps, type UseTaxEditorReturn } from './types'

const useTaxEditor = ({
  getTaxAmount,
  id,
  onChange,
  onSubmitTax: _onSubmitTax,
  value: originalValue
}: UseTaxEditorProps): UseTaxEditorReturn => {
  const { showAlert } = useCustomSnackbar()
  const { isModalOpen, onCloseModal, onOpenModal } = useModal()

  const [taxValue, setTaxValue] =
    useState(originalValue)

  const [amountValue, setAmountValue] =
    useState(roundCurrency(getTaxAmount(originalValue)))

  const refTaxValue = useRef(taxValue)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onChangeInput = useCallback((_, value) => {
    const newTaxValue = value == null ? null : percentToDecimal(value)
    const nextAmountValue = value == null ? null : roundCurrency(getTaxAmount(newTaxValue))
    refTaxValue.current = newTaxValue
    setTaxValue(newTaxValue)
    setAmountValue(nextAmountValue ?? 0)
  }, [getTaxAmount])

  const onSubmitTax = (): void => {
    const doJob = async (): Promise<void> => {
      try {
        await _onSubmitTax(taxValue ?? 0)

        onChange(id, taxValue)
        onCloseModal()
      } catch (err) {
        showAlert(err)
      }
    }

    void doJob()
  }

  const onClose = (): void => {
    const originalTaxValue = decimalToPercent(originalValue ?? 0)
    onChangeInput(id, originalTaxValue)
    onCloseModal()
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  const refGetTaxAmount = useRef(getTaxAmount)
  useEffect(() => {
    const isValueExternallyChanged = originalValue !== refTaxValue.current
    const isFormulaChanged = refGetTaxAmount.current !== getTaxAmount

    if (isValueExternallyChanged || isFormulaChanged) {
      const originalTaxValue = decimalToPercent(originalValue ?? 0)
      refGetTaxAmount.current = getTaxAmount
      onChangeInput(id, originalTaxValue)
    }
  }, [id, originalValue, onChangeInput])

  // ========================================== //

  return {
    amountValue,
    isModalOpen,
    onChangeInput,
    onCloseModal: onClose,
    onOpenModal,
    onOverrideData: onSubmitTax,
    taxValue
  }
}

export default useTaxEditor
