import { type FC } from 'react'
import { Loader, cnx, Banner } from '@carfluent/common'

import { type DictionaryItem } from 'api/types'
import IconSVG from 'components/inlineImages'

import ActionsFooterAndUnsavedChanges from '../ActionsFooterAndUnsavedChanges'
import FilterRowOptions from './components/FilterRowOptions'
import AddFilters from './components/AddFilters'
import { type UseStepRecipientsProps } from './hook/types'
import useStepRecipients from './hook'
import CLASS_NAME from './styles'

const EMPTY_OPTIONS: DictionaryItem[] = []

interface StepRecipientsProps extends UseStepRecipientsProps {
  className?: string
  canDeleteCampaign: boolean
  onDeleteCampaign: () => void
}

const StepRecipients: FC<StepRecipientsProps> = ({
  canDeleteCampaign,
  className,
  onDeleteCampaign,
  ...hookProps
}) => {
  const {
    errors,
    touched,
    statuses,
    sources,
    temperatures,
    values,
    recipientsCount,
    filterItems,
    selectedFilter,
    anchorEl,
    isDisabledAddFilterButton,
    isCounterLoading,
    error,
    onCloseFilter,
    onAddFilter,
    onCancel,
    onGoBack,
    onRemoveFilter,
    onBlur,
    onChange,
    onGoNext,
    unsavedChangesProps
  } = useStepRecipients(hookProps)

  return (
    <div className={cnx(CLASS_NAME, 'campaign-form', className)}>
      <Banner
        isOpen={error != null}
        message={error}
        type='error'
      />

      <span className='notes'>
        By default, the campaign is sent to leads except unsubscribed and leads without emails,
        but you can specify recipients by adding filters.
      </span>

      <div className='current-recipients-title'>
        <h4>Recipients:</h4>
        <div className='recipients-counter'>
          <IconSVG.People />
          <span>
            {
              isCounterLoading
                ? <Loader className='loader' size='small' />
                : (recipientsCount ?? 0).toLocaleString('en-US')
            }
          </span>
        </div>
      </div>

      <div className='container'>
        <div className='form'>

          {(values.filterLeadStatus != null) && (
            <FilterRowOptions
              values={values.filterLeadStatus}
              onChange={onChange}
              onBlur={onBlur}
              filterOptionName='filterLeadStatus'
              leadFilterLabel='Lead status'
              leadFilterOptions={statuses}
              errors={errors.filterLeadStatus}
              touched={touched.filterLeadStatus}
              disableSelectAll
              onRemoveFilter={onRemoveFilter}
            />
          )}

          {(values.filterLeadSource != null) && (
            <FilterRowOptions
              values={values.filterLeadSource}
              onChange={onChange}
              onBlur={onBlur}
              filterOptionName='filterLeadSource'
              leadFilterLabel='Lead source'
              leadFilterOptions={sources}
              errors={errors.filterLeadSource}
              touched={touched.filterLeadSource}
              onRemoveFilter={onRemoveFilter}
            />
          )}

          {(values.filterLeadTemperature != null) && (
            <FilterRowOptions
              values={values.filterLeadTemperature}
              onChange={onChange}
              onBlur={onBlur}
              filterOptionName='filterLeadTemperature'
              leadFilterLabel='Lead temperature'
              leadFilterOptions={temperatures}
              errors={errors.filterLeadTemperature}
              touched={touched.filterLeadTemperature}
              disableSelectAll
              onRemoveFilter={onRemoveFilter}
            />
          )}

          {(values.filterLeadWithScheduledAppointments != null) && (
            <FilterRowOptions
              values={values.filterLeadWithScheduledAppointments}
              onChange={onChange}
              onBlur={onBlur}
              filterOptionName='filterLeadWithScheduledAppointments'
              leadFilterLabel='Lead with scheduled appointments'
              leadFilterOptions={EMPTY_OPTIONS}
              errors={errors.filterLeadWithScheduledAppointments}
              touched={touched.filterLeadWithScheduledAppointments}
              onRemoveFilter={onRemoveFilter}
            />
          )}
        </div>

        <AddFilters
          onOpen={onAddFilter}
          onClose={onCloseFilter}
          anchorEl={anchorEl}
          filterItems={filterItems}
          selectedFilter={selectedFilter}
          disabled={isDisabledAddFilterButton}
        />
      </div>

      <ActionsFooterAndUnsavedChanges
        isDeleteShown={canDeleteCampaign}
        isSubmitting={isCounterLoading}
        onClose={onCancel}
        onDelete={onDeleteCampaign}
        onGoBack={onGoBack}
        onSubmit={onGoNext}
        unsavedChangesProps={unsavedChangesProps}
      />
    </div>
  )
}

export default StepRecipients
