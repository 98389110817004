import { css } from '@emotion/css'

interface StylesReturn {
  tooltip: string
  popper: string
  content: string
}

const createStyleClass = (numberOfRows: number): StylesReturn => {
  return {
    tooltip: css(`
      &.MuiTooltip-tooltip {
        font-family: Roboto;
        font-weight: 400;
        padding: 0;
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        background: none;
        color: #FFFFFF;
      }
    `),

    popper: css(`
      &.MuiTooltip-popper {
        z-index: 10001;
        background: #212121;
        border-radius: 4px;
        padding: 4px 12px;
      }
    `),

    content: css(`
        &.cf-tooltip-container {
          max-width: 100%;

        > p {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #101010;
          text-overflow: ellipsis;

          ${numberOfRows > 1
            ? `
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
            `
            : `
              white-space: nowrap;
              overflow: hidden;
            `
          }
    
          &:first-child: { margin-top: 0; }
        }
      }
    `)
  }
}

export default createStyleClass
