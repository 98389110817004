import type { FC, ReactNode } from 'react'
import { cn } from '@carfluent/common'

import CLASS_NAME from './styles'

export interface SectionLayoutProps {
  children: ReactNode | ReactNode[]
  className?: string
  title?: ReactNode
}

const SectionLayout: FC<SectionLayoutProps> = ({
  children,
  className,
  title
}) => (
  <div className={cn(CLASS_NAME, className)}>
    {(typeof title === 'string') ? <p className='cf-block-title'>{title}</p> : title}
    {children}
  </div>
)

export default SectionLayout
