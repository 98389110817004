import { type Preset, UI, dates, PRESET_LABELS, DEFAULT_MIN_DATE, DEFAULT_MAX_DATE } from '@carfluent/common'

import addMonths from 'date-fns/addMonths'
import addQuarters from 'date-fns/addQuarters'

import fileNames from 'utils/reports/fileNames'

export const DEFAULT_SORTING: UI.SortingInfo = { id: 'make', order: 'asc' }

export const API_CALL_DELAY_SEARCH = 3000

export enum Messages {
  emptyTableState = 'No records',
  nothingFoundState = 'No results found'
}

export const presetLabels = [
  PRESET_LABELS.ALL,
  PRESET_LABELS.TODAY,
  PRESET_LABELS.YESTERDAY,
  PRESET_LABELS.LAST_7_DAYS,
  PRESET_LABELS.LAST_14_DAYS,
  PRESET_LABELS.LAST_30_DAYS,
  PRESET_LABELS.THIS_WEEK,
  PRESET_LABELS.THIS_MONTH,
  PRESET_LABELS.PREVIOUS_MONTH
]

export const getPresets = (): Preset[] => {
  const date = new Date()
  const defaultPresets = UI.getDefaultDateRangePresets(DEFAULT_MIN_DATE, DEFAULT_MAX_DATE)
  const allPeriod = { ...defaultPresets[0], title: 'All period' }
  const prevMonday = dates.addDays(-7, dates.getWeekStart(date))
  const prevSunday = dates.addDays(6, prevMonday)
  const prevMonthStart = addMonths(dates.getMonthStart(date), -1)
  const prevMonthEnd = dates.getMonthEnd(prevMonthStart)
  const prevQuarterStart = addQuarters(dates.getQuarterStart(date), -1)
  const prevQuarterEnd = dates.getQuarterEnd(prevQuarterStart)

  const presets = [
    allPeriod,
    ...defaultPresets.slice(2),
    { name: PRESET_LABELS.PREVIOUS_WEEK, startDate: prevMonday, endDate: prevSunday },
    { name: PRESET_LABELS.PREVIOUS_MONTH, startDate: prevMonthStart, endDate: prevMonthEnd },
    { name: PRESET_LABELS.PREVIOUS_QUARTER, startDate: prevQuarterStart, endDate: prevQuarterEnd }
  ]

  return [
    ...presets.slice(0, 3),
    { name: PRESET_LABELS.LAST_7_DAYS, startDate: dates.addDays(-6, date), endDate: date },
    { name: PRESET_LABELS.LAST_14_DAYS, startDate: dates.addDays(-13, date), endDate: date },
    { name: PRESET_LABELS.LAST_30_DAYS, startDate: dates.addDays(-29, date), endDate: date },
    ...presets.slice(3)
  ].filter(period => presetLabels.includes(period.name as PRESET_LABELS))
}

export const REPORT_FILE_NAMES = fileNames('LeadsByVehicleReport')
export const ROW_HEIGHT = 57
export const TABLE_MIN_HEIGHT = 1140
