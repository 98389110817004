import type { FC } from 'react'

import IconSVG from 'components/inlineImages'

import CLASS_NAME from './styles'

const DragHereMessage: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <IconSVG.DragNDropIcon />

      <p>Drop your files here, or</p>

      <span>browse</span>
    </div>
  )
}

export default DragHereMessage
