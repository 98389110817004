import { type FocusEvent, type ChangeEvent, useMemo, useCallback, useContext, useEffect, useState, useRef } from 'react'
import { getEnhancedResultHandler, useForm } from '@carfluent/common'
import isEqual from 'lodash-es/isEqual'

import CRMApiProvider from 'api/crm.api'
import IdentityApiProvider from 'api/identity.api'
import { getParsedToken } from 'services/storage.service'
import { userRolesWithCRMPermission } from 'constants/constants'
import CalcRoutes from 'constants/route_helper'
import { calcTableHeight } from 'utils/math'
import parseUsers from 'utils/parseUsers'
import LeadsListDataCTX from 'pages/crm/LeadsList/hook/store'
import { UNASSIGNED_OPTION } from 'pages/crm/LeadDetailsView/hook/constants'
import { parseAssignedUser } from 'pages/crm/LeadDetailsView/hook/parser'
import useEffectOnce from 'hooks/useEffectOnce'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useScrolledToBottom from 'hooks/useScrolledToBottom'

import type {
  DuplicateLeadDto,
  ErrTouchShortcuts,
  UpdateLeadFormData,
  UseLeadDetailsModalProps,
  UseLeadDetailsModalReturn
} from './types'
import {
  getDefaultFormData,
  FIELD_TRANSFORMS,
  FieldIds,
  HOT_TEMPERATURE_ID,
  getDefaultSearchState,
  isSearchableField
} from './constants'
import validationRules, { DEPENDENT_VALIDATIONS } from './validator'
import serializeData from './serializer'
import transformLead from './utils'
import getColumnDefinitions from './columns'

const ROW_HEIGHT = 87
const TABLE_MIN_HEIGHT = 1892
const PAGE_SIZE = 20
const DEFAULT_FORM_DATA = getDefaultFormData()

const useLeadDetailsFormModal = ({
  isOpen,
  lead,
  onClose: _onClose,
  onUpdateAction,
  onCreateAction
}: UseLeadDetailsModalProps): UseLeadDetailsModalReturn => {
  const isUpdate = lead != null
  const { showAlert } = useCustomSnackbar()
  const {
    sources,
    temperatures,
    users,
    setSources,
    setTemperatures,
    setUsers
  } = useContext(LeadsListDataCTX)

  const assignedTo = useMemo(() => [UNASSIGNED_OPTION, ...parseUsers(users, 'Unassigned')], [users])

  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [duplicateLeads, setDuplicateLeads] = useState<DuplicateLeadDto[]>([])
  const [totalCounter, setTotalCounter] = useState<number>(0)
  const [isLoading, setLoading] = useState(false)

  const refSearch = useRef(getDefaultSearchState())
  const refModalContainer = useRef<HTMLElement | null>(null)

  // ========================================== //
  //                   TABLE                    //
  // ========================================== //

  const flushDuplicateLeads = (): void => {
    setDuplicateLeads([])
    setTotalCounter(0)
  }

  const onOpenLeadDetails = useCallback((leadId: number) => {
    const url = CalcRoutes.LeadView(leadId)
    window.open(url, '_blank')
  }, [])

  const getDuplicateLeads = useCallback(async (forceRefresh: boolean = true) => {
    setLoading(true)

    const skip = forceRefresh ? 0 : duplicateLeads.length

    const listPayload = {
      take: PAGE_SIZE,
      skip,
      ...refSearch.current
    }

    try {
      const { items } = await CRMApiProvider.getDuplicateLeads(listPayload)
      const { all } = await CRMApiProvider.getDuplicateLeadsTotal(refSearch.current)

      setDuplicateLeads((prevRows) => forceRefresh ? items : [...prevRows, ...items])
      setTotalCounter(all)
    } catch {
      flushDuplicateLeads()
    } finally {
      setLoading(false)
    }
  }, [duplicateLeads.length])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onSearch = useCallback(() => {
    /**
     * do not sent empty search state
     */
    if (isEqual(refSearch.current, getDefaultSearchState())) {
      flushDuplicateLeads()
      return
    }

    void getDuplicateLeads()
  }, [getDuplicateLeads])

  const submitAction = useCallback(async (values: UpdateLeadFormData) => {
    try {
      setIsFormSubmitting(true)

      const data = serializeData(values)

      if (lead?.id != null) {
        await CRMApiProvider.updateLead({ ...data, leadId: lead.id })
        await onUpdateAction?.()
      } else {
        const createdLeadId = await CRMApiProvider.createLead({ ...data, tasks: [] })
        onCreateAction?.(createdLeadId)
      }
    } catch {
      showAlert(`Lead ${lead?.id == null ? 'creation' : 'update'} failed`)
    } finally {
      setIsFormSubmitting(false)
    }
  }, [showAlert, onUpdateAction, onCreateAction, lead?.id])

  const deleteAction = useCallback(async () => {
    await CRMApiProvider.deleteLead(lead?.id ?? '')
  }, [lead?.id])

  const _onActionResult = useCallback((_, resetForm) => {
    resetForm()
    flushDuplicateLeads()
    refSearch.current = getDefaultSearchState()
    _onClose?.()
  }, [_onClose])

  const onActionResult = useMemo(() => {
    return getEnhancedResultHandler<UpdateLeadFormData, ErrTouchShortcuts>(_onActionResult, showAlert)
  }, [_onActionResult, showAlert])

  const form = useForm<UpdateLeadFormData, ErrTouchShortcuts>({
    transforms: FIELD_TRANSFORMS,
    baseValues: DEFAULT_FORM_DATA,
    validationRules,
    onActionResult,
    submitAction,
    deleteAction
  })

  const {
    values,
    errors,
    touched,
    onChange: _onChange,
    onBlur: _onBlur,
    onSubmit,
    resetForm,
    validateField
  } = form

  const onBlur = useCallback((evOrField: FocusEvent<HTMLElement> | string): void => {
    const id = typeof evOrField === 'string'
      ? evOrField
      : evOrField.target.getAttribute('id')

    if (id == null) {
      return
    }

    if (isSearchableField(id)) {
      onSearch()
    }

    _onBlur(id)
  }, [_onBlur, onSearch])

  const onChange = useCallback((
    evOrField: ChangeEvent<HTMLInputElement> | string, value?: unknown
  ): void => {
    const [id, val] = typeof evOrField === 'string'
      ? [evOrField, value]
      : [evOrField.target.getAttribute('id') as string, evOrField.target.value]

    if (id == null) {
      return
    }
    if (isSearchableField(id) && typeof val === 'string') {
      refSearch.current[id] = val
    }

    _onChange(id, val)

    /**
     * temporary solution for validate dep fields because:
     * useForm is getting error with stack loop during crossed fields in dependentValidations;
     * TODO-check useForm hook on case of crossed fields in dependentValidations
     * example of wrong dependent validation:
     * const dependentValidations = { email: ['phoneNumber'], phoneNumber: ['email'] }
     * **/
    const dependentField = DEPENDENT_VALIDATIONS[id as keyof typeof DEPENDENT_VALIDATIONS]

    if (dependentField != null) {
      validateField(dependentField)
    }
  }, [_onChange, validateField])

  const onClose = useCallback(() => {
    resetForm()
    flushDuplicateLeads()
    refSearch.current = getDefaultSearchState()
    _onClose()
  }, [_onClose, resetForm])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffectOnce(() => {
    const shouldGetOptions = sources.length === 0 || temperatures.length === 0 || users.length === 0

    if (shouldGetOptions) {
      const runEffect = async (): Promise<void> => {
        const [
          { items: sources },
          { items: temperatures },
          { items: users }
        ] = await Promise.all([
          CRMApiProvider.getLeadSources(),
          CRMApiProvider.getLeadTemperature(),
          IdentityApiProvider.getUsers(userRolesWithCRMPermission)
        ])

        setSources(sources)
        setTemperatures(temperatures)
        setUsers(users)
      }

      void runEffect()
    }
  }, [sources, temperatures, users])

  useEffect(() => {
    const token = getParsedToken()
    const LOGGED_USER_OPTION = { name: String(token?.name), id: Number(token?.sub) }

    if (isOpen) {
      if (isUpdate) {
        const transformedLeadData = transformLead(lead)
        resetForm(transformedLeadData)

        onChange(FieldIds.AssignedTo,
          lead.assignedUserId != null
            ? parseAssignedUser(lead.assignedUser)
            : UNASSIGNED_OPTION)
        onChange(FieldIds.Source, sources.find(s => s.id === lead?.leadSourceId))
      } else {
        onChange(FieldIds.AssignedTo, LOGGED_USER_OPTION)
      }
      onChange(FieldIds.Temperature, temperatures.find(t => t.id === (lead?.leadTemperatureId ?? HOT_TEMPERATURE_ID)))
    }
  }, [isOpen, isUpdate, lead, onChange, resetForm, sources, temperatures])

  useEffect(() => {
    if (isOpen && duplicateLeads.length > 0) {
      refModalContainer.current = document.querySelector('.cf-modal-content-scroll-wrapper')
    }
  }, [duplicateLeads.length, isOpen])

  /**
   * Cheap'n'dirty lazy loading.
   */
  useScrolledToBottom(() => {
    if (!isLoading &&
      duplicateLeads.length < totalCounter &&
      TABLE_MIN_HEIGHT <= calcTableHeight(duplicateLeads.length, ROW_HEIGHT)
    ) {
      void getDuplicateLeads(false)
    }
  },
  [isLoading, duplicateLeads.length, totalCounter, getDuplicateLeads],
  refModalContainer
  )

  // ========================================== //

  const tableProps = useMemo(() => ({
    isLoading,
    counter: totalCounter,
    columns: getColumnDefinitions({ onOpenLeadDetails }),
    rows: duplicateLeads
  }), [isLoading, totalCounter, onOpenLeadDetails, duplicateLeads])

  return {
    isFormSubmitting,
    isOpen,
    isUpdate,
    assignedTo,
    temperatures,
    sources,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onClose,
    onSubmit,
    tableProps
  }
}

export default useLeadDetailsFormModal
