import type { AxiosResponse } from 'axios'
import { type FileDto } from 'api/types/responses'
import {
  CancelationOptions,
  UploadedVehicleDocumentDto,
  type UploadedAssetWithThumbnailDto,
  type UploadedAttachmentDto,
  type ListResponse
} from 'api/types'

import { WrapperRequest } from './wrapper.api'
import DocumentsApiProvider from './documents.api'

const UPLOAD_SLICE_SIZE = 20

export interface FileWithUniqueName {
  file: File
  uniqueName: string
}

export const makeBatches = <T>(files: T[], batchSize: number): T[][] => {
  const batches = []

  for (let i = 0; i < files.length; i += batchSize) {
    batches.push(files.slice(i, i + batchSize))
  }

  return batches
}

class FilesApi extends WrapperRequest {
  url = process.env.REACT_APP_FILES ?? ''

  getEmailImagesUrl (isDownload = false): string {
    return `${this.url}/api/v1/${isDownload ? 'Download' : 'Upload'}/email-inline-images`
  }

  uploadVehicleVideo = async (data: FormData): Promise<UploadedAssetWithThumbnailDto> => {
    return await this.post(`${this.url}/api/v1/Upload/vehicle-videos`, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })
  }

  uploadFilesInBatches = async (
    files: FileWithUniqueName[],
    onFileUpload: (uploaded: UploadedAssetWithThumbnailDto, original: FileWithUniqueName) => void,
    onFileError: (original: FileWithUniqueName) => void,
    vehicleId?: number
  ): Promise<void> => {
    let photoUploads: Array<Promise<void>> = []
    let videoUploads: Array<Promise<void>> = []
    const videoItems = files.filter(file => file.file.type.includes('video'))
    const photoItems = files.filter(file => !file.file.type.includes('video'))
    const batches = makeBatches(photoItems, UPLOAD_SLICE_SIZE)
    const videoBatches = makeBatches(videoItems, UPLOAD_SLICE_SIZE)

    const uploadPhotoFile = async (file: File): Promise<UploadedAssetWithThumbnailDto> => {
      const formData = new FormData()
      formData.append('File', file)

      return await DocumentsApiProvider.postVehiclePhotos(formData)
    }

    const uploadVideoFile = async (file: File): Promise<UploadedAssetWithThumbnailDto> => {
      const formData = new FormData()

      formData.append('File', file)
      formData.append('parameters', `{ "vehicleId": ${vehicleId ?? ''} }`)

      return await this.uploadVehicleVideo(formData)
    }

    for (let i = 0; i < batches.length; i++) {
      for (const val of batches[i]) {
        photoUploads.push(
          uploadPhotoFile(val.file)
            .then((uploaded) => onFileUpload(uploaded, val))
            .catch(() => onFileError(val))
        )
      }
    }

    for (let i = 0; i < videoBatches.length; i++) {
      for (const val of videoBatches[i]) {
        videoUploads.push(
          uploadVideoFile(val.file)
            .then((uploaded) => onFileUpload(uploaded, val))
            .catch(() => onFileError(val))
        )
      }
    }

    await Promise.all([...photoUploads, ...videoUploads])

    photoUploads = []
    videoUploads = []
  }

  uploadFile = async (file: File): Promise<UploadedAssetWithThumbnailDto> => {
    const formData = new FormData()
    formData.append('File', file)

    return await DocumentsApiProvider.postVehiclePhotos(formData)
  }

  uploadFileForEmailAttachment = async (file: File): Promise<UploadedAttachmentDto> => {
    const formData = new FormData()
    formData.append('File', file)
    return await DocumentsApiProvider.postEmailAttachments(formData)
  }

  uploadImageForEmail = async (
    file: File,
    cancellationOptions?: CancelationOptions
  ): Promise<UploadedAssetWithThumbnailDto> => {
    const data = new FormData()
    data.append('File', file)
    data.append('parameters', '{}')

    return await this.post(this.getEmailImagesUrl(), {
      headers: { 'Content-Type': 'multipart/form-data' },
      data
    }, cancellationOptions)
  }

  uploadWebSiteHeroImage = async (
    file: File
  ): Promise<UploadedAssetWithThumbnailDto> => {
    const data = new FormData()
    data.append('File', file)
    data.append('parameters', '{}')
    return await this.post(`${this.url}/api/v1/Upload/home-image`, {
      headers: { 'Content-Type': 'multipart/form-data' },
      data
    })
  }

  uploadBankStatement = async (data: FormData): Promise<FileDto> => {
    return await this.post(`${this.url}/api/v1/Upload/accounting-bank-statements`, {
      headers: { 'Content-Type': 'multipart/form-data' },
      data
    })
  }

  uploadVehicleDocument = async (data: FormData): Promise<ListResponse<UploadedVehicleDocumentDto>> => {
    return await this.post(`${this.url}/api/v1/Upload/vehicle-files/batch`, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })
  }

  getVehicleFiles = async (id: number): Promise<AxiosResponse<Blob>> => {
    return await this.get(`${this.url}/api/v1/download/vehicle-files/${id}`, {
      headers: { 'X-Special-Request': true },
      additionalParams: { responseType: 'blob' }
    })
  }
}

const FilesApiProvider = new FilesApi()

export default FilesApiProvider
