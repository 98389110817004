import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
  margin-bottom: 16px;

  .filter-block,
  .link-block {
    min-width: 80px;
    
    &.is-loading .text-skeleton {
      min-height: 16px;
      max-height: 16px;
    }
  }
  
  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    padding: 4px;
    border-radius: 8px;
    text-decoration: none;
    
    &:hover {
      background: #F3F3F3;
    }
  }
  
  .filter {
    max-height: 32px;
    
    .cf-select-display-container {
      align-items: center;
      padding: 6px 8px;
      height: 100%;
      border-radius: 8px;
      border: none;
      background: #F3F3F3;
      
      .cf-display {
        padding: 0px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
      }
      
      .cf-display-adornment {
        line-height: 24px;
        padding: 0px;
        width: 16px;
        height: 16px;
        margin-left: 4px;
        
        .cf-dropdown-trigger-button {
          width: 16px;
          height: 16px;
        }
        
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
`)

export const DEAL_FILTERS_POPOVER_CLASS_NAME = css(`
  border-radius: 8px;
  min-width: 240px;
  
  .cf-dropdown-listbox {
    border-radius: 8px;
    min-width: 240px;
  }
`)
