import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import Table from 'components/common/Table'

import Title from './components/Title'
import WorkPlanEventModal from './components/WorkPlanEventModal'
import DeleteRule from './components/DeleteRule'

import { useRules } from './hook'
import CLASS_NAME from './styles'

const Rules: FC = () => {
  const {
    onCloseModal,
    leadStatus,
    onCloseDeleteModal,
    onConfirmDelete,
    isLoading,
    onRowClick,
    dataToUpdate,
    onSubmitRuleForm,
    tableInfo,
    columnDefs,
    onDeleteRule,
    isDeleteModalOpened
  } = useRules()

  return (
    <div className={cnx(CLASS_NAME, 'g-submenu-content', 'g-margin-top-big')}>
      <Title />

      {Array.from(tableInfo.entries()).map(([item, data]) => {
        return (
          <div key={item.id} className='table-wrapper'>
            <Table
              columns={columnDefs[item.id]}
              onRowClick={onRowClick}
              emptyTableMessage='No events for this status'
              data={data}
            />
          </div>
        )
      })}

      <WorkPlanEventModal
        onSubmit={onSubmitRuleForm}
        onCloseModal={onCloseModal}
        leadStatus={leadStatus}
        formData={dataToUpdate}
        onDelete={onDeleteRule}
      />

      <DeleteRule
        isOpen={isDeleteModalOpened}
        onClose={onCloseDeleteModal}
        onSubmit={onConfirmDelete}
        isLoading={isLoading}
      />
    </div>
  )
}

export default Rules
