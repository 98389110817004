import { css } from '@emotion/css'
import { ScreenWidthBreakpoints, px } from 'constants/constants'

export default css(`
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  min-height: 60px;
  display: grid;
  align-items: center;
  box-sizing: border-box;
  grid-gap: 0 20px;
  grid-template-columns: 25% repeat(4, 1fr) 2fr;
  grid-template-rows: minmax(60px, auto);
  grid-template-areas:
    "lenderTop lenderTop amountTop buyRateTop termTop statusTop additionalInfoTop"
    "lenderBottom lenderBottom amountBottom buyRateBottom termBottom statusBottom additionalInfoBottom";

  @media screen and (min-width: ${px(ScreenWidthBreakpoints.Small)}) {
    grid-template-columns: 24.5% repeat(4, 1fr) 2fr;
  }

  @media screen and (min-width: ${px(ScreenWidthBreakpoints.Medium)}) {
    grid-template-columns: 24% repeat(4, 1fr) 2fr;
  }

  > p, > div {
    padding: 8px 0;
  }

  .amount-financed {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .stipulations-list {
    padding-left: 15px;
  }

  .stipulation {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(33, 33, 33, 0.8);
  }
`)
