import type { FC, MouseEventHandler, ReactNode } from 'react'
import { useMemo } from 'react'
import MUIButton from '@material-ui/core/Button'
import { cn, UI } from '@carfluent/common'
import { useStyles } from './styles'

const { Loader } = UI

export type ButtonType = 'primary' | 'secondary'
export type ButtonVariant = 'text' | 'outlined' | 'contained'

interface ButtonProps {
  classes?: {
    label?: string
    root?: string
  }
  className?: string
  htmlType?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  disableRipple?: boolean
  disableElevation?: boolean
  isLoading?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  startIcon?: ReactNode
  dataTestId?: string
  'data-test-id'?: string
  variant?: ButtonVariant
  type?: ButtonType
}

const Button: FC<ButtonProps> = ({
  classes,
  children,
  className = '',
  disabled: isDisabled = false,
  disableRipple = false,
  disableElevation = false,
  isLoading = false,
  onClick,
  type = 'primary',
  variant = 'contained',
  htmlType = 'button',
  startIcon,
  dataTestId,
  ...rest
}) => {
  const styles = useStyles()

  const secondaryClasses = (type === 'secondary' && variant === 'contained')
    ? styles.whiteBtn
    : styles.default
  const resolvedClasses = useMemo(() => ({ ...classes }), [classes])

  return (
    <MUIButton
      type={htmlType}
      className={cn(className, secondaryClasses)}
      classes={resolvedClasses}
      disabled={isDisabled}
      onClick={onClick}
      variant={variant}
      startIcon={startIcon}
      disableRipple={disableRipple}
      disableElevation={disableElevation}
      data-test-id={dataTestId ?? rest['data-test-id']}
    >
      {children}
      {isLoading && (
        <Loader
          className='cf-button-progress'
          color='inherit'
          size={14}
        />
      )}
    </MUIButton>
  )
}

export default Button
