import { type FC } from 'react'
import { DateRangePicker as DatePicker, Button, PopoverV2, PRESET_LABELS, type Preset, RangePickerMode } from '@carfluent/common'

import { DateType } from 'api/types'
import { Errors } from 'utils/validationPresets'
import ActionBar from 'components/common/ActionBar'

import useDateRangePicker from './hooks'

import IconSVG from 'components/inlineImages'
import { CLASS_NAME, DATE_PICKER_CLASS_NAME, POPOVER_CLASS_NAME } from './styles'

export interface DateFiltersProps {
  title?: string
  presets: Preset[]
  initPreset?: Preset
  presetLabel?: string
  monthsNumber?: number
  notifyOnMount?: boolean
  appliedFilters?: DateType
  defaultPresetName?: PRESET_LABELS
  rangePickerMode?: RangePickerMode
  dateFiltersResetMarker?: object
  onPresetChange?: (preset: Preset) => void
  onFilterChange: (filters: DateType) => Promise<void>
}

const DateRangePicker: FC<DateFiltersProps> = ({ appliedFilters, notifyOnMount, dateFiltersResetMarker, initPreset, rangePickerMode = RangePickerMode.Range, presetLabel: _presetLabel, monthsNumber = 2, presets, defaultPresetName, onFilterChange, title = '' }) => {
  const presetLabel = _presetLabel ?? (rangePickerMode === RangePickerMode.Single ? 'As of' : 'Period')

  const {
    errors,
    onBlur,
    isOpen,
    touched,
    onClose,
    disabled,
    onChange,
    onSubmit,
    toggleOpen,
    periodLabel,
    isCloseIcon,
    refAnchorEl,
    initialYear,
    initialMonth,
    onChangeDate,
    endDateString,
    onPresetChange,
    popoverOptions,
    startDateString,
    activeDatePreset,
    onResetDateFilter,
    appliedFilters: _appliedFilters
  } = useDateRangePicker({ appliedFilters, notifyOnMount, dateFiltersResetMarker, rangePickerMode, initPreset, presetLabel, monthsNumber, defaultPresetName, onFilterChange, presets })

  return (
    <div className={CLASS_NAME}>
      <div className='action-bar'>
        <div className='title' ref={refAnchorEl}>{title}</div>

        <div>
          <Button
            variant='text'
            onClick={toggleOpen}
            className='period-button'
          >
            {periodLabel ?? ''}

            {isCloseIcon && (
              <div
                onClick={(evt) => {
                  evt?.stopPropagation()
                  void onResetDateFilter?.()
                }}
                className='icon-delete'
              >

                <IconSVG.Cross width={16} height={16} />
              </div>
            )}

            {!isCloseIcon && (
              <IconSVG.DropdownArrowDown width={16} height={16} />
            )}
          </Button>
        </div>

        <PopoverV2
          isOpen={isOpen}
          anchorEl={refAnchorEl}
          options={popoverOptions}
          className={POPOVER_CLASS_NAME}
          onClose={onClose}
        >
          <div className={DATE_PICKER_CLASS_NAME}>
            <div
              onClick={onClose}
              className='close-icon'
            >
              <IconSVG.Cross />
            </div>

            <DatePicker
              withFormMask
              onBlur={onBlur}
              errors={errors}
              touched={{
                ...touched,
                endDate: touched?.endDate ?? false,
                startDate: (
                  errors?.startDate === Errors.FromGreaterThanTo
                ) || (touched?.startDate ?? false)
              }}
              presets={presets}
              onChange={onChange}
              initialYear={initialYear}
              initialMonth={initialMonth}
              onChangeDate={onChangeDate}
              monthsNumber={monthsNumber}
              endDate={_appliedFilters?.to}
              endDateString={endDateString}
              activePreset={activeDatePreset}
              startDate={_appliedFilters?.from}
              startDateString={startDateString}
              rangePickerMode={rangePickerMode}
              presetLabel={`${presetLabel ?? title}:`}
              onActivePresetChange={onPresetChange}
              initialPresetId={activeDatePreset?.name}
            />

            <ActionBar
              disabled={disabled}
              onSubmit={onSubmit}
              onRemove={onResetDateFilter}
            />
          </div>
        </PopoverV2>
      </div>
    </div>
  )
}

export default DateRangePicker
