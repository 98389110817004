import { css } from '@emotion/css'

export default css(`
  .cf-button-content {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }

  .cf-no-data-block {
    display: flex;
    justify-content: center;
    padding: 20px;
    background: #fff;
    border-top: 8px solid #F3F3F3;

    p { line-height: 24px }
  }

  .header-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 88px;
    width: 100%;
    margin: 0 0 16px 0;
    padding: 16px;
    background: #FFFFFF;
    border-radius: 12px;
  }

  .search-block { min-width: 420px; }

  .cf-table { table-layout: fixed; }

  .cf-cell-wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .cf-table-row.cf-skeleton-row .cf-table-cell {
    :first-child { 
      width: 320px; 
      .MuiSkeleton-root { width: 196px; }
    }

    :last-child .MuiSkeleton-root { width: 326px; }
  }

  .cf-header-cell-title {
    color: #101010;
  }
`)
