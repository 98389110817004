import { css } from '@emotion/css'

export default css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: white;
  overflow: auto;
  
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.is-voided {
    .sub-row {
      .col-right-side {
        width: 525px;
      }

      .col-left-side {
        width: calc(100% - 525px)
      }

      .voided-text {
        color: rgba(33, 33, 33, 0.50);
        margin-right: 16px;
      }
    }

    .sign-content {
      opacity: 0.4
    }
  }
  
  .sub-row {
    height: 56px;
    width: 100%;
    display: flex;
    margin-top: 8px;
    
    .col-left-side {
      width: 60%;
      display: flex;
      align-items: center;
      height: 100%;

      .status-mark {
        min-width: 8px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin: 0 12px;
        background: rgba(0, 0, 0, 0.30);
        
        &.completed {
          background: #219653;
        }
        
        &.voided {
          background: #B00020;
        }
      }

      .col-name-block {
        width: calc(100% - 24px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 24px;

        .col-name-block-description {
          color: rgba(33, 33, 33, 0.50);
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
        }
      }
    }

    .col-right-side {
      width: 40%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > button.cf-button-root.cf-button-variant-text {
        margin-right: 16px;
        
        &.cf-button-loading {
          padding-right: 16px;
        }

        .cf-button-content {
          font-weight: 700;
        }
      }
    }
  }
  
  .sign-content {
    display: grid;
    column-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 16px;
    
    &.with-co-buyer {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    
    .sign-item {
      padding: 8px;
      background: #FAFAFA;
      border-radius: 8px;
      
      .sign-checked-block {
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 4px 8px;
        background: #EBECEB;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;
        
        &.is-signed {
          background: rgba(33, 150, 83, 0.10);

          svg {
            path {
              fill: #219653;
              fill-opacity: 1;
            }
          }
        }
        
        svg {
          margin-right: 4px;
        }
      }
      
      .sign-item-text {
        display: flex;
        align-items: center;
        
        > p {
          font-size: 14px;
          line-height: 20px;
        }

        &.text-sender {
          margin-bottom: 4px;
        }
        
        .sign-item-text-label {
          width: 76px;
          color: rgba(33, 33, 33, 0.50);
        }

        .sign-item-text-value {
          width: calc(100% - 76px);
          padding-left: 4px;
        }
      }
    }
  }
`
