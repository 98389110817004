import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Typography } from '@material-ui/core'
import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from '@toast-ui/react-image-editor'

import type { ImageDescriptor } from 'components/inventory/ImageItem'

import useStyles from './styles'

const EDITOR_MAX_WIDTH = 952
const UI_SIZE_HEIGHT = 596

interface EditModalProps {
  editPhoto: ImageDescriptor | null
  closeModal: () => void
  updatePhoto: (photos: File) => Promise<void>
}

const VehicleImageEditor: FC<EditModalProps> = ({ editPhoto, closeModal, updatePhoto }) => {
  const styles = useStyles()
  const imageEditor = useRef<any>(null)
  const [isChanged, setIsChanged] = useState(false)

  const saveImage = useCallback((): void => {
    if (imageEditor.current != null) {
      const imageEditorInst = imageEditor.current.imageEditorInst
      const data = imageEditorInst.toDataURL()

      const arr = data.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while ((n--) > 0) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      const file = new File([u8arr], editPhoto?.fileName ?? '', { type: mime })

      void updatePhoto(file)
    }
  }, [updatePhoto, imageEditor])

  useEffect(() => {
    if (imageEditor.current != null) {
      const imageEditorInst = imageEditor.current.imageEditorInst
      imageEditorInst.on('undoStackChanged', () => {
        const isEmptyUndoStack: boolean = imageEditor.current?.imageEditorInst.isEmptyUndoStack()
        setIsChanged(!isEmptyUndoStack)
      })
      return () => imageEditorInst.off('undoStackChanged')
    }
  }, [imageEditor])

  return (
    <div className={styles.editorWrapper}>
      <ImageEditor
        cssMaxHeight={window.innerHeight * (450 / window.innerHeight)}
        cssMaxWidth={EDITOR_MAX_WIDTH}
        includeUI={{
          loadImage: {
            path: editPhoto?.originalUrl ?? '',
            name: editPhoto?.fileName ?? ''
          },
          theme: {
            'submenu.backgroundColor': '#ffffff',
            'common.backgroundColor': '#ffffff'
          },
          menu: ['text', 'crop', 'flip', 'rotate', 'filter'],
          menuBarPosition: 'bottom',
          uiSize: {
            width: `${EDITOR_MAX_WIDTH}px`,
            height: `${UI_SIZE_HEIGHT}px`
          }
        }}
        ref={imageEditor}
        selectionStyle={{
          cornerSize: 20,
          rotatingPointOffset: 70
        }}
      />
      <div className={styles.btnWrapper}>
        <button className={styles.cancel} disabled={!isChanged} onClick={closeModal}>
          <Typography>Discard</Typography>
        </button>
        <button className={styles.saveChanges} disabled={!isChanged} onClick={saveImage}>
          <Typography>Save changes</Typography>
        </button>
      </div>
    </div>
  )
}

export default VehicleImageEditor
