import type { MessageTemplate } from 'api/types'

import type { MessageTemplatePayload } from './types'

const serializeData = (formData: MessageTemplatePayload): MessageTemplate => {
  return {
    ...formData,
    body: formData.body ?? ''
  }
}

export default serializeData
