import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSvgPlayer: FC<IconProps> = ({ color = '#fff', width = 24, height = 24 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'>
      <g opacity='0.8' clipPath='url(#clip0_83838_198913)'>
        <path
          d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 16.5V7.5L16 12L10 16.5Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_83838_198913'>
          <rect width={width} height={height} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSvgPlayer
