import type { FC } from 'react'
import { cn } from '@carfluent/common'
import { FormControlLabel, FormControlLabelProps } from '@material-ui/core'

import Radio from 'components/common/Radio'

import CLASS_NAME from './styles'

const RadioLabelControl: FC<Omit<FormControlLabelProps, 'control'>> = ({
  label,
  value,
  className = '',
  ...otherProps
}) => {
  return (
    <FormControlLabel
      {...otherProps}
      className={cn(CLASS_NAME, 'radio-item', className)}
      label={label}
      value={value}
      control={<Radio />}
    />
  )
}

export default RadioLabelControl
