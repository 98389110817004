import type { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import { UI, cn } from '@carfluent/common'

import type { CellProps, KeyVal } from 'types'

const { CellWrapper } = UI

export interface TitleCellProps extends CellProps<KeyVal> {
  isWrong: boolean
}

const TitleCell: FC<TitleCellProps> = ({ getValue, isWrong }) => {
  return (
    <CellWrapper className={cn(isWrong && 'cf-wrong-cell')}>
      <Typography>{getValue() as string}</Typography>
    </CellWrapper>
  )
}

export default TitleCell
