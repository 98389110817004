import { type FC } from 'react'
import { FormDropdown, Banner, getFirstError, utils } from '@carfluent/common'

import { type AccountListItem } from 'api/types'
import FormFilesUpload from 'components/common/FormFilesUpload'
import SaveCloseButtonsPanel from 'components/common/SaveCloseButtonsPanel'
import { containsTruthy } from 'utils/general'

import useStatementUploadHook from './hook'
import { type UseStatementUploadFormProps } from './hook/types'
import { FILE_EXTENSIONS, Messages } from './hook/constants'
import CLASS_NAME from './styles'
import { joinPartsToStr } from 'utils/view_helper'

export type StatementUploadFormProps = UseStatementUploadFormProps

const StatementUploadForm: FC<StatementUploadFormProps> = (props) => {
  const {
    errorMessage,
    errors,
    getAccounts,
    isDuplicateInfoVisible,
    isFilesLoading,
    isFormSubmitting,
    onBlur,
    onChange,
    onChangeFiles,
    onGotoList,
    onSubmitUploadForm,
    touched,
    values,
    bankList
  } = useStatementUploadHook(props)

  return (
    <>
      <div className={CLASS_NAME}>
        <h5>Bank statement upload</h5>

        {(errorMessage != null) && (
          <Banner
            className='banner-error'
            isOpen // disables animation
            message={errorMessage}
            type='error'
          />
        )}

        <div className='field-section'>
          <h6>Account</h6>

          <FormDropdown
            error={getFirstError(errors.account)}
            id='account'
            fetchHandler={getAccounts}
            formatDisplayValue={formatAccount}
            label='Select account'
            onChange={onChange}
            onBlur={onBlur}
            renderOption={formatAccount}
            touched={containsTruthy(touched.account)}
            value={values.account}
          />
        </div>

        <div className='field-section'>
          <h6>Statement file</h6>

          {isDuplicateInfoVisible && (
            <Banner
              className='banner-duplicates'
              isOpen // disables animation
              message={Messages.DuplicatesInfo}
              type='info'
            />
          )}

          <FormFilesUpload
            acceptedExtensions={null}
            error={getFirstError(errors.files)}
            isLoading={isFilesLoading}
            isSingleFile
            id='files'
            maxFileSize={utils.UNLIMITED_FILES_SIZE}
            maxFilesTotalSize={utils.UNLIMITED_FILES_SIZE}
            onChange={onChangeFiles}
            suggestedExtensions={FILE_EXTENSIONS}
            touched={containsTruthy(touched.files)}
            value={values.files}
          />

          <div className='files-info'>
            <p className='g-subtitle1'>
              Supported formats: .CSV or .TXT.
            </p>

            <p className='g-subtitle1'>
              If you have files in other formats, you can convert it to an accepted file format using any online/offline converter.
            </p>
          </div>
        </div>

        <div className='field-section'>
          <h6>Bank</h6>

          <FormDropdown
            error={getFirstError(errors.bank)}
            id='bank'
            label='Select bank'
            options={bankList}
            onChange={onChange}
            onBlur={onBlur}
            popoverClassName='g-low-zindex-dropdown'
            touched={containsTruthy(touched.bank)}
            value={values.bank}
          />
        </div>
      </div>

      <SaveCloseButtonsPanel
        handleReset={onGotoList}
        handleSubmit={onSubmitUploadForm}
        isSubmitting={isFormSubmitting}
        titleCloseButton='CANCEL'
        titleSaveButton='NEXT'
      />
    </>
  )
}

export default StatementUploadForm

// ========================================== //

const formatAccount = (opt: AccountListItem | null): string => {
  return joinPartsToStr(' - ', opt?.number, opt?.name)
}
