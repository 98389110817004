import type { FC } from 'react'
import { formatCurrencyDecimal } from '@carfluent/common'

import type { FinancingApplicationLenderDecisionDetailsDto } from 'api/types'

import { ApprovalStatus } from 'constants/names'

import { formatPercentages } from 'utils/format_number'

import DecisionStatus from 'components/deals/DecisionStatus'
import SemiCollapsible from 'components/common/SemiCollapsible'

import CLASSES from './styles'

interface LenderDecisionCardProps {
  decision: FinancingApplicationLenderDecisionDetailsDto
}

const LenderDecisionCard: FC<LenderDecisionCardProps> = ({
  decision
}) => {
  const {
    additionalInformation = [],
    stipulations = [],
    approvedAmount,
    approvalStatus,
    approvedRate,
    approvedTerm,
    lienholder
  } = decision

  const hasStipulations = (stipulations !== null) || (additionalInformation !== null)

  return (
    <div className={CLASSES}>
      <p>{lienholder?.name}</p>
      <p className='amount-financed'>{formatCurrencyDecimal(approvedAmount, { emptyValues: '-' })}</p>
      <p>{formatPercentages(approvedRate ?? '', '-')}</p>
      <p>{approvedTerm !== null ? `${approvedTerm} months` : '-'}</p>

      <DecisionStatus approvalStatus={approvalStatus as ApprovalStatus} />

      {hasStipulations && (
        <SemiCollapsible fixedHeight={60}>
          <ul className='stipulations-list'>
            {stipulations?.map(({ text }) => <li key={text} className='stipulation'>{text}</li>)}
            {additionalInformation?.map((text) => <li key={text} className='stipulation'>{text}</li>)}
          </ul>
        </SemiCollapsible>
      )}

      {!hasStipulations && '-'}
    </div>
  )
}

export default LenderDecisionCard
