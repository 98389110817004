import type { FC } from 'react'
import { Button, Modal } from '@carfluent/common'

import CLASS_NAME, { MODAL_CLASS_NAME } from './styles'

const MODAL_MAX_WIDTH = 540

interface DialogProps {
  open: boolean
  onClose: () => void
}

const RecordWasUpdated: FC<DialogProps> = ({ open, onClose }) => {
  return (
    <Modal
      isOpen={open}
      maxWidth={MODAL_MAX_WIDTH}
      className={MODAL_CLASS_NAME}
    >
      <div className={CLASS_NAME}>
        <p className='cf-title'>
          This record has been modified by another user or Admin.
        </p>

        <p className='cf-text'>
          To see changes, reload the page.
        </p>

        <Button
          className='cf-btn'
          onClick={onClose}
        >
          Reload page
        </Button>
      </div>
    </Modal>
  )
}

export default RecordWasUpdated
