import { css } from '@emotion/css'

export default css(`
  display: flex;
  
  .cell-image {
    border-radius: 4px;
    margin-right: 10px;
    height: 50px;
    width: 80px;
  }

  .cell-description {
    width: 100%,
    position: relative;
  }

  .big-skeleton {
    width: clamp(90px, 90%, 220px);
    height: 24px;
  }

  .small-skeleton {
    width: clamp(60px, 70%, 84px);
    height: 24px;
  }
`)
