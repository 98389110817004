import axios from 'axios'
import { useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { type KeyVal } from 'types'
import {
  type RequestVehicleId,
  type VehicleById,
  type VehicleDetailsByVin,
  DisabilityEquipped,
  Dropdowns,
  VehicleState
} from 'api/types'

import VehiclesApiProvider from 'api/vehicles.api'
import processApiErrors from 'utils/inventory/processApiErrors'
import { Routes, NOT_FOUND_STATUS } from 'constants/route_helper'
import CustomersCoreApiProvider from 'api/customersCore.api'
import { compareDictionaryByName } from 'utils/general'
import { type ImageDescriptor } from 'components/inventory/ImageItem'

import type { UpdateVehicleFormData, TransactionDealDetails } from './types'
import { parseVehicleById, parseVehicleByVIN, parseImages } from './parser'
import VehicleDetailsCTX from '../store'
import GeneralTabCTX from './store'
import serializeVehicle from './serializer'

interface UseVehicleDetailsAPIProps {
  setApiErrors: (errors: KeyVal | null) => void
  setFieldError: (fieldId: string, error: string) => void
  setFormData: (formData: UpdateVehicleFormData) => Promise<any>
  setTransactionDealDetails: (transactionDealDetails: TransactionDealDetails) => void
  fetchLeadsTab: ({
    dateTime,
    soldDate,
    deletedDate
  }: { dateTime: string, soldDate: string | null, deletedDate: string | null }) => void
}

interface UseVehicleDetailsAPIReturn {
  loadVehicleById: (vehicleId: RequestVehicleId) => Promise<void>
  loadVehicleByVIN: (vin: string) => Promise<VehicleDetailsByVin | null>
  loadDictionaries: () => Promise<void>
  updateVehicle: (
    data?: Partial<UpdateVehicleFormData>,
    mediaFiles?: ImageDescriptor[]
  ) => Promise<boolean>
}

const useVehicleDetailsAPI = ({
  setApiErrors,
  setFormData,
  setFieldError,
  fetchLeadsTab,
  setTransactionDealDetails
}: UseVehicleDetailsAPIProps): UseVehicleDetailsAPIReturn => {
  const navigate = useNavigate()

  const {
    setDictionaries,
    vehicleFormData,
    dictionaries
  } = useContext(GeneralTabCTX)

  const {
    originalVehicle,
    setOriginalMediaFiles,
    setOriginalVehicle
  } = useContext(VehicleDetailsCTX)

  const loadDictionaries = useCallback(async (): Promise<void> => {
    const [
      bodyType,
      cylinderCountsTypes,
      doorCountsTypes,
      driveTrainTypes,
      fuelTypes,
      genericColorsTypes,
      genericInteriorsTypes,
      transmissionTypes,
      titleTypes,
      inventoryStates,
      tags,
      makes,
      titleStages,
      registrationStage
    ] = await Promise.all([
      VehiclesApiProvider.getBodyTypes(),
      VehiclesApiProvider.getCylinderCountsTypes(),
      VehiclesApiProvider.getDoorCountsTypes(),
      VehiclesApiProvider.getDriveTrainTypes(),
      VehiclesApiProvider.getFuelTypes(),
      VehiclesApiProvider.getGenericColorsTypes(),
      VehiclesApiProvider.getGenericInteriorsTypes(),
      VehiclesApiProvider.getTransmissionTypes(),
      VehiclesApiProvider.getTitleTypes(),
      VehiclesApiProvider.getInventoryStates(),
      VehiclesApiProvider.getTags(),
      VehiclesApiProvider.getMakes(),
      VehiclesApiProvider.getTitleStages(),
      VehiclesApiProvider.getRegistrationStages()
    ])

    genericColorsTypes.items.sort(compareDictionaryByName)
    genericInteriorsTypes.items.sort(compareDictionaryByName)

    const dictionaries = {
      [Dropdowns.Body]: bodyType.items,
      [Dropdowns.Cylinders]: cylinderCountsTypes.items,
      [Dropdowns.Doors]: doorCountsTypes.items,
      [Dropdowns.Drivetrain]: driveTrainTypes.items,
      [Dropdowns.Fuel]: fuelTypes.items,
      [Dropdowns.GenericColor]: genericColorsTypes.items,
      [Dropdowns.GenericInterior]: genericInteriorsTypes.items,
      [Dropdowns.Transmission]: transmissionTypes.items,
      [Dropdowns.TitleStatus]: titleTypes.items,
      [Dropdowns.InventoryState]: inventoryStates.items,
      [Dropdowns.DisabilityEquipped]: [
        { id: 1, name: DisabilityEquipped.Yes },
        { id: 0, name: DisabilityEquipped.No }
      ],
      [Dropdowns.Tags]: tags.items,
      [Dropdowns.Make]: makes.items,
      [Dropdowns.TitleStage]: titleStages.items,
      [Dropdowns.RegistrationStage]: registrationStage.items
    }

    setDictionaries(dictionaries)
  }, [])

  /**
   * AZ-TODO: move to root hook, it's used by several tabs
   */
  const loadVehicleById = useCallback(async (vehicleId: RequestVehicleId): Promise<void> => {
    let vehicle: VehicleById | null = null

    try {
      vehicle = await VehiclesApiProvider.getVehicleById(vehicleId)
      if (vehicle.activationDate != null) {
        fetchLeadsTab({
          dateTime: vehicle.activationDate.toString(),
          soldDate: vehicle.soldDate !== null ? vehicle.soldDate?.toString() : null,
          deletedDate: vehicle.deletedDate !== null ? vehicle.deletedDate?.toString() : null
        })
      }

      setOriginalVehicle(vehicle)
      setOriginalMediaFiles(parseImages(vehicle.vehicleImages))
    } catch (e) {
      if (axios.isAxiosError(e) && e?.response?.status === NOT_FOUND_STATUS) {
        navigate(Routes.NoDealerAndVin)
      }
    }

    if (vehicle == null) {
      return
    }

    try {
      if (vehicle?.soldDealId != null && vehicle.vehicleState === VehicleState.Sold) {
        const {
          workflowAccountingStateId,
          recapTransactionId
        } = await CustomersCoreApiProvider.getDeal(vehicle?.soldDealId)

        setTransactionDealDetails({
          workflowAccountingStateId,
          recapTransactionId,
          isDealLoaded: true
        })
      }
    } catch (e) {
      console.error('Error while loading vehicle deal')
    }
  }, [
    navigate,
    setTransactionDealDetails,
    setOriginalMediaFiles,
    setOriginalVehicle
  ])

  const loadVehicleByVIN = useCallback(async (vin: string): Promise<VehicleDetailsByVin | null> => {
    setApiErrors(null)

    const vehicle = await VehiclesApiProvider.getVehicleByVin(vin)
    if ((vehicle as any).status === 204) {
      return null
    }

    await setFormData(parseVehicleByVIN(vehicleFormData, vehicle, dictionaries))
    return vehicle
  }, [vehicleFormData, dictionaries, setFormData])

  /**
   * AZ-TODO: move to root hook, it's used by several tabs
   */
  const updateVehicle = useCallback(async (
    _data?: Partial<UpdateVehicleFormData>,
    mediaFiles: ImageDescriptor[] = []
  ): Promise<boolean> => {
    try {
      const data = serializeVehicle(
        { ...vehicleFormData, ..._data },
        mediaFiles,
        originalVehicle.inventoryDate,
        originalVehicle.vehicleRepricingDetails
      )

      const vehicle = await VehiclesApiProvider.patchVehicle(data)

      if (vehicle.activationDate != null) {
        fetchLeadsTab({
          dateTime: vehicle.activationDate.toString(),
          soldDate: vehicle.soldDate !== null ? vehicle.soldDate?.toString() : null,
          deletedDate: vehicle.deletedDate !== null ? vehicle.deletedDate?.toString() : null
        })
      }

      setApiErrors(null)
      setOriginalVehicle(vehicle)
      setOriginalMediaFiles(parseImages(vehicle.vehicleImages))
      await setFormData(parseVehicleById(vehicle, dictionaries))

      return true
    } catch (err) {
      processApiErrors({ setApiErrors, setFieldError }, err)
      return false
    }
  }, [
    vehicleFormData,
    dictionaries,
    setFormData,
    setFieldError,
    setOriginalVehicle,
    setOriginalMediaFiles,
    originalVehicle.inventoryDate,
    originalVehicle.vehicleRepricingDetails
  ])

  return useMemo(() => ({
    loadVehicleById,
    loadVehicleByVIN,
    loadDictionaries,
    updateVehicle
  }), [
    loadVehicleById,
    loadVehicleByVIN,
    loadDictionaries,
    updateVehicle
  ])
}

export default useVehicleDetailsAPI
