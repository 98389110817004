import React, { FC } from 'react'
import { UITools } from '@carfluent/common'

import { Typography, Button } from '@material-ui/core'

import { FileInfo } from './file_info'
import { UploadContent } from './upload_content'

import { ContentProps } from '../types'
import { useStyles } from './styles'

const { cn } = UITools

const Content: FC<ContentProps> = ({
  file,
  error,
  handleChangeFile,
  deleteFile,
  downloadTemplate
}) => {
  const styles = useStyles()
  const errorClass = error != null ? styles.errorBlock : ''

  return (
    <div>
      <Typography classes={{ root: styles.subtitle }} variant='body1'>
        Import is possible only from template.
        <Button
          classes={{
            root: styles.downloadBtn,
            label: styles.downloadBtnLabel
          }}
          onClick={downloadTemplate}
          variant='text'
        >
          Download template csv
        </Button>
      </Typography>
      <div className={cn(styles.dragAndDropBlock, errorClass)}>
        {
          file != null
            ? <FileInfo file={file} deleteFile={deleteFile} />
            : <UploadContent error={error} handleChangeFile={handleChangeFile} />
        }
      </div>
    </div>
  )
}

export default Content
