import { css } from '@emotion/css'

export default css`
  height: 100vh;
  width: 100%;

  .empty-header {
    height: var(--header-height);
    background: #101010;
  }

  .wrapper {
    height: calc(100% - 120px);
    max-width: 600px;
    margin: 0 auto 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .image-wrapper {
    margin-bottom: 48px;
    width: 100%;
    max-width: 207px;
  }

  .title {
    font-size: 34px;
    line-height: 36px;
    margin-bottom: 40px;
  }

  .text {
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #101010;
  }
`
