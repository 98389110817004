import { TransactionTypeId } from 'api/types'

export const CUSTOM_TRANSACTION_TYPE_IDS = new Set<number | null>([
  TransactionTypeId.Receivable,
  TransactionTypeId.Receive,
  TransactionTypeId.Payable,
  TransactionTypeId.Check,
  TransactionTypeId.PayBill
])

const isSpecialTransaction = (transactionTypeId?: number | null): boolean => {
  return (transactionTypeId != null) && CUSTOM_TRANSACTION_TYPE_IDS.has(transactionTypeId)
}

export default isSpecialTransaction
