import { useCallback } from 'react'
import type { ChangeEvent } from 'react'
import { cn, UI } from '@carfluent/common'

import type { CellProps, KeyVal } from 'types'
import type { EditableCellProps } from 'components/common/Table/cells/editable_cell'
import Checkbox from 'components/common/Checkbox'
import CLASS_NAME from './styles'

const { CellWrapper } = UI

export type CheckboxCellProps<TRow extends KeyVal> = CellProps<TRow> & EditableCellProps<boolean>

function CheckboxCell<TRow extends KeyVal> ({
  className,
  column,
  onChange: _onChange,
  getValue,
  row
}: CheckboxCellProps<TRow>): JSX.Element {
  const value = Boolean(getValue())

  const onChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    _onChange(row.index, column.id, evt.target.checked)
  }, [_onChange, column.id, row.index])

  return (
    <CellWrapper className={cn(CLASS_NAME, className)}>
      <Checkbox
        checked={value}
        onChange={onChange}
      />
    </CellWrapper>
  )
}

export default CheckboxCell
