import { css } from '@emotion/css'
import { px } from 'constants/constants'

const MIN_SCREEN_SIZE_FOR_KEEPING_AVATARS = 1424

export const AVATAR_CLASS_NAME = css(`
  display: none;
  
  @media screen and (min-width: ${px(MIN_SCREEN_SIZE_FOR_KEEPING_AVATARS)}) {
    display: flex;
  }
`)

export const TOOLTIP_CLASS_NAME = css(`
  &.MuiTooltip-tooltip {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    padding: 4px 12px;
    margin: 0;
    margin-top: 16px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    border-radius: 4px;
    background: #212121; 

    .cf-tooltip-info {
      font-weight: 300;
      font-size: 14px;
    }

    > span {
      width: 100px;
    }
  }

  &.cf-wide-tooltip {
    max-width: 350px;
    }
`)
