import { useState, useCallback } from 'react'
import { PRESET_LABELS, type Preset } from '@carfluent/common'

import { type FiltersFormData } from 'types/filters'
import { GET_DEFAULT_PRESETS } from 'constants/constants'

export type DateFilters = FiltersFormData['date']

export interface UseDateFiltersProps {
  setDateFilters?: (value: DateFilters) => void
  defaultPreset?: Preset
}

export interface UseDateFiltersReturn {
  activeDatePreset: Preset | null
  presets: Preset[]
  setActiveDatePreset: (preset: Preset | null) => void
}

const DEFAULT_PRESETS = GET_DEFAULT_PRESETS()

const useDateFilters = ({
  setDateFilters,
  defaultPreset = DEFAULT_PRESETS[1]
}: UseDateFiltersProps): UseDateFiltersReturn => {
  const [activeDatePreset, _setActiveDatePreset] = useState<Preset | null>(defaultPreset)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const setActiveDatePreset = useCallback((preset: Preset | null): void => {
    _setActiveDatePreset(preset)

    if (setDateFilters != null) {
      const isAllPresetSelected = preset?.name === PRESET_LABELS.ALL
      const isAllTillTodayPresetSelected = preset?.name === PRESET_LABELS.ALL_TILL_TODAY

      const from = isAllPresetSelected ?? isAllTillTodayPresetSelected ? null : preset?.startDate ?? null
      const to = isAllPresetSelected ? null : preset?.endDate ?? null

      setDateFilters({ from, to })
    }
  }, [setDateFilters])

  // ========================================== //

  return {
    activeDatePreset,
    setActiveDatePreset,
    presets: DEFAULT_PRESETS
  }
}

export default useDateFilters
