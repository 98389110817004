import { type FC, useState, useRef } from 'react'
import { cnx } from '@carfluent/common'

import type { MessageTemplateItem } from 'api/types'
import Popover, { type PopoverProps } from 'components/common/Popover'
import TooltipedText from 'components/common/TooltipedText'

import { CLASS_NAME, POPOVER_CLASS_NAME } from './styles'

interface AddTemplateButtonProps {
  className?: string
  isDisabled: boolean
  items: MessageTemplateItem[]
  onAddTemplate: (id: number) => Promise<void>
}

const POPOVER_PROPS: Pick<PopoverProps, 'placement' | 'transform'> = {
  placement: {
    vertical: 'top',
    horizontal: 'left'
  },
  transform: { x: -84, y: -420 }
}

const AddTemplateButton: FC<AddTemplateButtonProps> = ({
  className,
  items,
  isDisabled,
  onAddTemplate,
  children
}) => {
  const refAddTemplate = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const onTemplateClick = async (id: number): Promise<void> => {
    await onAddTemplate(id)
    setIsOpen(false)
  }

  return (
    <div
      ref={refAddTemplate}
      className={cnx(CLASS_NAME, className, isDisabled && 'disabled')}
      onClick={() => !isDisabled && setIsOpen(true)}
    >
      <Popover
        open={isOpen}
        anchorEl={refAddTemplate.current}
        className={POPOVER_CLASS_NAME}
        onClose={() => setIsOpen(false)}
        {...POPOVER_PROPS}
      >
        {isOpen && items.map(({ name, id }) => (
          <div
            key={id}
            onClick={async () => await onTemplateClick(id)}
            className='badge'
          >
            <TooltipedText
              value={name}
            />
          </div>
        ))}
      </Popover>
      {children}
    </div>
  )
}

export default AddTemplateButton
