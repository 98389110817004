import { type FC, useMemo } from 'react'
import { cnx, formatCurrencyDecimal } from '@carfluent/common'

import { VehicleStateMap } from 'constants/names'

import { type VehiclesDropdownOptionProps } from './types'
import CLASS_NAME from './styles'

const formatStock = (stock: string | null): string | null => stock !== null ? `# ${stock}` : null

const VehiclesDropdownOption: FC<VehiclesDropdownOptionProps> = ({
  data,
  className
}) => {
  const {
    vehicleData,
    vehicleStateClass
  } = useMemo(() => {
    const vehicleStateName = VehicleStateMap[data.vehicleState]
    const vehicleStateClass = vehicleStateName?.toLowerCase() ?? 'unknown'

    const vehicleData = [
      vehicleStateName,
      formatCurrencyDecimal(data.salePrice, { mantissa: 0, allowZero: false, emptyValues: { zeroValue: '' } }),
      formatCurrencyDecimal(data.mileage, { mantissa: 0, postfix: ' mi', prefix: '' }),
      formatStock(data.stock)
    ].filter(Boolean)

    return {
      vehicleData,
      vehicleStateClass
    }
  }, [
    data.stock,
    data.mileage,
    data.salePrice,
    data.vehicleState
  ])

  return (
    <div className={cnx(CLASS_NAME, `option-${vehicleStateClass}`, className)}>
      <div className='vehicle-name'>
        <p>{`${data.year ?? ''} ${data.make ?? ''} ${data.model ?? ''} ${data.trim ?? ''}`}</p>
      </div>
      <div className={cnx('vehicle-state', vehicleStateClass)}>
        {vehicleData.map((el, i) => (
          <span className='option-data-container' key={i}>
            {el}
            {i !== vehicleData.length - 1 && <span className='option-data-divider' />}
          </span>
        ))}
      </div>
    </div>
  )
}

export default VehiclesDropdownOption
