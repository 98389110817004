import { NodeEnvironment } from 'constants/names'

const injectHotjar = (): void => {
  if (process.env.NODE_ENV !== NodeEnvironment.Production) {
    return
  }

  const scriptCode = `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:2794514,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `

  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.text = scriptCode

  document.head.appendChild(script)
}

export default injectHotjar
