import { parsers } from '@carfluent/common'

import { HOURS_IN_DAY, MIN_IN_HOUR, SECS_IN_MIN } from 'constants/constants'

const { parseDateStringUTC } = parsers

/**
 * Trims minutes if there are other 2 parts
 * @param seconds number of seconds
 * @returns string in dd:hh | hh:mm | mm
 * @param isMinutesRequired if we need required format dd:hh:mm
 */
export const formatSecondsToInterval = (seconds: number, isMinutesRequired: boolean = false): string => {
  const days = Math.floor(seconds / (SECS_IN_MIN * MIN_IN_HOUR * HOURS_IN_DAY))
  const hours = Math.floor((seconds % (SECS_IN_MIN * MIN_IN_HOUR * HOURS_IN_DAY)) / (SECS_IN_MIN * MIN_IN_HOUR))
  const minutes = Math.floor((seconds % (SECS_IN_MIN * MIN_IN_HOUR)) / SECS_IN_MIN)

  return days > 0
    ? `${Math.max(days, 0)}d ${Math.max(hours, 0)}h${isMinutesRequired ? ` ${Math.max(minutes, 0)}m` : ''}`
    : `${Math.max(hours, 0)}h ${Math.max(minutes, 0)}m`
}

export const formatTimeIntervalFromNow = (dateTime: string, isMinutesRequired = false): string => {
  return formatSecondsToInterval((Date.now() - (parseDateStringUTC(dateTime)?.getTime() ?? 0)) / 1000, isMinutesRequired)
}
