import { type FC } from 'react'
import { cnx, CellWrapper } from '@carfluent/common'
import { type Row } from '@tanstack/react-table'

import { type CellProps, type CellFC } from 'types'
import TooltipedText from 'components/common/TooltipedText'
import ConfigurableCellHOC from 'components/common/Table/cells/ConfigurableCellHOC'
import CLASS_NAME from './styles'


export interface ValueCellProps<TRow> {
  className?: string
  emptyValue?: string
  renderEndAdornment?: FC<CellAdornmentProps<TRow>>
  renderStartAdornment?: FC<CellAdornmentProps<TRow>>
}

export type CellAdornmentProps<TRow> = { row: Row<TRow> }

function ValueCell <TRow, TValue = unknown> ({
  className,
  emptyValue = '',
  getValue,
  renderEndAdornment,
  renderStartAdornment,
  row
}: CellProps<TRow, TValue> & ValueCellProps<TRow>): JSX.Element {
  const rawValue = getValue?.()

  if ((typeof rawValue === 'object') && (rawValue != null)) {
    console.error('ValueCell can be used only for primitive values')
    return (
      <CellWrapper className={cnx(CLASS_NAME, 'error', className)}>
        Error
      </CellWrapper>
    )
  }

  const value = `${rawValue ?? emptyValue}`
  const endAdornment = renderEndAdornment?.({ row })
  const startAdornment = renderStartAdornment?.({ row })
  const hasEndAdornment = endAdornment != null
  const hasStartAdornment = startAdornment != null

  return (
    <CellWrapper className={cnx(CLASS_NAME, className, hasStartAdornment && 'with-start-adornment')}>
      {hasStartAdornment && startAdornment}

      <TooltipedText
        rootClassName={cnx('cf-table-cell-text', hasEndAdornment && 'with-end-adornment')}
        value={value}
      />

      {hasEndAdornment && endAdornment}
    </CellWrapper>
  )
}

export default ValueCell

export function ValueCellHOC <TRow>(hocProps: ValueCellProps<TRow>) {
  const Comp: CellFC<TRow & ValueCellProps<TRow>> = ValueCell
  return ConfigurableCellHOC(Comp, hocProps)
}
