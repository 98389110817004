import { type ChangeEvent } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { cnx } from '@carfluent/common'

import type { CheckboxType } from 'types'
import TooltipedText from 'components/common/TooltipedText'
import IconSVG from 'components/inlineImages'

import CLASS_NAME from './styles'

export type CheckboxLabelProps<M extends CheckboxLabelMode = 'as-object'> =
  (M extends 'as-object' ? CheckboxLabelPropsAsObject : CheckboxType) & {
    disabled?: boolean
    checked?: boolean
    iconClassName?: string
    mode?: M
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
  }

export type CheckboxLabelMode = 'inlined' | 'as-object'

export interface CheckboxLabelPropsAsObject {
  value: CheckboxType
}

const CheckboxLabel = <M extends CheckboxLabelMode = 'as-object'>({
  iconClassName = '',
  mode,
  onChange,
  disabled,
  checked,
  ...restProps
}: CheckboxLabelProps<M>): JSX.Element => {
  const value = (mode ?? 'as-object') === 'as-object'
    ? (restProps as unknown as CheckboxLabelPropsAsObject).value
    : restProps as unknown as CheckboxType

  const hasIcon = Boolean(value?.icon)
  const image = hasIcon
    ? (
      <div className={cnx('cf-left-custom-icon', iconClassName)}>
        {typeof value.icon === 'string'
          ? <img src={value.icon} alt='' />
          : value.icon}
      </div>
      )
    : null

  return (
    <FormControlLabel
      classes={{ label: CLASS_NAME }}
      control={
        <Checkbox
          indeterminate={value?.indeterminate ?? false}
          checked={checked != null ? checked : value.checked}
          checkedIcon={<IconSVG.CheckboxChecked isDisabled={disabled} />}
          indeterminateIcon={<IconSVG.CheckboxIndeterminate />}
          classes={{ root: 'cf-checkbox-icon' }}
          color='default'
          icon={<IconSVG.CheckboxUnchecked />}
          name={`${value.id}`}
          onChange={onChange}
          disabled={disabled}
        />
      }
      label={<>{image} <TooltipedText value={value.name} /></>}
    />
  )
}

export default CheckboxLabel
