import type { FC, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { cn } from '@carfluent/common'

import type { TransactionListItemLine } from 'api/types'
import type { ItemProps } from 'components/common/Table/cells/MultipleItemsCell/types'
import ControlIdentifierBadge from 'components/common/ControlIdentifierBadge'
import { ControlOrDeal } from 'constants/route_helper'

import CLASS_NAME from './styles'

export type ControlItemProps = Pick<TransactionListItemLine, 'controlId' | 'controlEntity' | 'deal'> & ItemProps

const ControlItem: FC<ControlItemProps> = ({
  controlId,
  controlEntity,
  deal,
  isAlwaysVisible = false
}) => {
  const path = ControlOrDeal(controlEntity, deal)
  if (path == null) {
    return null
  }

  const handleLinkClick = (event: MouseEvent<Element>): void => {
    event?.stopPropagation()
  }

  return (
    <div className={cn(CLASS_NAME, !isAlwaysVisible && 'cf-tooltip-item')}>
      <div>
        <Link to={path} onClick={handleLinkClick}>
          <p>{controlId}</p>
        </Link>
      </div>

      {!isAlwaysVisible && (
        <ControlIdentifierBadge
          control={controlEntity ?? deal?.vehicle ?? null}
        />
      )}
    </div>
  )
}

export default observer(ControlItem)
