import { type FC } from 'react'
import { type MenuIconProps } from 'types'

const CrmSettingsMenuIcon: FC<MenuIconProps> = ({
  isActive = false
}) => {
  const pictogramFill = isActive ? '#fff' : '#101010'

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 4C2.89 4 2 4.89 2 6V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H12V18H4V8H20V12H22V8C22 7.46957 21.7893 6.96086 21.4142 6.58579C21.0391 6.21071 20.5304 6 20 6H12L10 4M18 14C17.9389 13.9989 17.8793 14.0193 17.8318 14.0577C17.7842 14.0961 17.7517 14.15 17.74 14.21L17.55 15.53C17.25 15.66 16.96 15.82 16.7 16L15.46 15.5C15.35 15.5 15.22 15.5 15.15 15.63L14.15 17.36C14.09 17.47 14.11 17.6 14.21 17.68L15.27 18.5C15.2301 18.8321 15.2301 19.1679 15.27 19.5L14.21 20.32C14.1651 20.3585 14.1346 20.4113 14.1237 20.4694C14.1128 20.5276 14.1221 20.5878 14.15 20.64L15.15 22.37C15.21 22.5 15.34 22.5 15.46 22.5L16.7 22C16.96 22.18 17.24 22.35 17.55 22.47L17.74 23.79C17.76 23.91 17.86 24 18 24H20C20.11 24 20.22 23.91 20.24 23.79L20.43 22.47C20.73 22.34 21 22.18 21.27 22L22.5 22.5C22.63 22.5 22.76 22.5 22.83 22.37L23.83 20.64C23.8579 20.5878 23.8672 20.5276 23.8563 20.4694C23.8454 20.4113 23.8149 20.3585 23.77 20.32L22.7 19.5C22.72 19.33 22.74 19.17 22.74 19C22.74 18.83 22.73 18.67 22.7 18.5L23.76 17.68C23.8049 17.6415 23.8354 17.5887 23.8463 17.5306C23.8572 17.4724 23.8479 17.4122 23.82 17.36L22.82 15.63C22.76 15.5 22.63 15.5 22.5 15.5L21.27 16C21 15.82 20.73 15.65 20.42 15.53L20.23 14.21C20.2237 14.153 20.1969 14.1003 20.1546 14.0617C20.1122 14.023 20.0573 14.0011 20 14M19 17.5C19.83 17.5 20.5 18.17 20.5 19C20.5 19.83 19.83 20.5 19 20.5C18.16 20.5 17.5 19.83 17.5 19C17.5 18.17 18.17 17.5 19 17.5Z'
        fill={pictogramFill}
      />
    </svg>

  )
}

export default CrmSettingsMenuIcon
