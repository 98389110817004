import { cn, CellWrapper } from '@carfluent/common'

import formatCurrencyLabel from 'utils/accounting/formatCurrencyLabel'
import type { CellProps } from 'types'

export interface BalanceCellProps {
  className?: string
}

/**
 * @deprecated - use `components/common/Table/cells/AmountCell` instead
 */
function BalanceCell <TData> ({
  className,
  getValue
}: CellProps<TData> & BalanceCellProps): JSX.Element {
  const value = getValue() as string | number

  return (
    <CellWrapper className={cn('cf-balance-cell', className)}>
      <p>{formatCurrencyLabel(value)}</p>
    </CellWrapper>
  )
}

export default BalanceCell
