import { css } from '@emotion/css'

export default css(`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #101010;
  margin-bottom: 16px;
  display: flex;
  text-transform: capitalize;
  align-items: center;
  width: 100%;

  .birth-date { color: #21212180; }

  .lead-info-empty {
    color: inherit;
    font-weight: 400;
    font-size: 16px;
  }

  .lead-info-details {
    width: 100%;
    display: flex;
  }

  .status-container {
    display: flex;
    align-items: baseline;
  }

  .lead-name-container {
    max-width: 100%;

    .name {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 120% */
      letter-spacing: 0.15px;
    }

    :has(.name-with-birthDate) {
      max-width: calc(100% - 132px);
    }
  }

  .lead-info-deal-status {
    width: min-content;
    max-width: unset;
    margin-left: 8px;
    text-transform: none;
    padding: 0 9px;
    height: 26px;

    &.deal-status-complete {
      background-color: #CFE6D9;
      color: #212121CC;
    }
  }
`)
