import { type FC, useContext, useMemo } from 'react'
import { Navigate } from 'react-router-dom'
import { CRMSettingsSubRoutes } from 'constants/route_helper'
import AuthCTX from 'store/auth'
import { UserRoles } from 'api/types'

const DefaultCRMSettingsSubRoute: FC = () => {
  const { userRoles } = useContext(AuthCTX)
  const toRoute = useMemo(() => {
    if (userRoles.includes(UserRoles.BDC)) {
      return CRMSettingsSubRoutes.EmailTemplates
    }

    return CRMSettingsSubRoutes.GeneralSettings
  }, [userRoles])

  return (
    <Navigate to={toRoute} replace />
  )
}

export default DefaultCRMSettingsSubRoute
