import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;
  
  .cf-popover {
    left: 60px;
    width: 351px;
  }

  .cf-recon-car-header {
    display: flex;
    align-items: center;
    
    span {
      font-size: 14px;
      line-height: 24px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 350px;
      display: block;
      width: calc(100% - 32px);
    }
    
    svg {
      margin-right: 8px;
    }

    @media (max-width: 1600px) {
      max-width: 300px;
    }
  }
  
  .recon-car-step {
    border-radius: 4px;
    background: #F1F7FF;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding: 0 8px;
    margin-top: 10px;
    margin-bottom: 8px;
    
    span {
      font-weight: 500;
    }
   }

  .cf-recon-car-content {
    display: flex;
    align-items: center;

    .cf-recon-car-img {
      max-height: 60px;
      max-width: 92px;
      min-width: 92px;
      margin-right: 8px;
    }
  
    .cf-recon-car-specs {
      display: flex;
      flex-direction: column;

      a {
        width: 100%;
        text-align: left;

        &, :visited {
          color: #458FFF;
        }

        &, :hover {
          text-decoration: none;
        }
      }
    }
  }
  
  .label-tags-block {
    display: flex;
    
    .deposited-label, .complete-incomplete-label {
      margin-top: 6px;
    }
    
    .recon-car-tags {
      margin-left: 6px;
      
      > p {
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
  }
`)
