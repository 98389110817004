import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGCross: FC<IconProps> = ({ color = '#101010', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8.97708 7.99933L13.1305 3.84581C13.4008 3.57564 13.4008 3.13881 13.1305 2.86864C12.8603 2.59847 12.4235 2.59847 12.1533 2.86864L7.99978 7.02216L3.84639 2.86864C3.57609 2.59847 3.13939 2.59847 2.86923 2.86864C2.59893 3.13881 2.59893 3.57564 2.86923 3.84581L7.02262 7.99933L2.86923 12.1528C2.59893 12.423 2.59893 12.8598 2.86923 13.13C3.00387 13.2648 3.1809 13.3325 3.35781 13.3325C3.53472 13.3325 3.71162 13.2648 3.84639 13.13L7.99978 8.97649L12.1533 13.13C12.2881 13.2648 12.465 13.3325 12.6419 13.3325C12.8188 13.3325 12.9957 13.2648 13.1305 13.13C13.4008 12.8598 13.4008 12.423 13.1305 12.1528L8.97708 7.99933Z' fill={color}/>
    </svg>
  )
}

export default IconSVGCross
