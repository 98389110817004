import type { FC } from 'react'
import type { IconProps } from 'types'

const CaseIcon: FC<IconProps> = ({ color = '#21212180', width = 16, height = 16 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'>
      <path d='M19.2 7.57895H15.6V5.78947C15.6 4.79632 14.799 4 13.8 4H10.2C9.201 4 8.4 4.79632 8.4 5.78947V7.57895H4.8C3.801 7.57895 3.009 8.37526 3.009 9.36842L3 19.2105C3 20.2037 3.801 21 4.8 21H19.2C20.199 21 21 20.2037 21 19.2105V9.36842C21 8.37526 20.199 7.57895 19.2 7.57895ZM13.8 7.57895H10.2V5.78947H13.8V7.57895Z' fill={color} />
    </svg>
  )
}

export default CaseIcon
