import { type FC, useCallback, useEffect, useState } from 'react'
import { TimeSelect, FormDatePicker, type UseFormReturn } from '@carfluent/common'
import { isBefore, parse } from 'date-fns'

import { getHoursMinutesTime } from 'utils/parse_date'
import { TIME_12H_MIN_SPACE_AMPM_FORMAT } from 'constants/constants'
import DropdownIcon from 'components/common/DropdownIcon'

import type { OpenTaskFormData, ErrTouchShortcuts } from '../Task/hook/types'

interface DateTimeInputsProps {
  shouldGetCurrentDate?: boolean
  dueDate: Date | null
  time: string | null
  onDateChange: (id: string, dueDate: Date | null) => void
  onTimeChange: (id: string, time: string) => void
  onBlur: UseFormReturn<OpenTaskFormData, ErrTouchShortcuts>['onBlur']
  dueDateTouched: boolean
  dueDateError: string | null
  timeError: string | null
  dateId: string
  timeId: string
  businessHours?: [string, string] | null
}

const DateTimeInputs: FC<DateTimeInputsProps> = ({
  shouldGetCurrentDate = false,
  dueDate,
  time,
  onDateChange,
  onTimeChange,
  onBlur,
  dueDateTouched,
  dueDateError,
  timeError,
  dateId,
  timeId,
  businessHours
}) => {
  const opening = businessHours?.[0]
  const closing = businessHours?.[1]
  const [currentDate, setCurrentDate] = useState(shouldGetCurrentDate ? new Date() : (dueDate ?? null))
  const [currentTime, setCurrentTime] = useState(shouldGetCurrentDate ? getHoursMinutesTime(new Date()) : (time ?? ''))

  const onChangeDate = useCallback((_, value: Date | null): void => {
    onDateChange(dateId, value)
    setCurrentDate(value)
  }, [onDateChange, dateId])

  const onChangeTime = useCallback((value: string): void => {
    onTimeChange(timeId, value)
    setCurrentTime(value)
  }, [onTimeChange, timeId])

  const isTimeValue = time != null && time !== ''

  useEffect(() => {
    if (!shouldGetCurrentDate) {
      return
    }
    if (time !== currentTime) {
      onChangeTime(currentTime)
    }
    if (dueDate !== currentDate) {
      onChangeDate(dateId, currentDate)
    }
  }, [])

  useEffect(() => {
    const now = new Date()

    const openingTime = opening != null ? parse(opening, TIME_12H_MIN_SPACE_AMPM_FORMAT, new Date()) : null
    const closingTime = closing != null ? parse(closing, TIME_12H_MIN_SPACE_AMPM_FORMAT, new Date()) : null

    if (openingTime == null || closingTime == null || isTimeValue) {
      return
    }

    if (isBefore(now, openingTime)) {
      onChangeTime(opening ?? '')
    } else if (isBefore(closingTime, now)) {
      onChangeDate(dateId, new Date(now.setDate(now.getDate() + 1)))
      onChangeTime(opening ?? '')
    }
  }, [])

  return (
    <>
      <div className='due-date prefilled-value'>
        <FormDatePicker
          id={dateId}
          label='Due date'
          onChange={onChangeDate}
          onBlur={onBlur}
          error={dueDateError}
          value={currentDate}
          touched={dueDateTouched}
          data-test-id='lead-task-due-date'
        />
      </div>

      <div className={`time ${isTimeValue ? 'prefilled-value' : ''}`}>
        <TimeSelect
          id={timeId}
          classes={{ option: 'time-option' }}
          label='Time'
          onChange={onChangeTime}
          value={currentTime}
          error={timeError ?? ''}
          data-test-id='lead-task-time'
          DropdownIconComponent={DropdownIcon}
          opening={opening}
          closing={closing}
        />
      </div>
    </>
  )
}

export default DateTimeInputs
