import { css } from '@emotion/css'

export default css(`
  margin-top: 32px;

  .cf-open-balances-counter {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 14px;
  }

  .cf-open-balances-table-root {
    background-color: #F3F3F3;
    border-radius: 4px;
    padding: 24px;

    .cf-filters-wrapper {
      background-color: #F3F3F3;
      padding: 0;
      height: unset;

      .MuiInputBase-root,
      .cf-period-button,
      .period-button {
        background: #FFFFFF;
        background-color: #FFFFFF !important;
      }

      .period-button {
        height: 37px;
      }
    }
  }
`)
