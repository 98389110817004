import { css } from '@emotion/css'

export default css(`
  &.cf-has-error {
    .cf-drop-zone-content {
      border: 2px dashed #B00020;
    }
  }

  .cf-drop-zone-content {
    box-sizing: border-box;
    min-height: 96px; 
    width: 100%;
    height: 100%;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 2px dashed #C99B86;
    background: #FFF;
    position: relative;
  }

  .cf-dnd-overlay {
    padding: 16px;
    border-radius: 8px;
    border: 2px dashed #C99B86;
    background: linear-gradient(0deg, rgba(201, 155, 134, 0.10) 0%, rgba(201, 155, 134, 0.10) 100%), #FFF;
    text-transform: none;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
  }

  .cf-section-loader {
    opacity: 1;
    background: #FFF;
  }

  .cf-drag-capture {
    width: 100%;
    text-align: center;

    label {
      display: inline;
      color: #458FFF;
      cursor: pointer;
    }
  }

  .cf-form-field-error {
    color: #B00020;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  .cf-file-item {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #F3F3F3;
  }

  .cf-file-remove {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cf-tablet-message {
    display: none;
  }

  .cf-mobile-message {
    display: block;
  }

  @media (min-width: 1280px) {
    .cf-tablet-message {
      display: block;
    }

    .cf-mobile-message {
      display: none;
    }
  }
`)
