import { type FC, useMemo } from 'react'
import { cnx } from '@carfluent/common'
import { RadialBarChart, RadialBar, PolarAngleAxis } from 'recharts'

import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'
import { type ProgressChartProps } from './types'
import CLASS_NAME from './styles'

const CIRCLE_SIZE = 248
const BACKGROUND_PROPS = { fill: '#ECF4FF' }

const ProgressChart: FC<ProgressChartProps> = ({
  className,
  isLoading = false,
  progress: _progress,
  salesTarget
}) => {
  const progress = salesTarget === 0 ? 100 : Math.min(_progress, 100)

  const DATA = useMemo(() => ([
    { name: '', value: progress }
  ]), [progress])

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, className)}>
      {isLoading && (
        <div className='progress-chart is-loading'>
          <BlockSkeleton className='chart-skeleton' isLoading/>
          <div className='progress-chart-overlay'/>
          <BlockSkeleton className='target-skeleton' isLoading/>
        </div>
      )}

      {!isLoading && (
        <div className='progress-chart'>
          <RadialBarChart
            width={CIRCLE_SIZE}
            height={CIRCLE_SIZE}
            innerRadius={110}
            outerRadius={140}
            barSize={30}
            data={DATA}
            startAngle={190}
            endAngle={-10}
          >
            <PolarAngleAxis
              type='number'
              domain={[0, 100]}
              tick={false}
            />

            <RadialBar
              background={BACKGROUND_PROPS}
              dataKey='value'
              cornerRadius={6}
              fill='#458FFF'
            />
          </RadialBarChart>

          <div className='target-info'>
            <h6>{progress}%</h6>
            <div className='target-helper'>Target - {salesTarget} cars</div>
            <div className='target-helper'>per month</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProgressChart
