import { ChangeEvent, useCallback } from 'react'
import { UI, FormInput } from '@carfluent/common'
import { observer } from 'mobx-react-lite'

import type { CellProps, KeyVal } from 'types'
import type { EditableCellProps } from 'components/common/Table/cells/editable_cell'
import { CELL_CLASS } from './styles'

const { CellWrapper } = UI

export type InputCellProps<TRow extends KeyVal> = CellProps<TRow> & EditableCellProps<string> & {
  label?: string
  showTooltip?: boolean
}

function InputCell<TRow extends KeyVal> (
  props: InputCellProps<TRow>
): JSX.Element {
  const {
    column, label,
    onChange: _onChange, getValue, row
  } = props

  const _value = getValue()
  const value = typeof _value === 'string' ? _value : null

  const onChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    _onChange(row.index, column.id, evt.target.value)
  }, [_onChange, column.id, row.index])

  return (
    <CellWrapper className={CELL_CLASS}>
      <FormInput
        id={column.id}
        placeholder={label}
        onChange={onChange}
        value={value}
      />
    </CellWrapper>
  )
}

export default observer(InputCell)
