import { css } from '@emotion/css'

interface StylesReturn {
  pageWrapper: string
  reportContainer: string
}

const createStyleClass = (): StylesReturn => {
  return {
    reportContainer: css(`
      width: 900px;
      margin: 0 auto;
      &.cf-lg {
        width: 1300px;
      }
    `),
    pageWrapper: css(`
      & {
        &.g-overrides {
          margin: var(--content-margin-big) auto;
          box-sizing: border-box;

          @media (max-width: 1400px) {
            margin: var(--content-margin-big) 20px;
            margin-left: 20px;
          }
        } 
        
        .cf-top-actions {
          height: unset;
        }

        .cf-report-top-actions {          
          .cf-top-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          
          .cf-block {
            display: flex;
            
            .cf-title {
              font-size: 24px;
              letter-spacing: 0.18px;
              color: #101010;
            }
          }
          
          .cf-link-button.cf-button-root.cf-button-variant-text {
            margin-right: 16px;
            text-underline-position: auto;
    
            .cf-button-content {
              font-family: Roboto;
              font-weight: 700;
    
              :active {
                color: #212121;
                font-weight: 500;
              }
            }
    
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    `)
  }
}

export default createStyleClass
