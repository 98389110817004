import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import type { EmailAttachment } from 'api/types'
import { downloadBlob } from 'utils/general'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

import IconSVG from 'components/inlineImages'
import CLASS_NAME from './styles'

export interface AttachmentItemsProps {
  className?: string
  items: EmailAttachment[]
  onDeleteAttachment?: (id: string | number) => void
}

const AttachmentItems: FC<AttachmentItemsProps> = ({
  className = '',
  items,
  onDeleteAttachment
}) => {
  const { showAlert } = useCustomSnackbar()

  const onItemClick = async (item: EmailAttachment): Promise<void> => {
    try {
      const response = await fetch(item.url)
      const blob = await response.blob()

      downloadBlob(blob, item.fileName)
    } catch (err) {
      showAlert(err)
    }
  }

  return (
    <div className={cnx(CLASS_NAME, className)}>
      {items.map((item, index) => {
        return (
          <p className='attachment-item' key={`${index}_${item.fileName}`}>
            <a
              href={item.url}
              onClick={async (e) => {
                e.preventDefault()
                e.stopPropagation()

                await onItemClick(item)
              }}
            >
              {item.fileName}
            </a>
            {onDeleteAttachment != null && (
              <div
                className='delete-icon'
                onClick={() => onDeleteAttachment(item.id)}
              >
                <IconSVG.Close width={16} height={16} />
              </div>
            )}
          </p>
        )
      })}
    </div>
  )
}

export default AttachmentItems
