/**
 * This hook is initially generated automatically according to the architectural approach of CarFluent:
 * - https://dev.azure.com/carfluent/CarFluent/_wiki/wikis/CarFluent.wiki/40/Page-level-Architecture
 * - https://dev.azure.com/carfluent/CarFluent/_wiki/wikis/CarFluent.wiki/74/Component-Structure
*
* Please read the documents mentioned above before changing the hook structure.
*/

import axios from 'axios'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useLoader, useModal } from '@carfluent/common'

import { type DictionaryItem, type Vehicle, type LeadMessageDto } from 'api/types'
import CRMApiProvider from 'api/crm.api'
import { type LeadFormData } from 'types'
import NotificationsAPIProvider from 'api/notifications.api'
import IdentityApiProvider from 'api/identity.api'
import CustomersCoreApiProvider from 'api/customersCore.api'
import { NOT_FOUND_STATUS, Routes } from 'constants/route_helper'
import parseUsers from 'utils/parseUsers'
import SettingsCTX from 'store/settings'
import { getParsedToken } from 'services/storage.service'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useEffectOnce from 'hooks/useEffectOnce'
import useSendEmail from 'hooks/useSendEmail'
import VehiclesApiProvider from 'api/vehicles.api'
import { ONE_LOAD_PAGE_SIZE, userRolesWithCRMPermission } from 'constants/constants'

import type { UseLeadDetailsViewReturn, ActionsMenuCloseReason } from './types'
import useLeadTasks from './useLeadTasks'
import useLeadMessages from './useLeadMessages'
import { useTimeline } from '../components/Timeline/hook'
import { DEFAULT_LEAD, Messages } from './constants'
import { parseLeadDetails } from './parser'
import { serializeFormData } from './serializer'
import { extractEarliestAndLatestTime } from './utils'
import type { WebChatInfo } from '../components/MessangerPreview'

interface LocationState {
  isLeadFreshlyCreated?: boolean
}

const useLeadDetailsView = (): UseLeadDetailsViewReturn => {
  const navigate = useNavigate()
  const location = useLocation<LocationState>()
  const token = getParsedToken()
  const dealerId: string = token?.dealer ?? ''
  const { showAlert, showSuccess } = useCustomSnackbar()
  const { id: leadId = '' } = useParams<{ id: string }>()
  const [lead, setLead] = useState<LeadFormData>(DEFAULT_LEAD())
  const { dealer } = useContext(SettingsCTX)

  const [isCarsMenuOpen, setCarsMenuOpen] = useState(false)
  const [statuses, setStatuses] = useState<DictionaryItem[]>([])
  const [temperatures, setTemperatures] = useState<DictionaryItem[]>([])
  const [sources, setSources] = useState<DictionaryItem[]>([])
  const [reminderSubjects, setReminderSubjects] = useState<DictionaryItem[]>([])
  const [taskPriorities, setTaskPriorities] = useState<DictionaryItem[]>([])
  const [assignedTo, setAssignedTo] = useState<DictionaryItem[]>([])
  const [emailTemplates, setEmailTemplates] = useState<DictionaryItem[]>([])
  const [suggestedCars, setSuggestedCars] = useState<Vehicle[]>([])
  const [dealState, setDealState] = useState<string | null>(null)
  const [messages, setMessages] = useState<LeadMessageDto[] | null>(null)
  const [webChatInfo, setWebChatInfo] = useState<WebChatInfo | null>(null)
  const [pausedByUserName, setPausedByUserName] = useState<string | null>(null)

  const leadTemperature = lead?.leadTemperatureId ?? 1
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(
    location.state?.isLeadFreshlyCreated ?? false
  )

  const businessHours = extractEarliestAndLatestTime(dealer?.dealerBusinessHours ?? null)

  const { isLoading, startLoader, stopLoader } = useLoader()

  const {
    isModalOpen: isDeleteModalOpen,
    onOpenModal: _onOpenModalDeleteLead,
    onCloseModal: _onCloseModalDeleteLead
  } = useModal()

  const {
    isModalOpen: isEditModalOpen,
    onOpenModal: _onOpenModalEditLead,
    onCloseModal: _onCloseModalEditLead
  } = useModal()

  const {
    isModalOpen: isActionsMenuOpen,
    onOpenModal: openActionsMenu,
    onCloseModal: closeActionsMenu
  } = useModal()

  const {
    isModalOpen: isLeadLostModalOpen,
    onOpenModal: _onOpenLeadLostModal,
    onCloseModal: _onCloseLeadLostModal
  } = useModal()

  const {
    isModalOpen: isSendEmailOpen,
    onOpenModal: _onOpenSendEmail,
    onCloseModal: _onCloseSendEmail
  } = useModal()

  const {
    isModalOpen: isCreateDealOpenModal,
    onOpenModal: _onCreateDealClick,
    onCloseModal: onCloseAddDeal
  } = useModal()

  const linkToWebsite = `${dealer?.dealerFeeMarkupSettings.websiteBaseUrl ?? ''}dealer/${dealerId}`

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const timelineProps = useTimeline({
    leadId,
    sources,
    suggestedCars,
    emailTemplates,
    carsOfInterest: lead.carsOfInterest ?? [],
    primaryCarOfInterestId: lead.primaryCarOfInterestId,
    linkToWebsite
  })

  const onActionsModalClose = useCallback(() => {
    setIsActionsModalOpen(false)
  }, [])

  const onGoBack = useCallback((): void => {
    navigate(-1)
  }, [navigate])

  const onCopy = useCallback((nameOfFiled: string): void => {
    showSuccess(`${nameOfFiled} copied.`)
  }, [showSuccess])

  const setParsedLead = useCallback(async (rawLead): Promise<LeadFormData | null> => {
    let subjects = [...reminderSubjects]

    if (subjects.length === 0) {
      const { items } = await CRMApiProvider.getReminderSubjects()
      subjects = items
    }

    const leadData = parseLeadDetails(rawLead, subjects)
    setLead(leadData)

    return leadData
  }, [reminderSubjects])

  /**
   * @leadId lead id
   * @isLoaderShown controls if we need to show whole lead page loader (by default = true).
   * Very often getLead is called after any related updates which might
   * have their own spinner so lead spinner is not needed in those cases.
   */
  const getLead = useCallback(async (
    leadId: number | string,
    isLoaderShown: boolean = true
  ): Promise<LeadFormData | null> => {
    try {
      if (isLoaderShown) {
        startLoader()
      }

      const rawLead = await CRMApiProvider.getLead(leadId)
      const leadData = await setParsedLead(rawLead)

      return leadData
    } catch (e) {
      if (axios.isAxiosError(e) && e?.response?.status === NOT_FOUND_STATUS) {
        navigate(Routes.NoDealerAndVin)
      }
      return null
    } finally {
      if (isLoaderShown) {
        stopLoader()
      }
    }
  }, [navigate, startLoader, stopLoader, setParsedLead])

  const getSuggestedCars = useCallback(async (vehicleId: number | null) => {
    const { items } = await VehiclesApiProvider.getSuggestedVehicles({
      take: 10,
      skip: 0,
      vehicleId
    })

    setSuggestedCars(items.map(({ description: _, ...car }) => car))
  }, [])

  const onSubmitDelete = useCallback(async () => {
    try {
      await CRMApiProvider.deleteLead(leadId)
    } finally {
      _onCloseModalDeleteLead()
      showSuccess(Messages.SuccessLeadDelete)
      onGoBack()
    }
  }, [leadId, _onCloseModalDeleteLead, showSuccess, onGoBack])

  const onSubmitUpdate = useCallback(async (updatedFields: LeadFormData) => {
    const payload = serializeFormData(updatedFields)
    try {
      startLoader()

      const rawLead = await CRMApiProvider.updateLead({ ...payload, leadId, tasks: [] })
      await setParsedLead(rawLead)
      showSuccess(Messages.SuccessLeadUpdate)
    } catch (e) {
      showAlert(e)
    } finally {
      _onCloseModalDeleteLead()
      stopLoader()
    }
  }, [
    startLoader, leadId, setParsedLead,
    showSuccess, showAlert, _onCloseModalDeleteLead,
    stopLoader
  ])

  const onStatusUpdate = useCallback(async (leadStatusId) => {
    try {
      startLoader()
      const rawLead = await CRMApiProvider.updateStatus(leadId, leadStatusId)
      await setParsedLead(rawLead)
      showSuccess(Messages.SuccessLeadUpdate)
    } catch (e) {
      showAlert(e)
    } finally {
      stopLoader()
    }
  }, [leadId, setParsedLead, showAlert, showSuccess, startLoader, stopLoader])

  const onActionsMenuClose = useCallback((reason: ActionsMenuCloseReason) => {
    if (reason === 'click-out') {
      closeActionsMenu()
      setCarsMenuOpen(false)
    }
  }, [closeActionsMenu])

  const onActionsMenuOpen = useCallback(() => {
    setCarsMenuOpen(false)
    openActionsMenu()
  }, [openActionsMenu])

  const onAddCarClick = useCallback(() => {
    setCarsMenuOpen(true)
  }, [])

  const onCreateDealClick = useCallback(() => {
    _onCreateDealClick()
    closeActionsMenu()
  }, [closeActionsMenu])

  const onCarSelected = useCallback(async (carId: number) => {
    try {
      closeActionsMenu()
      setCarsMenuOpen(false)
      startLoader()

      const rawLead = await CRMApiProvider.addCarOfInterest(leadId, carId)
      const leadData = await setParsedLead(rawLead)

      await getSuggestedCars(leadData?.primaryCarOfInterestId ?? null)
    } catch (err) {
      showAlert(err)
    } finally {
      stopLoader()
    }
  }, [
    closeActionsMenu, startLoader, leadId,
    setParsedLead, getSuggestedCars, showAlert,
    stopLoader
  ])

  const onDeleteCar = useCallback(async (carId: number) => {
    try {
      startLoader()
      await CRMApiProvider.deleteCarOfInterest(leadId, carId)
      const lead = await getLead(leadId)
      await getSuggestedCars(lead?.primaryCarOfInterestId ?? null)
    } catch (err) {
      showAlert(err)
    } finally {
      stopLoader()
    }
  }, [
    leadId,
    startLoader,
    stopLoader,
    getLead,
    showAlert,
    getSuggestedCars
  ])

  const onMakePrimaryCar = useCallback(async (carId: number) => {
    try {
      startLoader()

      const payload = serializeFormData({ ...lead, primaryCarOfInterestId: carId })
      const rawLead = await CRMApiProvider.makeCarOfInterestPrimary(payload)
      const leadData = await setParsedLead(rawLead)
      await getSuggestedCars(leadData?.primaryCarOfInterestId ?? null)
    } catch (err) {
      showAlert(err)
    } finally {
      stopLoader()
    }
  }, [startLoader, lead, setParsedLead, getSuggestedCars, showAlert, stopLoader])

  const {
    leadTasks,
    logTypeId,
    isTaskListEmpty,
    onSubmitTask,
    onSubmitActivityLog,
    onDeleteTask,
    onScheduleTaskButtonClick,
    onActivityTaskButtonClick,
    removeTask: _removeTask,
    onCloseActivity: _onCloseActivity,
    updateTasks,
    onSubmitCompleteTask,
    onSubmitRespondTask,
    onSubmitRespondOnLeadTask,
    onViewEmailClick
  } = useLeadTasks({
    reminderSubjects,
    leadTemperature,
    leadTasks: lead.tasks,
    leadTimelines: timelineProps.timelines,
    leadId,
    onViewEmailClick: timelineProps.showThreadBtnProps.onLoadEmailThread
  })

  const onCloseActivity = useCallback((): void => {
    onActionsModalClose()
    _onCloseActivity()
  }, [_onCloseActivity, onActionsModalClose])

  const removeTask = useCallback((taskId: number | null): void => {
    onActionsModalClose()
    _removeTask(taskId)
  }, [_removeTask, onActionsModalClose])

  const fetchLead = useCallback(async () => await getLead(leadId), [getLead, leadId])

  const onSubmitLeadUpdate = useCallback(async () => {
    await fetchLead()
  }, [fetchLead])

  const onSubmitLeadLost = useCallback(async (isLost: boolean, lostReasonId: number | null) => {
    await onSubmitUpdate({ ...lead, isLost, lostReasonId })
  }, [lead, onSubmitUpdate])

  const onLeadLostToggleClick = useCallback(async () => {
    if (lead.isLost) {
      return await onSubmitLeadLost(false, null)
    }

    closeActionsMenu()
    _onOpenLeadLostModal()
  }, [lead.isLost, closeActionsMenu, _onOpenLeadLostModal, onSubmitLeadLost])

  const onUpdatePauseOrResumeLead = useCallback(async () => {
    try {
      startLoader()
      await CRMApiProvider.updatePauseOrResumeLead(leadId, !lead?.isPaused)
      const result = await fetchLead()
      const pausedByUserName = assignedTo.find((user) => user.id === result?.pausedByUserId)?.name ?? null
      setPausedByUserName(pausedByUserName)
      showSuccess(`Automated emails/messages ${lead?.isPaused ? 'paused' : 'resumed'}.`)
    } catch (err) {
      showAlert(err)
    } finally {
      stopLoader()
      closeActionsMenu()
    }
  }, [startLoader, leadId, showAlert, stopLoader, fetchLead, assignedTo, lead?.isPaused, showSuccess])

  const {
    isOpen: isOpenSendEmailModal,
    onClose: onCloseSendEmailModal,
    attachInventoryPhotosProps,
    ...sendEmailForm
  } = useSendEmail({
    isOpen: isSendEmailOpen,
    onClose: _onCloseSendEmail,
    leadEmail: lead.email,
    leadId,
    linkToWebsite,
    carOfInterest: lead.carsOfInterest.find((car) => car.isPrimary),
    primaryCarOfInterestId: lead.primaryCarOfInterestId
  })

  const messagesProps = useLeadMessages({
    reminderSubjects,
    leadId,
    updateTasks,
    carsOfInterest: lead.carsOfInterest ?? [],
    leadPhoneNumber: lead.phoneNumber,
    isUnsubscribed: lead.isUnsubscribed
  })

  const { onOpenModal: onOpenViewChatPopover } = messagesProps

  const onOpenChatMessage = useCallback(async () => {
    messagesProps.onOpenModal()
    const { items } = await messagesProps.transport.getMessages()
    setMessages(items)
  }, [])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * clear nav state property for showing Lead actions popup
   */
  useEffect(() => {
    window.history.replaceState(
      { isLeadFreshlyCreated: false },
      document.title
    )
  }, [location])

  useEffect(() => {
    const getItems = async (): Promise<void> => {
      const [
        statusesResponse,
        temperaturesResponse,
        sourcesResponse,
        taskPrioritiesResponse,
        reminderSubjectsResponse,
        leadAssignedToResponse,
        emailTemplatesResponse
      ] = await Promise.all([
        CRMApiProvider.getLeadStatuses(),
        CRMApiProvider.getLeadTemperature(),
        CRMApiProvider.getLeadSources(),
        CRMApiProvider.getTaskPriorities(),
        CRMApiProvider.getReminderSubjects(),
        IdentityApiProvider.getUsers(userRolesWithCRMPermission),
        NotificationsAPIProvider.getLeadEmailTemplates({ skip: 0, take: ONE_LOAD_PAGE_SIZE })
      ])

      setStatuses(statusesResponse.items)
      setTemperatures(temperaturesResponse.items)
      setSources(sourcesResponse.items)
      setTaskPriorities(taskPrioritiesResponse.items)
      setReminderSubjects(reminderSubjectsResponse.items)
      setAssignedTo(parseUsers(leadAssignedToResponse.items, 'Unassigned'))
      setEmailTemplates(emailTemplatesResponse.items)
    }

    void getItems()
  }, [])

  useEffect(() => {
    if (assignedTo.length !== 0 && lead != null) {
      const pausedByUserName = assignedTo?.find((user) => user.id === lead?.pausedByUserId)?.name ?? null
      setPausedByUserName(pausedByUserName)
    }
  }, [lead, assignedTo])

  useEffectOnce(() => {
    const runEffect = async (): Promise<void> => {
      startLoader()
      try {
        const lead = await getLead(leadId)
        updateTasks(lead?.tasks ?? [])
        await getSuggestedCars(lead?.primaryCarOfInterestId ?? null)

        if (lead?.dealId != null) {
          try {
            const res = await CustomersCoreApiProvider.getDealForDashboard(lead.dealId)
            setDealState(res.dealState)
          } catch {
            setDealState(null)
          } finally {
            stopLoader()
          }
        }
      } catch (e) {
        showAlert(e)
      } finally {
        stopLoader()
      }
    }

    void runEffect()
  }, [leadId, assignedTo, getLead, startLoader, stopLoader])

  // ========================================== //

  return {
    isActionsMenuOpen,
    isActionsModalOpen,
    isCarsMenuOpen,
    lead,
    linkToWebsite,
    leadPhoneNumber: lead.phoneNumber,
    statuses,
    temperatures,
    sources,
    isLoading,
    onActionsMenuClose,
    onActionsModalClose,
    onActionsMenuOpen,
    onAddCarClick,
    onCarSelected,
    onCopy,
    onDeleteCar,
    onUpdateLead: onSubmitUpdate,
    onGoBack,
    onMakePrimaryCar,
    onScheduleTaskButtonClick,
    onActivityTaskButtonClick,
    onStatusUpdate,
    webChatInfo,
    setWebChatInfo,
    activityTasksProps: {
      logTypeId,
      assignedTo,
      reminderSubjects,
      onCloseActivity,
      onSubmitActivityLog
    },
    onLeadLostToggleClick,
    leadTasksProps: {
      leadId,
      taskPriorities,
      assignedTo,
      reminderSubjects,
      leadTemperature,
      isTaskListEmpty,
      leadTasks,
      businessHours,
      removeTask,
      onSubmitTask,
      onSubmitCompleteTask,
      onSubmitRespondTask,
      onSubmitRespondOnLeadTask,
      onViewEmailClick,
      onDeleteTask,
      onOpenViewChatPopover
    },
    leadDeleteProps: {
      isModalOpen: isDeleteModalOpen,
      onOpenModal: () => {
        closeActionsMenu()
        _onOpenModalDeleteLead()
      },
      onCloseModal: _onCloseModalDeleteLead,
      onCancel: _onCloseModalDeleteLead,
      onSubmit: onSubmitDelete
    },
    leadEditProps: {
      isModalOpen: isEditModalOpen,
      onOpenModal: () => {
        closeActionsMenu()
        _onOpenModalEditLead()
      },
      onCloseModal: _onCloseModalEditLead,
      onCancel: _onCloseModalEditLead,
      onSubmit: onSubmitLeadUpdate
    },
    leadLostProps: {
      isOpen: isLeadLostModalOpen,
      onClose: _onCloseLeadLostModal,
      onSubmit: onSubmitLeadLost
    },
    timelineProps: {
      ...timelineProps,
      emailThreadProps: {
        ...timelineProps.emailThreadProps,
        onSubmitLeadUpdate
      }
    },
    sendEmailProps: {
      carsOfInterest: lead.carsOfInterest ?? [],
      isOpen: isOpenSendEmailModal,
      linkToWebsite,
      onClose: onCloseSendEmailModal,
      onOpen: _onOpenSendEmail,
      attachInventoryPhotosProps,
      suggestedCars,
      emailTemplates,
      ...sendEmailForm
    },
    onOpenChatMessage,
    messagesProps: {
      ...messagesProps,
      messages
    },
    dealProps: {
      isModalOpen: isCreateDealOpenModal,
      onOpenModal: onCreateDealClick,
      onCloseModal: onCloseAddDeal,
      dealState
    },
    pausedByUserName,
    onUpdatePauseOrResumeLead
  }
}

export default useLeadDetailsView
