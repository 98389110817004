import type { ChangeEvent } from 'react'
import type { UseFormReturn } from '@carfluent/common'
import type {
  TransactionLineVendorDto,
  FuelTypes,
  BodyTypes,
  DictionaryItem,
  VehicleDetails,
  DoorCountsTypes,
  DriveTrainTypes,
  AccountListItem,
  TransmissionTypes,
  GenericColorsTypes,
  DisabilityEquipped,
  VehicleDetailsByVin,
  CylinderCountsTypes,
  GenericInteriorsTypes,
  VehiclePartsDictionaries
} from 'api/types'
import type { KeyVal } from 'types'
import type { UseDropdownReturn } from 'hooks/useDropdown'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ErrTouchShortcuts = {
  buyer: true
  vendor: true
}

export type PickedVehicleDetails = 'year' | 'make' | 'model' | 'trim' | 'engine'

export interface AddNewVehicleFormData extends Pick<VehicleDetails, PickedVehicleDetails> {
  source: string
  vin: string | null
  stock: string | null
  mileage: string | null
  salePrice: number | null
  accountId: number | string
  purchasePrice: number | null
  buyer: DictionaryItem | null
  inventoryDate: Date | null
  body: DictionaryItem<BodyTypes> | null
  vendor: TransactionLineVendorDto | null
  fuelType: DictionaryItem<FuelTypes> | null
  doors: DictionaryItem<DoorCountsTypes> | null
  drivetrain: DictionaryItem<DriveTrainTypes> | null
  transmission: DictionaryItem<TransmissionTypes> | null
  genericColor: DictionaryItem<GenericColorsTypes> | null
  engineCylinders: DictionaryItem<CylinderCountsTypes> | null
  genericInterior: DictionaryItem<GenericInteriorsTypes> | null
  disabilityEquipped: DictionaryItem<DisabilityEquipped> | null
}

export enum Messages {
  Required = 'Cannot be empty'
}

export interface UseNewVehicleReturnType extends UseFormReturn<AddNewVehicleFormData, ErrTouchShortcuts> {
  users: DictionaryItem[]
  isLoading: boolean
  apiErrors: KeyVal | null
  onCancelClick: () => void
  isAccountingEnabled: boolean
  vendorProps: UseDropdownReturn<TransactionLineVendorDto>
  dictionaries: VehiclePartsDictionaries
  purchaseAccountOptions: AccountListItem[] | []
  onAddVendor: (vendor: TransactionLineVendorDto) => Promise<void>
  onApplyClick: (vin: string) => Promise<VehicleDetailsByVin | null>
  onChangeAccountId: (evt: ChangeEvent<HTMLInputElement>, value: string, id: string) => void
}

export interface UseNewVehicleProps {
  isOpen: boolean
  onClose: () => void
}
