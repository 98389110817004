import {
  type WebsiteSettingsDto,
  type WebsiteSettingsModel
} from 'api/types/responses'

export const serializeWebsiteSettings = (data: WebsiteSettingsModel): WebsiteSettingsDto => ({
  apr: data.apr,
  term: data.term,
  cashDownAmount: data.cashDownAmount,
  cashDownPercentage: data.cashDownPercentage,
  isEstimatedMonthlyPaymentEnabled: data.isEstimatedMonthlyPaymentEnabled,

  homePageHeading1: data.heroData.header,
  vdpIcon1Heading1: data.carAds.ads1.header,
  vdpIcon1Heading2: data.carAds.ads1.description,
  vdpIcon2Heading1: data.carAds.ads2.header,
  vdpIcon2Heading2: data.carAds.ads2.description,
  vdpIcon3Heading1: data.carAds.ads3.header,
  vdpIcon3Heading2: data.carAds.ads3.description,
  homePageHeading2: data.heroData.description
})
