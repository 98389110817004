import { css } from '@emotion/css'

export default css`
  padding: 16px;
  display: grid;
  row-gap: 8px;
  background: white;
  border-radius: 12px;
  margin-bottom: 16px;

  .MuiSkeleton-text {
    height: 16px;
  }
  
  .block-info {
    border-radius: 8px;
    background: #FAFAFA;
    padding: 8px 16px;
    
    display: grid;
    grid-template-columns: minmax(100px, 148px) 40px;
    align-items: center;
    justify-content: space-between;
    height: 60px;
  }

  .block-statistics {
    border-radius: 8px;
    background: #F1F7FF;
    padding: 8px 16px;
    height: 36px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
    align-items: center;
  }
`
