import { serializers } from '@carfluent/common'
import type { OpenBalancesListPayload } from 'api/types'
import { EntityTypeId } from 'api/types'
import {
  serializeAmountFilters,
  serializeDateFilters as _serializeDateFilters,
  type SerializedDate
} from 'utils/accounting'
import isNinetyPlusRange from 'utils/accounting/isNinetyPlusRange'

import type { AppliedDateFilter, OpenBalancesFilters } from './types'

const { serializeDate } = serializers

const serializeFilters = (data: OpenBalancesFilters): OpenBalancesListPayload => {
  const isVendor = data.entity?.entityType === EntityTypeId.Vendor
  const isCustomer = !isVendor

  return {
    amount: serializeAmountFilters(data.amount),
    accountId: Number(data.account?.id) ?? null,
    vendorId: isVendor ? data.entity?.id : null,
    customerId: isCustomer ? data.entity?.id : null,
    date: serializeDateFilters(data.date)
  }
}

/**
 * Date filter for "90+ days" should have open `minDate` part.
 */
const serializeDateFilters = (date: AppliedDateFilter): SerializedDate | null => {
  const res = _serializeDateFilters(date, serializeDate)

  if ((res != null) && isNinetyPlusRange(date)) {
    res.from = null
  }

  return res
}

export default serializeFilters
