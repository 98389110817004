import type { FC } from 'react'
import { cnx, formatCurrencyAccounting, Button, PRESET_LABELS } from '@carfluent/common'
import TooltipedText from 'components/common/TooltipedText'

import type { AccountHeaderProps } from './types'
import type { DateType } from 'api/types'
import FiltersPanel, { FilterItem } from 'components/common/FiltersPanel'
import useDateFilters from 'hooks/useDateFilters'
import { GET_DEFAULT_PRESETS } from 'constants/constants'
import { formatWithRemoveEmptyDecimal } from 'utils/filters/filterPanel'
import CLASS_NAME from './styles'

const FORMAT_CONFIG = { allowNegative: true }
const DEFAULT_PRESETS = GET_DEFAULT_PRESETS()

const AccountHeader: FC<AccountHeaderProps> = ({
  hasBankStatement = false,
  filtersProps,
  searchProps,
  shortInfo,
  onReconcileClick
}) => {
  const {
    closingBalance,
    currentMonthBalance,
    description,
    monthOpeningBalance,
    name
  } = shortInfo

  const onDateFiltersChange = async (date: DateType): Promise<void> => await filtersProps.onFiltersChange({ date })

  const dateFilterProps = useDateFilters({
    defaultPreset: DEFAULT_PRESETS[0],
    setDateFilters: onDateFiltersChange
  })

  const dateFilterItemProps: FilterItem<'date'> = {
    filterType: 'date',
    componentProps: {
      ...dateFilterProps,
      onFilterChange: onDateFiltersChange,
      defaultPresetName: PRESET_LABELS.ALL
    }
  }

  const amountFilter: FilterItem<'range'> = {
    filterType: 'range',
    componentProps: {
      onApply: (range) => {
        void filtersProps.onFiltersChange({
          amount: {
            from: range?.from ?? null,
            to: range?.to ?? null,
            equal: range?.exact ?? null
          }
        })
      },
      formatter: formatWithRemoveEmptyDecimal,
      preset: 'price',
      name: 'Amount',
      hasExact: true
    }
  }

  return (
    <div className={cnx(CLASS_NAME, 'top-block')}>
      <div className='name'>
        <TooltipedText value={name ?? ''} />
      </div>

      <div className='description'>{description}</div>

      <div className='closing-balance'>
        <div className='closing-balance-label'>
          Closing balance:
          <span className='closing-balance-value'>
            {formatCurrencyAccounting(closingBalance, FORMAT_CONFIG)}
          </span>
        </div>

        <div className='month-balance'>
          Current month balance:&nbsp;
          {formatCurrencyAccounting(currentMonthBalance, FORMAT_CONFIG)}
        </div>

        <div className='month-balance'>
          Month opening balance:&nbsp;
          {formatCurrencyAccounting(monthOpeningBalance, FORMAT_CONFIG)}
        </div>

        {hasBankStatement && (
          <Button
            className='reconcile-button'
            variant='text'
            onClick={() => {
              onReconcileClick?.()
            }}
          >
            RECONCILE
          </Button>
        )}
      </div>

      <div className='search-and-filters'>
        <FiltersPanel
          componentBackground='gray'
          searchProps={{
            ...searchProps,
            className: 'search-block',
            placeholder: 'Сontrol, description, notes',
            withDeleteIcon: true
          }}
          filterProps={{
            filters: [
              dateFilterItemProps,
              amountFilter
            ]
          }}
        />
      </div>
    </div>
  )
}

export default AccountHeader
