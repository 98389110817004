import type { FormValidation, DeepRequired } from '@carfluent/common'
import type { UpdateDealerAccountFormData } from './types'

import {
  firstNameField,
  lastNameField,
  userRoleField,
  dealersMultiselect,
  phoneField
} from 'utils/validationPresets'

const dealerFirstName = firstNameField()
const dealerLastName = lastNameField(true)
const roleId = userRoleField()
const dealerIds = dealersMultiselect()

const validationRules: FormValidation<DeepRequired<UpdateDealerAccountFormData>> = {
  dealerFirstName,
  dealerLastName,
  roleId,
  dealerIds,
  phoneNumber: phoneField()
}

export default validationRules
