import { css } from '@emotion/css'

export default css(`  
  .button {
    margin: 16px;
    border-radius: 12px;
  }
  
  .search-block {
    min-width: 420px;
    max-width: 420px;
    width: 100%;
    margin: 16px;

    .MuiInputBase-root {
      background: #F3F3F3;
      border-radius: 12px;
      box-shadow: none;
      height: 56px;
    }
  }

  .cf-no-data-block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    background: #fff;
    border-top: 8px solid #F3F3F3;
  }

  .cf-table-header {
    tr > th:last-child > div { justify-content: flex-end; }
  }

  .cf-table-header-title p { font-weight: 700; }

  .cf-table-cell {
    p { max-width: 35vw; }

    :last-child > div {
      display: flex;
      justify-content: flex-end;
    }
  } 
`)
