import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { COVERAGE_NAME_BY_ID } from 'constants/coverage'
import Checkbox from 'components/common/CheckboxNoMui'
import { type CoverageCheckboxesListProps } from './types'
import CLASS_NAME from './styles'

const CoverageCheckboxesList: FC<CoverageCheckboxesListProps> = ({
  className,
  productsIds,
  disabled = false,
  getIsChecked,
  onToggleItem
}) => {
  return (
    <div className={cnx(CLASS_NAME, 'coverage-checkboxes-list', className)}>
      {productsIds.map(id => {
        const isChecked = getIsChecked(id)

        return (
          <Checkbox
            isDisabled={disabled}
            key={id}
            label={COVERAGE_NAME_BY_ID[id]}
            onChange={() => {
              if (!disabled) {
                onToggleItem(id, !isChecked)
              }
            }}
            value={isChecked}
          />
        )
      })}
    </div>
  )
}

export default CoverageCheckboxesList
