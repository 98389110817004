import { useCallback, useState } from 'react'
import { useRefUpdater, noop } from '@carfluent/common'
import get from 'lodash-es/get'
import set from 'lodash-es/set'

import { type TransactionLineRow } from 'types'
import { type ControlListItem, type EntityListItem } from 'api/types'

import { type UseLinesTableProps, type UseLinesTableReturn } from './types'
import { DEFAULT_ROW, getDefaultRow, getDefaultRows } from '../constants'

const RELATED_CELLS_MAP: Record<string, string> = {
  debits: 'credits',
  credits: 'debits'
}
const useLinesTable = ({
  resetValidation
}: UseLinesTableProps): UseLinesTableReturn => {
  const [rows, setRows] = useState<TransactionLineRow[]>(getDefaultRows)
  const [isTableAmountDirty, setIsTableAmountDirty] = useState(false)
  const refRows = useRefUpdater(rows)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const addRow = useCallback((control: ControlListItem | null, entity: EntityListItem | null): void => {
    const newRow = getDefaultRow()
    const row = { ...newRow, control, entity }

    setRows(rows => rows.slice(0).concat([row]))
    setTimeout(resetValidation, 0)
  }, [resetValidation])

  const insertRow = useCallback((rowIdx: number, row?: TransactionLineRow): void => {
    const newRow = row ?? getDefaultRow()

    setRows(rows => {
      const nextRows = rows.slice(0)
      nextRows.splice(rowIdx, 0, newRow)

      return nextRows
    })
    setTimeout(resetValidation, 0)
  }, [resetValidation])

  const removeRow = useCallback((rowIdx: number, skipLastRowCheck: boolean = false): void => {
    setRows(rows => {
      const nextRows = rows.slice(0)
      nextRows.splice(rowIdx, 1)

      if ((nextRows.length === 0) && !skipLastRowCheck) {
        nextRows.push(getDefaultRow()) // adds fresh new line, so table is not completely empty
      }

      return nextRows
    })

    setTimeout(resetValidation, 0)
  }, [resetValidation])

  const updateCell = useCallback((rowIdx: number, columnId: string, value: unknown): void => {
    if (!isColumnId(columnId) || (refRows.current[rowIdx] == null)) {
      return // invalid wrong columnId or rowIdx
    }

    let isAmountChanged = false

    setRows(rows => {
      const nextRows = rows.slice(0)
      const row = nextRows[rowIdx]
      const prevValue = get(row, columnId)

      set(row, columnId, value)

      /**
       * Erase credit when debit is changed, and vice verse.
       */
      const relatedAmountColumn = RELATED_CELLS_MAP[columnId]
      const hasRelatedAmountColumn = relatedAmountColumn != null
      isAmountChanged = Boolean(prevValue) !== Boolean(value)
      if (hasRelatedAmountColumn && isAmountChanged) {
        set(row, relatedAmountColumn, get(DEFAULT_ROW, relatedAmountColumn))
      }

      return nextRows
    })

    setTimeout(() => { setIsTableAmountDirty(isAmountChanged) }, 0)
    setTimeout(resetValidation, 100)
  }, [resetValidation])

  const getRows = useCallback(() => refRows.current, [])

  // ========================================== //

  return {
    addRow,
    cleanUp: noop, // AZ-TODO: remove
    getRows,
    insertRow,
    isTableAmountDirty,
    removeRow,
    rows,
    setRows,
    updateCell
  }
}

export default useLinesTable

// ========================================== //

export const isColumnId = (id: any): id is keyof TransactionLineRow => {
  return (typeof id === 'string') && ((DEFAULT_ROW as any)[id] !== undefined)
}
