import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;

  .text-editor {

    &-container {
      min-height: 560px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 12px;
      background-color: #fff;
      padding: 16px;

      span > span {
        font-weight: inherit;
        font-family: inherit;
      }
      
      .public-DraftStyleDefault-block {
        margin: 0;
      }
    }

    &-toolbar {
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 12px;
      padding: 15px 15px 4px;
      margin-bottom: 24px;

      .rdw-option-wrapper {
        border: none;
        min-width: 40px;
        min-height: 40px;

        &img {
          width: 17.5px;
          height: 17.5px;
        }
      }

      .rdw-dropdown-wrapper,
      .rdw-block-dropdown {
        background-color: #f3f3f4;
        padding: 0 12px;
        border-radius: 12px;
        position: relative;
        min-height: 40px;
        min-width: 70px;
        max-width: 150px;
      }

      .rdw-fontfamily-dropdown,
      .rdw-block-dropdown {
        width: 150px;
      }

      .rdw-dropdown-optionwrapper {
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        margin-top: 4px;
        border-radius: 12px;
      }

      .rdw-dropdownoption-default {
        padding: 8px 15px;
      }
    }
  }
`)
