import { useEffect } from 'react'

import { Notifications } from 'constants/names'
import { WebSocketsRecon as ws } from 'services/web_sockets'

export interface UseWSUpdatesProps {
  onReconUpdated?: () => Promise<void>
}

const useWSUpdates = ({
  onReconUpdated
}: UseWSUpdatesProps): void => {
  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    ws.on(Notifications.VehicleUpdated, {
      name: 'reconsList_reconUpdated',
      action: (args) => {
        if (args?.vehicleId != null) {
          void onReconUpdated?.()
        }
      }
    })

    ws.on(Notifications.VehicleCreated, {
      name: 'reconsList_reconCreated',
      action: (args) => {
        if (args?.vehicleId != null) {
          void onReconUpdated?.()
        }
      }
    })

    ws.on(Notifications.VehicleDeleted, {
      name: 'reconsList_reconDeleted',
      action: (args) => {
        if (args?.vehicleId != null) {
          void onReconUpdated?.()
        }
      }
    })

    return () => {
      ws.off(Notifications.VehicleUpdated, 'reconsList_reconUpdated')
      ws.off(Notifications.VehicleCreated, 'reconsList_reconCreated')
      ws.off(Notifications.VehicleDeleted, 'reconsList_reconDeleted')
    }
  }, [onReconUpdated])
}

export default useWSUpdates
