import { useCallback, useEffect, useState, useRef } from 'react'
import { type FileFilterData, utils } from '@carfluent/common'

import ReconAPIProvider from 'api/recon.api'
import getUniqueName from 'utils/getUniqueName'
import type { DropzoneProps } from 'components/common/DropZone'
import type { ReconImage, ReconVehicleDetailsResponseDto, UploadedAssetWithThumbnailDto } from 'api/types'
import { type ImageDescriptor, ImageState } from 'components/inventory/ImageItem'
import FilesApiProvider, { type FileWithUniqueName } from 'api/files.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { parseAsset, parseFiles } from 'hooks/usePhotosUpload/parser'

import { isAllowedFilesNum } from './utils'
import { parseFromReconImages } from './parser'
import { serializeImages } from './serializer'
import { Messages } from './constants'

interface UseGalleryProps {
  vehicleImages: ReconImage[]
  setDetails: (details: ReconVehicleDetailsResponseDto) => void
  id: string
}

export interface UseGalleryReturn {
  dropzone: DropzoneProps
  onDeletePhoto: (i: number) => Promise<void>
  images: ImageDescriptor[]
  selected?: number
  setSelected: (i: number | undefined) => void
  deletingItemIdx: number | null
  setIsGalleryOpen: (state: boolean) => void
  isGalleryOpen: boolean
  uploadImageError?: string
}

/**
 * DD-NOTE: all image uploading and downloading logic should be solidified and
 * moved to common files someday. We have quite a mess with it at the moment.
 */
const MAX_SIZE = 15 * utils.KiB * utils.KiB // 15MB

export const useGallery = ({ vehicleImages, setDetails, id }: UseGalleryProps): UseGalleryReturn => {
  const [deletingItemIdx, setDeletingItemIdx] = useState<number | null>(null)
  const [selected, setSelected] = useState<undefined | number>(undefined)
  const [photos, _setPhotos] = useState<ImageDescriptor[]>([])
  const [isPhotosUploading, _setPhotosUploading] = useState(false)
  const [isGalleryOpen, setIsGalleryOpen] = useState(false)
  const [uploadImageError, setUploadImageError] = useState<FileFilterData['error']>(undefined)

  const refPhotos = useRef<ImageDescriptor[]>([])
  const refIsUploading = useRef(false)

  const { showAlert } = useCustomSnackbar()

  const setFilesUploading = useCallback((state: boolean): void => {
    refIsUploading.current = state
    _setPhotosUploading(state)
  }, [])

  const setPhotos = useCallback((nextPhotos: ImageDescriptor[]): void => {
    refPhotos.current = nextPhotos
    _setPhotos(nextPhotos)
  }, [])

  const savePhotos = useCallback(async (photos: ImageDescriptor[]) => {
    try {
      const details = await ReconAPIProvider.updateReconDetails(id, { vehicleImages: serializeImages(photos) })
      setDetails(details)
    } catch {
      throw new Error()
    }
  }, [])

  const onUploadPhotos = useCallback(async (_filesToUpload: File[]): Promise<void> => {
    setUploadImageError(undefined)

    const filesToUpload = _filesToUpload.filter(({ size }) => {
      const isCorrectSize = size <= MAX_SIZE

      if (!isCorrectSize) {
        return setUploadImageError('File size can\'t exceed 15MB.')
      }

      return isCorrectSize
    })

    try {
      setFilesUploading(true)
      const _filesToUpload = filesToUpload.map(file => ({ file, uniqueName: getUniqueName(file.name) }))
      setPhotos([...refPhotos.current, ...parseFiles(_filesToUpload)])

      const setPhoto = (photo: ImageDescriptor, idx: number): void => {
        const nextPhotos = [...refPhotos.current]
        nextPhotos[idx] = photo
        setPhotos(nextPhotos)
      }

      const onFileUpload = (uploaded: UploadedAssetWithThumbnailDto, original: FileWithUniqueName): void => {
        const matchIdx = refPhotos.current.findIndex(photo => photo.uniqueName === original.uniqueName)
        if (matchIdx > -1) {
          setPhoto(parseAsset(uploaded, original.file), matchIdx)
        }
      }

      const onFileError = (original: FileWithUniqueName): void => {
        const matchIdx = refPhotos.current.findIndex(photo => photo.uniqueName === original.uniqueName)
        if (matchIdx > -1) {
          setPhoto({
            ...refPhotos.current[matchIdx],
            state: ImageState.Failed
          }, matchIdx)
        }
      }

      await FilesApiProvider.uploadFilesInBatches(_filesToUpload, onFileUpload, onFileError)
      await savePhotos(refPhotos.current)
    } catch (err) {
      showAlert(Messages.FailedUpdateImages)
    } finally {
      setFilesUploading(false)
    }
  }, [
    setPhotos,
    savePhotos,
    setFilesUploading,
    showAlert
  ])

  const onDeletePhoto = useCallback(async (idx: number): Promise<void> => {
    try {
      if (refIsUploading.current) {
        showAlert(Messages.ImagesLoading, { variant: 'success' })
        return
      }
      setDeletingItemIdx(idx)
      const nextPhotos = refPhotos.current.slice(0, idx).concat(refPhotos.current.slice(idx + 1))
      await savePhotos(nextPhotos)
      showAlert(Messages.ImageSuccessfullyDeleted, { variant: 'success' })
    } catch {
      showAlert(Messages.FailedUpdateImages)
    } finally {
      setDeletingItemIdx(null)
    }
  }, [
    setPhotos,
    showAlert,
    savePhotos
  ])

  useEffect(() => {
    // we need to forbid state update during loading because we might have a case:
    // - user loads something and then deletes during upload and all photos will be lost
    // - because they would be erased with previous item images
    if (!refIsUploading.current) {
      setPhotos(parseFromReconImages(vehicleImages))
    }
  }, [vehicleImages])

  return {
    dropzone: {
      onAddFiles: onUploadPhotos,
      maxFiles: isAllowedFilesNum(photos),
      disabled: isPhotosUploading
    },
    images: photos,
    onDeletePhoto,
    selected,
    setSelected,
    deletingItemIdx,
    setIsGalleryOpen,
    isGalleryOpen,
    uploadImageError
  }
}
