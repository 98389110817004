import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  editorWrapper: {
    position: 'relative',
    '& *': {
      fontFamily: 'Roboto, sans-serif'
    },
    '& .tui-image-editor-controls': {
      width: 'auto',
      backgroundColor: '#ffffff',
      height: '40px',
      '& svg': {
        '& > use.normal, & > use.hover': {
          fill: '#000000',
          stroke: '#000000'
        }
      }
    },
    '& .tui-image-editor-container': {
      backgroundColor: '#ffffff',
      '& .tui-image-editor-checkbox': {
        '&  label': {
          '& > span': {
            color: '#000000',
            '&:before': {
              border: '1px solid #000000'
            }
          }
        }
      },
      '& .tui-image-editor-virtual-range-pointer': {
        border: '1px solid #cccccc'
      },
      '& .tui-image-editor-submenu': {
        '& use.normal.use-default, & use.active.use-default': {
          fill: '#000000',
          stroke: '#000000'
        },
        '& .tui-image-editor-button': {
          '&.preset.active': {
            '& label': {
              color: '#000000'
            }
          },
          '&:hover': {
            '& > label': {
              color: '#000000'
            }
          }
        },
        '& .tui-image-editor-menu-filter': {
          verticalAlign: 'middle',
          '& .tui-image-editor-submenu-item': {
            '& > li': {
              display: 'none',
              '&:first-child': {
                display: 'inline-block',
                '& .tui-image-editor-checkbox': {
                  display: 'none',
                  '&:nth-child(6)': {
                    display: 'inline-block'
                  }
                }
              },
              '&:nth-child(3)': {
                display: 'inline-block',
                '& .tui-image-editor-checkbox-group': {
                  display: 'none',
                  '&:nth-child(2)': {
                    display: 'inline-block'
                  }
                }
              }
            }
          }
        }
      },
      '& .tui-image-editor-header': {
        display: 'none'
      },
      '& .tui-colorpicker-palette-preview': {
        textIndent: '-9999px'
      },
      '& .tui-image-editor-item[tooltip-content]': {
        '&:hover': {
          '&:after': {
            fontFamily: 'Roboto, sans-serif',
            height: 13
          }
        }
      },
      '& .color-picker-control': {
        '& .tui-colorpicker-clearfix': {
          display: 'flex',
          '& li': {
            width: '100%',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },
        '& .tui-colorpicker-palette-button': {
          margin: 0,
          width: 5,
          height: 5
        }
      },
      '& .tui-image-editor-main': {
        top: '32px'
      }
    },
    '& .tui-image-editor-help-menu.top': {
      width: 'auto',
      backgroundColor: '#ffffff',
      height: '34px',
      top: 0,
      '& svg': {
        '& > use.normal, & > use.hover': {
          fill: '#000000',
          stroke: '#000000'
        }
      },
      '& .tui-image-editor-item': {
        display: 'none'
      },
      '& .tie-btn-undo': {
        display: 'inline-flex'
      },
      '& .tie-btn-redo': {
        display: 'inline-flex'
      },
      '& .tie-btn-reset': {
        display: 'inline-flex'
      }
    }
  },
  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    bottom: '30px'
  },
  saveChanges: {
    padding: '0 18px',
    height: '26px',
    background: '#FFFFFF',
    color: '#000000',
    border: '1px solid #C99B86',
    borderRadius: '8px',
    marginLeft: '10px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:disabled': {
      opacity: 0.4
    }
  },
  cancel: {
    padding: '0 18px',
    height: '26px',
    background: '#FFFFFF',
    color: '#000000',
    border: '1px solid #C99B86',
    borderRadius: '8px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:disabled': {
      opacity: 0.4
    }
  }
})

export default useStyles
