import { type FC } from 'react'
import { cnx, FormInput, FormNumberInput, FormDropdown } from '@carfluent/common'

import { type AccountListItem } from 'api/types'
import AccountingApiProvider from 'api/accounting.api'

import Toggle from 'components/common/Toggle'
import CheckboxLabel from 'components/common/CheckboxLabel'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

import getAccountOptionName from 'utils/accounting/getAccountOptinName'
import { MAX_COST_LABEL_LENGTH, MAX_PRICE_VALUE, MAX_PERCENT_VALUE } from 'constants/validation'

import { type FeeFieldSettingsProps } from './types'
import CLASS_NAME from './styles'

/**
 * AZ-NOTES:
 * - props are compatible to `UseFormReturn` type, but the form state should be managed outside the component.
 * - `defaultValueCash` is used for both "Default when cash" and "Default amount" cases.
 */
const FeeFieldSettings: FC<FeeFieldSettingsProps> = ({
  className,
  errors,
  feeName,
  isPercent,
  isLoading,
  onBlur,
  onChange,
  touched,
  values,
  resetFields
}) => {
  const hasAmountField = values.defaultValueFinancing !== undefined

  return (
    <BlockSkeleton isLoading={isLoading} className={cnx(CLASS_NAME, 'fee-field-loading')}>
      <div className={cnx('fee-item', 'fee-field-settings', className, values.isEnabled && 'active')}>
        <div className='fee-field-settings-header'>
          <div className='g-body2-medium'>
            {feeName}
          </div>

          <Toggle
            className={cnx('field-is-enabled')}
            inversed
            onClick={(isEnabled) => {
              onChange('isEnabled', isEnabled)
              resetFields()
            }}
            toggled={values.isEnabled}
          />
        </div>

        <div className='fee-field-settings-content'>
          <FormInput
            dataTestId='input-label-name'
            disabled={!values.isEnabled}
            error={errors.labelName}
            helper={
              <p className='label-name-helper'>
                {values.labelName.length ?? 0}/{MAX_COST_LABEL_LENGTH} symbols
              </p>
          }
            maxLength={25}
            id='labelName'
            label='Label name'
            onChange={onChange}
            onBlur={onBlur}
            touched={touched.labelName}
            value={values.labelName}
          />

          <FormNumberInput
            blurComplementMode={isPercent ? 'min-scale' : 'max-scale'}
            error={errors.defaultValueCash}
            dataTestId='input-default-value-cash'
            disabled={!values.isEnabled}
            id='defaultValueCash'
            isEmptyAllowed
            max={isPercent ? MAX_PERCENT_VALUE : MAX_PRICE_VALUE}
            mountComplementMode={isPercent ? 'min-scale' : 'max-scale'}
            onBlur={onBlur}
            onChange={onChange}
            preset={isPercent ? 'percent' : 'price'}
            label={hasAmountField ? 'Default when cash' : 'Default amount'}
            placeholder={hasAmountField ? 'Default when cash' : 'Default amount'}
            touched={touched.defaultValueCash}
            decimalPrecision={isPercent ? 3 : 2}
            value={values.defaultValueCash ?? 0}
          />

          {hasAmountField && (
            <FormNumberInput
              blurComplementMode={isPercent ? 'min-scale' : 'max-scale'}
              error={errors.defaultValueFinancing}
              dataTestId='input-default-value-financing'
              disabled={!values.isEnabled}
              id='defaultValueFinancing'
              isEmptyAllowed
              max={isPercent ? MAX_PERCENT_VALUE : MAX_PRICE_VALUE}
              mountComplementMode={isPercent ? 'min-scale' : 'max-scale'}
              mode='decimal'
              onBlur={onBlur}
              onChange={onChange}
              label='Default when financing'
              placeholder='Default when financing'
              preset={isPercent ? 'percent' : 'price'}
              touched={touched.defaultValueFinancing}
              decimalPrecision={isPercent ? 3 : 2}
              value={values.defaultValueFinancing ?? 0}
            />
          )}

          <FormDropdown<AccountListItem>
            error={errors.account}
            errorMessage={errors.account}
            disabled={!values.isEnabled}
            fetchHandler={AccountingApiProvider.getAccounts}
            formatDisplayValue={getAccountOptionName}
            id='account'
            label='Accounting account'
            onBlur={onBlur}
            onChange={onChange}
            renderOption={getAccountOptionName}
            touched={touched.account}
            value={values.account}
          />

          <div className={cnx('checkboxes', !values.isEnabled && 'disabled')}>
            <CheckboxLabel
              checked={values.isReadonly}
              disabled={!values.isEnabled}
              id='isReadonly'
              mode='inlined'
              name='Read only'
              onChange={() => {
                onChange('isReadonly', !values.isReadonly)
              }}
            />

            <CheckboxLabel
              checked={values.isTaxable}
              disabled={!values.isEnabled}
              id='isTaxable'
              mode='inlined'
              name='Taxable'
              onChange={() => {
                onChange('isTaxable', !values.isTaxable)
              }}
            />
          </div>
        </div>
      </div>
    </BlockSkeleton>
  )
}

export default FeeFieldSettings
