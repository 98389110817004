import type { FC } from 'react'

import ReportLayout from 'components/reports/ReportLayout'
import FiltersPanel from 'components/common/FiltersPanel'
import Table from 'components/common/Table'
import BackToTop from 'components/common/BackToTop'

import { useCarRepricing } from './hook'
import { columns } from './hook/columns'
import CLASS_NAME from './styles'

const ReportCarRepricing: FC = () => {
  const {
    onExportExcel,
    searchProps,
    isLoading,
    rows,
    sorting,
    setSorting,
    scrollableRef
  } = useCarRepricing()

  return (
    <ReportLayout
      title='Car repricing report'
      isDateFilterEnabled={false}
      onExportExcel={onExportExcel}
      rootClassName={CLASS_NAME}
    >
      <div className='table-wrapper'>
        <FiltersPanel
          componentBackground='gray'
          searchProps={searchProps}
        />

        <Table
          data={rows}
          sorting={sorting}
          isLoading={isLoading}
          onSortingChange={setSorting}
          columns={columns}
          emptyTableMessage='No results found'
        />
      </div>
      {scrollableRef != null && <BackToTop refScrollable={scrollableRef} />}
    </ReportLayout>
  )
}

export default ReportCarRepricing
