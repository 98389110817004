import { type FC } from 'react'
import { cnx, Button } from '@carfluent/common'

import OverlayedPageHOC from 'components/PageOverlay/OverlayedPageHOC'
import BackToTop from 'components/common/BackToTop'
import Table from 'components/common/Table'
import NewFormPackModal from 'components/dialogs/NewFormPack'
import FiltersPanel from 'components/common/FiltersPanel'

import useEmailTemplatesList from './hook'
import CLASS_NAME from './styles'

const FormPacks: FC = () => {
  const {
    isNewFormPackModalOpen,
    onCloseNewFormPackModal,
    onOpenNewFormPackModal,
    isLoading,
    emptyTableMessage,
    rows,
    columns,
    sorting,
    search,
    onOpenFormPackDetails,
    onSearchChange,
    onSearch,
    setSorting,
    onBottomReached
  } = useEmailTemplatesList()

  return (
    <div className={cnx('grey-background-page', CLASS_NAME)}>
      <FiltersPanel
        searchProps={{
          isLoading,
          onChange: onSearchChange,
          value: search,
          placeholder: 'Name, description',
          withDeleteIcon: true,
          onSearch
        }}
        renderActions={() => (
          <Button
            className='btn-new-form-pack'
            onClick={onOpenNewFormPackModal}
          >
            NEW PACK
          </Button>
        )}
      />

      <Table
        isLoading={isLoading}
        emptyTableMessage={emptyTableMessage}
        columns={columns}
        data={rows}
        sorting={sorting}
        onSortingChange={setSorting}
        onBottomReached={onBottomReached}
        onRowClick={onOpenFormPackDetails}
        headerDataTestId='form-packs-table-header'
        bodyDataTestId='form-packs-table-body'
      />

      <NewFormPackModal
        isOpen={isNewFormPackModalOpen}
        onClose={onCloseNewFormPackModal}
      />

      <BackToTop />
    </div>
  )
}

export default OverlayedPageHOC(FormPacks)
