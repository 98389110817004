import { type ReactNode } from 'react'
import { type DictionaryItem } from 'api/types'

import NameWithAvatar from 'components/common/NameWithAvatar'
import TaskPriority from 'components/common/TaskPriority'

export const renderNoUsersOption = (): ReactNode => (<div> No users found </div>)

export const renderAssignOption = (option: DictionaryItem | null): ReactNode => (
  <NameWithAvatar
    showAvatar
    name={option?.name}
  />)

export const renderPriorityOption = (option: DictionaryItem | null): ReactNode => <TaskPriority value={option?.id} />
