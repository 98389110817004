import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'

import { formatPhoneNumber } from 'pages/accounting/Settings/components/utils'

import CLASS_NAME from './styles'

interface PhoneCellProps {
  getValue: () => string
}

const PhoneCell: FC<PhoneCellProps> = (props) => {
  return (
    <CellWrapper className={CLASS_NAME}>
      <p className='label'>Phone:</p>
      <p>{formatPhoneNumber(props.getValue()) ?? '-'}</p>
    </CellWrapper>
  )
}

export default PhoneCell
