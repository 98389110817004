import { css } from '@emotion/css'

export default css`
  &.disabled {
    pointer-events: none;
  }

  button {
    padding: 6px 12px;
  }

  .cf-attach-media-menu {
    .cf-icon-container {
      transform: rotate(270deg);
    }
    
    .cf-menu-popper {
      cursor: default;

      .MuiPopover-paper {
        top: 546px!important;
        left: 12px!important;
      }
    }

    ul {
      padding: 8px 0;

      .cf-menu-item li {
        padding: 12px 16px;
        height: 24px;
      }
    }
  }
}
`
