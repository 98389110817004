import { useState, useRef, useEffect, useCallback } from 'react'

import { DictionaryItem } from 'api/types'
import AppraisalAPIProvider from 'api/appraisal.api'
import useAsyncEffect from 'hooks/useAsyncEffect'

import { parseTrims } from './parser'
import { DEFAULT_VALUES } from './constants'

export type IdKeys = 'make' | 'model' | 'trim'

export interface UseCarDetailsProps {
  make: string | null
  model: string | null
  trim: string | null
  onChange: (id: string, value: string | null) => void
  setFieldTouched?: (id: string, value: boolean) => void
  ids: Record<IdKeys, string>
}

export interface UseCarDetailsReturn extends Record<'makes' | 'models' | 'trims', DictionaryItem[]> {
  onChange: (id: string, value: DictionaryItem | null) => void
  selectedMakeItem: DictionaryItem | null
  selectedModelItem: DictionaryItem | null
  selectedTrimItem: DictionaryItem | null
}

// DD-NOTE -> OF-TODO: Refactor this hook!!!
export const useCarDetailsFields = ({
  make,
  model,
  trim,
  onChange: _onChange,
  ids,
  setFieldTouched
}: UseCarDetailsProps): UseCarDetailsReturn => {
  const [makes, setMakes] = useState<DictionaryItem[]>([])
  const [models, setModels] = useState<DictionaryItem[]>([])
  const [trims, setTrims] = useState<DictionaryItem[]>([])
  const valuesRef = useRef<Record<IdKeys, string | null>>({ ...DEFAULT_VALUES })
  const onChangeRef = useRef<(id: string, value: string | null) => void>(_onChange)
  const setFieldTouchRef = useRef<((id: string, value: boolean) => void) | undefined>(setFieldTouched)
  const modelsRef = useRef<DictionaryItem[]>([])

  useEffect(() => {
    onChangeRef.current = _onChange
  }, [_onChange])

  useEffect(() => {
    setFieldTouchRef.current = setFieldTouched
  }, [setFieldTouched])

  useEffect(() => {
    modelsRef.current = models
  }, [models])

  useAsyncEffect(async () => {
    const { items } = await AppraisalAPIProvider.getMakes()
    setMakes(items)
    setModels([])
    setTrims([])
  }, [])

  const onChange = useCallback(
    (id, value: DictionaryItem | null) => {
      const valName = value?.name.trim() ?? ''
      _onChange(id, valName === '' ? null : valName)
    }, [_onChange])

  const onMakeChanged = useCallback(async (makeInOptions: DictionaryItem, isReset: boolean = false) => {
    const { items } = await AppraisalAPIProvider.getModels(makeInOptions.id)

    setModels(items)
    setTrims([])

    if (isReset) {
      await onChangeRef.current(ids.model, null)
      await onChangeRef.current(ids.trim, null)
      valuesRef.current.make = null
      valuesRef.current.trim = null
    }
  }, [make])

  const onModelChanged = useCallback(async (
    makeInOptions: DictionaryItem,
    isReset: boolean = false,
    trim: string | null
  ) => {
    if (model == null || modelsRef.current.length === 0) {
      await onChangeRef.current(ids.trim, null)
      return
    }

    const modelInOptions = modelsRef.current.find(({ name }) => name.toLowerCase() === model.toLowerCase())

    if (modelInOptions != null) {
      const { items } = await AppraisalAPIProvider.getTrims(modelInOptions.id)
      const isTrimsExist = items.find(({ name }) => name.toLowerCase() === trim?.toLowerCase()) != null
      setTrims(parseTrims(items))

      valuesRef.current.model = model
      if (isReset && !isTrimsExist) {
        await onChangeRef.current(ids.trim, null)
        valuesRef.current.trim = null
      }
    } else {
      await onChangeRef.current(ids.model, null)
      await onChangeRef.current(ids.trim, null)
      valuesRef.current.model = null
      valuesRef.current.trim = null
    }
  }, [model])

  useAsyncEffect(async () => {
    const isPreventLogic = make == null || makes.length === 0

    if (isPreventLogic) {
      return
    }

    const makeInOptions = makes.find(({ name }) => name.trim().toLowerCase() === make?.trim().toLowerCase())

    if (makeInOptions == null) {
      valuesRef.current = { ...DEFAULT_VALUES }

      onChangeRef.current(ids.make, null)
      onChangeRef.current(ids.model, null)
      onChangeRef.current(ids.trim, null)
      return
    }

    const isMakeChanged = make !== valuesRef.current.make
    const isModelChanged = model !== valuesRef.current.model

    if (isMakeChanged) {
      await onMakeChanged(makeInOptions, !isModelChanged)
    }

    if (isModelChanged) {
      await onModelChanged(makeInOptions, !isMakeChanged, trim)
    }

    await setFieldTouchRef.current?.(ids.model, false)
    await setFieldTouchRef.current?.(ids.trim, false)
    valuesRef.current = { make, model, trim }
  }, [make, model, trim, onModelChanged, onMakeChanged, makes])

  const selectedMakeItem = makes.find(({ name }) => name.toLowerCase() === make?.toLowerCase()) ?? null
  const selectedModelItem = models.find(({ name }) => name.toLowerCase() === model?.toLowerCase()) ?? null
  const selectedTrimItem = trims.find(({ name }) => name.toLowerCase() === trim?.toLowerCase()) ?? null

  return {
    makes,
    models,
    trims,
    onChange,
    selectedMakeItem,
    selectedModelItem,
    selectedTrimItem
  }
}
