import { parsers } from '@carfluent/common'

import {
  TimelineLogTypes,
  type DictionaryItems,
  type EmailThread,
  type TimelineItem,
  type TimelineLogData, MessageMediaTypeId
} from 'api/types'
import { TimelineTextTitles } from 'types'
import { formatEntryIfPresent, joinPartsBySpace } from 'utils/view_helper'
import { iframeRegex, imgInCarBlockRegex } from 'utils/regex_helper'
import { formatDate, toDateFromStringOrDate } from 'utils/parse_date'
import { DATE_US_FORMAT, TIME_HH_MM_FORMAT } from 'constants/constants'
import { TABLE_CLASS_CONTAINER_NAME, TABLE_TITLE_CLASS_NAME } from 'components/wysiwyg/editor/CarBlock'
import { leadLostReasonMap } from 'components/crm/LeadLostModal/constants'
import { convertDateToUTCString } from 'components/common/EmailThreadModal/hook/utils'
import { getDate } from 'pages/crm/LeadDetailsView/hook/utils'
import { LeadSources } from 'pages/crm/LeadDetailsView/hook/types'
import { unwrapBodyWithCarsOfInterest } from 'utils/wysiwygHtmlWrappers'

import type { ItemProps } from '../components/Item/types'

const isPublicUrl = (url: string): boolean => url.includes('carfluent-public-loadfilescontainer')

const { parseDateStringUTC } = parsers

const getTextItem = (text: string | null, name: string | null): Array<{name: string | null, text: string}> => {
  return text != null ? [{ name, text }] : []
}

const mergedLeadTitle = 'Web lead'

export const PRICE_DROP_ALERT_TYPE = 'Price drop alert'

const getName = (logTypeId: TimelineLogTypes, { feedbackAction, userFirstName, userLastName, type, leadDetails, leadSourceId }: TimelineLogData): string | null => {
  const isHideName = [
    TimelineLogTypes.LeadLost,
    TimelineLogTypes.DealManuallyCreated,
    TimelineLogTypes.LeadAutomaticallyCreated,
    TimelineLogTypes.DealStatusUpdated,
    TimelineLogTypes.EmailSentAutomatically,
    TimelineLogTypes.MessageSentAutomatically,
    TimelineLogTypes.UnableToSendEmail,
    TimelineLogTypes.UnableToSendMessage
  ].includes(logTypeId)

  if (type === PRICE_DROP_ALERT_TYPE) {
    return (userFirstName != null || userLastName != null)
      ? joinPartsBySpace(userFirstName, userLastName)
      : isHideName
        ? null
        : 'System'
  }

  if (logTypeId === TimelineLogTypes.EmailReceived) {
    return (userFirstName != null || userLastName != null)
      ? joinPartsBySpace(userFirstName, userLastName)
      : feedbackAction != null ? 'Unassigned' : null
  }

  if (logTypeId === TimelineLogTypes.MessageReceived) {
    if (feedbackAction == null || feedbackAction === '') {
      return null
    }
  }

  const isNameExist = userFirstName != null || userLastName != null
  const isHideNameForCreditApp = TimelineLogTypes.CreditAppReceived === logTypeId && !isNameExist

  if (isHideNameForCreditApp) {
    return null
  }

  const isEventType = [TimelineLogTypes.EmailSent, TimelineLogTypes.MessageSent].includes(logTypeId)

  return isHideName
    ? null
    : (userFirstName != null || userLastName != null)
        ? joinPartsBySpace(userFirstName, userLastName)
        : isEventType ? 'System' : 'Unassigned'
}

const getWorkNote = (
  logTypeId: TimelineLogTypes,
  { workNotes }: TimelineLogData
): string | null => {
  const isAppointment = logTypeId === TimelineLogTypes.Appointment
  const isUnplannedCall = logTypeId === TimelineLogTypes.UnplannedCall
  const isSendText = logTypeId === TimelineLogTypes.Reminder
  const isStoreVisit = logTypeId === TimelineLogTypes.StoreVisit
  const isSwappedTires = logTypeId === TimelineLogTypes.SwappedTired
  const isFollowUpCall = logTypeId === TimelineLogTypes.Call
  const isLeadReceived = logTypeId === TimelineLogTypes.LeadReceived
  const isCreditAppReceived = logTypeId === TimelineLogTypes.CreditAppReceived

  const isWorkNote = (workNotes != null && workNotes !== '' && (
    isFollowUpCall ||
    isUnplannedCall ||
    isAppointment ||
    isSendText ||
    isStoreVisit ||
    isSwappedTires ||
    isLeadReceived ||
    isCreditAppReceived
  ))

  return isWorkNote ? workNotes : null
}

const getLogActivityDate = (logTypeId: TimelineLogTypes, { userDefinedCompletionDate }: TimelineLogData): string | null => {
  const isLogActivityTask = logTypeId === TimelineLogTypes.UnplannedCall ||
    logTypeId === TimelineLogTypes.StoreVisit ||
    logTypeId === TimelineLogTypes.SwappedTired

  return (isLogActivityTask && userDefinedCompletionDate != null)
    ? getDate(userDefinedCompletionDate)
    : null
}

const getAdditionalHeaderData = ({ completionSubOptions, completionOption }: TimelineLogData, logTypeId: TimelineLogTypes): string[] => {
  const isAppointment = logTypeId === TimelineLogTypes.Appointment

  return [
    ...(completionSubOptions != null ? completionSubOptions : []),
    ...(completionOption != null
      ? !isAppointment
          ? [completionOption]
          : []
      : [])
  ]
}

const getHeldOn = (
  logTypeId: TimelineLogTypes,
  { completionDate }: TimelineLogData
): string | null => {
  const isFollowUpCall = logTypeId === TimelineLogTypes.Call

  return (isFollowUpCall && completionDate != null)
    ? getDate(completionDate)
    : null
}

export const getShowedUp = (
  logTypeId: TimelineLogTypes,
  { completionDate }: TimelineLogData
): string | null => {
  const isAppointment = logTypeId === TimelineLogTypes.Appointment

  return (isAppointment && completionDate != null)
    ? getDate(completionDate)
    : null
}

const getSource = (
  logTypeId: TimelineLogTypes,
  { leadSourceId }: TimelineLogData,
  sources: DictionaryItems<string>
): string | null => {
  const isLeadCreated = logTypeId === TimelineLogTypes.LeadReceived ||
    logTypeId === TimelineLogTypes.LeadManuallyCreated

  return (isLeadCreated && leadSourceId != null)
    ? sources.find(({ id }) => id === leadSourceId)?.name ?? null
    : null
}

const getDescriptionName = (logTypeId: TimelineLogTypes): string | null => {
  const isDescriptionName = logTypeId !== TimelineLogTypes.LeadLost &&
    logTypeId !== TimelineLogTypes.CarOfInterestAdded &&
    logTypeId !== TimelineLogTypes.CarOfInterestIsDeposited &&
    logTypeId !== TimelineLogTypes.Comment

  return isDescriptionName ? TimelineTextTitles.Description : null
}

const getEmailSubject = (logTypeId: TimelineLogTypes, { emailSubject }: TimelineLogData): string | null => {
  const isShowEmailSubject = [
    TimelineLogTypes.EmailSent,
    TimelineLogTypes.EmailReceived,
    TimelineLogTypes.EmailSentAutomatically
  ].includes(logTypeId)
  return isShowEmailSubject ? emailSubject != null ? emailSubject : '-' : null
}

const getFeedbackAction = (logTypeId: TimelineLogTypes, { feedbackAction }: TimelineLogData): string | null => {
  const isShowFeedbackAction = [
    TimelineLogTypes.EmailReceived,
    TimelineLogTypes.LeadReceived,
    TimelineLogTypes.MessageReceived,
    TimelineLogTypes.CreditAppReceived
  ].includes(logTypeId)
  return isShowFeedbackAction ? feedbackAction != null ? feedbackAction : '' : null
}

const getOpenedAction = (logTypeId: TimelineLogTypes, { isOpen }: TimelineLogData): string | null => {
  const isShowOpenedAction = TimelineLogTypes.EmailSent === logTypeId
  return (isShowOpenedAction && isOpen === true) ? 'Email opened' : null
}

const getClickedLinkAction = (logTypeId: TimelineLogTypes, { isClick }: TimelineLogData): string | null => {
  const isShowClickedLinkAction = TimelineLogTypes.EmailSent === logTypeId
  return (isShowClickedLinkAction && isClick === true) ? 'Link clicked' : null
}

const getBody = (logTypeId: TimelineLogTypes, _body: TimelineLogData['body']): string | null => {
  const isShowBody = [
    TimelineLogTypes.EmailSent,
    TimelineLogTypes.EmailReceived,
    TimelineLogTypes.EmailSentAutomatically
  ].includes(logTypeId)

  if (!isShowBody) {
    return null
  }

  let i = 0

  const data = _body?.replace(imgInCarBlockRegex, () => {
    i++
    return ''
  }).replace(iframeRegex, () => {
    i++
    return ''
  })

  const templateEl = document.createElement('div')

  templateEl.innerHTML = unwrapBodyWithCarsOfInterest(data)

  const listCarOfInterest = templateEl.querySelectorAll(`.${TABLE_CLASS_CONTAINER_NAME}`)

  listCarOfInterest.forEach(item => {
    const nameOfCar = templateEl.querySelector(`.${TABLE_TITLE_CLASS_NAME}`)?.getAttribute('data-car-info-name') ?? ''
    item.replaceWith(`[${nameOfCar}]`)
  })

  const attachments = i !== 0 ? `[${i} attachment${i > 1 ? 's' : ''}]` : ''
  return `${attachments} ${templateEl.innerHTML}`
}

const getLeadLost = (logTypeId: TimelineLogTypes, { lostReasonId }: TimelineLogData): string | null => {
  if (logTypeId !== TimelineLogTypes.LeadLost || lostReasonId == null) {
    return null
  }

  return leadLostReasonMap[lostReasonId]
}

const getAttachments = ({ media }: TimelineLogData): string | null => {
  const attachmentsLength = media?.filter(el => el.mediaTypeId === MessageMediaTypeId.Image)?.length ?? 0

  return attachmentsLength > 0
    ? `[${attachmentsLength} attachment${attachmentsLength > 1 ? 's' : ''}]` ?? null
    : null
}

const getMessage = (logTypeId: TimelineLogTypes, { message }: TimelineLogData): string | null => {
  const isMessages = [
    TimelineLogTypes.MessageSent,
    TimelineLogTypes.MessageReceived,
    TimelineLogTypes.MessageSentAutomatically
  ].includes(logTypeId)

  const messageLines = message?.replace(/\n/g, ' ')

  return messageLines != null && isMessages ? messageLines : null
}

const getAdditionalDataForLeadReceived = (
  logTypeId: TimelineLogTypes,
  {
    additionalData: data,
    leadDetails,
    carOfInterestDetails,
    leadSourceId,
    subject
  }: TimelineLogData): string | null => {
  const isAskDriveSource = leadSourceId === LeadSources.Ask || leadSourceId === LeadSources.Drive
  const isMergedLead = subject === mergedLeadTitle && (leadDetails != null || carOfInterestDetails != null)
  const labelStyles = 'color: #21212180'

  const isWebChat = leadSourceId === LeadSources.WebChat

  if (isWebChat && leadDetails?.phoneNumber != null) {
    return Object.entries({
      ...((
        (leadDetails?.firstName != null && leadDetails?.firstName.trim() !== '') ||
        (leadDetails?.lastName != null && leadDetails?.lastName.trim() !== ''))
        ? { Name: joinPartsBySpace(leadDetails.firstName, leadDetails.lastName) }
        : { Name: 'Unknown name' }),
      Phone: leadDetails?.phoneNumber
    })
      .map(([label, value]) =>
        formatEntryIfPresent({ label, value, labelStyles }))
      .join('')
  }

  if ((logTypeId !== TimelineLogTypes.LeadReceived || isAskDriveSource) && !isMergedLead) {
    return null
  }

  const parsedDate = data?.Date != null ? parseDateStringUTC(data.Date) : null
  const formattedDate = formatDate(parsedDate ?? new Date(), DATE_US_FORMAT)
  const formattedTime = formatDate(parsedDate ?? new Date(), TIME_HH_MM_FORMAT)
  const parsedDateOfBirth = toDateFromStringOrDate(data?.DateOfBirth)
  const formattedDateOfBirth = parsedDateOfBirth != null ? formatDate(parsedDateOfBirth, DATE_US_FORMAT) : null

  const parsedCreatedDate = leadDetails?.createdDate != null ? parseDateStringUTC(leadDetails.createdDate) : null
  const formattedCreatedDate = formatDate(parsedCreatedDate ?? new Date(), DATE_US_FORMAT)
  const formattedCreatedTime = formatDate(parsedCreatedDate ?? new Date(), TIME_HH_MM_FORMAT)

  let mergedLeadProps: Record<string, string | number | null | undefined> = {}

  if (leadDetails != null) {
    mergedLeadProps = {
      ...mergedLeadProps,
      'All details': '',
      'Created date': `${formattedCreatedDate} at ${formattedCreatedTime}`,
      'Deal ID': leadDetails?.dealId,
      Name: `${leadDetails?.firstName} ${leadDetails?.lastName}`,
      'Date of birth': formattedDateOfBirth,
      'E-mail': leadDetails?.email,
      Phone: leadDetails?.phoneNumber,
      Address: leadDetails?.address,
      'Year income': data?.YearIncome?.length === 0 ? null : data?.YearIncome
    }

    if (data != null && leadSourceId === LeadSources.WebsiteTradeIn) {
      const tradeInProps = {
        'Preferable type of contact': data?.PreferableTypeOfContact,
        'Trade in vehicle information': '',
        Year: data?.Year,
        Make: data?.Make,
        Model: data?.Model,
        Trim: data?.Trim,
        Mileage: data?.Mileage,
        VIN: data?.Vin,
        'Vehicle condition': data?.VehicleCondition,
        Collision: data?.Collision,
        'Airbags deployed': data?.AirbagsDeployed,
        'Repairs Cost': data?.RepairsCost,
        'Mechanical issues': data?.MechanicalIssues,
        'Payments on your vehicle': data?.PaymentsOnYourVehicle,
        'Amount still outstanding': data?.AmountStillOutstanding,
        'Trade in value': data?.TradeInValue
      }

      mergedLeadProps = {
        ...mergedLeadProps,
        Address: null,
        'Zip code': leadDetails?.zipCode,
        ...tradeInProps
      }
    }
  }

  if (carOfInterestDetails != null) {
    mergedLeadProps = {
      ...mergedLeadProps,
      'Vehicle details': '',
      Year: carOfInterestDetails?.year,
      Make: carOfInterestDetails?.make,
      Model: carOfInterestDetails?.model,
      Trim: carOfInterestDetails?.trim,
      Mileage: data?.Mileage,
      VIN: carOfInterestDetails?.vin,
      'Stock No': carOfInterestDetails.stock,
      'Deal ID': leadDetails?.dealId,
      Price: carOfInterestDetails?.salePrice,
      'Vehicle condition': data?.VehicleCondition,
      Collision: data?.Collision,
      'Repairs Cost': data?.RepairsCost,
      'Mechanical issues': data?.MechanicalIssues,
      'Payments on your vehicle': data?.PaymentsOnYourVehicle,
      'Amount still outstanding': data?.AmountStillOutstanding,
      'Trade In Value': data?.TradeInValue
    }
  }

  if (isMergedLead) {
    return Object.entries(mergedLeadProps)
      .map(([label, value]) => formatEntryIfPresent({ label, value, labelStyles })).join('')
  } else {
    if (data == null) {
      return null
    }

    const tradeInProps = {
      On: `${formattedDate} at ${formattedTime}, Trade in for an ${data?.Year ?? ''} ${data?.Make ?? ''} ${data?.Model ?? ''}`,
      Name: data?.Name,
      Phone: data?.Phone,
      Email: data?.Email,
      'Zip code': data?.ZipCode,
      'Preferable type of contact': data?.PreferableTypeOfContact,
      Mileage: data?.Mileage,
      'Vehicle condition': data?.VehicleCondition,
      Collision: data?.Collision,
      'Airbags deployed': data?.AirbagsDeployed,
      'Repairs Cost': data?.RepairsCost,
      'Mechanical issues': data?.MechanicalIssues,
      'Payments on your vehicle': data?.PaymentsOnYourVehicle,
      'Amount still outstanding': data?.AmountStillOutstanding,
      'Trade In Value': data?.TradeInValue,
      Vin: data?.Vin
    }

    if (leadSourceId === LeadSources.Financing) {
      const financingProps = {
        Name: data?.Name,
        Phone: data?.Phone,
        'Date of birth': formattedDateOfBirth,
        Email: data?.Email,
        Address: data?.Address,
        'Year income': data?.YearIncome?.length === 0 ? null : data?.YearIncome
      }

      return `${formattedDate} at ${formattedTime}, Pre-Qualified for an auto loan:\n` +
        Object.entries(financingProps).map(([label, value]) => formatEntryIfPresent({ label, value, labelStyles })).join('')
    } else if (leadSourceId === LeadSources.WebsiteTradeIn) {
      return Object.entries(tradeInProps).map(([label, value]) => formatEntryIfPresent({ label, value, labelStyles })).join('')
    }
  }

  return null
}

const getDateTime = (logTypeId: TimelineLogTypes, { leadSourceId, additionalData }: TimelineLogData): string | null => {
  const isAskDriveSource = leadSourceId === LeadSources.Ask || leadSourceId === LeadSources.Drive

  if (logTypeId !== TimelineLogTypes.LeadReceived || !isAskDriveSource) {
    return null
  }

  return additionalData?.DateTime != null ? getDate(additionalData.DateTime) : null
}

const getReturnDealString = (dealId?: number | null): string | null => {
  return dealId != null ? `Deal-${dealId}` : null
}

const getDeal = (logTypeId: TimelineLogTypes, { leadDetails, dealId: _dealId, reason }: TimelineLogData): string | null => {
  if (logTypeId !== TimelineLogTypes.LeadReceived && logTypeId !== TimelineLogTypes.DealManuallyCreated && logTypeId !== TimelineLogTypes.DealStatusUpdated) {
    return null
  }

  if (logTypeId === TimelineLogTypes.DealManuallyCreated ||
    (logTypeId === TimelineLogTypes.DealStatusUpdated && reason === '')
  ) {
    return getReturnDealString(_dealId)
  }

  const { dealId } = leadDetails ?? {}
  return getReturnDealString(dealId)
}

const getSalesperson = (logTypeId: TimelineLogTypes, { salespersonName }: TimelineLogData): string | null => {
  if (logTypeId !== TimelineLogTypes.DealManuallyCreated) {
    return null
  }

  return salespersonName ?? null
}

const getCar = (logTypeId: TimelineLogTypes, { carOfInterestDetails }: TimelineLogData, vehicleId?: number | null): string | null => {
  if ((logTypeId !== TimelineLogTypes.LeadReceived && logTypeId !== TimelineLogTypes.DealManuallyCreated) || carOfInterestDetails == null) {
    return null
  }

  const { make, model, year, stock } = carOfInterestDetails
  const carInfo = `${stock ?? ''} | ${year ?? ''} ${make ?? ''} ${model ?? ''}`
  return vehicleId != null ? `${carInfo}` : null
}

const getReason = (logTypeId: TimelineLogTypes, { reason }: TimelineLogData): string | null => {
  if (logTypeId !== TimelineLogTypes.DealStatusUpdated || reason == null || reason === '') {
    return null
  }

  return reason
}

const getType = ({ type }: TimelineLogData): string | null => {
  if (type == null || type === '') {
    return null
  }

  return type
}

const getVoiceMessage = ({ media }: TimelineLogData): string | null => {
  const hasVoiceMessage = (media ?? [])
    .some(x => x.mediaTypeId === MessageMediaTypeId.Audio)

  return hasVoiceMessage ? '[Voice message]' : null
}

export const parseTimelines = (
  items: TimelineItem[],
  sources: DictionaryItems<string>
): ItemProps[] => {
  return items.map(({
    id,
    logTypeId,
    date: dateToParse,
    logData,
    taskId,
    vehicleId
  }) => {
    const {
      subject: title,
      emailId,
      description = null,
      activityCompleteSubOptions,
      activityCompleteOption,
      leadDetails,
      dealId
    } = logData

    const workNote = getWorkNote(logTypeId, logData)
    const name = getName(logTypeId, logData)
    const date = getDate(dateToParse)
    const completionDate = getLogActivityDate(logTypeId, logData)
    const showedUpOn = getShowedUp(logTypeId, logData)
    const heldOn = getHeldOn(logTypeId, logData)
    const source = getSource(logTypeId, logData, sources)
    const descriptionName = getDescriptionName(logTypeId)
    const additionalHeaderData = getAdditionalHeaderData(logData, logTypeId)
    const emailSubject = getEmailSubject(logTypeId, logData)
    const feedbackActions = getFeedbackAction(logTypeId, logData)
    const body = getBody(logTypeId, logData.body)
    const leadLost = getLeadLost(logTypeId, logData)
    const attachmentsSummary = getAttachments(logData)
    const voiceMessage = getVoiceMessage(logData)
    const message = getMessage(logTypeId, logData)
    const customerComment = (logData.comment !== '' && logData.comment != null) ? logData.comment : null
    const additionalLeadReceivedData = getAdditionalDataForLeadReceived(logTypeId, logData)
    const deal = getDeal(logTypeId, logData)
    const car = getCar(logTypeId, logData, vehicleId)
    const dateTime = getDateTime(logTypeId, logData)
    const salesperson = getSalesperson(logTypeId, logData)
    const reason = getReason(logTypeId, logData)
    const type = getType(logData)
    const clickedAction = getClickedLinkAction(logTypeId, logData)
    const openedAction = getOpenedAction(logTypeId, logData)
    const isWebchat = logData.leadSourceId === LeadSources.WebChat

    return {
      id,
      taskId: taskId ?? null,
      title,
      emailId: emailId ?? null,
      logTypeId,
      dateISO: dateToParse,
      date,
      attachments: logData.media ?? [],
      name,
      type: logData.type ?? null,
      salesperson,
      feedbackActions,
      additionalHeaderData,
      activityCompleteOption: activityCompleteOption != null ? activityCompleteOption : '',
      activityCompleteSubOptions: activityCompleteSubOptions != null ? activityCompleteSubOptions : [],
      vehicleId,
      dealId: (leadDetails?.dealId ?? dealId) ?? null,
      clickedAction,
      openedAction,
      isWebchat,
      threadId: logData.threadId,
      leadDetails,

      /**
       * AZ-NOTE:
       * - order of items matters.
       * - what if order will be different for different log types?
       * - need to add an explicit order, where order depends on the log type
       */
      textItems: [
        ...getTextItem(reason, TimelineTextTitles.Reason),
        ...getTextItem(logTypeId !== TimelineLogTypes.EmailReceived ? attachmentsSummary : null, null),
        ...getTextItem(showedUpOn, TimelineTextTitles.ShowedUpOn),
        ...getTextItem(heldOn, TimelineTextTitles.HeldOn),
        ...getTextItem(completionDate, TimelineTextTitles.Date),
        ...getTextItem(source, TimelineTextTitles.Source),
        ...getTextItem(deal, TimelineTextTitles.Deal),
        ...getTextItem(car, TimelineTextTitles.Car),
        ...getTextItem(salesperson, TimelineTextTitles.Salesperson),
        ...getTextItem(workNote, TimelineTextTitles.WorkNote),
        ...getTextItem(description, descriptionName),
        ...getTextItem(type, TimelineTextTitles.Type),
        ...getTextItem(emailSubject, TimelineTextTitles.EmailSubject),
        ...getTextItem(logTypeId === TimelineLogTypes.EmailReceived ? attachmentsSummary : null, null),
        ...getTextItem(body, null),
        ...getTextItem(leadLost, null),
        ...getTextItem(message, null),
        ...getTextItem(logTypeId === TimelineLogTypes.MessageReceived ? voiceMessage : null, null),
        ...getTextItem(customerComment, TimelineTextTitles.CustomerComment),
        ...getTextItem(dateTime, TimelineTextTitles.DateTime),
        ...getTextItem(additionalLeadReceivedData, null)
      ]
    }
  })
}

export const parseDataToEmailThread = (data: EmailThread, token: string): EmailThread => ({
  ...data,
  emails: data.emails.map((email) =>
    ({
      ...email,
      emailAttachments: email.emailAttachments.map((attachment) => {
        const url = isPublicUrl(attachment.url)
          ? attachment.url
          : `${attachment.url}?${token}`

        return {
          ...attachment,
          url
        }
      }),
      createdDate: convertDateToUTCString(email.createdDate) ?? '',
      body: getBody(TimelineLogTypes.EmailSent ?? TimelineLogTypes.EmailSentAutomatically, email.body)
    })
  )
})
