import { useCallback, useState, useRef, useEffect } from 'react'
import { useForm, useLoader, isOk } from '@carfluent/common'
import { useNavigate } from 'react-router-dom'
import { isAxiosError, AxiosError } from 'axios'

import { Routes } from 'constants/route_helper'
import AccountingApiProvider from 'api/accounting.api'
import FilesApiProvider from 'api/files.api'
import isFile from 'utils/common/isFile'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { type ItemWithName, type UploadedBankStatementWithResults, type BaseListItem } from 'types'
import {
  type AccountListPayload,
  type AccountListItem,
  type PaginatedResult,
  AccountCategoryId
} from 'api/types'

import { GET_DEFAULT_FORM_DATA, FieldsId, Messages } from './constants'
import validationRules from './validation'
import {
  type UseStatementUploadFormProps,
  type UseStatementUploadFormReturn,
  type StatementUploadFormData
} from './types'

const DEFAULT_FORM_DATA = GET_DEFAULT_FORM_DATA()

const useStatementUploadForm = ({
  onSubmitBankStatement
}: UseStatementUploadFormProps): UseStatementUploadFormReturn => {
  const navigate = useNavigate()
  const { showError } = useCustomSnackbar()

  const [isDuplicateInfoVisible, setIsDuplicateInfoVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [bankList, setBankList] = useState<BaseListItem[]>([])

  // AZ-NOTE: ref needed to avoid race condition is useForm
  const refFormSubmitResults = useRef<UploadedBankStatementWithResults | null>(null)

  const {
    isLoading,
    startLoader,
    stopLoader
  } = useLoader()

  const submitAction = useCallback(async (values: StatementUploadFormData): Promise<void> => {
    try {
      setErrorMessage(null)
      startLoader()

      const payload = {
        accountId: Number(values.account?.id ?? -1),
        bankId: values.bank?.id,
        fileId: values.fileId ?? -1,
        originalFileName: values.files[0].name
      }

      const response = await AccountingApiProvider.parseBankStatements(payload)
      refFormSubmitResults.current = {
        ...payload,
        parsingResults: response
      }
    } catch (err) {
      refFormSubmitResults.current = null

      if (isAxiosError(err)) {
        if (err.code === AxiosError.ERR_BAD_REQUEST) {
          setErrorMessage(err.response?.data?.message)
          return
        }
      }

      showError(Messages.SomethingWrong)
    } finally {
      stopLoader()
    }
  }, [showError, stopLoader, startLoader])

  const onActionResult = useCallback((res) => {
    if (isOk(res) && (refFormSubmitResults.current != null)) {
      onSubmitBankStatement(refFormSubmitResults.current)
    }
  }, [onSubmitBankStatement])

  const form = useForm<StatementUploadFormData>({
    baseValues: DEFAULT_FORM_DATA, // AZ-TODO: props.savedFormData ?? DEFAULT_FORM_DATA
    validationRules,
    submitAction,
    onActionResult
  })

  const {
    setFieldError,
    setFieldValue,
    setFieldTouched,
    validateField,
    onSubmit
  } = form

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const getAccounts = useCallback(async (
    payload: AccountListPayload = { take: 20, skip: 0 }
  ): Promise<PaginatedResult<AccountListItem>> => {
    return await AccountingApiProvider.getAccounts({
      ...payload,
      categoryIds: [
        AccountCategoryId.Banks,
        AccountCategoryId.CreditCard,
        AccountCategoryId.BankChecking
      ]
    })
  }, [])

  const onChangeFiles = useCallback(async (id: string, value: ItemWithName[]): Promise<void> => {
    /**
     * File removed.
     */
    if (value.length === 0) {
      setFieldValue(id, value)
      setFieldValue(FieldsId.FileId, null)
      setFieldTouched(id, true)
      setTimeout(() => { validateField(id) }, 0) // AZ-NOTE: race condition in useForm.validation
      return
    }

    /**
     * File added.
     */
    try {
      startLoader()

      const formData = new FormData()
      const nextValue: File[] = []
      const file = value[0]

      if (!isFile(file)) {
        return // AZ-NOTE: impossible case (for single-file scenario), added for TS appeasement
      }

      const error = validationRules.files?.(value)
      if (error != null) {
        setFieldError(id, error)
        setFieldTouched(id, true)
        setFieldValue(id, [])
        return
      }

      formData.append('File', file)
      formData.append('parameters', '{}')
      nextValue.push(file)

      const uploadedFileInfo = await FilesApiProvider.uploadBankStatement(formData)
      const previousBankStatementsInfo = await AccountingApiProvider.getBankStatements({ originalFileName: file.name })

      setIsDuplicateInfoVisible(previousBankStatementsInfo.items.length > 0)
      setFieldValue(id, nextValue) // for display
      setFieldValue(FieldsId.FileId, uploadedFileInfo.id) // for form submit
      validateField(id)
      setFieldTouched(id, true)
    } catch (err) {
      console.error(err)
    } finally {
      stopLoader()
    }
  }, [
    setFieldValue,
    setFieldTouched,
    startLoader,
    stopLoader,
    validateField,
    setFieldError
  ])

  const onGotoList = useCallback(() => {
    navigate(Routes.AccountingBanking)
  }, [navigate])

  const onSubmitUploadForm = useCallback(() => {
    void onSubmit?.()
    setFieldTouched('files', true)
  }, [setFieldTouched, onSubmit])

  useEffect(() => {
    const load = async (): Promise<void> => {
      const res = await AccountingApiProvider.getBanks()
      setBankList(res.items)
    }

    void load()
  }, [])
  // ========================================== //

  return {
    ...form,
    errorMessage,
    getAccounts,
    isDuplicateInfoVisible,
    isFilesLoading: isLoading,
    isFormSubmitting: form.isSubmitting,
    bankList,
    onChangeFiles,
    onGotoList,
    onSubmitUploadForm
  }
}

export default useStatementUploadForm
