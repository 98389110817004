import { type FC } from 'react'
import { Button, Loader, cnx } from '@carfluent/common'
import { DragDropContext } from 'react-beautiful-dnd'

import DeleteModal from 'components/dialogs/DeleteModal'
import UnsavedChangesDialog from 'components/dialogs/UnsavedChanges'
import SaveCloseButtonsPanel from 'components/common/SaveCloseButtonsPanel'

import useExactPacks from './hook'
import { Messages } from './hook/constants'
import ExactPackForm from './components/ExactPackForm'
import SkeletonTable from './components/SkeletonTable'
import DroppableTable from './components/DroppableTable'
import ExactPackTableHeader from './components/ExactPackTableHeader'

import CLASS_NAME from './styles'

const ExactPack: FC = () => {
  const {
    rows,
    onClose,
    onSubmit,
    formPack,
    onDragEnd,
    formProps,
    onDeletePack,
    onDeleteForm,
    isSubmitting,
    isFormChanged,
    isFormLoading,
    onSaveAndCloseForm,
    isUnsavedChangesOpen,
    isDeleteFormModalOpen,
    onOpenDeleteFormModal,
    onCloseDeleteFormModal,
    onDontSaveAndCloseForm,
    onCloseUnsavedChangesModal
  } = useExactPacks()

  return (
    <div className={cnx('grey-background-page', 'g-submenu-content', 'g-margin-top-big', CLASS_NAME)}>
      {isFormLoading && <Loader size='large' />}

      <div className='header-block'>
        <Button onClick={onOpenDeleteFormModal} variant='text'>DELETE PACK</Button>
        <ExactPackForm formProps={formProps} formPack={formPack} />
      </div>
      <ExactPackTableHeader />

      <DragDropContext onDragEnd={onDragEnd}>
        <DroppableTable data={rows} onDeleteForm={onDeleteForm} />
      </DragDropContext>

      {
        (rows?.length === 0 && !isFormLoading) && (
          <div className='empty-table-class-name'>
            <span>{Messages.EmptyTableState}</span>
          </div>
        )
      }

      {isFormLoading && <SkeletonTable />}

      <SaveCloseButtonsPanel
        handleReset={onClose}
        handleSubmit={onSubmit}
        titleCloseButton='CANCEL'
        isSubmitting={isSubmitting}
        isSubmitDisabled={!isFormChanged}
      />

      <DeleteModal
        onConfirm={onDeletePack}
        title='Delete form pack'
        submitTitle='YES, DELETE'
        isOpen={isDeleteFormModalOpen}
        onClose={onCloseDeleteFormModal}
        content='Are you sure you want to delete the pack?'
      />

      <UnsavedChangesDialog
        isSubmitting={isSubmitting}
        isOpen={isUnsavedChangesOpen}
        onSaveAndClose={onSaveAndCloseForm}
        onCloseDialog={onCloseUnsavedChangesModal}
        onDontSaveAndClose={onDontSaveAndCloseForm}
        content='Your changes will be lost if you dont save them'
      />
    </div>
  )
}

export default ExactPack
