import { type FC } from 'react'

import type { Shift, Coords } from 'types'

import { getShiftsForDate } from 'pages/crm/WorkSchedule/hook/utils'

import Day from '../Day'

interface DaysProps {
  dates: Array<Array<Date | null>>
  shifts: Shift[]
  editShift: Shift | null
  addShiftDate: Date | null
  isBDC: boolean
  onOpenAddShift: (date: Date) => void
  onOpenEditShift: (shift: Shift) => void
  changeChipPosition: (pos: Coords) => void
}

const Days: FC<DaysProps> = ({
  dates,
  onOpenAddShift,
  onOpenEditShift,
  shifts,
  editShift,
  addShiftDate,
  isBDC,
  changeChipPosition
}) => (
  <>
    {dates?.flat().map((date, index) => (
      <Day
        isBDC={isBDC}
        key={date?.toISOString() ?? `${index}empty`}
        date={date}
        editShift={editShift}
        isAddBtnActive={addShiftDate === date}
        onOpenAddShift={onOpenAddShift}
        onOpenEditShift={onOpenEditShift}
        changeChipPosition={changeChipPosition}
        shifts={getShiftsForDate(date, shifts)} // AS-NOTE: potential performance issue, but it's ok for now
      />
    ))}
  </>
)

export default Days
