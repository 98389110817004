import { css } from '@emotion/css'

export default css(`
  .carousel { padding: 0 60px 0; }

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .view-img-wrapper {
    height: 100%;
    margin-bottom: 2px;
    border-radius: 12px;
    position: relative;
    width: 600px;
    min-height: 524px;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }

  .view-img {
    width: 100%;
    max-height: 100%;
  }

  .select-btn {
    height: 48px;
    margin: 24px auto 0;
    text-transform: uppercase;
  }

  .description {
    display: flex;
    align-items: center;
  }

  .customize-btn {
    height: 26px;
    border-radius: 8px;
    color: #000000;
    margin: 0px 8px;
    padding: 0px 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;

    .cf-button-start-adornment { margin-right: 4px; }
  }

  .name-of-view {
    font-size: 14px;
    line-height: 20px;
    color: rgba(33, 33, 33, 0.8);
    margin-bottom: 14px;
  }

  .nav-button-wrapper {
    right: 0;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    :last-child {
      right: auto;
      left: 0;
    }
  }

  .nav-button {
    background: rgba(0, 0, 0, 0.1);
    opacity: 1;
    filter: none;
    padding: 0;
    top: 0 !important;
    margin: 0;
    width: 100%;
    height: 100%;
    path { fill: #101010; }

    :hover {
      opacity: 1 !important;
      background: rgba(0, 0, 0);
      path { fill: #ffffff; }
    }
  }
`)
