import type { ProfitLossReport } from 'api/types/accounting.types'
import { ProfitLossTypes } from 'api/types/accounting.types'
import type { RowProps } from 'components/reports/ReportsBSPLTable/components/Row'
import { RowVariants } from 'components/reports/ReportsBSPLTable/components/Row'
import { getBalanceValue } from 'pages/reports/ReportsList/utils'

import { GROUP_SUBTITLES, GROUP_TITLES } from './constants'

export const parseData = ({
  expenses: {
    accountTypeData: {
      accountItems
    },
    totalBalance
  },
  netProfit,
  profits: {
    accountTypesData,
    totalBalance: tbProfits
  }
}: ProfitLossReport): RowProps[] => [
  {
    variant: RowVariants.Title,
    text: GROUP_TITLES[ProfitLossTypes.Profit],
    value: 'Balance'
  },
  { variant: RowVariants.Empty },
  ...(
    accountTypesData != null
      ? accountTypesData.reduce((res: RowProps[], {
        accountType,
        accountItems,
        totalBalance
      }) => {
        return [
          ...res,
          ...(
            accountItems.length > 0
              ? [
                  {
                    variant: RowVariants.Subtitle,
                    text: GROUP_SUBTITLES[accountType]
                  },
                  ...(
                    accountItems.map(({
                      accountName,
                      accountNumber,
                      amount
                    }) => ({
                      variant: RowVariants.Info,
                      value: getBalanceValue(amount),
                      text: `${accountNumber} ${accountName}`
                    }))
                  ),
                  {
                    variant: RowVariants.Subtitle,
                    text: `Total for ${GROUP_SUBTITLES[accountType]}`,
                    value: accountItems?.length === 0 ? '' : getBalanceValue(totalBalance)
                  },
                  { variant: RowVariants.Empty }
                ]
              : []
          )
        ]
      }, [])
      : []),
  {
    variant: RowVariants.Total,
    text: `Total ${GROUP_TITLES[ProfitLossTypes.Profit]}`,
    value: getBalanceValue(tbProfits, '')
  },
  { variant: RowVariants.Empty, name: 'title-row-divider', key: 'divider-1' },
  {
    variant: RowVariants.Title,
    text: GROUP_TITLES[ProfitLossTypes.Expense],
    value: 'Balance'
  },
  { variant: RowVariants.Empty },
  ...(
    accountItems.map(({
      accountName,
      accountNumber,
      amount
    }) => ({
      variant: RowVariants.Info,
      value: getBalanceValue(amount),
      text: `${accountNumber} ${accountName}`
    }))
  ),
  { variant: RowVariants.Empty },
  {
    variant: RowVariants.Total,
    text: `Total ${GROUP_TITLES[ProfitLossTypes.Expense]}`,
    value: getBalanceValue(totalBalance, '')
  },
  { variant: RowVariants.Empty, name: 'title-row-divider', key: 'divider-2' },
  {
    variant: RowVariants.Total,
    text: GROUP_TITLES[ProfitLossTypes.NetProfit],
    value: getBalanceValue(netProfit),
    name: 'net-profit-block'
  }
]
