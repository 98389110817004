import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGFail: FC<IconProps> = ({ color = '#B00020', width = 20, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_86082_291007)'>
        <path d='M10.0003 1.66797C5.39199 1.66797 1.66699 5.39297 1.66699 10.0013C1.66699 14.6096 5.39199 18.3346 10.0003 18.3346C14.6087 18.3346 18.3337 14.6096 18.3337 10.0013C18.3337 5.39297 14.6087 1.66797 10.0003 1.66797ZM14.167 12.993L12.992 14.168L10.0003 11.1763L7.00866 14.168L5.83366 12.993L8.82533 10.0013L5.83366 7.00963L7.00866 5.83464L10.0003 8.8263L12.992 5.83464L14.167 7.00963L11.1753 10.0013L14.167 12.993Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_86082_291007'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>

  )
}

export default IconSVGFail
