import { type FiltersFormData } from 'types/filters'
import { type FiltersType } from 'utils/filters/types'
import serializeFiltersBase from 'utils/filters/serializeFiltersBase'
import serializeLocalDateTime from 'utils/filters/serializeLocalDateTime'

const serializeFiltersLocal = (payload: FiltersFormData): FiltersType => {
  return serializeFiltersBase(payload, { serializeDate: serializeLocalDateTime })
}

export default serializeFiltersLocal
