import { type MouseEvent, useCallback, useEffect, useRef, useState } from 'react'

import type { FCHook } from 'types'
import type { ListPayload, PaginatedResult, Vehicle } from 'api/types'
import VehiclesApiProvider from 'api/vehicles.api'
import { GET_DEFAULT_VEHICLES_SORTING } from 'api/defaults'
import { carToVehicle, matchesSearchCriteria } from 'utils/vehicleDropdownHelpers'

import type {
  UseAddCarDropdownProps,
  UseSnippetsDropdownReturn,
  CarBlockData,
  VehicleWithGroup
} from './types'
import { addCarBlockToState } from './utils'
import {
  GROUP_FROM_INVENTORY,
  GROUP_CARS_OF_INTEREST,
  GROUP_SUGGESTED_CARS,
  suggestedCarsGroup,
  prependGroupNameOptions,
  RACE_CONDITIONS_GODFATHER
} from './constants'

const useAddCarDropdown: FCHook<UseAddCarDropdownProps, UseSnippetsDropdownReturn> = ({
  suggestedCars = [],
  carsOfInterest,
  editorState,
  linkToWebsite,
  onChange: _onChange
}) => {
  const [isOpen, setOpen] = useState(false)
  /**
   * actually all of this should be removed
   * refDropdownContent need to handle proper clickOutside behavior.
   * By default, we have popover(from dropdown) inside popover, but when we choose an option,
   * the first popover threat it as clickOutside and close everything(that is not always needed)
   */
  const refDropdownContent = useRef<HTMLDivElement | null>(null)
  const refInput = useRef<HTMLElement | null>(null)

  const refEditorState = useRef(editorState)
  const refAnchorEl = useRef<null | HTMLElement>(null)
  const refSearch = useRef('')
  const refInitialLoad = useRef(true)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const getVehicles = useCallback(async (payload: ListPayload): Promise<PaginatedResult<VehicleWithGroup>> => {
    const search = payload?.search?.toLowerCase() ?? ''

    try {
      const response = await VehiclesApiProvider.getVehicles({ ...payload, ...GET_DEFAULT_VEHICLES_SORTING() })

      if (response.items.length === 0) {
        refInitialLoad.current = true
        return { items: [], count: 0 }
      }

      let newVehicles = response.items.map(item => ({ ...item, group: GROUP_FROM_INVENTORY }))

      const vehiclesOfInterest = carsOfInterest
        .filter(item => matchesSearchCriteria(item, search))
        .map(carToVehicle)

      /**
       * Suggested cars are only available if there are cars of interest.
       */
      const suggestedVehicles = carsOfInterest.length > 0
        ? suggestedCars
          .filter(item => matchesSearchCriteria(item, search))
          .map(suggestedCarsGroup)
        : []

      const existingIds = new Set([
        ...vehiclesOfInterest.map(item => item.id),
        ...suggestedVehicles.map(item => item.id)
      ])

      newVehicles = newVehicles.filter(item => !existingIds.has(item.id))

      if (refInitialLoad.current || (refSearch.current !== search)) {
        newVehicles = [
          ...prependGroupNameOptions(vehiclesOfInterest, GROUP_CARS_OF_INTEREST),
          ...prependGroupNameOptions(suggestedVehicles, GROUP_SUGGESTED_CARS),
          ...prependGroupNameOptions(newVehicles, GROUP_FROM_INVENTORY)
        ]
      }

      refInitialLoad.current = false
      refSearch.current = search

      return {
        ...response,
        items: newVehicles
      }
    } catch (err) {
      return { items: [], count: 0 }
    }
  }, [])

  const onAddCarClick = useCallback((evt?: MouseEvent<Element>) => {
    if (refAnchorEl.current == null) {
      refAnchorEl.current = evt?.currentTarget as HTMLElement ?? null
    }
    refInitialLoad.current = true
    setOpen(prev => !prev)
  }, [])

  const onChange = useCallback((_, value: Vehicle | null): void => {
    if ((value == null) || (refEditorState.current == null)) {
      return
    }

    const inlineData: CarBlockData = {
      car: value,
      linkToWebsite
    }

    const lastEditorState = addCarBlockToState(refEditorState.current, inlineData)

    setTimeout(() => {
      refEditorState.current = lastEditorState
      _onChange?.(lastEditorState)
    }, RACE_CONDITIONS_GODFATHER)

    setOpen(false)
  }, [_onChange])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (!isOpen) {
      return
    }

    /**
     * refInput.current?.focus() - to unfocus from editor input.
     */
    setTimeout(() => {
      refInput.current = document.querySelector('.vehicles-dropdown input')
      refDropdownContent.current = document.querySelector('.add-car-dropdown-popover')
      refInput.current?.focus()
    }, RACE_CONDITIONS_GODFATHER)

    const modalContentScrollWrapper = document.querySelector('.cf-modal-content-scroll-wrapper') as HTMLElement
    if (modalContentScrollWrapper != null) {
      modalContentScrollWrapper.style.overflow = 'hidden'
    }

    return () => {
      modalContentScrollWrapper.style.overflow = ''
    }
  }, [isOpen])

  /**
   * Sync local editor state with props.
   */
  useEffect(() => {
    if (editorState !== refEditorState.current) {
      refEditorState.current = editorState
    }
  }, [editorState])

  // ========================================== //

  return {
    refAnchorEl,
    refDropdownContent,
    isOpen,
    onChange,
    onClose: () => setOpen(false),
    onAddCarClick,
    getVehicles
  }
}

export default useAddCarDropdown
