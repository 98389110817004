import { useState, FC, useCallback } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'

import type { DateBalance } from 'api/types'
import { ColorPeriod } from './types'
import ActiveShape from './ActiveShape'
import LabelShape from './LabelShape'

const EMPTY_COLOR = '#DDDDDD'
const EMPTY_DATA = [{ name: 'No Data', balance: 1 }]

export interface SchedulesPieChartProps {
  data: DateBalance[]
  isLoading?: boolean
  onSelectSector?: (data: DateBalance | null, idx: number | null) => void
  selectedSector?: DateBalance | null
}

const SchedulesPieChart: FC<SchedulesPieChartProps> = ({
  data,
  isLoading = false,
  onSelectSector,
  selectedSector
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null)

  const resolvedFocusedIndex = selectedSector !== undefined
    ? data.findIndex(item => isDateBalancesEqual(item, selectedSector)) ?? null
    : focusedIndex

  const activeIndex = [hoveredIndex, resolvedFocusedIndex].filter(item => item != null) as number[]

  const onToggleSector = useCallback((index) => {
    if (isLoading) {
      return
    }

    if (resolvedFocusedIndex !== index) {
      setFocusedIndex(index)
      onSelectSector?.(data[index], index)
    } else {
      setFocusedIndex(null)
      onSelectSector?.(null, null)
    }
    setHoveredIndex(null)
  }, [
    data,
    isLoading,
    onSelectSector,
    resolvedFocusedIndex
  ])

  return (
    <ResponsiveContainer>
      <PieChart margin={{ top: 5, right: 5, bottom: 5, left: 15 }}>
        {data != null && data.length > 0
          ? (
            <Pie
              data={data.map((el, index) => ({ ...el, index }))}
              activeIndex={activeIndex}
              innerRadius={60}
              outerRadius={110}
              minAngle={12}
              dataKey='balance'
              activeShape={(props) => (
                <ActiveShape
                  isActive
                  isHover={resolvedFocusedIndex !== props.index}
                  onClick={() => onToggleSector(props.index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  {...props}
                />
              )}
              isAnimationActive={false}
              label={(props) => {
                return (
                  <LabelShape
                    isActive={activeIndex.length === 0 || activeIndex.includes(props.index)}
                    {...props}
                  />
                )
              }}
              inactiveShape={(props) => (
                <ActiveShape
                  isHover={resolvedFocusedIndex !== props.index}
                  onClick={() => onToggleSector(props.index)}
                  isActive={false}
                  onMouseLeave={() => {
                    setHoveredIndex(null)
                  }}
                  {...props}
                />
              )}
            >
              {data?.map((el, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={ColorPeriod[el.dateRange?.range]}
                  onMouseEnter={() => {
                    setHoveredIndex(index)
                  }}
                  onMouseLeave={() => {
                    setHoveredIndex(null)
                  }}
                />
              ))}
            </Pie>
            )
          : (
            <Pie
              data={EMPTY_DATA}
              dataKey='balance'
              fill={EMPTY_COLOR}
              innerRadius={60}
              outerRadius={110}
              isAnimationActive
            />
            )}
      </PieChart>
    </ResponsiveContainer>
  )
}

export default SchedulesPieChart

// ========================================== //

const isDateBalancesEqual = (b1: DateBalance, b2: DateBalance | null): boolean => {
  if (b2 == null) {
    return false
  }

  return (b1.dateRange.from === b2.dateRange.from) && (b1.dateRange.to === b2.dateRange.to)
}
