import { css } from '@emotion/css'

import { RowVariants } from 'components/reports/ReportsBSPLTable/components/Row'

export const createStyleClass = (): string => {
  return css(`
    .cf-bs-pl-table {
      margin-top: 0;
      .cf-row-${RowVariants.Empty} {
        height: 16px;
        &.cf-title-row-divider {
          height: 24px;
        }
      }

      .cf-row-${RowVariants.Empty}.cf-title-row-divider td {
         padding: 14px 0;
      }

      .cf-row-${RowVariants.Total}.cf-net-profit-block td {
        border-bottom: none;
        padding-bottom: 16px;
      }
    }

    .cf-top-wrapper {
      margin-bottom: 24px;
    }
  `)
}
