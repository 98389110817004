import type { FC } from 'react'

interface ArrowListToggleIconProps {
  isActive?: boolean
  isToggleToOpen: boolean
}

const ArrowListToggleIcon: FC<ArrowListToggleIconProps> = ({
  isActive = false,
  isToggleToOpen
}) => {
  const pictogramFill = isActive ? '#fff' : '#101010'

  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: isToggleToOpen ? 'rotate(0deg)' : 'rotate(180deg)' }}
    >
      <g clipPath='url(#clip0_74743_223146)'>
        <path
          d='M11.06 5.72656L8 8.7799L4.94 5.72656L4 6.66656L8 10.6666L12 6.66656L11.06 5.72656Z'
          fill={pictogramFill}
        />
      </g>

      <defs>
        <clipPath id='clip0_74743_223146'>
          <rect width='16' height='16' fill='white' transform='matrix(-1 0 0 1 16 0)'/>
        </clipPath>
      </defs>
    </svg>

  )
}

export default ArrowListToggleIcon
