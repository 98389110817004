import { serializers as S } from '@carfluent/common'

import { type RangeFilter } from 'types'
import { type GetDashboardDealsDto } from 'api/types/requests'
import serializeDateFilters from 'utils/filters/serializeDateFilters'

const serializeFilters = (filters: RangeFilter<Date | null>): GetDashboardDealsDto | null => {
  const dateRange = serializeDateFilters(filters, S.serializeDateTime)

  if (dateRange == null) {
    return null
  }

  return {
    dateRange
  }
}

export default serializeFilters
