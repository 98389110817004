import { useState, useMemo, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useLoader, UI } from '@carfluent/common'

import type { ReconVehicleDetailsResponseDto } from 'api/types'
import useEffectOnce from 'hooks/useEffectOnce'
import ReconAPIProvider from 'api/recon.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { isNotFoundError } from 'utils/isNotFoundError'
import useAsyncEffect from 'hooks/useAsyncEffect'
import type { InspectionGeneralBlockProps } from 'components/reconditioning/InspectionGeneralBlock'
import { formatDateForTimelineGroup } from 'utils/formatTimelineGroup'
import { formatUTCDateTimeForView } from 'utils/view_helper'

import { parseStepChanges } from './parser'
import { DATE_FORMAT, getDefaultDetails, getDefaultGeneralBlockData, Messages } from './constants'
import { useGallery } from './useGallery'
import type { UseGalleryReturn } from './useGallery'
import type { HistoryProps } from '../components/HistoryTable'
import type { TimelineProps } from '../components/Timeline'

const { defaultParseData: parseErrors } = UI

interface UseReconDetailsReturn extends UseGalleryReturn {
  onGoBack: () => void
  details: ReconVehicleDetailsResponseDto
  onUpdateFavorite: (isFavorite: boolean) => Promise<void>
  onUpdateStep: (id: number) => Promise<void>
  isStepLoading: boolean
  historyProps: HistoryProps
  timelineProps: TimelineProps
  uploadImageError?: string
  generalBlockData: InspectionGeneralBlockProps
  initLoading: boolean
}

export const useReconDetails = (): UseReconDetailsReturn => {
  const { id = '' } = useParams()
  const navigate = useNavigate()
  const { showAlert } = useCustomSnackbar()
  const [initLoading, setInitLoading] = useState(true)
  const [details, _setDetails] = useState<ReconVehicleDetailsResponseDto>(getDefaultDetails())
  const [generalBlockData, setGeneralBlockData] = useState(getDefaultGeneralBlockData)

  const setDetails = useCallback((details: ReconVehicleDetailsResponseDto) => {
    _setDetails({
      ...details,
      logs: details.logs.map(log => ({
        ...log,
        date: formatDateForTimelineGroup(log.date)
      }))
    })
  }, [])

  const {
    isLoading: isStepLoading,
    startLoader: startStepLoader,
    stopLoader: stopStepLoader
  } = useLoader()

  const {
    isLoading: isCommentLoading,
    startLoader: startCommentLoader,
    stopLoader: stopCommentLoader
  } = useLoader()

  const {
    uploadImageError,
    ...galleryProps
  } = useGallery({
    vehicleImages: details.vehicleImages,
    setDetails,
    id
  })

  const onGoBack = useCallback(() => navigate(-1), [navigate])

  const onUpdateFavorite = useCallback(async (isFavorite: boolean): Promise<void> => {
    if (isFavorite === details.isFavorite) {
      return
    }

    setDetails({
      ...details,
      isFavorite: !details.isFavorite
    })

    try {
      await ReconAPIProvider.updateReconFavorite(id, isFavorite)
    } catch {
      _setDetails((details) => ({
        ...details,
        isFavorite: !details.isFavorite
      }))

      showAlert(Messages.FailedUpdateFavorite, { variant: 'error' })
    }
  }, [details])

  const onUpdateStep = useCallback(async (reconStepId: number): Promise<void> => {
    startStepLoader()

    try {
      const details = await ReconAPIProvider.updateReconDetails(id, { reconStepId })
      setDetails(details)
    } catch (e) {
      showAlert(
        isNotFoundError(e)
          ? Messages.NotFoundUpdateReconDetail
          : parseErrors(e) ?? Messages.FailedUpdateStep)
    } finally {
      stopStepLoader()
    }
  }, [
    showAlert,
    setDetails,
    stopStepLoader,
    startStepLoader
  ])

  const onAddComment = useCallback(async (body: string): Promise<void> => {
    try {
      startCommentLoader()
      const logDataItem = await ReconAPIProvider.postReconComment(id, { comment: body })

      _setDetails(details => {
        const logItemDate = formatDateForTimelineGroup(logDataItem.date)

        if (logItemDate == null) {
          return details
        }

        if (details.logs.length > 0 && formatDateForTimelineGroup(details.logs[0].date) === logItemDate) {
          return {
            ...details,
            logs: [
              {
                date: logItemDate,
                logs: [logDataItem, ...details.logs[0].logs]
              },
              ...details.logs.slice(1)
            ]
          }
        } else {
          return {
            ...details,
            logs: [
              {
                date: logDataItem.date,
                logs: [logDataItem]
              },
              ...details.logs
            ]
          }
        }
      })
    } finally {
      stopCommentLoader()
    }
  }, [startCommentLoader, stopCommentLoader])

  useEffectOnce(() => {
    const runEffect = async (): Promise<void> => {
      const details = await ReconAPIProvider.getReconDetails(id)
      setDetails(details)
      setInitLoading(false)
    }

    void runEffect()
  }, [id, setDetails])

  useAsyncEffect(async () => {
    const updatedGeneralBlockData = await ReconAPIProvider.summaryVehicleInspection(id)

    setGeneralBlockData({
      ...updatedGeneralBlockData,
      statusInfo: updatedGeneralBlockData.submittedDateTime !== null ? `Submitted on ${formatUTCDateTimeForView(updatedGeneralBlockData.submittedDateTime, DATE_FORMAT) ?? ''} by ${updatedGeneralBlockData.submittedByUser?.dealerFirstName ?? ''} ${updatedGeneralBlockData.submittedByUser?.dealerLastName ?? ''}` : 'In progress',
      isCompleted: updatedGeneralBlockData.submittedDateTime !== null,
      isLoading: false
    })
  }, [id])

  const historyRows = useMemo(() =>
    parseStepChanges(details.stepChanges), [details.stepChanges])

  return {
    onGoBack,
    initLoading,
    ...galleryProps,
    details,
    onUpdateFavorite,
    onUpdateStep,
    isStepLoading,
    historyProps: {
      historyRows,
      createdDate: details.createdDate
    },
    timelineProps: {
      onAddComment,
      initLoading,
      logs: details.logs ?? [],
      isLoading: isCommentLoading
    },
    uploadImageError,
    generalBlockData: {
      ...generalBlockData,
      to: `/recon/${id}/intake-inspection`
    }
  }
}
