import { type FC, useState, useCallback, useMemo } from 'react'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import Checkbox from 'components/common/Checkbox'
import IconSVG from 'components/inlineImages'

import LineItem from './line'

import { UseStatementPreviewFormReturn } from '../../hook/types'
import CLASS_NAME from './styles'

const UploadedLines: FC<UseStatementPreviewFormReturn> = ({ ...props }) => {
  const {
    values,
    setFieldValue,
    setValues
  } = props
  const isShouldSelectAll = useMemo(() => values.every(({ checked }) => checked), [values])
  const isIndeterminate = values.some(({ checked }) => !checked) && !isShouldSelectAll
  const [selectedAll, setSelectedAll] = useState(isShouldSelectAll)

  const onChangeAll = useCallback(() => {
    const copyValue = [...values]
    const updatedValues = copyValue.map((item) => ({
      ...item,
      checked: selectedAll ? false : !(item.hasError || item.isDuplicate)
    }))

    setValues(updatedValues, true, true)

    setSelectedAll((prev) => !prev)
  }, [selectedAll, values, setValues])

  return (
    <div className={CLASS_NAME}>
      <div className='header row'>
        <div className='column'>
          <Checkbox
            indeterminateIcon={<IconSVG.CheckboxIndeterminate />}
            indeterminate={isIndeterminate ?? false}
            checked={selectedAll}
            onChange={onChangeAll}
            className='upload-line-checkbox'
          />
        </div>

        <div className='column'>Date</div>
        <div className='column'>Description</div>
        <div className='column'>Deposit</div>
        <div className='column'>Withdrawal</div>
      </div>

      <div className='line-container'>
        <AutoSizer>
          {({ height, width }: { height: number, width: number }) => {
            return (
              <FixedSizeList
                height={height}
                itemCount={values.length}
                itemSize={40}
                layout='vertical'
                width={width}
              >
                {({ index, style }) => {
                  const item = values[index]

                  return (
                    <LineItem
                      style={style}
                      key={`key_${item.description?.rawValue ?? ''}_${item.deposit?.rawValue ?? ''}_${index}`}
                      onChange={(e) => setFieldValue(`[${index}].checked`, e.target.checked)}
                      {...item}
                    />
                  )
                }}
              </FixedSizeList>
            )
          }}
        </AutoSizer>
      </div>
    </div>
  )
}

export default UploadedLines
