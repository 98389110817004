import {
  type ParseBankStatementsDto,
  type ParseBankStatementsRequestDto,
  type AccountListItem,
  type EntityListItem,
  type BankStatementTransaction,
  type ControlListItem,
  type ListPayload,
  type ListResponse,
  type LineEntryReconciliationDto,
  type TransactionLineVendorDto
} from 'api/types'
import { BaseListItem } from 'types/index'
import type { UseFormReturn } from '@carfluent/common'

export enum BankStatementTransactionClearMethodId {
  Categorize = 0,
  Link = 1,
  Pay = 2,
  Receive = 3,
}

export interface UploadedBankStatementWithResults extends ParseBankStatementsRequestDto {
  parsingResults: ParseBankStatementsDto
}

export interface ReconciliationInfo {
  bankStatements: BankStatementInfo[]
  clearedAccounts: BankStatementAccountInfo[]
  hasBankingLines: boolean
  hasLineWithCompletedReconciliation: boolean
  isAutoLineInBanking: boolean
  reconciledAccounts: BankStatementAccountInfo[]
}

export interface BankStatementInfo {
  accountName: string
  change: number
  clearMethodId: number | null
  date: Date | null
  description: string
  id: number
}

export interface BankStatementAccountInfo {
  change: number
  id: number
  name: string
  reconciledOnDate: Date | null
}

export interface TransactionLineControl extends ControlListItem {
  id: number
  name: string
}

export interface TransactionLineRow {
  account: AccountListItem | null
  credits: number
  control: TransactionLineControl | null
  dealId?: number | null
  debits: number
  entity: EntityListItem | null
  id?: number
  isSystemCreated?: boolean
  notes: string
  reconciliationId?: string | null // AZ-TODO: this is some other reconciliation, from Open Balances
  remover?: never // column with "remove row" button
  disabled?: boolean
  bankStatementTransaction?: BankStatementTransaction | null
  lineEntryReconciliation?: LineEntryReconciliationDto | null
}

export interface ReconciliationFormData {
  beginningBalance: number | null
  endingBalance: number | null
  endDate: Date | null
}

export type ItemsGetter<T> = (payload: ListPayload) => Promise<ListResponse<T>>

export interface TransactionFormData {
  accountId: number | null
  amount: number | null
  checkNumber: number | string | null
  costInventoryId: number | null
  customerId: number | null
  dateTime: Date | null
  description: string
  dueDate: Date | null
  endDate: Date | null
  isLocked: boolean
  isVehiclePayment: boolean
  isVehicleCost: boolean
  lastPrintDate: Date | null
  memo: string | null
  receivableAccount: AccountListItem | null
  receivableControl: ControlListItem | null
  receivableEntity: EntityListItem | null
  receivableLineId: number | null
  receivableVendor: TransactionLineVendorDto| null
  recurringInterval: BaseListItem | null
  recurringTransactionTemplateId: number | null
  referenceNumber: string | null
  startDate: Date | null
  transactionTypeId: number | null
  vendorId: number | null
}

export type TransactionFormErrors = Record<keyof TransactionFormData, string | null>

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type TransactionFormErrTouchShortcuts = {
  receivableAccount: true
  receivableControl: true
  receivableEntity: true
  receivableVendor: true
  recurringInterval: true
}

export type UseTransactionFormReturn = UseFormReturn<TransactionFormData, TransactionFormErrTouchShortcuts>
