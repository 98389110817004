import { type Dispatch, type FC, type SetStateAction } from 'react'
import { Modal, Loader } from '@carfluent/common'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import { type EntityId } from 'api/types'
import { getFormIdsByUniqIds } from 'utils/deals/getFormId'
import FormsTable from 'components/deals/FormsTable'

import SentForSignInForm from './components/SentForSignInForm'
import EnvelopeSignUrlModal from './components/EnvelopeSignUrlModal'
import EnvelopRow from './components/EnvelopeRow'
import type { DealFormState } from './hooks/types'
import { useDealFormsTab } from './hooks'
import CLASS_NAME, { CONFIRM_MODAL_CLASS_NAME } from './styles'

interface DealFormsTabProps {
  state: DealFormState
  setState: Dispatch<SetStateAction<DealFormState>>
  dealId: EntityId
  email: string | null
  coBuyerEmail: string | null
  make: string
  model: string
  year: number | null
}

const DealFormsTab: FC<DealFormsTabProps> = ({
  dealId,
  state,
  setState,
  ...otherProps
}) => {
  const {
    // Form table
    items,
    checkedItems,
    onDeleteItem,
    onAdd,
    onChange,
    setCheckedItems,
    manageFormBannerProps,
    loadingItemDelete,
    onFormView,
    loadingViewIds,

    // Envelop table
    envelopItems,
    isDeleteAllowed,
    onCloseSignModal,
    isSignModalOpen,
    updateEnvelops,
    signEnvelopUrlId,
    isLoadingEnvelop,
    onSignUrl,
    envelopItemProps,

    // Confirm envelop void
    voidEnvelopId,
    setVoidEnvelopId,
    isVoiding,
    onVoidEnvelope,

    // Confirm envelop resend
    isResending,
    resendEnvelopId,
    setResendEnvelopId,
    onResendEnvelope,
    onCloseSignUrlModal
  } = useDealFormsTab({ dealId, state, setState })

  return (
    <div className={CLASS_NAME}>
      <div className='form-pack-content'>
        <div className='contract-sent-table'>
          <div className='form-pack-table-wrapper'>
            <div className='form-pack-table-wrapper-header'>
              Digital contracts sent
            </div>

            {isLoadingEnvelop && (
              <div className='envelop-loader-wrapper'><Loader /></div>
            )}

            {!isLoadingEnvelop && (
              <div className='form-pack-table-body'>
                {envelopItems.length > 0
                  ? (
                    <>
                      {envelopItems.map((item) => (
                        <EnvelopRow key={item.id} {...item} {...envelopItemProps} />
                      ))}
                    </>
                    )
                  : <div className='empty-list'>No digital contracts yet</div>}
              </div>
            )}
          </div>
        </div>

        <h6 className='manage-form-title'>Manage forms</h6>

        <FormsTable
          items={items}
          onChange={onChange}
          onDelete={onDeleteItem}
          onAdd={onAdd}
          isCheckAvailable
          isDeleteAllowed={isDeleteAllowed}
          checkedItems={checkedItems}
          onChangeCheckedItems={setCheckedItems}
          manageFormBannerProps={manageFormBannerProps}
          loadingItemDelete={loadingItemDelete}
          onView={onFormView}
          loadingViewIds={loadingViewIds}
        />
      </div>

      <SentForSignInForm
        isOpen={isSignModalOpen}
        onClose={onCloseSignModal}
        dealId={dealId}
        selectedForms={getFormIdsByUniqIds(items, checkedItems)}
        onSubmit={updateEnvelops}
        {...otherProps}
      />

      <Modal
        isOpen={voidEnvelopId != null}
        onClose={() => setVoidEnvelopId(null)}
        title='Void pack?'
        className={CONFIRM_MODAL_CLASS_NAME}
        renderFooterActions={(
          <CancelSubmitActionsFooter
            isLoading={isVoiding}
            onSubmit={onVoidEnvelope}
            onClose={() => setVoidEnvelopId(null)}
            submitTitle='YES, VOID'
          />
        )}
      >
        Please note that this step cannot be undone and will invalidate the forms, revoking access to them.
      </Modal>

      <Modal
        isOpen={resendEnvelopId != null}
        onClose={() => setResendEnvelopId(null)}
        title='Resend form pack?'
        className={CONFIRM_MODAL_CLASS_NAME}
        renderFooterActions={(
          <CancelSubmitActionsFooter
            isLoading={isResending}
            onSubmit={onResendEnvelope}
            onClose={() => setResendEnvelopId(null)}
            submitTitle='RESEND'
          />
        )}
      >
        New email will be send to the Customer. The old link expires.
      </Modal>

      <EnvelopeSignUrlModal
        dealId={dealId}
        envelopeSignUrlId={signEnvelopUrlId}
        onClose={onCloseSignUrlModal}
        onSigned={onSignUrl}
      />
    </div>
  )
}

export default DealFormsTab
