import type { BalanceSheetReport, BalanceSheetAccount, AccountCategories } from 'api/types/accounting.types'
import { ReportAccountTypes } from 'api/types/accounting.types'
import type { RowProps } from 'components/reports/ReportsBSPLTable/components/Row'
import { RowVariants } from 'components/reports/ReportsBSPLTable/components/Row'
import { getBalanceValue } from 'pages/reports/ReportsList/utils'
import { containsTruthy } from 'utils/general'

import { GROUP_TITLES } from './constants'

interface TotalSubtitleProps {
  accountType: ReportAccountTypes
  accountCategory: string
}

interface DataProps {
  rows: RowProps[]
  totalRows: RowProps[]
  totalSum: number
}

const getTotalBalanceValue = (accCategoryGroups: AccountCategories[], totalBalance: number, emptyValue: string): string => {
  const hasDataToRender = containsTruthy(accCategoryGroups) || totalBalance !== 0
  return hasDataToRender ? getBalanceValue(totalBalance) : emptyValue
}

const getTotalRows = (accTypeGroups: BalanceSheetAccount[], totalRows: RowProps[]): RowProps[] | [] => {
  return accTypeGroups.every(item => !containsTruthy(item.accountCategoryGroups)) ? [] : totalRows
}

const getCategoryTotal = ({
  accountType,
  accountCategory
}: TotalSubtitleProps): string =>
  `Total ${accountCategory} ${GROUP_TITLES[accountType].toLowerCase()}`

export const parseData = ({ accountTypeGroups, netIncome }: BalanceSheetReport): RowProps[] => {
  const { rows, totalRows } = accountTypeGroups.reduce((res: DataProps, {
    accountType,
    accountCategoryGroups,
    totalBalance
  }, i) => {
    res.rows = [
      ...res.rows,
      ...(i !== 0 ? [{ variant: RowVariants.Empty, name: 'title-row-divider' }] : []),
      {
        variant: RowVariants.Title,
        value: 'Balance',
        text: GROUP_TITLES[accountType]
      },
      { variant: RowVariants.Empty },
      ...(
        containsTruthy(accountCategoryGroups)
          ? accountCategoryGroups.reduce((res: RowProps[], {
            accountCategory,
            accountItems,
            totalBalance
          }) => {
            return [
              ...res,
              ...(
                accountCategory != null
                  ? [{ variant: RowVariants.Subtitle, text: accountCategory }]
                  : []
              ),
              ...accountItems.map(({
                accountName,
                accountNumber,
                amount
              }) => ({
                variant: RowVariants.Info,
                value: getBalanceValue(amount),
                text: `${accountNumber} ${accountName}`
              })),
              ...(
                accountCategory != null
                  ? [
                      {
                        variant: RowVariants.Subtitle,
                        value: getTotalBalanceValue(accountCategoryGroups, totalBalance, '$0.00'),
                        text: getCategoryTotal({ accountType, accountCategory })
                      }
                    ]
                  : []
              ),
              { variant: RowVariants.Empty }
            ]
          }, [])
          : []
      )
    ]

    if (accountType === ReportAccountTypes.Equity) {
      res.rows.push(
        {
          variant: RowVariants.Subtitle,
          value: getBalanceValue(netIncome),
          text: 'Net Income'
        }
      )
    }

    /**
     * Big "Total: ..." header in the end of each AccountType's group ("Assets", "Equity" etc.)
     */
    res.rows.push(
      {
        variant: RowVariants.Total,
        value: getTotalBalanceValue(accountCategoryGroups, totalBalance, ''),
        text: `Total ${GROUP_TITLES[accountType]}`
      }
    )

    /**
     * Section with "Total: ..." in the end of report.
     */
    res.totalRows = [
      ...res.totalRows,
      {
        variant: RowVariants.Subtitle,
        text: `Total ${GROUP_TITLES[accountType]}`,
        value: getTotalBalanceValue(accountCategoryGroups, totalBalance, ''),
        name: 'total-row-item'
      }
    ]

    res.totalSum = res.totalSum + totalBalance

    return res
  }, { rows: [], totalRows: [], totalSum: 0 })

  return [
    ...rows,
    {
      variant: RowVariants.Empty,
      name: 'total-block-divider'
    },
    ...(getTotalRows(accountTypeGroups, totalRows))
  ]
}
