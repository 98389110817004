import { useMemo, type FC } from 'react'
import { Link } from 'react-router-dom'
import { type TableRowProps, cnx, Banner, Loader } from '@carfluent/common'
import { TableRow as MuiTableRow, TableCell } from '@material-ui/core'
import { flexRender } from '@tanstack/react-table'

import { Routes } from 'constants/route_helper'
import BankStatementMatches from 'components/accounting/BankStatementMatches'
import RadioGroup from 'components/common/RadioGroup'
import RadioLabelControl from 'components/common/RadioLabelControl'
import IconSVGLock from 'components/inlineImages/IconSVGLock'

import FormView from './components/FormView'
import useRowForReview from './hook'
import { BASE_CLASS_NAME, HOVER_CLASS_NAME } from './styles'
import {
  type BankStatementRowData,
  type RowForReviewHOCProps,
  type UseRowForReviewProps,
  type RowForReviewState,
  type ViewTransactionResult,
  ReviewCategory
} from './hook/types'

export type { RowForReviewState, BankStatementRowData, ViewTransactionResult }
export type RowForReviewProps<V extends BankStatementRowData> = UseRowForReviewProps<V>

export function RowForReview <V extends BankStatementRowData> (props: RowForReviewProps<V>): JSX.Element {
  const { classes, row } = props

  const {
    isBannerErrorVisible,
    isExpanded,
    isExcluded,
    isLoading,
    hasMatches,
    onChangeReviewCategory,
    onClearMethodClick,
    onRowClick,
    onViewTransaction,
    reviewCategory,
    ...formFieldsProps
  } = useRowForReview(props)
  const tableRowExpanderClasses = useMemo(() => ({
    root: cnx(BASE_CLASS_NAME, 'cf-table-row', 'row-expander', isExpanded && 'is-expanded', classes?.row),
    hover: cnx(HOVER_CLASS_NAME, 'cf-table-row-hover')
  }), [isExpanded, classes?.row])

  const tableRowExpandableClasses = useMemo(() => ({
    root: cnx(BASE_CLASS_NAME, 'cf-table-row', 'row-expandable', isExpanded && 'is-expanded', classes?.row),
    hover: cnx(HOVER_CLASS_NAME, 'row-expandable')
  }), [isExpanded, classes?.row])

  // ========================================== //

  if (isExcluded) {
    return <></>
  }

  return (
    <>
      <MuiTableRow
        classes={tableRowExpanderClasses}
        data-id={row.id}
        hover
        key={`${row.id}_expander`}
        onClick={onRowClick}
      >
        {row.getVisibleCells().map(cell => {
          return (
            <TableCell
              className={cnx('cf-table-cell', classes?.cell)}
              key={cell.id}
              width={cell.column.getSize()}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          )
        })}
      </MuiTableRow>

      {isExpanded && (
        <MuiTableRow
          classes={tableRowExpandableClasses}
          key={`${row.id}_expandable`}
          hover
        >
          <TableCell
            className={cnx('cf-table-cell', classes?.cell)}
            width='100%'
            colSpan={6}
          >
            <div className={cnx('row-expandable-form', isBannerErrorVisible && 'with-banner')}>
              {isLoading && <Loader className='cf-section-loader' size='medium' />}

              {isBannerErrorVisible && (
                <Banner
                  className='banner-error'
                  endAdornment={<Link to={Routes.AccountingGeneralSettings}>Accounting settings</Link>}
                  isOpen
                  message='This date is locked in accounting. Unlock it in accounting settings if needed.'
                  startAdornment={<IconSVGLock color='#B00020' width={24} height={24} opacity={1} />}
                  type='error'
                />
              )}

              <div className='form-fields-line'>
                <RadioGroup
                  className='categories-radio-group'
                  id='categories'
                  onChange={onChangeReviewCategory}
                  value={reviewCategory}
                >
                  <RadioLabelControl value={ReviewCategory.Categorize} label='Categorize' />
                  <RadioLabelControl value={ReviewCategory.FindMatch} label='Find match' />
                  <RadioLabelControl value={ReviewCategory.RecordAsTransfer} label='Record as transfer' />

                  {!formFieldsProps.isDeposit && (
                    <RadioLabelControl value={ReviewCategory.RecordAsCreditCard} label='Record as credit card payment' />
                  )}
                </RadioGroup>
              </div>

              {(reviewCategory === ReviewCategory.FindMatch) && (
                <BankStatementMatches
                  className={cnx(formFieldsProps.isDeposit && 'deposit-matches')}
                  mode='BS'
                  rows={row.original.matches}
                  onClearMethodClick={onClearMethodClick}
                  onViewTransaction={onViewTransaction}
                />
              )}

              <FormView {...formFieldsProps} reviewCategory={reviewCategory} />
            </div>
          </TableCell>
        </MuiTableRow>
      )}

    </>
  )
}

function RowForReviewHOC <V extends BankStatementRowData> (hocProps: RowForReviewHOCProps): FC<TableRowProps<V>> {
  return (props: TableRowProps<V>) => <RowForReview {...hocProps} {...props} />
}

export default RowForReviewHOC
