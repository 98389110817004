import type { FC, ReactNode } from 'react'
import { cn } from '@carfluent/common'

import CLASS_NAME from './styles'

export interface InputsRowLayoutProps {
  className?: string
  children?: ReactNode | ReactNode[]
  hasBottomMargin?: boolean
}

const InputsRowLayout: FC<InputsRowLayoutProps> = ({
  children,
  className,
  hasBottomMargin = true
}) => {
  return (
    <div className={cn(CLASS_NAME, hasBottomMargin && 'cf-bottom-margin', className)}>
      {children}
    </div>
  )
}

export default InputsRowLayout
