import { useCallback, useState } from 'react'

import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { type ResponseOnAutoTaskFormData, TaskCompleteOptions, TaskMode } from 'pages/crm/LeadDetailsView/hook/types'
import { Messages } from 'pages/crm/LeadDetailsView/components/LeadDetailsTasks/components/TaskComplete/hook/constants'

export interface CompleteTaskFormProps {
  taskMode: TaskMode | null
  taskId: number
  onViewEmailClick: (taskId: number) => Promise<void>
  onSubmit?: (values: ResponseOnAutoTaskFormData, taskId: number) => Promise<void>
  onCloseTask: (taskId: number | null, taskMode?: TaskMode) => void
}

export interface UseEmailReceivedTaskReturn {
  isLoading: boolean
  onRespondedSubmit: () => Promise<void>
  onNoResponseNeededSubmit: () => Promise<void>
  onViewEmailClick: () => Promise<void>
}

const useEmailReceivedTask = ({
  taskId,
  taskMode,
  onCloseTask,
  onViewEmailClick: _onViewEmailClick,
  onSubmit: _onSubmit
}: CompleteTaskFormProps): UseEmailReceivedTaskReturn => {
  const { showSuccess } = useCustomSnackbar()
  const [isLoading, setLoading] = useState<boolean>(false)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onSubmit = useCallback(async (taskCompleteOptionId: TaskCompleteOptions): Promise<void> => {
    try {
      setLoading(true)

      await _onSubmit?.({ taskCompleteOptionId }, taskId)
      onCloseTask?.(taskId, taskMode as TaskMode)
      showSuccess(Messages.SuccessTaskComplete)
    } catch (error) {
      // DO NOTHING
    } finally {
      setLoading(false)
    }
  }, [
    _onSubmit, taskId, onCloseTask,
    taskMode, showSuccess
  ])

  const onRespondedSubmit = useCallback(async (): Promise<void> => {
    await onSubmit(TaskCompleteOptions.Responded)
  }, [onSubmit])

  const onNoResponseNeededSubmit = useCallback(async (): Promise<void> => {
    await onSubmit(TaskCompleteOptions.NoResponseNeeded)
  }, [onSubmit])

  const onViewEmailClick = useCallback(async (): Promise<void> => {
    await _onViewEmailClick(taskId)
  }, [_onViewEmailClick, taskId])

  // ========================================== //

  return {
    isLoading,
    onRespondedSubmit,
    onNoResponseNeededSubmit,
    onViewEmailClick
  }
}

export default useEmailReceivedTask
