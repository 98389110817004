import type { FC, MouseEventHandler } from 'react'

interface StarIconProps {
  isActive?: boolean
  onClick?: MouseEventHandler<SVGSVGElement>
  className?: string
  activeColor?: string
}

const StarIcon: FC<StarIconProps> = ({ isActive = false, onClick, className, activeColor }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <g clipPath='url(#clip0_60782_164679)'>
        <path
          d='M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z'
          fill={isActive ? (activeColor ?? '#FFCA28') : '#000'}
          fillOpacity={isActive ? '1' : '0.3'}
        />
      </g>
      <defs>
        <clipPath id='clip0_60782_164679'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>

  )
}

export default StarIcon
