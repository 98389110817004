import type { FC } from 'react'
import { PRESET_LABELS } from '@carfluent/common'

import ReportLayout from 'components/reports/ReportLayout'
import BackToTop from 'components/common/BackToTop'
import FiltersPanel, { type FilterItem } from 'components/common/FiltersPanel'

import { asyncNoop } from 'constants/constants'

import Table from 'components/common/Table'
import GroupContent from './components/GroupContent'
import StateFilter from './components/Filters/StatesFilter'

import useReportSalesTax from './hook'
import {
  LOADING_ROWS,
  GROUP_ROW_FIXED_COL_SPAN
} from './hook/constants'

import { ROOT_CLASS_NAME, CLASS_NAME } from './styles'

const ReportSalesTax: FC = () => {
  const {
    isLoading,
    rows,
    sorting,
    search,
    presets,
    emptyTableMessage,
    states,
    columnDefinitions,
    onSearch,
    groupBy,
    onSearchChange,
    onExportExcel,
    onDateFilterChange,
    onStateFilterChange,
    onSortingChange
  } = useReportSalesTax()

  const searchProps = {
    value: search ?? '',
    onSearch,
    disabled: isLoading,
    isLoading,
    onChange: onSearchChange,
    placeholder: 'Make, model, deal ID, stock #'
  }

  const dateFilters: FilterItem<'date'> = {
    filterType: 'date',
    componentProps: {
      presets,
      onFilterChange: onDateFilterChange,
      defaultPresetName: PRESET_LABELS.PREVIOUS_MONTH
    }
  }

  const stateFilter: FilterItem<'custom'> = {
    filterType: 'custom',
    componentProps: {
      render: () => <StateFilter options={states} setFilters={onStateFilterChange} />
    }
  }

  return (
    <ReportLayout
      title='Sales tax report'
      rootClassName={ROOT_CLASS_NAME}
      size='md'
      className=''
      withChildOutsideContainer
      onExportExcel={onExportExcel}
      onChangeDatesFilter={asyncNoop}
      isDateFilterEnabled={false}
    >
      <div className={CLASS_NAME}>
        <FiltersPanel
          componentBackground='gray'
          searchProps={searchProps}
          filterProps={{ filters: [dateFilters, stateFilter] }}
        />

        <Table
          data={rows}
          withFixedCell
          sorting={sorting}
          groupBy={groupBy}
          isLoading={isLoading}
          loadingRows={LOADING_ROWS}
          renderGroup={GroupContent}
          columns={columnDefinitions}
          onSortingChange={onSortingChange}
          emptyTableMessage={emptyTableMessage}
          groupColSpan={GROUP_ROW_FIXED_COL_SPAN}
          hideFooter={!(rows.length > 0)}
        />

        <BackToTop />
      </div>
    </ReportLayout>

  )
}

export default ReportSalesTax
