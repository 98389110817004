import { useEffect, useState } from 'react'

enum AnimationDelay {
  Open = 100,
  Close = 500
}

interface UseAnimatedToggleReturn {
  isOpen: boolean
  isAnimatedOpen: boolean
}

export const useAnimatedToggle = (open: boolean): UseAnimatedToggleReturn => {
  const [isOpen, setOpen] = useState(false)
  const [isAnimatedOpen, setIsAnimatedOpen] = useState(false)

  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (open) {
      setOpen(true)

      timeout = setTimeout(() => {
        setIsAnimatedOpen(true)
      }, AnimationDelay.Open)
    } else {
      setIsAnimatedOpen(false)

      timeout = setTimeout(() => {
        setOpen(false)
      }, AnimationDelay.Close)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [open])

  return {
    isOpen,
    isAnimatedOpen
  }
}
