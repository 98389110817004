import { css } from '@emotion/css'

export default css`
  .no-alert-item {
    width: 100%;
    height: 16px!important;
  }

  .alert-item {
    width: 20px;
    height: 16px!important;
  }
`
