import { css } from '@emotion/css'

export default css(`
  &&.cf-modal-container {
    transition: none;
    .cf-modal-content-scroll-wrapper {
      padding-bottom: 20px;
      z-index: 1199;
    }
    .cf-modal-root {
      padding: 0;

      .cf-modal-title {
        padding: 20px 24px 8px;
      }

      .cf-modal-content {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        min-height: unset;
        max-height: 508px;
        overflow-y: auto;
        padding: 0;
        padding-top: 16px;
        margin: 0px 24px 8px;
      }
    }
  }
`)
