/**
 * DEPRECATED! USE Dropdown from `@carfluent/common` instead
 */
import type {
  ComponentType, FocusEvent, HTMLAttributes,
  ReactNode
} from 'react'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { Dropdown, UI, cn } from '@carfluent/common'
import { observer } from 'mobx-react-lite'

import type {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason
} from '@material-ui/lab'

import { useField } from '../../hook'
import type { BaseFormField } from '../../types'
import useStyles from './styles'
import useFormHelperStyles from '../../styles'

export interface DropdownProps<T, V> extends Omit<BaseFormField<V>, 'value' | 'errors' | 'error' | 'touched'> {
  inputValue?: string
  label?: string
  name?: string
  className?: string
  classes?: { wrapper?: string, option?: string, popper?: string }
  dataTestId?: string
  disabled?: boolean
  disableClearable?: boolean
  isLoading?: boolean
  isDropdownOpen?: boolean
  mode?: UI.DropdownMode
  bottomThreshold?: number
  maxLength?: number
  blurOnSelect?: boolean
  isAutocompleteBlurHandlerSet?: boolean
  onBlur?: (evt: FocusEvent<HTMLDivElement>) => void
  onScroll?: () => void
  onChange?: (id: string, value: V | null, reason: AutocompleteChangeReason) => void
  onInputChange?: (id: string, value: string, reason: AutocompleteInputChangeReason) => void
  getOptionLabel?: (option: T | null) => string
  getOptionDisabled?: (option: T) => boolean
  getOptionValue?: (option: T | null) => V
  isOptionOfValue?: (option: T | null, value: V) => boolean
  getOptionSelected?: (option: T | null, value: T) => boolean
  filterOptions?: (option: T[]) => T[]
  ListboxComponent?: ComponentType<HTMLAttributes<HTMLElement>>
  NoOptionsComponent?: ComponentType<HTMLAttributes<HTMLElement>>
  DropdownIconComponent?: ComponentType<HTMLAttributes<HTMLElement>> | null
  renderOption?: (option: T) => ReactNode
  options: T[]
  value?: V | null
  errors?: V | null
  error?: string | null
  touched?: boolean
  startAdornment?: React.ReactNode
  isExtendedListbox?: boolean
}

function FormDropdown<T, V = string | number> (
  props: DropdownProps<T, V>
): JSX.Element {
  const {
    id,
    inputValue,
    label,
    className,
    classes,
    disabled,
    dataTestId,
    options,
    disableClearable,
    blurOnSelect,
    isAutocompleteBlurHandlerSet,
    mode,
    getOptionLabel,
    getOptionSelected,
    getOptionValue = DEFAULT_GET_OPTION_VALUE,
    isOptionOfValue = (opt, value) => getOptionValue(opt) === value,
    filterOptions,
    isLoading,
    isDropdownOpen,
    bottomThreshold,
    maxLength,
    onScroll,
    onBlur,
    onChange,
    onInputChange,
    getOptionDisabled,
    ListboxComponent,
    NoOptionsComponent,
    DropdownIconComponent,
    renderOption,
    startAdornment,
    isExtendedListbox = false
  } = props

  const styles = useStyles()
  const errorStyle = useFormHelperStyles()

  const {
    value: originalValue,
    error,
    touched,
    isErrorVisible
  } = useField<V | null>({
    id: props.id,
    touched: props.touched,
    // @ts-expect-error
    errors: props.error ?? props.errors,
    value: props.value
  })

  const resolvedClasses = useMemo(() => ({
    ...classes,
    error: errorStyle,
    wrapper: cn(styles.wrapper, className, classes?.wrapper)
  }), [classes, className])

  const [resolvedValue, setResolvedValue] = useState<T | null>(null)

  const handleChange = useCallback((_, value, reason) => {
    onChange?.(id, getOptionValue<T, V>(value), reason)
  }, [id, onChange, getOptionValue])

  const handleInputChange = useCallback((_, value, reason) => {
    onInputChange?.(id, value, reason)
  }, [id, onInputChange])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const resolvedOptions = filterOptions?.(options) ?? options
    const value = (originalValue == null)
      ? null
      : resolvedOptions.find(item => isOptionOfValue(item, originalValue)) ?? null

    setResolvedValue(value)
  }, [options, originalValue, isOptionOfValue, filterOptions])

  // ========================================== //

  return (
    <Dropdown<T>
      dataTestId={dataTestId}
      id={id}
      inputValue={inputValue}
      isDropdownOpen={isDropdownOpen}
      label={label}
      options={options}
      error={error}
      touched={touched}
      showError={isErrorVisible}
      disabled={disabled}
      disableClearable={disableClearable}
      blurOnSelect={blurOnSelect}
      isAutocompleteBlurHandlerSet={isAutocompleteBlurHandlerSet}
      classes={resolvedClasses}
      mode={mode}
      loading={isLoading}
      bottomThreshold={bottomThreshold}
      maxLength={maxLength}
      onScroll={onScroll}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      getOptionDisabled={getOptionDisabled}
      filterOptions={filterOptions}
      onBlur={onBlur}
      onChange={handleChange}
      onInputChange={handleInputChange}
      value={resolvedValue}
      ListboxComponent={ListboxComponent}
      NoOptionsComponent={NoOptionsComponent}
      DropdownIconComponent={DropdownIconComponent}
      renderOption={renderOption}
      startAdornment={startAdornment}
      isExtendedListbox={isExtendedListbox}
    />
  )
}

export default observer(FormDropdown)

function DEFAULT_GET_OPTION_VALUE<T, V> (option: T): V | null {
  return ((option as any)?.id as V) ?? null
}
