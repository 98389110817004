import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useLoader } from '@carfluent/common'

import queryString from 'query-string'

import VehiclesApiProvider from 'api/vehicles.api'
import { type VehiclesDashboardDto } from 'api/types/responses'
import useAsyncEffect from 'hooks/useAsyncEffect'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

import { type UseDashboardInventoryReturn } from './types'

const useDashboardInventory = (): UseDashboardInventoryReturn => {
  const { search } = useLocation()
  const { isLoading: _isLoading } = queryString.parse(search)
  const { showAlert } = useCustomSnackbar()
  const { isLoading, startLoader, stopLoader } = useLoader()

  const [data, setData] =
    useState<VehiclesDashboardDto | null>(null)

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useAsyncEffect(async () => {
    try {
      startLoader()
      const resp = await VehiclesApiProvider.getVehicleDashboard()
      setData(resp)
    } catch (err) {
      showAlert(err)
    } finally {
      stopLoader()
    }
  }, [
    showAlert,
    startLoader,
    stopLoader
  ])

  // ========================================== //

  return {
    data,
    isLoading: isLoading || (_isLoading === 'true')
  }
}

export default useDashboardInventory
