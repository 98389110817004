import { useEffect } from 'react'

export type AsyncFn = (...args: any[]) => Promise<any>

function useAsyncEffect (runEffect: AsyncFn, deps: any[]): void
function useAsyncEffect (runEffect: AsyncFn, cleanup: () => void, deps: any[]): void
function useAsyncEffect (runEffect: AsyncFn, cleanup: any, deps: any[] = []): void {
  let resolvedDeps: any[] = []
  let resolvedCleanup = (): void => {}

  if (Array.isArray(cleanup)) { // case with two arguments
    resolvedDeps = cleanup
  } else {
    resolvedCleanup = cleanup
    resolvedDeps = deps
  }

  useEffect(() => {
    void runEffect()

    return resolvedCleanup
  }, resolvedDeps)
}

export default useAsyncEffect
