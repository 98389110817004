import { serializers as S } from '@carfluent/common'
import formatISO from 'date-fns/formatISO'
import get from 'lodash-es/get'

import type {
  EmploymentDetails as APIEmploymentDetails,
  PreviousEmploymentDetails as APIPreviousEmploymentDetails,
  CurrentAddressDetails as APICurrentAddressDetails,
  PreviousAddressDetails as APIPreviousAddressDetails,
  TradeInDetails as APITradeInDetails,
  CoApplicantFinancialDetails as APICoApplicantFinancialDetails,
  CreditApplication,
  ApplicantFinancialDetailsRequestDto,
  CoverageDetails
} from 'api/types'

import { GET_DEFAULT_DEAL } from 'api/defaults'
import { isPersonalType } from 'utils/deals/workflowTypes'

import { convertFromUTC } from 'utils/parse_date'
import { onlyNumbersAndDot } from 'utils/format_number'
import {
  getTypedOrNull, isEmploymentFieldVisible, isPreviousEmploymentOrHousingNeeded, hasOtherIncomeOption
} from 'utils/creditApplication'
import { isFalsy } from 'utils/general'
import { isEmptyString } from 'utils/validation'
import { emptyAddress } from 'utils/address'

import type {
  EmploymentDetails,
  CreditApplicationFormData,
  CurrentAddressDetails,
  PreviousAddressDetails,
  AdditionalCostsObject
} from '../../../types'
import { CoverageDetailsSection } from 'types'

type PropsToExclude = 'salesperson'
| 'applicantFinancialDetails'
| 'selectedDealerProducts'
| 'additionalCosts'
| 'coApplicantFinancialDetails'
| 'tradeInDetails'
| 'isManualSalesTax'
| 'salesTax'
| 'salesTaxAmount'
| 'transportationCost'
| 'registrationTax'
| 'documentFee'
| 'coverageData'

export type SerializableCreditApplication =
  Pick<CreditApplication, keyof Omit<CreditApplicationFormData, PropsToExclude>> & {
    // dealFees: CreditApplication['dealFees']
    salespersonId: number | null
    coApplicantFinancialDetails: APICoApplicantFinancialDetails | null
    applicantFinancialDetails: ApplicantFinancialDetailsRequestDto
    tradeInDetails: APITradeInDetails | null
  }

const DEFAULT_DEAL = GET_DEFAULT_DEAL()

/**
 * Main use case: get correct value for a number field.
 * Explanation: as we need to allow number field to be string for inputs
 * we get empty strings even for number fields.
 *
 * Though for other literal types it should also return correct values.
 *
 * NOTE:
 * in the places where we use getValueOrDefault it is intentional that we pass field keys without nesting
 * The same prop has the same name and the same type in different creditAppForm object sections.
 * This is logical and consistent that is why nested paths to props are not used.
 */

const getValueOrDefault = <T>(val: T, key: string): T => {
  return (val == null || (typeof val === 'string' && val === ''))
    ? get(DEFAULT_DEAL, key)
    : val
}

export const serializeCreditApplicationData = (
  formData: CreditApplicationFormData,
  /**
   * it is easier to work with rowVersion field outside formData
   * it controls whether changes would be accepted by backend or 409 would be returned
   */
  rowVersion: string,
  isCoApplicantSectionVisible: boolean,
  isTradeInSectionVisible: boolean
): SerializableCreditApplication => {
  const {
    salesTax,
    salesTaxAmount,
    registrationTax,
    documentFee,
    transportationCost,
    customerDetails,
    applicantFinancialDetails,
    coApplicantFinancialDetails,
    tradeInDetails,
    financingCalculatorDetails,
    vehicleDetails,
    creditApplicationSubmittedDate,
    salesperson,
    executiveDetails,
    additionalCosts,
    dealFees,
    coverageData,
    coverageDetails,
    ...restData
  } = formData

  const isPersonal = isPersonalType(formData.workflowTypeId)

  const {
    customerBirthDate,
    addressData,
    customerPhoneNumber,
    customerFirstName,
    customerLastName,
    customerEmail,
    ...restCustomerDetails
  } = customerDetails

  const {
    birthDate: executiveBirthDate,
    addressData: executiveAddressData,
    ...restExecutiveDetails
  } = executiveDetails

  const {
    wholesaleValue,
    housingStatus,
    apartmentMoveInDate,
    driverLicenseNumber,
    stateOfIssue,
    apartmentPayment: currentApartmentPayment,
    currentEmploymentDetails: currentEmployment,
    previousEmploymentDetails: previousEmployment,
    previousAddressDetails: previousAddress,
    ...restApplicantFinancialDetails
  } = applicantFinancialDetails

  const {
    currentAddressDetails: coApplicantCurrentAddressDetails,
    previousAddressDetails: coApplicantPreviousAddressDetails,
    currentEmploymentDetails: coApplicantCurrentEmployment,
    previousEmploymentDetails: coApplicantPreviousEmployment,
    incomeOption: coApplicantIncomeOption,
    otherIncome: coApplicantOtherIncome,
    driverLicenseNumber: coApplicantDriverLicenseNumber,
    stateOfIssue: coApplicantStateOfIssue,
    ...coApplicantDetails
  } = coApplicantFinancialDetails

  const hasApplicantOtherIncomeSources = hasOtherIncomeOption(applicantFinancialDetails.incomeOption)
  const hasCoApplicantOtherIncomeSources = hasOtherIncomeOption(coApplicantIncomeOption)
  const hasCoApplicant = isCoApplicantSectionVisible

  const res: SerializableCreditApplication = {
    ...restData,
    salespersonId: salesperson?.id ?? null,
    rowVersion,
    creditApplicationSubmittedDate: getTypedOrNull(formatISO, convertFromUTC(creditApplicationSubmittedDate)),
    executiveDetails: {
      ...restExecutiveDetails,
      birthDate: S.serializeDate(executiveBirthDate),
      address: executiveAddressData?.address ?? '',
      city: executiveAddressData?.city ?? '',
      state: executiveAddressData?.state ?? '',
      zipCode: executiveAddressData?.zipCode ?? '',
      apt: executiveAddressData?.apt ?? null
    },
    customerDetails: {
      ...restCustomerDetails,
      customerFirstName: customerFirstName?.trim() ?? null,
      customerLastName: customerLastName?.trim() ?? null,
      customerEmail: customerEmail?.trim() ?? null,
      customerAddress: addressData?.address ?? null,
      customerCity: addressData?.city ?? null,
      customerState: addressData?.state ?? null,
      customerZipCode: addressData?.zipCode ?? null,
      customerApt: addressData?.apt ?? null,
      customerPhoneNumber: onlyNumbersAndDot(customerPhoneNumber),
      customerBirthDate: S.serializeDate(customerBirthDate)
    },
    tradeInDetails: isTradeInSectionVisible ? getTradeIn(tradeInDetails) : null,
    applicantFinancialDetails: {
      ...restApplicantFinancialDetails,
      hasCoApplicant,
      driverLicenseNumber: isEmptyString(driverLicenseNumber) ? null : driverLicenseNumber,
      stateOfIssue: isEmptyString(stateOfIssue) ? null : stateOfIssue,
      incomeOption: hasApplicantOtherIncomeSources ? applicantFinancialDetails.incomeOption : null,
      otherIncome: hasApplicantOtherIncomeSources
        ? getValueOrDefault(applicantFinancialDetails.otherIncome, 'otherIncome')
        : null,
      wholesaleSourceType: applicantFinancialDetails.wholesaleSourceType !== 0
        ? applicantFinancialDetails.wholesaleSourceType
        : null,
      wholesaleValue: getValueOrDefault(wholesaleValue, 'wholesaleValue'),
      creditApplicationConsentAccepted: true,
      applicationAgreementAccepted: true
    },
    coApplicantFinancialDetails: hasCoApplicant
      ? {
          ...coApplicantDetails,
          driverLicenseNumber: isEmptyString(coApplicantDriverLicenseNumber) ? null : coApplicantDriverLicenseNumber,
          stateOfIssue: isEmptyString(coApplicantStateOfIssue) ? null : coApplicantStateOfIssue,
          firstName: coApplicantDetails.firstName?.trim() ?? null,
          lastName: coApplicantDetails.lastName?.trim() ?? null,
          email: coApplicantDetails.email?.trim() ?? null,
          birthDate: S.serializeDate(coApplicantFinancialDetails.birthDate),
          currentEmploymentDetails: getCurrentEmployment(coApplicantCurrentEmployment),
          previousEmploymentDetails: getPreviousEmployment(
            coApplicantPreviousEmployment,
            coApplicantCurrentEmployment?.workingStartDate,
            coApplicantCurrentEmployment?.employmentStatus
          ),
          currentAddressDetails: getCurrentAddress(coApplicantCurrentAddressDetails),
          previousAddressDetails: getPreviousAddress(
            coApplicantPreviousAddressDetails,
            coApplicantCurrentAddressDetails?.apartmentMoveInDate
          ),
          hasOtherIncomeSources: hasCoApplicantOtherIncomeSources,
          incomeOption: hasCoApplicantOtherIncomeSources ? coApplicantIncomeOption : null,
          otherIncome: hasCoApplicantOtherIncomeSources
            ? getValueOrDefault(coApplicantOtherIncome, 'otherIncome')
            : null,
          creditApplicationConsentAccepted: !isFalsy(coApplicantDetails.id),
          applicationAgreementAccepted: !isFalsy(coApplicantDetails.id)
        }
      : null,
    financingCalculatorDetails: {
      ...financingCalculatorDetails,
      downPayment: getValueOrDefault(financingCalculatorDetails.downPayment, 'downPayment'),
      term: getValueOrDefault(financingCalculatorDetails.term, 'term')
    },
    vehicleDetails: {
      ...vehicleDetails,
      vehiclePrice: getValueOrDefault(vehicleDetails.vehiclePrice, 'vehiclePrice'),
      vehicleTrim: vehicleDetails.vehicleTrim === '' ? null : vehicleDetails.vehicleTrim
    },
    coverageDetails: getCoverageDetails(coverageDetails),
    dealFees: {
      ...dealFees,
      carDelivery: getValueOrDefault(transportationCost, 'transportationCost') ?? 0,
      registrationFee: getValueOrDefault(registrationTax, 'registrationTax'),
      dealerHandlingFee: getValueOrDefault(documentFee, 'documentFee'),
      ...getAdditionalCostsObject(formData)
    }
  }

  if (isPersonal) {
    res.applicantFinancialDetails = {
      ...res.applicantFinancialDetails,
      housingStatus,
      apartmentPayment: getValueOrDefault(currentApartmentPayment, 'apartmentPayment'),
      apartmentMoveInDate: S.serializeDate(apartmentMoveInDate),
      currentEmploymentDetails: getCurrentEmployment(currentEmployment),
      previousEmploymentDetails: getPreviousEmployment(
        previousEmployment,
        currentEmployment?.workingStartDate,
        currentEmployment?.employmentStatus
      ),
      previousAddressDetails: getPreviousAddress(previousAddress, apartmentMoveInDate),
      hasOtherIncomeSources: hasApplicantOtherIncomeSources
    }
  }

  return res
}

const getCurrentEmployment = (
  employmentDetails: EmploymentDetails | null
): APIEmploymentDetails | null => {
  const status = employmentDetails?.employmentStatus

  if (status == null) {
    return null
  }

  return getTypedOrNull(({ id, employmentStatus, ...otherProps }) => {
    return {
      id,
      employmentStatus,
      jobTitle: isEmploymentFieldVisible(status, 'jobTitle')
        ? otherProps.jobTitle
        : null,
      employerName: isEmploymentFieldVisible(status, 'employerName')
        ? otherProps.employerName
        : null,
      employerPhoneNumber: isEmploymentFieldVisible(status, 'employerPhoneNumber')
        ? otherProps.employerPhoneNumber
        : null,
      income: isEmploymentFieldVisible(status, 'income')
        ? getValueOrDefault(otherProps.income, 'income')
        : null,
      workingStartDate: isEmploymentFieldVisible(status, 'workingStartDate')
        ? S.serializeDate(otherProps.workingStartDate)
        : null
    }
  }, employmentDetails)
}

const getAdditionalCostsObject = (data: CreditApplicationFormData): Partial<AdditionalCostsObject> => {
  const { additionalCosts } = data
  const res: Partial<AdditionalCostsObject> = {}

  res.other1Description = additionalCosts[0]?.description ?? null
  res.other1 = additionalCosts[0]?.amount ?? null
  res.other2Description = additionalCosts[1]?.description ?? null
  res.other2 = additionalCosts[1]?.amount ?? null
  res.other3Description = additionalCosts[2]?.description ?? null
  res.other3 = additionalCosts[2]?.amount ?? null

  return res
}

const getPreviousEmployment = (
  data: EmploymentDetails | null,
  currentStartDate?: Date | null,
  currentEmploymentStatus?: number | null
): APIPreviousEmploymentDetails | null => {
  if (!isPreviousEmploymentOrHousingNeeded(currentStartDate, currentEmploymentStatus)) {
    return null
  }

  const employmentDetails = getCurrentEmployment(data)
  if ((employmentDetails == null) || (data == null)) {
    return null
  }

  return {
    ...employmentDetails,
    income: getValueOrDefault(employmentDetails.income, 'income'),
    workingEndDate: S.serializeDate(data.workingEndDate)
  }
}

const getCurrentAddress = (
  addressDetails: CurrentAddressDetails | null
): APICurrentAddressDetails | null => {
  return getTypedOrNull(data => {
    const { addressData, apartmentPayment, ...rest } = data
    const { addressLong, ...addrRest } = (addressData ?? emptyAddress)

    return {
      ...rest,
      ...addrRest,
      apartmentPayment: getValueOrDefault(apartmentPayment, 'apartmentPayment'),
      apartmentMoveInDate: S.serializeDate(data.apartmentMoveInDate)
    }
  }, addressDetails)
}

const getPreviousAddress = (
  addressDetails: PreviousAddressDetails | null,
  currentMoveIn?: Date | null
): APIPreviousAddressDetails | null => {
  if ((currentMoveIn == null) || !isPreviousEmploymentOrHousingNeeded(currentMoveIn)) {
    return null
  }

  return getTypedOrNull(data => {
    const { addressData, ...rest } = data
    const { addressLong, ...addrRest } = (addressData ?? emptyAddress)

    return {
      ...rest,
      ...addrRest,
      apartmentMoveInDate: S.serializeDate(data.apartmentMoveInDate),
      apartmentMoveOutDate: S.serializeDate(currentMoveIn)
    }
  }, addressDetails)
}

const getTradeIn = (data: APITradeInDetails): APITradeInDetails => {
  return {
    ...data,
    tradeInVehicleYear: (data.tradeInVehicleYear != null) ? parseInt(`${data.tradeInVehicleYear}`) : null,
    tradeInVehicleVIN: (data.tradeInVehicleVIN ?? '') === '' ? null : data.tradeInVehicleVIN,
    tradeInPayoff: (data.tradeInPayoff ?? '') === '' ? null : Number(data.tradeInPayoff)
  }
}

const getCoverageDetails = (items: CoverageDetailsSection[]): CoverageDetails[] => {
  return items.reduce((acc: CoverageDetails[], x) => {
    const coverage = x.forms[x.coverageType]

    if (coverage != null) {
      acc.push(coverage as CoverageDetails)
    }

    return acc
  }, [])
}
