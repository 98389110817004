import type { OptionRendererConfig } from '@carfluent/common/dist/UI/Dropdown/types'

import { type DictionaryItem } from 'api/types'

export const renderTemplateOption = (option: DictionaryItem): string => option.name

export const renderMultiOption = (value: DictionaryItem, config: OptionRendererConfig): JSX.Element => {
  const { isSelected, renderCheckbox } = config
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: 8, width: '24px', height: '24px' }}>{renderCheckbox(isSelected)}</div>
      <div>{value.name}</div>
    </div>
  )
}
