import { css } from '@emotion/css'

export default css(`
  flex: 1;
  gap: 0 8px;
  display: grid;
  grid-template-columns: 1fr;

  .purchase-accounts-skeleton {
    flex: 1;
    gap: 0 8px;
    height: 42px;
    display: grid;
    align-items: center;
    grid-template-columns: 24px 0.7fr;
  }
`)
