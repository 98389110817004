import { useContext, useEffect, useRef, useState } from 'react'

import { AccountingApiProvider } from 'api/accounting.api'
import { Routes } from 'constants/route_helper'
import useAsyncEffect from 'hooks/useAsyncEffect'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'
import useDummySubscriptions from 'hooks/useDummySubscriptions'
import GlobalUICTX from 'store/global_ui'
import AuthCTX from 'store/auth'
import SettingsCTX from 'store/settings'

const useAppLoading = (): boolean => {
  /**
   * This is used to disable conditional re-rendering of
   * all auth-dependant components.
   * We will render them only once, after `switchOn` execution.
   */
  const [isLoading, setIsLoading] = useState(true)
  const isInittedRef = useRef(false)
  const spinnerStartedRef = useRef(false) // This ref will help track if the spinner has been started.

  const { auth, switchOn, userRoles } = useContext(AuthCTX)
  const { startSpinner, stopSpinner } = useContext(GlobalUICTX)
  const { setAccountingRaw, dealersActionWithLoading } = useContext(SettingsCTX)

  useGoogleAnalytics()
  useDummySubscriptions()

  useAsyncEffect(async (): Promise<void> => {
    try {
      setIsLoading(true)
      startSpinner()
      await switchOn()
    } catch (err) {
      if (window.location.pathname !== Routes.NoDealerAndVin) {
        window.location.replace(Routes.NoDealerAndVin) // no `history` since we are not inside Router
      }
    } finally {
      stopSpinner()
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent): void => {
      if (event.key === 'access_token') {
        void dealersActionWithLoading()
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  /**
   * Loads global application settings each time after
   * successful re-login.
   */
  useAsyncEffect(async (): Promise<void> => {
    /**
     * We do not have any enforcement of API per roles.
     * So this is an edge case here because Settings are closed for BDC.
     *
     * DD-NOTE:
     * in general this says that we need a smart wrapper over actions where
     * we would be able to smartly ignore requests if they does not pass some filter
     *
     */
    if (!auth || userRoles.length === 0) {
      return
    }

    try {
      /**
       * Display the spinner only during the initial app mount.
       * This check ensures the spinner starts only once,
       * even if useEffect re-runs multiple times.
      */
      if (!spinnerStartedRef.current) {
        setIsLoading(true)
        startSpinner()
        spinnerStartedRef.current = true
      }

      const settings = await AccountingApiProvider.getSettings()

      setAccountingRaw(settings)
    } catch (err) {
      // pass silently
    } finally {
      // Ensure spinner stops only once after its start and during initial app load.
      if (!isInittedRef.current && spinnerStartedRef.current) {
        stopSpinner()
        isInittedRef.current = true
      }
      /**
       * AS-NOTE:
       *  React batches state update, and we have always isLoading false
       *  (for case when user role is BDC)
       */
      setTimeout(() => setIsLoading(false), 0)
    }
  }, [auth, userRoles])

  return isLoading
}

export default useAppLoading
