import { FC } from 'react'
import { FormDropdown, Loader, cnx } from '@carfluent/common'

import IconSVG from 'components/inlineImages'

import { AccountListItem } from 'api/types'
import getAccountOptionName from 'utils/accounting/getAccountOptinName'
import AccountingApiProvider from 'api/accounting.api'

import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

interface AccountFilterProps {
  account: AccountListItem | null
  setAccount: (account: AccountListItem | null) => void
  isLoading: boolean
}

const AccountFilter: FC<AccountFilterProps> = ({ account, setAccount, isLoading }) => {
  return (
    <div className={CLASS_NAME}>
      <FormDropdown
        className={cnx('account-dropdown', isLoading && 'is-loading')}
        id='account'
        label='Account'
        mode='select'
        disabled={isLoading}
        popoverClassName={POPOVER_CLASS_NAME}
        value={account}
        onChange={(_, value) => setAccount(value)}
        renderOption={getAccountOptionName}
        formatDisplayValue={getAccountOptionName}
        fetchHandler={AccountingApiProvider.getAccounts}
        renderSelectDisplay={() => {
          if (account == null) {
            return <>Account {isLoading ? <Loader color='dark' className='loader' /> : <IconSVG.DropdownArrowDown width={16} height={16} />}</>
          }

          return (
            <>
              {account.number} - {account.name}

              <span
                className='close-icon-wrapper'
                onClick={(e) => {
                  e.stopPropagation()
                  setAccount(null)
                }}
              >
                {isLoading ? <Loader color='dark' className='loader' /> : <IconSVG.Close width={14} height={14} />}
              </span>
            </>
          )
        }}
      />
    </div>
  )
}

export default AccountFilter
