import { touchify } from '@carfluent/common'
import type { VehicleById } from 'api/types'
import { Dropdowns } from 'api/types'
import { GET_DEFAULT_VEHICLE_BY_ID } from 'api/defaults'

import type { TransactionDealDetails, UpdateVehicleDictionaries } from './types'
import { parseVehicleById } from './parser'

export enum Alerts {
  saveSuccess = 'Changes successfully saved.',
  vinError = 'Could not fetch car details by VIN'
}

export const DEFAULT_VEHICLE: VehicleById = GET_DEFAULT_VEHICLE_BY_ID()

export const DEFAULT_DICTIONARIES: UpdateVehicleDictionaries = {
  [Dropdowns.DisabilityEquipped]: [],
  [Dropdowns.Body]: [],
  [Dropdowns.Cylinders]: [],
  [Dropdowns.Doors]: [],
  [Dropdowns.Drivetrain]: [],
  [Dropdowns.Fuel]: [],
  [Dropdowns.GenericColor]: [],
  [Dropdowns.GenericInterior]: [],
  [Dropdowns.Transmission]: [],
  [Dropdowns.TitleStatus]: [],
  [Dropdowns.InventoryState]: [],
  [Dropdowns.Tags]: [],
  [Dropdowns.TitleStage]: [],
  [Dropdowns.RegistrationStage]: []
}

export const DEFAULT_FORM_DATA = parseVehicleById(DEFAULT_VEHICLE, DEFAULT_DICTIONARIES)

export const ALL_FORM_TOUCHED = touchify(DEFAULT_FORM_DATA)

export const GET_DEFAULT_TRANSACTION_DEAL_DETAILS = (): TransactionDealDetails => ({
  workflowAccountingStateId: null,
  recapTransactionId: null
})
