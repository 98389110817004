import { type GenerateSaleReportDto } from 'api/types/requests'
import { type DealerSettingsModel, type CoverageFeeSettingsModel, SaleRecordCombinedModel } from 'api/types/responses'
import { parseFeeMarkupSettingsDto, parserDealSaleReportDto } from 'api/mappers/responses'

import AccountingApiProvider from './accounting.api'
import CustomersCoreApiProvider from './customersCore.api'

export type KeyType = Omit<CoverageFeeSettingsModel, PropsToExclude>
export type PropsToExclude =
  | 'serviceContractTaxable'
  | 'gapTaxable'
  | 'appearanceProtectionTaxable'
  | 'tireAndWheelTaxable'
  | 'theftTaxable'

export const getDealerSettings = async (): Promise<DealerSettingsModel> => {
  const settings = await CustomersCoreApiProvider.getSettings()
  const accountIds: number[] = Object.keys(settings.coverageFeeSettings)
    .map((key) => settings.coverageFeeSettings[key as keyof KeyType]?.accountId)
    .filter((item) => item != null) as number[]

  // find unique values
  const params = [...new Set(accountIds)]

  const accountList = await AccountingApiProvider.getAccountByIds(params)

  return parseFeeMarkupSettingsDto(settings, accountList.items)
}

export const getDealSaleReport = async (payload: GenerateSaleReportDto): Promise<SaleRecordCombinedModel> => {
  const [report, workflowTypes] = await Promise.all([
    CustomersCoreApiProvider.getDealSaleReport(payload),
    CustomersCoreApiProvider.getWorkflowTypes()
  ])

  return {
    parsedDeport: parserDealSaleReportDto(report.records, workflowTypes.items),
    defaultReport: report
  }
}
