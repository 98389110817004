import { useCallback, useState } from 'react'

export interface UseSelectRowReturn<T = number> {
  hasSelectedRow: boolean
  selectedRowId: T | null
  isSelectedRow: (rowId: T) => boolean
  onClearSelection: () => void
  onSelectRow: (rowId?: T | null) => void
}

const useSelectRow = <T>(): UseSelectRowReturn<T> => {
  const [selectedRowId, setSelectedRowId] = useState<T | null>(null)
  const hasSelectedRow = selectedRowId != null

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const isSelectedRow = useCallback((rowId: T) => {
    return selectedRowId === rowId
  }, [selectedRowId])

  const onClearSelection = useCallback(() => {
    setSelectedRowId(null)
  }, [])

  const onSelectRow = useCallback((rowId?: T | null) => {
    setSelectedRowId(rowId ?? null)
  }, [])

  // ========================================== //

  return {
    hasSelectedRow,
    isSelectedRow,
    onClearSelection,
    onSelectRow,
    selectedRowId
  }
}

export default useSelectRow
