import type { FC } from 'react'
import { FormInput, FormNumberInput } from '@carfluent/common'

import { ValidationLength } from 'constants/validation'
import { type OtherIncomeSectionProps } from 'pages/deals/CreditApplication/types'

const OtherIncome: FC<OtherIncomeSectionProps> = ({
  pathPrefix,
  values,
  errors,
  touched,
  isFormDisabled,
  onChange,
  onBlur
}) => {
  return (
    <div className='credit-app-section no-collapse other-income'>
      <h3>Other monthly income</h3>
      <p className='text-info'>
        In accordance with the General Terms and Conditions, you are required to read the
        following statement to the applicant before you can request from the applicant the
        amount, if any, of "Other Income" and the "Source of Other Income". Alimony, child
        support, or separate maintenance income need not be disclosed if you do not wish
        to have it considered as a basis for repaying this obligation.
      </p>

      <FormInput
        id={`${pathPrefix}.incomeOption`}
        label='Income options'
        preset='withoutCyrillic'
        disabled={isFormDisabled}
        value={values.incomeOption}
        error={errors?.incomeOption}
        touched={touched?.incomeOption}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={ValidationLength.INCOME_OPTIONS_MAX}
      />

      <FormNumberInput
        id={`${pathPrefix}.otherIncome`}
        label='Income'
        preset='financial'
        mode='integer'
        disabled={isFormDisabled}
        isNegativeAllowed={false}
        endAdornment='/month'
        value={values.otherIncome}
        error={errors?.otherIncome}
        touched={touched?.otherIncome}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={ValidationLength.INCOME_MAX_LENGTH}
      />
    </div>
  )
}

export default OtherIncome
