import { css } from '@emotion/css'

export default css(`
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  :has(div > .cf-menu-button):not(:has(.cf-go-back-btn)) {
    align-self: flex-end;
    justify-content: flex-end;
  }
  
  :has(.children-wrapper) {
    display: grid;
    grid-template-columns: 1fr 1fr 140px;
    grid-gap: 24px;

    :not(:has(.cf-go-back-btn)) { grid-template-columns: 1fr 140px; }

    .cf-go-back-btn { justify-self: flex-start; }
    > div { justify-self: flex-end; }
  }

  .cf-go-back-btn.cf-button-root.cf-button-variant-text {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    height: 16px;
    padding: 2px;

    .cf-arrow {
      width: 20px;
      margin-right: 12px;
    }

    .cf-button-content {
      letter-spacing: 0.5px;
      height: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #212121;

      :hover {
        color: #101010;
        font-weight: 600;
      }
    }
  }

  .cf-button-root.cf-button-variant-text.cf-action-btn {
    width: fit-content;
    max-width: 160px;
    height: 16px;  
    text-transform: uppercase;
    text-decoration: none;

    .cf-button-content {
      letter-spacing: 0.5px;
      height: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #212121;
      
      .cf-loader {
        top: -5px;
      }

      :hover {
        color: #101010;
        font-weight: 600;
      }
    }
  }

  .cf-delete-btn {
    color: #B00020;
  }
  
  .cf-menu-item {
    width: 240px;
  }
`)
