import { serializers as S } from '@carfluent/common'

import {
  type VehicleCostCreateRequestDto,
  type VehicleCostUpdateRequestDto,
  TransactionTypeId
} from 'api/types'

import { type CostFormData } from './types'

/**
 * Should be refused on the validation step, so this value should never go to BE.
 */
const INVALID_NUMBER = -42

export interface SerializerProps {
  cost: CostFormData
  control: number | null
  costType?: TransactionTypeId
  isPurchaseCostType?: boolean
}

export const serializeVehicleCost = ({
  control,
  cost: {
    amount,
    costType,
    creditAccountId: _creditAccountId,
    date,
    description,
    purchaseAccountId,
    referenceNumber,
    vendor
  },
  isPurchaseCostType = false
}: SerializerProps): VehicleCostCreateRequestDto => {
  const vendorItemId = vendor != null ? Number(vendor.id) : INVALID_NUMBER
  const creditAccountId = (isPurchaseCostType ? purchaseAccountId : _creditAccountId) ?? INVALID_NUMBER
  const vehicleCostTypeId = costType?.id != null ? Number(costType.id) : INVALID_NUMBER

  return {
    amount: amount ?? 0,
    creditAccountId,
    date: S.serializeDate(date) ?? '',
    description: description,
    referenceNumber: referenceNumber,
    vendorId: vendorItemId,
    vehicleCostTypeId,
    vehicleId: control ?? INVALID_NUMBER
  }
}
export const serializeUpdateVehicleCost = (payload: SerializerProps): VehicleCostUpdateRequestDto => {
  const { vehicleId, ...serialized } = serializeVehicleCost(payload)
  return serialized
}
