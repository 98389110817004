import type { FC } from 'react'
import { observer } from 'mobx-react-lite'

import FiltersPanel from 'components/common/FiltersPanel'
import OverlayedPageHOC from 'components/PageOverlay/OverlayedPageHOC'
import Button from 'components/common/Button'
import BackToTop from 'components/common/BackToTop'
import { LoadableTable as Table } from 'components/common/Table'
import LeadDetailsFormModal from 'components/crm/LeadDetailsFormModal'
import getFilterCounter from 'utils/filters/getFilterCounter'

import { FILTERS } from './hook/constants'
import useLeadsList from './hook'

import CLASS_NAME, { LABEL_POPOVER_CLASS_NAME } from './styles'

const LeadsList: FC = () => {
  const {
    columns,
    rows,
    counters,
    search,
    sorting,
    tabFilter,
    emptyState,
    isModalOpen,
    isFiltersChange,
    isLoading,
    onBottomReached,
    onCreateLead,
    onCloseLeadDetails,
    onOpenLeadDetails,
    onSelectTab,
    setSorting,
    setSearch,
    onSearch
  } = useLeadsList({
    labelPopoverCls: LABEL_POPOVER_CLASS_NAME
  })

  return (
    <div className={CLASS_NAME}>
      <FiltersPanel
        searchProps={{
          onChange: setSearch,
          isLoading,
          value: search,
          placeholder: 'Name, email, phone, car',
          dataTestId: 'leads-filter-search',
          withDeleteIcon: true,
          onSearch
        }}
        tabProps={{
          tabs: FILTERS.map((item) => ({
            ...item,
            counter: getFilterCounter(item, counters)
          })),
          activeTab: tabFilter,
          isLoading: isFiltersChange,
          onSelectTab
        }}
        renderActions={() => (
          <Button
            className='button'
            onClick={() => onOpenLeadDetails()}
            data-test-id='leads-btn-add-new'
          >
            NEW LEAD
          </Button>
        )}
      />

      <Table
        columns={columns}
        data={rows}
        emptyTableMessage={emptyState}
        isLoading={isLoading}
        isTabChanges={isFiltersChange}
        onBottomReached={onBottomReached}
        onSortingChange={setSorting}
        onRowClick={onOpenLeadDetails}
        sorting={sorting}
        headerDataTestId='leads-table-header'
        bodyDataTestId='leads-table-body'
      />

      <LeadDetailsFormModal
        isOpen={isModalOpen}
        onClose={onCloseLeadDetails}
        onCreateAction={onCreateLead}
      />

      <BackToTop />
    </div>
  )
}

export default OverlayedPageHOC(observer(LeadsList))
