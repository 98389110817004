import { css } from '@emotion/css'
import { px, ScreenWidthBreakpoints } from 'constants/constants'

const BOLD_TEXT_STYLES = `
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`

export const MODAL_CLASSES = css(`
  &.cf-modal-container{
    .cf-modal-content-scroll-wrapper {
      .cf-modal-root {
        .cf-modal-title,
        .cf-modal-content {
          max-width: 1600px;
          padding: 0px 24px;
        }

        .cf-modal-title {
          padding: 40px 24px 0;
        }

        .cf-modal-footer {
          padding: 12px 40px;
        }

        .cf-modal-content {
          min-height: unset;
        }
      }
    }
  }
`)

export default css(`
  .lender-decision-title {
    ${BOLD_TEXT_STYLES}
    color: #101010;
    margin-bottom: 24px;
    font-size: 20px;
  }

  .lender-decision-top-row-wrapper {
    > div {
      padding: 12px 0 20px;
    }
  }

  .lender-decision-items-wrapper {
    padding: 28px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    display: grid;
    align-items: center;
    grid-template-areas: 
    'lenderTop lenderTop amountTop buyRateTop termTop statusTop additionalInfoTop'
    'lenderBottom lenderBottom amountBottom buyRateBottom termBottom statusBottom additionalInfoBottom';
    grid-gap: 0 20px;
    grid-template-columns: 25% repeat(6, 1fr);

    @media screen and (min-width: ${px(ScreenWidthBreakpoints.Small)}) {
      grid-template-columns: 24.5% repeat(6, 1fr);
    }
  
    @media screen and (min-width: ${px(ScreenWidthBreakpoints.Medium)}) {
      grid-template-columns: 24% repeat(6, 1fr);
    }
  }

  .lender-decision-top-row-label {
    ${BOLD_TEXT_STYLES}
  }
  
  .lender-decision-bottom-row-wrapper {
    padding-bottom: 96px;
  }
`)
