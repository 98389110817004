import { type DealerProductDeductibleDto } from 'api/types/responses'
import type { CoverageProvider, CoverageTerm, CoverageWithTerms } from 'types/coverage'

export const getSelectedCoverageName = (currentPackage: CoverageWithTerms[], coverageName?: string): CoverageWithTerms | null => {
  return currentPackage?.find(el => {
    return el.coverageName.toLowerCase() === (coverageName ?? '').toLowerCase()
  }) ?? null
}

export const getSelectedTerm = (termList: CoverageTerm[], productCoverageId: number | null = null): CoverageTerm | null => {
  return termList.find(el => el.id === productCoverageId) ?? null
}

export const getSelectedVendor = (penPackages: CoverageProvider[], providerId: number | null = null): CoverageProvider | null => {
  return penPackages?.find(el => el.id === providerId) ?? null
}

export const formatDeductibleOption = (el: DealerProductDeductibleDto | null): string => el?.description ?? ''
