import type { DeepRequired } from '@carfluent/common'
import { emptyAddress } from 'utils/address'
import type { VendorFormData } from './types'

export enum Messages {
  SuccessCreate = 'Vendor was created successfully.',
  SuccessUpdate = 'Vendor was updated successfully.',
  SuccessDelete = 'Vendor was deleted successfully.',
  ErrorLoad = 'Failed to load vendor data.'
}

const DEFAULT_FORM_DATA: DeepRequired<VendorFormData> = {
  id: null,
  name: null,
  phoneNumber: null,
  email: null,
  ein: null,
  ssn: null,
  address: { ...emptyAddress },
  isDifferentMailingAddress: false,
  mailingAddress: { ...emptyAddress },
  isTrack1099Payments: false,
  payableAccount: null
}

export const FIELD_TRANSFORMS = {
  email: (value: string) => value.trim()
}

export const getDefaultFormData = (): DeepRequired<VendorFormData> => ({ ...DEFAULT_FORM_DATA })
