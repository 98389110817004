import * as Yup from 'yup'
import type { AnyObject } from 'yup/es/types'

import { VehicleState } from 'api/types'
import { MAX_CAR_PRICE_VALUE } from 'constants/validation'
import {
  Validator, getValidator,
  requiredNumber, requiredString, modelField,
  makeField, trimField, dictionaryObjectItem,
  yearField, vinField, dateField,
  hundredYearsDateValidator, ErrorMsg, maxNumberField
} from 'utils/yup'

import type { UpdateVehicleFormData } from '../types'

const DAYS_MAX = 100

const PRICE_ERROR_MSG = ErrorMsg.betweenMinMax.replace(/\$\{max\}/g, 'Sale price')
const DAYS_ERROR_MSG = ErrorMsg.betweenMinMax.replace(/\$\{max\}/g, `${DAYS_MAX} inclusive`)
const DEDUCTION_ERROR_MSG = 'Price deduction exceeds the minimal price limit.'

const DRAFT_SCHEMA = Yup.object().shape({
  vin: vinField(),
  year: yearField(true),
  make: makeField(true),
  model: modelField(true),
  trim: trimField(true),
  inventoryDate: hundredYearsDateValidator(),
  stock: requiredString(),
  titleReceived: dateField(),
  titleSent: dateField(),
  salePrice: maxNumberField(MAX_CAR_PRICE_VALUE),
  titleStage: dictionaryObjectItem()
})

interface GenericCtx {
  from: Array<{ value: Record<string, unknown> }>
}

const validateMinSalePrice = (_value: number | null | undefined, ctx: Yup.TestContext<AnyObject>): boolean => {
  const contextGeneric = ctx as unknown as GenericCtx
  const value = _value ?? 0
  const salePrice = contextGeneric.from?.[1].value.salePrice as number

  return (value < salePrice) && (value > 0)
}

const validateDeductionDifference = (_value: number | null | undefined, ctx: Yup.TestContext<AnyObject>): boolean => {
  const contextGeneric = ctx as unknown as GenericCtx
  const value = _value ?? 0
  const currentMinPrice = (ctx.parent.minPrice ?? 0)
  const salePrice = contextGeneric.from?.[1].value.salePrice as number
  const diff = salePrice - currentMinPrice

  return value < diff
}

const validateDays = (_value: number | null | undefined): boolean => {
  const value = _value ?? 0

  return (value <= DAYS_MAX) && (value > 0)
}

const REPRICE_FIELDS_SCHEMA = Yup.object().shape({
  minPrice: requiredNumber()
    .test('minSale', PRICE_ERROR_MSG, validateMinSalePrice),
  dayNumber: requiredNumber()
    .test('noDays', DAYS_ERROR_MSG, validateDays),
  priceDeduction: requiredNumber()
    .test('priceDeduction', PRICE_ERROR_MSG, validateMinSalePrice)
    .test('priceDiff', DEDUCTION_ERROR_MSG, validateDeductionDifference)
})

const getStrictSchema = (isRepriceActive: boolean): Yup.AnySchema => Yup.object().shape({
  vin: vinField(),
  year: yearField(true),
  make: makeField(true),
  model: modelField(true),
  trim: trimField(true),
  mileage: requiredNumber(),
  inventoryDate: hundredYearsDateValidator(),
  stock: requiredString(),
  genericColor: dictionaryObjectItem(true),
  titleReceived: dateField(),
  titleSent: dateField(),
  salePrice: maxNumberField(MAX_CAR_PRICE_VALUE),
  titleStage: dictionaryObjectItem(),
  ...(isRepriceActive ? { vehicleRepricingDetails: REPRICE_FIELDS_SCHEMA } : {})
})

const getValidationFn = (status: VehicleState, isRepriceActive: boolean): Validator<UpdateVehicleFormData> => {
  if (status === VehicleState.Active) {
    return getValidator(getStrictSchema(isRepriceActive))
  }

  return getValidator(DRAFT_SCHEMA)
}

export default getValidationFn
