export const MIN_GLOBAL_MODAL_WIDTH = 1276

export const MIN_CONFIRM_MODAL_WIDTH = 680

export enum ModalTitles {
  Refund = 'Refund receipt?',
  Void = 'Void receipt?'
}

export enum SubmitBtnNames {
  Refund = 'YES, REFUND',
  Void = 'YES, VOID'
}

export enum ModalContents {
  Refund = 'Are you sure you want to refund this receipt? This action cannot be undone.',
  Void = 'Are you sure you want to void this receipt? This action cannot be undone.'
}
