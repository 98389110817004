import { type FC } from 'react'
import { Button, FormInput, FormMaskedInput } from '@carfluent/common'

import { observer } from 'mobx-react-lite'

import ErrorsBlock from 'components/common/ErrorsBlock'
import AuthLayout from 'components/layout/AuthLayout'
import PasswordValidationPanel from 'components/common/PasswordValidationPanel'

import useSetupAccount from './hook'
import CLASS_NAME from './styles'

const SetUpAccount: FC = () => {
  const {
    isSubmitting,
    values,
    errors,
    touched,
    onSubmit,
    onChange,
    onBlur,
    apiErrors,
    showPassword,
    toggleShowHidePassword
  } = useSetupAccount()

  return (
    <AuthLayout>
      <div className={CLASS_NAME}>
        {(apiErrors != null) && (
          <ErrorsBlock errors={apiErrors} className='setup-account-api-errors' />
        )}

        <p className='title-wrapper'>
          Set Up Account
        </p>

        <FormInput
          id='firstName'
          label='First name'
          onChange={onChange}
          onBlur={onBlur}
          value={values.firstName}
          error={errors.firstName}
          touched={touched.firstName}
          className='half-item'
        />

        <FormInput
          id='lastName'
          label='Last name'
          onChange={onChange}
          onBlur={onBlur}
          value={values.lastName}
          error={errors.lastName}
          touched={touched.lastName}
          className='half-item'
        />

        <FormMaskedInput
          id='phoneNumber'
          mask='phone'
          label='Phone number'
          onChange={onChange}
          onBlur={onBlur}
          value={values.phoneNumber}
          error={errors.phoneNumber}
          touched={touched.phoneNumber}
        />

        <div className='password-wrapper'>
          <FormInput
            id='password'
            label='Password'
            autoComplete='cc-csc' // prevent autofill of form
            type={showPassword ? 'text' : 'password'}
            onChange={onChange}
            onBlur={() => {}}
            value={values.password}
            touched={touched.password}
            error={errors.password}
            endAdornment={(
              <span
                className='show-hide'
                onClick={toggleShowHidePassword}
              >
                {showPassword ? 'Hide' : 'Show'}
              </span>
          )}
          />

          <PasswordValidationPanel
            value={values.password}
            isTouched={Boolean(touched.password)}
          />
        </div>

        <Button
          className='submit-btn'
          dataTestId='btn-set-up'
          isLoading={isSubmitting}
          onClick={() => { void onSubmit() }}
        >
          Set up
        </Button>
      </div>
    </AuthLayout>
  )
}

export default observer(SetUpAccount)
