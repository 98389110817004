import { type FC } from 'react'
import { cnx, utils } from '@carfluent/common'

import HorizontalScrollableList from 'components/common/HorizontalScrollableList'
import AccountInfo from './components/AccountInfo'
import AccountSkeleton from './components/AccountSkeleton'
import useAccountsListSection from './hook'
import { type UseAccountsListSectionProps } from './hook/types'
import CLASS_NAME from './styles'

const SKELETONS = utils.numbers.range(4)

export type AccountsListSectionProps = UseAccountsListSectionProps

const AccountsListSection: FC<AccountsListSectionProps> = (props) => {
  const {
    accounts,
    activeAccountNum,
    isLoading,
    onSelectAccount
  } = useAccountsListSection(props)

  return (
    <HorizontalScrollableList className={cnx(CLASS_NAME, 'g-panel')}>
      {isLoading && SKELETONS.map((id) => <AccountSkeleton key={id} />)}

      {!isLoading && accounts.map((item) => (
        <AccountInfo
          {...item}
          key={item.id}
          isActive={activeAccountNum === item.number}
          onClick={onSelectAccount}
        />
      ))}
    </HorizontalScrollableList>
  )
}

export default AccountsListSection
