import { css } from '@emotion/css'
import { px, ScreenWidthBreakpoints } from 'constants/constants'

export default css(`
  display: flex;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  flex-wrap: wrap;
    
  b {
    font-weight: 500;
  }
  
  .icon-container,
  .text-container,
  .date-container {
    display: flex;
    min-width: fit-content;
    align-items: center;
  }
  
  .text-container,
  .date-container {
    gap: 4px;
  }

  .text-container {
    .banking-info {
      font-weight: 500;

      @media screen and (max-width: ${px(ScreenWidthBreakpoints.Medium)}) {
        max-width: 230px;
      }
    }
  }
`)
