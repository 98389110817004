import { css } from '@emotion/css'
import { px, ScreenWidthBreakpoints } from 'constants/constants'

export const POPOVER_CLASS_NAME = css(`
  min-width: 270px;
  &.cf-popover-content {
    z-index: 1301 !important;
  }
`)

export default css(`
  .cf-table-wrapper {

    .cf-table { table-layout: fixed; }

    .cf-table-header {
      tr > th:last-child > div { justify-content: flex-end; }

      .cf-table-header-cell:not(:last-child) { padding: 0 8px;}

      img { opacity: 0.5; }
    }
      
    .cf-table-row {
      cursor: auto;

      .cf-table-cell { background: #FFFFFF; }

      :hover {
        .cf-table-cell { background: #FAFAFA; }
        .cf-table-cell:hover { background: #FAFAFA; }
      }
      
    }

    .cf-table-cell {
      height: 1px; // this is a pure magic: https://stackoverflow.com/questions/3215553/make-a-div-fill-an-entire-table-cell
      :nth-child(2) .cf-cell-wrapper { min-width: fit-content; }
    }

    .cf-cell-wrapper {
      height: 100%;
      padding: 12px 16px;
      min-height: 64px;

      p {white-space: nowrap;}
    }

    .cf-header-cell-title { color: rgba(33, 33, 33, 0.5); }


    .cf-checkbox-cell .MuiButtonBase-root { padding: 0; }

    .cf-account-cell {
      max-width: 400px;

      @media screen and (max-width: ${px(ScreenWidthBreakpoints.Medium)}) {
        max-width: 260px;
      }
    }

    .cf-transaction-cell {
      .cf-button-root {
        text-decoration: none;
      }

      .cf-button-content {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.15px;
        color: #458FFF;
      }
    }

    .cf-reference-cell {
      max-width: 200px;

      @media screen and (max-width: ${px(ScreenWidthBreakpoints.Medium)}) {
        max-width: 160px;
      }
    }

    .cf-identifier-cell {
      div { margin-top: 0px; }
      max-width: 280px;
      align-items: baseline;

      @media screen and (max-width: ${px(ScreenWidthBreakpoints.Medium)}) {
        max-width: 240px;
      }
    }

    .cf-amount-cell {
      text-align: right;
      align-items: baseline;
    }

    .cf-amount-header-cell {
      justify-content: flex-end;
    }
  }

  &.hidden {
    display: none;
  }
`)
