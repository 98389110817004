import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { DEAL_ACTIONS } from 'constants/constants'

import CLASS_NAME from './styles'

export interface DealActionProps {
  action: number | null
}

/**
 * AZ-TODO: check if needed after Deal redesign (uses some odd actions enum, different from BE)
 */
const DealAction: FC<DealActionProps> = ({ action }) => {
  const name = action !== null ? DEAL_ACTIONS.find((item) => item.id === action)?.name : ''
  return (
    <span className={cnx(CLASS_NAME, `deal-action-${name != null ? name.toLowerCase() : 'no-action'}`)}>
      {name}
    </span>
  )
}

export default DealAction
