import { type FC } from 'react'

const IconPersonaPassed: FC = () => {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.4974 1.67188C5.8974 1.67188 2.16406 5.40521 2.16406 10.0052C2.16406 14.6052 5.8974 18.3385 10.4974 18.3385C15.0974 18.3385 18.8307 14.6052 18.8307 10.0052C18.8307 5.40521 15.0974 1.67188 10.4974 1.67188ZM8.83073 14.1677L4.66406 10.001L5.83906 8.82604L8.83073 11.8094L15.1557 5.48438L16.3307 6.66771L8.83073 14.1677Z'
        fill='#00C4B4'
      />
    </svg>
  )
}

export default IconPersonaPassed
