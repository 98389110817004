import { css } from '@emotion/css'

const modalPartsHeight = {
  root: 1032,
  header: 76,
  footer: 80,
  contentSearch: 56,
  contentMargins: 48
}

// Apart from gallery section height
const heights = modalPartsHeight.header + modalPartsHeight.footer +
modalPartsHeight.contentSearch + modalPartsHeight.contentMargins

export const CLASS_NAME = css(`
  &&.cf-modal-container {  
    opacity: 1;
    .cf-modal-content-scroll-wrapper {
      padding-bottom: 20px;
      z-index: 1199;
      overflow: hidden;
    }

    .cf-modal-root {
      padding: 0;
      height: ${modalPartsHeight.root}px;
      overflow: hidden;

      .cf-modal-content {
        .vehicles-dropdown {
          max-width: 420px;
          margin-bottom: 24px;
        
          .cf-input::placeholder { color: rgba(33, 33, 33, 0.4); }
      
          .cf-end-adornment:has(.cf-dropdown-clear-button) { 
            .cf-dropdown-trigger-button { display:none; }
          }
        }

        .gallery-section {
          max-height: calc(100vh - ${heights}px);
          overflow: auto;

          ::-webkit-scrollbar {  display: none; }
          
          .empty-state {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: rgba(33, 33, 33, 0.5);
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
          }
        }
      }

      .cf-modal-footer {
        display: flex;
        height: ${modalPartsHeight.footer}px;
        padding: 12px 64px;
        margin: 0;
        background: #FFF;
        box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.12);
        justify-content: space-between;
        align-items: center;

        .photos-selected-counter {
          color: rgba(0, 0, 0, 0.38);
          font-family: Roboto;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
        }

        .action-buttons {
          display: flex;

          button {
            margin: 0;

            :first-of-type { margin-right: 24px; }
          }
        }
      }
    }
  }
`)

export const DROPDOWN_POPOVER_CLASS_NAME = css(`
  &.cf-popover-content {
    position: relative;
    margin-top: 0px!important;
    padding-top: 8px;
    padding-bottom: 8px;
    min-width: 420px;
    box-shadow: rgba(58, 71, 78, 0.1) 0px 24px 38px 0px;

    .cf-dropdown-listbox {
      max-height: 328px;
      padding: 0;
      border-radius: 8px;
    }

    .cf-dropdown-option { 
      padding: 0px 16px;
      :has(.group-name) { opacity: 1; }
    }

    .cf-no-options-container { color: rgba(0, 0, 0, 0.54); }

    .option-render {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 6px 0px;

      &.group-name {
        color: rgba(33, 33, 33, 0.50);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    .vehicle-state {
      display: flex;
      align-items: center;

      :before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
        background-color: rgba(0, 0, 0, 0.3);
      }

      &.active:before { background-color: #219653; }
      &.sold:before { background-color: #FFA451; }
    }

    .vehicle-name {
      display: flex;
      align-items: center;
      font-weight: 400;
      max-width: 274px;
      white-space: nowrap;
      margin-top: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-stock {
        font-weight: 500;
        margin-right: 8px;
      }
    }

    .option-sold, .option-deleted {
      .vehicle-name { color: rgba(33, 33, 33, 0.4); }
    }
  }
`)
