import type { FC, FocusEvent } from 'react'
import { cn, FormDropdown } from '@carfluent/common'
import type { DictionaryItem } from 'api/types'
import { useCarDetailsFields } from './hook'
import type { UseCarDetailsProps, IdKeys } from './hook'
import { DEFAULT_CAR_DETAILS_IDS, Labels } from './hook/constants'
import CLASS_NAME from './styles'

interface MakeModelFormProps extends Omit<UseCarDetailsProps, 'ids'> {
  ids?: Record<IdKeys, string>
  make: string | null
  model: string | null
  trim: string | null
  makeError?: string | null
  modelError?: string | null
  trimError?: string | null
  makeTouched?: boolean
  modelTouched?: boolean
  trimTouched?: boolean
  disabled?: boolean
  getOptionValue?: (option: DictionaryItem | null) => string
  classes?: Record<Partial<IdKeys>, string>
  onBlur?: (evt: FocusEvent<HTMLDivElement>) => void
}

const CarDetailsFields: FC<MakeModelFormProps> = ({
  ids = DEFAULT_CAR_DETAILS_IDS,
  classes = {},
  make,
  model,
  trim,
  makeError,
  modelError,
  trimError,
  makeTouched,
  modelTouched,
  trimTouched,
  disabled,
  onBlur,
  onChange: _onChange,
  setFieldTouched
}) => {
  const { makes, models, trims, onChange, selectedMakeItem, selectedModelItem, selectedTrimItem } = useCarDetailsFields({
    make: make,
    model: model,
    trim: trim,
    onChange: _onChange,
    ids,
    setFieldTouched
  })

  const makeCls = cn(CLASS_NAME, classes.make ?? '')
  const modelCls = cn(CLASS_NAME, classes.model ?? '')
  const trimCls = cn(CLASS_NAME, classes.trim ?? '')

  return (
    <>
      <FormDropdown
        className={makeCls}
        disabled={makes.length === 0 || disabled}
        error={makeError}
        id={ids.make}
        label={Labels.Make}
        onBlur={onBlur}
        onChange={onChange}
        options={makes}
        touched={makeTouched}
        value={selectedMakeItem}
      />

      <FormDropdown
        className={modelCls}
        disabled={models.length === 0 || disabled}
        error={modelError}
        id={ids.model}
        label={Labels.Model}
        onBlur={onBlur}
        onChange={onChange}
        options={models}
        touched={modelTouched}
        value={selectedModelItem}
      />

      <FormDropdown
        className={trimCls}
        disabled={trims.length === 0 || disabled}
        error={trimError}
        id={ids.trim}
        label={Labels.Trim}
        onBlur={onBlur}
        onChange={onChange}
        options={trims}
        touched={trimTouched}
        value={selectedTrimItem}
      />
    </>
  )
}

export default CarDetailsFields
