import intersection from 'lodash-es/intersection'
import type { AddressLike, FullAddressParts } from '../types/address'

export const ZIP_CODE_TYPE = 'postal_code'
export const CITY_TYPES = ['locality', 'city', 'postal_town', 'sublocality_level_1', 'sublocality']
export const STATE_TYPE = 'administrative_area_level_1'
export const COUNTY_TYPE = 'administrative_area_level_2'

// @deprecated
export const emptyAddress = {
  addressLong: null,
  address: null,
  city: null,
  zipCode: null,
  state: null,
  county: null
}

export function getPostalCode (place?: google.maps.GeocoderResult | null): string | null {
  if (place == null) {
    return null
  }

  const addressPart = place.address_components
    ?.find((item) => item.types.includes(ZIP_CODE_TYPE))

  return addressPart?.short_name ?? addressPart?.long_name ?? null
}

export function getCity (place?: google.maps.GeocoderResult | null): string | null {
  if (place == null) {
    return null
  }

  const addressPart = place.address_components
    ?.find((item) => intersection(item.types, CITY_TYPES).length > 0)

  return addressPart?.short_name ?? addressPart?.long_name ?? null
}

export function getState (place?: google.maps.GeocoderResult | null): string | null {
  if (place == null) {
    return null
  }

  const addressPart = place.address_components
    ?.find((item) => item.types.includes(STATE_TYPE) && (item.short_name.length === 2))

  return addressPart?.short_name ?? addressPart?.long_name ?? null
}

export function getCounty (place?: google.maps.GeocoderResult | null): string | null {
  if (place == null) {
    return null
  }

  const addressPart = place.address_components
    ?.find((item) => item.types.includes(COUNTY_TYPE))

  return addressPart?.short_name ?? addressPart?.long_name ?? null
}

export const fullAddress = (addr?: string | FullAddressParts | null): string => {
  if (addr == null) {
    return ''
  }

  if (typeof addr === 'string') {
    return addr
  }

  const { addressLong, address, city, state, zipCode } = addr
  const prefix = [addressLong ?? address, city, state, zipCode].filter(Boolean).join(', ')
  return prefix.length > 0 ? `${prefix}, USA` : ''
}

export const isAddressDataEqual = (addr1?: AddressLike | null, addr2?: AddressLike | null): boolean => {
  /**
   * county is ignored totaly as backend very often ignores this prop and
   * we have situations when it is undefined or absent but it is present in address predictions
   */
  return addr1?.address === addr2?.address &&
    addr1?.city === addr2?.city &&
    addr1?.zipCode === addr2?.zipCode &&
    addr1?.state === addr2?.state &&
    addr1?.apt === addr2?.apt
}

export const areAddressesEqual = (addr1?: FullAddressParts | null, addr2?: FullAddressParts | null): boolean => {
  /**
   * county is ignored totaly as backend very often ignores this prop and
   * we have situations when it is undefined or absent but it is present in address predictions
   */
  return addr1?.address === addr2?.address &&
    addr1?.city === addr2?.city &&
    addr1?.zipCode === addr2?.zipCode &&
    addr1?.state === addr2?.state
}

export const addressParts = (
  place: google.maps.GeocoderResult | null
): FullAddressParts | null => {
  if (place == null) {
    return null
  }

  const addressParts = place?.address_components?.slice(0, 2) ?? []
  const addressLong = addressParts.map(item => item.long_name).join(' ') ?? null
  const addressShort = addressParts.map(item => item.short_name).join(' ') ?? null

  return {
    address: addressShort,
    addressLong,
    city: getCity(place),
    state: getState(place),
    zipCode: getPostalCode(place),
    county: getCounty(place)
  }
}

export const loadGoogleMapScript = async (
  googleMapsScriptBaseUrl: string,
  googleMapsScriptUrl: string
): Promise<void> => {
  if (typeof google !== 'undefined' && google.maps != null) {
    return await Promise.resolve()
  }

  const scriptElements = document.querySelectorAll(
    `script[src*='${googleMapsScriptBaseUrl}']`
  )

  if (scriptElements?.length > 0) {
    return await new Promise((resolve) => {
      // in case we already have a script on the page and it's loaded we resolve
      if (typeof google !== 'undefined') return resolve()

      // otherwise we wait until it's loaded and resolve
      scriptElements[0].addEventListener('load', () => resolve())
    })
  }

  const el = document.createElement('script')
  el.src = googleMapsScriptUrl

  document.body.appendChild(el)

  return await new Promise((resolve) => {
    el.addEventListener('load', () => resolve())
  })
}
