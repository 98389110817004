import { type KeyVal, type SortingInfo } from './types'

export const DEFAULT_SORTING: SortingInfo = { id: 'date', order: 'desc' }

export const DEFAULT_FILTERS: KeyVal = {}

export const API_CALL_DELAY_SEARCH = 3000

export enum Messages {
  EmptyTableState = 'There are no transactions showing for this account in this time period.',
  ReconciliationCannotBeFinished = 'Reconciliation cannot be finished as difference is not zero.',
  ReconciliationSuccess = 'Account reconciled successfully.',
  SomethingWrong = 'Something went wrong.'
}
