import { getHoursMinutesTime } from 'utils/parse_date'
interface CurrentDateTime {
  date: Date
  time: string
}

export const getCurrentDateTime = (): CurrentDateTime => ({
  date: new Date(),
  time: getHoursMinutesTime(new Date())
})
