import {
  type Vehicle,
  type VehicleById,
  type VehicleDetails,
  type VehiclePartsDictionaries,
  Dropdowns,
  VehicleState,
  SortOrder
} from 'api/types'
import { type VehicleModel } from 'api/types/responses'

export const GET_DEFAULT_VEHICLE = (): Vehicle => ({
  age: 0,
  engine: null,
  genericColor: null,
  genericInterior: null,
  id: 0,
  imageCount: 0,
  isDeposited: false,
  fuelType: null,
  mainImageUrl: null,
  make: null,
  mileage: null,
  model: null,
  salePrice: null,
  stock: null,
  tags: [],
  transmissionType: null,
  trim: null,
  vehicleState: VehicleState.Inactive,
  vin: null,
  year: null,
  vehicleStatus: null,
  viewsCount: null,
  leadsCount: null,
  source: null
})

export const DEFAULT_VEHICLE_REPRICING = {
  minPrice: null,
  dayNumber: null,
  priceDeduction: null,
  history: [],
  nextPriceDropInDays: 0,
  isEnabled: false
}

export const GET_DEFAULT_VEHICLE_BY_ID = (): VehicleById => ({
  id: 0,
  vin: null,
  year: 0,
  make: null,
  model: null,
  trim: null,
  mileage: 0,
  stock: null,
  age: 0,
  salePrice: 0,
  vehicleRepricingDetails: DEFAULT_VEHICLE_REPRICING,
  mainImageUrl: null,
  imageCount: 0,
  tags: [],
  vehicleState: VehicleState.Inactive,
  inventoryDate: null,
  notes: null,
  titleType: 0,
  titleReceived: null,
  titleSent: null,
  titleStateId: 0,
  titleNumber: null,
  bodyType: 0,
  engine: null,
  doorCount: 0,
  fuelType: 0,
  cylinderCount: 0,
  transmissionType: 0,
  drivetrainType: 0,
  genericColor: 0,
  genericInterior: 0,
  isDisabilityEquipped: false,
  description: null,
  descriptionText: null,
  isVehicleInspected: false,
  rowVersion: null,
  vehicleImages: [],
  vehiclePrices: [],
  isDeposited: false,
  vehicleStatus: null,
  source: '',
  isTitleReceived: false,
  features: {},
  options: {},
  isRepricingActive: false,
  isRepricingEnabled: false,
  vehicleDocuments: [],
  buyer: null,
  viewsCount: 0,
  activationDate: null,
  titleStageId: 0,
  registrationStageId: 0,
  titleNote: null,
  soldDate: null,
  deletedDate: null,
  deletedByUser: null,
  soldByUser: null
})

export const GET_DEFAULT_VEHICLE_DETAILS = (): VehicleDetails => ({
  antiBrakeSystem: null,
  cargoLength: null,
  cargoVolume: null,
  category: null,
  cityMileage: null,
  curbWeight: null,
  curbWeightManual: null,
  deliveryCharges: null,
  depth: null,
  doors: null,
  drivetrain: null,
  engine: null,
  engineCylinders: null,
  engineSize: null,
  exteriorColor: null,
  frontBrakeType: null,
  frontHeadroom: null,
  frontHipRoom: null,
  frontLegroom: null,
  frontShoulderRoom: null,
  frontSpringType: null,
  frontSuspension: null,
  fuelCapacity: null,
  grossVehicleWeightRating: null,
  fuelType: null,
  groundClearance: null,
  highwayMileage: null,
  interiorTrim: null,
  invoicePrice: null,
  madeIn: null,
  madeInCity: null,
  make: null,
  manufacturerSuggestedRetailPrice: null,
  maximumGvwr: null,
  maximumPayload: null,
  maximumTowing: null,
  model: null,
  optionalSeating: null,
  overallHeight: null,
  overallLength: null,
  overallWidth: null,
  passengerVolume: null,
  productionSeqNumber: null,
  rearBrakeType: null,
  rearHeadroom: null,
  rearHipRoom: null,
  rearLegroom: null,
  rearShoulderRoom: null,
  rearSpringType: null,
  standardSeating: null,
  rearSuspension: null,
  size: null,
  standardPayload: null,
  standardTowing: null,
  steeringType: null,
  style: null,
  tires: null,
  trackFront: null,
  trackRear: null,
  transmission: null,
  transmissionShort: null,
  transmissionSpeeds: null,
  transmissionType: null,
  trim: null,
  turningDiameter: null,
  type: null,
  wheelbaseLength: null,
  widthAtWall: null,
  widthAtWheelwell: null,
  year: null
})

export const GET_DEFAULT_DEAL_VEHICLE_MODEL = (): VehicleModel => ({
  id: null,
  dealId: 0,
  make: '',
  model: '',
  trim: '',
  vin: '',
  mainImageUrl: null,
  year: null,
  stock: '',
  salePrice: null,
  mileage: null,
  dealerId: 0,
  inventoryDate: null,
  soldDate: null,
  isDeposited: false,
  vehicleState: 'Inactive',
  vehicleStatus: '0',
  buyerId: null,
  odometer: null,
  weight: null,
  listPrice: null,
  inServiceDate: null,
  bodyStyle: null,
  color: null
})

export const GET_DEFAULT_DICTIONARIES = (): VehiclePartsDictionaries => ({
  [Dropdowns.DisabilityEquipped]: [],
  [Dropdowns.Body]: [],
  [Dropdowns.Cylinders]: [],
  [Dropdowns.Doors]: [],
  [Dropdowns.Drivetrain]: [],
  [Dropdowns.Fuel]: [],
  [Dropdowns.GenericColor]: [],
  [Dropdowns.GenericInterior]: [],
  [Dropdowns.Transmission]: [],
  [Dropdowns.Make]: []
})

export const GET_DEFAULT_VEHICLES_SORTING = (): { sortField: string, sortOrder: SortOrder} => ({
  sortField: 'vehicleState',
  sortOrder: 1
})
