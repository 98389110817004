import type { ColumnDef } from '@carfluent/common/dist/UI'

import TextCellHOC from 'components/common/Table/cells/TextCell'
import { TextValueHOC } from 'components/common/Table/cells/TextValueCell'
import InputNumberCellHOC from 'components/common/Table/cells/InputNumberCell'

import { MAX_PRICE_VALUE } from 'constants/validation'

import { type OpeningBalanceRowData } from './types'

export interface ColumnsConfig {
  onBalanceChange: (rowIdx: number, columnId: string, value: number | string) => void
}

const getColumnDefinitions = ({
  onBalanceChange
}: ColumnsConfig): Array<ColumnDef<OpeningBalanceRowData>> => ([
  {
    accessorKey: 'number',
    cell: TextValueHOC({ className: 'cell-account-number' }),
    header: TextCellHOC({ text: 'Number' }),
    minSize: 80,
    sortable: false
  },
  {
    accessorKey: 'name',
    cell: TextValueHOC({ className: 'cell-account-name' }),
    header: TextCellHOC({ text: 'Name' }),
    minSize: 340,
    sortable: false,
    loading: 'extremely_big_line'
  },
  {
    accessorKey: 'accountType',
    cell: TextValueHOC({ className: 'cell-account-type' }),
    header: TextCellHOC({ text: 'Type' }),
    minSize: 160,
    sortable: false
  },
  {
    accessorKey: 'accountCategory',
    cell: TextValueHOC({ className: 'cell-account-category' }),
    header: TextCellHOC({ text: 'Category' }),
    minSize: 160,
    sortable: false
  },
  {
    accessorKey: 'openingBalance',
    cell: InputNumberCellHOC({
      className: 'cell-opening-balance',
      isDecimal: true,
      isEmptyAllowed: false,
      onChange: onBalanceChange,
      isNegativeAllowed: true,
      isSelectedOnFocus: true,
      tooltipMessage: 'Cannot be modified as the account has already been reconciled.',
      maxValue: MAX_PRICE_VALUE
    }),
    header: TextCellHOC({ text: 'Opening balances', className: 'cf-opening-balances-column' }),
    minSize: 160,
    sortable: false
  }
])

export default getColumnDefinitions
