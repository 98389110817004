import type { DictionaryItem } from 'api/types'
import { TaskAssigneeType, TaskTypesNames } from 'api/types/crm.types'

export enum FieldIds {
  Title = 'title',
  AssignedTo = 'assignedUser',
  TaskType = 'taskType',
  ReminderSubject = 'reminderSubject',
  TaskPriority = 'taskPriority',
  RunOnDay = 'executionDay',
  Description = 'description',
  WorkPlanEventTypeId = 'workplanEventTypeId',
  EmailTemplateId = 'emailTemplateId',
  MessageTemplateId = 'messageTemplateId',
  RunAt = 'runAtTime',
}

export enum FieldLabels {
  Title = 'Title',
  AssignedTo = 'Assigned to',
  TaskType = 'Task type',
  ReminderSubject = 'Reminder subject',
  TaskPriority = 'Task priority',
  RunOnDay = 'Run on day',
  Description = 'Description',
  EmailTemplate = 'Email template',
  MessageTemplate = 'Message template',
  RunAt = 'Run at',
}

export enum SubmitBtnText {
  Add = 'ADD',
  Save = 'SAVE'
}

export const TaskAssigneeTypeNames = {
  [TaskAssigneeType.User]: 'User',
  [TaskAssigneeType.Owner]: TaskTypesNames.Owner,
  [TaskAssigneeType.RoundRobin]: TaskTypesNames.RoundRobin,
  [TaskAssigneeType.RoundRobinBDC]: TaskTypesNames.RoundRobinBDC,
  [TaskAssigneeType.RoundRobinSales]: TaskTypesNames.RoundRobinSales
}

export function getDefaultAssigneeOptions (assignees: DictionaryItem[]): DictionaryItem[] {
  return assignees
    .filter(a => a.id !== TaskAssigneeType.User)
    .map(assignee => {
      return {
        id: -assignee.id,
        name: TaskAssigneeTypeNames[assignee.id as TaskAssigneeType]
      }
    })
}

export const UNASSIGNED_USER = {
  id: 0,
  name: 'Unassigned'
}

export const PRIORITY_TASK_MEDIUM_ID = 2

export const MAX_RULES = 20
export const MAX_RULES_ERROR = 'Can’t be more than 20 events per status.'
export const SUCCESS_MESSAGE = 'Event added successfully.'
export const SUCCESS_UPDATED_MESSAGE = 'Event saved successfully.'
export const ERROR_MESSAGE = 'Failed to send request. Please try again later.'
export const RULE_DELETE_SUCCESS_MESSAGE = 'Event deleted successfully.'

export const DEFAULT_FORM_VALUE = {
  [FieldIds.Title]: '',
  [FieldIds.AssignedTo]: null,
  [FieldIds.TaskType]: null,
  [FieldIds.ReminderSubject]: null,
  [FieldIds.TaskPriority]: null,
  [FieldIds.RunOnDay]: 0,
  [FieldIds.Description]: '',
  [FieldIds.WorkPlanEventTypeId]: 1,
  [FieldIds.EmailTemplateId]: null,
  [FieldIds.MessageTemplateId]: null,
  [FieldIds.RunAt]: '10:00 AM'
}

export const MAX_ROWS_DESCRIPTION = 3
