import { type FC } from 'react'
import isValidDate from 'date-fns/isValid'
import {
  cnx,
  FormDatePicker,
  FormDropdown,
  TimeSelect
} from '@carfluent/common'

import { DELIVERY_OPTIONS } from 'api/mappers/responses'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

import { type DeliverySectionProps } from './types'
import CLASS_NAME from './styles'

const DeliverySection: FC<DeliverySectionProps> = ({
  className,
  errors,
  isLoading = false,
  onBlur,
  onChange,
  touched,
  values,
  isRecorded
}) => {
  const checkoutDetails = values.checkoutDetails

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <BlockSkeleton className='section-title' isLoading={isLoading}>
        <h6>Delivery</h6>
      </BlockSkeleton>

      <div className='fields'>
        <BlockSkeleton isLoading={isLoading}>
          <FormDropdown
            error={errors.checkoutDetails.deliveryType}
            disableClearable
            id='checkoutDetails.deliveryType'
            mode='search'
            onBlur={onBlur}
            onChange={onChange}
            options={DELIVERY_OPTIONS}
            placeholder='Delivery type'
            value={checkoutDetails.deliveryType}
            touched={touched.checkoutDetails.deliveryType}
            disabled={isRecorded}
          />
        </BlockSkeleton>

        <BlockSkeleton isLoading={isLoading}>
          <FormDatePicker
            error={errors.checkoutDetails.pickupDeliveryDate}
            id='checkoutDetails.pickupDeliveryDate'
            label='Delivery date'
            onBlur={onBlur}
            onChange={onChange}
            touched={touched.checkoutDetails.pickupDeliveryDate}
            value={values.checkoutDetails.pickupDeliveryDate}
            disabled={isRecorded}
          />
        </BlockSkeleton>

        <BlockSkeleton isLoading={isLoading}>
          <TimeSelect
            disabled={!isValidDate(checkoutDetails?.pickupDeliveryDate) || isRecorded}
            id='checkoutDetails.pickupDeliveryTime'
            label='Delivery time'
            onChange={(value: string) => {
              onChange('checkoutDetails.pickupDeliveryTime', value)
            }}
            value={checkoutDetails?.pickupDeliveryTime ?? ''}
          />
        </BlockSkeleton>

        {!isLoading && (
          <>
            <BlockSkeleton />
            <BlockSkeleton />
          </>
        )}
      </div>

      <BlockSkeleton className='section-title' isLoading={isLoading} />
    </div>
  )
}

export default DeliverySection
