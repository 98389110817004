import { type DictionaryItem } from 'api/types'
import { type UserDto } from 'api/types/common'
import { type CampaignStatisticsModel } from './CampaignStatisticsModel'

export enum SendScheduleType {
  Now = 'Now',
  Schedule = 'Schedule'
}

export enum RecipientsFilter {
  None = 0,
  Included,
  Excluded
}

export enum RecipientsFilterTitles {
  Exclude = 'Exclude',
  Include = 'Include',
  NotSpecified = 'Not specified'
}

export interface RecipientFilterValues {
  filter: DictionaryItem | null
  options: DictionaryItem[] | null
}

export interface LeadWithScheduledAppointmentsValuesData {
  filter: DictionaryItem | null
  options: DictionaryItem[] | null
}

export interface CampaignModel {
  id: number | null
  campaignStatusId: number
  campaignTypeId: number
  contentDetails: {
    body: string | null
    bodyHtml: string | null
    emailSubject: string | null
    name: string | null
    preHeader: string | null
    senderEmail: string | null
    senderName: string | null
  }
  createdByUser: UserDto | null
  filters: {
    filterLeadSource: RecipientFilterValues | null
    filterLeadStatus: RecipientFilterValues | null
    filterLeadTemperature: RecipientFilterValues | null
    filterLeadWithScheduledAppointments: LeadWithScheduledAppointmentsValuesData | null
    recipientsCounter: number | null
  }
  recipientsCount: number | null
  rowVersion: string
  schedule: {
    type: SendScheduleType
    date: Date | null
    time: string | null
  }
  statistics: CampaignStatisticsModel
}
