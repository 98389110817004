import { FC, useCallback } from 'react'
import { UI, cn } from '@carfluent/common'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import type { CellProps } from 'types'
import ApplyPropsHOK from 'components/common/ApplyPropsHOC'
import useStyles from './styles'

const { CellWrapper } = UI

export type CellFC<TData, TValue = unknown> = FC<CellProps<TData, TValue>>
export type RemoveRowCellFC<TData, TValue = unknown> = FC<CellProps<TData, TValue> & RemoveRowCellProps>

export interface RemoveRowCellProps {
  onRemoveRow: (rowIdx: number) => void
  disabled?: boolean
  isHidden?: boolean
}

function RemoveRowCell <TData, TValue = unknown> ({
  row,
  onRemoveRow,
  disabled = false
}: CellProps<TData, TValue> & RemoveRowCellProps): JSX.Element {
  const styles = useStyles()

  const handleRemoveRow = useCallback(() => {
    onRemoveRow(row.index)
  }, [row.index, onRemoveRow])

  return (
    <CellWrapper className={cn(styles.root, 'cf-remove-row')}>
      {!disabled && (
        <IconButton
          aria-label='close'
          onClick={handleRemoveRow}
        >
          <CloseIcon />
        </IconButton>
      )}
    </CellWrapper>
  )
}

export function RemoveRowCellHOK<TData, TValue = unknown> (
  config: RemoveRowCellProps,
  Comp: RemoveRowCellFC<TData, TValue> = RemoveRowCell
): CellFC<TData, TValue> {
  return ApplyPropsHOK(config, Comp)
}

export default RemoveRowCellHOK
