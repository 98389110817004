import type { FC } from 'react'
import type { Row } from '@tanstack/react-table'
import { CellWrapper } from '@carfluent/common'

import type { Vehicle } from 'api/types/vehicles.types'
import TooltipedText from 'components/common/TooltipedText'

export interface CarCellProps {
  row: Row<Vehicle>
}

const StockCell: FC<CarCellProps> = (props) => {
  const row = props.row.original

  return (
    <CellWrapper>
      <TooltipedText
        value={row.stock}
      />
    </CellWrapper>
  )
}

export default StockCell
