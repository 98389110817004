import { css } from '@emotion/css'

export default css(`
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  min-width: 92px;
  letter-spacing: 0.25px;
  color: #474747;
  padding: 2px 9px;
  background: #E0E0E0;
  border-radius: 4px;
  text-align: center;

  &.deal-complete {
    background: #CFE6D9;
    color: rgba(14, 76, 41, 1);
  }
`)
