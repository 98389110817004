import { css } from '@emotion/css'

export default css(`
  padding: 0;
  display: flex;
  background: #fff;
  flex-direction: column;
  justify-content: center;

  p, span {
    color: #458FFF;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    line-height: 24px;
    text-overflow: ellipsis;
    font-family: Roboto, sans-serif;
  }

  &:hover {
    text-decoration: none;
  }
`)
