import { useContext, useMemo } from 'react'
import { FormikValues, FormikErrors, FormikTouched } from 'formik'
import get from 'lodash-es/get'

import { FormCTX, FormCTXProps } from './context'

export interface FormField<V = any> {
  id: string
  value?: V
  errors?: FormikErrors<V> | Array<FormikErrors<V>>
  error?: string | null
  touched?: V extends object ? FormikTouched<V> : boolean
}

export interface UseFieldReturn<V> {
  value: V
  error: string
  touched: boolean
  isErrorVisible: boolean
}

export function useField<V = any> (props: FormField<V>): UseFieldReturn<V> {
  const { id } = props
  const { values, errors, touched } = useContext(FormCTX) ?? {}
  const resolvedValue = props.value === undefined ? get(values, id) : props.value

  const resolvedError = props.error ?? ((props.errors !== undefined)
    ? Array.isArray(props.errors) ? (props.errors[0] ?? '') : props.errors
    : Array.isArray(errors) ? (errors[0] ?? '') : get(errors, id, ''))

  const resolvedTouched = props.touched !== undefined
    ? Boolean(props.touched)
    : get(touched, id, false)

  const isErrorVisible = Boolean(resolvedError) && Boolean(resolvedTouched)

  return useMemo(() => ({
    value: resolvedValue as V,
    error: resolvedError ?? '',
    touched: Boolean(resolvedTouched),
    isErrorVisible
  }), [
    resolvedValue,
    resolvedError,
    resolvedTouched,
    isErrorVisible
  ])
}

export function useForm<V extends FormikValues = FormikValues> (): FormCTXProps<V> {
  return useContext<FormCTXProps<V>>(FormCTX)
}
