import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;

  .white-background {
    background: #FFF;
    border-radius: 12px;
  }

  &.grey-background { background: #F3F3F3; }

  .schedule-created-task {
    display: flex;
    flex-direction: column;
    position:relative;
    background: #FFF;
    border-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    &.task-overdue-border-color {
      border-left: 4px solid #B00020;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .cf-loader {
      position: absolute;
      inset: 0;
      z-index: 100;
      background-color: rgba(255, 255, 255, 0.75);
    }

    .created-task {
      display: grid;
      grid-template-areas:
        'dueToDate assignedTo priority'
        'description description description'
        'buttons buttons buttons';
      grid-template-columns: minmax(100px, 180px) minmax(100px, 194px) minmax(80px, 1fr);
      row-gap: 8px;
      padding: 12px 16px 12px 16px;

      &.no-description {
        grid-template-areas:
        'dueToDate assignedTo priority'
        'buttons buttons buttons';
      }
      
      .lead-tasks-result-block {

        .due-to {
          text-transform: uppercase;
          color: #101010;
        }

        .label {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: rgba(33, 33, 33, 0.5);
        }

        img {
          height: 25px;
          position: relative;
          right: 5px;
        }
      }

      .task-dueToDate {
        grid-area: dueToDate;
        max-width: 167px;
      }

      .task-assignedTo {
        grid-area: assignedTo;
        max-width: 160px;
      }

      .task-priority {
        grid-area: priority;

        .priority-value {
          display:flex;
          align-items: center;

          p {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #101010;
            letter-spacing: 0.4px;
          }
        }
      }

      .task-description {
        grid-area: description;
        
        p {
          overflow-wrap: break-word;
          color: #101010;
        }
      }
    }

      .delete-lead-btn {
        display: flex;
        justify-content: center;
      }

      .lead-tasks-action-buttons {
        line-height: 22px;
        width: max-content;
        height: 26px;
        border-radius: 8px;
        padding: 12px 18px 10px 18px;
        color: #000;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 1.25px;
        }
      }
    }

    .horizontal-divider {
      height: 8px;
      background-color: #F3F3F3; 
      border: solid 1px #F3F3F3;
    }

    .task-outdated-border-color {
      border-left: 4px solid #B00020;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .task-today-border-color {
      border-left: 4px solid #FFCA28;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    } 

    .task-upcoming-border-color {
      border-left: 4px solid rgba(0, 0, 0, 0.12);
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    } 
  }
`)
