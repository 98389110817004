import { FC, useState } from 'react'
import { UITools } from '@carfluent/common'

import { Button, Typography } from '@material-ui/core'

import { DEFAULT_HELPER_TEXT } from 'components/dialogs/ImportCsvModal/constants'
import { FileUploadInfo } from 'components/dialogs/ImportCsvModal/types'
import { useStyles } from './styles'

const { cn } = UITools

export const UploadContent: FC<FileUploadInfo> = ({ error, handleChangeFile }) => {
  const styles = useStyles()
  const [isDragHelperExist, toggleDragHelper] = useState(false)

  const showDragHelper = () => toggleDragHelper(true)
  const hideDragHelper = () => toggleDragHelper(false)

  const helperText = error != null ? error : DEFAULT_HELPER_TEXT
  const contentClass = cn(styles.content, isDragHelperExist ? styles.contentHidden : '')
  const dragContentClass = cn(styles.dragContent, isDragHelperExist ? styles.dragContentActive: '')

  return (
    <>
      <div className={contentClass}>
        <Typography variant='body1' className={styles.dragTitle}>
          Drag and Drop here, or <Button classes={{ root: styles.uploadBtn }}>Upload File</Button>
        </Typography>
        <Typography variant='body1' className={`${styles.textHelper} errorMessage`}>
          {helperText}
        </Typography>
      </div>
      <div className={dragContentClass}>
        <Typography variant='body1' className={styles.dragTitle}>
          Drag file here
        </Typography>
      </div>
      <input
        onDrop={hideDragHelper}
        onDragEnter={showDragHelper}
        onDragLeave={hideDragHelper}
        className={styles.inputFile}
        accept='text/csv'
        type='file'
        onChange={handleChangeFile}
      />
    </>
  )
}
