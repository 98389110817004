import { type FC } from 'react'
import { utils } from '@carfluent/common'

import Table from 'components/common/Table'
import DragHereMessage from 'components/common/DragHereMessage'
import ConfirmModal from 'components/dialogs/DeleteModal'

import { DEFAULT_FILE_MAX_SIZE } from 'constants/files'
import FormFilesUpload, { type FormFilesUploadValue, type EntityWithName } from 'components/common/FormFilesUpload'
import type { VehicleDocumentDto } from 'api/types'

import getColumnDefinitions, { type VehicleDocumentLoaded } from './columns'
import CLASS_NAME from './styles'

const FILE_EXTENSIONS = ['pdf', 'xlsx', 'xls', 'doc', 'docx', 'jpg', 'jpeg', 'png']
const EMPTY_IMAGE_VALUE: FormFilesUploadValue = []
const MAX_FILES_SIZE = DEFAULT_FILE_MAX_SIZE * 3
export const MAX_FILE_NUMBER = 100
export const MAX_LIMIT_ERROR = '100 files maximum'

export interface FilesTabProps {
  documents: Array<VehicleDocumentLoaded | VehicleDocumentDto>
  onChangeFiles: (id: string, files: EntityWithName[]) => Promise<void>
  onRedirect: (id: number) => Promise<void>
  onDelete: () => Promise<void>
  setDeleteFileId: (id: number | null) => void
  isDeleteModalOpen: boolean
  onError: (error?: string) => void
  error?: string
}

const additionalInfo = <p className='additional-info'>Supported formats: doc, docx, pdf, png, jpeg, jpg, xls or xlsx.</p>

const FilesTab: FC<FilesTabProps> = ({
  documents,
  onDelete,
  onChangeFiles,
  onRedirect,
  setDeleteFileId,
  isDeleteModalOpen,
  error,
  onError
}) => {
  const columnDefinitions = getColumnDefinitions({
    onDelete: setDeleteFileId,
    onRedirect
  })

  return (
    <div className={CLASS_NAME}>
      <FormFilesUpload
        id='doc_files'
        acceptedExtensions={FILE_EXTENSIONS}
        maxFileSize={MAX_FILES_SIZE}
        maxFilesTotalSize={utils.UNLIMITED_FILES_SIZE}
        onChange={onChangeFiles}
        onError={onError}
        suggestedExtensions={FILE_EXTENSIONS}
        value={EMPTY_IMAGE_VALUE}
        dragHereMessage={<DragHereMessage />}
        className={CLASS_NAME}
        maxFileNumber={MAX_FILE_NUMBER}
        errorMessage={error}
        showError={error != null}
        messageFilesNumberLimit={MAX_LIMIT_ERROR}
        additionalInfo={additionalInfo}
      >
        <div className='cf-files-table'>
          <Table
            data={documents}
            columns={columnDefinitions}
            emptyTableMessage='No documents yet. Drag or upload.'
          />
        </div>
      </FormFilesUpload>

      <ConfirmModal
        content='Please note this step cannot be undone.'
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteFileId(null)}
        onConfirm={onDelete}
        title='Delete this file?'
        cancelTitle='Back'
      />
    </div>

  )
}

export default FilesTab
