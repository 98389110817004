/**
 * Converts a float number to a TimeSpan string in the format "0.00:00:00" (C# acceptable format).
 * The integer part represents hours, and the decimal part represents minutes.
 *
 * @param {number} number Float number where the decimal part represents minutes and the integer part represents hours.
 * @returns {string} string in the format "0.00:00:00".
 */
const convertNumberToTimeSpan = (number: number): string => {
  const [_hours, minutes = 0] = number.toFixed(2).toString().split('.').map(Number)

  const minutesInHours = _hours * 60

  const days = Math.floor(minutesInHours / 1440) // 1440 minutes in a day
  const remainingMinutesFromHours = minutesInHours % 1440
  const hours = Math.floor(remainingMinutesFromHours / 60)

  const paddedHours = hours.toString().padStart(2, '0')
  const paddedMinutes = minutes.toString().padStart(2, '0')

  return `${days}.${paddedHours}:${paddedMinutes}:00`
}

export default convertNumberToTimeSpan
