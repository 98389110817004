import { css } from '@emotion/css'

export default css(`
  .form-container {
    gap: 24px;
    display: flex;
    flex-direction: column;
  }
`)

export const MESSAGE_CLASS_NAME = css(`
  .content-container {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`)
