import type { FC } from 'react'
import { Button } from '@carfluent/common'

import VehiclePurchasePayments from 'pages/accounting/VehiclePurchasePayments'

import Chip from '../Chip'
import CLASS_NAME from './styles'
import usePaymentSection from './hook'
import { PAYMENT_TEXT } from './constants'
import { PaymentSectionProps, PaymentSectionType } from './types'

const PaymentSection: FC<PaymentSectionProps> = ({ paymentSectionType = PaymentSectionType.VehiclePurchase }) => {
  const {
    isOpen,
    accounts,
    onOpenModal,
    selectedAccounts,
    setSelectedAccounts
  } = usePaymentSection({ paymentSectionType })

  return (
    <div className={CLASS_NAME}>
      <div className='payment-section-title'>
        <h6>
          {PAYMENT_TEXT[paymentSectionType].title}
        </h6>
        <Button
          isContentSized
          color='secondary'
          variant='outlined'
          onClick={onOpenModal}
        >
          EDIT
        </Button>
      </div>

      <p className='payment-section-text'>
        {PAYMENT_TEXT[paymentSectionType].description}
      </p>

      <div className='payment-section-chip-container'>
        {accounts.map(({ id, name }) =>
          <Chip label={name} key={id} />
        )}
      </div>

      <VehiclePurchasePayments
        isOpen={isOpen}
        onClose={onOpenModal}
        selectedAccounts={selectedAccounts}
        paymentSectionType={paymentSectionType}
        setSelectedAccounts={setSelectedAccounts}
      />
    </div>
  )
}

export default PaymentSection
