import { type FC, useState, useEffect, MouseEvent } from 'react'
import { FormDropdown } from '@carfluent/common'

import { type StateItem } from 'utils/states_us'
import IconSVG from 'components/inlineImages'

import { CLASS_NAME, POPOVER_CLASS_NAME } from './styles'

export interface StateFilterProps {
  setFilters: (state: string) => Promise<void>
  options: StateItem[]
}

const StateFilter: FC<StateFilterProps> = ({ setFilters, options }) => {
  const [selectedItem, setSelectedItem] = useState<StateItem | null>(null)
  const onChange = (_: string, value: StateItem | null): void => {
    setSelectedItem(value)

    void setFilters(value?.abbreviation ?? '')
  }

  const onClear = (e: MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    setSelectedItem(null)

    void setFilters('')
  }

  useEffect(() => {
    if (selectedItem == null) {
      setSelectedItem(null)
    }
  }, [options])

  return (
    <div className={CLASS_NAME}>
      <div className='action-bar'>
        <FormDropdown
          className='states-filter'
          id='state'
          mode='select'
          options={options}
          value={selectedItem}
          popoverClassName={POPOVER_CLASS_NAME}
          onChange={onChange}
          formatDisplayValue={(value) => value?.name != null ? value.name : 'State'}
          endAdornment={
            selectedItem != null
              ? <div onClick={onClear}><IconSVG.Close width={16} height={16} /></div>
              : <IconSVG.DropdownArrowDown width={16} height={16} />
          }
        />
      </div>
    </div>
  )
}

export default StateFilter
