import { css } from '@emotion/css'

export default css`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;

 .col-title {
   line-height: 16px;
   font-size: 12px;
   color: rgba(33, 33, 33, 0.50);
 }
  
  .col-value {
    font-weight: 500;
    line-height: 24px;
    color: #101010;
  }
`
