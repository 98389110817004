import type { FC } from 'react'
import { Button, CellWrapper } from '@carfluent/common'

import type { LeadsListCarOfInterest } from 'api/types'
import TooltipedText from 'components/common/TooltipedText'

import CLASS_NAME from './styles'

export interface GoToLeadAndCarOfInterestCellProps {
  getValue: () => GoToLeadAndCarOfInterestCellData
}

export interface GoToLeadAndCarOfInterestCellData {
  carOfInterest: LeadsListCarOfInterest
  onOpenLeadDetails: () => void
}

const GoToLeadAndCarOfInterestCell: FC<GoToLeadAndCarOfInterestCellProps> = (props) => {
  const { carOfInterest, onOpenLeadDetails } = props.getValue()

  const displayValue = carOfInterest != null
    ? `${carOfInterest?.year ?? ''} ${carOfInterest?.make ?? ''} ${carOfInterest?.model ?? ''}`
    : '-'

  return (
    <CellWrapper className={CLASS_NAME}>
      <Button
        onClick={onOpenLeadDetails}
        variant='outlined'
        color='secondary'
      >
        Go to lead
      </Button>

      <div className='car-of-interest-wrapper'>
        <p className='label'>Car of interest:</p>
        <TooltipedText
          value={displayValue}
          rootClassName='table-cell-text'
        />
      </div>
    </CellWrapper>
  )
}

export default GoToLeadAndCarOfInterestCell
