import type { ReactNode } from 'react'

import type { Customer } from 'api/types'
import { formatPhoneNumberForView, formatFullName } from 'utils/view_helper'

import CLASS_NAME from './styles'

const CustomerOption = (customer: Customer | string | null): ReactNode => {
  if (customer == null || typeof customer === 'string') {
    return null
  }

  const { email, phoneNumber } = customer
  const hasEmail = email != null && email.length > 0
  const hasPhone = phoneNumber != null && phoneNumber.length > 0
  const hasDivider = hasEmail && hasPhone
  const name = formatFullName(customer)

  return (
    <div className={CLASS_NAME}>
      <div className='entity-option'>
        <p>{name}</p>
        <div className='entity-subtitles'>
          {hasEmail && (<p className='entity-subtitle'>{email}</p>)}
          {hasDivider && (<div className='entity-divider' />)}
          {hasPhone && (
            <p className='entity-subtitle'>{formatPhoneNumberForView(phoneNumber)}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default CustomerOption
