import type { ReactNode } from 'react'
import { FormDropdown } from '@carfluent/common'
import type { Item } from '@carfluent/common/dist/UI/Dropdown/types'

import TogglableButtonHOC from 'components/wysiwyg/toolbar/TogglableButton'
import { type EmailSnippetDto } from 'api/types/responses'

import type { SnippetsDropdownProps } from './hook/types'
import useSnippetsDropdown from './hook'

import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

const renderOption = (props: EmailSnippetDto): ReactNode => {
  return (
    <div className={props.id >= 0 ? 'dropdown-option' : 'group-name-option'}>
      {props.name}
    </div>
  )
}

const isOptionDisabled = ({ value }: Item<EmailSnippetDto>): boolean => value.id < 0

const SnippetsDropdown = ({
  isOpen,
  label,
  options,
  ...props
}: SnippetsDropdownProps): JSX.Element => {
  const { onChange } = useSnippetsDropdown({ ...props })

  return (
    <FormDropdown
      className={CLASS_NAME}
      popoverClassName={POPOVER_CLASS_NAME}
      id={`${label} Dropdown`}
      isOpen={isOpen}
      disableClearable
      mode='select'
      onChange={onChange}
      options={options}
      renderOption={renderOption}
      isOptionDisabled={isOptionDisabled}
      formatDisplayValue={() => label}
      value={null}
    />
  )
}

export default TogglableButtonHOC(SnippetsDropdown)
