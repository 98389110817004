import { useCallback, useContext, useState, useMemo, useRef, useEffect } from 'react'
import { type FileFilterData, useRefUpdater } from '@carfluent/common'
import { arrayMove } from '@dnd-kit/sortable'
import { toJS } from 'mobx'

import { type UploadedAssetWithThumbnailDto } from 'api/types'
import DocumentsApiProvider from 'api/documents.api'
import FilesApiProvider, { type FileWithUniqueName } from 'api/files.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import getUniqueName from 'utils/getUniqueName'
import { parseFiles, parseAsset } from 'hooks/usePhotosUpload/parser'
import { DEFAULT_MAX_FILES_NUM } from 'components/common/DropZone'
import { type ImageDescriptor, ImageState } from 'components/inventory/ImageItem'

import { MAX_IMAGE_SIZE, MAX_VIDEO_SIZE } from './constants'
import { type UseGalleryTabReturn } from './types'
import { type UpdateVehicleFormData } from '../../GeneralTabPanel/types'
import VehicleDetailsCTX from '../../../hooks/store' // AZ-TODO: pass original files through props

/**
 * AZ-NOTE:
 * For now, this hook is imported not by its component, but by the root hook.
 * It will be fixed, as a part of the ongoing refactoring.
 */
const useGalleryTab = (
  saveVehicle: (data?: Partial<UpdateVehicleFormData>, mediaFiles?: ImageDescriptor[]) => Promise<boolean> // AZ-TODO: temporal
): UseGalleryTabReturn => {
  const { originalParsedMediaFiles, originalVehicle } = useContext(VehicleDetailsCTX)
  const { showAlert } = useCustomSnackbar()

  const [mediaFiles, setMediaFiles] =
    useState<ImageDescriptor[]>([])

  const [editFile, setEditFile] =
    useState<ImageDescriptor | null>(null)

  const [mainImageUrl, setMainImageUrl] =
    useState<string | null>(originalVehicle?.mainImageUrl ?? null)

  const [isPhotosUploading, setPhotosUpload] = useState(false)
  const [isTabDirty, setIsTabDirty] = useState(false)
  const [isTabSaving, setIsTabSaving] = useState(false)
  const [uploadImageError, setUploadImageError] = useState<FileFilterData['error']>(undefined)
  const [isPlayingVideo, setIsPlayingVideo] = useState<boolean>(false)
  const refFiles = useRef<ImageDescriptor[]>([])

  const allowedPhotosNum = useMemo(() => {
    return Math.max(DEFAULT_MAX_FILES_NUM - mediaFiles.length, 0)
  }, [mediaFiles.length])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const setFiles = useCallback((nextFiles: ImageDescriptor[]): void => {
    refFiles.current = nextFiles
    setMediaFiles(nextFiles)
  }, [])

  const setSingleFile = useCallback((file: ImageDescriptor, idx: number): void => {
    const nextFiles = [...refFiles.current]
    nextFiles[idx] = file
    setFiles(nextFiles)
  }, [mediaFiles, setFiles])

  const onUploadFiles = useCallback(async (filesToUpload: File[]): Promise<void> => {
    try {
      setUploadImageError(undefined)
      setPhotosUpload(true)
      setIsTabSaving(true)
      setIsTabDirty(true) // AZ-NOTE: photo uploaded to cloud, but not linked with Vehicle entity yet.

      const filteredFiles = filesToUpload.filter(({ size, type }) => {
        const isVideo = ['video/mp4', 'video/quicktime'].includes(type)
        const maxSize = isVideo ? MAX_VIDEO_SIZE : MAX_IMAGE_SIZE
        const isCorrectSize = size <= maxSize

        if (!isCorrectSize) {
          return setUploadImageError(`File size can't exceed ${isVideo ? '500' : '15'}MB.`)
        }

        return isCorrectSize
      })

      const _filesToUpload = filteredFiles.map(file => ({ file, uniqueName: getUniqueName(file.name) }))
      setFiles([...refFiles.current, ...parseFiles(_filesToUpload)])

      const onFileUpload = (uploaded: UploadedAssetWithThumbnailDto, original: FileWithUniqueName): void => {
        const matchIdx = refFiles.current.findIndex(file => file.uniqueName === original.uniqueName)
        if (matchIdx > -1) {
          setSingleFile(parseAsset(uploaded, original.file), matchIdx)
        }
      }

      const onFileError = (original: FileWithUniqueName): void => {
        const matchIdx = refFiles.current.findIndex(file => file.uniqueName === original.uniqueName)
        if (matchIdx > -1) {
          setSingleFile({
            ...refFiles.current[matchIdx],
            state: ImageState.Failed
          }, matchIdx)
        }
      }

      await FilesApiProvider.uploadFilesInBatches(_filesToUpload, onFileUpload, onFileError, originalVehicle?.id)
    } catch (err) {
      console.error(err)
    } finally {
      setIsTabSaving(false)
      setPhotosUpload(false)
    }
  }, [
    setFiles,
    setSingleFile,
    originalVehicle?.id
  ])

  const onEditFile = useCallback(async (idx: number, fileType: string): Promise<void> => {
    const isVideo = ['.mp4', '.mov'].includes(fileType.toLowerCase())
    setEditFile(mediaFiles[idx])

    if (isVideo) {
      setIsPlayingVideo(true)
    }
  }, [mediaFiles, setEditFile])

  const onCloseEditModal = useCallback((): void => {
    setEditFile(null)
    setIsPlayingVideo(false)
  }, [])

  const onUploadEditedPhoto = useCallback(async (fileToUpload: File): Promise<void> => {
    try {
      setIsTabSaving(true)
      setPhotosUpload(true)

      const sendFormData = new FormData()
      sendFormData.append('File', fileToUpload)

      const uploadedPhoto = await DocumentsApiProvider.postVehiclePhotos(sendFormData)
      const updatedPhotos = mediaFiles.map(photo => {
        const isOriginalCopyOfEdited = photo.originalUrl === editFile?.originalUrl
        const isMainImage = mainImageUrl === editFile?.originalUrl

        if (isOriginalCopyOfEdited) {
          if (isMainImage) {
            setMainImageUrl(uploadedPhoto.originalUrl)
          }

          return {
            ...photo,
            originalUrl: uploadedPhoto.originalUrl,
            thumbnailUrl: uploadedPhoto.thumbnailUrl
          }
        }

        return photo
      })

      setFiles([...updatedPhotos])
      setIsTabDirty(true) // AZ-NOTE: photo uploaded to cloud, but not linked with Vehicle entity yet.
      onCloseEditModal()
    } catch (err) {
      console.error(err)
    } finally {
      setPhotosUpload(false)
      setIsTabSaving(false)
    }
  }, [
    mediaFiles,
    editFile,
    setFiles,
    onCloseEditModal
  ])

  const onSetMainPhoto = useCallback((idx: number): void => {
    setMainImageUrl(mediaFiles[idx].originalUrl)
    setIsTabDirty(true)
  }, [mediaFiles])

  const onDeleteFile = useCallback((idx: number): void => {
    const nextFiles = mediaFiles.slice(0, idx).concat(mediaFiles.slice(idx + 1))
    setFiles(nextFiles)
    setIsTabDirty(true)
  }, [mediaFiles, setFiles])

  const onReorderPhotos = useCallback((activeIdx: number, overIdx: number) => {
    setFiles(arrayMove(mediaFiles, activeIdx, overIdx))
    setIsTabDirty(true)
  }, [mediaFiles, setFiles])

  const onProcessSave = useCallback(async (): Promise<boolean> => {
    if (refFiles.current.some(file => !isImageLoaded(file))) {
      showAlert('Please remove failed photos before saving')
      return false
    }

    setIsTabSaving(true)
    const isSaved = await saveVehicle({ mainImageUrl }, mediaFiles)
    setIsTabSaving(false)
    setIsTabDirty(false)

    return isSaved
  }, [
    saveVehicle,
    showAlert,
    mainImageUrl,
    mediaFiles
  ])

  /**
   * AZ-NOTE: for some reason `originalVehicle` value is not refreshed here,
   * so reset restores pre-previous value. MobX?
   * Upd.: or check deps in unsaved changes buttons handlers
   */
  const refOriginalMediaFiles = useRefUpdater(originalParsedMediaFiles)
  const onProcessReset = useCallback(async (): Promise<void> => {
    setFiles(refOriginalMediaFiles.current.map(x => toJS(x)))
    setIsTabDirty(false)
  }, [
    setFiles,
    originalParsedMediaFiles,
    originalParsedMediaFiles.length // MobX specifics
  ])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    refFiles.current = originalParsedMediaFiles.map(x => toJS(x)) ?? []
    setMediaFiles(originalParsedMediaFiles.map(x => toJS(x)))
  }, [
    originalParsedMediaFiles,
    originalParsedMediaFiles.length // MobX specifics
  ])

  // ========================================== //

  // AZ-TODO: remove `useMemo` as the final part of refactoring
  return useMemo(() => ({
    allowedPhotosNum,
    editPhoto: editFile,
    isPhotosUploading,
    isTabDirty,
    isTabSaving,
    mainImageUrl,
    mediaFiles,
    onProcessReset,
    onProcessSave,
    onUploadFiles,
    onSetMainPhoto,
    onDeleteFile,
    onEditFile,
    onUploadEditedPhoto,
    onCloseEditModal,
    onReorderPhotos,
    uploadImageError,
    isPlayingVideo
  }), [
    allowedPhotosNum,
    editFile,
    mediaFiles,
    mainImageUrl,
    isPhotosUploading,
    isTabDirty,
    isTabSaving,
    onProcessReset,
    onProcessSave,
    onUploadFiles,
    onSetMainPhoto,
    onDeleteFile,
    onEditFile,
    onUploadEditedPhoto,
    onCloseEditModal,
    onReorderPhotos,
    uploadImageError,
    isPlayingVideo
  ])
}

export default useGalleryTab

// ========================================== //

const isImageLoaded = (img: ImageDescriptor): boolean => {
  return img.state === ImageState.Uploaded && img.originalUrl.startsWith('http')
}
