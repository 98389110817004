import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import CLASS_NAME from './styles'

export interface DepositedLabelProps {
  className?: string
  text?: string
}

export const DepositedLabel: FC<DepositedLabelProps> = ({ className = '', text = 'On deposit' }) => {
  return (
    <span className={cnx(CLASS_NAME, 'deposited-label', className)}>
      {text}
    </span>
  )
}

export default DepositedLabel
