import type { PopoverPropsV2 } from '@carfluent/common'

export const POPOVER_OPTIONS: PopoverPropsV2['options'] = {
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 8]
      }
    }
  ]
}
