import { parseDateStringUTC, formatters as F } from '@carfluent/common'

import { GET_CHECKOUT_DETAILS } from 'api/defaults'
import {
  type CheckoutDetailsDto,
  type CheckoutModel,
  DeliveryType
} from 'api/types/responses'

export const DELIVERY_OPTIONS = [
  { id: DeliveryType.PickUp, name: 'Pick Up' },
  { id: DeliveryType.Delivery, name: 'Delivery' }
]

export const parseCheckoutDetailsDto = (data: CheckoutDetailsDto | null, dealId: number): CheckoutModel => {
  const hasDelivery = data?.hasDelivery ?? null

  const deliveryTypeId = hasDelivery === true
    ? DeliveryType.Delivery
    : (hasDelivery === false) ? DeliveryType.PickUp : -1

  const deliveryType = DELIVERY_OPTIONS
    .find(x => x.id === deliveryTypeId) ?? null

  return {
    ...GET_CHECKOUT_DETAILS(),
    ...data,
    dealId: data?.dealId ?? dealId,
    deliveryType,
    pickupDeliveryDate: parseDateStringUTC(data?.pickupDeliveryDate),
    pickupDeliveryTime: F.formatLocalDate(data?.pickupDeliveryDate, 'hh:mm aaa')
  }
}
