import type { FormValidation } from '@carfluent/common'

import { respondLeadTaskOptionId } from 'utils/validationPresets'
import type { ResponseOnAutoTaskFormData } from 'pages/crm/LeadDetailsView/hook/types'

const respondOnLeadFormValidation: FormValidation<ResponseOnAutoTaskFormData> = {
  taskCompleteOptionId: respondLeadTaskOptionId
}

export default respondOnLeadFormValidation
