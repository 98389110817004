import { FC, useContext, useMemo } from 'react'
import { Navigate } from 'react-router-dom'
import { Routes } from 'constants/route_helper'
import AuthCTX from 'store/auth'
import { UserRoles } from 'api/types'
import { fullTasksUrl } from 'routing/constants'

const DefaultRoute: FC = () => {
  const { userRoles } = useContext(AuthCTX)
  const toRoute = useMemo(() => {
    /**
     * TODO: Refactor this logic.
     * - Currently, an unauthenticated user briefly sees the deals page
     *   before being redirected to the login page.
     * - This redirection to the login page is handled by the auth store
     *   in the switchOn method.
    */

    if (userRoles.includes(UserRoles.BDC)) {
      return fullTasksUrl
    } else if (userRoles.includes(UserRoles.Buyer)) {
      return Routes.Vehicles
    } else if (userRoles.includes(UserRoles.Mechanic)) {
      return Routes.Recon
    }

    return Routes.Deals
  }, [userRoles])

  return (
    <Navigate to={toRoute} replace />
  )
}

export default DefaultRoute
