import { type FC, useCallback } from 'react'
import kebabCase from 'lodash-es/kebabCase'
import { useModal, formatters as F } from '@carfluent/common'
import { Skeleton } from '@material-ui/lab'

import Modal from 'components/dialogs/ModalFullscreen'
import FooterActions from 'components/dialogs/FooterActionsFullscreen'
import DeleteModal from 'components/dialogs/DeleteModal'
import type { UseTransactionDetailsReturn } from 'pages/accounting/TransactionDetails/hook'
import _isCheck from 'utils/accounting/isCheck'

import TransactionDetails from '../TransactionDetailsContainer'
import {
  getDeleteModalContent,
  getDeleteTooltipMessage,
  getModalTitle,
  getSubmitTooltipMessage,
  PrintTitle
} from './constants'

const EditTransactionDialog: FC<UseTransactionDetailsReturn & { className?: string }> = ({
  className,
  hasRelatedDeal,
  isControlLoading,
  isLoading,
  isModalOpen,
  onCancel,
  onCloseModal,
  onDeleteTransaction: _onDeleteTransaction,
  onPrintCheck,
  onUpdateTransaction,
  recurringTransactionTemplateId,
  transactionId,
  ...restProps
}) => {
  const {
    isModalOpen: isDeleteModalOpen,
    onCloseModal: onCloseDeleteModal,
    onOpenModal: onOpenDeleteModal
  } = useModal()

  const { formProps, topBannersProps } = restProps
  const isCheck = _isCheck(restProps.transactionTypeId)
  const isDepositedDownPaymentTransactionCreated = restProps.isSystemCreated && (recurringTransactionTemplateId == null)
  const isSubmitDisabled = restProps.isReadonly || isDepositedDownPaymentTransactionCreated
  const isYearClosed = topBannersProps.lockedInfo?.isClosed ?? false
  const lockMonth = formProps.values.dateTime?.toLocaleDateString('en-us', { month: 'long', year: 'numeric' }) ?? '-'

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onDeleteTransaction = useCallback(async () => {
    onCloseDeleteModal()
    return await _onDeleteTransaction()
  }, [_onDeleteTransaction, onCloseDeleteModal])

  // ========================================== //
  //                    RENDER                  //
  // ========================================== //

  const modalTitle = getModalTitle({
    isGeneratedTransaction: restProps.isGeneratedTransaction,
    isRecurring: formProps.isRecurring,
    isSystemCreated: restProps.isSystemCreated,
    transactionTypeId: restProps.transactionTypeId
  })

  const modalTitleWithSubTitle = (recurringTransactionTemplateId != null)
    ? <h3>{modalTitle} <span className='g-modal-subtitle'>(recurring transaction)</span></h3>
    : <h3>{modalTitle}</h3>

  const tooltipMessageSubmit = getSubmitTooltipMessage({
    hasLineWithCompletedReconciliation: topBannersProps.hasLineWithCompletedReconciliation,
    isDepositedDownPaymentTransactionCreated,
    isLocked: formProps.values.isLocked,
    isReadonly: restProps.isReadonly,
    isYearClosed,
    lockMonth,
    lockYear: topBannersProps.lockedInfo?.year ?? ''
  })

  const tooltipMessageDelete: string | null = getDeleteTooltipMessage({
    hasLineWithCompletedReconciliation: topBannersProps.hasLineWithCompletedReconciliation,
    isDepositedDownPaymentTransactionCreated,
    isLocked: formProps.values.isLocked,
    isReadonly: restProps.isReadonly,
    isYearClosed,
    lockMonth,
    lockYear: topBannersProps.lockedInfo?.year ?? ''
  })

  const renderFooterActions = (): JSX.Element => (
    <FooterActions
      isDeleteDisabled={restProps.hasBankingLines || isSubmitDisabled}
      isPrintDisabled={!restProps.hasBankingLines && isSubmitDisabled}
      isSubmitDisabled={isSubmitDisabled}
      onCancel={onCancel}
      onDelete={onOpenDeleteModal}
      onOk={onUpdateTransaction}
      onPrint={isCheck ? onPrintCheck : undefined}
      titleOk='UPDATE'
      titlePrint={formProps.values.lastPrintDate == null ? PrintTitle.Print : PrintTitle.RePrint}
      printedDateTitle={F.formatLocalDate(formProps.values.lastPrintDate, 'MM/dd/yyyy hh:mm a')}
      showTooltip={restProps.isReadonly || formProps.values.isLocked}
      tooltipMessage={tooltipMessageSubmit}
      deleteTooltipMessage={tooltipMessageDelete}
    />
  )

  // ========================================== //

  return (
    <Modal
      data-test-id={kebabCase(modalTitle)}
      className={className}
      isOpen={isModalOpen}
      onClose={onCloseModal}
      renderFooterActions={renderFooterActions}
      title={isLoading ? <Skeleton className='g-small-text-skeleton' variant='text' /> : modalTitleWithSubTitle}
    >
      <TransactionDetails {...restProps} />

      <DeleteModal
        title={formProps.isRecurring ? 'Delete recurring journal entry?' : 'Delete journal entry?'}
        content={getDeleteModalContent(hasRelatedDeal, formProps.isRecurring)}
        isOpen={isDeleteModalOpen}
        onConfirm={onDeleteTransaction}
        onClose={onCloseDeleteModal}
      />
    </Modal>
  )
}

export default EditTransactionDialog
