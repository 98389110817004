import { useState, useCallback } from 'react'
import { PRESET_LABELS } from '@carfluent/common'

import type { DateType } from 'api/types'
import { AccountingApiProvider } from 'api/accounting.api'
import type { ProfitLossReport } from 'api/types/accounting.types'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import type { RowProps } from 'components/reports/ReportsBSPLTable/components/Row'
import { getDefaultData } from 'components/reports/ReportsBSPLTable/utils'
import { serializeReportForXslx } from 'pages/reports/ReportsList/serializer'
import { serializePeriod } from 'utils/accounting'
import { downloadBlob } from 'utils/general'

import { parseData } from './parser'
import {
  DEFAULT_DATA_TABLE,
  REPORT_FILE_NAMES
} from './constants'

interface UseReportsProfitLossReturn {
  data: RowProps[]
  initialPresetName: PRESET_LABELS
  onExportExcel: () => Promise<void>
  onChangeDatesFilter: (period: DateType) => Promise<void>
}

export const useReportsProfitLoss = (): UseReportsProfitLossReturn => {
  const { showAlert } = useCustomSnackbar()
  const [data, setData] = useState<RowProps[]>(getDefaultData(DEFAULT_DATA_TABLE))
  const [report, setReport] = useState<ProfitLossReport | null>(null)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const loadReport = useCallback(async (period: DateType) => {
    try {
      const nextPeriod = { startDate: period.from, endDate: period.to }
      const payload = serializePeriod(nextPeriod)
      const res = await AccountingApiProvider.getProfitLossReport(payload)
      const parsedData = parseData(res)

      setReport(res)
      setData(parsedData)
    } catch (e) {
      showAlert(e)
    }
  }, [])

  const onExportExcel = useCallback(async () => {
    try {
      if (report != null) {
        const payload = serializeReportForXslx(report)
        const res = await AccountingApiProvider.downloadProfitLossReportXlsx(payload)

        downloadBlob(res, REPORT_FILE_NAMES.xlsx)
      }
    } catch (err) {
      showAlert(err)
    }
  }, [report])

  return {
    data,
    initialPresetName: PRESET_LABELS.TODAY,
    onExportExcel,
    onChangeDatesFilter: loadReport
  }
}
