/**
 * Converts a TimeSpan string in the format "0.00:00:00" or "00:00:00" back to a float number.
 * The integer part represents hours, and the decimal part represents minutes.
 *
 * @param {string} timeSpan TimeSpan string in the format "0.00:00:00" or "00:00:00".
 * @returns {number} Float number where the decimal part represents minutes and the integer part represents hours.
 */
const convertTimeSpanToNumber = (timeSpan: string): number => {
  /**
   * if we get "00:00:00" - this will be value of daysOrTime and time will be undefined
   */
  const [daysOrTime, time] = timeSpan.split('.')

  const [_hours, minutes] = (time ?? daysOrTime).split(':').map(Number)

  const hours = time != null ? Number(daysOrTime) * 24 + _hours : _hours
  const paddedMinutes = minutes.toString().padStart(2, '0')

  return parseFloat(`${hours}.${paddedMinutes}`)
}

export default convertTimeSpanToNumber
