import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import CLASS_NAME from './styles'

interface ButtonType {
  isComplete: boolean
}

const CompleteOrIncomplete: FC<ButtonType> = ({ isComplete }) => {
  return (
    <div className={cnx(CLASS_NAME, 'complete-incomplete-label', isComplete && 'deal-complete')}>
      {isComplete ? 'Sold' : 'Incomplete'}
    </div>
  )
}

export default CompleteOrIncomplete
