import type { FC } from 'react'
import { UI } from '@carfluent/common'

import ModalFullscreen from 'components/dialogs/ModalFullscreen'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import FIProductSetup from 'pages/settings/FISettings/components/FIPackageSetup/components/FIProductSetup'

import useWarranty from './hook'
import { type WarrantySectionProps } from './hook/types'

import CLASS_NAME, { MODAL_CLASSES } from './styles'

const { Button } = UI

const Warranty: FC<WarrantySectionProps> = ({ isRecorded, ...props }) => {
  const {
    onClose,
    onSubmit,
    isLoading,
    penPackages,
    onDisplayFI,
    onProductAdd,
    addedProducts,
    isCashPayment,
    isDealComplete,
    isDealCancel,
    onChangeManualVendor,
    ...form
  } = useWarranty(props)

  const renderFooter = (): JSX.Element => {
    return (
      <CancelSubmitActionsFooter
        isLoading={isLoading}
        onSubmit={onSubmit}
        onClose={onClose}
        isDisabled={isRecorded}
        submitTitle='SAVE'
        additionalContent={
          <div className='display-fi-wrapper'>
            <Button
              isDisabled={isRecorded || isDealCancel || isDealComplete}
              variant='outlined'
              dataTestId='btn-display-fi'
              onClick={onDisplayFI}
            >
              DISPLAY F&I
            </Button>
            <span>{addedProducts.length} product{addedProducts.length > 1 ? 's' : ''} added</span>
          </div>
        }
      />
    )
  }

  return (
    <ModalFullscreen
      onClose={onClose}
      isOpen={props.isOpen}
      title='F&I products'
      isFullscreen
      isLoading={isLoading}
      className={MODAL_CLASSES}
      renderFooterActions={renderFooter}
    >
      <div className={CLASS_NAME}>
        <FIProductSetup
          {...form}
          isDealForm
          penPackages={penPackages}
          penFormAdditionalProps={{
            isCostVisible: true,
            isTotalVisible: true
          }}
          onProductAdd={onProductAdd}
          isCashPayment={isCashPayment}
          addedProducts={addedProducts}
          coverageSections={form.values}
          isProductDisabled={isRecorded}
          onChangeManualVendor={onChangeManualVendor}
        />
      </div>
    </ModalFullscreen>
  )
}

export default Warranty
