import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  labelText: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0.15,
    color: '#101010',
    marginLeft: 4
  }
})

export default useStyles
