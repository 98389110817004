import type { FC } from 'react'
import {
  FormInput,
  FormNumberInput,
  FormTextArea,
  FormDropdown,
  TimeSelect
} from '@carfluent/common'

import type { DictionaryItem } from 'api/types'
import DropdownIcon from 'components/common/DropdownIcon'

import {
  FieldIds,
  FieldLabels,
  MAX_ROWS_DESCRIPTION
} from '../../hook/constants'
import { type WorkPlanEventFormBarProps, WorkPlanEventType } from '../../hook/types'

const RUN_ON_DAY_MAX_LENGTH = 3

const WorkPlanEventFormBar: FC<WorkPlanEventFormBarProps> = ({
  onChange,
  onBlur,
  values,
  errors,
  touched,
  emailTemplates,
  messageTemplates,
  timeZoneDisplayName
}) => {
  const isMessageType = values.workplanEventTypeId === WorkPlanEventType.Message
  const fieldId = isMessageType ? FieldIds.MessageTemplateId : FieldIds.EmailTemplateId

  return (
    <>
      <FormInput
        id={FieldIds.Title}
        label={FieldLabels.Title}
        onChange={onChange}
        onBlur={onBlur}
        value={values[FieldIds.Title]}
        error={errors[FieldIds.Title]}
        touched={touched[FieldIds.Title]}
        className='g-full-width'
        dataTestId='crm-rules-field-title'
      />

      <FormDropdown<DictionaryItem>
        className='g-full-width'
        id={fieldId}
        label={isMessageType ? FieldLabels.MessageTemplate : FieldLabels.EmailTemplate}
        mode='search'
        blurMode='select-first'
        disableClearable
        options={isMessageType ? messageTemplates : emailTemplates}
        value={values[fieldId] ?? null}
        error={!(errors[fieldId] == null)}
        errorMessage={errors[fieldId]}
        touched={!(touched[fieldId] == null)}
        onChange={(id, value) => onChange(id, value)}
        onBlur={onBlur}
        dataTestId='crm-rules-field-assigned-to'
      />

      <FormNumberInput
        id={FieldIds.RunOnDay}
        label={FieldLabels.RunOnDay}
        maxLength={RUN_ON_DAY_MAX_LENGTH}
        value={values[FieldIds.RunOnDay]}
        error={errors[FieldIds.RunOnDay]}
        touched={touched[FieldIds.RunOnDay]}
        onChange={onChange}
        onBlur={onBlur}
        dataTestId='crm-rules-field-run-on-day'
      />

      <div className='time-container'>
        <TimeSelect
          id={FieldIds.RunAt}
          classes={{ option: 'time-option' }}
          label='Run at'
          onChange={(val) => onChange(FieldIds.RunAt, val)}
          value={values[FieldIds.RunAt]}
          error='Invalid time'
          data-test-id='lead-task-time'
          DropdownIconComponent={DropdownIcon}
        />
        <p className='time-zone-label'>
          *{timeZoneDisplayName ?? 'Time zone not set'}
        </p>
      </div>

      <FormTextArea
        id={FieldIds.Description}
        maxRows={MAX_ROWS_DESCRIPTION}
        label={FieldLabels.Description}
        onChange={onChange}
        onBlur={onBlur}
        value={values[FieldIds.Description]}
        error={errors[FieldIds.Description]}
        touched={touched[FieldIds.Description]}
        className='g-full-width add-rule-textarea'
        dataTestId='crm-rules-field-description'
      />
    </>
  )
}

export default WorkPlanEventFormBar
