import { serializers } from '@carfluent/common'
import type { DealBuyerPatchRequestDto } from 'api/types/customersCore.types'
import type { BuyerCobuyerTabState } from './types'
import { isStringEmpty } from 'utils/parse_string'

const { serializeDate } = serializers

const serializeTabBuyerCobuyer = (
  values: BuyerCobuyerTabState, isPersonal?: boolean
): DealBuyerPatchRequestDto => {
  const { addressDetails: buyerAddress, birthDate: buyerBirthDate, ...buyerValues } = values.buyer
  const { birthDate: cobuyerBirthDate, ...cobuyerValues } = values.cobuyer

  return {
    personalDetails: {
      ...buyerValues,
      birthDate: serializeDate(buyerBirthDate),
      socialSecurityNumber: isStringEmpty(buyerValues.socialSecurityNumber)
        ? null
        : buyerValues.socialSecurityNumber,
      address: buyerAddress.address,
      city: buyerAddress.city,
      state: buyerAddress.state,
      zipCode: buyerAddress.zipCode,
      apt: buyerAddress.apt
    },
    coApplicantFinancingDetails: values.isCobuyerSectionVisible === true
      ? {
          ...cobuyerValues,
          socialSecurityNumber: isStringEmpty(cobuyerValues.socialSecurityNumber)
            ? null
            : cobuyerValues.socialSecurityNumber,
          currentAddressDetails: {
            ...cobuyerValues.currentAddressDetails,
            id: cobuyerValues.currentAddressDetails.id ?? 0,
            apartmentMoveInDate: null
          },
          birthDate: serializeDate(cobuyerBirthDate)
        }
      : null,
    businessDetails: isPersonal === true ? null : values.businessDetails
  }
}

export default serializeTabBuyerCobuyer
