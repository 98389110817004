import { type FC, useMemo } from 'react'
import { cnx, formatDecimal, formatInteger } from '@carfluent/common'
import sortBy from 'lodash-es/sortBy'

import { ProductType } from 'api/types'
import { intercalate } from 'utils/general'
import { COVERAGE_ORDER } from 'constants/coverage'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'
import LabeledFieldSkeleton from 'components/common/Skeletons/LabeledFieldSkeleton'
import Divider from 'components/common/Divider'
import TooltipedText from 'components/common/TooltipedText'
import IconSVGPencil from 'components/inlineImages/IconSVGPencil'

import { type FinanceInsuranceSectionProps } from './types'
import { CLASS_NAME, TOOLTIP_CLASS_NAME } from './styles'

const FinanceInsuranceSection: FC<FinanceInsuranceSectionProps> = ({
  className,
  coverageDetails,
  isLoading = false,
  onOpenWarrantiesDialog
}) => {
  const resolvedCoverages = useMemo(() => (
    intercalate(null, sortBy(coverageDetails, (x) => COVERAGE_ORDER[x.productType ?? '']))
  ), [coverageDetails])

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME)}>
      <div className={cnx('inner-container', isLoading && 'no-borders', className)}>
        <BlockSkeleton className='section-title' isLoading={isLoading}>
          <h6>F&I</h6>
          <div
            className='edit-action'
            onClick={onOpenWarrantiesDialog}
          >
            <IconSVGPencil className='btn-edit-coverages' />
          </div>
        </BlockSkeleton>

        {isLoading && (
          <div className='fields'>
            <LabeledFieldSkeleton isLoading={isLoading} />
            <LabeledFieldSkeleton isLoading={isLoading} />
            <LabeledFieldSkeleton isLoading={isLoading} />
            <LabeledFieldSkeleton isLoading={isLoading} />
            <LabeledFieldSkeleton isLoading={isLoading} />
            <LabeledFieldSkeleton isLoading={isLoading} />
          </div>
        )}

        {!isLoading && (
          <div className='fields'>
            {(coverageDetails.length === 0) && (
              <div className='no-fni'>No F&I added</div>
            )}

            {resolvedCoverages.map((c, idx) => {
              if (c === null) {
                return <Divider key={idx} />
              }

              return (
                <div className='coverage-info' key={c.productType}>
                  <div className='product-name'>
                    <TooltipedText
                      className={TOOLTIP_CLASS_NAME}
                      value={
                        <>
                          <label>{c.productType}</label>
                          {(c.productType === ProductType.ServiceContract) && (
                            <span> - {formatInteger(c.termMonths, { postfix: ' mo' })}/{formatInteger(c.termMiles, { postfix: ' miles' })}</span>
                          )}
                        </>
                      }
                    />
                  </div>

                  <div className='coverage-field'>
                    <label>Type:</label>

                    <TooltipedText
                      rootClassName={TOOLTIP_CLASS_NAME}
                      value={<span>{c.coverageName}</span>}
                    />
                  </div>

                  <div className='coverage-field'>
                    <label>Total price:</label>
                    <TooltipedText value={<span>{formatDecimal(c.dealerRetailPrice, { prefix: '$' })}</span>} />
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default FinanceInsuranceSection
