import { type FormValidation, type DeepRequired } from '@carfluent/common'

import { MAX_SALES_TAX_AMOUNT } from 'constants/validation'
import {
  dateField,
  requiredField,
  priceField,
  transactionDescriptionField,
  transactionReferenceField
} from 'utils/validationPresets'

import { type CostFormData } from './types'

const validationRules: FormValidation<DeepRequired<CostFormData>> = {
  amount: priceField(MAX_SALES_TAX_AMOUNT, true),
  costType: requiredField,
  date: dateField('append'),
  description: transactionDescriptionField(true),
  referenceNumber: transactionReferenceField(),
  vendor: requiredField
}

export default validationRules
