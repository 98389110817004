import type { FC, ReactNode } from 'react'
import {
  formatCurrencyDecimal,
  formatCurrencyAccounting,
  formatInteger,
  PRESET_LABELS,
  RangePickerMode
} from '@carfluent/common'
import { NavLink } from 'react-router-dom'

import ReportLayout from 'components/reports/ReportLayout'
import BackToTop from 'components/common/BackToTop'

import TooltipedText from 'components/common/TooltipedText'
import FiltersPanel, { type FilterItem } from 'components/common/FiltersPanel'
import { asyncNoop } from 'constants/constants'
import CalcRoutes from 'constants/route_helper'

import LoadingRows from './components/LoadingRows'

import { getDisplayItem } from 'utils/selectHelpers'

import { useReportInventoryCosts } from './hook'
import { EMPTY_FORMAT_VALUE, ACCOUNTING_FORMAT_VALUE, FilterItemIds } from './hook/constants'
import CLASS_NAME, { STATUS_CLASS_NAME, ROOT_CLASS_NAME, WRAPPER_CLASS_NAME } from './styles'

const states: number[] = [
  FilterItemIds.Active,
  FilterItemIds.Inactive
]

const nameValueCell = (id: number | null, name: string): ReactNode => (
  id != null
    ? (
      <NavLink
        className='vehicle-link'
        to={CalcRoutes.VehicleDetails(id)}
        onClick={(event) => event.stopPropagation()}
      >
        <TooltipedText value={name} />
      </NavLink>
      )
    : <TooltipedText value={name} />
)

const ReportInventoryCosts: FC = () => {
  const {
    rows,
    isLoading,
    totalCost,
    presets,
    search,
    emptyTableMessage,
    selectedStates,
    onSearch,
    onSearchChange,
    onStatusFiltersChanged,
    onChangeDatesFilter,
    ...layoutProps
  } = useReportInventoryCosts()

  const searchProps = {
    value: search ?? '',
    onSearch,
    disabled: isLoading,
    isLoading,
    onChange: onSearchChange,
    placeholder: 'Car, stock #, VIN'
  }

  const dateFilters: FilterItem<'date'> = {
    filterType: 'date',
    componentProps: {
      presets,
      onFilterChange: onChangeDatesFilter,
      rangePickerMode: RangePickerMode.Single,
      defaultPresetName: PRESET_LABELS.TODAY,
      monthsNumber: 1
    }
  }

  const stateFilter: FilterItem<'multi-select', number> = {
    filterType: 'multi-select',
    componentProps: {
      id: 'status',
      name: 'Status',
      options: states,
      value: selectedStates,
      popoverClassName: STATUS_CLASS_NAME,
      renderOption: (option, config) => {
        return (<>{config.renderCheckbox(config.isSelected)} {FilterItemIds[option]}</>)
      },
      renderSelectDisplay: () => {
        const selected: string = selectedStates.length === states.length
          ? 'All'
          : selectedStates.length.toString()
        return getDisplayItem(`Status ${selected !== '0' ? `(${selected})` : ''}`)
      },
      onChange: (_id, val) => {
        void onStatusFiltersChanged(val ?? [])
      }
    }
  }

  return (
    <div className={WRAPPER_CLASS_NAME}>
      <ReportLayout
        {...layoutProps}
        title='Inventory costs'
        className={CLASS_NAME}
        rootClassName={ROOT_CLASS_NAME}
        initialPresetName={PRESET_LABELS.TODAY}
        isDateFilterEnabled={false}
        onChangeDatesFilter={asyncNoop}
      >
        <div className='report-container'>
          <FiltersPanel
            componentBackground='gray'
            searchProps={searchProps}
            filterProps={{ filters: [stateFilter, dateFilters] }}
          />
          <div className='content-block'>
            <div className='cf-total-cost'>
              <span>Total cost</span> <span>{formatCurrencyAccounting(totalCost, ACCOUNTING_FORMAT_VALUE)}</span>
            </div>

            <table className='cf-inventory-cost-report-table'>
              <thead>
                <tr>
                  <th className='cf-car-cell'>Car</th>
                  <th className='cf-mileage-cell'>Mileage</th>
                  <th className='cf-stock-cell'>Stock #</th>
                  <th className='cf-status-cell'>Status</th>
                  <th className='cf-age-cell'>Age</th>
                  <th className='cf-vin-cell'>VIN</th>
                  <th className='cf-sale-price-cell'>Sale price</th>
                  <th className='cf-cost-cell'>Cost</th>
                </tr>
              </thead>

              <tbody>
                {isLoading
                  ? <LoadingRows />
                  : (
                    <>
                      {rows.length > 0
                        ? rows.map((item, i) => {
                          const name = `${item.year ?? ''} ${item.make ?? ''} ${item.model ?? ''} ${item.trim ?? ''}`

                          return (
                            <tr key={`${name}${i}`}>
                              <td className='cf-car-cell'>{nameValueCell(item.id, name)}</td>
                              <td className='cf-mileage-cell'>{formatInteger(item.mileage, EMPTY_FORMAT_VALUE)}</td>
                              <td className='cf-stock-cell'>{item.stock}</td>
                              <td className='cf-status-cell'>{item.vehicleState}</td>
                              <td className='cf-age-cell'>{item.age} days</td>
                              <td className='cf-vin-cell'>{item.vin}</td>
                              <td className='cf-sale-price-cell'>{formatCurrencyDecimal(item.salePrice, EMPTY_FORMAT_VALUE)}</td>
                              <td className='cf-cost-cell'>{formatCurrencyAccounting(item.cost, ACCOUNTING_FORMAT_VALUE)}</td>
                            </tr>
                          )
                        })
                        : <tr className='no-data-block'><td>{emptyTableMessage}</td></tr>}
                    </>
                    )}
              </tbody>
            </table>
          </div>
        </div>
      </ReportLayout>

      <BackToTop />
    </div>
  )
}

export default ReportInventoryCosts
