import { type DealFormValues } from 'types'
import { type DealFeesAndCoveragesSettingsModel } from 'api/types/responses'
import getDealFeesAmount from 'utils/deals/priceHelpers/getDealFeesAmount'
import { roundCurrency } from 'utils/math'

export const getFeesAndTaxesSum = (
  vehiclePrice: number | null,
  fees: DealFormValues['dealFees'],
  feesAndCoveragesSettings: DealFeesAndCoveragesSettingsModel | null
): number => {
  return (fees.overridenSalesTaxAmount ?? 0) +
    getDealFeesAmount(
      vehiclePrice,
      fees,
      feesAndCoveragesSettings?.fees ?? null,
      false
    )
}

export const getFeesAndTaxesSumRounded = (
  vehiclePrice: number | null,
  fees: DealFormValues['dealFees'],
  feesAndCoveragesSettings: DealFeesAndCoveragesSettingsModel | null
): number => {
  return roundCurrency(
    getFeesAndTaxesSum(
      vehiclePrice,
      fees,
      feesAndCoveragesSettings
    )
  )
}
