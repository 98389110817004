import { dates } from '@carfluent/common'

import type { ShiftDto } from 'api/types'
import type { Shift } from 'types'

const parseData = (data: ShiftDto[]): Shift[] => {
  const shifts: Shift[] = data.map(parseDataToShift)

  return shifts
}

export const parseDataToShift = (data: ShiftDto): Shift => ({
  ...data,
  endDate: dates.convertToUTC(data.endDate) ?? new Date(data.endDate),
  startDate: dates.convertToUTC(data.startDate) ?? new Date(data.startDate)
})

export default parseData
