import {
  dateField,
  dictionaryItemDropdownField,
  positiveNumberField,
  transactionDescriptionField,
  transactionReferenceField
} from 'utils/validationPresets'

export default {
  dateTime: dateField(true),
  referenceNumber: transactionReferenceField(),
  description: transactionDescriptionField(true),
  receivableAccount: dictionaryItemDropdownField(true),
  receivableEntity: dictionaryItemDropdownField(true),
  receivableControl: dictionaryItemDropdownField(false),
  memo: transactionDescriptionField(),
  checkNumber: positiveNumberField()
}
