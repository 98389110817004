import { type FC, useCallback, useState } from 'react'

import {
  CallIcon,
  ApptIcon,
  ReminderIcon,
  EmailIcon,
  MessageIcon,
  StoreVisitIcon,
  OtherIcon
} from 'components/common/LogActivityIcons'
import Tooltip from 'components/common/Tooltip'
import { ScheduleButtonTaskType, ActivityButtonTaskType } from 'pages/crm/LeadDetailsView/hook/types'

import { LogActivityButtonsNames } from './types'
import CLASS_NAME, { TOOLTIP_CLASS_NAME } from './styles'

interface LogActivityButtonsProps {
  isContactActionsShown?: boolean
  onScheduleTaskButtonClick: (taskTypeString: ScheduleButtonTaskType) => void
  onActivityTaskButtonClick: (taskTypeString: ActivityButtonTaskType) => void
  onMessageButtonClick: () => void
  onCloseActivity: () => void
  onCloseSchedule: (taskId: number | null) => void
  onSendEmailButtonClick: () => void
  leadEmail: string | null
  leadPhoneNumber: string | null
}

const LogActivityButtons: FC<LogActivityButtonsProps> = ({
  isContactActionsShown = true,
  onScheduleTaskButtonClick,
  onActivityTaskButtonClick,
  onCloseActivity,
  onCloseSchedule,
  onSendEmailButtonClick,
  onMessageButtonClick,
  leadEmail,
  leadPhoneNumber
}) => {
  const [isEmailTooltipVisible, setIsEmailTooltipVisible] = useState<boolean>(false)
  const [isMessageTooltipVisible, setIsMessageTooltipVisible] = useState<boolean>(false)

  const onScheduleClick = useCallback((type: ScheduleButtonTaskType) => {
    onScheduleTaskButtonClick(type)
    onCloseActivity()
  }, [onScheduleTaskButtonClick, onCloseActivity])

  const onActivityClick = useCallback((type: ActivityButtonTaskType) => {
    onActivityTaskButtonClick(type)
    onCloseSchedule(null)
  }, [onActivityTaskButtonClick, onCloseSchedule])

  const _onSendEmailButtonClick = (): void => {
    if (leadEmail == null) {
      return
    }

    onSendEmailButtonClick()
  }

  const _onMessageButtonClick = (): void => {
    if (leadPhoneNumber == null) {
      return
    }

    onMessageButtonClick()
  }

  return (
    <div className={CLASS_NAME}>
      <div className='schedule-section'>
        <p className='title'>Schedule</p>
        <div className='buttons-container'>
          <div
            data-test-id='schedule-call-button'
            onClick={() => onScheduleClick(ScheduleButtonTaskType.FollowUpCall)}
          >
            <CallIcon />
            <p className='caption'>{LogActivityButtonsNames.ScheduleCall}</p>
          </div>
          <div
            data-test-id='schedule-appt-button'
            onClick={() => onScheduleClick(ScheduleButtonTaskType.Appointment)}
          >
            <ApptIcon />
            <p className='caption'>{LogActivityButtonsNames.ScheduleAppointment}</p>
          </div>
          <div
            data-test-id='schedule-reminder-button'
            onClick={() => onScheduleClick(ScheduleButtonTaskType.Reminder)}
          >
            <ReminderIcon />
            <p className='caption'>{LogActivityButtonsNames.ScheduleReminder}</p>
          </div>
        </div>
      </div>
      {
        isContactActionsShown && (
          <>
            <div className='vertical-divider' />
            <div className='contact-section'>
              <p className='title'>Contact</p>
              <div className='buttons-container'>
                <div
                  data-test-id='contact-email-button'
                  onClick={_onSendEmailButtonClick}
                  className={leadEmail == null ? 'disabled-button' : ''}
                  onMouseEnter={() => setIsEmailTooltipVisible(true)}
                  onMouseLeave={() => setIsEmailTooltipVisible(false)}
                >
                  {leadEmail == null &&
                    <Tooltip
                      className={TOOLTIP_CLASS_NAME}
                      isOpen={isEmailTooltipVisible}
                      title='No email address'
                    />}
                  <EmailIcon />
                  <p className='caption'>{LogActivityButtonsNames.ContactEmail}</p>
                </div>

                <div
                  data-test-id='contact-message-button'
                  onClick={_onMessageButtonClick}
                  className={leadPhoneNumber == null ? 'disabled-button' : ''}
                  onMouseEnter={() => setIsMessageTooltipVisible(true)}
                  onMouseLeave={() => setIsMessageTooltipVisible(false)}
                >
                  {leadPhoneNumber == null &&
                    <Tooltip
                      className={TOOLTIP_CLASS_NAME}
                      isOpen={isMessageTooltipVisible}
                      title='No phone number'
                    />}
                  <MessageIcon />
                  <p className='caption'>{LogActivityButtonsNames.ContactMessage}</p>
                </div>
              </div>
            </div>
          </>
        )
      }
      <div className='vertical-divider' />
      <div className='log-section'>
        <p className='title'>Log</p>
        <div className='buttons-container'>
          <div
            data-test-id='log-followup-button'
            onClick={() => onActivityClick(ActivityButtonTaskType.FollowUpCall)}
          >
            <CallIcon />
            <p className='caption'>{LogActivityButtonsNames.LogCall}</p>
          </div>
          <div
            data-test-id='log-visit-button'
            onClick={() => onActivityClick(ActivityButtonTaskType.Visit)}
          >
            <StoreVisitIcon />
            <p className='caption'>{LogActivityButtonsNames.LogStoreVisit}</p>
          </div>
          <div
            data-test-id='log-other-button'
            onClick={() => onActivityClick(ActivityButtonTaskType.Other)}
          >
            <OtherIcon />
            <p className='caption'>{LogActivityButtonsNames.LogOther}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogActivityButtons
