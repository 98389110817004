import { type FC, type ReactNode, useCallback } from 'react'

import {
  SubmenuVisibilityCtx,
  SubmenuSetVisibilityCtx
} from './contexts'

export interface PageOverlaySettingsProps {
  children?: ReactNode | ReactNode[]
  isSubmenuVisible: boolean
  setSubmenuVisible?: (isVisible: boolean) => void
}

const PageOverlaySettingsProvider: FC<PageOverlaySettingsProps> = ({
  children,
  isSubmenuVisible,
  setSubmenuVisible: _setSubmenuVisible
}) => {
  const setSubmenuVisible = useCallback((value: boolean) => {
    _setSubmenuVisible?.(value)
  }, [])

  // ========================================== //

  return (
    <SubmenuSetVisibilityCtx.Provider value={setSubmenuVisible}>
      <SubmenuVisibilityCtx.Provider value={isSubmenuVisible}>
        {children}
      </SubmenuVisibilityCtx.Provider>
    </SubmenuSetVisibilityCtx.Provider>
  )
}

export default PageOverlaySettingsProvider
