import { UNASSIGNED_LEAD_ID } from 'constants/constants'

import { type TaskTotalAmountsPayload, TaskStateEnum, TaskFilterEnum } from 'api/types'
import type { KeyVal } from 'types'
import type {
  TaskColumnsFilters,
  TaskListFilters,
  TaskListFiltersForApi
} from './types'

export const serializeAssignedToData = (assigneeIds: number[]): TaskListFiltersForApi['assignedTo'] | null => {
  const assignedToIds = assigneeIds ?? []
  const unassignedIdx = assignedToIds.findIndex(id => id === UNASSIGNED_LEAD_ID)
  const isFilterChosen = assignedToIds.length !== 0
  const areUnassigned = unassignedIdx > -1

  const resolvedAssignedToIds = assignedToIds.filter(id => id > 0)

  return isFilterChosen ? { areUnassigned, assignedToIds: resolvedAssignedToIds } : null
}

const serializeCommonData = ({
  assigneeIds,
  leadStatusIds
}: Partial<TaskColumnsFilters>): Partial<TaskTotalAmountsPayload> => {
  const assignedToFilter = serializeAssignedToData(assigneeIds ?? [])

  return {
    leadStatusIds: leadStatusIds,
    assignedTo: assignedToFilter
  }
}

export const serializeFilters = ({
  taskStateEnum,
  taskFilterEnum,
  ...commonProps
}: TaskListFilters): KeyVal => ({
  taskStateEnum: TaskStateEnum[taskStateEnum],
  taskFilterEnum: TaskFilterEnum[taskFilterEnum],
  ...serializeCommonData(commonProps)
})
