import { type FC } from 'react'
import { cnx, formatCurrencyAccounting } from '@carfluent/common'

import { Routes } from 'constants/route_helper'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'
import Header from 'components/dashboard/Header'
import NumberIndicator from 'components/dashboard/NumberIndicator'

import ProgressChart from './components/ProgressChart'
import useDashboardDeals from './hook'
import { DEALS_FILTERS } from './constants'
import _CLASS_NAME from './styles'

export interface DealsProps {
  className?: string
}

const Deals: FC<DealsProps> = ({ className }) => {
  const {
    data,
    dateRange,
    isLoading,
    onChangeDateRange
  } = useDashboardDeals()

  const CLASS_NAME = cnx(
    'g-panel',
    'g-panel--complete',
    'g-panel--no-shadow',
    _CLASS_NAME,
    className
  )

  // ========================================== //

  return (
    <div className={CLASS_NAME}>
      <Header
        dateRange={dateRange}
        dateRangeOptions={DEALS_FILTERS}
        isLoading={isLoading}
        onChangeDateRange={onChangeDateRange}
        routes={Routes.Deals}
        title='Deals'
      />

      <div className='deals-content'>
        <div className='deals-fields'>
          <NumberIndicator
            className='deals-field'
            isLoading={isLoading}
            label='Cars sold'
            value={data?.carsSold ?? 0}
          />

          <NumberIndicator
            className='deals-field'
            formatter={formatCurrency}
            isLoading={isLoading}
            label='Total gross'
            value={data?.totalGross ?? 0}
          />

          <NumberIndicator
            className='deals-field'
            formatter={formatCurrency}
            isLoading={isLoading}
            label='Front profit'
            value={data?.frontProfit ?? 0}
          />

          <NumberIndicator
            className='deals-field'
            formatter={formatCurrency}
            isLoading={isLoading}
            label='Back profit'
            value={data?.backProfit ?? 0}
          />
        </div>

        <div className='deals-sales-target'>
          <BlockSkeleton className='sales-target-header' isLoading={isLoading}>
            <h6>Sales target</h6>
          </BlockSkeleton>

          <ProgressChart
            isLoading={isLoading}
            progress={data?.carsSoldProgress ?? 0}
            salesTarget={data?.salesTarget ?? 0}
          />
        </div>
      </div>
    </div>
  )
}

export default Deals

// ========================================== //

const AMOUNT_FORMAT_CONFIG = {
  allowNegative: true,
  emptyValues: '$0.00'
}

const formatCurrency: typeof formatCurrencyAccounting = (x) => {
  return formatCurrencyAccounting(x, AMOUNT_FORMAT_CONFIG)
}
