import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  root: {
    minWidth: '344px',
    borderRadius: '12px'
  },
  standardError: {
    backgroundColor: 'rgb(211, 47, 47)',

    '& .MuiAlert-icon': {
      display: 'none'
    },

    '& .MuiAlert-message': {
      fontFamily: 'inherit',
      color: '#fff'
    },

    '& .MuiAlert-action': {
      color: '#fff'
    }
  },

  standardSuccess: {
    background: 'rgba(0, 0, 0, 0.87)',

    '& .MuiAlert-icon': {
      display: 'none'
    },

    '& .MuiAlert-message': {
      fontFamily: 'inherit',
      color: '#fff'
    },

    '& .MuiAlert-action': {
      color: '#fff'
    }
  }
})
