import { css } from '@emotion/css'

export default css(`  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: default;

  &.cf-tooltip-item {
    display: grid;
    grid-template-columns: 120px minmax(240px, max-content);
    grid-template-rows: 1fr;
    column-gap: 16px;
    row-gap: 16px;
    align-items: center;

    min-width: 366px;
    max-width: 480px;
    width: fit-content;
    min-height: 48px;
    padding: 0 16px 0 0;
  }

  a {
    display: block;
  }

  a > p {
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #458FFF;
    width: fit-content;
    white-space: nowrap;
  }

  a:hover {
    text-decoration: none;
  }
`)
