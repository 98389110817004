import { css } from '@emotion/css'
import { px, Responsive } from 'constants/constants'

export default css`
  display: grid;
  grid-auto-rows: min-content;
  gap: 24px;
  width: 423px;

  .title-wrapper {
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.5px;
    margin-bottom: 30px;

    @media screen and (max-width: ${px(Responsive.Tablet)}) {
      font-size: 34px;
      line-height: 36px;
      margin-bottom: 16px;
    }
  }

  .show-hide {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.38);
    cursor: pointer;
  }

  .root-form-helper-text {
    color: #B00020;
    padding-left: 12px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    position: absolute;
    bottom: -15px;
  }

  .link-reset {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto, sans-serif;
    font-weight: 600;
    margin-left: 9px;
    cursor: pointer;
  }

  .helper { margin-top: 8px;}
  .password-error-message { margin-top: 4px; }
  .submit-btn span { text-transform: uppercase; }
`
