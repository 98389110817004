import type { FC } from 'react'
import { useState } from 'react'
import { Button, UI, Loader } from '@carfluent/common'

import type { ReconVehicleLogTimeline } from 'api/types'

import LoaderSkeleton from './components/LoaderSkeleton'

import Comment from '../Comment'

import CLASS_NAME from './styles'

const { TextArea } = UI
const MAX_AREA_ROWS = 3

export interface TimelineProps {
  onAddComment: (body: string) => Promise<void>
  logs: ReconVehicleLogTimeline
  isLoading: boolean
  initLoading: boolean
}

const Timeline: FC<TimelineProps> = ({
  isLoading,
  onAddComment,
  initLoading,
  logs
}) => {
  const [value, setValue] = useState('')

  const onSendComment = async (): Promise<void> => {
    await onAddComment(value)
    setValue('')
  }

  if (initLoading) {
    return <LoaderSkeleton />
  }

  return (
    <div className={CLASS_NAME}>
      <p className='cf-timeline-title'>Timeline</p>

      <TextArea
        id='cf-comment-input'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder='Your comment'
        maxRows={MAX_AREA_ROWS}
      />

      <div className='cf-add-btn-wrapper'>
        <Button
          className='cf-add-btn'
          variant='text'
          onClick={onSendComment}
          isDisabled={value.trim() === '' || isLoading}
        >
          {isLoading ? <Loader size='small' color='dark' /> : 'ADD COMMENT'}
        </Button>
      </div>

      <div className='cf-comment-list'>
        {logs.length > 0
          ? logs.map(({ date, logs }, i) => (
            <div key={`${date}_${i}`} className='cf-comments-block'>
              <div className='cf-comment-date'>{date}</div>
              {logs.map(log => {
                const userName = log.logData.userFirstName == null &&
                  log.logData.userLastName == null
                  ? 'System'
                  : `${log.logData.userFirstName ?? ''} ${log.logData.userLastName ?? ''}`

                return (
                  <Comment
                    key={log.id}
                    id={log.id}
                    logTypeId={log.logTypeId}
                    logData={log.logData}
                    body={log.logData.stepName ?? log.logData.comment ?? ''}
                    createdDate={log.date}
                    userName={userName}
                  />
                )
              })}
            </div>
          ))
          : <div className='cf-no-data-block'>No records yet</div>}
      </div>
    </div>
  )
}

export default Timeline
