import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { type CoverageType } from 'types'
import { RadioGroup } from 'components/common/RadioGroup'
import RadioLabelControl from 'components/common/RadioLabelControl'

import { type CoverageTypeSelectorProps } from './types'
import CLASS_NAME from './styles'

export const COVERAGE_TYPE_LABELS: Record<CoverageType, string> = {
  PEN: 'PEN F&I list',
  Manual: 'Manual input'
}

const CoverageTypeSelector: FC<CoverageTypeSelectorProps> = ({
  className,
  disabled = false,
  onChange,
  value
}) => {
  return (
    <RadioGroup
      className={cnx(CLASS_NAME, 'coverage-type-selector', className)}
      disabled={disabled}
      id='coverage'
      onChange={(_, value) => {
        onChange(value as CoverageType)
      }}
      value={value?.coverageType}
    >
      <RadioLabelControl
        disabled={disabled || (value?.forms.PEN == null)}
        label={COVERAGE_TYPE_LABELS.PEN}
        value='PEN'
      />

      <RadioLabelControl
        disabled={disabled || (value?.forms.Manual == null)}
        label={COVERAGE_TYPE_LABELS.Manual}
        value='Manual'
      />
    </RadioGroup>
  )
}

export default CoverageTypeSelector
