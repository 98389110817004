import { useCallback } from 'react'

import { type KeyVal } from 'types'
import { type PaginatedResult, type TransactionListItem, TransactionsListGetRequestDto } from 'api/types'
import { type UseTableApiReturn } from 'hooks/useTableApi/types'
import useTableApi from 'hooks/useTableApi'
import AccountingApiProvider from 'api/accounting.api'

import { type TransactionsListFilters } from './types'
import { DEFAULT_SORTING, getDefaultFilters } from './constants'
import { serializeFilters } from './serializer'

const DEFAULT_FILTERS = getDefaultFilters()

const useTransactionsListTable = (): UseTableApiReturn<TransactionListItem, TransactionsListFilters> => {
  const getTransactionsList = useCallback(async (_payload: KeyVal): Promise<PaginatedResult<TransactionListItem>> => {
    try {
      const payload = _payload as TransactionsListGetRequestDto // AZ-TODO: improve generics for filters in useTableApi
      return await AccountingApiProvider.getTransactions(payload)
    } catch (err) {
      console.error(err)
      return { items: [], count: 0 }
    }
  }, [])

  return useTableApi<TransactionListItem, TransactionsListFilters, TransactionListItem>({
    defaultFilters: DEFAULT_FILTERS,
    defaultSorting: DEFAULT_SORTING,
    getList: getTransactionsList,
    serializeFilters,
    shouldRunOnCall: true,
    shouldScrollToSpinner: true
  })
}

export default useTransactionsListTable
