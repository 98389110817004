import { type FC, useContext, useEffect, useMemo, useLayoutEffect, useRef } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { cnx } from '@carfluent/common'

import BackToTop from 'components/common/BackToTop'
import { ContentLayoutWidth as CLW } from 'constants/constants'
import RoutesWithPagesOverlay from './RoutesWithPagesOverlay'
import { PAGE_OVERLAY_ID } from './constants'
import { overlayContentStyles } from './styles'
import { SubmenuVisibilityCtx } from 'components/PageOverlay/contexts'

export {
  RoutesWithPagesOverlay
}

const DEFAULT_MIN_CONTENT_WIDTH = CLW.MinContent
const DEFAULT_MAX_CONTENT_WIDTH = CLW.MaxContent

interface PageOverlayProps {
  minContentWidth?: number
  maxContentWidth?: number
  className?: string
  overlayedPageWithSubmenu?: boolean
  /**
   * DD-NOTE: probably trying to keep previous value before DOM mutation is not reliable.
   * Sometimes body.overflow might still have not desired value and then we're screwed.
   *
   * We have a choice because for Recon list page we probably would need 'hidden'
   * instead of 'auto'
   */
  prevBodyOverflow?: 'hidden' | 'auto'
  showBackToTop?: boolean
  backToTopClassName?: string
  withWhiteBackground?: boolean
}

/**
 * Used to wrap all feature (deals, inventory, crm, ...) sub-routes
 * which should be nested under the feature's main page.
 * Feature's main page in our case is a page with filters and grid.
 */
export const PageOverlay: FC<PageOverlayProps> = ({
  minContentWidth = DEFAULT_MIN_CONTENT_WIDTH,
  maxContentWidth = DEFAULT_MAX_CONTENT_WIDTH,
  className,
  overlayedPageWithSubmenu: _overlayedPageWithSubmenu = false,
  prevBodyOverflow = 'auto',
  showBackToTop = false,
  backToTopClassName,
  withWhiteBackground = false
}) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const { pathname } = useLocation()

  /**
   * AZ-NOTE:
   * Since related component `RoutesWithPageOverlay` sets a pair of `relative` + `absolute` divs,
   * scrolling of the internal element does not trigger `window.scroll` event.
   * Instead, it triggers `element.scroll` event.
   * So, to make our `<BackToTop />` button work for such nested routes - we need to propagate
   * a ref of the scrollable parent, and use it as a scroll event source.
   */
  const refScrollableContainer = useRef<HTMLDivElement>(null)

  const contentClassName = useMemo(() => (
    overlayContentStyles(minContentWidth, maxContentWidth))
  , [minContentWidth, maxContentWidth])

  const overlayedPageWithSubmenu = useContext(SubmenuVisibilityCtx) ?? _overlayedPageWithSubmenu

  const classes = cnx(
    'cf-details-view-overlay',
    className,
    overlayedPageWithSubmenu && 'with-submenu',
    withWhiteBackground && 'with-white-background'
  )

  /**
   * we want to make global style changes before our content starts rendering.
   * It might not be critical but ought to give better UX of all these transformations.
   */
  useLayoutEffect(() => {
    /**
     * we need to add a special classname on #root to control some styles
     * in page content layout and main header.
     * all general styles are described in Global Styles.
     */

    /**
     * we need to prevent body scrolling so that the underlying Grid page is not scrolled
     * and refetched in the background.
    */
    const root = document.getElementById('root')

    requestAnimationFrame(() => {
      document.body.style.overflowY = 'hidden'
      root?.classList.add(overlayedPageWithSubmenu ? 'with-submenu-pages-overlay' : 'with-pages-overlay')
    })

    return () => {
      /**
       * All DOM manipulations wrapped in requestAnimationFrame - it is important.
       * Without that noticed bugs that overflow was not removed
       * despite the appropriate code was reached.
       */
      requestAnimationFrame(() => {
        document.body.style.overflowY = prevBodyOverflow
        root?.classList.remove(overlayedPageWithSubmenu ? 'with-submenu-pages-overlay' : 'with-pages-overlay')
      })
    }
  }, [prevBodyOverflow, overlayedPageWithSubmenu])

  /**
   * after each nav it is better to start from top.
   */
  useEffect(() => {
    const el = scrollRef.current
    if (el != null) {
      requestAnimationFrame(() => el.scrollIntoView())
    }
  }, [pathname])

  return (
    <div
      id={PAGE_OVERLAY_ID}
      className={classes}
      ref={refScrollableContainer}
    >
      {/*
        scrollAnchor is a pixel in the top left corner.
        It showed to be quite stable in serving as an anchor to restore scroll to top left corner.
      */}
      <div id='scroll-anchor' ref={scrollRef} />
      <div className={contentClassName}>
        {showBackToTop && <BackToTop className={backToTopClassName} refScrollable={refScrollableContainer} />}
        <Outlet />
      </div>
    </div>
  )
}
