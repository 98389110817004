import type { FC } from 'react'

import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

import CLASS_NAME from './styles'

const LoaderSkeleton: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <div className='skeleton-toggle-section'>
        <TextSkeleton className='skeleton-section-title' />

        <TextSkeleton className='skeleton-section-title' />
      </div>
      <div className='skeleton-dropdown-section'>
        <TextSkeleton className='skeleton-section-title' />
      </div>
    </div>
  )
}

export default LoaderSkeleton
