import { css } from '@emotion/css'

export default css(`
  .cf-bold-text {
    font-weight: 500;
    color: #101010;
  }

  .cf-grey-text {
    color: rgba(33, 33, 33, 0.8)!important;
  }
`)
