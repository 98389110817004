import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLoader, useModal } from '@carfluent/common'
import type { ColumnDef } from '@carfluent/common/dist/UI/Table/types'
import type { Row } from '@tanstack/react-table'

import type {
  AccountOrInvite,
  DealerAccountWithRoleId,
  DealerInvitesPayload,
  DictionaryItem,
  UpdateDealerAccountPayload
} from 'api/types'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import getColumnDefinitions from 'pages/UserManagement/columns'
import IdentityApiProvider from 'api/identity.api'
import { isInviteGuard, mapDealerAccountsAndInvites, mapDealerInvite, parseDealerAccounts } from './helpers'

enum Messages {
  FailDeletion = 'Deletion failed',
  SuccessDeletion = 'User has been deleted.'
}

export type UserRow = Row<AccountOrInvite>

export interface UseUserManagementReturn {
  accountsAndInvites: AccountOrInvite[]
  dealers: DictionaryItem[]
  dealerAccount: DealerAccountWithRoleId | null
  columns: Array<ColumnDef<AccountOrInvite>>
  isLoading: boolean
  isRemoveUserModalOpen: boolean
  isInviteUserModalOpen: boolean
  isDealerAccountDetailsModalOpen: boolean
  onOpenInviteUserModal: () => void
  onOpenDealerAccountDetailsModal: (row: UserRow) => void
  onCloseInviteUserModal: () => void
  onCloseRemoveUserModal: () => void
  onCloseDealerAccountDetailsModal: () => void
  onSubmitDeleteAccount: (dealerAccountId: number) => Promise<void>
  onSubmitDeleteInvite: () => Promise<void>
  onUpdateDealerAccount: (dealerAccountId: number, payload: UpdateDealerAccountPayload) => Promise<void>
  patchDealerInvites: (payload: DealerInvitesPayload) => Promise<void>
}

const useUserManagement = (): UseUserManagementReturn => {
  const { isLoading, startLoader, stopLoader } = useLoader()
  const { showAlert } = useCustomSnackbar()

  const [accountsAndInvites, setAccountsAndInvites] = useState<AccountOrInvite[]>([])
  const [dealers, setDealers] = useState<DictionaryItem[]>([])
  const [dealerAccounts, setDealerAccounts] = useState<DealerAccountWithRoleId[]>([])

  const [rowForDeletion, setRowForDeletion] = useState(-1)
  const [dealerAccount, setDealerAccount] = useState<DealerAccountWithRoleId | null>(null)

  const {
    isModalOpen: isRemoveUserModalOpen,
    onOpenModal: _onOpenRemoveUserModal,
    onCloseModal: _onCloseRemoveUserModal
  } = useModal()

  const {
    isModalOpen: isInviteUserModalOpen,
    onOpenModal: onOpenInviteUserModal,
    onCloseModal: onCloseInviteUserModal
  } = useModal()

  const {
    isModalOpen: isDealerAccountDetailsModalOpen,
    onOpenModal: _onOpenDealerAccountDetailsModal,
    onCloseModal: onCloseDealerAccountDetailsModal
  } = useModal()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const getDealerAccountInvites = useCallback(async (): Promise<void> => {
    try {
      startLoader()

      const accAndInvites = await IdentityApiProvider.getDealerAccountInvites()
      setAccountsAndInvites(mapDealerAccountsAndInvites(accAndInvites))
      setDealerAccounts(parseDealerAccounts(accAndInvites))
    } catch {
      showAlert('Invites fetching failed')
    } finally {
      stopLoader()
    }
  }, [showAlert, startLoader, stopLoader])

  const getAvailableDealers = useCallback(async (): Promise<void> => {
    try {
      startLoader()

      const { items: dealers } = await IdentityApiProvider.getAvailableDealers()
      setDealers(dealers)
    } catch {
      showAlert('Dealers fetching failed')
    } finally {
      stopLoader()
    }
  }, [showAlert, startLoader, stopLoader])

  const patchDealerInvites = useCallback(async (payload: DealerInvitesPayload): Promise<void> => {
    try {
      startLoader()
      const res = await IdentityApiProvider.postDealerInvites(payload)
      const inviteIds = new Set(accountsAndInvites
        .filter(isInviteGuard)
        .map(item => item.id))
      const newInvites = res.filter(item => !inviteIds.has(item.id)).map(mapDealerInvite)

      if (newInvites.length > 0) {
        setAccountsAndInvites(prev => [...prev, ...newInvites])
      }
    } catch {
      showAlert('Patch dealer invites failed')
    } finally {
      stopLoader()
    }
  }, [showAlert, startLoader, stopLoader])

  const onOpenDealerAccountDetailsModal = useCallback((row: UserRow) => {
    if (isInviteGuard(row.original)) {
      return
    }

    const dealerAccountToOpen = dealerAccounts.find(({ id }) => id === row?.original.id) ?? null

    setDealerAccount(dealerAccountToOpen)
    _onOpenDealerAccountDetailsModal()
  }, [_onOpenDealerAccountDetailsModal, dealerAccounts])

  const onSubmitDeleteAccount = useCallback(async (dealerAccountId: number): Promise<void> => {
    try {
      const res = await IdentityApiProvider.deleteDealerAccount(dealerAccountId)
      if (res != null) {
        setAccountsAndInvites(prev => prev.filter(item => isInviteGuard(item) || !item.dealerIds.includes(dealerAccountId)))
      }
      await getDealerAccountInvites()
      showAlert(Messages.SuccessDeletion, { variant: 'success' })
    } catch {
      showAlert(Messages.FailDeletion)
    } finally {
      onCloseDealerAccountDetailsModal()
    }
  }, [
    getDealerAccountInvites,
    showAlert, onCloseDealerAccountDetailsModal
  ])

  const onUpdateDealerAccount = useCallback(
    async (dealerAccountId: number, payload: UpdateDealerAccountPayload): Promise<void> => {
      try {
        await IdentityApiProvider.updateDealerAccount(dealerAccountId, payload)
        await getDealerAccountInvites()
      } catch {
        showAlert('Role change failed')
      } finally {
        onCloseDealerAccountDetailsModal()
      }
    }, [
      getDealerAccountInvites,
      onCloseDealerAccountDetailsModal, showAlert
    ])

  const onSubmitDeleteInvite = useCallback(async (): Promise<void> => {
    try {
      const { id } = accountsAndInvites[rowForDeletion]
      const res = await IdentityApiProvider.deleteDealerInvite(id)

      if (res != null) {
        setAccountsAndInvites(prev => prev.filter(item => !isInviteGuard(item) || item.id !== id))
      }
      showAlert(Messages.SuccessDeletion, { variant: 'success' })
    } catch {
      showAlert(Messages.FailDeletion)
    }
  }, [rowForDeletion, showAlert])

  const onDeleteInvite = useCallback((rowIdx: number) => {
    setRowForDeletion(rowIdx)
    _onOpenRemoveUserModal()
  }, [_onOpenRemoveUserModal])

  const onCloseRemoveUserModal = useCallback(() => {
    setRowForDeletion(-1)
    _onCloseRemoveUserModal()
  }, [_onCloseRemoveUserModal])

  // ========================================== //
  //                    EFFECTS                 //
  // ========================================== //

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      try {
        startLoader()
        await Promise.all([
          getDealerAccountInvites(),
          getAvailableDealers()
        ])
      } catch {
        showAlert('Invites fetching failed')
      } finally {
        stopLoader()
      }
    }

    void runEffect()
  }, [
    showAlert, startLoader, stopLoader,
    getDealerAccountInvites, getAvailableDealers
  ])

  // ========================================== //

  const columns = useMemo(() =>
    getColumnDefinitions(dealers, onDeleteInvite),
  [dealers, onDeleteInvite])

  return {
    accountsAndInvites,
    dealers,
    dealerAccount,
    columns,
    isLoading,
    isRemoveUserModalOpen,
    isInviteUserModalOpen,
    isDealerAccountDetailsModalOpen,
    onOpenInviteUserModal,
    onOpenDealerAccountDetailsModal,
    onCloseInviteUserModal,
    onCloseDealerAccountDetailsModal,
    onCloseRemoveUserModal,
    onSubmitDeleteInvite,
    onSubmitDeleteAccount,
    onUpdateDealerAccount,
    patchDealerInvites
  }
}

export default useUserManagement
