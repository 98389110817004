import { type FC } from 'react'
import { Button } from '@carfluent/common'

import Table from 'components/common/Table'
import InviteUserModal from 'components/userManagement/InviteUserModal'
import RemoveUserModal from 'components/userManagement/RemoveUserModal'
import DealerAccountDetailsModal from 'components/userManagement/DealerAccountDetailsFormModal'

import useUserManagement from './hook'

import CLASS_NAME from './styles'

const NO_ITEMS = 'No accounts or invites yet.'

const UserManagement: FC = () => {
  const {
    accountsAndInvites,
    dealers,
    dealerAccount,
    columns,
    isLoading,
    isRemoveUserModalOpen,
    isInviteUserModalOpen,
    isDealerAccountDetailsModalOpen,
    onOpenInviteUserModal,
    onOpenDealerAccountDetailsModal,
    onCloseInviteUserModal,
    onCloseDealerAccountDetailsModal,
    onCloseRemoveUserModal,
    onSubmitDeleteInvite,
    onSubmitDeleteAccount,
    patchDealerInvites,
    onUpdateDealerAccount
  } = useUserManagement()

  return (
    <div className={CLASS_NAME}>
      <div className='title-and-button'>
        <p>Create new users, customize user permissions, and remove users from CarFluent.</p>
        <Button
          onClick={onOpenInviteUserModal}
          isLoading={isLoading}
        >
          INVITE
        </Button>
      </div>

      <InviteUserModal
        dealers={dealers}
        isLoading={isLoading}
        isOpen={isInviteUserModalOpen}
        onClose={onCloseInviteUserModal}
        onCancel={onCloseInviteUserModal}
        patchDealerInvites={patchDealerInvites}
      />

      <RemoveUserModal
        isOpen={isRemoveUserModalOpen}
        onClose={onCloseRemoveUserModal}
        onCancel={onCloseRemoveUserModal}
        onSubmit={onSubmitDeleteInvite}
      />

      <DealerAccountDetailsModal
        dealers={dealers}
        dealerAccount={dealerAccount}
        isOpen={isDealerAccountDetailsModalOpen}
        onClose={onCloseDealerAccountDetailsModal}
        onSubmit={onUpdateDealerAccount}
        onDeleteDealerAccount={onSubmitDeleteAccount}
      />

      <Table
        columns={columns}
        data={accountsAndInvites}
        emptyTableMessage={NO_ITEMS}
        isLoading={isLoading}
        onRowClick={onOpenDealerAccountDetailsModal}
      />
    </div>
  )
}

export default UserManagement
