import {
  type DealerSettingsModel
} from 'api/types/responses'

export enum DaysOfWeek {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday'
}

export type DayOfWeekTimes = `${DaysOfWeek}OpeningTime` | `${DaysOfWeek}ClosingTime`
export type DealerBusinessHours = Record<DayOfWeekTimes, string | null>

export interface DealerData {
  dealerName: string
  address1: string
  city: string
  state: string
  zipCode: string
  phone: string
  email: string
  leadCmsEmails: string[]
  dealerFeeMarkupSettings: DealerSettingsModel
  googleMapsLink: string
  logoUrl: string
  termsUrl: string
  dealerBusinessHours: DealerBusinessHours
  subscriptionTypeName: string
  locationLatitude?: google.maps.LatLngLiteral['lat']
  locationLongitude?: google.maps.LatLngLiteral['lng']
}

export enum ViewDealerRoles {
  DealerSuperAdmin = 1,
  DealerAdmin = 2,
  /**
   * DD-NOTE: add these roles when they are implemented on the backend
   */
  // CarfluentAdmin = 3,
  // Customer = 4,
  Sales = 5,
  BDC = 6,
  Buyer = 7,
  Mechanic = 8
}

export enum UserRoles {
  SuperAdmin = 'Dealer Super Admin',
  Admin = 'Dealer Admin',
  Sales = 'Sales',
  BDC = 'BDC',
  Buyer = 'Buyer',
  Mechanic = 'Mechanic'
}

export interface DealerAccount {
  id: number
  dealerIds: number[]
  dealerFirstName: string | null
  dealerLastName: string | null
  dealerEmail: string
  dealerRoleName: string
  phoneNumber: string | null
}

export type DealerAccountWithRoleId = Omit<DealerAccount, 'dealerRoleName' | 'dealerEmail'> & {
  roleId: ViewDealerRoles | null
  phoneNumber?: string | null
}

export interface DealerInvite {
  id: number
  dealerIds: number[]
  email: string | null
  role: string
  isInvite: boolean
}

export type AccountOrInvite = (DealerAccount | DealerInvite) & {
  email: string | null
  dealerRole?: ViewDealerRoles | null
  name?: string | null
  dealership?: string | null
  phoneNumber?: string | null
}

export interface DealerAccountInvites {
  dealerAccounts: DealerAccount[]
  dealerInvites: DealerInvite[]
}

export interface DealerInvitesPayload {
  emails: string[]
  roleId: ViewDealerRoles
}

export interface UpdateDealerAccountPayload {
  dealerFirstName: string
  dealerLastName: string
  phoneNumber: string | null
  roleId: ViewDealerRoles
  dealerIds: number[]
}

export interface CreateDealerAccountPayload {
  firstName: string
  lastName: string
  password: string
  token: string
  phoneNumber: number | null
}

/**
 * @deprecated - use DealerProductCoverageTemplate
 * **/
export interface CoverageTemplate {
  id: number
  name: string
  imageUrl: string
  imageUrlSmall: string
  description: string
  isSelected: boolean
}

export type CoverageTemplates = CoverageTemplate[]

export interface CloudAccessTokenDto {
  token: string
  expirationDate: string
}

export const CLOUD_ACCESS_TOKEN = 'CLOUD_ACCESS_TOKEN'
