import type { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Button, parsers } from '@carfluent/common'

import { DealAccountingState } from 'api/types'
import { DealView } from 'constants/route_helper'
import { formatDate, toDateOrNull } from 'utils/parse_date'

import { Messages } from './constants'

export const getCarStateBannerText = (
  isCarSold: boolean,
  dealAccountingState: number | null,
  byUser: {
    id: number
    dealerFirstName: string
    dealerLastName: string
  } | null,
  soldDate: string | null,
  deletedDate: string | null
): ReactNode => {
  const parsedDate = soldDate != null ? toDateOrNull(soldDate) : parsers.parseDateStringUTC(deletedDate)
  const date = parsedDate != null ? formatDate(parsedDate, 'MM/dd/yy') : null

  if (!isCarSold) {
    return `${Messages.CarDeleted}${date != null ? ` on ${date}` : ''}${byUser != null ? ` by ${byUser.dealerFirstName} ${byUser.dealerLastName}` : ''}.`
  }

  return (
    <>
      {Messages.CarSold}{date != null ? ` on ${date}` : ''}{byUser != null ? ` by ${byUser.dealerFirstName} ${byUser.dealerLastName}` : ''}. <br />
      {dealAccountingState === DealAccountingState.Recorded && Messages.DealRecorder}
    </>
  )
}

export const getCarStateBannerAdornment = (
  isCarSold: boolean,
  dealAccountingState: number | null,
  onOpenTransaction: () => void,
  isDealLoaded?: boolean,
  dealId?: number | null
): ReactNode => {
  if (!isCarSold || dealId == null) {
    return null
  }

  return (
    <div className='car-state-banner-adornment'>
      {dealAccountingState === DealAccountingState.Recorded && (
        <Button
          variant='text'
          className='view-transaction-button'
          onClick={onOpenTransaction}
        >
          View transaction
        </Button>
      )}

      {
        isDealLoaded === true && (
          <Link to={DealView(dealId)}>
            View deal
          </Link>
        )
      }
    </div>
  )
}
