import { type FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Banner, Modal } from '@carfluent/common'

import { MODAL_MIN_WIDTH } from 'constants/constants'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import DocumentField from './components/DocumentField'
import useRequestDocuments, { REQUESTED_DOCUMENTS, DocumentsIds } from './hook'
import CLASS_NAME from './styles'

interface RequestDocumentsProps {
  open: boolean
  onClose: () => void
}

const RequestDocuments: FC<RequestDocumentsProps> = (props) => {
  const {
    isLoading,
    onClose,
    onOtherDocumentsChange,
    onSubmit,
    onToggleCheckbox,
    selectedDocuments,
    otherDocError,
    otherDocTouched,
    otherDocValue,
    apiError
  } = useRequestDocuments(props)

  const renderFooter = (): JSX.Element => {
    return (
      <CancelSubmitActionsFooter
        isLoading={isLoading}
        onSubmit={onSubmit}
        onClose={onClose}
        submitTitle='SEND REQUEST'
      />
    )
  }

  return (
    <Modal
      title='Request documents'
      isOpen={props.open}
      closeByEsc
      closeByBackdropClick={false}
      renderFooterActions={renderFooter}
      onClose={onClose}
      minWidth={MODAL_MIN_WIDTH}
    >
      <div className={CLASS_NAME}>
        {apiError != null && <Banner message={apiError} type='error' />}

        <p className='cf-form-title'>
          List of requested documents will be sent to the Customer via email.
        </p>

        {REQUESTED_DOCUMENTS.map(item => (
          <DocumentField
            key={item.id}
            id={item.id}
            label={item.name}
            isChecked={selectedDocuments[item.id]}
            isOther={item.id === DocumentsIds.OtherDocuments}
            onInputChange={onOtherDocumentsChange}
            onToggleCheckbox={onToggleCheckbox}
            inputError={otherDocError}
            inputTouched={otherDocTouched}
            inputValue={otherDocValue}
          />
        ))}
      </div>
    </Modal>
  )
}

export default observer(RequestDocuments)
