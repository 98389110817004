import { DictionaryItem } from 'api/types'

export const getOptionValue = (option: DictionaryItem | null): string => option?.name ?? ''

export enum Labels {
  Make = 'Make',
  Model = 'Model',
  Trim = 'Trim'
}

export const DEFAULT_CAR_DETAILS_IDS = {
  make: 'make',
  model: 'model',
  trim: 'trim'
}

export const DEFAULT_VALUES = {
  make: null,
  model: null,
  trim: null
}
