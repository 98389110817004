import { type FC, type ReactNode } from 'react'
import { Banner, Button } from '@carfluent/common'
import { Link } from 'react-router-dom'

import { Routes } from 'constants/route_helper'
import _isClearedTransaction from 'utils/accounting/isClearedTransaction'
import _isReconciledTransaction from 'utils/accounting/isReconciledTransaction'
import { type AccountingFiscalYearsResponse } from 'api/types'

export interface BannersProps {
  costId: number | null
  closeYearError: string | null
  isLocked: boolean
  isSystemCreated: boolean
  lockedDate: Date | null
  lockedInfo: AccountingFiscalYearsResponse | null
  onOpenOtherCostJournalEntry?: (id: number) => void
  reconcileStatusId: number | null
  transactionId: number | null
}

export enum ReadonlyMessageType {
  ReconciledCost = 1,
  ClearedCost = 2,
  LockedYearError = 3,
  LockedYear = 4,
  LockedMonth = 5,
  OtherSystemCreated = 6
}

const Banners: FC<BannersProps> = ({
  costId,
  closeYearError,
  isLocked,
  isSystemCreated,
  lockedDate,
  lockedInfo,
  onOpenOtherCostJournalEntry,
  reconcileStatusId,
  transactionId
}) => {
  const hasCloseYearApiError = closeYearError != null

  if (costId == null && !hasCloseYearApiError) {
    return null
  }

  const isClearedCost = _isClearedTransaction(reconcileStatusId)
  const isReconciledCost = _isReconciledTransaction(reconcileStatusId)

  const isYearClosed = lockedInfo?.isClosed ?? false
  const lockYear = lockedInfo?.year ?? null
  const lockMonth = lockedDate?.toLocaleDateString('en-us', { month: 'long', year: 'numeric' })

  const isReadonlyConstBannerVisible =
    (closeYearError != null || isLocked) ||
    isClearedCost ||
    isReconciledCost ||
    isSystemCreated

  const readonlyMessageInfo = ((): { element: JSX.Element, messageType: ReadonlyMessageType } | null => {
    if (!isReadonlyConstBannerVisible) {
      return null
    }

    if (hasCloseYearApiError) {
      return {
        element: <div>{closeYearError}</div>,
        messageType: ReadonlyMessageType.LockedYearError
      }
    }

    if (isReconciledCost) {
      return {
        element: <div>This cost cannot be modified because it was reconciled.</div>,
        messageType: ReadonlyMessageType.ReconciledCost
      }
    }

    if (isClearedCost) {
      return {
        element: <div>This cost cannot be modified because it was cleared.</div>,
        messageType: ReadonlyMessageType.ClearedCost
      }
    }

    if (isLocked) {
      if (isYearClosed) {
        return {
          element: <div>{lockYear} year is closed.</div>,
          messageType: ReadonlyMessageType.LockedYear
        }
      } else {
        return {
          element: <div>{lockMonth} is locked. <br /> Unlock month in accounting settings if needed.</div>,
          messageType: ReadonlyMessageType.LockedMonth
        }
      }
    }

    return {
      element: <div>This cost was created via accounting as journal entry.</div>,
      messageType: ReadonlyMessageType.OtherSystemCreated
    }
  })()

  const readonlyBannerLink = ((): ReactNode => {
    if (readonlyMessageInfo?.messageType === ReadonlyMessageType.LockedMonth) {
      return (
        <Link to={Routes.AccountingGeneralSettings}>
          Settings
        </Link>
      )
    }

    if (isSystemCreated && (transactionId != null)) {
      return (
        <Button
          className='g-link-button'
          variant='text'
          onClick={() => {
            if (transactionId != null) {
              onOpenOtherCostJournalEntry?.(transactionId)
            }
          }}
        >
          View
        </Button>
      )
    }

    return null
  })()

  return (
    <Banner
      message={readonlyMessageInfo?.element}
      isOpen={isReadonlyConstBannerVisible}
      type={closeYearError != null ? 'error' : 'info'}
      endAdornment={readonlyBannerLink}
    />
  )
}

export default Banners
