import { IntakeInspectionQuestionCategoriesDto, VehicleIntakeInspectionDto, type VehicleInspectionSubmittedByUserDto } from 'api/types/responses'
import { formatUTCDateTimeForView } from 'utils/view_helper'
import { InspectionStatus, type VehicleInspectionQuestionPayload } from 'api/types'

import { DATE_FORMAT } from './constants'
import type { SectionProps } from './types'
import type { NavigationItemProps } from '../components/NavigationBlock'

export const getIntakeInspectionData = (values: SectionProps[], shouldFilterQuestion: boolean): SectionProps[] => {
  if (shouldFilterQuestion) {
    return values.map(group => ({
      ...group,
      questions: group.questions
        .filter(el => el.questionStateId === InspectionStatus.Fail)
    })).filter(el => el.questions.length)
  }

  return values
}

export const updateIntakeInspectionDate = (
  values: SectionProps[],
  data: VehicleInspectionQuestionPayload,
  questionId: number | string,
  questionCategoryId: number | string
): SectionProps[] => {
  return values?.map(el =>
    el.id === questionCategoryId
      ? {
          ...el,
          questions: el.questions.map(question =>
            question.id === questionId
              ? {
                  ...question,
                  ...data
                }
              : question
          )
        }
      : el
  )
}

export const updateNavigationData = (navigationData: NavigationItemProps[], intakeInspectionData: SectionProps[]): NavigationItemProps[] => {
  return navigationData.map(el => {
    let resolved = 0
    const questions = (intakeInspectionData?.find(item => item.id === el.id)?.questions ?? [])
    const additionalParams =
    questions.reduce((acc, item, i, arr) => {
      if (item.questionStateId !== null) {
        resolved++
      }

      return {
        pass: acc.pass + (item.questionStateId === InspectionStatus.Pass ? 1 : 0),
        fail: acc.fail + (item.questionStateId === InspectionStatus.Fail ? 1 : 0),
        comment: acc.comment + (item.comments === null || item.comments === '' ? 0 : 1)
      }
    }, {
      pass: 0,
      fail: 0,
      comment: 0
    })

    return ({
      ...el,
      ...additionalParams,
      percentage: Math.round((resolved / questions.length) * 100)
    })
  })
}

export const getSubmittedStatusLabel = (submittedDateTime: string | null, submittedByUser: VehicleInspectionSubmittedByUserDto | null): string => {
  return `Submitted on ${
    formatUTCDateTimeForView(submittedDateTime, DATE_FORMAT) ?? ''
  } by ${
    submittedByUser?.dealerFirstName ?? ''
  } ${
    submittedByUser?.dealerLastName ?? ''
  }`
}

export const filterCategoriesWithoutQuestions = (
  questionCategories: IntakeInspectionQuestionCategoriesDto[],
  intakeInspection: VehicleIntakeInspectionDto
): IntakeInspectionQuestionCategoriesDto[] => {
  return questionCategories.filter(el => intakeInspection.questions[el.id] !== undefined)
}
