import { type FC } from 'react'
import { useModal } from '@carfluent/common'

import SalesTaxEditor from './components/SalesTaxEditor'
import PopperTrigger from './components/PopperTrigger'
import { type UseSalesTaxEditorProps } from './components/SalesTaxEditor/types'

export interface SalesTaxInputProps extends Omit<UseSalesTaxEditorProps, 'isOpen' | 'onClose' | 'salesTaxAmount'> {
  error: string | null
  isManualSalesTax: boolean
  label: string
  touched: boolean | null
  value: UseSalesTaxEditorProps['salesTaxAmount']
}

/**
 * @deprecated - use `PercentOrAmountInput`
 */
export const SalesTaxInput: FC<SalesTaxInputProps> = ({
  getSalesTaxAmount,
  isManualSalesTax,
  label,
  salesTax,
  value,
  disabled,
  onToucheField,
  onChange,
  onSubmitSalesTax,
  salesTaxAmountId,
  salesTaxId
}) => {
  const { isModalOpen, onCloseModal, onOpenModal } = useModal()

  return (
    <PopperTrigger
      disabled={disabled}
      isManualSalesTax={isManualSalesTax}
      isOpen={isModalOpen}
      label={label}
      onChange={onChange}
      onClose={onCloseModal}
      onOpen={onOpenModal}
      salesTax={salesTax}
      salesTaxAmount={value ?? 0}
    >
      <SalesTaxEditor
        disabled={disabled}
        getSalesTaxAmount={getSalesTaxAmount}
        isManualSalesTax={isManualSalesTax}
        isOpen={isModalOpen}
        onChange={onChange}
        onClose={onCloseModal}
        onToucheField={onToucheField}
        onSubmitSalesTax={onSubmitSalesTax}
        salesTaxAmount={Number(value)}
        salesTaxAmountId={salesTaxAmountId}
        salesTax={salesTax}
        salesTaxId={salesTaxId}
      />
    </PopperTrigger>
  )
}

export default SalesTaxInput
