import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'

import type { DictionaryItems, User } from 'api/types'
import { getParsedToken } from 'services/storage.service'
import { getAssignedToMeLead, UNASSIGNED_LEAD } from 'constants/constants'

/**
 * AZ-TODO: remove, convert to local state
 */
export class LeadsListData {
  sources: DictionaryItems<string> = []
  temperatures: DictionaryItems<string> = []
  statuses: DictionaryItems<string> = []
  assignedTo: DictionaryItems<string> = []
  users: User[] = []

  setSources = (sources: DictionaryItems<string>): void => {
    this.sources = sources
  }

  setTemperatures = (temperatures: DictionaryItems<string>): void => {
    this.temperatures = temperatures
  }

  setStatuses = (statuses: DictionaryItems<string>): void => {
    this.statuses = statuses
  }

  setAssignedTo = (assignedTo: DictionaryItems<string>): void => {
    const currentId = Number(getParsedToken()?.sub)
    const assignedToMeIndex = assignedTo.findIndex(({ id }) => currentId === id)

    if (assignedToMeIndex > -1) {
      assignedTo.splice(assignedToMeIndex, 1)
    }

    const assignedToMeLead = getAssignedToMeLead(currentId)

    this.assignedTo = [assignedToMeLead, UNASSIGNED_LEAD, ...assignedTo]
  }

  setUsers = (users: User[]): void => {
    this.users = users
  }

  constructor () {
    makeAutoObservable(this)
  }
}

export const LeadsListDataInstance = new LeadsListData()
export const LeadsListDataCTX = createContext(LeadsListDataInstance)

export default LeadsListDataCTX
