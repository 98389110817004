import { css } from '@emotion/css'

export default css(`
  flex-direction: column;

  .vehicle-state {
    display: flex;
    align-items: center;

    span {
      color: #21212180;

      &:first-of-type {
        color: #212121CC;
      }
    }

    &:before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 6px;
      background: rgba(0, 0, 0, 0.3);
    }

    &.active {
      &:before {
        content: '';
        background: #219653;
      }
    }

    &.sold {
      &:before {
        content: '';
        background: #FFA451;
      }
    }
  }

  .vehicle-name {
    display: flex;
    align-items: center;
    font-weight: 400;
    
    p {
      color: #101010;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      line-height: 24px;
      overflow-x: hidden;
      font-family: Roboto;
      white-space: nowrap;
      letter-spacing: 0.5px;
      text-overflow: ellipsis;
    }

    &-stock {
      font-weight: 500;
      margin-right: 8px;
    }
  }

  .option-data-container {
    display: flex;
    line-height: 20px;
  }

  .option-data-divider {
    height: 20px;
    margin-left: 8px;
    margin-right: 8px;
    letter-spacing: 0.25px;
    border-left: 1px solid #0000001F;
  }
`)
