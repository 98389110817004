import type { AccountListItem, ControlListItem } from 'api/types'

export const namifyAccount = <T extends AccountListItem>(item: T): T => ({
  ...item,
  name: [item.number, item.name].filter(Boolean).join(' - ').trim()
})

export const namifyControl = (item: ControlListItem): ControlListItem => ({
  ...item,
  name: item.controlId
})
