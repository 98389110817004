import { css } from '@emotion/css'

export default css(`
    overflow: hidden;
    transition: height .3s;
  
    .collapsible-button {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      width: 100%;
      display: flex;
      align-items: center;
      
      .collapsible-button-end-adornment {
        margin-left: 8px;
      }
      
      img {
        transition: transform .3s;
        margin-right: 8px;
      }
      
      &.is-active {
        img {
          transform: rotate(180deg);
        }
      }
    }
    
    
  `)
