import { css } from '@emotion/css'

export default css(`
  .progress-chart {
    position: relative;
    overflow: hidden;
    
    &.is-loading {
      margin-left: -0.4px;
      min-width: 250px;
      max-width: 250px;
    }
    
    .chart-skeleton.is-loading {
      overflow: hidden;
      
      .text-skeleton {
        min-height: 160px;
        max-height: 160px;
        min-width: 248px;
        max-width: 248px;
        box-shadow: none;
        text-shadow: none;
      }
    }
    
    .progress-chart-overlay {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      z-index: 10;
      opacity: 1;
      background: white;
      clip-path: path("M0 0H248V160H0V0ZM236.047 154.023C240.315 155.167 244.729 152.637 245.595 148.305C248.789 132.324 248.807 115.843 245.617 99.8088C241.907 81.1542 233.954 63.6032 222.376 48.5136C210.797 33.4239 195.902 21.2001 178.844 12.7878C161.785 4.37542 143.02 0 124 0C104.98 0 86.2148 4.37542 69.1562 12.7878C52.0976 21.2001 37.2029 33.424 25.6242 48.5136C14.0455 63.6032 6.09326 81.1542 2.38262 99.8088C-0.806871 115.843 -0.789018 132.324 2.40521 148.305C3.27122 152.637 7.68488 155.167 11.9526 154.023L27.5756 149.837C31.8434 148.693 34.3393 144.309 33.5715 139.958C31.5961 128.765 31.7121 117.279 33.9385 106.086C36.6864 92.2714 42.5753 79.2743 51.1496 68.1C59.724 56.9256 70.7541 47.8735 83.3865 41.6439C96.0189 35.4143 109.915 32.1741 124 32.1741C138.085 32.1741 151.981 35.4143 164.614 41.6439C177.246 47.8735 188.276 56.9256 196.85 68.1C205.425 79.2743 211.314 92.2713 214.061 106.086C216.288 117.279 216.404 128.765 214.429 139.958C213.661 144.309 216.157 148.693 220.424 149.837L236.047 154.023Z");
    }
    
    .target-skeleton.is-loading {
      min-width: 128px;
      max-width: 128px;
      position: absolute;
      left: calc(50% - 128px / 2);
      top: 78px;
      z-index: 20;
      
      .text-skeleton {
        min-height: 16px;
        max-height: 16px;
      }
    }
    
    .target-info {
      position: absolute;
      min-width: 128px;
      max-width: 128px;
      text-align: center;
      top: 78px;
      left: calc(50% - 128px / 2);
      z-index: 20;
    }
    
    h6 {
      margin-bottom: 4px;
    }
    
    .target-helper {
      color: rgba(33, 33, 33, 0.50);
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
  }
`)
