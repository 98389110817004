import { css } from '@emotion/css'

export const POPOVER_CLASS_NAME = css(`
  &.cf-popover-content {
    min-width: min(100vw, 340px) !important;
    margin-top: 0px !important;
  }
`)

export default css(`
  && {
    padding: 6px 8px;
    position: relative;

    .cf-button-root.cf-button-variant-text {
      position: relative;
      height: 20px;
      text-decoration: none;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;

      .cf-filter-label-text-container {
        max-width: 270px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .cf-popover-list {
      min-width: min(100vw, 340px);
      position: absolute;
      top: 36px;
      left: 0px;
      padding: 4px;
      border-radius: 12px;
      background-color: #fff;

      > div.cf-form-field {
        height: 56px;
        border-radius: 12px;

        .cf-input-container {
          .cf-end-adornment {
            padding: 10px 0px;

            svg {
              width: 24px;
              height: 24px;
            }
          }

          input {
            padding: 10px 0px;
          }
        }
      }
    }
`)
