import { type FC } from 'react'
import { Button, cnx, FormInput, FormNumberInput } from '@carfluent/common'
import Collapse, { renderAddRemoveBtn } from 'components/common/Collapse'
import type { AdditionalCostsSectionProps } from '../../types'
import CLASS_NAME from './styles'

const MAX_COSTS_NUM = 3
const GET_BTN_TEXT_FUNC = (): string => 'ADD'

const AdditionalCosts: FC<AdditionalCostsSectionProps> = ({
  isFormDisabled,
  values,
  errors,
  touched,
  onChange,
  onBlur
}) => {
  const onAddCost = (): void => {
    if (values.length < MAX_COSTS_NUM) {
      onChange('additionalCosts', [...values, { description: '', amount: '' }])
    }
  }

  return (
    <Collapse
      className={cnx('credit-app-section additional-costs', CLASS_NAME)}
      isDisabled={isFormDisabled || values.length >= MAX_COSTS_NUM}
      isExpanded={values.length > 0}
      title='Additional costs'
      getBtnText={GET_BTN_TEXT_FUNC}
      renderCollapseBtn={renderAddRemoveBtn}
      toggleIsExpanded={onAddCost}
    >
      {values.map((item, i) => (
        <div key={i} className='costs-row'>
          <FormInput
            id={`additionalCosts.[${i}].description`}
            label='Cost label'
            preset='withoutCyrillic'
            value={item.description}
            error={errors[i]?.description}
            touched={touched[i]?.description}
            disabled={isFormDisabled}
            onChange={onChange}
            onBlur={onBlur}
          />

          <FormNumberInput
            id={`additionalCosts.[${i}].amount`}
            label='Amount'
            preset='financial'
            isNegativeAllowed={false}
            value={item.amount}
            error={errors[i]?.amount}
            touched={touched[i]?.amount}
            disabled={isFormDisabled}
            onChange={onChange}
            onBlur={onBlur}
          />

          <div className='btn-remove'>
            <Button
              onClick={() => {
                onChange('additionalCosts', values.filter((_, idx) => idx !== i))
              }}
              isDisabled={isFormDisabled}
              variant='text'
            >
              Remove
            </Button>
          </div>
        </div>
      ))}
    </Collapse>
  )
}

export default AdditionalCosts
