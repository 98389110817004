import { type FormValidation } from '@carfluent/common'
import { type WebsiteChatModel } from 'api/types/responses'
import { WebsiteChatAnswerTypes } from 'types/crm'
import { phoneField } from 'utils/validationPresets'

type ValidationResult = FormValidation<WebsiteChatModel, never>

const validationRules: ValidationResult = {
  'botMetadata.1.[*].answer': (val, ctx, idx) => {
    if (val == null) {
      return null
    }

    if (ctx?.botMetadata?.[1]?.[idx as number].botAnswerTypeId === WebsiteChatAnswerTypes.Phone) {
      return phoneField()(val, ctx, idx)
    }

    return null
  }
}

export default validationRules
