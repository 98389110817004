import { css } from '@emotion/css'

export default css(`
  padding: 8px 16px;
  margin-right: 4px;
  max-height: 40px;
  min-width: 96px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000;
  text-transform: capitalize;
  border: none;
  border-radius: 12px 12px 2px 2px;
  list-style: none;
  cursor: pointer;
  background-color: #fff;
  z-index: 100;
  display: flex;
  justify-content: center; /* AZ-NOTE: counters? Needed for now, to fix https://dev.azure.com/carfluent/CarFluent/_workitems/edit/12452 */

  &:hover {
    opacity: 0.5;
    background: #fff;
  }

  .counter {
    padding-left: 4px;
    color: #C99B86;
  }

  &.tab-selected {
    background-color: #C99B86;
    color: #fff;
    font-weight: 500;
    .counter {
      color: #fff;
      font-weight: 500;
    }

    &:hover {
      opacity: 0.5;
      background-color: #C99B86;
    }
  }

  &.tab-loading {
    position: relative;

    .cf-loader {
      margin-left: 4px;
    }
  }

  &.tab-disabled {
    opacity: 0.4;
    box-shadow: none;
    color: #101010;
    cursor: not-allowed;
    pointer-events: none;
    background: #FAFAFA;
  }
`)
