import { useCallback, useEffect, useState } from 'react'

import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { PercentOrAmountModes } from 'types'
import { percentToDecimal } from 'utils/math'

import {
  type PercentOrAmountFormData,
  type UsePercentOrAmountFormProps,
  type UsePercentOrAmountFormReturn
} from './types'

const DEFAULT_FORM_DATA: PercentOrAmountFormData = {
  amount: null,
  percent: null
}

const usePercentOrAmountForm = ({
  fieldPathAmount,
  fieldPathPercent,
  formatFormHelper,
  getAmountFromPercent,
  isOverriden,
  isOpen,
  onChange,
  onClose,
  onSubmit: _onSubmit,
  values
}: UsePercentOrAmountFormProps): UsePercentOrAmountFormReturn => {
  const { amount: _valueAmount, percent: _valuePercent } = values
  const { showAlert } = useCustomSnackbar()

  const [valueAmount, setValueAmount] =
    useState(DEFAULT_FORM_DATA.amount)

  const [valuePercent, setValuePercent] =
    useState(DEFAULT_FORM_DATA.percent)

  const [formMode, setFormMode] =
    useState<string>(isOverriden ? PercentOrAmountModes.Manual : PercentOrAmountModes.Auto)

  const helperText = formatFormHelper?.(
    { amount: valueAmount, percent: valuePercent },
    formMode === PercentOrAmountModes.Manual
  ) ?? null

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onChangeAmount = useCallback((_, value): void => {
    setValueAmount(value)
  }, [])

  /**
   * Value's precision is `0.001`
   */
  const onChangePercent = useCallback((_, value) => {
    const newTaxValue = percentToDecimal(value)
    setValuePercent(newTaxValue)
  }, [])

  const onSubmit = (): void => {
    const doJob = async (): Promise<void> => {
      try {
        /**
          * OP-NOTE: should set 0 as a default value to valueAmount and valuePercent to avoid 400 error from BE.
          * The issue is that state expects null or a number. There is a rare case where null is sent from the
          * BE for overriddenSalesTaxPercent or overriddenSalesTaxAmount, and we switch to another mode.
          * In the form, 0 is displayed but in the state we have null. In such a case, both fields equal null.
          * and we get a 400 error.
        */
        await _onSubmit({
          amount: formMode === PercentOrAmountModes.Manual ? (valueAmount ?? 0) : null,
          percent: formMode === PercentOrAmountModes.Auto ? (valuePercent ?? 0) : null
        })

        /**
         * `nextAmount` is always number, since it's FE-calculated field.
         */
        const nextAmount = formMode === PercentOrAmountModes.Manual
          ? Number(valueAmount)
          : getAmountFromPercent(valuePercent)

        /**
         * `nextPercent` can be `null` if amount is entered manually.
         */
        const nextPercent = formMode === PercentOrAmountModes.Manual
          ? null
          : Number(valuePercent)

        onChange(fieldPathPercent, nextPercent)
        onChange(fieldPathAmount, nextAmount)
        onClose()
      } catch (err) {
        showAlert(err)
      }
    }

    void doJob()
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Prefill/reset data for inputs
   */
  useEffect(() => {
    const data = isOpen
      ? { amount: _valueAmount, percent: _valuePercent }
      : DEFAULT_FORM_DATA

    setValueAmount(data.amount)
    setValuePercent(data.percent)
  }, [isOpen, _valueAmount, _valuePercent])

  // ========================================== //

  return {
    formMode,
    helperText,
    onChangeAmount,
    onChangePercent,
    onChangeFormMode: setFormMode,
    onSubmit,
    valueAmount,
    valuePercent
  }
}

export default usePercentOrAmountForm
