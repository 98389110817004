import { parsers as P, serializers as S } from '@carfluent/common'

import { type CreateCampaignDto } from 'api/types/requests'
import { type CampaignModel, SendScheduleType } from 'api/types/responses'
import { isValidDate } from 'utils/validation'
import { getHtmlWithEditorStyles } from 'components/common/RichEditor/utils'

import serializeFilters from '../components/StepRecipients/hook/serializer'

const serializeCampaign = ({
  filters,
  schedule,
  contentDetails,
  rowVersion
}: CampaignModel): CreateCampaignDto => {
  const { date, time } = schedule
  let resolvedDateTime: Date | null = null

  if (isValidDate(date)) {
    const { hours = 0, minutes = 0 } = P.parseTimeInterval(time ?? '') ?? {}
    resolvedDateTime = new Date(date.setHours(hours, minutes, 0, 0))
  }

  const bodyHtml = getHtmlWithEditorStyles(contentDetails.body ?? '')

  return {
    ...serializeFilters(filters),
    bodyRaw: normalizeHrefs(contentDetails.body ?? ''),
    bodyHtml: normalizeHrefs(bodyHtml),
    emailSubject: contentDetails.emailSubject,
    name: contentDetails.name,
    preheader: contentDetails.preHeader,
    rowVersion,
    scheduledDateTime: schedule.type === SendScheduleType.Now
      ? null
      : S.serializeDateTimeUTC(resolvedDateTime),
    senderName: contentDetails.senderName
  } as any
}

const normalizeHrefs = (html: string): string => {
  return html.replace(/href="(?!http:\/\/|https:\/\/)([^"]*)"/g, 'href="http://$1"')
}

export default serializeCampaign
