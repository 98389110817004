import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  display: flex;

  .action-bar {
    display: inline-flex;
    align-items: center;
    
    .title {
      color: rgba(33, 33, 33, 0.80);
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    .period-button {
      height: 32px;
      background-color: #F3F3F3;
      border: none;
      border-radius: 8px;
      padding: 6px 8px;
      margin: 0px;
      text-decoration: none;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;

      .cf-button-content {
        display: flex;
        align-items: center;

        svg { margin-left: 8px; }

        .icon-delete svg { margin-top: 6px; }
      }
    }
  }
`)

export const DATE_PICKER_CLASS_NAME = css(`
  padding: 32px;
  
  .close-icon {
    position: absolute;
    top: 32px;
    right: 32px;
    cursor: pointer;
  }
`)

export const POPOVER_CLASS_NAME = css(`
  &&.cf-popover-content {
    z-index: 1300;
    padding: 0;
    margin: 12px 0px !important;
  }
`)
