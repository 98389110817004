import type { FC } from 'react'
import { FormInput, FormMaskedInput, FormDatePicker } from '@carfluent/common'

import { ValidationLength } from 'constants/validation'
import { StatePicker } from 'components/form'
import AddressFields from 'components/common/AddressFields'
import InputSSN2 from 'components/form/components/InputSSN2'
import { isStringEmpty } from 'utils/parse_string'
import type { PersonalDetailsSectionProps } from '../../types'

const PersonalDetails: FC<PersonalDetailsSectionProps> = ({
  values,
  errors,
  touched,
  isPersonal,
  isFormDisabled,
  onChange,
  onBlur
}) => {
  const noDriverLicense = isStringEmpty(values.applicantFinancialDetails.driverLicenseNumber)
  const isDriverLicenseAndStateOptional = noDriverLicense && isStringEmpty(values.applicantFinancialDetails.stateOfIssue)

  return (
    <div className='credit-app-section no-collapse personal-details'>
      {
        isPersonal && (
          <>
            <FormInput
              id='customerDetails.customerFirstName'
              label='First name'
              value={values.customerDetails.customerFirstName}
              error={errors?.customerDetails?.customerFirstName}
              touched={touched?.customerDetails?.customerFirstName}
              disabled={isFormDisabled}
              onChange={onChange}
              onBlur={onBlur}
            />

            <FormInput
              id='customerDetails.customerLastName'
              label='Last name'
              value={values.customerDetails.customerLastName}
              error={errors?.customerDetails?.customerLastName}
              touched={touched?.customerDetails?.customerLastName}
              disabled={isFormDisabled}
              onChange={onChange}
              onBlur={onBlur}
            />

            <FormDatePicker
              id='customerDetails.customerBirthDate'
              label='Date of birth'
              disabled={isFormDisabled}
              isHidden={isFormDisabled}
              value={values.customerDetails.customerBirthDate}
              error={errors?.customerDetails?.customerBirthDate}
              touched={touched?.customerDetails?.customerBirthDate}
              onChange={onChange}
              onBlur={onBlur}
            />

            <FormMaskedInput
              id='customerDetails.customerPhoneNumber'
              label='Phone number'
              mask='phone'
              value={values.customerDetails.customerPhoneNumber}
              error={errors?.customerDetails?.customerPhoneNumber}
              touched={touched?.customerDetails?.customerPhoneNumber}
              disabled={isFormDisabled}
              onChange={onChange}
              onBlur={onBlur}
            />
          </>
        )
      }

      <AddressFields
        id='customerDetails.addressData'
        values={values.customerDetails.addressData}
        errors={errors?.customerDetails?.addressData}
        touched={touched?.customerDetails?.addressData}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
        useNormalAptKey
      />

      {
        isPersonal
          ? (
            <>
              <FormInput
                id='applicantFinancialDetails.driverLicenseNumber'
                label={`Driver's license number ${isDriverLicenseAndStateOptional ? '(optional)' : ''}`}
                value={isFormDisabled
                  ? values.applicantFinancialDetails.driverLicenseNumber?.replace(/[^/]/g, '*') ?? null
                  : values.applicantFinancialDetails.driverLicenseNumber}
                error={errors?.applicantFinancialDetails.driverLicenseNumber}
                touched={touched?.applicantFinancialDetails.driverLicenseNumber}
                disabled={isFormDisabled}
                onChange={onChange}
                onBlur={onBlur}
                maxLength={ValidationLength.DRIVER_LICENSE_MAX}
              />

              <StatePicker
                id='applicantFinancialDetails.stateOfIssue'
                label={`State of issue ${isDriverLicenseAndStateOptional ? '(optional)' : ''}`}
                value={values.applicantFinancialDetails.stateOfIssue}
                error={errors?.applicantFinancialDetails.stateOfIssue}
                touched={touched?.applicantFinancialDetails.stateOfIssue}
                disabled={isFormDisabled || noDriverLicense}
                onChange={onChange}
                onBlur={onBlur}
              />

              <InputSSN2
                id='applicantFinancialDetails.socialSecurityNumber'
                label='Social Security Number'
                value={values.applicantFinancialDetails.socialSecurityNumber}
                error={errors?.applicantFinancialDetails.socialSecurityNumber}
                touched={touched?.applicantFinancialDetails.socialSecurityNumber}
                isDisabled={isFormDisabled}
                isInitialSsnVisible
                onChange={onChange}
                onBlur={onBlur}
              />
            </>
            )
          : (
            <FormMaskedInput
              id='customerDetails.customerPhoneNumber'
              label='Phone number'
              mask='phone'
              value={values.customerDetails.customerPhoneNumber}
              error={errors?.customerDetails?.customerPhoneNumber}
              touched={touched?.customerDetails?.customerPhoneNumber}
              disabled={isFormDisabled}
              onChange={onChange}
              onBlur={onBlur}
            />
            )
      }
    </div>
  )
}

export default PersonalDetails
