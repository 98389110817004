import type { FC } from 'react'
import { cn } from '@carfluent/common'

import type { ReconVehicleStatus } from 'api/types'
import { formatTimeIntervalFromNow } from 'utils/recon/stepIntervalUtils'

import CLASS_NAME from './styles'

interface DisDirLabelProps {
  value: string
  status?: ReconVehicleStatus
}

const DisDirLabel: FC<DisDirLabelProps> = ({ value, status = '' }) => {
  return <p className={cn(CLASS_NAME, status)}>{formatTimeIntervalFromNow(value)}</p>
}

export default DisDirLabel
