import { parsers } from '@carfluent/common'

import { formatDate } from 'utils/parse_date'

const { parseDateStringUTC } = parsers

const DATE_PART = 'MM/dd/yy'
const FORMAT_DATE = `${DATE_PART} hh:mm aa`
const EMPTY_DATE_VALUE = '-'

const getDate = (date: string): string => {
  const parsedDate = parseDateStringUTC(date)

  return parsedDate != null
    ? formatDate(parsedDate, FORMAT_DATE) ?? EMPTY_DATE_VALUE
    : EMPTY_DATE_VALUE
}

export default getDate
