import type { MessageProps } from '../components/Message'

const closeUniqId = (): () => string => {
  let id = 0

  return (): string => `uniqId-${id++}`
}

const getUniqId = closeUniqId()

const getSendingMessage = (
  message: string, imageUrls: string[], name: string | null = null
): MessageProps => ({
  id: getUniqId(),
  message,
  imageUrls,
  isReceived: false,
  name: `${name != null ? `${name}` : ''}`,
  isSending: true,
  date: new Date().toString()
})

export default getSendingMessage
