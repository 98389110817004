import { css } from '@emotion/css'

export default css(`
  .cf-menu-item {
    min-width: 180px;
  }
  
  .cf-menu-button {
    padding-top: 16px;

    > span {
      font-weight: 500;
      letter-spacing: 1.25px;
    }
  }
`)
