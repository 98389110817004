import { css } from '@emotion/css'
import { ReconLabelColors } from 'constants/constants'

const LABEL_COMMON_STYLES = `
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 105px;
  max-width: 120px;
  width: 100%;
  height: 40px;
  padding: 4px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  border-radius: 8px;
`

export default css(`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 48px;
  padding: 4px 60px 4px 40px;
  background-color: #FAFAFA;
  border-radius: 2px;

  .cf-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #101010;
    width: 100%;
    max-width: 284px;
    min-width: 200px;
    
    &.message {
      max-width: 348px;
      width: 100%;
      min-width: 200px;
      padding-left: 8px;
      
      @media screen and (min-width: 1600px) {
        min-width: 348px;
      }
    }
  }

  .cf-label-daysInStepUntilFirstAlert {
    ${LABEL_COMMON_STYLES}
    color: ${ReconLabelColors.TextYellow};
    background-color: ${ReconLabelColors.BGYellow};
  }

  .cf-label-daysInStepUntilSecondAlert {
    ${LABEL_COMMON_STYLES}
    color: ${ReconLabelColors.TextRed};
    background-color: ${ReconLabelColors.BGRed};
  }

  .cf-label-daysInReconUntilFirstAlert {
    ${LABEL_COMMON_STYLES}
    color: ${ReconLabelColors.TextYellow};
    background-color: ${ReconLabelColors.BGYellow};
  }

  .cf-label-daysInReconUntilSecondAlert {
    ${LABEL_COMMON_STYLES}
    color: ${ReconLabelColors.TextRed};
    background-color: ${ReconLabelColors.BGRed};
  }
`)
