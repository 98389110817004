import { GET_DEFAULT_CAMPAIGN_DETAILS } from 'api/defaults'
import { type CampaignModel } from 'api/types/responses'

import { type RecipientsFormData } from './types'

export const mapModelOrDefaultToForm = (campaign?: CampaignModel): RecipientsFormData => {
  return GET_DEFAULT_CAMPAIGN_DETAILS(campaign).filters
}

export const mapFormToModel = (data: RecipientsFormData): Partial<CampaignModel> => {
  return { filters: data }
}
