import { type DealFormValues } from 'types'
import { type DealFeesSettingsModel, DealFeesSettingsToValuesMap } from 'api/types/responses'
import { keys } from 'utils/general'
import { getValueFromPercentRounded } from 'utils/math'

export type DealFeesLike =
  Partial<Omit<DealFormValues['dealFees'], 'others'> & { others: Array<{ amount: number | null }> }>

const getDealFeesAmount = (
  vehiclePrice: number | null,
  fees: DealFeesLike,
  config: DealFeesSettingsModel | null,
  shouldUseOnlyTaxable: boolean = true
): number => {
  let result = 0

  if (config == null) {
    return result
  }

  for (const key of keys(config)) {
    const feeKey = DealFeesSettingsToValuesMap[key]
    const feeValue = fees[feeKey as keyof DealFormValues['dealFees']] ?? 0
    const feeConfig = config[key]
    const isTaxable = feeConfig.isEnabled && (feeConfig.isTaxable || !shouldUseOnlyTaxable)

    if ((typeof feeValue === 'number') && isTaxable) {
      const amount: number = feeConfig.isPercent
        ? getValueFromPercentRounded(vehiclePrice ?? 0, feeValue ?? 0)
        : feeValue

      result += amount
    }
  }

  const others = fees.others ?? []
  result += others.reduce((acc: number, x) => acc + (x.amount ?? 0), 0)

  return result
}

export default getDealFeesAmount
