import { serializers as S } from '@carfluent/common'

import { type LeadFormData } from 'types'
import type {
  LeadDataPatch,
  CreateCallActivityPayload,
  CreateOtherActivityPayload,
  CreateTaskPayload,
  CreateVisitActivityPayload,
  CompleteTaskPayload,
  CompleteAutoTaskPayload,
  CompleteRespondOnLeadTaskPayload
} from 'api/types'

import type { CompleteTaskFormData } from '../components/LeadDetailsTasks/components/TaskComplete/hook/types'
import type { LogActivityFormData } from '../components/LeadDetailsLogActivity/hook/types'
import { CALL_RADIOS } from '../components/LeadDetailsLogActivity/hook/constants'
import type { OpenTaskFormData, RespondOnLeadTaskFormData, ResponseOnAutoTaskFormData } from './types'
import { combineDateTimeToISO } from './utils'
import { UNASSIGNED_OPTION } from './constants'

interface SerializeTaskDataProps extends OpenTaskFormData {
  leadId: string
  taskTypeId: number
}

export const serializeTaskData = ({
  dueDate, time, assignedUser,
  leadId, subject,
  taskPriority, ...otherProps
}: SerializeTaskDataProps): CreateTaskPayload => {
  return {
    leadId: Number(leadId),
    subject: subject?.name ?? null,
    dueDate: combineDateTimeToISO(dueDate, time),
    assignedUserId: assignedUser?.id === UNASSIGNED_OPTION.id ? null : assignedUser?.id ?? 0,
    taskPriorityId: taskPriority?.id ?? null,
    ...otherProps
  }
}

export const serializeFormData = (data: LeadFormData): LeadDataPatch => {
  return {
    address: data.address,
    assignedUserId: data.assignedUserId,
    birthDate: S.serializeDate(data.birthDate),
    city: data.city,
    email: data.email,
    firstName: data.firstName,
    id: data.id,
    isLost: data.isLost,
    lostReasonId: data.lostReasonId,
    lastName: data.lastName,
    leadSourceId: data.leadSourceId,
    leadStatusId: data.leadStatusId,
    leadTemperatureId: data.leadTemperatureId,
    primaryCarOfInterestId: data.primaryCarOfInterestId,
    phoneNumber: data.phoneNumber,
    state: data.state,
    zipCode: data.zipCode,
    lostDate: data.lostDate,
    dealId: data.dealId,
    isUnsubscribed: data.isUnsubscribed,
    isPaused: data.isPaused,
    pausedByUserId: data.pausedByUserId,
    pausedDateTime: S.serializeDate(data.pausedDateTime)
  }
}

export const serializeCallActivityData = (values: LogActivityFormData, leadId: string): CreateCallActivityPayload => {
  const subOptionName = CALL_RADIOS[Number(values.isInbound)].subOptions.find(option => option.id === values.activityCompleteOption)?.label as string
  return {
    leadId: Number(leadId),
    isInbound: values.isInbound === '1',
    workNotes: values.workNotes,
    assignedUserId: values.assignedUser?.id === UNASSIGNED_OPTION.id ? null : values.assignedUser?.id ?? 0,
    activityCompleteOption: String(subOptionName),
    userDefinedCompletionDate: combineDateTimeToISO(values.date, values.time)
  }
}

export const serializeVisitActivityData = (values: LogActivityFormData, leadId: string): CreateVisitActivityPayload => {
  return {
    leadId: Number(leadId),
    workNotes: values.workNotes,
    assignedUserId: values.assignedUser?.id === UNASSIGNED_OPTION.id ? null : values.assignedUser?.id ?? 0,
    userDefinedCompletionDate: combineDateTimeToISO(values.date, values.time),
    activityCompleteSubOptions: values.activityCompleteSubOptions
  }
}

export const serializeOtherActivityData = (values: LogActivityFormData, leadId: string): CreateOtherActivityPayload => {
  return {
    leadId: Number(leadId),
    subject: values.subject?.name ?? null,
    workNotes: values.workNotes,
    assignedUserId: values.assignedUser?.id === UNASSIGNED_OPTION.id ? null : values.assignedUser?.id ?? 0,
    userDefinedCompletionDate: combineDateTimeToISO(values.date, values.time)
  }
}

export const serializeCompleteTaskData = (data: CompleteTaskFormData, taskId: number): CompleteTaskPayload => {
  const date = data.date !== null ? new Date(data.date) : null

  const subOptions = data.taskCompleteSubOptionIds.map(selected => Number(selected.id))

  const isTaskCompleteWithoutDate = data.taskCompleteOptionId === '3' ||
    data.taskCompleteOptionId === '4' ||
    data.taskCompleteOptionId === '6' ||
    data.taskCompleteOptionId === '12'

  const userDefinedCompletionDate = isTaskCompleteWithoutDate
    ? null
    : (date != null && data.time !== '')
        ? combineDateTimeToISO(date, data.time)
        : null

  return {
    taskId,
    subject: data.subject?.name ?? null,
    workNotes: String(data.workNotes),
    taskCompleteOptionId: data.taskCompleteOptionId != null ? Number(data.taskCompleteOptionId) : null,
    taskCompleteSubOptionIds: subOptions,
    userDefinedCompletionDate
  }
}

export const serializeCompleteAutoTaskData = (data: ResponseOnAutoTaskFormData, taskId: number): CompleteAutoTaskPayload => {
  return {
    taskId,
    taskCompleteOptionId: data.taskCompleteOptionId != null ? Number(data.taskCompleteOptionId) : null
  }
}

export const serializeRespondOnLeadTaskData = (data: RespondOnLeadTaskFormData, taskId: number): CompleteRespondOnLeadTaskPayload => {
  return {
    taskId,
    taskCompleteOptionId: data.taskCompleteOptionId != null ? Number(data.taskCompleteOptionId) : null,
    workNotes: data.workNotes
  }
}
