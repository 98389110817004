import type { FC } from 'react'
import { Button, cnx } from '@carfluent/common'
import FiltersPanel from 'components/common/FiltersPanel'

import AccountingHOK from 'pages/accounting/AccountingHOC'
import AccountDetails from 'pages/accounting/AccountDetails'
import OverlayedPageHOC from 'components/PageOverlay/OverlayedPageHOC'
import Table from 'components/common/Table'
import BackToTop from 'components/common/BackToTop'

import useChartOfAccounts from './hook'
import CLASS_NAME from './styles'

const ChartOfAccounts: FC = () => {
  const {
    columns,
    isAddAccountModalOpen,
    isLoading,
    onAccountClick,
    onAddAccount,
    onBottomReached,
    onSearch,
    onSearchChange,
    onSortingChange,
    onToggleAccountFormModal,
    rows,
    search,
    sorting
  } = useChartOfAccounts()

  return (
    <div className={cnx(CLASS_NAME)}>
      <FiltersPanel
        searchProps={{
          onChange: onSearchChange,
          isLoading,
          value: search,
          placeholder: 'Number, name, description',
          withDeleteIcon: true,
          onSearch
        }}
        renderActions={() => (
          <Button
            className='g-button-new-item'
            onClick={onToggleAccountFormModal}
            data-test-id='btn-new-account'
          >
            NEW ACCOUNT
          </Button>
        )}
      />

      <Table
        columns={columns}
        data={rows}
        isLoading={isLoading}
        onBottomReached={onBottomReached}
        onRowClick={onAccountClick}
        emptyTableMessage='No Accounts found'
        onSortingChange={onSortingChange}
        sorting={sorting}
      />

      <AccountDetails
        isModalOpen={isAddAccountModalOpen}
        onCloseModal={onToggleAccountFormModal}
        onSubmit={onAddAccount}
      />

      <BackToTop />
    </div>
  )
}

export default OverlayedPageHOC(AccountingHOK(ChartOfAccounts))
