import type { DeepRequired, FormValidation } from '@carfluent/common'
import type { EmailTemplateFormData } from './types'

import { nameTemplateField } from 'utils/validationPresets'

const name = nameTemplateField()

const validationRules: FormValidation<DeepRequired<EmailTemplateFormData>, 'body'> = {
  name
}

export default validationRules
