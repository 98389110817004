import { useCallback } from 'react'
import { type AddTransactionRequest, TransactionTypeId } from 'api/types'
import { type EntityId, type TransactionLineRow } from 'types'

import { serializeSpecialTransaction } from './specialTransactionSerializer'
import { serializeJournalEntry } from './journalEntrySerializer'
import { type TransactionFormData } from '../types'

export interface SerializerConfig {
  formData: TransactionFormData
  rows: TransactionLineRow[]
  dealId?: EntityId | null
  isPrint?: boolean
}

type UseSerializerReturn = (config: SerializerConfig) => AddTransactionRequest

const useSerializer = (type?: number | null): UseSerializerReturn => {
  return useCallback(({
    formData,
    rows,
    dealId,
    isPrint
  }: SerializerConfig): AddTransactionRequest => {
    switch (type) {
      case TransactionTypeId.Receivable:
      case TransactionTypeId.Receive:
      case TransactionTypeId.Check:
      case TransactionTypeId.PayBill:
      case TransactionTypeId.Payable: {
        return serializeSpecialTransaction({ formData, rows, dealId, transactionTypeId: type, isPrint })
      }

      case TransactionTypeId.JournalEntry:
      default: {
        return serializeJournalEntry(formData, rows, dealId)
      }
    }
  }, [type])
}

export default useSerializer
