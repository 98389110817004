import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE, serializers as S } from '@carfluent/common'

import { isTruthy } from 'utils/general'
import type { DateType } from 'api/types'
import { type DateSerializer, type SerializedDate } from 'utils/filters/types'

/**
 * Serializes dates range. It's different from serializing a single date,
 * since in range we should add '00:00' to the start date and '23:59' to the end date.
 */
export const serializeDateFilters = (
  dates: DateType,
  serializer: DateSerializer = S.serializeDateTimeUTC // used to serialize each Date field
): SerializedDate | null => {
  const { from: rawFrom, to: rawTo } = dates
  let from: Date | null = rawFrom === DEFAULT_MIN_DATE ? null : rawFrom
  let to: Date | null = rawTo === DEFAULT_MAX_DATE ? null : rawTo

  if (from != null) {
    from = new Date(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0)
  }

  if (to != null) {
    to = new Date(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59, 59)
  }

  const fromStr = serializer(from)
  const toStr = serializer(to)

  return {
    from: isTruthy(fromStr) ? fromStr : null,
    to: isTruthy(toStr) ? toStr : null
  }
}

export default serializeDateFilters
