import { css } from '@emotion/css'

export default css(`
  .section-info {
    margin-bottom: 16px;

    h4 {
      font-size: 20px;
      color: #101010;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 8px;
      letter-spacing: 0.15px;
    }

    p {
      font-size: 16px;
      color: #21212180;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }

  .home-page-hero {
    width: 100%;
    height: 264px;
    display: flex;
    min-height: 264px;
    position: relative;
    margin-bottom: 16px;
    border-radius: 12px;
    align-items: center;
    flex-direction: column;

    .hero-image {
      width: 100%;
    }

    .home-page-hero-overlay {
      color: #101010;
      margin-top: 38px;

      h3 {
        font-size: 32px;
        font-weight: 500;
        text-align: center;
        line-height: 44.8px;
      }

      h1 {
        font-size: 40px;
        font-weight: 700;
        line-height: 56px;
        text-align: left;
      }
    }

    .edit-button {
      position: absolute;
      top: 18px;
      right: 18px;
      background-color: #fff;
      cursor: pointer;
      width: 36px;
      height: 36px;
      font-size: 16px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
        position: relative;
      }

      &:before {
        content: '';
        opacity: 0;
        width: 36px;
        height: 36px;
        position: absolute;
        border-radius: 50%;
        transition: all .6s;
        background-color: #EDEDED;
      }

      &: hover {
        &:before {
          opacity: 1;
        }
      }
    }

    .home-page-search-bar {
      display: flex;
      margin-top: 32px;

      .cf-button-root {
        height: 48px;
      }

      .cf-input-container {
        width: 556px;
        height: 48px;
        margin-right: 8px;

        .cf-input {
          padding-top: 12px;
        }

        .cf-input-adornment {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }
  }

  .hero-headings {
    display: flex;
    margin-bottom: 32px;

    > div {
      flex: 1;

      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .car-ads-wrapper {
    display: flex;
    margin-bottom: 32px;

    .car-ad-container {
      flex: 1;
      gap: 16px;
      display: flex;
      border-radius: 12px;
      flex-direction: column;
      border: 1px solid #00000012;
      padding: 16px 16px 24px 16px;

      &:not(:last-of-type) {
        margin-right: 16px;
      }

      .input-container {
        margin-top: 16px;

        > div:first-of-type {
          margin-bottom: 24px;
        }
      }
    }

    .ad-preview {
      flex: 1;
      padding: 8px;
      display: flex;
      color: #101010;
      border-radius: 8px;
      background: #FAFAFA;


      h4 {
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.015em;
      }

      img {
        width: 52px;
        height: 52px;
        margin-right: 4px;
      }
    }
  }

  .btn-new-deal {
    background-color: var(--cf-button-color-primary)!important;

    .cf-button-content {
      color: #fff!important;
    }
  }
`)
