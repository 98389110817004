export enum Messages {
  SaveSuccess = 'Changes successfully saved.',
  UpdateVehicleStatusSuccess = 'Vehicle status updated successfully.',
  UpdateVehicleStatusError = 'Cannot update vehicle status.',
  VehicleField = 'To set car as Active, fill all required fields.',
  CarSold = 'Car is sold',
  CarDeleted = 'Car is deleted',
  DealRecorder = 'Deal recorded to accounting. Please be aware that adding costs may impact your accounting accuracy.'
}

export enum TabIds {
  General = 0,
  Media = 1,
  Costs = 3,
  Leads = 4,
  Files = 5
}
