import { type FC, type ReactNode, useMemo } from 'react'
import { Button, PRESET_LABELS } from '@carfluent/common'
import { type SelectDisplayProps } from '@carfluent/common/dist/UI/Dropdown/types'

import { DealFiltersState, type DictionaryItem } from 'api/types'

import { DEALS_FILTER_LIST } from 'constants/constants'
import OverlayedPageHOC from 'components/PageOverlay/OverlayedPageHOC'
import BackToTop from 'components/common/BackToTop'
import Table from 'components/common/Table'

import NewDealModal from 'pages/deals/NewDeal'
import FiltersPanel, { FilterType, type FilterItem } from 'components/common/FiltersPanel'
import { getDisplayItem, renderOptionWithCounter } from 'utils/selectHelpers'

import useDealsList from './hook'
import { SEARCH_OPTIONS, searchFilterEnumName } from './hook/constants'

import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

const renderSelectDisplay = ({ value }: SelectDisplayProps<DictionaryItem>): ReactNode => getDisplayItem(value)
const activeOption = 'Active deals'

const DealsList: FC = () => {
  const {
    columns,
    counters,
    emptyState,
    isAddDealOpen,
    isLoading,
    filters,
    activeSearchOption,
    rows,
    search,
    sorting,
    dateFiltersProps,
    workflowTypes,
    isFiltersChange,
    onCloseAddDeal,
    onFiltersChange,
    onOpenAddDeal,
    onOpenDealDetails,
    onSearchChange,
    onSortingChange,
    onSearch,
    onSearchOptionChange,
    onDepositCheckboxChange,
    onBottomReached
  } = useDealsList()

  const renderOption = useMemo(() => ({ name }: DictionaryItem): ReactNode => {
    const enumKey = searchFilterEnumName[name]

    return renderOptionWithCounter({
      isLoading,
      counter: name === activeOption
        ? counters[DealFiltersState.Incomplete] + counters[DealFiltersState.Sold]
        : counters[enumKey],
      value: name
    })
  }, [counters, isLoading])

  const dateFilters: FilterItem<'date'> = {
    filterType: 'date',
    componentProps: {
      ...dateFiltersProps,
      presetLabel: 'Sale date',
      defaultPresetName: PRESET_LABELS.ALL
    }
  }

  const workflowSearchFilters: FilterItem<'single-select', DictionaryItem<string>> = {
    filterType: 'single-select',
    componentProps: {
      id: 'workflowSearchState',
      mode: 'select',
      disableClearable: true,
      value: activeSearchOption,
      options: SEARCH_OPTIONS,
      popoverClassName: POPOVER_CLASS_NAME,
      popoverOptions: {
        placement: 'bottom-start'
      },
      onChange: (_, value: DictionaryItem<string> | null): void => {
        if (value !== null) {
          void onSearchOptionChange(value)
        }
      },
      renderSelectDisplay,
      renderOption
    }
  }

  const filterItems: Array<FilterItem<FilterType, any>> = [workflowSearchFilters]

  if (filters.workflowSearchState === DealFiltersState.Sold) {
    filterItems.push(dateFilters)
  }

  if (filters.workflowSearchState !== DealFiltersState.Sold) {
    filterItems.push({
      filterType: 'checkbox',
      componentProps: {
        label: 'On deposit',
        defaultValue: filters.isDeposited,
        onChange: onDepositCheckboxChange
      }
    })
  }

  const tabProps = filters.workflowSearchState === DealFiltersState.Canceled
    ? undefined
    : {
        tabs: DEALS_FILTER_LIST.map((item) => ({
          ...item,
          counter: counters[item.id]
        })),
        activeTab: filters.workflowSearchState,
        isLoading: isFiltersChange,
        onSelectTab: onFiltersChange
      }

  return (
    <div className={CLASS_NAME}>
      <FiltersPanel
        searchProps={{
          isLoading,
          onChange: onSearchChange,
          value: search,
          withDeleteIcon: true,
          onSearch,
          placeholder: 'Car, stock#, deal id'
        }}
        filterProps={{ filters: filterItems }}
        renderActions={(): ReactNode => (
          <Button
            className='btn-new-deal'
            onClick={onOpenAddDeal}
          >
            NEW DEAL
          </Button>
        )}
        tabProps={tabProps}
      />

      <Table
        columns={columns}
        data={rows}
        emptyTableMessage={emptyState}
        isLoading={isLoading}
        isTabChanges={isFiltersChange}
        onSortingChange={onSortingChange}
        onRowClick={onOpenDealDetails}
        sorting={sorting}
        onBottomReached={onBottomReached}
      />

      <NewDealModal
        isOpen={isAddDealOpen}
        onClose={onCloseAddDeal}
        workflowTypes={workflowTypes}
      />

      <BackToTop />
    </div>
  )
}

export default OverlayedPageHOC(DealsList)
