import type { KeyVal } from 'types'
import type { LeadListCounters } from 'api/types'

const parseCountersData = ({ needAttention, ...otherProps }: KeyVal): LeadListCounters => {
  return {
    needattention: needAttention,
    ...otherProps
  } as unknown as LeadListCounters
}

export default parseCountersData
