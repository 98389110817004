import { css } from '@emotion/css'

export default css(`
  &.je-summary-table {
    background: #FFFFFF !important;
    cursor: default;
    padding: 8px;
    
    .MuiPaper-root.cf-table-wrapper {
      .cf-table {
        background: #FFFFFF !important;
        cursor: default;
        
        .cf-table-row {
          border-top: 8px solid #fff;

          &.cf-table-row-hover { background: #fff !important; cursor: default; }
          &.cf-table-row-hover .cf-table-cell { background: #fff !important; cursor: default; }
          &.cf-table-row-hover .cf-table-cell > div { background: #fff !important; cursor: default; }

          > .cf-table-cell:first-child > div {
            border-radius: 0;
            border-left: 4px solid transparent;
          }

          > .cf-table-cell:last-child > div {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }

          :hover > .cf-table-cell > div {
            background: #fff;
            cursor: default;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
          }

          :hover > .cf-table-cell:first-child > div {
            border-left: 1px solid transparent;
          }

          :hover > .cf-table-cell:last-child > div {
            border-right: 1px solid transparent;
          }

          .cf-table-cell {
            background: transparent;
            border-bottom: 1px solid transparent;

            > div { height: 48px; } // Row height
            p { font-weight: 500; }
          }
        }

        .cf-table-row:first-child {
          td.cf-table-cell:nth-of-type(n+4):nth-of-type(-n+7) > div {
            border-bottom: 1px solid rgba(0, 0, 0, 0.3); // Divider
          }
        }

        .cf-table-row:last-child {
          border-top: none;
          > .cf-table-cell .cf-cell-wrapper { padding-top: 8px; }
          .cf-wrong-cell p { color: #B00020; }
        }
      }
    } 
  }
  
  &.memo-container {
    display: flex;
    flex: 1 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    gap: 112px;
    margin-top: 12px;

    .cf-input-container {
      min-height: 100%;
    }
    
    .field-textarea {
      min-width: 450px;
      width: 696px;
      height: 120px;

      & > textarea {
        height: 100%!important;
        background-color: #FAFAFA;
      }
    }

    .total-container {
      display: flex;
      flex-direction: column;

      .total {
        display: flex;
        padding-bottom: 16px;
        width: 495px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.30);
        justify-content: space-between;
        font-weight: 500;
      }

      .total-title {
        font-weight: 500;
        flex: 1;
        margin-right: 60px;
      }

      .total-sum {
        font-weight: 500;
        display: block;
        min-width: 220px;
        padding: 0px 5px;
        flex: 1;

        @media (max-width: 1360px) {
          min-width: 180px;
        }
      }
      
      .payable-total-sum {
        min-width: auto;
        padding: 0px 25px;
      }

      .difference {
        display: flex;
        padding-top: 16px;
        padding-bottom: 8px;
      }

      .error-text {
        color: #B00020;
      }
    }
    
    .bank-statement-match-total {
      width: 656px;
      
      .total { width: auto; }

      .total-sum {
        padding: 0px;
        flex: unset;
        min-width: 220px;
        text-align: start;

        @media (max-width: 1360px) {
          min-width: 180px;
        }
      }
    }
  }
`)
