import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import BackToTop from 'components/common/BackToTop'
import AccountingHOK from 'pages/accounting/AccountingHOC'

import HeaderSection from './components/HeaderSection'
import AccountsListSection from './components/AccountsListsSection'
import StatementsListsSection from './components/StatementsListsSection'
import useBankStatementsList from './hook'
import CLASS_NAME from './styles'

const BankStatementsList: FC = () => {
  const {
    activeAccountId,
    isFiltersChanged,
    onCategorizeBankStatementRow,
    onExcludeBankStatementRow,
    onUndoExcludeClick,
    onSelectAccount,
    setIsFiltersChanged
  } = useBankStatementsList()

  return (
    <div className={cnx(CLASS_NAME, 'g-content-layout')}>
      <HeaderSection accountId={activeAccountId} />

      <AccountsListSection
        onSelectAccount={onSelectAccount}
      />

      <StatementsListsSection
        accountId={activeAccountId}
        onCategorizeRow={onCategorizeBankStatementRow}
        isFiltersChanged={isFiltersChanged}
        onExcludeRow={onExcludeBankStatementRow}
        onUndoExcludeClick={onUndoExcludeClick}
        setIsFiltersChanged={setIsFiltersChanged}
      />

      <BackToTop />
    </div>
  )
}

export default AccountingHOK(BankStatementsList)
