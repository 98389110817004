import { type FC } from 'react'
import { Modal, FormInput } from '@carfluent/common'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import { ValidationLength } from 'constants/validation'

import type { UseNewFormPackModalProps } from './hook/types'
import useNewFormPack from './hook'
import CLASS_NAME from './styles'

const MODAL_WIDTH = 680

const NewFormPackModal: FC<UseNewFormPackModalProps> = (props) => {
  const {
    isSubmitting,
    values,
    errors,
    touched,
    onClose,
    onSubmit,
    onBlur,
    onChange
  } = useNewFormPack(props)

  const renderFooter = (): JSX.Element => {
    return (
      <CancelSubmitActionsFooter
        isLoading={isSubmitting}
        onSubmit={onSubmit}
        onClose={onClose}
        submitTitle='Create form pack'
      />
    )
  }

  return (
    <Modal
      maxWidth={MODAL_WIDTH}
      minWidth={MODAL_WIDTH}
      isOpen={props.isOpen}
      onClose={onClose}
      className={CLASS_NAME}
      renderFooterActions={renderFooter}
      title='New form pack'
    >
      <FormInput
        id='name'
        label='Name'
        value={values.name}
        error={errors.name}
        touched={touched.name}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={ValidationLength.TEXT_FIELDS_MAX}
      />

      <FormInput
        id='description'
        label='Description'
        value={values.description}
        error={errors.description}
        touched={touched.description}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={ValidationLength.TEXT_FIELDS_MAX}
      />
    </Modal>
  )
}

export default NewFormPackModal
