import type { FC } from 'react'

const IconSVGUnlock: FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_65380_23448)'>
        <path
          d='M7.99984 11.3346C8.73317 11.3346 9.33317 10.7346 9.33317 10.0013C9.33317 9.26797 8.73317 8.66797 7.99984 8.66797C7.2665 8.66797 6.6665 9.26797 6.6665 10.0013C6.6665 10.7346 7.2665 11.3346 7.99984 11.3346ZM11.9998 5.33464H11.3332V4.0013C11.3332 2.1613 9.83984 0.667969 7.99984 0.667969C6.15984 0.667969 4.6665 2.1613 4.6665 4.0013H5.93317C5.93317 2.8613 6.85984 1.93464 7.99984 1.93464C9.13984 1.93464 10.0665 2.8613 10.0665 4.0013V5.33464H3.99984C3.2665 5.33464 2.6665 5.93464 2.6665 6.66797V13.3346C2.6665 14.068 3.2665 14.668 3.99984 14.668H11.9998C12.7332 14.668 13.3332 14.068 13.3332 13.3346V6.66797C13.3332 5.93464 12.7332 5.33464 11.9998 5.33464ZM11.9998 13.3346H3.99984V6.66797H11.9998V13.3346Z'
          fill='#323232'
        />
      </g>
      <defs>
        <clipPath id='clip0_65380_23448'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGUnlock
