import { type WebsiteChatModel, type WebsiteChatSettingsDto } from 'api/types/responses'

const serializeWebsiteChatSettings = (formData: WebsiteChatModel): WebsiteChatSettingsDto => {
  return {
    ...formData,
    botMetadata: Object.values(formData.botMetadata)
      .reduce((acc, el) => [...acc, ...el])
      .map(el => ({
        ...el,
        answer: el.answer === null ? el.answer : el.answer.toString()
      }))
  }
}

export default serializeWebsiteChatSettings
