import type { CoverageDefault, CoverageDefaultSection } from 'types/coverage'
import { GET_MANUAL_DEFAULT } from 'api/defaults/coverageDefault'

import { CoverageSectionsProps } from './types'

export const parseCoverageSections = ({
  penPackages,
  coverageSections,
  defaultCoverages
}: CoverageSectionsProps): CoverageDefaultSection[] => {
  const copiedCoverages: CoverageDefaultSection[] = coverageSections.map(section => {
    const hasPenCoverage = penPackages[section.productTypeId]?.some(el => el.coverages.length > 0) ?? false

    return {
      ...section,
      isPenEnabled: hasPenCoverage,
      coverageType: hasPenCoverage ? 'PEN' : 'Manual'
    }
  })

  defaultCoverages.forEach(defaultCoverage => {
    const currentCoverage = copiedCoverages.find(coverage => coverage.productTypeId === defaultCoverage.productTypeId)

    if (currentCoverage != null) {
      currentCoverage.coverageType = defaultCoverage.productCoverageId !== null ? 'PEN' : 'Manual'
      currentCoverage.forms = {
        PEN: defaultCoverage.productCoverageId !== null ? defaultCoverage as CoverageDefault : null,
        Manual: defaultCoverage.productCoverageId === null ? defaultCoverage as CoverageDefault : GET_MANUAL_DEFAULT()
      }

      const selectedForm = currentCoverage.forms[currentCoverage.coverageType]
      currentCoverage.coverageId = selectedForm?.id ?? currentCoverage.coverageId
    }
  })

  return copiedCoverages
}
