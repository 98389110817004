import { formatInteger } from '@carfluent/common'

const formatTermValue = (
  termMonths: string | number | null,
  termMiles: string | number | null
): string | null => {
  if ((termMonths == null) || (termMiles == null)) {
    return null
  }

  return `${termMonths} mo / ${formatInteger(termMiles)} mi`
}

export default formatTermValue
