import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import NotificationsAPIProvider from 'api/notifications.api'
import useTableApi from 'hooks/useTableApi'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { calcTableHeight } from 'utils/math'
import { TABLE_MIN_HEIGHT } from 'constants/constants'
import CalcRoutes, { Routes } from 'constants/route_helper'

import type { EmailRow, UseEmailTemplatesListReturn } from './types'
import { CANCEL_LOAD_DATA, EmptyTableMsg } from './constants'
import columnDefinitions from './columns'
import parseListData from './parser'

const useEmailTemplatesList = (): UseEmailTemplatesListReturn => {
  const { showAlert } = useCustomSnackbar()
  const [isTemplateAdding, setIsTemplateAdding] = useState(false)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const {
    isLoading,
    rows,
    search,
    emptyTableMessage,
    loadRows,
    setSearch,
    onSearch
  } = useTableApi({
    defaultFilters: null,
    getList: NotificationsAPIProvider.getLeadEmailTemplates,
    parseListData: parseListData,
    nothingFoundMessage: EmptyTableMsg.NoItemsFound,
    emptyTableMessage: EmptyTableMsg.NoItems,
    cancelationOptions: CANCEL_LOAD_DATA
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onAddNewTemplate = useCallback(async (): Promise<void> => {
    try {
      setIsTemplateAdding(true)
      const { id } = await NotificationsAPIProvider.createLeadEmailTemplate()
      navigate(CalcRoutes.CRMEmailTemplateForm(id))
    } catch (err) {
      showAlert(err)
    } finally {
      setIsTemplateAdding(false)
    }
  }, [navigate, showAlert])

  const onOpenEmailTemplateDetails = useCallback((row?: EmailRow) => {
    const id = row?.original.id

    if (id != null) {
      navigate(CalcRoutes.CRMEmailTemplateForm(id))
    }
  }, [navigate])

  const onBottomReached = useCallback(async () => {
    if (!isLoading && TABLE_MIN_HEIGHT <= calcTableHeight(rows.length)) {
      await loadRows({ forceRefresh: false })
    }
  }, [isLoading, loadRows, rows.length])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (pathname === Routes.CRMEmailTemplatesList) {
      void loadRows()
    }
  }, [pathname, loadRows])

  // ========================================== //

  return {
    isLoading,
    isTemplateAdding,
    emptyTableMessage,
    rows,
    columns: columnDefinitions,
    search,
    onAddNewTemplate,
    onOpenEmailTemplateDetails,
    onSearchChange: setSearch,
    onBottomReached,
    onSearch
  }
}

export default useEmailTemplatesList
