import { PlacementProps } from './types'

export const TOP_PLACEMENT = 'top'
export const BOTTOM_PLACEMENT = 'bottom'
export const CENTER_PLACEMENT = 'center'
export const LEFT_PLACEMENT = 'left'
export const RIGHT_PLACEMENT = 'right'

export const DEFAULT_PLACEMENT: PlacementProps = {
  vertical: BOTTOM_PLACEMENT,
  horizontal: CENTER_PLACEMENT
}

export const DEFAULT_WITHOUT_ANCHOR_PLACEMENT: PlacementProps = {
  vertical: TOP_PLACEMENT,
  horizontal: LEFT_PLACEMENT
}

export const DEFAULT_TRANSFORM = {
  x: 0,
  y: 0
}

export const OUTSIDE_CLICK_CONFIG = {
  capture: true
}
