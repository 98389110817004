import {
  type ChangeEvent,
  type FC,
  useRef
} from 'react'
import { cn, Button, noop } from '@carfluent/common'

import {
  DEFAULT_FILE_MAX_SIZE,
  DEFAULT_MAX_FILES_NUM,
  UNLIMITED_FILES_SIZE
} from 'constants/files'
import { type FileFilterData, filterFiles } from 'utils/fileFilter'

export interface AddFilesButtonProps {
  acceptedExtensions?: string[]
  className?: string
  isDisabled?: boolean
  maxFileSize?: number
  maxFilesTotalSize?: number
  maxFileNumber?: number
  onAddFiles: (data: FileFilterData) => void
}

const INPUT_STYLE = { display: 'none' }

const AddFilesButton: FC<AddFilesButtonProps> = ({
  acceptedExtensions,
  className,
  isDisabled,
  maxFileSize = DEFAULT_FILE_MAX_SIZE,
  maxFilesTotalSize = UNLIMITED_FILES_SIZE,
  maxFileNumber = DEFAULT_MAX_FILES_NUM,
  onAddFiles
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const onFilesSelected = (e: ChangeEvent<HTMLInputElement>): void => {
    const res = filterFiles(
      Array.from(e.target.files ?? []),
      maxFileSize,
      maxFilesTotalSize,
      maxFileNumber,
      acceptedExtensions
    )

    onAddFiles(res)

    if (fileInputRef.current != null) {
      fileInputRef.current.value = ''
    }
  }

  const accepted = acceptedExtensions?.map(v => `.${v}`).join(', ')

  return (
    <div
      onClick={(e) => {
        if (isDisabled === true) {
          e.preventDefault()
          return
        }
        fileInputRef.current?.click()
      }}
      className={className}
    >
      <input
        type='file'
        multiple
        style={INPUT_STYLE}
        ref={fileInputRef}
        accept={accepted}
        onChange={onFilesSelected}
      />
      <div className={cn(
        'cf-attach-files-content',
        isDisabled === true ? 'is-disabled' : ''
      )}
      >
        <Button
          variant='text'
          onClick={noop}
        >
          Upload Files
        </Button>
      </div>
    </div>
  )
}

export default AddFilesButton
