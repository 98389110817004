import { css } from '@emotion/css'
import NoteTriangle from 'assets/note_triangle.svg'

export const NOTES_CLASSES = css(`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  height: 100%;
  background: url(${NoteTriangle}) left top no-repeat #FFFBF3;
  box-shadow: 0px 24px 38px rgba(58, 71, 78, 0.1);
  border-radius: 12px;
  padding: 12px 16px 0 32px;
  position: relative;
`)

export const NOTES_TITLE_CLASSES = css(`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(33, 33, 33, 0.5);
`)

export const NOTES_TEXTAREA_CLASSES = css(`
  padding: 0 16px 12px 0;
  overflow: auto;
  background: none;
  border: none;
  resize: none;
  width: 100%;
  height: 100%;
  outline: none;
  letter-spacing: 0.5px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-line;
  word-break: break-all;
  font-style: italic;
  color: #101010;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin-bottom: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(16, 16, 16, 0.2);
    border: 1px solid #FFFFFF;
    border-radius: 3px;
  }

  .cf-textarea-placeholder {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    color: rgba(33, 33, 33, 0.5);
    font-style: italic;
  }
`)

export const NOTES_TEXTAREA_EMPTY_CLASSES = css(`
  color: rgba(33, 33, 33, 0.5);
  font-style: italic;
`)
