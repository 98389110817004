import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useModal } from '@carfluent/common'
import { type AutocompleteChangeReason, type AutocompleteInputChangeReason } from '@material-ui/lab'
import { type FCHook } from 'types'
import { type TransactionLineVendorDto } from 'api/types'

export enum SpecialId {
  AddVendor = -1
}

export const SPECIAL_ENTITIES = [
  { id: SpecialId.AddVendor, name: 'Add Vendor' }
] as any as TransactionLineVendorDto[]

export interface UseVendorDropdownProps {
  filterOptions?: (items: TransactionLineVendorDto[]) => TransactionLineVendorDto[]
  inputValue?: string
  onInputChange?: (id: string, value: string, reason: AutocompleteInputChangeReason) => void
  onChange?: (id: string, value: TransactionLineVendorDto | null, reason: AutocompleteChangeReason) => void
  onAddVendor: (value: TransactionLineVendorDto) => Promise<void>
  options: TransactionLineVendorDto[]
}

export interface UseVendorDropdownReturn {
  dropdownProps: {
    filterOptions: (items: TransactionLineVendorDto[]) => TransactionLineVendorDto[]
    onInputChange: (id: string, value: string, reason: AutocompleteInputChangeReason) => void
    onChange: (id: string, value: TransactionLineVendorDto | null, reason: AutocompleteChangeReason) => void
    options: TransactionLineVendorDto[]
  }
  vendorModalProps: {
    isOpen: boolean
    onSubmit: (vendor: TransactionLineVendorDto) => Promise<void>
    onClose: () => void
  }
}

/**
 * Adds "+ Add Vendor" button (option) to Dropdown's options.
 * Handles visibility for "VendorDetails" modal.
 */
const useVendorDropdown: FCHook<UseVendorDropdownProps, UseVendorDropdownReturn> = ({
  filterOptions,
  inputValue,
  onAddVendor,
  onInputChange,
  onChange,
  options
}) => {
  const refInputValue = useRef(inputValue ?? '')
  const vendorModalProps = useModal()
  const vendorsOptions: TransactionLineVendorDto[] = useMemo(() => SPECIAL_ENTITIES.concat(options), [options])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onVendorChange = useCallback((id: string, value: TransactionLineVendorDto | null, reason: AutocompleteChangeReason) => {
    if (value?.id === SpecialId.AddVendor) {
      vendorModalProps.onOpenModal()
      return
    }

    onChange?.(id, value, reason)
  }, [onChange])

  const onVendorInputChange = useCallback((id: string, value: string, reason: AutocompleteInputChangeReason) => {
    refInputValue.current = value
    onInputChange?.(id, value, reason)
  }, [onInputChange])

  const filterVendors = useCallback((items: TransactionLineVendorDto[]) => {
    return items.filter(({ id, name }) => (
      (name ?? '').toLowerCase().includes(refInputValue.current.toLowerCase()) ||
      id === SpecialId.AddVendor
    ))
  }, [])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    refInputValue.current = inputValue ?? ''
  }, [inputValue])

  // ========================================== //
  return {
    dropdownProps: {
      filterOptions: filterOptions ?? filterVendors,
      onInputChange: onVendorInputChange,
      onChange: onVendorChange,
      options: vendorsOptions
    },
    vendorModalProps: {
      isOpen: vendorModalProps.isModalOpen,
      onClose: vendorModalProps.onCloseModal,
      onSubmit: onAddVendor
    }
  }
}

export default useVendorDropdown
