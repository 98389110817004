import type { FC } from 'react'
import { formatters, UI } from '@carfluent/common'
import { type BasicValueCellProps } from 'types'
import TooltipedText from 'components/common/TooltipedText'

import CLASS_NAME from './styles'

const { formatInteger } = formatters
const { CellWrapper } = UI

const AgeCell: FC<BasicValueCellProps> = (props) => {
  const daysNum = parseInt(`${props.getValue() as number | string}`)
  const days = formatInteger(daysNum)
  const postfix = daysNum === 1 ? '' : 's'

  return (
    <CellWrapper className={CLASS_NAME}>
      <TooltipedText
        value={`${days} day${postfix}`}
      />
    </CellWrapper>
  )
}

export default AgeCell
