import { useCallback, useEffect, useState, useMemo } from 'react'
import { PRESET_LABELS, serializers as S } from '@carfluent/common'

import { type KeyVal } from 'types'
import type { ListResponse, DateType, SalesTaxReportDto } from 'api/types'
import { type CoverageFeeSettingsDto } from 'api/types/responses'
import CustomersCoreApiProvider from 'api/customersCore.api'
import useTableApi from 'hooks/useTableApi'
import useDragScroll from 'hooks/useDragScroll'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { type StateItem } from 'utils/states_us'
import serializeDateFilters from 'utils/filters/serializeDateFilters'
import { downloadBlob } from 'utils/general'

import type {
  ReportSalesTaxFilters,
  UseReportSalesTaxReturn,
  SalesTaxReportFromApi,
  SaleTaxRowData,
  SumValuesProps
} from './types'

import parseListData from './parser'
import {
  getPresets, Messages,
  DEFAULT_SORTING, API_CALL_DELAY_SEARCH,
  DEFAULT_SUM_VALUES, REPORT_FILE_NAMES
} from './constants'
import { groupByState } from './utils'
import getColumnDefinitions from './columns'

const presets = getPresets()
const PREVIOUS_MONTH = presets.find(p => p.name === PRESET_LABELS.PREVIOUS_MONTH)

const useReportSalesTax = (): UseReportSalesTaxReturn => {
  const { showAlert } = useCustomSnackbar()

  const [states, setStates] = useState<StateItem[]>([])
  const [feeSettings, setFeeSettings] = useState<CoverageFeeSettingsDto | null>(null)
  const [sumValues, setSumValues] = useState<SumValuesProps>(DEFAULT_SUM_VALUES)
  const [stateAmount, setStateAmount] = useState<Record<string, number>>({})
  const [allInfoAboutReport, setAllInfoAboutReport] = useState<Omit<SalesTaxReportDto, 'columns'> | null>(null)

  useDragScroll({ className: '.cf-table-wrapper' })

  const loadSaleTaxReport = useCallback(async ({
    sortField,
    sortOrder,
    search,
    state,
    dateRange
  }: KeyVal): Promise<ListResponse<SalesTaxReportFromApi>> => {
    const date = serializeDateFilters(dateRange, S.serializeDateTime)
    const payload = {
      dateFrom: date?.from ?? null,
      dateTo: date?.to ?? null,
      sortField,
      sortOrder,
      search,
      state
    }
    const [{ records, states, ...resp }, { coverageFeeSettings }] = await Promise.all([
      CustomersCoreApiProvider.getSalesTaxReport(payload),
      CustomersCoreApiProvider.getSettings()
    ])
    setStates(() => (states))
    setFeeSettings(coverageFeeSettings)
    setSumValues({ ...resp })
    setAllInfoAboutReport({ records, states, ...resp })

    const stateAmount = records.reduce((acc, item) => ({
      ...acc,
      [item.state]: Number(acc[item.state as keyof typeof acc] ?? 0) + 1
    }), {})

    setStateAmount(stateAmount)

    return { items: records }
  }, [])

  const {
    rows,
    search,
    sorting,
    onSearch,
    isLoading,
    setSearch,
    setFilters,
    setSorting,
    filters,
    emptyTableMessage
  } = useTableApi<SalesTaxReportFromApi, ReportSalesTaxFilters, SaleTaxRowData>({
    parseListData,
    defaultFilters: {
      dateRange: {
        from: PREVIOUS_MONTH?.startDate ?? new Date(),
        to: PREVIOUS_MONTH?.endDate ?? new Date()
      },
      state: ''
    },
    defaultSorting: DEFAULT_SORTING,
    getList: loadSaleTaxReport,
    apiCallDelay: API_CALL_DELAY_SEARCH,
    emptyTableMessage: Messages.EmptyTableState,
    nothingFoundMessage: Messages.NothingFoundState,
    shouldRunOnCall: true
  })

  const groupBy = useMemo(() => groupByState(stateAmount), [stateAmount])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onDateFilterChange = useCallback(async (dateRange: DateType) => {
    void setFilters({ dateRange })
  }, [setFilters])

  const onStateFilterChange = useCallback(async (value: string) => {
    void setFilters({ state: value })
  }, [setFilters])

  const onExportExcel = useCallback(async (): Promise<void> => {
    try {
      if (allInfoAboutReport == null) {
        return
      }
      const date = serializeDateFilters({ from: filters.dateRange.from, to: filters.dateRange.to }, S.serializeDateTime)
      const payload = {
        ...allInfoAboutReport,
        dateFrom: date?.from ?? null,
        dateTo: date?.to ?? null
      }

      const res = await CustomersCoreApiProvider.downloadSalesTaxReportXlsx(payload)

      downloadBlob(res, REPORT_FILE_NAMES.xlsx)
    } catch (err) {
      showAlert(err)
    }
  }, [allInfoAboutReport, filters.dateRange, serializeDateFilters, showAlert])

  const columnDefinitions = useMemo(() => getColumnDefinitions(sumValues, feeSettings), [sumValues, feeSettings])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    /**
     * Set skeleton table at the same scroll position as main table
     * to align columns at this two tables
     */
    if (isLoading) {
      const outerDiv = document.querySelector('.cf-table-wrapper') as HTMLDivElement
      const innerDiv = document.querySelector('.skeleton-table-wrapper') as HTMLDivElement

      if (outerDiv != null && innerDiv != null) {
        innerDiv.scrollLeft = outerDiv.scrollLeft
      }
    }
  }, [isLoading])

  // ========================================== //

  return {
    rows,
    search,
    states,
    isLoading,
    sorting,
    presets,
    emptyTableMessage,
    columnDefinitions,
    groupBy,
    onSearch,
    onExportExcel,
    onDateFilterChange,
    onStateFilterChange,
    onSearchChange: setSearch,
    onFiltersChange: setFilters,
    onSortingChange: setSorting
  }
}

export default useReportSalesTax
