import { css } from '@emotion/css'

export default css`
  > div {
    padding: 24px 0;

    :first-of-type {
      padding-top: 8px;
    }

    :not(:last-of-type) {
      border-bottom: 1px solid #00000012
    }
  }

  .MuiSkeleton-root {
    height: 16px;
  }

  .skeleton-bottom-padding {
    margin-bottom: 16px;
  }

  .skeleton-column-wrapper {
    column-gap: 16px;
    row-gap: 24px;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(6, 1fr);
  
    @media (max-width: 1359px) {
      grid-template-columns: repeat(5, 1fr);
    }

    &.skeleton-bottom-padding {
      margin-bottom: 24px;
    }
  }

  .skeleton-section-title {
    max-width: 180px;
  }

  .skeleton-checkbox-wrapper {
    display: flex;

    span:first-of-type {
      width: 16px;
      margin-right: 10px;
    }

    span:last-of-type {
      width: 180px;
    }
  }

  .skeleton-rectangle {
    height: 120px;

    &.skeleton-bottom-padding
  }
`
