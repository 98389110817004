import { css } from '@emotion/css'

export const COLOR_MAP = {
  black: '#212121',
  red: '#D42A49',
  yellow: '#FC973B'
}

export default css(`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0px;
  margin-right: 8px;
  border-radius: 12px 2px 2px 12px;
  position: relative;
  padding-bottom: 16px;

  .cf-recon-step-filters-header {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 0px;
    padding: 8px 16px 0px!important;
    background: white;
    
    .car-block-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-radius: 4px 4px 0px 0px;
    }
  }

  .cf-recon-step-filters-list {
    padding: 0;
    background: #fff;
    height: calc(100% - 32px);
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      display: none;
    }
    
    -ms-overflow-style: none;
    scrollbar-width: none;

    .cf-recon-step-filter-item {
      background: white;
      font-weight: 400;
      font-size: 14px;
      position: relative;
      
      span {
        * {
          font-size: 14px;
        }
      }

      margin-right: 0;
      padding: 0;
      min-height: 32px;
      max-height: 64px;

      &.selected, :hover {
        background: #FAFAFA;
        cursor: pointer;
        
        :before {
          opacity: 1;
        }
      }
      
      &.selected {
        span, .ellipsis-text-content {
          font-weight: 500;
        }
      }

      span:nth-child(2) {
        color: ${COLOR_MAP.black};
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      span:nth-child(3) {
        color: ${COLOR_MAP.yellow};
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      span:last-child {
        color: ${COLOR_MAP.red};
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  
  .no-alert-bg {
    background: #FAFAFA;
  }
  
  .first-alert-bg {
    background: rgba(252, 151, 59, 0.10);
  }
  
  .second-alert-bg {
    background: rgba(212, 42, 73, 0.10);
  }
  
  .no-alert-line {
    background: #FAFAFA;
    position: absolute;
    width: 32px;
    height: calc(100% - 16px);
    top: 8px;
    right: 88px;
    border-radius: 4px;
  }

  .first-alert-line {
    background: rgba(252, 151, 59, 0.10);
    position: absolute;
    width: 32px;
    height: calc(100% - 16px);
    top: 8px;
    right: 52px;
    border-radius: 4px;
  }

  .second-alert-line {
    background: rgba(212, 42, 73, 0.10);
    position: absolute;
    width: 32px;
    height: calc(100% - 16px);
    top: 8px;
    right: 16px;
    border-radius: 4px;
  }
  
  .cf-recon-step-filters-header {
    min-height: 32px;
  }

  .cf-recon-step-filters-header, .cf-recon-step-filters-list .cf-recon-step-filter-item {
    display: grid;
    grid-template-columns: auto 32px 32px 32px;
    grid-gap: 4px;
    align-items: center;
    padding: 0 16px;
  }

  .divider {
    margin: 8px 16px;
    z-index: 1;
    flex-shrink: 0;
    width: calc(100% - 32px);
  }
`)

export const TOOLTIP_CLASS_NAME = css`
  &.MuiTooltip-popper[role='tooltip'] {
    z-index: 1202;
  }
`
