import { css } from '@emotion/css'

export default css(`
  height: 220px;
  flex-basis: 400px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-alignment: flex-start;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.03), 0px 10px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 8px 5px rgba(0, 0, 0, 0.02);
  cursor: pointer;
  max-width: 400px;

  .account-title .tooltip-content {
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(16, 16, 16);
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 24px;
    font-size: 20px;
    letter-spacing: 0.15px;
  }
  
  .account-amount {
    color: rgba(33, 33, 33, 0.8);
  }


  &.active {
    border: 1px solid #C99B86;
    box-shadow: 0px 24px 38px 0px rgba(58, 71, 78, 0.10);
  }

  .last-manual-upload-date,
  .last-reconciled-process-date {
    display: flex;
    gap: 4px;
    align-items: center;
    color: rgba(33, 33, 33, 0.8);

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
  
  .review-transaction-count,
  .last-reconciled-period {
    color: rgba(33, 33, 33, 0.5);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  
  .review-transaction-count {
    color: rgba(33, 33, 33, 0.8);
  }

  .g-horizontal-line {
    margin: 0px;
  }
`)
