import { type FC } from 'react'
import { cnx, FormNumberInput } from '@carfluent/common'

import { MAX_PRICE_VALUE } from 'constants/validation'

import { type FinancingFeesFormProps } from './types'
import CLASS_NAME from './styles'

const FinancingFeesForm: FC<FinancingFeesFormProps> = ({
  className,
  errors,
  isDisabled = false,
  isLoading = false,
  onBlur,
  onChange,
  touched,
  values
}) => {
  return (
    <div className={cnx(CLASS_NAME, className)}>
      <div className='g-h7-title'>Financing fees</div>

      <div className='financing-fees-fields'>
        <FormNumberInput
          blurComplementMode='min-scale'
          disabled={isDisabled}
          error={errors?.buyRate}
          id='buyRate'
          isEmptyAllowed
          mountComplementMode='min-scale'
          onBlur={onBlur}
          onChange={onChange}
          label='Buy rate'
          preset='percent'
          touched={touched?.buyRate}
          value={values?.buyRate ?? null}
        />

        <FormNumberInput
          disabled={isDisabled}
          error={errors?.reserve}
          id='reserve'
          isNegativeAllowed
          label='Reserve'
          max={MAX_PRICE_VALUE}
          onBlur={onBlur}
          onChange={onChange}
          preset='financial'
          touched={touched?.reserve}
          value={values?.reserve ?? null}
        />

        <FormNumberInput
          disabled={isDisabled}
          error={errors?.acquisitionFee}
          id='acquisitionFee'
          isNegativeAllowed={false}
          label='Acquisition fee'
          max={MAX_PRICE_VALUE}
          onBlur={onBlur}
          onChange={onChange}
          preset='financial'
          touched={touched?.acquisitionFee}
          value={values?.acquisitionFee ?? null}
        />
      </div>
    </div>
  )
}

export default FinancingFeesForm
