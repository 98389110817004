import type { UseFormReturn } from '@carfluent/common'

import type { PickedFormDataProps, Shift, Coords } from 'types'
import type { DictionaryItem, CreateShiftRequestDto, UpdateShiftRequestDto } from 'api/types'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ErrTouchShortcuts = {
  person: true
}

export interface ShiftDetailsFormData {
  date: Date | null
  startTime: string
  endTime: string
  person: DictionaryItem | null
}

export interface ShiftDetailsModalProps extends UseShiftDetailsModalProps {
  isRemoving: boolean
  position?: Coords | undefined
  onClose: () => void
}

export interface UseShiftDetailsModalProps {
  isOpen: boolean
  shift?: Shift | null
  initialDate?: Date | null
  shiftDetailsSubmit: (shift: UpdateShiftRequestDto | CreateShiftRequestDto) => Promise<void>
  onRemoveShift: (shiftId: number) => Promise<void>
}

export interface UseShiftDetailsModalReturn extends Pick<UseFormReturn<ShiftDetailsFormData, ErrTouchShortcuts>, PickedFormDataProps | 'onSubmit' | 'isFormChanged' | 'isSubmitting'> {
  options: DictionaryItem[]
  countHours: (value: Date) => string
  onChangeTime: (val: string) => void
  onRemoveShiftClick: () => void
}

export enum FieldIds {
  Date = 'date',
  StartTime = 'startTime',
  EndTime = 'endTime',
  Person = 'person',
}

export enum WeekDays {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export enum ShiftDuration {
  fullDay = 9
}
