import { type FC, type ReactNode } from 'react'
import carBgImage from 'assets/car_bg.png'
import LogoIcon from 'components/inlineImages/LogoIcon'
import CLASS_NAME from './styles'

export interface AuthLayoutProps {
  children?: ReactNode
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  return (
    <div className={CLASS_NAME}>
      <div className='header'>
        <div className='header-logo'>
          <LogoIcon />
        </div>
      </div>
      <div className='page-wrapper'>
        <div className='background-image-wrapper'>
          <img className='background-image' alt='bg_car' src={carBgImage} />
        </div>
        <div className='content-wrapper'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
