import { type FC } from 'react'
import { Modal, FormInput, FormMaskedInput, FormDropdown, Banner } from '@carfluent/common'

import type { WorkflowTypeDto, CustomerDto } from 'api/types'
import AccountingApiProvider from 'api/accounting.api'
import { type StateItem, STATES_US } from 'utils/states_us'
import { COMMON_MODAL_FORM_CLS, EIN_MASK, MODAL_MIN_WIDTH } from 'constants/constants'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import DeleteEntityButton from 'components/dialogs/DeleteEntityButton'
import { Checkbox } from 'components/form'
import InputSSN2 from 'components/form/components/InputSSN2'
import { ValidationLength } from 'constants/validation'
import CheckBoxIcon from 'components/accounting/CheckBoxIcon'
import AddressFields from 'components/common/AddressFields'
import IconSVG from 'components/inlineImages'
import CustomerOption from './components/CustomerOption'
import { isPersonalType } from 'utils/deals/workflowTypes'

import { getWindowTitle } from './hook/constants'
import type { UseCustomerProps } from './hook/types'
import useCustomer from './hook/index'
import CLASS_NAME from './styles'

export type CustomerDetailsProps = UseCustomerProps

const renderWorkflowTypeOption = (option: WorkflowTypeDto): string => {
  return option.name
}

const CustomerDetails: FC<CustomerDetailsProps> = (props) => {
  const {
    isLoading,
    isStateOfIssueDisabled,
    isOpen,
    isEdit,
    values,
    errors,
    touched,
    workflowTypes,
    error,
    isDateError,
    onClose,
    onSubmit,
    onChange,
    onBlur,
    onDelete,
    onNameBlur
  } = useCustomer(props)

  const isPersonal = isPersonalType(values.customerTypeId)
  const isBusiness = !isPersonal

  const renderFooter = (): JSX.Element => {
    const renderDeleteButton = (): JSX.Element => (
      <DeleteEntityButton
        isDisabled={isLoading}
        onConfirm={onDelete}
        titleButton='REMOVE CUSTOMER'
        titleWindow='Delete this customer?'
      />
    )

    return (
      <CancelSubmitActionsFooter
        isLoading={isLoading}
        onSubmit={onSubmit}
        onClose={onClose}
        renderDeleteButton={isEdit ? renderDeleteButton : undefined}
        submitTitle={isEdit ? 'SAVE' : 'CREATE'}
      />
    )
  }

  return (
    <Modal
      className={CLASS_NAME}
      isOpen={isOpen}
      onClose={onClose}
      minWidth={MODAL_MIN_WIDTH}
      renderFooterActions={renderFooter}
      title={getWindowTitle(isEdit)}
    >
      <Banner
        isOpen={isDateError}
        startAdornment={(
          <IconSVG.Lock
            opacity={1}
            width={24}
            height={24}
            color='#B00020'
          />
        )}
        message={error}
        type='error'
      />
      <div className={COMMON_MODAL_FORM_CLS}>
        <FormDropdown
          id='customerTypeId'
          label='Type'
          className='customer-type-id'
          blurMode='select-first'
          options={workflowTypes}
          renderOption={renderWorkflowTypeOption}
          value={workflowTypes.find(v => v.id === values.customerTypeId)}
          error={errors.customerTypeId}
          touched={touched.customerTypeId}
          onChange={(id, val) => onChange(id, val?.id)}
          onBlur={onBlur}
          dataTestId='customer-type-id'
        />

        {isBusiness && (
          <FormInput
            id='businessName'
            label='Business name'
            className='new-customer-business-name'
            onChange={onChange}
            onBlur={onBlur}
            value={values.businessName}
            error={errors.businessName}
            touched={touched.businessName}
            dataTestId='business-details-name'
          />
        )}

        <FormDropdown<CustomerDto | string>
          id='firstName'
          label={isBusiness ? 'Rep. First name' : 'First name'}
          mode='search'
          blurMode='create'
          createOptionFromInput={(val) => val}
          renderOption={CustomerOption}
          fetchHandler={AccountingApiProvider.getCustomers}
          value={values.firstName}
          error={errors.firstName}
          touched={touched.firstName}
          onChange={onChange}
          onBlur={onNameBlur}
          endAdornment={<></>}
        />

        <FormDropdown<CustomerDto | string>
          id='lastName'
          label={isBusiness ? 'Rep. Last name' : 'Last name'}
          mode='search'
          blurMode='create'
          createOptionFromInput={(val) => val}
          renderOption={CustomerOption}
          fetchHandler={AccountingApiProvider.getCustomers}
          value={values.lastName}
          error={errors.lastName}
          touched={touched.lastName}
          onChange={onChange}
          onBlur={onNameBlur}
          endAdornment={<></>}
        />

        <FormMaskedInput
          id='phoneNumber'
          value={values.phoneNumber}
          error={errors.phoneNumber}
          touched={touched.phoneNumber}
          label='Phone number'
          onChange={onChange}
          onBlur={onBlur}
          mask='phone'
        />

        <FormInput
          value={values.email}
          error={errors.email}
          touched={touched.email}
          id='email'
          label='Email'
          maxLength={ValidationLength.EMAIL_MAX}
          onChange={onChange}
          onBlur={onBlur}
        />

        <AddressFields
          id='address'
          label='Address'
          values={values.address}
          errors={errors.address}
          touched={touched.address}
          onChange={onChange}
          onBlur={onBlur}
          shouldHideApt
        />

        {
          isPersonal
            ? (
              <>
                <FormInput
                  id='driverLicense'
                  value={values.driverLicense}
                  error={errors.driverLicense}
                  touched={touched.driverLicense}
                  label='Driver license number'
                  maxLength={ValidationLength.DRIVER_LICENSE_MAX}
                  onChange={onChange}
                  onBlur={onBlur}
                />

                <FormDropdown<StateItem | string>
                  id='stateOfIssue'
                  disabled={isStateOfIssueDisabled}
                  label='State of issue'
                  options={STATES_US}
                  value={values.stateOfIssue?.name ?? null}
                  error={errors.stateOfIssue}
                  touched={touched.stateOfIssue}
                  onChange={onChange}
                  onBlur={onBlur}
                />

                <InputSSN2
                  id='ssn'
                  isInitialSsnVisible={values.firstName == null}
                  value={values.ssn}
                  error={errors.ssn}
                  touched={touched.ssn}
                  label='SSN'
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </>
              )
            : (
              <FormMaskedInput
                id='ein'
                label='EIN'
                mask={EIN_MASK}
                onChange={onChange}
                onBlur={onBlur}
                value={values.ein}
                error={errors.ein}
                touched={touched.ein}
              />
              )
        }

        <Checkbox
          icon={<CheckBoxIcon />}
          id='isDifferentMailingAddress'
          className='is-different-mailing-address'
          label='Different mailing address'
          value={values.isDifferentMailingAddress}
          onChange={onChange}
          onBlur={onBlur}
        />

        {values.isDifferentMailingAddress && (
          <AddressFields
            id='mailingAddress'
            label='Mailing address'
            values={values.mailingAddress}
            errors={errors.mailingAddress}
            touched={touched.mailingAddress}
            onChange={onChange}
            onBlur={onBlur}
            shouldHideApt
          />
        )}
      </div>
    </Modal>
  )
}

export default CustomerDetails
