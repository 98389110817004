import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'
import { Skeleton } from '@material-ui/lab'

import CLASS_NAME from './styles'

const BigSkeletonLineCell: FC = () => {
  return (
    <CellWrapper>
      <Skeleton className={CLASS_NAME} variant='text' />
    </CellWrapper>
  )
}

export default BigSkeletonLineCell
