import { css } from '@emotion/css'

const PROGRESS_BAR_WIDTH = 40

export default css`
  padding: 16px;
  display: grid;
  row-gap: 8px;
  background: white;
  border-radius: 12px;
  margin-bottom: 16px;
  
  &.pointer {
    cursor: pointer;

    .block-info-header {
      text-decoration: underline;
    }

    .block-statistics {
      padding: 12px 16px;

      @media (max-width: 1280px) {
        padding: 14px 16px;
      }
    }
  }

  .block-info {
    border-radius: 8px;
    background: #FAFAFA;
    padding: 4px 16px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr ${PROGRESS_BAR_WIDTH}px;
    align-items: center;

    .cf-loader {
      right: 22px;
      position: absolute;
    }
    
    @media (max-width: 1280px) {
      padding: 8px 16px;
    }
  }
  
  .block-info-titles {
    overflow: hidden;
    max-width: calc(100% - ${PROGRESS_BAR_WIDTH}px);

    @media (max-width: 1280px) {
      max-width: none;
    }


    p {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.15px;
    }

    .tooltip-content {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: rgba(33, 33, 33, 0.50);
    }

    .block-info-status {
      color: rgba(33, 33, 33, 0.50);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }

  .block-statistics {
    border-radius: 8px;
    background: #F1F7FF;
    padding: 12px 16px;
    
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      
      svg {
        margin-right: 8px;
      }
    }

    .block-statistic-count {
      font-size: 14px;
      min-width: 20px;
    }

    .loader-container {
      position: relative;
      width: 20px;
      height: 20px;

      .cf-loader {
        position: absolute;
        top: -2px;

        svg {
          margin-right: 0;
        }
      }
    }
  }
`
