import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGReply: FC<IconProps> = ({ color = '#000000', width = 24, height = 24 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'>
      <g clipPath='url(#clip0_55364_93182)'>
        <path
          d='M9.99875 8.00026V6.41026C9.99875 5.52026 8.91875 5.07026 8.28875 5.70026L3.69875 10.2903C3.30875 10.6803 3.30875 11.3103 3.69875 11.7003L8.28875 16.2903C8.91875 16.9203 9.99875 16.4803 9.99875 15.5903V13.9003C14.9987 13.9003 18.4988 15.5003 20.9988 19.0003C19.9988 14.0003 16.9987 9.00026 9.99875 8.00026Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_55364_93182'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGReply
