import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGThumbUp: FC<IconProps> = ({ color = '#219653', width = 21, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_86082_291004)'>
        <path d='M1.16602 17.4987H4.49935V7.4987H1.16602V17.4987ZM19.4993 8.33203C19.4993 7.41536 18.7493 6.66536 17.8327 6.66536H12.5743L13.366 2.85703L13.391 2.59036C13.391 2.2487 13.2493 1.93203 13.0243 1.70703L12.141 0.832031L6.65768 6.3237C6.34935 6.6237 6.16602 7.04036 6.16602 7.4987V15.832C6.16602 16.7487 6.91602 17.4987 7.83268 17.4987H15.3327C16.0243 17.4987 16.616 17.082 16.866 16.482L19.3827 10.607C19.4577 10.4154 19.4993 10.2154 19.4993 9.9987V8.33203Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_86082_291004'>
          <rect width={width} height={height} fill='white' transform='translate(0.333008)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGThumbUp
