import { css } from '@emotion/css'

export default css(`
  width: 100%;
  
  .wrapper {
    max-width: 600;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .title {
    font-weight: bold;
    font-size: 44px;
    line-height: 56px;
    color: #000;
    margin-top: 50px;
    text-align: center;
  }
  
  .text {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15;
    color: #101010;
    margin-top: 20px;
  }
`)
