import { AccountFields } from '../constants'
import type { DeepRequired, FormValidation } from '@carfluent/common'

import type { AccountFormData } from './types'
import { alphanumericField, required, requiredNumber } from 'utils/validationPresets'

export const validationRules: FormValidation<DeepRequired<AccountFormData>> = {
  [AccountFields.Number]: requiredNumber,
  [AccountFields.Type]: required,
  [AccountFields.Name]: alphanumericField(true),
  [AccountFields.Description]: alphanumericField(false)
}
