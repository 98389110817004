import { type FC, useCallback } from 'react'
import { Modal, UI, FormInput } from '@carfluent/common'
import { format } from 'date-fns'

import { CopyMonthFormData } from 'types'
import { DATE_MONTH_NAME_YEAR_FORMAT } from 'constants/constants'
import Button from 'components/common/Button'
import Icon from 'components/common/Icon'

import useCopyMonthModal from './hook'
import { FieldIds, TITLE } from './hook/constants'

import { CLASS_NAME, PICKER_POPPER } from './styles'

const { MonthPicker } = UI

export interface CopyMonthModalProps {
  isModalOpen: boolean
  onCloseModal: () => void
  currentMonth: Date
  onSubmit: (formData: CopyMonthFormData) => Promise<void>
}

const CopyMonthModal: FC<CopyMonthModalProps> = (props) => {
  const { values, onChangeDate, onSubmit, errors } = useCopyMonthModal(props)

  const onChange = useCallback((id: string) => (date: Date) => onChangeDate(id, date), [onChangeDate])

  return (
    <Modal
      minWidth={600}
      className={CLASS_NAME}
      isOpen={props.isModalOpen}
      onClose={props.onCloseModal}
      title={TITLE}
    >
      <div className='copy-month-modal-content'>
        <MonthPicker
          value={values.sourceMonth}
          onChange={onChange(FieldIds.SourceMonth)}
          classes={{
            popover: PICKER_POPPER
          }}
          triggerComponent={
            <FormInput
              id='sourceMonth'
              value={format(values.sourceMonth, DATE_MONTH_NAME_YEAR_FORMAT)}
              aria-readonly
              error={errors.sourceMonth}
              label='Source'
              touched
              endAdornment={<Icon name='calendarIcon' />}
            />
          }
        />
        <MonthPicker
          value={values.monthToFill}
          onChange={onChange(FieldIds.MonthToFill)}
          triggerComponent={
            <FormInput
              id='monthToFill'
              value={format(values.monthToFill, DATE_MONTH_NAME_YEAR_FORMAT)}
              label='Month to fill'
              error={errors.monthToFill}
              touched
              endAdornment={<Icon name='calendarIcon' />}
            />
          }
        />
        <p className='warning-text'>
          Please note, existing schedules will be overwritten.
        </p>
      </div>
      <div className='copy-month-modal-buttons-wrapper'>
        <Button
          onClick={props.onCloseModal}
          className='cancel-copy-month-btn'
        >
          Cancel
        </Button>
        <Button onClick={onSubmit}>
          Copy
        </Button>
      </div>
    </Modal>
  )
}

export default CopyMonthModal
