import { type EmailTemplateModel, type LeadEmailTemplateDto } from 'api/types/responses'
import { serializeToHtml } from 'utils/wysiwyg'

export const serializeData = (formData: EmailTemplateModel): LeadEmailTemplateDto => {
  return {
    body: serializeToHtml(formData.body),
    subject: formData.subject,
    name: formData.name,
    id: formData.id,
    dealerId: formData.dealerId,
    logoUrl: formData.logoUrl
  }
}
