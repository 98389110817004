import type { FC } from 'react'

import type { ImageDescriptor } from 'components/inventory/ImageItem'
import ModalWindow from 'components/dialogs/ModalWindow'
import VehicleImageEditor from 'components/inventory/ImageEditor'

import useStyles from './styles'

interface EditModalProps {
  editPhoto: ImageDescriptor | null
  closeModal: () => void
  updatePhoto: (photos: File) => Promise<void>
  isOpen: boolean
}

const MODAL_CONFIG = { maxWidth: 1000 }

const EditModal: FC<EditModalProps> = ({ isOpen, editPhoto, closeModal, updatePhoto }) => {
  const styles = useStyles()

  return (
    <ModalWindow
      classes={{ title: styles.title, paper: styles.paper }}
      config={MODAL_CONFIG}
      onClose={closeModal}
      open={isOpen}
      title={editPhoto?.fileName}
    >
      <VehicleImageEditor
        closeModal={closeModal}
        editPhoto={editPhoto}
        updatePhoto={updatePhoto}
      />
    </ModalWindow>
  )
}

export default EditModal
