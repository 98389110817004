import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import CheckboxList, { type CheckboxListProps } from 'components/common/CheckboxList'
import IconSVG from 'components/inlineImages'

import { type TaxabilityProps } from './types'
import CLASS_NAME from './styles'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

const GAT_CHECKBOX_ITEMS = (values: TaxabilityProps['values']): CheckboxListProps['items'] => ([
  {
    id: 'serviceContractTaxable',
    isChecked: values.coverageFeeSettings.serviceContractTaxable,
    name: 'Taxable',
    startAdornment: (
      <>
        <IconSVG.WarrantyServiceContract />
        <span>Service Contract</span>
      </>
    )
  },
  {
    id: 'gapTaxable',
    isChecked: values.coverageFeeSettings.gapTaxable,
    name: 'Taxable',
    startAdornment: (
      <>
        <IconSVG.WarrantyGapInsurance />
        <span>GAP insurance</span>
      </>
    )
  },
  {
    id: 'appearanceProtectionTaxable',
    isChecked: values.coverageFeeSettings.appearanceProtectionTaxable,
    name: 'Taxable',
    startAdornment: (
      <>
        <IconSVG.WarrantyAppearanceProtection />
        <span>Appearance protection</span>
      </>
    )
  },
  {
    id: 'tireAndWheelTaxable',
    isChecked: values.coverageFeeSettings.tireAndWheelTaxable,
    name: 'Taxable',
    startAdornment: (
      <>
        <IconSVG.WarrantyTireAndWheel />
        <span>Tire & Wheel</span>
      </>
    )
  },
  {
    id: 'theftTaxable',
    isChecked: values.coverageFeeSettings.theftTaxable,
    name: 'Taxable',
    startAdornment: (
      <>
        <IconSVG.WarrantyTheftProtection />
        <span>Theft insurance</span>
      </>
    )
  }
])

const Taxability: FC<TaxabilityProps> = ({
  className,
  values,
  onChange,
  isLoading
}) => {
  const CHECKBOX_ITEMS = GAT_CHECKBOX_ITEMS(values)

  return (
    <div className={cnx(CLASS_NAME, 'checkbox-container', className)}>
      <div className='sub-title-container'>
        <BlockSkeleton isLoading={isLoading}>
          <h6 className='section-title h6-title'>Taxability</h6>
        </BlockSkeleton>

        <div className='sub-title'>
          <BlockSkeleton isLoading={isLoading}>
            The changes will apply to deals created after the new settings have been applied.
          </BlockSkeleton>
        </div>
      </div>

      <CheckboxList
        items={CHECKBOX_ITEMS}
        isLoading={isLoading}
        onChange={(id, checked) => onChange(`coverageFeeSettings.${id}`, checked)}
      />
    </div>
  )
}

export default Taxability
