import { type FC } from 'react'
import { CellWrapper } from '@carfluent/common'
import { type BasicValueCellProps } from 'types'

import newLeadIcon from 'assets/lead-new.svg'
import messageLeadIcon from 'assets/lead-message.svg'
import taskLeadIcon from 'assets/lead-task.svg'
import lostLeadIcon from 'assets/lost-lead-icon.svg'

import CLASS_NAME from './styles'

export type LeadStateType = 'new' | 'lost' | 'message' | 'task'

const iconMap = {
  new: newLeadIcon,
  message: messageLeadIcon,
  task: taskLeadIcon,
  lost: lostLeadIcon
}

const LeadStateCell: FC<BasicValueCellProps> = (props) => {
  const markers = props.getValue() as LeadStateType[]

  return (
    <CellWrapper className={CLASS_NAME}>
      {markers.map(m => <img key={m} src={iconMap[m]} className={`cf-${m}`} alt={m} />)}
    </CellWrapper>
  )
}

export default LeadStateCell
