import { css } from '@emotion/css'

export default css(`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;

  > .cf-option-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #101010
  }

  > .cf-option-sub-title {
    display: flex;
    align-items: center;
    
    .name,
    .status {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: rgba(33, 33, 33, 0.5);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: auto;
    }
    
    .status {
      color: rgba(33, 33, 33, 0.8);
      padding-right: 4px;
      min-width: fit-content;
    }
    
  }
  
  .vehicle-status-indicator {
    &:before {
      content: '';
      display: block;
      width: 8px;
      min-width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 6px;
      background: rgba(0, 0, 0, 0.3);
    }

    &.active:before {
      background: #219653;
    }

    &.sold:before,
    &.completed:before {
      background: #FFA451;
    }
    
    &.inactive:before,
    &.incomplete:before {
      background: rgba(0, 0, 0, 0.30);
    }
    
    &.deleted:before,
    &.canceled:before {
      background: rgba(0, 0, 0, 0.12);
    }
  }  
`)
