import type { DeepRequired } from '@carfluent/common'
import { toDateOrNull } from 'utils/parse_date'
import { type DuplicateLeadDto, type LeadData, LeadLabel } from 'api/types'
import { type NewDealFormData } from 'types'

export const parseCustomerData = (
  data: DuplicateLeadDto | null,
  defaultValues?: Partial<NewDealFormData>
): Partial<DeepRequired<NewDealFormData>> => {
  const {
    mileage,
    salePrice,
    vin,
    stock,
    make,
    model,
    trim,
    id,
    year
  } = data?.carOfInterest ?? defaultValues?.vehicle ?? {}

  const addressData = {
    address: data?.address ?? null,
    city: data?.city ?? null,
    zipCode: data?.zipCode ?? null,
    state: data?.state ?? null,
    county: null,
    addressLong: null,
    apt: null
  }

  return {
    addressData,
    customer: data == null ? null : data,
    customerFirstName: data?.firstName ?? null,
    customerLastName: data?.lastName ?? null,
    customerPhoneNumber: data?.phoneNumber ?? null,
    customerEmail: data?.email ?? null,
    customerBirthDate: toDateOrNull(data?.birthDate) ?? null,
    vehicle: {
      vin: vin ?? null,
      stock: stock ?? null,
      mileage: mileage ?? null,
      salePrice: salePrice ?? null,
      make: make ?? null,
      model: model ?? null,
      trim: trim ?? null,
      id: id ?? null,
      year: year ?? null
    }
  }
}

export const parseLeadData = (data: LeadData | null): DuplicateLeadDto | null => {
  if (data == null) {
    return null
  }

  return {
    id: data.id,
    firstName: data.firstName ?? null,
    lastName: data.lastName ?? null,
    phoneNumber: data.phoneNumber ?? null,
    email: data.email ?? null,
    birthDate: data.birthDate ?? null,
    address: data.address ?? null,
    city: data.city ?? null,
    zipCode: data.zipCode ?? null,
    state: data.state ?? null,
    leadSourceId: data.leadSourceId,
    leadStatusId: data.leadStatusId,
    carOfInterest: data.carsOfInterest?.find(c => c.id === data?.primaryCarOfInterestId) ?? data.carsOfInterest[0] ?? null,
    label: LeadLabel[data.leadTemperatureId ?? 1] as keyof typeof LeadLabel
  }
}
