import { useEffect } from 'react'

import { Notifications } from 'constants/names'
import { WebSocketsInventory as ws } from 'services/web_sockets'

export interface UseWSUpdatesProps {
  onVehicleUpdated?: () => Promise<void>
}

const useWSUpdates = ({
  onVehicleUpdated
}: UseWSUpdatesProps): void => {
  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    ws.on(Notifications.VehicleUpdated, {
      name: 'vehiclesList_vehicleUpdated',
      action: (args) => {
        if (args?.vehicleId != null) {
          void onVehicleUpdated?.()
        }
      }
    })

    ws.on(Notifications.VehicleCreated, {
      name: 'vehiclesList_vehicleCreated',
      action: (args) => {
        if (args?.vehicleId != null) {
          void onVehicleUpdated?.()
        }
      }
    })

    ws.on(Notifications.VehicleDeleted, {
      name: 'vehiclesList_vehicleDeleted',
      action: (args) => {
        if (args?.vehicleId != null) {
          void onVehicleUpdated?.()
        }
      }
    })

    return () => {
      ws.off(Notifications.VehicleUpdated, 'vehiclesList_vehicleUpdated')
      ws.off(Notifications.VehicleCreated, 'vehiclesList_vehicleCreated')
      ws.off(Notifications.VehicleDeleted, 'vehiclesList_vehicleDeleted')
    }
  }, [onVehicleUpdated])
}

export default useWSUpdates
