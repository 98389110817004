import { css } from '@emotion/css'

export const TAG_FONT_SIZE = 14

export default css(`
  width: 100%;
  position: relative;
  
  .cf-toggler {
    cursor: pointer;
    margin-right: 0px;
    position: absolute; // algorithm calculates space for it. But this does not hurt and makes it more stable.
  }
  
  .cf-popover {
    position: absolute;
    left: -40px;
    right: -20px;
    bottom: -10px;
    background: #FFFFFF;
    box-shadow: 0px 24px 38px rgba(58, 71, 78, 0.1);
    border-radius: 12px;
    z-index: 1;
    transform: translate(0px, 100%);
    padding: 12px 18px 18px 18px;
    min-width: min-content;
  }
`)
