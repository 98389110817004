import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

import CLASS_NAME from './styles'

const LOADER_ITEMS = new Array(12).fill(null)

const LoaderScreen: FC = () => {
  return (
    <div className={CLASS_NAME}>
      {LOADER_ITEMS.map((_, i) => (
        <div className={cnx('cf-recon-step-filter-item', i === 0 && 'selected')} key={i}>
          <span>
            <TextSkeleton className='no-alert-item' />
          </span>

          <span className='no-alert-bg'>
            <TextSkeleton className='alert-item' />
          </span>

          <span className='first-alert-bg'>
            <TextSkeleton className='alert-item' />
          </span>

          <span className='second-alert-bg'>
            <TextSkeleton className='alert-item' />
          </span>
        </div>
      ))}
    </div>
  )
}

export default LoaderScreen
