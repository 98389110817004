import getEntityName from 'utils/accounting/getEntityName'
import type { ReconciliationDraftRowData, ReconciliationDraftFromApi } from './types'

const parseListData = (data: ReconciliationDraftFromApi[]): ReconciliationDraftRowData[] => {
  return data.map(parseRowData)
}

const parseRowData = (data: ReconciliationDraftFromApi): ReconciliationDraftRowData => {
  return {
    account: data.account,
    clearedDate: data.clearedDate,
    costTypeId: data.costTypeId,
    date: data.date,
    deposit: data.change > 0 ? data.change : null,
    description: data.description ?? '',
    entity: getEntityName(data.customer, data.vendor, ''),
    id: data.id,
    reconcileStatus: data.reconcileStatus,
    referenceNumber: data.referenceNumber,
    transactionId: data.transactionId,
    transactionStateId: data.transactionStateId,
    transactionTypeId: data.transactionTypeId,
    withdrawal: data.change < 0 ? Math.abs(data.change) : null
  }
}

export default parseListData
