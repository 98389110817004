import { type FC } from 'react'
import { Banner } from '@carfluent/common'

import SaveCloseButtonsPanel from 'components/common/SaveCloseButtonsPanel'

import CounterLine from './components/CounterLine'
import UploadedLines from './components/UploadedLines'
import useStatementPreviewForm from './hook'
import { type UseStatementPreviewFormProps } from './hook/types'
import CLASS_NAME from './styles'

const StatementPreviewForm: FC<UseStatementPreviewFormProps> = ({ onBack, ...hookProps }) => {
  const {
    totalLinesCount,
    duplicateLinesCount,
    successfulLinesCount,
    errorLinesCount,
    pendingLinesCount,
    onCancel,
    ...formProps
  } = useStatementPreviewForm(hookProps)

  return (
    <div className={CLASS_NAME}>
      <h5>Bank statement preview</h5>

      <div className='banner-wrapper'>
        {(duplicateLinesCount != null) && (duplicateLinesCount > 0) && (
          <Banner
            message='A statement includes duplicates.'
            type='info'
            className='banner-item'
            isOpen
          />
        )}

        {(errorLinesCount != null) && (errorLinesCount > 0) && (
          <Banner
            message='Cannot upload everything from file, some lines contain errors. '
            type='info'
            className='banner-item'
            isOpen
          />
        )}
      </div>

      <CounterLine
        totalLinesCount={totalLinesCount ?? 0}
        duplicateLinesCount={duplicateLinesCount ?? 0}
        successfulLinesCount={successfulLinesCount ?? 0}
        errorLinesCount={errorLinesCount ?? 0}
        pendingLinesCount={pendingLinesCount ?? 0}
      />

      <UploadedLines {...formProps} />

      <SaveCloseButtonsPanel
        className='button-panel-container'
        handleReset={onCancel}
        handleBack={onBack}
        handleSubmit={formProps.onSubmit}
        isSubmitting={formProps.isSubmitting}
        titleCloseButton='CANCEL'
        titleSaveButton='IMPORT'
        titleBackButton='BACK'
      />
    </div>
  )
}

export default StatementPreviewForm
