import type { FC } from 'react'
import type { IconProps } from 'types'

const StoreVisitIcon: FC<IconProps> = ({ color = '#21212180', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_86546_28087)'>
        <path d='M3.33143 3.99984H12.6648C13.0314 3.99984 13.3314 3.69984 13.3314 3.33317C13.3314 2.9665 13.0314 2.6665 12.6648 2.6665H3.33143C2.96476 2.6665 2.66476 2.9665 2.66476 3.33317C2.66476 3.69984 2.96476 3.99984 3.33143 3.99984ZM13.4381 5.19984C13.3781 4.89317 13.1048 4.6665 12.7848 4.6665H3.21143C2.89143 4.6665 2.6181 4.89317 2.5581 5.19984L1.89143 8.53317C1.81143 8.9465 2.12476 9.33317 2.54476 9.33317H2.66476V12.6665C2.66476 13.0332 2.96476 13.3332 3.33143 13.3332H8.66476C9.03143 13.3332 9.33143 13.0332 9.33143 12.6665V9.33317H11.9981V12.6665C11.9981 13.0332 12.2981 13.3332 12.6648 13.3332C13.0314 13.3332 13.3314 13.0332 13.3314 12.6665V9.33317H13.4514C13.8714 9.33317 14.1848 8.9465 14.1048 8.53317L13.4381 5.19984ZM7.9981 11.9998H3.9981V9.33317H7.9981V11.9998Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_86546_28087'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StoreVisitIcon
