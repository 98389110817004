import type { FC } from 'react'
import { cn, formatCurrencyDecimal } from '@carfluent/common'

import { CostTypeEnum } from 'api/types'
import { generateId } from 'utils/generateId'

import CLASS_NAME from './styles'

const formatDecimalCost = (val: number): string => formatCurrencyDecimal(val, { emptyValues: '$0.00', allowNegative: true })

interface CostItemsProps {
  name: string
  amount: number
}
export interface AllCostBreakdownProps {
  total: number
  floorplanAmount: number
  packCost: number
  className?: string
  hasPackCost?: boolean
  hasFloorplanCost?: boolean
  costList: CostItemsProps[]
  otherInventoryCosts: number | null
  isDisabled?: boolean
}

const AllCostBreakdown: FC<AllCostBreakdownProps> = ({
  costList,
  total,
  className,
  packCost,
  otherInventoryCosts,
  hasPackCost = false,
  isDisabled = false
}) => {
  return (
    <div className={cn(className, CLASS_NAME)}>
      {
        costList.map(({ name, amount }) => (
          <div className={cn('cf-breakdown-item', isDisabled && 'cf-item-disabled')} key={`${generateId(10)}_${name}_${amount}`}>
            <p className='cf-breakdown-label'>{name} {CostTypeEnum.Purchase === name ? '' : 'cost'}</p>
            <p>{formatDecimalCost(amount)}</p>
          </div>
        ))
      }

      {hasPackCost && (
        <div className={cn('cf-breakdown-item', isDisabled && 'cf-item-disabled')}>
          <p className='cf-breakdown-label'>Pack cost</p>
          <p>{formatDecimalCost(packCost)}</p>
        </div>
      )}
      {otherInventoryCosts != null && (
        <div className={cn('cf-breakdown-item', isDisabled && 'cf-item-disabled')}>
          <p className='cf-breakdown-label'>Other inventory cost</p>
          <p>{formatDecimalCost(otherInventoryCosts)}</p>
        </div>
      )}

      <hr />

      <div className='cf-breakdown-item cf-breakdown-total'>
        <p>Total</p>
        <p>{formatDecimalCost(total)}</p>
      </div>
    </div>
  )
}

export default AllCostBreakdown
