import { css } from '@emotion/css'

export default css(`
  &.MuiTooltip-tooltip {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    padding: 4px 12px;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    border-radius: 4px;
    background: #212121; 
  }
`)
