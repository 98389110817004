import { unstable_useBlocker } from 'react-router-dom'
import type { Blocker, RouterState, Location } from '@remix-run/router'

interface UseTransitionBlockerProps {
  /**
   * DD-NOTE: tried to do a getter, but then useBlocker hook is not recalculated
   * and that is why it did not track POP actions for example.
   */
  shouldBlock: boolean
  onBlock: () => void
}

interface UseTransitionBlockerReturn {
  proceedTransition: () => void
  resetTransition: () => void
  location?: Location
  state: Blocker['state']
}

interface BlockerFunctionArgs {
  currentLocation: Location
  nextLocation: Location
  historyAction: RouterState['historyAction']
}

const noop = (): void => {}

const useTransitionBlocker = ({ shouldBlock, onBlock }: UseTransitionBlockerProps): UseTransitionBlockerReturn => {
  const blocker: Blocker = unstable_useBlocker((_e: BlockerFunctionArgs): boolean => {
    if (shouldBlock) {
      onBlock()
      return true
    }
    return false
  })

  return {
    proceedTransition: blocker.proceed ?? noop,
    resetTransition: blocker.reset ?? noop,
    location: blocker.location,
    state: blocker.state
  }
}

export default useTransitionBlocker
