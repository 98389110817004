export enum BotMessageRole {
  User,
  AI
}

interface WebChatMessage {
  botMessageRoleId: BotMessageRole
  content: string
  createdDate: string
}

export interface WebChatDto {
  id: number
  threadId: string
  messages: WebChatMessage[]
}
