import { type FC } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { cnx } from '@carfluent/common'

import CalcRoutes from 'constants/route_helper'
import CLASS_NAME from './styles'

export interface CreditApplicationLinkProps {
  className?: string
  id?: string | null
  name?: string
}

const CreditApplicationLink: FC<CreditApplicationLinkProps> = ({
  className,
  id,
  name = 'View'
}) => {
  const { id: idFromUrl = '' } = useParams<{ id: string }>()

  return (
    <NavLink
      className={cnx(CLASS_NAME, className)}
      to={CalcRoutes.CreditApplication(id ?? idFromUrl)}
    >
      {name}
    </NavLink>
  )
}

export default CreditApplicationLink
