import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'
import set from 'lodash-es/set'

import { isFieldName } from 'hooks/useMobXFormik'

import type {
  UpdateVehicleDictionaries,
  UpdateVehicleFormData,
  UseGeneralTabReturn
} from './types'

import { DEFAULT_FORM_DATA, DEFAULT_DICTIONARIES } from './constants'

class GeneralTabPanel {
  vehicleFormData: UpdateVehicleFormData = { ...DEFAULT_FORM_DATA }
  dictionaries: UpdateVehicleDictionaries = { ...DEFAULT_DICTIONARIES }

  setDictionaries = (dictionaries: UpdateVehicleDictionaries): void => {
    this.dictionaries = dictionaries
  }

  updateFormData: UseGeneralTabReturn['updateFormData'] = (name, value): void => {
    if (isFieldName(name, this.vehicleFormData)) {
      set(this.vehicleFormData, name, value)
      return
    }

    if (typeof name === 'object') {
      Object.assign(this.vehicleFormData, name)
      return
    }

    console.info('unknown field :: ', name)
  }

  // ========================================== //

  cleanUp = (): void => {
    this.vehicleFormData = { ...DEFAULT_FORM_DATA }
    this.dictionaries = { ...DEFAULT_DICTIONARIES }
  }

  constructor () {
    makeAutoObservable(this)
  }
}

export const GeneralTabPanelInstance = new GeneralTabPanel()
export const GeneralTabPanelCTX = createContext(GeneralTabPanelInstance)
export default GeneralTabPanelCTX
