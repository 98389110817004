import { css } from '@emotion/css'

export default css(`
  cursor: pointer;

  .entity-option {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    margin: 0px;
    margin-right: 20px;
    overflow-x: hidden;
  }

  .entity-subtitles {
    display: flex;
    align-items: center;
  }

  .entity-subtitle, .option-sub-name {
    font-size: 12px;
    line-height: 16px;
    color: #909090;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 4px;
  },

  .entity-subtitle:nth-of-type(2n) {
    padding-left: 4px;
    border-left: 1px solid #909090;
  }
`)
