import { useCallback, useContext, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm, isOk, type UseFormReturn, type FormValidation, type Result } from '@carfluent/common'
import axios from 'axios'

import { type DealerInvite, UserRoles } from 'api/types'
import { type KeyVal, type PickedFormDataProps } from 'types'
import { AuthCTX } from 'store/auth'
import { startSpinner, stopSpinner } from 'store/global_ui'
import { Routes } from 'constants/route_helper'
import IdentityApiProvider from 'api/identity.api'
import { firstNameField, lastNameField, passwordField, phoneField } from 'utils/validationPresets'
import { navigateToUserDefaultRoute } from 'pages/auth/utils'

export interface SetUpAccountFormValues {
  firstName: string
  lastName: string
  password: string
  phoneNumber: number | null
}

export interface UseSetupAccountReturn extends Pick<UseFormReturn<SetUpAccountFormValues>, PickedFormDataProps | 'onSubmit' | 'isSubmitting'> {
  apiErrors: KeyVal | null
  showPassword: boolean
  toggleShowHidePassword: () => void
}

const baseValues = {
  firstName: '',
  lastName: '',
  password: '',
  phoneNumber: null
}

const validationRules: FormValidation<SetUpAccountFormValues> = {
  firstName: firstNameField(true),
  lastName: lastNameField(true),
  password: passwordField(),
  phoneNumber: phoneField()
}

const useSetupAccount = (): UseSetupAccountReturn => {
  const navigate = useNavigate()
  const { loginAction } = useContext(AuthCTX)
  const { token = '' } = useParams<{ token: string }>()

  const [apiErrors, setAPIErrors] = useState<KeyVal | null>(null)
  const [invite, setInvite] = useState<DealerInvite | null>(null)
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowHidePassword = (): void => {
    setShowPassword(prev => !prev)
  }

  const getInvite = useCallback(async (token: string): Promise<void> => {
    startSpinner()
    try {
      const res = await IdentityApiProvider.getInvite(token)
      setInvite(res)
    } catch {
      navigate(Routes.ExpiredLink)
    } finally {
      stopSpinner()
    }
  }, [navigate])

  const onActionResult = useCallback((res: Result<UserRoles[]>) => {
    if (isOk<UserRoles[]>(res)) {
      navigateToUserDefaultRoute(res.result, navigate)
    } else if (axios.isAxiosError(res.result)) {
      setAPIErrors(res.result?.response?.data ?? null)
    }
  }, [navigate])

  const submitAction = useCallback(async (values: SetUpAccountFormValues): Promise<UserRoles[] | undefined> => {
    const { firstName, lastName, password, phoneNumber } = values
    const trimmedFirstName = firstName.trim()
    const trimmedLastName = lastName.trim()

    await IdentityApiProvider.postDealerAccount({
      token,
      password,
      firstName: trimmedFirstName,
      lastName: trimmedLastName,
      phoneNumber
    })

    const loginPayload = {
      username: invite?.email ?? '',
      password: values.password
    }

    return await loginAction(loginPayload)
  }, [invite])

  const {
    isSubmitting,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onSubmit
  } = useForm<SetUpAccountFormValues>({
    baseValues,
    validationRules,
    submitAction,
    onActionResult
  })

  useEffect(() => {
    void getInvite(token)
  }, [token, getInvite])

  return {
    isSubmitting,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onSubmit,
    apiErrors,
    showPassword,
    toggleShowHidePassword
  }
}

export default useSetupAccount
