import type { FC, ReactNode } from 'react'
import { CellWrapper, cnx } from '@carfluent/common'
import TooltipedText from 'components/common/TooltipedText'
import ConfigurableCellHOC from 'components/common/Table/cells/ConfigurableCellHOC'

import { formatUTCDateTimeForView } from 'utils/view_helper'
import { toDate } from 'utils/parse_date'

import appointmentLeadIcon from 'assets/lead-appointment.svg'
import followUpLeadIcon from 'assets/lead-follow-up.svg'
import reminderLeadIcon from 'assets/lead-reminder.svg'
import noActivityLeadIcon from 'assets/lead-no-activity.svg'
import webLeadIcon from 'assets/person_icon.svg'
import emailReceivedIcon from 'assets/email_icon.svg'
import messageReceivedIcon from 'assets/message_icon.svg'
import creditAppReceivedTaskRowIcon from 'assets/credit_app_received_task_row_icon.svg'

import CLASS_NAME from './styles'

export type LeadActivityType = 'no-activity'
| 'reminder'
| 'appointment'
| 'follow-up'
| 'web-lead'
| 'message-received'
| 'email-received'

export interface LeadActivityData {
  type: LeadActivityType
  text: string
  dueDate: string | null
}

export interface SubjectCellProps extends SubjectCellHOCProps {
  getValue: () => ReactNode
}

const iconMap = {
  'no-activity': noActivityLeadIcon,
  'follow-up': followUpLeadIcon,
  'web-lead': webLeadIcon,
  'message-received': messageReceivedIcon,
  'email-received': emailReceivedIcon,
  'credit-app-received': creditAppReceivedTaskRowIcon,
  reminder: reminderLeadIcon,
  appointment: appointmentLeadIcon
}

const DATE_PART = 'MM/dd/yy'
const FORMAT = `${DATE_PART} hh:mm aa`

const SubjectCell: FC<SubjectCellProps> = ({ isCompletedTab = false, getValue }) => {
  const activityData = getValue() as LeadActivityData
  const dueDate = (activityData.dueDate != null) ? toDate(formatUTCDateTimeForView(activityData.dueDate, FORMAT)) : null
  /**
   * AS-TODO ideally we should apply such styles inside table, Table component refactor required
   */
  const dateStatus = dueDate != null ? getDateStatus(dueDate) : 'none'

  return (
    <CellWrapper className={cnx(CLASS_NAME,
      'subject-cell',
      `${activityData.type}`,
      dateStatus === 'past' && 'overdue-border-color',
      dateStatus === 'future' && 'future-task-border-color',
      dateStatus === 'today' && 'border-color',
      isCompletedTab && 'completed-tab'
    )}
    >
      <img src={iconMap[activityData.type]} alt={activityData.type} />
      <TooltipedText
        value={activityData.text}
        rootClassName='table-cell-text'
      />
    </CellWrapper>
  )
}
export interface SubjectCellHOCProps {
  isCompletedTab: boolean
}

export const SubjectCellHOC = (hocProps: SubjectCellHOCProps) => ConfigurableCellHOC(SubjectCell, hocProps)

function getDateStatus (date: Date): 'past' | 'today' | 'future' {
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  if (date < today) return 'past'
  if (date.setHours(0, 0, 0, 0) === today.getTime()) return 'today'
  return 'future'
}
