import { type FC } from 'react'
import { Outlet } from 'react-router-dom'

import { Routes, SettingsSubRoutes } from 'constants/route_helper'
import PageSubmenu from 'components/layout/PageSubmenu'

const LINKS = [
  { name: 'General', url: SettingsSubRoutes.General },
  { name: 'F&I settings', url: SettingsSubRoutes.Fi },
  { name: 'Website', url: SettingsSubRoutes.Promo },
  { name: 'Form packs', url: SettingsSubRoutes.FormPacks },
  { name: 'List of lienholders', url: SettingsSubRoutes.LienholderList },
  { name: 'Profile', url: SettingsSubRoutes.Profile }
]

const Settings: FC = () => {
  return (
    <div className='g-content-layout g-overrides general-settings'>
      <div className='g-settings-body-wrapper'>
        <PageSubmenu
          basePath={Routes.Settings}
          links={LINKS}
        />
        <Outlet />
      </div>
    </div>
  )
}

export default Settings
