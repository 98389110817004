import { useEffect, RefObject } from 'react'

export interface Config {
  capture?: boolean
}

const useClickOutside = (ref: RefObject<HTMLElement>, callback: (event: Event) => void, config?: Config): void => {
  const handleClick = (e: Event): void => {
    if (ref.current != null && !ref.current.contains(e.target as Node)) {
      callback(e)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick, config)
    return () => {
      document.removeEventListener('click', handleClick, config)
    }
  })
}

export default useClickOutside
