import { useCallback, useEffect, useMemo, useRef } from 'react'
import { type Result, useForm, isOk, useModal } from '@carfluent/common'
import { useNavigate } from 'react-router-dom'

import { type CampaignModel, SendScheduleType } from 'api/types/responses'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { Routes } from 'constants/route_helper'

import { type ReviewFormData, type UseStepReviewProps, type UseStepReviewReturn } from './types'
import { mapModelOrDefaultToForm, mapFormToModel } from './utils'
import { validationRules, DEPENDENT_VALIDATIONS } from './validation'

const useStepReview = ({
  campaign,
  hasUnsavedChanges,
  onChangeUnsavedChanges,
  onRequestPreviousStep,
  onSendCampaign
}: UseStepReviewProps): UseStepReviewReturn => {
  const navigate = useNavigate()
  const { showAlert, showSuccess } = useCustomSnackbar()
  const modalUnsavedChanges = useModal()

  const baseValues = useMemo(() => {
    return mapModelOrDefaultToForm(campaign)
  }, [campaign])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const refScheduleType = useRef<string | null>(null)
  const submitAction = useCallback(async (values: ReviewFormData): Promise<CampaignModel> => {
    refScheduleType.current = values.type
    return await onSendCampaign(mapFormToModel(values))
  }, [onSendCampaign])

  const onActionResult = useCallback((res: Result<CampaignModel>) => {
    if (isOk(res)) {
      const message = refScheduleType.current === SendScheduleType.Now
        ? 'Email campaign was sent.'
        : 'Email campaign saved.'

      showSuccess(message)
      navigate(Routes.CRMMarketingCampaigns)
    } else {
      showAlert(res.result)
    }
  }, [showAlert, showSuccess])

  const form = useForm<ReviewFormData>({
    baseValues,
    dependentValidations: DEPENDENT_VALIDATIONS,
    isTrackingChanges: true,
    onActionResult,
    submitAction,
    validationRules
  })

  const onCancel = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const onGoBack = useCallback(() => {
    onRequestPreviousStep()
  }, [onRequestPreviousStep])

  const onChangeScheduleType = (_: unknown, value: string): void => {
    form.onChange('type', value)
    onChangeUnsavedChanges(true)
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Track if form was changed.
   */
  useEffect(() => {
    if (form.isTouched) {
      onChangeUnsavedChanges(form.hasChanges)
    }
  }, [
    form.hasChanges,
    form.isTouched,
    onChangeUnsavedChanges
  ])

  // ========================================== //

  return {
    ...form,
    onCancel,
    onChangeScheduleType,
    onGoBack,
    unsavedChangesProps: {
      ...modalUnsavedChanges,
      shouldBlock: hasUnsavedChanges
    }
  }
}

export default useStepReview
