import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import identity from 'lodash-es/identity'
import pDebounce from 'p-debounce'
import debounce from 'lodash-es/debounce'
import { useLoader, useRefUpdater, type SortingInfo } from '@carfluent/common'

import { PAGE_SIZE } from 'constants/constants'
import type { ListResponse } from 'api/types'
import { convertSortingOrder } from 'utils/general'
import type { RowsLoadingOptions, UseTableApiProps, UseTableApiReturn } from './types'

const API_CALL_DELAY = 1000
const DEFAULT_SORTING: SortingInfo = { id: 'id', order: 'desc' }
const NOTHING_FOUND_MSG = 'Nothing found'
const TOP_OFFSET_TO_MAKE_SKELETONS_VISIBLE = 100

const useTableApi = <TRow, TFilters, TParsedRow = TRow>({
  apiCallDelay = API_CALL_DELAY,
  apiCallDelaySearch,
  containerElement,
  cancelationOptions,
  defaultFilters,
  defaultSorting = DEFAULT_SORTING,
  emptyTableMessage = NOTHING_FOUND_MSG,
  getList,
  nothingFoundMessage = NOTHING_FOUND_MSG,
  pageSize = PAGE_SIZE,
  parseListData = identity,
  serializeFilters = identity,
  shouldRunOnCall = false,
  shouldScrollToSpinner = true
}: UseTableApiProps<TRow, TFilters, TParsedRow>): UseTableApiReturn<TParsedRow, TFilters> => {
  const [rows, setRows] = useState<TParsedRow[]>([])
  const [filters, _setFilters] = useState<TFilters>(defaultFilters)
  const [search, _setSearch] = useState('')
  const [sorting, _setSorting] = useState<SortingInfo>(defaultSorting)
  const [emptyMessage, setEmptyMessage] = useState(emptyTableMessage)
  const { isLoading, startLoader, stopLoader } = useLoader()

  const sortingRef = useRef(sorting)
  const refSearch = useRef(search)
  const refFilters = useRef(filters)
  const refSearchChanged = useRef(false)
  const refFiltersChanged = useRef(false)
  const refRequestDebounceConfig = useRef<{before: boolean}>({ before: false })

  const refRows = useRefUpdater(rows)
  const refRowsLength = useRefUpdater(rows.length)
  const refSerializeFilters = useRefUpdater(serializeFilters)
  const refParseListData = useRefUpdater(parseListData)
  const refContainerElement = useRefUpdater(containerElement)
  const refIsScrollToBottomDisabled = useRef<boolean>(false)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const loadRows = useCallback(async (loadingOptions?: RowsLoadingOptions): Promise<void> => {
    try {
      startLoader()
      const shouldScroll = shouldScrollToSpinner &&
        !refIsScrollToBottomDisabled.current && // disabled by filter/search
        (loadingOptions?.forceScrollToSkeleton !== false) // disabled directly by options

      if (shouldScroll) {
        (refContainerElement.current ?? window).scrollBy({ top: TOP_OFFSET_TO_MAKE_SKELETONS_VISIBLE })
      }

      const { forceRefresh, pageSize: _pageSize } = {
        forceRefresh: true,
        ...loadingOptions
      }

      const skip = forceRefresh ? 0 : refRowsLength.current
      const take = _pageSize ?? pageSize
      const listPayload = {
        ...refSerializeFilters.current(refFilters.current),
        take,
        skip,
        sortField: sortingRef.current.id,
        sortOrder: convertSortingOrder(sortingRef.current.order),
        search: refSearch.current
      }

      const result: ListResponse<TRow> = await getList(
        listPayload,
        cancelationOptions
      )

      const parsedRows = refParseListData.current(result.items ?? [], forceRefresh)

      setRows((prevRows) => {
        const newRows = forceRefresh ? parsedRows : [...prevRows, ...parsedRows]
        refRowsLength.current = newRows.length

        return newRows
      })

      if (parsedRows.length === 0) {
        if (refFiltersChanged.current || refSearchChanged.current) {
          setEmptyMessage(nothingFoundMessage)
        } else {
          setEmptyMessage(emptyTableMessage)
        }
      }

      refIsScrollToBottomDisabled.current = false // reset scroll disabled, set by filter/search/sorting
    } catch (err) {
      // DO NOTHING
    } finally {
      stopLoader()
    }
  }, [
    cancelationOptions,
    pageSize,
    getList,
    shouldScrollToSpinner,
    startLoader,
    stopLoader
  ])

  /*
  * Replaced `pDebounce` with `debounce` from lodash.
  * Reason: With `pDebounce`, triggering an immediate request (e.g., on pressing enter) presents a challenge.
  * This is mainly because promises aren't cancellable by default.
  * By utilizing the synchronous `debounce` along with its 'flush' capability, we can handle this case more elegantly
  * without requiring additional function wrappers or complex flag logic.
  */
  const loadRowsWithDelay = useMemo(
    () => debounce(loadRows, apiCallDelaySearch ?? apiCallDelay),
    [loadRows, apiCallDelay, apiCallDelaySearch]
  )

  const onSearch = async (): Promise<void> => {
    refIsScrollToBottomDisabled.current = true
    await loadRowsWithDelay.flush()
  }

  const loadRowsWithDelayLock = useMemo(
    () => pDebounce(loadRows, apiCallDelay, refRequestDebounceConfig.current),
    [loadRows, apiCallDelay]
  )

  const setSearch = useCallback(async (search: string, skipLoad: boolean = false) => {
    refSearch.current = search
    refSearchChanged.current = true
    _setSearch(search)

    if (!skipLoad) {
      refIsScrollToBottomDisabled.current = true
      await loadRowsWithDelay()
    }
  }, [loadRowsWithDelay])

  const setSorting = useCallback(async (sorting: SortingInfo, skipLoad: boolean = false) => {
    _setSorting(sorting)
    sortingRef.current = sorting

    if (!skipLoad) {
      refIsScrollToBottomDisabled.current = true
      await loadRows()
    }
  }, [loadRows])

  const setFilters = useCallback(async (
    filters: Partial<TFilters>,
    isBefore: boolean = false,
    isDelayed: boolean = false,
    skipLoad: boolean = false // AZ-TODO: convert flags to one config
  ): Promise<void> => {
    refFilters.current = { ...refFilters.current, ...filters }
    refFiltersChanged.current = true
    _setFilters({ ...refFilters.current })

    if (!skipLoad) {
      refRequestDebounceConfig.current.before = isBefore
      refIsScrollToBottomDisabled.current = true

      if (isDelayed) {
        await loadRowsWithDelayLock()
      } else {
        await loadRows()
      }
    }
  }, [loadRowsWithDelayLock, loadRows])

  /**
   * This is a basic cells mutability solution. It is useful only when you need to download
   * rows data once, and then need to provide some way to change loaded rows.
   *
   * Keep in mind, that mutated rows will be reset after next `loadRows`, so this is really a very
   * basic solution for one specific case.
   */
  const setCellValue = useCallback((rowIdx: number, columnId: string, value: unknown) => {
    setRows((rows) => {
      const nextRows = [...rows]
      nextRows[rowIdx] = { ...rows[rowIdx], [columnId]: value }
      refRows.current = nextRows

      return nextRows
    })
  }, [])

  const getRows = useCallback((): TParsedRow[] => {
    return refRows.current
  }, [])

  const refPageSize = useRefUpdater(pageSize)
  const getPageSize = useCallback((): number => {
    return refPageSize.current
  }, [])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  const refLoadRows = useRefUpdater(loadRows)
  const refShouldRunOnCall = useRefUpdater(shouldRunOnCall)

  useEffect(() => {
    if (refShouldRunOnCall.current) {
      refIsScrollToBottomDisabled.current = true
      void refLoadRows.current?.()
    }
  }, [])

  // ========================================== //

  return {
    emptyTableMessage: emptyMessage,
    getRows,
    getPageSize,
    rows,
    search,
    sorting,
    filters,
    isLoading,
    loadRows,
    setRows,
    setCellValue,
    setFilters,
    setSorting,
    setSearch,
    startLoader,
    stopLoader,
    onSearch
  }
}

export default useTableApi
