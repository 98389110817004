import { type AccountListItem } from 'api/types/accounting.types'

export enum CommissionType {
  Fixed = 1,
  Percent = 2
}

export interface DealerFeeMarkupSettingsDto {
  id: number
  maxCardPaymentAmount: number
  excellentInterestRate: number
  goodInterestRate: number
  averageInterestRate: number
  belowAverageInterestRate: number
  interestRateMarkup: number
  inventoryPackCost: number
  tradeInAdjustment: number
  websiteBaseUrl: string | null
  coverageFeeSettings: CoverageFeeSettingsDto
  salesTarget: number
}

export interface DefaultAmountFeeSettingDto {
  labelName: string | null
  isEnabled: boolean
  isReadonly: boolean
  isTaxable: boolean
  accountId: number | null
  account: AccountListItem | null
  defaultAmount: number
}

export type FinanceTypeAmountFeeSettingDto = Omit<DefaultAmountFeeSettingDto, 'defaultAmount'> & {
  defaultCashAmount?: number
  defaultFinancingAmount?: number
}

export type FinanceTypePercentFeeSettingDto = Omit<DefaultAmountFeeSettingDto, 'defaultAmount'> & {
  defaultCashPercent?: number
  defaultFinancingPercent?: number
}

export interface CoverageFeeSettingsDto {
  appearanceProtectionTaxable: boolean
  backGrossSalesCommissionSetting: SalesCommissionSettingsDto
  buyerTagSetting: FinanceTypeAmountFeeSettingDto
  carDeliverySetting: DefaultAmountFeeSettingDto
  dealerHandlingFeeSetting: DefaultAmountFeeSettingDto
  dealerInventoryTaxSetting: FinanceTypePercentFeeSettingDto
  emissionsSetting: DefaultAmountFeeSettingDto
  frontGrossSalesCommissionSetting: SalesCommissionSettingsDto
  gapTaxable: boolean
  inspectionFeeSetting: FinanceTypeAmountFeeSettingDto
  plateTransferFeeSetting: FinanceTypeAmountFeeSettingDto
  registrationFeeSetting: FinanceTypeAmountFeeSettingDto
  serviceContractTaxable: boolean
  titleFeeSetting: FinanceTypeAmountFeeSettingDto
  tireAndWheelTaxable: boolean
  theftTaxable: boolean
}

export interface SalesCommissionSettingsDto {
  salesCommissionTypeId: number
  percent: number | null
  fixed: number | null
  minAmount: number | null
}
