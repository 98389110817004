import { type AccountListItem } from 'api/types'
import { joinPartsToStr } from 'utils/view_helper'

const getAccountOptionName = (value: AccountListItem | null, emptyValue: any = ''): string => {
  if (value == null) {
    return (typeof emptyValue === 'string') ? emptyValue : ''
  }

  return joinPartsToStr(' - ', value?.number, value?.name)
}

export default getAccountOptionName
