import { type WebsiteSettingsModel } from 'api/types/responses'

export const GET_DEFAULT_FORM_DATA = (): WebsiteSettingsModel => ({
  apr: 10,
  term: 72,
  cashDownAmount: 0,
  cashDownPercentage: null,
  isEstimatedMonthlyPaymentEnabled: false,

  heroData: {
    header: '',
    description: ''
  },
  carAds: {
    ads1: {
      header: '',
      description: ''
    },
    ads2: {
      header: '',
      description: ''
    },
    ads3: {
      header: '',
      description: ''
    }
  }
})
