import { useCallback, type FC } from 'react'
import { Draggable } from 'react-beautiful-dnd'

import type { DocumentFormDto } from 'api/types'
import Icon from 'components/common/Icon'

import CLASS_NAME from './styles'
import FormCell from '../cells/FormCell'
import ActionCell from '../cells/ActionCell'

interface DraggableRowProps {
  index: number
  item: DocumentFormDto
  onDeleteForm: (idx: number) => void
}

const DraggableRow: FC<DraggableRowProps> = ({ item, index, onDeleteForm }) => {
  const draggableId = `${item?.id}${index}`
  const getFormCellValue = useCallback(() => item, [item])
  const getActionCellValue = useCallback(() => ({ ...item, id: index, originId: item.id }), [item])

  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={CLASS_NAME}
        >
          <div className='draggable-area' {...provided.dragHandleProps}>
            <Icon name='drag' />
          </div>
          <FormCell getValue={getFormCellValue} />
          <ActionCell withDeleteIcon onDeleteForm={onDeleteForm} getValue={getActionCellValue} />
        </div>
      )}
    </Draggable>
  )
}

export default DraggableRow
