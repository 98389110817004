import { FC, useState, createRef } from 'react'

import type { TooltipProps } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import useClickOutside from 'hooks/useClickOutside'
import { useStyles } from './styles'
import { cnx } from '@carfluent/common'

interface InfoIconWithTooltipProps {
  description?: string
  size?: number
  placement?: TooltipProps['placement']
  className?: string
  tooltipClassName?: string
}

const InfoIconWithTooltip: FC<InfoIconWithTooltipProps> = ({
  description,
  size = 24,
  placement = 'bottom-start',
  className,
  tooltipClassName
}) => {
  const styles = useStyles({ iconSize: size })
  const tooltipRef = createRef<HTMLElement>()
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenTooltip = (): void => {
    if (!isOpen) setIsOpen(true)
  }
  const handleCloseTooltip = (): void => {
    if (isOpen) setIsOpen(false)
  }

  useClickOutside(tooltipRef, handleCloseTooltip)

  const showTooltip = isOpen && description != null && description !== ''

  return (
    <Tooltip
      open={showTooltip}
      ref={tooltipRef}
      placement={placement}
      title={<p className='description'>{description}</p>}
      classes={{ tooltip: styles.tooltipTitle, popper: cnx(styles.popper, tooltipClassName) }}
    >
      <div
        className={cnx(styles.infoRoot, className)}
        onMouseOver={handleOpenTooltip}
        onClick={handleOpenTooltip}
        onMouseLeave={handleCloseTooltip}
      >
        <InfoOutlined onClick={() => setIsOpen(true)} classes={{ root: styles.iconRoot }} />
      </div>
    </Tooltip>
  )
}

export default InfoIconWithTooltip
