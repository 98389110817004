import { serializers as S } from '@carfluent/common'
import type { GrossProfitReportPayload } from 'api/types'
import serializeDateFilters from 'utils/filters/serializeDateFilters'

import type { GrossProfitFilters } from './types'

const serializeFilters = (filters: GrossProfitFilters): GrossProfitReportPayload => {
  const dates = serializeDateFilters({
    from: filters.dateFrom,
    to: filters.dateTo
  }, S.serializeDateTime)

  return {
    userIds: filters.userIds,
    accountingStateIds: filters.accountingStateIds,
    dateFrom: dates?.from ?? null,
    dateTo: dates?.to ?? null
  }
}

export default serializeFilters
