import { css } from '@emotion/css'

export default css(`
  .cf-header-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 4px 0;
  }

  .cf-left-panel {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 8px;

    .tab-list {
      margin-bottom: 0;
    }
  }

  .cf-actions-menu {
    .cf-menu-button {
      width: 160px;
      height: 40px;
      padding: 8px 16px 8px 24px;
      font-size: 14px;
      line-height: 16px;
    }

    .cf-menu-item {
      min-width: 180px;
    }
  }
`)
