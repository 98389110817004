import type { FC } from 'react'
import type { IconProps } from 'types'

const AutomaticallyIcon: FC<IconProps> = ({ width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#FFF2EC"/>
      <path d="M18.6182 11.7387C18.4835 11.4133 18.1948 11.3353 17.9995 11.3167V9.33398C17.9995 8.59865 17.4015 8.00065 16.6662 8.00065H12.6662V7.07398C12.8695 6.89132 12.9995 6.62865 12.9995 6.33398C12.9995 6.06877 12.8941 5.81441 12.7066 5.62688C12.5191 5.43934 12.2647 5.33398 11.9995 5.33398C11.7343 5.33398 11.4799 5.43934 11.2924 5.62688C11.1049 5.81441 10.9995 6.06877 10.9995 6.33398C10.9995 6.62865 11.1295 6.89132 11.3328 7.07398V8.00065H7.33284C6.59751 8.00065 5.99951 8.59865 5.99951 9.33398V11.332L5.94484 11.336C5.77682 11.3481 5.61962 11.4234 5.50485 11.5467C5.39008 11.67 5.32624 11.8322 5.32617 12.0007V13.334C5.32617 13.5108 5.39641 13.6804 5.52143 13.8054C5.64646 13.9304 5.81603 14.0007 5.99284 14.0007H5.99951V17.334C5.99951 18.0693 6.59751 18.6673 7.33284 18.6673H16.6662C17.4015 18.6673 17.9995 18.0693 17.9995 17.334V14.0007C18.1763 14.0007 18.3459 13.9304 18.4709 13.8054C18.5959 13.6804 18.6662 13.5108 18.6662 13.334V12.042C18.6738 11.9385 18.6574 11.8347 18.6182 11.7387ZM7.33284 17.334V9.33398H16.6662L16.6668 11.998L16.6662 12.0007V13.334L16.6668 13.3373L16.6675 17.334H7.33284Z" fill="#DDB3A0"/>
      <path d="M9.66602 13.3327C10.2183 13.3327 10.666 12.7357 10.666 11.9993C10.666 11.263 10.2183 10.666 9.66602 10.666C9.11373 10.666 8.66602 11.263 8.66602 11.9993C8.66602 12.7357 9.11373 13.3327 9.66602 13.3327Z" fill="#DDB3A0"/>
      <path d="M14.334 13.3327C14.8863 13.3327 15.334 12.7357 15.334 11.9993C15.334 11.263 14.8863 10.666 14.334 10.666C13.7817 10.666 13.334 11.263 13.334 11.9993C13.334 12.7357 13.7817 13.3327 14.334 13.3327Z" fill="#DDB3A0"/>
      <path d="M9.33398 14.666H14.6673V15.9993H9.33398V14.666Z" fill="#DDB3A0"/>
    </svg>
  )
}

export default AutomaticallyIcon
