import { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useCommand, useCommandListener, useMediator, useRefUpdater } from '@carfluent/common'
import { useImmer } from 'use-immer'

import type { DateRangeFilter } from 'types'
import AccountingApiProvider from 'api/accounting.api'
import { GET_DEFAULT_OPEN_BALANCES_WITH_STATISTICS } from 'api/defaults'
import { EntityTypeId, OpenBalancesListPayload, TransactionTypeId } from 'api/types'
import type { DateBalance, EntityBalance, AccountBalance, OpenBalancesListWithStatistics } from 'api/types'

import { GET_DEFAULT_PRESETS, TransactionActionNames } from 'constants/constants'
import type { FiltersChangePayload, OpenBalanceRow, ReconciliationId } from 'components/accounting/OpenBalancesTable'
import useAsyncEffect from 'hooks/useAsyncEffect'
import { toDate } from 'utils/parse_date'

import type { UseSchedulesTabProps, UseSchedulesTabReturn } from './types'
import {
  accountToAccountBalance,
  entityListItemToBalanceEntity,
  getAcountId,
  getEntityId,
  getEntityName,
  getBusinessName
} from './utils'
import Events from 'constants/events'

const DEFAULT_PRESETS = GET_DEFAULT_PRESETS()
const INIT_PRESET = DEFAULT_PRESETS[1]

const useSchedulesTab = ({
  isSelected,
  isPayables = false,
  onCreateBalanceTransaction: _onCreateBalanceTransaction,
  onLoadOpenBalances: _onLoadOpenBalances,
  onViewBalanceTransaction,
  onStartLoader,
  onStopLoader,
  resetCommand,
  ...statistics
}: UseSchedulesTabProps): UseSchedulesTabReturn => {
  /**
   * Initial value is set to `undefined` to not trigger OpenBalancesTable's
   * logic "load data when props.filters are updated".
   */
  const [tableFilters, setTableFilters] = useImmer<FiltersChangePayload | null | undefined>(undefined)

  const [tableData, setTableData] = useState(GET_DEFAULT_OPEN_BALANCES_WITH_STATISTICS)
  const [summaryEntity, setSummaryEntity] = useState<EntityBalance | null>(null)
  const [summaryAccount, setSummaryAccount] = useState<AccountBalance | null>(null)
  const [summaryPeriod, setSummaryPeriod] = useState<DateBalance | null>(null)
  const [isFiltersLoading, setIsFiltersLoading] = useState(false)
  const [checkedOpenBalances, setCheckedOpenBalances] = useState<Set<ReconciliationId>>(new Set())
  const cmdResetTable = useCommand()
  const { accountBalances, entityBalances } = tableData.statistics

  const refInitialTablePayload = useRef<OpenBalancesListPayload | undefined>(undefined)
  const refCheckedOpenBalances = useRef<Set<ReconciliationId>>(new Set())

  const { send } = useMediator()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const refStartLoader = useRefUpdater(onStartLoader)
  const startLoader = useCallback(() => {
    setIsFiltersLoading(true)
    refStartLoader.current?.()
  }, [])

  const refStopLoader = useRefUpdater(onStopLoader)
  const stopLoader = useCallback(() => {
    setIsFiltersLoading(false)
    refStopLoader.current?.()
  }, [])

  const resetCheckedOpenBalances = useCallback(() => {
    refCheckedOpenBalances.current = new Set()
    setCheckedOpenBalances(new Set())
  }, [])

  const onLoadOpenBalances = useCallback((
    data: OpenBalancesListWithStatistics,
    payload: OpenBalancesListPayload
  ) => {
    setTableData(data)
    _onLoadOpenBalances(data)
    resetCheckedOpenBalances()

    if (refInitialTablePayload.current === undefined) {
      refInitialTablePayload.current = payload
    }

    stopLoader()
  }, [_onLoadOpenBalances, stopLoader, resetCheckedOpenBalances])

  const onChangeSummaryAccount = useCallback((data: AccountBalance) => {
    startLoader()

    const nextAccount = (summaryAccount != null) && (getAcountId(summaryAccount) === getAcountId(data)) ? null : data
    setSummaryAccount(nextAccount)

    /**
     * Updates Account in OpenBalancesTable filters.
     */
    setTableFilters((draft) => {
      draft = draft ?? {
        presets: [],
        appliedFilters: {}
      }

      draft.appliedFilters = draft.appliedFilters ?? {}

      if (nextAccount == null) {
        draft.appliedFilters.account = null
        return
      }

      draft.appliedFilters.account = {
        ...nextAccount.account
      }

      return draft
    })
  }, [summaryAccount, startLoader, setTableFilters])

  const onChangeSummaryEntity = useCallback((data: EntityBalance) => {
    startLoader()

    const nextEntity = (summaryEntity != null) && (getEntityId(summaryEntity) === getEntityId(data)) ? null : data
    setSummaryEntity(nextEntity)

    /**
     * Updates Account in OpenBalancesTable filters.
     */
    setTableFilters((draft) => {
      draft = draft ?? {
        presets: [],
        appliedFilters: {}
      }

      draft.appliedFilters = draft.appliedFilters ?? {}

      if (nextEntity == null) {
        draft.appliedFilters.entity = null
        return
      }

      draft.appliedFilters.entity = {
        email: null,
        entityType: (nextEntity.vendorId != null) ? EntityTypeId.Vendor : EntityTypeId.Customer,
        id: nextEntity.vendorId ?? nextEntity.customerId ?? 0,
        name: getBusinessName(nextEntity) ?? getEntityName(nextEntity),
        phoneNumber: null
      }

      return draft
    })
  }, [summaryEntity, startLoader, setTableFilters])

  const onResetSubmitPeriod = useCallback(() => {
    setSummaryPeriod(null)
  }, [])

  const onChangeSummaryPeriod = useCallback((data: DateBalance | null) => {
    startLoader()
    setSummaryPeriod(data)
    void send(
      Events.ScheduleBalanceDateRequested,
      data != null
        ? {
            from: toDate(data.dateRange.from),
            to: toDate(data.dateRange.to)
          }
        : null)

    /**
     * Updates Account in OpenBalancesTable filters.
     */
    setTableFilters((draft) => {
      draft = draft ?? {
        presets: [],
        appliedFilters: {}
      }

      draft.appliedFilters = draft.appliedFilters ?? {}

      if (data == null) {
        draft.activeDatePreset = INIT_PRESET
        draft.appliedFilters.date = undefined
      } else {
        draft.activeDatePreset = null
        draft.appliedFilters.date = {
          from: toDate(data.dateRange.from),
          to: toDate(data.dateRange.to),
          range: data.dateRange.range
        }
      }

      return draft
    })
  }, [setTableFilters, startLoader])

  const onChangeTableFilters = useCallback((data: FiltersChangePayload) => {
    setTableFilters(data)

    /**
     * Updates Summary Entities active filter.
     */
    const appliedEntity = data.appliedFilters?.entity
    if (appliedEntity == null) {
      setSummaryEntity(null)
    } else {
      const { id } = appliedEntity
      const appliedSummaryEntity = entityBalances.find(item => (item.customerId === id) || (item.vendorId === id))
      setSummaryEntity(appliedSummaryEntity ?? entityListItemToBalanceEntity(appliedEntity))
    }

    /**
     * Updates Summary Accounts active filter.
     */
    const appliedAccount = data.appliedFilters?.account
    if (appliedAccount == null) {
      setSummaryAccount(null)
    } else {
      const appliedSummaryAccount = accountBalances.find(item => item.account.number === appliedAccount.number)
      setSummaryAccount(appliedSummaryAccount ?? accountToAccountBalance(appliedAccount))
    }

    /**
     * Updates Summary Accounts active filter.
     * If `datePreset` or `date` fields were changed inside OpenBalancesTable -
     * reset PieChart selected sector.
     */
    try {
      const appliedPeriod = data.appliedFilters?.date
      const dt1 = new Date(appliedPeriod?.from ?? '')
      const dt2 = new Date(summaryPeriod?.dateRange.from ?? '')
      if (dt1.toISOString() !== dt2.toISOString()) {
        setSummaryPeriod(null)
      }
    } catch (err) {
      setSummaryPeriod(null)
    }
  }, [entityBalances, accountBalances, summaryPeriod])

  /**
   * This is a partial duplication of internal logic of the
   * OpenBalancesTable component. Since we can't use this logic,
   * when component is unmounted - we should to re-implement it here.
   *
   * A "pros" of this is that we can send a light-weight request, since we need only
   * counters data, and don't need statistics.
   */
  const loadCountersForInactive = useCallback(async (date?: DateRangeFilter) => {
    if (refIsSelected.current) {
      return
    }

    try {
      refStartLoader.current?.()
      const payload = { includeStatistics: false, date: date ?? null }
      const response = await AccountingApiProvider.getOpenBalances(payload, isPayables)
      refOnLoad.current(response)
    } catch (err) {
      // DO NOTHING
    } finally {
      stopLoader()
    }
  }, [isPayables, stopLoader])

  const onToggleCheckedOpenBalances = useCallback((
    _: number,
    nextChecked: boolean,
    row: OpenBalanceRow
  ) => {
    if (row.reconciliationId == null) {
      return
    }

    if (nextChecked) {
      refCheckedOpenBalances.current.add(row.reconciliationId)
    } else {
      refCheckedOpenBalances.current.delete(row.reconciliationId)
    }

    setCheckedOpenBalances(new Set(refCheckedOpenBalances.current))
  }, [])

  const onCreateBalanceTransaction = useCallback((transactionTypeId: number = TransactionTypeId.Receive) => {
    _onCreateBalanceTransaction(
      transactionTypeId,
      new Set(refCheckedOpenBalances.current),
      tableFilters ?? null
    )
  }, [
    isPayables,
    _onCreateBalanceTransaction,
    tableFilters
  ])

  const bottomPanelActions = useMemo(() => {
    return [
      {
        title: TransactionActionNames.PayBill,
        handleOnClick: () => onCreateBalanceTransaction(TransactionTypeId.PayBill)
      },
      {
        title: TransactionActionNames.Check,
        handleOnClick: () => onCreateBalanceTransaction(TransactionTypeId.Check)
      }
    ]
  }, [onCreateBalanceTransaction])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  const refIsSelected = useRefUpdater(isSelected)
  const refOnLoad = useRefUpdater(_onLoadOpenBalances)

  /**
   * Loads counter for *inactive* Tab, on mount.
   * In this case OpenBalancesTable is unmounted, and it can't load
   * counters-related data for us. So we need to do it manually.
   */
  useAsyncEffect(loadCountersForInactive, [])

  /**
   * Resets internal state on leaving Tab, by command from parent.
   * Current use-cases:
   * - leaving tab
   * - submitting of JE modals
   */
  useCommandListener(resetCommand, async () => {
    const initialDateFilter = refInitialTablePayload.current?.date

    setSummaryAccount(null)
    setSummaryEntity(null)
    setSummaryPeriod(null)
    resetCheckedOpenBalances()

    cmdResetTable.send() // AZ-NOTE: this will eventually lead to reseting of `tableFilters` and `tableData`

    /**
     * AZ-NOTE: possible optimization - save initially loaded rows,
     * and on tab leaving set counters to the length of that initial array.
     * It's not "fair" but maybe it's OK and will allow us to skip this additional request.
     */
    await loadCountersForInactive(initialDateFilter ?? undefined)
  })

  /**
   * Starts loader that is shown in *active* Tab's header.
   * It will be stopped by onLoadOpenBalances handler
   * (actual data loading is handled by child component, in this case).
   */
  useEffect(() => {
    if (isSelected) {
      startLoader()
    }
  }, [isSelected, startLoader])

  // ========================================== //

  return {
    ...statistics,
    checkedOpenBalances,
    isFiltersLoading,
    isPayables,
    onResetSubmitPeriod,
    onLoadOpenBalances,
    onChangeSummaryAccount,
    onChangeSummaryEntity,
    onChangeSummaryPeriod,
    onChangeTableFilters,
    onCreateBalanceTransaction,
    onToggleCheckedOpenBalances,
    onViewBalanceTransaction,
    resetCommand: cmdResetTable.signal,
    summaryAccount,
    summaryEntity,
    summaryPeriod,
    tableData,
    tableFilters,
    bottomPanelActions
  }
}

export default useSchedulesTab
