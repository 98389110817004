import type { FC } from 'react'
import type { IconProps } from 'types'

const DecreaseIcon: FC<IconProps> = ({ width = 16, height = 16, color = '#B00020' }) => {
  return (
    <svg width={width} height={width} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.66732 2.66732L8.66732 10.7807L12.394 7.05399L13.334 8.00065L8.00065 13.334L2.66732 8.00065L3.60732 7.06065L7.33399 10.7807L7.33399 2.66732L8.66732 2.66732Z"
        fill={color}
      />
    </svg>
  )
}

export default DecreaseIcon
