import { css } from '@emotion/css'

export default css(`
  position: absolute;
  width: 400px;
  background: #FFFFFF;
  box-shadow: 0px 24px 38px rgba(58, 71, 78, 0.1);
  border-radius: 8px;
  left: 0;
  bottom: -10px;
  transform: translate(0, 100%);
  z-index: 102;
  padding-bottom: 16px;
  
  .cf-content-wrapper {
    max-height: 384px;
    overflow: auto;
  }
  
  .badge {
    &.cf-button-root.cf-button-variant-text {
      width: 100%;
      height: 48px;
      padding-left: 16px;
      display: flex;
      align-items: center;
      text-decoration: none;
      justify-content: space-between;
      
      .cf-button-content {
        width: calc(100% - 30px);
        text-align: left;
      }
      
      .cf-button-start-adornment {
        display: none;
      }
      
      &.cf-button-disabled {
        img {
          opacity: 0.38;
        }
      }
      img {
        margin-right: 8px;
      }
      
     :hover {
        background: #F3F3F3;
     }
    }
  }
  
  .cf-input-wrapper {
    padding: 16px;
  }
`)
