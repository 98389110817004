import { type EntityId, type RouteItem } from 'types'

export enum Routes {
  NoDealerAndVin = '/404',
  ForbiddenAccess = '/403',
  ExpiredLink = '/expired-link',
  Login = '/login',
  Reset = '/reset',
  Settings = '/settings',
  SettingsGeneral = '/settings/general',
  SettingsGeneralUpdate = '/settings/general/update',
  SettingsFi = '/settings/fi',
  SettingsFiUpdate = '/settings/fi/update',
  SettingsWebsite = '/settings/promo',
  SettingsFormPacks = '/settings/form-packs',
  SettingsFormPacksItem = '/settings/form-packs/:id',
  SettingsLienholderList = '/settings/lienholder-list',
  SettingsProfile = '/settings/profile',
  UserManagement = '/user_management',
  DealsOther = '/deals/*',
  Deals = '/deals',
  DealItem = '/deals/:id',
  DealItemUpdate = '/deals/:id/update',
  NewVehicle = '/new-vehicle',
  SetupAccount = '/setup-account',
  VehiclesOther = '/vehicles/*',
  Vehicles = '/vehicles',
  VehiclesItem = '/vehicles/:id',
  ReportTitleReport = '/reports/title-report',
  AppraisalCarfaxOathCallback = '/vehicles/appraisals/carfax-oath-callback',
  Accounting = '/accounting',
  AccountingTransactions = '/accounting/transactions',
  AccountingSchedulesDashboard = '/accounting/schedules',
  AccountingChartOfAccounts = '/accounting/chart-of-accounts',
  AccountingTrialBalance = '/accounting/chart-of-accounts/*',
  AccountingSettingsOther = '/accounting/account-settings/*',
  AccountingSettings = '/accounting/account-settings',
  AccountingGeneralSettings = '/accounting/account-settings/general',
  AccountingSettingsOpeningBalances = '/accounting/account-settings/opening-balances',
  AccountingSettingsVendors = '/accounting/account-settings/vendors',
  AccountingSettingsCustomers = '/accounting/account-settings/customers',
  AccountingRecurringEntries = '/accounting/account-settings/recurring-entries',
  AccountingBanking = '/accounting/banking',
  AccountingBankingUpload = '/accounting/banking/upload',
  AccountingBankingPreview = '/accounting/banking/preview',
  AccountingReconciliationDraft = '/accounting/reconciliation/:accountId/draft',
  AccountingReconciliationSetup = '/accounting/reconciliation/:accountId/setup',
  ReportTransactions = '/reports/transactions',
  ReportInventoryCosts = '/reports/inventory-costs',
  ReportCarRepricing = '/reports/car-repricing',
  ReportGrossProfit = '/reports/gross-profit',
  ReportBalanceSheet = '/reports/balance-sheet',
  ReportSalesTax = '/reports/sales-tax',
  ReportDealSaleReport = '/reports/sale-report',
  ReportProfitLoss = '/reports/profit-loss',
  ReportTrialBalance = '/reports/trial-balance',
  ReportAccountsAgingReceivable = '/reports/receivable',
  ReportAccountsAgingPayable = '/reports/payable',
  CRM = '/crm',
  CRMSettingsOther = '/crm/settings/*',
  CRMSettings = '/crm/settings',
  CRMGeneralSettings = '/crm/settings/general-settings',
  CRMEmailTemplatesList = '/crm/settings/email-templates',
  CRMMessageTemplatesList = '/crm/settings/message-templates',
  CRMWorkSchedule = '/crm/settings/work-schedule',
  CRMMarketingCampaigns = '/crm/marketing-campaigns',
  CRMMarketingCampaignDetails = '/crm/marketing-campaigns/:id',
  CRMNewMarketingCampaign = '/crm/marketing-campaigns/new',
  CRMTasks = '/crm/tasks',
  CRMLeads = '/crm/leads',
  CRMLeadItem = '/crm/leads/:id',
  CRMTaskLeadsItem = '/crm/tasks/leads/:id',
  ReportLeadsByVehicle = '/reports/leads-by-vehicle',
  ReportLeadsBySource = '/reports/leads-by-source',
  ReportActivity = '/reports/activity',
  ReportDealsSaleReport = '/reports/sale-report',
  Recon = '/recon',
  ReconSettingsOther = '/recon/settings/*',
  ReconSettings = '/recon/settings',
  ReconDetails = '/recon/:id',
  ReconIntakeInspection = '/recon/:id/intake-inspection',
  ReportsOther = '/reports/*',
  Reports = '/reports',
  Dashboard = '/dashboard'
}

export enum NavIcons {
  Accounting = 'accounting',
  AccountingChartOfAccounts = 'accounting-chart-of-accounts',
  Reports = 'reports',
  AccountingSchedule = 'accounting-schedule',
  AccountingSettings = 'accounting-settings',
  AccountingTransactions = 'accounting-transactions',
  AccountingBanking = 'banking',
  Crm = 'crm',
  CrmLeads = 'crm-leads',
  CrmSettings = 'crm-settings',
  CrmTasks = 'crm-tasks',
  CrmMarketingCampaigns = 'crm-marketingCampaigns',
  Deals = 'deals',
  Inventory = 'inventory',
  InventoryList = 'inventory-list',
  InventoryAppraisalList = 'inventory-appraisal',
  Profile = 'profile',
  Recon = 'recon',
  ReconList = 'recon-list',
  ReconSettings = 'recon-settings',
  Settings = 'settings',
  Dashboard = 'dashboard'
}

export enum ResetSubRoutes {
  Sent = 'sent',
  Password = 'password'
}

export enum SettingsSubRoutes {
  Fi = 'fi',
  General = 'general',
  Update = 'update',
  Promo = 'promo',
  FormPacks = 'form-packs',
  LienholderList = 'lienholder-list',
  Profile = 'profile'
}

export enum DealsSubRoutes {
  CreditApplication = 'credit-application',
  Update = 'update',
  OldView = 'old-view'
}

export enum AccountingSubRoutes {
  AccountingSettings = 'account-settings',
  Banking = 'banking',
  ChartsOfAccounts = 'chart-of-accounts',
  Reconciliation = 'reconciliation',
  SchedulesDashboard = 'schedules',
  Transactions = 'transactions'
}

export enum AccountingBunkingSubRoutes {
  Upload = 'upload',
  Preview = 'preview'
}

export enum AccountingSettingsSubRoutes {
  General = 'general',
  OpeningBalances = 'opening-balances',
  Vendors = 'vendors',
  Customers = 'customers',
  RecurringEntries = 'recurring-entries'
}

export enum ReportsSubRoutes {
  AccountsAgingAP = 'payable',
  AccountsAgingAR = 'receivable',
  BalanceSheet = 'balance-sheet',
  InventoryCosts = 'inventory-costs',
  GrossProfit = 'gross-profit',
  ProfitLoss = 'profit-loss',
  SalesTax = 'sales-tax',
  Transactions = 'transactions',
  TrialBalance = 'trial-balance',
  ReportTitleReport = 'title-report',
  ReportLeadsByVehicle = 'leads-by-vehicle',
  ReportLeadsBySource = 'leads-by-source',
  ReportCarRepricing = 'car-repricing',
  ReportDealSaleReport = 'sale-report',
  ReportActivity = 'activity'
}

export enum AccountingReconciliationSubRoutes {
  Setup = ':accountId/setup',
  Draft = ':accountId/draft'
}

export enum CRMSubRoutes {
  Leads = 'leads',
  Tasks = 'tasks',
  MarketingCampaigns = 'marketing-campaigns',
  Settings = 'settings',
}

export enum InventorySubRoutes {
  Appraisals = 'appraisals'
}

export enum CRMSettingsSubRoutes {
  EmailTemplates = 'email-templates',
  MessageTemplates = 'message-templates',
  WorkSchedule = 'work-schedule',
  GeneralSettings = 'general-settings',
  Rules = 'rules',
  Campaigns = 'campaign',
  WebsiteChat = 'website-chat'
}

export enum PathParameterNames {
  Id = ':id',
  Token = ':token',
  Other = '*'
}

export enum AccountingLinkTitles {
  AccountingSettings = 'Accounting settings',
  Banking = 'Banking',
  ChartOfAccounts = 'Chart of Accounts',
  Transactions = 'Transactions',
  SchedulesDashboard = 'Schedules'
}

export enum CRMLinkTitles {
  Leads = 'Leads',
  Tasks = 'Tasks',
  MarketingCampaigns = 'Marketing campaigns',
  Settings = 'CRM settings',
  CRMReports = 'CRM reports'
}

export enum ReconLinkTitles {
  ReconList = 'Recon list',
  ReconSettings = 'Recon settings',
  ReconDetails = 'Recon details'
}

export enum ReconSettingsSubRoutes {
  Steps = 'steps'
}

export const ItemView = (route: Routes, itemId: number | string): string => {
  return route.replace(PathParameterNames.Id, itemId.toString())
}

export const DealView = (dealId: number | string): string => {
  return ItemView(Routes.DealItem, dealId)
}

export const OldDealView = (dealId: number | string): string => {
  const baseUrl = ItemView(Routes.DealItem, dealId)
  return `${baseUrl}/${DealsSubRoutes.OldView}`
}

export const DealUpdate = (dealId: number | string): string => {
  return `${Routes.Deals}/${dealId}/update`
}

export const DealWarranty = (dealId: number | string): string => {
  return `${DealUpdate(dealId)}/warranty`
}

export const CreditApplication = (dealId: number | string): string => {
  return `${Routes.Deals}/${dealId}/credit-application`
}

export const VehicleDetails = (vehicleId: number | string, tabPanel?: number | string): string => {
  return tabPanel == null ? `${Routes.Vehicles}/${vehicleId}` : `${Routes.Vehicles}/${vehicleId}/#${tabPanel}`
}

export const AccountTrialBalance = (accountId: number | string): string => {
  return `${Routes.AccountingChartOfAccounts}/${accountId}`
}

export const AppraisalDetails = (id: number): string => {
  return `${Routes.Vehicles}/${InventorySubRoutes.Appraisals}/${id}`
}

export const ControlOrDeal = (control: WithId | null, deal: WithId | null): string | null => {
  if (control != null) {
    return VehicleDetails(control.id)
  }

  if (deal != null) {
    return DealView(deal.id)
  }

  return null
}

export interface WithId {
  id: EntityId
}

export interface AccountingLink {
  title: string
  to: string
  hidden?: boolean
}

export interface LinkItem {
  id?: number | string
  to: Routes
  title: string
  icon: NavIcons
  isForbidden?: boolean
  subLinks?: LinkItem[]
}

export const NOT_FOUND_STATUS = 404

export const ACCOUNTING_LINKS: LinkItem[] = [
  {
    icon: NavIcons.AccountingSchedule,
    title: AccountingLinkTitles.SchedulesDashboard,
    to: Routes.AccountingSchedulesDashboard
  },
  {
    icon: NavIcons.AccountingBanking,
    title: AccountingLinkTitles.Banking,
    to: Routes.AccountingBanking
  },
  {
    icon: NavIcons.AccountingTransactions,
    title: AccountingLinkTitles.Transactions,
    to: Routes.AccountingTransactions
  },
  {
    icon: NavIcons.AccountingChartOfAccounts,
    title: AccountingLinkTitles.ChartOfAccounts,
    to: Routes.AccountingChartOfAccounts
  },
  {
    icon: NavIcons.AccountingSettings,
    title: AccountingLinkTitles.AccountingSettings,
    to: Routes.AccountingSettings
  }
]

export const CRM_LINKS: LinkItem[] = [
  {
    icon: NavIcons.CrmTasks,
    title: CRMLinkTitles.Tasks,
    to: Routes.CRMTasks
  },
  {
    icon: NavIcons.CrmLeads,
    title: CRMLinkTitles.Leads,
    to: Routes.CRMLeads
  },
  {
    icon: NavIcons.CrmMarketingCampaigns,
    title: CRMLinkTitles.MarketingCampaigns,
    to: Routes.CRMMarketingCampaigns
  },
  {
    icon: NavIcons.CrmSettings,
    title: CRMLinkTitles.Settings,
    to: Routes.CRMSettings
  }
]

export const RECON_LINKS: LinkItem[] = [
  {
    icon: NavIcons.ReconList,
    title: ReconLinkTitles.ReconList,
    to: Routes.Recon
  },
  {
    icon: NavIcons.ReconSettings,
    title: ReconLinkTitles.ReconSettings,
    to: Routes.ReconSettings
  }
]

export const LeadView = (leadId: number | string): string => {
  return Routes.CRMLeadItem.replace(PathParameterNames.Id, leadId.toString())
}

export const TasksLeadView = (leadId: number | string): string => {
  return `${Routes.CRMTasks}/${CRMSubRoutes.Leads}/${leadId}`
}

export const CRMEmailTemplateForm = (templateId: number | string): string => {
  return `${Routes.CRMSettings}/${CRMSettingsSubRoutes.EmailTemplates}/${templateId}`
}

export const CRMMessageTemplateForm = (templateId: number | string): string => {
  return `${Routes.CRMSettings}/${CRMSettingsSubRoutes.MessageTemplates}/${templateId}`
}

export const CRMMarketingCampaignDetails = (campaignId: number | string): string => {
  return Routes.CRMMarketingCampaignDetails.replace(PathParameterNames.Id, campaignId.toString())
}

export const ReconciliationSetup = (accountId: EntityId): string => {
  return Routes.AccountingReconciliationSetup.replace(':accountId', accountId.toString())
}

export const ReconciliationDraft = (accountId: EntityId): string => {
  return Routes.AccountingReconciliationDraft.replace(':accountId', accountId.toString())
}

export const ROUTE_ITEMS: RouteItem[] = [
  {
    path: Routes.Deals,
    isGreyBackgroundPage: true,
    isWidePage: true
  },
  {
    path: Routes.DealItem,
    isGreyBackgroundPage: true,
    isWidePage: true
  },
  {
    path: Routes.DealItemUpdate,
    isGreyBackgroundPage: true,
    isWidePage: true
  },
  {
    path: Routes.Vehicles,
    isGreyBackgroundPage: true,
    isWidePage: true
  },
  {
    path: Routes.VehiclesItem,
    isGreyBackgroundPage: true,
    isWidePage: true
  },
  {
    path: Routes.Accounting,
    isGreyBackgroundPage: true,
    isWidePage: true
  },
  {
    path: Routes.AccountingBankingUpload,
    isGreyBackgroundPage: false,
    isSettingsPage: true
  },
  {
    path: Routes.AccountingBankingPreview,
    isGreyBackgroundPage: false,
    isSettingsPage: true
  },
  {
    path: Routes.AccountingReconciliationSetup,
    isGreyBackgroundPage: false,
    isSettingsPage: true
  },
  {
    path: Routes.CRMSettings,
    isSettingsPage: true
  },
  {
    path: Routes.Settings,
    isGreyBackgroundPage: true,
    isSettingsPage: true
  },
  {
    path: Routes.AccountingSettingsOther,
    isGreyBackgroundPage: true,
    isSettingsPage: true
  },
  {
    path: Routes.AccountingTransactions,
    isGreyBackgroundPage: true,
    isWidePage: true
  },
  {
    path: Routes.AccountingChartOfAccounts,
    isGreyBackgroundPage: true,
    isReportWithTablePage: true
  },
  {
    path: Routes.AccountingGeneralSettings,
    isGreyBackgroundPage: false,
    isSettingsPage: true
  },
  {
    path: Routes.CRMLeads,
    isWidePage: true,
    isGreyBackgroundPage: true
  },
  {
    path: Routes.CRMLeadItem,
    isWidePage: true,
    isGreyBackgroundPage: true
  },
  {
    path: Routes.CRMTasks,
    isWidePage: true,
    isGreyBackgroundPage: true
  },
  {
    path: Routes.CRMEmailTemplatesList,
    isSettingsPage: true,
    isGreyBackgroundPage: true,
    isWidePage: true
  },
  {
    path: Routes.CRMMessageTemplatesList,
    isSettingsPage: true,
    isGreyBackgroundPage: true,
    isWidePage: true
  },
  // {
  //   path: Routes.SettingsProfile,
  //   isGreyBackgroundPage: false,
  //   isWidePage: true
  // },
  {
    path: Routes.ReconSettings,
    isSettingsPage: true
  },
  {
    path: Routes.UserManagement,
    isWidePage: true
  },
  {
    path: `${Routes.Recon}`,
    isWidePage: true,
    isGreyBackgroundPage: true
  },
  {
    path: Routes.ReportGrossProfit,
    isWidePage: true
  },
  {
    path: Routes.Reports,
    isReportWithTablePage: true
  },
  {
    path: Routes.ReportSalesTax,
    isWidePage: true,
    isFullPage: true
  },
  {
    path: Routes.ReportProfitLoss,
    isWidePage: true
  },
  {
    path: Routes.ReportGrossProfit,
    isWidePage: true
  },
  {
    path: Routes.ReportLeadsBySource,
    isWidePage: true
  },
  {
    path: Routes.ReportTitleReport,
    isFullPage: true,
    isWidePage: true
  },
  {
    path: Routes.ReportTransactions,
    isWidePage: true
  },
  {
    path: Routes.ReportTrialBalance,
    isWidePage: true
  },
  {
    path: Routes.ReportBalanceSheet,
    isWidePage: true
  },
  {
    path: Routes.ReportInventoryCosts,
    isWidePage: true
  },
  {
    path: Routes.ReportLeadsByVehicle,
    isFullPage: true,
    isWidePage: true
  },
  {
    path: Routes.ReportDealSaleReport,
    isFullPage: true,
    isWidePage: true
  },
  {
    path: Routes.ReportAccountsAgingPayable,
    isWidePage: true
  },
  {
    path: Routes.ReportAccountsAgingReceivable,
    isWidePage: true
  },
  {
    path: Routes.CRMMarketingCampaigns,
    isGreyBackgroundPage: true,
    isWidePage: true
  },
  {
    path: Routes.CRMMarketingCampaignDetails,
    isGreyBackgroundPage: false
  },
  {
    path: Routes.CRMNewMarketingCampaign,
    isFullPage: true,
    isGreyBackgroundPage: false,
    isWidePage: true
  },
  {
    path: Routes.Dashboard,
    isSettingsPage: false,
    isGreyBackgroundPage: true,
    isWidePage: true
  }
]

export default {
  DealView,
  DealUpdate,
  OldDealView,
  DealWarranty,
  CreditApplication,
  VehicleDetails,
  AccountTrialBalance,
  LeadView,
  CRMEmailTemplateForm,
  CRMMessageTemplateForm,
  AppraisalDetails
}
