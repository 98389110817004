import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .cf-popover-area {
    display: block;
    width: calc(100% - 56px);
  }
`)

export const POPOVER_CLASS_NAME = css(`
  &.cf-popover-container {
    display: block;
    background-color: #212121;
    padding: 4px 12px;
    color: #fff;
    z-index: 15000;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`)
