import { type FC, type ReactNode, useCallback, useMemo } from 'react'
import type { EditorState } from 'draft-js'
import { observer } from 'mobx-react-lite'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { Loader, Button, Modal, cnx } from '@carfluent/common'

import { Form } from 'components/form'
import EditorComponent from 'components/common/EditorComponent'
import SectionLayout from 'components/inventory/SectionLayout'
import Notes from 'components/common/Notes'

import VinSection from './components/VinSection'
import DetailsSection from './components/DetailsSection'
import TitlesSection from './components/TitlesSection'
import CarInfoSection from './components/CarInfoSection'
import FeatureOptionSelection from './components/FeatureOptionSelection'
import AutoRepricingSection from './components/AutoRepricingSection'
import Collapse from './components/Collapse'
import LoaderSkeleton from './components/LoaderSkeleton'

import type { VehicleUpdateFormProps } from './types'
import { getFeaturesOptionsCount } from './utils'
import CLASS_NAME, { editorWrapperStyles, GENERATE_AI_MODAL_CLASS_NAME } from './styles'

const VehicleUpdateForm: FC<VehicleUpdateFormProps> = (props) => {
  const {
    onApplyVin,
    carInfoDictionaries,
    makeDictionaries,
    titleDictionaries,
    priceHistory,
    isLoading,
    generateAiModalProps,
    onGenerateAIDescription,
    isGenerateAiLoading,
    onChangeFeature,
    onChangeOption,
    originalVehicle,
    repriceTooltip,
    isDisabledTab,
    isCarSold,
    notes,
    handleUpdateNotes,
    ...formProps
  } = props

  const { values, setFieldValue } = formProps
  const featuresAdornment = getFeaturesOptionsCount(values.features)
  const optionsAdornment = getFeaturesOptionsCount(values.options)
  const isRepricingDisabled = repriceTooltip != null || isDisabledTab || isCarSold === true
  const isRepricingCheckboxEnabled = values.vehicleRepricingDetails?.isEnabled ?? false
  const isRepriceActive = isRepricingCheckboxEnabled && !isRepricingDisabled

  const onDescriptionChange = useCallback((value: EditorState) => {
    void setFieldValue('description', value)
  }, [setFieldValue])

  // ========================================== //

  const renderFooterAction = useMemo((): ReactNode => {
    return (
      <>
        <Button
          variant='outlined'
          onClick={generateAiModalProps.onCloseModal}
        >
          CANCEL
        </Button>

        <Button
          isLoading={isGenerateAiLoading}
          onClick={onGenerateAIDescription}
        >
          GENERATE
        </Button>
      </>
    )
  }, [
    isGenerateAiLoading,
    generateAiModalProps.onCloseModal,
    onGenerateAIDescription
  ])

  if (isLoading) {
    return <LoaderSkeleton />
  }

  return (
    <Form {...formProps} className={CLASS_NAME}>
      <div className='cf-left-panel'>
        <SectionLayout>
          <VinSection
            onApplyVin={onApplyVin}
            isDisabled={isDisabledTab}
          />
        </SectionLayout>

        <SectionLayout title='Details'>
          <DetailsSection
            isDisabled={isDisabledTab}
            priceHistory={priceHistory}
            daysForNextReprice={
              isRepriceActive ? values.vehicleRepricingDetails?.nextPriceDropInDays : null
            }
          />

          <AutoRepricingSection
            isDisabled={isDisabledTab}
            isRepricingDisabled={isRepricingDisabled}
            repriceTooltip={repriceTooltip}
          />
        </SectionLayout>

        <SectionLayout title='Titles'>
          <TitlesSection
            isDisabled={isDisabledTab}
            dictionaries={titleDictionaries}
          />

        </SectionLayout>

        <SectionLayout title='Car info'>
          <CarInfoSection isDisabled={isDisabledTab} dictionaries={carInfoDictionaries} />

          <div className='cf-expected-wrapper'>
            <FormControlLabel
              label='Vehicle has been inspected (specify details in Description)'
              disabled={isDisabledTab}
              control={
                <Checkbox
                  classes={{ root: 'cf-vehicle-update-checkbox-icon' }}
                  color='default'
                  checked={values.isVehicleInspected}
                  onChange={async (e) => {
                    await setFieldValue('isVehicleInspected', e.target.checked)
                  }}
                />
              }
            />
          </div>
        </SectionLayout>

        <SectionLayout
          title='Description'
          className='cf-description-section'
        >
          <Button
            className='g-generate-ai-button'
            variant='text'
            isDisabled={isDisabledTab}
            onClick={(e) => {
              e?.preventDefault()
              generateAiModalProps.onOpenModal()
            }}
          >
            GENERATE AI
          </Button>

          <EditorComponent
            className={cnx(editorWrapperStyles, isDisabledTab && 'editor-disabled')}
            description={values.description}
            placeholder='Begin typing...'
            onChange={onDescriptionChange}
            isReadOnly={isDisabledTab}
          />
        </SectionLayout>

        <SectionLayout>
          <Collapse
            title='Features'
            endAdornment={(
              isLoading
                ? <Loader />
                : <span className='end-adornment'>({featuresAdornment})</span>
            )}
          >
            <FeatureOptionSelection
              isDisabled={isDisabledTab}
              features={values.features}
              onChange={onChangeFeature}
            />
          </Collapse>
        </SectionLayout>

        <SectionLayout>
          <Collapse
            title='Options'
            endAdornment={(
              isLoading
                ? <Loader />
                : <span className='end-adornment'>({optionsAdornment})</span>
            )}
          >
            <FeatureOptionSelection
              isDisabled={isDisabledTab}
              features={values.options}
              onChange={onChangeOption}
            />
          </Collapse>
        </SectionLayout>

        <Notes
          handleUpdateNotes={handleUpdateNotes}
          notes={notes}
          placeholderName='car'
        />

        <Modal
          onClose={generateAiModalProps.onCloseModal}
          isOpen={generateAiModalProps.isModalOpen}
          title='Generate AI Description'
          className={GENERATE_AI_MODAL_CLASS_NAME}
          renderFooterActions={renderFooterAction}
        >
          <div className='g-modal-ai-content'>
            Tips for AI Description Generation
            <ul>
              <li>Make sure car details are correct and saved</li>
              <li>Select all applicable features equipment</li>
            </ul>
            Verify the description doesn't contain incorrect or inaccurate information before clicking insert below. All use of the content is subject to <a target='_blank' href='https://openai.com/policies/terms-of-use' rel='noreferrer'>OpenAI's Terms of Use.</a>
          </div>
        </Modal>
      </div>
    </Form>
  )
}

export default observer(VehicleUpdateForm)
