import { type FC } from 'react'
import { cnx, isOk, type Result, type UseModalReturn } from '@carfluent/common'

import useTransitionBlocker from 'hooks/useTransitionBlocker'
import UnsavedChangesDialog from 'components/dialogs/UnsavedChanges'
import SaveCloseButtonsPanel from 'components/common/SaveCloseButtonsPanel'
import CLASS_NAME from './styles'

export interface ActionsFooterAndUnsavedChangesProps {
  isActionsFooterShown?: boolean
  isDeleteShown?: boolean
  isSubmitting?: boolean
  onClose?: () => void
  onDelete?: () => void
  onGoBack?: () => void
  onSubmit: () => Promise<Result<unknown>>
  titleSaveButton?: string
  unsavedChangesProps: UnsavedChangesProps
}

export interface UnsavedChangesProps extends UseModalReturn {
  shouldBlock: boolean
  shouldCallClose?: boolean
  onDontSaveAndClose?: () => void
}

export const ActionsFooterAndUnsavedChanges: FC<ActionsFooterAndUnsavedChangesProps> = ({
  isActionsFooterShown = true,
  isDeleteShown = false,
  isSubmitting = false,
  onClose,
  onDelete,
  onGoBack,
  onSubmit: _onSubmit,
  unsavedChangesProps,
  titleSaveButton = 'NEXT'
}) => {
  const {
    onDontSaveAndClose: _onDontSaveAndClose,
    shouldBlock,
    shouldCallClose = true
  } = unsavedChangesProps

  const { proceedTransition, resetTransition } = useTransitionBlocker({
    shouldBlock,
    onBlock: unsavedChangesProps.onOpenModal
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onDontSaveAndClose = (): void => {
    _onDontSaveAndClose?.()
    if (shouldCallClose) {
      unsavedChangesProps.onCloseModal()
    }

    proceedTransition()
  }

  const onCloseModal = (): void => {
    unsavedChangesProps.onCloseModal()
    resetTransition()
  }

  const onSubmitFromModal = (): void => {
    const doJob = async (): Promise<void> => {
      const res = await _onSubmit()

      if (isOk(res)) {
        proceedTransition()
      } else {
        resetTransition()
      }

      onClose?.()
    }

    void doJob()
  }

  const onSubmitFromFooter = (): void => {
    const doJob = async (): Promise<void> => {
      await _onSubmit()
      resetTransition()
    }

    void doJob()
  }

  // ========================================== //

  return (
    <>
      <UnsavedChangesDialog
        content='Do you want to save this campaign before closing?'
        isOpen={unsavedChangesProps.isModalOpen}
        isSubmitting={isSubmitting}
        isSubmittingCanceled // AZ-TODO: remove, after Drafts implementation
        onCloseDialog={onCloseModal}
        onDontSaveAndClose={onDontSaveAndClose}
        onSaveAndClose={onSubmitFromModal}
        title='Close campaign'
        submitTitle='SAVE AS DRAFT'
        cancelTitle='DO NOT SAVE CHANGES'
      />

      <div className={cnx(CLASS_NAME, 'page-footer', isActionsFooterShown && 'is-shown')} role='footer'>
        <SaveCloseButtonsPanel
          className='footer'
          handleBack={onGoBack}
          handleDelete={onDelete}
          handleReset={onClose}
          handleSubmit={onSubmitFromFooter}
          isDeleteHidden={!isDeleteShown}
          isSubmitDisabled={isSubmitting}
          titleBackButton='BACK'
          titleCloseButton='CLOSE'
          titleDeleteButton='DELETE'
          titleSaveButton={titleSaveButton}
        />
      </div>
    </>
  )
}

export default ActionsFooterAndUnsavedChanges
