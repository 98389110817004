import { parse, format, addMinutes, isValid } from 'date-fns'

const TIME_FORMAT = 'hh:mm a'

/**
 * @param time - time in `TIME_FORMAT` format
 * @param step - step value, in minutes
 */
export const addStep = (time: string, step: number): string => {
  const _time = parse(time, TIME_FORMAT, new Date(0))

  if (!isValid(_time)) {
    return time
  }

  return format(addMinutes(_time, step), TIME_FORMAT)
}
