import { css } from '@emotion/css'

export const POPOVER_CLASS_NAME = css(`
  .cf-dropdown-listbox {
    min-width: 150px;
  }
`)

export default css(`
  .bank-statements-table {
    min-height: 500px;

    > table {
      thead {
        tr {
          th:last-child {
            padding-right: 8px;
          }
        }
      }

      > tr td {
        &:first-child > div, &:last-child > div {
          padding: 12px 16px;
        }
        &:first-child > div {
          padding-right: 8px;
        }
        &:not(:first-child, :last-child)>div {
          padding: 12px 8px;
        }
        > div {
          &:not(.row-expandable .row-expandable-form) {
            height: 48px;
          }
        }
      }
    }
  }

  &.cf-no-data-block {
    display: flex;
    justify-content: center;
    padding: 30px;
    background: #fff;
    border-top: 8px solid #F3F3F3;
  }

  .tab-container {
    .g-panel {
      padding: 16px;
    }
  }

  .header-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 24px 0;
  }

  .g-panel {
    box-shadow: none;
  }

  .left-panel {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .cf-table-header-title p {
    font-weight: 600;
    color: #101010;
  }

  td.cf-table-cell {
    overflow: hidden;
  }

  .cell-description {
    display: flex;
    gap: 10px;

    .cf-table-cell-text {
      width: 100%;
      display: table; // for responsive ellipsis
      table-layout: fixed;
      white-space: nowrap;

      &.with-end-adornment {
        max-width: calc(100% - 160px);
      }
      
      p {
        display: table-cell; // for responsive ellipsis
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .cf-skeleton-row {
    .cf-table-cell {
    
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        .cf-cell-wrapper{
          justify-content: end;
          display: flex;
        }
      }
    }
  }
`)
