import type { FC } from 'react'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'

import useFIPackageSetup from './hook'
import type { UseFIPackageSetupProps } from './hook/types'
import FIPackageNames from './components/FIPackageNames'
import FIProductSetup from './components/FIProductSetup'
import ModalFullscreen from 'components/dialogs/ModalFullscreen'

import CLASS_NAME from './styles'

const FIPackageSetup: FC<UseFIPackageSetupProps> = (props) => {
  const {
    isOpen,
    markups,
    onCancel,
    isLoading,
    penPackages,
    onApplyMarkupChanges,
    onChangeManualVendor,
    ...form
  } = useFIPackageSetup(props)

  return (
    <ModalFullscreen
      isFullscreen
      isOpen={isOpen}
      onClose={onCancel}
      isLoading={isLoading}
      className={CLASS_NAME}
      title='F&I package setup'
      renderFooterActions={(
        <CancelSubmitActionsFooter
          onClose={onCancel}
          submitTitle='SAVE'
          isLoading={isLoading}
          onSubmit={form.onSubmit}
        />
      )}
      titleDataTestId='fipackagesetup-title-create-update'
    >
      <FIPackageNames {...form} />

      <h3 className='fiproduct-setup-title'>Products setup</h3>

      <FIProductSetup
        markups={markups}
        onBlur={form.onBlur}
        onChange={form.onChange}
        penPackages={penPackages}
        validateField={form.validateField}
        fieldPathPrefix='coverageSections.'
        errors={form.errors.coverageSections}
        touched={form.touched.coverageSections}
        onChangeManualVendor={onChangeManualVendor}
        onApplyMarkupChanges={onApplyMarkupChanges}
        coverageSections={form.values.coverageSections}
      />
    </ModalFullscreen>
  )
}

export default FIPackageSetup
