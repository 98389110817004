import { type FC, useRef, useState, useEffect } from 'react'
import {
  Button, PopoverV2, FormNumberInput, cnx, formatInteger, type NumberInputProps
} from '@carfluent/common'

import IconSVG from 'components/inlineImages'

import { getButtonText } from './utils'
import { POPOVER_OPTIONS } from './constants'
import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

interface FilterRangeFormValues {
  from: number | null
  to: number | null
  exact?: number | null
}

export interface FilterRangeProps {
  name: string
  hasExact?: boolean
  defaultValues?: FilterRangeFormValues | null
  values?: FilterRangeFormValues | null
  popoverClassName?: string
  preset?: NumberInputProps['preset']
  formatter?: (v: string | number) => string
  onApply: (values: FilterRangeFormValues | null) => void
}

const EMPTY_STATE = { from: null, to: null, exact: null }

const FilterRange: FC<FilterRangeProps> = ({
  name,
  defaultValues,
  values = null,
  popoverClassName,
  hasExact = false,
  preset,
  formatter = formatInteger,
  onApply
}) => {
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const [stateValues, setStateValues] = useState<FilterRangeFormValues>(defaultValues ?? EMPTY_STATE)
  const refPrevValues = useRef<FilterRangeFormValues | null>(stateValues)

  const onReset = (values: FilterRangeFormValues | null): void => {
    setStateValues({
      from: values?.from ?? null,
      to: values?.to ?? null,
      exact: values?.exact ?? null
    })
    setIsOpen(false)
  }

  const onSubmit = async ({ from, to, exact }: FilterRangeFormValues): Promise<void> => {
    const appliedValue = exact != null
      ? { exact, from: null, to: null }
      : (from == null && to === null)
          ? null
          : { from, to }

    void onApply(appliedValue)
    refPrevValues.current = appliedValue
    setIsOpen(false)
  }

  const onRemove = async (): Promise<void> => {
    const payload = { from: null, to: null, exact: null }
    void onSubmit(payload)
    onReset(payload)
  }

  const onBlur = (): void => {
    setStateValues(prev => {
      const { from, to } = prev
      if (to != null && from != null && from > to) {
        return { from: to, to: from }
      }

      return prev
    })
  }

  const { from, to, exact } = stateValues

  const btnText = getButtonText(name, formatter, from, to, exact)
  const hasValue = from != null || to != null || exact != null

  useEffect(() => {
    if (values == null) {
      return
    }

    setStateValues(prev => {
      const { from, to, exact } = values

      if (from === prev.from && to === prev.to && exact === prev.exact) {
        return prev
      }

      return {
        ...prev,
        ...values
      }
    })
  }, [values])

  return (
    <div className={CLASS_NAME}>
      <div ref={anchorEl}>
        <Button
          onClick={() => setIsOpen(!isOpen)}
          variant='text'
          className='range-button'
        >
          {btnText}

          {
            hasValue
              ? <IconSVG.Close
                  onClick={(e) => {
                    e.stopPropagation()
                    void onRemove()
                  }}
                />
              : <IconSVG.DropdownArrowDown />
          }
        </Button>
      </div>

      <PopoverV2
        isOpen={isOpen}
        anchorEl={anchorEl}
        className={cnx(popoverClassName, POPOVER_CLASS_NAME)}
        options={POPOVER_OPTIONS}
        onClose={() => onReset(refPrevValues.current)}
      >
        {
          !hasExact && (
            <div className='form-header'>
              <p>{name}</p>
            </div>
          )
        }

        {
          hasExact && (
            <div className='form-exact-content'>
              <p className='form-input-label'>Exact {name.toLowerCase()}</p>
              <FormNumberInput
                id='exact'
                preset={preset}
                isEmptyAllowed
                placeholder='0.00'
                onChange={(_, val) => {
                  setStateValues({ from: null, to: null, exact: val })
                }}
                value={stateValues.exact ?? null}
              />
            </div>
          )
        }

        {hasExact && <p className='form-input-label'>{name} between</p>}
        <div className='form-content'>
          <FormNumberInput
            id='from'
            placeholder='From'
            preset={preset}
            isEmptyAllowed
            onChange={(_, val) => {
              setStateValues({ from: val, to, exact: null })
            }}
            onBlur={onBlur}
            value={from}
          />
          -
          <FormNumberInput
            id='to'
            placeholder='To'
            preset={preset}
            isEmptyAllowed
            onChange={(_, val) => {
              setStateValues({ from, to: val, exact: null })
            }}
            onBlur={onBlur}
            value={to}
          />
        </div>

        <div className='form-actions'>
          <Button
            onClick={onRemove}
            variant='text'
            className='remove-btn'
          >
            REMOVE
          </Button>
          <Button
            onClick={async () => await onSubmit({ from, to, exact })}
            className='apply-btn'
          >
            APPLY
          </Button>
        </div>
      </PopoverV2>
    </div>
  )
}

export default FilterRange
