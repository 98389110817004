import { type ReactNode, type FC, type MouseEvent } from 'react'
import { cnx } from '@carfluent/common'

import closeIcon from 'assets/close_blue.svg'
import CLASS_NAME from './styles'

export interface BadgeProps {
  id?: string
  className?: string
  message: ReactNode
  onClick?: (evt: MouseEvent<HTMLParagraphElement>) => void
  onDelete?: (item: string) => void
}

const Badge: FC<BadgeProps> = ({
  className = '',
  message,
  id = typeof message === 'string' ? message : '',
  onClick,
  onDelete,
}) => {
  return (
    <p
      className={cnx(CLASS_NAME, 'badge', className)}
      onClick={onClick}
    >
      {message}

      {(onDelete != null) && (
        <img
          onClick={() => onDelete(id)}
          src={closeIcon} alt=''
        />
      )}
    </p>
  )
}

export default Badge
