import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import Button from 'components/common/Button'
import { TaskMode } from 'pages/crm/LeadDetailsView/hook/types'

import CLASS_NAME from './styles'

interface ActionBarProps {
  taskMode: TaskMode | null
  isDisabled?: boolean
  onDelete?: () => void
  onSubmit: () => void
  onCancel?: () => void
  isLoading: boolean
  buttonsClassName?: string
  cancelButtonTitle?: string
  isShowCancelButton?: boolean
}

const SubmitBtnText = {
  [TaskMode.NewTask]: 'ADD TASK',
  [TaskMode.EditTask]: 'SAVE TASK',
  [TaskMode.CompleteTask]: 'COMPLETE TASK',
  [TaskMode.ViewTask]: 'START TASK',
  [TaskMode.ViewRespond]: 'I RESPONDED'
}

const ActionBar: FC<ActionBarProps> = ({
  taskMode,
  buttonsClassName = '',
  isLoading,
  isDisabled,
  onSubmit,
  onCancel,
  cancelButtonTitle = 'CANCEL',
  isShowCancelButton = false
}) => {
  const isTaskMode = (taskMode != null)

  const showCancelButton = (
    taskMode === TaskMode.CompleteTask ||
    taskMode === TaskMode.EditTask ||
    taskMode === TaskMode.NewTask ||
    isShowCancelButton
  )

  return (
    <>
      {isTaskMode &&
        <div className={CLASS_NAME}>
          {showCancelButton &&
            <Button
              className={cnx(buttonsClassName, 'cancel-button')}
              disabled={isLoading}
              type='secondary'
              onClick={onCancel}
              data-test-id='schedule-task-btn-cancel'
            >
              {cancelButtonTitle}
            </Button>}
          <Button
            className={buttonsClassName}
            disabled={isDisabled === true || isLoading}
            type='secondary'
            onClick={onSubmit}
            data-test-id='schedule-task-btn-submit'
          >
            {SubmitBtnText[taskMode as Exclude<TaskMode, 'Message received' | 'Email received'>]}
          </Button>
        </div>}
    </>
  )
}

export default ActionBar
