import {
  type FISetupMarkupsModel,
  type DealerProductCoverageMarkupDto
} from 'api/types/responses'

const parseFISetupMarkups = (markups: DealerProductCoverageMarkupDto[]): FISetupMarkupsModel => {
  return markups.reduce((acc, el) => ({ ...acc, [el.productTypeId]: el }), {})
}

export default parseFISetupMarkups
