import type { FC } from 'react'
import { CellWrapper, Button, Loader } from '@carfluent/common'

import CLASS_NAME from './styles'
import IconSVG from 'components/inlineImages'

interface ValueProps {
  name: string
  loading?: boolean
  fileId: number
}

interface FileNameCellProps {
  getValue: () => ValueProps
  onRedirect: (id: number) => Promise<void>
}

const FileNameCell: FC<FileNameCellProps> = ({ getValue, onRedirect }) => {
  const { loading = false, name, fileId } = getValue()

  return (
    <CellWrapper className={CLASS_NAME}>
      <div className='wrapper'>
        {loading ? <Loader color='dark' /> : <IconSVG.IconSVGDocument />}

        <Button
          variant='text'
          onClick={() => {
            if (loading) {
              return
            }

            void onRedirect(fileId)
          }}
        >
          {name}
        </Button>
      </div>
    </CellWrapper>
  )
}

export default FileNameCell
