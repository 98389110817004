import { css } from '@emotion/css'

export default css(`
  height: calc(100vh - 104px);
  min-height: calc(100vh - 104px)!important;

  .g-filters-panel {
    margin-bottom: 0;
    grid-column-gap: 0;
  }

  .children-wrapper {
    display: flex;
    align-items: center;

    > span {
      font-size: 16px;
      text-wrap: nowrap;
      line-height: 24px;
      margin-right: 24px;
      color: #21212180;
      letter-spacing: 0.5px;
    }

    .MuiSkeleton-text {
      width: 149px;
      height: 16px;
      margin-right: 0px;
    }
  }

  .cf-no-data-block {
    display: flex;
    justify-content: center;
    padding: 30px;
    background: #fff;
    border-top: 8px solid #F3F3F3;
  }

  .header-block {
    display: flex;
    justify-content: end;
    width: 100%;
    margin: 0 0 28px 0;
  }

  .filters-block {
    margin-right: 24px;
  }

  .search-block {
    max-width: 376px;
    width: 100%;
  }

  .btn-new-item {
    width: 150px;
    height: 56px;
  }

  .cf-table-header-title p {
    font-weight: 600;
    color: #101010;
  }

  .intake-inspection-container {
    display: grid; 

    grid-template-rows: 136px 1fr;
    grid-template-columns: 294px 1fr;

    gap: 16px;
    height: calc(100% - 110px);

    @media (max-width: 1280px) {
      grid-template-columns: 244px 1fr;
    }
  }

  .intake-inspection-form-block {
    grid-row-start: 1;
    grid-column-start: 1;
 
    grid-row-end: 2;
    grid-column-end: 2;
  }

  .intake-inspection-navigation-block {
    padding: 16px 0;
    grid-row-end: 4;
    overflow-y: auto;
    grid-row-start: 2;
    grid-column-end: 2;
    grid-column-start: 1;
    background-color: #fff;
    border-radius: 12px 12px 0 0;
  }

  .intake-inspection-list {
    grid-row-end: 4;
    overflow-y: auto;
    grid-row-start: 1;
    grid-column-end: 3;
    margin-right: -4px;
    grid-column-start: 2;
  }
`)
