import { css } from '@emotion/css'

export default css(`
  &.cf-tab-disabled {
    opacity: 0.4;
    
    * {
      pointer-events: none;
    }
  }
`)
