import { WrapperRequest } from 'api/wrapper.api'
import { type GetPromosDto } from 'api/types/requests'
import {
  type PromosDto,
  type WebsiteSettingsDto,
  type WebsiteSettingsModel
} from 'api/types/responses'

import { parseWebsiteSettingsDto } from 'api/mappers/responses'

class PromosApi extends WrapperRequest {
  baseUrl = process.env.REACT_APP_INVENTORY ?? ''
  customerUrl = process.env.REACT_APP_CUSTOMERSCORE ?? ''

  getPromos = async (): Promise<PromosDto> => {
    return await this.get(`${this.baseUrl}/api/v1/Promos`)
  }

  updatePromos = async (data: GetPromosDto): Promise<boolean> => {
    return await this.patch(`${this.baseUrl}/api/v1/Promos`, data)
  }

  getWebsiteSettings = async (): Promise<WebsiteSettingsModel> => {
    const res = await this.get(`${this.customerUrl}/api/v1/Settings/website`)

    return parseWebsiteSettingsDto(res)
  }

  updatePaymentSettings = async (data: WebsiteSettingsDto): Promise<WebsiteSettingsModel> => {
    const res = await this.patch(`${this.customerUrl}/api/v1/Settings/website`, data)

    return parseWebsiteSettingsDto(res)
  }

  updateWebSiteSettings = async (data: WebsiteSettingsDto): Promise<WebsiteSettingsDto> => {
    return await this.patch(`${this.customerUrl}/api/v1/Settings/website`, data)
  }
}

const PromosApiProvider = new PromosApi()

export default PromosApiProvider
