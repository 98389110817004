import { type FC } from 'react'
import { formatCurrencyAccounting } from '@carfluent/common'

import IconSVG from 'components/inlineImages'
import { formatDate } from 'utils/parse_date'
import { DATE_US_FORMAT } from 'constants/constants'
import CLASS_NAME from './styles'

interface BankStatementBannerItemProps {
  date: Date | string | null
  description: string | null
  price: number
  accountName: string
}

const BankStatementBannerItem: FC<BankStatementBannerItemProps> = ({ date, description, price, accountName }) => {
  return (
    <div className={CLASS_NAME}>
      <div className='item-icon'>
        <IconSVG.BankStatement color='#458FFF' />
      </div>

      <div className='item-info'>
        <p>
          <b>{formatDate(new Date(date ?? ''), DATE_US_FORMAT)}</b>&nbsp;-&nbsp;{description}&nbsp;
          -&nbsp;<b>{formatCurrencyAccounting(price, { allowNegative: true })}</b>
        </p>

        <p>Created in <b>{accountName}</b></p>
      </div>
    </div>
  )
}

export default BankStatementBannerItem
