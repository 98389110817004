import { useCallback, useEffect } from 'react'

import { Notifications } from 'constants/names'
import { WebSocketsCrm as ws } from 'services/web_sockets'
import type {
  WebSocketArgs,
  LeadUpdatedNotificationArgs,
  LeadDeletedNotificationArgs
} from 'api/types'

import type { RowId } from './types'

export interface UseWSUpdatesProps {
  onLeadDeleted?: (leadId: RowId) => void
  onLeadUpdated?: () => Promise<void>
}

const useWSUpdates = ({
  onLeadDeleted: _onLeadDeleted,
  onLeadUpdated: _onLeadUpdated
}: UseWSUpdatesProps): void => {
  // ========================================== //
  //                   ACTIONS                  //
  // ========================================== //

  const leadsUpdatedAction = useCallback((args: LeadUpdatedNotificationArgs) => {
    if (args.dealerId != null && args.leadId != null) {
      void _onLeadUpdated?.()
    }
  }
  , [_onLeadUpdated])

  const leadDeletedAction = useCallback((args: LeadDeletedNotificationArgs) => {
    if (args.dealerId != null && args.leadId != null) {
      _onLeadDeleted?.(args.leadId)
    }
  }
  , [_onLeadDeleted])

  /**
   * In the future, it could change.
   * Now, for TaskCreate & TasksUpdated and TasksDeleted Notifications
   * we need to pull the leads list.
   */

  const tasksChangedAction = useCallback((args: WebSocketArgs) => {
    if (args.dealerId != null) {
      void _onLeadUpdated?.()
    }
  }
  , [_onLeadUpdated])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //
  const throttledGroupId = 'leadsListUpdate'

  useEffect(() => {
    const onLeadCreateHandler = { name: 'leads_leadCreateAction', action: leadsUpdatedAction }
    const onLeadUpdateHandler = { name: 'leads_leadUpdatedAction', action: leadsUpdatedAction }

    ws.on(Notifications.LeadCreated, onLeadCreateHandler, { throttledGroupId })
    ws.on(Notifications.LeadUpdated, onLeadUpdateHandler, { throttledGroupId })

    return () => {
      ws.off(Notifications.LeadCreated, onLeadCreateHandler.name)
      ws.off(Notifications.LeadUpdated, onLeadUpdateHandler.name)
    }
  }, [leadsUpdatedAction])

  useEffect(() => {
    const onLeadDeleteHandler = { name: 'leads_leadDeleteAction', action: leadDeletedAction }

    ws.on(Notifications.LeadDeleted, onLeadDeleteHandler)

    return () => {
      ws.off(Notifications.LeadDeleted, onLeadDeleteHandler.name)
    }
  }, [leadDeletedAction])

  useEffect(() => {
    const onTaskCreateHandler = { name: 'leads_TaskCreateAction', action: tasksChangedAction }
    const onTasksUpdateHandler = { name: 'leads_TasksUpdatedAction', action: tasksChangedAction }
    const onTasksDeleteHandler = { name: 'leads_TasksDeleteAction', action: tasksChangedAction }

    ws.on(Notifications.TaskCreated, onTaskCreateHandler, { throttledGroupId })
    ws.on(Notifications.TasksUpdated, onTasksUpdateHandler, { throttledGroupId })
    ws.on(Notifications.TasksDeleted, onTasksDeleteHandler, { throttledGroupId })

    return () => {
      ws.off(Notifications.TaskCreated, onTaskCreateHandler.name)
      ws.off(Notifications.TasksUpdated, onTasksUpdateHandler.name)
      ws.off(Notifications.TasksDeleted, onTasksDeleteHandler.name)
    }
  }, [tasksChangedAction])
}

export default useWSUpdates
