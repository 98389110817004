import { type LeadsDashboardCrmModel } from 'api/types/responses'

export interface DateRangeOption {
  id: number
  name: string
}

export enum FilterTypesId {
  Today,
  Yesterday,
  Last7Days,
  Last14Days,
  Last30Days
}

export interface DealsProps {
  className?: string
}

export interface UseDashboardCrmReturn {
  data: LeadsDashboardCrmModel | null
  dateRange: DateRangeOption | null
  dateRangeOptions: DateRangeOption[]
  isLoading: boolean
  onChangeDateRange: (value: DateRangeOption | null) => void
}
