import type {
  WorkPlanEventDto,
  Task,
  ShiftDto,
  LeadMessageDto,
  LeadTask,
  LeadData,
  EntityId,
  SendEmail,
  NewLeadApi,
  GetAssignee,
  EmailThread,
  SummaryShift,
  ListResponse,
  LeadListItem,
  TimelineItem,
  DuplicateLeadDto,
  GetLeadStatus,
  LeadDataPatch,
  UpdateLeadApi,
  MessagePayload,
  DictionaryItem,
  LeadListPayload,
  CreateShiftRequestDto,
  MakesAmountItem,
  TaskListPayload,
  GetShiftsPayload,
  TaskTotalAmounts,
  UpdateTaskPayload,
  CreateTaskPayload,
  MakesAmountPayload,
  CrmSettings,
  UpdateShiftRequestDto,
  TaskTypeFormOptions,
  LeadListCountersApi,
  CompleteTaskPayload,
  InterestedLeadsLisDto,
  DuplicateLeadsPayload,
  LeadsByVehiclePayload,
  GetMemberShiftsPayload,
  CopyMonthShiftsPayload,
  TimelineFilterItemsIds,
  EmailTemplatesResponse,
  GetSummaryShiftsPayload,
  ClearMonthShiftsPayload,
  TaskTotalAmountsPayload,
  CompleteAutoTaskPayload,
  MessageTemplatesResponse,
  ReportLeadsByVehiclesItem,
  CreateCallActivityPayload,
  CreateVisitActivityPayload,
  LeadListCountersPayloadApi,
  CreateOtherActivityPayload,
  DuplicateLeadsTotalPayload,
  DuplicateLeadsTotalResponse,
  InterestedLeadsListGetRequestDto,
  CompleteRespondOnLeadTaskPayload,
  DownloadLeadsByVehicleReportPayload,
  InterestedLeadsAmountGetDto,
  LeadsByVehicleSourceRequest,
  ReportLeadsBySourceDto,
  ReportLeadsBySourceExportXlsxRequest,
  GetCampaignRecipientsCountRequestDto,
  CampaignRecipientsCountDto,
  CancelationOptions,
  PaginatedResult
} from 'api/types'

import {
  type CreateCampaignDto,
  type GetCampaignsListDto,
  type GetLeadsDashboardDto,
  type ActivityReportDto,
  type DownloadActivityReportXlsx
} from 'api/types/requests'

import {
  type CampaignModel,
  type CampaignListModel,
  type CampaignTotalDto,
  type EmailSnippetDto,
  type LeadsDashboardCrmModel,
  type MessageSnippetDto,
  type WebsiteChatModel,
  type WebsiteChatSettingsDto,
  type WebChatItemModel,
  type ActivityReportItemDto
} from 'api/types/responses'

import {
  parseCampaignDto,
  parseCampaignListDto,
  parseDashboardLeadsDto, parseWebChatDto,
  parseWebsiteChatDto
} from 'api/mappers/responses'

import { DEFAULT_MESSAGE_BODY } from './defaults/leads'
import { WrapperRequest } from './wrapper.api'

class CRMApi extends WrapperRequest {
  url = process.env.REACT_APP_CRM ?? ''

  getLeadBySourceReport = async (data: LeadsByVehicleSourceRequest): Promise<ReportLeadsBySourceDto> => {
    return await this.post(`${this.url}/api/v1/Reports/leads-by-source`, data)
  }

  getLeadsByVehicleReport = async (data: LeadsByVehiclePayload): Promise<ListResponse<ReportLeadsByVehiclesItem>> => {
    return await this.post(`${this.url}/api/v1/Reports/leads-by-vehicle`, data)
  }

  getMakesAmount = async (data: MakesAmountPayload): Promise<ListResponse<MakesAmountItem>> => {
    return await this.post(`${this.url}/api/v1/Reports/makes-amount`, data)
  }

  getShifts = async (payload: GetShiftsPayload): Promise<ListResponse<ShiftDto>> => {
    return await this.post(`${this.url}/api/v1/Shifts/list`, payload)
  }

  getMemberShifts = async (payload: GetMemberShiftsPayload): Promise<ListResponse<ShiftDto>> => {
    return await this.post(`${this.url}/api/v1/Shifts/list/my`, payload)
  }

  addShift = async (payload: CreateShiftRequestDto): Promise<ShiftDto> => {
    return await this.post(`${this.url}/api/v1/Shifts`, payload)
  }

  updateShift = async (payload: UpdateShiftRequestDto): Promise<ShiftDto> => {
    return await this.patch(`${this.url}/api/v1/Shifts/${payload.id}`, payload)
  }

  deleteShift = async (id: number): Promise<void> => {
    return await this.delete(`${this.url}/api/v1/Shifts/${id}`)
  }

  getShiftsSummary = async (payload: GetSummaryShiftsPayload): Promise<ListResponse<SummaryShift>> => {
    return await this.get(`${this.url}/api/v1/Shifts/summary`, payload)
  }

  clearMonthShifts = async (payload: ClearMonthShiftsPayload): Promise<void> => {
    const { year, month } = payload

    return await this.delete(`${this.url}/api/v1/Shifts/clear?year=${year}&month=${month}`)
  }

  copyMonthShifts = async (payload: CopyMonthShiftsPayload): Promise<void> => {
    return await this.post(`${this.url}/api/v1/Shifts/copy`, payload)
  }

  getTasks = async (
    payload: TaskListPayload,
    cancelationOptions?: CancelationOptions
  ): Promise<PaginatedResult<Task>> => {
    return await this.post(`${this.url}/api/v1/Tasks/list`, payload, cancelationOptions)
  }

  getTasksListTotal = async (
    payload?: Partial<TaskTotalAmountsPayload>,
    cancelationOptions?: CancelationOptions
  ): Promise<TaskTotalAmounts> => {
    return await this.post(`${this.url}/api/v1/Tasks/list/total`, payload, cancelationOptions)
  }

  createTask = async (data: CreateTaskPayload): Promise<LeadTask> => {
    return await this.post(`${this.url}/api/v1/Tasks`, data)
  }

  completeTask = async (data: CompleteTaskPayload): Promise<void> => {
    return await this.post(`${this.url}/api/v1/Tasks/complete`, data)
  }

  completeRespondOnAutoTask = async (data: CompleteAutoTaskPayload): Promise<void> => {
    return await this.post(`${this.url}/api/v1/Tasks/complete`, data)
  }

  completeRespondOnLeadTask = async (data: CompleteRespondOnLeadTaskPayload): Promise<void> => {
    return await this.post(`${this.url}/api/v1/Tasks/complete`, data)
  }

  updateTask = async (data: UpdateTaskPayload): Promise<LeadTask> => {
    return await this.patch(`${this.url}/api/v1/Tasks/${data.id}`, data)
  }

  deleteTask = async (id: number): Promise<void> => {
    return await this.delete(`${this.url}/api/v1/Tasks/${id}`)
  }

  getStatusList = async (): Promise<GetLeadStatus> => {
    return await this.get(`${this.url}/api/v1/Reference/lead-statuses`, {
      additionalParams: { cache: true }
    })
  }

  getAssigneeList = async (): Promise<GetAssignee> => {
    return await this.get(`${this.url}/api/v1/Tasks/assignees`)
  }

  createCallActivity = async (data: CreateCallActivityPayload): Promise<void> => {
    return await this.post(`${this.url}/api/v1/Logs/call`, data)
  }

  createVisitActivity = async (data: CreateVisitActivityPayload): Promise<void> => {
    return await this.post(`${this.url}/api/v1/Logs/store-visit`, data)
  }

  createOtherActivity = async (data: CreateOtherActivityPayload): Promise<void> => {
    return await this.post(`${this.url}/api/v1/Logs/other-task`, data)
  }

  getEmailSnippets = async (): Promise<ListResponse<EmailSnippetDto>> => {
    return await this.get(`${this.url}/api/v1/EmailSnippets`)
  }

  getMessageSnippets = async (): Promise<ListResponse<MessageSnippetDto>> => {
    return await this.get(`${this.url}/api/v1/MessageSnippets`)
  }

  getCrmSettings = async (): Promise<CrmSettings> => {
    return await this.get(`${this.url}/api/v1/Settings`)
  }

  updateCrmSettings = async (data: CrmSettings): Promise<CrmSettings> => {
    return await this.patch(`${this.url}/api/v1/Settings`, data)
  }

  getEmailThread = async (leadId: EntityId, emailId: EntityId): Promise<EmailThread> => {
    return await this.get(`${this.url}/api/v1/Leads/${leadId}/emails/${emailId}/thread`)
  }

  getLeadSources = async (): Promise<ListResponse<DictionaryItem>> => {
    return await this.get(`${this.url}/api/v1/Reference/lead-sources`, {
      additionalParams: { cache: true }
    })
  }

  getLeadTemperature = async (): Promise<ListResponse<DictionaryItem>> => {
    return await this.get(`${this.url}/api/v1/Reference/lead-temperature`, {
      additionalParams: { cache: true }
    })
  }

  getReminderSubjects = async (): Promise<ListResponse<DictionaryItem>> => {
    return await this.get(`${this.url}/api/v1/Reference/reminder-subjects`, {
      additionalParams: { cache: true }
    })
  }

  getLeadTasks = async (leadId: EntityId): Promise<ListResponse<LeadTask>> => {
    return await this.get(`${this.url}/api/v1/Leads/${leadId}/tasks`)
  }

  getTaskPriorities = async (): Promise<ListResponse<DictionaryItem>> => {
    return await this.get(`${this.url}/api/v1/Reference/task-priorities`, {
      additionalParams: { cache: true }
    })
  }

  getTaskAssigneeTypes = async (): Promise<ListResponse<DictionaryItem<string>>> => {
    return await this.get(`${this.url}/api/v1/Reference/task-assignee-types`, {
      additionalParams: { cache: true }
    })
  }

  getTaskTypes = async (): Promise<ListResponse<TaskTypeFormOptions>> => {
    return await this.get(`${this.url}/api/v1/Reference/task-types`, {
      additionalParams: { cache: true }
    })
  }

  createLead = async (data: NewLeadApi): Promise<string | number> => {
    return await this.post(`${this.url}/api/v1/Leads`, data)
  }

  getLeads = async (
    payload: LeadListPayload,
    cancelationOptions?: CancelationOptions
  ): Promise<PaginatedResult<LeadListItem>> => {
    return await this.post(`${this.url}/api/v1/Leads/list`, payload, cancelationOptions)
  }

  getLead = async (leadId: number | string): Promise<LeadData> => {
    return await this.get(`${this.url}/api/v1/Leads/${leadId}`)
  }

  getLeadsListTotal = async (
    payload?: Partial<LeadListCountersPayloadApi>,
    cancelationOptions?: CancelationOptions
  ): Promise<LeadListCountersApi> => {
    return await this.post(`${this.url}/api/v1/Leads/total`, payload, cancelationOptions)
  }

  updateLead = async (payload: UpdateLeadApi): Promise<LeadData> => {
    return await this.patch(`${this.url}/api/v1/Leads/${payload.leadId}`, payload)
  }

  getLeadAssignedTo = async (): Promise<GetAssignee> => {
    return await this.get(`${this.url}/api/v1/Leads/assignees`)
  }

  getLeadStatuses = async (): Promise<ListResponse<DictionaryItem<string>>> => {
    return await this.get(`${this.url}/api/v1/Reference/lead-statuses`, {
      additionalParams: { cache: true }
    })
  }

  deleteLead = async (leadId: number | string): Promise<null> => {
    return await this.delete(`${this.url}/api/v1/Leads/${leadId}`)
  }

  addCarOfInterest = async (leadId: EntityId, carId: EntityId): Promise<LeadData> => {
    return await this.post(`${this.url}/api/v1/Leads/${leadId}/carsOfInterest/${carId}`)
  }

  deleteCarOfInterest = async (leadId: EntityId, carId: EntityId): Promise<void> => {
    return await this.delete(`${this.url}/api/v1/Leads/${leadId}/carsOfInterest/${carId}`)
  }

  getTimelines = async (leadId: EntityId, filterId: TimelineFilterItemsIds): Promise<ListResponse<TimelineItem>> => {
    return await this.get(`${this.url}/api/v1/Logs`, { leadId, filterId })
  }

  getTimeline = async (leadId: EntityId, filterId: TimelineFilterItemsIds, logId: number): Promise<TimelineItem> => {
    return await this.get(`${this.url}/api/v1/Logs/${logId}`, { leadId, filterId })
  }

  sendTimelineComment = async (leadId: EntityId, comment: string): Promise<void> => {
    return await this.post(`${this.url}/api/v1/Logs/comment`, { leadId, comment })
  }

  makeCarOfInterestPrimary = async (lead: LeadDataPatch): Promise<LeadData> => {
    return await this.patch(`${this.url}/api/v1/Leads/${lead.id}`, lead)
  }

  getEmailTemplates = async (leadId: EntityId, emailTemplateId: number): Promise<EmailTemplatesResponse> => {
    return await this.get(`${this.url}/api/v1/Leads/${leadId}/emailTemplates/${emailTemplateId}`)
  }

  getMessageTemplates = async (leadId: EntityId, messageTemplateId: number): Promise<MessageTemplatesResponse> => {
    return await this.get(`${this.url}/api/v1/Leads/${leadId}/messageTemplates/${messageTemplateId}`)
  }

  sendEmails = async (leadId: EntityId, payload: SendEmail): Promise<void> => {
    return await this.post(`${this.url}/api/v1/Leads/${leadId}/emails`, payload)
  }

  updateStatus = async (leadId: EntityId, leadStatusId: number): Promise<LeadData> => {
    return await this.patch(`${this.url}/api/v1/Leads/${leadId}/status`, { leadStatusId })
  }

  getMessages = async (leadId: EntityId, lastItemCreatedDate: string | null = null): Promise<ListResponse<LeadMessageDto>> => {
    return await this.post(`${this.url}/api/v1/Leads/${leadId}/messages/list`, {
      ...DEFAULT_MESSAGE_BODY,
      lastItemCreatedDate
    })
  }

  sendMessage = async (leadId: EntityId, payload: MessagePayload): Promise<LeadMessageDto> => {
    return await this.post(`${this.url}/api/v1/Leads/${leadId}/message`, payload)
  }

  getMessage = async (leadId: EntityId, messageId: EntityId): Promise<LeadMessageDto> => {
    return await this.get(`${this.url}/api/v1/Leads/${leadId}/message/${messageId}`)
  }

  getEvents = async (): Promise<{ events: WorkPlanEventDto[] }> => {
    return await this.get(`${this.url}/api/v1/Settings/workplan/events`)
  }

  createEvent = async (event: Omit<WorkPlanEventDto, 'isActive' | 'id' | 'statusChangedDate' | 'assignedUser' | 'workplanEventTypeId' | 'templateName'>): Promise<WorkPlanEventDto> => {
    return await this.post(`${this.url}/api/v1/Settings/workplan/events`, event)
  }

  updateEvent = async (event: WorkPlanEventDto): Promise<WorkPlanEventDto> => {
    return await this.patch(`${this.url}/api/v1/Settings/workplan/events/${event.id}`, event)
  }

  deleteEvent = async (eventId: number): Promise<void> => {
    return await this.delete(`${this.url}/api/v1/Settings/workplan/events/${eventId}`)
  }

  getDuplicateLeads = async (payload: DuplicateLeadsPayload): Promise<PaginatedResult<DuplicateLeadDto>> => {
    return await this.post(`${this.url}/api/v1/Leads/duplicate/list`, payload)
  }

  getDuplicateLeadsTotal = async (payload: DuplicateLeadsTotalPayload): Promise<DuplicateLeadsTotalResponse> => {
    return await this.post(`${this.url}/api/v1/Leads/duplicate/total`, payload)
  }

  getLeadLostReasons = async (): Promise<ListResponse<DictionaryItem>> => {
    return await this.get(`${this.url}/api/v1/Reference/lead-lost-reasons`, {
      additionalParams: { cache: true }
    })
  }

  downloadLeadsByVehicleReportXlsx = async (data: DownloadLeadsByVehicleReportPayload): Promise<Blob> => {
    return await this.post(`${this.url}/api/v1/Reports/leads-by-vehicle/xlsx`, {
      data,
      additionalParams: { responseType: 'blob' }
    })
  }

  downloadLeadsByVehicleSourceXlsx = async (data: ReportLeadsBySourceExportXlsxRequest): Promise<Blob> => {
    return await this.post(`${this.url}/api/v1/Reports/leads-by-source/xlsx`, {
      data,
      additionalParams: { responseType: 'blob' }
    })
  }

  getActivityReportList = async (payload: ActivityReportDto): Promise<ListResponse<ActivityReportItemDto>> => {
    return await this.post(`${this.url}/api/v1/Reports/activity`, payload)
  }

  getInterestedLeadsList = async (payload: InterestedLeadsListGetRequestDto): Promise<ListResponse<InterestedLeadsLisDto>> => {
    return await this.post(`${this.url}/api/v1/Vehicles/${payload?.vehicleId ?? ''}/interested-leads-list`, payload)
  }

  getInterestedLeadsAmount = async (vehicleId: EntityId): Promise<InterestedLeadsAmountGetDto> => {
    return await this.get(`${this.url}/api/v1/Vehicles/${vehicleId ?? ''}/interested-leads-amount`)
  }

  getWebLeadCreatedDates = async (vehicleId: string, from: string): Promise<ListResponse<string>> => {
    return await this.get(`${this.url}/api/v1/Vehicles/${vehicleId ?? ''}/statistic-log-created-dates?from=${from}`)
  }

  getRecipientsCount = async (payload: GetCampaignRecipientsCountRequestDto): Promise<CampaignRecipientsCountDto> => {
    return await this.post(`${this.url}/api/v1/Campaigns/recipients/count`, payload)
  }

  createCampaign = async (payload: CreateCampaignDto): Promise<CampaignModel> => {
    const response = await this.post(`${this.url}/api/v1/Campaigns`, payload)
    return parseCampaignDto(response, {})
  }

  getCampaign = async (id: EntityId): Promise<CampaignModel> => {
    const [
      response,
      { items: leadStatuses },
      { items: leadSources },
      { items: leadTemperatures }
    ] = await Promise.all([
      this.get(`${this.url}/api/v1/Campaigns/${id}`),
      this.getLeadStatuses(),
      this.getLeadSources(),
      this.getLeadTemperature()
    ])

    return parseCampaignDto(response, { leadSources, leadStatuses, leadTemperatures })
  }

  deleteCampaign = async (id: EntityId, rowVersion: string): Promise<void> => {
    return await this.delete(`${this.url}/api/v1/Campaigns/${id}`, { rowVersion })
  }

  updateCampaign = async (id: EntityId, payload: CreateCampaignDto): Promise<CampaignModel> => {
    const response = await this.patch(`${this.url}/api/v1/Campaigns/${id}`, payload)
    return parseCampaignDto(response, {})
  }

  getCampaignRecipientsCount = async (id: EntityId): Promise<number> => {
    const response = await this.get(`${this.url}/api/v1/Campaigns/${id}/recipients/count`)
    return response.total
  }

  getCampaignTotal = async (search: string): Promise<CampaignTotalDto> => {
    return await this.post(`${this.url}/api/v1/Campaigns/list/total`, search)
  }

  getCampaignStatuses = async (): Promise<PaginatedResult<DictionaryItem>> => {
    return await this.get(`${this.url}/api/v1/Reference/campaign-statuses`, {
      additionalParams: { cache: true }
    })
  }

  getCampaignTypes = async (): Promise<PaginatedResult<DictionaryItem>> => {
    return await this.get(`${this.url}/api/v1/Reference/campaign-types`, {
      additionalParams: { cache: true }
    })
  }

  getCampaignList = async (payload: GetCampaignsListDto = { take: 20, skip: 0 }): Promise<CampaignListModel> => {
    const [
      { items: statusList },
      { items: typeList },
      { items: campaigns }
    ] = await Promise.all([
      this.getCampaignStatuses(),
      this.getCampaignTypes(),
      this.post(`${this.url}/api/v1/Campaigns/list`, payload)
    ])

    return parseCampaignListDto({
      campaigns,
      statusList,
      typeList
    })
  }

  getWebChatMessages = async (threadId: string): Promise<WebChatItemModel[]> => {
    const response = await this.get(`${this.url}/api/v1/Bot/${threadId}`)

    return parseWebChatDto(response)
  }

  getLeadsDashboard = async (payload: GetLeadsDashboardDto): Promise<LeadsDashboardCrmModel> => {
    const response = await this.post(`${this.url}/api/v1/Dashboard/leads`, payload)
    return parseDashboardLeadsDto(response)
  }

  getWebsiteChatSettings = async (): Promise<WebsiteChatModel> => {
    const response = await this.get(`${this.url}/api/v1/Settings/bot`)
    return parseWebsiteChatDto(response)
  }

  updateWebsiteChatSettings = async (payload: WebsiteChatSettingsDto): Promise<WebsiteChatModel> => {
    const response = await this.patch(`${this.url}/api/v1/Settings/bot`, payload)
    return parseWebsiteChatDto(response)
  }

  updatePauseOrResumeLead = async (leadId: EntityId, isPaused: boolean): Promise<boolean> => {
    return await this.post(`${this.url}/api/v1/Leads/${leadId}/pause`, { isPaused })
  }
}

export const CRMApiProvider = new CRMApi()
export default CRMApiProvider
