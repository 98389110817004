import { css } from '@emotion/css'

export default css(`
  .cf-modal-content > .cf-loader {
    position: fixed!important;
    height: 100vh;
  }

  .MuiDialog-paper {
    max-width: 680px;
  }

  .MuiDialogTitle-root {
    margin-bottom: 24px;
  }

  .MuiFormControl-root {
    max-height: 56px;
  }

  .fipackagesetup-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(5, 1fr);
    column-gap: 16px;
    row-gap: 24px;
  }

  .cf-modal-root {
    @media (min-width: 1600px) {
      padding: 0 24px!important;
    }

    .cf-modal-footer {
      grid-gap: 24px;
    }
  }

  .cf-modal-title {
    color: #101010!important;
    margin-bottom: 32px!important;
  }

  .cf-modal-title,
  .cf-modal-content {
    max-width: 1600px!important;

    .fiproduct-setup-title {
      font-size: 16px;
      margin-top: 32px;
      color: #101010;
      font-weight: 500;
      text-align: left;
      line-height: 24px;
      margin-bottom: 16px;
      letter-spacing: 0.4px;
    }
  }
`)

export const loader = css(`
  width: 20px;
  height: 20px;
  margin: 0 auto;

  &.MuiCircularProgress-colorPrimary {
    color: #000;
  }
`)
