import { type FocusEvent, useCallback, useEffect, useState } from 'react'
import { type DeepRequired, useForm, useLoader, isOk, isFail } from '@carfluent/common'

import { type TransactionLineVendorDto, AccountCategoryId, ErrorCode } from 'api/types'
import AccountingApiProvider from 'api/accounting.api'
import { isStringEmpty } from 'utils/parse_string'
import { namifyAccount } from 'utils/accounting/namifyAccount'
import type { FullAddressParts } from 'types/address'
import { emptyAddress } from 'utils/address'

import { useCustomSnackbar } from 'hooks/useCustomSnackbar'

import { FIELD_TRANSFORMS, Messages, getDefaultFormData } from './constants'
import type { UseVendorReturn, UseVendorProps, VendorFormData, FieldPath, ErrTouchShortcuts } from './types'
import parseVendorData from './parser'
import serializeVendorForm from './serializer'
import validationRules from './validator'

const DEFAULT_FORM_DATA = getDefaultFormData()

const useVendor = ({
  isOpen,
  vendorId,
  onSubmit: _onSubmit,
  onDeleteVendor: _onDeleteVendor,
  onClose: _onClose
}: UseVendorProps): UseVendorReturn => {
  const { showAlert } = useCustomSnackbar()

  const { isLoading, startLoader, stopLoader } = useLoader()
  const [lienholderId, setLienholderId] = useState<number | null>(null)
  const [isDateError, setIsDateError] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  // ========================================== //
  //                  HANDLERS                  //
  // ========================================== //

  const submitAction = useCallback(async (values: DeepRequired<VendorFormData>) => {
    startLoader()

    try {
      const payload = serializeVendorForm(values)
      let vendor: TransactionLineVendorDto

      if (vendorId == null) {
        vendor = await AccountingApiProvider.createVendor(payload)
      } else {
        vendor = await AccountingApiProvider.updateVendor({ ...payload, id: vendorId })
      }

      await _onSubmit(vendor)
    } catch (err) {
      showAlert(err)
    }
  }, [showAlert, _onSubmit, vendorId, startLoader])

  const deleteAction = useCallback(async () => {
    startLoader()
    if (vendorId != null) {
      await AccountingApiProvider.removeVendor(vendorId)
      await _onDeleteVendor?.()
    }
  }, [vendorId, showAlert, startLoader, _onDeleteVendor])

  const onActionResult = useCallback((res, resetForm, reason) => {
    stopLoader()

    if (reason === 'submit' && isOk(res)) {
      showAlert(
        vendorId == null ? Messages.SuccessCreate : Messages.SuccessUpdate,
        { variant: 'success' }
      )
      _onClose()
      resetForm(getDefaultFormData())
    }

    if (reason === 'delete' && isOk(res)) {
      showAlert(Messages.SuccessDelete, { variant: 'success' })
      _onClose()
      resetForm(getDefaultFormData())
    }

    if (reason === 'delete' && isFail(res)) {
      if (res?.result?.response?.data?.code === ErrorCode.LinkedVendor) {
        setIsDateError(true)
        setError(res?.result?.response?.data?.message)
      } else {
        showAlert(res?.result)
      }
    }
  }, [_onClose, showAlert, stopLoader, vendorId])

  const {
    values,
    errors,
    touched,
    onChange: _onChange,
    onBlur,
    onSubmit,
    onDelete,
    resetForm,
    setFieldTouched,
    validateField
  } = useForm<VendorFormData, ErrTouchShortcuts>({
    transforms: FIELD_TRANSFORMS,
    baseValues: DEFAULT_FORM_DATA,
    validationRules,
    submitAction,
    deleteAction,
    onActionResult
  })

  const onClose = useCallback(() => {
    resetForm(getDefaultFormData())
    setIsDateError(false)
    setError('')
    _onClose?.()
  }, [_onClose, resetForm])

  /**
   * Payable Account dropdown
   */
  const getPayableAccounts = useCallback(async (payload) => {
    try {
      const response = await AccountingApiProvider.getAccounts({
        ...payload,
        categoryIds: [AccountCategoryId.Payable]
      })
      const items = response.items.map(namifyAccount)
      return { items }
    } catch (err) {
      return { items: [] }
    }
  }, [])

  /**
   * AS-NOTE: check whether this needed.
   * Supposing because of blurMode='create'
   * Problem is that validation is applied after default blur
   * but before setting value from 'input to create'
   */
  const onNameBlur = useCallback((e: FocusEvent<HTMLInputElement>): void => {
    const id = e.target.id as FieldPath
    const value = e.target.value

    _onChange(id, isStringEmpty(value) ? null : value)
    setFieldTouched(id, true)
  }, [_onChange, setFieldTouched])

  const onChange = useCallback((field: string, value: unknown): void => {
    const fieldKey = field as FieldPath
    setIsDateError(false)
    setError('')

    switch (fieldKey) {
      case 'name': {
        if (value == null || typeof value === 'string') {
          _onChange(fieldKey, value ?? null)
        } else {
          const vendor = value as TransactionLineVendorDto
          resetForm(parseVendorData(vendor))
        }
        break
      }

      case 'ein': {
        if (typeof value === 'string') {
          _onChange(fieldKey, value.replace(/[^-a-zA-Z0-9]/g, ''))
        }
        break
      }

      case 'address':
      case 'mailingAddress': {
        const addressData = value as FullAddressParts | null
        _onChange(fieldKey, addressData)
        break
      }

      case 'isDifferentMailingAddress': {
        _onChange(fieldKey, value)

        if (value === false) {
          _onChange('mailingAddress', { ...emptyAddress })
        }
        break
      }

      case 'isTrack1099Payments': {
        _onChange(fieldKey, value)
        validateField('ein')
        validateField('ssn')
        break
      }

      default: {
        _onChange(fieldKey, value)
      }
    }
  }, [_onChange, validateField, resetForm])

  const loadVendorData = useCallback(async (id: number): Promise<void> => {
    try {
      startLoader()
      const vendor = await AccountingApiProvider.getVendor(id)
      setLienholderId(vendor.lienholderId)
      resetForm(parseVendorData(vendor))
    } catch (err) {
      showAlert(err)
    } finally {
      stopLoader()
    }
  }, [startLoader, resetForm, showAlert, stopLoader])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Load vendor data if dialog is open in "edit" mode.
   */
  useEffect(() => {
    if ((vendorId == null) || !isOpen) {
      setLienholderId(null)
      return
    }

    void loadVendorData(vendorId)
  }, [vendorId, isOpen, loadVendorData])

  // ========================================== //

  return {
    isEdit: vendorId != null,
    lienholderId,
    isLoading,
    isOpen,
    values,
    errors,
    touched,
    isDateError,
    error,
    onChange,
    onBlur,
    onNameBlur,
    onDelete,
    onSubmit,
    onClose,
    getPayableAccounts
  }
}

export default useVendor
