import type { FC } from 'react'
import { Loader, cnx } from '@carfluent/common'

import Table from 'components/common/Table'
import IconSVG from 'components/inlineImages'
import Tooltip from 'components/common/Tooltip'
import { type UseLeadsTabReturn } from 'pages/inventory/VehicleDetails/hooks/useLeadsTab/types'
import { getPageOverlayContainer } from 'utils/html'

import { columns } from './columns'
import PriceHistory from './components/PriceHistory'
import LeadsGraphView from './components/LeadsGraphView'

import CLASS_NAME, { TOOLTIP_CLASS_NAME } from './styles'

interface LeadsPanelProps extends UseLeadsTabReturn {
  containerElement?: HTMLDivElement | null
}

const TOTAL_LEADS_TITLE = (
  <>
    Amount of internet inquiries submitted,<br />
    one lead can submit several inquiries/requests.
  </>
)

const LeadsPanel: FC<LeadsPanelProps> = ({
  leadsTableProps: {
    rows,
    sorting,
    isLoading,
    onSortingChange,
    onBottomReached,
    emptyTableMessage
  },
  viewsCount = 0,
  priceHistoryProps: {
    data,
    graphData,
    totalLeads,
    lastDateTime,
    leadsAmountForRange = 0,
    priceHistoryTotalLeads = 0,
    isLoading: priceHistoryLoading
  }
}) => {
  return (
    <div className={CLASS_NAME}>
      <div className={cnx('graph-view', 'border-styles')}>
        {priceHistoryLoading && (<Loader size={64} />)}

        {!priceHistoryLoading && (
          graphData.length > 0
            ? <LeadsGraphView data={graphData} />
            : <p className='no-data-label'>No data yet</p>
        )}
      </div>

      <div className='price-history'>
        <div className={cnx('page-visits', 'border-styles')}>
          <h6>Page visits</h6>
          <p>{viewsCount > 0 ? viewsCount : '-'}</p>
        </div>

        <div className={cnx('leads-for-days', 'border-styles')}>
          <h6>Inquiries for last 15d</h6>
          <p>{leadsAmountForRange > 0 ? leadsAmountForRange : '-'}</p>
        </div>

        <div className={cnx('total-leads', 'border-styles ')}>
          <h6>
            Total inquiries
            <Tooltip
              title={TOTAL_LEADS_TITLE}
              className={TOOLTIP_CLASS_NAME}
            >
              <IconSVG.Info color='#21212150' width={16} height={16} />
            </Tooltip>
          </h6>
          <p>{priceHistoryTotalLeads > 0 ? priceHistoryTotalLeads : '-'}</p>
        </div>

        <div className={cnx('price-history-table', 'border-styles')}>
          <PriceHistory lastDateTime={lastDateTime} isLoading={priceHistoryLoading} priceHistoryList={data} />
        </div>
      </div>

      <div className='leads-table'>
        <Table
          data={rows}
          sorting={sorting}
          isLoading={isLoading}
          onSortingChange={onSortingChange}
          onBottomReached={onBottomReached}
          containerElement={getPageOverlayContainer()}
          emptyTableMessage={emptyTableMessage}
          columns={columns({ totalLeads })}
        />
      </div>
    </div>
  )
}

export default LeadsPanel
