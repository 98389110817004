import { serializers } from '@carfluent/common'

import { PaymentTypeId, type DealCreateRequestDto } from 'api/types/customersCore.types'
import { type NewDealFormData } from 'types'
import { isPersonalType } from 'utils/deals/workflowTypes'
import { isEmptyString } from 'utils/validation'

const { serializeDate } = serializers

export const serializeAddNewDealData = (data: NewDealFormData): DealCreateRequestDto => {
  const isBusiness = !isPersonalType(data.workflowType?.id)

  return {
    leadId: data.customer?.id ?? null,
    leadSourceId: data.customer != null ? data.customer.leadSourceId : data.source?.id ?? null,
    vehicleMileage: data.vehicle.mileage,
    vehiclePrice: data.vehicle.salePrice,
    vehicleStockNumber: data.vehicle.stock,
    vehicleVIN: data.vehicle.vin,
    vehicleId: data.vehicle.id,
    dealFinanceTypeId: (data.paymentType)?.id as PaymentTypeId, // trimming null value from type
    salespersonId: (data.salesperson)?.id ?? null,
    workflowType: data.workflowType?.id ?? 0,
    businessDetails: isBusiness ? data.businessDetails : null,
    personalDetails: {
      firstName: data.customerFirstName,
      lastName: data.customerLastName,
      phoneNumber: data.customerPhoneNumber,
      email: isEmptyString(data.customerEmail) ? null : data.customerEmail,
      birthDate: serializeDate(data.customerBirthDate),
      secondaryAddress: null,
      county: data.addressData.county,
      state: data.addressData?.state !== '' ? data.addressData.state : null,
      city: data.addressData.city,
      address: data.addressData.address,
      zipCode: data.addressData.zipCode,
      apt: data.addressData.apt ?? null
    }
  }
}
