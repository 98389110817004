import type { FC } from 'react'
import CLASS_NAME from './styles'
import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

const DropzoneContentLoaderSkeleton: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <div className='row-skeleton'>
        <div className='cell-skeleton'>
          <TextSkeleton />
        </div>
        <div className='cell-skeleton'>
          <TextSkeleton />
        </div>
        <div className='cell-skeleton'>
          <TextSkeleton />
        </div>
      </div>
      <div className='row-skeleton'>
        <div className='cell-skeleton'>
          <TextSkeleton />
        </div>
        <div className='cell-skeleton'>
          <TextSkeleton />
        </div>
        <div className='cell-skeleton'>
          <TextSkeleton />
        </div>
      </div>
    </div>
  )
}

export default DropzoneContentLoaderSkeleton
