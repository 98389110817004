import { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useModal } from '@carfluent/common'

import DocumentsApiProvider from 'api/documents.api'
import useTableApi from 'hooks/useTableApi'
import { calcTableHeight } from 'utils/math'
import { TABLE_MIN_HEIGHT } from 'constants/constants'
import { Routes } from 'constants/route_helper'

import type { FormPackRow, UseFormPacksListReturn } from './types'
import { CANCEL_LOAD_DATA, DEFAULT_SORTING, EmptyTableMsg } from './constants'
import columnDefinitions from './columns'
import parseListData from './parser'

const useFormPacks = (): UseFormPacksListReturn => {
  const {
    isModalOpen: isNewFormPackModalOpen,
    onCloseModal: onCloseNewFormPackModal,
    onOpenModal: onOpenNewFormPackModal
  } = useModal()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const {
    isLoading,
    emptyTableMessage,
    rows,
    search,
    sorting,
    loadRows,
    setSearch,
    setSorting,
    onSearch
  } = useTableApi({
    defaultFilters: null,
    defaultSorting: DEFAULT_SORTING,
    getList: DocumentsApiProvider.getFormPacks,
    parseListData: parseListData,
    nothingFoundMessage: EmptyTableMsg.NoItemsFound,
    emptyTableMessage: EmptyTableMsg.NoItems,
    cancelationOptions: CANCEL_LOAD_DATA
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onOpenFormPackDetails = useCallback((row?: FormPackRow) => {
    const id = row?.original.id

    if (id != null) {
      navigate(`${Routes.SettingsFormPacks}/${id}`)
    }
  }, [navigate])

  const onBottomReached = useCallback(async () => {
    if (!isLoading && TABLE_MIN_HEIGHT <= calcTableHeight(rows.length)) {
      await loadRows({ forceRefresh: false })
    }
  }, [isLoading, loadRows, rows.length])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (pathname === Routes.SettingsFormPacks) {
      void loadRows()
    }
  }, [pathname, loadRows])

  // ========================================== //

  return {
    isNewFormPackModalOpen,
    onCloseNewFormPackModal,
    onOpenNewFormPackModal,
    isLoading,
    emptyTableMessage,
    rows,
    columns: columnDefinitions,
    sorting,
    search,
    onOpenFormPackDetails,
    onSearchChange: setSearch,
    onBottomReached,
    onSearch,
    setSorting
  }
}

export default useFormPacks
