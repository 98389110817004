import type { FC } from 'react'
import { CellWrapper, cnx } from '@carfluent/common'

import CompleteOrIncomplete from 'components/deals/CompleteOrIncomplete'
import DepositedLabel from 'components/common/DepositedLabel'

import CLASS_NAME from './styles'

export interface StatusCellProps {
  getValue: () => DealStatus
}

export interface DealStatus {
  status: string
  isPending: boolean
  isCompleted: boolean
  isDeposited: boolean
  isCanceled: boolean
}

const StatusCell: FC<StatusCellProps> = ({ getValue }) => {
  const {
    status,
    isPending,
    isCompleted,
    isDeposited,
    isCanceled
  } = getValue()

  return (
    <CellWrapper className={CLASS_NAME}>
      <div className='deal-status'>
        {(isCanceled) && (
          <div className={cnx(isCanceled && 'deal-canceled')}>{status}</div>
        )}
        {!isPending && !isCanceled && <CompleteOrIncomplete isComplete={isCompleted} />}
        {isDeposited && !isCompleted && <DepositedLabel className='deposited-label' />}
      </div>

    </CellWrapper>
  )
}

export default StatusCell
