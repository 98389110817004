import { type FC, useCallback } from 'react'
import { cnx } from '@carfluent/common'

import ErrorsBlock from 'components/common/ErrorsBlock'
import { getDisclosureUrl } from 'constants/index'
import { DATE_US_FORMAT, TIME_12H_MIN_AMPM_FORMAT } from 'constants/constants'
import { formatUTCDateTimeForView } from 'utils/view_helper'
import { isPersonalType } from 'utils/deals/workflowTypes'
import { filterCoApplicantTypes as _filterCoApplicantTypes } from 'utils/creditApplication'
import type { BaseListItem } from 'types'
import { type CreditApplicantFormProps } from '../../types'
import TradeInDetails from 'components/deals/TradeInDetails'
import PersonalDetails from '../PersonalDetails'
import OtherIncome from '../OtherIncome'
import ApplicantHousingAndEmployment from '../ApplicantHousingAndEmployment'
import Financing from '../Financing'
import Warranty from '../Warranty'
import AdditionalCosts from '../AdditionalCosts'
import CoApplicantDetails from '../CoApplicant'
import BusinessDetails from '../BusinessDetails'
import BankDetails from '../BankDetails'
import RepresentativeDetails from '../RepresentativeDetails'
import CLASS_NAME from './styles'

const disclosureUrl = getDisclosureUrl()

const CreditApplicationForm: FC<CreditApplicantFormProps> = ({
  apiErrors,
  feesAndCoveragesSettings,
  isTradeInSectionVisible,
  isWarrantySectionVisible,
  isCoApplicantSectionVisible,
  employmentStatuses,
  housingStatuses,
  wholesaleSourceTypes,
  coApplicantTypes,
  businessTypes,
  onApplyVin,
  onBlur,
  onChange,
  onSubmitSalesTax,
  onToggleIsTradeInSectionVisible,
  onToggleIsWarrantySectionVisible,
  onToggleIsCoapplicantSectionVisible,
  onToggleDealerProduct,
  setFieldTouched,
  values,
  errors,
  touched
}) => {
  const {
    isCreditApplicationSubmitted,
    creditApplicationSubmittedDate
  } = values

  const isFormDisabled = isCreditApplicationSubmitted
  const isPersonal = isPersonalType(values.workflowTypeId)
  const isBusiness = !isPersonal

  const filterCoApplicantTypes = useCallback((options: BaseListItem[]): BaseListItem[] => {
    return _filterCoApplicantTypes(options, values.customerDetails?.customerState)
  }, [values.customerDetails?.customerState])

  /**
   * DD-NOTE:
   * cf-modal-content-scroll-wrapper is a hardcode but this is how form finds its scrollable parent.
   * DD-TODO: fix it
   */

  return (
    <div className={cnx('cf-modal-content-scroll-wrapper', CLASS_NAME)}>
      <div className='credit-application-form-content'>
        <div className='credit-application-title-block'>
          <p className='credit-application-form-title'>
            Credit application
          </p>

          {(isCreditApplicationSubmitted && creditApplicationSubmittedDate != null) && (
            <div className='credit-application-submitted-info'>
              <p>
                Submitted {formatUTCDateTimeForView(creditApplicationSubmittedDate.toString(), DATE_US_FORMAT)}
              </p>
              <p>
                {formatUTCDateTimeForView(creditApplicationSubmittedDate.toString(), TIME_12H_MIN_AMPM_FORMAT)}
              </p>
            </div>
          )}
        </div>

        {
          apiErrors != null && (
            <ErrorsBlock
              className='credit-application-errors-block'
              errors={apiErrors}
            />
          )
        }

        {
          isBusiness && (
            <>
              <BusinessDetails
                values={values.businessDetails}
                errors={errors.businessDetails}
                touched={touched.businessDetails}
                isFormDisabled={isFormDisabled}
                businessTypes={businessTypes}
                onChange={onChange}
                onBlur={onBlur}
              />

              <hr className='credit-application-divider' />
            </>
          )
        }

        <PersonalDetails
          values={values}
          errors={errors}
          touched={touched}
          isFormDisabled={isFormDisabled}
          isPersonal={isPersonal}
          onChange={onChange}
          onBlur={onBlur}
        />

        <hr className='credit-application-divider' />

        {
          isBusiness && (
            <>
              <BankDetails
                values={values.bankDetails}
                errors={errors.bankDetails}
                touched={touched.bankDetails}
                isFormDisabled={isFormDisabled}
                onChange={onChange}
                onBlur={onBlur}
              />

              <hr className='credit-application-divider' />

              <RepresentativeDetails
                values={values}
                errors={errors}
                touched={touched}
                isFormDisabled={isFormDisabled}
                onChange={onChange}
                onBlur={onBlur}
              />

              <hr className='credit-application-divider' />
            </>
          )
        }

        <TradeInDetails
          className='credit-app-section'
          values={values.tradeInDetails}
          errors={errors.tradeInDetails}
          touched={touched.tradeInDetails}
          isFormDisabled={isFormDisabled}
          isTradeInSectionVisible={isTradeInSectionVisible}
          onToggleIsTradeInSectionVisible={onToggleIsTradeInSectionVisible}
          onApplyVin={onApplyVin}
          onChange={onChange}
          onBlur={onBlur}
        />

        <hr className='credit-application-divider' />

        {
          isPersonal && (
            <>
              <ApplicantHousingAndEmployment
                employmentStatuses={employmentStatuses}
                housingStatuses={housingStatuses}
                isFormDisabled={isFormDisabled}
                values={values.applicantFinancialDetails}
                errors={errors.applicantFinancialDetails}
                touched={touched.applicantFinancialDetails}
                onChange={onChange}
                onBlur={onBlur}
              />

              <hr className='credit-application-divider' />

              <OtherIncome
                pathPrefix='applicantFinancialDetails'
                values={values.applicantFinancialDetails}
                errors={errors.applicantFinancialDetails}
                touched={touched.applicantFinancialDetails}
                isFormDisabled={isFormDisabled}
                onChange={onChange}
                onBlur={onBlur}
              />

              <hr className='credit-application-divider' />
            </>
          )
        }

        <CoApplicantDetails
          values={values.coApplicantFinancialDetails}
          errors={errors.coApplicantFinancialDetails}
          touched={touched.coApplicantFinancialDetails}
          isCoApplicantSectionVisible={isCoApplicantSectionVisible}
          isFormDisabled={isFormDisabled}
          isPersonal={isPersonal}
          coApplicantTypes={coApplicantTypes}
          housingStatuses={housingStatuses}
          employmentStatuses={employmentStatuses}
          applicantAddressDetails={values.customerDetails.addressData}
          filterCoApplicantTypes={filterCoApplicantTypes}
          onToggleIsCoapplicantSectionVisible={onToggleIsCoapplicantSectionVisible}
          onChange={onChange}
          onBlur={onBlur}
        />
        <hr className='credit-application-divider' />

        <Financing
          errors={errors}
          isFormDisabled={isFormDisabled}
          feesAndCoveragesSettings={feesAndCoveragesSettings}
          onBlur={onBlur}
          onChange={onChange}
          onSubmitSalesTax={onSubmitSalesTax}
          setFieldTouched={setFieldTouched}
          touched={touched}
          values={values}
          wholesaleSourceTypes={wholesaleSourceTypes}
        />

        <hr className='credit-application-divider' />

        <Warranty
          coverageData={values.coverageData}
          errors={errors.coverageData.coverageDetails}
          touched={touched.coverageData.coverageDetails}
          isFormDisabled={isFormDisabled}
          isWarrantySectionVisible={isWarrantySectionVisible}
          onToggleIsWarrantySectionVisible={onToggleIsWarrantySectionVisible}
          onToggleDealerProduct={onToggleDealerProduct}
          onChange={onChange}
          onBlur={onBlur}
        />

        <hr className='credit-application-divider' />

        <AdditionalCosts
          values={values.additionalCosts}
          errors={errors.additionalCosts}
          touched={touched.additionalCosts}
          isFormDisabled={isFormDisabled}
          onChange={onChange}
          onBlur={onBlur}
        />
        <hr className='credit-application-divider' />

        <p className='credit-application-text-disclosure'>
          When I, the dealer, select the "Submit This Application" to send this application to
          the financial institutions selected, I acknowledge and agree that:(i) either I
          provided a printed copy of the disclosures to the Applicant(s) or read the
          disclosures to the Applicant(s) verbatim and that the Applicant(s) agreed to the
          disclosures, and (ii) the Applicant(s) agreed that I may submit this application to
          all selected financial institutions.{' '}
          <a
            className='credit-application-link-disclosure'
            target='_blank'
            rel='noreferrer'
            href={disclosureUrl}
          >
            View Disclosure
          </a>
        </p>
      </div>
    </div>
  )
}

export default CreditApplicationForm
