import { css } from '@emotion/css'

interface StylesProps {
  isReceived: boolean
  imagesNum: number
}

enum FlexMessageLayout {
  Received = 'flex-start',
  Sent = 'flex-end'
}

enum BackgroundMessages {
  Received = '#ffffff',
  Sent = '#FFFBF3'
}

const MIN_THUMBNAIL_WIDTH = 80
const MAX_THUMBNAIL_WIDTH = 165

const getThumbnailWidth = (imagesNum: number): number => {
  return imagesNum < 3
    ? MAX_THUMBNAIL_WIDTH
    : MIN_THUMBNAIL_WIDTH
}

export const createUseStyles = ({
  isReceived,
  imagesNum
}: StylesProps): string => {
  const justifyContent = isReceived ? FlexMessageLayout.Received : FlexMessageLayout.Sent
  const background = isReceived ? BackgroundMessages.Received : BackgroundMessages.Sent
  const thumbnailWidth = getThumbnailWidth(imagesNum)

  return css(`
    display: flex;
    justify-content: ${justifyContent};
    margin-bottom: 16px;
    width: 100%;
    
    .message-content {
      padding: 16px;
      width: 380px;
      background: ${background};
      border-radius: 12px 12px ${isReceived ? '12px 0px' : '0px 12px'};
      box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.02);
      
      .message-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        
        .message-name {
          color: #101010;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.4;
        }
        
        .message-date {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #21212150;
        }
      }
      
      .message {
        position: relative;
        word-break: break-word;

        span {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
        }
        
        a {
          word-wrap: break-word;
          color: rgba(69, 143, 255, 1);
        }

        &.message-sending { min-height: 48px; }
        
        .sending-note {
          position: absolute;
          bottom: 0;
          right: 8px;
          color: rgba(33, 33, 33, 0.5);
          font-size: 10px;
          line-height: 24px;
        }
      }

      .images-section {
        display: none;
        display: flex;
        flex-wrap: wrap;

        &.is-visible { display: block; }

        .file-thumbnail {
          display: inline-block;
          position: relative;
          width: ${thumbnailWidth}px;
          height: ${thumbnailWidth}px;
          margin-right: 8px;
          margin-top: 8px;
          cursor: pointer;

          img {
            transition: transform 0.2s ease, filter 0.2s ease;
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }
          
          :hover img { filter: brightness(50%); }
        }
      }
    }
  `)
}
