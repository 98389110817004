import { type FC, useState, useEffect, useRef } from 'react'
import { Modal, Loader } from '@carfluent/common'
import { uniqueId } from 'lodash-es'

import IconSVG from 'components/inlineImages'

import Message from 'components/common/Messenger/components/Message'

import CLASS_NAME from './styles'
import CRMApiProvider from 'api/crm.api'
import type { WebChatItemModel } from 'api/types/responses'
import { joinPartsBySpace } from 'utils/view_helper'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

export interface WebChatInfo {
  id: string | null
  firstName?: string | null
  lastName?: string | null
}

interface MessengerPreviewProps {
  webChatInfo: WebChatInfo | null
  onClose: () => void
}

const getDefaultMessage = (): WebChatItemModel => ({
  id: uniqueId(),
  isReceived: true,
  message: 'Hello!\n How can I assist you?',
  name: 'AI Assistant'
})

const MessengerPreview: FC<MessengerPreviewProps> = ({
  onClose,
  webChatInfo
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [messages, setMessages] = useState<WebChatItemModel[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const { showAlert } = useCustomSnackbar()

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      if (webChatInfo?.id == null) {
        setMessages([])
        return
      }

      try {
        setIsLoading(true)
        const res = await CRMApiProvider.getWebChatMessages(webChatInfo.id)
        setMessages([getDefaultMessage(), ...res].reverse())

        if (contentRef.current != null) {
          contentRef.current.scrollTop = -contentRef.current.scrollHeight
        }
      } catch (e) {
        showAlert(e)
      } finally {
        setIsLoading(false)
      }
    }

    void runEffect()
  }, [webChatInfo, showAlert])

  const userName = (
    (webChatInfo?.firstName != null && webChatInfo?.firstName.trim() !== '') ||
    (webChatInfo?.lastName != null && webChatInfo?.lastName.trim() !== '')
  )
    ? joinPartsBySpace(webChatInfo.firstName, webChatInfo.lastName)
    : 'Unknown name'

  return (
    <Modal
      isOpen={webChatInfo != null}
      className={CLASS_NAME}
    >
      {isLoading && (
        <div className='loader-wrapper'>
          <Loader size='medium' />
        </div>
      )}

      <div className='messenger-header'>
        Website chat conversation

        <IconSVG.Close onClick={onClose} />
      </div>

      <div ref={contentRef} className='messenger-content'>
        {messages.map((message) => {
          return (
            <Message
              key={message.id}
              {...message}
              name={message.isReceived ? message.name : userName}
            />
          )
        })}
      </div>
    </Modal>
  )
}

export default MessengerPreview
