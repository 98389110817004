import type { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { CellWrapper } from '@carfluent/common'

import HiddenItemsLabel from 'components/common/HiddenItemsLabel'
import TooltipedText from 'components/common/TooltipedText'

import type { LeadsListCarOfInterest } from 'api/types'
import CalcRoutes from 'constants/route_helper'

import CLASS_NAME from './styles'

export interface CarOfInterestCellProps {
  getValue: () => LeadsListCarOfInterest[]
}

const CarOfInterestCell: FC<CarOfInterestCellProps> = (props) => {
  const cars = props.getValue() ?? []
  const otherCars = cars.length - 1
  const primaryCar = cars.find((car) => car.isPrimary)

  const displayValue = `${primaryCar?.year ?? ''} ${primaryCar?.make ?? ''} ${primaryCar?.model ?? ''}`

  return (
    <CellWrapper className={CLASS_NAME}>
      {cars.length === 0 && <p className='cf-empty-value'> No car of interest </p>}
      {(primaryCar != null) && (
        <NavLink
          to={CalcRoutes.VehicleDetails(primaryCar.id)}
          onClick={(event) => event.stopPropagation()}
        >
          <TooltipedText
            value={displayValue}
            rootClassName='cf-item-link-content'
          />
          <p className='cf-text-with-label'>
            <span className='cf-stock-number'>
              {primaryCar.stock}
            </span>

            {otherCars > 0 && (
              <HiddenItemsLabel
                className='cf-hidden-items-label'
                text={`+${otherCars} more car${otherCars > 1 ? 's' : ''}`}
              />
            )}
          </p>
        </NavLink>
      )}
    </CellWrapper>
  )
}

export default CarOfInterestCell
