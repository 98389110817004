import { type FC } from 'react'
import { type MenuIconProps } from 'types'

const ReconSettingsMenuIcon: FC<MenuIconProps> = ({
  isActive = false
}) => {
  const pictogramFill = isActive ? '#fff' : '#101010'

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 15H9V11H7.5V12.25H6V13.75H7.5V15ZM10 13.75H18V12.25H10V13.75ZM15 11H16.5V9.75H18V8.25H16.5V7H15V11ZM6 9.75H14V8.25H6V9.75ZM8 21V19H4C3.45 19 2.979 18.804 2.587 18.412C2.195 18.02 1.99934 17.5493 2 17V5C2 4.45 2.196 3.979 2.588 3.587C2.98 3.195 3.45067 2.99934 4 3H20C20.55 3 21.021 3.196 21.413 3.588C21.805 3.98 22.0007 4.45067 22 5V17C22 17.55 21.804 18.021 21.412 18.413C21.02 18.805 20.5493 19.0007 20 19H16V21H8ZM4 17H20V5H4V17Z"
        fill={pictogramFill}
      />
    </svg>
  )
}

export default ReconSettingsMenuIcon
