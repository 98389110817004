import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useLoader, useRefUpdater } from '@carfluent/common'

export interface UseAccountCategoriesProps<TRawData, TParsed> {
  getData: (...args: any[]) => Promise<TRawData>
  getEmptyState: () => TParsed
  getFromStore?: () => TRawData | null
  parseData: (data: TRawData) => TParsed
  saveToStore?: (data: TRawData) => void
  shouldPrefetch?: boolean
}

export interface UseAccountCategoriesReturn<TParsed> {
  getData: (isForced?: boolean, shouldUseCached?: boolean) => Promise<TParsed>
  isLoading: boolean
}

function usePrefetchable <TRawData, TParsed> ({
  getData,
  getFromStore,
  getEmptyState,
  parseData,
  saveToStore,
  shouldPrefetch = false
}: UseAccountCategoriesProps<TRawData, TParsed>): UseAccountCategoriesReturn<TParsed> {
  const { isLoading, startLoader, stopLoader } = useLoader()
  const refShouldPrefetch = useRefUpdater(shouldPrefetch)
  const refFetching = useRef<Promise<TRawData> | null>(null)
  const refLastFetched = useRef<TParsed | null>(null)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  /**
   * Returns loaded or cached data.
   *
   * @param {boolean} shouldUseCached - has a highest priority. 
   * @param { boolean} isForced - forces loading of fresh data, if `shouldUseCached` != true
   */
  const loadData = useCallback(async (
    isForced = false,
    shouldUseCached = true
  ): Promise<TParsed> => {
    try {
      const dataFromStore = getFromStore?.()
      const cachedData = dataFromStore != null
        ? parseData(dataFromStore)
        : refLastFetched.current

      if (shouldUseCached && (cachedData != null)) {
        return cachedData
      }

      startLoader()

      if ((refFetching.current == null) || isForced) {
        refFetching.current = getData()
      }

      const data = await refFetching.current
      saveToStore?.(data) // We save raw (unparsed) data to store, to not erase any info from original response.

      const parsedData = parseData(data)
      refLastFetched.current = parsedData

      return parsedData
    } catch (err) {
      return getEmptyState()
    } finally {
      refFetching.current = null
      stopLoader()
    }
  }, [
    getData,
    parseData,
    getEmptyState,
    startLoader,
    stopLoader
  ])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (refShouldPrefetch.current) {
      void loadData()
    }
  }, [loadData])

  // ========================================== //

  return useMemo(() => ({
    getData: loadData,
    isLoading
  }), [
    isLoading,
    loadData
  ])
}

export default usePrefetchable
