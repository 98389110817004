import { dates, UI, type Preset } from '@carfluent/common'
import { ReportAccountTypes } from 'api/types/accounting.types'
import { addMonths } from 'date-fns'
import fileNames from 'utils/reports/fileNames'

export const GROUP_TITLES = {
  [ReportAccountTypes.Assets]: 'Assets',
  [ReportAccountTypes.Equity]: 'Equity',
  [ReportAccountTypes.Liabilities]: 'Liabilities'
}

export const DEFAULT_DATA_PROPS = {
  types: ReportAccountTypes,
  titles: GROUP_TITLES
}

export const REPORT_FILE_NAMES = fileNames('BalanceSheetReport')

export const getPresets = (): Preset[] => {
  const today = new Date()
  const prevMonthStart = addMonths(dates.getMonthStart(today), -1)
  const prevMonthEnd = dates.getMonthEnd(prevMonthStart)
  const defaultPresets = UI.getDefaultDateRangePresets(today, prevMonthEnd)
  const todayRange = defaultPresets.filter(p => p.name === UI.PRESET_LABELS.TODAY)
  const yesterdayRange = defaultPresets.filter(p => p.name === UI.PRESET_LABELS.YESTERDAY)

  return todayRange.concat(yesterdayRange)
}

export const DEFAULT_PRESETS = getPresets()
