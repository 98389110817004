import { type FC } from 'react'
import { FormNumberInput, Button, formatCurrencyDecimal } from '@carfluent/common'

import { RadioGroup } from 'components/common/RadioGroup'
import { MAX_PRICE_VALUE } from 'constants/validation'
import { PercentOrAmountModes } from 'types'

import { type UseSalesTaxEditorProps } from './types'
import { useSalesTaxEditor } from './hook'
import CLASS_NAME from './styles'
import { decimalToPercent } from 'utils/math'

const SALES_TAX_MODES = [
  { label: 'Sales tax, %', value: 'auto' },
  { label: 'Amount', value: 'manual' }
]

const SalesTaxEditor: FC<UseSalesTaxEditorProps> = (props) => {
  const {
    amountValue,
    helperAmountValue,
    onChangeTax,
    onChangeTaxAmount,
    onChangeTaxMode,
    onOverrideData,
    taxValue,
    taxMode
  } = useSalesTaxEditor(props)

  const salesTaxStr = formatCurrencyDecimal(helperAmountValue)

  return (
    <div className={CLASS_NAME}>
      <div className='form-fields'>
        <RadioGroup
          className='tax-mode-field'
          id='taxMode'
          items={SALES_TAX_MODES}
          onChange={(_, value) => onChangeTaxMode(value)}
          value={taxMode}
        />

        {(taxMode === PercentOrAmountModes.Manual) && (
          <FormNumberInput
            className='sales-tax-input'
            id='taxAmount'
            label='Sales tax amount'
            preset='price'
            blurComplementMode='min-scale'
            mountComplementMode='min-scale'
            isEmptyAllowed
            value={amountValue}
            onChange={onChangeTaxAmount}
            max={MAX_PRICE_VALUE}
            disabled={props.disabled}
          />
        )}

        {(taxMode === PercentOrAmountModes.Auto) && (
          <>
            <FormNumberInput
              className='sales-tax-input'
              id='tax'
              label='Sales tax'
              preset='percent'
              isEmptyAllowed={false}
              blurComplementMode='min-scale'
              mountComplementMode='min-scale'
              decimalPrecision={3}
              value={decimalToPercent(taxValue ?? 0)}
              onChange={onChangeTax}
              disabled={props.disabled}
            />

            <p className='sales-tax-label'>
              Tax {salesTaxStr}
            </p>
          </>
        )}
      </div>

      <div className='apply-button-wrapper'>
        <Button
          onClick={onOverrideData}
          className='btn-apply'
          variant='contained'
          isDisabled={props.disabled}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}

export default SalesTaxEditor
