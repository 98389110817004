import { emptyAddress } from 'utils/address'
import { type NewDealFormData } from 'types'
import { type Optionals } from 'components/common/AddressFields'

export const DEFAULT_SOURCE = { id: 36, name: 'Other' }

export const getDefaultNewDealFormValues = (values?: Partial<NewDealFormData>): NewDealFormData => ({
  vehicle: {
    mileage: null,
    salePrice: null,
    vin: null,
    stock: null,
    make: null,
    model: null,
    trim: null,
    year: null,
    id: null,
    ...values?.vehicle
  },
  addressData: {
    ...emptyAddress,
    apt: null,
    ...values?.addressData
  },
  paymentType: null,
  salesperson: null,
  source: null,
  customer: null,
  customerBirthDate: null,
  customerPhoneNumber: null,
  customerLastName: null,
  customerFirstName: null,
  customerEmail: null,
  workflowType: {
    id: 1,
    name: 'Personal',
    ...values?.workflowType
  },
  businessDetails: {
    businessName: '',
    businessTypeId: 0,
    ein: '',
    ...values?.businessDetails
  },
  ...values
})

export const FIELD_TRANSFORMS = {
  customerEmail: (value: string) => value.trim()
}

export const ADDRESS_OPTIONALITY_LABELS: Optionals = {
  address: true,
  apt: true,
  city: true,
  state: true
}
