import type { FC } from 'react'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

const EMPTY_ROWS_COUNT = 5

export interface LoadingRowsProps {
  rowNumbers?: number
  columNumber?: number
}

const LoadingRows: FC<LoadingRowsProps> = ({ rowNumbers = EMPTY_ROWS_COUNT, columNumber = EMPTY_ROWS_COUNT }) => (
  <>
    {new Array(rowNumbers).fill(null).map((_, i) => (
      <div className='tr' key={i}>
        {new Array(columNumber).fill(null).map((_, i) => (
          <div className='cell-value' key={i}>
            <BlockSkeleton isLoading />
          </div>
        ))}
      </div>
    ))}
  </>
)

export default LoadingRows
