import { useEffect, useMemo, useState } from 'react'
import type { FCHook } from 'types'
import type { AccountListItem } from 'api/types'
import AccountingApiProvider from 'api/accounting.api'
import { namifyAccount } from 'utils/accounting/namifyAccount'

import type { UseVehiclePurchasesPaymentSectionProps, UseVehiclePurchasesPaymentSectionReturn } from './types'
import { PaymentSectionType } from '../types'

const useVehiclePurchasesPaymentSection: FCHook<UseVehiclePurchasesPaymentSectionProps, UseVehiclePurchasesPaymentSectionReturn> = ({ paymentSectionType }) => {
  const [selectedAccounts, setSelectedAccounts] = useState<AccountListItem[]>([])
  const [isOpen, setIsOpen] = useState(false)

  const accounts = useMemo(() => selectedAccounts.map(namifyAccount), [selectedAccounts])

  // ========================================== //
  //                  HANDLERS                  //
  // ========================================== //

  const onOpenModal = (): void => {
    setIsOpen(!isOpen)
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    void (async (): Promise<void> => {
      const methodName = paymentSectionType === PaymentSectionType.VehiclePurchase
        ? 'getVehiclePaymentAccountsList'
        : 'getVehicleCostPaymentAccounts'
      const res = await AccountingApiProvider[methodName]()

      setSelectedAccounts(res.items)
    })()
  }, [paymentSectionType])

  // ========================================== //

  return {
    isOpen,
    accounts,
    onOpenModal,
    selectedAccounts,
    setSelectedAccounts
  }
}

export default useVehiclePurchasesPaymentSection
