import { FC, ReactNode, MouseEvent, useRef } from 'react'
import { useCallback, useState } from 'react'
import { cn } from '@carfluent/common'

import Popover from 'components/common/Popover'

import downArrowIcon from 'assets/black_down_arrow.svg'
import type { PopoverProps } from 'components/common/Popover'

import {
  CONTENT_CLASS_NAME,
  LABEL_CLASS_NAME,
  POPOVER_CLASS_NAME
} from './styles'

const MIN_POPOVER_WIDTH = 184

interface HiddenItemsLabelProps {
  text: string
  icon?: ReactNode
  IconComponent?: FC<{ isActive?: boolean }>
  content?: ReactNode
  popoverProps?: Omit<PopoverProps,  'anchorEl' | 'onClose'>
  className?: string
  isClosedByLabelMouseOut?: boolean
  onClick?: (evt: MouseEvent) => void
}

// TODO-OF will be moved to common library after testing
const HiddenItemsLabel: FC<HiddenItemsLabelProps> = ({
  text,
  content = null,
  className = '',
  popoverProps = {},
  icon = null,
  IconComponent,
  isClosedByLabelMouseOut = true,
  onClick
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const refIsLabelHovered = useRef(false)
  const refIsContentHovered = useRef(false)
  const refLabel = useRef<HTMLParagraphElement | null>(null)

  const isContentExist = content != null
  const iconComp = IconComponent != null
    ? <IconComponent isActive={isPopoverOpen} />
    : icon ?? <img src={downArrowIcon} alt='down_arrow' />

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onLabelMouseEnter = useCallback(() => {
    refIsLabelHovered.current = true
    setIsPopoverOpen(true)
  }, [])

  const onLabelMouseLeave = useCallback(() => {
    refIsLabelHovered.current = false

    if (isClosedByLabelMouseOut) {
      setIsPopoverOpen(false)
      return
    }

    setTimeout(() => {
      if (!refIsContentHovered.current) {
        setIsPopoverOpen(false)
      }
    }, 100);
  }, [isClosedByLabelMouseOut])

  const onContentMouseEnter = useCallback(() => {
    refIsContentHovered.current = true
  }, [])

  const onContentMouseLeave = useCallback(() => {
    refIsContentHovered.current = false

    setTimeout(() => {
      if (!refIsLabelHovered.current) {
        setIsPopoverOpen(false)
      }
    }, 100);
  }, [])

  const onContentClick = useCallback((evt: MouseEvent) => {
    evt.stopPropagation()
  }, [])

  // ========================================== //

  return (
    <>
      {(text != null) && (
        <p
          ref={refLabel}
          className={cn(LABEL_CLASS_NAME, className, isPopoverOpen && 'cf-label-active')}
          onMouseOver={onLabelMouseEnter}
          onMouseLeave={onLabelMouseLeave}
          onClick={onClick}
        >
          {text}
          {isContentExist && iconComp}
        </p>
      )}

      {isContentExist && (
        <Popover
          {...popoverProps}
          open={isPopoverOpen}
          anchorEl={refLabel.current}
          minWidth={MIN_POPOVER_WIDTH}
          className={cn(POPOVER_CLASS_NAME, popoverProps?.className ?? '')}
        >
          <div
            className={CONTENT_CLASS_NAME}
            onClick={onContentClick}
            onMouseEnter={onContentMouseEnter}
            onMouseLeave={onContentMouseLeave}
          >
            {content}
          </div>
        </Popover>
      )}
    </>
  )
}

export default HiddenItemsLabel
