import type { ColumnDef } from '@carfluent/common/dist/UI'
import { formatCurrencyDecimal } from '@carfluent/common'

import TextCellHOC from 'components/common/Table/cells/TextCell'
import TooltipCell from 'components/common/Table/cells/TooltipCell'
import { LinkCellHOC } from 'components/common/Table/cells/LinkCell'

import type { ReportCarRepricingItemDto } from 'api/types'

import LastDateCell from '../components/LastDateCell'

const EMPTY_VALUES = { emptyValues: '-' }

export const columns: Array<ColumnDef<ReportCarRepricingItemDto>> = [
  {
    id: 'car',
    accessorFn: (row) => ({
      path: `/vehicles/${row.vehicleId ?? ''}`,
      title: row.car
    }),
    loading: 'big_line',
    header: TextCellHOC({ text: 'Car' }),
    cell: LinkCellHOC({ withTooltip: true }),
    minSize: 165
  },
  {
    id: 'stock',
    accessorFn: (row) => row.stock,
    loading: 'big_line',
    header: TextCellHOC({ text: 'Stock' }),
    cell: TooltipCell
  },
  {
    id: 'age',
    size: 83,
    accessorFn: (row) => `${row.age} ${row.age === 1 ? 'day' : 'days'}`,
    loading: 'big_line',
    header: TextCellHOC({ text: 'Age' }),
    cell: TooltipCell
  },
  {
    id: 'initialPrice',
    size: 125,
    accessorFn: (row) => formatCurrencyDecimal(row.initialPrice, EMPTY_VALUES),
    loading: 'big_line',
    header: TextCellHOC({ text: 'Initial price' }),
    cell: TooltipCell
  },
  {
    id: 'isAutoRepricingEnabled',
    size: 133,
    accessorFn: (row) => row.isAutoRepricingEnabled ? 'On' : 'Off',
    loading: 'big_line',
    header: TextCellHOC({ text: 'Auto repricing' }),
    cell: TooltipCell
  },
  {
    id: 'lastRepricingDate',
    size: 137,
    accessorFn: (row) => row.lastRepricingDate,
    loading: 'big_line',
    header: TextCellHOC({ text: 'Last repricing' }),
    cell: LastDateCell
  },
  {
    id: 'previousPrice',
    accessorFn: (row) => formatCurrencyDecimal(row.previousPrice, EMPTY_VALUES),
    loading: 'big_line',
    header: TextCellHOC({ text: 'Previous price' }),
    cell: TooltipCell
  },
  {
    id: 'currentPrice',
    accessorFn: (row) => formatCurrencyDecimal(row.currentPrice, EMPTY_VALUES),
    loading: 'big_line',
    header: TextCellHOC({ text: 'Current price' }),
    cell: TooltipCell
  },
  {
    id: 'leadsLastWeek',
    accessorFn: (row) => row.leadsLastWeek,
    loading: 'big_line',
    header: TextCellHOC({ text: 'Leads last 7 days' }),
    cell: TooltipCell
  },
  {
    id: 'leadsLastTwoWeeks',
    accessorFn: (row) => row.leadsLastTwoWeeks,
    loading: 'big_line',
    header: TextCellHOC({ text: 'Leads last 14 days' }),
    cell: TooltipCell
  },
  {
    id: 'totalLeads',
    size: 111,
    accessorFn: (row) => row.totalLeads,
    loading: 'big_line',
    header: TextCellHOC({ text: 'Total leads' }),
    cell: TooltipCell
  }
]
