import { useMemo, type FC } from 'react'
import { PRESET_LABELS, TableRowMemoizedByOriginal, type TableRowProps } from '@carfluent/common'

import { BankStatementTransactionState, DateType } from 'api/types'
import { BANK_STATEMENT_TRANSACTION_TYPES } from 'constants/accounting'
import VendorDetails from 'pages/accounting/VendorDetails'
import CustomerDetails from 'pages/accounting/CustomerDetails'
import BackToTop from 'components/common/BackToTop'
import { LoadableTable as Table } from 'components/common/Table'

import TransactionDetails from 'pages/accounting/TransactionDetails'
import { GET_DEFAULT_PRESETS } from 'constants/constants'
import FiltersPanel, { type FilterItem } from 'components/common/FiltersPanel'

import { formatWithRemoveEmptyDecimal } from 'utils/filters/filterPanel'
import RowForReviewHOC from './components/RowForReview'
import useBankStatementsList from './hook'
import { type BankStatementRowData, type UseBankStatementsListProps } from './hook/types'
import useDateFilters from 'hooks/useDateFilters'
import { TABS, TabCounterIds } from './hook/constants'
import { type BaseListItem } from 'types'
import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

export type BankStatementsListProps = UseBankStatementsListProps

const TABLE_CLASSES = { wrapper: 'bank-statements-table' }

const DEFAULT_PRESETS = GET_DEFAULT_PRESETS()

const BankStatementsList: FC<BankStatementsListProps> = (props) => {
  const {
    accountId,
    columns,
    containerElement,
    filters,
    dateFiltersResetMarker,
    emptyTableMessage,
    isLoading,
    onBottomReached,
    onCategorizeRow,
    onExcludeRow,
    onFiltersPanelChange,
    onRowClick,
    onSearch,
    onSearchChange,
    onSortingChange,
    onSelectTab,
    onViewPreloadedTransaction,
    refRowStates,
    rows,
    search,
    selectedTabId,
    showError,
    sorting,
    tabCounters,
    transactionDetailsProps,
    addVendorProps,
    addCustomerProps
  } = useBankStatementsList(props)

  const RowForReview: FC<TableRowProps<BankStatementRowData>> = useMemo(() => RowForReviewHOC({
    accountId,
    onCategorize: onCategorizeRow,
    onExclude: onExcludeRow,
    onViewTransaction: transactionDetailsProps.onViewNextTransaction ?? (() => {}),
    onViewPreloadedTransaction,
    onOpenVendorModal: addVendorProps.onOpenModal,
    onOpenCustomerModal: addCustomerProps.onOpenModal,
    refRowStates,
    showError
  }), [
    addVendorProps.onOpenModal,
    addCustomerProps.onOpenModal,
    accountId,
    refRowStates,
    onCategorizeRow,
    onExcludeRow,
    onViewPreloadedTransaction,
    showError,
    transactionDetailsProps.onViewNextTransaction
  ])

  const tabProps = {
    tabs: TABS.map((item) => ({
      ...item,
      name: item.title,
      counter: tabCounters[TabCounterIds[item.id]]
    })),
    activeTab: selectedTabId,
    isLoading: isLoading,
    onSelectTab
  }

  const onDateFiltersChange = async (date: DateType): Promise<void> => await onFiltersPanelChange({ date })

  const dateFilterProps = useDateFilters({
    defaultPreset: DEFAULT_PRESETS[0],
    setDateFilters: onDateFiltersChange
  })

  const dateFilterItemProps: FilterItem<'date'> = {
    filterType: 'date',
    componentProps: {
      ...dateFilterProps,
      // appliedFilters: filters.date, // does not work because of stupid DatePicker bugs
      onFilterChange: onDateFiltersChange,
      defaultPresetName: PRESET_LABELS.ALL,
      dateFiltersResetMarker
    }
  }

  const txTypeFilterItemProps: FilterItem<'single-select', BaseListItem | null> = {
    filterType: 'single-select',
    componentProps: {
      options: BANK_STATEMENT_TRANSACTION_TYPES,
      disableClearable: true,
      dataTestId: 'filter-entity',
      id: 'transactionType',
      mode: 'select',
      popoverClassName: POPOVER_CLASS_NAME,
      value: filters.transactionType ?? null,
      onChange: (_id, value) => {
        void onFiltersPanelChange({
          transactionType: value
        })
      }
    }
  }

  const amountFilter: FilterItem<'range'> = {
    filterType: 'range',
    componentProps: {
      onApply: (range) => {
        void onFiltersPanelChange({
          amount: {
            from: range?.from ?? null,
            to: range?.to ?? null,
            equal: range?.exact ?? null
          }
        })
      },
      formatter: formatWithRemoveEmptyDecimal,
      values: filters.amount,
      preset: 'price',
      name: 'Amount',
      hasExact: true
    }
  }

  return (
    <div className={CLASS_NAME}>
      <FiltersPanel
        searchProps={{
          disabled: isLoading,
          isLoading,
          onChange: onSearchChange,
          onSearch,
          value: search,
          placeholder: 'Description, info'
        }}
        filterProps={{
          filters: [dateFilterItemProps, txTypeFilterItemProps, amountFilter]
        }}
        tabProps={tabProps}
      />

      <div className='tab-container'>

        <Table
          classes={TABLE_CLASSES}
          columns={columns}
          containerElement={containerElement}
          data={rows}
          emptyTableMessage={emptyTableMessage}
          isLoading={isLoading}
          onBottomReached={onBottomReached}
          onRowClick={onRowClick}
          onSortingChange={onSortingChange}
          RowComponent={(selectedTabId === BankStatementTransactionState.ForReview) ? RowForReview : TableRowMemoizedByOriginal}
          sorting={sorting}
        />

        <TransactionDetails {...transactionDetailsProps} />
        <VendorDetails {...addVendorProps} />
        <CustomerDetails {...addCustomerProps} />
        <BackToTop />
      </div>
    </div>
  )
}

export default BankStatementsList
