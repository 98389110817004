import type { TransactionDetails as ApiTransactionDetails } from 'api/types'
import { TransactionTypeId } from 'api/types'

import { parseTransaction as parseReceivableTransaction } from './receivableParser'
import { parseTransaction as parseJournalEntryTransaction } from './journalEntryParser'
import type { TransactionDetails } from '../types'

type UseParserReturn = (
  transaction: ApiTransactionDetails
) => TransactionDetails

const getParser = (
  type?: number | null
): UseParserReturn => {
  const parseTransaction = (transaction: ApiTransactionDetails): TransactionDetails => {
    switch (type) {
      case TransactionTypeId.Receivable:
      case TransactionTypeId.Receive:
      case TransactionTypeId.Check:
      case TransactionTypeId.PayBill:
      case TransactionTypeId.Payable: {
        return parseReceivableTransaction(transaction)
      }
      case TransactionTypeId.JournalEntry:
      default: {
        return parseJournalEntryTransaction(transaction)
      }
    }
  }

  return parseTransaction
}

export default getParser
