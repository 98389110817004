import DateCell from 'components/common/Table/cells/DateCell'
import NameCell from 'components/common/Table/cells/NameCell'
import ValueCell from 'components/common/Table/cells/ValueCell'
import TextCellHOC from 'components/common/Table/cells/TextCell'

import type { ColumnDefinitions } from './types'

const columnDefinitions: ColumnDefinitions = [
  {
    accessorKey: 'name',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Template name' }),
    size: 350,
    sortable: false
  },
  {
    accessorKey: 'modifiedDate',
    cell: DateCell,
    header: TextCellHOC({ text: 'Last edited' }),
    size: 200,
    sortable: false
  },
  {
    accessorKey: 'modifiedByUser',
    cell: NameCell,
    header: TextCellHOC({ text: 'Edited by' }),
    size: 150,
    sortable: false
  }
]

export default columnDefinitions
