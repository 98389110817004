import type { FC, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { cnx, Button } from '@carfluent/common'

import type {
  MenuButtonProps,
  MenuListProps,
  ActionsMenuCloseReason,
  ActionOptions
} from 'components/common/ActionsMenu'
import ActionsMenu from 'components/common/ActionsMenu'

import arrowBack from 'assets/arrow_back.svg'
import CLASS_NAME from './styles'

export interface TopPanelActionsProps {
  actionButtonName?: string
  actions?: ActionOptions[]
  backButtonName: string
  className?: string
  isActionButtonDisable?: boolean
  isActionsMenuOpen?: boolean
  showBackButton?: boolean
  ActionsMenuButtonComponent?: FC<MenuButtonProps>
  ActionsListComponent?: FC<MenuListProps>
  onActionClick?: () => void
  onGoBack: () => void
  onActionsMenuClose?: (reason: ActionsMenuCloseReason) => void
  onActionsMenuOpen?: () => void
  children?: ReactNode
  isActionBtnLoading?: boolean
  actionMenuPopoverClassName?: string
}

const TopPanelActions: FC<TopPanelActionsProps> = ({
  actionButtonName = '',
  actions = [],
  backButtonName,
  className = '',
  isActionButtonDisable = false,
  isActionsMenuOpen,
  showBackButton = true,
  ActionsMenuButtonComponent,
  ActionsListComponent,
  onActionClick,
  onGoBack,
  onActionsMenuClose,
  onActionsMenuOpen,
  children,
  isActionBtnLoading = false,
  actionMenuPopoverClassName
}) => {
  return (
    <div className={cnx(CLASS_NAME, className)}>
      {showBackButton && (
        <Button
          className='cf-go-back-btn'
          variant='text'
          onClick={onGoBack}
          startAdornment={<img src={arrowBack} alt='arrow back' className='cf-arrow' />}
        >
          {backButtonName}
        </Button>
      )}

      {!isActionButtonDisable && (onActionClick != null) && (
        <Button
          className='cf-action-btn'
          variant='text'
          onClick={onActionClick}
          isLoading={isActionBtnLoading}
        >
          {actionButtonName}
        </Button>
      )}

      {children != null && (
        <div className='children-wrapper'>
          {children}
        </div>
      )}

      {(actions.length > 0) && (
        <ActionsMenu
          actions={actions}
          isOpen={isActionsMenuOpen}
          MenuButtonComponent={ActionsMenuButtonComponent}
          MenuListComponent={ActionsListComponent}
          onMenuClose={onActionsMenuClose}
          onMenuOpen={onActionsMenuOpen}
          isDisabled={isActionBtnLoading}
          actionMenuPopoverClassName={actionMenuPopoverClassName}
        />
      )}
    </div>
  )
}

export default observer(TopPanelActions)
