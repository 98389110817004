import { css } from '@emotion/css'
import { LabelColors } from 'constants/constants'

export default css(`
  p {
    min-width: 34px;
    max-width: 34px;
    color: #FFF;
    text-align: center;
    border-radius: 2px;
    font-weight: 500;
    font-size: 10px;
    height: 20px;
    line-height: 20px;
  }

  .cf-hot {
    background: ${LabelColors.Hot};
  }

  .cf-warm {
    background: ${LabelColors.Warm};
  }

  .cf-cold {
    background: ${LabelColors.Cold};
  }
`)
