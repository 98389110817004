import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  .finances-payments-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    button.cf-button-root.cf-button-variant-text {
      padding: 5px 18px;
      border-radius: 8px;
      border: 1px solid #C99B86;
      text-decoration: none;
      
      :hover {
        border: 1px solid #DDB3A0;
      }
      
      .cf-button-content {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1.25px;
      }
    }
  }
  
  .finances-payment-info {
    background: #F7FAFF;
    border-radius: 12px;
    border: none;
  }
  
  .finances-no-payments {
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(33, 33, 33, 0.40);
    background: #F7FAFF;
    border-radius: 12px;
    font-size: 14px;
  }
  
  .finances-balance-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    background: #F7FAFF;
    border-radius: 12px;
    
    > div {
      color: #101010;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
    }
  }

  .more-info {
    padding-left: 16px;
    color: rgba(33, 33, 33, 0.50);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 4px;
    }
  }
`)
