import {
  type CoverageFeeSettingsDto,
  type DealFeeFieldSettingsModel,
  type DealFeesAndCoveragesSettingsModel,
  type DefaultAmountFeeSettingDto,
  type FinanceTypeAmountFeeSettingDto,
  type FinanceTypePercentFeeSettingDto
} from 'api/types/responses'

const parseDealFeesSettingsDto = (
  data: CoverageFeeSettingsDto
): DealFeesAndCoveragesSettingsModel => {
  return {
    fees: {
      buyerTagSetting: parseFeeSetting(data.buyerTagSetting),
      carDeliverySetting: parseFeeSetting(data.carDeliverySetting),
      dealerHandlingFeeSetting: parseFeeSetting(data.dealerHandlingFeeSetting),
      dealerInventoryTaxSetting: parseFeeSetting(data.dealerInventoryTaxSetting),
      emissionsSetting: parseFeeSetting(data.emissionsSetting),
      inspectionFeeSetting: parseFeeSetting(data.inspectionFeeSetting),
      plateTransferFeeSetting: parseFeeSetting(data.plateTransferFeeSetting),
      registrationFeeSetting: parseFeeSetting(data.registrationFeeSetting),
      titleFeeSetting: parseFeeSetting(data.titleFeeSetting)
    },
    coverages: {
      appearanceProtectionTaxable: data.appearanceProtectionTaxable,
      gapTaxable: data.gapTaxable,
      serviceContractTaxable: data.serviceContractTaxable,
      theftTaxable: data.theftTaxable,
      tireAndWheelTaxable: data.tireAndWheelTaxable
    },
    salesCommissions: {
      backGrossSalesCommissionSetting: { ...data.backGrossSalesCommissionSetting },
      frontGrossSalesCommissionSetting: { ...data.frontGrossSalesCommissionSetting }
    }
  }
}

const parseFeeSetting = (
  config: FinanceTypeAmountFeeSettingDto | FinanceTypePercentFeeSettingDto | DefaultAmountFeeSettingDto
): DealFeeFieldSettingsModel => {
  return {
    defaultCash: 'defaultCashPercent' in config
      ? config.defaultCashPercent ?? null
      : 'defaultCashAmount' in config
        ? config.defaultCashAmount ?? null
        : null,
    defaultFinancing: 'defaultFinancingPercent' in config
      ? config.defaultFinancingPercent ?? null
      : 'defaultFinancingAmount' in config
        ? config.defaultFinancingAmount ?? null
        : null,
    defaultValue: 'defaultAmount' in config ? config.defaultAmount : null,
    isEnabled: config.isEnabled,
    isPercent: 'defaultCashPercent' in config,
    isReadonly: config.isReadonly,
    isTaxable: config.isTaxable,
    labelName: config.labelName ?? ''
  }
}

export default parseDealFeesSettingsDto
