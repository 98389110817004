import { css } from '@emotion/css'

export default css(`
  .title-loader {
    margin: 24px 0 16px 0;
    
    &.is-loading {
      max-width: 200px;
    }
  }
  
  .section-title.h6-title.markups-title {
    margin-top: 24px;
    margin-bottom: 16px;
  }
  
  .title-filler {
    height: 64px;
    width: 100%;
  }
`)
