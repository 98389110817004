import { useCallback, useEffect } from 'react'

import { Notifications } from 'constants/names'
import { WebSocketsCrm as ws } from 'services/web_sockets'
import type {
  LeadUpdatedNotificationArgs,
  TasksDeletedNotificationArgs,
  TasksUpdatedNotificationArgs
} from 'api/types'

import type { RowId } from './types'

export interface UseWSUpdatesProps {
  onTasksDeleted?: (taskIds: RowId[]) => void
  onTasksUpdated?: () => Promise<void>
}

const useWSUpdates = ({
  onTasksDeleted: _onTasksDeleted,
  onTasksUpdated: _onTasksUpdated
}: UseWSUpdatesProps): void => {
  // ========================================== //
  //                   ACTIONS                  //
  // ========================================== //

  const leadUpdatedAction = useCallback((args: LeadUpdatedNotificationArgs) => {
    if (args.dealerId != null && args.leadId != null && args.hasTasks) {
      void _onTasksUpdated?.()
    }
  }
  , [_onTasksUpdated])

  const tasksUpdatedAction = useCallback((args: TasksUpdatedNotificationArgs) => {
    if (args.dealerId != null) {
      void _onTasksUpdated?.()
    }
  }
  , [_onTasksUpdated])

  const tasksDeletedAction = useCallback((args: TasksDeletedNotificationArgs) => {
    if (args.dealerId != null && args.taskIds != null) {
      _onTasksDeleted?.(args.taskIds)
    }
  }
  , [_onTasksDeleted])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //
  const throttledGroupId = 'tasksListUpdate'

  useEffect(() => {
    const onLeadUpdateHandler = { name: 'tasks_leadUpdateAction', action: leadUpdatedAction }

    ws.on(Notifications.LeadUpdated, onLeadUpdateHandler, { throttledGroupId })

    return () => {
      ws.off(Notifications.LeadUpdated, onLeadUpdateHandler.name)
    }
  }, [leadUpdatedAction])

  useEffect(() => {
    const onTaskCreateHandler = { name: 'tasks_taskCreateAction', action: tasksUpdatedAction }
    const onTasksUpdateHandler = { name: 'tasks_tasksUpdateAction', action: tasksUpdatedAction }

    ws.on(Notifications.TaskCreated, onTaskCreateHandler, { throttledGroupId })
    ws.on(Notifications.TasksUpdated, onTasksUpdateHandler, { throttledGroupId })

    return () => {
      ws.off(Notifications.TaskCreated, onTaskCreateHandler.name)
      ws.off(Notifications.TasksUpdated, onTasksUpdateHandler.name)
    }
  }, [tasksUpdatedAction])

  useEffect(() => {
    const onTasksDeleteHandler = { name: 'tasks_tasksDeleteAction', action: tasksDeletedAction }

    ws.on(Notifications.TasksDeleted, onTasksDeleteHandler)

    return () => {
      ws.off(Notifications.TasksDeleted, onTasksDeleteHandler.name)
    }
  }, [tasksDeletedAction])
}

export default useWSUpdates
