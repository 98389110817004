import type { FC } from 'react'
import { Button, cnx, parsers } from '@carfluent/common'
import { useNavigate, useParams } from 'react-router-dom'

import HTMLContent from 'components/common/HTMLContent'
import Avatar from 'components/common/NameWithAvatar'

import { ReconLogTypeEnum, ReconVehicleComment } from 'api/types'
import comment_icon from 'assets/recon_comment.svg'
import inspection_submitted from 'assets/inspection_submitted.svg'
import { formatDate } from 'utils/parse_date'

import CLASS_NAME from './styles'

const { parseDateStringUTC } = parsers

const DEFAULT_FORMAT = 'MM/dd/yy hh:mm aa'

const Comment: FC<ReconVehicleComment> = ({
  userName,
  createdDate,
  logTypeId,
  body,
  logData
}) => {
  const parsedDate = parseDateStringUTC(createdDate)
  const date = parsedDate != null ? formatDate(parsedDate, DEFAULT_FORMAT) : '-'
  const navigate = useNavigate()
  const { id: vehicleId = '' } = useParams()

  if (logTypeId === ReconLogTypeEnum.ChangedStep) {
    return (
      <div className={CLASS_NAME}>
        <div className='timeline-item-header'>
          <p className='timeline-item-title changed-step'>
            Moved to <span>&nbsp;{body}&nbsp;</span> by <span>&nbsp;{userName}&nbsp;</span>
          </p>

          <p className='timeline-item-date'>
            {date}
          </p>
        </div>
      </div>
    )
  }

  if (logTypeId === ReconLogTypeEnum.SubmitInspection) {
    return (
      <div className={cnx(CLASS_NAME, 'inspection-container')}>
        <div className='timeline-item-header'>
          <div className='timeline-item-title'>
            <img src={inspection_submitted} alt='' />
            Intake inspection submitted
          </div>

          <p className='timeline-item-date'>
            {date}
          </p>
        </div>

        <div className='inspection-content'>
          <Avatar showAvatar name={userName} />
          <p>Passed: <span>{logData.passedCount}</span></p>
          <p>Failed: <span>{logData.failedCount}</span></p>
          <p>Comments: <span>{logData.commentsCount}</span></p>

          <div className='show-btn-wrapper'>
            <Button
              variant='text'
              onClick={() => navigate(`/recon/${vehicleId}/intake-inspection`)}
            >
              Show intake form
            </Button>
          </div>
        </div>
      </div>
    )
  }

  if (logTypeId === ReconLogTypeEnum.UnlockInspection) {
    return (
      <div className={cnx(CLASS_NAME, 'unlock-inspection-record')}>
        <div className='timeline-item-header'>
          <div className='timeline-item-title'>
            Unlocked &nbsp;
            <span>Intake Inspection by {userName}</span>
          </div>

          <p className='timeline-item-date'>
            {date}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className={CLASS_NAME}>
      <div className='timeline-item-header'>
        <div className='timeline-item-title'>
          <img src={comment_icon} alt='' />
          <Avatar showAvatar name={userName} />
        </div>

        <p className='timeline-item-date'>
          {date}
        </p>
      </div>

      <div className='comment-content'>
        <p>Comment:</p>

        <HTMLContent
          isShouldConvertWithLink
          text={body}
        />
      </div>
    </div>
  )
}

export default Comment
