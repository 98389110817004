import { useCallback, useEffect, useState } from 'react'

import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { PercentOrAmountModes } from 'types'

import {
  type UseCalculatedOrManualFormProps,
  type UseCalculatedOrManualFormReturn
} from './types'

const usePercentOrAmountForm = ({
  fieldPathManual,
  isOverriden,
  isOpen,
  onChange,
  onClose,
  onSubmit: _onSubmit,
  values: _valueManual
}: UseCalculatedOrManualFormProps): UseCalculatedOrManualFormReturn => {
  const { showAlert } = useCustomSnackbar()
  const initialMode = isOverriden ? PercentOrAmountModes.Manual : PercentOrAmountModes.Auto

  const [valueManual, setValueManual] =
    useState<number | null>(null)

  const [formMode, setFormMode] =
    useState<string>(initialMode)

  const [isCalculatedValueHidden, setIsCalculatedValueHidden] =
    useState<boolean>(false)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onChangeManualAmount = useCallback((_, value): void => {
    setValueManual(value)
  }, [])

  const onChangeFormMode = useCallback((mode: string): void => {
    if ((initialMode === PercentOrAmountModes.Manual) && (mode === PercentOrAmountModes.Auto)) {
      setIsCalculatedValueHidden(true)
    }

    setFormMode(mode)
  }, [initialMode])

  const onSubmit = (): void => {
    const doJob = async (): Promise<void> => {
      try {
        const nextManualAmount = formMode === PercentOrAmountModes.Auto
          ? null
          : valueManual

        await _onSubmit(nextManualAmount)
        onClose()
        onChange(fieldPathManual, nextManualAmount)

        setTimeout(() => {
          setIsCalculatedValueHidden(false)
        }, 300) // timeout is needed to not show value until popup is closed
      } catch (err) {
        showAlert(err)
      }
    }

    void doJob()
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Prefill/reset data for inputs
   */
  useEffect(() => {
    setValueManual(isOpen ? _valueManual : null)
  }, [isOpen, _valueManual])

  // ========================================== //

  return {
    formMode,
    isCalculatedValueHidden,
    onChangeManualAmount,
    onChangeFormMode,
    onSubmit,
    valueManual
  }
}

export default usePercentOrAmountForm
