import { type CobuyerModel } from 'api/types/responses'
import { type CoApplicantFinancialDetails } from 'api/types'
import { toDateOrNull } from 'utils/parse_date'
import { GET_DEFAULT_COBUYER_MODEL } from 'api/defaults/deals'

/**
 * DD-NOTE: can not parse @sameAddressAsMainApplicant .
 * Please use extra parser to compare CobuyerModel currentAddress and personalDetails address.
 */
const parseCobuyerDto = (cobuyerDetails: CoApplicantFinancialDetails | null): CobuyerModel => {
  if (cobuyerDetails == null) {
    return GET_DEFAULT_COBUYER_MODEL()
  }

  return {
    id: cobuyerDetails.id,
    coApplicantType: cobuyerDetails.coApplicantType,
    firstName: cobuyerDetails.firstName ?? '',
    lastName: cobuyerDetails.lastName ?? '',
    email: cobuyerDetails.email ?? '',
    phoneNumber: cobuyerDetails.phoneNumber ?? '',
    birthDate: toDateOrNull(cobuyerDetails.birthDate),
    socialSecurityNumber: cobuyerDetails.socialSecurityNumber,

    currentAddressDetails: {
      id: cobuyerDetails.currentAddressDetails?.id ?? 0,
      address: cobuyerDetails.currentAddressDetails?.address ?? '',
      secondaryAddress: cobuyerDetails.currentAddressDetails?.secondaryAddress ?? null,
      city: cobuyerDetails.currentAddressDetails?.city ?? '',
      state: cobuyerDetails.currentAddressDetails?.state ?? '',
      zipCode: cobuyerDetails.currentAddressDetails?.zipCode ?? '',
      county: cobuyerDetails.currentAddressDetails?.county ?? null,
      apt: cobuyerDetails.currentAddressDetails?.apt ?? null
    },

    sameAddressAsMainApplicant: false
  }
}

export default parseCobuyerDto
