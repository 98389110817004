import { ChangeEvent, useCallback, useState } from 'react'

import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { VehiclesApiProvider } from 'api/vehicles.api'
import { ImportCSVModalProps, UseImportCSVReturn } from './types'
import { isCancelled } from 'api/wrapper.api'
import {
  ALLOWED_TYPES,
  ALLOWED_BYTES,
  SUCCESS_IMPORT_FILE,
  IMPORT_CANCELLATION_KEY,
  Errors
} from './constants'

export const useImportCSV = ({
  toggleModal,
  onSubmit
}: ImportCSVModalProps): UseImportCSVReturn => {
  const [loader, setLoader] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [error, setError] = useState<string | null>(null)
  const { showAlert } = useCustomSnackbar()

  const deleteFile = useCallback(() => setFile(null), [])
  const handleClose = useCallback(() => {
    VehiclesApiProvider.cancelRequest(IMPORT_CANCELLATION_KEY)
    toggleModal(false)
    deleteFile()
    setError(null)
    setLoader(false)
  }, [])

  const handleChangeFile = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    const file = target.files?.[0]

    if (file == null) {
      setFile(null)
      setError(Errors.UploadFailed)

      return
    }

    const isAllowedSize = file.size <= ALLOWED_BYTES

    if (!isAllowedSize) {
      setFile(null)
      setError(Errors.FileSize)

      return
    }

    const isAllowedType = ALLOWED_TYPES.includes(file.type)

    if (!isAllowedType) {
      setFile(null)
      setError(Errors.FileType)

      return
    }

    setError(null)
    setFile(file)
  }, [file])

  const handleSubmit = useCallback(async () => {
    if (file != null) {
      setLoader(true)
      try {
        await VehiclesApiProvider.importFile(file, IMPORT_CANCELLATION_KEY)
        showAlert(SUCCESS_IMPORT_FILE, { variant: 'success' })
        handleClose()
        onSubmit?.()
      } catch (e) {
        if (!isCancelled(e)) {
          setError(Errors.UploadFailed)
        }
      } finally {
        setFile(null)
        setLoader(false)
      }
    }
  }, [file])

  const downloadTemplate = useCallback(async () => {
    try {
      const content = await VehiclesApiProvider.downloadTemplate()
      const url = window.URL.createObjectURL(new Blob([content]))
      const link = document.createElement('a')
      link.setAttribute('download', 'template.csv')
      link.href = url
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch {
      showAlert(Errors.TemplateDownloadFailed)
    }
  }, [])

  return {
    file,
    error,
    disabledSubmit: file === null,
    deleteFile,
    handleChangeFile,
    handleSubmit,
    handleClose,
    downloadTemplate,
    loader
  }
}
