import { css } from '@emotion/css'

export default css(`  
  .table-wrapper {
    padding: 8px;
    background:  #F3F3F3;
    margin-bottom: 16px;
    border-radius: 4px;
    overflow: hidden;
  
    .MuiPaper-root {
      border-radius: 0;
      
      table {
        th {
          :last-child {
            div {
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
          }
        }
        thead {
          tr {
            border-top: none;
            th {
              :first-child {
                p {
                  font-size: 14px;
                  line-height: 16px;
                  font-weight: 500;
                  letter-spacing: 1.25px;
                  color: #101010;
                }
              }
  
              :last-child {
                p {
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 24px;
                  letter-spacing: 0.15px;
                  color: #101010;
                }
              }
            }
          }
        }
        tr {
          border-top: 4px solid #F3F3F3;

          &:hover{
            td {
              background-color: #fafafa;
            }
          }
  
          td {
            background-color: transparent;
            &:hover{
              background-color: #fafafa;
            }

            &.rule-column {
              & > div {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                text-align: right;
              }
            }

            &.workplan-event-column {
              div {
                max-width: 260px;

                @media only screen and (max-width: 1300px) {  {
                  max-width: 180px;
                  min-width: 180px;
                }
              }
            }
    
            :first-child {
              p {
                font-weight: 500;
              }

              div {
                align-items: flex-start;
                width: 405px;
                text-align: left;

                @media only screen and (max-width: 1300px) {  {
                  width: 375px;
                }
                span {
                  font-weight: 500;
                }
              }
            }

            &:last-child {
              & > div {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                text-align: right;
              }
            }
            
            > .isDisabled{
              color: rgba(33, 33, 33, 0.40);

              * > div p {
                color: rgba(33, 33, 33, 0.40);
              } 
            }

            > div {
              padding: 8px;
              height: 48px;
              display: flex;
              align-items: center;
              font-size: 14px;

              .cell-text {
                font-size: 14px;
                width: 100%;

                @media only screen and (max-width: 1300px) {  {
                  max-width: 180px;
                  min-width: 180px;
                }
              }
            }

            p {
              font-size: 14px;
            }
          }
          
          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          
          .day {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: rgba(33, 33, 33, 0.5);
          }
        }
        
        th {
          :nth-child(2n) {
            div {
              justify-content: flex-end;
              
              p {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
      
      .MuiTableCell-root {
        border-bottom: none;
      }
    }
  
    .cf-no-data-block {
      border-top: none;
      background: #F3F3F3;
      padding: 16px;
      
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: rgba(33, 33, 33, 0.40);
        letter-spacing: 0.4px;
      }
    }
  }
`)
