import { parsers } from '@carfluent/common'

import type { ReconImage, StepChange } from 'api/types/recon.types'
import type { ImageDescriptor } from 'components/inventory/ImageItem'
import { formatTimeIntervalFromNow, formatSecondsToInterval } from 'utils/recon/stepIntervalUtils'

import type { HistoryRow } from '../components/HistoryTable/columns'

const { parseDateStringUTC } = parsers

export const parseStepChanges = (data: StepChange[]): HistoryRow[] => {
  return data.map(({ createdDate, stepName, userName }, i) => {
    let formattedDate = '-'

    if (i === (data.length - 1)) {
      formattedDate = formatTimeIntervalFromNow(createdDate, true)
    } else {
      const currentDate = parseDateStringUTC(createdDate)
      const nextDate = parseDateStringUTC(data[i + 1].createdDate)

      if (nextDate != null && currentDate != null) {
        formattedDate = formatSecondsToInterval((nextDate.getTime() - currentDate.getTime()) / 1000, true)
      }
    }

    return {
      enteredOn: parseDateStringUTC(createdDate) ?? new Date(createdDate),
      step: stepName ?? '-',
      changedBy: userName ?? '-',
      timeInStep: formattedDate
    }
  })
}

export const parseFromReconImages = (images: ReconImage[]): ImageDescriptor[] =>
  images.map(({ order, ...otherProps }) => ({
    state: order,
    fileId: otherProps.id,
    ...otherProps
  }))
