import type {
  TrialBalanceReport as ApiData,
  TrialBalanceAccount as ApiAccount
} from 'api/types/accounting.types'
import type { TrialBalanceReport, TrialBalanceAccount } from './types'

const parseTrialBalance = (data: ApiData): TrialBalanceReport => {
  return {
    ...data,
    accounts: data.accounts.map(parseTrialAccount)
  }
}

const parseTrialAccount = (account: ApiAccount): TrialBalanceAccount => {
  return {
    ...account,
    debits: account.debits ?? 0,
    credits: account.credits ?? 0
  }
}

export default parseTrialBalance
