import { CoverageProductTypeIds } from 'api/types/responses'

export interface SupportedForms {
  [key: string]: string[]
}

const addAdditionalFields = (withAdditionalFields = false, fieldList: string[], addAdditionalFields?: string[]): string[] => {
  return fieldList.concat(withAdditionalFields ? ((addAdditionalFields ?? ['cost', 'totalPrice']) ?? []) : [])
}

const PRODUCTS_SUPPORTED_FIELDS = (withAdditionalFields = false): Record<CoverageProductTypeIds, SupportedForms> => ({
  [CoverageProductTypeIds.ServiceContract]: {
    PEN: addAdditionalFields(withAdditionalFields, ['providerId', 'coverageName', 'termMonths', 'deductibleAmount']),
    Manual: ['providerId', 'coverageName', 'termMonths', 'deductibleAmount', 'cost', 'totalPrice']
  },
  [CoverageProductTypeIds.GapInsurance]: {
    PEN: addAdditionalFields(withAdditionalFields, ['providerId', 'coverageName', 'termMonths']),
    Manual: ['providerId', 'coverageName', 'termMonths', 'cost', 'totalPrice']
  },
  [CoverageProductTypeIds.AppearanceProtection]: {
    PEN: addAdditionalFields(withAdditionalFields, ['providerId', 'coverageName']),
    Manual: ['providerId', 'coverageName', 'cost', 'totalPrice']
  },
  [CoverageProductTypeIds.TireAndWheel]: {
    PEN: addAdditionalFields(withAdditionalFields, ['providerId', 'coverageName', 'termMonths']),
    Manual: ['providerId', 'coverageName', 'termMonths', 'cost', 'totalPrice']
  },
  [CoverageProductTypeIds.Theft]: {
    PEN: addAdditionalFields(withAdditionalFields, ['providerId', 'coverageName', 'termMonths']),
    Manual: ['providerId', 'coverageName', 'termMonths', 'cost', 'totalPrice']
  }
})

const isFieldSupported = (productType?: CoverageProductTypeIds | null, coverageType?: string | null, field?: string | null, withAdditionalFields?: boolean): boolean => {
  return (productType != null) && (coverageType != null) && (field != null) && PRODUCTS_SUPPORTED_FIELDS(withAdditionalFields)?.[productType]?.[coverageType]?.includes(field)
}

export default isFieldSupported
