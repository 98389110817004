import { css } from '@emotion/css'

export const createStyleClass = (): string => {
  return css(`
    & {
      padding: 8px;
      cursor: pointer;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: rgba(33, 33, 33, 0.8);

      .report-link-title {
        color: #101010;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        line-height: 24px;
        padding-bottom: 4px;
        letter-spacing: 0.4px;
      }

      .report-link-description {
        font-size: 14px;
        font-weight: 400;
        color: #212121CC;
        text-align: left;
        line-height: 20px;
        letter-spacing: 0.25px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  `)
}
