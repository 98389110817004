import { type Preset, UI, PRESET_LABELS, dates } from '@carfluent/common'
import fileNames from 'utils/reports/fileNames'
import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'

import type { PopoverPropsV2 } from '@carfluent/common'

export const POPOVER_OPTIONS: PopoverPropsV2['options'] = {
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 8]
      }
    }
  ]
}

export enum FilterItemNames {
  All = 'All',
  Active = 'Active',
  Inactive = 'Inactive'
}

export const EMPTY_FILTER_TITLE = 'Select'

export const DEFAULT_SORTING: UI.SortingInfo = { id: 'date', order: 'desc' }

export const API_CALL_DELAY_SEARCH = 3000

export const presetLabels = [
  PRESET_LABELS.TODAY,
  PRESET_LABELS.YESTERDAY,
  PRESET_LABELS.PRESET_END_OF_LAST_MONTH
]

export const getPresetToday = (): Preset => {
  const today = new Date()
  return ({ name: PRESET_LABELS.TODAY, startDate: today, endDate: today })
}

export const getPresets = (): Preset[] => {
  const today = new Date()
  const yesterday = addDays(today, -1)
  const prevMonthStart = addMonths(dates.getMonthStart(today), -1)
  const prevMonthEnd = dates.getMonthEnd(prevMonthStart)

  const presets = [
    getPresetToday(),
    { name: PRESET_LABELS.YESTERDAY, startDate: yesterday, endDate: yesterday },
    { name: PRESET_LABELS.PRESET_END_OF_LAST_MONTH, startDate: prevMonthEnd, endDate: prevMonthEnd }
  ]

  return presets.filter(period => presetLabels.includes(period.name as PRESET_LABELS))
}

export const REPORT_FILE_NAMES = fileNames('InventoryCosts')
export const EMPTY_FORMAT_VALUE = { emptyValues: '-' }
export const ACCOUNTING_FORMAT_VALUE = { ...EMPTY_FORMAT_VALUE, allowNegative: true }
export const REQUEST_DELAY = 1000

export enum FilterItemIds {
  All = 0,
  Active,
  Inactive
}

export const ALL_SELECTED_ITEMS = [FilterItemIds.Active, FilterItemIds.Inactive]
