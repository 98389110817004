import { PAGE_OVERLAY_ID } from 'components/PageOverlay/constants'
import { linkRegex } from './regex_helper'

export const getHtmlLink = (link: string): string => `<a href="${link}" target="_blank">${link}</a>`
export const getLink = (link: string, text: string): string => `<a href="${link}" target="_self">${text}</a>`

// Split the string to lines by \n, \n\r, \\n, \\n\\r
export const getHtmlString = (value: string): string => value
  .replace(linkRegex, (link) => getHtmlLink(link))
  .replace(/\r|\\r/g, '')
  .replace(/\n|\\n/g, '<br />')

export const getPageOverlayContainer = (elementId: string = PAGE_OVERLAY_ID): HTMLDivElement =>
  document.getElementById(elementId) as HTMLDivElement
