import {
  EMPTY_FILTER_TITLE,
  ALL_SELECTED_ITEMS,
  FilterItemNames
} from './constants'

export const getFilterStatusTitle = (selectedItems: number[]): string => {
  if (selectedItems.length === 0) {
    return EMPTY_FILTER_TITLE
  }

  if (selectedItems.length === ALL_SELECTED_ITEMS.length) {
    return FilterItemNames.All
  }

  return `Status (${selectedItems.length === ALL_SELECTED_ITEMS.length ? 'All' : selectedItems.length})`
}
