import { css } from '@emotion/css'

export default css`
  width: min-content;

  .dropdown-button.cf-button-root.cf-button-variant-text {
    height: 40px;
    padding: 8px 8px 8px 16px;
    text-decoration: none;
    border-radius: 12px;
    background: #FAFAFA;
    box-sizing: border-box;
    
    .cf-button-content {
      font-weight: 500;
    }
    
    &.is-active {
      border: 1px solid #DDB3A0;
    }
    
    .cf-button-end-adornment {
      width: 24px;
      height: 24px;
    }
  }
`

export const POPOVER_CLASS_NAME = css`
  width: 400px;
  padding: 0!important;
  box-shadow: none!important;
`
