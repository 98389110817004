import { css } from '@emotion/css'

export const TOOLTIP_CONTENT_CLASSES = css(`
  z-index: 1500!important;
`)

export default css(`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  p {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
  }
  
  .img-wrapper {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    position: relative;
    
    img {
      width: 100%;
      height: 100%;
    }
  }

  .wrapper{
    display: flex;
    align-items: center;
  }

`)
