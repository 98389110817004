import { css } from '@emotion/css'

export default css(`
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.25px;

  &.FirstAlert {
    border-radius: 4px;
    text-align: center;
    padding: 0px 13px;
    line-height: 24px;
    background: #FC973B;
    color: #fff;
  }

  &.SecondAlert {
    border-radius: 4px;
    text-align: center;
    background: #D42A49;
    padding: 0px 13px;
    line-height: 20px;
    color: #fff;
  }
`)
