import { css } from '@emotion/css'

export default css(`
  .cf-inventory-sale-price {
    .cf-end-adornment {
      padding: 0;
      display: flex;
      height: 100%;
    }
  }
  
  .cf-clickable-input-adornment {
    color: inherit!important;
  }
  
  .reprice-reminder {
    bottom: -2px;
    transform: translate(0, 100%);
    left: 16px;
    position: absolute;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(33, 33, 33, 0.50);
  }
  
  .sale-price-wrapper {
    position: relative;
  }
`)

export const POPOVER_CLASS_NAME = css(`
  .MuiPaper-root {
    margin-top: 4px;
    border-radius: 8px;
    box-shadow: 0px 24px 38px 0px rgba(0, 0, 0, 0.14), 0px 2px 250px 0px rgba(0, 0, 0, 0.10);
  }
`)
