import { touchify, DeepRequired } from '@carfluent/common'
import type { AddNewVehicleFormData } from './types'

export const GET_DEFAULT_FORM_DATA = (): DeepRequired<AddNewVehicleFormData> => ({
  body: null,
  disabilityEquipped: null,
  doors: null,
  drivetrain: null,
  engineCylinders: null,
  engine: null,
  fuelType: null,
  genericColor: null,
  genericInterior: null,
  inventoryDate: new Date(),
  make: null,
  mileage: null,
  model: null,
  salePrice: 0,
  stock: null,
  transmission: null,
  trim: null,
  vin: null,
  year: null,
  source: '',
  purchasePrice: null,
  accountId: '',
  vendor: null,
  buyer: null
})

export const ALL_FORM_TOUCHED = touchify(GET_DEFAULT_FORM_DATA())

export enum Messages {
  SearchByVinFailed = 'Could not fetch car details by vin',
  VehicleAddSucceed = 'Vehicle added successfully',
  VehicleAddFailed = 'Could not create vehicle'
}
