import { css } from '@emotion/css'

export default css(`
  display: flex;
  align-itmes: center;
  flex-direction: column;

  .cf-grey-text {
    color: rgba(33, 33, 33, 0.8);
  }
`)
