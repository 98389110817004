import { useState } from 'react'
import { type ColumnDef, Button, CellWrapper } from '@carfluent/common'

import { type BankStatementTransactionMatchItem } from 'api/types'
import { ValueCellHOC } from 'components/common/Table/cells/ValueCell'
import { AmountCellHOC } from 'components/common/Table/cells/AmountCell'
import { DateCellHOC } from 'components/common/Table/cells/DateCell'
import { ControlIdentifierCellHOC } from 'components/common/Table/cells/IdentifierCell'
import { EntityWithAccountCellHOC } from 'components/common/Table/cells/EntityWithAccountCell'
import { LinksListHOC } from 'components/common/Table/cells/LinkButtonsListCell'
import { toDateOrNull } from 'utils/parse_date'
import getBankStatementTxClearMethodName from 'utils/accounting/getBankStatementTxClearMethodName'

import { type ColumnsConfig } from './types'

const getColumnDefinitions = ({
  mode,
  onClearMethodClick,
  onViewTransaction
}: ColumnsConfig): Array<ColumnDef<BankStatementTransactionMatchItem>> => {
  const isBS = mode === 'BS'

  return [
    {
      id: 'date',
      accessorFn: (row) => toDateOrNull(row.date),
      cell: DateCellHOC({ className: 'cell-date', format: 'MM/dd/yyyy' }),
      maxSize: 100,
      size: 100
    },
    {
      id: 'entityAccount',
      accessorFn: (row) => ({
        account: row.account,
        customer: row.customer,
        vendor: row.vendor
      }),
      cell: EntityWithAccountCellHOC({}),
      sortable: false,
      minSize: isBS ? 240 : 200
    },
    {
      id: 'transaction',
      accessorFn: (row: BankStatementTransactionMatchItem) => ([{
        id: row.id,
        onClick: () => onViewTransaction?.(row),
        title: 'View Transaction'
      }]),
      cell: LinksListHOC({ className: 'cf-transaction-cell' }),
      sortable: false,
      size: 140
    },
    {
      id: 'identifier',
      accessorFn: (row: BankStatementTransactionMatchItem) => row.controlEntity ?? row.deal?.vehicle,
      cell: ControlIdentifierCellHOC({ className: 'cell-responsive-text' }),
      sortable: false,
      minSize: isBS ? 210 : 200
    },
    {
      accessorKey: 'notes',
      cell: ValueCellHOC({ emptyValue: '', className: 'cell-responsive-text' }),
      size: isBS ? 210 : 280,
      sortable: false
    },
    {
      id: 'change',
      accessorFn: (row) => Math.abs(row.change),
      cell: AmountCellHOC({ isAlignedRight: true }),
      size: isBS ? 420 : 130,
      sortable: false
    },
    {
      id: 'action',
      accessorFn: () => null,
      cell: ({ row }) => {
        const methodTitle = getBankStatementTxClearMethodName(row.original.clearMethodId)?.toUpperCase()
        const [isLoading, setIsLoading] = useState(false)

        if (methodTitle == null) {
          return null
        }

        return (
          <CellWrapper>
            <Button
              className='g-button-concise button-action'
              color={isBS ? 'primary' : 'secondary'}
              isLoading={isLoading}
              onClick={async () => {
                setIsLoading(true)
                await onClearMethodClick(row.original)
                setIsLoading(false)
              }}
              variant={isBS ? 'contained' : 'outlined'}
            >
              {methodTitle}
            </Button>
          </CellWrapper>
        )
      },
      size: isBS ? 100 : 110,
      minSize: isBS ? 100 : 110,
      maxSize: isBS ? 100 : 110,
      sortable: false
    }
  ]
}

export default getColumnDefinitions
