export interface VehiclesByAgeDto {
  ageBucket: number
  count: number
}

export interface VehiclesDashboardDto {
  currentInventoryCount: number
  onDepositCount: number
  noPhotosCount: number
  totalCosts: number
  averageCosts: number
  averageAge: number
  vehiclesByAge: VehiclesByAgeDto [] | null
}

export enum BucketDays {
  Bucket0To15Days = 1,
  Bucket16To30Days = 2,
  Bucket31To45Days = 3,
  Bucket46To60Days = 4,
  Bucket61To90Days = 5,
  Bucket91To120Days = 6,
  Bucket121PlusDays = 7,
}
