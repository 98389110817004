import type { DraftDecorator, ContentBlock, ContentState } from 'draft-js'
import FeedbackLink from 'components/wysiwyg/editor/FeedbackLink'
import { isEntityFeedbackLink } from 'utils/wysiwyg'

export const findFeedbackLinkBlock = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
): void => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity()

      if (entityKey != null) {
        const entity = contentState.getEntity(entityKey)
        return isEntityFeedbackLink(entity)
      }
      return false
    },
    callback
  )
}

const feedbackLinkDecorator: DraftDecorator = {
  strategy: findFeedbackLinkBlock,
  component: FeedbackLink
}

export default feedbackLinkDecorator
