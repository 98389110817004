import { css } from '@emotion/css'

export const EMAIL_THREAD_MODAL_STYLES = css(`
  .cf-modal-content-scroll-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
      width: 0;
      background: transparent;
    }

    .cf-modal-content {
      padding: 16px 40px 0 40px;
    }
  }

  .cf-modal-title {
    color: #101010;
  }

  .cf-modal-content-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .cf-header-subject {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #101010;
  }

  .cf-header-email {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(33, 33, 33, 0.5);
  }

  .cf-modal-content-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .cf-modal-content-footer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`)

export const REPLY_BTN_STYLES = css(`
  &.cf-button-root {
    margin-top: 16px;
  }

  .cf-button-content {
    text-transform: uppercase;
    letter-spacing: 1.25px;
    font-weight: 500; 
  }
`)

export const EMAIL_LIST_ITEM_WRAPPER = css(`
  display: flex;
  flex-direction: column;
  margin: 16px 0 0 0;
  width: 100%;
`)
