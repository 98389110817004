import type { DeepRequired } from '@carfluent/common'
import { emptyAddress } from 'utils/address'
import type { LienholderFormData } from './types'

export enum Messages {
  SuccessCreate = 'Lienholder was created successfully.',
  SuccessUpdate = 'Lienholder was updated successfully.',
  SuccessDelete = 'Lienholder was deleted successfully.',
  ErrorLoad = 'Failed to load Lienholder data.'
}

const DEFAULT_FORM_DATA: DeepRequired<LienholderFormData> = {
  id: null,
  name: null,
  phoneNumber: null,
  email: null,
  corpCode: null,
  address: { ...emptyAddress }
}

export const FIELD_TRANSFORMS = {
  email: (value: string) => value.trim()
}

export const getDefaultFormData = (): DeepRequired<LienholderFormData> => ({ ...DEFAULT_FORM_DATA })
