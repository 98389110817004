import { ColumnDef } from '@carfluent/common/dist/UI'

import type { ReconVehicleListItemResponseDto } from 'api/types'
import { formatTimeIntervalFromNow } from 'utils/recon/stepIntervalUtils'

import TextCellHOC from 'components/common/Table/cells/TextCell'

import ReconCarCell from './components/cells/ReconCarCell'
import ReconCarCommentsCell from './components/cells/ReconCarCommentsCell'
import ReconDaysInStatusCell from './components/cells/ReconDaysInStatusCell'
import ReconCarCellHeader from './components/cells/ReconCarCellHeader'

interface GetColumnDefinitionsProps {
  isFavoriteFilter: boolean
  refreshCommentTrigger: number
  onChangeFavoriteFilter: () => void
}

function getColumnDefinitions ({
  isFavoriteFilter,
  refreshCommentTrigger,
  onChangeFavoriteFilter
}: GetColumnDefinitionsProps): Array<ColumnDef<ReconVehicleListItemResponseDto>> {
  return [
    {
      accessorKey: 'make',
      cell: (props) => <ReconCarCell {...props} />,
      header: () => <ReconCarCellHeader onChange={onChangeFavoriteFilter} isFavorite={isFavoriteFilter} title='Car' />,
      sortable: true,
      loading: 'extremely_big_line',
      minSize: 250,
      maxSize: 250
    },
    {
      id: 'dis',
      cell: ReconDaysInStatusCell,
      accessorFn: (row) => ({
        value: formatTimeIntervalFromNow(row.stepDate),
        status: row.disState
      }),
      header: TextCellHOC({ text: 'DIS' }),
      sortable: true,
      minSize: 80,
      maxSize: 80
    },
    {
      id: 'dir',
      cell: ReconDaysInStatusCell,
      accessorFn: (row) => ({
        value: formatTimeIntervalFromNow(row.createdDate),
        status: row.dirState
      }),
      header: TextCellHOC({ text: 'DIR' }),
      sortable: true,
      minSize: 80,
      maxSize: 80
    },
    {
      id: 'dms',
      cell: ReconDaysInStatusCell,
      accessorFn: (row) => ({ value: formatTimeIntervalFromNow(row.inventoryDate) }),
      header: TextCellHOC({ text: 'DMS' }),
      sortable: true,
      minSize: 80,
      maxSize: 80
    },
    {
      id: 'comments',
      cell: (props) => (
        <ReconCarCommentsCell
          {...props}
          refreshCommentTrigger={refreshCommentTrigger}
        />
      ),
      header: TextCellHOC({ text: 'Comments' }),
      sortable: false,
      loading: 'two_lines_different_length',
      minSize: 452,
      maxSize: 640
    }
  ]
}

export default getColumnDefinitions
