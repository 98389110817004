import { css } from '@emotion/css'

export default css(`
  width: 100%;
  margin: auto;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 16px 16px 24px 16px;

  .header {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;

    .add-form-btn {
      width: 115px;
      padding: 5px 18px;
      border-radius: 8px;
      text-decoration: none;
      border: 1px solid #C99B86;

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1.25px;
      }
    }

    h1 {
      .tooltip-content {
        color: #101010;
        max-width: 100%;
        font-size: 24px;
        min-height: 26px;
        line-height: 24px;
        margin-bottom: 8px;
        letter-spacing: 0.18px;
      }
    }
  
    p {
      font-size: 16px;
      min-height: 24px;
      color: #212121CC;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }

  .header-titles {
    width: calc(100% - 131px);

    .cf-tooltip-container .tooltip-content {
      margin-bottom: 0px;
    }

    > p {
      margin-top: 8px;
    }
  }

  .form-grid {
    display: grid;
    grid-gap: 16px;
    align-items: center;
    grid-template-columns: 1fr 3fr;

    > div:first-of-type {
      width: 400px
    }
  }
`)
