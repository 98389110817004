import { formatCurrencyDecimal } from '@carfluent/common'

const formatDealRecapValue = (
  value: string | number | null | undefined,
  emptyValues = '$-'
): string => {
  return formatCurrencyDecimal(value, { emptyValues, allowNegative: true })
}

export default formatDealRecapValue
