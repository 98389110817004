import { css } from '@emotion/css'

export const CELL_CLASS_NAME = css(`
  display: flex;
  align-items: center;
  
  .cf-form-field {
    width: 100%;
  }
  
  .cf-dropdown-root {
    height: 50px;
    max-height: 50px;  
  }
  
  .cf-dropdown-clear-button {
    min-width: 24px;
    min-height: 24px;
    svg { display: none; }
  }
  
  .cf-dropdown-trigger-button {
    min-width: 24px;
    min-height: 24px;
    svg { display: none; }
  }
  
  .cf-dropdown-root:hover {
    .cf-dropdown-clear-button svg { display: block; }
    .cf-dropdown-trigger-button svg { display: block; }
  }
  
  .cf-input-container {
    background-color: #FFF;
    max-height: 50px;
    
    input {
      padding: 14px 0 10px 0;
    }
    
    .cf-input-adornment {
      padding: 14px 0 2px 0;
    }
  }
`)

export const POPOVER_CLASS_NAME = css(`
  .cf-dropdown-listbox { min-width: 300px;  }
  
  &.extended-width {
    .cf-dropdown-listbox { min-width: 320px; }
  }
  
  .cf-option-disabled {
    .cf-dropdown-option {
      opacity: 1;
    }
    
    .control-option {
      .cf-option-name {
        color: rgba(33, 33, 33, 0.40);
      }
      
      .status {
        color: rgba(33, 33, 33, 0.40);
      }
      
      .name {
        color: rgba(33, 33, 33, 0.50);
      }
    }
  }
`)
