import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, isOk, type UseFormReturn, type FormValidation } from '@carfluent/common'
import axios from 'axios'

import { type PickedFormDataProps } from 'types'
import IdentityApiProvider from 'api/identity.api'
import { Routes } from 'constants/route_helper'
import { emailField } from 'utils/validationPresets'

export interface ResetPasswordForm {
  email: string
}

export interface UseResetPasswordReturn extends Pick<UseFormReturn<ResetPasswordForm>, PickedFormDataProps | 'onSubmit' | 'isSubmitting'> {}

const baseValues = {
  email: ''
}

const validationRules: FormValidation<ResetPasswordForm> = {
  email: emailField(true)
}

const useResetPassword = (): UseResetPasswordReturn => {
  const navigate = useNavigate()

  const onActionResult = useCallback((res) => {
    if (isOk(res)) {
      navigate(`${Routes.Reset}/sent`)
    } else if (axios.isAxiosError(res.result)) {
      setFieldError('email', res.result?.response?.data.message ?? null)
    }
  }, [navigate])

  const submitAction = useCallback(async (values: ResetPasswordForm): Promise<void> => {
    await IdentityApiProvider.sendResetPasswordEmail(values)
  }, [])

  const {
    isSubmitting,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onSubmit,
    setFieldError
  } = useForm<ResetPasswordForm>({
    baseValues,
    validationRules,
    submitAction,
    onActionResult
  })

  return {
    isSubmitting,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onSubmit
  }
}

export default useResetPassword
