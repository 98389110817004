import { type FC, ReactNode } from 'react'
import { cnx } from '@carfluent/common'
import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

export interface BlockSkeletonProps {
  className?: string
  children?: ReactNode | ReactNode[]
  isLoading?: boolean
}

const BlockSkeleton: FC<BlockSkeletonProps> = ({
  children,
  className,
  isLoading = false
}) => {
  return (
    <div className={cnx('block-skeleton', isLoading && 'is-loading', className)}>
      {isLoading && <TextSkeleton />}
      {!isLoading && children}
    </div>
  )
}

export default BlockSkeleton
