import { type FC } from 'react'
import { Modal, cnx } from '@carfluent/common'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'

import ProfileForm from './components/ProfileForm'
import useProfile from './hook'
import { LOGO_UPDATED_MODAL_CLASS_NAME, CLASS_NAME } from './styles'
import UnsavedChangesDialog from 'components/dialogs/UnsavedChanges'

const SUBMIT_BTN_TEXT = 'Continue'
const MODAL_MIN_WIDTH = 600

const Profile: FC = () => {
  const {
    isOpenUnsavedChanges,
    termsUrl,
    logoPreviewUrl,
    logoUrl,
    isSubmitting,
    values,
    errors,
    touched,
    isLoading,
    hasChanges,
    isLogoUpdatedModalOpen,
    onBlur,
    onChange,
    onSubmit,
    onCloseUnsavedDialog,
    onDontSaveAndClose,
    onSaveAndClose,
    setLogoAndPreviewUrl,
    onCancelEdit,
    onCloseLogoUpdatedModal,
    onModalContinue
  } = useProfile()

  const renderFooter = (): JSX.Element => (
    <CancelSubmitActionsFooter
      onSubmit={onModalContinue}
      onClose={onCloseLogoUpdatedModal}
      submitTitle={SUBMIT_BTN_TEXT}
    />
  )

  return (
    <div className={cnx(CLASS_NAME, 'g-content-layout', 'g-margin-top-big')}>
      <div className={cnx('g-single-form-content')}>
        <ProfileForm
          termsUrl={termsUrl}
          logoPreviewUrl={logoPreviewUrl}
          logoUrl={logoUrl}
          isLoading={isLoading}
          setLogoAndPreviewUrl={setLogoAndPreviewUrl}
          values={values}
          errors={errors}
          touched={touched}
          onBlur={onBlur}
          onChange={onChange}
        />
      </div>

      <Modal
        title='Logo updated'
        isOpen={isLogoUpdatedModalOpen}
        onClose={onCloseLogoUpdatedModal}
        renderFooterActions={renderFooter}
        minWidth={MODAL_MIN_WIDTH}
        className={LOGO_UPDATED_MODAL_CLASS_NAME}
      >
        <p>Please note, logo will be changed for all locations.</p>
      </Modal>

      <div className={cnx('tab-footer', hasChanges && 'is-shown')} role='footer'>
        <CancelSubmitActionsFooter
          isLoading={isSubmitting}
          onSubmit={onSubmit}
          onClose={onCancelEdit}
          cancelTitle='DISCARD'
          submitTitle='SAVE'
        />
      </div>

      <UnsavedChangesDialog
        content='Your changes will be lost if you dont save them.'
        isOpen={isOpenUnsavedChanges}
        onCloseDialog={onCloseUnsavedDialog}
        onDontSaveAndClose={onDontSaveAndClose}
        onSaveAndClose={onSaveAndClose}
        isSubmitting={isSubmitting}
      />
    </div>
  )
}

export default Profile
