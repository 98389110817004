import { css } from '@emotion/css'

export default css(`

  &.cf-cell-wrapper {
    .cf-empty-value {
      color: rgba(33, 33, 33, 0.5);
    }
  }

  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &, &:hover {
      text-decoration: none;
    }
  
    .cf-item-link-content p, .cf-text-with-label {
      color: #458FFF;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .cf-stock-number {
        font-size: 16px;
        line-height: 16px;
        padding: 2px 0;
        color: #458FFF;
      }
    }
  }

  .cf-text-with-label {
    display: flex;
    align-items: center;
    height: 24px;
    margin-top: 2px;
    
    .cf-hidden-items-label {
      display: inline-block;
      margin-left: 8px;
      background: transparent;
      color: inherit;
      border: 1px solid #458FFF;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .cf-counter {
    margin-left: 8px;
  }
`)
