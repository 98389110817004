import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import ConfirmCashPayment from 'components/deals/ConfirmCashPayment'
import ActionsFooterAndUnsavedChanges from 'pages/deals/DealDetails/components/ActionsFooterAndUnsavedChanges'
import isDealRecorded from 'utils/deals/isDealRecorded'
import { DealStates } from 'api/types'

import DealRecapSection from './components/DealRecapSection'
import DealPaymentsSection from './components/DealPaymentsSection'
import FinancingFeesForm from './components/FinancingFeesForm'
import useDealFinances from './hook'
import { type UseDealFinancesProps } from './hook/types'
import CLASS_NAME from './styles'

export interface DealFinancesProps extends UseDealFinancesProps {
  className?: string
  isShowCarDelivery: boolean
  onResetTabNavigation: () => void
}

const DealFinances: FC<DealFinancesProps> = ({
  className,
  isShowCarDelivery,
  onResetTabNavigation,
  ...hookProps
}) => {
  const {
    amount,
    dealFinances,
    formProps,
    isDealRecapDataLoading,
    isFinancing,
    modalCashPaymentProps,
    modalUnsavedChangesProps,
    payments,
    shouldBeConfirmed,
    totalAmount
  } = useDealFinances(hookProps)

  const {
    isModalOpen: isUnsavedChangesShown,
    onCloseModal: onCloseUnsavedChanges,
    onOpenModal: onOpenUnsavedChanges
  } = modalUnsavedChangesProps

  const {
    onChange: onChangeConfirmAmount,
    onCloseModal: onCloseConfirmCashPayment,
    onOpenModal: onOpenConfirmCashPayment,
    onSubmit: onConfirmCashPayment
  } = modalCashPaymentProps

  // ========================================== //

  return (
    <>
      <div className={cnx(CLASS_NAME, className)}>
        <DealRecapSection
          acquisitionFee={formProps.values.acquisitionFee}
          className='finances-left-side'
          dealRecapData={dealFinances ?? null}
          isLoading={isDealRecapDataLoading}
          isShowCarDelivery={isShowCarDelivery}
        />

        <div className='finances-right-side'>
          {isFinancing && (
            <FinancingFeesForm
              {...formProps}
              isDisabled={isDealRecorded(hookProps.deal)}
              isLoading={isDealRecapDataLoading}
              isSubmitting={formProps.isSubmitting}
            />
          )}

          <DealPaymentsSection
            customerBalance={hookProps.deal?.accountingDetails.customerBalance ?? null}
            isFinancing={isFinancing}
            lienholderBalance={hookProps.deal?.accountingDetails.lienholderBalance ?? null}
            onOpenConfirmCashPayment={onOpenConfirmCashPayment}
            payments={payments}
            shouldBeConfirmed={shouldBeConfirmed}
            totalAmount={totalAmount}
            isFullyPaid={hookProps.deal?.accountingDetails.dealPaidStatusId === DealStates.FullyPaid ?? false}
          />
        </div>
      </div>

      <ConfirmCashPayment
        amount={amount}
        isConfirmOpen={modalCashPaymentProps.isModalOpen}
        isDisabledConfirm={modalCashPaymentProps.isDisabled}
        isSubmitting={modalCashPaymentProps.isSubmitting}
        onChangeConfirmAmount={onChangeConfirmAmount}
        onClose={onCloseConfirmCashPayment}
        onConfirmPayment={onConfirmCashPayment}
        shouldBeConfirmed={shouldBeConfirmed}
        payments={payments}
        totalAmount={totalAmount}
      />

      <ActionsFooterAndUnsavedChanges
        isActionsFooterShown={formProps.hasChanges}
        isSubmitting={formProps.isSubmitting}
        isUnsavedChangesShown={isUnsavedChangesShown}
        onCancel={() => { formProps.resetForm() }}
        onClose={onCloseUnsavedChanges}
        onDontSaveAndClose={hookProps.onTrySwitchTab}
        onOpen={onOpenUnsavedChanges}
        onSubmit={formProps.onSubmit}
        onResetTabNavigation={onResetTabNavigation}
        shouldBlock={formProps.hasChanges}
      />
    </>
  )
}

export default DealFinances
