import { type FC } from 'react'
import { type IconProps } from 'types'

const IconSvgCrown: FC<IconProps> = ({ width = 24, height = 24, color = '#fff', opacity = '0.8' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g opacity={opacity}>
        <path
          d='M19.6416 18H4.35839C4.15441 18 3.98311 17.8465 3.96079 17.6438L3.08479 9.6847C3.04925 9.36172 3.39323 9.13337 3.67707 9.29151L7.68502 11.5245C7.86401 11.6242 8.08942 11.5726 8.20712 11.4049L11.6726 6.46658C11.8319 6.2396 12.1681 6.2396 12.3274 6.46658L15.7929 11.4049C15.9106 11.5726 16.136 11.6242 16.315 11.5245L20.3229 9.29151C20.6068 9.13337 20.9508 9.36172 20.9152 9.6847L20.0392 17.6438C20.0169 17.8465 19.8456 18 19.6416 18Z'
          fill={color}
        />
        <circle cx='21' cy='7.5' r='1.5' fill={color} />
        <circle cx='3' cy='7.5' r='1.5' fill={color} />
        <circle cx='12' cy='4.5' r='1.5' fill={color} />
        <rect x='3.75' y='19.5' width='16.5' height='1.5' rx='0.75' fill={color} />
      </g>
    </svg>
  )
}

export default IconSvgCrown
