import { type FC, useState } from 'react'

import { type DealRecapDto } from 'api/types/responses'
import Tab from 'components/common/Tabs/components/Tabs/Tab'
import DealFinances, { type DealFinancesProps } from './DealFinances'

export type OwnTabDealFinancesProps =
  | 'cachedDealFinances'
  | 'saveLoadedFinancesToCache'

export interface TabDealFinancesProps extends Omit<DealFinancesProps, OwnTabDealFinancesProps> {
  isSelected: boolean
}

const TabDealFinances: FC<TabDealFinancesProps> = ({
  isSelected,
  ...props
}) => {
  const [cachedDealFinances, setCachedDealFinances] =
    useState<DealRecapDto | null>(null)

  return (
    <Tab isSelected={isSelected}>
      <DealFinances
        {...props}
        cachedDealFinances={cachedDealFinances}
        saveLoadedFinancesToCache={setCachedDealFinances}
      />
    </Tab>
  )
}

export default TabDealFinances
