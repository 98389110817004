import type { FC } from 'react'
import Typography from '@material-ui/core/Typography'

import { UI } from '@carfluent/common'

const { CellWrapper } = UI

export interface TextCellProps {
  text: string
}

const TextCellHOC = (text: string) => () => <TextCell text={text} />

const TextCell: FC<TextCellProps> = (props) => {
  const { text } = props

  return (
    <CellWrapper>
      <Typography>{text}</Typography>
    </CellWrapper>
  )
}

export default TextCellHOC
