import type { FC } from 'react'
import { Button } from '@carfluent/common'
import CLASS_NAME from './styles'

interface ActionBarProps {
  disabled?: boolean
  onRemove: () => void
  onSubmit: () => void
}

const ActionBar: FC<ActionBarProps> = ({ disabled = false, onRemove, onSubmit }) => (
  <div className={CLASS_NAME}>
    <Button
      variant='text'
      className='cf-remove-button'
      onClick={onRemove}
    >
      Remove filters
    </Button>

    <Button
      className='cf-result-button'
      onClick={onSubmit}
      isDisabled={disabled}
    >
      SHOW RESULTS
    </Button>
  </div>
)

export default ActionBar
