import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import Divider from 'components/common/Divider'

import ActionsFooterAndUnsavedChanges from '../ActionsFooterAndUnsavedChanges'
import DealSection from './components/DealSection'
import FinancingSection from './components/FinancingSection'
import FinanceInsuranceSection from './components/FinanceInsuranceSection'
import SummarySection from './components/SummarySection'
import FeesSection from './components/FeesSection'
import FeesSectionSkeleton from './components/FeesSectionSkeleton'
import DeliverySection from './components/DeliverySection'

import { type DealFormProps } from './types'
import useTabDeal from './hook'
import CLASS_NAME from './styles'

const DealForm: FC<DealFormProps> = ({
  className,
  isLoading,
  onResetTabNavigation,
  salesCommission,
  ...hookProps
}) => {
  const {
    isFinancing,
    isManualSalesTax,
    isRecorded,
    isWholesale,
    modalUnsavedChangesProps,
    onOpenWarrantiesDialog,
    onRefreshDeal,
    onResetForm,
    onSubmitForm,
    onSubmitInventoryTax,
    onSubmitSalesCommission,
    onSubmitSalesTax,
    onUpdateNotes,
    onViewBillOfSale,
    ...formProps
  } = useTabDeal(hookProps)

  const { dealId, onTrySwitchTab } = hookProps

  const {
    isModalOpen: isUnsavedChangesShown,
    onCloseModal: onCloseUnsavedChanges,
    onOpenModal: onOpenUnsavedChanges
  } = modalUnsavedChangesProps

  // ========================================== //

  return (
    <>
      <div className={cnx(CLASS_NAME, className)}>
        <DealSection
          {...formProps}
          className='cell-1-1 fields-column'
          dealId={dealId}
          feesAndCoveragesSettings={hookProps.feesAndCoveragesSettings}
          isFinancing={isFinancing}
          isLoading={isLoading}
          isRecorded={isRecorded}
          isWholesale={isWholesale}
        />

        {(isLoading || isFinancing) && (
          <FinancingSection
            {...formProps}
            className='cell-1-2 fields-column'
            isLoading={isLoading}
            isRecorded={isRecorded}
          />
        )}

        <FinanceInsuranceSection
          className={cnx('fields-column', (isLoading || isFinancing) ? 'cell-1-3' : 'cell-1-2')}
          coverageDetails={hookProps.deal?.coverageDetails ?? []}
          isLoading={isLoading}
          isRecorded={isRecorded}
          onOpenWarrantiesDialog={onOpenWarrantiesDialog}
        />

        <SummarySection
          {...formProps}
          className='cell-1-4 fields-column'
          deal={hookProps.deal}
          dealId={hookProps.dealId}
          dealerId={hookProps.dealerId}
          feesAndCoveragesSettings={hookProps.feesAndCoveragesSettings}
          isFinancing={isFinancing}
          isLoading={isLoading}
          onRefreshDeal={onRefreshDeal}
          onUpdateNotes={onUpdateNotes}
          onViewBillOfSale={onViewBillOfSale}
        />

        <div className='cell-2-1 divider'>
          <Divider />
        </div>

        {isLoading && (
          <FeesSectionSkeleton
            className='cell-3-1 fields-row'
          />
        )}

        {!isLoading && (
          <FeesSection
            {...formProps}
            className='cell-3-1 fields-row'
            coverageDetails={hookProps.deal?.coverageDetails ?? []}
            deal={hookProps.deal}
            feesAndCoveragesSettings={hookProps.feesAndCoveragesSettings}
            isLoading={isLoading}
            isManualSalesTax={isManualSalesTax}
            isRecorded={isRecorded}
            isWholesale={isWholesale}
            onSubmitInventoryTax={onSubmitInventoryTax}
            onSubmitSalesCommission={onSubmitSalesCommission}
            onSubmitSalesTax={onSubmitSalesTax}
            salesCommission={salesCommission}
          />
        )}

        <div className='cell-4-1 divider'>
          <Divider />
        </div>

        <DeliverySection
          {...formProps}
          className='cell-5-1 fields-row'
          isLoading={isLoading}
          isRecorded={isRecorded}
        />
      </div>

      <ActionsFooterAndUnsavedChanges
        isActionsFooterShown={formProps.hasChanges}
        isSubmitting={formProps.isSubmitting}
        isUnsavedChangesShown={isUnsavedChangesShown}
        onCancel={onResetForm}
        onClose={onCloseUnsavedChanges}
        onDontSaveAndClose={onTrySwitchTab}
        onOpen={onOpenUnsavedChanges}
        onSubmit={onSubmitForm}
        onResetTabNavigation={onResetTabNavigation}
        shouldBlock={formProps.hasChanges}
      />
    </>
  )
}

export default DealForm
