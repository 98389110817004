import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGCheck: FC<IconProps> = ({
  color = '#212121',
  width = 16,
  height = 16
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_77786_49454)'>
        <path
          d='M5.99975 10.7989L3.66642 8.46557C3.40642 8.20557 2.99309 8.20557 2.73309 8.46557C2.47309 8.72557 2.47309 9.13891 2.73309 9.39891L5.52642 12.1922C5.78642 12.4522 6.20642 12.4522 6.46642 12.1922L13.5331 5.13224C13.7931 4.87224 13.7931 4.45891 13.5331 4.19891C13.2731 3.93891 12.8598 3.93891 12.5998 4.19891L5.99975 10.7989Z'
          fill={color}
          fillOpacity={0.4}
        />
      </g>

      <defs>
        <clipPath id='clip0_77786_49454'>
          <rect width={width} height={height} fill='white'/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGCheck
