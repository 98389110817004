import { css } from '@emotion/css'

export default css(`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .cf-dropzone {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    width: 100%;
    height: 150px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    margin-bottom: 24px;
  }

  .cf-dropzone-error {
    border: 1px solid #B00020;
  }

  .cf-dropzone-drop-overlay {
    position: absolute;
    background: #C99B86;
    opacity: 0.1;
    border-radius: 8px;
    left: 4px;
    top: 4px;
    right: 4px;
    bottom: 4px;
  }

  .cf-dropzone-block-button {
    display: flex;
    align-items: center;
  }

  .cf-dropzone-error-btn-block {
    padding-bottom: 4px;
  }

  .cf-dropzone-btn-upload {
    padding: 6px 18px;
    border-radius: 8px;
    max-width: 132px;
    margin-left: 8px;
    height: 26px;
    white-space: nowrap;

    .cf-button-content {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
    }
  }

  .cf-dropzone-text {
    font-family: Roboto, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #101010;
    word-break: keep-all;
    white-space: nowrap;
    user-select: none;
  }

  .cf-dropzone-block-error {
    position: absolute;
    bottom: 4px;
  }

  .cf-dropzone-text-error {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #B00020;
  }

  .cf-dropzone-chip-root {
    background: #F3F3F3;
    border-radius: 8px;
  }

  .cf-dropzone-chip-label {
    max-width: 194;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20x;
    letter-spacing: 0.25px;
    color: #101010;
  }

  .cf-dropzone-chip-delete-icon {
    width: 11px;
    margin: 0 12px 0 1px;
  }
`)
