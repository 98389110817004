import type { FC } from 'react'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'

import CLASS_NAME from './styles'

interface FooterActionsProps {
  onClose: () => void
  onSubmit: () => void
  isSubmitting: boolean
  amountOfForms: number
}

const FooterActions: FC<FooterActionsProps> = ({ onClose, onSubmit, isSubmitting, amountOfForms }) => {
  return (
    <div className={CLASS_NAME}>
      <span className='selected-amount-of-forms'>
        {`${amountOfForms} form${amountOfForms === 1 ? '' : 's'} selected`}
      </span>

      <div className='actions-container'>
        <CancelSubmitActionsFooter
          onClose={onClose}
          submitTitle='ADD'
          onSubmit={onSubmit}
          isLoading={isSubmitting}
          isDisabled={amountOfForms === 0}
        />
      </div>
    </div>
  )
}

export default FooterActions
