import {
  type CampaignModel,
  type RecipientFilterValues,
  type LeadWithScheduledAppointmentsValuesData,
  SendScheduleType,
  RecipientsFilter,
  RecipientsFilterTitles
} from 'api/types/responses'

import { type DictionaryItem } from 'api/types'

export const GET_DEFAULT_CAMPAIGN_DETAILS = (data?: Partial<CampaignModel>): CampaignModel => ({
  id: data?.id ?? null,
  campaignStatusId: data?.campaignStatusId ?? 0,
  campaignTypeId: data?.campaignTypeId ?? 0,
  contentDetails: {
    name: data?.contentDetails?.name ?? null,
    senderName: data?.contentDetails?.senderName ?? null,
    senderEmail: data?.contentDetails?.senderEmail ?? null,
    emailSubject: data?.contentDetails?.emailSubject ?? null,
    preHeader: data?.contentDetails?.preHeader ?? null,
    body: data?.contentDetails?.body ?? null,
    bodyHtml: data?.contentDetails?.bodyHtml ?? null
  },
  createdByUser: data?.createdByUser ?? null,
  filters: {
    filterLeadSource: data?.filters?.filterLeadSource ?? null,
    filterLeadStatus: data?.filters?.filterLeadStatus ?? null,
    filterLeadTemperature: data?.filters?.filterLeadTemperature ?? null,
    filterLeadWithScheduledAppointments: data?.filters?.filterLeadWithScheduledAppointments ?? null,
    recipientsCounter: data?.filters?.recipientsCounter ?? null
  },
  recipientsCount: data?.recipientsCount ?? null,
  rowVersion: data?.rowVersion ?? '',
  schedule: {
    type: data?.schedule?.type ?? SendScheduleType.Now,
    date: data?.schedule?.date ?? (new Date()),
    time: data?.schedule?.time ?? null
  },
  statistics: {
    appointmentsCount: data?.statistics?.appointmentsCount ?? null,
    clickCount: data?.statistics?.clickCount ?? null,
    clickRate: data?.statistics?.clickRate ?? null,
    failedCount: data?.statistics?.failedCount ?? null,
    id: data?.statistics?.id ?? null,
    openCount: data?.statistics?.openCount ?? null,
    openRate: data?.statistics?.openRate ?? null,
    salesCount: data?.statistics?.salesCount ?? null,
    totalGrossAmount: data?.statistics?.totalGrossAmount ?? null,
    totalSentCount: data?.statistics?.totalSentCount ?? null,
    unsubscribedCount: data?.statistics?.unsubscribedCount ?? null
  }
})

export const INCLUDED_FILTER_OPTION = (): DictionaryItem => ({
  id: RecipientsFilter.Included,
  name: RecipientsFilterTitles.Include
})

export const EXCLUDED_FILTER_OPTION = (): DictionaryItem => ({
  id: RecipientsFilter.Excluded,
  name: RecipientsFilterTitles.Exclude
})

export const NONE_FILTER_OPTION = (): DictionaryItem => ({
  id: RecipientsFilter.None,
  name: RecipientsFilterTitles.NotSpecified
})

export const GET_DEFAULT_LEAD_STATUS_VALUES = (): RecipientFilterValues => ({
  filter: INCLUDED_FILTER_OPTION(),
  options: null
})

export const GET_DEFAULT_LEAD_SOURCE_VALUES = (): RecipientFilterValues => ({
  filter: INCLUDED_FILTER_OPTION(),
  options: null
})

export const GET_DEFAULT_LEAD_TEMPERATURE_VALUES = (): RecipientFilterValues => ({
  filter: INCLUDED_FILTER_OPTION(),
  options: null
})

export const GET_DEFAULT_LEAD_WITH_SCHEDULED_APPOINTMENTS_VALUES = (): LeadWithScheduledAppointmentsValuesData => ({
  filter: INCLUDED_FILTER_OPTION(),
  options: null
})
