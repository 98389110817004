/**
 * This hook is initially generated automatically according to the architectural approach of CarFluent:
 * - https://dev.azure.com/carfluent/CarFluent/_wiki/wikis/CarFluent.wiki/40/Page-level-Architecture
 * - https://dev.azure.com/carfluent/CarFluent/_wiki/wikis/CarFluent.wiki/74/Component-Structure
 *
 * Please read the documents mentioned above before changing the hook structure.
 */

import { type FocusEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm, getEnhancedResultHandler, useAutocompleteInfiniteList } from '@carfluent/common'
import { AutocompleteInputChangeReason } from '@material-ui/lab'

import type { EntityId } from 'types'
import type { AccountListItem, ListPayload, ListResponse } from 'api/types'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import AccountingApiProvider from 'api/accounting.api'
import { isTruthy } from 'utils/general'
import { PaymentSectionType } from 'pages/accounting/Settings/components/SettingsGeneral/components/PaymentSection/types'

import type { ErrTouchShortcuts } from '../components/VehiclePurchasePaymentsForm/types'
import type { UseVehiclePurchasePaymentsProps, UseVehiclePurchasePaymentsReturn, VehiclePurchasePaymentsFormData } from './types'
import { getDefaultFormData } from './constants'
import formValidation from './validation'
import parseFormData from './parser'
import serializeFormData from './serializer'

// DD-NOTE -> OP-TODO: it should be reworked to work with an array directly
const DEFAULT_FORM_DATA = getDefaultFormData()

const useVehiclePurchasePayments = ({
  isOpen,
  onClose,
  selectedAccounts,
  paymentSectionType,
  setSelectedAccounts
}: UseVehiclePurchasePaymentsProps): UseVehiclePurchasePaymentsReturn => {
  const { showAlert } = useCustomSnackbar()
  const [isLoading, setIsLoading] = useState(false)

  const refSearchField = useRef<null | Record<string, string>>(null)
  const refSearchForInput = useRef('')
  const refKeyForInput = useRef('')
  const getAccounts = useCallback(async (payload: ListPayload): Promise<ListResponse<AccountListItem>> => {
    try {
      const response = await AccountingApiProvider.getAccounts(payload)

      return { items: response.items }
    } catch (err) {
      return { items: [] }
    }
  }, [])

  const {
    getItems: loadItems,
    setSearch: setSearchForRequest,
    isLoading: isSearchLoading,
    items
  } = useAutocompleteInfiniteList({
    propIdName: 'vehiclePurchasePayments',
    fetch: getAccounts
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const submitAction = useCallback(async (values: VehiclePurchasePaymentsFormData): Promise<void> => {
    const dataToSave = serializeFormData(values) as { ids: EntityId[] }
    setIsLoading(true)
    const methodName = paymentSectionType === PaymentSectionType.VehiclePurchase
      ? 'updateVehiclePaymentAccountsList'
      : 'updateVehicleCostPaymentAccounts'
    const res = await AccountingApiProvider[methodName](dataToSave)
    setIsLoading(false)
    setSelectedAccounts(res.items)
  }, [setSelectedAccounts, paymentSectionType])

  const onActionResult = useMemo(() => {
    return getEnhancedResultHandler<VehiclePurchasePaymentsFormData, ErrTouchShortcuts>(onClose, showAlert)
  }, [showAlert, onClose])

  const form = useForm<VehiclePurchasePaymentsFormData, ErrTouchShortcuts>({
    baseValues: DEFAULT_FORM_DATA,
    validationRules: formValidation,
    submitAction,
    onActionResult
  })

  const { onBlur: _onBlur, setValues } = form

  const onSearch = useCallback((key?: string, val?: string): void => {
    if (key !== undefined && val !== undefined) {
      refSearchForInput.current = val
      refSearchField.current = { [key]: val }
      refKeyForInput.current = key
      setSearchForRequest(val)
    } else {
      refSearchForInput.current = ''
      refSearchField.current = null
      refKeyForInput.current = ''
      setSearchForRequest('')
    }
  }, [setSearchForRequest])

  const onSelect = useCallback((field: string, value: AccountListItem | null) => {
    setValues({
      ...form.values,
      [field]: value
    })

    onSearch()
  }, [onSearch, form.values, setValues])

  const onInputChange = useCallback((id, value: string, reason: AutocompleteInputChangeReason) => {
    onSearch(id, value)
    if (reason === 'clear') {
      onSelect(id, null)
      onSearch()
    }
  }, [onSelect, onSearch])

  const onBlur = (eOrField: FocusEvent<HTMLElement> | string): void => {
    if (typeof eOrField !== 'string') {
      _onBlur(eOrField)
    }

    // Unselect account when user delete the account name and unfocus dropdown
    if (refSearchField.current !== null && refSearchForInput.current === '') {
      setValues({
        ...form.values,
        [refKeyForInput.current]: null
      })
      onSearch()
    }

    // clear all necessary search data when user unfocus dropdown
    if (isTruthy(refSearchForInput.current)) {
      onSearch()
    }
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      const formData = parseFormData(selectedAccounts)

      setValues(formData)
    }

    if (isOpen) {
      void runEffect()
    }
  }, [selectedAccounts, setValues, isOpen])

  // ========================================== //

  return {
    ...form,
    items,
    onBlur,
    isOpen,
    onClose,
    onSelect,
    isLoading,
    loadItems,
    onInputChange,
    isSearchLoading,
    search: refSearchField.current
  }
}

export default useVehiclePurchasePayments
