import { css } from '@emotion/css'
const UNWRAPPED_TOOLBAR_WIDTH = 1108

export default css(`
  &&.cf-modal-container {
    .cf-modal-content-scroll-wrapper::-webkit-scrollbar {
      display: none;
    }
    
    .cf-modal-content {
      display: flex;
      flex-direction: column;

      .cf-divider {
        margin: 24px 0;
      }

      .togglable-toolbar-button {
        height: 40px;
        flex: 1;
        display: flex;
        justify-content: flex-start;

        button {
          border-color: #C99B86;
        }

        @media screen and (min-width: ${UNWRAPPED_TOOLBAR_WIDTH}px) {
          justify-content: flex-end;
        }
      }

      .inputs-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 24px;

        > div:nth-child(3) {
          grid-column: 1 / 3;
        }

        margin-bottom: 24px;
      }

      .send-email-editor {
        margin-bottom: 24px;

        .text-editor-container {
          min-height: 300px;
        }
      }
    }

    .cf-modal-footer {
      display: flex !important;
      justify-content: flex-end;
      padding: 0 40px 0 40px;

      button {
        min-width: 150px;
        margin: 0px;
        letter-spacing: 1.25px;

        .cf-button-content {
          font-weight: 500;
        }
      }
    }
  }
`)
