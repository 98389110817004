import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  min-height: 56px;
  width: 100%;
  padding: 4px 60px 4px 40px;
  border-radius: 2px;
  background-color: #FFFFFF;
  margin: 8px 0;
  border-left: 4px solid transparent;

  :hover {
    background-color: #FAFAFA;
    border-left: 4px solid #212121;
    border-top-left-radius: 3.5px;
    border-bottom-left-radius: 3.5px;
  }
  
  &.disabled {
     background-color: #FAFAFA;
  }

  div:first-child {
    margin-left: -4px;
  }

  :last-child {
    margin-bottom: 0;
  }

  &.cf-edit-mode-view {
    padding: 4px 4px 4px 24px;
    margin: 0;
    border-left: unset;
    border-radius: unset;
    align-items: flex-start;

    div:first-child {
      margin-left: 0;
    } 

    .cf-form-field-error {
      transform: unset;
      position: unset;
      text-wrap: wrap;
      width: 100%;
      padding: 0;
    }
  }

  .cf-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #101010;
    width: 100%;
    min-width: 200px;
    max-width: 284px;
  }
`)
