import {
  useCallback,
  useEffect,
  useState
} from 'react'

import CoverageApiProvider from 'api/coverage.api'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import useTransitionBlocker from 'hooks/useTransitionBlocker'

import {
  type DealerProductCoveragePackageSettingsDto,
  type DealerProductCoverageTemplateDto,
  type DealerDefaultCoverageDto,
  type FISettingsModel,
  type SelectedTemplateModel,
  type FISetupMarkupsModel
} from 'api/types/responses'

import { DEFAULT_COVERAGE_TEMPLATE, DEFAULT_SETTINGS } from './constants'
import { OnSubmitPackageSetupProps } from '../components/FIPackageSetup/hook/types'
import { UseFISettingsReturn } from './types'

const useFISettings = (): UseFISettingsReturn => {
  const { showAlert, showSuccess } = useCustomSnackbar()

  const [coverageTemplates, setCoverageTemplates] = useState<DealerProductCoverageTemplateDto[]>([])
  const [defaultSettings, setDefaultSettings] = useState<FISettingsModel>(DEFAULT_SETTINGS)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [markups, setMarkups] = useState<FISetupMarkupsModel | null>(null)
  const [openedTemplate, setOpenedTemplate] = useState<number | null>(null)
  const [dealerCoverage, setDealerCoverage] = useState<SelectedTemplateModel>(DEFAULT_COVERAGE_TEMPLATE)
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [isFIPackageSetupOpen, setIsFIPackageSetupOpen] = useState(false)
  const [isOpenUnsavedChanges, setIsOpenUnsavedChanges] = useState(false)
  const [packages, setPackages] = useState<DealerProductCoveragePackageSettingsDto[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [defaultCoverages, setDefaultCoverages] = useState<DealerDefaultCoverageDto[]>([])

  const { proceedTransition } = useTransitionBlocker({
    shouldBlock: isFormChanged,
    onBlock: () => setIsOpenUnsavedChanges(true)
  })

  const onSetDefaultCoverages = useCallback((coverages: DealerDefaultCoverageDto[]) => {
    coverages.sort((a, b) => a.productTypeId - b.productTypeId)
    setDefaultCoverages(coverages)
  }, [])

  const getDefaultCoverages = useCallback(async () => {
    const { items } = await CoverageApiProvider.getDefaultCoverage()

    onSetDefaultCoverages(items)
  }, [onSetDefaultCoverages])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onOpenFIPackageSetup = (): void => {
    setIsFIPackageSetupOpen(prevState => !prevState)
  }

  const onSetSettingsToState = useCallback((settings: FISettingsModel) => {
    setDefaultSettings(settings)
    setMarkups(settings.markups)
    setCoverageTemplates(settings.coverageViewTemplates)
    setDealerCoverage(settings.selectedTemplate ?? DEFAULT_COVERAGE_TEMPLATE())
    setPackages(settings.packages)
  }, [])

  const onChangePackages = (packages: DealerProductCoveragePackageSettingsDto[]): void => {
    setPackages(packages)
    setIsFormChanged(true)
  }

  const submitUpdate = async (): Promise<void> => {
    setIsSubmitting(true)

    try {
      const settings = await CoverageApiProvider.updateFISettings({
        packages,
        coverageViewTemplateId: dealerCoverage.id
      })

      onSetSettingsToState(settings)
      setIsFormChanged(false)
      showSuccess('Changes successfully saved.')
    } catch (err) {
      showAlert(err)
    } finally {
      setIsSubmitting(false)
    }
  }

  const onSubmitPackageSetup = useCallback(async ({
    markups,
    productNames,
    defaultCoverages
  }: OnSubmitPackageSetupProps): Promise<void> => {
    setMarkups(markups)
    const settings = await CoverageApiProvider.updatePackagesNames(productNames)
    defaultCoverages.sort((a, b) => a.productTypeId - b.productTypeId)
    onSetDefaultCoverages(defaultCoverages)
    onSetSettingsToState(settings)
  }, [onSetSettingsToState, onSetDefaultCoverages])

  const onCloseUnsavedDialog = (): void => setIsOpenUnsavedChanges(false)

  const onDontSaveAndClose = (): void => {
    onCloseUnsavedDialog()
    proceedTransition()
  }

  const onSaveAndClose = async (): Promise<void> => {
    await submitUpdate()
    onDontSaveAndClose()
  }

  const onChangeTemplate = (id: number): void => {
    const template = coverageTemplates.find((coverage) => coverage.id === id) ?? null

    setDealerCoverage({ id, template })
    setIsFormChanged(true)
  }

  const onReset = (e: MouseEvent): void => {
    e?.preventDefault()
    onSetSettingsToState(defaultSettings)
    setIsFormChanged(false)
    showSuccess('Changes discarded.')
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      try {
        setIsLoading(true)

        const settings = await CoverageApiProvider.getFISettings()
        await getDefaultCoverages()

        onSetSettingsToState(settings)
      } catch (e) {
        showAlert(e)
      } finally {
        setIsLoading(false)
      }
    }

    void runEffect()
  }, [onSetSettingsToState, showAlert, getDefaultCoverages])

  // ========================================== //

  return {
    markups,
    coverageTemplates,
    dealerCoverageViewTemplateId: dealerCoverage.id,
    isOpenUnsavedChanges,
    isSubmitting,
    onCloseUnsavedDialog,
    onDontSaveAndClose,
    onSaveAndClose,
    onReset,
    openedTemplate,
    setOpenedTemplate,
    submitUpdate,
    onChangeTemplate,
    isFormChanged,
    packages,
    onChangePackages,
    onOpenFIPackageSetup,
    isFIPackageSetupOpen,
    isLoading,
    defaultCoverages,
    onSubmitPackageSetup
  }
}

export default useFISettings
