import type { ColumnDef } from '@carfluent/common/dist/UI'
import { formatInteger } from '@carfluent/common'

import type { Vehicle } from 'api/types/vehicles.types'
import type { FilterHeaderCellProps } from 'components/common/Table/cells/FilterHeaderCell'

import { FilterHeaderCell } from 'components/common/Table/cells/FilterHeaderCell'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import PriceCell from 'components/common/Table/cells/PriceCell'
import AgeCell from 'components/common/Table/cells/AgeCell'
import ValueCell from 'components/common/Table/cells/ValueCell'

import StockCell from '../components/Cells/StockCell'
import TagsCellHOC from '../components/Cells/TagsCell'
import CarCell from '../components/Cells/CarCell'
import StatusCell from '../components/Cells/StatusCell'
import { COUNTER_MAP } from './constants'

interface GetColumnDefinitions {
  headerTagFilterProps: FilterHeaderCellProps
  totalCars: number
}

const getColumnDefinitions = ({
  totalCars,
  headerTagFilterProps
}: GetColumnDefinitions): Array<ColumnDef<Vehicle>> =>
  [
    {
      accessorKey: 'make',
      cell: CarCell,
      header: TextCellHOC({
        text: (
          <>
            Cars
            <span className='total-cars-wrapper'>
              ({totalCars})
            </span>
          </>
        )
      }),
      className: 'make-column',
      loading: 'rectangle_with_two_lines',
      size: 312
    },
    {
      id: 'vehicleState',
      accessorFn: (row) => {
        return {
          soldDealId: row.soldDealId,
          isDeposited: row.isDeposited,
          statusName: COUNTER_MAP[row.vehicleState] ?? '-'
        }
      },
      cell: StatusCell,
      header: TextCellHOC({ text: 'Status' }),
      size: 100,
      className: 'status-column',
      sortable: false
    },
    {
      accessorKey: 'stock',
      cell: StockCell,
      header: TextCellHOC({ text: 'Stock #' }),
      size: 100
    },
    {
      accessorKey: 'salePrice',
      cell: PriceCell,
      header: TextCellHOC({ text: 'Sale price' }),
      size: 140
    },
    {
      id: 'views',
      accessorKey: 'viewsCount',
      accessorFn: (item) => formatInteger(item.viewsCount, { emptyValues: '0' }),
      cell: ValueCell,
      header: TextCellHOC({ text: 'Views' }),
      size: 78
    },
    {
      id: 'leads',
      accessorKey: 'leadsCount',
      cell: ValueCell,
      header: TextCellHOC({ text: 'Leads' }),
      size: 78
    },
    {
      accessorKey: 'age',
      cell: AgeCell,
      header: TextCellHOC({ text: 'Age' }),
      size: 100
    },
    {
      id: 'photos',
      accessorFn: (item) => item.imageCount ?? '-',
      cell: ValueCell,
      accessorKey: 'imageCount',
      header: TextCellHOC({ text: 'Photos' }),
      size: 86
    },
    {
      accessorKey: 'tags',
      cell: TagsCellHOC({ items: headerTagFilterProps.items ?? [] }),
      header: <FilterHeaderCell {...headerTagFilterProps} /> as unknown as () => void,
      size: 188,
      minSize: 188,
      className: 'tag-cell',
      sortable: false
    }
  ]

export default getColumnDefinitions
