import { DealNextAction } from 'api/types'
import { isDeal2Completed } from 'utils/deals/isDealCompleted'

export interface Deal2Like {
  dealStateId: number
  nextAction: number | null
}

export const isDeal2WaitingForDocsSelection = (deal?: Deal2Like | null): boolean => {
  return !isDeal2Completed(deal) && (deal?.nextAction === DealNextAction.SelectForms)
}
