import { useCallback, useEffect } from 'react'

import { Notifications } from 'constants/names'
import { WebSocketsCrm as ws } from 'services/web_sockets'
import type {
  TaskCreatedNotificationArgs,
  TasksDeletedNotificationArgs,
  TasksUpdatedNotificationArgs
} from 'api/types'

export interface UseLeadWSUpdatesProps {
  onTasksUpdated: (leadId: number) => Promise<void>
  onTasksDeleted: (taskIds: number[]) => void
  leadId: number
}

const updateConfig = {
  throttledGroupId: 'leadDetailsTasksUpdate',
  throttledTimeout: 1000,
  accumulateEvents: true,
  resetTimerOnNewEvent: true
}

const deleteConfig = { throttledGroupId: 'leadDetailsTasksDelete', accumulateEvents: true }

const useLeadWSUpdates = ({
  onTasksDeleted: _onTasksDeleted,
  onTasksUpdated: _onTasksUpdated,
  leadId
}: UseLeadWSUpdatesProps): void => {
  // ========================================== //
  //                   ACTIONS                  //
  // ========================================== //

  const taskCreatedAction = useCallback((events: TaskCreatedNotificationArgs[][]) => {
    if (events.some(args => args.some(a => a.leadId === leadId))) {
      void _onTasksUpdated(leadId)
    }
  }, [_onTasksUpdated, leadId])

  const tasksUpdatedAction = useCallback((events: TasksUpdatedNotificationArgs[][]) => {
    if (events.some(args => args.some(a => a.leadIds != null && a?.leadIds.includes(leadId) && a.taskIds != null))) {
      void _onTasksUpdated(leadId)
    }
  }, [_onTasksUpdated, leadId])

  const tasksDeletedAction = useCallback((events: TasksDeletedNotificationArgs[][]) => {
    const taskIdsToDelete = events.flatMap(args =>
      args.filter(a => a.leadIds != null && a?.leadIds.includes(leadId) && a.taskIds != null)
        .flatMap(a => a.taskIds != null ? a.taskIds : [])
    )

    if (taskIdsToDelete.length > 0) {
      _onTasksDeleted(taskIdsToDelete)
    }
  }, [_onTasksDeleted, leadId])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const onTaskCreatedHandler = { name: 'tasks_taskCreatedAction', action: taskCreatedAction }

    ws.on(Notifications.TaskCreated, onTaskCreatedHandler, updateConfig)

    return () => {
      ws.off(Notifications.TaskCreated, onTaskCreatedHandler.name)
    }
  }, [taskCreatedAction])

  useEffect(() => {
    const onTasksUpdatedHandler = { name: 'tasks_tasksUpdatedAction', action: tasksUpdatedAction }

    ws.on(Notifications.TasksUpdated, onTasksUpdatedHandler, updateConfig)

    return () => {
      ws.off(Notifications.TasksUpdated, onTasksUpdatedHandler.name)
    }
  }, [tasksUpdatedAction])

  useEffect(() => {
    const onTasksDeletedHandler = { name: 'tasks_tasksDeletedAction', action: tasksDeletedAction }

    ws.on(Notifications.TasksDeleted, onTasksDeletedHandler, deleteConfig)

    return () => {
      ws.off(Notifications.TasksDeleted, onTasksDeletedHandler.name)
    }
  }, [tasksDeletedAction])
}

export default useLeadWSUpdates
