import { useLayoutEffect, useRef, type FC } from 'react'
import { Button, Loader, cnx } from '@carfluent/common'
import type { FilterTabsSectionProps } from '../../types'
import CLASS_NAME from './styles'

const ACTIVE_TAB_CLS = 'g-active-filter-tab'
const TAB_CLS = 'g-filter-tab'

const updateIndicator = (indicatorEl: HTMLSpanElement): void => {
  requestAnimationFrame(() => {
    const activeTabEl = document.getElementsByClassName(ACTIVE_TAB_CLS)[0] as HTMLElement

    if (activeTabEl != null) {
      indicatorEl.style.width = `${activeTabEl.clientWidth}px`
      indicatorEl.style.left = `${activeTabEl.offsetLeft}px`
    }
  })
}

const FilterTabsSection: FC<FilterTabsSectionProps> = ({
  tabs,
  activeTab,
  showActiveIndicator = true,
  renderTabIcon,
  onSelectTab
}) => {
  const refIndicatorEl = useRef<HTMLSpanElement | null>(null)
  const refTabsContainerEl = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    if (!showActiveIndicator) {
      return
    }

    const containerEl = refTabsContainerEl.current

    if (containerEl == null) {
      return
    }

    const resizeObserver = new ResizeObserver(() => {
      if (refIndicatorEl.current == null) {
        return
      }

      updateIndicator(refIndicatorEl.current)
    })

    resizeObserver.observe(containerEl)

    return () => {
      resizeObserver.unobserve(containerEl)
      resizeObserver.disconnect()
    }
  }, [])

  useLayoutEffect(() => {
    if (showActiveIndicator && refIndicatorEl.current != null && activeTab != null) {
      updateIndicator(refIndicatorEl.current)
    }
  }, [activeTab])

  const isAnyTabActive = tabs?.some(tab => tab.id === activeTab) ?? false

  return (
    <div className={cnx(CLASS_NAME, 'g-filter-panel-tabs')}>
      {
        isAnyTabActive && showActiveIndicator && (
          <span ref={refIndicatorEl} className='g-filter-tab-active-indicator' />
        )
      }
      <div ref={refTabsContainerEl} className='g-filter-tabs-container'>
        {tabs?.map(tab => {
          const isActive = tab.id === activeTab

          return (
            <Button
              key={tab.id}
              className={cnx(TAB_CLS, isActive && ACTIVE_TAB_CLS)}
              onClick={(e) => {
                onSelectTab(tab.id)
              }}
              dataTestId={`filter-tab-${tab.id}`}
              variant='text'
            >
              <span className='g-filter-tab-icon'>{renderTabIcon?.(tab, isActive)}</span>
              <span className='g-filter-tab-text'>{tab.name}</span>
              <span className='g-filter-tab-counter'>
                {
                  tab.isLoading === true
                    ? <Loader color='inherit' />
                    : (
                      <span>
                      &nbsp;
                        {tab.withoutCounter === true ? '' : tab.counter ?? 0}
                      </span>
                      )
                }
              </span>
            </Button>
          )
        })}
      </div>
    </div>
  )
}

export default FilterTabsSection
