import { TimelineFilterItemsIds } from 'api/types'

export enum FilterItemTitles {
  All = 'All',
  Emails = 'Emails only',
  Messages = 'Messages only'
}

interface GetFilterItems {
  onClose: () => void
  onChange: (id: TimelineFilterItemsIds) => void
}

export interface FilterItem {
  onClick: () => void
  title: FilterItemTitles
  disabled?: boolean
  id: TimelineFilterItemsIds
}

export const FILTER_ITEMS_TEXTS = {
  [TimelineFilterItemsIds.All]: FilterItemTitles.All,
  [TimelineFilterItemsIds.Emails]: FilterItemTitles.Emails,
  [TimelineFilterItemsIds.Messages]: FilterItemTitles.Messages
}

const getFilterItems = ({ onClose, onChange }: GetFilterItems): FilterItem[] => {
  const getAction = (id: TimelineFilterItemsIds): () => void => () => {
    onChange(id)
    onClose()
  }

  return [
    {
      onClick: getAction(TimelineFilterItemsIds.All),
      title: FilterItemTitles.All,
      id: TimelineFilterItemsIds.All
    },
    {
      onClick: getAction(TimelineFilterItemsIds.Emails),
      title: FilterItemTitles.Emails,
      id: TimelineFilterItemsIds.Emails
    },
    {
      onClick: getAction(TimelineFilterItemsIds.Messages),
      title: FilterItemTitles.Messages,
      id: TimelineFilterItemsIds.Messages
    }
  ]
}

export default getFilterItems
