import { type AccountingReconciliationSessionDto } from 'api/types'
import { toDate } from 'utils/parse_date'

import { type ReconciliationFormData } from 'types/accounting'

export const parseEditForm = (data: AccountingReconciliationSessionDto): ReconciliationFormData => {
  const { beginningBalance, endBalance, endDate } = data

  return ({
    ...data,
    beginningBalance,
    endingBalance: endBalance,
    endDate: toDate(endDate)
  })
}

export default parseEditForm
