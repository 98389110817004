import { css } from '@emotion/css'

export default css(`
  .g-panel {
    height: 464px;
    box-shadow: 0px 4px 12px rgba(78, 91, 98, 0.1);
  }

  .cf-statistics-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 30px;
  }
  
  .cf-pie-chart-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 50%;
    min-width: 525px;
  }

  .cf-list-item {
    display: flex;
    flex: 1 0 auto;
    width: 50%;
    height: 100%;
    gap: 16px;
  }

  .cf-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #101010;
  }

  .recharts-layer.recharts-pie,
  .recharts-sector:focus {
      outline: none !important;
      outline-color: none;
      outline-style: none;
      outline-width: none;
  }

  .cf-open-balances-table-root {
    margin-top: 24px;
  }

  .cf-balances-margin {
    margin-bottom: 64px;
  }

  .g-bottom-actions-panel {
    transition: bottom 0.3s ease;
    
    .cf-actions-menu {
      button {
        width: 150px;
        margin: 0;
        height: 56px!important;
      }

      .cf-menu-popper .MuiPaper-root.MuiPaper-elevation8 {
        box-shadow: 0px 24px 38px 0px rgba(58, 71, 78, 0.10);
      }

      .cf-menu-container {
        width: 150px;
      }
      
      .cf-menu-item {
        min-width: 100%;
      }
    }
  }
`)
