import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  & {
    position: relative;
    
    .backdrop {
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 12px;
      cursor: pointer;
      
      display: none;
      &.not-disabled {
        display: block;
      }
    }
    
    .clickable-input {
      /**
       * Util input is not explicitly disabled, it looks like active input (but is not focusable).
       */
      &.not-disabled {
        .disabled {
          .cf-start-adornment,
          .cf-input {
            color: var(--cf-input-color);
          }
          
          .cf-input-label {
            color: var(--cf-label-color);
          }
        }
      }
    
      .cf-end-adornment.cf-input-adornment {
        .clickable-input-adornment {
          font-family: Roboto;
          cursor: pointer !important;
          text-decoration: underline;
          color: #000000;
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          min-height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          
          &.disabled {
            color: var(--cf-input-disabled-color);
          }
        }  
      }
    }

    .popper-trigger-helper-text {
      font-family: Roboto;
      position: absolute;
      left: 16px;
      bottom: -20px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4;
      color: rgba(33, 33, 33, 0.4)
    }
    
    .cf-popper-root {
      width: 100%;
      z-index: 2;
      
      .cf-popper-content {
        width: 100%;
        padding: 16px;
        border-radius: 8px;
      }
    }
  }
`)

export const POPOVER_CLASS_NAME = css(`
  .MuiPopover-paper {
    border-radius: 12px;
    
    .popover-content {
      width: 360px;
      padding: 24px;
    }
  }
`)
