import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;

  .car-of-interest-wrapper {
    display: flex;
    align-items: center;
  }

  .cf-button-root {
    align-self: flex-end;
    min-width: 124px;
    max-width: 124px;
    height: 26px;
    padding: 0;
    margin-bottom: 8px;
    border-radius: 8px;

    .cf-button-variant-outlined { background-color: inherit; }

    .cf-button-content {
      text-wrap: nowrap;
      padding: 5px 18px;
      text-transform: uppercase;
      letter-spacing: 1.25px;
      font-weight: 500; 
    }
  }
`)
