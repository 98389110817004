import { useCallback, useContext, useMemo, useState } from 'react'
import { touchify, useRefUpdater, scrollToFirstError, noop } from '@carfluent/common'
import pDebounce from 'p-debounce'
import { toJS } from 'mobx'

import { type EntityId, type TransactionFormData } from 'types'
import { type OpenBalanceRow } from 'components/accounting/OpenBalancesTable'
import {
  type AccountingFiscalYearsResponse,
  type AccountListItem,
  type AccountListPayload,
  type EntityListItem,
  type ListResponse,
  type OpenBalancesListItem,
  type TransactionDetails as ApiTransactionDetails,
  type TransactionLineCustomerDto,
  type TransactionLineVendorDto,
  type RecurringTransactionTemplatesDetails,
  type BankStatementTransactionMatchItem,
  EntityTypeId,
  TransactionTypeId,
  TransactionControlTypeId
} from 'api/types'

import useAsyncEffect from 'hooks/useAsyncEffect'
import SettingsCTX from 'store/settings'
import AccountingApiProvider from 'api/accounting.api'
import delay from 'utils/delay'
import getSuggestedAccountCategories from 'utils/accounting/getSuggestedAccountCategories'
import getSuggestedAccountTypes from 'utils/accounting/getSuggestedAccountTypes'
import getAvailableAccountCategories from 'utils/accounting/getAvilableAccountCategories'
import appendTextLine from 'utils/common/appendTextLine'
import isCheck from 'utils/accounting/isCheck'
import isPayBill from 'utils/accounting/isPayBill'
import _isPayable from 'utils/accounting/isPayable'
import parseCustomer from 'utils/accounting/parseCustomer'
import parseVendor from 'utils/accounting/parseVendor'
import { isTruthy } from 'utils/general'

import useTransactionApiClient from './apiClient'
import { validateLinesTable } from './linesTableValidator'
import getParser from './parser'
import useOpenBalances from './useOpenBalances'
import CheckWindow from './printCheck'
import dictionaries from './dictionaries'
import useTransactionForm from './useTransactionForm'
import useLinesTable from './useLinesTable'
import { getDefaultFormData, TRANSACTION_TYPE_IDS_WITH_DEFAULT_VALUE } from './constants'
import {
  type TransactionDetails,
  type UseTransactionDetailsProps,
  type UseTransactionDetailsReturn
} from './types'

export type { UseTransactionDetailsProps, UseTransactionDetailsReturn }

const DEFAULT_FORM_DATA = getDefaultFormData(false)
const PRELOADED_CONTROL_ROW_IDX = 0
const FORM_CONTROL_FIELD_ID = 'control'

const useTransactionDetails = ({
  bankStatementId,
  bankStatementMatches,
  checkedOpenBalances: _checkedOpenBalances,
  dealId,
  inventoryId,
  isModalOpen = false,
  isReadonly: _isReadonly = false,
  onBankStatementClear: _onBankStatementClear,
  onCancel,
  onCloseModal,
  onSubmit,
  onDelete = onSubmit,
  onViewNextTransaction,
  openBalancesFilters,
  preloadedControlData = null,
  preloadedTransactionData = null,
  recurringTransactionTemplateId: _recurringTransactionTemplateId = null,
  transactionId = null,
  transactionTypeId: _transactionTypeId = null,
  transactionCreationMode = 'regular'
}: UseTransactionDetailsProps): UseTransactionDetailsReturn => {
  const [lockedInfo, setLockedInfo] = useState<AccountingFiscalYearsResponse | null>(null)
  const [originalTransaction, setOriginalTransaction] = useState<ApiTransactionDetails | null>(null)
  const [originalTemplate, setOriginalTemplate] = useState<RecurringTransactionTemplatesDetails | null>(null)
  const [parsedTransaction, setParsedTransaction] = useState<TransactionDetails | null>(null)

  const [globalError, setGlobalError] = useState<string | null>(null)
  const [closeYearError, setCloseYearError] = useState<string | null>(null)
  const resetGlobalValidation = useCallback(() => {
    setGlobalError(null)
    setCloseYearError(null)
  }, [])

  const { accounting } = useContext(SettingsCTX)

  const {
    rows,
    isTableAmountDirty,
    addRow,
    cleanUp: cleanUpLines,
    getRows,
    insertRow,
    removeRow,
    updateCell,
    setRows
  } = useLinesTable({ resetValidation: resetGlobalValidation })

  const isRecurring = transactionCreationMode === 'recurring'
  const baseValues = useMemo(() => (
    getDefaultFormData(transactionId != null)
  ), [transactionId])

  const form = useTransactionForm({
    baseValues,
    isRecurring,
    transactionTypeId: isRecurring
      ? originalTemplate?.templateTransaction.transactionTypeId ?? _transactionTypeId
      : parsedTransaction?.transactionTypeId ?? _transactionTypeId
  })

  const { setFieldValue, onChange, setValues, setTouched, values, setErrors } = form
  const transactionTypeId = values.transactionTypeId ?? _transactionTypeId
  const isBankStatementMatch = transactionCreationMode === 'bank-statement-match'
  const isGeneratedTransaction = transactionCreationMode === 'generated-from-deal'
  const isPayable = _isPayable(transactionTypeId)
  const refTransactionTypeId = useRefUpdater(transactionTypeId)
  const recurringTransactionTemplateId = values.recurringTransactionTemplateId ?? _recurringTransactionTemplateId

  const API = useTransactionApiClient({
    originalTemplate,
    originalTransaction,
    rows,
    setCloseYearError,
    setErrors,
    setFormValue: setValues,
    setLockedInfo,
    setOriginalTemplate,
    setOriginalTransaction,
    setParsedTransaction,
    setRows,
    transactionFormData: values,
    transactionTypeId
  })

  /**
   * Receivable Account dropdown
   */
  const getReceivableAccounts = useCallback(pDebounce(async (_payload: AccountListPayload): Promise<ListResponse<AccountListItem>> => {
    const availableCategoryIds = getAvailableAccountCategories(refTransactionTypeId.current)

    const payload: AccountListPayload = {
      ..._payload,
      suggestedCategoryIds: getSuggestedAccountCategories(refTransactionTypeId.current),
      suggestedTypeIds: getSuggestedAccountTypes(refTransactionTypeId.current)
    }

    if (availableCategoryIds != null) {
      payload.categoryIds = availableCategoryIds
    }

    return await dictionaries.getAccounts(payload)
  }, 100), [])

  /**
   * Updates state of the fields outside the OpenBalances table,
   * in response to checks\unchecks in the table:
   * - prefills Entity in top form, when User checks a single OpenBalance;
   * - clears Entity in the top form, when User checks more than one OpenBalance;
   * - appends row's Entity name to Memo field, when User checks a single row.
   */
  const onPostprocessOpenBalancesToggle = useCallback((
    checkedOpenBalances: Set<string>,
    openBalance: OpenBalanceRow | OpenBalancesListItem[]
  ) => {
    if (isCheck(transactionTypeId) || isPayBill(transactionTypeId)) {
      return
    }

    if (checkedOpenBalances.size !== 1) {
      setFieldValue('receivableEntity', null)
      return
    }

    const { customer, vendor } = Array.isArray(openBalance) ? openBalance[0] : openBalance
    let entity: EntityListItem | null = null

    if (customer != null) {
      entity = parseCustomer(customer)
    } else if (vendor != null) {
      entity = parseVendor(vendor)
    }

    if (entity !== null) {
      setFieldValue('receivableEntity', entity)
      setFieldValue('memo', appendTextLine(values.memo, entity.name))
    }
  }, [
    setFieldValue,
    transactionTypeId,
    values.memo
  ])

  const {
    checkedOpenBalances,
    isCheckByIdInProgress,
    onCheckOpenBalanceById,
    onLoadOpenBalances,
    onToggleOpenBalances,
    openBalancesData,
    removeOpenBalanceCheck
  } = useOpenBalances({
    getRows,
    insertRow,
    onCheckOpenBalance: onPostprocessOpenBalancesToggle,
    onUncheckOpenBalance: onPostprocessOpenBalancesToggle,
    removeRow,
    transactionTypeId
  })

  const hasBankingLines = parsedTransaction?.hasBankingLines ?? false
  const isSystemCreated = parsedTransaction?.isSystemCreated ?? false
  const isReadonly = _isReadonly ||
    values.isLocked ||
    (isSystemCreated && (recurringTransactionTemplateId == null)) || // recurring tx-s are also system-created, but editable
    isTruthy(parsedTransaction?.hasLineWithCompletedReconciliation) // banking tx-s are partially editable, until reconciliation is finished

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const setLoadedTransaction = useCallback(async (transaction: ApiTransactionDetails): Promise<void> => {
    const parseTransaction = getParser(transaction.transactionTypeId)
    const parsedTransaction = parseTransaction(transaction)
    const { rows, ...formData } = parsedTransaction

    setOriginalTransaction(preloadedTransactionData)
    setValues(formData, true)
    form.validateForm()
    setRows(rows)
  }, [
    setOriginalTransaction,
    setValues,
    setRows,
    form.validateForm
  ])

  const handleCleanUp = useCallback(() => {
    form.resetForm(getDefaultFormData(true))
    cleanUpLines()
    onCloseModal?.()
  }, [
    form.resetForm,
    onCloseModal,
    cleanUpLines
  ])

  /**
   * Processes "raw" modal closing, which can be triggered in different ways:
   * by Esc, by click on the cross icon, or by click on backdrop.
   * Currently disallows to close modal by backdrop click.
   *
   * Fires events (in order):
   * - onCloseModal
   */
  const handleCloseMuiModal = useCallback((_?, reason?: string) => {
    if (reason !== 'backdropClick') {
      handleCleanUp()
    }
  }, [handleCleanUp])

  /**
   * Fires events (in order):
   * - onCloseModal
   * - onCancel
   */
  const handleCancel = useCallback(() => {
    handleCloseMuiModal()
    onCancel?.()
  }, [handleCloseMuiModal, onCancel])

  /**
   * Fires events (in order):
   * - onCloseModal
   * - onSubmit
   */
  const getSubmitCallback = useCallback((
    apiCall: (id?: EntityId | null, isPrint?: boolean) => Promise<boolean>,
    parentId?: EntityId | null,
    isPrint?: boolean,
    onSubmit?: (data: TransactionFormData) => void
  ) => async (): Promise<boolean> => {
    /**
     * instead of disabling submit button we need to keep it active and show alert
     * if there is any condition mismatch
     */
    form.validateForm()

    if (!form.isValid) {
      setTouched(touchify(DEFAULT_FORM_DATA) as any)
      scrollToFirstError()
      return false
    }

    const linesTableErrors = validateLinesTable(form.values, rows, transactionTypeId)
    const isLinesTableValid = linesTableErrors === null
    if (!isLinesTableValid) {
      setGlobalError(linesTableErrors)
    }

    const isSuccess = isLinesTableValid && await apiCall(parentId, isPrint)
    if (isSuccess) {
      handleCleanUp()
      onSubmit?.(form.values)
      return true
    } else {
      return false
    }
  }, [
    form, rows, transactionTypeId,
    setTouched, handleCleanUp
  ])

  const onCreateTransaction = useCallback(async () => {
    const apiCall = isRecurring
      ? API.createTransactionTemplate
      : isBankStatementMatch ? API.createBankStatementMatch : API.createTransaction

    const id = isBankStatementMatch ? bankStatementId : dealId
    await getSubmitCallback(apiCall, id, false, onSubmit)()
  }, [
    getSubmitCallback,
    API.createTransaction,
    API.createTransactionTemplate,
    dealId, onSubmit, isRecurring,
    bankStatementId, isBankStatementMatch
  ])

  const onUpdateTransaction = useCallback(async () => {
    const apiCall = isRecurring ? API.updateTransactionTemplate : API.updateTransaction
    await getSubmitCallback(apiCall, undefined, false, onSubmit)()
  }, [
    getSubmitCallback,
    API.updateTransaction,
    API.updateTransactionTemplate,
    onSubmit, isRecurring
  ])

  /**
   * Fires events (in order):
   * - onCloseModal
   * - onDelete
   */
  const onDeleteTransaction = useCallback(async () => {
    const isSuccess = isRecurring ? await API.deleteTransactionTemplate() : await API.deleteTransaction()
    if (isSuccess) {
      handleCleanUp()
      onDelete?.()
    }
  }, [API.deleteTransaction, API.deleteTransactionTemplate, onDelete, handleCleanUp, isRecurring])

  const onAddCustomer = useCallback((data: TransactionLineCustomerDto, rowIdx?: number | null, columnId?: string | null) => {
    const doJob = async (): Promise<void> => {
      const entity = parseCustomer(data)
      if (entity == null) {
        return
      }

      if (rowIdx != null && columnId != null) {
        updateCell(rowIdx, columnId, entity)
      } else {
        onChange('receivableEntity', entity)
      }
    }

    void doJob()
  }, [updateCell, onChange])

  const onAddVendor = useCallback((data: TransactionLineVendorDto, rowIdx?: number | null, columnId?: string | null) => {
    const doJob = async (): Promise<void> => {
      const entity = parseVendor(data)
      if (entity == null) {
        return
      }

      if (rowIdx != null && columnId != null) {
        updateCell(rowIdx, columnId, entity)
      } else {
        onChange(isPayable ? 'receivableVendor' : 'receivableEntity', entity)
        if (isPayable) {
          onChange('receivableAccount', data.account)
        }
      }
    }

    void doJob()
  }, [updateCell, onChange, isPayable])

  const onAddRow = useCallback(() => {
    addRow(form.values.receivableControl ?? null, form.values.receivableEntity ?? null)
  }, [
    addRow,
    form.values.receivableControl,
    form.values.receivableEntity
  ])

  const onRemoveRow = useCallback((rowIdx: number): void => {
    const rows = getRows()
    removeRow(rowIdx)
    removeOpenBalanceCheck(rows[rowIdx].reconciliationId ?? null)
  }, [removeOpenBalanceCheck, removeRow, getRows])

  const onPrintCheck = useCallback(async () => {
    await form.validateForm()

    if (!form.isValid) {
      await setTouched(touchify(DEFAULT_FORM_DATA) as any)
      scrollToFirstError()
    } else {
      const wnd = CheckWindow(accounting)
      wnd.showLoader()
      window.focus()

      /**
       * Data should be gathered before submit,
       * since after successful submitting form will be cleaned up.
       */
      const lines = getRows()
      const hasEmptyLines = lines.length === 0
      const defaultEmptyLine = lines.find((item) => item.account == null)
      const isEmptyCheck = hasEmptyLines || (defaultEmptyLine != null)
      const parentId = isBankStatementMatch ? bankStatementId : dealId

      const saveTransaction = transactionId == null
        ? getSubmitCallback(API.createTransaction, parentId, true, onSubmit)
        : getSubmitCallback(API.updateTransaction, undefined, true, onSubmit)

      const { receivableControl, receivableEntity } = form.values
      const controls = receivableControl != null
        ? await AccountingApiProvider.getControls({
          search: receivableControl?.name,
          skip: 0,
          take: 1
        })
        : null

      const getEntity = receivableEntity?.entityType === EntityTypeId.Customer
        ? AccountingApiProvider.getCustomer
        : AccountingApiProvider.getVendor
      const entity = await getEntity(receivableEntity?.id ?? 0)

      /**
       * For most cases, when User prints transaction, we also need to create\update it.
       * But if it's a BankStatement transaction - we can't update it, we only should
       * mark it as printed (on BE side).
       */
      const isSaved = (hasBankingLines && transactionId != null)
        ? await API.printCheckAsBankStatement(transactionId)
        : await saveTransaction()

      if (isSaved) {
        const emptyLine = lines.map((item) => ({
          ...item,
          credits: null,
          debits: null
        }))

        wnd.focus()
        wnd.showCheck({
          control: controls?.items[0] ?? null,
          entity,
          form: values,
          isEmptyCheck,
          lines: isEmptyCheck ? Array(3).fill(emptyLine) : lines.map(x => toJS(x))
        })
      } else {
        await delay(1000, wnd.close)
      }
    }
  }, [
    bankStatementId,
    dealId,
    isBankStatementMatch,
    form.isValid,
    form.validateForm,
    getRows,
    getSubmitCallback,
    API.createTransaction,
    API.updateTransaction,
    setTouched,
    transactionId,
    onSubmit,
    values
  ])

  const onBankStatementClear = useCallback(async (match: BankStatementTransactionMatchItem) => {
    _onBankStatementClear?.(match)
  }, [_onBankStatementClear])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Loads transaction data or fills form with the already preloaded data.
   */
  useAsyncEffect(async () => {
    if (!isModalOpen) {
      return
    }

    /**
     * Preloaded a.k.a. "Generated" transaction.
     * See "Generate transaction" in VehicleDetails.
     */
    if (preloadedTransactionData != null) {
      await setLoadedTransaction(preloadedTransactionData)
      return
    }

    /**
     * New transaction.
     */
    if (transactionId == null) {
      form.resetForm(getDefaultFormData())

      /**
       * Simulate clicking on OpenBalances rows, to reproduce selection (with side effects)
       * of the rows, selected on parent screen.
       */
      if ((_checkedOpenBalances != null) && (_checkedOpenBalances.size > 0)) {
        for (const reconciliationId of _checkedOpenBalances) {
          onCheckOpenBalanceById(reconciliationId, _checkedOpenBalances.size > 1)
        }
      }

      return
    }

    /**
     * Existing transaction.
     */
    await (isRecurring ? API.loadTransactionTemplate(transactionId) : API.loadTransaction(transactionId))
  }, [
    API.loadTransaction,
    API.loadTransactionTemplate,
    _checkedOpenBalances,
    isModalOpen,
    onCheckOpenBalanceById,
    preloadedTransactionData,
    setLoadedTransaction,
    transactionId,
    form.resetForm,
    isRecurring
  ])

  /**
   * New transaction.
   * Fills control dropdown by preloaded data.
   */
  useAsyncEffect(async () => {
    if (!isModalOpen || (transactionId != null)) {
      return
    }

    if (preloadedControlData?.stock == null) {
      return
    }

    const lineControl = {
      ...preloadedControlData,
      name: `INV-${preloadedControlData?.stock ?? ''}`,
      controlType: TransactionControlTypeId.Vehicle
    }

    setFieldValue(FORM_CONTROL_FIELD_ID, lineControl)
    updateCell(PRELOADED_CONTROL_ROW_IDX, FORM_CONTROL_FIELD_ID, lineControl)
  }, [
    isModalOpen,
    preloadedControlData,
    setFieldValue,
    transactionId,
    updateCell
  ])

  /**
   * New transaction.
   * Preloads Account object (by account number),
   * needed as default value for 'Deposited to' field.
   */
  useAsyncEffect(async () => {
    if (!isModalOpen || (transactionId != null) || isBankStatementMatch) {
      return
    }

    if (TRANSACTION_TYPE_IDS_WITH_DEFAULT_VALUE.has(transactionTypeId)) {
      const searchValue = transactionTypeId === TransactionTypeId.Receive
        ? `${accounting.receiveAccountNumber}`
        : `${accounting.checkAccountNumber}`

      const resp = await getReceivableAccounts({
        search: searchValue,
        skip: 0,
        take: 1
      })

      const account = resp.items[0]
      if (account != null) {
        onChange('receivableAccount', account)
      }
    }
  }, [
    isModalOpen,
    isBankStatementMatch,
    transactionTypeId,
    transactionId,
    getReceivableAccounts,
    onChange
  ])

  // ========================================== //

  return {
    /**
     * Props for Transaction Container.
     */
    bankingBannersInfoProps: {
      bankStatements: parsedTransaction?.bankStatements ?? [],
      clearedAccounts: parsedTransaction?.clearedAccounts ?? [],
      reconciledAccounts: parsedTransaction?.reconciledAccounts ?? []
    },
    bankStatementMatchesProps: {
      onClearMethodClick: onBankStatementClear,
      rows: bankStatementMatches ?? []
    },
    dealRecapButtonProps: {
      dealId: originalTransaction?.dealId ?? null,
      inventoryId: inventoryId ?? null
    },
    dictionaries: {
      ...dictionaries,
      getReceivableAccounts
    },
    formProps: {
      ...form,
      dateTimeMin: accounting.accountingStartDate,
      isRecurring,
      resetForm: form.resetForm as any
    },
    hasBankingLines,
    isAutoLineInBanking: parsedTransaction?.isAutoLineInBanking ?? false,
    isBankStatementMatch,
    isGeneratedTransaction,
    isReadonly,
    isSystemCreated: parsedTransaction?.isSystemCreated ?? false,
    linesTableProps: {
      data: rows,
      error: globalError,
      isLoading: isCheckByIdInProgress, // AZ-TODO: should also include `isTransactionLoading`
      onAddRow,
      onChange: updateCell,
      onRemoveRow
    },
    onAddCustomer,
    onAddVendor,
    onViewNextTransaction: onViewNextTransaction ?? noop,
    openBalancesProps: {
      filters: openBalancesFilters,
      openBalancesData,
      onLoadData: onLoadOpenBalances,
      onToggleRowCheckbox: onToggleOpenBalances,
      selectedRowsIds: checkedOpenBalances
    },
    topBannersProps: {
      closeYearError,
      depositDealId: originalTransaction?.depositDealId ?? null,
      hasLineWithCompletedReconciliation: parsedTransaction?.hasLineWithCompletedReconciliation ?? false,
      lockedInfo,
      onCloseModal: handleCloseMuiModal,
      packInventoryAssetAccountNumber: accounting.packInventoryAssetAccountNumber,
      retailVehicleCostsAccountNumber: accounting.retailVehicleCostsAccountNumber
    },
    transactionSummaryProps: {
      isTableAmountDirty
    },
    transactionStateId: parsedTransaction?.transactionStateId ?? null,
    transactionTypeId,

    /**
     * Props for Modal wrappers.
     */
    hasRelatedDeal: originalTransaction?.dealId != null,
    isControlLoading: isCheckByIdInProgress,
    isLoading: API.isLoading || isCheckByIdInProgress,
    isModalOpen,
    onCancel: handleCancel,
    onCloseModal: handleCloseMuiModal,
    onCreateTransaction,
    onDeleteTransaction,
    onPrintCheck: isCheck(transactionTypeId) ? onPrintCheck : undefined,
    onUpdateTransaction,
    recurringTransactionTemplateId,
    transactionId: transactionId ?? -1
  }
}

export default useTransactionDetails
