import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGAdd: FC<IconProps> = ({
  disabled = false,
  hidden = false,
  width = 24,
  height = 24
}) => {
  const opacity = hidden
    ? 0
    : disabled ? 0.4 : 1

  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z'
        fill={disabled ? 'black' : '#101010'}
        fillOpacity={opacity}
      />
    </svg>
  )
}

export default IconSVGAdd
