import { css } from '@emotion/css'

export default css(`
  display: flex;
  align-items: center;

  .timeline-item-assigned-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(33, 33, 33, 0.5);
    margin-right: 4px;
    white-space: nowrap;
  }

  .timeline-item-user-state-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    height: 100%;
    padding-left: 8px;
    margin-left: 8px;
    border-left: 1px solid rgba(33, 33, 33, 0.5);
    color: rgba(33, 33, 33, 0.5);
    letter-spacing: 0.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: initial;
    max-width: 100%;
  }

  .timeline-item-user-state-label:empty {
    display: none;
  }
  
  .name-wrapper {
    max-width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    p {
      overflow: hidden;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`)
