import { type SortingInfo, serializers } from '@carfluent/common'

import {
  type DateType,
  type DictionaryItems,
  type LeadsByVehicleSourceRequest,
  SortOrder
} from 'api/types'
import serializeDateFilters from 'utils/filters/serializeDateFilters'

export interface SerializePayloadProps {
  dateRange: DateType
  sorting?: SortingInfo
  leadSources: DictionaryItems<string>
}

export const serializePayload = ({ sorting, leadSources, dateRange }: SerializePayloadProps): LeadsByVehicleSourceRequest => {
  return {
    dateRange: serializeDateFilters(dateRange, serializers.serializeDateTimeUTC) as DateType,
    leadSources: leadSources.length !== 0 ? leadSources.map(({ id }) => id) : null,
    sortField: sorting != null ? sorting.id : null,
    sortOrder: sorting != null ? sorting.order === 'asc' ? SortOrder.Ascending : SortOrder.Descending : null
  }
}
