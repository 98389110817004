import type { FC } from 'react'
import { CellWrapper, parsers } from '@carfluent/common'

import { DATE_US_SHORT_YEAR_FORMAT } from 'constants/constants'
import { formatDate, getDaysFromNow } from 'utils/parse_date'

import CLASS_NAME from './styles'

export interface LastDateCellProps {
  getValue: () => string
}

const { parseDateStringUTC } = parsers

const LastDateCell: FC<LastDateCellProps> = ({ getValue }) => {
  const date = getValue()
  const parsedDate = parseDateStringUTC(date)
  const daysFromNow = getDaysFromNow(date)

  return (
    <CellWrapper className={CLASS_NAME}>
      <p>{daysFromNow} days ago</p>

      {parsedDate != null && (
        <p>
          {formatDate(parsedDate, DATE_US_SHORT_YEAR_FORMAT)}
        </p>
      )}
    </CellWrapper>
  )
}

export default LastDateCell
