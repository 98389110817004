import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE, UI } from '@carfluent/common'
import type { CommandRequest, SortingInfo, Preset } from '@carfluent/common'
import type { OpenBalancesFilters } from './types'

export const GET_DEFAULT_FILTERS = (): OpenBalancesFilters => ({
  amount: { from: null, to: null, equal: null },
  account: null,
  entity: null,
  date: { from: null, to: null }
})

export const DEFAULT_SORTING: SortingInfo = {
  id: 'date',
  order: 'desc'
}

export enum Messages {
  EmptyState = 'No open balances',
  NothingFound = 'Nothing found'
}

export const SECRET_RECONCILIATION_ID = '-----'
export const ALL_TILL_TODAY_ID = 'All till Today'
export const GET_DEFAULT_PRESETS = (): Preset[] => UI.getDefaultDateRangePresets(DEFAULT_MIN_DATE, DEFAULT_MAX_DATE)
export const DEFAULT_SELECTED_ROWS: Set<string> = new Set([])
export const RESET_COMMAND_BOILERPLATE: CommandRequest<void> = { triggers: 0, respond: () => {} } as const
