import type { FC, ReactNode } from 'react'
import type { Row } from '@tanstack/react-table'
import { cnx } from '@carfluent/common'

import { formatIntegerForView, joinPartsBySpace } from 'utils/view_helper'
import TooltipedText from 'components/common/TooltipedText'
import IconSVG from 'components/inlineImages'
import Tooltip from 'components/common/Tooltip'
import { getValue } from 'pages/inventory/VehiclesList/hook/utils'

import type { Vehicle } from 'api/types'
import carPlaceholder from './car_placeholder_for_table.svg'
import CLASS_NAME, { TOOLTIP_CLASS_NAME } from './styles'

export interface CarCellProps {
  row: Row<Vehicle>
}

const CarCell: FC<CarCellProps> = (props) => {
  const row = props.row.original
  const hasImage = Boolean(row.mainImageUrl)
  const imgUrl = hasImage ? row.mainImageUrl : carPlaceholder

  const renderCarInfo = (): NonNullable<ReactNode> => {
    return (
      <ul className='car-info-container'>
        <li className='car-info-item'>
          <span className='car-info-title'>VIN:</span>
          <span className='car-info-value'>{row.vin ?? '-'}</span>
        </li>
        <li className='car-info-item'>
          <span className='car-info-title'>Mileage:</span>
          <span className='car-info-value'>{formatIntegerForView(getValue(row.mileage)) ?? '-'}</span>
        </li>
        <li className='car-info-item'>
          <span className='car-info-title'>Source: </span>
          <span className='car-info-value'>{getValue(row.source)}</span>
        </li>
      </ul>
    )
  }

  return (
    <div className={cnx(CLASS_NAME, 'cf-cell-wrapper')}>
      <img alt='' src={imgUrl ?? ''} className='cell-image' />

      <div className='cell-description'>
        <div className='car-name-wrapper'>
          <TooltipedText
            numberOfRows={2}
            className='bold-text'
            value={joinPartsBySpace(row.year, row.make, row.model, row.trim)}
          />
        </div>

        <span className='cell-info-icon'>
          <Tooltip
            placement='bottom-start'
            title={renderCarInfo()}
            className={TOOLTIP_CLASS_NAME}
          ><IconSVG.Info />
          </Tooltip>
        </span>
      </div>
    </div>
  )
}

export default CarCell
