import { type BuyerModel } from 'api/types/responses'
import { type PersonalDetailsDto } from 'api/types/customersCore.types'
import { toDateOrNull } from 'utils/parse_date'
import { GET_DEFAULT_BUYER_MODEL } from 'api/defaults/deals'

const parseBuyerDto = (personalDetails: PersonalDetailsDto | null): BuyerModel => {
  if (personalDetails == null) {
    return GET_DEFAULT_BUYER_MODEL()
  }

  return {
    id: personalDetails.id,
    firstName: personalDetails.firstName ?? '',
    lastName: personalDetails.lastName ?? '',
    email: personalDetails.email ?? '',
    phoneNumber: personalDetails.phoneNumber ?? '',
    title: personalDetails.title,
    birthDate: toDateOrNull(personalDetails.birthDate),
    socialSecurityNumber: personalDetails.socialSecurityNumber,

    addressDetails: {
      address: personalDetails.address ?? '',
      secondaryAddress: personalDetails.secondaryAddress,
      city: personalDetails.city ?? '',
      state: personalDetails.state ?? '',
      zipCode: personalDetails.zipCode ?? '',
      county: personalDetails.county,
      apt: personalDetails.apt
    }
  }
}

export default parseBuyerDto
