import type { AddAccountPayload } from 'api/types/accounting.types'
import type { AccountFormData } from './types'

const serializeAccountForm = (formData: AccountFormData): Omit<AddAccountPayload, 'id'> => {
  return {
    name: formData.name ?? '',
    number: formData.number ?? null,
    accountCategory: formData.accountCategory?.id ?? null,
    accountType: formData.accountType?.id ?? null,
    description: formData.description ?? null
  }
}

export default serializeAccountForm
