import type { FC, MouseEvent } from 'react'
import { cn } from '@carfluent/common'

import iconChopDelete from 'assets/chip_delete.svg'
import iconMoreVertical from 'assets/more_vertical.svg'
import iconNext from 'assets/next.svg'
import iconPrev from 'assets/prev.svg'
import iconCrownYellow from 'assets/crown_yellow.svg'
import iconEditPen from 'assets/edit.svg'
import iconPlus from 'assets/plus_dark.svg'
import iconSearch from 'assets/search_icon.svg'
import iconArrowBottom from 'assets/arrow_bottom.svg'
import iconArrowBottomWhite from 'assets/arrow_bottom_white.svg'
import iconPlusRounded from 'assets/black_plus_rounded.svg'
import iconCalendar from 'assets/calendar.svg'
import infoIcon from 'assets/info_black.svg'
import iconClose from 'assets/delete_icon.svg'
import iconDrag from 'assets/drag.svg'
import iconLocked from 'assets/lock-grey.svg'
import iconUnLocked from 'assets/lockIcon.svg'

export const ICONS_URLS = {
  arrowBottomWhite: iconArrowBottomWhite,
  arrowBottom: iconArrowBottom,
  chipDelete: iconChopDelete,
  crownYellow: iconCrownYellow,
  moreVertical: iconMoreVertical,
  next: iconNext,
  previous: iconPrev,
  editPen: iconEditPen,
  plus: iconPlus,
  roundedPlus: iconPlusRounded,
  searchIcon: iconSearch,
  calendarIcon: iconCalendar,
  infoIcon,
  close: iconClose,
  drag: iconDrag,
  locked: iconLocked,
  unlocked: iconUnLocked
} as const

export type IconName = keyof typeof ICONS_URLS

export interface IconProps {
  alt?: string
  className?: string
  name: IconName
  onClick?: (e: MouseEvent<HTMLElement>) => void
}

const Icon: FC<IconProps> = ({ alt, className, name, onClick }) => {
  const iconUrl = ICONS_URLS[name]

  if (iconUrl == null) {
    return null
  }

  return (
    <img
      alt={alt ?? name}
      className={cn('cf-icon', className)}
      onClick={onClick}
      src={iconUrl}
    />
  )
}

export default Icon
