import { useCallback } from 'react'

import type { AccountListItem, PaginatedResult } from 'api/types'
import useAccountCategories from 'hooks/accounting/useAccountCategories'
import useAccountTypes from 'hooks/accounting/useAccountTypes'

export type LoaderFn<TPayload, TResult> = (payload: TPayload) => Promise<TResult>
export type AccountsList<TResult> = PaginatedResult<Account<TResult>>
export type Account<TItem> = AccountWithDisplayableHierarchy<TItem>

export type AccountWithDisplayableHierarchy<TItem> = TItem & {
  accountCategoryName: string | null
  accountTypeName: string | null
}

export interface UseAccountsListProps<TPayload, TResult> {
  getData: LoaderFn<TPayload, PaginatedResult<TResult>>
}

export interface UseAccountsListReturn<TPayload, TResult> {
  getAccounts: LoaderFn<TPayload, AccountsList<TResult>>
}

function useAccountsList<TPayload, TResult extends AccountListItem> ({
  getData
}: UseAccountsListProps<TPayload, TResult>): UseAccountsListReturn<TPayload, TResult> {
  const { getCategories } = useAccountCategories(true)
  const { getTypes } = useAccountTypes(true)

  const getAccounts = useCallback(async (payload: TPayload): Promise<AccountsList<TResult>> => {
    try {
      const [data, categories, types] = await Promise.all([
        getData(payload),
        getCategories(),
        getTypes()
      ])

      const rows = data.items.map(({
        accountCategory,
        accountType,
        ...rest
      }: TResult): Account<TResult> => {
        const accountCategoryName = accountCategory != null ? categories.get(accountCategory) : null
        const accountTypeName = accountType != null ? types.get(accountType) : null

        const result = {
          ...rest,
          accountCategory,
          accountCategoryName,
          accountType,
          accountTypeName
        }

        return result as Account<TResult>
      })

      return {
        ...data,
        items: rows
      }
    } catch (err) {
      return { items: [], count: 0 }
    }
  }, [getData, getCategories, getTypes])

  return {
    getAccounts
  }
}

export default useAccountsList
