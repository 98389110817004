import { type FC, useCallback } from 'react'
import { Dropdown2 } from '@carfluent/common'

import type { ListPayload, VehicleList } from 'api/types'
import { VehiclesApiProvider } from 'api/vehicles.api'
import { GET_DEFAULT_VEHICLES_SORTING } from 'api/defaults'
import { namifyVehicle } from 'utils/vehicleDropdownHelpers'
import { renderNoVehiclesOption, renderVehicleOption } from 'components/optionRenderers/vehiclesDropdown'
import IconSVG from 'components/inlineImages'

import { CLASS_NAME, POPOVER_CLASS_NAME } from './styles'
import { VehicleOption } from 'components/common/VehiclesDropdownOption/types'

export interface CarsListProps {
  onCarSelected: (id: number) => void
}

const CarsList: FC<CarsListProps> = ({ onCarSelected: _onCarSelected }) => {
  const onCarSelected = useCallback((_, item) => {
    if (item != null) {
      _onCarSelected(item.id)
    }
  }, [_onCarSelected])

  const getVehicles = useCallback(async (payload: ListPayload): Promise<VehicleList> => {
    try {
      const response = await VehiclesApiProvider.getVehicles({ ...payload, ...GET_DEFAULT_VEHICLES_SORTING() })
      const items = response.items.map(namifyVehicle)
      return { ...response, items }
    } catch (err) {
      return { items: [], count: 0 }
    }
  }, [])

  // ========================================== //

  return (
    <Dropdown2<VehicleOption>
      id='cars-list'
      className={CLASS_NAME}
      placeholder='Search by VIN, make, stock #'
      startAdornment={<span> <IconSVG.SearchIcon /> </span>}
      isOpen
      fetchHandler={getVehicles}
      onChange={onCarSelected}
      renderNoOptions={renderNoVehiclesOption}
      renderOption={renderVehicleOption}
      popoverClassName={POPOVER_CLASS_NAME}
    />
  )
}

export default CarsList
