
import { type SortingInfo } from '@carfluent/common'
import { VehicleState, VehicleCounters } from 'api/types'

export const COUNTER_MAP = {
  [VehicleState.Sold]: 'sold',
  [VehicleState.Active]: 'active',
  [VehicleState.Inactive]: 'inactive',
  [VehicleState.Deleted]: 'deleted'
}

export const getDefaultCounters = (): VehicleCounters => ({
  active: 0,
  inactive: 0,
  total: 0,
  sold: 0,
  deleted: 0
})

export const DEFAULT_SORTING: SortingInfo = { id: 'make', order: 'asc' }
