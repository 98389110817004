import { type FC, type ReactNode } from 'react'
import { cnx, Button, FormDropdown, PopoverV2 } from '@carfluent/common'
import type { Item } from '@carfluent/common/dist/UI/Dropdown/types'

import { type Vehicle, VehicleState } from 'api/types'
import type { TogglableContentProps } from 'components/wysiwyg/toolbar/TogglableButton'
import type { VehicleOption } from 'components/common/VehiclesDropdownOption/types'
import { renderNoVehiclesOption, renderVehicleOption } from 'components/optionRenderers/vehiclesDropdown'
import IconSVG from 'components/inlineImages'

import { optionTextMap, GroupCarIds } from './constants'
import useAddCarDropdown from './hook'
import type { UseAddCarDropdownProps } from './types'

import { CLASS_NAME, POPOVER_CLASS_NAME, DROPDOWN_POPOVER_CLASS_NAME } from './styles'

export type AddCarDropdownProps = UseAddCarDropdownProps & TogglableContentProps

const POPOVER_OPTIONS = { modifiers: [{ name: 'offset', options: { offset: [-140, 0] } }] }

const AddCarDropdown: FC<AddCarDropdownProps> = ({ label, ...props }) => {
  const {
    isOpen,
    refAnchorEl,
    refDropdownContent,
    onChange,
    onClose,
    onAddCarClick,
    getVehicles
  } = useAddCarDropdown(props)

  return (
    <div className={cnx(CLASS_NAME, 'togglable-toolbar-button')}>
      <Button
        onClick={onAddCarClick}
        color='secondary'
        variant='outlined'
        endAdornment={<IconSVG.DropdownArrowDown />}
      >
        {label}
      </Button>

      <PopoverV2
        anchorEl={refAnchorEl}
        options={POPOVER_OPTIONS}
        className={POPOVER_CLASS_NAME}
        isOpen={isOpen}
        onClose={onClose}
        outsideClickConfig={{ excludedElemRefs: [refDropdownContent] }}
      >
        <FormDropdown<Vehicle>
          className='vehicles-dropdown'
          id='editorCarsList'
          placeholder='Search by VIN, make, stock #'
          startAdornment={<span> <IconSVG.SearchIcon /> </span>}
          isOpen={isOpen}
          value={null}
          fetchHandler={getVehicles}
          onChange={onChange}
          renderNoOptions={renderNoVehiclesOption}
          renderOption={renderOption}
          isOptionDisabled={getOptionDisabled}
          popoverClassName={cnx(DROPDOWN_POPOVER_CLASS_NAME, 'add-car-dropdown-popover')}
        />
      </PopoverV2>
    </div>
  )
}

export default AddCarDropdown

const renderOption = (o: VehicleOption): ReactNode => {
  const text = optionTextMap[o.id as GroupCarIds]

  const noOptionsClass = (o.id === GroupCarIds.NoCarOfInterest ||
    o.id === GroupCarIds.NoSuggestedCars)
    ? 'no-options'
    : ''

  return text != null
    ? <div className={`option-render group-name ${noOptionsClass}`}>{text}</div>
    : renderVehicleOption(o)
}

const getOptionDisabled = ({ value }: Item<VehicleOption>): boolean =>
  (value.id === GroupCarIds.NoCarOfInterest) ||
  (value.id === GroupCarIds.NoSuggestedCars) ||
  (value.vehicleState !== VehicleState.Active)
