import type { FC } from 'react'
import { FormInput, cn } from '@carfluent/common'

import TooltipedText from 'components/common/TooltipedText'
import type { ReconStepsTableCellProps } from 'pages/reconditioning/Settings/Steps/components/ReconStepsTable/types'
import { MAX_NAME_LENGTH } from 'pages/reconditioning/Settings/Steps/hook/constants'

import CLASS_NAME from './styles'

const ReconStepsTableTextCell: FC<ReconStepsTableCellProps> = ({
  isEditMode,
  id,
  value,
  error,
  touched,
  disabled,
  onChange,
  onBlur
}) => {
  return (
    <div className={cn(CLASS_NAME, isEditMode && 'cf-edit-mode-view')}>
      {isEditMode
        ? (
          <FormInput
            className='cf-form-text-input'
            dataTestId={`form-input-${id}`}
            id={id}
            value={value?.toString() ?? ''}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            touched={touched}
            disabled={disabled ?? false}
            maxLength={MAX_NAME_LENGTH + 1}
          />
          )
        : <TooltipedText rootClassName='cf-text' value={value?.toString() ?? ''} />}
    </div>
  )
}

export default ReconStepsTableTextCell
