import type { CoverageTerm, CoverageWithTerms } from 'types/coverage'

const findCoverage = (
  termMiles: number | undefined | null,
  termMonths: number | undefined | null,
  coverages: CoverageWithTerms | null
): CoverageTerm | null => {
  return coverages?.termList.find(el => (el.termMiles === termMiles) && (el.termMonths === termMonths)) ?? null
}

export default findCoverage
