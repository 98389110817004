import { css } from '@emotion/css'

export default css(`  
  &.cell-reconcile-status.cf-cell-wrapper {
    padding-top: 4px;
    padding-bottom: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .tooltip-content {
      display: flex;
      gap: 4px;
      border-radius: 4px;
      padding: 8px;
    } 
  }
  
  &.is-toggle-button {
    .tooltip-content {
      &:hover {
        background-color: rgba(243, 243, 243, 1);
      }
    }
    
    &.is-reconciled {
      .tooltip-content {
        &:hover {
          background-color: #FFE1AB;
        }
      }
    }
  }  

`)
