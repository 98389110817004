import { type ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'

import { type UseFormReturn, useForm } from '@carfluent/common'

import PromosApiProvider from 'api/promos.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { type WebsiteSettingsModel } from 'api/types/responses'
import { GET_DEFAULT_FORM_DATA } from 'api/defaults/websiteSettings'

import useImageUpload from './useImageUpload'
import { serializeWebsiteSettings } from './serializer'
import { DEPENDENT_VALIDATIONS, validationRules } from './validation'
import { PickerFields } from './constants'

const DEFAULT_FORM_DATA = GET_DEFAULT_FORM_DATA()

export interface UseMarketplacePromoReturn extends Pick<UseFormReturn<WebsiteSettingsModel>, PickerFields>{
  isChanged: boolean
  isLoading: boolean
  heroImage: string | null
  isUploadFilesTouched: boolean
  onChange: (id: string, value: unknown) => void
  resetForm: (shouldResetUploadFile?: boolean) => void
  onChangeCashDownDollar: (id: string, value: number) => void
  onChangeCashDownPercent: (id: string, value: number) => void
  onUploadFiles: (filesToUpload: ChangeEvent<HTMLInputElement>) => void
}

const useMarketplacePromo = (): UseMarketplacePromoReturn => {
  const [isLoading, setIsLoading] = useState(false)
  const refOriginalData = useRef<WebsiteSettingsModel>(DEFAULT_FORM_DATA)
  const { showAlert } = useCustomSnackbar()

  const { onDiscardChanges, isUploadFilesTouched, onSaveHeroImage, previewUrl, onFileChange } = useImageUpload()

  const submitAction = useCallback(async (values: WebsiteSettingsModel) => {
    try {
      const serializedData = serializeWebsiteSettings(values)
      await onSaveHeroImage()
      const res = await PromosApiProvider.updatePaymentSettings(serializedData)
      refOriginalData.current = res
    } catch {
      throw new Error('Error update website settings')
    }
  }, [onSaveHeroImage])

  const {
    values,
    errors,
    touched,
    onBlur,
    isValid,
    setValues,
    hasChanges,
    validateForm,
    setFieldTouched,
    onChange: _onChange,
    resetForm: _resetForm,
    onSubmit
  } = useForm<WebsiteSettingsModel>({
    baseValues: DEFAULT_FORM_DATA,
    validationRules,
    isTrackingChanges: true,
    submitAction,
    dependentValidations: DEPENDENT_VALIDATIONS
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onChange = (id: string, value: unknown): void => {
    _onChange(id, value)

    // hide showing error on toggling section
    switch (id) {
      case 'isEstimatedMonthlyPaymentEnabled':
        setFieldTouched('apr', false)
        setFieldTouched('term', false)
        setFieldTouched('cashDownAmount', false)
        setFieldTouched('cashDownPercentage', false)
        break

      default:
    }
  }

  const onUploadFiles = (files: ChangeEvent<HTMLInputElement>): void => {
    onFileChange(files)
  }

  const onChangeCashDownDollar = (id: string, value: number | null): void => {
    onChange(id, value)
    onChange('cashDownPercentage', null)
  }

  const onChangeCashDownPercent = (id: string, value: number | null): void => {
    onChange(id, value)
    onChange('cashDownAmount', null)
  }

  const resetForm = (shouldResetUploadFile = true): void => {
    onDiscardChanges(shouldResetUploadFile)
    _resetForm(refOriginalData.current)
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      setIsLoading(true)
      try {
        const res = await PromosApiProvider.getWebsiteSettings()
        setValues(res)
        refOriginalData.current = res
      } catch (e) {
        showAlert(e)
      } finally {
        setIsLoading(false)
      }
    }

    void runEffect()
  }, [setValues, showAlert])

  // ========================================== //

  return {
    values,
    errors,
    onBlur,
    touched,
    isValid,
    onSubmit,
    onChange,
    resetForm,
    isLoading,
    validateForm,
    onUploadFiles,
    isUploadFilesTouched,
    heroImage: previewUrl,
    isChanged: hasChanges,
    onChangeCashDownDollar,
    onChangeCashDownPercent
  }
}

export default useMarketplacePromo
