import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { RecipientsFilter } from 'api/types/responses'
import { type FilterChipsProps } from './types'
import CLASS_NAME from './styles'

const FilterChips: FC<FilterChipsProps> = ({
  className,
  filter,
  options: _options = []
}) => {
  if ((filter == null) || (_options == null)) {
    return (
      <div className={cnx(CLASS_NAME, className)} />
    )
  }

  const options = _options.length > 0 ? _options : [filter]

  const chipTypeClass = filter.id === RecipientsFilter.Excluded
    ? 'chip-excluded'
    : 'chip-included'

  return (
    <div className={cnx(CLASS_NAME, className)}>
      {options.map(item => {
        return (
          <div key={item.id} className={cnx('filter-chip', chipTypeClass)}>
            {item.name}
          </div>
        )
      })}
    </div>
  )
}

export default FilterChips
