import { css } from '@emotion/css'

export default css(`
  .title-section-skeleton {
    margin-bottom: 16px;

    .MuiSkeleton-root {
      &:first-of-type {
        width: 200px;
        margin-bottom: 8px;
      }

      &:last-of-type {
        width: 600px
      }
    }
  }

  .home-page-hero-section-skeleton {
    margin-bottom: 32px;

    .home-page-hero-section-skeleton {
      height: 264px;
    }

    .home-page-heading-section-skeleton {
      display: flex;
      margin-top: 16px;

      .MuiSkeleton-root {
        height: 56px;

        &:first-of-type {
          margin-right: 16px;
        }
      }
    }
  }

  .home-page-ads-wrapper-section-skeleton {
    display: flex;

    .home-page-ads-container-section-skeleton {
      flex: 1;
      border-radius: 12px;
      padding: 16px 16px 24px 16px;
      border: 1px solid #00000012;

      .MuiSkeleton-root {
        height: 56px;
        margin-bottom: 16px;

        &:first-of-type {
          height: 68px;
        }

        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }
  }

  .home-page-monthly-payment-section-skeleton {
    margin: 32px 0;

    .MuiSkeleton-root {
      width: 200px;
    }

    > div {
      display: flex;
      margin-top: 16px;

      .MuiSkeleton-root {
        width: 20%;
        height: 56px;
        margin-right: 16px;
      }
    }
  }

  .home-page-gallery-section-skeleton {
    .MuiSkeleton-root {
      width: 200px;
      margin-bottom: 16px;

      &:nth-of-type(2) {
        width: 20%;
        height: 56px;
      }

      &:last-of-type {
        width: 100%;
        height: 236px;
      }
    }
  }
`)
