import { parsers } from '@carfluent/common'

import type {
  AccountListItem,
  TransactionsReport as ApiTransactionsReport,
  TransactionsReportPayload,
  TransactionsReportTransaction as ApiReportTransaction
} from 'api/types/accounting.types'
import { Period, serializeDateFiltersLocal, serializeLocalDate } from 'utils/accounting'
import serializePeriodLocal from 'utils/filters/serializePeriodLocal'

const { parseDateStringUTC } = parsers

/**
 * For building xslx files we should send all dates in local timezone,
 * since API endpoint doesn't know user's tz when builds xslx report.
 */
export const serializeReportForXslx = (report: ApiTransactionsReport): ApiTransactionsReport => {
  const dates = serializeDateFiltersLocal({
    from: parseDateStringUTC(report.dateFrom),
    to: parseDateStringUTC(report.dateTo)
  })

  return {
    ...report,
    transactions: serializeTransactionsForXslx(report.transactions),
    dateFrom: dates?.from ?? null,
    dateTo: dates?.to ?? null,
    dateIssue: serializeLocalDate(new Date()) ?? ''
  }
}

export const serializeTransactionsForXslx = (transactions: ApiReportTransaction[]): ApiReportTransaction[] => {
  return transactions.map(t => ({
    ...t,
    date: serializeLocalDate(parseDateStringUTC(t.date)) ?? ''
  }))
}

export interface SerializeFiltersProps {
  account: AccountListItem | null
  period: Period
}

export const serializeFilters = ({ account, period }: SerializeFiltersProps): TransactionsReportPayload => {
  return {
    ...serializePeriodLocal(period),
    accountId: typeof account?.id === 'number' ? account.id : null
  }
}
