import { type FC } from 'react'
import { FormDropdown, type UseFormReturn, type Dropdown2Props, FormDatePicker } from '@carfluent/common'
import startOfToday from 'date-fns/startOfToday'

import { type ListResponse } from 'api/types'
import {
  type BaseListItem,
  type TransactionFormData,
  type TransactionFormErrTouchShortcuts
} from 'types'

interface RecurringLineProps extends UseFormReturn<TransactionFormData, TransactionFormErrTouchShortcuts> {
  getRecurringIntervals: () => Promise<ListResponse<BaseListItem>>
  isReadOnly: boolean
  onRecurringIntervalChange: Dropdown2Props<BaseListItem, false>['onChange']
}

const RecurringLine: FC<RecurringLineProps> = ({
  values,
  errors,
  isReadOnly,
  touched,
  getRecurringIntervals,
  onBlur,
  onRecurringIntervalChange,
  onChange
}) => {
  const today = startOfToday()

  return (
    <>
      <FormDropdown
        className='field-receivable-dropdown'
        dataTestId='recurringInterval'
        disabled={values.isLocked}
        error={errors.recurringInterval}
        fetchHandler={getRecurringIntervals}
        id='recurringInterval'
        label='Repeats'
        mode='select'
        onBlur={onBlur}
        onChange={onRecurringIntervalChange}
        renderOption={(option) => <div className='option-item'>{option.name}</div>}
        renderNoOptions={() => <div className='no-options'>No options found</div>}
        touched={touched.recurringInterval}
        value={values.recurringInterval}
        errorMessage={errors.recurringInterval}
      />

      <FormDatePicker
        className='field-datetime'
        id='startDate'
        dataTestId='startDate'
        label='Start Date'
        onBlur={onBlur}
        onChange={onChange}
        disabled={isReadOnly || values.isLocked}
        minDate={today}
        value={values.startDate ?? null}
        error={errors.startDate}
        touched={touched.startDate}
        helper={values.startDate != null ? 'Transactions will be created at 5:00 AM' : null}
      />

      <FormDatePicker
        className='field-datetime'
        id='endDate'
        label='End Date'
        dataTestId='endDate'
        onBlur={onBlur}
        onChange={onChange}
        minDate={today}
        disabled={isReadOnly || values.isLocked}
        value={values.endDate ?? null}
        error={errors.endDate}
        touched={touched.endDate}
      />
    </>
  )
}

export default RecurringLine
