import { RegistrationStage, TitleStage } from 'types/enums'
import type { DealerAccount } from './dealers.types'
import type { DictionaryItems, EntityDictionary, PaginationOptions, SortOrder } from './utility.types'

export enum VehicleState {
  Active = 1,
  Inactive,
  Sold,
  Deleted
}

export enum VehicleStatus {
  PurchaseAction = 1,
  Recon,
  ReadyForSale = 3,
}

export type CountersNames = 'total' | 'active' | 'inactive' | 'sold' | 'deleted'
export type VehicleCounters = Record<CountersNames, number>

export type RequestVehicleId = number | string

export interface VehicleListCountersPayload {
  search: string | null
  tagIds?: number[] | null
  isDeposited?: boolean | null
  isPhotoPresent?: boolean | null
}

export interface VehicleListPayload {
  skip?: number
  take?: number
  search?: string | null
  tagIds?: VehicleState[] | null
  vehicleStateIds?: VehicleState[]
  sortField?: string
  sortOrder?: SortOrder
  isDeposited?: boolean | null
  isPhotoPresent?: boolean | null
}

export interface VehicleRepricingDetails {
  minPrice: number | null
  dayNumber: number | null
  priceDeduction: number | null
  history: VehicleRepricingHistoryDto[]
  nextPriceDropInDays: number
  isEnabled: boolean
}

// Vehicle for table item
export interface Vehicle {
  engine: string | null
  genericColor: string | null
  genericInterior: string | null
  id: number
  fuelType: string | null
  vin: string | null
  year: number | null
  make: string | null
  model: string | null
  trim: string | null
  mileage: number | null
  stock: string | null
  age: number
  salePrice: number | null
  mainImageUrl: string | null
  imageCount: number
  tags: number[]
  vehicleState: VehicleState
  transmissionType: string | null
  isDeposited: boolean
  vehicleStatus: VehicleStatus | null
  soldDealId?: number | null
  description?: string
  viewsCount: number | null
  leadsCount: number | null
  source: string | null
}

export interface VehicleList {
  items: Vehicle[]
  count: number
}

export interface VehicleDetails {
  year: number | null
  make: string | null
  model: string | null
  trim: string | null
  style: string | null
  type: string | null
  size: string | null
  category: string | null
  madeIn: string | null
  madeInCity: string | null
  doors: string | null
  fuelType: string | null
  fuelCapacity: string | null
  cityMileage: string | null
  highwayMileage: string | null
  engine: string | null
  engineSize: string | null
  engineCylinders: string | null
  transmission: string | null
  transmissionShort: string | null
  transmissionType: string | null
  transmissionSpeeds: string | null
  drivetrain: string | null
  antiBrakeSystem: string | null
  steeringType: string | null
  curbWeight: string | null
  grossVehicleWeightRating: string | null
  overallHeight: string | null
  overallLength: string | null
  overallWidth: string | null
  wheelbaseLength: string | null
  standardSeating: string | null
  invoicePrice: string | null
  deliveryCharges: string | null
  manufacturerSuggestedRetailPrice: string | null
  productionSeqNumber: string | null
  frontBrakeType: string | null
  rearBrakeType: string | null
  turningDiameter: string | null
  frontSuspension: string | null
  rearSuspension: string | null
  frontSpringType: string | null
  rearSpringType: string | null
  tires: string | null
  frontHeadroom: string | null
  rearHeadroom: string | null
  frontLegroom: string | null
  rearLegroom: string | null
  frontShoulderRoom: string | null
  rearShoulderRoom: string | null
  frontHipRoom: string | null
  rearHipRoom: string | null
  interiorTrim: string[] | null
  exteriorColor: string[] | null
  curbWeightManual: string | null
  groundClearance: string | null
  trackFront: string | null
  trackRear: string | null
  cargoLength: string | null
  widthAtWheelwell: string | null
  widthAtWall: string | null
  depth: string | null
  optionalSeating: string | null
  passengerVolume: string | null
  cargoVolume: string | null
  standardTowing: string | null
  maximumTowing: string | null
  standardPayload: string | null
  maximumPayload: string | null
  maximumGvwr: string | null
}

export interface VehicleDetailsByVin {
  year: number
  make: string | null
  model: string | null
  trim: string | null
  bodyType: number | null
  bodyTypeString: string | null
  engine: string | null
  doorCount: number | null
  fuelType: number | null
  fuelTypeString: string | null
  cylinderCount: number | null
  transmissionType: number | null
  transmissionTypeString: string | null
  drivetrainType: number | null
  drivetrainTypeString: string | null
  genericColor: number | null
  genericColorString: string | null
  genericInterior: number | null
  genericInteriorString: string
  trims: string[] | null
}

export interface VehicleFeature {
  id: number
  vehicleId: number
  key: string
  sectionId: string
  subSectionId: number
  sectionName: string
  name: string
  nameNoBrand: string
  description: string
  rankingValue: number
  isHybridFeature: boolean
  isEVFeature: boolean
  isEnabled: boolean
}

export interface VehicleOption {
  id: number
  vehicleId: number
  optionCode: string
  group: string
  isChromeCode: boolean
  isStandard: boolean
  msrp: number
  optionDescription: string
  installCause: string
  isEnabled: boolean
}

export interface VehicleDocumentDto {
  id: number
  fileName: string
  fileId: number
  createdDate: string
  createdBy: string
}

export interface VehicleDocumentPatchRequestDto {
  fileName: string
  fileId: number
}

// Vehicle for view/edit page
export interface VehicleById extends Omit<
Vehicle,
| 'transmissionType'
| 'fuelType'
| 'genericColor'
| 'genericInterior'
| 'description'
| 'viewsCount'
| 'leadsCount'
| 'source'
> {
  inventoryDate: string | null
  titleType: number | null
  titleReceived: string | null
  titleSent: string | null
  titleStateId: number | null
  titleNumber: string | null
  bodyType: number | null
  engine: string | null
  doorCount: number | null
  fuelType: number | null
  cylinderCount: number | null
  transmissionType: number | null
  drivetrainType: number | null
  genericColor: number | null
  genericInterior: number | null
  isDisabilityEquipped: boolean
  notes: string | null
  description: string | null
  descriptionText: string | null
  isVehicleInspected: boolean
  rowVersion: string | null
  vehicleImages: VehicleImage[]
  vehiclePrices: VehiclePrices[]
  isTitleReceived: boolean
  source: string
  options?: Record<string, VehicleOption[]>
  features?: Record<string, VehicleFeature[]>
  vehicleRepricingDetails: VehicleRepricingDetails | null
  isRepricingActive: boolean
  isRepricingEnabled: boolean
  buyer: DealerAccount | null
  viewsCount: number
  activationDate: Date | null
  titleStageId: number | null
  registrationStageId: number | null
  titleNote: string | null
  vehicleDocuments: VehicleDocumentDto[]
  soldDate: string | null
  deletedDate: string | null
  soldByUser: {
    id: number
    dealerFirstName: string
    dealerLastName: string
  } | null
  deletedByUser: {
    id: number
    dealerFirstName: string
    dealerLastName: string
  } | null
}

/**
 * Remove the buyer field since it is only needed for the
 * view/edit form, but in the patch we need to send the buyerId instead.
 */
export interface VehicleByIdPatch extends Omit<VehicleById, 'buyer' | 'titleStage' | 'registrationStage'> {
  buyerId: number
  titleStageId: number | null
  registrationStageId: number | null
}

export interface VehiclePrices {
  salePrice: number
  createdByUser: {
    id: number
    dealerFirstName: string
    dealerLastName: string
  }
  createdDate: string
  leadAmount?: number
}

export interface VehicleRepricingHistoryDto {
  id: number
  isEnabled: boolean
  createdByUser: {
    dealerFirstName: string
    dealerLastName: string
  }
  modifiedByUser: {
    dealerFirstName: string
    dealerLastName: string
  } | null
  activationChangeDate: string
  dayNumber: number | null
  priceDeduction: number | null
}

export enum VehicleMediaTypeId {
  Image = 1,
  Video = 2
}

export interface VehicleImage {
  originalUrl: string
  thumbnailUrl?: string
  order: number
  fileName: string
  originalSizeKb: number
  id?: string
  fileId?: number | null
  vehicleId?: number | null
  vehicleMediaTypeId: VehicleMediaTypeId.Image | VehicleMediaTypeId.Video
}

export interface SuggestedVehicles extends PaginationOptions {
  vehicleId: number | string | null
}

export interface GetStockNumber {
  stockNumber: string
}

export enum TitleTypes {
  Clear = 'Clear',
  FloodWaterDamage = 'FloodWaterDamage',
  LemnAndManufacturerBuyback = 'LemnAndManufacturerBuyback',
  RebuiltRebuildableAndReconstructed = 'RebuiltRebuildableAndReconstructed',
  Salvage = 'Salvage',
  Other = 'Other'
}

export enum BodyTypes {
  Convertible = 'Convertible',
  Coupe = 'Coupe',
  Crossover = 'Crossover',
  Hatchback = 'Hatchback',
  MinivanVan = 'MinivanVan',
  PickupTruck = 'PickupTruck',
  Suv = 'Suv',
  Sedan = 'Sedan',
  Wagon = 'Wagon',
  Other = 'Other'
}

export enum CylinderCountsTypes {
  Two = 'Two',
  Three = 'Three',
  Four = 'Four',
  Five = 'Five',
  Six = 'Six',
  Eight = 'Eight',
  Ten = 'Ten',
  Twelve = 'Twelve',
  Unspecified = 'Unspecified',
  Other = 'Other'
}

export enum DoorCountsTypes {
  Two = 'Two',
  Three = 'Three',
  Four = 'Four',
  FiveOrMore = 'FiveOrMore'
}

export enum DriveTrainTypes {
  AllWheelDrive = 'All Wheel Drive',
  FourWheelDrive = 'Four Wheel Drive',
  FrontWheelDrive = 'Front Wheel Drive',
  RearWheelDrive = 'Rear Wheel Drive'
}

export enum FuelTypes {
  Biodiesel = 'Biodiesel',
  CNG = 'CNG',
  Diesel = 'Diesel',
  Electric = 'Electric',
  EthanolFFV = 'EthanolFFV',
  Gas = 'Gas',
  HybridElectric = 'HybridElectric',
  Steam = 'Steam'
}

export enum GenericColorsTypes {
  Black = 'Black',
  Blue = 'Blue',
  Brown = 'Brown',
  Burgundy = 'Burgundy',
  Gold = 'Gold',
  Gray = 'Gray',
  Green = 'Green',
  Orange = 'Orange',
  Purple = 'Purple',
  Red = 'Red',
  Silver = 'Silver',
  Tan = 'Tan',
  Teal = 'Teal',
  White = 'White',
  Yellow = 'Yellow',
  Other = 'Other'
}

export enum GenericInteriorsTypes {
  Black = 'Black',
  Blue = 'Blue',
  Brown = 'Brown',
  Burgundy = 'Burgundy',
  Gold = 'Gold',
  Gray = 'Gray',
  Green = 'Green',
  Red = 'Red',
  Tan = 'Tan',
  Teal = 'Teal',
  White = 'White',
  Other = 'Other'
}

export enum TransmissionTypes {
  Automatic = 'Automatic',
  DirectDrive = 'DirectDrive',
  Manual = 'Manual',
  PowerShift = 'PowerShift',
  Unspecified = 'Unspecified',
  Other = 'Other'
}

export enum DisabilityEquipped {
  Yes = 'Yes',
  No = 'No'
}

export enum Dropdowns {
  Body = 'Body',
  Doors = 'Doors',
  Fuel = 'Fuel',
  Cylinders = 'Cylinders',
  Transmission = 'Transmission',
  Drivetrain = 'Drivetrain',
  GenericColor = 'GenericColor',
  GenericInterior = 'GenericInterior',
  DisabilityEquipped = 'DisabilityEquipped',
  TitleStatus = 'TitleStatus',
  InventoryState = 'InventoryState',
  Tags = 'Tags',
  Make = 'Make',
  TitleStage = 'TitleStage',
  RegistrationStage = 'RegistrationStage'
}

export interface VehiclePartsDictionaries {
  [Dropdowns.Body]: DictionaryItems<BodyTypes>
  [Dropdowns.Cylinders]: DictionaryItems<CylinderCountsTypes>
  [Dropdowns.Doors]: DictionaryItems<DoorCountsTypes>
  [Dropdowns.Drivetrain]: DictionaryItems<DriveTrainTypes>
  [Dropdowns.Fuel]: DictionaryItems<FuelTypes>
  [Dropdowns.GenericColor]: DictionaryItems<GenericColorsTypes>
  [Dropdowns.GenericInterior]: DictionaryItems<GenericInteriorsTypes>
  [Dropdowns.Transmission]: DictionaryItems<TransmissionTypes>
  [Dropdowns.DisabilityEquipped]: DictionaryItems<DisabilityEquipped>
  [Dropdowns.Make]?: DictionaryItems<string>
}

export type GetTitleTypes = EntityDictionary<TitleTypes>
export type GetBodyTypes = EntityDictionary<BodyTypes>
export type GetCylinderCountsTypes = EntityDictionary<CylinderCountsTypes>
export type GetDoorCountsTypes = EntityDictionary<DoorCountsTypes>
export type GetDriveTrainTypes = EntityDictionary<DriveTrainTypes>
export type GetFuelTypes = EntityDictionary<FuelTypes>
export type GetGenericColorsTypes = EntityDictionary<GenericColorsTypes>
export type GetGenericInteriorsTypes = EntityDictionary<GenericInteriorsTypes>
export type GetTransmissionTypes = EntityDictionary<TransmissionTypes>
export type GetInventoryStates = EntityDictionary<string>
export type GetTags = EntityDictionary<string>
export type GetInventoryMakes = EntityDictionary<string>
export type GetTitleStagesDto = EntityDictionary<TitleStage>
export type GetRegistrationStageDto = EntityDictionary<RegistrationStage>

export interface VehicleNotesUpdate {
  id: string | number
  notes: string
}

export interface TitleReportItemGetDto {
  vehicleId: number
  stock: string
  vin: string
  make: string
  model: string
  trim: string
  year?: number
  source: string
  vehicleCustomerFullName: string
  inventoryDate: string
  age: number
  daysPendingRegistration: number
  titleStageId: number
  registrationStageId: number
  titleNote: string
  soldDealId: number
}

interface RegistrationStageFilterDto {
  noRegistrationStage?: boolean
  registrationStageIds?: Array<number | null>
}

export interface TitleReportsRequestDto {
  skip?: number
  take?: number
  search?: string | null
  sortField?: string
  sortOrder?: SortOrder
  titleStageIds?: Array<number | null>
  registrationStageFilter?: RegistrationStageFilterDto
}

export interface CarRepricingReportsRequestDto {
  search?: string | null
  sortField?: string
  sortOrder?: SortOrder
}

export interface VehicleStateResponse {
  id: number
  vehicleState: VehicleState
  changedDate: string | null
  changedByUser: {
    id: number
    dealerFirstName: string
    dealerLastName: string
  } | null
}

export interface TitleReportDownloadRequestDto {
  search: string
  sortField: string
  sortOrder: SortOrder
}

export interface MakesAmountGetRequestDto {
  make: string
  amount: number
}

export interface MakesAmountRequestDto {
  search?: string
  tagIds?: number[]
  titleStageIds?: Array<number | null> | null
  isDeposited?: boolean
  isPhotoPresent?: boolean
  vehicleStateIds?: number[]
  registrationStageFilter?: RegistrationStageFilterDto
}
