import type { FC } from 'react'

import ImageItem, { type ImageDescriptor } from 'components/inventory/ImageItem'
import NoVehicleImagesText from 'components/inventory/NoVehicleImagesText'

import CLASS_NAME from './styles'

interface DisabledGalleryTabProps {
  images: ImageDescriptor[]
}

const DisabledGalleryTab: FC<DisabledGalleryTabProps> = ({ images }) => {
  if (images.length === 0) {
    return <NoVehicleImagesText />
  }

  return (
    <div className={CLASS_NAME}>
      {images.map((item) =>
        <ImageItem
          key={item.uniqueName ?? item.thumbnailUrl ?? item.originalUrl}
          image={item}
        />
      )}
    </div>
  )
}

export default DisabledGalleryTab
