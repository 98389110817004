import { css } from '@emotion/css'
import { LabelColors } from 'constants/constants'

export const LABEL_COMMON_CSS = `
  min-width: 34px;
  max-width: 34px;
  color: #FFF;
  text-align: center;
  border-radius: 2px;
  font-weight: 500;
  font-size: 10px;
  height: 16px;
  line-height: 18px;
  text-transform: uppercase;
  display: block;
`

export default css(`
  ${LABEL_COMMON_CSS}

  &.hot { background-color: ${LabelColors.Hot}; }

  &.warm { background-color: ${LabelColors.Warm}; }

  &.cold { background-color: ${LabelColors.Cold}; }
`)
