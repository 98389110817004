import React from 'react'
import { FormikValues } from 'formik'

import FormCTX, { FormCTXProps } from './context'
export type { FormCTXProps, UseFormikReturnType } from './context'
export { useField, useForm } from './hook'
export * from './components'

export interface FormProps<V extends FormikValues> extends FormCTXProps<V> {
  children: React.ReactNode
  className?: string
}

export function Form<V extends FormikValues = FormikValues> (props: FormProps<V>): JSX.Element {
  const { children, className } = props

  return (
    <form className={className}>
      <FormCTX.Provider value={props}>
        {children}
      </FormCTX.Provider>
    </form>
  )
}
