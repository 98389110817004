import type { FC } from 'react'
import { FormNumberInput, cn } from '@carfluent/common'

import { convertNumberToTimeForDisplay, limitMaxValueNumber } from 'pages/reconditioning/Settings/Steps/hook/utils'
import type { ReconStepsTableCellProps } from 'pages/reconditioning/Settings/Steps/components/ReconStepsTable/types'

import CLASS_NAME from './styles'

const ReconStepsTableNumberCell: FC<ReconStepsTableCellProps> = ({
  isEditMode,
  id,
  value,
  error,
  touched,
  disabled,
  onChange,
  onBlur
}) => {
  const displayValue = !isEditMode
    ? convertNumberToTimeForDisplay(limitMaxValueNumber(Number(value)))
    : value
  const isZero = Number(value) === 0

  return (
    <div className={cn(CLASS_NAME, isEditMode && 'cf-edit-mode-view')}>
      {isEditMode
        ? (
          <FormNumberInput
            className='cf-form-number-input'
            dataTestId={`form-input-${id}`}
            disabled={disabled ?? false}
            id={id}
            error={error}
            errorMessage={error}
            isNegativeAllowed={false}
            isEmptyAllowed={false}
            min={0}
            max={limitMaxValueNumber}
            decimalPrecision={2}
            thousandSeparator={null}
            touched={touched}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />)
        : (
          <p className={isZero ? 'cf-disabled-text' : ''}>
            {displayValue}
          </p>
          )}
    </div>
  )
}

export default ReconStepsTableNumberCell
