import { FC, useMemo } from 'react'
import { Banner, cnx } from '@carfluent/common'

import IconSVG from 'components/inlineImages'
import CLASS_NAME from './styles'
import { type ErrorsBlockProps, IconKeys } from './types'

const icons = {
  [IconKeys.IconSVGLock]: <IconSVG.Lock color='#B00020' width={24} height={24} opacity={1} />
}

const ErrorsBlock: FC<ErrorsBlockProps> = ({ errors, className }) => {
  const additionalProps = useMemo(() => {
    const [bannerPropName, iconKey] = errors?.iconProps ?? []

    return bannerPropName !== undefined && iconKey !== undefined
      ? {
          [bannerPropName]: icons[iconKey as IconKeys]
        }
      : {}
  }, [errors?.iconProps])
  const errMsgNodes = useMemo(() => {
    const hasKeyedErrors = Object.keys(errors?.errors ?? {}).length > 0

    return hasKeyedErrors
      ? Object.entries(errors?.errors).map(([fieldId, messages]) => (
        <p key={fieldId} className='cf-error-message-line'>
          <span>{fieldId}:</span>
          <span>{(Array.isArray(messages) ? messages : [messages]).join(' | ')}</span>
        </p>
      ))
      : errors?.message
  }, [errors])

  return (
    <Banner
      message={errMsgNodes}
      type='error'
      className={cnx(CLASS_NAME, className)}
      isOpen={errMsgNodes != null}
      {...additionalProps}
    />
  )
}

export default ErrorsBlock
