import { type FC } from 'react'
import { Skeleton } from '@material-ui/lab'
import { cnx } from '@carfluent/common'

import CLASS_NAME from './styles'

export interface TextSkeletonProps {
  className?: string
}

const TextSkeleton: FC<TextSkeletonProps> = ({ className }) => {
  return (
    <Skeleton className={cnx(CLASS_NAME, 'text-skeleton', className)} variant='text' />
  )
}

export default TextSkeleton
