import { css } from '@emotion/css'

export const MODAL_CLASS_NAME = css`
  &.cf-modal-container .cf-modal-content-scroll-wrapper .cf-modal-root {
    .cf-modal-title, .cf-modal-content {
      padding-left: 0;
      padding-right: 0;
      width: 900px;
      max-width: 900px;
    }
  }
`

export default css`
  .deal-form-subtitle {
    margin-bottom: 24px;
  }
`
