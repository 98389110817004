import type { UseFormReturn } from '@carfluent/common'
import type { CheckboxType as CheckboxValue } from 'types'
import type { AccountListItem } from 'api/types/accounting.types'
import type { DeleteModalProps } from 'components/dialogs/DeleteModal'
import type { UseDropdownReturn } from 'hooks/useDropdown'

export type PackCostProps = PackCostOwnProps

export interface PackCostOwnProps {
  lastResetTS: number | null
  lastTouchifyTS: number | null
  onDeletePackCost: () => Promise<void>
  setFieldValue: (id: string, value: unknown) => void
  setFormIsValid: (isValid: boolean) => void
  values: PackCostFormData
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ErrTouchShortcuts = { packHoldingAccount: true, packInventoryAssetAccount: true }

export interface PackCostFormData {
  id: number | null
  isPackEnabled: boolean
  packHoldingAccount: AccountListItem | null
  packInventoryAssetAccount: AccountListItem | null
  packCost: number | null
}

export type DeletePackModalProps = Omit<DeleteModalProps, 'title' | 'content'>

export type UsePackCostProps = PackCostProps

export interface UsePackCostReturn extends UseFormReturn<PackCostFormData, ErrTouchShortcuts> {
  checkboxValue: CheckboxValue
  creditAccountProps: UseDropdownReturn<AccountListItem>
  deleteModalProps: DeletePackModalProps
  debitAccountProps: UseDropdownReturn<AccountListItem>
  isLastLineErrorVisible: boolean
  onTogglePackCost: () => void
}

export enum PackCostFields {
  Id = 'id',
  IsPackEnabled = 'isPackEnabled',
  PackHoldingAccount = 'packHoldingAccount',
  PackInventoryAssetAccount = 'packInventoryAssetAccount',
  PackCost = 'packCost'
}

export type AccountFields = PackCostFields.PackHoldingAccount | PackCostFields.PackInventoryAssetAccount
