import { css } from '@emotion/css'

const ACTIONS_PANEL_HEIGHT = 80

export default css(`
  &.cf-modal-container {
    .cf-modal-content-scroll-wrapper {
      padding: 0px;
    }
    .cf-modal-content-scroll-wrapper .cf-modal-root {
      padding: 0px;
      max-width: 100vw;
      min-width: 1280px;
    
      .cf-modal-title {
        max-width: 1460px;
        padding: 40px 0px 0px;
        margin: 0px auto 24px;
        @media (max-width: 1600px) {
          max-width: 100%;
          padding: 40px 70px 0;
        }
      }

      .cf-modal-content {
        max-width: 1460px;
        padding: 0px 0px 120px;
        @media (max-width: 1600px) {
          max-width: 100%;
          padding: 0px 70px 120px;
        }
      }

      .cf-modal-footer {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        margin-top: 0px;
        top: calc(100vh - ${ACTIONS_PANEL_HEIGHT}px);

        display: flex;
        width: 100%;
        padding: 12px 64px;
        justify-content: flex-end;
        align-items: center;
        box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.12);
        background: #fff;
        z-index: calc(var(--main-menu-zindex) - 1);
      }
      
    }
  }
`)
