import { TitleReportsRequestDto } from 'api/types'
import { REGISTRATION_STAGE_NOT_SELECTED_ID } from '../components/Filters/constants'

const serializeDateFilters = (filters: TitleReportsRequestDto): TitleReportsRequestDto => {
  return {
    ...filters,
    registrationStageFilter: {
      ...filters.registrationStageFilter,
      registrationStageIds: filters.registrationStageFilter?.registrationStageIds?.filter(el => el !== REGISTRATION_STAGE_NOT_SELECTED_ID)
    }
  }
}

export default serializeDateFilters
