import type { FC } from 'react'
import type { IconProps } from 'types'

const EmailIcon: FC<IconProps> = ({ color = '#21212180', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_86546_18057)'>
        <path d='M13.332 2.6665H2.66536C1.93203 2.6665 1.33203 3.2665 1.33203 3.99984V11.9998C1.33203 12.7332 1.93203 13.3332 2.66536 13.3332H13.332C14.0654 13.3332 14.6654 12.7332 14.6654 11.9998V3.99984C14.6654 3.2665 14.0654 2.6665 13.332 2.6665ZM13.0654 5.49984L8.70537 8.2265C8.27203 8.49984 7.72536 8.49984 7.29203 8.2265L2.93203 5.49984C2.76536 5.39317 2.66536 5.21317 2.66536 5.01984C2.66536 4.57317 3.15203 4.3065 3.53203 4.53984L7.9987 7.33317L12.4654 4.53984C12.8454 4.3065 13.332 4.57317 13.332 5.01984C13.332 5.21317 13.232 5.39317 13.0654 5.49984Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_86546_18057'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EmailIcon
