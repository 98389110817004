import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { type ControlListItem, TransactionControlTypeId } from 'api/types'
import { joinPartsBySpace } from 'utils/view_helper'
import { isTruthy } from 'utils/general'
import { VehicleStateMap, DealStateMap } from 'constants/names'

import CLASS_NAME from './styles'

export interface ControlOptionProps {
  className?: string
  option: ControlListItem | null
}

const ControlOption: FC<ControlOptionProps> = ({ className, option }) => {
  const control = option as unknown as (ControlListItem | null)
  const carName = joinPartsBySpace(control?.year, control?.make, control?.model)
  const userName = control?.firstName != null ? `${control?.firstName} ${control?.lastName ?? ''} |` : ''

  const vehicleStateName = VehicleStateMap[control?.vehicleState ?? -1]
  const dealStateName = DealStateMap[control?.dealStateId ?? -1]
  const statusName = `${vehicleStateName ?? dealStateName ?? ''}`
  const statusClass = statusName.toLowerCase()
  const hasStatus = isTruthy(statusName)

  const controlInfo = control?.controlType === TransactionControlTypeId.Deal
    ? `${userName} ${carName}`
    : carName

  return (
    <div className={cnx(CLASS_NAME, className, 'control-option')}>
      <div className='cf-option-name'>{control?.controlId}</div>

      <div className={cnx('cf-option-sub-title', hasStatus && 'vehicle-status-indicator', statusClass)}>
        {hasStatus && (<div className='status'>{statusName} |</div>)}
        <div className='name'>{controlInfo}</div>
      </div>
    </div>
  )
}

export default ControlOption
