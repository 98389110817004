import type { FC } from 'react'

import closeIcon from 'assets/close_black.svg'

import CLASS_NAME from './styles'

interface HeaderProps {
  onClose: () => void
}

const Header: FC<HeaderProps> = ({ onClose }) => {
  return (
    <div className={CLASS_NAME}>
      Messages

      <img onClick={onClose} src={closeIcon} alt=""/>
    </div>
  )
}

export default Header
