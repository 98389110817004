import type { FC } from 'react'
import { cnx } from '@carfluent/common'
import CopyIcon from './CopyIcon'
import CLASS_NAME from './styles'

export interface CopyButtonProps {
  value: string | null
  nameOfField?: string
  showConfirmation?: (msg: string) => void
}

const CopyButton: FC<CopyButtonProps> = ({ value, nameOfField, showConfirmation }) => {
  const onClick = (): void => {
    if (value == null) {
      return
    }

    void navigator.clipboard.writeText(value)
    showConfirmation?.(`${nameOfField ?? ''} copied.`)
  }

  return (
    <span
      className={cnx(CLASS_NAME, 'copy-button')}
      onClick={onClick}
    >
      <CopyIcon />
    </span>
  )
}

export default CopyButton
