import { FC, ReactElement } from 'react'
import { cn } from '@carfluent/common'
import CLASS_NAME from './styles'

interface TabListProps {
  children: ReactElement[] | ReactElement
}

const TabList: FC<TabListProps> = (props) => {
  return (
    <div className={cn(CLASS_NAME, 'tab-list')}>{props.children}</div>
  )
}

export default TabList
