import type { DocumentFormPackCreateRequestDto } from 'api/types'
import type { FormPacksFormData } from './types'

const serializeAddNewFormPackData = (data: FormPacksFormData): DocumentFormPackCreateRequestDto => {
  return {
    name: data.name ?? '',
    description: data.description ?? ''
  }
}

export default serializeAddNewFormPackData
