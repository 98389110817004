import { css } from '@emotion/css'

export default css(`
  grid-area: buttons;
  margin-top: -8px;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: flex-end;
  gap: 16px;
  
  .delete-lead-btn {
      display: flex;
      justify-content: center;
    }

    .activity-tasks-action-buttons {
      line-height: 22px;
      width: max-content;
      height: 26px;
      padding: 5px 18px;
      border-radius: 8px;
      
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
      }
    }

  .MuiButton-root {
    border: 1px solid #C99B86;

    :hover {
      background-color: #FFF;
      border: 1px solid #DDB3A0;
      opacity: 1;
    }
  }
`)
