import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import type { UseLeadDetailsViewReturn } from 'pages/crm/LeadDetailsView/hook/types'
import Task from './components/Task'

import CLASS_NAME from './styles'

export type LeadDetailsTasksProps = UseLeadDetailsViewReturn['leadTasksProps']

const LeadDetailsTasksBlock: FC<LeadDetailsTasksProps> = ({ leadTasks, ...rest }) => {
  return (
    <div className={cnx(CLASS_NAME, 'grey-background')}>
      {leadTasks.map((task, index) => {
        const isLastTaskInList = index === leadTasks.length - 1

        return (
          <Task
            key={task.id}
            index={index}
            task={task}
            isLastTaskInList={isLastTaskInList}
            {...rest}
          />
        )
      })}
    </div>
  )
}

export default LeadDetailsTasksBlock
