import { type FC, useEffect, useState } from 'react'
import { Modal, Loader } from '@carfluent/common'
import { DocusealForm } from '@docuseal/react'

import DocumentsApiProvider from 'api/documents.api'
import { EntityId } from 'api/types'

import CLASS_NAME from './styles'

interface EnvelopeSignUrlPropsModal {
  envelopeSignUrlId: number | null
  dealId: EntityId
  onClose: () => void
  onSigned: () => Promise<void>
}

const EnvelopeSignUrlModal: FC<EnvelopeSignUrlPropsModal> = ({
  envelopeSignUrlId,
  dealId,
  onClose,
  onSigned
}) => {
  const [signUrl, setSignUrl] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (envelopeSignUrlId == null) {
      return
    }

    const runEffect = async (): Promise<void> => {
      const { url } = await DocumentsApiProvider.getEnvelopeSignUrl(dealId, envelopeSignUrlId)
      setSignUrl(url)
    }

    void runEffect()
  }, [envelopeSignUrlId, onSigned, dealId])

  return (
    <Modal
      isOpen={envelopeSignUrlId != null}
      title='Sign in pack'
      onClose={onClose}
      className={CLASS_NAME}
    >
      {(signUrl == null) && (
        <div className='loader-content'>
          <Loader color='dark' size='large' />
        </div>
      )}

      {(signUrl != null) && (
        <>
          {isLoading && (
            <div className='loader-content'>
              <Loader color='dark' size='large' />
            </div>
          )}

          <DocusealForm
            src={signUrl}
            onLoad={() => setIsLoading(false)}
            onComplete={(detail) => {
              if (detail.status === 'completed') {
                void onSigned()
              }
            }}
          />
        </>
      )}
    </Modal>
  )
}

export default EnvelopeSignUrlModal
