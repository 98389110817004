import { useCallback, useState } from 'react'
import { useModal } from '@carfluent/common'

import CustomersCoreApiProvider from 'api/customersCore.api'
import useTableApi from 'hooks/useTableApi'
import { calcTableHeight } from 'utils/math'
import { TABLE_MIN_HEIGHT } from 'constants/constants'

import type { FormLienholderRow, UseLienholderListReturn } from './types'
import { DEFAULT_SORTING, EmptyTableMsg } from './constants'
import columnDefinitions from './columns'
import parseListData from './parser'

const useLienholderList = (): UseLienholderListReturn => {
  const {
    isModalOpen: isNewLienholderModalOpen,
    onCloseModal: onCloseNewLienholderModal,
    onOpenModal: onOpenNewLienholderModal
  } = useModal()

  const [selectedLienholderId, setSelectedLienholderId] = useState<number | null>(null)

  const {
    isLoading,
    emptyTableMessage,
    rows,
    search,
    sorting,
    loadRows,
    setSearch,
    setSorting,
    onSearch
  } = useTableApi({
    defaultFilters: null,
    defaultSorting: DEFAULT_SORTING,
    getList: CustomersCoreApiProvider.getLienholderList,
    parseListData,
    nothingFoundMessage: EmptyTableMsg.NoItemsFound,
    emptyTableMessage: 'No lienholders yet',
    shouldRunOnCall: true
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onOpenLienholderDetails = useCallback((row?: FormLienholderRow) => {
    setSelectedLienholderId(Number(row?.original.id) ?? null)
    onOpenNewLienholderModal()
  }, [onOpenNewLienholderModal])

  const onAddLienholder = useCallback(async () => {
    await loadRows({ forceRefresh: true })
  }, [loadRows])

  const onDeleteLienholder = useCallback(async () => {
    await loadRows({ forceRefresh: true })
  }, [loadRows])

  const onBottomReached = useCallback(async () => {
    if (!isLoading && TABLE_MIN_HEIGHT <= calcTableHeight(rows.length)) {
      await loadRows({ forceRefresh: false })
    }
  }, [isLoading, loadRows, rows.length])

  const onClose = useCallback(() => {
    setSelectedLienholderId(null)
    onCloseNewLienholderModal()
  }, [])

  return {
    isNewLienholderModalOpen,
    onCloseNewLienholderModal: onClose,
    onOpenNewLienholderModal,
    isLoading,
    emptyTableMessage,
    rows,
    columns: columnDefinitions,
    sorting,
    search,
    selectedLienholderId,
    onOpenLienholderDetails,
    onSearchChange: setSearch,
    onBottomReached,
    onSearch,
    setSorting,
    onAddLienholder,
    onDeleteLienholder
  }
}

export default useLienholderList
