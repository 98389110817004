import { css } from '@emotion/css'

export default css(`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
  .h6-title {
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    span {
      color: #B00020;
      font-size: 14px;
    }
  }
  
  > div {
    width: 292px;
    margin-bottom: 16px;
    
    &:nth-last-child(-n+2) {
      margin-bottom: 24px;
    }
  }
`)
