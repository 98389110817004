import type { FC } from 'react'
import { cnx, CellWrapper, formatCurrencyAccounting } from '@carfluent/common'

import CLASS_NAME from './styles'

interface BalanceCellProps {
  getValue: () => number | string
}

/**
 * @deprecated - use `components/common/Table/cells/AmountCell` instead
 */
const BalanceCell: FC<BalanceCellProps> = ({ getValue }) => {
  const value = getValue()
  const num = (value == null) ? null : Number(value)
  const displayBalance = formatCurrencyAccounting(num, { allowNegative: true })

  return (
    <CellWrapper className={cnx(CLASS_NAME, 'cell-balance')}>
      <p>{displayBalance}</p>
    </CellWrapper>
  )
}

export default BalanceCell
