import { css } from '@emotion/css'

export default css(`
  .add-rule-textarea {
    min-height: 120px;

    textarea { min-height: 96px; }
  }
  
  .radio-line-container {
    display: flex;
    margin-bottom: 24px;
    padding-left: 3px;
  }

  .time-zone-label {
    color: rgba(33, 33, 33, 0.50);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.4px;
    padding-left: 16px;
    position: absolute;
  }

  .time-container {
    position: relative;
  }

  .cf-modal-footer {
    grid-gap: 20px 16px!important;
  }

  .cf-button-content {
    font-size: 14px !important;
    font-weight: 500;
    line-height: 16px;
  }
`)
