import { type AccountBankStatementListItem } from 'api/types'
import { toDateOrNull } from 'utils/parse_date'
import { type AccountBankStatement } from './types'

const parseAccountBalances = (items: AccountBankStatementListItem[]): AccountBankStatement[] => {
  return items.map(item => ({
    ...item,
    lastManualUploadDate: toDateOrNull(item.lastManualUploadDate),
    lastReconciledStartDate: toDateOrNull(item.lastFinishedReconciliationSession?.beginningDate),
    lastReconciledEndDate: toDateOrNull(item.lastFinishedReconciliationSession?.endDate),
    lastReconciledProcessDate: toDateOrNull(item.lastFinishedReconciliationSession?.processEndDate),
  }))
}

export default parseAccountBalances
