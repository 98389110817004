import { type FC, useState, useRef, useEffect, useMemo } from 'react'
import { Button, cnx } from '@carfluent/common'

import { createStyles } from './styles'

interface TruncateWithShowMoreProps {
  lineClamp?: number
  lineHeight?: number
}

const TruncateWithShowMore: FC<TruncateWithShowMoreProps> = ({
  children,
  lineClamp = 5,
  lineHeight = 24
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [isTruncated, setIsTruncated] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const firstTruncate = useRef(false)

  const className = useMemo(() => {
    return createStyles(lineClamp - 1)
  }, [lineClamp])

  useEffect(() => {
    if (contentRef.current != null) {
      const blockHeight = lineClamp * lineHeight
      const margins = Array.from(contentRef.current?.children).reduce((res, item) => {
        const styles = getComputedStyle(item)
        res = res + parseInt(styles.marginTop) + parseInt(styles.marginBottom)

        return res
      }, 0)

      const heightWithoutMargin = contentRef.current.scrollHeight - margins

      if (heightWithoutMargin > blockHeight) {
        setShowButton(true)

        if (!firstTruncate.current) {
          setIsTruncated(true)
          firstTruncate.current = true
        }
      } else {
        setShowButton(false)
      }
    }
  }, [children, lineClamp, lineHeight])

  return (
    <div className={className}>
      <div
        ref={contentRef}
        className={cnx('content-wrapper', isTruncated ? 'truncated' : '')}
      >
        {children}
      </div>

      {showButton && (
        <div className='show-more-less-block'>
          <Button
            onClick={(): void => {
              setIsTruncated(!isTruncated)
            }}
          >
            {isTruncated ? 'Show more' : 'Show less'}
          </Button>
        </div>
      )}
    </div>
  )
}

export default TruncateWithShowMore
