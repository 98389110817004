import type { FC } from 'react'

import { DAYS_OF_WEEK } from 'pages/crm/WorkSchedule/hook/constants'

const DaysOfTheWeek: FC = () => (
  <>
    {DAYS_OF_WEEK.map((dayOfWeek, index) => (
      <div key={index} className='cf-calendar-header-cell'>
        {dayOfWeek}
      </div>
    ))}
  </>
)

export default DaysOfTheWeek
