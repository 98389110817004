import type { FormValidation, DeepRequired } from '@carfluent/common'

import { campaignField, campaignPreHeaderField } from 'utils/validationPresets'
import type { ContentFormData } from './types'

const createFormValidation = (): FormValidation<DeepRequired<ContentFormData>> => {
  return {
    name: campaignField(true),
    senderName: campaignField(true),
    emailSubject: campaignField(true),
    preHeader: campaignPreHeaderField(false)
  }
}

export default createFormValidation()
