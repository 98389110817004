import {
  type DealerProductCoverageTemplateDto,
  type DealerProductCoveragePackageSettingsDto,
  type DealerProductCoverageMarkupDto
} from 'api/types/responses'

export enum WarrantyDroppableIds {
  SelectedProducts = 'selected-products',
  Promoted = 'promoted'
}

export interface SelectedTemplateModel {
  id: number
  template: DealerProductCoverageTemplateDto | null
}

export interface FISetupMarkupsModel {
  [ProductType: number]: DealerProductCoverageMarkupDto
}

export interface FISettingsModel {
  markups: FISetupMarkupsModel
  coverageViewTemplates: DealerProductCoverageTemplateDto[]
  selectedTemplate: SelectedTemplateModel | null
  packages: DealerProductCoveragePackageSettingsDto[]
}
