import { css } from '@emotion/css'

export default css(`
  & {
    overflow-y: hidden;

    .entity-option {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      overflow-x: hidden;
    }

    .entity-subtitles {
      display: flex;
      align-items: center;
    }

    .entity-subtitle {
      font-size: 12px;
      line-height: 16px;
      color: #909090;
      flex-grow: 1;
      overflow: hidden;
      white-space: nowrap;
    }

    .entity-divider {
      box-sizing: border-box;
      width: 5px;
      height: 12px;
      border-right: 1px solid #909090;
      margin: 0 4px 0 0;
    }
  }
`)
