import { useState, useCallback } from 'react'
import { useLoader } from '@carfluent/common'
import { useNavigate } from 'react-router-dom'

import ReconAPIProvider from 'api/recon.api'
import { type ReconVehicleAmount, type TotalStepsAmountDtoDto } from 'api/types'

import { Routes } from 'constants/route_helper'

import useAsyncEffect from 'hooks/useAsyncEffect'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

import { type UseDashboardReconditioningReturn } from './types'

const UseReconditioningDashboard = (): UseDashboardReconditioningReturn => {
  const { isLoading, startLoader, stopLoader } = useLoader()
  const { showAlert } = useCustomSnackbar()
  const navigate = useNavigate()

  const [selectedStep, setSelectedStep] = useState<number | undefined>(undefined)
  const [statuses, setStatuses] = useState<ReconVehicleAmount[]>([])
  const [statusTotal, setStatusesTotal] = useState<TotalStepsAmountDtoDto>({ noAlertCount: 0, firstAlertCount: 0, secondAlertCount: 0 })

  const onChangeStep = useCallback((stepId: number | undefined) => {
    setSelectedStep(stepId)
    navigate(Routes.Recon, { state: { reconStepId: stepId } })
  }, [])

  useAsyncEffect(async () => {
    try {
      startLoader()
      const res = await ReconAPIProvider.getReconVehiclesTotal()
      setStatuses(res.amounts)
      setStatusesTotal(res.total)
    } catch (err) {
      showAlert(err)
    } finally {
      stopLoader()
    }
  }, [
    showAlert,
    startLoader,
    stopLoader
  ])

  return {
    isLoading,
    selectedStep,
    onChangeStep,
    statusFiltersProps: {
      statuses,
      total: statusTotal,
      isLoading
    }
  }
}

export default UseReconditioningDashboard
