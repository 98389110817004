import { useEffect } from 'react'
import noop from 'lodash-es/noop'

import ws from 'services/web_sockets'
import { Notifications } from 'constants/names'

/**
 * This module provides dummy subscriptions to all WS events,
 * to supress an annoying warning:
 * `Warning: No client method with the name 'XXX' found.`
 */
const useDummySubscriptions = (): void => {
  useEffect(() => {
    ws.on(Notifications.NewDealCreated, { name: 'noop', action: noop })
    ws.on(Notifications.DealUpdated, { name: 'noop', action: noop })

    return () => {
      ws.off(Notifications.NewDealCreated)
      ws.off(Notifications.DealUpdated)
    }
  }, [])
}

export default useDummySubscriptions
