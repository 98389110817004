import { TransactionTypeId, AccountCategoryId } from 'api/types'

const getSuggestedAccountCategories = (
  transactionTypeId: number | null,
  isForTable = false
): number[] => {
  switch (transactionTypeId) {
    case TransactionTypeId.Payable: {
      return isForTable
        ? []
        : [AccountCategoryId.Payable]
    }

    case TransactionTypeId.Receive: {
      return isForTable
        ? [AccountCategoryId.Receivable]
        : [AccountCategoryId.Banks, AccountCategoryId.UndepositedFunds]
    }

    case TransactionTypeId.Receivable: {
      return isForTable
        ? []
        : [AccountCategoryId.Receivable]
    }

    default: {
      return []
    }
  }
}

export default getSuggestedAccountCategories
