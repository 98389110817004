import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import IconPersonaPassed from './icons/IconPersonaPassed'
import IconPersonaFailed from './icons/IconPersonaFailed'

import { type PersonaIconProps } from './types'
import CLASS_NAME from './styles'

const PersonaIcon: FC<PersonaIconProps> = ({
  className,
  isPassed
}) => {
  return (
    <div className={cnx(CLASS_NAME, className)}>
      {isPassed ? <IconPersonaPassed /> : <IconPersonaFailed />}
    </div>
  )
}

export default PersonaIcon
