import type { DealerData } from 'api/types'
import type { ProfileFormData } from './types'
import { GET_DEFAULT_DEALER_DATA } from 'api/defaults'

export const parseProfileData = (dealerInfo: DealerData): ProfileFormData => {
  return {
    dealerName: dealerInfo.dealerName ?? '',
    addressData: {
      addressLong: dealerInfo.address1,
      address: dealerInfo.address1,
      city: dealerInfo.city,
      zipCode: dealerInfo.zipCode,
      state: dealerInfo.state,
      county: null
    },
    googleMapsLink: dealerInfo.googleMapsLink ?? '',
    dealerBusinessHours: dealerInfo.dealerBusinessHours ?? DEFAULT_PROFILE_DATA.dealerBusinessHours,
    phone: dealerInfo.phone ?? '',
    email: dealerInfo.email ?? '',
    leadCmsEmails: dealerInfo.leadCmsEmails?.join(';') ?? '',
    logoUrl: dealerInfo.logoUrl ?? '',
    termsUrl: dealerInfo.termsUrl ?? ''
  }
}

export const DEFAULT_PROFILE_DATA = parseProfileData(GET_DEFAULT_DEALER_DATA())
