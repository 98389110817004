import type { FC } from 'react'

import Tooltip from 'components/common/Tooltip'
import Icon from 'components/common/Icon'

import CLASS_NAME, { TOOLTIP_CONTENT_CLASSES } from './styles'

const Title: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <Tooltip
        placement='bottom'
        popperClassName={TOOLTIP_CONTENT_CLASSES}
        title={
          `Workplan is a set of CRM automated events: tasks, emails, and messages.
          Events are dedicated to each status and depend on the status day.`
        }
      >
        <div className='wrapper'>
          <p>Workplan</p>

          <div className='img-wrapper'>
            <Icon name='infoIcon' alt='icon' />
          </div>
        </div>
      </Tooltip>
    </div>

  )
}

export default Title
