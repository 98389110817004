import type { FC } from 'react'

import formImage from 'assets/form.svg'
import stepperImage from 'assets/step.svg'
import CLASS_NAME from './styles'

export interface PreviewProps {
  logoUrl: string
}

const Preview: FC<PreviewProps> = ({ logoUrl }) => {
  return (
    <div className={CLASS_NAME}>
      <div className='header'>
        {logoUrl != null && (
          <img
            className='logo-img'
            src={logoUrl}
            alt='Logo'
          />
        )}
      </div>
      <div className='side-bar'>
        <img src={stepperImage} alt='' />
      </div>
      <div className='content'>
        <img src={formImage} alt='' />
      </div>
    </div>
  )
}

export default Preview
