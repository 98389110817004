import { type FocusEvent, useCallback, useEffect, useState } from 'react'
import { type DeepRequired, useForm, useLoader, isOk, isFail } from '@carfluent/common'

import AccountingApiProvider from 'api/accounting.api'
import { WorkflowTypeDto, type CustomerDto, ErrorCode } from 'api/types'
import { isStringEmpty, isStringNotEmpty } from 'utils/parse_string'
import { emptyAddress } from 'utils/address'
import type { FullAddressParts } from 'types/address'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

import { FIELD_TRANSFORMS, Messages, getDefaultFormData } from './constants'
import type { UseCustomerReturn, UseCustomerProps, CustomerFormData, FieldPath, ErrTouchShortcuts } from './types'
import parseCustomerData from './parser'
import serializeCustomerForm from './serializer'
import { validationRules, DEPENDENT_VALIDATIONS } from './validator'
import CustomersCoreApiProvider from 'api/customersCore.api'

const DEFAULT_FORM_DATA = getDefaultFormData()

const useCustomer = ({
  isOpen,
  customerId,
  onSubmit: _onSubmit,
  onDeleteCustomer: _onDeleteCustomer,
  onClose: _onClose
}: UseCustomerProps): UseCustomerReturn => {
  const { showAlert } = useCustomSnackbar()

  const { isLoading, startLoader, stopLoader } = useLoader()
  const [workflowTypes, setWorkflowTypes] = useState<WorkflowTypeDto[]>([])
  const [isDateError, setIsDateError] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  // ========================================== //
  //                  HANDLERS                  //
  // ========================================== //

  const submitAction = useCallback(async (values: DeepRequired<CustomerFormData>) => {
    startLoader()

    try {
      const payload = serializeCustomerForm(values)
      let customer: CustomerDto

      if (customerId == null) {
        customer = await AccountingApiProvider.createCustomer(payload)
      } else {
        customer = await AccountingApiProvider.updateCustomer({ ...payload, id: customerId })
      }

      await _onSubmit(customer)
    } catch (err) {
      showAlert(err)
    }
  }, [showAlert, _onSubmit, customerId, startLoader])

  const deleteAction = useCallback(async () => {
    startLoader()
    if (customerId != null) {
      await AccountingApiProvider.removeCustomer(customerId)
      await _onDeleteCustomer?.()
    }
  }, [customerId, startLoader, _onDeleteCustomer])

  const onActionResult = useCallback((res, resetForm, reason) => {
    stopLoader()

    if (reason === 'submit' && isOk(res)) {
      showAlert(
        customerId == null ? Messages.SuccessCreate : Messages.SuccessUpdate,
        { variant: 'success' }
      )
      _onClose()
      resetForm(getDefaultFormData())
    }

    if (reason === 'delete' && isOk(res)) {
      showAlert(Messages.SuccessDelete, { variant: 'success' })
      _onClose()
      resetForm(getDefaultFormData())
    }

    if (reason === 'delete' && isFail(res)) {
      if (res?.result?.response?.data?.code === ErrorCode.LinkedCustomer) {
        setIsDateError(true)
        setError(res?.result?.response?.data?.message)
      } else {
        showAlert(res?.result)
      }
    }
  }, [_onClose, showAlert, stopLoader, customerId])

  const {
    values,
    errors,
    touched,
    onChange: _onChange,
    onBlur,
    onSubmit,
    onDelete,
    setFieldTouched,
    validateField,
    validateForm,
    setTouched,
    resetForm
  } = useForm<CustomerFormData, ErrTouchShortcuts>({
    transforms: FIELD_TRANSFORMS,
    baseValues: DEFAULT_FORM_DATA,
    dependentValidations: DEPENDENT_VALIDATIONS,
    validationRules,
    submitAction,
    deleteAction,
    onActionResult
  })

  const isStateOfIssueDisabled = isStringNotEmpty(errors?.driverLicense) || isStringEmpty(values.driverLicense)

  const onClose = useCallback(() => {
    resetForm(getDefaultFormData())
    _onClose?.()
    setIsDateError(false)
    setError('')
  }, [_onClose, resetForm])

  /**
   * AS-NOTE: check whether this needed.
   * Supposing because of blurMode='create'
   * Problem is that validation is applied after default blur
   * but before setting value from 'input to create'
   */
  const onNameBlur = useCallback((e: FocusEvent<HTMLInputElement>): void => {
    const id = e.target.id as FieldPath
    const value = e.target.value

    _onChange(id, isStringEmpty(value) ? null : value)
    setFieldTouched(id, true)
  }, [_onChange, setFieldTouched])

  const onChange = useCallback((field: string, value: unknown): void => {
    const fieldKey = field as FieldPath
    setIsDateError(false)
    setError('')

    switch (fieldKey) {
      case 'firstName':
      case 'lastName': {
        if (value == null || typeof value === 'string') {
          _onChange(fieldKey, value ?? null)
        } else {
          const customer = value as CustomerDto
          resetForm(parseCustomerData(customer))
        }
        break
      }

      case 'address':
      case 'mailingAddress': {
        const addressData = value as FullAddressParts | null
        _onChange(fieldKey, addressData)
        break
      }

      case 'isDifferentMailingAddress': {
        _onChange(fieldKey, value)

        if (value === true) {
          _onChange('mailingAddress', { ...emptyAddress })
        }
        break
      }

      case 'customerTypeId': {
        _onChange(fieldKey, value)
        validateField(fieldKey)
        break
      }

      default: {
        _onChange(fieldKey, value)
      }
    }
  }, [_onChange, resetForm, validateForm, validateField, setTouched, values])

  const loadCustomerData = useCallback(async (id: number): Promise<void> => {
    try {
      startLoader()
      const customer = await AccountingApiProvider.getCustomer(id)
      resetForm(parseCustomerData(customer))
    } catch (err) {
      showAlert(err)
    } finally {
      stopLoader()
    }
  }, [startLoader, resetForm, showAlert, stopLoader])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const loadWorkflowTypes = async (): Promise<void> => {
      try {
        const response = await CustomersCoreApiProvider.getWorkflowTypes()
        setWorkflowTypes(response.items)
      } catch {
        // DO NOTHING
      }
    }

    void loadWorkflowTypes()
  }, [])

  useEffect(() => {
    setFieldTouched('stateOfIssue', true)
    validateField('stateOfIssue')

    if (isStateOfIssueDisabled) {
      _onChange('stateOfIssue', null)
    }
  }, [isStateOfIssueDisabled, _onChange, setFieldTouched, validateField])

  /**
   * Load customer data if dialog is open in "edit" mode.
   */
  useEffect(() => {
    if ((customerId == null) || !isOpen) {
      return
    }

    void loadCustomerData(customerId)
  }, [customerId, isOpen, loadCustomerData])

  // ========================================== //

  return {
    isEdit: customerId != null,
    isLoading,
    isOpen,
    isStateOfIssueDisabled,
    values,
    errors,
    touched,
    workflowTypes,
    isDateError,
    error,
    onChange,
    onBlur,
    onDelete,
    onSubmit,
    onClose,
    onNameBlur
  }
}

export default useCustomer
