import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import BackToTop from 'components/common/BackToTop'
import SaveCloseButtonsPanel from 'components/common/SaveCloseButtonsPanel'
import UnsavedChangesDialog from 'components/dialogs/UnsavedChanges'
import Table from 'components/common/Table'

import useOpeningBalancesList from './hook'
import { type OpeningBalanceRowData } from './hook/types'
import CLASS_NAME from './styles'

const LOADING_ROWS = Array(10).fill({})

const OpeningBalancesList: FC = () => {
  const {
    columns,
    emptyTableMessage,
    hasUnsavedChanges,
    isSubmitting,
    isTableLoading,
    isUnsavedChangesOpen,
    onCancelChanges,
    onCloseUnsavedChanges,
    onDontSaveAndContinueTransition,
    onSaveAndContinueTransition,
    onSortingChange,
    onSubmitChanges,
    rows,
    sorting
  } = useOpeningBalancesList()

  return (
    <div className={cnx('g-submenu-content', CLASS_NAME, hasUnsavedChanges && 'edit-container')}>
      <Table<OpeningBalanceRowData>
        columns={columns}
        data={isTableLoading ? [] : rows}
        emptyTableMessage={emptyTableMessage}
        isLoading={isTableLoading}
        isMemoizedByOriginal
        loadingRows={LOADING_ROWS}
        onSortingChange={onSortingChange}
        sorting={sorting}
      />

      {(hasUnsavedChanges || isSubmitting) && (
        <SaveCloseButtonsPanel
          isSubmitDisabled={false}
          handleSubmit={onSubmitChanges}
          handleReset={onCancelChanges}
          isSubmitting={isSubmitting}
          titleCloseButton='DISCARD'
        />
      )}

      <UnsavedChangesDialog
        content='Your changes will be lost if you dont save them'
        isOpen={isUnsavedChangesOpen}
        isSubmitting={isSubmitting}
        onCloseDialog={onCloseUnsavedChanges}
        onDontSaveAndClose={onDontSaveAndContinueTransition}
        onSaveAndClose={onSaveAndContinueTransition}
      />

      <BackToTop className={cnx({ 'cf-back-to-top-edit': hasUnsavedChanges })} />
    </div>
  )
}

export default OpeningBalancesList
