import { useCallback } from 'react'
import { isAxiosError } from 'axios'
import { isFail, isOk, useForm, type DeepRequired, type UseFormReturn } from '@carfluent/common'

import AccountingApiProvider from 'api/accounting.api'
import { CostTypeId } from 'api/types'
import parseError from 'utils/parseErrors'

import { DEFAULT_FORM_VALUES } from './constants'
import { serializeVehicleCost, serializeUpdateVehicleCost } from './serializer'
import validationRules from './validator'
import { type CostFormData, type ErrTouchShortcuts } from './types'

export interface UseCostFormProps {
  costId: number | null
  controlId: number | null
  onCloseModal: () => void
  onDelete?: (costId: number) => void
  onSubmit?: () => void
  setCloseYearError: (value: string) => void
  showError: (err: any) => void
}

const useCostForm = ({
  costId,
  controlId,
  onCloseModal,
  onDelete,
  onSubmit,
  setCloseYearError,
  showError
}: UseCostFormProps): UseFormReturn<CostFormData, ErrTouchShortcuts> => {
  const submitAction = useCallback(async (formData: DeepRequired<CostFormData>): Promise<void> => {
    const isPurchaseCostType = CostTypeId.Purchase === formData.costType?.id
    const payload = {
      cost: formData,
      control: controlId,
      isPurchaseCostType
    }

    if (costId == null) {
      const data = serializeVehicleCost(payload)
      await AccountingApiProvider.createTransactionCost(data)
      return
    }

    const data = serializeUpdateVehicleCost(payload)
    await AccountingApiProvider.updateTransactionCost(costId, data)
  }, [])

  const deleteAction = useCallback(async () => {
    if (costId == null) {
      return
    }

    await AccountingApiProvider.removeTransactionCost(costId)
    onDelete?.(costId)
    onCloseModal()
  }, [onCloseModal, onDelete])

  const onSubmitResult = useCallback((res, resetForm, setErrors) => {
    if (isOk(res)) {
      onSubmit?.()
      onCloseModal()
      resetForm()
      return
    }

    if (isFail(res)) {
      const err = res.result
      const errors = parseError(err)
      if (isAxiosError(err)) {
        if (errors?.date != null) {
          setCloseYearError(errors?.date)
        } else if (errors.referenceNumber != null) {
          setErrors(errors)
        } else {
          showError(err)
        }
      } else {
        showError(`Cost ${costId == null ? 'adding' : 'updating'} failed`)
      }

      /**
       * DD-TODO: process api errors here: Journal must be unique so we might get this
       * API error and we must display it under Reference # field as a validation error
       *
       * AZ-NOTE: ported this comment from previous, formik-based code.
       */
      console.error(err)
    }
  }, [onSubmit, onCloseModal])

  const onActionResult = useCallback((res, resetForm, reason, setErrors) => {
    if (reason === 'submit') {
      onSubmitResult(res, resetForm, setErrors)
      return
    }

    if (reason === 'delete') {
      if (isOk(res)) {
        onCloseModal()
        resetForm()
      }

      if (isFail(res)) {
        const err = res.result
        showError('Cost deleting failed')
        console.error(err)
      }
    }
  }, [onSubmit, onCloseModal, showError])

  return useForm<CostFormData, ErrTouchShortcuts>({
    baseValues: DEFAULT_FORM_VALUES,
    deleteAction,
    onActionResult,
    submitAction,
    validationRules
  })
}

export default useCostForm
