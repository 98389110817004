import { clearLocalStorage } from 'services/storage.service'
import { Routes } from 'constants/route_helper'

export const FORBIDDEN_ROLES = new Set(['customer'])

export const isTokenValid = (accessToken: string): boolean => {
  if (accessToken === '') {
    return false
  }
  const parsed = parseJwt(accessToken)

  return parsed === null
    ? false
    : parsed.role.every((v: string) => !FORBIDDEN_ROLES.has(v.toLowerCase()))
}

export const parseJwt = (token: string): Api.JwtPayload | null => {
  try {
    const parsed = JSON.parse(atob(token.split('.')[1]))
    if (!Array.isArray(parsed.role)) {
      parsed.role = [parsed.role]
    }
    return parsed as Api.JwtPayload
  } catch {
    return null
  }
}

export const logout = (): void => {
  clearLocalStorage()
  window.location.pathname = Routes.Login
}
