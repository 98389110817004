const hasSameKeys = (errorObject: Record<string, any>, formObject: Record<string, any>): boolean => {
  const errorKeys = Object.keys(errorObject)

  // Check if all keys in the error object are present in the form object
  for (const key of errorKeys) {
    if (!(formObject.hasOwnProperty(key))) {
      return false
    }
  }

  // If all checks pass, the objects have the same keys
  return true
}

export default hasSameKeys
