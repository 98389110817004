import { css } from '@emotion/css'

export default css`
  .manage-forms-wrapper {
    display: flex;
    margin-bottom: 16px;

    > div {
      margin-right: 16px;
    }
  }
  
  .deal-form-table-wrapper {
    background: #F3F3F3;
    border-radius: 4px;
    padding: 8px;
  }

  .form-table-header {
    height: 48px;
    background: #FAFAFA;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    padding: 0 32px;
    font-weight: 500;
    border-left: 4px solid transparent;
  }
  
  .forms-table-body {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
    overflow-y: auto;
    max-height: calc(100% - 56px);
    border-radius: 2px;

    &.grab-row {
      .forms-table-row {
        cursor: grab;
      }
    }
  }
  
  .empty-list {
    display: flex;
    height: 56px;
    justify-content: center;
    align-items: center;
    background: white;
  }
  
  .loader-wrapper {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
  }

  .manage-form-banner {
    height: 56px;
    padding: 16px 32px;
    background: #ECF4FF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 8px;

    > p {
      color: #458FFF;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
    }

    > div {
      display: flex;
      align-items: center;

      > button.cf-button-root.cf-button-variant-text {
        color: #458FFF;
        margin-left: 16px;
        
        .cf-button-content {
          font-weight: 700;
        }
        
        &.cf-button-disabled {
          cursor: not-allowed;
          opacity: 0.4;

          :hover {
            color: #458FFF;
            opacity: 0.4;
          }
        }
        
        &.cf-button-loading {
          margin-right: 16px;

          .cf-loader {
            circle {
              stroke: #458FFF;
            }
          }
        }
      }
    }
  }
`
