import type { FC } from 'react'
import { Typography } from '@material-ui/core'

import error_img from 'assets/404.svg'
import { useStyles } from './styles'

const NoDealerAndVin: FC = () => {
  const { content, wrapper, title, text } = useStyles()

  return (
    <div className={content}>
      <div className={wrapper}>
        <div>
          <img alt='404' src={error_img} />
          <Typography classes={{ root: title }}>
            Whoops, that Page is gone.
          </Typography>
          <Typography classes={{ root: text }}>
            The link you clicked (entered) may
            <br /> be broken or the page may have been removed. What you can do:
            <br />
            check the URL for typos or go back to dealer’s page
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default NoDealerAndVin
