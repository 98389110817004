import { type FC } from 'react'
import { MessageDialog, Loader, formatters as F } from '@carfluent/common'

import { DealAccountingState, type VehicleStateResponse } from 'api/types'
import TransactionDetails from 'pages/accounting/TransactionDetails'
import TopPanelActions from 'components/common/TopPanelActions'
import DeleteDealModal from 'components/deals/DeleteDeal'
import GeneratedTransaction from 'components/deals/GeneratedTransaction'
import BannersList from 'components/deals/BannersList'
import CheckboxLabel from 'components/common/CheckboxLabel'
import RequestDocuments from 'components/dialogs/RequestDocuments'
import SelectDocumentDealForms from 'components/dialogs/SelectDocumentDealForms'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'
import isDealTransactionRecorded from 'utils/deals/isDealTransactionRecorded'

import LenderDecisionsModal from '../LenderDecisionsModal'
import PaymentForm from '../PaymentFormModal'
import WarrantyFormModal from '../WarrantyFormModal'

import useDealViewBannersAndModalsProps from './hooks'
import { type UseDealViewBannersAndModalsProps } from './hooks/types'
import { getDeletedCarMassageInfo } from './utils'
import {
  CANNOT_UNSOLD_MODAL_CLASSES,
  UNSOLD_MODAL_CLASSES,
  HELPER_TEXT_CLASSES,
  CONTAINER_CLASSES
} from './styles'

const FORMAT = 'MM/dd/yy hh:mm aaa'

export interface BannersPanelActionsAndModalsProps extends Omit<UseDealViewBannersAndModalsProps, 'vehicleState'> {
  vehicleInfo: VehicleStateResponse | null
  isLoading: boolean
  isShowCarDelivery: boolean
}

const BannersPanelActionsAndModals: FC<BannersPanelActionsAndModalsProps> = ({
  deal,
  dealId,
  linkToWebsite,
  vehicleInfo,
  depositedCarId,
  isLoading: _isLoading,
  onLoadDeal,
  onApproveDeal: _onApproveDeal,
  onUpdateDealStatus,
  isShowCarDelivery,
  ...hookProps
}) => {
  const {
    actions,
    deleteDealModalProps,
    signaturePending,
    isCarAlreadySold,
    isCarDeleted,
    isCarDeposited,
    isCannotMakeDealUnsoldModalOpen,
    isCompletedDealerCheck,
    isDealWaitingForCreditAppApprove,
    isDealWaitingForCreditAppSubmit,
    isDealWaitingForDocsSelection,
    unsoldRecordDialogProps,
    unsoldDialogProps,
    lenderDecisionsModalProps,
    requestDocumentsModalProps,
    paymentFormModalProps,
    selectFormsModalProps,
    paymentMethod,
    isLoading,
    onApproveDeal,
    onCloseCannotMakeDealUnsoldModal,
    onGoBack,
    onDeleteDeal,
    onOpenSelectTemplateModal,
    onRefreshDeal,
    onSignPaperwork,
    onSubmitWarranties,
    onViewTransaction,
    onViewDealOnDeposit,
    viewGeneratedTransactionModalProps,
    viewTransactionModalProps,
    warrantyModalProps,
    redirectToCoverage
  } = useDealViewBannersAndModalsProps({
    ...hookProps,
    deal,
    dealId,
    depositedCarId,
    linkToWebsite,
    onLoadDeal,
    onApproveDeal: _onApproveDeal,
    onUpdateDealStatus,
    vehicleState: vehicleInfo?.vehicleState
  })

  const deletedCarMassageInfo = getDeletedCarMassageInfo(isCarDeleted, vehicleInfo)
  const formattedValue = deal?.createdDate != null ? F.formatLocalDate(deal?.createdDate, FORMAT) : ''

  return (
    <div className={CONTAINER_CLASSES}>
      {isLoading && <Loader size='large' />}

      <TopPanelActions
        className='g-top-panel-actions g-top-panel-actions-big-height '
        actions={actions}
        isActionButtonDisable
        backButtonName='Back'
        onGoBack={onGoBack}
        isActionBtnLoading={_isLoading}
      >
        <BlockSkeleton className='date-info-title' isLoading={_isLoading}>
          <div className={HELPER_TEXT_CLASSES}>
            Deal created: {formattedValue}
          </div>
        </BlockSkeleton>

      </TopPanelActions>

      <div className='banner-container'>
        <BannersList
          dealId={dealId}
          deletedCarMassageInfo={deletedCarMassageInfo}
          isCarAlreadySoldVisible={isCarAlreadySold}
          isCarDepositedVisible={isCarDeposited}
          isCarDeletedVisible={isCarDeleted}
          isDealerSignaturePendingVisible={signaturePending && isCompletedDealerCheck}
          isDealIsRecordedVisible={isDealTransactionRecorded(deal?.dealAccountingStateId ?? null)}
          isDealWaitingForCreditAppApprove={isDealWaitingForCreditAppApprove}
          isDealWaitingForCreditAppSubmit={isDealWaitingForCreditAppSubmit}
          isDealWaitingForDocsSelection={isDealWaitingForDocsSelection}
          onOpenSelectTemplateModal={onOpenSelectTemplateModal}
          onSignPaperwork={onSignPaperwork} // AZ-TODO: to open dialog with forms (planned feature) - move this handler from props to hook and add command to open modal. See `onOpenSelectTemplateModal`.
          onViewDealOnDeposit={onViewDealOnDeposit}
          onViewTransaction={onViewTransaction}
        />
      </div>

      <MessageDialog
        {...unsoldRecordDialogProps}
        className={UNSOLD_MODAL_CLASSES}
        title='Unmark deal as Sold?'
        textSubmit='YES, UNMARK'
      >
        <p>
          Note: This deal was pushed to Accounting and already recorded to the accounting books. Unmarking will delete journal entry that system created.
          <br />If deal contains trade in car, it will be removed from Inventory automatically.
        </p>

        <CheckboxLabel
          {...unsoldRecordDialogProps.checkboxProps}
          mode='inlined'
          id='-'
          name='I confirm and agree that this action cannot be undone. '
        />
      </MessageDialog>

      <MessageDialog
        {...unsoldDialogProps}
        className={UNSOLD_MODAL_CLASSES}
        title='Unmark deal as Sold?'
        textSubmit='YES, UNMARK'
      >
        <p>
          There is related trade in car added to Inventory.
          <br /> Unmarking deal as sold will remove the car from there.
        </p>
      </MessageDialog>

      <MessageDialog
        isOpen={isCannotMakeDealUnsoldModalOpen}
        onSubmit={onCloseCannotMakeDealUnsoldModal}
        className={CANNOT_UNSOLD_MODAL_CLASSES}
        title='Cannot unmark deal'
        textSubmit='OK'
      >
        <p>
          Trade in car added to inventory has some costs created. Remove them first.
        </p>
      </MessageDialog>

      <DeleteDealModal
        dealId={dealId}
        isOpen={deleteDealModalProps.isModalOpen}
        onClose={() => deleteDealModalProps.onCloseModal()}
        onConfirm={onDeleteDeal}
      />

      {/* DD-TODO: originally it had refreshDeal on submit - check if we still need it */}

      <GeneratedTransaction
        dealId={dealId}
        inventoryId={deal?.vehicle?.id ?? null}
        isModalOpen={viewGeneratedTransactionModalProps.isModalOpen}
        onCloseModal={() => viewGeneratedTransactionModalProps.onCloseModal()}
        onSubmit={onRefreshDeal}
      />

      <TransactionDetails
        dealId={dealId}
        transactionId={deal?.recapTransactionId}
        isModalOpen={viewTransactionModalProps.isModalOpen}
        onCloseModal={() => viewTransactionModalProps.onCloseModal()}
        onSubmit={onRefreshDeal}
      />

      <LenderDecisionsModal
        isOpen={lenderDecisionsModalProps.isModalOpen}
        onClose={() => lenderDecisionsModalProps.onCloseModal()}
        lenderDecisionDetails={deal?.creditApplication?.financingApplicationLenderDecisionDetails}
      />

      <RequestDocuments
        open={requestDocumentsModalProps.isModalOpen}
        onClose={() => requestDocumentsModalProps.onCloseModal()}
      />

      <PaymentForm
        isOpen={paymentFormModalProps.isModalOpen}
        onClose={() => paymentFormModalProps.onCloseModal()}
        onSubmit={onRefreshDeal}
        vin={deal?.vehicle?.vin ?? ''}
        dealId={dealId}
        dealerId={deal?.dealerId ?? ''}
        paymentMethod={paymentMethod}
      />

      <SelectDocumentDealForms
        onClose={() => selectFormsModalProps.onCloseModal()}
        isOpen={selectFormsModalProps.isModalOpen}
        onSubmit={onApproveDeal}
        dealId={dealId}
      />

      <WarrantyFormModal
        isOpen={warrantyModalProps.isModalOpen}
        onClose={() => warrantyModalProps.onCloseModal()}
        deal={deal}
        onUpdateDeal={onSubmitWarranties}
        redirectToCoverage={redirectToCoverage}
        isRecorded={deal?.dealAccountingStateId === DealAccountingState.Recorded}
      />
    </div>
  )
}

export default BannersPanelActionsAndModals
