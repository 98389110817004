import { DragEvent, useState, useCallback } from 'react'
import { FileFilterData, filterFiles } from 'utils/fileFilter'

export interface UseFilesDragAndDropProps {
  acceptedExtensions: string[]
  isDisabled?: boolean
  maxFileSize: number
  maxFilesTotalSize: number
  maxFileNumber: number
  onAddFiles: (data: FileFilterData) => void
}

export interface UseFilesDragAndDropReturn {
  isDragging: boolean
  onDragEnter: (e: DragEvent<HTMLElement>) => void
  onDragLeave: (e: DragEvent<HTMLElement>) => void
  onDragOver: (e: DragEvent<HTMLElement>) => void
  onDrop: (e: DragEvent<HTMLElement>) => void
}

const useFilesDragAndDrop = ({
  acceptedExtensions,
  isDisabled,
  maxFileSize,
  maxFilesTotalSize,
  maxFileNumber,
  onAddFiles
}: UseFilesDragAndDropProps): UseFilesDragAndDropReturn => {
  const [dragCounter, setDragCounter] = useState(0)

  const onDragEnter = useCallback((e: DragEvent<HTMLElement>): void => {
    if (isDisabled === true) {
      return
    }

    e.preventDefault()
    setDragCounter(prev => prev + 1)
  }, [isDisabled])

  const onDragLeave = useCallback((e: DragEvent<HTMLElement>): void => {
    e.preventDefault()
    setDragCounter(prev => prev - 1)
  }, [])

  const onDrop = useCallback((e: DragEvent<HTMLElement>): void => {
    e.preventDefault()
    setDragCounter(0)

    const res = filterFiles(
      Array.from(e.dataTransfer.files ?? []),
      maxFileSize,
      maxFilesTotalSize,
      maxFileNumber,
      acceptedExtensions
    )

    onAddFiles(res)
  }, [onAddFiles])

  const onDragOver = useCallback((e: DragEvent<HTMLElement>): void => {
    e.preventDefault()
  }, [])

  const onDropDisabled = useCallback((e: DragEvent<HTMLElement>): void => {
    e.preventDefault()
  }, [])

  return {
    isDragging: dragCounter > 0,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop: isDisabled === true ? onDropDisabled : onDrop
  }
}

export default useFilesDragAndDrop
