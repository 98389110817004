export const DATE_FORMAT = 'MM/dd/yy'

export const GENERAL_BLOCK_DATA_DEFAULT = {
  statusInfo: '',
  passedCount: 0,
  failedCount: 0,
  isLoading: true,
  commentsCount: 0,
  isUpdating: true,
  isCompleted: true,
  progressPercentage: 0
}
