import { useState, type FC, useEffect } from 'react'
import { cnx } from '@carfluent/common'

import Textarea from 'components/common/Textarea'
import HTMLContent from 'components/common/HTMLContent'
import { type IntakeInspectionQuestionDto } from 'api/types/responses'
import { InspectionStatus, type VehicleInspectionQuestionPayload } from 'api/types'

import IconSVG from 'components/inlineImages'
import CLASS_NAME from './styles'

export interface InspectionItemProps extends IntakeInspectionQuestionDto {
  error: string | null
  touched: boolean
  isSubmitted: boolean
  onQuestionUpdate: ({
    data,
    questionId,
    questionCategoryId
  }: {
    questionId: number | string
    questionCategoryId: number | string
    data: VehicleInspectionQuestionPayload
  }) => Promise<void>
}

const InspectionItem: FC<InspectionItemProps> = ({
  id,
  order,
  error,
  touched,
  isSubmitted,
  questionBody,
  questionStateId,
  questionCategoryId,
  comments: _comments,
  onQuestionUpdate: _onQuestionUpdate
}) => {
  const [comments, setComments] = useState(_comments)
  const onQuestionUpdate = (data: VehicleInspectionQuestionPayload): void => {
    void _onQuestionUpdate({
      data,
      questionId: id,
      questionCategoryId
    })
  }

  const onChange = (field: string, value: string): void => {
    setComments(value)
  }

  const onBlurComment = (): void => {
    if (comments !== _comments) {
      onQuestionUpdate({ comments, ...(questionStateId != null ? { questionStateId } : {}) })
    }
  }

  useEffect(() => {
    setComments(_comments)
  }, [_comments])

  return (
    <div className={cnx(CLASS_NAME, error !== null && touched && 'with-error')}>
      <div>
        <h3 className='inspection-title'>{`${order}. ${questionBody}`}</h3>

        <div className='inspection-button-container'>
          <div
            className='inspection-button'
            onClick={() => !isSubmitted && onQuestionUpdate({ questionStateId: InspectionStatus.Pass, comments })}
          >
            <span>
              <span className='icon-container'>
                <IconSVG.ThumbUp
                  width={24}
                  height={24}
                  color={
                    questionStateId === InspectionStatus.Pass ? '#219653' : '#ddd'
                  }
                />
              </span>
              Pass
            </span>
          </div>

          <div
            className='inspection-button'
            onClick={() => !isSubmitted && onQuestionUpdate({ questionStateId: InspectionStatus.Fail, comments })}
          >
            <span>
              <span className='icon-container'>
                <IconSVG.Fail
                  width={24}
                  height={24}
                  color={
                    questionStateId === InspectionStatus.Fail ? '#B00020' : '#ddd'
                  }
                />
              </span>
              Fail
            </span>
          </div>

          <div
            className='inspection-button'
            onClick={() => !isSubmitted && onQuestionUpdate({ questionStateId: InspectionStatus.Unavailable, comments })}
          >
            <span>
              <span className='icon-container'>
                <IconSVG.NaIcon
                  width={24}
                  height={24}
                  color={
                    questionStateId === InspectionStatus.Unavailable ? '#212121' : '#ddd'
                  }
                />
              </span>
              N/A
            </span>
          </div>
        </div>
      </div>

      {
        isSubmitted
          ? (
            <div className='comment-text'>
              <HTMLContent
                text={comments ?? ''}
                isShouldConvertWithLink
              />
            </div>
            )
          : (
            <Textarea
              maxRows={3}
              value={comments}
              onChange={onChange}
              placeholder='Comment'
              additionalHeight={2}
              onBlur={onBlurComment}
              className='comment-section'
            />
            )
      }
    </div>
  )
}

export default InspectionItem
