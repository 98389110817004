import { type FC, useCallback, useRef } from 'react'
import addDays from 'date-fns/addDays'
import { cnx, FormDatePicker, FormDropdown, FormNumberInput } from '@carfluent/common'

import { MAX_PRICE_VALUE } from 'constants/validation'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'
import LabeledFieldSkeleton from 'components/common/Skeletons/LabeledFieldSkeleton'
import { GET_DEFAULT_DEAL_FEES_BY_CONFIG } from 'api/defaults/deals'
import getPaymentTypeName from 'utils/deals/getPaymentTypeName'
import omitDisabledFees from 'utils/deals/omitDisabledFees'

import { type DealSectionProps } from './types'
import { PAYMENT_TYPE_OPTIONS } from './constants'
import { CLASS_NAME, POPOVER_CLASS_NAME } from './styles'

const FIRST_PAYMENT_DATE_SHIFT = 30

const DealSection: FC<DealSectionProps> = ({
  className,
  dealId,
  errors,
  feesAndCoveragesSettings,
  isFinancing,
  isLoading,
  isWholesale,
  onBlur,
  onChange,
  touched,
  users,
  values,
  isRecorded
}) => {
  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onChangePaymentType = (id: string, paymentType: number | null): void => {
    if ((paymentType != null) && (feesAndCoveragesSettings != null)) {
      const {
        dealerInventoryTax,
        ...newFees
      } = omitDisabledFees(
        GET_DEFAULT_DEAL_FEES_BY_CONFIG(paymentType, feesAndCoveragesSettings),
        feesAndCoveragesSettings.fees
      )

      onChange('dealFees', { ...values.dealFees, ...newFees })
    }

    onChange(id, paymentType)
  }

  /**
   * `DatePicker` triggers `onChange` when value is changed outside picker.
   * When we navigate between deals (using links in banners), it leads to change
   * of the "Sale date" picker's value, which leads to calling of this code,
   * that unexpectedly changes "First payment date".
   * This ref allows to fix it.
   * See https://dev.azure.com/carfluent/CarFluent/_workitems/edit/14197/
   */
  const refLastClickedDealId = useRef(dealId)
  const onSaleDateChange = useCallback((id: string, value: Date | null) => {
    onChange(id, value)

    const isChangeCausedByNavigation = refLastClickedDealId.current !== dealId

    if ((value != null) && !isChangeCausedByNavigation) {
      const nextFirstPaymentDate = addDays(value, FIRST_PAYMENT_DATE_SHIFT)
      onChange('firstPaymentDate', nextFirstPaymentDate)
    }

    refLastClickedDealId.current = dealId
  }, [dealId, onChange])

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <BlockSkeleton className='section-title' isLoading={isLoading}>
        <h6>Deal</h6>
      </BlockSkeleton>

      <div className='fields'>
        <LabeledFieldSkeleton isLoading={isLoading}>
          <label htmlFor='dealFinanceTypeId'>Payment type:</label>

          <FormDropdown
            error={errors.dealFinanceTypeId}
            disableClearable
            disabled={isWholesale || isRecorded}
            formatDisplayValue={formatPaymentType}
            id='dealFinanceTypeId'
            mode='search'
            onBlur={onBlur}
            onChange={onChangePaymentType}
            options={PAYMENT_TYPE_OPTIONS}
            placeholder='Select type'
            renderOption={getPaymentTypeName}
            value={values.dealFinanceTypeId}
            touched={touched.dealFinanceTypeId}
          />
        </LabeledFieldSkeleton>

        <LabeledFieldSkeleton isLoading={isLoading}>
          <label htmlFor='vehicle.listPrice'>Sale price:</label>

          <FormNumberInput
            error={errors.vehicle.listPrice}
            id='vehicle.listPrice'
            isNegativeAllowed={false}
            max={MAX_PRICE_VALUE}
            onBlur={onBlur}
            onChange={onChange}
            placeholder='Sale price'
            preset='financial'
            touched={touched.vehicle.listPrice}
            value={values.vehicle.listPrice}
            disabled={isRecorded}
          />
        </LabeledFieldSkeleton>

        {(values.isTradeInSectionVisible || isLoading) && (
          <LabeledFieldSkeleton isLoading={isLoading}>
            <label htmlFor='tradeInDetails.credit'>Trade in credit:</label>

            <FormNumberInput
              error={errors.tradeInDetails.credit}
              id='tradeInDetails.credit'
              isNegativeAllowed={false}
              max={MAX_PRICE_VALUE}
              onBlur={onBlur}
              onChange={onChange}
              placeholder='Trade in credit'
              preset='financial'
              touched={touched.tradeInDetails.credit}
              value={values.tradeInDetails.credit}
              disabled={isRecorded}
            />
          </LabeledFieldSkeleton>
        )}

        {(values.isTradeInSectionVisible || isLoading) && (
          <LabeledFieldSkeleton isLoading={isLoading}>
            <label htmlFor='tradeInDetails.paymentAmount'>Trade in payoff:</label>

            <FormNumberInput
              error={errors.tradeInDetails.paymentAmount}
              id='tradeInDetails.paymentAmount'
              isNegativeAllowed={false}
              max={MAX_PRICE_VALUE}
              onBlur={onBlur}
              onChange={onChange}
              placeholder='Trade in payoff'
              preset='financial'
              touched={touched.tradeInDetails.paymentAmount}
              value={values.tradeInDetails.paymentAmount}
              disabled={isRecorded}
            />
          </LabeledFieldSkeleton>
        )}

        {(values.isTradeInSectionVisible || isLoading) && (
          <LabeledFieldSkeleton isLoading={isLoading}>
            <label htmlFor='tradeInDetails.acv'>ACV:</label>

            <FormNumberInput
              error={errors.tradeInDetails.acv}
              id='tradeInDetails.acv'
              isNegativeAllowed={false}
              max={MAX_PRICE_VALUE}
              onBlur={onBlur}
              onChange={onChange}
              placeholder='ACV'
              preset='financial'
              touched={touched.tradeInDetails.acv}
              value={values.tradeInDetails.acv}
              disabled={isRecorded}
            />
          </LabeledFieldSkeleton>
        )}

        <LabeledFieldSkeleton isLoading={isLoading}>
          <label htmlFor='salesperson'>Salesperson:</label>

          <FormDropdown
            error={errors.salesperson}
            id='salesperson'
            blurMode='select-first'
            mode='search'
            onBlur={onBlur}
            onChange={onChange}
            options={users}
            placeholder='Select salesperson'
            popoverClassName={POPOVER_CLASS_NAME}
            touched={touched.salesperson}
            value={values.salesperson}
            disabled={isRecorded}
          />
        </LabeledFieldSkeleton>

        {!isLoading && (
          <div className='labeled-field'>
            <label htmlFor='saleDate'>Sale date:</label>

            <FormDatePicker
              className='date-picker sale-date'
              id='saleDate'
              onBlur={onBlur}
              onChange={onSaleDateChange}
              value={values.saleDate}
              error={errors.saleDate}
              touched={touched.saleDate}
              disabled={isRecorded}
            />
          </div>
        )}

        {!isLoading && !isFinancing && (
          <div className='labeled-field'>
            <label htmlFor='firstPaymentDate'>First payment date:</label>

            <FormDatePicker
              id='firstPaymentDate'
              minDate={values.saleDate}
              onBlur={onBlur}
              onChange={onChange}
              error={errors.firstPaymentDate}
              touched={touched.firstPaymentDate}
              value={values.firstPaymentDate}
              disabled={isRecorded}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default DealSection

// ========================================== //

const formatPaymentType = (paymentType: number | null): string => {
  return getPaymentTypeName(paymentType) ?? ''
}
