import { formatCurrencyDecimal, formatPercentages } from '@carfluent/common'

import { type DealerProductCoverageMarkupDto } from 'api/types/responses'

export const getPriceConfigTitle = (markup: DealerProductCoverageMarkupDto): string => {
  if (markup.markupFixed !== null) {
    return formatCurrencyDecimal(markup.markupFixed, { prefix: 'Fixed price: $' })
  }

  if (markup.markupAmount !== null || markup.markupPercent !== null) {
    const formattedPercentages = formatPercentages(markup.markupPercent, { prefix: '+' })
    const formattedCurrency = formatCurrencyDecimal(markup.markupAmount, { prefix: '+$' })

    if (markup.markupAmount === null) {
      return `Markup: ${formattedPercentages}`
    }

    if (markup.markupPercent === null) {
      return `Markup: ${formattedCurrency}`
    }

    return `Markup: ${formattedPercentages}, ${formattedCurrency}`
  }

  return 'No markup'
}
