import { type ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  type DeepRequired,
  UI,
  useForm,
  useModal,
  useLoader
} from '@carfluent/common'

import { type MessageSnippetDto } from 'api/types/responses'
import NotificationsAPIProvider from 'api/notifications.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { Routes } from 'constants/route_helper'
import { isTruthy } from 'utils/general'
import { ErrorMsg } from 'utils/yup'
import CRMApiProvider from 'api/crm.api'

import type {
  MessageTemplateFormData,
  MessageTemplatePayload,
  UseMessageTemplateFormReturn
} from './types'
import {
  FieldIds,
  Messages,
  getDefaultFormData,
  getDefaultMessageTemplatePayloadData,
  BODY_LINE_HEIGHT,
  TEMPLATE_IN_USE_CODE
} from './constants'
import validationRules from './validator'
import serializeData from './serializer'
import { configurateSnippets } from './utils'

const DEFAULT_FORM_DATA = getDefaultFormData()
const DEFAULT_PAYLOAD_DATA = getDefaultMessageTemplatePayloadData()

const { parseErrorsObject } = UI

const useMessageTemplateForm = (): UseMessageTemplateFormReturn => {
  const navigate = useNavigate()
  const { showAlert, showSuccess } = useCustomSnackbar()
  const { id: templateId = '' } = useParams<{ id: string }>()
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [messageTemplate, setMessageTemplate] = useState<MessageTemplatePayload>(DEFAULT_PAYLOAD_DATA)
  const [snippets, setSnippets] = useState<MessageSnippetDto[]>([])
  const [errorName, setErrorName] = useState<string | null>(null)

  const { isLoading, startLoader, stopLoader } = useLoader()

  const templateDeleteProps = useModal()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const deleteAction = useCallback(async (force = false) => {
    try {
      await NotificationsAPIProvider.deleteMessageTemplate({ id: templateId, force })
      showSuccess('Template deleted successfully.')
      navigate(Routes.CRMMessageTemplatesList)
    } catch (err: any) {
      if (err?.response.data.code === TEMPLATE_IN_USE_CODE) {
        templateDeleteProps.onOpenModal()
      } else {
        showAlert(err)
      }
    }
  }, [navigate, templateId, showAlert])

  const submitAction = useCallback(async (values: DeepRequired<MessageTemplateFormData>) => {
    try {
      setIsFormSubmitting(true)
      setErrorName(null)

      const payload = {
        id: messageTemplate.id,
        dealerId: messageTemplate.dealerId,
        ...values
      }
      const serializedPayload = serializeData(payload)
      const templateData = await NotificationsAPIProvider.updateMessageTemplate(serializedPayload)

      setMessageTemplate(templateData)
    } catch (err: any) {
      const msg = parseErrorsObject(err?.response?.data.errors)
      if (isTruthy(msg)) {
        throw new Error(ErrorMsg.nameAlreadyTaken)
      }
    } finally {
      setIsFormSubmitting(false)
    }
  }, [messageTemplate.id, messageTemplate.dealerId])

  const onResetForm = useCallback((resetForm) => {
    resetForm(DEFAULT_FORM_DATA)
  }, [])

  const onActionResult = useCallback((res) => {
    if (res.kind === 'Ok') {
      showAlert(Messages.SuccessUpdate, { variant: 'success' })
      navigate(Routes.CRMMessageTemplatesList)
    } else {
      setErrorName(res.result.message)
    }
  }, [navigate, showAlert])

  const form = useForm<MessageTemplateFormData>({
    baseValues: DEFAULT_FORM_DATA,
    validationRules,
    submitAction,
    deleteAction,
    onActionResult
  })

  const { isValid, setFieldError, setFieldTouched, resetForm, onChange } = form

  const onNameChange = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    onChange(FieldIds.Name, e.target.value)
    setFieldTouched(FieldIds.Name, true)
  }, [onChange, setFieldTouched])

  const onCancel = useCallback(() => {
    onResetForm(resetForm)
    navigate(Routes.CRMMessageTemplatesList)
  }, [resetForm, onResetForm, navigate])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      startLoader()

      const [
        templateData,
        { items: snippets }
      ] = await Promise.all([
        NotificationsAPIProvider.getMessageTemplate(templateId),
        CRMApiProvider.getMessageSnippets()
      ])

      setMessageTemplate(templateData)
      setSnippets(configurateSnippets(snippets))

      onChange(FieldIds.Name, templateData.name)
      onChange(FieldIds.Body, templateData.body)

      const textarea = document.getElementById(FieldIds.Body) as HTMLTextAreaElement

      const lines = templateData.body?.split('\n')
      const contentHeight = (lines?.length ?? 0) * BODY_LINE_HEIGHT

      textarea.style.height = `${contentHeight}px`

      stopLoader()
    }

    void runEffect()
  }, [templateId, startLoader, stopLoader, onChange])

  useEffect(() => {
    if (errorName != null) {
      setFieldError(FieldIds.Name, errorName)
    }
  }, [errorName, setFieldError])

  // ========================================== //

  return {
    isDisabledActionBtn: !isValid,
    isLoading,
    isFormSubmitting,
    snippets,
    addSnippetError: Messages.FailAddSnippet,
    messageTemplate,
    templateDeleteProps,
    onDeleteTemplate: deleteAction,
    onCancel,
    onNameChange,
    ...form
  }
}

export default useMessageTemplateForm
