import { css } from '@emotion/css'

export default css(`
  && {
    &.cf-modal-container {
      .cf-modal-root {
        .cf-modal-content {
          .user-management-invite-input {
            display: flex;
            flex-direction: column;
            
            > div {
              margin-top: 24px;
            }
            
            .error-text, .cf-form-field-error {
              margin-top: 16px;
              color: #B00020;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0.4px;
            }
          
            .error-text {
              margin-top: 2px;
              margin-bottom: -18px;
              margin-left: 16px;
            }

            .checkboxes-wrapper {
              .checkboxes-title {
                margin-bottom: 16px;
              }

              .items-list {
                display: grid;
                grid-gap: 16px;

                div { padding-left: 2px; }
                label { height: 24px; } 
              }
            }

            button {
              max-height: 58px;
            }
          
            .invite-email-input {
              background: #F3F3F3;
              min-height: 80px;
              border: none;
              padding: 7px 0px;
              border-radius: 12px;
              box-shadow: none;
              display: flex;

              &.field-error {
                border: 1px solid #B00020;
              }
          
              &.row {
                flex-direction: row;
                align-items: flex-start;
              }
          
              &.column {
                flex-direction: column;
                align-items: stretch;
              }
          
              :not(.field-error):hover, :not(.field-error).Mui-focused {
                border: none;
              }
          
              .email-wrapper {
                padding: 0 16px;
                display: grid;
                grid-template-columns: 30px 1fr;
          
                .email-wrapper-label {
                  line-height: 36px;
                  margin-right: 8px;
                }
          
                .invite-email-chip {
                  height: 36px;
                  font-size: 14px;
                  padding: 8px 16px;
                  background: #F3F3F3;
                  border: 1px solid rgba(0, 0, 0, 0.12);
                  border-radius: 8px;
                  margin: 0px 4px 4px 0px;
          
                  :focus {
                    background: #F3F3F3;
                  }
          
                  .delete-icon {
                    width: 12px;
                    height: 12px;
                    margin: 0px;
                  }
                }
              }
            }
          }
        
        p {
          font-family: Roboto, sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
`)
