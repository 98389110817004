import { WrapperRequest } from './wrapper.api'
import type {
  DictionaryItem,
  ListResponse,
  MarketResponse,
  MarketParams,
  PaginatedResult,
  ListPayload,
  AppraisalDetails,
  NewAppraisal
} from './types'

class AppraisalApi extends WrapperRequest {
  url = process.env.REACT_APP_APPRAISAL ?? ''

  getMarketVehicles = async (params: MarketParams): Promise<MarketResponse> => {
    return await this.post(`${this.url}/api/v1/MarketVehicles/search`, params)
  }

  getMakes = async (): Promise<ListResponse<DictionaryItem>> => {
    return await this.get(`${this.url}/api/v1/Hierarchy/makes`)
  }

  getModels = async (makeId: number): Promise<ListResponse<DictionaryItem>> => {
    return await this.get(`${this.url}/api/v1/Hierarchy/makes/${makeId}/models`)
  }

  getTrims = async (modelId: number): Promise<ListResponse<DictionaryItem>> => {
    return await this.get(`${this.url}/api/v1/Hierarchy/models/${modelId}/trims`)
  }

  getTrimsByName = async (make: string, model: string): Promise<ListResponse<DictionaryItem>> => {
    return await this.get(`${this.url}/api/v1/Hierarchy/makes/${make}/models/${model}/trims`)
  }

  createNewAppraisal = async (data: NewAppraisal): Promise<AppraisalDetails> => {
    return await this.post(`${this.url}/api/v1/VehicleAppraisalDetails`, data)
  }

  getAppraisals = async (payload: ListPayload): Promise<PaginatedResult<AppraisalDetails>> => {
    return await this.post(`${this.url}/api/v1/VehicleAppraisalDetails/list`, payload)
  }

  getAppraisalDetails = async (id: string | number): Promise<AppraisalDetails> => {
    return await this.get(`${this.url}/api/v1/VehicleAppraisalDetails/${id}`)
  }

  deleteAppraisal = async (id: number | string): Promise<void> => {
    return await this.delete(`${this.url}/api/v1/VehicleAppraisalDetails/${id}`)
  }

  addToInventory = async (id: number | string): Promise<{inventoryId: number}> => {
    return await this.post(`${this.url}/api/v1/VehicleAppraisalDetails/${id}/inventory`)
  }

  updateAppraisal = async (id: string | number, payload: Partial<AppraisalDetails>) => {
    return await this.patch(`${this.url}/api/v1/VehicleAppraisalDetails/${id}`, payload)
  }
}

export const AppraisalAPIProvider = new AppraisalApi()
export default AppraisalAPIProvider
