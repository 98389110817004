import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { formatLocalDateTimeForView } from 'utils/view_helper'
import { DATE_US_FORMAT } from 'constants/constants'
import { DealView } from 'constants/route_helper'
import DealStatus from 'components/common/DealStatus'
import TooltipedText from 'components/common/TooltipedText'

import { type LeadInfoProps } from './types'
import CLASS_NAME from './styles'

const LeadInfo: FC<LeadInfoProps> = ({
  className,
  dealState,
  isLoading = false,
  lead
}) => {
  const isEmptyInfo = lead.firstName == null && lead.lastName == null && lead?.birthDate == null
  const birthDate = formatLocalDateTimeForView(lead.birthDate, DATE_US_FORMAT)

  return (
    <div className={cnx(CLASS_NAME, 'lead-info', className)}>
      {isEmptyInfo && (<span className='lead-info-empty'>-</span>)}
      {!isEmptyInfo && (
        <div
          className='lead-info-details'
          style={{
            width: 'auto',
            whiteSpace: 'nowrap',
            maxWidth: `calc(100% - ${(lead.dealId != null && dealState != null ? 140 : 0) + (lead.isUnsubscribed ? 110 : 0)}px)`
          }}
        >
          <TooltipedText
            className={cnx('name', (lead?.birthDate) != null && 'name-with-birthDate')}
            rootClassName='lead-name-container'
            value={`${lead.firstName ?? ''} ${lead.lastName ?? ''}`}
          />

          {((lead?.birthDate) != null) && <span className='birth-date'>&nbsp; ({birthDate})</span>}
        </div>
      )}

      <div className='status-container'>
        {!isLoading && lead.dealId != null && dealState != null && (
          <a className='source-link' href={DealView(lead.dealId) ?? '#'} target='_self' rel='noreferrer'>
            <DealStatus
              dealState={dealState}
              getTitle={(name) => `Deal - ${name.toLowerCase()}`}
              className='lead-info-deal-status'
            />
          </a>
        )}

        {lead.isUnsubscribed && (
          <span className='status-unsubscribed'>Unsubscribed</span>
        )}
      </div>
    </div>
  )
}

export default LeadInfo
