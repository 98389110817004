import { formatPercentagesDecimal, formatCurrencyDecimal } from '@carfluent/common'

import DateCell from 'components/common/Table/cells/DateCell'
import ValueCell from 'components/common/Table/cells/ValueCell'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import StatusCell from '../components/StatusCell'

import type { ColumnDefinitions } from './types'

const columnDefinitions: ColumnDefinitions = [
  {
    accessorKey: 'name',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Campaigns' }),
    sortable: true,
    size: 265
  },
  {
    accessorFn: (row) => row.campaignType?.name ?? '-',
    id: 'campaignTypeId',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Type' }),
    sortable: false,
    size: 100
  },
  {
    id: 'delivered',
    accessorFn: (row) => row?.campaignStatistic?.totalSentCount ?? '-',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Total sent' }),
    sortable: false,
    minSize: 85,
    maxSize: 100
  },
  {
    accessorFn: (row) => row.campaignStatus?.name ?? '-',
    id: 'campaignStatusId',
    cell: StatusCell,
    header: TextCellHOC({ text: 'Status' }),
    sortable: false,
    size: 120,
    className: 'status-cell'
  },
  {
    id: 'uniqueOpensRate',
    accessorFn: (row) => row?.campaignStatistic != null ? formatPercentagesDecimal(row?.campaignStatistic.openRate * 100) : '-',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Open rate' }),
    sortable: true,
    size: 110
  },
  {
    id: 'uniqueClicksRate',
    accessorFn: (row) => row?.campaignStatistic != null ? formatPercentagesDecimal(row?.campaignStatistic.clickRate * 100) : '-',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Click rate' }),
    sortable: true,
    size: 110
  },
  {
    id: 'appointmentsCount',
    accessorFn: (row) => row?.campaignStatistic?.appointmentsCount ?? '-',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Appts' }),
    sortable: true,
    size: 100
  },
  {
    id: 'salesCount',
    accessorFn: (row) => row?.campaignStatistic?.salesCount ?? '-',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Sales' }),
    sortable: true,
    size: 100
  },
  {
    id: 'totalGrossAmount',
    accessorFn: (row) => formatCurrencyDecimal(row?.campaignStatistic?.totalGrossAmount, { allowNegative: true }) ?? '-',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Total gross' }),
    sortable: true,
    size: 120
  },
  {
    accessorFn: (row) => ({
      value: row.scheduledDateTime,
      isMultiline: true
    }),
    id: 'scheduledDateTime',
    cell: DateCell,
    header: TextCellHOC({ text: 'Scheduled on' }),
    sortable: true,
    size: 130,
    className: 'date-cell'
  }
]

export default columnDefinitions
