import type { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { cn } from '@carfluent/common'

import CalcRoutes from 'constants/route_helper'

import CloseImg from 'assets/close_black.svg'
import InvertedBorderImg from 'assets/inverted_border.svg'

import CLASS_NAME from './styles'

export interface DealNotificationProps {
  workflowId: number
  dealVehicle: string | null
  onClose: () => void
  isLastElement: boolean
}

const DealNotification: FC<DealNotificationProps> = ({
  workflowId,
  dealVehicle,
  onClose,
  isLastElement
}) => {
  return (
    <div className={CLASS_NAME}>
      <div className='cf-snackbar-content'>
        <NavLink to={CalcRoutes.DealView(workflowId)} className='cf-link' onClick={onClose}>
          <img src={InvertedBorderImg} className='cf-inverted-border' alt='' />
          <div>
            <p className='cf-title'>Pending Deal</p>
            <p className={cn('cf-title', 'cf-deal-vehicle')}>{dealVehicle ?? ''}</p>
          </div>
        </NavLink>
        <button className='cf-close-button' onClick={onClose}>
          <img src={CloseImg} className='cf-close-img' alt='X' />
        </button>
      </div>
      {!isLastElement ? <hr className='cf-horizontal-line' /> : null}
    </div>
  )
}

export default observer(DealNotification)
