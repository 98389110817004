import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  h5 {
    margin-bottom: 24px;
  }

  .banner {
    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &-item {
      &:after {
        display: none
      }
      &.cf-banner-appeared {
        &:last-child {
          margin-bottom: 24px;
        }
      }
    }
  }


  .cf-dropdown-root {
    width: 320px;
  }

  .field-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .dropzone-content {
    height: 112px;
    width: 100%;
    border: 1px solid black;
    position: relative;
  }

  .button-panel-container {
    width: calc(100% - 64px);
  }
`)
