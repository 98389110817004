import { css } from '@emotion/css'

export default css(`
  .cf-button-content {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }
`)
