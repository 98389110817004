import type { Group, Groups, Rows } from '@carfluent/common'
import { type ColumnDef } from '@tanstack/react-table'
import { type CoverageFeeSettingsDto } from 'api/types/responses'
import type { SaleTaxRowData } from './types'

export const groupByState = (stateAmount: Record<string, number>) => (rows: Rows<SaleTaxRowData>): Groups<SaleTaxRowData, string> => {
  const groups = rows.reduce((acc: Map<string, Group<SaleTaxRowData, string>>, row) => {
    const groupId = row.original.state ?? ''
    const group = acc.get(groupId) ?? { rows: [], key: '' }

    acc.set(groupId, {
      rows: [...group.rows, row],
      key: `${group.key},${row.id}`,
      amount: stateAmount[groupId] ?? 0,
      meta: groupId
    })

    return acc
  }, new Map())

  return [...groups.values()]
}

// formatNumber for show number w/o rounding to more and show
// Input: 0.1025 - Result: "10,25"
// Input: 0.10256 - Result: "10,256"
// Input: 0.7 - Result: "70,00"
// Input: 0.70000001 - Result: "70,00"
// Input: 0.700023 - Result: "70,002"

const formatNumber = (value: number): string => {
  // Multiply by 100 to move decimal two places to the right
  const multipliedNumber = value * 100

  // Round to 3 decimal places
  const roundedNumber = Math.round(multipliedNumber * 1000) / 1000

  // Convert to a string with 3 decimal places
  const formattedNumber = roundedNumber.toFixed(3)

  // Replace the decimal point with a comma and remove leading zeros
  const result = formattedNumber.replace(/\.(.{2})0?/, '.$1')

  return result
}

// helper for view percentages number in Sales tax report with uniq function for formatting
export const viewHelperForPercentagesDecimal = (value: number): string => {
  if (value != null) {
    return `${formatNumber(value)}%`
  } else {
    return '0.00%'
  }
}

export const shouldShowColumn = (settings: CoverageFeeSettingsDto | null) => (column: ColumnDef<SaleTaxRowData>): boolean => {
  if (settings === null) return true

  switch (column.id) {
    case 'buyerTag':
      return settings.buyerTagSetting.isEnabled && settings.buyerTagSetting.isTaxable
    case 'carDelivery':
      return settings.carDeliverySetting.isEnabled && settings.carDeliverySetting.isTaxable
    case 'dealerHandlingFee':
      return settings.dealerHandlingFeeSetting.isEnabled && settings.dealerHandlingFeeSetting.isTaxable
    case 'dealerInventoryTax':
      return settings.dealerInventoryTaxSetting.isEnabled && settings.dealerInventoryTaxSetting.isTaxable
    case 'emissions':
      return settings.emissionsSetting.isEnabled && settings.emissionsSetting.isTaxable
    case 'inspectionFee':
      return settings.inspectionFeeSetting.isEnabled && settings.inspectionFeeSetting.isTaxable
    case 'plateTransferFee':
      return settings.plateTransferFeeSetting.isEnabled && settings.plateTransferFeeSetting.isTaxable
    case 'registrationFee':
      return settings.registrationFeeSetting.isEnabled && settings.registrationFeeSetting.isTaxable
    case 'titleFee':
      return settings.titleFeeSetting.isEnabled && settings.titleFeeSetting.isTaxable

    case 'serviceContract':
      return settings.serviceContractTaxable
    case 'gap':
      return settings.gapTaxable

    case 'aftermarketsProduct':
      return settings.tireAndWheelTaxable || settings.appearanceProtectionTaxable || settings.theftTaxable

    default:
      return true
  }
}
