import { type ColumnDef } from '@carfluent/common'

import TextCellHOC from 'components/common/Table/cells/TextCell'
import CheckboxCell from 'components/common/Table/cells/CheckboxCell'
import EditableCellHOK from 'components/common/Table/cells/editable_cell'

import FormCell from '../../cells/FormCell'
import ActionCell from '../../cells/ActionCell'
import type { ColumnsConfig, FormProps } from './types'

const getColumnDefinitions = ({ onToggleRowCheckbox }: ColumnsConfig): Array<ColumnDef<FormProps>> => [
  {
    accessorKey: 'checked',
    cell: EditableCellHOK<FormProps>(
      {
        className: 'cf-checkbox-cell',
        onChange: onToggleRowCheckbox
      },
      CheckboxCell
    ),
    className: 'checkbox-class-name',
    header: TextCellHOC({ text: '' }),
    sortable: false,
    maxSize: 48
  },
  {
    accessorFn: (row) => row,
    cell: FormCell,
    id: 'form-fields',
    className: 'form-fields-class-name',
    header: TextCellHOC({ text: 'Forms' }),
    sortable: false,
    size: 740
  },
  {
    maxSize: 68,
    size: 68,
    id: 'actions',
    sortable: false,
    cell: ActionCell,
    accessorFn: (row) => row,
    className: 'actions-class-name',
    header: TextCellHOC({ text: '' })
  }
]

export default getColumnDefinitions
