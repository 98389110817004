import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { type AccountListItem } from 'api/types'
import RadioGroup from 'components/common/RadioGroup'
import RadioLabelControl from 'components/common/RadioLabelControl'

import PurchaseAccountsSectionSkeleton from '../PurchaseAccountsSectionSkeleton'
import CLASS_NAME from './styles'

const PURCHASE_ACCOUNTS_TITLE = {
  purchaseAccountId: 'How did you pay for vehicle?',
  creditAccountId: 'How did you pay for this cost?'
} as const

interface PurchaseAccountsSectionProps {
  id: 'purchaseAccountId' | 'creditAccountId'
  value: number | null
  purchaseAccountIsLoading: boolean
  purchaseAccountOptions: AccountListItem[]
  onChangePaymentAccount: (id: string, value: number) => void
}

const PurchaseAccountsSection: FC<PurchaseAccountsSectionProps> = ({
  id,
  onChangePaymentAccount,
  purchaseAccountOptions,
  purchaseAccountIsLoading,
  value
}) => {
  return (
    <div className={cnx('g-full-width', CLASS_NAME)}>
      <div className='g-horizontal-line' />

      <h6 className='g-section-title'>
        {PURCHASE_ACCOUNTS_TITLE[id]}
      </h6>

      <div>
        {purchaseAccountIsLoading && <PurchaseAccountsSectionSkeleton />}
        {!purchaseAccountIsLoading && (
          <RadioGroup
            id={id}
            value={(value ?? '').toString()}
            onChange={(_, value: string) => { onChangePaymentAccount(id, Number(value)) }}
          >
            {purchaseAccountOptions.map(el =>
              <RadioLabelControl value={el.id.toString()} key={el.id} label={el.name} />
            )}
          </RadioGroup>
        )}
      </div>
    </div>
  )
}

export default PurchaseAccountsSection
