import { css } from '@emotion/css'

export default css(`
  display: flex;
  
  .fields {
    .block-skeleton.is-loading {
      width: 260px;
    }
    
    .MuiFormControl-root .cf-disabled .MuiInputLabel-root { color: rgba(33, 33, 33, 0.4);}
  }
`)
