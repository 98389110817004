import type { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { cnx } from '@carfluent/common'

import type { DealsListItem } from 'api/types'
import Button from 'components/common/Button'

import { useNotifications } from '../NotificationsPresenter/hook'

import DealNotification from './DealNotification'

import CLASS_NAME from './styles'

const DealsNotifications: FC = () => {
  const { notifications, onCloseNotification, onClearNotifications } = useNotifications()

  const isNotifications = notifications.length !== 0
  const lastElement = notifications.length - 1

  return (
    isNotifications
      ? (
        <div className={cnx(CLASS_NAME, 'g-non-printable-block')}>
          <div className='cf-snackbar-header'>
            <p className='cf-title'>Notifications</p>
            <Button className='cf-close-all-btn' variant='text' onClick={onClearNotifications}>
              Clear all
            </Button>
          </div>
          <hr className='cf-horizontal-line' />
          {notifications.map((notification: DealsListItem, index: number) => (
            <DealNotification
              key={notification.workflowId}
              workflowId={notification.workflowId != null ? notification.workflowId : 3}
              dealVehicle={notification.vehicleModel != null ? `${notification.vehicleModelYear} ${notification.vehicleModel}` : '-'}
              onClose={() => onCloseNotification(notification.workflowId)}
              isLastElement={lastElement === index}
            />
          ))}
        </div>
        )
      : null
  )
}

export default observer(DealsNotifications)
