import { useContext, useEffect, useCallback, useState } from 'react'
import { useModal, useForm } from '@carfluent/common'

import SettingsCTX from 'store/settings'
import DealersApiProvider from 'api/dealers.api'

import { onlyNumbersAndDot } from 'utils/format_number'
import { trimSpaces } from 'utils/general'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useTransitionBlocker from 'hooks/useTransitionBlocker'
import geocodeClient from 'utils/googleGeocodeClient'

import type { ErrTouchShortcuts, ProfileFormData, UseProfileReturn } from './types'
import formValidation from './validation'
import { parseProfileData, DEFAULT_PROFILE_DATA } from './parsers'
import serializeProfileData from './serializer'

interface DefaultGeocodeData {
  latLng: google.maps.LatLngLiteral
}

const DEFAULT_GEOCODE_DATA: DefaultGeocodeData = {
  latLng: { lat: 0, lng: 0 }
}

const FIELD_TRANSFORMS = {
  phone: onlyNumbersAndDot,
  email: trimSpaces,
  leadCmsEmails: trimSpaces
}

const useProfile = (): UseProfileReturn => {
  const { dealer, dealersAction, isLoadingDealersAction } = useContext(SettingsCTX)
  const { showAlert } = useCustomSnackbar()

  const [logoUrl, setLogoUrl] = useState<string>(dealer.logoUrl)
  const [logoPreviewUrl, setLogoPreviewUrl] = useState<string>('')

  const [modalDecisionPromise, setModalDecisionPromise] = useState<((decision: boolean) => void) | null>(null)

  const {
    isModalOpen: isLogoUpdatedModalOpen,
    onOpenModal: onOpenLogoUpdatedModal,
    onCloseModal: _onCloseLogoUpdatedModal
  } = useModal()

  const {
    isModalOpen: isOpenUnsavedChanges,
    onCloseModal: onCloseUnsavedDialog,
    onOpenModal: onOpenUnsavedDialog
  } = useModal()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const setLogoAndPreviewUrl = useCallback((url: string): void => {
    setLogoPreviewUrl(url)
    setLogoUrl(url)
  }, [])

  const showModal = useCallback(async (): Promise<boolean> => {
    return await new Promise((resolve) => {
      setModalDecisionPromise(() => resolve)
      onOpenLogoUpdatedModal()
    })
  }, [onOpenLogoUpdatedModal])

  const onModalContinue = useCallback((): void => {
    modalDecisionPromise?.(true)
    setModalDecisionPromise(null)
    _onCloseLogoUpdatedModal()
  }, [_onCloseLogoUpdatedModal, modalDecisionPromise])

  const onCloseLogoUpdatedModal = useCallback((): void => {
    modalDecisionPromise?.(false)
    setModalDecisionPromise(null)
    _onCloseLogoUpdatedModal()
  }, [_onCloseLogoUpdatedModal, modalDecisionPromise])

  const onUpdateProfile = useCallback(async (values: ProfileFormData) => {
    const { latLng } = await geocodeClient.geocodeByAddress({
      address: values.addressData?.address,
      city: values.addressData?.city,
      zipCode: values.addressData?.zipCode,
      state: values.addressData?.state
    }) ?? DEFAULT_GEOCODE_DATA

    const dataToSave = serializeProfileData(values, dealer, latLng)
    await DealersApiProvider.patchDealers(dataToSave)
  }, [dealer, dealersAction])

  const submitAction = useCallback(async (values: ProfileFormData): Promise<ProfileFormData> => {
    if (dealer.logoUrl !== values.logoUrl) {
      const shouldProceed = await showModal()
      if (!shouldProceed) {
        throw new Error()
      }

      await onUpdateProfile(values)

      setLogoUrl(values.logoUrl)
      setLogoAndPreviewUrl(values.logoUrl)
      return values
    } else {
      await onUpdateProfile(values)
      return values
    }
  }, [
    dealer.logoUrl,
    showModal,
    setLogoAndPreviewUrl,
    onUpdateProfile
  ])

  const onActionResult = useCallback((_res, resetForm) => {
    if (_res.kind === 'Ok') {
      showAlert('Changes successfully saved', { variant: 'success' })
      resetForm(_res.result)
    }
  }, [showAlert, dealersAction])

  const form = useForm<ProfileFormData, ErrTouchShortcuts>({
    baseValues: DEFAULT_PROFILE_DATA,
    validationRules: formValidation,
    transforms: FIELD_TRANSFORMS,
    isTrackingChanges: true,
    submitAction,
    onActionResult
  })

  const {
    isSubmitting,
    isValid,
    values,
    errors,
    touched,
    hasChanges,
    isFormChanged,
    onBlur,
    onChange,
    onSubmit,
    resetForm
  } = form

  const onCancelEdit = useCallback(async (): Promise<void> => {
    setLogoAndPreviewUrl(dealer.logoUrl)
    resetForm(parseProfileData(dealer))
    showAlert('Changes discarded.', { variant: 'success' })
  }, [dealer, resetForm, showAlert, setLogoAndPreviewUrl])

  const { proceedTransition } = useTransitionBlocker({
    shouldBlock: isFormChanged(),
    onBlock: onOpenUnsavedDialog
  })

  const onDontSaveAndClose = (): void => {
    onCloseUnsavedDialog()
    proceedTransition()
  }

  const onSaveAndClose = async (): Promise<void> => {
    if (!isValid) {
      onCloseUnsavedDialog()
      return
    }

    await onSubmit()
    onDontSaveAndClose()
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const syncValues = async (): Promise<void> => {
      const res = await dealersAction()

      if (res != null) {
        const parsed = parseProfileData(res)
        resetForm(parsed)
        setLogoUrl(parsed.logoUrl)
      }
    }

    void syncValues()

    return () => {
      setLogoAndPreviewUrl(dealer.logoUrl)
      resetForm(parseProfileData(dealer))
    }
  }, [dealersAction, dealer.logoUrl, resetForm, setLogoAndPreviewUrl])

  // ========================================== //

  return {
    isLoading: isLoadingDealersAction,
    isOpenUnsavedChanges,
    termsUrl: dealer.termsUrl,
    logoPreviewUrl,
    logoUrl,
    isSubmitting,
    values,
    errors,
    touched,
    hasChanges,
    onBlur,
    onChange,
    onSubmit,
    setLogoAndPreviewUrl,
    onCancelEdit,
    isLogoUpdatedModalOpen,
    onCloseLogoUpdatedModal,
    onCloseUnsavedDialog,
    onDontSaveAndClose,
    onModalContinue,
    onSaveAndClose
  }
}

export default useProfile
