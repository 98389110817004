import { css } from '@emotion/css'

export default css(`
  &.g-submenu-content {
    margin-bottom: 80px;
  }

  .g-filter-tabs-container {
    padding: 4px 0;
  }

  .form-container {
    margin-top: 32px;
  }

  .question-section {
    margin-bottom: 32px;

    .checkmark-label {
      font-size: 16px;
      color: #101010;
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    > div:last-of-type {
      border-radius: 12px;
      border: 1px solid transparent;

      :hover {
        border-color: #DDB3A0!important;
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 16px;
      letter-spacing: 0.15px;
    }

    > div:first-of-type {
      width: 300px;
      margin-bottom: 24px;
    }

    &.full-width {
      > div:first-of-type {
        width: auto;
      }
    }

    .cf-input-label,
    .cf-display-label {
      color: #21212180;
    }

    .with-error .cf-input-label {
      color: var(--cf-input-error-color);
    }

    textarea {
      background: #fafafa;
      height: 108px!important;
    }

    .cf-label {
      color: #21212180;
      background: #fafafa;
    }

    .cf-active {
      &:has(textarea:focus) {
        border-color: #DDB3A0 !important;
      }

      .cf-label {
        top: 0px;
        left: 1px;
      }
    }

    .question-value-container {
      display: flex;
      width: auto!important;

      > div:first-of-type {
        width: 300px;
        margin-right: 16px;
      }

      > div:last-of-type {
        flex: 1;
      }
    }

    .row-list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 8px!important;

      > div {
        margin-right: 16px;
        margin-bottom: 16px;
      }
    }
  }

  .toggle {
    margin-bottom: 32px;

    p {
      font-size: 24px;
      color: #101010;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
    }


    span {
      border: 1px solid #00000012;
      background: rgba(0, 0, 0, 0.3);

      &.active {
        background: #219653;
      }

      :before {
        left: 1px;
        bottom: 1px;
      }
    }
  }

  .tab-footer {
    height: 80px;
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: flex-end;
    box-shadow: 0px -2px 12px 0px #0000001F;
    
    left: 0px;
    right: 0px;
    bottom: -80px;
    z-index: 1102;
    position: fixed;
    padding-right: 64px;
    transition: bottom 0.3s ease;

    &.is-shown {
      bottom: 0px;
    }

    button {
      margin: 0px;

      &:first-child {
        margin-right: 24px;
      }
    }
  }

  .g-filter-tab-active-indicator {
    transition: 0ms!important;
  }

  .g-active-filter-tab {
    .g-filter-tab-text {
      font-weight: 500;
    }
  }

  .g-filter-panel-tabs .g-filter-tabs-container {
    button.cf-button-root.g-filter-tab {
      background: #F3F3F3;

      :hover {
        background: #ddd;

      }
    }

    .g-active-filter-tab {
      background: transparent!important;
    }
  }
`)

export const SKELETON_CLASS_NAME = css`
  display: flex;
  flex-direction: column;
  padding: 20px;

  .skeleton-toggle-section {
    display: flex;
    margin-bottom: 32px;

    .skeleton-toggle-text {
      height: 32px;
      width: 280px;
      margin-right: 8px; 
    }

    .skeleton-toggle {
      height: 32px;
      width: 40px;
    }
  }

  .skeleton-tab-section {
    display: flex;
    margin-bottom: 32px;

    .skeleton-tab {
      height: 32px;
      width: 100px;
      margin-right: 8px;
    }
  }

  .skeleton-question-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    .skeleton-question-title {
      height: 24px;
      width: 380px;
      margin-bottom: 16px;
    }

    .skeleton-question-input {
      height: 56px;
      width: 300px;
      margin-bottom: 24px;
    }

    .skeleton-question-textarea {
      width: 100%;
      height: 102px;
    }
  }
`
