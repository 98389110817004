import { FC, ReactNode, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { cn } from '@carfluent/common'

import useSemiCollapsible from './hook'
import getClasses, { DEFAULT_FIXED_HEIGHT } from './styles'

interface SemiCollapsibleProps {
  children: ReactNode | ReactNode[]
  buttonBg?: string
  fixedHeight?: number
}

const SemiCollapsible: FC<SemiCollapsibleProps> = ({
  children,
  buttonBg,
  fixedHeight = DEFAULT_FIXED_HEIGHT
}) => {
  const {
    isOpen,
    isTogglerVisible,
    onToggle,
    ref
  } = useSemiCollapsible({ fixedHeight, children })

  const classes = useMemo(() => getClasses({ buttonBg, fixedHeight }), [buttonBg, fixedHeight])

  return (
    <div
      className={cn(classes, isOpen ? 'cf-expanded' : 'cf-collapsed')}
      ref={ref}
    >
      <div className={cn('cf-content', isOpen ? 'cf-expanded' : 'cf-collapsed')}>
        {children}
      </div>

      {isTogglerVisible && (
        <div className='cf-toggler-button'>
          <span className='cf-ellipsis'>{!isOpen && '...'}</span>
          <span className='cf-link' onClick={onToggle}>Show {isOpen ? 'less' : 'more'}</span>
        </div>
      )}
    </div>
  )
}

export default observer(SemiCollapsible)
