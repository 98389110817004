import type { Group, Groups, Rows } from '@carfluent/common/dist/UI'

import { type DictionaryItems, Vehicle, VehicleState, type VehicleCounters } from 'api/types'
import type { CheckboxType } from 'types'
import type { ActionOptions } from 'components/common/ActionsMenu'

import { type ConvertedFilter } from './types'
import { TAGS_ICONS, VehicleTagIds } from '../../constants'
import { ALL_SELECTED_ITEMS } from '../components/StatusFilter/constants'
import { COUNTER_MAP } from './constants'

export const groupByMake = (rows: Rows<Vehicle>): Groups<Vehicle, string> => {
  const groups = rows.reduce((acc: Map<string, Group<Vehicle, string>>, row) => {
    const groupId = row.original.make ?? ''
    const group = acc.get(groupId) ?? { rows: [], key: '' }

    acc.set(groupId, {
      rows: [...group.rows, row],
      key: `${group.key},${row.id}`,
      meta: groupId
    })

    return acc
  }, new Map())

  return [...groups.values()]
}

export const convertFilter = (filters: VehicleState[]): ConvertedFilter => {
  return { vehicleStateIds: (filters.length > 0) ? filters : ALL_SELECTED_ITEMS }
}

export const convertSearch = (search: string): { search: string} | null => {
  return (search === '') ? null : { search }
}

export const mapToCheckboxes = (tags: DictionaryItems<string>, values: number[] = []): CheckboxType[] => {
  return tags.map(item => {
    return {
      ...item,
      icon: TAGS_ICONS[item.id as VehicleTagIds],
      label: item.name,
      checked: values.includes(item.id)
    }
  })
}

enum ActionTitles {
  AddNewCar = 'Add new car',
  ImportCSV = 'Import CSV'
}

interface GetTopPanelActionsProps {
  onToggleCsvModal: (isOpen: boolean) => void
  onAddNewCar: () => void
}

export const getTopPanelActionsButton = ({
  onToggleCsvModal,
  onAddNewCar
}: GetTopPanelActionsProps): ActionOptions[] => [{
  title: ActionTitles.AddNewCar,
  handleOnClick: onAddNewCar
},
{
  title: ActionTitles.ImportCSV,
  handleOnClick: () => onToggleCsvModal(true)
}
]

export const getTotalCars = (filters: VehicleState[], counters: VehicleCounters): number => {
  if (filters.length === 0) {
    return counters.total
  }

  return filters.reduce((res, item) => {
    const amount = counters[COUNTER_MAP[item] as keyof typeof counters]
    if (amount != null) {
      res += amount
    }

    return res
  }, 0)
}

export const getValue = (value: string | number | null | undefined): string => {
  return value !== null && value !== undefined && value !== '' ? value.toString() : '-'
}
