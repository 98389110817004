import { type Editor } from '@ckeditor/ckeditor5-core'
import { type FileLoader } from '@ckeditor/ckeditor5-upload/src/filerepository'

import ImageUploadAdapter from './ImageUploadAdapter'

function ImageUploadAdapterPlugin (editor: Editor): void {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: FileLoader) => {
    return new ImageUploadAdapter(loader)
  }
}

export default ImageUploadAdapterPlugin
