import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { dates, useLoader } from '@carfluent/common'
import addMonths from 'date-fns/addMonths'
import queryString from 'query-string'

import CustomersCoreApiProvider from 'api/customersCore.api'
import { type RangeFilter } from 'types'
import { type DashboardDealsModel } from 'api/types/responses'
import { type DateRangeOption } from 'components/dashboard/Header/types'
import useAsyncEffect from 'hooks/useAsyncEffect'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

import { type UseDashboardDealsReturn } from './types'
import serializeFilters from './serializer'
import { DEALS_FILTERS, FilterTypesId } from '../constants'

const useDashboardDeals = (): UseDashboardDealsReturn => {
  const { search } = useLocation()
  const { isLoading: _isLoading } = queryString.parse(search)
  const { showAlert } = useCustomSnackbar()
  const { isLoading, startLoader, stopLoader } = useLoader()

  const [dateRangeFilter, setDateRangeFilter] =
    useState<DateRangeOption | null>(DEALS_FILTERS[0])

  const [data, setData] =
    useState<DashboardDealsModel | null>(null)

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useAsyncEffect(async () => {
    try {
      startLoader()

      const payload = serializeFilters(getFilterDateRange(dateRangeFilter?.id ?? null))
      if (payload != null) {
        const resp = await CustomersCoreApiProvider.getDashboardDeals(payload)
        setData(resp)
      }
    } catch (err) {
      showAlert(err)
    } finally {
      stopLoader()
    }
  }, [
    dateRangeFilter,
    showAlert,
    startLoader,
    stopLoader
  ])

  // ========================================== //

  return {
    data,
    dateRange: dateRangeFilter,
    isLoading: isLoading || (_isLoading === 'true'),
    onChangeDateRange: setDateRangeFilter
  }
}

export default useDashboardDeals

// ========================================== //

const getFilterDateRange = (id: number | null): RangeFilter<Date | null> => {
  const date = new Date()

  switch (id) {
    case FilterTypesId.PreviousMonth: {
      const prevMonthStart = addMonths(dates.getMonthStart(date), -1)
      const prevMonthEnd = dates.getMonthEnd(prevMonthStart)

      return {
        from: prevMonthStart,
        to: prevMonthEnd
      }
    }

    case FilterTypesId.CurrentMonth:
    default: {
      const currMonthStart = dates.getMonthStart(date)
      const currMonthEnd = dates.getMonthEnd(currMonthStart)

      return {
        from: currMonthStart,
        to: currMonthEnd
      }
    }
  }
}
