import { css } from '@emotion/css'

export const createStyleClass = (): string => {
  return css(`
    &, &.MuiTabs-flexContainer {
      display: block;
    }

    & .MuiButtonBase-root {
      padding: 0;
      line-height: 24px;
      min-height: 24px;
      margin-bottom: 24px;
      min-width: auto;
      margin-right: 62px;

      & .MuiTab-wrapper {
        font-size: 20px;
        text-transform: capitalize;
        font-weight: 400;
        color: rgba(33, 33, 33, 0.5);
      }
    }

    & .Mui-selected {
      & .MuiTab-wrapper {
        font-weight: 500;
        color: #101010;
      }
    }
  `)
}

export const createIndicatorClass = (): string => {
  return css(`
    display: none;
  `)
}
