import type { FC } from 'react'
import { cn } from '@carfluent/common'

import CLASS_NAME from './styles'

export interface DividerProps {
  className?: string
}

const Divider: FC<DividerProps> = ({
  className
}) => {
  return <div className={cn(CLASS_NAME, 'cf-divider', className)} />
}

export default Divider
