import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  formHelperText: {
    color: '#B00020 !important',
    paddingLeft: 12,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 0.4,
    width: '100%',

    position: 'absolute',
    margin: 0,
    marginTop: '60px',
    top: 0,
    bottom: 0
  }
})

const useFormHelperStyles = (): string => {
  const { formHelperText } = useStyles()
  return formHelperText
}

export default useFormHelperStyles
