import type { FC } from 'react'
import { PRESET_LABELS } from '@carfluent/common'

import ReportLayout from 'components/reports/ReportLayout'
import BackToTop from 'components/common/BackToTop'

import Filters from './components/Filters'
import GrossProfitTable from './components/Table'
import SummaryInfo from './components/SummaryInfo'

import useReportGrossProfit from './hook'
import { DEFAULT_PRESETS } from './hook/constants'

import { CLASS_NAME, REPORT_CLASS_NAME, ROOT_CLASS_NAME } from './styles'

const ReportGrossProfit: FC = () => {
  const {
    isLoading,
    rows,
    totalSale,
    totalGross,
    averageGross,
    numberOfSales,
    setFilters,
    onDateFilterChange,
    ...layoutProps
  } = useReportGrossProfit()

  return (
    <div className={CLASS_NAME}>
      <ReportLayout
        {...layoutProps}
        title='Gross profit report'
        className={REPORT_CLASS_NAME}
        rootClassName={ROOT_CLASS_NAME}
        isDateFilterEnabled={false}
        initialPresetName={PRESET_LABELS.THIS_MONTH}
      >
        <Filters
          setFilters={setFilters}
          presets={DEFAULT_PRESETS}
          onDateFilterChange={onDateFilterChange}
          defaultPresetName={PRESET_LABELS.THIS_MONTH}
        />

        <SummaryInfo
          totalSale={totalSale}
          totalGross={totalGross}
          averageGross={averageGross}
          numberOfSales={numberOfSales}
        />

        <GrossProfitTable isLoading={isLoading} rows={rows} />
      </ReportLayout>

      <BackToTop />
    </div>
  )
}

export default ReportGrossProfit
