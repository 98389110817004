import { useCallback, useRef, useState, useMemo } from 'react'
import { serializers as S, useRefUpdater, useLoader } from '@carfluent/common'

import { type TransactionLineRow } from 'types'
import { type OpenBalancesList, type OpenBalancesListItem, TransactionTypeId } from 'api/types'
import { type OpenBalanceRow } from 'components/accounting/OpenBalancesTable'
import { parseOpenBalanceRow } from 'components/accounting/OpenBalancesTable/hook/parser'

import { parseTransactionLine } from './parser/receivableParser'
import { isDefaultLine } from './utils'

export type ReconciliationId = string | null

export interface UseOpenBalancesProps {
  getRows: () => TransactionLineRow[]
  insertRow: (rowIdx: number, row?: TransactionLineRow) => void
  onCheckOpenBalance: (checkedOpenBalances: Set<string>, data: OpenBalanceRow) => void
  onUncheckOpenBalance: (checkedOpenBalances: Set<string>, data: OpenBalancesListItem[]) => void
  removeRow: (rowIdx: number, skipLastRowCheck?: boolean) => void
  transactionTypeId: TransactionTypeId | null
}

export interface UseOpenBalancesReturn {
  checkedOpenBalances: Set<string>
  isCheckByIdInProgress: boolean
  openBalancesData: OpenBalancesList | null
  onCheckOpenBalanceById: (id: ReconciliationId, isOneOfMany: boolean) => void
  onLoadOpenBalances: (data: OpenBalancesList) => void
  onToggleOpenBalances: (rowIdx: number, nextValue: boolean, row: OpenBalanceRow) => void
  removeOpenBalanceCheck: (id: ReconciliationId) => void
}

const useOpenBalances = ({
  getRows,
  insertRow,
  onCheckOpenBalance,
  onUncheckOpenBalance,
  removeRow,
  transactionTypeId
}: UseOpenBalancesProps): UseOpenBalancesReturn => {
  const [openBalancesData, setOpenBalancesData] = useState<OpenBalancesList | null>(null)
  const [checkedOpenBalances, setCheckedOpenBalances] = useState<Set<string>>(new Set())

  const {
    isLoading: isCheckByIdInProgress,
    startLoader: startSelectByIdLoader,
    stopLoader: stopSelectByIdLoader
  } = useLoader()

  /**
   * Optimization shit
   */
  const refOpenBalancesData = useRef<OpenBalancesList | null>(openBalancesData)
  const refCheckedOpenBalances = useRef<Set<string>>(new Set())
  const refOnCheckOpenBalance = useRefUpdater(onCheckOpenBalance)
  const refOnUncheckOpenBalance = useRefUpdater(onUncheckOpenBalance)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  /**
   * Generates Transaction's Line from selected OpenBalance.
   */
  const _checkOpenBalance = useCallback((row: OpenBalanceRow) => {
    const lines = getRows()
    const { reconciliationId } = row

    if (reconciliationId == null) {
      return
    }

    const line = parseTransactionLine({
      line: {
        ...row,
        amount: row.amount,
        date: S.serializeDateTimeUTC(row.date),
        disabled: true
      },
      transactionTypeId
    })

    /**
     * Remove default empty Line, if it's the only row in Lines table
     */
    if ((lines.length === 1) && isDefaultLine(lines[0])) {
      removeRow(0, true)
    }

    refCheckedOpenBalances.current.add(reconciliationId)
    insertRow(lines.length, line)

    const nextChecked = new Set(refCheckedOpenBalances.current)
    setCheckedOpenBalances(nextChecked)
    refOnCheckOpenBalance.current?.(nextChecked, row)
  }, [getRows, insertRow, removeRow])

  /**
   * Removes transaction's Line that was generated from selected (previously) OpenBalance.
   */
  const _uncheckOpenBalance = useCallback((
    row: OpenBalanceRow
  ) => {
    const lines = getRows()
    const { reconciliationId } = row

    if (reconciliationId == null) {
      return
    }

    const lineIdx = lines.findIndex(r => r.reconciliationId === reconciliationId)
    refCheckedOpenBalances.current.delete(reconciliationId)
    const nextChecked = new Set(refCheckedOpenBalances.current)
    const checkedRows = (refOpenBalancesData.current?.items ?? [])
      .filter(item => nextChecked.has(item.reconciliationId ?? ''))

    removeRow(lineIdx)
    setCheckedOpenBalances(nextChecked)
    refOnUncheckOpenBalance.current?.(nextChecked, checkedRows)
  }, [getRows, removeRow])

  const onToggleOpenBalances = useCallback((
    _: number,
    nextValue: boolean,
    row: OpenBalanceRow
  ) => {
    if (nextValue) {
      _checkOpenBalance(row)
    } else {
      _uncheckOpenBalance(row)
    }
  }, [_checkOpenBalance, _uncheckOpenBalance])

  const onLoadOpenBalances = useCallback((data: OpenBalancesList) => {
    refOpenBalancesData.current = data
    setOpenBalancesData(data)
  }, [])

  const removeOpenBalanceCheck = useCallback((reconciliationId: ReconciliationId) => {
    if (refCheckedOpenBalances.current.has(reconciliationId ?? '')) {
      refCheckedOpenBalances.current.delete(reconciliationId ?? '')

      const nextChecked = new Set(refCheckedOpenBalances.current)
      const checkedRows = (refOpenBalancesData.current?.items ?? [])
        .filter(item => nextChecked.has(item.reconciliationId ?? ''))

      setCheckedOpenBalances(nextChecked)
      refOnUncheckOpenBalance.current?.(nextChecked, checkedRows)
    }
  }, [])

  const onCheckOpenBalanceById = useCallback((reconciliationId: ReconciliationId) => {
    startSelectByIdLoader()

    /**
     * At the moment when this function is called, OpenBalances could be not loaded yet.
     * So we need to poll them.
     */
    if (refOpenBalancesData.current == null) {
      setTimeout(() => {
        onCheckOpenBalanceById(reconciliationId)
        stopSelectByIdLoader()
      }, 200)

      return
    }

    const balances = refOpenBalancesData.current.items
    const row = balances.find(b => b.reconciliationId === reconciliationId)

    if (row != null) {
      _checkOpenBalance(parseOpenBalanceRow(row))
    }

    stopSelectByIdLoader()
  }, [
    startSelectByIdLoader,
    stopSelectByIdLoader
  ])

  // ========================================== //

  return useMemo(() => ({
    checkedOpenBalances,
    isCheckByIdInProgress,
    onCheckOpenBalanceById,
    onLoadOpenBalances,
    onToggleOpenBalances,
    removeOpenBalanceCheck,
    openBalancesData
  }), [
    checkedOpenBalances,
    isCheckByIdInProgress,
    onCheckOpenBalanceById,
    onLoadOpenBalances,
    onToggleOpenBalances,
    removeOpenBalanceCheck,
    openBalancesData
  ])
}

export default useOpenBalances
