import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  display: flex;
  justify-content: space-between;
  align-items: start;
  
  .fields-row {
    display: flex;
    margin: 0px;
    margin-bottom: 24px;
    flex: 1;

    & > div {
      margin: 0px;
      margin-right: 16px;
    }

    & > div:last-child {
      margin: 0px;
    }

    .MuiFormHelperText-root {
      color: rgba(33, 33, 33, 0.50);
      position: absolute;
      bottom: -22px;
      letter-spacing: 0.1px;
    }
  }

  .fields-container-big {
    max-width: 800px;
    min-width: 720px;

    & > .MuiFormControl-fullWidth {
      width: auto!important;
      flex: 1;
    }
  }

  .field-datetime,
  .field-reference,
  .field-receivable-dropdown { flex: 1; }

  .field-description { width: 100%; }

  .option-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;
  }

  .no-options {
    color: rgba(33, 33, 33, 0.40);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .entity-option {
    &.cf-dropdown-option {
      padding: 8px 16px;
    }

    .cf-button-option {
      cursor: pointer;
      user-select: none;
      min-height: 48px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      .cf-button-title {
        font-family: Roboto;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .cf-plus-icon {
      height: 14px;
      width: 14px;
      margin: 0px;
      margin-right: 8px;
    }

    .entity-option,
    .option-name {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      box-sizing: border-box;
      margin: 0px;
      margin-right: 20px;
      overflow-x: hidden;
    }

    .entity-subtitles {
      display: flex;
      align-items: center;
    }

    .entity-subtitle,
    .option-sub-name {
      font-size: 12px;
      line-height: 16px;
      color: #909090;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 4px;
    },

    .entity-subtitle:nth-of-type(2n) {
      padding-left: 4px;
      border-left: 1px solid #909090;
    }
  }

  .description-row {
    margin: 0px;
    margin-bottom: 24px;
    max-width: 800px;
  }

  .description-container {
    max-width: 800px;
  }
`)

export const CONTROL_CLASS_NAME = css(`
  .cf-dropdown-listbox {
    min-width: 320px;
  }
  
  .cf-option-disabled {
    .cf-dropdown-option {
      opacity: 1;
    }
    
    .cf-option-name {
      color: rgba(33, 33, 33, 0.40);
    }
    
    .status {
      color: rgba(33, 33, 33, 0.40);
    }
    
    .name {
      color: rgba(33, 33, 33, 0.50);
    }
  }
`)
