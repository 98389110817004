import { css } from '@emotion/css'

export default css(`
  width: 100%;
  height: 268px;
  margin: 0;
  margin-bottom: 24px;

  &.g-panel {
    border-radius: 12px;
    // box-shadow: 0px 24px 38px 0px rgba(58, 71, 78, 0.10);
    box-shadow: 0px 4px 12px rgba(78, 91, 98, 0.1);
  }
`)

