import { useMemo } from 'react'
import { cn, CellWrapper } from '@carfluent/common'
import get from 'lodash-es/get'

import type { CellProps, CellFC } from 'types'
import ConfigurableCellHOC from 'components/common/Table/cells/ConfigurableCellHOC'
import getClassName from './styles'

export interface TextValueCellProps {
  className?: string
  isWrapped?: boolean
  fieldName?: string | null
}

function TextValueCell <TData, TValue = unknown> ({
  className,
  getValue,
  isWrapped = false,
  fieldName
}: CellProps<TData, TValue> & TextValueCellProps): JSX.Element {
  const classes = useMemo(() => getClassName(isWrapped), [isWrapped])
  let value = getValue?.()

  if ((value != null) && (typeof value === 'object') && (typeof fieldName === 'string')) {
    value = get(value, fieldName)
  }

  return (
    <CellWrapper className={cn(classes, className)}>
      <p>{value != null ? value : '-'}</p>
    </CellWrapper>
  )
}

export function TextValueHOC <TData>(hocProps: TextValueCellProps) {
  const Comp: CellFC<TData & TextValueCellProps> = TextValueCell
  return ConfigurableCellHOC(Comp, hocProps)
}

export default TextValueCell
