import { type FC, memo } from 'react'
import { cn } from '@carfluent/common'

import type { ReconStepsTableRowProps } from 'pages/reconditioning/Settings/Steps/components/ReconStepsTable/types'
import ReconStepsTableNumberCell from '../NumberCell'
import ReconStepsTableTextCell from '../TextCell'
import UserCell from '../UserCell'

import CLASS_NAME from './styles'

const noop = (): void => {}

const ReconStepsTableRow: FC<ReconStepsTableRowProps> = (props) => {
  const {
    isEditMode,
    item,
    touched,
    errors,
    onChange = noop,
    onBlur = noop,
    dealerAccounts,
    index
  } = props

  return (
    <div
      className={cn(
        CLASS_NAME,
        'cf-edit-mode-view',
        item.isReadonly === true && 'disabled'
      )}
    >
      <ReconStepsTableTextCell
        isEditMode={isEditMode}
        id={`reconSteps.[${index}].name`}
        value={item.name}
        disabled={item.isReadonly}
        touched={touched?.name}
        error={errors?.name}
        onChange={onChange}
        onBlur={onBlur}
      />

      <ReconStepsTableNumberCell
        isEditMode={isEditMode}
        id={`reconSteps.[${index}].daysInStepUntilFirstAlert`}
        value={item.daysInStepUntilFirstAlert}
        disabled={item.isReadonly}
        touched={touched?.daysInStepUntilFirstAlert}
        error={errors?.daysInStepUntilFirstAlert}
        onChange={onChange}
        onBlur={onBlur}
      />

      <ReconStepsTableNumberCell
        isEditMode={isEditMode}
        id={`reconSteps.[${index}].daysInStepUntilSecondAlert`}
        value={item.daysInStepUntilSecondAlert}
        disabled={item.isReadonly}
        touched={touched?.daysInStepUntilSecondAlert}
        error={errors?.daysInStepUntilSecondAlert}
        onChange={onChange}
        onBlur={onBlur}
      />

      <ReconStepsTableNumberCell
        isEditMode={isEditMode}
        id={`reconSteps.[${index}].daysInReconUntilFirstAlert`}
        value={item.daysInReconUntilFirstAlert}
        disabled={item.isReadonly}
        touched={touched?.daysInReconUntilFirstAlert}
        error={errors?.daysInReconUntilFirstAlert}
        onChange={onChange}
        onBlur={onBlur}
      />

      <ReconStepsTableNumberCell
        isEditMode={isEditMode}
        id={`reconSteps.[${index}].daysInReconUntilSecondAlert`}
        value={item.daysInReconUntilSecondAlert}
        disabled={item.isReadonly}
        touched={touched?.daysInReconUntilSecondAlert}
        error={errors?.daysInReconUntilSecondAlert}
        onChange={onChange}
        onBlur={onBlur}
      />

      <UserCell
        isEditMode={isEditMode}
        id={`reconSteps.[${index}].subscribers`}
        value={item.subscribers}
        onChange={onChange}
        dealerAccounts={dealerAccounts}
      />
    </div>
  )
}

// PERF_CHECKED: reduces rerenders.
export default memo(ReconStepsTableRow)
