import { useCallback, useEffect, useState, useContext } from 'react'
import { type DeepRequired, useForm, useLoader, isOk, serializers, isFail } from '@carfluent/common'
import { useNavigate, useParams } from 'react-router-dom'
import addDays from 'date-fns/addDays'

import AccountingApiProvider from 'api/accounting.api'
import { type AccountingFiscalYearsLockedDto } from 'api/types'
import { KeyVal } from 'types'
import { ReconciliationDraft } from 'constants/route_helper'
import useAsyncEffect from 'hooks/useAsyncEffect'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import parseError from 'utils/parseErrors'
import SettingsCTX from 'store/settings'

import { parseReconciliationSetup } from './parser'
import validationRules from './validation'
import {
  type UseReconciliationSetupReturn,
  type ReconciliationFormData,
  type SessionWithDate
} from './types'

const DEFAULT_FORM_DATA: Omit<ReconciliationFormData, 'beginningBalance'> = {
  endingBalance: null,
  endDate: null
}

const { serializeDate } = serializers

const useReconciliationSetup = (): UseReconciliationSetupReturn => {
  const { isLoading, startLoader, stopLoader } = useLoader()
  const { showAlert } = useCustomSnackbar()
  const navigate = useNavigate()
  const { accountId } = useParams()

  const { accounting } = useContext(SettingsCTX)

  const [accountName, setAccountName] = useState<string| null>(null)
  const [lastSessionId, setInLastSessionId] = useState<number| null>(null)
  const [inProgressSessionId, setInProgressSessionId] = useState<number| null>(null)
  const [lastFinishedSession, setLastFinishedSession] = useState<SessionWithDate| null>(null)
  const [openingBalance, setOpeningBalance] = useState<number>(0)
  const [lockedInfo, setLockedInfo] = useState<AccountingFiscalYearsLockedDto[] | null>(null)
  const [apiErrors, setApiErrors] = useState<KeyVal | null>(null)

  const lockedDates = lockedInfo?.map(item => {
    const date = new Date(item.startDateInclusive)
    return { year: date.getFullYear(), month: date.getMonth() }
  })

  const minDate = lastFinishedSession?.endDate != null ? addDays(new Date(lastFinishedSession.endDate), 1) : accounting.accountingStartDate

  const shouldDisableMonth = useCallback((monthId: number, year?: number): boolean => {
    return lockedDates?.some(date => date.year === year && date.month === monthId) ?? false
  }, [lockedDates])
  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const submitAction = useCallback(async (values: Omit<ReconciliationFormData, 'beginningBalance'>) => {
    const payload = {
      accountBankStatementId: Number(accountId),
      endDate: serializeDate(values.endDate),
      endBalance: values.endingBalance
    }

    await AccountingApiProvider.saveReconciliationSession(payload)
    navigate(ReconciliationDraft(Number(accountId)), { state: { fromSetup: true } })
  }, [accountId])

  const onActionResult = useCallback((res, resetForm) => {
    if (res.result != null && isOk(res)) {
      resetForm(DEFAULT_FORM_DATA)
    }

    if (isFail(res)) {
      setApiErrors(parseError(res.result))
    }
  }, [])

  const {
    errors,
    touched,
    values,
    onChange,
    onBlur,
    resetForm,
    onSubmit,
    setFieldError
  } = useForm<DeepRequired<Omit<ReconciliationFormData, 'beginningBalance'>>>({
    baseValues: DEFAULT_FORM_DATA,
    validationRules,
    submitAction,
    onActionResult
  })

  const onCancel = useCallback(() => {
    resetForm(DEFAULT_FORM_DATA)
    navigate(-1)
  }, [navigate, resetForm])

  const onSubmitSaveReconciliationSession = useCallback(() => {
    if (inProgressSessionId != null) {
      navigate(ReconciliationDraft(Number(accountId)), { state: { fromSetup: true } })
    } else {
      void onSubmit?.()
    }
  }, [onSubmit, navigate, inProgressSessionId, accountId])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //
  useAsyncEffect(async () => {
    const { items } = await AccountingApiProvider.getLockedYearOrMonth()
    setLockedInfo(items)
  }, [])

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      try {
        startLoader()
        const result = await AccountingApiProvider.getReconciliationSession(Number(accountId))
        if (result != null) {
          const { lastFinishedReconciliationSession } = parseReconciliationSetup(result)
          setAccountName(`${result.number} - ${result.name ?? ''}`)
          setInLastSessionId(result.lastFinishedReconciliationSessionId)
          setInProgressSessionId(result.inProgressReconciliationSessionId)
          setLastFinishedSession(lastFinishedReconciliationSession)
          setOpeningBalance(result.openingBalance)
        }
      } catch (err) {
        showAlert('Something went wrong.', { variant: 'error' })
      } finally {
        stopLoader()
      }
    }

    void runEffect()
  }, [accountId, showAlert, stopLoader, startLoader])

  useEffect(() => {
    if (apiErrors != null) {
      // probably should be fixed on BE
      setFieldError('endDate', apiErrors['endDate.EndDate'])
      setApiErrors(null)
    }
  }, [apiErrors, setFieldError])

  // ========================================== //
  //

  return {
    errors,
    touched,
    values,
    onChange,
    onBlur,
    minDate,
    isLoading,
    accountName,
    lastSessionId,
    inProgressSessionId,
    lastFinishedSession,
    openingBalance,
    shouldDisableMonth,
    onCancel,
    onSubmitSaveReconciliationSession
  }
}

export default useReconciliationSetup
