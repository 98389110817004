import type { FC } from 'react'
import { Loader, formatCurrencyAccounting } from '@carfluent/common'

import TooltipedText from 'components/common/TooltipedText'
import type { VehiclePrices } from 'api/types/vehicles.types'
import { DATE_US_SHORT_YEAR_FORMAT } from 'constants/constants'
import { joinPartsBySpace, formatUTCDateTimeForView } from 'utils/view_helper'

import { getFormattedDate } from './utils'

import CLASS_NAME from './styles'

interface PriceHistoryProps {
  isLoading: boolean
  lastDateTime: string | null
  priceHistoryList: VehiclePrices[]
}

enum AutomaticUser {
  Id,
  Name = 'Automatically'
}

const getCreatedByText = (user: VehiclePrices['createdByUser']): string => {
  return user.id === AutomaticUser.Id
    ? AutomaticUser.Name
    : joinPartsBySpace(user.dealerFirstName ?? '-', user.dealerLastName)
}

const PriceHistory: FC<PriceHistoryProps> = ({ priceHistoryList, isLoading, lastDateTime }) => {
  return (
    <div className={CLASS_NAME}>
      <div className='history-table-header'>
        <div className='history-table-header-item'>Price</div>
        <div className='history-table-header-item'>When</div>
        <div className='history-table-header-item'>Changed by</div>
        <div className='history-table-header-item'>Inquiries</div>
      </div>
      {
        isLoading && <Loader size={32} />
      }
      {
        !isLoading &&
          <div className='history-table-body'>
            {
            priceHistoryList.length > 0
              ? priceHistoryList.map((item, i) => (
                <div
                  className='history-table-row'
                  key={`${item.salePrice}-${item.createdDate}-${item.createdByUser.dealerLastName}`}
                >
                  <div className='history-table-row-item'>
                    {formatCurrencyAccounting(item.salePrice, { emptyValues: '$-' })}
                  </div>
                  <div className='history-table-row-item'>
                    {`${formatUTCDateTimeForView(item.createdDate, DATE_US_SHORT_YEAR_FORMAT) ?? ''} - `}
                    {getFormattedDate(i, lastDateTime, priceHistoryList, DATE_US_SHORT_YEAR_FORMAT)}
                  </div>
                  <div className='history-table-row-item'>
                    <TooltipedText
                      value={getCreatedByText(item.createdByUser)}
                    />
                  </div>
                  <div className='history-table-row-item'>
                    {item?.leadAmount}
                  </div>
                </div>
              ))
              : <div className='history-table-row no-price'>No data yet</div>
          }
          </div>
      }
    </div>
  )
}

export default PriceHistory
