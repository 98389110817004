import type { KeyVal as ApiData } from 'types' // TODO: replace by import of real API type
import type { VehiclePurchasePaymentsFormData } from './types'
import { EntityId } from 'api/types'
import { keys } from 'utils/general'

const extractIds = (input: VehiclePurchasePaymentsFormData): { ids: EntityId[] } => {
  const ids: EntityId[] = []

  for (const key of keys(input)) {
    const account = input[key]
    if (account !== null) {
      ids.push(account.id)
    }
  }

  return { ids }
}

const serializeFormData = (data: VehiclePurchasePaymentsFormData): ApiData => {
  return {
    ...extractIds(data)
  }
}

export default serializeFormData
