import { type FC } from 'react'
import { cnx, FormNumberInput } from '@carfluent/common'
import { observer } from 'mobx-react-lite'
import { type FormikErrors, type FormikTouched } from 'formik'
import { Checkbox, FormControlLabel } from '@material-ui/core'

import { type VehicleRepricingDetails } from 'api/types'
import { isTruthy } from 'utils/general'
import { MAX_PRICE_VALUE } from 'constants/validation'
import { useForm } from 'components/form'
import Tooltip from 'components/common/Tooltip'
import InputsRowLayout from 'components/inventory/InputsRowLayout'
import {
  REPRICE_BLOCK_CLASS_NAME,
  REPRICING_LAYOUT_CLASS_NAME,
  TOOLTIP_CLASS_NAME
} from './styles'

interface AutoRepricingSectionProps {
  isDisabled: boolean
  isRepricingDisabled: boolean
  repriceTooltip: string | null
}

const AutoRepricingSection: FC<AutoRepricingSectionProps> = ({
  isDisabled,
  isRepricingDisabled,
  repriceTooltip
}) => {
  const {
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched
  } = useForm()

  const isRepricingCheckboxEnabled = isTruthy(values.vehicleRepricingDetails?.isEnabled)
  const isRepriceActive = isRepricingCheckboxEnabled && !isRepricingDisabled

  // force assign types to touched/errors objects because of wrong mapping of the types for nested values of Formik
  const vehicleRepricingTouched = touched.vehicleRepricingDetails as unknown as FormikTouched<VehicleRepricingDetails | null>
  const vehicleRepricingErrors = errors.vehicleRepricingDetails as unknown as FormikErrors<VehicleRepricingDetails | null>

  // ========================================== //

  return (
    <>
      <div className={REPRICE_BLOCK_CLASS_NAME}>
        <Tooltip
          placement='top-start'
          className={cnx(TOOLTIP_CLASS_NAME, isRepricingDisabled && 'is-active')}
          title={repriceTooltip ?? ''}
        >
          <FormControlLabel
            label='Auto repricing on website'
            classes={{ root: 'form-control-checkbox' }}
            control={
              <Checkbox
                classes={{ root: 'cf-vehicle-update-checkbox-icon' }}
                color='default'
                checked={isRepriceActive}
                disabled={isRepricingDisabled}
                onChange={() => {
                  void setFieldValue('vehicleRepricingDetails.isEnabled', !isRepricingCheckboxEnabled)
                }}
              />
            }
          />
        </Tooltip>
      </div>

      {isRepriceActive && (
        <InputsRowLayout className={REPRICING_LAYOUT_CLASS_NAME}>
          <FormNumberInput
            id='vehicleRepricingDetails.minPrice'
            label='Min price'
            onChange={setFieldValue}
            className='cf-inventory-min-price'
            value={values.vehicleRepricingDetails?.minPrice ?? null}
            touched={vehicleRepricingTouched?.minPrice}
            error={vehicleRepricingErrors?.minPrice}
            preset='financial'
            isNegativeAllowed={false}
            onBlur={handleBlur}
            disabled={isDisabled}
            max={MAX_PRICE_VALUE}
          />

          <FormNumberInput
            id='vehicleRepricingDetails.dayNumber'
            label='Every (# of days)'
            onChange={setFieldValue}
            className='cf-inventory-every-no-days'
            value={values.vehicleRepricingDetails?.dayNumber ?? null}
            touched={vehicleRepricingTouched?.dayNumber}
            error={vehicleRepricingErrors?.dayNumber}
            isNegativeAllowed={false}
            onBlur={handleBlur}
            disabled={isDisabled}
          />

          <FormNumberInput
            id='vehicleRepricingDetails.priceDeduction'
            label='Price deduction'
            onChange={setFieldValue}
            className='cf-inventory-price-deduction'
            value={values.vehicleRepricingDetails?.priceDeduction ?? null}
            touched={vehicleRepricingTouched?.priceDeduction}
            error={vehicleRepricingErrors?.priceDeduction}
            preset='financial'
            isNegativeAllowed={false}
            onBlur={handleBlur}
            max={MAX_PRICE_VALUE}
            disabled={isDisabled}
          />
        </InputsRowLayout>
      )}
    </>
  )
}

export default observer(AutoRepricingSection)
