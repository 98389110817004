import { type DocumentDetailsDto, FileType } from 'api/types'

export interface UploadedDealDocument extends DocumentDetailsDto {
  loading?: boolean
}

let uniqId = 0

interface GetEmptyItemProps {
  fileName: string
  loading?: boolean
  fileId?: number
  fileTypeId?: FileType
}

export const getFileItem = ({
  fileId = -1,
  fileName,
  loading = true,
  fileTypeId = FileType.Other
}: GetEmptyItemProps): UploadedDealDocument => ({
  id: --uniqId,
  fileId,
  fileTypeId,
  documentVerificationDetails: null,
  createdDate: null,
  notarizeTransactionId: null,
  uploadedByUser: null,
  loading,
  fileName
})
