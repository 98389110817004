import type { SendEmail } from 'api/types'
import { serializeToHtml } from 'utils/wysiwyg'
import type { EditorState } from 'react-draft-wysiwyg'
import { SendEmailFormData } from './types'

const serializeFormData = (data: SendEmailFormData, body: EditorState | null, replyToEmailId: string | number | undefined): SendEmail => {
  return {
    body: serializeToHtml(body),
    cc: data.cc,
    subject: data.subject,
    emailAttachments: data.emailAttachments?.map((attachment) => ({
      fileName: attachment.fileName,
      url: attachment.url
    })) ?? null,
    replyToEmailId
  }
}

export default serializeFormData
