import { css } from '@emotion/css'

export default css(`
  min-width: 532px;
  width: 100%;
  position: relative;

  .no-car {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-top: -8px;
  }

  .section-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      img {
        user-select: none;
      }

      .title-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.15px;
        color: #101010;

        .icon-crown {
          margin-left: 8px;
        }
      }

      .title-switcher {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        height: 24px;

        .more-actions {
          padding-left: 0 10px;
          margin-left: 16px;
        }

        .cf-icon-button {
          padding: 6px 8px;

          img {
            width: 10px;
            height: 12px;
          }
        }
      }
  }

  .car-carousel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
    width: 100%;
    min-width: 100%;

    .car-item {
      flex: 1;
      width: 100%;
      min-width: 100%;
    }
  }
`)
