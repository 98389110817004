import type { DeepRequired, FormValidation } from '@carfluent/common'
import {
  requiredField,
  alphanumericField,
  phoneField,
  emailField,
  ssnField,
  nameField,
  zipCodeField
} from 'utils/validationPresets'
import type { VendorFormData } from './types'

const createFormValidation = (): FormValidation<DeepRequired<VendorFormData>> => {
  return {
    name: nameField(true),
    phoneNumber: phoneField(),
    email: emailField(),
    ein: (val: string | null, ctx) => alphanumericField(Boolean(ctx?.isTrack1099Payments))(val, ctx),
    ssn: (val: string | null, ctx) => ssnField(Boolean(ctx?.isTrack1099Payments))(val, ctx),
    payableAccount: requiredField,
    'address.zipCode': zipCodeField(),
    'mailingAddress.zipCode': zipCodeField()
  }
}

export default createFormValidation()
