import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGMapLocation: FC<IconProps> = ({ color = '#BDBDBD', width = 24, height = 24 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'>
      <path
        d='M12.0003 3.66797C8.77533 3.66797 6.16699 6.2763 6.16699 9.5013C6.16699 13.8763 12.0003 20.3346 12.0003 20.3346C12.0003 20.3346 17.8337 13.8763 17.8337 9.5013C17.8337 6.2763 15.2253 3.66797 12.0003 3.66797ZM12.0003 11.5846C10.8503 11.5846 9.91699 10.6513 9.91699 9.5013C9.91699 8.3513 10.8503 7.41797 12.0003 7.41797C13.1503 7.41797 14.0837 8.3513 14.0837 9.5013C14.0837 10.6513 13.1503 11.5846 12.0003 11.5846Z'
        fill={color}
      />
    </svg>
  )
}

export default IconSVGMapLocation
