import {
  type DealFeesSettingsModel,
  type DealFeesModel,
  DealFeesSettingsToValuesMap
} from 'api/types/responses'
import { keys } from 'utils/general'
import { type DealFormValues } from 'types'

export const omitDisabledFees = (
  fees: Partial<DealFeesModel>,
  config: DealFeesSettingsModel | null
): Partial<DealFeesModel> => {
  const result: Partial<DealFeesModel> = {}

  if (config == null) {
    return result
  }

  for (const key of keys(config)) {
    const feeKey = DealFeesSettingsToValuesMap[key] as keyof Omit<DealFormValues['dealFees'], 'others'>
    const feeConfig = config[key]
    const feeValue = fees[feeKey]

    if ((feeValue != null) && (feeConfig != null) && feeConfig.isEnabled) {
      result[feeKey] = feeValue
    }
  }

  return result
}

export default omitDisabledFees
