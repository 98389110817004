import { makeStyles } from '@material-ui/core/styles'
import { ScreenWidthBreakpoints } from 'constants/constants'

const useStyles = makeStyles((theme) => ({
  item: {
    minWidth: '100%',
    height: '100%',
    minHeight: '160px',
    maxHeight: '160px',
    overflow: 'hidden',
    border: '1px solid grey',
    borderRadius: '12px',
    position: 'relative',
    outline: 'none',

    '&.cf-img-failed': {
      border: '1px solid #B00020',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '30px',

      '& .cf-img-failed-msg': {
        color: '#B00020',
        fontSize: '14px'
      }
    },

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },

    '&:hover .hoverable': {
      opacity: '0.8'
    },

    [theme.breakpoints.down(ScreenWidthBreakpoints.Medium)]: {
      minHeight: '150px',
      maxHeight: '150px'
    },

    [theme.breakpoints.down(1480)]: {
      minHeight: '124px',
      maxHeight: '124px'
    }
  },

  posterContainer: {
    position: 'relative',
    height: '100%'
  },

  iconPlayer: {
    position: 'absolute',
    top: '50%',
    display: 'block',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    background: 'rgb(0 0 0 / 60%)',
    borderRadius: '50%',
    width: '50px',
    height: '50px'
  },

  blockControls: {
    position: 'absolute',
    width: '100%',
    height: '40px',
    background: '#101010',
    opacity: '0.2',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 16px',

    '&:hover': {
      opacity: '0.8'
    }
  },
  fileName: {
    width: '120px'
  },
  buttonsList: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    flex: 1,

    '&.is-loading': {

      '& .loading-container': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flex: '1',
        width: '100%',
        gap: '10px',

        '& > div': {
          width: '24px'
        }
      }
    }
  },
  buttonItem: {
    width: '24px',
    height: '24px',
    padding: '2px',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    '&:last-child': {
      marginRight: '0'
    }
  },
  mainItem: {
    opacity: '0.8'
  },
  container: {
    minWidth: '24px',
    minHeight: '24px'
  },
  tooltip: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    padding: '4px 12px',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    background: '#212121',
    color: '#FFFFFF'
  },
  textFileName: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#FFF',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },

  load: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
}))

export default useStyles
