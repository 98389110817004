import { type FC } from 'react'
import {
  Bar,
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts'

import type { PricesByDate } from 'pages/inventory/VehicleDetails/hooks/useLeadsTab/types'

import { GRAPH_SIZES } from './constants'
import CustomTooltip from './components/CustomTooltip'
import LegendContent from './components/LegendContent'
import { formatDate, leadsTickFormatter, priceTickFormatter } from './utils'

interface LeadsGraphViewProps {
  data: PricesByDate[]
}

const LeadsGraphView: FC<LeadsGraphViewProps> = ({ data }) => {
  return (
    <ResponsiveContainer width='100%'>
      <ComposedChart
        barGap={0}
        data={data}
        barCategoryGap={0}
      >
        <CartesianGrid
          vertical={false}
          stroke='#0000001F'
        />
        <XAxis
          fontSize={14}
          dataKey='date'
          axisLine={false}
          tickLine={false}
          stroke='#21212180'
          tickFormatter={formatDate}
          style={{
            fontSize: '14px',
            textAlign: 'center',
            letterSpacing: '0.25px'
          }}
          padding={{
            left: GRAPH_SIZES.xAxisPadding,
            right: GRAPH_SIZES.xAxisPadding
          }}
        />
        <YAxis
          fontSize={14}
          yAxisId='right'
          axisLine={false}
          tickLine={false}
          stroke='#21212180'
          orientation='right'
          width={GRAPH_SIZES.priceAxisWidth}
          tickFormatter={priceTickFormatter}
        />
        <YAxis
          fontSize={14}
          yAxisId='left'
          axisLine={false}
          tickLine={false}
          stroke='#21212180'
          orientation='left'
          width={GRAPH_SIZES.leadsAxisWidth}
          tickFormatter={leadsTickFormatter}
        />
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ stroke: '#21212180', strokeWidth: 1, strokeDasharray: '3 3' }}
        />
        <Legend content={LegendContent} />
        <Bar
          name='Price'
          yAxisId='right'
          fill='#7CDF984D'
          maxBarSize={GRAPH_SIZES.barWidth}
          dataKey={item => item.prices[item.prices.length - 1]}
        />
        <Line
          name='Leads'
          type='linear'
          yAxisId='left'
          strokeWidth={3}
          stroke='#0047FF'
          activeDot={false}
          dataKey='webLeads'
          dot={data.length > 1 ? false : { fill: '#0047FF' }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default LeadsGraphView
