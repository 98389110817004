import { css } from '@emotion/css'

export default css(`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.25px;
  color: rgba(33, 33, 33, 0.8);


  > span {
    cursor: pointer;
  }

  .edit-action {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    align-items: center;

    svg {
      top: 50%;
      left: 50%;
      z-index: 1;
      position: absolute;
      transform: translate(-50%, -50%);

      path {
        fill-opacity: 1;
      }
    }

    &:after {
      content: '';
      opacity: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      transition: all .6s;
      background-color: #21212108;
    }

    &:hover {
      &:after {
        opacity: 1;
        transition: all .6s;
      }
    }

    .btn-edit-coverages {
      cursor: pointer;
    }
  }
`)

export const POPOVER_CLASS_NAME = css(`
  &.cf-popover-content {
    padding: 24px;
  }

  .radio-item {
    padding-right: 0;
  }

  .MuiButtonBase-root {
    padding: 8px;
  }

  .price-config-mode {
    margin-bottom: 16px;
  }

  .inputs-container {
    display: flex;
    align-items: center;

    .cf-form-field {
      width: 332px;
    }

    > span {
      margin: 0 8px;
    }
  }

  .markup-inputs {
    .cf-form-field {
      width: 154px;
    }
  }

  .apply-button-wrapper {
    display: flex;
    margin-top: 24px;
    justify-content: flex-end;

    & .btn-apply {
      max-width: 84px;
      min-width: 84px;
      height: 26px;
      padding: 0px 18px;
      border-radius: 8px;
      
      .cf-button-content {
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
      }
      
      &:disabled {
        background: transparent;
        text-decoration: none;
      }
    }
  }
`)
