import { ApprovalStatus, PaymentTypeAPI, VehicleStateNames } from 'constants/names' // AZ-TODO: remove\invert
import type { EntityId, TemplateIds } from 'types'
import { PaymentTransactionTypeEnum } from 'types/payments'

import { type CreateDealPersonalDetailsDto } from 'api/types/requests'
import { type CheckoutDetailsDto } from 'api/types/responses'

import type { DictionaryItem, ListPayload, SortOrder, CancelationOptions } from './utility.types'
import type { DocumentDetails, ErrorResponse, DocumentDetailsDto } from './common.types'
import type { DealerAccount } from './dealers.types'
import type { FileIdDto } from './documents.types'

export enum DealFiltersState {
  Incomplete = 2,
  Sold = 3,
  OnDeposit = 4,
  Canceled = 5
}

export enum DealAccountingState {
  NoPushToAccounting = 0,
  PushToAccounting = 1,
  Recorded = 2
}

export interface DateType {
  from: Date | null
  to: Date | null
}

export enum ReceiptStatus {
  Issued = 1,
  Voided,
  Refunded
}

export enum PaymentMethod {
  Full = 1,
  Deposit,
  DownPayment
}

export enum PaymentTypeId {
  Finance = 1,
  Cash = 2
}

export enum DealNextAction {
  SubmitCreditApplication = 1,
  ReviewCreditApplication = 2,
  SelectForms = 3,
  SignPaperwork= 4
}

export enum WorkflowStateId {
  Pending = 1,
  Completed = 2,
  Canceled = 3,
  Deleted = 4
}

export interface GetDealsListPayload extends ListPayload {
  date?: DateType | null
  workflowSearchState?: DealFiltersState | null
  workflowAccountingStates?: number[] | null
  nextAction?: number[]
}

export type DealsCounters = Record<DealFiltersState, number>

export type DealRequestConfig<T = never> = T[] extends never[]
  ? {
      rowVersion: string
      dealId: string | number
    }
  : {
      rowVersion: string
      dealId: string | number
      data: T
    }

export interface DealNoteUpdate {
  dealId: string | number
  notes: string | null
}

export enum WorkflowStep {
  Initial = 'Initial',
  PersonalDetails = 'Personal Details',
  TradeIn = 'Trade In',
  Financing = 'Financing',
  ServiceProtection = 'Service & Protection',
  CreditApplication = 'Credit application',
  DealerCheck = 'Dealer check',
  DealerCheckCompleted = 'Dealer check completed',
  Documents = 'Documents',
  SignOnline = 'Sign Online',
  PickupAndDelivery = 'Pickup and Delivery',
  Payments = 'Payments',
  ReviewOrder = 'Review Order',
  PaymentSummary = 'Payment Summary'
}

export enum WorkflowStepId {
  Initial = 1,
  TradeIn = 2,
  Payments = 3,
  PersonalDetails = 4,
  ServiceProtection = 5,
  Financing = 6,
  Documents = 7,
  SignOnline = 8,
  PickupAndDelivery = 9,
  ReviewOrder = 10,
  DealerCheck = 11,
  CreditApplication = 12,
  DealerCheckCompleted = 13,
  PaymentSummary = 14
}

export interface DealsListItem {
  isDeposited: boolean
  createdDate: string
  currentWorkflowStepName: WorkflowStep
  currentWorkflowStepStartedAt: string
  accountingStateChangeDate: string | null
  customerAddress: string | null
  customerState: string | null
  customerCity: string | null
  customerEmail: string | null
  customerFirstName: string | null
  customerLastName: string | null
  customerPhoneNumber: string | null
  dealState: string
  monthlyPayment: number | null
  dealFinanceTypeId: PaymentTypeId | null
  requiresCheck: boolean
  vehicleImageUrl: string | null
  vehicleModel: string | null
  vehicleModelYear: number
  vehiclePrice: number
  vehicleState: VehicleStateNames | null
  workflowId: number
  workflowAccountingState: number | null
  currentWorkflowStepId: number
  isVehicleDeposited: boolean
  nextAction: number
  name: string | null
  saleDate: string | null
  dealPaidStatusId: number
}

export interface ReceiptPaymentDetails {
  id: number
  transactionTypeId: PaymentTransactionTypeEnum
  paymentMethodId: PaymentMethod
  amount: number
  amountPayed: number
  paymentStatusId: number
  isManual: true
}

// AZ-TODO: rename to ReceiptItemDto
export interface ReceiptItem {
  id: number
  number: number
  amount: number
  paymentMethodId: PaymentMethod
  receiptStatusId: ReceiptStatus
  description: string | null
  carDesc: string
  customerName: string
  customerAddress: string
  transactionId: number
  fileId: number
  workflowId: number
  createdByUser: {
    id: number
    userName: string
    email: string
    dealerFirstName: string
    dealerLastName: string
  }
  receiptDate: string | null
  createdDate: string
  modifiedByUser: {
    id: number
    userName: string
    email: string
    dealerFirstName: string
    dealerLastName: string
  }
  modifiedDate: string
  paymentDetails: ReceiptPaymentDetails[]
}

export interface Summary {
  amountFinanced: number | null
  annualPercentageRate: number | null
  bodyStyle: number | string | null
  calcAmountFinanced: number | null
  calcDownPayment: number | null
  calcMonthlyPayment: number | null
  calcTerm: number | null
  cashPriceIncludingSalesTax: number | null
  ceramicId: number | string | null
  ceramicName: string | null
  ceramicPrice: number | null
  coPurchaserAddress: null
  coPurchaserApt: number | string | null
  coPurchaserCity: number | string | null
  coPurchaserCountry: string | null
  coPurchaserDateOfBirth: string | null
  coPurchaserEmail: string | null
  coPurchaserFirstName: string | null
  coPurchaserLastName: string | null
  coPurchaserLicenseNumber: number | null
  coPurchaserName: string | null
  coPurchaserPhone: string | null
  coPurchaserState: string | null
  coPurchaserZipCode: string | null
  color: string | null
  dealerAddress: string | null
  dealerCity: string | null
  dealerEmail: string | null
  dealerId: number | null
  dealerName: string | null
  dealerPhone: string | null
  dealerRepresentativeEmail: string | null
  dealerRepresentativeFirstName: string | null
  dealerRepresentativeId: string | number | null
  dealerRepresentativeLastName: string | null
  dealerRepresentativeName: string | null
  dealerState: string | null
  dealerZipCode: string | null
  documentFee: number | null
  downPayment: number | null
  financeCharge: number | null
  firstPaymentDate: string | null
  fundedAmount: number | null
  gapId: string | number | null
  gapName: string | number | null
  gapPrice: number | null
  gapProviderName: string | null
  inServiceDate: string | null
  interestRate: number | null
  isCreditApplicationSubmitted: false
  lender: string | null
  make: string | null
  maxCardPaymentAmount: number | null
  minFinancedAmount: number | null
  model: string | null
  modelYear: number | null
  monthlyPayment: number | null
  monthlyPaymentDueDate: string | null
  odometer: number | null
  other1: number | null
  other1Description: string | null
  other2: number | null
  other2Description: string | null
  other3: number | null
  other3Description: string | null
  pickupDeliveryDate: string | null
  purchasePrice: number | null
  purchaserAddress: string | null
  purchaserApt: string | number | null
  purchaserCity: string | null
  purchaserCountry: string | null
  purchaserDateOfBirth: string | null
  purchaserEmail: string | null
  purchaserFirstName: string | null
  purchaserLastName: string | null
  purchaserLicenseNumber: string | null
  purchaserName: string | null
  purchaserPhone: string | null
  purchaserState: string | null
  purchaserZipCode: string | null
  registrationTax: number | null
  saleDate: string | null
  salesTax: number | null
  salesTaxAmount: number | null
  stockNumber: string | null
  theftId: number | null
  theftName: string | null
  theftPrice: number | null
  tireAndWheelId: number | null
  tireAndWheelName: string | null
  tireAndWheelPrice: number | null
  term: number | null
  totalAmount: number | null
  totalAmountDueCashOrFinanced: number | null
  totalDown: number | null
  totalOtherCharges: number | null
  totalPayments: number | null
  totalPrice: number | null
  totalPriceLessDeposit: number | null
  totalSalePrice: number | null
  tradeInCredit: number | null
  tradeInLessPayoff: number | null
  tradeInMake: string | null
  tradeInModel: string | null
  tradeInOdometer: number | null
  tradeInPayoff: number | null
  tradeInTrim: string | null
  tradeInVin: string | null
  tradeInYear: number | null
  transportationCost: number | null
  trim: string | null
  unpaidBalanceOfCashPrice: number | null
  vin: string | null
  warrantyDeductibleAmount: null
  warrantyId: number | string |null
  warrantyName: string | null
  warrantyPrice: number | null
  warrantyProviderName: string | null
  warrantyTermMiles: number | string | null
  warrantyTermMonths: number | string | null
  workflowId: number | null
}

/**
 * @deprecated - use DealDto instead
 */
export interface Deal {
  creditScore: number | null
  creditScoreLink: string | null
  customerId: number
  dealState: string
  documentFee: number
  dealFees: DealFeesDto
  nextAction: DealActions | null
  currentWorkflowStepName: WorkflowStep
  currentWorkflowStepStartedAt: string
  accountingStateChangeDate: string | null
  createdDate: string | null
  saleDate: string | null
  firstPaymentDate: string | null
  rowVersion: string
  // TODO-yavo: need to delete
  isPaperworkPendingSigning: boolean
  totalPrice: number
  registrationTax: number
  recapTransactionId: number | null
  transportationCost: number | null
  other1: number | null
  other2: number | null
  other3: number | null
  totalDown: number | null
  other1Description: string | null
  other2Description: string | null
  other3Description: string | null
  isCreditApplicationSubmitted: boolean
  creditApplicationSubmittedDate: string | null
  areTemplateDocumentsAvailable: boolean
  vehicleDetails: DealVehicleDetails
  customerDetails: CustomerDetails
  tradeInDetails: TradeInDetails
  notes: string | null
  financingDetails: FinancingDetails
  lenderDecisions: FinancingDetails[]
  applicantFinancialDetails: ApplicantFinancialDetails
  coApplicantFinancialDetails: CoApplicantFinancialDetails
  financingCalculatorDetails: FinancingCalculatorDetails
  checkoutDetails: CheckoutDetails
  coverageDetails: CoverageDetails[]
  documentDetails: DocumentDetails[]
  paymentDetails: PaymentDetails[]
  salesCommission: number | null
  salesTaxAmount: number | null
  overridenSalesTaxPercent: number | null
  overridenSalesTaxAmount: number | null
  salesTax: number | null
  workflowAccountingStateId: number | null
  workflowTypeId: WorkflowTypeEnum
  isVehicleDeposited: boolean
  receipts: ReceiptItem[]
  salesperson: DealerAccount | null
  soldDate: string | null
  resultSalesTax: number | null
  bankDetails: BankDetailsDto | null
  executiveDetails: ExecutiveDetailsDto | null
  businessDetails: BusinessDetailsDto | null
  leadId: number | null
  name: string | null
  dealPaidStatusId: number
}

export type DealDto = Deal

export type DateFields = 'saleDate' | 'firstPaymentDate'
export type OmittedProps = 'salesperson' | 'checkoutDetails' | 'customerDetails' | 'coApplicantFinancialDetails'
export type OmittedDeal = Omit<Deal, DateFields | OmittedProps >

export interface DealUpdate extends OmittedDeal {
  saleDate: Date | null
  firstPaymentDate: Date | null
  salesperson: DictionaryItem | null
  checkoutDetails: Omit<CheckoutDetails, 'pickupDeliveryDate'> & {
    pickupDeliveryDate: Date | null
  }
  customerDetails: Omit<CustomerDetails, 'customerBirthDate'> & {
    customerBirthDate: Date | null
  }
  coApplicantFinancialDetails: Omit<CoApplicantFinancialDetails, 'birthDate'> & {
    birthDate: Date | null
  }
}

export interface EmploymentDetails {
  id: number
  employmentStatus: number
  jobTitle: string | null
  employerName: string | null
  employerPhoneNumber: string | null
  income: number | null
  workingStartDate: string | null
}

export interface PreviousEmploymentDetails extends EmploymentDetails {
  workingEndDate: string | null
}

export interface AddressDetails {
  id: number
  address: string | null
  secondaryAddress: string | null
  city: string | null
  state: string | null
  zipCode: string | null
  county: string | null
  apt: string | null
  apartmentMoveInDate: string | null
}

export interface CurrentAddressDetails extends AddressDetails {
  apartmentPayment: number
}

export interface PreviousAddressDetails extends AddressDetails {
  apartmentMoveOutDate: string | null
}

export interface DealVehicleDetails {
  vehicleDetailsId: number
  vehicleMake: string
  vehicleModel: string
  vehicleModelYear: number
  vehicleTrim: string | null
  vehicleImageUrl: string
  vehicleMileage: number
  vehiclePrice: number
  vehicleVIN: string
  vehicleStockNumber: string
  inventoryId: string | null
  vehicleState: VehicleStateNames | null
}

export interface TradeInDetails {
  tradeInDetailsId: number | null
  tradeInVehicleYear: number | null
  tradeInVehicleMake: string | null
  tradeInVehicleModel: string | null
  tradeInVehicleTrim: string | null
  tradeInVehicleVIN: string | null
  tradeInVehicleMileage: number | null
  tradeInCredit: number | null
  tradeInPayoff: number | null
  inventoryVehicleId: number | null
  acv: number | null
}

export interface FinancingCalculatorDetails {
  downPayment: number
  creditScore: number
  term: number
  amountFinanced: number | null
  monthlyPayment: number | null
}

// @deprecated
export interface ApplicantFinancialDetails {
  id: number
  hasCoApplicant: boolean
  creditApplicationConsentAccepted: boolean
  applicationAgreementAccepted: boolean
  apartmentMoveInDate: string | null
  apartmentPayment: number
  previousAddressDetails: PreviousAddressDetails | null
  stateOfIssue: string | null
  housingStatus: number
  driverLicenseNumber: string | null
  socialSecurityNumber: string | null
  hasOtherIncomeSources: boolean
  otherIncome: number | null
  incomeOption: string | null
  wholesaleValue: number | null
  wholesaleSourceType: number | null
  currentEmploymentDetails: EmploymentDetails | null
  previousEmploymentDetails: PreviousEmploymentDetails | null
}

export interface CustomerDetails {
  customerDetailsId: number | null
  customerFirstName: string | null
  customerLastName: string | null
  customerEmail: string | null
  customerBirthDate: string | null
  customerPhoneNumber: string | null
  customerAddress: string | null
  customerSecondaryAddress: string | null
  customerCity: string | null
  customerCounty: string | null
  customerState: string | null
  customerZipCode: string | null
  customerApt: string | null
  socialSecurityNumber: string | null
  title: string | null
}

// AZ-TODO: rename to CoverageDetailsDto
export interface CoverageDetails {
  coverageName: string | null
  dealerCost: number | null
  dealerRetailPrice: number | null
  deductibleAmount: number | null
  deductibleDescription: string | null
  deductibleId: number | null
  fullProductName: string
  id: number
  productName: string | null
  productType: string | null
  providerName: string | null
  termMiles: string | null
  termMonths: string | null
  workflowStepId: number
  dealCoverageDefaultId: number | null
  productTypeId: number
}

export interface CostType {
  amount: number | null
  costLabel: string | null
}

export interface Stipulation {
  comments: string | null
  text: string | null
  shareWithConsumer: boolean
}

export interface FinancingDetails {
  financingDetailsId: number | null
  paymentType: PaymentTypeAPI | null
  buyRate: number | null
  interestRate: number | null
  downPayment: number | null
  financingTerm: number | null
  amountFinanced: number | null
  monthlyPayment: number | null
  fundedAmount: number | null
  approvalStatus: ApprovalStatus | null
  comments: string | null
  stipulations: Stipulation[]
  additionalInformation: string[]
  reserve: number | null
  lienholderId: number | null
  lienholder: LienholderItemDto | null
}

export interface CheckoutDetails {
  checkoutDetailsId: number | null
  hasDelivery: boolean | null
  pickupDeliveryDate: string | null
  deliveryAddress: string | null
  deliveryState: string | null
  deliveryCity: string | null
  deliveryZipCode: string | null
}

// @deprecated
export interface CoApplicantFinancialDetails {
  id: number
  firstName: string | null
  lastName: string | null
  email: string | null
  phoneNumber: string | null
  birthDate: string | null
  socialSecurityNumber: string | null
  coApplicantType: number
  creditApplicationConsentAccepted: boolean
  applicationAgreementAccepted: boolean
  driverLicenseNumber: string | null
  stateOfIssue: string | null
  currentAddressDetails: CurrentAddressDetails | null
  previousAddressDetails: PreviousAddressDetails | null
  primaryApplicantState: string | null
  housingStatus: number
  hasOtherIncomeSources: boolean
  otherIncome: number | null
  incomeOption: string | null
  currentEmploymentDetails: EmploymentDetails | null
  previousEmploymentDetails: PreviousEmploymentDetails | null
}

export interface PaymentDetails {
  id: number | null
  transactionTypeId: PaymentTransactionTypeEnum
  paymentMethodId: PaymentMethod
  amount: number
  amountPayed: number
  paymentStatusId: number
}

export interface DealCheck {
  dealId: EntityId
  templatesIds: TemplateIds
}

export type CoApplicantType = Array<{id: number, name: string}>

export type CreditApplication = Deal

export type CoApplicantFinancialDetailsDto = CoApplicantFinancialDetails
export type ApplicantFinancialDetailsDto = ApplicantFinancialDetails

export interface DealCreateRequestDto {
  vehicleMileage: number | null
  vehiclePrice: number | null
  vehicleVIN: string | null
  vehicleId: number | null
  vehicleStockNumber: string | null
  dealFinanceTypeId: PaymentTypeId
  personalDetails: CreateDealPersonalDetailsDto
  businessDetails?: BusinessDetailsDto | null
  salespersonId: number | null
  workflowType: WorkflowTypeEnum
  leadId: number | null
  leadSourceId: number | null
}

export type DealDetailsUpdateResponse = Partial<Deal> & Partial<ErrorResponse>

export interface PaymentType {
  id: number
  transactionTypeId: number
  amount: number
}

export interface Payment {
  dealId: number
  paymentMethodId: number
  paymentDate: string
  description: string
  isManual: boolean
  payments: PaymentType[]
}

export interface EditPaymentPayload {
  id: number
  paymentDate: string
  description: string
  payments: PaymentType[]
}

export interface RequestDocumentsPayload {
  dealId: EntityId
  data: { documentTypes: string[]}
}

export interface DeleteDocumentPayload {
  rowVersion?: string | null
  dealId: EntityId
  data: { documentsIds: number[]}
}

export interface DocumentDealPayload {
  rowVersion?: string | null
  dealId: EntityId
  data: {
    isClientArea: boolean
    documents: FileIdDto[]
  }
}

export interface DealCashPayload {
  dealId: EntityId
  data: { amount: number }
}

export interface PaymentIntentPayload {
  dealId: EntityId
  amount: number
}

export interface YearItem {
  year: number
}

export interface MakeItem {
  makeId: number
  makeName: string
}

export interface ModelItem {
  modelId: number
  modelName: string
}

export interface GrossProfitReport {
  dateFrom: Date | null
  dateTo: Date | null
  dealStatus: string | null
  users: string | null
  records: GrossProfitRecord[]
  totalSale: number
  totalGross: number
  averageGross: number
  numberOfSales: number
}

export interface SalesTaxRecordDto {
  date: string | null
  dealId: number
  vehicleId: number
  stock: string
  soldTo: string
  zipCode: string
  state: string
  county: string
  salePrice: number
  tradeInCredit: number
  serviceContract: number
  gap: number
  aftermarketsProduct: number
  carDelivery: number
  dealerHandlingFee: number
  dealerInventoryTax: number
  buyerTag: number
  emissions: number
  inspectionFee: number
  plateTransferFee: number
  registrationFee: number
  titleFee: number
  documentFee: number
  additionalCosts: number
  taxableAmount: number
  rate: number
  totalSalesTaxPerReport: number
  totalSalesTaxPerDms: number
  difference: number
  year: number
  make: string
  model: string
}

export interface StateItemDto {
  abbreviation: string
  name: string
}

export interface SalesTaxReportDto {
  dateFrom: string | null
  dateTo: string | null
  records: SalesTaxRecordDto[]
  states: StateItemDto[]
  totalSalePrice: number
  totalTradeInCredit: number
  totalServiceContract: number
  totalGap: number
  totalAftermarketsProduct: number
  totalCarDelivery: number
  totalDealerHandlingFee: number
  totalBuyerTag: number
  totalEmissions: number
  totalInspectionFee: number
  totalPlateTransferFee: number
  totalRegistrationFee: number
  totalTitleFee: number
  totalDealerInventoryTax: number
  totalTaxableAmount: number
  totalAdditionalCosts: number
  totalTotalSalesTaxPerReport: number
  totalTotalSalesTaxPerDms: number
  totalDifference: number
}

export interface GrossProfitRecord {
  date: string | null
  stock: string | null
  carDescription: string | null
  buyerName: string | null
  salesName: string | null
  customerName: string | null
  salePrice: number
  frontGross: number
  backGross: number | null
  totalGross: number
  inventoryVehicleId: number | null
}

export const enum DealStatus {
  Sold = 'Sold',
  PushedToAccounting = 'Pushed to accounting',
  Recorded = 'Recorded'
}

export enum DealState {
  Incomplete = 1,
  Completed,
  Canceled,
  Deleted
}

export interface GrossProfitReportPayload {
  dateFrom: string | null
  dateTo: string | null
  userIds: number[] | null
  accountingStateIds: number[]
}

export interface SalesTaxReportRequestDto {
  dateFrom: string | null
  dateTo: string | null
  search: string
  state: string
  sortOrder: SortOrder
  sortField: string
}

export interface GrossProfitReportXlsxDownloadPayload extends GrossProfitReport {}

export const enum WorkflowTypeEnum {
  Personal = 1,
  Business = 2,
  Wholesale = 3
}

export interface WorkflowTypeDto {
  id: number
  name: string
}

export interface BusinessTypeDto {
  id: number
  name: string
}

export interface BusinessDetailsDto {
  ein: string
  businessName: string
  businessTypeId: number
}

export interface BankDetailsDto {
  name: string
  checkingAccountNumber: string
  contactName: string
  contactPhone: string
}

export interface ExecutiveDetailsDto {
  firstName: string
  lastName: string
  title: string
  ownership: number
  birthDate: string | null
  socialSecurityNumber: string
  address: string
  city: string
  county: string
  state: string
  zipCode: string
  apt: string | null
}

// not used for business
type CreditAppFinancialDetailsOptionalFields = 'housingStatus'
| 'apartmentMoveInDate'
| 'apartmentPayment'
| 'currentEmploymentDetails'
| 'previousEmploymentDetails'
| 'previousAddressDetails'

export type ApplicantFinancialDetailsRequestDto = Omit<CreditApplication['applicantFinancialDetails'], CreditAppFinancialDetailsOptionalFields> & {
  housingStatus?: number | null
  apartmentMoveInDate?: string | null
  apartmentPayment?: number | null
  currentEmploymentDetails?: EmploymentDetails | null
  previousEmploymentDetails?: PreviousEmploymentDetails | null
  previousAddressDetails?: PreviousAddressDetails | null
}

export type CreditAppPropsToOmit =
  | 'salesperson'
  | 'coApplicantFinancialDetails'
  | 'tradeInDetails'
  | 'applicantFinancialDetails'
  | 'salesTax'
  | 'salesTaxAmount'
  | 'transportationCost'
  | 'registrationTax'
  | 'documentFee'
  | 'coverageDetails'

export type CreditAppRequestDto = DealRequestConfig<Omit<CreditApplication, CreditAppPropsToOmit> & {
  coApplicantFinancialDetails: CoApplicantFinancialDetails | null
  applicantFinancialDetails: ApplicantFinancialDetailsRequestDto
  tradeInDetails: TradeInDetails | null
}>

export enum DealActions {
  Submit = 2,
  Review = 3,
  Sign = 4
}

export enum DealStates {
  All = 0,
  NotPaid = 1,
  FullyPaid = 2
}

export interface DealsTotalGetRequestDto {
  search: string
  cancelationOptions?: CancelationOptions
  workflowAccountingStates?: number[] | null
  isDeposited: boolean
  date?: {
    from: string | null
    to: string | null
  } | null
  nextAction?: number[]
  dealPaidStatusId?: DealStates
}

interface PurchasingDepositDetails {
  dealId: number
  amount: number
  userName: string
  createdDate: string
}

export interface PurchasingDetailsDto {
  soldDate: string | null
  soldDealId: string | null
  salePrice: number | null
  depositDetails: PurchasingDepositDetails[]
  soldToUserName: string | null
}

export interface LienholderCreateRequestDto {
  name?: string | null
  phoneNumber?: string | null
  email?: string | null
  corpCode?: string | null
  address?: string | null
  city?: string | null
  state?: string | null
  zipCode?: string | null
}

export interface LienholderUpdateRequestDto extends LienholderCreateRequestDto {
  id: number
}

export interface LienholderItemDto {
  id: number
  dealerId: number
  name: string | null
  phoneNumber: string | null
  email: string | null
  corpCode: string | null
  address: string | null
  city: string | null
  state: string | null
  zipCode: string | null
}

// region PAYLOADS

export interface DealVehiclePatchRequestDto {
  vehicle: {
    vehicleId: number
    actualMileage: number
  }
  tradeInDetails: {
    vehicleVin: string
    vehicleMake: string
    vehicleModel: string
    vehicleTrim: string | null
    vehicleYear: number | null
    mileage: number | null
    acv: number | null
    credit: number | null
    paymentAmount: number | null
  } | null
}

export interface DealBuyerPatchRequestDto {
  personalDetails: {
    id: number
    address: string
    city: string
    state: string
    zipCode: string
    apt: string | null
    title: string | null
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    birthDate: string | null
    socialSecurityNumber: string | null
  }

  coApplicantFinancingDetails: {
    id: number
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    birthDate: string | null
    coApplicantType: number
    socialSecurityNumber: string | null

    currentAddressDetails: {
      id: number
      address: string
      secondaryAddress: string | null
      city: string
      state: string
      zipCode: string
      apt: string | null
      apartmentMoveInDate: string | null
    }
  } | null

  businessDetails: {
    businessName: string
    ein: string
    businessTypeId: number
  } | null
}
// endregion PAYLOADS

export interface AccountingDetailsDto {
  customerBalance: number
  lienholderBalance: number
  dealPaidStatusId: number
}

// region NEW DEAL DETAILS DTOs

export interface Deal2Dto {
  accountingStateChangeDate: string | null
  // DD-TODO: add full type when needed
  applicantFinancingDetails?: {
    hasCoApplicant: boolean
  }
  businessDetails: BusinessDetailsDto | null
  checkoutDetails: CheckoutDetailsDto | null
  coApplicantFinancingDetails: CoApplicantFinancialDetailsDto | null
  coverageDetails: CoverageDetails[] | null
  createdDate: string
  creditApplication: CreditApplicationInfoDto | null
  currentDealStep: WorkflowStepDto
  customerId: number
  dealerId: number
  dealAccountingStateId: number
  dealFees: DealFeesDto
  dealFinanceTypeId: number | null
  dealStateId: number
  dealStateChangeReasonId: number
  dealSteps: WorkflowStepDto[]
  dealTemplateId: number
  dealTypeId: number
  documents: DocumentDetailsDto[]
  financeInfo: FinanceInfoDto
  firstPaymentDate: string | null
  id: number
  isCreatedByCustomer: boolean
  isDeposited: boolean
  leadId: number | null
  nextAction: number | null
  notes: string | null
  paymentDetails: PaymentDetailsDto[]
  personalDetails: PersonalDetailsDto | null
  recapTransactionId: number | null
  rowVersion: string | null
  receipts: ReceiptItem[]
  salesperson: SalespersonDto | null
  salespersonId: number | null
  saleDate: string
  soldDate: string | null
  tradeInDetails: TradeInDetailsDto | null
  vehicle: VehicleDto | null
  accountingDetails: AccountingDetailsDto
}

export interface PersonalDetailsDto {
  id: number
  email: string | null
  firstName: string | null
  lastName: string | null
  birthDate: string | null
  phoneNumber: string | null
  address: string | null
  county: string | null
  secondaryAddress: string | null
  city: string | null
  state: string | null
  zipCode: string | null
  apt: string | null
  socialSecurityNumber: string | null
  title: string | null
}

export interface VehicleDto {
  id: number
  vin: string
  dealId: number
  make: string
  model: string
  modelYear: number
  trim: string | null
  odometer: number
  weight: number
  listPrice: number
  stockNumber: string | null
  imageUrl: string | null
  inServiceDate: string | null
  bodyStyle: string | null
  color: string | null
  inventoryVehicleId: number | null
  inventoryVehicle: InventoryVehicleDto | null
}

export interface InventoryVehicleDto {
  id: number
  vin: string
  year: number
  make: string
  model: string
  trim: string
  mileage: number | null
  stock: string | null
  mainImageUrl: string | null
  inventoryDate: string
  salePrice: number | null
  dealerId: number
  soldDate: string | null
  vehicleState: string
  vehicleStatus: string | null
  isDeposited: boolean
  buyerId: number | null
}

export interface StipulationsDto {
  shareWithConsumer: boolean
  comments: string | null
  text: string | null
}

export interface FinancingApplicationLenderDecisionDetailsDto {
  id: number
  creditApplicationId: number
  partnerId: string | null
  partnerDealerId: string | null
  dealRefId: string | null
  approvalStatus: string | null
  approvedAmount: number | null
  approvedRate: number | null
  approvedTerm: number | null
  lenderId: string | null
  lenderName: string | null
  lienholderId: number
  tier: string | null
  dealerMessage: string | null
  fromEmailAddress: string | null
  dealerCode: number
  lenderApplicationId: string | null
  lenderMoneyFactor: number | null
  customerMoneyFactor: number | null
  downPayment: number | null
  monthlyPayment: number | null
  financeMethod: string | null
  dealerDiscount: number | null
  dealUpdate: boolean
  fundedAmount: number | null
  rateVariance: string | null
  eContractEligible: boolean
  maxAmtToFinance: string | null
  resubmitReturnedEcontract: boolean
  message: string | null
  isAccepted: boolean
  stipulations: StipulationsDto[] | null
  additionalInformation: string[] | null
  lienholder: LienholderItemDto
}

export interface CreditApplicationInfoDto {
  acquisitionFee: number | null
  buyRate: number | null
  reserve: number | null
  submittedDate: string | null
  financingApplicationLenderDecisionDetails: FinancingApplicationLenderDecisionDetailsDto[]
}

export interface PaymentDetailsDto {
  id: number
  transactionTypeId: PaymentTransactionTypeEnum
  paymentMethodId: PaymentMethod
  amount: number
  amountPayed: number
  paymentStatusId: number
  isManual: true
}

export interface WorkflowStepDto {
  id: number
  dealStepStateId: number
  dealId: number
  dealTemplateStepId: number
  templateStepId: number
  order: number
  startDateTime: string | null
}

export interface TradeInDetailsDto {
  id: number
  vehicleId: number
  mileage: number | null
  hasAnyAccidents: boolean | null
  haveAirbagsDeployed: boolean | null
  totalRepairCosts: number | null
  anyMechanicalIssues: boolean | null
  mechanicalIssueType: number | null
  stillMakingPayments: boolean | null
  paymentType: number
  paymentAmount: number | null
  vehicleMake: string
  vehicleModel: string
  vehicleTrim: string | null
  vehicleYear: number | null
  vehicleVin: string
  acv: number | null
  providerPrice: number | null
  credit: number | null
  inventoryVehicleId: number
  tradeInProviderId: number | null
  dealId: number
}

export interface SalespersonDto {
  id: number
  dealerFirstName: string | null
  dealerLastName: string | null
  email: string | null
}

export interface DealFeesDto {
  /**
   * Fees and taxes, configurable from "Settings".
   */
  buyerTag: number
  carDelivery: number
  dealerHandlingFee: number
  dealerInventoryTax: number
  emissions: number
  inspectionFee: number
  plateTransferFee: number
  registrationFee: number
  titleFee: number

  /**
   * Fees and taxes, common for all dealerships.
   */
  overridenSalesCommission: number | null
  stateSalesTaxPercent: number
  overridenSalesTaxPercent: number | null
  overridenSalesTaxAmount: number | null

  /**
   * "Additional costs" section.
   */
  other1: number | null
  other1Description: string | null
  other2: number | null
  other2Description: string | null
  other3: number | null
  other3Description: string | null
}

export interface FinanceInfoDto {
  coverages: number | null
  feesAndTaxes: number
  monthlyPayment: number | null
  netTradeInValue: number | null
  payments: null | {
    deposit: number | null
    downPayment: number | null
    totalDownPayment: number
  }
  resultSalesTaxAmount: number
  totalDown: number
  totalPrice: number
}

export interface CustomCoverageItemDto {
  dealCoverageDefaultId: number
}

export interface PenCoverageItemDto {
  deductibleId: number
  dealerRetailPrice: number
}

export interface UpdateCoverageRequestDto {
  dealId: number | string
  rowVersion: string
  coverages: CustomCoverageItemDto[] | null
}

// endregion NEW DEAL DETAILS DTOs
