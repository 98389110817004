import type { PopoverPropsV2 } from '@carfluent/common'

import { VehicleState } from 'api/types'

export const POPOVER_OPTIONS: PopoverPropsV2['options'] = {
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 8]
      }
    }
  ]
}

export enum FilterItemNames {
  All = 'All',
  Active = 'Active',
  Deposited = 'Deposited',
  Inactive = 'Inactive',
  Sold = 'Sold',
  Deleted = 'Deleted'
}

export const EMPTY_FILTER_TITLE = 'Select'
export const ALL_SELECTED_ITEMS = [
  VehicleState.Active,
  VehicleState.Inactive,
  VehicleState.Sold,
  VehicleState.Deleted
]

export const DEFAULT_SELECTED_ITEMS = [
  VehicleState.Active,
  VehicleState.Inactive
]

export const ALL_ID = 0
