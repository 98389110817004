import type { FC, ReactNode } from 'react'

import IconSVG from 'components/inlineImages'
import NameWithAvatar from 'components/common/NameWithAvatar'

import CLASS_NAME from './styles'

interface PriceHistoryProps {
  priceHistoryList: PriceHistoryMergedItem[]
  onHandlerClose: () => void
}

export interface PriceHistoryItem {
  id: string
  salePrice: string
  createdDate: string
  createdBy: string
  status: 'increased' | 'decreased' | 'not-changed'
}

interface RepriceHistoryItem {
  id: number
  description: string
  deductionDescription: string | null
}

export type PriceHistoryMergedItem = PriceHistoryItem | RepriceHistoryItem

const renderPriceHistoryBody = ({
  salePrice,
  createdDate,
  createdBy,
  status
}: PriceHistoryItem): JSX.Element => {
  const statusContent = (): ReactNode => {
    if (status === 'not-changed') {
      return (
        <div className='not-changed-mark'>
          <div />
        </div>
      )
    }

    return status === 'increased' ? <IconSVG.IncreaseArrowIcon /> : <IconSVG.DecreaseArrowIcon />
  }
  return (
    <>
      <div className='history-table-row-item with-icon'>
        {statusContent()}
        {salePrice}
      </div>
      <div className='history-table-row-item'>
        {createdDate}
      </div>
      <div className='history-table-row-item name-col'>
        {createdBy === 'Automatically'
          ? (
            <>
              <IconSVG.AutomaticallyIcon />
              {createdBy}
            </>
            )
          : <NameWithAvatar className='avatar-wrapper' name={createdBy} showAvatar />}
      </div>
    </>
  )
}

const PriceHistory: FC<PriceHistoryProps> = ({
  priceHistoryList,
  onHandlerClose
}) => {
  return (
    <div className={CLASS_NAME}>
      <div className='history-table-header'>
        <div className='history-table-header-item'>Price</div>
        <div className='history-table-header-item'>When</div>
        <div className='history-table-header-item'>Changed by</div>
      </div>

      <div className='history-table-body'>
        {
          priceHistoryList.length > 0
            ? priceHistoryList.map((item) => (
              <div className='history-table-row' key={item.id}>
                {
                  'salePrice' in item
                    ? renderPriceHistoryBody(item)
                    : (
                      <p className='repricing-description'>
                        {item.description}
                        {item.deductionDescription != null && (
                          <><br /> {item.deductionDescription}</>
                        )}
                      </p>
                      )
                }
              </div>
            ))
            : <div className='history-table-row no-price'>No price history yet</div>
        }
      </div>
      <div className='history-table-button' onClick={onHandlerClose}>Close</div>
    </div>
  )
}

export default PriceHistory
