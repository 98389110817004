import type { FormValidation, DeepRequired } from '@carfluent/common'
import type { MessageTemplateFormData } from './types'

import { nameTemplateField } from 'utils/validationPresets'

const namePreset = nameTemplateField()

const validationRules: FormValidation<DeepRequired<MessageTemplateFormData>> = {
  name: namePreset
}

export default validationRules
