import type { CoverageDefault, CoverageDefaultSection } from 'types/coverage'

export const GET_MANUAL_DEFAULT = (): CoverageDefault => ({
  id: 0,
  cost: null,
  termMiles: 0,
  termMonths: 0,
  totalPrice: null,
  providerId: null,
  coverageName: '',
  productTypeId: 0,
  deductibleId: null,
  isFormDisabled: true,
  deductibleAmount: null,
  productCoverageId: null
})

export const generateCoverageSectionsData = (el: unknown, productTypeId: number): CoverageDefaultSection => (
  {
    coverageId: 0,
    isPenEnabled: true,
    coverageType: 'PEN',
    productTypeId: productTypeId + 1,

    forms: {
      PEN: {
        id: 0,
        cost: null,
        termMiles: 0,
        termMonths: 0,
        providerId: null,
        totalPrice: null,
        coverageName: '',
        productTypeId: 0,
        deductibleId: null,
        isFormDisabled: true,
        productCoverageId: 0,
        deductibleAmount: null
      },
      Manual: GET_MANUAL_DEFAULT()
    }
  }
)
