import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

import CommissionFieldsSet from './components/CommissionFieldsSet'
import { type SalesCommissionFieldsProps } from './types'
import CLASS_NAME from './styles'

const SalesCommissionFields: FC<SalesCommissionFieldsProps> = ({
  backGrossSalesCommissionSetting,
  className,
  frontGrossSalesCommissionSetting,
  isLoading,
  ...fieldsSetProps
}) => {
  return (
    <div className={cnx(CLASS_NAME, className)}>
      <div className='sub-title-container'>
        <BlockSkeleton isLoading={isLoading} className='title-skeleton'>
          <h6 className='section-title h6-title'>
            Sales commission
          </h6>
        </BlockSkeleton>
      </div>

      <div className='sales-commission-fields-container'>
        <div className='fields-set-container'>
          <BlockSkeleton isLoading={isLoading} className='title-loader'>
            <h6 className='fields-set-title'>Front gross:</h6>
          </BlockSkeleton>

          <CommissionFieldsSet
            {...fieldsSetProps}
            className='fields-set-content'
            isLoading={isLoading}
            originalValues={frontGrossSalesCommissionSetting}
            pathPrefix='frontGrossSalesCommissionSetting'
          />
        </div>

        <div className='fields-set-container'>
          <BlockSkeleton isLoading={isLoading} className='title-loader'>
            <h6 className='fields-set-title'>Back gross:</h6>
          </BlockSkeleton>

          <CommissionFieldsSet
            {...fieldsSetProps}
            className='fields-set-content'
            isLoading={isLoading}
            originalValues={backGrossSalesCommissionSetting}
            pathPrefix='backGrossSalesCommissionSetting'
          />
        </div>
      </div>
    </div>
  )
}

export default SalesCommissionFields
