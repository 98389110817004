import { addDays, parse } from 'date-fns'
import { serializers } from '@carfluent/common'

import { isValidDate } from 'utils/validation'
import { TIME_12H_MIN_AMPM_FORMAT } from 'constants/constants'
import type { CreateShiftRequestDto, UpdateShiftRequestDto } from 'api/types'
import type { ShiftDetailsFormData } from './types'

const { serializeDateTimeUTC } = serializers

export const serializeCreateShift = (shift: ShiftDetailsFormData): CreateShiftRequestDto => {
  const resolvedDate = isValidDate(shift.date) ? shift.date : new Date()
  const startDate = parse(shift.startTime, TIME_12H_MIN_AMPM_FORMAT, resolvedDate)
  let endDate = parse(shift.endTime, TIME_12H_MIN_AMPM_FORMAT, resolvedDate)

  if (endDate <= startDate) {
    endDate = addDays(endDate, 1)
  }

  return {
    userId: Number(shift.person?.id),
    startDate: serializeDateTimeUTC(startDate),
    endDate: serializeDateTimeUTC(endDate)
  }
}

export const serializeUpdateShift = (shift: ShiftDetailsFormData, id: number): UpdateShiftRequestDto => ({
  ...serializeCreateShift(shift),
  id
})
