import { css } from '@emotion/css'

export default css(`
  display: flex;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(33, 33, 33, 0.50);

  .item {
    display: flex;
    align-items: center;
    gap: 4px;

    &.active {
      .name {
        color: #212121;
      }
      svg {
        fill-opacity: 1;
      }
    }
    
    &.current-active-item {
      .name {
        font-weight: 500;
      }
    }
  }
`)
