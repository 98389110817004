import type { FC } from 'react'
import { Skeleton } from '@material-ui/lab'
import { UI } from '@carfluent/common'

import CLASS_NAME from './styles'

const { CellWrapper } = UI

const LoadingTwoLinesCell: FC = () => {
  return (
    <CellWrapper className={CLASS_NAME}>
      <Skeleton className='basic-skeleton' variant='text' />
      <Skeleton className='basic-skeleton' variant='text' />
    </CellWrapper>
  )
}

export default LoadingTwoLinesCell
