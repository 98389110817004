import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { toJS } from 'mobx'
import { useModal, useLoader } from '@carfluent/common'

import { getParsedToken, getUserRoles } from 'services/storage.service'
import { type DictionaryItem, VehicleState, type WorkflowTypeDto, VehicleStatus, UserRoles } from 'api/types'
import { type NewDealFormData } from 'types'
import VehiclesApiProvider from 'api/vehicles.api'
import DocumentsApiProvider from 'api/documents.api'
import CustomersCoreApiProvider from 'api/customersCore.api'
import SettingsCTX from 'store/settings'
import useAsyncEffect from 'hooks/useAsyncEffect'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useCostsSummary from 'hooks/useCostsSummary'
import useCostsDetails from 'hooks/useCostDetails'
import { isTruthy } from 'utils/general'
import { type ActionOptions } from 'components/common/ActionsMenu'

/**
 * AZ-TODO: remove these imports. They should not exist.
 * All inter-tabs communication (save/reset) should be done through `useMediator`.
 * See the reference implementation on the DealDetails page.
 */
import useGalleryTab from '../components/GalleryTabPanel/hook' // AZ-TODO: temporal, part of the ongoing refactoring
import useGeneralTab from './useGeneralTab'
import useCostsTab from './useCostsTab'
import useLeadsTab from './useLeadsTab'
import { useFilesTab } from './useFilesTab'

import VehicleDetailsCTX from './store'
import { TabIds, Messages } from './constants'
import type { SavedTab, UseVehicleDetailsReturn } from './types'
import GeneralTabPanelCTX from './useGeneralTab/store'
import { useBlocker } from './useBlocker'

const FORBIDDEN_ROLES_TO_CREATE_DEAL = [UserRoles.Buyer, UserRoles.Mechanic]

const useVehicleDetails = (): UseVehicleDetailsReturn => {
  const token = getParsedToken()
  const dealerId: string = token?.dealer ?? ''
  const navigate = useNavigate()
  const { isAccountingEnabled, dealer } = useContext(SettingsCTX)
  const { id: vehicleId = '' } = useParams<{ id: string }>()
  const { showAlert } = useCustomSnackbar()

  const [tagDictionaries, setTagDictionaries] = useState<DictionaryItem[]>([])
  const [statusToChange, setStatusToChange] = useState<VehicleStatus | null>(null)
  const [isUnsavedChangesBlocked, setIsUnsavedChangesBlocked] = useState(false)
  const [isUploadingDocumentsBlocked, setIsUploadingDocumentsBlocked] = useState(false)
  const [isDeleteChecking, setIsDeleteChecking] = useState(false)
  const [workflowTypes, setWorkflowTypes] = useState<WorkflowTypeDto[]>([])
  const [canBeDeleted, setCanBeDeleted] = useState(false)

  const { hash } = useLocation()
  const {
    isLoading: isTagLoading,
    stopLoader: stopTagLoading,
    startLoader: startTagLoading
  } = useLoader()

  const {
    apiErrors,
    cleanUp,
    closeUnsavedChanges,
    isOpenUnsavedChanges,
    openUnsavedChanges,
    requestedNextTab,
    selectedTab,
    setApiErrors,
    setSelectedTab,
    updateOriginalVehicle
  } = useContext(VehicleDetailsCTX)

  const leadsPanel = useLeadsTab({ vehicleId })
  const generalTabProps = useGeneralTab({ setApiErrors, vehicleId, fetchLeadsTab: leadsPanel.fetchLeadsTab })
  const { vehicleFormData } = useContext(GeneralTabPanelCTX)

  const {
    isTabDirty: isGeneralTabDirty,
    isTabSaving: isGeneralTabSaving,
    isValid: isGeneralTabValid,
    onProcessReset: resetGeneralTab,
    onProcessSave: saveGeneralTab,
    originalVehicle,
    touched: generalTabTouched,
    updateFormData,
    setFieldValue,
    values: vehicleDetails,
    tags
  } = generalTabProps

  const isCarSold = vehicleDetails.vehicleState === VehicleState.Sold
  const isCarDeleted = vehicleDetails.vehicleState === VehicleState.Deleted

  const {
    isTabDirty: isGalleryTabDirty,
    isTabSaving: isGalleryTabSaving,
    onProcessReset: resetGalleryTab,
    onProcessSave: saveGalleryTab,
    ...galleryTabProps
  } = useGalleryTab(saveGeneralTab)

  /**
   * Salesperson = user who clicked Create deal
   * Car, price, and mileage is prefilled with car details
   */
  const newDealDefaultValues: Partial<NewDealFormData> = useMemo(() => ({
    vehicle: { ...vehicleDetails }
  }), [
    vehicleDetails,
    vehicleDetails?.rowVersion // MobX...
  ])

  const costsPanelProps = useCostsTab({ vehicleId })
  const {
    isTabDirty: isCostsTabDirty,
    isTabSaving: isCostsTabSaving,
    onProcessReset: resetCostsTab,
    onProcessSave: saveCostsTab
  } = costsPanelProps

  const costsPanelDetailsProps = useCostsDetails(vehicleId)
  const costsPanelSummaryProps = useCostsSummary(vehicleId)

  const {
    isLoading: isDocumentsUploading,
    ...filesTabProps
  } = useFilesTab({
    documents: generalTabProps.values.vehicleDocuments,
    updateOriginalVehicle,
    updateFormData,
    vehicleId
  })

  const deleteVehicleModalProps = useModal()
  const transactionModalProps = useModal()
  const newDealModalProps = useModal()

  const isCurrentTabSaving = isCostsTabSaving || isGalleryTabSaving || isGeneralTabSaving
  const isNotificationVisible =
    vehicleFormData.vehicleState === VehicleState.Active &&
    !isGeneralTabValid &&
    Object.values(generalTabTouched).filter(Boolean).length > 0

  const shouldGeneralBlock = (isCostsTabDirty || isGeneralTabDirty || isGalleryTabDirty) &&
    !isUnsavedChangesBlocked

  const shouldDocumentsBlock = TabIds.Files === selectedTab && isDocumentsUploading

  const { proceedTransition, resetTransition } = useBlocker({
    shouldGeneralBlock,
    shouldDocumentsBlock,
    onBlockGeneral: openUnsavedChanges,
    onBlockDocuments: () => setIsUploadingDocumentsBlocked(true)
  })

  const linkToWebsite = `${dealer?.dealerFeeMarkupSettings?.websiteBaseUrl ?? ''}dealer/${dealerId}`

  const isAnyTabDirty = isGeneralTabDirty || isGalleryTabDirty || isCostsTabDirty

  const tabsSavingState: Record<number, SavedTab> = useMemo(() => ({
    [TabIds.General]: {
      isTabDirty: isGeneralTabDirty,
      processReset: resetGeneralTab,
      processSave: saveGeneralTab
    },
    [TabIds.Media]: {
      isTabDirty: isGalleryTabDirty,
      processReset: resetGalleryTab,
      processSave: saveGalleryTab
    },
    [TabIds.Costs]: {
      isTabDirty: isCostsTabDirty,
      processReset: resetCostsTab,
      processSave: saveCostsTab
    }
  }), [
    isCostsTabDirty,
    isGalleryTabDirty,
    isGeneralTabDirty,
    resetCostsTab,
    resetGalleryTab,
    resetGeneralTab,
    saveCostsTab,
    saveGalleryTab,
    saveGeneralTab
  ])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onAddTagInVehicle = useCallback(async (tagId) => {
    await setFieldValue('tags', [tagId, ...vehicleDetails.tags])
  }, [setFieldValue, vehicleDetails.tags])

  const onChangeStatus = useCallback(async (id: VehicleStatus | null) => {
    if (id === null && statusToChange !== null) {
      await setFieldValue('vehicleStatus', statusToChange)
      setStatusToChange(null)
    } else {
      setStatusToChange(generalTabProps.values.vehicleStatus)
      await setFieldValue('vehicleStatus', id)
    }
  }, [generalTabProps.values.vehicleStatus, statusToChange])

  const onResetStatus = useCallback(() => {
    setStatusToChange((prevVal) => {
      if (prevVal !== null) {
        void setFieldValue('vehicleStatus', prevVal)
      }

      return null
    })
  }, [statusToChange])

  const onConfirmStatus = useCallback(async () => {
    if (statusToChange == null) {
      onResetStatus()
      return
    }

    try {
      const { rowVersion } = await VehiclesApiProvider.updateVehicleStatus(vehicleId, generalTabProps.values.vehicleStatus ?? statusToChange)
      setStatusToChange(null)
      await setFieldValue('rowVersion', rowVersion)
      showAlert(Messages.UpdateVehicleStatusSuccess, { variant: 'success' })
    } catch {
      showAlert(Messages.UpdateVehicleStatusError, { variant: 'error' })
      onResetStatus()
    }
  }, [onResetStatus, setFieldValue, generalTabProps.values.vehicleStatus])

  const onAddTag = useCallback(async (tag: string) => {
    try {
      startTagLoading()
      const addedTag = await VehiclesApiProvider.addTag(tag)

      setTagDictionaries(prev => ([addedTag, ...prev]))
      await onAddTagInVehicle(addedTag.id)
    } finally {
      stopTagLoading()
    }
  }, [
    startTagLoading,
    stopTagLoading,
    onAddTagInVehicle
  ])

  const onDeleteTagFromVehicle = useCallback((tagName: string) => {
    const tagId = tags.find(({ name }) => tagName.trim() === name)?.id
    const index = vehicleDetails.tags.findIndex((id) => tagId === id)
    const newValues = [...vehicleDetails.tags]

    if (index === -1) {
      return
    }

    newValues.splice(index, 1)
    void setFieldValue('tags', newValues)
  }, [setFieldValue, vehicleDetails.tags, tags])

  const onTabSwitchRequest = useCallback(async (nextTabIdx: number) => {
    const currTab = tabsSavingState[selectedTab]

    if (isTruthy(currTab?.isTabDirty)) {
      openUnsavedChanges(nextTabIdx)
    } else {
      setSelectedTab(nextTabIdx)
    }
  }, [
    openUnsavedChanges,
    selectedTab,
    setSelectedTab,
    tabsSavingState
  ])

  const findDirtyTab = (): SavedTab[] => {
    return Object.values(tabsSavingState).filter(el => el.isTabDirty)
  }

  const onSaveDirtyTabs = useCallback(async () => {
    const dirtyTabs = findDirtyTab()
    let allSaved = true

    for (const el of dirtyTabs) {
      const isSaved = await el?.processSave()
      if (isSaved) {
        showAlert(Messages.SaveSuccess, { variant: 'success' })
      } else {
        allSaved = false
      }
    }

    return allSaved
  }, [selectedTab, tabsSavingState])

  const onResetDirtyTabs = useCallback(() => {
    const dirtyTabs = findDirtyTab()
    dirtyTabs.map(async el => {
      await el?.processReset()
    })
  }, [selectedTab, tabsSavingState])

  const onGoBack = useCallback((): void => {
    navigate(-1)
  }, [navigate])

  const onSubmitTransactions = useCallback(async (): Promise<void> => {
    await Promise.all([
      costsPanelProps.getSummary(),
      costsPanelProps.getDetails()
    ])
  }, [costsPanelProps.getSummary, costsPanelProps.getDetails])

  const onDeleteVehicle = useCallback(async () => {
    try {
      await VehiclesApiProvider.deleteVehicle(vehicleId)
      showAlert('Vehicle was deleted', { variant: 'success' })
      setIsUnsavedChangesBlocked(true)
      onGoBack()
    } catch {
      showAlert('Could not delete vehicle')
      deleteVehicleModalProps.onCloseModal()
    }
  }, [
    vehicleId,
    onGoBack,
    deleteVehicleModalProps.onCloseModal,
    showAlert
  ])

  const onCheckDeletingCar = useCallback((): void => {
    const doJob = async (): Promise<void> => {
      try {
        setIsDeleteChecking(true)
        deleteVehicleModalProps.onOpenModal()
      } catch (e) {
        showAlert(e)
      } finally {
        setIsDeleteChecking(false)
      }
    }

    void doJob()
  }, [
    vehicleId,
    deleteVehicleModalProps.onOpenModal,
    showAlert
  ])

  const onUpdateVehicleNotes = useCallback(async (notes: string | null): Promise<void> => {
    try {
      const { rowVersion } = await VehiclesApiProvider.postNotes({ id: vehicleId, notes: notes ?? '' })
      updateOriginalVehicle({ rowVersion, notes })
      updateFormData({ rowVersion, notes })
    } catch (err) {
      showAlert(err)
    }
  }, [
    vehicleId,
    updateOriginalVehicle,
    updateFormData,
    showAlert
  ])

  const onResetTabNavigation = (): void => {
    setSelectedTab(selectedTab)
  }

  const onDontSaveAndCloseForm = useCallback(() => {
    closeUnsavedChanges()

    if (requestedNextTab == null) {
      proceedTransition()
    } else {
      void onResetDirtyTabs()
      setSelectedTab(requestedNextTab)
    }
  }, [
    closeUnsavedChanges,
    requestedNextTab,
    proceedTransition,
    setSelectedTab
  ])

  const onSaveAndCloseForm = useCallback(async () => {
    closeUnsavedChanges()
    const isSaved = await onSaveDirtyTabs()

    if (!isSaved) {
      return
    }

    if (requestedNextTab == null) {
      proceedTransition()
    } else {
      setSelectedTab(requestedNextTab)
    }
  }, [
    closeUnsavedChanges,
    onSaveDirtyTabs,
    requestedNextTab,
    proceedTransition,
    setSelectedTab
  ])

  const onPrintBuyersGuide = useCallback(() => {
    const doJob = async (): Promise<void> => {
      try {
        const res = await DocumentsApiProvider.downloadBuyersGuide(vehicleId)
        window.open(
          URL.createObjectURL(new Blob([res], { type: 'application/pdf' })),
          '_blank'
        )
      } catch {
        showAlert('Cannot print Buyers Guide.')
      }
    }

    void doJob()
  }, [vehicleId])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Some clean-up on unmount.
   */
  useEffect(() => { return cleanUp }, [cleanUp])
  useEffect(() => setTagDictionaries(tags), [tags])

  useAsyncEffect(async () => {
    try {
      const { canBeDeleted } = await VehiclesApiProvider.isCarCanBeDeleted(vehicleId)
      setCanBeDeleted(canBeDeleted)
    } catch (error) {
      console.log('error: ', 'color: red; display: block; width: 100%;', error)
    }
  }, [costsPanelSummaryProps.items, costsPanelDetailsProps.items]) // Need to re-check if car can be deleted when costs or JE changes

  useEffect(() => {
    if (hash != null) {
      const tab = hash.slice(1)
      setSelectedTab(Number(tab))
    }
  }, [hash, setSelectedTab])

  useEffect((): void => {
    const loadWorkflowTypes = async (): Promise<void> => {
      try {
        const response = await CustomersCoreApiProvider.getWorkflowTypes()
        setWorkflowTypes(response.items)
      } catch {
        // DO NOTHING
      }
    }

    void loadWorkflowTypes()
  }, [])

  // ========================================== //

  const _costsPanelProps = useMemo(() => ({
    ...costsPanelProps,
    defaultControlData: toJS(originalVehicle) as any // AZ-TODO: replace to proper type
  }), [costsPanelProps, originalVehicle])

  const deleteVehicleTooltip = isCarDeleted
    ? ''
    : isCarSold
      ? 'Sold car cannot be deleted.'
      : !canBeDeleted ? 'The car cannot be deleted because there are journal entries in the accounting system linked to this car.' : ''

  const topMenuActions: ActionOptions[] = useMemo(() => {
    const rolesToCreateDeal = getUserRoles().filter((role) => (
      !FORBIDDEN_ROLES_TO_CREATE_DEAL.includes(role)
    ))

    const canCreateDeal = rolesToCreateDeal.length > 0

    return ([
      {
        title: 'Create deal',
        disabled: isCarSold || isCarDeleted || !canCreateDeal,
        handleOnClick: () => { newDealModalProps.onOpenModal() }
      },
      {
        title: 'Print Buyers Guide',
        handleOnClick: onPrintBuyersGuide
      },
      {
        placement: 'top',
        title: 'Delete vehicle',
        tooltip: deleteVehicleTooltip,
        disabled: isCarSold || isCarDeleted || !canBeDeleted,
        handleOnClick: onCheckDeletingCar,
        optionType: 'highlighted'
      }
    ])
  }, [
    canBeDeleted,
    isCarDeleted,
    isCarSold,
    onCheckDeletingCar,
    onPrintBuyersGuide,
    newDealModalProps.onOpenModal
  ])

  return useMemo(() => {
    return ({
      apiErrors,
      costsPanelProps: _costsPanelProps,
      costsPanelDetailsProps,
      costsPanelSummaryProps,
      deleteVehicleModalProps,
      galleryTabProps,
      generalTabProps,
      isAccountingEnabled,
      isCarSold,
      isCarDeleted,
      isSaving: isCurrentTabSaving,
      isNotificationVisible,
      isOpenUnsavedChanges,
      linkToWebsite,
      onResetDirtyTabs,
      handleCloseUnsavedChanges: closeUnsavedChanges,
      handleDontSaveAndCloseForm: onDontSaveAndCloseForm,
      handleSaveAndCloseForm: onSaveAndCloseForm,
      handleGoBack: onGoBack,
      handleUpdateVehicleNotes: onUpdateVehicleNotes,
      handleDelete: onDeleteVehicle,
      onSavePage: onSaveDirtyTabs,
      onTabSwitchRequest,
      selectedTab,
      setSelectedTab,
      vehicleId,
      isTagLoading,
      onAddTag,
      onAddTagInVehicle,
      onDeleteTagFromVehicle,
      tagDictionaries,
      onChangeStatus,
      onResetStatus,
      onConfirmStatus,
      statusToChange,
      transactionDetailsProps: {
        ...transactionModalProps,
        onSubmit: onSubmitTransactions
      },
      leadsPanel: {
        ...leadsPanel,
        viewsCount: vehicleDetails.viewsCount
      },
      filesTabProps,
      isUploadingDocumentsBlocked,
      setIsUploadingDocumentsBlocked,
      proceedTransition,
      isDeleteChecking,
      topMenuActions,
      newDealModalProps,
      newDealDefaultValues,
      workflowTypes,
      resetTransition,
      isAnyTabDirty,
      onResetTabNavigation
    })
  }, [
    _costsPanelProps,
    deleteVehicleModalProps,
    apiErrors,
    originalVehicle,
    costsPanelDetailsProps,
    costsPanelSummaryProps,
    galleryTabProps,
    generalTabProps,
    isAccountingEnabled,
    tabsSavingState[selectedTab]?.isTabDirty,
    isNotificationVisible,
    isOpenUnsavedChanges,
    isCurrentTabSaving,
    linkToWebsite,
    closeUnsavedChanges,
    onDontSaveAndCloseForm,
    onResetTabNavigation,
    onSaveAndCloseForm,
    onGoBack,
    onUpdateVehicleNotes,
    onDeleteVehicle,
    onSaveDirtyTabs,
    onTabSwitchRequest,
    selectedTab,
    setSelectedTab,
    vehicleId,
    isTagLoading,
    onAddTag,
    onAddTagInVehicle,
    onDeleteTagFromVehicle,
    tagDictionaries,
    onChangeStatus,
    onResetStatus,
    onConfirmStatus,
    statusToChange,
    transactionModalProps,
    leadsPanel,
    filesTabProps,
    isUploadingDocumentsBlocked,
    setIsUploadingDocumentsBlocked,
    proceedTransition,
    isDeleteChecking,
    topMenuActions,
    newDealDefaultValues,
    newDealModalProps,
    workflowTypes,
    onResetDirtyTabs,
    resetTransition
  ])
}

export default useVehicleDetails
