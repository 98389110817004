import { useCallback, useEffect, useRef, useMemo, useContext } from 'react'
import { useForm, useModal, useRefUpdater, type FormValidation } from '@carfluent/common'
import debounce from 'lodash-es/debounce'
import get from 'lodash-es/get'
import set from 'lodash-es/set'
import isDeepEqual from 'lodash-es/isEqual'
import { toJS } from 'mobx'

import { type FCHook } from 'types'
import { type TransactionLineVendorDto } from 'api/types'
import { assertFieldName, containsTruthy, isTruthy, keys } from 'utils/general'
import { requiredNumber, required, dateField } from 'utils/validationPresets'
import SettingsCTX from 'store/settings'

import {
  type UseFloorplanProps,
  type UseFloorplanReturn,
  type FloorplanFormData,
  ErrTouchShortcuts,
  FloorplanFields
} from './types'

export const GET_DEFAULT_FLOORPLAN = (): FloorplanFormData => ({
  amount: 0,
  date: null,
  id: null,
  isFloorplanEnabled: false,
  vendor: null
})

const PARENT_UPDATE_DELAY = 300
const DEFAULT_FORM_DATA = GET_DEFAULT_FLOORPLAN()

const useFloorplanForm: FCHook<UseFloorplanProps, UseFloorplanReturn> = ({
  lastResetTS,
  lastTouchifyTS,
  values: parentValues,
  setFieldValue: _setFieldValue,
  setFormIsValid: setParentFormIsValid,
  onDeleteFloorplan: _onDeleteFloorplan
}) => {
  const deleteModalProps = useModal()
  const refValues = useRef(GET_DEFAULT_FLOORPLAN())
  const refLastReset = useRef(0)
  const refLastTouchify = useRef(0)

  const { accounting } = useContext(SettingsCTX)

  const form = useForm<FloorplanFormData, ErrTouchShortcuts>({
    baseValues: DEFAULT_FORM_DATA,
    validationRules,
    submitAction
  })

  const {
    isValid,
    onChange,
    resetForm,
    setFieldTouched,
    touched,
    validateForm,
    values
  } = form

  const checkboxValue = useMemo(() => ({
    id: 'isFloorplanEnabled',
    name: 'Add floorplan',
    checked: values.isFloorplanEnabled
  }), [values.isFloorplanEnabled])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onResetForm = useCallback((value = GET_DEFAULT_FLOORPLAN()) => {
    refValues.current = value
    resetForm({ ...value })
  }, [resetForm, setFieldTouched])

  const onSetParentValue = useCallback(
    debounce(_setFieldValue, PARENT_UPDATE_DELAY), [_setFieldValue])

  const onSetLocalValue = useCallback((id: string, value: unknown, markTouched = true) => {
    set(refValues.current, id, value)
    onChange(id, value)
    setFieldTouched(id, markTouched)
  }, [onChange, setFieldTouched])

  /**
   * Updates local state and then updates parent state in debounced manner.
   */
  const onSetFieldValue = useCallback((id: string, value: unknown) => {
    assertFieldName(id, DEFAULT_FORM_DATA)
    onSetLocalValue(id, value)
    void onSetParentValue(id, value)
  }, [onSetLocalValue, onSetParentValue])

  const onDeleteFloorplanConfirm = useCallback(async () => {
    await _onDeleteFloorplan()
    onResetForm()
    deleteModalProps.onCloseModal()
  }, [
    _onDeleteFloorplan,
    onResetForm,
    deleteModalProps.onCloseModal
  ])

  const onToggleFloorplan = useCallback(() => {
    const packCostId = refValues.current.id
    const isNextEnabled = !refValues.current.isFloorplanEnabled
    const isPackAlreadyExists = packCostId != null

    if (isPackAlreadyExists && !isNextEnabled) {
      deleteModalProps.onOpenModal()
      return
    }

    if (!isNextEnabled) {
      /**
       * Updates checkbox state just to hide section, before real state
       * update will arrive from parent.
       */
      onSetLocalValue(FloorplanFields.IsFloorplanEnabled, false)

      void _onDeleteFloorplan()
      return
    }

    onSetFieldValue(FloorplanFields.IsFloorplanEnabled, isNextEnabled)
  }, [
    _onDeleteFloorplan,
    onSetFieldValue,
    onSetLocalValue,
    deleteModalProps.onOpenModal
  ])

  const onAddVendor = useCallback(async (vendor: TransactionLineVendorDto) => {
    onSetFieldValue(FloorplanFields.Vendor, vendor)
  }, [onSetFieldValue])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Observes parent state (only) and updates local state if needed.
   */
  const refOnSetLocalValue = useRefUpdater(onSetLocalValue)
  useEffect(() => {
    for (const key of keys(parentValues)) {
      const parentValue = toJS(parentValues[key])
      const refValue = toJS(refValues.current[key])
      const parentValueId = get(parentValue, 'id')
      const isIdChanged = (parentValueId !== undefined) && (parentValueId !== get(refValue, 'id'))

      if (isIdChanged || !isDeepEqual(parentValue, refValue)) {
        refOnSetLocalValue.current(key, toJS(parentValue), false)
      }
    }
  }, [
    parentValues.amount,
    parentValues.id,
    parentValues.isFloorplanEnabled,
    parentValues.date,
    parentValues.vendor,
    parentValues.vendor?.id
  ])

  useEffect(() => {
    setParentFormIsValid(isValid || !containsTruthy(touched))
  }, [isValid, touched, setParentFormIsValid])

  useEffect(() => {
    if ((lastResetTS != null) && (lastResetTS > refLastReset.current)) {
      refLastReset.current = lastResetTS
      onResetForm(toJS(parentValues))
    }
  }, [lastResetTS, parentValues, onResetForm])

  useEffect(() => {
    if ((lastTouchifyTS != null) && (lastTouchifyTS > refLastTouchify.current)) {
      refLastTouchify.current = lastTouchifyTS
      validateForm(true)
    }
  }, [lastTouchifyTS, validateForm])

  // ========================================== //

  return {
    ...form,
    checkboxValue,
    minStartDate: accounting.accountingStartDate,
    deleteModalProps: {
      isOpen: deleteModalProps.isModalOpen,
      onClose: deleteModalProps.onCloseModal,
      onConfirm: onDeleteFloorplanConfirm
    },
    isLastLineErrorVisible: isTruthy(touched.amount) && isTruthy(form.errors.amount),
    onAddVendor,
    onToggleFloorplan,
    setFieldValue: onSetFieldValue
  }
}

export default useFloorplanForm

// ========================================== //

const submitAction = async (): Promise<void> => {}

const validationRules: FormValidation<FloorplanFormData> = {
  [FloorplanFields.Amount]: requiredNumber,
  [FloorplanFields.Date]: dateField('append'),
  [FloorplanFields.Vendor]: required
}
