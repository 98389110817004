import type { FC, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { useStyles } from './styles'

interface FooterPanelProps {
  children: ReactNode | ReactNode[]
}

const FooterActionsPanel: FC<FooterPanelProps> = ({ children }) => {
  const styles = useStyles()

  return (
    <div className={styles.actionBlock}>
      {children}
    </div>
  )
}

export default observer(FooterActionsPanel)
