import { TransactionTypeId, AccountCategoryId } from 'api/types'

const getAvailableAccountCategories = (
  transactionTypeId: number | null
): number[] | null => {
  switch (transactionTypeId) {
    case TransactionTypeId.PayBill: {
      return [AccountCategoryId.Banks, AccountCategoryId.BankChecking, AccountCategoryId.CreditCard]
    }

    case TransactionTypeId.Check: {
      return [AccountCategoryId.BankChecking]
    }

    default: {
      return null
    }
  }
}

export default getAvailableAccountCategories
