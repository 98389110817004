import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import type { IconProps } from 'types'

const IconSVGCreditApps: FC<IconProps> = ({
  width = 32,
  height = 32,
  className
}) => {
  return (
    <svg
      className={cnx(className, 'cf-icon')}
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width={width} height={height} rx='8' fill='#FAFAFA' />
      <g clipPath='url(#clip0_83675_211358)'>
        <path d='M9.33366 11.0013H7.66699V22.668C7.66699 23.5846 8.41699 24.3346 9.33366 24.3346H21.0003V22.668H9.33366V11.0013ZM22.667 7.66797H12.667C11.7503 7.66797 11.0003 8.41797 11.0003 9.33464V19.3346C11.0003 20.2513 11.7503 21.0013 12.667 21.0013H22.667C23.5837 21.0013 24.3337 20.2513 24.3337 19.3346V9.33464C24.3337 8.41797 23.5837 7.66797 22.667 7.66797ZM21.8337 15.168H13.5003V13.5013H21.8337V15.168ZM18.5003 18.5013H13.5003V16.8346H18.5003V18.5013ZM21.8337 11.8346H13.5003V10.168H21.8337V11.8346Z' fill='#101010' />
      </g>
      <defs>
        <clipPath id='clip0_83675_211358'>
          <rect width='20' height='20' fill='#FAFAFA' transform='translate(6 6)' />
        </clipPath>
      </defs>
    </svg>

  )
}

export default IconSVGCreditApps
