import { css } from '@emotion/css'

export const BASE_CLASS_NAME = css(`
  &.cf-table-row {
    cursor: pointer;
    .icon-arrow-up { display: none }
    .icon-arrow-down { display: block }
  }

  &.is-expanded {
    .icon-arrow-up { display: block }
    .icon-arrow-down { display: none }
    
    /**
     * Top row's (expander) cells
     */
    & > td > div {
      background: #F4F5F8;
      background-color: #F4F5F8;
      border-bottom: 0px solid transparent;
    }
  }
  
  /**
   * When row is "expanded" - both "expander" and "expandable" should have a black border on the left.
   */
  &.row-expandable > td:first-child > div,
  &.is-expanded > td:first-child > div,
  &.is-expanded:has(+ &.row-expandable:hover) > td:first-child > div,
  &.is-expanded + &.row-expandable > td:first-child > div {
    border-left: 4px solid #212121 !important;
    border-radius: 0px;
    border-top: none;
    border-bottom: none;
  }

  &.row-expandable {
    margin-top: 0px;
    border-top: none;

    & .row-expandable-form {
      position: relative;
      width: 100%;
      height: fit-content;
      min-height: fit-content;
      background-color: #F4F5F8;
      border-top: none;
      border-bottom: none;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      cursor: default;
      padding: 16px;
      padding-bottom: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      
      .cf-form-field .cf-input-container {
        background-color: #FFF;
      }
    }
    
    .bank-statement-matches {
      background-color: #E8EBEE;
      
      tr {
        border-top: 8px solid #E8EBEE;
      }
      
      tr:first-of-type {
        border-top: none;
      }
    }
    
    .deposit-matches {
      .cell-amount {
        padding-right: 55%;
      }
    }
  }

  .cf-table-cell {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding: 0px;
    padding-right: 0px;
    
    
    .cf-cell-wrapper.cell-amount {
      align-items: flex-start;
    }
  }

  .cf-table-cell:last-child {
    padding-right: 0px;
  }

  .cell-action {
    background-color: #FFF;
  }

  .cell-action-content {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #000;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    user-select: none;
    border-top: none;
    border-bottom: none;
  }
  
  .action-title {
    padding-right: 6px;
  }

  .form-fields,
  .form-fields-line {
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: flex-start;
  }

  .form-bottom-line {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .form-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 24px;

    .cf-button-content {
      color: #000;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
    }

    .btn-find-matches {
      width: 150px;
      background-color: #F4F5F8;
      
      &:hover {
        background-color: #F4F5F8;
      }

      .cf-button-start-adornment,
      .cf-button-end-adornment {
        display: none;
      }

      .cf-button-content {
        letter-spacing: 1px;
      }
    }
  }

  .banner-error {
    :after { display: none; }
    .cf-banner-message { margin-lefht: 8px; }
  }


  .categories-radio-group {
    gap: 32px;
    align-items: flex-start;

    .radio-item {
      padding: 0px;
      margin: 0px;
    }

    .radio-item > span {
      padding: 0px;
      padding-right: 8px;
    }
  }

  .cf-form-field {
    width: 249px;
  }

  .field-description {
    width: 515px;
  }

  .badge {
    line-height: 20px;
    margin: 0px;
  }
  
  .cf-section-loader {
    z-index: 3;
  }
  
  .btn-exclude.cf-button-root {
    text-decoration: none;
  }
`)

export const HOVER_CLASS_NAME = css(`
  &.cf-table-row-hover {
    background-color: #FFF;
  }

  &.cf-table-row-hover:hover > td > div {
    background-color: #FAFAFA;
  }

  &.cf-table-row > td:first-child > div {
    border-top: none;
    border-bottom: none;
  }


  /**
   * Hover on expander row show should also "highlight" expandable row,
   * and vice verse.
   */
  &.row-expandable:hover > td:first-child > div,
  &.cf-table-row-hover:hover > td:first-child > div,
  &.cf-table-row-hover:has(+ &.row-expandable:hover) > td:first-child > div,
  &.cf-table-row-hover:hover + &.row-expandable > td:first-child > div {
    border-left: 4px solid #212121 !important;
    border-radius: 0px;
  }
  
  &.row-expandable:hover > td > div {
    background-color: #F4F5F8;
  }
  
  &.cf-table-row-hover.is-expanded:hover > td > div {
    background-color: #F4F5F8;
  }
`)
