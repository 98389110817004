import { type FC, useEffect } from 'react'
import { useRouteError } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import CLASS_NAME from './styles'

const ErrorUI: FC = (props) => {
  const err = useRouteError()

  useEffect(() => {
    Sentry.captureException(err)
  }, [err])

  return (
    <div className={CLASS_NAME}>
      <div className='wrapper'>
        <div>
          <p className='title'>
            Whoops, something went wrong :(
          </p>

          <p className='text'>
            Try refreshing the page, or going back and attempting the action again.
            <br />Please, report a bug if this problem persists.
          </p>
        </div>
      </div>
    </div>
  )
}

export default ErrorUI
