import { FC, useCallback } from 'react'
import { UI } from '@carfluent/common'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import Content from './content'

import { useImportCSV } from './hook'
import { ImportCSVModalProps } from './types'
import { INVENTORY_MODAL_TITLE } from './constants'

const { Modal } = UI

const MODAL_MIN_WIDTH = 680

const ImportCSVModal: FC<ImportCSVModalProps> = (props) => {
  const {
    handleSubmit,
    loader,
    handleClose,
    disabledSubmit,
    ...contentProps
  } = useImportCSV(props)

  const renderFooter = useCallback(() => {
    return (
      <CancelSubmitActionsFooter
        isDisabled={disabledSubmit}
        isLoading={loader}
        onClose={handleClose}
        onSubmit={handleSubmit}
        submitTitle='IMPORT'
      />
    )
  }, [handleSubmit, handleClose, loader, disabledSubmit])

  return (
    <Modal
      isOpen={props.isOpen}
      minWidth={MODAL_MIN_WIDTH}
      onClose={handleClose}
      renderFooterActions={renderFooter}
      title={INVENTORY_MODAL_TITLE}
    >
      <Content {...contentProps} />
    </Modal>
  )
}

export default ImportCSVModal
