import { css } from '@emotion/css'

export default css(`
  margin-top: -16px;
  padding: 0 16px 16px 16px;

  .form-subtitle {
    margin: 16px 0;
    font-weight: 500;
    font-size: 16px;
    color: #101010;
  }

  .form {
    display: grid;
    grid-template-areas:
      'subject subject'
      'notes notes';
    row-gap: 24px;

    .notes-field { grid-area: notes; }

    .subject-field { grid-area: subject; }

    .lead-tasks-action-buttons {
      grid-area: buttons;
      line-height: 22px;
      width: max-content;
      border: 1px solid #C99B86;
      height: 26px;
      color: #000;

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
      }
    }
  }
`)
