export const ALLOWED_BYTES = 15728640
export const ALLOWED_TYPES = ['text/csv']
export const INVENTORY_MODAL_TITLE = 'Inventory import'
export const DEFAULT_HELPER_TEXT = 'Supported format: .csv, max 15 MB'
export const SUCCESS_IMPORT_FILE = 'Cars imported successfully'
export const IMPORT_CANCELLATION_KEY = 'cancel_csv_import'

export enum Errors {
  UploadFailed = 'Upload Failed. Please try again',
  FileSize = 'File size can\'t exceed 15MB',
  FileType = 'Supported format: .csv',
  TemplateDownloadFailed = 'Template download failed.'
}
