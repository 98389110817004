import { css } from '@emotion/css'

export default css(`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .selected-amount-of-forms {
    font-size: 14px;
    color: #00000061;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .actions-container {
    display: flex;

    button {
      :first-of-type {
        margin-right: 24px;
      }

      :last-of-type {
        margin-right: 0;
      }
    }
  }
`)
