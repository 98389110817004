import { type FocusEvent, type KeyboardEvent, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import capitalize from 'lodash-es/capitalize'
import { FormControl, FormHelperText } from '@material-ui/core'
import { cn } from '@carfluent/common'
import type { BaseFormField } from 'components/form/types'
import { useField } from 'components/form/hook'
import AddressAutocomplete, { AddressValue } from './AddressAutocomplete'
import { FullAddressParts } from 'types/address'
import { useStyles } from './styles'

export interface FormAddressPickerProps<
  FreeSolo extends boolean | undefined = undefined
> extends BaseFormField<AddressValue<FreeSolo>> {
  label?: string
  classes?: { control?: string }
  disabled?: boolean
  onBlur?: (evt: FocusEvent<HTMLDivElement>) => void
  onChange: (id: string, data: AddressValue<FreeSolo>) => void
  freeSolo?: FreeSolo
  /**
   * if freeSolo is true the input would work basicaly with a string value,
   * that is why we have onBlurChange.
   */
  onBlurChange?: (id: string, data: string) => void
  /**
   * if not provided simple id would be considered as id of the full address value.
   * It is needed if 'id' prop is for address part in freeSolo mode
   */
  fullAddressValueKey?: string
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void
}

const FormAddressPicker = <FreeSolo extends boolean | undefined = undefined>(
  props: FormAddressPickerProps<FreeSolo>
): JSX.Element => {
  const styles = useStyles()
  const { id, label, classes, disabled, freeSolo, onBlur, onChange, onBlurChange, onKeyPress, fullAddressValueKey } = props
  const { value, error, isErrorVisible } = useField<AddressValue<FreeSolo>>(props)
  const { value: fullAddressValue } = useField<FullAddressParts>({ id: fullAddressValueKey ?? '' })

  const handleChange = useCallback((_id: string, resolvedItem: AddressValue<FreeSolo>) => {
    onChange(fullAddressValueKey ?? _id, resolvedItem)
  }, [id, onChange, fullAddressValue])

  return (
    <FormControl fullWidth className={cn(classes?.control, 'cf-address-picker')}>
      <AddressAutocomplete
        id={id}
        value={value ?? null}
        fullAddressValue={fullAddressValue ?? null}
        label={label ?? capitalize(id)}
        freeSolo={freeSolo}
        disabled={disabled}
        onChange={handleChange}
        onBlur={onBlur}
        onBlurChange={onBlurChange}
        onKeyPress={onKeyPress}
        error={isErrorVisible}
      />

      {isErrorVisible && (
        <FormHelperText classes={{ root: styles.formHelperText }}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default observer(FormAddressPicker)
