import { css } from '@emotion/css'

interface WrapperStyles {
  lines: number | null
  defaultLines: number
  lineHeight: number
}

export const createUseStyles = ({
  lines,
  defaultLines,
  lineHeight
}: WrapperStyles): string => css(`
  .default-height {
    height: ${defaultLines * lineHeight}px;
  }
  
  .ellipsis-text-content {
    display: -webkit-box;
    -webkit-line-clamp: ${lines ?? 'initial'};
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .show-more-less-block {
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;

    button {
      font-size: 12px;
      letter-spacing: 0.4px;
      text-decoration: underline;
      text-underline-offset: 1px;
      padding-right: 0;
      padding-left: 0;
      background: none;
    }
  }
`)
