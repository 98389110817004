import type { MouseEventHandler, FC } from 'react'

export interface CheckedIconProps {
  fill?: string
  stroke?: string
  className?: string
  onClick?: MouseEventHandler<SVGSVGElement>
}

const CheckedIcon: FC<CheckedIconProps> = ({
  className,
  fill = '#fff'
}) => {
  return (
    <svg className={className} width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.00039 11.2016L1.80039 7.00156L0.400391 8.40156L6.00039 14.0016L18.0004 2.00156L16.6004 0.601562L6.00039 11.2016Z'
        fill={fill}
      />
    </svg>
  )
}

export default CheckedIcon
