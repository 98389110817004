import React from 'react'
import { Modal, type UseModalReturn, cnx } from '@carfluent/common'

import type { ImageDescriptor } from 'components/inventory/ImageItem'
import CLASS_NAME from './styles'

interface VideoModalProps extends UseModalReturn {
  file: ImageDescriptor | null
}

const MODAL_MIN_WIDTH = 1216

const VideoModal: React.FC<VideoModalProps> = ({ isModalOpen, onCloseModal, file }) => {
  return (
    <Modal
      className={cnx(CLASS_NAME, 'video-modal')}
      isOpen={isModalOpen}
      onClose={onCloseModal}
      minWidth={MODAL_MIN_WIDTH}
    >
      <div className='modal-content'>
        <video
          className='video-container'
          controls
          preload=''
          poster={file?.thumbnailUrl}
        >
          <source src={file?.videoUrl} type='video/mp4' />
        </video>
      </div>
    </Modal>
  )
}

export default VideoModal
