import { css } from '@emotion/css'

export default css(`  
  .cf-modal-content-scroll-wrapper {
    min-width: 100vw;
  }

  .cf-delete-button.cf-button-root.cf-button-variant-text {
    grid-column: 1 / 3;
    text-decoration: none;

    .cf-button-content {
      font-weight: 500;
    }
  }

  .cf-button-content {
    font-size: 14px;
  }

  .input-wrapper {
    margin-bottom: 24px;
  }

  .address-fields-component {
    .MuiFormLabel-root {
      color: rgba(33, 33, 33, 0.8);
    }
  }

  &.cf-modal-container {
    .cf-modal-footer {
      grid-gap: 16px;
    }
  }

`)
