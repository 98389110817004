import { type FC } from 'react'
import { Button, FormInput } from '@carfluent/common'

import type { DocumentFormPackDto } from 'api/types'
import TooltipedText from 'components/common/TooltipedText'
import type { FormProps } from 'pages/settings/ExactPack/hook/types'

import AddFormModal from '../AddFormModal'

import CLASS_NAME from './styles'

interface ExactPackFormProps {
  formPack: DocumentFormPackDto
  formProps: FormProps
}

const ExactPackForm: FC<ExactPackFormProps> = ({
  formProps,
  formPack
}) => {
  const {
    values,
    errors,
    onBlur,
    touched,
    onChange
  } = formProps

  return (
    <div className={CLASS_NAME}>
      <div className='header'>
        <div className='header-titles'>
          <h1><TooltipedText value={formPack.name} /></h1>

          {
            Boolean(formPack.description) &&
              <p>{formPack.description}</p>
          }
        </div>

        <div className='form-group button-group'>
          <Button
            variant='text'
            className='add-form-btn'
            onClick={formProps.onOpenModal}
          >
            ADD FORM
          </Button>
        </div>
      </div>

      <div className='form-grid'>
        <div className='form-group'>
          <FormInput
            id='name'
            label='Name'
            onBlur={onBlur}
            onChange={onChange}
            error={errors.name}
            value={values.name}
            touched={touched.name}
          />
        </div>

        <div className='form-group'>
          <FormInput
            onBlur={onBlur}
            id='description'
            label='Description'
            onChange={onChange}
            error={errors.description}
            value={values.description}
            touched={touched.description}
          />
        </div>
      </div>

      <AddFormModal
        isSubmitting={false}
        isOpen={formProps.isModalOpen}
        onClose={formProps.onCloseModal}
        onAddForms={formProps.onAddForms}
      />
    </div>
  )
}

export default ExactPackForm
