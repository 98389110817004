import { useEffect, useCallback, useRef, useState, useMemo } from 'react'
import pDebounce from 'p-debounce'
import axios from 'axios'

import { loadGoogleMapScript, addressParts, fullAddress } from 'utils/address'
import { FullAddressParts } from 'types/address'

export const GOOGLE_MAP_SCRIPT_BASE_URL = 'https://maps.googleapis.com/maps/api/js'

interface SearchOptions {
  input: string
  types?: string[]
  componentRestrictions?: google.maps.places.ComponentRestrictions
}

interface UsePlacesAutocompleteProps {
  apiKey: string
  debounce?: number
  types?: string[]
  language?: string
}

interface UsePlacesAutocompleteReturn {
  placesAutocompleteService: google.maps.places.AutocompleteService | null
  placePredictions: FullAddressParts[]
  getPlacePredictions: (opts: SearchOptions) => Promise<FullAddressParts[]>
  isPlacePredictionsLoading: boolean
}

type SearchPlacesDetailsFunc = (opts: SearchOptions) => Promise<FullAddressParts[]>

const getGeocodeUrl = (placeId: string, apiKey: string): string =>
  `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${apiKey}`

const client = axios.create()

const defaultCountry = 'us'
const defaultTypes = ['address']
const defaultRestrictions = { country: defaultCountry }

const geocodingCache: Record<string, google.maps.GeocoderResult | null> = {}

const usePlacesAutocomplete = ({
  apiKey,
  types = defaultTypes,
  debounce = 300,
  language = ''
}: UsePlacesAutocompleteProps): UsePlacesAutocompleteReturn => {
  const [placePredictions, setPlacePredictions] = useState<FullAddressParts[]>([])
  const [isPlacePredictionsLoading, setIsPlacePredictionsLoading] = useState(false)

  const placesAutocompleteService = useRef<google.maps.places.AutocompleteService | null>(null)

  const searchPlacesDetails: SearchPlacesDetailsFunc = useMemo(() => {
    return pDebounce(async (opts: SearchOptions) => {
      let result: Array<FullAddressParts | null> = []

      try {
        if (placesAutocompleteService.current != null && opts.input !== '') {
          const predictions = (await placesAutocompleteService.current.getPlacePredictions({
            componentRestrictions: defaultRestrictions,
            types,
            ...opts
          })).predictions ?? []

          const detailedResults: Array<google.maps.GeocoderResult | null> = await Promise.all(predictions.map(async p => {
            try {
              if (geocodingCache[p.place_id] == null) {
                geocodingCache[p.place_id] = (await client.get(getGeocodeUrl(p.place_id, apiKey))).data.results[0]
              }
              return geocodingCache[p.place_id]
            } catch (e) {
              return null
            }
          }))

          /**
           * sometimes google search returns duplicates in predictions
           * they can be filtered out when we receive details of each prediction
           */
          const resultSet = new Set<string>()

          result = detailedResults.reduce((acc: FullAddressParts[], curr) => {
            const { addressLong, address, city, zipCode, state, county } = addressParts(curr) ?? {}

            /**
             * county is optional that is why it is not checked
             */
            if (Boolean(city) && Boolean(zipCode) && Boolean(state) && Boolean(address)) {
              const addrParts = { address, addressLong, city, state, zipCode, county }
              const addressStr = fullAddress(addrParts)

              if (!resultSet.has(addressStr)) {
                acc.push(addrParts)
                resultSet.add(addressStr)
              }
            }

            return acc
          }, [])
        }
      } catch { /** silent error catching */ }

      return result as FullAddressParts[]
    }, debounce)
  }, [debounce, types, apiKey])

  const getPlacePredictions = useCallback(
    async (opts: SearchOptions): Promise<FullAddressParts[]> => {
      setIsPlacePredictionsLoading(true)
      const res = (await searchPlacesDetails(opts)) ?? []
      setIsPlacePredictionsLoading(false)
      setPlacePredictions(res)
      return res
    }, [searchPlacesDetails])

  useEffect(() => {
    const handleLoadScript = async (): Promise<void> => {
      const languageQueryParam = language !== '' ? `&language=${language}` : ''
      const googleMapsScriptUrl = `${GOOGLE_MAP_SCRIPT_BASE_URL}?key=${apiKey}&libraries=places${languageQueryParam}`

      try {
        await loadGoogleMapScript(GOOGLE_MAP_SCRIPT_BASE_URL, googleMapsScriptUrl)
        placesAutocompleteService.current = new google.maps.places.AutocompleteService()
      } catch {
        placesAutocompleteService.current = null
      }
    }

    if (apiKey !== '') {
      void handleLoadScript()
    }
  }, [apiKey, language])

  return {
    placesAutocompleteService: placesAutocompleteService.current,
    placePredictions,
    isPlacePredictionsLoading,
    getPlacePredictions
  }
}

export default usePlacesAutocomplete
