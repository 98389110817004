import { FC, useMemo } from 'react'

import ActionsMenu, { type ActionOptions } from 'components/common/ActionsMenu'

import CLASS_NAME from './styles'

interface ActionsButtonProps {
  className?: string
  onOpenSummaryModal: () => void
  onOpenClearMonthModal: () => void
  onOpenCopyMonthModal: () => void
}

const ActionsButton: FC<ActionsButtonProps> = ({
  onOpenSummaryModal,
  onOpenClearMonthModal,
  onOpenCopyMonthModal
}) => {
  const actionList: ActionOptions[] = useMemo(() => ([
    {
      title: 'Show summary',
      handleOnClick: onOpenSummaryModal
    },
    {
      title: 'Copy this month',
      handleOnClick: onOpenCopyMonthModal
    },
    {
      title: 'Clear this month',
      optionType: 'highlighted',
      handleOnClick: onOpenClearMonthModal
    }
  ]), [onOpenSummaryModal, onOpenCopyMonthModal, onOpenClearMonthModal])

  return (
    <div className={CLASS_NAME}>
      <ActionsMenu
        className='cf-work-schedule-actions-menu'
        actions={actionList}
      />
    </div>
  )
}

export default ActionsButton
