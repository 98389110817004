import { type FC } from 'react'

import { type UseReportGrossProfitReturn } from 'pages/reports/ReportGrossProfit/hook/types'

import { COLUMNS } from './columns'

export type GrossProfitTableProps = Pick<UseReportGrossProfitReturn, 'isLoading' | 'rows'>

const GrossProfitTable: FC<GrossProfitTableProps> = ({ isLoading, rows }) => (
  <>
    <table className='gross-profit-table'>
      <thead>
        <tr>
          {COLUMNS.map(col =>
            <th key={col.className} className={col.className}>
              {col.title}
            </th>)}
        </tr>
      </thead>

      <tbody>
        {isLoading
          ? <LoadingRows />
          : rows.map((item, i) => (
            <tr key={`${COLUMNS[4].data(item)}${i}`}>
              {COLUMNS.map(col => (
                <td key={col.className} className={col.className}>
                  {col.data(item)}
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>

    {rows.length === 0 && (
      <div className='no-data'>
        <p>No results found</p>
      </div>
    )}
  </>
)

export default GrossProfitTable

const EMPTY_ROWS_COUNT = 10

const LoadingRows: FC = () => (
  <>
    {new Array(EMPTY_ROWS_COUNT).fill(null).map((_, i) => (
      <tr key={i}>
        {COLUMNS.map(column => (
          <td key={column.className} className={column.className}>
            <div className='loading-row' />
          </td>
        ))}
      </tr>
    ))}
  </>
)
