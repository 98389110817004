import { css } from '@emotion/css'

const actionMenuOptionTypes = ['highlighted'] as const

export type ActionMenuOptionType = typeof actionMenuOptionTypes[number]

export default css(`
  display: flex;

  .cf-menu-button {
    width: fit-content;
    max-width: 160px;
    padding: 12px 24px;
    height: 40px;

    .MuiButton-label {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 1.25px;
      text-transform: uppercase;
    }

    &.Mui-disabled {
      svg {
        path {
          fill: rgba(0, 0, 0, 0.38);
        }
      }
    }

    svg { 
      margin-left: 12px;
    }
  }

  .cf-menu-popper {
    position: relative;
    z-index: 100;

    .MuiPaper-root {
      border-radius: 8px;
    }
  }

  .cf-menu-container {
    border-radius: 8px;
  }

  .cf-menu-item {
    min-width: 160px;
    position: relative;

    svg {
      margin-right: 8px;
    }
    
    &.${actionMenuOptionTypes[0]} {
      color: #B00020;
    }
  }
`)
