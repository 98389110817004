import { useCallback, useEffect, useState } from 'react'
import {
  useSensor, useSensors, useDroppable,
  DragStartEvent, DragEndEvent, UniqueIdentifier,
  PointerSensor, SensorDescriptor,
  SensorOptions, CollisionDetection, closestCenter,
  DropAnimation, defaultDropAnimationSideEffects
} from '@dnd-kit/core'
import { rectSortingStrategy, SortingStrategy } from '@dnd-kit/sortable'

import type { ImageDescriptor } from 'components/inventory/ImageItem'

export interface UseImageListProps {
  images: ImageDescriptor[]
  onReorderPhotos: (activeIdx: number, overIdx: number) => void
}

export interface UseImageListReturnType {
  dndContext: {
    onDragStart: (event: DragStartEvent) => void
    onDragEnd: (event: DragEndEvent) => void
    collisionDetection: CollisionDetection
    sensors: Array<SensorDescriptor<SensorOptions>>
  }
  sortingContext: {
    strategy: SortingStrategy
    items: Array<{ id: UniqueIdentifier }>
  }
  setNodeRef: (element: HTMLElement | null) => void
  draggedItem: ImageDescriptor | null
  dropAnimation: DropAnimation
}

const imgUrl = (img: ImageDescriptor): string => img.thumbnailUrl ?? img.originalUrl

const useImageList = (props: UseImageListProps): UseImageListReturnType => {
  const { images, onReorderPhotos } = props
  const [draggedItem, setDragged] = useState<ImageDescriptor | null>(null)

  const { setNodeRef } = useDroppable({ id: 'image-list' })
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 0.5 } // without this buttons' `onClick` will not work
  })
  const sensors = useSensors(pointerSensor)

  const dropAnimationConfig: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: { opacity: '0.5' }
      }
    })
  }

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const getIndex = (id: UniqueIdentifier): number => images.findIndex(item => imgUrl(item) === id)

  const handleDragStart = useCallback(({ active }: DragStartEvent) => {
    const nextDragged = images.find(item => imgUrl(item) === active.id)
    setDragged(nextDragged ?? null)
  }, [images])

  const handleDragEnd = useCallback(({ over }: DragEndEvent) => {
    setDragged(null)

    if ((over != null) && (draggedItem != null)) {
      const activeIdx = getIndex(imgUrl(draggedItem))
      const overIdx = getIndex(over.id)

      if (activeIdx !== overIdx) {
        onReorderPhotos(activeIdx, overIdx)
      }
    }
  }, [draggedItem, onReorderPhotos])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    document.body.style.cursor = draggedItem == null ? '' : 'grabbing'
  }, [draggedItem])

  // ========================================== //

  return {
    dndContext: {
      onDragStart: handleDragStart,
      onDragEnd: handleDragEnd,
      collisionDetection: closestCenter,
      sensors
    },
    sortingContext: {
      strategy: rectSortingStrategy,
      items: images.map(item => ({ id: imgUrl(item) }))
    },
    setNodeRef,
    draggedItem,
    dropAnimation: dropAnimationConfig
  }
}

export default useImageList
