import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'

import { WorkflowStep } from 'api/types'
import { fromDateToNow } from 'utils/parse_date'
import { formatWorkflowAccountingStateName, formatWorkflowStepNameForView } from 'utils/view_helper'
import TooltipedText from 'components/common/TooltipedText'

import CLASS_NAME from './styles'

export interface WorkflowStepData {
  startedAt: Date | null
  workflowId?: number | null
  stepName?: WorkflowStep | null
}

export interface WorkflowStepCellProps {
  getValue: () => WorkflowStepData
}

const WorkflowStepCell: FC<WorkflowStepCellProps> = ({ getValue }) => {
  const { startedAt, workflowId = null, stepName = null } = getValue()

  return (
    <CellWrapper className={CLASS_NAME}>
      <TooltipedText
        value={
          workflowId != null
            ? formatWorkflowAccountingStateName(workflowId)
            : formatWorkflowStepNameForView(stepName, true)
        }
      />

      {(startedAt != null) && (
        <TooltipedText
          className='cf-grey-text'
          value={fromDateToNow(startedAt)}
        />
      )}
    </CellWrapper>
  )
}

export default WorkflowStepCell
