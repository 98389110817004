import { css } from '@emotion/css'

export const MODAL_CLASSES = css(`
  &.cf-modal-container{
    .cf-modal-content-scroll-wrapper {
      .cf-modal-root {
        .cf-modal-title,
        .cf-modal-content {
          max-width: 1600px;
          padding: 0px 24px 90px 24px;
        }

        .cf-modal-title {
          padding: 40px 24px 0;
          margin-bottom: 32px;
          color: #101010;
        }

        .cf-modal-footer {
          padding: 12px 40px;
          grid-gap: 24px;

          .display-fi-wrapper {
            flex-grow: 1;
            display: flex;
            margin-left: 24px;
            align-items: center;

            button {
              font-size: 14px;
              margin-right: 24px;
              border: 1px solid #101010;
              
              .cf-button-content {
                color: #101010;
                font-weight: 500;
                line-height: 16px;
                text-align: center;
                letter-spacing: 1.25px;
              }
            }

            .cf-button-disabled {
              border-color: #00000030;

              .cf-button-content {
                color: #00000030;
              }
            }

            > span {
              font-size: 14px;
              line-height: 20px;
              color: #00000038;
              letter-spacing: 0.25px;
            }
          }
        }

        .cf-modal-content {
          min-height: calc(100vh - 64px);
        }
      }
    }
  }
`)

export default css(`
  .coverage-checkboxes-list {
    margin-bottom: 24px;
  }
  
  .warranty-coverage {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 32px;
    grid-column: 1;
  }
  
  .warranty-coverage {
    grid-row-gap: 16px;
    margin-bottom: 24px;
  }

  .warranty-item-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 16px;
  }
`)
