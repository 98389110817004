import { type FC, useState, useMemo, memo } from 'react'
import { cnx } from '@carfluent/common'

import WaveFormPlayer from 'components/common/WaveFormPlayer'
import { getHtmlString } from 'utils/html'

import getDate from './utils'
import MessageThumbnail from './components/MessageThumbnail'
import { createUseStyles } from './styles'

export interface MessageProps {
  date?: string
  id?: number | string
  audioUrls?: string[]
  imageUrls?: string[]
  isReceived?: boolean
  isSending?: boolean
  message: string
  name: string | null
  onOpenGallery?: (images: string[], id: number) => void
}

const Message: FC<MessageProps> = ({
  date,
  audioUrls = [],
  imageUrls = [],
  isReceived = false,
  isSending = false,
  name,
  message,
  onOpenGallery: _onOpenGallery
}) => {
  /**
   * all images are known from the very start and they are loading by default
   */
  const [imagesLoading, setImagesLoading] = useState(
    imageUrls.reduce<Record<string, boolean>>((acc, curr) => {
      acc[curr] = true
      return acc
    }, {})
  )

  const rootCls = useMemo(() => {
    return createUseStyles({ isReceived, imagesNum: imageUrls.length })
  }, [isReceived, imageUrls.length])

  const htmlData = getHtmlString(message)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onLoadImage = (imageUrl: string): void => {
    setImagesLoading(data => ({ ...data, [imageUrl]: false }))
  }

  const onOpenGallery = (id: number): void => {
    if (isSending) {
      return
    }

    _onOpenGallery?.(imageUrls, id)
  }

  // ========================================== //

  return (
    <div className={rootCls}>
      <div className='message-content'>
        <div className='message-header'>
          <span className='message-name'>{name}</span>

          {(date != null) && (
            <span className='message-date'>{getDate(date)}</span>
          )}
        </div>

        <div className={cnx('images-section', (imageUrls.length > 0) && 'is-visible')}>
          {imageUrls.map((url, index) => (
            <MessageThumbnail
              key={url}
              imageUrl={url}
              isLoading={imagesLoading[url]}
              isSending={isSending}
              onLoad={onLoadImage}
              onOpenGallery={() => { onOpenGallery(index) }}
            />
          ))}
        </div>

        <p className={cnx('message', isSending ? 'message-sending' : '')}>
          <span dangerouslySetInnerHTML={{ __html: htmlData }} />

          {isSending && (
            <span className='sending-note'>Sending...</span>
          )}
        </p>

        {audioUrls.map((url) => (
          <WaveFormPlayer key={url} fileUrl={url} />
        ))}
      </div>
    </div>
  )
}

export default memo(Message)
