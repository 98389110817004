import { type FC, useMemo } from 'react'
import { Banner, Loader } from '@carfluent/common'

import Table from 'components/common/Table'

import type { TransactionsTableProps } from './types'
import getColumnDefinitions from './columns'
import CLASS_NAME from './styles'

const TransactionsTable: FC<TransactionsTableProps> = ({
  data,
  dataTestId,
  disabled = false,
  entityActions,
  error,
  hasBankingLines,
  isLoading,
  getAccounts,
  getEntities,
  getControls,
  getWidths,
  onAddRow,
  onChange,
  onRemoveRow,
  transactionTypeId
}) => {
  const canAddRow = !disabled && !isLoading && !hasBankingLines

  const columns = useMemo(() => {
    return getColumnDefinitions({
      disabled,
      entityActions,
      getAccounts,
      getEntities,
      getControls,
      getWidths,
      hasBankingLines,
      onChange,
      onRemoveRow,
      transactionTypeId,
      styles: 'text-style'
    })
  }, [
    entityActions, getAccounts, getEntities,
    getControls, onChange, onRemoveRow,
    getWidths, disabled, transactionTypeId,
    hasBankingLines
  ])

  // ========================================== //

  return (
    <>
      <Banner message={error} isOpen={error != null} type='error' />

      <div className='table-container'>
        {isLoading && (
          <div className='cf-section-loader'>
            <Loader size='large' color='inherit' />
          </div>
        )}

        <div className={CLASS_NAME} data-test-id={dataTestId}>
          <Table
            columns={columns}
            data={data}
            onAddRow={canAddRow ? onAddRow : undefined}
          />
        </div>
      </div>
    </>
  )
}

export default TransactionsTable
