import { useCallback, useMemo } from 'react'
import { isOk, useForm } from '@carfluent/common'

import type { FCHook } from 'types'
import useEffectOnce from 'hooks/useEffectOnce'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { TaskMode, TaskType } from 'pages/crm/LeadDetailsView/hook/types'

import type { CompleteTaskFormData, ErrTouchShortcuts, UseCompleteTaskFormProps, UseCompleteTaskFormReturn } from './types'
import {
  FieldIds,
  Messages,
  getAppointmentDefaultFormData,
  getCallDefaultFormData,
  getReminderDefaultFormData
} from './constants'
import { completeAppointmentFormValidation, completeFormValidation } from './validator'

const useCompleteForm: FCHook<UseCompleteTaskFormProps, UseCompleteTaskFormReturn> = ({
  subject,
  taskType,
  onCloseTask: _onClose,
  taskId,
  taskMode,
  reminderSubjects,
  onSubmit: _onSubmit
}) => {
  const { showSuccess } = useCustomSnackbar()

  const defaultValues = useMemo(() => {
    if (taskType === TaskType.Appointment) {
      return getAppointmentDefaultFormData()
    } else if (taskType === TaskType['Follow up call']) {
      return getCallDefaultFormData()
    }

    return getReminderDefaultFormData()
  }, [taskType])

  const validationRules = useMemo(() => {
    if (taskType === TaskType.Appointment) {
      return completeAppointmentFormValidation
    } else if (taskType === TaskType['Follow up call'] || taskType === TaskType['Credit app received']) {
      return completeFormValidation
    }
    return {}
  }, [taskType])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const submitAction = useCallback(async (values: CompleteTaskFormData): Promise<void> => {
    await _onSubmit(values, taskId)
  }, [taskId, _onSubmit])

  const onActionResult = useCallback((res) => {
    _onClose?.(taskId, taskMode as TaskMode)
    if (isOk(res)) {
      showSuccess(Messages.SuccessTaskComplete)
    }
  }, [_onClose, taskId, taskMode, showSuccess])

  const form = useForm<CompleteTaskFormData, ErrTouchShortcuts>({
    baseValues: defaultValues,
    onActionResult,
    submitAction,
    validationRules
  })

  const {
    isSubmitting,
    values,
    errors,
    touched,
    onBlur,
    onChange,
    onSubmit
  } = form

  const onCloseTask = useCallback(() => {
    _onClose(null, taskMode as TaskMode)
  }, [_onClose, taskMode])

  const onChangeSelectedCheckbox = useCallback((id: string, value: boolean): void => {
    if (value) {
      const result = [...values.taskCompleteSubOptionIds, { id, value }]
      onChange(FieldIds.TaskCompleteSubOptionIds, result)

      return
    }

    const result = values.taskCompleteSubOptionIds.filter(item => item.id !== id)
    onChange(FieldIds.TaskCompleteSubOptionIds, result)
  }, [onChange, values.taskCompleteSubOptionIds])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffectOnce(() => {
    if (values.subject == null) {
      onChange(FieldIds.Subject, reminderSubjects.find(r => r.name === subject))
    }
  }, [values.subject, subject, onChange])

  // ========================================== //
  return {
    onCloseTask,
    onChangeSelectedCheckbox,
    taskMode,
    subject,
    reminderSubjects,
    isSubmitting,
    values,
    errors,
    touched,
    onBlur,
    onChange,
    onSubmit
  }
}

export default useCompleteForm
