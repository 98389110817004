import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { UI, useRefUpdater, type Preset, serializers as S } from '@carfluent/common'
import mergeWith from 'lodash-es/mergeWith'

import type {
  AccountListItem,
  OpenBalancesListItem,
  OpenBalancesListPayload,
  PaginatedResult,
  ListPayload,
  EntityListItem
} from 'api/types'

import AccountingApiProvider from 'api/accounting.api'
import useTableApi from 'hooks/useTableApi'

import {
  OpenBalancesFilters,
  OpenBalanceRow,
  UseOpenBalancesTableProps,
  UseOpenBalancesTableReturn,
  FiltersChangePayload
} from './types'
import type { FiltersFormData } from 'types/filters'

import {
  Messages,
  DEFAULT_SELECTED_ROWS,
  DEFAULT_SORTING,
  GET_DEFAULT_FILTERS,
  GET_DEFAULT_PRESETS,
  RESET_COMMAND_BOILERPLATE,
  SECRET_RECONCILIATION_ID
} from './constants'

import getColumnDefinitions from './columns'
import { parseOpenBalances } from './parser'
import serializeFilters from './serializer'
import { serializeDateFilters } from 'utils/accounting'
import { CALENDAR_MIN_MAX_RANGE } from 'constants/constants'

const DEFAULT_PRESETS = GET_DEFAULT_PRESETS()
const INIT_PRESET = DEFAULT_PRESETS[1]

const useOpenBalancesTable = ({
  filters: controlledFilters,
  // id, // useful for debug
  includeStatistics = false,
  isPayables = false,
  onChangeFilters,
  onLoadData,
  onOpenTransaction,
  onToggleRowCheckbox: _onToggleRowCheckbox,
  resetCommand = RESET_COMMAND_BOILERPLATE,
  selectedRowsIds = DEFAULT_SELECTED_ROWS
}: UseOpenBalancesTableProps): UseOpenBalancesTableReturn => {
  const popperRef = useRef<HTMLDivElement>(null)
  const [lastCheckRerenderTS, setCheckRerenderTS] = useState(Date.now())
  const [filterPresets, setFilterPresets] = useState(controlledFilters?.presets ?? DEFAULT_PRESETS)
  const [activeDatePreset, setDatePreset] = useState<Preset | null>(controlledFilters?.activeDatePreset ?? INIT_PRESET)

  const refIsInitialized = useRef(false)
  const refDatePreset = useRef(activeDatePreset)
  const refLastFiltersPayload = useRef<FiltersChangePayload | null>(null)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const refIsPayables = useRefUpdater(isPayables)
  const refIncludeStatistics = useRefUpdater(includeStatistics)
  const refOnLoadData = useRefUpdater(onLoadData)
  const getOpenBalances = useCallback(async (
    data: OpenBalancesListPayload
  ): Promise<PaginatedResult<OpenBalancesListItem>> => {
    const includeStatistics = refIncludeStatistics.current
    const allTillToday = filterPresets[1]
    const serializedDate = serializeDateFilters({ from: allTillToday?.startDate ?? null, to: allTillToday?.endDate ?? null }, S.serializeDate)

    const payload = {
      ...data,
      date: data.date == null ? serializedDate : data.date,
      includeStatistics
    }
    const response = await AccountingApiProvider.getOpenBalances(payload, refIsPayables.current)
    refIsInitialized.current = true
    refOnLoadData.current?.(response, payload)
    setTimeout(() => { setCheckRerenderTS(Date.now()) }, 100)

    return {
      ...response,
      count: response.items.length
    }
  }, [filterPresets, refIncludeStatistics, refIsPayables, refOnLoadData])

  const {
    emptyTableMessage,
    getRows,
    isLoading: isTableLoading,
    filters: appliedFilters,
    rows: loadedRows,
    search,
    setCellValue,
    setFilters,
    setSorting,
    setSearch,
    sorting,
    startLoader,
    onSearch
  } = useTableApi<OpenBalancesListItem, OpenBalancesFilters, OpenBalanceRow>({
    emptyTableMessage: Messages.EmptyState,
    defaultFilters: mergeWith(GET_DEFAULT_FILTERS(), { ...controlledFilters?.appliedFilters }, mergeCustomizer),
    defaultSorting: DEFAULT_SORTING,
    getList: getOpenBalances,
    nothingFoundMessage: Messages.NothingFound,
    parseListData: parseOpenBalances,
    serializeFilters,
    shouldRunOnCall: false
  })

  const onToggleRowCheckbox = useCallback((rowIdx: number, columnId: string, nextValue: unknown) => {
    const row = getRows()[rowIdx]

    if (row != null) {
      setCellValue(rowIdx, columnId, nextValue)
      _onToggleRowCheckbox?.(rowIdx, Boolean(nextValue), row)
    }
  }, [_onToggleRowCheckbox, setCellValue, getRows])

  /**
   * AZ-NOTE: always check deps of `onPresetChange`.
   * This handler is used in `reset` command effect, so it should NOT
   * trigger any unexpected executions of this effect.
   */
  const onPresetChange = useCallback((value: Preset | null) => {
    refDatePreset.current = value
    setDatePreset(value)
  }, [])

  const onFilterChange = useCallback(async (appliedFilters: Partial<FiltersFormData>) => {
    const payload = {
      activeDatePreset: refDatePreset.current,
      appliedFilters,
      presets: filterPresets
    }

    refLastFiltersPayload.current = payload
    onChangeFilters?.(payload)

    await setFilters(appliedFilters)
  }, [
    setFilters,
    onChangeFilters,
    filterPresets
  ])

  /**
   * Accounts dropdown props
   */

  const getAccounts = useCallback(async (payload: ListPayload): Promise<PaginatedResult<AccountListItem>> => {
    try {
      return await AccountingApiProvider.getAccounts(payload)
    } catch (err) {
      return { items: [], count: 0 }
    }
  }, [])

  /**
   * Entities dropdown props
   */

  const getEntities = useCallback(async (payload: ListPayload): Promise<PaginatedResult<EntityListItem>> => {
    try {
      return await AccountingApiProvider.getEntities(payload)
    } catch (err) {
      return { items: [], count: 0 }
    }
  }, [])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //
  const refOnFilterChange = useRefUpdater(onFilterChange)
  const refControlledFilters = useRefUpdater(controlledFilters)

  useEffect(() => {
    refIsInitialized.current = false

    const { minDate, maxDate } = CALENDAR_MIN_MAX_RANGE
    const presets = UI.getDefaultDateRangePresets(minDate, maxDate)
    const allTillToday = presets[1]
    const controlledActiveDatePreset = refControlledFilters.current?.activeDatePreset

    const activeDatePreset = controlledActiveDatePreset !== undefined
      ? controlledActiveDatePreset
      : allTillToday

    setFilterPresets(presets)
    onPresetChange(activeDatePreset)

    const appliedFilters = mergeWith(
      {
        ...GET_DEFAULT_FILTERS(),
        date: { from: allTillToday.startDate, to: allTillToday.endDate }
      },
      { ...refControlledFilters.current?.appliedFilters },
      mergeCustomizer
    )

    void refOnFilterChange.current(appliedFilters)
  }, [
    resetCommand.triggers,
    startLoader,
    onPresetChange,
    isPayables
  ])

  useEffect(() => {
    const rows = getRows()

    for (let idx = 0; idx < rows.length; idx++) {
      const row = rows[idx]
      const shouldBeChecked = selectedRowsIds.has(row.reconciliationId ?? SECRET_RECONCILIATION_ID)

      setCellValue(idx, 'checked', shouldBeChecked)
    }
  }, [
    getRows,
    lastCheckRerenderTS,
    selectedRowsIds,
    setCellValue
  ])

  /**
   * Updates internal filters state by filters from props
   */
  const refSetFilters = useRefUpdater(setFilters)
  useEffect(() => {
    if (!refIsInitialized.current) {
      return
    }

    /**
     * Prevents forever loop
     */
    if (refLastFiltersPayload.current === controlledFilters) {
      return
    }

    const nextFilters = mergeWith(
      GET_DEFAULT_FILTERS(),
      {
        ...controlledFilters?.appliedFilters
      },
      mergeCustomizer
    )

    void refSetFilters.current(nextFilters)
  }, [controlledFilters?.appliedFilters])

  useEffect(() => {
    if (!refIsInitialized.current) {
      return
    }

    if (refLastFiltersPayload.current === controlledFilters) {
      return
    }

    onPresetChange(controlledFilters?.activeDatePreset ?? null)
  }, [controlledFilters?.activeDatePreset, onPresetChange])

  // ========================================== //

  const filtersProps = {
    activeDatePreset,
    popperRef,
    appliedFilters,
    initPreset: INIT_PRESET,
    presets: filterPresets,
    onFilterChange,
    onPresetChange
  }

  const columns = useMemo(() => getColumnDefinitions({
    onOpenTransaction,
    onToggleRowCheckbox
  }), [onOpenTransaction, onToggleRowCheckbox])

  return {
    emptyTableMessage,
    columns,
    isSearchLoading: false,
    isTableLoading,
    filtersProps,
    getAccounts,
    getEntities,
    onSearchChange: setSearch,
    onSortingChange: setSorting,
    rows: loadedRows,
    search,
    sorting,
    onSearch
  }
}

export default useOpenBalancesTable

// ========================================== //

const mergeCustomizer = (_objValue: any, srcValue: any): any => {
  if (Array.isArray(srcValue)) {
    return srcValue
  }
}
