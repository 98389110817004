import { css } from '@emotion/css'

export default css(`
  @media screen and (max-width: 1380px) {
    &.cf-modal-container .cf-modal-content-scroll-wrapper .cf-modal-root {
      .cf-modal-title {
        padding-left: 24px;
        padding-right: 24px;
      }

      .cf-modal-content {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
`)
