import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { getEnhancedResultHandler, useForm } from '@carfluent/common'

import type { FCHook } from 'types'
import { ViewDealerRoles } from 'api/types'
import mapToCheckboxes from 'utils/mapToCheckboxes'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import type {
  UseDealerAccountDetailsFormProps,
  UseDealerAccountDetailsFormReturn,
  UpdateDealerAccountFormData
} from './types'
import {
  FieldIds,
  SuccessDealerAccountUpdate,
  getDefaultFormData
} from './constants'
import serializeData from './serializer'
import validationRules from './validator'

const DEFAULT_FORM_DATA = getDefaultFormData()

const useDealerAccountDetailsForm: FCHook<UseDealerAccountDetailsFormProps, UseDealerAccountDetailsFormReturn> = ({
  isOpen,
  dealerAccount,
  dealers,
  onSubmit: _onSubmit,
  onDeleteDealerAccount: _onDeleteDealerAccount,
  onClose
}) => {
  const { id: dealerAccountId } = dealerAccount ?? { id: -1 }

  const { showAlert } = useCustomSnackbar()

  const [isLoading, setLoading] = useState(false)
  const [_selectedDealers, setSelectedDealers] = useState<number[]>([])

  const selectedDealers = mapToCheckboxes(dealers ?? [], _selectedDealers)

  // ========================================== //
  //                  HANDLERS                  //
  // ========================================== //

  const submitAction = useCallback(async (values: UpdateDealerAccountFormData) => {
    try {
      setLoading(true)
      const data = serializeData(values)

      await _onSubmit(dealerAccountId, data)
    } catch {
      showAlert(SuccessDealerAccountUpdate)
    } finally {
      setLoading(false)
    }
  }, [dealerAccountId, showAlert, _onSubmit])

  const onDeleteDealerAccount = useCallback(async () => {
    await _onDeleteDealerAccount(dealerAccountId)
  }, [dealerAccountId, _onDeleteDealerAccount])

  const onActionResult = useMemo(() => {
    return getEnhancedResultHandler<UpdateDealerAccountFormData>(onClose, showAlert)
  }, [onClose, showAlert])

  const form = useForm<UpdateDealerAccountFormData>({
    baseValues: DEFAULT_FORM_DATA,
    validationRules,
    onActionResult,
    submitAction
  })

  const {
    values, errors, touched,
    resetForm, onBlur, onChange, onSubmit
  } = form

  const onDealerSelect = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    const id = Number(name)
    const nextValue = checked
      ? [..._selectedDealers, id]
      : _selectedDealers.filter(item => item !== id)

    setSelectedDealers(nextValue)
    onChange(FieldIds.DealerIds, nextValue)
  }, [_selectedDealers, onChange])

  const onRoleChange = useCallback((_, value: ViewDealerRoles | null) => {
    onChange(FieldIds.Role, value)
    if (Number(value) === ViewDealerRoles.DealerAdmin) {
      setSelectedDealers(dealers.map(v => v.id))
      onChange(FieldIds.DealerIds, dealers.map(v => v.id))
    }
  }, [dealers, onChange])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if ((dealerAccount == null) || !isOpen) {
      return
    }

    setSelectedDealers(dealerAccount.dealerIds ?? [])
    resetForm({
      dealerFirstName: dealerAccount.dealerFirstName,
      dealerLastName: dealerAccount.dealerLastName,
      roleId: dealerAccount.roleId,
      dealerIds: dealerAccount.dealerIds,
      phoneNumber: dealerAccount.phoneNumber ?? null
    })
  }, [dealerAccountId, isOpen, dealerAccount, resetForm])

  // ========================================== //

  return {
    isOpen,
    isLoading,
    dealers,
    selectedDealers,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onDealerSelect,
    onRoleChange,
    onSubmit,
    onDeleteDealerAccount,
    onClose
  }
}

export default useDealerAccountDetailsForm
