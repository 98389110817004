import {
  Routes,
  CRMSubRoutes,
  CRMSettingsSubRoutes,
  SettingsSubRoutes
} from 'constants/route_helper'

export const fullTasksUrl = `${Routes.CRM}/${CRMSubRoutes.Tasks}`
export const fullEmailTemplatesUrl = `${Routes.CRMSettings}/${CRMSettingsSubRoutes.EmailTemplates}`
export const fullMessageTemplatesUrl = `${Routes.CRMSettings}/${CRMSettingsSubRoutes.MessageTemplates}`
export const fullMarketingCampaignsUrl = `${Routes.CRM}/${CRMSubRoutes.MarketingCampaigns}`
export const fullFormPacksUrl = `${Routes.Settings}/${SettingsSubRoutes.FormPacks}`
export const fullLienholderListUrl = `${Routes.Settings}/${SettingsSubRoutes.LienholderList}`

export const MIN_RECON_DETAILS_WIDTH = 920 // 1024 - sidenav (64) - 2 * padding (16) - 8 (to remove unnecessary scroll bar)
