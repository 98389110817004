import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import type { BlockRendererProps } from 'components/wysiwyg/types'
import { VIDEO_BLOCK_START, VIDEO_BLOCK_END, type VideoBlockData } from 'utils/wysiwyg'
import closeIcon from 'assets/close_black.svg'

import CLASS_NAME from './styles'

export const VIDEO_CLASS_CONTAINER_NAME = 'insert-video-container'
export const VIDEO_LINK_CLASS_CONTAINER_NAME = 'insert-video-link'

const UploadVideoLink: FC<BlockRendererProps> = ({
  decoratedText = '',
  contentState,
  entityKey,
  onRemove: _onRemove,
  isRemovable = true,
  blockKey
}) => {
  let data: VideoBlockData | null

  /**
   * The block's data can be extracted either from the decorated text, which is basically a serialied JSON,
   * or from metadata of the related Entity.
   */

  /**
   * Step 1: trying to get block data from the decorated text.
   */
  try {
    data = JSON.parse(decoratedText.replace(VIDEO_BLOCK_START, '').replace(VIDEO_BLOCK_END, ''))
  } catch (err) {
    data = null
  }

  /**
   * Step 2: trying to get block data from the entity metadata.
   */
  if ((data == null) && (contentState != null) && (entityKey != null)) {
    data = contentState.getEntity(entityKey).getData()
  }

  if (data == null) {
    return null
  }

  const { posterUrl, videoUrl } = data

  const onRemove = (): void => {
    if (!isRemovable || contentState == null || blockKey == null) {
      return
    }

    _onRemove?.(blockKey)
  }

  // fix for open link in editor because can click on the link from the editor
  const openLink = (): void => {
    window.open(videoUrl, '_blank')
  }

  return (
    <div
      className={cnx(CLASS_NAME, VIDEO_CLASS_CONTAINER_NAME)}
      data-uploaded-video-block
    >
      <a
        href={videoUrl ?? '#'}
        className={VIDEO_LINK_CLASS_CONTAINER_NAME}
        data-uploaded-video-link
        rel='noreferrer'
        target='_blank'
        onClick={openLink}
        style={{
          display: 'block',
          cursor: 'pointer',
          width: '385px',
          height: '223px',
          textDecoration: 'none',
          backgroundImage: `url(${posterUrl})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover'
        }}
      >
        <img
          src={`${process.env.REACT_APP_FILES ?? ''}/api/v1/static/common/images/email/play_circle_outline.png`}
          alt=''
          style={{
            width: '100%',
            height: '100%',
            textAlign: 'center',
            objectFit: 'none',
            objectPosition: 'center center'
          }}
        />
      </a>
      {
        _onRemove != null && isRemovable && (
          <img
            style={{
              marginLeft: 8,
              cursor: 'pointer',
              position: 'absolute',
              top: '0',
              right: '-15px'
            }}
            onClick={onRemove}
            src={closeIcon}
            alt='close_icon'
          />
        )
      }
    </div>
  )
}

export default UploadVideoLink
