export enum FileType {
  DriverLicense = 'DriverLicense',
  Paperwork = 'Paperwork',
  Insurance = 'Insurance',
  Other = 'Other',
  NotarizedPaperwork = 'NotarizedPaperwork'
}

export enum DocumentsFolderName {
  DriverLicense = 'DriverLicense',
  Insurance = 'Insurance',
  Other = 'Other'
}

export interface UploadIteratorValue {
  uploaded: UploadedAssetWithThumbnailDto[]
  original: File[]
  startIdx: number
}

export interface UploadIteratorResult {
  value: UploadIteratorValue
  done: boolean
}

export interface UploadedAssetDto {
  fileUrl: string
}

export interface UploadedAssetWithThumbnailDto {
  id?: number
  originalSizeKb: number
  originalUrl: string
  thumbnailUrl?: string
  fileExtension?: string
  contentType?: string
  fileSize?: number
}

export interface UploadedAttachmentDto extends UploadedAssetDto {
  fileName: string
}

export interface UploadedVehicleDocumentDto {
  id: number
  originalFileName: string
  fileTypeId: string
}
