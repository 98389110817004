import { UI } from '@carfluent/common'
import fileNames from 'utils/reports/fileNames'

enum TitleStage {
  NotReceived = 1,
  Received = 2,
  TitleReceived = 3
}

enum RegistrationStage {
  PendingRegistration = 1,
  SentToDMVOrAgency = 2,
  RegistrationReceived = 3
}

export const REPORT_FILE_NAMES = fileNames('TitleReport')
export const DEFAULT_SORTING: UI.SortingInfo = { id: 'make', order: 'asc' }
export const DEFAULT_FILTERS = {
  titleStageIds: [TitleStage.NotReceived, TitleStage.Received],
  registrationStageFilter: {
    noRegistrationStage: false,
    registrationStageIds: [RegistrationStage.PendingRegistration, RegistrationStage.RegistrationReceived]
  }
}

export const API_CALL_DELAY_SEARCH = 3000

export enum Messages {
  emptyTableState = 'No results found',
  nothingFoundState = 'No results found'
}

export const GROUP_COL_SPAN = 3
