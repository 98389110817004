import { useCallback, useState, useRef, useEffect } from 'react'
import { useForm, isOk, useLoader, touchify } from '@carfluent/common'

import CRMApiProvider from 'api/crm.api'
import NotificationsAPIProvider from 'api/notifications.api'
import IdentityApiProvider from 'api/identity.api'
import { type DictionaryItem, type TaskTypeFormOptions } from 'api/types'
import { userRolesWithCRMPermission, ONE_LOAD_PAGE_SIZE } from 'constants/constants'
import parseUsers from 'utils/parseUsers'
import useAsyncEffect from 'hooks/useAsyncEffect'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { TaskType } from 'pages/crm/LeadDetailsView/hook/types'

import {
  FieldIds,
  DEFAULT_FORM_VALUE,
  getDefaultAssigneeOptions,
  UNASSIGNED_USER,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  SUCCESS_UPDATED_MESSAGE,
  PRIORITY_TASK_MEDIUM_ID
} from './constants'
import { serializeData } from './serializer'
import validationRules, { type FormValues } from './validator'
import { parseDataToEdit } from './parser'
import {
  type UseAddEditRuleProps,
  type UseAddEditRuleReturn,
  ErrTouchShortcuts
} from './types'

export const useAddEditRule = ({
  leadStatus,
  formData,
  onCloseModal,
  onSubmit: _onSubmit,
  onDelete
}: UseAddEditRuleProps): UseAddEditRuleReturn => {
  const isEdit = formData != null
  const [taskPriorities, setTaskPriorities] = useState<DictionaryItem[]>([])
  const [reminderSubjects, setReminderSubjects] = useState<DictionaryItem[]>([])
  const [taskTypes, setTaskTypes] = useState<TaskTypeFormOptions[]>([])
  const [taskAssigneeTypes, setTaskAssigneeTypes] = useState<DictionaryItem[]>([])
  const [users, setUsers] = useState<DictionaryItem[]>([])
  const [emailTemplates, setEmailTemplates] = useState<DictionaryItem[]>([])
  const [messageTemplates, setMessageTemplates] = useState<DictionaryItem[]>([])
  const [timeZoneDisplayName, setTimeZoneDisplayName] = useState<string | null>(null)
  const formInited = useRef<boolean>(false)
  const formDataRef = useRef<FormValues>(DEFAULT_FORM_VALUE)
  const { showAlert } = useCustomSnackbar()

  const {
    stopLoader,
    startLoader,
    isLoading
  } = useLoader()

  const submitAction = useCallback(async (values) => {
    startLoader()
    const data = serializeData(values, leadStatus?.id ?? null, taskAssigneeTypes)

    if (formData != null) {
      const rule = await CRMApiProvider.updateEvent({ ...formData, ...data })
      _onSubmit(rule, 'update')
    } else {
      const rule = await CRMApiProvider.createEvent(data)
      _onSubmit(rule, 'create')
    }
  }, [startLoader, leadStatus?.id, formData, _onSubmit])

  const onActionResult = useCallback((res, resetForm): void => {
    stopLoader()

    if (isOk(res)) {
      resetForm(formDataRef.current)
      showAlert(
        isEdit ? SUCCESS_UPDATED_MESSAGE : SUCCESS_MESSAGE,
        { variant: 'success' }
      )
    } else {
      showAlert(ERROR_MESSAGE)
    }
  }, [stopLoader, showAlert, isEdit])

  const form = useForm<FormValues, ErrTouchShortcuts>({
    baseValues: DEFAULT_FORM_VALUE,
    submitAction,
    validationRules,
    onActionResult
  })

  const {
    values,
    errors,
    touched,
    onChange,
    setFieldValue,
    setTouched,
    resetForm,
    onBlur,
    onSubmit,
    setFieldError
  } = form

  const onChangeTaskType = useCallback((
    id: string,
    value: DictionaryItem | null
  ) => {
    onChange(id, value)

    if (value?.id !== TaskType.Reminder) {
      onChange(FieldIds.ReminderSubject, null)
    }
  }, [onChange])

  const onDeleteEvent = useCallback(async () => {
    if (formData?.id != null) {
      await onDelete(formData.id)
    }
  }, [formData?.id])

  const onChangeEventType = useCallback(async (value: string) => {
    onChange(FieldIds.WorkPlanEventTypeId, Number(value))

    await setTouched({
      title: false,
      assignedUser: false,
      taskType: false,
      reminderSubject: false,
      taskPriority: false,
      executionDay: false,
      description: false,
      workplanEventTypeId: false,
      emailTemplateId: {
        id: false,
        name: false
      },
      messageTemplateId: {
        id: false,
        name: false
      },
      runAtTime: false
    })
    // await setTouched(touchify(DEFAULT_FORM_VALUE) as any)
  }, [onChange, setTouched, formData?.id, setFieldError])

  const onClose = useCallback(() => {
    onCloseModal?.()
    onChange(FieldIds.AssignedTo, '')
    formDataRef.current = { ...DEFAULT_FORM_VALUE }
  }, [onCloseModal, onChange])

  const resetTaskPriority = useCallback(() => {
    const taskPriority = taskPriorities.find(({ id }) => id === PRIORITY_TASK_MEDIUM_ID)

    onChange(FieldIds.TaskPriority, taskPriority)

    formDataRef.current = { ...DEFAULT_FORM_VALUE, taskPriority: taskPriority ?? null }
  }, [onChange, taskPriorities])

  useEffect(() => {
    if (leadStatus == null) {
      resetTaskPriority()
      resetForm(formDataRef.current)
      formInited.current = false
    }
  }, [leadStatus, setFieldValue, resetTaskPriority, resetForm])

  useEffect(() => {
    if (taskPriorities.length > 0 && !formInited.current) {
      resetTaskPriority()
    }
  }, [resetTaskPriority])

  useAsyncEffect(async () => {
    const [
      { items: taskPriorities },
      { items: reminderSubjects },
      { items: taskTypes },
      { items: taskAssigneeTypes },
      { items: users },
      { items: emailTemplates },
      { items: messageTemplates },
      { ...roundRobinSettings }
    ] = await Promise.all([
      CRMApiProvider.getTaskPriorities(),
      CRMApiProvider.getReminderSubjects(),
      CRMApiProvider.getTaskTypes(),
      CRMApiProvider.getTaskAssigneeTypes(),
      IdentityApiProvider.getUsers(userRolesWithCRMPermission),
      NotificationsAPIProvider.getLeadEmailTemplates({ skip: 0, take: ONE_LOAD_PAGE_SIZE }),
      NotificationsAPIProvider.getMessageTemplates({ skip: 0, take: ONE_LOAD_PAGE_SIZE }),
      CRMApiProvider.getCrmSettings()
    ])
    const taskTypesUsableInAssignmentRule = taskTypes.filter(({ isUsableInWorkplan }) => isUsableInWorkplan)
    const defaultOptions = getDefaultAssigneeOptions(taskAssigneeTypes)

    setTaskPriorities(taskPriorities)
    setReminderSubjects(reminderSubjects)
    setTaskTypes(taskTypesUsableInAssignmentRule)
    setTaskAssigneeTypes(taskAssigneeTypes)
    setUsers([...defaultOptions, ...parseUsers(users, 'Unassigned')])
    setEmailTemplates(emailTemplates)
    setMessageTemplates(messageTemplates)
    setTimeZoneDisplayName(roundRobinSettings?.timeZoneDisplayName ?? '')
  }, [])

  useAsyncEffect(async () => {
    const isReadyToSetDataForEdit =
      formData != null &&
      taskTypes.length > 0 &&
      users.length > 0 &&
      reminderSubjects.length > 0 &&
      taskPriorities.length > 0 &&
      !formInited.current

    if (isReadyToSetDataForEdit) {
      formInited.current = true

      const data = { ...formData }
      const assignedUser: DictionaryItem | null = users.find(({ id }) => id === data.assignedUserId || -id === data.taskAssigneeTypeId) ?? null
      const emailTemplate: DictionaryItem | null = emailTemplates.find(({ id }) => id === data.emailTemplateId) ?? null
      const messageTemplate: DictionaryItem | null = messageTemplates.find(({ id }) => id === data.messageTemplateId) ?? null

      const values = parseDataToEdit({
        data,
        taskPriorities,
        reminderSubjects,
        taskTypes,
        assignedUser,
        emailTemplate,
        messageTemplate
      })

      formDataRef.current = values

      resetForm(values)
    }
  }, [
    formData,
    taskPriorities,
    reminderSubjects,
    taskTypes,
    users
  ])

  return {
    isReminderDisabled: values[FieldIds.TaskType]?.id !== TaskType.Reminder,
    isLoading,
    taskPriorities,
    reminderSubjects,
    taskTypes,
    users,
    values,
    errors,
    touched,
    messageTemplates,
    emailTemplates,
    timeZoneDisplayName,
    onChange,
    onChangeTaskType,
    onBlur,
    onSubmit,
    onClose,
    onDeleteEvent,
    onChangeEventType
  }
}
