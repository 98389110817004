import { useCallback, useEffect, useState } from 'react'

import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { PercentOrAmountModes } from 'types'
import { type FormData, type UseSalesTaxEditorProps, type UseSalesTaxEditorReturn } from './types'
import { percentToDecimal, roundCurrency } from 'utils/math'

const DEFAULT_FORM_DATA: FormData = {
  amount: null,
  tax: null
}

export const useSalesTaxEditor = ({
  getSalesTaxAmount,
  isManualSalesTax,
  isOpen,
  onChange,
  onClose,
  onSubmitSalesTax: _onSubmitSalesTax,
  salesTax,
  salesTaxAmount,
  salesTaxAmountId,
  salesTaxId
}: UseSalesTaxEditorProps): UseSalesTaxEditorReturn => {
  const { showAlert } = useCustomSnackbar()
  const [amountValue, setAmountValue] = useState(DEFAULT_FORM_DATA.amount)
  const [helperAmountValue, setHelperAmountValue] = useState(getSalesTaxAmount(salesTax))
  const [taxValue, setTaxValue] = useState(DEFAULT_FORM_DATA.tax)

  const [taxMode, setTaxMode] =
    useState<string>(isManualSalesTax ? PercentOrAmountModes.Manual : PercentOrAmountModes.Auto)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onChangeTaxAmount = useCallback((_, value): void => {
    setAmountValue(value)
  }, [])

  /**
   * Value's precision is `0.001`
   */
  const onChangeTax = useCallback((_, value) => {
    const newTaxValue = percentToDecimal(value)
    setTaxValue(newTaxValue)
    setHelperAmountValue(roundCurrency(getSalesTaxAmount(newTaxValue)))
  }, [])

  const onSubmitSalesTax = (): void => {
    const doJob = async (): Promise<void> => {
      try {
        /**
          * OP-NOTE: should set 0 as a default value to amountValue and taxValue to avoid 400 error from BE.
          * The issue is that state expects null or a number. There is a rare case where null is sent from the
          * BE for overriddenSalesTaxPercent or overriddenSalesTaxAmount, and we switch to another mode.
          * In the form, 0 is displayed but in the state we have null. In such a case, both fields equal null,
          * and we get a 400 error.
        */
        await _onSubmitSalesTax({
          overridenSalesTaxAmount: taxMode === PercentOrAmountModes.Manual ? amountValue ?? 0 : null,
          overridenSalesTaxPercent: taxMode === PercentOrAmountModes.Auto ? taxValue ?? 0 : null
        })

        /**
         * `nextAmount` is always number, since it's FE-calculated field.
         */
        const nextAmount = taxMode === PercentOrAmountModes.Manual
          ? Number(amountValue)
          : getSalesTaxAmount(taxValue)

        /**
         * `nextTax` can be `null` if amount is entered manually.
         */
        const nextTax = taxMode === PercentOrAmountModes.Manual
          ? null
          : Number(taxValue)

        onChange(salesTaxId, nextTax)
        onChange(salesTaxAmountId, nextAmount)
        onClose()
      } catch (err) {
        showAlert(err)
      }
    }

    void doJob()
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Prefill/reset data for inputs
   * AZ-TODO: check, need reset?
   */
  useEffect(() => {
    const data = isOpen
      ? { amount: salesTaxAmount, tax: salesTax }
      : DEFAULT_FORM_DATA

    setAmountValue(data.amount)
    setTaxValue(data.tax)
  }, [isOpen, salesTaxAmount, salesTax])

  // ========================================== //

  return {
    amountValue,
    helperAmountValue,
    onChangeTaxAmount,
    onChangeTax,
    onChangeTaxMode: setTaxMode,
    onOverrideData: onSubmitSalesTax,
    taxMode,
    taxValue
  }
}
