import { type DictionaryItem } from 'api/types'
import { type AccountModel } from 'api/types/responses'
import { type AccountFormData, type CategoriesByTypes } from './types'

export interface ParsedData {
  account: AccountModel
  types: DictionaryItem[]
  categoriesByTypes: CategoriesByTypes
}

const parseData = ({
  account,
  types,
  categoriesByTypes
}: ParsedData): AccountFormData => {
  const accountType = types
    .find(({ id }) => id === account.accountType) ?? null

  const accountCategory = accountType?.id != null
    ? categoriesByTypes[accountType.id]?.find(
      ({ id }) => id === account.accountCategory
    ) ?? null
    : null

  return {
    ...account,
    accountType,
    accountCategory
  }
}

export default parseData
