import {
  useState,
  useRef,
  useEffect
} from 'react'

import usePhotosUpload, { MAX_FILE_NUMBER } from 'hooks/usePhotosUpload'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { ImageState, type ImageDescriptor } from 'components/inventory/ImageItem'
import { type PromosDto } from 'api/types/responses'
import { DEFAULT_PROMO_DTO } from 'api/defaults/promo'
import PromosApiProvider from 'api/promos.api'

export interface UseGalleryPromoReturn {
  isPhotosUploading: boolean
  frequencyTouched: boolean
  frequencyError: string
  promoData: PromosDto
  photos: ImageDescriptor[]
  uploadImageError: string
  onResetGalleryPromo: () => void
  onToggleIsEnabled: () => void
  onUpdateFrequency: (_: string, frequency: number) => void
  onBlurFrequency: () => void
  onUpdate: () => Promise<void>
  onUploadFiles: (filesToUpload: File[]) => Promise<void>
  onDeleteFile: (idx: number) => Promise<void>
  onReorderPhotos: (activeIdx: number, overIdx: number) => Promise<void>
  validatePromo: () => boolean
  setUploadImageError: (error: string) => void
  isChanged: boolean
  isLoading: boolean
}

const isNumberEmpty = (val: number | null): boolean => val == null || val === 0

const FREQUENCY_ERROR_MESSAGE = 'Cannot be empty or zero'

const useGalleryPromo = (): UseGalleryPromoReturn => {
  const [isLoading, setIsLoading] = useState(false)
  const [frequencyTouched, setFrequencyTouched] = useState(false)
  const [isGalleryPhotosTouched, setIsGalleryPhotosTouched] = useState(false)
  const [isGalleryFieldsTouched, setIsGalleryFieldsTouched] = useState(false)
  const [promoData, setPromoData] = useState<PromosDto>(DEFAULT_PROMO_DTO)
  const refOriginalData = useRef<PromosDto>(DEFAULT_PROMO_DTO)
  const { showAlert } = useCustomSnackbar()

  const {
    photos,
    isPhotosUploading,
    uploadImageError,
    setUploadImageError,
    onResetPhotos,
    onUploadFiles: _onUploadFiles,
    onDeleteFile: _onDeleteFile,
    onReorderPhotos: _onReorderPhotos
  } = usePhotosUpload({
    uploadedPhotos: promoData.promoImages
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onUploadFiles = async (files: File[]): Promise<void> => {
    await _onUploadFiles(files)
    setIsGalleryPhotosTouched(true)
  }

  const onDeleteFile = async (idx: number): Promise<void> => {
    await _onDeleteFile(idx)
    setIsGalleryPhotosTouched(true)
  }

  const onReorderPhotos = async (activeIdx: number, overIndex: number): Promise<void> => {
    await _onReorderPhotos(activeIdx, overIndex)
    setIsGalleryPhotosTouched(true)
  }

  const onResetGalleryPromo = (): void => {
    onResetPhotos(refOriginalData.current.promoImages)
    setPromoData(refOriginalData.current)
    setIsGalleryPhotosTouched(false)
    setIsGalleryFieldsTouched(false)
    setFrequencyTouched(false)
  }

  const onToggleIsEnabled = (): void => {
    const isFieldChanged = (
      refOriginalData.current.isEnabled !== (!promoData.isEnabled) ||
      refOriginalData.current.galleryFrequency !== promoData.galleryFrequency
    )
    setFrequencyTouched(false)
    setIsGalleryFieldsTouched(isFieldChanged)
    setPromoData(prev => ({ ...prev, isEnabled: !prev.isEnabled }))
  }

  const onUpdateFrequency = (_: string, frequency: number): void => {
    const isFieldChanged = (
      refOriginalData.current.isEnabled !== promoData.isEnabled ||
      refOriginalData.current.galleryFrequency !== frequency
    )
    setIsGalleryFieldsTouched(isFieldChanged)
    setPromoData(prev => ({ ...prev, galleryFrequency: frequency }))
  }

  const onBlurFrequency = (): void => {
    setFrequencyTouched(true)
  }

  const validatePromo = (): boolean => {
    if (!promoData.isEnabled) {
      return true
    }

    setFrequencyTouched(true)

    if (isNumberEmpty(promoData.galleryFrequency)) {
      return false
    }

    if (photos.some(p => p.state === ImageState.Failed)) {
      showAlert('Please remove failed photos before saving')
      return false
    }

    if (photos.length > MAX_FILE_NUMBER) {
      showAlert(`Maximum ${MAX_FILE_NUMBER} photos allowed`)
      return false
    }

    return true
  }

  const onUpdate = async (): Promise<void> => {
    await PromosApiProvider.updatePromos({
      ...promoData,
      promoImages: photos.map((photo, i) => {
        return {
          ...photo,
          thumbnailUrl: photo.thumbnailUrl ?? '',
          originalSizeKb: photo.originalSizeKb ?? 0,
          order: i,
          promoId: promoData.id
        }
      })
    })

    setFrequencyTouched(false)
    setIsGalleryFieldsTouched(false)
    setIsGalleryPhotosTouched(false)
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const load = async (): Promise<void> => {
      setIsLoading(true)
      try {
        const res = await PromosApiProvider.getPromos()
        res.promoImages = res.promoImages.sort((a, b) => a.order - b.order)
        setPromoData(res)
        refOriginalData.current = res
      } catch (e) {
        showAlert(e)
      } finally {
        setIsLoading(false)
      }
    }

    void load()
  }, [])

  // ========================================== //

  const frequencyError = isNumberEmpty(promoData.galleryFrequency)
    ? FREQUENCY_ERROR_MESSAGE
    : ''

  const isChanged = isGalleryFieldsTouched || isGalleryPhotosTouched

  return {
    frequencyTouched,
    frequencyError,
    promoData,
    onResetGalleryPromo,
    onToggleIsEnabled,
    onUpdateFrequency,
    onBlurFrequency,
    onUpdate,
    photos,
    uploadImageError,
    isPhotosUploading,
    onUploadFiles,
    onDeleteFile,
    onReorderPhotos,
    validatePromo,
    setUploadImageError,
    isChanged,
    isLoading
  }
}

export default useGalleryPromo
