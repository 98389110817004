import { css } from '@emotion/css'

export default (isWrapped: boolean): string => css(`  
  && {
    p {
      font-family: Roboto;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: ${isWrapped ? 'break-spaces' : 'nowrap'};
    }
  }
`)
