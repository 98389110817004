import {
  type ControlListItem,
  type TransactionListItemLine,
  TransactionControlTypeId
} from 'api/types'

const getLineControlBaseItem = (line: TransactionListItemLine | null): ControlListItem | null => {
  const { controlEntity, controlId, deal } = line ?? {}

  if (controlEntity != null) {
    return {
      ...controlEntity,
      controlId: controlId ?? '',
      controlType: TransactionControlTypeId.Vehicle,
      id: controlEntity.id,
      firstName: null,
      lastName: null,
      name: controlId ?? '',
      dealStateId: null,
      vehicleState: controlEntity.vehicleState
    }
  }

  if (deal != null) {
    return {
      ...deal.vehicle,
      controlId: controlId ?? '',
      controlType: TransactionControlTypeId.Deal,
      id: deal.id, // AZ-NOTE: important, not `deal.vehicle.id`
      firstName: deal.personalDetails.firstName,
      lastName: deal.personalDetails.lastName,
      name: controlId ?? '',
      stock: deal.vehicle.stockNumber,
      year: deal.vehicle.modelYear,
      dealStateId: null,
      vehicleState: null
    }
  }

  return null
}

export default getLineControlBaseItem
