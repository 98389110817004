import { makeStyles, Theme } from '@material-ui/core/styles'

interface StylesProps {
  iconSize: number
}

export const useStyles = makeStyles<Theme, StylesProps>({
  infoRoot: {
    display: 'inline-flex',
    marginLeft: 5,
    cursor: 'pointer',
    position: 'relative',
    alignItems: 'center'
  },
  iconRoot: {
    width: ({ iconSize }) => iconSize,
    height: ({ iconSize }) => iconSize
  },
  tooltipTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    padding: 0,
    margin: 0,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    background: 'none',
    color: '#FFFFFF',
    minWidth: 300,
    maxWidth: 450
  },
  popper: {
    background: '#212121',
    borderRadius: 4,
    padding: '4px 12px',
    marginLeft: 12,
    '&[role="tooltip"]': {
      zIndex: 1400
    }
  }
})
