import bestQuality from 'assets/best_quality.svg'
import carPieceOfMind from 'assets/car_piece_of_mind.svg'
import easyCarShopping from 'assets/easy_car_shopping.svg'

export const MAX_HEADER_LENGTH = 40
export const MAX_AD_HEADER_LENGTH = 30

export type FieldId = 'ads1' | 'ads2' | 'ads3'

export const CAR_ADS_SECTIONS = [
  {
    fieldId: 'ads1',
    icon: carPieceOfMind
  },
  {
    fieldId: 'ads2',
    icon: bestQuality
  },
  {
    fieldId: 'ads3',
    icon: easyCarShopping
  }
]
