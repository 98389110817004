import { type FC } from 'react'

const IconPersonaFailed: FC = () => {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.4974 1.67188C5.8974 1.67188 2.16406 5.40521 2.16406 10.0052C2.16406 14.6052 5.8974 18.3385 10.4974 18.3385C15.0974 18.3385 18.8307 14.6052 18.8307 10.0052C18.8307 5.40521 15.0974 1.67188 10.4974 1.67188ZM11.3307 14.1667H9.66406V12.5H11.3307V14.1667ZM11.3307 10.8438H9.66406V5.84375H11.3307V10.8438Z'
        fill='#FFA451'
      />
    </svg>
  )
}

export default IconPersonaFailed
