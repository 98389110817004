import type { FC } from 'react'
import { UI, Loader, cnx } from '@carfluent/common'

import { isTruthy } from 'utils/general'
import IconSVG from 'components/inlineImages'

import useSearchInput from './hook'

import CLASS_NAME from './styles'

const { InputField } = UI

export interface SearchInputProps {
  className?: string
  disabled?: boolean
  isLoading?: boolean
  bgColor?: 'white' | 'gray'
  onChange: (val: string) => void
  value?: string
  placeholder?: string
  dataTestId?: string
  onSearch?: () => void
  withDeleteIcon?: boolean
}

export const SearchInput: FC<SearchInputProps> = ({
  className = '',
  disabled = false,
  bgColor = 'white',
  isLoading,
  onChange: _onChange,
  value = '',
  placeholder,
  dataTestId,
  onSearch,
  withDeleteIcon = false
}) => {
  const {
    onBlur,
    onFocus,
    inputRef,
    onChange,
    iconColor,
    onKeyDown,
    onIconMouseUp,
    onIconMouseDown
  } = useSearchInput({
    disabled,
    onSearch,
    onChange: _onChange
  })
  return (
    <div className={cnx(CLASS_NAME, className, bgColor)}>
      <InputField
        disabled={disabled}
        inputRef={inputRef}
        onKeyDown={onKeyDown}
        id='search-input'
        dataTestId={dataTestId}
        startAdornment={(isLoading === true
          ? <Loader color='dark' />
          : (
            <span onClick={() => {
              onSearch?.()
            }}
            >
              <IconSVG.SearchIcon color={iconColor} />
            </span>
            )
        )}
        endAdornment={
          withDeleteIcon && isTruthy(value) &&
            <span
              onMouseUp={onIconMouseUp}
              onMouseDown={onIconMouseDown}
            >
              <IconSVG.DeleteIcon />
            </span>
        }
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        value={value}
      />
    </div>
  )
}

export default SearchInput
