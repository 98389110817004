import useToggler from 'hooks/useToggler'
import { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react'
import { FCHook } from 'types'

export interface UseSemiCollapsibleProps {
  children: ReactNode | ReactNode[]
  fixedHeight: number
}

export interface UseSemiCollapsibleReturn {
  isOpen: boolean
  isTogglerVisible: boolean
  onToggle: () => void
  ref: MutableRefObject<HTMLDivElement | null>
}

const useSemiCollapsible: FCHook<UseSemiCollapsibleProps, UseSemiCollapsibleReturn> = ({
  children,
  fixedHeight
}) => {
  const togglerProps = useToggler(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [contentHeight, setContentHeight] = useState(fixedHeight)

  useEffect(() => {
    if (wrapperRef.current == null) {
      return
    }

    const content = wrapperRef.current.querySelector('.cf-content')
    if (content == null) {
      return
    }

    setContentHeight(content.scrollHeight)
  }, [wrapperRef.current, fixedHeight, children])

  return {
    isOpen: togglerProps.value,
    isTogglerVisible: contentHeight > fixedHeight,
    onToggle: togglerProps.toggle,
    ref: wrapperRef
  }
}

export default useSemiCollapsible
