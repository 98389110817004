import { parsers } from '@carfluent/common'
import { serializeDateFiltersLocal, serializeLocalDate } from 'utils/accounting'

const { parseDateStringUTC } = parsers

/**
 * For building xslx files we should send all dates in local timezone,
 * since API endpoint doesn't know user's tz when builds xslx report.
 *
 * Moved to common file because of using it in PL,BS,TB components
 */
export function serializeReportForXslx <T extends { dateFrom: string | null, dateTo: string | null }> (report: T): T {
  const dates = serializeDateFiltersLocal({
    from: parseDateStringUTC(report.dateFrom),
    to: parseDateStringUTC(report.dateTo)
  })

  return {
    ...report,
    dateFrom: dates?.from ?? null,
    dateTo: dates?.to ?? null,
    dateIssue: serializeLocalDate(new Date()) ?? ''
  }
}
