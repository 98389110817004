import type { BaseListItem, EntityId as BaseEntityId } from 'types'

export enum SortOrder {
  Ascending = 1,
  Descending,
}

export interface PaginationOptions {
  skip: number
  take: number
}

export interface ListPayload extends PaginationOptions {
  id?: string | number
  search?: string | null
  sortField?: string
  sortOrder?: SortOrder
}

export interface CancelationOptions {
  key?: string | number
  cancelPrevious?: boolean
}

export interface ListResponse<T = BaseListItem> {
  items: T[]
}

export interface PaginatedResult<T> extends ListResponse<T> {
  count: number
}

export const isCancelationOptions = (config: any): config is CancelationOptions => {
  return (config != null) && (typeof config === 'object') && ('cancelPrevious' in config)
}

export interface DictionaryItem<T = string> {
  id: number
  name: T
}

export type DictionaryItems<T> = Array<DictionaryItem<T>>

export interface EntityDictionary<T> {
  items: DictionaryItems<T>
}

export type EntityId = BaseEntityId

export interface WebSocketArgs {
  dealerId: number
}
