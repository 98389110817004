import {
  type OpenBalancesListItem,
  type TransactionLine as ApiTransactionLine,
  type TransactionDetails as ApiTransactionDetails,
  TransactionControlTypeId
} from 'api/types'

import {
  type BankStatementAccountInfo,
  type BankStatementInfo,
  type ReconciliationInfo,
  type TransactionLineControl
} from 'types'

import { namifyAccount } from 'utils/accounting/namifyAccount'
import { toDateOrNull } from 'utils/parse_date'
import { isFalsy } from 'utils/general'
import { toNumberOrNull } from 'utils/parse_string'

/**
 * For Transactions page API sends reduced version of Vehicle (Control).
 * But since we get a full-fledged Vehicle in `GET /Vehicles` - we need
 * to use Vehicle type everywhere. So this "parser" complements `Control`
 * to `Vehicle` by filling not-used fields with mock data.
*/
export const parseLineToControl = (line: ApiTransactionLine | OpenBalancesListItem | null): TransactionLineControl | null => {
  if (line == null) {
    return null
  }

  const {
    control,
    dealId,
    controlEntity,
    deal
  } = line

  if ((control != null) && (controlEntity != null)) {
    return {
      ...controlEntity,
      name: line.controlId ?? '',
      controlId: line.controlId ?? '',
      controlType: TransactionControlTypeId.Vehicle,
      firstName: '',
      lastName: '',
      dealStateId: null,
      vehicleState: controlEntity.vehicleState
    }
  }

  if ((dealId != null) && (deal != null)) {
    return {
      id: deal.id,
      name: line.controlId ?? '',
      controlId: line.controlId ?? '',
      controlType: TransactionControlTypeId.Deal,
      firstName: deal.personalDetails.firstName ?? null,
      lastName: deal.personalDetails.lastName ?? null,
      vin: deal.vehicle.vin ?? null,
      make: deal.vehicle.make ?? null,
      model: deal.vehicle.model ?? null,
      year: deal.vehicle.modelYear ?? null,
      stock: deal.vehicle.stockNumber?.toString() ?? null,
      trim: deal.vehicle.trim ?? null,
      dealStateId: null,
      vehicleState: null
    }
  }

  return null
}

export const parseBankingInfo = (
  lines: Array<ApiTransactionLine | OpenBalancesListItem>
): ReconciliationInfo => {
  const bankStatements: BankStatementInfo[] = []
  const clearedAccounts: BankStatementAccountInfo[] = []
  const reconciledAccounts: BankStatementAccountInfo[] = []
  let hasLineWithCompletedReconciliation = false
  let isAutoLineInBanking = false

  for (const item of lines) {
    if (isFalsy((item as ApiTransactionLine).id)) {
      continue // don't parse new unsaved lines
    }

    const accountName = item.account != null ? namifyAccount(item.account).name : ''
    const accountChange = (item as ApiTransactionLine).change ?? (item as OpenBalancesListItem).amount ?? 0

    if (item.bankStatementTransaction != null) {
      const bankInfo: BankStatementInfo = {
        accountName,
        change: item.bankStatementTransaction.change,
        clearMethodId: item.bankStatementTransaction.bankStatementTransactionClearMethodId ?? null,
        date: toDateOrNull(item.bankStatementTransaction.date),
        description: item.bankStatementTransaction.description,
        id: item.bankStatementTransaction.id
      }

      bankStatements.push(bankInfo)

      if ((item as ApiTransactionLine).isSystemCreated) {
        isAutoLineInBanking = true
      }
    }

    const accountInfo: BankStatementAccountInfo = {
      id: 0,
      name: accountName,
      change: accountChange,
      reconciledOnDate: null
    }

    if (item.lineEntryReconciliation != null) {
      accountInfo.id = item.lineEntryReconciliation.id
      accountInfo.reconciledOnDate = new Date(item.lineEntryReconciliation.reconciledOnDate)
      reconciledAccounts.push(accountInfo)

      if (item.lineEntryReconciliation.processEndDate != null) {
        hasLineWithCompletedReconciliation = true
      }

      if ((item as ApiTransactionLine).isSystemCreated) {
        isAutoLineInBanking = true
      }
    }

    if (item.bankStatementTransaction != null) {
      accountInfo.id = (item as ApiTransactionLine).id ?? Date.now() // AZ-NOTE: OpenBalancesListItem item does not have id ??
      clearedAccounts.push(accountInfo)
    }
  }

  /**
   * Calculates "biggest" reconcile status, in order: Cleared < Reconciled < ClearedAndReconciled
   * Reconcile status of the entire transaction is deducted from lines' statuses.
   */
  const hasReconciledLines = reconciledAccounts.length > 0
  const hasClearedLines = clearedAccounts.length > 0

  return {
    bankStatements,
    clearedAccounts,
    hasBankingLines: hasReconciledLines || hasClearedLines,
    hasLineWithCompletedReconciliation,
    isAutoLineInBanking,
    reconciledAccounts
  }
}

export interface ParsedCommonTransaction {
  costInventoryId: number | null
  isVehicleCost: boolean
}

// AZ-TODO: enhance, move all common logic here
export const parseCommonParts = ({
  isVehiclePayment,
  lines
}: ApiTransactionDetails): ParsedCommonTransaction => {
  const isVehicleCost = lines.some(l => l.isVehicleCost)
  const costInventoryId = (isVehiclePayment || isVehicleCost)
    ? toNumberOrNull(lines[0].controlEntity?.id)
    : null

  return {
    isVehicleCost,
    costInventoryId
  }
}
