import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import VehiclesApiProvider from 'api/vehicles.api'
import { GET_DEFAULT_VEHICLES_SORTING } from 'api/defaults'
import {
  type ListPayload,
  type PaginatedResult,
  type Vehicle,
  type VehicleImage,
  VehicleMediaTypeId
} from 'api/types'
import type { FCHook } from 'types'
import { KiB, TOTAL_FILE_SIZE_ERROR, DEFAULT_FILE_MAX_SIZE } from 'constants/files'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { namifyVehicle, carToVehicle, matchesSearchCriteria } from 'utils/vehicleDropdownHelpers'

import type {
  VehicleWithGroup,
  UseAttachInventoryPhotosModalProps,
  UseAttachInventoryPhotosModalReturn
} from './types'
import {
  photosNumLimit,
  GROUP_FROM_INVENTORY,
  prependGroupNameOptions,
  GROUP_CARS_OF_INTEREST
} from './constants'

const useAttachInventoryPhotosModal: FCHook<UseAttachInventoryPhotosModalProps, UseAttachInventoryPhotosModalReturn> = ({
  isOpen,
  isPhotosNumLimited,
  carsOfInterest,
  attachmentsInfo: {
    attachments,
    totalSize
  },
  onClose: _onClose,
  onSubmit: _onSubmit
}) => {
  const { showAlert } = useCustomSnackbar()

  const [files, setFiles] = useState<VehicleImage[]>([])
  const [photosCache, setPhotosCache] = useState<Map<number, VehicleImage[]>>(new Map())
  const [selectedFileNumbers, setSelectedFileNumbers] = useState<Set<number>>(new Set())
  const [isPhotosLoading, setPhotosLoading] = useState<boolean>(false)
  const [isCarSelected, setCarSelected] = useState<boolean>(false)
  const [value, setValue] = useState<Vehicle | null>(null)
  const refInitialLoad = useRef(true)

  const hasCarOfInterest = carsOfInterest.length > 0
  const refSearch = useRef<string | null>(null)

  const selectedFiles = useMemo(() =>
    files.filter(file => selectedFileNumbers.has(file.order)),
  [files, selectedFileNumbers])

  const selectedPhotos = useMemo(() =>
    selectedFiles.filter(file => file.vehicleMediaTypeId === VehicleMediaTypeId.Image),
  [files, selectedFileNumbers])

  const selectedVideos = useMemo(() =>
    selectedFiles
      .filter(file => file.vehicleMediaTypeId === VehicleMediaTypeId.Video),
  [files, selectedFileNumbers])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onCarSelected = useCallback(async (vehicleId: number) => {
    setSelectedFileNumbers(new Set()) // reset selected photos from previous vehicle

    const cachedPhotos = photosCache.get(vehicleId)
    if (cachedPhotos != null) {
      setFiles(cachedPhotos)
      setCarSelected(true)
      return
    }

    try {
      setPhotosLoading(true)
      const { items } = await VehiclesApiProvider.getVehicleImages(vehicleId)
      setFiles(items)

      setPhotosCache(prevCache => new Map(prevCache).set(vehicleId, items))
    } catch (err) {
      showAlert(err)
    } finally {
      setPhotosLoading(false)
      setCarSelected(true)
    }
  }, [showAlert, photosCache])

  const onChange = useCallback((_, item: Vehicle | null) => {
    if (item == null) {
      return
    }

    refSearch.current = namifyVehicle(item).name
    void onCarSelected(item.id)
    setValue(item)
  }, [onCarSelected])

  const getVehicles = useCallback(async (payload: ListPayload): Promise<PaginatedResult<VehicleWithGroup>> => {
    const search = payload?.search?.toLowerCase() ?? ''

    try {
      const response = await VehiclesApiProvider.getVehicles({ ...payload, ...GET_DEFAULT_VEHICLES_SORTING() })

      if (response.items.length === 0) {
        refInitialLoad.current = true
        return { items: [], count: 0 }
      }

      let newVehicles = response.items.map(item => ({ ...item, group: GROUP_FROM_INVENTORY }))

      const vehiclesOfInterest = carsOfInterest
        .filter(item => matchesSearchCriteria(item, search))
        .map(carToVehicle)

      const existingIds = new Set([
        ...vehiclesOfInterest.map(item => item.id)
      ])

      newVehicles = newVehicles.filter(item => !existingIds.has(item.id))

      if (refInitialLoad.current || (refSearch.current !== search)) {
        newVehicles = [
          ...prependGroupNameOptions(vehiclesOfInterest, GROUP_CARS_OF_INTEREST),
          ...prependGroupNameOptions(newVehicles, GROUP_FROM_INVENTORY)
        ]
      }

      refInitialLoad.current = false
      refSearch.current = search

      return {
        ...response,
        items: newVehicles
      }
    } catch (err) {
      return { items: [], count: 0 }
    }
  }, [])

  const onFileClick = useCallback((file: VehicleImage): void => {
    const isVideo = file.vehicleMediaTypeId === VehicleMediaTypeId.Video

    if (selectedFileNumbers.has(file.order)) {
      setSelectedFileNumbers((prevSelectedFileNumbers) => {
        const newSelectedPhotoNumbers = new Set(prevSelectedFileNumbers)
        newSelectedPhotoNumbers.delete(file.order)
        return newSelectedPhotoNumbers
      })
    } else if (isVideo) {
      setSelectedFileNumbers((prevSelectedFileNumbers) => new Set(prevSelectedFileNumbers).add(file.order))
    } else if (!isPhotosNumLimited || (selectedPhotos.length < photosNumLimit - attachments.length)) {
      setSelectedFileNumbers((prevSelectedFileNumbers) => new Set(prevSelectedFileNumbers).add(file.order))
    }
  }, [attachments.length, selectedFileNumbers, selectedPhotos, isPhotosNumLimited])

  const onClose = useCallback(() => {
    _onClose()
    setSelectedFileNumbers(new Set())
    setFiles([])
    setCarSelected(false)
    refSearch.current = null
  }, [_onClose])

  const onSubmit = useCallback(() => {
    const totalSizeWithSelected = (selectedFiles.reduce((acc, photo) => acc + photo.originalSizeKb, 0) / KiB) + totalSize

    if (totalSizeWithSelected >= DEFAULT_FILE_MAX_SIZE) {
      showAlert(TOTAL_FILE_SIZE_ERROR())
      return
    }

    _onSubmit(selectedFiles, value?.id ?? null)
    onClose()
  }, [selectedFiles, totalSize, showAlert, _onSubmit, onClose])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    refInitialLoad.current = true

    if (isOpen && hasCarOfInterest && refSearch.current == null) {
      const findPrimaryCar = carsOfInterest.find(car => car.isPrimary) ?? carsOfInterest[0]
      refSearch.current = namifyVehicle(findPrimaryCar).name
      void onCarSelected(findPrimaryCar.id)
      setValue(findPrimaryCar as unknown as Vehicle)
    }
  }, [isOpen, onCarSelected, carsOfInterest, hasCarOfInterest])

  // ========================================== //

  return {
    isPhotosLoading,
    isPhotosNumLimited,
    isCarSelected,
    value,
    files,
    selectedPhotos,
    selectedVideos: selectedVideos ?? [],
    attachmentsLength: attachments.length,
    onFileClick,
    onSubmit,
    onClose,
    onChange,
    getVehicles
  }
}

export default useAttachInventoryPhotosModal
