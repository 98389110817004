import type { Dispatch, SetStateAction } from 'react'

import { type LeadFormData, type TasksFormData } from 'types'
import type { SendEmailProps } from 'hooks/useSendEmail/types'
import type { FileData } from 'hooks/useFilesUpload'
import type { ActionsMenuCloseReason } from 'components/common/ActionsMenu'
import type {
  DictionaryItem,
  EntityId,
  VehicleImage,
  EmailAttachment
} from 'api/types'

import type { UseLeadMessagesReturn } from './useLeadMessages'
import type { UseTimelineReturn } from '../components/Timeline/hook'
import type { CompleteTaskFormData } from '../components/LeadDetailsTasks/components/TaskComplete/hook/types'
import type { LogActivityFormData } from '../components/LeadDetailsLogActivity/hook/types'
import type { ItemProps } from '../components/Timeline/components/Item/types'
import type { WebChatInfo } from '../components/MessangerPreview'
export type { CompleteTaskFormData } from '../components/LeadDetailsTasks/components/TaskComplete/hook/types'
export type { LogActivityFormData } from '../components/LeadDetailsLogActivity/hook/types'
export type { ActionsMenuCloseReason } from 'components/common/ActionsMenu'

export enum LeadSources {
  WalkIn = 1,
  Web = 2,
  GeneralReferral = 3,
  ServiceReferral = 4,
  DealerMgmtSys = 5,
  ListImport = 6,
  Manufacturer = 7,
  Phone = 8,
  CoBuyer = 9,
  AutoTrader = 10,
  CarGurus = 11,
  CarsCom = 12,
  Ebay = 13,
  TrueCar = 14,
  CarFax = 15,
  DealerRater = 16,
  Edmunds = 17,
  HomeNet = 18,
  DealerCenter = 19,
  Ove = 20,
  Financing = 21,
  WebsiteTradeIn = 22,
  Ask = 23,
  Drive = 24,
  WebsiteDeal = 25,
  WebChat = 37
}

export enum ScheduleButtonTaskType {
  FollowUpCall = 'Follow up call',
  Appointment = 'Appointment',
  Reminder = 'Reminder'
}

export enum ActivityButtonTaskType {
  FollowUpCall = 'Follow up call',
  Visit = 'Visit store',
  Other = 'Other'
}

export enum TaskType {
  'Follow up call' = 1,
  Appointment,
  Reminder,
  'Web lead',
  'Message received',
  'Email received',
  'Credit app received' = 7
}

export type TaskOmitRespondType = Exclude<TaskType, 4 | 5 | 6>

export enum TaskMode {
  NewTask = 'New task',
  EditTask = 'Edit task',
  ViewTask = 'Created',
  CompleteTask = 'Complete',
  ViewRespond = 'View Respond',
  MessageReceived = 'Message received',
  EmailReceived = 'Email received'
}

export enum TaskCompleteOptions {
  SpokeToCustomer = '1',
  LeftMessage = '2',
  BadPhoneNumber = '3',
  UnableToLeaveMessage = '4',
  Yes = '5',
  No = '6',
  UnreportedShow = '7',
  SentEmail = '8',
  SentMessage = '9',
  Responded = '10',
  NoResponseNeeded = '11',
  UnableToReachCustomer = '12'
}

export interface OpenTaskFormData {
  assignedUser: DictionaryItem | null
  dueDate: Date | null
  time: string | null
  description: string | null
  subject: DictionaryItem | null
  taskPriority: DictionaryItem | null
}


export enum LogType {
  Call = 1,
  'Store visit',
  Other
}

export interface ResponseOnAutoTaskFormData {
  taskCompleteOptionId: string | null
}

export interface RespondOnLeadTaskFormData extends ResponseOnAutoTaskFormData {
  workNotes: string | null
}

export interface UseLeadDetailsViewReturn {
  isActionsMenuOpen: boolean
  isActionsModalOpen: boolean
  isCarsMenuOpen: boolean
  lead: LeadFormData
  linkToWebsite: string
  leadPhoneNumber: string | null
  statuses: DictionaryItem[]
  temperatures: DictionaryItem[]
  sources: DictionaryItem[]
  onActionsMenuClose: (reason: ActionsMenuCloseReason) => void
  onActionsModalClose: () => void
  onActionsMenuOpen: () => void
  onAddCarClick: () => void
  onCarSelected: (carId: number) => void
  onCopy: (nameOfFiled: string) => void
  onDeleteCar: (carId: number) => void
  onGoBack: () => void
  onMakePrimaryCar: (carId: number) => void
  onUpdateLead: (changedField: LeadFormData) => Promise<void>
  onScheduleTaskButtonClick: (task: ScheduleButtonTaskType) => void
  onActivityTaskButtonClick: (task: ActivityButtonTaskType) => void
  onStatusUpdate: (leadStatusId: number) => void
  isLoading: boolean
  setWebChatInfo: (info: WebChatInfo | null) => void
  webChatInfo: WebChatInfo | null
  activityTasksProps: {
    logTypeId: LogType | null
    assignedTo: DictionaryItem[]
    reminderSubjects: DictionaryItem[]
    onCloseActivity: () => void
    onSubmitActivityLog: (values: LogActivityFormData, logTypeId: LogType) => void
  }
  onLeadLostToggleClick: () => Promise<void>
  leadTasksProps: {
    leadId: number | string
    leadTemperature: number
    leadTasks: TasksFormData[]
    assignedTo: DictionaryItem[]
    taskPriorities: DictionaryItem[]
    reminderSubjects: DictionaryItem[]
    isTaskListEmpty: boolean
    businessHours: [string, string]
    removeTask: (taskId: number | null) => void
    onSubmitTask: (taskId: number | null, values: OpenTaskFormData & { taskTypeId: number}) => Promise<void>
    onSubmitCompleteTask: (values: CompleteTaskFormData, taskId: number) => Promise<void>
    onSubmitRespondTask: (values: ResponseOnAutoTaskFormData, taskId: number) => Promise<void>
    onSubmitRespondOnLeadTask: (values: RespondOnLeadTaskFormData, taskId: number) => Promise<void>
    onViewEmailClick: (taskId: number) => Promise<void>
    onDeleteTask: (taskId: number) => Promise<void>
    onOpenViewChatPopover: () => void
  }
  leadEditProps: {
    isModalOpen: boolean
    onOpenModal: () => void
    onCloseModal: () => void
    onCancel: () => void
    onSubmit: () => Promise<void>
  }
  leadDeleteProps: {
    isModalOpen: boolean
    onOpenModal: () => void
    onCloseModal: () => void
    onCancel: () => void
    onSubmit: () => Promise<void>
  }
  leadLostProps: {
    isOpen: boolean
    onClose: () => void
    onSubmit: (isLost: boolean, lostReasonId: number | null) => Promise<void>
  }
  messagesProps: UseLeadMessagesReturn
  timelineProps: Omit<UseTimelineReturn, 'loadTimelines'>
  sendEmailProps: SendEmailProps
  onOpenChatMessage: () => Promise<void>
  dealProps: {
    isModalOpen: boolean
    onOpenModal: () => void
    onCloseModal: () => void
    dealState: string | null
  }
  onUpdatePauseOrResumeLead: () => void
  pausedByUserName: string | null
}

export interface UseLeadTasksProps {
  reminderSubjects: DictionaryItem[]
  leadTemperature: number
  leadTasks: TasksFormData[]
  leadTimelines: ItemProps[]
  leadId: string
  onViewEmailClick: (emailId: EntityId) => Promise<void>
}

export interface UseLeadTasksReturn {
  onScheduleTaskButtonClick: (taskTypeString: ScheduleButtonTaskType) => void
  onActivityTaskButtonClick: (taskTypeString: ActivityButtonTaskType) => void
  onCloseActivity: () => void
  leadTemperature: number
  isTaskListEmpty: boolean
  logTypeId: LogType | null
  leadTasks: TasksFormData[]
  removeTask: (taskId: number | null) => void
  updateTasks: (tasks: TasksFormData[]) => void
  onSubmitTask: (taskId: number | null, values: OpenTaskFormData & { taskTypeId: number }) => Promise<void>
  onSubmitActivityLog: (values: LogActivityFormData, logTypeId: LogType) => void
  onSubmitCompleteTask: (values: CompleteTaskFormData, taskId: number) => Promise<void>
  onSubmitRespondTask: (values: ResponseOnAutoTaskFormData, taskId: number) => Promise<void>
  onSubmitRespondOnLeadTask: (values: RespondOnLeadTaskFormData, taskId: number) => Promise<void>
  onViewEmailClick: (taskId: number) => Promise<void>
  onDeleteTask: (taskId: number) => Promise<void>
  setTasks: Dispatch<SetStateAction<TasksFormData[]>>
}

export type Attachment = VehicleImage | FileData | EmailAttachment

export interface AttachmentsInfo {
  attachments: Attachment[]
  totalSize: number
}

export interface PhotoAttachmentsProps {
  vehiclePhotoAttachments: VehicleImage[]
  vehicleVideoAttachments?: VehicleImage[]
  setAttachmentsInfo: Dispatch<SetStateAction<AttachmentsInfo>>
  setVehiclePhotoAttachments: Dispatch<SetStateAction<VehicleImage[]>>
  setVehicleVideoAttachments?: Dispatch<SetStateAction<VehicleImage[]>>
  onDeletePhoto: (id: string | number) => void
}
