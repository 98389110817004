import type { FC } from 'react'
import isNaN from 'lodash-es/isNaN'
import { cnx, formatCurrencyAccounting, CellWrapper } from '@carfluent/common'

import ConfigurableCellHOC from 'components/common/Table/cells/ConfigurableCellHOC'
import CLASS_NAME from './styles'

const AMOUNT_FORMAT_CONFIG = {
  allowNegative: true,
  emptyValues: '$0.00'
}

export interface AmountCellPropsHOCProps {
  className?: string
  isAlignedRight?: boolean
  isAbsNum?: boolean
}

interface AmountCellProps extends AmountCellPropsHOCProps {
  getValue: () => number | string
}

export const AmountCell: FC<AmountCellProps> = ({
  className,
  getValue,
  isAlignedRight = false,
  isAbsNum = false
}) => {
  const value = getValue?.()
  if (value != null) {
    const numberedValue = Number(value)
    const isValid = !isNaN(numberedValue)
    const num = isAbsNum ? Math.abs(numberedValue) : numberedValue
    const displayBalance = isValid ? formatCurrencyAccounting(num, AMOUNT_FORMAT_CONFIG) : null

    return (
      <CellWrapper className={cnx(CLASS_NAME, 'cell-amount', isAlignedRight && 'aligned-right', className)}>
        <p>{displayBalance}</p>
      </CellWrapper>
    )
  } else {
    return (
      <CellWrapper className={className}>
        <p />
      </CellWrapper>
    )
  }
}

export const AmountCellHOC = (hocProps: AmountCellPropsHOCProps) =>
  ConfigurableCellHOC(AmountCell, hocProps)

export default AmountCell
