import { type FC } from 'react'
import { Skeleton } from '@material-ui/lab'

import CLASS_NAME from './styles'

const AccountsSkeleton: FC = ({
}) => {
  return (
    <div className={CLASS_NAME}>
      <h6 className='line1'><Skeleton variant='text' /></h6>
      <h6 className='line2'><Skeleton variant='text' /></h6>
      <div className='line3'>
        <div className='left'><Skeleton variant='text' /></div>
        <div className='right'><Skeleton variant='text' /></div>
      </div>
      <div className='line4'><Skeleton variant='text' /></div>
      <div className='g-horizontal-line' />
      <div className='line3'>
        <div className='left'><Skeleton variant='text' /></div>
        <div className='right'><Skeleton variant='text' /></div>
      </div>
      <div className='line6'><Skeleton variant='text' /></div>
    </div>
  )
}

export default AccountsSkeleton
