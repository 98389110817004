import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

import CLASS_NAME from './styles'

export interface FeesSectionSkeletonProps {
  className?: string
}

const FeesSectionSkeleton: FC<FeesSectionSkeletonProps> = ({
  className
}) => {
  return (
    <div className={cnx(CLASS_NAME, className)}>
      <BlockSkeleton className='section-title' isLoading />

      <div className='fields'>
        <BlockSkeleton isLoading />

        <BlockSkeleton isLoading />

        <BlockSkeleton isLoading />

        <BlockSkeleton isLoading />
      </div>

      <BlockSkeleton className='section-title' isLoading />
    </div>
  )
}

export default FeesSectionSkeleton
