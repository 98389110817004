import { css } from '@emotion/css'

export default css(`
  text-decoration: underline;
  font-weight: 500;
  
  &:hover {
    cursor: pointer;
  }
`)
