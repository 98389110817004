import { css } from '@emotion/css'

export default css(`
  > * {
    width: 100%;
    height: 100%;
  }
`)

export const overlayContentStyles = (minWidth: number, maxWidth: number): string => {
  return css(`
    min-width: ${minWidth}px;
    max-width: ${maxWidth}px;
    margin: 0 auto;
  `)
}
