import { type FC } from 'react'

const IconPause: FC = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='32' height='32' rx='16' fill='#4C4C4C' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.875 9.25391H14.125C14.5375 9.25391 14.875 9.59141 14.875 10.0039V22.0039C14.875 22.4164 14.5375 22.7539 14.125 22.7539H11.875C11.4625 22.7539 11.125 22.4164 11.125 22.0039V10.0039C11.125 9.59141 11.4625 9.25391 11.875 9.25391ZM17.875 9.25391H20.125C20.5375 9.25391 20.875 9.59141 20.875 10.0039V22.0039C20.875 22.4164 20.5375 22.7539 20.125 22.7539H17.875C17.4625 22.7539 17.125 22.4164 17.125 22.0039V10.0039C17.125 9.59141 17.4625 9.25391 17.875 9.25391Z'
        fill='white'
      />
    </svg>
  )
}

export default IconPause
