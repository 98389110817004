import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import type { DropResult } from 'react-beautiful-dnd'
import { type Result, getEnhancedResultHandler, isOk, useForm, useModal } from '@carfluent/common'
import axios from 'axios'

import { type KeyVal } from 'types'
import { type DocumentFormDto, type DocumentFormPackDto } from 'api/types'
import GlobalUICTX from 'store/global_ui'
import { NOT_FOUND_STATUS, Routes } from 'constants/route_helper'
import useAsyncEffect from 'hooks/useAsyncEffect'
import DocumentsApiProvider from 'api/documents.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useTransitionBlocker from 'hooks/useTransitionBlocker'

import { type UseExactPacksListReturn } from './types'
import serializeData from './serializer'
import validationRules from './validator'
import { DEFAULT_FORM_DATA, Messages } from './constants'

const useExactPacks = (): UseExactPacksListReturn => {
  const { showAlert } = useCustomSnackbar()
  const { id: packId } = useParams()
  const navigate = useNavigate()
  const { isLoading: isGlobalLoading } = useContext(GlobalUICTX)

  const [isFormLoading, setIsFormLoading] = useState(true)
  const [apiErrors, setAPIErrors] = useState<KeyVal | null>(null)
  const refFormPack = useRef(DEFAULT_FORM_DATA)

  const { isModalOpen, onOpenModal, onCloseModal } = useModal()

  const {
    isModalOpen: isDeleteFormModalOpen,
    onOpenModal: onOpenDeleteFormModal,
    onCloseModal: onCloseDeleteFormModal
  } = useModal()

  const {
    isModalOpen: isUnsavedChangesOpen,
    onOpenModal: onOpenUnsavedChangesModal,
    onCloseModal: onCloseUnsavedChangesModal
  } = useModal()

  const onActionResult = useMemo(() => {
    const action = (res: Result<string>, resetForm: (data: DocumentFormPackDto) => void): void => {
      if (isOk<string>(res)) {
        resetForm(refFormPack.current)
      } else if (axios.isAxiosError(res.result)) {
        setAPIErrors(res.result?.response?.data ?? null)
      }
    }

    return getEnhancedResultHandler<DocumentFormPackDto, never, string>(action, showAlert, undefined, Messages.SuccessMessage)
  }, [showAlert])

  const submitAction = useCallback(async (data: DocumentFormPackDto): Promise<void> => {
    await DocumentsApiProvider.patchFormPack(serializeData(data))
    refFormPack.current = data
  }, [])

  const form = useForm<DocumentFormPackDto, never>({
    submitAction,
    onActionResult,
    validationRules,
    baseValues: DEFAULT_FORM_DATA
  })

  const {
    values,
    errors,
    onBlur,
    touched,
    onChange,
    onSubmit,
    resetForm,
    isSubmitting,
    isValid,
    setFieldError,
    setFieldTouched,
    isFormChanged: _isFormChanged
  } = form

  const isFormChanged = _isFormChanged()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onClose = (): void => {
    navigate(Routes.SettingsFormPacks)
  }

  const onDeletePack = async (): Promise<void> => {
    try {
      await DocumentsApiProvider.deleteFormPack(packId ?? '')
      resetForm()
      showAlert(Messages.FormSuccessfullyDeletedMessage, { variant: 'success' })
      onClose()
    } catch (e: any) {
      showAlert(e.message)
    }
  }

  const onAddForms = (forms: DocumentFormDto[]): void => {
    onChange('forms', [...(values.forms ?? []), ...forms])
    setFieldTouched('forms', true)
    onCloseModal()
  }

  const onDeleteForm = (idx: number): void => {
    const newForms = [...(values.forms ?? [])]
    newForms.splice(idx, 1)
    onChange('forms', newForms.map((el, index) => ({ ...el, order: index })))
  }

  const onDragEnd = useCallback((result: DropResult): void => {
    if (result.destination == null || result.source.index === result.destination.index) {
      return
    }

    const items = [...values.forms ?? []]
    const draggedItem = items[result.source.index]
    const destinationIndex = result.destination.index

    items.splice(result.source.index, 1)

    items.splice(destinationIndex, 0, draggedItem)

    const reorderedItems = items.map((item, index) => ({
      ...item,
      order: index
    })).sort((a, b) => a.order - b.order)

    onChange('forms', reorderedItems)
  }, [onChange, setFieldTouched, values.forms])

  // =========================================== //
  //           UNSAVED CHANGES MODAL             //
  // =========================================== //

  const { proceedTransition } = useTransitionBlocker({
    shouldBlock: isFormChanged,
    onBlock: onOpenUnsavedChangesModal
  })

  const onDontSaveAndCloseForm = useCallback(async () => {
    onCloseModal()
    proceedTransition()
  }, [onCloseModal, proceedTransition])

  const onSaveAndCloseForm = useCallback(async (): Promise<void> => {
    if (!isValid) {
      onCloseUnsavedChangesModal()
      return
    }

    await onSubmit()
    proceedTransition()
    onCloseModal()
  }, [
    onCloseModal,
    onCloseUnsavedChangesModal,
    onSubmit,
    proceedTransition,
    isValid
  ])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useAsyncEffect(async () => {
    setIsFormLoading(true)
    try {
      const res = await DocumentsApiProvider.getFormPack(packId ?? '')
      refFormPack.current = res
      resetForm(res)
    } catch (e) {
      if (axios.isAxiosError(e) && e?.response?.status === NOT_FOUND_STATUS) {
        navigate(Routes.SettingsFormPacks)
      }
    } finally {
      setIsFormLoading(false)
    }
  }, [])

  useEffect(() => {
    if (apiErrors != null) {
      setFieldError('name', apiErrors.message)
    }
  }, [apiErrors, setFieldError])

  useEffect(() => {
    if (isGlobalLoading) {
      proceedTransition()
      resetForm(refFormPack.current)
    }
  }, [isGlobalLoading, proceedTransition])

  // ========================================== //

  return {
    onClose,
    onSubmit,
    onDragEnd,
    onDeletePack,
    onDeleteForm,
    isSubmitting,
    isFormChanged,
    formProps: {
      values,
      errors,
      onBlur,
      touched,
      onChange,
      onAddForms,
      isModalOpen,
      onOpenModal,
      onCloseModal
    },
    isFormLoading,
    onSaveAndCloseForm,
    rows: values.forms,
    isUnsavedChangesOpen,
    isDeleteFormModalOpen,
    onOpenDeleteFormModal,
    onCloseDeleteFormModal,
    onDontSaveAndCloseForm,
    formPack: refFormPack.current,
    onCloseUnsavedChangesModal
  }
}

export default useExactPacks
