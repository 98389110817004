import { css } from '@emotion/css'

export default css`
  min-width: 200px;
  max-width: 348px;
  width: 100%;

  @media screen and (min-width: 1600px) {
    min-width: 348px;
  }

  &.non-editable {
    padding-left: 12px;
  }

  .cf-select-display-container {
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    height: 48px;

    .cf-display, .cf-display-adornment {
      padding: 11px 0;
    }
    
    .cf-display-label {
      top: 11px;
    }
  }
`

export const POPOVER_CLASS_NAME = css`
  .render-option {
    display: flex;
    justify-content: space-between;

    .option-content {
      flex-direction: column;
      width: calc(100% - 32px);
    }
    
    .display-value {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    p {
      color: #101010;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
    }

    span {
      color: rgba(33, 33, 33, 0.50);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
`
