import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;
  font-weight: 400;

  .info-label {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #21212180;
    margin-bottom: 4px;
  }

  .info-value {
    display: flex;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #101010;
    flex-direction: row;
    align-items: center;

    &-width { max-width: 270px; }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .copy-button {
    margin-left: 9px;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
`)
