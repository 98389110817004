import { useForm } from '@carfluent/common'

import {
  type TransactionFormData,
  type TransactionFormErrTouchShortcuts,
  type UseTransactionFormReturn
} from 'types'

import useValidation from './useValidation'
import { DEPENDENT_VALIDATIONS } from './useValidation/constants'
import { type UseTransactionFormProps } from './types'

const useTransactionForm = ({
  baseValues,
  isRecurring,
  transactionTypeId
}: UseTransactionFormProps): UseTransactionFormReturn => {
  const validationRules = useValidation({
    transactionTypeId,
    isRecurring
  })

  return useForm<TransactionFormData, TransactionFormErrTouchShortcuts>({
    baseValues,
    dependentValidations: DEPENDENT_VALIDATIONS,
    validationRules
  })
}

export default useTransactionForm
