import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import Divider from 'components/common/Divider'
import formattedValue from 'utils/formatters/formatDealRecapValue'
import getBackGrossAmount from 'utils/deals/priceHelpers/getBackGrossAmount'

import { type DealRecapBackGrossProps } from './types'
import CLASS_NAME from './styles'

const DealRecapBackGross: FC<DealRecapBackGrossProps> = ({
  acquisitionFee,
  className,
  dealRecapData,
  isForModal = true
}) => {
  const backGross = (acquisitionFee === undefined)
    ? dealRecapData?.backGross
    : getBackGrossAmount(
      dealRecapData?.backGross ?? null,
      dealRecapData?.acquisitionFee ?? null,
      acquisitionFee
    )

  return (
    <div className={cnx(CLASS_NAME, 'deal-recap-back-gross', className)}>
      <div className='back-gross-rows'>
        {(dealRecapData?.coverages ?? []).map((item) => {
          return (
            <div className='row' key={`${item?.name ?? ''}-${item?.netProfit ?? ''}`}>
              <div className='item-label'>{item.name}</div>
              <div className='item-value'>{formattedValue(item.netProfit)}</div>
            </div>
          )
        })}

        <div className='row'>
          <div className='item-label'>Buy rate</div>
          <div className='item-value'>
            {dealRecapData?.buyRate != null ? `${dealRecapData.buyRate}%` : '-'}
          </div>
        </div>

        <div className='row'>
          <div className='item-label'>Reserve</div>
          <div className='item-value'>{formattedValue(dealRecapData?.reserve)}</div>
        </div>

        <div className='row'>
          <div className='item-label'>Acquisition fee</div>
          <div className='item-value'>{formattedValue(acquisitionFee ?? dealRecapData?.acquisitionFee)}</div>
        </div>

        {isForModal && (
          <>
            <Divider/>

            <div className='row back-gross'>
              <div className='item-label'>Back Gross</div>
              <div className='item-value'>{formattedValue(backGross)}</div>
            </div>

            <Divider/>
          </>
        )}
      </div>

      {isForModal && (
        <div className='back-gross-rows bottom-rows'>
          <div className='row'>
            <div className='item-label bold'>Total Gross</div>
            <div className='item-value bold'>{formattedValue(dealRecapData?.totalGross)}</div>
          </div>
        </div>
      )}

      {!isForModal && (
        <div className='back-gross-rows'>
          {!isForModal && <Divider />}

          <div className='row back-gross'>
            <div className='item-label bold'>Back Gross</div>
            <div className='item-value bold'>{formattedValue(backGross)}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DealRecapBackGross
