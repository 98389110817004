import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  padding-left: var(--content-margin-small);
  margin-top: var(--content-margin-small);
  border-left: 1px solid rgba(0, 0, 0, 0.12);

  .form-actions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: var(--content-margin-default);

    .cf-button-content {
      text-transform: uppercase;
      letter-spacing: 1.25px;
      font-weight: 500; 
    }
    
    button:last-of-type { margin-left: var(--content-margin-small); }
  }

  .cf-input-container {
    display: flex;
    flex-direction: row;
    margin-top: var(--content-margin-default);
    column-gap: var(--content-margin-small);

    .cf-form-field { flex: 1; }
  }

  .reply-person {
    text-align: left;
    padding-bottom: var(--content-margin-small);

    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #101010;
    }
  }
  
  .email-thread-attachments { margin-bottom: var(--content-margin-small); }

  .cf-field-input,
  .cf-field-dropdown {
    margin-bottom: var(--content-margin-small);
  }
`)

export const EDITOR_STYLES = css(`
  margin: var(--content-margin-small) 0;

  .text-editor-container { min-height: 300px; }

  .text-editor-toolbar {
    padding: var(--content-margin-small);
    padding-left: 20px;
    margin-bottom: var(--content-margin-small);

    .rdw-block-dropdown, .rdw-fontfamily-dropdown { width: 142px; }

    .rdw-dropdown-selectedtext { font-size: 12px; }

    .rdw-dropdown-carettoopen,
    .rdw-dropdown-carettoclose {
      right: 0;
    }

    .rdw-option-wrapper[title="Bold"] img { transform: scale(1.3); }

    .togglable-toolbar-button { margin-left: auto; }
  }
`)
