import { useState, useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import {
  defer,
  useModal,
  useMediator,
  useRefUpdater,
  useSubscribe
} from '@carfluent/common'

import {
  DealAccountingState,
  DealActions,
  VehicleState,
  PaymentMethod,
  WorkflowStateId,
  PaymentTypeId,
  WorkflowStepId
} from 'api/types'

import { type DealModel } from 'api/types/responses'
import { type TemplateIds } from 'types'
import DocumentsApiProvider from 'api/documents.api'
import CustomersCoreApiProvider from 'api/customersCore.api'
import CustomerApiProvider from 'api/customer.api'
import VehiclesApiProvider from 'api/vehicles.api'
import { DealState } from 'constants/names'
import Events from 'constants/events'
import CalcRoutes from 'constants/route_helper'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useUnsoldDialog from 'hooks/deals/useUnsoldDialog'
import { isPushToAccountingHidden2 } from 'utils/deals/isPushToAccountingHidden'
import isGenerateTransactionHidden from 'utils/deals/isGenerateTransactionHidden'
import { isDeal2Completed as _isDealCompleted } from 'utils/deals/isDealCompleted'
import { isDeal2WaitingForCreditAppApprove as _isDealWaitingForCreditAppApprove } from 'utils/deals/isDealWaitingForCreditAppApprove'
import { isDeal2WaitingForCreditAppSubmit as _isDealWaitingForCreditAppSubmit } from 'utils/deals/isDealWaitingForCreditAppSubmit'
import { isDeal2WaitingForDocsSelection as _isDealWaitingForDocsSelection } from 'utils/deals/isDealWaitingForDocsSelection'
import getResponseErrorMessage from 'utils/getResponseErrorMessage'

import {
  type ActionOptionsWithOrder,
  type UseDealViewBannersAndModalsProps,
  type UseDealViewBannersAndModalsReturn
} from './types'

const CUSTOMER_COVERAGES_PAGE = '/deal/IndependentServiceAndProtection'

export enum ActionTitles {
  SubmitCreditApplication = 'Submit credit application',
  ViewCreditApplication = 'View credit application',
  DisplayFI = 'Display F&I',
  RequestDocuments = 'Request documents',
  ReceiveDownPayment = 'Receive down payment',
  ReceiveDeposit = 'Receive deposit',
  DealRecap = 'Deal recap',
  CancelDeal = 'Cancel deal',
  ViewLead = 'Go to lead',
  GenerateTransaction = 'Generate journal entry',
  LenderDecisions = 'Show lender decisions',
  PushToAccounting = 'Push to accounting',
  MarkDealAsSold = 'Mark deal as Sold',
  UnmarkDealAsSold = 'Unmark deal as Sold',
}

const UPDATE_STATUS_DEAL_SUCCESS = 'Deal status was successfully updated'
const UNMARKED_STATUS_DEAL_SUCCESS = 'Deal unmarked as Sold successfully'

const useDealViewBannersAndModals = ({
  deal,
  dealId,
  depositedCarId,
  vehicleState,
  onLoadDeal,
  onApproveDeal: _onApproveDeal,
  onSelectDealFormsTab,
  onUpdateDeal
}: UseDealViewBannersAndModalsProps): UseDealViewBannersAndModalsReturn => {
  const navigate = useNavigate()
  const { showAlert, showSuccess } = useCustomSnackbar()
  const { send } = useMediator()

  const deleteDealModalProps = useModal()
  const lenderDecisionsModalProps = useModal()
  const requestDocumentsModalProps = useModal()
  const paymentFormModalProps = useModal()
  const selectFormsModalProps = useModal()
  const viewTransactionModalProps = useModal()
  const warrantyModalProps = useModal()
  const viewGeneratedTransactionModalProps = useModal()
  const [isCannotMakeDealUnsoldModalOpen, setIsCannotMakeDealUnsoldModalOpen] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null)

  const dealAccountingState = deal?.dealAccountingStateId ?? null
  const isDealComplete = _isDealCompleted(deal)
  const isDealCancel = deal?.dealStateId === WorkflowStateId.Canceled
  const isDeleteDealButtonDisabled = isDealComplete || isDealCancel

  const isDealWaitingForCreditAppApprove = _isDealWaitingForCreditAppApprove(deal)
  const isDealWaitingForCreditAppSubmit = _isDealWaitingForCreditAppSubmit(deal)
  const isDealWaitingForDocsSelection = _isDealWaitingForDocsSelection(deal)

  const isCarDeposited = depositedCarId != null
  const isCarDeleted = vehicleState === VehicleState.Deleted
  const isCarAlreadySold = vehicleState === VehicleState.Sold && !isDealComplete
  const signaturePending = deal?.nextAction != null && deal.nextAction === DealActions.Sign
  const isFinancingPayment = deal?.dealFinanceTypeId === PaymentTypeId.Finance
  const isLenderDecisionsExists = deal?.creditApplication != null && deal?.creditApplication.financingApplicationLenderDecisionDetails.length > 0
  const isLinkedLead = deal?.leadId != null

  const isCompletedDealerCheck = (
    deal?.dealSteps?.find((item) => item.templateStepId === WorkflowStepId.DealerCheck)
      ?.dealStepStateId === WorkflowStateId.Canceled
  )

  const refRowVersion = useRefUpdater(deal?.rowVersion)
  const refWarrantiesSubmitted = useRef(defer<boolean>())

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const getHandlerWithTabSave = (doJob: () => Promise<void>) => async () => {
    try {
      const result: boolean[] = await send(Events.DealSaveRequested, null)
      const isSaved = result.every(Boolean)

      if (isSaved) {
        await doJob()
      }
    } catch (err) {
      // pass
    }
  }

  const onViewTransaction = (): void => {
    if (deal?.recapTransactionId != null) {
      viewTransactionModalProps.onOpenModal()
    }
  }

  const onRefreshDeal = useCallback((): void => {
    void onLoadDeal(dealId)
  }, [dealId, onLoadDeal])

  const onGoBack = (): void => navigate(-1)

  const onPushToAccounting = (): void => {
    const doJob = getHandlerWithTabSave(async (): Promise<void> => {
      try {
        setIsLoading(true)
        await CustomersCoreApiProvider.setDealAccountingStatus(dealId, refRowVersion.current ?? '', DealAccountingState.PushToAccounting)
        await onLoadDeal(dealId)
      } catch (e) {
        showAlert(e)
      } finally {
        setIsLoading(false)
      }
    })

    void doJob()
  }

  const makeDealUnsold = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await CustomersCoreApiProvider.setDealStatus(dealId, DealState.Pending)
      onRefreshDeal()
      showSuccess(UNMARKED_STATUS_DEAL_SUCCESS)
    } catch (e) {
      showAlert(e)
    } finally {
      setIsLoading(false)
    }
  }

  const unsoldRecordDialogProps = useUnsoldDialog({ onSubmit: makeDealUnsold })
  const unsoldDialogProps = useUnsoldDialog({ onSubmit: makeDealUnsold, agreedTogglerDefault: true })

  const onUnmarkDealAsSold = (): void => {
    const doJob = getHandlerWithTabSave(async (): Promise<void> => {
      /**
       * 1. Show a 'cannot unmark' modal if the trade-in car has an associated created cost.
       * 2. Display an 'unmark' modal with a checkbox if the trade-in car does not have a created cost
       * but a journal entry was made.
       * 3. Trigger a standard 'unmark' modal in cases where the deal was marked as sold and there's an added trade-in car.
       * 4. Simply unmark the deal without showing any modal if none of the above conditions are met.
       */
      let canBeDeleted = true

      if (deal?.tradeInDetails.inventoryVehicleId !== 0) {
        canBeDeleted = await VehiclesApiProvider.checkIfVehicleCanBeDeleted(deal?.tradeInDetails.inventoryVehicleId ?? 0)
      }

      if (deal?.tradeInDetails.inventoryVehicleId !== 0 && deal?.tradeInDetails.id !== 0 && !canBeDeleted) {
        setIsCannotMakeDealUnsoldModalOpen(true)
        return
      }

      if (deal?.recapTransactionId != null) {
        unsoldRecordDialogProps.onOpen()
        return
      }

      if (deal?.tradeInDetails.inventoryVehicleId !== 0 && deal?.tradeInDetails.id !== 0 && canBeDeleted) {
        unsoldDialogProps.onOpen()
        return
      }

      await makeDealUnsold()
      onRefreshDeal()
    })

    void doJob()
  }

  const onApproveDeal = async (templatesIds: TemplateIds): Promise<void> => {
    try {
      await DocumentsApiProvider.patchDocumentDealForms(dealId, templatesIds)
      await CustomersCoreApiProvider.dealCheck(dealId)
      await onLoadDeal(dealId)

      _onApproveDeal()
      showSuccess('Deal approved successfully.')
    } catch {
      showAlert('Deal approval failed')
    }
  }

  const onMarkDealAsSold = (): void => {
    const doJob = getHandlerWithTabSave(async (): Promise<void> => {
      const state = isDealComplete ? DealState.Pending : DealState.Completed
      setIsLoading(true)
      try {
        await CustomersCoreApiProvider.setDealStatus(dealId, state)
        onRefreshDeal()
        showSuccess(UPDATE_STATUS_DEAL_SUCCESS)
      } catch (e) {
        showAlert(e)
      } finally {
        setIsLoading(false)
      }
    })

    void doJob()
  }

  const onDeleteDeal = async (dealId: string | number): Promise<void> => {
    try {
      await CustomersCoreApiProvider.setDealStatus(dealId, DealState.Canceled)
      onRefreshDeal()
    } catch (e) {
      showAlert(getResponseErrorMessage(e, 'Deal deletion failed'))
    }
  }

  const onViewDealOnDeposit = (): void => {
    if (isCarDeposited) {
      navigate(CalcRoutes.DealView(depositedCarId))
    }
  }

  const onCloseCannotMakeDealUnsoldModal = (): void => setIsCannotMakeDealUnsoldModalOpen(false)

  const onSubmitWarranties = (deal: DealModel): void => {
    const doJob = async (): Promise<void> => {
      onUpdateDeal(deal)
      refWarrantiesSubmitted.current.resolve(true)
    }

    void doJob()
  }

  const redirectToCoverage = useCallback(async () => {
    const redirectLink: string | null = await CustomerApiProvider.getCustomerCoveragesUrl(dealId)

    if (redirectLink == null) {
      return
    }

    const { origin, search } = new URL(redirectLink)
    const { token } = queryString.parse(search)
    const searchParams = encodeURIComponent(`accessToken=${token as string}&dealId=${dealId}&rowVersion=${deal?.rowVersion ?? ''}`)
    const redirectUrl = `${origin}${CUSTOMER_COVERAGES_PAGE}?${searchParams}`

    window.open(redirectUrl, '_blank')
  }, [dealId, deal?.rowVersion])

  const onOpenSelectTemplateModal = getHandlerWithTabSave(async (): Promise<void> => {
    selectFormsModalProps.onOpenModal()
  })

  const onSignPaperwork = getHandlerWithTabSave(async (): Promise<void> => {
    onSelectDealFormsTab()
    setTimeout(() => {
      void send(Events.DealShowSignForm, null)
    }, 500)
  })

  /**
   * order shows relative position in the list of actions
   */
  const actionOptionList: ActionOptionsWithOrder[] = [
    {
      title: deal?.creditApplication?.submittedDate != null
        ? ActionTitles.ViewCreditApplication
        : ActionTitles.SubmitCreditApplication,
      handleOnClick: () => navigate(CalcRoutes.CreditApplication(dealId)),
      hidden: !isFinancingPayment,
      order: 1
    },
    {
      title: ActionTitles.LenderDecisions,
      disabled: !isLenderDecisionsExists,
      hidden: !isFinancingPayment || deal?.creditApplication?.submittedDate === null,
      handleOnClick: () => lenderDecisionsModalProps.onOpenModal(),
      order: 2
    },
    {
      title: isDealComplete ? ActionTitles.UnmarkDealAsSold : ActionTitles.MarkDealAsSold,
      handleOnClick: isDealComplete ? onUnmarkDealAsSold : onMarkDealAsSold,
      optionType: isDealComplete ? 'highlighted' : undefined,
      order: isDealComplete ? 12 : 3
    },
    {
      title: ActionTitles.ViewLead,
      hidden: !isLinkedLead,
      handleOnClick: () => navigate(CalcRoutes.LeadView(deal?.leadId ?? '')),
      order: 6
    },
    {
      title: ActionTitles.PushToAccounting,
      hidden: isPushToAccountingHidden2(deal?.dealStateId ?? null, dealAccountingState),
      handleOnClick: onPushToAccounting,
      order: 5
    },
    {
      title: ActionTitles.GenerateTransaction,
      hidden: isGenerateTransactionHidden(dealAccountingState),
      handleOnClick: () => {
        const doJob = getHandlerWithTabSave(async (): Promise<void> => {
          viewGeneratedTransactionModalProps.onOpenModal()
        })

        void doJob()
      },
      order: 4
    },
    {
      title: ActionTitles.DisplayFI,
      handleOnClick: redirectToCoverage,
      disabled: isDealComplete || isDealCancel,
      order: 7
    },
    {
      title: ActionTitles.RequestDocuments,
      handleOnClick: requestDocumentsModalProps.onOpenModal,
      order: 8
    },
    {
      title: ActionTitles.ReceiveDeposit,
      handleOnClick: () => {
        const doJob = getHandlerWithTabSave(async (): Promise<void> => {
          setPaymentMethod(PaymentMethod.Deposit)
          paymentFormModalProps.onOpenModal()
        })

        void doJob()
      },
      order: 9
    },
    {
      title: ActionTitles.ReceiveDownPayment,
      hidden: !isFinancingPayment,
      handleOnClick: () => {
        const doJob = getHandlerWithTabSave(async (): Promise<void> => {
          setPaymentMethod(PaymentMethod.DownPayment)
          paymentFormModalProps.onOpenModal()
        })

        void doJob()
      },
      order: 10
    },
    {
      title: ActionTitles.CancelDeal,
      handleOnClick: deleteDealModalProps.onOpenModal,
      disabled: isDeleteDealButtonDisabled,
      tooltip: isDeleteDealButtonDisabled
        ? isDealCancel
          ? 'Already canceled.'
          : 'You cannot cancel a deal as it has already been marked as sold.'
        : '',
      placement: isDealCancel ? 'top-start' : 'top-end',
      optionType: 'highlighted',
      order: 13
    }
  ]

  actionOptionList.sort((a, b) => a.order - b.order)

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useSubscribe(Events.DealShowWarranties, async () => {
    refWarrantiesSubmitted.current = defer<boolean>()
    warrantyModalProps.onOpenModal()
    return await refWarrantiesSubmitted.current.promise // will be resolved by `onSubmitWarranties`
  })

  // ========================================== //

  return {
    actions: actionOptionList,
    deleteDealModalProps,
    isDealWaitingForCreditAppApprove,
    isDealWaitingForCreditAppSubmit,
    isDealWaitingForDocsSelection,
    isCarDeposited,
    isCarDeleted,
    isCarAlreadySold,
    isCannotMakeDealUnsoldModalOpen,
    isCompletedDealerCheck,
    isLoading,
    onApproveDeal,
    onCloseCannotMakeDealUnsoldModal,
    onDeleteDeal,
    onGoBack,
    onOpenSelectTemplateModal,
    onRefreshDeal,
    onSignPaperwork,
    onSubmitWarranties,
    onViewDealOnDeposit,
    onViewTransaction,
    lenderDecisionsModalProps,
    paymentFormModalProps,
    paymentMethod,
    requestDocumentsModalProps,
    selectFormsModalProps,
    signaturePending,
    unsoldRecordDialogProps,
    unsoldDialogProps,
    viewGeneratedTransactionModalProps,
    viewTransactionModalProps,
    warrantyModalProps,
    redirectToCoverage
  }
}

export default useDealViewBannersAndModals
