import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 32px;

  .cf-list-of-items {
    flex: 1;
    overflow: hidden;
  }

  .list-title {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .list {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .item {
      display: flex;
      width: 100%;
      background: #F3F3F3;
      border-radius: 2px;
      padding: 10px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      justify-content: space-between;
      text-transform: capitalize;

      &-name {
        display: flex;
        font-weight: 500;
        align-items: center;
        font-size: 16px;
        gap: 10px;
        flex: 2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
      }

      &-info-container {
        display: flex;
        gap: 24px;
        margin-right: 24px;
        flex: 1;

        .item-name {
          font-size: 14px;
        }

        &.closed {
          cursor: default;
          pointer-events: none;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.38);
        }

        &.year {
          font-size: 16px;
        }

      }

      &-closed-time {
        font-size: 14px;
      }
      
      
      &-action {

        &-open {
          position: relative;
          font-size: 16px;
        }

        &-lock {
          text-decoration: underline;
          pointer-events: auto;
          font-size: 12px;
        }
      }

      &.closed {
        color: rgba(33, 33, 33, 0.40);

        .item-action-lock {
          color: #000;
        }
      }

      .cf-icon-lock {
        color: #000;
      }

      &.active {
        background: #FFFFFF;
        border: 1px solid #C99B86;
        border-radius: 2px;
        padding: 9px 15px;
      }

      &:hover {
        background: #FAFAFA;
        cursor: pointer;
      }
    }
  }

  .cf-close-year-btn {
    width: 100%;
    background: #F3F3F3;
    border-radius: 2px;
    padding: 8px 16px;
    text-align: center;
    margin: 8px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-decoration-line: underline;
    color: rgba(0, 0, 0, 0.38);
    cursor: none;
    pointer-events: none;
    
    &.active {
      color: #000;
      cursor: pointer;
      pointer-events: auto;
    }
  }

  .cf-skeleton-left-part {
    width: calc(75% - 48px);
    padding-right: 48px;
  }

  .cf-skeleton-right-part {
    width: 25%;
  }

  *::-webkit-scrollbar-thumb {
    background: rgb(16 16 16 / 20%);
    border-radius: 3px;
  }
`)
