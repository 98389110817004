import { css } from '@emotion/css'

export default css(`
  --icon-width: 24px;
  --margin-left-icon: 10px;
  
  margin-bottom: 16px;
  max-width: 100%;

  .timeline-item-header {
    display: flex;
    justify-content: space-between;
  }

  .timeline-item-date {
    width: 65px;

    @media (min-width: 1280px) {
      width: 125px;
    }
  }

  &.inspection-container {
    .timeline-item-title {
      font-weight: 500;
      align-items: center;
    }

    .timeline-item-header {
      margin-bottom: 4px;
      align-items: center;
    }

    .tooltip-content {
      font-size: 14px;
    }

    .cf-button-variant-text {
      height: 20px;
    }

    .cf-button-content {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }

  &.unlock-inspection-record {
    .timeline-item-header .timeline-item-title {
      display: inline-block;
      font-size: 14px;
      text-align: left;
      line-height: 20px;
      color: #21212180;
      column-gap: initial;
      letter-spacing: 0.25px;
      max-width: 100%;
      text-wrap: wrap;
  
      span {
        font-weight: 500;
        letter-spacing: 0.4px;
      }
    }
  }

  .timeline-item-header {
    display: grid;
    column-gap: 8px;
    grid-template-columns: auto auto;
    align-items: flex-start;
    margin-bottom: 16px;

    .timeline-item-title {
      &.changed-step {
        display: inline;
        color: #21212180;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;

        span {
          font-weight: 500;
        }
      }
    }

    p.timeline-item-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }

    div.timeline-item-title {
      display: grid;
      grid-template-columns: var(--icon-width) minmax(0px, auto);
      column-gap: 8px;
      text-wrap: nowrap;
    }

    .timeline-item-date {
      min-width: 60px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: rgba(33, 33, 33, 0.5);
      text-align: right;
    }
  }

  .inspection-content {
    margin-left: 12px;
    padding-left: 20px;
    border-left: 2px solid rgba(0, 0, 0, 0.12);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;

    > p {
      margin: 4px 0;
      color: #21212180;

      span {
        color: #101010;
      }
    }

    .show-btn-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 4px;

      button {
        font-size: 12px;
        letter-spacing: 0.4px;
        text-decoration: underline;
        text-underline-offset: 1px;
        padding-right: 0;
        padding-left: 0;
        background: none;
      }
    }
  }

  .comment-content {
    margin-left: 12px;
    padding-left: 20px;
    border-left: 2px solid rgba(0, 0, 0, 0.12);

    p, span {
      font-size: 14px;
    }
    
    .tooltip-container {
      width: calc(100% - var(--icon-width));

      p {
        width: calc(100% - var(--margin-left-icon));
      }
    }
  }
`)
