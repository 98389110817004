import type { SortingInfo } from '@carfluent/common/dist/UI'

export const TABLE_MIN_HEIGHT = 960
export const ROW_HEIGHT = 156
export const ROW_GAP = 8

export const DEFAULT_FILTERS = {
  isFavorite: null
}

export const DEFAULT_SORTING: SortingInfo = { id: 'step', order: 'asc' }

export const EMPTY_MESSAGE = 'No reconditioned vehicles found'

export const EXPORT_XLS_PAGE_SIZE = 30

export const TABLE_WRAPPER_UNIQUE_CLASSNAME = '__RECON_TABLE_WRAPPER_UNIQUE_CLASSNAME__'
