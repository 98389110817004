// AZ-TODO: move to Common Library

import { type FC, type ChangeEvent, useCallback, useRef, memo, type ReactNode } from 'react'
import kebabCase from 'lodash-es/kebabCase'
import {
  type FormCompatible,
  type DropZoneProps,
  type FileFilterData,
  type FilterFilesConfig,
  DropZone,
  Loader,
  cnx,
  utils
} from '@carfluent/common'

import IconSVGDocument from './icons/IconSVGDocument'
import IconSVGCross from './icons/IconSVGCross'
import CLASS_NAME from './styles'
import getUniqueName from 'utils/getUniqueName'

const INPUT_STYLES = { display: 'none' }

export interface EntityWithName { name: string } // AZ-NOTE: instead of `File`, for less restrictions
export type FormFilesUploadValue = EntityWithName[]
export type PropsToHide = 'onAddFiles'

export type FormFilesUploadProps = Omit<FormCompatible<DropZoneProps, FormFilesUploadValue>, PropsToHide> & {
  dataTestId?: string
  disabled?: boolean // AZ-TODO: move to DropZoneProps
  isLoading?: boolean
  isSingleFile?: boolean
  onChange: (id: string, value: EntityWithName[]) => void
  onError?: (error?: string) => void
  suggestedExtensions?: string[]
  children?: ReactNode
  additionalInfo?: ReactNode
}

const FormFilesUpload: FC<FormFilesUploadProps> = ({
  dataTestId: _dataTestId,
  disabled = false,
  error,
  errorMessage = (typeof error === 'string' ? error : null),
  id,
  isLoading = false,
  isSingleFile = false,
  onChange,
  showError: _showError,
  suggestedExtensions,
  dragHereMessage,
  touched,
  value,
  children,
  onError,
  additionalInfo,
  ...restProps
}) => {
  const dataTestId = _dataTestId ?? kebabCase(id)
  const showError = _showError ?? (!disabled && Boolean(touched) && Boolean(error))
  const hasErrorMessage = errorMessage !== '' && errorMessage != null
  const { acceptedExtensions } = restProps
  const acceptedMimes = (suggestedExtensions ?? acceptedExtensions)?.map(mimeFileExtensionsToMIME).join(', ')
  const refInput = useRef<HTMLInputElement>(null)

  const config: FilterFilesConfig = {
    ...restProps,
    currentAttachments: (value ?? []).length,
    maxFileNumber: isSingleFile ? 2 : restProps.maxFileNumber // AZ-NOTE: `2` - to not skip next addings after we added 1st file
  }

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onAddFiles = useCallback((data: FileFilterData): void => {
    const nextValue = isSingleFile
      ? [data.files[0]].filter(Boolean)
      : value.concat(data.files)

    onError?.(data.error)

    if (nextValue.length > 0) {
      onChange(id, nextValue)
    }
  }, [id, isSingleFile, onChange, value, onError])

  const onLabelClick = useCallback(() => {
    if (refInput.current != null) {
      refInput.current.value = null as any
    }
  }, [])

  const isError = showError && hasErrorMessage

  // ========================================== //
  //                    RENDER                  //
  // ========================================== //

  return (
    <div
      className={cnx(CLASS_NAME, 'cf-form-field', showError && 'cf-has-error')}
      data-test-id={dataTestId}
    >
      <DropZone
        onAddFiles={onAddFiles}
        dragHereMessage={dragHereMessage ?? 'Drag files here'}
        {...config}
      >
        <div className='cf-drop-zone-content'>
          {isLoading && <Loader className='cf-section-loader' size='medium' />}

          <IconSVGDocument />

          <div className={cnx('cf-drag-capture', 'g-text-black')}>
            <div className='cf-tablet-message'>
              Drop your files here, or <label htmlFor={id} onClick={onLabelClick}> browse</label>
            </div>
            <div className='cf-mobile-message'>
              <label htmlFor={id} onClick={onLabelClick}>Browse</label> your files here
            </div>

            <input
              accept={acceptedMimes}
              id={id}
              multiple={!isSingleFile}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                const files: File[] = Array.from(evt.target.files ?? [])
                const data = utils.filterFiles(files, config)

                onAddFiles(data)
              }}
              ref={refInput}
              style={INPUT_STYLES}
              type='file'
            />
          </div>

          {isError
            ? (
              <div className='cf-form-field-error'>
                {errorMessage}
              </div>
              )
            : <>{additionalInfo}</>}

          {(value ?? []).map(file => (
            <div className='cf-file-item' key={getUniqueName(file.name)}>
              <div className='cf-file-name'>{file.name}</div>
              <div
                className='cf-file-remove'
                onClick={() => {
                  onChange(id, (value ?? []).filter(x => x.name !== file.name))
                }}
              >
                <IconSVGCross />
              </div>
            </div>
          ))}
        </div>

        {children}
      </DropZone>
    </div>
  )
}

export default memo(FormFilesUpload)

// ========================================== //

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
// https://www.iana.org/assignments/media-types/media-types.xhtml
const EXT_TO_MIME_MAP = new Map([
  ['png', 'image/png'],
  ['jpg', 'image/jpg'],
  ['jpeg', 'image/jpeg'],
  ['txt', 'text/plain'],
  ['csv', 'text/csv'],
  ['pdf', 'application/pdf'],
  ['xls', 'application/ms-excel'],
  ['xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  ['mp4', 'video/mp4'],
  ['mov', 'video/quicktime']
])

const mimeFileExtensionsToMIME = (fileExt: string): string => {
  return EXT_TO_MIME_MAP.get(fileExt) ?? 'text/plain'
}
