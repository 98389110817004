import { css } from '@emotion/css'

/**
 * Styles for correct rendering of feedback link in wysiwyg editor.
 */

export default css(`
  &.insert-video-container {
    max-width: 385px;
    max-height: 223px;
    margin-bottom: 8px;
    position: relative;
  }
`)
