import type { KeyVal } from 'types'
import {
  type LeadListCountersPayloadApi,
  LeadState,
  LeadStateAPI
} from 'api/types'

import { UNASSIGNED_LEAD_ID } from 'constants/constants'
import type { Filters, SerializeCountersProps } from './types'

const leadStateMap: Record<LeadState, LeadStateAPI | null> = {
  [LeadState.All]: null,
  [LeadState.New]: LeadStateAPI.New,
  [LeadState.NeedAttention]: LeadStateAPI.NeedAttention,
  [LeadState.Inbox]: LeadStateAPI.Inbox
}

const serializeAssignedToData = (data: Filters['assignedTo']): LeadListCountersPayloadApi['assignedTo'] | {} => {
  const assignedToIds = [...data]
  const unassignedIdx = assignedToIds.findIndex(id => id === UNASSIGNED_LEAD_ID)
  const isFilterChosen = assignedToIds.length !== 0
  const areUnassigned = unassignedIdx > -1

  return isFilterChosen ? { assignedTo: { areUnassigned, assignedToIds } } : {}
}

const serializerCommonData = ({
  temperatures,
  status,
  assignedTo,
  source
}: Partial<Omit<Filters, 'tab'>>): Partial<LeadListCountersPayloadApi> => ({
  leadTemperatures: temperatures,
  leadStatuses: status,
  leadSources: source,
  ...serializeAssignedToData(assignedTo ?? [])
})

export const serializeFilters = ({
  tab,
  search,
  ...commonProps
}: Filters): KeyVal => ({
  search,
  leadState: leadStateMap[tab],
  ...serializerCommonData(commonProps)
})

export const serializeCounters = ({
  search,
  ...commonProps
}: SerializeCountersProps): Partial<LeadListCountersPayloadApi> => ({
  search,
  ...serializerCommonData(commonProps)
})
