import type { ColumnDef } from '@carfluent/common'

import TextCellHOC from 'components/common/Table/cells/TextCell'
import TooltipCell from 'components/common/Table/cells/TooltipCell'

import type { ActivityReportItemDto } from 'api/types/responses'

export const columns: Array<ColumnDef<ActivityReportItemDto>> = [
  {
    sortable: false,
    header: '',
    id: 'representativeGroup',
    className: 'representative-group',
    columns: [
      {
        size: 240,
        id: 'name',
        accessorFn: (row) => {
          if (row.firstName == null && row.lastName == null) {
            return '-'
          }

          return `${row.firstName ?? ''}${row.lastName != null ? ` ${row.lastName}` : ''}`
        },
        loading: 'big_line',
        header: TextCellHOC({ text: 'Representative' }),
        cell: TooltipCell,
        sortable: false,
        className: 'representative-group'
      }
    ] as Array<ColumnDef<ActivityReportItemDto>>
  },
  {
    size: 80,
    id: 'workHours',
    accessorFn: (row) => {
      const hours = Math.floor(row.workMinutes / 60)
      const minutes = row.workMinutes % 60

      return `${hours}h${minutes !== 0 ? ` ${minutes}m` : ''}`
    },
    loading: 'big_line',
    header: TextCellHOC({ text: 'Work hours' }),
    cell: TooltipCell,
    sortable: false,
    className: 'work-hours-group'
  },
  {
    sortable: false,
    header: 'Completed tasks',
    id: 'completedTaskGroup',
    className: 'completed-task-group',
    columns: [
      {
        size: 240,
        accessorKey: 'webLeadCompleted',
        loading: 'big_line',
        header: TextCellHOC({ text: 'Web lead' }),
        cell: TooltipCell,
        sortable: false
      },
      {
        size: 240,
        accessorKey: 'callCompleted',
        loading: 'big_line',
        header: TextCellHOC({ text: 'Call' }),
        cell: TooltipCell,
        sortable: false
      },
      {
        size: 240,
        accessorKey: 'creditAppCompleted',
        loading: 'big_line',
        header: TextCellHOC({ text: 'Credit app' }),
        cell: TooltipCell,
        sortable: false
      },
      {
        size: 240,
        accessorKey: 'reminderCompleted',
        loading: 'big_line',
        header: TextCellHOC({ text: 'Reminder' }),
        cell: TooltipCell,
        sortable: false
      }
    ] as Array<ColumnDef<ActivityReportItemDto>>
  },
  {
    sortable: false,
    header: 'Sent',
    id: 'sentGroup',
    className: 'sent-group',
    columns: [
      {
        size: 240,
        accessorKey: 'messagesSent',
        loading: 'big_line',
        header: TextCellHOC({ text: 'Messages' }),
        cell: TooltipCell,
        sortable: false,
        className: 'sent-group'
      },
      {
        size: 240,
        accessorKey: 'emailsSent',
        loading: 'big_line',
        header: TextCellHOC({ text: 'Emails' }),
        cell: TooltipCell,
        sortable: false,
        className: 'sent-group'
      }
    ] as Array<ColumnDef<ActivityReportItemDto>>
  },
  {
    sortable: false,
    header: 'Appointments',
    id: 'appointmentsGroup',
    className: 'appointments-group',
    columns: [
      {
        size: 240,
        accessorKey: 'appointmentsCreated',
        loading: 'big_line',
        header: TextCellHOC({ text: 'Created' }),
        cell: TooltipCell,
        sortable: false
      },
      {
        size: 240,
        accessorKey: 'appointmentsCompleted',
        loading: 'big_line',
        header: TextCellHOC({ text: 'Held' }),
        cell: TooltipCell,
        sortable: false
      },
      {
        size: 240,
        accessorKey: 'appointmentsCanceled',
        loading: 'big_line',
        header: TextCellHOC({ text: 'Canceled' }),
        cell: TooltipCell,
        sortable: false
      }
    ] as Array<ColumnDef<ActivityReportItemDto>>
  }
]
