import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, isOk } from '@carfluent/common'
import { isAxiosError } from 'axios'

import { type DictionaryItem } from 'api/types'
import { type AccountModel } from 'api/types/responses'
import AccountingApiProvider from 'api/accounting.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import parseError from 'utils/parseErrors'

import { AccountFields, getDefaultFormData, DISABLED_CATEGORY, Messages } from '../constants'
import serializeAccountForm from './serializer'
import { validationRules } from './validator'
import parseData from './parser'
import {
  type AccountFormData,
  type ErrTouchShortcuts,
  type CategoriesByTypes,
  type UseAccountDetailsReturn,
  type UseAccountDetailsProps
} from './types'

const DEFAULT_FORM_DATA = getDefaultFormData()

const useAccountDetails = ({
  isModalOpen,
  accountId,
  onCloseModal: _onCloseModal,
  onSubmit: _onSubmit
}: UseAccountDetailsProps): UseAccountDetailsReturn => {
  const { showAlert, showSuccess, showError } = useCustomSnackbar()

  const [account, setAccount] =
    useState<AccountModel | null>(null)

  const [types, setTypes] =
    useState<DictionaryItem[]>([])

  const [categoriesByTypes, setCategories] =
    useState<CategoriesByTypes>({})

  const [isLoading, setIsLoading] =
    useState(false)

  const isEdit = accountId != null

  const submitAction = useCallback(async (values: AccountFormData) => {
    setIsLoading(true)

    const payload = serializeAccountForm(values)
    const account = isEdit
      ? await AccountingApiProvider.updateAccount({ ...payload, id: accountId })
      : await AccountingApiProvider.createAccount(payload)

    await _onSubmit?.(account)
  }, [_onSubmit, isEdit])

  const onActionResult = useCallback((res, resetForm, _, setErrors) => {
    setIsLoading(false)

    if (isOk(res)) {
      _onCloseModal()
      resetForm()
      showSuccess(isEdit ? Messages.SuccessUpdate : Messages.SuccessCreate)
    } else if (isAxiosError(res.result)) {
      const errors = parseError(res.result)
      if (Object.keys(errors).length > 0) {
        setErrors(errors)
      }
    } else {
      showError(res.result)
    }
  }, [_onCloseModal, showError, showSuccess, isEdit])

  const form = useForm<AccountFormData, ErrTouchShortcuts>({
    baseValues: DEFAULT_FORM_DATA,
    validationRules,
    submitAction,
    onActionResult
  })

  const {
    values: { accountType },
    setValues,
    resetForm,
    onChange,
    onBlur,
    values,
    errors,
    touched,
    onSubmit
  } = form

  const categoriesOfSelectedType = useMemo(() =>
    accountType?.id != null
      ? categoriesByTypes[accountType.id] ?? []
      : [DISABLED_CATEGORY]
  , [accountType])

  const isCategoriesDisabled = (accountType?.id != null) && (categoriesOfSelectedType.length === 0)

  // ========================================== //
  //                  HANDLERS                  //
  // ========================================== //

  const onCloseModal = (): void => {
    resetForm(getDefaultFormData())
    _onCloseModal()
  }

  const onTypeChange = useCallback((key, value) => {
    onChange(AccountFields.Category, null)
    onChange(key, value)
  }, [onChange])

  const loadAccountData = useCallback(async (
    id: number,
    categories: CategoriesByTypes,
    types: DictionaryItem[]
  ): Promise<void> => {
    try {
      setIsLoading(true)
      const account = await AccountingApiProvider.getAccount(id)
      const parsedData = parseData({ account, categoriesByTypes: categories, types })

      setAccount(account)
      setValues(parsedData)
    } catch (err) {
      showAlert(err)
    } finally {
      setIsLoading(false)
    }
  }, [setValues, showAlert])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (!isModalOpen) {
      return
    }

    const runEffect = async (): Promise<void> => {
      const [
        { items: types },
        { groupedByTypes }
      ] = await Promise.all([
        await AccountingApiProvider.getTypes(),
        await AccountingApiProvider.getCategories()
      ])

      setCategories(groupedByTypes)
      setTypes(types)

      if (accountId != null) {
        await loadAccountData(accountId, groupedByTypes, types)
      }
    }

    void runEffect()
  }, [isModalOpen, accountId, loadAccountData])

  // ========================================== //

  return {
    categories: categoriesOfSelectedType,
    errors,
    isCategoriesDisabled,
    isEdit,
    isLoading,
    isModalOpen,
    isReadonly: account?.isReadonly ?? false,
    onBlur,
    onCloseModal,
    onChange,
    onSubmit,
    onTypeChange,
    touched,
    types,
    values
  }
}

export default useAccountDetails
