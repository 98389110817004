import { FC, ReactNode } from 'react'
import { CellWrapper, cnx } from '@carfluent/common'

import NameWithAvatar from 'components/common/NameWithAvatar'

import CLASS_NAME from './styles'

export interface AvatarName {
  name: string | null
  showAvatar: boolean
  avatarIcon?: ReactNode | null
  className?: string
}

interface NameCellProps {
  getValue: () => AvatarName
}

const NameCell: FC<NameCellProps> = (props) => {
  const { name, showAvatar, className, avatarIcon } = props.getValue()
  const isName = name != null
  const displayName = isName ? name : 'Unassigned'

  return (
    <CellWrapper className={cnx(CLASS_NAME, isName ? '' : 'unassigned', className)}>
      <NameWithAvatar
        className={className}
        showAvatar={showAvatar}
        name={displayName}
        avatarIcon={avatarIcon}
      />
    </CellWrapper>
  )
}

export default NameCell
