import { EditorState, SelectionState, Modifier } from 'draft-js'

import { CAR_BLOCK_END, CAR_BLOCK_ENTITY, CAR_BLOCK_START } from 'utils/wysiwyg'

import type { CarBlockData } from './types'

const START_OFFSET = 0
const EMPTY_BLOCK_SIZE = 0

export const addCarBlockToState = (state: EditorState, carData: CarBlockData, selectionKey?: string): EditorState => {
  const selection = selectionKey != null
    ? SelectionState.createEmpty(selectionKey).merge({ hasFocus: true })
    : null

  const text = `${CAR_BLOCK_START}${JSON.stringify(carData)}${CAR_BLOCK_END}`
  let lastEditorState = state
  let lastContentState = lastEditorState.getCurrentContent()

  /**
   * Usually entity are used to associate some metadata with text range,
   * but in this case we only use entity to mark inserted text as IMMUTABLE,
   * so that whole block will be removed on Delete or Backspace.
   */
  lastContentState = lastContentState.createEntity(CAR_BLOCK_ENTITY, 'IMMUTABLE', carData)
  const entityKey = lastContentState.getLastCreatedEntityKey()

  /**
   * Update the selection to include the car block data if not at start of a block
   */
  if (lastEditorState.getSelection().getAnchorOffset() !== START_OFFSET) {
    const currentSelection = lastEditorState.getSelection()
    const currentContent = lastEditorState.getCurrentContent()
    const currentBlockKey = currentSelection.getStartKey()
    const blockMap = currentContent.getBlockMap()
    /**
     * Obtain all blocks that come after the current block in the editor's content
     */
    const blocksAfter = blockMap.skipUntil((_, k) => k === currentBlockKey).rest()

    if (blocksAfter.size > EMPTY_BLOCK_SIZE) {
      /**
       * Move to the next block if there are blocks following the current block
       */
      const nextBlockKey = blocksAfter.first().getKey()
      const nextSelection = SelectionState.createEmpty(nextBlockKey)
      lastEditorState = EditorState.forceSelection(lastEditorState, nextSelection)
    } else {
      /**
       * Add a new line and move there if current block is the last block
       */
      const newSelection = currentSelection.merge({
        anchorKey: currentBlockKey,
        anchorOffset: currentContent.getBlockForKey(currentBlockKey).getLength(),
        focusKey: currentBlockKey,
        focusOffset: currentContent.getBlockForKey(currentBlockKey).getLength()
      })
      lastContentState = Modifier.splitBlock(currentContent, newSelection)
      lastEditorState = EditorState.push(lastEditorState, lastContentState, 'split-block')

      const newBlockKey = lastContentState.getKeyAfter(currentBlockKey)
      const newBlockSelection = SelectionState.createEmpty(newBlockKey)
      lastEditorState = EditorState.forceSelection(lastEditorState, newBlockSelection)
    }
  }

  /**
   * Update currently selected text region to contain an special tags of CarBlock with inlined car's data.
   */
  lastContentState = Modifier.replaceText(lastContentState, selection ?? lastEditorState.getSelection(), text, undefined, entityKey)
  lastEditorState = EditorState.push(lastEditorState, lastContentState, 'insert-characters')

  /**
   * Splits the current ContentBlock onto two, to allow future insertions of more car blocks.
   */
  lastContentState = Modifier.splitBlock(lastContentState, selection ?? lastEditorState.getSelection())
  lastEditorState = EditorState.push(lastEditorState, lastContentState, 'split-block')

  /**
   * Force selection to the empty block after the added block with car details.
   */
  const lastSelectedBlockKey = (selection ?? lastEditorState.getSelection()).getEndKey()

  if (lastSelectedBlockKey != null) {
    const nextSelection = SelectionState.createEmpty(lastSelectedBlockKey).merge({ hasFocus: true })
    lastEditorState = EditorState.forceSelection(lastEditorState, nextSelection)
  }

  return lastEditorState
}
