import { type FC } from 'react'
import { cnx, Loader } from '@carfluent/common'

import DealRecapFrontGross from 'components/deals/DealRecapFrontGross'
import DealRecapBackGross from 'components/deals/DealRecapBackGross'
import formatDealRecapValue from 'utils/formatters/formatDealRecapValue'

import { type DealRecapSectionProps } from './types'
import CLASS_NAME from './styles'

const DealRecapSection: FC<DealRecapSectionProps> = ({
  acquisitionFee,
  className,
  dealRecapData,
  isLoading,
  isShowCarDelivery
}) => {
  return (
    <div className={cnx(CLASS_NAME, className)}>
      {isLoading && <Loader className='cf-section-loader' size='large' />}

      <div className='grid-cell grid-cell-header'>
        <div className='g-h7-title'>Deal recap</div>
      </div>

      <div className='grid-cell grid-cell-left'>
        <DealRecapFrontGross
          className='deal-recap-front-gross'
          dealRecapData={dealRecapData}
          isShowCarDelivery={isShowCarDelivery}
          isForModal={false}
        />
      </div>

      <div className='grid-cell grid-cell-right'>
        <DealRecapBackGross
          acquisitionFee={acquisitionFee}
          className='deal-recap-back-gross'
          dealRecapData={dealRecapData}
          isForModal={false}
        />
      </div>

      <div className='grid-cell grid-cell-total'>
        <div className='g-h7-title'>Total Gross</div>

        <div className='g-h7-title'>
          {formatDealRecapValue(dealRecapData?.totalGross)}
        </div>
      </div>
    </div>
  )
}

export default DealRecapSection
