import {
  ACCOUNTING_LINKS,
  Routes,
  AccountingLinkTitles,
  CRMLinkTitles,
  ReconLinkTitles
} from 'constants/route_helper'

enum HeaderTitles {
  Dashboard = 'Dashboard',
  Settings = 'Settings',
  Deals = 'Deals',
  DealDetails = 'Deals',
  Reports = 'Reports',
  Vehicle = 'Current inventory',
  Appraisal = 'Appraisal list',
  AppraisalDetails = 'Appraisal Details',
  VehicleDetails = 'Vehicle Details',
  UserManagement = 'User management'
}

export const headerTitlesConfig = [
  {
    to: Routes.Dashboard,
    title: HeaderTitles.Dashboard
  },
  {
    to: Routes.SettingsProfile,
    title: HeaderTitles.Settings
  },
  {
    to: Routes.SettingsGeneral,
    title: HeaderTitles.Settings
  },
  {
    to: Routes.SettingsGeneralUpdate,
    title: HeaderTitles.Settings
  },
  {
    to: Routes.SettingsFi,
    title: HeaderTitles.Settings
  },
  {
    to: Routes.SettingsFiUpdate,
    title: HeaderTitles.Settings
  },
  {
    to: Routes.SettingsWebsite,
    title: HeaderTitles.Settings
  },
  {
    to: Routes.SettingsFormPacks,
    title: HeaderTitles.Settings
  },
  {
    to: Routes.SettingsFormPacksItem,
    title: HeaderTitles.Settings
  },
  {
    to: Routes.SettingsLienholderList,
    title: HeaderTitles.Settings
  },
  {
    to: Routes.UserManagement,
    title: HeaderTitles.UserManagement
  },
  {
    to: Routes.Deals,
    title: HeaderTitles.Deals
  },
  {
    to: Routes.DealsOther,
    title: HeaderTitles.DealDetails
  },
  {
    to: Routes.Vehicles,
    title: HeaderTitles.Vehicle
  },
  {
    to: Routes.VehiclesOther,
    title: HeaderTitles.VehicleDetails
  },
  {
    to: Routes.AccountingBankingUpload,
    title: AccountingLinkTitles.Banking
  },
  {
    to: Routes.AccountingReconciliationDraft,
    title: AccountingLinkTitles.Banking
  },
  {
    to: Routes.AccountingGeneralSettings,
    title: AccountingLinkTitles.AccountingSettings
  },
  {
    to: Routes.AccountingSettingsOpeningBalances,
    title: AccountingLinkTitles.AccountingSettings
  },
  {
    to: Routes.AccountingRecurringEntries,
    title: AccountingLinkTitles.AccountingSettings
  },
  {
    to: Routes.AccountingSettingsVendors,
    title: AccountingLinkTitles.AccountingSettings
  },
  {
    to: Routes.AccountingSettingsCustomers,
    title: AccountingLinkTitles.AccountingSettings
  },
  ...ACCOUNTING_LINKS,
  {
    to: Routes.CRMLeads,
    title: CRMLinkTitles.Leads
  },
  {
    to: Routes.CRMMarketingCampaigns,
    title: CRMLinkTitles.MarketingCampaigns
  },
  {
    to: Routes.CRMMarketingCampaignDetails,
    title: CRMLinkTitles.MarketingCampaigns
  },
  {
    to: Routes.CRMLeadItem,
    title: CRMLinkTitles.Leads
  },
  {
    to: Routes.CRMTaskLeadsItem,
    title: CRMLinkTitles.Leads
  },
  {
    to: Routes.CRMTasks,
    title: CRMLinkTitles.Tasks
  },
  {
    to: Routes.CRMSettingsOther,
    title: CRMLinkTitles.Settings
  },
  {
    to: Routes.ReportsOther,
    title: HeaderTitles.Reports
  },
  {
    to: Routes.Recon,
    title: ReconLinkTitles.ReconList
  },
  {
    to: Routes.ReconSettingsOther,
    title: ReconLinkTitles.ReconSettings
  },
  {
    to: Routes.ReconDetails,
    title: ReconLinkTitles.ReconDetails
  },
  {
    to: Routes.ReconIntakeInspection,
    title: ReconLinkTitles.ReconDetails
  },
  {
    to: Routes.AccountingReconciliationSetup,
    title: AccountingLinkTitles.Banking
  },
  {
    to: Routes.AccountingReconciliationDraft,
    title: AccountingLinkTitles.Banking
  },
  {
    to: Routes.AccountingTrialBalance,
    title: AccountingLinkTitles.ChartOfAccounts
  }
]
