import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { RouterProvider } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { ThemeProvider } from '@material-ui/core'
import { UI } from '@carfluent/common'

import router from 'routing'
import copyAuthToken from 'utils/copyAuthToken'
import getBuildInfo from 'utils/buildInfo'
import injectHotjar from 'utils/injectHotjar'

import { theme } from 'config/material_ui_theme'
import reportWebVitals from 'reportWebVitals'
import './index.css'
import { GLOBAL_STYLES } from './styles'

const { Loader, GlobalStyles } = UI

const { releaseName, isSentryUsed } = getBuildInfo()

if (isSentryUsed) {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: 'https://1fa1f3fedb0946ca9c6122571d252bd6@o1190176.ingest.sentry.io/6593665',
    release: releaseName,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0 // AZ-TODO: experiment with this value, 1.0 is usually too much
  })
}

injectHotjar()

console.info('Commit SHA: ', process.env.REACT_APP_COMMIT)
window.CarFluent = {
  commit: process.env.REACT_APP_COMMIT,
  copyAuthToken
}

document.addEventListener('keyup', (evt: KeyboardEvent) => {
  if (evt.ctrlKey && evt.altKey && evt.code === 'BracketRight') {
    void window.CarFluent.copyAuthToken?.()
  }
})

ReactDOM.render(
  <SnackbarProvider>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loader size='large' />}>
        <GlobalStyles styles={GLOBAL_STYLES} />
        <RouterProvider router={router} />
      </Suspense>
    </ThemeProvider>
  </SnackbarProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
