import { css } from '@emotion/css'

export default css(`
  width: 112px;
  display: flex;

  p {
    color: #101010;
    font-size: 16px;
    cursor: pointer;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-decoration: underline;
    margin: 12px 16px 12px 16px;
  }

  span {
    width: 56px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  svg {
    padding: 2px;
  }
`)
