import { useState, useCallback } from 'react'
import { useRefUpdater } from '@carfluent/common'

import { type EntityWithName } from 'components/common/FormFilesUpload'
import { type VehicleDocumentDto, type VehicleById } from 'api/types'
import FilesApiProvider from 'api/files.api'
import VehiclesApiProvider from 'api/vehicles.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useCloudAccessToken from 'hooks/useCloudAccessToken'
import { downloadBlob, parseFileNameFromContentDisposition } from 'utils/general'

import type { VehicleDocumentLoaded } from '../../components/FilesTab/columns'
import type { FilesTabProps } from '../../components/FilesTab'
import type { UseGeneralTabReturn } from '../useGeneralTab/types'
import { MAX_FILE_NUMBER, MAX_LIMIT_ERROR } from '../../components/FilesTab'

let uniqId = 0

const getLoadingItem = (fileName: string): VehicleDocumentLoaded => ({
  id: --uniqId,
  fileId: -1,
  fileName,
  createdBy: '',
  createdDate: '',
  loading: true
})

interface UseFilesTabProps extends Pick<UseGeneralTabReturn, 'updateFormData'>{
  documents: VehicleDocumentDto[]
  updateOriginalVehicle: (value: Partial<VehicleById>) => void
  vehicleId: string
}

export interface UseFilesTabReturn extends FilesTabProps {
  isLoading: boolean
}

export const useFilesTab = ({
  documents,
  updateFormData,
  updateOriginalVehicle,
  vehicleId
}: UseFilesTabProps): UseFilesTabReturn => {
  const { showAlert } = useCustomSnackbar()
  const [deletingFileId, setDeleteFileId] = useState<number | null>(null)
  const [uploadingData, setUploadingData] = useState<VehicleDocumentLoaded[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const uploadingDataRef = useRefUpdater(uploadingData)
  const documentsRef = useRefUpdater(documents)

  const { token = '' } = useCloudAccessToken() ?? {}

  const onChangeFiles = useCallback(async (_: string, files: EntityWithName[]): Promise<void> => {
    const formData = new FormData()
    const _uploadingData: VehicleDocumentLoaded[] = []
    let filesToUpload = [...files]

    // calculate and validate files length
    const existingDocs = documentsRef.current.length - uploadingDataRef.current.length
    const totalFileLength = filesToUpload.length + existingDocs

    if (totalFileLength > MAX_FILE_NUMBER) {
      filesToUpload = filesToUpload.slice(0, MAX_FILE_NUMBER - existingDocs)
      setError(MAX_LIMIT_ERROR)
    }

    if (filesToUpload.length === 0) {
      return
    }

    filesToUpload.forEach((file, i) => {
      formData.append(`filesDto[${i}].File`, file as File)

      _uploadingData.push(getLoadingItem(file.name))
    })

    formData.append('parameters', `{ "vehicleId": ${vehicleId ?? ''} }`)
    setUploadingData(prev => [..._uploadingData, ...prev])

    try {
      setIsLoading(true)
      const { items: filesItems } = await FilesApiProvider.uploadVehicleDocument(formData)
      const docs = filesItems?.map(({ id: fileId, originalFileName: fileName }) => ({ fileId, fileName }))
      const { items } = await VehiclesApiProvider.patchDocuments(vehicleId, { documents: docs })
      documentsRef.current = [...items, ...documentsRef.current]

      updateFormData({ vehicleDocuments: documentsRef.current })
      updateOriginalVehicle({ vehicleDocuments: documentsRef.current })
      showAlert('Documents uploaded successfully', { variant: 'success' })
    } catch {
      showAlert('Failed to upload files')
    } finally {
      setUploadingData((prev) =>
        prev.filter(({ id }) =>
          !_uploadingData.some((item) => item.id === id))
      )

      setIsLoading(false)
    }
  }, [updateFormData, updateOriginalVehicle, documents, showAlert])

  const onDelete = useCallback(async (): Promise<void> => {
    if (deletingFileId == null) {
      return
    }

    try {
      await VehiclesApiProvider.deleteDocument(deletingFileId)
      const vehicleDocuments = [...documentsRef.current]
      const idx = vehicleDocuments.findIndex(({ id }) => id === deletingFileId)
      if (idx > -1) {
        vehicleDocuments.splice(idx, 1)
        documentsRef.current = vehicleDocuments
        updateFormData({ vehicleDocuments })
        updateOriginalVehicle({ vehicleDocuments })
      }
      setDeleteFileId(null)
      showAlert('File deleted successfully', { variant: 'success' })
    } catch {
      showAlert('Failed to delete file')
    }
  }, [showAlert, deletingFileId, updateFormData, updateOriginalVehicle, documents])

  const onRedirect = useCallback(async (id: number): Promise<void> => {
    const response = await FilesApiProvider.getVehicleFiles(id)
    const fileName = parseFileNameFromContentDisposition(response.headers['content-disposition'])

    downloadBlob(response.data, fileName)
  }, [token])

  return {
    onRedirect,
    onDelete,
    onChangeFiles,
    documents: [...uploadingData, ...documents],
    isLoading,
    setDeleteFileId,
    isDeleteModalOpen: deletingFileId != null,
    onError: setError,
    error
  }
}
