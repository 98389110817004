import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  wrapperStyle: {
    display: 'flex',
    alignItems: 'center'
  },
  itemStyle: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 40
  },
  indicatorStyle: {
    display: 'block',
    marginRight: 8,
    background: '#F3F3F3',
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0.25,
    width: 11,
    height: 11,
    borderRadius: '50%'
  },
  indicatorDefaultStyle: {
    background: '#F3F3F3'
  },
  indicatorValidStyle: {
    background: '#219653'
  },
  indicatorInvalidStyle: {
    background: '#B00020'
  },
  textStyle: {
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: 'Roboto,sans-serif',
    color: 'rgba(0, 0, 0, 0.38)',
    '&:hover': {
      color: '#212121'
    }
  }
})
