import { type FC, type MouseEvent } from 'react'
import { cnx, CellWrapper, Loader, useLoader } from '@carfluent/common'

import { ReconcileStatusId } from 'api/types'
import { type CellFC } from 'types'
import isReconciledTransaction from 'utils/accounting/isReconciledTransaction'
import isClearedTransaction from 'utils/accounting/isClearedTransaction'
import ConfigurableCellHOC from 'components/common/Table/cells/ConfigurableCellHOC'
import Tooltip from 'components/common/Tooltip'
import IconSVG from 'components/inlineImages'

import CLASS_NAME from './styles'

export interface ReconcileStatusCellProps {
  getValue: () => CellValue
  onToggleReconcile?: (rowIdx: number, rowId: number, currentStatus: number) => Promise<void>
}

export interface CellValue {
  id: number
  status: ReconcileStatusId
  rowIdx: number
}

const ReconcileStatusCell: FC<ReconcileStatusCellProps> = ({
  getValue,
  onToggleReconcile
}) => {
  const isToggleButton = onToggleReconcile != null
  const { isLoading, startLoader, stopLoader } = useLoader()
  const { id, rowIdx, status } = getValue()
  const isReconciled = isReconciledTransaction(status)
  const isCleared = isClearedTransaction(status)
  const colorLeft = (isCleared || isReconciled) ? '#219653' : '#21212166'
  const colorRight = isReconciled ? '#219653' : '#21212166'
  const text = isReconciled
    ? 'Reconciled'
    : isCleared ? 'Cleared' : ''

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onClick = (evt: MouseEvent): void => {
    if (!isToggleButton) {
      return
    }

    const doAction = async (): Promise<void> => {
      startLoader()
      await onToggleReconcile?.(rowIdx, id, status) // exception should be managed on the Table's level.
      stopLoader()
    }

    evt.stopPropagation()
    void doAction()
  }

  // ========================================== //

  return (
    <CellWrapper
      className={cnx(CLASS_NAME, 'cell-reconcile-status', isToggleButton && 'is-toggle-button', isReconciled && 'is-reconciled')}
    >
      {isLoading && <Loader color='dark' />}

      {!isLoading && (
        <Tooltip
          title={text}
          popperClassName='tooltip-popover'
        >
          <div className='tooltip-content' onClick={onClick}>
            <IconSVG.StatusCheckmark width={24} height={24} color={colorLeft} />
            <IconSVG.StatusCheckmark width={24} height={24} color={colorRight} />
          </div>
        </Tooltip>
      )}
    </CellWrapper>
  )
}

export default ReconcileStatusCell

export function ReconcileStatusCellHOC (hocProps: Partial<ReconcileStatusCellProps>) {
  const Comp: CellFC<ReconcileStatusCellProps, CellValue> = ReconcileStatusCell
  return ConfigurableCellHOC(Comp, hocProps)
}
