import { css } from '@emotion/css'

export default css(`
  width: 100%;
  max-height: 130px;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  margin-bottom: 24px;

  textarea {
    background-color: #FAFAFA;

    ::-webkit-scrollbar {
      &-thumb { background-color: #BDBDBD; }
      &-track { margin: 8px 0; }
    }
  }

  .cf-comment-button-wrapper {
    text-align: right;
    margin-top: 16px;

    .cf-add-comment-btn {
      padding: 5px 18px;
      border: 1px solid #C99B86;
      border-radius: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1.25px;
      transition: color .3s, border .3s;
      min-width: 150px;
      max-height: 26px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: #FFFFFF;

      &[disabled] {
        cursor: not-allowed;
        border: 1px solid rgba(0, 0, 0, 0.3);
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }
`)
