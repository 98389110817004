import { type FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { PRESET_LABELS, RangePickerMode } from '@carfluent/common'

import ReportBalanceSheetTable, { createTableClass } from 'components/reports/ReportsBSPLTable'
import ReportLayout from 'components/reports/ReportLayout'
import AccountingHOK from 'pages/accounting/AccountingHOC'

import { useReportBalanceSheet } from './hook'
import { DEFAULT_PRESETS } from './hook/constants'
import { createStyleClass } from './styles'

const ReportBalanceSheet: FC = () => {
  const { rootCls, tableClass } = useMemo(() => ({
    rootCls: createStyleClass(),
    tableClass: createTableClass()
  }), [])
  const { data, ...layoutProps } = useReportBalanceSheet()

  return (
    <ReportLayout
      {...layoutProps}
      monthsNumber={1}
      title='Balance sheet'
      className={tableClass}
      rootClassName={rootCls}
      presets={DEFAULT_PRESETS}
      initialPresetName={PRESET_LABELS.TODAY}
      rangePickerMode={RangePickerMode.Single}
    >
      <ReportBalanceSheetTable data={data} />
    </ReportLayout>
  )
}

export default AccountingHOK(observer(ReportBalanceSheet))
