import { useMemo, type FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import { cnx, Button, Modal, FormDropdown } from '@carfluent/common'

import { logout } from 'utils/authHelpers'
import ActionsMenu, { type MenuButtonProps } from 'components/common/ActionsMenu'
import IconSVG from 'components/inlineImages'

import { headerTitlesConfig } from './config'
import useHeader from './hook'
import { CLASS_NAME, ACCESS_CHANGED_MODAL_CLASSES } from './styles'
import LogoIconBlack from 'components/inlineImages/LogoIconBlack'

const MODAL_MIN_WIDTH = 480

const Header: FC = () => {
  const {
    isAdmin,
    isAccessChangedModalOpen,
    dealers,
    activeDealer,
    onChange,
    onOkClick,
    onOpenUserManagement
  } = useHeader()

  const actionList = useMemo(() => ([
    {
      title: 'User Management',
      icon: <IconSVG.UserManagement />,
      handleOnClick: onOpenUserManagement
    },
    {
      title: 'Log Out',
      icon: <IconSVG.Logout />,
      handleOnClick: logout
    }
  ]), [onOpenUserManagement])

  return (
    <header className={cnx(CLASS_NAME, 'g-main-header', 'g-non-printable-block')}>
      <div className='main-header-logo'>
        <LogoIconBlack />
      </div>

      <h1 className='main-header-title' data-test-id='page-header-text'>
        <Routes>
          {headerTitlesConfig.map(({ to, title }) =>
            <Route key={to} path={to} element={title}/>
          )}
        </Routes>
      </h1>

      {(dealers.length > 1) && (
        <FormDropdown
          id='cf-dealer-dropdown'
          options={dealers}
          value={activeDealer}
          onChange={onChange}
          mode='select'
          startAdornment={<IconSVG.MapLocation/>}
          disableClearable
        />
      )}

      {isAdmin && (
        <ActionsMenu
          className='main-header-actions-menu'
          actions={actionList}
          MenuButtonComponent={MenuButton}
        />
      )}

      {!isAdmin && (
        <Button
          className='main-header-logout'
          variant='text'
          onClick={logout}
        >
          Log Out
        </Button>
      )}

      <Modal
        isOpen={isAccessChangedModalOpen}
        keepContentMounted={false}
        minWidth={MODAL_MIN_WIDTH}
      >
        <div className={ACCESS_CHANGED_MODAL_CLASSES}>
          <p className='title'>
            Access changed
          </p>

          <p className='text'>
            You have restricted access to this dealership.
          </p>

          <Button onClick={onOkClick}>OK</Button>
        </div>
      </Modal>
    </header>
  )
}

export default Header

const MenuButton: FC<MenuButtonProps> = ({ onClick, isOpen }) => (
  <div
    className={`main-header-manage-account-icon-btn ${isOpen === true ? 'menu-open' : ''}`}
    onClick={onClick}
  >
    <IconSVG.ManageAccount />
  </div>
)
