import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGCheckboxUnchecked: FC<IconProps & {isDisabled?: boolean}> = ({ color = '#FFFFFF', width = 24, height = 24, isDisabled = false }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='1' y='1' width='22' height='22' rx='1'
        stroke='#F3F3F3'
        strokeWidth='2'
        fill={isDisabled ? '#F3F3F3' : color}
      />
    </svg>
  )
}

export default IconSVGCheckboxUnchecked
