import { css } from '@emotion/css'

export default css(`
  width: 100%;
  padding-left: 16px;
  min-width: 152px;
  max-width: 208px;

  :first-child {
    padding-left: 0;
    min-width: 204px;
    max-width: 284px;

    .cf-text  {
      margin-left: 0;

      > p {
        font-size: 14px;
      }
    }
  }

  &.cf-edit-mode-view {
    padding-left: 0;
    min-width: 152px;
    max-width: 208px;

    :first-child {
      min-width: 212px;
      max-width: 296px;
    }
  }

  .cf-input-container {
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    height: 48px;

    :focus, :hover {
      border: 1px solid #C99B86;
    }

    &.disabled {
      border-color: transparent;
      background: transparent;
      
      input {
        background: transparent;
        color: #101010;
      }
    }
    
    &.with-error {
      border-color: var(--cf-input-error-color);
    }

    input {
      background-color: #FFFFFF;
      height: 14px;

      :-webkit-autofill,
      :-webkit-autofill:hover, 
      :-webkit-autofill:focus, 
      :-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #FFFFFF inset !important;
      }
    }
  }
`)
