import { type ChangeEvent, type FC, useCallback } from 'react'
import { cnx } from '@carfluent/common'

import CheckboxLabel from 'components/common/CheckboxLabel'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

import { type CheckboxListProps } from './types'
import CLASS_NAME from './styles'

export { type CheckboxListProps } from './types'

const CheckboxList: FC<CheckboxListProps> = ({
  className,
  disabled = false,
  items,
  layout = 'two-columns',
  onChange: _onChange,
  isLoading
}) => {
  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name: id, checked: nextChecked } = event.target
    const nextItems = [...items]
    const idx = nextItems.findIndex(x => x.id === id)

    if (idx > -1) {
      nextItems[idx] = {
        ...nextItems[idx],
        isChecked: nextChecked
      }
    }

    _onChange(id, nextChecked, nextItems)
  }, [items, _onChange])

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, layout, className)}>
      {items.map(item => (
        <BlockSkeleton key={item.id} isLoading={isLoading}>
          <div className='list-item-container'>
            <div className='start-adornment'>
              {item.startAdornment}
            </div>

            <CheckboxLabel
              checked={item.isChecked}
              disabled={disabled || item.isDisabled}
              id={item.id}
              key={item.id}
              mode='inlined'
              name={item.name}
              onChange={onChange}
            />
          </div>
        </BlockSkeleton>
      ))}
    </div>
  )
}

export default CheckboxList
