import { css } from '@emotion/css'

export default css(`
  width: 100%;
  height: 262px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(33, 33, 33, 0.50);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
`)
