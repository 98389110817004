import { CIVIL_UNION, PROGRESSIVE_STATES } from 'constants/names'
import isAfter from 'date-fns/isAfter'
import addYears from 'date-fns/addYears'

// to be removed after CoApplicant Form refactoring
import mapValues from 'lodash-es/mapValues'

import type { BaseListItem, KeyVal } from 'types'
import type { CreditAppValidationContext } from 'types/validation'

import {
  CreditAppParts,
  EmploymentFields,
  EmploymentStatus,
  EmploymentStatusIdWithPrevData,

  // to be removed after CoApplicant Form refactoring
  CoApplicantFields,
  AddressFields
} from './enums'

import { type HousingField, type EmploymentField, HOUSING_FIELDS, EMPLOYMENT_FIELDS } from './constants'

export {
  CreditAppParts,
  EmploymentFields,
  HOUSING_FIELDS,
  EMPLOYMENT_FIELDS
}

export type {
  HousingField,
  EmploymentField
}

export const isEmploymentField = (fieldId: EmploymentField | HousingField): fieldId is EmploymentField => {
  return EMPLOYMENT_FIELDS.includes(fieldId as EmploymentField)
}

export const isHousingField = (fieldId: EmploymentField | HousingField): fieldId is HousingField => {
  return HOUSING_FIELDS.includes(fieldId as HousingField)
}

export const hasOtherIncomeOption = (incomeOption?: string | null): boolean => {
  return incomeOption != null && incomeOption !== '' && incomeOption !== 'None'
}

export const getTypedOrNull = <T = any, V = any>(Constructor: (x: V) => T, val?: V | null): T | null => {
  if (val == null) {
    return null
  }

  // sometimes as for the Date it might crash if val is not a valid date
  try {
    return Constructor(val)
  } catch {
    return null
  }
}

export const toDate = (x: string): Date => new Date(x)

export const filterCoApplicantTypes = (options: BaseListItem[], state?: string | null): BaseListItem[] => {
  if (state != null && PROGRESSIVE_STATES.includes(state)) {
    return options
  }

  return options.filter(item => item.name !== CIVIL_UNION)
}

const checkVisibilityWithEmploymentStatus = (status: number): boolean => {
  switch (status) {
    case EmploymentStatusIdWithPrevData.Retired:
    case EmploymentStatusIdWithPrevData.Student:
    case EmploymentStatusIdWithPrevData.Unemployed:
    case EmploymentStatusIdWithPrevData.RetiredMilitary: {
      return true
    }

    default: {
      return false
    }
  }
}

const getFieldsVisibility =
  (statusName: number): Set<string> => {
    switch (statusName) {
      case EmploymentStatus.FullTime:
      case EmploymentStatus.ActiveMilitary:
      case EmploymentStatus.Other: {
        return new Set([
          EmploymentFields.JobTitle,
          EmploymentFields.EmployerName,
          EmploymentFields.PhoneNumber,
          EmploymentFields.StartDate,
          EmploymentFields.EndDate,
          EmploymentFields.Income
        ])
      }

      case EmploymentStatus.SelfEmployed: {
        return new Set([
          EmploymentFields.JobTitle,
          EmploymentFields.PhoneNumber,
          EmploymentFields.StartDate,
          EmploymentFields.EndDate,
          EmploymentFields.Income
        ])
      }

      case EmploymentStatus.RetiredMilitary:
      case EmploymentStatus.Retired: {
        return new Set([EmploymentFields.Income])
      }

      case EmploymentStatus.Student: {
        return new Set([
          EmploymentFields.Income,
          EmploymentFields.EmployerName,
          EmploymentFields.PhoneNumber
        ])
      }

      case EmploymentStatus.Unemployed: {
        return new Set()
      }

      default: {
        return new Set()
      }
    }
  }

/**
 * used for real values to check render conditions
 * and as a helper in @isHousingOrEmploymentFieldVisible
 */
export const isEmploymentFieldVisible = (
  employmentStatus: number | undefined | null,
  fieldId: string
): boolean => {
  if (employmentStatus == null) {
    return false
  }

  const fields = getFieldsVisibility(employmentStatus)
  return fields.has(fieldId)
}

/**
 * used for real values to check render conditions
 * and as a helper in @isHousingOrEmploymentFieldVisible
 */
export const isPreviousEmploymentOrHousingNeeded = (currentStartDate?: Date | null, status?: number | null): boolean => {
  const isNeededWithCurrentDate = currentStartDate == null
    ? false
    : isAfter(addYears(currentStartDate, 2), new Date())

  const isNeededWithEmploymentStatus = status == null
    ? false
    : checkVisibilityWithEmploymentStatus(status)

  return isNeededWithCurrentDate || isNeededWithEmploymentStatus
}

/**
 * used as a helper for employment and housing fields validation
 */
export const isHousingOrEmploymentFieldVisible = (
  fieldId: EmploymentField | HousingField,
  ctx: CreditAppValidationContext | undefined | null,
  isForCoApplicant: boolean = false,
  isForPreviousPeriod: boolean = false
): boolean => {
  const applicantRole = isForCoApplicant ? CreditAppParts.CoApplicantDetails : CreditAppParts.ApplicantDetails

  if (isHousingField(fieldId)) {
    const currStartDate = applicantRole === CreditAppParts.ApplicantDetails
      ? ctx?.[applicantRole]?.apartmentMoveInDate
      : ctx?.[applicantRole]?.currentAddressDetails?.apartmentMoveInDate

    return isForPreviousPeriod ? isPreviousEmploymentOrHousingNeeded(currStartDate) : true
  }

  const currStartDate = ctx?.[applicantRole]?.[CreditAppParts.CurrentEmployment]?.workingStartDate
  const currEmpStatus = ctx?.[applicantRole]?.[CreditAppParts.CurrentEmployment]?.employmentStatus
  const periodPart = `${isForPreviousPeriod ? CreditAppParts.PrevEmployment : CreditAppParts.CurrentEmployment}` as const

  const { employmentStatus } = ctx?.[applicantRole]?.[periodPart] ?? {}

  /**
   * employemnt fields may be invisible even if section for the specific period is visible based by employment status.
   */

  return isForPreviousPeriod
    ? isPreviousEmploymentOrHousingNeeded(currStartDate, currEmpStatus) && (
      fieldId === 'employmentStatus' || isEmploymentFieldVisible(employmentStatus, fieldId))
    : isEmploymentFieldVisible(employmentStatus, fieldId)
}

// ----------------------------- //
// to be removed after CoApplicant Form refactoring

export const prefixize =
  <T extends KeyVal<string>>(prefix: string, obj: T): { [P in keyof T]: string; } => {
    return mapValues(obj, val => `${prefix}.${val}`)
  }

export const PrefixedCoApplicantFinancialFields =
  prefixize('coApplicantFinancialDetails', CoApplicantFields)

export const PrefixedCoApplicantCurrentAddress =
  prefixize('coApplicantFinancialDetails.currentAddressDetails', AddressFields)
