import { type FC, type ReactNode } from 'react'
import { cnx } from '@carfluent/common'

export interface TabProps {
  children: ReactNode
  isSelected?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  keepMounted?: boolean
}

const Tab: FC<TabProps> = ({
  children,
  isDisabled = false,
  isSelected = false,
  isLoading = false,
  keepMounted = false
}) => {
  if (!isSelected && !keepMounted) {
    return null
  }

  return (
    <section className={cnx('tab-panel', isDisabled && 'disabled')} role='tabpanel' tabIndex={0}>
      {typeof children === 'function' ? children(isDisabled, isSelected, isLoading) : children}
    </section>
  )
}

export default Tab
