import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGArrowLeft: FC<IconProps> = ({
  width = 24,
  height = 24,
  color = '#101010'
}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'>
      <path fill-rule='evenodd' clip-rule='evenodd' d='M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z' fill={color} />
    </svg>
  )
}

export default IconSVGArrowLeft
