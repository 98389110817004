import { useContext, useState } from 'react'

import DocumentsApiProvider from 'api/documents.api'
import { CLOUD_ACCESS_TOKEN, type CloudAccessTokenDto } from 'api/types'
import { AuthCTX } from '../store/auth'
import { convertFromUTCStrict } from '../utils/parse_date'

import useAsyncEffect from './useAsyncEffect'
import { getParsedCloudAccessToken } from '../services/storage.service'

const useCloudAccessToken = (): CloudAccessTokenDto | null => {
  const [cloudAccessToken, setCloudAccessToken] = useState<CloudAccessTokenDto | null>(null)
  const { auth } = useContext(AuthCTX)

  useAsyncEffect(async () => {
    if (auth) {
      const token = getParsedCloudAccessToken()
      const expirationDate = convertFromUTCStrict(token?.expirationDate ?? '')
      const todayDateTimestamp = Date.now()
      const hoursDiff = (expirationDate.getTime() - todayDateTimestamp) / 3600000

      if (hoursDiff <= 2 || isNaN(hoursDiff)) {
        const response = await DocumentsApiProvider.getCloudAccessToken()
        localStorage.setItem(CLOUD_ACCESS_TOKEN, JSON.stringify(response))
      }

      setCloudAccessToken(getParsedCloudAccessToken())
    }
  }, [auth])

  return cloudAccessToken
}

export default useCloudAccessToken
