/**
 * Tries to find the first defined option, using the given array of keys.
 * It's used, for example, to find some property in object,
 * that can have prefixed keys: `['firstName', 'customerFirstName']` etc.
 *
 * AZ-TODO: check if this is necessary. Maybe it's better to normalize
 * namings on the parsing step.
 */
const getValueByKeys = <T = string>(keys: string[], obj: Record<string, T>): T | null => {
  for (const k of keys) {
    const val = obj[k]
    if (val !== undefined) {
      return val
    }
  }

  return null
}

export default getValueByKeys
