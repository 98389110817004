import { css } from '@emotion/css'

export default css`
  .MuiSkeleton-root {
    height: 16px;
  }

  div {
    padding: 16px;
  }

  .skeleton-toggle-section {
    display: flex;
    margin-bottom: 8px;
    background: #FAFAFA;
    justify-content: space-between;

    span:first-of-type {
      width: 148px;
    }

    span:last-of-type {
      width: 40px;
    }
  }

  .skeleton-dropdown-section {
    background: #F1F7FF;
    .MuiSkeleton-root {
      width: 148px;
    }
  }
`
