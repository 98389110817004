import { useMemo, type FC } from 'react'
import { useNavigate } from 'react-router-dom'

import TooltipCell from '../TooltipCell'

import {
  createStyleClass,
  createTooltipClass
} from './styles'

interface LinkCellProps {
  getValue: () => {
    title: string
    to: string
  }
}

const LinkCell: FC<LinkCellProps> = ({ getValue, ...props }) => {
  const navigate = useNavigate()
  const { title, to } = getValue()
  const {
    linkCls,
    tooltipCls
  } = useMemo(() => ({
    linkCls: createStyleClass(),
    tooltipCls: createTooltipClass()
  }), [])

  return (
    <span
      className={linkCls}
      onClick={() => navigate(to)}
    >
      <TooltipCell
        {...props}
        className={tooltipCls}
        getValue={() => title}
      />
    </span>
  )
}

export default LinkCell
