import type { SortingInfo } from '@carfluent/common'
import type { CancelationOptions } from 'api/types'

export const CANCEL_LOAD_DATA: CancelationOptions = { key: 'load-form-packs', cancelPrevious: true }

export enum EmptyTableMsg {
  NoItems = 'No records yet',
  NoItemsFound = 'No results found',
}

export const DEFAULT_SORTING: SortingInfo = { id: 'name', order: 'asc' }
