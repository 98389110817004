
import { type FC } from 'react'
import { cnx, FormNumberInput, FormDatePicker } from '@carfluent/common'
import { observer } from 'mobx-react-lite'

import AccountingApiProvider from 'api/accounting.api'
import CheckboxLabel from 'components/common/CheckboxLabel'
import DeleteModal from 'components/dialogs/DeleteModal'
import VendorDropdown from 'components/accounting/VendorDropdown'
import { MAX_PRICE_VALUE } from 'constants/validation'

import { FloorplanCostProps, FloorplanFields } from './types'
import CLASS_NAME from './styles'
import useFloorplanForm from './hook'

export * from './types'
export { GET_DEFAULT_FLOORPLAN } from './hook'

const POPOVER_CONFIG = {
  placement: 'top-end'
} as const

const FloorplanCost: FC<FloorplanCostProps> = (props) => {
  const {
    checkboxValue,
    deleteModalProps,
    errors,
    isLastLineErrorVisible,
    onAddVendor,
    onBlur,
    onToggleFloorplan,
    setFieldValue, // AZ-NOTE: don't change to `onChange`, it's a local wrapper.
    touched,
    values,
    minStartDate
  } = useFloorplanForm(props)

  return (
    <>
      <div className={cnx(CLASS_NAME, isLastLineErrorVisible && 'last-line-error')}>
        <div className='floorplan-item-wrapper'>
          <CheckboxLabel
            value={checkboxValue}
            onChange={onToggleFloorplan}
          />
        </div>

        {values.isFloorplanEnabled && (
          <>
            <div className='item-wrapper-dropdown'>
              <FormDatePicker
                className={cnx('floorplan-datepicker', errors.date != null && touched.date ? 'error' : '')}
                id='date'
                label='Floorplan date'
                minDate={minStartDate}
                onBlur={onBlur}
                onChange={setFieldValue}
                value={values.date}
                touched={touched.date}
                error={errors.date}
              />

              <VendorDropdown
                className='floorplan-dropdown'
                error={errors.vendor}
                id={FloorplanFields.Vendor}
                fetchHandler={AccountingApiProvider.getVendors}
                label='Floorplan company'
                onAddVendor={onAddVendor}
                onChange={setFieldValue}
                onBlur={onBlur}
                popoverOptions={POPOVER_CONFIG}
                touched={touched.vendor}
                value={values.vendor}
              />
            </div>

            <div className='item-wrapper-input'>
              <FormNumberInput
                className='floorplan-input'
                error={errors.amount}
                id={FloorplanFields.Amount}
                preset='price'
                isEmptyAllowed
                isNegativeAllowed={false}
                isSelectedOnFocus
                label='Amount'
                onBlur={onBlur}
                onChange={setFieldValue}
                max={MAX_PRICE_VALUE}
                touched={touched.amount}
                value={values.amount}
              />
            </div>
          </>
        )}
      </div>

      <DeleteModal
        {...deleteModalProps}
        title='Delete floorplan?'
        content='Once you Save changes, related journal entry will be deleted automatically.'
      />
    </>
  )
}

export default observer(FloorplanCost)
