import { type FC } from 'react'
import { CellWrapper } from '@carfluent/common'
import { LeadLabel as LeadLabelType } from 'api/types'
import { type BasicValueCellProps } from 'types'
import LeadLabel from 'components/common/LeadLabel'

const LeadLabelCell: FC<BasicValueCellProps> = (props) => {
  const value = props.getValue() as keyof typeof LeadLabelType

  return (
    <CellWrapper>
      {
        (value != null) && (
          <LeadLabel
            value={value}
            className='cf-label-item'
          />
        )
      }
    </CellWrapper>
  )
}

export default LeadLabelCell
