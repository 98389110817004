import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  status: {
    display: 'flex',
    fontFamily: 'Roboto, sans-serif',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: 0.15,
    '& img': {
      marginRight: 5
    }
  }
})

export default useStyles
