import { UseFormReturn } from '@carfluent/common'
import { PaymentMethod, type ReceiptItem } from 'api/types'

export enum PaymentMethodEnum {
  Full = 1,
  Deposit = 2,
  DownPayment = 3
}

export enum PaymentTransactionTypeEnum {
  CARD = 1,
  ACH = 2,
  CASH = 3,
  COINBASE = 4,
  CHECK = 5,
  ZELLE = 6,
  MULTIPLE = 7 // used only on the client side
}

export enum ReceiptStatusEnum {
  Issued = 1,
  Voided = 2,
  Refunded = 3
}

export type PaymentTransactionType = keyof typeof PaymentTransactionTypeEnum

export type PaymentAmounts = Record<PaymentTransactionType, number | string | null>

export interface PaymentFormData {
  amount: number
  billingDetails: ElectronicPaymentDetails | null
  description: string | null
  isBillingAddressProvided: boolean
  isManual: boolean
  transactionTypeId: PaymentTransactionTypeEnum | null
  paymentDate: Date | null
  payments: PaymentAmounts
  printReceiptCheck: boolean
}

export interface UsePaymentFormProps {
  dealId: string | number
  dealerId: string | number
  description: string | null
  paymentMethod: PaymentMethod | null
  onSubmit: (data?: PaymentFormData) => Promise<void>
  onClose: () => void
  isOpen: boolean
  setIsFormSubmitted: (isSubmitted: boolean) => void
  defaultValues?: ReceiptItem | null
  id: number | null
}

export interface UsePaymentFormReturn extends UseFormReturn<PaymentFormData> {
  isLoading: boolean
  remainingAmount: string
  isRemainingAmountError: boolean
  isDateError: boolean
}

export interface BillingAddress {
  address: string
  city: string
  state: string
  zipCode: string
}

export interface ElectronicPaymentDetails {
  cardNumber: string
  cardHolderName: string
  expirationDate: string | null
  cvv: string
  address: BillingAddress
}
