import type { CSSProperties } from 'react'
import {
  TOP_PLACEMENT,
  LEFT_PLACEMENT,
  RIGHT_PLACEMENT,
  CENTER_PLACEMENT,
  BOTTOM_PLACEMENT
} from './constants'
import type {
  Coords,
  ConfigProps,
  PlacementProps,
  GetBoundingRectReturn
} from './types'

interface CalculateConfigProps extends PlacementProps, Coords {
  portalWidth: number
  anchorRect: GetBoundingRectReturn
  maxHeight?: 'auto' | number
  minWidth?: number
  maxWidth?: number
}

export const calculateConfig = ({
  vertical,
  horizontal,
  maxHeight: mH,
  anchorRect: { x, y, height, width },
  x: transformX,
  y: transformY,
  minWidth,
  maxWidth,
  portalWidth
}: CalculateConfigProps): ConfigProps => {
  const {
    scrollX,
    scrollY,
    innerHeight,
    innerWidth
  } = window

  /**
   * default bottom placement calculate
   * */
  let top: number = scrollY + y + height + transformY
  /**
   * default horizontal placement calculate
   * */
  let left: number = scrollX + x + transformX + (width / 2)
  let translateX: string | number = '-50%'
  let maxHeight = mH

  if (vertical === TOP_PLACEMENT) {
    top = scrollY + y + transformY
  }

  if (horizontal === LEFT_PLACEMENT) {
    left = scrollX + x + transformX
  }

  if (horizontal === RIGHT_PLACEMENT) {
    left = scrollX + x + width + transformX
    translateX = '-100%'
  }

  if (horizontal === CENTER_PLACEMENT) {
    const isRightOutOfTheScreen = (left - scrollX + (portalWidth / 2)) > innerWidth
    const isLeftOutOfTheScreen = (left - (portalWidth / 2)) < 0

    /**
     * Set right placement if popover is out of the screen (right side)
     * */
    if (isRightOutOfTheScreen) {
      left = innerWidth + scrollX
      translateX = '-100%'
    }

    /**
     * Set left placement if popover is out of the screen (left side)
     * */
    if (isLeftOutOfTheScreen) {
      left = scrollX + x + transformX
    }
  }

  if (maxHeight == null) {
    maxHeight = innerHeight - top + scrollY
  }

  return {
    top,
    left,
    translateX,
    maxHeight,
    minWidth,
    maxWidth
  }
}

export const calculateConfigWithoutAnchor = ({
  vertical,
  horizontal,
  x,
  y,
  ...otherProps
}: Omit<CalculateConfigProps, 'anchorRect' | 'portalWidth'>): ConfigProps => {
  let top: CSSProperties['top'] = 'auto'
  let bottom: CSSProperties['bottom'] = 'auto'
  let left: CSSProperties['left'] = 'auto'
  let right: CSSProperties['right'] = 'auto'
  let translateX: CSSProperties['translate'] = 0
  let translateY: CSSProperties['translate'] = 0

  if (vertical === TOP_PLACEMENT) {
    top = y
  }

  if (vertical === CENTER_PLACEMENT) {
    top = '50%'
    translateY = '-50%'
  }

  if (vertical === BOTTOM_PLACEMENT) {
    bottom = y
  }

  if (horizontal === LEFT_PLACEMENT) {
    left = x
  }

  if (horizontal === RIGHT_PLACEMENT) {
    right = x
  }

  if (horizontal === CENTER_PLACEMENT) {
    left = '50%'
    translateX = '-50%'
  }

  return {
    top,
    bottom,
    left,
    right,
    translateX,
    translateY,
    position: 'fixed',
    ...otherProps
  }
}
