import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, useModal, DeepRequired, UI, useLoader } from '@carfluent/common'
import html2canvas from 'html2canvas'
import { EditorState } from 'draft-js'

import { type UploadedAssetDto } from 'api/types'
import { type EmailSnippetDto, type EmailTemplateModel } from 'api/types/responses'
import NotificationsAPIProvider from 'api/notifications.api'
import CRMApiProvider from 'api/crm.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { Routes } from 'constants/route_helper'
import { isTruthy } from 'utils/general'
import { ErrorMsg } from 'utils/yup'

import type {
  EmailTemplateFormData,
  SnippetDropdownConfig,
  UseEmailTemplateFormProps,
  UseEmailTemplateFormReturn
} from './types'
import {
  getDefaultFormData,
  FieldIds,
  getDefaultEmailTemplatePayloadData,
  SUBJECT_SNIPPET_LABEL,
  BODY_SNIPPET_LABEL,
  TEMPLATE_IN_USE_CODE
} from './constants'
import validationRules from './validator'
import { serializeData } from './serializer'
import { configurateSnippets } from './utils'

const DEFAULT_FORM_DATA = getDefaultFormData()
const DEFAULT_PAYLOAD_DATA = getDefaultEmailTemplatePayloadData()
const { parseErrorsObject } = UI

const useEmailTemplateForm = ({
  editorSelector
}: UseEmailTemplateFormProps): UseEmailTemplateFormReturn => {
  const navigate = useNavigate()
  const { showAlert, showSuccess } = useCustomSnackbar()
  const { id: templateId = '' } = useParams<{ id: string }>()
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplateModel>(DEFAULT_PAYLOAD_DATA)
  const [bodySnippets, setBodySnippets] = useState<EmailSnippetDto[]>([])
  const [subjectSnippets, setSubjectSnippets] = useState<EmailSnippetDto[]>([])
  const [errorName, setErrorName] = useState<string | null>(null)

  const { isLoading, startLoader, stopLoader } = useLoader()

  const templateDeleteProps = useModal()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const getEmailSnippets = useCallback(async () => {
    try {
      startLoader()

      const { items: snippets } = await CRMApiProvider.getEmailSnippets()

      setBodySnippets(configurateSnippets(snippets, 'isUsableInBody'))
      setSubjectSnippets(configurateSnippets(snippets, 'isUsableInSubject'))
    } catch (err) {
      console.error('error', err)
    } finally {
      stopLoader()
    }
  }, [startLoader, stopLoader])

  const getEmailTemplate = useCallback(async (templateId: number | string) => {
    try {
      startLoader()

      const templateData = await NotificationsAPIProvider.getLeadEmailTemplate(templateId)

      setEmailTemplate(templateData)
    } catch (err) {
      console.error('error', err)
    } finally {
      stopLoader()
    }
  }, [setEmailTemplate, startLoader, stopLoader])

  const getEditorPreviewBlob = useCallback(async (): Promise<null | Blob> => {
    if (editorSelector == null) {
      return null
    }

    const elem = document.querySelector<HTMLElement>(`.${editorSelector}`)
    if (elem == null) {
      return null
    }

    const canvas = await html2canvas(elem, { logging: false })
    return await new Promise((resolve, reject) => {
      try {
        canvas.toBlob(resolve)
      } catch (err) {
        reject(err)
      }
    })
  }, [editorSelector])

  const updateEmailTemplate = useCallback(async (values) => {
    try {
      setIsFormSubmitting(true)
      setErrorName(null)

      const blob = await getEditorPreviewBlob()
      let imageUrl: UploadedAssetDto | null = null
      if (blob != null) {
        const data = new FormData()
        data.append('File', blob)
        imageUrl = await NotificationsAPIProvider.uploadEmailPreview(data)
      }

      const payload = {
        id: emailTemplate.id,
        dealerId: emailTemplate.dealerId,
        logoUrl: imageUrl?.fileUrl ?? null,
        ...values
      }
      const serializedPayload = serializeData(payload)
      const templateData = await NotificationsAPIProvider.updateLeadEmailTemplate(serializedPayload)

      setEmailTemplate(templateData)
    } catch (err: any) {
      const msg = parseErrorsObject(err?.response?.data.errors)
      if (isTruthy(msg)) {
        throw new Error(ErrorMsg.nameAlreadyTaken)
      }
    } finally {
      setIsFormSubmitting(false)
    }
  }, [
    emailTemplate.id, emailTemplate.dealerId,
    getEditorPreviewBlob
  ])

  const deleteAction = useCallback(async (force) => {
    try {
      await NotificationsAPIProvider.deleteLeadEmailTemplate({ id: templateId, force })
      showSuccess('Template deleted successfully.')
      navigate(Routes.CRMEmailTemplatesList)
    } catch (err: any) {
      if (err?.response.data.code === TEMPLATE_IN_USE_CODE) {
        templateDeleteProps.onOpenModal()
      } else {
        showAlert(err)
      }
    }
  }, [navigate, templateId, showAlert])

  const submitAction = useCallback(async (values: DeepRequired<EmailTemplateFormData>) => {
    await updateEmailTemplate(values)
  }, [updateEmailTemplate])

  const onResetForm = useCallback((resetForm) => {
    resetForm(DEFAULT_FORM_DATA)
  }, [])

  const onActionResult = useCallback((res) => {
    if (res.kind === 'Ok') {
      showAlert('Email Template was updated.', { variant: 'success' })
      navigate(Routes.CRMEmailTemplatesList)
    } else {
      setErrorName(res.result.message)
    }
  }, [showAlert])

  const form = useForm<EmailTemplateFormData, { body: true }, 'body'>({
    baseValues: DEFAULT_FORM_DATA,
    validationRules,
    submitAction,
    deleteAction,
    onActionResult
  })

  const { onChange, setFieldError, resetForm, isValid } = form

  const onEditorChange = useCallback((value: EditorState) => {
    onChange(FieldIds.Body, value)
  }, [onChange])

  const onSubjectDropdownChange = useCallback((_, value: EmailSnippetDto | null): void => {
    if (value == null) {
      return
    }

    onChange(FieldIds.Subject, `${form.values.subject ?? ''} ${value?.placeholder}`)
  }, [onChange, form.values.subject])

  const onCancel = useCallback(() => {
    onResetForm(resetForm)
    navigate(Routes.CRMEmailTemplatesList)
  }, [resetForm, onResetForm, navigate])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      await getEmailTemplate(templateId)
      await getEmailSnippets()
    }

    void runEffect()
  }, [templateId, getEmailTemplate, getEmailSnippets])

  useEffect(() => {
    if (emailTemplate != null) {
      onChange(FieldIds.Body, emailTemplate.body)
      onChange(FieldIds.Name, emailTemplate.name)
      onChange(FieldIds.Subject, emailTemplate.subject)
    }
  }, [emailTemplate, onChange])

  useEffect(() => {
    if (errorName != null) {
      setFieldError(FieldIds.Name, errorName)
    }
  }, [errorName, setFieldError])

  // ========================================== //
  //                   CONFIG                   //
  // ========================================== //

  const snippetsConfig: SnippetDropdownConfig[] = useMemo(() => [
    {
      label: SUBJECT_SNIPPET_LABEL,
      key: 'subject-snippets',
      options: subjectSnippets,
      onDropdownChange: onSubjectDropdownChange,
      shouldUseDefaultOnChange: false
    },
    {
      label: BODY_SNIPPET_LABEL,
      key: 'body-snippets',
      options: bodySnippets,
      shouldUseDefaultOnChange: true
    }
  ], [bodySnippets, onSubjectDropdownChange, subjectSnippets])

  // ========================================== //

  return {
    isDisabledActionBtn: !isValid,
    isLoading,
    isFormSubmitting,
    emailTemplate,
    templateDeleteProps,
    onDeleteTemplate: deleteAction,
    onCancel,
    snippetsConfig,
    onEditorChange,
    ...form
  }
}

export default useEmailTemplateForm
