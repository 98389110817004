import { type FC, type ReactNode } from 'react'
import { Button, cnx } from '@carfluent/common'
import Tooltip from 'components/common/Tooltip'

import { TOOLTIP_POPOVER_CLASSES, TOOLTIP_CONTENT_CLASSES } from './styles'

interface SaveCloseButtonsPanelProps {
  className?: string
  handleSubmit: (e: any) => void
  handleReset?: (e: any) => void // according to useFormik documentation
  handleBack?: () => void
  handleDelete?: () => void
  isSubmitDisabled?: boolean
  isSubmitting?: boolean
  isBackHidden?: boolean
  isCloseHidden?: boolean
  isDeleteHidden?: boolean
  isReadOnly?: boolean
  titleCloseButton?: string
  titleSaveButton?: string
  titleBackButton?: string
  titleDeleteButton?: string
  tooltipSaveButton?: string
  validationMessage?: ReactNode | null
}

const SaveCloseButtonsPanel: FC<SaveCloseButtonsPanelProps> = ({
  handleSubmit,
  handleReset,
  handleBack,
  handleDelete,
  className,
  isSubmitDisabled,
  isSubmitting,
  isCloseHidden = false,
  isDeleteHidden = false,
  isReadOnly = false,
  isBackHidden = false,
  titleCloseButton = 'CLOSE',
  titleSaveButton = 'SAVE',
  titleBackButton = 'BACK',
  titleDeleteButton = 'DELETE',
  tooltipSaveButton,
  validationMessage
}) => {
  return (
    <div className={cnx('g-bottom-actions-panel', className)}>
      <div className='g-button-container g-button-container--left'>
        {!isDeleteHidden && (handleDelete != null) && (
          <Button
            className='g-button-delete'
            dataTestId='btn-delete'
            onClick={handleDelete}
            variant='outlined'
          >
            {titleDeleteButton}
          </Button>
        )}

        {(!isBackHidden && handleBack != null) && (
          <Button
            dataTestId='btn-back'
            onClick={handleBack}
            variant='outlined'
          >
            {titleBackButton}
          </Button>
        )}
      </div>

      <div className='g-button-container g-button-container--right'>
        {(validationMessage != null) && validationMessage}

        {(!isCloseHidden && handleReset != null) && (
          <Button
            dataTestId='btn-close'
            onClick={handleReset}
            variant='outlined'
          >
            {titleCloseButton}
          </Button>
        )}

        <Tooltip
          className={TOOLTIP_CONTENT_CLASSES}
          popperClassName={TOOLTIP_POPOVER_CLASSES}
          title={tooltipSaveButton ?? ''}
        >
          <Button
            dataTestId='btn-save'
            isDisabled={isSubmitDisabled ?? isReadOnly}
            isLoading={isSubmitting}
            onClick={handleSubmit}
          >
            {titleSaveButton}
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

export default SaveCloseButtonsPanel
