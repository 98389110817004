import { type FC, type ReactNode, useMemo } from 'react'
import { Button, FormDropdown } from '@carfluent/common'
import type { OptionRendererConfig } from '@carfluent/common/dist/UI/Dropdown/types'

import OverlayedPageHOC from 'components/PageOverlay/OverlayedPageHOC'
import FiltersPanel from 'components/common/FiltersPanel'
import Table from 'components/common/Table'
import { getDisplayItem, renderOptionWithCounter } from 'utils/selectHelpers'

import { DictionaryItem } from 'api/types'
import { type CampaignTotalDto } from 'api/types/responses'

import useMarketingCampaignList from './hooks/index'

import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

const statusFilterTitle = 'Status'

const MarketingCampaigns: FC = () => {
  const {
    isLoading,
    emptyTableMessage,
    rows,
    columns,
    search,
    sorting,
    statusList,
    selectedStatuses,
    counters,
    onAddNewCampaign,
    onOpenCampaignDetails,
    onSearchChange,
    onBottomReached,
    onSearch,
    onStatusFilterChange,
    setSorting
  } = useMarketingCampaignList()

  const isAllSelected = selectedStatuses.length === statusList?.length
  const isNoneSelected = selectedStatuses.length === 0

  const renderSelectDisplay = (): ReactNode => {
    if (isNoneSelected) {
      return statusFilterTitle
    }

    const txt = `${statusFilterTitle} (${isAllSelected ? 'All' : `${selectedStatuses.length}`})`
    return getDisplayItem(txt)
  }

  const renderOption = useMemo(() => (value: DictionaryItem, config: OptionRendererConfig): JSX.Element => {
    const { isSelected, renderCheckbox } = config
    const enumKey = statusList?.find((el) => el.name === value.name)?.name ?? ''
    const option = renderOptionWithCounter({
      isLoading,
      counter: counters[enumKey.toLowerCase() as keyof CampaignTotalDto] ?? 0,
      value: value.name
    })

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: 8, width: '24px', height: '24px' }}>{renderCheckbox(isSelected)}</div>
        <div>{option}</div>
      </div>
    )
  }, [counters, isLoading])

  return (
    <div className={CLASS_NAME}>
      <FiltersPanel
        searchProps={{
          isLoading,
          onChange: onSearchChange,
          value: search,
          placeholder: 'Search by campaign name',
          withDeleteIcon: true,
          onSearch
        }}
        renderActions={() => (
          <Button
            onClick={onAddNewCampaign}
            className='button-new-campaign'
          >
            NEW CAMPAIGN
          </Button>
        )}
        filterProps={{
          render: () => {
            return (
              <div className='car-filter-wrapper'>
                <FormDropdown
                  id='statusIds'
                  isMultiselect
                  value={selectedStatuses}
                  options={statusList ?? []}
                  popoverClassName={POPOVER_CLASS_NAME}
                  className='status-filter-dropdown'
                  onChange={onStatusFilterChange}
                  renderSelectDisplay={renderSelectDisplay}
                  renderOption={renderOption}
                />
              </div>
            )
          }
        }}
      />

      <Table
        columns={columns}
        data={rows}
        emptyTableMessage={emptyTableMessage}
        isLoading={isLoading}
        sorting={sorting}
        onBottomReached={onBottomReached}
        onRowClick={onOpenCampaignDetails}
        headerDataTestId='email-templates-table-header'
        bodyDataTestId='email-templates-table-body'
        onSortingChange={setSorting}
      />
    </div>
  )
}

// export default MarketingCampaigns
export default OverlayedPageHOC(MarketingCampaigns)
