import type { InterestedLeadsLisDto } from 'api/types'
import type { InterestedLeadsListData } from './types'

const parseListData = (data: InterestedLeadsLisDto[]): InterestedLeadsListData[] => {
  return data.map(parseRowData)
}

const parseRowData = (data: InterestedLeadsLisDto): InterestedLeadsListData => {
  return {
    ...data,
    label: data.label ?? '',
    name: `${data.firstName} ${data.lastName}`
  }
}

export default parseListData
