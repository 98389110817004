import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { STATE_OPTIONS } from 'constants/constants'

import CLASS_NAME from './styles'

export interface DealPaidStateProps {
  state: number | null
}

const DealPaidState: FC<DealPaidStateProps> = ({ state }) => {
  const name = state !== null ? STATE_OPTIONS.find((item) => item.id === state)?.name : ''
  const className = state !== null ? STATE_OPTIONS.find((item) => item.id === state)?.className : ''

  return (
    <span className={cnx(CLASS_NAME, `deal-state-${className != null ? className : 'no-state'}`)}>
      {name}
    </span>
  )
}

export default DealPaidState
