import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  && {
    .cf-modal-title {
      color: #101010;
    }

    .copy-month-modal-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      grid-template-areas: 
        'source monthToFill'
        'warning warning';

      .cf-sourceMonth {
        grid-area: source;
      }

      .cf-monthToFill {
        grid-area: monthToFill;
      }

      .warning-text {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #101010;

        grid-area: warning;
      }
    }

    .copy-month-modal-buttons-wrapper {
      display: flex;
      gap: 16px;
      margin-top: 48px;

      .MuiButton-label {
        font-size: 14px;
        font-weight: 500;
      }

      .cancel-copy-month-btn {
        grid-area: cancel-button;
        border: 1px solid #101010;
        color: #101010;
        background: #ffffff;

        :hover {
          background: #ffffff;
          color: #212121;
          border: 1px solid #212121;
          opacity: unset;
        }
      }
    }
  }
`)

export const PICKER_POPPER = css(`
  && {
    box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.02),
              0px 10px 16px rgba(0, 0, 0, 0.08),
              0px 6px 30px rgba(0, 0, 0, 0.03);
  }
`)
