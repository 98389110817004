import type { FC } from 'react'
import type { IconProps } from 'types'

const MessageIcon: FC<IconProps> = ({ color = '#21212180', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_86546_5583)'>
        <path d='M13.332 4.00016H12.6654V9.3335C12.6654 9.70016 12.3654 10.0002 11.9987 10.0002H3.9987V10.6668C3.9987 11.4002 4.5987 12.0002 5.33203 12.0002H11.9987L14.6654 14.6668V5.3335C14.6654 4.60016 14.0654 4.00016 13.332 4.00016ZM11.332 7.3335V2.66683C11.332 1.9335 10.732 1.3335 9.9987 1.3335H2.66536C1.93203 1.3335 1.33203 1.9335 1.33203 2.66683V11.3335L3.9987 8.66683H9.9987C10.732 8.66683 11.332 8.06683 11.332 7.3335Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_86546_5583'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MessageIcon
