import { css } from '@emotion/css'

export const BASE_CLASS_NAME = css(`
  &.cf-table-row {
    cursor: pointer;
    border-top: 6px solid #FFF;
    
    .cf-cell-wrapper {
      height: 48px;
      padding: 12px 8px;
    }
  }

  &.reconciled-row {
    tr,
    td,
    .cf-cell-wrapper {
      background-color: #FFF4E0;
    }
  }

  .cf-table-cell {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding: 0px;
  }

  .cf-table-cell:last-child {
    padding-right: 0px;
  }
`)

export const HOVER_CLASS_NAME = css(`
  &.cf-table-row-hover {
    background-color: #FFF;
  }

  &.cf-table-row-hover:hover > td > div {
    background-color: #FAFAFA;
  }
  
  &.cf-table-row-hover.reconciled-row {
    background-color: #FFF4E0;
  }

  &.cf-table-row-hover.reconciled-row:hover > td > div {
    background-color: #FFE8BE;
  }

  &.cf-table-row-hover:hover > td:first-child > div {
    border-left: 4px solid transparent;
    border-top-left-radius: 3.5px;
    border-bottom-left-radius: 3.5px;
  }

  &.row-expandable:hover > td:first-child > div {
    border-left: 4px solid transparent !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`)
