import type { ColumnDef } from '@carfluent/common/dist/UI'

import { DealFiltersState } from 'api/types'

import TextCellHOC from 'components/common/Table/cells/TextCell'
import { FilterHeaderCell } from 'components/common/Table/cells/FilterHeaderCell'
import DealStatusCell from 'components/deals/cells/StatusCell'
import DateCell from 'components/common/Table/cells/DateCell'
import ClientCell from 'components/deals/cells/ClientCell'
import ContactsCell from 'components/deals/cells/ContactsCell'
import PaymentCell from 'components/deals/cells/PaymentCell'
import VehicleCell from 'components/deals/cells/VehicleCell'
import WorkflowStepCell from 'components/deals/cells/WorkflowStepCell'
import ActionCell from 'components/deals/cells/ActionCell'
import StateCell from 'components/deals/cells/StateCell'

import { DEAL_ACTIONS, STATE_OPTIONS } from 'constants/constants'

import type { DealRowData, GetColumnsDefinitionProps } from './types'
import { defaultTags } from './constants'

const DEFAULT_TAGS = defaultTags()

export const getAdvancedColumn = ({
  filters,
  onChange
}: GetColumnsDefinitionProps): Array<ColumnDef<DealRowData>> => {
  const isSoldTab = filters.workflowSearchState === DealFiltersState.Sold

  return ([
    {
      id: 'nextAction',
      accessorKey: 'nextAction',
      cell: ActionCell,
      header: <FilterHeaderCell
        title='Action'
        popUpTitle='Show Action:'
        filters={filters.nextAction}
        items={DEAL_ACTIONS}
        onChange={onChange('nextAction')}
        isSelectAllAllowed
        popoverProps={{ minWidth: 260, transform: { x: 80 } }}
              /> as unknown as () => void,
      size: 93,
      sortable: false,
      enableResizing: true
    },
    {
      accessorKey: 'client',
      cell: ClientCell,
      header: TextCellHOC({ text: 'Client' }),
      minSize: 147,
      maxSize: 242
    },
    {
      id: 'status',
      accessorKey: 'status',
      cell: DealStatusCell,
      header: TextCellHOC({ text: 'Status' }),
      loading: 'two_lines',
      size: 110,
      sortable: false
    },
    {
      id: 'car',
      accessorKey: 'vehicle',
      cell: VehicleCell,
      header: TextCellHOC({ text: 'Car' }),
      loading: 'rectangle_with_two_lines',
      minSize: 255,
      maxSize: 337
    },
    {
      accessorKey: 'payment',
      cell: PaymentCell,
      header: TextCellHOC({ text: 'Payment' }),
      size: 130,
      sortable: false
    },
    {
      id: 'dealPaidStatusId',
      accessorKey: 'dealPaidStatusId',
      cell: StateCell,
      header: <FilterHeaderCell
        title='State'
        popUpTitle='Show Action:'
        filters={filters.dealPaidStatusId}
        items={STATE_OPTIONS}
        onChange={onChange('dealPaidStatusId')}
        isMultiSelect={false}
        popoverProps={{ minWidth: 260 }}
              /> as unknown as () => void,
      size: 110,
      sortable: false,
      className: 'state-cell',
      enableResizing: true
    },
    {
      id: 'workflowAccountingState',
      accessorKey: 'workflowAccountingState',
      cell: WorkflowStepCell,
      header: <FilterHeaderCell
        title='Accounting'
        popUpTitle='Show tags:'
        filters={filters.workflowAccountingStates}
        items={DEFAULT_TAGS}
        onChange={onChange('workflowAccountingStates')}
        isSelectAllAllowed
        popoverProps={{ minWidth: 245 }}
              /> as unknown as () => void,
      minSize: 150,
      maxSize: 200,
      sortable: false
    },
    {
      id: 'step',
      accessorKey: 'step',
      cell: WorkflowStepCell,
      header: TextCellHOC({ text: 'Step' }),
      minSize: 150,
      maxSize: 200,
      sortable: false
    },
    {
      accessorKey: 'contacts',
      cell: ContactsCell,
      header: TextCellHOC({ text: 'Contacts' }),
      minSize: 150,
      maxSize: 213,
      sortable: false
    },
    {
      accessorKey: isSoldTab ? 'saleDate' : 'date',
      cell: (props: any) => DateCell({ format: isSoldTab ? 'MM/dd/yy' : undefined, ...props }),
      header: TextCellHOC({ text: isSoldTab ? 'Sale date' : 'Created on' }),
      minSize: 116,
      maxSize: 120
    }
  ].filter((col) => {
    return isSoldTab
      ? (col.id !== 'step') && (col.id !== 'nextAction')
      : (col.id !== 'workflowAccountingState') && (col.id !== 'dealPaidStatusId')
  }) as Array<ColumnDef<DealRowData>>)
}

export const getCancelFilterStateColumn = (): Array<ColumnDef<DealRowData>> => [
  {
    accessorKey: 'client',
    cell: ClientCell,
    header: TextCellHOC({ text: 'Client' }),
    minSize: 190,
    maxSize: 233
  },
  {
    id: 'status',
    accessorKey: 'status',
    cell: DealStatusCell,
    header: TextCellHOC({ text: 'Status' }),
    loading: 'rectangle_with_two_lines',
    size: 110,
    sortable: false
  },
  {
    id: 'car',
    accessorKey: 'vehicle',
    cell: VehicleCell,
    header: TextCellHOC({ text: 'Car' }),
    loading: 'rectangle_with_two_lines',
    minSize: 300,
    maxSize: 336
  },
  {
    accessorKey: 'payment',
    cell: PaymentCell,
    header: TextCellHOC({ text: 'Payment' }),
    minSize: 100,
    maxSize: 186,
    sortable: false
  },
  {
    accessorKey: 'step',
    cell: WorkflowStepCell,
    header: TextCellHOC({ text: 'Step' }),
    size: 150,
    sortable: false
  },
  {
    accessorKey: 'contacts',
    cell: ContactsCell,
    header: TextCellHOC({ text: 'Contacts' }),
    minSize: 150,
    maxSize: 213,
    sortable: false
  },
  {
    id: 'date',
    accessorKey: 'date',
    cell: DateCell,
    header: TextCellHOC({ text: 'Created on' }),
    size: 130
  }
]
