import { css } from '@emotion/css'

export default css`
  padding: 0 16px;

  li {
    gap: 8px;
    display: grid;
    align-items: center;
    padding: 21px 30px 21px 0;
    justify-content: space-between;
    grid-template-columns: 38px 1fr;

    .MuiSkeleton-text {
      height: 16px;
    }
  }
`
