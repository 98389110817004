import { css } from '@emotion/css'

export default css(`
  .tabs-header {
    display: flex;
    margin-bottom: 4px;
  }

  .tab-panel {
    border-radius: 2px 12px 12px 12px;

    > div {
      border-radius: 2px 12px 12px 12px;
    }
    
    &.disabled {
      opacity: 0.4;
      
      * {
        pointer-events: none;
      }
    }
  }
`)
