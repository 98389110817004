import React, { type ReactNode, type FC } from 'react'
import { cn } from '@carfluent/common'

import CLASS_NAME from './styles'

interface TabPanelProps {
  children: ReactNode
  index: number
  value: number
  isDisabled?: boolean
}

interface A11yPropsReturn {
  id: string
  'aria-controls': string
  disableRipple: boolean
}

export const a11yProps = (index: number): A11yPropsReturn => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    disableRipple: true
  }
}

const TabPanel: FC<TabPanelProps> = ({
  children,
  value,
  index,
  isDisabled = false
}) => {
  return (
    <div
      className={cn(CLASS_NAME, isDisabled && 'cf-tab-disabled')}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && children}
    </div>
  )
}

export default TabPanel
