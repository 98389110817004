import { type AccountBankStatementDetailsDto } from 'api/types'
import { toDate } from 'utils/parse_date'

import { type ReconciliationSessionData } from './types'

export const parseReconciliationSetup = (data: AccountBankStatementDetailsDto): ReconciliationSessionData => {
  const { lastFinishedReconciliationSession, inProgressReconciliationSession } = data
  return ({
    ...data,
    lastFinishedReconciliationSession: lastFinishedReconciliationSession != null
      ? {
          ...lastFinishedReconciliationSession,
          beginningDate: lastFinishedReconciliationSession != null ? toDate(lastFinishedReconciliationSession.beginningDate) : null,
          endDate: lastFinishedReconciliationSession != null ? toDate(lastFinishedReconciliationSession.endDate) : null,
          processStartDate: lastFinishedReconciliationSession != null ? toDate(lastFinishedReconciliationSession.processStartDate) : null,
          processEndDate: lastFinishedReconciliationSession != null ? toDate(lastFinishedReconciliationSession.processEndDate) : null
        }
      : null,
    inProgressReconciliationSession: inProgressReconciliationSession != null
      ? {
          ...inProgressReconciliationSession,
          beginningDate: inProgressReconciliationSession != null ? toDate(inProgressReconciliationSession.beginningDate) : null,
          endDate: inProgressReconciliationSession != null ? toDate(inProgressReconciliationSession.endDate) : null,
          processStartDate: inProgressReconciliationSession != null ? toDate(inProgressReconciliationSession.processStartDate) : null,
          processEndDate: inProgressReconciliationSession != null ? toDate(inProgressReconciliationSession.processEndDate) : null
        }
      : null
  })
}

export default parseReconciliationSetup
