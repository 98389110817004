import { css } from '@emotion/css'

export default css(`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #fff;
  box-shadow: 0px 24px 38px rgba(58, 71, 78, 0.1);
  border-radius: 12px;
  padding: 24px 0;

  .credit-application-submitted-info {
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: rgba(0, 0, 0, 0.38);
    letter-spacing: 0.25px;
  }

  .credit-application-form-content {
    display: flex;
    flex-direction: column;
    width: 630px;
  };

  .credit-application-title-block {
    display: flex;
    width: 100%;
    justify-content: space-between;
  };

  .credit-application-form-title {
    font-weight: 400;
    font-size: 34px;
    line-height: 36px;
    color: #101010;
    padding-bottom: 40px;
    user-select: none;
  };

  .credit-application-text-disclosure {
    margin-top: 60px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(33, 33, 33, 0.4);
    letter-spacing: 0.15px;
  };

  .credit-application-link-disclosure {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    color: rgba(33, 33, 33, 0.4);

    :hover {
      text-decoration: underline;
    }
  };

  .credit-application-errors-block {
    margin-bottom: 40px;
  }

  .credit-application-divider {
    border: none;
    height: 1px;
    background-color: #C99B86;
    margin: 32px 0;
  }
`)
