import { FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Loader, PRESET_LABELS } from '@carfluent/common'

import ReportLayout from 'components/reports/ReportLayout'
import AccountingHOK from 'pages/accounting/AccountingHOC'

import TransactionsTable from './components/TransactionsTable'
import AccountFilter from './components/AccountFilter'

import useReportTransactions from './hook'
import createStyleClass from './styles'

const ReportTransactions: FC = () => {
  const className = useMemo(createStyleClass, [])
  const {
    data,
    account,
    setAccount,
    isLoading,
    ...layoutProps
  } = useReportTransactions()

  return (
    <ReportLayout
      {...layoutProps}
      title='Transaction list'
      className={className}
      size='lg'
      rootClassName={className}
      excludePresets={[PRESET_LABELS.YEAR_TO_DATE]}
      initialPresetName={PRESET_LABELS.THIS_QUARTER}
      additionalFiltersNodes={(
        <AccountFilter
          isLoading={isLoading}
          account={account}
          setAccount={setAccount}
        />
      )}
    >
      {isLoading
        ? <Loader />
        : <TransactionsTable data={data} />}
    </ReportLayout>
  )
}

export default AccountingHOK(observer(ReportTransactions))
