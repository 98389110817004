import type { FC } from 'react'
import { cn } from '@carfluent/common'

import { joinPartsBySpace } from 'utils/view_helper'

import { MAIN_CLASSES } from './styles'

export interface ControlIdentifierBadgeProps {
  className?: string
  control: DealRenderable | ControlRenderable | null
}

export interface ControlRenderable {
  make: string | null
  model: string | null
  soldDate: string | null
  trim: string | null
  vehicleState: number | null
  year: number
  vin: string | null
}

export interface DealRenderable {
  make: string | null
  model: string | null
  trim: string | null
  modelYear: number | null
  vin: string | null
}

/**
 * Displays Identifier badge for control (vehicle).
 * If control is from Deal - status line is hidden.
 */
export const ControlIdentifierBadge: FC<ControlIdentifierBadgeProps> = ({
  className,
  control
}) => {
  if (control == null) {
    return null
  }

  if (isControlRenderable(control)) {
    const identifier = joinPartsBySpace(control.year, control.make, control.model, control.trim)

    return (
      <div className={cn(MAIN_CLASSES, 'cf-control-identifier-badge', className)}>
        <div className='content'>
          <p className='cf-identifier'>{identifier}</p>
        </div>

        <div className='content'>
          <div className='cf-vin'>{control?.vin ?? ''}</div>
        </div>
      </div>
    )
  }

  const identifier = joinPartsBySpace(control.modelYear, control.make, control.model, control.trim)

  return (
    <div className={cn(MAIN_CLASSES, 'cf-control-identifier-badge', className)}>
      <p className='cf-identifier'>{identifier}</p>
      <div className='cf-vin'>{control?.vin ?? ''}</div>
    </div>
  )
}

const isControlRenderable = (x: DealRenderable | ControlRenderable): x is ControlRenderable =>
  (x as any).vehicleState != null

export default ControlIdentifierBadge
