import { toDate } from 'utils/parse_date'
import type { OpenBalancesListItem as ApiRow } from 'api/types'
import type { OpenBalanceRow as ParsedRow } from './types'

export const parseOpenBalances = (rows: ApiRow[]): ParsedRow[] => {
  return rows.map(parseOpenBalanceRow)
}

export const parseOpenBalanceRow = (row: ApiRow): ParsedRow => ({
  ...row,
  checked: false,
  date: toDate(row.date)
})
