import { css } from '@emotion/css'

export default css(`
  --row-margin: 24px;

  background-color: #fff;
  padding: var(--row-margin) 16px;
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-row-gap: var(--row-margin);

  .vehicle-inputs {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: minmax(33%, 475px) minmax(16%, 229px);
  }

  .cf-collapse:not(.is-expanded) .collapse-btn {
    visibility: hidden;
  }

  .vehicle-info-section {
    background-color: #F7FAFF;
    padding: 16px;
    height: 56px;
    display: flex;
    justify-content: flex-start;
    border-radius: 12px;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-column-gap: 4px;

      :not(:last-child) {
        margin-right: 24px;
      }
      
      :first-child {
        max-width: 69%;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span:first-child {
        color: #21212180;
      }

      .copy-button {
        margin: 0px;
      }
    }
  }

  .trade-in-section {
    .toggle-trade-in {
      line-height: 24px;
      font-weight: 500;
      cursor: pointer;
    }

    .cf-collapse .cf-collapse-header {
      h3 {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.15px;
      }

      button {
        height: 26px;
        width: 174px;
        border-radius: 8px;

        span {
          &.cf-button-content {
            position: absolute;
          }

          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
          letter-spacing: 1.25px;
        }
      }
    }

    .trade-in-details-content {
      .cf-collapse-section {
        display: grid;
        grid-gap: var(--row-margin) 16px;
        grid-template-columns: repeat(5, 1fr);
        margin-top: 16px;

        @media (min-width: 1360px) {
          grid-template-columns: repeat(6, 1fr);
        }

        .cf-form-field {
          box-sizing: border-box;
        }
      }

      button span {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1.25px;
      }

      &.trade-in-disabled {
        .toggle-trade-in {
          color: #21212140;
          cursor: default;
        }
      }
    }
  }

  hr {
    margin: 0px;
    border: 0px;
    border-top: 1px solid #0000001F;
  }

  .cf-input-container {
    &.disabled .cf-input {
      color: var(--cf-input-disabled-color)!important;
      
      :-webkit-autofill {
        -webkit-text-fill-color: var(--cf-input-disabled-color) !important;
      }
    }
  }
`)
