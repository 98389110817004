import { useCallback, useMemo, useState } from 'react'
import { useLoader } from '@carfluent/common'

import type { KeyVal } from 'types'
import type { VehiclePartsDictionaries, VehicleDetailsByVin, AccountListItem } from 'api/types'
import { Dropdowns, DisabilityEquipped } from 'api/types'
import VehiclesApiProvider from 'api/vehicles.api'
import { GET_DEFAULT_DICTIONARIES } from 'api/defaults'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import processApiErrors from 'utils/inventory/processApiErrors'
import { namifyAccount } from 'utils/accounting/namifyAccount'
import hasSameKeys from 'utils/hasSameKeys'
import { compareDictionaryByName } from 'utils/general'

import type { AddNewVehicleFormData } from './types'
import serializeFormData from './serializer'
import { Messages } from './constants'
import AccountingApiProvider from 'api/accounting.api'

const DEFAULT_DICTIONARIES = GET_DEFAULT_DICTIONARIES()

export type SetErrorFn = (fieldId: string, value: string) => void

export interface UseAddNewVehicleApiReturnType {
  addNewVehicle: (formValues: AddNewVehicleFormData, setFieldError: SetErrorFn) => Promise<number | null>
  apiErrors: KeyVal | null
  dictionaries: VehiclePartsDictionaries
  isLoading: boolean
  getStockNumber: () => Promise<string | null>
  getVehiclePaymentAccountsList: () => Promise<AccountListItem[] | []>
  loadInventoryDictionaries: () => Promise<Omit<VehiclePartsDictionaries, Dropdowns.DisabilityEquipped>>
  loadVehicleByVin: (id: string) => Promise<VehicleDetailsByVin | null>
  resetAPIErrors: () => void
}

const useAddNewVehicleAPI = (): UseAddNewVehicleApiReturnType => {
  const { showAlert, showSuccess } = useCustomSnackbar()
  const [apiErrors, setAPIErrors] = useState<KeyVal | null>(null)
  const [dictionaries, setDictionaries] = useState<VehiclePartsDictionaries>(DEFAULT_DICTIONARIES)
  const { isLoading, startLoader, stopLoader } = useLoader()

  const loadVehicleByVin = useCallback(async (vin: string): Promise<VehicleDetailsByVin> => {
    setAPIErrors(null)
    return await VehiclesApiProvider.getVehicleByVin(vin)
  }, [])

  const resetAPIErrors = (): void => {
    setAPIErrors(null)
  }

  const getStockNumber = useCallback(async () => {
    try {
      startLoader()
      const response = await VehiclesApiProvider.getStockNumber()
      return response.stockNumber
    } finally {
      stopLoader()
    }
  }, [startLoader, stopLoader])

  const getVehiclePaymentAccountsList = useCallback(async () => {
    try {
      startLoader()
      const response = await AccountingApiProvider.getVehiclePaymentAccountsList()
      return response.items.map(namifyAccount)
    } finally {
      stopLoader()
    }
  }, [startLoader, stopLoader])

  const addNewVehicle = useCallback(async (
    formValues: AddNewVehicleFormData,
    setFieldError: SetErrorFn
  ) => {
    try {
      setAPIErrors(null)
      const vehicleId = await VehiclesApiProvider.postVehicle(serializeFormData(formValues))
      showSuccess(Messages.VehicleAddSucceed)

      return vehicleId
    } catch (err: any) {
      const sameError = hasSameKeys(err?.response?.data?.errors, formValues)
      processApiErrors({ setFieldError, setApiErrors: setAPIErrors }, err)

      if (!sameError) {
        showAlert(Messages.VehicleAddFailed)
      }
      throw err
    }
  }, [showAlert, showSuccess])

  const loadInventoryDictionaries = useCallback(async () => {
    try {
      startLoader()

      const [
        bodyType, cylinderCountsTypes, doorCountsTypes,
        driveTrainTypes, fuelTypes, genericColorsTypes,
        genericInteriorsTypes, transmissionTypes, makes
      ] = await Promise.all([
        VehiclesApiProvider.getBodyTypes(),
        VehiclesApiProvider.getCylinderCountsTypes(),
        VehiclesApiProvider.getDoorCountsTypes(),
        VehiclesApiProvider.getDriveTrainTypes(),
        VehiclesApiProvider.getFuelTypes(),
        VehiclesApiProvider.getGenericColorsTypes(),
        VehiclesApiProvider.getGenericInteriorsTypes(),
        VehiclesApiProvider.getTransmissionTypes(),
        VehiclesApiProvider.getMakes()
      ])

      genericColorsTypes.items.sort(compareDictionaryByName)
      genericInteriorsTypes.items.sort(compareDictionaryByName)

      const dictionaries = {
        [Dropdowns.Body]: bodyType.items,
        [Dropdowns.Cylinders]: cylinderCountsTypes.items,
        [Dropdowns.Doors]: doorCountsTypes.items,
        [Dropdowns.Drivetrain]: driveTrainTypes.items,
        [Dropdowns.Fuel]: fuelTypes.items,
        [Dropdowns.GenericColor]: genericColorsTypes.items,
        [Dropdowns.GenericInterior]: genericInteriorsTypes.items,
        [Dropdowns.Transmission]: transmissionTypes.items,
        [Dropdowns.DisabilityEquipped]: [
          { id: 1, name: DisabilityEquipped.Yes },
          { id: 2, name: DisabilityEquipped.No }
        ],
        [Dropdowns.Make]: makes.items
      }

      setDictionaries(dictionaries)
      return dictionaries
    } finally {
      stopLoader()
    }
  }, [startLoader, stopLoader, setDictionaries])

  return useMemo(() => ({
    isLoading,
    apiErrors,
    dictionaries,
    getStockNumber,
    addNewVehicle,
    loadInventoryDictionaries,
    loadVehicleByVin,
    resetAPIErrors,
    getVehiclePaymentAccountsList
  }), [
    isLoading,
    apiErrors,
    resetAPIErrors,
    dictionaries,
    loadVehicleByVin,
    getStockNumber,
    addNewVehicle,
    loadInventoryDictionaries,
    getVehiclePaymentAccountsList
  ])
}

export default useAddNewVehicleAPI
