import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-wrap: wrap; 
  flex-direction: column;
  align-items: stretch;
  background-color: #FAFAFA;
  border-radius: 12px;
  padding: 8px;
  width: 100%;

  :hover { cursor: pointer; }

  .email-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }

  .title {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto 0 0;

    svg {
      margin-right: 8px;
    }
  }

  .title-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px; 
    color: #101010;
  }

  .email-reply {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .email-reply-date {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.5px;
    color: rgba(33, 33, 33, 0.5);
    margin: 0 8px;
  }

  .email-body {
    flex: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #101010;
  }

  .attachments-title {
    margin-top: 16px;
    color: rgba(33, 33, 33, 0.50);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`)
