import { css } from '@emotion/css'

export default css(`
  position: relative;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px; 
  letter-spacing: 1.25px;
  text-transform: uppercase;

  &.disable {
    opacity: 0.4;
    cursor: inherit;

    :hover {
      cursor: inherit;
      background-color: #FFFFFF;
    }

    &.completed:hover { background-color: #DDB3A0; }
  }

  &.completed {
    background-color: #DDB3A0;
    color: #fff;

    :hover { background-color: #C99B86; }
  }

  &.un-completed { 
    color: #101010;
    background-color: #FFFFFF;
  }

  :hover {
    cursor: pointer;
    background-color: #FAFAFA;
  }

  .icon-progress { margin-right: 10px; }
`)
