import { type FC } from 'react'
import { cn, formatCurrencyDecimal, Button } from '@carfluent/common'

import ActionsMenu from 'components/common/ActionsMenu'

import OpenBalancesTable from 'components/accounting/OpenBalancesTable'
import SchedulesPieChart from './components/SchedulesPieChart'
import ListOfBalance from './components/ListOfBalance'

import type { UseSchedulesTabProps } from './hook/types'
import useSchedulesTab from './hook'
import { getEntityName, getAcountName, getAcountId, getEntityId, getBusinessName } from './hook/utils'
import CLASS_NAME from './styles'

const ReceivablesTab: FC<UseSchedulesTabProps> = (props) => {
  const {
    checkedOpenBalances,
    isFiltersLoading,
    isPayables,
    onResetSubmitPeriod,
    onChangeSummaryAccount,
    onChangeSummaryEntity,
    onChangeSummaryPeriod,
    onChangeTableFilters,
    onCreateBalanceTransaction,
    onLoadOpenBalances,
    onToggleCheckedOpenBalances,
    onViewBalanceTransaction,
    resetCommand,
    summaryAccount,
    summaryEntity,
    summaryPeriod,
    tableData,
    tableFilters,
    bottomPanelActions
  } = useSchedulesTab(props)

  const {
    accountBalances,
    dateBalances,
    entityBalances,
    totalBalance
  } = tableData.statistics

  const hasSelectedBalances = checkedOpenBalances.size > 0
  const formattedBalance = formatCurrencyDecimal(totalBalance, { emptyValues: '$0.00' })

  if (!props.isSelected) {
    return null
  }

  return (
    <div className={cn(CLASS_NAME, 'cf-schedules-tab', props.className)}>
      <div className='g-panel'>
        <div className='cf-statistics-container'>
          <div className='cf-pie-chart-container'>
            <div className='cf-title'>Balance: {formattedBalance}</div>
            <SchedulesPieChart
              data={dateBalances}
              isLoading={isFiltersLoading}
              onSelectSector={onChangeSummaryPeriod}
              selectedSector={summaryPeriod}
            />
          </div>

          <div className='cf-list-item'>
            <ListOfBalance
              activeItem={summaryEntity}
              getItemId={getEntityId}
              getItemName={getEntityName}
              getItemBusinessName={getBusinessName}
              isLoading={isFiltersLoading}
              items={entityBalances}
              listTitle='Balance by Entity'
              onClickItem={onChangeSummaryEntity}
            />

            <ListOfBalance
              activeItem={summaryAccount}
              getItemId={getAcountId}
              getItemName={getAcountName}
              isLoading={isFiltersLoading}
              items={accountBalances}
              listTitle='Balance by Account'
              onClickItem={onChangeSummaryAccount}
            />
          </div>
        </div>
      </div>

      <OpenBalancesTable
        className={cn(hasSelectedBalances && 'cf-balances-margin')}
        filters={tableFilters}
        isLoading={isFiltersLoading}
        id={`schedules-dashboard-${isPayables ? 'payables' : 'receivables'}`}
        includeStatistics
        isPayables={isPayables}
        filtersTitle='Open Balances'
        onChangeFilters={onChangeTableFilters}
        onLoadData={onLoadOpenBalances}
        onOpenTransaction={onViewBalanceTransaction}
        onToggleRowCheckbox={onToggleCheckedOpenBalances}
        onChangeDateFilters={onResetSubmitPeriod}
        resetCommand={resetCommand}
      />

      {hasSelectedBalances && (
        <div className='g-bottom-actions-panel'>
          {
            isPayables
              ? (
                <ActionsMenu
                  actions={bottomPanelActions}
                  className='cf-actions-menu'
                  buttonTitle='PAY'
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  transformOrigin={{ vertical: 120, horizontal: 'center' }}
                />
                )
              : (<Button onClick={() => onCreateBalanceTransaction()}>RECEIVE</Button>)
          }
        </div>
      )}
    </div>
  )
}

export default ReceivablesTab
