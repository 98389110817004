import { type FC } from 'react'
import { FormDatePicker, FormInput, FormMaskedInput } from '@carfluent/common'

import optionalLabel from 'utils/common/optionalLabel'
import AddressFields, { type Optionals } from 'components/common/AddressFields'
import { EIN_MASK } from 'constants/constants'
import InputSSN2 from 'components/form/components/InputSSN2'

import type { BuyerFormProps } from '../types'

export const ADDRESS_OPTIONALITY_LABELS: Optionals = {
  address: true,
  apt: true,
  city: true,
  state: true
}

const BuyerCobuyerTab: FC<BuyerFormProps> = ({
  values, errors, touched, isPersonal, onBlur, onChange, isDisabled
}) => {
  const isBusiness = !isPersonal

  return (
    <section className='tab-buyer-form'>
      {
          isBusiness && (
            <FormInput
              id='businessDetails.businessName'
              label='Business name'
              onChange={onChange}
              onBlur={onBlur}
              value={values.businessDetails.businessName}
              error={errors.businessDetails.businessName}
              touched={touched.businessDetails.businessName}
              dataTestId='business-details-name'
              disabled={isDisabled}
            />
          )
        }
      <FormInput
        id='buyer.firstName'
        label={isBusiness ? optionalLabel('Rep. first name') : 'First name'}
        value={values.buyer.firstName}
        error={errors.buyer.firstName}
        touched={touched.buyer.firstName}
        onChange={onChange}
        onBlur={onBlur}
        disabled={isDisabled}
      />

      <FormInput
        id='buyer.lastName'
        label={isBusiness ? optionalLabel('Rep. last name') : 'Last name'}
        value={values.buyer.lastName}
        error={errors.buyer.lastName}
        touched={touched.buyer.lastName}
        onChange={onChange}
        onBlur={onBlur}
        disabled={isDisabled}
      />

      <FormMaskedInput
        id='buyer.phoneNumber'
        label='Phone number'
        mask='phone'
        value={values.buyer.phoneNumber}
        error={errors.buyer.phoneNumber}
        touched={touched.buyer.phoneNumber}
        onChange={onChange}
        onBlur={onBlur}
        disabled={isDisabled}
      />

      {
          !isBusiness && (
            <FormDatePicker
              id='buyer.birthDate'
              label={optionalLabel('Date of birth')}
              value={values.buyer.birthDate}
              error={errors.buyer.birthDate}
              touched={touched.buyer.birthDate}
              onChange={onChange}
              onBlur={onBlur}
              disabled={isDisabled}
            />
          )
        }

      <FormInput
        id='buyer.email'
        label={optionalLabel('Email')}
        onChange={onChange}
        onBlur={onBlur}
        value={values.buyer.email}
        error={errors.buyer.email}
        touched={touched.buyer.email}
        className='collspan-2'
        dataTestId='buyer-email'
        disabled={isDisabled}
      />

      <AddressFields
        id='addressDetails'
        values={values.buyer.addressDetails}
        errors={errors.buyer.addressDetails}
        touched={touched.buyer.addressDetails}
        onBlur={onBlur}
        onChange={onChange}
        optionals={ADDRESS_OPTIONALITY_LABELS}
        pathPrefix='buyer'
        useNormalAptKey
        disabled={isDisabled}
      />

      {isBusiness && (
        <FormMaskedInput
          id='businessDetails.ein'
          label={optionalLabel('EIN')}
          mask={EIN_MASK}
          onChange={onChange}
          onBlur={onBlur}
          value={values.businessDetails.ein}
          error={errors.businessDetails.ein}
          touched={touched.businessDetails.ein}
          disabled={isDisabled}
        />
      )}

      <InputSSN2
        id='buyer.socialSecurityNumber'
        label='Social Security Number'
        value={values.buyer.socialSecurityNumber}
        error={errors.buyer.socialSecurityNumber}
        touched={touched.buyer.socialSecurityNumber}
        onChange={onChange}
        onBlur={onBlur}
        isDisabled={isDisabled}
      />
    </section>
  )
}

export default BuyerCobuyerTab
