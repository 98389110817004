import { useCallback, useMemo, useRef, useState } from 'react'
import Popover from '@material-ui/core/Popover'
import { cnx, FormNumberInput, noop } from '@carfluent/common'

import { MAX_PRICE_VALUE } from 'constants/validation'
import { type PopoverWrapperProps } from './types'
import { CLASS_NAME, POPOVER_CLASS_NAME } from './styles'

export { type PopoverWrapperProps }

const POPOVER_ANCHOR_ORIGIN = { vertical: 'bottom', horizontal: 'left' } as const

function PopoverTrigger<V> ({
  children,
  disabled = false,
  editButtonEl,
  formatFieldHelper,
  getValue,
  id,
  isOpen,
  isOverriden,
  label,
  onClose: _onClose,
  onOpen: _onOpen,
  values
}: PopoverWrapperProps<V>): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const helperText = formatFieldHelper?.(values, isOverriden) ?? null

  const onOpen = useCallback((): void => {
    _onOpen()
    setAnchorEl(containerRef.current)
  }, [_onOpen])

  const onClose = useCallback((): void => {
    _onClose()
    setAnchorEl(null)
  }, [_onClose])

  const endAdornment = useMemo(() => (
    <span
      onClick={disabled ? noop : onOpen}
      className={cnx('clickable-input-adornment', disabled && 'disabled')}
      aria-describedby='popper-wrapper'
    >
      {editButtonEl}
    </span>
  ), [onOpen, disabled])

  // ========================================== //

  return (
    <div className={CLASS_NAME} ref={containerRef}>
      <div
        className={cnx('backdrop', !disabled && 'not-disabled')}
        onClick={onOpen}
      />

      {/* AZ-NOTE: further enhancement - add `renderInput` instead of this hardcoded input */}
      <FormNumberInput
        className={cnx('clickable-input', !disabled && 'not-disabled')}
        disabled
        endAdornment={endAdornment}
        id={id}
        isEmptyAllowed={false}
        label={label}
        max={MAX_PRICE_VALUE}
        onChange={noop}
        preset='price'
        value={getValue(values)}
      />

      {(helperText != null) && (
        <p className='popper-trigger-helper-text'>
          {helperText}
        </p>
      )}

      <Popover
        id='popper-wrapper'
        className={POPOVER_CLASS_NAME}
        open={isOpen}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={POPOVER_ANCHOR_ORIGIN}
      >
        <div className='popover-content'>
          {children}
        </div>
      </Popover>
    </div>
  )
}

export default PopoverTrigger
