import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGBankStatement: FC<IconProps> = ({ color = '#000000', width = 24, height = 24 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'>
      <path d='M12.5 2L3 7V9H22V7M17 11V18H20V11M3 23H22V20H3M11 11V18H14V11M5 11V18H8V11H5Z' fill={color} />
    </svg>
  )
}

export default IconSVGBankStatement
