import { type ChangeEvent } from 'react'

/**
 * when user autocompletes forms in one click, mui autocomplete components
 * open popovers which are not closed then even if you click somewhere else.
 *
 * Inputs were filled by browser automatically omitting our normal change-blur flow.
 *
 * During 'autofill' flow 'input' event is triggered, but nativeEvent is not of InputEvent type
 * and its 'data' is missing.
 * We wait some arbitrary time needed for slow React to render those popovers
 * and then we must pass normal flow of focus-blur to properly hide them.
 * If we try to hide them directly then they are not properly closed and are still hidden
 * during next focus event.
 * And also we can not delete them!!! - if we do element.remove() -> browser rendering breaks
 */
export const onAfterAutocompleteAutofillInputFix = (
  e: ChangeEvent<{}>,
  onUnfocusedInput: (value: string) => void
): void => {
  const isAutofilled = !isNativeEventAnInputEvent(e.nativeEvent)

  if (isAutofilled) {
    const t = setTimeout(() => {
      clearTimeout(t)
      const el = e.target as HTMLInputElement

      if (el != null && el.value.length === 0) {
        el.focus()
        setTimeout(() => el.blur(), 10)
      }

      onUnfocusedInput(el.value)
    }, 100)
  }
}

const isNativeEventAnInputEvent = (e: Event): e is InputEvent => {
  return (e as InputEvent).data != null
}
