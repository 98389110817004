import type { FC } from 'react'
import { CellWrapper, cnx } from '@carfluent/common'

import TooltipItem from 'components/common/TooltipItem'

import CLASS_NAME from './styles'

interface TooltipCellProps {
  className?: string
  getValue: () => string
}

const TooltipCell: FC<TooltipCellProps> = ({ getValue, className = '' }) => {
  return (
    <CellWrapper className={cnx(CLASS_NAME, className)}>
      <TooltipItem
        value={getValue() ?? '-'}
        className={cnx('cell-text', className)}
      />
    </CellWrapper>
  )
}

export default TooltipCell
