import { css } from '@emotion/css'

export default css(`
  display: flex;
  gap: 4px;
  width: 100%;
  min-width: 140px;
  
  .type-icon {
    height: 24px;
    width: 24px;
  }
  
  .type-name {
    width: 100%;
    display: table; // for responsive ellipsis
    table-layout: fixed;
    white-space: nowrap;
    
    p {
      display: table-cell; // for responsive ellipsis
      color: #101010;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }
`)
