import { type Deal, type DealsListItem, PaymentMethod } from 'api/types'
import { PaymentStatusId } from 'types/number_enums'

interface DealData {
  dealState: DealsListItem['dealState']
  requiresCheck: DealsListItem['requiresCheck']
}

export const isDealPending = (deal: DealData): boolean => {
  return (deal.dealState === 'Pending') && deal.requiresCheck
}

export const isDeposited = (deal: Deal): boolean => {
  return deal.paymentDetails?.some(p =>
    p.paymentStatusId === PaymentStatusId.Completed && p.paymentMethodId === PaymentMethod.Deposit
  ) ?? false
}
