import { css } from '@emotion/css'

export default {
  vendorOptions: css(`
    & .cf-option {
      font-family: Roboto;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    & .cf-button-option {
      cursor: pointer;
      user-select: none;
      min-height: 48px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      & .cf-option {
        font-family: Roboto;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      & .cf-plus-icon {
        height: 14px;
        width: 14px;
        margin: 0 8px 0 0;
      }
    }
  `)
}
