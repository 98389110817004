import type { EditorState } from 'react-draft-wysiwyg'

import { LeadStatus } from 'types/enums'
import type { DateType } from './customersCore.types'
import type { PaginationOptions, DictionaryItem, SortOrder, EntityDictionary, ListPayload, WebSocketArgs } from './utility.types'

import type { User } from './common.types'

export interface LeadsByVehiclePayload {
  skip?: number
  take?: number
  sortField?: string
  dateRange?: DateType
  sortOrder?: SortOrder
  search?: string | null
}

export interface LeadsByVehicleSourceRequest {
  sortField: string | null
  sortOrder: SortOrder | null
  dateRange: DateType
  leadSources: number[] | null
}

interface VehicleProps {
  id: number
  age: number
  year: number
  make: string
  model: string
  stock: string
}

export interface ReportLeadsByVehiclesItem {
  vehicle: VehicleProps
  interestedLeadsAmount: number
  interestedLeadsAmountPerSource: Record<string, number>
}

export interface ReportLeadBySourceItem {
  leadSource: string
  leadsSourceCategory: string
  leadSourceId: number
  leadSourceCategoryId: number
  newLeadsCount: number
  appointmentsCount: number
  dealsSoldCount: number
  conversionRate: number
  frontGross: number
  backGross: number
  totalGross: number
}

export interface ReportCarRepricingItemDto {
  vehicleId: number
  car: string
  stock: string
  age: number
  initialPrice: number | null
  isAutoRepricingEnabled: boolean
  previousPrice: number | null
  currentPrice: number | null
  leadsLastWeek: number
  leadsLastTwoWeeks: number
  lastRepricingDate: string
  totalLeads: number
}

export interface ReportLeadsBySourceDto {
  totalLeads: number
  averageConversion: number
  totalFrontGross: number
  totalBackGross: number
  totalGross: number
  items: ReportLeadBySourceItem[]
}

export interface ReportLeadsBySourceExportXlsxRequest extends ReportLeadsBySourceDto {
  leadSources: number[] | null
  dateRange: DateType | null
}

export interface MakesAmountPayload {
  search: string
  dateRange: DateType | null
}

export interface MakesAmountItem {
  make: string
  amount: number
}

export interface ShiftDto {
  id: number
  userId: number
  userName: string
  startDate: string
  endDate: string
}

export interface GetShiftsPayload {
  year: number
  month: number
  userIds: number[]
}

export interface GetMemberShiftsPayload extends Pick<GetShiftsPayload, 'month' | 'year'> {}

export interface GetSummaryShiftsPayload extends GetMemberShiftsPayload {}

export interface ClearMonthShiftsPayload extends GetMemberShiftsPayload {}

export interface CreateShiftRequestDto {
  userId: number
  startDate: string | null
  endDate: string | null
}

export interface CopyMonthShiftsPayload {
  sourceYear: number
  sourceMonth: number
  targetYear: number
  targetMonth: number
}

export interface UpdateShiftRequestDto extends CreateShiftRequestDto {
  id: number
}

export interface SummaryShift {
  userName: string
  totalShifts: number
  totalHoursPerMonth: number
}

export enum TaskAssigneeType {
  User = 1,
  Owner,
  RoundRobin,
  RoundRobinBDC,
  RoundRobinSales,
}

export enum TaskTypesNames {
  Owner = 'Lead Owner',
  System = 'System',
  RoundRobin = 'Round Robin',
  RoundRobinBDC = 'BDC team (Round Robin)',
  RoundRobinSales = 'Sales team (Round Robin)'
}

export enum TaskStateEnum {
  NewLeads = 2,
  CreditAppReceived,
  Inbox,
  Workplan,
  Appointments = 1
}

export enum TaskFilterEnum {
  All = 0,
  Today,
  Completed
}

export type TaskStateName = keyof typeof TaskStateEnum
export type TaskFilterName = keyof typeof TaskFilterEnum

export interface TaskTotalAmounts {
  filters: Record<TaskFilterName, number>
  states: Record<TaskStateName, number>
}

type NormalizedStateKeys = {
  [Key in keyof Omit<TaskTotalAmounts['states'], 'Appointments'> as CounterKeysNormalized<Key>]: number;
} & {'appts': number}

export interface TasksListCounters {
  filters: Record<TaskFilterName, number>
  states: NormalizedStateKeys
}

export interface TaskListPayload extends ListPayload {
  taskStateEnum?: TaskStateEnum
  taskListEnum?: TaskFilterEnum
  leadStatusIds?: number[]
  assignedTo?: {
    assignedToIds?: number[]
    areUnassigned?: boolean
  }
}

export interface TaskTotalAmountsPayload {
  search?: string
  leadStatusIds?: number[]
  assignedTo?: {
    assignedToIds: number[]
    areUnassigned: boolean
  } | null
  taskStateEnum?: TaskStateEnum
  taskListEnum?: TaskFilterEnum
}

export type GetLeadStatus = EntityDictionary<string>
export type GetAssignee = EntityDictionary<string>

export interface Task {
  assignee: string | null
  carsOfInterest: LeadsListCarOfInterest[]
  dueDate: string | null
  id: number
  lastActivity: string | null
  leadCreatedDate: string | null
  completionDate?: string | null
  leadId: number
  leadAge: number | null
  leadStatus: keyof typeof TaskStateEnum | null
  relatedTo: string | null
  subject: string | null
  outcome: string | null
  taskPriorityId: number
}

export interface CreateTaskPayload {
  leadId: number
  subject: string | null
  dueDate: string | null
  description: string | null
  taskTypeId: number | null
  taskPriorityId: number | null
  assignedUserId: number | null
}

export interface CreateCallActivityPayload {
  leadId: number
  isInbound: boolean
  workNotes: string
  assignedUserId: number | null
  activityCompleteOption: string
  userDefinedCompletionDate: string
}

export interface CreateVisitActivityPayload {
  leadId: number
  workNotes: string
  assignedUserId: number | null
  userDefinedCompletionDate: string
  activityCompleteSubOptions: string[]
}

export interface CreateOtherActivityPayload {
  leadId: number
  subject: string | null
  workNotes: string
  assignedUserId: number | null
  userDefinedCompletionDate: string
}

export interface CompleteTaskPayload {
  taskId: number
  subject: string | null
  workNotes: string | null
  taskCompleteOptionId: number | null
  taskCompleteSubOptionIds: number[] | null
  userDefinedCompletionDate: string | null
}

export type CompleteAutoTaskPayload = Pick<CompleteTaskPayload, 'taskId' | 'taskCompleteOptionId'>

export type CompleteRespondOnLeadTaskPayload = Pick<CompleteTaskPayload, 'taskId' | 'taskCompleteOptionId' | 'workNotes'>

export interface LeadTask {
  id: number
  subject: string | null
  dueDate: string
  description: string | null
  taskTypeId: number
  taskPriorityId: number
  assignedUser: AssignedUser
  dealId: number | null
}

export interface UpdateTaskPayload extends Omit<CreateTaskPayload, 'leadId'> {
  id: number
}

export interface TaskCreatedNotificationArgs extends WebSocketArgs {
  leadId: number
}

export interface TasksUpdatedNotificationArgs extends WebSocketArgs {
  leadIds: number[] | null
  taskIds: number[] | null
}

export type TasksDeletedNotificationArgs = TasksUpdatedNotificationArgs

export interface EmailSnippet {
  id: number
  name: string
  placeholder: string
  isUsableInBody: boolean
  isUsableInSubject: boolean
}

export interface MessageSnippet {
  id: number
  name: string
  placeholder: string
}

export interface AppointmentReminderUser {
  id: number
}

export interface CrmSettings {
  roundRobinEnabled: boolean
  userIds: number[]
  roundRobinIntervalMinutes: number
  autoReplyEnabled: boolean
  vehicleAvailableEmailTemplateId: number | null
  vehicleAvailableMessageTemplateId: number | null
  vehicleUnavailableEmailTemplateId: number | null
  vehicleUnavailableMessageTemplateId: number | null
  timeZoneDisplayName?: string

  webLeadTaskAssigneeTypeId: number | null
  webLeadAssignedUserId: number | null
  messageReceivedTaskAssigneeTypeId: number | null
  messageReceivedAssignedUserId: number | null
  emailReceivedTaskAssigneeTypeId: number | null
  emailReceivedAssignedUserId: number | null
  creditAppTasksEnabled: boolean
  creditAppTaskAssigneeTypeId: number | null
  creditAppAssignedUserId: number | null

  isEmailOnPriceDropEnabled: boolean
  emailOnPriceDropEmailTemplateId: number | null
  emailOnPriceDropThreshold: number | null

  isMessageOnPriceDropEnabled: boolean
  messageOnPriceDropEmailTemplateId: number | null
  messageOnPriceDropThreshold: number | null

  salesAppReminderEnabled: boolean
  salesAppReminderMinutes: number
  appointmentReminderUsers: AppointmentReminderUser[] | null

  appReminderSendToLeadOwner: boolean
  appReminderSendToAssignee: boolean

  customerAppReminderEnabled: boolean
  customerAppReminderMinutes: number
  customerAppReminderMessageTemplateId: number | null
}

export enum LeadState {
  All = 0,
  New = 1,
  NeedAttention = 2,
  Inbox = 4
}

export enum LeadStateAPI {
  None = 0,
  New = 1,
  NeedAttention = 2,
  Inbox = 4
}

export enum LeadLabel {
  Hot = 1,
  Warm,
  Cold
}

export enum MessageTypeId {
  Sent = 1,
  Received
}

export type LeadStateName = keyof typeof LeadState

export interface LeadListPayload extends ListPayload {
  leadState?: LeadStateAPI
}

/**
 * DD-NOTE: I blame backend in this necessity
 */
type CounterKeysNormalized<T extends string> = Lowercase<T>

export interface LeadListCountersApi {
  all: number
  needAttention: number
  inbox: number
  new: number
}

export type LeadListCounters = {
  [Key in keyof LeadListCountersApi as CounterKeysNormalized<Key>]: number
}

export interface LeadCarOfInterest {
  vehicleId: number
  make: string
  model: string
  trim: string
  stock: string
  year: number
  id: number
  vehicleState: string
  vin: string
  mileage: number
  mainImageUrl: string
  inventoryDate: string
  age: number
  salePrice: number
  titleType: string
  titleReceived: string
  titleSent: string
  titleState: string
  titleNumber: string
  bodyType: string
  engine: string
  doorCount: string
  fuelType: string
  cylinderCount: string
  transmissionType: string
  drivetrainType: string
  genericColor: string
  genericInterior: string
  notes: string
  description: string
  soldDate: string
  dealerId: number
  isDeposited: boolean
  isDisabilityEquipped: boolean
  isVehicleInspected: boolean
}

export interface Lead extends User {
  age: number | null
  assignedTo: string | null
  carsOfInterest: LeadCarOfInterest[]
  id: number
  isLost: boolean
  firstName: string | null
  label: keyof typeof LeadLabel | null
  lastName: string | null
  lastActivityDate: string | null
  nextActivity: string | null
  source: string | null
  status: keyof typeof LeadStatus | null
  state?: LeadStateAPI
}

export interface AssignedUser {
  id: number
  email: string
  firstName: string
  lastName: string
}

export interface LeadData {
  id: number
  firstName: string | null
  lastName: string | null
  email: string | null
  phoneNumber: string | null
  address: string | null
  city: string | null
  state: string | null
  zipCode: string | null
  isLost: boolean
  lostDate: string | null
  lostReasonId: number | null
  tasks: LeadTask[]
  birthDate: string | null
  lastActivityDate: string
  leadTemperatureId: number | null
  leadSourceId: number
  leadStatusId: number
  dealerId: number
  assignedUserId: number | null
  assignedUser: AssignedUser | null
  primaryCarOfInterestId: number | null
  carsOfInterest: LeadCarOfInterest[]
  dealId: number | null
  isUnsubscribed: boolean
  isPaused: boolean
  pausedByUserId: number | null
  pausedDateTime: string | null
}

export type NotPatchableLeadProps =
  | 'assignedUser'
  | 'carsOfInterest'
  | 'dealerId'
  | 'lastActivityDate'
  | 'temperature'
  | 'tasks'

export type LeadDataPatch = Omit<LeadData, NotPatchableLeadProps>

export interface TaskTypeFormOptions extends DictionaryItem {
  isUsableInWorkplan: boolean
}

export interface NewLeadApi {
  firstName: string | null
  lastName: string | null
  phoneNumber: string | null
  email: string | null
  address: string | null
  city: string | null
  state: string | null
  zipCode: string | null
  isLost: boolean
  lostReasonId: number | null
  birthDate: string | null
  tasks: LeadTask[]
  primaryCarOfInterestId: number | null
  leadSourceId: number | null
  leadStatusId: number | null
  leadTemperatureId: number | null
  assignedUserId: number | null
}

export interface UpdateLeadApi extends NewLeadApi {
  leadId: number | string
}

export interface LeadsListCarOfInterest {
  id: number
  make: string
  model: string | null
  trim: string | null
  year: number | null
  stock: string
  isPrimary: true
}

export interface LeadListItem extends Omit<Lead, 'carsOfInterest'> {
  carsOfInterest: LeadsListCarOfInterest[]
}

export interface LeadListCountersPayloadApi {
  search?: string | null
  leadTemperatures: number[]
  leadStatuses: number[]
  leadSources: number[]
  assignedTo: {
    assignedToIds: number[]
    areUnassigned: boolean
  }
}

export interface MessagePayload {
  message: string
  imageUrls: string[]
}

export enum TimelineFilterItemsIds {
  All = 1,
  Emails,
  Messages
}

// YA-VO-TODO: update this enum to get all items from api call
export enum LeadLostReason {
  DecidedToWait = 1,
  BadCredit,
  NegativeEquity,
  VehicleUnavailable,
  NoResponse,
  NothingCanBeDone,
  BoughtElsewhere,
  BadInformation,
  TradeValue,
  MonthlyPayment,
  NoPriceCompromise,
  IssueWithVehicle,
  DifferentCustomerPurchased,
  Other,
  NoLongerInterested,
  UnsubscribedFromMarketingMaterials
}

export enum TimelineLogTypes {
  LeadManuallyCreated = 1,
  LeadReceived,
  LeadAssigned,
  CarOfInterestAdded,
  LeadLost,
  Comment,
  Call, // PlannedCall
  Appointment, // PlannedAppointment
  Reminder, // PlannedReminder
  UnplannedCall, // UnplannedCall
  StoreVisit, // UnplannedStoreVisit
  SwappedTired, // UnplannedOtherTask
  MessageSent = 14,
  MessageReceived,
  EmailReceived,
  EmailSent,
  DealManuallyCreated,
  LeadAutomaticallyCreated,
  DealStatusUpdated,
  EmailSentAutomatically,
  MessageSentAutomatically,
  UnableToSendEmail,
  UnableToSendMessage,
  CreditAppReceived,
  CarOfInterestIsDeposited,
  AutomatedMessagesPaused,
  AutomatedMessagesResumed
}

export enum SnippetTypeIds {
  CarDetails = 1,
  LeadDetails,
  DealershipDetails
}

export enum SnippetTypeNames {
  CarDetails = 'Car details',
  LeadDetails = 'Lead details',
  DealershipDetails = 'Dealership details'
}

export interface TimelineLeadDetailsLog {
  createdDate: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  address?: string
  zipCode?: string
  dealId?: number | null
}

export interface TimelineVehicleDetailsLog {
  year?: number | null
  make: string
  model: string
  trim: string
  vin: string
  stock: string
  salePrice?: number | null
}

export interface TimelineAdditionalData {
  PreferableTypeOfContact: string
  Mileage: string
  VehicleCondition: string
  Collision: string
  AirbagsDeployed: string
  RepairsCost: string
  MechanicalIssues: string
  PaymentsOnYourVehicle: string
  AmountStillOutstanding: string | null
  Make: string
  Model: string
  Trim: string
  DateOfBirth?: Date
  Email: string
  ZipCode: string
  Name: string
  Phone: string
  Date: string
  Year: number
  TradeInValue?: number
  Vin?: string
  DateTime?: string | null
  Address?: string | null
  YearIncome?: string | null
}

export interface TimelineLogData {
  message?: string | null
  leadSourceId?: number | null
  subject: string
  description?: string | null
  userFirstName?: string | null
  userLastName?: string | null
  completionDate?: string | null
  workNotes?: string | null
  lostReasonId?: LeadLostReason
  completionOption?: string | null
  activityCompleteOption?: string | null
  completionSubOptions?: string[] | null
  activityCompleteSubOptions?: string[] | null
  userDefinedCompletionDate: string
  from?: string | null
  emailSubject?: string | null
  feedbackAction?: string | null
  body?: string | null
  comment?: string
  emailId?: number | null
  additionalData?: TimelineAdditionalData
  leadDetails?: TimelineLeadDetailsLog
  carOfInterestDetails?: TimelineVehicleDetailsLog
  dealId: number | null
  salespersonName?: string | null
  reason: string | null // Deal Status Update reason
  type: string | null // event type
  isClick?: boolean
  isOpen?: boolean
  threadId: string | null
  media: TimelineLogMediaDto[] | null
}

export type TimelineLogMediaDto = LeadMessageMediaDto

export interface TimelineItem {
  date: string
  id: number
  taskId: number | null
  logTypeId: TimelineLogTypes
  logData: TimelineLogData
  vehicleId: number | null
}

export interface EmailThread {
  subject: string
  leadEmail: string
  emails: EmailThreadListItem[]
}

export interface EmailAttachment {
  id: number | string
  fileName: string
  url: string
}

export interface EmailThreadListItem {
  id: string | number
  displayName: string
  createdDate: string
  body: string | null
  emailType: number
  emailAttachments: EmailAttachment[]
}

export interface EmailTemplatesResponse {
  body: string | null
  subject: string | null
}

export interface MessageTemplatesResponse {
  body: string | null
  imageUrls: string[]
}

export interface SendEmail {
  cc: string
  subject: string
  body: string | null
  replyToEmailId?: string | number
  emailAttachments: Array<Omit<EmailAttachment, 'id'>>
}

export interface SendEmailPayload {
  body: EditorState | null
  cc: string
  subject: string
  emailAttachments: EmailAttachment[]
}

export interface LeadMessageDto {
  id: number
  messageTypeId: number
  leadFirstName: string | null
  leadLastName: string | null
  adminLastName: string | null
  adminFirstName: string | null
  body: string
  date: string
  sentByCurrentUser: boolean
  media: LeadMessageMediaDto[] | null
}

export interface LeadMessageMediaDto {
  contentType: string | null
  fileId: number | null
  mediaTypeId: number
  url: string | null
}

export enum MessageMediaTypeId {
  Image = 1,
  Audio = 2
}

export enum WorkPlanEventTypeId {
  Task = 1,
  Email,
  Message
}

export interface WorkPlanEventDto {
  id: number
  title: string
  description: string | null
  assignedUser: {
    email: string
    firstName: string
    id: number
    lastName: string
    roundRobinEnabled: boolean
  } | null
  taskTypeId: number | null
  taskPriorityId: number | null
  executionDay: number | null
  reminderSubject: number | string | null
  leadStatusId: number | null
  isActive: boolean | null
  assignedUserId: number | null
  taskAssigneeTypeId: number | null
  statusChangedDate: string
  workplanEventTypeId: WorkPlanEventTypeId
  emailTemplateId: number | null
  runAtTime: string | null
  messageTemplateId: number | null
  templateName: string | null
}

export interface LeadCreatedNotificationArgs extends WebSocketArgs {
  leadId: number
}

export interface LeadUpdatedNotificationArgs extends LeadCreatedNotificationArgs {
  hasTasks: boolean
}

export type LeadDeletedNotificationArgs = LeadCreatedNotificationArgs

export interface LeadLogCreatedNotificationArgs extends LeadCreatedNotificationArgs {
  logId: number
  logTypeId: TimelineLogTypes
}

export type LeadLogUpdatedNotificationArgs = LeadLogCreatedNotificationArgs

export interface DuplicateLeadVehicle {
  id: number
  make: string | null
  mileage: number | null
  model: string | null
  salePrice: number | null
  stock: string | null
  trim: string | null
  vin: string | null
  year: number | null
}

export interface DuplicateLeadDto {
  id: number
  firstName: string | null
  lastName: string | null
  email: string | null
  phoneNumber: string | null
  carOfInterest: DuplicateLeadVehicle | null
  label: keyof typeof LeadLabel
  leadStatusId: number
  leadSourceId: number
  address: string | null
  city: string | null
  zipCode: string | null
  state: string | null
  birthDate: string | null
}

export interface DuplicateLeadsSearchPayload {
  phoneNumber: string
  lastName: string
  email: string
}

export type DuplicateLeadsPayload = PaginationOptions & DuplicateLeadsSearchPayload | { search: string }
export type DuplicateLeadsTotalPayload = DuplicateLeadsSearchPayload
export interface DuplicateLeadsTotalResponse {
  all: number
}

export interface DownloadLeadsByVehicleReportPayload {
  search: string
  sortField: string
  dateRange: DateType
  sortOrder: SortOrder
}

export interface InterestedLeadsListGetRequestDto {
  skip?: number
  take?: number
  sortField?: string
  vehicleId?: number
  sortOrder?: SortOrder
}

export interface InterestedLeadsLisDto {
  id: number
  age: number
  email: string
  lastName: string
  firstName: string
  phoneNumber: string
  leadStatusId: number
  leadSourceId: number
  lastActivityDate: string
  label: keyof typeof LeadLabel | null
}

export interface InterestedLeadsAmountGetDto {
  all: number
}

export interface GetCampaignRecipientsCountRequestDto {
  leadStatusFilterId: number
  leadSourceFilterId: number
  leadTemperatureFilterId: number
  leadSources?: number[] | null
  leadStatuses?: number[] | null
  leadTemperatures?: number[] | null
  isLostStatusSelected: boolean
  leadAppointmentsFilterId: number
}

export interface CampaignRecipientsCountDto {
  total: number
}
