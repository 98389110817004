import { useState, useCallback } from 'react'

import type { DateType } from 'api/types'
import type { BalanceSheetReport } from 'api/types/accounting.types'
import { AccountingApiProvider } from 'api/accounting.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { serializePeriod } from 'utils/accounting'
import { serializeReportForXslx } from 'pages/reports/ReportsList/serializer'
import { getDefaultData } from 'components/reports/ReportsBSPLTable/utils'
import type { RowProps } from 'components/reports/ReportsBSPLTable/components/Row'
import { downloadBlob } from 'utils/general'

import { parseData } from './parser'
import { DEFAULT_DATA_PROPS, REPORT_FILE_NAMES } from './constants'

export interface UseReportBalanceSheetReturn {
  data: RowProps[]
  onExportExcel: () => Promise<void>
  onChangeDatesFilter: (period: DateType) => Promise<void>
}

export const useReportBalanceSheet = (): UseReportBalanceSheetReturn => {
  const { showAlert } = useCustomSnackbar()
  const [data, setData] = useState<RowProps[]>(getDefaultData(DEFAULT_DATA_PROPS))
  const [report, setReport] = useState<BalanceSheetReport | null>(null)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const loadReport = useCallback(async (period: DateType) => {
    try {
      const payload = serializePeriod({
        startDate: period.from,
        endDate: period.to
      })
      const res = await AccountingApiProvider.getBalanceSheetReport(payload)
      const parsedData = parseData(res)

      setReport(res)
      setData(parsedData)
    } catch (e) {
      showAlert(e)
    }
  }, [])

  const onExportExcel = useCallback(async () => {
    try {
      if (report != null) {
        const payload = serializeReportForXslx(report)
        const res = await AccountingApiProvider.downloadBalanceSheetReportXlsx(payload)

        downloadBlob(res, REPORT_FILE_NAMES.xlsx)
      }
    } catch (err) {
      showAlert(err)
    }
  }, [report])

  return {
    data,
    onExportExcel,
    onChangeDatesFilter: loadReport
  }
}
