import { divide, floor, multiply } from 'mathjs'

/**
 * Converts percent value (0.000%-100.000%) to decimal (0.00000 - 1.00000)
 * 0.012(%) --> 0.00012
 */
export const percentToDecimal = (x: number): number => {
  return floor(divide(x, 100), 5)
}

/**
 * Use when target precision is less than original.
 * 0.0012 --> 0.012
 */
export const decimalToPercent = (x: number, n: number = 3): number => {
  return floor(multiply(x, 100), n)
}

/**
 * Rate are stored as 1% = 0.01, there might be percents 1.255% = 0.01255
 * so we need to round to 5 digits to preserve precision
 */
export const roundRate = (x: number): number => {
  return floor(x, 5)
}

/**
 * Rounds money.
 * Important: for money we should never round to the upper boundary.
 */
export const roundCurrency = (x: number): number => {
  return floor(x, 2)
}

export const calcTableHeight = (rowsLength: number, rowHeight: number = 68, gap: number = 8): number => {
  return rowsLength * (rowHeight + gap) - gap
}

/**
 * Trims digits from decimal part of the number, after specified number of digits (d)
 * (works almost as `n.toFixed(d)`, but without rounding).
 * @param {number} n - number to normalize
 * @param {number} d - allowed number of decimal digits
 */
export const trimNumber = (n: number, d: number): number => {
  if (d <= 0) {
    return n
  }

  const trimed = n.toFixed(d + 1).slice(0, -1)

  if (trimed.endsWith('.')) {
    return Number(trimed.slice(0, -1))
  }

  return Number(trimed)
}

export const getValueFromPercent = (value: number, percent: number): number => {
  return value * percent
}

export const getValueFromPercentRounded = (value: number, percent: number): number => {
  return roundCurrency(getValueFromPercent(value, percent))
}
