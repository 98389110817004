import { css } from '@emotion/css'

export default css(`
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  
  &:last-child {
    .feature-option:nth-last-child(-n+2) {
      margin-bottom: 0;
    }
  }

  .feature-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding: 24px 0;
  }

  .feature-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
  }
  
  .feature-option {
    width: 50%;
    margin-bottom: 24px;
    padding-left: 9px;
    
    .MuiFormControlLabel-root {
      display: flex;
      align-items: flex-start;
      
      .MuiFormControlLabel-label {
        padding: 6px 0;
      }
    }
    
    .cf-tooltip-container p {
      white-space: wrap;
      font-weight: 500;
      letter-spacing: 0.4px;
    }
    
    .MuiFormControlLabel-root {
      margin-left: -9px;
    }
    
    .PrivateSwitchBase-root-313 {
      padding: 0 9px;
    }
    
    svg {
      width: 18px;
      height: 18px;
    }
  }
`)
