import { dates } from '@carfluent/common'
import type { VehiclePrices } from 'api/types'
import type { PricesByDate } from './types'

export const convertToPricesByDate = (vehiclePrices: VehiclePrices[], leadDates: string[], createdDate: string, lastDateTime: string | null): PricesByDate[] => {
  const currentDate = new Date(createdDate)
  const todayDate = (lastDateTime != null ? new Date(lastDateTime) : new Date()).setUTCHours(23, 59, 59, 999)

  const leadCounts = leadDates.reduce((acc: { [key: string]: number }, date: string) => {
    const datePart = date.split('T')[0]
    acc[datePart] = (acc[datePart] ?? 0) + 1
    return acc
  }, {})

  const dataArray = []
  const sortedPrices = [...vehiclePrices].sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime())

  const priceMap: { [date: string]: number[] } = {}
  sortedPrices.forEach(price => {
    const datePart = price.createdDate.split('T')[0]
    priceMap[datePart] = [...(priceMap[datePart] ?? []), price.salePrice]
  })

  let lastAvailablePrice = [0]

  /**
   * OP-NOTE: currentDate is used to track the most recent date when a price change occurred.
   * When loop finds new date with price changes the currentDate should be re-written
   * to use newest price for the following dates in bar chart.
   */
  while (currentDate <= new Date(todayDate)) {
    const dateFormatted = currentDate.toISOString().replace('Z', '') + '2'
    const datePart = dateFormatted.split('T')[0]

    let prices = [0]

    if (priceMap[datePart] != null) {
      lastAvailablePrice = priceMap[datePart]
      prices = priceMap[datePart]
    } else if (lastAvailablePrice.length > 0) {
      prices = [lastAvailablePrice[lastAvailablePrice.length - 1]]
    }

    dataArray.push({
      date: dateFormatted,
      prices,
      webLeads: leadCounts[datePart] ?? 0
    })

    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dataArray
}

export const calcTotalAmountOfLeads = (vehiclePrices: VehiclePrices[]): number => {
  return vehiclePrices.reduce((acc, el) => acc + (el.leadAmount ?? 0), 0)
}

export const getEarlierDate = (activationDate: Date): string => {
  const currentDate = new Date()
  const fifteenDaysAgo = dates.addDays(-15, currentDate).toISOString()

  return (new Date(activationDate) > new Date(fifteenDaysAgo) ? activationDate : fifteenDaysAgo).toString()
}

export const countLeadsAmountForRange = (activationDate: string, dateArray: string[]): number => {
  const activation = new Date(activationDate)
  const dates = dateArray.map(date => new Date(date))

  const today = new Date()
  const fifteenDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15)

  let comparisonDate: Date

  if (activation < fifteenDaysAgo) {
    comparisonDate = fifteenDaysAgo
  } else {
    comparisonDate = activation
  }

  const count = dates.filter(date => date >= comparisonDate).length
  return count
}
