import { FC, useMemo } from 'react'
import { cn, CellWrapper, parsers as P } from '@carfluent/common'

import { formatDate } from 'utils/parse_date'
import ConfigurableCellHOC from 'components/common/Table/cells/ConfigurableCellHOC'
import CLASS_NAME from './styles'

interface DateCellDataWithConfig {
  value: Date | null
  isMultiline?: boolean
}

export type CellValue = Date | string | null | DateCellDataWithConfig

export interface DateCellProps extends DateCellHOCProps {
  getValue: () => CellValue
  format?: string
}

const isValueWithConfig = (val: CellValue): val is DateCellDataWithConfig => {
  if ((val == null) || (val instanceof Date) || (typeof val === 'string')) {
    return false
  }

  return true
}

const DATE_PART = 'MM/dd/yy'
const DEFAULT_FORMAT = `${DATE_PART} hh:mm aa`

export const DateCell: FC<DateCellProps> = ({
  className,
  format = DEFAULT_FORMAT,
  emptyValue = '-',
  getValue
}) => {
  const data = getValue()

  const config = useMemo(() => {
    const value = isValueWithConfig(data) ? data.value : data
    const date = typeof value === 'string' ? P.parseDateStringUTC(value) : value
    const formattedValue = date != null ? formatDate(date, format) ?? emptyValue : emptyValue

    return {
      value: formattedValue,
      isMultiline: (data as DateCellDataWithConfig)?.isMultiline ?? false
    }
  }, [data, format])

  return (
    <CellWrapper className={cn(CLASS_NAME, className)}>
      {config.isMultiline && (
        <div className='cf-multiline-datetime'>
          <p className='cf-table-cell-text'>{config.value.slice(0, DATE_PART.length)}</p>
          <p className='cf-table-cell-text'>{config.value.slice(DATE_PART.length)}</p>
        </div>
      )}

      {!config.isMultiline && (<p className='cf-table-cell-text'>{config.value}</p>)}
    </CellWrapper>
  )
}

export interface DateCellHOCProps {
  className?: string
  emptyValue?: string
  format?: string
}

export const DateCellHOC = (hocProps: DateCellHOCProps) => ConfigurableCellHOC(DateCell, hocProps)

export default DateCell // AZ-TODO: remove default export, since we have more than 1 exported thing now
