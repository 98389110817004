import { formatInteger, formatPercentagesDecimal } from '@carfluent/common'
import { CampaignStatusId } from 'api/types/responses'
import { type GetStatusInfo } from 'components/common/StatusBadge'

export const getStringOrDash = (value: string | null): string => {
  return ((value != null) && (value?.length > 0)) ? value : '-'
}

export const formatCount = (count: number | null): string => {
  if (count == null) {
    return '-'
  }

  return `${formatInteger(count)} leads`
}

export const formatRateOfCount = (rate: number | null, count: number | null): string => {
  if ((rate == null) || (count == null)) {
    return '-'
  }

  return `${formatPercentagesDecimal(rate * 100)} of sent (${formatCount(count)})`
}

export const getStatusesInfo: GetStatusInfo<CampaignStatusId> = (statusId) => {
  switch (statusId) {
    case CampaignStatusId.Pending: {
      return {
        color: '#60400D',
        bgColor: '#FBE9D8',
        message: 'Pending'
      }
    }
    case CampaignStatusId.Started: {
      return {
        color: '#605D0D',
        bgColor: '#FBF9D8',
        message: 'Started'
      }
    }
    case CampaignStatusId.Sent: {
      return {
        color: '#0E4C29',
        bgColor: '#CFE6D9',
        message: 'Sent'
      }
    }
    case CampaignStatusId.Failed: {
      return {
        color: '#B00020',
        bgColor: '#B000201A',
        message: 'Failed'
      }
    }
    case CampaignStatusId.Draft: {
      return {
        color: '#4A4A4A',
        bgColor: '#F3F3F3',
        message: 'Draft'
      }
    }
    default: {
      return {
        color: '#fff',
        bgColor: '#000',
        message: 'Unknown'
      }
    }
  }
}
