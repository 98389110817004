import type { FC } from 'react'
import { CellWrapper, formatCurrencyDecimal } from '@carfluent/common'

import TooltipedText from 'components/common/TooltipedText'
import CLASS_NAME from './styles'

export interface PaymentCellProps {
  getValue: () => PaymentData
}

export interface PaymentData {
  name: string | null
  value: number | null
}

const PaymentCell: FC<PaymentCellProps> = ({ getValue }) => {
  const { name, value } = getValue()

  return (
    <CellWrapper className={CLASS_NAME}>
      {(name != null) && (
        <TooltipedText value={name} />
      )}

      {(value != null) && (
        <TooltipedText
          className='cf-grey-text'
          value={formatCurrencyDecimal(value, { postfix: '/month' })}
        />
      )}
    </CellWrapper>
  )
}

export default PaymentCell
