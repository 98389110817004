import { css } from '@emotion/css'

export default css(`
  color: #fff;
  padding: 10px;
  borderRadius: 5px;
  border-radius: 4px;
  background: #212121;
  padding: 4px 12px 4px 12px;

  .bold-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-family: Roboto, sans-serif;
  }

  p {
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-family: Roboto, sans-serif;
  }

  .price-list {
    margin: 0;
    padding-left: 0;
    list-style-type: none;
  }
`)
