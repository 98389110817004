import { type FC, type ReactNode, useMemo } from 'react'
import { FormDropdown, Modal, FormInput } from '@carfluent/common'

import type { DictionaryItem } from 'api/types'
import AttachInventoryPhotosModal from 'components/crm/AttachInventoryPhotosModal'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import EmailAttachments from 'components/common/EmailAttachments'
import EditorComponent from 'components/common/EditorComponent'
import Divider from 'components/common/Divider'
import AddCarDropdown from 'components/wysiwyg/toolbar/AddCarDropdown'
import feedbackLinkDecorator from 'components/wysiwyg/decorators/feedbackLink'
import { getVideoDecoratorWithProps } from 'components/wysiwyg/decorators/uploadVideoLink'

import { ValidationLength } from 'constants/validation'
import { getCarDecoratorWithProps } from 'components/wysiwyg/decorators/carBlock'
import type { SendEmailProps } from 'hooks/useSendEmail/types'
import { FormTitle, FieldIds, EMPTY_EMAIL_OPTION } from 'hooks/useSendEmail/constants'

import CLASS_NAME from './styles'

const MODAL_MIN_WIDTH = 1034

const renderTemplateOption = (option: DictionaryItem): string => option.name
const renderNoTemplateOption = (): ReactNode => (<div>No templates found</div>)

const SendEmailDetails: FC<SendEmailProps> = ({
  emailTemplates,
  carsOfInterest,
  isOpen,
  linkToWebsite,
  onClose,
  setAttachmentsField,
  suggestedCars,
  onRemoveBlock,
  isSubmitting,
  body,
  values,
  errors,
  touched,
  onChange,
  onBlur,
  onSubmit,
  onBodyChange,
  attachInventoryPhotosProps: {
    onOpenAttachPhotosModal,
    photoAttachmentsProps,
    attachInventoryPhotosModalProps
  }
}) => {
  const customButtons = useMemo(() => ([
    <AddCarDropdown
      key='addCar'
      label='ADD CAR'
      carsOfInterest={carsOfInterest}
      linkToWebsite={linkToWebsite}
      suggestedCars={suggestedCars}
    />
  ]), [carsOfInterest, linkToWebsite, suggestedCars])

  const renderFooter = (): JSX.Element => {
    return (
      <CancelSubmitActionsFooter
        isLoading={isSubmitting}
        onSubmit={onSubmit}
        onClose={onClose}
        submitTitle='SEND'
        cancelTitle='CLOSE'
      />
    )
  }

  const decorators = useMemo(() => {
    return [
      getCarDecoratorWithProps({ onRemove: onRemoveBlock }),
      feedbackLinkDecorator,
      getVideoDecoratorWithProps({ onRemove: onRemoveBlock })
    ]
  }, [onRemoveBlock])

  return (
    <Modal
      className={CLASS_NAME}
      dataTestId='send-email-popup-create-update'
      isOpen={isOpen}
      minWidth={MODAL_MIN_WIDTH}
      onClose={onClose}
      renderFooterActions={renderFooter}
      titleDataTestId='send-email-title-create-update'
      title={FormTitle.SendEmail}
    >
      <FormDropdown<DictionaryItem>
        id={FieldIds.Template}
        label='Template'
        options={[EMPTY_EMAIL_OPTION, ...emailTemplates]}
        renderOption={renderTemplateOption}
        renderNoOptions={renderNoTemplateOption}
        onChange={onChange}
        onBlur={onBlur}
        mode='search'
        blurMode='select-first'
        disableClearable
        value={values[FieldIds.Template]}
        error={errors[FieldIds.Template]}
        touched={touched[FieldIds.Template]}
      />

      <Divider />

      <div className='inputs-container'>
        <FormInput
          dataTestId='input-to'
          error={errors.to}
          id={FieldIds.To}
          label='To'
          onChange={onChange}
          onBlur={onBlur}
          touched={touched.to}
          value={values.to}
          disabled
        />

        <FormInput
          dataTestId='input-cc'
          error={errors.cc}
          id={FieldIds.CC}
          label='CC'
          onChange={onChange}
          onBlur={onBlur}
          touched={touched.cc}
          value={values.cc}
        />

        <FormInput
          dataTestId='input-subject'
          error={errors.subject}
          id={FieldIds.Subject}
          label='Email subject'
          onChange={onChange}
          onBlur={onBlur}
          touched={touched.subject}
          value={values.subject}
          maxLength={ValidationLength.DESCRIPTION_MAX}
        />
      </div>

      <EditorComponent
        className='send-email-editor'
        customDecorators={decorators}
        description={body}
        toolbarCustomButtons={customButtons}
        onChange={onBodyChange}
      />

      <EmailAttachments
        setAttachmentsField={setAttachmentsField}
        onOpenAttachPhotosModal={onOpenAttachPhotosModal}
        photoAttachmentsProps={photoAttachmentsProps}
      />

      <AttachInventoryPhotosModal
        {...attachInventoryPhotosModalProps}
        carsOfInterest={carsOfInterest}
        isPhotosNumLimited={false}
      />
    </Modal>
  )
}

export default SendEmailDetails
