import { type FC } from 'react'

import TopPanelActions from 'components/common/TopPanelActions'
import Table from 'components/common/Table'
import AccountDetails from 'pages/accounting/AccountDetails'
import AccountingHOK from 'pages/accounting/AccountingHOC'
import TransactionDetails from 'pages/accounting/TransactionDetails'

import AccountHeader from './components/AccountHeader'
import useAccountTrialBalance from './hook'
import CLASS_NAME from './styles'

const AccountTrialBalance: FC = () => {
  const {
    accountEditProps,
    accountHeaderProps,
    columns,
    containerElement,
    emptyState,
    onGoBack,
    onBottomReached,
    onJournalEntryClick,
    onSortingChange,
    rows,
    sorting,
    transactionDetailsProps
  } = useAccountTrialBalance()

  const { onOpenModal: onOpenEditAccountModal } = accountEditProps

  return (
    <div className={CLASS_NAME}>
      <TopPanelActions
        actionButtonName='edit'
        backButtonName='back'
        onActionClick={onOpenEditAccountModal}
        onGoBack={onGoBack}
        className='g-top-panel-actions g-top-panel-actions-small-height'
      />

      <div className='top-block-wrapper'>
        <AccountHeader {...accountHeaderProps} />
      </div>

      <Table
        columns={columns}
        data={rows}
        isLoading={accountHeaderProps.searchProps.isLoading}
        containerElement={containerElement}
        emptyTableMessage={emptyState}
        onRowClick={onJournalEntryClick}
        onBottomReached={onBottomReached}
        onSortingChange={onSortingChange}
        sorting={sorting}
      />

      <TransactionDetails {...transactionDetailsProps} />
      <AccountDetails {...accountEditProps} />
    </div>
  )
}

export default AccountingHOK(AccountTrialBalance)
