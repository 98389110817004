import { css } from '@emotion/css'

export default css(`
  background-color: #F3F3F3;
  border-radius: 4px;
  padding: 8px;

  .cf-draggable-row {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 56px;
    position: relative; /* Add position: relative to enable positioning of the draggable area */
    border-left: 4px solid transparent;
    border-radius: 2px;

    :hover {
        background-color: #FAFAFA;
        border-left: 4px solid #212121;
        border-top-left-radius: 3.5px;
        border-bottom-left-radius: 3.5px;
      }
    }

    .cf-draggable-area {
      position: absolute; /* Position the draggable area within the parent div */
      top: 0;
      left: 0;
      bottom: 0;
      width: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      z-index: 1; /* Ensure the draggable area is above the other content */
      margin-left: -4px;
      
      img {
        position: absolute; /* Position the draggable area within the parent div */
        top: 22px;
      }
    }

    .cf-close-btn-area {
      width: 56px;
      min-width: 56px;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      
      .disabled {
        opacity: 0.4
      }

      .cf-icon-button {
        position: absolute;
        top: 22px;
      }

      img {
        width: 13px;
      }
    }
  }

  .cf-button-root.cf-button-variant-text.cf-btn-add-recon-step {
    margin: 8px 0;
    padding-left: 21px;
    text-decoration: none;
    background-color: #FFFFFF;
    border-radius: 2px;
    width: 100%;
    height: 56px;
    justify-content: flex-start;

    .cf-button-start-adornment {
      height: 20px;

      .cf-icon {
        width: 14px;
      }
    }

    .cf-button-content {
      margin-left: 9px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.4px;
    }
  }
`)
