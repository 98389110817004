import type { FC } from 'react'
import {
  Modal,
  FormInput,
  FormNumberInput,
  FormTextArea,
  FormDropdown
} from '@carfluent/common'

import type { DictionaryItem } from 'api/types'
import { COMMON_MODAL_FORM_CLS, MODAL_MIN_WIDTH } from 'constants/constants'
import { renderPriorityOption } from 'components/crm/common'
import TaskPriority from 'components/common/TaskPriority'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import RadioGroup from 'components/common/RadioGroup'
import RadioLabelControl from 'components/common/RadioLabelControl'

import WorkPlanEventFormBar from './components/WorkPlanEventFormBar'

import {
  FieldIds,
  FieldLabels,
  MAX_ROWS_DESCRIPTION
} from './hook/constants'
import { useAddEditRule } from './hook'
import { type UseAddEditRuleProps, WorkPlanEventType } from './hook/types'
import CLASS_NAME from './styles'

const RUN_ON_DAY_MAX_LENGTH = 3

const WorkPlanEventModal: FC<UseAddEditRuleProps> = (props) => {
  const isEdit = props.formData != null

  const {
    isReminderDisabled,
    isLoading,
    reminderSubjects,
    taskPriorities,
    taskTypes,
    users,
    values,
    errors,
    touched,
    messageTemplates,
    emailTemplates,
    timeZoneDisplayName,
    onChange,
    onChangeTaskType,
    onBlur,
    onSubmit,
    onClose,
    onDeleteEvent,
    onChangeEventType
  } = useAddEditRule(props)

  const isEmailType = values.workplanEventTypeId?.toString() === WorkPlanEventType.Email.toString()
  const isMessageType = values.workplanEventTypeId?.toString() === WorkPlanEventType.Message.toString()

  const renderFooter = (): JSX.Element => {
    const restProps = isEdit
      ? {
          submitTitle: 'SAVE',
          onDelete: onDeleteEvent,
          deleteBtnTitle: 'DELETE EVENT'
        }
      : { submitTitle: 'ADD' }

    return (
      <CancelSubmitActionsFooter
        isLoading={isLoading}
        onSubmit={onSubmit}
        onClose={onClose}
        {...restProps}
      />
    )
  }

  return (
    <Modal
      title='Workplan event'
      isOpen={props.leadStatus != null}
      onClose={onClose}
      className={CLASS_NAME}
      minWidth={MODAL_MIN_WIDTH}
      renderFooterActions={renderFooter}
    >
      <div className='radio-line-container'>
        <RadioGroup
          id='workplanEventTypeId'
          value={(values[FieldIds.WorkPlanEventTypeId]?.toString() ?? '')}
          onChange={(_, value: string) => onChangeEventType(value)}
        >
          <RadioLabelControl value={WorkPlanEventType.Task.toString()} label='Task' />
          <RadioLabelControl value={WorkPlanEventType.Email.toString()} label='Email' />
          <RadioLabelControl value={WorkPlanEventType.Message.toString()} label='Message' />
        </RadioGroup>
      </div>

      {(isEmailType || isMessageType)
        ? (
          <div className={COMMON_MODAL_FORM_CLS}>
            <WorkPlanEventFormBar
              onChange={onChange}
              onBlur={onBlur}
              values={values}
              errors={errors}
              touched={touched}
              emailTemplates={emailTemplates}
              messageTemplates={messageTemplates}
              timeZoneDisplayName={timeZoneDisplayName}
            />
          </div>
          )
        : (
          <div className={COMMON_MODAL_FORM_CLS}>
            <FormInput
              id={FieldIds.Title}
              label={FieldLabels.Title}
              onChange={onChange}
              onBlur={onBlur}
              value={values[FieldIds.Title]}
              error={errors[FieldIds.Title]}
              touched={touched[FieldIds.Title]}
              className='g-full-width'
              dataTestId='crm-rules-field-title'
            />

            <FormDropdown<DictionaryItem>
              className='g-full-width'
              id={FieldIds.AssignedTo}
              label={FieldLabels.AssignedTo}
              mode='search'
              blurMode='select-first'
              disableClearable
              options={users}
              value={values[FieldIds.AssignedTo]}
              error={errors[FieldIds.AssignedTo]}
              touched={touched[FieldIds.AssignedTo]}
              onChange={onChange}
              onBlur={onBlur}
              dataTestId='crm-rules-field-assigned-to'
            />

            <FormDropdown<DictionaryItem>
              options={taskTypes}
              id={FieldIds.TaskType}
              label={FieldLabels.TaskType}
              value={values[FieldIds.TaskType]}
              error={errors[FieldIds.TaskType]}
              touched={touched[FieldIds.TaskType]}
              onChange={onChangeTaskType}
              onBlur={onBlur}
              dataTestId='crm-rules-field-task-type'
            />

            <FormDropdown<DictionaryItem>
              options={reminderSubjects}
              className={isReminderDisabled ? 'cf-disabled' : ''}
              id={FieldIds.ReminderSubject}
              label={FieldLabels.ReminderSubject}
              value={values[FieldIds.ReminderSubject]}
              error={errors[FieldIds.ReminderSubject]}
              touched={touched[FieldIds.ReminderSubject]}
              onChange={onChange}
              onBlur={onBlur}
              disabled={isReminderDisabled}
              dataTestId='crm-rules-field-reminder-subjects'
            />

            <FormDropdown<DictionaryItem>
              id={FieldIds.TaskPriority}
              label={FieldLabels.TaskPriority}
              value={values[FieldIds.TaskPriority]}
              error={errors[FieldIds.TaskPriority]}
              touched={touched[FieldIds.TaskPriority]}
              renderOption={renderPriorityOption}
              startAdornment={<TaskPriority iconsOnly value={values[FieldIds.TaskPriority]?.id} />}
              onChange={onChange}
              onBlur={onBlur}
              options={taskPriorities}
              dataTestId='crm-rules-field-task-priority'
            />

            <FormNumberInput
              id={FieldIds.RunOnDay}
              label={FieldLabels.RunOnDay}
              maxLength={RUN_ON_DAY_MAX_LENGTH}
              value={values[FieldIds.RunOnDay]}
              error={errors[FieldIds.RunOnDay]}
              touched={touched[FieldIds.RunOnDay]}
              onChange={onChange}
              onBlur={onBlur}
              dataTestId='crm-rules-field-run-on-day'
            />

            <FormTextArea
              id={FieldIds.Description}
              maxRows={MAX_ROWS_DESCRIPTION}
              label={FieldLabels.Description}
              onChange={onChange}
              onBlur={onBlur}
              value={values[FieldIds.Description]}
              error={errors[FieldIds.Description]}
              touched={touched[FieldIds.Description]}
              className='g-full-width add-rule-textarea'
              dataTestId='crm-rules-field-description'
            />
          </div>
          )}

    </Modal>
  )
}

export default WorkPlanEventModal
