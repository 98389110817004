import { css } from '@emotion/css'

export default css(`
  p {
    font-size: 16px;
    color: #21212180;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 32px;
    font-family: Roboto;
    letter-spacing: 0.5px;
  }
  .cf-vehicle-purchase-payments-form {
    display: grid;
    row-gap: 24px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
  }
`)
