import type { AccountingSettings } from './types'

export {
  GET_DEFAULT_ACCOUNTING_CATEGORIES,
  GET_DEFAULT_ACCOUNTING_TYPES,
  GET_DEFAULT_DEALER_DATA
} from 'api/defaults'

export const GET_DEFAULT_ACCOUNTING = (): AccountingSettings => ({
  id: 0,
  accountingStartDate: null,
  floorplanDebitAccountNumber: 0,
  floorplanCreditAccountNumber: 0,
  packHoldingAccountNumber: 0,
  packInventoryAssetAccountNumber: 0,
  receiveAccountNumber: 0,
  checkAccountNumber: 0,
  fiscalYearStartMonth: 0,
  retailVehicleCostsAccountNumber: 0,
  costDebitAccountNumber: 0,
  costCreditAccountNumber: 0,
  printCheckNumber: true,
  printHorizontalOffset: 0,
  printVerticalOffset: 0,
  printHeaderCheckNumberRowMarginBottom: 0,
  printHeaderDateRowMarginBottom: 0,
  printHeaderAmountRowMarginBottom: 0,
  printHeaderAmountWordsRowMarginBottom: 0,
  printHeaderEntityWithAddressRowMarginBottom: 0,
  printTable1MarginTop: 0,
  printTable1MarginBottom: 0
})
