import { css } from '@emotion/css'

export default css(`
  .activate-toggle {
    display: flex;
    margin-bottom: 8px;
    padding: 12px 16px;
    border-radius: 8px;
    flex-direction: row;
    align-items: center;
    background: #FAFAFA;
    justify-content: space-between;

    h2 {
      color: #212121;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.4px;
    }

    label {
      margin: 0;
    }

    .cf-main-block {
      background: #00000030;
    }

    .cf-main-block.active {
      background: #219653;
    }
  }

  .cf-select-display-container {
    height: 48px;
    background: #F1F7FF;

    &.disabled {
      background: #F1F7FF40;
    }

    .cf-display {
      height: 20px;
      padding: 14px 0;
    }

    .cf-display-adornment {
      padding: 12px 0;
    }

    .cf-dropdown-clear-button {
      display: none;
    }

    :hover {
      border-color: transparent;
    }
  }

  .cf-display {
    font-size: 14px;
    font-weight: 500;
  }
`)
