import { css } from '@emotion/css'

export const TOGGLE_CLASS_NAME = css(`
  .cf-main-block {
    background: rgba(0, 0, 0, 0.3);

    :before {
      transform: translateX(0);
    }
    
    &.active {
      background: #219653;
      
      :before {
        transform: translateX(15px);
      }
    }
  }
`)
