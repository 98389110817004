import { type DealFeesDto } from 'api/types'
import { type DealFeesModel, type OtherChargeModel } from 'api/types/responses'

const parseDealFeesDto = (data: DealFeesDto): DealFeesModel => {
  const others: OtherChargeModel[] = []

  if (data?.other1 != null) {
    /**
     * it's important to keep id-s ordered, from min to max.
     */
    others.push({ id: Date.now() + 1, amount: data.other1 ?? 0, description: data.other1Description ?? '' })
  }

  if (data?.other2 != null) {
    others.push({ id: Date.now() + 2, amount: data.other2 ?? 0, description: data.other2Description ?? '' })
  }

  if (data?.other3 != null) {
    others.push({ id: Date.now() + 3, amount: data.other3 ?? 0, description: data.other3Description ?? '' })
  }

  return {
    /**
     * Fees and taxes, configurable from "Settings".
     */
    buyerTag: data.buyerTag,
    carDelivery: data.carDelivery,
    dealerHandlingFee: data.dealerHandlingFee,
    dealerInventoryTax: data.dealerInventoryTax,
    emissions: data.emissions,
    inspectionFee: data.inspectionFee,
    plateTransferFee: data.plateTransferFee,
    registrationFee: data.registrationFee,
    titleFee: data.titleFee,

    /**
     * Fees and taxes, non-configurable or overriden for the specific Deal,
     * in DealDetails page.
     */
    overridenSalesCommission: data.overridenSalesCommission,
    overridenSalesTaxPercent: data.overridenSalesTaxPercent,
    overridenSalesTaxAmount: data.overridenSalesTaxAmount,
    others
  }
}

export default parseDealFeesDto
