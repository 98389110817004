import { css } from '@emotion/css'

export default css(`
  display: inline-block;
  position: relative;

  .cf-label-control-root {
    padding-left: 5px;
    margin-top: 20px;
    margin-right: 0;
    width: 100%;
  }

  .cf-label-control-text {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #101010;
    margin-left: 4px;
  }

  .cf-checkbox-icon {
    width: 24px;
    height: 24px;
  }

  .cf-input-wrapper {
    padding-left: 38px;
    padding-top: 7px;
    width: 100%;
    position: absolute;
    bottom: -100%;
    left: 0;
  }
`)
