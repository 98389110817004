import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  .financing-fees-fields {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  
  .cf-form-field {
    flex: 1;
  }
`)
