import { type FC } from 'react'

import DeleteModal from 'components/dialogs/DeleteModal'
import FiltersPanel from 'components/common/FiltersPanel'
import TopPanelActions from 'components/common/TopPanelActions'
import TextSkeleton from 'components/common/Skeletons/TextSkeleton'
import SaveCloseButtonsPanel from 'components/common/SaveCloseButtonsPanel'
import InspectionGeneralBlock from 'components/reconditioning/InspectionGeneralBlock'

import useIntakeInspectionsList from './hook'
import InspectionList from './components/InspectionList'
import NavigationBlock from './components/NavigationBlock'
import InspectionListLoader from './components/InspectionListLoader'
import NavigationBlockLoader from './components/NavigationBlockLoader'

import CLASS_NAME from './styles'

const IntakeInspectionsList: FC = () => {
  const {
    data,
    onGoBack,
    isLoading,
    pageStatus,
    filterItems,
    listValidation,
    navigationData,
    topMenuActions,
    resetModalProps,
    onQuestionUpdate,
    generalBlockData,
    unlockModalProps,
    bottomPanelProps,
    shouldFilterQuestion
  } = useIntakeInspectionsList()

  return (
    <>
      <div className={CLASS_NAME}>
        <TopPanelActions
          actions={topMenuActions}
          backButtonName='Back'
          isActionBtnLoading={isLoading}
          className='g-top-panel-actions g-top-panel-actions-big-height '
          onGoBack={onGoBack}
        >
          {
            isLoading
              ? <TextSkeleton />
              : (
                <>
                  <span>{pageStatus}</span>

                  <FiltersPanel filterProps={{ filters: filterItems }} />
                </>
                )
          }
        </TopPanelActions>

        <div className='intake-inspection-container'>
          <div className='intake-inspection-form-block'>
            <InspectionGeneralBlock
              {...generalBlockData}
            />
          </div>

          <div className='intake-inspection-navigation-block'>
            {
              isLoading
                ? <NavigationBlockLoader />
                : <NavigationBlock
                    blocks={navigationData}
                    shouldFilterQuestion={shouldFilterQuestion}
                    isSubmitted={generalBlockData.isCompleted ?? false}
                  />
            }
          </div>

          {
              isLoading
                ? (
                  <InspectionListLoader />
                  )
                : (
                  <InspectionList
                    listValidation={listValidation}
                    onQuestionUpdate={onQuestionUpdate} lists={data}
                    isSubmitted={generalBlockData.isCompleted ?? false}
                  />
                  )
              }
        </div>
      </div>

      <DeleteModal
        {...resetModalProps}
        title='Reset all items'
        submitTitle='YES, RESET'
        content='Do you want to reset all items? Your progress will be cleared.'
      />

      <DeleteModal
        {...unlockModalProps}
        submitTitle='YES, UNLOCK'
        title='Unlock Intake Inspection'
        content='Do you want to unlock Intake Inspection? Your submitted form will be unlocked.'
      />

      <SaveCloseButtonsPanel {...bottomPanelProps} />
    </>
  )
}

export default IntakeInspectionsList
