import { GET_DEFAULT_VEHICLE } from 'api/defaults'
import { type Vehicle, VehicleState } from 'api/types'
import type { CarOfInterest, VehicleWithGroup } from 'types/vehicles'

export interface VehicleLike {
  id: Vehicle['id']
  vin: Vehicle['vin']
  stock: Vehicle['stock']
  make: Vehicle['make']
  model: Vehicle['model']
  year: Vehicle['year']
  trim: Vehicle['trim']
}

const GROUP_CARS_OF_INTEREST = 'Car(s) of interest'

export const carToVehicle = (car: CarOfInterest): VehicleWithGroup => ({
  ...GET_DEFAULT_VEHICLE(),
  age: car.age,
  engine: car.engine,
  genericColor: car.genericColor,
  genericInterior: car.genericInterior,
  group: GROUP_CARS_OF_INTEREST,
  id: car.vehicleId ?? car.id,
  imageCount: 0,
  isDeposited: false,
  fuelType: car.fuelType,
  mainImageUrl: car.mainImageUrl,
  make: car.make,
  mileage: car.mileage,
  model: car.model,
  salePrice: car.salePrice,
  stock: car.stock,
  tags: [],
  transmissionType: car.transmissionType,
  trim: car.trim,
  vehicleState: VehicleState[car.vehicleState as keyof typeof VehicleState],
  vin: car.vin,
  year: car.year
})

export const matchesSearchCriteria = <T extends Vehicle | CarOfInterest>(item: T, search: string): boolean => (
  (item?.vin ?? '').toLowerCase().includes(search) ||
  (item?.make ?? '').toLowerCase().includes(search) ||
    (item?.trim ?? '').toLowerCase().includes(search) ||
    (item?.stock ?? '').toLowerCase().includes(search)
)

export const namifyVehicle = <T extends VehicleLike>(item: T): T & { name: string } => ({
  ...item,
  name: item.stock == null ? '' : `${item.stock ?? ''} - ${item.year ?? ''} ${item.make ?? ''} ${item.model ?? ''}`
})

export const formatVehicleDisplayName = (vehicle: VehicleLike | null): string => {
  if (vehicle == null) {
    return ''
  }

  if (vehicle.stock == null && vehicle.vin != null) {
    return vehicle.vin
  }

  return namifyVehicle(vehicle).name
}
