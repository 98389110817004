import type { MouseEvent } from 'react'
import { useCallback, useMemo, useState } from 'react'

import { TimelineFilterItemsIds } from 'api/types'
import type { FilterItem } from './utils'
import getFilterItems, {
  FILTER_ITEMS_TEXTS,
  FilterItemTitles
} from './utils'

export interface UseHeaderProps {
  selectedFilter: TimelineFilterItemsIds
  onChangeFilter: (id: TimelineFilterItemsIds) => void
}

interface UseHeaderReturn {
  filterItems: FilterItem[]
  onOpen: (e: MouseEvent<HTMLButtonElement>) => void
  onClose: () => void
  anchorEl: HTMLElement | null
  selectedFilter: TimelineFilterItemsIds
  selectedFilterText: FilterItemTitles
}

export const useHeader = ({
  selectedFilter,
  onChangeFilter
}: UseHeaderProps): UseHeaderReturn => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const onOpen = useCallback(({ currentTarget }: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(currentTarget)
  }, [])

  const onClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const filterItems = useMemo(() => getFilterItems({
    onClose,
    onChange: onChangeFilter
  }), [onClose, onChangeFilter])

  return {
    filterItems,
    onOpen,
    onClose,
    anchorEl,
    selectedFilter,
    selectedFilterText: FILTER_ITEMS_TEXTS[selectedFilter]
  }
}
