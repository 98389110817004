import { FilterTypesId } from './types'

export const POPOVER_CONFIG = { placement: 'bottom-start' } as const

export const DEALS_FILTERS = [
  { id: FilterTypesId.Today, name: 'Today' },
  { id: FilterTypesId.Yesterday, name: 'Yesterday' },
  { id: FilterTypesId.Last7Days, name: 'Last 7 days' },
  { id: FilterTypesId.Last14Days, name: 'Last 14 days' },
  { id: FilterTypesId.Last30Days, name: 'Last 30 days' }
]
