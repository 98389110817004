import { css } from '@emotion/css'

export default css(`
  .cf-modal-content {
    display: grid;
    gap: 24px;
  }

  .cf-button-content {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }
`)
