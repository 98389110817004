import type { FC } from 'react'
import { cnx, formatCurrencyDecimal } from '@carfluent/common'

import { formatPaymentMethodForView, formatPaymentStatusForView } from 'utils/view_helper'
import type { PaymentDetailsDto } from 'api/types'
import { PaymentStatusId } from 'types/number_enums'
import IconSVG from 'components/inlineImages'

import CLASS_NAME from './styles'

interface PaymentInfoProps {
  className?: string
  isLargeStatusInfo?: boolean
  isTotalBalanceDueVisible?: boolean
  payments: PaymentDetailsDto[]
  totalAmount: number
}

const PaymentInfo: FC<PaymentInfoProps> = ({
  className,
  isLargeStatusInfo = true,
  isTotalBalanceDueVisible = true,
  payments,
  totalAmount
}) => {
  return (
    <div className={cnx(CLASS_NAME, className)}>
      {payments.map((payment) => {
        return (
          <div
            className={cnx('deal-recap-payment-item', isLargeStatusInfo && 'is-large')}
            key={payment.transactionTypeId}
          >
            <div className={cnx('payment-type', isLargeStatusInfo && 'is-large')}>
              {formatPaymentMethodForView(payment.transactionTypeId)}
            </div>

            {isLargeStatusInfo && (
              <div className='status-item-large'>
                <div className='status-name'>
                  {formatPaymentStatusForView(payment.paymentStatusId)}
                </div>

                <div className='status-icon'>
                  {payment.paymentStatusId === PaymentStatusId.Completed && (
                    <IconSVG.StatusCheckmark width={20} height={20} color='rgba(0, 0, 0, 0.87)' />
                  )}
                </div>
              </div>
            )}

            {!isLargeStatusInfo && (
              <div className='status-item-small'>
                <div className='status-icon'>
                  {payment.paymentStatusId === PaymentStatusId.Completed && (
                    <IconSVG.StatusCheckmark width={18} height={18} color='rgba(0, 0, 0, 0.87)'/>
                  )}
                </div>

                <div className='status-icon'>
                  {payment.paymentStatusId === PaymentStatusId.Pending && (
                    <IconSVG.StatusPending width={18} height={18} color='rgba(0, 0, 0, 0.87)'/>
                  )}
                </div>

                <div className='status-name'>
                  {formatPaymentStatusForView(payment.paymentStatusId)}
                </div>
              </div>
            )}

            <div className='amount-item'>
              {formatCurrencyDecimal(payment.amount)}
            </div>
          </div>
        )
      })}

      {isTotalBalanceDueVisible && (
        <>
          <div className='divider'/>

          <div className='deal-recap-payment-total-item'>
            Total balance due <span>{formatCurrencyDecimal(totalAmount)}</span>
          </div>
        </>
      )}
    </div>
  )
}

export default PaymentInfo
