import type { FC } from 'react'
import { useState } from 'react'
import { cnx } from '@carfluent/common'

import SaveCloseButtonsPanel from 'components/common/SaveCloseButtonsPanel'
import UnsavedChangesDialog from 'components/dialogs/UnsavedChanges'

import useTransitionBlocker from 'hooks/useTransitionBlocker'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { scrollToError } from 'utils/validation'

import MonthlyPayments from './components/MonthlyPayments'
import GalleryPromo from './components/GalleryPromo'
import HomePage from './components/HomePage'

import useMarketplacePromo from './hook'
import useGalleryPromo from './components/GalleryPromo/hook'
import MarketplacePromoSkeleton from './components/MarketplacePromoSkeleton'

import CLASS_NAME from './styles'

const MarketplacePromo: FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isOpenUnsavedChanges, setIsOpenUnsavedChanges] = useState(false)
  const { showAlert, showSuccess } = useCustomSnackbar()

  const {
    isPhotosUploading,
    onUpdate,
    onResetGalleryPromo,
    validatePromo,
    setUploadImageError,
    isChanged: isChangedPromo,
    ...promoProps
  } = useGalleryPromo()

  const {
    isValid,
    onSubmit: _onSubmit,
    resetForm,
    isChanged: isChangedMonthlyPayment,
    ...marketplacePromoProps
  } = useMarketplacePromo()

  const initLoading = marketplacePromoProps.isLoading || promoProps.isLoading
  const isFormChanged = isChangedMonthlyPayment || isChangedPromo || marketplacePromoProps.isUploadFilesTouched
  const { proceedTransition } = useTransitionBlocker({
    shouldBlock: isFormChanged,
    onBlock: () => setIsOpenUnsavedChanges(true)
  })

  const onSubmit = async (): Promise<void> => {
    if (!validatePromo() || !isValid) {
      if (!isValid) {
        marketplacePromoProps.validateForm(true)
      }

      scrollToError()
      return
    }

    setIsSubmitting(true)
    try {
      await Promise.all([
        onUpdate(),
        _onSubmit()
      ])
      showAlert('Changes successfully saved.', { variant: 'success' })
    } catch {
      showAlert('Changes failed to save.')
    } finally {
      resetForm(false)
      setUploadImageError('')
      setIsSubmitting(false)
    }
  }

  const onSaveAndSubmit = async (): Promise<void> => {
    if (!validatePromo() || !isValid) {
      setIsOpenUnsavedChanges(false)
      return
    }

    await onSubmit()
    proceedTransition()
  }

  return (
    <div className={cnx('g-submenu-content', 'g-margin-top-big', CLASS_NAME)}>
      {
        initLoading
          ? <MarketplacePromoSkeleton />
          : (
            <>
              <HomePage
                {...marketplacePromoProps}
              />
              <MonthlyPayments
                {...marketplacePromoProps}
              />

              <GalleryPromo
                {...promoProps}
              />

              <UnsavedChangesDialog
                content="Your changes will be lost if you don't save them."
                isOpen={isOpenUnsavedChanges}
                onCloseDialog={() => setIsOpenUnsavedChanges(false)}
                isSubmitting={isSubmitting}
                onDontSaveAndClose={() => {
                  proceedTransition()
                  setIsOpenUnsavedChanges(false)
                }}
                onSaveAndClose={onSaveAndSubmit}
              />

              {isFormChanged &&
                <SaveCloseButtonsPanel
                  titleCloseButton='DISCARD'
                  isSubmitting={isSubmitting}
                  isSubmitDisabled={isPhotosUploading}
                  handleSubmit={onSubmit}
                  handleReset={() => {
                    onResetGalleryPromo()
                    resetForm()
                    showSuccess('Changes discarded.')
                  }}
                />}
            </>
            )
      }
    </div>
  )
}

export default MarketplacePromo
