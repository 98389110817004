import type { FC } from 'react'
import {
  cnx,
  Modal,
  FormInput,
  FormMaskedInput,
  FormDropdown,
  Banner
} from '@carfluent/common'

import type { TransactionLineVendorDto, AccountListItem } from 'api/types'
import type { BaseListItem } from 'types'

import AccountingApiProvider from 'api/accounting.api'
import { optionalLabel } from 'utils/form_helpers'
import { COMMON_MODAL_FORM_CLS, MODAL_MIN_WIDTH } from 'constants/constants'
import DeleteEntityButton from 'components/dialogs/DeleteEntityButton'
import { Checkbox } from 'components/form'
import InputSSN2 from 'components/form/components/InputSSN2'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import AddressFields from 'components/common/AddressFields'
import { ValidationLength } from 'constants/validation'
import CheckBoxIcon from 'components/accounting/CheckBoxIcon'
import Divider from 'components/common/Divider'
import IconSVG from 'components/inlineImages'
import VendorOption from './components/VendorOption'

import type { UseVendorProps } from './hook/types'
import useVendor from './hook'
import CLASS_NAME from './styles'

export type VendorDetailsProps = UseVendorProps

const VendorDetails: FC<VendorDetailsProps> = (props) => {
  const {
    isLoading,
    lienholderId,
    isOpen,
    isEdit,
    values,
    errors,
    touched,
    isDateError,
    error,
    onChange,
    onBlur,
    onNameBlur,
    onDelete,
    onClose,
    onSubmit,
    getPayableAccounts
  } = useVendor(props)

  const automaticallyCreated = lienholderId !== null

  const renderFooter = (): JSX.Element => {
    const renderDeleteButton = (): JSX.Element => (
      <DeleteEntityButton
        isDisabled={isLoading || automaticallyCreated}
        onConfirm={onDelete}
        titleButton='REMOVE VENDOR'
        titleWindow='Delete a vendor?'
      />
    )

    return (
      <CancelSubmitActionsFooter
        isLoading={isLoading}
        onSubmit={onSubmit}
        onClose={onClose}
        renderDeleteButton={isEdit ? renderDeleteButton : undefined}
        submitTitle={isEdit ? 'SAVE' : 'CREATE'}
        isDisabled={automaticallyCreated}
      />
    )
  }

  return (
    <Modal
      className={CLASS_NAME}
      isOpen={isOpen}
      onClose={onClose}
      minWidth={MODAL_MIN_WIDTH}
      renderFooterActions={renderFooter}
      title='Vendor'
    >

      <Banner
        isOpen={automaticallyCreated}
        startAdornment={(
          <IconSVG.Lock
            opacity={1}
            width={24}
            height={24}
            color='#458FFF'
          />
        )}
        message='Editing is disabled because the vendor was created via lienholder.'
        type='info'
      />

      <Banner
        isOpen={isDateError}
        startAdornment={(
          <IconSVG.Lock
            opacity={1}
            width={24}
            height={24}
            color='#B00020'
          />
        )}
        message={error}
        type='error'
      />

      <div className={COMMON_MODAL_FORM_CLS}>
        <FormDropdown<TransactionLineVendorDto | string>
          id='name'
          label='Name'
          mode='search'
          blurMode='create'
          createOptionFromInput={(val) => val}
          className='g-full-width'
          renderOption={VendorOption}
          value={values.name}
          fetchHandler={AccountingApiProvider.getVendors}
          error={errors.name}
          touched={Boolean(touched.name)}
          onChange={onChange}
          onBlur={onNameBlur}
          endAdornment={<></>}
          disabled={automaticallyCreated}
        />

        <FormMaskedInput
          id='phoneNumber'
          label='Phone number'
          mask='phone'
          value={values.phoneNumber}
          error={errors.phoneNumber}
          touched={touched.phoneNumber}
          onChange={onChange}
          onBlur={onBlur}
          disabled={automaticallyCreated}
        />

        <FormInput
          id='email'
          label='Email'
          value={values.email}
          error={errors.email}
          touched={touched.email}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={ValidationLength.EMAIL_MAX}
          disabled={automaticallyCreated}
        />

        <FormInput
          id='ein'
          value={values.ein}
          error={errors.ein}
          touched={touched.ein}
          label={optionalLabel('EIN', !values.isTrack1099Payments)}
          maxLength={ValidationLength.VENDOR_EIN_MAX}
          onChange={onChange}
          onBlur={onBlur}
          disabled={automaticallyCreated}
        />

        <InputSSN2
          id='ssn'
          isInitialSsnVisible={values.id == null}
          value={values.ssn}
          error={errors.ssn}
          touched={touched.ssn}
          label={optionalLabel('SSN', !values.isTrack1099Payments)}
          onChange={onChange}
          onBlur={onBlur}
          isDisabled={automaticallyCreated}
        />

        <AddressFields
          id='address'
          label='Address'
          values={values.address}
          errors={errors.address as any}
          touched={touched.address as any}
          onChange={onChange}
          onBlur={onBlur}
          shouldHideApt
          disabled={automaticallyCreated}
        />

        <div className='g-full-width'>
          <Checkbox
            icon={<CheckBoxIcon />}
            id='isDifferentMailingAddress'
            label='Different mailing address'
            value={values.isDifferentMailingAddress}
            onChange={onChange}
            onBlur={onBlur}
            disabled={automaticallyCreated}
          />
        </div>

        {values.isDifferentMailingAddress && (
          <AddressFields
            id='mailingAddress'
            label='Mailing address'
            values={values.mailingAddress}
            errors={errors.mailingAddress as any}
            touched={touched.mailingAddress as any}
            onChange={onChange}
            onBlur={onBlur}
            shouldHideApt
            disabled={automaticallyCreated}
          />
        )}

        <Divider className='g-full-width' />

        <FormDropdown<AccountListItem>
          className={cnx(CLASS_NAME, 'g-full-width')}
          id='payableAccount'
          dataTestId='payable-account'
          fetchHandler={getPayableAccounts}
          label='Payable account'
          mode='search'
          renderOption={(option: BaseListItem | null) => <div>{option?.name}</div>}
          renderNoOptions={() => <div className={cnx(CLASS_NAME, 'no-options')}>No options found</div>}
          value={values.payableAccount}
          error={errors.payableAccount}
          touched={touched.payableAccount}
          onChange={onChange}
          onBlur={onBlur}
          popoverClassName={cnx(CLASS_NAME, 'popover-container')}
          disabled={automaticallyCreated}
        />

        <Divider className='g-full-width' />

        <div className='g-full-width'>
          <Checkbox
            icon={<CheckBoxIcon />}
            id='isTrack1099Payments'
            label='Track 1099 payments'
            value={values.isTrack1099Payments}
            onChange={onChange}
            onBlur={onBlur}
            disabled={automaticallyCreated}
          />
        </div>
      </div>
    </Modal>
  )
}

export default VendorDetails
