import { formatters as F, parseDateStringUTC } from '@carfluent/common'

import { type DictionaryItem } from 'api/types/utility.types'
import {
  type CampaignDto,
  type CampaignModel,
  SendScheduleType
} from 'api/types/responses'

import { parseCampaignFilter } from './parseCampaignFilter'

const parseCampaignDto = (
  campaign: CampaignDto,
  dictionaries: Record<string, DictionaryItem[]>
): CampaignModel => {
  const isStatusField = true

  return {
    id: campaign.id ?? 0,
    campaignStatusId: campaign.campaignStatusId ?? 0,
    campaignTypeId: campaign.campaignTypeId ?? 0,
    contentDetails: {
      name: campaign.name ?? null,
      senderEmail: campaign.senderEmail ?? null,
      senderName: campaign.senderName ?? null,
      emailSubject: campaign.emailSubject ?? null,
      preHeader: campaign.preheader ?? null,
      body: campaign.bodyRaw ?? null,
      bodyHtml: campaign.bodyHtml ?? null
    },
    createdByUser: campaign.createdByUser,
    filters: {
      filterLeadSource: parseCampaignFilter(
        campaign.leadSourceFilterId,
        campaign.leadSources,
        dictionaries.leadSources ?? []
      ),

      filterLeadStatus: parseCampaignFilter(
        campaign.leadStatusFilterId,
        campaign.leadStatuses,
        dictionaries.leadStatuses ?? [],
        campaign.isLostStatusSelected,
        isStatusField
      ),
      filterLeadTemperature: parseCampaignFilter(
        campaign.leadTemperatureFilterId,
        campaign.leadTemperatures,
        dictionaries.leadTemperatures ?? []
      ),
      filterLeadWithScheduledAppointments: parseCampaignFilter(
        campaign.leadAppointmentsFilterId,
        [],
        []
      ),
      recipientsCounter: null
    },
    recipientsCount: campaign.recipientsCount ?? null,
    rowVersion: campaign.rowVersion,
    schedule: {
      type: campaign.scheduledDateTime == null ? SendScheduleType.Now : SendScheduleType.Schedule,
      date: parseDateStringUTC(campaign.scheduledDateTime) ?? (new Date()),
      time: F.formatLocalDate(parseDateStringUTC(campaign.scheduledDateTime), 'hh:mm aaa')
    },
    statistics: {
      appointmentsCount: campaign.campaignStatistic?.appointmentsCount ?? null,
      id: campaign.campaignStatistic?.id ?? null,
      clickCount: campaign.campaignStatistic?.uniqueClicks ?? null,
      clickRate: campaign.campaignStatistic?.uniqueClicksRate ?? null,
      failedCount: campaign.campaignStatistic?.failed ?? null,
      openCount: campaign.campaignStatistic?.uniqueOpens ?? null,
      openRate: campaign.campaignStatistic?.uniqueOpensRate ?? null,
      salesCount: campaign.campaignStatistic?.salesCount ?? null,
      totalGrossAmount: campaign.campaignStatistic?.totalGrossAmount ?? null,
      totalSentCount: campaign.campaignStatistic?.delivered ?? null,
      unsubscribedCount: campaign.campaignStatistic?.unsubscribes ?? null
    }
  }
}

export default parseCampaignDto
