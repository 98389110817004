import { css } from '@emotion/css'

export default css(`
  display: flex;
  width: 100%;
  height: 48px;
  padding: 8px;
  padding-right: 12px;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: #FAFAFA;
  
  --waveform-size: 32px;
  
  .btn-pause-play {
    cursor: pointer;
    width: var(--waveform-size);
    height: var(--waveform-size);
  }
  
  .waveform-progress {
    height: var(--waveform-size);
    flex: 1;
  }
  
  .duration {
    color: rgba(33, 33, 33, 0.50);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    width: var(--waveform-size);
  }
`)
