
import { serializers } from '@carfluent/common'
import type { DateType } from 'api/types'
import serializeDateFilters from 'utils/filters/serializeDateFilters'

import type { GenerateSaleReportDto } from 'api/types/requests'

const serializeFilters = (filters: GenerateSaleReportDto): GenerateSaleReportDto => {
  const dates = serializeDateFilters({
    from: filters.dateRange.from,
    to: filters.dateRange.to
  }, serializers.serializeDate)

  return {
    ...filters,
    dateRange: dates as DateType
  }
}

export default serializeFilters
