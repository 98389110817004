import { type FC, useMemo } from 'react'

import type { DictionaryItem } from 'api/types'
import CreatedCompleteLayout, { type CreatedCompleteLayoutProps } from 'components/crm/CreatedCompleteLayout'
import { TaskMode, TaskType, type TaskOmitRespondType } from 'pages/crm/LeadDetailsView/hook/types'

import CompleteCallForm from './components/CallTaskForm'
import CompleteReminderTaskForm from './components/ReminderTaskForm'
import CompleteAppointmentTaskForm from './components/AppointmentTaskForm'
import CreditAppTask from './components/CreditAppTask'
import type { CompleteTaskFormData, UseCompleteTaskFormReturn } from './hook/types'
import useCompleteForm from './hook'

import CLASS_NAME from './styles'

interface CompleteTaskProps {
  completeLayoutProps: CreatedCompleteLayoutProps
  reminderSubjects: DictionaryItem[]
  subject: string
  taskType: TaskType
  taskMode: TaskMode | null
  taskId: number
  onSubmit: (values: CompleteTaskFormData, taskId: number) => Promise<void>
  onCloseTask: (taskId: number | null, taskMode?: TaskMode) => void
}

const CompleteTaskComponentMap: Record<TaskOmitRespondType, FC<UseCompleteTaskFormReturn>> = {
  [TaskType.Appointment]: CompleteAppointmentTaskForm,
  [TaskType.Reminder]: CompleteReminderTaskForm,
  [TaskType['Follow up call']]: CompleteCallForm,
  [TaskType['Credit app received']]: CreditAppTask
}

const CompleteTask: FC<CompleteTaskProps> = ({ completeLayoutProps, ...props }) => {
  const formProps = useCompleteForm(props)

  const FormComponent = useMemo(() => CompleteTaskComponentMap[props.taskType as keyof typeof CompleteTaskComponentMap], [props.taskType])

  return (
    <div className={CLASS_NAME}>
      <CreatedCompleteLayout {...completeLayoutProps} showActionBar={false} />
      <FormComponent {...formProps} />
    </div>
  )
}

export default CompleteTask
