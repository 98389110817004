import { parseDateStringUTC } from '@carfluent/common'

import type { Deal2Dto } from 'api/types'
import { type ActiveLenderDecisionModel, type DealModel } from 'api/types/responses'
import { GET_DEFAULT_TRADE_IN_MODEL } from 'api/defaults/tradeInDetailsModel'
import { GET_DEFAULT_ACTIVE_LENDER_DECISION } from 'api/defaults/activeLenderDecision'
import { isAddressDataEqual } from 'utils/address'
import { isBusinessType, isPersonalType, isWholesaleType } from 'utils/deals/workflowTypes'
import namifySalesperson from 'utils/deals/namifySalesperson'
import { toDateOrNull } from 'utils/parse_date'
import { decimalToPercent } from 'utils/math'

import parseVehicleDto from './parseVehicleDto'
import parseBuyerDto from './parseBuyerDto'
import parseCobuyerDto from './parseCobuyerDto'
import parseDealFeesDto from './parseDealFeesDto'
import { parseCheckoutDetailsDto } from './parseCheckoutDetailsDto'

const parseDealDto = (deal: Deal2Dto): DealModel => {
  const { currentDealStep } = deal

  const currentWorkflowStoppedAt =
    currentDealStep !== null ? parseDateStringUTC(currentDealStep.startDateTime) : null

  const activeLenderDecision: ActiveLenderDecisionModel | null =
    deal.creditApplication?.financingApplicationLenderDecisionDetails
      ?.find(d => d.isAccepted) ?? null

  const buyer = parseBuyerDto(deal.personalDetails)
  const cobuyer = parseCobuyerDto(deal.coApplicantFinancingDetails)
  const isManualSalesTax = deal.dealFees.overridenSalesTaxPercent == null
  const financingBuyRate = deal?.creditApplication?.buyRate ?? null

  const parsed: DealModel = {
    ...deal,
    createdDate: parseDateStringUTC(deal.createdDate),
    accountingStateChangeDate: parseDateStringUTC(deal.accountingStateChangeDate),
    firstPaymentDate: toDateOrNull(deal.firstPaymentDate),
    tradeInDetails: deal.tradeInDetails ?? GET_DEFAULT_TRADE_IN_MODEL(),
    vehicle: parseVehicleDto(deal.vehicle),
    businessDetails: deal.businessDetails,
    buyer,
    cobuyer: {
      ...cobuyer,
      sameAddressAsMainApplicant: isAddressDataEqual(
        cobuyer.currentAddressDetails,
        buyer.addressDetails
      )
    },
    currentWorkflowStoppedAt,
    financingFees: {
      acquisitionFee: deal.creditApplication?.acquisitionFee ?? null,
      buyRate: financingBuyRate != null ? decimalToPercent(financingBuyRate) : null,
      reserve: deal.creditApplication?.reserve ?? null
    },
    isPersonal: isPersonalType(deal.dealTypeId),
    isBusiness: isBusinessType(deal.dealTypeId),
    isWholesale: isWholesaleType(deal.dealTypeId),
    isManualSalesTax,
    isTradeInSectionVisible: deal.tradeInDetails != null,
    isCobuyerSectionVisible: (deal.coApplicantFinancingDetails?.id ?? 0) > 0,
    salesperson: namifySalesperson(deal.salesperson),
    saleDate: toDateOrNull(deal.saleDate),
    soldDate: toDateOrNull(deal.soldDate),
    activeLenderDecision: activeLenderDecision ?? GET_DEFAULT_ACTIVE_LENDER_DECISION(),
    hasLenderDecision: activeLenderDecision?.lienholder?.id != null,
    coverageDetails: deal.coverageDetails ?? [],
    checkoutDetails: parseCheckoutDetailsDto(deal.checkoutDetails, deal.id),
    dealFees: parseDealFeesDto(deal.dealFees)
  }

  return {
    ...parsed,
    dealFees: {
      ...parsed.dealFees,
      overridenSalesTaxAmount: parsed.financeInfo.resultSalesTaxAmount
    }
  }
}

export default parseDealDto
