import { css } from '@emotion/css'

export default css(`
  .link {
    width: 100%;
    display: table; // for responsive ellipsis
    table-layout: fixed;
    white-space: nowrap;

    .link-text {
      display: table-cell; // for responsive ellipsis
      color: #458FFF;
    }
  }
`)
