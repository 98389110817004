/**
 * @deprecated - use DealerProductPackageDto
 * **/
export interface Package {
  packageName: string
  coverages: Coverage[]
}

/**
 * @deprecated - use DealerProductCoverageDto
 * **/
export interface Coverage {
  id: number | string
  termMiles: string | null
  termMonths: string | null
  deductibles: Deductible[]
}

/**
 * @deprecated - use DealerProductDeductibleDto
 * **/
export interface Deductible {
  id: number
  amount: number
  dealerCost: number
  dealerRetailPrice: number
  description: string | null
}

// we have the same enum of package types as on the backend
// where key is packageType and number is packageTypeId
export enum PackageTypes {
  Prime = 1,
  Standard = 2
}

/**
 * @deprecated - use DealerProductCoveragePackageSettingsDto
 * **/
export interface PackageSetting {
  packageType: keyof typeof PackageTypes
  packageTypeId: number
  dealerProductsIds: number[]
}

export interface CoverageSummary {
  productType: string
  dealerRetailPrice: number
}

export enum ProductType {
  ServiceContract = 'Service Contract',
  GapInsurance = 'GAP Insurance',
  CeramicCoating = 'Ceramic Coating',
  AppearanceProtection = 'Appearance Protection',
  TireAndWheel = 'Tire and Wheel',
  Theft = 'Theft'
}
