
import { type FC, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { cnx, formatCurrencyDecimal } from '@carfluent/common'

import TruncatedItemsList from 'components/common/TruncatedItemsList'
import CarImage from 'components/common/CarImage'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import TooltipedText from 'components/common/TooltipedText'
import DepositedLabel from 'components/common/DepositedLabel'

import { type DictionaryItem, type VehicleById } from 'api/types'
import {
  formatIntegerForView,
  formatInventoryDateWithAgeForView,
  formatVehicleNameForView
} from 'utils/view_helper'
import { linkToCar } from 'utils/website'

import AddTag from './components/AddTag'
import CopyButton from 'components/common/CopyButton'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

import { CLASS_NAME, getTruncatedClassName } from './styles'

const MIN_ROWS = 1

interface VehicleShortInfoPanelProps {
  isCarOnDeposit: boolean
  className?: string
  linkToWebsite: string
  vehicleDetails: VehicleById
  tags: DictionaryItem[]
  onAddTag: (tag: string) => Promise<void>
  selectedTags: number[]
  isLoading: boolean
  onAddTagInVehicle: (tagId: number) => void
  onDeleteTagFromVehicle: (tag: string) => void
  isCarWebSiteLinkHidden: boolean
  isCarReconLinkHidden: boolean
}

const VehicleShortInfoPanel: FC<VehicleShortInfoPanelProps> = ({
  isCarOnDeposit,
  className,
  linkToWebsite,
  vehicleDetails,
  tags,
  onAddTag,
  isLoading,
  selectedTags,
  onAddTagInVehicle,
  onDeleteTagFromVehicle,
  isCarWebSiteLinkHidden,
  isCarReconLinkHidden
}) => {
  const [titleWidth, setTitleWidth] = useState<number | null>(null)
  const titleRef = useRef<HTMLDivElement | null>(null)
  const linkToWebsiteCar = linkToCar(linkToWebsite, vehicleDetails)
  const [isOpen, setIsOpen] = useState(false)
  const truncatedClassName = useMemo(() => getTruncatedClassName(titleWidth), [titleWidth])
  const isOutsideClosing = useRef(false)
  const closingTimeout = useRef<NodeJS.Timeout>()
  const { showSuccess } = useCustomSnackbar()

  const onClosePopup = (): void => {
    isOutsideClosing.current = true

    // move outside closing to async queue to prevent onTogglePopup handler
    closingTimeout.current = setTimeout(() => {
      setIsOpen(false)
      isOutsideClosing.current = false
    }, 0)
  }

  const onTogglePopup = (): void => {
    if (isOutsideClosing.current) {
      return
    }

    setIsOpen(prev => !prev)
  }

  const tagsToShow = useMemo(() => {
    return tags.reduce((res: string[], { name, id }) => {
      if (selectedTags.includes(id)) {
        res.push(name)
      }

      return res
    }, [])
  }, [tags, selectedTags])

  useEffect(() => {
    if (titleRef.current != null) {
      const { width } = titleRef.current.getBoundingClientRect()
      setTitleWidth(width)
    }
  }, [vehicleDetails.year, vehicleDetails.make, vehicleDetails.model, vehicleDetails.trim])

  useEffect(() => () => clearTimeout(closingTimeout.current), [])

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <BlockSkeleton isLoading={isLoading} className='title-block'>
        <div className='title-wrapper'>
          <div ref={titleRef} className='title'>
            <TooltipedText
              className={cnx((isCarOnDeposit) && 'with-label')}
              value={formatVehicleNameForView(
                vehicleDetails.year,
                vehicleDetails.make,
                vehicleDetails.model,
                vehicleDetails.trim)}
            />
          </div>
          {isCarOnDeposit && <DepositedLabel className='vehicle-status-deposit-label' />}

          <div className='button-wrapper'>
            <span
              className='add-btn'
              onClick={onTogglePopup}
            >
              +
            </span>

            {isOpen && (
              <AddTag
                selectedItems={selectedTags}
                onAddTagInVehicle={onAddTagInVehicle}
                onAddTag={onAddTag}
                isTagLoading={isLoading}
                items={tags}
                onClose={onClosePopup}
              />
            )}
          </div>

          <TruncatedItemsList
            maxRows={MIN_ROWS}
            items={tagsToShow}
            onDelete={onDeleteTagFromVehicle}
            className={truncatedClassName}
          />
        </div>
      </BlockSkeleton>

      <div className='vehicle-info-wrapper'>
        <BlockSkeleton isLoading={isLoading} className='image-block'>
          <CarImage
            width={99}
            height={64}
            withExclamationMark
            className='vehicle-image'
            vehicleImageUrl={vehicleDetails.mainImageUrl}
          />
        </BlockSkeleton>

        <div className='vehicle-info-container'>
          <div className='cell-buyer-info cell-two-lines'>
            <BlockSkeleton isLoading={isLoading} className='line vin-line'>
              <div className='property-name'>VIN:</div>
              <TooltipedText rootClassName='property-value' value={vehicleDetails.vin} />
              <CopyButton nameOfField='VIN' value={vehicleDetails.vin} showConfirmation={showSuccess} />
            </BlockSkeleton>

            <BlockSkeleton isLoading={isLoading} className='line stock-line skeleton-80'>
              <div className='property-name'>Stock #:</div>
              <TooltipedText rootClassName='property-value' value={vehicleDetails.stock} />
            </BlockSkeleton>
          </div>

          <div className='cell-buyer-info cell-two-lines'>
            <BlockSkeleton isLoading={isLoading} className='line milage-line'>
              <div className='property-name'>Mileage:</div>
              <TooltipedText rootClassName='property-value' value={formatIntegerForView(vehicleDetails.mileage, ' miles') ?? '-'} />
            </BlockSkeleton>

            <BlockSkeleton isLoading={isLoading} className='line price-line skeleton-80'>
              <div className='property-name'>Price:</div>
              <TooltipedText rootClassName='property-value price-value' value={formatCurrencyDecimal(vehicleDetails.salePrice, { allowZero: false, emptyValues: '-' })} />
            </BlockSkeleton>
          </div>

          <div className='cell-buyer-info cell-two-lines cell-links'>
            <BlockSkeleton isLoading={isLoading} className='line'>
              <div className='property-name'>Website link:</div>
              {
                isCarWebSiteLinkHidden
                  ? '-'
                  : (
                    <Link className='property-value link' to={linkToWebsiteCar ?? ''} target='_blank' rel='noreferrer'>
                      Open link
                    </Link>
                    )
              }

            </BlockSkeleton>

            <BlockSkeleton isLoading={isLoading} className='line skeleton-80'>
              <div className='property-name'>Recon link:</div>
              {
                isCarReconLinkHidden
                  ? '-'
                  : (
                    <Link className='property-value link' to={`/recon/${vehicleDetails.id}`} rel='noreferrer'>
                      Open link
                    </Link>
                    )
              }

            </BlockSkeleton>
          </div>

          <BlockSkeleton isLoading={isLoading} className='line inventory-line'>
            <div className='property-name'>Inventory date:</div>
            <TooltipedText rootClassName='property-value' value={formatInventoryDateWithAgeForView(vehicleDetails.inventoryDate, vehicleDetails.age)} />
          </BlockSkeleton>
        </div>
      </div>
    </div>
  )
}

export default observer(VehicleShortInfoPanel)
