import type { FormValidation } from '@carfluent/common'
import { type WebsiteSettingsModel } from 'api/types/responses'

import {
  Errors,
  alphanumericWithSymbolsField,
  nonNegativeNumber,
  requiredNumber
} from 'utils/validationPresets'

export const DEPENDENT_VALIDATIONS = {
  isEstimatedMonthlyPaymentEnabled: ['cashDownPercentage', 'cashDownAmount', 'term', 'apr']
}

export const validationRules: FormValidation<WebsiteSettingsModel> = {
  term: (v, ctx) => {
    if (ctx?.isEstimatedMonthlyPaymentEnabled === false) {
      return null
    }
    return requiredNumber(v)
  },
  apr: (v, ctx) => {
    if (ctx?.isEstimatedMonthlyPaymentEnabled === false) {
      return null
    }
    return v == null ? Errors.Empty : nonNegativeNumber(v)
  },
  cashDownPercentage: (value, context) => {
    if (context?.isEstimatedMonthlyPaymentEnabled === false) {
      return null
    }
    if (context?.cashDownAmount == null && value == null) {
      return 'One of cash down should be filled'
    }

    return null
  },

  cashDownAmount: (value, context) => {
    if (context?.isEstimatedMonthlyPaymentEnabled === false) {
      return null
    }
    if (context?.cashDownPercentage == null && value == null) {
      return 'One of cash down should be filled'
    }

    return null
  },

  'heroData.description': alphanumericWithSymbolsField(true),
  'heroData.header': alphanumericWithSymbolsField(true),

  'carAds.ads1.description': alphanumericWithSymbolsField(true),
  'carAds.ads1.header': alphanumericWithSymbolsField(true),
  'carAds.ads2.description': alphanumericWithSymbolsField(true),
  'carAds.ads2.header': alphanumericWithSymbolsField(true),
  'carAds.ads3.description': alphanumericWithSymbolsField(true),
  'carAds.ads3.header': alphanumericWithSymbolsField(true)
}
