import type { UploadedAssetWithThumbnailDto } from 'api/types'

export const DEFAULT_UPLOADED_PHOTOS: UploadedAssetWithThumbnailDto[] = []

export type PickerFields = 'errors' | 'values' | 'touched' | 'onBlur' | 'isValid' | 'onSubmit' | 'validateForm'

export const MAX_PERCENT_NUMBER = 99
export const INFO_TEXT = 'Adjust the settings to see estimated monthly payments at the website inventory without soft pull.'

export const MAX_FILE_SIZE = 5 * 1024 * 1024 // 5 MB

export const FILE_EXTENSIONS = ['.jpeg', '.jpg', '.png']
