import { type FC, useMemo } from 'react'
import { FormDropdown } from '@carfluent/common'

import type { ViewDealerRoles } from 'api/types'
import { keys } from 'utils/general'
import { VIEW_DEALER_ROLE_NAMES } from 'constants/constants'

export interface DealerRolesDropdownProps {
  isReadonly?: boolean
  disableClearable?: boolean
  id?: string
  value: ViewDealerRoles | null
  onChange: (field: string, value: ViewDealerRoles | null) => void
  error?: string
  showSuperAdmin?: boolean
}

const FULL_ROLE_ITEMS = keys(VIEW_DEALER_ROLE_NAMES)

const DealerRolesDropdown: FC<DealerRolesDropdownProps> = ({
  isReadonly = false,
  disableClearable = false,
  error,
  id = 'dealer-roles-dropdown',
  onChange,
  showSuperAdmin = false,
  value
}) => {
  const roleItems = useMemo(
    () => showSuperAdmin ? FULL_ROLE_ITEMS : FULL_ROLE_ITEMS.slice(1),
    [showSuperAdmin]
  )

  return (
    <div className='cf-dealer-roles-dropdown'>
      <FormDropdown
        id={id}
        error={error}
        disabled={isReadonly}
        disableClearable={disableClearable}
        formatDisplayValue={(v) => v != null ? VIEW_DEALER_ROLE_NAMES[v] : ''}
        renderOption={(v: ViewDealerRoles) => VIEW_DEALER_ROLE_NAMES[v]}
        label='Role'
        mode='select'
        onChange={onChange}
        options={roleItems}
        showError={error != null && error !== ''}
        value={value}
      />
    </div>
  )
}

export default DealerRolesDropdown
