import type { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { CellWrapper } from '@carfluent/common'

import TooltipedText from 'components/common/TooltipedText'
import { formatPhoneNumberForView } from 'utils/view_helper'
import CLASS_NAME from './styles'

export interface ContactsData {
  email: string | null
  phone: string | null
}

export interface ContactsCellProps {
  getValue: () => ContactsData
}

const ContactsCell: FC<ContactsCellProps> = ({ getValue }) => {
  const { email, phone } = getValue()
  const phoneStr = formatPhoneNumberForView(phone)

  return (
    <CellWrapper className={CLASS_NAME}>
      {(email != null) && (
        <TooltipedText className='cf-link-text' value={email} />
      )}

      {(phoneStr != null) && <TooltipedText value={phoneStr} />}
    </CellWrapper>
  )
}

export default observer(ContactsCell)
