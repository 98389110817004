import type { FC } from 'react'
import { Button } from '@carfluent/common'

import FiltersPanel from 'components/common/FiltersPanel'
import OverlayedPageHOC from 'components/PageOverlay/OverlayedPageHOC'
import BackToTop from 'components/common/BackToTop'
import Table from 'components/common/Table'

import useEmailTemplatesList from './hook'

import CLASS_NAME from './styles'

const EmailTemplatesList: FC = () => {
  const {
    isLoading,
    isTemplateAdding,
    emptyTableMessage,
    rows,
    columns,
    search,
    onAddNewTemplate,
    onOpenEmailTemplateDetails,
    onSearchChange,
    onSearch,
    onBottomReached
  } = useEmailTemplatesList()

  return (
    <div className={CLASS_NAME}>
      <FiltersPanel
        title='Email templates'
        searchProps={{
          isLoading,
          onChange: onSearchChange,
          value: search,
          placeholder: 'Search by name',
          withDeleteIcon: true,
          onSearch
        }}
        renderActions={() => (
          <Button
            onClick={onAddNewTemplate}
            isLoading={isTemplateAdding}
          >
            NEW TEMPLATE
          </Button>
        )}
      />

      <Table
        columns={columns}
        data={rows}
        emptyTableMessage={emptyTableMessage}
        isLoading={isLoading}
        onBottomReached={onBottomReached}
        onRowClick={onOpenEmailTemplateDetails}
        headerDataTestId='email-templates-table-header'
        bodyDataTestId='email-templates-table-body'
      />

      <BackToTop />
    </div>
  )
}

export default OverlayedPageHOC(EmailTemplatesList)
