import { useState } from 'react'
import { type FC, type ReactNode } from 'react'
import { Modal, cnx } from '@carfluent/common'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import { MODAL_MIN_WIDTH } from 'constants/constants'
import CLASS_NAME from './styles'

export interface DeleteModalProps {
  title: string
  className?: string
  content: ReactNode
  isOpen: boolean
  onConfirm: () => (Promise<void> | void)
  onClose: () => void
  onCloseAfterSubmit?: () => void
  submitTitle?: string
  cancelTitle?: string
}

// TODO-OF refactor change to confirm Modal
const DeleteModal: FC<DeleteModalProps> = ({
  className,
  content,
  isOpen,
  onClose,
  onConfirm,
  onCloseAfterSubmit,
  title,
  submitTitle = 'DELETE',
  cancelTitle
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async (): Promise<void> => {
    setIsSubmitting(true)
    await onConfirm()
    setIsSubmitting(false)

    if (onCloseAfterSubmit != null) {
      onCloseAfterSubmit()
      return
    }

    onClose()
  }

  const renderFooter = (): JSX.Element => {
    return (
      <CancelSubmitActionsFooter
        isLoading={isSubmitting}
        onSubmit={onSubmit}
        onClose={onClose}
        submitTitle={submitTitle}
        cancelTitle={cancelTitle}
      />
    )
  }

  return (
    <Modal
      className={cnx(CLASS_NAME, className)}
      isOpen={isOpen}
      minWidth={MODAL_MIN_WIDTH}
      onClose={onClose}
      renderFooterActions={renderFooter}
      title={title}
    >
      {(typeof content === 'string') ? (<p style={{ lineHeight: '24px' }}>{content}</p>) : content}
    </Modal>
  )
}

export default DeleteModal
