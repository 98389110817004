import type { FC } from 'react'
import { useRef, useState, useMemo, useCallback } from 'react'
import { FormInput, useClickOutside, Button } from '@carfluent/common'

import type { DictionaryItem } from 'api/types'
import plusIcon from 'assets/plus_icon_thin.svg'

import CLASS_NAME from './styles'

const SEARCH_ERROR = 'Tag is too long. Maximum 36 characters'
const MAX_SEARCH_LENGTH = 36

interface AddTagProps {
  onClose: () => void
  items: DictionaryItem[]
  onAddTag: (tag: string) => Promise<void>
  isTagLoading: boolean
  onAddTagInVehicle: (tagId: number) => void
  selectedItems: number[]
}

const AddTag: FC<AddTagProps> = ({ onClose, items, onAddTag, isTagLoading, onAddTagInVehicle, selectedItems }) => {
  const popoverRef = useRef<HTMLDivElement | null>(null)
  const [search, setSearch] = useState('')

  useClickOutside(popoverRef, onClose, { capture: true })

  const onChange = useCallback((_, value) => {
    setSearch((value ?? '').toString())
  }, [])

  const { itemsToShow, isAddDisabled } = useMemo(() => {
    const isExistInList = items.find(({ name }) => name.toLowerCase() === search.trim().toLowerCase()) != null

    return {
      isAddDisabled: isExistInList || search.trim() === '',
      itemsToShow: items.filter(({ name, id }) =>
        name.trim()
          .toLowerCase()
          .includes(
            search.trim()
              .toLowerCase()
          ) && !selectedItems.includes(id))
    }
  }, [search, items, selectedItems])

  const isLongSearch = search.length > MAX_SEARCH_LENGTH

  return (
    <div ref={popoverRef} className={CLASS_NAME}>
      <div className='cf-input-wrapper'>
        <FormInput
          id='searchValue'
          label='Search or add new tag'
          onChange={onChange}
          value={search}
          disabled={isTagLoading}
          error={isLongSearch ? SEARCH_ERROR : null}
          touched
        />
      </div>

      <div className='cf-content-wrapper'>
        <Button
          isLoading={isTagLoading}
          isDisabled={isTagLoading || isAddDisabled || isLongSearch}
          onClick={async () => await onAddTag(search)}
          variant='text'
          className='badge'
        >
          <img src={plusIcon} alt='plus' />
          Add new tag
        </Button>

        {itemsToShow.map(({ name, id }) => (
          <Button
            onClick={() => onAddTagInVehicle(id)}
            variant='text'
            className='badge'
            key={id}
            isDisabled={isTagLoading}
          >
            {name}
          </Button>
        ))}
      </div>
    </div>
  )
}

export default AddTag
