import { type FC, useState, useRef } from 'react'
import { Button, PopoverV2, cnx, PopoverPropsV2 } from '@carfluent/common'
import { type FetchHandler } from '@carfluent/common/dist/hooks/useAutocompleteInfiniteList'

import { type DocumentFormDto, type DocumentFormPackDto } from 'api/types'
import IconSVG from 'components/inlineImages'
import SearchList from 'components/deals/SearchList'

import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

const OPTIONS: PopoverPropsV2['options'] = {
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 16]
      }
    }
  ]
}

export interface ManageFormDropDownProps {
  title?: string
  fetchHandler: FetchHandler<DocumentFormDto | DocumentFormPackDto>
  onSelect: (item: DocumentFormDto | DocumentFormPackDto) => Promise<void> | void
}

const ManageFormDropDown: FC<ManageFormDropDownProps> = ({
  title,
  fetchHandler,
  onSelect
}) => {
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const onClose = (): void => setIsOpen(false)

  return (
    <div ref={anchorEl} className={CLASS_NAME}>
      <Button
        className={cnx('dropdown-button', isOpen && 'is-active')}
        onClick={() => setIsOpen(true)}
        variant='text'
        endAdornment={<IconSVG.DropdownArrowDown />}
      >
        {title}
      </Button>

      <PopoverV2
        isOpen={isOpen}
        onClose={onClose}
        anchorEl={anchorEl}
        options={OPTIONS}
        className={POPOVER_CLASS_NAME}
      >
        <SearchList
          fetchHandler={fetchHandler}
          onSelect={onSelect}
          onClose={onClose}
        />
      </PopoverV2>
    </div>
  )
}

export default ManageFormDropDown
