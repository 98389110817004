import type { FC } from 'react'
import Row, { RowProps } from './components/Row'
import { getRowClassName } from './utils'
export { createTableClass } from './styles'

interface ReportBalanceSheetProfitLossTableProps {
  data: RowProps[]
}

const ReportBalanceSheetProfitLossTable: FC< ReportBalanceSheetProfitLossTableProps> = ({ data }) => {
  return (
    <table className='cf-bs-pl-table'>
      <tbody>
        {data.map(({ name, variant, key, ...other }, idx) => (
          <Row
            key={key ?? `${name ?? ''}-${other.text ?? ''}-${idx}`}
            className={getRowClassName({ variant, name })}
            variant={variant}
            {...other}
          />
        ))}
      </tbody>
    </table>
  )
}

export default ReportBalanceSheetProfitLossTable
