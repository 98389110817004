import type { ItemWithId, KeyVal } from 'types'

function replaceById<TRes extends KeyVal> (
  obj1: KeyVal,
  dict: ItemWithId[],
  foreignKey: string
): TRes {
  const res = {
    ...obj1,
    [foreignKey]: dict.find(item => item.id === obj1[foreignKey]) ?? null
  }

  return res as any as TRes
}

export default replaceById
