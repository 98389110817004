import { FC } from 'react'
import { formatCurrencyAccounting } from '@carfluent/common'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

import type { SaleSummaryModel } from 'api/types/responses'

import CLASS_NAME from './styles'

export interface SummaryProps {
  summary: SaleSummaryModel | null
  isLoading: boolean
}

export const AMOUNT_FORMAT = { emptyValues: '-', allowNegative: true }

const Summary: FC<SummaryProps> = ({
  summary,
  isLoading
}) => {
  return (
    <div className={CLASS_NAME}>
      <div className='summary-wrapper'>

        <div className='summary-item'>
          <div className='item'>
            <div className='summary-item__label'>
              <BlockSkeleton isLoading={isLoading}>
                Financing
              </BlockSkeleton>
            </div>
            <div className='summary-item__value'>
              <BlockSkeleton isLoading={isLoading}>
                {summary?.financingCount ?? '-'}
              </BlockSkeleton>
            </div>
          </div>

          <div className='item'>
            <div className='summary-item__label'>
              <BlockSkeleton isLoading={isLoading}>
                Cash
              </BlockSkeleton>
            </div>
            <div className='summary-item__value'>
              <BlockSkeleton isLoading={isLoading}>
                {summary?.cashCount ?? '-'}
              </BlockSkeleton>
            </div>
          </div>

          <div className='item'>
            <div className='summary-item__label bold'>
              <BlockSkeleton isLoading={isLoading}>Total sales</BlockSkeleton>
            </div>
            <div className='summary-item__value bold'>
              <BlockSkeleton isLoading={isLoading}>{summary?.totalSalesCount ?? '-'}</BlockSkeleton>
            </div>
          </div>
        </div>

        <div className='summary-item'>
          <div className='item'>
            <div className='summary-item__label'><BlockSkeleton isLoading={isLoading}>Personal</BlockSkeleton></div>
            <div className='summary-item__value'>
              <BlockSkeleton isLoading={isLoading}>{summary?.personalCount ?? '-'}</BlockSkeleton>
            </div>
          </div>

          <div className='item'>
            <div className='summary-item__label'><BlockSkeleton isLoading={isLoading}>Business</BlockSkeleton></div>
            <div className='summary-item__value'>
              <BlockSkeleton isLoading={isLoading}>{summary?.businessCount ?? '-'}</BlockSkeleton>
            </div>
          </div>

          <div className='item'>
            <div className='summary-item__label'><BlockSkeleton isLoading={isLoading}>Wholesale</BlockSkeleton></div>
            <div className='summary-item__value'>
              <BlockSkeleton isLoading={isLoading}>{summary?.wholesaleCount ?? '-'}</BlockSkeleton>
            </div>
          </div>
        </div>

        <div className='summary-item sum'>
          <div className='item'>
            <BlockSkeleton isLoading={isLoading}>
              <div className='summary-item__value'>{formatCurrencyAccounting(summary?.frontGross, AMOUNT_FORMAT)}</div>
              <div className='summary-item__label'>Front gross</div>
            </BlockSkeleton>
          </div>

          {!isLoading && (<span>+</span>)}

          <div className='item'>
            <BlockSkeleton isLoading={isLoading}>
              <div className='summary-item__value'>{formatCurrencyAccounting(summary?.backGross, AMOUNT_FORMAT)}</div>
              <div className='summary-item__label'>Back gross</div>
            </BlockSkeleton>
          </div>

          {!isLoading && (<span>=</span>)}

          <div className='item'>
            <BlockSkeleton isLoading={isLoading}>
              <div className='summary-item__value'>{formatCurrencyAccounting(summary?.grossProfit, AMOUNT_FORMAT)}</div>
              <div className='summary-item__label'>Gross profit</div>
            </BlockSkeleton>
          </div>
        </div>

      </div>
    </div>

  )
}

export default Summary
