import { useEffect } from 'react'
import { type UseHorizontalScrollableList } from './types'

const useHorizontalScrollableList = ({
  scrollableRef
}: UseHorizontalScrollableList): void => {
  useEffect(() => {
    const onScrollableWheel = (evt: WheelEvent): void => {
      const target = evt.target as Node
      const container = scrollableRef.current

      if (container == null) {
        return
      }

      if ((target !== container) && !container.contains(target)) {
        return
      }

      if (Math.abs(evt.deltaX) === 0) {
        container.scrollLeft -= (evt.deltaY > 0) ? 30 : -30
      }
    }

    window.addEventListener('wheel', onScrollableWheel)

    return () => {
      window.removeEventListener('wheel', onScrollableWheel)
    }
  }, [scrollableRef])
}

export default useHorizontalScrollableList
