import { css } from '@emotion/css'

export const createStyleClass = (): string => {
  return css(`
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;

    & .cf-tooltip-costs-text {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: auto;
    }
  `)
}
