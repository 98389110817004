import { css } from '@emotion/css'

export default css(`
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;
  gap: 8px;

  .back-gross-rows {
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    &.bottom-rows {
      margin-top: 16px;
    }
    
    .cf-divider { margin: 8px 0; }
   
    .row {
      display: flex;
      flex-direction: row;
      gap: 8px;
      justify-content: space-between;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: #101010;

      &.indented-left {
        padding-left: 16px;
      }
      
      .bold {
        font-weight: 700;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
`)
