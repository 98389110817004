import type { FC } from 'react'
import { utils } from '@carfluent/common'

import DragHereMessage from 'components/common/DragHereMessage'
import FormFilesUpload, { type FormFilesUploadValue } from 'components/common/FormFilesUpload'
import DeleteModal from 'components/dialogs/DeleteModal'

import DealDocumentRow from './components/DealDocumentRow'

import { DEFAULT_FILE_MAX_SIZE } from 'constants/files'
import {
  type UseDealDocumentsProps,
  useDealDocuments,
  MAX_FILE_NUMBER,
  MAX_LIMIT_ERROR
} from './hook'

import CLASS_NAME from './styles'
import type { PersonalDetailsDto } from 'api/types'

const FILE_EXTENSIONS = ['pdf', 'xlsx', 'xls', 'doc', 'docx', 'jpg', 'jpeg', 'png']
const EMPTY_IMAGE_VALUE: FormFilesUploadValue = []
const MAX_FILES_SIZE = DEFAULT_FILE_MAX_SIZE * 3

export interface DealDocumentsProps extends UseDealDocumentsProps {
  personalDetails?: PersonalDetailsDto | null
}

const DealDocuments: FC<DealDocumentsProps> = ({ personalDetails, ...props }) => {
  const {
    error,
    onError,
    onChangeFiles,
    documents,
    onClickFile,
    deleteFileId,
    setDeleteFileId,
    onSubmitRemoveFile
  } = useDealDocuments(props)

  return (
    <div className={CLASS_NAME}>
      <FormFilesUpload
        id='dealDocFiles'
        acceptedExtensions={FILE_EXTENSIONS}
        maxFileSize={MAX_FILES_SIZE}
        maxFilesTotalSize={utils.UNLIMITED_FILES_SIZE}
        onChange={onChangeFiles}
        onError={onError}
        suggestedExtensions={FILE_EXTENSIONS}
        value={EMPTY_IMAGE_VALUE}
        dragHereMessage={<DragHereMessage />}
        className={CLASS_NAME}
        maxFileNumber={MAX_FILE_NUMBER}
        errorMessage={error}
        showError={error != null}
        messageFilesNumberLimit={MAX_LIMIT_ERROR}
        additionalInfo={(
          <p className='additional-info'>
            Supported formats: doc, pdf, png, jpg or xls.
          </p>
        )}
      >
        <div className='deal-doc-table-wrapper'>
          <div className='doc-table-header'>
            <div>Name</div>
            <div>Date</div>
            <div>Added by</div>
          </div>

          {documents.length > 0
            ? (
              <>
                {documents.map((document) => {
                  const customerName = (personalDetails?.firstName != null) && (personalDetails.lastName != null)
                    ? `${personalDetails.firstName} ${personalDetails.lastName}`
                    : null

                  return (
                    <DealDocumentRow
                      {...document}
                      key={document.id}
                      onClick={() => {
                        if (document.loading === true) {
                          return
                        }

                        void onClickFile(document.fileId)
                      }}
                      onRemove={() => setDeleteFileId(document.id)}
                      isLoading={document.loading === true}
                      customerName={customerName}
                    />
                  )
                })}
              </>
              )
            : (
              <div className='empty-doc'>
                No documents yet. Drag or upload.
              </div>
              )
          }
        </div>
      </FormFilesUpload>

      <DeleteModal
        content='Please note this step cannot be undone.'
        isOpen={deleteFileId != null}
        onClose={() => setDeleteFileId(null)}
        onConfirm={onSubmitRemoveFile}
        title='Delete this file?'
        cancelTitle='CANCEL'
      />
    </div>
  )
}

export default DealDocuments
