import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import type { KeyVal } from 'types'

/**
 * Same as `lodash/partial`, but for the React's functional components.
 */
function ApplyPropsHOK<TApplied extends KeyVal, TRest extends KeyVal> (
  config: TApplied,
  Comp: FC<TApplied & TRest>
): FC<TRest> {
  return observer((props: TRest): JSX.Element => <Comp {...config} {...props} />)
}

export default ApplyPropsHOK
