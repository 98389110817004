import { LeadSource, LeadStatus, CampaignSteps } from 'types/enums'
import {
  DealState as ApiDealState,
  DealNextAction,
  PaymentTypeId,
  VehicleState,
  WorkflowStepId,
  WorkflowStep
} from 'api/types'
import { DealWorkflowAccountingStateId } from 'types/number_enums'

export enum DealState {
  All = 'All',
  Completed = 'Completed',
  Incomplete = 'Incomplete',
  Pending = 'Pending', // AZ-TODO: it's Incomplete now, should be removed
  Canceled = 'Canceled',
  Deleted = 'Deleted'
}

export const DealStateMap: Record<number, string> = {
  [ApiDealState.Incomplete]: DealState.Incomplete,
  [ApiDealState.Completed]: 'Sold',
  [ApiDealState.Canceled]: DealState.Canceled,
  [ApiDealState.Deleted]: DealState.Deleted
}

export enum ApprovalStatus {
  Approved = 'Approved',
  Declined = 'Declined',
  Counteroffer = 'Counteroffer'
}

export enum DealStateName {
  Incomplete = 'Incomplete',
  Sold = 'Sold',
  OnDeposit = 'On deposit',
  Canceled = 'Canceled'
}

export enum DeliveryType {
  Delivery = 'Delivery',
  PickUp = 'Pick Up',
  None = 'None',
}

// AZ-TODO: rename
export enum PaymentTypeAPI {
  Cash = 'Cash',
  Finance = 'Finance'
}

export enum PaymentTypeView {
  Cash = 'Cash',
  Financing = 'Financing'
}

export enum PaymentName {
  CreditCard = 'Credit card',
  BankTransfer = 'Bank transfer',
  Cash = 'Cash',
  Cryptocurrency = 'Cryptocurrency',
  Deposit = 'Deposit'
}

export enum PaymentStatus {
  Paid = 'Paid',
  Processing = 'Processing',
  Pending = 'Pending'
}

export enum NavigationStep {
  YourDetails = 'Your Details',
  TradeIn = 'Trade In',
  CashFinancing = 'Cash/Financing',
  Coverage = 'Coverage',
  CreditApplication = 'Credit Application',
  Review = 'Review',
  DocumentsUpload = 'Documents: Upload',
  DocumentsContractSigning = 'Documents: Signing',
  PickupDelivery = 'Pickup/Delivery',
  Payment = 'Payment'
}

export enum Notifications {
  NewDealCreated = 'NewDealCreated',
  DealUpdated = 'DealUpdated',
  DealDeleted = 'DealDeleted',
  LogCreated = 'LogCreated',
  LogUpdated = 'LogUpdated',
  MessageReceived = 'MessageReceived',
  LeadCreated = 'LeadCreated',
  LeadUpdated = 'LeadUpdated',
  LeadDeleted = 'LeadDeleted',
  TaskCreated = 'TaskCreated',
  TasksUpdated = 'TasksUpdated',
  TasksDeleted = 'TasksDeleted',
  CampaignCreated = 'CampaignCreated',
  CampaignUpdated = 'CampaignUpdated',
  CampaignDeleted = 'CampaignDeleted',
  VehicleCreated = 'VehicleCreated',
  VehicleUpdated = 'VehicleUpdated',
  VehicleDeleted = 'VehicleDeleted',
}

export enum NodeEnvironment {
  Development = 'development',
  Production = 'production',
  Test = 'test'
}

/**
 * States that allow financing for Civil Union.
 * See https://dev.azure.com/carfluent/CarFluent/_workitems/edit/1743
 */
export const PROGRESSIVE_STATES = ['CT', 'VT']
export const CIVIL_UNION = 'Civil Union'

export enum VehicleStateNames {
  All = 'All',
  Active = 'Active',
  Inactive = 'Inactive',
  Sold = 'Sold',
  Deleted = 'Deleted'
}

export enum VehiclePaymentStateNames {
  Deposited = 'Deposited'
}

export const VehicleStateMap: Record<number, string> = {
  [VehicleState.Active]: VehicleStateNames.Active,
  [VehicleState.Inactive]: VehicleStateNames.Inactive,
  [VehicleState.Sold]: VehicleStateNames.Sold,
  [VehicleState.Deleted]: VehicleStateNames.Deleted
}

export enum DealWorkflowAccountingStateNames {
  Sold = 'Sold',
  PushedToAccounting = 'Pushed to accounting',
  Recorded = 'Recorded'
}

export enum DealsPeopleName {
  CoBuyer = 'Co-buyer',
  CustomerDetails = 'Customer',
}

export enum DealDetailsTabs {
  Deal = 'Deal',
  Buyer = 'Buyer',
  Vehicles = 'Vehicles',
  Forms = 'Forms',
  Documents = 'Documents',
  Finances = 'Finances'
}

// TODO-YAVO: need to update this enum to match with the API Reference/lead-sources from crm
export const LEAD_SOURCE_LABEL_TEXTS: Record<LeadSource, string> = {
  [LeadSource.WalkIn]: 'Walk In',
  [LeadSource.Web]: 'Web',
  [LeadSource.GeneralReferral]: 'General referral',
  [LeadSource.ServiceReferral]: 'Service referral',
  [LeadSource.DealerMgmtSys]: 'Dealer Mgmt Sys',
  [LeadSource.ListImport]: 'List import',
  [LeadSource.Manufacturer]: 'Manufacturer',
  [LeadSource.Phone]: 'Phone',
  [LeadSource.CoBuyer]: 'CoBuyer',
  [LeadSource.AutoTrader]: 'AutoTrader',
  [LeadSource.CarGurus]: 'CarGurus',
  [LeadSource.CarsCom]: 'Cars.com',
  [LeadSource.Ebay]: 'Ebay',
  [LeadSource.TrueCar]: 'TrueCar',
  [LeadSource.CarFax]: 'CarFax',
  [LeadSource.DealerRater]: 'DealerRater',
  [LeadSource.Edmunds]: 'Edmunds',
  [LeadSource.HomeNet]: 'HomeNet',
  [LeadSource.DealerCenter]: 'DealerCenter',
  [LeadSource.Ove]: 'Ove',
  [LeadSource.WebsiteFinancing]: 'Website Financing',
  [LeadSource.WebsiteTradeIn]: 'Website Trade In',
  [LeadSource.WebsiteAsk]: 'Website Ask',
  [LeadSource.WebsiteTestDrive]: 'Website Test Drive',
  [LeadSource.WebsiteDeal]: 'Website Deal',
  [LeadSource.OfferUp]: 'OfferUp',
  [LeadSource.Sasdesk]: 'Sasdesk',
  [LeadSource.CarCode]: 'CarCode',
  [LeadSource.CarZing]: 'CarZing',
  [LeadSource.CapitalOne]: 'CapitalOne',
  [LeadSource.Goxee]: 'Goxee',
  [LeadSource.CommercialTruckTrader]: 'CommercialTruckTrader',
  [LeadSource.Autolist]: 'Autolist',
  [LeadSource.WestLakeFinancial]: 'WestLakeFinancial',
  [LeadSource.AutoSweet]: 'AutoSweet',
  [LeadSource.Other]: 'Other',
  [LeadSource.WebChat]: 'Website Chat'
}

export const LEAD_STATUS_TEXT = {
  [LeadStatus.Unqualified]: 'Unqualified',
  [LeadStatus.Contacted]: 'Contacted',
  [LeadStatus.DealCreated]: 'Deal created',
  [LeadStatus.Sold]: 'Sold'
}

export const PaymentTypeMap: Record<number, string> = {
  [PaymentTypeId.Cash]: PaymentTypeAPI.Cash,
  [PaymentTypeId.Finance]: PaymentTypeAPI.Finance
}

export const DealNextActionMap: Record<number, string | null> = {
  [DealNextAction.SubmitCreditApplication]: null, // AZ-NOTE: it seems, this value is not used on FE.
  [DealNextAction.ReviewCreditApplication]: 'Submit',
  [DealNextAction.SelectForms]: 'Review',
  [DealNextAction.SignPaperwork]: 'Sign'
}

export const WorkflowStepToNavigationStepMap: Partial<Record<string, string>> = {
  [WorkflowStep.Initial]: NavigationStep.YourDetails,
  [WorkflowStep.PersonalDetails]: NavigationStep.YourDetails,
  [WorkflowStep.TradeIn]: NavigationStep.TradeIn,
  [WorkflowStep.Financing]: NavigationStep.CashFinancing,
  [WorkflowStep.ServiceProtection]: NavigationStep.Coverage,
  [WorkflowStep.CreditApplication]: NavigationStep.CreditApplication,
  [WorkflowStep.DealerCheck]: 'Waiting Review',
  [WorkflowStep.DealerCheckCompleted]: NavigationStep.Review,
  [WorkflowStep.Documents]: NavigationStep.DocumentsUpload,
  [WorkflowStep.SignOnline]: NavigationStep.DocumentsContractSigning,
  [WorkflowStep.PickupAndDelivery]: NavigationStep.PickupDelivery
}

export const WorkflowStepIdToNavigationStepMap: Record<number, string> = {
  [WorkflowStepId.Initial]: NavigationStep.YourDetails,
  [WorkflowStepId.PersonalDetails]: NavigationStep.YourDetails,
  [WorkflowStepId.TradeIn]: NavigationStep.TradeIn,
  [WorkflowStepId.Financing]: NavigationStep.CashFinancing,
  [WorkflowStepId.ServiceProtection]: NavigationStep.Coverage,
  [WorkflowStepId.CreditApplication]: NavigationStep.CreditApplication,
  [WorkflowStepId.DealerCheck]: 'Waiting Review',
  [WorkflowStepId.DealerCheckCompleted]: NavigationStep.Review,
  [WorkflowStepId.Documents]: NavigationStep.DocumentsUpload,
  [WorkflowStepId.SignOnline]: NavigationStep.DocumentsContractSigning,
  [WorkflowStepId.PickupAndDelivery]: NavigationStep.PickupDelivery

  // AZ-TODO: these steps were not mapped in NavigationStep, check if they are needed
  // [WorkflowStepId.Payments]: WorkflowStep.Payments,
  // [WorkflowStepId.ReviewOrder]: WorkflowStep.ReviewOrder,
  // [WorkflowStepId.PaymentSummary]: WorkflowStep.PaymentSummary
}

export const DealAccountingStateMap: Record<number, string> = {
  [DealWorkflowAccountingStateId.Sold]: DealWorkflowAccountingStateNames.Sold,
  [DealWorkflowAccountingStateId.PushedToAccounting]: DealWorkflowAccountingStateNames.PushedToAccounting,
  [DealWorkflowAccountingStateId.Recorded]: DealWorkflowAccountingStateNames.Recorded
}

export const CampaignStepsName: Record<number, string> = {
  [CampaignSteps.CampaignInformation]: 'Campaign information',
  [CampaignSteps.Recipients]: 'Recipients',
  [CampaignSteps.ReviewSend]: 'Review & Send'
}
