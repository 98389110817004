import { css } from '@emotion/css'

export default css(`
    display: flex;
    align-items: center;
    
    &.disabled {
      opacity: 0.5;
    }

    label {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 24px;
      margin: 0 8px;
    }

    &.toggle-inversed {
      input:checked + span:before {
        transform: translateX(15px);
      }

      span:before {
        transform: translateX(0px);
      }
    }
    
    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + p {
        color: #101010;
      }

      &:checked + span:before {
        transform: translateX(0);
      }
    }

    span {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #101010;
      transition: 0.3s;
      border-radius: 30px;

      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
        background-color: #fff;
        border-radius: 50%;
        transition: 0.3s;
        transform: translateX(15px);
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      letter-spacing: 0.5px;
      
      &.inactive {
        color: #a6a6a6;
      }

      &.active {
        color: #101010;
      }
  }
`)
