import type { DocumentFormPackDto, DocumentFormPackRequestDto } from 'api/types'

const serializeData = (data: DocumentFormPackDto): DocumentFormPackRequestDto => {
  return {
    ...data,
    forms: (data.forms ?? []).map(el => el.id)
  }
}

export default serializeData
