import {
  ReactElement,
  type ChangeEvent,
  type FC
} from 'react'
import { formatCurrencyDecimal, cnx } from '@carfluent/common'

import { KeyVal } from 'types'
import {
  BankStatementsCellValue,
  ParseBankStatementsTransaction
} from 'api/types'

import Checkbox from 'components/common/Checkbox'
import IconSVG from 'components/inlineImages'
import TooltipedText from 'components/common/TooltipedText'
import Tooltip from 'components/common/Tooltip'

import { formatDate } from 'utils/parse_date'
import { DATE_US_FORMAT } from 'constants/constants'
import { TOOLTIP_CONTENT_CLASSES } from './styles'

interface LineItemProps extends ParseBankStatementsTransaction {
  checked: boolean
  hasError: boolean
  isDuplicate: boolean
  isPending: boolean
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void
  style?: KeyVal
}

interface ColumnValueProps {
  value?: BankStatementsCellValue | null
  isCurrency?: boolean
}

interface ColumnDateProps {
  value?: BankStatementsCellValue<Date | null> | null
}

const ColumnValue = ({ value = null, isCurrency = false }: ColumnValueProps): ReactElement => {
  if (value === null) {
    return <></>
  }

  if (value != null && !value?.hasError) {
    if (isCurrency) {
      return <p>{formatCurrencyDecimal(value?.rawValue)}</p> ?? <></>
    } else {
      return (
        <TooltipedText
          rootClassName='text-container'
          value={value?.rawValue as string ?? ''}
        />
      )
    }
  } else {
    return <Tooltip title={value?.error?.message ?? ''}><IconSVG.ExclamationMark /></Tooltip>
  }
}

const ColumnDate = ({ value }: ColumnDateProps): ReactElement => {
  if (value === null) {
    return <></>
  }

  if (value != null && !value?.hasError) {
    return <p>{formatDate(value?.rawValue as Date, DATE_US_FORMAT)}</p>
  } else {
    return <Tooltip title={value?.error?.message ?? ''}><IconSVG.ExclamationMark /></Tooltip>
  }
}

const LineItem: FC<LineItemProps> = ({
  date,
  description,
  deposit,
  hasError,
  checked,
  withdrawal,
  isDuplicate,
  isPending,
  style,
  onChange
}) => {
  return (
    <div
      className={cnx('line row', (!isDuplicate && hasError) && 'error', isDuplicate && 'duplicated')}
      style={style}
    >
      <div className='column'>
        <Checkbox
          id='checked'
          checked={checked}
          onChange={onChange}
          className='upload-line-checkbox'
          disabled={hasError}
        />
      </div>

      <div className='column'>
        <ColumnDate value={date} />
      </div>

      <div className='column'>
        {isDuplicate && (
          <Tooltip title='Duplicate transaction'>
            <IconSVG.ExclamationMark width={24} height={24} />
          </Tooltip>
        )}
        {/* need only for show error in same state if pending line */}
        {
          description?.hasError && (
            <Tooltip title={description?.error?.message ?? ''}><IconSVG.ExclamationMark /></Tooltip>
          )
        }
        {isPending && (
          <Tooltip
            title='A statement includes pending transactions.'
            className={TOOLTIP_CONTENT_CLASSES}
          >
            <div className='pending-flag'>Pending</div>
          </Tooltip>
        )}
        {
          !description?.hasError && (
            <ColumnValue value={description} />
          )
        }
      </div>

      <div className='column'>
        <ColumnValue value={deposit} isCurrency />
      </div>

      <div className='column'>
        <ColumnValue value={withdrawal} isCurrency />
      </div>
    </div>
  )
}

export default LineItem
