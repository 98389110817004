import { css } from '@emotion/css'

export default css(`
  position: relative;

  .cf-button-root {
    .cf-button-end-adornment {
      height: auto;
      display: flex;
      margin-right: 4px;
      min-width: 24px;
    }
  }
  
  .cf-step-popup {
    width: 368px;
    position: absolute;
    bottom: -4px;
    left: 0;
    transform: translate(0, 100%);
    padding: 16px 0;
    border-radius: 8px;
    background: #ffffff;
    z-index: 1;
    
    .cf-steps-wrapper {
      max-height: 320px;
      overflow-y: auto;
    }
    
    .cf-search-wrapper {
      padding: 0 16px;
      margin-bottom: 8px;
    }
    
    .cf-step-item {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      cursor: pointer;
      
      &.cf-active {
        background: #FAFAFA;
      }
      
      :hover {
        background: #F3F3F3;
        
        &.cf-active {
          background: #F3F3F3;
        }
      }
    }
    
    .cf-no-steps {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 16px;
    }
  }
`)
