import { useMemo } from 'react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import { createStyleClass } from './styles'

export interface LinkItem {
  title: string
  description: string
  to: string
}

const ReportLink: FC<LinkItem> = ({ title, description, to }) => {
  const rootCls = useMemo(createStyleClass, [])

  return (
    <Link className={rootCls} to={to}>
      <p className='report-link-title'>
        {title}
      </p>

      <p className='report-link-description'>
        {description}
      </p>
    </Link>
  )
}

export default ReportLink
