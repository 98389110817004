import { css } from '@emotion/css'

export default css`
  display: grid;
  grid-template-columns: 1fr 190px 336px;
  height: 56px;
  background: #ffffff;
  padding-left: 16px;
  margin-top: 8px;

  :hover {
    border-left: 4px solid #000;
    background: #FAFAFA;
    padding-left: 12px;
    border-radius: 2px;
  }
  
  > div {
    display: flex;
    align-items: center;
    color: #101010;
    
    &.name-column {
      overflow: hidden;

      &.is-loading {
        cursor: not-allowed;
      }

      .file-name {
        width: calc(100% - 32px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #101010;
      }

      cursor: pointer;

      .cf-loader {
        position: relative;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        
        svg {
          margin: 0
        }
      }

      svg {
        margin-right: 8px;
        margin-top: 4px;
      }
    }

    &.user-column {
      position: relative;
      padding-right: 54px;
      
      .driver-licence-wrapper {
        display: flex;
        align-items: center;
        overflow: hidden;
        
        .cf-tooltip-container {
          max-width: calc(100% - 32px);
        }
        
        svg {
          margin-right: 8px;
        }
      }

      &.disabled {
        .close-icon {
          cursor: not-allowed;

          path {
            fill: rgba(16, 16, 16, .4);
          }
        }
      }

      .close-icon {
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 16px;
      }
      
      .name-with-avatar {
        overflow: hidden;
        
        .cf-tooltip-container {
          overflow: hidden;
        }
      }
    }
  }
`

export const TOOLTIP_CLASS_NAME = css`
  padding: 4px 0;
  max-width: 400px;
  
  ul {
    padding-left: 10px;
  }
`
