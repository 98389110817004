import { type FC } from 'react'

import { COVERAGE_NAME_BY_ID } from 'constants/coverage'
import CoverageTypeSelector from 'components/deals/CoverageTypeSelector'
import CoverageManualForm from 'components/deals/CoverageManualForm'
import CoveragePenForm from 'components/deals/CoveragePenForm'
import isFieldSupported from 'utils/coverage/isFieldSupported'

import { type WarrantyItemProps } from '../../types'

const WarrantyItem: FC<WarrantyItemProps> = ({
  className,
  errors,
  fieldPathPrefix,
  isFormDisabled,
  onBlur,
  onChange,
  product,
  touched,
  values
}) => {
  const { coverageType } = values

  return (
    <div className={className}>
      <h1 className='g-text-black coverage-section-title'>{COVERAGE_NAME_BY_ID[values.productTypeId]}</h1>

      <CoverageTypeSelector
        className='coverage-type-selector'
        disabled={isFormDisabled}
        onChange={(value) => {
          onChange(`${fieldPathPrefix}.coverageType`, value)
        }}
        value={values}
      />

      {(coverageType === 'PEN') && (
        <CoveragePenForm
          errors={errors?.forms.PEN}
          disabled={isFormDisabled}
          fieldPathPrefix={`${fieldPathPrefix}.forms.PEN`}
          onBlur={onBlur}
          onChange={onChange}
          product={product}
          touched={touched?.forms.PEN}
          values={values?.forms.PEN}
        />
      )}

      {(coverageType === 'Manual') && (
        <CoverageManualForm
          errors={errors?.forms.Manual}
          disabled={isFormDisabled}
          product={product}
          fieldPathPrefix={`${fieldPathPrefix}.forms.Manual`}
          isCoveragesVisible={isFieldSupported(values.productTypeId, values.coverageType, 'termMonths')}
          isDeductibleVisible={isFieldSupported(values.productTypeId, values.coverageType, 'deductibleAmount')}
          onBlur={onBlur}
          onChange={onChange}
          touched={touched?.forms.Manual}
          values={values?.forms?.Manual}
        />
      )}
    </div>
  )
}

export default WarrantyItem
