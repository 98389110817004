import { css } from '@emotion/css'

export default css(`
  padding: 8px;
  margin: 4px 0;
  padding-top: 0;
  background: #fff;
  padding-left: 4px;
  border-radius: 2px;
  border-left: 4px solid transparent;

  h3 {
    margin-left: 5px;
  }

  &.with-error h3 {
    color: #B00020;
  }

  :hover {
    background: #FAFAFA;
    border-color: #000000;
  }

  .inspection-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.4px;
  }

  > div {
    display: flex;
    margin-bottom: 4px;
    align-items: center;
    justify-content: space-between;
  }

  .comment-section {
    padding: 12px;
    color: #101010;
    background: #fff;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    border: 1px solid #0000001F;

    ::placeholder {
      color: #21212180;
    }

    :focus,
    :hover {
      border-color: #C99B86;
    }
  }

  .inspection-button-container {
    display: flex;
    margin-right: -8px;
  }

  .inspection-button {
    width: 100px;
    margin: 8px 0;
    color: #101010;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    display: inline-flex;

    > span {
      cursor: pointer;
      align-items: center;
      display: inline-flex;

      :hover .icon-container {
        background: #f3f3f3;
      }
    }

    .icon-container {
      width: 32px;
      height: 32px;
      display: flex;
      margin-right: 8px;
      align-items: center;
      border-radius: 16px;
      justify-content: center;
    }
  }

  .comment-text > div {
    width: 100%;
    display: flex;
    padding: 12px;
    margin-top: 0;
    font-size: 16px;
    color: #101010;
    text-align: left;
    line-height: 24px;
    letter-spacing: 0.5px;

    span {
      width: 100%;
      display: block;
      overflow: auto;
      max-height: 72px;
    }
    
    :empty {
      :after {
        content: 'No comment';
        color: #21212180;
      }
    }
  }
`)
