import { type FC } from 'react'

import PasswordValidationPanel from 'components/common/PasswordValidationPanel'
import useNewPassword from './hook'
import CLASS_NAME from '../../styles'
import { Button, FormInput } from '@carfluent/common'
import ErrorsBlock from 'components/common/ErrorsBlock'

const NewPassword: FC = () => {
  const {
    isSubmitting,
    values,
    errors,
    touched,
    onSubmit,
    onChange,
    onBlur,
    apiErrors,
    showPassword,
    toggleShowHidePassword
  } = useNewPassword()

  return (
    <div className={CLASS_NAME}>
      <p className='title-wrapper'>
        Password reset
      </p>

      {(apiErrors != null) && (
        <ErrorsBlock errors={apiErrors} className='password-reset-api-errors' />
      )}

      <div className='password-wrapper'>
        <FormInput
          id='password'
          label='Password'
          className='password-reset'
          type={showPassword ? 'text' : 'password'}
          onChange={onChange}
          onBlur={onBlur}
          value={values.password}
          touched={touched.password}
          error={errors.password}
          endAdornment={(
            <span
              className='show-hide'
              onClick={toggleShowHidePassword}
            >
              {showPassword ? 'Hide' : 'Show'}
            </span>
          )}
        />

        <PasswordValidationPanel
          value={values.password}
          isTouched={Boolean(touched.password)}
        />
      </div>

      <Button
        className='submit-btn'
        dataTestId='btn-save-and-login'
        isLoading={isSubmitting}
        onClick={() => { void onSubmit() }}
      >
        Save and log in
      </Button>
    </div>
  )
}

export default NewPassword
