import { css } from '@emotion/css'
import { px, PAGE_TITLE_HEIGHT } from 'constants/constants'

export default css(`
  && {
    position: sticky;
  }
  
  top: ${px(PAGE_TITLE_HEIGHT)};
  width: 212px;
  z-index: 1101;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  background: #FFF;
  
  .page-submenu-list-item {
    &:hover {
      text-decoration: none;
      
      &:not(.disable) .g-subtitle-1 {
        font-weight: 500;
      }
    }
    
    &.disable {
      cursor: not-allowed;
      pointer-events: none;
      
      .cf-list-item {
        color: rgba(33, 33, 33, 0.40);
      }
    }
    
    &.active .cf-list-item {
      background: #F3F3F3;
      
      .g-subtitle-1 {
        font-weight: 500;
      }
    }
    
    .cf-list-item {
      width: 211px;
      padding: 10px 24px;
      font-family: Roboto;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #101010;

      text-container > span {
        font-size: 16px;
      }

      &:hover .MuiTypography-root {
        font-weight: 600;
      }

      .MuiListItemText-root {
        min-width: 152px;
      }
    }
  }
`)
