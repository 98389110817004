import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

import CLASS_NAME from './styles'

export interface MarketplacePromoSkeletonProps {
  className?: string
}

const ADS_WRAPPER_SECTIONS = Array(3).fill(null)

const MarketplacePromoSkeleton: FC<MarketplacePromoSkeletonProps> = ({
  className
}) => {
  return (
    <div className={cnx(CLASS_NAME, className)}>
      <div className='home-page-hero-section-skeleton'>
        <div className='title-section-skeleton'>
          <TextSkeleton />
          <TextSkeleton />
        </div>

        <TextSkeleton className='home-page-hero-section-skeleton' />

        <div className='home-page-heading-section-skeleton'>
          <TextSkeleton />
          <TextSkeleton />
        </div>

      </div>
      <div className='home-page-ads-section-skeleton'>
        <div className='title-section-skeleton'>
          <TextSkeleton />
          <TextSkeleton />
        </div>

        <div className='home-page-ads-wrapper-section-skeleton'>
          {
              ADS_WRAPPER_SECTIONS.map((el, idx) => (
                <div className='home-page-ads-container-section-skeleton' key={idx}>
                  <TextSkeleton />
                  <TextSkeleton />
                  <TextSkeleton />
                </div>
              ))
            }
        </div>
      </div>

      <div className='home-page-monthly-payment-section-skeleton'>
        <TextSkeleton />

        <div>
          <TextSkeleton />
          <TextSkeleton />
          <TextSkeleton />
          <TextSkeleton />
        </div>
      </div>

      <div className='home-page-gallery-section-skeleton'>
        <TextSkeleton />
        <TextSkeleton />
        <TextSkeleton />
      </div>
    </div>
  )
}

export default MarketplacePromoSkeleton
