import { FC } from 'react'
import { Sector } from 'recharts'

import { ShapeProps } from './types'

const ActiveShape: FC<ShapeProps> = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    midAngle,
    fill,
    isHover = false,
    onClick: _onClick,
    onMouseLeave: _onMouseLeave,
    onMouseEnter: _onMouseEnter,
    isActive = false
  } = props

  const RADIAN = Math.PI / 180

  const sin: number = Math.sin(-RADIAN * midAngle)
  const cos: number = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius - 90) * cos
  const sy = cy + (outerRadius - 90) * sin

  return (
    <g>
      <Sector
        cx={isHover ? cx : sx}
        cy={isHover ? cy : sy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        onClick={_onClick}
        onMouseLeave={_onMouseLeave}
        onMouseEnter={_onMouseEnter}
      />
      {isHover && isActive
        ? (
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius}
            outerRadius={outerRadius + 10}
            fill={fill}
            fillOpacity={0.24}
          />
        )
        : null}
    </g>
  )
}

export default ActiveShape
