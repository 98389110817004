import { css } from '@emotion/css'

export default css(`
  .cf-no-data-block {
    display: flex;
    justify-content: center;
    padding: 30px;
    background: #fff;
    border-top: 8px solid #F3F3F3;
  }

  .header-block {
    display: flex;
    width: 100%;
    margin-bottom: 4px;
    flex-direction: column;
  }

  .search-container {
    display: flex;
    background-color: #fff;
    padding: 16px;
    border-radius: 12px;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .search-and-filter-panel {
    display: flex;
    align-items: center;
  }

  .filters-block {
    margin-right: 24px;
  }

  .search-block {
    min-width: 420px;
    width: 100%;
  }

  .btn-new-deal {
    width: 150px;
    height: 56px;
  }

  .MuiPaper-root.cf-table-wrapper {
    border-radius: 2px 12px 2px 2px;
    
    .cf-table { table-layout: fixed; }

    .state-cell {
      .cf-cell-wrapper {
        text-align: left;
        align-items: baseline;
      }
    }

    .cf-table-header-row:first-child .cf-header-cell-title:first-child,
    .cf-table-header-row:first-child .cf-table-header-title > div:first-child
     { padding-left: 8px; }
    
    .cf-cell-wrapper { 
      height: 60px;
      padding: 4px 8px;
    }

    .cf-table-cell {
      :first-child .cf-cell-wrapper { padding-left: 4px; align-items: baseline; }
      :last-child .cf-cell-wrapper p { white-space: normal; width: 100px; }
    }
  }

  .filter-block {
    display: flex;
    align-items: center;
    margin-left: 24px;

    .title {
      color: rgba(33, 33, 33, 0.80);
    }

    .show-filter {
      margin-left: 8px;
      max-width: 330px;

      .cf-select-display-container  {
        height: 36px;
        background: #F3F3F3;
        border: none;
        border-radius: 8px;
        padding: 8px;
        padding-right: 4px;
        display: flex;
        align-items: center;
  
        .cf-display {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.25px;
          border: none;
          outline: none;
          resize: none;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
  
        .cf-end-adornment {
          display: flex;
          align-items: center;
  
          > div { height: 24px; }
  
          .cf-dropdown-trigger-button {
            margin-top: 4px;
  
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
`)

export const POPOVER_CLASS_NAME = css(`
  &.cf-popover-content {
    min-width: 248px;
    z-index: 1000;
  }

  .cf-dropdown-listbox { border-radius: 8px; }

  .cf-options-divider { margin: 8px 0; }

  .cf-dropdown-option { 
    color: #101010;

    .counter { 
      display: inline-block;
      margin-left: 8px;
      color: rgba(0, 0, 0, 0.30);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }
`)
