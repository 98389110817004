import { type FC } from 'react'
import { CellWrapper, cn } from '@carfluent/common'
import { type BasicValueCellProps } from 'types'
import TooltipedText from 'components/common/TooltipedText'

import appointmentLeadIcon from 'assets/lead-appointment.svg'
import followUpLeadIcon from 'assets/lead-follow-up.svg'
import reminderLeadIcon from 'assets/lead-reminder.svg'
import noActivityLeadIcon from 'assets/lead-no-activity.svg'
import webLeadIcon from 'assets/person_icon.svg'
import messageReceivedIcon from 'assets/message_icon.svg'
import emailReceivedIcon from 'assets/email_icon.svg'

import CLASS_NAME from './styles'

export type LeadActivityType = 'no-activity'
| 'reminder'
| 'appointment'
| 'follow-up'
| 'web-lead'
| 'message-received'
| 'email-received'

export interface LeadActivityData {
  type: LeadActivityType
  text: string
}

const iconMap = {
  'no-activity': noActivityLeadIcon,
  'follow-up': followUpLeadIcon,
  reminder: reminderLeadIcon,
  appointment: appointmentLeadIcon,
  'web-lead': webLeadIcon,
  'email-received': emailReceivedIcon,
  'message-received': messageReceivedIcon
}

const NextActivityCell: FC<BasicValueCellProps> = (props) => {
  const activityData = props.getValue() as LeadActivityData
  return (
    <CellWrapper className={cn(CLASS_NAME, `cf-${activityData.type}`)}>
      <img src={iconMap[activityData.type]} alt={activityData.type} />
      <TooltipedText
        value={activityData.text}
        rootClassName='cf-table-cell-text'
      />
    </CellWrapper>
  )
}

export default NextActivityCell
