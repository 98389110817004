import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import type { FCHook } from 'types'
import CustomersCoreApiProvider from 'api/customersCore.api'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'

export const SPACE_RE = /%20/g

export enum DocumentsIds {
  CarInsurance = 1,
  DriverLicence = 2,
  ProofOfResidence = 3,
  ProofOfIncome = 4,
  TradeInRegistration = 5,
  TradeInTitle = 6,
  TradeInCar = 7,
  PayoffInfo = 8,
  OtherDocuments = 9
}

export const REQUESTED_DOCUMENTS = [
  { id: DocumentsIds.CarInsurance, name: 'Car insurance' },
  { id: DocumentsIds.ProofOfIncome, name: 'Proof of income' },
  { id: DocumentsIds.ProofOfResidence, name: 'Proof of residence' },
  { id: DocumentsIds.TradeInTitle, name: 'Trade in title' },
  { id: DocumentsIds.TradeInRegistration, name: 'Trade in registration documents' },
  { id: DocumentsIds.PayoffInfo, name: 'Payoff info' },
  { id: DocumentsIds.TradeInCar, name: 'Trade in car photos' },
  { id: DocumentsIds.OtherDocuments, name: 'Other documents' },
  { id: DocumentsIds.DriverLicence, name: 'Driver licence (front and back)' }
] as const

export interface UseRequestDocumentsProps {
  onClose: () => void
}

export interface UseRequestDocumentsReturn {
  isDisabled: boolean
  isLoading: boolean
  onClose: () => void
  onOtherDocumentsChange: (value: string) => void
  onSubmit: () => Promise<void>
  onToggleCheckbox: (id: number) => void
  otherDocError: string
  otherDocTouched: boolean
  otherDocValue: string
  selectedDocuments: Record<number, boolean>
  apiError: string | null
}

const useRequestDocuments: FCHook<UseRequestDocumentsProps, UseRequestDocumentsReturn> = ({
  onClose: _onClose
}) => {
  const { id: dealId } = useParams<{ id: string }>()
  const { showAlert } = useCustomSnackbar()
  const [selectedDocuments, setSelectedDocument] = useState<Record<number, boolean>>({})
  const [otherDocValue, setOtherDocValue] = useState('')
  const [otherDocError, setOtherDocError] = useState('')
  const [otherDocTouched, setOtherDocTouched] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const isDisabled = !Object.values(selectedDocuments).includes(true)
  const [apiError, setApiError] = useState<string | null>(null)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onClose = useCallback(() => {
    setSelectedDocument({})
    _onClose()
    setApiError(null)
  }, [_onClose])

  const onToggleCheckbox = useCallback((id: number): void => {
    if (id === DocumentsIds.OtherDocuments && selectedDocuments[id]) {
      setOtherDocValue('')
      setOtherDocTouched(false)
      setOtherDocError('')
    }
    setSelectedDocument((selectedDocuments) => ({ ...selectedDocuments, [id]: !selectedDocuments[id] }))
  }, [selectedDocuments])

  const onOtherDocumentsChange = useCallback((value: string) => {
    setOtherDocValue(value)
    setOtherDocTouched(true)
    setOtherDocError('')
  }, [])

  const onSubmit = async (): Promise<void> => {
    const otherDocValueTrim = otherDocValue.trim()
    const otherItem = selectedDocuments[DocumentsIds.OtherDocuments]

    if (otherItem && otherDocValueTrim === '') {
      setOtherDocError('Field is required')
      return
    }

    const otherDocumentsList = (otherItem != null) ? otherDocValueTrim.split(',') : []
    const codedDocumentsList = otherDocumentsList
      .filter(Boolean)
      .map((doc) => encodeURIComponent(doc.trim()))
      .map((doc) => doc.replace(SPACE_RE, ' '))

    const selectedIds = Object.entries(selectedDocuments).filter(([, value]) => value).map(([key]) => Number(key))
    const selectedDocItems = REQUESTED_DOCUMENTS.filter(({ id }) => selectedIds.includes(id))
    const selectedDocNames = selectedDocItems
      .filter(({ id }) => id !== DocumentsIds.OtherDocuments)
      .map(({ name }) => name)

    const documentTypes = [...selectedDocNames, ...codedDocumentsList]

    if (documentTypes.length < 1) {
      showAlert('Please select at least one document type')
      return
    }

    const payload = {
      dealId: dealId ?? '',
      data: { documentTypes }
    }

    try {
      setIsLoading(true)
      await CustomersCoreApiProvider.postRequestDocumentsDeal(payload)
      showAlert('Request was sent.', { variant: 'success' })
      onClose()
    } catch (e: any) {
      setApiError(e?.response?.data?.message)
    } finally {
      setIsLoading(false)
    }
  }

  // ========================================== //

  return {
    isDisabled,
    isLoading,
    onClose,
    onOtherDocumentsChange,
    onSubmit,
    onToggleCheckbox,
    selectedDocuments,
    otherDocError,
    otherDocTouched,
    otherDocValue,
    apiError
  }
}

export default useRequestDocuments
