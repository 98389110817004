import { type FC, type ReactNode } from 'react'
import { FormDropdown } from '@carfluent/common'
import { type Item } from '@carfluent/common/dist/UI/Dropdown/types'

import { type MessageSnippetDto } from 'api/types/responses'

import type { SnippetsDropdownProps } from './hook/types'
import useSnippetsDropdown from './hook'

import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

const renderOption = (props: MessageSnippetDto): ReactNode => {
  return (
    <div className={props.id >= 0 ? 'dropdown-option' : 'group-name-option'}>
      {props.name}
    </div>
  )
}

const isOptionDisabled = ({ value }: Item<MessageSnippetDto>): boolean => value.id < 0

const SnippetsDropdown: FC<SnippetsDropdownProps> = ({
  isOpen,
  label,
  options,
  ...rest
}) => {
  const { onChange } = useSnippetsDropdown(rest)

  return (
    <FormDropdown
      popoverClassName={POPOVER_CLASS_NAME}
      className={CLASS_NAME}
      id={`${label} Dropdown`}
      isOpen={isOpen}
      disableClearable
      mode='select'
      onChange={onChange}
      renderOption={renderOption}
      isOptionDisabled={isOptionDisabled}
      options={options}
      formatDisplayValue={() => label}
      value={null}
    />
  )
}

export default SnippetsDropdown
