import { useState } from 'react'
import type { FC } from 'react'
import { UI, useLoader } from '@carfluent/common'

import type { EntityId } from 'types'
import type { TransactionDetails as ApiTransaction } from 'api/types'
import useAsyncEffect from 'hooks/useAsyncEffect'
import AccountingApiProvider from 'api/accounting.api'
import TransactionDetails from 'pages/accounting/TransactionDetails' // AZ-TODO: consider to move this to `components/deals`
import useCustomSnackbar from 'hooks/useCustomSnackbar'

export interface GeneratedTransactionProps {
  dealId: EntityId
  inventoryId?: EntityId | null
  isModalOpen: boolean
  onCloseModal: () => void
  onSubmit?: () => void
}

const GeneratedTransaction: FC<GeneratedTransactionProps> = ({
  dealId,
  isModalOpen,
  inventoryId,
  onCloseModal,
  onSubmit
}) => {
  const { isLoading, startLoader, stopLoader } = useLoader()
  const [transaction, setTransaction] = useState<ApiTransaction | null>(null)
  const { showAlert } = useCustomSnackbar()

  useAsyncEffect(async () => {
    if (!isModalOpen) {
      return
    }

    try {
      startLoader()
      const transaction = await AccountingApiProvider.getTransactionByDeal(dealId)
      setTransaction(transaction)
    } catch (err) {
      showAlert(err)
    } finally {
      stopLoader()
    }
  }, [
    isModalOpen,
    startLoader,
    stopLoader,
    showAlert
  ])

  // ========================================== //
  //                    RENDER                  //
  // ========================================== //

  return (
    <>
      {isLoading && <UI.Loader size='large' />}

      {!isLoading && (
        <TransactionDetails
          dealId={dealId}
          inventoryId={inventoryId}
          preloadedTransactionData={transaction}
          isModalOpen={isModalOpen}
          onCloseModal={onCloseModal}
          onSubmit={onSubmit}
          transactionCreationMode='generated-from-deal'
        />
      )}
    </>
  )
}

export default GeneratedTransaction
