import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import type { IconProps } from 'types'

const IconSVGInbox: FC<IconProps> = ({
  width = 32,
  height = 32,
  className
}) => {
  return (
    <svg
      className={cnx(className, 'cf-icon')}
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width={width} height={height} rx='8' fill='#FAFAFA' />
      <g clipPath='url(#clip0_81842_236156)'>
        <path d='M24.3337 13.4846V21.0013C24.3337 21.918 23.5837 22.668 22.667 22.668H9.33366C8.41699 22.668 7.66699 21.918 7.66699 21.0013V11.0013C7.66699 10.0846 8.41699 9.33464 9.33366 9.33464H17.7503C17.7003 9.6013 17.667 9.88464 17.667 10.168C17.667 11.4013 18.2087 12.493 19.0587 13.2596L16.0003 15.168L9.33366 11.0013V12.668L16.0003 16.8346L20.417 14.068C20.867 14.2346 21.3337 14.3346 21.8337 14.3346C22.7753 14.3346 23.6337 14.0096 24.3337 13.4846ZM19.3337 10.168C19.3337 11.5513 20.4503 12.668 21.8337 12.668C23.217 12.668 24.3337 11.5513 24.3337 10.168C24.3337 8.78464 23.217 7.66797 21.8337 7.66797C20.4503 7.66797 19.3337 8.78464 19.3337 10.168Z' fill='#101010' />
      </g>
      <defs>
        <clipPath id='clip0_81842_236156'>
          <rect width='20' height='20' fill='#FAFAFA' transform='translate(6 6)' />
        </clipPath>
      </defs>
    </svg>

  )
}

export default IconSVGInbox
