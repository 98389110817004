import { css } from '@emotion/css'

export default css(`
  display: flex;
  align-items: center;
  
  div, span {
    color: #101010;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  
  svg { margin-right: 4px; }
  span {
    display: inline-block;
    width: 18px;
    text-align: center;
  }
`)
