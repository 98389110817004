import { chain, isNaN } from 'mathjs'
import { type DashboardDealsDto, type DashboardDealsModel } from 'api/types/responses'

const parseDashboardDealsDto = (data: DashboardDealsDto): DashboardDealsModel => {
  const progress = chain(data.carsSold)
    .divide(data.salesTarget)
    .multiply(100)
    .floor()
    .done()

  return {
    ...data,
    carsSoldProgress: isNaN(progress) ? 0 : Math.min(progress, 100) // can't be more than 100%
  }
}

export default parseDashboardDealsDto
