import { FC } from 'react'
import { PRESET_LABELS } from '@carfluent/common'

import Table from 'components/common/Table'
import BackToTop from 'components/common/BackToTop'
import ReportLayout from 'components/reports/ReportLayout'
import FiltersPanel, { type FilterItem } from 'components/common/FiltersPanel'

import { GET_DEFAULT_ACCOUNTING_STATES } from 'api/mappers/responses'

import { asyncNoop } from 'constants/constants'

import StatusTypeFilter from './components/StatusTypeFilter'
import Summary from './components/Summary'
import Breakdown from './components/Breakdown'

import useReportDealSaleReport from './hook'
import columnDefinitions from './hook/columns'
import { LOADING_ROWS } from './hook/constants'

import CLASS_NAME, { ROOT_CLASS_NAME } from './styles'

const states = GET_DEFAULT_ACCOUNTING_STATES()

const ReportDealSaleReport: FC = () => {
  const {
    containerElement,
    rows,
    search,
    sorting,
    presets,
    emptyTableMessage,
    isLoading,
    isShowSkeleton,
    summary,
    breakdown,
    onSearch,
    onExportExcel,
    onSearchChange,
    onSortingChange,
    onDateFilterChange,
    onAccountingStateFilterChange
  } = useReportDealSaleReport()

  const searchProps = {
    value: search ?? '',
    onSearch,
    disabled: isLoading,
    isLoading,
    onChange: onSearchChange,
    placeholder: 'Deal ID, Car, Customer'
  }

  const dateFilters: FilterItem<'date'> = {
    filterType: 'date',
    componentProps: {
      presets,
      onFilterChange: onDateFilterChange,
      defaultPresetName: PRESET_LABELS.THIS_MONTH,
      monthsNumber: 2
    }
  }

  const stateFilter: FilterItem<'custom'> = {
    filterType: 'custom',
    componentProps: {
      render: () => <StatusTypeFilter options={states} onChange={onAccountingStateFilterChange} />
    }
  }

  return (
    <ReportLayout
      size='md'
      className=''
      title='Sale report'
      withChildOutsideContainer
      isDateFilterEnabled={false}
      onExportExcel={onExportExcel}
      rootClassName={ROOT_CLASS_NAME}
      onChangeDatesFilter={asyncNoop}
    >
      <div className={CLASS_NAME}>
        <FiltersPanel
          componentBackground='gray'
          className='sale-report-filters'
          searchProps={searchProps}
          filterProps={{ filters: [dateFilters, stateFilter] }}
        />

        <Summary summary={summary} isLoading={isShowSkeleton} />
        <Breakdown breakdown={breakdown} isLoading={isShowSkeleton} />

        <Table
          containerElement={containerElement}
          data={rows}
          sorting={sorting}
          isLoading={isLoading}
          loadingRows={LOADING_ROWS}
          columns={columnDefinitions}
          onSortingChange={onSortingChange}
          emptyTableMessage={emptyTableMessage}
        />
        <BackToTop />
      </div>
    </ReportLayout>
  )
}

export default ReportDealSaleReport
