import type { FC } from 'react'

import alertIcon from 'assets/alert_red.svg'
import alertWhiteIcon from 'assets/alert_white.svg'

import CLASS_NAME from './styles'

interface CostsAlertProps {
  isSelected: boolean
}

const CostsAlert: FC<CostsAlertProps> = ({ isSelected }) => {
  return (
    <span className={CLASS_NAME}>
      <img src={isSelected ? alertWhiteIcon : alertIcon} alt=""/>

      <p className='cf-tooltip'>
        Lack of costs. To add them, go to the Costs tab
      </p>
    </span>
  )
}

export default CostsAlert
