import { css } from '@emotion/css'
import {
  px,
  SIDEBAR_WIDTH
} from 'constants/constants'

export const CLASS_NAME = css(`
  &.campaign-layout {
    &.campaign-layout--create,
    &.campaign-layout--edit {
      width: 900px;
      min-width: 900px;
      margin-right: auto; 
      margin-left: auto; 
      margin-bottom: 24px; 
      
      .footer {
        width: calc(100vw - ${px(SIDEBAR_WIDTH)});
      }
    }
    
    &.campaign-layout--view {
      box-sizing: border-box;
    }
    
    &.campaign-layout--edit {
      margin-top: 8px;
    }
    
    &.campaign-layout--create {
      margin-top: 0px;
    }
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    
    h3 {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.18px;
      color: #101010;
    }
  }  
`)

export const EMAIL_PREVIEW_CLASSES = `
  body {
    max-width: 900px;
    margin: 0px auto;
  }
`
