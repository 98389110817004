import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

import CLASS_NAME from './styles'

const SKELETON_SECTIONS = [...Array(2)]
const SKELETON_QUESTIONS = [...Array(7)]

const InspectionListLoader: FC = () => {
  return (
    <div className={(cnx(CLASS_NAME, 'intake-inspection-list'))}>
      {SKELETON_SECTIONS.map((_, sectionIndex) => (
        <div key={sectionIndex} className='skeleton-section'>
          <div className='skeleton-header'>
            <TextSkeleton />
          </div>
          {SKELETON_QUESTIONS.map((_, questionIndex) => (
            <div key={questionIndex} className='skeleton-question'>
              <div className='skeleton-question-name'><TextSkeleton /></div>
              <div className='skeleton-question-comment'><TextSkeleton /></div>
              <div className='skeleton-question-status'>
                <TextSkeleton />
                <TextSkeleton />
                <TextSkeleton />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default InspectionListLoader
