import { useMemo, useState, useCallback } from 'react'
import { useModal } from '@carfluent/common'

import CRMApiProvider from 'api/crm.api'
import {
  VehicleMediaTypeId,
  type DictionaryItem,
  type EntityId,
  type ListResponse,
  type LeadMessageDto,
  type MessagePayload,
  type VehicleImage
} from 'api/types'
import { type TasksFormData } from 'types'
import { type MessagesProps } from 'components/common/Messenger'
import { type CarOfInterest } from 'components/crm/AttachInventoryPhotosModal/hook/types'
import { getParsedToken } from 'services/storage.service'

import { parseLeadTasks, parseMessage as parser } from './parser'
import { type AttachmentsInfo } from './types'

export interface UseLeadMessagesProps {
  reminderSubjects: DictionaryItem[]
  leadId: EntityId
  leadPhoneNumber: string | null
  carsOfInterest: CarOfInterest[]
  updateTasks: (tasks: TasksFormData[]) => void
  isUnsubscribed: boolean
}

export interface UseLeadMessagesReturn extends MessagesProps<LeadMessageDto> {
  leadId: EntityId
  onOpenModal: () => void
  isUnsubscribed: boolean
}

const useLeadMessages = ({
  reminderSubjects,
  leadId,
  leadPhoneNumber,
  carsOfInterest,
  updateTasks,
  isUnsubscribed
}: UseLeadMessagesProps): UseLeadMessagesReturn => {
  const [vehiclePhotoAttachments, setVehiclePhotoAttachments] = useState<VehicleImage[]>([])
  const [vehicleVideoAttachments, setVehicleVideoAttachments] = useState<VehicleImage[]>([])
  const [attachmentsInfo, setAttachmentsInfo] = useState<AttachmentsInfo>({
    attachments: [],
    totalSize: 0
  })

  const {
    isModalOpen: isAttachPhotosModalOpen,
    onOpenModal: onOpenAttachPhotosModal,
    onCloseModal: onCloseAttachPhotosModal
  } = useModal()

  const { isModalOpen, onCloseModal, onOpenModal } = useModal()
  const transport = useMemo(() => ({
    sendMessage: async (payload: MessagePayload): Promise<LeadMessageDto> => {
      const message = await CRMApiProvider.sendMessage(leadId, payload)
      const { items } = await CRMApiProvider.getLeadTasks(leadId)

      const tasks = parseLeadTasks(items, reminderSubjects)
      updateTasks(tasks)

      return message
    },
    getMessages: async (lastItemCreatedDate?: string | null): Promise<ListResponse<LeadMessageDto>> => {
      return await CRMApiProvider.getMessages(leadId, lastItemCreatedDate)
    },
    getMessage: async (id: string, _leadId: number): Promise<LeadMessageDto | null> => {
      return _leadId === Number(leadId) ? await CRMApiProvider.getMessage(leadId, id) : null
    }
  }), [leadId, updateTasks, reminderSubjects])

  // TODO-OF investigate about correct approach of setting images to Messenger attachments
  const onSubmitAttachPhotos = useCallback((selectedFiles: VehicleImage[], vehicleId?: number | null) => {
    const photos = selectedFiles.filter(file => file.vehicleMediaTypeId === VehicleMediaTypeId.Image)
    const videos = selectedFiles.filter(file => file.vehicleMediaTypeId === VehicleMediaTypeId.Video)

    setVehicleVideoAttachments(videos.map(video => ({
      ...video,
      id: `${video?.id ?? ''}`,
      vehicleId: vehicleId ?? null
    })))

    setVehiclePhotoAttachments(prevPhotos => [
      ...prevPhotos,
      ...photos.map(photo => ({ ...photo, id: `${photo.fileName}${photo.order}` }))
    ])
  }, [])

  const onDeletePhoto = useCallback((id: string | number) => {
    setVehiclePhotoAttachments(prev => prev.filter(v => v.id !== id))
  }, [])

  const assignedUser: string | null = getParsedToken()?.name
  const sendingMessageName = assignedUser != null ? `${assignedUser}` : null

  return {
    leadId,
    isUnsubscribed,
    leadPhoneNumber,
    config: {
      sendingMessageName
    },
    transport,
    parser,
    isOpen: isModalOpen,
    onClose: onCloseModal,
    onOpenModal,
    onOpenAttachPhotosModal,
    attachInventoryPhotosModalProps: {
      isOpen: isAttachPhotosModalOpen,
      onClose: onCloseAttachPhotosModal,
      onSubmit: onSubmitAttachPhotos,
      carsOfInterest,
      attachmentsInfo
    },
    photoAttachmentsProps: {
      vehiclePhotoAttachments,
      vehicleVideoAttachments,
      setVehiclePhotoAttachments,
      setVehicleVideoAttachments,
      setAttachmentsInfo,
      onDeletePhoto
    }
  }
}

export default useLeadMessages
