import type { FormValidation, DeepRequired } from '@carfluent/common'

import {
  dealerName,
  addressDataRequired,
  phoneField,
  leadCmsEmails,
  alphanumericField,
  alphanumericWithSymbolsField,
  emailField
} from 'utils/validationPresets'

import type { ProfileFormData } from './types'

const createFormValidation = (): FormValidation<DeepRequired<ProfileFormData>> => {
  return {
    dealerName,
    addressData: addressDataRequired,
    phone: phoneField(true),
    email: emailField(true),
    leadCmsEmails: (value) => leadCmsEmails({ value }),
    googleMapsLink: alphanumericWithSymbolsField(true),
    termsUrl: alphanumericField(true)
  }
}

export default createFormValidation()
