import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'

import NameWithAvatar from 'components/common/NameWithAvatar'

import CLASS_NAME from './styles'

export interface RelatedToData {
  className?: string
  name: string | null
  leadAge: number | null
}

export interface RelatedToCellProps {
  getValue: () => RelatedToData
}

const RelatedToCell: FC<RelatedToCellProps> = ({ getValue }) => {
  const { leadAge, name, className } = getValue()
  const isName = name != null
  const displayName = isName ? name : 'Unassigned'
  return (
    <CellWrapper className={CLASS_NAME}>
      <div className='multiline'>
        <NameWithAvatar
          showAvatar={false}
          name={displayName}
          className={className}
        />
        <p className='days-old'>
          Age: {leadAge}d
        </p>
      </div>
    </CellWrapper>
  )
}

export default RelatedToCell
