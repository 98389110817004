import type { FC } from 'react'

import { TaskMode } from 'pages/crm/LeadDetailsView/hook/types'
import type { ResponseOnAutoTaskFormData } from 'pages/crm/LeadDetailsView/hook/types'
import EditDeletePopover from 'pages/crm/LeadDetailsView/components/EditDeletePopover'
import CreatedCompleteLayout, { type CreatedCompleteLayoutProps } from 'components/crm/CreatedCompleteLayout'

import ActionBar from './components/ActionBar'
import useEmailReceivedTask from './hook'

import CLASS_NAME from './styles'

export interface EmailReceivedTaskProps {
  completeLayoutProps: CreatedCompleteLayoutProps
  taskMode: TaskMode | null
  taskId: number
  onSubmit: (values: ResponseOnAutoTaskFormData, taskId: number) => Promise<void>
  onViewEmailClick: (taskId: number) => Promise<void>
  onCloseTask: (taskId: number | null, taskMode?: TaskMode) => void
  onEditTaskClick: (taskId: number) => void
  onDeleteTaskClick: (taskId: number) => void
}

const EmailReceivedTask: FC<EmailReceivedTaskProps> = ({ completeLayoutProps, ...props }) => {
  const {
    isLoading,
    onRespondedSubmit,
    onNoResponseNeededSubmit,
    onViewEmailClick
  } = useEmailReceivedTask(props)
  const { taskId, onEditTaskClick, onDeleteTaskClick } = props

  return (
    <div className={CLASS_NAME}>
      <EditDeletePopover
        onDeleteTaskClick={onDeleteTaskClick}
        onEditTaskClick={onEditTaskClick}
        taskId={taskId}
      />
      <CreatedCompleteLayout {...completeLayoutProps} showActionBar={false} />
      <div className='lead-tasks-action-buttons-container'>
        <ActionBar
          buttonsClassName='lead-tasks-action-buttons'
          onRespondedSubmit={onRespondedSubmit}
          onNoResponseNeededSubmit={onNoResponseNeededSubmit}
          onViewEmailClick={onViewEmailClick}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default EmailReceivedTask
