import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import Avatar from 'components/common/NameWithAvatar'
import { getHtmlString, getLink } from 'utils/html'
import CLASS_NAME from './styles'

interface HTMLContentProps {
  name?: string | null
  text: string
  linkText?: string
  isShouldConvertWithLink: boolean
  isWrappedLine?: boolean
  salesperson?: string | null
}

const HTMLContent: FC<HTMLContentProps> = ({
  isShouldConvertWithLink,
  isWrappedLine = false,
  name,
  text,
  linkText,
  salesperson
}) => {
  const htmlData = isShouldConvertWithLink
    ? linkText != null
      ? getLink(text, linkText)
      : getHtmlString(text)
    : text.replace(/\n/g, '<br />')
      .replace(/<p>/g, '<span>')
      .replace(/<\/p>/g, '</span>')

  return (
    <div className={cnx(CLASS_NAME, isWrappedLine && 'wrapped-line')}>
      {name != null && <span className='item-name-text'>{name}:</span>}
      {text !== '' && <span dangerouslySetInnerHTML={{ __html: htmlData }} />}
      {salesperson != null && <Avatar showAvatar name={salesperson} />}
    </div>
  )
}

export default HTMLContent
