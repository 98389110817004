import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  height: 100%;
  padding: 16px;
  background: #F3F3F3;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;

  // important -> decreases number of rerenders significantly
  &.is-dragging > * {
    pointer-events: none;
  }
  
  .cf-no-messages {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .cf-loader {
    display: flex;
    justify-content: center;
    color: #101010;
    padding-bottom: 16px;
  }

  .cf-dnd-overlay {
    position: absolute;
    inset: 0px;
    pointer-events: none;
    background-color: #C99B86;
    z-index: 100;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }

  .unsubscribed {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: rgba(33, 33, 33, 0.50);
  }
`)

export const GALLERY_CLASS_NAME = css(`
  .cf-gallery-sections .cf-full-image-section {
    margin: unset;
  }
`)
