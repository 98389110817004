import { type ChangeEvent, useCallback, useEffect, useRef, useMemo } from 'react'
import { type Result, isOk, isFail, useForm, useSubscribe, useMediator } from '@carfluent/common'
import cloneDeep from 'lodash-es/cloneDeep'

import {
  type DealFormValues,
  type DealFormErrTouchShortcuts,
  type UseDealForm
} from 'types'

import { type LienholderItemDto } from 'api/types'
import { type UpdateSalesTaxDto } from 'api/types/requests'
import { type DealModel } from 'api/types/responses'
import { type PercentOrAmountFormData } from 'components/deals/PercentOrAmountInput/types'
import CustomersCoreApiProvider from 'api/customersCore.api'
import DocumentsApiProvider from 'api/documents.api'
import useUsersList from 'hooks/useUsersList'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useUnsavedChanges from 'hooks/deals/useUnsavedChanges'
import getCurrentUser from 'utils/getCurrentUser'
import getDealSalesTaxAmountRounded from 'utils/deals/priceHelpers/getDealSalesTaxAmount'
import isDealTransactionRecorded from 'utils/deals/isDealTransactionRecorded'
import _isFinancing from 'utils/deals/isFinancing'
import { roundRate } from 'utils/math'
import { scrollToError } from 'utils/validation'
import Events from 'constants/events'

import { type UseTabDealProps, type UseTabDealReturn } from './types'
import { Messages, GET_DEFAULT_DEAL_TAB_VALUES, DEPENDENT_VALIDATIONS } from './constants'
import validationRules from './validator'
import serializeTabDeal from './serializer'

const SCROLL_TO_ERROR_DELAY = 200

const useTabDeal = ({
  deal,
  dealId,
  isSelected,
  feesAndCoveragesSettings,
  onLoadSalesCommission,
  nextTab,
  onLoadDeal,
  onTrySwitchTab,
  onUpdateDeal,
  tabIdx
}: UseTabDealProps): UseTabDealReturn => {
  const { showAlert, showSuccess } = useCustomSnackbar()
  const { send } = useMediator()

  const currentUser = getCurrentUser()
  const users = useUsersList(currentUser)

  const refLastUpdatedFieldId = useRef<string | ChangeEvent | null>(null)
  const refLastUpdateProcessed = useRef(true)

  const refShouldUpdateBaseValues = useRef(true)
  const refBaseValues = useRef(GET_DEFAULT_DEAL_TAB_VALUES())

  const submitAction = useCallback(async (_values: DealFormValues): Promise<DealFormValues | null> => {
    if (deal?.rowVersion == null) {
      return null
    }

    const data = serializeTabDeal(_values, deal, feesAndCoveragesSettings)
    const payload = { dealId, rowVersion: deal.rowVersion, data }

    const result = await CustomersCoreApiProvider.patchDeal2(payload)
    await onLoadSalesCommission(dealId)

    return result
  }, [
    dealId,
    deal,
    feesAndCoveragesSettings
  ])

  const onActionResult = useCallback((res: Result<DealModel>, resetForm: (val: DealFormValues) => void): void => {
    if (isOk(res)) {
      onUpdateDeal(res.result)
      showSuccess(Messages.SuccessUpdate)
    }

    if (isFail(res)) {
      setTimeout(scrollToError, SCROLL_TO_ERROR_DELAY)
    }
  }, [onUpdateDeal, showSuccess])

  const baseValues = useMemo(() => {
    /**
     * In case with SalesTax, Inventory Tax and Sales Commission we have updated Deal object
     * (rowVersion, etc.), bat we don't want to recalculate `baseValues` to not reset unsaved changes.
     *
     * Use case:
     * Step 1: user changed field "Car delivery" field but not saved form
     * Step 2: user changed "Sales Commission" field and clicked "Apply"
     * After `PATCH` request, changed to "Car delivery" field should not be lost.
     */
    if (!refShouldUpdateBaseValues.current) {
      refShouldUpdateBaseValues.current = true
      return refBaseValues.current
    }

    const nextBaseValues = GET_DEFAULT_DEAL_TAB_VALUES(cloneDeep(deal))
    refBaseValues.current = nextBaseValues

    return nextBaseValues
  }, [deal])

  const form = useForm<DealFormValues, DealFormErrTouchShortcuts>({
    baseValues,
    dependentValidations: DEPENDENT_VALIDATIONS,
    isTrackingChanges: true,
    onActionResult,
    submitAction,
    validationRules
  })

  const {
    hasChanges,
    isValid,
    onChange,
    onSubmit,
    resetForm,
    setFieldInitialValue,
    values
  } = form

  const isFinancing = _isFinancing(values.dealFinanceTypeId)
  const isWholesale = deal?.isWholesale ?? false

  const modalUnsavedChangesProps = useUnsavedChanges({
    onTrySwitchTab,
    shouldShowUnsavedChanges: (nextTab.id !== tabIdx) && hasChanges,
    trigger: nextTab
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onSaveButtonClick = useCallback(async () => {
    if (!isValid) {
      setTimeout(scrollToError, SCROLL_TO_ERROR_DELAY)
    }

    return await onSubmit()
  }, [isValid, onSubmit])

  const onUpdateNotes = useCallback(async (notes: string | null): Promise<void> => {
    try {
      const { rowVersion } = await CustomersCoreApiProvider.updateDealNotes({ dealId, notes })
      onUpdateDeal({ rowVersion, notes })
    } catch {
    }
  }, [dealId, onUpdateDeal])

  const onViewBillOfSale = useCallback(async (): Promise<void> => {
    try {
      if (hasChanges) {
        const result = await onSaveButtonClick()
        if (!isOk(result)) {
          return
        }
      }

      const res = await DocumentsApiProvider.downloadBillOfSale(dealId)
      window.open(
        URL.createObjectURL(new Blob([res], { type: 'application/pdf' })),
        '_blank'
      )
    } catch {
      showAlert('Cannot print Bill of Sale.')
    }
  }, [hasChanges, onSaveButtonClick, showAlert])

  const onRefreshDeal = useCallback(async () => {
    await onLoadDeal(dealId)
  }, [dealId, onLoadDeal])

  const onChangeField = useCallback((idOrEvent, value) => {
    refLastUpdatedFieldId.current = idOrEvent
    refLastUpdateProcessed.current = false
    onChange(idOrEvent, value)

    /**
     * AZ-TODO: investigate magic, this field is not updated in second time.
     * Need to re-send update.
     */
    if (idOrEvent === 'dealFees.dealerInventoryTax') {
      setTimeout(() => {
        refLastUpdatedFieldId.current = idOrEvent
        refLastUpdateProcessed.current = false
        onChange(idOrEvent, value)
      }, 100)
    }
  }, [onChange])

  const onResetForm = useCallback(() => {
    resetForm(GET_DEFAULT_DEAL_TAB_VALUES(cloneDeep(deal)))

    /**
     * `onChange` of saleDate automatically re-calculates `firstPaymentDate`,
     * that activates "unsaved changes" footer, even on "DISCARD".
     * See https://dev.azure.com/carfluent/CarFluent/_workitems/edit/14160
     */
    if (deal?.firstPaymentDate != null) {
      setTimeout(() => {
        onChange('firstPaymentDate', deal?.firstPaymentDate)
      }, 100)
    }
  }, [deal, resetForm, onChange])

  const onAddLienholder = useCallback(async (value: LienholderItemDto) => {
    onChange('activeLenderDecision.lienholder', value)
  }, [])

  const onOpenWarrantiesDialog = useCallback(() => {
    const doJob = async (): Promise<void> => {
      if (hasChanges) {
        const result = await onSaveButtonClick()

        if (!isOk(result)) {
          return
        }
      }

      const result: boolean[] = await send(Events.DealShowWarranties, null)
      if (result.every(Boolean)) {
        await onRefreshDeal()
      }
    }

    void doJob()
  }, [
    hasChanges,
    onRefreshDeal,
    onSaveButtonClick,
    send
  ])

  const onSubmitSalesTax = useCallback(async (_data: PercentOrAmountFormData): Promise<void> => {
    const data: UpdateSalesTaxDto = {
      overridenSalesTaxPercent: _data.percent,
      overridenSalesTaxAmount: _data.amount
    }

    if (data.overridenSalesTaxPercent != null) {
      data.overridenSalesTaxPercent = roundRate(data.overridenSalesTaxPercent)
    }

    const nextDeal = await CustomersCoreApiProvider.patchDealSalesTax({
      data,
      dealId,
      rowVersion: deal?.rowVersion ?? ''
    })

    if (nextDeal != null) {
      setFieldInitialValue(
        'dealFees.overridenSalesTaxAmount',
        nextDeal.dealFees.overridenSalesTaxAmount ?? 0
      )

      setFieldInitialValue(
        'dealFees.overridenSalesTaxPercent',
        nextDeal.dealFees.overridenSalesTaxPercent ?? null
      )

      refShouldUpdateBaseValues.current = false
      onUpdateDeal(nextDeal)
    }
  }, [
    dealId,
    deal,
    onUpdateDeal,
    setFieldInitialValue
  ])

  const onSubmitInventoryTax = useCallback(async (value: number): Promise<void> => {
    const nextDeal = await CustomersCoreApiProvider.patchDealInventoryTax({
      data: roundRate(value),
      dealId,
      rowVersion: deal?.rowVersion ?? ''
    })

    if (nextDeal != null) {
      setFieldInitialValue(
        'dealFees.overridenSalesTaxAmount',
        nextDeal.dealFees.overridenSalesTaxAmount ?? 0
      )

      setFieldInitialValue(
        'dealFees.dealerInventoryTax',
        nextDeal.dealFees.dealerInventoryTax ?? 0
      )

      refShouldUpdateBaseValues.current = false
      onUpdateDeal(nextDeal)
    }
  }, [
    dealId,
    deal?.rowVersion,
    onUpdateDeal,
    setFieldInitialValue
  ])

  const onSubmitSalesCommission = useCallback(async (data: number | null): Promise<void> => {
    const nextDeal = await CustomersCoreApiProvider.patchDealSalesCommission({
      data,
      dealId,
      rowVersion: deal?.rowVersion ?? ''
    })

    if (nextDeal != null) {
      const nextCommission = nextDeal.dealFees.overridenSalesCommission ?? null
      setFieldInitialValue('dealFees.overridenSalesCommission', nextCommission)

      refShouldUpdateBaseValues.current = false
      onUpdateDeal(nextDeal)

      if (nextCommission == null) {
        await onLoadSalesCommission(dealId)
      }
    }
  }, [
    dealId,
    deal?.rowVersion,
    onLoadSalesCommission,
    onUpdateDeal,
    setFieldInitialValue
  ])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Recalculates `salesTaxAmount` on every change (of other fields).
   * AZ-TODO: check if debounce is needed/helpful
   */
  useEffect(() => {
    if (refLastUpdateProcessed.current || (deal?.isManualSalesTax === true)) {
      return
    }

    const lastUpdatedFieldId = refLastUpdatedFieldId.current?.toString() ?? ''
    if (!['dealFees.overridenSalesTaxAmount', 'dealFees.overridenSalesTaxPercent'].includes(lastUpdatedFieldId)) {
      const salesTaxAmount = getDealSalesTaxAmountRounded({
        coverages: deal?.coverageDetails ?? [],
        feesAndCoveragesSettings,
        values,
        salesTax: values.dealFees.overridenSalesTaxPercent
      })

      onChange('dealFees.overridenSalesTaxAmount', salesTaxAmount)
    }

    refLastUpdateProcessed.current = true
  }, [
    feesAndCoveragesSettings,
    values,
    deal?.coverageDetails,
    deal?.isManualSalesTax,
    onChange
  ])

  useSubscribe(Events.DealSaveRequested, async (_, skip) => {
    if (!isSelected || !hasChanges) {
      return skip()
    }

    const result = await onSaveButtonClick()
    return isOk(result)
  })

  // ========================================== //

  return {
    ...form,
    isFinancing,
    isManualSalesTax: deal?.isManualSalesTax ?? false,
    isRecorded: isDealTransactionRecorded(deal?.dealAccountingStateId ?? null),
    isWholesale,
    modalUnsavedChangesProps,
    onAddLienholder,
    onChange: onChangeField as UseDealForm['onChange'],
    onOpenWarrantiesDialog,
    onRefreshDeal,
    onResetForm,
    onSubmitForm: onSaveButtonClick,
    onSubmitInventoryTax,
    onSubmitSalesCommission,
    onSubmitSalesTax,
    onUpdateNotes,
    onViewBillOfSale,
    users
  }
}

export default useTabDeal
