import { useCallback, useContext } from 'react'
import type { FC } from 'react'
import { useNavigate, useMatch } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import AccountingDisabled from 'components/accounting/AccountingDisabled'
import { Routes } from 'constants/route_helper'
import SettingsCTX from 'store/settings'

const AccountingHOK = (Comp: FC): (() => JSX.Element) => observer(() => {
  const navigate = useNavigate()
  const matches = useMatch(`${Routes.AccountingSettings}/*`)
  const {
    isAccountingEnabled,
    isStarterEnabled,
    enableStarter
  } = useContext(SettingsCTX)

  const isEnabled = (matches == null)
    ? isAccountingEnabled
    : (isStarterEnabled || isAccountingEnabled)

  const onEnableStarter = useCallback(() => {
    enableStarter()
    navigate(Routes.AccountingGeneralSettings)
  }, [enableStarter, navigate])

  if (isEnabled) {
    return <Comp />
  }

  return (
    <AccountingDisabled onEnable={onEnableStarter} />
  )
})

export default AccountingHOK
