import { css } from '@emotion/css'

export default css(`
  display: flex;
  align-items: center;
  
  .range-button {
    .cf-button-content {
      display: flex;
    }

    &.cf-button-root {
      background: transparent;
      padding: 8px;
      border-radius: 8px;
      height: 36px;
      margin-left: 8px;
      
      svg {
        margin-left: 4px;
      }
      
      &.cf-button-variant-text {
        color: rgba(16, 16, 16);
        text-decoration: none;
        font-size: 14px;
      }
    }
  }
`)

export const POPOVER_CLASS_NAME = css(`
  padding: 24px !important;
  border-radius: 12px !important;
  width: 340px;
  z-index: 1000!important;

  .cf-filter-items {
    margin-top: 8px;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  
  .form-content, .form-header, .form-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .form-content .cf-form-field {
    max-width: 140px;
  }

  .form-exact-content {
    margin-bottom: 24px;

    .cf-form-field {
      width: 100%;
    }
  }
  
  .form-header {
    p {
      color: #101010;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
    }
    
    svg {
      cursor: pointer;
    }
  }

  .form-input-label {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  
  .form-actions {
    justify-content: flex-end;
    margin-bottom: 0;

    button.cf-button-root {

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1.25px;
      }

      &.cf-button-variant-text {
        padding: 0px;
        color: #000;
      }

      height: 26px;
      padding: 5px 18px;
      min-width: unset;
      border-radius: 8px;

      [class^="cf-button"] {
        margin: 0px;
      }
    }

    .apply-btn {
      margin-left: 24px;
      color: #fff;
    }

    .remove-btn {
      text-decoration: none;
      color: #101010;
    }
  }
`)
