import { type FC, useCallback, useMemo, useRef, useState } from 'react'
import Popover from '@material-ui/core/Popover'
import { cnx, FormNumberInput, noop, type NumberInputProps } from '@carfluent/common'

import { PercentOrAmountModes } from 'types'
import { MAX_PRICE_VALUE } from 'constants/validation'

import { CLASS_NAME, POPOVER_CLASS_NAME } from './styles'

const POPOVER_ANCHOR_ORIGIN = { vertical: 'bottom', horizontal: 'left' } as const

interface PopperWrapperProps extends Omit<NumberInputProps, 'id' | 'onChange' | 'label' | 'value'> {
  isManualSalesTax: boolean
  isOpen: boolean
  label: string
  onChange: (id: string, value: number) => void
  onClose: () => void
  onOpen: () => void
  salesTax: number | null
  salesTaxAmount: number | null
}

const PopperTrigger: FC<PopperWrapperProps> = ({
  disabled = false,
  isManualSalesTax,
  isOpen,
  label,
  children,
  onChange: _onChange,
  onClose: _onClose,
  onOpen: _onOpen,
  salesTax,
  salesTaxAmount
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const taxMode: string = isManualSalesTax ? PercentOrAmountModes.Manual : PercentOrAmountModes.Auto

  const salesTaxStr = new Intl.NumberFormat('en-IN', {
    maximumFractionDigits: 3,
    minimumFractionDigits: 2
  }).format((salesTax ?? 0) * 100)

  const onOpen = useCallback(() => {
    _onOpen()
    setAnchorEl(containerRef.current)
  }, [_onOpen])

  const onClose = useCallback(() => {
    _onClose()
    setAnchorEl(null)
  }, [_onClose])

  const endAdornment = useMemo(() => (
    <span
      onClick={disabled ? noop : onOpen}
      className={cnx('clickable-input-adornment', disabled && 'disabled')}
      aria-describedby='popper-wrapper'
    >
      Edit tax
    </span>
  ), [onOpen, disabled])

  // ========================================== //

  return (
    <div className={CLASS_NAME} ref={containerRef}>
      <div
        className={cnx('backdrop', !disabled && 'not-disabled')}
        onClick={onOpen}
      />

      <FormNumberInput
        className={cnx('clickable-input', !disabled && 'not-disabled')}
        id='readonly-sales-tax-amount'
        label={label}
        preset='price'
        blurComplementMode='min-scale'
        mountComplementMode='min-scale'
        isEmptyAllowed={false}
        endAdornment={endAdornment}
        value={salesTaxAmount}
        max={MAX_PRICE_VALUE}
        onChange={noop}
        disabled
      />

      {(taxMode === PercentOrAmountModes.Auto) && (
        <p className='sales-tax-label'>
          Tax {salesTaxStr}%
        </p>
      )}

      {(taxMode === PercentOrAmountModes.Manual) && (
        <p className='sales-tax-label'>
          Manually entered amount
        </p>
      )}

      <Popover
        id='popper-wrapper'
        className={POPOVER_CLASS_NAME}
        open={isOpen}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={POPOVER_ANCHOR_ORIGIN}
      >
        <div className='popover-content'>
          {children}
        </div>
      </Popover>
    </div>
  )
}

export default PopperTrigger
