import { DraftDecorator } from 'draft-js'

import carBlockDecorator from './carBlock'
import feedbackLinkDecorator from './feedbackLink'
import uploadVideoLinkDecorator from './uploadVideoLink'

const decorators: DraftDecorator[] = [
  carBlockDecorator,
  feedbackLinkDecorator,
  uploadVideoLinkDecorator
]

export default decorators
