import { css } from '@emotion/css'

export default css(`
  gap: 16px;
  padding-bottom: 110px;
  
  .cf-no-data-block {
    display: flex;
    justify-content: center;
    padding: 30px;
    background: #fff;
    border-top: 8px solid #F3F3F3;
  }

  .header-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0px;
    
    .left-panel {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .right-panel {
      display: flex;
      width: fit-content;
      min-width: fit-content;
      align-items: center;
      gap: 16px;
    }
    
    .btn-new-item {
      width: 150px;
      height: 56px;
    }
  }

  .cf-table-header-title p {
    font-weight: 600;
    color: #101010;
  }
  
  .aligned-right {
    text-align: right;
  }
  
  .cf-back-to-top {
    bottom: 56px;
  }
`)
