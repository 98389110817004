import type { FC } from 'react'
import lowPriorityIcon from 'assets/1-fire-of-3-icon.svg'
import mediumPriorityIcon from 'assets/2-fires-of-3-icon.svg'
import highPriorityIcon from 'assets/3-fires-icon.svg'

const HighPriorityIcon: FC = () => {
  return (
    <img src={highPriorityIcon} alt='High Priority' />
  )
}

const MediumPriorityIcon: FC = () => {
  return (
    <img src={mediumPriorityIcon} alt='Medium Priority' />
  )
}

const LowPriorityIcon: FC = () => {
  return (
    <img src={lowPriorityIcon} alt='Low Priority' />
  )
}

enum TaskPriority {
  LowPriority = 1,
  MediumPriority,
  HighPriority
}

const PRIORITY_ICONS: Record<string, FC> = {
  [TaskPriority.LowPriority]: LowPriorityIcon,
  [TaskPriority.MediumPriority]: MediumPriorityIcon,
  [TaskPriority.HighPriority]: HighPriorityIcon
}

interface PriorityIconProps {
  id?: number
}

const PriorityIcon: FC<PriorityIconProps> = ({ id }) => {
  if (id == null) {
    return null
  }

  const Icon = PRIORITY_ICONS[id]

  return <Icon />
}

export default PriorityIcon
