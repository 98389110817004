import { css } from '@emotion/css'

export default css`
  .cf-modal-content-scroll-wrapper .cf-modal-root {
    width: 1034px;
    max-width: 1034px;
    
    .cf-modal-content {
      display: grid;
      row-gap: 24px;
    }
    
    .cf-modal-footer {
      display: flex;
      justify-content: flex-end;
    }

    .cf-banner-appeared:after {
      display: none;
    }
    
    .pdf-viewer {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.24);
      height: 564px;
    }
    
    .editor-wrapper {
      .text-editor-container {
        min-height: 64px;
      }
    }
  }
`
