import { type SortingInfo } from '@carfluent/common'
import { type CancelationOptions } from 'api/types'
import { type CampaignTotalDto } from 'api/types/responses'

export const CANCEL_LOAD_DATA: CancelationOptions = { key: 'load-email-templates-data', cancelPrevious: true }

export enum EmptyTableMsg {
  NoItems = 'No records yet',
  NoItemsFound = 'No records found'
}

export const DEFAULT_SORTING: SortingInfo = { id: 'scheduledDateTime', order: 'desc' }

export const getDefaultCounters = (): CampaignTotalDto => ({
  draft: 0,
  pending: 0,
  started: 0,
  sent: 0,
  failed: 0
})
