import { css } from '@emotion/css'

export default css(`
  .cf-footer-actions-section button {
    margin: 0px;

    &:first-child {
      margin-right: 25px;
    }
  }

  > .cf-loader {
    position: fixed;
    inset: 0px;
    left: 64px;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.75);
  }

  .text-info {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.3);
    letter-spacing: 0.15px;
  }

  .cf-collapse-header {
    button.cf-button-root {
      padding: 0px;
      height: 32px;
    }
  }

  h3 {
    color: rgb(16, 16, 16);
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    user-select: none;
    letter-spacing: 0.18px;
  }

  h6 {
    grid-column: 1 / 3;
  }

  .credit-app-section.no-collapse, .cf-collapse-section, .warranty-coverage {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 32px;
    grid-column: 1 / 3;
  }
  
  .warranty-coverage {
    grid-row-gap: 16px;
  }

  hr,
  .no-collapse h3,
  .address-fields-component,
  .cf-banner-appeared,
  .text-info,
  .warranty-coverage
  .g-checkbox,
  .business-details-name {
    grid-column: 1 / 3;
    padding: 0px;
  }

  .cf-banner-appeared {
    margin-bottom: -16px;
  }

  .coapp-housing-status, .coapp-same-address {
    grid-column: 1 / 2;
  }

  .additional-costs {
    .cf-collapse-section {
      margin-top: 32px;
      grid-template-columns: 1fr;
    }
  }

  .credit-app-section.warranty, .credit-app-section.co-applicant, .credit-app-section.trade-in {
    .cf-collapse-section {
      margin-top: 32px;
    }
  }

  .credit-app-section.warranty {
    /* AZ-TODO: investigate, is not applied */
    > .cf-collapse-section {
      grid-template-columns: 1fr;
      grid-row-gap: 0px;  
    }
    
    .coverage-checkboxes-list,
    .coverage-type-selector,
    .coverage-section-title,
    .cf-loader {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    
    .credit-app-coverages-checklist {
      display: grid;
      grid-template-columns: fit-content(30%) fit-content(20%) 1fr;
      column-gap: 32px;
      row-gap: 24px;
    }
  }

  .collapse-btn .cf-button-content {
    font-size: 14px;
  }

  .text-info-header {
    grid-column: 1 / 3;
  }

  .no-margin {
    margin: 0px;
  }
`)
