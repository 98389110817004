import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { Routes } from 'constants/route_helper'
import ReconStatusFilters from 'components/reconditioning/ReconStatusFilters'
import Header from '../Header'

import useReconditioningDashboard from './hook/index'

import CLASS_NAME from './styles'

interface ReconditioningDashboardProps {
  className?: string
}

export const EMPTY_FORMAT_VALUE = { emptyValues: '0' }

const ReconditioningDashboard: FC<ReconditioningDashboardProps> = ({
  className
}) => {
  const { isLoading, selectedStep, onChangeStep, statusFiltersProps } = useReconditioningDashboard()

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <Header
        withFilter={false}
        isLoading={isLoading}
        title='Reconditioning'
        routes={Routes.Recon}
        className='recon-dashboard-header'
      />

      <ReconStatusFilters
        selectedStep={selectedStep}
        onChangeStep={onChangeStep}
        statusFiltersProps={statusFiltersProps}
        className='recon-dashboard-status-filters'
      />
    </div>

  )
}

export default ReconditioningDashboard
