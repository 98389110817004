import { css } from '@emotion/css'
import { ContentLayoutWidth as CLW, px } from 'constants/constants'

export const CLASS_NAME = css(`
  --footer-height: 80px;

  &.g-content-layout {
    width: 100%;
    min-width: calc(100vw - ${px(CLW.SettingsSideMenu + CLW.NavDrawer + CLW.MinSideMargins)});
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    .g-single-form-content {
      margin: 0px;
    }
  }

  .tab-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: var(--footer-height);
    box-shadow: 0px -2px 12px 0px #0000001F;
    background-color: #fff;
    
    position: fixed;
    z-index: 1102;
    left: 0px;
    right: 0px;
    bottom: calc(var(--footer-height) * -1);
    padding-right: 64px;
    transition: bottom 0.3s ease;

    &.is-shown {
      bottom: 0px;
    }

    button {
      margin: 0px;

      &:first-child {
        margin-right: 24px;
      }
    }
  }
`)

export const LOGO_UPDATED_MODAL_CLASS_NAME = css(`
  p {
    color: #101010;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
`)
