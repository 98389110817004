import { type CrmSettings, TaskAssigneeType } from 'api/types'

import type { CrmSettingsFormData } from './types'
import { transformMinutesByDuration } from './utils'
import { AdditionalRecipientsIds } from './constants'
import type { AppointmentReminderUser } from 'api/types/crm.types'

interface SerializeAssigneeReturn {
  taskType: number | null
  userId: number | null
}

const serializeAssignee = (id: number | null): SerializeAssigneeReturn =>
  id == null
    ? { taskType: null, userId: null }
    : {
        taskType: id > 0 ? TaskAssigneeType.User : Math.abs(id),
        userId: id > 0 ? id : null
      }

interface ReduceResult {
  users: AppointmentReminderUser[]
  appReminderSendToAssignee: boolean
  appReminderSendToLeadOwner: boolean
}

const gerDefaultReduceValue = (): ReduceResult => ({
  appReminderSendToAssignee: false,
  appReminderSendToLeadOwner: false,
  users: []
})

export const serializeCrmSettings = ({
  userIds,
  roundRobinEnabled,
  duration,
  roundRobinIntervalMinutes,
  autoReplyEnabled,
  vehicleAvailableEmailTemplate,
  vehicleAvailableMessageTemplate,
  vehicleUnavailableEmailTemplate,
  vehicleUnavailableMessageTemplate,
  creditAppTasksEnabled,
  webLeadAssignedUserId: _webLeadAssignedUserId,
  messageReceivedAssignedUserId: _messageReceivedAssignedUserId,
  emailReceivedAssignedUserId: _emailReceivedAssignedUserId,
  creditAppAssignedUserId: _creditAppAssignedUserId,
  isEmailOnPriceDropEnabled,
  emailOnPriceDropEmailTemplate,
  emailOnPriceDropThreshold,
  isMessageOnPriceDropEnabled,
  messageOnPriceDropThreshold,
  messageOnPriceDropEmailTemplate,
  salesAppReminderMinutes,
  salesAppReminderEnabled,
  appointmentReminderUsers,
  customerAppReminderEnabled,
  customerAppReminderMessageTemplate,
  customerAppReminderMinutes
}: CrmSettingsFormData): CrmSettings => {
  const _userIds = userIds.filter(user => user.checked).map(user => user.id)
  const transformedIntervalMinutes = transformMinutesByDuration(duration.id, roundRobinIntervalMinutes)
  const {
    userId: webLeadAssignedUserId,
    taskType: webLeadTaskAssigneeTypeId
  } = serializeAssignee(_webLeadAssignedUserId?.id ?? null)

  const {
    userId: messageReceivedAssignedUserId,
    taskType: messageReceivedTaskAssigneeTypeId
  } = serializeAssignee(_messageReceivedAssignedUserId?.id ?? null)

  const {
    userId: emailReceivedAssignedUserId,
    taskType: emailReceivedTaskAssigneeTypeId
  } = serializeAssignee(_emailReceivedAssignedUserId?.id ?? null)

  const {
    userId: creditAppAssignedUserId,
    taskType: creditAppTaskAssigneeTypeId
  } = serializeAssignee(_creditAppAssignedUserId?.id ?? null)

  const {
    users: _appointmentReminderUsers,
    appReminderSendToAssignee,
    appReminderSendToLeadOwner
  } = appointmentReminderUsers.reduce<ReduceResult>((res, item) => {
    const isAssignee = item.id === AdditionalRecipientsIds.AppointmentTaskAssignee
    const isLeadOwner = item.id === AdditionalRecipientsIds.LeadOwner
    if (isAssignee && !res.appReminderSendToAssignee) {
      res.appReminderSendToAssignee = true
    }

    if (isLeadOwner && !res.appReminderSendToLeadOwner) {
      res.appReminderSendToLeadOwner = true
    }

    if (!isAssignee && !isLeadOwner) {
      res.users.push({ id: item.id })
    }

    return res
  }, gerDefaultReduceValue())

  return {
    roundRobinEnabled,
    roundRobinIntervalMinutes: transformedIntervalMinutes,
    userIds: _userIds,
    autoReplyEnabled,
    vehicleAvailableEmailTemplateId: vehicleAvailableEmailTemplate?.id ?? null,
    vehicleAvailableMessageTemplateId: vehicleAvailableMessageTemplate?.id ?? null,
    vehicleUnavailableEmailTemplateId: vehicleUnavailableEmailTemplate?.id ?? null,
    vehicleUnavailableMessageTemplateId: vehicleUnavailableMessageTemplate?.id ?? null,
    webLeadTaskAssigneeTypeId,
    webLeadAssignedUserId,
    messageReceivedTaskAssigneeTypeId,
    messageReceivedAssignedUserId,
    emailReceivedTaskAssigneeTypeId,
    emailReceivedAssignedUserId,
    creditAppTasksEnabled,
    creditAppTaskAssigneeTypeId,
    creditAppAssignedUserId,
    isEmailOnPriceDropEnabled,
    emailOnPriceDropEmailTemplateId: emailOnPriceDropEmailTemplate?.id ?? null,
    emailOnPriceDropThreshold,
    isMessageOnPriceDropEnabled,
    messageOnPriceDropThreshold,
    messageOnPriceDropEmailTemplateId: messageOnPriceDropEmailTemplate?.id ?? null,
    salesAppReminderMinutes: salesAppReminderMinutes?.id ?? 0,
    salesAppReminderEnabled,
    appointmentReminderUsers: _appointmentReminderUsers,
    appReminderSendToAssignee,
    appReminderSendToLeadOwner,
    customerAppReminderEnabled,
    customerAppReminderMessageTemplateId: customerAppReminderMessageTemplate?.id ?? null,
    customerAppReminderMinutes: customerAppReminderMinutes?.id ?? 0
  }
}
