import { css } from '@emotion/css'

export default css(`
  &.g-panel {
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin: 0px;
  }
  
  
  .deals-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .deals-fields {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
    
    .deals-field {
      flex: calc(50% - 4px);
    }
  }
  
  .deals-sales-target {
    width: 100%;
    height: 232px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    
    .sales-target-header {
      &.is-loading .text-skeleton {
        min-width: 109px;
        min-height: 16px;
        max-height: 16px;
      }
      
      h6 {
        width: fit-content;
      }
    }
  }
`)
