import { css } from '@emotion/css'

interface Styles {
  vendorOptions: string
  optionValue: string
  sectionItem: string
  modalClassName: string
}

const createStyleClass = (): Styles => {
  return {
    modalClassName: css(`
    .cf-modal-content-scroll-wrapper {
      -ms-overflow-style: none;
      scrollbar-width: none;
  
      ::-webkit-scrollbar {
        display: none;
        width: 0;
        background: transparent;
      }
  
      .cf-modal-content {
        padding: 16px 40px 0 40px;
      }

      .cf-button-variant-text {
        text-decoration: none;
      }
    }
  `),

    vendorOptions: css(`
      .cf-option {
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      .cf-button-option {
        cursor: pointer;
        user-select: none;
        min-height: 48px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 16px;
        
        :hover {
          background: #F3F3F3;
        }

        .cf-option {
          font-family: Roboto;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }

        .cf-plus-icon {
          height: 14px;
          width: 14px;
          margin: 0 8px 0 0;
        }
      }
    `),

    optionValue: css(`
      font-size: 16px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `),

    sectionItem: css(`
      padding: 0 16px;
      max-width: 290px;
      
      & > .cf-item-label {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(33, 33, 33, 0.80);
        margin-bottom: 8px;
      }
    `)
  }
}

export default createStyleClass
