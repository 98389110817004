import { useState, type FC, type ReactNode } from 'react'
import { UI } from '@carfluent/common'
import { CANCEL_BUTTON_STYLE, DELETE_BUTTON_STYLE, SUBMIT_BUTTON_STYLE } from './styles'

const { Button } = UI

/**
 * DD-TODO: rename it later to ModalFooterActions
 */

interface CancelSubmitActionsFooterProps {
  isDisabled?: boolean
  isLoading?: boolean
  onSubmit?: () => void
  onClose: () => void
  submitTitle: string
  cancelTitle?: string
  onDelete?: () => Promise<void>
  deleteBtnTitle?: string
  renderDeleteButton?: () => ReactNode
  isDeleteDisabled?: boolean
  isCancelDisabled?: boolean
  additionalContent?: ReactNode
}

const CancelSubmitActionsFooter: FC<CancelSubmitActionsFooterProps> = ({
  cancelTitle = 'CANCEL',
  isDeleteDisabled = false,
  isCancelDisabled = false,
  onSubmit,
  onClose,
  onDelete: _onDelete,
  isDisabled,
  isLoading,
  deleteBtnTitle,
  renderDeleteButton,
  submitTitle,
  additionalContent = null
}) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const onDelete = async (): Promise<void> => {
    try {
      setIsDeleting(true)
      await _onDelete?.()
    } catch (e) {
      console.error(e)
    } finally {
      setIsDeleting(false)
    }
  }
  return (
    <>
      {additionalContent}
      <Button
        className={CANCEL_BUTTON_STYLE}
        dataTestId='btn-cancel'
        isDisabled={isCancelDisabled ?? isLoading}
        onClick={onClose}
        variant='outlined'
      >
        {cancelTitle}
      </Button>

      {onSubmit != null && (
        <Button
          className={SUBMIT_BUTTON_STYLE}
          dataTestId='btn-save'
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={onSubmit}
        >
          {submitTitle}
        </Button>
      )}

      {renderDeleteButton?.()}

      {(onDelete != null && deleteBtnTitle != null && renderDeleteButton == null) && (
        <Button
          className={DELETE_BUTTON_STYLE}
          isLoading={isDeleting}
          isDisabled={isDeleteDisabled}
          onClick={onDelete}
          variant='text'
        >
          {deleteBtnTitle}
        </Button>
      )}
    </>
  )
}

export default CancelSubmitActionsFooter
