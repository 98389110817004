export const LABEL_INTEREST_RATE = 'Interest rate'
export const LABEL_CREDIT_CARD_LIMIT = 'Credit card limit'
export const LABEL_INVENTORY_PACK_COST = 'Inventory pack cost'
export const LABEL_ADJUSTMENT = 'Adjustment'
export const LABEL_MONTHLY_TARGET = 'Monthly target'
export const MIN_TRADE_IN_ADJUSTMENT_VALUE = -100
export const TRADE_IN_DESCRIPTION = 'Amount to be added or substracted from the Trade in amount by default. Can be positive and negative value.'
export const SALES_MONTHLY_TARGET_DESCRIPTION = 'This setting allows you to set a monthly target for cars sold, with the dashboard displaying progress and indicating whether the target has been achieved.'


export const INTEREST_RATES = [
  {
    name: 'Excellent',
    amount: '> 720',
    className: 'excellent-status'
  },
  {
    name: 'Good',
    amount: '650-720',
    className: 'good-status'
  },
  {
    name: 'Average',
    amount: '600-650',
    className: 'average-status'
  },
  {
    name: 'Below average',
    amount: '< 600',
    className: 'below-average-status'
  }
]
