import { formatCurrency } from '@carfluent/common'

import { type CoverageProducts, type CoverageTerm } from 'types/coverage'
import {
  CoverageProductTypeIds,
  type DealerDefaultDto,
  type DealerProductCoverageDto,
  type DealerProductDeductibleDto,
  type DealerPenProducts
} from 'api/types/responses'

const PRODUCTS_LIST = [
  CoverageProductTypeIds.ServiceContract,
  CoverageProductTypeIds.GapInsurance,
  CoverageProductTypeIds.AppearanceProtection,
  CoverageProductTypeIds.TireAndWheel,
  CoverageProductTypeIds.Theft
]

const createCoverageDeductible = (productCoverage: DealerDefaultDto | null, coverage: DealerProductCoverageDto | null): DealerProductDeductibleDto[] => {
  if (
    coverage != null &&
    productCoverage?.deductibleId != null &&
    productCoverage?.termMonths === Number(coverage.termMonths) &&
    productCoverage?.termMiles === Number(coverage.termMiles)
  ) {
    return [{
      dealerCost: productCoverage.cost ?? 0,
      id: productCoverage.deductibleId ?? null,
      amount: productCoverage.deductibleAmount ?? 0,
      dealerRetailPrice: productCoverage.totalPrice ?? 0,
      description: `${formatCurrency(productCoverage.deductibleAmount)} Deductible`
    }]
  }
  return []
}

const createCoverage = (coverage: DealerProductCoverageDto, productCoverage: DealerDefaultDto | null, processedCombinations: Set<string>, pkgName: string): CoverageTerm => {
  const termCombination = `${productCoverage?.productTypeId ?? ''}-${productCoverage?.coverageName ?? ''}-${coverage.termMonths ?? ''}-${coverage.termMiles ?? ''}`
  let deductibles: DealerProductDeductibleDto[] = []

  if (!processedCombinations.has(termCombination) && productCoverage?.coverageName === pkgName) {
    deductibles = createCoverageDeductible(productCoverage, coverage)
    processedCombinations.add(termCombination)
  }

  return {
    id: Number(coverage.id),
    termMiles: Number(coverage.termMiles),
    termMonths: Number(coverage.termMonths),
    deductibles: [...deductibles, ...coverage.deductibles]
  }
}

const parsePenProducts = ({ penProducts, providers, isCashPayment, defaults }: DealerPenProducts): CoverageProducts => {
  const result: CoverageProducts = {}
  const filteredPenProducts = providers.filter(el => el.name)

  penProducts.forEach((product) => {
    if (isCashPayment && product.productTypeId === CoverageProductTypeIds.GapInsurance) return

    const productProviders = providers.map(vendor => {
      const productVendor = product.providers.find(el => el.id === vendor.id)

      if (productVendor != null) {
        return productVendor
      }

      return {
        ...vendor,
        packages: []
      }
    })

    result[product.productTypeId] = productProviders.map(provider => {
      const processedCombinations = new Set<string>()

      return ({
        id: provider.id,
        name: provider.name,
        coverages: (provider?.packages ?? []).map(pkg => ({
          coverageName: pkg.packageName,
          termList: pkg.coverages.map(coverage => {
            const productCoverage = defaults?.find(el => el.productTypeId === product.productTypeId) ?? null

            return createCoverage(coverage, productCoverage, processedCombinations, pkg.packageName)
          })
        }))
      })
    })
  })

  PRODUCTS_LIST.forEach(id => {
    if (isCashPayment && id === CoverageProductTypeIds.GapInsurance) return
    if (result[id] == null) {
      result[id] = filteredPenProducts.map(provider => ({
        id: provider.id,
        name: provider.name,
        coverages: []
      }))
    }
  })

  return result
}

export default parsePenProducts
