import type { FormValidation, DeepRequired } from '@carfluent/common'

import { dictionaryItemDropdownField } from 'utils/validationPresets'
import { type RecipientsFormData, type ErrTypeMap } from './types'

const formValidation = (): FormValidation<DeepRequired<RecipientsFormData>, never, ErrTypeMap> => {
  return {
    'filterLeadStatus.filter': dictionaryItemDropdownField(true),
    'filterLeadStatus.options': dictionaryItemDropdownField(true),
    'filterLeadTemperature.filter': dictionaryItemDropdownField(true),
    'filterLeadTemperature.options': dictionaryItemDropdownField(true),
    'filterLeadSource.filter': dictionaryItemDropdownField(true),
    'filterLeadSource.options': dictionaryItemDropdownField(true),
    'filterLeadWithScheduledAppointments.filter': dictionaryItemDropdownField(true)
  }
}

export default formValidation()
