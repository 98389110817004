import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGSound: FC<IconProps> = ({ color = '#212121', width = 222, height = 32, opacity = 0.5 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 244 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect y='15' width='2' height='2' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='6' y='12' width='2' height='8' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='12' y='9' width='2' height='14' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='18' y='14' width='2' height='4' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='24' y='8' width='2' height='16' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='30' y='9' width='2' height='14' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='36' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='42' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='48' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='54' y='9' width='2' height='14' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='60' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='66' y='8' width='2' height='16' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='72' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='78' y='14' width='2' height='4' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='84' y='12' width='2' height='8' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='90' y='9' width='2' height='14' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='96' y='14' width='2' height='4' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='102' y='8' width='2' height='16' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='108' y='9' width='2' height='14' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='114' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='120' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='126' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='132' y='9' width='2' height='14' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='138' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='144' y='8' width='2' height='16' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='150' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='156' y='14' width='2' height='4' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='162' y='15' width='2' height='2' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='168' y='8' width='2' height='16' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='174' y='9' width='2' height='14' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='180' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='186' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='192' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='198' y='9' width='2' height='14' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='204' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='210' y='8' width='2' height='16' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='216' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='222' y='14' width='2' height='4' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='228' y='15' width='2' height='2' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='234' y='14' width='2' height='4' rx='1' fill={color} fillOpacity={opacity} />
      <rect x='240' y='11' width='2' height='10' rx='1' fill={color} fillOpacity={opacity} />
    </svg>
  )
}
export default IconSVGSound
