import { type FC, useCallback } from 'react'
import { cnx, FormInput, FormDropdown } from '@carfluent/common'

import type { DictionaryItem } from 'api/types'
import { TaskMode, TaskType } from 'pages/crm/LeadDetailsView/hook/types'
import { FieldIds, UNASSIGNED_OPTION } from 'pages/crm/LeadDetailsView/hook/constants'
import type { PickedOpenTaskFormDataProps, UseTaskFormReturn } from 'pages/crm/LeadDetailsView/components/LeadDetailsTasks/components/Task/hook/types'

import { ValidationLength } from 'constants/validation'
import NameWithAvatar from 'components/common/NameWithAvatar'
import TaskPriority from 'components/common/TaskPriority'
import { renderAssignOption, renderNoUsersOption, renderPriorityOption } from 'components/crm/common'
import ActionBar from 'components/crm/ActionBar'

import DateTimeInputs from '../DateTimeInputs'

import CLASS_NAME from './styles'

type PickedOpenTaskFormProps =
| 'dateTimeInputsProps' | 'taskTypeId'
| 'onCloseTask' | 'taskMode' | 'isReminder'

export interface OpenTaskFormProps extends Pick<UseTaskFormReturn, PickedOpenTaskFormProps | PickedOpenTaskFormDataProps> {
  isLoading: boolean
  taskId: number | null
  businessHours?: [string, string]
  assignedTo: DictionaryItem[]
  taskPriorities: DictionaryItem[]
  reminderSubjects: DictionaryItem[]
}

const OpenTaskForm: FC<OpenTaskFormProps> = ({
  isLoading,
  isReminder,
  taskId,
  dateTimeInputsProps,
  taskMode,
  taskTypeId,
  businessHours,
  assignedTo,
  taskPriorities,
  reminderSubjects,
  values,
  errors,
  touched,
  onChange,
  onSubmit,
  onBlur,
  onDelete,
  onCloseTask
}) => {
  const onCancel = useCallback(() => onCloseTask(taskId, taskMode), [onCloseTask, taskId, taskMode])

  return (
    <div className={cnx(CLASS_NAME, isReminder ? 'with-subject-dropdown' : '')}>
      <DateTimeInputs
        {...dateTimeInputsProps}
        onBlur={onBlur}
        shouldGetCurrentDate={taskMode === TaskMode.NewTask}
        dateId={FieldIds.DueDate}
        timeId={FieldIds.Time}
        dueDateError={errors.dueDate}
        timeError={errors.time}
        dueDateTouched={touched.dueDate}
        businessHours={taskTypeId === TaskType.Appointment ? businessHours : null}
      />

      <FormDropdown<DictionaryItem>
        className='assigned'
        id={FieldIds.AssignedTo}
        label='Assigned to'
        mode='search'
        blurMode='select-first'
        disableClearable
        options={[UNASSIGNED_OPTION, ...assignedTo]}
        renderOption={renderAssignOption}
        renderNoOptions={renderNoUsersOption}
        startAdornment={<NameWithAvatar showAvatar avatarOnly name={values[FieldIds.AssignedTo]?.name} />}
        value={values[FieldIds.AssignedTo]}
        error={errors[FieldIds.AssignedTo]}
        touched={touched[FieldIds.AssignedTo]}
        onChange={onChange}
        onBlur={onBlur}
        dataTestId='lead-task-assigned-to'
      />

      <FormDropdown<DictionaryItem>
        className='priority'
        id={FieldIds.Priority}
        label='Priority'
        mode='select'
        blurMode='clear'
        disableClearable
        options={taskPriorities}
        renderOption={renderPriorityOption}
        startAdornment={<TaskPriority iconsOnly value={values[FieldIds.Priority]?.id} />}
        value={values[FieldIds.Priority]}
        error={errors[FieldIds.Priority]}
        touched={touched[FieldIds.Priority]}
        onChange={onChange}
        onBlur={onBlur}
        dataTestId='lead-task-priority'
      />

      {isReminder
        ? <FormDropdown<DictionaryItem>
            className='subject'
            id={FieldIds.Subject}
            options={reminderSubjects}
            label='Subject'
            mode='search'
            blurMode='select-first'
            disableClearable
            value={values[FieldIds.Subject]}
            error={errors[FieldIds.Subject]}
            touched={touched[FieldIds.Subject]}
            onChange={onChange}
            onBlur={onBlur}
            dataTestId='lead-task-field-subject'
          />
        : null}

      <FormInput
        className='description'
        id={FieldIds.Description}
        label='Description'
        value={values[FieldIds.Description]}
        error={errors[FieldIds.Description]}
        touched={touched[FieldIds.Description]}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={ValidationLength.TEXT_FIELDS_MAX}
        dataTestId='lead-task-field-description'
      />

      <ActionBar
        buttonsClassName='lead-tasks-action-buttons'
        taskMode={taskMode}
        onSubmit={onSubmit}
        onCancel={onCancel}
        isLoading={isLoading}
        onDelete={onDelete}
      />
    </div>
  )
}

export default OpenTaskForm
