import type { ColumnDef } from '@carfluent/common/dist/UI'
import { formatCurrencyDecimal, formatDecimal } from '@carfluent/common'

import TextCellHOC from 'components/common/Table/cells/TextCell'
import TooltipCell from 'components/common/Table/cells/TooltipCell'

import type { ReportLeadBySourceItem } from 'api/types/crm.types'
import { PERCENTAGE_FORMAT } from './constants'

export const columns: Array<ColumnDef<ReportLeadBySourceItem>> = [
  {
    id: 'leadSource',
    size: 240,
    accessorFn: (row) => row.leadSource,
    loading: 'big_line',
    header: TextCellHOC({ text: 'Source' }),
    cell: TooltipCell
  },
  {
    id: 'newLeadsCount',
    size: 240,
    accessorFn: (row) => row.newLeadsCount,
    loading: 'big_line',
    header: TextCellHOC({ text: 'New leads' }),
    cell: TooltipCell
  },
  {
    id: 'appointmentsCount',
    size: 240,
    accessorFn: (row) => row.appointmentsCount,
    loading: 'big_line',
    header: TextCellHOC({ text: 'Appointments' }),
    cell: TooltipCell
  },
  {
    id: 'dealsSoldCount',
    size: 240,
    accessorFn: (row) => row.dealsSoldCount,
    loading: 'big_line',
    header: TextCellHOC({ text: 'Sold' }),
    cell: TooltipCell
  },
  {
    id: 'conversionRate',
    size: 240,
    accessorFn: (row) => formatDecimal(row.conversionRate * 100, PERCENTAGE_FORMAT),
    loading: 'big_line',
    header: TextCellHOC({ text: 'Conversion' }),
    cell: TooltipCell
  },
  {
    id: 'frontGross',
    size: 240,
    accessorFn: (row) => formatCurrencyDecimal(row.frontGross, { allowNegative: true }),
    loading: 'big_line',
    header: TextCellHOC({ text: 'Front gross' }),
    cell: (props) => <TooltipCell {...props} className='right-alignment' />
  },
  {
    id: 'backGross',
    size: 240,
    accessorFn: (row) => formatCurrencyDecimal(row.backGross, { allowNegative: true }),
    loading: 'big_line',
    header: TextCellHOC({ text: 'Back gross' }),
    cell: (props) => <TooltipCell {...props} className='right-alignment' />
  },
  {
    id: 'totalGross',
    size: 240,
    accessorFn: (row) => formatCurrencyDecimal(row.totalGross, { allowNegative: true }),
    loading: 'big_line',
    header: TextCellHOC({ text: 'Total gross' }),
    cell: (props) => <TooltipCell {...props} className='right-alignment' />
  }
]
