import { serializers as S } from '@carfluent/common'
import { toJS } from 'mobx'

import { isTruthy } from 'utils/general'
import _isPayable from 'utils/accounting/isPayable'
import _isReceivable from 'utils/accounting/isReceivable'
import _isCheck from 'utils/accounting/isCheck'
import { type EntityId, type TransactionLineRow } from 'types'
import {
  type LinePayload,
  type AddTransactionRequest,
  EntityTypeId,
  TransactionControlTypeId
} from 'api/types'

import { type TransactionFormData } from '../types'
import { isNotEmptyRow } from '../utils'

interface SerializeSpecialTransactionTypes {
  formData: TransactionFormData
  rows: TransactionLineRow[]
  dealId?: EntityId | null
  transactionTypeId?: number | null
  isPrint?: boolean
}

/**
 * Special transactions (Receive/Receivable/Check/PayBill/Payable)
 * always have the difference between total credit and total debit equal to `0`.
 * To achieve this we always create an "auto" line with an amount that is equal
 * to the `-1 * total_difference`.
 */
export const serializeSpecialTransaction = ({
  formData,
  rows,
  dealId,
  transactionTypeId: _transactionTypeId,
  isPrint = false
}: SerializeSpecialTransactionTypes): AddTransactionRequest => {
  const data = toJS(formData)
  const transactionTypeId = data.transactionTypeId ?? _transactionTypeId
  const lines = rows.map(item => toJS(item)).filter(isNotEmptyRow)
  const calculatedTransactionTypeId = transactionTypeId

  const isPayable = _isPayable(transactionTypeId)
  const dueDateValue = (isPayable || _isReceivable(transactionTypeId))
    ? S.serializeDate(data.dueDate)
    : null

  const calculatedCheckNumber = _isCheck(calculatedTransactionTypeId)
    ? (data.checkNumber != null && data.checkNumber !== '')
        ? Number(data.checkNumber)
        : null
    : null

  const payload = {
    date: S.serializeDate(data.dateTime) ?? '',
    dueDate: dueDateValue,
    description: data.description,
    referenceNumber: data.referenceNumber ?? null,
    lines: serializeLines(lines, formData, calculatedTransactionTypeId),
    transactionTypeId: calculatedTransactionTypeId,
    memo: data.memo ?? '',
    checkNumber: calculatedCheckNumber,
    isPrint
  }

  if (dealId != null) {
    return { ...payload, dealId }
  }

  return payload
}

const serializeLines = (
  lines: TransactionLineRow[],
  formData: TransactionFormData,
  transactionTypeId?: number | null
): LinePayload[] => {
  const {
    receivableAccount,
    receivableEntity,
    receivableVendor,
    receivableControl,
    receivableLineId
  } = formData

  const isPayable = _isPayable(transactionTypeId)
  let totalManualAmount = 0

  const manualLines = lines
    .filter((item) => item.isSystemCreated !== true)
    .map((line) => {
      const { entity, control, id } = line
      const isDealControl = control?.controlType === TransactionControlTypeId.Deal
      const isVehicleControl = control?.controlType === TransactionControlTypeId.Vehicle
      const isVendor = isPayable ? receivableVendor != null : entity?.entityType === EntityTypeId.Vendor
      const isCustomer = !isVendor
      const entityId = isPayable ? (receivableVendor?.id ?? null) : (entity?.id ?? null)
      const change = line.credits !== 0 ? -line.credits : line.debits

      totalManualAmount += change

      return {
        accountId: Number(line.account?.id ?? -1),
        change,
        control: isVehicleControl ? control.id : null,
        customerId: isCustomer ? entityId : null,
        dealId: isDealControl ? control.id : null,
        id: id ?? null,
        isSystemCreated: false,
        notes: isTruthy(line.notes) ? line.notes : null,
        reconciliationId: line.reconciliationId,
        vendorId: isVendor ? entityId : null
      }
    })

  const isDealControl = receivableControl?.controlType === TransactionControlTypeId.Deal
  const isVehicleControl = receivableControl?.controlType === TransactionControlTypeId.Vehicle
  const isVendor = (receivableEntity?.entityType === EntityTypeId.Vendor) ||
    (isPayable && (receivableVendor != null))

  const isCustomer = !isVendor
  const entityId = receivableEntity?.id ?? null

  const autoLine = {
    accountId: Number(receivableAccount?.id),
    change: Number(-totalManualAmount.toFixed(2)),
    customerId: isCustomer ? entityId : null,
    control: isVehicleControl ? Number(receivableControl.id) : null,
    dealId: isDealControl ? Number(receivableControl.id) : null,
    id: receivableLineId ?? null,
    isSystemCreated: true,
    notes: null,
    vendorId: isVendor
      ? isPayable ? (receivableVendor?.id ?? null) : entityId
      : null
  }

  return [autoLine, ...manualLines]
}
