import { css } from '@emotion/css'

export default css(`
  .navigation-block-container {
    position: relative;

    &.active .navigation-block-name {
      font-weight: 500;
    }

    > div {
      gap: 2px 8px;
      height: 100%;
      display: grid;
      padding: 10px 0;
      margin-bottom: 0;
      margin: 0px 16px;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: 
        "progress-bar section-name section-name"
        "progress-bar section-summary section-summary";
      grid-template-columns: 43px 1fr 1fr;
      border-bottom: 1px solid #0000001F;
    }

    :hover,
    &.active {
      background: #FAFAFA;

      :before {
        opacity: 1;
      }
    }

    :before {
      content: "";
      top: 0;
      left: 0;
      opacity: 0;
      width: 2px;
      height: 100%;
      background: black;
      position: absolute;
      transition: opacity 0.5s;
      border-radius: 0 2px 2px 0;
      -webkit-transition: opacity 0.5s;
    }
  }
  
  .navigation-block-progress-bar {
    height: 100%;
    max-height: 42px;
    grid-area: progress-bar;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 2;
  }

  .navigation-block-name {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: 2;
    grid-column-end: 4;
    grid-area: section-name;
    color: #101010;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .navigation-block-intake-inspection {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-row-end: 3;
    grid-column-end: 4;
    grid-area: section-summary;
    display: flex;
    align-items: center;

    > span {
      width: 41px;
      display: inline-grid;
      align-items: center;
      margin-right: 16px;
      grid-template-columns: auto 1fr;
      gap: 4px;
      color: #212121CC;
      font-size: 14px;
      line-height: 20px;
    }
  }
`)
