export interface DealerProductsDto {
  isCashPayment: boolean
  defaults: DealerDefaultDto[]
  penProducts: DealerPenProductDto[]
}

export interface DealerPenProducts {
  isCashPayment: boolean
  defaults?: DealerDefaultDto[]
  providers: CoverageProvidersDto[]
  penProducts: DealerPenProductDto[]
}

export interface DealerDefaultDto {
  id: number
  cost: number
  termMiles: number
  termMonths: number
  providerId: number
  totalPrice: number
  deductibleId: number
  productTypeId: number
  coverageName: string
  deductibleAmount: number
}

export interface CoverageProvidersDto {
  id: number
  name: string
}

export interface DealerPenProductDto {
  productTypeId: number
  providers: DealerProductProvidersDto[]
}

export interface DealerProductProvidersDto {
  id: number
  name: string
  packages: DealerProductPackageDto[]
}

export interface DealerProductPackageDto {
  packageName: string
  coverages: DealerProductCoverageDto[]
}

export interface DealerProductCoverageDto {
  id: number | string
  termMiles: string | null
  termMonths: string | null
  deductibles: DealerProductDeductibleDto[]
}

export interface DealerProductDeductibleDto {
  id: number
  amount: number
  dealerCost: number
  dealerRetailPrice: number
  description: string | null
}

export interface DealerProductCoverageTemplateDto {
  id: number
  name: string
  imageUrl: string
  imageUrlSmall: string
  description: string
  isSelected: boolean
}

export interface DealerProductCoveragePackageSettingsDto {
  packageName: string
  packageTypeId: CoveragePackageTypeIds
  productTypeIds: CoverageProductTypeIds[]
}

export enum CoveragePackageTypeIds {
  Platinum = 1,
  Gold = 2,
  Basic = 3
}

export enum CoverageProductTypeIds {
  ServiceContract = 1,
  GapInsurance,
  AppearanceProtection,
  TireAndWheel,
  Theft
}

export interface DealerProviderCoverageDto {
  id: number
  name: string
  coverages: DealerCoverageDto[]
}

export interface DealerCoverageDto {
  id: number
  termMiles: number
  termMonths: number
  coverageName: string
  productTypeId: number
}

export interface CoverageProductTypesDto {
  id: number
  name: string
}

export interface SettingsPackageTypesDto {
  packages: SettingsPackageType[]
}

export interface SettingsPackageType {
  name: string
  packageTypeId: number
}

export interface DealerDefaultCoverageDto {
  id?: number
  cost: number
  termMiles: number
  termMonths: number
  totalPrice: number
  providerId: number
  coverageName: string
  productTypeId: number
  deductibleAmount: number
  productCoverageId: number | null
}

export interface DealerProductCoverageMarkupDto {
  productTypeId: number
  markupFixed: number | null
  markupAmount: number | null
  markupPercent: number | null
}

export interface DealerProductCoverageMarkupsDto {
  markups: DealerProductCoverageMarkupDto[]
}
