import { css } from '@emotion/css'

export default css(`
  .cf-select-display-container {
    align-items: center;
    height: 36px;
    padding: 0 12px;

    > div {
      height: 24px;
      padding: 0;
    }

    .cf-display {
      font-style: normal;
      font-weight: 500;
      letter-spacing: 1.25px;
      text-transform: uppercase;
    }

    .cf-end-adornment {  margin-left: 8px; }
  }
`)

export const POPOVER_CLASS_NAME = css`
  .cf-dropdown-listbox {
    min-width: 240px;
  }

  &.cf-popover-content {
    .cf-option-item {
      &.cf-option-disabled {
        .cf-dropdown-option {
          opacity: 1;
        }
      }
    }
    
    .cf-dropdown-option {
      padding: 0 16px;
    }
    
    .dropdown-option {
      padding: 12px 0;
    }
    
    .group-name-option {
      color: rgba(33, 33, 33, 0.50);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding: 8px 0;
      font-size: 14px;
      line-height: 20px;
    }
  }
`
