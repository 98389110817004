import type { SortingInfo } from '@carfluent/common'
import type { InterestedLeadsListGetRequestDto } from 'api/types'

export const DEFAULT_SORTING: SortingInfo = { id: 'age', order: 'asc' }
export const DEFAULT_FILTERS: InterestedLeadsListGetRequestDto = {}

export const API_CALL_DELAY_SEARCH = 3000

export enum Messages {
  emptyTableState = 'No leads yet',
  nothingFoundState = 'No records found'
}
