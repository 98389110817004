import { css } from '@emotion/css'

export default css(`
  --field-name-width: 120px;
  
  &.review-screen-wrapper {
    padding-bottom: 80px;
  }
  
  .panels-list {
    display: grid;
    grid-template-areas:
      'top top'
      'statistics details';
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 16px;
  }
  
  .panel {
    border-radius: 12px;
    border: 1px solid var(--Outline, rgba(0, 0, 0, 0.12));
    padding: 16px;
    background-color: #FFF;
    
    width: 100%;
    min-height: 94px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .panel-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    
    .panel-content {
      display: grid;
      grid-template-columns: var(--field-name-width) minmax(0, 1fr);
      row-gap: 8px;
      column-gap: 8px;
    }
    
    .campaign-field-title {
      color: rgba(33, 33, 33, 0.50);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
    
    .campaign-field-value {
      color: #101010;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      max-width: 730px;

      &.bold {
        font-weight: 500;
      }
     
      .tooltiped-text {
        color: #101010;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
      }
    }
  }
  
  .panel.top {
    grid-area: top;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 56px;
    padding: 16px;
    gap: 24px;
    
    .campaign-title.block-skeleton.is-loading {
      min-width: 240px;
      max-width: 240px;
    }
    
    .campaign-status.block-skeleton.is-loading {
      min-width: 80px;
      max-width: 80px;
    }
    
    .left-part, .right-part {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    
    .left-part {
      gap: 16px;
      justify-content: flex-start;
    }
    
    .right-part {
      gap: 8px;
      justify-content: flex-end;
      
      &.block-skeleton.is-loading {
        min-width: 80px;
        max-width: 80px;
      } 
    }
    
    .cf-tooltip-container {
      width: fit-content;
      display: table; // for responsive ellipsis
      table-layout: fixed;
    }
    
    .tooltiped-text {
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 120% */
      letter-spacing: 0.15px;
      color: #101010;
    }
  }
  
  .panel.statistics {
    grid-area: statistics;
    height: min-content;
    
    .panel-title.block-skeleton.is-loading,
    .campaign-field-title.block-skeleton.is-loading {
      min-width: var(--field-name-width);
      max-width: var(--field-name-width);
    }
    
    .campaign-field-value.block-skeleton.is-loading {
      min-width: 64px;
      max-width: 64px;
    }
  }
  
  .panel.details {
    grid-area: details;
    
    .panel-title.block-skeleton.is-loading {
      min-width: 160px;
      max-width: 160px;
    }
    
    .campaign-field-title.block-skeleton.is-loading {
      min-width: var(--field-name-width);
      max-width: var(--field-name-width);
    }
    
    .campaign-field-value.block-skeleton.is-loading {
      min-width: 160px;
      max-width: 160px;
    }
    
    .empty-filters-info {
      width: 100%;
      flex: 1;
      min-width: 200px;
      color: rgba(33, 33, 33, 0.50);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
`)
