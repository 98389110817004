import type { FC } from 'react'

import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

import CLASS_NAME from './styles'

const LoaderSkeleton: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <div className='block-info'>
        <TextSkeleton />
        <TextSkeleton />
      </div>

      <div className='block-statistics'>
        <TextSkeleton />
        <TextSkeleton />
        <TextSkeleton />
      </div>
    </div>
  )
}

export default LoaderSkeleton
