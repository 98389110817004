import { useCallback, useState, useMemo } from 'react'
import { useModal, DropdownValueChangeReason, Dropdown2Props } from '@carfluent/common'

import appendTextLine from 'utils/common/appendTextLine'
import isCheck from 'utils/accounting/isCheck'
import { joinPartsBySpace, joinPartsToStr } from 'utils/view_helper'
import { type DropdownCellAction } from 'components/common/Table/cells/DictionaryCell'
import type {
  BankStatementTransactionMatchItem,
  CustomerDto,
  ControlListItem,
  EntityListItem,
  TransactionLineVendorDto
} from 'api/types'

import type { UseTransactionFormProps, UseTransactionFormReturn } from './types'
import { SpecialId } from './constants'

/**
 * WARNING: don't add any business logic to this hook.
 * It's for UI purposes only (like opened-closed popups).
 * All business logic should be added to the page's hook and
 * propagated here in props.
 */
export const useTransactionForm = ({
  onAddCustomer,
  onAddVendor,
  onCellChange,
  onChange,
  onViewNextTransaction,
  tableData,
  transactionTypeId = null,
  values
}: UseTransactionFormProps): UseTransactionFormReturn => {
  const vendorModal = useModal()
  const customerModal = useModal()
  const [lastRowIdx, setRowIdx] = useState<null | number>(null)
  const [lastColumnId, setColumnId] = useState<null | string>(null)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const handleResetState = useCallback(() => {
    setRowIdx(null)
    setColumnId(null)
  }, [])

  const handleAddCustomer = useCallback(async (data: CustomerDto) => {
    onAddCustomer(data, lastRowIdx, lastColumnId)
    customerModal.onCloseModal()
    handleResetState()

    const customerName = joinPartsBySpace(data.firstName, data.lastName)
    if (!isCheck(transactionTypeId)) {
      onChange('memo', appendTextLine(values.memo, customerName))
    }
  }, [
    onAddCustomer, lastRowIdx, lastColumnId,
    customerModal.onCloseModal, handleResetState,
    values.memo, onChange, transactionTypeId
  ])

  const handleAddVendor = useCallback(async (data: TransactionLineVendorDto) => {
    onAddVendor(data, lastRowIdx, lastColumnId)
    vendorModal.onCloseModal()
    handleResetState()

    const vendorName = joinPartsBySpace(data.name)
    if (!isCheck(transactionTypeId)) {
      onChange('memo', appendTextLine(values.memo, vendorName))
    }
  }, [
    onAddVendor, lastRowIdx, lastColumnId,
    vendorModal.onCloseModal, handleResetState,
    values.memo, transactionTypeId, onChange
  ])

  const openVendorModal = useCallback((rowIdx?: number | null, columnId?: string| null): void => {
    if (rowIdx != null && columnId != null) {
      setRowIdx(rowIdx)
      setColumnId(columnId)
    }

    vendorModal.onOpenModal()
  }, [vendorModal.onOpenModal])

  const openCustomerModal = useCallback((rowIdx?: number | null, columnId?: string| null): void => {
    if (rowIdx != null && columnId != null) {
      setRowIdx(rowIdx)
      setColumnId(columnId)
    }

    customerModal.onOpenModal()
  }, [customerModal.onOpenModal])

  // AZ-TODO: move to parent hook
  const updateCellInRow = useCallback((onChangeCell, cellId, value) => {
    tableData.forEach((item, index) => {
      if (item.disabled !== true) {
        onChangeCell(index, cellId, value)
      }
    })
  }, [tableData])

  // AZ-TODO: move to parent hook
  const onReceivableEntityChange = useCallback((
    id: string,
    option: TransactionLineVendorDto | EntityListItem | null,
    reason: DropdownValueChangeReason
  ) => {
    onChange(id, option ?? null)
    updateCellInRow(onCellChange, 'entity', option ?? null)

    if ((option != null) && (reason === 'select-option') && !isCheck(transactionTypeId)) {
      onChange('memo', appendTextLine(values.memo, option.name))
    }
  }, [
    onChange, onCellChange, updateCellInRow,
    values.memo, transactionTypeId
  ])

  // AZ-TODO: move to parent hook
  const onVendorChange = useCallback((
    id: string,
    option: TransactionLineVendorDto | null,
    reason: DropdownValueChangeReason
  ) => {
    const account = option?.account ?? null
    onChange(id, option ?? null)
    onChange('receivableAccount', account)

    if ((option != null) && (reason === 'select-option') && !isCheck(transactionTypeId)) {
      onChange('memo', appendTextLine(values.memo, option.name))
    }
  }, [onChange, transactionTypeId, values.memo])

  // AZ-TODO: move to parent hook
  const onReceivableControlChange = useCallback((
    id: string,
    option: ControlListItem | null,
    reason: DropdownValueChangeReason
  ) => {
    onChange(id, option)
    updateCellInRow(onCellChange, 'control', option ?? null)

    if ((option != null) && (reason === 'select-option') && !isCheck(transactionTypeId)) {
      const memoLine = joinPartsToStr(', ', option.name, joinPartsBySpace(option.year, option.make, option.model))
      onChange('memo', appendTextLine(values.memo, memoLine))
    }
  }, [onChange, onCellChange, updateCellInRow, values.memo, transactionTypeId])

  const onViewNextTransactionFromMatch = useCallback((match: BankStatementTransactionMatchItem) => {
    onViewNextTransaction?.(match.transactionId, match.transactionTypeId)
  }, [onViewNextTransaction])

  // ========================================== //

  const ENTITY_ACTIONS: Dropdown2Props<EntityListItem, false>['actions'] = useMemo(() => ([
    { id: SpecialId.AddCustomer, name: 'Add Customer', onClick: customerModal.onOpenModal },
    { id: SpecialId.AddVendor, name: 'Add Vendor', onClick: vendorModal.onOpenModal }
  ]), [customerModal.onOpenModal, vendorModal.onOpenModal])

  const ENTITY_CELL_ACTIONS: DropdownCellAction[] = useMemo(() => ([
    { id: SpecialId.AddCustomer, name: 'Add Customer', onClick: openCustomerModal },
    { id: SpecialId.AddVendor, name: 'Add Vendor', onClick: openVendorModal }
  ]), [openCustomerModal, openVendorModal])

  const VENDOR_ACTIONS: Dropdown2Props<TransactionLineVendorDto, false>['actions'] = useMemo(() => ([
    { id: SpecialId.AddVendor, name: 'Add Vendor', onClick: vendorModal.onOpenModal }
  ]), [vendorModal.onOpenModal])

  return {
    addCustomerProps: {
      isOpen: customerModal.isModalOpen,
      onClose: customerModal.onCloseModal,
      onSubmit: handleAddCustomer
    },
    addVendorProps: {
      isOpen: vendorModal.isModalOpen,
      onClose: vendorModal.onCloseModal,
      onSubmit: handleAddVendor
    },
    entityActions: ENTITY_ACTIONS,
    entityCellActions: ENTITY_CELL_ACTIONS,
    onReceivableEntityChange,
    onReceivableControlChange,
    onVendorChange,
    onViewNextTransactionFromMatch,
    vendorActions: VENDOR_ACTIONS
  }
}

export default useTransactionForm
