import { useCallback, useEffect, useRef, useState } from 'react'
import { arrayMove } from '@dnd-kit/sortable'
import { utils } from '@carfluent/common'
import { ImageState, type ImageDescriptor } from 'components/inventory/ImageItem'
import { type UploadedAssetWithThumbnailDto } from 'api/types'
import FilesApiProvider, { type FileWithUniqueName } from 'api/files.api'
import { type FormFilesUploadValue } from 'components/common/FormFilesUpload'
import getUniqueName from 'utils/getUniqueName'
import { parseAsset, parseAssets, parseFiles } from './parser'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

interface UsePhotosUploadProps {
  uploadedPhotos: UploadedAssetWithThumbnailDto[]
  onBeforeUpload?: () => void
  onAfterUpload?: () => void
  onAfterDelete?: () => void
  onAfterReorder?: () => void
}

interface UsePhotosUploadReturn {
  isPhotosUploading: boolean
  photos: ImageDescriptor[]
  uploadImageError: string
  setUploadImageError: (error: string) => void
  onUploadFiles: (filesToUpload: File[]) => Promise<void>
  onDeleteFile: (idx: number) => void
  onReorderPhotos: (activeIdx: number, overIdx: number) => void
  onResetPhotos: (photos: UploadedAssetWithThumbnailDto[]) => void
}

export const MAX_SIZE = 15 * utils.KiB * utils.KiB // 15MB
export const FILE_EXTENSIONS = ['jpeg', 'jpg', 'png']
export const EMPTY_IMAGE_VALUE: FormFilesUploadValue = []
export const MAX_FILE_NUMBER = 100

/*
* DD-TODO: replace old logic in useGalleryTab and useGallery with this new hook
* after those places are refactored to get rid of MobX and stores
*/

const usePhotosUpload = ({
  uploadedPhotos,
  onBeforeUpload,
  onAfterUpload,
  onAfterDelete,
  onAfterReorder
}: UsePhotosUploadProps): UsePhotosUploadReturn => {
  const [isPhotosUploading, setIsPhotosUploading] = useState(false)
  const [uploadImageError, setUploadImageError] = useState('')
  const [photos, setPhotos] = useState<ImageDescriptor[]>([])
  const refPhotos = useRef<ImageDescriptor[]>([])

  const { showAlert } = useCustomSnackbar()

  const setPhotosOnUploadResult = useRef((
    original: FileWithUniqueName,
    createItem: (prev: ImageDescriptor) => ImageDescriptor
  ): void => {
    setPhotos(prev => {
      const matchIdx = prev.findIndex(photo => photo.uniqueName === original.uniqueName)

      if (matchIdx > -1) {
        const res = [
          ...prev.slice(0, matchIdx),
          createItem(prev[matchIdx]),
          ...prev.slice(matchIdx + 1)
        ]

        refPhotos.current = res
        return res
      }
      return prev
    })
  }).current

  const onUploadFiles = useCallback(async (filesToUpload: File[]): Promise<void> => {
    try {
      setUploadImageError('')
      setIsPhotosUploading(true)
      onBeforeUpload?.()

      if (filesToUpload.length === 0) {
        setUploadImageError('Please upload files')
        return
      }

      const filteredFiles = filesToUpload.filter(({ size }) => {
        const isCorrectSize = size <= MAX_SIZE

        if (!isCorrectSize) {
          setUploadImageError('File size can\'t exceed 15MB.')
          return false
        }

        return isCorrectSize
      })

      const _filesToUpload = filteredFiles.map(file => ({ file, uniqueName: getUniqueName(file.name) }))

      const nextPhotos = [...refPhotos.current, ...parseFiles(_filesToUpload)]
      setPhotos(nextPhotos)
      refPhotos.current = nextPhotos

      const onFileUpload = (uploaded: UploadedAssetWithThumbnailDto, original: FileWithUniqueName): void => {
        setPhotosOnUploadResult(original, () => parseAsset(uploaded, original.file))
      }

      const onFileError = (original: FileWithUniqueName): void => {
        setPhotosOnUploadResult(original, (prev) => ({ ...prev, state: ImageState.Failed }))
      }

      await FilesApiProvider.uploadFilesInBatches(_filesToUpload, onFileUpload, onFileError)
    } catch (err) {
      console.error(err)
    } finally {
      setIsPhotosUploading(false)
      onAfterUpload?.()
    }
  }, [])

  const onDeleteFile = useCallback((idx: number): void => {
    showAlert('Image was deleted.', { variant: 'success' })

    setPhotos(prev => {
      const next = prev.slice(0, idx).concat(prev.slice(idx + 1))
      refPhotos.current = next

      if (next.length === 0) {
        setUploadImageError('Please upload files')
      }

      return next
    })
    onAfterDelete?.()
  }, [onAfterDelete, showAlert])

  const onReorderPhotos = useCallback((activeIdx: number, overIdx: number) => {
    setPhotos(prev => {
      const next = arrayMove(prev, activeIdx, overIdx)
      refPhotos.current = next
      return next
    })
    onAfterReorder?.()
  }, [onAfterReorder])

  const onResetPhotos = useCallback((photos: UploadedAssetWithThumbnailDto[]): void => {
    const parsed = parseAssets(photos, [])
    setIsPhotosUploading(false)
    setUploadImageError('')
    setPhotos(parsed)
    refPhotos.current = parsed
  }, [])

  useEffect(() => {
    const parsed = parseAssets(uploadedPhotos, [])
    setPhotos(parsed)
    refPhotos.current = parsed
  }, [uploadedPhotos])

  return {
    isPhotosUploading,
    photos,
    uploadImageError,
    setUploadImageError,
    onUploadFiles,
    onDeleteFile,
    onReorderPhotos,
    onResetPhotos
  }
}

export default usePhotosUpload
