import type { FC } from 'react'
import type { IconProps } from 'types'

const SwappedTiresIcon: FC<IconProps> = ({ color = '#21212180', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_86546_8154)'>
        <path d='M6.00073 10.7999L3.6674 8.46655C3.4074 8.20655 2.99406 8.20655 2.73406 8.46655C2.47406 8.72655 2.47406 9.13988 2.73406 9.39988L5.5274 12.1932C5.7874 12.4532 6.2074 12.4532 6.4674 12.1932L13.5341 5.13322C13.7941 4.87322 13.7941 4.45988 13.5341 4.19988C13.2741 3.93988 12.8607 3.93988 12.6007 4.19988L6.00073 10.7999Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_86546_8154'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SwappedTiresIcon
