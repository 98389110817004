import { WrapperRequest } from './wrapper.api'
import { DealId } from '../types'

// AZ-TODO: merge to CustomerCoreApi
class CustomerApi extends WrapperRequest {
  url = process.env.REACT_APP_AUTHORITY ?? ''

  getCustomerCoveragesUrl = async (id: DealId): Promise<string | null> => {
    return await this.get(`${this.url}/api/v1/Dealers/deal/${id}/coverages-url`)
  }
}

export const CustomerApiProvider = new CustomerApi()
export default CustomerApiProvider
