import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-wrap: wrap;

  img {
    width: 20px;
    height: 20px;

    &:first-of-type {
      margin: 0 auto;
    }

    &:first-of-type:not(:last-of-type) {
      margin: 0 0 0 auto;
    }

    &:nth-child(2) {
      margin-left: 4px;
      margin-right: auto;
    }

    &:nth-child(3) {
      margin: 4px auto 0 auto;
      width: 100%;
    }
  }
`)
