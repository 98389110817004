import { css } from '@emotion/css'

const EMPTY_PROGRESS = 0
const RED_PROGRESS = 50
const ORANGE_PROGRESS = 85
const FULL_PROGRESS = 100

const getProgressColor = (progress: number): string => {
  if (progress === FULL_PROGRESS) {
    return 'rgba(33, 150, 83, 1)'
  }

  if (progress > ORANGE_PROGRESS) {
    return 'rgba(255, 202, 40, 1)'
  }

  if (progress > RED_PROGRESS) {
    return 'rgba(255, 164, 81, 1)'
  }

  if (progress > EMPTY_PROGRESS) {
    return 'rgba(176, 0, 32, 1)'
  }

  return 'transparent'
}

export const createStylesCls = (progress: number): string => {
  return css`
    .progress-bar-circle-background {
      fill: white;
    }
    
    .progress-bar-circle-unfilled {
      fill: none;
      stroke: #e0e0e0;
      stroke-width: 4;
    }
  
    .progress-bar-circle-fill {
      fill: none;
      stroke: ${getProgressColor(progress)};
      stroke-width: 4;
      stroke-linecap: round;
      transition: stroke-dashoffset 0.4s ease;
      transform: rotate(-90deg);
      transform-origin: center;
    }
  
    .progress-bar-circle-text {
      fill: #000000;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
  `
}
