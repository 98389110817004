import type { FC } from 'react'
import { cn } from '@carfluent/common'
import { formatCurrencyDecimal } from 'utils/format_number'

export interface TrialBalanceAccount {
  number: number | string
  name: string
  start: number | null
  debits: number
  credits: number
  balance: number | null
}

export interface AccountRowProps {
  className?: string
  account: TrialBalanceAccount
}

const AccountRow: FC<AccountRowProps> = ({ className, account }) => {
  return (
    <tr className={cn('cf-account-row', className)}>
      <td className='cf-account-cell'>{account.number}</td>
      <td className='cf-name-cell'>{account.name}</td>
      <td className='cf-money-cell'>{formatCurrencyDecimal(account.start)}</td>
      <td className='cf-money-cell'>{formatCurrencyDecimal(account.debits)}</td>
      <td className='cf-money-cell'>{formatCurrencyDecimal(account.credits)}</td>
      <td className='cf-money-cell'>{formatCurrencyDecimal(account.balance)}</td>
    </tr>
  )
}

export default AccountRow
