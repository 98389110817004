import { type TransactionLineRow, type TransactionFormData } from 'types'
import isCheck from 'utils/accounting/isCheck'
import isDefaultJE from 'utils/accounting/isDefaultJE'
import transactionDifference from 'utils/accounting/transactionDifference'
import transactionTotalDebits from 'utils/accounting/transactionTotalDebits'
import transactionTotalCredits from 'utils/accounting/transactionTotalCredits'

import { isEmptyRow, isNotEmptyRow } from './utils'

export enum RowsErrorMessages {
  AtLeastOneLine = 'You need at least 1 line to create a transaction.',
  AtLeastTwoLines = 'You need at least 2 entries to create a transaction.',
  BothChanges = 'Cannot create line with value in both Credit and Debit. Please try again.',
  DebitCreditMismatch = 'Debits and credits must be equal.',
  EmptyAccount = 'Please select accounts for all lines.',
  NegativeCheckAmount = 'Unable to create check. Amount must be greater than zero.',
  ZeroChanges = 'Cannot create line with 0 Credit and 0 Debit. Please try again.',
  ZeroAmount = 'Amount cannot be zero.'
}

export const validateLinesTable = (
  values: TransactionFormData,
  rows: TransactionLineRow[],
  transactionTypeId?: number | null
): string | null => {
  return computeValidity({ values, rows, transactionTypeId }, [
    validateMinRowsNumber,
    validateJECreditsDebitsMatch,
    validateNetTotalNotZero,
    validateJEBothAmountsChanged,
    validateNotEmptyLinesAccount,
    validateNotEmptyLinesAmount,
    validateNegativeCheckAmount
  ])
}

export interface ValidatedData {
  values: TransactionFormData
  rows: TransactionLineRow[]
  transactionTypeId?: number | null
}

export type ValidationResult = string | null

export type ValidatorFn = (data: ValidatedData) => ValidationResult

/**
 * Validates `data` through list of `validators`, from left to right,
 * with early exit after first error.
 */
const computeValidity = (data: ValidatedData, validators: ValidatorFn[]): ValidationResult => {
  for (const v of validators) {
    const result = v(data)
    if (result != null) {
      return result
    }
  }

  return null
}

const validateMinRowsNumber = ({ rows, transactionTypeId }: ValidatedData): ValidationResult => {
  if (isDefaultJE(transactionTypeId) && (rows.length < 2)) {
    return RowsErrorMessages.AtLeastTwoLines
  } else if (!isCheck(transactionTypeId) && (rows.length < 1)) {
    return RowsErrorMessages.AtLeastOneLine
  }

  return null
}

/**
 * For default JE user should align credits with debits manually.
 */
const validateJECreditsDebitsMatch = ({ rows, transactionTypeId }: ValidatedData): ValidationResult => {
  if (isDefaultJE(transactionTypeId) && (transactionDifference(rows) !== 0)) {
    return RowsErrorMessages.DebitCreditMismatch
  }

  return null
}

const validateJEBothAmountsChanged = ({ rows, transactionTypeId }: ValidatedData): ValidationResult => {
  if (!isDefaultJE(transactionTypeId)) {
    return null
  }

  for (const row of rows) {
    if ((row.debits !== 0) && (row.credits !== 0)) {
      return RowsErrorMessages.BothChanges
    }
  }

  return null
}

const validateNotEmptyLinesAccount = ({ rows, transactionTypeId }: ValidatedData): ValidationResult => {
  for (const row of rows) {
    if (isNotEmptyRow(row) && (row.account == null)) {
      return RowsErrorMessages.EmptyAccount
    }
  }

  return null
}

const validateNetTotalNotZero = ({ rows, transactionTypeId }: ValidatedData): ValidationResult => {
  if (isCheck(transactionTypeId) && rows.every(isEmptyRow)) {
    return null
  }

  if ((transactionTotalDebits(rows) === 0) && (transactionTotalCredits(rows) === 0)) {
    return isDefaultJE(transactionTypeId) ? RowsErrorMessages.ZeroChanges : RowsErrorMessages.ZeroAmount
  }

  return null
}

const validateNotEmptyLinesAmount = ({ rows, transactionTypeId }: ValidatedData): ValidationResult => {
  for (const row of rows) {
    if (isNotEmptyRow(row) && (row.debits === 0) && (row.credits === 0)) {
      return isDefaultJE(transactionTypeId) ? RowsErrorMessages.ZeroChanges : RowsErrorMessages.ZeroAmount
    }
  }

  return null
}

const validateNegativeCheckAmount = ({ rows, transactionTypeId }: ValidatedData): ValidationResult => {
  if (!isCheck(transactionTypeId)) {
    return null
  }

  for (const row of rows) {
    if (row.debits < 0) {
      return RowsErrorMessages.NegativeCheckAmount
    }
  }

  return null
}
