import { type FormValidation } from '@carfluent/common'
import { type CoverageDefaultSection } from 'types/coverage'

import { warrantyRequiredFieldFISettings } from 'utils/validationPresets'
type ValidationResult = FormValidation<CoverageDefaultSection[], never>

export const DEPENDENT_VALIDATIONS = {
  '[*].forms.PEN': [
    '[*].forms.PEN.termMonths',
    '[*].forms.PEN.coverageName',
    '[*].forms.PEN.deductibleAmount',
    '[*].forms.PEN.cost',
    '[*].forms.PEN.totalPrice'

  ]
}

export const validationRules: ValidationResult = ({
  // --- PEN Coverage --- //
  '[*].forms.PEN.coverageName': warrantyRequiredFieldFISettings('PEN', 'coverageName'),
  '[*].forms.PEN.termMonths': warrantyRequiredFieldFISettings('PEN', 'termMonths'),
  '[*].forms.PEN.deductibleAmount': warrantyRequiredFieldFISettings('PEN', 'deductibleAmount'),
  '[*].forms.PEN.cost': warrantyRequiredFieldFISettings('PEN', 'cost'),
  '[*].forms.PEN.totalPrice': warrantyRequiredFieldFISettings('PEN', 'totalPrice'),

  // --- Manual Coverage --- //
  '[*].forms.Manual.coverageName': warrantyRequiredFieldFISettings('Manual', 'coverageName'),
  '[*].forms.Manual.termMonths': warrantyRequiredFieldFISettings('Manual', 'termMonths'),
  '[*].forms.Manual.deductibleAmount': warrantyRequiredFieldFISettings('Manual', 'deductibleAmount'),
  '[*].forms.Manual.cost': warrantyRequiredFieldFISettings('Manual', 'cost'),
  '[*].forms.Manual.totalPrice': warrantyRequiredFieldFISettings('Manual', 'totalPrice')
})
