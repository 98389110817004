import type { RefObject } from 'react'
import { useEffect, useRef, useState, useCallback } from 'react'

const MAX_LINES = 3
const LINE_HEIGHT = 24
const DEFAULT_HEIGHT = 0

interface UseTextEllipsisReturn {
  isCollapsed: boolean | null
  lines: number | null
  maxLines: number
  lineHeight: number
  onClick: () => void
  wrapperEl: RefObject<HTMLDivElement>
  contentEl: RefObject<HTMLDivElement>
  isWithEllipse: boolean
}

export interface UseTextEllipsisProps {
  lineHeight?: number
  maxLines?: number
  isCollapsible?: boolean
  initiallyCollapsed?: boolean
}

export const useTextEllipsis = ({
  maxLines = MAX_LINES,
  lineHeight = LINE_HEIGHT,
  isCollapsible = true,
  initiallyCollapsed = false
}: UseTextEllipsisProps): UseTextEllipsisReturn => {
  const initializedRef = useRef(false)
  const wrapperEl = useRef<HTMLDivElement | null>(null)
  const contentEl = useRef<HTMLDivElement | null>(null)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const [lines, setLines] = useState<number | null>(null)
  const [isWithEllipse, setIsWithEllipse] = useState(false)

  const onClick = useCallback(() => {
    if (isCollapsible) {
      setIsCollapsed(prevIsCollapsed => !prevIsCollapsed)
    }
  }, [isCollapsible])

  useEffect(() => {
    const { height = DEFAULT_HEIGHT } = wrapperEl.current?.getBoundingClientRect() ?? {}
    const { height: contentHeight = DEFAULT_HEIGHT } = contentEl.current?.getBoundingClientRect() ?? {}

    if (contentHeight > height) {
      setLines(maxLines)
      setIsCollapsed(false)
      setIsWithEllipse(true)
    } else {
      setLines(Math.round(contentHeight / lineHeight))
    }
  }, [maxLines, lineHeight])

  useEffect(() => {
    if (!initializedRef.current) {
      initializedRef.current = true
      setIsCollapsed(initiallyCollapsed)
    }
  }, [initiallyCollapsed])

  return {
    isCollapsed,
    lines,
    maxLines,
    lineHeight,
    onClick,
    wrapperEl,
    contentEl,
    isWithEllipse
  }
}
