import { useCallback, useEffect, useMemo, useState } from 'react'

import type { RegistrationStage, TitleStage } from 'types/enums'
import { type TitleReportItemGetDto, type TitleReportsRequestDto, DictionaryItems, VehicleState } from 'api/types'
import VehiclesApiProvider from 'api/vehicles.api'
import { GET_TABLE_MIN_HEIGHT, asyncNoop, PAGE_SIZE } from 'constants/constants'
import useTableApi from 'hooks/useTableApi'
import useAsyncEffect from 'hooks/useAsyncEffect'
import { calcTableHeight } from 'utils/math'
import { downloadBlob } from 'utils/general'
import { getPageOverlayContainer } from 'utils/html'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

import {
  Messages,
  DEFAULT_FILTERS,
  DEFAULT_SORTING,
  REPORT_FILE_NAMES,
  API_CALL_DELAY_SEARCH
} from './constants'
import parseListData from './parser'
import serializeFilters from './serializer'
import { groupByMake } from './utils'
import type { TitleReportItem, UseReportBalanceSheetReturn } from './types'
import { REGISTRATION_STAGE_NOT_SELECTED_ID } from '../components/Filters/constants'

const TABLE_MIN_HEIGHT = GET_TABLE_MIN_HEIGHT(PAGE_SIZE, 56.5, 0)

export const useTitleReport = (): UseReportBalanceSheetReturn => {
  const { showAlert } = useCustomSnackbar()
  const [titleStages, setTitleStages] = useState<DictionaryItems<TitleStage> | null>(null)
  const [registrationStages, setRegisterStages] = useState<DictionaryItems<RegistrationStage> | null>(null)
  const [makesAmount, setMakesAmount] = useState<Record<string, number>>({})
  const containerElement = getPageOverlayContainer()

  const getTitleReports = useCallback(async (payload: TitleReportsRequestDto) => {
    const [reportLeadsByVehicles, makesAmount] = await Promise.all([
      VehiclesApiProvider.getTitleReports(payload),
      VehiclesApiProvider.getMakesAmount({
        search: payload.search ?? '',
        titleStageIds: payload.titleStageIds,
        registrationStageFilter: payload.registrationStageFilter,
        vehicleStateIds: [VehicleState.Active, VehicleState.Inactive, VehicleState.Sold]
      })
    ])

    setMakesAmount(makesAmount.items.reduce((acc, item) => ({
      ...acc,
      [item.make]: item.amount
    }), {}))

    return reportLeadsByVehicles
  }, [])

  const {
    rows,
    search,
    sorting,
    loadRows,
    onSearch,
    isLoading,
    setSearch,
    setSorting,
    setFilters,
    emptyTableMessage,
    filters
  } = useTableApi<TitleReportItemGetDto, TitleReportsRequestDto, TitleReportItem>({
    containerElement,
    parseListData: (data: TitleReportItemGetDto[]) => parseListData(data, titleStages, registrationStages),
    defaultSorting: DEFAULT_SORTING,
    defaultFilters: DEFAULT_FILTERS,
    getList: getTitleReports,
    apiCallDelay: API_CALL_DELAY_SEARCH,
    serializeFilters,
    emptyTableMessage: Messages.emptyTableState,
    nothingFoundMessage: Messages.nothingFoundState
  })

  const groupBy = useMemo(() => groupByMake(makesAmount), [makesAmount])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onBottomReached = useCallback(async () => {
    if (!isLoading && TABLE_MIN_HEIGHT <= calcTableHeight(rows.length)) {
      await loadRows({ forceRefresh: false })
    }
  }, [isLoading, loadRows, rows.length])

  const onExportExcel = async (): Promise<void> => {
    try {
      const res = await VehiclesApiProvider.downloadTitleReportXlsx(serializeFilters(filters))
      downloadBlob(res, REPORT_FILE_NAMES.xlsx)
    } catch (err) {
      showAlert(err)
    }
  }

  const onTitleStageChange = (titleStageIds: Array<number | null>): void => {
    void setFilters({ titleStageIds })
  }

  const onRegistrationStageChange = (registrationStageIds: Array<number | null>): void => {
    void setFilters({
      registrationStageFilter: {
        noRegistrationStage: registrationStageIds.includes(REGISTRATION_STAGE_NOT_SELECTED_ID),
        registrationStageIds
      }
    })
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (titleStages !== null && registrationStages !== null) {
      void loadRows()
    }
  }, [loadRows, titleStages, registrationStages])

  useAsyncEffect(async () => {
    const [titleStage, registrationStage] = await Promise.all([
      VehiclesApiProvider.getTitleStages(),
      VehiclesApiProvider.getRegistrationStages()
    ])

    setTitleStages(titleStage.items)
    setRegisterStages(registrationStage.items)
  }, [])

  // ========================================== //

  return {
    containerElement,
    rows,
    filters: {
      search,
      onSearch,
      titleStages,
      registrationStages,
      onTitleStageChange,
      onSearchChange: setSearch,
      onRegistrationStageChange,
      titleStageIds: filters.titleStageIds ?? null,
      registrationStageIds: filters.registrationStageFilter?.registrationStageIds ?? null
    },
    groupBy,
    sorting,
    onBottomReached,
    emptyTableMessage,
    onSortingChange: setSorting,
    onExportExcel: onExportExcel,
    onChangeDatesFilter: asyncNoop,
    isLoading: isLoading || (titleStages === null) || (registrationStages === null)
  }
}
