import { Vehicle, VehicleState } from 'api/types'
import { GET_DEFAULT_VEHICLE } from 'api/defaults'
import type { CarOfInterest, VideoDataProps } from './types'

const carToVehicle = (car: CarOfInterest): Vehicle => ({
  ...GET_DEFAULT_VEHICLE(),
  age: car.age,
  engine: car.engine,
  genericColor: car.genericColor,
  genericInterior: car.genericInterior,
  id: car.vehicleId ?? car.id,
  imageCount: 0,
  isDeposited: false,
  fuelType: car.fuelType,
  mainImageUrl: car.mainImageUrl,
  make: car.make,
  mileage: car.mileage,
  model: car.model,
  salePrice: car.salePrice,
  stock: car.stock,
  tags: [],
  transmissionType: car.transmissionType,
  trim: car.trim,
  vehicleState: VehicleState[car.vehicleState as keyof typeof VehicleState],
  vin: car.vin,
  year: car.year
})

export const extractVideoData = (body: string): VideoDataProps | null => {
  const videoUrlMatch = body.match(/<a href="(.+?)".*?class="insert-video-link".*?>/)
  const posterUrlMatch = body.match(/background-image: url\(&quot;(.+?)&quot;\)/)

  if ((videoUrlMatch != null) && (posterUrlMatch != null)) {
    return {
      videoUrl: videoUrlMatch[1],
      posterUrl: posterUrlMatch[1]
    }
  }

  return null
}

export default carToVehicle
