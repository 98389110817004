import { type FC } from 'react'
import { Modal, FormInput, FormMaskedInput, Banner } from '@carfluent/common'

import { COMMON_MODAL_FORM_CLS, MODAL_MIN_WIDTH } from 'constants/constants'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import DeleteEntityButton from 'components/dialogs/DeleteEntityButton'
import { ValidationLength } from 'constants/validation'
import AddressFields from 'components/common/AddressFields'
import IconSVG from 'components/inlineImages'

import type { UseLienholderProps } from './hook/types'
import useLienholder from './hook/index'
import CLASS_NAME from './styles'

export type CustomerDetailsProps = UseLienholderProps

const LienholderDetails: FC<CustomerDetailsProps> = (props) => {
  const {
    isLoading,
    isOpen,
    isEdit,
    values,
    errors,
    touched,
    isBannerErrorVisible,
    onClose,
    onSubmit,
    onChange,
    onBlur,
    onDelete
  } = useLienholder(props)

  const renderFooter = (): JSX.Element => {
    const renderDeleteButton = (): JSX.Element => (
      <DeleteEntityButton
        isDisabled={isLoading}
        onConfirm={onDelete}
        titleButton='REMOVE LIENHOLDER'
        titleWindow='Remove lienholder?'
      />
    )

    return (
      <CancelSubmitActionsFooter
        isLoading={isLoading}
        onSubmit={onSubmit}
        onClose={onClose}
        renderDeleteButton={isEdit ? renderDeleteButton : undefined}
        submitTitle={isEdit ? 'SAVE' : 'CREATE'}
      />
    )
  }

  return (
    <Modal
      className={CLASS_NAME}
      isOpen={isOpen}
      onClose={onClose}
      minWidth={MODAL_MIN_WIDTH}
      renderFooterActions={renderFooter}
      title='Lienholder'
    >
      {isBannerErrorVisible && (
        <Banner
          message={<p>You cannot delete a lienholder as it has already been used <br /> in the deal.</p>}
          type='error'
          isOpen
          startAdornment={(
            <IconSVG.Lock
              opacity={1}
              width={24}
              height={24}
              color='rgba(176, 0, 32, 1)'
            />
          )}
        />
      )}
      <div className='input-wrapper'>
        <FormInput
          value={values.name}
          error={errors.name}
          touched={touched.name}
          id='name'
          label='Name'
          onChange={onChange}
          onBlur={onBlur}
          preset='withoutCyrillic'
        />
      </div>

      <div className={COMMON_MODAL_FORM_CLS}>

        <FormMaskedInput
          id='phoneNumber'
          value={values.phoneNumber}
          error={errors.phoneNumber}
          touched={touched.phoneNumber}
          label='Phone number (optional)'
          onChange={onChange}
          onBlur={onBlur}
          mask='phone'
        />

        <FormInput
          value={values.email}
          error={errors.email}
          touched={touched.email}
          id='email'
          label='Email (optional)'
          maxLength={ValidationLength.EMAIL_MAX}
          onChange={onChange}
          onBlur={onBlur}
        />

        <FormInput
          value={values.corpCode}
          error={errors.corpCode}
          touched={touched.corpCode}
          id='corpCode'
          label='Corp code (optional)'
          maxLength={ValidationLength.CORP_CODE}
          onChange={onChange}
          onBlur={onBlur}
          preset='withoutCyrillic'
        />

        <AddressFields
          id='address'
          label='Address (optional)'
          values={values.address}
          errors={errors.address}
          touched={touched.address}
          onChange={onChange}
          onBlur={onBlur}
          shouldHideApt
        />
      </div>
    </Modal>
  )
}

export default LienholderDetails
