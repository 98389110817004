import type { ColumnDef } from '@carfluent/common/dist/UI'

import type { AccountOrInvite, DictionaryItem } from 'api/types'
import { VIEW_DEALER_ROLE_NAMES } from 'constants/constants'
import ValueCell from 'components/common/Table/cells/ValueCell'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import NameCell from 'components/common/Table/cells/NameCell'
import PhoneCell from 'components/common/Table/cells/PhoneCell'
import ConditionalCellHOK from 'components/common/Table/cells/conditional_cell'
import RemoveRowCellHOK from 'components/common/Table/cells/remove_row_cell'

import { isInviteGuard } from './hook/helpers'

const getColumnDefinitions = (
  dealers: DictionaryItem[],
  onDeleteInvite: (i: number) => void
): Array<ColumnDef<AccountOrInvite>> => {
  const columns: Array<ColumnDef<AccountOrInvite>> = [
    {
      accessorFn: (row: AccountOrInvite) => {
        return isInviteGuard(row)
          ? { name: 'Invited user', className: 'cf-invite-cell' }
          : { name: row.name, showAvatar: true, className: 'cf-account-cell' }
      },
      id: 'name',
      cell: NameCell,
      header: TextCellHOC({ text: 'Name' }),
      minSize: 260,
      maxSize: 396,
      sortable: false
    },
    {
      accessorKey: 'email',
      cell: ValueCell,
      header: TextCellHOC({ text: 'Email' }),
      minSize: 260,
      maxSize: 396,
      sortable: false
    },
    {
      accessorKey: 'phoneNumber',
      cell: PhoneCell,
      header: TextCellHOC({ text: 'Phone number' }),
      minSize: 200,
      maxSize: 200,
      sortable: false
    },
    {
      accessorKey: 'dealerRole',
      cell: ValueCell,
      accessorFn: (row: AccountOrInvite) => row.dealerRole != null ? VIEW_DEALER_ROLE_NAMES[row.dealerRole] : '',
      header: TextCellHOC({ text: 'Role' }),
      minSize: 178,
      maxSize: 396,
      sortable: false
    }
  ]

  if (dealers.length > 1) {
    columns.push({
      accessorFn: (row: AccountOrInvite) => {
        const dealerIds = row.dealerIds
        const dealerNames = dealerIds.map((id: number) => {
          const foundDealer = dealers.find(dealer => dealer.id === id)
          return (foundDealer != null) ? foundDealer.name : null
        }).filter((name): name is string => Boolean(name))

        const count = dealerNames.length
        if (count === dealers.length) return 'All'
        if (count === 0) return ''
        if (count === 1) return dealerNames[0]
        if (count === 2) return `${dealerNames[0]}, 1+`
        return `${dealerNames[0]}, ${count - 1}+`
      },
      id: 'dealership',
      cell: ValueCell,
      header: TextCellHOC({ text: 'Dealership' }),
      minSize: 180,
      maxSize: 180,
      sortable: false
    })
  }

  columns.push({
    accessorKey: 'id',
    cell: ConditionalCellHOK<AccountOrInvite>(
      (data) => (!isInviteGuard(data.row.original)),
      RemoveRowCellHOK({ onRemoveRow: onDeleteInvite, disabled: true }),
      RemoveRowCellHOK({ onRemoveRow: onDeleteInvite, disabled: false })
    ),
    header: TextCellHOC({ text: '' }),
    sortable: false,
    loading: 'no-skeleton',
    size: 56
  })
  return columns
}

export default getColumnDefinitions
