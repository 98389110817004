import { type WebsiteChatModel } from 'api/types/responses'
import { type BotMetadataDto, type WebsiteChatSettingsDto } from 'api/types/responses/DTOs/crm/WebsiteChatSettingsDto'

const parseWebsiteChatDto = (apiData: WebsiteChatSettingsDto): WebsiteChatModel => {
  return {
    botEnabled: apiData.botEnabled,
    botMetadata: apiData.botMetadata.reduce<{ [questionTypeId: string]: BotMetadataDto[] }>((acc, question) => {
      const questionTypeId = question.botQuestionTypeId.toString()
      return {
        ...acc,
        [questionTypeId]: [
          ...(acc[questionTypeId] ?? []),
          question
        ]
      }
    }, {})
  }
}

export default parseWebsiteChatDto
