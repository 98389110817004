import { css } from '@emotion/css'

interface PopoverStylesProps {
  maxWidth?: number
}

export default ({ maxWidth }: PopoverStylesProps) => css(`
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  .item {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    color: #101010;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    gap: 8px;

    &:hover {
      background-color: #FAFAFA;
      cursor: pointer;
    }

    &.active {
      font-weight: 500;
    }

    span {
      color: rgba(0, 0, 0, 0.30);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .cf-popper {
    width: 256px;
    color: inherit;
  }

  .cf-item-block {
    padding-left: 4px;

    p {
      ${maxWidth != null ? `max-width: ${maxWidth - 80}px;` : ''}
    }
  }
`)

export const createPopoverStyles = (): string => css(`
  padding: 24px;
`)
