import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from '@carfluent/common'
import { addHours, format, parse, differenceInMinutes } from 'date-fns'

import { ViewDealerRoles, type User } from 'api/types'
import IdentityApiProvider from 'api/identity.api'
import { TIME_12H_MIN_AMPM_FORMAT } from 'constants/constants'
import { isValidDate } from 'utils/validation'

import {
  FieldIds,
  ShiftDuration,
  type ShiftDetailsFormData,
  type UseShiftDetailsModalReturn,
  type ErrTouchShortcuts,
  type UseShiftDetailsModalProps
} from './types'
import validationRules from './validator'
import { checkLessThanHourDifference, transformShiftToFormData, transformUsersToOptions } from './utils'
import { serializeCreateShift, serializeUpdateShift } from './serializer'
import getShiftInitialValues from './constants'

const useShiftDetailsModal = ({
  shift,
  isOpen,
  initialDate,
  shiftDetailsSubmit,
  onRemoveShift
}: UseShiftDetailsModalProps): UseShiftDetailsModalReturn => {
  const [persons, setPersons] = useState<User[]>([])

  const shiftId = shift?.id

  const submitAction = useCallback(async (values: ShiftDetailsFormData): Promise<void> => {
    if (shiftId != null) {
      await shiftDetailsSubmit(serializeUpdateShift(values, shiftId))
    } else {
      await shiftDetailsSubmit(serializeCreateShift(values))
    }
  }, [shiftDetailsSubmit, shiftId])

  const baseValues = useMemo(() =>
    getShiftInitialValues(initialDate != null ? initialDate : new Date()),
  [initialDate])

  const {
    isSubmitting,
    values,
    errors,
    touched,
    onBlur,
    onChange,
    onSubmit,
    resetForm,
    isFormChanged
  } = useForm<ShiftDetailsFormData, ErrTouchShortcuts>({
    baseValues,
    validationRules,
    submitAction
  })

  const resolvedDate = useMemo(() =>
    parse(values.startTime, TIME_12H_MIN_AMPM_FORMAT, isValidDate(values.date) ? values.date : initialDate ?? new Date()),
  [values.startTime, values.date, initialDate])
  const personOptions = transformUsersToOptions(persons)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onRemoveShiftClick = (): void => {
    if (shiftId == null) {
      return
    }

    void onRemoveShift(shiftId)
  }

  const onChangeTime = (val: string): void => {
    const newStartTime = parse(val, TIME_12H_MIN_AMPM_FORMAT, resolvedDate)
    const currentEndTime = parse(values.endTime, TIME_12H_MIN_AMPM_FORMAT, resolvedDate)

    // Calculate the minimum end time as 1 hour after the start time
    let adjustedEndTime = addHours(newStartTime, 1)
    const isLessThanHourDifference = checkLessThanHourDifference(newStartTime, currentEndTime)

    // If the new start time is greater or less than one hour than the current end time, set end time to full day duration
    if (newStartTime > currentEndTime || isLessThanHourDifference) {
      adjustedEndTime = addHours(newStartTime, ShiftDuration.fullDay)
    }

    if (currentEndTime < adjustedEndTime) {
      const newEndTime = format(adjustedEndTime, TIME_12H_MIN_AMPM_FORMAT)
      onChange(FieldIds.EndTime, newEndTime.toUpperCase())
    }

    onChange(FieldIds.StartTime, val.toUpperCase())
  }

  /**
  * By requirements, we need to show values in dropdown in the next format: 1h, 1.25h, 1.5h, 1.75h
  */
  const countHours = (endDate: Date): string => {
    const difference = differenceInMinutes(endDate, resolvedDate) / 60
    return difference.toFixed(2).replace(/0+$/, '').replace(/\.$/, '')
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      try {
        const { items } = await IdentityApiProvider.getUsers([ViewDealerRoles.Sales, ViewDealerRoles.BDC])

        setPersons(items)
      } catch (err) {
        console.error(err)
      }
    }
    void runEffect()
  }, [])

  useEffect(() => {
    if (!isOpen) {
      return
    }

    if (shift != null) {
      const transformedShiftData = transformShiftToFormData(shift)

      resetForm(transformedShiftData)
    } else {
      /**
       * when we do not close the add shift form, and click on the other add shift button
       * actually when initialDate is changed, we need to reset the form
       */
      resetForm(baseValues)
    }

    return () => {
      resetForm(baseValues)
    }
  }, [isOpen, shift, initialDate, resetForm])

  return {
    isFormChanged,
    isSubmitting,
    values,
    errors,
    touched,
    onBlur,
    onChange,
    onSubmit,
    countHours,
    onChangeTime,
    onRemoveShiftClick,
    options: personOptions
  }
}

export default useShiftDetailsModal
