import type { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { cnx } from '@carfluent/common'

import IconButton from 'components/common/IconButton'
import Icon from 'components/common/Icon'
import ActionsMenu, { type MenuButtonProps } from 'components/common/ActionsMenu'

import CarInfo from './components/CarInfo'

import useCarsOfInterest, { type UseCarsOfInterestProps } from './hook'
import CLASS_NAME from './styles'

export interface CarsOfInterestProps extends UseCarsOfInterestProps {
  className?: string
  linkToWebsite: string
}

const CarsOfInterest: FC<CarsOfInterestProps> = ({
  className,
  linkToWebsite,
  ...hookProps
}) => {
  const {
    cars,
    currentCarIndex,
    hasCars,
    onDeleteCar,
    onMakePrimaryCar,
    onShowNextCar,
    onShowPrevCar,
    onCopy,
    carToRender,
    actionList
  } = useCarsOfInterest(hookProps)

  return (
    <div className={cnx(CLASS_NAME, 'container', className)}>
      <div className='section-title'>
        {carToRender != null && (
          <div className='title-text'>
            {carToRender.year} {carToRender.make} {carToRender.model} {carToRender.trim}
            {carToRender.isPrimary && <Icon name='crownYellow' className='icon-crown'/>}
          </div>
        )}

        {cars.length > 1 && (
          <div className='title-switcher'>
            <IconButton
              isDisabled={currentCarIndex === 0}
              name='previous'
              onClick={onShowPrevCar}
            />

            <span>{currentCarIndex + 1}/{cars.length}</span>

            <IconButton
              isDisabled={currentCarIndex === cars.length - 1}
              name='next'
              onClick={onShowNextCar}
            />

            <ActionsMenu
              actions={actionList}
              className='more-actions'
              MenuButtonComponent={MenuButton}
            />
          </div>
        )}
      </div>

      {!hasCars && (
        <div className='no-car'>No car of interest</div>
      )}

      {carToRender != null && (
        <div className='car-carousel'>
          <CarInfo
            key={carToRender.id}
            car={carToRender}
            linkToWebsite={linkToWebsite}
            onDelete={onDeleteCar}
            onMakePrimary={onMakePrimaryCar}
            onCopyCarLink={onCopy}
          />
        </div>
      )}
    </div>
  )
}

export default observer(CarsOfInterest)

const MenuButton: FC<MenuButtonProps> = (props) => (
  <IconButton name='moreVertical' {...props} />
)
