import { formatInteger } from '@carfluent/common'
import type { CoverageTerm } from 'types/coverage'
import { type DealerProductCoverageDto } from 'api/types/responses'

const getTermDescription = (item: DealerProductCoverageDto | CoverageTerm | null): string => {
  return (item?.termMonths == null) || (item?.termMiles == null)
    ? ''
    : `${item.termMonths} mo / ${formatInteger(item.termMiles)} mi`
}

export default getTermDescription
