import type { FC, MouseEvent, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { CellWrapper } from '@carfluent/common'

import CLASS_NAME from './styles'
import type { CellProps } from '../types'

export interface LoadingValueCellProps extends CellProps {
  getValue: () => ReactNode
}

const ControlCell: FC<LoadingValueCellProps> = (props) => {
  const row = props.row.original
  const isControl = row.control != null

  const handleLinkClick = (event: MouseEvent<Element>): void => {
    event?.stopPropagation()
  }

  const controlId = String(row.control)
  const dealId = String(row.dealId)
  const linkUrl = isControl ? `/vehicles/${controlId}` : `/deals/${dealId}`
  const displayValue = props.getValue() != null && (props.getValue() ?? '-')

  return (
    <CellWrapper className={CLASS_NAME}>
      <Link to={linkUrl} onClick={handleLinkClick} className='link'>
        <p className='link-text'>{displayValue}</p>
      </Link>
    </CellWrapper>
  )
}

export default ControlCell
