import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import type { IconProps } from 'types'

const IconSVGLeads: FC<IconProps> = ({
  width = 32,
  height = 32,
  className
}) => {
  return (
    <svg
      className={cnx(className, 'cf-icon')}
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width={width} height={height} rx='8' fill='#FAFAFA' />
      <g clipPath='url(#clip0_81842_236158)'>
        <path fillRule='evenodd' clipRule='evenodd' d='M19.8916 16.9414C21.0333 17.7164 21.8333 18.7664 21.8333 20.1664V22.6664H24.3333C24.7916 22.6664 25.1666 22.2914 25.1666 21.8331V20.1664C25.1666 18.3497 22.1916 17.2747 19.8916 16.9414Z' fill='#101010' />
        <path d='M13.4993 15.9987C15.3403 15.9987 16.8327 14.5063 16.8327 12.6654C16.8327 10.8244 15.3403 9.33203 13.4993 9.33203C11.6584 9.33203 10.166 10.8244 10.166 12.6654C10.166 14.5063 11.6584 15.9987 13.4993 15.9987Z' fill='#101010' />
        <path fillRule='evenodd' clipRule='evenodd' d='M18.4999 15.9987C20.3416 15.9987 21.8333 14.507 21.8333 12.6654C21.8333 10.8237 20.3416 9.33203 18.4999 9.33203C18.1083 9.33203 17.7416 9.41536 17.3916 9.53203C18.0833 10.3904 18.4999 11.482 18.4999 12.6654C18.4999 13.8487 18.0833 14.9404 17.3916 15.7987C17.7416 15.9154 18.1083 15.9987 18.4999 15.9987Z' fill='#101010' />
        <path fillRule='evenodd' clipRule='evenodd' d='M13.4997 16.832C11.2747 16.832 6.83301 17.9487 6.83301 20.1654V21.832C6.83301 22.2904 7.20801 22.6654 7.66634 22.6654H19.333C19.7913 22.6654 20.1663 22.2904 20.1663 21.832V20.1654C20.1663 17.9487 15.7247 16.832 13.4997 16.832Z' fill='#101010' />
      </g>
      <defs>
        <clipPath id='clip0_81842_236158'>
          <rect width='20' height='20' fill='#FAFAFA' transform='translate(6 6)' />
        </clipPath>
      </defs>
    </svg>

  )
}

export default IconSVGLeads
