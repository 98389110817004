import type { FormValidation } from '@carfluent/common'
import type { BaseValuesProps } from './types'
import { parseNumber } from './utils'
import { MAX_NAME_LENGTH } from './constants'

export const DEPENDENT_VALIDATIONS = {
  'reconSteps.[*].daysInStepUntilSecondAlert': ['reconSteps.[*].daysInStepUntilFirstAlert'],
  'reconSteps.[*].daysInReconUntilSecondAlert': ['reconSteps.[*].daysInReconUntilFirstAlert']
}

const validationRules: FormValidation<BaseValuesProps> = {
  'reconSteps.[*].name': (val: string, ctx?: BaseValuesProps, idx?: number) => {
    const name = val.toLowerCase()

    if (name === '') {
      return 'Name field cannot be blank'
    } else if (name != null && name.length > MAX_NAME_LENGTH) {
      return `Name is too long. Maximum ${MAX_NAME_LENGTH} characters`
    } else if (ctx?.reconSteps?.find((item, i) => i !== idx && item.name.toLowerCase() === name) != null) {
      return 'This name already exists'
    }

    return null
  },
  'reconSteps.[*].daysInStepUntilFirstAlert': (val: number | null, ctx?: BaseValuesProps, idx?: number) => {
    const item = ctx?.reconSteps?.[idx ?? 0]
    const valueDISFirstAlert = parseNumber(val ?? 0)
    const valueDISSecondAlert = parseNumber(item?.daysInStepUntilSecondAlert ?? 0)

    if (valueDISFirstAlert !== 0 && (valueDISFirstAlert >= valueDISSecondAlert)) {
      return 'DIS yellow should be smaller than DIS red'
    }

    return null
  },
  'reconSteps.[*].daysInReconUntilFirstAlert': (val: number | null, ctx?: BaseValuesProps, idx?: number) => {
    const item = ctx?.reconSteps?.[idx ?? 0]
    const valueDIRFirstAlert = parseNumber(val ?? 0)
    const valueDIRSecondAlert = parseNumber(item?.daysInReconUntilSecondAlert ?? 0)

    if (valueDIRFirstAlert !== 0 && valueDIRFirstAlert >= valueDIRSecondAlert) {
      return 'DIR yellow should be smaller than DIR red'
    }
    return null
  }
}

export default validationRules
