import { type EmailTemplateModel } from 'api/types/responses'
import { parseDescription } from 'utils/wysiwyg'

import type { EmailTemplateFormData } from './types'

export const getDefaultFormData = (): EmailTemplateFormData => ({
  name: '',
  subject: '',
  body: parseDescription(null)
})

export const getDefaultEmailTemplatePayloadData = (): EmailTemplateModel => ({
  id: 0,
  name: '',
  subject: '',
  body: parseDescription(null),
  dealerId: 0,
  logoUrl: null
})

export enum FieldIds {
  Name = 'name',
  Subject = 'subject',
  Body = 'body'
}

export const BODY_SNIPPET_LABEL = 'BODY SNIPPET'
export const SUBJECT_SNIPPET_LABEL = 'SUBJECT SNIPPET'

export const TEMPLATE_IN_USE_CODE = '10001'
