import type { FC } from 'react'
import { cn, UI } from '@carfluent/common'

import BackToTop from 'components/common/BackToTop'
import ListFilterPopup from 'components/crm/ListFilterPopup'
import NavigationPanel from 'components/crm/NavigationPanel'
import Calendar from 'components/crm/Calendar'
import ActionsButton from 'components/crm/Calendar/components/ActionsButton'
import ShowSummaryModal from 'components/crm/ShowSummaryModal'
import DeleteModalWithState from 'components/dialogs/DeleteModalWithState'
import ShiftDetailsModal from 'components/crm/ShiftDetailsModal'
import CopyMonthModal from 'components/crm/CopyMonthModal'

import useWorkSchedule from './hook'

import CLASS_NAME from './styles'

const { Loader } = UI

const WorkSchedule: FC = () => {
  const {
    isLoading,
    date,
    shifts,
    initialDate,
    isBDC,
    usersListFilter,
    showSummaryModalProps,
    clearMonthModalProps,
    copyMonthModalProps,
    onOpenSummaryModal,
    onOpenCopyMonthModal,
    onOpenClearMonthModal,
    onDateChange,
    changeChipPosition,
    onOpenShift,
    isShiftModalOpen,
    isRemoving,
    shift,
    onCloseShiftForm,
    shiftsModalPosition,
    onRemoveShift,
    shiftDetailsSubmit
  } = useWorkSchedule()

  return (
    <div className={cn('g-submenu-content', 'g-margin-top-big', 'work-schedule-page', CLASS_NAME)}>
      <div className='cf-header-block'>
        <p className='cf-table-header-title'>Work schedule</p>

        {!isBDC && (
          <ActionsButton
            onOpenCopyMonthModal={onOpenCopyMonthModal}
            onOpenSummaryModal={onOpenSummaryModal}
            onOpenClearMonthModal={onOpenClearMonthModal}
          />)}
      </div>

      <div className='cf-filters-block'>
        {!isBDC &&
          <ListFilterPopup
            {...usersListFilter}
          />}

        <NavigationPanel date={date} onDateChange={onDateChange} />
      </div>

      <ShowSummaryModal {...showSummaryModalProps} />

      <DeleteModalWithState
        isOpen={clearMonthModalProps.isModalOpen}
        handleClose={clearMonthModalProps.onCloseModal}
        title='Clear all shifts for this month?'
        submitButtonTitle='Clear'
        handleConfirm={clearMonthModalProps.onSubmit}
      />

      <CopyMonthModal {...copyMonthModalProps} />

      <section className='cf-calendar-section'>
        <Calendar
          onOpenShift={onOpenShift}
          date={date}
          shifts={shifts}
          isBDC={isBDC}
          editShift={shift}
          addShiftDate={initialDate}
          changeChipPosition={changeChipPosition}
        />

        {isLoading && <Loader className='cf-section-loader' size='large' />}
      </section>

      {isShiftModalOpen && (
        <ShiftDetailsModal
          isOpen
          onClose={onCloseShiftForm}
          isRemoving={isRemoving}
          initialDate={initialDate}
          shift={shift}
          position={shiftsModalPosition}
          onRemoveShift={onRemoveShift}
          shiftDetailsSubmit={shiftDetailsSubmit}
        />
      )}
      <BackToTop />
    </div>
  )
}

export default WorkSchedule
