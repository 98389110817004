import type { FC } from 'react'
import RadioButton from 'components/common/RadioButton'
import { PaymentTransactionTypeEnum } from 'types/payments'
import CLASS_NAME from './styles'

interface TransactionTypesProps {
  id: string
  value: PaymentTransactionTypeEnum | null
  onChange: (id: string, value: PaymentTransactionTypeEnum) => void
  error?: string | null
  touched?: boolean
}

const TransactionTypes: FC<TransactionTypesProps> = ({
  onChange,
  id,
  value,
  touched,
  error
}) => {
  return (
    <div className={CLASS_NAME}>
      <h6 className='h6-title'>
        Transaction type

        {error != null && touched === true && <span>{error}</span>}
      </h6>

      <RadioButton
        isChecked={value === PaymentTransactionTypeEnum.CHECK}
        label='Check'
        onChange={() => onChange(id, PaymentTransactionTypeEnum.CHECK)}
      />

      <RadioButton
        isChecked={value === PaymentTransactionTypeEnum.ACH}
        label='ACH'
        onChange={() => onChange(id, PaymentTransactionTypeEnum.ACH)}
      />

      <RadioButton
        isChecked={value === PaymentTransactionTypeEnum.CASH}
        label='Cash'
        onChange={() => onChange(id, PaymentTransactionTypeEnum.CASH)}
      />

      <RadioButton
        isChecked={value === PaymentTransactionTypeEnum.ZELLE}
        label='ZELLE'
        onChange={() => onChange(id, PaymentTransactionTypeEnum.ZELLE)}
      />

      <RadioButton
        isChecked={value === PaymentTransactionTypeEnum.CARD}
        label='Credit card'
        onChange={() => onChange(id, PaymentTransactionTypeEnum.CARD)}
      />

      <RadioButton
        isChecked={value === PaymentTransactionTypeEnum.MULTIPLE}
        label='Multiple payments types'
        onChange={() => onChange(id, PaymentTransactionTypeEnum.MULTIPLE)}
      />
    </div>
  )
}

export default TransactionTypes
