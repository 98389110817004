import { type FocusEvent, useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useLoader, useModal, useRefUpdater } from '@carfluent/common'

import SettingsCTX from 'store/settings'
import AccountingApiProvider from 'api/accounting.api'
import { Routes } from 'constants/route_helper'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useAsyncEffect from 'hooks/useAsyncEffect'
import useCostTypes from 'hooks/useCostTypes'
import getAccountOptionName from 'utils/accounting/getAccountOptinName'
import { type BaseListItem } from 'types'
import {
  type AccountListItem,
  type AccountingFiscalYearsResponse,
  type VehicleCostDto,
  CostTypeId
} from 'api/types'

import { parseCostDetails } from './parser'
import useCostForm from './useCostForm'
import { type UseCostFormProps, type UseCostsFormReturn } from './types'
import { DEFAULT_FORM_VALUES, MODAL_CLOSE_DELAY } from './constants'
import useAccountsList from 'hooks/accounting/useAccontsList'
import { namifyAccount } from 'utils/accounting/namifyAccount'
import isClearedTransaction from 'utils/accounting/isClearedTransaction'
import isReconciledTransaction from 'utils/accounting/isReconciledTransaction'

const useCostsDetails = ({
  isModalOpen,
  onCloseModal,
  onDeleteCost,
  onSubmit: _onSubmit,
  selectedCost
}: UseCostFormProps): UseCostsFormReturn => {
  const isClosed = !isModalOpen
  const isClosedRef = useRef(isClosed) // AZ-NOTE: DON'T TOUCH! Check `onBlur` comment.
  const isPrefilledRef = useRef(false)

  const { accounting } = useContext(SettingsCTX)
  const vendorFormModalProps = useModal()
  const { isLoading, startLoader, stopLoader } = useLoader()
  const { showError } = useCustomSnackbar()
  const { costTypes, getCostTypes } = useCostTypes()
  const { getAccounts } = useAccountsList({ getData: AccountingApiProvider.getAccounts })

  const [costDetails, setCostDetails] = useState<VehicleCostDto | null>(null)
  const [lockedInfo, setLockedInfo] = useState<AccountingFiscalYearsResponse | null>(null)
  const [closeYearError, setCloseYearError] = useState<string | null>(null)
  const [activePaymentAccountOptions, setActivePaymentAccountOptions] = useState<AccountListItem[]>([])
  const [debitAccount, setDebitAccount] = useState<AccountListItem | null>(null)
  const [creditAccount, setCreditAccount] = useState<AccountListItem | null>(null)
  const [vehiclePurchaseAccounts, setVehiclePurchaseAccounts] = useState<AccountListItem[]>([])
  const [vehicleCostAccounts, setVehicleCostAccounts] = useState<AccountListItem[]>([])

  const reconcileStatusId = costDetails?.reconcileStatusId ?? 0
  const isLocked = costDetails?.isLocked === true
  const costId = typeof selectedCost === 'number'
    ? selectedCost
    : selectedCost?.id ?? null

  const isSystemCreated = costDetails?.isSystemCreated ?? false
  const isDisabled = isLocked ||
    isSystemCreated ||
    isClearedTransaction(reconcileStatusId) ||
    isReconciledTransaction(reconcileStatusId)

  // AZ-TODO: consider to move this logic outside of the modal.
  // Calculation of the control id (from url) is not a responsibility of the modal.
  const { pathname } = useLocation()
  const { id } = useParams<{ id: string }>()
  const control = useMemo(() => {
    const idNum = pathname.startsWith(Routes.Vehicles) ? Number(id) : null
    const vehicleId = idNum ?? costDetails?.vehicleId
    if (vehicleId == null) {
      return null
    }

    return vehicleId
  }, [pathname, id, costDetails])

  const form = useCostForm({
    controlId: control,
    costId,
    onCloseModal,
    onDelete: onDeleteCost,
    onSubmit: _onSubmit,
    setCloseYearError,
    showError
  })

  const {
    isSubmitting,
    onChange,
    onDelete,
    onSubmit,
    resetForm,
    setFieldTouched,
    values
  } = form

  const isPurchaseCostType = CostTypeId.Purchase === values.costType?.id
  const radioButtonsValueKey = isPurchaseCostType ? 'purchaseAccountId' : 'creditAccountId'

  const vendorEntityActions = useMemo(() => [{
    id: 'add_vendor',
    name: 'Add Vendor',
    onClick: vendorFormModalProps.onOpenModal
  }], [vendorFormModalProps.onOpenModal])

  /**
   * If we are loading existed Cost, then
   * payment accounts options should contain its credit account,
   * even if this account was removed from the list of possible credit accounts,
   * in Accounting Settings.
   */
  const paymentAccountOptions = useMemo(() => {
    if (costDetails?.creditAccount == null) {
      return activePaymentAccountOptions
    }

    const creditAccount: AccountListItem = namifyAccount(costDetails.creditAccount)
    if (activePaymentAccountOptions.some(a => a.id === creditAccount.id)) {
      return activePaymentAccountOptions
    }

    return [creditAccount].concat(activePaymentAccountOptions)
  }, [
    activePaymentAccountOptions,
    costDetails
  ])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  /**
   * Resets all internal state.
   */
  const onClose = useCallback(async () => {
    isPrefilledRef.current = false
    onCloseModal()

    setTimeout(() => {
      resetForm(DEFAULT_FORM_VALUES)
      setCostDetails(null)
      setLockedInfo(null)
      setCloseYearError(null)
      setActivePaymentAccountOptions([])
      setCreditAccount(null)
      setDebitAccount(null)
      setVehiclePurchaseAccounts([])
      setVehicleCostAccounts([])
    }, MODAL_CLOSE_DELAY)
  }, [onCloseModal, resetForm])

  const onAddVendor = useCallback(async (vendor) => {
    onChange('vendor', vendor)
  }, [onChange])

  const onCostTypeChange = useCallback((id: string, value: BaseListItem | null): void => {
    onChange(id, value)

    if (value != null) {
      const description = `${value.name} ${value.id !== CostTypeId.Purchase ? 'cost' : ''}`
      onChange('description', description)
    }
  }, [onChange])

  const onBlur = useCallback((evt: FocusEvent<HTMLElement> | string) => {
    /**
     * There is a case when form is not unmounted after closing.
     * So we need to prevent possible blur events after form is closed.
     * This way we avoid artifacts on the consequent openings.
     */
    if (isClosedRef.current) {
      return
    }

    const fieldId = typeof evt === 'string' ? evt : evt.target.getAttribute('id')
    if (fieldId != null) {
      void setFieldTouched(fieldId, true)
    }
  }, [setFieldTouched])

  const loadVehiclePurchaseAccounts = useCallback(async (): Promise<AccountListItem[]> => {
    if (vehiclePurchaseAccounts.length > 0) {
      return vehiclePurchaseAccounts
    }

    const resp = await AccountingApiProvider.getVehiclePaymentAccountsList()
    setVehiclePurchaseAccounts(resp.items)

    return resp.items
  }, [vehiclePurchaseAccounts])

  const loadVehicleCostAccounts = useCallback(async (): Promise<AccountListItem[]> => {
    if (vehicleCostAccounts.length > 0) {
      return vehicleCostAccounts
    }

    const resp = await AccountingApiProvider.getVehicleCostPaymentAccounts()
    setVehicleCostAccounts(resp.items)

    return resp.items
  }, [vehicleCostAccounts])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Loads cost data by costId.
   */
  useAsyncEffect(async () => {
    if (isClosed || (costId == null)) {
      return
    }

    try {
      startLoader()
      const costDetails = await AccountingApiProvider.getTransactionCost(costId)
      const isLocked = costDetails.isLocked

      const gettingLockedInfo = isLocked && (costDetails.date != null)
        ? AccountingApiProvider.getLockedYearOrMonthByDate(costDetails?.date)
        : Promise.resolve(null)

      const [
        costTypes,
        lockedInfo
      ] = await Promise.all([
        getCostTypes(),
        gettingLockedInfo
      ])

      setLockedInfo(lockedInfo)

      const costFormData = parseCostDetails({
        costDetails,
        costTypes: costTypes.items
      })

      resetForm(costFormData)
      setCostDetails(costDetails)
      setCreditAccount(costDetails.creditAccount)
      setDebitAccount(costDetails.debitAccount)
    } catch (err) {
      console.info(err)
    } finally {
      stopLoader()
    }
  }, [
    costId,
    getCostTypes,
    isClosed,
    resetForm,
    startLoader,
    stopLoader
  ])

  /**
   * Loads payment options for radio-buttons section.
   */
  const refLoadPurchaseAccounts = useRefUpdater(loadVehiclePurchaseAccounts)
  const refLoadCostsAccounts = useRefUpdater(loadVehicleCostAccounts)
  useAsyncEffect(async () => {
    const [purchaseAccounts, costAccounts] = await Promise.all([
      refLoadPurchaseAccounts.current(),
      refLoadCostsAccounts.current()
    ])

    const paymentAccountOptions = isPurchaseCostType ? purchaseAccounts : costAccounts
    setActivePaymentAccountOptions(paymentAccountOptions.map(namifyAccount))

    /**
     * Cost's credit account depends on costType.
     * "Purchase" cost uses different credit account than other costs.
     * We use two different fields to independently keep last selected option
     * for both possible cases.
     */
    if (costId == null) {
      onChange('purchaseAccountId', purchaseAccounts[0]?.id ?? null)
      onChange('creditAccountId', costAccounts[0]?.id ?? null)
    }
  }, [costId, isPurchaseCostType, onChange])

  /**
   * Loads default accounts using Accounting settings
   * and initializes Cost's credit and debit accounts.
   */
  const refAreAccountsLoaded = useRef(false)
  useAsyncEffect(async () => {
    if ((costId != null) || refAreAccountsLoaded.current) {
      return
    }

    const [creditAccounts, debitAccounts] = await Promise.all([
      getAccounts({ search: accounting.costCreditAccountNumber.toString(), skip: 0, take: 1 }),
      getAccounts({ search: accounting.costDebitAccountNumber.toString(), skip: 0, take: 1 })
    ])

    const debitAccount = debitAccounts?.items[0] ?? null
    const creditAccount = creditAccounts?.items[0] ?? null

    /**
     * Debit account does not depend on `costType`.
     */
    onChange('debitAccountId', debitAccount?.id ?? null)

    setDebitAccount(debitAccount)
    setCreditAccount(creditAccount)
    refAreAccountsLoaded.current = true
  }, [
    costId,
    getAccounts,
    onChange
  ])

  /**
   * Resets state on modal close.
   * Since content is not unmounted on modal close, we need to clean it manually.
   */
  useEffect(() => {
    /**
     * Calls `onClose` only if modal was actually opened and then closed.
     *
     * Since `isCloseRef` is initialized by `isModalOpen` property,
     * this situation is only possible when property `isModalOpen` is changed because
     * of explicit opening\closing of the form.
     *
     * DON'T CHANGE `isCloseRef` in any other place, PLEASE!
     */
    if (isClosed && !isClosedRef.current) {
      void onClose()
    }

    isClosedRef.current = isClosed
  }, [onClose, isClosed])

  // ========================================== //

  return {
    closeYearError,
    costId,
    creditValue: getAccountOptionName(creditAccount, '-'),
    dateTimeMin: accounting.accountingStartDate,
    debitValue: getAccountOptionName(debitAccount, '-'),
    formProps: { ...form, onBlur },
    isDisabled,
    isLoading,
    isLocked,
    isPurchaseCostType,
    isSubmitting,
    isSystemCreated,
    lockedInfo,
    costTypes,
    onAddVendor,
    onCostTypeChange,
    onDelete,
    onSubmit,
    paymentAccountOptions,
    radioButtonsValueKey,
    reconcileStatusId,
    transactionId: costDetails?.transactionId ?? null,
    vendorEntityActions,
    vendorFormModalProps
  }
}

export default useCostsDetails
