import { createElement } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import {
  ContentState,
  EditorState,
  ContentBlock,
  convertFromRaw,
  convertToRaw
} from 'draft-js'

import type { RawDraftEntity, EntityInstance } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import type { CarBlockData } from 'components/wysiwyg/toolbar/AddCarDropdown/types'
import { findCarInfoBlock } from 'components/wysiwyg/decorators/carBlock'
import CarBlockComponent from 'components/wysiwyg/editor/CarBlock'
import FeedbackLink from 'components/wysiwyg/editor/FeedbackLink'
import { findVideoBlock } from 'components/wysiwyg/decorators/uploadVideoLink'
import UploadedVideoLink from 'components/wysiwyg/editor/UploadVideoLink'
// import { wrapBodyWithCarsOfInterest } from 'utils/wysiwygHtmlWrappers'

export const CAR_BLOCK_ENTITY = 'CAR_BLOCK'
export const CAR_BLOCK_START = '%%CAR_BLOCK_START%%'
export const CAR_BLOCK_END = '%%CAR_BLOCK_END%%'
export const CAR_OF_INTEREST_TAG = '%%CAR_OF_INTEREST%%'
export const CAR_OF_INTEREST_VIDEO_TAG = '%%CAR_OF_INTEREST_VIDEO%%'
export const SUGGESTED_CARS_TAG = '%%SUGGESTED_CARS%%'
export const FEEDBACK_LINK_TAG = '%%FEEDBACK_LINK%%'
export const SPAN_TAG = 'span'
export const LINK_TAG = 'a'
export const VIDEO_BLOCK_ENTITY = 'VIDEO_BLOCK_ENTITY'
export const VIDEO_BLOCK_START = '%%VIDEO_BLOCK_START%%'
export const VIDEO_BLOCK_END = '%%VIDEO_BLOCK_END%%'

export const FEEDBACK_LINK_ID = 'feedback-url'
export const FEEDBACK_LINK_TEXT = 'PROVIDE FEEDBACK'

export enum CustomBlockClasses {
  SuggestedCars = 'suggested-car-block',
  CarOfInterest = 'car-of-interest-block',
  AttachedVideo = 'attached-video-block',
  InsertVideo = 'insert-video-link'
}

export enum CustomBlockTypes {
  SuggestedCars = 'SUGGESTED_CARS',
  CarOfInterest = 'CAR_OF_INTEREST',
  AttachedVideo = 'ATTACHED_VIDEO'
}

export interface SelectionKeys {
  carOfInterest: string | null
  suggestedCars: string | null
  attachedVideo: string | null
}

export interface VideoBlockData {
  videoUrl: string
  posterUrl: string
}

const DEFAULT_CONTENT_FOR_DESCRIPTION = {
  entityMap: {},
  blocks: [{
    key: '637gr',
    text: '',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  }]
}

const END_LINK_TAG = '</a>'

const getCarBlockTag = (data: CarBlockData): string => {
  return `${CAR_BLOCK_START}${JSON.stringify(data)}${CAR_BLOCK_END}`
}

const getVideoBlockTag = (urls: VideoBlockData): string => {
  return `${VIDEO_BLOCK_START}${JSON.stringify(urls)}${VIDEO_BLOCK_END}`
}

const isEntityARawDraft = (entity: RawDraftEntity | EntityInstance): entity is RawDraftEntity => {
  return 'data' in entity
}

export const isEntityFeedbackLink = (entity: EntityInstance | RawDraftEntity): boolean => {
  return isEntityARawDraft(entity)
    ? entity.type === 'LINK' && entity.data?.title === FEEDBACK_LINK_TEXT
    : entity.getType() === 'LINK' && entity.getData()?.title === FEEDBACK_LINK_TEXT
}

/**
 * Replaces custom keys for car block components(CAR_OF_INTEREST, SUGGESTED_CARS) for
 * setting tags on that places with according classes to get selection for adding custom components to body.
 */
export const replaceTags = (description: string): string => {
  description = description.replace(new RegExp(`${SUGGESTED_CARS_TAG}`, 'g'), () => {
    return `<span class='${CustomBlockClasses.SuggestedCars}'></span>`
  })

  description = description.replace(new RegExp(`${CAR_OF_INTEREST_TAG}`, 'g'), () => {
    return `<span class='${CustomBlockClasses.CarOfInterest}'></span>`
  })

  description = description.replace(new RegExp(`<a id="${FEEDBACK_LINK_ID}" href=".*"></a>`, 'g'), (match) => {
    const allUntilCloseTag = match.slice(0, match.lastIndexOf(END_LINK_TAG))
    return `${allUntilCloseTag}${FEEDBACK_LINK_TEXT}${END_LINK_TAG}`
  })

  return description
}

const parseHtmlToDraft = (description: string): ReturnType<typeof htmlToDraft> => {
  const _parsedContent = htmlToDraft(description, (nodeName: string, node: HTMLElement): RawDraftEntity | undefined => {
    const isSpanNode = nodeName === SPAN_TAG
    const isLinkNode = nodeName === LINK_TAG

    if (isLinkNode && node.classList.contains(CustomBlockClasses.InsertVideo)) {
      return {
        type: CustomBlockTypes.AttachedVideo,
        mutability: 'IMMUTABLE',
        data: {}
      }
    }

    if (!isSpanNode) {
      return
    }

    if (node?.classList?.contains(CustomBlockClasses.SuggestedCars)) {
      return {
        type: CustomBlockTypes.SuggestedCars,
        mutability: 'IMMUTABLE',
        data: {}
      }
    }

    if (node?.classList?.contains(CustomBlockClasses.CarOfInterest)) {
      return {
        type: CustomBlockTypes.CarOfInterest,
        mutability: 'IMMUTABLE',
        data: {}
      }
    }

    if (node?.classList?.contains(CustomBlockClasses.AttachedVideo)) {
      return {
        type: CustomBlockTypes.AttachedVideo,
        mutability: 'IMMUTABLE',
        data: {}
      }
    }
  })

  /**
   * To make block with car of interest "renderable", we should associate it with the Entity record,
   * that we created using `htmlToDraft` custom parser.
   * This is needed, because CarBlock component will try to get car's data from the block's Entity metadata.
   *
   * https://immutable-js.com/docs/v3.8.2/OrderedMap/#findKey()
   */
  const carBlockEntityKey = _parsedContent.entityMap.findKey((v: RawDraftEntity) => v.type === CustomBlockTypes.SuggestedCars)
  const carBlockEntityKeyCarOfInterest = _parsedContent.entityMap.findKey((v: RawDraftEntity) => v.type === CustomBlockTypes.CarOfInterest)
  const attachedVideoBlock = _parsedContent.entityMap.findKey((v: RawDraftEntity) => v.type === CustomBlockTypes.AttachedVideo)

  return {
    ..._parsedContent,
    contentBlocks: _parsedContent.contentBlocks.map((block) => {
      if (block.getEntityAt(0) === carBlockEntityKey) {
        return block
          .set('type', CustomBlockTypes.SuggestedCars)
          .set('text', '') as ContentBlock
      }

      if (block.getEntityAt(0) === carBlockEntityKeyCarOfInterest) {
        return block
          .set('type', CustomBlockTypes.CarOfInterest)
          .set('text', '') as ContentBlock
      }

      if (block.getEntityAt(0) === attachedVideoBlock) {
        return block
          .set('type', CustomBlockTypes.AttachedVideo)
          .set('text', '') as ContentBlock
      }

      return block
    })
  }
}

export const parseHtmlToEditorState = (_description: string | null, isForceDraft: boolean = false): EditorState => {
  const defaultContent = EditorState.createWithContent(convertFromRaw(DEFAULT_CONTENT_FOR_DESCRIPTION))

  if (_description == null) {
    return defaultContent
  }

  const description = replaceTags(_description)
  const parsedContent = isForceDraft ? htmlToDraft(_description) : parseHtmlToDraft(description)
  const contentState = ContentState.createFromBlockArray(parsedContent.contentBlocks)

  return EditorState.createWithContent(contentState)
}

export const getSelectionKeys = (parsedBody: EditorState): SelectionKeys => {
  let carOfInterest: string | null = null
  let suggestedCars: string | null = null
  let attachedVideo: string | null = null

  convertToRaw(parsedBody.getCurrentContent()).blocks.map((b) => {
    if (b.type === CustomBlockTypes.CarOfInterest) {
      carOfInterest = b.key
    }

    if (b.type === CustomBlockTypes.SuggestedCars) {
      suggestedCars = b.key
    }

    if (b.type === CustomBlockTypes.AttachedVideo) {
      attachedVideo = b.key
    }

    return b
  })

  return {
    suggestedCars,
    carOfInterest,
    attachedVideo
  }
}

/**
 * WARNING: legacy, don't use.
 */
export const parseDescription = parseHtmlToEditorState

export const serializeDescriptionToHtml = (description: EditorState): string | null => {
  return draftToHtml(convertToRaw(description.getCurrentContent()))
}

export const serializeDescriptionToPlainText = (description: EditorState): string | null => {
  return convertToRaw(description.getCurrentContent()).blocks.reduce((acc, curr) => `${acc}${curr.text}\n`, '')
}

export interface SerializerConfig {
  /**
   * AZ-NOTE: this is just a rough sketch of how draft saving (serializing)
   * can be implemented, when we will need it.
   *
   * To make this properly work we will need to add parser part, so that a special
   * text will be parsed into a corresponding Entity.
   *
   * Check htmlToDraft's customChunkRenderer.
   */
  isDraft: boolean
}

const DEFAULT_SERIALIZER_CONFIG: SerializerConfig = {
  isDraft: false
}

export const serializeToHtml = (
  editorState?: EditorState | null,
  config = DEFAULT_SERIALIZER_CONFIG
): string | null => {
  if (editorState == null) {
    return null
  }
  const rawContent = convertToRaw(editorState.getCurrentContent())
  // Return correct type of blocks to correct parsing(after changing type to get selection position)
  const correctRowContent = {
    ...rawContent,
    blocks: rawContent.blocks.map(({ type, ...otherProps }) => ({
      ...otherProps,
      type: (type === CustomBlockTypes.SuggestedCars || type === CustomBlockTypes.CarOfInterest || type === CustomBlockTypes.AttachedVideo)
        ? 'atomic'
        : type
    }))
  }

  const res = draftToHtml(correctRowContent, undefined, undefined, (entity: RawDraftEntity, text: string) => {
    if (!config.isDraft && (entity.type === CAR_BLOCK_ENTITY)) {
      return serializeCarInfoBlockToHtml(text ?? getCarBlockTag(entity.data as CarBlockData))
    }

    if (!config.isDraft && (entity.type === VIDEO_BLOCK_ENTITY)) {
      return serializeUploadVideoBlockToHtml(text ?? getVideoBlockTag(entity.data as VideoBlockData))
    }

    if (isEntityFeedbackLink(entity)) {
      const state = editorState.getCurrentContent()
      return renderToStaticMarkup(createElement(FeedbackLink, { contentState: state, entityData: entity.data }))
    }

    return null
  })

  /**
   * AZ-NOTED: commented to fix this bug: https://dev.azure.com/carfluent/CarFluent/_workitems/edit/13675
   * Need to re-check if it's needed.
   */
  // return hasCarsOfInterest ? wrapBodyWithCarsOfInterest(res) : res

  return res
}

export const serializeCarInfoBlockToHtml = (text: string): string => {
  const range = findCarInfoBlock(text)

  if (range == null) {
    return text
  }

  const carInfoBlockText = text.substring(range.start, range.end + range.endLength)
  const carInfoJSON = carInfoBlockText.replace(CAR_BLOCK_START, '').replace(CAR_BLOCK_END, '')
  const blockHtml = renderToStaticMarkup(createElement(CarBlockComponent, { decoratedText: carInfoJSON, isRemovable: false }))

  return text.substring(0, range.start) + blockHtml + text.substring(range.end + range.endLength)
}

export const serializeUploadVideoBlockToHtml = (text: string): string => {
  const range = findVideoBlock(text)

  if (range == null) {
    return text
  }

  const videoInfoBlockText = text.substring(range.start, range.end + range.endLength)
  const carInfoJSON = videoInfoBlockText.replace(VIDEO_BLOCK_START, '').replace(VIDEO_BLOCK_END, '')
  const blockHtml = renderToStaticMarkup(createElement(UploadedVideoLink, { decoratedText: carInfoJSON, isRemovable: false }))

  return text.substring(0, range.start) + blockHtml + text.substring(range.end + range.endLength)
}

export const convertHtmlToText = (html: string | null): string => {
  if (html == null) {
    return ''
  }

  return html
    .replace(/<\/[^>]*>/g, ' ')
    .replace(/<[^>]*>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/(\s){2,}/g, ' ')
}
