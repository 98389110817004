import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    marginBottom: '12px',
    padding: '24px 24px 0',
    '& .MuiTypography-root': {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '1.25px',
      color: '#101010'
    },
    '& h2': {
      '& > div': {
        paddingRight: 0
      }
    }
  },
  paper: {
    '& .MuiDialogContent-root': {
      padding: '0 24px 24px'
    }
  }
})

export default useStyles
