import { css } from '@emotion/css'

export default css(`
  &.review-screen-wrapper {
    padding-bottom: 80px;
  }

  .panels-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
  }
  
  .panel {
    border-radius: 12px;
    border: 1px solid var(--Outline, rgba(0, 0, 0, 0.12));
    padding: 16px;
    background-color: #FFF;
    
    width: 100%;
    min-height: 94px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    &.send-panel {
      gap: 7px;
    }
    
    .panel-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    
    .btn-edit {
      cursor: pointer;
    }
    
    .panel-content {
      display: grid;
      grid-template-columns: 130px 1fr;
      row-gap: 8px;
      column-gap: 8px;
    }
    
    .campaign-field-title {
      color: rgba(33, 33, 33, 0.50);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
    
    .campaign-field-value {
      color: #101010;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      max-width: 730px;

      .tooltiped-text {
        color: #101010;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
      }

       &.bold {
        font-weight: 500;
       }
    }
  }
  
  .panel:last-of-type {
    padding-bottom: 24px;
  }
  
  .form-fields .fields-row {
    display: flex;
    gap: 16px;
    
    > div {
      min-width: 260px;
      max-width: 260px;
      min-height: 56px;
      max-height: 56px;
    }
  }
  
  .btn-edit {
    cursor: pointer;
    position: relative;
    width: 24px;
    height: 24px;

    &.disabled {
      cursor: default;
      
      svg {
        path {
          fill: #a6a6a6;
          fill-opacity: 1;
        }
      }

      &:hover {
        &:after {
          opacity: 0;
        }
      }
    }

    svg {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;

      path {
        fill-opacity: 1;
      }
    }

    &:after {
      content: '';
      opacity: 0;
      width: 36px;
      height: 36px;
      position: absolute;
      background-color: #EDEDED;
      top: -6px;
      left: -6px;
      border-radius: 50%;
      transition: all .6s;
    }

    &:hover {
      &:after {
        opacity: 1;
        transition: all .6s;
      }
    }
  }
  
  .send-schedule-type {
    padding-bottom: 7px;
    
    .radio-item {
      margin-right: 0px;
      padding: 0px;
      padding-right: 32px;
    }
    
    .MuiTypography-root.MuiFormControlLabel-label {
      font-size: 14px;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
  
  .time-container {
    position: relative;
  }
  
  .time-zone-label {
    color: rgba(33, 33, 33, 0.50);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.4px;
    padding-left: 16px;
    position: absolute;
  }

  .footer {
    width: calc(100% - 64px);
    box-shadow: 0px -2px 12px 0px #0000001F;
  }
`)
