import { dates, DEFAULT_MAX_DATE, DEFAULT_MIN_DATE, UI } from '@carfluent/common'

import addMonths from 'date-fns/addMonths'
import addQuarters from 'date-fns/addQuarters'

const getPresets = (): UI.Preset[] => {
  const date = new Date()
  const defaultPresets = UI.getDefaultDateRangePresets(DEFAULT_MIN_DATE, DEFAULT_MAX_DATE)
  const allPeriod = { ...defaultPresets[0], title: 'All period' }
  const prevMonday = dates.addDays(-7, dates.getWeekStart(date))
  const prevSunday = dates.addDays(6, prevMonday)
  const prevMonthStart = addMonths(dates.getMonthStart(date), -1)
  const prevMonthEnd = dates.getMonthEnd(prevMonthStart)
  const prevQuarterStart = addQuarters(dates.getQuarterStart(date), -1)
  const prevQuarterEnd = dates.getQuarterEnd(prevQuarterStart)

  return [
    allPeriod,
    ...defaultPresets.slice(1)
  ].concat([
    { name: 'Previous Week', startDate: prevMonday, endDate: prevSunday },
    { name: 'Previous Month', startDate: prevMonthStart, endDate: prevMonthEnd },
    { name: 'Previous Quarter', startDate: prevQuarterStart, endDate: prevQuarterEnd }
  ])
}

export const DEFAULT_PRESETS = getPresets()
