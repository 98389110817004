import type { SendEmailFormData } from './types'

export const getDefaultFormData = (): SendEmailFormData => ({
  to: '',
  template: { id: 0, name: 'Empty Email' },
  cc: '',
  subject: '',
  emailAttachments: []
})

export const EMPTY_EMAIL_OPTION = {
  id: 0,
  name: 'Empty Email',
  body: null,
  subject: null,
  dealerId: 0,
  logoUrl: null,
  modifiedDate: null,
  modifiedByUser: {
    id: 0,
    dealerFirstName: null,
    dealerLastName: null
  }
}

export enum FormTitle {
  SendEmail = 'Send email'
}

export enum FieldIds {
  To = 'to',
  CC = 'cc',
  Subject = 'subject',
  Template = 'template',
  EmailAttachments = 'emailAttachments'
}

export enum Messages {
  SuccessMessage = 'Email was sent.',
  ErrorMessage = 'This email was not sent.',
}
