import { useState, useCallback, useEffect, useMemo } from 'react'
import { type Result, isOk, useForm, useModal } from '@carfluent/common'

import type {
  CrmSettingsFormData,
  UseGeneralSettingsReturn, UserItem
} from 'pages/crm/GeneralSettings/hook/types'
import useTransitionBlocker from 'hooks/useTransitionBlocker'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import CRMApiProvider from 'api/crm.api'
import NotificationsAPIProvider from 'api/notifications.api'
import {
  ViewDealerRoles,
  type MessageTemplateItem,
  type LeadEmailTemplateItem,
  type CrmSettings
} from 'api/types'
import useEffectOnce from 'hooks/useEffectOnce'
import IdentityApiProvider from 'api/identity.api'
import parseUsers from 'utils/parseUsers'

import type { ErrTouchShortcuts } from './types'
import { parseCrmSettings } from './parser'
import { serializeCrmSettings } from './serializer'
import { validationRules } from './validator'
import {
  getDefaultFormData,
  DEFAULT_OPTIONS,
  APPOINTMENT_RECIPIENTS_ASSIGNEE_OPTION,
  LEAD_OWNER_RECIPIENTS_OPTION
} from './constants'

const DEFAULT_FORM_DATA = getDefaultFormData()
const DEFAULT_PAYLOAD = { skip: 0, take: 2000 }

const useGeneralSettings = (): UseGeneralSettingsReturn => {
  const { showAlert } = useCustomSnackbar()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [emailTemplates, setEmailTemplates] = useState<LeadEmailTemplateItem[] | null>(null)
  const [messageTemplates, setMessageTemplates] = useState<MessageTemplateItem[] | null>(null)
  const [crmSettings, setCrmSettings] = useState<CrmSettings | null>(null)
  const [allUsers, setAllUsers] = useState<UserItem[]>([])
  const [bdcUsers, setBdcUsers] = useState<number[]>([])
  const [salesUsers, setSalesUsers] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [recipients, setRecipients] = useState<UserItem[]>([])

  const bdcSalesUsers = useMemo(() => {
    return allUsers.filter(({ id }) =>
      bdcUsers.includes(id) || salesUsers.includes(id))
  }, [salesUsers, bdcUsers, allUsers])

  const { isModalOpen, onOpenModal, onCloseModal } = useModal()

  // ========================================= //
  //                   SUBMIT                  //
  // ========================================= //

  const onActionResult = useCallback((res: Result<CrmSettingsFormData>, resetForm) => {
    if (isOk<CrmSettingsFormData>(res)) {
      resetForm(res.result)
    }
  }, [])

  const submitAction = useCallback(async (formData: CrmSettingsFormData): Promise<CrmSettingsFormData | null> => {
    try {
      setIsSubmitting(true)

      const serializedCrmSettings = serializeCrmSettings(formData)
      const crmSettings = await CRMApiProvider.updateCrmSettings(serializedCrmSettings)

      setCrmSettings(crmSettings)
      showAlert('Changes successfully saved.', { variant: 'success' })

      return formData
    } catch (err) {
      showAlert(err)

      return null
    } finally {
      setIsSubmitting(false)
      onCloseModal()
    }
  }, [showAlert, setIsSubmitting, onCloseModal])

  // ========================================== //
  //                   FORM                     //
  // ========================================== //

  const form = useForm<CrmSettingsFormData, ErrTouchShortcuts>({
    baseValues: DEFAULT_FORM_DATA,
    isTrackingChanges: true,
    submitAction: submitAction,
    validationRules,
    onActionResult
  })

  const {
    isFormChanged,
    values,
    errors,
    onChange: _onChange,
    resetForm,
    isValid,
    setValues,
    onBlur,
    setFieldTouched
  } = form

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //
  const onChange = (id: string, value: unknown): void => {
    _onChange(id, value)

    // hide showing error on toggling section
    switch (id) {
      case 'creditAppTasksEnabled':
        setFieldTouched('creditAppAssignedUserId', false)
        break
      case 'autoReplyEnabled':
        setFieldTouched('vehicleAvailableEmailTemplate', false)
        setFieldTouched('vehicleUnavailableEmailTemplate', false)
        setFieldTouched('vehicleAvailableMessageTemplate', false)
        setFieldTouched('vehicleUnavailableMessageTemplate', false)
        break
      case 'roundRobinEnabled':
        setFieldTouched('roundRobinIntervalMinutes', false)
        break
    }
  }

  const resetFormValues = useCallback(() => {
    if (crmSettings != null) {
      const parsedCrmSettings = parseCrmSettings({
        allUsers,
        bdcSalesUsersList: bdcSalesUsers,
        emailTemplates: emailTemplates ?? [],
        messageTemplates: messageTemplates ?? [],
        ...crmSettings
      })

      setValues(parsedCrmSettings)
    }
  }, [crmSettings, emailTemplates, messageTemplates, setValues, bdcSalesUsers])

  const onUpdateRoundRobinUsers = (id: number, value: boolean): void => {
    const index = values.userIds.findIndex((user) => user.id === id)
    onChange(`userIds.[${index}].checked`, value)
    onBlur(`userIds.[${index}].checked`)
  }

  const onCancelChangeForm = (): void => {
    if (crmSettings != null) {
      const parsedCrmSettings = parseCrmSettings({
        allUsers,
        bdcSalesUsersList: bdcSalesUsers,
        emailTemplates: emailTemplates ?? [],
        messageTemplates: messageTemplates ?? [],
        ...crmSettings
      })

      resetForm(parsedCrmSettings ?? DEFAULT_FORM_DATA)
      showAlert('Changes discarded.', { variant: 'success' })
    }
  }

  // =========================================== //
  //                  MODAL                      //
  // =========================================== //

  const { proceedTransition } = useTransitionBlocker({
    shouldBlock: isFormChanged(),
    onBlock: onOpenModal
  })

  const onDontSaveAndClose = (): void => {
    resetFormValues()
    onCloseModal()
    proceedTransition()
  }

  const onSaveAndClose = async (): Promise<void> => {
    if (!isValid) {
      onCloseModal()
      return
    }

    proceedTransition()
    await form.onSubmit()
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffectOnce(() => {
    const runEffect = async (): Promise<void> => {
      try {
        setIsLoading(true)

        const { items: bdcUsers } = await IdentityApiProvider.getUsers([ViewDealerRoles.BDC])
        const { items: salesUsers } = await IdentityApiProvider.getUsers([ViewDealerRoles.Sales])
        const { items: allUsers } = await IdentityApiProvider.getUsers([])
        const recipients = [
          APPOINTMENT_RECIPIENTS_ASSIGNEE_OPTION,
          LEAD_OWNER_RECIPIENTS_OPTION,
          ...allUsers.map((user) => ({
            id: user.id,
            name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
            phoneNumber: user.phoneNumber
          }))
        ]
        setBdcUsers(bdcUsers.map(({ id }) => id))
        setSalesUsers(salesUsers.map(({ id }) => id))
        setAllUsers([...DEFAULT_OPTIONS, ...parseUsers(allUsers)])
        setRecipients(recipients)

        const [
          { items: emailTemplates },
          { items: messageTemplates },
          crmSettings
        ] = await Promise.all([
          await NotificationsAPIProvider.getLeadEmailTemplates(DEFAULT_PAYLOAD),
          await NotificationsAPIProvider.getMessageTemplates(DEFAULT_PAYLOAD),
          await CRMApiProvider.getCrmSettings()
        ])

        setEmailTemplates(emailTemplates)
        setMessageTemplates(messageTemplates)
        setCrmSettings(crmSettings)
      } finally {
        setIsLoading(false)
      }
    }

    void runEffect()
  }, [])

  useEffect(() => {
    if (allUsers.length > 0) {
      void resetFormValues()
    }
  }, [resetFormValues, allUsers])

  return {
    ...form,
    onChange,
    isLoading,
    isSubmitting,
    onUpdateRoundRobinUsers,
    emailTemplates,
    messageTemplates,
    onCancelChangeForm,
    usersOptions: allUsers,
    bdcUsers: values.userIds.filter(({ id }) => bdcUsers.includes(id)),
    salesTeamUsers: values.userIds.filter(({ id }) => salesUsers.includes(id)),
    unsavedChangesModalProps: {
      isOpen: isModalOpen,
      isSubmittingCanceled: errors.roundRobinIntervalMinutes !== null,
      onCloseDialog: onCloseModal,
      onDontSaveAndClose,
      onSaveAndClose
    },
    recipients
  }
}

export default useGeneralSettings
