import type { FC } from 'react'
import type { IconProps } from 'types'

// todo double check
const UnableToMessage: FC<IconProps> = ({ color = '#21212180', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask id='mask0_86546_35003' maskUnits='userSpaceOnUse' x='0' y='0' width={width} height={height}>
        <rect width={width} height={height} fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_86546_35003)'>
        <path d='M4 12.3335L2.56667 13.7668C2.45556 13.8779 2.33333 13.9057 2.2 13.8502C2.06667 13.7946 2 13.6891 2 13.5335V3.66683C2 3.30016 2.13056 2.98627 2.39167 2.72516C2.65278 2.46405 2.96667 2.3335 3.33333 2.3335H12.6667C13.0333 2.3335 13.3472 2.46405 13.6083 2.72516C13.8694 2.98627 14 3.30016 14 3.66683V6.66683H12.6667V3.66683H3.33333V11.0002H8.66667V12.3335H4ZM6 6.66683H10C10.1889 6.66683 10.3472 6.60294 10.475 6.47516C10.6028 6.34739 10.6667 6.18905 10.6667 6.00016C10.6667 5.81127 10.6028 5.65294 10.475 5.52516C10.3472 5.39739 10.1889 5.3335 10 5.3335H6C5.81111 5.3335 5.65278 5.39739 5.525 5.52516C5.39722 5.65294 5.33333 5.81127 5.33333 6.00016C5.33333 6.18905 5.39722 6.34739 5.525 6.47516C5.65278 6.60294 5.81111 6.66683 6 6.66683ZM6 9.3335H8C8.18889 9.3335 8.34722 9.26961 8.475 9.14183C8.60278 9.01405 8.66667 8.85572 8.66667 8.66683C8.66667 8.47794 8.60278 8.31961 8.475 8.19183C8.34722 8.06405 8.18889 8.00016 8 8.00016H6C5.81111 8.00016 5.65278 8.06405 5.525 8.19183C5.39722 8.31961 5.33333 8.47794 5.33333 8.66683C5.33333 8.85572 5.39722 9.01405 5.525 9.14183C5.65278 9.26961 5.81111 9.3335 6 9.3335Z' fill={color} />
        <path d='M12.3662 11.5762L11.1877 10.3977C10.9274 10.1373 10.9274 9.7152 11.1877 9.45485C11.4481 9.1945 11.8702 9.1945 12.1305 9.45485L13.309 10.6334L14.4876 9.45485C14.7479 9.1945 15.17 9.1945 15.4304 9.45485C15.6907 9.7152 15.6907 10.1373 15.4304 10.3977L14.2519 11.5762L15.4304 12.7547C15.6907 13.015 15.6907 13.4371 15.4304 13.6975C15.17 13.9578 14.7479 13.9578 14.4876 13.6975L13.309 12.519L12.1305 13.6975C11.8702 13.9578 11.4481 13.9578 11.1877 13.6975C10.9274 13.4371 10.9274 13.015 11.1877 12.7547L12.3662 11.5762Z' fill={color} />
      </g>
    </svg>
  )
}

export default UnableToMessage
