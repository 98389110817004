import type { SortingInfo } from '@carfluent/common/dist/UI'

import {
  type LeadListCounters,
  type CancelationOptions,
  LeadState,
  LeadLabel
} from 'api/types'

export const CANCEL_LOAD_DATA: CancelationOptions = { key: 'load-leads-data', cancelPrevious: true }
export const CANCEL_LOAD_COUNTERS: CancelationOptions = { key: 'load-leads-counters', cancelPrevious: true }

export enum ColumnTitles {
  Label = 'Label',
  Source = 'Source',
  Status = 'Status',
  AssignedTo = 'Lead owner'
}

export enum ActivityType {
  Appointment = 'Appointment',
  FollowUpCall = 'Follow up call',
  Reminder = 'Reminder',
  WebLead = 'Web lead',
  MessageReceived = 'Message received',
  EmailReceived = 'Email received'
}

export enum ColumnPopUpTitles {
  Label = 'Show labels:',
  Source = 'Show sources:',
  Status = 'Show statuses:',
  AssignedTo = 'Show assigned to:'
}

export enum FiltersKeys {
  Status = 'status',
  Temperatures = 'temperatures',
  Source = 'source',
  AssignedTo = 'assignedTo'
}

export enum Errors {
  Counters = 'Leads counters fetching failed',
  Statuses = 'Leads statuses fetching failed',
  AssignedTo = 'Leads assigned to fetching failed'
}

export const LABEL_TEXTS: Record<LeadLabel, string> = {
  [LeadLabel.Hot]: 'Hot',
  [LeadLabel.Warm]: 'Warm',
  [LeadLabel.Cold]: 'Cold'
}

export const DEFAULT_TABLE_FILTERS = {
  [FiltersKeys.Status]: [],
  [FiltersKeys.Temperatures]: [],
  [FiltersKeys.Source]: [],
  [FiltersKeys.AssignedTo]: []
}

export const DEFAULT_SORTING: SortingInfo = { id: 'age', order: 'asc' }

export const DEFAULT_COUNTERS: LeadListCounters = {
  all: 0,
  new: 0,
  needattention: 0,
  inbox: 0
}

export const DEFAULT_FILTERS = {
  ...DEFAULT_TABLE_FILTERS,
  tab: LeadState.All,
  search: ''
}

export const FILTERS = [
  {
    id: LeadState.All,
    name: 'All',
    dataTestId: 'leads-filter-all'
  },
  {
    id: LeadState.New,
    name: 'New',
    dataTestId: 'leads-filter-new'
  },
  {
    id: LeadState.NeedAttention,
    name: 'Need Attention',
    dataTestId: 'leads-filter-need-attention'
  }
]
