import { css } from '@emotion/css'

export default css(`
   &.cf-dropdown-option {
     padding: 8px 16px;
   }

  .cf-plus-icon {
    height: 14px;
    width: 14px;
    margin: 0 8px 0 0;
  }

  .cf-button-option {
    cursor: pointer;
    user-select: none;
    min-height: 48px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .cf-button-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px
    }
  }
`)
