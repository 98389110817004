import { css } from '@emotion/css'

export default css(`
  padding: 0px 16px 0px 0px;

  .cf-snackbar-content {
    height: 60px;
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .cf-inverted-border {
    margin-right: 12px;
  }

  .cf-link {
    display: flex;

    &:hover {
      text-decoration: none;
      text-decoration-color: #101010;
    }
  }

  .cf-deal-vehicle {
    margin-top: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    font-family: Roboto, sans-serif;
    text-overflow: ellipsis;
  }

  .cf-close-button {
    display: flex;
    width: 12px;
    height: 12px;
    align-items: center;
    justify-content: center;
    align-self: start;
    border: none;
    background: none;
    cursor: pointer;
  }

  .cf-close-img {
    color: #333333;
    width: 8px;
    height: 8px;
  }

  .cf-close-img:hover {
    width: 14px;
    height: 14px;
    background-image: url("ellipse_for_background.svg");
    background-color: #DEDEDE;
    border-radius: 12px;
    padding: 2px;
  }

  .cf-horizontal-line {
    width: 285px;
    height: 1px;
    border: none;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.12);
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`)
