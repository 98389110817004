import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AccountingApiProvider from 'api/accounting.api'
import useTableApi from 'hooks/useTableApi'
import useAccountsList from 'hooks/accounting/useAccontsList'
import useNavigateSubscription from 'hooks/useNavigateSubscription'
import CalcRoutes from 'constants/route_helper'
import { PAGE_SIZE } from 'constants/constants'

import columnDefinitions from './columns'
import {
  type AccountItem,
  type SortingInfo,
  type Row,
  type UseChartOfAccountsReturn
} from './types'

const DEFAULT_FILTERS = {}
export const DEFAULT_SORTING: SortingInfo = { id: 'number', order: 'asc' }

const useChartOfAccounts = (): UseChartOfAccountsReturn => {
  const navigate = useNavigate()

  const { getAccounts } = useAccountsList({
    getData: AccountingApiProvider.getAccountsWithBalances
  })

  const {
    isLoading,
    loadRows,
    rows,
    onSearch,
    search,
    setSearch,
    setSorting,
    sorting
  } = useTableApi({
    defaultFilters: DEFAULT_FILTERS,
    defaultSorting: DEFAULT_SORTING,
    getList: getAccounts,
    shouldRunOnCall: true
  })

  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onAddAccount = useCallback(async () => {
    await loadRows({ forceRefresh: true, forceScrollToSkeleton: false })
  }, [loadRows])

  const onAccountClick = useCallback((row: Row<AccountItem>) => {
    navigate(CalcRoutes.AccountTrialBalance(row.original.id), { state: { ...row.original } })
  }, [navigate])

  const onBottomReached = useCallback(async () => {
    if (!isLoading) {
      void loadRows({ forceRefresh: false })
    }
  }, [loadRows, isLoading])

  const onToggleAccountFormModal = useCallback(() => {
    setIsAddAccountModalOpen(open => !open)
  }, [])

  const onReturnFromLAccountTrialBalance = useCallback(() => {
    void loadRows({ forceRefresh: true, forceScrollToSkeleton: false })
  }, [loadRows])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useNavigateSubscription((from: unknown) => {
    if (from === -1) {
      void loadRows({
        forceRefresh: true,
        forceScrollToSkeleton: false,
        pageSize: rows.length > 0 ? rows.length : PAGE_SIZE
      })
    }
  })

  // ========================================== //

  return {
    columns: columnDefinitions,
    isAddAccountModalOpen,
    isLoading,
    rows,
    sorting,
    search,
    onAddAccount,
    onBottomReached,
    onReturnFromLAccountTrialBalance,
    onSortingChange: setSorting,
    onSearchChange: setSearch,
    onToggleAccountFormModal,
    onAccountClick,
    onSearch
  }
}

export default useChartOfAccounts
