import { useState, useEffect } from 'react'

interface UseDragScrollProps {
  className: string
}

const useDragScroll = ({ className }: UseDragScrollProps): void => {
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)

  useEffect(() => {
    const container = document.querySelector(className) as HTMLElement
    const handleMouseDown = (e: MouseEvent): void => {
      setIsDragging(true)
      setStartX(e.pageX - (e.currentTarget as HTMLElement).offsetLeft)
      setScrollLeft((e.currentTarget as HTMLElement).scrollLeft)
    }

    const handleMouseLeave = (): void => {
      setIsDragging(false)
    }

    const handleMouseUp = (): void => {
      setIsDragging(false)
    }

    const handleMouseMove = (e: MouseEvent): void => {
      if (!isDragging) return
      e.preventDefault()
      const x = e.pageX - (e.currentTarget as HTMLElement).offsetLeft
      const walk = (x - startX);
      (e.currentTarget as HTMLElement).scrollLeft = scrollLeft - walk
    }

    container?.addEventListener('mousedown', handleMouseDown)
    container?.addEventListener('mouseleave', handleMouseLeave)
    container?.addEventListener('mouseup', handleMouseUp)
    container?.addEventListener('mousemove', handleMouseMove)

    return () => {
      container?.removeEventListener('mousedown', handleMouseDown)
      container?.removeEventListener('mouseleave', handleMouseLeave)
      container?.removeEventListener('mouseup', handleMouseUp)
      container?.removeEventListener('mousemove', handleMouseMove)
    }
  }, [isDragging, startX, scrollLeft])
}

export default useDragScroll
