import { css } from '@emotion/css'
import { px, ScreenWidthBreakpoints } from 'constants/constants'

export default css(`
  .info-block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
    margin-bottom: 8px;
  }

  .loader {
    height: 232px;
    width: 100%;
    box-shadow: none;
    text-shadow: none;
  }

  .inventory-chart {
    @media screen and (max-width: ${px(ScreenWidthBreakpoints.Small)}) {
      max-width: 359px;
    }
  }

  .no-data {
    display: flex;
    max-height: 232px;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: rgba(33, 33, 33, 0.50);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
  }

  .custom-tooltip {
    display: flex;
    flex-direction: column;
    padding: 4px 12px;
    border-radius: 4px;
    background: #212121;

    .value {
      color: #FFF;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .label {
      color: #FFF;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
`)
