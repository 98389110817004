import { BankStatementTransactionClearMethod } from 'api/types'

const IdToNameMap: Record<number, string> = {
  [BankStatementTransactionClearMethod.Pay]: 'Pay',
  [BankStatementTransactionClearMethod.Link]: 'Link',
  [BankStatementTransactionClearMethod.Receive]: 'Receive',
  [BankStatementTransactionClearMethod.Categorize]: 'Categorize'
}

const getBankStatementTxClearMethodName = (id: number): string | null => {
  return IdToNameMap[id] ?? null
}

export default getBankStatementTxClearMethodName
