// BE BackGross = Sum of NetProfit for coverages + reserve - acquisition fee
// FE BackGross = BE BackGross + BE acquisition fee - FE acquisition fee
import { floor } from 'mathjs'

const getBackGrossAmount = (
  backGrossOriginal: number | null,
  acquisitionFeeOriginal: number | null,
  acquisitionFeeCurrent: number | null
): number => {
  const backGrossCurrent = (backGrossOriginal ?? 0) +
    (acquisitionFeeOriginal ?? 0) -
    (acquisitionFeeCurrent ?? 0)

  return floor(backGrossCurrent, 2)
}

export default getBackGrossAmount
