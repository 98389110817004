import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  align-items: center;
  justify-content: center;

  .status-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    width: 77px;
    padding: 0 8px;
    height: 24px;
    border-radius: 4px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0.25px;
    text-align: center;
    border: none;

    &.status-failed {
      background-color: rgba(176, 0, 32, 0.10);
      color: #B00020;
    }

    &.status-pending {
      background-color: #FBE9D8;
      color: #60400D;
    }

    &.status-started {
      background-color: #FBF9D8;
      color: #605D0D;
    }

    &.status-sent {
      background-color: #CFE6D9;
      color: #0E4C29;
    }

    &.status-draft {
      background-color: #F3F3F3;
      color: #4A4A4A;
    }
  }
`)
