import { css } from '@emotion/css'

export default css(`
  --footer-height: 80px;

  p {
    color: #101010;
  }

  .cf-title-and-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--content-margin-default);
    height: 24px;
  }
  
  .cf-button-root.cf-button-variant-text.cf-btn-edit {
    text-decoration: none;
    border: 1px solid #C99B86;
    border-radius: 8px;
    width: 70px;
    height: 26px; 

    .cf-button-content {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #101010;

      :hover {
        font-weight: 600;
      }
    }
  }

  .tab-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: var(--footer-height);
    box-shadow: 0px -2px 12px 0px #0000001F;
    background-color: #fff;
    
    position: fixed;
    z-index: 1102;
    left: 0px;
    right: 0px;
    bottom: calc(var(--footer-height) * -1);
    padding-right: 64px;
    transition: bottom 0.3s ease;

    &.is-shown {
      bottom: 0px;
    }

    button {
      margin: 0px;

      &:first-child {
        margin-right: 24px;
      }
    }
  }
`)
