import { useCallback, useEffect, useState } from 'react'
import {
  type DeepRequired,
  useForm, useLoader, isOk
} from '@carfluent/common'
import { useNavigate } from 'react-router-dom'

import AccountingApiProvider from 'api/accounting.api'
import { Routes } from 'constants/route_helper'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import parseError from 'utils/parseErrors'

import { parseBankStatements } from './parser'
import { serializeData } from './serializer'
import validationRules from './validation'
import {
  type UseStatementPreviewFormReturn,
  type UseStatementPreviewFormProps,
  type StatementPreviewFormData
} from './types'

const DEFAULT_FORM_DATA: StatementPreviewFormData[] = []

const useStatementPreviewForm = ({
  fileId,
  bankId,
  accountId,
  originalFileName,
  parsingResults
}: UseStatementPreviewFormProps): UseStatementPreviewFormReturn => {
  const { startLoader, stopLoader } = useLoader()
  const { showAlert } = useCustomSnackbar()
  const navigate = useNavigate()

  const [totalLinesCount, setTotalLinesCount] = useState(0)
  const [duplicateLinesCount, setDuplicateLinesCount] = useState(0)
  const [successfulLinesCount, setSuccessfulLinesCount] = useState(0)
  const [errorLinesCount, setErrorLinesCount] = useState(0)
  const [pendingLinesCount, setPendingLinesCount] = useState(0)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const submitAction = useCallback(async (values: StatementPreviewFormData[]) => {
    const allIsUnChecked = values.every(({ checked }) => !checked)
    if (allIsUnChecked) {
      showAlert('Please select lines to import.', { variant: 'error' })
      return
    }

    try {
      startLoader()
      const data = serializeData({
        fileId,
        bankId,
        accountId,
        originalFileName: originalFileName ?? '',
        transactions: values
      })

      await AccountingApiProvider.importBankStatements(data)
      showAlert('Bank statement has been imported.', { variant: 'success' })
      navigate(Routes.AccountingBanking)
    } catch (err: any) {
      const errors = parseError(err)
      showAlert(errors, { variant: 'error' })
    } finally {
      stopLoader()
    }
  }, [fileId, bankId, accountId, originalFileName, startLoader, showAlert, stopLoader, navigate])

  const onActionResult = useCallback((res, resetForm) => {
    if (res.result != null && isOk(res)) {
      resetForm(DEFAULT_FORM_DATA)
    }
  }, [])

  const form = useForm<DeepRequired<StatementPreviewFormData[]>>({
    baseValues: DEFAULT_FORM_DATA,
    validationRules,
    submitAction,
    onActionResult
  })

  const { setValues, resetForm } = form

  const onCancel = useCallback(() => {
    resetForm(DEFAULT_FORM_DATA)
    navigate(Routes.AccountingBanking)
  }, [navigate, resetForm])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const runEffect = async () => {
      try {
        const parsedValue = parseBankStatements(parsingResults)

        setValues(parsedValue, true, true)
        setTotalLinesCount(parsingResults.totalLinesCount)
        setDuplicateLinesCount(parsingResults.duplicateLinesCount)
        setSuccessfulLinesCount(parsingResults.successfulLinesCount)
        setErrorLinesCount(parsingResults.errorLinesCount)
        setPendingLinesCount(parsingResults.pendingLinesCount)

        return parsingResults
      } catch {
        showAlert('Something went wrong.', { variant: 'error' })
        return false
      }
    }

    void runEffect()
  }, [
    fileId, bankId, accountId,
    parsingResults, setValues, showAlert
  ])

  // ========================================== //
  //

  return {
    ...form,
    onCancel,
    totalLinesCount,
    duplicateLinesCount,
    successfulLinesCount,
    errorLinesCount,
    pendingLinesCount
  }
}

export default useStatementPreviewForm
