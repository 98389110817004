import { cnx, type ColumnDef } from '@carfluent/common'

import TextCellHOC from 'components/common/Table/cells/TextCell'
import TooltipCell from 'components/common/Table/cells/TooltipCell'
import { LinkCellHOC } from 'components/common/Table/cells/LinkCell'

import type { ReportLeadsByVehicleRowData } from './types'

const columnDefinitions: Array<ColumnDef<ReportLeadsByVehicleRowData>> = [
  {
    sortable: false,
    header: 'Car details',
    id: 'carDetailsGroup',
    className: 'car-details-group',
    columns: [
      {
        size: 240,
        accessorKey: 'make',
        loading: 'big_line',
        header: TextCellHOC({ text: 'Car' }),
        cell: LinkCellHOC({ withTooltip: true })
      },
      {
        size: 120,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'stock',
        header: TextCellHOC({ text: 'Stock#' })
      },
      {
        size: 120,
        cell: TooltipCell,
        accessorKey: 'age',
        loading: 'big_line',
        header: TextCellHOC({ text: 'Age' })
      },
      {
        size: 120,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'interestedLeadsAmount',
        header: TextCellHOC({ text: 'Total leads' })
      }
    ] as Array<ColumnDef<ReportLeadsByVehicleRowData>>
  },
  {
    sortable: false,
    id: 'websiteLeadsGroup',
    header: 'CarFluent website leads',
    className: 'car_fluent-website-leads-group',
    columns: [
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'WebsiteDeal',
        className: cnx('car_fluent-website-leads-group', 'header-indent'),
        header: TextCellHOC({ text: 'Deal', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'WebsiteAsk',
        className: 'car_fluent-website-leads-group',
        header: TextCellHOC({ text: 'Ask', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'WebsiteTestDrive',
        className: 'car_fluent-website-leads-group',
        header: TextCellHOC({ text: 'Test drive', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'WebsiteTradeIn',
        className: 'car_fluent-website-leads-group',
        header: TextCellHOC({ text: 'Trade in', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'WebsiteFinancing',
        className: 'car_fluent-website-leads-group',
        header: TextCellHOC({ text: 'Financing', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'WebsiteChat',
        className: 'car_fluent-website-leads-group',
        header: TextCellHOC({ text: 'Chat', withTooltip: true })
      }
    ] as Array<ColumnDef<ReportLeadsByVehicleRowData>>
  },
  {
    sortable: false,
    id: 'dealershipGroup',
    header: 'Dealership leads',
    className: 'dealership-leads-group',
    columns: [
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'Phone',
        className: cnx('dealership-leads-group', 'header-indent'),
        header: TextCellHOC({ text: 'Phone', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'WalkIn',
        className: 'dealership-leads-group',
        header: TextCellHOC({ text: 'Walk in', withTooltip: true })
      }
    ] as Array<ColumnDef<ReportLeadsByVehicleRowData>>
  },
  {
    sortable: false,
    id: 'internetGroup',
    header: 'Internet leads',
    className: 'internet-leads-group',
    columns: [
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'Autolist',
        className: cnx('internet-leads-group', 'header-indent'),
        header: TextCellHOC({ text: 'Auto List', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'AutoTrader',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'Auto Trader', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'CapitalOne',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'Capital One', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'CarsCom',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'Cars.com', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'CarCode',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'CarCode', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'CarGurus',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'CarGurus', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'CarFax',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'CarFax', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'CarZing',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'CarZing', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        className: 'internet-leads-group',
        accessorKey: 'CommercialTruckTrader',
        header: TextCellHOC({ text: 'Commercial Truck trader', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'DealerCenter',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'DealerCenter', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'DealerRater',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'DealerRater', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        accessorKey: 'Ebay',
        loading: 'big_line',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'Ebay', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'Edmunds',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'Edmunds', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'Goxee',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'Goxee', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'HomeNet',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'HomeNet', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'TrueCar',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'TrueCar', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        accessorKey: 'Ove',
        loading: 'big_line',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'Ove', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'OfferUp',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'OfferUp', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'WestLakeFinancial',
        className: 'internet-leads-group',
        header: TextCellHOC({ text: 'WestLakeFinancial', withTooltip: true })
      }
    ] as Array<ColumnDef<ReportLeadsByVehicleRowData>>
  },
  {
    sortable: false,
    id: 'otherGroup',
    header: 'Other leads',
    className: 'other-leads-group',
    columns: [
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'CoBuyer',
        className: cnx('other-leads-group', 'header-indent'),
        header: TextCellHOC({ text: 'CoBuyer', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'DealerMgmtSys',
        className: 'other-leads-group',
        header: TextCellHOC({ text: 'DMS', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'GeneralReferral',
        className: 'other-leads-group',
        header: TextCellHOC({ text: 'General referral', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'ListImport',
        className: 'other-leads-group',
        header: TextCellHOC({ text: 'List import', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'Manufacturer',
        className: 'other-leads-group',
        header: TextCellHOC({ text: 'Manufacturer', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'ServiceReferral',
        className: 'other-leads-group',
        header: TextCellHOC({ text: 'Service referral', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'Sasdesk',
        className: 'other-leads-group',
        header: TextCellHOC({ text: 'Sasdesk', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        accessorKey: 'Web',
        loading: 'big_line',
        className: 'other-leads-group',
        header: TextCellHOC({ text: 'Web', withTooltip: true })
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        accessorKey: 'Other',
        loading: 'big_line',
        className: 'other-leads-group',
        header: TextCellHOC({ text: 'Other', withTooltip: true })
      }
    ] as Array<ColumnDef<ReportLeadsByVehicleRowData>>
  }
]

export default columnDefinitions
