import { css } from '@emotion/css'

export default css(`
  .deal-recap-link {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-decoration-line: underline;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }
`)
