import type { FC, ReactNode } from 'react'
import { cn } from '@carfluent/common'
import { observer } from 'mobx-react-lite'

import PendingDealsNotifications from 'components/common/NotificationsPresenter'
import Header from 'components/layout/Header'
import NavDrawer from 'components/layout/NavDrawer'

import useStyles from './styles'

interface SidebarLayoutProps {
  children: ReactNode | ReactNode[]
  contentClassName?: string
  isWideContent?: boolean
  isGreyBackgroundPage?: boolean
  isSettingsPage?: boolean
  isReportWithTablePage?: boolean
  isFullPage?: boolean
}

const SidebarLayout: FC<SidebarLayoutProps> = ({
  children,
  contentClassName,
  isWideContent,
  isGreyBackgroundPage,
  isSettingsPage,
  isReportWithTablePage,
  isFullPage
}) => {
  const styles = useStyles()

  const wideContentClassName = isWideContent === true && styles.wideContent
  const fullPageContentClassName = isFullPage === true && styles.fullPage
  const settingsClassName = isSettingsPage === true && styles.settingsPageContent
  const greyBackgroundClassName = isGreyBackgroundPage === true && styles.greyBackgroundPageContent

  return (
    <>
      <PendingDealsNotifications />
      <NavDrawer />
      <div className={cn(styles.wrapper, 'cf-content-wrapper')}>
        <section
          className={cn(styles.content,
            wideContentClassName,
            settingsClassName,
            greyBackgroundClassName,
            contentClassName ?? '',
            fullPageContentClassName
          )}
        >
          <Header />
          {children}
        </section>
      </div>
    </>
  )
}

export default observer(SidebarLayout)
