import { type RefObject, useEffect, useRef } from 'react'

const SCROLL_THRESHOLD = 20

const useScrolledToBottom = (cb: () => void, deps: any[], ref?: RefObject<HTMLElement>): void => {
  const scrollX = useRef(ref?.current?.scrollLeft ?? 0)
  const scrollY = useRef(ref?.current?.scrollTop ?? 0)

  useEffect(() => {
    const targetElement = ref?.current != null ? ref?.current : window

    const onScroll = (): void => {
      const currentScrollX = ref?.current?.scrollLeft ?? window.scrollX
      const currentScrollY = ref?.current?.scrollTop ?? window.scrollY

      /**
       * Don't process horizontal scroll
       */
      if (currentScrollX !== scrollX.current) {
        scrollX.current = currentScrollX
        return
      }

      /**
       * Process only vertical scroll from UP to DOWN.
       */
      if (currentScrollY > scrollY.current) {
        /**
         * currentScrollY is subpixel precise for window,
         * Math.ceil fix case when scrollY isn't a whole number (e.g. when zoom)
         */
        const isBottomReached = ref?.current != null
          ? (ref.current.scrollHeight - ref.current.clientHeight) <= (ref.current.scrollTop + SCROLL_THRESHOLD)
          : Math.ceil(window.innerHeight + window.scrollY + SCROLL_THRESHOLD) >= document.body.offsetHeight

        if (isBottomReached) {
          cb()
        }
      }
      scrollY.current = currentScrollY
    }

    targetElement.addEventListener('scroll', onScroll)

    return () => targetElement.removeEventListener('scroll', onScroll)
  }, deps)
}

export default useScrolledToBottom
