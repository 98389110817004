import type { FC, DetailedHTMLProps, ImgHTMLAttributes } from 'react'
import { useState, useRef, useCallback } from 'react'

import useEffectOnce from 'hooks/useEffectOnce'

interface StatedIconProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  src: string
  activeSrc: string
  isActive?: boolean
  isParentHoverListen?: boolean
  className?: string
  isHoverListen?: boolean
  disabled?: boolean
}

const StatedIcon: FC<StatedIconProps> = ({
  src,
  activeSrc,
  isActive = false,
  isParentHoverListen = true,
  isHoverListen = true,
  className = '',
  onMouseOver: _onMouseOver,
  onMouseOut: _onMouseOut,
  onMouseEnter,
  onMouseLeave,
  ...otherProps
}) => {
  const anchorEl = useRef<HTMLImageElement | null>(null)
  const [isLocalActive, setIsLocalActive] = useState<boolean>(false)

  const onMouseOver = useCallback((e) => {
    if (!isParentHoverListen && isHoverListen) {
      setIsLocalActive(true)
    }

    _onMouseOver?.(e)
  }, [isParentHoverListen, isHoverListen, _onMouseOver])

  const onMouseOut = useCallback((e) => {
    if (!isParentHoverListen && isHoverListen) {
      setIsLocalActive(false)
    }

    _onMouseOut?.(e)
  }, [isParentHoverListen, isHoverListen, _onMouseOut])

  useEffectOnce(() => {
    const mouseOverCb = (): void => setIsLocalActive(true)
    const mouseOutCb = (): void => setIsLocalActive(false)

    if (
      anchorEl?.current != null &&
      isParentHoverListen &&
      isHoverListen
    ) {
      anchorEl.current?.parentElement?.addEventListener('mouseover', mouseOverCb)
      anchorEl.current?.parentElement?.addEventListener('mouseout', mouseOutCb)
    }

    return () => {
      if (
        anchorEl?.current != null &&
        isParentHoverListen &&
        isHoverListen
      ) {
        anchorEl.current?.parentElement?.removeEventListener('mouseover', mouseOverCb)
        anchorEl.current?.parentElement?.removeEventListener('mouseout', mouseOutCb)
      }
    }
  }, [isParentHoverListen])

  return (
    <img
      {...otherProps}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={anchorEl}
      className={className}
      src={(isActive || isLocalActive) ? activeSrc : src}
      alt='icon'
    />
  )
}

export default StatedIcon
