import { css } from '@emotion/css'

export default css(`
  .form-fields {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
    position: relative;
  }
  
  .tax-mode-field {
    .radio-item {
      padding: 0px;
      margin: 0px;
      
      .MuiIconButton-root {
        padding: 0px;
        padding-right: 8px;
      }
    }
    
    .radio-item:first-of-type {
      padding-right: 34px;
    }
  }
  
  .sales-tax-label {
    font-family: Roboto;
    position: absolute;
    left: 16px;
    bottom: -20px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4;
    color: rgba(33, 33, 33, 0.4)
  }
  
  .apply-button-wrapper {
    display: flex;
    justify-content: flex-end;

    & .btn-apply {
      max-width: 84px;
      min-width: 84px;
      height: 26px;
      padding: 0px 18px;
      border-radius: 8px;
      
      .cf-button-content {
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #FFF;
      }
      
      &:disabled {
        background: transparent;
        text-decoration: none;
      }
    }
  }
`)
