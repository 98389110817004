import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  display: flex;
`)

export const POPOVER_CLASS_NAME = css(`
  width: 259px!important;

  .cf-dropdown-listbox { min-width: 259px;  }
`)
