import { css } from '@emotion/css'

export default css(`
  width: 100%;
  display: flex;
  flex-direction: column;

  .row-skeleton {
    display: flex;

    &:first-of-type {
      margin-top: 16px;
      margin-bottom: 24px;
    }
  }

  .cell-skeleton {
    flex: 1;

    &:not(:last-of-type) {
      margin-right: 16px;
    }

    .MuiSkeleton-text {
      width: 100%;
      height: 207px;
    }
  }
`)
