import { formatCurrency, parseDateStringUTC } from '@carfluent/common'

export const formatDate = (dateString: string): string => {
  const date = parseDateStringUTC(dateString)
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const month = monthNames[date?.getMonth() ?? 0]
  const day = date?.getDate()

  return `${month} ${day ?? ''}`
}

export const leadsTickFormatter = (value: number): string => value % 1 === 0 ? value.toString() : ''
export const priceTickFormatter = (price: number): string => formatCurrency(price)
