import { css } from '@emotion/css'

export interface StylesProps {
  isActive?: boolean
}

export default css(`
  .cf-input {
    padding: 14px 0;

    ::placeholder { color: rgba(33, 33, 33, 0.40); }
  }

  .icon {
    min-width: 24px;
    fill: rgba(33, 33, 33, 0.54);

    &.active-icon {
      fill: #212121;
    }
  }

  .cf-end-adornment {
    display: none;
  }

  .cf-input-container:hover > .cf-end-adornment,
  .cf-input:hover + .cf-end-adornment,
  .cf-input:focus + .cf-end-adornment {
    display: block;
  }

  .white {
    background-color: #fff;
  }

  .gray {
    background-color: #FAFAFA;
  }
`)
