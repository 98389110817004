import { useCallback, useEffect, useRef, useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { type DeepRequired, useForm, useModal } from '@carfluent/common'
import isEqual from 'lodash-es/isEqual'

import ReconAPIProvider from 'api/recon.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useTransitionBlocker from 'hooks/useTransitionBlocker'
import IdentityApiProvider from 'api/identity.api'
import { SortOrder } from 'api/types'

import type { ReconStepItemFormData, BaseValuesProps, UseReconStepsReturn, UserItemNotifications } from './types'
import { DEFAULT_STEP, Messages, DEFAULT_VALUES } from './constants'
import validationRules, { DEPENDENT_VALIDATIONS } from './validation'
import parseReconSteps from './parser'
import serializeReconSteps from './serializer'

const useReconSteps = (): UseReconStepsReturn => {
  const [isLoading, setIsLoading] = useState(false)
  const [dealerAccounts, setDealerAccounts] = useState<UserItemNotifications[]>([])

  const [reconStepIdsToDelete, setReconStepIdsToDelete] = useState<Set<number>>(new Set())
  const [currentReconStepIdToDelete, setCurrentReconStepIdToDelete] = useState<number | null>(null)

  const refOriginalSteps = useRef<ReconStepItemFormData[]>([])
  const refIsFormSaved = useRef(false)

  const { showAlert } = useCustomSnackbar()

  const {
    isModalOpen: isDeleteStepModalOpen,
    onOpenModal: _onOpenDeleteStepModal,
    onCloseModal: _onCloseDeleteStepModal
  } = useModal()

  const {
    isModalOpen: isUnsavedChangesOpen,
    onOpenModal: onOpenUnsavedChanges,
    onCloseModal: onCloseUnsavedChanges
  } = useModal()

  // ========================================== //
  //                   FORM                     //
  // ========================================== //

  const submitAction = useCallback(async (values: DeepRequired<BaseValuesProps>) => {
    const reconStepsToCreate = values.reconSteps.filter(item => (
      !refOriginalSteps.current.some(originalItem => originalItem.id === item.id) &&
      !reconStepIdsToDelete.has(item.id)
    ))

    const reconStepsToUpdate = values.reconSteps.filter(item => (
      refOriginalSteps.current.some(originalItem => originalItem.id === item.id) &&
      !isEqual(refOriginalSteps.current.find(originalItem => originalItem.id === item.id), item)
    ))

    const reconStepIdsToDeleteFiltered = Array.from(reconStepIdsToDelete).filter(id => (
      !reconStepsToCreate.some(step => step.id === id)
    ))

    if (
      reconStepsToCreate.length === 0 &&
      reconStepsToUpdate.length === 0 &&
      reconStepIdsToDeleteFiltered.length === 0
    ) {
      return refOriginalSteps.current
    }

    try {
      const payload = serializeReconSteps({
        reconStepsToCreate,
        reconStepsToUpdate,
        reconStepIdsToDelete: reconStepIdsToDeleteFiltered
      })

      const { items } = await ReconAPIProvider.updateReconStepsList(payload)
      const reconSteps = parseReconSteps(items)

      showAlert(Messages.SuccessUpdate, { variant: 'success' })

      refIsFormSaved.current = true
      refOriginalSteps.current = reconSteps

      setReconStepIdsToDelete(new Set())
      return reconSteps
    } catch {
      showAlert(Messages.ErrorUpdate)
      refIsFormSaved.current = false
      return []
    }
  }, [reconStepIdsToDelete, showAlert])

  const onActionResult = useCallback(({ result: res }, resetForm) => {
    resetForm({
      reconSteps: res
    })
  }, [])

  const form = useForm<DeepRequired<BaseValuesProps>>({
    baseValues: DEFAULT_VALUES,
    validationRules,
    dependentValidations: DEPENDENT_VALIDATIONS,
    isTrackingChanges: true,
    submitAction,
    onActionResult
  })

  const {
    isValid,
    values,
    setValues,
    resetForm,
    onSubmit,
    isFormChanged
  } = form

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onOpenDeleteStepModal = useCallback((id: number): void => {
    setReconStepIdsToDelete(prevItems => new Set([...prevItems, id]))
    setCurrentReconStepIdToDelete(id)
    _onOpenDeleteStepModal()
  }, [_onOpenDeleteStepModal])

  const onCloseDeleteStepModal = useCallback((): void => {
    if (currentReconStepIdToDelete != null) {
      setReconStepIdsToDelete(prevItems => {
        const newSet = new Set(prevItems)
        newSet.delete(currentReconStepIdToDelete)
        return newSet
      })
      setCurrentReconStepIdToDelete(null)
    }

    _onCloseDeleteStepModal()
  }, [currentReconStepIdToDelete, _onCloseDeleteStepModal])

  const onDeleteReconStep = useCallback((): void => {
    if (currentReconStepIdToDelete == null) {
      return
    }
    setReconStepIdsToDelete(prevItems => new Set([...prevItems, currentReconStepIdToDelete]))
    const updatedValues = values.reconSteps
      .filter(item => item.id !== currentReconStepIdToDelete)
      .map((item, index) => ({ ...item, order: index }))

    form.onChange('reconSteps', updatedValues)
  }, [currentReconStepIdToDelete, values, form.onChange])

  const onDragEnd = useCallback((result: DropResult) => {
    if (result.destination == null || result.source.index === result.destination.index) {
      return
    }

    const items = [...values.reconSteps]
    const draggedItem = items[result.source.index]
    const destinationIndex = result.destination.index

    // Remove the dragged item from the original position
    items.splice(result.source.index, 1)

    // Insert the dragged item at the destination index
    items.splice(destinationIndex, 0, draggedItem)

    // Update the order property based on the new positions
    const reorderedItems = items.map((item, index) => ({
      ...item,
      order: index
    })).sort((a, b) => a.order - b.order)

    form.onChange('reconSteps', reorderedItems)
  }, [values, form.onChange])

  const onAddReconStep = useCallback(() => {
    const addedItem = { ...DEFAULT_STEP() }
    const updatedValues = [addedItem, ...values.reconSteps.map(item => ({ ...item, order: item.order + 1 }))]

    form.onChange('reconSteps', updatedValues)
  }, [values, form.onChange])

  const onReset = useCallback((): void => {
    resetForm({
      reconSteps: refOriginalSteps.current
    })
    setReconStepIdsToDelete(new Set())
    setCurrentReconStepIdToDelete(null)
    showAlert('Changes discarded.', { variant: 'success' })
  }, [resetForm, showAlert])

  // ========================================== //
  //            UNSAVED CHANGES MODAL           //
  // ========================================== //

  const { proceedTransition } = useTransitionBlocker({
    shouldBlock: isFormChanged(),
    onBlock: onOpenUnsavedChanges
  })

  const onSaveAndContinueTransition = useCallback(async (): Promise<void> => {
    if (isValid) {
      await onSubmit()
    }

    if (refIsFormSaved.current) {
      proceedTransition()
      onCloseUnsavedChanges()
    } else {
      onCloseUnsavedChanges()
    }
  },
  [
    isValid, onCloseUnsavedChanges,
    onSubmit, proceedTransition
  ])

  const onDontSaveAndContinueTransition = useCallback(async (): Promise<void> => {
    onReset()
    proceedTransition()
    onCloseUnsavedChanges()
  }, [onCloseUnsavedChanges, onReset, proceedTransition])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      setIsLoading(true)

      const { items: users } = await IdentityApiProvider.getUsers(
        [],
        { sortOrder: SortOrder.Descending, sortField: 'phone' }
      )
      setDealerAccounts(users.map(({ id, firstName, lastName, phoneNumber }) => ({
        id,
        name: `${firstName ?? ''} ${lastName ?? ''}`,
        phoneNumber
      })))

      const { items } = await ReconAPIProvider.getReconStepsList()
      const reconSteps = parseReconSteps(items)
      refOriginalSteps.current = reconSteps ?? []
      setValues({ reconSteps }, true)

      setIsLoading(false)
    }

    void runEffect()
  }, [])

  // ========================================== //

  return {
    isLoading,
    reconStepIdToDelete: currentReconStepIdToDelete,
    onAddReconStep,
    onDragEnd,
    onReset,
    dealerAccounts,
    ...form,
    onSavedChangesProps: {
      isUnsavedChangesOpen,
      onCloseUnsavedChanges,
      onDontSaveAndContinueTransition,
      onSaveAndContinueTransition
    },
    deleteModalProps: {
      isDeleteStepModalOpen,
      onOpenDeleteStepModal,
      onCloseDeleteStepModal: onCloseDeleteStepModal,
      onCloseAfterSubmit: _onCloseDeleteStepModal,
      onDeleteReconStep
    }
  }
}

export default useReconSteps
