import { FC } from 'react'
import { FormDropdown } from '@carfluent/common'

import { VehicleStatus } from 'api/types'
import Toggle from 'components/common/Toggle'

import LoaderSkeleton from './components/LoaderSkeleton'
import { VehicleStatusesName, statusesToChoose } from './constants'

import CLASS_NAME from './styles'

export interface VehicleStatusSectionProps {
  toggled: boolean
  isLoading: boolean
  isCarSold: boolean
  isCarDeleted: boolean
  value: {
    id: VehicleStatus
    name: VehicleStatusesName
  } | null
  onChange: (toggled: boolean) => void
  onChangeStatus: (id: VehicleStatus | null) => void
}

const getStatusBlockTitle = (isCarDeleted: boolean, isCarSold: boolean): string => {
  if (isCarSold) {
    return 'Sold'
  } else if (isCarDeleted) {
    return 'Deleted'
  } else {
    return 'Active on website'
  }
}

const VehicleStatusSection: FC<VehicleStatusSectionProps> = ({
  value,
  toggled,
  onChange,
  isLoading,
  isCarSold,
  isCarDeleted,
  onChangeStatus
}) => {
  const isStatusPanelDisabled = isCarDeleted || isCarSold
  if (isLoading) {
    return <LoaderSkeleton />
  }

  return (
    <div className={CLASS_NAME}>
      <div className='activate-toggle'>
        <h2>{getStatusBlockTitle(isCarDeleted, isCarSold)}</h2>
        {
          !isStatusPanelDisabled && (
            <Toggle
              inversed
              toggled={toggled}
              onClick={onChange}
            />
          )
        }
      </div>
      <FormDropdown
        value={value}
        mode='select'
        id='vehicle-statuses'
        className='double-col'
        options={statusesToChoose}
        disabled={isStatusPanelDisabled}
        dataTestId='deal-details-workflow-type'
        onChange={(_, value) => onChangeStatus(value?.id ?? null)}
      />
    </div>
  )
}

export default VehicleStatusSection
