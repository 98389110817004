import { GET_DEFAULT_CAMPAIGN_DETAILS } from 'api/defaults'
import { type CampaignModel } from 'api/types/responses'
import { type ContentFormData } from './types'

export const mapModelOrDefaultToForm = (campaign?: CampaignModel): ContentFormData => {
  return GET_DEFAULT_CAMPAIGN_DETAILS(campaign).contentDetails
}

export const mapFormToModel = (data: ContentFormData): Partial<CampaignModel> => {
  return {
    contentDetails: {
      ...data,
      bodyHtml: null
    }
  }
}
