import type { CustomerLike } from 'utils/accounting/isCustomerLike'
import type { VendorLike } from 'utils/accounting/getVendorName'

import getVendorName from 'utils/accounting/getVendorName'
import getCustomerName from 'utils/accounting/getCustomerName'

import { isPersonalType } from 'utils/deals/workflowTypes'

const _getCustomerName = (el: CustomerLike | null): string | null => {
  const isPersonal = isPersonalType(el?.customerTypeId)
  const isBusiness = !isPersonal

  return isBusiness ? (el?.businessName ?? '') : getCustomerName(el)
}

const getEntityName = (
  customer: CustomerLike | null,
  vendor: VendorLike | null,
  emptyValue: string = 'Unassigned entity'
): string => {
  return _getCustomerName(customer) ?? getVendorName(vendor) ?? emptyValue
}

export default getEntityName
