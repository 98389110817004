import { css } from '@emotion/css'

export default css(`
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
  display: grid;
  // grid-template-rows: repeat(auto, 44px);
  row-gap: 16px;
  
  &.ome-column {
    grid-template-columns: 1fr;
  }
  
  &.two-columns {
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
  }
  
  .list-item-container {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    border-radius: 12px;
    background: #FAFAFA;
  }
  
  .start-adornment {
     display: flex;
     align-items: center;
     gap: 8px;

     span {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.4px;
    }
  }
  
  /**
   * Checkbox field overrides.
   */
  .list-item-container {
    > label {
      margin: 0px;
    }

    .MuiFormControlLabel-label {
      margin-left: 4px;
    }
    
    .cf-checkbox-icon {
      padding: 0px;
    
      svg {
        transform: scale(0.75, 0.75);
      }
    }
    
    .tooltip-content {
      color: #101010;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px; 
    }
  }
`)
