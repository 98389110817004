import type { FC } from 'react'
import type { IconProps } from 'types'

const SendIcon: FC<IconProps> = ({ color = '#21212180', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_86546_23931)'>
        <path d='M9.33145 0.666504H6.66478C6.29811 0.666504 5.99811 0.966504 5.99811 1.33317C5.99811 1.69984 6.29811 1.99984 6.66478 1.99984H9.33145C9.69811 1.99984 9.99811 1.69984 9.99811 1.33317C9.99811 0.966504 9.69811 0.666504 9.33145 0.666504ZM7.99811 9.33317C8.36478 9.33317 8.66478 9.03317 8.66478 8.6665V5.99984C8.66478 5.63317 8.36478 5.33317 7.99811 5.33317C7.63145 5.33317 7.33145 5.63317 7.33145 5.99984V8.6665C7.33145 9.03317 7.63145 9.33317 7.99811 9.33317ZM12.6848 4.9265L13.1848 4.4265C13.4381 4.17317 13.4448 3.75317 13.1848 3.49317L13.1781 3.4865C12.9181 3.2265 12.5048 3.23317 12.2448 3.4865L11.7448 3.9865C10.7114 3.15984 9.41145 2.6665 7.99811 2.6665C4.79811 2.6665 2.07811 5.3065 1.99811 8.5065C1.91145 11.8932 4.62478 14.6665 7.99811 14.6665C11.3181 14.6665 13.9981 11.9798 13.9981 8.6665C13.9981 7.25317 13.5048 5.95317 12.6848 4.9265ZM7.99811 13.3332C5.41811 13.3332 3.33145 11.2465 3.33145 8.6665C3.33145 6.0865 5.41811 3.99984 7.99811 3.99984C10.5781 3.99984 12.6648 6.0865 12.6648 8.6665C12.6648 11.2465 10.5781 13.3332 7.99811 13.3332Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_86546_23931'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SendIcon
