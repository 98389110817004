import { type FC } from 'react'
import { cnx, FormDropdown, FormNumberInput } from '@carfluent/common'
import { observer } from 'mobx-react-lite'

import Button from 'components/common/Button'
import Toggle from 'components/common/Toggle'
import WarningsDialog from './components/WarningsDialog'

import useAccountingSettings from './hook'
import ListOfItem from './components/ListOfItem'
import PaymentSection from './components/PaymentSection'
import { PaymentSectionType } from './components/PaymentSection/types'

import CLASS_NAME from './styles'

const PRINT_OFFSET_LIMIT = 99

const SettingsGeneral: FC = () => {
  const {
    generalSettingsTab: {
      fiscalYearList,
      fiscalYearStartMonths,
      isAccountingEnabled,
      isWarningsModalOpen,
      confirmModal,
      values,
      warnings,
      activeItem,
      isLoading,
      onChange,
      onCloseWarningsModal,
      onEnable,
      onChangeActiveItem,
      onCloseYear,
      onSaveField,
      onUpdateLockStatus
    }
  } = useAccountingSettings()

  return (
    <div className={cnx(CLASS_NAME, 'g-submenu-content', 'g-margin-top-big')}>
      <h5>General</h5>

      <h6 className='g-section-title'>
        Fiscal year
      </h6>

      <div className='dropdown-container'>
        <FormDropdown
          id='fiscalYearStartMonth'
          options={fiscalYearStartMonths}
          value={fiscalYearStartMonths.find((el) => el.id === values.fiscalYearStartMonth)}
          disableClearable
          mode='select'
          dataTestId='fiscal-year-start-months-account-setting'
          onChange={(id, value) => onChange(id, value?.id)}
          disabled={isAccountingEnabled}
        />
      </div>

      {isAccountingEnabled && (
        <div className='payment-section-container'>
          <PaymentSection />
          <PaymentSection paymentSectionType={PaymentSectionType.VehicleCost} />
        </div>
      )}
      {isAccountingEnabled && (
        <>
          <div className='g-horizontal-line' />

          <h6 className='g-section-title'>
            Check number
          </h6>

          <Toggle
            className='toggle-print-check-number'
            inactiveLabel='Print check number'
            toggled={!values.printCheckNumber}
            onClick={(value) => onSaveField('printCheckNumber', !value)}
          />

          <h6 className='g-section-title'>
            Fine-tune alignment
          </h6>

          <p className='print-check-info'><span>Adjust the amount</span> inside the large square to the place where it appears on your printout. This lets CarFluent figure out how to adjust the alignment.</p>

          <div className='print-check-form'>
            <FormNumberInput
              id='printHorizontalOffset'
              isEmptyAllowed={false}
              isNegativeAllowed
              label='Horizontal'
              min={-PRINT_OFFSET_LIMIT}
              max={PRINT_OFFSET_LIMIT}
              onChange={onSaveField}
              value={values.printHorizontalOffset}
            />

            <FormNumberInput
              id='printVerticalOffset'
              isEmptyAllowed={false}
              isNegativeAllowed
              label='Vertical'
              min={-PRINT_OFFSET_LIMIT}
              max={PRINT_OFFSET_LIMIT}
              onChange={onSaveField}
              value={values.printVerticalOffset}
            />
          </div>

          <div className='g-horizontal-line' />

          <h6 className='section-title'>
            Month close procedures
          </h6>

          <ListOfItem
            activeItem={activeItem}
            items={fiscalYearList}
            isLoading={isLoading}
            confirmModal={confirmModal}
            onChangeActiveItem={onChangeActiveItem}
            onUpdateLockStatus={onUpdateLockStatus}
            onCloseYear={onCloseYear}
          />
        </>
      )}

      <WarningsDialog
        isModalOpen={isWarningsModalOpen}
        onCloseModal={onCloseWarningsModal}
        warnings={warnings}
      />

      {!isAccountingEnabled && (
        <Button
          className='btn-enable-accounting'
          onClick={onEnable}
        >
          Enable accounting
        </Button>
      )}
    </div>
  )
}

export default observer(SettingsGeneral)
