import { useMemo } from 'react'
import { type FormValidation } from '@carfluent/common'

import { type TransactionFormData } from 'types'
import { TransactionTypeId } from 'api/types'

import journalEntryValidationRules from './journalEntryValidator'
import receivableValidateRules from './receivableValidator'
import receiveValidationRules from './receiveValidator'
import checkValidateRules from './checkValidator'
import payableValidateRules from './payableValidator'
import recurringPayableTemplateValidationRules from './recurringPayableTemplateValidation'
import recurringJournalEntryTemplateValidatorRules from './recurringJournalEntryTemplateValidator'

export interface UseValidationProps {
  isRecurring?: boolean
  transactionTypeId: number | null
}

export type UseValidationReturn = FormValidation<TransactionFormData>

const useValidation = ({
  isRecurring = false,
  transactionTypeId
}: UseValidationProps): UseValidationReturn => {
  return useMemo(() => {
    switch (transactionTypeId) {
      case TransactionTypeId.Receive: {
        return receiveValidationRules
      }

      case TransactionTypeId.Receivable: {
        return receivableValidateRules
      }

      case TransactionTypeId.Payable: {
        return isRecurring ? recurringPayableTemplateValidationRules : payableValidateRules
      }

      case TransactionTypeId.PayBill:
      case TransactionTypeId.Check: {
        return checkValidateRules
      }

      case TransactionTypeId.JournalEntry:
      default: {
        return isRecurring ? recurringJournalEntryTemplateValidatorRules : journalEntryValidationRules
      }
    }
  }, [transactionTypeId, isRecurring])
}

export default useValidation
