import { type DealFeesDto, ProductType } from 'api/types'
import { DealCoveragesSettingsIds, type DealFeesSettingsIds } from './DealFeesAndCoveragesSettingsModel'

export type ReplacedProps =
  | 'other1'
  | 'other1Description'
  | 'other2'
  | 'other2Description'
  | 'other3'
  | 'other3Description'
  | 'stateSalesTaxPercent'

export interface OtherChargeModel {
  amount: number | null
  description: string | null
  id: number
}

export type DealFeesModel = Omit<DealFeesDto, ReplacedProps> & {
  others: OtherChargeModel[]
}

export type DealFeesIds = Omit<keyof DealFeesModel, 'others'>
export type DealCoveragesIds = ProductType

export const DealFeesSettingsToValuesMap: Record<DealFeesSettingsIds, DealFeesIds> = {
  buyerTagSetting: 'buyerTag',
  carDeliverySetting: 'carDelivery',
  dealerHandlingFeeSetting: 'dealerHandlingFee',
  dealerInventoryTaxSetting: 'dealerInventoryTax',
  emissionsSetting: 'emissions',
  inspectionFeeSetting: 'inspectionFee',
  plateTransferFeeSetting: 'plateTransferFee',
  registrationFeeSetting: 'registrationFee',
  titleFeeSetting: 'titleFee'
}

export const DealProductTypesToSettingsMap: Record<DealCoveragesIds, DealCoveragesSettingsIds> = {
  [ProductType.AppearanceProtection]: 'appearanceProtectionTaxable',
  [ProductType.CeramicCoating]: 'appearanceProtectionTaxable',
  [ProductType.GapInsurance]: 'gapTaxable',
  [ProductType.ServiceContract]: 'serviceContractTaxable',
  [ProductType.Theft]: 'theftTaxable',
  [ProductType.TireAndWheel]: 'tireAndWheelTaxable'
}
