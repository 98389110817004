import { css } from '@emotion/css'

export default css(`
  .cf-multiline-datetime {
    display: flex;
    flex-direction: column;
  }

  p {
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`)
