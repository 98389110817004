import { generateCoverageSectionsData } from 'api/defaults/coverageDefault'

import type { FIPackageFormProps } from './types'

export const getDefaultFormData = (): FIPackageFormProps => ({
  firstPackageName: '',
  secondPackageName: '',
  thirdPackageName: '',

  coverageSections: Array(5).fill(null).map(generateCoverageSectionsData)
})

export enum Notifications {
  ValidationError = 'Please check your inputs.',
  SaveSuccess = 'Changes successfully saved.'
}

export const FIELD_TRANSFORMS = {
  firstPackageName: (value: string) => value.trimStart(),
  secondPackageName: (value: string) => value.trimStart(),
  thirdPackageName: (value: string) => value.trimStart()
}
