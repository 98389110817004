import { css } from '@emotion/css'

export default css(`
  .cf-modal-content {
    min-height: unset;

    p {
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }
`)
