import { css } from '@emotion/css'
import { px, ScreenWidthBreakpoints } from 'constants/constants'

export default css(`
  --invite-avatar-color: rgba(33, 33, 33, 0.4);
  --avatar-height: 32px;

  .title-and-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--content-margin-default);

    h5 {
      margin-right: 12px;
    }
  }


  .cf-table-header-row:first-child .cf-header-cell-title:first-child { 
    padding-left: 8px;
    color: #101010; 
  }

  .cf-table-row {
    border-top: 2px solid #f3f3f3;

    .cf-cell-wrapper {
      background-color: #FFF;
    }

    :hover {
      .cf-cell-wrapper { 
        background-color: #fafafa;
      }
    }

    .cf-dealer-role-dropdown {
      padding: 6px 12px;

      .MuiInputBase-root {
        padding-top: 13px;
        background: #fff;
        border-radius: 8px;
        border: 0px;
      }

      .MuiAutocomplete-endAdornment {
        visibility: hidden;
      }
    }

    :hover {
      .cf-dealer-role-dropdown {
        .MuiInputBase-root {
          border: 1px solid rgba(0, 0, 0, 0.12);
        }
        
        .MuiAutocomplete-endAdornment {
          visibility: visible;
        }
      }
    }

    .cf-table-cell {
      :first-child .cf-cell-wrapper { padding-left: 4px; }
      :last-child {
        min-width: 56px;
        max-width: 56px;
      }

      > .cf-invite-cell:before {
        content: '';
        width: var(--avatar-height);
        height: var(--avatar-height);
        box-sizing: border-box;
        border-radius: 50%;
        border: 2px var(--invite-avatar-color) dashed;
      }
    }
    
    .cf-table-cell:nth-child(1) {
      min-width: 242px;
      max-width: 261px;
      .cf-cell-wrapper {
        width: 100%;
      }
    }
    
    .cf-table-cell:nth-child(3) {
      min-width: 200px;
      max-width: 200px;
      .cf-cell-wrapper {
        width: 100%;
      }
    }

    .cf-table-cell:nth-child(1) {
      .cf-cell-wrapper {
        padding: 16px 8px;
        height: 64px;
        display: grid;
        grid-template-columns: 32px 1.5fr;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-template-areas: "cf-account-cell cf-account-cell";
      }

      .cf-account-cell {
        grid-area: cf-account-cell;
      }

      .cf-tooltip-container {
        max-width: calc(100% - 32px);
      }
    }
    
    .cf-account-cell {
      max-width: 100%;
    }

    .cf-cell-wrapper {
      padding: 16px 8px;
      height: 64px;

      p {
        line-height: 32px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

  .cf-remove-row button {
    color: #101010;
    width: 14px;
    height: 14px;
    padding: 0px;
  }

  .cf-account-cell {
    p {
      margin-left: 16px;
      line-height: var(--avatar-height);
    }

    .avatar {
      width: var(--avatar-height);
      max-width: var(--avatar-height);
      height: var(--avatar-height);
    }
  }

  .cf-invite-cell {
    color: var(--invite-avatar-color);

    .cf-tooltip-container > p {
      margin-left: 16px;
      line-height: var(--avatar-height);
      color: var(--invite-avatar-color);
    }
  }

  @media screen and (max-width: ${px(ScreenWidthBreakpoints.Small)}) {
    td:nth-child(4) {
      .cf-cell-wrapper {
        max-width: 355px;
      }
    }
  }
`)
