import { FC } from 'react'
import usePasswordValidationPanel from './hook'
import { useStyles } from './styles'

interface PasswordValidationPanelProps {
  value: string
  isTouched: boolean
}

const PasswordValidationPanel: FC<PasswordValidationPanelProps> = (props) => {
  const {
    wrapperStyle,
    itemStyle,
    indicatorStyle,
    textStyle
  } = useStyles()

  const {
    minLengthIndicatorStyle,
    upperAndLowerCaseIndicatorStyle
  } = usePasswordValidationPanel(props)

  return (
    <div className={`${wrapperStyle}`}>
      <div className={itemStyle}>
        <span className={`${indicatorStyle} ${minLengthIndicatorStyle}`} />
        <span className={textStyle}>
          at least 6 symbols
        </span>
      </div>
      <div className={itemStyle}>
        <span className={`${indicatorStyle} ${upperAndLowerCaseIndicatorStyle}`} />
        <span className={textStyle}>
          upper & lower case
        </span>
      </div>
    </div>
  )
}

export default PasswordValidationPanel
