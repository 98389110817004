import { css } from '@emotion/css'

export const POPOVER_CLASS_NAME = css`
  padding: 16px !important;
  width: 478px;
  z-index: 1000!important;

  .table-row {
    display: grid;
    grid-template-columns: 100px 130px 184px;
    column-gap: 16px;
    margin-top: 8px;
    
    &.header {
      p {
        font-size: 12px;
        color: rgba(33, 33, 33, 0.50);
        line-height: 16px;
      }
    }
    
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      line-height: 20px;
      
      a {
        letter-spacing: 0.1px;
        color: #458FFF;
        
        :hover {
          text-decoration: none;
        }
      }
    }
  }
`
