import { type FC, type FocusEvent, type ChangeEvent, useRef } from 'react'
import { FormMaskedInput } from '@carfluent/common'
import useToggler from 'hooks/useToggler'
import { formatNumberByMask } from './utils'
import CLASS_NAME from './styles'

/**
 * TODO: move ssn mask toggle visibility logic to Common Lib
 */

const SSN_LENGTH = 9

export interface InputSSNProps {
  id: string
  label: string
  isDisabled?: boolean
  isInitialSsnVisible?: boolean
  value: string | null
  error: string | null
  touched: boolean
  onBlur: (evt: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  onChange?: (id: string, value: string) => void
  onFocus?: (evt: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

const InputSSN2: FC<InputSSNProps> = ({
  isDisabled,
  isInitialSsnVisible = false,
  onChange: _onChange,
  onFocus: _onFocus,
  onBlur,
  id,
  label,
  value: _value,
  error,
  touched
}) => {
  const refEl = useRef<HTMLInputElement | null>(null)
  const {
    value: isSsnVisible,
    toggle: toggleVisibility,
    enable: enableVisibility
  } = useToggler(isInitialSsnVisible)

  const isResultSsnVisible = isDisabled === true ? false : isSsnVisible

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const [field, val] = [e.target.getAttribute('id') as string, e.target.value]
    _onChange?.(field, val)
  }

  const value = isResultSsnVisible ? _value : formatNumberByMask(_value)
  const mask = isResultSsnVisible ? '000-00-0000' : '***-**-0000'

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onToggleVisibility = (): void => {
    if (isDisabled === true) {
      return
    }

    toggleVisibility()
  }

  const onFocus = (evt: FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    enableVisibility()
    _onFocus?.(evt)

    // after value change via enableVisibility call we need to manually refocus the input
    setTimeout(() => {
      refEl.current?.focus()
    }, 0)
  }

  return (
    <FormMaskedInput
      id={id}
      label={label}
      inputRef={refEl}
      mask={mask}
      placeholder='___-__-____'
      disabled={isDisabled}
      value={value ?? null}
      error={error}
      touched={touched}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      className={CLASS_NAME}
      endAdornment={(value == null || value.length < SSN_LENGTH)
        ? null
        : (
          <span onClick={onToggleVisibility}>
            {isSsnVisible ? 'Hide' : 'Show'}
          </span>
          )}
    />
  )
}

export default InputSSN2
