import { type FC, useRef, ReactNode } from 'react'
import { cnx } from '@carfluent/common'

import useHorizontalScrollableList from './hook'
import CLASS_NAME from './styles'

export interface HorizontalScrollableListProps {
  className?: string
  children?: ReactNode | ReactNode[]
  isScrollVisible?: boolean
}

const HorizontalScrollableList: FC<HorizontalScrollableListProps> = ({
  className,
  children,
  isScrollVisible = false
}) => {
  const scrollableRef = useRef<HTMLDivElement>(null)
  useHorizontalScrollableList({ scrollableRef })

  return (
    <div className={cnx(CLASS_NAME, isScrollVisible && 'visible-scroll', className)}>
      <div className='scrollable' ref={scrollableRef}>
        {children}
      </div>
    </div>
  )
}

export default HorizontalScrollableList
