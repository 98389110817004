import { css } from '@emotion/css'

const ICON_SIZE = 18

export default css(`
  display: flex;

  .radio-item {
    padding: 0;

    .cf-icon {
      width: 18px;
      height: 18px;
    }

    .cf-icon {
      width: ${ICON_SIZE}px;
      height: ${ICON_SIZE}px;
    }
    
    .cf-checked-icon:before {
      width: ${ICON_SIZE}px;
      height: ${ICON_SIZE}px;
    }
  }

  .MuiFormControlLabel-root:not(:last-of-type) {
    margin-right: 24px;
  }

  .MuiTypography-body1 {
    color: #101010;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`)
