import { type FC } from 'react'
import { Button, cnx, formatCurrencyAccounting } from '@carfluent/common'
import InfoOutlined from '@material-ui/icons/InfoOutlined'

import PaymentInfo from 'components/deals/PaymentInfo'

import { type DealPaymentsSectionProps } from './types'
import CLASS_NAME from './styles'

const DealPaymentsSection: FC<DealPaymentsSectionProps> = ({
  className,
  customerBalance,
  isFinancing,
  lienholderBalance,
  onOpenConfirmCashPayment,
  payments,
  shouldBeConfirmed,
  totalAmount,
  isFullyPaid
}) => {
  return (
    <div className={cnx(CLASS_NAME, className)}>
      <div className='finances-payments-header'>
        <div className='g-h7-title'>Payments</div>

        {shouldBeConfirmed && (
          <Button onClick={onOpenConfirmCashPayment} variant='text'>
            RECEIVE CASH
          </Button>
        )}
      </div>

      {(payments.length === 0) && (
        <div className='finances-no-payments'>
          No records yet
        </div>
      )}

      {(payments.length > 0) && (
        <PaymentInfo
          className='finances-payment-info'
          isLargeStatusInfo={false}
          isTotalBalanceDueVisible={false}
          payments={payments}
          totalAmount={totalAmount}
        />
      )}

      <div className='finances-balance-row'>
        <div>Customer balance</div>
        <div>{formatValue(customerBalance)}</div>
      </div>

      {isFinancing && (
        <div className='finances-balance-row'>
          <div>Lienholder balance</div>
          <div>{formatValue(lienholderBalance)}</div>
        </div>
      )}
      {isFullyPaid && (
        <div className='more-info'>
          <InfoOutlined fontSize='small' />
          Pending balances less than $1 are considered 'fully paid'.
        </div>
      )}
    </div>
  )
}

export default DealPaymentsSection

// ========================================== //

const formatValue = (
  value: string | number | null | undefined,
  emptyValues = '$0.00'
): string => {
  return formatCurrencyAccounting(value, { emptyValues, allowNegative: true })
}
