import { useCallback } from 'react'
import { useForm, UseFormReturn, isOk } from '@carfluent/common'

import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { TaskMode } from 'pages/crm/LeadDetailsView/hook/types'
import type { RespondOnLeadTaskFormData } from 'pages/crm/LeadDetailsView/hook/types'
import { Messages } from 'pages/crm/LeadDetailsView/components/LeadDetailsTasks/components/TaskComplete/hook/constants'

import { getDefaultFormData } from './constants'
import respondOnLeadFormValidation from './validator'

export interface CompleteTaskFormProps {
  taskMode: TaskMode | null
  taskId: number
  onSubmit?: (values: RespondOnLeadTaskFormData, taskId: number) => Promise<void>
  onCloseTask: (taskId: number | null, taskMode?: TaskMode) => void
}

export interface UseCompleteTaskFormReturn extends UseFormReturn<RespondOnLeadTaskFormData> {
  isLoading: boolean
  taskMode: TaskMode | null
}

const defaultValues = getDefaultFormData()

const useRespondOnLeadForm = ({
  taskId,
  taskMode,
  onSubmit,
  onCloseTask
}: CompleteTaskFormProps): UseCompleteTaskFormReturn => {
  const { showSuccess } = useCustomSnackbar()

  const submitAction = useCallback(async (values: RespondOnLeadTaskFormData): Promise<void> => {
    if (onSubmit != null) {
      await onSubmit(values, taskId)
    }
  }, [taskId, onSubmit])

  const onActionResult = useCallback((res) => {
    onCloseTask?.(taskId, taskMode as TaskMode)
    if (isOk(res)) {
      showSuccess(Messages.SuccessTaskComplete)
    }
  }, [taskId, taskMode, showSuccess, onCloseTask])

  const form = useForm<RespondOnLeadTaskFormData>({
    baseValues: defaultValues,
    onActionResult,
    submitAction,
    validationRules: respondOnLeadFormValidation
  })

  const { isSubmitting } = form

  return {
    isLoading: isSubmitting,
    taskMode,
    ...form
  }
}

export default useRespondOnLeadForm
