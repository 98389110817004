import { DictionaryItems } from 'api/types/utility.types'

import { VehicleTagIds } from 'pages/inventory/constants'

export const mapToTagItems = (tags: DictionaryItems<string>, tagIds: VehicleTagIds[]): string[]=> {
  return tagIds?.map(tagId => {
    const tag = tags.find(({ id }) => id === tagId)

    return tag?.name ?? ''
  })
}
