import { useCallback } from 'react'
import omit from 'lodash-es/omit'

import { PAGE_SIZE } from 'constants/constants'
import parseListData from './parser'
import serializeFilters from './serializer'

import AccountingApiProvider from 'api/accounting.api'
import useTableApi from 'hooks/useTableApi'
import { type UseTableApiReturn } from 'hooks/useTableApi/types'
import {
  type BankStatementTransactionsListPayload,
  type ListResponse,
  BankStatementTransactionState,
  BankStatementTransactionType
} from 'api/types'

import {
  API_CALL_DELAY_SEARCH,
  DEFAULT_FILTERS,
  DEFAULT_SORTING,
  DEFAULT_COUNTERS
} from './constants'

import {
  type BankStatementRowData,
  type BankStatementsListFilters,
  type BankStatementFromApi,
  type UseApiClientProps
} from './types'

export { PAGE_SIZE } from 'constants/constants'

const useBankStatementsTable = ({
  accountId,
  setIsFiltersChanged,
  setTabCounters
}: UseApiClientProps): UseTableApiReturn<BankStatementRowData, BankStatementsListFilters> => {
  const getBankStatementTransactions = useCallback(async (
    _payload: BankStatementTransactionsListPayload
  ): Promise<ListResponse<BankStatementFromApi>> => {
    if (accountId == null) {
      return { items: [] }
    }

    try {
      const payload = {
        accountId,
        transactionType: BankStatementTransactionType.All,
        transactionState: BankStatementTransactionState.ForReview,
        ..._payload
      }

      const [countersResp, listResp] = await Promise.all([
        AccountingApiProvider.getBankStatementTransactionsTotal(omit(payload, 'transactionState')),
        AccountingApiProvider.getBankStatementTransactions(payload)
      ])

      const isFiltersChanged = (payload.search !== '') ||
        (payload.amount?.from != null) ||
        (payload.amount?.to != null) ||
        (payload.date?.to != null) ||
        (payload.date?.from != null) ||
        (payload.transactionType !== BankStatementTransactionType.All)

      setIsFiltersChanged(isFiltersChanged)
      setTabCounters(countersResp)

      return { ...listResp }
    } catch (err) {
      setTabCounters(DEFAULT_COUNTERS)
      return { items: [] }
    }
  }, [accountId, setIsFiltersChanged])

  return useTableApi<BankStatementFromApi, BankStatementsListFilters, BankStatementRowData>({
    apiCallDelay: API_CALL_DELAY_SEARCH,
    defaultFilters: DEFAULT_FILTERS,
    defaultSorting: DEFAULT_SORTING,
    getList: getBankStatementTransactions,
    pageSize: PAGE_SIZE,
    parseListData,
    serializeFilters,
    shouldRunOnCall: true
  })
}

export default useBankStatementsTable
