import { css } from '@emotion/css'

interface StylesReturn {
  dialogPaper: string
}

const createStyleClass = (): StylesReturn => {
  return {
    dialogPaper: css(`
      & {
        box-sizing: border-box;
        width: 618px !important;
        max-width: 618px !important;
        
        * {
          font-family: Roboto;
          font-style: normal;
        }
        
        .cf-dialog-title-content {
          text-align: center;
          font-weight: 400;
          font-size: 34px;
          line-height: 36px;
          color: #101010;
        }
        
        .cf-content-header {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.15px;
          padding-bottom: 6px;
          color: #101010;
        }

        .cf-warning-line {
          margin-bottom: 16px;
        }

        .cf-warning-line:last-of-type {
          margin-bottom: 0;
        }

        .cf-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #101010;
          display: inline;
        }

        .cf-text.cf-rows {
          font-size: 18px;
          font-weight: 600;
        }

        .cf-btn-ok {
          width: 294px;
          height: 56px;
        }
      }
    `)
  }
}

export default createStyleClass
