import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import BankStatementBannerItem from 'components/accounting/BankStatementBannerItem'
import BankingAccountInfo from 'components/accounting/BankingAccountInfo'

import { type BankingBannersInfoProps } from './types'
import CLASS_NAME from './styles'

const BankingBannersInfo: FC<BankingBannersInfoProps> = ({
  bankStatements,
  className,
  clearedAccounts,
  reconciledAccounts
}) => {
  return (
    <div className={cnx(CLASS_NAME, className)}>
      {(bankStatements.length > 0) && (
        <div className='bank-statement-banner-container'>
          {bankStatements.map((item) => (
            <BankStatementBannerItem
              key={item.id}
              accountName={item.accountName}
              price={item.change}
              date={item.date}
              description={item.description}
            />
          ))}
        </div>
      )}

      {(clearedAccounts.length > 0) && (
        <div className='accounts-info-container'>
          <div className='title'>Cleared Accounts</div>

          {clearedAccounts.map((item) => (
            <BankingAccountInfo
              key={item.id}
              accountName={item.name}
              change={item.change}
              isReconciled={false}
            />
          ))}
        </div>
      )}

      {(reconciledAccounts.length > 0) && (
        <div className='accounts-info-container'>
          <div className='title'>Reconciled Accounts</div>

          {reconciledAccounts.map((item) => (
            <BankingAccountInfo
              key={item.id}
              accountName={item.name}
              change={item.change}
              isReconciled
              reconciledOnDate={item.reconciledOnDate}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default BankingBannersInfo
