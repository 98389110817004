import { type FC, useCallback } from 'react'
import { cnx, formatters, Loader } from '@carfluent/common'

import { DATETIME_US_SHORT_YEAR_FORMAT } from 'constants/constants'
import { getHoursMinutesTime } from 'utils/parse_date'
import CompleteTask from 'pages/crm/LeadDetailsView/components/LeadDetailsTasks/components/TaskComplete'
import EditDeletePopover from 'pages/crm/LeadDetailsView/components/EditDeletePopover'
import { FieldIds } from 'pages/crm/LeadDetailsView/hook/constants'
import { TaskDueDateState } from 'pages/crm/LeadDetailsView/hook/utils'
import DeleteModal from 'components/dialogs/DeleteModal'
import CreatedCompleteLayout from 'components/crm/CreatedCompleteLayout'
import OpenTaskForm from '../OpenTaskForm'
import TaskTitle from '../TaskTitle'
import RespondOnLeadTask from '../RespondOnLeadTask'
import EmailReceivedTask from '../EmailReceivedTask'
import MessageReceivedTask from '../MessageReceivedTask'
import { type TaskProps, TaskMode, TaskType } from './hook/types'
import useTask from './hook'

const { formatLocalDate } = formatters

const Task: FC<TaskProps> = (props) => {
  const {
    assignedTo, reminderSubjects, taskPriorities,
    isLastTaskInList, businessHours,
    onSubmitCompleteTask, onSubmitRespondTask,
    onOpenViewChatPopover, onSubmitRespondOnLeadTask
  } = props

  const taskId = props.task.id
  const {
    isReminder, taskMode, dueDate,
    taskDueDateState, taskTypeId,
    assignedUser, description,
    onCloseTask, onViewEmailClick,
    isFormSubmitting, onChange, values,
    onChangeTaskMode,
    touched, onSubmit, onDelete, errors,
    onBlur, dateTimeInputsProps,
    onDeleteTaskClick, onEditTaskClick,
    isModalOpen, onCloseModal, onSubmitModal,
    onRedirectToCreditApp
  } = useTask(props)

  const dueDateValue = values?.dueDate ?? dueDate
  const timeValue = values.time ?? getHoursMinutesTime(dueDate)
  const completeTaskDueDateValue = formatLocalDate(dueDate, DATETIME_US_SHORT_YEAR_FORMAT)

  const onConfirmDeleteModal = useCallback(async () => await onSubmitModal(), [onSubmitModal])
  const _onChangeTaskMode = useCallback(() => onChangeTaskMode(TaskMode.CompleteTask), [onChangeTaskMode])

  const subject = props.task.subject?.name ?? ''
  const newTaskFormProps = {
    isReminder,
    taskId,
    taskMode,
    onCloseTask,
    isLoading: isFormSubmitting,
    onChange,
    values,
    touched,
    onSubmit,
    onDelete,
    errors,
    onBlur,
    businessHours,
    taskTypeId,
    reminderSubjects,
    taskPriorities,
    assignedTo,
    dateTimeInputsProps: {
      ...dateTimeInputsProps,
      dueDate: dueDateValue,
      time: timeValue
    }
  }

  const editTaskFormProps = {
    ...newTaskFormProps
  }

  const completeTaskProps = {
    taskType: taskTypeId,
    taskId: taskId as number,
    taskMode,
    onCloseTask,
    reminderSubjects,
    taskPriorities,
    subject,
    onSubmit: onSubmitCompleteTask,
    completeLayoutProps: {
      dueTo: completeTaskDueDateValue,
      assignedTo: assignedUser,
      priority: values[FieldIds.Priority],
      description: description,
      onSubmit: _onChangeTaskMode
    }
  }

  const respondTaskProps = {
    taskId: taskId as number,
    taskMode,
    onCloseTask,
    onSubmit: onSubmitRespondTask,
    onEditTaskClick,
    onDeleteTaskClick,
    completeLayoutProps: {
      dueTo: completeTaskDueDateValue,
      assignedTo: assignedUser,
      priority: values[FieldIds.Priority],
      description: description
    }
  }

  const respondOnLeadTaskProps = {
    ...respondTaskProps,
    onSubmit: onSubmitRespondOnLeadTask
  }

  const emailReceivedTaskProps = {
    ...respondTaskProps,
    onSubmitSaveChanges: onSubmit,
    onViewEmailClick
  }

  const messageReceivedTaskProps = {
    ...respondTaskProps,
    onOpenViewChatPopover
  }

  const isCreditAppTask = taskTypeId === TaskType['Credit app received']

  return (
    <>
      <div
        className={cnx('schedule-created-task',
          taskMode === TaskMode.ViewTask ||
          taskMode === TaskMode.EmailReceived ||
          taskMode === TaskMode.ViewRespond ||
          taskMode === TaskMode.MessageReceived
            ? taskDueDateState === TaskDueDateState.Outdated
              ? 'task-outdated-border-color'
              : taskDueDateState === TaskDueDateState.Today
                ? 'task-today-border-color'
                : 'task-upcoming-border-color'
            : '')}
      >
        {isFormSubmitting && <Loader size='large' />}
        {taskMode === TaskMode.NewTask
          ? (
            <div className='white-background'>
              <TaskTitle taskTypeId={taskTypeId} taskMode={taskMode} />

              <OpenTaskForm
                {...newTaskFormProps}
                values={values}
                errors={errors}
                touched={touched}
                onChange={onChange}
                onBlur={onBlur}
                onDelete={onDelete}
                onSubmit={onSubmit}
              />
            </div>)
          : (
            <div className={cnx(taskMode === TaskMode.CompleteTask
              ? taskDueDateState === TaskDueDateState.Outdated
                ? 'task-outdated-border-color'
                : taskDueDateState === TaskDueDateState.Today
                  ? 'task-today-border-color'
                  : 'task-upcoming-border-color'
              : '')}
            >
              {taskId != null && (
                <TaskTitle
                  taskTypeId={taskTypeId}
                  taskMode={taskMode}
                  titleText={taskMode === TaskMode.EditTask ? null : subject}
                />
              )}

              {(taskMode === TaskMode.EditTask) && (
                <OpenTaskForm
                  {...editTaskFormProps}
                  values={values}
                  errors={errors}
                  touched={touched}
                  onChange={onChange}
                  onBlur={onBlur}
                  onDelete={onDelete}
                />
              )}

              {(taskMode === TaskMode.ViewTask) && (taskId != null) && (
                <>
                  <EditDeletePopover
                    onDeleteTaskClick={onDeleteTaskClick}
                    onEditTaskClick={onEditTaskClick}
                    taskId={taskId}
                  />

                  <CreatedCompleteLayout
                    {...completeTaskProps.completeLayoutProps}
                    cancelButtonTitle={
                      isCreditAppTask
                        ? 'View credit app'
                        : undefined
                    }
                    onCancel={
                      isCreditAppTask
                        ? (): void => onRedirectToCreditApp()
                        : undefined
                    }
                    isShowCancelButton={isCreditAppTask}
                  />
                </>)}

              {taskMode === TaskMode.CompleteTask && (
                <CompleteTask
                  {...completeTaskProps}
                />
              )}

              {taskMode === TaskMode.ViewRespond && (
                <RespondOnLeadTask {...respondOnLeadTaskProps} />
              )}

              {taskMode === TaskMode.EmailReceived && (
                <EmailReceivedTask {...emailReceivedTaskProps} />
              )}

              {taskMode === TaskMode.MessageReceived && (
                <MessageReceivedTask {...messageReceivedTaskProps} />
              )}
            </div>
            )}
      </div>

      {isLastTaskInList ? null : <div className='horizontal-divider' />}

      <DeleteModal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        title='Delete task?'
        content='Please note, this step could not be undone'
        onConfirm={onConfirmDeleteModal}
      />
    </>
  )
}

export default Task
