import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  --row-content-height: 40px;
  --cell-photo-width: 99px;
  --cell-links-width: 160px;
  --cell-creditapp-width: 175px;
  --column-gap: 24px;
  --cell-max-width: calc((100% - var(--cell-photo-width) - var(--cell-links-width) - var(--cell-creditapp-width) - (5 * var(--column-gap))) / 3);
  --property-gap: 8px;
  
  width: 100%;
  display: grid;
  grid-template-columns:
    var(--cell-photo-width) repeat(2, var(--cell-max-width)) var(--cell-links-width) var(--cell-creditapp-width) var(--cell-max-width);
  grid-template-rows: 26px 64px;
  grid-template-areas:
    "cell_top cell_top cell_top cell_top cell_top cell_top"
    "cell_photo cell2_2 cell3_2 cell4_2 cell5_2 cell6_2";
  row-gap: 16px;
  column-gap: var(--column-gap);
  align-items: center;
  
  &.g-panel { padding: 16px; }
  
  .line {
    display: flex;
    gap: var(--property-gap);
    
    color: #101010;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px; 
    
    .property-name {
      color: rgba(33, 33, 33, 0.40);
      min-width: 72px;
    }
    
    .property-value {
      max-width: calc(100% - 72px - var(--property-gap)); /* for ellipsis */
    }
  }
  
  .cell-top {
    grid-area: cell_top;
    display: flex;
    gap: 16px;
    
    .deal-title {
      max-width: fit-content;
      
      &.is-loading {
        min-width: 128px;
      }
    }
    
    .deal-chips {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  
  .cell-photo {
    grid-area: cell_photo;
    
    .text-skeleton,
    img {
      width: 99px;
      height: 64px;
      object-fit: cover;
      border-radius: 4px;
    }
  }
  
  .cell-buyer-info {
    grid-area: cell2_2;
    min-height: var(--row-content-height);
  }
  
  .cell-car-info {
    grid-area: cell3_2;
    min-height: var(--row-content-height);
    
    .property-name { min-width: 32px; }
    .property-value { max-width: calc(100% - 32px - var(--property-gap)); }
    
    .line--bottom {
      .property-value { max-width: calc(100% - 32px - var(--property-gap) - 24px); }
    }
  }
  
  .cell-stock {
    grid-area: cell4_2;
    min-height: var(--row-content-height);
    
    .line--top {
      .property-value { max-width: calc(100% - 72px - var(--property-gap) - 24px); }
    }
  }
  
  .cell-credit-app {
    grid-area: cell5_2;
    min-height: var(--row-content-height);
    
    .property-name { min-width: 85px; }
    .property-value { max-width: calc(100% - 85px - var(--property-gap)); }
  }
  
  .cell-workflow-step {
    grid-area: cell6_2;
    min-height: var(--row-content-height);
    
    .property-value { max-width: 100%; }
  }
  
  .copy-button { margin-left: 0px; }
  
  .text-skeleton { height: 16px; }
  
  .skeleton-93.is-loading { width: 93%; }
  .skeleton-80.is-loading { width: 80%; }
  .skeleton-45.is-loading { width: 45%; }
  
  .cell-two-lines {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .website-link {
    color: #458FFF;
    text-decoration: none;

    &:hover { text-decoration: none; }
  }
  
  .no-decoration {
    text-decoration: none;
    &:hover { text-decoration: none; }
  }
  
  .text-bold {
    font-weight: 500;
  }
`)

export const TOOLTIP_CLASS_NAME = css(`
  .tooltip-content.website-link {
    color: #458FFF;
    text-decoration: none;

    &:hover { text-decoration: none; }
  }
`)
