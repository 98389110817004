import { applyValidators, formatNumber } from '@carfluent/common'
import type { ValidationRule } from '@carfluent/common/dist/hooks'

import { Vehicle, DictionaryItem, WorkflowTypeDto, WorkflowTypeEnum } from 'api/types'
import type { CopyMonthFormData } from 'types'
import { BIRTHDATE_RANGE_END, BIRTHDATE_RANGE_START, MAX_CAR_PRICE_VALUE, ValidationLength } from 'constants/validation'
import parseLeadEmailString from 'utils/parseLeadEmailString'
import type { AddressData, FullAddressParts, FullAddressValidation } from 'types/address'
import type { VehicleDropdownValidation, CreditAppValidationContext } from 'types/validation'
import { type DealerSettingsModel, type CoverageFeeSettingsModel } from 'api/types/responses'
import type { CoverageDefaultSection } from 'types/coverage'

import {
  addressData as addressDataUtil,
  isAlphanumericWithSymbolsAndApostrophe,
  isNameValid,
  ruleRequired,
  lessThanOrEqual,
  greaterThanOrEqual,
  ruleIsNotCyrillic,
  isAlphanumericWithSymbols,
  isAlphanumericWithOutSymbols,
  greaterThan,
  lessThanOrEqualDateForStringDate,
  greaterThanOrEqualDateForStringDate,
  isDateOrEmpty,
  greaterThanOrEqualDate,
  lessThanOrEqualDate,
  ruleEndDateAfterStartDate,
  ruleCorrectDate,
  isNumberRequired,
  ruleVinOrEmpty,
  ruleFourNumbersOrEmpty,
  ruleYearNotInFutureOrEmpty,
  ruleMultiSelectNotEmpty,
  ruleAtLeastOneFieldRequired,
  ruleSameAccount,
  rulePhoneNumberOrEmpty,
  ruleSSNOrEmpty,
  isDriverLicenseNumberOrEmpty,
  ruleFiveNumbersOrEmpty,
  ruleEmail,
  ruleEin,
  ruleTextMaxLength,
  exactLength,
  rulePasswordCase,
  ruleCustomerDropdown,
  ruleDealSource,
  ruleDateGE,
  ruleDateGT,
  ruleTradeInCreditRequiredIfVisible,
  ruleLienholderRequiredIfRelated,
  ruleApprovedRateRequiredIfRelated,
  ruleApprovedTermRequiredIfRelated
} from 'utils/validationRules'

import { ErrorMsg } from 'utils/yup'
import { isStringEmpty, isStringNotEmpty } from 'utils/parse_string'
import { hasOtherIncomeOption, CreditAppParts, isHousingOrEmploymentFieldVisible, HousingField } from 'utils/creditApplication'
import getCurrentStartDate from 'utils/common/getCurentStartDate'
import { isPersonalType } from 'utils/deals/workflowTypes'
import { FIPackageFormProps } from 'pages/settings/FISettings/components/FIPackageSetup/hook/types'
import isFieldSupported from './coverage/isFieldSupported'

export interface GeneralSettingsFormData extends Omit<DealerSettingsModel, 'id' | 'websiteBaseUrl'> {
  [key: string]: any
}

export interface WithDriverLicense { driverLicense: string | null }
export type Validator<T> = (val: string | null, ctx?: T) => string | null
export type WithBusinessTypeId = { workflowType: WorkflowTypeDto | null } | { customerTypeId: number}

export enum Errors {
  BusinessEmail = 'Business Email seems to be too long',
  Email = 'Invalid email',
  Empty = 'Cannot be empty',
  Negative = 'Cannot be negative',
  InvalidDate = 'Invalid date',
  IntervalTooLow = 'Interval should not be less than 0',
  IntervalTooBig = 'Interval should not be greater than 100',
  EmptyRadio = 'Please, mark at least one option below',
  EmptyRadioAbove = 'Please, mark one option above',
  InvalidFormat = 'Invalid format',
  LongEmail = 'Lead Email seems to be too long',
  LongName = 'Name is too long',
  LongDescription = 'Description is too long',
  LongTemplateName = 'Name is too long. Maximum 100 characters',
  OnlyLatin = 'Latin alphabet only',
  NotInFuture = 'Date cannot be in the future',
  NotSymbols = 'Should not contain symbols',
  VinCode = 'Wrong VIN number. Please check.',
  AppraisalMaxPriceError = 'Max value $999,999',
  AppraisalMinPriceError = 'Min value -$999,999',
  NoUserRoleSelected = 'Please select a role',
  NoDealersSelected = 'Select at least one dealership above',
  SameAccount = 'The same account cannot be chosen.',
  PhoneNumber = 'Should contain 10 characters',
  SSN = 'Should contain 9 characters',
  EIN = 'Should contain 9 characters',
  DriverLicense = 'Please enter valid driver license number',
  DriverLicenseEmptyState = 'Please select state',
  InvalidZipCode = 'Enter 5 digits',
  InvalidDateFormat = 'Invalid date format',
  FromGreaterThanTo= 'From is greater than To',
  DisabledDate = 'This date is disabled',
  PasswordCase = 'Password must contain uppercase and lowercase letters',
  AddCustomer = 'Please add customer',
  TransactionDueDateAfterDate = 'Cannot be earlier than Date',
  TransactionStartDateNotPast = 'Cannot be in past',
  TransactionEndDateAfterStartDate = 'Should be later than start date',
  DealFirstPaymentDateAfterSaleDate = 'Cannot be before sale date',
  ZeroAmount = 'Cannot be zero',
  EmailOrPhoneRequired = 'Email or phone is required'
}

const NAME_MAX_LENGTH = (maxLength: number): string => `Should contain at most ${maxLength} characters`
const MIN_NAME = `Should contain at least ${ValidationLength.NAME_MIN} letters`
const MAX_EXECUTION_DAY = `Cannot be bigger than ${ValidationLength.EXECUTION_DAYS} days`
const MIN_EXECUTION_DAY = 'Not available'
export const NUMBER_MAX_ERROR = (max: number): string => `Cannot be bigger than ${formatNumber(max, { mantissa: 0 })}`
export const NUMBER_MAX_ERROR_SHORT = (max: number): string => `Max value $${formatNumber(max, { mantissa: 2 })}`
export const STRING_MAX_ERROR = (max: number): string => `Max ${formatNumber(max, { mantissa: 0 })} characters`
export const STRING_MIN_ERROR = (min: number): string => `Min ${formatNumber(min, { mantissa: 0 })} characters`

export const correctRule: ValidationRule<unknown, string> = () => null
export const correctAddressRule: ValidationRule<unknown, FullAddressValidation> = () => ({
  city: null,
  zipCode: null,
  state: null,
  address: null
})

interface LeadCmsEmailsParams {
  value?: string
  isRequired?: boolean
}

// ========================================== //
//               VALIDATION RULES             //
// ========================================== //

const listItemRequiredValidators = [
  ruleRequired(Errors.Empty)
]

const radioRequiredValidator = [
  ruleRequired(Errors.EmptyRadio)
]

const checkboxRequiredValidator = [
  ruleRequired(Errors.EmptyRadio)
]

const businessNameValidators = [
  ruleIsNotCyrillic(Errors.OnlyLatin),
  greaterThanOrEqual(MIN_NAME, ValidationLength.NAME_MIN),
  lessThanOrEqual(Errors.LongName, ValidationLength.BUSINESS_NAME_MAX)
]

const nameValidators = [
  isAlphanumericWithSymbolsAndApostrophe(Errors.NotSymbols),
  greaterThanOrEqual(MIN_NAME, ValidationLength.NAME_MIN),
  lessThanOrEqual(Errors.LongName, ValidationLength.NAME_MAX)
]

const dealerNameValidators = [ruleRequired(Errors.Empty), ...nameValidators]

const checkingAccountValidators = [
  exactLength(Errors.InvalidFormat, ValidationLength.CHECKING_ACCOUNT_LENGTH)
]

const addressDataRequiredValidators = [
  addressDataUtil(ErrorMsg.invalidAddress)
]

const addressDataValidators = [
  addressDataUtil(ErrorMsg.invalidAddress, false)
]

const leadEmailValidators = [
  lessThanOrEqual(Errors.LongEmail, ValidationLength.EMAIL_MAX),
  ruleEmail(Errors.Email)
]

const executionDayValidators = [
  isNumberRequired(Errors.Empty),
  lessThanOrEqual(MAX_EXECUTION_DAY, ValidationLength.EXECUTION_DAYS)
]

const executionDayGreaterZeroValidators = [
  isNumberRequired(Errors.Empty),
  greaterThanOrEqual(MIN_EXECUTION_DAY, ValidationLength.EXECUTION_MIN_DAYS, false),
  lessThanOrEqual(MAX_EXECUTION_DAY, ValidationLength.EXECUTION_DAYS)
]

export const ruleTitleValidators = [
  ruleRequired(Errors.Empty),
  lessThanOrEqual(Errors.LongName, ValidationLength.TITLE_NUMBER_MAX)
]

const jobTitleValidators = [
  lessThanOrEqual(Errors.LongName, ValidationLength.JOB_TITLE_MAX)
]

const employerNameValidators = [
  lessThanOrEqual(Errors.LongName, ValidationLength.EMPLOYER_NAME_MAX)
]

// ========================================== //
//                  VALIDATORS                //
// ========================================== //

export const scheduleCompleteTaskOptionId = (val: string | null): string | null => applyValidators(
  val, radioRequiredValidator
)

export const scheduleCompleteTaskSubOptionIds = (val: string | null): string | null => applyValidators(
  val, checkboxRequiredValidator
)

export const taskTime = (val: string | null): string | null => applyValidators(
  val, listItemRequiredValidators
)

export const dealerName = (val?: string): string | null => applyValidators(
  val, dealerNameValidators
)

export const addressData = (
  val?: FullAddressParts | null
): string | null => applyValidators(val, addressDataValidators)

export const addressDataRequired = (
  val?: FullAddressParts | null
): string | null => applyValidators(val, addressDataRequiredValidators)

export const leadCmsEmails = ({ value, isRequired = true }: LeadCmsEmailsParams): string | null => {
  const emails = parseLeadEmailString(value)
  if (isRequired) {
    if (emails == null || emails.length === 0) {
      return Errors.Empty
    }
  }

  for (const email of emails) {
    const valRes = applyValidators(email, leadEmailValidators)

    if (valRes != null && valRes !== '') {
      return valRes
    }
  }

  return null
}

export const isIntervalLessOrGreater = (val: number | null): string | null => applyValidators(
  val, [required, lessThanOrEqual(Errors.IntervalTooBig, 100, false), greaterThanOrEqual(Errors.IntervalTooLow, 0, false)]
)

export const isEndDateAfterStartDate = (val: Date, context?: CopyMonthFormData): string | null => {
  const startDate = context?.sourceMonth
  return applyValidators(
    val,
    [greaterThanOrEqualDate(ErrorMsg.incorrectEndDate, startDate ?? new Date())]
  )
}

export const executionDay = (val?: number | null): string | null => applyValidators(
  val, executionDayValidators
)

export const executionDayGreaterZero = (val?: number | null): string | null => applyValidators(
  val, executionDayGreaterZeroValidators
)

export const requiredDescription = (val: string | null): string | null => applyValidators(
  val, [lessThanOrEqual(Errors.LongDescription, ValidationLength.TITLE_NUMBER_MAX)]
)

export const ruleTitle = (val: string | null): string | null => applyValidators(
  val, ruleTitleValidators
)

export const required = ruleRequired(Errors.Empty) // AZ-TODO: remove, legacy

export const requiredNumber = (val: number | null): string | null => applyValidators(
  val,
  [required, greaterThan(Errors.Empty, 0)]
)

export const nonNegativeNumber = (val: number | null): string | null => applyValidators(
  val,
  [greaterThanOrEqual(Errors.Negative, 0)]
)

export const requiredNonNegativeNumber = (val: number | null): string | null => applyValidators(
  val,
  [required, greaterThanOrEqual(Errors.Negative, 0)]
)

// ========================================== //
//                REFACTORED                  //
// ========================================== //

export const requiredField = ruleRequired(Errors.Empty)
export const requiredNumberField = isNumberRequired(Errors.Empty)

export const respondLeadTaskOptionId = ruleRequired(Errors.EmptyRadioAbove)

/**
 * `append` means that `required` validator will be last in validation queue.
 * `true` works as prepend.
 */
export type RequiredMode = false | true | 'append'

export interface BuildPresetConfig {
  isRequired?: RequiredMode
  isZeroAllowed?: boolean
}

/**
 * Usage:
 *   In this file:
 *      const myPresetFn = buildPreset([ruleFn1, ruleFn2, ...])
 *
 *   In form validationRules:
 *      const myFieldValidatorFn = myPreset(true) // `true` makes field required
 *
 *      // embedded `required` validator will not validate `0`
 *      const myFieldValidatorFn2 = myPreset({ isRequired: true, isZeroAllowed: true })
 */
export const buildPreset = <V, T = unknown>(_validators: Array<ValidationRule<T>>) =>
  (conf: RequiredMode | BuildPresetConfig = false): ValidationRule<T> => {
    const isRequired = (typeof conf === 'object')
      ? (conf.isRequired ?? false)
      : conf

    const isZeroAllowed = (typeof conf === 'object')
      ? (conf.isZeroAllowed ?? false)
      : false

    const _required = ruleRequired(Errors.Empty, isZeroAllowed)

    return (val: V, context?: T): string | null => {
      const validators = isRequired === 'append'
        ? [..._validators, _required]
        : isRequired ? [_required, ..._validators] : _validators

      return applyValidators<T>(val, validators, context)
    }
  }

export const priceField = (
  upperLimit: number, isRequired: boolean = true, isShortError: boolean = false
): (val?: number | null) => string | null => {
  const errorFn = isShortError ? NUMBER_MAX_ERROR_SHORT : NUMBER_MAX_ERROR
  const validators = [
    lessThanOrEqual(errorFn(upperLimit), upperLimit),
    greaterThanOrEqual(Errors.Negative, 0)
  ]

  if (isRequired) {
    validators.unshift(requiredNumberField)
  }

  return (val?: number | null): string | null => applyValidators(val, validators)
}

export const dateField = buildPreset<Date | string | null | undefined>([
  isDateOrEmpty(Errors.InvalidDate)
])

export const birthDate = buildPreset<string | null>([
  isDateOrEmpty(Errors.InvalidDate),
  greaterThanOrEqualDateForStringDate(ErrorMsg.birthdayMin, BIRTHDATE_RANGE_END),
  lessThanOrEqualDateForStringDate(ErrorMsg.birthdayMax, BIRTHDATE_RANGE_START)
])

export const vinField = buildPreset<string | null | undefined>([
  ruleVinOrEmpty(Errors.VinCode)
])

export const vehicleYearField = buildPreset<string | null | undefined>([
  ruleFourNumbersOrEmpty(Errors.InvalidFormat),
  ruleYearNotInFutureOrEmpty(Errors.NotInFuture)
])

export const makeField = buildPreset<string | null | undefined>([
  lessThanOrEqual(ErrorMsg.maxLength, ValidationLength.MAKE_MAX)
])

export const modelField = buildPreset<string | null | undefined>([
  lessThanOrEqual(ErrorMsg.maxLength, ValidationLength.MODEL_MAX)
])

export const trimField = buildPreset<string | null | undefined>([
  lessThanOrEqual(ErrorMsg.maxLength, ValidationLength.TRIM_MAX)
])

export const mileageField = buildPreset<number | null>([
  greaterThanOrEqual(Errors.Negative, 0)
])

export const appraisalPriceField = buildPreset<number | null | undefined>([
  greaterThanOrEqual(Errors.AppraisalMinPriceError, ValidationLength.MIN_APPRAISAL_PRICE, true),
  lessThanOrEqual(Errors.AppraisalMaxPriceError, ValidationLength.MAX_APPRAISAL_PRICE, true)
])

export const nameTemplateField = buildPreset<string>([
  ruleRequired(Errors.Empty, false),
  lessThanOrEqual(Errors.LongTemplateName, ValidationLength.TEMPLATE_NAME_LENGTH)
])

/**
 * DD-TODO: create generic string field preset builder
 */

export const firstNameField = buildPreset<string>([
  isNameValid(ErrorMsg.onlyLatin),
  greaterThanOrEqual(MIN_NAME, ValidationLength.NAME_MIN),
  lessThanOrEqual(NAME_MAX_LENGTH(ValidationLength.FIRST_NAME_MAX), ValidationLength.FIRST_NAME_MAX)
])

export const cardholderNameField = buildPreset<string>([
  ruleRequired(Errors.Empty),
  isNameValid(ErrorMsg.onlyLatin),
  greaterThanOrEqual(MIN_NAME, ValidationLength.NAME_MIN),
  lessThanOrEqual(Errors.LongName, ValidationLength.CARDHOLDER_NAME_MAX)
])

export const lastNameField = buildPreset<string>([
  isNameValid(ErrorMsg.onlyLatin),
  greaterThanOrEqual(MIN_NAME, ValidationLength.NAME_MIN),
  lessThanOrEqual(NAME_MAX_LENGTH(ValidationLength.LAST_NAME_MAX), ValidationLength.LAST_NAME_MAX)
])

/**
 * Validates if Dropdown option is not null
 */
export const dictionaryItemDropdownField = buildPreset<DictionaryItem | null | undefined>([])

/**
 * takes selected id as it is the more correct way to work with dropdowns instead of keeping DictionaryItem
 */
export const listItemField = <T>(isRequired: boolean = false): ValidationRule<T, string> => {
  return (val: number | null, ctx?: T): string | null => {
    if (isRequired) {
      return applyValidators<T>(
        val,
        [requiredNumberField, greaterThan(Errors.Empty, 0)],
        ctx
      )
    }
    return null
  }
}

export const userRoleField = buildPreset<string | null | undefined>([
  ruleRequired(Errors.NoUserRoleSelected)
])

export const dealersMultiselect = buildPreset<number[] | null | undefined>([
  ruleMultiSelectNotEmpty(Errors.NoDealersSelected)
])

export const firstAccountField = buildPreset<number | null>([
  ruleAtLeastOneFieldRequired(Errors.Empty),
  ruleSameAccount(Errors.SameAccount)
])

export const accountField = buildPreset<number | null>([
  ruleSameAccount(Errors.SameAccount)
])

export const alphanumericField = buildPreset<string | null | undefined>([
  ruleIsNotCyrillic(Errors.OnlyLatin),
  isAlphanumericWithSymbols(Errors.NotSymbols)
])

export const alphanumericFieldWithOutSymbols = buildPreset<string | null | undefined>([
  ruleIsNotCyrillic(Errors.OnlyLatin),
  isAlphanumericWithOutSymbols(Errors.NotSymbols)
])

export const alphanumericWithSymbolsField = buildPreset<string | null | undefined>([
  ruleIsNotCyrillic(Errors.OnlyLatin)
])

export const phoneField = buildPreset<string | null>([
  rulePhoneNumberOrEmpty(Errors.PhoneNumber)
])

export const positiveNumberField = buildPreset<number | null>([
  greaterThan(Errors.Negative, 0)
])

export const nonZeroNumberField = buildPreset<number | null>([
  greaterThan(Errors.ZeroAmount, -1)
])

export const requiredNonNegativeField = buildPreset<number | null>([
  greaterThan(Errors.Empty, -1, false)
])

export const requiredNonZeroField = buildPreset<number | null>([
  greaterThan(Errors.ZeroAmount, 0, false)
])

export const emailField = buildPreset<string | null>([
  ruleEmail(Errors.Email)
])

export const passwordField = buildPreset<string | null>([
  ruleRequired(Errors.Empty)
])

export const passwordResetField = buildPreset<string | null>([
  ruleRequired(Errors.Empty),
  greaterThanOrEqual(STRING_MIN_ERROR(ValidationLength.PASSWORD_MIN), ValidationLength.PASSWORD_MIN, false),
  rulePasswordCase(Errors.PasswordCase)
])

export const ssnField = buildPreset<string | null | undefined>([
  ruleSSNOrEmpty(Errors.SSN)
])

export const nameField = buildPreset<string | null | undefined>([
  ruleIsNotCyrillic(Errors.OnlyLatin),
  lessThanOrEqual(Errors.LongName, ValidationLength.TEXT_FIELDS_MAX)
])

// Can not be empty - if driver license number is not filled
// Please select state

export const driverLicenseNumberField = buildPreset<string | null | undefined>([
  ruleIsNotCyrillic(Errors.DriverLicense),
  isDriverLicenseNumberOrEmpty(Errors.DriverLicense)
])

export const zipCodeField = buildPreset<string | null | undefined>([
  ruleFiveNumbersOrEmpty(Errors.InvalidZipCode)
])

/**
  Period filter input rules start:
*/
export const periodFilterFrom = buildPreset<string>([
  ruleCorrectDate(Errors.InvalidDateFormat),
  ruleEndDateAfterStartDate(Errors.FromGreaterThanTo)
])

export const periodFilterTo = buildPreset<string>([
  ruleCorrectDate(Errors.InvalidDateFormat)
])

/**
  Period filter input rules end.
*/

export const stateOfIssueValidationRule = buildPreset([(
  value: string | null, ctx?: WithDriverLicense
): string | null => {
  if (ctx?.driverLicense == null && value == null) {
    return null
  }

  const driverLicenseNumberError = driverLicenseNumberField()(ctx?.driverLicense, ctx)

  if (driverLicenseNumberError == null && value == null) {
    return Errors.Empty
  }

  return null
}])

// ------------------------------------------ //
//             FULL VALIDATORS                //
// ------------------------------------------ //

const addressDataFullValidators = <T>(isRequired = true): Array<ValidationRule<T, FullAddressValidation>> => ([
  (val: AddressData | null): FullAddressValidation | null => {
    const { city, zipCode, state, address } = (val ?? {})

    const zipCodeMessage = zipCodeField(isRequired)(zipCode)
    const isZipCodeValid = zipCodeMessage === null
    const hasCity = isStringNotEmpty(city)
    const hasState = isStringNotEmpty(state)
    const hasAddress = isStringNotEmpty(address)

    const isFull = isZipCodeValid && hasCity && hasState && hasAddress
    const isPartial = isZipCodeValid || hasCity || hasState || hasAddress
    const isValid = isRequired ? isFull : (isFull || !isPartial)

    return isValid
      ? {
          city: null,
          zipCode: null,
          state: null,
          address: null
        }
      : {
          city: hasCity ? null : Errors.Empty,
          zipCode: zipCodeMessage,
          state: hasState ? null : Errors.Empty,
          address: hasAddress ? null : Errors.Empty
        }
  }
])

export const addressDataFull = <T>(
  isRequired = true
) => (val?: AddressData | null, ctx?: T): FullAddressValidation | null =>
    applyValidators<T, FullAddressValidation>(val, addressDataFullValidators<T>(isRequired), ctx)

// ------------------------------------------ //
// ---    Vehicle Dropdown Validators     --- //
// ------------------------------------------ //

const vehicleDropdownValidators = <T>(isRequired = true, upperPriceLimit: number = MAX_CAR_PRICE_VALUE): Array<ValidationRule<T, VehicleDropdownValidation>> => ([
  (val: Vehicle | null): VehicleDropdownValidation | null => {
    const { vin, mileage, salePrice } = (val ?? {})

    const vinErr = vinField(isRequired)(vin)
    const mileageErr = isRequired ? ruleRequired(Errors.Empty)(mileage) : null
    const salePriceErr = priceField(upperPriceLimit, isRequired)(salePrice)

    return {
      vin: vinErr,
      salePrice: salePriceErr,
      mileage: mileageErr,
      stock: null
    }
  }
])

export const vehicleDropdownFull = <T>(
  isRequired = true
) => (val?: AddressData | null): VehicleDropdownValidation | null =>
    applyValidators<T, VehicleDropdownValidation>(val, vehicleDropdownValidators<T>(isRequired))

// ========================================== //
//          DEALS RELATED VALIDATORS          //
// ========================================== //

export const customerDropdownField = buildPreset([ruleCustomerDropdown(Errors.AddCustomer)])

export const sourceField = buildPreset([ruleDealSource(Errors.Empty)])

export const businessNameField = buildPreset(businessNameValidators)

export const businessEinField = buildPreset([ruleEin(Errors.EIN)])

export const bankDetailsNameField = buildPreset(nameValidators)

export const bankDetailsContactNameField = buildPreset(nameValidators)

export const bankDetailsCheckingAccountNumberField = buildPreset(checkingAccountValidators)

export const firstPaymentDate = buildPreset<Date | string | null>([
  ruleDateGE(Errors.DealFirstPaymentDateAfterSaleDate, 'saleDate')
])

// ------------------------------------------ //
//   BUSINESS/PERSONAL BY CTX VALIDATORS      //
// ------------------------------------------ //

export type ValidationRuleConfigured<T> = (isRequired?: RequiredMode) => ValidationRule<T, string>

export interface WorkflowTypeDependantValidators<T> {
  [WorkflowTypeEnum.Personal]?: ValidationRuleConfigured<T>
  [WorkflowTypeEnum.Business]?: ValidationRuleConfigured<T>
  [WorkflowTypeEnum.Wholesale]?: ValidationRuleConfigured<T>
}

export interface WorkflowTypeDependantRequiredConfig {
  [WorkflowTypeEnum.Personal]?: boolean
  [WorkflowTypeEnum.Business]?: boolean
  [WorkflowTypeEnum.Wholesale]?: boolean
}

export const createWorkflowTypeDependantValidator = <T extends WithBusinessTypeId>(
  validators: WorkflowTypeDependantValidators<T> | ValidationRuleConfigured<T>,
  required: WorkflowTypeDependantRequiredConfig = {}
): Validator<T> => {
  return (val: string | null, ctx?: T): string | null => {
    if (ctx == null) {
      return correctRule()
    }

    const id: WorkflowTypeEnum | undefined = 'workflowType' in ctx ? ctx.workflowType?.id : ctx.customerTypeId
    if (id == null) {
      return correctRule()
    }

    const validator = typeof validators === 'function' ? validators : validators[id]
    if (validator == null) {
      return correctRule()
    }

    const isRequired = required[id] ?? false
    return validator(isRequired)(val, ctx)
  }
}

export const createBusinessOrPersonalFieldValidator = <T extends WithBusinessTypeId>(
  validator: (isRequired?: RequiredMode) => ValidationRule<T, string>,
  isForPersonal = false,
  isRequired = true
): Validator<T> => {
  return (val: string | null, ctx?: T): string | null => {
    if (ctx == null) {
      return correctRule()
    }

    const id = 'workflowType' in ctx ? ctx.workflowType?.id : ctx.customerTypeId
    const _isPersonalType = isPersonalType(id)

    if ((_isPersonalType && !isForPersonal) || (!_isPersonalType && isForPersonal)) {
      return correctRule()
    }

    return validator(isRequired)(val, ctx)
  }
}

export const businessNameByCtx = createBusinessOrPersonalFieldValidator(businessNameField)

export const businessEinByCtx = createBusinessOrPersonalFieldValidator(businessEinField)

export const businessEinByCtxOptional =
  createBusinessOrPersonalFieldValidator(businessEinField, false, false)

export const personalSsnByCtx =
  createBusinessOrPersonalFieldValidator(ssnField, true, false)

export const personalDriverLicenseByCtx =
  createBusinessOrPersonalFieldValidator(driverLicenseNumberField, true, false)

export const personalDriverLicenseStateByCtx =
  createBusinessOrPersonalFieldValidator<WithDriverLicense & WithBusinessTypeId>(stateOfIssueValidationRule, true, false)

export const workflowTypeDependantFirstname = createWorkflowTypeDependantValidator(
  firstNameField,
  { [WorkflowTypeEnum.Personal]: true }
)

export const workflowTypeDependantLastname = createWorkflowTypeDependantValidator(
  lastNameField,
  { [WorkflowTypeEnum.Personal]: true }
)

// ------------------------------------------ //
// ---      Deal TradeIn validators       --- //
// ------------------------------------------ //

export const tradeInCreditField = buildPreset([
  ruleTradeInCreditRequiredIfVisible(Errors.Empty)
])

// ------------------------------------------ //
// ---      Deal Financing validators     --- //
// ------------------------------------------ //

export const dealLienholderField = buildPreset([
  ruleLienholderRequiredIfRelated(Errors.Empty)
])

export const dealApprovedRateField = buildPreset([
  ruleApprovedRateRequiredIfRelated(Errors.Empty)
])

export const dealApprovedTermField = buildPreset([
  ruleApprovedTermRequiredIfRelated(Errors.Empty)
])

// ----------------------------------------- //
// ---  Warranty  Dealer Product rules   --- //
// ----------------------------------------- //

export const warrantyRequiredField = (coverageType: string, fieldId: string) =>
  (val: unknown, ctx?: CreditAppValidationContext, idx?: number): string | null => {
    const { coverageDetails } = ctx?.coverageData ?? {}

    if ((coverageDetails == null) || (idx == null)) {
      return null
    }

    const section = coverageDetails[idx]
    if ((section == null) || section.coverageType !== coverageType) {
      return null
    }
    if (!isFieldSupported(section.productTypeId, section.coverageType, fieldId, true)) {
      return null
    }

    if ((fieldId === 'deductibleAmount') || (fieldId === 'cost')) {
      return requiredNumberField(val as number)
    }

    return requiredField(val, ctx)
  }

// ----------------------------------------- //
// ---  Warranty New Dealer Product rules   --- //
// ----------------------------------------- //

export const warrantyRequiredFieldFISettings = (coverageType: string, field?: string) =>
  (val: unknown, ctx?: FIPackageFormProps | CoverageDefaultSection[] | null, idx?: number): string | null => {
    if ((ctx == null) || (idx == null)) {
      return null
    }

    let isSupported = true
    let section = null

    if (Array.isArray(ctx)) {
      section = ctx[idx]
      isSupported = isFieldSupported(section.productTypeId, section.coverageType, field, true)
    } else {
      section = ctx.coverageSections[idx]
      isSupported = isFieldSupported(section.productTypeId, section.coverageType, field)
    }

    if ((section == null) || (section.coverageType !== coverageType) || section.forms[coverageType]?.isFormDisabled === true) {
      return null
    }

    if (!isSupported) {
      return null
    }

    if ((field === 'deductibleAmount') || (field === 'cost')) {
      return requiredNumberField(val as number)
    }

    return requiredField(val, ctx)
  }

// ----------------------------------------- //
// --- Applicant Financial Details rules --- //
// ----------------------------------------- //

export const driverLicenseNumberRule = (applicantRole: CreditAppParts.ApplicantDetails | CreditAppParts.CoApplicantDetails) =>
  (val: string, ctx?: CreditAppValidationContext): string | null => {
    const { driverLicenseNumber, stateOfIssue } = ctx?.[applicantRole] ?? {}
    const isDriverLicenseEmpty = isStringEmpty(driverLicenseNumber)
    const isStateEmpty = isStringEmpty(stateOfIssue)

    if (isDriverLicenseEmpty && isStateEmpty) {
      return null
    }

    if (isDriverLicenseEmpty && !isStateEmpty) {
      return Errors.Empty
    }

    if (!isDriverLicenseEmpty && isStateEmpty) {
      return Errors.DriverLicenseEmptyState
    }

    const res = driverLicenseNumberField()(val, ctx)
    return res
  }

export const otherIncomeFieldRule = (
  isRequired: boolean,
  applicantRole: CreditAppParts.ApplicantDetails | CreditAppParts.CoApplicantDetails
) =>
  (val: string, ctx?: CreditAppValidationContext): string | null => {
    return (hasOtherIncomeOption(ctx?.[applicantRole]?.incomeOption) && isRequired)
      ? requiredField(val, ctx)
      : null
  }

// ----------------------------------------- //

// ----------------------------------------- //
// ----------   Housing rules   ------------ //
// ----------------------------------------- //

export const apartmentMoveInDate = (
  isRequired: boolean,
  isForCoApplicant: boolean = false,
  isForPreviousPeriod: boolean = false
) => (val: Date | null, ctx?: CreditAppValidationContext): string | null => {
  if (isHousingOrEmploymentFieldVisible('apartmentMoveInDate', ctx, isForCoApplicant, isForPreviousPeriod)) {
    const applicantRole = isForCoApplicant ? CreditAppParts.CoApplicantDetails : CreditAppParts.ApplicantDetails
    const birthDate = applicantRole === CreditAppParts.ApplicantDetails
      ? ctx?.customerDetails?.customerBirthDate
      : ctx?.[applicantRole]?.birthDate

    const now = new Date()

    const validators = [
      isDateOrEmpty(Errors.InvalidDate),
      greaterThanOrEqualDate(ErrorMsg.afterBirthDate, birthDate ?? now),
      lessThanOrEqualDate(ErrorMsg.notInFuture, now)
    ]

    if (isRequired) {
      validators.unshift(requiredField)
    }

    return applyValidators(val, validators, ctx)
  }

  return null
}

export const previousAddressDataFull = (
  isRequired: boolean,
  isForCoApplicant: boolean = false
) => (val: AddressData | null, ctx?: CreditAppValidationContext): FullAddressValidation | null => {
  if (isHousingOrEmploymentFieldVisible('addressData', ctx, isForCoApplicant, true)) {
    return addressDataFull(isRequired)(val, ctx)
  }

  return null
}

export const previousAddressField = (
  fieldKey: HousingField,
  isRequired: boolean,
  isForCoApplicant: boolean = false
) => (val: AddressData | null, ctx?: CreditAppValidationContext): string | null => {
  if (isHousingOrEmploymentFieldVisible(fieldKey, ctx, isForCoApplicant, true) && isRequired) {
    return requiredField(val, ctx)
  }

  return null
}

// ----------------------------------------- //

// ----------------------------------------- //
// ---------   Employment rules   ---------- //
// ----------------------------------------- //

export const previousStatusField = (
  isForCoApplicant: boolean = false
) => (val: string, ctx?: CreditAppValidationContext): string | null => {
  return isHousingOrEmploymentFieldVisible('employmentStatus', ctx, isForCoApplicant, true)
    ? applyValidators(
      val,
      [listItemField(true)],
      ctx
    )
    : null
}

export const jobTitleField = (
  isRequired: boolean,
  isForCoApplicant: boolean = false,
  isForPreviousPeriod: boolean = false
) => (val: string, ctx?: CreditAppValidationContext): string | null => {
  return isHousingOrEmploymentFieldVisible('jobTitle', ctx, isForCoApplicant, isForPreviousPeriod)
    ? applyValidators(
      val,
      (isRequired ? [requiredField, ...jobTitleValidators] : jobTitleValidators),
      ctx
    )
    : null
}

export const employerNameField = (
  isRequired: boolean,
  isForCoApplicant: boolean = false,
  isForPreviousPeriod: boolean = false
) => (val: string, ctx?: CreditAppValidationContext): string | null => {
  return isHousingOrEmploymentFieldVisible('employerName', ctx, isForCoApplicant, isForPreviousPeriod)
    ? applyValidators(
      val,
      (isRequired ? [requiredField, ...employerNameValidators] : employerNameValidators),
      ctx
    )
    : null
}

export const employerPhoneNumberField = (
  isRequired: boolean,
  isForCoApplicant: boolean = false,
  isForPreviousPeriod: boolean = false
) => (val: string, ctx?: CreditAppValidationContext): string | null => {
  return isHousingOrEmploymentFieldVisible('employerPhoneNumber', ctx, isForCoApplicant, isForPreviousPeriod)
    ? applyValidators(
      val,
      [phoneField(isRequired)],
      ctx
    )
    : null
}

export const workingStartDateField = (
  isRequired: boolean,
  isForCoApplicant: boolean = false,
  isForPreviousPeriod: boolean = false
) => (val: Date | null, ctx?: CreditAppValidationContext): string | null => {
  if (isHousingOrEmploymentFieldVisible('workingStartDate', ctx, isForCoApplicant, isForPreviousPeriod)) {
    const applicantRole = isForCoApplicant ? CreditAppParts.CoApplicantDetails : CreditAppParts.ApplicantDetails
    const birthDate = applicantRole === CreditAppParts.ApplicantDetails
      ? ctx?.customerDetails?.customerBirthDate
      : ctx?.[applicantRole]?.birthDate

    const now = new Date()
    const oneMonthAgoDate = new Date(now.setMonth(now.getMonth() - 1))

    const validators = [
      isDateOrEmpty(Errors.InvalidDate),
      greaterThanOrEqualDate(ErrorMsg.afterBirthDate, birthDate ?? now),
      lessThanOrEqualDate(ErrorMsg.lessThenOneMonth, oneMonthAgoDate)
    ]

    if (isRequired) {
      validators.unshift(requiredField)
    }

    return applyValidators(val, validators, ctx)
  }

  return null
}

export const workingEndDateField = (
  isRequired: boolean,
  isForCoApplicant: boolean = false,
  isForPreviousPeriod: boolean = false
) => (val: Date | null, ctx?: CreditAppValidationContext): string | null => {
  if (isHousingOrEmploymentFieldVisible('workingEndDate', ctx, isForCoApplicant, isForPreviousPeriod)) {
    const applicantRole = isForCoApplicant ? CreditAppParts.CoApplicantDetails : CreditAppParts.ApplicantDetails
    const periodPart = `${isForPreviousPeriod ? CreditAppParts.PrevEmployment : CreditAppParts.CurrentEmployment}` as const
    const birthDate = applicantRole === CreditAppParts.ApplicantDetails
      ? ctx?.customerDetails?.customerBirthDate
      : ctx?.[applicantRole]?.birthDate
    const { workingStartDate } = ctx?.[applicantRole]?.[periodPart] ?? {}

    const now = new Date()

    const validators = [
      isDateOrEmpty(Errors.InvalidDate),
      greaterThanOrEqualDate(ErrorMsg.afterBirthDate, birthDate ?? now),
      greaterThanOrEqualDate(ErrorMsg.afterStartDate, workingStartDate),
      lessThanOrEqualDate(ErrorMsg.notInFuture, now)
    ]

    if (isRequired) {
      validators.unshift(requiredField)
    }

    return applyValidators(val, validators, ctx)
  }

  return null
}

export const employmentIncomeField = (
  isRequired: boolean,
  isForCoApplicant: boolean = false,
  isForPreviousPeriod: boolean = false
) => (val: string, ctx?: CreditAppValidationContext): string | null => {
  return isHousingOrEmploymentFieldVisible('income', ctx, isForCoApplicant, isForPreviousPeriod)
    ? applyValidators(
      val,
      [positiveNumberField(isRequired)],
      ctx
    )
    : null
}

// ========================================== //
//       TRANSACTION RELATED VALIDATORS       //
// ========================================== //

export const transactionDescriptionField = buildPreset<string | null>([
  ruleTextMaxLength(STRING_MAX_ERROR(ValidationLength.DESCRIPTION_MAX), ValidationLength.DESCRIPTION_MAX)
])

export const transactionReferenceField = buildPreset<string | null>([
  ruleTextMaxLength(STRING_MAX_ERROR(ValidationLength.COST_REFERENCE_NUM_MAX), ValidationLength.COST_REFERENCE_NUM_MAX)
])

export const transactionDueDate = buildPreset<Date | string | null>([
  ruleDateGE(Errors.TransactionDueDateAfterDate, 'dateTime')
])

export const startDateNotPast = buildPreset<Date | string | null>([
  ruleDateGE(Errors.TransactionStartDateNotPast, getCurrentStartDate())
])

export const transactionRecurringEndDate = buildPreset<Date | string | null>([
  ruleDateGT(Errors.TransactionEndDateAfterStartDate, 'startDate')
])

export const campaignField = buildPreset<string>([
  ruleIsNotCyrillic(Errors.OnlyLatin),
  greaterThanOrEqual(MIN_NAME, ValidationLength.NAME_MIN),
  lessThanOrEqual(NAME_MAX_LENGTH(ValidationLength.CAMPAIGN_NAME), ValidationLength.CAMPAIGN_NAME)
])

export const campaignPreHeaderField = buildPreset<string>([
  ruleIsNotCyrillic(Errors.OnlyLatin),
  greaterThanOrEqual(MIN_NAME, ValidationLength.NAME_MIN),
  lessThanOrEqual(NAME_MAX_LENGTH(ValidationLength.CAMPAIGN_NAME), ValidationLength.CAMPAIGN_NAME)
])

export const questionStateIdSelected = (val: number | null): string | null => {
  if (val === null) {
    return 'Error'
  }

  return null
}

export const settingsReqField = (id: keyof Omit<CoverageFeeSettingsModel, 'serviceContractTaxable' | 'gapTaxable' | 'appearanceProtectionTaxable' | 'tireAndWheelTaxable' | 'theftTaxable'>) =>
  (val: string | number, ctx?: GeneralSettingsFormData): string | null => {
    if (ctx?.coverageFeeSettings?.[id]?.isEnabled as boolean && (val === null || val === '')) {
      return 'Cannot be empty'
    } else {
      return null
    }
  }
