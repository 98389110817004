import { css } from '@emotion/css'
import { px, ScreenWidthBreakpoints } from 'constants/constants'

export default css(`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;

  .label-right-side {
    width: calc(100% - 35px)!important;
    .cf-tooltip-container {
      width: auto!important;
      max-width: calc(100% - 147px)!important;
      display: inline-flex;

      > p {
        color: #458FFF;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }

    .tooltip-content {
      width: 100%!important;
      max-width: 100%!important;
    }
  }

  .car {
    &-info {
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #101010;
      letter-spacing: 0.15px;
      align-items: stretch;
      flex: 1;
      overflow: hidden;

      &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;
      
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.15px;
          margin-right: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex-shrink: 1;
        }

        .more-actions { margin-left: 16px; }

        a {
          color: #458FFF;
      
          :hover { text-decoration: none; }
        }
      }
      

      &-price {
        margin-bottom: 8px;
        align-items: center;

        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.15px;
        }

        .label {
          border: 1px solid #219653;
          box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.02);
          border-radius: 4px;
          padding: 3px 9px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #101010;
          letter-spacing: 0.25px;
          margin-left: 8px;
          width: 80%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          div {
            width: 100%;

            a {
              letter-spacing: 0.1px;
              color: #458FFF;
              display: inline;
              margin-left: 5px;

              :hover {
                text-decoration: none;
              }
            }
          }

          span {
            color: rgba(33, 33, 33, 0.50);
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      &-additional {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        
        &-column {
          display: flex;
          flex-direction: column;
        }

        @media screen and (min-width: ${px(ScreenWidthBreakpoints.Medium)}) {
          flex-direction: row;
          gap: 4px 32px;
        }

        &-row { display: grid; }

        .row-left {
          grid-template-columns: 92px 149px;
          gap: 0 8px;
          align-items: baseline;
          margin: 0 0 4px 0;

          &:last-of-type {
            margin: 0;
            
            @media screen and (max-width: ${px(ScreenWidthBreakpoints.Medium)}) {
              margin: 0 0 4px 0;
            }
          }
        }

        .row-right {
          grid-template-columns: 92px 100%;
          gap: 0 8px;
          align-items: baseline;
          margin: 0 0 4px 0;

          &:last-of-type { margin: 0; }
        }

        &-label {
          letter-spacing: 0.25px;
          color: rgba(33, 33, 33, 0.8);
        }

        &-value, &-value > p {
          font-weight: 500;
          letter-spacing: 0.1px;
          font-size: 14px;
          line-height: 20px;
          color: #101010;
          white-space: nowrap;

          a {
            letter-spacing: 0.1px;
            color: #458FFF;
            font-weight: 500;

            :hover { text-decoration: none; }
          }
        }

        .website-link-row .copy-car-link,
        .website-link-row > p {
          font-weight: 500;
          letter-spacing: 0.1px;
          max-width: 86px;
      
          @media screen and (max-width: ${px(ScreenWidthBreakpoints.Medium)}) {
            max-width: 190px;
          } 
        }

        .website-link-row .copy-car-link {
          display: inline-flex;
          flex-wrap: wrap;
          align-items: center;
          cursor: pointer;

          a {
            font-weight: inherit;
            color: #458FFF;
            margin: 0 9px 0 0;

            :hover { text-decoration: none; }
          }
        }

        .generic-interior > p,
        .generic-color > p,
        .transmission > p,
        .fuel > p {
          max-width: 86px;
          font-size: inherit;
          line-height: inherit;
      
          @media screen and (max-width: ${px(ScreenWidthBreakpoints.Medium)}) {
            max-width: 190px;
          }
        }
      
        .engine > p {
          max-width: 160px;
          font-size: inherit;
          line-height: inherit;
      
          @media screen and (max-width: ${px(ScreenWidthBreakpoints.Medium)}) {
            max-width: 190px;
          }
        }
      }

      .more-actions {
        .icon-button {
          width: 24px;
          justify-content: center;
        }
      }
    }

    &-photo {
      display: flex;
      background-color: #FAFAFA;
      border-radius: 8px;
      width: 170px;
      min-width: 170px;
      height: 150px;
      position: relative;
      overflow: hidden;
      margin-right: 32px;

      &.active {
        .car-photo-label {
          background-color: #219653;
        }
      }

      &.inactive {
        img { opacity: 0.2; }
        .car-photo-label { background-color: #FFA451; }
      }

      &.deleted, &.sold {
        img { opacity: 0.2; }

        .car-photo-label {
          z-index: 2;
          background-color: #777777;
        }
      }

      &-label {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 38px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
      }

      .car-image {
        object-fit: cover;
        width: 100%;
      }

      .car-image.placeholder { object-fit: contain; }
    }
  }

  .icon-crown {
    width: 24px;
    height: 24px;
  }

  .cf-menu-container { 
    min-width: 240px; 

    ul span {
      width: 100%;
      margin: 0;
    }

    ul li {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    ul li[aria-disabled="true"] { opacity: .4; }
    ul li:hover { background: rgba(243, 243, 243, 1); }
  }
`)
