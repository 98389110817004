import {
  type LeadEmailTemplateDto,
  type EmailTemplateModel
} from 'api/types/responses'

import { parseEmailTemplateDto } from 'api/mappers/responses'

import { WrapperRequest } from './wrapper.api'
import type {
  EntityId,
  ListPayload,
  PaginatedResult,
  UploadedAssetDto,
  LeadEmailTemplateItem,
  MessageTemplate,
  MessageTemplateItem
} from './types'

class NotificationsAPI extends WrapperRequest {
  url = process.env.REACT_APP_NOTIFICATIONS ?? ''
  documentUrl = process.env.REACT_APP_DOCUMENTS ?? ''

  createLeadEmailTemplate = async (): Promise<LeadEmailTemplateDto> => {
    return await this.post(`${this.url}/api/v1/LeadEmailTemplates`)
  }

  deleteLeadEmailTemplate = async ({ id, force = false }: {id: EntityId, force?: boolean}): Promise<LeadEmailTemplateDto> => {
    return await this.delete(`${this.url}/api/v1/LeadEmailTemplates/${id}?force=${force.toString()}`)
  }

  getLeadEmailTemplates = async (payload: ListPayload): Promise<PaginatedResult<LeadEmailTemplateItem>> => {
    return await this.post(`${this.url}/api/v1/LeadEmailTemplates/list`, payload)
  }

  getLeadEmailTemplate = async (id: EntityId): Promise<EmailTemplateModel> => {
    const res = await this.get(`${this.url}/api/v1/LeadEmailTemplates/${id}`)

    return parseEmailTemplateDto(res)
  }

  updateLeadEmailTemplate = async (payload: LeadEmailTemplateDto): Promise<EmailTemplateModel> => {
    const res = await this.patch(`${this.url}/api/v1/LeadEmailTemplates/${payload.id}`, payload)

    return parseEmailTemplateDto(res)
  }

  uploadEmailPreview = async (data: FormData): Promise<UploadedAssetDto> => {
    return await this.post(`${this.documentUrl}/api/v1/Assets/emailtemplate-logo`, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })
  }

  createMessageTemplate = async (): Promise<MessageTemplate> => {
    return await this.post(`${this.url}/api/v1/MessageTemplates`)
  }

  deleteMessageTemplate = async ({ id, force = false }: {id: EntityId, force?: boolean}): Promise<MessageTemplate> => {
    return await this.delete(`${this.url}/api/v1/MessageTemplates/${id}?force=${force.toString()}`)
  }

  getMessageTemplates = async (payload?: ListPayload): Promise<PaginatedResult<MessageTemplateItem>> => {
    return await this.post(`${this.url}/api/v1/MessageTemplates/list`, payload)
  }

  getMessageTemplate = async (id: EntityId): Promise<MessageTemplate> => {
    return await this.get(`${this.url}/api/v1/MessageTemplates/${id}`)
  }

  updateMessageTemplate = async (payload: MessageTemplate): Promise<MessageTemplate> => {
    return await this.patch(`${this.url}/api/v1/MessageTemplates/${payload.id}`, payload)
  }
}

export const NotificationsAPIProvider = new NotificationsAPI()
export default NotificationsAPIProvider
