import { type FC, useState, useEffect } from 'react'

import { type EntityId, type DocumentFormPackDto, type DocumentFormDto } from 'api/types'
import DocumentsApiProvider from 'api/documents.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import getUniqueName from 'utils/getUniqueName'
import { getFormIdByUniqId } from 'utils/deals/getFormId'
import Modal from 'components/dialogs/ModalFullscreen'
import FooterActions from 'components/dialogs/FooterActionsFullscreen'
import FormsTable from 'components/deals/FormsTable'
import { type FormRowItem } from 'components/deals/FormsTable/components/FormRow'

import CLASS_NAME, { MODAL_CLASS_NAME } from './styles'

export interface SelectDocumentDealFormsProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (ids: number[]) => Promise<void>
  dealId: EntityId
}

const SelectDocumentDealForms: FC<SelectDocumentDealFormsProps> = ({
  isOpen,
  onClose,
  onSubmit,
  dealId
}) => {
  const [items, setItems] = useState<FormRowItem[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingForms, setIsLoadingForms] = useState(false)
  const [loadingViewIds, setLoadingViewIds] = useState<string[]>([])
  const { showAlert } = useCustomSnackbar()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onView = async (uniqId: string): Promise<void> => {
    try {
      setLoadingViewIds((prev) => [...prev, uniqId])

      const id = getFormIdByUniqId(items, uniqId)

      if (id != null) {
        const blobPart = await DocumentsApiProvider.downloadDocumentDealForms(dealId, [id])
        window.open(
          URL.createObjectURL(new Blob([blobPart], { type: 'application/pdf' })),
          '_blank'
        )
      }
    } finally {
      setLoadingViewIds(prev => {
        const idx = prev.findIndex((id) => id === uniqId)
        if (idx === -1) {
          return prev
        }

        const next = [...prev]
        next.splice(idx, 1)
        return next
      })
    }
  }

  const onDeleteItem = (item: FormRowItem): void => {
    setItems((prev) => {
      const idx = prev.findIndex(({ uniqId }) => item.uniqId === uniqId)
      if (idx < 0) {
        return prev
      }

      const next = [...prev]
      next.splice(idx, 1)
      return next
    })
  }

  const onAdd = (item: DocumentFormDto | DocumentFormPackDto): void => {
    const itemsToAdd = ('forms' in item ? item.forms ?? [] : [item])
      .map((item) => ({
        ...item,
        uniqId: getUniqueName(`${item.id}${item.name}`)
      }))

    setItems((items) => [
      ...items,
      ...itemsToAdd
    ])

    if ('forms' in item) {
      showAlert('Form pack was selected.', { variant: 'success' })
    }
  }

  const onSelect = async (): Promise<void> => {
    try {
      const isDocAdded = items.length > 0

      if (!isDocAdded) {
        showAlert('Please select the forms.')
        return
      }

      setIsLoading(true)
      await onSubmit(items.map(({ id }) => id))
      onClose()
    } catch {
      showAlert('Cannot select the forms.')
    } finally {
      setIsLoading(false)
    }
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (!isOpen) {
      return
    }

    const runEffect = async (): Promise<void> => {
      try {
        setIsLoadingForms(true)

        const { items: dealFormItems } = await DocumentsApiProvider.getDocumentDealForms(dealId)
        const uniqItems = dealFormItems.map((item) => ({
          ...item,
          uniqId: getUniqueName(`${item.id}${item.name}`)
        }))

        setItems(uniqItems)
      } catch {
        showAlert('Cannot fetch forms.')
      } finally {
        setIsLoadingForms(false)
      }
    }

    void runEffect()
  }, [isOpen, showAlert])

  // ========================================== //

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title='Select deal forms'
      isFullscreen
      className={MODAL_CLASS_NAME}
      renderFooterActions={(
        <FooterActions
          onCancel={onClose}
          onOk={onSelect}
          isLoading={isLoading}
          titleOk='SELECT'
        />
      )}
    >
      <div className={CLASS_NAME}>
        <p className='deal-form-subtitle'>
          Select the forms that need to be signed by the customer via DocuSign as part of the deal.
        </p>

        <FormsTable
          items={items}
          onChange={setItems}
          onDelete={onDeleteItem}
          onAdd={onAdd}
          onView={onView}
          loadingViewIds={loadingViewIds}
          isLoading={isLoadingForms}
        />
      </div>
    </Modal>
  )
}

export default SelectDocumentDealForms
