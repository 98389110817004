import { type FC } from 'react'
import { type IconProps } from 'types'

const IconSVGCheckFilled: FC<IconProps> = ({ color = '#212121', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${height} ${width}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M7.99967 1.33203C4.31967 1.33203 1.33301 4.3187 1.33301 7.9987C1.33301 11.6787 4.31967 14.6654 7.99967 14.6654C11.6797 14.6654 14.6663 11.6787 14.6663 7.9987C14.6663 4.3187 11.6797 1.33203 7.99967 1.33203ZM6.66634 11.332L3.33301 7.9987L4.27301 7.0587L6.66634 9.44536L11.7263 4.38536L12.6663 5.33203L6.66634 11.332Z' fill={color} fillOpacity='0.8' />
    </svg>
  )
}

export default IconSVGCheckFilled
