import axios from 'axios'

import type { KeyVal } from 'types'
import CalcRoutes from 'constants/route_helper'
import { BannerPropName, IconKeys } from 'components/common/ErrorsBlock/types'

export interface ProcessErrorsConfig {
  setFieldError: (fieldId: string, error: string) => void
  setApiErrors: (errors: KeyVal) => void
}

export const START_TOKEN = '__##__'

/**
 * See https://dev.azure.com/carfluent/CarFluent/_wiki/wikis/CarFluent.wiki/87/Api-error-formats
 * for more details of possible error formats.
 */
const asParsable = (
  originalMsg: string,
  template: string,
  getLink?: (id: string) => string
): string => {
  const base = `${START_TOKEN}${template}`
  const linkId = originalMsg.split('.')[1]?.replace(/\D/g, '')
  const linkUrl = linkId == null ? null : getLink?.(linkId)

  return [base, linkUrl].filter(Boolean).join(START_TOKEN)
}

const processApiErrors = (config: ProcessErrorsConfig, err: any): void => {
  const { setFieldError, setApiErrors } = config

  if (!axios.isAxiosError(err)) {
    return
  }

  const stockError = err?.response?.data?.errors.stock?.[0]
  const isLockedDate = err?.response?.data?.code === '10001'
  const hasStockError = Boolean(stockError)
  if (hasStockError) {
    setFieldError('stock', 'This Stock # is already taken.')
    delete err?.response?.data?.errors.stock
  }

  const vinError: string | undefined = err?.response?.data?.errors.vin?.[0]
  if ((vinError !== '') && (vinError != null)) {
    const errorMsg = asParsable(
      vinError,
      'This VIN is in Inventory. #link(View entry)',
      CalcRoutes.VehicleDetails
    )

    setFieldError('vin', errorMsg)
    delete err?.response?.data?.errors.vin
  }

  if (isLockedDate) {
    setApiErrors({
      iconProps: [BannerPropName.StartAdornment, IconKeys.IconSVGLock],
      message: 'Cannot add new car - this date is locked in accounting. Unlock it in accounting settings if needed.'
    })
    return
  }

  if (Object.keys(err?.response?.data?.errors).length !== 0) {
    setApiErrors(err?.response?.data ?? null)
  }
}

export default processApiErrors
