
import { CellWrapper } from '@carfluent/common'

import type { DictionaryItems } from 'api/types'
import IconSVG from 'components/inlineImages'
import CLASS_NAME from './styles'

export interface TagsCellHOCProps {
  items: DictionaryItems<string>
}

export interface TagsCellProps {
  getValue: () => TagsCellValue
}

export interface TagsCellValue {
  transactionTypeFilterId: number
  isBankStatementMatch: boolean
}

const TypeCellHOC = ({ items }: TagsCellHOCProps) => (props: TagsCellProps) => {
  const { transactionTypeFilterId, isBankStatementMatch } = props.getValue()
  const typeName = items.find((item) => item.id === transactionTypeFilterId)?.name ?? '-'

  return (
    <CellWrapper className={CLASS_NAME}>
      {isBankStatementMatch && (
        <div className='type-icon'>
          <IconSVG.Bank />
        </div>
      )}

      <div className='type-name'>
        <p>{typeName}</p>
      </div>
    </CellWrapper>
  )
}

export default TypeCellHOC
