import { css } from '@emotion/css'

export default css(`
  position: relative;
  display: grid;
  height: auto;
  grid-template-areas:
    'header header'
    'left right'
    'total total';
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 26px 1fr 52px;
  gap: 16px;
  
  > .grid-cell {
    border-radius: 12px;
    background: #F7FAFF;
    padding: 16px;
  }
  
  .grid-cell-header {
    grid-area: header;
    background: #FFF;
    padding: 0px;
    
    .block-skeleton.is-loading {
      max-width: 120px;
    }
  }
  
  .grid-cell-left { grid-area: left; }
  .grid-cell-right { grid-area: right; }
  
  .grid-cell-left,
  .grid-cell-right {
    &.block-skeleton.is-loading {
      background: #FFF;
      border-radius: 2px;
      padding: 0px;
      
      .text-skeleton {
        max-height: 384px;
        min-height: 384px;
      }
    }
  }
  
  .grid-cell-total {
    grid-area: total;
    display: flex;
    justify-content: space-between;
    
    &.block-skeleton.is-loading {
      background: #FFF;
      border-radius: 2px;
      padding: 0px;
      
      .text-skeleton {
        max-height: 52px;
        min-height: 52px;
      }
    }
  }
  
  .deal-recap-front-gross .front-gross .bold,
  .deal-recap-back-gross .back-gross .bold {
    font-weight: 500;
  }
  
  .item-label,
  .item-value {
    color: #101010;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    
    &.bold {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
    }
  }
`)
