import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import Deals from 'components/dashboard/Deals'
import CrmChart from 'components/dashboard/Crm'
import InventoryDashboard from 'components/dashboard/Inventory'
import ReconditioningDashboard from 'components/dashboard/Reconditioning'

import CLASS_NAME from './styles'

const DashboardDetails: FC = () => {
  return (
    <div className={cnx('g-content-layout', CLASS_NAME)}>
      <Deals className='widget-deals' />
      <InventoryDashboard className='widget widget-inventory' />
      <ReconditioningDashboard className='widget widget-recon' />
      <CrmChart className='widget widget-crm' />
    </div>
  )
}

export default DashboardDetails
