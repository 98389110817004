import { type FC, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { FormDropdown, FormNumberInput } from '@carfluent/common'

import InputsRowLayout from 'components/inventory/InputsRowLayout'
import CarDetailsFields from 'components/common/CarDetailsFields'
import VinInput from 'components/common/VinInput'
import { VehicleDetailsByVin } from 'api/types'

import { useForm } from 'components/form'
import { ValidationLength } from 'constants/validation'
import { getYearsForDropdownField } from 'utils/input'

import CLASS_NAME from './styles'

interface VehicleVinFormControlsProps {
  isDisabled: boolean
  onApplyVin: (vin: string) => Promise<VehicleDetailsByVin | null>
}

const yearOptions = getYearsForDropdownField()

const VehicleVinFormControls: FC<VehicleVinFormControlsProps> = ({
  onApplyVin,
  isDisabled
}) => {
  const { setFieldValue, handleBlur, values, errors, touched } = useForm()

  const onChange = useCallback((id, value) => {
    /**
     * OP NOTE: Workaround to resolve verification issue when user
     * sees a "cannot be empty" error when changing the year field.
     * Should be reworked when changing useMobXFormik to useForm.
     */
    if (id === 'year') {
      void setFieldValue(id, value?.id ?? null)
    } else {
      void setFieldValue(id, value)
    }
  }, [setFieldValue])

  return (
    <div className={CLASS_NAME}>
      <InputsRowLayout>
        <VinInput
          className='cf-vin-input'
          value={values.vin}
          error={errors.vin as string}
          touched={touched.vin as boolean}
          onBlur={handleBlur}
          onChange={onChange}
          onApply={onApplyVin}
          disabled={isDisabled}
        />
        <FormDropdown
          id='year'
          label='Year'
          onBlur={handleBlur}
          onChange={onChange}
          value={values.year}
          options={yearOptions}
          disabled={isDisabled}
          error={errors.year as string}
          touched={touched.year as boolean}
        />

        <CarDetailsFields
          make={values.make}
          model={values.model}
          trim={values.trim}
          makeError={errors.make as string}
          modelError={errors.model as string}
          trimError={errors.trim as string}
          makeTouched={touched.make as boolean}
          modelTouched={touched.model as boolean}
          trimTouched={touched.trim as boolean}
          onChange={onChange}
          onBlur={handleBlur}
          disabled={isDisabled}
        />

        <FormNumberInput
          id='mileage'
          label='Mileage'
          value={values.mileage}
          error={errors.mileage as string}
          touched={touched.mileage as boolean}
          onChange={onChange}
          onBlur={handleBlur}
          maxLength={ValidationLength.MILEAGE_MAX}
          preset='financial'
          isNegativeAllowed={false}
          mode='integer'
          startAdornment={null}
          disabled={isDisabled}
        />
      </InputsRowLayout>
    </div>
  )
}

export default observer(VehicleVinFormControls)
