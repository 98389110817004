import { useCallback, useMemo, useRef } from 'react'
import { useLoader, useModal } from '@carfluent/common'

import { type PaginatedResult } from 'api/types'
import AccountingApiProvider from 'api/accounting.api'
import useTableApi from 'hooks/useTableApi'
import useSelectRow from 'hooks/useSelectRow'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useAccountCategories from 'hooks/accounting/useAccountCategories'
import useAccountTypes from 'hooks/accounting/useAccountTypes'
import useTransitionBlocker from 'hooks/useTransitionBlocker'

import {
  type OpeningBalanceRowData,
  type UseOpeningBalancesListReturn,
  type RowId
} from './types'

import { DEFAULT_SORTING, Messages } from './constants'
import getColumnDefinitions from './columns'
import parseListData from './parser'

const useOpeningBalancesList = (): UseOpeningBalancesListReturn => {
  const loaderSubmit = useLoader()
  const { selectedRowId } = useSelectRow<RowId>()
  const { showAlert, showSuccess } = useCustomSnackbar()
  const { getCategories } = useAccountCategories(true)
  const { getTypes } = useAccountTypes(true)

  const {
    isModalOpen: isUnsavedChangesOpen,
    onOpenModal: onOpenUnsavedChanges,
    onCloseModal: onCloseUnsavedChanges
  } = useModal()

  const refLastParsedData = useRef<PaginatedResult<OpeningBalanceRowData> | null>(null)
  const refChangedBalances = useRef<Map<number, number>>(new Map())
  const refChangedIdxs = useRef<Set<number>>(new Set())

  const loadOpeningBalances = useCallback(async (): Promise<PaginatedResult<OpeningBalanceRowData>> => {
    try {
      const [
        categoriesMap,
        typesMap,
        tableData
      ] = await Promise.all([
        getCategories(),
        getTypes(),
        AccountingApiProvider.getOpeningBalances()
      ])

      const config = {
        categoriesMap,
        typesMap
      }

      const result = parseListData(config, tableData)
      refLastParsedData.current = result

      return result
    } catch (err) {
      showAlert(err)
      return { items: [], count: 0 }
    }
  }, [getCategories, getTypes])

  const {
    emptyTableMessage,
    getRows,
    isLoading: isTableLoading,
    rows,
    search,
    setCellValue,
    setRows,
    setSorting,
    setSearch,
    sorting
  } = useTableApi<OpeningBalanceRowData, unknown>({
    defaultFilters: null,
    defaultSorting: DEFAULT_SORTING,
    emptyTableMessage: Messages.EmptyTableState,
    getList: loadOpeningBalances,
    nothingFoundMessage: Messages.NothingFoundState,
    shouldScrollToSpinner: false, // no lazy-loading here
    shouldRunOnCall: true
  })

  const hasUnsavedChanges = useMemo(() => {
    if (isTableLoading) {
      return false
    }

    for (const idx of refChangedIdxs.current) {
      const originalRow = refLastParsedData.current?.items[idx]
      const currentRow = rows[idx]

      if (originalRow?.openingBalance !== currentRow.openingBalance) {
        return true
      }
    }

    return false
  }, [
    rows,
    isTableLoading,
    loaderSubmit.isLoading // needed, don't remove
  ])

  const { proceedTransition } = useTransitionBlocker({
    shouldBlock: hasUnsavedChanges,
    onBlock: onOpenUnsavedChanges
  })

  const columnDefinitions = useMemo(() => {
    return getColumnDefinitions({
      onBalanceChange: (rowIdx: number, columnId: string, value: string | number) => {
        const changedRow = getRows()[rowIdx]
        refChangedBalances.current.set(changedRow.id, Number(value))
        refChangedIdxs.current.add(rowIdx)
        setCellValue(rowIdx, columnId, value)
      }
    })
  }, [setCellValue, getRows])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onCancelChanges = (): void => {
    refChangedBalances.current.clear()
    refChangedIdxs.current.clear()
    setRows([...(refLastParsedData.current?.items ?? [])])
    showSuccess(Messages.ChangesDiscarded)
  }

  const _onSubmitChangesWithoutLoad = async (): Promise<boolean> => {
    try {
      loaderSubmit.startLoader()

      const payload = {
        openingBalances: [...refChangedBalances.current.entries()]
          .map(([key, val]) => ({ id: key, openingBalance: val }))
      }

      await AccountingApiProvider.updateOpeningBalances(payload)
      refChangedBalances.current.clear()
      refChangedIdxs.current.clear()

      if (refLastParsedData.current != null) {
        refLastParsedData.current.items = [...getRows()]
      }

      loaderSubmit.stopLoader()
      showSuccess(Messages.SubmitSuccess)
      return true
    } catch (err) {
      loaderSubmit.stopLoader()
      showAlert(err)
      return false
    }
  }

  const onSubmitChanges = async (): Promise<boolean> => {
    return await _onSubmitChangesWithoutLoad()
  }

  const onSaveAndContinueTransition = async (): Promise<void> => {
    const isSaved = await _onSubmitChangesWithoutLoad()

    if (isSaved) {
      onCloseUnsavedChanges()
      proceedTransition()
    } else {
      onCloseUnsavedChanges()
    }
  }

  const onDontSaveAndContinueTransition = (): void => {
    refChangedBalances.current.clear()
    onCloseUnsavedChanges()
    proceedTransition()
  }

  // ========================================== //

  return {
    columns: columnDefinitions,
    emptyTableMessage,
    hasUnsavedChanges,
    isUnsavedChangesOpen,
    isSubmitting: loaderSubmit.isLoading,
    isTableLoading,
    onCancelChanges,
    onCloseUnsavedChanges,
    onDontSaveAndContinueTransition,
    onSearchChange: setSearch,
    onSortingChange: setSorting,
    onSubmitChanges,
    onSaveAndContinueTransition,
    rows,
    search,
    selectedRowId,
    sorting
  }
}

export default useOpeningBalancesList
