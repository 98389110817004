import { type FC } from 'react'
import { cnx, PRESET_LABELS } from '@carfluent/common'

import { type DateType } from 'api/types'
import AccountingHOK from 'pages/accounting/AccountingHOC'
import ActionsMenu from 'components/common/ActionsMenu'
import TransactionDetails from 'pages/accounting/TransactionDetails'
import BackToTop from 'components/common/BackToTop'
import Table from 'components/common/Table'
import FiltersPanel, { type FilterItem } from 'components/common/FiltersPanel'
import { GET_DEFAULT_PRESETS } from 'constants/constants'
import { formatWithRemoveEmptyDecimal } from 'utils/filters/filterPanel'
import useDateFilters from 'hooks/useDateFilters'

import useTransactions from './hook'
import CLASS_NAME from './styles'

const DEFAULT_PRESETS = GET_DEFAULT_PRESETS()

const Transactions: FC = () => {
  const {
    isLoading,
    columns,
    search,
    sorting,
    filtersProps: {
      initPreset,
      showAccountFilter: _,
      ...otherProps
    },
    rows,
    onBottomReached,
    onOpenJournalEntry,
    onSortingChange,
    onSearchChange,
    onSearch,
    transactionDialogProps,
    topPanelActions
  } = useTransactions()

  const onDateFiltersChange = async (date: DateType): Promise<void> => await otherProps.onFilterChange({ date })

  const dateFilterProps = useDateFilters({
    defaultPreset: DEFAULT_PRESETS[0],
    setDateFilters: onDateFiltersChange
  })

  const dateFilters: FilterItem<'date'> = {
    filterType: 'date',
    componentProps: {
      ...dateFilterProps,
      onFilterChange: onDateFiltersChange,
      defaultPresetName: PRESET_LABELS.ALL,
      initPreset
    }
  }

  const amountFilter: FilterItem<'range'> = {
    filterType: 'range',
    componentProps: {
      onApply: (range) => {
        void otherProps.onFilterChange({
          amount: {
            from: range?.from ?? null,
            to: range?.to ?? null,
            equal: range?.exact ?? null
          }
        })
      },
      formatter: formatWithRemoveEmptyDecimal,
      preset: 'price',
      name: 'Amount',
      hasExact: true
    }
  }

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, 'g-content-layout')}>
      <FiltersPanel
        searchProps={{
          onChange: onSearchChange,
          isLoading,
          value: search,
          placeholder: 'Control, description, notes',
          withDeleteIcon: true,
          onSearch
        }}
        filterProps={{ filters: [dateFilters, amountFilter] }}
        renderActions={() => (
          <ActionsMenu
            actions={topPanelActions}
            buttonTitle='NEW ENTRY'
          />
        )}
      />

      <Table
        columns={columns}
        data={rows}
        emptyTableMessage='No transactions found'
        isLoading={isLoading}
        onBottomReached={onBottomReached}
        onRowClick={onOpenJournalEntry}
        onSortingChange={onSortingChange}
        sorting={sorting}
      />

      <TransactionDetails {...transactionDialogProps} />

      <BackToTop />
    </div>
  )
}

export default AccountingHOK(Transactions)
