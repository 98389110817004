import { css } from '@emotion/css'

export default css`
  height: 56px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 8px;
  border-radius: 2px;
  box-sizing: border-box;
  border-left: 4px solid transparent;

  :hover {
    border-left: 4px solid #000;
    background: #FAFAFA;
  }
  
  .form-table-row-col-left {
    display: flex;
    align-items: center;

    > .cf-icon {
      margin-right: 8px;
      padding: 3px;
    }
    
    .name-block {
      display: flex;
      flex-direction: column;
      
      .name-block-description {
        color: rgba(33, 33, 33, 0.50);
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
      }
    }
  }
  
  .form-table-row-col-right {
    display: flex;
    align-items: center;
  }
  
  .cf-loader {
    margin-left: 36px;
  }
  
  .form-delete-icon {
    width: 20px;
    padding: 4px;
    margin-left: 32px;
    cursor: pointer;
    
    &.disabled {
      opacity: 0.5;
    }
  }
`
