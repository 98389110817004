import type { FC } from 'react'
import type { IconProps } from 'types'

const DragNDropIcon: FC<IconProps> = ({ width = 48, height = 48 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_67879_31232)'>
        <rect width='48' height='48' rx='8' fill='#F8FAFC' />
        <path opacity='0.6' d='M-2 48H29.0967C32.821 48 34.5242 43.3574 31.6834 40.949L11.9201 24.1931C10.4277 22.9278 8.239 22.9278 6.7466 24.1931L-4.58674 33.8018C-5.48317 34.5618 -6 35.6776 -6 36.8528V44C-6 46.2091 -4.20914 48 -2 48Z' fill='#458FFF' />
        <path d='M45 48H4.42383C0.757896 48 -0.976896 43.4792 1.74797 41.0268L27.9908 17.4083C29.512 16.0392 31.8213 16.0392 33.3425 17.4083L47.6759 30.3083C48.5187 31.0668 49 32.1475 49 33.2814V44C49 46.2091 47.2091 48 45 48Z' fill='#458FFF' />
        <g opacity='0.6'>
          <rect x='9' y='11' width='8' height='8' rx='4' fill='#458FFF' />
        </g>
      </g>
      <rect x='0.5' y='0.5' width='47' height='47' rx='7.5' stroke='white' />
      <defs>
        <clipPath id='clip0_67879_31232'>
          <rect width='48' height='48' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DragNDropIcon
