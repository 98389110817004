import type { CopyMonthShiftsPayload } from 'api/types'

import type { CopyMonthFormData } from 'types'

import type { WorkScheduleFilters, WorkScheduleFiltersForApi, DatePayload } from './types'
import { MONTH_NUM } from './constants'

export const serializeDate = (date: Date): DatePayload => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1

  return { year, month }
}

export const serializeFilters = (data: WorkScheduleFilters): WorkScheduleFiltersForApi => {
  const { date, userIds } = data
  const year = date.getFullYear()
  const month = date.getMonth() + 1

  return { year, month, userIds }
}

export const serializeCopyMonthFormDataToPayload = (data: CopyMonthFormData): CopyMonthShiftsPayload => {
  const source = data.sourceMonth
  const target = data.monthToFill

  return {
    sourceYear: source.getFullYear(),
    sourceMonth: source.getMonth() + MONTH_NUM,
    targetYear: target.getFullYear(),
    targetMonth: target.getMonth() + MONTH_NUM
  }
}
