import type { FC } from 'react'

import { type ReconStep } from 'api/types'
import Table from 'components/common/Table'
import SectionItem from 'components/common/SectionItem'
import { formatTimeIntervalFromNow } from 'utils/recon/stepIntervalUtils'

import Step from '../Step'
import type { HistoryRow } from './columns'
import { columnDefinitions } from './columns'
import LoaderSkeleton from './components/LoaderSkeleton'

import CLASS_NAME from './styles'

interface ReconStepProps {
  reconStep: ReconStep
  isStepLoading: boolean
  onUpdateStep: (stepId: number) => Promise<void>
}

export interface HistoryProps {
  historyRows: HistoryRow[]
  createdDate: string
}

export interface HistoryTableProps extends HistoryProps, ReconStepProps {
  initLoading: boolean
}

const HistoryTable: FC<HistoryTableProps> = ({
  historyRows,
  createdDate,
  isStepLoading,
  reconStep,
  onUpdateStep,
  initLoading
}) => {
  if (initLoading) {
    return <LoaderSkeleton />
  }

  return (
    <div className={CLASS_NAME}>
      <div className='history-header'>
        <SectionItem
          label='Step:'
          value={(
            <Step
              isLoading={isStepLoading}
              onUpdateStep={onUpdateStep}
              step={reconStep}
            />
          )}
        />

        {createdDate !== '' && (
          <div className='cf-vehicle-total-time'>
            <span>
              Vehicle time:
            </span>

            <span>{formatTimeIntervalFromNow(createdDate, true)}*</span>
          </div>
        )}
      </div>

      <Table
        columns={columnDefinitions}
        emptyTableMessage='There are no details yet'
        embedAddButton
        data={historyRows}
        addBtnTestId='btn-add-journal-entry'
      />

      <p className='cf-helper-text'>
        * Time is measured as total elapsed time which includes days, night and weekends.
      </p>
    </div>
  )
}

export default HistoryTable
