import { type TradeInDetailsModel } from 'api/types/responses'

export const GET_DEFAULT_TRADE_IN_MODEL = (): TradeInDetailsModel => ({
  id: 0,
  vehicleId: 0,
  mileage: null,
  hasAnyAccidents: true,
  haveAirbagsDeployed: true,
  totalRepairCosts: null,
  anyMechanicalIssues: true,
  mechanicalIssueType: 0,
  stillMakingPayments: true,
  paymentType: 0,
  paymentAmount: null,
  vehicleMake: '',
  vehicleModel: '',
  vehicleTrim: '',
  vehicleYear: null,
  vehicleVin: '',
  acv: null,
  providerPrice: null,
  credit: null,
  inventoryVehicleId: 0,
  tradeInProviderId: 0,
  dealId: 0
})
