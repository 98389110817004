import { useCallback, useState } from 'react'
import pDebounce from 'p-debounce'
import { PRESET_LABELS } from '@carfluent/common'

import type { DateType } from 'api/types'
import type { TrialBalanceReport as ApiTrialBalanceReport } from 'api/types/accounting.types'
import { AccountingApiProvider } from 'api/accounting.api'
import { serializeReportForXslx } from 'pages/reports/ReportsList/serializer'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { downloadBlob } from 'utils/general'
import { serializePeriodUTC } from 'utils/accounting'

import type { TrialBalanceReport } from './types'
import { getDefaultData, REPORT_FILE_NAMES } from './constants'
import parseTrialBalance from './parser'

const API_CALL_DELAY = 500

export interface UseReportTrialBalanceReturn {
  data: TrialBalanceReport
  initialPresetName: PRESET_LABELS
  onChangeDatesFilter: (period: DateType) => Promise<void>
  onExportExcel: () => Promise<void>
}

const useReportTrialBalance = (): UseReportTrialBalanceReturn => {
  const { showAlert } = useCustomSnackbar()
  const [data, setData] = useState<TrialBalanceReport>(getDefaultData())
  const [report, setReport] = useState<ApiTrialBalanceReport | null>(null)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const loadReport = useCallback(pDebounce(async (period: DateType) => {
    try {
      const payload = serializePeriodUTC({ startDate: period.from, endDate: period.to })
      const response = await AccountingApiProvider.getTrialBalanceReport(payload)
      const data = parseTrialBalance(response)
      setData(data)
      setReport(response)
    } catch (err) {
      showAlert(err)
    }
  }, API_CALL_DELAY), [])

  const onExportExcel = useCallback(async () => {
    try {
      if (report != null) {
        const payload = serializeReportForXslx(report)
        const response = await AccountingApiProvider.downloadTrialBalanceReportXlsx(payload)
        downloadBlob(response, REPORT_FILE_NAMES.xlsx)
      }
    } catch (err) {
      showAlert(err)
    }
  }, [report])

  // ========================================== //

  return {
    data,
    initialPresetName: PRESET_LABELS.TODAY,
    onChangeDatesFilter: loadReport,
    onExportExcel
  }
}

export default useReportTrialBalance
