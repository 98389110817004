import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom'

import { WebSocketsCrm as ws } from 'services/web_sockets'
import Layout from 'components/layout'
import ErrorUI from 'components/common/ErrorBoundaryUI'
import NoDealerAndVin from 'pages/404'
import CreditApplication from 'pages/deals/CreditApplication'
import ResetPasswordEmail from 'pages/auth/ResetPassword/components/ResetPasswordEmail'
import DealDetails from 'pages/deals/DealDetails'
import Vehicles from 'pages/inventory/VehiclesList'
import VehicleDetails from 'pages/inventory/VehicleDetails'
import ExpiredLinkPage from 'pages/auth/ExpiredLink'
import Login from 'pages/auth/Login'
import ResetPassword from 'pages/auth/ResetPassword'
import SetUpAccount from 'pages/auth/SetupAccount'
import Settings from 'pages/settings'
import DealsList from 'pages/deals/DealsList'
import FISettings from 'pages/settings/FISettings'
import GeneralSettings from 'pages/settings/General'
import ChartOfAccounts from 'pages/accounting/ChartOfAccounts'
import AccountingSettings from 'pages/accounting/Settings'
import AccountTrialBalance from 'pages/accounting/AccountTrialBalance'
import ProfileSettings from 'pages/settings/Profile'
import SettingsGeneral from 'pages/accounting/Settings/components/SettingsGeneral'
import SettingsCustomers from 'pages/accounting/CustomersList'
import OpeningBalancesList from 'pages/accounting/OpeningBalancesList'
import SettingsVendors from 'pages/accounting/VendorsList'
import Transactions from 'pages/accounting/TransactionsList'
import SchedulesDashboard from 'pages/accounting/SchedulesDashboard'
import RecurringEntriesList from 'pages/accounting/RecurringEntriesList'
import BankStatementsList from 'pages/accounting/BankStatementList'
import BankStatementUpload from 'pages/accounting/BankStatementUpload'
import ReconciliationSetup from 'pages/accounting/ReconciliationSetup'
import ReconciliationDraft from 'pages/accounting/ReconciliationDraft'
import Reports from 'pages/reports/ReportsList'
import ReportTransactions from 'pages/reports/ReportTransactions'
import ReportTrialBalance from 'pages/reports/ReportTrialBalance'
import ReportBalanceSheet from 'pages/reports/ReportBalanceSheet'
import ReportsProfitLoss from 'pages/reports/ReportsProfitLoss'
import ReportInventoryCosts from 'pages/reports/ReportInventoryCosts'
import ReportGrossProfit from 'pages/reports/ReportGrossProfit'
import ReportSalesTax from 'pages/reports/ReportSalesTax'
import ReportAccountsAging from 'pages/reports/ReportAccountsAging'
import ReportLeadBySource from 'pages/reports/ReportLeadBySource'
import UserManagement from 'pages/UserManagement'
import EmailSent from 'pages/auth/ResetPassword/components/EmailSent'
import NewPassword from 'pages/auth/ResetPassword/components/NewPassword'
import LeadsList from 'pages/crm/LeadsList'
import TasksList from 'pages/crm/TasksList'
import LeadDetails from 'pages/crm/LeadDetailsView'
import CRMSettings from 'pages/crm/Settings'
import CRMEmailTemplatesList from 'pages/crm/EmailTemplatesList'
import CRMEmailTemplateForm from 'pages/crm/EmailTemplateForm'
import CRMMessageTemplatesList from 'pages/crm/MessageTemplatesList'
import CRMMessageTemplateForm from 'pages/crm/MessageTemplateForm'
import WorkSchedule from 'pages/crm/WorkSchedule'
import Rules from 'pages/crm/Rules'
import MarketingCampaignList from 'pages/crm/MarketingCampaignList'
import MarketingCampaignDetails from 'pages/crm/MarketingCampaignDetails'
import ForbiddenAccess from 'pages/403'
import CRMGeneralSettings from 'pages/crm/GeneralSettings'
import ReconSettings from 'pages/reconditioning/Settings'
import ReconList from 'pages/reconditioning/ReconList'
import ReconSteps from 'pages/reconditioning/Settings/Steps'
import ReconDetails from 'pages/reconditioning/ReconDetails'
import DefaultRoute from 'routing/components/DefaultRoute'
import DefaultCRMSettingsSubRoute from 'routing/components/DefaultCRMSettingsSubRoute'
import TitleReport from 'pages/inventory/TitleReport'
import MarketplacePromo from 'pages/settings/MarketplacePromo'
import FormPacks from 'pages/settings/FormPacks'
import ExactPack from 'pages/settings/ExactPack'
import LienholderList from 'pages/settings/LienholderList'
import ReportLeadsByVehiclesList from 'pages/reports/ReportLeadsByVehicle'
import ReportCarRepricing from 'pages/reports/ReportCarRepricing'
import ReportDealSaleReport from 'pages/reports/ReportDealSaleReport'
import Dashboard from 'pages/Dashboard'
import WebsiteChatSettingsForm from 'pages/crm/WebsiteChatSettingsForm'
import ReportActivity from '../pages/reports/ReportActivity'

import { PageOverlay, RoutesWithPagesOverlay } from 'components/PageOverlay'
import {
  AccountingBunkingSubRoutes,
  AccountingReconciliationSubRoutes,
  ReportsSubRoutes,
  AccountingSettingsSubRoutes,
  AccountingSubRoutes,
  CRMSettingsSubRoutes,
  CRMSubRoutes,
  DealsSubRoutes,
  PathParameterNames,
  ReconSettingsSubRoutes,
  ResetSubRoutes,
  Routes,
  SettingsSubRoutes
} from 'constants/route_helper'
import { AccountAgingReportTypes } from 'pages/reports/ReportAccountsAging/hook/types'
import IntakeInspectionDetails from 'pages/IntakeInspection'

import {
  fullEmailTemplatesUrl,
  fullMessageTemplatesUrl,
  fullTasksUrl,
  fullFormPacksUrl,
  fullLienholderListUrl,
  MIN_RECON_DETAILS_WIDTH,
  fullMarketingCampaignsUrl
} from './constants'

const fullLeadsUrl = `${Routes.CRM}/${CRMSubRoutes.Leads}`
const fullChartOfAccountsUrl = `${Routes.Accounting}/${AccountingSubRoutes.ChartsOfAccounts}`
const LeadsOrTasksUrls = [fullLeadsUrl, fullTasksUrl]
const ReconUrls = [Routes.Recon]
const onCRMPagesUnmount = ws.shutdown

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route ErrorBoundary={ErrorUI}>
      <Route path={Routes.ExpiredLink} element={<ExpiredLinkPage />} />
      <Route path={Routes.Login} element={<Login />} />
      <Route path={Routes.Reset} element={<ResetPassword />}>
        <Route index element={<ResetPasswordEmail />} />
        <Route path={`${Routes.Reset}/${PathParameterNames.Other}`}>
          <Route path={ResetSubRoutes.Sent} element={<EmailSent />} />
          <Route path={ResetSubRoutes.Password} element={<NewPassword />} />
        </Route>
      </Route>
      <Route path={`${Routes.SetupAccount}/${PathParameterNames.Token}`} element={<SetUpAccount />} />

      <Route element={<Layout />}>
        <Route path='/' element={<DefaultRoute />} />
        <Route path={Routes.NoDealerAndVin} element={<NoDealerAndVin />} />
        <Route path={Routes.Dashboard} element={<Dashboard />} />

        <Route path={`${Routes.Settings}/${PathParameterNames.Other}`} element={<Settings />}>
          {/**
           * Redirects to "General" by default, when we open parent router.
           * Used in Main Menu.
           */}
          <Route
            index
            element={<Navigate to={SettingsSubRoutes.General} replace />}
          />
          <Route path={SettingsSubRoutes.Fi}>
            <Route index element={<FISettings />} />
          </Route>

          <Route
            path={SettingsSubRoutes.Promo}
            element={<MarketplacePromo />}
          />
          <Route element={<RoutesWithPagesOverlay routesUrls={fullFormPacksUrl} isPageWithSubmenu />}>
            <Route path={SettingsSubRoutes.FormPacks} element={<FormPacks />}>
              <Route path={PathParameterNames.Id} element={<PageOverlay showBackToTop overlayedPageWithSubmenu backToTopClassName='g-back-top-with-bottom-actions-panel' />}>
                <Route index element={<ExactPack />} />
              </Route>
            </Route>
          </Route>

          <Route element={<RoutesWithPagesOverlay routesUrls={fullLienholderListUrl} isPageWithSubmenu />}>
            <Route path={SettingsSubRoutes.LienholderList} element={<LienholderList />}>
              <Route path={PathParameterNames.Id} element={<PageOverlay showBackToTop overlayedPageWithSubmenu backToTopClassName='g-back-top-with-bottom-actions-panel' />}>
                <Route index element={<ExactPack />} />
              </Route>
            </Route>
          </Route>

          <Route path={SettingsSubRoutes.General} element={<GeneralSettings />} />
          <Route
            path={PathParameterNames.Other}
            element={
              <Navigate
                to={Routes.NoDealerAndVin}
                replace
              />
            }
          />
          <Route path={SettingsSubRoutes.Profile} element={<ProfileSettings />} />
        </Route>

        <Route element={<RoutesWithPagesOverlay routesUrls={Routes.UserManagement} className='g-deal-routes-overlay' />}>
          <Route path={Routes.UserManagement} element={<UserManagement />} />
        </Route>

        <Route element={<RoutesWithPagesOverlay routesUrls={Routes.Deals} className='g-deal-routes-overlay' />}>
          <Route path={Routes.Deals} element={<DealsList />}>
            <Route path={PathParameterNames.Id} element={<PageOverlay className='g-deal-page-overlay' />}>
              <Route index element={<DealDetails />} />
              <Route path={DealsSubRoutes.CreditApplication} element={<CreditApplication />} />
            </Route>
          </Route>
        </Route>

        <Route element={<RoutesWithPagesOverlay routesUrls={Routes.Vehicles} className='g-deal-routes-overlay' />}>
          <Route path={Routes.Vehicles} element={<Vehicles />}>
            <Route path={PathParameterNames.Id} element={<PageOverlay />}>
              <Route index element={<VehicleDetails />} />
            </Route>
          </Route>
        </Route>

        <Route element={<RoutesWithPagesOverlay routesUrls={ReconUrls} />}>
          <Route path={Routes.Recon} element={<ReconList />}>
            <Route path={PathParameterNames.Id} element={<PageOverlay minContentWidth={MIN_RECON_DETAILS_WIDTH} />}>
              <Route index element={<ReconDetails />} />
              <Route path={Routes.ReconIntakeInspection} element={<IntakeInspectionDetails />} />
            </Route>
          </Route>
        </Route>

        <Route path={Routes.ReconSettings} element={<ReconSettings />}>
          {/**
           * Redirects to "Recon steps" by default, when we open parent router.
           */}
          <Route
            index
            element={<Navigate to={ReconSettingsSubRoutes.Steps} replace />}
          />
          <Route path={ReconSettingsSubRoutes.Steps} element={<ReconSteps />} />
        </Route>

        <Route path={Routes.Accounting}>
          <Route path={AccountingSubRoutes.Transactions} element={<Transactions />} />
          <Route path={AccountingSubRoutes.SchedulesDashboard} element={<SchedulesDashboard />} />

          <Route path={AccountingSubRoutes.Banking}>
            <Route index element={<BankStatementsList />} />
            <Route path={AccountingBunkingSubRoutes.Upload} element={<BankStatementUpload />} />
          </Route>

          <Route path={AccountingSubRoutes.Reconciliation}>
            <Route path={AccountingReconciliationSubRoutes.Draft} element={<ReconciliationDraft />} />
            <Route path={AccountingReconciliationSubRoutes.Setup} element={<ReconciliationSetup />} />
          </Route>

          <Route element={<RoutesWithPagesOverlay routesUrls={fullChartOfAccountsUrl} />}>
            <Route path={AccountingSubRoutes.ChartsOfAccounts} element={<ChartOfAccounts />}>
              <Route path={PathParameterNames.Id} element={<PageOverlay showBackToTop />}>
                <Route index element={<AccountTrialBalance />} />
              </Route>
            </Route>
          </Route>

          <Route path={AccountingSubRoutes.AccountingSettings} element={<AccountingSettings />}>
            {/**
             * Redirects to "General settings" by default, when we open parent router.
             * Used in Main Menu.
             */}
            <Route
              index
              element={<Navigate to={AccountingSettingsSubRoutes.General} replace />}
            />
            <Route path={AccountingSettingsSubRoutes.General} element={<SettingsGeneral />} />
            <Route path={AccountingSettingsSubRoutes.OpeningBalances} element={<OpeningBalancesList />} />
            <Route path={AccountingSettingsSubRoutes.Vendors} element={<SettingsVendors />} />
            <Route path={AccountingSettingsSubRoutes.Customers} element={<SettingsCustomers />} />
            <Route path={AccountingSettingsSubRoutes.RecurringEntries} element={<RecurringEntriesList />} />
            <Route
              path={PathParameterNames.Other}
              element={<Navigate to={Routes.NoDealerAndVin} replace />}
            />
          </Route>
        </Route>

        <Route path={Routes.CRM}>
          <Route element={<RoutesWithPagesOverlay routesUrls={LeadsOrTasksUrls} onUnmount={onCRMPagesUnmount} />}>
            <Route path={CRMSubRoutes.Leads} element={<LeadsList />}>
              <Route
                path={PathParameterNames.Id}
                element={<PageOverlay />}
              >
                <Route index element={<LeadDetails />} />
              </Route>
            </Route>

            <Route path={CRMSubRoutes.Tasks} element={<TasksList />}>
              <Route
                path={`leads/${PathParameterNames.Id}`}
                element={<PageOverlay />}
              >
                <Route index element={<LeadDetails />} />
              </Route>
            </Route>
          </Route>

          <Route element={<RoutesWithPagesOverlay routesUrls={fullMarketingCampaignsUrl} />}>
            <Route path={CRMSubRoutes.MarketingCampaigns} element={<MarketingCampaignList />}>
              <Route path={PathParameterNames.Id} element={<PageOverlay withWhiteBackground />}>
                <Route index element={<MarketingCampaignDetails />} />
              </Route>
            </Route>
          </Route>

          <Route path={CRMSubRoutes.Settings} element={<CRMSettings />}>
            {/**
             * Redirects to "General" by default, when we open parent router.
             * Used in Main Menu.
             */}
            <Route
              index
              element={<DefaultCRMSettingsSubRoute />}
            />
            <Route path={CRMSettingsSubRoutes.GeneralSettings} element={<CRMGeneralSettings />} />

            <Route element={<RoutesWithPagesOverlay routesUrls={fullEmailTemplatesUrl} isPageWithSubmenu />}>
              <Route path={CRMSettingsSubRoutes.EmailTemplates} element={<CRMEmailTemplatesList />}>
                <Route path={PathParameterNames.Id} element={<PageOverlay overlayedPageWithSubmenu />}>
                  <Route index element={<CRMEmailTemplateForm />} />
                </Route>
              </Route>
            </Route>

            <Route element={<RoutesWithPagesOverlay routesUrls={fullMessageTemplatesUrl} isPageWithSubmenu />}>
              <Route path={CRMSettingsSubRoutes.MessageTemplates} element={<CRMMessageTemplatesList />}>
                <Route path={PathParameterNames.Id} element={<PageOverlay overlayedPageWithSubmenu />}>
                  <Route index element={<CRMMessageTemplateForm />} />
                </Route>
              </Route>
            </Route>

            <Route path={CRMSettingsSubRoutes.Rules} element={<Rules />} />
            <Route path={CRMSettingsSubRoutes.WorkSchedule} element={<WorkSchedule />} />

            <Route path={CRMSettingsSubRoutes.WebsiteChat} element={<WebsiteChatSettingsForm />} />
          </Route>

          <Route
            path={PathParameterNames.Other}
            element={<Navigate to={Routes.NoDealerAndVin} replace />}
          />
        </Route>

        <Route element={<RoutesWithPagesOverlay routesUrls={Routes.Reports} />}>
          <Route element={<Reports />} path={Routes.Reports}>
            <Route path={PathParameterNames.Other} element={<PageOverlay withWhiteBackground />}>
              <Route path={ReportsSubRoutes.SalesTax} element={<ReportSalesTax />} />
              <Route path={ReportsSubRoutes.ProfitLoss} element={<ReportsProfitLoss />} />
              <Route path={ReportsSubRoutes.GrossProfit} element={<ReportGrossProfit />} />
              <Route path={ReportsSubRoutes.ReportTitleReport} element={<TitleReport />} />
              <Route path={ReportsSubRoutes.Transactions} element={<ReportTransactions />} />
              <Route path={ReportsSubRoutes.TrialBalance} element={<ReportTrialBalance />} />
              <Route path={ReportsSubRoutes.BalanceSheet} element={<ReportBalanceSheet />} />
              <Route path={ReportsSubRoutes.InventoryCosts} element={<ReportInventoryCosts />} />
              <Route path={ReportsSubRoutes.ReportLeadsByVehicle} element={<ReportLeadsByVehiclesList />} />
              <Route path={ReportsSubRoutes.ReportLeadsBySource} element={<ReportLeadBySource />} />
              <Route path={ReportsSubRoutes.ReportCarRepricing} element={<ReportCarRepricing />} />
              <Route path={ReportsSubRoutes.ReportDealSaleReport} element={<ReportDealSaleReport />} />
              <Route path={ReportsSubRoutes.ReportActivity} element={<ReportActivity />} />

              <Route
                path={ReportsSubRoutes.AccountsAgingAR}
                element={<ReportAccountsAging schedulesType={AccountAgingReportTypes.Receivable} />}
              />

              <Route
                path={ReportsSubRoutes.AccountsAgingAP}
                element={<ReportAccountsAging schedulesType={AccountAgingReportTypes.Payable} />}
              />
            </Route>
          </Route>
        </Route>

        <Route
          path={PathParameterNames.Other}
          element={<Navigate to={Routes.NoDealerAndVin} replace />}
        />

        <Route path={Routes.ForbiddenAccess} element={<ForbiddenAccess />} />
      </Route>

    </Route>
  ))

export default router
