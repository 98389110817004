import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGArrowStep: FC<IconProps> = ({ color = '#212121', width = 20, height = 20, opacity = '0.4' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 20 20' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.91211 6.175L10.7288 10L6.91211 13.825L8.08711 15L13.0871 10L8.08711 5L6.91211 6.175Z'
        fill={color}
        fillOpacity={opacity}
      />
    </svg>
  )
}

export default IconSVGArrowStep
