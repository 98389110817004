import { css } from '@emotion/css'

export default css(`
  padding-top: 48px!important;
  
  p {
    padding: 0;
  }

  &.FirstAlert p {
    border-radius: 4px;
    line-height: 24px;
    text-align: center;
    background: #FC973B;
    color: #fff;
  }

  &.SecondAlert p {
    border-radius: 4px;
    line-height: 24px;
    text-align: center;
    background: #E55D76;
    color: #fff;
  }

  @media (max-width: 1600px) {
    font-size: 14px;
  }
`)
