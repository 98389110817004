import { FC, useCallback } from 'react'
import { UI } from '@carfluent/common'

import { formatDate } from 'utils/parse_date'
import Button from 'components/common/Button'

import CLASS_NAME from './styles'
import Icon from 'components/common/Icon'

const { MonthPicker } = UI

interface NavigationPanelProps {
  date: Date
  onDateChange: (date: Date) => Promise<void>
}

const NavigationPanel: FC<NavigationPanelProps> = ({ date, onDateChange }) => {
  const onTodayClick = useCallback(async () => {
    await onDateChange(new Date())
  }, [onDateChange])

  const onPrevClick = useCallback(async () => {
    await onDateChange(new Date(date.getFullYear(), date.getMonth() - 1))
  }, [date, onDateChange])

  const onNextClick = useCallback(async () => {
    await onDateChange(new Date(date.getFullYear(), date.getMonth() + 1))
  }, [date, onDateChange])

  const onChangeDate = useCallback(async (value: Date) => {
    await onDateChange(value)
  }, [onDateChange])

  const dateString = formatDate(date, 'MMMM, yyyy')

  return (
    <div className={CLASS_NAME}>
      <Button
        className='cf-today-btn'
        variant='text'
        disableRipple
        onClick={onTodayClick}
      >
        Today
      </Button>

      <div className='cf-icon-btn' onClick={onPrevClick}>
        <Icon name='previous' />
      </div>

      <MonthPicker
        classes={{
          triggerComponent: 'cf-date-btn'
        }}
        triggerComponent={
          <Button
            variant='text'
            disableRipple
          >
            {dateString}
          </Button>
        }
        transform={{ x: -80 }}
        value={date}
        onChange={onChangeDate}
      />
      <div className='cf-icon-btn' onClick={onNextClick}>
        <Icon name='next' />
      </div>
    </div>
  )
}

export default NavigationPanel
