import { type FC } from 'react'
import { formatters as F, Loader } from '@carfluent/common'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  DotProps,
  LegendProps
} from 'recharts'

import { type LeadsByDateModel } from 'api/types/responses'
import { DATE_US_FORMAT } from 'constants/constants'

import CLASS_NAME from './styles'

interface CrmChartProps {
  data: LeadsByDateModel[]
  isLoading: boolean
}

interface CustomTooltipProps extends TooltipProps<number, string> { }

const CustomTooltip: FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active != null && (payload != null) && (payload.length > 0)) {
    return (
      <div className='custom-tooltip'>
        <p className='value'>{F.formatLocalDate(label, DATE_US_FORMAT)}</p>
        {payload.map((item) => (
          <p key={item.name} className='label'>
            {item?.name}:&nbsp;
            <span className='value'>{item.value}</span>
          </p>
        ))}
      </div>
    )
  }

  return null
}

const CustomizedDot: FC<DotProps> = ({ cx, cy, stroke }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      viewBox='0 0 12 12'
      x={(cx ?? 0) - 6}
      y={(cy ?? 0) - 6}
      fill='none'
    >
      <circle cx='6' cy='6' r='6' fill={stroke} />
    </svg>
  )
}

const LegendContent: FC<LegendProps> = ({ payload }) => {
  return (
    <div className='legend'>
      {
        payload?.map((entry, index) => (
          <div key={`item-${index}`} className='legend-item'>
            <div className='icon' />
            <span>{entry.value}</span>
          </div>
        ))
      }

    </div>
  )
}

const CrmChart: FC<CrmChartProps> = ({
  data,
  isLoading
}) => {
  return (
    <div className={CLASS_NAME}>
      {isLoading && <Loader className='loader' size='large' />}
      {data.length === 0 && !isLoading && <div className='no-data'>No data yet</div>}

      {data.length > 0 && !isLoading &&
        (
          <ResponsiveContainer width='100%' height={300}>
            <LineChart
              data={data}
              margin={{
                top: 15,
                right: 0,
                left: -20,
                bottom: 15
              }}
            >
              <CartesianGrid
                strokeDasharray='0'
                vertical={false}
                stroke='#000'
                strokeOpacity={0.12}
              />

              <XAxis
                dataKey='date'
                axisLine={false}
                fill='#212121'
                fillOpacity='0.5'
                tickLine={false}
                padding={{ left: 26, right: 26 }}
                style={{ fontSize: '14px' }}
                tickFormatter={(value) => F.formatLocalDate(value, 'dd MMM') ?? ''}
                tickCount={7}
              />

              <YAxis
                dataKey='newLeadsCount'
                axisLine={false}
                fill='#212121'
                fillOpacity='0.5'
                tickLine={false}
                style={{ fontSize: '14px' }}
                tickCount={5}
              />

              <Tooltip content={<CustomTooltip />} />
              <Legend
                iconType='plainline'
                wrapperStyle={{ fontSize: '14px', left: 0, right: 0 }}
                iconSize={16}
                content={<LegendContent />}
              />

              <Line
                dataKey='newLeadsCount'
                name='New leads'
                stroke='#458FFF'
                strokeWidth={3}
                dot={data.length === 1 ? <CustomizedDot /> : false}
              />

              <Line
                dataKey='engagedLeadsCount'
                name='Engaged leads'
                stroke='#FF8A00'
                strokeDasharray='6 6'
                strokeWidth={3}
                dot={data.length === 1 ? <CustomizedDot /> : false}
              />
            </LineChart>
          </ResponsiveContainer>
        )}

    </div>
  )
}

export default CrmChart
