import { css } from '@emotion/css'

export default css(`
  margin-top: 0;

  img {
    width: 800px;
    height: 310px;
    max-width: 100%;
    border-radius: 12px;
    object-fit: cover;
    object-position: top center;

    :first-of-type { margin-bottom: 24px; }
    :last-of-type { border: 1px solid rgba(0, 0, 0, 0.12); }
  }

  .prompt-block {
    position: relative;
    padding: 8px;
    background: #000000;
    border-radius: 4px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    margin-left: 25px;
    margin-right: 32px;
    text-wrap: nowrap;
  }

  .arrow {
    position: absolute;
    background: #000000;
    height: 12px;
    width: 12px;
    transform: rotate(45deg);
    bottom: -3px;
    left: 15px;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .restriction-list {
      display: flex;
      list-style: none;
      max-width: fit-content;

    .list-item-block {
      display: flex;
      margin-top: 8px;
      margin-left: 25px;
      gap: 4px;
  
      :first-child { margin-right: 32px; }
    }

    .list-item {
      font-family: Roboto, sans-serif;
      color: rgba(33, 33, 33, 0.8);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      white-space: nowrap;

      ::before {
        content: "•";
        color: rgba(33, 33, 33, 0.8);
        font-weight: bold;
        display: inline-block;
        width: 15px;
        margin-left: -1em;
      }
    }
  }
}
`)
