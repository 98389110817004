import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;

  .cf-item-label {
    font-family: Roboto, sans-serif;
    line-height: 20px;
    letter-spacing: 0.4;
    color: rgba(33, 33, 33, 0.5);
    text-wrap: nowrap;
  }

  .cf-item-value {
    font-family: Roboto, sans-serif;
    color: #101010;
    line-height: 24px;
    letter-spacing: 0.5px;
    word-break: break-word;
    white-space: pre-wrap;
    font-weight: 400;
    width: max-content;

    &.bold {
      font-weight: 700;
    }
  }
`)
