import { useCallback, FocusEvent } from 'react'
import { observer } from 'mobx-react-lite'
import capitalize from 'lodash-es/capitalize'
import { FormControl, FormHelperText } from '@material-ui/core'
import type { StateItem } from 'utils/states_us'

import AutocompleteStates from './autocomplete_states_us'
import type { BaseFormField } from '../../types'
import useFormHelperStyles from '../../styles'

export interface FormStatePickerProps extends BaseFormField<StateItem['abbreviation']>{
  label: string
  disabled?: boolean
  classes?: { control?: string }
  onBlur?: (evt: FocusEvent<HTMLDivElement>) => void
  onFocus?: (evt: FocusEvent<HTMLDivElement>) => void
  onChange: (id: string, value: string) => void
}

function FormStatePicker (props: FormStatePickerProps): JSX.Element {
  const {
    id, label, classes,
    disabled, onChange, onBlur, onFocus,
    value, error, touched
  } = props

  const formHelperText = useFormHelperStyles()

  const isErrorVisible = touched === true && error != null && error !== ''

  const handleOnChange = useCallback((value: any): void => {
    onChange(id, value?.abbreviation ?? null)
  }, [onChange])

  return (
    <FormControl fullWidth className={classes?.control}>
      <AutocompleteStates
        id={id}
        label={label ?? capitalize(id)}
        value={value ?? null}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={handleOnChange}
        error={isErrorVisible}
      />

      {isErrorVisible && (
        <FormHelperText classes={{ root: formHelperText }}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
};

export default observer(FormStatePicker)
