import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  align-items: center;
  justify-content: center;

  .status-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    min-width: 84px;
    padding: 0 8px;
    height: 24px;
    border-radius: 4px;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.25px;
    text-align: center;
    border: none;
    text-transform: capitalize;

    &.status-active {
      background-color: #CFE6D9;
      color: #0E4C29;
    }

    &.status-sold {
      background: #FBE9D8;
      color: #60400D;
    }

    &.status-inactive {
      background-color: #DDDDDD;
      color: #474747;
    }

    &.status-deleted {
      background-color: #21212108;
      color: #21212140;
    }

    &.no-action {
      display: none;
    }
  }

  .deposit-label {
    width: 84px;
  }
`)
