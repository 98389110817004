import { type ReactNode, type FC } from 'react'
import { cnx, FormDropdown } from '@carfluent/common'
import DateRangePicker from 'components/common/DateRangePicker'
import Checkbox from 'components/common/CheckboxNoMui'
import type { FilterItem, FilterSectionProps, FilterType } from '../../types'
import FilterRange from './FilterRange'
import SearchSelect from './SearchSelect'
import { POPOVER_CLASS_NAME } from './styles'

const renderFilter = <T extends FilterType, TValue>(props: FilterItem<T, TValue>): ReactNode => {
  switch (props.filterType) {
    case 'date':
      return <DateRangePicker {...props.componentProps} />
    case 'search-select':
      return (
        <SearchSelect
          {...props.componentProps}
          popoverClassName={`${POPOVER_CLASS_NAME} ${props.componentProps.popoverClassName ?? ''}`}
        />
      )
    case 'single-select':
      return (
        <FormDropdown
          {...props.componentProps}
          popoverClassName={`${POPOVER_CLASS_NAME} ${props.componentProps.popoverClassName ?? ''}`}
        />
      )
    case 'multi-select':
      return (
        <FormDropdown
          {...props.componentProps}
          popoverClassName={`${POPOVER_CLASS_NAME} ${props.componentProps.popoverClassName ?? ''}`}
          isMultiselect
        />
      )
    case 'range':
      return (
        <FilterRange
          {...props.componentProps}
          popoverClassName={`${POPOVER_CLASS_NAME} ${props.componentProps.popoverClassName ?? ''}`}
        />
      )
    case 'checkbox':
      return <Checkbox {...props.componentProps} />
    case 'custom':
      return props.componentProps.render()
    default:
      return null
  }
}

const FilterSection: FC<FilterSectionProps> = ({
  filterBackground = 'white',
  filters
}) => {
  return (
    <>
      {
        filters?.map((filter, index) => {
          return (
            <div key={index} className={cnx(filter.filterType, 'g-filter-item', filterBackground)}>
              {renderFilter(filter)}
            </div>
          )
        })
      }
    </>
  )
}

export default FilterSection
