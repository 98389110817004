import type { FC } from 'react'
import { cn } from '@carfluent/common'
import { Tooltip, TooltipProps } from '@material-ui/core'

import useEllipsisHover from 'hooks/useEllipsisHover'

import CLASS_NAME from './styles'

export interface TooltipItemProps {
  value: string | number
  placement?: TooltipProps['placement']
  className?: string
  isWideTooltip?: boolean
}

const TooltipItem: FC<TooltipItemProps> = ({
  value,
  isWideTooltip = false,
  placement = 'top',
  className = ''
}) => {
  const wideTooltipClass = isWideTooltip ? 'cf-wide-tooltip' : ''

  const {
    isShowTooltip,
    titleRef,
    onMouseEnter,
    onMouseLeave
  } = useEllipsisHover()

  return (
    <Tooltip
      placement={placement}
      open={isShowTooltip}
      title={value}
      classes={{ tooltip: cn(CLASS_NAME, wideTooltipClass) }}
    >
      <span
        ref={titleRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={className}
      >
        {value}
      </span>
    </Tooltip>
  )
}

export default TooltipItem
