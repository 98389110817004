import type { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { Routes, ReconSettingsSubRoutes } from 'constants/route_helper'
import { asRoute } from 'utils/route_helper'
import PageSubmenu from 'components/layout/PageSubmenu'

enum LinksNames {
  Steps = 'Recon steps'
}

const LINKS = [
  { name: LinksNames.Steps, url: asRoute(ReconSettingsSubRoutes.Steps) }
]

const ReconSettings: FC = () => {
  return (
    <div className='g-content-layout g-overrides'>
      <div className='g-settings-body-wrapper'>
        <PageSubmenu basePath={Routes.ReconSettings} links={LINKS} />
        <Outlet />
      </div>
    </div>
  )
}

export default observer(ReconSettings)
