import { type FC, useMemo } from 'react'
import { Button, FormInput, FormDropdown, cnx } from '@carfluent/common'

import AccountingApiProvider from 'api/accounting.api'
import getAccountOptionName from 'utils/accounting/getAccountOptinName'
import getControlName from 'utils/accounting/getControlName'
import renderControlOption from 'components/accounting/ControlOption/new'
import renderEntityOption from 'components/accounting/EntityOption/new'
import isControlOptionDisabled from 'utils/accounting/isControlOptionDisabled'
import EntityAction from 'components/accounting/EntityAction'

import { type FormViewProps, ReviewCategory } from './types'
import { CONTROL_CLASS_NAME } from './styles'

const FormView: FC<FormViewProps> = ({
  errors,
  isSubmitting,
  getAccounts,
  onBlur,
  onChange,
  onClickExclude,
  onClickFindMatch,
  onSubmit,
  touched,
  values,
  reviewCategory,
  entityActions
}) => {
  const getLabelAccount = useMemo((): string => {
    switch (reviewCategory) {
      case ReviewCategory.RecordAsTransfer : {
        return 'Transferred to'
      }

      case ReviewCategory.RecordAsCreditCard : {
        return 'Select credit card'
      }
      default: {
        return 'Account'
      }
    }
  }, [reviewCategory])

  return (
    <>
      {(reviewCategory !== ReviewCategory.FindMatch) && (
        <div className='form-fields-line'>
          <div className='form-fields'>
            <FormDropdown
              key={reviewCategory}
              error={errors.account}
              id='account'
              fetchHandler={getAccounts}
              formatDisplayValue={getAccountOptionName}
              renderOption={getAccountOptionName}
              label={getLabelAccount}
              onBlur={onBlur}
              onChange={onChange}
              touched={touched.account}
              value={values.account}
            />

            <FormDropdown
              error={errors.entity}
              id='entity'
              fetchHandler={AccountingApiProvider.getEntities}
              actions={entityActions}
              label='Entity'
              onBlur={onBlur}
              onChange={onChange}
              renderOption={renderEntityOption}
              renderAction={(action) => <EntityAction action={action} />}
              touched={touched.entity}
              value={values.entity}
            />

            <FormInput
              error={errors.referenceNumber}
              id='referenceNumber'
              label='Reference#'
              onBlur={onBlur}
              onChange={onChange}
              touched={touched.referenceNumber}
              value={values.referenceNumber}
            />
          </div>
        </div>
      )}

      <div className='form-fields-line form-bottom-line'>
        <div className='form-fields'>
          {(reviewCategory !== ReviewCategory.FindMatch) && (
            <>
              <FormDropdown
                error={errors.control}
                id='control'
                isOptionDisabled={isControlOptionDisabled}
                fetchHandler={AccountingApiProvider.getControls}
                formatDisplayValue={getControlName}
                label='Control'
                onBlur={onBlur}
                onChange={onChange}
                popoverClassName={CONTROL_CLASS_NAME}
                renderOption={renderControlOption}
                touched={touched.control}
                value={values.control}
              />

              <FormInput
                error={errors.description}
                className='field-description'
                id='description'
                label='Description'
                onBlur={onBlur}
                onChange={onChange}
                touched={touched.description}
                value={values.description}
              />
            </>
          )}
        </div>

        <div className='form-buttons'>
          <Button
            className='g-button-concise btn-exclude'
            color='secondary'
            isLoading={isSubmitting}
            onClick={onClickExclude}
            // variant='outlined'
            variant='text'
          >
            EXCLUDE
          </Button>

          {(reviewCategory !== ReviewCategory.FindMatch) && (
            <Button
              className='g-button-concise'
              isDisabled={isSubmitting}
              onClick={onSubmit}
            >
              CONFIRM
            </Button>
          )}

          {(reviewCategory === ReviewCategory.FindMatch) && (
            <div className='form-buttons'>
              <Button
                className={cnx('g-button-concise', 'btn-find-matches')}
                color='secondary'
                isLoading={isSubmitting}
                onClick={onClickFindMatch}
                variant='outlined'
              >
                FIND MATCHES
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default FormView
