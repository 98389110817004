import { css } from '@emotion/css'
import { px, ScreenWidthBreakpoints } from 'constants/constants'

export default css(`
  margin-right: 24px;
  margin-bottom: 24px;

  @media screen and (max-width: 1279px) {
    width: auto;
    overflow-x: auto !important;
  }

  .breakdown-wrapper {
    background-color: #f8faff;
    border-radius: 12px;
    border: 12px;
    overflow: hidden;

    @media screen and (max-width: 1279px) {
      min-width: 1280px;
      overflow-x: scroll;
    }

    .breakdown-title {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 16px;
      padding: 8px 16px;
      align-self: stretch;
      border-radius: 12px 12px 0px 0px;
      background: #ebf4ff;
      cursor: pointer;
      justify-content: space-between;

      span {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.4px;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      gap: 16px;
      padding: 0 8px;
      transition: max-height 0.3s ease;
      margin-top: 16px;

      @media screen and (min-width: ${px(ScreenWidthBreakpoints.Large)}) {
        gap: 32px;
      }
    }

    .column {
      display: flex;
      gap: 16px;
      flex-direction: column;
      flex: 1;
    }

    .table {
      display: grid;

      .is-loading {
        height: 16px;
          
        span {
          height: 100%;
        }
      }

      &.productTypes {
        .tbody .tr,
        .thead {
          grid-template-columns: minmax(180px, 295px) 60px repeat(2, minmax(178px, 247px));
          text-align: right;
        }
      }

      &.lienholder {
        .tbody .tr,
        .thead {
          grid-template-columns: minmax(200px, 220px) 70px repeat(3, minmax(140px, 186px));
        }
      }
      
      &.profits {
        .tbody .tr,
        .thead {
          grid-template-columns: minmax(140px, 415px) repeat(2, minmax(119px, 220px));
        }
      }

      .thead {
        display: grid;
        gap: 4px;
        background: #ebf4ff;
        padding: 4px 8px;
        border-radius: 4px;

        .cell-title {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.4px;
          color: #101010;
          text-align: right;

          &:first-child {
            text-align: left;
          }
        }
      }

      .tbody {
        .tr {
          display: grid;
          gap: 4px;
          padding: 4px 8px;

          .cell-title,
          .cell-value {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: #101010;
            text-align: right;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            & > div > p {
              font-size: 14px;
            }

            &:first-child {
              text-align: left;
            }
          }

          &.bold {
            .cell-value { font-weight: 500; }
          }
        }
        
        .empty-row {
          text-align: center;
          padding: 4px 16px;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;
        }
      }
    }
  }
`)
