import zip from 'lodash-es/zip'

import type { FileWithUniqueName } from 'api/files.api'
import type { UploadedAssetWithThumbnailDto } from 'api/types'
import type { ImageDescriptor } from 'components/inventory/ImageItem'
import { ImageState } from 'components/inventory/ImageItem'
import { KiB } from 'constants/files'
import getUniqueName from 'utils/getUniqueName'

export const parseAsset = (asset?: UploadedAssetWithThumbnailDto, file?: File): ImageDescriptor => {
  const fileExtension = asset?.fileExtension?.toLowerCase() ?? ''
  const isVideo = ['.mp4', '.mov'].includes(fileExtension)
  const videoThumbnailUrl = `${process.env.REACT_APP_FILES ?? ''}/api/v1/Download/vehicle-videos/thumbnail/${asset?.id ?? ''}`
  const originalVideoUrl = `${process.env.REACT_APP_FILES ?? ''}/api/v1/Download/vehicle-videos/${asset?.id ?? ''}`

  return {
    id: asset?.id,
    originalUrl: (isVideo ? originalVideoUrl : asset?.originalUrl) ?? '',
    thumbnailUrl: asset?.thumbnailUrl ?? videoThumbnailUrl,
    videoUrl: isVideo ? originalVideoUrl : '',
    fileName: file?.name ?? '',
    originalSizeKb: asset?.originalSizeKb ?? asset?.fileSize,
    state: ImageState.Uploaded,
    uniqueName: isVideo ? getUniqueName(videoThumbnailUrl ?? '') : getUniqueName(asset?.thumbnailUrl ?? asset?.originalUrl ?? ''),
    fileExtension: asset?.fileExtension,
    contentType: asset?.contentType
  }
}

export const parseAssets = (assets: UploadedAssetWithThumbnailDto[], files: File[]): ImageDescriptor[] => {
  return zip(assets, files).map(([asset, file]) => (parseAsset(asset, file)))
}

export const parseFiles = (files: FileWithUniqueName[]): ImageDescriptor[] => {
  return files.map(({ file, uniqueName }) => ({
    originalUrl: uniqueName,
    fileName: uniqueName,
    state: ImageState.Uploading,
    originalSizeKb: file.size / KiB,
    uniqueName,
    fileExtension: file.type
  }))
}
