
import type { DictionaryItem } from 'api/types'
import { PRESET_LABELS, dates, type Preset } from '@carfluent/common'
import { GET_DEFAULT_PRESETS } from 'constants/constants'
import { DealWorkflowAccountingStateNames } from 'constants/names'
import { DealWorkflowAccountingStateId } from 'types/number_enums'
import fileNames from 'utils/reports/fileNames'
import type { GrossProfitFilters } from './types'
import { addMonths, addQuarters } from 'date-fns'

export const presetLabels = [
  PRESET_LABELS.ALL,
  PRESET_LABELS.TODAY,
  PRESET_LABELS.THIS_WEEK,
  PRESET_LABELS.THIS_MONTH,
  PRESET_LABELS.THIS_QUARTER,
  PRESET_LABELS.THIS_YEAR,
  PRESET_LABELS.YEAR_TO_DATE,
  PRESET_LABELS.YESTERDAY,
  PRESET_LABELS.PREVIOUS_WEEK,
  PRESET_LABELS.PREVIOUS_MONTH,
  PRESET_LABELS.PREVIOUS_QUARTER
]

export const getPresets = (): Preset[] => {
  const date = new Date()
  const defaultPresets = GET_DEFAULT_PRESETS()
  const allPeriod = { ...defaultPresets[0], title: 'All period' }
  const prevMonday = dates.addDays(-7, dates.getWeekStart(date))
  const prevSunday = dates.addDays(6, prevMonday)
  const prevMonthStart = addMonths(dates.getMonthStart(date), -1)
  const prevMonthEnd = dates.getMonthEnd(prevMonthStart)
  const prevQuarterStart = addQuarters(dates.getQuarterStart(date), -1)
  const prevQuarterEnd = dates.getQuarterEnd(prevQuarterStart)

  return [
    allPeriod,
    ...defaultPresets.slice(2)
  ].concat([
    { name: PRESET_LABELS.PREVIOUS_WEEK, startDate: prevMonday, endDate: prevSunday },
    { name: PRESET_LABELS.PREVIOUS_MONTH, startDate: prevMonthStart, endDate: prevMonthEnd },
    { name: PRESET_LABELS.PREVIOUS_QUARTER, startDate: prevQuarterStart, endDate: prevQuarterEnd }
  ]).filter(period => presetLabels.includes(period.name as PRESET_LABELS))
}

export const DEFAULT_PRESETS = getPresets()
const THIS_MONTH_PRESET = DEFAULT_PRESETS.find(p => p.name === 'This Month')
export const REPORT_FILE_NAMES = fileNames('ProfitGross')

export const API_CALL_DELAY = 2000

export const GET_DEFAULT_ACCOUNTING_STATES = (): DictionaryItem[] => [
  {
    id: DealWorkflowAccountingStateId.Sold,
    name: DealWorkflowAccountingStateNames.Sold
  },
  {
    id: DealWorkflowAccountingStateId.PushedToAccounting,
    name: DealWorkflowAccountingStateNames.PushedToAccounting
  },
  {
    id: DealWorkflowAccountingStateId.Recorded,
    name: DealWorkflowAccountingStateNames.Recorded
  }
]

export const GET_DEFAULT_FILTERS = (): GrossProfitFilters => ({
  dateFrom: THIS_MONTH_PRESET?.startDate ?? new Date(),
  dateTo: THIS_MONTH_PRESET?.endDate ?? new Date(),
  userIds: null,
  accountingStateIds: GET_DEFAULT_ACCOUNTING_STATES().map(({ id }) => id)
})
