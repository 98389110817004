import type { FC } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'

import { matchRouteOrParent } from 'utils/route_helper'
import RecordWasUpdated from 'components/dialogs/RecordWasUpdated'
import FullPageLoader from 'components/common/FullPageLoader'
import { ROUTE_ITEMS } from 'constants/route_helper'
import AlertPresenter from '../common/AlertPresenter'

import { useLayout } from './hook'
import SidebarLayout from './SidebarLayout'

interface RouteParams {
  path: string
  isGreyBackgroundPage: boolean
  isWidePage: boolean
  isReportWithTablePage: boolean
  isSettingsPage: boolean
  isFullPage?: boolean
}

const Layout: FC = () => {
  const location = useLocation()
  const matched = matchRouteOrParent(ROUTE_ITEMS, location)
  const matchedRout = matched?.[0]?.route as RouteParams

  const {
    isLoading,
    isAppLoading,
    isRecordWasUpdatedVisible,
    snackbarConfig,
    hideSnackbar,
    onRecordUpdatedClick,
    isSettingsLoading,
    auth
  } = useLayout()

  if (!auth) {
    return null
  }

  return (
    <>
      <AlertPresenter />

      {isLoading && <FullPageLoader />}
      {
        (isAppLoading || isSettingsLoading)
          ? null
          : (
            <SidebarLayout
              isWideContent={matchedRout?.isWidePage}
              isFullPage={matchedRout?.isFullPage}
              isGreyBackgroundPage={matchedRout?.isGreyBackgroundPage}
              isSettingsPage={matchedRout?.isSettingsPage}
              isReportWithTablePage={matchedRout?.isReportWithTablePage}
            >
              <Outlet />
            </SidebarLayout>
            )
      }

      <RecordWasUpdated
        onClose={onRecordUpdatedClick}
        open={isRecordWasUpdatedVisible}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={hideSnackbar}
        open={snackbarConfig.isOpen}
      >
        <SnackbarContent
          classes={{
            root: snackbarConfig.type === 'error' ? 'cf-error-content' : undefined
          }}
          message={snackbarConfig.message}
        />
      </Snackbar>
    </>
  )
}

export default observer(Layout)
