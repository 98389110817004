import { css } from '@emotion/css'

export default css(`
  margin-left: 24px;

  .link-cell {
    background: transparent;
  }
  
  .sale-report-filters {
    margin-bottom: 24px;
    margin-right: 24px;
  }

  .cf-cell-wrapper {
    color: #101010;
    padding-right: 16px;
  }

  .cf-cell-wrapper,
  .cf-header-cell-title p,
  .cf-table-header-title p,
  .cf-header-cell-title div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .cf-header-cell-title,
  .cf-header-cell-title div {
    max-width: 100%!important;
  }

  .cf-table {
    margin-right: 24px;
    background-color: #F3F3F3;
    border-radius: 12px 12px 0 0;

    .cf-table-header-cell {
      height: 40px;

      .tooltip-container {
        span {
          font-weight: 500;
        }
      }
    }

    th {
      margin-bottom: 0;
      border: none;
    }

    tr:first-of-type th:last-of-type {
      border-radius: 0 12px 0 0;
    }

    tr {
      border: none;

      th:first-child>div>div:first-child {
        padding-left: 16px;
      }
    }
    
    tr th >div>div {
      padding: 12px 8px;
    }

    .cf-table-row-hover:hover>td>div {
      a {
        background-color: #FAFAFA;
      }
    }

    .cf-sort-icon-wrapper {
      margin-left: 4px;
      width: 24px;
      height: 24px;
    }

    .lender-wrapper {
      max-width: 200px;
    }

    .stock-wrapper {
      max-width: 100px;
    }

    .salesperson-wrapper {
      max-width: 160px;
    }
    .payment-type-cell-wrapper {
      min-width: 140px;
    }

    .cf-table-row-hover:hover>td:first-child>div {
      border-left: 4px solid #fafafa;
    }
  }

  .header-indent {
    .tooltip-container,
    .cf-cell-wrapper {
      padding-left: 16px;
    }
  }

  .cf-table-group-row {
    td {
      height: 40px;
    }
  }

  .MuiTableContainer-root.cf-table-wrapper {
    border-radius: 12px 0 0 0;
  }

  .cf-table-header-title {
    height: 40px;
    box-sizing: content-box;

    p {
      color: #101010;
      font-weight: 600;
    }
  }

  .MuiTableContainer-root.cf-table-wrapper {
    width: auto;
    overflow-x: auto!important;
    position: relative;
  }

  .MuiTableContainer-root.skeleton-table-wrapper {
    position: sticky;
    left: 0;
  }

  .cf-table-wrapper{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    ::-webkit-scrollbar {
      display: none; /* For Webkit browsers */
    }

    .cf-no-data-block {
      left: 0;
      border-top: 0;
      display: flex;
      padding: 16px;
      font-size: 16px;
      position: sticky;
      background: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: 0.5px;
      justify-content: flex-start;
      border: none;
    }
  }
`)

export const ROOT_CLASS_NAME = css(`
  margin-left: 0!important;

  .cf-md {
    margin: 0 24px;
    width: calc(100% - 48px);
  }

  .cf-report-top-actions {
    margin-top: 24px;
    margin-bottom: 24px!important;
  }
`)
