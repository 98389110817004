import { serializers as S } from '@carfluent/common'
import { toJS } from 'mobx'

import { type EntityId, type TransactionLineRow } from 'types'
import { type AddRecurringTransactionTemplatesPayload } from 'api/types'
import _isPayable from 'utils/accounting/isPayable'

import { serializeSpecialTransaction } from './specialTransactionSerializer'
import { serializeJournalEntry } from './journalEntrySerializer'
import { type TransactionFormData } from '../types'

interface SerializeRecurringTemplateTypes {
  formData: TransactionFormData
  rows: TransactionLineRow[]
  dealId?: EntityId | null
  transactionTypeId?: number | null
  isPrint?: boolean
}

export const serializeRecurringTemplate = ({
  formData, rows, dealId, transactionTypeId, isPrint = false
}: SerializeRecurringTemplateTypes): AddRecurringTransactionTemplatesPayload => {
  const data = toJS(formData)

  const isPayable = _isPayable(data.transactionTypeId ?? transactionTypeId)

  const templateTransactionParams = isPayable
    ? serializeSpecialTransaction({ formData, rows, dealId, transactionTypeId })
    : serializeJournalEntry(formData, rows, dealId)

  const payload = {
    startDate: S.serializeDate(data.startDate) ?? '',
    endDate: S.serializeDate(data.endDate) ?? '',
    recurringIntervalId: Number(data.recurringInterval?.id),
    templateTransaction: {
      ...templateTransactionParams,
      transactionTypeId: data.transactionTypeId ?? transactionTypeId,
      dueDate: null,
      dealId: dealId ?? null
    }
  }

  // @ts-expect-error
  return payload
}
