import { css } from '@emotion/css'

export interface AddButtonStylesConfig {
  isActive: boolean
}

const COMMON_BORDERS = `
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-left: none;
  border-top: none;

  :nth-child(7n) {
    border-right: none;
  } 
`

const ACTIVE_BTN = `
  && {
    visibility: visible;
    background: #f3f3f3;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
`

export default css(`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px; 

  .cf-calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, minmax(136px, 1fr));
    grid-template-rows: 48px repeat(5, minmax(88px, auto));
    width: 100%;
    min-width: 956px;
  }

  .cf-calendar-header-cell {
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    letter-spacing: 0.25px;
    ${COMMON_BORDERS}
  }

  .cf-calendar-day {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 88px;
    padding: 0 4px 4px;
    ${COMMON_BORDERS}

    :nth-last-child(-n+7) {
      border-bottom: none;
    }

    :hover .cf-calendar-add-shift {
      visibility: visible;
    }

    &-number {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      margin-top: 8px;
      margin-bottom: 12px;
      font-size: 14px;
      letter-spacing: 0.25px;

      &.current-day {
        border-radius: 50%;
        border: 1px solid #C99B86;
      }
    }
  }

  .cf-calendar-row:last-child .cf-calendar-cell {
    border-bottom: none;
  }

  /** 
   * for chips 
   */
  .cf-calendar-item-container {

    .cf-calendar-item {}
  }
`)

export const createButtonStyle = ({ isActive }: AddButtonStylesConfig): string => {
  return css(`
      && {
        visibility: hidden;
        padding: 4px 8px;
        max-width: 170px;
        min-width: 124px;
        width: 100%;
        height: 32px;
        background: #FAFAFA;
        border-radius: 90px;
        margin: auto 0;
        ${isActive && ACTIVE_BTN}

        :active {
          ${ACTIVE_BTN}
        }

      > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #101010;
      }
    }
  `)
}
