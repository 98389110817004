import { parseDescription } from 'utils/wysiwyg'

import {
  type LeadEmailTemplateDto,
  type EmailTemplateModel
} from 'api/types/responses'

const parseEmailTemplateDto = ({
  body,
  ...otherProps
}: LeadEmailTemplateDto): EmailTemplateModel => ({
  body: parseDescription(body, true),
  ...otherProps
})

export default parseEmailTemplateDto
