import { type FC } from 'react'
import { cnx, useModal } from '@carfluent/common'

import DealRecapModal from 'pages/deals/DealRecap'
import { type DealRecapButtonProps } from './types'
import CLASS_NAME from './styles'

const DealRecapButton: FC<DealRecapButtonProps> = ({
  className,
  dealId,
  inventoryId
}) => {
  const { isModalOpen, onCloseModal, onOpenModal } = useModal()

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <div
        className='deal-recap-link'
        onClick={onOpenModal}
      >
        Deal recap
      </div>

      <DealRecapModal
        isModalOpen={isModalOpen}
        onCloseModal={onCloseModal}
        dealId={dealId}
        inventoryId={inventoryId}
      />
    </div>
  )
}

export default DealRecapButton
