import { css } from '@emotion/css'

export default css`
  --footer-height: 80px;
  padding-bottom: 80px;
  
  .title {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.4px;
  }

  .title-and-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    height: 26px;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.4px;
  }

  a:hover {
    text-decoration: none;
  }

  .edit-btn.cf-button-root.cf-button-content-sized {
    padding: 0;
    min-width: 70px;
    min-height: 26px;
    height: auto;
    border-radius: 8px;
    text-transform: uppercase;

    .cf-button-content {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.25px;
    }
  }

  .cf-button-root.cf-button-variant-contained.remove-btn {
    position: absolute;
    right: 10px;
    top: 55%;
    transform: translateY(-50%);
    display: none;
    background: none;
    border: none;
    width: 20px;
    min-width: 20px;
    padding: 0;
    margin-left: auto;
    height: 20px;

    :hover {
      background: none;
    }
  }

  .products-view {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    justify-content: space-between;
  }
  
  .action-bar {
    bottom: calc(var(--footer-height) * -1);
    transition: bottom 0.3s ease;

    &.is-shown {
      bottom: 0;
    }
  }
`
