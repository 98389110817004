import { useCallback, useMemo, useRef, useState } from 'react'
import { useForm } from '@carfluent/common'

import { PriceConfigModes } from 'types'
import type { DealerProductCoverageMarkupDto } from 'api/types/responses'

import { getDefaultMode } from './utils'
import createValidationRules from './validator'
import type { UsePriceConfigProps, UsePriceConfigReturn } from './types'

const usePriceConfig = ({ markup, onApplyMarkupChanges: _onApplyMarkupChanges }: UsePriceConfigProps): UsePriceConfigReturn => {
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false)
  const [configMode, setConfigMode] = useState<PriceConfigModes>(getDefaultMode(markup.markupFixed))

  const validationRules = useMemo(() =>
    createValidationRules(configMode === PriceConfigModes.Fixed), [configMode])

  const onApplyMarkupChanges = useCallback(async (value: DealerProductCoverageMarkupDto): Promise<void> => {
    onCloseIsConfigModalOpen()
    _onApplyMarkupChanges?.({
      ...value,
      markupFixed: configMode === PriceConfigModes.Fixed ? value.markupFixed : null,
      markupAmount: configMode === PriceConfigModes.Markup ? value.markupAmount : null,
      markupPercent: configMode === PriceConfigModes.Markup ? value.markupPercent : null
    })
  }, [_onApplyMarkupChanges, configMode])

  const {
    errors,
    values,
    onBlur,
    touched,
    onChange,
    onSubmit,
    resetForm
  } = useForm({
    validationRules,
    baseValues: markup,
    submitAction: onApplyMarkupChanges
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onCloseIsConfigModalOpen = (): void => {
    resetForm()
    setIsConfigModalOpen(false)
    setConfigMode(getDefaultMode(markup.markupFixed))
  }

  const onOpenIsConfigModalOpen = (): void => {
    setConfigMode(getDefaultMode(markup.markupFixed))
    setIsConfigModalOpen(true)
  }

  // ========================================== //

  return {
    values,
    errors,
    onBlur,
    touched,
    anchorEl,
    onChange,
    onSubmit,
    configMode,
    isConfigModalOpen,
    onOpenIsConfigModalOpen,
    onCloseIsConfigModalOpen,
    onChangeConfigMode: setConfigMode
  }
}

export default usePriceConfig
