import type { FC } from 'react'
import { cn, CellWrapper } from '@carfluent/common'

import type { Account, OpenBalancesListItem } from 'api/types'
import getEntityName from 'utils/accounting/getEntityName'
import ConfigurableCellHOC from 'components/common/Table/cells/ConfigurableCellHOC'
import CLASS_NAME from './styles'

export interface EntityWithAccountHOCProps {
  className?: string
}

export type CellValue = Pick<OpenBalancesListItem, 'account' | 'customer' | 'vendor'>

export interface EntityWithAccountCellProps extends EntityWithAccountHOCProps {
  getValue: () => CellValue
}

export const EntityWithAccountCell: FC<EntityWithAccountCellProps> = ({
  className,
  getValue
}) => {
  const value: CellValue | null = getValue() ?? null
  const { account, customer, vendor } = value ?? {}
  const entityName = getEntityName(customer ?? null, vendor ?? null)
  const accountName = getAccountName(account)

  return (
    <CellWrapper className={cn(CLASS_NAME, className)}>
      <div className='content'>
        <p className='cf-entity-name'>{entityName}</p>
      </div>

      <div className='content'>
        <p className='cf-account-name'>{accountName}</p>
      </div>
    </CellWrapper>
  )
}

export const EntityWithAccountCellHOC = (hocProps: EntityWithAccountHOCProps) =>
  ConfigurableCellHOC(EntityWithAccountCell, hocProps)

// ========================================== //

export const getAccountName = (account: Account | null): string =>
  account == null ? '' : [account.number, account.name].filter(Boolean).join(' - ')
