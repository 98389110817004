import type { FC } from 'react'
import { Skeleton } from '@material-ui/lab'
import { CellWrapper } from '@carfluent/common'

import CLASS_NAME from './styles'

interface LoadingRectangleWithTwoLinesCellProps {
  isImageSkeletonDisplayed?: boolean
}

const LoadingRectangleWithTwoLinesCell: FC<LoadingRectangleWithTwoLinesCellProps> = ({
  isImageSkeletonDisplayed = true
}) => {
  return (
    <CellWrapper className={CLASS_NAME}>
      {
        isImageSkeletonDisplayed && (
          <Skeleton className='cell-image' variant='rect' />
        )
      }
      <div className='cell-description'>
        <div>
          <Skeleton className='big-skeleton' variant='text' />
        </div>
        <div>
          <Skeleton className='small-skeleton' variant='text' />
        </div>
      </div>
    </CellWrapper>
  )
}

export default LoadingRectangleWithTwoLinesCell
