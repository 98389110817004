import { type FC } from 'react'
import { NavIcons } from 'constants/route_helper'

import AccountingChartOfAccountsMenuIcon from './AccountingChartOfAccountsMenuIcon'
import AccountingMenuIcon from './AccountingMenuIcon'
import AccountingScheduleMenuIIcon from './AccountingScheduleMenuIIcon'
import AccountingSettingsMenuIcon from './AccountingSettingsMenuIcon'
import AccountingTransactionsMenuIcon from './AccountingTransactionsMenuIcon'
import AccountingBankingMenuIcon from 'components/layout/NavIcon/AccountingBankingMenuIcon'
import CrmLeadsMenuIcon from './CrmLeadsMenuIcon'
import CrmMarketingCampaigns from './CrmMarketingCampaigns'
import CrmMenuIcon from './CrmMenuIcon'
import CrmSettingsMenuIcon from './CrmSettingsMenuIcon'
import CrmTasksMenuIcon from './CrmTasksMenuIcon'
import DashboardMenuIcon from './DashboardMenuIcon'
import DealsMenuIcon from './DealsMenuIcon'
import InventoryAppraisalListMenuIcon from './InventoryAppraisalListMenuIcon'
import InventoryListMenuIcon from './InventoryListMenuIcon'
import InventoryMenuIcon from './InventoryMenuIcon'
import ProfileMenuIcon from './ProfileMenuIcon'
import ReconListMenuIcon from './ReconListMenuIcon'
import ReconMenuIcon from './ReconMenuIcon'
import ReconSettingsMenuIcon from './ReconSettingsMenuIcon'
import ReportsMenuIcon from './ReportsMenuIcon'
import SettingsMenuIcon from './SettingsMenuIcon'

export interface NavIconProps {
  icon: NavIcons
  isActive?: boolean
}

const NavIcon: FC<NavIconProps> = ({
  icon,
  ...iconProps
}) => {
  switch (icon) {
    case NavIcons.Accounting:
      return <AccountingMenuIcon {...iconProps} />
    case NavIcons.AccountingBanking:
      return <AccountingBankingMenuIcon {...iconProps} />
    case NavIcons.AccountingChartOfAccounts:
      return <AccountingChartOfAccountsMenuIcon {...iconProps} />
    case NavIcons.AccountingSchedule:
      return <AccountingScheduleMenuIIcon {...iconProps} />
    case NavIcons.AccountingSettings:
      return <AccountingSettingsMenuIcon {...iconProps} />
    case NavIcons.AccountingTransactions:
      return <AccountingTransactionsMenuIcon {...iconProps} />
    case NavIcons.Crm:
      return <CrmMenuIcon {...iconProps} />
    case NavIcons.CrmLeads:
      return <CrmLeadsMenuIcon {...iconProps} />
    case NavIcons.CrmMarketingCampaigns:
      return <CrmMarketingCampaigns {...iconProps} />
    case NavIcons.CrmSettings:
      return <CrmSettingsMenuIcon {...iconProps} />
    case NavIcons.CrmTasks:
      return <CrmTasksMenuIcon {...iconProps} />
    case NavIcons.Dashboard:
      return <DashboardMenuIcon {...iconProps} />
    case NavIcons.Deals:
      return <DealsMenuIcon {...iconProps} />
    case NavIcons.Inventory:
      return <InventoryMenuIcon {...iconProps} />
    case NavIcons.InventoryList:
      return <InventoryListMenuIcon {...iconProps} />
    case NavIcons.InventoryAppraisalList:
      return <InventoryAppraisalListMenuIcon {...iconProps} />
    case NavIcons.Recon:
      return <ReconMenuIcon {...iconProps} />
    case NavIcons.ReconList:
      return <ReconListMenuIcon {...iconProps} />
    case NavIcons.ReconSettings:
      return <ReconSettingsMenuIcon {...iconProps} />
    case NavIcons.Reports:
      return <ReportsMenuIcon {...iconProps} />
    case NavIcons.Settings:
      return <SettingsMenuIcon {...iconProps} />
    case NavIcons.Profile:
      return <ProfileMenuIcon {...iconProps} />
    default:
      return null
  }
}

export default NavIcon
