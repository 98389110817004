import { type VehicleModel } from 'api/types/responses'
import { toDateOrNull } from 'utils/parse_date'
import { GET_DEFAULT_DEAL_VEHICLE_MODEL } from 'api/defaults/vehicles'
import type { VehicleDto } from 'api/types/customersCore.types'

const parseVehicleDto = (vehicle: VehicleDto | null): VehicleModel => {
  if (vehicle?.inventoryVehicle == null) {
    return GET_DEFAULT_DEAL_VEHICLE_MODEL()
  }

  const { inventoryDate, soldDate, mainImageUrl, ...rest } = vehicle.inventoryVehicle
  return {
    ...rest,
    mainImageUrl: mainImageUrl ?? vehicle.imageUrl ?? null,
    dealId: vehicle.dealId,
    weight: vehicle.weight,
    odometer: vehicle.odometer,
    bodyStyle: vehicle.bodyStyle,
    color: vehicle.color,
    listPrice: vehicle.listPrice,
    inServiceDate: toDateOrNull(vehicle.inServiceDate),
    inventoryDate: toDateOrNull(inventoryDate),
    soldDate: toDateOrNull(soldDate)
  }
}

export default parseVehicleDto
