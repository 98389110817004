import { type FC, type ReactNode } from 'react'
import { cn } from '@carfluent/common'
import { observer } from 'mobx-react-lite'

import CLASS_NAME from './styles'

interface SectionItemProps {
  label: string
  value: ReactNode
  isBold?: boolean
  showEmpty?: boolean
  className?: string
  extraContent?: ReactNode
}

const SectionItem: FC<SectionItemProps> = (props) => {
  const { label, value, isBold = false, showEmpty = false, className, extraContent } = props

  const hasValue = Boolean(value)
  if (!hasValue && !showEmpty) {
    return null
  }

  return (
    <div className={cn(CLASS_NAME, className)}>
      <div className='cf-item-label'>
        {label}
      </div>

      <div className={cn('cf-item-value', isBold && 'bold')}>
        {value}
      </div>
      {extraContent}
    </div>
  )
}

export default observer(SectionItem)
