import type { FormValidation } from '@carfluent/common'

import { correctRule, requiredNumberField } from 'utils/validationPresets'
import type { DealerProductCoverageMarkupDto } from 'api/types/responses'

const createValidationRules = (isFixedMode: boolean): FormValidation<DealerProductCoverageMarkupDto> => ({
  markupFixed: isFixedMode ? requiredNumberField : correctRule
})

export default createValidationRules
