import type { ColumnDef } from '@carfluent/common/dist/UI'
import type { Customer } from 'api/types/accounting.types'

import { joinPartsBySpace } from 'utils/view_helper'
import { isPersonalType } from 'utils/deals/workflowTypes'

import ValueCell from 'components/common/Table/cells/ValueCell'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import PhoneCell from 'components/common/Table/cells/PhoneCell'

import AddressCell from '../components/cells/AddressCell'

const columnDefinitions: Array<ColumnDef<Customer>> = [
  {
    accessorFn: row => {
      const isPersonal = isPersonalType(row.customerTypeId)
      const isBusiness = !isPersonal

      return isBusiness
        ? row.businessName
        : joinPartsBySpace(row.firstName, row.lastName)
    },
    id: 'fullName',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Name', isHeader: false }),
    size: 320,
    sortable: false
  },
  {
    accessorKey: 'phoneNumber',
    cell: PhoneCell,
    header: TextCellHOC({ text: 'Phone', isHeader: false }),
    size: 200,
    sortable: false
  },
  {
    accessorKey: 'email',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Email', isHeader: false }),
    size: 300,
    sortable: false
  },
  {
    accessorKey: 'address',
    cell: AddressCell,
    header: TextCellHOC({ text: 'Address', isHeader: false }),
    size: 464,
    sortable: false
  }
]

export default columnDefinitions
