import type { VehiclePrices } from 'api/types'
import { formatUTCDateTimeForView } from 'utils/view_helper'

export const getFormattedDate = (
  idx: number,
  lastDateTime: string | null,
  priceHistoryList: VehiclePrices[],
  dateFormat: string
): string | null => {
  if (idx > 0 || lastDateTime != null) {
    const dateToFormat = idx > 0 ? priceHistoryList[idx - 1].createdDate : lastDateTime

    return formatUTCDateTimeForView(dateToFormat, dateFormat)
  } else {
    return 'Current'
  }
}
