import type { KeyVal } from 'types'

export enum IconKeys {
  IconSVGLock = 'IconSVGLock'
}

export enum BannerPropName {
  StartAdornment = 'startAdornment'
}

export interface ErrorsBlockProps {
  errors: KeyVal | null
  className?: string
}
