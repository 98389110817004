import { type FC } from 'react'
import { cnx, Loader } from '@carfluent/common'

import { type MessageThumbnailProps } from './types'
import CLASS_NAME from './styles'

const MessageThumbnail: FC<MessageThumbnailProps> = ({
  className,
  isLoading,
  isSending,
  imageUrl,
  onLoad,
  onOpenGallery
}) => {
  return (
    <div
      className={cnx(CLASS_NAME, 'file-thumbnail', className)}
      onClick={onOpenGallery}
    >
      {!isSending && (
        <img
          alt=''
          onLoad={() => { onLoad(imageUrl) }}
          src={imageUrl}
        />
      )}

      {isLoading && (
        <Loader className='cf-section-loader' size='small' color='dark' />
      )}
    </div>
  )
}

export default MessageThumbnail
