import type { FC } from 'react'
import type { IconProps } from 'types'

const IncreaseIcon: FC<IconProps> = ({ width = 16, height = 16, color = '#219653' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.66732 13.3327L8.66732 5.21935L12.394 8.94601L13.334 7.99935L8.00065 2.66602L2.66732 7.99935L3.60732 8.93935L7.33399 5.21935L7.33399 13.3327L8.66732 13.3327Z'
        fill={color}
      />
    </svg>

  )
}

export default IncreaseIcon
