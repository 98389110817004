export const SSN_LEN = 9
export const MASK_CHAR = '*'

/**
 * Replace left part of the string by '*' character.
 *
 * Example:
 *   - `formatNumberByMask('123456789') === '*****6789'`.
 */
export const formatNumberByMask = (
  str: string | null,
  visibleNums = 4,
  maskChar = MASK_CHAR
): string | null => {
  if (str == null || str.length < visibleNums) {
    return str ?? null
  }

  const maskLength = str.length - visibleNums
  return maskChar.repeat(maskLength) + str.slice(maskLength)
}
