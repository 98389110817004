import { UI } from '@carfluent/common'

import AddressAutocomplete, {
  AddressAutocompleteProps, AddressError, AddressValue
} from 'components/common/AddressAutocomplete/AddressAutocomplete'

const { FormFieldHOC } = UI

type AddrError = string | boolean | AddressError | undefined

export default FormFieldHOC<UI.FormCompatible<AddressAutocompleteProps, AddressValue, AddrError>, AddressValue, AddrError>(AddressAutocomplete)
