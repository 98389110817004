import { type FC, useState } from 'react'
import {
  Modal,
  FormDatePicker,
  FormNumberInput,
  FormInput,
  FormMaskedInput,
  cnx,
  Banner
} from '@carfluent/common'

import { PaymentMethod } from 'api/types'
import { PaymentTransactionTypeEnum } from 'types/payments'

import { toDateFromStringOrDate } from 'utils/parse_date'
import RadioButton from 'components/common/RadioButton'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import CheckboxLabel from 'components/common/CheckboxLabel'
import AddressFields from 'components/common/AddressFields'
import IconSVGLock from 'components/inlineImages/IconSVGLock'

import { MAX_PRICE_VALUE } from 'constants/validation'

import TransactionTypes from './components/TransactionType'

import { usePaymentForm } from './hook'
import { PaymentFormProps } from './hook/types'
import CLASS_NAME from './styles'

const MAX_CARDHOLDER_LEN = 32

enum ModalTitles {
  Deposit = 'Deposit',
  DownPayment = 'Down payment'
}

const getTitle = (paymentMethod: PaymentMethod | null): string => {
  return paymentMethod == null
    ? ''
    : paymentMethod === PaymentMethod.DownPayment
      ? ModalTitles.DownPayment
      : ModalTitles.Deposit
}

export const PaymentForm: FC<PaymentFormProps> = ({
  dealId,
  dealerId,
  isOpen,
  onClose,
  paymentMethod,
  onSubmit,
  vin,
  defaultValues,
  id = null
}) => {
  const title = getTitle(paymentMethod)
  const paymentDescription = `${title ?? ''} ${vin ?? ''}`

  const [cvv, setCvv] = useState('')

  const {
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onSubmit: _onSubmit,
    isLoading,
    remainingAmount,
    isRemainingAmountError,
    isDateError
  } = usePaymentForm({
    dealId,
    dealerId,
    description: paymentDescription,
    onSubmit,
    paymentMethod,
    onClose,
    isOpen,
    defaultValues,
    id
  })
  const isEditMode = defaultValues != null
  const isMultipleType = values.transactionTypeId === PaymentTransactionTypeEnum.MULTIPLE
  const isManuallyDisabled = isEditMode && !values.isManual
  const isElectronicDisabled = isEditMode && values.isManual

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      className={CLASS_NAME}
      renderFooterActions={(
        <CancelSubmitActionsFooter
          isLoading={isLoading}
          onSubmit={_onSubmit}
          onClose={onClose}
          submitTitle='ADD'
        />
      )}
    >
      <Banner
        isOpen={isDateError}
        startAdornment={(
          <IconSVGLock
            opacity={1}
            width={24}
            height={24}
            color='rgba(176, 0, 32, 1)'
          />
        )}
        type='error'
        message={(
          <>
            The date is locked in accounting. <br /> Unlock it in accounting settings if needed.
          </>
        )}
      />

      <div className='payment-form-content'>
        <RadioButton
          className='payment-form-input'
          isChecked={values.isManual}
          label='Enter transaction manually'
          isDisabled={isManuallyDisabled}
          onChange={() => { onChange('isManual', true) }}
        />

        <RadioButton
          className='payment-form-input'
          isChecked={!values.isManual}
          label='Process electronic payment'
          isDisabled={isElectronicDisabled}
          onChange={() => { onChange('isManual', false) }}
        />

        <FormDatePicker
          className='payment-form-input'
          id='paymentDate'
          label='Date'
          onChange={onChange}
          onBlur={onBlur}
          value={toDateFromStringOrDate(values.paymentDate)}
          error={errors.paymentDate}
          touched={touched.paymentDate}
        />

        <FormNumberInput
          className='payment-form-input'
          error={errors.amount}
          id='amount'
          label='Amount'
          max={MAX_PRICE_VALUE}
          onChange={onChange}
          onBlur={onBlur}
          preset='price'
          touched={touched.amount}
          value={values.amount}
          isSelectedOnFocus
          disabled={isManuallyDisabled}
        />

        <FormInput
          className='payment-form-input-full'
          error={errors.description}
          id='description'
          label='Description'
          onChange={onChange}
          onBlur={onBlur}
          touched={touched.description}
          value={values.description}
        />

        {
          values.isManual && (
            <TransactionTypes
              id='transactionTypeId'
              value={values.transactionTypeId}
              error={errors.transactionTypeId}
              touched={touched.transactionTypeId}
              onChange={onChange}
            />
          )
        }

        {(isMultipleType && values.isManual) && (
          <>
            <FormNumberInput
              className='payment-form-input'
              error={errors.payments.CHECK}
              id='payments.CHECK'
              label='Check'
              max={MAX_PRICE_VALUE}
              onChange={onChange}
              onBlur={onBlur}
              preset='price'
              touched={touched.payments.CHECK}
              value={values.payments.CHECK}
              isSelectedOnFocus
            />

            <FormNumberInput
              className='payment-form-input'
              error={errors.payments.ACH}
              id='payments.ACH'
              label='ACH'
              max={MAX_PRICE_VALUE}
              onChange={onChange}
              onBlur={onBlur}
              preset='price'
              touched={touched.payments.ACH}
              value={values.payments.ACH}
              isSelectedOnFocus
            />

            <FormNumberInput
              className='payment-form-input'
              error={errors.payments.CASH}
              id='payments.CASH'
              label='Cash'
              max={MAX_PRICE_VALUE}
              preset='price'
              onChange={onChange}
              onBlur={onBlur}
              touched={touched.payments.CASH}
              value={values.payments.CASH}
              isSelectedOnFocus
            />

            <FormNumberInput
              className='payment-form-input'
              error={errors.payments.ZELLE}
              id='payments.ZELLE'
              label='ZELLE'
              max={MAX_PRICE_VALUE}
              preset='price'
              onChange={onChange}
              onBlur={onBlur}
              touched={touched.payments.ZELLE}
              value={values.payments.ZELLE}
              isSelectedOnFocus
            />

            <FormNumberInput
              className='payment-form-input'
              error={errors.payments.CARD}
              id='payments.CARD'
              label='Credit Card'
              max={MAX_PRICE_VALUE}
              preset='price'
              onChange={onChange}
              onBlur={onBlur}
              touched={touched.payments.CARD}
              value={values.payments.CARD}
              isSelectedOnFocus
            />

            <div className={cnx('remaining-amount', isRemainingAmountError && 'with-error')}>
              <p>Remaining amount:</p>
              <p>{remainingAmount}</p>
            </div>
          </>
        )}

        {!values.isManual && (
          <>
            <h6 className='h6-title'>
              Card details
            </h6>

            <Banner
              isOpen={isEditMode}
              message={(
                <>
                  Card details are hidden. <br /> You cannot change card details anymore, because it is processed.
                </>
              )}
            />

            {!isEditMode && (
              <>
                <div className='billing-details'>
                  <FormMaskedInput
                    error={errors.billingDetails?.cardNumber}
                    id='billingDetails.cardNumber'
                    mask='cardNumber'
                    onChange={onChange}
                    onBlur={onBlur}
                    touched={touched.billingDetails?.cardNumber}
                    value={values.billingDetails?.cardNumber ?? ''}
                  />

                  {/* /** DD-NOTE: */}
                  {/* add to masked input possibility to provide masked value. */}
                  {/* Currently we force unmasked value. */}
                  <FormMaskedInput
                    error={errors.billingDetails?.expirationDate}
                    id='billingDetails.expirationDate'
                    mask='cardExpiration'
                    onChange={onChange}
                    onBlur={onBlur}
                    touched={touched.billingDetails?.expirationDate}
                    value={values.billingDetails?.expirationDate ?? ''}
                  />

                  <FormInput
                    error={errors.billingDetails?.cvv}
                    id='billingDetails.cvv'
                    label='CVV'
                    maxLength={3}
                    type='password'
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                    touched={touched.billingDetails?.cvv}
                    value={values.billingDetails?.cvv ?? ''}
                  />

                  <FormInput
                    id='billingDetails.cardHolderName'
                    error={errors.billingDetails?.cardHolderName}
                    label='Cardholder name'
                    maxLength={MAX_CARDHOLDER_LEN}
                    onChange={onChange}
                    onBlur={onBlur}
                    touched={touched.billingDetails?.cardHolderName}
                    value={values.billingDetails?.cardHolderName ?? ''}
                  />
                </div>

                <div className='payment-form-input-full address-checkbox'>
                  <CheckboxLabel
                    value={{
                      name: 'Enter billing address (optional)',
                      id: 'isBillingAddressProvided',
                      checked: values.isBillingAddressProvided
                    }}
                    onChange={() => onChange('isBillingAddressProvided', !values.isBillingAddressProvided)}
                  />
                </div>

                {
                  values.isBillingAddressProvided && (
                    <AddressFields
                      errors={errors.billingDetails?.address}
                      id='address'
                      pathPrefix='billingDetails'
                      onChange={onChange}
                      onBlur={onBlur}
                      shouldHideApt
                      touched={touched.billingDetails?.address}
                      values={values.billingDetails?.address ?? null}
                    />
                  )
                }
              </>
            )}
          </>
        )}

        <div className='payment-modal-divider-content' />
        <div className='receipt-checkbox'>
          <CheckboxLabel
            value={{
              name: 'Print receipt now',
              id: 'printReceiptCheck',
              checked: values.printReceiptCheck
            }}
            onChange={() => onChange('printReceiptCheck', !values.printReceiptCheck)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default PaymentForm
