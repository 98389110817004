import { type SortingInfo } from '@carfluent/common'
import { type AccountShortInfo } from './types'
import { type FiltersFormData } from 'types/filters'

export const GET_DEFAULT_SHORT_INFO = (): AccountShortInfo => ({
  closingBalance: null,
  currentMonthBalance: null,
  description: null,
  name: null,
  monthOpeningBalance: null
})

export const GET_DEFAULT_FILTERS = (): FiltersFormData => ({
  amount: { from: null, to: null, equal: null },
  date: { from: null, to: null }
})

export const DEFAULT_SORTING: SortingInfo = { id: 'date', order: 'desc' }
