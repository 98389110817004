import subtractMoney from 'utils/common/subtractMoney'
import transactionTotalDebits from 'utils/accounting/transactionTotalDebits'
import transactionTotalCredits from 'utils/accounting/transactionTotalCredits'

export interface TransactionLineMinimal {
  credits: number
  debits: number
}

const transactionDifference = (rows: TransactionLineMinimal[]): number => {
  return subtractMoney(transactionTotalDebits(rows), transactionTotalCredits(rows))
}

export default transactionDifference
