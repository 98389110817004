import { type FC } from 'react'

import IconSVG from 'components/inlineImages'
import type { EntityId, DocumentFormDto } from 'api/types'
import DocumentsApiProvider from 'api/documents.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

import CLASS_NAME from './style'

interface ActionCellProps {
  withDeleteIcon?: boolean
  getValue: () => DocumentFormDto & {
    originId?: EntityId
  }
  onDeleteForm?: (idx: number) => void
}

const ActionCell: FC<ActionCellProps> = ({
  getValue,
  onDeleteForm,
  withDeleteIcon = false
}) => {
  const { id, originId } = getValue()
  const { showAlert } = useCustomSnackbar()

  const onViewForm = async (): Promise<void> => {
    try {
      const response = await DocumentsApiProvider.downloadForm(originId ?? id)
      const blob = new Blob([response], { type: 'application/pdf' })

      const url = URL.createObjectURL(blob)

      window.open(url, '_blank')
    } catch (e) {
      showAlert('Cannot save a PDF document with no pages.')
    }
  }

  const onDeleteStepBtnClick = (): void => {
    onDeleteForm?.(id)
  }

  return (
    <div className={CLASS_NAME}>
      <p onClick={onViewForm}>View</p>
      {
        withDeleteIcon &&
          <span onClick={onDeleteStepBtnClick}>
            <IconSVG.Close />
          </span>
      }
    </div>

  )
}

export default ActionCell
