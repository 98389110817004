import { type FC, useMemo } from 'react'
import { Button, cnx, noop } from '@carfluent/common'

import Tooltip, { TooltipProps } from 'components/common/Tooltip'

import { useTextEllipsis, type UseTextEllipsisProps } from './hook'

import { createUseStyles } from './styles'

export interface MultilineTextEllipsisProps extends UseTextEllipsisProps {
  className?: string
  showToggleButton?: boolean
  withTooltip?: boolean
  tooltipProps?: Omit<TooltipProps, 'title'>
}

/**
 * TODO - OF: Should found cross-browser solution for more clear view
 * */
const MultilineTextEllipsis: FC<MultilineTextEllipsisProps> = ({
  children,
  className,
  showToggleButton = false,
  withTooltip = false,
  tooltipProps,
  ...otherProps
}) => {
  const {
    isCollapsed, lines, maxLines,
    lineHeight, onClick, wrapperEl,
    contentEl, isWithEllipse
  } = useTextEllipsis(otherProps)

  const rootCls = useMemo(() => createUseStyles({
    lines,
    defaultLines: maxLines,
    lineHeight
  }), [lines, lineHeight, maxLines])

  const isWithTooltip = isWithEllipse && withTooltip && !showToggleButton

  const content = (
    <div
      ref={wrapperEl}
      className={lines == null ? 'default-height' : ''}
    >
      <div
        ref={contentEl}
        className={cnx('text-content', (lines != null) && (isCollapsed !== true) && 'ellipsis-text-content')}
      >
        {children}
      </div>
    </div>
  )

  return (
    <div
      className={cnx(rootCls, 'multiline-text-ellipsis', className)}
      onClick={showToggleButton ? noop : onClick}
    >
      {isWithTooltip
        ? (
          <Tooltip
            {...tooltipProps}
            title={(<>{children}</>)}
          >
            {content}
          </Tooltip>
          )
        : content}

      {showToggleButton && (
        <div className='show-more-less-block'>
          <Button
            variant='text'
            onClick={onClick}
          >
            {isCollapsed === true ? 'Show less' : 'Show more'}
          </Button>
        </div>
      )}
    </div>
  )
}

export default MultilineTextEllipsis
