import type { FC, ReactNode } from 'react'
import { cn } from '@carfluent/common'

import CLASS_NAME from './styles'

export interface RadioButtonProps {
  id?: string
  isChecked?: boolean
  label?: ReactNode
  className?: string
  isDisabled?: boolean
  onChange?: (isChecked: boolean) => void
}

const RadioButton: FC<RadioButtonProps> = ({
  id,
  isChecked = false,
  label = '',
  className = '',
  isDisabled = false,
  onChange: _onChange
}) => {
  const onChange = (): void => {
    if (isDisabled) {
      return
    }

    _onChange?.(!isChecked)
  }

  return (
    <div
      id={id}
      onClick={onChange}
      className={cn(CLASS_NAME, className, 'cf-radio-button', isDisabled && 'cf-disabled')}
    >
      <div className={cn('cf-radio-icon', isChecked && 'cf-checked')} />
      {label}
    </div>
  )
}

export default RadioButton
