import { GET_DEFAULT_CAMPAIGN_DETAILS } from 'api/defaults'
import { type CampaignModel } from 'api/types/responses'
import { type ReviewFormData } from './types'

export const mapModelOrDefaultToForm = (campaign?: CampaignModel): ReviewFormData => {
  return GET_DEFAULT_CAMPAIGN_DETAILS(campaign).schedule
}

export const mapFormToModel = (data: ReviewFormData): Partial<CampaignModel> => {
  return { schedule: data }
}
