import { css } from '@emotion/css'

export default css(`
   padding: 2px 4px !important;

  .cf-input-container {
    background-color: #ffffff;
    border: 1px solid transparent;
    border-radius: 8px;
    box-shadow: none;
    max-height: 50px;
    
    &.focused {
      border: 1px solid #C99B86;
    }
    
    .cf-input {
      padding: 14px 0;
    }
    
    :hover {
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
`)

export const TOOLTIP_POPOVER_CLASSES = css(`
  &.MuiTooltip-popper[role='tooltip'] {
    z-index: 1200;
  }
`)
