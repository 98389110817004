import type { FC } from 'react'
import { FormInput, FormMaskedInput } from '@carfluent/common'

import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'
import FormAddressPicker from 'components/form/v2/FormAddressPicker'

import LogoDropzone from '../LogoDropzone'
import BusinessHours from '../BusinessHours'
import { type ProfileFormProps } from '../../hook/types'
import CLASS_NAME from './styles'

const leadCmsEmailsHelper = <p>An email that receives lead notifications.</p>
const googleMapsLinkHelper = <p>Google Maps business (used in feedback form)</p>

const ProfileForm: FC<ProfileFormProps> = ({
  isLoading,
  logoPreviewUrl,
  logoUrl,
  termsUrl,
  values,
  errors,
  touched,
  onBlur,
  onChange,
  setLogoAndPreviewUrl
}) => {
  return (
    <div className={CLASS_NAME}>
      <BlockSkeleton isLoading={isLoading} className='title-and-edit-btn'>
        <h5>Business info</h5>
      </BlockSkeleton>

      <div className='page-info'>
        <div className='business-info-section'>
          <BlockSkeleton isLoading={isLoading} className='loading-field'>
            <FormInput
              id='dealerName'
              label='Business name'
              disabled={isLoading}
              value={values.dealerName}
              error={errors.dealerName}
              touched={touched.dealerName}
              onChange={onChange}
              onBlur={onBlur}
            />
          </BlockSkeleton>

          <BlockSkeleton isLoading={isLoading} className='loading-field'>
            <FormAddressPicker
              id='addressData'
              label='Address'
              disabled={isLoading}
              value={values.addressData}
              error={errors.addressData}
              touched={touched.addressData}
              onChange={onChange}
              onBlur={onBlur}
              className='address-input'
            />
          </BlockSkeleton>
          <div className='subgrid'>
            <BlockSkeleton isLoading={isLoading} className='loading-field'>
              <FormMaskedInput
                id='phone'
                label='Phone number'
                disabled={isLoading}
                mask='phone'
                value={values.phone}
                error={errors.phone}
                touched={touched.phone}
                onChange={onChange}
                onBlur={onBlur}
              />
            </BlockSkeleton>

            <BlockSkeleton isLoading={isLoading} className='loading-field'>
              <FormInput
                id='email'
                label='Business email'
                disabled={isLoading}
                value={values.email}
                error={errors.email}
                touched={touched.email}
                onChange={onChange}
                onBlur={onBlur}
              />
            </BlockSkeleton>

            <BlockSkeleton isLoading={isLoading} className='loading-field'>
              <FormInput
                id='leadCmsEmails'
                label='Lead email'
                disabled={isLoading}
                value={values.leadCmsEmails}
                error={errors.leadCmsEmails}
                touched={touched.leadCmsEmails}
                onChange={onChange}
                onBlur={onBlur}
                helper={leadCmsEmailsHelper}
              />
            </BlockSkeleton>
            <div className='cf-form-field'>
              <BlockSkeleton isLoading={isLoading} className='loading-field'>
                <FormInput
                  id='googleMapsLink'
                  label='Google maps link'
                  disabled={isLoading}
                  value={values.googleMapsLink}
                  error={errors.googleMapsLink}
                  touched={touched.googleMapsLink}
                  onChange={onChange}
                  onBlur={onBlur}
                  helper={googleMapsLinkHelper}
                />
              </BlockSkeleton>
            </div>
          </div>

        </div>
        <LogoDropzone
          isLoading={isLoading}
          disabledForm={false}
          logoPreviewUrl={logoPreviewUrl}
          logoUrl={logoUrl}
          setLogoAndPreviewUrl={setLogoAndPreviewUrl}
          setFieldValue={onChange}
        />

        <div className='g-horizontal-line' />

        <BlockSkeleton isLoading={isLoading} className='title'>
          <h5>Business hours</h5>
        </BlockSkeleton>

        <BusinessHours
          id='dealerBusinessHours'
          isLoading={isLoading}
          value={values.dealerBusinessHours}
          onChange={onChange}
        />

        <div className='g-horizontal-line' />

        <div className='terms'>
          <BlockSkeleton isLoading={isLoading} className='title'>
            <h6>Terms & Conditions</h6>
          </BlockSkeleton>

          <BlockSkeleton isLoading={isLoading} className='loading-field'>
            <FormInput
              id='termsUrl'
              label='Link'
              disabled={isLoading}
              value={values.termsUrl}
              error={errors.termsUrl}
              touched={touched.termsUrl}
              onChange={onChange}
              onBlur={onBlur}
            />
          </BlockSkeleton>
        </div>

      </div>
    </div>
  )
}

export default ProfileForm
