import {
  type DealFeeFieldSettingsModel,
  type DealFeesAndCoveragesSettingsModel,
  type DealSalesCommissionFieldSettingsModel
} from 'api/types/responses'

export const GET_DEFAULT_DEAL_FEES_CONFIG = (
  data?: DealFeesAndCoveragesSettingsModel
): DealFeesAndCoveragesSettingsModel => ({
  fees: {
    buyerTagSetting: GET_DEFAULT_FEE_FIELD_SETTINGS(data?.fees.buyerTagSetting, 'Buyer tag'),
    carDeliverySetting: GET_DEFAULT_FEE_FIELD_SETTINGS(data?.fees.carDeliverySetting, 'Car delivery'),
    dealerHandlingFeeSetting: GET_DEFAULT_FEE_FIELD_SETTINGS(data?.fees.dealerHandlingFeeSetting, 'Dealer handling fee'),
    dealerInventoryTaxSetting: GET_DEFAULT_FEE_FIELD_SETTINGS(data?.fees.dealerInventoryTaxSetting, 'Dealer inventory tax'),
    emissionsSetting: GET_DEFAULT_FEE_FIELD_SETTINGS(data?.fees.emissionsSetting, 'Emissions'),
    inspectionFeeSetting: GET_DEFAULT_FEE_FIELD_SETTINGS(data?.fees.inspectionFeeSetting, 'Inspection fee'),
    plateTransferFeeSetting: GET_DEFAULT_FEE_FIELD_SETTINGS(data?.fees.plateTransferFeeSetting, 'Plate transfer fee'),
    registrationFeeSetting: GET_DEFAULT_FEE_FIELD_SETTINGS(data?.fees.registrationFeeSetting, 'Registration fee'),
    titleFeeSetting: GET_DEFAULT_FEE_FIELD_SETTINGS(data?.fees.titleFeeSetting, 'Title fee')
  },
  coverages: {
    appearanceProtectionTaxable: data?.coverages.appearanceProtectionTaxable ?? false,
    gapTaxable: data?.coverages.gapTaxable ?? false,
    serviceContractTaxable: data?.coverages.serviceContractTaxable ?? false,
    theftTaxable: data?.coverages.theftTaxable ?? false,
    tireAndWheelTaxable: data?.coverages.tireAndWheelTaxable ?? false
  },
  salesCommissions: {
    backGrossSalesCommissionSetting: GET_DEFAULT_SALES_COMMISSION_FIELD_SETTINGS(
      data?.salesCommissions.backGrossSalesCommissionSetting
    ),
    frontGrossSalesCommissionSetting: GET_DEFAULT_SALES_COMMISSION_FIELD_SETTINGS(
      data?.salesCommissions.frontGrossSalesCommissionSetting
    )
  }
})

export const GET_DEFAULT_FEE_FIELD_SETTINGS = (
  data?: DealFeeFieldSettingsModel,
  label?: string
): DealFeeFieldSettingsModel => ({
  isEnabled: true,
  isPercent: false,
  isReadonly: false,
  isTaxable: true,
  labelName: label ?? '',
  defaultCash: null,
  defaultFinancing: null,
  defaultValue: null,
  ...data
})

export const GET_DEFAULT_SALES_COMMISSION_FIELD_SETTINGS = (
  data?: DealSalesCommissionFieldSettingsModel
): DealSalesCommissionFieldSettingsModel => {
  return {
    fixed: null,
    minAmount: null,
    percent: null,
    salesCommissionTypeId: -1,
    ...data
  }
}