import type { FC } from 'react'

const LogoIcon: FC = () => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.08851 17.6758L6.96005 25.2737L4.09375 22.7264L6.90968 13.2913C7.53817 11.1853 9.18963 10.0781 12.2607 10.0781H26.0872L26.0935 12.8203H12.2607C11.0388 12.8203 10.4294 13.4318 10.4294 14.6485V15.107H18.1227V17.8493L9.08851 17.676V17.6758Z'
        fill='#101010'
      />

      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.0254 0.000391231C17.7569 0.000391231 21.3042 1.46712 23.9428 4.10108C24.9693 5.12571 25.8186 6.28767 26.4738 7.5443H23.1655C22.8022 7.03277 22.3938 6.54881 21.9423 6.09811C19.8358 3.99544 17.0048 2.82508 14.0258 2.82508C11.0466 2.82508 8.21557 3.99544 6.10906 6.09811C4.00255 8.20078 2.83024 11.0266 2.83024 14.0002C2.83024 16.9739 4.00274 19.7998 6.10906 21.9023C8.21557 24.0049 11.0466 25.1753 14.0258 25.1753C17.0048 25.1753 19.836 24.0049 21.9423 21.9023C23.1509 20.6959 24.0519 19.2515 24.6005 17.678H27.561C26.9269 20.0097 25.6916 22.1538 23.943 23.8993C21.3044 26.5331 17.7571 28 14.0256 28C10.2941 28 6.74675 26.5333 4.10818 23.8993C1.4696 21.2655 0 17.7247 0 14C0 10.2753 1.4694 6.73445 4.10818 4.10069C6.74675 1.46692 10.2941 0 14.0256 0L14.0254 0.000391231ZM25.1574 12.8044C25.0571 11.8668 24.8395 10.9523 24.5125 10.0803H27.4926C27.7501 10.9639 27.9208 11.8758 28 12.8044H25.1572H25.1574Z'
        fill='#101010'
      />
    </svg>

  )
}

export default LogoIcon
