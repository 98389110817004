import { type DealFormValues } from 'types'
import { type DealSalesCommissionSettingsModel } from 'api/types/responses'
import { roundCurrency } from 'utils/math'
import { isFalsy } from 'utils/general'

export interface GetSalesCommissionRoundedConfig {
  beValues: DealValuesForSalesCommission | null
  feValues: DealValuesForSalesCommission | null
  commissionSettings: DealSalesCommissionSettingsModel | null
  frontPercent: number | null
  oldSalesCommission: number | null
  oldSalesCost: number | null
}

export interface DealValuesForSalesCommission {
  dealFees: DealFeesLike
  tradeInDetails: Pick<DealFormValues['tradeInDetails'], 'acv' | 'credit'>
  vehicle: Pick<DealFormValues['vehicle'], 'listPrice'>
}

export type DealFeesLike = Pick<DealFormValues['dealFees'], 'carDelivery'>

/**
 * Sales commission is calculated on FE side only if Front Gross commission
 * is configured as `percent`, and it's not zero.
 *
 * newSalesCommission = be_salesCommission - be_dynamicPart + fe_dynamicPart
 * dynamicPart = be_frontPercent * (salePrice - be_salesCost + tradeInAllowance + carDelivery)
 * tradeInAllowance = tradeInACV - tradeInCredit
 */
const getSalesCommissionRounded = ({
  beValues,
  commissionSettings,
  feValues,
  frontPercent,
  oldSalesCommission,
  oldSalesCost
}: GetSalesCommissionRoundedConfig): number => {
  if (isFalsy(commissionSettings?.frontGrossSalesCommissionSetting?.percent)) {
    return oldSalesCommission ?? 0
  }

  const _beDynamicPart = roundCurrency(getFrontGrossCommission(
    frontPercent,
    oldSalesCost,
    beValues
  ))

  const beDynamicPart = Math.max(
    (commissionSettings?.frontGrossSalesCommissionSetting.minAmount ?? 0),
    _beDynamicPart
  )

  const _feDynamicPart = roundCurrency(getFrontGrossCommission(
    frontPercent,
    oldSalesCost,
    feValues
  ))

  const feDynamicPart = Math.max(
    (commissionSettings?.frontGrossSalesCommissionSetting.minAmount ?? 0),
    _feDynamicPart
  )

  return roundCurrency((oldSalesCommission ?? 0) - beDynamicPart + feDynamicPart)
}

const getFrontGrossCommission = (
  frontGrossPercent: number | null,
  salesCost: number | null,
  values: DealValuesForSalesCommission | null
): number => {
  return (frontGrossPercent ?? 0) * (
    (values?.vehicle.listPrice ?? 0) +
    (values?.tradeInDetails.acv ?? 0) -
    (values?.tradeInDetails.credit ?? 0) +
    (values?.dealFees.carDelivery ?? 0) -
    (salesCost ?? 0)
  )
}

export default getSalesCommissionRounded
