import type { FC } from 'react'
import { FormInput, FormMaskedInput } from '@carfluent/common'

import { ViewDealerRoles } from 'api/types'
import { isTruthy } from 'utils/general'
import { COMMON_MODAL_FORM_CLS } from 'constants/constants'
import DealerRolesDropdown from 'components/userManagement/DealerRolesDropdown'
import CheckboxLabel from 'components/common/CheckboxLabel'

import type { DealerAccountDetailsFormProps } from './hook/types'
import { FieldIds } from './hook/constants'

const DealerAccountDetailsForm: FC<DealerAccountDetailsFormProps> = ({
  dealers,
  selectedDealers,
  values,
  errors,
  touched,
  onChange,
  onBlur,
  onDealerSelect,
  onRoleChange
}) => {
  return (
    <div className={COMMON_MODAL_FORM_CLS}>
      <FormInput
        id={FieldIds.DealerFirstName}
        label='First name'
        onChange={onChange}
        onBlur={onBlur}
        value={values[FieldIds.DealerFirstName]}
        error={errors[FieldIds.DealerFirstName]}
        touched={touched[FieldIds.DealerFirstName]}
        dataTestId='user-field-dealer-first-name'
      />

      <FormInput
        id={FieldIds.DealerLastName}
        label='Last name'
        onChange={onChange}
        onBlur={onBlur}
        value={values[FieldIds.DealerLastName]}
        error={errors[FieldIds.DealerLastName]}
        touched={touched[FieldIds.DealerLastName]}
        dataTestId='user-field-dealer-last-name'
      />

      <div className='phone-number-wrapper'>
        <FormMaskedInput
          id={FieldIds.PhoneNumber}
          mask='phone'
          label='Phone number'
          onChange={onChange}
          onBlur={onBlur}
          value={values[FieldIds.PhoneNumber]}
          error={errors[FieldIds.PhoneNumber]}
          touched={touched[FieldIds.PhoneNumber]}
        />

        <span className='info-block'>
          Required for notifications
        </span>
      </div>

      <DealerRolesDropdown
        id={FieldIds.Role}
        onChange={onRoleChange}
        value={values[FieldIds.Role]}
        error={errors[FieldIds.Role] ?? ''}
      />

      <p className='title'>If role changed, user have to re-login, or changes will be applied within 30 minutes automatically.</p>
      {dealers.length > 1 && (
        <div className='checkboxes-wrapper'>
          <p className='checkboxes-title'>Dealership(s):</p>
          <div className='items-list'>
            {selectedDealers.map((dealer) => (
              <div
                key={dealer.id}
                className='item-block'
              >
                <CheckboxLabel
                  onChange={onDealerSelect}
                  value={dealer}
                  disabled={Number(values[FieldIds.Role]) === ViewDealerRoles.DealerAdmin}
                  checked={Number(values[FieldIds.Role]) === ViewDealerRoles.DealerAdmin || dealer.checked}
                />
              </div>
            ))}
          </div>
          {isTruthy(errors[FieldIds.DealerIds]) && (
            <p className='error-text'>
              {errors[FieldIds.DealerIds]}
            </p>
          )}
        </div>
      )}
    </div>
  )
}

export default DealerAccountDetailsForm
