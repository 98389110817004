import type { DealerAccount, DictionaryItem } from 'api/types'

const parseDealerAccount = (dealerAccount: DealerAccount | null): DictionaryItem | null => {
  const { id, dealerFirstName, dealerLastName } = dealerAccount ?? {}

  return id != null && dealerFirstName != null && dealerLastName != null
    ? {
        id,
        name: `${dealerFirstName} ${dealerLastName}`
      }
    : null
}

export default parseDealerAccount
