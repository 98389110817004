import type { FC, MouseEvent } from 'react'

import type { EmailThreadListItem } from 'api/types'
import MultilineTextEllipsis from 'components/common/MultilineTextEllipsis'
import HTMLContent from 'components/common/HTMLContent'
import AttachmentItems from 'components/common/AttachmentItems'

import IconSVG from 'components/inlineImages'
import CLASS_NAME from './styles'

export enum EmailThreadListItemType {
  outbound = 1,
  inbound = 2
}

interface EmailThreadListItemProps extends EmailThreadListItem {
  emailIndex: number
  lastEmailIndex: number
  onClickSetIsResponseEmail: (e: MouseEvent<HTMLDivElement>, emailIndex: number, emailId: string | number) => void
}

const EmailListItem: FC<EmailThreadListItemProps> = ({
  id, displayName, createdDate,
  body, emailType, emailIndex,
  emailAttachments, lastEmailIndex,
  onClickSetIsResponseEmail
}) => {
  const hasAttachedFiles = emailAttachments.length > 0

  const onSetResponseEmail = (e: MouseEvent<HTMLDivElement>): void => {
    onClickSetIsResponseEmail(e, emailIndex, id)
  }

  return (
    <div className={CLASS_NAME}>
      <MultilineTextEllipsis
        maxLines={1}
        initiallyCollapsed={emailIndex === lastEmailIndex}
      >
        <div className='email-header'>
          <div className='title'>
            {emailType === EmailThreadListItemType.inbound ? <IconSVG.EmailInbound /> : <IconSVG.EmailOutbound />}
            <span className='title-text'>{displayName}</span>
          </div>
          <div className='email-reply'>
            {hasAttachedFiles && (
              <div className='attached-files'>
                <IconSVG.Clip color='rgba(33, 33, 33, 0.50)' />
              </div>)}
            <div className='email-reply-date'>
              <span>{createdDate}</span>
            </div>
            <div className='email-reply-icon' onClick={onSetResponseEmail}>
              <IconSVG.Reply />
            </div>
          </div>
        </div>
        <div className='email-body'>
          {body != null &&
            <HTMLContent
              isShouldConvertWithLink={false}
              text={body}
            />}
          {hasAttachedFiles && (
            <div>
              <p className='attachments-title'>Attachments:</p>
              <AttachmentItems
                items={emailAttachments}
              />
            </div>
          )}
        </div>
      </MultilineTextEllipsis>
    </div>
  )
}

export default EmailListItem
