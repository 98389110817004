import { type FC } from 'react'
import { type IconProps } from 'types'

const IconSVGDropdownArrowUp: FC<IconProps> = ({ color = '#000', width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${height} ${width}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.41 15.7031L12 11.1231L16.59 15.7031L18 14.2931L12 8.29313L6 14.2931L7.41 15.7031Z'
        fill={color}
        fillOpacity='0.87'
      />
    </svg>
  )
}

export default IconSVGDropdownArrowUp
