import { type RefObject, type MouseEvent, type ChangeEvent } from 'react'
import { type TableRowProps } from '@carfluent/common'

import { type EntityId } from 'types'
import {
  type BankStatementTransactionMatchItem,
  type BankStatementTransactionsListItem,
  type TransactionDetails,
  type Customer,
  type TransactionLineVendorDto
} from 'api/types'

import { type ReviewFormData, type FormViewProps } from '../components/FormView/types'

export { type ReviewFormData }

export interface BankStatementRowData extends Omit<BankStatementTransactionsListItem, 'date'> {
  date: Date | null
  lastLoadTS: number
  isLazyLoadReason: boolean
}

export type UseRowForReviewProps <V extends BankStatementRowData> = TableRowProps<V> & RowForReviewHOCProps

export interface RowForReviewHOCProps {
  accountId: number | null
  onCategorize: () => void
  onExclude: () => void
  onViewTransaction: (transactionId: number, transactionTypeId: number) => void
  onViewPreloadedTransaction: (
    transaction: TransactionDetails,
    bankStatementId: number,
    matches: BankStatementTransactionMatchItem[]
  ) => Promise<ViewTransactionResult>
  refRowStates?: RefObject<Record<EntityId, RowForReviewState>>
  showError?: (msg: string) => void
  onOpenCustomerModal: () => Promise<Customer | null>
  onOpenVendorModal: () => Promise<TransactionLineVendorDto | null>
}

export interface RowForReviewState {
  isExpanded?: boolean
  isExcluded?: boolean
  isBannerErrorVisible?: boolean
  reviewCategory?: string
  values?: ReviewFormData
}

export interface UseRowForReviewReturn extends FormViewProps {
  isBannerErrorVisible: boolean
  isExpanded: boolean
  isExcluded: boolean
  isLoading: boolean
  hasMatches: boolean
  onChangeReviewCategory: (evt: ChangeEvent<HTMLInputElement>, value: string, id: string) => void
  onClearMethodClick: (match: BankStatementTransactionMatchItem) => Promise<void>
  onViewTransaction: (match: BankStatementTransactionMatchItem) => void
  onRowClick: (evt: MouseEvent<HTMLTableRowElement>) => void
}

export type ViewTransactionResult = ViewTransactionResultClearMethod | ViewTransactionResultRegular

export type ViewTransactionResultType = 'cancel' | 'submit-create-match' | 'submit-update-existing' | 'bank-statement-clear-method'

export interface ViewTransactionResultRegular {
  type: Exclude<ViewTransactionResultType, 'bank-statement-clear-method'>
  payload: null
}

export interface ViewTransactionResultClearMethod {
  type: 'bank-statement-clear-method'
  payload: BankStatementTransactionMatchItem
}

export enum ReviewCategory {
  Categorize = 'Categorize',
  FindMatch = 'FindMatch',
  RecordAsTransfer = 'RecordAsTransfer',
  RecordAsCreditCard = 'RecordAsCreditCard'
}
