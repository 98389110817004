import type { BankStatementsListFilters, BankStatementsListFiltersForApi } from './types'
import serializeFiltersLocal from 'utils/filters/serializeFiltersLocal'

const serializeFilters = (data: BankStatementsListFilters): BankStatementsListFiltersForApi => {
  return {
    ...serializeFiltersLocal(data),
    transactionState: data.transactionState
  }
}

export default serializeFilters
