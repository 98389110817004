import { css } from '@emotion/css'

export default css(`
  display: flex;
  justify-content: center;
  align-items: center;
  
  p {
    font-weight: 500;
    margin-left: 16px;
    margin-right: 6px;
    text-transform: none;
    font-size: 16px;
    letter-spacing: 0.5px;
  }
  
  span {
    color:  #458FFF;
    text-transform: none;
    font-size: 16px;
    font-weight: 400px;
    letter-spacing: 0.5px;
  }
`)
