import { useCallback, type FC, type HTMLAttributes, type ReactNode, useMemo } from 'react'
import { cnx, CellWrapper, FormDropdown, type DropdownAction } from '@carfluent/common'

import type { BaseListItem as ListItem, CellProps, CellFC, KeyVal } from 'types'
import type { ListResponse, ListPayload } from 'api/types'
import type { EditableCellProps } from 'components/common/Table/cells/editable_cell'
import ApplyPropsHOK from 'components/common/ApplyPropsHOC'
import { CELL_CLASS_NAME, POPOVER_CLASS_NAME } from './styles'

export type DictionaryCellHOCProps<TItem extends ListItem = ListItem> =
  EditableCellProps<TItem | null> & {
    actions?: DropdownCellAction[]
    disableClearable?: boolean
    filterOptions?: (items: TItem[], inputValue: string) => TItem[]
    getItems: (payload: ListPayload) => Promise<ListResponse<TItem>>
    getValueOnBlur?: (items: TItem[], inputValue: string) => TItem | null
    isOptionDisabled?: (option: { id: string, value: TItem }) => boolean // AZ-TODO: export type Item from common library's Dropdown
    isDropdownOpen?: boolean
    label?: string
    ListboxHOC?: (props: ListboxHOCProps) => FC<ListboxBaseProps>
    popoverClassName?: string
    renderAction?: (props: DropdownAction) => ReactNode
    renderOption?: (option: TItem | null) => ReactNode
  }

export type DropdownCellAction = Omit<DropdownAction, 'onClick'> & {
  onClick: (rowIdx: number, columnId: string, actionId: string) => void
}

export type DictionaryCellProps<TRow extends KeyVal, TItem extends ListItem = ListItem> =
  CellProps<TRow> & DictionaryCellHOCProps<TItem>

export interface ListboxHOCProps {
  rowIdx: number
  columnId: string
}

export type ListboxBaseProps = HTMLAttributes<HTMLElement>

export function DictionaryCell<TRow extends KeyVal, TItem extends ListItem = ListItem> (
  props: DictionaryCellProps<TRow, TItem>
): JSX.Element {
  const {
    actions: _actions,
    column,
    disableClearable = false,
    getItems,
    getValue,
    isDropdownOpen,
    isOptionDisabled,
    row,
    label,
    onChange: _onChange,
    popoverClassName,
    renderAction,
    renderOption
  } = props

  const _value = getValue() as TItem | null
  const value = _value == null ? null : _value
  const hasValue = Boolean(value?.id)
  const columnId = column.id
  const rowIdx = row.index
  const id = `${columnId}-${rowIdx}`

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onChange = useCallback((_, value: TItem | null) => {
    _onChange(row.index, column.id, value)
  }, [_onChange, columnId, rowIdx])

  const actions: DropdownAction[] = useMemo(() => {
    return (_actions ?? []).map((item: DropdownCellAction) => {
      return {
        ...item,
        onClick: (actionId: string) => {
          item.onClick(rowIdx, columnId, actionId)
        }
      }
    })
  }, [_actions, columnId, rowIdx])

  // ========================================== //

  return (
    <CellWrapper className={CELL_CLASS_NAME}>
      <FormDropdown<TItem>
        actions={actions}
        disableClearable={disableClearable}
        id={id}
        isOpen={isDropdownOpen}
        isOptionDisabled={isOptionDisabled} // AZ-TODO: export type Item from common library's Dropdown
        fetchHandler={getItems}
        mode='search'
        onChange={onChange}
        placeholder={hasValue ? undefined : label}
        popoverClassName={cnx(POPOVER_CLASS_NAME, popoverClassName)}
        renderAction={renderAction}
        renderOption={renderOption}
        value={value}
      />
    </CellWrapper>
  )
}

function DictionaryCellHOK<TRow extends KeyVal, TItem extends ListItem = ListItem> (
  config: DictionaryCellHOCProps<TItem>
): CellFC<TRow> {
  return ApplyPropsHOK<DictionaryCellHOCProps<TItem>, CellProps<TRow>>(config, DictionaryCell)
}

export default DictionaryCellHOK

// ========================================== //

export const filterListByName = <T extends ListItem>(items: T[], inputValue: string): T[] => (
  items.filter(({ name }) => (name.toLowerCase().includes(inputValue.toLowerCase() ?? '')))
)
