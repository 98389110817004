import { type FormValidation, utils } from '@carfluent/common'

import { buildPreset } from 'utils/validationPresets'
import { ruleFileMaxSize, ruleRequired, ruleFileExtensions } from 'utils/validationRules'
import { Messages, FILE_EXTENSIONS } from './constants'
import type { StatementUploadFormData } from './types'

const { KiB, FILE_SIZE_ERROR } = utils
const FILE_MAX_SIZE = 15 * KiB * KiB // MB

const fileField = buildPreset([
  ruleFileMaxSize(FILE_SIZE_ERROR(FILE_MAX_SIZE), FILE_MAX_SIZE),
  ruleFileExtensions(Messages.InvalidFormat, FILE_EXTENSIONS),
  ruleRequired(Messages.FileRequired)
])

const validationRules: FormValidation<StatementUploadFormData> = {
  account: ruleRequired(Messages.AccountRequired),
  files: fileField(),
  bank: ruleRequired(Messages.BankRequired)
}

export default validationRules
