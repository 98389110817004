import type { FC } from 'react'
import { Button, Loader } from '@carfluent/common'

import OverlayedPageHOC from 'components/PageOverlay/OverlayedPageHOC'
import BackToTop from 'components/common/BackToTop'
import Table from 'components/common/Table'
import FiltersPanel from 'components/common/FiltersPanel'

import ReconStatusFilters from 'components/reconditioning/ReconStatusFilters'
import useReconList from './hook'
import { TABLE_WRAPPER_UNIQUE_CLASSNAME } from './hook/constants'
import CLASS_NAME from './styles'

const TABLE_CLASSES = { wrapper: TABLE_WRAPPER_UNIQUE_CLASSNAME }

const ReconList: FC = () => {
  const {
    containerElement,
    isLoading,
    emptyTableMessage,
    onChangeStep,
    onBottomReached,
    rows,
    search,
    setSearch,
    selectedStep,
    setSorting,
    sorting,
    onRowClick,
    onSearch,
    onPrint,
    isPrintLoading,
    columns,
    statusFiltersProps
  } = useReconList()

  return (
    <div className={CLASS_NAME}>
      <FiltersPanel
        searchProps={{
          className: 'g-non-printable-block',
          onChange: setSearch,
          value: search,
          placeholder: 'VIN, make, tag, stock #',
          withDeleteIcon: true,
          isLoading,
          onSearch
        }}
        renderActions={() => (
          <Button
            className='print-pdf-button'
            variant='text'
            onClick={onPrint}
          >
            Print PDF {isPrintLoading && <Loader size='small' color='dark' />}
          </Button>
        )}
      />

      <div className='cf-recon-list-section'>
        <ReconStatusFilters
          selectedStep={selectedStep}
          onChangeStep={onChangeStep}
          statusFiltersProps={statusFiltersProps}
          className='recon-list'
        />

        <Table
          classes={TABLE_CLASSES}
          columns={columns}
          containerElement={containerElement}
          data={rows}
          isLoading={isLoading}
          onRowClick={onRowClick}
          emptyTableMessage={emptyTableMessage}
          onBottomReached={onBottomReached}
          onSortingChange={setSorting}
          sorting={sorting}
        />
      </div>

      <BackToTop />
    </div>
  )
}

export default OverlayedPageHOC(ReconList)
