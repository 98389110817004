import { css } from '@emotion/css'

export default css`
  padding-bottom: 80px;

  .promo-title {
    display: flex;
    justify-content: flex-start;

    h2 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      padding-right: 16px;
      display: flex;
    }

    > div {
      label {
        margin: 0px;
      }

      .cf-main-block {
        background: #999;
        
        &.active {
          background: #219653;
        }
      }
    }
  }

  .is-disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.7;
  }

  .cf-item-wrapper .hoverable {
    background: #000000DE;
    opacity: 0.3;

    .cf-image-buttons-control {
      justify-content: center;
    }
  }

  .promo-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    > button.cf-btn-update {
      height: 26px;
      text-decoration: none;
      border-radius: 8px;
      border: 1px solid #C99B86;
      padding: 5px 18px;
    }
  }

  .promo-content {
    min-width: min-content;
    
    &.monthly-payment-content {
      display: grid;
      grid-template-columns: 200px 16px 200px 1px 200px 200px;
      column-gap: 16px;
      align-items: center;
      margin-bottom: 40px;
      
      .divider {
        background: rgba(0, 0, 0, 0.12);
        height: 100%;
      }
    }
  }

  .gallery-frequency .cf-input-container {
    width: 200px;
    margin-bottom: 24px;
  }

  .cf-dropzone {  
    .dropzone-container {
      background: #fff;
      padding-top: 24px;
      padding-bottom: 56px;
  
      .no-images-yet {
        color: #21212180;
        font-size: 16px;
        padding: 56px;
        text-align: center;
      }
    }

    .cf-dnd-overlay {
      background: #fff;
      opacity: 0.9;
      border: 2px dashed #C99B86;
    }
  
    .cf-content-wrapper {
      &.cf-content-hidden {
        visibility: visible;
      }
    }
  }
`
