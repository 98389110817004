import { useEffect, useState, useCallback } from 'react'
import { useSubscribe } from '@carfluent/common'

import { type DocumentFormDto, type DocumentFormPackDto } from 'api/types'
import DocumentsApiProvider from 'api/documents.api'
import Events from 'constants/events'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useCloudAccessToken from 'hooks/useCloudAccessToken'
import { downloadBlob } from 'utils/general'
import getUniqueName from 'utils/getUniqueName'
import { getFormIdByUniqId, getFormIdsByUniqIds } from 'utils/deals/getFormId'
import { type FormRowItem } from 'components/deals/FormsTable/components/FormRow'

import {
  type DealFormState,
  type UseDealFormsTabProps,
  type UseDealFormsTabReturn
} from './types'

const MIN_ITEMS_IN_TABLE = 1

export const useDealFormsTab = ({
  dealId,
  state,
  setState
}: UseDealFormsTabProps): UseDealFormsTabReturn => {
  const [checkedItems, setCheckedItems] = useState<string[]>([])
  const [loadingItemDelete, setLoadingItemDelete] = useState<string[]>([])
  const [isNotarizing, setIsNotarizing] = useState(false)
  const [isViewing, setIsViewing] = useState(false)
  const [isSignModalOpen, setIsSignModalOpen] = useState(false)
  const [loadingViewIds, setLoadingViewIds] = useState<string[]>([])
  const [isLoadingEnvelop, setIsLoadingEnvelop] = useState(false)

  const [signEnvelopUrlId, setSignEnvelopUrlId] = useState<number | null>(null)

  const [voidEnvelopId, setVoidEnvelopId] = useState<number | null>(null)
  const [isVoiding, setIsVoiding] = useState(false)

  const [resendEnvelopId, setResendEnvelopId] = useState<number | null>(null)
  const [isResending, setIsResending] = useState(false)

  const { showAlert } = useCustomSnackbar()
  const { token = '' } = useCloudAccessToken() ?? {}

  const downloadDocumentDealForms = async (ids: number[]): Promise<void> => {
    const res = await DocumentsApiProvider.downloadDocumentDealForms(
      dealId,
      ids
    )

    const url = downloadBlob(
      res,
      'deal_form_documents.pdf',
      { type: 'application/pdf' }
    )
    window.open(url, '_blank')
  }

  // ========================================== //
  //               ENVELOP HANDLERS             //
  // ========================================== //

  const onViewSignedContract = async (fileId: number): Promise<void> => {
    const { uri } = await DocumentsApiProvider.getFile(fileId)
    window.open(`${uri}?${token}`, '_blank')
  }

  const updateEnvelops = useCallback(async (): Promise<void> => {
    const { items: envelopes } = await DocumentsApiProvider.getEnvelopes(dealId)
    setState(prev => ({ ...prev, envelopes }))
  }, [setState])

  const onVoidEnvelope = async (): Promise<void> => {
    if (voidEnvelopId == null) {
      return
    }

    try {
      setIsVoiding(true)
      await DocumentsApiProvider.voidEnvelop(voidEnvelopId)

      setIsLoadingEnvelop(true)
      await updateEnvelops()
      showAlert('Voided successfully', { variant: 'success' })
      setVoidEnvelopId(null)
    } catch {
      showAlert('Cannot void the form pack.')
    } finally {
      setIsLoadingEnvelop(false)
      setIsVoiding(false)
    }
  }

  const onResendEnvelope = async (): Promise<void> => {
    if (resendEnvelopId == null) {
      return
    }

    try {
      setIsResending(true)
      await DocumentsApiProvider.resendEnvelop(resendEnvelopId)

      setIsLoadingEnvelop(true)
      await updateEnvelops()
      showAlert('Form pack resent successfully', { variant: 'success' })
      setResendEnvelopId(null)
    } catch {
      showAlert('Cannot resend form pack.')
    } finally {
      setIsLoadingEnvelop(false)
      setIsResending(false)
    }
  }

  const onCloseSignUrlModal = useCallback(() => {
    setSignEnvelopUrlId(null)
  }, [])

  const onSignUrl = useCallback(async () => {
    setSignEnvelopUrlId(null)

    try {
      setIsLoadingEnvelop(true)
      await updateEnvelops()
      onCloseSignUrlModal()
    } catch {
      showAlert('Cannot load form packs.')
    } finally {
      setIsLoadingEnvelop(false)
    }
  }, [updateEnvelops, showAlert, onCloseSignUrlModal])

  // ========================================== //
  //            MANAGE BANNER HANDLERS          //
  // ========================================== //

  const onView = async (): Promise<void> => {
    try {
      setIsViewing(true)
      await downloadDocumentDealForms(getFormIdsByUniqIds(state.documents, checkedItems))
    } catch {
      showAlert('Cannot view deal forms.')
    } finally {
      setIsViewing(false)
    }
  }

  const onNotarize = async (): Promise<void> => {
    try {
      setIsNotarizing(true)

      await DocumentsApiProvider.notarizeDocumentDealForms(
        dealId,
        getFormIdsByUniqIds(state.documents, checkedItems)
      )

      showAlert('Deal forms was notarize successfully.', { variant: 'success' })
    } catch {
      showAlert('Cannot notarize deal forms.')
    } finally {
      setIsNotarizing(false)
    }
  }

  const onOpenSignModal = (): void => setIsSignModalOpen(true)
  const onCloseSignModal = (): void => setIsSignModalOpen(false)

  // ========================================== //
  //              FORM TABLE HANDLERS           //
  // ========================================== //

  const onDeleteItem = async (item: FormRowItem): Promise<void> => {
    try {
      setLoadingItemDelete(prev => {
        return [...prev, item.uniqId]
      })

      const nextItems = [...state.documents]
      const idx = nextItems.findIndex(({ uniqId }) => item.uniqId === uniqId)
      if (idx >= 0) {
        nextItems.splice(idx, 1)

        await DocumentsApiProvider.patchDocumentDealForms(
          dealId,
          nextItems.map(({ id }) => id)
        )
        setState(prev => ({ ...prev, documents: nextItems }))
      }

      setCheckedItems((prev) => {
        const idx = prev.findIndex((id) => item.uniqId === id)

        if (idx < 0) {
          return prev
        }

        const next = [...prev]
        next.splice(idx, 1)
        return next
      })

      showAlert('Form was successfully deleted.', { variant: 'success' })
    } catch {
      showAlert('Cannot delete form.')
    } finally {
      setLoadingItemDelete(prev => {
        const idx = prev.findIndex((id) => id === item.uniqId)
        if (idx === -1) {
          return prev
        }

        const next = [...prev]
        next.splice(idx, 1)
        return next
      })
    }
  }

  const onAdd = async (item: DocumentFormDto | DocumentFormPackDto): Promise<void> => {
    const currState = state

    try {
      const itemsToAdd = (
        'forms' in item
          ? item.forms ?? []
          : [item]
      ).map((item) => ({
        ...item,
        uniqId: getUniqueName(`${item.id}${item.name}`)
      }))

      const nextItems = [...state.documents, ...itemsToAdd]

      setState(prev => ({ ...prev, documents: nextItems }))

      setCheckedItems(prev => [...prev, ...itemsToAdd.map(({ uniqId }) => uniqId)])

      await DocumentsApiProvider.patchDocumentDealForms(
        dealId,
        nextItems.map(({ id }) => id)
      )
    } catch {
      setState(currState)
      setCheckedItems(checkedItems)
      showAlert('Cannot add form.')
    }
  }

  const onChange = async (nextItems: FormRowItem[]): Promise<void> => {
    let curr: DealFormState | null = null

    try {
      setState(prev => {
        curr = prev
        return { ...prev, documents: nextItems }
      })

      await DocumentsApiProvider.patchDocumentDealForms(
        dealId,
        nextItems.map(({ id }) => id)
      )

      showAlert('Forms updated successfully.', { variant: 'success' })
    } catch {
      if (curr != null) {
        setState(curr)
      }
      showAlert('Cannot update forms.')
    }
  }

  const onFormView = async (uniqId: string): Promise<void> => {
    try {
      setLoadingViewIds((prev) => [...prev, uniqId])

      const id = getFormIdByUniqId(state.documents, uniqId)

      if (id != null) {
        await downloadDocumentDealForms([id])
      }
    } finally {
      setLoadingViewIds(prev => {
        const idx = prev.findIndex((id) => id === uniqId)
        if (idx === -1) {
          return prev
        }

        const next = [...prev]
        next.splice(idx, 1)
        return next
      })
    }
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    setCheckedItems(state.documents.map(({ uniqId }) => uniqId))
  }, [state.documents])

  useSubscribe(Events.DealShowSignForm, async () => {
    setSignEnvelopUrlId(state.envelopes[0].id)
  })

  // ========================================== //
  //              PREPARING PROPS               //
  // ========================================== //

  const isDeleteAllowed = state.documents.length > MIN_ITEMS_IN_TABLE &&
    ((state.documents.length - loadingItemDelete.length) > MIN_ITEMS_IN_TABLE)

  // ========================================== //

  return {
    // form table
    onDeleteItem,
    checkedItems,
    onAdd,
    items: state.documents,
    onChange,
    setCheckedItems,
    loadingItemDelete,
    envelopItems: state.envelopes,
    loadingViewIds,
    onFormView,
    manageFormBannerProps: {
      onSend: onOpenSignModal,
      onView,
      onNotarize,
      amount: checkedItems.length,
      isNotarizing,
      isViewing
    },

    // envelop table
    isDeleteAllowed,
    onCloseSignModal,
    isSignModalOpen,
    updateEnvelops,
    signEnvelopUrlId,
    onCloseSignUrlModal,
    isLoadingEnvelop,
    onSignUrl,
    envelopItemProps: {
      onVoid: setVoidEnvelopId,
      onResend: setResendEnvelopId,
      onSign: setSignEnvelopUrlId,
      onView: downloadDocumentDealForms,
      onViewSignedContract: onViewSignedContract
    },

    // envelop confirm void
    voidEnvelopId,
    setVoidEnvelopId,
    isVoiding,
    onVoidEnvelope,

    // envelop confirm resend
    isResending,
    resendEnvelopId,
    setResendEnvelopId,
    onResendEnvelope
  }
}
