import { css } from '@emotion/css'

export default css(`
  display: flex;
  align-items: center;
  
  .vehicle-img-block {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .vehicle-img {
    height: 52px;

    img { border-radius: 4px; }
  }
  
  .vehicle-text-block {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .bold-text {
    font-weight: 500;
    color: #101010;
  }
`)
