import type { FC } from 'react'
import { FormDropdown, cnx } from '@carfluent/common'

import AccountingApiProvider from 'api/accounting.api'
import type { AccountListItem } from 'api/types'
import getAccountOptionName from 'utils/accounting/getAccountOptinName'
import { isTruthy } from 'utils/general'

import type { VehiclePurchasePaymentsFormProps } from './types'
import { accountKeys } from './constants'

import CLASS_NAME from './styles'

const VehiclePurchasePaymentsForm: FC<VehiclePurchasePaymentsFormProps> = ({
  items,
  onBlur,
  values,
  errors,
  touched,
  onSelect
}) => {
  return (
    <div className={cnx(CLASS_NAME, 'cf-vehicle-purchase-payments-form')}>
      {accountKeys.map((accountKey) => {
        const key = accountKey as keyof typeof values
        return (
          <FormDropdown<AccountListItem>
            key={key}
            label='Account'
            onBlur={onBlur}
            id={accountKey}
            options={items}
            value={values[key]}
            onChange={onSelect}
            touched={touched[key]}
            error={errors[key]}
            renderOption={getAccountOptionName}
            formatDisplayValue={getAccountOptionName}
            fetchHandler={AccountingApiProvider.getAccounts}
            className={cnx(isTruthy(values[key]) && 'selected-account')}
          />
        )
      })}
    </div>
  )
}

export default VehiclePurchasePaymentsForm
