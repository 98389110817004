export interface ReportFileNames {
  pdf: string
  xlsx: string
}

const fileNames = (base: string): ReportFileNames => ({
  pdf: `${base}.pdf`,
  xlsx: `${base}.xlsx`
})

export default fileNames
