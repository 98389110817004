import type { KeyboardEvent, BaseSyntheticEvent } from 'react'
import isDate from 'date-fns/isDate'
import get from 'lodash-es/get'
import type { SortDirection } from '@carfluent/common'

import { type DictionaryItem, SortOrder } from 'api/types'
import type { ItemWithId } from 'types'

export const keys = <T extends Record<string, any>>(obj: T): [keyof T] => {
  return Object.keys(obj) as [keyof T]
}

export const compareDictionaryByName = (a: DictionaryItem, b: DictionaryItem): number => {
  const nameA = a.name.toUpperCase()
  const nameB = b.name.toUpperCase()

  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}

export const containsTruthy = (value: any, isZeroAllowed: boolean = false): boolean => {
  if (Array.isArray(value)) {
    return value.some(x => containsTruthy(x, isZeroAllowed))
  }

  if (isDate(value)) {
    return true
  }

  if ((typeof value === 'object') && (value !== null)) {
    return Object.values(value).some(x => containsTruthy(x, isZeroAllowed))
  }

  if (isZeroAllowed && (value === 0)) {
    return true
  }

  return Boolean(value)
}

export const isFalsy = (value: any): boolean => {
  const bool = Boolean(value)
  return !bool
}

export const isTruthy = (value: any): boolean => {
  return Boolean(value)
}

export const trimSlashEnd = (value: string): string => {
  return value.replace(/\/$/, '')
}

export const trimSpaces = (value: string): string => {
  return value.trim()
}

export const downloadBlob = (fileContent: any, fileName: string, blobOptions?: BlobPropertyBag): string => {
  const url = window.URL.createObjectURL(new Blob([fileContent], blobOptions))
  const link = document.createElement('a')
  link.setAttribute('download', fileName)
  link.href = url
  document.body.appendChild(link)
  link.click()
  link.remove()

  return url
}

export const capitalizeString = (value: string = ''): string => {
  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`
}

export const intercalate = <T>(glue: T, arr: T[]): T[] => {
  return arr.reduce((acc: T[], v) => acc.concat([glue, v]), []).slice(1)
}

export enum Keys {
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  Backspace = 'Backspace',
  Delete = 'Delete',
  End = 'End',
  Escape = 'Escape',
  Insert = 'Insert',
  Home = 'Home',
  Number = 'Number'
}

const KEYS: string[] = ['Esc'].concat(Object.values(Keys))

export const isSpecialKey = (evt: KeyboardEvent): boolean => {
  return evt.altKey || evt.ctrlKey || evt.metaKey || KEYS.includes(evt.key)
}

export const isFieldName = <T>(k: any, value: T): k is keyof T => {
  return (typeof k === 'string') && (get(value, k) !== undefined)
}

export const assertFieldName: <T>(k: unknown, value: T) => asserts k is keyof T = (k, value) => {
  if ((typeof k !== 'string') || (get(value, k) === undefined)) {
    throw new Error(`field ${k} is not found in object ${JSON.stringify(value)}`)
  }
}

export const isFieldNameCurried = <T>(value: T) => (k: any): k is keyof T => {
  return (typeof k === 'string') && (get(value, k) !== undefined)
}

type FnToWrap<TArg> = (arg: TArg | null | undefined) => void
type FnToReturn<TArg, TEvt = Event> = (arg: TArg | null | undefined | TEvt) => void

export const isEvent = <TEvt extends BaseSyntheticEvent>(arg: unknown): arg is TEvt => {
  return (arg as BaseSyntheticEvent).eventPhase !== undefined
}

export const eventToNull = <TArg, TEvt extends BaseSyntheticEvent = BaseSyntheticEvent>(fn: FnToWrap<TArg>): FnToReturn<TArg, TEvt> => {
  return (arg: TArg | null | undefined | TEvt): void => {
    if (isEvent(arg)) {
      return fn(null)
    }

    return fn(arg)
  }
}

export const getOptionSelected = (option: ItemWithId | null, value: ItemWithId | null): boolean => {
  return option?.id === value?.id
}

export const getFirstError = (error: unknown): string | null => {
  if (error == null) {
    return null
  }

  if (Array.isArray(error)) {
    return getFirstError(error.filter(Boolean)[0])
  }

  if (typeof error === 'object') {
    const anyVal = Object.values(error).find(val => val != null)
    return getFirstError(anyVal)
  }

  return `${error}`
}

export const convertSortingOrder = (order: SortDirection): SortOrder => {
  return (order === 'desc') ? SortOrder.Descending : SortOrder.Ascending
}

export const parseFileNameFromContentDisposition = (disposition: string, defaultFileName: string = 'download.xlsx'): string => {
  let filename = defaultFileName

  if (disposition?.includes('filename=')) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition)

    if (matches?.[1] != null) {
      filename = matches[1].replace(/['"]/g, '')
    }
  }

  return filename
}
