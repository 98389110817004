import { WrapperRequest } from './wrapper.api'

import {
  type CoverageTemplates,
  type DealerData
} from './types'

class DealersApi extends WrapperRequest {
  url = process.env.REACT_APP_DOCUMENTS ?? ''

  patchDealers = async (data: Partial<DealerData>): Promise<void> => {
    return await this.patch('/api/v1/Dealers', data)
  }

  getDealer = async (): Promise<DealerData> => {
    /**
     * The name is wrong! Method returns a single object
     */
    return await this.get('/api/v1/Dealers')
  }

  getDealersCoverageTemplates = async () => {
    return await this.get<CoverageTemplates>('/api/v1/Dealers/coverage-view-templates')
  }
}

const DealersApiProvider = new DealersApi()
export default DealersApiProvider
