import {
  type ChangeEvent,
  type KeyboardEvent,
  useRef,
  useState,
  useEffect,
  useCallback
} from 'react'

import { FCHook } from 'types'
import { isTruthy } from 'utils/general'

import type { UseSearchInputProps, UseSearchInputReturn } from './types'

const useSearchInput: FCHook<UseSearchInputProps, UseSearchInputReturn> = ({
  onSearch,
  disabled,
  onChange: _onChange
}) => {
  const [isActive, setIsActive] = useState(false)
  const isIconMouseDown = useRef(false) // Ref to track if mousedown event happened on the icon
  const inputRef = useRef<HTMLInputElement | null>(null)
  const iconColor = isActive ? '#212121' : 'rgba(33, 33, 33, 0.54)'

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onIconMouseDown = (): void => {
    isIconMouseDown.current = true
  }

  // Handle mouseup on the delete icon to reset state, clear the input,
  // and focus it if mousedown is caused on delete icon
  const onIconMouseUp = (): void => {
    if (isIconMouseDown.current) {
      isIconMouseDown.current = false
      setIsActive(true)
      _onChange('')
      inputRef.current?.focus()
      onSearch?.()
    }
  }

  const onChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
    if (!isTruthy(disabled)) {
      _onChange(value)
    }
  }, [disabled, _onChange])

  const onFocus = useCallback(() => setIsActive(true), [])

  // Handle blur event: if blur is caused by mousedown on delete icon, maintain active state
  const onBlur = useCallback(() => {
    if (!isIconMouseDown.current) {
      setIsActive(false)
    }
  }, [])

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      onSearch?.()
    }
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  // Add a mouseup event listener to reset isIconMouseDown state and to focus the input field if required
  useEffect(() => {
    const eventHandler = (event: MouseEvent): void => {
      if (isIconMouseDown.current) {
        setIsActive(false)
        isIconMouseDown.current = false
      }

      if (event.target === inputRef.current) {
        inputRef.current?.focus()
      }
    }
    document.addEventListener('mouseup', eventHandler)
    return () => {
      document.removeEventListener('mouseup', eventHandler)
    }
  }, [])

  // ========================================== //

  return {
    onBlur,
    onFocus,
    inputRef,
    onChange,
    onKeyDown,
    iconColor,
    onIconMouseUp,
    onIconMouseDown
  }
}

export default useSearchInput
