import type { FC } from 'react'
import LoadingBasicCell from 'components/common/Table/loading_cells/basic'
import BigSkeletonLineCell from 'components/common/Table/loading_cells/BigSkeletonLine'

import CLASS_NAME from './styles'

const LoadingRow: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <BigSkeletonLineCell />
      <LoadingBasicCell />
      <LoadingBasicCell />
      <LoadingBasicCell />
      <LoadingBasicCell />
      <BigSkeletonLineCell />
    </div>
  )
}

export default LoadingRow
