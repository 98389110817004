import { type DealFormValues } from 'types'
import { type DealModel } from 'api/types/responses'
import { GET_DEFAULT_ACTIVE_LENDER_DECISION } from 'api/defaults'

export const GET_DEFAULT_DEAL_TAB_VALUES = (deal?: DealModel | null): DealFormValues => ({
  activeLenderDecision: deal?.activeLenderDecision ?? GET_DEFAULT_ACTIVE_LENDER_DECISION(),
  dealFees: {
    buyerTag: deal?.dealFees.buyerTag ?? 0,
    carDelivery: deal?.dealFees.carDelivery ?? 0,
    dealerHandlingFee: deal?.dealFees.dealerHandlingFee ?? 0,
    dealerInventoryTax: deal?.dealFees.dealerInventoryTax ?? 0,
    emissions: deal?.dealFees.emissions ?? 0,
    inspectionFee: deal?.dealFees.inspectionFee ?? 0,
    plateTransferFee: deal?.dealFees.plateTransferFee ?? 0,
    registrationFee: deal?.dealFees.registrationFee ?? 0,
    titleFee: deal?.dealFees.titleFee ?? 0,

    others: deal?.dealFees.others ?? [],
    overridenSalesCommission: deal?.dealFees.overridenSalesCommission ?? null,
    overridenSalesTaxAmount: deal?.dealFees.overridenSalesTaxAmount ?? 0,
    overridenSalesTaxPercent: deal?.dealFees.overridenSalesTaxPercent ?? null
  },
  checkoutDetails: {
    deliveryType: deal?.checkoutDetails.deliveryType ?? null,
    pickupDeliveryDate: deal?.checkoutDetails.pickupDeliveryDate ?? null,
    pickupDeliveryTime: deal?.checkoutDetails.pickupDeliveryTime ?? null
  },
  dealFinanceTypeId: deal?.dealFinanceTypeId ?? null,
  firstPaymentDate: deal?.firstPaymentDate ?? null,
  hasLenderDecision: deal?.hasLenderDecision ?? false,
  isTradeInSectionVisible: deal?.isTradeInSectionVisible ?? false,
  salesperson: deal?.salesperson ?? null,
  saleDate: deal?.saleDate ?? null,
  tradeInDetails: {
    acv: deal?.tradeInDetails.acv ?? 0,
    credit: deal?.tradeInDetails.credit ?? 0,
    paymentAmount: deal?.tradeInDetails.paymentAmount ?? 0
  },
  vehicle: {
    listPrice: deal?.vehicle.listPrice ?? 0
  }
})

export const DEPENDENT_VALIDATIONS = {
  'activeLenderDecision.buyRate': [
    'activeLenderDecision.lienholder',
    'activeLenderDecision.approvedRate',
    'activeLenderDecision.approvedTerm'
  ],
  'activeLenderDecision.reserve': [
    'activeLenderDecision.lienholder',
    'activeLenderDecision.approvedRate',
    'activeLenderDecision.approvedTerm'
  ],
  'activeLenderDecision.lienholder': [
    'dealFinanceTypeId',
    'activeLenderDecision.approvedRate',
    'activeLenderDecision.approvedTerm',
    'activeLenderDecision.downPayment'
  ],
  'activeLenderDecision.approvedRate': [
    'dealFinanceTypeId',
    'activeLenderDecision.downPayment'
  ],
  'activeLenderDecision.approvedTerm': [
    'dealFinanceTypeId',
    'activeLenderDecision.downPayment'
  ]
}

export enum Messages {
  SuccessUpdate = 'Changes successfully saved.',
}
