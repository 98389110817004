import { type FC, useCallback, useState, useEffect } from 'react'
import { FormDropdown, Modal } from '@carfluent/common'

import CRMApiProvider from 'api/crm.api'
import type { DictionaryItem } from 'api/types'
import type { BaseListItem } from 'types'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import { MODAL_MIN_WIDTH } from 'constants/constants'
import { Errors } from 'utils/validationPresets'

import CLASS_NAME from './styles'

interface LeadLostModalProps {
  isOpen: boolean
  onSubmit: (isLost: boolean, lostReasonId: number | null) => Promise<void>
  onClose: () => void
}

const TITLE = 'Customer is lost'
const SUBTITLE = 'Please select the reason customer has been lost:'

const LeadLostModal: FC<LeadLostModalProps> = ({
  isOpen,
  onClose: _onClose,
  onSubmit: _onSubmit
}) => {
  const [leadLostReasons, setLeadLostReasons] = useState<DictionaryItem[]>([])
  const [lostReason, setLostReason] = useState<BaseListItem | null>(null)
  const [error, setError] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onClose = useCallback(() => {
    _onClose()
    setLostReason(null)
    setError('')
  }, [_onClose])

  const onSubmit = useCallback(async (): Promise<void> => {
    const lostReasonId = Number(lostReason?.id)
    if (isNaN(lostReasonId)) {
      setError(Errors.Empty)
      return
    }

    setIsSubmitting(true)
    await _onSubmit(true, lostReasonId).finally(
      () => setIsSubmitting(false)
    )
    onClose()
  }, [onClose, _onSubmit, lostReason])

  const onReasonChange = (_fieldId: string, val: BaseListItem | null): void => {
    setLostReason(val)
    setError('')
  }

  useEffect(() => {
    const getItems = async (): Promise<void> => {
      const { items } = await CRMApiProvider.getLeadLostReasons()
      setLeadLostReasons(items)
    }
    void getItems()
  }, [])

  const renderFooter = (): JSX.Element => {
    return (
      <CancelSubmitActionsFooter
        isLoading={isSubmitting}
        onSubmit={onSubmit}
        onClose={onClose}
        submitTitle='SAVE'
      />
    )
  }

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title={TITLE}
      className={CLASS_NAME}
      minWidth={MODAL_MIN_WIDTH}
      renderFooterActions={renderFooter}
    >
      <p>{SUBTITLE}</p>
      <FormDropdown
        disableClearable
        error={error}
        id='lostReasonId'
        label='Reason'
        onChange={onReasonChange}
        options={leadLostReasons}
        touched
        value={lostReason}
      />
    </Modal>
  )
}

export default LeadLostModal
