import { css } from '@emotion/css'

export default css(`
  .cf-modal-content {
    min-width: 680px;
    
    p {
      width: 100%;
    }
  }
  
  .cf-modal-footer {
    .cf-button-root {
      button {
        width: 294px;
      }
    }
  }
`)
