import type { DeepRequired } from '@carfluent/common'

import { onlyNumbersAndDot } from 'utils/format_number'
import type { UpdateLeadFormData, DuplicateLeadsSearchPayload } from './types'

export const HOT_TEMPERATURE_ID = 1

export const getDefaultFormData = (): DeepRequired<UpdateLeadFormData> => ({
  firstName: null,
  lastName: null,
  phoneNumber: null,
  email: null,
  addressData: null,
  assignedUser: null,
  birthDate: null,
  leadSource: null,
  leadStatusId: null,
  leadTemperature: null,
  isLost: false,
  lostReasonId: null,
  tasks: [],
  primaryCarOfInterestId: 0
})

export const FIELD_TRANSFORMS = {
  phoneNumber: onlyNumbersAndDot,
  email: (value: string) => value.trim()
}

export enum FormTitle {
  UpdateLead = 'Edit Lead',
  NewLead = 'Add new lead'
}

export enum FieldIds {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Source = 'leadSource',
  BirthDate = 'birthDate',
  Temperature = 'leadTemperature',
  AddressData = 'addressData',
  AssignedTo = 'assignedUser'
}

const SEARCHABLE_FIELDS: Array<keyof DuplicateLeadsSearchPayload> = [FieldIds.PhoneNumber, FieldIds.LastName, FieldIds.Email]

export const getDefaultSearchState = (): DuplicateLeadsSearchPayload => ({
  phoneNumber: '',
  lastName: '',
  email: ''
})

export const isSearchableField = (key: string): key is keyof DuplicateLeadsSearchPayload => {
  return SEARCHABLE_FIELDS.includes(key as keyof DuplicateLeadsSearchPayload)
}
