import { type FC } from 'react'
import { type MenuIconProps } from 'types'

const SettingsMenuIcon: FC<MenuIconProps> = ({
  isActive = false
}) => {
  const pictogramFill = isActive ? '#fff' : '#101010'

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.1411 12.9344C19.1771 12.6344 19.2011 12.3224 19.2011 11.9984C19.2011 11.6744 19.1771 11.3624 19.1291 11.0624L21.1571 9.47844C21.3371 9.33444 21.3851 9.07044 21.2771 8.86644L19.3571 5.54244C19.2371 5.32644 18.9851 5.25444 18.7691 5.32644L16.3811 6.28644C15.8771 5.90244 15.3491 5.59044 14.7611 5.35044L14.4011 2.80644C14.3651 2.56644 14.1611 2.39844 13.9211 2.39844H10.0811C9.84108 2.39844 9.64908 2.56644 9.61308 2.80644L9.25308 5.35044C8.66508 5.59044 8.12508 5.91444 7.63308 6.28644L5.24508 5.32644C5.02908 5.24244 4.77708 5.32644 4.65708 5.54244L2.73708 8.86644C2.61708 9.08244 2.66508 9.33444 2.85708 9.47844L4.88508 11.0624C4.83708 11.3624 4.80108 11.6864 4.80108 11.9984C4.80108 12.3104 4.82508 12.6344 4.87308 12.9344L2.84508 14.5184C2.66508 14.6624 2.61708 14.9264 2.72508 15.1304L4.64508 18.4544C4.76508 18.6704 5.01708 18.7424 5.23308 18.6704L7.62108 17.7104C8.12508 18.0944 8.65308 18.4064 9.24108 18.6464L9.60108 21.1904C9.64908 21.4304 9.84108 21.5984 10.0811 21.5984H13.9211C14.1611 21.5984 14.3651 21.4304 14.3891 21.1904L14.7491 18.6464C15.3371 18.4064 15.8771 18.0824 16.3691 17.7104L18.7571 18.6704C18.9731 18.7544 19.2251 18.6704 19.3451 18.4544L21.2651 15.1304C21.3851 14.9144 21.3371 14.6624 21.1451 14.5184L19.1411 12.9344ZM12.0011 15.5984C10.0211 15.5984 8.40108 13.9784 8.40108 11.9984C8.40108 10.0184 10.0211 8.39844 12.0011 8.39844C13.9811 8.39844 15.6011 10.0184 15.6011 11.9984C15.6011 13.9784 13.9811 15.5984 12.0011 15.5984Z'
        fill={pictogramFill}
      />
    </svg>

  )
}

export default SettingsMenuIcon
