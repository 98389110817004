import { type FormValidation } from '@carfluent/common'

import { required, warrantyRequiredFieldFISettings } from 'utils/validationPresets'
import { FIPackageFormProps } from 'pages/settings/FISettings/components/FIPackageSetup/hook/types'

type ValidationResult = FormValidation<FIPackageFormProps, never>

export const DEPENDENT_VALIDATIONS = {
  'coverageSections.[*].forms.PEN': [
    'coverageData.coverageDetails.[*].forms.PEN.termMonths',
    'coverageData.coverageDetails.[*].forms.PEN.providerId',
    'coverageData.coverageDetails.[*].forms.PEN.coverageName',
    'coverageData.coverageDetails.[*].forms.PEN.deductibleAmount'
  ]
}

export const validationRules: ValidationResult = {
  firstPackageName: required,
  secondPackageName: required,
  thirdPackageName: required,

  // --- PEN Coverage --- //
  'coverageSections.[*].forms.PEN.providerId': warrantyRequiredFieldFISettings('PEN', 'providerId'),
  'coverageSections.[*].forms.PEN.coverageName': warrantyRequiredFieldFISettings('PEN', 'coverageName'),
  'coverageSections.[*].forms.PEN.termMonths': warrantyRequiredFieldFISettings('PEN', 'termMonths'),
  'coverageSections.[*].forms.PEN.deductibleAmount': warrantyRequiredFieldFISettings('PEN', 'deductibleAmount'),

  // --- Manual Coverage --- //
  'coverageSections.[*].forms.Manual.providerId': warrantyRequiredFieldFISettings('Manual', 'providerId'),
  'coverageSections.[*].forms.Manual.coverageName': warrantyRequiredFieldFISettings('Manual', 'coverageName'),
  'coverageSections.[*].forms.Manual.termMonths': warrantyRequiredFieldFISettings('Manual', 'termMonths'),
  'coverageSections.[*].forms.Manual.deductibleAmount': warrantyRequiredFieldFISettings('Manual', 'deductibleAmount'),
  'coverageSections.[*].forms.Manual.cost': warrantyRequiredFieldFISettings('Manual', 'cost'),
  'coverageSections.[*].forms.Manual.totalPrice': warrantyRequiredFieldFISettings('Manual', 'totalPrice')
}
