import { css } from '@emotion/css'

export const WRAPPER_CLASS_NAME = css(`    
  th:first-child, td:first-child {
    padding-left: 16px;
  }
`)

export const STATUS_CLASS_NAME = css(`
  .cf-dropdown-listbox {
    min-width: 150px;

    .cf-dropdown-option {
      display: flex;
      align-items: center;
      column-gap: 8px;

      .cf-checkbox-icon {
        height: 24px;
      }
    }
  }
`)

export const ROOT_CLASS_NAME = css(`
  position: relative;
  margin-left: 0!important;

  .cf-md {
    width: calc(100% - 48px);
    margin: 0 24px;
    max-width: 100%;
    
    @media screen and (min-width: 1440px) {
      width: 1300px;
      margin: 0 auto;
    }
  }

  .cf-title {
    margin-bottom: 0!important;
  }

  .cf-report-top-actions {
    margin-top: 24px;
    margin-bottom: 24px!important;
  }
`)

export default css(`
  .cf-inventory-cost-report-table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    margin-top: 24px;
  }
  
  td, th {
    font-style: normal;
    letter-spacing: 0.15px;
    color: #101010;
    padding: 16px 4px;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
  }

  th,
  td:first-of-type .tooltip-content {
    font-weight: 500;
  }
  
  .cf-action-bar {
    display: none;
    opacity: 0;
  }

  thead {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    tr:hover { background: #ffffff; }
  }

  tbody {
    border: 1px solid #fff;
  }
  
  tr {
    min-height: 64px;
  }

  tr:hover {
    background: rgba(250, 250, 250, 1);
  }

  tr {
    &.no-data-block {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }
  }
  
  .cf-car-cell {
    width: 280px;
    min-width: 100px;

    .vehicle-link {
      color: #458FFF;
      text-decoration: none;

      .cf-tooltip-container >p {
        color: inherit;
      }
  
      &:hover {
        text-decoration: none;
      }
    }
    
    @media screen and (max-width: 1300px) {
      max-width: 200px;
    }
  }
  
  .cf-mileage-cell {
    width: 92px;
  }
  
  .cf-stock-cell {
    width: 92px;
  }
  
  .cf-status-cell {
    width: 92px;
  }
  
  .cf-age-cell {
    width: 92px;
  }
  
  .cf-vin-cell {
    min-width: 220px;
    max-width: 220px;
    width: 220px;
  }
  
  .cf-sale-price-cell {
    width: 150px;
    text-align: right;
  }

  .cf-cost-cell {
    width: 150px;
    max-width: 150px;
    text-align: right;
  }

  .cf-loading-row {
    width: 100%;
    height: 16px;
    background: rgba(33, 33, 33, 0.08);
    border-radius: 2px;
  }
`)
