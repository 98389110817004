import { useState } from 'react'
import {
  Button,
  cnx,
  FormDropdown,
  type Dropdown2Props,
  type FormCompatible
} from '@carfluent/common'
import IconSVG from 'components/inlineImages'
import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

const AUTOFOCUS_DELAY = 50
const BTN_CLS = 'search-select-button'

function SearchSelect<TValue> (
  props: FormCompatible<Dropdown2Props<TValue, false>, TValue>
): JSX.Element {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const hasValue = props.value != null

  return (
    <div className={CLASS_NAME}>
      <Button
        variant='text'
        // DD-NOTE: using clsName for this is a hot-fix. Button must support id or data-attributes
        className={BTN_CLS}
        onClick={(e) => {
          const clsList = e?.currentTarget?.classList

          if (clsList == null) {
            return
          }

          if (clsList.contains(BTN_CLS)) {
            setIsDropdownOpen(!isDropdownOpen)

            if (!isDropdownOpen) {
              setTimeout(() => {
                const el = document.getElementById(props.id)

                if (el != null) {
                  el.click()
                }
              }, AUTOFOCUS_DELAY)
            }
          }
        }}
        endAdornment={
          hasValue
            ? (
              <div
                onClick={(evt) => {
                  evt?.stopPropagation()
                  props.onChange?.(props.id, null, 'clear')
                }}
              >
                <IconSVG.Cross width={16} height={16} />
              </div>
              )
            : <IconSVG.DropdownArrowDown width={16} height={16} />
        }
      >
        <div className='cf-filter-label-text-container'>
          {hasValue ? props.formatDisplayValue?.(props.value) : (props.name ?? '')}
        </div>
      </Button>

      {
          isDropdownOpen && (
            <div className='cf-popover-list'>
              <FormDropdown
                {...props}
                mode='search'
                onChange={(id, value) => {
                  setIsDropdownOpen(false)
                  props.onChange?.(id, value, 'select-option')
                }}
                popoverClassName={cnx(props.popoverClassName, POPOVER_CLASS_NAME)}
                onBlur={() => {
                  setIsDropdownOpen(false)
                }}
              />
            </div>
          )
        }
    </div>
  )
}

export default SearchSelect
