import type { DeepRequired } from '@carfluent/common'
import type { UpdateDealerAccountFormData } from './types'

export enum FieldIds {
  DealerFirstName = 'dealerFirstName',
  DealerLastName = 'dealerLastName',
  Role = 'roleId',
  DealerIds = 'dealerIds',
  PhoneNumber = 'phoneNumber'
}

export const SuccessDealerAccountUpdate = 'User has been edited'

export const getDefaultFormData = (): DeepRequired<UpdateDealerAccountFormData> => ({
  dealerFirstName: null,
  dealerLastName: null,
  roleId: null,
  dealerIds: [],
  phoneNumber: null
})
