import { useCallback, type FC, type FocusEvent } from 'react'
import { cnx } from '@carfluent/common'

import { type CoverageFeeSettingsModel } from 'api/types/responses'
import FeeFieldSettings from 'components/settings/FeeFieldSettings'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

import { type FeeFieldsProps } from './types'
import CLASS_NAME from './styles'

const FeeFields: FC<FeeFieldsProps> = ({
  className,
  values,
  errors,
  touched,
  isLoading,
  onBlur,
  onChange
}) => {
  const getOnChange = (prefix: string): FeeFieldsProps['onChange'] => {
    const _onChange = (id: string, value: any): void => {
      onChange(`${prefix}.${id}`, value)
    }
    return _onChange as any as FeeFieldsProps['onChange']
  }

  const getOnBlur = (prefix: string): FeeFieldsProps['onBlur'] => {
    const _onBlur = (e: FocusEvent<HTMLElement> | string): void => {
      if (typeof e === 'string') {
        return onBlur(`${prefix}.${e}`)
      } else {
        return onBlur(`${prefix}.${e.target.id}`)
      }
    }
    return _onBlur as any as FeeFieldsProps['onBlur']
  }

  const resetFields = useCallback((prefix: keyof Omit<CoverageFeeSettingsModel, 'serviceContractTaxable' | 'gapTaxable' | 'appearanceProtectionTaxable' | 'tireAndWheelTaxable' | 'theftTaxable'>): void => {
    if (values?.coverageFeeSettings?.[prefix]?.defaultValueCash === null) {
      onChange(`coverageFeeSettings.${prefix}.defaultValueCash`, '0')
    }

    // @ts-expect-error
    if ((values?.coverageFeeSettings?.[prefix] as DefaultAmountFeeSettingDto)?.defaultValueFinancing === null) {
      onChange(`coverageFeeSettings.${prefix}.defaultValueFinancing`, '0')
    }
  }, [values])

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <div className='sub-title-container'>
        <BlockSkeleton isLoading={isLoading} className='title-skeleton with-space'>
          <h6 className='section-title h6-title'>
            Fees and costs
          </h6>
        </BlockSkeleton>

        <BlockSkeleton isLoading={isLoading} className='sub-title-skeleton'>
          <div className='sub-title'>The changes will apply to deals created after the new settings have been applied.</div>
        </BlockSkeleton>
      </div>

      <FeeFieldSettings
        values={{
          ...values.coverageFeeSettings.buyerTagSetting,
          labelName: values.coverageFeeSettings.buyerTagSetting.labelName ?? ''
        }}
        errors={{
          ...errors.coverageFeeSettings.buyerTagSetting,
          account: errors.coverageFeeSettings.buyerTagSetting?.account?.toString() ?? null
        }}
        touched={{
          ...touched.coverageFeeSettings.buyerTagSetting,
          account: touched.coverageFeeSettings.buyerTagSetting.account !== null ?? false
        }}
        onBlur={getOnBlur('coverageFeeSettings.buyerTagSetting')}
        onChange={getOnChange('coverageFeeSettings.buyerTagSetting')}
        resetFields={() => resetFields('buyerTagSetting')}
        feeName='Buyer tag'
        isPercent={false}
        isLoading={isLoading}
      />

      <FeeFieldSettings
        values={{
          ...values.coverageFeeSettings.carDeliverySetting,
          defaultValueFinancing: undefined,
          labelName: values.coverageFeeSettings.carDeliverySetting.labelName ?? ''
        }}
        errors={{
          ...errors.coverageFeeSettings.carDeliverySetting,
          defaultValueFinancing: null,
          account: errors.coverageFeeSettings.carDeliverySetting?.account?.toString() ?? null
        }}
        touched={{
          ...touched.coverageFeeSettings.carDeliverySetting,
          account: touched.coverageFeeSettings.carDeliverySetting.account !== null ?? false,
          defaultValueFinancing: false
        }}
        onBlur={getOnBlur('coverageFeeSettings.carDeliverySetting')}
        onChange={getOnChange('coverageFeeSettings.carDeliverySetting')}
        resetFields={() => resetFields('carDeliverySetting')}
        feeName='Car delivery'
        isPercent={false}
        isLoading={isLoading}
      />

      <FeeFieldSettings
        values={{
          ...values.coverageFeeSettings.dealerHandlingFeeSetting,
          labelName: values.coverageFeeSettings.dealerHandlingFeeSetting.labelName ?? '',
          defaultValueFinancing: undefined
        }}
        errors={{
          ...errors.coverageFeeSettings.dealerHandlingFeeSetting,
          account: errors.coverageFeeSettings.dealerHandlingFeeSetting?.account?.toString() ?? null,
          defaultValueFinancing: null
        }}
        touched={{
          ...touched.coverageFeeSettings.dealerHandlingFeeSetting,
          account: touched.coverageFeeSettings.dealerHandlingFeeSetting.account !== null ?? false,
          defaultValueFinancing: false
        }}
        onBlur={getOnBlur('coverageFeeSettings.dealerHandlingFeeSetting')}
        onChange={getOnChange('coverageFeeSettings.dealerHandlingFeeSetting')}
        resetFields={() => resetFields('dealerHandlingFeeSetting')}
        feeName='Dealer handling fee'
        isPercent={false}
        isLoading={isLoading}
      />

      <FeeFieldSettings
        values={{
          ...values.coverageFeeSettings.dealerInventoryTaxSetting,
          labelName: values.coverageFeeSettings.dealerInventoryTaxSetting.labelName ?? ''
        }}
        errors={{
          ...errors.coverageFeeSettings.dealerInventoryTaxSetting,
          account: errors.coverageFeeSettings.dealerInventoryTaxSetting?.account?.toString() ?? null
        }}
        touched={{
          ...touched.coverageFeeSettings.dealerInventoryTaxSetting,
          account: touched.coverageFeeSettings.dealerInventoryTaxSetting.account !== null ?? false
        }}
        onBlur={getOnBlur('coverageFeeSettings.dealerInventoryTaxSetting')}
        onChange={getOnChange('coverageFeeSettings.dealerInventoryTaxSetting')}
        resetFields={() => resetFields('dealerInventoryTaxSetting')}
        feeName='Dealer’s inventory tax'
        isPercent
        isLoading={isLoading}
      />

      <FeeFieldSettings
        values={{
          ...values.coverageFeeSettings.emissionsSetting,
          labelName: values.coverageFeeSettings.emissionsSetting.labelName ?? '',
          defaultValueFinancing: undefined
        }}
        errors={{
          ...errors.coverageFeeSettings.emissionsSetting,
          account: errors.coverageFeeSettings.emissionsSetting?.account?.toString() ?? null,
          defaultValueFinancing: null
        }}
        touched={{
          ...touched.coverageFeeSettings.emissionsSetting,
          account: touched.coverageFeeSettings.emissionsSetting.account !== null ?? false,
          defaultValueFinancing: false
        }}
        onBlur={getOnBlur('coverageFeeSettings.emissionsSetting')}
        onChange={getOnChange('coverageFeeSettings.emissionsSetting')}
        resetFields={() => resetFields('emissionsSetting')}
        feeName='Emissions'
        isPercent={false}
        isLoading={isLoading}
      />

      <FeeFieldSettings
        values={{
          ...values.coverageFeeSettings.inspectionFeeSetting,
          labelName: values.coverageFeeSettings.inspectionFeeSetting.labelName ?? ''
        }}
        errors={{
          ...errors.coverageFeeSettings.inspectionFeeSetting,
          account: errors.coverageFeeSettings.inspectionFeeSetting?.account?.toString() ?? null
        }}
        touched={{
          ...touched.coverageFeeSettings.inspectionFeeSetting,
          account: touched.coverageFeeSettings.inspectionFeeSetting.account !== null ?? false
        }}
        onBlur={getOnBlur('coverageFeeSettings.inspectionFeeSetting')}
        onChange={getOnChange('coverageFeeSettings.inspectionFeeSetting')}
        resetFields={() => resetFields('inspectionFeeSetting')}
        feeName='Inspection fee'
        isPercent={false}
        isLoading={isLoading}
      />

      <FeeFieldSettings
        values={{
          ...values.coverageFeeSettings.plateTransferFeeSetting,
          labelName: values.coverageFeeSettings.plateTransferFeeSetting.labelName ?? ''
        }}
        errors={{
          ...errors.coverageFeeSettings.plateTransferFeeSetting,
          account: errors.coverageFeeSettings.plateTransferFeeSetting?.account?.toString() ?? null
        }}
        touched={{
          ...touched.coverageFeeSettings.plateTransferFeeSetting,
          account: touched.coverageFeeSettings.plateTransferFeeSetting.account !== null ?? false
        }}
        onBlur={getOnBlur('coverageFeeSettings.plateTransferFeeSetting')}
        onChange={getOnChange('coverageFeeSettings.plateTransferFeeSetting')}
        resetFields={() => resetFields('plateTransferFeeSetting')}
        feeName='Plate transfer fee'
        isPercent={false}
        isLoading={isLoading}
      />

      <FeeFieldSettings
        values={{
          ...values.coverageFeeSettings.registrationFeeSetting,
          labelName: values.coverageFeeSettings.registrationFeeSetting.labelName ?? ''
        }}
        errors={{
          ...errors.coverageFeeSettings.registrationFeeSetting,
          account: errors.coverageFeeSettings.registrationFeeSetting?.account?.toString() ?? null
        }}
        touched={{
          ...touched.coverageFeeSettings.registrationFeeSetting,
          account: touched.coverageFeeSettings.registrationFeeSetting.account !== null ?? false
        }}
        onBlur={getOnBlur('coverageFeeSettings.registrationFeeSetting')}
        onChange={getOnChange('coverageFeeSettings.registrationFeeSetting')}
        resetFields={() => resetFields('registrationFeeSetting')}
        feeName='Registration fee'
        isPercent={false}
        isLoading={isLoading}
      />

      <FeeFieldSettings
        values={{
          ...values.coverageFeeSettings.titleFeeSetting,
          labelName: values.coverageFeeSettings.titleFeeSetting.labelName ?? ''
        }}
        errors={{
          ...errors.coverageFeeSettings.titleFeeSetting,
          account: errors.coverageFeeSettings.titleFeeSetting?.account?.toString() ?? null
        }}
        touched={{
          ...touched.coverageFeeSettings.titleFeeSetting,
          account: touched.coverageFeeSettings.titleFeeSetting.account !== null ?? false
        }}
        onBlur={getOnBlur('coverageFeeSettings.titleFeeSetting')}
        onChange={getOnChange('coverageFeeSettings.titleFeeSetting')}
        resetFields={() => resetFields('titleFeeSetting')}
        feeName='Title fee'
        isPercent={false}
        isLoading={isLoading}
      />
    </div>
  )
}

export default FeeFields
