import { type FC, type ReactNode, useRef, useState } from 'react'
import { cnx } from '@carfluent/common'

import smallArrow from 'assets/small_arrow.svg'

import CLASS_NAME from './styles'

interface CollapseProps {
  title: string
  endAdornment?: ReactNode
  children: ReactNode
}

const DEFAULT_HEIGHT = 40

const Collapse: FC<CollapseProps> = ({ title, endAdornment, children }) => {
  const anchorRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number>(DEFAULT_HEIGHT)

  const isOpen = height !== DEFAULT_HEIGHT

  const onOpen = (): void => {
    if (!isOpen && anchorRef.current != null) {
      const { height } = anchorRef.current.getBoundingClientRect()
      setHeight(DEFAULT_HEIGHT + height)

      return
    }

    setHeight(DEFAULT_HEIGHT)
  }

  return (
    <div className={CLASS_NAME} style={{ height: height }}>
      <div className={cnx('collapsible-button', isOpen && 'is-active')} onClick={onOpen}>
        <img src={smallArrow} />
        {title}
        {endAdornment != null && <span className='collapsible-button-end-adornment'>{endAdornment}</span>}
      </div>

      <div ref={anchorRef}>
        {children}
      </div>
    </div>
  )
}

export default Collapse
