import { type FC } from 'react'
import { cnx, formatPercentagesDecimal } from '@carfluent/common'

import { Routes } from 'constants/route_helper'
import Header from '../Header'
import NumberIndicator from '../NumberIndicator'
import CrmChart from './CrmChart'

import CLASS_NAME from './styles'
import useDashboardCrm from './hook/index'

interface CrmDashboardProps {
  className?: string
}

export const EMPTY_FORMAT_VALUE = { emptyValues: '0' }

const CrmDashboard: FC<CrmDashboardProps> = ({
  className
}) => {
  const { data, dateRange, dateRangeOptions, isLoading, onChangeDateRange } = useDashboardCrm()

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <Header
        dateRange={dateRange}
        dateRangeOptions={dateRangeOptions}
        isLoading={isLoading}
        title='CRM'
        routes={Routes.CRMLeads}
        onChangeDateRange={onChangeDateRange}
      />

      <div className='info-block'>
        <NumberIndicator
          isLoading={isLoading}
          label='New leads'
          value={data?.newLeadsCount ?? 0}
          emptyValue={EMPTY_FORMAT_VALUE}
        />

        <NumberIndicator
          label='Engaged leads'
          isLoading={isLoading}
          value={data?.engagedLeadsCount ?? 0}
          percent={formatPercentagesDecimal((data?.engagedLeadsPercent ?? 0) * 100)}
          emptyValue={EMPTY_FORMAT_VALUE}
        />

        <NumberIndicator
          isLoading={isLoading}
          label='Completed tasks'
          value={data?.completedTasksCount ?? 0}
          emptyValue={EMPTY_FORMAT_VALUE}
        />

        <NumberIndicator
          isLoading={isLoading}
          label='Appointments'
          value={data?.appointmentsCount ?? 0}
          emptyValue={EMPTY_FORMAT_VALUE}
        />

        <NumberIndicator
          isLoading={isLoading}
          label='Sold (conversion rate, %)'
          value={data?.soldCount ?? 0}
          percent={formatPercentagesDecimal((data?.conversionRate ?? 0) * 100)}
          emptyValue={EMPTY_FORMAT_VALUE}
        />
      </div>

      <CrmChart isLoading={isLoading} data={data?.leadsByDate ?? []} />
    </div>

  )
}

export default CrmDashboard
