import { type DictionaryItem, WorkPlanEventDto, TaskAssigneeType } from 'api/types'
import { TaskType } from 'pages/crm/LeadDetailsView/hook/types'

import { FormValues } from './validator'
import { WorkPlanEventType } from './types'

export const serializeData = (
  {
    assignedUser: _assignedUser,
    taskPriority,
    taskType,
    reminderSubject: _reminderSubject,
    description,
    ...otherProps
  }: FormValues,
  leadStatusId: number | null,
  taskAssigneeTypes: DictionaryItem[]
): Omit<WorkPlanEventDto, 'isActive' | 'id' | 'statusChangedDate' | 'assignedUser' | 'workplanEventTypeId' | 'templateName'> => {
  const taskAssigneeTypeId = getTaskAssigneeType(_assignedUser, taskAssigneeTypes)
  const assignedUserId = (_assignedUser?.id ?? 0) < 0 ? null : _assignedUser?.id ?? 0
  const reminderSubject = taskType?.id !== TaskType.Reminder
    ? null
    : _reminderSubject?.name ?? null

  const isTaskEventType = otherProps.workplanEventTypeId === WorkPlanEventType.Task
  const isEmailEventType = otherProps.workplanEventTypeId === WorkPlanEventType.Email
  const isMessageEventType = otherProps.workplanEventTypeId === WorkPlanEventType.Message

  return {
    ...otherProps,
    assignedUserId: isTaskEventType ? assignedUserId : null,
    taskAssigneeTypeId: isTaskEventType ? taskAssigneeTypeId : null,
    reminderSubject: isTaskEventType ? reminderSubject : null,
    taskTypeId: isTaskEventType ? taskType?.id ?? null : null,
    taskPriorityId: isTaskEventType ? (taskPriority?.id ?? null) : null,
    leadStatusId,
    description: description === '' ? null : description,
    runAtTime: !isTaskEventType ? otherProps.runAtTime : null,
    emailTemplateId: isEmailEventType ? otherProps.emailTemplateId?.id ?? null : null,
    messageTemplateId: isMessageEventType ? otherProps.messageTemplateId?.id ?? null : null
  }
}
const getTaskAssigneeType = (assignedUser: DictionaryItem | null, taskAssigneeTypes: DictionaryItem[]): TaskAssigneeType => {
  if (assignedUser == null) return TaskAssigneeType.User

  const assigneeTypeMap = new Map<number, TaskAssigneeType>(
    taskAssigneeTypes.map(type => ([-type.id, type.id as TaskAssigneeType]))
  )

  return assigneeTypeMap.get(assignedUser.id) ?? TaskAssigneeType.User
}
