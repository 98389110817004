import { useModal } from '@carfluent/common'

import { type OverwritableInputProps } from './types'
import PopoverTrigger from './components/PopperTrigger'

function OverwritableInput<T, V> (props: OverwritableInputProps<T, V>): JSX.Element {
  const {
    editButtonEl,
    disabled,
    formatFieldHelper,
    getValue,
    id,
    isOverriden,
    label,
    renderForm: Form,
    values
  } = props

  const { isModalOpen, onCloseModal, onOpenModal } = useModal()

  return (
    <PopoverTrigger<V>
      editButtonEl={editButtonEl}
      disabled={disabled}
      formatFieldHelper={formatFieldHelper}
      getValue={getValue}
      id={id}
      isOpen={isModalOpen}
      isOverriden={isOverriden}
      label={label}
      onClose={onCloseModal}
      onOpen={onOpenModal}
      values={values}
    >
      <Form
        {...props}
        isOpen={isModalOpen}
        onClose={onCloseModal}
      />
    </PopoverTrigger>
  )
}

export default OverwritableInput
