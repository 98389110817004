import { type FC, useState, useEffect } from 'react'
import { Button, Loader, cnx } from '@carfluent/common'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import {
  type DragStartEvent,
  type DropAnimation,
  type DragEndEvent,
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
  closestCenter
} from '@dnd-kit/core'

import { type DocumentFormDto, type DocumentFormPackDto } from 'api/types'
import DocumentsApiProvider from 'api/documents.api'
import Sortable from 'components/common/Sortable'
import Checkbox from 'components/common/CheckboxNoMui'
import ManageFormDropDown from 'components/deals/ManageFormDropDown'

import FormRow, { type FormRowItem } from './components/FormRow'
import CLASS_NAME from './styles'

const POINT_SENSOR_PROPS = {
  activationConstraint: { distance: 0.5 }
}

const DROP_ANIMATION: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: { opacity: '0.5' }
    }
  })
}

export const DROPPABLE_ID = 'forms-list'

export interface ManageFormBannerProps {
  amount: number
  onView: () => Promise<void>
  onNotarize: () => Promise<void>
  onSend: () => void
  isNotarizing: boolean
  isViewing: boolean
}

export interface FormsTableProps {
  items: FormRowItem[]
  onChange: (items: FormRowItem[]) => Promise<void> | void
  onDelete: (item: FormRowItem) => Promise<void> | void
  onAdd: (item: DocumentFormDto | DocumentFormPackDto) => Promise<void> | void
  checkedItems?: string[]
  isCheckAvailable?: boolean
  isDeleteAllowed?: boolean
  onChangeCheckedItems?: (items: string[]) => void
  manageFormBannerProps?: ManageFormBannerProps
  loadingItemDelete?: string[]
  loadingViewIds?: string[]
  onView?: (id: string) => Promise<void>
  isLoading?: boolean
}

const FormsTable: FC<FormsTableProps> = ({
  items,
  onAdd,
  onChange,
  onDelete,
  checkedItems = [],
  isCheckAvailable = false,
  onChangeCheckedItems,
  manageFormBannerProps,
  isDeleteAllowed = true,
  loadingItemDelete = [],
  loadingViewIds = [],
  onView,
  isLoading = false
}) => {
  const [draggedItem, setDragged] = useState<FormRowItem | null>(null)

  const onDragStart = ({ active }: DragStartEvent): void => {
    const nextDragged = items.find(item => item.uniqId === active.id)
    setDragged(nextDragged ?? null)
  }

  const { setNodeRef } = useDroppable({ id: DROPPABLE_ID })
  const pointerSensor = useSensor(PointerSensor, POINT_SENSOR_PROPS)
  const sensors = useSensors(pointerSensor)

  const onDragEnd = ({ active, over }: DragEndEvent): void => {
    if (over != null && active.id !== over?.id) {
      const activeIndex = items.findIndex((item) => item.uniqId === active.id)
      const overIndex = items.findIndex((item) => item.uniqId === over.id)

      void onChange(arrayMove(items, activeIndex, overIndex))
    }

    setDragged(null)
  }

  const isAllChecked = checkedItems?.length === items.length && items.length > 0

  const onCheckAll = (): void => {
    if (!isCheckAvailable) {
      return
    }

    onChangeCheckedItems?.(isAllChecked ? [] : items.map(({ uniqId }) => uniqId))
  }

  const onCheck = (_id: string): void => {
    const nextCheckedItems = [...checkedItems]

    if (!checkedItems.includes(_id)) {
      nextCheckedItems.push(_id)
      onChangeCheckedItems?.(nextCheckedItems)
      return
    }

    const idx = checkedItems.findIndex(id => _id === id)

    if (idx > -1) {
      nextCheckedItems.splice(idx, 1)
      onChangeCheckedItems?.(nextCheckedItems)
    }
  }

  useEffect(() => {
    document.body.style.cursor = draggedItem == null ? '' : 'grabbing'
  }, [draggedItem])

  const isManageBannerButtonsDisabled = checkedItems.length === 0

  return (
    <div className={CLASS_NAME}>
      <div className='manage-forms-wrapper'>
        <ManageFormDropDown
          title='ADD FORM PACK'
          fetchHandler={DocumentsApiProvider.getFormPacks}
          onSelect={onAdd}
        />

        <ManageFormDropDown
          title='ADD FORM'
          fetchHandler={DocumentsApiProvider.getForms}
          onSelect={onAdd}
        />
      </div>

      {manageFormBannerProps != null && (
        <div className='manage-form-banner'>
          <p>Forms selected: {manageFormBannerProps.amount}</p>

          <div>
            <Button
              onClick={manageFormBannerProps.onView}
              variant='text'
              isLoading={manageFormBannerProps.isViewing}
              isDisabled={isManageBannerButtonsDisabled}
            >
              View
            </Button>

            <Button
              onClick={manageFormBannerProps.onNotarize}
              variant='text'
              isLoading={manageFormBannerProps.isNotarizing}
              isDisabled={isManageBannerButtonsDisabled}
            >
              Notarize
            </Button>

            <Button
              isDisabled={isManageBannerButtonsDisabled}
              onClick={manageFormBannerProps.onSend}
              variant='text'
            >
              Send for signing
            </Button>
          </div>
        </div>
      )}

      <div className='deal-form-table-wrapper'>
        <div className='form-table-header'>
          {isCheckAvailable && <Checkbox value={isAllChecked} onChange={onCheckAll} />}

          Forms
        </div>

        {
          isLoading
            ? <div className='loader-wrapper'><Loader /></div>
            : items.length > 0
              ? (
                <DndContext
                  onDragStart={onDragStart}
                  collisionDetection={closestCenter}
                  sensors={sensors}
                  onDragEnd={onDragEnd}
                >
                  <SortableContext items={items.map((item) => ({ id: item.uniqId }))}>
                    <div ref={setNodeRef} className={cnx('forms-table-body', draggedItem == null && 'grab-row')}>
                      {items.map((item) => (
                        <Sortable id={item.uniqId} key={item.uniqId}>
                          <FormRow
                            {...item}
                            onCheck={() => onCheck(item.uniqId)}
                            onDelete={
                              isDeleteAllowed
                                ? () => onDelete(item)
                                : undefined
                            }
                            isChecked={
                              isCheckAvailable
                                ? checkedItems.find((id) => item.uniqId === id) != null
                                : undefined
                            }
                            isDeleting={loadingItemDelete.includes(item.uniqId)}
                            isViewing={loadingViewIds.includes(item.uniqId)}
                            onView={onView}
                          />
                        </Sortable>
                      ))}
                    </div>
                  </SortableContext>

                  <DragOverlay adjustScale dropAnimation={DROP_ANIMATION}>
                    {(draggedItem != null) && (
                      <FormRow
                        isChecked={
                          isCheckAvailable
                            ? checkedItems.find((id) => draggedItem?.uniqId === id) != null
                            : undefined
                        }
                        {...draggedItem}
                      />
                    )}
                  </DragOverlay>
                </DndContext>
                )
              : <div className='empty-list'>No forms selected</div>
        }
      </div>
    </div>
  )
}

export default FormsTable
