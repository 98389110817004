import { css } from '@emotion/css'

export default css(`
  .MuiFormHelperText-root {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4;
  }

  .selected-account:hover {
    .MuiAutocomplete-clearIndicator {
      visibility: initial
    }
  }
`)
