import { useContext, useCallback, useState, useEffect } from 'react'
import { useForm, isOk, touchify } from '@carfluent/common'

import { type GeneralSettingsFormData } from 'types'
import { type DealerFeeMarkupSettingsDto } from 'api/types/responses'
import CustomersCoreApiProvider from 'api/customersCore.api'
import { DEFAULT_DEALER_SETTINGS } from 'api/defaults/dealer'
import { getDealerSettings as getDealerSettingsApi } from 'api/combine.api'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import useTransitionBlocker from 'hooks/useTransitionBlocker'
import SettingsCTX from 'store/settings'

import { type UseGeneralSettingsReturn } from './types'
import { SUCCESS_MESSAGE, DISCARDED_MESSAGE } from './constants'
import validationRules, { DEPENDENT_VALIDATIONS } from './validator'
import serializeSettings from './serializer'

const useGeneralSettings = (): UseGeneralSettingsReturn => {
  const {
    dealer: { dealerFeeMarkupSettings },
    setDealerSettings
  } = useContext(SettingsCTX)

  const { showAlert } = useCustomSnackbar()

  const [isOpenUnsavedChanges, setIsOpenUnsavedChanges] =
    useState(false)

  const [isLoading, setIsLoading] =
    useState(false)

  const [settings, setSettings] =
    useState<GeneralSettingsFormData>(DEFAULT_DEALER_SETTINGS)

  const getDealerSettings = useCallback(async () => {
    setIsLoading(true)
    try {
      const settings = await getDealerSettingsApi()
      const { id, ...rest } = settings
      setDealerSettings(settings)
      setSettings(rest)
      return settings
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }, [setDealerSettings])

  const onActionResult = useCallback((res, resetForm) => {
    if (isOk(res)) {
      void getDealerSettings().then((res) => resetForm(res))
      showAlert(SUCCESS_MESSAGE, { variant: 'success' })
    }
  }, [showAlert])

  const submitAction = useCallback(async (values: GeneralSettingsFormData): Promise<void> => {
    try {
      const serializedSettings = serializeSettings(values)

      const payload: DealerFeeMarkupSettingsDto = {
        id: dealerFeeMarkupSettings.id,
        websiteBaseUrl: dealerFeeMarkupSettings.websiteBaseUrl,
        ...serializedSettings
      }

      await CustomersCoreApiProvider.patchSettings(payload)
    } catch (err) {
      console.error(err)
    }
  }, [dealerFeeMarkupSettings])

  const formProps = useForm<GeneralSettingsFormData>({
    baseValues: settings,
    validationRules,
    isTrackingChanges: true,
    dependentValidations: DEPENDENT_VALIDATIONS,
    onActionResult,
    submitAction
  })

  const { isFormChanged, isValid, resetForm, onSubmit, setTouched } = formProps

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onFormReset = (): void => {
    resetForm(settings)
    showAlert(DISCARDED_MESSAGE, { variant: 'success' })
  }

  const { proceedTransition } = useTransitionBlocker({
    shouldBlock: isFormChanged(),
    onBlock: () => setIsOpenUnsavedChanges(true)
  })

  const onCloseUnsavedDialog = (): void => {
    setTouched(touchify(settings) as any)
    setIsOpenUnsavedChanges(false)
  }

  const onDontSaveAndClose = (): void => {
    onCloseUnsavedDialog()
    proceedTransition()
  }

  const onSaveAndClose = async (): Promise<void> => {
    if (!isValid) {
      onCloseUnsavedDialog()
      return
    }

    await onSubmit()
    onDontSaveAndClose()
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    void getDealerSettings()
  }, [getDealerSettings])

  // ========================================== //

  return {
    ...formProps,
    isLoading,
    isOpenUnsavedChanges,
    onCloseUnsavedDialog,
    onDontSaveAndClose,
    onFormReset,
    onSaveAndClose,
    settings
  }
}

export default useGeneralSettings
