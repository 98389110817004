import { css } from '@emotion/css'

export default css`
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px;
  margin-bottom: 32px;
  
  .grid-row {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 176px 176px 176px;
    align-items: center;
    
    .header-title {
      font-weight: 500;
      line-height: 24px;
    }

    &.package-item {
      min-height: 56px;
      
      .name-wrapper {
        overflow: hidden;

        .coverage-name {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        
        .coverage-info {
          .tooltip-content {
            font-size: 14px;
            line-height: 20px;
            color: rgba(33, 33, 33, 0.50);
          }
        }
      }
      
      .checkbox-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .package-label {
      border-radius: 12px;
      font-weight: 500;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.platinum {
        background: #FFEFBE;
      }

      &.gold {
        background: #FFE4CB;
      }

      &.basic {
        background: #ECF4FF;
      }
    }
  }
  
  .empty-block {
    margin-top: 16px;
    color: rgba(33, 33, 33, 0.50);
    line-height: 24px;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
