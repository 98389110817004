import { formatIntegerDecimal } from 'utils/format_number'
import { REPORTS_LISTS } from './constants'
import type { FilteredReportLinksProps } from './types'

/**
 * AS-TODO remove this formatting in favor of new formatter
 */
export const getBalanceValue = (
  value: number | null,
  emptyValue: string | undefined = '$0.00'
): string => {
  if (value != null && !isNaN(value) && value !== 0) {
    return value < 0
      ? `($${formatIntegerDecimal(Math.abs(value * -1 ?? 0).toFixed(2))})`
      : `$${formatIntegerDecimal(Math.abs(value ?? 0).toFixed(2))}`
  } else {
    return emptyValue
  }
}

export const getFilteredReports = (search: string): FilteredReportLinksProps[] => {
  return REPORTS_LISTS.map(({ title, icon, links }) => ({
    title,
    icon,
    filteredLinks: links.filter(el =>
      el.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
      el.description.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }))
}
