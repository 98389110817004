import type { FormValidation } from '@carfluent/common'
import type { FormPacksFormData } from './types'
import { nameField } from 'utils/validationPresets'

const validationRules: FormValidation<FormPacksFormData> = {
  name: nameField(true),
  description: nameField()
}

export default validationRules
