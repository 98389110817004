export const DEFAULT_FILE_WITH_VIDEO_EXTENSIONS = ['png', 'jpeg', 'jpg', 'gif', 'mp4', 'mov']
export const DEFAULT_FILE_WITHOUT_VIDEO_EXTENSIONS = ['png', 'jpeg', 'jpg', 'gif']
export const KiB = 1024
export const DEFAULT_FILE_MAX_SIZE = 500 * KiB * KiB // 500 MB
export const DEFAULT_MAX_FILES_NUM = 10
export const DEFAULT_MAX_TOTAL_FILE_SIZE = 20 * KiB * KiB
export const UNLIMITED_FILES_SIZE = Number.MAX_SAFE_INTEGER

export const FILE_SIZE_ERROR = (sizeInBytes: number = DEFAULT_FILE_MAX_SIZE): string => (
  `File size can't exceed ${sizeInBytes / KiB / KiB}MB.`
)

export const FILE_NUMBER_ERROR = 'Limited to 10 photos per message.'

export const TOTAL_FILE_SIZE_ERROR = (sizeInBytes: number = DEFAULT_MAX_TOTAL_FILE_SIZE): string => (
  `Total files size ${sizeInBytes / KiB / KiB}MB exceeded.`
)
