import { type FC } from 'react'
import { type MenuIconProps } from 'types'

const CrmLeadsMenuIcon: FC<MenuIconProps> = ({
  isActive = false
}) => {
  const pictogramFill = isActive ? '#fff' : '#101010'

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 6C11.1491 6 10.333 6.31607 9.73137 6.87868C9.12969 7.44129 8.79167 8.20435 8.79167 9C8.79167 9.79565 9.12969 10.5587 9.73137 11.1213C10.333 11.6839 11.1491 12 12 12C12.8509 12 13.667 11.6839 14.2686 11.1213C14.8703 10.5587 15.2083 9.79565 15.2083 9C15.2083 8.20435 14.8703 7.44129 14.2686 6.87868C13.667 6.31607 12.8509 6 12 6ZM6.04167 8.57143C5.43388 8.57143 4.85098 8.79719 4.42121 9.19906C3.99144 9.60092 3.75 10.146 3.75 10.7143C3.75 11.52 4.23583 12.2143 4.9325 12.5829C5.2625 12.7543 5.63833 12.8571 6.04167 12.8571C6.445 12.8571 6.82083 12.7543 7.15083 12.5829C7.49 12.4029 7.77417 12.1457 7.985 11.8371C7.31719 11.0233 6.9562 10.0258 6.95833 9V8.76C6.68333 8.64 6.37167 8.57143 6.04167 8.57143ZM17.9583 8.57143C17.6283 8.57143 17.3167 8.64 17.0417 8.76V9C17.0417 10.0286 16.6842 11.0229 16.015 11.8371C16.125 12 16.2442 12.1286 16.3817 12.2571C16.8044 12.6404 17.3695 12.8554 17.9583 12.8571C18.3617 12.8571 18.7375 12.7543 19.0675 12.5829C19.7642 12.2143 20.25 11.52 20.25 10.7143C20.25 10.146 20.0086 9.60092 19.5788 9.19906C19.149 8.79719 18.5661 8.57143 17.9583 8.57143ZM12 13.7143C9.855 13.7143 5.58333 14.7171 5.58333 16.7143V17C5.58333 17.5523 6.03105 18 6.58333 18H17.4167C17.969 18 18.4167 17.5523 18.4167 17V16.7143C18.4167 14.7171 14.145 13.7143 12 13.7143ZM5.3175 14.1857C3.54833 14.3829 1 15.2229 1 16.7143V17C1 17.5523 1.44772 18 2 18H3.75V16.3457C3.75 15.48 4.3825 14.76 5.3175 14.1857ZM18.6825 14.1857C19.6175 14.76 20.25 15.48 20.25 16.3457V18H22C22.5523 18 23 17.5523 23 17V16.7143C23 15.2229 20.4517 14.3829 18.6825 14.1857Z'
        fill={pictogramFill}
      />
    </svg>
  )
}

export default CrmLeadsMenuIcon
