import { css } from '@emotion/css'

export const TAG_FONT_SIZE = 14

export default css(`
  padding: 2px 8px;
  font-weight: 400;
  font-size: ${TAG_FONT_SIZE}px;
  line-height: 18px;
  color: #458FFF;
  background: #F1F7FF;
  border-radius: 4px;
  margin-right: 6px;
  margin-top: 6px;    
  display: inline-flex;
  align-items: center;
  
  img {
    margin-left: 8px;
    cursor: pointer;
  }
`)
