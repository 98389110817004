import type { ColumnDef } from '@carfluent/common/dist/UI'
import { parsers } from '@carfluent/common'

import type { KeyVal } from 'types'
import AgeCell from 'components/common/Table/cells/AgeCell'
import DateCell from 'components/common/Table/cells/DateCell'
import NameCell from 'components/common/Table/cells/NameCell'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import ValueCell from 'components/common/Table/cells/ValueCell'
import CarOfInterestCell from 'components/common/Table/cells/CarOfInterestCell'
import LeadLabelCell from '../components/cells/LeadLabelCell'
import LeadStateCell from '../components/cells/LeadStateCell'
import NextActivityCell from '../components/cells/NextActivityCell'
import { FilterHeaderCell } from 'components/common/Table/cells/FilterHeaderCell'

import { LeadLabel, LeadStateAPI } from 'api/types'
import type { DictionaryItems, LeadListItem } from 'api/types'

import {
  ActivityType,
  ColumnPopUpTitles,
  ColumnTitles,
  FiltersKeys,
  LABEL_TEXTS
} from './constants'

type ColumnKeys = 'temperatures' | 'sources' | 'assignedTo' | 'statuses'

interface GetColumnDefinition extends Record<ColumnKeys, DictionaryItems<string>> {
  labelPopoverCls?: string
  onChange: (column: FiltersKeys) => (ids: number[]) => Promise<void>
  filters: KeyVal
}

const { parseDateStringUTC } = parsers

const getColumnsDefinition = ({
  temperatures,
  sources,
  assignedTo,
  statuses,
  onChange,
  filters,
  labelPopoverCls = ''
}: GetColumnDefinition): Array<ColumnDef<LeadListItem>> => [
  {
    accessorFn: (row: LeadListItem) => {
      const isEmptyCell = row.state === LeadStateAPI.None && !row.isLost

      if (row.state == null || isEmptyCell) {
        return []
      }

      const res = []

      if ((row.state & LeadStateAPI.New) > 0) {
        res.push('new')
      }

      if ((row.state & LeadStateAPI.NeedAttention) > 0) {
        res.push('task')
      }

      if ((row.state & LeadStateAPI.Inbox) > 0) {
        res.push('message')
      }

      if (row.isLost) {
        res.push('lost')
      }

      return res
    },
    id: 'leadState',
    cell: LeadStateCell,
    header: undefined,
    size: 80,
    sortable: false
  },
  {
    accessorFn: (row: LeadListItem) => `${row.firstName ?? ''} ${row.lastName ?? ''}`,
    id: 'name',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Name' }),
    size: 132,
    sortable: true
  },
  {
    accessorFn: (row: LeadListItem) => {
      /**
       * DD-TODO: rework when proper login is added/discussed with the backend
       */
      if (row.nextActivity == null) {
        return { type: 'no-activity', text: 'No Activity' }
      }

      if (row.nextActivity === ActivityType.Appointment) {
        return { type: 'appointment', text: ActivityType.Appointment }
      }

      if (row.nextActivity === ActivityType.FollowUpCall) {
        return { type: 'follow-up', text: ActivityType.FollowUpCall }
      }

      if (row.nextActivity === ActivityType.WebLead) {
        return { type: 'web-lead', text: ActivityType.WebLead }
      }

      if (row.nextActivity === ActivityType.EmailReceived) {
        return { type: 'email-received', text: ActivityType.EmailReceived }
      }

      if (row.nextActivity === ActivityType.MessageReceived) {
        return { type: 'message-received', text: ActivityType.MessageReceived }
      }

      return { type: 'reminder', text: row.nextActivity }
    },
    id: 'nextActivity',
    cell: NextActivityCell,
    header: TextCellHOC({ text: 'Next activity' }),
    loading: 'big_line',
    size: 150,
    sortable: false
  },
  {
    accessorFn: (row: LeadListItem) => row.label,
    id: 'label',
    cell: LeadLabelCell,
    /**
     * AZ-NOTE: this is a temporal work-around. Discussed this with OF.
     * The proper fix is to improve FilterHeaderHOC, to remove unnecessary rerenders.
     **/
    header: <FilterHeaderCell
      {...{
        title: ColumnTitles.Label,
        popUpTitle: ColumnPopUpTitles.Label,
        filters: filters[FiltersKeys.Temperatures],
        items: temperatures.map(({ id }) => ({
          id,
          name: LABEL_TEXTS[id as LeadLabel] ?? ''
        })),
        onChange: onChange(FiltersKeys.Temperatures),
        isSelectAllAllowed: true,
        isUniqItem: true,
        popoverClassName: labelPopoverCls,
        popoverProps: {
          minWidth: 180
        }
      }}
            /> as unknown as () => void,
    size: 70,
    sortable: false
  },
  {
    accessorFn: (row: LeadListItem) => row.status,
    id: 'status',
    cell: ValueCell,
    header: <FilterHeaderCell {...{
      title: ColumnTitles.Status,
      popUpTitle: ColumnPopUpTitles.Status,
      filters: filters[FiltersKeys.Status],
      items: statuses,
      onChange: onChange(FiltersKeys.Status),
      isSelectAllAllowed: true,
      popoverProps: {
        minWidth: 260
      }
    }}
            /> as unknown as () => void,
    size: 90,
    sortable: false
  },
  {
    accessorFn: (row: LeadListItem) => row.source,
    id: 'source',
    cell: ValueCell,
    header: <FilterHeaderCell {...{
      title: ColumnTitles.Source,
      popUpTitle: ColumnPopUpTitles.Source,
      filters: filters[FiltersKeys.Source],
      items: sources,
      onChange: onChange(FiltersKeys.Source),
      isSelectAllAllowed: true,
      popoverProps: {
        minWidth: 260
      }
    }}
            /> as unknown as () => void,
    size: 140,
    sortable: false
  },
  {
    accessorKey: 'carsOfInterest',
    cell: CarOfInterestCell,
    header: TextCellHOC({ text: 'Car of interest' }),
    loading: 'two_lines_different_length',
    size: 150,
    sortable: true
  },
  {
    accessorFn: (row: LeadListItem) => row.age,
    id: 'age',
    cell: AgeCell,
    header: TextCellHOC({ text: 'Age' }),
    size: 80,
    sortable: true
  },
  {
    accessorFn: (row: LeadListItem) => ({
      name: row.assignedTo,
      showAvatar: true,
      className: 'name-with-avatar-for-lead-table'
    }),
    id: 'assignedTo',
    cell: NameCell,
    header: <FilterHeaderCell {...{
      title: ColumnTitles.AssignedTo,
      popUpTitle: ColumnPopUpTitles.AssignedTo,
      filters: filters[FiltersKeys.AssignedTo],
      items: assignedTo,
      onChange: onChange(FiltersKeys.AssignedTo),
      isSelectAllAllowed: true,
      popoverProps: {
        minWidth: 260,
        maxWidth: 260
      }
    }}
            /> as unknown as () => void,
    loading: 'circle_image_with_line',
    size: 140,
    sortable: false
  },
  {
    accessorFn: (row: LeadListItem) => ({
      value: parseDateStringUTC(row.lastActivityDate),
      isMultiline: true
    }),
    id: 'lastActivityDate',
    cell: DateCell,
    header: TextCellHOC({ text: 'Last activity' }),
    loading: 'two_lines_different_length',
    size: 136,
    sortable: true
  }
]

export default getColumnsDefinition
