import { PaymentSectionType } from './types'

export const PAYMENT_TEXT = {
  [PaymentSectionType.VehicleCost]: {
    title: 'Set up payment methods for vehicle costs',
    description: 'When you add a cost it will be paid in a variety of ways. You may wish to credit Account Payable, or credit A/P Credit card, or credit any of bank accounts. You can select up to five accounts and these account will be available to you when you enter a cost.'
  },
  [PaymentSectionType.VehiclePurchase]: {
    title: 'Set up payments for vehicle purchases',
    description: 'When you make a purchase you will pay for it in a variety of ways. You may wish to credit Accounts Payable, or credit A/P Credit card, or whatever. You can select accounts you want to be able to credit here (up to five and then these accounts will be available to you when your enter your purchase).'
  }
}
