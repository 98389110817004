import { type FC, useRef, useState } from 'react'
import { cnx, IconButton, Loader } from '@carfluent/common'

import { type EntityId } from 'types'
import { type MessageTemplateItem } from 'api/types'
import { type FileFilterData } from 'utils/fileFilter'
import { DEFAULT_FILE_WITHOUT_VIDEO_EXTENSIONS } from 'constants/files'
import StatedIcon from 'components/common/StatedIcon'
import BaseTextarea from 'components/common/Textarea'
import Popover from 'components/common/Popover'
import AddTemplateButton from 'components/common/AddTemplateButton'
import AttachMediaMenu from 'components/common/Messenger/components/AttachMediaMenu'
import IconSVG from 'components/inlineImages'
import sendIcon from 'assets/send_message_icon.svg'
import activeSendIcon from 'assets/active_send_icon.svg'

import { MAX_MESSAGE_LENGTH, MAX_ROWS_TEXTAREA, TOOLTIPS_CONFIG } from './constants'
import useTextarea, { type UseTextAndFilesAreaProps } from './hook'
import { CLASS_NAME, POPOVER_CLASS_NAME } from './styles'

type ReducedProps = 'isDisabled' | 'setVehiclePhotoAttachments' | 'attachmentsAmount'

export interface TextAndFilesAreaProps extends Omit<UseTextAndFilesAreaProps, ReducedProps> {
  leadId: EntityId
  messageTemplateItems: MessageTemplateItem[]
  fileError?: string
  leadPhoneNumber: string | null
  onAddFiles: (data: FileFilterData) => void
  onOpenAttachPhotosModal: () => void
}

const TextAndFilesArea: FC<TextAndFilesAreaProps> = ({
  messageTemplateItems,
  fileError = '',
  isLeadUnsubscribed,
  leadPhoneNumber,
  onAddFiles,
  onOpenAttachPhotosModal,
  ...textAreaHookProps
}) => {
  const {
    attachments,
    isAddingAttachmentsDisabled,
    isMaxLengthValue,
    onAddTemplate,
    onDeleteAttachment,
    onChange,
    onKeyDown,
    onSubmit,
    textareaWrapperEl,
    value
  } = useTextarea({
    ...textAreaHookProps,
    isLeadUnsubscribed,
    leadPhoneNumber
  })

  const [addTemplatesAnchorEl, setAddTemplatesAnchorEl] = useState<HTMLDivElement | null>(null)
  const [sendBtnAnchorEl, setSendBtnAnchorEl] = useState<HTMLDivElement | null>(null)
  const refFileUrlsMap = useRef<Record<string, string>>({})

  // ========================================== //

  return (
    <div ref={textareaWrapperEl} className={CLASS_NAME}>
      <div
        className={cnx(
          'cf-files-section',
          ((attachments.length > 0 || fileError.length > 0) && !isLeadUnsubscribed) ? 'is-visible' : ''
        )}
      >
        <div className='file-zone'>
          {attachments.map((attachment) => {
            let sourceUrl
            let isLoading = false

            if ('file' in attachment) {
              const fileData = attachment
              if (refFileUrlsMap.current[fileData.id] == null) {
                refFileUrlsMap.current[fileData.id] = URL.createObjectURL(fileData.file)
              }

              sourceUrl = refFileUrlsMap.current[fileData.id]
              isLoading = fileData.url == null
            } else {
              const { thumbnailUrl, originalUrl } = attachment

              sourceUrl = thumbnailUrl != null ? thumbnailUrl : originalUrl
            }

            return (
              <div key={attachment.id} className='cf-file-thumbnail'>
                <img src={sourceUrl} alt='' />
                <IconButton onClick={() => onDeleteAttachment(attachment.id)}>
                  <IconSVG.Close />
                </IconButton>
                {
                  isLoading && (
                    <Loader className='cf-section-loader' size='small' color='dark' />
                  )
                }
              </div>
            )
          })}
        </div>

        <p className={cnx('cf-file-error-message', (fileError.length > 0) && 'with-content')}>
          {fileError}
        </p>
      </div>

      <section className='cf-textarea-section'>
        <AttachMediaMenu
          onOpenAttachPhotosModal={onOpenAttachPhotosModal}
          className='cf-messages-icon'
          isAddingAttachmentsDisabled={isAddingAttachmentsDisabled}
          isDisabled={isLeadUnsubscribed}
          onAddFiles={onAddFiles}
          acceptedExtensions={DEFAULT_FILE_WITHOUT_VIDEO_EXTENSIONS}
          attachmentsLength={attachments.length}
        />

        <BaseTextarea
          value={isLeadUnsubscribed ? '' : value}
          maxRows={MAX_ROWS_TEXTAREA}
          onChange={onChange}
          onKeyDown={onKeyDown}
          disabled={isLeadUnsubscribed}
          placeholder={isLeadUnsubscribed ? 'Lead unsubscribed' : 'Type your message'}
        />

        <Popover
          anchorEl={addTemplatesAnchorEl}
          className={cnx(POPOVER_CLASS_NAME, 'cf-popover-container')}
          open={addTemplatesAnchorEl != null && !isLeadUnsubscribed}
          {...TOOLTIPS_CONFIG.addTemplatesBtn}
        >
          Add Message template
        </Popover>

        <AddTemplateButton
          items={messageTemplateItems}
          className='templates-icon'
          onAddTemplate={onAddTemplate}
          isDisabled={isLeadUnsubscribed}
        >
          <div
            onMouseEnter={({ currentTarget }) => setAddTemplatesAnchorEl(currentTarget)}
            onMouseLeave={() => setAddTemplatesAnchorEl(null)}
          >
            <IconSVG.AddTemplate color={isLeadUnsubscribed ? '#00000030' : '#000000'} />
          </div>
        </AddTemplateButton>

        <hr className='vertical-line' />

        <Popover
          open={sendBtnAnchorEl != null && !isLeadUnsubscribed}
          anchorEl={sendBtnAnchorEl}
          className={cnx(POPOVER_CLASS_NAME, 'cf-popover-container')}
          {...TOOLTIPS_CONFIG.sendBtn}
        >
          Send
        </Popover>

        <StatedIcon
          onMouseEnter={({ currentTarget }) => setSendBtnAnchorEl(currentTarget)}
          onMouseLeave={() => setSendBtnAnchorEl(null)}
          className={cnx('send-message-icon', isLeadUnsubscribed && 'disabled')}
          src={sendIcon}
          isHoverListen={false}
          isParentHoverListen={false}
          activeSrc={activeSendIcon}
          isActive={leadPhoneNumber != null && !isLeadUnsubscribed}
          onClick={onSubmit}
        />
      </section>

      {isMaxLengthValue &&
        <p className='cf-max-symbols-error'>
          Message is too long. {MAX_MESSAGE_LENGTH} symbols max.
        </p>}
    </div>
  )
}

export default TextAndFilesArea
