import { useCallback, useContext, useEffect, useMemo, useRef, useState, type FC } from 'react'
import { useLocation, useMatch } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { cnx } from '@carfluent/common'

import AuthCTX from 'store/auth'
import useClickOutside from 'hooks/useClickOutside'
import LogoIcon from 'components/inlineImages/LogoIcon'
import TwoArrowToggleIcon from 'components/inlineImages/TwoArrowToggle'

import MenuItem from './components/MenuItem'
import { configureMenuItems } from './config'
import CLASS_NAME, { DRAWER_TRANSITION_DURATION } from './styles'

const DRAWER_OPENED_CLS = 'cf-drawer-fully-opened'

export interface NavDrawerProps {
  hasLogo?: boolean
}

const NavDrawer: FC<NavDrawerProps> = ({
  hasLogo = false
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const [expandedSection, setExpandedSection] =
    useState<string | null>(null)

  const drawerRef = useRef<HTMLDivElement>(null)
  const { userRoles } = useContext(AuthCTX)

  const location = useLocation()
  const match = useMatch(location.pathname)

  // ========================================== //
  //                  HANDLERS                  //
  // ========================================== //

  const onToggle = (): void => {
    setExpandedSection(null)
    setIsOpen(isOpen => !isOpen)
  }

  const menuItems = useMemo(() => {
    return configureMenuItems(userRoles)
      .filter(link => link.isForbidden !== true)
      .map(item => ({
        ...item,
        subLinks: (item.subLinks ?? []).filter(subLink => subLink.isForbidden !== true)
      }))
  }, [userRoles])

  const onClose = useCallback(() => {
    setIsOpen(false)
    setExpandedSection(null)
  }, [])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useClickOutside(drawerRef, onClose, { capture: true })

  useEffect(() => {
    onClose()
  }, [onClose, location.pathname])

  useEffect(() => {
    const el = drawerRef.current

    if (el == null) {
      return
    }

    if (isOpen) {
      const t = setTimeout(() => {
        el.classList.add(DRAWER_OPENED_CLS)
        clearTimeout(t)
      }, DRAWER_TRANSITION_DURATION)
    } else {
      el.classList.remove(DRAWER_OPENED_CLS)
    }
  }, [isOpen])

  // ========================================== //

  return (
    <div
      ref={drawerRef}
      className={cnx(CLASS_NAME, 'g-non-printable-block', isOpen ? 'cf-drawer-open' : 'cf-drawer-close')}
    >
      {hasLogo && (
        <div className='cf-drawer-header'>
          <LogoIcon />
        </div>
      )}

      <nav>
        <ul className='cf-nav-menu'>
          {menuItems.map((item) => (
            <MenuItem
              key={item.to}
              item={item}
              isClosedMode={!isOpen}
              expandedSection={expandedSection}
              matchedPathname={match?.pathname}
              setIsSubLinksShown={setExpandedSection}
            />
          ))}
        </ul>
      </nav>

      <div onClick={onToggle} className='cf-nav-toggle'>
        <TwoArrowToggleIcon isToggleToOpen={!isOpen} />
      </div>
    </div>
  )
}

export default observer(NavDrawer)
