import format from 'date-fns/format'

import type { User, DictionaryItem } from 'api/types'
import type { Shift } from 'types'
import { TIME_12H_MIN_AMPM_FORMAT } from 'constants/constants'

import type { ShiftDetailsFormData } from './types'

export const transformUsersToOptions = (users: User[]): DictionaryItem[] => users.map(user => ({
  name: `${user.firstName} ${user.lastName}`,
  id: user.id
}))

export const transformShiftToFormData = (shift: Shift): ShiftDetailsFormData => ({
  person: { name: shift.userName, id: shift.userId },
  date: shift.startDate,
  startTime: format(shift.startDate, TIME_12H_MIN_AMPM_FORMAT).toUpperCase(),
  endTime: format(shift.endDate, TIME_12H_MIN_AMPM_FORMAT).toUpperCase()
})

export const formatTime = (date: Date): string => format(date, TIME_12H_MIN_AMPM_FORMAT).toUpperCase()

export const checkLessThanHourDifference = (startDate: Date, endDate: Date): boolean => {
  const differenceInMilliseconds = Math.abs(Number(startDate) - Number(endDate))
  const differenceInMinutes = differenceInMilliseconds / (1000 * 60)

  return differenceInMinutes < 60
}
