import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-wrap: nowrap;

  &.name-with-avatar-for-lead-table {
    width: 100%;
    min-width: 124px;
  }
  
  .avatar {
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    border-radius: 50%;
    color: #FFF;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  p {
    margin-left: 8px;
    color:  #101010;
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`)
