import { css } from '@emotion/css'

export default css(`
  && {
    &.cf-modal-container {
      .cf-modal-root {
        .cf-modal-content p {
          font-family: Roboto, sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }

        .cf-modal-footer {
          margin-top: 48px;
          padding: 0px 40px;

          .cf-cancel-btn {
            color: #101010;
            background-color: #fff;
            border: 1px solid #101010;
          }
        }
      }
    }
  }
`)
