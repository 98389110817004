import { type Task, TaskFilterEnum } from 'api/types'
import { toDateOrNull } from 'utils/parse_date'
import { formatUTCDateTimeForView } from 'utils/view_helper'

import { FilterHeaderCell } from 'components/common/Table/cells/FilterHeaderCell'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import ValueCell from 'components/common/Table/cells/ValueCell'
import NameCell from 'components/common/Table/cells/NameCell'
import DateCell from 'components/common/Table/cells/DateCell'
import CarOfInterestCell from 'components/common/Table/cells/CarOfInterestCell'
import TaskPriorityCell from 'components/common/Table/cells/TaskPriorityCell'

import OutcomeCell from '../components/cells/OutcomeCell'
import RelatedToCell from '../components/cells/RelatedToCell'
import { SubjectCellHOC } from '../components/cells/SubjectCell'

import type { GetColumnsDefinitionProps, TasksColumns } from './types'
import {
  FiltersKeys,
  ColumnPopUpTitles,
  ColumnTitles,
  RowSubject,
  RowType,
  RowText
} from './constants'

const getColumnsDefinition = ({
  assignedToList,
  leadStatusList,
  onChange,
  filters,
  taskFilter
}: GetColumnsDefinitionProps): TasksColumns => {
  const columnsDefinition: TasksColumns = [
    {
      accessorFn: (row: Task) => {
        const dueDate = row.dueDate

        if (row.subject == null || row.subject === RowSubject.NoActivity) {
          return { type: RowType.NoActivity, text: RowText.NoActivity, dueDate }
        }

        if (row.subject === RowSubject.CreditAppReceived) {
          return { type: RowType.CreditAppReceived, text: RowText.CreditAppReceived, dueDate }
        }

        if (row.subject === RowSubject.Appointment) {
          return { type: RowType.Appointment, text: RowText.Appointment, dueDate }
        }

        if (row.subject === RowSubject.FollowUp) {
          return { type: RowType.FollowUp, text: RowText.FollowUp, dueDate }
        }

        if (row.subject === RowSubject.WebLead) {
          return { type: RowType.WebLead, text: RowText.WebLead, dueDate }
        }

        if (row.subject === RowSubject.EmailReceived) {
          return { type: RowType.EmailReceived, text: RowText.EmailReceived, dueDate }
        }

        if (row.subject === RowSubject.MessageReceived) {
          return { type: RowType.MessageReceived, text: RowText.MessageReceived, dueDate }
        }

        return { type: RowType.Reminder, text: row.subject, dueDate }
      },
      id: 'subject',
      cell: SubjectCellHOC({ isCompletedTab: taskFilter === TaskFilterEnum.Completed }),
      header: TextCellHOC({ text: 'Subject' }),
      loading: 'big_line',
      minSize: 160,
      sortable: false
    },
    taskFilter === TaskFilterEnum.Completed
      ? {
          accessorFn: (row: Task) => ({
            numberOfRows: 2,
            text: row.outcome
          }),
          accessorKey: 'outcome',
          cell: OutcomeCell,
          loading: 'two_lines_different_length',
          header: TextCellHOC({ text: 'Outcome' }),
          sortable: false,
          size: 160
        }
      : {
          accessorKey: 'taskPriorityId',
          cell: TaskPriorityCell,
          header: TextCellHOC({ text: 'Priority' }),
          size: 110
        },
    {
      accessorFn: (row: Task) => ({
        value: toDateOrNull(formatUTCDateTimeForView(row.dueDate, 'MM/dd/yy hh:mm aa')),
        isMultiline: true
      }),
      id: 'dueDate',
      cell: DateCell,
      header: TextCellHOC({ text: 'Due date' }),
      loading: 'two_lines_different_length',
      size: 110
    },
    {
      accessorFn: (row: Task) => ({
        name: row.relatedTo,
        leadAge: row.leadAge,
        className: 'cf-related-to-cell'
      }),
      id: 'relatedTo',
      cell: RelatedToCell,
      header: TextCellHOC({ text: 'Related to' }),
      loading: 'two_lines_different_length',
      size: 140,
      sortable: false
    },
    {
      accessorKey: 'leadStatus',
      cell: ValueCell,
      header: (
        <FilterHeaderCell
          title={ColumnTitles.LeadStatus}
          popUpTitle={ColumnPopUpTitles.LeadStatus}
          filters={filters[FiltersKeys.LeadStatus]}
          items={leadStatusList}
          onChange={onChange(FiltersKeys.LeadStatus)}
          isSelectAllAllowed
          popoverProps={{ minWidth: 260 }}
        /> as unknown as () => void
      ),
      size: 120,
      sortable: false
    },
    {
      accessorKey: 'carsOfInterest',
      cell: CarOfInterestCell,
      header: TextCellHOC({ text: 'Car of interest' }),
      loading: 'two_lines_different_length',
      size: 180,
      sortable: true
    },
    {
      accessorFn: (row: Task) => ({
        name: row.assignee,
        showAvatar: true,
        className: 'cf-assigned-to-cell'
      }),
      id: 'assignee',
      cell: NameCell,
      header: (
        <FilterHeaderCell
          title={ColumnTitles.AssignedTo}
          popUpTitle={ColumnPopUpTitles.AssignedTo}
          filters={filters[FiltersKeys.AssignedTo]}
          items={assignedToList}
          onChange={onChange(FiltersKeys.AssignedTo)}
          isSelectAllAllowed
          popoverProps={{ minWidth: 260, maxWidth: 260 }}
        /> as unknown as () => void
      ),
      loading: 'circle_image_with_line',
      size: 146,
      maxSize: 146,
      sortable: false
    }
  ]

  if (taskFilter === TaskFilterEnum.Completed) {
    columnsDefinition.splice(6, 0, {
      accessorFn: (row: Task) => ({
        value: toDateOrNull(formatUTCDateTimeForView(row.completionDate, 'MM/dd/yy hh:mm aa')),
        isMultiline: true
      }),
      id: 'completionDate',
      cell: DateCell,
      header: TextCellHOC({ text: 'Completed' }),
      loading: 'two_lines_different_length',
      size: 110
    })
  }

  return columnsDefinition
}

export default getColumnsDefinition
