import type { ReactNode } from 'react'
import { Loader } from '@carfluent/common'
import IconSVG from 'components/inlineImages'

export interface OptionWithCounter {
  isLoading: boolean
  value: string
  counter: number
}

export const renderOptionWithCounter = ({
  isLoading,
  value,
  counter
}: OptionWithCounter): JSX.Element => {
  return (
    <>
      {value}
      <span className='counter'>
        {isLoading
          ? <Loader color='dark' size='small' />
          : counter}
      </span>
    </>
  )
}

/**
 * AS-TODO: remove this utils when dropdown(select) will be fixed in common lib
 */

export const getDisplayItem = (value: string | null): ReactNode => {
  return (
    <>
      <div className='cf-display'>
        {value}
      </div>

      <div className='cf-end-adornment'>
        <IconSVG.DropdownArrowDown width={16} height={16} />
      </div>
    </>
  )
}
