import { css } from '@emotion/css'

export default css(`
  .cf-modal-content-scroll-wrapper {
    min-width: 100vw;
  }
  
  .phone-number-wrapper {
    position: relative;
    
    .info-block {
      position: absolute;
      bottom: -20px;
      left: 16px;
      color: rgba(33, 33, 33, 0.50);
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
  }

  .title {
    grid-column: 1 / 3;
    color: #101010;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .checkboxes-wrapper {
    grid-column: 1;

    .checkboxes-title {
      margin-bottom: 16px;
    }

    .items-list {
      display: grid;
      grid-gap: 16px;

      div { padding-left: 2px; }
      label { height: 24px; } 
    }

    .error-text {
      margin-top: 4px;
      margin-bottom: -20px;
      margin-left: 16px;
      color: #B00020;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
  }

  .cf-delete-button.cf-button-root.cf-button-variant-text {
    grid-column: 1 / 3;
    text-decoration: none;

    .cf-button-content {
      font-weight: 500;
    }
  }

  .cf-button-content {
    font-size: 14px;
  }
`)
