import { parsers } from '@carfluent/common'
import type { VehicleStateResponse } from 'api/types/vehicles.types'
import { formatDate } from 'utils/parse_date'

export const getDeletedCarMassageInfo = (isCarDeleted: boolean, vehicleInfo: VehicleStateResponse | null): string | undefined => {
  if (!isCarDeleted || vehicleInfo == null) {
    return undefined
  }

  const { changedDate, changedByUser } = vehicleInfo

  const parsedDate = parsers.parseDateStringUTC(changedDate)
  const date = parsedDate != null ? formatDate(parsedDate, 'MM/dd/yy') : null

  return (date != null && changedByUser != null)
    ? `on ${date} by ${changedByUser.dealerFirstName} ${changedByUser.dealerLastName}`
    : undefined
}
