import green from '@material-ui/core/colors/green'
import purple from '@material-ui/core/colors/purple'
import { createTheme } from '@material-ui/core/styles'
import type {} from '@material-ui/lab/themeAugmentation' // enables overrides for lab components

export const theme = createTheme({
  props: {
    MuiButton: {
      variant: 'contained'
    }
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h2: {
      color: '#101010',
      fontWeight: 400,
      fontSize: 34,
      lineHeight: '36px'
    },
    h3: {
      color: '#101010',
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '24px',
      letterSpacing: 0.15
    },
    h4: {
      color: '#212121',
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: 0.15
    },
    h5: {
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 24,
      lineHeight: '24px',
      letterSpacing: 0.18
    },
    h6: {
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 20,
      lineHeight: '24px',
      letterSpacing: 0.15
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: 0.5,
      color: '#101010'
    },
    subtitle2: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: 0.15,
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
  palette: {
    primary: {
      main: purple[500]
    },
    secondary: {
      main: green[500]
    }
  },
  overrides: {
    MuiButton: {
      root: {
        padding: 15,
        borderRadius: 12,
        lineHeight: 1.5,
        fontSize: '0.875rem',
        minWidth: '64px',
        textTransform: 'none',
        boxSizing: 'border-box',
        width: '100%',

        '&:disabled': {
          backgroundColor: '#F3F3F3',
          color: 'rgba(0, 0, 0, 0.38)'
        },

        '&&, &&:hover': {
          boxShadow: 'none'
        }
      },
      contained: {
        backgroundColor: '#000',
        color: '#fff',
        textTransform: 'uppercase',

        '&:disabled': {
          backgroundColor: '#F3F3F3',
          color: 'rgba(0, 0, 0, 0.38)'
        },

        '&:hover': {
          backgroundColor: '#101010',
          opacity: 0.8
        }
      },
      outlined: {
        height: 56,
        border: '1px solid #101010',
        backgroundColor: 'transparent',
        color: '#101010',
        textTransform: 'uppercase',

        '&:hover, &:active, &.active': {
          backgroundColor: '#101010',
          color: '#fff',
          opacity: 0.8
        }
      },
      label: {
        fontFamily: 'Roboto, sans-serif'
      },
      text: {
        width: 'auto',
        textDecoration: 'underline'
      }
    },
    MuiInput: {
      formControl: {
        border: '1px solid #828282',
        boxShadow: '0px 1px 2px rgba(50, 43, 95, 0.08)'
      },
      root: {
        boxSizing: 'border-box',
        borderRadius: 4
      },
      input: {
        padding: '12px 16px',
        '&:hover': {
          borderBottom: '0px solid #828282'
        }
      },
      underline: {
        '&:before': {
          borderBottom: '0!important'
        },
        '&:after': {
          borderBottom: '0px solid #828282'
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: '#101010',
        letterSpacing: 0.15,
        fontSize: 16,
        '&.Mui-focused': {
          letterSpacing: 0.4,
          color: 'rgba(33, 33, 33, 0.8)'
        },
        '&.Mui-error': {
          color: ' #B00020'
        },
        '&.Mui-disabled': {
          color: 'rgba(33, 33, 33, 0.65)'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        color: '#f44336',
        fontWeight: 400,
        fontSize: 12
      }
    },
    MuiFilledInput: {
      underline: {
        background: '#FAFAFA',
        borderRadius: 12,
        boxSizing: 'border-box',
        border: '1px solid #FAFAFA',
        '&:before': {
          borderBottom: '0!important'
        },
        '&:after': {
          borderBottom: '0px solid #828282'
        },
        '&.Mui-error': {
          border: '1px solid #B00020'
        },
        '&.Mui-focused': {
          backgroundColor: '#FAFAFA'
        }
      }
    },
    MuiTextField: {
      root: {
        width: '100%',
        '&:focus': {
          borderBottom: '1px solid #828282'
        }
      }
    },
    MuiTab: {
      wrapper: {
        fontFamily: 'Roboto, sans-serif'
      }
    },
    MuiMenuItem: {
      root: {
        padding: '12px 16px',
        fontWeight: 400,
        fontSize: 16,
        height: 48
      }
    },
    MuiSnackbarContent: {
      message: {
        fontFamily: 'Roboto, sans-serif'
      }
    },
    MuiInputBase: {
      root: {
        backgroundColor: '#FAFAFA!important',

        '&:focus': {
          borderBottom: '1px solid #828282'
        },
        '&:hover': {
          backgroundColor: '#FAFAFA!important',
          border: '1px solid #C99B86'
        },
        '&.Mui-disabled': {
          backgroundColor: '#FAFAFA!important',
          border: '1px solid #FAFAFA'
        },
        '&.Mui-focused': {
          border: '1px solid #C99B86'
        }
      },
      input: {
        borderRadius: 12
      }
    },
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px #FAFAFA inset',
          WebkitTextFillColor: '#FAFAFA'
        }
      }
    },
    MuiAutocomplete: {
      option: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 16,
        lineHeight: '20px',
        letterSpacing: 0.25,
        color: '#101010'
      }
    },
    MuiSnackbar: {
      root: {
        '&.MuiSnackbar-anchorOriginBottomCenter': {
          bottom: '104px'
        }
      }
    }
  }
})
