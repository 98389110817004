import { useCallback, useState } from 'react'
import { useModal } from '@carfluent/common'

import AccountingApiProvider from 'api/accounting.api'
import useTableApi from 'hooks/useTableApi'
import { API_CALL_DELAY, PAGE_SIZE } from 'constants/constants'

import columnDefinitions from './columns'
import {
  type Row,
  type TransactionLineVendorDto,
  type UseVendorsReturn
} from './types'

const DEFAULT_FILTERS = {}

const useVendors = (): UseVendorsReturn => {
  const {
    emptyTableMessage,
    isLoading,
    loadRows,
    onSearch,
    rows,
    search,
    setSearch
  } = useTableApi({
    apiCallDelay: API_CALL_DELAY,
    defaultFilters: DEFAULT_FILTERS,
    getList: AccountingApiProvider.getVendors,
    pageSize: PAGE_SIZE,
    shouldRunOnCall: true
  })

  const [selectedVendorId, setSelectedVendorId] = useState<number | null>(null)
  const {
    isModalOpen: isVendorModalOpen,
    onOpenModal: onOpenVendorModal,
    onCloseModal: onCloseVendorModal
  } = useModal()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onAddVendor = useCallback(async () => {
    await loadRows({ forceRefresh: true })
  }, [loadRows])

  const onDeleteVendor = useCallback(async () => {
    await loadRows({ forceRefresh: true })
  }, [loadRows])

  const onOpenVendorDetails = useCallback((row?: Row<TransactionLineVendorDto>) => {
    setSelectedVendorId(row?.original.id ?? null)
    onOpenVendorModal()
  }, [onOpenVendorModal])

  const onBottomReached = useCallback(async () => {
    if (!isLoading) {
      await loadRows({ forceRefresh: false })
    }
  }, [isLoading, loadRows])

  // ========================================== //

  return {
    emptyState: emptyTableMessage,
    isLoading,
    isModalOpen: isVendorModalOpen,
    columns: columnDefinitions,
    rows,
    selectedVendorId,
    search,
    onAddVendor,
    onBottomReached,
    onCloseVendorDetails: onCloseVendorModal,
    onDeleteVendor,
    onOpenVendorDetails,
    onSearchChange: setSearch,
    onSearch
  }
}

export default useVendors
