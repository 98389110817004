import { useCallback, useMemo, useRef, useState } from 'react'

export interface UseTogglerReturn {
  disable: () => void
  enable: () => void
  toggle: () => void
  setValue: (value: boolean) => void
  value: boolean
  getValue: () => boolean
}

const useToggler = (initialValue: boolean): UseTogglerReturn => {
  const [value, _setValue] = useState(initialValue)
  const refValue = useRef(initialValue)

  const setValue: typeof _setValue = useCallback((value) => {
    _setValue(value)
    refValue.current = typeof value === 'function' ? value(refValue.current) : value
  }, [])

  const enable = useCallback(() => {
    setValue(true)
  }, [setValue])

  const disable = useCallback(() => {
    setValue(false)
  }, [setValue])

  const toggle = useCallback(() => {
    setValue((value) => !value)
  }, [])

  const getValue = useCallback(() => {
    return refValue.current
  }, [])

  return useMemo(() => ({
    disable,
    enable,
    getValue,
    setValue,
    toggle,
    value
  }), [
    disable,
    enable,
    getValue,
    toggle,
    value
  ])
}

export default useToggler
