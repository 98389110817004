import React, { FC, useMemo } from 'react'
import { CellWrapper, cn } from '@carfluent/common'

import TooltipItem from 'components/common/TooltipItem'

import { createStyleClass } from './styles'

interface TooltipCell {
  getValue: () => string
  className?: string
}

const TooltipCell: FC<TooltipCell> = ({ getValue, className= '' }) => {
  const rootCls = useMemo(createStyleClass, [])

  return (
    <CellWrapper className={rootCls}>
      <TooltipItem
        value={getValue()}
        className={cn('cf-tooltip-costs-text', className)}
      />
    </CellWrapper>
  )
}

export default TooltipCell
