import type { DictionaryItem } from 'api/types'
import type { LogType, UseLeadDetailsViewReturn } from 'pages/crm/LeadDetailsView/hook/types'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ErrTouchShortcuts = {
  subject: true
  assignedUser: true
  activityCompleteSubOptions: true
}
export interface LogActivityFormData {
  date: Date | null
  time: string
  activityCompleteOption: string | null
  activityCompleteSubOptions: string[]
  assignedUser: DictionaryItem | null
  subject: DictionaryItem | null
  workNotes: string
  isInbound: null | string
}

export enum FieldIds {
  ActivityCompleteOptionId = 'activityCompleteOption',
  ActivityCompleteSubOptions = 'activityCompleteSubOptions',
  WorkNotes = 'workNotes',
  IsInbound = 'isInbound',
  Subject = 'subject',
  AssignedTo = 'assignedUser',
  Date = 'date',
  Time = 'time'
}

export type LogActivityProps = Omit<UseLeadDetailsViewReturn['activityTasksProps'], 'logTypeId'> & {logTypeId: LogType}
