import { type FC, type ReactNode } from 'react'
import { FormDropdown, Loader } from '@carfluent/common'
import type { Item } from '@carfluent/common/dist/UI/Dropdown/types'

import { type Vehicle, VehicleState } from 'api/types'
import { formatVehicleDisplayName } from 'utils/vehicleDropdownHelpers'
import Modal from 'components/dialogs/ModalFullscreen'
import Gallery from 'components/crm/Gallery'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import type { VehicleOption } from 'components/common/VehiclesDropdownOption/types'
import { renderNoVehiclesOption, renderVehicleOption } from 'components/optionRenderers/vehiclesDropdown'
import IconSVG from 'components/inlineImages'

import type { UseAttachInventoryPhotosModalProps } from './hook/types'
import { EmptyState, GroupCarsIds, optionTextMap, photosNumLimit } from './hook/constants'
import useAttachInventoryPhotos from './hook'

import { CLASS_NAME, DROPDOWN_POPOVER_CLASS_NAME } from './styles'

export const MODAL_MIN_WIDTH = 1280

const AttachInventoryPhotosModal: FC<UseAttachInventoryPhotosModalProps> = (props) => {
  const {
    isPhotosLoading,
    isPhotosNumLimited,
    isCarSelected,
    files,
    value,
    selectedPhotos,
    selectedVideos,
    attachmentsLength,
    onFileClick,
    onClose,
    onSubmit,
    onChange,
    getVehicles
  } = useAttachInventoryPhotos(props)

  const selectedVideoCount = selectedVideos.length
  const selectedPhotoCount = selectedPhotos.length

  const selectedVideoText = selectedVideoCount > 0
    ? `, ${selectedVideoCount} video ${selectedVideoCount > 1 ? 's' : ''} selected`
    : ''

  const isDisabled = selectedPhotoCount === 0 && selectedVideoCount === 0

  const renderFooter = (): JSX.Element => {
    return (
      <>
        <p className='photos-selected-counter'>
          {isPhotosNumLimited
            ? `${selectedPhotoCount}/${photosNumLimit - attachmentsLength} photos selected${selectedVideoText}`
            : selectedPhotoCount > 0 && `${selectedPhotoCount} photos selected${selectedVideoText}`}
        </p>

        <div className='action-buttons'>
          <CancelSubmitActionsFooter
            isLoading={isPhotosLoading}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
            onClose={onClose}
            submitTitle='ATTACH MEDIA'
          />
        </div>
      </>
    )
  }

  // YAVO-TODO: need to do vehicleState always as number

  // Disable video selection if the vehicle
  // is sold or inactive but value?.vehicleState
  // set from carsOfInterest or from list and vehicleState in carsOfInterest - string
  // but in list vehicleState - number
  const disabledVideo = [
    VehicleState.Sold,
    VehicleState.Inactive,
    'Sold',
    'Inactive'
  ].includes(value?.vehicleState ?? VehicleState.Inactive)

  return (
    <Modal
      className={CLASS_NAME}
      keepContentMounted={false}
      isOpen={props.isOpen}
      onClose={onClose}
      dataTestId='attach-inventory-photo-modal'
      minWidth={MODAL_MIN_WIDTH}
      renderFooterActions={renderFooter}
      title='Attach Inventory Media'
      titleDataTestId='title-attach-inventory-photo-modal'
    >
      <FormDropdown<Vehicle>
        className='vehicles-dropdown'
        id='GroupCarList'
        placeholder='Search by VIN, make, stock #'
        startAdornment={<span> <IconSVG.SearchIcon /> </span>}
        onChange={onChange}
        value={value}
        formatDisplayValue={formatVehicleDisplayName}
        fetchHandler={getVehicles}
        renderNoOptions={renderNoVehiclesOption}
        renderOption={renderOption}
        isOptionDisabled={getOptionDisabled}
        popoverClassName={DROPDOWN_POPOVER_CLASS_NAME}
      />

      <div className='gallery-section'>
        {isPhotosLoading && <Loader className='cf-section-loader' size={32} />}
        {files.length > 0
          ? (
            <div className='gallery-container'>
              <Gallery
                files={files}
                selectedPhotos={selectedPhotos}
                selectedVideos={selectedVideos}
                onFileClick={onFileClick}
                isDisabledVideo={disabledVideo}
              />
            </div>
            )
          : !isPhotosLoading && (
            <p className='empty-state'>
              {isCarSelected ? EmptyState.EmptyGallery : EmptyState.NoVehicle}
            </p>
            )}
      </div>
    </Modal>
  )
}

export default AttachInventoryPhotosModal

const renderOption = (o: VehicleOption): ReactNode => {
  const text = optionTextMap[o.id as GroupCarsIds]

  return text != null
    ? <div className='option-render group-name'>{text}</div>
    : renderVehicleOption(o)
}

const getOptionDisabled = ({ value }: Item<VehicleOption>): boolean =>
  (value.id === GroupCarsIds.CarOfInterestGroup) ||
  (value.id === GroupCarsIds.FromInventoryGroup)
