import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'
import { Skeleton } from '@material-ui/lab'

import CLASS_NAME from './styles'

export interface LoadingBasicCellProps {
  noSkeleton?: boolean
}

const LoadingBasicCell: FC<LoadingBasicCellProps> = ({ noSkeleton }) => {
  return (
    <CellWrapper>
      {noSkeleton === true ? null : <Skeleton className={CLASS_NAME} variant='text' />}
    </CellWrapper>
  )
}

export default LoadingBasicCell
