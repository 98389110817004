import { css } from '@emotion/css'

export default css(`  
  display: flex;
  
  &.error {
    color: red;
    font-weight: 500;
  }
  
  &.with-start-adornment {
    gap: 4px;
  }
  
  .cf-tooltip-container {
    width: 100%;
    display: table; // for responsive ellipsis
    table-layout: fixed;
    white-space: nowrap;
    
    p {
      display: table-cell; // for responsive ellipsis
      font-family: Roboto;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`)
