import { css } from '@emotion/css'

export default css(`
  padding: 0;
  display: flex;
  background: #fff;
  flex-direction: column;
  justify-content: center;

  & .cell-text {
    height: auto;
    font-size: 16px;
    overflow: hidden;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Roboto, sans-serif;
  }
`)
