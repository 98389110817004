import { parsers } from '@carfluent/common'

import { isTruthy } from 'utils/general'
import { joinPartsBySpace } from 'utils/view_helper'

import type { MessageTemplateRowData, MessageTemplateFromApi } from './types'

const NO_USER_DATA_MSG = ''

const parseListData = (data: MessageTemplateFromApi[]): MessageTemplateRowData[] => {
  return data.map(parseRowData)
}

const parseRowData = (data: MessageTemplateFromApi): MessageTemplateRowData => {
  const { modifiedByUser } = data
  const userName = modifiedByUser != null ? joinPartsBySpace(modifiedByUser.dealerFirstName, modifiedByUser.dealerLastName) : ''

  return {
    id: data.id,
    modifiedByUser: {
      name: isTruthy(userName) ? userName : NO_USER_DATA_MSG,
      showAvatar: isTruthy(userName)
    },
    modifiedDate: parsers.parseDateStringUTC(data.modifiedDate),
    name: data.name ?? ''
  }
}

export default parseListData
