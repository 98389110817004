import { css } from '@emotion/css'
import { px, ScreenWidthBreakpoints } from 'constants/constants'

export default css(`
  &.edit-container {
    margin-bottom: 90px;
  }
  
  .cf-table-row {
    cursor: default;

    :hover > .cf-table-cell > .cf-cell-wrapper {
      background-color: #FAFAFA;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
    }

    .cf-table-cell {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      padding: 0px;
      padding-right: 0px;
      
      .cf-cell-wrapper {
        height: 64px;
        padding: 20px 8px;
      }

      :first-child .cf-cell-wrapper { padding-left: 12px; }
      :last-child .cf-cell-wrapper { padding-right: 16px; }
    }

    .cf-cell-wrapper.cf-number-input-cell {
      padding-top: 4px;
      padding-right: 14px;

      .MuiFormControl-root { align-items: flex-end; }

      .MuiInputBase-root {
        min-height: 56px;
        width: 136px;
        background-color: #fff !important;
        border: 1px solid rgba(0, 0, 0, 0.12);
      }

      .MuiInputBase-root.Mui-focused { border: 1px solid #C99B86; }

      .MuiTypography-root { width: 8px; } 
    }

    .cf-cell-wrapper.cell-balance-cell { text-align: end; }

    .cf-cell-wrapper.cell-account-number { max-width: 200px; }

    .cf-cell-wrapper.cell-account-name > p {
      max-width: 440px;

      @media screen and (max-width: ${px(ScreenWidthBreakpoints.Medium)}) {
        max-width: 320px;
      }
    }

    .cf-cell-wrapper.cell-account-type > p {
      max-width: 160px;

      @media screen and (max-width: ${px(ScreenWidthBreakpoints.Medium)}) {
        max-width: 140px;
      }
    }

    .cf-cell-wrapper.cell-account-category > p {
      max-width: 160px;

      @media screen and (max-width: ${px(ScreenWidthBreakpoints.Medium)}) {
        max-width: 140px;
      }
    }
    
    .cell-opening-balance {
      padding-right: 32px !important;
      
      .cf-input-container {
        max-height: 56px;
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
    
    .cf-table-cell:last-child {
      padding-right: 0px;
    }
  }
  
  .cf-no-data-block {
    display: flex;
    justify-content: center;
    padding: 30px;
    background: #fff;
    border-top: 8px solid #F3F3F3;
  }

  .header-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 24px;
    margin: 0 0 16px 0;
  }

  .left-panel {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .btn-edit {
    width: 150px;
    height: 56px;

    &.cf-button-variant-text {
      text-decoration: none;

      .cf-button-content {
        text-decoration: none;
      }
    }

    .cf-button-content {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #212121;
    }
  }

  .cf-header-cell-title {
    color: rgba(33, 33, 33, 0.5);
  }

  .cf-header-cell-title p.cf-header-cell-content {
    font-weight: 600;
    color: rgba(33, 33, 33, 0.5);
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
  }

  /**
   * "Opening balances" column header
   */
  th:nth-child(5) > div { padding-left: 16px; }
 
  .cf-back-to-top-edit {
    bottom: 84px;
  }
`)
