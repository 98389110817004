import isEqual from 'date-fns/isEqual'
import { formatters, formatCurrencyAccounting, type Preset, DEFAULT_MIN_DATE, DEFAULT_MAX_DATE } from '@carfluent/common'

import { type DateType } from 'api/types'

export const DATE_LABEL_FORMAT = 'M/d/yyyy'

export const { formatLocalDate } = formatters

export const formatWithRemoveEmptyDecimal = (string: string | number): string => {
  return formatCurrencyAccounting(string, {
    allowNegative: true,
    allowZero: true,
    emptyValues: '0'
  }).replace('.00', '')
}

/**
 * Formats date range as `Date: ${from}` or `Date: ${from}-${to}`.
 */
export const getDateLabel = (value: DateType | null, preset: Preset | null | undefined, dateFilterButtonTitle: string): string => {
  if (preset?.name != null) {
    return `${dateFilterButtonTitle}: ${preset.name}`
  }

  const { from, to } = value ?? {}
  const isDefaultStartDate = DEFAULT_MIN_DATE === from
  const isDefaultEndDate = DEFAULT_MAX_DATE === to

  if ((from == null && to == null) || (isDefaultStartDate && isDefaultEndDate)) {
    return dateFilterButtonTitle
  }

  let dateStr = ''

  if (from == null || isDefaultStartDate) {
    dateStr = `Till ${formatLocalDate(to, DATE_LABEL_FORMAT) ?? ''}`
  } else if (to == null || isDefaultEndDate) {
    dateStr = `From ${formatLocalDate(from, DATE_LABEL_FORMAT) ?? ''}`
  } else if (isEqual(from, to)) {
    dateStr = formatLocalDate(from, DATE_LABEL_FORMAT) ?? ''
  } else {
    dateStr = `${formatLocalDate(from, DATE_LABEL_FORMAT) ?? ''} - ${formatLocalDate(to, DATE_LABEL_FORMAT) ?? ''}`
  }

  return `${dateFilterButtonTitle}: ${dateStr}`
}
