import { cnx } from '@carfluent/common'

import { type EntityListItem, type TransactionLineVendorDto } from 'api/types'
import { formatPhoneNumberForView } from 'utils/view_helper'
import CLASS_NAME from './styles'

export type ListItem<TItem = null> = TItem | EntityListItem | TransactionLineVendorDto

export interface EntityOptionProps {
  option: ListItem
  className?: string
}

const EntityOption = ({ option, className }: EntityOptionProps): JSX.Element | null => {
  if (option == null) {
    return null
  }

  const { email, phoneNumber } = option
  const hasEmail = email != null && email.length > 0
  const hasPhone = phoneNumber != null && phoneNumber.length > 0
  const hasDivider = hasEmail && hasPhone

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <div className='entity-option'>
        <div>{option.name}</div>
        <div className='entity-subtitles'>
          {hasEmail && (<div className='entity-subtitle'>{email}</div>)}
          {hasDivider && (<div className='entity-divider' />)}
          {hasPhone && (
            <div className='entity-subtitle'>{formatPhoneNumberForView(phoneNumber)}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EntityOption
