import { type FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { EditorState } from 'draft-js'
import { cnx, Loader, Button, FormInput } from '@carfluent/common'

import { ValidationLength } from 'constants/validation'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import DeleteModal from 'components/dialogs/DeleteModal'
import EditorComponent, { EDITOR_CONTAINER_CLASS } from 'components/common/EditorComponent'
import SnippetsDropdown from 'components/wysiwyg/toolbar/SnippetsDropdown'
import decorators from 'components/wysiwyg/decorators'

import useEmailTemplateForm from './hook'
import { FieldIds } from './hook/constants'
import { PAGE_CLASSES, DELETE_MODAL_CLASSES } from './styles'

const EmailTemplateForm: FC = () => {
  const {
    isLoading,
    isFormSubmitting,
    isDisabledActionBtn,
    snippetsConfig,
    templateDeleteProps,
    values,
    errors,
    touched,
    onDeleteTemplate,
    onEditorChange,
    onChange,
    onSubmit,
    onBlur,
    onCancel
  } = useEmailTemplateForm({ editorSelector: EDITOR_CONTAINER_CLASS })

  const { isModalOpen, onCloseModal } = templateDeleteProps

  const customButtons = useMemo(() => (
    snippetsConfig.map(({ key, label, options, onDropdownChange, shouldUseDefaultOnChange }) => (
      <SnippetsDropdown
        key={key}
        label={label}
        options={options}
        onDropdownChange={onDropdownChange}
        shouldUseDefaultOnChange={shouldUseDefaultOnChange}
      />
    ))
  ), [snippetsConfig])

  return (
    <div className={cnx('g-submenu-content', 'g-margin-top-big', PAGE_CLASSES)}>
      {isLoading && <Loader size='large' />}

      <div className='cf-content'>
        <div className='cf-header-block'>
          <h5 className='cf-title'>
            Email Template
          </h5>

          <Button
            className='cf-button-link'
            dataTestId='btn-delete-template'
            onClick={() => onDeleteTemplate(false)}
            variant='text'
          >
            Delete template
          </Button>

          <DeleteModal
            className={DELETE_MODAL_CLASSES}
            content={
              <div className='delete-template-content'>
                <p>
                  Please note, this step cannot be undone. If the template is in use for auto-replies
                </p>
                <p>
                  or rules, no further emails or messages will be sent.
                </p>
              </div>
            }
            isOpen={isModalOpen}
            onClose={onCloseModal}
            onConfirm={() => onDeleteTemplate(true)}
            title='Delete template?'
          />
        </div>

        <div className='cf-template-and-subject-inputs-block'>
          <FormInput
            id={FieldIds.Name}
            label='Template Name'
            value={values[FieldIds.Name]}
            error={errors[FieldIds.Name]}
            touched={touched[FieldIds.Name]}
            onChange={onChange}
            onBlur={onBlur}
            dataTestId='email-template-name-field'
            maxLength={ValidationLength.TEMPLATE_NAME_LENGTH + 1}
          />

          <FormInput
            id={FieldIds.Subject}
            label='Subject'
            value={values[FieldIds.Subject]}
            error={errors[FieldIds.Subject]}
            touched={touched[FieldIds.Subject]}
            onChange={onChange}
            onBlur={onBlur}
            dataTestId='email-template-subject-field'
          />
        </div>

        <div className='cf-email-editor'>
          <EditorComponent
            description={values.body as EditorState}
            customDecorators={decorators}
            onChange={onEditorChange}
            toolbarCustomButtons={customButtons}
          />
        </div>
      </div>

      <div className='cf-button-container'>
        <CancelSubmitActionsFooter
          isDisabled={isDisabledActionBtn}
          isLoading={isFormSubmitting}
          onSubmit={onSubmit}
          onClose={onCancel}
          submitTitle='SAVE'
        />
      </div>
    </div>
  )
}

export default observer(EmailTemplateForm)
