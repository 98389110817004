import { css } from '@emotion/css'

export default css(`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(124px, 1fr));
  column-gap: 16px;
  row-gap: 16px;
  min-height: 262px;
  
  .cf-item-wrapper {
    > div {
      display: none;
    }
  }
`)
