import { css } from '@emotion/css'

export const TOOLTIP_CLASS_NAME = css(`
  margin-left: 16px;
  marging-bottom: 4px;
`)

export default css(`
  margin-right: 8px;

  &, & > div {
    height: 24px;
  }

  .cf-priority-main-img {
    cursor: pointer;
    border-radius: 50%;
  }

  // Return if hover background is needed

  // .cf-priority-main-img:hover {
  //   background: #21212114;
  // }

  // &.is-active {
  //   .cf-priority-main-img:hover {
  //     background: #FFF7DF;
  //   }
  // }
`)
