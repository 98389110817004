export interface ObjectWithId {
  id: string | number
}

export const VEHICLE_SLUG = 'vehicle'
export const DEALER_SLUG = 'dealer'

export const linkToCar = (
  linkToWebsite: string,
  car: ObjectWithId
): string | null => (
  linkToWebsite == null
    ? null
    : `${linkToWebsite}/${VEHICLE_SLUG}/${car.id}`.replace(`//${VEHICLE_SLUG}`, `/${VEHICLE_SLUG}`)
)

export const getVideoLinkToWebsite = (
  linkToWebsite: string,
  vehicleId: string,
  videoId: string
): string => {
  return `${linkToWebsite}/vehicle/${vehicleId ?? ''}/video/${videoId ?? ''}`.replace(`//${DEALER_SLUG}`, `/${DEALER_SLUG}`)
}
