import { type FC, useState, useEffect, type ReactNode } from 'react'
import { FormDropdown } from '@carfluent/common'

import type { DictionaryItem } from 'api/types'
import useUsersList from 'hooks/useUsersList'
import { getDisplayItem } from 'utils/selectHelpers'
import type { PickedFiltersProps } from 'pages/reports/ReportGrossProfit/components/Filters'

import { CLASS_NAME, POPOVER_CLASS_NAME } from './styles'

export interface BuyerFilterProps {
  setFilters: PickedFiltersProps['setFilters']
}

const buyerFilterTitle = 'Buyer/salesperson'

const BuyerFilter: FC<BuyerFilterProps> = ({ setFilters }) => {
  const users = useUsersList()
  const [selectedItems, setSelectedItems] = useState<DictionaryItem[]>([])

  const isAllSelected = selectedItems.length === users.length
  const isNoneSelected = selectedItems.length === 0

  const onChange = (_: string, v: DictionaryItem[] | null): void => {
    const newSelectedItems = v ?? []

    setSelectedItems(newSelectedItems)
    /**
     * AS-NOTE:
     * Intentionally set userIds as null to get all users
     */
    const userIds = newSelectedItems.length === users.length || newSelectedItems.length === 0
      ? null
      : newSelectedItems.map(({ id }) => id)

    void setFilters({ userIds }, true)
  }

  const renderSelectDisplay = (): ReactNode => {
    if (isNoneSelected) {
      return buyerFilterTitle
    }

    const txt = `${buyerFilterTitle} (${isAllSelected ? 'All' : `${selectedItems.length}`})`
    return getDisplayItem(txt)
  }

  useEffect(() => {
    setSelectedItems(users)
  }, [users])

  return (
    <div className={CLASS_NAME}>
      <div className='action-bar'>
        <FormDropdown
          className='buyer-filter'
          id='buyer'
          isMultiselect
          options={users}
          value={selectedItems}
          popoverClassName={POPOVER_CLASS_NAME}
          onChange={onChange}
          disableClearable={isAllSelected}
          renderSelectDisplay={renderSelectDisplay}
        />
      </div>
    </div>
  )
}

export default BuyerFilter
