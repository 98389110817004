import { css } from '@emotion/css'

export default css(`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 2px solid #F3F3F3;
  background: #F3F3F3;

  & table > tbody > tr:first-of-type {
    border-top: none;
  }

  &.bank-statement-matches .cf-table-wrapper {
    border-radius: 2px 2px 0 0;
  }

  & .cf-table-wrapper tbody tr td:first-child > div.cf-cell-wrapper {
    border-left: 4px solid transparent;
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  &.bank-statement-matches .cf-table-wrapper table tbody tr td .cf-cell-wrapper {
    height: 70px;
  }

  .cell-responsive-text {
    .cf-table-cell-text {
      width: 100%;
      display: table; // for responsive ellipsis
      table-layout: fixed;
      white-space: nowrap;
      
      p {
        display: table-cell; // for responsive ellipsis
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .cf-transaction-cell {
    .cf-button-root {
      text-decoration: none;
    }

    .cf-button-content {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
      color: #458FFF;
    }
  }

  .button-action {
    height: 26px;
  }
`)
