import { FC, useMemo } from 'react'
import type { Row } from '@tanstack/react-table'

import Table from 'components/common/Table'
import { type CostDetailsListItem } from 'hooks/useCostDetails'

import { columnCostsDetailsDefinitions } from '../../columns'
import { createStyleClass } from './styles'

export interface CostsDetailsProps {
  onAddJournal: () => void
  onRowClick: (row: Row<CostDetailsListItem>) => void
  items: CostDetailsListItem[]
}

const CostsDetails: FC<CostsDetailsProps> = ({
  items,
  onAddJournal,
  onRowClick
}) => {
  const tableCls = useMemo(createStyleClass, [])

  return (
    <>
      <div className='cf-cost-details-table' data-test-id='list-cost'>
        <Table
          classes={{ table: tableCls }}
          columns={columnCostsDetailsDefinitions}
          onAddRow={onAddJournal}
          onRowClick={onRowClick}
          embedAddButton
          emptyTableMessage='There are no details yet'
          addRowLabel='Add journal entry'
          data={items}
          addBtnTestId='btn-add-journal-entry'
        />
      </div>
    </>
  )
}

export default CostsDetails
