import { BankStatementTransactionType } from 'api/types'
import { type FiltersFormData } from 'types/filters'

const DEFAULT_TRANSACTION_TYPE = {
  id: BankStatementTransactionType.All,
  name: 'All transactions'
}

export const GET_DEFAULT_FILTERS = (): FiltersFormData => ({
  amount: { from: null, to: null, equal: null },
  transactionType: { ...DEFAULT_TRANSACTION_TYPE },
  date: { from: null, to: null }
})

export const BANK_STATEMENT_TRANSACTION_TYPES = [
  { ...DEFAULT_TRANSACTION_TYPE },
  { id: BankStatementTransactionType.Deposit, name: 'Deposit' },
  { id: BankStatementTransactionType.Withdrawal, name: 'Withdrawal' }
]
