import { type ReactNode, useMemo, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { cnx, Table, Button, type TableProps, type ColumnDef } from '@carfluent/common'
import CloseIcon from '@material-ui/icons/Add'

import LoadingBasicCell from './loading_cells/basic'
import LoadingTwoLinesCell from './loading_cells/two_lines'
import LoadingRectangleWithTwoLinesCell from './loading_cells/rectangle_with_two_lines'
import LoadingCircleImageWithLineCell from './loading_cells/CircleImageWithLine'

import BigSkeletonLineCell from './loading_cells/BigSkeletonLine'
import ExtremelyBigSkeletonLine from './loading_cells/ExtremelyBigSkeletonLine'
import CLASS_NAME from './styles'

type BaseTableProps<T, M> = Omit<TableProps<T, M>, 'classes'>

type LoadableTableClasses = TableProps<{}, {}>['classes'] & {
  newRow?: string
  noDataBlock?: string
  skeletonRow?: string
}

export interface LoadableTableProps<T, M = string> extends BaseTableProps<T, M> {
  classes?: LoadableTableClasses
  emptyTableMessage?: string
  isLoading?: boolean
  isTabChanges?: boolean
  onAddRow?: () => void
  onBottomReached?: () => Promise<void>
  embedAddButton?: boolean
  addRowLabel?: string
  addBtnTestId?: string
  loadingRows?: any[]
  containerElement?: HTMLDivElement | null
}

const DEFAULT_LOADING_ROWS = Array(5).fill({})

const getLoadingCell = (type?: string): ReactNode => {
  switch (type) {
    case 'no-skeleton' : {
      return <LoadingBasicCell noSkeleton />
    }
    case 'two_lines': {
      return <LoadingTwoLinesCell />
    }
    case 'big_line': {
      return <BigSkeletonLineCell />
    }
    case 'extremely_big_line': {
      return <ExtremelyBigSkeletonLine />
    }
    case 'rectangle_with_two_lines': {
      return <LoadingRectangleWithTwoLinesCell />
    }
    case 'two_lines_different_length': {
      return <LoadingRectangleWithTwoLinesCell isImageSkeletonDisplayed={false} />
    }
    case 'circle_image_with_line': {
      return <LoadingCircleImageWithLineCell />
    }
    default: {
      return <LoadingBasicCell />
    }
  }
}

export const LoadableTable = <T = unknown>(props: LoadableTableProps<T>): JSX.Element => {
  const {
    classes,
    columns,
    data,
    emptyTableMessage = 'No items found',
    groupBy,
    isLoading = false,
    isTabChanges = false,
    onAddRow,
    embedAddButton = false,
    addRowLabel = 'New Line',
    renderGroup: _renderGroup,
    addBtnTestId,
    loadingRows,
    withFixedCell,
    ...restProps
  } = props

  const resolvedClasses = useMemo(() => ({
    wrapper: cnx(CLASS_NAME, classes?.wrapper),
    table: classes?.table,
    header: classes?.header,
    headerCell: classes?.headerCell,
    headerTitle: classes?.headerTitle,
    row: classes?.row,
    groupRow: classes?.groupRow,
    groupCell: classes?.groupCell,
    cell: classes?.cell
  }), [classes])

  const resolvedClassesForLoadingTable = useMemo(() => ({
    wrapper: 'skeleton-table-wrapper',
    row: cnx('cf-row', 'cf-skeleton-row', classes?.skeletonRow),
    table: cnx('cf-table', classes?.table)
  }), [classes])

  const renderGroup = useMemo(() => {
    return groupBy != null ? _renderGroup : undefined
  }, [groupBy, _renderGroup])

  const loadingTableColumns = useMemo(() => {
    const processColumns = (cols: Array<ColumnDef<T>>): Array<ColumnDef<T>> => {
      return cols.map(c => {
        if (c.columns != null) {
          return {
            ...c,
            columns: processColumns(c.columns)
          }
        } else {
          return {
            ...c,
            cell: getLoadingCell(c.loading) as typeof c.cell
          }
        }
      })
    }

    return processColumns(columns)
  }, [columns])
  const isEmpty = data.length === 0 && !isLoading && emptyTableMessage !== ''

  const renderAddButton = useCallback((): ReactNode => {
    if (onAddRow == null) {
      return null
    }

    return (
      <div className='add-new-row'>
        <Button
          onClick={onAddRow}
          startAdornment={<CloseIcon />}
          variant='outlined'
          dataTestId={addBtnTestId}
        >
          {addRowLabel}
        </Button>
      </div>
    )
  }, [onAddRow, addRowLabel, addBtnTestId])

  const renderLoader = useCallback(() => {
    return isLoading
      ? (
        <Table
          hideHeader
          withFixedCell={withFixedCell}
          columns={loadingTableColumns}
          data={loadingRows ?? DEFAULT_LOADING_ROWS}
          classes={resolvedClassesForLoadingTable}
        />
        )
      : null
  }, [isLoading, loadingTableColumns, resolvedClassesForLoadingTable])

  const renderEmptyBlock = useCallback(() => {
    return isEmpty
      ? (
        <div className={cnx('cf-no-data-block', restProps.hideHeader && 'hide-header', classes?.noDataBlock)}>
          <p>{emptyTableMessage}</p>
        </div>
        )
      : null
  }, [isEmpty, emptyTableMessage])

  if (isEmpty && embedAddButton) {
    return (
      <>
        <div className='cf-empty-embedded-block-wrapper'>
          <div className='cf-empty-embedded-block-content'>
            <p className='cf-empty-embedded-block-message'>{emptyTableMessage}</p>
          </div>
        </div>
        {renderAddButton()}
      </>
    )
  }

  if (isTabChanges) {
    return (
      <Table
        columns={loadingTableColumns}
        withFixedCell={withFixedCell}
        classes={resolvedClassesForLoadingTable}
        data={loadingRows ?? DEFAULT_LOADING_ROWS}
      />
    )
  }

  return (
    <Table<T, string>
      {...restProps}
      classes={resolvedClasses}
      columns={columns}
      data={data}
      renderLoader={renderLoader}
      groupBy={groupBy}
      renderGroup={renderGroup}
      withFixedCell={withFixedCell}
      renderEmptyBlock={renderEmptyBlock}
      renderEmbeddedActionButton={renderAddButton}
    />
  )
}

export default observer(LoadableTable)
