import { useCallback, useRef, useState, useEffect } from 'react'
import { useAutocompleteInfiniteList } from '@carfluent/common'
import type { FocusEvent } from 'react'
import type { AutocompleteInputChangeReason } from '@material-ui/lab'
import type { FetchOptions } from '@carfluent/common/dist/hooks'

import type { BaseListItem, Nullable } from 'types'
import type { PaginatedResult } from 'api/types'

export interface UseDropdownProps<T extends Nullable<BaseListItem>> {
  id: string
  formatInputValue?: (value: T | null) => string
  getItemsList: (config: FetchOptions) => Promise<PaginatedResult<T>>
  onBlur?: (evt: FocusEvent<HTMLDivElement>) => void
  onInputChange?: (id: string, value: string, reason: AutocompleteInputChangeReason) => void
  value: T | null
}

export interface UseDropdownReturn<T extends Nullable<BaseListItem>> {
  inputValue: string
  isLoading: boolean
  onBlur: (evt: FocusEvent<HTMLDivElement>) => void
  onInputChange: (id: string, value: string, reason: AutocompleteInputChangeReason) => void
  onScroll: (search?: string, isRefreshing?: boolean) => Promise<void>
  options: T[]
}

/**
 * A convenient wrapper on `useAutocompleteInfiniteList` that provides list of properties,
 * that are compatible with `components/forms/Dropdown`.
 *
 * Includes infinite list loading, and returns its' props in renamed form (for convenient use with dropdown).
 * Also provides formatting of the inputValue (no formatting by default).
 *
 * AZ-TODO: add `preloadedItems` and `combineItems` (check boilerplate in Pack/Floorplan to se why).
 */
const useDropdown = <T extends Nullable<BaseListItem>>({
  id,
  formatInputValue = defaultFormatName,
  getItemsList,
  onBlur: _onBlur,
  onInputChange: _onInputChange,
  value
}: UseDropdownProps<T>): UseDropdownReturn<T> => {
  const refValue = useRef(value)
  const [inputValue, setInputValue] = useState(formatInputValue(value))

  const infiniteListProps = useAutocompleteInfiniteList({
    propIdName: id,
    fetch: getItemsList
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onBlur = useCallback((evt: FocusEvent<HTMLDivElement>) => {
    setInputValue(formatInputValue(refValue.current))
    _onBlur?.(evt)
  }, [_onBlur, formatInputValue])

  const onInputChange = useCallback((id: string, value: string, reason: AutocompleteInputChangeReason) => {
    setInputValue(value)
    infiniteListProps.setSearch(value)
    _onInputChange?.(id, value, reason)
  }, [_onInputChange, infiniteListProps.setSearch])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    refValue.current = value
    setInputValue(formatInputValue(refValue.current))
  }, [value])

  // ========================================== //

  return {
    inputValue,
    isLoading: infiniteListProps.isLoading,
    onBlur,
    onInputChange,
    onScroll: infiniteListProps.getItems,
    options: infiniteListProps.items
  }
}

export default useDropdown

// ========================================== //

const defaultFormatName = (item: Nullable<BaseListItem> | null): string => {
  return item?.name ?? ''
}
