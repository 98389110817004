import { css } from '@emotion/css'

export default css(`
  opacity: 0px;
  margin: 16px 0;
  background: #fff;
  border-radius: 12px;
  padding: 20px 16px 16px 16px;

  .MuiSkeleton-text {
    height: 16px;
  }
  
  .timeline-header-skeleton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    > .MuiSkeleton-text {
      max-width: 100px;
      margin-bottom: 20px;
    }

    .comment-skeleton {
      flex: 1;
      display: flex;
      margin-bottom: 24px;
      align-items: flex-end;
      flex-direction: column;

      .MuiSkeleton-text {
        width: 100%;
        height: 100px;
        margin-bottom: 16px;
      }

      .comment-btn-skeleton {
        font-size: 14px;
        font-weight: 500;
        color: #00000030;
        line-height: 16px;
        padding: 5px 18px;
        border-radius: 8px;
        letter-spacing: 1.25px;
        border: 1px solid #00000030;
      }
    }
  }

  .timeline-record-skeleton {
    .MuiSkeleton-text {
      margin-bottom: 16px;
    }

    > div {
      :first-of-type {
        width: 200px;
      }
  
      :nth-of-type(3) {
        width: calc(100% - 73px);
      }
  
      :nth-of-type(4) {
        width: calc(100% - 112px);
      }
  
      :nth-of-type(5) {
        width: calc(100% - 158px);
      }
    }
  }
`)
