export enum LogActivityButtonsNames {
  ScheduleCall = 'Call',
  ScheduleAppointment = 'Appt',
  ScheduleReminder = 'Reminder',
  ContactEmail = 'Email',
  ContactMessage = 'Message',
  LogCall = 'Call',
  LogStoreVisit = 'Store visit',
  LogOther = 'Other'
}
