import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;

  .name-and-temperature-label-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    p { 
      font-weight: 500;
      letter-spacing: 0.4px;
      margin-right: 4px; 
    }
  }

  .email-wrapper {
    display: flex;
    align-items: center;
  }
`)
