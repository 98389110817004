import { css } from '@emotion/css'

export default css(`
  --gallery-big-image-padding: 32px;
  --gallery-bottom-padding: 72px;
  --gallery-side-margin: 0px;
  --arrow-margin: 2.5%;
  --gallery-header: 72px;
  --thumbnail-section: 66px;
  --arrows-full-width: 80px;
  --gallery-arrow-size: 32px;

  .cf-modal-content-scroll-wrapper {
    width: 100% !important;
    overflow: hidden !important;
    padding: 0 !important;
    
    .cf-modal-root {
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      background: rgba(0, 0, 0, .6);
      backdrop-filter: blur(7px);
      
      .cf-modal-content {
        background: transparent;
      }
    }
  }
  
  .cf-gallery-sections {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin: var(--gallery-header) auto 0px;
    max-width: calc(100% - (var(--gallery-arrow-size) + var(--arrow-margin) * 2) * 2);
    height: calc(100vh - var(--gallery-header) - var(--gallery-bottom-padding));
    
    .cf-full-image-section {
      height: calc(100% - var(--thumbnail-section));
      padding-bottom: var(--gallery-big-image-padding);
      margin: auto;
      box-sizing: border-box;
  
      .cf-gallery-arrow {
        display: flex;
        background: rgba(176, 176, 176, 0.6);
        
        &.left {
          left: calc(-1 * (var(--gallery-arrow-size) + var(--arrow-margin)));
        }
  
        &.right {
          right: calc(-1 * (var(--gallery-arrow-size) + var(--arrow-margin)));
        }
      }
  
      .cf-gallery-item {
        max-height: calc(100% - var(--gallery-header));
      }
    }
  
    .cf-thumbnails-section {
      display: block;
      height: var(--thumbnail-section);
      
      .gallery-container {
        max-width: calc(100% - var(--arrows-full-width));
        margin: 0 auto;
      }
      
      .cf-gallery-arrow {
        background: rgba(176,176,176, 0.6);
      }
    }
  }
`)
