import type { RecurringEntryRowData, RecurringEntryFromApi } from './types'

const parseListData = (data: RecurringEntryFromApi[]): RecurringEntryRowData[] => {
  return data.map(parseRowData)
}

const parseRowData = (data: RecurringEntryFromApi): RecurringEntryRowData => {
  return {
    ...data,
    createdDate: data.createdDate != null ? new Date(data.createdDate) : null
  }
}

export default parseListData
