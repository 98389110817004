import { toDateOrNull } from 'utils/parse_date'
import type { BankStatementRowData, BankStatementFromApi } from './types'

const parseListData = (data: BankStatementFromApi[], isForceRefresh: boolean): BankStatementRowData[] => {
  const lastLoadTS: number = Math.random() // AZ-NOTE: it seems, Date.now sometime is not fast enough
  return data.map((x) => parseRowData(x, lastLoadTS, !isForceRefresh))
}

const parseRowData = (
  data: BankStatementFromApi,
  lastLoadTS: number,
  isLazyLoadReason: boolean
): BankStatementRowData => {
  return {
    ...data,
    date: toDateOrNull(data.date),
    lastLoadTS,
    isLazyLoadReason
  }
}

export default parseListData
