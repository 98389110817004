import { css } from '@emotion/css'

export default css(`
  position: relative;
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);

  > .section-loader {
    border-radius: 12px;
  }

  .attachments-divider {
    margin: 16px 0;
    height: 1px;
    width: 100%;
    background: rgba(0, 0, 0, 0.12);
  }

  .email-attachments-top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .title {
      color: rgba(33, 33, 33, 0.50);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .actions {
      display: flex;
      align-items: flex-start;
      
      .cf-button-root.cf-button-variant-text {
        text-underline-position: auto;

        .cf-button-content {
          color: #101010;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.15px;
        }
      }

      .is-disabled {
        .cf-button-root.cf-button-variant-text {
          cursor: default;
          color: rgba(33, 33, 33, 0.40);

          .cf-button-content {
            color: rgba(33, 33, 33, 0.40);
          }
        }
      }

      .attach-inventory-photo-button {
        margin-right: 16px;
      }
    }
  }
`)
