import type { CoverageDefault, CoverageDefaultSection, CoverageType } from 'types/coverage'
import { CoverageProductTypeIds, type DealerProductsDto } from 'api/types/responses'
import { generateCoverageSectionsData } from 'api/defaults/coverageDefault'

const DEFAULT_FORM_DATA = Array(5).fill(null).map(generateCoverageSectionsData)

const parseCoverageDetails = ({ penProducts, defaults, isCashPayment }: DealerProductsDto): CoverageDefaultSection[] => {
  const coverageDetails = DEFAULT_FORM_DATA.map(defaultProduct => {
    const currentCoverage = defaults.find(el => el.productTypeId === defaultProduct.productTypeId)
    const currentPenProducts = penProducts.find(el => el.productTypeId === defaultProduct.productTypeId)
    const hasPenCoverages = (currentPenProducts?.providers ?? []).length > 0
    const coverageType = (hasPenCoverages && currentCoverage?.deductibleId != null) ? 'PEN' : 'Manual'
    let currentCoverageId: string | number | null = null

    if (currentCoverage == null) {
      const defaultCoverageType = (hasPenCoverages ? 'PEN' : 'Manual') as CoverageType

      return ({
        ...defaultProduct,
        productTypeId: defaultProduct.productTypeId,
        coverageType: defaultCoverageType,
        isPenEnabled: hasPenCoverages,
        forms: {
          PEN: hasPenCoverages
            ? {
                ...defaultProduct.forms.PEN as CoverageDefault,
                productTypeId: defaultProduct.productTypeId
              }
            : null,
          Manual: {
            ...defaultProduct.forms.Manual,
            productTypeId: defaultProduct.productTypeId
          }
        }
      })
    }

    if (coverageType === 'PEN') {
      const coverageId = currentPenProducts?.providers.find(el =>
        el.id === currentCoverage.providerId
      )?.packages.find(el => el.packageName === currentCoverage.coverageName)
        ?.coverages.find(coverage => Number(coverage.termMiles) === currentCoverage.termMiles && Number(coverage.termMonths) === currentCoverage.termMonths)?.id ?? null

      currentCoverageId = coverageId != null ? Number(coverageId) : null
    }

    return ({
      ...defaultProduct,
      coverageType: coverageType as CoverageType,
      isPenEnabled: hasPenCoverages,
      coverageId: currentCoverage.id,

      forms: {
        PEN: coverageType === 'Manual'
          ? null
          : {
              id: currentCoverage.id,
              cost: currentCoverage.cost,
              termMiles: currentCoverage.termMiles,
              termMonths: currentCoverage.termMonths,
              totalPrice: currentCoverage.totalPrice,
              providerId: currentCoverage.providerId,
              coverageName: currentCoverage.coverageName,
              productTypeId: currentCoverage.productTypeId,
              isFormDisabled: currentCoverage.providerId === null,
              deductibleAmount: currentCoverage.deductibleAmount,
              productCoverageId: currentCoverageId,
              deductibleId: currentCoverage.deductibleId,
              dealerRetailPrice: null
            },
        Manual: coverageType === 'Manual'
          ? {
              id: currentCoverage.id,
              cost: currentCoverage.cost,
              termMiles: currentCoverage.termMiles,
              termMonths: currentCoverage.termMonths,
              totalPrice: currentCoverage.totalPrice,
              providerId: currentCoverage.providerId,
              coverageName: currentCoverage.coverageName,
              productTypeId: currentCoverage.productTypeId,
              deductibleAmount: currentCoverage.deductibleAmount,
              isFormDisabled: currentCoverage.providerId === null,
              productCoverageId: null,
              deductibleId: currentCoverage.deductibleId,
              dealerRetailPrice: null
            }
          : defaultProduct.forms.Manual
      }
    })
  }).filter(el => {
    return !isCashPayment || el.productTypeId !== CoverageProductTypeIds.GapInsurance
  })

  return coverageDetails
}

export default parseCoverageDetails
