import { type FC, useMemo } from 'react'
import { cnx } from '@carfluent/common'

import Table from 'components/common/Table'
import getColumns from './columns'
import { type BankStatementMatchesProps } from './types'
import CLASS_NAME from './styles'

const BankStatementMatches: FC<BankStatementMatchesProps> = ({
  className,
  mode,
  onClearMethodClick,
  onViewTransaction,
  rows
}) => {
  const columns = useMemo(() => getColumns({
    mode,
    onClearMethodClick,
    onViewTransaction
  }), [
    mode,
    onClearMethodClick,
    onViewTransaction
  ])

  return (
    <div className={cnx(CLASS_NAME, 'bank-statement-matches', className)}>
      <Table
        data={rows}
        columns={columns}
        hideHeader
      />
    </div>
  )
}

export default BankStatementMatches
