import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

import CLASS_NAME from './styles'

const ROWS_ITEMS = new Array(4).fill(null)

const Loader: FC = () => {
  return (
    <div className={cnx('g-submenu-content g-margin-top-big', CLASS_NAME)}>
      <div className='title-and-button'>
        <h5>F&I settings</h5>
      </div>

      <div className='main-container'>
        <div>
          <div className='title-with-button'>
            <TextSkeleton className='title' />

            <TextSkeleton className='button' />
          </div>

          <div className='package-customization'>
            <div className='grid-row'>
              <div>
                <TextSkeleton className='simple-text header' />
              </div>

              <TextSkeleton className='label' />

              <TextSkeleton className='label' />

              <TextSkeleton className='label' />
            </div>

            {ROWS_ITEMS.map((_, i) => {
              return (
                <div key={i} className='grid-row body'>
                  <div className='name'>
                    <TextSkeleton className='simple-text' />
                    <TextSkeleton className='simple-text' />
                  </div>

                  <div>
                    <TextSkeleton className='checkbox' />
                  </div>

                  <div>
                    <TextSkeleton className='checkbox' />
                  </div>

                  <div>
                    <TextSkeleton className='checkbox' />
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div>
          <TextSkeleton className='title' />

          <div className='blocks-wrapper'>
            <TextSkeleton className='template-item' />
            <TextSkeleton className='template-item' />
            <TextSkeleton className='template-item' />
            <TextSkeleton className='template-item' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loader
