import { useMemo } from 'react'

import { type DealerProductDeductibleDto } from 'api/types/responses'
import type { CoverageDefaultSection, CoverageProvider, CoverageTerm, CoverageWithTerms } from 'types/coverage'

import { getSelectedCoverageName, getSelectedTerm, getSelectedVendor } from '../utils'
import { DEDUCTIBLE_OPTIONS } from './constants'

export interface UseCoverageFormReturn {
  disabledPen: boolean
  termList: CoverageTerm[]
  selectedTerm: CoverageTerm | null
  filteredVendors: CoverageProvider[]
  currentPackage: CoverageWithTerms[]
  selectedVendor: CoverageProvider | null
  currentCoverage: CoverageDefaultSection
  deductibles: DealerProductDeductibleDto[]
  selectedCoverage: CoverageWithTerms | null
  selectedDeductible: DealerProductDeductibleDto | null
}

export interface UseCoverageFormProps {
  idx: number
  isDealForm: boolean
  penPackages: CoverageProvider[]
  coverageSections: CoverageDefaultSection[]
}

const useCoverageForm = ({
  idx,
  isDealForm,
  penPackages,
  coverageSections
}: UseCoverageFormProps): UseCoverageFormReturn => {
  const currentCoverage = coverageSections?.[idx]

  const currentPackage = useMemo(() => {
    return penPackages?.find(el => el.id === currentCoverage.forms[currentCoverage.coverageType]?.providerId)?.coverages ?? []
  }, [penPackages, currentCoverage])

  const selectedVendor = useMemo(() => {
    return getSelectedVendor(penPackages, currentCoverage?.forms[currentCoverage.coverageType]?.providerId)
  }, [penPackages, currentCoverage?.forms[currentCoverage.coverageType]?.providerId])

  const selectedCoverage = useMemo(() => {
    return getSelectedCoverageName(currentPackage, currentCoverage?.forms.PEN?.coverageName)
  }, [currentPackage, currentCoverage?.forms.PEN?.coverageName])

  const termList = selectedCoverage?.termList ?? []

  const selectedTerm = useMemo(() => {
    return getSelectedTerm(termList, currentCoverage.forms.PEN?.productCoverageId)
  }, [termList, currentCoverage.forms.PEN?.productCoverageId])

  const filteredVendors = useMemo(() => {
    return penPackages?.filter(el => el.coverages.length)
  }, [penPackages])

  const deductibles = useMemo(() => {
    if (isDealForm) {
      return selectedTerm?.deductibles ?? []
    }

    return DEDUCTIBLE_OPTIONS
  }, [selectedTerm?.deductibles, currentCoverage.forms.PEN, isDealForm])

  const selectedDeductible = useMemo(() => {
    if (isDealForm) {
      return deductibles.find(el => {
        return el.id === currentCoverage.forms.PEN?.deductibleId
      }) ?? null
    }

    return deductibles.find(el => {
      return el.amount === currentCoverage.forms.PEN?.deductibleAmount
    }) ?? null
  }, [deductibles, currentCoverage.forms.PEN?.deductibleId, currentCoverage.forms.PEN?.deductibleAmount])

  return {
    termList,
    deductibles,
    selectedTerm,
    currentPackage,
    selectedVendor,
    currentCoverage,
    filteredVendors,
    selectedCoverage,
    selectedDeductible,
    disabledPen: !currentCoverage.isPenEnabled
  }
}

export default useCoverageForm
