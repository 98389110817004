export interface FilterOption<T extends string | number> {
  id: T | null
  name: string
  serverName?: string
  dataTestId?: string
}

const getFilterCounter = <T extends string | number>(filter: FilterOption<T>, counters: Record<string, number>): number => {
  if (filter.id !== null) {
    if (typeof filter.id === 'string') {
      return counters[filter.id]
    }

    if (typeof filter.id === 'number') {
      return counters[(filter.serverName ?? filter.name).split(' ').join('').toLocaleLowerCase()] ?? counters[filter.id] ?? 0
    }
  }

  /**
   * Counter "All" clause.
   */
  const { deposited, ...otherCounters } = counters

  return Object.values(otherCounters).reduce((a, c) => a + c, 0)
}

export default getFilterCounter
