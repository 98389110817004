import { type FC, useEffect, useRef, useState, memo } from 'react'
import { Button, cnx } from '@carfluent/common'

import { TimelineLogTypes } from 'api/types'
import MultilineTextEllipsis from 'components/common/MultilineTextEllipsis'
import TruncateWithShowMore from 'components/common/TruncatedShowMoreButton'

import MultilineContent from './components/MultilineContent'
import ItemHeader from './components/ItemHeader'
import { type ItemProps } from './types'
import { getMaxVisibleContentHeight, getVisibleLines, isGreyedRecord } from './utils'
import CLASS_NAME from './styles'

const EXCLUDED_ITEM_STATUSES = [
  TimelineLogTypes.LeadAutomaticallyCreated,
  TimelineLogTypes.LeadAssigned,
  TimelineLogTypes.CarOfInterestAdded,
  TimelineLogTypes.CarOfInterestIsDeposited
]

const Item: FC<ItemProps> = ({
  id,
  logTypeId,
  emailId,
  dealId,
  vehicleId,
  date,
  title,
  name,
  clickedAction,
  openedAction,
  salesperson,
  additionalHeaderData,
  textItems,
  activityCompleteSubOptions,
  activityCompleteOption,
  feedbackActions,
  isWebchat,
  onOpenMessages,
  setWebChatInfo,
  leadDetails,
  threadId,
  showThreadBtnProps: {
    isSubmitting: isSubmittingEmailThread,
    onLoadEmailThread,
    activeTimelineEmailId: activeEmailId
  } = {}
}) => {
  const isEmail = [
    TimelineLogTypes.EmailReceived,
    TimelineLogTypes.EmailSent,
    TimelineLogTypes.EmailSentAutomatically
  ].includes(logTypeId)

  const isMessage = [
    TimelineLogTypes.MessageReceived,
    TimelineLogTypes.MessageSent,
    TimelineLogTypes.MessageSentAutomatically
  ].includes(logTypeId)

  const labelValue = additionalHeaderData.join(', ')
  const isInSubmittingState = (isSubmittingEmailThread === true) && (activeEmailId === emailId)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [showToggleButton, setShowToggleButton] = useState(false)

  const hasMultilineContent = (TimelineLogTypes.LeadAssigned !== logTypeId) &&
    (TimelineLogTypes.CarOfInterestAdded !== logTypeId) &&
    (TimelineLogTypes.CarOfInterestIsDeposited !== logTypeId)

  const isExcludedItem = EXCLUDED_ITEM_STATUSES.includes(logTypeId)

  const onOpenEmailThread = async (): Promise<void> => {
    if (emailId != null) {
      await onLoadEmailThread?.(emailId)
    }
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if ((contentRef.current != null) && (contentRef.current.scrollHeight > getMaxVisibleContentHeight(logTypeId))) {
      setShowToggleButton(true)
    }
  }, [])

  // ========================================== //

  const multilineContent = (
    hasMultilineContent
      ? (
        <MultilineContent
          activityCompleteOption={activityCompleteOption}
          activityCompleteSubOptions={activityCompleteSubOptions}
          clickedAction={clickedAction}
          dealId={dealId}
          feedbackActions={feedbackActions}
          isMessage={isMessage}
          labelValue={labelValue}
          logTypeId={logTypeId}
          name={name}
          openedAction={openedAction}
          salesperson={salesperson}
          textItems={textItems}
          vehicleId={vehicleId}
        />
        )
      : null
  )

  return (
    <div className={cnx(CLASS_NAME, isGreyedRecord(logTypeId) && 'greyed-record')}>
      <ItemHeader
        date={date}
        logTypeId={logTypeId}
        name={name}
        title={title}
      />

      {!isExcludedItem && (
        <div className={cnx('timeline-item-wrapper', `${title.split(' ').join('-').toLowerCase()}`)}>
          <div className='timeline-item-content' ref={contentRef}>
            {logTypeId === TimelineLogTypes.CreditAppReceived
              ? (
                <TruncateWithShowMore>
                  {multilineContent}
                </TruncateWithShowMore>
                )
              : (
                <MultilineTextEllipsis
                  key={id}
                  isCollapsible={!isEmail && !isMessage && showToggleButton && !isWebchat}
                  maxLines={getVisibleLines(logTypeId)}
                  showToggleButton={!isEmail && !isMessage && showToggleButton && !isWebchat}
                  initiallyCollapsed={isWebchat}
                >
                  {multilineContent}
                </MultilineTextEllipsis>
                )
            }

            {/* AZ-TODO: move to <ItemActions /> component */}

            {isWebchat && (
              <div className='show-btn-wrapper'>
                <Button
                  variant='text'
                  onClick={() => setWebChatInfo?.({
                    id: threadId,
                    firstName: leadDetails?.firstName,
                    lastName: leadDetails?.lastName
                  })}
                >
                  Show conversation
                </Button>
              </div>
            )}

            {isEmail && (
              <div className='show-btn-wrapper'>
                <Button
                  isLoading={isInSubmittingState}
                  isDisabled={isInSubmittingState}
                  variant='text'
                  onClick={onOpenEmailThread}
                >
                  Show thread
                </Button>
              </div>
            )}

            {isMessage && (onOpenMessages != null) && (
              <div className='show-btn-wrapper'>
                <Button
                  variant='text'
                  onClick={onOpenMessages}
                >
                  Show chat
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(Item)
