// AZ-TODO: move to "enums.ts"

export enum PaymentStatusId {
  Pending,
  Processing,
  Completed
}

export enum DealWorkflowAccountingStateId {
  Sold = 0,
  PushedToAccounting = 1,
  Recorded = 2
}
