import type { PopoverProps } from '@carfluent/common/dist/UI'

export const MAX_MESSAGE_LENGTH = 600
export const MAX_SYMBOLS_REMINDER_DURATION = 2000
export const MAX_ROWS_TEXTAREA = 6
export const FILE_PREFIX = 'file_'
export const PHOTO_PREFIX = 'photo_'

export enum KeyCode {
  Enter = 'Enter',
  Backspace = 'Backspace'
}

type IconButtons = 'addAttachmentsBtn' | 'addTemplatesBtn' | 'sendBtn'
type TooltipsConfig = Record<IconButtons, Pick<PopoverProps, 'placement' | 'transform' | 'minWidth'>>

export const TOOLTIPS_CONFIG: TooltipsConfig = {
  addAttachmentsBtn: {
    placement: { vertical: 'top', horizontal: 'left' },
    transform: { x: 54, y: -32 },
    minWidth: 110
  },
  addTemplatesBtn: {
    placement: { vertical: 'top', horizontal: 'right' },
    transform: { x: 0, y: -32 },
    minWidth: 174
  },
  sendBtn: {
    placement: { vertical: 'top', horizontal: 'right' },
    transform: { x: 0, y: -18 },
    minWidth: 58
  }
}
