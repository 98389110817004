import type { FC, KeyboardEvent, FocusEvent } from 'react'
import { cnx, FormInput, FormMaskedInput } from '@carfluent/common'
import type { FormErrors, FormTouched } from '@carfluent/common/dist/hooks/useForm/types'

import AddressPicker from 'components/common/AddressAutocomplete'
import type { FullAddressParts } from 'types/address'
import AutocompleteStates from 'components/form/components/state_picker'
import { cyrillicRegex } from 'utils/regex_helper'
import optionalLabel from 'utils/common/optionalLabel'

import { useStyles } from './styles'

const FIELDS = {
  address: 'address',
  city: 'city',
  state: 'state',
  zipCode: 'zipCode',
  apt: 'apt'
}

const DEFAULT_LABEL = 'Street address'

export type Optionals = Partial<Record<keyof typeof FIELDS, boolean>>

export interface AddressFieldsValues extends FullAddressParts {
  apt?: string | null
}

export interface AddressFieldsProps<V extends Record<string, any>> {
  id?: string
  values: V | null
  errors?: string | FormErrors<V> | null
  touched?: FormTouched<V> | null | boolean
  disabled?: boolean
  label?: string
  onBlur?: (evt: FocusEvent<HTMLDivElement | HTMLTextAreaElement>) => void
  onChange: <T>(fieldId: string, value: T) => void
  optionals?: Optionals
  pathPrefix?: string
  fieldsMapping?: Partial<Record<keyof typeof FIELDS, string>>
  shouldHideApt?: boolean
  /**
   * temporary while transitioning to the better AddressPicker.
   * It is convenient to keep apt in the same addressData object and avoid extra mappings or transforms
   */
  useNormalAptKey?: boolean
}

const isFullAddress = (value: unknown): value is AddressFieldsValues => {
  return (
    typeof value === 'object' &&
    value !== null &&
    'address' in value &&
    'city' in value &&
    'state' in value &&
    'zipCode' in value
  )
}

const isTouched = (
  field: keyof FormTouched<AddressFieldsValues>,
  touched?: FormTouched<AddressFieldsValues> | null | boolean
): boolean => {
  return touched == null
    ? false
    : typeof touched === 'boolean'
      ? touched
      : touched[field] === true || touched.address === true
}

const AddressFields: FC<AddressFieldsProps<AddressFieldsValues>> = ({
  id = 'addressData',
  values,
  touched,
  errors,
  disabled,
  label,
  onChange: _onChange,
  onBlur,
  optionals = {},
  pathPrefix = '',
  fieldsMapping = {},
  shouldHideApt = false,
  useNormalAptKey = false,
  children
}) => {
  const styles = useStyles()

  const fieldKeys = { ...FIELDS, ...fieldsMapping }
  const prefix = pathPrefix === '' ? '' : `${pathPrefix}.`
  const aptFieldId = useNormalAptKey ? `${prefix}${id}.${fieldKeys.apt}` : `${prefix}${fieldKeys.apt}`

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
    const char = e.key

    if (cyrillicRegex.test(char)) {
      e.preventDefault()
    }
  }

  const onChange = (fieldId: string, value: unknown): void => {
    const nextValue = value ?? null

    /**
     * it is important to add apt to the object or else form will not be able then to update it as part of the object.
     * it is done so to simplify work with apt and addressData to avoid transforms and exclusion rules for apt.
     */
    if (fieldId === id && useNormalAptKey && isFullAddress(nextValue)) {
      nextValue.apt = values?.apt ?? null
    }

    _onChange(fieldId, nextValue)
  }

  return (
    <div className={cnx(styles.container, 'address-fields-component', shouldHideApt && 'hidden-apt')}>
      <div className={cnx(styles.address, 'address-field')}>
        <AddressPicker
          id={`${prefix}${id}.${fieldKeys.address}`}
          fullAddressValueKey={`${prefix}${id}`}
          label={optionalLabel(label ?? DEFAULT_LABEL, Boolean(optionals.address))}
          value={values?.address}
          errors={typeof errors === 'string' ? errors : errors?.address ?? ''}
          touched={isTouched('address', touched)}
          disabled={disabled}
          onChange={onChange}
          onBlurChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          freeSolo
        />
      </div>

      {!shouldHideApt && (
        <div className={styles.apt}>
          <FormMaskedInput
            id={aptFieldId}
            label={optionalLabel('Apt/Unit', Boolean(optionals.apt))}
            mask='apt'
            value={values?.apt ?? ''}
            touched={isTouched('apt', touched)}
            error={typeof errors === 'string' ? '' : errors?.apt}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
          />
        </div>
      )}

      <div className={styles.city}>
        <FormInput
          id={`${prefix}${id}.${fieldKeys.city}`}
          label={optionalLabel('City', Boolean(optionals.city))}
          value={values?.city ?? ''}
          touched={isTouched('city', touched)}
          error={typeof errors === 'string' ? '' : errors?.city}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
        />
      </div>

      <div className={styles.state}>
        <AutocompleteStates
          id={`${prefix}${id}.${fieldKeys.state}`}
          label={optionalLabel('State', Boolean(optionals.state))}
          disabled={disabled}
          onChange={onChange}
          error={typeof errors === 'string' ? '' : errors?.state ?? ''}
          touched={isTouched('state', touched)}
          onBlur={onBlur}
          value={values?.state ?? ''}
        />
      </div>

      <div className={styles.zipCode}>
        <FormMaskedInput
          id={`${prefix}${id}.${fieldKeys.zipCode}`}
          mask='zipCode'
          value={values?.zipCode ?? ''}
          touched={isTouched('zipCode', touched)}
          error={typeof errors === 'string' ? '' : errors?.zipCode}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>

      {children}
    </div>
  )
}

export default AddressFields
