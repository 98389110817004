import { css } from '@emotion/css'

export default css(`
  &.unassigned p {
    color: rgba(33, 33, 33, 0.4);
  }

  &.isDisabled {
    color: rgba(33, 33, 33, 0.40);
    pointer-events: none;
  }
`)
