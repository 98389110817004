import type { FC } from 'react'
import { FormInput, FormNumberInput, FormDatePicker } from '@carfluent/common'

import { DATE_US_FORMAT } from 'constants/constants'
import AddressFields from 'components/common/AddressFields'
import InputSSN2 from 'components/form/components/InputSSN2'
import type { RepresentativeDetailsSectionProps } from '../../types'

const RepresentativeDetails: FC<RepresentativeDetailsSectionProps> = ({
  values,
  errors,
  touched,
  isFormDisabled,
  onChange,
  onBlur
}) => {
  return (
    <div className='credit-app-section no-collapse representative-details'>
      <h3>Representative details (submitting on behalf of Applicant)</h3>
      <p className='text-info'>Name and title of natural person opening account - Representative</p>

      <FormInput
        id='customerDetails.customerFirstName'
        label='First name'
        value={values.customerDetails.customerFirstName}
        error={errors?.customerDetails?.customerFirstName}
        touched={touched?.customerDetails?.customerFirstName}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormInput
        id='customerDetails.customerLastName'
        label='Last name'
        value={values.customerDetails.customerLastName}
        error={errors?.customerDetails?.customerLastName}
        touched={touched?.customerDetails?.customerLastName}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormInput
        id='customerDetails.title'
        label='Title'
        value={values.customerDetails.title}
        error={errors?.customerDetails?.title}
        touched={touched?.customerDetails?.title}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormDatePicker
        id='customerDetails.customerBirthDate'
        label='Date of birth'
        disabled={isFormDisabled}
        isHidden={isFormDisabled}
        value={values.customerDetails.customerBirthDate}
        error={errors?.customerDetails?.customerBirthDate}
        touched={touched?.customerDetails?.customerBirthDate}
        onChange={onChange}
        onBlur={onBlur}
      />

      <p className='text-info'>An executive officer or senior manager (e.g., CEO, CFO, COO President, Vice President etc) or any induvidual who regularly performs similar functions</p>

      <FormInput
        id='executiveDetails.firstName'
        label='First name'
        value={values.executiveDetails.firstName}
        error={errors?.executiveDetails?.firstName}
        touched={touched?.executiveDetails?.firstName}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormInput
        id='executiveDetails.lastName'
        label='Last name'
        value={values.executiveDetails.lastName}
        error={errors?.executiveDetails?.lastName}
        touched={touched?.executiveDetails?.lastName}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormInput
        id='executiveDetails.title'
        label='Title'
        value={values.executiveDetails.title}
        error={errors?.executiveDetails?.title}
        touched={touched?.executiveDetails?.title}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormNumberInput
        id='executiveDetails.ownership'
        label='% of ownership'
        preset='percent'
        value={values.executiveDetails.ownership}
        error={errors?.executiveDetails?.ownership}
        touched={touched?.executiveDetails?.ownership}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
      />

      <FormDatePicker
        id='executiveDetails.birthDate'
        label='Date of birth'
        disabled={isFormDisabled}
        format={DATE_US_FORMAT}
        isHidden={isFormDisabled}
        value={values.executiveDetails.birthDate}
        error={errors?.executiveDetails?.birthDate}
        touched={touched?.executiveDetails?.birthDate}
        onChange={onChange}
        onBlur={onBlur}
      />

      <InputSSN2
        id='executiveDetails.socialSecurityNumber'
        label='Social Security Number'
        value={values.executiveDetails.socialSecurityNumber}
        error={errors?.executiveDetails.socialSecurityNumber}
        touched={touched?.executiveDetails.socialSecurityNumber}
        isDisabled={isFormDisabled}
        isInitialSsnVisible
        onChange={onChange}
        onBlur={onBlur}
      />

      <AddressFields
        id='executiveDetails.addressData'
        values={values.executiveDetails.addressData}
        errors={errors?.executiveDetails?.addressData}
        touched={touched?.executiveDetails?.addressData}
        disabled={isFormDisabled}
        onChange={onChange}
        onBlur={onBlur}
        useNormalAptKey
      />
    </div>
  )
}

export default RepresentativeDetails
