import { useMemo } from 'react'
import type { FC } from 'react'
import { cn } from '@carfluent/common'

import type { TrialBalanceAccount } from './components/AccountRow'
import AccountRow from './components/AccountRow'

export type { TrialBalanceAccount } from './components/AccountRow'

export interface TrialBalanceReport {
  accounts: TrialBalanceAccount[]
  totalCredits: number
  totalDebits: number
}

export interface TrialBalanceTableProps {
  className?: string
  data: TrialBalanceReport
}

const TrialBalanceTable: FC<TrialBalanceTableProps> = ({
  className,
  data
}) => {
  const { accounts, totalCredits, totalDebits } = data
  const fakeAccount = useMemo((): TrialBalanceAccount => ({
    number: 'Total',
    name: '',
    start: null,
    debits: totalDebits,
    credits: totalCredits,
    balance: null
  }), [totalCredits, totalDebits])

  return (
    <table className={cn('cf-trial-balance-table', className)}>
      <thead>
        <tr>
          <th className='cf-account-cell'>Account</th>
          <th className='cf-name-cell'>Name</th>
          <th className='cf-money-cell'>Start</th>
          <th className='cf-money-cell'>Debits</th>
          <th className='cf-money-cell'>Credits</th>
          <th className='cf-money-cell'>Balance</th>
        </tr>
      </thead>

      <tbody>
        {accounts.map((account, acIdx) => (
          <AccountRow key={acIdx} account={account} />
        ))}

        <AccountRow className='cf-row-summary' account={fakeAccount} />
      </tbody>
    </table>
  )
}

export default TrialBalanceTable
