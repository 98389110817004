import React from 'react'
import { FormInput, Dropdown2, cnx, FormMaskedInput, FormNumberInput } from '@carfluent/common'

import Textarea from 'components/common/Textarea'
import Checkbox from 'components/common/CheckboxNoMui'
import { type BotMetadataDto } from 'api/types/responses'
import { WebsiteChatAnswerTypes, type WebsiteChatSettingsFormType } from 'types/crm'
import { ADDITIONAL_COMMENT_LENGTH, NAME_LENGTH } from '../../hooks/constants'
import { MAX_PRICE_VALUE } from 'constants/validation'

export interface QuestionProps {
  touched: boolean
  fieldPrefix: string
  error: string | null
  question: BotMetadataDto
  onBlur: WebsiteChatSettingsFormType['onBlur']
  onChange: WebsiteChatSettingsFormType['onChange']
}

export interface MultiselectOptionType {
  type: string
  required: boolean
}

const QuestionSection: React.FC<QuestionProps> = ({ onBlur, error, touched, fieldPrefix, question, onChange }) => {
  const renderAnswerInput = (): JSX.Element | null => {
    let options: MultiselectOptionType[]

    switch (question.botAnswerTypeId) {
      case WebsiteChatAnswerTypes.Text:
        return (
          <FormInput
            label='Name'
            onChange={onChange}
            maxLength={NAME_LENGTH}
            id={`${fieldPrefix}.answer`}
            value={question.answer ?? ''}
            dataTestId={`${question.question.toLowerCase().replace(/\s/g, '-')}-answer`}
          />
        )

      case WebsiteChatAnswerTypes.Phone:
        return (
          <FormMaskedInput
            mask='phone'
            error={error}
            onBlur={onBlur}
            touched={touched}
            onChange={onChange}
            label='Phone number'
            id={`${fieldPrefix}.answer`}
            value={question.answer ?? ''}
            dataTestId={`${question.question.toLowerCase().replace(/\s/g, '-')}-answer`}
          />
        )

      case WebsiteChatAnswerTypes.YesNo:
        return (
          <Dropdown2
            mode='select'
            label='Select'
            onChange={onChange}
            options={['Yes', 'No']}
            id={`${fieldPrefix}.answer`}
            value={question.answer ?? ''}
            disableClearable={question.answer == null}
            dataTestId={`${question.question.toLowerCase().replace(/\s/g, '-')}-answer`}
          />
        )

      case WebsiteChatAnswerTypes.MultipleChoice:
        try {
          options = JSON.parse(question.answer ?? '[]')
        } catch (e) {
          console.error('Failed to parse multiple choice options:', e)
          options = []
        }

        return (
          <div className='row-list'>
            {options.map((option: MultiselectOptionType, index: number) => (
              <Checkbox
                key={index}
                label={option.type}
                id={`${fieldPrefix}.answer`}
                dataTestId={`${option.type.toLowerCase().replace(/\s/g, '-')}-answer`}
                value={option.required}
                onChange={(value) => {
                  const updatedOptions = [...options]
                  updatedOptions[index] = {
                    ...updatedOptions[index],
                    required: value
                  }

                  onChange(`${fieldPrefix}.answer`, JSON.stringify(updatedOptions))
                }}
              />
            ))}
          </div>
        )

      case WebsiteChatAnswerTypes.Number:
        return (
          <FormNumberInput
            label='Amount, $'
            startAdornment='$'
            onChange={onChange}
            decimalPrecision={2}
            max={MAX_PRICE_VALUE}
            isNegativeAllowed={false}
            id={`${fieldPrefix}.answer`}
            value={question.answer ?? ''}
            data-test-id={`${question.question.toLowerCase().replace(/\s/g, '-')}-value`}
          />
        )

      default:
        return null
    }
  }

  return (
    <div className={cnx('question-section', (question.botAnswerTypeId === WebsiteChatAnswerTypes.None || question.botAnswerTypeId === WebsiteChatAnswerTypes.MultipleChoice) && 'full-width')}>
      <h3>{question.question}</h3>

      {renderAnswerInput()}

      <Textarea
        minRows={3}
        maxRows={3}
        onChange={onChange}
        label='Additional comment'
        maxLength={ADDITIONAL_COMMENT_LENGTH}
        id={`${fieldPrefix}.additionalComments`}
        value={question.additionalComments ?? ''}
        data-test-id={`${question.question.toLowerCase().replace(/\s/g, '-')}-additional-comment`}
      />
    </div>
  )
}

export default QuestionSection
