import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGCheckboxChecked: FC<IconProps & {isDisabled?: boolean}> = ({ color = '#FFFFFF', width = 24, height = 24, isDisabled = false }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='1' y='1' width='22' height='22' rx='1'
        fill={`${isDisabled ? '#21212166' : '#000'}`}
        stroke={`${isDisabled ? '#21212166' : '#000'}`}
        strokeWidth='0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.9999 16.2L4.7999 12L3.3999 13.4L8.9999 19L20.9999 7.00001L19.5999 5.60001L8.9999 16.2Z'
        fill={color}
      />
    </svg>
  )
}

export default IconSVGCheckboxChecked
