import { TaskCompleteOptions, TaskType } from 'pages/crm/LeadDetailsView/hook/types'

export const APPT_RADIO_OPTIONS = [
  {
    value: TaskCompleteOptions.Yes,
    label: 'Yes',
    taskTypeId: TaskType.Appointment,
    subOptions: [
      {
        id: '1',
        name: 'Demoed vehicle(s)'
      },
      {
        id: '4',
        name: 'Sold a vehicle'
      },
      {
        id: '2',
        name: 'Completed a write up'
      },
      {
        id: '5',
        name: 'Turn'
      },
      {
        id: '3',
        name: 'Sent to F&I'
      }
    ]
  },
  {
    value: TaskCompleteOptions.No,
    label: 'No',
    taskTypeId: TaskType.Appointment,
    subOptions: [
      {
        id: '7',
        name: 'No-show'
      },
      {
        id: '8',
        name: 'Canceled'
      }
    ]
  },
  {
    value: TaskCompleteOptions.UnreportedShow,
    label: 'Unreported show',
    taskTypeId: TaskType.Appointment,
    subOptions: [
      {
        id: '9',
        name: 'Demoed vehicle(s)'
      },
      {
        id: '12',
        name: 'Sold a vehicle'
      },
      {
        id: '10',
        name: 'Completed a write up'
      },
      {
        id: '13',
        name: 'Turn'
      },
      {
        id: '11',
        name: 'Sent to F&I'
      }
    ]
  }
]

export { FieldIds } from 'pages/crm/LeadDetailsView/components/LeadDetailsTasks/components/TaskComplete/hook/constants'
