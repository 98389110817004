import { css } from '@emotion/css'

export default css(`
  th:last-child {
    .cf-table-header-title {
      justify-content: flex-end;
    }
  }
  
  .MuiPaper-root table thead th {
    color: #101010;
  }
`)
