import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGStatusPending: FC<IconProps> = ({
  bgColor = 'white',
  color = '#101010',
  opacity = 1,
  height = 18,
  width = 18
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_90143_171656)'>
        <path
          d='M10.5 0.75H7.49995C7.08745 0.75 6.74995 1.0875 6.74995 1.5C6.74995 1.9125 7.08745 2.25 7.49995 2.25H10.5C10.9125 2.25 11.25 1.9125 11.25 1.5C11.25 1.0875 10.9125 0.75 10.5 0.75ZM8.99995 10.5C9.41245 10.5 9.74995 10.1625 9.74995 9.75V6.75C9.74995 6.3375 9.41245 6 8.99995 6C8.58745 6 8.24995 6.3375 8.24995 6.75V9.75C8.24995 10.1625 8.58745 10.5 8.99995 10.5ZM14.2725 5.5425L14.835 4.98C15.12 4.695 15.1275 4.2225 14.835 3.93L14.8275 3.9225C14.535 3.63 14.07 3.6375 13.7775 3.9225L13.215 4.485C12.0525 3.555 10.59 3 8.99995 3C5.39995 3 2.33995 5.97 2.24995 9.57C2.15245 13.38 5.20495 16.5 8.99995 16.5C12.735 16.5 15.75 13.4775 15.75 9.75C15.75 8.16 15.195 6.6975 14.2725 5.5425ZM8.99995 15C6.09745 15 3.74995 12.6525 3.74995 9.75C3.74995 6.8475 6.09745 4.5 8.99995 4.5C11.9025 4.5 14.25 6.8475 14.25 9.75C14.25 12.6525 11.9025 15 8.99995 15Z'
          fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_90143_171656'>
          <rect width={width} height={height} fill={bgColor} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGStatusPending
