import type { FC } from 'react'

import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

import CLASS_NAME from './styles'

const LOADING_ROWS = [...Array(8)]

const NavigationBlockLoader: FC = () => {
  return (
    <ul className={CLASS_NAME}>
      {
        LOADING_ROWS.map((_, i) => (
          <li key={i}>
            <TextSkeleton />
            <TextSkeleton />
          </li>
        ))
      }
    </ul>
  )
}

export default NavigationBlockLoader
