import { useState, useCallback } from 'react'
import { type Result, isOk, useForm, useModal } from '@carfluent/common'

import CRMApiProvider from 'api/crm.api'
import useAsyncEffect from 'hooks/useAsyncEffect'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { type WebsiteChatModel } from 'api/types/responses'
import useTransitionBlocker from 'hooks/useTransitionBlocker'

import { TabIds } from './constants'
import validationRules from './validation'
import serializeWebsiteChatSettings from './serializer'
import { type UseWebsiteChatSettingsFormReturn } from './types'

const DEFAULT_VALUE = {
  botEnabled: false,
  botMetadata: {}
}

const useWebsiteChatSettingsForm = (): UseWebsiteChatSettingsFormReturn => {
  const { showAlert, showSuccess } = useCustomSnackbar()
  const { isModalOpen, onOpenModal, onCloseModal } = useModal()

  const [activeTab, setActiveTab] = useState(TabIds.General)
  const [isInitLoading, setIsInitLoading] = useState(false)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onActionResult = useCallback((res: Result<WebsiteChatModel>, resetForm) => {
    if (isOk<WebsiteChatModel>(res)) {
      resetForm(res.result)
    }
  }, [])

  const submitAction = useCallback(async (formData: WebsiteChatModel): Promise<WebsiteChatModel | null> => {
    try {
      const serializedCrmSettings = serializeWebsiteChatSettings(formData)
      const chatSettings = await CRMApiProvider.updateWebsiteChatSettings(serializedCrmSettings)

      showSuccess('Changes successfully saved.')

      return chatSettings
    } catch (err) {
      showAlert(err)

      return null
    } finally {
      onCloseModal()
    }
  }, [showAlert])

  const {
    values,
    errors,
    onBlur,
    touched,
    isValid,
    onChange,
    onSubmit,
    resetForm,
    hasChanges,
    isSubmitting
  } = useForm<WebsiteChatModel>({
    submitAction,
    onActionResult,
    validationRules,
    isTrackingChanges: true,
    baseValues: DEFAULT_VALUE
  })

  const onDiscardChanges = useCallback(() => {
    resetForm()
    showSuccess('Changes discarded.')
  }, [resetForm, showSuccess])

  const onToggleBot = (isEnabled: boolean): void => {
    onChange('botEnabled', isEnabled)
  }

  const { proceedTransition } = useTransitionBlocker({
    shouldBlock: hasChanges,
    onBlock: onOpenModal
  })

  const onDontSaveAndClose = (): void => {
    onDiscardChanges()
    onCloseModal()
    proceedTransition()
  }

  const onSaveAndClose = async (): Promise<void> => {
    proceedTransition()
    await onSubmit()
  }

  const onSubmitAction = async (): Promise<void> => {
    if (!isValid) {
      setActiveTab(1)
    }

    await onSubmit()
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useAsyncEffect(async () => {
    try {
      setIsInitLoading(true)
      const res = await CRMApiProvider.getWebsiteChatSettings()

      resetForm(res)
    } catch (e) {
      showAlert('Something went wrong.')
    } finally {
      setIsInitLoading(false)
    }
  }, [])

  // ========================================== //

  return {
    onBlur,
    errors,
    touched,
    onChange,
    resetForm,
    activeTab,
    hasChanges,
    onToggleBot,
    isSubmitting,
    setActiveTab,
    isInitLoading,
    formData: values,
    onDiscardChanges,
    onSubmit: onSubmitAction,
    unsavedChangesModalProps: {
      onSaveAndClose,
      onDontSaveAndClose,
      isOpen: isModalOpen,
      onCloseDialog: onCloseModal
    }
  }
}

export default useWebsiteChatSettingsForm
