import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  .action-bar {
    display: inline-flex;
    align-items: center;
    
    .title {
      color: rgba(33, 33, 33, 0.80);
      line-height: 24px;
      letter-spacing: 0.5px;
    }
    
    .deal-status-filter {
      margin-left: 4px;
      max-width: 330px;

      .cf-select-display-container {
        height: 32px;
        background: #F3F3F3;
        border: none;
        border-radius: 8px;
        padding: 8px;
        padding-right: 4px;
        display: flex;
        align-items: center;

        .cf-display {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.25px;
          border: none;
          outline: none;
          resize: none;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .cf-end-adornment {
          display: flex;
          align-items: center;

          > div { height: 24px; }

          :has(.cf-dropdown-clear-button) { .cf-dropdown-trigger-button { display:none; } }

          .cf-dropdown-clear-button {
            margin-top: 2px;

            svg {
              width: 20px;
              height: 20px;
            }
          }

          .cf-dropdown-trigger-button {
            margin-top: 4px;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
`)

export const POPOVER_CLASS_NAME = css(`
  &.cf-popover-content {
    padding: 16px 8px;
    min-width: 260px;
    z-index: 1000;
  }

  .cf-options-divider { margin: 8px 0; }

  .cf-dropdown-option {
    padding: 8px 16px;

    > div { 
      height: 24px;
      
      .cf-checkbox-icon { flex-shrink: 0; }

      div:last-child {
        flex-grow: 1; 
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`)
