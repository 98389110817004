import { css } from '@emotion/css'

export default css(`
  .timeline-comments-list {
    .no-timelines {
      margin-top: 40px;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .day-separator {
    display: flex;
    align-items: center;
    margin: 16px 0;
  }
  
  .day-separator span{
    :first-of-type,
    :last-of-type {
      flex-grow: 1;
      border: none;
      border-top: 1px solid #0000001F;
    }

    :first-of-type {
      margin-left: 24px;
      margin-right: 16px;
    }

    :last-of-type {
      margin-left: 16px;
      margin-right: 24px;
    }
  }
  
  .day-separator span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #21212180;
    letter-spacing: 0.4px;
  }
`)
