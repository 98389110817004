import { css } from '@emotion/css'
import { ScreenWidthBreakpoints, px } from 'constants/constants'

export default css(`
  .button {
    margin: 16px;

    > button {
      word-break: no-break;
    }
  }
  
  .search-block {
    min-width: 420px;
    max-width: 420px;
    width: 100%;

    .MuiInputBase-root {
      background: #F3F3F3;
      border-radius: 12px;
      box-shadow: none;
      height: 56px;
    }
  }

  .cf-table-header-title p { color: #101010; }

  .cf-no-data-block {
    padding: 22px;
    height: 72px;
  }

  .cf-table-cell {
    .cf-cell-wrapper { 
      height: 64px;
      padding: 20px 8px;
    }

    :last-child .cf-cell-wrapper { padding-right: 16px; }

    :nth-last-child(2) > .cf-cell-wrapper {
      .cf-table-cell-text {
        width: calc(100% - 20px);
        max-width: 14vw;
      }
    }
  }


  .cf-table-cell {
    :nth-child(1) {
      max-width: 250px;
    }

    :nth-last-child(1) {
      p {
        max-width: 400px;
  
        @media screen and (max-width: ${px(ScreenWidthBreakpoints.Small)}) {
          max-width: 350px;
        }
      }
    }
  }

`)
