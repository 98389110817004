import { css } from '@emotion/css'

export default css`
  position: absolute;
  top: 8px;
  right: 14px;
  
  button { padding: 6px 12px; }

  .edit-delete-task .cf-menu-container {
    width: 120px;

    ul {
      .menu-item {
        padding-top: 12px;
        padding-bottom: 12px;
        color: #101010;
        letter-spacing: 0.15px;
      }

      div:last-of-type {
        .menu-item {
          color: #B00020;
        } 
      }
    }
  }
}
`
