import { type FC, useLayoutEffect } from 'react'
import { cn, Modal } from '@carfluent/common'
import type { ModalProps } from '@carfluent/common/dist/UI'

import CLASSES from './styles'

export interface ModalFullscreenProps extends ModalProps {
  isLoading?: boolean
}

const DEFAULT_MIN_WIDTH = 1280
const DEFAULT_CLASS_NAME_WITH_FULL_SCREEN_MODAL = 'cf-fullscreen-modal-is-open'

const ModalFullscreen: FC<ModalFullscreenProps> = ({
  children,
  className,
  isLoading = false,
  minWidth = DEFAULT_MIN_WIDTH,
  ...modalProps
}) => {
  useLayoutEffect(() => {
    const bodyClassName = document.body.classList

    if (modalProps.isOpen === true) {
      bodyClassName.add(DEFAULT_CLASS_NAME_WITH_FULL_SCREEN_MODAL)
    }

    return () => {
      bodyClassName.remove(DEFAULT_CLASS_NAME_WITH_FULL_SCREEN_MODAL)
    }
  }, [modalProps.isOpen])

  return (
    <Modal
      {...modalProps}
      className={cn(CLASSES, className, 'cf-modal-fullscreen')}
      isFullscreen
      isLoading={isLoading}
      minWidth={minWidth}
      closeByBackdropClick={false}
      closeByEsc={false}
    >
      {children}
    </Modal>
  )
}

export default ModalFullscreen
