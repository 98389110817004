import { css } from '@emotion/css'

import { RowVariants } from './components/Row'

const BODY_1_STYLES = `
  color: #101010;
  font-size: 16px;
  letter-spacing: 0.5px;
`

const BODY_2_STYLES = `
  color: #101010;
  font-size: 14px;
  letter-spacing: 0.25px;
`

const H6_STYLES = `
  color: #101010;
  font-size: 20px;
  letter-spacing: 0.15px;
`

export const createTableClass = (): string => {
  return css(`
    width: 100%;
    padding-bottom: 100px;

    .cf-bs-pl-table {
      margin-top: 24px;
      width: 100%;
      border-collapse: collapse;

      tr {
        &:hover {
          td {
            background: #f3f3f3;
          }
        }

        td {
          padding: 4px 0;
          font-family: Roboto;

          &:nth-child(2) {
            text-align: right;
          }

          &.cf-row-text-${RowVariants.Info} {
            ${BODY_2_STYLES}
            padding-left: 32px;
            width: 500px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 500px;
            line-height: 1.3572em;
          }

          &.cf-row-text-${RowVariants.Title} {
            ${H6_STYLES}
            font-weight: 500;
          }

          &.cf-row-text-${RowVariants.Subtitle} {
            ${BODY_1_STYLES}
            padding-left: 24px;
            font-weight: 500;
          }

          &.cf-row-text-${RowVariants.Total}, &.cf-row-value-${RowVariants.Total} {
            ${H6_STYLES}
            padding-bottom: 16px;
            font-weight: 500;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
          }

          &.cf-row-value-${RowVariants.Subtitle} {
            ${BODY_1_STYLES}
            font-weight: 500;
          }

          &.cf-row-value-${RowVariants.Title} {
            ${BODY_1_STYLES}
            color: rgba(33, 33, 33, 0.8);
          }
        }

        &.cf-row-${RowVariants.Info} td {
          color: rgba(33, 33, 33, 0.8);
        }

        &.cf-row-${RowVariants.Empty} {
          &:hover {
            td {
              background: #ffffff;
            }
          }
        }

        &.cf-row-${RowVariants.Empty} td {
          padding: 8px 0;
        }

        &.cf-row-${RowVariants.Total} td {
          padding: 8px 0 16px 0;
        }
      }
    }
  `)
}
