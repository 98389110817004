import { cnx, type ColumnDef, formatCurrencyDecimal } from '@carfluent/common'

import { type CoverageFeeSettingsDto } from 'api/types/responses'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import TooltipCell from 'components/common/Table/cells/TooltipCell'
import { DateCellHOC } from 'components/common/Table/cells/DateCell'
import { LinkCellHOC } from 'components/common/Table/cells/LinkCell'

import { toDateOrNull } from 'utils/parse_date'
import formatCurrencyLabel from 'utils/accounting/formatCurrencyLabel'

import type { SaleTaxRowData, SumValuesProps } from './types'
import { shouldShowColumn, viewHelperForPercentagesDecimal } from './utils'

const config = { emptyValues: '-' }
const configForFooter = { emptyValues: '-', allowZero: false }

const getColumnDefinitions = (
  sumValues: SumValuesProps,
  settings: CoverageFeeSettingsDto | null
): Array<ColumnDef<SaleTaxRowData>> => [
  {
    sortable: false,
    id: 'carDetailsGroup',
    header: '',
    className: 'car-sticky-group',
    footer: undefined,
    columns: [
      {
        size: 120,
        id: 'date',
        loading: 'big_line',
        accessorFn: (row) => toDateOrNull(row.date),
        cell: DateCellHOC({ format: 'MM/dd/yyyy' }),
        header: TextCellHOC({ text: 'Sale date' }),
        footer: undefined
      },
      {
        size: 100,
        loading: 'big_line',
        accessorKey: 'deal',
        header: TextCellHOC({ text: 'Deal ID' }),
        cell: LinkCellHOC({ withTooltip: false }),
        footer: undefined
      },
      {
        size: 240,
        loading: 'big_line',
        accessorKey: 'car',
        cell: LinkCellHOC({ withTooltip: true }),
        header: TextCellHOC({ text: 'Car' }),
        footer: undefined
      },
      {
        size: 100,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'stock',
        header: TextCellHOC({ text: 'Stock#' }),
        footer: <div className='last-sticky-td' />
      }
    ] as Array<ColumnDef<SaleTaxRowData>>
  },
  {
    sortable: false,
    id: 'websiteLeadsGroup',
    header: '',
    className: 'car-scrolled-group',
    footer: undefined,
    columns: ([
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'soldTo',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'left-padding'),
        header: TextCellHOC({ text: 'Sold to', withTooltip: true }),
        footer: 'SUM'
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'zipCode',
        className: cnx('car_fluent-website-leads-group', 'header-indent'),
        header: TextCellHOC({ text: 'Zip code', withTooltip: true }),
        footer: undefined
      },
      {
        size: 200,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'county',
        className: cnx('car_fluent-website-leads-group', 'header-indent'),
        header: TextCellHOC({ text: 'County', withTooltip: true }),
        footer: undefined
      },
      {
        size: 140,
        accessorFn: (item) => formatCurrencyDecimal(item.salePrice, config),
        id: 'salePrice',
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: 'Sale price', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalSalePrice, configForFooter)}`
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyDecimal(item.tradeInCredit, config),
        id: 'tradeInCredit',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: 'Trade in credit', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalTradeInCredit, configForFooter)}`
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyDecimal(item.serviceContract, config),
        id: 'serviceContract',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: 'Service contract', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalServiceContract, configForFooter)}`
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyDecimal(item.gap, config),
        id: 'gap',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: 'GAP', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalGap, configForFooter)}`
      },
      {
        size: 180,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyDecimal(item.aftermarketsProduct, config),
        id: 'aftermarketsProduct',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: 'Aftermarket products', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalAftermarketsProduct, configForFooter)}`
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        id: 'buyerTag',
        accessorFn: (item) => formatCurrencyDecimal(item.buyerTag, { allowZero: true, emptyValues: '-' }),
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: settings?.buyerTagSetting.labelName ?? 'Buyer tag', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalBuyerTag, configForFooter)}`
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        id: 'carDelivery',
        accessorFn: (item) => formatCurrencyDecimal(item.carDelivery, { allowZero: true, emptyValues: '-' }),
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: settings?.carDeliverySetting.labelName ?? 'Car delivery', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalCarDelivery, configForFooter)}`
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyDecimal(item.dealerHandlingFee, { allowZero: true, emptyValues: '-' }),
        id: 'dealerHandlingFee',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: settings?.dealerHandlingFeeSetting.labelName ?? 'Document fee', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalDealerHandlingFee, configForFooter)}`
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyDecimal(item.dealerInventoryTax, { allowZero: true, emptyValues: '-' }),
        id: 'dealerInventoryTax',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: settings?.dealerInventoryTaxSetting.labelName ?? 'Dealer’s inventory tax', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalDealerInventoryTax, configForFooter)}`
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyDecimal(item.emissions, { allowZero: true, emptyValues: '-' }),
        id: 'emissions',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: settings?.emissionsSetting.labelName ?? 'Emissions', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalEmissions, configForFooter)}`
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyDecimal(item.inspectionFee, { allowZero: true, emptyValues: '-' }),
        id: 'inspectionFee',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: settings?.inspectionFeeSetting.labelName ?? 'Inspection fee', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalInspectionFee, configForFooter)}`
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyDecimal(item.plateTransferFee, { allowZero: true, emptyValues: '-' }),
        id: 'plateTransferFee',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: settings?.plateTransferFeeSetting.labelName ?? 'Plate transfer fee', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalPlateTransferFee, configForFooter)}`
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyDecimal(item.registrationFee, { allowZero: true, emptyValues: '-' }),
        id: 'registrationFee',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: settings?.registrationFeeSetting.labelName ?? 'Registration fee', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalRegistrationFee, configForFooter)}`
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyDecimal(item.titleFee, { allowZero: true, emptyValues: '-' }),
        id: 'titleFee',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: settings?.titleFeeSetting.labelName ?? 'Title fee', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalTitleFee, configForFooter)}`
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyDecimal(item.additionalCosts, config),
        id: 'additionalCosts',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: 'Additional costs', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalAdditionalCosts, configForFooter)}`
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyDecimal(item.taxableAmount, config),
        id: 'taxableAmount',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: 'Taxable amount', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalTaxableAmount, configForFooter)}`
      },
      {
        size: 100,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorKey: 'rate',
        accessorFn: (item) => viewHelperForPercentagesDecimal(item.rate),
        id: 'rate',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: '% Rate', withTooltip: true }),
        footer: undefined
      },
      {
        size: 180,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyDecimal(item.totalSalesTaxPerReport, config),
        id: 'totalSalesTaxPerReport',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: 'Total sales tax per Report', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalTotalSalesTaxPerReport, configForFooter)}`
      },
      {
        size: 180,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyDecimal(item.totalSalesTaxPerDms, config),
        id: 'totalSalesTaxPerDms',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: 'Total Sales tax per DMS', withTooltip: true }),
        footer: `${formatCurrencyDecimal(sumValues.totalTotalSalesTaxPerDms, configForFooter)}`
      },
      {
        size: 140,
        sortable: false,
        cell: TooltipCell,
        loading: 'big_line',
        accessorFn: (item) => formatCurrencyLabel(item.difference, true, '-'),
        id: 'difference',
        className: cnx('car_fluent-website-leads-group', 'header-indent', 'right-align'),
        header: TextCellHOC({ text: 'Difference', withTooltip: true }),
        footer: formatCurrencyLabel(sumValues.totalDifference, true, '$0.00')
      }
    ] as Array<ColumnDef<SaleTaxRowData>>).filter(shouldShowColumn(settings))
  }
]

export default getColumnDefinitions
