import { type DealerData } from '../types'

import {
  type FinanceTypeAmountFeeSettingModel,
  type DefaultAmountFeeSettingModel,
  type DealerSettingsModel,
  CommissionType
} from 'api/types/responses'

const GET_DEFAULT_AMOUNT_FEE_SETTING = (): DefaultAmountFeeSettingModel => ({
  labelName: null,
  isEnabled: false,
  isReadonly: false,
  isTaxable: false,
  accountId: null,
  account: null,
  defaultValueCash: 0
})

const GET_FINANCE_TYPE_AMOUNT_FEE_SETTING = (): FinanceTypeAmountFeeSettingModel => ({
  ...GET_DEFAULT_AMOUNT_FEE_SETTING(),
  defaultValueCash: 0,
  defaultValueFinancing: 0
})

export const DEFAULT_DEALER_SETTINGS: DealerSettingsModel = ({
  id: 0,
  excellentInterestRate: 0,
  goodInterestRate: 0,
  averageInterestRate: 0,
  belowAverageInterestRate: 0,
  maxCardPaymentAmount: 0,
  interestRateMarkup: 0,
  tradeInAdjustment: 0,
  websiteBaseUrl: null,
  inventoryPackCost: 0,
  coverageFeeSettings: {
    buyerTagSetting: GET_FINANCE_TYPE_AMOUNT_FEE_SETTING(),
    carDeliverySetting: GET_DEFAULT_AMOUNT_FEE_SETTING(),
    dealerHandlingFeeSetting: GET_DEFAULT_AMOUNT_FEE_SETTING(),
    dealerInventoryTaxSetting: GET_FINANCE_TYPE_AMOUNT_FEE_SETTING(),
    emissionsSetting: GET_DEFAULT_AMOUNT_FEE_SETTING(),
    inspectionFeeSetting: GET_FINANCE_TYPE_AMOUNT_FEE_SETTING(),
    plateTransferFeeSetting: GET_FINANCE_TYPE_AMOUNT_FEE_SETTING(),
    registrationFeeSetting: GET_FINANCE_TYPE_AMOUNT_FEE_SETTING(),
    titleFeeSetting: GET_FINANCE_TYPE_AMOUNT_FEE_SETTING(),
    serviceContractTaxable: false,
    gapTaxable: false,
    appearanceProtectionTaxable: false,
    tireAndWheelTaxable: false,
    theftTaxable: false
  },
  salesMonthlyTarget: 0,
  backGrossSalesCommissionSetting: {
    salesCommissionTypeId: CommissionType.Percent,
    percent: null,
    fixed: null,
    minAmount: null
  },
  frontGrossSalesCommissionSetting: {
    salesCommissionTypeId: CommissionType.Percent,
    percent: null,
    fixed: null,
    minAmount: null
  },
})

export const GET_DEFAULT_DEALER_DATA = (): DealerData => ({
  dealerName: '',
  address1: '',
  city: '',
  state: '',
  zipCode: '',
  phone: '',
  email: '',
  leadCmsEmails: [],
  googleMapsLink: '',
  dealerFeeMarkupSettings: DEFAULT_DEALER_SETTINGS,
  logoUrl: '',
  termsUrl: '',
  dealerBusinessHours: {
    mondayOpeningTime: null,
    mondayClosingTime: null,
    tuesdayOpeningTime: null,
    tuesdayClosingTime: null,
    wednesdayOpeningTime: null,
    wednesdayClosingTime: null,
    thursdayOpeningTime: null,
    thursdayClosingTime: null,
    fridayOpeningTime: null,
    fridayClosingTime: null,
    saturdayOpeningTime: null,
    saturdayClosingTime: null,
    sundayOpeningTime: null,
    sundayClosingTime: null
  },
  subscriptionTypeName: ''
})
