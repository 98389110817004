import { css } from '@emotion/css'

export interface StylesConfig {
  width: number
  height: number
}

const createStyleClass = ({ width, height }: StylesConfig): string => css(`
  position: relative;

  &.disabled .icon-lock {
    position: absolute;
    z-index: 1;
    height: ${height}px;
    width: ${width}px;
    border-radius: 10px;
    background-color: rgba(33, 33, 33, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    svg { position: absolute; }
  }

  &.deposited .icon-exclamation-mark {
    right: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
  }

  .deposit-overlay {
    position: absolute;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
    height: ${height}px;
    width: ${width}px;
  }

  .icon-exclamation-mark {
    position: absolute;
    z-index: 1;
    right: 24px;

    .round-of-icon {
      display: flex;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      background-color: #fff;
    }
  }

  .vehicle-image {
    border-radius: 10px;
    width: ${width}px;
    height: ${height}px;
    object-fit: cover;
  }

  .skeleton {
    border-radius: 10px;
    width: ${width}px;
    height: ${height}px;
  }
  
  .hidden {
    visibility: hidden;
    position: absolute;
    left: -99999px;
  }
`)

export default createStyleClass
