import { css } from '@emotion/css'

export default css(`
  .multiline {
    display: flex;
    min-width: 140px;
    flex-direction: column;
  }

  .days-old {
    color: #21212150;
  }

  p {
    color: #101010;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`)
