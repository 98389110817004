import { uniqueId } from 'lodash-es'
import { type WebChatDto, type WebChatItemModel, BotMessageRole } from 'api/types/responses'

const parseWebChatDto = (data: WebChatDto): WebChatItemModel[] => {
  return data.messages.map(({ botMessageRoleId, content, createdDate }) => ({
    id: uniqueId(),
    isReceived: botMessageRoleId === BotMessageRole.AI,
    date: createdDate,
    message: content,
    name: botMessageRoleId === BotMessageRole.AI ? 'AI Assistant' : 'Unknown name'
  }))
}

export default parseWebChatDto
