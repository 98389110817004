import type { FormValidation } from '@carfluent/common'
import type { CreditApplicationFormData, ErrTypeMap } from '../../../types'
import {
  correctRule,
  correctAddressRule,
  addressDataFull,
  emailField,
  firstNameField,
  lastNameField,
  birthDate,
  phoneField,
  requiredField,
  vinField,
  businessNameField,
  businessEinField,
  bankDetailsNameField,
  bankDetailsContactNameField,
  bankDetailsCheckingAccountNumberField,
  ssnField,
  vehicleYearField,
  makeField,
  modelField,
  trimField,
  mileageField,

  jobTitleField,
  employerNameField,
  employerPhoneNumberField,
  workingStartDateField,
  workingEndDateField,
  employmentIncomeField,
  apartmentMoveInDate,
  warrantyRequiredField,
  previousStatusField,
  previousAddressDataFull,
  previousAddressField,

  listItemField,
  driverLicenseNumberRule,
  otherIncomeFieldRule,
  priceField,
  zipCodeField,
  requiredNonZeroField,
  requiredNumberField
} from 'utils/validationPresets'
import { MAX_CAR_PRICE_VALUE, MAX_CREDIT_APP_SALES_TAX_VALUE, MAX_PRICE_VALUE, MAX_TAX_OR_FEE_VALUE } from 'constants/validation'
import { CreditAppParts } from 'utils/creditApplication'

interface ValidationFuncConfig {
  isBusiness: boolean
  isSubmitting: boolean
  isTradeInSectionVisible: boolean
  isWarrantySectionVisible: boolean
  isCoApplicantSectionVisible: boolean
}

type ValidationResult = FormValidation<CreditApplicationFormData, never, ErrTypeMap>

export const DEPENDENT_VALIDATIONS = {
  'applicantFinancialDetails.stateOfIssue': ['applicantFinancialDetails.driverLicenseNumber'],
  'applicantFinancialDetails.incomeOption': ['applicantFinancialDetails.otherIncome'],
  'coApplicantFinancialDetails.stateOfIssue': ['coApplicantFinancialDetails.driverLicenseNumber'],
  'coApplicantFinancialDetails.incomeOption': ['coApplicantFinancialDetails.otherIncome'],

  'coverageData.coverageDetails.[*].forms.PEN': [
    'coverageData.coverageDetails.[*].forms.PEN.cost',
    'coverageData.coverageDetails.[*].forms.PEN.totalPrice',
    'coverageData.coverageDetails.[*].forms.PEN.termMonths',
    'coverageData.coverageDetails.[*].forms.PEN.providerId',
    'coverageData.coverageDetails.[*].forms.PEN.coverageName',
    'coverageData.coverageDetails.[*].forms.PEN.deductibleAmount',
    'coverageData.coverageDetails.[*].forms.PEN.productCoverageId'
  ],
  'coverageData.coverageDetails.[*].forms.Manual': ['coverageData.coverageDetails.[*].forms.Manual.providerId']
}

const createValidationRules = ({
  isBusiness,
  isSubmitting,
  isTradeInSectionVisible,
  isWarrantySectionVisible,
  isCoApplicantSectionVisible
}: ValidationFuncConfig
): ValidationResult => {
  return {
    // ------------------------------------------ //
    // ---            Business Details        --- //
    // ------------------------------------------ //

    'businessDetails.businessName': isBusiness ? businessNameField(true) : correctRule,
    'businessDetails.businessTypeId': isBusiness ? requiredField : correctRule,
    'businessDetails.ein': isBusiness ? businessEinField(true) : correctRule,

    // ------------------------------------------ //
    // ---            Customer Details        --- //
    // ------------------------------------------ //

    'customerDetails.customerFirstName': firstNameField(true),
    'customerDetails.customerLastName': lastNameField(true),
    'customerDetails.customerPhoneNumber': phoneField(true),
    'customerDetails.customerEmail': emailField(),
    'customerDetails.customerBirthDate': birthDate(true),
    'customerDetails.title': (isBusiness && isSubmitting) ? requiredField : correctRule,
    'customerDetails.addressData': addressDataFull(),
    'customerDetails.addressData.address': isSubmitting ? requiredField : correctRule,
    'customerDetails.addressData.city': isSubmitting ? requiredField : correctRule,
    'customerDetails.addressData.state': isSubmitting ? requiredField : correctRule,
    'customerDetails.addressData.zipCode': isSubmitting ? zipCodeField(true) : correctRule,

    // ------------------------------------------ //
    // ---              Bank Details          --- //
    // ------------------------------------------ //

    'bankDetails.name': isBusiness ? bankDetailsNameField(isSubmitting) : correctRule,
    'bankDetails.contactName': isBusiness ? bankDetailsContactNameField(isSubmitting) : correctRule,
    'bankDetails.contactPhone': isBusiness ? phoneField(isSubmitting) : correctRule,
    'bankDetails.checkingAccountNumber': isBusiness ? bankDetailsCheckingAccountNumberField(isSubmitting) : correctRule,

    // ------------------------------------------ //
    // ---           Executive Details        --- //
    // ------------------------------------------ //

    'executiveDetails.addressData': isBusiness ? addressDataFull() : correctAddressRule,
    'executiveDetails.addressData.address': (isBusiness && isSubmitting) ? requiredField : correctRule,
    'executiveDetails.addressData.city': (isBusiness && isSubmitting) ? requiredField : correctRule,
    'executiveDetails.addressData.state': (isBusiness && isSubmitting) ? requiredField : correctRule,
    'executiveDetails.addressData.zipCode': (isBusiness && isSubmitting) ? zipCodeField(true) : correctRule,
    'executiveDetails.firstName': isBusiness ? firstNameField(true) : correctRule,
    'executiveDetails.lastName': isBusiness ? lastNameField(true) : correctRule,
    'executiveDetails.birthDate': isBusiness ? birthDate(true) : correctRule,
    'executiveDetails.title': isBusiness ? requiredField : correctRule,
    'executiveDetails.socialSecurityNumber': isBusiness ? requiredField : correctRule,
    'executiveDetails.ownership': isBusiness ? requiredField : correctRule,

    // ------------------------------------------ //
    // ---           Applicant Details        --- //
    // ------------------------------------------ //

    'applicantFinancialDetails.socialSecurityNumber': !isBusiness ? ssnField(isSubmitting) : correctRule,
    'applicantFinancialDetails.driverLicenseNumber': !isBusiness ? driverLicenseNumberRule(CreditAppParts.ApplicantDetails) : correctRule,
    'applicantFinancialDetails.otherIncome': isBusiness ? correctRule : otherIncomeFieldRule(isSubmitting, CreditAppParts.ApplicantDetails),
    'applicantFinancialDetails.wholesaleValue': priceField(MAX_CAR_PRICE_VALUE, isSubmitting),

    // --- Current Address Details --- //

    'applicantFinancialDetails.housingStatus': isBusiness ? correctRule : listItemField(isSubmitting),
    'applicantFinancialDetails.apartmentMoveInDate': isBusiness ? correctRule : apartmentMoveInDate(isSubmitting),
    'applicantFinancialDetails.apartmentPayment': (isSubmitting && !isBusiness) ? requiredNumberField : correctRule,

    // --- Previous Address Details --- //
    'applicantFinancialDetails.previousAddressDetails.apartmentMoveInDate': isBusiness ? correctRule : apartmentMoveInDate(isSubmitting, false, true),
    'applicantFinancialDetails.previousAddressDetails.addressData': isBusiness ? correctAddressRule : previousAddressDataFull(isSubmitting),
    'applicantFinancialDetails.previousAddressDetails.addressData.address': isBusiness ? correctRule : previousAddressField('address', isSubmitting),
    'applicantFinancialDetails.previousAddressDetails.addressData.city': isBusiness ? correctRule : previousAddressField('city', isSubmitting),
    'applicantFinancialDetails.previousAddressDetails.addressData.state': isBusiness ? correctRule : previousAddressField('state', isSubmitting),
    'applicantFinancialDetails.previousAddressDetails.addressData.zipCode': isBusiness ? correctRule : previousAddressField('zipCode', isSubmitting),

    // --- Current Employment Details --- //

    'applicantFinancialDetails.currentEmploymentDetails.employmentStatus': isBusiness ? correctRule : listItemField(isSubmitting),
    'applicantFinancialDetails.currentEmploymentDetails.jobTitle': isBusiness ? correctRule : jobTitleField(isSubmitting),
    'applicantFinancialDetails.currentEmploymentDetails.employerName': isBusiness ? correctRule : employerNameField(isSubmitting),
    'applicantFinancialDetails.currentEmploymentDetails.employerPhoneNumber': isBusiness ? correctRule : employerPhoneNumberField(isSubmitting),
    'applicantFinancialDetails.currentEmploymentDetails.workingStartDate': isBusiness ? correctRule : workingStartDateField(isSubmitting),
    'applicantFinancialDetails.currentEmploymentDetails.income': isBusiness ? correctRule : employmentIncomeField(isSubmitting),

    // --- Previous Employment Details --- //

    'applicantFinancialDetails.previousEmploymentDetails.employmentStatus': isBusiness ? correctRule : previousStatusField(),
    'applicantFinancialDetails.previousEmploymentDetails.jobTitle': isBusiness ? correctRule : jobTitleField(isSubmitting, false, true),
    'applicantFinancialDetails.previousEmploymentDetails.employerName': isBusiness ? correctRule : employerNameField(isSubmitting, false, true),
    'applicantFinancialDetails.previousEmploymentDetails.employerPhoneNumber': isBusiness ? correctRule : employerPhoneNumberField(isSubmitting, false, true),
    'applicantFinancialDetails.previousEmploymentDetails.workingStartDate': isBusiness ? correctRule : workingStartDateField(isSubmitting, false, true),
    'applicantFinancialDetails.previousEmploymentDetails.income': isBusiness ? correctRule : employmentIncomeField(isSubmitting, false, true),
    'applicantFinancialDetails.previousEmploymentDetails.workingEndDate': isBusiness ? correctRule : workingEndDateField(isSubmitting, false, true),

    // ------------------------------------------ //
    // ---         CoApplicant Details        --- //
    // ------------------------------------------ //

    'coApplicantFinancialDetails.firstName': isCoApplicantSectionVisible ? firstNameField(isSubmitting) : correctRule,
    'coApplicantFinancialDetails.lastName': isCoApplicantSectionVisible ? lastNameField(isSubmitting) : correctRule,
    'coApplicantFinancialDetails.email': isCoApplicantSectionVisible ? emailField(isSubmitting) : correctRule,
    'coApplicantFinancialDetails.phoneNumber': isCoApplicantSectionVisible ? phoneField(isSubmitting) : correctRule,
    'coApplicantFinancialDetails.birthDate': isCoApplicantSectionVisible ? birthDate(isSubmitting) : correctRule,
    'coApplicantFinancialDetails.socialSecurityNumber': isCoApplicantSectionVisible ? ssnField(isSubmitting) : correctRule,
    'coApplicantFinancialDetails.driverLicenseNumber': isCoApplicantSectionVisible ? driverLicenseNumberRule(CreditAppParts.CoApplicantDetails) : correctRule,
    'coApplicantFinancialDetails.otherIncome': isCoApplicantSectionVisible ? otherIncomeFieldRule(isSubmitting, CreditAppParts.CoApplicantDetails) : correctRule,
    'coApplicantFinancialDetails.coApplicantType': isCoApplicantSectionVisible ? requiredField : correctRule,

    // --- Current Address Details --- //

    'coApplicantFinancialDetails.housingStatus': isCoApplicantSectionVisible ? listItemField(isSubmitting) : correctRule,
    'coApplicantFinancialDetails.currentAddressDetails.apartmentMoveInDate': isCoApplicantSectionVisible ? apartmentMoveInDate(isSubmitting, true) : correctRule,
    'coApplicantFinancialDetails.currentAddressDetails.apartmentPayment': isCoApplicantSectionVisible ? priceField(MAX_PRICE_VALUE, isSubmitting) : correctRule,
    'coApplicantFinancialDetails.currentAddressDetails.addressData': isCoApplicantSectionVisible ? addressDataFull() : correctAddressRule,
    'coApplicantFinancialDetails.currentAddressDetails.addressData.address': (isCoApplicantSectionVisible && isSubmitting) ? requiredField : correctRule,
    'coApplicantFinancialDetails.currentAddressDetails.addressData.city': (isCoApplicantSectionVisible && isSubmitting) ? requiredField : correctRule,
    'coApplicantFinancialDetails.currentAddressDetails.addressData.state': (isCoApplicantSectionVisible && isSubmitting) ? requiredField : correctRule,
    'coApplicantFinancialDetails.currentAddressDetails.addressData.zipCode': (isCoApplicantSectionVisible && isSubmitting) ? zipCodeField(true) : correctRule,

    // --- Previous Address Details --- //

    'coApplicantFinancialDetails.previousAddressDetails.apartmentMoveInDate': isCoApplicantSectionVisible ? apartmentMoveInDate(isSubmitting, true, true) : correctRule,
    'coApplicantFinancialDetails.previousAddressDetails.addressData': isCoApplicantSectionVisible ? previousAddressDataFull(isSubmitting, true) : correctAddressRule,
    'coApplicantFinancialDetails.previousAddressDetails.addressData.address': isCoApplicantSectionVisible ? previousAddressField('address', isSubmitting, true) : correctRule,
    'coApplicantFinancialDetails.previousAddressDetails.addressData.city': isCoApplicantSectionVisible ? previousAddressField('city', isSubmitting, true) : correctRule,
    'coApplicantFinancialDetails.previousAddressDetails.addressData.state': isCoApplicantSectionVisible ? previousAddressField('state', isSubmitting, true) : correctRule,
    'coApplicantFinancialDetails.previousAddressDetails.addressData.zipCode': isCoApplicantSectionVisible ? previousAddressField('zipCode', isSubmitting, true) : correctRule,

    // --- Current Employment Details --- //

    'coApplicantFinancialDetails.currentEmploymentDetails.employmentStatus': isCoApplicantSectionVisible ? listItemField(isSubmitting) : correctRule,
    'coApplicantFinancialDetails.currentEmploymentDetails.jobTitle': isCoApplicantSectionVisible ? jobTitleField(isSubmitting, true) : correctRule,
    'coApplicantFinancialDetails.currentEmploymentDetails.employerName': isCoApplicantSectionVisible ? employerNameField(isSubmitting, true) : correctRule,
    'coApplicantFinancialDetails.currentEmploymentDetails.employerPhoneNumber': isCoApplicantSectionVisible ? employerPhoneNumberField(isSubmitting, true) : correctRule,
    'coApplicantFinancialDetails.currentEmploymentDetails.workingStartDate': isCoApplicantSectionVisible ? workingStartDateField(isSubmitting, true) : correctRule,
    'coApplicantFinancialDetails.currentEmploymentDetails.income': isCoApplicantSectionVisible ? employmentIncomeField(isSubmitting, true) : correctRule,

    // --- Previous Employment Details --- //

    'coApplicantFinancialDetails.previousEmploymentDetails.employmentStatus': isCoApplicantSectionVisible ? previousStatusField(true) : correctRule,
    'coApplicantFinancialDetails.previousEmploymentDetails.jobTitle': isCoApplicantSectionVisible ? jobTitleField(isSubmitting, true, true) : correctRule,
    'coApplicantFinancialDetails.previousEmploymentDetails.employerName': isCoApplicantSectionVisible ? employerNameField(isSubmitting, true, true) : correctRule,
    'coApplicantFinancialDetails.previousEmploymentDetails.employerPhoneNumber': isCoApplicantSectionVisible ? employerPhoneNumberField(isSubmitting, true, true) : correctRule,
    'coApplicantFinancialDetails.previousEmploymentDetails.workingStartDate': isCoApplicantSectionVisible ? workingStartDateField(isSubmitting, true, true) : correctRule,
    'coApplicantFinancialDetails.previousEmploymentDetails.income': isCoApplicantSectionVisible ? employmentIncomeField(isSubmitting, true, true) : correctRule,
    'coApplicantFinancialDetails.previousEmploymentDetails.workingEndDate': isCoApplicantSectionVisible ? workingEndDateField(isSubmitting, true, true) : correctRule,

    // ------------------------------------------ //
    // ---           TradeIn Details          --- //
    // ------------------------------------------ //

    'tradeInDetails.tradeInVehicleVIN': isTradeInSectionVisible ? vinField(true) : correctRule,
    'tradeInDetails.tradeInVehicleYear': isTradeInSectionVisible ? vehicleYearField(isSubmitting) : correctRule,
    'tradeInDetails.tradeInVehicleMake': isTradeInSectionVisible ? makeField(isSubmitting) : correctRule,
    'tradeInDetails.tradeInVehicleModel': isTradeInSectionVisible ? modelField(isSubmitting) : correctRule,
    'tradeInDetails.tradeInVehicleTrim': isTradeInSectionVisible ? trimField(isSubmitting) : correctRule,
    'tradeInDetails.tradeInVehicleMileage': isTradeInSectionVisible ? mileageField(isSubmitting) : correctRule,
    'tradeInDetails.tradeInCredit': isTradeInSectionVisible && isSubmitting ? requiredField : correctRule,

    // ------------------------------------------ //
    // ---          Financing Details         --- //
    // ------------------------------------------ //

    'vehicleDetails.vehiclePrice': priceField(MAX_CAR_PRICE_VALUE, isSubmitting),
    salesTax: priceField(MAX_CREDIT_APP_SALES_TAX_VALUE, true),
    registrationTax: priceField(MAX_TAX_OR_FEE_VALUE, true),
    documentFee: priceField(MAX_TAX_OR_FEE_VALUE, true),
    transportationCost: priceField(MAX_PRICE_VALUE),
    'financingCalculatorDetails.downPayment': priceField(MAX_PRICE_VALUE, isSubmitting),
    'financingCalculatorDetails.term': isSubmitting ? requiredField : correctRule,

    // ------------------------------------------ //
    // ---          Warranty Details          --- //
    // ------------------------------------------ //

    // --- PEN Coverage --- //

    'coverageData.coverageDetails.[*].forms.PEN.providerId': isWarrantySectionVisible ? warrantyRequiredField('PEN', 'providerId') : correctRule,
    'coverageData.coverageDetails.[*].forms.PEN.coverageName': isWarrantySectionVisible ? warrantyRequiredField('PEN', 'coverageName') : correctRule,
    'coverageData.coverageDetails.[*].forms.PEN.termMonths': isWarrantySectionVisible ? warrantyRequiredField('PEN', 'termMonths') : correctRule,
    'coverageData.coverageDetails.[*].forms.PEN.deductibleAmount': isWarrantySectionVisible ? warrantyRequiredField('PEN', 'deductibleAmount') : correctRule,
    'coverageData.coverageDetails.[*].forms.PEN.totalPrice': isWarrantySectionVisible ? warrantyRequiredField('PEN', 'totalPrice') : correctRule,
    'coverageData.coverageDetails.[*].forms.PEN.cost': isWarrantySectionVisible ? warrantyRequiredField('PEN', 'cost') : correctRule,

    // // --- Manual Coverage --- //

    'coverageData.coverageDetails.[*].forms.Manual.providerId': isWarrantySectionVisible ? warrantyRequiredField('Manual', 'providerId') : correctRule,
    'coverageData.coverageDetails.[*].forms.Manual.coverageName': isWarrantySectionVisible ? warrantyRequiredField('Manual', 'coverageName') : correctRule,
    'coverageData.coverageDetails.[*].forms.Manual.termMonths': isWarrantySectionVisible ? warrantyRequiredField('Manual', 'termMonths') : correctRule,
    'coverageData.coverageDetails.[*].forms.Manual.deductibleAmount': isWarrantySectionVisible ? warrantyRequiredField('Manual', 'deductibleAmount') : correctRule,
    'coverageData.coverageDetails.[*].forms.Manual.totalPrice': isWarrantySectionVisible ? warrantyRequiredField('Manual', 'totalPrice') : correctRule,
    'coverageData.coverageDetails.[*].forms.Manual.cost': isWarrantySectionVisible ? warrantyRequiredField('Manual', 'cost') : correctRule,

    // ------------------------------------------ //
    // ---          Additional Costs          --- //
    // ------------------------------------------ //

    'additionalCosts.[*].amount': requiredNonZeroField(),
    'additionalCosts.[*].description': requiredField
  }
}

export default createValidationRules
