import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGTick: FC<IconProps> = ({ color = '#FFFFFF', width = 32, height = 32 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 32 32' fill='none'>
      <path
        d='M12.3315 21.6017L7.66487 16.9351C7.14487 16.4151 6.3182 16.4151 5.7982 16.9351C5.2782 17.4551 5.2782 18.2817 5.7982 18.8017L11.3849 24.3884C11.9049 24.9084 12.7449 24.9084 13.2649 24.3884L27.3982 10.2684C27.9182 9.74838 27.9182 8.92172 27.3982 8.40172C26.8782 7.88172 26.0515 7.88172 25.5315 8.40172L12.3315 21.6017Z'
        fill={color}
      />
    </svg>
  )
}

export default IconSVGTick
