import { FC, useState, useCallback, useEffect } from 'react'
import { cnx, Loader } from '@carfluent/common'

import CLASS_NAME from './styles'

interface OptionOfItems {
  id: number
  name: string
}

interface StatusItemProps {
  selectedStatusId: number | null
  item: OptionOfItems
  onClick: (statusId: number) => void
  isLoading: boolean
  isLost: boolean
}

const StatusItem: FC<StatusItemProps> = ({
  selectedStatusId,
  item,
  onClick: _onClick,
  isLoading,
  isLost
}) => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null)
  const active = item.id === selectedStatusId

  const onClick = useCallback((statusId: number) => {
    setSelectedItem(statusId)
    _onClick(statusId)
  }, [_onClick])

  useEffect(() => {
    if (!isLoading) {
      setSelectedItem(null)
    }
  }, [isLoading])

  const classNameForDisabledView = isLost ? 'disable' : null

  return (
    <div
      className={cnx(CLASS_NAME, 'lead-status', getStatusOfCompletedItem(active), classNameForDisabledView)}
      onClick={() => !isLost && onClick(item.id)}
    >
      {(selectedItem != null && isLoading)
        ? (
          <Loader
            className='icon-progress'
            color='inherit'
            size={14}
          />
          )
        : null}
      {item.name}
    </div>
  )
}

export default StatusItem

// ========================================== //

const getStatusOfCompletedItem = (active: boolean): string => {
  return active ? 'completed' : 'un-completed'
}
