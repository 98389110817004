import { css } from '@emotion/css'
import { ACTIVE_OPACITY, DEFAULT_OPACITY } from 'constants/constants'
import hexToRGBA from 'utils/hexToRGBA'

export interface StylesConfig {
  bgColor: string
  isEdit: boolean
}

const createStyleClass = ({ bgColor, isEdit }: StylesConfig): string => {
  return css(`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas:
    "avatar name"
    "avatar hours";
    grid-column-gap: 8px;
    align-items: center;
    border-radius: 90px;
    padding: 4px 10px;
    margin-bottom: 4px;
    max-width: 170px;
    min-width: 124px;
    width: 100%;
    background-color: ${hexToRGBA(bgColor, isEdit ? ACTIVE_OPACITY : DEFAULT_OPACITY)};

    :hover {
      background-color: ${hexToRGBA(bgColor, ACTIVE_OPACITY)};
      cursor: pointer;
    }

    .cf-shift-avatar {
      grid-area: avatar;
      
      > div {
        letter-spacing: 0.5px;
      }
    }
    
    .cf-shift-name {
      grid-area: name;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #101010;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    
    .cf-shift-hours {
      grid-area: hours;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: rgba(33, 33, 33, 0.5);
      white-space: nowrap;
    }
  `)
}

export default createStyleClass
