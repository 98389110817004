import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  .cf-button-root {
    min-width: 122px;
    height: 40px;
    padding: 8px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    :hover { opacity: 0.6; }

    .cf-button-end-adornment { height: 24px; }
  }
`)

export const POPOVER_CLASS_NAME = css(`
  &.cf-popover-content {
    padding: 0;

    .vehicles-dropdown {
      padding: 16px;
      width: 400px !important;

      .cf-input::placeholder { color: rgba(33, 33, 33, 0.4); }

      .cf-end-adornment:has(.cf-dropdown-clear-button) { 
        .cf-dropdown-trigger-button { display: none; }
      }
    }
  }
`)

export const DROPDOWN_POPOVER_CLASS_NAME = css(`
  &.cf-popover-content {
    position: relative;
    margin-top: 8px!important;
    padding-bottom: 8px;
    min-width: 400px;
    z-index: 1400;
    box-shadow: rgba(58, 71, 78, 0.1) 0px 30px 30px 0px;

    .cf-dropdown-listbox {
      max-height: 328px;
      padding: 0;
      border-radius: 0 0 8px 8px;
    }

    .cf-dropdown-option { 
      padding: 0px 16px;
      :has(.group-name) { opacity: 1; }
    }

    .cf-no-options-container { color: rgba(0, 0, 0, 0.54); }

    .option-render {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 6px 0px;

      &.option-deleted { opacity: 0.4; }
      &.group-name {
        color: rgba(33, 33, 33, 0.50);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
      &.no-options { font-weight: 400; }
    }

    .vehicle-state {
      display: flex;
      align-items: center;

      :before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
        background-color: rgba(0, 0, 0, 0.3);
      }

      &.active:before { background-color: #219653; }
      &.sold:before { background-color: #FFA451; }
    }

    .vehicle-name {
      display: flex;
      align-items: center;
      font-weight: 400;
      white-space: nowrap;
      margin-top: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-stock {
        font-weight: 500;
        margin-right: 8px;
      }
    }

    .option-sold, .option-deleted {
      .vehicle-name { color: rgba(33, 33, 33, 0.4); }
    }
  }
`)
