import { FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { cnx } from '@carfluent/common'

import Table from 'components/common/Table'
import TransactionDialogController from 'components/accounting/TransactionDialogController'

import AllCostBreakdown from './components/AllCostBreakdown'
import DetailsSummaryTabs from './components/DetailsSummaryTabs'
import CostsDetails from './components/CostsDetails'
import Summary from './components/Summary'
import PackCost from './components/PackCost'
import FloorplanCost from './components/Floorplan'

import type { CostsPanelProps } from './types'
import { columnCostsDefinitions } from './columns'
import { useCostsPanel } from './hook'
import { createStyleClass } from './styles'

export { GET_DEFAULT_PACK_COST, formatAccountName } from './components/PackCost'
export { GET_DEFAULT_FLOORPLAN } from './components/Floorplan'

const CostsPanel: FC<CostsPanelProps> = (props) => {
  const rootCls = useMemo(createStyleClass, [])
  const {
    costsPanelProps: {
      packCostProps,
      floorplanProps
    },
    isDisabled
  } = props

  const {
    costPanelProps,
    costsPanelDetailsProps,
    costsPanelSummaryProps,
    costsBreakdown,
    formControllerProps
  } = useCostsPanel(props)

  return (
    <div className={rootCls}>
      <div>
        <p className='all-costs-title'>All Costs</p>
        <div className={cnx('all-costs-table', isDisabled && 'cf-item-disabled')} data-test-id='list-cost'>
          <Table
            {...costPanelProps}
            columns={columnCostsDefinitions}
            embedAddButton
            emptyTableMessage='There are no costs yet'
            addRowLabel='Add Cost'
            addBtnTestId='btn-add-cost'
          />
        </div>

        <div className={isDisabled ? 'cf-item-disabled' : ''}>
          <PackCost {...packCostProps} />
          <FloorplanCost {...floorplanProps} />
        </div>
      </div>

      <div>
        <DetailsSummaryTabs
          detailsTabComponent={<CostsDetails {...costsPanelDetailsProps} />}
          summaryTabComponent={<Summary {...costsPanelSummaryProps} />}
          isDisabled={isDisabled}
        />
      </div>

      <AllCostBreakdown
        {...costsBreakdown}
        className='breakdown-component'
        isDisabled={isDisabled}
      />

      <TransactionDialogController {...formControllerProps} />
    </div>
  )
}

export default observer(CostsPanel)
