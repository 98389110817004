import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { type CoverageType } from 'types'
import { RadioGroup } from 'components/common/RadioGroup'
import RadioLabelControl from 'components/common/RadioLabelControl'

import CLASS_NAME from './styles'

export const COVERAGE_TYPE_LABELS: Record<CoverageType, string> = {
  PEN: 'PEN F&I list',
  Manual: 'Manual input'
}

export interface CoverageTypeSelectorProps {
  className?: string
  value: CoverageType
  disabledPen: boolean
  isProductDisabled?: boolean
  onChange: (value: CoverageType) => void
}

const CoverageTypeSelector: FC<CoverageTypeSelectorProps> = ({
  value,
  onChange,
  className,
  disabledPen,
  isProductDisabled
}) => {
  return (
    <RadioGroup
      className={cnx(CLASS_NAME, 'coverage-type-selector', className)}
      id='coverage'
      onChange={(_, value) => onChange(value as CoverageType)}
      value={value}
    >
      <RadioLabelControl
        label={COVERAGE_TYPE_LABELS.PEN}
        disabled={disabledPen || isProductDisabled}
        value='PEN'
      />

      <RadioLabelControl
        label={COVERAGE_TYPE_LABELS.Manual}
        disabled={isProductDisabled}
        value='Manual'
      />
    </RadioGroup>
  )
}

export default CoverageTypeSelector
