import type { FC } from 'react'
import { Typography } from '@material-ui/core'
import Button from 'components/common/Button'

import useStyles from './styles'

export interface AccountingDisabledProps {
  onEnable: () => void
}

const AccountingDisabled: FC<AccountingDisabledProps> = ({ onEnable }) => {
  const styles = useStyles()

  return (
    <div className={styles.root}>
      <Typography className={styles.header} variant='h2'>
        Accounting disabled
      </Typography>

      <Typography className={styles.description}>
        To start using it, enable accounting in Accounting Settings
      </Typography>

      <Button className={styles.button} onClick={onEnable}>
        Enable
      </Button>
    </div>
  )
}

export default AccountingDisabled
