import { type FC, useMemo } from 'react'
import { cnx, FormDatePicker, TimeSelect } from '@carfluent/common'
import { getTime, format, startOfToday, isValid, roundToNearestMinutes, isToday } from 'date-fns'

import { EXCLUDED_FILTER_OPTION } from 'api/defaults/campaigns'
import { SendScheduleType } from 'api/types/responses'
import { convertHtmlToText } from 'utils/wysiwyg'
import { addStep } from 'utils/timeIntervals'
import IconSVG from 'components/inlineImages'
import { RadioGroup } from 'components/common/RadioGroup'
import RadioLabelControl from 'components/common/RadioLabelControl'
import TooltipedText from 'components/common/TooltipedText'

import ActionsFooterAndUnsavedChanges from '../ActionsFooterAndUnsavedChanges'
import FilterChips from '../FilterChips'
import { type UseStepReviewProps } from './hook/types'
import useStepReview from './hook'
import CLASS_NAME from './styles'

export const STEP = 15
const DEFAULT_APPTS_FILTER = EXCLUDED_FILTER_OPTION()

export { type ReviewFormData } from './hook/types'

export interface StepReviewProps extends UseStepReviewProps {
  className?: string
  canDeleteCampaign: boolean
  onEditCampaignInformation: () => void
  onEditRecipients: () => void
  onDeleteCampaign: () => void
  timeZoneDisplayName: string | null
}

const StepReview: FC<StepReviewProps> = ({
  className,
  canDeleteCampaign,
  onEditCampaignInformation,
  onEditRecipients,
  onDeleteCampaign,
  timeZoneDisplayName,
  ...hookProps
}) => {
  const { campaign } = hookProps
  const today = startOfToday()
  const openingTime = format(roundToNearestMinutes(getTime(new Date()), { nearestTo: STEP }), 'hh:mm a')

  const {
    errors,
    isSubmitting,
    onBlur,
    onCancel,
    onChange,
    onChangeScheduleType,
    onGoBack,
    onSubmit,
    touched,
    values,
    unsavedChangesProps
  } = useStepReview(hookProps)

  /**
   * We need to check the opening time only if the date is today,
   * to check that time is not in the past.
   */
  const opening = useMemo(() => {
    if (values.date != null && isToday(values.date)) {
      return (openingTime != null) ? addStep(openingTime, STEP) : ''
    } else {
      return ''
    }
  }, [openingTime, values.date])

  return (
    <div className={cnx(CLASS_NAME, 'review-screen-wrapper', className)}>
      <div className='panels-list'>
        <div className='panel'>
          <div className='panel-title'>
            <h6>Campaign information</h6>

            <div className='btn-edit' onClick={onEditCampaignInformation}>
              <IconSVG.Pencil color='rgba(0, 0, 0, 1)' opacity={1} />
            </div>
          </div>

          <div className='panel-content'>
            <div className='campaign-field-title'>Campaign name:</div>
            <div className='campaign-field-value'>
              <TooltipedText
                className='tooltiped-text'
                value={getStringOrDash(campaign.contentDetails.name)}
              />
            </div>

            <div className='campaign-field-title'>Sender name:</div>
            <div className='campaign-field-value'>
              <TooltipedText
                className='tooltiped-text'
                value={getStringOrDash(campaign.contentDetails.senderName)}
              />
            </div>

            <div className='campaign-field-title'>Email subject:</div>
            <div className='campaign-field-value'>
              <TooltipedText
                className='tooltiped-text'
                value={getStringOrDash(campaign.contentDetails.emailSubject)}
              />
            </div>

            <div className='campaign-field-title'>Preheader:</div>
            <div className='campaign-field-value'>
              <TooltipedText
                className='tooltiped-text'
                value={getStringOrDash(campaign.contentDetails.preHeader)}
              />
            </div>

            <div className='campaign-field-title'>Email body:</div>
            <div className='campaign-field-value'>
              <TooltipedText
                className='tooltiped-text'
                value={getStringOrDash(convertHtmlToText(campaign.contentDetails.body))}
              />
            </div>
          </div>
        </div>

        <div className='panel'>
          <div className='panel-title'>
            <h6>Recipients</h6>

            <div className='btn-edit' onClick={onEditRecipients}>
              <IconSVG.Pencil color='rgba(0, 0, 0, 1)' opacity={1} />
            </div>
          </div>

          <div className='panel-content'>
            <div className='campaign-field-title'>Number of leads:</div>
            <div className='campaign-field-value bold'>{(campaign.filters.recipientsCounter ?? 0).toLocaleString('en-US')}</div>

            {(campaign.filters.filterLeadStatus != null) && (
              <>
                <div className='campaign-field-title'>Lead status:</div>
                <div className='campaign-field-value'>
                  <FilterChips {...campaign.filters.filterLeadStatus} />
                </div>
              </>
            )}

            {(campaign.filters.filterLeadSource != null) && (
              <>
                <div className='campaign-field-title'>Lead source:</div>
                <div className='campaign-field-value'>
                  <FilterChips {...campaign.filters.filterLeadSource} />
                </div>
              </>
            )}

            {(campaign.filters.filterLeadTemperature != null) && (
              <>
                <div className='campaign-field-title'>Lead temperature:</div>
                <div className='campaign-field-value'>
                  <FilterChips {...campaign.filters.filterLeadTemperature} />
                </div>
              </>
            )}

            {(campaign.filters.filterLeadWithScheduledAppointments != null) && (
              <>
                <div className='campaign-field-title'>Lead with appts:</div>
                <div className='campaign-field-value'>
                  <FilterChips
                    filter={campaign.filters.filterLeadWithScheduledAppointments?.filter ?? DEFAULT_APPTS_FILTER}
                    options={campaign.filters.filterLeadWithScheduledAppointments?.options ?? []}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className='panel send-panel'>
          <div className='panel-title'>
            <h6>Send</h6>
          </div>

          <div className='form-fields'>
            <RadioGroup
              className='send-schedule-type'
              id='coverage'
              onChange={onChangeScheduleType}
              value={values.type}
            >
              <RadioLabelControl
                label='Send campaign now'
                value={SendScheduleType.Now}
              />

              <RadioLabelControl
                label='Schedule campaign'
                value={SendScheduleType.Schedule}
              />
            </RadioGroup>

            <div className='fields-row'>
              {(values.type === SendScheduleType.Schedule) && (
                <FormDatePicker
                  error={errors.date}
                  id='date'
                  label='Date'
                  onBlur={onBlur}
                  onChange={onChange}
                  touched={touched.date}
                  value={values.date}
                  minDate={today}
                />
              )}

              {(values.type === SendScheduleType.Schedule) && (
                <div className='time-container'>
                  <TimeSelect
                    error={errors.time ?? undefined}
                    disabled={!isValid(values.date)}
                    id='time'
                    label='Time'
                    onChange={(value: string) => { onChange('time', value) }}
                    value={values.time ?? ''}
                    touched={touched.time}
                    opening={opening}
                    step={STEP}
                  />

                  <p className='time-zone-label'>
                    *{timeZoneDisplayName ?? 'Time zone not set'}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ActionsFooterAndUnsavedChanges
        isDeleteShown={canDeleteCampaign}
        isSubmitting={isSubmitting}
        onClose={onCancel}
        onDelete={onDeleteCampaign}
        onGoBack={onGoBack}
        onSubmit={onSubmit}
        titleSaveButton='SEND'
        unsavedChangesProps={unsavedChangesProps}
      />
    </div>
  )
}

export default StepReview

// ========================================== //

const getStringOrDash = (value: string | null): string => {
  return ((value != null) && (value?.length > 0)) ? value : '-'
}
