import { Routes } from 'constants/route_helper'
import type { LinkProps, ReportsLinksProps } from './types'

export enum LinkTitles {
  BalanceSheet = 'Balance sheet',
  ProfitLoss = 'Profit & Loss (Income Statement)',
  TrialBalance = 'Trial balance',
  Transactions = 'Transaction list',
  InventoryCosts = 'Inventory costs',
  GrossProfit = 'Gross profit report',
  SalesTax = 'Sales tax report',
  ReportAccountsAgingReceivable = 'A/R aging report',
  ReportAccountsAgingPayable = 'A/P aging report',
  LeadsByVehicle = 'Leads by vehicle',
  DealsSaleReport = 'Sale report',
  LeadsBySource = 'Leads by source',
  TitleReport = 'Title report',
  CarRepricing = 'Car repricing report',
  ReportActivity = 'Activity report'
}

export enum LinkDescriptions {
  BalanceSheet = 'A snapshot of your finances on a given day. The balance sheet calculates your business’s worth (equity) by subtracting what you owe (liabilities) from what you own (assets).',
  ProfitLoss = 'Shows your business’s net profit and summarizes your revenues and expenses in a given time period.',
  TrialBalance = 'The sum of debits and credits for all accounts. Helps you check for discrepancies to ensure your accounts balance.',
  Transactions = 'Your complete financial activity: A detailed list of all transactions and totals for every account in your Chart of Accounts.',
  InventoryCosts = 'Shows overview of the dealership’s current inventory, detailing the list of available cars along with their corresponding costs.',
  GrossProfit = 'Performance and profitability analysis per deal.',
  SalesTax = 'The sales tax report succinctly presents the total calculated sales tax for sold cars.',
  ReportAccountsAgingReceivable = 'Helps to analyze outstanding receivables and categorizes them based on the time the transactions have been outstanding.',
  ReportAccountsAgingPayable = 'Helps to analyze how much the dealership owes each entity and categorizes outstanding payables based on the time the transactions have been outstanding.',
  LeadsByVehicle = 'Report shows detailed information on leads per car, including their sources.',
  DealsSaleReport = 'Details on sold deals. Represents deal types, profits, sold F&I products, lienholder breakdown.',
  LeadsBySource = 'Report shows detailed information on leads per source, profit and conversion ratio.',
  TitleReport = 'A report that provides information about the legal status of a vehicle\'s title.',
  CarRepricing = 'Report that provides information about car repricing changes.',
  ReportActivity = 'Report shows representative activity, including resolved tasks, messages sent, and appointments created vs. held.'
}

export const ACCOUNTING_REPORT_LINKS: LinkProps[] = [
  {
    to: Routes.ReportAccountsAgingPayable,
    title: LinkTitles.ReportAccountsAgingPayable,
    description: LinkDescriptions.ReportAccountsAgingPayable
  },
  {
    to: Routes.ReportAccountsAgingReceivable,
    title: LinkTitles.ReportAccountsAgingReceivable,
    description: LinkDescriptions.ReportAccountsAgingReceivable
  },
  {
    to: Routes.ReportBalanceSheet,
    title: LinkTitles.BalanceSheet,
    description: LinkDescriptions.BalanceSheet
  },
  {
    to: Routes.ReportGrossProfit,
    title: LinkTitles.GrossProfit,
    description: LinkDescriptions.GrossProfit
  },
  {
    to: Routes.ReportProfitLoss,
    title: LinkTitles.ProfitLoss,
    description: LinkDescriptions.ProfitLoss
  },
  {
    to: Routes.ReportSalesTax,
    title: LinkTitles.SalesTax,
    description: LinkDescriptions.SalesTax
  },
  {
    to: Routes.ReportTransactions,
    title: LinkTitles.Transactions,
    description: LinkDescriptions.Transactions
  },
  {
    to: Routes.ReportTrialBalance,
    title: LinkTitles.TrialBalance,
    description: LinkDescriptions.TrialBalance
  }
]

export const CRM_REPORT_LINKS: LinkProps[] = [
  {
    to: Routes.ReportActivity,
    title: LinkTitles.ReportActivity,
    description: LinkDescriptions.ReportActivity
  },
  {
    to: Routes.ReportLeadsByVehicle,
    title: LinkTitles.LeadsByVehicle,
    description: LinkDescriptions.LeadsByVehicle
  },
  {
    to: Routes.ReportLeadsBySource,
    title: LinkTitles.LeadsBySource,
    description: LinkDescriptions.LeadsBySource
  }
]

export const DEALS_REPORT_LINKS: LinkProps[] = [
  {
    to: Routes.ReportDealsSaleReport,
    title: LinkTitles.DealsSaleReport,
    description: LinkDescriptions.DealsSaleReport
  }
]

export const INVENTORY_REPORT_LINKS: LinkProps[] = [
  {
    to: Routes.ReportInventoryCosts,
    title: LinkTitles.InventoryCosts,
    description: LinkDescriptions.InventoryCosts
  },
  {
    to: Routes.ReportCarRepricing,
    title: LinkTitles.CarRepricing,
    description: LinkDescriptions.CarRepricing
  },
  {
    to: Routes.ReportTitleReport,
    title: LinkTitles.TitleReport,
    description: LinkDescriptions.TitleReport
  }
]

export enum ReportsIcons {
  CRM,
  Inventory,
  Accounting,
  Deals
}

export const REPORTS_LISTS: ReportsLinksProps[] = [
  {
    title: 'Accounting',
    icon: ReportsIcons.Accounting,
    links: ACCOUNTING_REPORT_LINKS
  },
  {
    title: 'CRM',
    icon: ReportsIcons.CRM,
    links: CRM_REPORT_LINKS
  },
  {
    title: 'Deals',
    icon: ReportsIcons.Deals,
    links: DEALS_REPORT_LINKS
  },
  {
    title: 'Inventory',
    icon: ReportsIcons.Inventory,
    links: INVENTORY_REPORT_LINKS
  }
]
