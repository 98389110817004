import { type UseFormReturn } from '@carfluent/common'
import { type CheckboxType as CheckboxValue } from 'types'
import { type TransactionLineVendorDto } from 'api/types'
import { type DeleteModalProps } from 'components/dialogs/DeleteModal'

export type FloorplanCostProps = FloorplanCostOwnProps

export interface FloorplanCostOwnProps {
  lastResetTS: number | null
  lastTouchifyTS: number | null
  onDeleteFloorplan: () => Promise<void>
  setFieldValue: (id: string, value: unknown) => void
  setFormIsValid: (isValid: boolean) => void
  values: FloorplanFormData
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ErrTouchShortcuts = { vendor: true }

export interface FloorplanFormData {
  amount: number | null
  date: Date | null
  id: number | null
  isFloorplanEnabled: boolean
  vendor: TransactionLineVendorDto | null
}

export type DeleteFloorplanModalProps = Omit<DeleteModalProps, 'title' | 'content'>

export type UseFloorplanProps = FloorplanCostProps

export interface UseFloorplanReturn extends UseFormReturn<FloorplanFormData, ErrTouchShortcuts> {
  checkboxValue: CheckboxValue
  deleteModalProps: DeleteFloorplanModalProps
  isLastLineErrorVisible: boolean
  onAddVendor: (vendor: TransactionLineVendorDto) => Promise<void>
  onToggleFloorplan: () => void
  minStartDate: Date | null
}

export enum FloorplanFields {
  Amount = 'amount',
  Id = 'id',
  IsFloorplanEnabled = 'isFloorplanEnabled',
  Date = 'date',
  Vendor = 'vendor',
  CreditAccountId = 'creditAccountId',
  DebitAccountId = 'debitAccountId'
}
