import { css } from '@emotion/css'

export default css(`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 16px 0;

  .left-panel {
    display: flex;
    align-items: center;
    width: 100%;

    h5 {
      font-weight: 400;
    }
  }

  .actions-menu {
    .cf-menu-container {
      width: 240px;
    }

    .cf-menu-button {
      height: 100%;
    }
  }
`)

