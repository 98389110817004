import { useCallback, useMemo, useState } from 'react'
import { type Row } from '@tanstack/react-table'
import { type Preset, useModal } from '@carfluent/common'

import { TransactionTypeId, type TransactionListItem, type DictionaryItems } from 'api/types'
import AccountingApiProvider from 'api/accounting.api'
import { TransactionActionNames } from 'constants/constants'
import useAsyncEffect from 'hooks/useAsyncEffect'

import useTransactionsListTable from './useTransactionsListTable'
import getColumnsDefinition from './columns'
import { Errors, getPresets } from './constants'
import { type UseTransactionsReturn } from './types'

const PRESETS = getPresets()

const useTransactions = (): UseTransactionsReturn => {
  const [selectedTransactionTypeId, setTransactionTypeId] = useState<number>(TransactionTypeId.JournalEntry)
  const [transactionId, setTransactionId] = useState<number | null>(null)
  const [transactionTypeList, setTransactionTypesList] = useState<DictionaryItems<string>>([])
  const [activeDatePreset, setActiveDatePreset] = useState<Preset | null>(PRESETS[0])
  const transactionDetailsModal = useModal()

  const {
    emptyTableMessage,
    filters,
    getRows,
    getPageSize,
    isLoading,
    loadRows,
    onSearch,
    rows,
    search,
    setSearch,
    setSorting,
    setFilters,
    sorting
  } = useTransactionsListTable()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onOpenAddTransactionDetails = useCallback((transactionTypeId: number) => {
    setTransactionId(null)
    setTransactionTypeId(transactionTypeId)
    transactionDetailsModal.onOpenModal()
  }, [transactionDetailsModal.onOpenModal])

  const onCloseEditTransaction = useCallback((): void => {
    transactionDetailsModal.onCloseModal()
    setTransactionId(null)
    setTransactionTypeId(TransactionTypeId.JournalEntry)
  }, [transactionDetailsModal.onCloseModal])

  const onViewNextTransaction = useCallback((
    transactionId: number,
    transactionTypeId: number
  ) => {
    onCloseEditTransaction()

    setTimeout(() => {
      setTransactionId(transactionId)
      setTransactionTypeId(transactionTypeId)
      transactionDetailsModal.onOpenModal()
    }, 0)
  }, [
    onCloseEditTransaction,
    transactionDetailsModal.onOpenModal
  ])

  const onOpenJournalEntry = useCallback((row: Row<TransactionListItem>) => {
    onViewNextTransaction(row.original.id, row.original.transactionTypeId)
  }, [onViewNextTransaction])

  const onSubmitTransaction = useCallback(async () => {
    await loadRows({ forceRefresh: true, forceScrollToSkeleton: false })
  }, [loadRows])

  const onChangeFilterType = useCallback(async (transactionTypeFilters: number[]) => {
    await setFilters({ transactionTypeFilters })
  }, [setFilters])

  const onBottomReached = useCallback(async () => {
    if (!isLoading) {
      /**
       * Sometimes `onBottomReached` is triggered when table shows a page with
       * skeletons only (without rows).
       * For example, when filter forces refresh.
       * In this case we should not try to scroll to skeleton, to avoid page "jumping".
       * #lazyload, #bottom-reached
       */
      const skipScroll = getRows().length < getPageSize()
      await loadRows({ forceRefresh: false, forceScrollToSkeleton: !skipScroll })
    }
  }, [isLoading, loadRows, getRows, getPageSize])

  const onSortingChange = useCallback((...args: Parameters<typeof setSorting>): void => {
    void setSorting(...args)
  }, [setSorting])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Initial setup on mount.
   */
  useAsyncEffect(async () => {
    window.scrollTo({ top: 0 })

    try {
      const { items } = await AccountingApiProvider.getTransactionTypes()
      setTransactionTypesList(items)
    } catch {
      console.error(Errors.Types)
    }
  }, [])

  // ========================================== //

  const topPanelActions = useMemo(() => {
    return [
      {
        title: TransactionActionNames.JournalEntry,
        handleOnClick: () => onOpenAddTransactionDetails(TransactionTypeId.JournalEntry)
      },
      {
        title: TransactionActionNames.Payable,
        handleOnClick: () => onOpenAddTransactionDetails(TransactionTypeId.Payable)
      },
      {
        title: TransactionActionNames.PayBill,
        handleOnClick: () => onOpenAddTransactionDetails(TransactionTypeId.PayBill)
      },
      {
        title: TransactionActionNames.Check,
        handleOnClick: () => onOpenAddTransactionDetails(TransactionTypeId.Check)
      },
      {
        title: TransactionActionNames.Receivable,
        handleOnClick: () => onOpenAddTransactionDetails(TransactionTypeId.Receivable)
      },
      {
        title: TransactionActionNames.Receive,
        handleOnClick: () => onOpenAddTransactionDetails(TransactionTypeId.Receive)
      }
    ]
  }, [onOpenAddTransactionDetails])

  const columns = useMemo(() => getColumnsDefinition({
    onChange: onChangeFilterType,
    transactionTypeAppliedFilters: filters.transactionTypeFilters,
    transactionTypeList
  }), [
    filters.transactionTypeFilters,
    onChangeFilterType,
    transactionTypeList
  ])

  return {
    columns,
    emptyTableMessage,
    filtersProps: {
      activeDatePreset,
      appliedFilters: filters,
      presets: PRESETS,
      onFilterChange: setFilters,
      onPresetChange: setActiveDatePreset
    },
    isLoading,
    onBottomReached,
    onOpenJournalEntry,
    onSearch,
    onSortingChange,
    onSearchChange: setSearch,
    rows,
    sorting,
    search,
    topPanelActions,
    transactionDialogProps: {
      ...transactionDetailsModal,
      onCloseModal: onCloseEditTransaction,
      onSubmit: onSubmitTransaction,
      onViewNextTransaction,
      transactionId,
      transactionTypeId: selectedTransactionTypeId
    }
  }
}

export default useTransactions
