import type { FC, MouseEvent } from 'react'
import { CellWrapper, cn } from '@carfluent/common'

import downWhiteIcon from 'assets/white_down_arrow.svg'
import downBlackIcon from 'assets/black_down_arrow.svg'

import ConfigurableCellHOC from 'components/common/Table/cells/ConfigurableCellHOC'
import StatedIcon from 'components/common/StatedIcon'
import HiddenItemsLabel from 'components/common/HiddenItemsLabel'

import type { MultipleItemsHOCProps, MultipleItemsCellProps, ItemProps } from './types'
import CLASS_NAME from './styles'

function MultipleItemsCell <TItemProps extends ItemProps>({
  alwaysVisibleNum = 1,
  className,
  getValue,
  ItemComponent,
  moreLabel = 'more',
  VisibleItemComponent: _VisibleItemComponent
}: MultipleItemsCellProps<TItemProps>) {
  if (ItemComponent == null) {
    return null
  }

  const VisibleItemComponent = _VisibleItemComponent ?? ItemComponent
  const items = getValue() ?? []
  const [firstItem, ...otherItems] = items

  const handleLinkClick = (event: MouseEvent<Element>): void => {
    event?.stopPropagation()
  }

  if (items.length === 0) {
    return (
      <CellWrapper className={cn(CLASS_NAME, 'cf-multiple-items', className)}>
        {null}
      </CellWrapper>
    )
  }

  if (items.length <= alwaysVisibleNum) {
    return (
      <CellWrapper className={cn(CLASS_NAME, 'cf-multiple-items', className)}>
        {items.map((item) => (
          <ItemComponent
            {...item}
            className='cf-item'
            isAlwaysVisible
            key={item.id}
          />
        ))}
      </CellWrapper>
    )
  }

  return (
    <CellWrapper
      className={cn(CLASS_NAME, 'cf-multiple-items', 'cf-multiple-items-expanded', className)}
    >
      <VisibleItemComponent
        {...firstItem}
        className='cf-item cf-always-visible-item'
        isAlwaysVisible
      />

      <HiddenItemsLabel
        isClosedByLabelMouseOut={false}
        onClick={handleLinkClick}
        className='cf-label-more'
        text={`+${items.length - 1} ${moreLabel}`}
        IconComponent={IconComponent}
        content={
          otherItems.map((item) =>
            <ItemComponent key={item.id} className='cf-item' {...item} />
          )
        }
        popoverProps={{
          placement: { horizontal: 'left' },
          transform: { x: 200 }
        }}
      />
    </CellWrapper>
  )
}

function MultipleItemsCellHOC <TItemProps extends ItemProps>(hocProps: MultipleItemsHOCProps<TItemProps>) {
  const CellComp: FC<MultipleItemsCellProps<TItemProps>> = MultipleItemsCell
  return ConfigurableCellHOC(CellComp, hocProps)
}

const IconComponent: FC<{ isActive?: boolean }> = ({ isActive }) => (
  <StatedIcon isActive={isActive} src={downBlackIcon} activeSrc={downWhiteIcon} />
)

export default MultipleItemsCellHOC
