import { css } from '@emotion/css'

export default css(`
  height: 220px;
  flex-basis: 400px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.03), 0px 10px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 8px 5px rgba(0, 0, 0, 0.02);

  .line1 { width: 200px; }
  .line2 { width: 120px; }
  .line3 { display: flex; gap: 4px; }
  .line3 .left { width: 16px }
  .line3 .right { width: 160px }
  .line4 { width: 180px; }
  .line6 { width: 160px; }
`)

