import { type SettingsGeneralProps, ChartType } from '../types'

const isValid = (values: SettingsGeneralProps['values']): boolean => {
  const { startDate, typeOfChart, fileUrl } = values

  if (startDate == null) {
    return false
  }

  if (typeOfChart === ChartType.Custom) {
    return fileUrl != null
  }

  return typeOfChart === ChartType.Default
}

export default isValid
