import {
  type DealerDefaultDto,
  type CoverageProductTypesDto,
  type CoverageProvidersDto,
  type DealCoverageModal,
  type DealerDefaultCoverageDto,
  type DealerProductsDto,
  type DealerProviderCoverageDto,
  type FISettingsDto,
  type FISettingsModel,
  type SettingsPackageTypesDto,
  type DealerProductCoverageMarkupsDto
} from 'api/types/responses'

import {
  type UpdateDealerDefaultCoverageDto,
  type UpdateProductsDealDefaultsDto,
  type UpdateFISettingsDto
} from 'api/types/requests'

import {
  parseCoverageDetails,
  parseFISettingsDto,
  parsePenProducts
} from 'api/mappers/responses'

import { trimSlashEnd } from 'utils/general'

import { WrapperRequest } from './wrapper.api'
import { type ListResponse } from './types'

class CoverageApi extends WrapperRequest {
  url = trimSlashEnd(process.env.REACT_APP_INTEGRATIONSPEN ?? '')

  getFISettings = async (): Promise<FISettingsModel> => {
    const res: FISettingsDto = await this.get(`${this.url}/api/v1/Settings`)

    return parseFISettingsDto(res)
  }

  updateFISettings = async (payload: UpdateFISettingsDto): Promise<FISettingsModel> => {
    const res: FISettingsDto = await this.patch(`${this.url}/api/v1/settings`, payload)

    return parseFISettingsDto(res)
  }

  getPenCoverageProducts = async (): Promise<ListResponse<DealerProviderCoverageDto>> => {
    return await this.get(`${this.url}/api/v1/Products/pen`)
  }

  getDefaultCoverage = async (): Promise<ListResponse<DealerDefaultCoverageDto>> => {
    return await this.get(`${this.url}/api/v1/Products/defaults`)
  }

  updateDefaultCoverage = async (payload: UpdateDealerDefaultCoverageDto): Promise<ListResponse<DealerDefaultCoverageDto>> => {
    return await this.patch(`${this.url}/api/v1/Products/defaults`, payload)
  }

  updatePackagesNames = async (payload: SettingsPackageTypesDto): Promise<FISettingsModel> => {
    const res: FISettingsDto = await this.patch(`${this.url}/api/v1/Settings/packages`, payload)

    return parseFISettingsDto(res)
  }

  getProductTypes = async (): Promise<ListResponse<CoverageProductTypesDto>> => {
    return await this.get(`${this.url}/api/v1/Reference/product-types`)
  }

  getCoverageProviders = async (): Promise<ListResponse<CoverageProvidersDto>> => {
    return await this.get(`${this.url}/api/v1/Settings/providers`)
  }

  getDealProducts = async (dealId: number | string): Promise<DealerProductsDto> => {
    return await this.get(`${this.url}/api/v1/Products/deal/${dealId}`)
  }

  getDealCoverages = async (dealId: number | string, isCashPayment: boolean): Promise<DealCoverageModal> => {
    const [{ penProducts, defaults }, { items: providers }] = await Promise.all([
      this.getDealProducts(dealId),
      this.getCoverageProviders()
    ])

    return {
      penProducts: parsePenProducts({ penProducts, providers, isCashPayment, defaults }),
      coverageDetails: parseCoverageDetails({ penProducts, defaults, isCashPayment })
    }
  }

  updateProductsDealDefaults = async ({ dealId, data }: { dealId: number | string, data: UpdateProductsDealDefaultsDto }): Promise<ListResponse<DealerDefaultDto>> => {
    return await this.patch(`${this.url}/api/v1/Products/deal/${dealId}/defaults`, data)
  }

  updateFIProductsMarkups = async (data: DealerProductCoverageMarkupsDto): Promise<FISettingsModel> => {
    const res = await this.patch(`${this.url}/api/v1/Settings/markups`, data)

    return parseFISettingsDto(res)
  }
}

export const CoverageApiProvider = new CoverageApi()
export default CoverageApiProvider
