import { type EntityId } from 'types'
import { type UpdateFinancingFeesDto } from 'api/types/requests'
import { type FinancingFeesModel } from 'api/types/responses'
import { percentToDecimal } from 'utils/math'

const serializeFinancingFees = (
  dealId: EntityId,
  rowVersion: string,
  data: FinancingFeesModel
): UpdateFinancingFeesDto => {
  return {
    ...data,
    dealId,
    rowVersion,
    buyRate: data.buyRate != null ? percentToDecimal(data.buyRate) : null
  }
}

export default serializeFinancingFees
