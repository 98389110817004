import { type StatementUploadFormData } from './types'

export const GET_DEFAULT_FORM_DATA = (): StatementUploadFormData => ({
  account: null,
  files: [],
  [FieldsId.FileId]: null,
  bank: null
})

export enum FieldsId {
  FileId = 'fileId'
}

export enum Messages {
  AccountRequired = 'Please select account',
  BankRequired = 'Please select bank',
  DuplicatesInfo = 'Please note that a statement with the same name has already uploaded.',
  FileRequired = 'Please upload statement',
  InvalidFormat = 'Invalid format',
  SomethingWrong = 'Something went wrong.'
}

export const FILE_EXTENSIONS = ['csv', 'txt', 'CSV', 'TXT']
