import { css } from '@emotion/css'
import { px, Responsive } from 'constants/constants'

export default css`
  .header {
    height: var(--header-height);
    background: #101010;
    padding: 0 40px;
  }

  .header-logo {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .page-wrapper {
    display: flex;
    width: 100%;
  }

  .background-image-wrapper {
    flex: 1;
    height: calc(100vh - 60px);
    @media screen and (max-width: ${px(Responsive.Tablet)}) {
      display: none;
    }
  }

  .background-image {
    width: 100%;
    height: calc(100vh - 60px);
    object-fit: cover;
  }

  .content-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-top: 176px;
    @media screen and (max-width: ${px(Responsive.Tablet)}) {
      margin-top: 72px;
    }
  }
`
