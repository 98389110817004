import type { FC } from 'react'
import { cn } from '@carfluent/common'
import { Tooltip } from '@material-ui/core'

import Avatar from 'components/common/NameWithAvatar'
import useEllipsisHover from 'hooks/useEllipsisHover'

import { ChipProps } from './types'

import { AVATAR_CLASS_NAME, TOOLTIP_CLASS_NAME } from './styles'

const Chip: FC<ChipProps> = ({
  userName,
  shiftInfo,
  shiftColorStyle
}) => {
  const TooltipTitle = (): JSX.Element => (
    <div>
      <div className='cf-tooltip-name'>
        {userName}
      </div>
      <div className='cf-tooltip-info'>
        {shiftInfo}
      </div>
    </div>
  )

  const {
    isShowTooltip,
    titleRef,
    onMouseEnter,
    onMouseLeave
  } = useEllipsisHover()

  return (
    <>
      <Avatar
        className={cn(AVATAR_CLASS_NAME, 'cf-shift-avatar')}
        avatarStyle={shiftColorStyle}
        showAvatar
        avatarOnly
        name={userName}
      />

      <Tooltip
        placement='bottom'
        open={isShowTooltip}
        classes={{ tooltip: TOOLTIP_CLASS_NAME }}
        title={<TooltipTitle />}
      >
        <span
          className='cf-shift-name'
          ref={titleRef}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {userName}
        </span>
      </Tooltip>
    </>
  )
}

export default Chip
