import { type FC, type ReactNode, useRef } from 'react'
import { cnx, utils } from '@carfluent/common'

import type { Coords, Shift } from 'types'
import Button from 'components/common/Button'
import Icon from 'components/common/Icon'
import CalendarChip from 'components/crm/Chips/CalendarChip'
import { getCoords } from 'components/crm/Chips/helpers'

import { createButtonStyle } from '../../styles'

const { isCurrentDate } = utils.dates

interface DayProps {
  date: Date | null
  shifts: Shift[] | null
  editShift: Shift | null
  isBDC: boolean
  isAddBtnActive: boolean
  children?: ReactNode | ReactNode[]
  onOpenAddShift: (date: Date) => void
  onOpenEditShift: (shift: Shift) => void
  changeChipPosition: (pos: Coords) => void
}

const Day: FC<DayProps> = ({
  date,
  shifts,
  onOpenAddShift,
  onOpenEditShift,
  editShift,
  isBDC,
  isAddBtnActive,
  changeChipPosition
}) => {
  const elementRef = useRef(null)

  const onClick = (): void => {
    if (date != null) {
      onOpenAddShift(date)
      changeChipPosition(getCoords(elementRef.current as unknown as HTMLElement))
    }
  }

  if (date == null) {
    return <div className='cf-calendar-day' />
  }

  const BUTTON_CLASS = createButtonStyle({ isActive: isAddBtnActive })

  return (
    <div className='cf-calendar-day'>
      <p className={cnx('cf-calendar-day-number', isCurrentDate(date) && 'current-day')}>
        {date?.getDate()}
      </p>
      {shifts?.map(shift => {
        const isEdit = editShift?.id === shift.id
        return (
          <CalendarChip
            key={shift.id}
            shift={shift}
            isEdit={isEdit}
            onClick={onOpenEditShift}
            changeChipPosition={changeChipPosition}
          />
        )
      })}
      <div ref={elementRef} style={{ minWidth: '124px', width: '100%' }}>
        {!isBDC && (
          <Button
            variant='text'
            type='secondary'
            startIcon={<Icon name='roundedPlus' />}
            onClick={onClick}
            className={cnx(BUTTON_CLASS, 'cf-calendar-add-shift')}
          >
            Add Shift
          </Button>)}
      </div>
    </div>
  )
}

export default Day
