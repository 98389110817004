import { css } from '@emotion/css'

const ANIMATION_CONFIG = '300ms cubic-bezier(0.4, 0.0, 0.2, 1)'

/**
 * DD-TODO:
 * move styles to corresponding components.
 */

const TAB_SEPARATOR_STYLE = `
  content: '';
  position: absolute;
  height: 16px;
  width: 1px;
  background-color: #0000001F;
  transition: background-color ${ANIMATION_CONFIG};
`

export default css(`
  &.g-filter-panel-tabs {
    position: relative;

    .g-filter-tab-active-indicator {
      position: absolute;
      height: 32px;
      background-color: #C99B86;
      border-radius: 8px;
      top: 50%;
      transform: translateY(-50%);
      transition: left ${ANIMATION_CONFIG}, width ${ANIMATION_CONFIG};
      pointer-events: none;
    }

    & > .g-filter-tabs-container {
      display: grid;
      grid-auto-flow: column;
      justify-content: flex-start;
      align-items: center;

      grid-column-gap: 8px;
      background-color: #fff;
      border-radius: 8px;
      padding: 4px;
      height: var(--max-height);
      box-sizing: border-box;
      width: min-content;

      button.cf-button-root {
        width: max-content;
        min-width: unset;
        position: relative;
        height: 32px;
        border-radius: 8px;
        padding: 4px 16px;
        box-sizing: border-box;
        border: none;
        background-color: transparent;
        color: #101010;
        letter-spacing: 0.4px;
        text-decoration: none;
        transition: color ${ANIMATION_CONFIG};

        :after {
          right: -4px;
        }

        :before {
          left: -4px;
        }

        :after, :before {
          ${TAB_SEPARATOR_STYLE}
        }

        :last-of-type:after {
          display: none;
        }

        :first-of-type:before {
          display: none;
        }

        .g-filter-tab-counter {
          display: inline-block;
          color: #C99B86;
          transition: color ${ANIMATION_CONFIG};
        }

        &.g-filter-tab {
          :hover {
            background-color: #f3f3f3;
          }
        }

        &.g-active-filter-tab {
          color: #fff;

          :hover {
            background-color: transparent;
          }

          .g-filter-tab-counter {
            color: #fff;
          }
        }
      }
    }
  }
`)
