import type { FC } from 'react'

import Button from 'components/common/Button'
import { TaskCompleteOptions, TaskMode } from 'pages/crm/LeadDetailsView/hook/types'

import CLASS_NAME from './styles'

interface ActionBarProps {
  taskId: number
  taskMode: TaskMode | null
  onViewChat: () => void
  onSubmit: (taskCompleteOptionId: TaskCompleteOptions) => void
  isLoading: boolean
  buttonsClassName?: string
}

const ActionBar: FC<ActionBarProps> = ({
  taskId,
  taskMode,
  buttonsClassName = '',
  isLoading,
  onViewChat,
  onSubmit
}) => {
  const isTaskMode = (taskMode != null)

  const taskSpecificButtons = [
    {
      label: 'I RESPONDED',
      disabled: isLoading,
      onClick: () => onSubmit(TaskCompleteOptions.Responded),
      'data-test-id': 'schedule-task-btn-i-responded'
    },
    {
      label: 'NO RESPONSE NEEDED',
      disabled: isLoading,
      onClick: () => onSubmit(TaskCompleteOptions.NoResponseNeeded),
      'data-test-id': 'schedule-task-btn-no-response-needed'
    },
    {
      label: 'VIEW CHAT',
      disabled: isLoading,
      onClick: onViewChat,
      'data-test-id': 'schedule-task-btn-view-email'
    }
  ]

  return (
    <>
      {isTaskMode &&
        <div className={CLASS_NAME}>
          {taskSpecificButtons.map(({
            disabled,
            onClick,
            'data-test-id': dataTestId,
            label
          }) => (
            <Button
              key={`${taskId}_${label}`}
              className={buttonsClassName}
              disabled={disabled}
              type='secondary'
              onClick={onClick}
              data-test-id={dataTestId}
            >
              {label}
            </Button>
          ))}
        </div>}
    </>
  )
}

export default ActionBar
