import { dates, type Preset, UI, PRESET_LABELS } from '@carfluent/common'
import addMonths from 'date-fns/addMonths'
import type { TrialBalanceReport } from './types'
import fileNames from 'utils/reports/fileNames'

export const getDefaultData = (): TrialBalanceReport => ({
  totalCredits: 0,
  totalDebits: 0,
  accounts: []
})

export const PRESET_END_OF_LAST_MONTH: string = PRESET_LABELS.PRESET_END_OF_LAST_MONTH

export const REPORT_FILE_NAMES = fileNames('TrialBalanceReport')

export const getPresets = (): Preset[] => {
  const today = new Date()
  const prevMonthStart = addMonths(dates.getMonthStart(today), -1)
  const prevMonthEnd = dates.getMonthEnd(prevMonthStart)
  const defaultPresets = UI.getDefaultDateRangePresets(today, prevMonthEnd) // any maxDate will be OK
  const todayRange = defaultPresets.filter(p => p.name === UI.PRESET_LABELS.TODAY)
  const yesterdayRange = defaultPresets.filter(p => p.name === UI.PRESET_LABELS.YESTERDAY)

  return todayRange
    .concat(yesterdayRange)
    .concat([{ name: PRESET_END_OF_LAST_MONTH, startDate: prevMonthEnd, endDate: prevMonthEnd }])
}

export const DEFAULT_PRESETS = getPresets()
