import { css } from '@emotion/css'

export default css(`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 24px;

  .timeline-item-title {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #101010;

    svg {
      margin-right: 8px;
    }

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  
  .timeline-item-title > div {
    line-height: 24px;
    margin-left: 8px;
  }

  .timeline-item-date {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #21212180;
    margin-left: 8px;
  }
`)
