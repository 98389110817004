import { formatCurrencyDecimal } from '@carfluent/common'

/**
 * 0.01 ==> '$0.01'
 * -0.01 ==> '($0.01)
 */

const formatCurrencyLabel = (
  value: number | string | null | undefined,
  withPrefix: boolean = true,
  emptyValues: string = '$0.00'
): string | null => {
  const formattedValue = formatCurrencyDecimal(
    value || 0,
    { allowNegative: true, emptyValues, prefix: withPrefix ? '$' : '' }
  )
  return Number(value) < 0 ? `(${formattedValue})` : formattedValue
}

export default formatCurrencyLabel
