import { type FC } from 'react'
import { Skeleton } from '@material-ui/lab'

import CLASS_NAME from './styles'

const LOADING_ROWS = 6

const SkeletonTable: FC = () => {
  return (
    <div className={CLASS_NAME}>
      {new Array(LOADING_ROWS).fill(null).map((_, i) => (
        <div
          className='skeleton-row'
          key={i}
        >
          <div>
            <Skeleton width={16} height={16} variant='text' />
          </div>
          <div>
            <Skeleton height={16} variant='text' />
          </div>
        </div>
      ))}
    </div>
  )
}

export default SkeletonTable
