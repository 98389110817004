import { css } from '@emotion/css'

export default css(`
  &.cf-cell-wrapper-container {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      font-family: Roboto, sans-serif;
      font-size: 16px;
    }
  }

  .cf-costs-details-description-cell-title {
    font-weight: 500;
  }

  .cf-costs-details-description-cell-text {
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 24px;
  }
`)
