import { ReactNode } from 'react'
import { formatCurrencyAccounting, formatCurrencyDecimal } from '@carfluent/common'
import { NavLink } from 'react-router-dom'

import { formatDate, toDateOrNull } from 'utils/parse_date'
import { type GrossProfitRecord } from 'api/types'

import TooltipedText from 'components/common/TooltipedText'
import CalcRoutes from 'constants/route_helper'

export const EMPTY_FORMAT_VALUE = { emptyValues: '-' }
export const ACCOUNTING_FORMAT_VALUE = { ...EMPTY_FORMAT_VALUE, allowNegative: true }

const FORMAT_DATE = 'MM/dd/yy'

export interface ColumnType {
  className: string
  title: string
  data: (item: GrossProfitRecord) => string | ReactNode
}

export const COLUMNS: ColumnType[] = [
  {
    className: 'date-cell',
    title: 'Sale date',
    data: (item) => {
      const date = typeof item.date === 'string' ? toDateOrNull(item.date) : item.date
      const value = date != null ? formatDate(date, FORMAT_DATE) ?? '-' : '-'
      return <TooltipedText value={value} />
    }
  },
  {
    className: 'stock-cell',
    title: 'Stock #',
    data: (item) => <TooltipedText value={item.stock ?? '-'} />
  },
  {
    className: 'car-cell',
    title: 'Car',
    data: (item) => {
      const carName = item.carDescription ?? '-'

      return (
        item.inventoryVehicleId != null
          ? (
            <NavLink
              className='vehicle-link'
              to={CalcRoutes.VehicleDetails(item.inventoryVehicleId)}
              onClick={(event) => event.stopPropagation()}
            >
              <TooltipedText value={carName} />
            </NavLink>
            )
          : <TooltipedText value={carName} />
      )
    }
  },
  {
    className: 'buyer-cell',
    title: 'Buyer',
    data: (item) => <TooltipedText value={item.buyerName ?? '-'} />
  },
  {
    className: 'sales-person-cell',
    title: 'Salesperson',
    data: (item) => <TooltipedText value={item.salesName ?? '-'} />
  },
  {
    className: 'sold-to-cell',
    title: 'Sold to',
    data: (item) => <TooltipedText value={item.customerName ?? '-'} />
  },
  {
    className: 'sale-price-cell',
    title: 'Sale price',
    data: (item) => <TooltipedText value={formatCurrencyDecimal(item.salePrice, EMPTY_FORMAT_VALUE)} />
  },
  {
    className: 'front-gross-cell',
    title: 'Front gross',
    data: (item) => <TooltipedText value={formatCurrencyAccounting(item.frontGross, ACCOUNTING_FORMAT_VALUE)} />
  },
  {
    className: 'fi-gross-cell',
    title: 'Back gross',
    data: (item) => <TooltipedText value={formatCurrencyAccounting(item.backGross, ACCOUNTING_FORMAT_VALUE)} />
  },
  {
    className: 'total-gross-cell',
    title: 'Total gross',
    data: (item) => <TooltipedText value={formatCurrencyAccounting(item.totalGross, ACCOUNTING_FORMAT_VALUE)} />
  }
]
