import type { FC } from 'react'
import { cn, CellWrapper } from '@carfluent/common'

import ConfigurableCellHOC from 'components/common/Table/cells/ConfigurableCellHOC'
import CLASS_NAME from './styles'

export interface TextListHOCProps {
  className?: string
}

export interface TextListCellProps extends TextListHOCProps {
  getValue: () => string[] | number[]
}

export const TextListCell: FC<TextListCellProps> = ({
  className,
  getValue,
}) => {
  const lines = getValue() ?? []

  return (
    <CellWrapper className={cn(CLASS_NAME, 'cf-cell-text-list', className)}>
      {lines.map((line, idx) => (
        <div key={idx} className='cf-text-line'>
          <p>{line}</p>
        </div>
      ))}
    </CellWrapper>
  )
}

export const TextListHOC = (hocProps: TextListHOCProps) => ConfigurableCellHOC(TextListCell, hocProps)
