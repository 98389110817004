import { type TransactionsListGetRequestDto } from 'api/types'
import serializeFiltersLocal from 'utils/filters/serializeFiltersLocal'

import { type TransactionsListFilters } from './types'

export const serializeFilters = (data: TransactionsListFilters): TransactionsListGetRequestDto => {
  return {
    ...serializeFiltersLocal(data),
    skip: data.skip,
    take: data.take,
    transactionTypeFilters: data.transactionTypeFilters
  }
}
