import type {
  AmountRangeFilter,
  BaseListItem,
  DateRangeFilter,
  EntityId
} from 'types'

import type { ListPayload, ListResponse, EntityDictionary, DictionaryItem } from './utility.types'
import { SortOrder } from './utility.types'

export enum TransactionTypeId {
  JournalEntry = 1,
  Cost = 2, // AZ-NOTE: deprecated, will be removed in next PRs
  Receivable = 3,
  Receive = 4,
  Payable = 5,
  Check = 6,
  PayBill = 8,
}

export enum CostTypeId {
  Purchase = 1,
  Recon = 2,
  Detail = 3,
  Gas = 4,
  Transportation = 5,
  Shipping = 6,
  Advertising = 7,
  Other = 8
}

export enum ProfitLossTypes {
  Profit = 'Profit',
  Expense = 'Expense',
  NetProfit = 'Net Profit'
}

export enum ProfitLossSubtypes {
  CostOfGoodsSold = 'CostOfGoodsSold',
  Income = 'Income'
}

export enum EntityTypeId {
  Customer = 0,
  Vendor = 1
}

export enum CostTypeEnum {
  Purchase = 'Purchase cost',
  Recon = 'Recon',
  Detail = 'Detail',
  Gas = 'Gas',
  Transportation = 'Transportation',
  Shipping = 'Shipping',
  Advertising = 'Advertising',
  Other = 'Other'
}

export enum ReportAccountTypes {
  Assets = 'Asset',
  Liabilities = 'Liability',
  Equity = 'Equity'
}

export enum TransactionControlTypeId {
  Vehicle = 0,
  Deal = 1
}

export enum TransactionStateId {
  Filled = 0,
  Draft,
  Voided,
  Refunded
}

export enum AccountCategoryId {
  Banks = 1,
  UndepositedFunds = 2,
  Receivable = 3,
  Inventory = 4,
  Fixed = 5,
  Other = 6,
  CreditCard = 7,
  Payable = 9,
  BankChecking = 11
}

export enum AccountTypeId {
  Liability = 1,
  Asset = 2,
  Income = 3,
  CostOfGoodsSold = 4,
  Equity = 5,
  Expense = 6
}

export enum BankStatementTransactionType {
  All = 0,
  Deposit = 1,
  Withdrawal = 2
}

export enum BankStatementTransactionState {
  ForReview = 0,
  Categorized = 1,
  Excluded = 2
}

export enum BankStatementTransactionClearMethod {
  Categorize = 0,
  Link = 1,
  Pay = 2,
  Receive = 3
}

export enum ReconcileStatusId {
  None = 0,
  Cleared,
  Reconciled,
  ClearedAndReconciled
}

export interface AddAccountPayload {
  name: string
  number: number | null
  description: string | null
  accountType: number | null
  accountCategory: number | null
}

/**
 * @deprecated - use `AccountDto` instead.
 */
export interface UpdateAccountPayload extends AddAccountPayload {
  id: number
}

export interface AccountDto {
  accountCategory: number | null
  accountType: number
  description: string | null
  id: number
  name: string
  number: number
}

export interface AccountListPayload extends ListPayload {
  categoryIds?: number[]
  typeIds?: number[]
  suggestedCategoryIds?: number[]
  suggestedTypeIds?: number[]
}

export interface AccountListItem extends BaseListItem {
  accountCategory: number | null
  accountType: number
  description: string | null
  number: number
}

/**
 * @deprecated - use `api/types/DTOs/AccountDto`
 */
export interface Account extends AccountListItem {
  closingBalance: number
  currentMonthBalance: number
  hasBankStatement: boolean
  monthOpeningBalance: number
}

export interface AccountBalanceListItem {
  accountCategory: number | null
  accountType: number
  balance: number
  description: string | null
  hasBankStatement: boolean
  id: number
  name: string
  number: number
}

export interface AccountingTypesResponse {
  items: DictionaryItem[]
}

interface CostItem { costType: number, amount: number }

export interface AccountingCostListResponse {
  items: VehicleCostListItemDto[]
  totals: CostsTotalsParams
}

export interface VehicleCostListItemDto {
  amount: number
  costTypeId: number
  date: string
  id: number
}

export interface CostsTotalsParams {
  costs: CostItem[]
  otherInventoryCosts: number | null
}

export interface CustomerListPayload extends ListPayload {
  firstName?: string
  lastName?: string
}

export interface VendorListPayload extends ListPayload {
  name?: string
}

export interface AccountTransaction extends BaseListItem {
  account: string | null
  accountId: number
  change: number
  closingBalance: number
  control: number | null
  controlEntity: Control | null
  controlId: string | null
  costTypeId: number
  date: string | null
  dealId: number | null
  id: number
  notes: string | null
  reconcileStatus: ReconcileStatusId
  transactionId: number
  transactionTypeId: number
  transactionStateId: TransactionStateId
}

export interface AccountingSettingsResponse {
  id: number
  accountingStartDate: string
  packHoldingAccountNumber: number
  packInventoryAssetAccountNumber: number
  floorplanDebitAccountNumber: number
  floorplanCreditAccountNumber: number
  receiveAccountNumber: number
  checkAccountNumber: number
  fiscalYearStartMonth: number | null
  retailVehicleCostsAccountNumber: number | null
  costDebitAccountNumber: number
  costCreditAccountNumber: number
  printCheckNumber: boolean
  printHorizontalOffset: number
  printVerticalOffset: number
  printHeaderCheckNumberRowMarginBottom: number
  printHeaderDateRowMarginBottom: number
  printHeaderAmountRowMarginBottom: number
  printHeaderAmountWordsRowMarginBottom: number
  printHeaderEntityWithAddressRowMarginBottom: number
  printTable1MarginTop: number
  printTable1MarginBottom: number
}

export interface AccountingSettingsPayload {
  printCheckNumber: boolean
  printHorizontalOffset: number
  printVerticalOffset: number
}

export interface FiscalYearMonths {
  id: number
  number: number
  month: string
  isLocked: boolean
  lockedDateTime: string
}

export interface AccountingFiscalYearsLockedDto {
  id: number
  startDateInclusive: string
  endDateExclusive: string
}

export interface AccountingFiscalYearsResponse {
  id: number
  year: number | null
  isClosed: boolean
  closedDateTime: string | null
  fiscalYearMonths: FiscalYearMonths[]
}

export interface AccountingUpdateLockStateForMonthPayload {
  yearId: number
  monthId: number
  isLocked: boolean
}

export interface AccountingEnableRequest {
  fiscalYearStartMonth: number
  useDefaultAccounts: boolean
  uploadedAccountsFileUrl: string | null
}

export interface AccountingEnableResponse {
  warnings: Warnings
}

export type Warnings = Record<string, number[]>

export interface TransactionListItem {
  amount: number
  costTypeId: number | null
  createdBy: string | null
  date: string | null
  description: string | null
  id: number
  isCleared: boolean
  lines: TransactionListItemLine[]
  referenceNumber: string | null
  transactionTypeId: TransactionTypeId
  transactionStateId: TransactionStateId
  transactionTypeFilterId: number
}

export type TransactionListItemLine = Pick<TransactionLine, 'id' | 'controlEntity' | 'controlId' | 'deal' | 'dealId'>

export type GetTransactionTypesPayload = EntityDictionary<string>

export interface TransactionDetails {
  amount: number
  date: string | null
  dueDate: string | null
  dealId: number | null
  description: string | null
  id: number
  lines: TransactionLine[]
  memo?: string | null
  referenceNumber: string | null
  transactionTypeId: number
  isLocked: boolean
  isVehiclePayment: boolean
  checkNumber: number | null
  recurringTransactionTemplateId?: number | null
  isPrint?: boolean
  lastPrintDate?: string | null
  isSystemCreated: boolean
  transactionStateId: TransactionStateId
  lineEntryId?: number | null // AZ-TODO: remove
  depositDealId?: number | null
}

export interface BankStatementTransaction {
  id: number
  date: string | null
  description: string
  info: string
  change: number
  bankStatementTransactionStateId: number
  bankStatementTransactionClearMethodId?: number | null
  accountBankStatementId: number
  isReconciled: boolean
  clearedTransactionId: number
  clearedTransactionTypeId: number
  clearedCostTypeId: number
  isLocked: boolean
  isLinked: boolean
  matches: BankStatementTransactionMatchItem[]
}

export interface TransactionLine {
  id: number
  account: Account
  accountId: number
  change: number
  control: number | null
  controlEntity: TransactionLineControlDto | null
  controlId: string | null
  date: string | null
  dealId: number | null
  deal: TransactionLineDealDto | null
  customer: TransactionLineCustomerDto | null
  notes: string | null
  vendorId: number | null
  vendor: TransactionLineVendorDto | null
  isSystemCreated: boolean
  isVehicleCost: boolean
  disabled?: boolean
  bankStatementTransaction: BankStatementTransaction | null
  lineEntryReconciliation: LineEntryReconciliationDto | null
}

export interface LineEntryReconciliationDto {
  id: number
  processEndDate: string | null
  reconciledOnDate: string
  reconciliationSessionId: number
}

export interface OpenBalancesListItem {
  account: Account
  amount: number
  control: number | null
  controlEntity: TransactionLineControlDto | null
  controlId: string | null // 'DEAL-xxx' or 'INV-xxx'
  customerId: number | null
  customer: TransactionLineCustomerDto | null
  date: string | null
  dealId: number | null
  deal: TransactionLineDealDto
  notes: string | null
  reconciliationId: string | null // needed to match generated Lines with Open Balance
  transactions: OpenBalanceTransaction[]
  vendorId: number | null
  vendor: TransactionLineVendorDto | null
  disabled?: boolean
  bankStatementTransaction: BankStatementTransaction | null
  lineEntryReconciliation: LineEntryReconciliationDto | null
}

export interface TransactionLineDealDto {
  id: number
  personalDetails: {
    id: number
    firstName: string | null
    lastName: string | null
  }
  vehicle: DealVehicleDto
}

export interface DealVehicleDto {
  id: number
  make: string | null
  model: string | null
  vin: string | null
  trim: string | null
  stockNumber: string | null
  modelYear: number | null
}

export interface TransactionLineControlDto {
  id: number
  make: string | null
  model: string | null
  soldDate: string | null
  stock: string | null
  trim: string | null
  vehicleState: number | null
  vin: string | null
  year: number
}

export interface Control {
  controlId: number | null
  dealId: number | null
  firstName: string | null
  id: number
  lastName: string | null
  make: string | null
  model: string | null
  soldDate: string | null
  stock: string | null
  trim: string | null
  dealStateId: number | null
  vehicleState: number | null
  vin: string | null
  year: number
}

export interface BaseControlItem extends BaseListItem {
  controlType: number
}

export interface AddTransactionRequest {
  costTypeId?: number
  date: string
  dealId?: EntityId
  description: string
  referenceNumber: string | null
  lines: LinePayload[]
  transactionTypeId?: number | null
  isPrint?: boolean
}

export interface UpdateTransactionRequest extends AddTransactionRequest {
  id: number
}

export interface LinePayload {
  accountId: number
  change: number
  control: number | null
  customerId: number | null
  notes: string | null
  vendorId: number | null
  dealId: number | null
  id?: number | null
  reconciliationId?: string | null
}

export interface DateRangeResponse {
  minDate: string
  maxDate: string
}

export interface AddVendorPayload {
  name: string | null
  phoneNumber: string | null
  email: string | null
  ein: string | null
  ssn: string | null
  address: string | null
  city: string | null
  state: string | null
  zipCode: string | null
  mailingAddress: string | null
  mailingCity: string | null
  mailingState: string | null
  mailingZipCode: string | null
  track1099Payments: boolean
  accountId: number
  account?: AccountListItem | null
}

/**
 * @deprecated - use TransactionLineVendorDto
 */
export interface UpdateVendorPayload extends AddVendorPayload {
  id: number
}

// AZ-TODO: it should be VendorDto, but first check that this is exactly the same DTO as in Transactions
export interface TransactionLineVendorDto {
  account: AccountListItem | null
  accountId: number
  address: string | null
  city: string | null
  ein: string | null
  email: string | null
  id: number
  mailingAddress: string | null
  mailingCity: string | null
  mailingState: string | null
  mailingZipCode: string | null
  name: string | null
  phoneNumber: string | null
  ssn: string | null
  state: string | null
  track1099Payments: boolean
  zipCode: string | null
  lienholderId: number | null
}

export interface AddCustomerPayload {
  phoneNumber: string | null
  email: string | null
  customerTypeId: number
  address: string | null
  city: string | null
  state: string | null
  zipCode: string | null
  mailingAddress: string | null
  mailingCity: string | null
  mailingState: string | null
  mailingZipCode: string | null
  account?: AccountListItem | null
  firstName: string | null
  lastName: string | null

  driverLicense?: string | null
  stateOfIssue?: string | null
  ssn?: string | null

  ein?: string | null
  businessName?: string | null
}

/**
 * @deprecated - use CustomerDto instead
 */
export interface UpdateCustomerPayload extends AddCustomerPayload {
  id: number
}

/**
 * @deprecated - use CustomerDto instead
 */
export type Customer = UpdateCustomerPayload

export type TransactionLineCustomerDto = UpdateCustomerPayload
export type CustomerDto = UpdateCustomerPayload

export interface CostDetail {
  amount: number
  description: string
  dateTime: string
  id: number
  costTypeId: number
  transactionTypeId: number
  referenceNumber: string | null
}

export interface CostSummary {
  accountId: number
  accountNumber: number
  accountName: string
  amount: number
  accountType: string
  accountCategory: string
}

export interface EntityListItem {
  id: number
  name: string
  email: string | null
  phoneNumber: string | null
  entityType: number
}

export interface ControlListItem {
  id: number
  controlId: string
  vin: string | null
  year: number | null
  make: string | null
  model: string | null
  trim: string | null
  stock: string | null
  name: string
  firstName: string | null
  lastName: string | null
  controlType: number
  vehicleState: number | null
  dealStateId: number | null
}

export interface ReportPayload {
  dateFrom: string | null
  dateTo: string | null
}

export interface TransactionsReportPayload extends ReportPayload {
  accountId: number | null
}

export interface ReportResponse {
  dateFrom: string | null
  dateTo: string | null
  dateIssue: string
}

export interface TransactionsReport extends ReportResponse {
  totalCredits: number
  totalDebits: number
  transactions: TransactionsReportTransaction[]
}

export interface TransactionsReportTransaction {
  date: string
  description: string | null
  transactionStateId: TransactionStateId
  lines: TransactionsReportLine[]
}

export interface TransactionsReportLine {
  accountNumber: number
  accountName: string | null
  note: string | null
  referenceNo: string | null
  control: string | null
  controlYear: number | null
  controlModel: string | null
  controlMake: string | null
  controlTrim: string | null
  debits: number | null
  credits: number | null
}

export interface TrialBalanceReport extends ReportResponse {
  accounts: TrialBalanceAccount[]
  totalCredits: number
  totalDebits: number
}

export interface TrialBalanceAccount {
  id: number
  number: number
  name: string
  start: number
  debits: number
  credits: number
  balance: number
}

export interface ProfitLossReport extends ReportResponse {
  expenses: {
    accountTypeData: ProfitLossTypeItem
    totalBalance: number
  }
  profits: {
    accountTypesData: ProfitLossTypeItem[]
    totalBalance: number
  }
  netProfit: number
}

export interface ProfitLossTypeItem {
  accountItems: BalanceSheetProfitLossItem[]
  accountType: ProfitLossSubtypes
  totalBalance: number
}

export interface BalanceSheetReport extends ReportResponse {
  accountTypeGroups: BalanceSheetAccount[]
  netIncome: number
}

export interface BalanceSheetAccount {
  accountType: ReportAccountTypes
  accountCategoryGroups: AccountCategories[]
  totalBalance: number
}

export interface AccountCategories {
  accountCategory: string
  accountItems: BalanceSheetProfitLossItem[]
  totalBalance: number
}

export interface BalanceSheetProfitLossItem {
  accountId: number
  accountNumber: number
  accountName: string
  amount: number
}

export interface BatchTransactionsRequest {
  create: AddTransactionRequest[]
  update: Array<{
    id: number
    transaction: UpdateTransactionRequest
  }>
  delete: number[]
}

export interface EntityBalance {
  vendorId: number | null
  customerId: number | null
  vendor: TransactionLineVendorDto | null
  customer: CustomerDto | null
  balance: number
}
export interface AccountBalance {
  account: AccountListItem
  balance: number
}

export interface DateRange {
  range: number
  from: string
  to: string
}

export interface DateBalance {
  dateRange: DateRange
  balance: number
}

export interface OpenBalancesWithStatistics {
  statistics?: OpenBalancesStatistics
}

export type OpenBalancesList = ListResponse<OpenBalancesListItem>

export class OpenBalancesStatistics {
  totalBalance: number = 0
  entityBalances: EntityBalance[] = []
  accountBalances: AccountBalance[] = []
  dateBalances: DateBalance[] = []
}

export class OpenBalancesListWithStatistics {
  items: OpenBalancesListItem[] = []
  statistics: OpenBalancesStatistics = new OpenBalancesStatistics()
}

export interface OpenBalanceTransaction {
  description: string | null
  id: number
  referenceNumber: string | null
  transactionTypeId: number
}

export interface OpenBalancesListPayload {
  amount?: AmountRangeFilter | null
  accountId?: number | null
  vendorId?: number | null
  customerId?: number | null
  date?: DateRangeFilter | null
  search?: string | null
  sortOrder?: SortOrder
  includeStatistics?: boolean
}

/**
 * Represents a balance of the non-zero account, at the **beginning** of a fiscal year.
 * Not the same thing as an 'Open Balance'
 * (the last one means a list of Transactions for a **currently** non-zero accounts).
 */
export class OpeningBalance {
  accountType: number = 0
  accountCategory: number | null = null
  openingBalance: number = 0
  description: string | null = ''
  id: number = 0
  name: string = ''
  number: number = 0
  isReconciled: boolean = false
}

export interface OpeningBalancePatch {
  id: number
  openingBalance: number
}

export interface OpeningBalancePayload {
  openingBalances: OpeningBalancePatch[]
}

export interface RecurringEntriesListItem {
  amount: number
  createdByFirstName: string | null
  createdByLastName: string | null
  createdDate: string | null
  description: string | null
  id: number
  transactionTypeFilterId: TransactionTypeId
  recurringIntervalId: number
}

export interface AddRecurringTransactionTemplatesPayload {
  startDate: string
  endDate: string
  recurringIntervalId: number | null
  templateTransaction: AddTransactionRequest
}

export interface UpdateRecurringTransactionTemplatesPayload extends AddRecurringTransactionTemplatesPayload {
  id: number
}

export type RecurringTransactionTemplatesDetails = Omit<UpdateRecurringTransactionTemplatesPayload, 'templateTransaction'> & {
  templateTransaction: TransactionDetails
}

export interface AccountBankStatementListItem {
  balance: number
  id: number
  number: number
  name: string | null
  lastManualUploadDate: string | null
  lastFinishedReconciliationSession: {
    accountBankStatementId: number
    beginningBalance: number
    beginningDate: string
    endBalance: number
    endDate: string
    id: number
    processEndDate: string
    processStartDate: string
    reconciliationSessionStateId: number
  } | null
  numberOfTransactionToReview: number
}

export interface BankStatementListItem {
  id: number
  accountBankStatementId: number
  originalFileName: string | null
}

export interface BankStatementListPayload {
  originalFileName: string
}

export interface ParseBankStatementsRequestDto {
  fileId: number
  bankId: number
  accountId: number
  originalFileName?: string
  onBack?: () => void
}

interface ErrorMessage {
  message: string
}

export interface BankStatementsCellValue<T = string | number | null> {
  rawValue: T
  error: ErrorMessage | null
  hasError: boolean
}

export interface ParseBankStatementsTransaction {
  date: BankStatementsCellValue<Date | null> | null
  description: BankStatementsCellValue | null
  deposit: BankStatementsCellValue | null
  withdrawal: BankStatementsCellValue | null
  isDuplicate: boolean
  isPending: boolean
}

export interface ParseBankStatementsDto {
  transactions: ParseBankStatementsTransaction[] | null
  duplicateLineIndexes: number[] | null
  totalLinesCount: number
  duplicateLinesCount: number
  successfulLinesCount: number
  errorLinesCount: number
  pendingLinesCount: number
}

export interface ImportBankStatementsPayload extends ParseBankStatementsRequestDto {
  originalFileName: string
  transactions: any[]
}

export interface BankStatementTransactionsListPayload extends ListPayload {
  amount?: AmountRangeFilter | null
  accountId?: number | null
  date?: DateRangeFilter | null
  transactionState?: number
  transactionType?: number
}

export type BankStatementTransactionsTotalPayload = Omit<BankStatementTransactionsListPayload, 'transactionState'>

export interface BankStatementTransactionsListItem {
  date: string | null
  description: string | null
  change: number
  id: number
  info: string | null
  matches: BankStatementTransactionMatchItem[]
  isLocked: boolean
  isLinked: boolean
  clearedTransactionId: number
  clearedTransactionTypeId: number
  clearedCostTypeId: number | null
  isReconciled: boolean
}

export interface BankStatementTransactionsTotal {
  forReview: number
  categorized: number
  excluded: number
}

export interface BankStatementTransactionsCategorizePayload {
  accountId: number
  customerId: number | null
  control: number | null // for INV controls
  dealId: number | null // for DEAL controls
  description: string | null
  referenceNumber: string | null
  vendorId: number | null
}

export interface BankStatementTransactionMatchItem {
  accountId: number
  account: AccountListItem | null
  change: number
  clearMethodId: number
  control: number | null // for INV controls
  controlEntity: TransactionLineControlDto | null
  costTypeId: number
  customer: CustomerDto | null
  customerId: number | null
  date: string | null
  dealId: number | null // for DEAL controls
  deal: TransactionLineDealDto | null
  id: number
  notes: string | null
  transactionId: number
  transactionTypeId: number
  vendor: TransactionLineVendorDto | null
  vendorId: number | null
}

export interface VehicleCostCreateRequestDto {
  amount: number
  creditAccountId: number
  // debitAccount is always 1300, we don't need to send it
  date: string
  description: string | null
  referenceNumber: string | null
  vehicleCostTypeId: number
  vehicleId: number
  vendorId: number
}

export interface VehicleCostUpdateRequestDto {
  amount: number
  creditAccountId: number
  // debitAccount is always 1300, we don't need to send it
  date: string
  description: string | null
  referenceNumber: string | null
  vehicleCostTypeId: number
  vendorId: number
}

export interface VehicleCostDto {
  amount: number
  creditAccount: AccountDto | null
  date: string
  debitAccount: AccountDto
  description: string | null
  id: number
  isLocked: boolean
  isSystemCreated: boolean // true if Cost was created as a by-product of Transaction creation.
  reconcileStatusId: ReconcileStatusId
  referenceNumber: string | null
  transactionId: number | null // each Cost always has a corresponding Transaction.
  vehicleCostTypeId: number
  vehicleId: number // each Cost always has a corresponding Vehicle.
  vendor: TransactionLineVendorDto
}

export interface FloorPlanDto {
  amount: number
  date: string
  id: number
  vendor: TransactionLineVendorDto
}

export interface PackCostDto {
  amount: number
  creditAccount: AccountDto
  debitAccount: AccountDto
  id: number
}

export interface VehiclePaymentsDto {
  floorPlan: FloorPlanDto | null
  packCost: PackCostDto | null
}

export interface FloorPlanUpdateRequestDto {
  amount: number
  date: string
  vendorId: number
}

export interface PackCostUpdateRequestDto {
  amount: number
  creditAccountId: number
  debitAccountId: number
}

export interface VehiclePaymentsUpdateRequestDto {
  floorPlan: FloorPlanUpdateRequestDto | null
  packCost: PackCostUpdateRequestDto | null
}

export interface ReconciliationSessionCreateRequestDto {
  accountBankStatementId: number
  endDate: string | null
  endBalance: number | null
}

export interface ReconciliationSessionDto {
  accountBankStatementId: number
  endDate: string | null
  beginningDate: string | null
  processStartDate: string | null
  processEndDate: string | null
  endBalance: number | null
  beginningBalance: number | null
  id: number | null
  reconciliationSessionStateId: number | null
}

export interface ReconciliationSessionUpdateRequestDto {
  endDate: string | null
  endBalance: number | null
}

export interface ReportAccountsAgingGetRequestDto {
  date: string
  search?: string | null
}

export interface ReportAccountsAgingDto {
  date: string
  issueDate: string
  records: ReportAccountsAgingRecordDto[]
  totals: ReportAccountsAgingTotalsDto
}

export interface ReportAccountsAgingTotalsDto {
  balances: ReportAccountsAgingBalanceDto[]
  totalBalance: number
}

export interface ReportAccountsAgingRecordDto {
  balances: ReportAccountsAgingBalanceDto[]
  entityName: string
  totalBalance: number
}

export interface ReportAccountsAgingBalanceDto {
  balance: number
  range: number
}

export type ReportAccountsAgingXlsxGetRequestDto = ReportAccountsAgingDto

export interface CostsReportRequestDto {
  vehicleStatuses: number[]
  dateTo: string
  search?: string
}

export interface CostsReportItemDto {
  dateFrom: string
  dateTo: string
  make: string
  model: string
  trim: string
  year: number
  mileage: number | null
  vehicleState: string
  age: number
  stock: number
  vin: string
  salePrice: number
  cost: number | null
  id: number | null
}

export interface CostsReportDto {
  lines: CostsReportItemDto[]
  totalCost: number
}

export interface CostReportXlsxDownloadRequestDto extends CostsReportDto {
  vehicleStatuses: number[]
  dateTo: string
}

export interface TransactionsListGetRequestDto {
  skip: number
  take: number
  search?: string | null
  sortField?: string
  sortOrder?: SortOrder
  amount: AmountFilterGetRequestDto | null
  date: DateFilterGetRequestDto | null
  transactionTypeFilters: number[] | null
}

export interface AmountFilterGetRequestDto {
  from: number | null
  to: number | null
}

export interface DateFilterGetRequestDto {
  from: string | null
  to: string | null
}
