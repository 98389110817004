import { type CoverageDetailsDto, ProductType } from 'api/types'
import { type DealCoveragesSettingsModel, DealProductTypesToSettingsMap } from 'api/types/responses'

export interface CoverageLike {
  dealerRetailPrice: CoverageDetailsDto['dealerRetailPrice']
  productType: CoverageDetailsDto['productType']
}

const getDealTaxableCoveragesAmount = (
  coverages: CoverageLike[] = [],
  config: DealCoveragesSettingsModel | null
): number => {
  let result = 0

  if (config == null) {
    return result
  }

  for (const item of coverages) {
    const configKey = DealProductTypesToSettingsMap[item.productType as ProductType]
    const isTaxable = config[configKey] ?? false

    if (isTaxable) {
      result += (item.dealerRetailPrice ?? 0)
    }
  }

  return result
}

export default getDealTaxableCoveragesAmount
