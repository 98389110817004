import { type ColumnDef, cnx } from '@carfluent/common'

import { type BaseListItem, type TransactionLineRow } from 'types'
import { type ControlListItem, type AccountListItem } from 'api/types'

import getSuggestedAccountCategories from 'utils/accounting/getSuggestedAccountCategories'
import getSuggestedAccountTypes from 'utils/accounting/getSuggestedAccountTypes'
import _isCheck from 'utils/accounting/isCheck'
import _isPayBill from 'utils/accounting/isPayBill'
import _isReceive from 'utils/accounting/isReceive'
import _isReceivable from 'utils/accounting/isReceivable'
import _isPayable from 'utils/accounting/isPayable'
import isSpecialTransaction from 'utils/accounting/isSpecialTransaction'
import isControlOptionDisabled from 'utils/accounting/isControlOptionDisabled'

import { isEmptyRow } from 'pages/accounting/TransactionDetails/hook/utils'
import EditableCellHOK from 'components/common/Table/cells/editable_cell'
import ConditionalCellHOK from 'components/common/Table/cells/conditional_cell'
import RemoveRowCellHOK from 'components/common/Table/cells/remove_row_cell'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import DictionaryCellHOK from 'components/common/Table/cells/DictionaryCell'
import InputCell from 'components/common/Table/cells/input_cell'
import InputNumberCellHOC from 'components/common/Table/cells/InputNumberCell'
import { TextValueHOC } from 'components/common/Table/cells/TextValueCell'
import { AmountCellHOC } from 'components/common/Table/cells/AmountCell'
import EmptyCell from 'components/common/Table/cells/EmptyCell'
import ControlOption from 'components/accounting/ControlOption'
import EntityAction from 'components/accounting/EntityAction'
import EntityOption, { EntityOptionProps } from 'components/accounting/EntityOption'

import { MAX_PRICE_VALUE } from 'constants/validation'

import type { CommonColumnsConfig } from './types'

function getColumnDefinitions ({
  disabled = false,
  getAccounts: _getAccounts,
  getEntities,
  getControls,
  getWidths,
  onChange,
  onRemoveRow,
  styles,
  hasBankingLines,
  transactionTypeId,
  entityActions
}: CommonColumnsConfig): Array<ColumnDef<TransactionLineRow>> {
  const widths = getWidths(transactionTypeId)
  const isBaseJE = !isSpecialTransaction(transactionTypeId)
  const isCheck = _isCheck(transactionTypeId)
  const isPayBill = _isPayBill(transactionTypeId)
  const isPayable = _isPayable(transactionTypeId)
  const isReceive = _isReceive(transactionTypeId)
  const isReceivable = _isReceivable(transactionTypeId)
  const canRemoveLine = !disabled && !hasBankingLines

  const getAccounts: typeof _getAccounts = async (_payload) => {
    const payload = {
      ..._payload,
      suggestedCategoryIds: getSuggestedAccountCategories(transactionTypeId ?? null, true),
      suggestedTypeIds: getSuggestedAccountTypes(transactionTypeId ?? null, true)
    }

    return await _getAccounts(payload)
  }

  const columns: Array<ColumnDef<TransactionLineRow> | false> = [
    {
      accessorKey: 'account' as keyof TransactionLineRow,
      cell: ConditionalCellHOK<TransactionLineRow>(
        ({ row }) => {
          return (row.original.reconciliationId != null) || // AZ-NOTE: this is OpenBalance reconciliation, not banking
            (row.original.bankStatementTransaction != null) ||
            (row.original.lineEntryReconciliation != null) ||
            disabled
        },
        TextValueHOC({ fieldName: 'name', className: 'read-only-column-value account' }),
        DictionaryCellHOK<TransactionLineRow, AccountListItem>({
          disableClearable: true,
          label: 'Account',
          getItems: getAccounts,
          onChange,
          renderOption: (option: BaseListItem | null) => <div className={styles}> {option?.name}</div>
        })
      ),
      header: TextCellHOC({ text: 'Account', className: 'account' }),
      sortable: false,
      ...widths.get('account')
    },
    {
      accessorKey: 'control' as keyof TransactionLineRow,
      cell: ConditionalCellHOK<TransactionLineRow>(
        (data) => (disabled || (data.row.original.reconciliationId != null)),
        TextValueHOC({ fieldName: 'name', className: 'read-only-column-value' }),
        DictionaryCellHOK<TransactionLineRow, ControlListItem>({
          label: 'Control',
          getItems: getControls,
          isOptionDisabled: isControlOptionDisabled,
          onChange,
          popoverClassName: 'extended-width',
          renderOption: (option: ControlListItem | null) => <ControlOption option={option} className={styles} />
        })
      ),
      header: TextCellHOC({ text: 'Control', className: cnx(!isBaseJE && 'control') }),
      sortable: false,
      ...widths.get('control')
    },
    {
      accessorKey: 'notes' as keyof TransactionLineRow,
      cell: !disabled
        ? EditableCellHOK<TransactionLineRow>(
          { label: 'Notes', onChange },
          InputCell
        )
        : TextValueHOC({ fieldName: 'name', className: 'read-only-column-value' }),
      header: TextCellHOC({ text: 'Notes', className: cnx(!isBaseJE && 'notes') }),
      sortable: false,
      ...widths.get('notes')
    },
    (!isPayable && {
      accessorKey: 'entity' as keyof TransactionLineRow,
      cell: ConditionalCellHOK<TransactionLineRow>(
        (data) => (disabled || (data.row.original.reconciliationId != null)),
        TextValueHOC({ fieldName: 'name', className: 'read-only-column-value' }),
        DictionaryCellHOK<TransactionLineRow>({
          label: 'Entity',
          getItems: getEntities,
          onChange,
          actions: entityActions,
          renderAction: (action) => <EntityAction action={action} />,
          renderOption: (option: BaseListItem | null) => <EntityOption option={option as EntityOptionProps['option']} />
        })
      ),
      header: TextCellHOC({ text: 'Entity' }),
      sortable: false,
      ...widths.get('entity')
    }),
    ((isBaseJE || isPayable || isCheck || isPayBill) && {
      accessorKey: 'debits' as keyof TransactionLineRow,
      cell: disabled || hasBankingLines
        ? AmountCellHOC({ className: 'read-only-column-value' })
        : InputNumberCellHOC<TransactionLineRow>({
          isDecimal: true,
          isEmptyAllowed: false,
          isSelectedOnFocus: true,
          isNegativeAllowed: !isBaseJE,
          label: isBaseJE ? 'Debits' : 'Amount',
          onChange,
          maxValue: MAX_PRICE_VALUE,
          dataTestId: 'debits'
        }),
      header: TextCellHOC({ text: isBaseJE ? 'Debits' : 'Amount' }),
      sortable: false,
      ...widths.get('debits')
    }),
    ((isBaseJE || isReceive || isReceivable) && {
      accessorKey: 'credits' as keyof TransactionLineRow,
      cell: disabled || hasBankingLines
        ? AmountCellHOC({ className: 'read-only-column-value' })
        : InputNumberCellHOC<TransactionLineRow>({
          isDecimal: true,
          isEmptyAllowed: false,
          isSelectedOnFocus: true,
          isNegativeAllowed: !isBaseJE,
          label: isBaseJE ? 'Credits' : 'Amount',
          onChange,
          maxValue: MAX_PRICE_VALUE,
          dataTestId: 'credits'
        }),
      header: TextCellHOC({ text: isBaseJE ? 'Credits' : 'Amount' }),
      sortable: false,
      ...widths.get('credits')
    }),
    {
      accessorKey: 'remover',
      cell: ConditionalCellHOK<TransactionLineRow>(
        (data) =>
          ((data.table.getRowModel().rows.length === 1) && isEmptyRow(data.row.original)) || !canRemoveLine,
        EmptyCell,
        RemoveRowCellHOK({ onRemoveRow })
      ),
      header: TextCellHOC({ text: '' }),
      sortable: false,
      ...widths.get('remover')
    } as unknown as ColumnDef<TransactionLineRow>
  ]
    .filter(Boolean)

  return columns as Array<ColumnDef<TransactionLineRow>>
}

export default getColumnDefinitions
