import { useCallback, useState } from 'react'

import { type UploadedBankStatementWithResults } from 'types'
import { type UseBankStatementUploadReturn } from './types'

const useBankStatementUpload = (): UseBankStatementUploadReturn => {
  const [uploadedBankStatement, setUploadedBankStatement] = useState<UploadedBankStatementWithResults | null>(null)

  const onBack = useCallback(() => {
    setUploadedBankStatement(null)
  }, [])

  return {
    onSubmitBankStatement: setUploadedBankStatement,
    onBack,
    uploadedBankStatement
  }
}

export default useBankStatementUpload
