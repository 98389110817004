import type { FC } from 'react'
import { MenuList } from '@material-ui/core'

import type { MenuListProps } from 'components/common/ActionsMenu'
import CarsList from 'components/crm/CarsList'

export interface ActionsListProps {
  isOpen: boolean
  isCarsListVisible: boolean
  onCarSelected: (id: number) => void
}

const ActionsList: FC<ActionsListProps & MenuListProps> = ({
  children,
  isOpen,
  isCarsListVisible,
  onCarSelected
}) => {
  if (!isOpen) {
    return null // AZ-NOTE: this is a temporal work-around.
  }

  if (isCarsListVisible) {
    return (
      <CarsList onCarSelected={onCarSelected} />
    )
  }

  return (
    <MenuList>{children}</MenuList>
  )
}

const ActionsListHOC = (hocProps: ActionsListProps) => (props: MenuListProps) => {
  return (
    <ActionsList {...hocProps} {...props} />
  )
}

export default ActionsListHOC
