import { FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { PRESET_LABELS } from '@carfluent/common'

import ReportsProfitLossTable, { createTableClass } from 'components/reports/ReportsBSPLTable'
import ReportLayout from 'components/reports/ReportLayout'
import AccountingHOK from 'pages/accounting/AccountingHOC'

import { useReportsProfitLoss } from './hook'
import { createStyleClass } from './styles'

const ReportsProfitLoss: FC = () => {
  const { rootCls, tableClass } = useMemo(() => ({
    rootCls: createStyleClass(),
    tableClass: createTableClass()
  }), [])
  const { data, ...layoutProps } = useReportsProfitLoss()

  return (
    <ReportLayout
      {...layoutProps}
      className={tableClass}
      initialPresetName={PRESET_LABELS.THIS_YEAR}
      rootClassName={rootCls}
      excludePresets={[PRESET_LABELS.YEAR_TO_DATE]}
      title='Profit & Loss (Income Statement)'
    >
      <ReportsProfitLossTable data={data} />
    </ReportLayout>
  )
}

export default AccountingHOK(observer(ReportsProfitLoss))
