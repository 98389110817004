import { type FC } from 'react'
import { cnx, formatCurrencyAccounting, formatters as F } from '@carfluent/common'

import { type AccountBankStatementListItem } from 'api/types'
import IconSVG from 'components/inlineImages'
import TooltipedText from 'components/common/TooltipedText'

import CLASS_NAME from './styles'

export type AccountBalanceProps = Omit<AccountBankStatementListItem, 'lastManualUploadDate' | 'lastFinishedReconciliationSession'> & {
  isActive?: boolean
  lastManualUploadDate: Date | null
  lastReconciledStartDate: Date | null
  lastReconciledEndDate: Date | null
  lastReconciledProcessDate: Date | null
  balance: number | null
  numberOfTransactionToReview: number
  onClick: (accountNum: number) => void
}

const AccountsInfo: FC<AccountBalanceProps> = ({
  isActive = false,
  lastManualUploadDate,
  lastReconciledStartDate,
  lastReconciledEndDate,
  lastReconciledProcessDate,
  balance,
  numberOfTransactionToReview,
  name,
  number,
  onClick
}) => {
  return (
    <div
      className={cnx(CLASS_NAME, isActive && 'active')}
      onClick={() => onClick(number)}
    >
      <TooltipedText
        rootClassName='account-title'
        value={`${number} ${name ?? ''}`}
      />

      <h6 className='account-amount'>
        {formatCurrencyAccounting(balance, { allowNegative: true, emptyValues: '-' })}
      </h6>

      <div className='last-manual-upload-date'>
        <IconSVG.Download />
        <span>Last manual upload: {F.formatLocalDate(lastManualUploadDate, 'MM/dd/yy') ?? '-'}</span>
      </div>

      <div className='review-transaction-count'>
        Review {numberOfTransactionToReview} transaction{numberOfTransactionToReview === 1 ? '' : 's'}
      </div>
      <div className='g-horizontal-line' />

      <div className='last-reconciled-process-date'>
        <IconSVG.CheckFilled />
        <span>Last reconciled: {F.formatLocalDate(lastReconciledProcessDate, 'MM/dd/yy') ?? '-'}</span>
      </div>

      <div className='last-reconciled-period'>
        {F.formatLocalDate(lastReconciledStartDate, 'd MMM yyyy') ?? ' '} - {F.formatLocalDate(lastReconciledEndDate, 'd MMM yyyy') ?? ' '}
      </div>
    </div>
  )
}

export default AccountsInfo
