import { useCallback, useState } from 'react'
import pDebounce from 'p-debounce'

import {
  type DateType,
  type CostsReportItemDto,
  type PaginatedResult
} from 'api/types'
import AccountingApiProvider from 'api/accounting.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { downloadBlob } from 'utils/general'
import useTableApi from 'hooks/useTableApi'

import {
  REPORT_FILE_NAMES,
  REQUEST_DELAY,
  ALL_SELECTED_ITEMS,
  getPresets,
  getPresetToday,
  API_CALL_DELAY_SEARCH
} from './constants'
import serializeFilters from './serializer'
import { InventoryCostFilters, UseReportBalanceSheetReturn } from './types'

const presets = getPresets()
const TODAY = getPresetToday()

const DEFAULT_FILTERS: InventoryCostFilters = ({
  dateFrom: TODAY?.startDate ?? new Date(),
  dateTo: TODAY?.endDate ?? new Date(),
  vehicleStatuses: ALL_SELECTED_ITEMS
})

const getSum = (arr: number[]): number => arr.reduce((sum, a) => (sum += a), 0)

export const useReportInventoryCosts = (): UseReportBalanceSheetReturn => {
  const { showAlert } = useCustomSnackbar()
  const [totalCost, setTotalCost] = useState<number>(0)
  const [selectedStates, setSelectedStates] = useState<number[]>(ALL_SELECTED_ITEMS)

  const getInventoryCostsReport = useCallback(async (
    payload
  ): Promise<PaginatedResult<CostsReportItemDto>> => {
    try {
      const { search, vehicleStatuses, dateTo } = payload
      const { lines, totalCost } = await AccountingApiProvider.getInventoryCostsReport({ search, vehicleStatuses, dateTo })

      setTotalCost(totalCost)
      return { items: lines, count: lines.length }
    } catch (e) {
      showAlert(e)
      return { items: [], count: 0 }
    }
  }, [showAlert])

  const {
    rows,
    search,
    isLoading,
    filters,
    emptyTableMessage,
    onSearch,
    setSearch,
    setFilters
  } = useTableApi<CostsReportItemDto, InventoryCostFilters>({
    shouldRunOnCall: true,
    defaultFilters: DEFAULT_FILTERS,
    getList: getInventoryCostsReport,
    serializeFilters,
    apiCallDelay: API_CALL_DELAY_SEARCH,
    emptyTableMessage: 'No records',
    nothingFoundMessage: 'No results found'
  })

  const onChangeDatesFilter = useCallback(async (date: DateType | null) => {
    void setFilters({ dateFrom: date?.from, dateTo: date?.to })
  }, [setFilters])

  const onStatusFiltersChanged = pDebounce((items: number[]) => {
    setSelectedStates(items)
    const newFilters = getSum(items)
    const prevFilters = getSum(filters.vehicleStatuses)

    // checking sum of filters value to avoid redundant request
    // prev - [1, 2], next - [2, 1] - same filters, but changed array, which will handle request
    if (newFilters !== prevFilters) {
      void setFilters({ vehicleStatuses: items })
    }
  }, REQUEST_DELAY)

  const onExportExcel = async (): Promise<void> => {
    try {
      const { vehicleStatuses, dateTo } = serializeFilters(filters)
      const res = await AccountingApiProvider.downloadInventoryCostsReportXlsx({
        lines: rows,
        vehicleStatuses,
        totalCost,
        dateTo
      })

      downloadBlob(res, REPORT_FILE_NAMES.xlsx)
    } catch (err) {
      showAlert(err)
    }
  }

  return {
    rows,
    presets,
    emptyTableMessage,
    isLoading,
    totalCost,
    search,
    selectedStates,
    onExportExcel,
    onChangeDatesFilter,
    onStatusFiltersChanged,
    onSearchChange: setSearch,
    onSearch
  }
}
