import { type FC } from 'react'
import { cnx, Modal, FormNumberInput } from '@carfluent/common'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import PaymentInfo from 'components/deals/PaymentInfo'

import { type ConfirmCashPaymentProps } from './types'
import CLASS_NAME from './styles'

const MAX_AMOUNT = 999999
const MIN_MODAL_WIDTH = 680

const ConfirmCashPayment: FC<ConfirmCashPaymentProps> = ({
  amount,
  className,
  isConfirmOpen,
  isDisabledConfirm,
  isSubmitting,
  onChangeConfirmAmount,
  onClose,
  onConfirmPayment,
  payments,
  shouldBeConfirmed,
  totalAmount
}) => {
  return (
    <Modal
      title='Confirm Cash Payment'
      isOpen={isConfirmOpen && shouldBeConfirmed}
      onClose={onClose}
      minWidth={MIN_MODAL_WIDTH}
      className={cnx(CLASS_NAME, className)}
      renderFooterActions={(
        <CancelSubmitActionsFooter
          isDisabled={isDisabledConfirm}
          isLoading={isSubmitting}
          onSubmit={onConfirmPayment}
          onClose={onClose}
          submitTitle='CONFIRM'
        />
      )}
    >
      <PaymentInfo
        payments={payments}
        totalAmount={totalAmount}
      />

      <div className='amount-block'>
        <p className='title'>Enter amount received</p>

        <FormNumberInput
          id='amount'
          label='Amount'
          max={MAX_AMOUNT}
          value={amount}
          onChange={onChangeConfirmAmount}
          preset='financial'
          isNegativeAllowed={false}
        />
      </div>
    </Modal>
  )
}

export default ConfirmCashPayment
