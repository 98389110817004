import { type FC } from 'react'

import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

import { SKELETON_CLASS_NAME } from './styles'

const Skeleton: FC = () => {
  return (
    <div className={SKELETON_CLASS_NAME}>
      <div className='skeleton-toggle-section'>
        <TextSkeleton className='skeleton-toggle-text' />
        <TextSkeleton className='skeleton-toggle' />
      </div>

      <div className='skeleton-tab-section'>
        <TextSkeleton className='skeleton-tab' />
        <TextSkeleton className='skeleton-tab' />
        <TextSkeleton className='skeleton-tab' />
      </div>

      {[1, 2, 3, 4, 5].map((i) => (
        <div key={i} className='skeleton-question-section'>
          <TextSkeleton className='skeleton-question-title' />
          <TextSkeleton className='skeleton-question-input' />
          <TextSkeleton className='skeleton-question-textarea' />
        </div>
      ))}
    </div>
  )
}

export default Skeleton
