import { useEffect, useState } from 'react'
import { ValidationLength } from 'constants/validation'
import { isAnyUpperCase, isAnyLowerCase } from 'utils/regex_helper'
import { useStyles } from './styles'

interface UsePasswordValidationPanelProps {
  value: string
  isTouched: boolean
}

interface UsePasswordValidationPanelReturn {
  minLengthIndicatorStyle: string
  upperAndLowerCaseIndicatorStyle: string
}

const usePasswordValidationPanel = (props: UsePasswordValidationPanelProps): UsePasswordValidationPanelReturn => {
  const { value, isTouched } = props

  const {
    indicatorDefaultStyle,
    indicatorValidStyle,
    indicatorInvalidStyle
  } = useStyles()

  const [minLengthIndicatorStyle, setMinLengthIndicatorStyle] = useState<string>(indicatorDefaultStyle)
  const [upperAndLowerCaseIndicatorStyle, setUpperAndLowerCaseIndicatorStyle] = useState<string>(indicatorDefaultStyle)

  useEffect(() => {
    const getIndicatorColorStyle = (isValid: boolean): string => {
      const invalidStyle = isTouched ? indicatorInvalidStyle : indicatorDefaultStyle

      return isValid ? indicatorValidStyle : invalidStyle
    }

    const hasPasswordMinLength = value.length >= ValidationLength.PASSWORD_MIN
    setMinLengthIndicatorStyle(getIndicatorColorStyle(hasPasswordMinLength))

    const hasPasswordUpperAndLowerCase = isAnyUpperCase(value) && isAnyLowerCase(value)
    setUpperAndLowerCaseIndicatorStyle(getIndicatorColorStyle(hasPasswordUpperAndLowerCase))
  }, [value.length, isTouched])

  return {
    minLengthIndicatorStyle,
    upperAndLowerCaseIndicatorStyle
  }
}

export default usePasswordValidationPanel
