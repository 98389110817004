import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useImmerReducer } from 'use-immer'
import { useLoader, useModal } from '@carfluent/common'

import {
  UserRoles,
  ViewDealerRoles,
  type SummaryShift,
  type DictionaryItems,
  type UpdateShiftRequestDto,
  type CreateShiftRequestDto
} from 'api/types'
import CRMApiProvider from 'api/crm.api'
import IdentityApiProvider from 'api/identity.api'
import type { CopyMonthFormData, Shift, Coords } from 'types'
import parseUsers from 'utils/parseUsers'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import AuthCTX from 'store/auth'

import type {
  WorkScheduleFilters,
  UseWorkScheduleReturn,
  ArrayReducerAction,
  ShiftState,
  DatePayload
} from './types'
import {
  defaultFilters,
  initialState,
  SUCCESS_CREATE_SHIFT_MESSAGE,
  SUCCESS_DELETE_SHIFT_MESSAGE,
  SUCCESS_UPDATE_SHIFT_MESSAGE
} from './constants'
import reducer from './store'
import parseData from './parser'
import {
  serializeFilters,
  serializeDate,
  serializeCopyMonthFormDataToPayload
} from './serializer'
import { getShiftModalPosition } from './utils'

const DEFAULT_FILTERS = defaultFilters()
const INITIAL_STATE = initialState()

const useWorkSchedule = (): UseWorkScheduleReturn => {
  const [shifts, dispatch] = useImmerReducer<ShiftState, ArrayReducerAction<Shift>>(reducer, INITIAL_STATE)
  const [filters, _setFilters] = useState<WorkScheduleFilters>(DEFAULT_FILTERS)
  const [usersList, setUsersList] = useState<DictionaryItems<string>>([])
  const [summaryShifts, setSummaryShifts] = useState<SummaryShift[]>([])
  const [date, setDate] = useState<Date>(new Date())
  const [chipPosition, setChipPosition] = useState<Coords | null>(null)
  const [summaryModalInitialPosition, setSummaryModalInitialPosition] = useState<Coords>({ y: 0 })
  const [isRemoving, setIsRemoving] = useState(false)

  const [shift, setShift] = useState<Shift | null>(null)
  const [initialDate, setInitialDate] = useState<Date | null>(null)

  const { isLoading, startLoader, stopLoader } = useLoader()

  const filtersRef = useRef(filters)

  const { showAlert } = useCustomSnackbar()
  const { userRoles } = useContext(AuthCTX)

  const isBDC = userRoles.includes(UserRoles.BDC)

  const shiftsModalPosition = useMemo(() => getShiftModalPosition(chipPosition), [chipPosition])

  const {
    isModalOpen: isShiftModalOpen,
    onOpenModal: onOpenShiftForm,
    onCloseModal: _onCloseShiftForm
  } = useModal()

  const {
    isModalOpen: isSummaryModalOpen,
    onOpenModal: _onOpenSummaryModal,
    onCloseModal: onCloseSummaryModal
  } = useModal()

  const {
    isModalOpen: isClearMonthModalOpen,
    onOpenModal: onOpenClearMonthModal,
    onCloseModal: onCloseClearMonthModal
  } = useModal()

  const {
    isModalOpen: isCopyMonthOpen,
    onCloseModal: onCloseCopyMonthModal,
    onOpenModal: onOpenCopyMonthModal
  } = useModal()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onCloseShiftForm = useCallback(() => {
    setChipPosition(null)
    setShift(null)
    _onCloseShiftForm()
  }, [_onCloseShiftForm])

  const onOpenShift = useCallback((shiftData: Shift | Date): void => {
    if (shiftData instanceof Date) {
      setInitialDate(shiftData)
      setShift(null)
    } else {
      setShift(shiftData)
    }
    onOpenShiftForm()
  }, [onOpenShiftForm])

  const setShifts = useCallback((shifts: Shift[]) => {
    dispatch({ type: 'SET_ITEMS', payload: shifts })
  }, [dispatch])

  const loadSummaryShifts = useCallback(async (): Promise<void> => {
    try {
      startLoader()

      const datePayload = serializeDate(filtersRef.current.date)

      const result = await CRMApiProvider.getShiftsSummary(datePayload)

      setSummaryShifts(result.items)
    } catch (err) {
      // DO NOTHING
    } finally {
      stopLoader()
    }
  }, [startLoader, setSummaryShifts, stopLoader])

  const loadShifts = useCallback(async (): Promise<void> => {
    try {
      startLoader()

      const listPayload = serializeFilters(filtersRef.current)

      const datePayload: DatePayload = {
        month: listPayload.month,
        year: listPayload.year
      }

      const result = isBDC
        ? await CRMApiProvider.getMemberShifts(datePayload)
        : await CRMApiProvider.getShifts(listPayload)

      const parsedShifts = parseData(result.items ?? [])

      setShifts(parsedShifts)

      /**
       * we need to have actual summary info after loading shifts
       */
      if (!isBDC) void loadSummaryShifts()
    } catch (err) {
      // DO NOTHING
    } finally {
      stopLoader()
    }
  }, [
    startLoader, isBDC, setShifts,
    loadSummaryShifts, stopLoader
  ])

  const shiftDetailsSubmit = useCallback(async (shift: CreateShiftRequestDto | UpdateShiftRequestDto) => {
    try {
      if ('id' in shift && shift.id != null) {
        await CRMApiProvider.updateShift(shift)

        showAlert(SUCCESS_UPDATE_SHIFT_MESSAGE, { variant: 'success' })

        if (isSummaryModalOpen) {
          await loadSummaryShifts()
        }
      } else {
        await CRMApiProvider.addShift(shift)

        showAlert(SUCCESS_CREATE_SHIFT_MESSAGE, { variant: 'success' })
      }
      await loadShifts()
    } catch {
      showAlert('Saving shift failed')
    } finally {
      onCloseShiftForm()
    }
  }, [showAlert, onCloseShiftForm, loadSummaryShifts, isSummaryModalOpen, loadShifts])

  /**
   * onSubmit handler for removeShift button.
   */
  const onRemoveShift = useCallback(async (id: number) => {
    try {
      setIsRemoving(true)
      await CRMApiProvider.deleteShift(id)

      onCloseShiftForm()
      dispatch({ type: 'REMOVE_ITEM', payload: { id } })
      showAlert(SUCCESS_DELETE_SHIFT_MESSAGE, { variant: 'success' })

      if (isSummaryModalOpen) {
        void loadSummaryShifts()
      }
    } catch {
      showAlert('Removing shift failed')
    } finally {
      setIsRemoving(false)
    }
  }, [dispatch, onCloseShiftForm, showAlert, isSummaryModalOpen, loadSummaryShifts])

  /**
   * clear this month handler for ShowSummary modal.
   */
  const getDefaultSummaryShifts = (summaryShifts: SummaryShift[]): SummaryShift[] => {
    return summaryShifts.map((shift) => {
      return { ...shift, totalShifts: 0, totalHoursPerMonth: 0 }
    })
  }

  const onSubmitClearMonth = useCallback(async () => {
    try {
      startLoader()

      const datePayload = serializeDate(filtersRef.current.date)
      onCloseClearMonthModal()

      await CRMApiProvider.clearMonthShifts(datePayload)
      setSummaryShifts(getDefaultSummaryShifts(summaryShifts))
      setShifts([])
      showAlert('Shifts for this month deleted.', { variant: 'success' })
    } catch (err) {
      showAlert(err, { variant: 'error' })
    } finally {
      stopLoader()
    }
  }, [startLoader, onCloseClearMonthModal, summaryShifts, setShifts, showAlert, stopLoader])

  const onSubmitCopyMonth = useCallback(async (formData: CopyMonthFormData): Promise<void> => {
    try {
      startLoader()

      const copyMonthPayload = serializeCopyMonthFormDataToPayload(formData)
      onCloseCopyMonthModal()

      await CRMApiProvider.copyMonthShifts(copyMonthPayload)

      if (filters.date.getMonth() === formData.monthToFill.getMonth()) {
        await loadShifts()
      }

      showAlert('Schedule copied successfully.', { variant: 'success' })
    } catch (err) {
      showAlert(err)
    } finally {
      stopLoader()
    }
  }, [startLoader, stopLoader, showAlert, onCloseCopyMonthModal, filters.date, loadShifts])

  const onOpenSummaryModal = useCallback(async () => {
    await loadSummaryShifts()
    _onOpenSummaryModal()
  }, [_onOpenSummaryModal, loadSummaryShifts])

  const setFilters = useCallback(async (filters: Partial<WorkScheduleFilters>) => {
    filtersRef.current = { ...filtersRef.current, ...filters }
    _setFilters({ ...filtersRef.current })

    await loadShifts()
  }, [loadShifts])

  const onUsersListChange = useCallback(async (userIds: number[]) => {
    await setFilters({ userIds })
  }, [setFilters])

  const onDateChange = useCallback(async (date: Date) => {
    setDate(date)
    await setFilters({ date })
  }, [setFilters])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * setting initial position of ShowSummaryModal only once, to prevent it's rerender.
   * parent element is rerendering on each month change.
   */
  useEffect(() => {
    setSummaryModalInitialPosition({ y: 193 })
  }, [])

  useEffect(() => {
    if (isBDC) {
      return
    }

    const runEffect = async (): Promise<void> => {
      try {
        const { items } = await IdentityApiProvider.getUsers([ViewDealerRoles.Sales, ViewDealerRoles.BDC])

        setUsersList(parseUsers(items ?? []))
      } catch (err) {
        console.error(err)
      }
    }

    void runEffect()
  }, [isBDC])

  useEffect(() => {
    void loadShifts()
  }, [loadShifts])

  // ========================================== //

  return {
    shifts: shifts.items,
    date,
    setDate,
    isBDC,
    isLoading,
    onDateChange,
    changeChipPosition: setChipPosition,
    onOpenCopyMonthModal,
    isRemoving,
    shift,
    initialDate,
    shiftsModalPosition,
    onRemoveShift,
    shiftDetailsSubmit,
    isShiftModalOpen,
    onOpenShift,
    onCloseShiftForm,
    copyMonthModalProps: {
      currentMonth: filters.date,
      isModalOpen: isCopyMonthOpen,
      onSubmit: onSubmitCopyMonth,
      onCloseModal: onCloseCopyMonthModal
    },
    showSummaryModalProps: {
      summaryShifts,
      isModalOpen: isSummaryModalOpen,
      onCloseModal: onCloseSummaryModal,
      summaryModalInitialPosition: summaryModalInitialPosition
    },
    onOpenSummaryModal,
    clearMonthModalProps: {
      isModalOpen: isClearMonthModalOpen,
      onCloseModal: onCloseClearMonthModal,
      onCancel: onCloseClearMonthModal,
      onSubmit: onSubmitClearMonth
    },
    onOpenClearMonthModal,
    usersListFilter: {
      filters: filters.userIds,
      items: usersList,
      onChange: onUsersListChange
    }
  }
}

export default useWorkSchedule
