import { css } from '@emotion/css'
import { px, ScreenWidthBreakpoints } from 'constants/constants'

export const ACTIONS_MODAL_CLASS_NAME = css(`
  .cf-modal-root {
    .cf-modal-content {
      & > p {
        margin-bottom: 24px;
        line-height: 24px;
      }

      & > div {
        width: unset;
      }
    }
  }
`)

export const ACTION_MENU_CLASS_NAME = css(`
  .MuiPaper-root {
    border-radius: 8px 8px 0px 0px!important;
  }
`)

export default css(`
  position: relative;

  > .cf-loader {
    position: fixed;
    inset: 0px;
    left: 64px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.75);
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 24px 32px;
    margin-bottom: 24px;
    border-radius: 12px;
    box-shadow: 0px 24px 38px rgba(58, 71, 78, 0.1);

    &.center {
      align-items: center;
      overflow-x: hidden;
    }

    &.info-about-lead {
      min-height: 276px;
      padding: 24px 32px;
      border-radius: 2px 2px 12px 12px;

      .container:first-child {
        margin-right: 40px;
        width: calc(50% - 20px);
      }
    }

    &.log-activity {
      min-height: 530px;

      .container:first-child {
        margin-right: 40px;
        width: calc(50% - 20px);

        .open-tasks-title {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #101010;
          margin: 24px 0;
        }

        .open-tasks-container {
          border: 8px solid #F3F3F3;
          background: #F3F3F3;
          border-radius: 12px;
          flex: 1;
        }
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .toggle {
    margin-left: 32px;

    p { text-transform: uppercase; }
  }

  .container {
    display: flex;
    flex: 1;
    flex-direction: column;

    &-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.15px;
      color: #101010;
      margin-bottom: 15px;
    }
  }

  .lead-status {
    letter-spacing: 0.5px;
    min-width: 152px;
    width: 100%;
    height: 40px;
    border-radius: 2px;
    padding: 12px 20px;
    display: flex;
    margin-right: 4px;
    margin-bottom: 4px;
    align-items: center;

    &-container { flex: 1 0 auto; }

    :first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 2px;
    }

    :last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 2px;
      margin-right: 0;
    }
  }

  .no-tasks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 280px;
    background: #F3F3F3;
    border: 2px solid #F3F3F3;
    border-radius: 12px;

    p {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #101010;
    }
  }

  .status-unsubscribed {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    width: 107px;
    max-width: 107px;
    padding: 0 9px;
    height: 26px;
    border-radius: 4px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0.25px;
    text-align: center;
    border: none;
    user-select: none;
    margin-left: 8px;

    background-color: rgba(176, 0, 32, 0.10);
    color: #B00020;
  }

  .paused-banner {

    .cf-banner-content .cf-banner-message {
      margin: 0 8px;
      letter-spacing: 0.15px;
    }

    .cf-banner-content.cf-banner-type-info {
      border-color: transparent;
      background-color: rgba(33, 33, 33, 0.60);

      * {
        color: #fff;
        fill: #fff;
      }
    }

    &:after {
      content: '';
      height: 16px;
    }

    .resume-button {
      background-color: transparent!important;
      text-align: right;
      padding: 0;
      
      .cf-button-variant-text * {

        &:hover {
          color: #fff;
        }
      }

      .cf-button-content {
        font-size: 16px;
      }

      .cf-button-end-adornment {
        margin: 0;
      }
    }
  }
`)
