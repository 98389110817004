import { type FC, useRef, useState, useEffect, useCallback } from 'react'
import WaveSurfer from 'wavesurfer.js'
import { cnx } from '@carfluent/common'
import { floor, ceil } from 'mathjs'

import useAsyncEffect from 'hooks/useAsyncEffect'
import { getAccessToken } from 'services/storage.service'

import IconPlay from './components/IconPlay'
import IconPause from './components/IconPause'
import { type WaveFormPlayerProps } from './types'
import CLASS_NAME from './styles'
import IconSVG from 'components/inlineImages'

const WaveFormPlayer: FC<WaveFormPlayerProps> = ({
  className,
  fileUrl
}) => {
  const containerRef = useRef(null)
  const wavesurferRef = useRef<WaveSurfer | null>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onPlayPause = useCallback((): void => {
    wavesurferRef.current?.once('finish', () => {
      wavesurferRef.current?.stop()
      setIsPlaying(false)
    })

    void wavesurferRef.current?.playPause()
    setIsPlaying((prev) => !prev)
  }, [])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    wavesurferRef.current = WaveSurfer.create({
      barGap: 4,
      barRadius: 4,
      barWidth: 3,
      container: containerRef.current as unknown as HTMLDivElement,
      cursorColor: 'none',
      height: 32,
      normalize: true,
      progressColor: '#000000',
      waveColor: '#8D8D8D',
      fetchParams: {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`
        }
      }
    })

    wavesurferRef.current?.on('timeupdate', () => {
      const nextTime = (wavesurferRef.current?.getDuration() ?? 0) -
        (wavesurferRef.current?.getCurrentTime() ?? 0)

      setDuration(Math.max(nextTime, 0))
    })

    wavesurferRef.current?.on('ready', () => {
      setIsLoading(false) // Waveform is ready
    })

    return () => {
      wavesurferRef.current?.destroy()
    }
  }, [])

  useAsyncEffect(async (): Promise<void> => {
    setIsLoading(true)
    await wavesurferRef.current?.load(fileUrl)
    setDuration(wavesurferRef.current?.getDuration() ?? 0)
  }, [fileUrl])

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, 'waveform-player', className)}>
      <div className='btn-pause-play' onClick={onPlayPause}>
        {isPlaying ? <IconPause /> : <IconPlay />}
      </div>

      {isLoading && <IconSVG.Sound />}
      <div className='waveform-progress' ref={containerRef} />

      <div className='duration'>
        {formatDuration(duration)}
      </div>
    </div>
  )
}

export default WaveFormPlayer

// ========================================== //

const SECONDS_IN_MINUTE = 60

const formatDuration = (x: number): string => {
  const minutes = floor(x / SECONDS_IN_MINUTE)
  const seconds = ceil(x % SECONDS_IN_MINUTE)

  const formatter = new Intl.NumberFormat('en-IN', {
    minimumIntegerDigits: 2,
    maximumSignificantDigits: 2
  })

  return `${formatter.format(minutes)}:${formatter.format(seconds)}`
}
