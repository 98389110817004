import { css } from '@emotion/css'

export const CELL_CLASS = css(`
   padding: 2px 4px !important;

  .cf-input-container {
    background-color: #ffffff;
    border: 1px solid transparent;
    border-radius: 12px;
    box-shadow: none;
    max-height: 50px;
    
    &.focused {
      border: 1px solid #C99B86;
    }
    
    .cf-input {
      padding: 14px 0;
      
      ::placeholder {
        color: rgba(33, 33, 33, .5);
      }
    }
    
    :hover {
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
`)
