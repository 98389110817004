import { type FC, useState, useContext, useMemo } from 'react'
import { Button, Loader, parsers } from '@carfluent/common'

import Tooltip from 'components/common/Tooltip'

import { formatDate } from 'utils/parse_date'
import AuthCTX from 'store/auth'
import { UserRoles, type ReceiptItem, ReceiptStatus } from 'api/types'

import CLASS_NAME, { createTooltipCls } from './styles'

export interface ActionCellProps {
  getValue: () => ReceiptItem
  onRefund: (id: number) => void
  onPrint: (fileId: number) => Promise<void>
  onEdit: (item: ReceiptItem) => void
  onVoid: (id: number) => void
}

const RECEIPT_STATUS_MAP = {
  [ReceiptStatus.Refunded]: 'REFUNDED',
  [ReceiptStatus.Voided]: 'VOIDED'
}

const getDate = (value: string): string => {
  const date = parsers.parseDateStringUTC(value)

  return date != null ? formatDate(date, 'MM/dd/yy') ?? '-' : '-'
}

const TOOLTIP_TEXT = 'You don\'t have permission to perform this action'

const ActionsCell: FC<ActionCellProps> = ({
  onPrint: _onPrint,
  onVoid,
  onRefund,
  getValue,
  onEdit
}) => {
  const [isPrintLoading, setIsPrintLoading] = useState(false)
  const { userRoles } = useContext(AuthCTX)
  const isEditPermission = userRoles.find(role => (
    role === UserRoles.Mechanic || role === UserRoles.Buyer || role === UserRoles.BDC
  )) == null

  const tooltipCls = useMemo(() => createTooltipCls(isEditPermission), [isEditPermission])

  const receiptItem = getValue()
  const {
    receiptStatusId,
    modifiedByUser,
    modifiedDate,
    id,
    fileId
  } = receiptItem

  const onPrint = async (): Promise<void> => {
    try {
      setIsPrintLoading(true)
      await _onPrint(fileId)
    } finally {
      setIsPrintLoading(false)
    }
  }

  return (
    <div className={CLASS_NAME}>
      {receiptStatusId === ReceiptStatus.Issued
        ? (
          <>
            <Button
              variant='text'
              onClick={onPrint}
              isDisabled={isPrintLoading}
              className='print-button'
            >
              Print {isPrintLoading && <Loader size={18} color='dark' />}
            </Button>

            <Tooltip
              title={TOOLTIP_TEXT}
              popperClassName={tooltipCls}
            >
              <Button
                variant='text'
                isDisabled={!isEditPermission}
                onClick={() => onEdit(receiptItem)}
              >
                Edit
              </Button>
            </Tooltip>

            <Tooltip
              title={TOOLTIP_TEXT}
              popperClassName={tooltipCls}
            >
              <Button
                variant='text'
                isDisabled={!isEditPermission}
                onClick={() => onVoid(id)}
              >
                Void
              </Button>
            </Tooltip>

            <Tooltip
              title={TOOLTIP_TEXT}
              popperClassName={tooltipCls}
            >
              <Button
                variant='text'
                isDisabled={!isEditPermission}
                onClick={() => onRefund(id)}
              >
                Refund
              </Button>
            </Tooltip>
          </>
          )
        : (
          <p>
            {RECEIPT_STATUS_MAP[receiptStatusId]} {getDate(modifiedDate)} by {modifiedByUser.dealerFirstName} {modifiedByUser.dealerLastName}
          </p>
          )
      }
    </div>
  )
}

export default ActionsCell
