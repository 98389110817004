import { TransactionTypeId, AccountTypeId } from 'api/types'

const getSuggestedAccountTypes = (
  transactionTypeId: number | null,
  isForTable = false
): number[] => {
  switch (transactionTypeId) {
    case TransactionTypeId.PayBill:
    case TransactionTypeId.Check: {
      return isForTable ? [AccountTypeId.Expense] : []
    }

    case TransactionTypeId.Receivable: {
      return isForTable
        ? [AccountTypeId.Income]
        : []
    }

    default: {
      return []
    }
  }
}

export default getSuggestedAccountTypes
