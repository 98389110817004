import { css } from '@emotion/css'

export default css(`
  margin-left: 24px;

  .link-cell {
    background: transparent;
  }

  .cf-cell-wrapper {
    color: #101010;
    padding-right: 16px;
  }

  .cf-cell-wrapper,
  .cf-header-cell-title p,
  .cf-table-header-title p,
  .cf-header-cell-title div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .cf-header-cell-title,
  .cf-header-cell-title div {
    max-width: 100%!important;
  }

  .cf-table {
    margin-right: 24px;
    background-color: #F3F3F3;
    border-radius: 12px 12px 0 0;

    .cf-table-header-cell {
      height: 32px;

      .tooltip-container,
      .cf-header-cell-title {
        display: flex;
        padding-top: 0;
        padding-bottom: 0;
        align-items: center;
      }

      .tooltip-container {
        span {
          font-weight: 500;
        }
      }
    }

    .cf-table-group-row {
      height: 31px;
      box-sizing: border-box;

      .cf-table-group-cell {
        height: 32px;
        box-sizing: border-box;
      }
    }

    th {
      margin-bottom: 0;
    }

    tr:first-of-type th:last-of-type {
      border-radius: 0 12px 0 0;
    }

    /**
     * Paint group cells start
     * Use important to re-write skeleton styles
     */
    tr:not(:hover) {
      td.car_fluent-website-leads-group {
        & > div {
          background: #FDFFF0!important;
        }
      }

      td.dealership-leads-group {
        & > div {
          background: #EAFCE9!important;
        }
      }
    
      td.internet-leads-group {
        & > div {
          background: #E6FFF6!important;
        }
      }

      td.other-leads-group {
        & > div {
          background: #E4F7FF!important;
        }
      }
    }
    /**
     * Paint group cells end
     */

    /**
     * Paint header cells start
     */
    .car_fluent-website-leads-group {
      background: #F5F7E8;
    }
  
    .dealership-leads-group {
      background: #E5F7E4;
    }
  
    .internet-leads-group {
      background: #E0F7EF;
    }
    .other-leads-group {
      background: #DDEFF7;
    }
    /**
     * Paint header cells end
     */

    tbody {
      tr {
        border-top: 1px solid #0000001F;
        border-bottom: 1px solid #0000001F;

        :not(:hover) td {
          :nth-of-type(4) div {
            background: #f3f3f3!important;
          }
        }

        &.cf-table-row-hover:hover {
          .cf-cell-wrapper > a {
            background: transparent;
          }

          > td:first-child > div {
            border-left-color: transparent;
          }
        }
      }
    }
  }

  .header-indent {
    .tooltip-container,
    .cf-header-cell-title,
    .cf-cell-wrapper {
      padding-left: 16px;
    }
  }

  .cf-table-group-row {
    td {
      height: 32px;

      &:first-child {
        overflow-y: clip;
  
        > div {
          border: none!important;
          box-shadow: 4px 0px 12px 2px #0000000D;
        }
      }
    }
  }

  /**
   * Add box-shadow for the fixed table side start
   */
  .cf-table-header-row {
    :first-of-type {
      th:first-of-type {
        overflow-y: clip;

        > div {
          box-shadow: 4px 0px 12px 2px #0000000D;
        }
      }
    }
    :last-of-type {
      th:nth-of-type(4) {
        overflow-y: clip;

        > div {
          box-shadow: 4px 0px 12px 2px #0000000D;
        }
      }
    }
  }
  /**
   * Add box-shadow for the fixed table side end
   */

  .cf-divider-left,
  .cf-divider-right {
    display: none;
  }

  .cf-group-title {
    padding: 0px 16px;
    box-sizing: border-box;

    p {
      font-weight: 500;
    }
  }

  .MuiTableContainer-root.cf-table-wrapper {
    border-radius: 12px 0 0 0;
  }

  .cf-table-wrapper{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    ::-webkit-scrollbar {
      display: none; /* For Webkit browsers */
    }

    .cf-no-data-block {
      left: 0;
      border-top: 0;
      display: flex;
      padding: 16px;
      font-size: 16px;
      position: sticky;
      background: #fff;
      line-height: 24px;
      letter-spacing: 0.5px;
      justify-content: flex-start;
      border-top: 1px solid #0000001F;
      border-bottom: 1px solid #0000001F;
    }
  }

  .btn-new-item {
    width: 150px;
    height: 56px;
  }

  .cf-table-header-title {
    height: 32px;
    box-sizing: content-box;

    p {
      color: #101010;
      font-weight: 600;
    }
  }


  .MuiTableContainer-root.cf-table-wrapper {
    width: auto;
    overflow-x: auto!important;
    position: relative;
  }

  .MuiTableContainer-root.skeleton-table-wrapper {
    position: sticky;
    left: 0;
  }


  .cf-table-header-row:nth-of-type(1) th {
    padding-left: 16px;

    &:nth-of-type(1) {
      left: 0;
      z-index: 3;
      position: sticky;
      background: #F3F3F3;
    }
  }

  /**
   * Fix the titles of the left side of the table
   */
  .cf-table-header-row:nth-of-type(2) th {
    :nth-of-type(1) {
      left: 0;
      z-index: 3;
      position: sticky;
      background: #F3F3F3;
    }

    :nth-of-type(2) {
      z-index: 3;
      left: 240px;
      position: sticky;
      background: #F3F3F3;
    }

    :nth-of-type(3) {
      z-index: 4;
      left: 360px;
      position: sticky;
      background: #F3F3F3;
    }

    :nth-of-type(4) {
      z-index: 3;
      left: 480px;
      position: sticky;
      background: #F3F3F3;
    }
  }

  /**
   * Fix the cells of the left side of the table
   */
  .cf-table-row td,
  .cf-table-group-row td {
    :nth-of-type(1) {
      left: 0;
      z-index: 3;
      position: sticky;
    }

    :nth-of-type(2) {
      z-index: 3;
      left: 240px;
      position: sticky;
    }

    :nth-of-type(3) {
      z-index: 4;
      left: 360px;
      position: sticky;
    }

    :nth-of-type(4) {
      z-index: 3;
      left: 480px;
      position: sticky;
      overflow-y: clip;

      span {
        font-weight: 500;
      }

      > div {
        box-shadow: 4px 0px 12px 2px #0000000D;
      }
    }
  }

  .car-details-group {
    background: #F3F3F3;
  }
`)

export const ROOT_CLASS_NAME = css(`
  margin-left: 0!important;

  .cf-md {
    margin: 0 24px;
    width: calc(100% - 48px);
  }

  .cf-report-top-actions {
    margin-top: 24px;
    margin-bottom: 24px!important;
  }
`)
