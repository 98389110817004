import { useCallback, useEffect, useState } from 'react'
import { type DeepRequired, omitNotNumbers, useForm, useLoader, isOk, isFail } from '@carfluent/common'

import CustomersCoreApiProvider from 'api/customersCore.api'
import { type LienholderItemDto } from 'api/types'
import type { FullAddressParts } from 'types/address'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import parseError from 'utils/parseErrors'
import isLockedLienholderError from 'utils/isLockedLienholderError'

import { FIELD_TRANSFORMS, Messages, getDefaultFormData } from './constants'
import type {
  UseLienholderReturn,
  UseLienholderProps,
  LienholderFormData,
  FieldPath,
  ErrTouchShortcuts
} from './types'
import parseCustomerData from './parser'
import serializeCustomerForm from './serializer'
import validationRules from './validator'

const DEFAULT_FORM_DATA = getDefaultFormData()

const useLienholder = ({
  isOpen,
  lienholderId,
  onSubmit: _onSubmit,
  onDeleteLienholder: _onDeleteLienholder,
  onClose: _onClose
}: UseLienholderProps): UseLienholderReturn => {
  const { showAlert } = useCustomSnackbar()

  const { isLoading, startLoader, stopLoader } = useLoader()
  const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false)

  // ========================================== //
  //                  HANDLERS                  //
  // ========================================== //

  const submitAction = useCallback(async (values: DeepRequired<LienholderFormData>): Promise<void> => {
    const payload = serializeCustomerForm(values)
    let lienholder: LienholderItemDto

    if (lienholderId == null) {
      lienholder = await CustomersCoreApiProvider.createLienholder(payload)
    } else {
      lienholder = await CustomersCoreApiProvider.updateLienholder({ ...payload, id: lienholderId })
    }

    await _onSubmit(lienholder)
  }, [showAlert, _onSubmit, lienholderId, startLoader, stopLoader])

  const deleteAction = useCallback(async () => {
    if (lienholderId != null) {
      await CustomersCoreApiProvider.removeLienholder(lienholderId)
      await _onDeleteLienholder?.()
    }
  }, [lienholderId, showAlert, startLoader, stopLoader, _onDeleteLienholder])

  const onActionResult = useCallback((res, resetForm, reason, setErrors) => {
    stopLoader()

    if (reason === 'submit' && isOk(res)) {
      showAlert(
        lienholderId == null ? Messages.SuccessCreate : Messages.SuccessUpdate,
        { variant: 'success' }
      )
      _onClose()
      resetForm(getDefaultFormData())
    }

    if (reason === 'delete' && isOk(res)) {
      showAlert(Messages.SuccessDelete, { variant: 'success' })
      _onClose()
      resetForm(getDefaultFormData())
    }

    if (isFail(res)) {
      setErrors(parseError(res.result))
      if (reason === 'delete' && isLockedLienholderError(res.result)) {
        setIsBannerErrorVisible(true) // banner will be "removed" only by page leaving or by form re-submit.
      }
    }
  }, [_onClose, showAlert, stopLoader, lienholderId])

  const {
    values,
    errors,
    touched,
    onChange: _onChange,
    onBlur,
    onSubmit,
    onDelete,
    resetForm
  } = useForm<LienholderFormData, ErrTouchShortcuts>({
    transforms: FIELD_TRANSFORMS,
    baseValues: DEFAULT_FORM_DATA,
    validationRules,
    submitAction,
    deleteAction,
    onActionResult
  })

  const onClose = useCallback(() => {
    setIsBannerErrorVisible(false)
    resetForm(getDefaultFormData())
    _onClose?.()
  }, [_onClose, resetForm])

  const onChange = useCallback((field: string, value: unknown): void => {
    const fieldKey = field as FieldPath

    switch (fieldKey) {
      case 'name': {
        if (value == null || typeof value === 'string') {
          _onChange(fieldKey, value ?? null)
        } else {
          const lienholder = value as LienholderItemDto
          resetForm(parseCustomerData(lienholder))
        }
        break
      }

      case 'address': {
        const addressData = value as FullAddressParts | null
        _onChange(fieldKey, addressData)
        break
      }

      case 'phoneNumber': {
        if (typeof value === 'string') {
          _onChange(fieldKey, omitNotNumbers(value))
        }
        break
      }

      default: {
        _onChange(fieldKey, value)
      }
    }
  }, [_onChange, resetForm])

  const loadLienholderData = useCallback(async (id: number): Promise<void> => {
    try {
      startLoader()
      const lienholder = await CustomersCoreApiProvider.getLienholder(id)
      resetForm(parseCustomerData(lienholder))
    } catch (err) {
      showAlert(err)
    } finally {
      stopLoader()
    }
  }, [startLoader, resetForm, showAlert, stopLoader])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Load customer data if dialog is open in "edit" mode.
   */
  useEffect(() => {
    setIsBannerErrorVisible(false)

    if ((lienholderId == null) || !isOpen) {
      return
    }

    void loadLienholderData(lienholderId)
  }, [lienholderId, isOpen, loadLienholderData])

  // ========================================== //

  return {
    isEdit: lienholderId != null,
    isBannerErrorVisible,
    isLoading,
    isOpen,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onDelete,
    onSubmit,
    onClose
  }
}

export default useLienholder
