import { type FC } from 'react'
import { FormNumberInput, UI, cnx } from '@carfluent/common'
import { observer } from 'mobx-react-lite'
import identity from 'lodash-es/identity'

import type { AccountListItem } from 'api/types/accounting.types'
import { getOptionSelected } from 'utils/general'
import { MAX_PRICE_VALUE } from 'constants/validation'

import CheckboxLabel from 'components/common/CheckboxLabel'
import DeleteModal from 'components/dialogs/DeleteModal'
import { Dropdown } from 'components/form'

import { PackCostFields, PackCostProps } from './types'
import usePackCostForm, { formatAccountName } from './hook'
import CLASS_NAME from './styles'

export * from './types'

export { GET_DEFAULT_PACK_COST, formatAccountName } from './hook'

const PackCost: FC<PackCostProps> = (props) => {
  const {
    checkboxValue,
    creditAccountProps,
    debitAccountProps,
    deleteModalProps,
    errors,
    isLastLineErrorVisible,
    onBlur,
    onTogglePackCost,
    setFieldValue,
    touched,
    values
  } = usePackCostForm(props)

  return (
    <div className={cnx(CLASS_NAME, isLastLineErrorVisible && 'last-line-error')}>
      <div className='cf-pack-item-wrapper'>
        <CheckboxLabel
          value={checkboxValue}
          onChange={onTogglePackCost}
        />
      </div>

      {values.isPackEnabled && (
        <>
          <div className='cf-item-wrapper-dropdown'>
            <Dropdown<AccountListItem, AccountListItem | null>
              {...debitAccountProps}
              className='cf-pack-dropdown'
              error={errors.packInventoryAssetAccount}
              id={PackCostFields.PackInventoryAssetAccount}
              isAutocompleteBlurHandlerSet
              isOptionOfValue={getOptionSelected}
              getOptionLabel={formatAccountName}
              getOptionSelected={getOptionSelected}
              getOptionValue={identity}
              label='Pack inventory asset account'
              mode={UI.DropdownMode.Autocomplete}
              onChange={setFieldValue}
              renderOption={renderOption}
              touched={touched.packInventoryAssetAccount}
              value={values.packInventoryAssetAccount}
            />

            <Dropdown<AccountListItem, AccountListItem | null>
              {...creditAccountProps}
              className='cf-pack-dropdown'
              error={errors.packHoldingAccount}
              id={PackCostFields.PackHoldingAccount}
              isAutocompleteBlurHandlerSet
              isOptionOfValue={getOptionSelected}
              getOptionLabel={formatAccountName}
              getOptionSelected={getOptionSelected}
              getOptionValue={identity}
              label='Pack holding account'
              mode={UI.DropdownMode.Autocomplete}
              onChange={setFieldValue}
              renderOption={renderOption}
              touched={touched.packHoldingAccount}
              value={values.packHoldingAccount}
            />
          </div>

          <div className='cf-item-wrapper-input'>
            <FormNumberInput
              className='cf-pack-input'
              error={errors.packCost}
              id={PackCostFields.PackCost}
              preset='price'
              isEmptyAllowed
              isSelectedOnFocus
              label='Pack cost'
              onBlur={onBlur}
              onChange={setFieldValue}
              max={MAX_PRICE_VALUE}
              touched={touched.packCost}
              value={values.packCost}
            />
          </div>

          <DeleteModal
            {...deleteModalProps}
            title='Delete pack cost?'
            content='Once you Save changes, related journal entry will be deleted automatically.'
          />
        </>
      )}
    </div>
  )
}

export default observer(PackCost)

// ========================================== //

// AZ-TODO: move to classes
const OPTION_STYLES = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  height: 'auto'
} as const

const renderOption = (option: AccountListItem | null): JSX.Element => (
  <div style={OPTION_STYLES}>
    {formatAccountName(option)}
  </div>
)
