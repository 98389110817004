import { parsers as P, serializers as S } from '@carfluent/common'
import omit from 'lodash-es/omit'
import get from 'lodash-es/get'

import { type DealFormValues } from 'types'
import { type UpdateDealDto } from 'api/types/requests'
import {
  type DealFeesAndCoveragesSettingsModel,
  type DealModel,
  DeliveryType,
  DealFeesSettingsToValuesMap
} from 'api/types/responses'

import { isValidDate } from 'utils/validation'
import _isFinancing from 'utils/deals/isFinancing'
import { isTruthy } from 'utils/general'

const INVALID_ID_THAT_SHOULD_NEVER_HAPPEN = -42

const serializeTabDeal = (
  values: DealFormValues,
  originalDeal: DealModel,
  feesAndCoveragesSettings: DealFeesAndCoveragesSettingsModel | null
): UpdateDealDto => {
  const isFinancing = _isFinancing(values.dealFinanceTypeId)
  const hasLender = values.activeLenderDecision.lienholder != null
  const deliveryTypeId = values?.checkoutDetails?.deliveryType?.id ?? null

  const pickupDeliveryDate = values.checkoutDetails?.pickupDeliveryDate
  const pickupDeliveryTime = values.checkoutDetails?.pickupDeliveryTime
  let resolvedPickupDeliveryDate: Date | null = null

  if (isValidDate(pickupDeliveryDate)) {
    const { hours = 0, minutes = 0 } = P.parseTimeInterval(pickupDeliveryTime ?? '') ?? {}
    resolvedPickupDeliveryDate = new Date(pickupDeliveryDate.setHours(hours, minutes))
  }

  return {
    dealFinanceTypeId: values.dealFinanceTypeId ?? null, // `null` can be when a deal is created from the Customer app
    vehicle: {
      listPrice: values.vehicle.listPrice ?? 0
    },
    tradeInDetails: values.isTradeInSectionVisible
      ? {
          paymentAmount: values.tradeInDetails.paymentAmount ?? 0,
          acv: values.tradeInDetails.acv ?? 0,
          credit: values.tradeInDetails.credit ?? 0
        }
      : null,
    salespersonId: values.salesperson?.id ?? null,
    saleDate: S.serializeDateTime(values.saleDate),
    firstPaymentDate: S.serializeDateTime(values.firstPaymentDate),
    financingApplicationLenderDecisionDetails: (isFinancing && hasLender)
      ? {
          lienholderId: values.activeLenderDecision?.lienholder?.id ?? INVALID_ID_THAT_SHOULD_NEVER_HAPPEN,
          approvedRate: values.activeLenderDecision?.approvedRate ?? 0,
          downPayment: values.activeLenderDecision?.downPayment ?? null,
          approvedTerm: values.activeLenderDecision?.approvedTerm ?? 0
        }
      : null,
    dealFees: serializeDealFees(values.dealFees, originalDeal.dealFees, feesAndCoveragesSettings),
    checkoutDetails: {
      hasDelivery: deliveryTypeId == null ? null : (deliveryTypeId === DeliveryType.Delivery),
      pickupDeliveryDate: S.serializeDateTime(resolvedPickupDeliveryDate)
    }
  }
}

const serializeDealFees = (
  fees: DealFormValues['dealFees'],
  originalFees: DealModel['dealFees'],
  feesAndCoveragesSettings: DealFeesAndCoveragesSettingsModel | null
): UpdateDealDto['dealFees'] => {
  const otherCharges = fees?.others ?? []
  const enabledFees: Partial<DealModel['dealFees']> = Object.fromEntries(
    Object
      .entries(feesAndCoveragesSettings?.fees ?? {})
      .map(([key, conf]) => {
        const feeKey = get(DealFeesSettingsToValuesMap, key)
        return isTruthy(conf.isEnabled)
          ? [feeKey, get(fees, feeKey)]
          : [feeKey, get(originalFees, feeKey)]
      })
  )

  return {
    ...omit(
      fees,
      'others',
      'stateSalesTaxPercent',
      'overridenSalesCommission',
      'overridenSalesTaxPercent',
      'overridenSalesTaxAmount',
      'dealerInventoryTax'
    ),
    ...enabledFees,
    other1: otherCharges[0]?.amount ?? null,
    other1Description: otherCharges[0]?.description ?? null,
    other2: otherCharges[1]?.amount ?? null,
    other2Description: otherCharges[1]?.description ?? null,
    other3: otherCharges[2]?.amount ?? null,
    other3Description: otherCharges[2]?.description ?? null
  }
}

export default serializeTabDeal
