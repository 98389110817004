import { type ChangeEvent, type FocusEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useForm, type Preset, PRESET_LABELS, dateToString, stringToDate, DEFAULT_MAX_DATE, DEFAULT_MIN_DATE, RangePickerMode, useSubscribe, isOk } from '@carfluent/common'

import { getDefaultPreset } from 'utils/periodFilter'
import { getDateLabel } from 'utils/filters/filterPanel'

import { getBaseValueData } from './utils'
import { dateValidator } from './validator'
import type { PeriodFilterProps, UseDateRangePickerProps, UseDateRangePickerReturn } from './types'
import Events from 'constants/events'

const POPOVER_OPTIONS = { modifiers: [{ name: 'offset', options: { offset: [220, 8] } }] }

const useDateRangePicker = ({
  presets,
  notifyOnMount,
  presetLabel,
  onFilterChange,
  rangePickerMode,
  defaultPresetName,
  /**
   * dateFiltersResetMarker is used to trigger picker reset as it does not
   * work properly if we try to pass new appliedValues.
   *
   * It does not work because it tries to manage preset as from-to values
   * but does so inconsistently and with bugs
   */
  dateFiltersResetMarker,
  initPreset: _initPreset,
  appliedFilters: _appliedFilters
}: UseDateRangePickerProps): UseDateRangePickerReturn => {
  const [isOpen, setIsOpen] = useState(false)
  const initialYear = useRef(new Date().getFullYear())
  const initialMonth = useRef(new Date().getMonth())
  const refNotifyOnMount = useRef(notifyOnMount)
  const defaultPreset = useMemo(() => getDefaultPreset({
    presets,
    presetName: defaultPresetName
  }), [presets, defaultPresetName])
  const initPreset = useRef(_initPreset ?? defaultPreset)

  const baseValues = useMemo(() => {
    return getBaseValueData(defaultPreset, _appliedFilters)
  }, [defaultPreset, _appliedFilters])

  const {
    values,
    errors,
    touched,
    isValid,
    resetForm,
    setValues,
    validateForm,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    onChange: _onChange,
    onSubmit: _onSubmit
  } = useForm<PeriodFilterProps>({
    baseValues,
    submitAction: async ({ appliedFilters, activeDatePreset }) => await onFilterChange({
      from: appliedFilters?.from === DEFAULT_MIN_DATE ? null : appliedFilters.from,
      to: appliedFilters?.to === DEFAULT_MAX_DATE ? null : appliedFilters.to
    }, _appliedFilters != null ? activeDatePreset : undefined),
    validationRules: dateValidator[rangePickerMode ?? RangePickerMode.Range]
  })

  const isCloseIcon = (values.activeDatePreset?.name !== initPreset.current?.name)
  const refAnchorEl = useRef<HTMLDivElement | null>(null)
  const isEmptyInput = values.activeDatePreset?.name === PRESET_LABELS.ALL || values.activeDatePreset?.name === PRESET_LABELS.ALL_TILL_TODAY

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //
  const resetManualDate = (): void => {
    setFieldValue('endDate', '')
    setFieldValue('startDate', '')
  }

  const resetManualDateErrors = (): void => {
    setFieldError('endDate', '')
    setFieldError('startDate', '')
  }

  const onClose = (): void => {
    setFieldTouched('endDate', false)
    setFieldTouched('startDate', false)
    setFieldValue('endDate', values.appliedFilters?.to === DEFAULT_MAX_DATE ? '' : dateToString(values.appliedFilters?.to))
    setFieldValue('startDate', values.appliedFilters?.from === DEFAULT_MIN_DATE ? '' : dateToString(values.appliedFilters?.from))

    resetForm()
    setIsOpen(false)
  }

  const onResetDateFilter = (): void => {
    if (values.activeDatePreset?.name === initPreset.current?.name) {
      setIsOpen(false)
      return
    }
    const isAllPresetSelected = initPreset.current?.name === PRESET_LABELS.ALL
    const isAllTillTodayPresetSelected = initPreset.current?.name === PRESET_LABELS.ALL_TILL_TODAY

    const appliedFilters = {
      from: isAllPresetSelected ?? isAllTillTodayPresetSelected ? null : initPreset.current?.startDate ?? null,
      to: isAllPresetSelected ? null : initPreset.current?.endDate ?? null
    }

    void onFilterChange(appliedFilters, _appliedFilters != null ? initPreset.current : undefined)
    setValues(getBaseValueData(initPreset.current))
    setIsOpen(false)
  }

  const onChange = (startDate: Date | null, endDate: Date | null): void => {
    const appliedFilters = {
      to: endDate,
      from: startDate
    }

    /**
     * OP-NOTE: There is edge case when user select only startField in the date picker after
     * that switch to `from` input clear everything from input and press `show results` btn.
     * In this scenario, both 'appliedFilters.to' and 'appliedFilters.from' become equal
     * and are set to DEFAULT_MIN_DATE.
     * Instead, when the user clears the 'from' input in this manner, 'appliedFilters.to'
     * should be set to DEFAULT_MAX_DATE to maintain consistent and logical date range filtering.
     */
    if (appliedFilters.from === DEFAULT_MIN_DATE && appliedFilters.to === DEFAULT_MIN_DATE) {
      appliedFilters.to = DEFAULT_MAX_DATE
    }

    if (rangePickerMode === RangePickerMode.Single) {
      appliedFilters.to = appliedFilters.from
    }

    resetManualDateErrors()
    setFieldValue('appliedFilters', appliedFilters)
    setFieldValue('endDate', appliedFilters?.to == null || appliedFilters?.to === DEFAULT_MAX_DATE ? '' : dateToString(appliedFilters?.to))
    setFieldValue('startDate', appliedFilters?.from === DEFAULT_MIN_DATE ? '' : dateToString(appliedFilters?.from))
  }

  const onSubmit = (): void => {
    if (values.appliedFilters == null) {
      return
    }
    void _onSubmit()
    resetForm(values)
    setIsOpen(false)
  }

  const onPresetChange = (value: Preset | null): void => {
    if (value?.name === PRESET_LABELS.ALL || value?.name === PRESET_LABELS.ALL_TILL_TODAY) {
      resetManualDate()
    }

    setFieldValue('activeDatePreset', value)
    if (value != null) {
      resetManualDateErrors()
    }
  }

  const periodLabel = getDateLabel(
    values.appliedFilters ?? { from: null, to: null },
    values.activeDatePreset,
    presetLabel ?? defaultPreset?.name ?? ''
  )

  const onChangeDate = (event: ChangeEvent<HTMLInputElement>): void => {
    const dateKey = event.target.id
    _onChange(dateKey, event.target.value)

    onPresetChange(null)
  }

  const onBlur = (event: FocusEvent<HTMLInputElement>): void => {
    if (isEmptyInput) {
      return
    }
    const dateKey = event.target.id
    const stringDate = event.target.value.replace(/\D/g, '')

    if (event.target.value === '') {
      if (rangePickerMode === RangePickerMode.Single) {
        resetForm()
      } else if (dateKey === 'startDate') {
        setFieldValue('appliedFilters', { ...values.appliedFilters, from: DEFAULT_MIN_DATE })
      } else if (dateKey === 'endDate') {
        setFieldValue('appliedFilters', { ...values.appliedFilters, to: DEFAULT_MAX_DATE })
      }

      validateForm()

      return
    }

    const date = stringToDate(stringDate)

    if (rangePickerMode === RangePickerMode.Single) {
      setFieldValue('appliedFilters', { from: date, to: date })
      setFieldValue('endDate', stringDate)
      setFieldValue('startDate', stringDate)
    } else {
      if (dateKey === 'endDate' && date != null) {
        setFieldValue('appliedFilters', { ...values.appliedFilters, to: date })
      }
      if (dateKey === 'startDate' && date != null) {
        setFieldValue('appliedFilters', { ...values.appliedFilters, from: date })
      }
    }

    validateForm()
  }

  const toggleOpen = (): void => {
    setIsOpen((prev) => !prev)
  }

  /**
      * DateRangePicker is not convenient to work with. It always parses its data giving priority
   * to preset. Because of this we need to set preset to null when props.appliedFilters are manually changed.
   *
   * It also does not work correctly if we pass appliedFilters directly - it does not what to apply
   * preset or appliedFilters. It is a mess.
   *
   * So, there are you go - mediator overkill for local state syncing ->
   * Good luck refactoring this component and places where it is used...
   */

  useSubscribe(Events.ScheduleBalanceDateRequested, async (data) => {
    setFieldValue('activeDatePreset', data == null ? defaultPreset : null)
    setFieldValue('appliedFilters', data ?? { from: null, to: null })
    return isOk(true)
  })

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * OP-NOTE: trigger onFilterChange when mounting a component. Use in Accounting Reports
   */
  useEffect(() => {
    if (refNotifyOnMount.current != null) {
      void onFilterChange({
        to: defaultPreset?.endDate ?? null,
        from: defaultPreset?.startDate ?? null
      })
    }
  }, [])

  useEffect(() => {
    resetForm(getBaseValueData(initPreset.current))
  }, [dateFiltersResetMarker, resetForm])

  // ========================================== //

  return {
    isOpen,
    errors,
    onBlur,
    onClose,
    presets,
    touched,
    onChange,
    onSubmit,
    toggleOpen,
    periodLabel,
    isCloseIcon,
    refAnchorEl,
    onChangeDate,
    onPresetChange,
    onResetDateFilter,
    disabled: !isValid,
    endDateString: values.endDate,
    popoverOptions: POPOVER_OPTIONS,
    startDateString: values.startDate,
    appliedFilters: values.appliedFilters,
    activeDatePreset: values.activeDatePreset,
    initialYear: values.appliedFilters.to === DEFAULT_MAX_DATE ? initialYear.current : undefined,
    initialMonth: values.appliedFilters.to === DEFAULT_MAX_DATE ? initialMonth.current : undefined
  }
}

export default useDateRangePicker
