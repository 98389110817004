import { formatDate } from 'utils/parse_date'
import { TIME_12H_MIN_AMPM_COMPACT_FORMAT } from 'constants/constants'

export const displayHours = (startDate: Date, endDate: Date): string => (
  `${formatDate(startDate, TIME_12H_MIN_AMPM_COMPACT_FORMAT)}-${formatDate(endDate, TIME_12H_MIN_AMPM_COMPACT_FORMAT)}`)

export const getCoords = (elem: HTMLElement) => {
  let box = elem.getBoundingClientRect();

  return {
    x: box.left,
    y: box.top,
  };
}