import { css } from '@emotion/css'

export default css(`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 2px 8px;
  height: 24px;
  min-width: 78px;
  border-radius: 4px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  border: none;
  user-select: none;
`)
