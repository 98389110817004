import { useCallback, useState } from 'react'

import CRMApiProvider from 'api/crm.api'
import useTableApi from 'hooks/useTableApi'
import useAsyncEffect from 'hooks/useAsyncEffect'
import VehiclesApiProvider from 'api/vehicles.api'
import type { InterestedLeadsLisDto, InterestedLeadsListGetRequestDto, ListResponse } from 'api/types'

import type {
  UseLeadsTabProps,
  UseLeadsTabReturn,
  PriceHistoryProps,
  InterestedLeadsListData
} from './types'

import {
  Messages,
  DEFAULT_FILTERS,
  DEFAULT_SORTING,
  API_CALL_DELAY_SEARCH
} from './constants'

import parseListData from './parser'
import { calcTotalAmountOfLeads, convertToPricesByDate, countLeadsAmountForRange } from './utils'
import { useRefUpdater } from '@carfluent/common'

const useLeadsTab = ({ vehicleId }: UseLeadsTabProps): UseLeadsTabReturn => {
  const [priceHistoryProps, setPriceHistoryProps] = useState<PriceHistoryProps>({
    data: [],
    graphData: [],
    isLoading: false,
    lastDateTime: null
  })
  const getInterestedLeadsList = useCallback(async (payload): Promise<ListResponse<InterestedLeadsLisDto>> => {
    return await CRMApiProvider.getInterestedLeadsList({ ...payload, vehicleId })
  }, [vehicleId])

  const getTotalLeads = useCallback(async (): Promise<void> => {
    const { all: totalLeads } = await CRMApiProvider.getInterestedLeadsAmount(vehicleId)
    setPriceHistoryProps((prev) => ({
      ...prev,
      totalLeads
    }))
  }, [])

  const {
    rows,
    sorting,
    loadRows,
    isLoading,
    emptyTableMessage,
    setSorting: onSortingChange
  } = useTableApi<InterestedLeadsLisDto, InterestedLeadsListGetRequestDto, InterestedLeadsListData>({
    shouldRunOnCall: true,
    parseListData: parseListData,
    defaultFilters: DEFAULT_FILTERS,
    getList: getInterestedLeadsList,
    defaultSorting: DEFAULT_SORTING,
    apiCallDelay: API_CALL_DELAY_SEARCH,
    emptyTableMessage: Messages.emptyTableState,
    nothingFoundMessage: Messages.nothingFoundState
  })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const getPriceHistoryData = async (dateTime: string, lastDateTime: string | null): Promise<void> => {
    try {
      setPriceHistoryProps((prevState) => ({ ...prevState, isLoading: true }))

      const [{ items }, { items: leadsDates }, { all: totalLeads }] = await Promise.all([
        VehiclesApiProvider.getPriceHistory(vehicleId),
        CRMApiProvider.getWebLeadCreatedDates(vehicleId, dateTime),
        CRMApiProvider.getInterestedLeadsAmount(vehicleId)
      ])

      setPriceHistoryProps({
        ...priceHistoryProps,
        isLoading: false,
        data: items,
        leadsAmountForRange: countLeadsAmountForRange(dateTime, leadsDates),
        graphData: convertToPricesByDate(items, leadsDates, dateTime, lastDateTime),
        totalLeads,
        lastDateTime,
        priceHistoryTotalLeads: calcTotalAmountOfLeads(items)
      })
    } catch {
      // silent error handler
    }
  }

  const fetchLeadsTab = ({
    dateTime,
    soldDate,
    deletedDate
  }: { dateTime: string, soldDate: string | null, deletedDate: string | null }): void => {
    void getPriceHistoryData(dateTime, soldDate ?? deletedDate)
    void loadRows()
  }

  const onBottomReached = useCallback(async () => {
    if (!isLoading) {
      await loadRows({ forceRefresh: false })
    }
  }, [isLoading, loadRows, rows.length])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  const refGetTotalLeads = useRefUpdater(getTotalLeads)
  useAsyncEffect(async () => {
    await refGetTotalLeads.current()
  }, [])

  // ========================================== //

  return {
    leadsTableProps: {
      rows,
      sorting,
      isLoading,
      onBottomReached,
      onSortingChange,
      emptyTableMessage
    },
    fetchLeadsTab,
    priceHistoryProps
  }
}

export default useLeadsTab
