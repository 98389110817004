import React from 'react'

import Alert from '@material-ui/lab/Alert/Alert'

import { useStyles } from './styles'

interface SnackbarDefaultContentRendererProps {
  key: string
  message: string
  variant: Alert.Type
  onClose?: () => void
}

const SnackbarDefaultContentRenderer = React.forwardRef(({
  key,
  variant,
  message,
  onClose
}: SnackbarDefaultContentRendererProps, ref) => {
  const styles = useStyles()

  return (
    <Alert
      ref={ref}
      key={key}
      severity={variant}
      classes={{
        root: styles.root,
        standardError: styles.standardError,
        standardSuccess: styles.standardSuccess
      }}
      onClose={onClose}
    >
      {message}
    </Alert>
  )
})

export const defaultContentRenderer = (variant: Alert.Type, onClose?: () => void) =>
  (key: string, message: string): JSX.Element =>
    <SnackbarDefaultContentRenderer
      key={key}
      message={message}
      variant={variant}
      onClose={onClose}
    />
