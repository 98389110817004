import type { FC } from 'react'

import TextSkeleton from 'components/common/Skeletons/TextSkeleton'

import CLASS_NAME from './styles'

const LoaderSkeleton: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <div className='history-header-skeleton'>
        <TextSkeleton />
        <TextSkeleton />
      </div>

      <div className='table-container-skeleton'>
        <div className='row-skeleton'>
          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>

          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>

          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>

          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>
        </div>

        <div className='row-skeleton'>
          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>

          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>

          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>

          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>
        </div>
        <div className='row-skeleton'>
          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>

          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>

          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>

          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>
        </div>
        <div className='row-skeleton'>
          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>

          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>

          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>

          <div className='cell-skeleton'>
            <TextSkeleton />
          </div>
        </div>
      </div>

      <p className='helper-text-skeleton'>
        * Time is measured as total elapsed time which includes days, night and weekends.
      </p>
    </div>
  )
}

export default LoaderSkeleton
