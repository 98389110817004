/**
 * This hook is initially generated automatically according to the architectural approach of CarFluent:
 * - https://dev.azure.com/carfluent/CarFluent/_wiki/wikis/CarFluent.wiki/40/Page-level-Architecture
 * - https://dev.azure.com/carfluent/CarFluent/_wiki/wikis/CarFluent.wiki/74/Component-Structure
 *
 * Please read the documents mentioned above before changing the hook structure.
 */

import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  type OnActionResultHandler,
  useForm,
  getEnhancedResultHandler,
  useModal
} from '@carfluent/common'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'

import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useAsyncEffect from 'hooks/useAsyncEffect'
import {
  CustomBlockClasses,
  CustomBlockTypes,
  getSelectionKeys,
  parseDescription,
  parseHtmlToEditorState
} from 'utils/wysiwyg'
import CRMApiProvider from 'api/crm.api'
import {
  type EmailAttachment,
  type Vehicle,
  type VehicleImage,
  VehicleMediaTypeId
} from 'api/types'
import type { AttachmentsInfo } from 'pages/crm/LeadDetailsView/hook/types'
import VehiclesApiProvider from 'api/vehicles.api'
import { addCarBlockToState } from 'components/wysiwyg/toolbar/AddCarDropdown/utils'
import { addVideoPosterAndLinkToState } from 'components/wysiwyg/utils'
import { getVideoLinkToWebsite } from 'utils/website'
import getVehicleVideoUrl from 'utils/getVehicleVideoUrl'

import type { ErrTouchShortcuts, SendEmailFormData, UseSendEmailProps, UseSendEmailReturn } from './types'
import { FieldIds, Messages, getDefaultFormData } from './constants'
import { createFormValidation, createFormValidationWithoutSubject } from './validation'
import serializeFormData from './serializer'
import carToVehicle, { extractVideoData } from './utils'

const DEFAULT_FORM_DATA = getDefaultFormData()

const useSendEmail = ({
  isOpen,
  leadId,
  leadEmail = null,
  carOfInterest,
  linkToWebsite,
  subject,
  requiredSubject = true,
  replyToEmailId,
  onClose: _onClose,
  primaryCarOfInterestId
}: UseSendEmailProps): UseSendEmailReturn => {
  const {
    isModalOpen: isAttachPhotosModalOpen,
    onOpenModal: onOpenAttachPhotosModal,
    onCloseModal: onCloseAttachPhotosModal
  } = useModal()

  const [vehiclePhotoAttachments, setVehiclePhotoAttachments] = useState<VehicleImage[]>([])
  const [vehicleVideoAttachments, setVehicleVideoAttachments] = useState<VehicleImage[]>([])

  const [attachmentsInfo, setAttachmentsInfo] = useState<AttachmentsInfo>({
    attachments: [],
    totalSize: 0
  })

  const [body, setBody] = useState<EditorState | null>(parseDescription(null))

  const { showAlert } = useCustomSnackbar()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onDeletePhoto = useCallback((id: string | number) => {
    setVehiclePhotoAttachments(prev => prev.filter(v => v.id !== id))
  }, [])

  const onSubmitAttachPhotos = useCallback((selectedFiles: VehicleImage[], vehicleId?: number | null) => {
    const photos = selectedFiles.filter(file => file.vehicleMediaTypeId === VehicleMediaTypeId.Image)
    const videos = selectedFiles.filter(file => file.vehicleMediaTypeId === VehicleMediaTypeId.Video)

    setVehicleVideoAttachments(videos.map(video => ({
      ...video,
      id: `${video?.id ?? ''}`,
      vehicleId: vehicleId ?? null
    })))

    setVehiclePhotoAttachments(prevPhotos => [
      ...prevPhotos,
      ...photos.map(photo => ({ ...photo, id: `${photo.fileName}${photo.order}` }))
    ])
  }, [])

  const sendEmail = useCallback(async (values: SendEmailFormData): Promise<void> => {
    try {
      const serializedData = serializeFormData(values, body, replyToEmailId)
      await CRMApiProvider.sendEmails(leadId, serializedData)
    } catch (err) {
      console.error(err) // try-catch is needed for this line only
      throw err
    }
  }, [body, leadId, replyToEmailId])

  const onActionResult = useMemo(() => {
    const handler: OnActionResultHandler<SendEmailFormData, ErrTouchShortcuts> = (_res, resetForm) => {
      _onClose()
      resetForm(getDefaultFormData())
      setVehiclePhotoAttachments([])
      setVehicleVideoAttachments([])
    }

    return getEnhancedResultHandler(handler, showAlert, Messages.ErrorMessage, Messages.SuccessMessage)
  }, [showAlert, _onClose, setVehiclePhotoAttachments, setVehicleVideoAttachments])

  const form = useForm<SendEmailFormData, ErrTouchShortcuts>({
    baseValues: DEFAULT_FORM_DATA,
    validationRules: requiredSubject ? createFormValidation : createFormValidationWithoutSubject,
    submitAction: sendEmail,
    onActionResult
  })

  const {
    isSubmitting,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onSubmit,
    setFieldValue,
    resetForm
  } = form

  /**
   * Some clean-up on close.
   */
  const onClose = useCallback(() => {
    _onClose()
    resetForm(getDefaultFormData())
    setVehiclePhotoAttachments([])
    setVehicleVideoAttachments([])
  }, [_onClose, resetForm, setVehiclePhotoAttachments, setVehicleVideoAttachments])

  const setAttachmentsField = useCallback((value: EmailAttachment[]) => {
    setFieldValue(FieldIds.EmailAttachments, value)
  }, [setFieldValue])

  const onRemoveBlock = useCallback((removedKey: string) => {
    setBody((prevBody) => {
      if (prevBody == null) {
        return prevBody
      }

      const currentContent = prevBody.getCurrentContent()
      const blocks = convertToRaw(currentContent).blocks.filter(block => block.key !== removedKey)

      if (blocks.length === 0) {
        return EditorState.createEmpty()
      }

      const contentState = convertFromRaw({ blocks, entityMap: currentContent.getEntityMap() })

      return EditorState.push(prevBody, contentState, 'change-block-data')
    })
  }, [])

  const addVideoLinkToEditor = (): void => {
    let parsedBody = body ?? parseHtmlToEditorState(null)

    vehicleVideoAttachments.forEach((file: VehicleImage) => {
      parsedBody = addVideoPosterAndLinkToState({
        state: parsedBody,
        urls: {
          posterUrl: getVehicleVideoUrl('thumbnail', file?.fileId),
          videoUrl: getVideoLinkToWebsite(linkToWebsite, file?.vehicleId?.toString() ?? '', file?.id ?? '')
        },
        selectionKey: undefined
      })
    })

    parsedBody = EditorState.moveSelectionToEnd(parsedBody)
    setBody(parsedBody)
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useAsyncEffect(async () => {
    if (leadEmail != null && isOpen) {
      onChange(FieldIds.To, leadEmail)
    }

    if (subject != null && isOpen) {
      onChange(FieldIds.Subject, subject)
    }

    if (values.template?.id === 0) {
      if (subject == null) onChange(FieldIds.Subject, '')
      setBody(parseHtmlToEditorState(null))
    } else {
      const response = await CRMApiProvider.getEmailTemplates(leadId, values.template?.id ?? 0)
      let parsedBody = parseHtmlToEditorState(response.body)
      const isVideoLinkExist = response.body?.includes(CustomBlockClasses.InsertVideo) ?? false
      const selectionKeys = getSelectionKeys(parsedBody)
      const isSuggested = response.body?.includes(CustomBlockTypes.SuggestedCars) ?? false
      const isCarOfInterestExist = carOfInterest != null && selectionKeys.carOfInterest != null
      const isSuggestedCarsExist = isSuggested &&
        primaryCarOfInterestId !== undefined &&
        selectionKeys.suggestedCars != null

      if (isCarOfInterestExist) {
        parsedBody = addCarBlockToState(parsedBody, { car: carToVehicle(carOfInterest), linkToWebsite }, selectionKeys.carOfInterest as string)
        parsedBody = EditorState.moveSelectionToEnd(parsedBody)
      }

      if (isSuggestedCarsExist) {
        const { items } = await VehiclesApiProvider.getSuggestedVehicles({
          skip: 0,
          take: 4,
          vehicleId: primaryCarOfInterestId
        })

        items.forEach(({ description: _, ...car }: Vehicle, i: number) => {
          const selectionKey = i === 0 ? selectionKeys.suggestedCars as string : undefined
          parsedBody = addCarBlockToState(parsedBody, { car, linkToWebsite }, selectionKey)
        })

        parsedBody = EditorState.moveSelectionToEnd(parsedBody)
      }

      if (isVideoLinkExist) {
        const videoData = extractVideoData(response.body as string)

        if (videoData != null) {
          parsedBody = addVideoPosterAndLinkToState({
            urls: videoData,
            state: parsedBody,
            selectionKey: selectionKeys.attachedVideo as string
          })

          parsedBody = EditorState.moveSelectionToEnd(parsedBody)
        }
      }

      setBody(parsedBody)
      if (subject == null) onChange(FieldIds.Subject, response.subject)
    }
  }, [
    leadEmail,
    subject,
    isOpen,
    values.template,
    primaryCarOfInterestId
  ])

  useEffect(() => {
    if (vehicleVideoAttachments.length > 0 && isOpen) {
      addVideoLinkToEditor()
    }
  }, [vehicleVideoAttachments, isOpen])

  // ========================================== //

  return {
    isOpen,
    body,
    isSubmitting,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onSubmit,
    onBodyChange: setBody,
    onClose,
    setAttachmentsField,
    onRemoveBlock,
    attachInventoryPhotosProps: {
      onOpenAttachPhotosModal,
      attachInventoryPhotosModalProps: {
        isOpen: isAttachPhotosModalOpen,
        onClose: onCloseAttachPhotosModal,
        onSubmit: onSubmitAttachPhotos,
        attachmentsInfo
      },
      photoAttachmentsProps: {
        vehiclePhotoAttachments,
        vehicleVideoAttachments,
        setVehiclePhotoAttachments,
        setVehicleVideoAttachments,
        setAttachmentsInfo,
        onDeletePhoto
      }
    }
  }
}

export default useSendEmail
