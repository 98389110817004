import { css } from '@emotion/css'

export const CLASS_NAME = css`
  .cf-modal-title {
    max-width: 1600px!important;
    padding: 40px 24px 0!important;

    @media screen and (min-width: 1648px) {
      padding: 40px 0 0!important;
    }
  }
  .cf-modal-content {
    display: grid;
    row-gap: 24px;
    max-width: 1600px!important;
    padding: 0 24px 120px!important;
    
    @media screen and (min-width: 1648px) {
      padding: 0 0 120px!important;
    }
  }
  .cf-modal-footer {
    padding: 12px 40px!important;
    grid-gap: 24px!important;
    
    button {
      margin: 0;
    }
  }

  .new-deal-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 16px;
    
    .double-col {
      grid-column: span 2;
      
      &.customer-input {
        .cf-end-adornment {
          display: none;
        }
      }
    }
    
    .resized-double-col {
      grid-column: span 1;
      
      @media screen and (min-width: 1400px) {
        grid-column: span 2;
      }
    }

    @media screen and (min-width: 1400px) {
      grid-template-columns: repeat(6, 1fr);
    }
  }
  
  .new-deal-car-info-block {
    display: grid;
    row-gap: 16px;
  }

  .car-info-banner {
    display: flex;
    align-items: center;
    height: 56px;
    border-radius: 12px;
    background: #F7FAFF;
    padding: 16px;

    div {
      color: #101010;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-right: 24px;

      span {
        color: rgba(33, 33, 33, 0.50);
      }
    }
  }
  
  .address-fields-component {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: none;
    column-gap: 16px;
    padding: 0;
    
    > div {
      grid-area: auto;
    }

    .address-field {
      grid-column: span 2;
    }

    @media screen and (min-width: 1400px) {
      grid-template-columns: repeat(6, 1fr);
    }
  }
  
  .new-deal-title {
    color: #101010;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    
    &.customer-title {
      margin-bottom: -8px;
    }
  }
`

export const DROPDOWN_POPOVER_CLASS_NAME = css`
  &.cf-popover-content {
    position: relative;
    margin-top: 0!important;
    min-width: 420px;
    box-shadow: rgba(58, 71, 78, 0.1) 0px 24px 38px 0px;

    .cf-dropdown-listbox {
      max-height: 328px;
      padding: 0;
      border-radius: 8px;
    }

    .cf-dropdown-option { 
      padding: 6px 16px;
      :has(.group-name) { opacity: 1; }
    }

    .no-options {
      cursor: pointer;
      padding: 14px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      width: 100%;
      color: rgba(33, 33, 33, 0.5);

      :hover { background-color: #F3F3F3; }

      span {
        font-weight: 500;
        color: #101010;
        display: flex;
        align-items: center;
  
        svg { margin-right: 8px; }
      }
    }

    .cf-no-options-container { color: rgba(0, 0, 0, 0.54); }

    .option-render {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &.group-name {
        font-weight: 500;
        margin-top: 8px;
        margin-bottom: 4px;
        color: #101010;
      }
    }

    .vehicle-state {
      display: flex;
      align-items: center;

      :before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
        background-color: rgba(0, 0, 0, 0.3);
      }

      &.active:before { background-color: #219653; }
      &.sold:before { background-color: #FFA451; }
    }

    .vehicle-name {
      display: flex;
      align-items: center;
      font-weight: 400;
      white-space: nowrap;
      margin-top: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-stock {
        font-weight: 500;
        margin-right: 8px;
      }
    }

    .option-sold, .option-deleted {
      .vehicle-name { color: rgba(33, 33, 33, 0.4); }
    }
  }
`

export const CUSTOMER_DROPDOWN_POPOVER_CLASS_NAME = css`
  &.cf-popover-content {
    position: relative;
    margin-top: 0!important;
    min-width: 420px;
    box-shadow: rgba(58, 71, 78, 0.1) 0px 24px 38px 0px;

    .cf-dropdown-listbox {
      max-height: 328px;
      padding: 0;
      border-radius: 8px;
    }

    .cf-dropdown-option { 
      padding: 6px 16px;
      :has(.group-name) { opacity: 1; }
    }

    .no-options {
      cursor: pointer;
      padding: 14px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      width: 100%;
      color: rgba(33, 33, 33, 0.5);

      :hover { background-color: #F3F3F3; }

      span {
        font-weight: 500;
        color: #101010;
        display: flex;
        align-items: center;
  
        svg { margin-right: 8px; }
      }
    }

    .cf-no-options-container { color: rgba(0, 0, 0, 0.54); }

    .option-render {
      .data-line {
        display: flex;
        width: 100%;
        margin-bottom: 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        :first-child span:first-child { color: #101010; }
        :last-child { margin-bottom: 0; }
  
        .option-data-container {
          display: flex;
          line-height: 20px;
          color: rgba(33, 33, 33, 0.4);
          text-wrap: nowrap;
        }
    
        .option-data-divider {
          height: 20px;
          margin: 0 8px;
          border-left: 1px solid #0000001F;
        }
    
        .line-divider { flex-grow: 1; }
      }
    }
  }
`
