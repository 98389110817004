import { useCallback, useState, useContext, useMemo, useEffect } from 'react'
import { type DeepRequired, useForm, isOk, isFail, serializers as S, useModal } from '@carfluent/common'

import AccountingApiProvider from 'api/accounting.api'
import { type AccountingFiscalYearsResponse } from 'api/types'
import { type ReconciliationFormData } from 'types/accounting'
import { KeyVal } from 'types'
import useAsyncEffect from 'hooks/useAsyncEffect'
import parseError from 'utils/parseErrors'
import SettingsCTX from 'store/settings'

import validationRules from './validation'
import type { UseEditFormProps, UseEditFormReturn } from './types'
import parseEditForm from './parser'

const DEFAULT_FORM_DATA: ReconciliationFormData = {
  beginningBalance: null,
  endingBalance: null,
  endDate: null
}

const useEditForm = ({
  sessionId,
  prevReconciliationSession,
  isModalOpen,
  onCloseMainModal,
  onSubmit: _onSubmit
}: UseEditFormProps): UseEditFormReturn => {
  const confirmEditDialogProps = useModal()

  const { accounting } = useContext(SettingsCTX)
  const [lockedInfo, setLockedInfo] = useState<AccountingFiscalYearsResponse | null>(null)
  const [apiErrors, setApiErrors] = useState<KeyVal | null>(null)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const submitAction = useCallback(async (values: ReconciliationFormData) => {
    const payload = {
      endDate: S.serializeDate(values.endDate),
      endBalance: values.endingBalance
    }

    await AccountingApiProvider.updateReconciliationSession(payload, Number(sessionId))
  }, [sessionId])

  const onActionResult = useCallback((res, resetForm) => {
    if (isOk(res)) {
      resetForm(DEFAULT_FORM_DATA)
      _onSubmit?.()
      onCloseMainModal()
    }

    if (isFail(res)) {
      setApiErrors(parseError(res.result))
      confirmEditDialogProps.onCloseModal()
    }
  }, [onCloseMainModal, _onSubmit])

  const form = useForm<DeepRequired<ReconciliationFormData>>({
    baseValues: DEFAULT_FORM_DATA,
    validationRules,
    submitAction,
    onActionResult
  })

  const { resetForm, onSubmit, setFieldError, setValues } = form

  const onCancel = useCallback(() => {
    resetForm(DEFAULT_FORM_DATA)
  }, [resetForm])

  const onUpdateReconciliationSession = useCallback(() => {
    void onSubmit?.()
    confirmEditDialogProps.onCloseModal()
  }, [onSubmit])

  const endDate = prevReconciliationSession != null ? new Date(prevReconciliationSession?.endDate ?? '') : null
  const minDate = new Date(endDate ?? '')
  endDate != null && minDate.setDate(endDate.getDate() + 1)

  const lockedMonth = useMemo((): Record<number, string> | null => {
    const findLockedMonth = lockedInfo?.fiscalYearMonths.filter((item) => item.isLocked)
    return findLockedMonth != null
      ? findLockedMonth?.reduce((acc, item) => ({
        ...acc,
        [new Date(`${item.month} 1`).getMonth()]: item.month
      }), {})
      : null
  }, [lockedInfo])

  const shouldDisableMonth = useCallback((monthId: number): boolean => {
    return lockedMonth?.[monthId] != null
  }, [lockedMonth])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useAsyncEffect(async () => {
    const today = new Date()
    const lockedInfoResponse = await AccountingApiProvider.getLockedYearOrMonthByDate(today.toISOString())
    setLockedInfo(lockedInfoResponse)
    if (sessionId != null && isModalOpen) {
      const parsedResult = parseEditForm(await AccountingApiProvider.getReconciliationSessionInfo(sessionId))

      setValues({ ...parsedResult })
    }
  }, [sessionId, isModalOpen])

  useEffect(() => {
    if (apiErrors != null) {
      setFieldError('endDate', apiErrors.endDate)
      setApiErrors(null)
    }
  }, [apiErrors, setFieldError])

  // ========================================== //
  //

  return {
    ...form,
    minDateFromAccounting: accounting.accountingStartDate,
    lockedInfo,
    endDate,
    lockedMonth,
    minDate,
    onCancel,
    confirmEditDialogProps,
    onUpdateReconciliationSession,
    shouldDisableMonth
  }
}

export default useEditForm
