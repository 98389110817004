import type { FC } from 'react'
import type { IconProps } from 'types'

const CheckFilledIcon: FC<IconProps> = ({ width = 24, height = 24, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} fill={color}/>
      <g id="New F&#38;I">
        <path d="M-5647 -1568H12783V-1570H-5647V-1568ZM12784 -1567V9515H12786V-1567H12784ZM12783 9516H-5647V9518H12783V9516ZM-5648 9515V-1567H-5650V9515H-5648ZM-5647 9516C-5647.55 9516 -5648 9515.55 -5648 9515H-5650C-5650 9516.66 -5648.66 9518 -5647 9518V9516ZM12784 9515C12784 9515.55 12783.6 9516 12783 9516V9518C12784.7 9518 12786 9516.66 12786 9515H12784ZM12783 -1568C12783.6 -1568 12784 -1567.55 12784 -1567H12786C12786 -1568.66 12784.7 -1570 12783 -1570V-1568ZM-5647 -1570C-5648.66 -1570 -5650 -1568.66 -5650 -1567H-5648C-5648 -1567.55 -5647.55 -1568 -5647 -1568V-1570Z" fill="black" fillOpacity="0.1"/>
        <g id="Admin/F&#38;I/Custom selected - editing" clipPath="url(#clip0_461_30)">
          <rect width="1280" height="1455" transform="translate(-1051 -1076)" fill="white"/>
          <g id="Frame 427321658">
            <g id="Frame 427321707">
              <g id="Template">
                <g id="Frame 668">
                  <g id="template - list">
                    <rect x="-15.5" y="-15.5" width="220" height="266" rx="11.5" stroke="#C99B86"/>
                    <g id="Frame 427321659">
                      <g id="sel">
                        <circle id="Ellipse 35" cx="12" cy="12" r="12" fill="#C99B86"/>
                        <path id=" &#226;&#134;&#179;Color" fillRule="evenodd" clipRule="evenodd" d="M9.81818 15.2127L6.95455 12.1567L6 13.1754L9.81818 17.25L18 8.51866L17.0455 7.5L9.81818 15.2127Z" fill="white"/>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_461_30">
          <rect width="1280" height="1455" fill="white" transform="translate(-1051 -1076)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default CheckFilledIcon
