import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import TooltipedText from 'components/common/TooltipedText'
import IconSVG from 'components/inlineImages'

import { FValues } from '../StepCampaignContent/hook/types'

const EmailRowPreview: FC<FValues> = (props) => {
  const { senderName, emailSubject, preHeader } = props
  const isSenderNameNotEmpty = (senderName != null && senderName !== '')
  const isEmailSubjectNotEmpty = (emailSubject != null && emailSubject !== '')
  const isPreHeaderNotEmpty = (preHeader != null && preHeader !== '')

  return (
    <div className={cnx('skeleton-item', 'active-item')}>
      <IconSVG.Message />
      <TooltipedText
        className={cnx(isSenderNameNotEmpty && 'active')}
        value={isSenderNameNotEmpty ? senderName : 'Sender name'}
      />

      <TooltipedText
        className={cnx(isEmailSubjectNotEmpty && 'active')}
        value={isEmailSubjectNotEmpty ? emailSubject : 'Email subject'}
      />

      <TooltipedText
        className={cnx(isPreHeaderNotEmpty && 'active', 'pre-header')}
        value={isPreHeaderNotEmpty ? preHeader : 'Preheader'}
      />
    </div>
  )
}

export default EmailRowPreview
