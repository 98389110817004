import { type FC } from 'react'

import ActionsMenu from 'components/common/ActionsMenu'
import useHeaderSection from './hook'
import CLASS_NAME from './styles'

interface HeaderSectionProps {
  accountId: number | null
}

const HeaderSection: FC<HeaderSectionProps> = ({ accountId }) => {
  const { topPanelActions } = useHeaderSection({ accountId })

  return (
    <div className={CLASS_NAME}>
      <div className='left-panel'>
        <h5>Bank statement info</h5>
      </div>

      <div className='right-panel'>
        <ActionsMenu
          actions={topPanelActions}
          className='actions-menu'
          buttonTitle='ACTIONS'
        />
      </div>
    </div>
  )
}

export default HeaderSection
