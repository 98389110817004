import type { TableColumnsWidths } from '@carfluent/common/dist/utils/ui' // AZ-TODO: add export for type
import { tcw } from '@carfluent/common'

import isSpecialTransaction from 'utils/accounting/isSpecialTransaction'
import _isPayable from 'utils/accounting/isPayable'
import { TransactionTypeId } from 'api/types'

export const JOURNAL_ENTRIES_COLUMN_WIDTHS = new Map<string, TableColumnsWidths>([
  ['account', tcw(null, 220)],
  ['control', tcw(null, 180)],
  ['notes', tcw(null, 220)],
  ['entity', tcw(null, 175)],
  ['debits', tcw(135)],
  ['credits', tcw(135)],
  ['remover', tcw(56)]
])

export const PAYABLE_COLUMN_WIDTHS = new Map<string, TableColumnsWidths>([
  ['account', tcw(null, 200)],
  ['control', tcw(null, 180)],
  ['notes', tcw(null, 220)],
  ['entity', tcw(null, 175)],
  ['debits', tcw(135)],
  ['credits', tcw(135)],
  ['remover', tcw(56)]
])

export const RECEIVABLES_COLUMN_WIDTHS = new Map<string, TableColumnsWidths>([
  ['account', tcw(null, 325, 340)],
  ['control', tcw(null, 160, 160)],
  ['notes', tcw(null, 230, 240)],
  ['entity', tcw(null, 260, 280)],
  ['debits', tcw(140)],
  ['credits', tcw(140)],
  ['remover', tcw(56)]
])

export const TRANSACTION_TYPE_IDS_WITH_DEFAULT_VALUE = new Set<number | null>([
  TransactionTypeId.Receive,
  TransactionTypeId.Check,
  TransactionTypeId.PayBill
])

export const getColumnWidths = (transactionTypeId?: number | null): Map<string, TableColumnsWidths> => {
  const isPayable = _isPayable(transactionTypeId)
  return isSpecialTransaction(transactionTypeId)
    ? isPayable ? PAYABLE_COLUMN_WIDTHS : RECEIVABLES_COLUMN_WIDTHS
    : JOURNAL_ENTRIES_COLUMN_WIDTHS
}

export enum SpecialId {
  AddVendor = '___ADD_VENDOR___',
  AddCustomer = '___ADD_CUSTOMER___'
}
