import type { FC } from 'react'

import { TaskMode, TaskType } from 'pages/crm/LeadDetailsView/hook/types'
import appointmentLeadIcon from 'assets/lead-appointment.svg'
import followUpLeadIcon from 'assets/lead-follow-up.svg'
import reminderLeadIcon from 'assets/lead-reminder.svg'
import personIcon from 'assets/person_icon.svg'
import emailReceivedIcon from 'assets/email_icon.svg'
import messageReceived from 'assets/message_icon.svg'
import creditAppReceived from 'assets/credit_app_received_icon.svg'

import CLASS_NAME from './styles'

export type LeadActivityType = 'reminder' | 'appointment' | 'follow-up'

export interface TaskTitleProps {
  taskTypeId: TaskType
  taskMode: TaskMode | null
  titleText?: string | null
}

interface Map {
  [key: number]: string
}

const iconMap: Map = {
  1: followUpLeadIcon,
  2: appointmentLeadIcon,
  3: reminderLeadIcon,
  4: personIcon,
  5: messageReceived,
  6: emailReceivedIcon,
  7: creditAppReceived
}

const TaskTitle: FC<TaskTitleProps> = ({
  taskTypeId,
  taskMode,
  titleText
}) => {
  if (taskMode === null) {
    return <></>
  }

  const text = (titleText != null && titleText !== '')
    ? titleText
    : TaskType[taskTypeId]
  const showMode = taskMode != null &&
    (taskMode === TaskMode.CompleteTask ||
      taskMode === TaskMode.EditTask ||
      taskMode === TaskMode.NewTask)

  return (
    <div className={CLASS_NAME}>
      <img src={iconMap[taskTypeId]} alt='' />
      <label>{showMode ? `${text} - ${taskMode}` : text} </label>
    </div>
  )
}

export default TaskTitle
