import { TransactionTypeId } from 'api/types'
import { TransactionActionNames } from 'constants/constants'

export enum PrintTitle {
  Print = 'PRINT CHECK',
  RePrint = 'RE-PRINT CHECK'
}

export const SYSTEM_CREATED = 'System journal entry'

export const RECURRING_PAYABLE_TITLE = 'Recurring payable'
export const RECURRING_JOURNAL_ENTRY_TITLE = 'Recurring journal entry'

export const CONTENT_DEFAULT = 'Please note, this step could not be undone'
export const CONTENT_RELATED_DEAL = 'Note: Deal will be in Pushed to accounting status again. You can generate new entry from deal page.'
export const CONTENT_RECURRING_TRANSACTION = 'This recurring entry will be stopped and deleted.'

export const RECONCILED_TRANSACTION_EDIT_DELETE = 'Reconciled transaction cannot be deleted or updated.'

export interface ModalTitleConfig {
  transactionTypeId?: number | null
  isGeneratedTransaction: boolean
  isRecurring: boolean
  isSystemCreated: boolean
}

export const getModalTitle = ({
  transactionTypeId,
  isGeneratedTransaction,
  isRecurring,
  isSystemCreated
}: ModalTitleConfig): string => {
  switch (transactionTypeId) {
    case TransactionTypeId.JournalEntry: {
      if (isSystemCreated) {
        return SYSTEM_CREATED
      }

      if (isRecurring) {
        return RECURRING_JOURNAL_ENTRY_TITLE
      }

      return TransactionActionNames.JournalEntry
    }

    case TransactionTypeId.Payable: {
      return isRecurring ? RECURRING_PAYABLE_TITLE : TransactionActionNames.Payable
    }

    case TransactionTypeId.Receivable: {
      return TransactionActionNames.Receivable
    }

    case TransactionTypeId.Receive: {
      return TransactionActionNames.Receive
    }

    case TransactionTypeId.PayBill: {
      return TransactionActionNames.PayBill
    }

    case TransactionTypeId.Check: {
      return TransactionActionNames.Check
    }

    default: {
      return isGeneratedTransaction ? SYSTEM_CREATED : TransactionActionNames.JournalEntry
    }
  }
}

export const getDeleteModalContent = (hasRelatedDeal: boolean, isRecurring: boolean): string => {
  if (hasRelatedDeal) {
    return CONTENT_RELATED_DEAL
  }

  if (isRecurring) {
    return CONTENT_RECURRING_TRANSACTION
  }

  return CONTENT_DEFAULT
}

export interface TooltipConfig {
  hasLineWithCompletedReconciliation: boolean
  isDepositedDownPaymentTransactionCreated: boolean
  isLocked?: boolean
  isReadonly: boolean
  isYearClosed: boolean
  lockMonth?: string | null
  lockYear?: string | number | null
}

export const getDeleteTooltipMessage = ({
  hasLineWithCompletedReconciliation = false,
  isDepositedDownPaymentTransactionCreated = false,
  isLocked = false,
  isReadonly = false,
  isYearClosed = false,
  lockMonth,
  lockYear
}: TooltipConfig): string | null => {
  if (hasLineWithCompletedReconciliation) {
    return RECONCILED_TRANSACTION_EDIT_DELETE
  }

  if (isLocked || !isReadonly) {
    return isYearClosed
      ? `Can’t delete entry - ${lockYear ?? ''} year is closed.`
      : `Can’t delete entry - ${lockMonth ?? ''} is locked.`
  }

  return isDepositedDownPaymentTransactionCreated
    ? 'Created by system automatically. This journal entry cannot be deleted.'
    : null
}

export const getSubmitTooltipMessage = ({
  hasLineWithCompletedReconciliation,
  isDepositedDownPaymentTransactionCreated,
  isLocked = false,
  isReadonly,
  isYearClosed,
  lockMonth,
  lockYear
}: TooltipConfig): string | null => {
  if (hasLineWithCompletedReconciliation) {
    return RECONCILED_TRANSACTION_EDIT_DELETE
  }

  if (isLocked || !isReadonly) {
    return isYearClosed
      ? `Can’t update entry - ${lockYear ?? ''} year is closed.`
      : `Can’t update entry - ${lockMonth ?? ''} is locked.`
  }

  return isDepositedDownPaymentTransactionCreated
    ? 'Created by system automatically. This journal entry cannot be updated.'
    : null
}
