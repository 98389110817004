import { type EmailSnippetDto } from 'api/types/responses'
import { SnippetTypeIds, SnippetTypeNames } from 'api/types'

interface GroupedSnippets {
  carDetails: EmailSnippetDto[]
  leadDetails: EmailSnippetDto[]
  dealershipDetails: EmailSnippetDto[]
}

let uniqId = 0
const getUniqId = (): number => --uniqId

interface GetEmptySnippetProps {
  name: string
  snippetTypeId: number
  isUsableInSubject?: boolean
  isUsableInBody?: boolean
}

const getEmptySnippet = ({
  name,
  snippetTypeId,
  isUsableInSubject = false,
  isUsableInBody = false
}: GetEmptySnippetProps): EmailSnippetDto => {
  return {
    order: 0,
    isUsableInSubject,
    isUsableInBody,
    id: getUniqId(),
    name,
    placeholder: '',
    snippetTypeId
  }
}

interface GroupSnippetsProps {
  filterFieldMark: 'isUsableInSubject' | 'isUsableInBody'
  snippets: EmailSnippetDto[]
}

const groupSnippets = ({
  filterFieldMark,
  snippets
}: GroupSnippetsProps): GroupedSnippets => {
  snippets.sort((a, b) => {
    return a.order - b.order
  })

  return snippets.reduce<GroupedSnippets>((res, item) => {
    if (!item[filterFieldMark]) {
      return res
    }

    switch (item.snippetTypeId) {
      case SnippetTypeIds.CarDetails:
        res.carDetails.push(item)
        break
      case SnippetTypeIds.LeadDetails:
        res.leadDetails.push(item)
        break
      case SnippetTypeIds.DealershipDetails:
        res.dealershipDetails.push(item)
    }

    return res
  }, {
    carDetails: [],
    leadDetails: [],
    dealershipDetails: []
  })
}

interface ConcatSnippetsProps {
  snippets: EmailSnippetDto[]
  snippetTypeId: SnippetTypeIds
  name: SnippetTypeNames
  isUsableInSubject?: boolean
  isUsableInBody?: boolean
}

const concatSnippets = ({
  snippets,
  snippetTypeId,
  name,
  ...other
}: ConcatSnippetsProps): EmailSnippetDto[] => {
  return snippets.length > 0
    ? [
        getEmptySnippet({
          snippetTypeId,
          name,
          ...other
        }),
        ...snippets
      ]
    : []
}

export const configurateSnippets = (
  snippets: EmailSnippetDto[],
  filterFieldMark: 'isUsableInSubject' | 'isUsableInBody'
): EmailSnippetDto[] => {
  const {
    carDetails,
    leadDetails,
    dealershipDetails
  } = groupSnippets({
    filterFieldMark,
    snippets
  })

  return [
    ...concatSnippets({
      snippets: carDetails,
      snippetTypeId: SnippetTypeIds.CarDetails,
      name: SnippetTypeNames.CarDetails,
      [filterFieldMark]: true
    }),
    ...concatSnippets({
      snippets: leadDetails,
      snippetTypeId: SnippetTypeIds.LeadDetails,
      name: SnippetTypeNames.LeadDetails,
      [filterFieldMark]: true
    }),
    ...concatSnippets({
      snippets: dealershipDetails,
      snippetTypeId: SnippetTypeIds.DealershipDetails,
      name: SnippetTypeNames.DealershipDetails,
      [filterFieldMark]: true
    })
  ]
}
