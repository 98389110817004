import { type FC, useCallback, useMemo, useContext } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { observer } from 'mobx-react-lite'

import ThreeDotsMenuIcon from 'assets/3-dots-menu.svg'

import AuthCTX from 'store/auth'
import { UserRoles } from 'api/types'
import ActionsMenu, { type ActionOptions, type MenuButtonProps } from 'components/common/ActionsMenu'

import CLASS_NAME from './styles'

interface EditDeletePopoverProps {
  className?: string
  taskId: number
  onDeleteTaskClick: (taskId: number) => void
  onEditTaskClick: (taskId: number) => void
}

const ThreeDotsMenuButton: FC<MenuButtonProps> = ({
  isDisabled, onClick, title
}) => (
  <IconButton disabled={isDisabled} onClick={onClick} title={title}>
    <img src={ThreeDotsMenuIcon} alt='Menu' />
  </IconButton>
)

const EditDeletePopover: FC<EditDeletePopoverProps> = ({
  taskId,
  onDeleteTaskClick,
  onEditTaskClick
}) => {
  const { userRoles } = useContext(AuthCTX)

  const onActionEditClick = useCallback(() =>
    onEditTaskClick(taskId),
  [onEditTaskClick, taskId])

  const onActionDeleteClick = useCallback(() =>
    onDeleteTaskClick(taskId),
  [onDeleteTaskClick, taskId])

  const actionList: ActionOptions[] = useMemo(() => ([
    {
      title: 'Edit task',
      handleOnClick: onActionEditClick
    },
    {
      title: 'Delete task',
      optionType: 'highlighted',
      handleOnClick: onActionDeleteClick,
      disabled: userRoles.includes(UserRoles.BDC)
    }
  ]), [onActionDeleteClick, onActionEditClick, userRoles])

  return (
    <div className={CLASS_NAME}>
      <ActionsMenu
        className='edit-delete-task'
        MenuButtonComponent={ThreeDotsMenuButton}
        actions={actionList}
      />
    </div>
  )
}

export default observer(EditDeletePopover)
