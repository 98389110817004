import { type FC, type ReactNode, useCallback, useMemo } from 'react'
import { FormDropdown, Button, getFirstError } from '@carfluent/common'

import type { DictionaryItem } from 'api/types'
import AttachInventoryPhotosModal from 'components/crm/AttachInventoryPhotosModal'
import EditorComponent from 'components/common/EditorComponent'
import EmailAttachments from 'components/common/EmailAttachments'
import decorators from 'components/wysiwyg/decorators'
import AddCarDropdown from 'components/wysiwyg/toolbar/AddCarDropdown'
import { FieldIds, EMPTY_EMAIL_OPTION } from 'hooks/useSendEmail/constants'
import useSendEmail from 'hooks/useSendEmail'
import type { PartialEmailThreadFormProps } from './types'

import { CLASS_NAME, EDITOR_STYLES } from './styles'

const renderTemplateOption = (option: DictionaryItem): string => option.name
const renderNoTemplateOption = (): ReactNode => (<div>No templates found</div>)

const EmailThreadForm: FC<PartialEmailThreadFormProps> = ({
  carsOfInterest,
  linkToWebsite,
  emailTemplates,
  suggestedCars,
  primaryCarOfInterestId,
  displayName,
  isResponseEmailActive,
  leadId,
  subject,
  replyToEmailId,
  onSubmitLeadUpdate,
  onCloseEmailThreadModal,
  onCloseResponseEmail
}) => {
  const {
    isSubmitting,
    body,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onSubmit,
    onBodyChange,
    onClose: onCloseSendEmail,
    setAttachmentsField,
    attachInventoryPhotosProps: {
      onOpenAttachPhotosModal,
      attachInventoryPhotosModalProps,
      photoAttachmentsProps
    }
  } = useSendEmail({
    isOpen: isResponseEmailActive,
    onClose: onCloseResponseEmail,
    leadId,
    linkToWebsite,
    subject,
    replyToEmailId,
    carOfInterest: carsOfInterest.find((car) => car.isPrimary),
    primaryCarOfInterestId,
    requiredSubject: false
  })

  const customButtons = useMemo(() => ([
    <AddCarDropdown
      carsOfInterest={carsOfInterest}
      label='ADD CAR'
      key='addCar'
      linkToWebsite={linkToWebsite}
      suggestedCars={suggestedCars}
    />
  ]), [carsOfInterest, linkToWebsite, suggestedCars])

  const onSubmitEmailThreadForm = useCallback(async () => {
    await onSubmit()
    onCloseEmailThreadModal()
    await onSubmitLeadUpdate?.()
  }, [onCloseEmailThreadModal, onSubmit, onSubmitLeadUpdate])

  return (
    <div className={CLASS_NAME}>
      <div className='reply-person'>
        <span>Reply to {displayName}</span>
      </div>

      <FormDropdown<DictionaryItem>
        id={FieldIds.Template}
        label='Template'
        options={[EMPTY_EMAIL_OPTION, ...emailTemplates]}
        renderOption={renderTemplateOption}
        renderNoOptions={renderNoTemplateOption}
        onChange={onChange}
        onBlur={onBlur}
        mode='search'
        blurMode='select-first'
        disableClearable
        value={values[FieldIds.Template]}
        error={getFirstError(errors[FieldIds.Template])}
        touched={Boolean(touched[FieldIds.Template])}
      />

      <EditorComponent
        className={EDITOR_STYLES}
        customDecorators={decorators}
        description={body}
        toolbarCustomButtons={customButtons}
        onChange={onBodyChange}
      />

      <EmailAttachments
        className='email-thread-attachments'
        setAttachmentsField={setAttachmentsField}
        onOpenAttachPhotosModal={onOpenAttachPhotosModal}
        photoAttachmentsProps={photoAttachmentsProps}
      />

      <div className='form-actions'>
        <Button
          onClick={onCloseSendEmail}
          variant='outlined'
        >
          Cancel
        </Button>

        <Button
          onClick={onSubmitEmailThreadForm}
          isLoading={isSubmitting}
        >
          Reply
        </Button>
      </div>

      <AttachInventoryPhotosModal
        {...attachInventoryPhotosModalProps}
        carsOfInterest={carsOfInterest}
        isPhotosNumLimited={false}
      />
    </div>
  )
}

export default EmailThreadForm
