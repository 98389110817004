import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'

import TooltipedText from 'components/common/TooltipedText'
import { joinPartsBySpace } from 'utils/view_helper'
import CLASS_NAME from './styles'

export interface ClientData {
  firstName: string | null
  lastName: string | null
  address: string | null
  name?: string | null
}

export interface ClientCellProps {
  getValue: () => ClientData
}

const ClientCell: FC<ClientCellProps> = ({ getValue }) => {
  const { firstName, lastName, address, name } = getValue()

  return (
    <CellWrapper className={CLASS_NAME}>
      <TooltipedText
        className='cf-bold-text'
        value={name != null ? name : joinPartsBySpace(firstName ?? '-', lastName)}
      />

      <TooltipedText
        className='cf-grey-text'
        value={address ?? ''}
      />
    </CellWrapper>
  )
}

export default ClientCell
