import { type FC } from 'react'
import { type MenuIconProps } from 'types'

const CrmMarketingCampaigns: FC<MenuIconProps> = ({
  isActive = false
}) => {
  const pictogramFill = isActive ? '#fff' : '#101010'

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.8357 16.9072C20.2787 12.6472 20.2787 8.35423 19.8357 4.09423C19.6977 2.76223 18.1607 2.08723 17.0857 2.88623L12.9827 5.93623C11.6891 6.89836 10.1199 7.41804 8.50769 7.41823H4.67969C4.41447 7.41823 4.16012 7.52359 3.97258 7.71113C3.78504 7.89866 3.67969 8.15302 3.67969 8.41823V12.5842C3.67969 12.8495 3.78504 13.1038 3.97258 13.2913C4.16012 13.4789 4.41447 13.5842 4.67969 13.5842H8.50969C10.1214 13.5844 11.6902 14.1037 12.9837 15.0652L17.0867 18.1152C18.1617 18.9152 19.6977 18.2402 19.8357 16.9072Z'
        fill={pictogramFill}
      />
      <path
        d='M9.0933 15.8032C9.12166 15.6924 9.12431 15.5766 9.10104 15.4646C9.07777 15.3526 9.0292 15.2475 8.95905 15.1571C8.88889 15.0668 8.79901 14.9937 8.69627 14.9435C8.59353 14.8932 8.48066 14.8671 8.3663 14.8672H6.3663C6.22711 14.8674 6.09072 14.9063 5.97239 14.9796C5.85406 15.0529 5.75847 15.1577 5.6963 15.2822L4.6963 17.2822C4.61393 17.447 4.59512 17.6364 4.64344 17.8142C4.69176 17.992 4.80384 18.1457 4.9583 18.2462L7.0923 19.6302C7.19215 19.695 7.30601 19.7352 7.42445 19.7473C7.54289 19.7594 7.66252 19.7431 7.77343 19.6998C7.88434 19.6565 7.98335 19.5875 8.06227 19.4983C8.1412 19.4092 8.19776 19.3025 8.2273 19.1872L9.0933 15.8032Z'
        fill={pictogramFill}
      />
    </svg>
  )
}

export default CrmMarketingCampaigns
