import { css } from '@emotion/css'
import { ScreenWidthBreakpoints, px } from 'constants/constants'

export default css(`
  border-radius: 2px 12px 12px 12px;
  background: #FFF;
  padding: 24px 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  
  .finances-left-side {
    flex-grow: 1;
    width: 100%;
    
    @media screen and (max-width: ${px(ScreenWidthBreakpoints.Small)}) {
      min-width: 680px;
    }
  }
  
  .finances-right-side {
    flex-grow: 1;
    min-width: 520px;
    max-width: 520px;
    
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    @media screen and (max-width: ${px(ScreenWidthBreakpoints.Small)}) {
      min-width: 420px;
    }
  }
`)
