import { css } from '@emotion/css'

export default css(`
  display: flex;
  align-items: center;

  .cf-checkbox-icon {
    padding: 8px;
  }

  .cf-left-custom-icon {
    margin-right: 10px;
    display: flex;

    img {
      width: 20px;
      height: 20px;
    }
  }
`)
