import { css } from '@emotion/css'

export default css(`
    --table-background-color: #F3F3F3;
    --table-row-border: 8px solid var(--table-background-color);
    --table-item-background-color: #FFFFFF;
    --table-cell-height: 56px;
    --table-padding: 16px;
    
    .cf-dropzone .cf-content-wrapper {
      background: white;
    }
    
    .cf-drop-zone-content {
      .cf-drag-capture {
        width: auto;
      }
    }
    
    .cf-dnd-overlay {
      background: white;
      opacity: 0.9;
      border: 2px dashed #C99B86;
    }
    
    .additional-info {
      font-size: 12px;
      color: rgba(33, 33, 33, 0.50);
    }
    
    .cf-files-table {
      margin-top: 24px;
      
      .cf-no-data-block {
        border: 8px solid #F3F3F3;
        border-top: none;
        color: rgba(33, 33, 33, 0.50);
        letter-spacing: 0.5px;
        padding: 16px;
      }

      .cf-table {
        overflow: hidden;
      }

      th * {
        color: #101010
      }

      tr {
        display: flex;
        align-items: center;
        height: var(--table-cell-height);
        box-sizing: content-box;
        border: var(--table-row-border);
        background-color: var(--table-item-background-color);
        border-radius: 12px;

        .cf-cell-wrapper {
          background-color: var(--table-item-background-color);
        }
      }

      thead th:last-child > div {
        justify-content: flex-end;
      }

      .MuiTableContainer-root {
        border-radius: 4px;
        
        table {
          display: flex;
          flex-direction: column;
          background-color: var(--table-background-color);

          th * {
            font-weight: 500;
          }

          th, td {
            border-bottom: 0px;
            height: 100%;

            div {
              height: 100%;
              border: 0;
            }
          }

          td:first-child > div, th:first-child > div {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          td:last-child > div, th:last-child > div {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
          
          th:last-child .cf-header-cell-title {
            width: 100%;

            p {
              text-align: left;
            }
          }

        }

        .MuiTableBody-root {
          tr {
            border-top: 0px;

            &:hover td div.cf-cell-wrapper {
              background-color: #FAFAFA;
              border: 0;
            }

            [class*=cf-cell-wrapper] {
              height: var(--table-cell-height);
            }

            td:last-child > div {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
`)
