import { InspectionStatus } from 'api/types'
import {
  type IntakeInspectionQuestionCategoriesDto,
  type VehicleIntakeInspectionDto
} from 'api/types/responses'

import type { SectionProps } from './types'
import { filterCategoriesWithoutQuestions } from './utils'
import type { NavigationItemProps } from '../components/NavigationBlock'

export const parseQuestionsSections = (
  questionCategories: IntakeInspectionQuestionCategoriesDto[],
  intakeInspection: VehicleIntakeInspectionDto
): SectionProps[] => {
  return filterCategoriesWithoutQuestions(questionCategories, intakeInspection).map(el => {
    return {
      id: el.id,
      title: el.name,
      questions: intakeInspection.questions[el.id]
    }
  })
}

export const parseNavigationSection = (
  questionCategories: IntakeInspectionQuestionCategoriesDto[],
  intakeInspection: VehicleIntakeInspectionDto
): NavigationItemProps[] => {
  return filterCategoriesWithoutQuestions(questionCategories, intakeInspection).map(el => {
    let resolved = 0
    const additionalParams = intakeInspection
      .questions[el.id].reduce((acc, item) => {
        if (item.questionStateId !== null) {
          resolved++
        }

        return {
          pass: acc.pass + (item.questionStateId === InspectionStatus.Pass ? 1 : 0),
          fail: acc.fail + (item.questionStateId === InspectionStatus.Fail ? 1 : 0),
          comment: acc.comment + (item.comments === null || item.comments === '' ? 0 : 1)
        }
      }, {
        pass: 0,
        fail: 0,
        comment: 0
      })

    return ({
      ...el,
      ...additionalParams,
      percentage: Math.round((resolved / intakeInspection.questions[el.id].length) * 100)
    })
  })
}
