import { css } from '@emotion/css'

export default css(`
  [class*=cf-banner-content] {
    a {
      text-decoration: underline;
    }

    .cf-banner-end-adornment {
      width: fit-content;
    }

    button {
      background: none;
      border: none;
      padding: 0;
      text-decoration-line: underline;
      text-transform: none;
      font-size: 16px;
      min-width: max-content;

      :hover {
        background: none;
      }
    }
  }

  .cf-button-root.cf-button-loading .cf-button-loader {
    background: transparent;
  }
  
  .cf-deal-view-top-section-wrapper {
    display: flex;
    margin-bottom: 16px;
    min-height: 100px;
    height: 100%;
  }

  .deal-view-notes-section-wrapper {
    width: 40%;
    max-height: 100px;
  
    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  
  .tab.tab-selected {
    .counter {
      color: #fff;
    }
  }
  
  .tab {
    .counter-loader {
      margin-left: 8px;
    }
    
    .counter {
      color: #C99B86;
    }
  }

  .tab-label {
    text-transform: initial;
  }

  .cf-disabled .MuiFormLabel-root,
  .Mui-disabled.MuiFormLabel-root {
    color: rgba(33, 33, 33, 0.4) !important;
  }
`)
