import { useCallback, useEffect } from 'react'
import debounce from 'lodash-es/debounce'

import useToggler from 'hooks/useToggler'

export interface UseTogglableButtonProps {
  /**
   * The props below are injected to the *element* by the Editor component.
   */
  modalHandler?: {
    closeModals: () => void
    deregisterCallBack: (fn: Function) => void
    registerCallBack: (fn: Function) => void
    onEditorClick: () => void
  }
}

export interface UseTogglableButtonReturn {
  isOpen?: boolean
  onToggle: () => void
}

const CLICK_DEBOUNCE = 100

function useTogglableButton ({ modalHandler }: UseTogglableButtonProps): UseTogglableButtonReturn {
  const dropdownToggler = useToggler(false)
  const toggleDropdown = useCallback(debounce(dropdownToggler.toggle, CLICK_DEBOUNCE), [])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Register callback to properly close dropdown when User clicks inside Editor.
   */
  useEffect(() => {
    const cb = (): void => {
      /**
       * Closes dropdown only when click is inside its popper.
       */
      if (dropdownToggler.getValue()) {
        toggleDropdown()
      }
    }

    modalHandler?.registerCallBack?.(cb)

    return () => {
      modalHandler?.deregisterCallBack?.(cb)
    }
  }, [
    modalHandler?.registerCallBack, modalHandler?.deregisterCallBack,
    toggleDropdown, dropdownToggler.getValue
  ])

  // ========================================== //

  return {
    isOpen: dropdownToggler.value,
    onToggle: toggleDropdown
  }
}

export default useTogglableButton
