import { css } from '@emotion/css'

export default css(`  
  &.cf-cell-wrapper{
    &.cell-amount {
      display: flex;
      align-items: center;
      width: 100%;
      
      &.aligned-right {
        justify-content: flex-end;
        text-align: right;
        
        p {
          text-align: right;
        }
      }
    }
  }
`)
