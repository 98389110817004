import { css } from '@emotion/css'

export const POPOVER_CLASS_NAME = css(`
  .cf-dropdown-option {
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .action {
    cursor: pointer;
    user-select: none;
    min-height: 56px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .action-name {
      font-family: Roboto;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .action-icon {
      height: 14px;
      width: 14px;
      margin: 0 8px 0 0;
    }
  }
`)

export const CLASS_NAME = css(`
  width: 100%;
  
  .cf-input-container {
    background-color: #FFF;
  }
`)
