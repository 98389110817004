import { FC, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { FormInput, FormDropdown } from '@carfluent/common'

import InputsRowLayout from 'components/inventory/InputsRowLayout'

import { useForm } from 'components/form'

import type { CarInfoDictionaries } from './types'

interface VehicleCarInfoFormControlsProps {
  dictionaries: CarInfoDictionaries
  isDisabled: boolean
}

const VehicleCarInfoFormControls: FC<VehicleCarInfoFormControlsProps> = ({
  dictionaries,
  isDisabled
}) => {
  const { setFieldValue, handleBlur, values, errors, touched } = useForm()
  const handleChange = useCallback((id, value): void => { void setFieldValue(id, value) }, [setFieldValue])

  return (
    <>
      <InputsRowLayout>
        <FormDropdown
          disableClearable
          id='body'
          label='Body'
          onBlur={handleBlur}
          onChange={handleChange}
          options={dictionaries.Body}
          disabled={isDisabled}
          value={values.body}
          error={errors.body as string}
          touched={touched.body as boolean}
        />

        <FormInput
          id='engine'
          label='Engine'
          value={values.engine}
          error={errors.engine as string}
          touched={touched.engine as boolean}
          onBlur={handleBlur}
          onChange={setFieldValue}
          maxLength={50}
          disabled={isDisabled}
        />

        <FormDropdown
          disableClearable
          id='doors'
          label='Doors'
          onChange={handleChange}
          options={dictionaries.Doors}
          disabled={isDisabled}
          value={values.doors}
          error={errors.doors as string}
          touched={touched.doors as boolean}
        />

        <FormDropdown
          disableClearable
          id='fuelType'
          label='Fuel'
          onChange={handleChange}
          options={dictionaries.Fuel}
          disabled={isDisabled}
          value={values.fuelType}
          error={errors.fuelType as string}
          touched={touched.fuelType as boolean}
        />

        <FormDropdown
          disableClearable
          id='cylinderCounts'
          label='Cylinders'
          onChange={handleChange}
          options={dictionaries.Cylinders}
          disabled={isDisabled}
          value={values.cylinderCounts}
          error={errors.cylinderCounts as string}
          touched={touched.cylinderCounts as boolean}
        />

        <FormDropdown
          disableClearable
          id='transmission'
          label='Transmission'
          onChange={handleChange}
          options={dictionaries.Transmission}
          disabled={isDisabled}
          value={values.transmission}
          error={errors.transmission as string}
          touched={touched.transmission as boolean}
        />

        <FormDropdown
          disableClearable
          id='drivetrain'
          label='Drivetrain'
          onChange={handleChange}
          options={dictionaries.Drivetrain}
          disabled={isDisabled}
          value={values.drivetrain}
          error={errors.drivetrain as string}
          touched={touched.drivetrain as boolean}
        />

        <FormDropdown
          disableClearable
          id='genericColor'
          label='Generic color'
          onChange={handleChange}
          options={dictionaries.GenericColor}
          disabled={isDisabled}
          value={values.genericColor}
          error={errors.genericColor as string}
          touched={touched.genericColor as boolean}
        />

        <FormDropdown
          disableClearable
          id='genericInterior'
          label='Generic interior'
          onChange={handleChange}
          options={dictionaries.GenericInterior}
          disabled={isDisabled}
          value={values.genericInterior}
          error={errors.genericInterior as string}
          touched={touched.genericInterior as boolean}
        />

        <FormDropdown
          disableClearable
          id='disabilityEquipped'
          label='Disability equipped'
          onChange={handleChange}
          options={dictionaries.DisabilityEquipped}
          disabled={isDisabled}
          value={values.disabilityEquipped}
          error={errors.disabilityEquipped as string}
          touched={touched.disabilityEquipped as boolean}
        />
      </InputsRowLayout>
    </>
  )
}

export default observer(VehicleCarInfoFormControls)
