import type { FC } from 'react'
import { useStyles } from './styles'

interface FullPageLoaderProps {
  width?: number
}

const FullPageLoader: FC<FullPageLoaderProps> = ({ width }) => {
  const styles = useStyles({})

  return (
    <div className={styles.loaderPosition}>
      <svg
        data-tag='svg'
        aria-hidden='true'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        style={{
          position: 'absolute',
          width: 0,
          height: 0,
          overflow: 'hidden'
        }}
      >
        <symbol
          data-tag='symbol'
          id='svg-header_chat1'
          viewBox='0 0 80 80'
          fill='none'
        >
          <path
            d='m60 62v-4h-40v4c0 1.3-0.8 2-2 2h-12c-1.3 0-2-0.8-2-2 0-4-1-10-1-25 0.5-2.2 5-6 5-6s-5 0-6-1-1-5 1-5c6 0 8 3 8 3s5-8 9-12c2-2 15-2 20-2s18 0 20 2c4 4 9 12 9 12s2-3 8-3c2 0 2 4 1 5s-6 1-6 1 4.5 3.8 5 6c0 15-1 21-1 25 0 1.2-0.7 2-2 2h-12c-1.2 0-2-0.7-2-2z'
            strokeLinejoin='round'
            strokeLinecap='round'
            strokeMiterlimit='10'
            strokeWidth='3'
          />
        </symbol>
      </svg>

      <svg
        data-tag='svg'
        aria-hidden='true'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        style={{
          position: 'absolute',
          width: 0,
          height: 0,
          overflow: 'hidden'
        }}
      >
        <symbol
          data-tag='symbol'
          id='svg-header_chat2'
          viewBox='0 0 80 80'
          fill='none'
        >
          <path
            d='m60 62v-4h-40v4c0 1.3-0.8 2-2 2h-12c-1.3 0-2-0.8-2-2 0-4-1-10-1-25 0.5-2.2 5-6 5-6s-5 0-6-1-1-5 1-5c6 0 8 3 8 3s5-8 9-12c2-2 15-2 20-2s18 0 20 2c4 4 9 12 9 12s2-3 8-3c2 0 2 4 1 5s-6 1-6 1 4.5 3.8 5 6c0 15-1 21-1 25 0 1.2-0.7 2-2 2h-12c-1.2 0-2-0.7-2-2z'
            strokeLinejoin='round'
            strokeLinecap='round'
            strokeMiterlimit='10'
            strokeWidth='3'
          />
        </symbol>
      </svg>

      <div>
        <div className={styles.svgPak}>
          <div className={styles.scale_an}>
            <svg
              data-tag='svg'
              width={width ?? '200'}
              height='200'
              stroke='#F5CFCA'
              className={styles.stroke_an2}
            >
              <use xlinkHref='#svg-header_chat1' />
            </svg>
          </div>
        </div>

        <div className={styles.svgPak}>
          <div className={styles.scale_an}>
            <svg
              data-tag='svg'
              width={width ?? '200'}
              height='200'
              stroke='#DB5C4C'
              className={styles.stroke_an1}
            >
              <use xlinkHref='#svg-header_chat2' />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullPageLoader
