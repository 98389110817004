import { css } from '@emotion/css'

export default css(`
  display: flex;
  align-items: center;
  width: max-content;
  padding: 0px!important;
  padding-left: 4px!important;
  
  svg {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
`)
