import { css } from '@emotion/css'

export default css(`
  .cf-no-data-block {
    display: flex;
    justify-content: center;
    padding: 30px;
    background: #fff;
    border-top: 8px solid #F3F3F3;
  }

  .header-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border-radius: 12px;
    width: 100%;
    margin: 0 0 24px 0;
    padding: 16px;
    height: 88px;
  }

  .left-panel {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .filters-block {
    margin-right: 24px;
  }

  .search-block {
    max-width: 376px;
    width: 100%;
  }

  .actions-menu {
    width: 159px;
    height: 56px;

    .cf-menu-button {
      min-height: 56px;

      .MuiButton-label {
        letter-spacing: 0.25px;
      }
    }
  }

  .cf-table-header-title {
    font-weight: 600;
    color: #101010;
  }

  .cf-table-cell {
    .cf-cell-wrapper { 
      height: 84px;
      padding: 20px 8px;
    }

    :first-child .cf-cell-wrapper { padding-left: 12px; }
    :last-child .cf-cell-wrapper { padding-right: 16px; }
  }
  
  .cf-table-row {
    .cf-table-cell:first-child > .cf-cell-wrapper {
      .cf-table-cell-text {
        max-width: 25vw;
        min-width: 245px;
      }
    }

    .cf-table-cell:nth-last-child(2) > .cf-cell-wrapper {
      .cf-table-cell-text {
        width: calc(100% - 20px);
        max-width: 14vw;
      }
    }

  .cf-table-cell .cf-cell-wrapper { 
    height: 64px;
    padding: 20px 8px;
  }

  .cf-no-data-block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    background: #fff;
    border-top: 8px solid #F3F3F3;
  }

  .cf-table-cell .cf-cell-wrapper { 
    height: 64px;
    padding: 20px 8px;
  }

  .cf-table-row.cf-skeleton-row {
    .cf-table-cell > div {
      height: 64px;
    }
  }
`)
