import { type FC } from 'react'
import { cnx, formatCurrencyAccounting } from '@carfluent/common'

import TextWithItemsNumber from 'components/accounting/TextWithItemsNumber'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'
import { type ReconciliationBalancesProps } from './types'

import CLASS_NAME from './styles'

const ReconciliationBalances: FC<ReconciliationBalancesProps> = ({
  difference,
  isLoading = false,
  summary
}) => {
  const clearedLinesSummary = summary?.clearedLinesSummary ?? null
  const unclearedLinesSummary = summary?.unclearedLinesSummary ?? null
  const formula = summary?.formula ?? null

  return (
    <div className={cnx(CLASS_NAME, 'g-panel', 'g-panel--complete', 'g-panel--no-shadow')}>
      <div className='block block-cleared'>
        <div className='block-row'>
          <TextWithItemsNumber
            isLoading={isLoading}
            title='Cleared deposit'
            value={clearedLinesSummary?.deposits?.count}
          />

          <BlockSkeleton isLoading={isLoading} className='skeleton-amount'>
            {formatNumber(clearedLinesSummary?.deposits.amount)}
          </BlockSkeleton>
        </div>

        <div className='block-row'>
          <TextWithItemsNumber
            isLoading={isLoading}
            title='Cleared withdrawal'
            value={clearedLinesSummary?.withdrawals?.count}
          />

          <BlockSkeleton isLoading={isLoading} className='skeleton-amount'>
            {formatNumber(clearedLinesSummary?.withdrawals.amount)}
          </BlockSkeleton>
        </div>

        <div className='block-row'>
          <TextWithItemsNumber
            isBold
            isLoading={isLoading}
            title='Net cleared'
            value={(clearedLinesSummary?.deposits.count ?? 0) + (clearedLinesSummary?.withdrawals.count ?? 0)}
          />

          <BlockSkeleton isLoading={isLoading} className='skeleton-amount'>
            {formatNumber((clearedLinesSummary?.deposits.amount ?? 0) - (clearedLinesSummary?.withdrawals.amount ?? 0))}
          </BlockSkeleton>
        </div>
      </div>

      <div className='block block-uncleared'>
        <div className='block-row'>
          <TextWithItemsNumber
            isLoading={isLoading}
            title='Uncleared deposit'
            value={unclearedLinesSummary?.deposits.count}
          />

          <BlockSkeleton isLoading={isLoading} className='skeleton-amount'>
            {formatNumber(unclearedLinesSummary?.deposits.amount)}
          </BlockSkeleton>
        </div>

        <div className='block-row'>
          <TextWithItemsNumber
            className='long-text-with-items-number'
            isLoading={isLoading}
            title='Uncleared withdrawal'
            value={unclearedLinesSummary?.withdrawals.count}
          />

          <BlockSkeleton isLoading={isLoading} className='skeleton-amount'>
            {formatNumber(unclearedLinesSummary?.withdrawals.amount)}
          </BlockSkeleton>
        </div>

        <div className='block-row'>
          <TextWithItemsNumber
            isBold
            isLoading={isLoading}
            title='Net uncleared'
            value={(unclearedLinesSummary?.deposits.count ?? 0) + (unclearedLinesSummary?.withdrawals.count ?? 0)}
          />

          <BlockSkeleton isLoading={isLoading} className='skeleton-amount'>
            {formatNumber((unclearedLinesSummary?.deposits.amount ?? 0) - (unclearedLinesSummary?.withdrawals.amount ?? 0))}
          </BlockSkeleton>
        </div>
      </div>

      <div className='block block-difference'>
        <div className='block-row block-row--underscored'>
          <div className='cell'>
            <BlockSkeleton isLoading={isLoading} className='cell-number skeleton-amount'>
              {formatNumber(formula?.statementEndingBalance)}
            </BlockSkeleton>

            <div className='cell-subtitle'>Statement Ending Balance</div>
          </div>

          <div className={cnx('cell', 'cell--sign', isLoading && 'padding-right')}>
            <h6>-</h6>
          </div>

          <div className='cell cell--right'>
            <BlockSkeleton isLoading={isLoading} className='cell-number skeleton-amount'>
              {formatNumber(formula?.carFluentBalance)}
            </BlockSkeleton>

            <div className='cell-subtitle'>CarFluent balance</div>
          </div>
        </div>

        <div className='block-row block-row--underscored'>
          <div className='cell'>
            <BlockSkeleton isLoading={isLoading} className='cell-number skeleton-amount'>
              {formatNumber(formula?.beginningBalance)}
            </BlockSkeleton>

            <div className='cell-subtitle'>Beginning balance</div>
          </div>

          <div className='cell cell--sign'>
            <h6>-</h6>
          </div>

          <div className='cell cell--center'>
            <BlockSkeleton isLoading={isLoading} className='cell-number skeleton-amount'>
              {formatNumber(formula?.withdrawals.amount)}
            </BlockSkeleton>

            <div className='cell-subtitle'>{formula?.withdrawals.count} Withdrawals</div>
          </div>

          <div className='cell cell--sign'>
            <h6>+</h6>
          </div>

          <div className='cell cell--right'>
            <BlockSkeleton isLoading={isLoading} className='cell-number skeleton-amount'>
              {formatNumber(formula?.deposits.amount)}
            </BlockSkeleton>

            <div className='cell-subtitle'>{formula?.deposits.count} Deposits</div>
          </div>
        </div>

        <div className='block-row'>
          <div />

          <h6 className={cnx((difference !== 0) && 'error')}>
            Difference: {formatNumber(difference)}
          </h6>

          <div />
        </div>
      </div>
    </div>
  )
}

export default ReconciliationBalances

// ========================================== //

const formatNumber = (x: number | null | undefined): string | null => {
  return formatCurrencyAccounting(x, { allowNegative: true })
}
