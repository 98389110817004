import { validation } from '@carfluent/common'
import _isDate from 'date-fns/isDate'
import isValid from 'date-fns/isValid'
import { aptRegex } from 'utils/regex_helper'

export const { isEmailValid } = validation

export const isAlphanumericAndSpecialSymbols = (val?: string): boolean =>
  (val != null && val.length > 0) ? Boolean(val.match(/^[0-9a-zA-Z\s.\-:,/#]+$/g)) : true

export const isCyrillic = (val: string): boolean =>
  (val.length > 0) ? /\p{Script_Extensions=Cyrillic}/u.test(val) : true

export const isAlphanumericAndSpecialSymbolsWithApostrophe = (val?: string): boolean =>
  (val != null && val.length > 0) ? Boolean(val.match(/^[0-9a-zA-Z'\s.\-:,/#]+$/g)) : true

export function isDate (date: any): date is Date {
  return _isDate(date)
}

export function isValidDate (date: any): date is Date {
  return isDate(date) && isValid(date)
}

export const isDefined = (value?: string | number | null): value is string | number => {
  return value != null && value !== ''
}
export const isEmptyString = (value?: string | null): boolean => {
  return value == null || value === ''
}

export const isNumbersOnly = (value?: string | null): boolean => isDefined(value)
  ? Boolean(value.match(/^\d+$/g))
  : true

/**
 * Right now this function allows more then one space between words.
 * It's strange, but legal (at least, according to our requirements).
 */
export const isPersonName = (val?: string | null, required = true): boolean => {
  if (!required && !isDefined(val)) {
    return true
  }
  return /^(\s)*[A-Za-z']+((-|(\s)*)?[A-Za-z']+)*(\s)*$/.test(val ?? '')
}

export const isProperName = (value?: string | null): boolean => isDefined(value)
  ? /^[a-zA-Z]+[a-zA-Z.\s-]*$/.test(value)
  : true

export const isApt = (val?: string | null): boolean => {
  return aptRegex.test(val ?? '')
}

export const scrollToError = (): void => {
  const elements = document.querySelectorAll('.cf-form-field-error, .Mui-error')
  const firstElement = Array.from(elements).find(element => element instanceof HTMLElement)
  if (firstElement != null) {
    firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

export const scrollToErrorInContainer = (selector = '#page-overlay'): void => {
  const parentContainer = document.querySelector(selector)

  if (parentContainer != null) {
    const elements = parentContainer.querySelectorAll('.cf-form-field-error, .Mui-error')
    const firstElement = Array.from(elements).find(element => element instanceof HTMLElement) as HTMLElement | undefined

    if (firstElement != null) {
      const elementRect = firstElement.getBoundingClientRect()
      const parentRect = parentContainer.getBoundingClientRect()
      const relativeTop = elementRect.top - parentRect.top

      const targetScrollTop = Math.max(0,
        parentContainer.scrollTop + relativeTop - (parentRect.height / 2) + (elementRect.height / 2)
      )

      if (targetScrollTop !== parentContainer.scrollTop) {
        parentContainer.scrollTo({
          top: targetScrollTop,
          behavior: 'smooth'
        })
      }
    }
  }
}
