import { type DealFeesAndCoveragesSettingsModel } from 'api/types/responses'
import getDealTaxableCoveragesAmount, { type CoverageLike } from 'utils/deals/priceHelpers/getDealTaxableCoveragesAmount'
import getDealFeesAmount, { type DealFeesLike } from 'utils/deals/priceHelpers/getDealFeesAmount'

export interface CalculateDealSalesTaxAmountConfig {
  coverages: CoverageLike[]
  fees: DealFeesLike
  feesAndCoveragesSettings: DealFeesAndCoveragesSettingsModel | null
  tradeInCredit: number | null
  vehiclePrice: number | null
}

export const getDealTaxableAmount = ({
  coverages = [],
  fees,
  feesAndCoveragesSettings,
  tradeInCredit,
  vehiclePrice
}: CalculateDealSalesTaxAmountConfig): number => {
  const taxableAmount = (vehiclePrice ?? 0) -
    (tradeInCredit ?? 0) +
    getDealFeesAmount(vehiclePrice, fees, feesAndCoveragesSettings?.fees ?? null, true) +
    getDealTaxableCoveragesAmount(coverages, feesAndCoveragesSettings?.coverages ?? null)

  return taxableAmount > 0 ? taxableAmount : 0
}

export default getDealTaxableAmount
