import { PRESET_LABELS, UI } from '@carfluent/common'
import { DictionaryItems, type DictionaryItem, DealsCounters, DealFiltersState } from 'api/types'
import { DealWorkflowAccountingStateId } from 'types/number_enums'
import { DealWorkflowAccountingStateNames, DealStateName } from 'constants/names'

import { type DealsListFilters, SearchFilterName } from './types'

export const presetList = [
  PRESET_LABELS.ALL,
  PRESET_LABELS.ALL_TILL_TODAY,
  PRESET_LABELS.TODAY,
  PRESET_LABELS.YESTERDAY,
  PRESET_LABELS.THIS_WEEK,
  PRESET_LABELS.THIS_MONTH,
  PRESET_LABELS.THIS_QUARTER
]

export const defaultSorting = (): UI.SortingInfo => ({ id: 'date', order: 'desc' })
export const defaultSoldSorting = (): UI.SortingInfo => ({ id: 'saleDate', order: 'desc' })
export const defaultCounters = (): DealsCounters => ({
  [DealFiltersState.Incomplete]: 0,
  [DealFiltersState.Sold]: 0,
  [DealFiltersState.OnDeposit]: 0,
  [DealFiltersState.Canceled]: 0
})

export const defaultFilters = (): DealsListFilters => ({
  workflowSearchState: DealFiltersState.Incomplete,
  workflowAccountingStates: [],
  date: { from: null, to: null },
  nextAction: [],
  dealPaidStatusId: []
})

export const defaultTags = (): DictionaryItems<string> | null => [
  {
    id: DealWorkflowAccountingStateId.Sold,
    name: DealWorkflowAccountingStateNames.Sold
  },
  {
    id: DealWorkflowAccountingStateId.PushedToAccounting,
    name: DealWorkflowAccountingStateNames.PushedToAccounting
  },
  {
    id: DealWorkflowAccountingStateId.Recorded,
    name: DealWorkflowAccountingStateNames.Recorded
  }
]

export const SearchStepOptionsName: { [key in SearchFilterName]: string } = {
  [DealFiltersState.Incomplete]: 'Active deals',
  [DealFiltersState.OnDeposit]: DealStateName.OnDeposit,
  [DealFiltersState.Canceled]: DealStateName.Canceled
}

export const searchFilterEnumName: { [name: string]: SearchFilterName } = {}

for (const key of Object.keys(SearchStepOptionsName)) {
  const name = SearchStepOptionsName[key as unknown as keyof typeof SearchStepOptionsName]
  searchFilterEnumName[name] = key as unknown as SearchFilterName
}

export const SEARCH_OPTIONS: DictionaryItem[] = [
  {
    id: DealFiltersState.Incomplete,
    name: SearchStepOptionsName[DealFiltersState.Incomplete]
  },
  {
    id: DealFiltersState.Canceled,
    name: DealStateName.Canceled
  }
]
