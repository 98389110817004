import { css } from '@emotion/css'

export default css(`
  .title-and-edit-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
   max-width: 200px;
  }

  .page-info {
    display: grid;
    justify-content: space-between;
    gap: 24px;
  }

  h5, h6, .g-horizontal-line {
    grid-column: 1 / 4;
  }

  .g-horizontal-line {
    margin: 8px 0;
  }

  .logo {
    grid-column: 3;
  }

  .business-info-section {
    display: grid;
    grid-auto-rows: min-content;
    width: 600px;
    gap: 24px;

    .subgrid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px 16px;
    }

    .address-input {
      .MuiInputBase-root {
        padding-left: 12px;
      }
      
      .MuiFormLabel-root {
        padding-left: 8px;
        color: rgba(33, 33, 33, 0.8);

        &.Mui-disabled {
          color: rgba(21, 21, 21, 0.4);
        }
      }
    }
    .cf-form-field {
      width: 100%;
      position: relative;

      a {
        color: #458FFF;
        position: absolute;
        background: #fafafa;
        left: 16px;
        top: 27px;
        white-space: nowrap;
        width: calc(100% - 32px);
        text-overflow: ellipsis;
        overflow: clip;
      }
    }
  }

  .link {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #458FFF;
  }

  .edit-btn {
    margin-left: 24px;
    width: 70px;
    background: #FFFFFF;
    border: 1px solid #C99B86;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25;
    text-transform: uppercase;
    color: #000000;
    padding: 5px 18px;

    &:hover {
      border: 1px solid #DDB3A0;
      background: #FFFFFF;
    }
  }

  .loading-field {
    display: block;
    height: 56px;

    span {
      height: 100%;
    }
  }

  .terms {
    display: flex;
    gap: 24px;
    flex-direction: column;
  }

`)
