export const serializeNumber = (value: number | string | null | undefined): number | null => {
  if ((value == null) || (value === '')) {
    return null
  }

  const num = Number(value)
  if (isNaN(num)) {
    return null
  }

  return num
}
