import type { FC } from 'react'

import CheckboxLabel from 'components/common/CheckboxLabel'

import type { VehicleFeature, VehicleOption } from 'api/types/vehicles.types'
import CLASS_NAME from './styles'

interface FeatureSelectionProps {
  features?: Record<string, VehicleFeature[] | VehicleOption[]>
  onChange: (category: string, id: number) => void
  isDisabled: boolean
}

const getName = (option: VehicleFeature | VehicleOption): string => {
  if ((option as VehicleFeature).name != null) {
    return (option as VehicleFeature).name ?? ''
  }

  return (option as VehicleOption).optionDescription
}

const FeatureOptionSelection: FC<FeatureSelectionProps> = ({ features = {}, onChange, isDisabled }) => {
  return (
    <div>
      {Object.keys(features).map(feature => (
        <div key={feature} className={CLASS_NAME}>
          <div className='feature-title'>
            {feature}
          </div>
          <div className='feature-options'>
            {features[feature].map(f => (
              <div className='feature-option' key={f.id}>
                <CheckboxLabel
                  disabled={isDisabled}
                  onChange={() => onChange(feature, f.id)}
                  value={{
                    checked: f.isEnabled,
                    name: getName(f),
                    id: f.id
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default FeatureOptionSelection
