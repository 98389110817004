import { type FC } from 'react'
import { Modal, FormNumberInput, FormDatePicker, MessageDialog } from '@carfluent/common'

import { MAX_PRICE_VALUE } from 'constants/validation'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'

import useEditForm from './hook/index'
import { type EditFormProps } from './hook/types'

import CLASS_NAME, { MESSAGE_CLASS_NAME } from './styles'

const MODAL_MIN_WIDTH = 680

const EditForm: FC<EditFormProps> = ({
  editModal,
  prevReconciliationSession,
  onSubmitEditReconciliation
}) => {
  const { isModalOpen, onCloseModal, sessionId } = editModal

  const {
    lockedInfo, minDateFromAccounting, lockedMonth,
    endDate, minDate, confirmEditDialogProps,
    onUpdateReconciliationSession, shouldDisableMonth, ...formProps
  } = useEditForm({
    sessionId,
    prevReconciliationSession,
    onCloseMainModal: onCloseModal,
    onSubmit: onSubmitEditReconciliation,
    isModalOpen
  })

  const { values, errors, touched, onBlur, onChange, onCancel, isSubmitting } = formProps

  const renderFooter = (): JSX.Element => {
    return (
      <CancelSubmitActionsFooter
        isLoading={isSubmitting}
        onSubmit={confirmEditDialogProps?.onOpenModal}
        onClose={() => {
          onCancel()
          onCloseModal()
        }}
        submitTitle='Update'
      />
    )
  }

  return (
    <>
      <Modal
        className={CLASS_NAME}
        isLoading={isSubmitting}
        isOpen={isModalOpen}
        onClose={onCloseModal}
        minWidth={MODAL_MIN_WIDTH}
        renderFooterActions={renderFooter}
        title='Edit'
        data-test-id='edit-reconciliation-form'
      >

        <div className='form-container'>
          <FormNumberInput
            value={values?.beginningBalance ?? ''}
            error={errors.beginningBalance}
            touched={touched.beginningBalance}
            id='beginningBalance'
            label='Beginning balance'
            onBlur={onBlur}
            onChange={onChange}
            max={MAX_PRICE_VALUE}
            dataTestId='beginning-balance'
            mode='decimal'
            isNegativeAllowed
            startAdornment='$'
            isEmptyAllowed
            disabled
          />

          <FormNumberInput
            value={values.endingBalance ?? ''}
            error={errors.endingBalance}
            touched={touched.endingBalance}
            id='endingBalance'
            label='Ending balance'
            onBlur={onBlur}
            onChange={onChange}
            max={MAX_PRICE_VALUE}
            dataTestId='ending-balance'
            mode='decimal'
            isNegativeAllowed
            startAdornment='$'
            isEmptyAllowed
          />

          <FormDatePicker
            id='endDate'
            label='End date'
            className='date-picker'
            minDate={endDate != null ? minDate : minDateFromAccounting}
            shouldDisableMonth={shouldDisableMonth}
            onBlur={onBlur}
            onChange={onChange}
            value={values.endDate}
            error={errors.endDate}
            touched={touched.endDate}
          />
        </div>

      </Modal>

      <MessageDialog
        isOpen={confirmEditDialogProps.isModalOpen}
        onClose={confirmEditDialogProps?.onCloseModal}
        onCancel={() => {
          onCancel()
          confirmEditDialogProps?.onCloseModal()
          onCloseModal()
        }}
        onSubmit={onUpdateReconciliationSession}
        title='Edit reconciliation?'
        textSubmit='YES, CONFIRM'
        className={MESSAGE_CLASS_NAME}
      >
        <div className='content-container'>
          Editing the Ending balance or End date can affect the current reconciliation session.
          Please confirm to proceed with these changes.
        </div>
      </MessageDialog>
    </>

  )
}

export default EditForm
