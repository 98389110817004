import { useCallback, useMemo, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { formatCurrencyAccounting, noop, useLoader, useModal } from '@carfluent/common'

import AccountingApiProvider from 'api/accounting.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useTableApi from 'hooks/useTableApi'
import useCostTypes from 'hooks/useCostTypes'
import { Routes } from 'constants/route_helper'
import isReconciledTransaction from 'utils/accounting/isReconciledTransaction'
import isClearedTransaction from 'utils/accounting/isClearedTransaction'
import { eventToNull } from 'utils/general'
import { joinPartsToStr } from 'utils/view_helper'

import {
  type AccountDto,
  type ButtonClickEvent,
  type KeyVal,
  type ListResponse,
  type ReconciliationDraftFromApi,
  type ReconciliationDraftRowData,
  type ReconciliationSessionDto,
  type ReconciliationSessionSummaryDto,
  type Row,
  ReconcileStatusId,
  TransactionTypeId,
  type UseReconciliationDraftsListReturn
} from './types'

import { API_CALL_DELAY_SEARCH, DEFAULT_FILTERS, DEFAULT_SORTING, Messages } from './constants'

import getColumnDefinitions from './columns'
import parseListData from './parser'

const useReconciliationDraft = (): UseReconciliationDraftsListReturn => {
  const navigate = useNavigate()
  const { accountId = null } = useParams<{ accountId: string }>()
  const location = useLocation<{ fromSetup: boolean } | null>()
  const transactionModal = useModal()
  const sessionFinishDialogProps = useModal()
  const editDialogProps = useModal()

  const { isLoading: isGlobalLoading, startLoader, stopLoader } = useLoader()
  const { showSuccess, showError } = useCustomSnackbar()

  const [prevReconciliationSession, setPrevReconciliationSession] = useState<ReconciliationSessionDto | null>(null)
  const [account, setAccount] = useState<AccountDto | null>(null)
  const [reconciliationSummary, setReconciliationSummary] = useState<ReconciliationSessionSummaryDto | null>(null)
  const [sessionId, setSessionId] = useState<number | null>(null)
  const [costTypeId, setCostTypeId] = useState<number | null>(null)
  const [transactionId, setTransactionId] = useState<number | null>(null)
  const [transactionTypeId, setTransactionTypeId] = useState<number>(TransactionTypeId.JournalEntry)
  const { costTypes } = useCostTypes()

  const loadReconciliationSessionAll = useCallback(async ({
    sortField,
    sortOrder
  }: KeyVal): Promise<ListResponse<ReconciliationDraftFromApi>> => {
    if (accountId == null) {
      return { items: [] }
    }

    const payload = { accountId, sortField, sortOrder }
    const resp = await AccountingApiProvider.getAccountReconciliationSession(payload)

    setSessionId(resp.inProgressReconciliationSessionId)
    setAccount(resp.account)
    setPrevReconciliationSession(resp.lastFinishedReconciliationSession)
    setReconciliationSummary(resp.summary)

    return { items: resp.lines }
  }, [accountId])

  const {
    emptyTableMessage,
    rows,
    sorting,
    isLoading: isTableLoading,
    loadRows,
    setSorting,
    setCellValue
  } = useTableApi<ReconciliationDraftFromApi, KeyVal, ReconciliationDraftRowData>({
    apiCallDelay: API_CALL_DELAY_SEARCH,
    defaultFilters: DEFAULT_FILTERS,
    defaultSorting: DEFAULT_SORTING,
    emptyTableMessage: Messages.EmptyTableState,
    getList: loadReconciliationSessionAll,
    nothingFoundMessage: Messages.EmptyTableState,
    parseListData,
    shouldRunOnCall: true
  })

  const columns = useMemo(() => {
    return getColumnDefinitions({
      onToggleReconcile: async (rowIdx: number, id: number, status: number): Promise<void> => {
        if (sessionId == null) {
          return
        }

        try {
          const isReconciled = isReconciledTransaction(status)
          const isCleared = isClearedTransaction(status)
          const nextStatus = isReconciled
            ? isCleared ? ReconcileStatusId.Cleared : ReconcileStatusId.None
            : isCleared ? ReconcileStatusId.ClearedAndReconciled : ReconcileStatusId.Reconciled

          const getSummary = isReconciled
            ? AccountingApiProvider.uncheckTransactionAsReconciled
            : AccountingApiProvider.checkTransactionAsReconciled

          const resp = await getSummary(sessionId, id)
          setReconciliationSummary(resp)
          setCellValue(rowIdx, 'reconcileStatus', nextStatus)
        } catch (err) {
          showError(Messages.SomethingWrong)
        }
      }
    })
  }, [sessionId, setCellValue])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onOpenTransactionDetails = useCallback(eventToNull<Row<ReconciliationDraftRowData>, ButtonClickEvent>((row) => {
    if (row?.original == null) {
      return
    }

    const { costTypeId, transactionId, transactionTypeId } = row.original

    setCostTypeId(costTypeId ?? null)
    setTransactionId(transactionId ?? null)
    setTransactionTypeId(transactionTypeId)
    transactionModal.onOpenModal()
  }), [transactionModal.onOpenModal])

  const onCloseTransactionDetails = useCallback(() => {
    transactionModal.onCloseModal()
  }, [transactionModal.onCloseModal])

  const onSubmitEditReconciliation = useCallback(async () => {
    void loadRows({ forceRefresh: true })
  }, [loadRows])

  const onSubmitTransactionDetails = useCallback(async () => {
    transactionModal.onCloseModal()
    void loadRows({ forceRefresh: true })
  }, [
    transactionModal.onCloseModal,
    loadRows
  ])

  const onViewNextTransaction = useCallback((
    transactionId: number,
    typeId: number,
    costTypeId: number | null = null
  ) => {
    transactionModal.onCloseModal()

    setTimeout(() => {
      setTransactionId(transactionId)
      setTransactionTypeId(typeId)
      setCostTypeId(costTypeId)
      transactionModal.onOpenModal()
    }, 0)
  }, [transactionModal.onOpenModal])

  const onCloseReconciliation = useCallback(() => {
    if (location?.state?.fromSetup === true) {
      navigate(-2)
    } else {
      navigate(Routes.AccountingBanking)
    }
  }, [navigate, location])

  const onFinishReconciliationStart = useCallback(() => {
    sessionFinishDialogProps.onOpenModal()
  }, [sessionFinishDialogProps.onOpenModal])

  const onFinishReconciliationSubmit = useCallback(async () => {
    try {
      if (sessionId != null) {
        startLoader()
        sessionFinishDialogProps.onCloseModal()
        await AccountingApiProvider.completeReconciliationSession(sessionId)
      }

      setTimeout(() => { navigate(-2) }, 0)
    } catch (err) {
      showError(Messages.SomethingWrong)
    } finally {
      stopLoader()
      showSuccess(Messages.ReconciliationSuccess)
    }
  }, [
    navigate,
    sessionId,
    sessionFinishDialogProps.onCloseModal
  ])

  // ========================================== //

  const difference = reconciliationSummary?.formula.difference ?? 0
  const differenceError = `Difference: ${formatCurrencyAccounting(difference, { allowNegative: true })}`

  return {
    accountName: joinPartsToStr(' - ', account?.number, account?.name),
    columns,
    difference,
    differenceError,
    emptyTableMessage,
    isGlobalLoading,
    isSubmitDisabled: difference !== 0,
    isTableLoading,
    onCloseReconciliation,
    onFinishReconciliationStart,
    onFinishReconciliationSubmit,
    onOpenTransactionDetails,
    onSortingChange: setSorting,
    prevReconciliationSession,
    reconciliationSummary,
    rows,
    sessionFinishDialogProps,
    sorting,
    onSubmitEditReconciliation,
    editDialogProps: {
      ...editDialogProps,
      sessionId
    },
    transactionDialogControllerProps: {
      costsProps: {
        ...transactionModal,
        costTypes,
        onCloseModal: onCloseTransactionDetails,
        onDeleteCost: noop,
        onSubmit: onSubmitTransactionDetails,
        selectedCost: transactionId
      },
      costTypeId,
      transactionProps: {
        ...transactionModal,
        onCancel: onCloseTransactionDetails,
        onCloseModal: onCloseTransactionDetails,
        onSubmit: onSubmitTransactionDetails,
        onViewNextTransaction,
        transactionId
      },
      transactionTypeId
    }
  }
}

export default useReconciliationDraft
