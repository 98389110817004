import { useState, useEffect, useMemo } from 'react'
import { PRESET_LABELS, serializers } from '@carfluent/common'
import pDebounce from 'p-debounce'

import type { DateType } from 'api/types'
import type { ActivityReportItemDto } from 'api/types/responses'
import { DEFAULT_PRESETS } from 'components/reports/ReportLayout/utils'
import CRMApiProvider from 'api/crm.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import serializeDateFilters from 'utils/filters/serializeDateFilters'

export interface UseReportActivityReturn {
  onChangeDatesFilter: (date: DateType) => Promise<void>
  rows: ActivityReportItemDto[]
  isLoading: boolean
}

const THIS_MONTH_PRESET = DEFAULT_PRESETS.find(p => p.name === PRESET_LABELS.THIS_MONTH)
const DEFAULT_DATE_RANGE = {
  from: THIS_MONTH_PRESET?.startDate ?? null,
  to: THIS_MONTH_PRESET?.endDate ?? null
}

const LOAD_ROWS_DELAY = 1000

export const useReportActivity = (): UseReportActivityReturn => {
  const [rows, setRows] = useState<ActivityReportItemDto[]>([])
  const [dateRange, setDateRange] = useState<DateType>(DEFAULT_DATE_RANGE)
  const [isLoading, setIsLoading] = useState(true)
  const { showAlert } = useCustomSnackbar()

  const loadRows = useMemo(() => {
    return pDebounce(async (dateRange: DateType) => {
      try {
        setIsLoading(true)
        const { items } = await CRMApiProvider.getActivityReportList({
          dateRange: serializeDateFilters(dateRange, serializers.serializeDateTimeUTC) as DateType
        })
        setRows(items)
      } catch (e) {
        showAlert(e)
      } finally {
        setIsLoading(false)
      }
    }, LOAD_ROWS_DELAY)
  }, [showAlert])

  const onChangeDatesFilter = async (period: DateType): Promise<void> => {
    void setDateRange(period)
  }

  useEffect(() => {
    void loadRows(dateRange)
  }, [dateRange, loadRows])

  return {
    onChangeDatesFilter,
    isLoading,
    rows
  }
}
