import { type FC, useState } from 'react'
import { cnx, formatCurrencyAccounting } from '@carfluent/common'

import type { SaleBreakdownModel } from 'api/types/responses'

import TooltipedText from 'components/common/TooltipedText'
import IconSVG from 'components/inlineImages'

import LoadingRows from '../LoadingRows'

import CLASS_NAME from './styles'

export interface BreakdownProps {
  breakdown: SaleBreakdownModel | null
  isLoading: boolean
}

export const AMOUNT_FORMAT = { emptyValues: '$0.00', allowNegative: true }

const Breakdown: FC<BreakdownProps> = ({
  breakdown,
  isLoading
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const onToggleVisibility = (): void => {
    setIsOpen(!isOpen)
  }

  const isProfitsDataEmpty = Object.values(breakdown?.profits ?? {})
    .every(value => value === '' || value === null || value === undefined)

  return (
    <div className={CLASS_NAME}>
      <div className='breakdown-wrapper'>
        <div className='breakdown-title' onClick={onToggleVisibility}>
          <span>Breakdown</span>
          {
            isOpen
              ? <IconSVG.DropdownArrowUp width={24} height={24} color='#000' opacity={1} />
              : <IconSVG.DropdownArrowDown width={24} height={24} color='#000' opacity={1} />
          }
        </div>

        {isOpen && (
          <div className='content'>
            <div className='column'>
              <div className='table productTypes'>
                <div className='thead'>
                  <div className='cell-title'>Product type</div>
                  <div className='cell-title'>＃Sold</div>
                  <div className='cell-title'>Profit total</div>
                  <div className='cell-title'>Profit average</div>
                </div>

                <div className='tbody'>
                  {isLoading && <LoadingRows columNumber={4} rowNumbers={4} />}
                  {!isLoading && (
                    breakdown?.productTypes?.length !== 0
                      ? breakdown?.productTypes?.map((item) => (
                        <div key={item.name} className='tr'>
                          <div className='cell-value'>
                            <TooltipedText value={item.name ?? '-'} />
                          </div>
                          <div className='cell-value'>{item.soldCount ?? '-'}</div>
                          <div className='cell-value'>{formatCurrencyAccounting(item.profitTotal, AMOUNT_FORMAT)}</div>
                          <div className='cell-value'>{formatCurrencyAccounting(item.profitAverage, AMOUNT_FORMAT)}</div>
                        </div>
                      ))
                      : <div className='empty-row'>No records found</div>
                  )}
                </div>
              </div>

              <div className='table lienholder'>
                <div className='thead'>
                  <div className='cell-title'>Lienholder</div>
                  <div className='cell-title'>Financed</div>
                  <div className='cell-title'>Financed amount</div>
                  <div className='cell-title'>Acquisition fees</div>
                  <div className='cell-title'>Reserve</div>
                </div>

                <div className='tbody'>
                  {isLoading && <LoadingRows columNumber={5} rowNumbers={4} />}
                  {!isLoading && (
                    breakdown?.lienholders?.length !== 0
                      ? breakdown?.lienholders?.map((item) => (
                        <div key={item.name} className='tr'>
                          <div className='cell-value'><TooltipedText value={item.name ?? '-'} /></div>
                          <div className='cell-value'>{item.financedCount ?? '-'}</div>
                          <div className='cell-value'>{formatCurrencyAccounting(item.financedAmount, AMOUNT_FORMAT)}</div>
                          <div className='cell-value'>{formatCurrencyAccounting(item.acquisitionFee, AMOUNT_FORMAT)}</div>
                          <div className='cell-value'>{formatCurrencyAccounting(item.reserve, AMOUNT_FORMAT)}</div>
                        </div>
                      ))
                      : <div className='empty-row'>No records found</div>
                  )}
                </div>
              </div>
            </div>

            <div className='column'>
              <div className='table profits'>
                <div className='thead'>
                  <div className='cell-title'>Profits</div>
                  <div className='cell-title'>Total</div>
                  <div className='cell-title'>Average</div>
                </div>

                <div className='tbody'>
                  {isLoading && <LoadingRows columNumber={3} rowNumbers={7} />}
                  {!isLoading && (
                    isProfitsDataEmpty
                      ? (<div className='empty-row'>No records found</div>)
                      : (
                        <>
                          <div className='tr'>
                            <div className='cell-value'>Sale price</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits?.salePrice?.total, AMOUNT_FORMAT)}</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits?.salePrice?.average, AMOUNT_FORMAT)}</div>
                          </div>

                          <div className='tr'>
                            <div className='cell-value'>Total vehicle cost</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits?.totalVehicleCost?.total, AMOUNT_FORMAT)}</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits?.totalVehicleCost?.average, AMOUNT_FORMAT)}</div>
                          </div>

                          <div className='tr'>
                            <div className='cell-value'>Car delivery</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.carDelivery.total, AMOUNT_FORMAT)}</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.carDelivery.average, AMOUNT_FORMAT)}</div>
                          </div>

                          <div className='tr'>
                            <div className='cell-value'>Trade in allowance</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.tradeInAllowance.total, AMOUNT_FORMAT)}</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.tradeInAllowance.average, AMOUNT_FORMAT)}</div>
                          </div>

                          <div className='tr'>
                            <div className='cell-value'>Warranty profit</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.warrantyProfit.total, AMOUNT_FORMAT)}</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.warrantyProfit.average, AMOUNT_FORMAT)}</div>
                          </div>

                          <div className='tr'>
                            <div className='cell-value'>Reserve</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.reserve.total, AMOUNT_FORMAT)}</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.reserve.average, AMOUNT_FORMAT)}</div>
                          </div>

                          <div className='tr'>
                            <div className='cell-value'>Acquisition fee</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.acquisitionFee.total, AMOUNT_FORMAT)}</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.acquisitionFee.average, AMOUNT_FORMAT)}</div>
                          </div>

                          <div className='tr'>
                            <div className='cell-value'>Gross profit</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.grossProfit.total, AMOUNT_FORMAT)}</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.grossProfit.average, AMOUNT_FORMAT)}</div>
                          </div>

                          <div className='tr'>
                            <div className='cell-value'>Commissions</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.commissions.total, AMOUNT_FORMAT)}</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.commissions.average, AMOUNT_FORMAT)}</div>
                          </div>

                          <div className='tr bold'>
                            <div className='cell-value'>Net profit</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.netProfit.total, AMOUNT_FORMAT)}</div>
                            <div className='cell-value'>{formatCurrencyAccounting(breakdown?.profits.netProfit.average, AMOUNT_FORMAT)}</div>
                          </div>
                        </>
                        )
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Breakdown
