import { memo, useMemo, type FC } from 'react'
import { cnx, TableRowProps } from '@carfluent/common'
import get from 'lodash-es/get'
import { TableRow as MuiTableRow, TableCell } from '@material-ui/core'
import { flexRender } from '@tanstack/react-table'

import _isReconciledTransaction from 'utils/accounting/isReconciledTransaction'
import { BASE_CLASS_NAME, HOVER_CLASS_NAME } from './styles'

export interface WithReconcileStatus {
  reconcileStatus: number
}

export function RowForReconciliation <V extends WithReconcileStatus> ({
  classes,
  onRowClick,
  row
}: TableRowProps<V>): JSX.Element {
  const isReconciledTransaction = _isReconciledTransaction(row.original.reconcileStatus)

  const tableRowExpanderClasses = useMemo(() => ({
    root: cnx(BASE_CLASS_NAME, 'cf-table-row', isReconciledTransaction && 'reconciled-row'),
    hover: cnx(HOVER_CLASS_NAME, 'cf-table-row-hover')
  }), [isReconciledTransaction, classes?.row])

  // ========================================== //

  return (
    <MuiTableRow
      classes={tableRowExpanderClasses}
      data-id={row.id}
      hover
      key={`${row.id}_expander`}
      onClick={onRowClick}
    >
      {row.getVisibleCells().map(cell => {
        return (
          <TableCell
            className={cnx('cf-table-cell', classes?.cell)}
            key={cell.id}
            width={cell.column.getSize()}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        )
      })}
    </MuiTableRow>
  )
}

export default RowForReconciliation

export const MemoizedByOriginal: FC<TableRowProps<WithReconcileStatus>> = memo(RowForReconciliation, (
  prev: TableRowProps<WithReconcileStatus>,
  next: TableRowProps<WithReconcileStatus>
) => {
  for (const key of Object.keys(prev.row.original as any)) {
    if (get(prev.row.original, key) !== get(next.row.original, key)) {
      return false
    }
  }

  return true
})
