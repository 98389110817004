import type { LienholderItemDto } from 'api/types'
import type { FormLienholderData } from './types'

const parseListData = (data: LienholderItemDto[]): FormLienholderData[] => {
  return data.map(parseRowData)
}

const parseRowData = (data: LienholderItemDto): FormLienholderData => {
  return {
    id: data.id,
    name: data.name ?? '',
    email: data.email ?? '',
    phone: data.phoneNumber ?? '',
    address: data.address ?? '',
    city: data.city ?? '',
    state: data.state ?? '',
    zipCode: data.zipCode ?? ''
  }
}

export default parseListData
