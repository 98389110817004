import { css } from '@emotion/css'

export default css(`
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    width: 100%;
    position: fixed;
    padding: 0 24px;
    top: 0px;
    left: 0px;
    z-index: 1;

    img {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }

    .cf-header-counter {
      color: #fff;
      margin-left: 50%;
      transform: translateX(-50%);
      font-size: 1rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.5;
    }
`)
