import { useContext, useCallback } from 'react'

import type { DealsListItem } from 'api/types'

import DealNotificationCTX from 'store/notifications'
import { appendCheckedDealId } from 'services/storage.service'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

export interface DealCheckReturn {
  notifications: DealsListItem[]
  onCloseNotification: (workflowId: number) => void
  onClearNotifications: () => void
}

const SUCCESS_MESSAGE = 'Notifications cleared successfully.'

export const useNotifications = (): DealCheckReturn => {
  const { uncheckedDeals: notifications, checkDeal } = useContext(DealNotificationCTX)
  const { showAlert } = useCustomSnackbar()

  const onCloseNotification = useCallback((workflowId: number): void => {
    checkDeal(workflowId)
    appendCheckedDealId(workflowId)
  }, [checkDeal])

  const onClearNotifications = useCallback((): void => {
    notifications.forEach(({ workflowId }) => onCloseNotification(workflowId))
    showAlert(SUCCESS_MESSAGE, { variant: 'success' })
  }, [notifications, onCloseNotification, showAlert])

  return {
    notifications,
    onCloseNotification,
    onClearNotifications
  }
}
