import { css } from '@emotion/css'

/**
 * Styles for correct rendering of feedback link in wysiwyg editor.
 */

export default css(`
  span {
    color: #101010 !important;
    background: transparent !important;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    font-family: inherit;
    display: inline-block;
  }

  :hover {
    cursor: pointer;
    text-decoration: none;

    img {
      display: none;
    }
  }
`)
