import inProcessIcon from 'assets/in_process.svg'
import inTransitIcon from 'assets/in_transit.svg'
import pendingDetailsIcon from 'assets/pending_details.svg'
import pendingPhotosIcon from 'assets/pending_photos.svg'
import pendingReconIcon from 'assets/pending_recon.svg'
import readyForSaleIcon from 'assets/ready_for_sale.svg'

export enum VehicleTagIds {
  ReadyForSale = 1,
  InProcess,
  PendingRecon,
  PendingDetails,
  PendingPhotos,
  InTransit,
}

export const TAGS_ICONS: Record<VehicleTagIds, string> = {
  [VehicleTagIds.InProcess]: inProcessIcon,
  [VehicleTagIds.InTransit]: inTransitIcon,
  [VehicleTagIds.PendingDetails]: pendingDetailsIcon,
  [VehicleTagIds.PendingPhotos]: pendingPhotosIcon,
  [VehicleTagIds.PendingRecon]: pendingReconIcon,
  [VehicleTagIds.ReadyForSale]: readyForSaleIcon
}
