import { type FC, memo } from 'react'
import { cn } from '@carfluent/common'

import type { VehicleImage } from 'api/types'
import { DEFAULT_FILE_MAX_SIZE } from 'constants/files'

import IconSVG from 'components/inlineImages'
import playIcon from 'assets/play_circle_outline.png'
import getVehicleVideoUrl from 'utils/getVehicleVideoUrl'

import CLASS_NAME from './styles'

interface GalleryProps {
  files: VehicleImage[]
  selectedPhotos: VehicleImage[]
  selectedVideos: VehicleImage[]
  onFileClick: (file: VehicleImage) => void
  isDisabledVideo?: boolean
}

const Gallery: FC<GalleryProps> = ({
  isDisabledVideo = false,
  files,
  selectedPhotos,
  selectedVideos,
  onFileClick
}) => {
  return (
    <div className={CLASS_NAME}>
      {files.map((file) => {
        const { fileName, order, thumbnailUrl, originalUrl, originalSizeKb, fileId } = file
        const photoPoster = thumbnailUrl ?? originalUrl
        const isExceedSizeLimit = originalSizeKb > DEFAULT_FILE_MAX_SIZE
        const isPhotoSelected = selectedPhotos.includes(file)
        const isVideoSelected = selectedVideos.includes(file)
        const videoPoster = getVehicleVideoUrl('thumbnail', fileId)

        return (
          <div
            key={`${fileName}${order}`}
            className={cn(
              'cf-photo-item',
              (isPhotoSelected || isVideoSelected) && 'selected',
              (fileId != null && isDisabledVideo) && 'disabled',
              isExceedSizeLimit && 'exceeded-size-limit'
            )}
            onClick={() => onFileClick(file)}
          >
            {isExceedSizeLimit && (
              <div className='cf-size-limit-overlay'>
                <p className='cf-size-limit'>Exceeds 5MB limit</p>
              </div>
            )}
            {(isPhotoSelected || isVideoSelected) && <div className='cf-icon-tick'><IconSVG.Tick /></div>}
            {fileId != null
              ? (
                <>
                  {isDisabledVideo && <div className='disabled-bg' />}
                  <img src={videoPoster} alt={fileName} />
                  <div className='icon-player'>
                    <img src={playIcon} alt='Play video' />
                  </div>
                </>
                )
              : (
                <img src={photoPoster} alt={fileName} />
                )}
          </div>
        )
      })}
    </div>
  )
}

// PERF_CHECKED: reduces rerenders.
export default memo(Gallery)
