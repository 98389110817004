import { WorkflowTypeEnum } from 'api/types'

/**
 * If type is absent it is considered as personal
 */
export const isPersonalType = (type?: WorkflowTypeEnum | null): boolean => {
  return type == null || type === WorkflowTypeEnum.Personal
}

export const isBusinessType = (type?: WorkflowTypeEnum | null): boolean => {
  return type === WorkflowTypeEnum.Business
}

export const isWholesaleType = (type?: WorkflowTypeEnum | null): boolean => {
  return type === WorkflowTypeEnum.Wholesale
}
