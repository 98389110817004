import { css } from '@emotion/css'
import { px, ScreenWidthBreakpoints } from 'constants/constants'

export const FILTERS_PANEL_CLASS_NAME = css(`
  &.g-filters-panel {
    grid-template-columns: 1fr;

    .g-filters-panel-left-part {
      row-gap: 16px;
    }

    .g-filters-panel-left-part, .g-filters-panel-tabs {
      width: 100%;
    }
    
    .g-filter-panel-tabs .g-filter-tabs-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      grid-column-gap: 16px;
      width: 100%;
      background: transparent;
      height: auto;
      padding: 0px;

      button.g-filter-tab.cf-button-root {
        :after, :before {
          display: none;
        }

        width: 100%;
        height: auto;
        padding: 12px 16px;
        border-radius: 12px;
        background: #fff;
        border: 1px solid transparent;

        &.g-active-filter-tab {
          border: 1px solid #C99B86;
          background: #fff;

          svg {
            rect {
              fill: #C99B86;
            }

            path {
              fill: #fff;
            }
          }
        }

        :hover {
          background: #FAFAFA;
        }

        .g-filter-tab-icon {
          margin-right: 8px;
          height: 100%;
        }

        .cf-button-content {
          display: flex;
          align-items: center;
          height: 32px;
          width: 100%;

          .g-filter-tab-text {
            color: #101010;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 1.25px;
            text-transform: uppercase;
            text-align: left;
          }
          
          .g-filter-tab-counter {
            color: #101010;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.4px;
            margin-left: auto;
          }
        }
      }
    }
      
    .g-filters-panel-right-part {
      justify-content: flex-end;
      margin-top: -40px;
    }
  }
`)

export const CLASS_NAME = css(`
  .cf-no-data-block {
    display: flex;
    justify-content: center;
    padding: 30px;
    background: #fff;
    border-top: 8px solid #F3F3F3;
  }

  .MuiPaper-root.cf-table-wrapper {
    border-radius: 2px 12px 2px 2px;

    .cf-table { table-layout: fixed; }

    .cf-table-header-title {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      white-space: nowrap;
    }

    .cf-cell-wrapper {
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    } 

    .cf-table-cell {
      :last-of-type {
        width: 150px;
        .cf-cell-wrapper,
        .cf-assigned-to-cell {
          width: 100%;
        }
      }
      .cf-related-to-cell,
      .cf-related-to-cell > div,
      .cf-subject-cell > div,
      .cf-tooltip-container,
      .cf-cars-of-interest-cell > div,
      .cf-appointment > div {
        min-width: 80px;
      }
    }
  
    .cf-subject-cell p {
      @media screen and (min-width: ${px(ScreenWidthBreakpoints.Medium)}) {
        max-width: 208px;
      }
      @media screen and (min-width: ${px(ScreenWidthBreakpoints.Large)}) {
        max-width: 12vw;
      }
    }
    
    .cf-related-to-cell p {
      margin-left: 0;
  
      @media screen and (min-width: ${px(ScreenWidthBreakpoints.Medium)}) {
        max-width: 154px;
      }
    }
  
    .cf-cars-of-interest-cell a > p {
      @media screen and (min-width: ${px(ScreenWidthBreakpoints.Small)}) {
        max-width: 152px;
      }
      @media screen and (min-width: ${px(ScreenWidthBreakpoints.Medium)}) {
        max-width: 232px;
      }
      @media screen and (min-width: ${px(ScreenWidthBreakpoints.Large)}) {
        max-width: 12vw;
      }
    }
  
    .cf-assigned-to-cell p {
      @media screen and (min-width: ${px(ScreenWidthBreakpoints.Medium)}) {
        max-width: 144px;
      }
      @media screen and (min-width: ${px(ScreenWidthBreakpoints.Large)}) {
        max-width: 8vw;
      }
    }
  }
`)

export const POPOVER_CLASS_NAME = css(`
  &.cf-popover-content {
    min-width: 248px;
    left: 78px !important;
  }

  .cf-dropdown-listbox { border-radius: 8px; }

  .cf-options-divider { margin: 8px 0; }

  .cf-dropdown-option { 
    color: #101010;

    .counter { 
      display: inline-block;
      margin-left: 4px;
      color: rgba(33, 33, 33, 0.50);
    }
  }
`)
