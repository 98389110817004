import { css } from '@emotion/css'

export default css(`
  .cf-modal-content-scroll-wrapper {
    min-width: 100vw;

    .cf-modal-content {
      padding: 16px 40px;

      .cf-banner-appeared {
        &:after {
          content: '';
          display: block;
          height: 16px;
        }
      }
    }
  }

  .cf-delete-button.cf-button-root.cf-button-variant-text {
    grid-column: 1 / 3;
    text-decoration: none;

    .cf-button-content {
      font-weight: 500;
    }
  }

  .cf-button-content {
    font-size: 14px;
  }
  
  &.no-options {
    color: rgba(33, 33, 33, 0.40);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  &.popover-container {
    .cf-dropdown-listbox {
      max-height: 200px;
    }
  }
`)
