import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import type { DocumentFormDto } from 'api/types'
import TooltipedText from 'components/common/TooltipedText'

import CLASS_NAME from './style'

interface FormCellProps {
  getValue: () => DocumentFormDto
}

const FormCell: FC<FormCellProps> = ({
  getValue
}) => {
  const { name, description } = getValue()

  return (
    <div className={cnx('form-cell-container', CLASS_NAME)}>
      <div className='name-container'><TooltipedText value={name ?? ''} /></div>
      <div className='description-container'><TooltipedText value={description ?? ''} /></div>
    </div>
  )
}

export default FormCell
