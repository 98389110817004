import AccountingApiProvider from 'api/accounting.api'
import { namifyAccount, namifyControl } from 'utils/accounting/namifyAccount'
import { type BaseListItem } from 'types'
import {
  type AccountListItem,
  type ControlListItem,
  type EntityListItem,
  type ListPayload,
  type ListResponse,
  type TransactionLineVendorDto,
  type VendorListPayload
} from 'api/types'

export const getControls = async (payload: ListPayload): Promise<ListResponse<ControlListItem>> => {
  try {
    const response = await AccountingApiProvider.getControls(payload)
    const items = response.items.map(namifyControl)
    return { items }
  } catch (err) {
    return { items: [] }
  }
}

export const getAccounts = async (payload: ListPayload): Promise<ListResponse<AccountListItem>> => {
  try {
    const response = await AccountingApiProvider.getAccounts(payload)
    const items = response.items.map(namifyAccount)
    return { items }
  } catch (err) {
    return { items: [] }
  }
}

export const getEntities = async (payload: ListPayload): Promise<ListResponse<EntityListItem>> => {
  try {
    const response = await AccountingApiProvider.getEntities(payload)
    return { items: response.items }
  } catch (err) {
    return { items: [] }
  }
}

export const getVendors = async (payload: VendorListPayload): Promise<ListResponse<TransactionLineVendorDto>> => {
  try {
    const response = await AccountingApiProvider.getVendors(payload)
    return { items: response.items }
  } catch (err) {
    return { items: [] }
  }
}

export const getRecurringIntervals = async (): Promise<ListResponse<BaseListItem>> => {
  try {
    const response = await AccountingApiProvider.getRecurringIntervals()
    return { items: response.items }
  } catch (err) {
    return { items: [] }
  }
}

export default {
  getAccounts,
  getControls,
  getEntities,
  getRecurringIntervals,
  getVendors
}
