import { type FC } from 'react'
import { observer } from 'mobx-react-lite'
import { FormTextArea } from '@carfluent/common'
import { type OnChangeEvent } from '@carfluent/common/dist/UI/Input/types'

import _isCheck from 'utils/accounting/isCheck'
import _isPayBill from 'utils/accounting/isPayBill'
import _isReceive from 'utils/accounting/isReceive'
import BankStatementMatches from 'components/accounting/BankStatementMatches'
import VendorDetails from 'pages/accounting/VendorDetails'
import CustomerDetails from 'pages/accounting/CustomerDetails'

import Banners from './components/Banners'
import BankingBannersInfo from './components/BankingBannersInfo'
import DealRecapButton from './components/DealRecapButton'
import TransactionSummary from './components/Summary'
import TransactionsTable from './components/Table'
import TransactionForm from './components/TransactionForm'
import OpenBalancesSection from './components/OpenBalancesSection'
import { type TransactionDetailsContainerProps } from './types'
import { getColumnWidths } from './constants'
import useTransactionForm from './hook'
import CLASS_NAME from './styles'

const TransactionDetailsContainer: FC<TransactionDetailsContainerProps> = ({
  bankingBannersInfoProps,
  bankStatementMatchesProps,
  dealRecapButtonProps,
  dictionaries,
  formProps,
  hasBankingLines,
  isAutoLineInBanking,
  isBankStatementMatch,
  isGeneratedTransaction,
  isReadonly,
  isSystemCreated,
  linesTableProps,
  onAddCustomer,
  onAddVendor,
  onViewNextTransaction,
  openBalancesProps,
  topBannersProps,
  transactionStateId,
  transactionSummaryProps,
  transactionTypeId
}) => {
  const isCheck = _isCheck(transactionTypeId)
  const isPayBill = _isPayBill(transactionTypeId)
  const isReceive = _isReceive(transactionTypeId)
  const isBankStatementMatchesVisible = isBankStatementMatch && (bankStatementMatchesProps.rows.length > 0)
  const isDealRecapVisible = isGeneratedTransaction || (dealRecapButtonProps.dealId != null)
  const isOpenBalancesVisible = !hasBankingLines && (isReceive || isCheck || isPayBill)

  const {
    errors,
    onBlur,
    onChange,
    setFieldError,
    touched,
    values
  } = formProps

  const {
    addCustomerProps,
    addVendorProps,
    entityActions,
    entityCellActions,
    onReceivableControlChange,
    onReceivableEntityChange,
    onVendorChange,
    vendorActions,
    onViewNextTransactionFromMatch
  } = useTransactionForm({
    isBankStatementMatch,
    onAddCustomer,
    onAddVendor,
    onCellChange: linesTableProps.onChange,
    onChange,
    onViewNextTransaction,
    setFieldError,
    tableData: linesTableProps.data,
    transactionTypeId,
    values
  })

  // ========================================== //

  return (
    <div className={CLASS_NAME}>
      <Banners
        {...topBannersProps}
        bankStatements={bankingBannersInfoProps.bankStatements}
        inventoryId={dealRecapButtonProps.inventoryId ?? formProps.values.costInventoryId ?? null}
        isGeneratedTransaction={isGeneratedTransaction}
        isLocked={values.isLocked ?? false}
        isReadonly={isReadonly}
        isSystemCreated={isSystemCreated}
        isVehiclePayment={formProps.values.isVehiclePayment}
        isVehicleCost={formProps.values.isVehicleCost}
        lockedDate={values.dateTime ?? null}
        tableData={linesTableProps.data}
        transactionStateId={transactionStateId}
      />

      <div className='form-container'>
        <TransactionForm
          {...formProps}
          {...dictionaries}
          entityActions={entityActions}
          isAutoLineInBanking={isAutoLineInBanking}
          isBankingTransaction={hasBankingLines}
          isBankStatementMatch={isBankStatementMatch}
          isReadonly={isReadonly}
          onReceivableControlChange={onReceivableControlChange}
          onReceivableEntityChange={onReceivableEntityChange}
          onVendorChange={onVendorChange}
          vendorActions={vendorActions}
          transactionTypeId={transactionTypeId}
        />

        {(isDealRecapVisible && !hasBankingLines) && (
          <DealRecapButton {...dealRecapButtonProps} className='deal-recap-container' />
        )}

        {hasBankingLines && (
          <div className='banking-recap-container'>
            <BankingBannersInfo {...bankingBannersInfoProps} className='banking-banners-info-container' />
            {isDealRecapVisible && (
              <DealRecapButton {...dealRecapButtonProps} className='deal-recap-container' />
            )}
          </div>
        )}
      </div>

      <TransactionsTable
        {...dictionaries}
        {...linesTableProps}
        dataTestId='tx-table-section'
        disabled={isReadonly}
        entityActions={entityCellActions}
        getWidths={getColumnWidths}
        hasBankingLines={hasBankingLines}
        transactionTypeId={transactionTypeId}
      />

      <TransactionSummary
        {...transactionSummaryProps}
        amount={values.amount}
        getWidths={getColumnWidths}
        isBankStatementMatch={isBankStatementMatch}
        tableData={linesTableProps.data}
        transactionTypeId={transactionTypeId}
      >
        <FormTextArea
          id='memo'
          error={errors.memo}
          touched={touched.memo}
          placeholder='Memo'
          onBlur={onBlur}
          onChange={onChange}
          maxLength={240}
          maxRows={3}
          value={values.memo ?? ''}
          className='field-textarea'
          disabled={values.isLocked || isReadonly}
        />
      </TransactionSummary>

      {isBankStatementMatchesVisible && (
        <BankStatementMatches
          {...bankStatementMatchesProps}
          className='bank-statement-matches-container'
          mode='JE'
          onViewTransaction={onViewNextTransactionFromMatch}
        />
      )}

      {isOpenBalancesVisible && (
        <OpenBalancesSection
          {...openBalancesProps}
          id='receive-popup'
          isPayables={isCheck || isPayBill}
          onOpenTransaction={onViewNextTransaction}
          transactionTypeId={transactionTypeId}
        />
      )}

      <VendorDetails {...addVendorProps} />
      <CustomerDetails {...addCustomerProps} />
    </div>
  )
}

export default observer(TransactionDetailsContainer)
