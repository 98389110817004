import { LeadLostReason } from 'api/types'

export const leadLostReasonMap: Record<LeadLostReason, string> = {
  [LeadLostReason.DecidedToWait]: 'Decided to wait',
  [LeadLostReason.BadCredit]: 'Bad credit',
  [LeadLostReason.NegativeEquity]: 'Negative equity',
  [LeadLostReason.VehicleUnavailable]: 'Vehicle unavailable',
  [LeadLostReason.NoResponse]: 'No response',
  [LeadLostReason.NothingCanBeDone]: 'Nothing can be done',
  [LeadLostReason.BoughtElsewhere]: 'Bought elsewhere',
  [LeadLostReason.BadInformation]: 'Bad information',
  [LeadLostReason.TradeValue]: 'Trade value',
  [LeadLostReason.MonthlyPayment]: 'Monthly payment',
  [LeadLostReason.NoPriceCompromise]: 'No price compromise',
  [LeadLostReason.IssueWithVehicle]: 'Issue with vehicle',
  [LeadLostReason.DifferentCustomerPurchased]: 'Different customer purchased',
  [LeadLostReason.Other]: 'Other',
  [LeadLostReason.NoLongerInterested]: 'No longer interested',
  [LeadLostReason.UnsubscribedFromMarketingMaterials]: 'Unsubscribed from marketing materials.'
}
