import { css } from '@emotion/css'

export default css(`
  .search-block {
    width: 420px;
    margin-bottom: 24px;
  }
`)

export const TABLE_WRAPPER_CLASS_NAME = css(`
  padding: 8px;
  border-radius: 4px!important;
  background-color: #F3F3F3!important;

  .form-fields-class-name {
    max-width: 740px;
    
    :not(:first-child, :last-child) > div {
      padding: 0;
      width: 100%;
      padding-left: 0px;
    }

    .form-cell-container {
      padding-left: 8px!important;
    }  
  }

  .checkbox-class-name {
    max-width: 48px;

    > div {
      align-items: center;
      padding-left: 0!important;

      > span {
        padding: 0px;
        height: 24px;
      }
    }
  }

  .actions-class-name {
    max-width: 68px;

    > div {
      max-width: 100%;
      padding-right: 0!important;
    }
  }

  tbody tr td > div  {
    padding: 0;
    display: flex;
    justify-content: center;
  }

  .cf-header-cell-title {
    color: #101010;
  }
`)
