import {
  type MouseEvent,
  useState,
  useCallback,
  useEffect
} from 'react'

import type {
  UseEmailThreadModalProps,
  UseEmailThreadModalReturn
} from './types'

const useEmailThreadModal = ({
  emailList,
  isEmailThreadModalOpen,
  leadId,
  leadEmail,
  subject
}: UseEmailThreadModalProps): UseEmailThreadModalReturn => {
  const [responseEmailIndex, setResponseEmailIndex] = useState<number | null>(null)
  const [isActiveReplyBtnMode, setIsActiveReplyBtnMode] = useState<boolean>(false)
  const [replyToEmailId, setReplyToEmailId] = useState<string | number>()

  const lastEmailListIndex = emailList != null ? emailList?.length - 1 : 0
  const lastEmailListId = emailList?.[lastEmailListIndex].id
  const isResponseEmailActive = responseEmailIndex !== null

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onClickSetReplyBtnMode = useCallback(() => {
    setResponseEmailIndex(lastEmailListIndex)
    setIsActiveReplyBtnMode(true)

    if (lastEmailListId != null) {
      return setReplyToEmailId(lastEmailListId)
    }
  }, [lastEmailListId, lastEmailListIndex])

  const onCloseResponseEmail = useCallback(() => {
    setResponseEmailIndex(null)
    setIsActiveReplyBtnMode(false)
  }, [])

  const onClickSetIsResponseEmail = useCallback((e: MouseEvent<HTMLDivElement>, emailIndex: number, emailId: string | number) => {
    e.stopPropagation()
    setResponseEmailIndex(emailIndex)
    setReplyToEmailId(emailId)
  }, [])

  const onCheckReplyButtonMode = useCallback(() => {
    responseEmailIndex === lastEmailListIndex
      ? setIsActiveReplyBtnMode(true)
      : setIsActiveReplyBtnMode(false)
  }, [lastEmailListIndex, responseEmailIndex])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (!isEmailThreadModalOpen) {
      setResponseEmailIndex(null)
      setIsActiveReplyBtnMode(false)
    }
  }, [isEmailThreadModalOpen])

  useEffect(() => {
    onCheckReplyButtonMode()
  }, [onCheckReplyButtonMode])

  return {
    lastEmailListIndex,
    responseEmailIndex,
    onClickSetIsResponseEmail,
    replyBtnProps: {
      isActiveReplyBtnMode,
      onClickSetReplyBtnMode
    },
    emailThreadFormProps: {
      isResponseEmailActive,
      onCloseResponseEmail,
      leadEmail,
      leadId,
      subject,
      replyToEmailId
    }
  }
}

export default useEmailThreadModal
