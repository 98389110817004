import type { FC } from 'react'
import { type OpenBalancesList } from 'api/types'
import OpenBalancesTable, { type OpenBalancesTableProps } from 'components/accounting/OpenBalancesTable'

import CLASS_NAME from './styles'

export interface OpenBalancesSectionProps extends OpenBalancesTableProps {
  openBalancesData: OpenBalancesList | null
  transactionTypeId: number | null
}

const OpenBalancesSection: FC<OpenBalancesSectionProps> = ({
  openBalancesData,
  transactionTypeId,
  ...tableProps
}) => {
  return (
    <div className={CLASS_NAME}>
      <p className='cf-open-balances-counter'>Open balances ({openBalancesData?.items.length ?? 0})</p>
      <OpenBalancesTable {...tableProps} />
    </div>
  )
}

export default OpenBalancesSection
