import { css } from '@emotion/css'

export default css(`
  width: 50%;
  overflow: hidden;

  .list-title {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .list {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    // max-height: 420px;
    max-height: 380px;

    .item {
      display: flex;
      width: 100%;
      background: #F3F3F3;
      border-radius: 2px;
      padding: 10px 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      justify-content: space-between;
      text-transform: capitalize;

      &-name {
        flex: 2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
      }
      &-price {
        flex: 1;
        text-align: right;
      }

      &.active {
        background: #FFFFFF;
        border: 1px solid #C99B86;
        border-radius: 2px;
        padding: 9px 15px;
      }
      
      &:hover {
        background: #FAFAFA;
        cursor: pointer;
      }
    }
  }

  .cf-skeleton-left-part {
    width: calc(75% - 48px);
    padding-right: 48px;
  }

  .cf-skeleton-right-part {
    width: 25%;
  }

  *::-webkit-scrollbar-thumb {
    background: rgb(16 16 16 / 20%);
    border-radius: 3px;
  }
`)
