import { type ColumnDef } from '@carfluent/common'
import { type Row } from '@tanstack/react-table'

import { TransactionStateId } from 'api/types'
import { toDateOrNull } from 'utils/parse_date'
import isClearedTransaction from 'utils/accounting/isClearedTransaction'
import IconSVG from 'components/inlineImages'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import ValueCell, { ValueCellHOC } from 'components/common/Table/cells/ValueCell'
import ReconcileStatusCell from 'components/common/Table/cells/ReconcileStatusCell'
import { DateCellHOC } from 'components/common/Table/cells/DateCell'
import { AmountCellHOC } from 'components/common/Table/cells/AmountCell'

import ControlCell from '../components/cells/ControlCell'
import type { AccountTransactionRow } from './types'

export interface ColumnsConfig {
  showStatusColumn: boolean
}

const getColumnDefinitions = ({
  showStatusColumn
}: ColumnsConfig): Array<ColumnDef<AccountTransactionRow>> => ([
  {
    id: 'date',
    accessorFn: (row: AccountTransactionRow) => toDateOrNull(row.date),
    cell: DateCellHOC({ format: 'MM/dd/yy' }),
    header: TextCellHOC({ text: 'Date' }),
    size: 110
  },
  {
    accessorFn: (row: AccountTransactionRow) => row.account,
    id: 'account',
    cell: ValueCellHOC({
      className: 'cell-account',
      renderStartAdornment: ({ row }: { row: Row<AccountTransactionRow> }) => {
        return isClearedTransaction(row.original.reconcileStatus)
          ? <IconSVG.Bank />
          : null
      }
    }),
    header: TextCellHOC({ text: 'Account' }),
    size: 332,
    sortable: false
  },
  {
    accessorKey: 'controlId',
    id: 'control',
    cell: ControlCell,
    header: TextCellHOC({ text: 'Control' }),
    size: 200
  },
  {
    accessorFn: (row: AccountTransactionRow) => row.notes ?? '',
    id: 'notes',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Note' }),
    size: 284,
    sortable: false
  },
  {
    id: 'reconcileStatus',
    accessorFn: (row: AccountTransactionRow, index: number) => ({
      id: row.id,
      rowIdx: index,
      status: row.reconcileStatus
    }),
    cell: ReconcileStatusCell,
    header: TextCellHOC({ text: 'Status' }),
    size: 100,
    sortable: false
  },
  {
    id: 'debit',
    accessorFn: ({ credit, debit, transactionStateId }: AccountTransactionRow) => {
      if (transactionStateId === TransactionStateId.Filled) {
        if (debit === 0) {
          return credit === 0 ? 0 : null
        }

        return debit
      } else {
        return null
      }
    },
    cell: AmountCellHOC({ isAlignedRight: true }),
    header: TextCellHOC({ text: 'Debit', className: 'aligned-right' }),
    size: 160
  },
  {
    id: 'credit',
    accessorFn: ({ credit, debit, transactionStateId }: AccountTransactionRow) => {
      if (transactionStateId === TransactionStateId.Filled) {
        if (credit === 0) {
          return debit === 0 ? 0 : null
        }

        return credit
      } else if (transactionStateId === TransactionStateId.Refunded) {
        return credit
      } else {
        return null
      }
    },
    cell: AmountCellHOC({ isAlignedRight: true }),
    header: TextCellHOC({ text: 'Credit', className: 'aligned-right' }),
    size: 160
  },
  {
    accessorKey: 'balance',
    cell: AmountCellHOC({ isAlignedRight: true }),
    header: TextCellHOC({ text: 'Balance' }),
    size: 160,
    sortable: false
  }
].filter((col) => {
  return (col.id !== 'reconcileStatus') || showStatusColumn
}) as Array<ColumnDef<AccountTransactionRow>>)

export default getColumnDefinitions
