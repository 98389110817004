import { RecipientsFilter, RecipientsFilterTitles } from 'api/types/responses'

export const FILTER_OPTIONS = [
  {
    id: RecipientsFilter.Excluded,
    name: RecipientsFilterTitles.Exclude
  },
  {
    id: RecipientsFilter.Included,
    name: RecipientsFilterTitles.Include
  }
]
