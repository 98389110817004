import { parsers } from '@carfluent/common'
import type { DictionaryItems, TitleReportItemGetDto } from 'api/types'

import { formatDate } from 'utils/parse_date'
import type { RegistrationStage, TitleStage } from 'types/enums'

import type { TitleReportItem } from './types'

const { parseDateStringUTC } = parsers
const DATE_PART = 'MM/dd/yyyy'
const EMPTY_DATE_VALUE = '-'

const parseListData = (
  data: TitleReportItemGetDto[],
  titleStages: DictionaryItems<TitleStage> | null,
  registrationStages: DictionaryItems<RegistrationStage> | null): TitleReportItem[] => {
  return data.map(({
    stock,
    vin,
    inventoryDate,
    vehicleCustomerFullName,
    source,
    daysPendingRegistration,
    make,
    model,
    year,
    titleStageId,
    registrationStageId,
    vehicleId,
    age,
    soldDealId,
    titleNote
  }: TitleReportItemGetDto): TitleReportItem => {
    const parsedDate = parseDateStringUTC(inventoryDate)

    return {
      make: {
        make: make,
        path: `/vehicles/${vehicleId}`,
        title: `${year} ${make} ${model}`
      },
      stock,
      vin,
      titleStage: titleStages?.find(el => el.id === titleStageId)?.name ?? '-',
      registrationStage: registrationStages?.find(el => el.id === registrationStageId)?.name ?? '-',
      soldTo: vehicleCustomerFullName != null && soldDealId != null
        ? {
            path: `/deals/${soldDealId}`,
            title: vehicleCustomerFullName
          }
        : null,
      source: source !== '' ? source : null,
      inventoryDate: parsedDate != null
        ? formatDate(parsedDate, DATE_PART)
        : EMPTY_DATE_VALUE,
      age,
      daysPendingRegistration,
      titleNote
    }
  })
}

export default parseListData
