import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import AccountingHOK from 'pages/accounting/AccountingHOC'
import StatementUploadForm from './components/StatementUploadForm'
import StatementPreviewForm from './components/StatementPreviewForm'
import useBankStatementUpload from './hook'
import CLASS_NAME from './styles'

const BankStatementUpload: FC = () => {
  const {
    onSubmitBankStatement,
    onBack,
    uploadedBankStatement
  } = useBankStatementUpload()

  const isBankStatementUploaded = uploadedBankStatement != null

  return (
    <div className={cnx(CLASS_NAME, 'g-margin-top-big')}>
      <div className={cnx('g-single-form-content', isBankStatementUploaded && 'no-bottom-padding')}>
        {!isBankStatementUploaded && (
          <StatementUploadForm onSubmitBankStatement={onSubmitBankStatement} />
        )}

        {isBankStatementUploaded && (
          <StatementPreviewForm onBack={onBack} {...uploadedBankStatement} />
        )}
      </div>
    </div>
  )
}

export default AccountingHOK(BankStatementUpload)
