import { UI } from '@carfluent/common'
import type { RecurringEntriesListFilters } from './types'

export const DEFAULT_SORTING: UI.SortingInfo = { id: 'id', order: 'desc' }
export const DEFAULT_FILTERS: RecurringEntriesListFilters = {}

export const API_CALL_DELAY_SEARCH = 3000

export enum Messages {
  emptyTableState = 'No recurring entries',
  nothingFoundState = 'No entries found'
}
