import { type DealerProductDeductibleDto } from 'api/types/responses'

export const DEDUCTIBLE_OPTIONS: DealerProductDeductibleDto[] = [
  {
    id: 1,
    amount: 0,
    dealerCost: 0,
    dealerRetailPrice: 0,
    description: '$0 Deductible'
  }, {
    id: 2,
    amount: 100,
    dealerCost: 100,
    dealerRetailPrice: 100,
    description: '$100 Deductible'
  }, {
    id: 3,
    amount: 200,
    dealerCost: 200,
    dealerRetailPrice: 200,
    description: '$200 Deductible'
  }, {
    id: 1,
    amount: 250,
    dealerCost: 250,
    dealerRetailPrice: 250,
    description: '$250 Deductible'
  }
]
