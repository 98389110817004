import { type FC } from 'react'
import { cnx, CellWrapper } from '@carfluent/common'
import CLASS_NAME from './styles'

interface DescriptionCellProps {
  getValue: () => {
    text: string
    title: string | null
  }
}

const DescriptionCell: FC<DescriptionCellProps> = ({ getValue }) => {
  const { text, title } = getValue()
  
  return (
    <CellWrapper className={cnx(CLASS_NAME, 'cf-cell-wrapper-container')}>
      <span className='cf-costs-details-description-cell-title'>{title ?? '-'}</span>
      <span className='cf-costs-details-description-cell-text'>{text ?? '-'}</span>
    </CellWrapper>
  )
}

export default DescriptionCell
