import { type FC, type ReactNode, useCallback, useMemo, useState } from 'react'
import { useDropzone, ErrorCode, type FileRejection } from 'react-dropzone'
import { Chip } from '@material-ui/core'
import { cn, Button } from '@carfluent/common'

import delete_icon from 'assets/delete_icon.svg'
import CLASS_NAME from './styles'

export const KiB = 1024 // https://en.wikipedia.org/wiki/Byte#Multiple-byte_units
export const kb = (size: number): number => size * KiB
export const mb = (size: number): number => size * KiB * KiB
export const DEFAULT_FILE_MAX_SIZE = mb(15)
export const DEFAULT_MAX_FILES_NUM = 100
export const DEFAULT_ACCEPT = { 'image/png': [], 'image/jpeg': [], 'image/jpg': [] }

export const DEFAULT_TITLES = {
  button: 'Upload media',
  panel: 'Drag&drop, or'
}

export interface DropzoneProps {
  accept?: Record<string, string[]>
  children?: ReactNode | ReactNode[]
  disabled?: boolean
  error?: string | null
  ErrorMessages?: Partial<Record<keyof typeof ErrorCode, string>>
  files?: File[]
  maxFiles?: number
  maxSize?: number
  multiple?: boolean
  onAddFiles?: (data: File[]) => void
  onRemoveFileTag?: (file: File) => void
  showUploadPanel?: boolean
  showFileTags?: boolean
  titleButton?: string
  titlePanel?: string
}

export const Dropzone: FC<DropzoneProps> = (props) => {
  const {
    accept = DEFAULT_ACCEPT,
    children,
    disabled = false,
    error: _error,
    ErrorMessages: _ErrorMessages,
    files = [],
    multiple = true,
    maxFiles = multiple ? DEFAULT_MAX_FILES_NUM : 1,
    maxSize = DEFAULT_FILE_MAX_SIZE,
    onAddFiles,
    onRemoveFileTag,
    showUploadPanel = true,
    showFileTags = false,
    titleButton = DEFAULT_TITLES.button,
    titlePanel = DEFAULT_TITLES.panel
  } = props

  const ErrorMessages = useMemo<Record<string, string>>(() => ({
    [ErrorCode.FileTooLarge]: (_ErrorMessages?.FileTooLarge !== undefined)
      ? _ErrorMessages?.FileTooLarge
      : `File size cannot exceed ${getSizeStr(maxSize)}`,

    [ErrorCode.FileInvalidType]: (_ErrorMessages?.FileInvalidType !== undefined)
      ? _ErrorMessages?.FileInvalidType
      : 'Should be png, jpg or jpeg format',

    [ErrorCode.TooManyFiles]: (_ErrorMessages?.TooManyFiles !== undefined)
      ? _ErrorMessages?.TooManyFiles
      : `Total amount of uploaded files can't exceed ${maxFiles}`,

    [ErrorCode.FileTooSmall]: (_ErrorMessages?.FileTooSmall !== undefined)
      ? _ErrorMessages?.FileTooSmall
      : ''
  }), [maxSize, maxFiles, _ErrorMessages])

  const [error, setError] = useState<string | null>(null)
  const resolvedError = _error ?? error
  const hasError = Boolean(resolvedError)
  const isDisabled = (maxFiles === 0) || disabled

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    setError(null)

    if (acceptedFiles.length > 0) {
      onAddFiles?.(acceptedFiles)
    }

    const errorCode = rejectedFiles[0]?.errors[0].code
    if (errorCode != null) {
      setError(ErrorMessages[errorCode])
      console.error(rejectedFiles)
      console.error(rejectedFiles[0]?.errors[0].code)
    }
  }, [onAddFiles])

  const {
    getRootProps, getInputProps, open,
    isDragActive
  } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    maxSize,
    disabled: isDisabled,
    multiple,
    noClick: true
  })
  const { onDragEnter, onDragLeave, ...rootProps } = getRootProps()

  return (
    <div
      className={CLASS_NAME}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDragLeave}
    >
      <div
        {...rootProps}
        className={cn('cf-dropzone', hasError && 'cf-dropzone-error')}
      >
        <input {...getInputProps()} />

        {isDragActive && (
          <>
            <div className='cf-dropzone-drop-overlay' />
            <p className='cf-dropzone-text'>Drag files here</p>
          </>
        )}

        {!isDragActive && showUploadPanel && (
          <>
            <div className={cn('cf-dropzone-block-button', hasError && 'cf-dropzone-error-btn-block')}>
              <p className='cf-dropzone-text'>
                {titlePanel}
              </p>

              <Button
                variant='outlined'
                color='secondary'
                className='cf-dropzone-btn-upload'
                onClick={open}
                isDisabled={isDisabled}
              >
                {titleButton}
              </Button>
            </div>

            {hasError && (
              <div className='cf-dropzone-block-error'>
                <p className='cf-dropzone-text-error'>{resolvedError}</p>
              </div>
            )}
          </>
        )}

        {!isDragActive && showFileTags && (
          <div>
            {files.map((file) => {
              return (
                <Chip
                  classes={{
                    root: 'cf-dropzone-chip-root',
                    deleteIcon: 'cf-dropzone-chip-delete-icon',
                    label: 'cf-dropzone-chip-label'
                  }}
                  deleteIcon={<img alt='X' src={delete_icon} />}
                  disabled={isDisabled}
                  key={file.name}
                  label={file.name}
                  onDelete={() => { onRemoveFileTag?.(file) }}
                />
              )
            })}
          </div>
        )}
      </div>

      {children}
    </div>
  )
}

// ========================================== //

export const getSizeStr = (maxSize: number): string => {
  const kbSize = maxSize / KiB
  const mbSize = kbSize / KiB
  const isMb = mbSize > 0

  return `${isMb ? mbSize : kbSize}${isMb ? 'MB' : 'KB'}`
}
