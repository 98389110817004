interface CorrectionsOptions {
  upperBound: number
  offset: number
}

const corrections: CorrectionsOptions[] = [
  { upperBound: 9, offset: 48 },
  { upperBound: 35, offset: 55 },
  { upperBound: 61, offset: 61 }
]

/**
 * @function generateId
 * @summary Generate sequence of random characters (alpha-numeric, [A-z0-9]).
 * @param {number} [length=40] - Length of characters.
 * @param {func} [random=Math.random] - Function to use for RNG
 * @returns {string} - Sequence of random characters.
 */

export function generateId (length = 40, random = Math.random): string {
  const randomCharArray = new Array(length).fill(0).map(() => {
    const index = Math.floor(random() * 62)
    const { offset } = corrections.find(({ upperBound }) => index <= upperBound) as CorrectionsOptions
    const randomCharCode = index + offset

    return String.fromCharCode(randomCharCode)
  })

  return randomCharArray.join('')
}
