import { type ReactNode } from 'react'
import { cnx } from '@carfluent/common'

import CLASS_NAME from './styles'

export interface StatusBadgeProps<T extends string | number = string> {
  bgColor?: string
  className?: string
  color?: string
  getStatusInfo: GetStatusInfo<T>
  message?: ReactNode
  status: T
}

export type GetStatusInfo<T> = (val: T) => { color: string, bgColor: string, message: string }

export function StatusBadge <T extends string | number> ({
  bgColor: _bgColor = 'white',
  className,
  color: _color = 'black',
  getStatusInfo,
  message: _message = '',
  status
}: StatusBadgeProps<T>): JSX.Element {
  const { bgColor, color, message } = getStatusInfo(status)

  return (
    <span
      style={{ color, backgroundColor: bgColor }}
      className={cnx(CLASS_NAME, `status-${status}`, className)}
    >
      {message ?? message}
    </span>
  )
}

const StatusBadgeHOC = <T extends string | number>(getStatusInfo: GetStatusInfo<T>) => {
  return (props: Omit<StatusBadgeProps<T>, 'getStatusInfo'>) => {
    return (
      <StatusBadge {...props} getStatusInfo={getStatusInfo} />
    )
  }
}

export default StatusBadgeHOC
