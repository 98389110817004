import ValueCell from 'components/common/Table/cells/ValueCell'
import TextCellHOC from 'components/common/Table/cells/TextCell'

import type { ColumnDefinitions } from './types'

const columnDefinitions: ColumnDefinitions = [
  {
    accessorKey: 'name',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Name' }),
    size: 320
  },
  {
    accessorKey: 'description',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Description' }),
    size: 640,
    sortable: false
  }
]

export default columnDefinitions
