import { css } from '@emotion/css'

export default css(`
  button span {
    font-size: 14px;
    letter-spacing: 0.4px;
  }

  .cf-switch-radio-group {
    display: flex;
    
    .cf-radio-button {
      :first-child {
        margin-right: 32px;
      }
    }
  }

  .cf-button-apply.cf-button-root.cf-button-variant-text {
    justify-self: flex-start;
    align-self: center;
  }

  .cf-vin-wrapper {
    grid-template-columns: 39.3% 18%;
    justify-content: space-between;

    .cf-vin-input {
      margin-bottom: 8px;
    }
    
    .cf-state-panel {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    
    .cf-sold-car {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 164px;
      opacity: 0.5;
  
      img {
       margin-right: 6px; 
      }
    }
  }
`)
