import { type FC } from 'react'
import { Button, FormInput } from '@carfluent/common'

import useResetPassword from './hook'
import CLASS_NAME from '../../styles'

const ResetPasswordEmail: FC = () => {
  const {
    isSubmitting,
    values,
    errors,
    touched,
    onSubmit,
    onChange,
    onBlur
  } = useResetPassword()

  return (
    <div className={CLASS_NAME}>
      <p className='title-wrapper'>
        Password reset
      </p>
      <p className='text'>
        We will send you a link to set a new password
      </p>

      <FormInput
        id='email'
        label='Email'
        onChange={onChange}
        onBlur={onBlur}
        value={values.email}
        error={errors.email}
        touched={touched.email}
      />

      <Button
        className='submit-btn'
        dataTestId='btn-get-a-link'
        isLoading={isSubmitting}
        onClick={() => { void onSubmit() }}
      >
        Get a link
      </Button>
    </div>
  )
}

export default ResetPasswordEmail
