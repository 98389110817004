import { type RefObject, useCallback, useState, useEffect } from 'react'
import type { SortingInfo } from '@carfluent/common'
import type {
  ListPayload,
  ListResponse,
  ReportCarRepricingItemDto
} from 'api/types'

import type { FiltersPanelProps } from 'components/common/FiltersPanel'
import type { UseTableApiReturn } from 'hooks/useTableApi/types'
import useTableApi from 'hooks/useTableApi'
import VehiclesApiProvider from 'api/vehicles.api'
import { convertSortingOrder, downloadBlob } from 'utils/general'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

export const API_CALL_DELAY_SEARCH = 3000

type PickedProps = 'rows' | 'isLoading' | 'sorting' | 'setSorting'

interface UseCarRepricingReturn extends Pick<UseTableApiReturn<ReportCarRepricingItemDto, {}>, PickedProps>{
  onExportExcel: () => Promise<void>
  searchProps: FiltersPanelProps['searchProps']
  scrollableRef: RefObject<HTMLElement> | null
}

const DEFAULT_FILTERS = {}
const DEFAULT_SORTING: SortingInfo = { id: 'lastRepricingDate', order: 'asc' }

export const useCarRepricing = (): UseCarRepricingReturn => {
  const { showAlert } = useCustomSnackbar()
  const [scrollableRef, setScrollableRef] = useState<RefObject<HTMLElement> | null>(null)
  const getList = useCallback(async ({
    search,
    sortField,
    sortOrder
  }: ListPayload): Promise<ListResponse<ReportCarRepricingItemDto>> => {
    return await VehiclesApiProvider.getCarRepricingReports({
      search,
      sortField,
      sortOrder
    })
  }, [])

  const {
    rows,
    search,
    isLoading,
    onSearch,
    setSearch,
    setSorting,
    sorting
  } = useTableApi<ReportCarRepricingItemDto, {}>({
    shouldRunOnCall: true,
    defaultFilters: DEFAULT_FILTERS,
    defaultSorting: DEFAULT_SORTING,
    getList,
    apiCallDelay: API_CALL_DELAY_SEARCH
  })

  const onExportExcel = async (): Promise<void> => {
    try {
      const response = await VehiclesApiProvider.downloadCarRepricingReportXlsx({
        sortOrder: convertSortingOrder(sorting.order),
        sortField: sorting.id,
        search
      })

      downloadBlob(response, 'CarRepricingReport.xlsx')
    } catch (e) {
      showAlert(e)
    }
  }

  useEffect(() => {
    setScrollableRef({
      current: document.getElementById('page-overlay') ?? null
    })
  }, [])

  return {
    onExportExcel,
    rows,
    isLoading,
    setSorting,
    sorting,
    scrollableRef,
    searchProps: {
      value: search ?? '',
      onSearch,
      disabled: isLoading,
      isLoading,
      onChange: setSearch,
      placeholder: 'Search by make, model, stock #'
    }
  }
}
