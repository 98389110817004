import { type FC, useEffect, useState, useRef } from 'react'

import { PopoverV2, type PopoverPropsV2, parsers, formatCurrencyDecimal } from '@carfluent/common'

import CustomersCoreApiProvider from 'api/customersCore.api'
import type { PurchasingDetailsDto } from 'api/types/customersCore.types'
import { formatDate as _formatDate } from 'utils/parse_date'
import CalcRoutes from 'constants/route_helper'
import TooltipedText from 'components/common/TooltipedText'

import IconSVG from 'components/inlineImages'

import { POPOVER_CLASS_NAME } from './styles'

const { parseDateStringUTC } = parsers

export const POPOVER_OPTIONS: PopoverPropsV2['options'] = {
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 8]
      }
    }
  ]
}

const EMPTY_DATE_VALUE = '-'

const formatDate = (date: string): string => {
  const parsedDate = parseDateStringUTC(date)

  return parsedDate != null
    ? _formatDate(parsedDate, 'MM/dd/yy hh:mm aa') ?? EMPTY_DATE_VALUE
    : EMPTY_DATE_VALUE
}

interface CarStatusProps {
  id: number
}

const CarStatus: FC<CarStatusProps> = ({ id }) => {
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [details, setDetails] = useState<PurchasingDetailsDto | null>(null)

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      const res = await CustomersCoreApiProvider.getPurchasingDetails(id)
      setDetails(res)
    }

    void runEffect()
  }, [id])

  if (details == null || (details.soldDate == null && details.depositDetails.length === 0)) {
    return null
  }

  const items = (
    details.soldDate != null &&
    details.soldDealId != null &&
    details.soldToUserName != null &&
    details.salePrice != null
  )
    ? ([{
        amount: details.salePrice,
        dealId: details.soldDealId,
        createdDate: details.soldDate,
        userName: details.soldToUserName
      }])
    : details.depositDetails

  const title = details.soldDate != null
    ? 'Sold to'
    : 'Deposit by'

  return (
    <>
      <div
        onClick={() => setIsOpen(!isOpen)}
        ref={anchorEl}
        className='label'
      >
        <div className='label-right-side'>
          <span>
            {title}
          </span>
          <a
            href={CalcRoutes.DealView(items[0].dealId)}
            onClick={(e) => e.stopPropagation()}
          >
            <TooltipedText
              value={items[0]?.userName}
            />
          </a>

          <a
            href={CalcRoutes.DealView(items[0].dealId)}
            onClick={(e) => e.stopPropagation()}
          >
            | Deal {items[0]?.dealId}
          </a>
        </div>

        {items.length > 1 && (
          <span>
            +{items.length - 1}
          </span>
        )}

        <IconSVG.DropdownArrowDown />
      </div>

      <PopoverV2
        isOpen={isOpen}
        anchorEl={anchorEl}
        className={POPOVER_CLASS_NAME}
        options={POPOVER_OPTIONS}
        onClose={() => setIsOpen(false)}
      >
        <div className='table-row header'>
          <p>Amount</p>
          <p>When</p>
          <p>{title}</p>
        </div>

        {items.map(({ createdDate, userName, amount, dealId }) => {
          return (
            <div className='table-row' key={createdDate}>
              <p>{formatCurrencyDecimal(amount)}</p>
              <p>{formatDate(createdDate)}</p>
              <p>
                <a href={CalcRoutes.DealView(dealId)}>
                  {userName} | Deal {dealId}
                </a>
              </p>
            </div>
          )
        })}
      </PopoverV2>
    </>
  )
}

export default CarStatus
