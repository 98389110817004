import { type FC } from 'react'
import { PRESET_LABELS } from '@carfluent/common'

import Table from 'components/common/Table'
import BackToTop from 'components/common/BackToTop'
import ReportLayout from 'components/reports/ReportLayout'
import FiltersPanel, { type FilterItem } from 'components/common/FiltersPanel'

import { asyncNoop } from 'constants/constants'

import { GROUP_ROW_FIXED_COL_SPAN, LOADING_ROWS } from './constants'
import useReportLeadsByVehicle from './hook'
import columnDefinitions from './hook/columns'
import GroupContent from './components/GroupContent'

import CLASS_NAME, { ROOT_CLASS_NAME } from './styles'

const ReportLeadsByVehiclesList: FC = () => {
  const {
    containerElement,
    rows,
    search,
    sorting,
    groupBy,
    presets,
    onSearch,
    isLoading,
    onExportExcel,
    onSearchChange,
    onBottomReached,
    onSortingChange,
    emptyTableMessage,
    onDateFilterChange
  } = useReportLeadsByVehicle()

  const searchProps = {
    value: search ?? '',
    onSearch,
    disabled: isLoading,
    isLoading,
    onChange: onSearchChange,
    placeholder: 'Make, model, stock #'
  }

  const dateFilters: FilterItem<'date'> = {
    filterType: 'date',
    componentProps: {
      presets,
      onFilterChange: onDateFilterChange,
      defaultPresetName: PRESET_LABELS.LAST_30_DAYS,
      monthsNumber: 2
    }
  }

  return (
    <ReportLayout
      size='md'
      className=''
      title='Leads by vehicle'
      withChildOutsideContainer
      isDateFilterEnabled={false}
      onExportExcel={onExportExcel}
      rootClassName={ROOT_CLASS_NAME}
      onChangeDatesFilter={asyncNoop}
    >
      <div className={CLASS_NAME}>
        <FiltersPanel
          componentBackground='gray'
          searchProps={searchProps}
          filterProps={{ filters: [dateFilters] }}
        />

        <Table
          containerElement={containerElement}
          data={rows}
          withFixedCell
          sorting={sorting}
          groupBy={groupBy}
          isLoading={isLoading}
          loadingRows={LOADING_ROWS}
          renderGroup={GroupContent}
          columns={columnDefinitions}
          onBottomReached={onBottomReached}
          onSortingChange={onSortingChange}
          emptyTableMessage={emptyTableMessage}
          groupColSpan={GROUP_ROW_FIXED_COL_SPAN}
        />
        <BackToTop />
      </div>
    </ReportLayout>

  )
}

export default ReportLeadsByVehiclesList
