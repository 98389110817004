import { type FC } from 'react'

const IconPlay: FC = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='32' height='32' rx='16' fill='#4C4C4C' />

      <path
        d='M14.0105 9.83047C13.0106 9.24226 11.75 9.96324 11.75 11.1234V20.8781C11.75 22.0382 13.0106 22.7592 14.0105 22.171L22.3021 17.2936C23.288 16.7137 23.288 15.2878 22.3021 14.7078L14.0105 9.83047Z'
        fill='white'
      />
    </svg>
  )
}

export default IconPlay
