import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  loaderPosition: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(255, 255, 255, 1)',
    zIndex: 10000,
    height: '100vh',
    width: '100%'
  },
  svgPak: {
    width: 200,
    height: 200,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    top: 0,
    margin: 'auto',
    position: 'absolute'
  },
  scale_an: {
    animation: '5s $anScale1 ease-in-out forwards infinite'
  },
  stroke_an1: {
    animation: '5s $an1 linear forwards infinite'
  },
  stroke_an2: {
    animation: '12s $an2 linear forwards infinite'
  },
  '@keyframes an1': {
    '0%': {
      strokeDasharray: '130, 133',
      strokeDashoffset: 0
    },
    '50%': {
      strokeDasharray: '253, 10',
      strokeDashoffset: -263
    },
    '100%': {
      strokeDasharray: '130, 133',
      strokeDashoffset: -789
    }
  },
  '@keyframes an2': {
    '0%': {
      strokeDasharray: '200, 63',
      strokeDashoffset: 0
    },
    '100%': {
      strokeDasharray: '200, 63',
      strokeDashoffset: -789
    }
  },
  '@keyframes anScale1': {
    '0%': {
      transform: 'scale(1, 1)'
    },
    '50%': {
      transform: 'scale(1.1, 1.1)'
    },
    '100%': {
      transform: 'scale(1, 1)'
    }
  }
})
