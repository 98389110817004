import { type FC } from 'react'
import { type IconProps } from 'types'

const IconSVGDocument: FC<IconProps> = ({ width = 20, height = 23 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_66623_179439)'>
        <path opacity='0.6' fillRule='evenodd' clipRule='evenodd' d='M4.73811 0C3.31803 0 2.16748 1.13571 2.16748 2.53748V29.4625C2.16748 30.8643 3.31803 32 4.73811 32H27.9285C29.3476 32 30.4992 30.8643 30.4992 29.4625V10.1559L20.9224 0H4.73811Z' fill='#458FFF'/>
        <path fillRule='evenodd' clipRule='evenodd' d='M30.5 10.1703V10.6673H24.1155C24.1155 10.6673 20.9668 10.0411 21.0581 7.33398C21.0581 7.33398 21.1619 10.1703 24.0531 10.1703H30.5Z' fill='#579AFF'/>
        <path opacity='0.5' fillRule='evenodd' clipRule='evenodd' d='M21.0562 0V7.15458C21.0562 7.96826 21.6077 10 24.1057 10H30.5L21.0562 0Z' fill='white'/>
      </g>

      <defs>
        <clipPath id='clip0_66623_179439'>
        <rect width='32' height='32' fill='white' transform='translate(0.5)'/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGDocument
