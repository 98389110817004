import {
  type FC,
  type MouseEvent,
  type Ref,
  type ReactNode,
  useCallback,
  useRef
} from 'react'
import { observer } from 'mobx-react-lite'
import { cnx } from '@carfluent/common'
import {
  Popover, MenuItem, MenuList,
  Paper, ClickAwayListener, PopoverOrigin
} from '@material-ui/core'
import type { TooltipProps } from '@material-ui/core'

import useToggler from 'hooks/useToggler'
import Button from 'components/common/Button'
import Tooltip from 'components/common/Tooltip'
import ChevronDownWhiteIcon from 'components/inlineImages/ChevronDownWhiteIcon'

import CLASS_NAME, { type ActionMenuOptionType } from './styles'

export interface ActionOptions {
  disabled?: boolean
  handleOnClick: () => void
  hidden?: boolean
  optionType?: ActionMenuOptionType
  title: string
  tooltip?: string | null
  placement?: TooltipProps['placement']
  order?: number
  icon?: ReactNode
}

export type ActionsMenuCloseReason = 'click-out' | 'click-on-action'

export interface ActionsMenuProps {
  actions?: ActionOptions[]
  buttonTitle?: string
  className?: string
  isDisabled?: boolean
  isOpen?: boolean
  MenuButtonComponent?: FC<MenuButtonProps>
  MenuListComponent?: FC<MenuListProps>
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  onMenuOpen?: () => void
  onMenuClose?: (reason: ActionsMenuCloseReason) => void
  actionMenuPopoverClassName?: string
}

export interface MenuListProps {
  children?: ReactNode | ReactNode[]
  ref?: Ref<HTMLDivElement>
}

export interface MenuItemProps {
  action: ActionOptions
  onMenuClose: (evt: any, reason: ActionsMenuCloseReason) => void
}

export interface MenuButtonProps {
  isDisabled?: boolean
  onClick: (evt: MouseEvent<HTMLElement>) => void
  title?: string
  isOpen?: boolean
}

const ANCHOR_CONFIG: PopoverOrigin = { vertical: 'bottom', horizontal: 'right' }
const TRANSFORM_CONFIG: PopoverOrigin = { vertical: -5, horizontal: 'right' }

const ActionsMenu: FC<ActionsMenuProps> = ({
  actions = [],
  buttonTitle,
  isDisabled = false,
  isOpen,
  className,
  MenuButtonComponent = DefaultMenuButton,
  anchorOrigin = ANCHOR_CONFIG,
  transformOrigin = TRANSFORM_CONFIG,
  MenuListComponent = MenuList,
  onMenuClose: _onMenuClose,
  onMenuOpen: _onMenuOpen,
  actionMenuPopoverClassName
}) => {
  const anchorRef = useRef<null | HTMLElement>(null)
  const itemsVisibility = useToggler(isOpen ?? false)

  const onMenuClick = useCallback(({ currentTarget }: MouseEvent<HTMLElement>) => {
    if (anchorRef.current == null) {
      anchorRef.current = currentTarget
    }

    itemsVisibility.enable()
    _onMenuOpen?.()
  }, [_onMenuOpen, itemsVisibility])

  const onMenuClose = useCallback((_, reason: ActionsMenuCloseReason = 'click-out') => {
    itemsVisibility.disable()
    _onMenuClose?.(reason)
  }, [itemsVisibility, _onMenuClose])

  const visibleActions = actions.filter(item => item.hidden !== true)

  if (visibleActions.length === 0) {
    return null
  }

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <MenuButtonComponent
        isDisabled={isDisabled}
        onClick={onMenuClick}
        title={buttonTitle}
        isOpen={itemsVisibility.value}
      />

      {/**
        * AZ-TODO: this sh*t should be completely removed after we will improve our Popper.
        * So in the end we will be: Popper > MenuListComponent > MenuItemComponent.
        */}
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={anchorOrigin}
        className={cnx('cf-menu-popper', actionMenuPopoverClassName)}
        disablePortal
        open={isOpen ?? itemsVisibility.value}
        transformOrigin={transformOrigin}
      >
        <ClickAwayListener onClickAway={onMenuClose}>
          <Paper className='cf-menu-container'>
            <MenuListComponent>
              {visibleActions.map((action, index) => (
                <MenuItemComponent key={index} action={action} onMenuClose={onMenuClose} />
              ))}
            </MenuListComponent>
          </Paper>
        </ClickAwayListener>
      </Popover>
    </div>
  )
}

export default observer(ActionsMenu)

// ========================================== //

const MenuItemComponent: FC<MenuItemProps> = ({ action, onMenuClose }) => {
  const {
    title,
    tooltip,
    handleOnClick,
    disabled = false,
    optionType,
    icon,
    placement = 'left'
  } = action

  const onClick = useCallback((evt?: MouseEvent<HTMLElement>) => {
    if (!disabled) {
      onMenuClose(evt, 'click-on-action')
      handleOnClick()
    }
  }, [disabled, onMenuClose, handleOnClick])

  return (
    <Tooltip
      key={title}
      placement={placement}
      title={tooltip ?? ''}
    >
      <span> {/* needed for tooltip, see MUI docs */}
        <MenuItem
          className={`cf-menu-item ${optionType ?? ''}`}
          disabled={disabled}
          onClick={onClick}
          style={disabled ? { pointerEvents: 'none' } : {}} // needed for tooltip, see MUI docs
        >
          {icon}
          {title}
        </MenuItem>
      </span>
    </Tooltip>
  )
}

const DefaultMenuButton: FC<MenuButtonProps> = ({
  isDisabled = false,
  onClick,
  title = 'Actions'
}) => {
  return (
    <Button
      className='cf-menu-button'
      disabled={isDisabled}
      onClick={onClick}
    >
      {title} <ChevronDownWhiteIcon />
    </Button>
  )
}
