import { css } from '@emotion/css'

export default css(`
  .cf-delete-modal-title {
    font-size: 34px;
    line-height: 36px;
    color: #101010;
  }
  
  .cf-delete-modal-content {

    > label {
      height: 24px;
      margin-left: -8px;

      > span {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #101010;
      }
    }
  }
  
  .cf-delete-modal-buttons-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 16px 1fr;
    grid-template-areas: "cancel-button . submit-button";
    margin-top: 48px;

    > button {
      height: 56px;
      width: 100%;
    }

    .MuiButton-label {
      font-size: 14px;
      font-weight: 500;
    }
  }
  
  .cf-delete-modal-submit-button {
    grid-area: submit-button;
  }

  .cf-delete-modal-cancel-button {
    grid-area: cancel-button;
    border: 1px solid #101010;
    color: #101010;
    background: #ffffff;

    :hover {
      background: #ffffff;
      color: #212121;
      border: 1px solid #212121;
      opacity: unset;
    }
  }
`)
