import type { DealerData } from 'api/types'
import type { ProfileFormData } from './types'
import parseLeadEmailString from 'utils/parseLeadEmailString'

const serializeProfileData = (
  data: ProfileFormData,
  original: DealerData,
  latLng?: google.maps.LatLngLiteral
): Partial<DealerData> => ({
  dealerName: data.dealerName,
  address1: data.addressData?.address ?? original.address1 ?? '',
  city: data.addressData?.city ?? original.city ?? '',
  zipCode: data.addressData?.zipCode ?? original.zipCode ?? '',
  state: data.addressData?.state ?? original.state ?? '',
  dealerBusinessHours: data.dealerBusinessHours,
  phone: data.phone ?? null,
  email: data.email,
  leadCmsEmails: parseLeadEmailString(data.leadCmsEmails),
  googleMapsLink: data.googleMapsLink,
  logoUrl: data.logoUrl,
  termsUrl: data.termsUrl,
  locationLatitude: latLng?.lat ?? 0,
  locationLongitude: latLng?.lng ?? 0
})

export default serializeProfileData
