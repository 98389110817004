import { type FC } from 'react'
import { type MenuIconProps } from 'types'

const DashboardMenuIcon: FC<MenuIconProps> = ({
  isActive = false
}) => {
  const pictogramFill = isActive ? '#fff' : '#101010'

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M15.21 2.00007H8.75C6.9606 2.00271 5.24525 2.71472 3.97995 3.98002C2.71465 5.24532 2.00265 6.96067 2 8.75007V15.2501C2.00265 17.0395 2.71465 18.7548 3.97995 20.0201C5.24525 21.2854 6.9606 21.9974 8.75 22.0001H15.25C17.0394 21.9974 18.7548 21.2854 20.0201 20.0201C21.2853 18.7548 21.9974 17.0395 22 15.2501V8.75007C21.9987 7.86064 21.8219 6.98022 21.4797 6.15925C21.1375 5.33829 20.6367 4.59292 20.0059 3.96586C19.3751 3.3388 18.6268 2.84238 17.8038 2.50505C16.9809 2.16773 16.0994 1.99612 15.21 2.00007ZM17.58 9.42007L15.13 13.1301C14.8734 13.5087 14.4801 13.7733 14.0327 13.8684C13.5853 13.9635 13.1184 13.8816 12.73 13.6401L10.34 12.1301C10.3134 12.1176 10.2844 12.1111 10.255 12.1111C10.2256 12.1111 10.1966 12.1176 10.17 12.1301C10.1068 12.1377 10.0493 12.17 10.01 12.2201L7.56 15.4601C7.44205 15.6053 7.27409 15.7012 7.08908 15.7289C6.90407 15.7567 6.71536 15.7143 6.56 15.6101C6.41478 15.4921 6.31888 15.3242 6.29113 15.1391C6.26338 14.9541 6.30578 14.7654 6.41 14.6101L8.86 11.3501C9.12372 10.9993 9.5096 10.7604 9.94116 10.6808C10.3727 10.6011 10.8184 10.6865 11.19 10.9201L13.58 12.4301C13.6094 12.445 13.642 12.4528 13.675 12.4528C13.708 12.4528 13.7406 12.445 13.77 12.4301C13.8004 12.4244 13.8294 12.4126 13.8552 12.3954C13.881 12.3782 13.903 12.356 13.92 12.3301L16.38 8.62007C16.4901 8.45431 16.6615 8.33906 16.8565 8.29968C17.0515 8.26029 17.2542 8.3 17.42 8.41007C17.5858 8.52013 17.701 8.69154 17.7404 8.88657C17.7798 9.08161 17.7401 9.28431 17.63 9.45007L17.58 9.42007Z'
        fill={pictogramFill}
      />
    </svg>
  )
}

export default DashboardMenuIcon
