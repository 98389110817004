import type { FC } from 'react'
import { CellWrapper, cnx } from '@carfluent/common'

import Toggle from 'components/common/Toggle'

import { WorkPlanEventDto } from 'api/types'
import type { DictionaryItem } from 'api/types'

import { TOGGLE_CLASS_NAME } from './styles'
interface RuleCellProps {
  getValue: () => WorkPlanEventDto
  leadStatus: DictionaryItem
  toggleActivate: (leadStatus: DictionaryItem, rule: WorkPlanEventDto) => Promise<void>
  onOpenDeleteModal: (rule: WorkPlanEventDto) => void
  className?: string
}

const RuleCell: FC<RuleCellProps> = ({ getValue, toggleActivate, leadStatus, className }) => {
  const data = getValue?.()
  const { isActive } = data

  const onToggle = async (): Promise<void> => await toggleActivate(leadStatus, { ...data, isActive: !(isActive as boolean) })

  return (
    <CellWrapper className={cnx(className, 'rule-cell')}>
      <Toggle
        onClick={onToggle}
        className={TOGGLE_CLASS_NAME}
        toggled={isActive === true}
      />
    </CellWrapper>
  )
}

export default RuleCell
