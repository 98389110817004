import { type LeadFormData } from 'types'
import { type OpenTaskFormData } from './types'

export enum Messages {
  SuccessLeadUpdate = 'Lead was updated.',
  SuccessLeadDelete = 'Lead was deleted.',
  SuccessLogCreate = 'Log entry created',
  SuccessTaskComplete = 'Task completed.'
}

export const DEFAULT_LEAD = (): LeadFormData => ({
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  address: null,
  city: null,
  state: null,
  zipCode: null,
  isLost: false,
  lostReasonId: null,
  tasks: [],
  birthDate: null,
  lastActivityDate: '',
  leadTemperatureId: null,
  leadSourceId: 0,
  leadStatusId: 1,
  dealerId: 0,
  assignedUserId: null,
  assignedUser: null,
  primaryCarOfInterestId: null,
  carsOfInterest: [],
  lostDate: null,
  dealId: null,
  isUnsubscribed: false,
  isPaused: false,
  pausedByUserId: null,
  pausedDateTime: null
})

export const getDefaultFormData = (): OpenTaskFormData => ({
  assignedUser: null,
  dueDate: null,
  time: null,
  description: null,
  subject: null,
  taskPriority: null
})

export enum FieldIds {
  DueDate = 'dueDate',
  Date = 'date',
  Time = 'time',
  AssignedTo = 'assignedUser',
  Priority = 'taskPriority',
  Description = 'description',
  Subject = 'subject',
}

export const UNASSIGNED_OPTION = { name: 'Unassigned', id: 0 }
