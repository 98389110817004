import { type FC } from 'react'
import { type IconProps } from 'types'

const IconSVGNaIcon: FC<IconProps> = ({
  width = 24,
  height = 24,
  color = '#212121CC'
}) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_86873_41804)'>
        <path d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H7V11H17V13Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_86873_41804'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGNaIcon
