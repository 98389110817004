import { type UseFormReturn } from '@carfluent/common'

import {
  type BusinessDetailsDto,
  type CoverageDetails as ApiCoverageDetails,
  type DuplicateLeadDto,
  type WorkflowTypeDto,
  type DictionaryItem
} from 'api/types'

import { type DealModel } from 'api/types/responses'
import type { AddressData } from 'types/address'

export enum PercentOrAmountModes {
  Auto = 'auto',
  Manual = 'manual'
}

/**
 * @deprecated - will be removed after switch to the new DealDetails
 */
export const COVERAGE_TYPE = {
  PEN: 'PEN F&I list',
  MANUAL: 'Manual input'
} as const

/**
 * @deprecated - use coverages/CoverageManualFormValues instead
 */
export interface ManualWarranty {
  coverageName: string | null
  termMiles: string | null
  termMonths: string | null
  dealerCost: number | null
  deductibleAmount: number | null
  dealerRetailPrice: number | null
}

/**
 * @deprecated - will be removed after switch to the new DealDetails
 */
export interface CoverageDetails extends ApiCoverageDetails {
  coverageType: string
  isCoveragesShown: boolean
  isDeductibleShown: boolean
  manualWarranty: ManualWarranty | null
}

// ========================================== //
//                   DEAL DETAILS             //
// ========================================== //

export type DealFeesPropsToOmit =
  | 'documentFee'
  | 'registrationTax'
  | 'others'
  | 'salesCommission'
  | 'overridenSalesTaxPercent'
  | 'overridenSalesTaxAmount'
  | 'transportationCost'

export interface DealFormValues {
  activeLenderDecision: DealModel['activeLenderDecision']
  dealFees: DealModel['dealFees']
  checkoutDetails: Pick<DealModel['checkoutDetails'], 'deliveryType' | 'pickupDeliveryDate' | 'pickupDeliveryTime'>
  dealFinanceTypeId: DealModel['dealFinanceTypeId']
  firstPaymentDate: DealModel['firstPaymentDate']
  hasLenderDecision: DealModel['hasLenderDecision']
  isTradeInSectionVisible: DealModel['isTradeInSectionVisible']
  salesperson: DealModel['salesperson']
  saleDate: DealModel['saleDate']
  tradeInDetails: Pick<DealModel['tradeInDetails'], 'credit' | 'acv' | 'paymentAmount'>
  vehicle: Pick<DealModel['vehicle'], 'listPrice'>
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type DealFormErrTouchShortcuts = {
  activeLenderDecision: { lienholder: true }
  checkoutDetails: { deliveryType: true }
  salesperson: true
}

export type UseDealForm = UseFormReturn<DealFormValues, DealFormErrTouchShortcuts>

// ========================================== //
//                 ADD NEW DEAL               //
// ========================================== //

export interface NewDealFormData {
  vehicle: {
    mileage: number | null
    salePrice: number | null
    vin: string | null
    stock: string | null
    make: string | null
    model: string | null
    trim: string | null
    year: number | null
    id: number | null
  }
  paymentType: DictionaryItem | null
  addressData: AddressData
  customerFirstName: string | null
  customerLastName: string | null
  customerPhoneNumber: string | null
  customerEmail: string | null
  customerBirthDate: Date | null
  salesperson: DictionaryItem | null
  source: DictionaryItem | null
  customer: DuplicateLeadDto | null
  workflowType: WorkflowTypeDto | null
  businessDetails: BusinessDetailsDto
}
