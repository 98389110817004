import { serializers } from '@carfluent/common'
import { ImportBankStatementsPayload } from 'api/types'

import { SerializeDataProps } from './types'

const { serializeDate } = serializers

export const serializeData = ({
  fileId,
  bankId,
  accountId,
  originalFileName,
  transactions
}: SerializeDataProps): ImportBankStatementsPayload => {
  return ({
    fileId,
    bankId,
    accountId,
    originalFileName,
    transactions: transactions?.filter(({ checked }) => checked).map((item) => {
      const change = (): number => {
        if (item.withdrawal === null) {
          return Number(item.deposit?.rawValue)
        }

        if (item.withdrawal != null && item.withdrawal?.rawValue !== 0) {
          return -Number(item.withdrawal?.rawValue)
        } else {
          return Number(item.deposit?.rawValue)
        }
      }
      return ({
        date: serializeDate(item.date?.rawValue),
        change: change(),
        description: item.description?.rawValue
      })
    })
  })
}
