import { css } from '@emotion/css'
import { HEADER_HEIGHT } from './components/Header/styles'

const TOP_MARGIN = '32'

const createUseStyles = (height: number): string => {
  return css(`
    width: 460px;
    height: 720px;
    max-height: calc(100dvh - ${TOP_MARGIN}px);
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding-bottom: ${height}px;

    .cf-content-section {
      height: calc(100% - ${HEADER_HEIGHT});
      position: relative;
    }
  `)
}

export default createUseStyles
