import type { DictionaryItem, WorkPlanEventDto } from 'api/types'

import type { FormValues } from './validator'

interface ParseDataProps {
  data: WorkPlanEventDto
  taskTypes: DictionaryItem[]
  reminderSubjects: DictionaryItem[]
  taskPriorities: DictionaryItem[]
  assignedUser: DictionaryItem | null
  messageTemplate: DictionaryItem | null
  emailTemplate: DictionaryItem | null
}

export const parseDataToEdit = ({
  data,
  taskTypes,
  reminderSubjects,
  taskPriorities,
  assignedUser,
  emailTemplate,
  messageTemplate
}: ParseDataProps): FormValues => {
  return {
    title: data.title,
    assignedUser: assignedUser,
    taskType: taskTypes.find(({ id }) => id === data.taskTypeId) ?? null,
    reminderSubject: reminderSubjects.find(({ name }) => name === data.reminderSubject) ?? null,
    taskPriority: taskPriorities.find(({ id }) => id === data.taskPriorityId) ?? null,
    executionDay: data.executionDay ?? 1,
    description: data.description,
    workplanEventTypeId: data.workplanEventTypeId,
    runAtTime: data.runAtTime ?? '10:00 AM',
    emailTemplateId: emailTemplate ?? null,
    messageTemplateId: messageTemplate ?? null

  }
}
