import { type FormValidation, type DeepRequired } from '@carfluent/common'

import { isNumberRequired } from 'utils/validationRules'
import { Errors, dateField } from 'utils/validationPresets'

import type { ReconciliationFormData } from 'types/accounting'

const endDate = dateField('append')

const validationRules: FormValidation<DeepRequired<Omit<ReconciliationFormData, 'beginningBalance'>>> = {
  endingBalance: isNumberRequired(Errors.Empty),
  endDate
}

export default validationRules
