export const isControlKey = (key: string): boolean => {
  const controlKeys = ['Alt', 'Control', 'Meta', 'Shift']
  return controlKeys.includes(key)
}

export const isKeyPrintable = (key: string): boolean => {
  const nonPrintableKeys = [
    'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp',
    'Backspace', 'Delete', 'End', 'Enter', 'Escape', 'CapsLock', 'Tab',
    'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12',
    'Home', 'Insert', 'NumLock', 'PageDown', 'PageUp', 'ScrollLock',
    'ContextMenu'
  ]

  // Check if the key is a control key or in the list of non-printable keys
  return !isControlKey(key) && !nonPrintableKeys.includes(key)
}
