import { type DeepRequired } from '@carfluent/common'
import { type LeadFormData } from 'types'
import { type UpdateLeadFormData } from './types'

const transformLead = ({
  address,
  zipCode,
  city,
  state,
  assignedUserId,
  leadTemperatureId,
  leadSourceId,
  ...otherProps
}: LeadFormData): DeepRequired<UpdateLeadFormData> => ({
  addressData: {
    address,
    zipCode,
    city,
    state,
    addressLong: null,
    county: null
  },
  ...otherProps,
  leadTemperature: null,
  leadSource: null,
  assignedUser: null,
  tasks: []
})

export default transformLead
