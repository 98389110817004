import { type FC, useState, useRef, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { FormDropdown, FormInput, FormNumberInput } from '@carfluent/common'
import MemoizedFormDatePicker from '../MemoizedFormDatePicker'
import Popover from '@material-ui/core/Popover'

import getCurrentUser from 'utils/getCurrentUser'
import { ValidationLength, MAX_PRICE_VALUE } from 'constants/validation'
import useUsersList from 'hooks/useUsersList'
import InputsRowLayout from 'components/inventory/InputsRowLayout'
import { useForm } from 'components/form'

import PriceHistory, { type PriceHistoryMergedItem } from '../PriceHistory'

import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

interface InventoryDetailsProps {
  priceHistory: PriceHistoryMergedItem[]
  isDisabled: boolean
  daysForNextReprice?: number | null
}

const PRICES_PLACEMENT = {
  vertical: 'bottom',
  horizontal: 'left'
} as const

const getRepriceReminder = (days: number): string => {
  return `Next price drop in ${days} ${days === 1 ? 'day' : 'days'}`
}

const VehicleInventoryDetailsFormControls: FC<InventoryDetailsProps> = ({
  priceHistory,
  isDisabled,
  daysForNextReprice
}) => {
  const { setFieldValue, handleBlur, values, errors, touched } = useForm()
  const currentUser = getCurrentUser()
  const users = useUsersList(currentUser)

  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const onHandlerClick = useCallback(() => {
    setIsOpen((prev) => !prev)
  }, [])

  const onHandlerClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const endAdornment = useMemo(() => (
    <span
      onClick={onHandlerClick}
      className='cf-clickable-input-adornment'
      aria-describedby='popper-wrapper'
    >
      History
    </span>
  ), [onHandlerClick])

  const isSalePriceError = typeof errors.salePrice === 'string' &&
    errors.salePrice !== '' &&
    touched.salePrice === true

  const onChange = useCallback((id, value): void => {
    if (value !== values[id]) {
      void setFieldValue(id, value)
    }
  }, [setFieldValue, values])

  return (
    <InputsRowLayout className={CLASS_NAME}>
      <FormInput
        id='stock'
        label='Stock #'
        maxLength={ValidationLength.STOCK_MAX}
        onBlur={handleBlur}
        onChange={setFieldValue}
        touched={touched.stock as boolean}
        error={errors.stock as string}
        value={values.stock}
        disabled={isDisabled}
      />

      <MemoizedFormDatePicker
        id='inventoryDate'
        label='Inventory date'
        onBlur={handleBlur}
        onChange={onChange}
        value={values.inventoryDate}
        touched={Boolean(touched.inventoryDate)}
        error={errors.inventoryDate as string}
        disabled={isDisabled}
      />

      <div className='sale-price-wrapper' ref={containerRef}>
        <FormNumberInput
          id='salePrice'
          label='Sale price'
          max={MAX_PRICE_VALUE}
          onBlur={handleBlur}
          onChange={setFieldValue}
          endAdornment={endAdornment}
          className='cf-inventory-sale-price'
          value={values.salePrice}
          touched={touched.salePrice as boolean}
          error={errors.salePrice as string}
          preset='financial'
          isNegativeAllowed={false}
          disabled={isDisabled}
        />
        {
          daysForNextReprice != null && !isSalePriceError && (
            <span className='reprice-reminder'>
              {getRepriceReminder(daysForNextReprice)}
            </span>
          )
        }

        <Popover
          open={isOpen}
          onClose={onHandlerClose}
          anchorEl={containerRef.current}
          className={POPOVER_CLASS_NAME}
          anchorOrigin={PRICES_PLACEMENT}
        >
          <PriceHistory priceHistoryList={priceHistory} onHandlerClose={onHandlerClose} />
        </Popover>
      </div>

      <FormInput
        id='source'
        label='Source'
        onBlur={handleBlur}
        onChange={setFieldValue}
        value={values.source}
        maxLength={ValidationLength.MAX_SOURCE}
        disabled={isDisabled}
      />

      <FormDropdown
        id='buyer'
        label='Buyer'
        blurMode='select-first'
        options={users}
        value={values.buyer}
        error={errors.buyer as string}
        touched={Boolean(touched.buyer)}
        onChange={(id, value) => { void setFieldValue(id, value) }}
        disabled={isDisabled}
      />

    </InputsRowLayout>
  )
}

export default observer(VehicleInventoryDetailsFormControls)
