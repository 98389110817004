import type { CellFC, CellProps } from 'types'

function ConditionalCellHOK<TData, TValue = unknown> (
  predicate: (props: CellProps<TData, TValue>) => boolean,
  Comp1: CellFC<TData, TValue>,
  Comp2: CellFC<TData, TValue>
): CellFC<TData, TValue> {
  return (props: CellProps<TData, TValue>) => {
    if (predicate(props)) {
      return <Comp1 {...props} />
    }

    return <Comp2 {...props} />
  }
}

export default ConditionalCellHOK
