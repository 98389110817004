import { type FC } from 'react'
import type { BuyerCobuyerFormProps } from './types'
import useBuyerCobuyerTab from './hook'
import BuyerForm from './components/BuyerForm'
import CobuyerForm from './components/CobuyerForm'
import ActionsFooterAndUnsavedChanges from '../ActionsFooterAndUnsavedChanges'
import CLASS_NAME from './styles'

const BuyerCobuyerForm: FC<BuyerCobuyerFormProps> = (props) => {
  const {
    isPersonal, values, errors, touched, hasChanges, isSubmitting, isUnsavedChangesShown,
    onChange, onBlur, onSubmit, resetForm, onChangeSectionVisibility, onOpenUnsavedChanges,
    isRecorded, onCloseUnsavedChanges
  } = useBuyerCobuyerTab(props)

  return (
    <div className={CLASS_NAME}>
      <h6>Buyer</h6>
      <BuyerForm
        values={values}
        errors={errors}
        touched={touched}
        isPersonal={isPersonal}
        onBlur={onBlur}
        isDisabled={isRecorded}
        onChange={onChange}
      />

      <hr />

      <CobuyerForm
        isCobuyerSectionVisible={values.isCobuyerSectionVisible ?? false}
        values={values.cobuyer}
        errors={errors.cobuyer}
        touched={touched.cobuyer}
        isDisabled={isRecorded}
        buyerAddress={values.buyer.addressDetails}
        coApplicantTypes={props.coApplicantTypes}
        onBlur={onBlur}
        onChange={(field, val) => onChange(`cobuyer.${field}`, val)}
        onToggleIsCobuyerVisible={onChangeSectionVisibility}
      />
      <ActionsFooterAndUnsavedChanges
        isSubmitting={isSubmitting}
        isUnsavedChangesShown={isUnsavedChangesShown}
        isActionsFooterShown={hasChanges}
        shouldBlock={hasChanges}
        onSubmit={onSubmit}
        onCancel={() => resetForm()}
        onOpen={onOpenUnsavedChanges}
        onClose={onCloseUnsavedChanges}
        onDontSaveAndClose={props.onTrySwitchTab}
        onResetTabNavigation={props.onResetTabNavigation}
      />
    </div>
  )
}

export default BuyerCobuyerForm
