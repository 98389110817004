import { toJS } from 'mobx'

import type { LienholderCreateRequestDto } from 'api/types'
import type { LienholderFormData } from './types'

const serializeAddCustomerForm = (formData: LienholderFormData): LienholderCreateRequestDto => {
  const data = toJS(formData)
  const { address, corpCode, name } = data

  return {
    name,
    phoneNumber: data.phoneNumber ?? null,
    email: data.email ?? null,
    address: address?.address ?? address?.addressLong ?? null,
    city: address?.city ?? null,
    state: address?.state ?? null,
    zipCode: address?.zipCode ?? null,
    corpCode: corpCode?.toString() ?? null
  }
}

export default serializeAddCustomerForm
