import type { FC } from 'react'

import dropDownArrow from 'assets/dropdown-bottom-arrow.svg'

const DropdownIcon: FC = () => {
  return (
    <img src={dropDownArrow} alt='' />
  )
}

export default DropdownIcon
