import { type FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Loader, cnx, Banner } from '@carfluent/common'

import { type EntityId } from 'types'
import { TabIds } from 'pages/inventory/VehicleDetails/hooks/constants'
import CalcRoutes from 'constants/route_helper'
import ModalWindow from 'components/dialogs/ModalWindow'
import DealRecapFrontGross from 'components/deals/DealRecapFrontGross'
import DealRecapBackGross from 'components/deals/DealRecapBackGross'
import useDealRecap from 'hooks/deals/useDealRecap'

import CLASS_NAME from './styles'

export interface UseDealRecapProps {
  isModalOpen: boolean
  onCloseModal: () => void
  inventoryId?: EntityId | null
  dealId?: EntityId | null
  isShowCarDelivery?: boolean
}

const DealRecapDetails: FC<UseDealRecapProps> = ({
  dealId,
  inventoryId,
  isModalOpen = true,
  isShowCarDelivery = false,
  onCloseModal: onClose
}) => {
  const {
    dealFinancesData,
    isLoading
  } = useDealRecap({
    dealId,
    onError: onClose,
    shouldLoadData: isModalOpen
  })

  const dealRecapData = dealFinancesData

  const title = dealRecapData != null
    ? `Deal recap for ${dealRecapData?.year ?? ''} ${dealRecapData?.make ?? ''} ${dealRecapData?.model ?? ''}`
    : ''

  const showBannerLackOfCosts =
    (dealRecapData != null) && ((dealRecapData.saleCost == null) || (dealRecapData.saleCost === 0))

  // ========================================== //

  return (
    <ModalWindow
      onClose={onClose}
      open={isModalOpen}
      popupDataTestId='deal-recap-popup'
      titleDataTestId='deal-recap-title'
      title={title}
    >
      <div className={cnx(CLASS_NAME, 'modal-body-container')}>
        {isLoading && <Loader size='large' color='inherit' />}

        {showBannerLackOfCosts && (
          <Banner
            message='Lack of Costs. To add them, go to the Inventory.'
            type='info'
            endAdornment={(inventoryId != null) && (
              <NavLink
                className='vehicle-link'
                to={CalcRoutes.VehicleDetails(inventoryId, TabIds.Costs)}
                rel='noreferrer'
              >
                Inventory
              </NavLink>
            )}
          />
        )}

        {(dealRecapData != null) && (
          <div className='container'>
            <DealRecapFrontGross
              className='left-side'
              dealRecapData={dealRecapData}
              isShowCarDelivery={isShowCarDelivery}
            />

            <DealRecapBackGross
              className='right-side'
              dealRecapData={dealRecapData}
            />
          </div>
        )}
      </div>
    </ModalWindow>
  )
}

export default DealRecapDetails
