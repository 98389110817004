import { parsers as P } from '@carfluent/common'

import { toDate } from 'utils/parse_date'
import getLineControlListItem from 'utils/accounting/getLineControlListItem'
import _isOpenBalanceListItem from 'utils/accounting/isOpenBalanceListItem'
import isCheck from 'utils/accounting/isCheck'
import isPayable from 'utils/accounting/isPayable'
import isPayBill from 'utils/accounting/isPayBill'
import isReceivable from 'utils/accounting/isReceivable'
import isReceive from 'utils/accounting/isReceive'
import { trimNumber } from 'utils/math'
import { namifyAccount } from 'utils/accounting/namifyAccount'
import parseCustomer from 'utils/accounting/parseCustomer'
import parseVendor from 'utils/accounting/parseVendor'
import { type TransactionLineRow } from 'types'
import {
  type TransactionDetails as ApiTransactionDetails,
  type TransactionLine as ApiTransactionLine,
  type OpenBalancesListItem,
  TransactionTypeId
} from 'api/types'

import { type TransactionDetails } from '../types'
import { getDefaultRows, getDefaultFormData } from '../constants'
import { parseLineToControl, parseBankingInfo, parseCommonParts } from './utils'

const DEFAULT_ROWS = getDefaultRows()

export const parseTransaction = (transaction: ApiTransactionDetails): TransactionDetails => {
  const {
    amount,
    checkNumber,
    date,
    dueDate,
    description,
    isLocked,
    isVehiclePayment,
    isSystemCreated,
    lines,
    memo = null,
    referenceNumber,
    recurringTransactionTemplateId = null,
    transactionTypeId,
    lastPrintDate,
    transactionStateId
  } = transaction

  const manualLines = lines.filter((item) => !item.isSystemCreated)
  const autoLine = lines.find((item) => item.isSystemCreated)
  const { account = null, customer = null, vendor = null } = autoLine ?? {}
  const rows = manualLines.map(line => parseTransactionLine({ line, transactionTypeId }))

  return {
    ...getDefaultFormData(),
    amount,
    checkNumber,
    dateTime: toDate(date),
    dueDate: toDate(dueDate),
    description: description ?? '',
    isLocked: isLocked ?? false,
    isVehiclePayment,
    isSystemCreated,
    memo,
    receivableAccount: account != null ? namifyAccount(account) : null,
    receivableEntity: parseVendor(vendor) ?? parseCustomer(customer) ?? null,
    receivableVendor: vendor ?? null,
    receivableControl: getLineControlListItem(autoLine ?? null),
    receivableLineId: autoLine?.id ?? null,
    recurringTransactionTemplateId,
    referenceNumber,
    rows: rows.length === 0 ? DEFAULT_ROWS : rows,
    transactionTypeId: transactionTypeId ?? null,
    lastPrintDate: lastPrintDate != null ? P.parseDateStringUTC(lastPrintDate) : null,
    transactionStateId,
    ...parseCommonParts(transaction),
    ...parseBankingInfo(lines)
  }
}

interface ParseTransactionLineProps {
  line: ApiTransactionLine | OpenBalancesListItem
  transactionTypeId?: TransactionTypeId | null
}

export const parseTransactionLine = ({
  transactionTypeId,
  line
}: ParseTransactionLineProps): TransactionLineRow => {
  const {
    account,
    bankStatementTransaction,
    customer,
    dealId,
    lineEntryReconciliation,
    notes,
    vendor
  } = line

  const common = {
    account: account == null ? null : namifyAccount(account),
    control: parseLineToControl(line),
    entity: parseVendor(vendor) ?? parseCustomer(customer) ?? null,
    notes: notes ?? '',
    dealId
  }

  let id: number | undefined
  let isSystemCreated: boolean
  let change: number
  let reconciliationId: string | null

  const isOpenBalanceListItem = _isOpenBalanceListItem(line)

  if (isOpenBalanceListItem) {
    change = line.amount
    id = undefined
    isSystemCreated = false
    reconciliationId = line.reconciliationId
  } else {
    change = line.change
    id = line.id
    isSystemCreated = line.isSystemCreated
    reconciliationId = null
  }

  const parsedChange = trimNumber(change, 2)

  /**
   * Check (and Pay Bill):
   * - account indicated in Pay from is credited
   * - account indicated in lines is DEBITED
   *
   * Payable:
   * - account indicated for specific Vendor in Vendor popup is credited
   * - account indicated in lines is DEBITED
   *
   * Receive:
   * - account indicated in Deposit to is debited
   * - account indicated in lines is CREDITED
   *
   * Receivable:
   * - account indicated in Receivable account is debited
   * - account indicated in lines is CREDITED
   */

  /**
   * We invert credit's sign in serializer, so it should be also inverted in parser.
   * But for open balances "lines" we already get amount with sign, so don't invert.
   */
  const credits = (isReceive(transactionTypeId) || isReceivable(transactionTypeId))
    ? isOpenBalanceListItem ? parsedChange : -parsedChange
    : 0

  const debits = (isCheck(transactionTypeId) || isPayBill(transactionTypeId) || isPayable(transactionTypeId))
    ? parsedChange
    : 0

  return {
    ...common,
    id,
    credits,
    debits,
    isSystemCreated,
    reconciliationId,
    disabled: line?.disabled ?? false,
    bankStatementTransaction,
    lineEntryReconciliation
  }
}
