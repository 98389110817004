import { css } from '@emotion/css'

export default css(`  
  .cf-modal-content-scroll-wrapper {
    min-width: 100vw;

    .cf-modal-content {
      padding: 16px 40px;

      .cf-banner-appeared {
        &:after {
          content: '';
          display: block;
          height: 16px;
        }
      }
    }
  }

  .cf-delete-button.cf-button-root.cf-button-variant-text,
  .new-customer-business-name,
  .customer-type-id,
  .is-different-mailing-address{
    grid-column: 1 / 3;
    text-decoration: none;

    .cf-button-content {
      font-weight: 500;
    }
  }

  .cf-button-content {
    font-size: 14px;
  }
`)
