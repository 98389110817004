import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;

  .MuiFormControlLabel-root {
    margin-left: -9px;
  }

  .field-wrapper {
    margin-top: 15px;

    > div:not(:last-of-type) {
      margin-bottom: 24px;
    }

    .row-wrapper {
      height: 56px;
      display: flex;

      > div {
        width: calc(50% - 8px);

        :first-of-type {
          margin-right: 16px;
        }
      }
    }
  }

  .coverage-form-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`)
