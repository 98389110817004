import { utils } from '@carfluent/common'

import type { Shift, Coords } from 'types'
import { WORK_SCHEDULE_MODAL_CONSTRAINT_ID } from 'constants/constants'
import { SHIFT_MODAL_WIDTH, SHIFT_MODAL_HEIGHT } from './constants'

const { isEqual } = utils.dates

export const getChunk = <T>(array: T[], size: number): T[][] => {
  const chunkedArray: T[][] = []

  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size))
  }

  return chunkedArray
}

export const getCalendarDates = (year: number, month: number): Array<Array<Date | null>> => {
  const firstDayOfMonth = new Date(year, month, 1)
  const lastDayOfMonth = new Date(year, month + 1, 0)

  const daysInMonth = lastDayOfMonth.getDate()
  const firstDayOfWeek = firstDayOfMonth.getDay()
  const lastDayOfWeek = lastDayOfMonth.getDay()
  const dates: Array<Date | null> = []

  // Add empty cells before the first day of the month
  for (let i = 0; i < firstDayOfWeek; i++) {
    dates.push(null)
  }

  // Add the days of the month
  for (let i = 1; i <= daysInMonth; i++) {
    dates.push(new Date(year, month, i))
  }

  // Add empty cells after the last day of the month
  for (let i = lastDayOfWeek + 1; i < 7; i++) {
    dates.push(null)
  }

  return getChunk(dates, 7)
}

export const getShiftsForDate = (date: Date | null, shifts: Shift[]): Shift[] | null => {
  if (date == null) {
    return null
  }

  return shifts.filter(shift => isEqual(shift.startDate, date))
}

type ModalConstraintElementDimensionsReturn = { width: number } | undefined

const getModalConstraintElementDimensions = (constraintElement: HTMLElement | null): ModalConstraintElementDimensionsReturn => {
  if (constraintElement != null) {
    const constraintElementStyles = window.getComputedStyle(constraintElement)
    const constraintElementWidth = constraintElement.offsetWidth - parseFloat(constraintElementStyles.marginLeft) - parseFloat(constraintElementStyles.marginRight)
    return { width: constraintElementWidth }
  }
}

export const getShiftModalPosition = (chipPosition: Coords | null): Coords | undefined => {
  const constraintElement = document.getElementById(WORK_SCHEDULE_MODAL_CONSTRAINT_ID)
  const constraintElementDimensions = getModalConstraintElementDimensions(constraintElement)
  const WindowSize = {
    width: constraintElementDimensions?.width ?? window.innerWidth,
    height: window.innerHeight
  }
  if (chipPosition?.x != null && chipPosition?.y != null) {
    const initialTopLeft = {
      x: chipPosition.x - 20,
      y: chipPosition.y - 300
    }
    const initialBottomLeft = {
      x: chipPosition.x - 20,
      y: chipPosition.y + 35
    }
    const initialBottomRight = {
      x: chipPosition.x - 400,
      y: initialBottomLeft.y
    }

    const initialTopRight = {
      x: initialBottomRight.x,
      y: initialTopLeft.y
    }

    const xSum = initialBottomLeft.x + SHIFT_MODAL_WIDTH
    const ySum = initialBottomLeft.y + SHIFT_MODAL_HEIGHT

    const xDiff = WindowSize.width - xSum
    const yDiff = WindowSize.height - ySum

    if (xDiff <= 0 && yDiff <= 0) {
      return initialTopRight
    }
    if (xDiff <= 0) {
      return initialBottomRight
    }
    if (yDiff <= 0) {
      return initialTopLeft
    }
    return initialBottomLeft
  }
}
