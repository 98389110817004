import { css } from '@emotion/css'

export default css(`
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    .close-wrapper {
      cursor: pointer
    }
  }
`)
