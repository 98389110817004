import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import type { LogActivityProps } from './hook/types'
import LogActivityLayout from './components/LogActivityLayout'
import LogTitle from './components/LogTitle'

import CLASS_NAME from './styles'

const LeadDetailsLogActivityBlock: FC<LogActivityProps> = (props) => {
  return (
    <div className={cnx(CLASS_NAME, 'grey-background')}>
      <div className='schedule-log-task'>
        <LogTitle logTypeId={props.logTypeId} />
        <LogActivityLayout
          {...props}
        />
      </div>
    </div>
  )
}

export default LeadDetailsLogActivityBlock
