import { css } from '@emotion/css'

export const POPOVER_CLASS_NAME = css(`
  &.cf-popover-content {
    padding: 8px 0;
    min-width: 260px;
    z-index: 1000;
    box-shadow: 0px 24px 38px 0px rgba(58, 71, 78, 0.10);

    .cf-dropdown-listbox {
      padding: 0
    }
  }

  .cf-options-divider { margin: 8px 0; }

  .cf-dropdown-option {
    padding: 12px 16px;

    > div { 
      height: 48px;

      div:last-child {
        flex-grow: 1; 
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`)

export const CLASS_NAME = css(`
  .action-bar {
    display: flex;
    align-items: center;

    .states-filter {
      .cf-select-display-container {
        height: 32px;
        background: #F3F3F3;
        border: none;
        border-radius: 8px;
        padding: 6px 8px;
        padding-right: 4px;
        display: flex;
        align-items: center;

        .cf-display {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
          border: none;
          outline: none;
          resize: none;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .cf-end-adornment {
          display: flex;
          align-items: center;

          > div { height: 16px; }

          :has(.cf-dropdown-clear-button) { .cf-dropdown-trigger-button { display:none; } }

          .cf-dropdown-clear-button {
            margin-top: 2px;

            svg {
              width: 20px;
              height: 20px;
            }
          }

          .cf-dropdown-trigger-button {
            margin-top: 4px;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
`)
