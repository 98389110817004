import type { Group, Groups, Rows } from '@carfluent/common'

import type { ReportLeadBySourceItem } from 'api/types'

export const getAmount = (items: ReportLeadBySourceItem[]): Record<string, number> =>
  items.reduce<Record<string, number>>((res, { leadsSourceCategory }) => {
    const currentAmount = res[leadsSourceCategory]
    res[leadsSourceCategory] = currentAmount == null ? 1 : currentAmount + 1
    return res
  }, {})

export const groupByCategory = (makesAmount: Record<string, number>) => (rows: Rows<ReportLeadBySourceItem>): Groups<ReportLeadBySourceItem, string> => {
  const groups = rows.reduce((acc: Map<string, Group<ReportLeadBySourceItem, string>>, row) => {
    const groupId = row.original.leadsSourceCategory ?? ''
    const group = acc.get(groupId) ?? { rows: [], key: '' }

    acc.set(groupId, {
      rows: [...group.rows, row],
      key: `${group.key},${row.id}`,
      amount: makesAmount[groupId] ?? 0,
      meta: groupId
    })

    return acc
  }, new Map())

  return [...groups.values()]
}
