import type { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { cnx, Button } from '@carfluent/common'

import UnsavedChangesDialog from 'components/dialogs/UnsavedChanges'
import SaveCloseButtonsPanel from 'components/common/SaveCloseButtonsPanel'
import FIPackageSetup from 'pages/settings/FISettings/components/FIPackageSetup'

import PackageCustomization from './components/PackageCustomization'
import TemplatePreviews from './components/TemplatePreviews'
import CoverageTemplateItem from './components/CoverageTemplateItem'
import Loader from './components/Loader'

import useFISettings from './hook'
import CLASS_NAME from './styles'

const FISettings: FC = () => {
  const {
    isSubmitting,
    dealerCoverageViewTemplateId,
    coverageTemplates,
    openedTemplate,
    setOpenedTemplate,
    onChangeTemplate,
    submitUpdate,
    onReset,
    isOpenUnsavedChanges,
    onCloseUnsavedDialog,
    onDontSaveAndClose,
    onSaveAndClose,
    isFormChanged,
    packages,
    onChangePackages,
    onOpenFIPackageSetup,
    isFIPackageSetupOpen,
    onSubmitPackageSetup,
    isLoading,
    defaultCoverages,
    markups
  } = useFISettings()

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={cnx('g-submenu-content', 'g-margin-top-big', CLASS_NAME)}>
      <div className='title-and-button'>
        <h5>F&I settings</h5>
      </div>

      <h6 className='title-and-button'>
        Package setup

        <Button
          color='secondary'
          variant='outlined'
          className='edit-btn'
          onClick={onOpenFIPackageSetup}
          isContentSized
        >
          Edit
        </Button>
      </h6>

      <PackageCustomization
        onChange={onChangePackages}
        packages={packages}
        defaultCoverages={defaultCoverages}
      />

      <h6 className='title'>Warranty website view</h6>

      <div className='products-view'>
        {coverageTemplates.map((item, i) =>
          <CoverageTemplateItem
            key={item.id}
            onClick={() => setOpenedTemplate(i)}
            isSelected={dealerCoverageViewTemplateId === item.id}
            imgSrc={item.imageUrlSmall}
            name={item.name}
          />
        )}
      </div>

      <TemplatePreviews
        setOpenedView={setOpenedTemplate}
        coverageTemplates={coverageTemplates}
        openedView={openedTemplate}
        dealerCoverageViewTemplateId={dealerCoverageViewTemplateId}
        onChangeTemplate={onChangeTemplate}
      />

      <UnsavedChangesDialog
        content='Your changes will be lost if you dont save them.'
        isOpen={isOpenUnsavedChanges}
        onCloseDialog={onCloseUnsavedDialog}
        isSubmitting={isSubmitting}
        onDontSaveAndClose={onDontSaveAndClose}
        onSaveAndClose={onSaveAndClose}
      />

      <SaveCloseButtonsPanel
        isSubmitting={isSubmitting}
        handleSubmit={submitUpdate}
        handleReset={onReset}
        titleCloseButton='DISCARD'
        className={cnx('action-bar', isFormChanged && 'is-shown')}
      />

      <FIPackageSetup
        markups={markups}
        packages={packages}
        isOpen={isFIPackageSetupOpen}
        onClose={onOpenFIPackageSetup}
        onSubmit={onSubmitPackageSetup}
        defaultCoverages={defaultCoverages}
      />
    </div>
  )
}

export default observer(FISettings)
