import { isTruthy } from 'utils/general'

export interface BlankWindowConfig {
  body?: string
  isPopup?: boolean
  styles?: string
}

export interface BlankWindowReturn {
  close: () => void
  focus: () => void
  showLoader: () => void
  showBody: (body?: string) => void
}

const BlankWindow = (conf: BlankWindowConfig = {}): BlankWindowReturn => {
  const wnd = window.open(
    '',
    '_blank',
    isTruthy(conf.isPopup)
      ? 'popup=1,toolbar=0,location=0,directories=0,status=0,menubar=0,scrollbars=1,resizable=1,fullscreen=yes'
      : ''
  )

  const focus = (): void => {
    wnd?.focus()
  }

  let loaderTimerId: number

  const showLoader = (): void => {
    if (wnd == null) {
      return
    }

    let dots = 0

    loaderTimerId = wnd.setInterval(() => {
      wnd.document.body.innerHTML = getLoaderHtml(dots)
      dots = dots >= 3 ? 0 : (dots + 1)
    }, 500)
  }

  const showBody = (body?: string): void => {
    try {
      wnd?.clearInterval(loaderTimerId)
      wnd?.document.write(getHtml(conf.styles ?? '', body ?? conf.body ?? ''))
      wnd?.document.close()
    } catch (err) {
      console.error(err)
    }
  }

  const close = (): void => {
    wnd?.close()
  }

  return {
    close,
    focus,
    showLoader,
    showBody
  }
}

export default BlankWindow

// ========================================== //

const getLoaderHtml = (dots = 1): string => {
  return `Processing${'.'.repeat(dots)}`
}

const getHtml = (styles: string, body: string): string => {
  return `
    <!DOCTYPE html>
    <html lang="en">
      ${getHtmlHead(styles)}
      ${getHtmlBody(body)}
    </html>
  `
}

const getHtmlHead = (styles: string): string => {
  return `
    <head>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content="CarFluent Dealer Application" />
      <title>Preview</title>
      <link rel='preload' as='style' href='https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap'>
      <link rel='stylesheet' href='https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap'>
      <style>
        ${styles}
      </style>
    </head>
  `
}

const getHtmlBody = (body: string): string => {
  return `
    <body>
      ${body}
    </body>
  `
}
