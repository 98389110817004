import { type FC } from 'react'

const EMPTY_ROWS_COUNT = 10

const LoadingRows: FC = () => (
  <>
    {new Array(EMPTY_ROWS_COUNT).fill(null).map((_, i) => (
      <tr key={i}>
        <td className='cf-car-cell'><div className='cf-loading-row' /></td>
        <td className='cf-mileage-cell'><div className='cf-loading-row' /></td>
        <td className='cf-stock-cell'><div className='cf-loading-row' /></td>
        <td className='cf-status-cell'><div className='cf-loading-row' /></td>
        <td className='cf-age-cell'><div className='cf-loading-row' /></td>
        <td className='cf-vin-cell'><div className='cf-loading-row' /></td>
        <td className='cf-sale-price-cell'><div className='cf-loading-row' /></td>
        <td className='cf-cost-cell'><div className='cf-loading-row' /></td>
      </tr>
    ))}
  </>
)

export default LoadingRows
