import { type BankStatementTransactionsCategorizePayload } from 'api/types'
import getControlId from 'utils/accounting/getControlId'
import getDealId from 'utils/accounting/getDealId'
import getCustomerId from 'utils/accounting/getCustomerId'
import getVendorId from 'utils/accounting/getVendorId'

import { type ReviewFormData } from './types'

const serializeCategorizeFormData = (data: ReviewFormData): BankStatementTransactionsCategorizePayload => {
  return {
    accountId: Number(data.account?.id ?? -1),
    control: getControlId(data.control),
    customerId: getCustomerId(data.entity),
    dealId: getDealId(data.control),
    description: data.description,
    referenceNumber: data.referenceNumber,
    vendorId: getVendorId(data.entity)
  }
}

export default serializeCategorizeFormData
