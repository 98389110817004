import { css } from '@emotion/css'

export const CANCEL_BUTTON_STYLE = css(`
  &.cf-button-root {
    margin-left: 40px;

    span {
      text-transform: uppercase;
    }
  }
`)

export const SUBMIT_BUTTON_STYLE = css(`
  &.cf-button-root {
    margin-right: 40px;
    
    span {
      text-transform: uppercase;
    }
  }
`)

export const DELETE_BUTTON_STYLE = css(`
  &.cf-button-root {
    grid-column: 1/3;

    span {
      font-weight: 500;
      font-size: 10px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
  }
`)
