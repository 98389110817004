import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
    
  > .block-skeleton.field-loader {
    flex: 1;
    min-width: 145px;
    max-width: 193px;
  }
`)
