import { createContext } from 'react'
import { useFormik, FormikConfig, FormikValues } from 'formik'

/**
 * https://stackoverflow.com/a/64919133/2799055
 */
class Wrapper<T extends FormikValues = FormikValues> {
  // wrapped has no explicit return type so we can infer it
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  wrapped (e: FormikConfig<T>) {
    return useFormik<T>(e)
  }
}

export type UseFormikReturnType<T> = ReturnType<Wrapper<T>['wrapped']>

export type FormCTXProps<V extends FormikValues = FormikValues> =
  UseFormikReturnType<V> & { disabled?: boolean }

/**
 * Do not remove this `any` type.
 * It will be overrided by type provided to useForm/useField hooks.
 * See more details here:
 * https://github.com/formium/formik/blob/b9cc2536a1edb9f2d69c4cd20ecf4fa0f8059ade/packages/formik/src/FormikContext.tsx#L5
 */
export const FormCTX = createContext<FormCTXProps<any>>(undefined as any)

export default FormCTX
