const getVehicleVideoUrl = (
  fileVersion?: string,
  fileId?: number | null
): string => {
  const linkToEnv = process.env.REACT_APP_FILES ?? ''
  const partOfPosterUrl = `${fileVersion ?? 'thumbnail'}/${fileId ?? ''}`
  return `${linkToEnv}/api/v1/Download/vehicle-videos/${partOfPosterUrl}`
}

export default getVehicleVideoUrl
