import { type AmountType } from 'types/filters'

const serializeAmountFilters = (amount: AmountType): AmountType => {
  const { equal, from, to } = amount

  if (equal != null) {
    return { equal, from: null, to: null }
  }

  return { from, to, equal: null }
}

export default serializeAmountFilters
