import { type FC, useCallback, useEffect, useState } from 'react'
import { Modal, Banner, FormInput, FormTextArea, Loader, useForm } from '@carfluent/common'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'

import { joinPartsBySpace } from 'utils/view_helper'
import { required } from 'utils/validationPresets'
import type { EntityId } from 'api/types'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import DocumentsApiProvider from 'api/documents.api'

import CLASS_NAME from './styles'

interface SentForSignInFormProps {
  onClose: () => void
  isOpen: boolean
  email: string | null
  coBuyerEmail: string | null
  make: string
  model: string
  year: number | null
  dealId: EntityId
  selectedForms: number[]
  onSubmit: () => Promise<void>
}

interface FormValues {
  subject: string | null
  body: string | null
}

const baseValues = {
  subject: null,
  body: 'Please complete these forms in order to finalize your vehicle purchase. %%DEALER_NAME%% uses DocuSign to facilitate electronic signing.'
}

const validationRules = { subject: required }
const MAX_ROWS = 4
const MAX_SUBJECT_LENGTH = 240
const MAX_BODY_LENGTH = 600

const SentForSignInForm: FC<SentForSignInFormProps> = ({
  isOpen,
  onClose,
  email,
  coBuyerEmail,
  make,
  model,
  year,
  dealId,
  selectedForms,
  onSubmit: _onSubmit
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { showAlert } = useCustomSnackbar()
  const [pdfUrl, setPdfUrl] = useState<string | null>(null)

  const submitAction = useCallback(async (values: FormValues) => {
    try {
      setIsLoading(true)

      await DocumentsApiProvider.sendDocumentDealForms(dealId, {
        forms: selectedForms,
        ...values
      })
      await _onSubmit()
      onClose()
    } catch {
      showAlert('Cannot send forms for signing.')
    } finally {
      setIsLoading(false)
    }
  }, [selectedForms, onClose, showAlert])

  const {
    onChange,
    onBlur,
    values,
    errors,
    touched,
    resetForm,
    onSubmit
  } = useForm<FormValues>({
    submitAction,
    baseValues,
    validationRules
  })

  useEffect(() => {
    const shouldFetchPdf = selectedForms.length > 0 && isOpen

    if (!shouldFetchPdf) {
      return
    }

    const runEffect = async (): Promise<void> => {
      const res = await DocumentsApiProvider.downloadDocumentDealForms(
        dealId,
        selectedForms
      )

      const url = URL.createObjectURL(new Blob([res], { type: 'application/pdf' }))
      setPdfUrl(url)
    }

    void runEffect()
  }, [selectedForms, isOpen])

  useEffect(() => {
    if (!isOpen) {
      resetForm({
        ...baseValues,
        subject: `Signature Needed: ${joinPartsBySpace(year, make, model)}`
      })
      setPdfUrl(null)
    }
  }, [
    isOpen,
    make,
    model,
    year,
    resetForm
  ])

  return (
    <Modal
      title='Send for signing'
      onClose={onClose}
      isOpen={isOpen}
      className={CLASS_NAME}
      renderFooterActions={(
        <CancelSubmitActionsFooter
          isLoading={isLoading}
          onSubmit={onSubmit}
          onClose={onClose}
          submitTitle='SEND'
        />
      )}
    >
      <Banner
        isOpen
        type='info'
        message='Confirm the intended recipients for the signing forms below. To make changes, close this dialog and modify the deal. Signing is $5 per send, billed to your account.'
      />

      <FormInput
        id='email'
        value={`${email ?? ''}${coBuyerEmail != null ? `, ${coBuyerEmail}` : ''}`}
        disabled
        label='To'
      />

      <div className='pdf-viewer'>
        {pdfUrl != null
          ? <iframe src={pdfUrl} width='100%' height='100%' />
          : <Loader color='dark' />}
      </div>

      <FormInput
        id='subject'
        value={values.subject}
        onChange={onChange}
        onBlur={onBlur}
        label='Email subject'
        error={errors.subject}
        touched={touched.subject}
        maxLength={MAX_SUBJECT_LENGTH}
      />

      <FormTextArea
        id='body'
        value={values.body}
        label='Email body'
        maxLength={MAX_BODY_LENGTH}
        rows={MAX_ROWS}
        maxRows={MAX_ROWS}
        onChange={onChange}
      />
    </Modal>
  )
}

export default SentForSignInForm
