import { ChangeEvent, FocusEvent, RefObject, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import capitalize from 'lodash-es/capitalize'
import kebabCase from 'lodash-es/kebabCase'
import { FormControl, FormControlLabel, FormHelperText, Typography } from '@material-ui/core'
import { cn } from '@carfluent/common'
import Checkbox from 'components/common/Checkbox'

import type { BaseFormField } from '../../types'
import { useField } from '../../hook'
import useFormHelperStyles from '../../styles'
import useStyles from './styles'

export interface FormCheckboxProps extends BaseFormField<boolean | null>, Partial<{
  label: string
  name: string
  placeholder: string
  className?: string
  classes: { control?: string, label?: string }
  dataTestId: string
  disabled: boolean
  onBlur: (evt: FocusEvent<HTMLButtonElement>) => void
  onChange: (id: string, value: boolean) => void
  innerRef: RefObject<HTMLDivElement>
  icon?: React.ReactNode
  checkedIcon?: React.ReactNode
}> {}

function FormCheckbox (props: FormCheckboxProps): JSX.Element {
  const {
    id,
    label,
    name,
    classes,
    className,
    dataTestId,
    disabled,
    onBlur,
    onChange,
    innerRef,
    icon,
    checkedIcon
  } = props

  const formHelperText = useFormHelperStyles()
  const styles = useStyles()

  const {
    value,
    error,
    isErrorVisible
  } = useField<boolean | null>(props)

  const handleChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.checked
    onChange?.(id, value)
  }, [id, onChange])

  return (
    <FormControl className={cn(classes?.control, className)}>
      <FormControlLabel
        id={id}
        name={name ?? id}
        label={<Typography ref={innerRef}>{label ?? capitalize(id)}</Typography>}
        classes={{
          root: `${classes?.control ?? ''}`.trim(),
          label: `${styles.labelText} ${classes?.label ?? ''}`.trim()
        }}
        control={
          <Checkbox
            icon={icon ?? null}
            checkedIcon={checkedIcon ?? null}
            checked={value ?? false}
            disabled={disabled}
            onChange={handleChange}
            onBlur={onBlur}
            data-test-id={dataTestId ?? kebabCase(id)}
          />
        }
      />

      {isErrorVisible && (
        <FormHelperText classes={{ root: formHelperText }}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default observer(FormCheckbox)
