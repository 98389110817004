import type { FC } from 'react'
import type { IconProps } from 'types'

const IconSVGCheckboxIndeterminate: FC<IconProps> = ({ color = '#FFFFFF', width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='1' y='1' width='22' height='22' rx='1' fill='black' stroke='black' strokeWidth='2' />
      <rect x='5' y='11' width='14' height='2' fill={color} />
    </svg>
  )
}

export default IconSVGCheckboxIndeterminate
