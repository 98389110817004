import { type ReactNode } from 'react'
import { cnx } from '@carfluent/common'

const renderDisplayField = (label: string, value: string | null, className?: string): ReactNode => (
  <div>
    <p className={cnx('info-title', className)}>{label}</p>
    <p className='info-value'>{value}</p>
  </div>
)

export default renderDisplayField
