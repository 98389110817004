import type { VehicleById, VehicleDetailsByVin, VehicleImage, VehiclePrices } from 'api/types'
import { formatCurrencyAccounting } from '@carfluent/common'
import { toJS } from 'mobx'

import type { ImageDescriptor } from 'components/inventory/ImageItem'
import { parseTrim } from 'components/common/CarDetailsFields/hook/parser'
import { ImageState } from 'components/inventory/ImageItem'
import { toDateOrNull } from 'utils/parse_date'
import { guidRegex } from 'utils/regex_helper'
import { toNumberOrNull } from 'utils/parse_string'
import { parseDescription } from 'utils/wysiwyg'
import parseDealerAccount from 'utils/parseDealerAccount'
import {
  parseCylindersToForm,
  parseDriveTrainToForm,
  parseTransmissionToForm
} from 'pages/inventory/NewVehicle/hook/parser' // AZ-TODO: wrong import, move to shared consts
import { DEFAULT_VEHICLE_REPRICING } from 'api/defaults/vehicles'

import type {
  PriceHistoryMergedItem,
  PriceHistoryItem
} from 'pages/inventory/VehicleDetails/components/GeneralTabPanel/components/PriceHistory'
import type { VehicleRepricingHistoryDto } from 'api/types/vehicles.types'
import { formatUTCDateTimeForView, joinPartsBySpace } from 'utils/view_helper'

import type { UpdateVehicleDictionaries, UpdateVehicleFormData } from './types'
import { getTitleStageValue } from './utils'

export const parseVehicleById = (
  _apiData: VehicleById,
  dictionaries: UpdateVehicleDictionaries
): UpdateVehicleFormData => {
  const apiData = toJS(_apiData)
  const vehicleRepricingDetails = toJS(apiData.vehicleRepricingDetails)

  return {
    ...apiData,
    vehicleRepricingDetails: vehicleRepricingDetails ?? DEFAULT_VEHICLE_REPRICING,
    salePrice: apiData.salePrice ?? 0,
    description: parseDescription(apiData.description),
    imageCount: apiData.imageCount ?? 0,
    inventoryDate: toDateOrNull(apiData.inventoryDate),
    titleReceived: toDateOrNull(apiData.titleReceived),
    titleSent: toDateOrNull(apiData.titleSent),
    titleStatus: dictionaries.TitleStatus.find(d => d.id === apiData.titleType) ?? null,
    titleStage: getTitleStageValue(dictionaries.TitleStage, apiData.titleStageId, apiData.isTitleReceived),
    registrationStage: dictionaries.RegistrationStage.find(d => d.id === apiData.registrationStageId) ?? null,
    titleState: dictionaries.InventoryState.find(d => d.id === apiData.titleStateId) ?? null,
    body: dictionaries.Body.find(d => d.id === apiData.bodyType) ?? null,
    doors: dictionaries.Doors.find(d => d.id === apiData.doorCount) ?? null,
    fuelType: dictionaries.Fuel.find(d => d.id === apiData.fuelType) ?? null,
    cylinderCounts: dictionaries.Cylinders.find(d => d.id === apiData.cylinderCount) ?? null,
    transmission: dictionaries.Transmission.find(d => d.id === apiData.transmissionType) ?? null,
    drivetrain: dictionaries.Drivetrain.find(d => d.id === apiData.drivetrainType) ?? null,
    genericColor: dictionaries.GenericColor.find(d => d.id === apiData.genericColor) ?? null,
    genericInterior: dictionaries.GenericInterior.find(d => d.id === apiData.genericInterior) ?? null,
    disabilityEquipped: dictionaries.DisabilityEquipped.find((d) => d.id === Number(apiData.isDisabilityEquipped)) ?? null,
    make: apiData.make?.trim() ?? null,
    trim: parseTrim(apiData.trim),
    buyer: parseDealerAccount(apiData.buyer),
    titleNote: apiData.titleNote
  }
}

export const parseVehicleByVIN = (
  formData: UpdateVehicleFormData,
  apiData: VehicleDetailsByVin,
  dictionaries: UpdateVehicleDictionaries
): UpdateVehicleFormData => {
  return {
    ...formData,
    doors: null,
    drivetrain: parseDriveTrainToForm(dictionaries.Drivetrain, apiData.drivetrainTypeString),
    engine: apiData.engine,
    cylinderCounts: parseCylindersToForm(dictionaries.Cylinders, apiData.engine),
    transmission: parseTransmissionToForm(dictionaries.Transmission, apiData.transmissionTypeString),
    make: apiData.make?.trim() ?? null,
    /**
     * DD-NOTE: year might be a number which breaks further parsers
     * hot-fix - always convert to string forcefully
     */
    year: toNumberOrNull((apiData.year ?? '').toString()),
    trim: parseTrim(apiData.trim),
    fuelType: null,
    model: apiData.model
  }
}

export const parseImages = (images: VehicleImage[] = []): ImageDescriptor[] => {
  const linkForVideoPoster = (id: number): string =>
  `${process.env.REACT_APP_FILES ?? ''}/api/v1/Download/vehicle-videos/thumbnail/${id}`

  const fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi

  return images
    .sort((x, y) => x.order - y.order)
    .map(item => ({
      fileId: item.fileId ?? null,
      originalUrl: item.fileId != null
        ? linkForVideoPoster(item?.fileId)
        : item.originalUrl,
      thumbnailUrl: item.fileId != null
        ? linkForVideoPoster(item?.fileId)
        : item.thumbnailUrl,
      originalSizeKb: item.originalSizeKb,
      fileName: item.fileName ?? parseFileName(item.originalUrl),
      state: ImageState.Uploaded,
      videoUrl: `${process.env.REACT_APP_FILES ?? ''}/api/v1/Download/vehicle-videos/${item?.fileId ?? ''}` ?? '',
      fileExtension: item?.fileName.match(fileExtensionPattern)?.[0] ?? ''
    }))
}

const parseFileName = (url: string): string => {
  return url.split('/').slice(-1).join().replace(guidRegex, '').replace(/^-/, '')
}

interface PriceHistoryProps {
  priceHistory: VehiclePrices[]
  repriceHistory: VehicleRepricingHistoryDto[]
}

const DATE_PART = 'MM/dd/yy'
const FORMAT = `${DATE_PART} hh:mm a`

enum AutomaticallyUser {
  Id,
  Name = 'Automatically'
}

enum RepricingActions {
  TurnOff = 'turned off',
  TurnOn = 'turned on',
  ChangeOn = 'change on'
}

const getCreatedByText = (user: VehiclePrices['createdByUser']): string => {
  return user.id === AutomaticallyUser.Id
    ? AutomaticallyUser.Name
    : joinPartsBySpace(user.dealerFirstName ?? '-', user.dealerLastName)
}

export const parsePriceHistory = ({
  priceHistory,
  repriceHistory
}: PriceHistoryProps): PriceHistoryMergedItem[] => {
  const priceHistorySorted = [...priceHistory]

  priceHistorySorted.sort((a, b) => {
    return Date.parse(a.createdDate) - Date.parse(b.createdDate)
  })

  const parsedPrice = priceHistorySorted.map((price, i) => {
    let status: PriceHistoryItem['status'] = 'not-changed'

    if (i !== 0) {
      status = price.salePrice > priceHistorySorted[i - 1].salePrice ? 'increased' : 'decreased'
    }

    return {
      status,
      ...price
    }
  }, [])

  const repriceHistorySorted = [...repriceHistory]
  repriceHistorySorted.sort((a, b) => {
    return Date.parse(a.activationChangeDate) - Date.parse(b.activationChangeDate)
  })

  const parsedRepriceHistory = repriceHistorySorted.map((price, i) => {
    let action = RepricingActions.TurnOn

    if (i !== 0) {
      action = price.isEnabled !== repriceHistorySorted[i - 1].isEnabled
        ? price.isEnabled
          ? RepricingActions.TurnOn
          : RepricingActions.TurnOff
        : RepricingActions.ChangeOn
    }

    return {
      ...price,
      action
    }
  })

  const listForSorting = [...parsedPrice, ...parsedRepriceHistory]

  listForSorting.sort((a, b) => {
    const aDate = 'createdDate' in a ? a.createdDate : a.activationChangeDate
    const bDate = 'createdDate' in b ? b.createdDate : b.activationChangeDate

    return Date.parse(bDate) - Date.parse(aDate)
  })

  return listForSorting.map((item, i) => {
    if ('salePrice' in item) {
      return {
        id: `${item.salePrice}-${item.createdDate}-${item.createdByUser.dealerLastName}`,
        salePrice: formatCurrencyAccounting(item.salePrice, { emptyValues: '$-' }),
        createdDate: formatUTCDateTimeForView(item.createdDate, FORMAT) ?? '-',
        createdBy: getCreatedByText(item.createdByUser),
        status: item.status
      }
    }

    const user = item.modifiedByUser ?? item.createdByUser

    return {
      id: item.id,
      deductionDescription: (item.dayNumber != null && item.priceDeduction != null && item.action !== RepricingActions.TurnOff)
        ? ` (every ${item.dayNumber} day${item.dayNumber === 1 ? '' : 's'} on ${item.priceDeduction}$)`
        : null,
      description: `Auto repricing ${item.action} ${
        formatUTCDateTimeForView(item.activationChangeDate, FORMAT) ?? '-'
      } by ${joinPartsBySpace(user.dealerFirstName ?? '-', user.dealerLastName)}`
    }
  })
}
