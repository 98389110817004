import { UI } from '@carfluent/common'

import type { TitleReportItem } from '../../hook/types'

import CLASS_NAME from './styles'

const { GroupContentHOC } = UI

const GroupContent = GroupContentHOC<TitleReportItem, string>((group) => {
  return (
    <div className={CLASS_NAME}>
      <p>{group.meta}</p>
      <p className='text-counter'>
        ({group.amount})
      </p>
    </div>
  )
})

export default GroupContent
