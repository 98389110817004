import axios from 'axios'

type ErrorsParams = Record<string, string>
type EmtyObj = Record<string, never>

const parseError = (err: Error): ErrorsParams | EmtyObj => {
  if (!axios.isAxiosError(err)) {
    return {}
  }

  if (Object.keys(err?.response?.data?.errors).length !== 0) {
    return Object.entries(err?.response?.data?.errors).reduce((result: ErrorsParams, [key, value]) => {
      result[key] = Array.isArray(value) ? value[0] : value
      return result
    }, {})
  }

  return {}
}

export default parseError
