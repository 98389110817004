import { type FC } from 'react'
import { NavLink } from 'react-router-dom'
import { cnx } from '@carfluent/common'

import { DealState as ApiDealState } from 'api/types'
import { DealState } from 'constants/names'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'
import TooltipedText from 'components/common/TooltipedText'
import CopyButton from 'components/common/CopyButton'
import CarImage from 'components/common/CarImage'
import CreditApplicationLink from 'components/deals/CreditApplicationLink'
import PersonaStatus from 'components/deals/PersonaStatus'
import DealStatus from 'components/common/DealStatus'
import DepositedLabel from 'components/common/DepositedLabel'
import DealNextAction from 'components/common/DealNextAction'

import { type DealCardProps } from './types'
import useDealCard from './hook'
import { CLASS_NAME, TOOLTIP_CLASS_NAME } from './styles'
import DealPaidState from 'components/common/DealPaidState'

const DealCard: FC<DealCardProps> = ({
  className,
  isLoading = false,
  ...hookProps
}) => {
  const { showSuccess } = useCustomSnackbar()
  const {
    buyerName,
    carImageUrl,
    carName,
    creditAppLinkName,
    dealId,
    dealState,
    dealStepName,
    dealStepDate,
    inventoryUrl,
    isDealSold,
    hasDepositLabel,
    isPersonaPassed,
    nextDealAction,
    paymentInfo,
    stockNumber,
    vin,
    websiteUrl,
    dealPaidState
  } = useDealCard(hookProps)

  // ========================================== //

  const dealStepElem = isDealSold
    ? <span className='text-bold'>{dealStepName ?? '-'}:</span>
    : <>Stopped on <span className='text-bold'>{dealStepName ?? '-'}:</span></>

  const isSold = dealState === ApiDealState.Completed

  return (
    <div className={cnx('g-panel', 'g-panel--complete', CLASS_NAME, 'deal-card', className)}>
      <div className='cell-top'>
        <BlockSkeleton className='deal-title' isLoading={isLoading}>
          <h6>Deal id: {dealId}</h6>
        </BlockSkeleton>

        {!isLoading && (
          <div className='deal-chips'>
            <DealStatus dealState={dealState} />
            {hasDepositLabel && <DepositedLabel className='deal-status-deposit-label' />}
            <DealNextAction action={nextDealAction} />
            {isSold && (<DealPaidState state={dealPaidState} />)}
          </div>
        )}
      </div>

      <BlockSkeleton className='cell-photo' isLoading={isLoading}>
        <CarImage
          height={64}
          width={99}
          vehicleImageUrl={carImageUrl}
        />
      </BlockSkeleton>

      <div className='cell-buyer-info cell-two-lines'>
        <BlockSkeleton isLoading={isLoading} className='line'>
          <div className='property-name'>Buyer:</div>
          <TooltipedText value={buyerName?.length === 0 ? '-' : buyerName} rootClassName='property-value' />
        </BlockSkeleton>

        <BlockSkeleton isLoading={isLoading} className='line skeleton-80'>
          <div className='property-name'>Payment:</div>
          <TooltipedText value={paymentInfo ?? '-'} rootClassName='property-value' />
        </BlockSkeleton>
      </div>

      <div className='cell-car-info cell-two-lines'>
        <BlockSkeleton isLoading={isLoading} className='line'>
          <div className='property-name'>Car:</div>
          <TooltipedText value={carName ?? '-'} rootClassName='property-value' />
        </BlockSkeleton>

        <BlockSkeleton isLoading={isLoading} className='line line--bottom skeleton-80'>
          <div className='property-name'>VIN:</div>
          <TooltipedText value={vin ?? '-'} rootClassName='property-value' />

          {(vin != null) && (
            <CopyButton nameOfField='VIN' value={vin ?? ''} showConfirmation={showSuccess} />
          )}
        </BlockSkeleton>
      </div>

      <div className='cell-stock cell-two-lines'>
        <BlockSkeleton isLoading={isLoading} className='line line--top'>
          <div className='property-name'>Stock #:</div>

          {(inventoryUrl != null) && (
            <NavLink className='property-value no-decoration' to={inventoryUrl}>
              <TooltipedText value={stockNumber} rootClassName={TOOLTIP_CLASS_NAME} className='website-link' />
            </NavLink>
          )}

          {(inventoryUrl == null) && (
            <TooltipedText value={stockNumber} rootClassName='property-value' />
          )}

          {(stockNumber != null) && (
            <CopyButton nameOfField='Stock #' value={stockNumber ?? ''} showConfirmation={showSuccess} />
          )}
        </BlockSkeleton>

        <BlockSkeleton isLoading={isLoading} className='line skeleton-80'>
          <div className='property-name'>Website:</div>

          <div>
            {(websiteUrl != null)
              ? (<a className='website-link' href={websiteUrl} target='_blank' rel='noreferrer'>Link</a>)
              : '-'}
          </div>
        </BlockSkeleton>
      </div>

      <div className='cell-credit-app cell-two-lines'>
        <BlockSkeleton isLoading={isLoading} className='line'>
          <div className='property-name'>Credit App:</div>

          {(creditAppLinkName != null)
            ? <CreditApplicationLink name={creditAppLinkName} className='website-link' />
            : '-'}
        </BlockSkeleton>

        <BlockSkeleton isLoading={isLoading} className='line skeleton-45'>
          <div className='property-name'>Persona:</div>
          {(isPersonaPassed != null) && <PersonaStatus isPassed={isPersonaPassed} className='property-value' />}
          {(isPersonaPassed == null) && '-'}
        </BlockSkeleton>
      </div>

      <div className='cell-workflow-step cell-two-lines'>
        <BlockSkeleton isLoading={isLoading} className='line skeleton-93'>
          <TooltipedText
            className='property-name'
            rootClassName='property-value'
            value={dealStepElem}
          />
        </BlockSkeleton>

        <BlockSkeleton isLoading={isLoading} className='line skeleton-45'>
          <TooltipedText
            rootClassName='property-value'
            value={dealStepDate ?? '-'}
          />
        </BlockSkeleton>
      </div>
    </div>
  )
}

export default DealCard
