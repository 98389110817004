import { type FC, type MouseEvent } from 'react'
import { Menu, MenuItem, type MenuProps } from '@material-ui/core'
import { cnx } from '@carfluent/common'
import { RecipientsFilterOptions } from 'types/enums'

interface FilterItem {
  onClick: () => void
  title: string
  disabled?: boolean
  id: RecipientsFilterOptions
}

interface AddFiltersProps {
  onOpen: (e: MouseEvent<HTMLButtonElement>) => void
  onClose: () => void
  anchorEl: HTMLElement | null
  filterItems: FilterItem[]
  selectedFilter: number | null
  disabled?: boolean
}

const ANCHOR_ORIGIN: MenuProps['anchorOrigin'] = {
  vertical: 'bottom',
  horizontal: 'left'
}

const TRANSFORM_ORIGIN: MenuProps['transformOrigin'] = {
  vertical: 0,
  horizontal: 'left'
}

const AddFilters: FC<AddFiltersProps> = ({
  onOpen,
  onClose,
  anchorEl,
  filterItems,
  selectedFilter,
  disabled = false
}) => {
  return (
    <div className={cnx('button-container', disabled && 'disabled')}>
      <button className='filter-button' onClick={onOpen}>
        <div className='plus'>
          <span />
          <span />
        </div>

        <span className='filter-button-label'>
          Add filter
        </span>
      </button>
      <Menu
        onClose={onClose}
        anchorEl={anchorEl}
        open={anchorEl != null}
        anchorOrigin={ANCHOR_ORIGIN}
        transformOrigin={TRANSFORM_ORIGIN}
        getContentAnchorEl={null}
      >
        {filterItems.map(({
          title,
          onClick,
          disabled = false,
          id
        }) => (
          <MenuItem
            selected={id === selectedFilter}
            key={title}
            disabled={disabled}
            onClick={onClick}
          >
            {title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default AddFilters
