import type { FC } from 'react'

import ReportLayout from 'components/reports/ReportLayout'
import BackToTop from 'components/common/BackToTop'
import { LoadableTable as Table } from 'components/common/Table'

import GroupContent from './components/GroupContent'
import Filters from './components/Filters'
import { useTitleReport } from './hook'
import { columnDefinitions } from './hook/columns'
import { GROUP_COL_SPAN } from './hook/constants'
import CLASS_NAME, { ROOT_CLASS_NAME } from './styles'

const TitleReport: FC = () => {
  const {
    containerElement,
    emptyTableMessage,
    onSortingChange,
    sorting,
    groupBy,
    filters,
    rows,
    onBottomReached,
    isLoading,
    ...layoutProps
  } = useTitleReport()

  return (
    <ReportLayout
      {...layoutProps}
      title='Title report'
      className=''
      size='md'
      withChildOutsideContainer
      isDateFilterEnabled={false}
      rootClassName={ROOT_CLASS_NAME}
    >
      <div className={CLASS_NAME}>
        <Filters {...filters} isLoading={isLoading} />

        <Table
          columns={columnDefinitions}
          containerElement={containerElement}
          data={rows}
          emptyTableMessage={emptyTableMessage}
          groupBy={groupBy}
          groupColSpan={GROUP_COL_SPAN}
          isLoading={isLoading}
          onBottomReached={onBottomReached}
          onSortingChange={onSortingChange}
          renderGroup={GroupContent}
          sorting={sorting}
          withFixedCell
        />

        <BackToTop />
      </div>
    </ReportLayout>

  )
}

export default TitleReport
