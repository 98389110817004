import { type FC } from 'react'
import {
  cnx,
  FormDropdown,
  FormInput,
  FormNumberInput,
  FormDatePicker
} from '@carfluent/common'

import {
  type ControlListItem,
  type AccountListItem,
  type EntityListItem,
  type TransactionLineVendorDto,
  TransactionTypeId
} from 'api/types'

import { ValidationLength, MAX_PRICE_VALUE } from 'constants/validation'
import isSpecialTransaction from 'utils/accounting/isSpecialTransaction'
import isControlOptionDisabled from 'utils/accounting/isControlOptionDisabled'
import _isCheck from 'utils/accounting/isCheck'
import _isReceivable from 'utils/accounting/isReceivable'
import _isPayable from 'utils/accounting/isPayable'
import ControlOption from 'components/accounting/ControlOption'
import EntityAction, { EntityActionProps } from 'components/accounting/EntityAction'
import EntityOption, { EntityOptionProps } from 'components/accounting/EntityOption'

import { type TransactionFormProps } from './types'
import RecurringLine from '../RecurringLine'
import { CLASS_NAME, CONTROL_CLASS_NAME } from './styles'

const ACCOUNT_LABELS: Map<TransactionTypeId | null, string> = new Map([
  [TransactionTypeId.Receive, 'Deposit to'],
  [TransactionTypeId.Receivable, 'Receivable account'],
  [TransactionTypeId.Check, 'Pay from'],
  [TransactionTypeId.PayBill, 'Pay from']
])

const ENTITY_LABELS: Map<TransactionTypeId | null, string> = new Map([
  [TransactionTypeId.Receive, 'Receive from'],
  [TransactionTypeId.Receivable, 'Receivable entity'],
  [TransactionTypeId.Payable, 'Vendor'],
  [TransactionTypeId.Check, 'Pay to'],
  [TransactionTypeId.PayBill, 'Pay to']
])

const TransactionForm: FC<TransactionFormProps> = ({
  className,
  entityActions,
  dateTimeMin,
  isAutoLineInBanking,
  isBankStatementMatch,
  isBankingTransaction,
  isRecurring,
  isReadonly,
  getControls,
  getRecurringIntervals,
  getReceivableAccounts,
  getVendors,
  getEntities,
  onReceivableControlChange,
  onReceivableEntityChange,
  onVendorChange,
  transactionTypeId = null,
  vendorActions,
  ...formProps
}) => {
  const {
    errors,
    onBlur,
    onChange,
    touched,
    values
  } = formProps

  const isCheck = _isCheck(transactionTypeId)
  const isPayable = _isPayable(transactionTypeId)
  const isReceivable = _isReceivable(transactionTypeId)
  const isDisabledForPrintedCheck = isCheck && (values.lastPrintDate != null)

  const isEntityDisabled = isReadonly || isDisabledForPrintedCheck
  const isAccountDisabled = isReadonly ||
    isBankStatementMatch ||
    isDisabledForPrintedCheck ||
    (!isReceivable && !isPayable && isAutoLineInBanking)

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <div className='cf-form-container'>
        {isRecurring && (
          <div className={cnx('fields-row', 'fields-container-big')}>
            <RecurringLine
              {...formProps}
              getRecurringIntervals={getRecurringIntervals}
              isReadOnly={isReadonly}
              onRecurringIntervalChange={onChange}
            />
          </div>
        )}

        {!isRecurring && (
          <div className={cnx('fields-row', 'fields-container-big')}>
            <FormDatePicker
              className='field-datetime'
              id='dateTime'
              label='Date'
              onBlur={onBlur}
              onChange={onChange}
              minDate={dateTimeMin}
              disabled={isReadonly || isBankStatementMatch || isBankingTransaction}
              value={values.dateTime}
              error={errors.dateTime}
              touched={touched.dateTime}
            />

            {(isPayable || isReceivable) && (
              <FormDatePicker
                className='field-datetime'
                id='dueDate'
                label='Due date'
                onBlur={onBlur}
                onChange={onChange}
                minDate={dateTimeMin}
                disabled={isReadonly}
                value={values.dueDate}
                error={errors.dueDate}
                touched={touched.dueDate}
              />
            )}

            <FormInput
              value={values.referenceNumber}
              error={errors.referenceNumber}
              touched={touched.referenceNumber}
              className={cnx(!isPayable && 'field-reference')}
              id='referenceNumber'
              label='Reference #'
              onBlur={onBlur}
              onChange={onChange}
              maxLength={ValidationLength.COST_REFERENCE_NUM_MAX}
              disabled={isReadonly}
              dataTestId='reference-number'
            />

            {isCheck && (
              <FormNumberInput
                value={values.checkNumber ?? ''}
                error={errors.checkNumber}
                touched={touched.checkNumber}
                className='field-reference'
                id='checkNumber'
                label='Check #'
                onBlur={onBlur}
                onChange={onChange}
                max={MAX_PRICE_VALUE}
                disabled={isReadonly}
                dataTestId='check-number'
                mode='integer'
                isNegativeAllowed={false}
                thousandSeparator={null}
              />
            )}
          </div>
        )}

        {isSpecialTransaction(transactionTypeId) && (
          <>
            <div className={cnx('fields-row', 'fields-container-big')}>
              {isPayable && (
                <FormDropdown<TransactionLineVendorDto>
                  actions={vendorActions}
                  className='field-receivable-dropdown'
                  dataTestId='receivable-vendor'
                  disabled={isReadonly}
                  error={errors.receivableVendor}
                  fetchHandler={getVendors}
                  id='receivableVendor'
                  label={ENTITY_LABELS.get(transactionTypeId)}
                  mode='search'
                  onBlur={onBlur}
                  onChange={onVendorChange}
                  renderAction={renderVendorAction}
                  renderOption={renderVendorOption}
                  renderNoOptions={renderNoOptions}
                  touched={touched.receivableVendor}
                  value={values.receivableVendor}
                />
              )}

              {!isPayable && (
                <>
                  <FormDropdown<AccountListItem>
                    className='field-receivable-dropdown'
                    dataTestId='receivable-account'
                    disabled={isAccountDisabled}
                    error={errors.receivableAccount}
                    fetchHandler={getReceivableAccounts}
                    id='receivableAccount'
                    label={ACCOUNT_LABELS.get(transactionTypeId)}
                    mode='search'
                    onBlur={onBlur}
                    onChange={onChange}
                    renderOption={(option) => <div className='option-item'>{option.name}</div>}
                    renderNoOptions={renderNoOptions}
                    touched={touched.receivableAccount}
                    value={values.receivableAccount}
                  />

                  <FormDropdown<EntityListItem>
                    actions={entityActions}
                    className='field-receivable-dropdown'
                    dataTestId='receivable-entity'
                    disabled={isEntityDisabled}
                    error={errors.receivableEntity}
                    fetchHandler={getEntities}
                    id='receivableEntity'
                    label={ENTITY_LABELS.get(transactionTypeId)}
                    mode='search'
                    onBlur={onBlur}
                    onChange={onReceivableEntityChange}
                    renderAction={(action) => <EntityAction action={action} />}
                    renderOption={(option) => <EntityOption option={option} />}
                    renderNoOptions={renderNoOptions}
                    touched={touched.receivableEntity}
                    value={values.receivableEntity}
                  />
                </>
              )}

              <FormDropdown<ControlListItem>
                id='receivableControl'
                label='Control'
                mode='search'
                error={errors.receivableControl}
                touched={touched.receivableControl}
                dataTestId='receivable-control'
                className='field-receivable-dropdown'
                popoverClassName={CONTROL_CLASS_NAME}
                isOptionDisabled={isControlOptionDisabled}
                renderOption={renderControlOption}
                renderNoOptions={renderNoOptions}
                value={values.receivableControl}
                fetchHandler={getControls}
                onChange={onReceivableControlChange}
                onBlur={onBlur}
                disabled={isReadonly}
              />
            </div>
          </>
        )}

        <div className={cnx('description-row', 'description-container')}>
          <FormInput
            value={values.description}
            error={errors.description}
            touched={touched.description}
            className='field-description'
            id='description'
            label='Description'
            onBlur={onBlur}
            onChange={onChange}
            maxLength={240}
            disabled={isReadonly}
            dataTestId='description'
          />
        </div>
      </div>
    </div>
  )
}

export default TransactionForm

// ========================================== //

const renderControlOption = (option: ControlListItem): JSX.Element => (
  <ControlOption option={option} className='entity-option' />
)

const renderNoOptions = (): JSX.Element => (
  <div className='no-options'>No options found</div>
)

const renderVendorAction = (action: EntityActionProps['action']): JSX.Element => (
  <EntityAction action={action} />
)

const renderVendorOption = (option: EntityOptionProps['option']): JSX.Element => (
  <EntityOption option={option} />
)
