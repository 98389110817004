import { type DealFormValues } from 'types'
import { type DealFeesAndCoveragesSettingsModel } from 'api/types/responses'
import { type CoverageLike } from 'utils/deals/priceHelpers/getDealTaxableCoveragesAmount'
import { type DealFeesLike } from 'utils/deals/priceHelpers/getDealFeesAmount'
import getDealTaxableAmount from 'utils/deals/priceHelpers/getDealTaxableAmount'
import { roundCurrency, getValueFromPercent } from 'utils/math'

export interface CalculateDealSalesTaxAmountConfig {
  coverages: CoverageLike[]
  feesAndCoveragesSettings: DealFeesAndCoveragesSettingsModel | null
  values: DealValuesForSalesTaxAmount
  salesTax: number | null
}

export interface DealValuesForSalesTaxAmount {
  dealFees: DealFeesLike
  tradeInDetails: {
    credit: DealFormValues['tradeInDetails']['credit']
  }
  vehicle: {
    listPrice: DealFormValues['vehicle']['listPrice']
  }
}

export const getDealSalesTaxAmount = ({
  coverages = [],
  feesAndCoveragesSettings,
  salesTax,
  values
}: CalculateDealSalesTaxAmountConfig): number => {
  const taxableAmount = getDealTaxableAmount({
    coverages,
    fees: values.dealFees ?? null,
    feesAndCoveragesSettings,
    tradeInCredit: values.tradeInDetails.credit ?? 0,
    vehiclePrice: values.vehicle.listPrice ?? 0
  })

  const salesTaxAmount = getValueFromPercent(taxableAmount, salesTax ?? 0)

  return salesTaxAmount > 0 ? salesTaxAmount : 0
}

export const getDealSalesTaxAmountRounded = (conf: CalculateDealSalesTaxAmountConfig): number => {
  return roundCurrency(getDealSalesTaxAmount(conf))
}

export default getDealSalesTaxAmountRounded
