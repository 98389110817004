import type { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import {
  VinInput as VinInputCommon,
  type VinInputProps as VinInputCommonProps,
  type ParseVinErrorMessageReturn
} from '@carfluent/common'

export type VinInputProps<T> = Omit<VinInputCommonProps<T>, 'renderErrorMessage'> & {
  onClose?: () => void
}

const renderErrorMessage = (value: ParseVinErrorMessageReturn, onClose?: () => void): ReactNode => {
  if (value == null || typeof value === 'string' || Array.isArray(value)) {
    return value
  }

  return (
    <>
      {value.beforeLink}
      <Link
        to={value.linkUrl}
        onClick={(event) => {
          event.stopPropagation()
          onClose?.()
        }}
      >{value.linkMsg}
      </Link>
      {value.afterLink}
    </>
  )
}

function VinInput <T> (props: VinInputProps<T>): JSX.Element {
  return (
    <VinInputCommon<T>
      {...props}
      renderErrorMessage={(value: ParseVinErrorMessageReturn) => renderErrorMessage(value, props?.onClose)}
    />
  )
}

export default VinInput
