import { stringToDate } from '@carfluent/common'
import isEqual from 'date-fns/isEqual'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import negate from 'lodash-es/negate'

import { type AccountListItem, type LienholderItemDto } from 'api/types'
import { type ActiveLenderDecisionModel } from 'api/types/responses'
import { type FullAddressParts } from 'types/address'
import { containsTruthy, isFalsy, isTruthy } from 'utils/general'
import getFileExtension from 'utils/common/getFileExtension'
import {
  nameRegexp,
  alphanumericWithSymbolsAndApostropheRegex,
  alphanumericWithSymbolsRegex,
  cyrillicRegex,
  isVin,
  passwordRegex,
  alphanumericRegex
} from 'utils/regex_helper'
import { ValidationLength } from 'constants/validation'
import { isDate, isValidDate, isEmailValid, isApt } from './validation'
import { isPersonalType } from './deals/workflowTypes'
import assertNotNullable from 'utils/common/assertNotNullable'
import isFinancing from 'utils/deals/isFinancing'

export interface WithActiveLenderDecision {
  activeLenderDecision: ActiveLenderDecisionModel
  dealFinanceTypeId: number | null
}

/**
 * DD-NOTE:
 * should replace other validation in future
 */
export const isDateOrEmpty = (errorMessage: string) => (val?: Date | string | null): string | null => {
  if (val == null) {
    return null
  }

  return isValidDate(new Date(val)) ? null : errorMessage
}

export const isRequiredDate = (errorMessage: string) => (val?: Date | null | string): string | null => {
  const isValid = isDate(val)

  return isValid ? null : errorMessage
}

export const isNumberRequired = (errorMessage: string) => (val?: number | string | null): string | null => {
  return (val !== null && !isNaN(Number(val))) ? null : errorMessage
}

export const isAlphanumericWithSymbolsAndApostrophe = (errorMessage: string) =>
  (val?: string): string | null => {
    const isValid = val == null ||
      val.length === 0 ||
      alphanumericWithSymbolsAndApostropheRegex.test(val)

    return isValid ? null : errorMessage
  }

export const isNameValid = (errorMessage: string) =>
  (val?: string): string | null => {
    const isValid = val == null ||
      val.length === 0 ||
      nameRegexp.test(val)

    return isValid ? null : errorMessage
  }

export const isAlphanumericWithSymbols = (errorMessage: string) =>
  (val?: string): string | null => {
    const isValid = val == null ||
      val.length === 0 ||
      alphanumericWithSymbolsRegex.test(val)

    return isValid ? null : errorMessage
  }

export const isAlphanumericWithOutSymbols = (errorMessage: string) =>
  (val?: string): string | null => {
    const isValid = val == null ||
      val.length === 0 ||
      alphanumericRegex.test(val)

    return isValid ? null : errorMessage
  }

export const greaterThan = (errorMessage: string, min: number, isEmptyAllowed: boolean = true) =>
  (val: number | string | null): string | null => {
    if (isEmptyAllowed && val == null) {
      return null
    }

    const isValid = val != null &&
      (typeof val === 'string'
        ? val.length > min
        : val > min)

    return isValid ? null : errorMessage
  }

export const greaterThanOrEqual = (errorMessage: string, min: number, isEmptyAllowed: boolean = true) =>
  (val: number | string | null): string | null => {
    if (isEmptyAllowed && ((val == null) || (val === ''))) {
      return null
    }

    const isValid = val != null &&
      (typeof val === 'string'
        ? val.length >= min
        : val >= min)

    return isValid ? null : errorMessage
  }

export const lessThanOrEqual = (errorMessage: string, max: number, isEmptyAllowed: boolean = true) =>
  (val: number | string | null): string | null => {
    if (isEmptyAllowed && ((val == null) || (val === ''))) {
      return null
    }

    const isValid = val != null &&
      (typeof val === 'string'
        ? val.length <= max
        : val <= max)

    return isValid ? null : errorMessage
  }

export const exactLength = (errorMessage: string, len: number) =>
  (val: string | null): string | null => {
    const isValid = val != null && val.length === len
    return isValid ? null : errorMessage
  }

export const greaterThanOrEqualDateForStringDate = (errorMessage: string, min?: Date | null) =>
  (val: string | null): string | null => {
    const isValid = (val == null) ||
      (val === '') ||
      (min == null) ||
      isEqual(new Date(val), new Date(min)) ||
      isAfter(new Date(val), new Date(min))

    return isValid ? null : errorMessage
  }

export const lessThanOrEqualDateForStringDate = (errorMessage: string, max?: Date | null) =>
  (val: string | null): string | null => {
    const isValid = (val == null) ||
      (val === '') ||
      (max == null) ||
      isEqual(new Date(val), max) ||
      isBefore(new Date(val), new Date(max))

    return isValid ? null : errorMessage
  }

export const greaterThanOrEqualDate = (errorMessage: string, min?: Date | null) =>
  (val: Date | null): string | null => {
    const isValid = val == null || min == null || isEqual(val, min) || isAfter(val, min)
    return isValid ? null : errorMessage
  }

export const lessThanOrEqualDate = (errorMessage: string, max?: Date | null) =>
  (val: Date | null): string | null => {
    const isValid = val == null || max == null || isEqual(val, max) || isBefore(val, max)

    return isValid ? null : errorMessage
  }

export const addressData = (errorMessage: string, isRequired = true) =>
  (val: FullAddressParts | null): string | null => {
    const { city, zipCode, state, address } = (val ?? {})
    const isFull = Boolean(city) && Boolean(zipCode) && Boolean(state) && Boolean(address)
    const isPartial = Boolean(city) || Boolean(zipCode) || Boolean(state) || Boolean(address)

    const isValid = isRequired ? isFull : (isFull || !isPartial)
    return isValid ? null : errorMessage
  }

// ========================================== //
//                REFACTORED                  //
// ========================================== //

export type Predicate<TValue, TCtx = unknown, C = unknown> = (val: TValue, ctx?: TCtx, conf?: C) => boolean

export type BuildRuleType = <TValue, TCtx = unknown, TConf = unknown>(isValid: Predicate<TValue, TCtx, TConf>) => (
  errorMessage: string,
  conf?: TConf
) => (val: TValue, ctx?: TCtx) => string | null

export const buildRule: BuildRuleType = (isValid) => (errorMessage, conf) => (val, ctx) => {
  return isValid(val, ctx, conf) ? null : errorMessage
}

/**
 * Usage (in `buildPreset`):
 * `ruleRequired(ErrorMessage.Required, true)` - validates empty values but skips `0`,
 * so it can be validated by next validator in chain (with different error message).
 */
export const ruleRequired = buildRule<unknown, unknown, boolean>((val, _, isZeroAllowed: boolean = false): boolean => {
  return Array.isArray(val) ? (val.length > 0) : containsTruthy(val, isZeroAllowed)
})

/**
 * This validation rule ensures that, for the given set of form fields
 * encapsulated in the `values` object, at least one field contains a "truthy"
 * value, thereby enforcing a requirement that at least one field must be
 * populated/selected.
 */
export const ruleAtLeastOneFieldRequired = buildRule((_, values: any) => {
  if (typeof values !== 'object' || values === null) {
    return false
  }

  for (const key in values) {
    if (containsTruthy(values[key])) {
      return true
    }
  }

  return false
})

/**
 * This validation rule ensures that a particular account (specified by `val`)
 * is not selected more than once across multiple form fields contained within
 * the `values` object.
 */
export const ruleSameAccount = buildRule((val: AccountListItem, values: any) => {
  if (typeof values !== 'object' || values === null || val === null) {
    return true
  }
  let count = 0

  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      if (isTruthy(values[key]) && values[key]?.name === val?.name) {
        count++
      }
    }
  }

  return count <= 1
})

export const ruleVinOrEmpty = buildRule((val?: string | null) =>
  val == null || val.length === 0 || isVin(val ?? '')
)

export const ruleFourNumbersOrEmpty = buildRule((val?: string | number | null) =>
  (val == null) || /^\d{4}$/.test(`${val}`))

export const ruleYearNotInFutureOrEmpty = buildRule((val?: string | number | null) =>
  (val == null) || (Number(val) <= new Date().getFullYear()))

export const ruleMultiSelectNotEmpty = buildRule((val?: unknown[] | null) =>
  val != null && val.length > 0
)

export const ruleFileMaxSize = buildRule((val: File[] | null, _, maxSize: number = Number.POSITIVE_INFINITY) => {
  return (val ?? []).reduce((acc, x) => acc + x.size, 0) <= maxSize
})

export const ruleFileExtensions = buildRule((val: File[] | null, _, allowedExts: string[] = []) => {
  const exts = (val ?? []).map(getFileExtension)
  return exts.every(x => (x != null) && allowedExts.includes(x))
})

export const ruleRevalidationTrigger = buildRule(() => true)

export const ruleIsNotCyrillic = buildRule((val?: string | null) =>
  val == null || val.length === 0 || !cyrillicRegex.test(val)
)

export const rulePhoneNumberOrEmpty = buildRule((val?: string | null) =>
  val == null || val.length === 0 || val.length === ValidationLength.PHONE_NUMBER
)

export const ruleSSNOrEmpty = buildRule((val?: string | null) =>
  val == null || val.length === 0 || val.length === ValidationLength.SSN
)

const driverLicenseRegex = new RegExp(`^[A-Za-z0-9- ]{${ValidationLength.DRIVER_LICENSE_MIN},}$`)

export const isDriverLicenseNumberOrEmpty = buildRule((val: string | null): boolean =>
  val == null || val.length === 0 || driverLicenseRegex.test(val)
)

// AS-TODO: figure out how to please TS more elegant
const ruleExactNumbersOrEmpty = (digits: number): ReturnType<BuildRuleType> => buildRule((val) => {
  const value = val as string | null
  return value == null || value.length === 0 || new RegExp(`^\\d{${digits}}$`).test(value)
})

export const ruleFiveNumbersOrEmpty = ruleExactNumbersOrEmpty(5)

export const ruleEmail = buildRule<string | null>((val: string | null): boolean => {
  return (val === null) || (val === '') || isEmailValid(val)
})

export const ruleEin = buildRule<string>((val: string): boolean => {
  return (val == null) || (val === '') || (val.length === ValidationLength.EIN)
})

export const ruleCorrectDate = buildRule((val: string): boolean => {
  return val === '' || val === null ? true : (stringToDate(val) != null)
})

export const ruleEndDateAfterStartDate = buildRule((_, ctx: any): boolean => {
  const startDate = stringToDate(ctx.startDate)
  const endDate = stringToDate(ctx.endDate)

  if ((startDate == null) || (endDate == null)) {
    return true
  }

  return endDate >= startDate
})

export const ruleTextMaxLength = buildRule((val: string | null, _, maxLength: number = Number.MAX_SAFE_INTEGER) => {
  return (val ?? '').length <= maxLength
})

export const ruleIsValidApt = buildRule((val: string | null, _, maxLength: number = Number.MAX_SAFE_INTEGER) => {
  return isApt(val)
})

export const rulePasswordCase = buildRule((val: string | null) => {
  return passwordRegex.test(val ?? '')
})

export const ruleCustomerDropdown = buildRule((val: string | null, ctx: any) => {
  return !isPersonalType(ctx?.workflowType?.id) || ctx.isAddNewCustomer === true || val != null
})

export const ruleDealSource = buildRule((val: string | null, ctx: any) => {
  return !isPersonalType(ctx?.workflowType?.id) || val != null
})

export const ruleTradeInCreditRequiredIfVisible = buildRule((val: number | null, ctx: any): boolean => {
  if (isFalsy(ctx?.tradeInDetails?.id)) {
    return true
  }

  return (val ?? 0) > 0
})

// AZ-TODO: improve, remove copy-paste
export const ruleLienholderRequiredIfRelated = buildRule((
  value: LienholderItemDto | null,
  ctx?: WithActiveLenderDecision
): boolean => {
  const isVisible = isFinancing(ctx?.dealFinanceTypeId)
  if (!isVisible) {
    return true
  }

  const hasSavedDecision = ctx?.activeLenderDecision.id != null
  const areOtherFieldsSet = (ctx?.activeLenderDecision.approvedTerm != null) ||
    (ctx?.activeLenderDecision.approvedRate != null) ||
    (ctx?.activeLenderDecision.downPayment != null)

  return (areOtherFieldsSet || hasSavedDecision) ? isTruthy(value) : true
})

// AZ-TODO: improve, remove copy-paste
export const ruleApprovedRateRequiredIfRelated = buildRule((
  value: number | null,
  ctx?: WithActiveLenderDecision
): boolean => {
  const isVisible = isFinancing(ctx?.dealFinanceTypeId)
  if (!isVisible) {
    return true
  }

  const hasSavedDecision = ctx?.activeLenderDecision.id != null
  const areOtherFieldsSet = (ctx?.activeLenderDecision.approvedTerm != null) ||
    (ctx?.activeLenderDecision.lienholder != null) ||
    (ctx?.activeLenderDecision.downPayment != null)

  return (areOtherFieldsSet || hasSavedDecision) ? (value ?? -1) >= 0 : true
})

// AZ-TODO: improve, remove copy-paste
export const ruleApprovedTermRequiredIfRelated = buildRule((
  value: number | null,
  ctx?: WithActiveLenderDecision
): boolean => {
  const isVisible = isFinancing(ctx?.dealFinanceTypeId)
  if (!isVisible) {
    return true
  }

  const hasSavedDecision = ctx?.activeLenderDecision.id != null
  const areOtherFieldsSet = (ctx?.activeLenderDecision.approvedRate != null) ||
    (ctx?.activeLenderDecision.lienholder != null) ||
    (ctx?.activeLenderDecision.downPayment != null)

  return (areOtherFieldsSet || hasSavedDecision) ? (value ?? 0) > 0 : true
})

/**
 * Date-related rules (configurable, describes relation with other field).
 *
 * Usage:
 *
 * const myEndDatePreset = buildPreset<Date | string | null>([
 *   ruleDateGT(Errors.ShouldBeAfterStartDate, 'startDateField')
 * ])
 */

export const ruleDateGT = buildRule<DateFieldValue, any, DateFieldConfig>((...args): boolean => {
  return compareDateFields(isAfter, ...args)
})

export const ruleDateGE = buildRule<DateFieldValue, any, DateFieldConfig>((...args): boolean => {
  return compareDateFields(negate(isBefore), ...args)
})

export const ruleDateLT = buildRule<DateFieldValue, any, DateFieldConfig>((...args): boolean => {
  return compareDateFields(isBefore, ...args)
})

export const ruleDateLE = buildRule<DateFieldValue, any, DateFieldConfig>((...args): boolean => {
  return compareDateFields(negate(isAfter), ...args)
})

export type DateFieldValue = Date | string | null
export type DateFieldConfig = Date | string | undefined
export type CompareDateFn = (x: Date, y: Date) => boolean

/**
 * Compares two dates.
 * First date is a value of the validated field.
 * Second date is either a value of some other field, or some Date.
 *
 * Last case is useful, when validation rule need to compare field's value
 * with the "current date", or some other hard-coded dates.
 */
export const compareDateFields = (cmp: CompareDateFn, _val: DateFieldValue, ctx: any, otherFieldId?: DateFieldConfig): boolean => {
  assertNotNullable(otherFieldId, 'otherFieldId')

  const otherDate = typeof otherFieldId === 'string' ? ctx[otherFieldId] : otherFieldId
  if ((otherDate == null) || (_val == null)) {
    return true
  }

  const val = typeof _val === 'string' ? new Date(_val) : _val
  const otherDateVal = typeof otherDate === 'string' ? new Date(otherDate) : otherDate
  return cmp(val, otherDateVal)
}

// ------------------------------------------ //
