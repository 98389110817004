import { css } from '@emotion/css'

export const createStyleClass = () => {
  return css(`
    cursor: pointer;

    & * {
      color: #458FFF;
    }
  `)
}

export const createTooltipClass = () => {
  return css(`
    padding-left: 16px;
    padding-right: 16px;
  `)
}
