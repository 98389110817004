import { type FormValidation } from '@carfluent/common'
import { priceField, settingsReqField, nonZeroNumberField } from 'utils/validationPresets'
import { type GeneralSettingsFormData } from 'types'

const MAX_VALUE = 100000

export const DEPENDENT_VALIDATIONS = {
  'coverageFeeSettings.buyerTagSetting.isEnabled': ['coverageFeeSettings.buyerTagSetting.account', 'coverageFeeSettings.buyerTagSetting.labelName', 'coverageFeeSettings.buyerTagSetting.defaultValueCash', 'coverageFeeSettings.buyerTagSetting.defaultValueFinancing'],
  'coverageFeeSettings.carDeliverySetting.isEnabled': ['coverageFeeSettings.carDeliverySetting.account', 'coverageFeeSettings.carDeliverySetting.labelName', 'coverageFeeSettings.carDeliverySetting.defaultValueCash'],
  'coverageFeeSettings.dealerHandlingFeeSetting.isEnabled': ['coverageFeeSettings.dealerHandlingFeeSetting.account', 'coverageFeeSettings.dealerHandlingFeeSetting.labelName', 'coverageFeeSettings.dealerHandlingFeeSetting.defaultValueCash'],
  'coverageFeeSettings.dealerInventoryTaxSetting.isEnabled': ['coverageFeeSettings.dealerInventoryTaxSetting.account', 'coverageFeeSettings.dealerInventoryTaxSetting.labelName', 'coverageFeeSettings.dealerInventoryTaxSetting.defaultValueCash', 'coverageFeeSettings.dealerInventoryTaxSetting.defaultValueFinancing'],
  'coverageFeeSettings.emissionsSetting.isEnabled': ['coverageFeeSettings.emissionsSetting.account', 'coverageFeeSettings.emissionsSetting.labelName', 'coverageFeeSettings.emissionsSetting.defaultValueCash'],
  'coverageFeeSettings.inspectionFeeSetting.isEnabled': ['coverageFeeSettings.inspectionFeeSetting.account', 'coverageFeeSettings.inspectionFeeSetting.labelName', 'coverageFeeSettings.inspectionFeeSetting.defaultValueCash', 'coverageFeeSettings.inspectionFeeSetting.defaultValueFinancing'],
  'coverageFeeSettings.plateTransferFeeSetting.isEnabled': ['coverageFeeSettings.plateTransferFeeSetting.account', 'coverageFeeSettings.plateTransferFeeSetting.labelName', 'coverageFeeSettings.plateTransferFeeSetting.defaultValueCash', 'coverageFeeSettings.plateTransferFeeSetting.defaultValueFinancing'],
  'coverageFeeSettings.registrationFeeSetting.isEnabled': ['coverageFeeSettings.registrationFeeSetting.account', 'coverageFeeSettings.registrationFeeSetting.labelName', 'coverageFeeSettings.registrationFeeSetting.defaultValueCash', 'coverageFeeSettings.registrationFeeSetting.defaultValueFinancing'],
  'coverageFeeSettings.titleFeeSetting.isEnabled': ['coverageFeeSettings.titleFeeSetting.account', 'coverageFeeSettings.titleFeeSetting.labelName', 'coverageFeeSettings.titleFeeSetting.defaultValueCash', 'coverageFeeSettings.titleFeeSetting.defaultValueFinancing']
}

const validationRules: FormValidation<GeneralSettingsFormData> = {
  maxCardPaymentAmount: priceField(MAX_VALUE, false, true),
  inventoryPackCost: priceField(MAX_VALUE, false, true),
  salesMonthlyTarget: nonZeroNumberField({ isRequired: true, isZeroAllowed: true }),
  'coverageFeeSettings.buyerTagSetting.account': settingsReqField('buyerTagSetting'),
  'coverageFeeSettings.buyerTagSetting.labelName': settingsReqField('buyerTagSetting'),
  'coverageFeeSettings.buyerTagSetting.defaultValueCash': settingsReqField('buyerTagSetting'),
  'coverageFeeSettings.buyerTagSetting.defaultValueFinancing': settingsReqField('buyerTagSetting'),
  'coverageFeeSettings.carDeliverySetting.account': settingsReqField('carDeliverySetting'),
  'coverageFeeSettings.carDeliverySetting.labelName': settingsReqField('carDeliverySetting'),
  'coverageFeeSettings.carDeliverySetting.defaultValueCash': settingsReqField('carDeliverySetting'),
  'coverageFeeSettings.dealerHandlingFeeSetting.account': settingsReqField('dealerHandlingFeeSetting'),
  'coverageFeeSettings.dealerHandlingFeeSetting.labelName': settingsReqField('dealerHandlingFeeSetting'),
  'coverageFeeSettings.dealerHandlingFeeSetting.defaultValueCash': settingsReqField('dealerHandlingFeeSetting'),
  'coverageFeeSettings.dealerInventoryTaxSetting.account': settingsReqField('dealerInventoryTaxSetting'),
  'coverageFeeSettings.dealerInventoryTaxSetting.labelName': settingsReqField('dealerInventoryTaxSetting'),
  'coverageFeeSettings.dealerInventoryTaxSetting.defaultValueCash': settingsReqField('dealerInventoryTaxSetting'),
  'coverageFeeSettings.dealerInventoryTaxSetting.defaultValueFinancing': settingsReqField('dealerInventoryTaxSetting'),
  'coverageFeeSettings.emissionsSetting.account': settingsReqField('emissionsSetting'),
  'coverageFeeSettings.emissionsSetting.labelName': settingsReqField('emissionsSetting'),
  'coverageFeeSettings.emissionsSetting.defaultValueCash': settingsReqField('emissionsSetting'),
  'coverageFeeSettings.inspectionFeeSetting.account': settingsReqField('inspectionFeeSetting'),
  'coverageFeeSettings.inspectionFeeSetting.labelName': settingsReqField('inspectionFeeSetting'),
  'coverageFeeSettings.inspectionFeeSetting.defaultValueCash': settingsReqField('inspectionFeeSetting'),
  'coverageFeeSettings.inspectionFeeSetting.defaultValueFinancing': settingsReqField('inspectionFeeSetting'),
  'coverageFeeSettings.plateTransferFeeSetting.account': settingsReqField('plateTransferFeeSetting'),
  'coverageFeeSettings.plateTransferFeeSetting.labelName': settingsReqField('plateTransferFeeSetting'),
  'coverageFeeSettings.plateTransferFeeSetting.defaultValueCash': settingsReqField('plateTransferFeeSetting'),
  'coverageFeeSettings.plateTransferFeeSetting.defaultValueFinancing': settingsReqField('plateTransferFeeSetting'),
  'coverageFeeSettings.registrationFeeSetting.account': settingsReqField('registrationFeeSetting'),
  'coverageFeeSettings.registrationFeeSetting.labelName': settingsReqField('registrationFeeSetting'),
  'coverageFeeSettings.registrationFeeSetting.defaultValueCash': settingsReqField('registrationFeeSetting'),
  'coverageFeeSettings.registrationFeeSetting.defaultValueFinancing': settingsReqField('registrationFeeSetting'),
  'coverageFeeSettings.titleFeeSetting.account': settingsReqField('titleFeeSetting'),
  'coverageFeeSettings.titleFeeSetting.labelName': settingsReqField('titleFeeSetting'),
  'coverageFeeSettings.titleFeeSetting.defaultValueCash': settingsReqField('titleFeeSetting'),
  'coverageFeeSettings.titleFeeSetting.defaultValueFinancing': settingsReqField('titleFeeSetting')
}

export default validationRules
