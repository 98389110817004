import type { TasksListCounters, TaskTotalAmounts } from 'api/types'

const parseCountersData = ({ states, filters }: TaskTotalAmounts): TasksListCounters => {
  return {
    filters,
    states: {
      newleads: states.NewLeads,
      creditappreceived: states.CreditAppReceived,
      inbox: states.Inbox,
      workplan: states.Workplan,
      appts: states.Appointments
    }
  }
}

export default parseCountersData
