import type { FC } from 'react'

import error_img from 'assets/403.svg'
import CLASSES from './styles'

const ForbiddenAccess: FC = () => {
  return (
    <div className={CLASSES}>
      <div className='cf-content-wrapper'>
        <div>
          <img alt='403' src={error_img} />
          <p className='cf-content-title'>
            Forbidden
          </p>
          <p className='cf-content-text'>
            You don't have enough permissions.
          </p>
        </div>
      </div>
    </div>
  )
}

export default ForbiddenAccess
