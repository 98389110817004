import uniqBy from 'lodash-es/uniqBy'
import { type ColumnDef } from '@carfluent/common'

import { TransactionStateId, type TransactionListItem } from 'api/types'
import getLineControl from 'utils/accounting/getLineControl'
import { toDate } from 'utils/parse_date'
import isSpecialTransaction from 'utils/accounting/isSpecialTransaction'
import isCheck from 'utils/accounting/isCheck'
import { FilterHeaderCell } from 'components/common/Table/cells/FilterHeaderCell'
import { DateCellHOC } from 'components/common/Table/cells/DateCell'
import IdentifierCell from 'components/common/Table/cells/IdentifierCell'
import AmountCell from 'components/common/Table/cells/AmountCell'
import MultipleItemsCellHOC from 'components/common/Table/cells/MultipleItemsCell'
import ReferenceNumberCell from 'components/common/Table/cells/ReferenceNumberCell'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import ValueCell from 'components/common/Table/cells/ValueCell'

import ControlItem from '../components/ControlItem'
import TypesCellHOC from '../components/cells/TransactionTypeCell'
import { type ColumnsDefinitionsConfig } from './types'

const getColumnsDefinition = ({
  transactionTypeAppliedFilters,
  transactionTypeList,
  onChange
}: ColumnsDefinitionsConfig): Array<ColumnDef<TransactionListItem>> => [
  {
    id: 'date',
    accessorFn: (row) => toDate(row.date),
    cell: DateCellHOC({ format: 'MM/dd/yyyy' }),
    header: TextCellHOC({ text: 'Date' }),
    size: 100
  },
  {
    id: 'transactionTypeFilterId',
    accessorFn: (row) => ({
      transactionTypeFilterId: row.transactionTypeFilterId,
      isBankStatementMatch: row.isCleared
    }),
    cell: TypesCellHOC({ items: transactionTypeList ?? [] }),
    header: <FilterHeaderCell
      title='Type'
      popUpTitle='Show Type:'
      items={transactionTypeList}
      filters={transactionTypeAppliedFilters}
      onChange={onChange}
      isSelectAllAllowed
      popoverProps={{ minWidth: 260 }}
            /> as unknown as () => void,
    size: 140,
    minSize: 140,
    sortable: false
  },
  {
    accessorFn: (row) => ({
      transactionStateId: row.transactionStateId,
      referenceNumber: row.referenceNumber
    }),
    id: 'referenceNumber',
    cell: ReferenceNumberCell,
    header: TextCellHOC({ text: 'Reference #' }),
    size: 140
  },
  {
    accessorFn: (row) => uniqBy(
      (row.lines ?? []).filter(line => getLineControl(line) != null),
      (line) => getLineControl(line)?.id
    ),
    id: 'control',
    cell: MultipleItemsCellHOC({
      className: 'cf-control-cell',
      ItemComponent: ControlItem
    }),
    header: TextCellHOC({ text: 'Control' }),
    size: 120,
    sortable: false
  },
  {
    id: 'identifier',
    accessorFn: (row) => {
      for (const line of (row.lines ?? [])) {
        const control = getLineControl(line)
        if (control != null) {
          return control
        }
      }

      return null
    },
    cell: IdentifierCell,
    header: TextCellHOC({ text: 'Identifier' }),
    loading: 'two_lines',
    maxSize: 200,
    sortable: false
  },
  {
    accessorKey: 'description',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Description' }),
    minSize: 260,
    sortable: false
  },
  {
    accessorKey: 'createdBy',
    cell: ValueCell,
    header: TextCellHOC({ text: 'Created by' }),
    size: 160
  },
  {
    id: 'amount',
    accessorFn: (row) => (
      (row.transactionStateId === TransactionStateId.Draft) ? null : row.amount
    ),
    cell: AmountCell,
    header: TextCellHOC({ text: 'Amount' }),
    size: 160
  }
]

export default getColumnsDefinition
