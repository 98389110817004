import type { FC } from 'react'
import { FormInput, FormDropdown, FormMaskedInput } from '@carfluent/common'
import type { BusinessTypeDto } from 'api/types'
import { EIN_MASK } from 'constants/constants'

import type { BusinessDetailsSectionProps } from '../../types'

const renderBusinessTypeOption = (option: BusinessTypeDto): string => {
  return option.name
}

const BusinessDetails: FC<BusinessDetailsSectionProps> = ({
  values,
  errors,
  touched,
  isFormDisabled,
  businessTypes,
  onChange,
  onBlur
}) => {
  const businessTypeId = businessTypes.find((type) => type.id === values.businessTypeId) ?? null

  return (
    <div className='credit-app-section no-collapse business-details'>
      <FormInput
        id='businessDetails.businessName'
        label='Business name'
        className='business-details-name'
        onChange={onChange}
        onBlur={onBlur}
        disabled={isFormDisabled}
        value={values?.businessName}
        error={errors?.businessName}
        touched={touched?.businessName}
        dataTestId='business-details-name'
      />

      <FormDropdown
        id='businessDetails.businessTypeId'
        label='Business type'
        blurMode='select-first'
        options={businessTypes}
        renderOption={renderBusinessTypeOption}
        disabled={isFormDisabled}
        value={businessTypeId}
        error={errors?.businessTypeId}
        touched={touched?.businessTypeId}
        onChange={(fieldId, val) => onChange(fieldId, val?.id)}
        onBlur={onBlur}
      />

      <FormMaskedInput
        id='businessDetails.ein'
        label='EIN'
        mask={EIN_MASK}
        onChange={onChange}
        onBlur={onBlur}
        value={values.ein}
        error={errors?.ein}
        touched={touched?.ein}
      />
    </div>
  )
}

export default BusinessDetails
