import type { FC } from 'react'
import type { IconProps } from 'types'

const AppointmentIcon: FC<IconProps> = ({ color = '#21212180', width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_86546_28716)'>
        <path d='M12.6667 1.99984H12V1.33317C12 0.966504 11.7 0.666504 11.3333 0.666504C10.9667 0.666504 10.6667 0.966504 10.6667 1.33317V1.99984H5.33333V1.33317C5.33333 0.966504 5.03333 0.666504 4.66667 0.666504C4.3 0.666504 4 0.966504 4 1.33317V1.99984H3.33333C2.59333 1.99984 2 2.59984 2 3.33317V12.6665C2 13.3998 2.6 13.9998 3.33333 13.9998H12.6667C13.4 13.9998 14 13.3998 14 12.6665V3.33317C14 2.59984 13.4 1.99984 12.6667 1.99984ZM8 3.99984C9.10667 3.99984 10 4.89317 10 5.99984C10 7.1065 9.10667 7.99984 8 7.99984C6.89333 7.99984 6 7.1065 6 5.99984C6 4.89317 6.89333 3.99984 8 3.99984ZM12 11.9998H4V11.3332C4 9.99984 6.66667 9.2665 8 9.2665C9.33333 9.2665 12 9.99984 12 11.3332V11.9998Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_86546_28716'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AppointmentIcon
