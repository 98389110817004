import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import { dates, parsers } from '@carfluent/common'

import { HOURS_IN_DAY, MIN_IN_HOUR, SECS_IN_MIN } from '../constants/constants'

export { format as formatDate } from 'date-fns'
const { parseDateStringUTC } = parsers
export const convertFromUTC = dates.convertToUTC // AZ-TODO: rename fucntion in Common Lib.

/**
 * Interprets argument date-string or date as UTC+0 and converts
 * it to local date.
 */
export const convertFromUTCStrict = (value: Date | string): Date => {
  return convertFromUTC(value) ?? new Date(value)
}

export const getHoursMinutesTime = (date: string | Date | null): string => {
  if (date == null) return ''

  const dt = typeof date === 'string' ? new Date(date) : date
  return Intl.DateTimeFormat('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' })
    .format(dt)
}

export const fromDateToNow = (date: Date): string => {
  return formatDistanceToNowStrict(date, { addSuffix: true, roundingMethod: 'floor' })
}

/**
 * @deprecated - use `toDateOrNull` because of more precise naming
 */
export const toDate = (date?: string | null): Date | null => {
  if ((date == null) || (date === '')) {
    return null
  }

  return new Date(date)
}

export const toDateOrNull = toDate

export const getDaysFromNow = (date: string): number => {
  const seconds = (Date.now() - (parseDateStringUTC(date)?.getTime() ?? 0)) / 1000
  return Math.floor(seconds / (SECS_IN_MIN * MIN_IN_HOUR * HOURS_IN_DAY))
}

/**
 * Currently, due to type assertions across deals domain,
 * some dates are of Date type and some are of string type.
 * It can be removed when all dates will be of Date type.
 */
export const toDateFromStringOrDate = (date: string | Date | null | undefined): Date | null => {
  if (date == null || date === '') {
    return null
  }

  if (typeof date === 'string') {
    return new Date(date)
  }

  return date
}
