import { getHoursMinutesTime } from 'utils/parse_date'
import type { CompleteTaskFormData } from './types'

const initialDate = new Date()
const initialTime = getHoursMinutesTime(initialDate)

export const getAppointmentDefaultFormData = (): CompleteTaskFormData => ({
  taskCompleteOptionId: null,
  taskCompleteSubOptionIds: [],
  subject: null,
  workNotes: '',
  date: initialDate,
  time: initialTime
})

export const getCallDefaultFormData = (): CompleteTaskFormData => ({
  taskCompleteOptionId: null,
  taskCompleteSubOptionIds: [],
  subject: null,
  workNotes: '',
  date: initialDate,
  time: initialTime
})

export const getReminderDefaultFormData = (): CompleteTaskFormData => ({
  taskCompleteOptionId: null,
  taskCompleteSubOptionIds: [],
  subject: null,
  workNotes: '',
  date: initialDate,
  time: ''
})

export enum FieldIds {
  Date = 'date',
  Time = 'time',
  Notes = 'workNotes',
  Subject = 'subject',
  TaskCompleteOptionId = 'taskCompleteOptionId',
  TaskCompleteSubOptionIds = 'taskCompleteSubOptionIds'
}

export { Messages } from 'pages/crm/LeadDetailsView/hook/constants'
