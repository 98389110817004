import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import { CKEditor } from '@ckeditor/ckeditor5-react'

import ImageUploadAdapterPlugin from './plugins/ImageUploadPlugin'
import { type RichEditorProps } from './types'
import useRichEditor from './hook'
import CLASS_NAME from './styles'

// AZ-TODO: fix it, it seems export is incorrect in `ckeditor.js`
// https://github.com/ckeditor/ckeditor5/issues/2178
import './build/ckeditor'
let ClassicEditor: any
if (process.env.NODE_ENV === 'development') {
  ClassicEditor = require('./build/ckeditor')
}

const EDITOR_CONFIG = {
  extraPlugins: [ImageUploadAdapterPlugin],
  removePlugins: ['Markdown'], // AZ-TODO: fix editor imports to remove this
  toolbar: {
    items: [
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript',
      '|', 'heading', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
      '|', 'alignment', 'bulletedList', 'numberedList', 'outdent', 'indent',
      '-', 'link', 'uploadImage', 'code',
      '|', 'undo', 'redo'
    ],
    shouldNotGroupWhenFull: true
  },
  indentBlock: {
    offset: 1,
    unit: 'em'
  },
  list: {
    properties: {
      styles: true,
      startIndex: true,
      reversed: true
    }
  }
}

const RichEditor: FC<RichEditorProps> = ({
  className,
  ...hookProps
}) => {
  const { onBlur, onChange, onReady } = useRichEditor(hookProps)

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <CKEditor
        data={hookProps.value}
        editor={ClassicEditor ?? (window as any).ClassicEditor}
        config={EDITOR_CONFIG}
        onReady={onReady}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  )
}

export default RichEditor
