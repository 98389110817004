import { css } from '@emotion/css'

export default css(`
  margin: auto;
  margin-top: 8px;
  max-width: 1244px;

  .search-block {
    width: 420px;
  }

  .reports-lists-container {
    grid-gap: 24px;
    overflow: hidden;
    margin-top: 24px;
    display: grid!important;
    grid-template-columns: minmax(572px, 610px) minmax(572px, 610px);
    grid-template-rows: masonry;
  }

  .report-section {
    :nth-of-type(1) {
      grid-area: 1 / 1 / 8 / 2;
    }

    :nth-of-type(2) {
      grid-area: 1 / 2 / 1 / 2; 
    }

    :nth-of-type(3) {
      grid-area: 3 / 2 / 2 / 2; 
    }
    
    :nth-of-type(4) {
      grid-area: 4 / 2 / 3 / 3;
    }

    figure {
      padding: 24px;
      display: flex;
      border-radius: 12px;
      border: 1px solid #0000001F;
    }

    figcaption {
      height: 36px;
      display: flex;
      color: #101010;
      font-size: 20px;
      font-weight: 500;
      min-width: 150px;
      line-height: 24px;
      margin-right: 16px;
      align-items: center;
      letter-spacing: 0.15px;

      svg {
        margin-right: 4px;
      }
    }
  }

  .sidebar {
    box-sizing: border-box;
  }
  
  .report-section h2 {
    color: #333;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .report-section ul {
    flex: 1;
    padding: 0;
    list-style: none;
  }
  
  .report-section li {
    display: flex;
    padding: 16px 0;

    :first-of-type {
      padding-top: 0;
    }

    :last-of-type {
      padding-bottom: 0;
    }

    :not(:last-of-type) {
      border-bottom: 1px solid #0000001F;
    }

    a {
      flex: 1;
      padding: 8px;
      cursor: pointer;
      border-radius: 12px;

      :hover {
        background: #FAFAFA;
      }
    }
  }

  .empty-message {
    display: flex;
    font-size: 16px;
    padding: 82px 0;
    color: #21212180;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
    justify-content: center;
  }
`)
