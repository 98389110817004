import { css } from '@emotion/css'

export default css(`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: 92px;
  max-width: 92px;
  padding: 5px 12px;
  height: 24px;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0.25px;
  text-align: center;
  border: 1px solid #219653;
  color: #0E4C29;
`)
