import { css } from '@emotion/css'

export default css(`
  .payment-form-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 600px;
    
    .payment-modal-divider-content {
      width: 100%;
      height: 1px;
      background: rgba(0, 0, 0, 0.12);
      margin-bottom: 24px;
    }

    .billing-details {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 24px;
      grid-column-gap: 16px;
      margin-bottom: 24px;
      
      .cf-form-field:last-child {
        grid-column-start: 1;
        grid-column-end: 4;
      }
    }
    
    .cf-address-fields-component {
      padding: 0;
      grid-column-gap: 16px;
      margin-bottom: 24px;
    }
    
    .payment-form-input {
      width: 292px;
      margin-bottom: 24px;
    }
    
    .payment-form-input-full {
      width: 100%;
      margin-bottom: 32px;
      
      &.address-checkbox {
        margin-bottom: 24px;
      }
    }
    
    .receipt-checkbox,
    .address-checkbox {
      .MuiButtonBase-root.MuiIconButton-root {
        padding: 0 9px;
      }
    }

    .remaining-amount {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 24px;

      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #000;
        margin: 0;
      }

      &.with-error p:last-child {
        color: #B00020;
      }
    }
  }
  
  .h6-title {
    margin-bottom: 24px;
    width: 100%;
  }
`)
