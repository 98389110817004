import { type FC, useCallback } from 'react'
import { cnx, FormNumberInput, FormInput, Button } from '@carfluent/common'
import get from 'lodash-es/get'

import { type DealFeeFieldSettingsModel, type DealFeesModel } from 'api/types/responses'
import { GET_DEFAULT_FEE_FIELD_SETTINGS } from 'api/defaults/parsed'
import { MAX_COST_LABEL_LENGTH, MAX_PRICE_VALUE } from 'constants/validation'
import getDealSalesTaxAmountRounded from 'utils/deals/priceHelpers/getDealSalesTaxAmount'
import getSalesCommissionRounded from 'utils/deals/priceHelpers/getSalesCommissionRounded'
import { getValueFromPercentRounded } from 'utils/math'
import { isTruthy } from 'utils/general'
import IconSVGPlus from 'components/inlineImages/IconSVGPlus'
import PercentOrAmountInput from 'components/deals/PercentOrAmountInput'
import CalculatedOrManualInput from 'components/deals/CalculatedOrManualInput'
import TaxInput from 'components/deals/TaxInput'

import { type FeesSectionProps } from './types'
import CLASS_NAME from './styles'
import {
  formatSalesCommissionRadio,
  formatSalesTaxRadio,
  formatSalesTaxFieldHelper,
  formatSalesTaxFormHelper
} from './utils'

const MAX_OTHERS = 3

const FeesSection: FC<FeesSectionProps> = ({
  className,
  coverageDetails,
  deal,
  errors,
  feesAndCoveragesSettings,
  isManualSalesTax,
  isRecorded,
  isWholesale,
  onBlur,
  onChange,
  onSubmitInventoryTax,
  onSubmitSalesCommission,
  onSubmitSalesTax,
  salesCommission,
  setFieldTouched,
  touched,
  values
}) => {
  const dealFees = values.dealFees
  const isAddCostDisabled = dealFees?.others.length >= MAX_OTHERS
  const feesSettings = feesAndCoveragesSettings?.fees

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onAddAdditionalConst = (): void => {
    if (!isAddCostDisabled) {
      const id = Math.max(...dealFees.others.map(x => x.id)) + 1
      onChange('dealFees.others', dealFees.others.concat({ id, amount: 0, description: '' }))
    }
  }

  const getSalesTaxAmount = (salesTax: number | null): number => {
    return getDealSalesTaxAmountRounded({
      coverages: coverageDetails,
      feesAndCoveragesSettings,
      salesTax,
      values
    })
  }

  const getInventoryTaxAmount = useCallback((tax: number | null): number => {
    return getValueFromPercentRounded(values.vehicle?.listPrice ?? 0, tax ?? 0)
  }, [values.vehicle?.listPrice])

  const getCalculatedSalesCommission = (): number => {
    return getSalesCommissionRounded({
      beValues: deal,
      commissionSettings: feesAndCoveragesSettings?.salesCommissions ?? null,
      feValues: values,
      frontPercent: salesCommission?.frontGrossSalesCommissionPercent ?? null,
      oldSalesCommission: salesCommission?.resultSalesCommission ?? null,
      oldSalesCost: salesCommission?.saleCost ?? null
    })
  }

  // AZ-TODO: add export of FormInputProps, FormNumberInputProps to Common Lib.
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getCommonInputProps = (
    id: keyof DealFeesModel,
    feeConfig: DealFeeFieldSettingsModel = GET_DEFAULT_FEE_FIELD_SETTINGS()
  ) => {
    const fieldPath = `dealFees.${id}`

    return {
      error: get(errors, fieldPath),
      disabled: isRecorded || feeConfig.isReadonly,
      id: fieldPath,
      label: feeConfig.labelName,
      onBlur,
      onChange,
      touched: get(touched, fieldPath),
      value: get(values, fieldPath)
    }
  }

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <div className='section-title'>
        <h6>Fees and taxes</h6>
      </div>

      <div className='fields'>
        {!isWholesale && isTruthy(feesSettings?.buyerTagSetting.isEnabled) && (
          <div className='field-container'>
            <FormNumberInput
              {...getCommonInputProps('buyerTag', feesSettings?.buyerTagSetting)}
              isNegativeAllowed={false}
              max={MAX_PRICE_VALUE}
              preset='financial'
            />
          </div>
        )}

        {isTruthy(feesSettings?.carDeliverySetting.isEnabled) && (
          <div className='field-container'>
            <FormNumberInput
              {...getCommonInputProps('carDelivery', feesSettings?.carDeliverySetting)}
              isNegativeAllowed={false}
              max={MAX_PRICE_VALUE}
              preset='financial'
            />
          </div>
        )}

        {isTruthy(feesSettings?.dealerHandlingFeeSetting.isEnabled) && (
          <div className='field-container'>
            <FormNumberInput
              {...getCommonInputProps('dealerHandlingFee', feesSettings?.dealerHandlingFeeSetting)}
              isNegativeAllowed={false}
              max={MAX_PRICE_VALUE}
              preset='financial'
            />
          </div>
        )}

        {isTruthy(feesSettings?.dealerInventoryTaxSetting.isEnabled) && (
          <div className='field-container'>
            <TaxInput
              {...getCommonInputProps('dealerInventoryTax', feesSettings?.dealerInventoryTaxSetting)}
              getTaxAmount={getInventoryTaxAmount}
              onSubmitTax={onSubmitInventoryTax}
            />
          </div>
        )}

        {isTruthy(feesSettings?.emissionsSetting.isEnabled) && (
          <div className='field-container'>
            <FormNumberInput
              {...getCommonInputProps('emissions', feesSettings?.emissionsSetting)}
              isNegativeAllowed={false}
              max={MAX_PRICE_VALUE}
              preset='financial'
            />
          </div>
        )}

        {isTruthy(feesSettings?.inspectionFeeSetting.isEnabled) && (
          <div className='field-container'>
            <FormNumberInput
              {...getCommonInputProps('inspectionFee', feesSettings?.inspectionFeeSetting)}
              isNegativeAllowed={false}
              max={MAX_PRICE_VALUE}
              preset='financial'
            />
          </div>
        )}

        {isTruthy(feesSettings?.plateTransferFeeSetting.isEnabled) && (
          <div className='field-container'>
            <FormNumberInput
              {...getCommonInputProps('plateTransferFee', feesSettings?.plateTransferFeeSetting)}
              isNegativeAllowed={false}
              max={MAX_PRICE_VALUE}
              preset='financial'
            />
          </div>
        )}

        {isTruthy(feesSettings?.registrationFeeSetting.isEnabled) && (
          <div className='field-container'>
            <FormNumberInput
              {...getCommonInputProps('registrationFee', feesSettings?.registrationFeeSetting)}
              isNegativeAllowed={false}
              max={MAX_PRICE_VALUE}
              preset='financial'
            />
          </div>
        )}

        {isTruthy(feesSettings?.titleFeeSetting.isEnabled) && (
          <div className='field-container'>
            <FormNumberInput
              {...getCommonInputProps('titleFee', feesSettings?.titleFeeSetting)}
              isNegativeAllowed={false}
              max={MAX_PRICE_VALUE}
              preset='financial'
            />
          </div>
        )}

        <div className='field-container'>
          <CalculatedOrManualInput
            {...getCommonInputProps('overridenSalesCommission')}
            fieldPathManual='dealFees.overridenSalesCommission'
            formatRadioLabel={formatSalesCommissionRadio}
            getCalculatedAmount={getCalculatedSalesCommission}
            isOverriden={values.dealFees.overridenSalesCommission != null}
            label='Sales commission'
            onSubmit={onSubmitSalesCommission}
            manualValue={values.dealFees.overridenSalesCommission}
          />
        </div>

        <div className='field-container'>
          {!isWholesale && (
            <PercentOrAmountInput
              disabled={isRecorded}
              getAmountFromPercent={getSalesTaxAmount}
              isOverriden={isManualSalesTax}
              onChange={onChange}
              onSubmit={onSubmitSalesTax}
              fieldPathAmount='dealFees.overridenSalesTaxAmount'
              fieldPathPercent='dealFees.overridenSalesTaxPercent'
              formatFieldHelper={formatSalesTaxFieldHelper}
              formatFormHelper={formatSalesTaxFormHelper(getSalesTaxAmount)}
              formatRadioLabel={formatSalesTaxRadio}
              label='Sales tax amount'
              labelAmount='Sales tax amount'
              labelPercent='Sales tax'
              valueAmount={dealFees.overridenSalesTaxAmount}
              valuePercent={dealFees.overridenSalesTaxPercent}
            />
          )}
        </div>

        {isWholesale && <div className='field-container' />}
      </div>

      <div
        className='section-title'
        key={dealFees.others.reduce((acc, x) => acc + x.id, 0)}
      >
        {(dealFees.others).map((item, idx) => {
          return (
            <div key={item.id} className='additional-cost-line'>
              <div className='field-container'>
                <FormInput
                  error={errors.dealFees.others[idx]?.description}
                  helper={
                    <p className='count-cost-label'>
                      {item.description?.length ?? 0}/{MAX_COST_LABEL_LENGTH} symbols
                    </p>
                  }
                  id={`dealFees.others.[${idx}].description`}
                  label='Cost label'
                  maxLength={MAX_COST_LABEL_LENGTH}
                  onBlur={onBlur}
                  onChange={onChange}
                  touched={touched.dealFees.others[idx]?.description}
                  value={item.description}
                  disabled={isRecorded}
                />
              </div>

              <div className='field-container'>
                <FormNumberInput
                  error={errors.dealFees?.others[idx]?.amount}
                  id={`dealFees.others.[${idx}].amount`}
                  isEmptyAllowed={false}
                  isNegativeAllowed={false}
                  label='Amount'
                  max={MAX_PRICE_VALUE}
                  onBlur={onBlur}
                  onChange={onChange}
                  preset='financial'
                  touched={touched.dealFees?.others[idx]?.amount}
                  value={item.amount}
                  disabled={isRecorded}
                />
              </div>

              <div className='field-container'>
                <Button
                  className='additional-costs-remove-btn'
                  onClick={() => {
                    const nextOthers = dealFees?.others
                      ?.filter((_, _idx) => _idx !== idx)

                    onChange('dealFees.others', nextOthers)
                    setFieldTouched('dealFees.others', true)
                  }}
                  variant='text'
                  isDisabled={isRecorded}
                >
                  Remove
                </Button>
              </div>

              <div className='field-container' />
              <div className='field-container' />
            </div>
          )
        })}

        <div
          className={cnx('btn-add-cost', isAddCostDisabled && 'disabled')}
          onClick={onAddAdditionalConst}
        >
          <IconSVGPlus />
          <h3>Add additional cost</h3>
        </div>
      </div>
    </div>
  )
}

export default FeesSection
