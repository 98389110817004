import { css } from '@emotion/css'

export default css(`
  width: 100%;
  position: relative;
  
  .attachment-item {
    display: inline-flex;
    align-items: center;
    margin-right: 6px;
    margin-top: 6px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #F3F3F3;

    a {
      max-width: 150px;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
      text-decoration: none;
      color: #101010;
      letter-spacing: 0.25px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .delete-icon {
      margin-left: 4px;
      height: 16px;
      cursor: pointer;
    }
  }
`)
