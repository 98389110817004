import type { MouseEvent, RefObject } from 'react'
import { useLayoutEffect, useRef, useState, useEffect } from 'react'
import debounce from 'lodash-es/debounce'
import useClickOutside from 'hooks/useClickOutside'
import { TAG_FONT_SIZE } from '../styles'

const SYMBOL_WIDTH_KOEF = 0.6 // approximate value for Roboto font
const PADDING = 16
const MARGIN_RIGHT = 6
const BUTTON_WIDTH = 36
const MAX_ROWS = 2
const DELETE_ICON_WIDTH = 18

interface UseTruncatedItemsReturn {
  wrapperRef: RefObject<HTMLDivElement>
  popoverRef: RefObject<HTMLDivElement>
  itemsToRender: number
  isTruncatedButtonExist: boolean
  isOpen: boolean
  onTogglePopover: (e: MouseEvent<HTMLParagraphElement>) => void
  onPopoverClick: (e: MouseEvent<HTMLDivElement>) => void
  items: string[]
  onDelete?: (item: string) => void
}

export interface UseTruncatedItemsProps {
  items?: string[]
  maxRows?: number
  marginRight?: number
  padding?: number
  symbolWidthKoef?: number
  onDelete?: (item: string) => void
}

export const useTruncatedItems = ({
  items = [],
  maxRows = MAX_ROWS,
  marginRight = MARGIN_RIGHT,
  padding = PADDING,
  symbolWidthKoef = SYMBOL_WIDTH_KOEF,
  onDelete
}: UseTruncatedItemsProps): UseTruncatedItemsReturn => {
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const popoverRef = useRef<HTMLDivElement | null>(null)
  const firstRender = useRef(true)
  const [isOpen, setIsOpen] = useState(false)
  const isClosingOutside = useRef(false)
  const [itemsToRender, setItemsToRender] = useState(0)
  const [shouldRecalculateQuery, setShouldRecalculateQuery] = useState(0)
  const closingTimeout = useRef<NodeJS.Timeout>()

  const onClose = (): void => {
    isClosingOutside.current = true

    // move closing to async queue to prevent onTogglePopover handler
    closingTimeout.current = setTimeout(() => {
      setIsOpen(false)
      isClosingOutside.current = false
    }, 0)
  }

  const onPopoverClick = (e: MouseEvent<HTMLDivElement>): void => e.stopPropagation()
  const onTogglePopover = (e: MouseEvent<HTMLParagraphElement>): void => {
    e.stopPropagation()
    if (isClosingOutside.current) {
      return
    }

    setIsOpen(prev => !prev)
  }

  useClickOutside(popoverRef, onClose, { capture: true })

  const isTruncatedButtonExist = items.length > itemsToRender

  useEffect(() => {
    if (!isTruncatedButtonExist) {
      setIsOpen(false)
    }
  }, [isTruncatedButtonExist])

  useEffect(() => {
    const debouncedRecalculate = debounce(() => {
      setShouldRecalculateQuery(prev => prev + 1)
    }, 100)

    const resizeObserver = new ResizeObserver(() => {
      if (firstRender.current) {
        firstRender.current = false
        return
      }

      requestAnimationFrame(() => {
        debouncedRecalculate()
      })
    })

    if (wrapperRef.current != null) {
      resizeObserver.observe(wrapperRef.current)
    }

    return () => {
      if (wrapperRef.current != null) {
        resizeObserver.unobserve(wrapperRef.current)
        resizeObserver.disconnect()
      }

      clearTimeout(closingTimeout.current)
    }
  }, [])

  useLayoutEffect(() => {
    if (wrapperRef.current == null) {
      return
    }

    const { width } = wrapperRef.current.getBoundingClientRect()

    let itemsToRender = 0
    let lines = maxRows
    let lineFillIndicator = Math.floor(width - (lines === 1 ? BUTTON_WIDTH : 0))

    items.forEach((item, index) => {
      if (lines === 0) {
        return
      }

      const pixelInItem = Math.ceil(item.length * symbolWidthKoef * TAG_FONT_SIZE)
      const fullElWidth = pixelInItem + PADDING + marginRight + (onDelete != null ? DELETE_ICON_WIDTH : 0)

      if (lineFillIndicator >= fullElWidth + (index < items.length - 1 ? 28 : 0)) {
        lineFillIndicator -= fullElWidth
        itemsToRender += 1

        if (index < items.length - 1 && lineFillIndicator < 28) {
          lines -= 1
          lineFillIndicator = Math.floor(width - (lines === 1 ? BUTTON_WIDTH : 0))
        }
      } else {
        lines -= 1
        if (lines > 0) {
          lineFillIndicator = Math.floor(width - (lines === 1 ? BUTTON_WIDTH : 0)) - fullElWidth
          if (lineFillIndicator >= 0) {
            itemsToRender += 1

            if (index < items.length - 1 && lineFillIndicator < 28) {
              lines -= 1
              lineFillIndicator = Math.floor(width - (lines === 1 ? BUTTON_WIDTH : 0))
            }
          }
        }
      }
    })

    setItemsToRender(itemsToRender)
  }, [
    items,
    maxRows,
    marginRight,
    symbolWidthKoef,
    padding,
    shouldRecalculateQuery,
    onDelete
  ])

  return {
    isOpen,
    isTruncatedButtonExist,
    popoverRef,
    onPopoverClick,
    onTogglePopover,
    itemsToRender,
    items,
    wrapperRef,
    onDelete
  }
}
