import { type DealFormValues } from 'types'
import { type CoverageDetailsDto } from 'api/types'
import { type DealFeesAndCoveragesSettingsModel } from 'api/types/responses'
import { getNetTradInValue } from 'utils/deals/priceHelpers/getNetTradInValue'
import { getFeesAndTaxesSum } from 'utils/deals/priceHelpers/getFeesAndTaxesSum'
import { roundCurrency } from 'utils/math'

export const getDealTotalPriceRounded = (
  values: DealFormValues,
  coverages: CoverageDetailsDto[] = [],
  feesAndCoveragesSettings: DealFeesAndCoveragesSettingsModel | null
): number => {
  const vehiclePrice = values.vehicle?.listPrice ?? 0

  const netTradeInValue = getNetTradInValue(
    values.tradeInDetails?.credit, values.tradeInDetails?.paymentAmount)

  const feesAndTaxesAmount =
    getFeesAndTaxesSum(vehiclePrice, values.dealFees, feesAndCoveragesSettings)

  const coveragesPrice =
      coverages.reduce((acc, c) => acc + (c.dealerRetailPrice ?? 0), 0)

  return roundCurrency(vehiclePrice + netTradeInValue + coveragesPrice + feesAndTaxesAmount)
}

export default getDealTotalPriceRounded
