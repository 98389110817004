import { type FC } from 'react'
import { type MenuIconProps } from 'types'

const AccountingScheduleMenuIIcon: FC<MenuIconProps> = ({
  isActive = false
}) => {
  const pictogramFill = isActive ? '#fff' : '#101010'

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 19L10.75 16.25L8 15L10.75 13.75L12 11L13.25 13.75L16 15L13.25 16.25L12 19ZM3 22V4H6V2H8V4H16V2H18V4H21V22H3ZM5 20H19V10H5V20ZM5 8H19V6H5V8Z'
        fill={pictogramFill}
      />
    </svg>
  )
}

export default AccountingScheduleMenuIIcon
