import { serializers } from '@carfluent/common'

import type { Payment, EditPaymentPayload } from 'api/types'
import { type PaymentFormData, PaymentTransactionTypeEnum } from 'types'

interface SerializeData extends PaymentFormData {
  dealId: string | number
  paymentMethod: number | null
}

const serializePayments = (values: PaymentFormData): Payment['payments'] => {
  if (!values.isManual) {
    return [{ id: 0, transactionTypeId: PaymentTransactionTypeEnum.CARD, amount: values.amount }]
  }

  const isMultiple = values.transactionTypeId === PaymentTransactionTypeEnum.MULTIPLE

  return (values.transactionTypeId !== null && !isMultiple)
    ? [{ amount: values.amount, id: 0, transactionTypeId: values.transactionTypeId }]
    : isMultiple
      ? Object.keys(values.payments).reduce((res: Payment['payments'], key) => {
        const id = PaymentTransactionTypeEnum[key as keyof typeof PaymentTransactionTypeEnum]
        const amount = values.payments[key as keyof typeof values.payments]

        if (id != null && typeof amount === 'number' && amount > 0) {
          res.push({ id: 0, transactionTypeId: id, amount })
        }

        return res
      }, [])
      : []
}

export const serializeData = ({
  dealId,
  paymentMethod,
  ...values
}: SerializeData): Payment => {
  const date = serializers.serializeDate(values.paymentDate)

  return {
    dealId: Number(dealId),
    description: values.description ?? '',
    isManual: values.isManual,
    paymentDate: date ?? '',
    paymentMethodId: paymentMethod ?? 0,
    payments: serializePayments(values)
  }
}

export const serializeEditData = (
  {
    dealId,
    paymentMethod,
    ...values
  }: SerializeData,
  receiptId: number
): EditPaymentPayload => {
  const date = serializers.serializeDate(values.paymentDate)

  return {
    id: receiptId,
    paymentDate: date ?? '',
    description: values.description ?? '',
    payments: serializePayments(values)
  }
}
