import type { UseModalReturn } from '@carfluent/common'
import type { AccountingFiscalYearsResponse, AccountingUpdateLockStateForMonthPayload } from 'api/types'
import type { BaseListItem } from 'types'

export enum ChartType {
  Default = 'Default',
  Custom = 'Custom'
}

export interface GeneralSettings {
  startDate: Date | null
  fiscalYearStartMonth: number | null
  typeOfChart: string | null
  fileUrl: string | null
  file: File | null
  printCheckNumber: boolean
  printHorizontalOffset: number
  printVerticalOffset: number
}

export interface SettingsGeneralProps {
  activeItem: AccountingFiscalYearsResponse
  confirmModal: UseModalReturn
  error: string | null
  fiscalYearList: AccountingFiscalYearsResponse[]
  fiscalYearStartMonths: BaseListItem[]
  isAccountingEnabled: boolean
  isWarningsModalOpen: boolean
  isValid: boolean
  isLoading: boolean | null
  onChange: (fieldId: string, value: unknown) => void
  onSaveField: (fieldId: string, value: unknown) => void
  onChangeActiveItem: (item: AccountingFiscalYearsResponse) => void
  onCloseWarningsModal: () => void
  onDownload: () => void
  onEnable: () => void
  onCloseYear: (yearId: number, isClose: boolean) => void
  onUpdateLockStatus: ({
    yearId, monthId, isLocked
  }: AccountingUpdateLockStateForMonthPayload) => void
  values: GeneralSettings
  warnings: Record<string, number[]>
}
