import { css } from '@emotion/css'

export default css(`
  margin-top: -16px;
  padding: 0 16px 16px 16px;

  .radio-group {
    display: flex;
    row-gap: 16px;
    
    .radio-item {
      max-width: fit-content;
      padding: 0;
      margin: 0 32px 0 -9px;
      height: 24px;
    }
  }

  .radios-title-error {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .error-text {
      color: #B00020;
      font-size: 12px;
      letter-spacing: 0.4px;
    }
  }

  .notes-field {
    margin-top: 24px;
  }

  .form-subtitle {
    margin: 16px 0;
    font-size: 16px;
    color: #101010;
    font-weight: 500;
  }
  
  .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-endAdornment {
    button {
      padding: 8px 12px;
      margin-right: 8px;
      width: 12px;
    }
  }
`)
