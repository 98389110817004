import { formatUTCDateTimeForView } from 'utils/view_helper'
import { DATE_US_FORMAT } from 'constants/constants'

export const formatDateForTimelineGroup = (date: string): string => {
  const dateObj = new Date(date)
  if (dateObj.getDate() === new Date().getDate()) {
    return 'Today'
  } else if (dateObj.getDate() === new Date().getDate() - 1) {
    return 'Yesterday'
  }

  return formatUTCDateTimeForView(date, DATE_US_FORMAT) ?? 'Today'
}
