import type { ReactNode } from 'react'
import { Button } from '@carfluent/common'

export type RenderCollapseBtnFunc = (
  isExpanded: boolean,
  isDisabled: boolean,
  toggleIsExpanded?: () => void,
  getBtnText?: (isExpanded: boolean) => string
) => ReactNode

export const renderAddRemoveBtn: RenderCollapseBtnFunc = (
  isExpanded: boolean,
  isDisabled: boolean,
  toggleIsExpanded?: () => void,
  getBtnText?: (isExpanded: boolean) => string
): ReactNode => {
  if (toggleIsExpanded == null) {
    return null
  }

  const btnText = getBtnText?.(isExpanded) ?? (isExpanded ? 'REMOVE' : 'ADD')

  return (
    <Button
      onClick={toggleIsExpanded}
      isDisabled={isDisabled}
      className='collapse-btn'
      isContentSized
      variant='outlined'
      color='secondary'
    >
      {btnText}
    </Button>
  )
}
