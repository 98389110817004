import { useCallback, useMemo, useState } from 'react'
import { type Row } from '@tanstack/react-table'
import { useParams, useLocation } from 'react-router-dom'
import { useModal } from '@carfluent/common'

import AccountingApiProvider from 'api/accounting.api'
import useAsyncEffect from 'hooks/useAsyncEffect'
import useTableApi from 'hooks/useTableApi'
import useNavigate from 'hooks/useNavigate'
import serializeFiltersLocal from 'utils/filters/serializeFiltersLocal'
import { getPageOverlayContainer } from 'utils/html'
import { ReconciliationSetup } from 'constants/route_helper'
import {
  type Account,
  type AccountBalanceListItem,
  type AccountTransaction,
  type ListPayload,
  type PaginatedResult
} from 'api/types'

import getColumnDefinitions from './columns'
import { GET_DEFAULT_SHORT_INFO, GET_DEFAULT_FILTERS, DEFAULT_SORTING } from './constants'
import { parseTransactions, parseAccountShortInfo } from './parser'
import {
  type AccountTransactionRow,
  type UseAccountTrialBalanceReturn,
  type FiltersFormData
} from './types'

const DEFAULT_FILTERS = GET_DEFAULT_FILTERS()

const useAccountTrialBalance = (): UseAccountTrialBalanceReturn => {
  const navigate = useNavigate()
  const { id = '' } = useParams<{id: string}>()
  const location = useLocation<AccountBalanceListItem | null>()
  const accountId = Number(id)
  const containerElement = getPageOverlayContainer()

  const [transactionId, setTransactionId] = useState<number | null>(null)
  const [transactionTypeId, setTransactionTypeId] = useState<number | null>(null)
  const [lastSubmitTs, setLastSubmitTs] = useState(Date.now())
  const [accountShortInfo, setAccountShortInfo] = useState(GET_DEFAULT_SHORT_INFO)
  const [account, setAccount] = useState<Account | null>(null)

  const getAccountTransactions = useCallback(async (_payload: ListPayload): Promise<PaginatedResult<AccountTransaction>> => {
    const payload = { ..._payload, id: accountId }
    return await AccountingApiProvider.getAccountTransactions(payload)
  }, [accountId])

  const {
    emptyTableMessage,
    isLoading,
    loadRows,
    onSearch,
    rows,
    search,
    setFilters,
    setSearch,
    setSorting,
    sorting
  } = useTableApi<AccountTransaction, any, AccountTransactionRow>({
    containerElement,
    defaultFilters: DEFAULT_FILTERS,
    defaultSorting: DEFAULT_SORTING,
    getList: getAccountTransactions,
    parseListData: parseTransactions,
    serializeFilters: serializeFiltersLocal,
    shouldRunOnCall: true
  })

  const {
    isModalOpen: isEditRowOpen,
    onOpenModal: openEditRow,
    onCloseModal: closeEditRow
  } = useModal()

  const {
    isModalOpen: isEditAccountOpen,
    onOpenModal: openEditAccount,
    onCloseModal: closeEditAccount
  } = useModal()

  const columnDefinitions = useMemo(() => {
    return getColumnDefinitions({
      showStatusColumn: location.state?.hasBankStatement ?? account?.hasBankStatement ?? false
    })
  }, [
    account?.hasBankStatement,
    location.state?.hasBankStatement
  ])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onViewNextTransaction = useCallback((
    transactionId: number,
    transactionTypeId: number,
    costTypeId: number | null = null
  ) => {
    closeEditRow()

    setTimeout(() => {
      setTransactionId(transactionId)
      setTransactionTypeId(transactionTypeId)
      openEditRow()
    }, 0)
  }, [
    closeEditRow,
    openEditRow
  ])

  const onJournalEntryClick = useCallback((row: Row<AccountTransactionRow>) => {
    const { transactionId, transactionTypeId, costTypeId } = row.original
    onViewNextTransaction(transactionId, transactionTypeId, costTypeId)
  }, [onViewNextTransaction])

  const onGoBack = useCallback((): void => {
    navigate(-1)
  }, [navigate])

  const onFiltersChange = useCallback(async (data: Partial<FiltersFormData>) => {
    void setFilters(data, true)
  }, [setFilters])

  const onSubmitTransaction = useCallback(async () => {
    setLastSubmitTs(Date.now())
    await loadRows()
  }, [
    closeEditRow,
    loadRows,
    onFiltersChange
  ])

  const onSubmitTransactionWrapper = useCallback((): void => {
    void onSubmitTransaction()
  }, [onSubmitTransaction])

  const onSubmitAccount = useCallback(async () => {
    setLastSubmitTs(Date.now())
    await loadRows()
  }, [
    closeEditAccount,
    loadRows
  ])

  const onBottomReached = useCallback(async () => {
    if (!isLoading) {
      await loadRows({ forceRefresh: false })
    }
  }, [isLoading, loadRows])

  const onSortingChange = useCallback((...args: Parameters<typeof setSorting>): void => {
    void setSorting(...args)
  }, [setSorting])

  const onReconcileNavigate = useCallback(() => {
    navigate(ReconciliationSetup(accountId))
  }, [navigate])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useAsyncEffect(async (): Promise<void> => {
    const data = await AccountingApiProvider.getAccount(accountId)
    setAccountShortInfo(parseAccountShortInfo(data))
    setAccount(data)
  }, [accountId, lastSubmitTs])

  // ========================================== //

  return {
    accountEditProps: {
      accountId,
      isModalOpen: isEditAccountOpen,
      onOpenModal: openEditAccount,
      onCloseModal: closeEditAccount,
      onCancel: closeEditAccount,
      onSubmit: onSubmitAccount
    },
    accountHeaderProps: {
      filtersProps: {
        defaultFilters: DEFAULT_FILTERS,
        onFiltersChange
      },
      searchProps: {
        isLoading,
        onChange: setSearch,
        onSearch,
        value: search
      },
      onReconcileClick: onReconcileNavigate,
      hasBankStatement: location.state?.hasBankStatement ?? account?.hasBankStatement ?? false,
      shortInfo: accountShortInfo
    },
    columns: columnDefinitions,
    containerElement,
    rows,
    sorting,
    emptyState: emptyTableMessage,
    onSortingChange,
    onGoBack,
    onJournalEntryClick: onJournalEntryClick,
    onBottomReached,
    transactionDetailsProps: {
      isModalOpen: isEditRowOpen,
      onCloseModal: closeEditRow,
      onCancel: closeEditRow,
      onSubmit: onSubmitTransactionWrapper,
      onViewNextTransaction,
      transactionTypeId,
      transactionId
    }
  }
}

export default useAccountTrialBalance
