import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-wrap: nowrap;
  margin: 16px 16px 0 16px;
  background: #FFF;
  
  label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #101010;
  }
  
  img {
    margin-right: 12px;
  }
`)
