import { useState } from 'react'
import type { FCHook } from 'types'

import type { ActionOptions } from 'components/common/ActionsMenu'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

import type { CarOfInterest } from './components/CarInfo'

export interface UseCarsOfInterestProps {
  cars: CarOfInterest[]
  onDeleteCar: (id: number) => void
  onMakePrimaryCar?: (id: number) => void
}

export interface UseCarsOfInterestReturn {
  cars: CarOfInterest[]
  currentCarIndex: number
  hasCars: boolean
  onDeleteCar: (car: CarOfInterest) => void
  onMakePrimaryCar: (car: CarOfInterest) => void
  onShowNextCar: () => void
  onShowPrevCar: () => void
  onCopy: (nameOfFiled: string) => void
  actionList: ActionOptions[]
  carToRender: CarOfInterest | undefined
}

const useCarsOfInterest: FCHook<UseCarsOfInterestProps, UseCarsOfInterestReturn> = ({
  cars: _cars,
  onDeleteCar: _onDeleteCar,
  onMakePrimaryCar: _onMakePrimaryCar
}) => {
  const [currentCarIndex, setCarIndex] = useState(0)
  const { showSuccess } = useCustomSnackbar()
  const cars = _cars.length > 0 ? _cars : []
  const hasCars = cars.length > 0

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onShowNextCar = (): void => {
    if (hasCars) {
      setCarIndex(prevIndex => Math.min(prevIndex + 1, cars.length - 1))
    }
  }

  const onShowPrevCar = (): void => {
    if (hasCars) {
      setCarIndex(prevIndex => Math.max(prevIndex - 1, 0))
    }
  }

  const onDeleteCar = (car: CarOfInterest): void => {
    _onDeleteCar(car.id)
    setCarIndex(0)
  }

  const onMakePrimaryCar = (car: CarOfInterest): void => _onMakePrimaryCar?.(car.id)
  const onCopy = (nameOfFiled: string): void => showSuccess(`${nameOfFiled} copied.`)

  const carToRender = cars[currentCarIndex]

  const actionList = [
    {
      disabled: carToRender?.isPrimary ?? true,
      title: 'Make primary car of interest',
      handleOnClick: (): void => {
        onMakePrimaryCar(carToRender)
      }
    },
    {
      title: 'Remove car',
      handleOnClick: (): void => onDeleteCar(carToRender)
    }
  ]

  // ========================================== //

  return {
    cars,
    currentCarIndex,
    hasCars,
    onShowNextCar,
    onShowPrevCar,
    onDeleteCar,
    onMakePrimaryCar,
    onCopy,
    actionList,
    carToRender
  }
}

export default useCarsOfInterest
