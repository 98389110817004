const getDownloadFileUrl = (
  dirSlug: string,
  fileId: number | null,
  fileVersion?: string
): string => {
  const envUrl = process.env.REACT_APP_FILES ?? ''
  const fileSlug = fileVersion == null ? (fileId ?? '') : `${fileVersion}/${fileId ?? ''}`

  return `${envUrl}/api/v1/Download/${dirSlug}/${fileSlug}`
}

export default getDownloadFileUrl
