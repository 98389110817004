import {
  dictionaryItemDropdownField,
  transactionDescriptionField,
  startDateNotPast,
  transactionRecurringEndDate
} from 'utils/validationPresets'

export default {
  startDate: startDateNotPast(true),
  endDate: transactionRecurringEndDate(true),
  recurringInterval: dictionaryItemDropdownField(true),
  description: transactionDescriptionField(true),
  receivableVendor: dictionaryItemDropdownField(true),
  receivableControl: dictionaryItemDropdownField(false),
  memo: transactionDescriptionField()
}
