import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'

export interface EmptyCellProps {
  getValue: () => any
}

const EmptyCell: FC<EmptyCellProps> = () => {
  return (
    <CellWrapper> </CellWrapper>
  )
}

export default EmptyCell
