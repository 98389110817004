import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { CampaignSteps } from 'types/enums'
import { CampaignStepsName } from 'constants/names'
import Stepper from 'components/common/Stepper'
import TopPanelActions from 'components/common/TopPanelActions'
import DeleteModal from 'components/dialogs/DeleteModal'

import StepCampaignContent from './components/StepCampaignContent'
import StepReview from './components/StepReview'
import StepRecipients from './components/StepRecipients'
import StepCampaignStatistics from './components/StepCampaignStatistics'
import useCampaign from './hook'
import { FormTitle } from './hook/constants'
import { CLASS_NAME } from './styles'

const STEPS = [
  CampaignStepsName[CampaignSteps.CampaignInformation],
  CampaignStepsName[CampaignSteps.Recipients],
  CampaignStepsName[CampaignSteps.ReviewSend]
]

const CampaignDetails: FC = () => {
  const {
    actions,
    campaign,
    currentStepId,
    hasUnsavedChanges,
    isCreateMode,
    isEditMode,
    isLoading,
    modalDeleteProps,
    onChangeUnsavedChanges,
    onDeleteCampaign,
    onEditCampaignInformation,
    onEditRecipients,
    onGoBack,
    onRequestNextStep,
    onRequestPreviousStep,
    onSendCampaign,
    recipientsCount,
    timeZoneDisplayName
  } = useCampaign()

  const classes = cnx(
    CLASS_NAME,
    'campaign-layout',
    isCreateMode && 'campaign-layout--create',
    isEditMode && 'campaign-layout--edit',
    !(isCreateMode || isEditMode) && 'campaign-layout--view'
  )

  // ========================================== //

  return (
    <div className={classes}>
      {(currentStepId !== CampaignSteps.Statistics) && (
        <div className='header-container'>
          <h3 className='title'>{FormTitle.CreateCampaign}</h3>

          <Stepper
            currentStep={currentStepId}
            numberOfSteps={STEPS.length}
            steps={STEPS}
          />
        </div>
      )}

      {(currentStepId === CampaignSteps.Statistics) && (
        <TopPanelActions
          className='g-top-panel-actions g-top-panel-actions-big-height'
          actions={actions}
          isActionButtonDisable
          backButtonName='Back'
          onGoBack={onGoBack}
          isActionBtnLoading={false}
        />
      )}

      <div className='wrapper'>
        {(currentStepId === CampaignSteps.CampaignInformation) && (
          <StepCampaignContent
            campaign={campaign}
            canDeleteCampaign={isEditMode}
            hasUnsavedChanges={hasUnsavedChanges}
            onChangeUnsavedChanges={onChangeUnsavedChanges}
            onDeleteCampaign={modalDeleteProps.onOpenModal}
            onRequestNextStep={onRequestNextStep}
          />
        )}

        {(currentStepId === CampaignSteps.Recipients) && (
          <StepRecipients
            campaign={campaign}
            canDeleteCampaign={isEditMode}
            hasUnsavedChanges={hasUnsavedChanges}
            onChangeUnsavedChanges={onChangeUnsavedChanges}
            onDeleteCampaign={modalDeleteProps.onOpenModal}
            onRequestPreviousStep={onRequestPreviousStep}
            onRequestNextStep={onRequestNextStep}
          />
        )}

        {(currentStepId === CampaignSteps.ReviewSend) && (
          <StepReview
            campaign={campaign}
            canDeleteCampaign={isEditMode}
            hasUnsavedChanges={hasUnsavedChanges}
            onChangeUnsavedChanges={onChangeUnsavedChanges}
            onEditCampaignInformation={onEditCampaignInformation}
            onEditRecipients={onEditRecipients}
            onDeleteCampaign={modalDeleteProps.onOpenModal}
            onRequestPreviousStep={onRequestPreviousStep}
            onSendCampaign={onSendCampaign}
            timeZoneDisplayName={timeZoneDisplayName}
          />
        )}

        {(currentStepId === CampaignSteps.Statistics) && (
          <StepCampaignStatistics
            campaign={campaign}
            isLoading={isLoading}
            recipientsCount={recipientsCount}
          />
        )}
      </div>

      <DeleteModal
        content='Are you sure you want to delete the campaign from the campaign list?'
        isOpen={modalDeleteProps.isModalOpen}
        onClose={modalDeleteProps.onCloseModal}
        onConfirm={onDeleteCampaign}
        submitTitle='YES, DELETE'
        title='Delete campaign'
      />
    </div>
  )
}

export default CampaignDetails
