import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;

  .form-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }

  .table-container {
    position: relative;
    color: #C99B86;
  }

  .banking-banners-info-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    margin-bottom: 8px;
  }

  .deal-recap-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
    
  .bank-statement-matches-container {
    margin-top: 24px;
  }
  .banking-recap-container {
    margin-bottom: 24px;

    .deal-recap-container {
      justify-content: flex-start;
    }
  }
`)
