import { type DeepRequired } from '@carfluent/common'
import { type CostFormData } from './types'

export const DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm aa'

export const MODAL_CLOSE_DELAY = 500

export const DEFAULT_FORM_VALUES: DeepRequired<CostFormData> = {
  amount: null,
  costType: null,
  creditAccountId: null,
  date: new Date(),
  debitAccountId: null,
  description: '',
  id: null,
  isLocked: false,
  purchaseAccountId: null,
  referenceNumber: null,
  vendor: null
}
