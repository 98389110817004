import { FC, useCallback } from 'react'
import { Modal } from '@carfluent/common'
import Button from 'components/common/Button'

import CLASS_NAME from './styles'

interface RemoveUserModalProps {
  isOpen: boolean
  onSubmit: () => Promise<void>
  onClose: () => void
  onCancel: () => void
}

const TITLE = 'Remove user?'
const DEFAULT_MIN_WIDTH = 680

const RemoveUserModal: FC<RemoveUserModalProps> = ({
  isOpen,
  onClose,
  onCancel,
  onSubmit: _onSubmit
}) => {
  const onSubmit = useCallback(async (): Promise<void> => {
    await _onSubmit()
    onClose()
  }, [onClose, _onSubmit])

  const renderFooterActions = useCallback(() => {
    return (
      <>
        <Button
          data-test-id='btn-cancel'
          className='cf-cancel-btn'
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button
          data-test-id='btn-remove'
          onClick={onSubmit}
        >
          Remove
        </Button>
      </>
    )
  }, [onCancel, onSubmit])

  return (
    <Modal
      className={CLASS_NAME}
      isOpen={isOpen}
      minWidth={DEFAULT_MIN_WIDTH}
      onClose={onClose}
      renderFooterActions={renderFooterActions}
      title={TITLE}
    >
      <p>It will revoke access to the dealership for this user.</p>
      <p>All assigned tasks will remain assigned to this user.</p>
    </Modal>
  )
}

export default RemoveUserModal
