import { makeStyles } from '@material-ui/core/styles'
import { px, ContentLayoutWidth as CLW, PAGE_TITLE_HEIGHT } from 'constants/constants'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    marginLeft: '64px',
    minHeight: `calc(100vh - ${px(PAGE_TITLE_HEIGHT)})`
  },
  content: {
    height: `calc(100% - ${px(PAGE_TITLE_HEIGHT)})`,
    width: '100%',
    minWidth: '1280px',
    background: '#FFF'
  },
  wideContent: {
    minWidth: `calc(${px(CLW.MinContent)} + ${px(CLW.MinSideMargins)})`
  },
  settingsPageContent: {
    minWidth: `calc(1280px - ${px(CLW.NavDrawer)})`
  },
  greyBackgroundPageContent: {
    background: '#F3F3F3'
  },
  fullPage: {
    '& > .g-content-layout': {
      width: 'auto',

      '& > .cf-details-view-overlay': {
        padding: '24px 0',

        '& > div:last-of-type': {
          minWidth: 'auto',
          maxWidth: 'inherit'
        }
      }
    }
  }
}))

export default useStyles
