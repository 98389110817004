import type { FC } from 'react'
import { CellWrapper } from '@carfluent/common'

import LeadLabel from 'components/common/LeadLabel'
import TooltipedText from 'components/common/TooltipedText'

import CLASS_NAME from './styles'

export interface LeadNameAndEmailCellProps {
  getValue: () => LeadNameAndEmailCellData
}

export interface LeadNameAndEmailCellData {
  name: string
  email: string
  label: keyof typeof LeadLabel | null
}

const LeadNameAndEmailCell: FC<LeadNameAndEmailCellProps> = (props) => {
  const { name, email, label } = props.getValue()

  return (
    <CellWrapper className={CLASS_NAME}>
      <div className='name-and-temperature-label-wrapper'>
        <TooltipedText
          value={name}
          rootClassName='table-cell-text'
        />
        <LeadLabel
          value={label ?? ''}
        />
      </div>

      <div className='email-wrapper'>
        <p className='label'>Email:</p>
        <TooltipedText
          value={email}
          rootClassName='table-cell-text'
        />
      </div>
    </CellWrapper>
  )
}

export default LeadNameAndEmailCell
