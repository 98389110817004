import type { FC } from 'react'
import UnsavedChangesDialog from 'components/dialogs/UnsavedChanges'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import TopPanelActions from 'components/common/TopPanelActions'
import type { CreditApplicationPageContentProps } from '../../types'
import CreditApplicationForm from './CreditApplicationForm'
import useCreditAppForm from './hook'

const CreditApplicationPageContent: FC<CreditApplicationPageContentProps> = ({
  dealId,
  feesAndCoveragesSettings,
  isTradeInSectionVisible,
  isWarrantySectionVisible,
  isCoApplicantSectionVisible,
  creditAppData,
  employmentStatuses,
  housingStatuses,
  coApplicantTypes,
  wholesaleSourceTypes,
  businessTypes,
  getCreditApplicationData,
  onToggleIsTradeInSectionVisible,
  onToggleIsWarrantySectionVisible: _onToggleIsWarrantySectionVisible,
  onToggleIsCoApplicantSectionVisible
}) => {
  const {
    isCloseDialogVisible,
    isFormDisabled,
    isSubmitting,
    onGoBack,
    onSaveForm,
    onRequestCloseForm,
    onSendForm,
    onCloseForm,
    onHideCloseDialog,
    onApplyVin,
    onToggleIsWarrantySectionVisible,
    ...formProps
  } = useCreditAppForm({
    creditAppData,
    dealId,
    employmentStatuses,
    feesAndCoveragesSettings,
    isTradeInSectionVisible,
    isWarrantySectionVisible,
    getCreditApplicationData,
    isCoApplicantSectionVisible,
    onToggleIsWarrantySectionVisible: _onToggleIsWarrantySectionVisible
  })

  return (
    <>
      <TopPanelActions
        className='g-top-panel-actions g-top-panel-actions-small-height'
        backButtonName='back'
        actionButtonName='save changes'
        onGoBack={onGoBack}
        onActionClick={onSaveForm}
        isActionButtonDisable={isFormDisabled}
      />

      <CreditApplicationForm
        feesAndCoveragesSettings={feesAndCoveragesSettings}
        isFormDisabled={isFormDisabled}
        isTradeInSectionVisible={isTradeInSectionVisible}
        isWarrantySectionVisible={isWarrantySectionVisible}
        isCoApplicantSectionVisible={isCoApplicantSectionVisible}
        employmentStatuses={employmentStatuses}
        housingStatuses={housingStatuses}
        coApplicantTypes={coApplicantTypes}
        wholesaleSourceTypes={wholesaleSourceTypes}
        businessTypes={businessTypes}
        onApplyVin={onApplyVin}
        onToggleIsTradeInSectionVisible={onToggleIsTradeInSectionVisible}
        onToggleIsWarrantySectionVisible={onToggleIsWarrantySectionVisible}
        onToggleIsCoapplicantSectionVisible={onToggleIsCoApplicantSectionVisible}
        {...formProps}
      />

      <div className='cf-footer-actions-section'>
        <CancelSubmitActionsFooter
          cancelTitle='CLOSE'
          isLoading={isSubmitting}
          isDisabled={isFormDisabled}
          onSubmit={onSendForm}
          onClose={onRequestCloseForm}
          submitTitle='SEND'
        />
      </div>

      <UnsavedChangesDialog
        content="Document can't be restored."
        isOpen={isCloseDialogVisible}
        isSubmitting={isSubmitting}
        onCloseDialog={onHideCloseDialog}
        onDontSaveAndClose={onCloseForm}
        onSaveAndClose={onSaveForm}
      />
    </>
  )
}

export default CreditApplicationPageContent
