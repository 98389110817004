import { parseDateStringUTC } from '@carfluent/common'

import { type DictionaryItem } from 'api/types'
import {
  type CampaignListModel,
  type CampaignListRowModel,
  type CampaignListDto
} from 'api/types/responses'

export interface ParserCampaignListProps {
  campaigns: CampaignListDto[] | null
  statusList: DictionaryItem[]
  typeList: DictionaryItem[]
}

export interface ParserCampaignListRowProps {
  campaign: CampaignListDto
  statusList: DictionaryItem[]
  typeList: DictionaryItem[]
}

const parseCampaignListRow = ({
  campaign,
  statusList,
  typeList
}: ParserCampaignListRowProps): CampaignListRowModel => {
  return {
    id: campaign?.id ?? 0,
    name: campaign?.name ?? '',
    campaignType: typeList.find((el) => el.id === campaign?.campaignTypeId) ?? null,
    campaignStatus: statusList.find((el) => el.id === campaign?.campaignStatusId) ?? null,
    scheduledDateTime: parseDateStringUTC(campaign?.scheduledDateTime) ?? null,
    campaignStatistic: campaign?.campaignStatistic != null
      ? {
          totalSentCount: campaign?.campaignStatistic?.delivered.toLocaleString('en-US').toString(),
          openRate: campaign?.campaignStatistic?.uniqueOpensRate ?? 0,
          clickRate: campaign?.campaignStatistic?.uniqueClicksRate ?? 0,
          appointmentsCount: campaign?.campaignStatistic?.appointmentsCount.toLocaleString('en-US').toString(),
          salesCount: campaign?.campaignStatistic?.salesCount.toLocaleString('en-US').toString(),
          totalGrossAmount: campaign?.campaignStatistic?.totalGrossAmount ?? 0
        }
      : null
  }
}

const parseCampaignListDto = ({
  campaigns,
  statusList,
  typeList
}: ParserCampaignListProps): CampaignListModel => {
  return {
    campaigns: campaigns?.map((el) => parseCampaignListRow({ campaign: el, statusList, typeList })) ?? [],
    statusList
  }
}

export default parseCampaignListDto
