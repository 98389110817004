import { css } from '@emotion/css'

export default css(`
  overflow: hidden;

  .recon-dashboard-header {
    padding: 0 16px;
  }

  .recon-dashboard-status-filters {
    margin-right: 0;
    height: calc(100% - 32px);

    .no-alert-line,
    .first-alert-line,
    .second-alert-line {
      height: calc(100% - 24px);
    }

    .cf-recon-step-filters-header {
      padding: 0 16px!important;
      min-height: 24px;
    }
  }
`)
