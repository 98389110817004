import { UseFormReturn } from '@carfluent/common'
import type { PickedFormDataProps } from 'types'
import { type DictionaryItem, type WorkPlanEventDto } from 'api/types'
import { type FormValues } from './validator'

export type SubmitReason = 'update' | 'create'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ErrTouchShortcuts = {
  reminderSubject: true
  assignedUser: true
  taskType: true
  taskPriority: true
}

export interface UseAddEditRuleReturn extends Pick<UseFormReturn<FormValues, ErrTouchShortcuts>, PickedFormDataProps | 'onSubmit'> {
  isReminderDisabled: boolean
  isLoading: boolean
  taskTypes: DictionaryItem[]
  taskPriorities: DictionaryItem[]
  reminderSubjects: DictionaryItem[]
  users: DictionaryItem[]
  messageTemplates: DictionaryItem[]
  emailTemplates: DictionaryItem[]
  timeZoneDisplayName: string | null
  onClose: () => void
  onChangeTaskType: (id: string, value: DictionaryItem | null) => void
  onDeleteEvent: () => Promise<void>
  onChangeEventType: (value: string) => void
}

export interface WorkPlanEventFormBarProps {
  values: UseAddEditRuleReturn['values']
  errors: UseAddEditRuleReturn['errors']
  touched: UseAddEditRuleReturn['touched']
  messageTemplates: DictionaryItem[]
  emailTemplates: DictionaryItem[]
  onChange: UseAddEditRuleReturn['onChange']
  onBlur: UseAddEditRuleReturn['onBlur']
  timeZoneDisplayName: string | null
}

export interface UseAddEditRuleProps {
  onCloseModal?: () => void
  leadStatus: DictionaryItem | null
  formData?: WorkPlanEventDto | null
  onSubmit: (data: WorkPlanEventDto, reason: SubmitReason) => void
  onDelete: (id: number) => Promise<void>
}

export enum WorkPlanEventType {
  Task = 1,
  Email = 2,
  Message = 3
}
