import { type FC, type ReactNode } from 'react'
import { Banner, Button } from '@carfluent/common'
import { Link, useNavigate } from 'react-router-dom'

import CalcRoutes, { Routes } from 'constants/route_helper'
import { TabIds } from 'pages/inventory/VehicleDetails/hooks/constants'
import { TransactionStateId } from 'api/types'

import { type BannersProps, ReadonlyMessageType } from './types'

const Banners: FC<BannersProps> = ({
  bankStatements,
  closeYearError,
  depositDealId,
  hasLineWithCompletedReconciliation,
  inventoryId,
  isGeneratedTransaction,
  isLocked,
  isReadonly,
  isSystemCreated,
  isVehiclePayment,
  isVehicleCost,
  lockedDate,
  lockedInfo,
  onCloseModal,
  packInventoryAssetAccountNumber,
  retailVehicleCostsAccountNumber,
  tableData,
  transactionStateId
}) => {
  const navigate = useNavigate()

  const retailLineInTransactionLine = tableData
    .find((item) => item.account?.number === retailVehicleCostsAccountNumber)

  const packLineInTransactionLine = tableData
    .find((item) => item.account?.number === packInventoryAssetAccountNumber)

  const isEmptyRetailAndPackCostLine =
    (retailLineInTransactionLine?.credits === 0 && retailLineInTransactionLine.debits === 0) ||
    (packLineInTransactionLine?.credits === 0 && packLineInTransactionLine.debits === 0)

  const isYearClosed = lockedInfo?.isClosed ?? false
  const lockYear = lockedInfo?.year ?? null
  const lockMonth = lockedDate?.toLocaleDateString('en-us', { month: 'long', year: 'numeric' })
  const hasCloseYearApiError = closeYearError != null

  const showLockedMonthSettingsLink = (lockMonth != null) &&
    (!isYearClosed || hasCloseYearApiError) &&
    !isSystemCreated &&
    !hasLineWithCompletedReconciliation

  const isReadonlyTransactionBannerVisible = hasCloseYearApiError || isReadonly
  const isLackOfCostsBannerVisible = isEmptyRetailAndPackCostLine && isGeneratedTransaction && (inventoryId != null)

  const readonlyMessageInfo = ((): { element: JSX.Element, messageType: ReadonlyMessageType } | null => {
    if (!isReadonlyTransactionBannerVisible) {
      return null
    }

    /**
     * A banking-related transaction.
     */

    if (hasLineWithCompletedReconciliation) {
      return {
        element: <div>This transaction has been already reconciled. No modifications are allowed.</div>,
        messageType: ReadonlyMessageType.ReconciledTransaction
      }
    }

    if (hasCloseYearApiError) {
      return {
        element: <div>{closeYearError}</div>,
        messageType: ReadonlyMessageType.LockedYearError
      }
    }

    /**
     * A transaction with date that belongs to "locked" date-rage.
     */
    if (isLocked) {
      if (isYearClosed) {
        return {
          element: <div>{lockYear} year is closed.</div>,
          messageType: ReadonlyMessageType.LockedYear
        }
      } else {
        return {
          element: <div>{lockMonth} is locked. To adjust transaction, unlock month in accounting settings.</div>,
          messageType: ReadonlyMessageType.LockedMonth
        }
      }
    }

    /**
     * A system transaction that is created when User adds a Receipt to Deal.
     */
    if ((depositDealId != null) && isSystemCreated) {
      if (transactionStateId === TransactionStateId.Voided) {
        return {
          element: <div>This receipt has been voided.</div>,
          messageType: ReadonlyMessageType.VoidedReceipt
        }
      }

      if (transactionStateId === TransactionStateId.Refunded) {
        return {
          element: <div>This receipt has been refunded.</div>,
          messageType: ReadonlyMessageType.RefundReceipt
        }
      }
    }

    /**
     * A cost-related transaction (created as a by-product of cost creation).
     */
    if (isVehiclePayment || isVehicleCost) {
      return {
        element: <div>Journal entry is locked since it is created by the system automatically. Go to Inventory to manage the costs.</div>,
        messageType: ReadonlyMessageType.CostTransaction
      }
    }

    return {
      element: <div>Journal entry is locked since it is created by system automatically.</div>,
      messageType: ReadonlyMessageType.OtherSystemCreated
    }
  })()

  const readonlyBannerLink = (): ReactNode => {
    /**
     * A transaction that belongs to "locked" month can be adjusted if user "unlocks" the month is settings.
     */
    if (showLockedMonthSettingsLink) {
      return (
        <Link to={Routes.AccountingGeneralSettings}>Accounting settings</Link>
      )
    }

    if ((readonlyMessageInfo?.messageType === ReadonlyMessageType.CostTransaction) && (inventoryId != null)) {
      return (
        <Button
          className='g-link-button'
          variant='text'
          onClick={() => {
            navigate(CalcRoutes.VehicleDetails(inventoryId, TabIds.Costs))
            onCloseModal() // needed to close JE if we already are on Inventory page
          }}
        >
          Inventory
        </Button>
      )
    }
  }

  return (
    <>
      {isReadonlyTransactionBannerVisible && (
        <Banner
          message={isReadonlyTransactionBannerVisible ? readonlyMessageInfo?.element : null}
          type={hasCloseYearApiError ? 'error' : 'info'}
          endAdornment={isReadonlyTransactionBannerVisible ? readonlyBannerLink() : null}
        />
      )}

      {isLackOfCostsBannerVisible && (
        <Banner
          message='Lack of Costs. To add them, go to the Inventory.'
          type='info'
          endAdornment={
            <Link to={CalcRoutes.VehicleDetails(inventoryId, TabIds.Costs)}>
              Inventory
            </Link>
          }
        />
      )}
    </>
  )
}

export default Banners
