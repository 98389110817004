import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  inputFile: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0,
    cursor: 'pointer'
  },
  content: {
    textAlign: 'center',
    minWidth: 312
  },
  dragContent: {
    borderRadius: '8px',
    height: '100%',
    width: '100%',
    background: 'rgba(201, 155, 134, .1)',
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dragContentActive: {
    display: 'flex'
  },
  contentHidden: {
    display: 'none'
  },
  dragTitle: {
    fontSize: 16,
    lineHeight: '24px'
  },
  uploadBtn: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: 1.25,
    color: '#000000',
    background: 'none',
    border: '1px solid #C99B86',
    borderRadius: 8,
    maxWidth: 143,
    padding: '5px 18px',
    display: 'inline-block',
    textTransform: 'uppercase',
    '&:hover': {
      background: 'none'
    }
  },
  textHelper: {
    color: 'rgba(33, 33, 33, 0.5)',
    marginTop: 16
  }
})
