import { useEffect } from 'react'
import { useModal, type UseModalReturn } from '@carfluent/common'

export interface UseUnsavedChangesProps {
  onTrySwitchTab: () => void
  shouldShowUnsavedChanges: boolean
  trigger?: object // AZ-TODO: check, if needed
}

export type UseUnsavedChangesReturn = UseModalReturn

const useUnsavedChanges = ({
  onTrySwitchTab,
  shouldShowUnsavedChanges,
  trigger
}: UseUnsavedChangesProps): UseUnsavedChangesReturn => {
  const { isModalOpen, onCloseModal, onOpenModal } = useModal()

  useEffect(() => {
    if (shouldShowUnsavedChanges) {
      onOpenModal()
    } else {
      onCloseModal()
      onTrySwitchTab()
    }
  }, [onCloseModal, onOpenModal, onTrySwitchTab, shouldShowUnsavedChanges, trigger])

  // ========================================== //

  return {
    isModalOpen,
    onCloseModal,
    onOpenModal
  }
}

export default useUnsavedChanges
