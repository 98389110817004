import subYears from 'date-fns/subYears'

export enum ValidationLength {
  STREET_NAME_MAX = 20,
  LONG_STREET_NAME_MAX = 70,
  APT_MAX = 5,
  DRIVER_LICENSE_MIN = 7,
  EMAIL_MAX = 50,
  NAME_MIN = 2,
  NAME_MAX = 70,
  CITY_MAX = 20,
  FIRST_NAME_MAX = 15,
  LAST_NAME_MAX = 25,
  PASSWORD_MIN = 6,
  PASSWORD_MAX = 100,
  PHONE_NUMBER = 10,
  SSN = 9,
  STOCK_MAX = 20,
  ZIP_CODE = 5,
  TERM_MIN = 1,
  TERM_MAX = 99,
  TRADE_IN = 1,
  VIN_LENGTH = 17,
  YEAR_MAX = 4,
  MAKE_MAX = 30,
  MODEL_MAX = 50,
  TRIM_MAX = 50,
  JOB_TITLE_MAX = 26,
  EMPLOYER_NAME_MAX = 30,
  DRIVER_LICENSE_MAX = 15,
  INCOME_OPTIONS_MAX = 60,
  TITLE_NUMBER_MAX = 255,
  CARDHOLDER_NAME_MAX = 32,
  BUSINESS_NAME_MAX = 255,
  CORP_CODE = 6,
  /**
    * according to business rules, max length of input === (max length + 2) of business logic
    * due to thousand separator
    */
  MILEAGE_MAX = 10,
  SALE_PRICE_MAX = 13,
  TEXT_FIELDS_MAX = 240,
  MAX_NUMBER_LENGTH_OF_FIELD = 11,
  VENDOR_EIN_MAX = 75,
  VENDOR_TAX_MAX = 75,
  COST_REFERENCE_NUM_MAX = 75,
  DESCRIPTION_MAX = 400,
  EXECUTION_DAYS = 25,
  EXECUTION_MIN_DAYS = 1,
  INCOME_MAX_LENGTH = 7,
  MONTHLY_PAYMENT_MAX_LENGTH = 6,
  WHOLESALE_SOURCE_TYPE_MAX_LENGTH = 8,
  MIN_APPRAISAL_PRICE = -999999,
  MAX_APPRAISAL_PRICE = 999999,
  MAX_SOURCE = 40,
  EIN = 9,
  CHECKING_ACCOUNT_LENGTH = 15,
  TEMPLATE_NAME_LENGTH = 100,
  CAMPAIGN_NAME = 100
}

export const MIN_YEARS_OLD = 18
export const MAX_YEARS_OLD = 100

export const MAX_YEARS_AMOUNT = 100

export const BIRTHDATE_RANGE_START = subYears(new Date(), MIN_YEARS_OLD)
export const BIRTHDATE_RANGE_END = subYears(new Date(), MAX_YEARS_OLD)

export const MAX_CREDIT_APP_SALES_TAX_VALUE = 100
export const MAX_TERM_VALUE = 99
export const MAX_FEE_VALUE = 999_999_999.99
export const MAX_CAR_PRICE_VALUE = 999_999
export const MAX_PRICE_VALUE = 999_999_999.99
export const MAX_PERCENT_VALUE = 100
export const MAX_SALES_TAX_AMOUNT = 99_999_999_999
export const MAX_TAX_OR_FEE_VALUE = 100_000
export const MAX_COST_LABEL_LENGTH = 25
