import { useMemo } from 'react'
import type { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { PRESET_LABELS, RangePickerMode } from '@carfluent/common'

import AccountingHOK from 'pages/accounting/AccountingHOC'
import ReportLayout from 'components/reports/ReportLayout'

import TrialBalanceTable from './components/TrialBalanceTable'

import useReportTrialBalance from './hook'
import { DEFAULT_PRESETS } from './hook/constants'
import createStyleClass from './styles'

const ReportTrialBalance: FC = () => {
  const className = useMemo(createStyleClass, [])
  const { data, ...layoutProps } = useReportTrialBalance()

  return (
    <ReportLayout
      {...layoutProps}
      title='Trial balance'
      className={className}
      rootClassName={className}
      monthsNumber={1}
      presets={DEFAULT_PRESETS}
      initialPresetName={PRESET_LABELS.TODAY}
      rangePickerMode={RangePickerMode.Single}
      size='lg'
    >
      <TrialBalanceTable data={data} />
    </ReportLayout>
  )
}

export default AccountingHOK(observer(ReportTrialBalance))
