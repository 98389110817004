import { formatVehicleNameForView } from 'utils/view_helper'

import type {
  TransactionsReport as ApiData,
  TransactionsReportLine as ApiLine
} from 'api/types/accounting.types'
import { toDateOrNull } from 'utils/parse_date'

import type {
  TransactionsReport,
  TransactionsReportLine
} from './types'

const parseTransactions = (data: ApiData): TransactionsReport => {
  const transactions = data.transactions.map(({ lines, date, description, transactionStateId }) => ({
    lines: lines.map(parseLine),
    date: toDateOrNull(date),
    transactionStateId,
    description: description
  }))

  return {
    totalCredits: data.totalCredits,
    totalDebits: data.totalDebits,
    transactions
  }
}

const parseLine = (line: ApiLine): TransactionsReportLine => {
  const controlName = formatVehicleNameForView(
    line.controlYear,
    line.controlMake,
    line.controlModel,
    line.controlTrim
  )

  return {
    accountNumber: line.accountNumber,
    accountName: line.accountName,
    note: line.note,
    referenceNo: line.referenceNo,
    control: [line.control ?? '', controlName].filter(Boolean).join(' - '),
    debits: line.debits,
    credits: line.credits
  }
}

export default parseTransactions
