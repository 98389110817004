import { CampaignSteps } from 'types/enums'
import { type CampaignModel } from 'api/types/responses'

import * as StepCampaignContent from '../components/StepCampaignContent/hook/utils'
import * as StepRecipientsS from '../components/StepRecipients/hook/utils'
import * as StepReview from '../components/StepReview/hook/utils'

export enum FormTitle {
  CreateCampaign = 'New campaign',
  UpdateCampaign = 'Edit campaign'
}

export const STEPS_ORDERED = [
  CampaignSteps.CampaignInformation,
  CampaignSteps.Recipients,
  CampaignSteps.ReviewSend
]

export const GET_DEFAULT_STEP_STATE = (id: CampaignSteps, data?: CampaignModel): Partial<CampaignModel> => {
  switch (id) {
    case CampaignSteps.CampaignInformation: {
      return StepCampaignContent.mapFormToModel(StepCampaignContent.mapModelOrDefaultToForm(data))
    }
    case CampaignSteps.Recipients: {
      return StepRecipientsS.mapFormToModel(StepRecipientsS.mapModelOrDefaultToForm(data))
    }
    case CampaignSteps.ReviewSend: {
      return StepReview.mapFormToModel(StepReview.mapModelOrDefaultToForm(data))
    }
    default: {
      return {}
    }
  }
}

export const GET_DEFAULT_FORM_CHANGES = (): Record<CampaignSteps, boolean> => ({
  [CampaignSteps.CampaignInformation]: false,
  [CampaignSteps.Recipients]: false,
  [CampaignSteps.ReviewSend]: false,
  [CampaignSteps.Statistics]: false
})

export const INTENTIONALLY_WRONG_ID = - 42
