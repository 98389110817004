import { type FC, type ReactNode, useMemo } from 'react'
import { Skeleton } from '@material-ui/lab'
import {
  FormInput,
  FormNumberInput,
  FormDropdown,
  Modal,
  type DropdownAction,
  FormDatePicker
} from '@carfluent/common'

import { type BaseListItem } from 'types'
import { type AccountingFiscalYearsResponse, type TransactionLineVendorDto } from 'api/types'
import AccountingApiProvider from 'api/accounting.api'
import { COMMON_MODAL_FORM_CLS, MODAL_MIN_WIDTH } from 'constants/constants'
import { ValidationLength, MAX_PRICE_VALUE } from 'constants/validation'
import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import SectionItem from 'components/common/SectionItem'
import VendorDetails from 'pages/accounting/VendorDetails'
import iconPlus from 'assets/plus_dark.svg'

import Banners from './components/Banners'
import PurchaseAccountsSection from './components/PurchaseAccountsSection'
import useCostsForm from './hook'
import { type UseCostFormProps } from './hook/types'
import createStyleClass from './styles'

export type CostsFormProps = UseCostFormProps & {
  // AZ-TODO: move to hook all these props
  lockedInfo?: AccountingFiscalYearsResponse | null
  onOpenOtherCostJournalEntry?: (id: number | null) => void
}

const CostsDetails: FC<CostsFormProps> = ({
  isModalOpen = false,
  onCloseModal,
  onOpenOtherCostJournalEntry,
  ...hookProps
}) => {
  const styles = useMemo(createStyleClass, [])

  const {
    closeYearError,
    costId,
    creditValue,
    dateTimeMin,
    debitValue,
    formProps,
    costTypes,
    isDisabled,
    isLoading = false,
    isLocked,
    isSystemCreated,
    isSubmitting,
    lockedInfo,
    onAddVendor,
    onCostTypeChange,
    onDelete,
    onSubmit,
    paymentAccountOptions,
    radioButtonsValueKey,
    reconcileStatusId,
    transactionId,
    vendorEntityActions,
    vendorFormModalProps
  } = useCostsForm({
    isModalOpen,
    onCloseModal,
    ...hookProps
  })

  const {
    errors,
    onBlur,
    onChange,
    touched,
    values
  } = formProps

  const renderFooter = (): JSX.Element => {
    const restProps = costId != null
      ? {
          submitTitle: 'UPDATE',
          onDelete,
          deleteBtnTitle: 'DELETE COST',
          isDeleteDisabled: isDisabled
        }
      : { submitTitle: 'ADD' }

    return (
      <CancelSubmitActionsFooter
        {...restProps}
        isDisabled={isDisabled}
        isLoading={isSubmitting}
        onClose={onCloseModal}
        onSubmit={onSubmit}
      />
    )
  }

  // ========================================== //

  return (
    <Modal
      className={styles.modalClassName}
      data-test-id='popup-cost'
      isLoading={isLoading}
      isOpen={isModalOpen}
      minWidth={MODAL_MIN_WIDTH}
      onClose={onCloseModal}
      renderFooterActions={renderFooter}
      title='Cost'
    >
      <Banners
        closeYearError={closeYearError}
        costId={costId}
        isLocked={isLocked}
        isSystemCreated={isSystemCreated}
        lockedInfo={lockedInfo}
        lockedDate={values.date}
        onOpenOtherCostJournalEntry={onOpenOtherCostJournalEntry}
        reconcileStatusId={reconcileStatusId}
        transactionId={transactionId}
      />

      <div className={COMMON_MODAL_FORM_CLS}>
        <FormDropdown<BaseListItem | null>
          dataTestId='field-type'
          disabled={isDisabled}
          error={errors.costType}
          options={costTypes}
          id='costType'
          label='Type'
          onBlur={onBlur}
          onChange={onCostTypeChange}
          value={values.costType}
          touched={touched.costType}
        />

        <FormInput
          dataTestId='field-reference'
          disabled={isDisabled}
          error={errors.referenceNumber}
          id='referenceNumber'
          label='Reference #'
          maxLength={ValidationLength.COST_REFERENCE_NUM_MAX}
          onChange={onChange}
          onBlur={onBlur}
          touched={touched.referenceNumber}
          value={values.referenceNumber}
        />

        <FormDropdown<TransactionLineVendorDto>
          actions={vendorEntityActions}
          blurMode='clear'
          dataTestId='field-vendor'
          disabled={isDisabled}
          error={errors.vendor}
          fetchHandler={AccountingApiProvider.getVendors}
          id='vendor'
          label='Vendor'
          mode='search'
          onChange={onChange}
          onBlur={onBlur}
          renderOption={renderVendorOption}
          popoverClassName={styles.vendorOptions}
          renderAction={renderVendorAction}
          touched={touched.vendor}
          value={values.vendor}
        />

        <FormDatePicker
          dataTestId='field-date'
          disabled={isDisabled}
          id='date'
          label='Date'
          minDate={dateTimeMin}
          onBlur={onBlur}
          onChange={onChange}
          value={formProps.values.date}
          touched={formProps.touched.date}
          error={formProps.errors.date ?? ''}
        />

        <FormNumberInput
          dataTestId='field-amount'
          disabled={isDisabled}
          error={errors.amount}
          id='amount'
          isNegativeAllowed={false} // AZ-TODO: check, does not work?
          isSelectedOnFocus
          label='Amount'
          mode='decimal'
          min={0}
          max={MAX_PRICE_VALUE}
          onChange={onChange}
          onBlur={onBlur}
          startAdornment='$'
          touched={touched.amount}
          value={values.amount}
        />

        <FormInput
          className='g-full-width'
          dataTestId='field-description'
          disabled={isDisabled}
          error={errors.description}
          id='description'
          label='Description'
          maxLength={ValidationLength.DESCRIPTION_MAX}
          onBlur={onBlur}
          onChange={onChange}
          touched={touched.description}
          value={values.description}
        />

        {isDisabled && (
          <>
            <SectionItem
              className={styles.sectionItem}
              label='Account to debit'
              value={isLoading ? <Skeleton variant='text' /> : debitValue}
            />

            <SectionItem
              label='Account to credit'
              className={styles.sectionItem}
              value={isLoading ? <Skeleton variant='text' /> : creditValue}
            />
          </>
        )}

        {!isDisabled && (
          // AZ-TODO: rename to PaymentAccountsSection, it's not only about purchase.
          <PurchaseAccountsSection
            id={radioButtonsValueKey}
            onChangePaymentAccount={onChange}
            purchaseAccountOptions={paymentAccountOptions}
            purchaseAccountIsLoading={isLoading}
            value={values[radioButtonsValueKey]}
          />
        )}
      </div>

      <VendorDetails
        isOpen={vendorFormModalProps.isModalOpen}
        onClose={vendorFormModalProps.onCloseModal}
        onSubmit={onAddVendor}
      />
    </Modal>
  )
}

export default CostsDetails

// ========================================== //

const renderVendorAction = (item: DropdownAction): ReactNode => (
  <div className='cf-button-option'>
    <img className='cf-plus-icon' src={iconPlus} alt='plus' />
    <p className='cf-option'>{item.name}</p>
  </div>
)

const renderVendorOption = (opt: TransactionLineVendorDto): ReactNode => <p className='cf-option'>{opt.name}</p>
