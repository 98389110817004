import {
  px,
  ContentLayoutWidth as CLW,
  ScreenWidthBreakpoints,
  PAGE_TITLE_HEIGHT,
  NAV_DRAWER_WIDTH,
  PAGE_TITLE_HEIGHT_FIX_OVERFLOW_Y,
  SIDEBAR_WIDTH,
  BOTTOM_ACTIONS_HEIGHT,
  COMMON_MODAL_FORM_CLS
} from 'constants/constants'

export enum FontWeights {
  Light = 300,
  Regular = 400,
  Medium = 500,
  Bold = 700
}

const COMMON_TEXT_STYLES = ({ weight = FontWeights.Regular }): string => `
  color: #101010;
  font-family: Roboto;
  font-weight: ${weight};
  line-height: 24px;
`

export const GLOBAL_STYLES = `
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-weight: normal;
    vertical-align: baseline;
  }

  :root {
    --content-margin-small: 16px;
    --content-margin-default: 24px;
    --content-layout-margin: 16px;
    --content-layout-top-margin: 16px;
    --content-layout-side-margin: 24px;
    --content-margin-big: 32px;
    --content-margin-top-big: 24px;
    --main-menu-zindex: 1201;
    --popover-zindex: 1301;
    --header-zindex: 1200;
    --color-modal-subtitle: rgba(33, 33, 33, 0.50);
    --scrollbar-width: 6px;
    --scrollbar-height: 6px;
    --save-cancel-panel-height: 80px;
    --color-error: #B00020;
    --header-height: 64px;
    --nav-drawer-collapsed-width: 64px;
  }

  * {
    ::-webkit-scrollbar {
      width: var(--scrollbar-width);
      height: var(--scrollbar-height); // for horizontal scrollbar 
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: #f3f3f3;
      border-radius: 3px;
    }
  }

  .cf-modal-container .cf-modal-content-scroll-wrapper::-webkit-scrollbar {
    display: none;
  }

  :focus {
    outline: none!important;
  }

  // overflows for body and root to make nav drawer on top of horizontal scrollbar
  // but to preserve content horizontal scrolling
  body {
    overflow-x: hidden;
  }

  #root {
    overflow-x: auto;
  }

  h5 {
    ${COMMON_TEXT_STYLES({ weight: FontWeights.Medium })}
    font-size: 24px;
    letter-spacing: 0.18px;
  }

  h6 {
    ${COMMON_TEXT_STYLES({ weight: FontWeights.Medium })}
    font-size: 20px;
    letter-spacing: 0.15px;
  }
  
  .g-subtitle-1 {
    ${COMMON_TEXT_STYLES({ weight: FontWeights.Regular })}
    font-size: 16px;
    letter-height: 24px;
    letter-spacing: 0.15px;
  }
  
  .g-body2-medium {
    ${COMMON_TEXT_STYLES({ weight: FontWeights.Medium })}
    font-size: 14px;
    letter-height: 020px;
    letter-spacing: 0.4px;
  }
  
  .cf-details-view-overlay {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    padding: 16px 24px;
    height: 100%;
    width: 100%;
    max-height: calc(100vh - ${px(PAGE_TITLE_HEIGHT)});
    overflow: auto;
    scrollbar-gutter: stable both-edges;
    max-width: calc(100vw - ${px(NAV_DRAWER_WIDTH)});
    box-sizing: border-box;

    background: #f3f3f3;

    &.with-submenu {
      min-width: calc(${px(CLW.MinSettingsContent)} + ${px(CLW.SettingsSideMenu)});
      max-width: calc(100vw - ${px(CLW.SettingsSideMenu)} - ${px(SIDEBAR_WIDTH)});
      overflow: unset;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0;
      left: ${px(CLW.SettingsSideMenu)};

      &.with-submenu:not(:has(.grey-background-page)) {
        background: #fff;
      }
    }
    &.with-white-background {
      background: #fff;
    }
  }

  #root.with-pages-overlay {
    .g-content-layout {
      margin: 0px auto;
      margin-top: var(--header-height);
      overflow: hidden;
    }

    .cf-content-wrapper {
      overflow-x: hidden;
    }
  }

  #root.with-submenu-pages-overlay {
    .cf-content-wrapper {
      overflow-x: hidden;
      min-width: calc(${px(CLW.MinSettingsContent)} + ${px(CLW.SettingsSideMenu)} + ${px(CLW.MinSideMargins)});
    }

    .g-content-layout {
      margin: 0px auto;
      overflow: hidden;
      min-height: calc(100vh - ${px(PAGE_TITLE_HEIGHT_FIX_OVERFLOW_Y)});

      .g-submenu-container {
        top: 0;
        height: calc(100vh - ${px(BOTTOM_ACTIONS_HEIGHT)} - ${px(PAGE_TITLE_HEIGHT_FIX_OVERFLOW_Y)});
      }
      
      .g-submenu-container.with-no-actions {
        top: 0;
        height: calc(100vh - ${px(PAGE_TITLE_HEIGHT_FIX_OVERFLOW_Y)});
      }
    }

    .g-submenu-content {
      max-width: calc(100vw - ${px(CLW.SettingsSideMenu)} - ${px(CLW.MinSideMargins)} - ${px(SIDEBAR_WIDTH)});
      margin-top: var(--header-height);
    }
    
    .g-margin-top-big {
      margin-top: calc(var(--header-height) + var(--content-margin-top-big));
    }
  }

  #scroll-anchor {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 1px;
  }

  .g-main-header {
    height: var(--header-height);
    z-index: var(--header-zindex);
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 0px;
    margin: 0px;
    display: grid;
    
    // this hack is needed to prevent header's title to "jump" when user auth menu is toggled
    grid-template-columns: 64px clamp(733px, calc(100vw - 492px), calc(100vw - 492px)) 340px 88px; 
    
    align-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border-bottom: 1px solid var(--Outline, rgba(0, 0, 0, 0.12));
  }
  
  .card-content {
    padding-bottom: 80px;
    
    &.vehicle-details { min-width: min-content; }
  }

  .g-content-layout {
    min-height: calc(100vh - ${px(PAGE_TITLE_HEIGHT)});
    min-width: ${px(CLW.MinContent)};

    margin: var(--content-layout-top-margin) var(--content-layout-side-margin);
    margin-top: calc(var(--header-height) + var(--content-layout-top-margin));
    position: relative;

    display: flex;
    overflow-x: hidden;
    flex-direction: column;

    @media screen and (min-width: ${px(ScreenWidthBreakpoints.Large)}) {
      width: ${px(CLW.WideMaxContent)};
      margin: var(--content-layout-top-margin) auto;
      margin-top: calc(var(--header-height) + var(--content-layout-top-margin));

      &.g-deal-routes-overlay {
        width: auto;
        max-width: 1648px; /* 24px for inner side paddings */
      }
    }

    .g-top-panel-actions {
      margin-bottom: var(--content-margin-small);

      &-big-height {
        height: 40px;
      }

      &-small-height {
        height: 16px;
      }
    }

    &.new-vehicle-page {
      padding-top: var(--content-margin-big);
    }

    &.credit-application-page {
      padding-left: 64px;
      padding-right: 64px;
    }

    &.documents-page {
      min-width: 800px;
      max-width: 800px;
      margin: var(--content-margin-big) auto;
    }

    &.general-settings:not(:has(.grey-background-page)){
      background-color: #FFFFFF;
    }

    &.g-overrides {
      padding: 0;
      margin: 0;
      width: 100%;
    }
  
    .g-settings-body-wrapper {
      display: flex;
      min-height: calc(100vh - ${px(PAGE_TITLE_HEIGHT_FIX_OVERFLOW_Y)});
      overflow-x: auto;
    }
    
    .g-submenu-container {
      margin-top: var(--header-height);
    }
  }

  .g-submenu-content {
    min-width: ${px(CLW.MinSettingsContent)};
    margin: var(--content-layout-margin);
    margin-top: calc(var(--header-height) + var(--content-layout-margin));
    
    .g-back-top-with-bottom-actions-panel {
      bottom: 110px;
    }

    @media screen and (min-width: ${px(ScreenWidthBreakpoints.Small)}) {
      width: ${px(CLW.MaxSettingsContent)};
    }

    @media screen and (min-width: ${px(ScreenWidthBreakpoints.SettingsDefault)}) {
      margin: var(--content-layout-margin) auto;
      margin-top: calc(var(--header-height) + var(--content-layout-margin));
    }

    &.work-schedule-page,
    &.general-settings-page {
      @media screen and (min-width: 1568px) {
        margin-left: auto;
        margin-right: auto;
      }
    }  
  }

  .g-margin-top-big {
    margin-top: calc(var(--header-height) + var(--content-margin-top-big));
  }

  .g-margin-top-big .g-single-form-content,
  .g-content-layout .g-single-form-content {
    width: 900px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: calc(var(--save-cancel-panel-height) + 40px);

    &.disabled { padding-bottom: 40px;}
  }

  .cf-error-content {
    background: #B00020;
  }

  .cf-footer-actions-section {
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
    padding: 12px 64px;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;
  }

  .cf-section-loader {
    position: absolute;
    inset: 0px;
    z-index: 1;
    background: rgba(255, 255, 255, 0.75);
  }

  .g-deal-update-section-layout {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 0.6fr 0.6fr 0.6fr;
    grid-gap: 24px 16px;

    @media screen and (max-width: ${px(ScreenWidthBreakpoints.Large)}) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media screen and (max-width: ${px(ScreenWidthBreakpoints.Medium)}) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: ${px(ScreenWidthBreakpoints.Small)}) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .h6-title { 
    font-size: 20px;
    letter-spacing: 0.15px;
    ${COMMON_TEXT_STYLES({ weight: FontWeights.Medium })}
  }
  
  .g-h7-title { 
    font-size: 18px;
    letter-spacing: 0.4px;
    line-height: 20px;
    ${COMMON_TEXT_STYLES({ weight: FontWeights.Medium })}
  }

  /**
   * POPPERS STYLES FOR AUTOCOMPLETE COMPONENT
   */

  .cf-dropdown-popper {
    &.MuiAutocomplete-popper {
      z-index: 1199;
    }

    &.small-width {
      .MuiAutocomplete-listbox { 
        overflow-x: hidden;
        
        .MuiAutocomplete-option p {
          max-width: 140px;
        }
      }
    }
  }

  .MuiTable-root [class*=cf-table-cell-text] {
    color: #101010;
  }

  /**
   * will-change: transform; is a default MUI style. 
   * its produce issue with text on tooltip 
   * kinda blur when page is zoom out
   */
  .MuiTooltip-popper {
    will-change: unset!important;
  }

  .MuiAlert-root {
    &.MuiAlert-standardSuccess {
      background: #000;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
      border-radius: 2px;
    }
  }

  .g-panel {
    background-color: #fff;
    padding: 24px 32px;
    box-shadow: 0px 24px 38px rgba(58, 71, 78, 0.1);
    border-radius: 2px 12px 12px 12px;
    
    &.g-panel--complete {
      border-radius: 12px;
    }
    
    &.g-panel--no-shadow {
      box-shadow: none;
    }
  }

  [class*=cf-banner-content] {
    a {
      text-decoration: underline;
      font-weight: 500;
    }

    .cf-banner-end-adornment {
      width: fit-content;
    }

    button {
      background: none;
      border: none;
      padding: 0;
      text-decoration-line: underline;
      text-transform: none;
      font-size: 16px;
      min-width: max-content;

      :hover {
        background: none;
      }
    }
  }

  .g-bottom-actions-panel {
    padding: 12px 40px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    background: #FFFFFF;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
    z-index: calc(var(--main-menu-zindex) - 1);
    width: 100%;
    transition: bottom 0.3s ease;

    display: flex;
    justify-content: flex-end;

    button:first-child {
      margin-right: 24px;
    }

    button:last-child {
      margin-right: 0px; // for a single button case
    }

    .cf-button-content {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.25px;
    }
    
    .g-button-container {
      display: flex;
      width: 100%;
      align-items: center;
      
      &.g-button-container--left {
        justify-content: flex-start;
      }
      
      &.g-button-container--right {
        justify-content: flex-end;
      }
      
      .cf-button-root {
        margin-right: 24px;
      }
      
      div:last-of-type {
        margin-right: 0px;
        
        .cf-button-root {
          margin-right: 0px;
        }
      }
    }
    
    .validation-message {
      margin-right: 32px;
    }
  }
      
  .error-text {
    color: var(--color-error);
  }
  
  .g-button-new-item {
    &.cf-button-root {
      padding: 20px 24px;
    }
    
    .cf-button-start-adornment,
    .cf-button-end-adornment {
      display: none;
    }
    
    .cf-button-content {
      color: #FFF;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
    }
  }

  .cf-opened .cf-popover-content {
    z-index: var(--popover-zindex);
  }

  .cf-nav-menu .nav-item .hover-nav-menu {
    z-index: calc(var(--main-menu-zindex) + 1);
  }

  .${COMMON_MODAL_FORM_CLS} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;
    grid-row-gap: 24px;

    .g-full-width, .cf-address-picker {
      grid-column: 1 / 3;
    }

    .address-fields, .address-fields-component {
      grid-column: 1 / 3;

      &, > div {
        padding: 0px;
      }

      .same-address {
        margin-bottom: 24px;
      }
    }

    .address-fields {
      h6 {
        color: #101010;
        font-size: 24px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.18px;
        margin: 8px 0px 24px;
      }
    }
  }

  .cf-carfax-widget {
    max-width: 350px;
    max-height: 560px;
    position: relative;

    .cf-carfax-snapshot-wrapper {
      width: 100%;
      height: auto;
      max-height: 541px;
      display: none;
    }

    &.cf-snapshot-visible .cf-carfax-snapshot-wrapper {
      display: block;
    }

    .cf-carfax-snapshot-header {
      display: flex;
      align-items: center;
      background: #1976D2;
      height: 56px;
    }

    .cf-carfax-snapshot-logo {
      width: 125px;
      height: 24px;
      padding-left: 8px;
    }

    .cf-carfax-logout-link {
      margin-top: 16px;
      text-align: center;
      opacity: 0;
      height: 0px;

      &.cf-snapshot-logout-visible {
        opacity: 1;
        transition: opacity 0.3s ease-in-out 1s;
        height: 16px;
      }

      a {
        color: rgba(33, 33, 33, 0.50);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15px;
        text-decoration: underline;
      }
    }

    .cf-carfax-login, .cf-carfax-error {
      border: 1px solid #666666;
      height: min-content;
      max-width: 350px;

      .cf-carfax-content {
        padding: 8px 8px 24px;
        display: grid;
      }

      p {
        color: rgb(45, 45, 45);
        letter-spacing 0.12px;
        margin-top: 16px;
        line-height: 24px;
      }

      .cf-carfax-action-link {
        height: 36px;
        margin: 12px 0px;

        a {
          color: #1D86B8;
          border: none;
          font-size: 16px;
          font-wight: 500;
          align-items: center;
        }
      }
    }
  }

  .g-page-title {
    margin-bottom: 24px;
  }

  .g-section-title {
    margin: var(--content-margin-default) 0;
  }

  .g-horizontal-line {
    height: 1px;
    min-height: 1px;
    width: 100%;
    background: rgba(0, 0, 0, 0.12);
    margin: var(--content-margin-default) 0;
  }

  .g-modal-subtitle {
    color: var(--color-modal-subtitle);
  }

  .g-subtitle1 {
    color: rgba(33, 33, 33, 0.50);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .g-text-black {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
  }

  .g-low-zindex-dropdown {
    z-index: calc(var(--main-menu-zindex) - 1) !important;
  }

  .MuiPickersDatePickerRoot-toolbar .MuiButton-root {
    text-decoration: none;
  }

  .g-small-text-skeleton {
    background-color: #fafafa!important;
    max-width: 800px;
  }

  .g-button-concise.cf-button-root {
    box-sizing: border-box;
    height: 26px;
    width: 100px;
    min-width: 100px;
    padding: 5px 18px;
    border-radius: 8px;

    .cf-button-content {
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1.25px;
    }
  }
  
  .g-button-edit.cf-button-root.cf-button-content-sized {
    background-color: transparent;
    padding: 0;
    min-width: 70px;
    min-height: 26px;
    height: auto;
    border: 1px solid #C99B86;
    border-radius: 8px;
    text-transform: uppercase;
    
    :hover {
      border-color: #dd0000;
    }

    .cf-button-content {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.25px;
    }
  }
  
  
  .g-button-delete.cf-button-color-primary.cf-button-root.cf-button-variant-outlined {
    margin-right: 24px;

    &, &:hover {
      border-radius: 12px;
      border: 1px solid #B00020;
      border-color: #B00020;


      .cf-button-content {
        color: #B00020;
      }
    }
  }

  .g-concise-error-banner {
    &::after { display: none; }

    .cf-banner-content {
      padding: 8px;

      .cf-banner-message {
        margin: 0px;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;

        &::before, &::after { display: none; }
      }

      .cf-banner-start-adornment {
        display: flex;
        align-items: center;
        padding-right: 8px;
      }
    }
  }

  .g-checkbox {
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;

    .checkmark {
      width: 18px;
      min-width: 18px;
      height: 18px;
      border-radius: 2px;
      background: #fff;
      border: 2px solid #0000001F;
      margin-right: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition-property: background, border-color;
  
      svg {
        opacity: 0;
        transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }

    .checkmark-label {        
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    &.is-checked .checkmark {
      background: #000;
      border-color: #000;

      svg {
        opacity: 1;
      }
    }

    &.is-disabled .checkmark {
      background: #DBDBDB;
      border-color: #DBDBDB;
      cursor: default;
      pointer-events: none;

      svg {
        opacity: 0.3;
      }
    }
  }
  
  .g-link-button {
    &.cf-button-root.cf-button-variant-text,
    &.cf-button-root.cf-button-variant-text:hover {
      color: #458FFF;
    }
  }
  
  .g-link {
    color: #458FFF;
    color: red;
    text-decoration: none;

    :hover { text-decoration: none; }
  }

  .g-filters-panel-actions .MuiButton-label,
  .cf-button-variant-contained .cf-button-content,
  .tab-footer .cf-button-root span,
  .cf-footer-actions-section .cf-button-root span,
  .cf-modal-footer .cf-button-root span,
  .cf-modal-footer .cf-button-root span {
    font-size: 14px;
    font-weight: 500;
  }
`
