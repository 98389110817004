import { type ChangeEvent, useCallback, useEffect, useState } from 'react'

import FilesApiProvider from 'api/files.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { getParsedToken } from 'services/storage.service'

import { FILE_EXTENSIONS, MAX_FILE_SIZE } from './constants'

export interface UseImageUploadProps {
  previewUrl: string | null
  isUploadFilesTouched: boolean
  onSaveHeroImage: () => Promise<void>
  setIsUploadFilesTouched: (isTouched: boolean) => void
  onDiscardChanges: (shouldResetUploadFile: boolean) => void
  onFileChange: (evt: ChangeEvent<HTMLInputElement>) => void
}

const useImageUpload = (): UseImageUploadProps => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isUploadFilesTouched, setIsUploadFilesTouched] = useState(false)
  const { showAlert } = useCustomSnackbar()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onInitUpload = useCallback((shouldResetUploadFile = true) => {
    const token = getParsedToken()

    if (token?.headquarters_id != null) {
      setSelectedFile(null)
      setIsUploadFilesTouched(false)

      if (shouldResetUploadFile === true) {
        setPreviewUrl(`${process.env.REACT_APP_FILES ?? ''}/api/v1/download/headquarters/${token.headquarters_id as string}/home-image?${Date.now()}`)
      }
    }
  }, [])

  const onFileChange = useCallback((evt: ChangeEvent<HTMLInputElement>): void => {
    const files = evt.target.files

    if ((files == null) || files.length === 0) {
      return
    }

    if (FILE_EXTENSIONS.find(extension => {
      return files[0]?.name.endsWith(extension)
    }) === undefined) {
      showAlert('Should be png, jpg or jpeg format')
      return
    }

    const file = files[0]

    if (file.size > MAX_FILE_SIZE) {
      showAlert('File size exceeds the maximum limit of 5MB')
      return
    }

    setIsUploadFilesTouched(true)
    setPreviewUrl(URL.createObjectURL(file))
    setSelectedFile(file)

    /**
     * OP-NOTE:
     * Reset the input value to allow re-uploading the same file
     * This forces the input element to register changes even if the same file is selected again
     * For more details, see this Stack Overflow discussion:
     * https://stackoverflow.com/questions/39484895/reactjs-re-upload-the-same-file-using-input-type-file
     */

    evt.target.value = ''
  }, [showAlert])

  const onSaveHeroImage = useCallback(async (): Promise<void> => {
    if (selectedFile == null) {
      return
    }

    try {
      await FilesApiProvider.uploadWebSiteHeroImage(selectedFile)
      setIsUploadFilesTouched(false)
    } catch (error) {
      showAlert('An error occurred while saving the hero-image')
    }
  }, [showAlert, selectedFile])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    onInitUpload()
  }, [onInitUpload])

  // ========================================== //

  return {
    previewUrl,
    onFileChange,
    onSaveHeroImage,
    isUploadFilesTouched,
    setIsUploadFilesTouched,
    onDiscardChanges: onInitUpload
  }
}

export default useImageUpload
