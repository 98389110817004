import React, { FC, useMemo } from 'react'

import Table from 'components/common/Table'

import { type CostSummaryListItem } from 'hooks/useCostsSummary/types'
import { columnCostsSummaryDefinitions } from '../../columns'
import { createStyleClass } from './styles'

export interface SummaryProps {
  items: CostSummaryListItem[]
  onAddJournal: () => void
}

const Summary: FC<SummaryProps> = ({ items, onAddJournal }) => {
  const tableCls = useMemo(createStyleClass, [])

  return (
    <>
      <div className='cf-cost-summary-table' data-test-id='list-cost'>
        <Table
          classes={{ table: tableCls }}
          columns={columnCostsSummaryDefinitions}
          onAddRow={onAddJournal}
          embedAddButton
          emptyTableMessage='There are no entries yet'
          addRowLabel='Add journal entry'
          data={items}
          addBtnTestId='btn-add-journal-entry'
        />
      </div>
    </>
  )
}

export default Summary
