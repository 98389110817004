import { css } from '@emotion/css'

export default css(`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: 92px;
  padding: 0 8px;
  height: 24px;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;

  &.deal-state-fully-paid {
    border: 1px solid rgba(23, 58, 112, 0.80);
    color: rgba(23, 58, 112, 0.80);
  }

  &.deal-state-not-paid {
    border: 1px solid #D52A49;
    color: #D52A49;
  }
`)
