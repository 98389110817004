import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  dragAndDropBlock: {
    padding: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    borderRadius: 8,
    cursor: 'pointer',
    marginBottom: 8,
    height: 190,
    border: '1px solid rgba(0, 0, 0, 0.12)'
  },
  errorBlock: {
    border: '1px solid #B00020',
    '& .errorMessage': {
      color: '#B00020'
    }
  },
  subtitle: {
    color: 'rgba(33, 33, 33, 0.5)',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 40
  },
  downloadBtnLabel: {
    fontSize: 16,
    fontWeight: 700
  },
  downloadBtn: {
    '&:hover': {
      background: 'transparent'
    }
  }
})
