import { css } from '@emotion/css'

import type { ConfigProps } from './hook/types'

const getStylesValue = (value?: string | number): string =>
  typeof value === 'string' && isNaN(Number(value)) ? value : `${value ?? 0}px`

export const createPopoverStyles = ({
  position = 'absolute',
  top: _top = 'auto',
  left: _left = 'auto',
  bottom: _bottom = 'auto',
  right: _right = 'auto',
  translateX: _translateX = 0,
  translateY: _translateY = 0,
  maxHeight: _maxHeight = 'auto',
  minWidth = 'auto',
  maxWidth = 'auto'
}: ConfigProps): string => {
  const top = getStylesValue(_top)
  const bottom = getStylesValue(_bottom)
  const left = getStylesValue(_left)
  const right = getStylesValue(_right)
  const translateX = getStylesValue(_translateX)
  const translateY = getStylesValue(_translateY)
  const maxHeight = getStylesValue(_maxHeight)

  return css(`
    position: ${position};
    top: ${top};
    bottom: ${bottom};
    left: ${left};
    right: ${right};
    overflow: hidden;
    overflow-y: auto;
    background: #FFFFFF;
    box-shadow: 0px 24px 38px rgba(58, 71, 78, 0.1);
    border-radius: 12px;
    z-index: 1300;
    max-height: ${maxHeight};
    transform: translate(${translateX}, ${translateY});
    ${minWidth != null ? `min-width: ${minWidth}px;` : ''}
    ${maxWidth != null ? `max-width: ${maxWidth}px;` : ''}
  `)
}

export const ANIMATED_POPOVER_STYLE = css(`
  opacity: 0;
  transition: opacity .5s;
  
  &.cf-animated-open {
    opacity: 1;
  }
`)
