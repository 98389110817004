import { type FC, type ChangeEvent, useEffect, useRef, useState } from 'react'
import { cnx } from '@carfluent/common'
import { observer } from 'mobx-react-lite'

import { NOTES_CLASSES, NOTES_TITLE_CLASSES, NOTES_TEXTAREA_CLASSES, NOTES_TEXTAREA_EMPTY_CLASSES } from './styles'

export interface NotesProps {
  className?: string
  title?: string
  notes: string | null
  placeholderName: string
  handleUpdateNotes: (notes: string | null) => Promise<void>
}

export const Notes: FC<NotesProps> = ({
  notes,
  placeholderName,
  handleUpdateNotes,
  title = 'Notes',
  className
}) => {
  const [isEditMode, setEditMode] = useState(false)
  const [value, setValue] = useState(notes)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    setValue(notes)
  }, [notes])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setValue(e.currentTarget.value)
  }

  const handleDoubleClick = (): void => {
    setEditMode(true)
    textareaRef?.current?.focus()
  }

  const handleBlur = (): void => {
    setEditMode(false)
    const trimmedValue = value?.trim()
    const valueToUpdate = (trimmedValue === '' || trimmedValue == null) ? null : trimmedValue
    setValue(valueToUpdate)
    if (notes !== valueToUpdate) {
      void handleUpdateNotes(valueToUpdate)
    }
  }

  return (
    <div
      className={cnx(NOTES_CLASSES, 'notes', className)}
      onDoubleClick={handleDoubleClick}
    >
      <p className={NOTES_TITLE_CLASSES}>{title}</p>
      {
        isEditMode
          ? (
            <textarea
              autoFocus
              className={NOTES_TEXTAREA_CLASSES}
              onBlur={handleBlur}
              onChange={handleChange}
              ref={textareaRef}
              value={value ?? ''}
            />)
          : (
            <div className={cnx(NOTES_TEXTAREA_CLASSES, value == null ? NOTES_TEXTAREA_EMPTY_CLASSES : '')}>
              {value ?? <span className='cf-textarea-placeholder'>{`Make ${placeholderName} notes here...`}</span>}
            </div>)
      }
    </div>
  )
}

export default observer(Notes)
