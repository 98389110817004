import { type UserDto } from 'api/types/common'
import { type CampaignStatisticsDto } from './CampaignStatisticsDto'

export enum CampaignStatusId {
  Draft = 1,
  Pending = 2,
  Started = 3,
  Sent = 4,
  Failed = 5
}

export interface CampaignDto {
  bodyHtml: string | null
  bodyRaw: string | null
  campaignStatusId: number
  campaignStatistic: CampaignStatisticsDto | null
  campaignTypeId: number
  createdByUser: UserDto
  emailSubject: string | null
  id: number
  isLostStatusSelected: boolean
  leadAppointmentsFilterId: number | null
  leadSourceFilterId: number | null
  leadStatusFilterId: number | null
  leadTemperatureFilterId: number | null
  leadSources: number[]
  leadStatuses: number[]
  leadTemperatures: number[]
  name: string | null
  preheader: string | null
  recipientsCount: number | null
  rowVersion: string
  senderName: string | null
  senderEmail: string | null
  scheduledDateTime: string | null
}
