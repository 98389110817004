import { type ActiveLenderDecisionModel } from 'api/types/responses'

export const GET_DEFAULT_ACTIVE_LENDER_DECISION = (): ActiveLenderDecisionModel => ({
  id: null,
  approvedRate: null,
  approvedTerm: null,
  downPayment: null,
  lienholder: null,
  monthlyPayment: null
})
