import { type BaseListItem } from 'types'
import type { RecurringTransactionTemplatesDetails } from 'api/types'
import { toDateOrNull } from 'utils/parse_date'
import _isPayable from 'utils/accounting/isPayable'
import { parseTransaction as parseReceivableTransaction } from './receivableParser'
import { parseTransaction as parseJournalEntryTransaction } from './journalEntryParser'
import type { TransactionDetails } from '../types'

export const parseTransactionTemplate = (
  transaction: RecurringTransactionTemplatesDetails,
  intervals: BaseListItem[]
): TransactionDetails => {
  const {
    startDate,
    endDate,
    recurringIntervalId,
    templateTransaction
  } = transaction
  const isPayable = _isPayable(templateTransaction.transactionTypeId)

  const transactionParams = isPayable
    ? parseReceivableTransaction(templateTransaction)
    : parseJournalEntryTransaction(templateTransaction)

  return {
    ...transactionParams,
    startDate: toDateOrNull(startDate), // should be parsed in local TZ
    endDate: toDateOrNull(endDate), // should be parsed in local TZ
    recurringInterval: intervals.find((el) => el.id === recurringIntervalId) ?? null
  }
}
