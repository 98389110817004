import {
  type FISetupMarkupsModel,
  type SettingsPackageTypesDto,
  type DealerDefaultCoverageDto,
  type DealerProductCoverageMarkupsDto
} from 'api/types/responses'
import { type CoverageDefaultSection } from 'types/coverage'
import { type FIPackageFormProps } from './types'

export const serializeProductsName = (data: FIPackageFormProps): SettingsPackageTypesDto => {
  return {
    packages: [
      { packageTypeId: 1, name: data.firstPackageName },
      { packageTypeId: 2, name: data.secondPackageName },
      { packageTypeId: 3, name: data.thirdPackageName }
    ]
  }
}

export const serializeFIProductsMarkups = (markups: FISetupMarkupsModel): DealerProductCoverageMarkupsDto => {
  return {
    markups: Object.values(markups)
  }
}

const serializeFormData = (data: CoverageDefaultSection[]): DealerDefaultCoverageDto[] => {
  return data.map(el => {
    const coverageId = (
      (el.forms.PEN?.id != null && el.forms.PEN.id !== 0)
        ? { id: el.forms.PEN.id }
        : (el.forms.Manual?.id != null && el.forms.Manual.id !== 0)
            ? { id: el.forms.Manual.id }
            : {}
    )

    return ({
      ...coverageId,
      productTypeId: el.productTypeId,
      cost: el.forms[el.coverageType]?.cost ?? 0,
      termMiles: el.forms[el.coverageType]?.termMiles ?? 0,
      termMonths: el.forms[el.coverageType]?.termMonths ?? 0,
      totalPrice: el.forms[el.coverageType]?.totalPrice ?? 0,
      providerId: el.forms[el.coverageType]?.providerId ?? 0,
      coverageName: el.forms[el.coverageType]?.coverageName ?? '',
      deductibleAmount: el.forms[el.coverageType]?.deductibleAmount ?? 0,
      productCoverageId: el.forms[el.coverageType]?.productCoverageId ?? null
    })
  }).filter(el => el.providerId)
}

export default serializeFormData
