import { type FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { cnx, Banner, Modal, Loader, Button } from '@carfluent/common'

import type { ActionOptions } from 'components/common/ActionsMenu'
import TopPanelActions from 'components/common/TopPanelActions'
import Toggle from 'components/common/Toggle'
import DeleteModal from 'components/dialogs/DeleteModal'
import CarsOfInterest from 'components/crm/CarsOfInterest'
import LeadLostModal from 'components/crm/LeadLostModal'
import Messenger from 'components/common/Messenger'
import EmailThreadModal from 'components/common/EmailThreadModal'
import LeadDetailsFormModal from 'components/crm/LeadDetailsFormModal'
import NewDealModal from 'pages/deals/NewDeal'
import IconSVG from 'components/inlineImages'

import Timeline from './components/Timeline'
import ActionsListHOC from './components/ActionsList'
import LogActivityButtons from './components/LogActivityButtons'
import LeadDetailsTasksBlock from './components/LeadDetailsTasks'
import LeadDetailsLogActivityBlock from './components/LeadDetailsLogActivity'
import LeadDetailsPanel from './components/LeadDetailsPanel'
import LeadInfo from './components/LeadInfo'
import StatusItem from './components/statusItem'
import SendEmailModal from './components/SendEmailForm'
import MessengerPreview from './components/MessangerPreview'
import useLeadDetailsView from './hook'
import { getLostCustomerMessage, getPausedMessage } from './hook/utils'

import CLASS_NAME, { ACTION_MENU_CLASS_NAME, ACTIONS_MODAL_CLASS_NAME } from './styles'

const Lead: FC = () => {
  const {
    lead,
    statuses,
    temperatures,
    sources,
    isActionsMenuOpen,
    isCarsMenuOpen,
    isActionsModalOpen,
    linkToWebsite,
    isLoading,
    leadTasksProps,
    activityTasksProps,
    leadEditProps,
    leadDeleteProps,
    leadLostProps,
    timelineProps,
    sendEmailProps,
    messagesProps,
    dealProps,
    webChatInfo,
    pausedByUserName,
    onOpenChatMessage,
    setWebChatInfo,
    onActionsMenuClose,
    onActionsModalClose,
    onActionsMenuOpen,
    onAddCarClick,
    onCarSelected,
    onCopy,
    onDeleteCar,
    onGoBack,
    onMakePrimaryCar,
    onScheduleTaskButtonClick,
    onLeadLostToggleClick,
    onActivityTaskButtonClick,
    onStatusUpdate,
    onUpdatePauseOrResumeLead
  } = useLeadDetailsView()

  const { logTypeId, onCloseActivity } = activityTasksProps

  const { removeTask } = leadTasksProps
  const { onOpen: onSendEmailButtonClick } = sendEmailProps
  const { onCloseModal, onSubmit } = leadEditProps
  const { emailThreadProps } = timelineProps
  const { onOpenModal: onOpenMessagePopover } = messagesProps
  const {
    dealState, onOpenModal: onCreateDealClick,
    isModalOpen: isCreateDealOpenModal,
    onCloseModal: onCloseAddDeal
  } = dealProps

  const actionList: ActionOptions[] = useMemo(() => ([
    {
      title: 'Edit lead',
      handleOnClick: leadEditProps.onOpenModal
    },
    {
      title: 'Create deal',
      handleOnClick: onCreateDealClick
    },
    {
      title: 'Add car of interest',
      handleOnClick: onAddCarClick
    },
    {
      title: `${lead.isPaused ? 'Resume' : 'Pause'} auto emails & msgs`,
      handleOnClick: onUpdatePauseOrResumeLead
    },
    {
      title: 'Delete lead',
      optionType: 'highlighted',
      handleOnClick: leadDeleteProps.onOpenModal
    }
  ]), [
    leadDeleteProps.onOpenModal,
    leadEditProps.onOpenModal,
    onAddCarClick,
    lead.isPaused,
    onUpdatePauseOrResumeLead
  ])

  // ========================================== //

  const ActionsListComponent = useMemo(() => {
    return ActionsListHOC({
      isOpen: isActionsMenuOpen,
      isCarsListVisible: isCarsMenuOpen,
      onCarSelected
    })
  }, [
    isActionsMenuOpen, isCarsMenuOpen, onCarSelected,
    lead.carsOfInterest
  ])

  // ========================================== //

  return (
    <div className={cnx('card-content', CLASS_NAME)}>
      {isLoading && <Loader size='large' />}

      <TopPanelActions
        className='g-top-panel-actions g-top-panel-actions-big-height'
        actions={actionList}
        actionButtonName='Action'
        backButtonName='Back'
        isActionsMenuOpen={isActionsMenuOpen}
        ActionsListComponent={ActionsListComponent}
        onActionsMenuOpen={onActionsMenuOpen}
        onActionsMenuClose={onActionsMenuClose}
        onGoBack={onGoBack}
        showBackButton={window.history.length > 1}
        actionMenuPopoverClassName={ACTION_MENU_CLASS_NAME}
      >
        <div className='toggle'>
          <Toggle
            activeLabel='Active'
            inactiveLabel='Lost'
            toggled={!lead.isLost}
            onClick={onLeadLostToggleClick}
          />
        </div>
      </TopPanelActions>

      <Banner
        message={getLostCustomerMessage(lead?.lostDate ?? '')}
        isOpen={lead.isLost}
      />

      <Banner
        startAdornment={<IconSVG.MuteBell />}
        endAdornment={<Button onClick={onUpdatePauseOrResumeLead} className='resume-button'>Resume</Button>}
        message={getPausedMessage(lead?.pausedDateTime ?? '', pausedByUserName)}
        isOpen={lead?.isPaused && !lead.isLost}
        type='info'
        className='paused-banner'
      />

      <div className='lead-status-container row center'>
        {statuses.map((item) => (
          <StatusItem
            key={item.name}
            item={item}
            selectedStatusId={lead.leadStatusId}
            onClick={onStatusUpdate}
            isLoading={isLoading}
            isLost={lead.isLost}
          />
        ))}
      </div>

      <div className='wrapper info-about-lead'>
        <div className='container'>
          <LeadInfo
            dealState={dealState}
            isLoading={isLoading}
            lead={lead}
          />

          <LeadDetailsPanel
            lead={lead}
            onCopy={onCopy}
            sources={sources}
            temperatures={temperatures}
          />
        </div>

        <CarsOfInterest
          cars={lead.carsOfInterest}
          linkToWebsite={linkToWebsite}
          onDeleteCar={onDeleteCar}
          onMakePrimaryCar={onMakePrimaryCar}
        />
      </div>

      <div className='wrapper log-activity'>
        <div className='container'>
          <LogActivityButtons
            onMessageButtonClick={onOpenMessagePopover}
            onScheduleTaskButtonClick={onScheduleTaskButtonClick}
            onActivityTaskButtonClick={onActivityTaskButtonClick}
            onCloseActivity={onCloseActivity}
            onCloseSchedule={removeTask}
            onSendEmailButtonClick={onSendEmailButtonClick}
            leadEmail={lead.email}
            leadPhoneNumber={lead.phoneNumber}
          />

          <p className='open-tasks-title'> Open Tasks </p>

          <div className='open-tasks-container'>
            {(logTypeId != null) && (
              <LeadDetailsLogActivityBlock
                {...activityTasksProps}
                logTypeId={logTypeId}
              />
            )}

            {!leadTasksProps.isTaskListEmpty && (
              <LeadDetailsTasksBlock {...leadTasksProps} />
            )}

            {leadTasksProps.isTaskListEmpty && (logTypeId == null) && (
              <div className='no-tasks'>
                <p>No tasks yet</p>
              </div>
            )}
          </div>
        </div>

        <div className='container'>
          <Timeline
            {...timelineProps}
            onOpenMessages={() => { void onOpenChatMessage() }}
            setWebChatInfo={setWebChatInfo}
          />
        </div>
      </div>

      <LeadLostModal {...leadLostProps} />

      <LeadDetailsFormModal
        isOpen={leadEditProps.isModalOpen}
        lead={lead}
        onClose={onCloseModal}
        onUpdateAction={onSubmit}
      />

      <Modal
        className={ACTIONS_MODAL_CLASS_NAME}
        isOpen={isActionsModalOpen}
        onClose={onActionsModalClose}
        title='Actions'
      >
        <p>Add task or log event</p>

        <LogActivityButtons
          isContactActionsShown={false}
          onScheduleTaskButtonClick={onScheduleTaskButtonClick}
          onActivityTaskButtonClick={onActivityTaskButtonClick}
          onCloseActivity={onCloseActivity}
          onCloseSchedule={removeTask}
          onMessageButtonClick={onOpenMessagePopover}
          onSendEmailButtonClick={onSendEmailButtonClick}
          leadEmail={lead.email}
          leadPhoneNumber={lead.phoneNumber}
        />
      </Modal>

      <DeleteModal
        isOpen={leadDeleteProps.isModalOpen}
        // eslint-disable-next-line
        onClose={leadDeleteProps.onCloseModal}
        title='Delete this lead?'
        content='Please note, this step could not be undone'
        onConfirm={async () => await leadDeleteProps.onSubmit()}
      />

      <SendEmailModal {...sendEmailProps} />

      <EmailThreadModal {...emailThreadProps} />

      <NewDealModal
        isOpen={isCreateDealOpenModal}
        onClose={onCloseAddDeal}
        leadId={lead.id}
        isDealCreationFromLead
      />

      <MessengerPreview
        webChatInfo={webChatInfo}
        onClose={() => setWebChatInfo(null)}
      />

      <Messenger {...messagesProps} />
    </div>
  )
}

export default observer(Lead)
