import { type FC, useCallback, useMemo } from 'react'

import type { Coords, Shift } from 'types'
import { getCalendarDates } from 'pages/crm/WorkSchedule/hook/utils'

import Days from './components/Days'
import DaysOfTheWeek from './components/Days/WeekDays'

import CLASS_NAME from './styles'

interface CalendarProps {
  date: Date
  shifts: Shift[]
  onOpenShift: (shiftData: Shift | Date) => void
  isBDC: boolean
  editShift: Shift | null
  addShiftDate: Date | null
  changeChipPosition: (pos: Coords) => void
}

const Calendar: FC<CalendarProps> = ({
  date,
  shifts,
  onOpenShift: _onOpenShift,
  editShift,
  addShiftDate,
  isBDC,
  changeChipPosition
}) => {
  const year = date.getFullYear()
  const month = date.getMonth()

  const dates = useMemo(() => getCalendarDates(year, month), [year, month])
  const openEditShift = useCallback((shift: Shift) => {
    if (!isBDC) {
      _onOpenShift(shift)
    }
  }, [_onOpenShift, isBDC])

  return (
    <div className={CLASS_NAME}>
      <div className='cf-calendar-grid'>
        <DaysOfTheWeek />
        <Days
          editShift={editShift}
          isBDC={isBDC}
          onOpenAddShift={_onOpenShift}
          onOpenEditShift={openEditShift}
          dates={dates}
          shifts={shifts}
          addShiftDate={addShiftDate}
          changeChipPosition={changeChipPosition}
        />
      </div>
    </div>
  )
}

export default Calendar
