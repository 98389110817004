import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'

export interface SnackbarConfig {
  message: string
  type: 'info' | 'error'
  isOpen: boolean
}

class GlobalUIStore {
  private isRecordWasUpdatedVisibleState = false
  private isNotificationOpenState = false
  private loaderCounter: number = 0
  private snackbarConfigState: SnackbarConfig = {
    message: '',
    type: 'info',
    isOpen: false
  }

  showRecordWasUpdated = (): void => {
    this.isRecordWasUpdatedVisibleState = true
  }

  hideRecordWasUpdated = (): void => {
    this.isRecordWasUpdatedVisibleState = false
  }

  startSpinner = (): void => {
    this.loaderCounter++
  }

  stopSpinner = (): void => {
    this.loaderCounter = Math.max(this.loaderCounter - 1, 0)
  }

  stopSpinnerWithDelay = (delay: number): void => {
    setTimeout(() => {
      this.stopSpinner()
    }, delay)
  }

  closeNotification = (): void => {
    this.isNotificationOpenState = false
  }

  showNotification = (): void => {
    this.isNotificationOpenState = true
  }

  showSnackbar = (message: string, type: 'info' | 'error' = 'info'): void => {
    this.snackbarConfigState = { message, type, isOpen: true }
  }

  hideSnackbar = (): void => {
    this.snackbarConfigState.isOpen = false
  }

  get isLoading (): boolean {
    return this.loaderCounter > 0
  }

  get isRecordWasUpdatedVisible (): boolean {
    return this.isRecordWasUpdatedVisibleState
  }

  get isNotificationOpen (): boolean {
    return this.isNotificationOpenState
  }

  get snackbarConfig (): SnackbarConfig {
    return { ...this.snackbarConfigState }
  }

  constructor () {
    makeAutoObservable(this)
  }
}

// AZ-TODO: remove `export` and stop expose implementation details
export const GlobalUIInstance = new GlobalUIStore()

export const GlobalUICTX = createContext(GlobalUIInstance)
export default GlobalUICTX

// ========================================== //
//       PUBLIC API FOR NON-REACT SCOPE       //
// ========================================== //

export const startSpinner = (): void => {
  GlobalUIInstance.startSpinner()
}

export const stopSpinner = (): void => {
  GlobalUIInstance.stopSpinner()
}

export const stopSpinnerWithDelay = (delay: number): void => {
  GlobalUIInstance.stopSpinnerWithDelay(delay)
}
