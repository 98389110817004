import { useState, type FC } from 'react'
import { observer } from 'mobx-react-lite'

import IconSVG from 'components/inlineImages'
import BackToTop from 'components/common/BackToTop'
import ReportLink from 'components/reports/ReportLink'
import AccountingHOK from 'pages/accounting/AccountingHOC'
import OverlayedPageHOC from 'components/PageOverlay/OverlayedPageHOC'
import FiltersPanel from 'components/common/FiltersPanel'

import { ReportsIcons } from './constants'

import CLASS_NAME from './styles'
import { getFilteredReports } from './utils'

const icons = {
  [ReportsIcons.CRM]: <IconSVG.IconSVGCrmIcon />,
  [ReportsIcons.Deals]: <IconSVG.Suitcase />,
  [ReportsIcons.Inventory]: <IconSVG.Car color='#101010' />,
  [ReportsIcons.Accounting]: <IconSVG.IconSVGAccounting />
}

const Reports: FC = () => {
  const [search, setSearch] = useState('')

  const reports = getFilteredReports(search)
  const isAllFilteredOut = reports.every(report => report.filteredLinks.length === 0)

  return (
    <div className={CLASS_NAME}>
      <FiltersPanel
        searchProps={{
          onChange: setSearch,
          value: search,
          placeholder: 'Report name, description',
          withDeleteIcon: true,
          dataTestId: 'report-filter-search'
        }}
        componentBackground='gray'
      />

      {isAllFilteredOut && <div className='empty-message'>No records found</div>}
      <div className='reports-lists-container'>
        {!isAllFilteredOut && (reports.map(({ title, icon, filteredLinks }, idx) => {
          return (
            (filteredLinks.length > 0) &&
              <div key={idx} className='report-section sidebar'>
                <figure>
                  <figcaption>
                    {icons[icon]}
                    {title}
                  </figcaption>
                  <ul>
                    {filteredLinks.map((report, index) => (
                      <li key={index}>
                        <ReportLink {...report} />
                      </li>
                    ))}
                  </ul>
                </figure>
              </div>
          )
        }))}
      </div>
      <BackToTop />
    </div>
  )
}

export default OverlayedPageHOC(AccountingHOK(observer(Reports)))
