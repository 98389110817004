import { css } from '@emotion/css'

export const REPRICE_BLOCK_CLASS_NAME = css(`
  margin-top: 24px;
  margin-bottom: 16px;

  > div {
    width: max-content;
  }
`)

export const REPRICING_LAYOUT_CLASS_NAME = css(`
  margin-top: 19px;
`)

export const TOOLTIP_CLASS_NAME = css(`
 max-width: initial!important;
 display: none;

 &.is-active {
  display: block;
 }
`)
