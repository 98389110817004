import { type FC, useCallback } from 'react'
import capitalize from 'lodash-es/capitalize'
import { TimeSelect } from '@carfluent/common'

import { DaysOfWeek } from 'api/types'
import { addStep } from 'utils/timeIntervals'
import Checkbox from 'components/common/Checkbox'
import DropdownIcon from 'components/common/DropdownIcon'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'

export const DEFAULT_START = '09:00 AM'
export const DEFAULT_END = '07:00 PM'
export const STEP = 15

export interface DayRowProps {
  day: DaysOfWeek
  value: DayValue
  isLoading?: boolean
  onSelectionChange: (checked: boolean) => void
  onValueChange: (value: DayValue) => void
}

export interface DayValue {
  openingTime: string | null
  closingTime: string | null
}

const DayRows: FC<DayRowProps> = ({
  day,
  value,
  isLoading = true,
  onSelectionChange,
  onValueChange
}) => {
  const { openingTime, closingTime } = value
  const hasOpeningTime = Boolean(openingTime)
  const hasClosingTime = Boolean(closingTime)
  const isChecked = hasOpeningTime && hasClosingTime
  const isHoursDisabled = isLoading || !isChecked

  const handleSelectedChange = useCallback((evt) => {
    onSelectionChange(evt.target.checked)
  }, [onSelectionChange])

  const getChangeHandler = (key: keyof DayValue) => (val: string) => {
    onValueChange({ ...value, [key]: val })
  }

  const handleStartChange = useCallback(getChangeHandler('openingTime'), [onValueChange, value])
  const handleEndChange = useCallback(getChangeHandler('closingTime'), [onValueChange, value])

  return (
    <div className='business-hours-row'>
      <BlockSkeleton isLoading={isLoading} className='business-hours-checkbox label'>
        <Checkbox
          className='business-hours-checkbox'
          checked={isChecked}
          disabled={isLoading}
          onChange={handleSelectedChange}
        />
      </BlockSkeleton>

      <BlockSkeleton isLoading={isLoading} className='day label'>
        <p>{capitalize(day)}</p>
      </BlockSkeleton>

      <BlockSkeleton isLoading={isLoading} className='label'>
        <p>From</p>
      </BlockSkeleton>

      <BlockSkeleton isLoading={isLoading} className='label'>

        <TimeSelect
          disabled={isHoursDisabled}
          classes={{ root: `time-select  ${isHoursDisabled ? 'disabled' : ''}` }}
          value={isChecked ? (openingTime ?? '') : DEFAULT_START}
          onChange={handleStartChange}
          closing={(closingTime != null) ? addStep(closingTime, -STEP) : ''}
          step={STEP}
          DropdownIconComponent={DropdownIcon}
        />
      </BlockSkeleton>

      <BlockSkeleton isLoading={isLoading} className='label'>
        <p className={`label ${isLoading ? 'disabled' : ''}`}>To</p>
      </BlockSkeleton>

      <BlockSkeleton isLoading={isLoading}>
        <TimeSelect
          disabled={isHoursDisabled}
          classes={{ root: `time-select  ${isHoursDisabled ? 'disabled' : ''}` }}
          value={isChecked ? (closingTime ?? '') : DEFAULT_END}
          onChange={handleEndChange}
          opening={(openingTime != null) ? addStep(openingTime, STEP) : ''}
          step={STEP}
          DropdownIconComponent={DropdownIcon}
        />
      </BlockSkeleton>
    </div>
  )
}

export default DayRows
