import type { FC } from 'react'
import { PRESET_LABELS } from '@carfluent/common'

import Table from 'components/common/Table'
import ReportLayout from 'components/reports/ReportLayout'

import type { ActivityReportItemDto } from 'api/types/responses'

import { useReportActivity } from './hook'
import { columns } from './hook/columns'

import CLASS_NAME, { ROOT_CLASS_NAME } from './styles'

const EMPTY_ROWS: ActivityReportItemDto[] = []

const EXCLUDED_PRESETS = [
  PRESET_LABELS.ALL_TILL_TODAY,
  PRESET_LABELS.ALL,
  PRESET_LABELS.PREVIOUS_QUARTER,
  PRESET_LABELS.YEAR_TO_DATE,
  PRESET_LABELS.THIS_QUARTER,
  PRESET_LABELS.THIS_YEAR
]

const ReportActivity: FC = () => {
  const {
    onChangeDatesFilter,
    rows,
    isLoading
  } = useReportActivity()

  return (
    <ReportLayout
      title='Activity report'
      onChangeDatesFilter={onChangeDatesFilter}
      initialPresetName={PRESET_LABELS.THIS_MONTH}
      rootClassName={ROOT_CLASS_NAME}
      excludePresets={EXCLUDED_PRESETS}
    >
      <div className={CLASS_NAME}>
        <Table
          data={isLoading ? EMPTY_ROWS : rows}
          isLoading={isLoading}
          columns={columns}
          emptyTableMessage='No results found'
        />
      </div>
    </ReportLayout>
  )
}

export default ReportActivity
