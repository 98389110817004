import { AccountTrialBalance } from 'constants/route_helper'
import { type CostSummary } from 'api/types'
import { type CostSummaryListItem } from './types'

export const parseData = (data: CostSummary[]): CostSummaryListItem[] =>
  data.map(({
    accountId,
    accountName,
    amount,
    accountType,
    accountNumber,
    accountCategory
  }) => ({
    amount,
    type: `${accountType}${accountCategory != null ? ` - ${accountCategory}` : ''}`,
    account: {
      title: `${accountNumber} ${accountName}`,
      to: AccountTrialBalance(accountId)
    }
  }))
