import { css } from '@emotion/css'

export default css(`
  grid-column: 1 / 4; 
  display: grid;
  row-gap: 24px;

  .business-hours-row {
    display: grid;
    height: 56px;
    box-sizing: border-box;
    grid-template-columns: 42px 110px 64px 284px 55px 284px;
    align-items: center;
    justify-content: space-between;

    .business-hours-checkbox.MuiButtonBase-root {
      margin-left: -9px;
      width: 42px;
    }
  
    .label {
      text-align: right;
      margin-right: 8px;
      color: #101010;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;

      &.day { text-align: left; }
      &.disabled { color: rgba(33, 33, 33, 0.5); }
    }

    .time-select {
      &.disabled img { opacity: 0.5; }
      
      .MuiAutocomplete-endAdornment {
          button {
            padding: 8px 12px;
            margin-right: 8px;
            width: 12px;
          }
        }
      }
    }
  }
`)
