import { css } from '@emotion/css'

export const MODAL_CLASS = css(`
    && {
      &.cf-modal-container {

        .cf-modal-content-scroll-wrapper {
          z-index: 1199;
        }

        .cf-modal-root {

          padding: 0 0 16px;

          .cf-modal-content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 16px;
            grid-row-gap: 24px;
            padding: 24px 16px 0;
          }

          .cf-modal-title {
            padding: 16px 16px 0;
            h3 {
              font-size: 20px;
              font-weight: 500;
              color: #101010;
            }
          }

          .cf-icon {
            padding: 10px;
          }

          .cf-endTime-select, .cf-startTime-select {
            .MuiAutocomplete-inputRoot[class*="MuiInputBase-root"]{
              padding-left: 12px;
              background-color: #fafafa;
              border: 1px solid #fafafa;
              :hover {
                border: 1px solid #C99B86;
              }
            }
          }

          .MuiFilledInput-root {
            color: #101010;
          }

          .MuiInputLabel-root {
            color: rgba(33, 33, 33, 0.8);
          }

          .cf-modal-footer {
            display: flex;
            justify-content: end;
            gap: 16px;
            margin-top: 24px;
            padding: 0 16px;

            }

            .cf-modal-footer {
              display: flex;
              justify-content: end;
              gap: 16px;
              margin-top: 24px;
              padding: 0 16px;

              .cf-add-btn, .cf-remove-btn, .cf-save-btn {
                span {
                  font-family: Roboto;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 24px;
                  letter-spacing: 0.15px;
                }
              }
            }
          }
        }
      }
    }
  `)

export const DATEPICKER_POPPER_CLASS = css(`
  && {
    .MuiToolbar-root {
      display: none;
    }

    .MuiPickersBasePicker-pickerView {
      max-width: 388px;
      min-width: 388px;
      max-height: 432px;
      min-height: 432px;
      padding: 0 4px 12px 4px;
      justify-content: flex-start;
      align-items: stretch;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .MuiPickersBasePicker-pickerView::-webkit-scrollbar {
      display: none;
      width: 0;
      background: transparent;
    }

    .MuiPaper-root {
      border-radius: 12px;
    }

    .MuiPaper-elevation8 {
      box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.02), 0px 10px 16px rgba(0, 0, 0, 0.08), 0px 6px 30px rgba(0, 0, 0, 0.03);
    }

    .MuiPickersCalendarHeader-switchHeader {
      position: relative;
      margin: 0 0 18px;
      padding: 12px 0 12px 0;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, -50%);
        height: 1px;
        width: 356px;
        background: rgba(0, 0, 0, 0.3);
      }
    }

    .MuiPickersCalendarHeader-transitionContainer {
      height: 24px;
    }

    .MuiPickersSlideTransition-transitionContainer > * {
      position: relative;
    }

    .MuiPickersCalendar-transitionContainer {
      margin-top: 18px;
      min-height: initial;
      height: auto;
    }

    .MuiSvgIcon-root {
      path:nth-of-type(1) {
        fill: #101010;
      }
    }

    .MuiPickersDay-day {
      opacity: 1;
      width: 42px;
      height: 42px;
    }

    .MuiTypography-body1 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      color: #101010;
    }

    .MuiTypography-body2 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #101010;
    }

    .MuiPickersDay-hidden {
      .MuiTypography-body2 {
        color: rgba(33, 33, 33, 0.5);
      }
    }
    
    .MuiPickersDay-current {
        border: 1px solid #C99B86;
    }
      
    .MuiIconButton-root {
      &:hover {
        background: rgba(221, 179, 160, 0.62);
      }
  }

  .MuiPickersCalendarHeader-daysHeader {
    max-height: initial;
    justify-content: space-between;
    padding: 0 12px;
  }

  .MuiPickersCalendarHeader-dayLabel {
    font-size: 14px;
    line-height: 20px;
    color: #101010;
  }

  .MuiPickersCalendar-week {
    justify-content: space-between;
    padding: 0 12px;
    margin: 0 0 8px 0;
  }

  .MuiPickersCalendar-week:last-of-type {
    margin: 0;
  }
`)

export const END_TIME_OPTIONS_CONTAINER = css(`
  && {
    text-overflow: ellipsis; 
    overflow: hidden;
    white-space: nowrap;
  }

  span {
    color: #212121;
    opacity: 0.5; 
    margin-left: 6px;
  }
`)
