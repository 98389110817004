import { DealState } from 'constants/names'
import { WorkflowStateId } from 'api/types'

export interface DealLike {
  dealState: string
}
export interface Deal2Like {
  dealStateId: number
}

const isDealCompleted = (deal: DealLike): boolean => {
  return (deal.dealState === DealState.Completed)
}

export const isDeal2Completed = (deal?: Deal2Like | null): boolean => {
  return (deal?.dealStateId === WorkflowStateId.Completed)
}

export const isDealCanceled = (deal?: Deal2Like | null): boolean => {
  return (deal?.dealStateId === WorkflowStateId.Canceled)
}

export default isDealCompleted
