import axios from 'axios'
import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react'
import { type Row } from '@tanstack/react-table'
import { serializers as S, useModal } from '@carfluent/common'

import AccountingApiProvider from 'api/accounting.api'
import CostsCTX from 'store/costs'
import SettingsCTX from 'store/settings'
import useAsyncEffect from 'hooks/useAsyncEffect'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useCostTypes from 'hooks/useCostTypes'
import useCostsSummary from 'hooks/useCostsSummary'
import useCostDetails from 'hooks/useCostDetails'
import { scrollToErrorInContainer } from 'utils/validation'
import { toDateOrNull } from 'utils/parse_date'
import { toNumberOrNull } from 'utils/parse_string'
import { isTruthy } from 'utils/general'
import { type BaseListItem } from 'types'
import {
  type AccountListItem,
  type FloorPlanUpdateRequestDto,
  type PackCostUpdateRequestDto,
  type VehiclePaymentsUpdateRequestDto,
  type VehicleCostListItemDto
} from 'api/types'

import type {
  CostListItem,
  FloorplanFormData,
  PackCostFormData,
  UseCostsTabProps,
  UseCostsTabReturn
} from './types'

import { GET_DEFAULT_PACK_COST, GET_DEFAULT_FLOORPLAN } from '../../components/CostsTabPanel'
import CostsTabPanelCTX from './store'
import { PackCostFields, FloorplanFields } from './types'

const SCROLL_TO_ERROR_DELAY = 300
const INVALID_NUMBER = -42

const useCostsTab = ({
  vehicleId
}: UseCostsTabProps): UseCostsTabReturn => {
  const { showAlert } = useCustomSnackbar()
  const { isModalOpen, onOpenModal, onCloseModal: _onCloseModal } = useModal() // AZ-TODO: investigate, which modal?
  const { accounting, dealer, isAccountingEnabled } = useContext(SettingsCTX)
  const [selectedCost, setSelectedCost] = useState<VehicleCostListItemDto | null>(null)
  const [selectedJournalEntry, setSelectedJournalEntry] = useState<number | null>(null)
  const [isTabSaving, setIsTabSaving] = useState(false)

  const {
    costs,
    costsTotals,
    setCosts,
    setCostsTotals,
    deleteCost
  } = useContext(CostsCTX)

  const {
    askFloorplanFormReset,
    askFloorplanFormShowErrors,
    askPackFormReset,
    askPackFormShowErrors,
    cleanUp,
    isPackCostFormValid,
    isFloorplanFormValid,
    isTabDirty,
    floorplanFormData,
    floorplanResetTS,
    floorplanTouchifyTS,
    originalPackCost,
    originalFloorplan,
    packCostFormData,
    packCostResetTS,
    packCostTouchifyTS,
    setOriginalPackCost,
    setOriginalFloorplan,
    setPackFormData,
    setFloorplanFormData,
    setPackFormIsValid,
    setFloorplanIsValid,
    setTabDirty,
    updatePackFormData,
    updateFloorplanFormData
  } = useContext(CostsTabPanelCTX)

  const { getSummary } = useCostsSummary(vehicleId)
  const { getDetails } = useCostDetails(vehicleId)
  const { costTypes } = useCostTypes()

  const costItems = useMemo(() => {
    if ((costTypes.length === 0) || (costs.length === 0)) {
      return []
    }

    const costItems: CostListItem[] = []

    for (const c of costs) {
      costItems.push({
        id: c.id,
        type: costTypes.find(v => v.id === c.costTypeId)?.name ?? '',
        amount: c.amount,
        date: toDateOrNull(c.date)
      })
    }

    return costItems
  }, [costs, costs.length, costTypes])

  const costsBreakdown = useMemo(() => {
    const costTypesObject = costTypes.reduce<Record<string, BaseListItem>>((acc, v) => {
      acc[v.id] = v
      return acc
    }, {})

    const costList = costsTotals.costs.map((item) => {
      return {
        name: costTypesObject[item.costType]?.name ?? '',
        amount: item.amount
      }
    })

    const sumOfAllCosts = costList.reduce((acc, curr) => {
      acc += curr.amount
      return acc
    }, 0)

    return {
      costList,
      hasPackCost: packCostFormData.isPackEnabled,
      packCost: packCostFormData.packCost ?? 0,
      hasFloorplanCost: floorplanFormData.isFloorplanEnabled,
      floorplanAmount: floorplanFormData.amount ?? 0,
      otherInventoryCosts: costsTotals.otherInventoryCosts,
      total: sumOfAllCosts + (costsTotals?.otherInventoryCosts ?? 0) +
        (packCostFormData.isPackEnabled ? (packCostFormData.packCost ?? 0) : 0)
    }
  }, [
    costs,
    costTypes,
    costsTotals.costs,
    packCostFormData.isPackEnabled,
    packCostFormData.packCost,
    floorplanFormData.isFloorplanEnabled,
    floorplanFormData.amount
  ])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  /**
   * Gets account object by account number, caches into ref.
   */
  const refLoadedAccounts = useRef<Record<number, AccountListItem | null>>({})
  const loadAccountByNumber = useCallback(async (accNumber: number): Promise<AccountListItem | null> => {
    if (refLoadedAccounts.current[accNumber]?.number === accNumber) {
      return refLoadedAccounts.current[accNumber]
    }

    try {
      const payload = { search: `${accNumber}`, take: 1, skip: 0 }
      const resp = await AccountingApiProvider.getAccounts(payload)
      const account = resp.items[0] ?? null

      refLoadedAccounts.current[accNumber] = account
      return account
    } catch {
      refLoadedAccounts.current[accNumber] = null
      return null
    }
  }, [])

  const loadCosts = useCallback(async (isReset: boolean = false) => {
    if (vehicleId == null) {
      return
    }

    try {
      const costs = await AccountingApiProvider.getCosts(vehicleId)
      setCosts(costs.items)
      setCostsTotals(costs.totals)

      if (isReset) {
        askPackFormReset()
        askFloorplanFormReset()
      }
    } catch {
      console.error('Fetching costs error')
    }
  }, [
    vehicleId,
    setCosts,
    setCostsTotals,
    askPackFormReset,
    askFloorplanFormReset
  ])

  const loadVehiclePayments = useCallback(async (): Promise<void> => {
    const { floorPlan, packCost } = await AccountingApiProvider.getVehiclePayments(vehicleId)

    setFloorplanFormData({
      ...GET_DEFAULT_FLOORPLAN(),
      ...floorPlan,
      [FloorplanFields.IsFloorplanEnabled]: floorPlan?.id != null,
      [FloorplanFields.Date]: toDateOrNull(floorPlan?.date)
    })

    /**
     * AZ-TODO: check once more if we need default values here, since they
     * are already added in useEffect for checkbox.
     */

    const holdingAcc: AccountListItem | null = packCost?.creditAccount ??
      await loadAccountByNumber(accounting.packHoldingAccountNumber)

    const assetAcc: AccountListItem | null = packCost?.debitAccount ??
      await loadAccountByNumber(accounting.packInventoryAssetAccountNumber)

    const packCostAmount = packCost?.amount ?? null

    setPackFormData({
      ...GET_DEFAULT_PACK_COST(),
      ...packCost,
      [PackCostFields.IsPackEnabled]: packCost?.id != null,
      [PackCostFields.PackCost]: packCostAmount === null
        ? dealer?.dealerFeeMarkupSettings?.inventoryPackCost ?? null
        : packCostAmount,
      [PackCostFields.PackHoldingAccount]: holdingAcc,
      [PackCostFields.PackInventoryAssetAccount]: assetAcc
    })

    setOriginalPackCost(packCost)
    setOriginalFloorplan(floorPlan)
  }, [
    accounting.packHoldingAccountNumber,
    accounting.packInventoryAssetAccountNumber,
    setOriginalFloorplan,
    setOriginalPackCost,
    vehicleId
  ])

  const onSubmitCost = useCallback(async () => {
    await Promise.all([
      loadCosts(),
      getSummary(),
      getDetails()
    ])
  }, [loadCosts, getSummary, getDetails])

  /**
   * AZ-TODO: discover, which exactly table uses this handler.
   */
  const onRowClick = useCallback((row: Row<CostListItem>) => {
    const id = Number(row.original.id)
    const match = costs.find(c => c.id === id)

    if (match != null) {
      setSelectedCost(match)
    }

    onOpenModal()
  }, [costs, onOpenModal])

  /**
   * AZ-TODO: discover, which exactly modal uses this handler.
   */
  const onCloseModal = useCallback(() => {
    setSelectedCost(null)
    _onCloseModal()
  }, [_onCloseModal])

  const onOpenModalJournalEntry = useCallback((id: number | null) => {
    onCloseModal()
    setSelectedJournalEntry(id)
  }, [onCloseModal])

  const onCloseModalJournalEntry = useCallback(() => {
    setSelectedJournalEntry(null)
  }, [])

  const onDeleteCost = useCallback(async (id: string | number) => {
    deleteCost(id)

    await Promise.all([
      loadCosts(),
      getDetails(),
      getSummary()
    ])
  }, [deleteCost, getDetails, getSummary, loadCosts])

  /**
   * Removes pack cost only from page's store. The actual deletion will be executed
   * when the User will save the whole tab.
   */
  const onDeletePackCost = useCallback(async () => {
    setPackFormData(GET_DEFAULT_PACK_COST()) // To force "InitializedByDefaults" next time (by removing ID).
    setPackFormIsValid(true)
    askPackFormReset()
    setTabDirty(true)
  }, [
    askPackFormReset,
    setPackFormData,
    setPackFormIsValid,
    setTabDirty
  ])

  /**
   * Removes pack cost only from page's store. The actual deletion will be executed
   * when the User will save the whole page.
   */
  const onDeleteFloorplan = useCallback(async () => {
    setFloorplanFormData(GET_DEFAULT_FLOORPLAN())
    setFloorplanIsValid(true)
    askFloorplanFormReset()
    setTabDirty(true)
  }, [
    askFloorplanFormReset,
    setFloorplanFormData,
    setFloorplanIsValid,
    setTabDirty
  ])

  const saveSpecialCosts = useCallback(async (
    floorplanPayload: FloorPlanUpdateRequestDto,
    packCostPayload: PackCostUpdateRequestDto
  ) => {
    try {
      const payload: VehiclePaymentsUpdateRequestDto = {
        floorPlan: floorplanFormData.isFloorplanEnabled ? floorplanPayload : null,
        packCost: packCostFormData.isPackEnabled ? packCostPayload : null
      }

      setIsTabSaving(true)
      await AccountingApiProvider.updateVehiclePayments(vehicleId, payload)
      return true
    } catch (err) {
      if (axios.isAxiosError(err)) {
        showAlert(err)
      } else {
        showAlert('Costs updating failed')
      }

      console.error(err)
      return false
    } finally {
      setIsTabSaving(false)
    }
  }, [
    floorplanFormData.isFloorplanEnabled,
    packCostFormData.isPackEnabled,
    vehicleId
  ])

  const onProcessSave = useCallback(async (): Promise<boolean> => {
    if (!isPackCostFormValid) {
      askPackFormShowErrors()
    }

    if (!isFloorplanFormValid) {
      askFloorplanFormShowErrors()
    }

    if (!isFloorplanFormValid || !isPackCostFormValid) {
      setTimeout(scrollToErrorInContainer, SCROLL_TO_ERROR_DELAY)
      return false
    }

    if (isTabDirty) {
      const isSaved = await saveSpecialCosts(
        getFloorplanPayload(floorplanFormData),
        getPackPayload(packCostFormData)
      )

      if (isSaved) {
        await Promise.all([getSummary(), getDetails(), loadCosts(true)])
        askFloorplanFormReset()
        askPackFormReset()
        setTabDirty(false)
      }

      return isSaved
    }

    return true
  }, [
    askFloorplanFormReset,
    askFloorplanFormShowErrors,
    askPackFormReset,
    askPackFormShowErrors,
    isFloorplanFormValid,
    isPackCostFormValid,
    isTabDirty,
    floorplanFormData,
    originalFloorplan,
    originalPackCost,
    originalFloorplan?.id,
    packCostFormData?.id,
    packCostFormData,
    setTabDirty
  ])

  const onProcessReset = useCallback(async () => {
    await loadVehiclePayments()
    askFloorplanFormReset()
    askPackFormReset()
    setTabDirty(false)
  }, [
    askFloorplanFormReset,
    askPackFormReset,
    setTabDirty
  ])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Some clean-up on unmount.
   */
  useEffect(() => { return cleanUp }, [cleanUp])

  /**
   * The entry point of costs loading chain.
   */
  useEffect(() => {
    if (isAccountingEnabled) {
      setOriginalPackCost(null)
      setOriginalFloorplan(null)
      void loadCosts()
      void loadVehiclePayments()
    }
  }, [
    loadCosts,
    loadVehiclePayments,
    isAccountingEnabled,
    setOriginalFloorplan,
    setOriginalFloorplan
  ])

  /**
   * Initializes PackCost form after enabling it by checkbox.
   * Mostly, needed because of debit/credit accounts.
   * Floorplan does not have these fields, so we don't need a special
   * initializer for it.
   */
  useAsyncEffect(async () => {
    if (packCostFormData.isPackEnabled && (packCostFormData.id == null)) {
      const holdingAcc: AccountListItem | null = await loadAccountByNumber(accounting.packHoldingAccountNumber)
      const assetAcc: AccountListItem | null = await loadAccountByNumber(accounting.packInventoryAssetAccountNumber)

      setPackFormData({
        ...GET_DEFAULT_PACK_COST(),
        [PackCostFields.IsPackEnabled]: true,
        [PackCostFields.PackHoldingAccount]: holdingAcc,
        [PackCostFields.PackInventoryAssetAccount]: assetAcc,
        [PackCostFields.PackCost]: dealer?.dealerFeeMarkupSettings?.inventoryPackCost ?? 0
      })
    }
  }, [
    packCostFormData.id,
    packCostFormData.isPackEnabled,
    accounting.packHoldingAccountNumber,
    accounting.packInventoryAssetAccountNumber
  ])

  /**
   * Next two effects preload Account entities by account number,
   * if Vehicle's Pack is not created yet.
   *
   * In this case we need to prefill Pack with some default account IDs.
   *
   * On FE side we have only Account number, so we need to load
   * corresponding accounts to get their IDs.
   */

  useAsyncEffect(async () => {
    const isPackCostCreated = originalPackCost?.id != null
    const hasPackSettings = isTruthy(accounting?.packHoldingAccountNumber)

    if (!isPackCostCreated && hasPackSettings) {
      await loadAccountByNumber(accounting.packHoldingAccountNumber) // cacheable
    }
  }, [
    accounting?.packHoldingAccountNumber,
    originalPackCost?.id
  ])

  useAsyncEffect(async () => {
    const isPackCostCreated = originalPackCost?.id != null
    const hasPackSettings = isTruthy(accounting?.packInventoryAssetAccountNumber)

    if (!isPackCostCreated && hasPackSettings) {
      await loadAccountByNumber(accounting.packInventoryAssetAccountNumber) // cacheable
    }
  }, [
    accounting?.packInventoryAssetAccountNumber,
    originalPackCost?.id
  ])

  // ========================================== //

  const floorplanProps = useMemo(() => ({
    lastResetTS: floorplanResetTS,
    lastTouchifyTS: floorplanTouchifyTS,
    values: floorplanFormData,
    setFieldValue: updateFloorplanFormData,
    setFormIsValid: setFloorplanIsValid,
    onDeleteFloorplan
  }), [
    floorplanResetTS,
    floorplanTouchifyTS,
    floorplanFormData,
    onDeleteFloorplan,
    setFloorplanIsValid,
    updateFloorplanFormData
  ])

  const packCostProps = useMemo(() => ({
    lastResetTS: packCostResetTS,
    lastTouchifyTS: packCostTouchifyTS,
    values: packCostFormData,
    setFieldValue: updatePackFormData,
    setFormIsValid: setPackFormIsValid,
    onDeletePackCost
  }), [
    packCostResetTS,
    packCostTouchifyTS,
    packCostFormData,
    onDeletePackCost,
    setPackFormIsValid,
    updatePackFormData
  ])

  return useMemo(() => ({
    costTypes,
    costsBreakdown,
    floorplanProps,
    getCosts: loadCosts,
    isModalOpen,
    isTabDirty,
    isTabSaving,
    items: costItems,
    onProcessReset,
    onProcessSave,
    onRowClick,
    onOpenModal,
    onCloseModal,
    onDeleteCost,
    onSubmit: onSubmitCost,
    packCostProps,
    selectedCost,
    getSummary,
    getDetails,
    selectedJournalEntry,
    onOpenModalJournalEntry,
    onCloseModalJournalEntry
  }), [
    costTypes,
    costsBreakdown,
    floorplanProps,
    loadCosts,
    isModalOpen,
    isTabDirty,
    isTabSaving,
    costItems,
    onProcessReset,
    onProcessSave,
    onRowClick,
    onOpenModal,
    onCloseModal,
    onDeleteCost,
    onSubmitCost,
    packCostProps,
    selectedCost,
    getSummary,
    getDetails,
    selectedJournalEntry,
    onOpenModalJournalEntry,
    onCloseModalJournalEntry
  ])
}

export default useCostsTab

// ========================================== //

const getPackPayload = (packCostFormData: PackCostFormData): PackCostUpdateRequestDto => {
  return {
    amount: packCostFormData.packCost ?? 0,
    creditAccountId: toNumberOrNull(packCostFormData.packHoldingAccount?.id) ?? INVALID_NUMBER,
    debitAccountId: toNumberOrNull(packCostFormData.packInventoryAssetAccount?.id) ?? INVALID_NUMBER
  }
}

const getFloorplanPayload = (floorplanFormData: FloorplanFormData): FloorPlanUpdateRequestDto => {
  return {
    amount: floorplanFormData.amount ?? 0,
    date: S.serializeDate(floorplanFormData.date) ?? '',
    vendorId: toNumberOrNull(floorplanFormData.vendor?.id) ?? INVALID_NUMBER
  }
}
