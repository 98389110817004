import { type FC } from 'react'

import CLASS_NAME from './styles'

const LegendContent: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <div className='legend-item'>
        <div className='web-leads-icon' />
        <span>Inquiries</span>
      </div>
      <div className='legend-item'>
        <div className='price-icon' />
        <span>Price</span>
      </div>
    </div>
  )
}
export default LegendContent
