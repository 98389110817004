import axios from 'axios'
import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useLoader, useModal } from '@carfluent/common'

import { WebSocketsCrm as ws } from 'services/web_sockets'
import useCloudAccessToken from 'hooks/useCloudAccessToken'
import { NOT_FOUND_STATUS } from 'constants/route_helper'
import { Notifications } from 'constants/names'
import CRMApiProvider from 'api/crm.api'
import {
  type DictionaryItems,
  type EntityId,
  type EmailThread,
  type TimelineItem,
  type LeadLogUpdatedNotificationArgs,
  TimelineFilterItemsIds, TimelineLogTypes,
  type Vehicle,
  type DictionaryItem
} from 'api/types'

import { formatDateForTimelineGroup } from 'utils/formatTimelineGroup'
import type { CarOfInterest } from 'components/crm/CarsOfInterest/components/CarInfo'
import type { EmailThreadModalProps } from 'components/common/EmailThreadModal/hook/types'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useEffectOnce from 'hooks/useEffectOnce'
import type { GroupedTimelines, ItemProps, ShowThreadBtnProps } from '../components/Item/types'
import type { UseHeaderProps } from '../components/Header/hook'
import { parseTimelines, parseDataToEmailThread } from './parser'

export interface UseTimelineReturn {
  timelines: ItemProps[]
  groupedTimelines: GroupedTimelines
  headerProps: UseHeaderProps
  commentProps: {
    comment: string
    onChange: (id: string | undefined, value: string) => void
    onSubmit: () => void
    isLoading: boolean
    disabled: boolean
  }
  emailThreadProps: EmailThreadModalProps
  showThreadBtnProps: ShowThreadBtnProps
}

export interface UseTimelineProps {
  sources: DictionaryItems<string>
  leadId: EntityId
  carsOfInterest: CarOfInterest[]
  linkToWebsite: string
  suggestedCars: Vehicle[]
  emailTemplates: DictionaryItem[]
  primaryCarOfInterestId: number | null
}

export const useTimeline = ({
  sources,
  leadId,
  carsOfInterest,
  emailTemplates,
  linkToWebsite,
  suggestedCars,
  primaryCarOfInterestId
}: UseTimelineProps): UseTimelineReturn => {
  const [comment, setComment] = useState('')
  const [timelinesApi, setTimelinesApi] = useState<TimelineItem[]>([])
  const [selectedFilter, setSelectedFilter] = useState<TimelineFilterItemsIds>(TimelineFilterItemsIds.All)
  const [emailThread, setEmailThread] = useState<EmailThread | null>(null)
  const [isSubmittingShowThread, setIsSubmittingShowThread] = useState<boolean>(false)
  const [activeTimelineEmailId, setActiveTimelineEmailId] = useState<EntityId | null>(null)
  const { showAlert } = useCustomSnackbar()
  const { isLoading, startLoader, stopLoader } = useLoader()
  const { token } = useCloudAccessToken() ?? {}

  const refFilter = useRef<TimelineFilterItemsIds>(TimelineFilterItemsIds.All)
  const refIsInitialLoad = useRef(true)

  const timelines = useMemo(() => {
    return parseTimelines(timelinesApi, sources)
  }, [sources, timelinesApi])

  const groupedTimelines = useMemo(() => {
    const grouped: Record<string, ItemProps[]> = {}

    timelines.forEach(timeline => {
      const dateKey = formatDateForTimelineGroup(timeline.dateISO) ?? 'Today'

      if (grouped[dateKey] === undefined) {
        grouped[dateKey] = []
      }

      grouped[dateKey].push(timeline)
    })

    return grouped
  }, [timelines])

  const {
    isModalOpen: isEmailThreadModalOpen,
    onOpenModal: onOpenEmailThreadModal,
    onCloseModal: onCloseEmailThreadModal
  } = useModal()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onChangeComment = useCallback((_, value) => setComment(value), [])

  const loadTimelines = useCallback(async (filter = selectedFilter) => {
    try {
      const { items } = await CRMApiProvider.getTimelines(leadId, filter)

      setTimelinesApi(items)
    } catch (e) {
      if (axios.isAxiosError(e) && e?.response?.status !== NOT_FOUND_STATUS) {
        showAlert(e)
      }
    }
  }, [leadId, selectedFilter, showAlert])

  const loadTimeline = useCallback(async (leadId: number, logId: number): Promise<void> => {
    try {
      const res = await CRMApiProvider.getTimeline(leadId, refFilter.current, logId)

      setTimelinesApi(prev => {
        const index = prev.findIndex(item => item.id === logId)
        if (index !== -1) {
          const updatedTimelines = [...prev]
          updatedTimelines[index] = res

          return updatedTimelines
        } else {
          return [res, ...prev]
        }
      })
    } catch (e) {
      console.error(e)
    }
  }, [])

  const onChangeFilter = useCallback(
    (id: TimelineFilterItemsIds) => {
      setSelectedFilter(id)
      refFilter.current = id
      void loadTimelines(id)
    },
    [loadTimelines]
  )

  const onSubmitComment = useCallback(
    async () => {
      try {
        startLoader()
        await CRMApiProvider.sendTimelineComment(leadId, comment)

        setComment('')
      } catch (e) {
        showAlert(e)
      } finally {
        stopLoader()
      }
    }, [
      leadId,
      loadTimelines,
      comment,
      showAlert,
      startLoader,
      stopLoader
    ])

  const onLoadEmailThread = useCallback(async (emailId: EntityId): Promise<void> => {
    try {
      setIsSubmittingShowThread(true)
      setActiveTimelineEmailId(emailId)

      const emailThreadPayload = await CRMApiProvider.getEmailThread(leadId, emailId)
      const parsedEmailThread = parseDataToEmailThread(emailThreadPayload ?? null, token ?? '')

      setEmailThread(parsedEmailThread)
      onOpenEmailThreadModal()
    } catch (err) {
      // DO NOTHING
    } finally {
      setIsSubmittingShowThread(false)
    }
  }, [leadId, token, onOpenEmailThreadModal])

  const _onCloseEmailThreadModal = useCallback(() => {
    setEmailThread(null)
    onCloseEmailThreadModal()
  }, [onCloseEmailThreadModal])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffectOnce(() => {
    const runEffect = async (): Promise<void> => {
      await loadTimelines()
      refIsInitialLoad.current = false
    }

    void runEffect()
  }, [loadTimelines])

  // ========================================== //
  //                   WEBSOCKETS               //
  // ========================================== //

  /**
   * Updates Lead logs.
   * For create and update events.
   */
  const logUpdatedAction = useCallback((args: LeadLogUpdatedNotificationArgs) => {
    if (refIsInitialLoad.current) {
      return
    }

    if (args.logId != null && args.leadId === Number(leadId)) {
      /**
      * Matching with timeline filters
      */
      const isMessageType = (args.logTypeId === TimelineLogTypes.MessageSent) ||
        (args.logTypeId === TimelineLogTypes.MessageReceived) ||
        (args.logTypeId === TimelineLogTypes.MessageSentAutomatically)
      const isEmailType = (args.logTypeId === TimelineLogTypes.EmailSent) ||
        (args.logTypeId === TimelineLogTypes.EmailReceived) ||
        (args.logTypeId === TimelineLogTypes.EmailSentAutomatically)
      const isShouldAddToList = refFilter.current === TimelineFilterItemsIds.All ||
        ((refFilter.current === TimelineFilterItemsIds.Emails) && isEmailType) ||
        ((refFilter.current === TimelineFilterItemsIds.Messages) && isMessageType)

      if (!isShouldAddToList) {
        return
      }

      void loadTimeline(args.leadId, args.logId)
    }
  }
  , [loadTimeline, leadId])

  useEffect(() => {
    const onLeadLogCreatedHandler = { name: 'leads_LogCreated', action: logUpdatedAction }
    const onLeadLogUpdatedHandler = { name: 'leads_LogUpdated', action: logUpdatedAction }

    ws.on(Notifications.LogCreated, onLeadLogCreatedHandler)
    ws.on(Notifications.LogUpdated, onLeadLogUpdatedHandler)

    return () => {
      ws.off(Notifications.LogCreated)
      ws.off(Notifications.LogUpdated)
    }
  }, [logUpdatedAction])

  // ========================================== //

  return {
    timelines,
    groupedTimelines,
    headerProps: {
      selectedFilter,
      onChangeFilter
    },
    commentProps: {
      comment,
      isLoading,
      onSubmit: onSubmitComment,
      onChange: onChangeComment,
      disabled: comment.trim() === '' || isLoading
    },
    emailThreadProps: {
      leadId,
      isEmailThreadModalOpen,
      emailThread,
      emailTemplates,
      carsOfInterest,
      linkToWebsite,
      suggestedCars,
      primaryCarOfInterestId,
      onCloseEmailThreadModal: _onCloseEmailThreadModal
    },
    showThreadBtnProps: {
      isSubmitting: isSubmittingShowThread,
      activeTimelineEmailId,
      onLoadEmailThread
    }
  }
}
