import { css } from '@emotion/css'

export default css(`
    position: fixed;
    top: 132px;
    right: 24px;
    z-index: 1199; // make it lower than modals and drawers (1300/1200 by default)
    font-family: Roboto, sans-serif;
    border-radius: 8px;
    border: 1px solid #F3F3F3;
    color: #101010;
    background:  #FFFFFF;
    box-shadow: 0px 24px 38px rgba(58, 71, 78, 0.1);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    width: 318px;
    max-height: 584px;
    min-height: 128px;
    overflow-y: scroll;
    
    &::-webkit-scrollbar {
      width: 6px;
    }
    
    &::-webkit-scrollbar-track {
      background: transparent;
      margin-bottom: 13px;
      margin-top: 60px;
    }

    &::-webkit-scrollbar-thumb {
      background: #f3f3f3;
      border-radius: 3px;
    }
    

  .cf-snackbar-header {
    display: flex;
    height: 24px;
    margin: 12px 16px;
    align-items: center;
    justify-content: space-between
  }

  .cf-title {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #101010;
    width: 269px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .cf-close-all-btn {
    background: none;
    cursor: pointer;
    text-transform: none;
    text-decoration: underline;
    width: min-content;
    padding: 0px;

    &:hover {
      textDecoration: underline;
      background: none
    }
  }

  .cf-horizontal-line {
    width: 285px;
    height: 1px;
    border: none;
    background: rgba(0, 0, 0, 0.12);
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`)
