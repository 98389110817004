import type { FC } from 'react'
import type { IconProps } from 'types'

const UnableToEmail: FC<IconProps> = ({ color = '#21212180', width = 16, height = 16 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'>
      <path d='M16.5855 18L14.8177 16.2322C14.4272 15.8417 14.4272 15.2085 14.8177 14.818C15.2082 14.4275 15.8414 14.4275 16.2319 14.818L17.9997 16.5858L19.7675 14.818C20.158 14.4275 20.7912 14.4275 21.1817 14.818C21.5722 15.2085 21.5722 15.8417 21.1817 16.2322L19.4139 18L21.1817 19.7678C21.5722 20.1583 21.5722 20.7915 21.1817 21.182C20.7912 21.5725 20.158 21.5725 19.7675 21.182L17.9997 19.4142L16.2319 21.182C15.8414 21.5725 15.2082 21.5725 14.8177 21.182C14.4272 20.7915 14.4272 20.1583 14.8177 19.7678L16.5855 18Z' fill={color} />
      <path d='M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H12V18H4V8L12 13L20 8V13H22V6C22 4.9 21.1 4 20 4ZM12 11L4 6H20L12 11Z' fill={color} />
    </svg>
  )
}

export default UnableToEmail
