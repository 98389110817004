import { PercentOrAmountModes } from 'types'
import { formatCurrencyDecimal } from '@carfluent/common'
import { roundCurrency } from 'utils/math'

import { type PercentOrAmountFormData } from 'components/deals/PercentOrAmountInput/types'

export const formatSalesCommissionRadio = (mode: string): string => (
  mode === PercentOrAmountModes.Auto ? 'Default commission' : 'Amount'
)

export const formatSalesTaxRadio = (mode: string): string => (
  mode === PercentOrAmountModes.Auto ? 'Sales tax, %' : 'Amount'
)

export const formatSalesTaxFormHelper = (getSalesTaxAmount: (value: number | null) => number) => (
  values: PercentOrAmountFormData,
  isManual: boolean
): string | null => {
  if (isManual) {
    return null
  }

  const helperAmountValue = roundCurrency(getSalesTaxAmount(values.percent))
  const percentValueStr = formatCurrencyDecimal(helperAmountValue)
  return `Tax ${percentValueStr}`
}

export const formatSalesTaxFieldHelper = (
  values: PercentOrAmountFormData,
  isManual: boolean
): string | null => {
  if (isManual) {
    return 'Manually entered amount'
  }

  const percentValueStr = new Intl.NumberFormat('en-IN', {
    maximumFractionDigits: 3,
    minimumFractionDigits: 2
  }).format((values.percent ?? 0) * 100)

  return `Tax ${percentValueStr}%`
}
