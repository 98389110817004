import LeadNameAndEmailCell from '../components/cells/LeadNameAndEmailCell'
import GoToLeadAndCarOfInterestCell from '../components/cells/GoToLeadAndCarOfInterestCell'
import PhoneCell from '../components/cells/PhoneCell'

import type { GetColumnDefinitions, ColumnDefinitions } from './types'

const getColumnDefinitions = ({ onOpenLeadDetails }: GetColumnDefinitions): ColumnDefinitions => [
  {
    accessorFn: (row) => ({
      name: `${row?.firstName ?? ''} ${row?.lastName ?? ''}`,
      email: row?.email ?? '-',
      label: row.label
    }),
    id: 'name',
    cell: LeadNameAndEmailCell,
    loading: 'two_lines',
    size: 272,
    header: undefined,
    sortable: false
  },
  {
    accessorKey: 'phoneNumber',
    cell: PhoneCell,
    loading: 'big_line',
    size: 202,
    header: undefined,
    sortable: false
  },
  {
    accessorFn: (row) => ({
      carOfInterest: row.carOfInterest,
      onOpenLeadDetails: () => onOpenLeadDetails(row.id)
    }),
    accessorKey: 'carOfInterest',
    cell: GoToLeadAndCarOfInterestCell,
    loading: 'two_lines',
    size: 352,
    header: undefined,
    sortable: false
  }
]

export default getColumnDefinitions
