import { type FC } from 'react'
import type { BuyerCobuyerTabProps } from './types'
import Tab from 'components/common/Tabs/components/Tabs/Tab'
import BuyerCobuyerForm from './BuyerCobuyerForm'

const BuyerCobuyerTab: FC<BuyerCobuyerTabProps> = (props) => {
  return (
    <Tab isSelected={props.isSelected}>
      <BuyerCobuyerForm {...props} />
    </Tab>
  )
}

export default BuyerCobuyerTab
