import { css } from '@emotion/css'

export default css(`
  .info-block {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 8px;
  }

  .no-data {
    display: flex;
    min-height: 300px;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: rgba(33, 33, 33, 0.50);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
  }

  .legend-label {
    color: #101010;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .loader {
    height: 300px;
    width: 100%;
    box-shadow: none;
    text-shadow: none;
  }

  .custom-tooltip {
    display: flex;
    flex-direction: column;
    padding: 4px 12px;
    border-radius: 4px;
    background: #212121;

    .value {
      color: #FFF;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .label {
      color: #FFF;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }

  .legend {
    display: flex;
    justify-content: center;
    gap: 16px;

    .legend-item {
      display: flex;
      gap: 8px;
      align-items: center;
      color:  #101010;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;

      .icon {
        width: 16px;
        height: 4px;
      }

      &:nth-child(1) .icon {
        border-radius: 1px;
        background: #458FFF;
      }

      &:nth-child(2) .icon {
        border: 2px dashed #FF8A00;
        border-radius: 1px;
      }
    }
  }
`)
