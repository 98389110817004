import { type FC, memo } from 'react'

import {
  type DealerBusinessHours,
  type DayOfWeekTimes,
  DaysOfWeek
} from 'api/types'
import { keys } from 'utils/general'

import DayRow, { type DayValue, DEFAULT_START, DEFAULT_END } from './DayRows'

import CLASS_NAME from './styles'

export interface FormBusinessHoursProps {
  id: string
  value: DealerBusinessHours
  isLoading?: boolean
  onChange: (id: string, value: DealerBusinessHours) => void
}

const BusinessHours: FC<FormBusinessHoursProps> = ({
  id,
  value,
  onChange,
  isLoading = true
}) => {
  const rows = keys(DaysOfWeek).map((key): JSX.Element => {
    const day = DaysOfWeek[key]
    const openingKey: DayOfWeekTimes = `${day}OpeningTime`
    const closingKey: DayOfWeekTimes = `${day}ClosingTime`
    const openingTime = value?.[openingKey] ?? null
    const closingTime = value?.[closingKey] ?? null

    return (
      <DayRow
        key={`${openingKey}_${closingKey}`}
        day={DaysOfWeek[key]}
        value={{ openingTime, closingTime }}
        isLoading={isLoading}
        onSelectionChange={(checked: boolean) => {
          const nextValue = checked
            ? { ...value, [openingKey]: DEFAULT_START, [closingKey]: DEFAULT_END }
            : { ...value, [openingKey]: null, [closingKey]: null }

          onChange(id, nextValue)
        }}
        onValueChange={(val: DayValue) => {
          const nextValue = {
            ...value,
            [openingKey]: val.openingTime,
            [closingKey]: val.closingTime
          }

          onChange(id, nextValue)
        }}
      />
    )
  })

  return <div className={CLASS_NAME}>{rows}</div>
}

export default memo(BusinessHours)
