import { type FC, useCallback } from 'react'
import { FormInput } from '@carfluent/common'

import RadioGroup from 'components/common/RadioGroup'
import ActionBar from 'components/crm/ActionBar'
import { TaskCompleteOptions } from 'pages/crm/LeadDetailsView/hook/types'
import DateTimeInputs from 'pages/crm/LeadDetailsView/components/LeadDetailsTasks/components/DateTimeInputs/index'
import type { UseCompleteTaskFormReturn } from 'pages/crm/LeadDetailsView/components/LeadDetailsTasks/components/TaskComplete/hook/types'
import { CALL_RESULT_BUTTONS, FieldIds } from './constants'

import CLASS_NAME from './styles'

const CompleteCallForm: FC<UseCompleteTaskFormReturn> = ({
  isSubmitting,
  values,
  touched,
  errors,
  onBlur,
  onChange,
  onSubmit,
  onCloseTask,
  taskMode
}) => {
  const { date, time, taskCompleteOptionId, workNotes } = values

  const toShowCallHeldOn = taskCompleteOptionId === TaskCompleteOptions.SpokeToCustomer ||
    taskCompleteOptionId === TaskCompleteOptions.LeftMessage

  const showRadioError = touched[FieldIds.TaskCompleteOptionId] && (taskCompleteOptionId == null) && errors[FieldIds.TaskCompleteOptionId]

  const onCancel = useCallback((): void => {
    onCloseTask(null)
  }, [onCloseTask])

  const onChangeRadioOption = useCallback((_, value: string, id: string): void => {
    onChange(id, value)
  }, [onChange])

  return (
    <div className={CLASS_NAME}>
      <div className='radios-title-error'>
        <p className='form-subtitle'>Call result</p>
        <p className='error-text'>{showRadioError}</p>
      </div>

      <RadioGroup
        items={CALL_RESULT_BUTTONS}
        value={taskCompleteOptionId}
        onChange={onChangeRadioOption}
        id={FieldIds.TaskCompleteOptionId}
        classes={{ root: 'radio-group', item: 'radio-item' }}
      />

      {toShowCallHeldOn && (
        <>
          <p className='form-subtitle'>
            Call held on
          </p>

          <div className='complete-task-form'>
            <DateTimeInputs
              shouldGetCurrentDate
              dueDateTouched={touched.date}
              dueDateError={errors.date}
              timeError={errors.time}
              dateId={FieldIds.Date}
              timeId={FieldIds.Time}
              dueDate={date}
              time={time}
              onDateChange={onChange}
              onTimeChange={onChange}
              onBlur={onBlur}

            />
          </div>
        </>
      )}

      <FormInput
        value={workNotes}
        onChange={onChange}
        id={FieldIds.Notes}
        className={`notes-field ${!toShowCallHeldOn ? 'notes-field-margin' : ''}`}
        label='Work notes'
      />

      <div className='lead-complete-task-action-buttons-container'>
        <ActionBar
          buttonsClassName='lead-tasks-action-buttons'
          taskMode={taskMode}
          onSubmit={onSubmit}
          onCancel={onCancel}
          isLoading={isSubmitting}
        />
      </div>
    </div>
  )
}

export default CompleteCallForm
