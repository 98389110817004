import {
  dateField,
  transactionReferenceField,
  transactionDescriptionField
} from 'utils/validationPresets'

export default {
  dateTime: dateField(true),
  referenceNumber: transactionReferenceField(),
  description: transactionDescriptionField(true)
}
