import type { DeepRequired } from '@carfluent/common'
import type { PaymentFormData } from 'types'

/**
 * DD-NOTE:
 * Form does not handle cases when target is null
 * that is why we need to provide full default values
 * (like in case of billingDetails)
 */

const DEFAULT_PAYMENT_FORM_DATA: DeepRequired<PaymentFormData> = {
  amount: 0,
  billingDetails: {
    address: {
      address: '',
      city: '',
      state: '',
      zipCode: ''
    },
    cardNumber: '',
    cardHolderName: '',
    expirationDate: null,
    cvv: ''
  },
  description: null,
  isBillingAddressProvided: false,
  isManual: true,
  paymentDate: new Date(),
  transactionTypeId: null,
  payments: {
    CHECK: 0,
    CASH: 0,
    CARD: 0,
    ACH: 0,
    ZELLE: 0,
    COINBASE: 0,
    MULTIPLE: 0
  },
  printReceiptCheck: true
}

export default DEFAULT_PAYMENT_FORM_DATA
