import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import CancelSubmitActionsFooter from 'components/common/CancelSubmitActionsFooter'
import DeleteModal from 'components/dialogs/DeleteModal'
import UnsavedChangesDialog from 'components/dialogs/UnsavedChanges'

import ReconStepsTable from './components/ReconStepsTable'
import { DeleteModalInfo } from './hook/constants'
import useReconSteps from './hook'

import CLASS_NAME from './styles'

const ReconSteps: FC = () => {
  const {
    isLoading,
    isSubmitting,
    reconStepIdToDelete,
    onAddReconStep,
    onDragEnd,
    onReset,
    onSubmit,
    deleteModalProps,
    onSavedChangesProps,
    ...formProps
  } = useReconSteps()

  const {
    isDeleteStepModalOpen,
    onOpenDeleteStepModal,
    onCloseDeleteStepModal,
    onCloseAfterSubmit,
    onDeleteReconStep
  } = deleteModalProps

  const {
    isUnsavedChangesOpen,
    onCloseUnsavedChanges,
    onDontSaveAndContinueTransition,
    onSaveAndContinueTransition
  } = onSavedChangesProps

  return (
    <div className={cnx('g-submenu-content', 'card-content', 'g-margin-top-big', CLASS_NAME)}>
      <div className='cf-title-and-button'>
        <h5>Recon steps</h5>
      </div>

      <ReconStepsTable
        isLoading={isLoading}
        onAddReconStep={onAddReconStep}
        onDragEnd={onDragEnd}
        onOpenDeleteStepModal={onOpenDeleteStepModal}
        {...formProps}
      />

      <DeleteModal
        title={DeleteModalInfo.Title}
        isOpen={isDeleteStepModalOpen}
        onClose={onCloseDeleteStepModal}
        onCloseAfterSubmit={onCloseAfterSubmit}
        onConfirm={onDeleteReconStep}
        content={DeleteModalInfo.Content}
      />

      <UnsavedChangesDialog
        content='Changes done to this car will be lost if you dont save them.'
        isOpen={isUnsavedChangesOpen}
        isSubmitting={isSubmitting}
        onCloseDialog={onCloseUnsavedChanges}
        onDontSaveAndClose={onDontSaveAndContinueTransition}
        onSaveAndClose={onSaveAndContinueTransition}
      />

      <div className={cnx('tab-footer', formProps.hasChanges && 'is-shown')} role='footer'>
        <CancelSubmitActionsFooter
          isLoading={isSubmitting}
          onSubmit={onSubmit}
          onClose={onReset}
          cancelTitle='DISCARD'
          submitTitle='SAVE'
        />
      </div>
    </div>
  )
}

export default ReconSteps
