import { type FC, useMemo } from 'react'
import { cnx, FormDatePicker, FormDropdown, FormNumberInput, useModal } from '@carfluent/common'

import CustomersCoreApiProvider from 'api/customersCore.api'
import { MAX_PRICE_VALUE, MAX_TERM_VALUE } from 'constants/validation'
import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'
import LabeledFieldSkeleton from 'components/common/Skeletons/LabeledFieldSkeleton'
import renderEntityAction from 'components/accounting/EntityAction/render'
import LienholderDetails from 'pages/settings/LienholderDetails'

import { type FinancingSectionProps } from './types'
import { CLASS_NAME, POPOVER_CLASS_NAME } from './styles'

const FinancingSection: FC<FinancingSectionProps> = ({
  className,
  errors,
  isLoading = false,
  isRecorded,
  onAddLienholder,
  onBlur,
  onChange,
  touched,
  values
}) => {
  const { isModalOpen, onCloseModal, onOpenModal } = useModal()
  const lienholderActions = useMemo((): any => ([
    { id: 'Add Lienholder', name: 'Add Lienholder', onClick: onOpenModal }
  ]), [onOpenModal])

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, className)}>
      <BlockSkeleton className='section-title' isLoading={isLoading}>
        <h6>Financing</h6>
      </BlockSkeleton>

      <div className='fields'>
        <LabeledFieldSkeleton isLoading={isLoading}>
          <label htmlFor='activeLenderDecision.lienholder'>Lender:</label>

          <FormDropdown
            actions={lienholderActions}
            disabled={isRecorded}
            error={errors.activeLenderDecision?.lienholder}
            id='activeLenderDecision.lienholder'
            blurMode='select-first'
            fetchHandler={CustomersCoreApiProvider.getLienholderList}
            mode='search'
            onBlur={onBlur}
            onChange={onChange}
            placeholder='Select lender'
            popoverClassName={POPOVER_CLASS_NAME}
            renderAction={renderEntityAction}
            value={values.activeLenderDecision?.lienholder ?? null}
            touched={touched.activeLenderDecision?.lienholder}
          />
        </LabeledFieldSkeleton>

        <LabeledFieldSkeleton isLoading={isLoading}>
          <label htmlFor='activeLenderDecision.approvedRate'>Interest rate:</label>

          <FormNumberInput
            blurComplementMode='min-scale'
            error={errors.activeLenderDecision?.approvedRate}
            id='activeLenderDecision.approvedRate'
            isEmptyAllowed
            mountComplementMode='min-scale'
            placeholder='Interest rate'
            onBlur={onBlur}
            onChange={onChange}
            preset='percent'
            touched={touched.activeLenderDecision?.approvedRate}
            value={values.activeLenderDecision?.approvedRate ?? null}
            disabled={isRecorded}
          />
        </LabeledFieldSkeleton>

        <LabeledFieldSkeleton isLoading={isLoading}>
          <label htmlFor='activeLenderDecision.downPayment'>Lender down payment:</label>

          <FormNumberInput
            error={errors.activeLenderDecision?.downPayment}
            id='activeLenderDecision.downPayment'
            isNegativeAllowed={false}
            max={MAX_PRICE_VALUE}
            onBlur={onBlur}
            onChange={onChange}
            placeholder='Lender down payment'
            preset='financial'
            value={values.activeLenderDecision?.downPayment ?? null}
            touched={touched.activeLenderDecision?.downPayment}
            disabled={isRecorded}
          />
        </LabeledFieldSkeleton>

        <LabeledFieldSkeleton isLoading={isLoading}>
          <label htmlFor='activeLenderDecision.approvedTerm'>Term (month):</label>

          <FormNumberInput
            endAdornment='months'
            error={errors.activeLenderDecision?.approvedTerm}
            id='activeLenderDecision.approvedTerm'
            isNegativeAllowed={false}
            max={MAX_TERM_VALUE}
            onBlur={onBlur}
            onChange={onChange}
            placeholder='Term'
            touched={touched.activeLenderDecision?.approvedTerm}
            value={values.activeLenderDecision?.approvedTerm ?? null}
            disabled={isRecorded}
          />
        </LabeledFieldSkeleton>

        {!isLoading && (
          <div className='labeled-field'>
            <label htmlFor='firstPaymentDate'>First payment date:</label>

            <FormDatePicker
              id='firstPaymentDate'
              minDate={values.saleDate}
              onBlur={onBlur}
              onChange={onChange}
              error={errors.firstPaymentDate}
              touched={touched.firstPaymentDate}
              value={values.firstPaymentDate}
              disabled={isRecorded}
            />
          </div>
        )}
      </div>

      <LienholderDetails
        isOpen={isModalOpen}
        onClose={onCloseModal}
        onSubmit={onAddLienholder}
      />
    </div>
  )
}

export default FinancingSection
