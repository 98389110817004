import { css } from '@emotion/css'

export default css(`
  display: grid;
  grid-template-columns: 289px 200px 1fr;
  width: 100%;
  height: 630px;
  overflow: hidden;
  
  .header {
    grid-column: 1 / 4;
    background: #101010;
    height: 60px;
    min-width: 1280px;
    display: flex;
    align-items: center;
    padding: 0 40px;
  }
  
  .logo-img {
    height: 24px;
  }

  .side-bar {
    grid-column: 1;
    box-shadow: 0px 24px 38px rgba(58, 71, 78, 0.1);
  }

  .content {
    display: flex;
    flex-direction: column;
    grid-column: 3;
    margin-top: 44px;
    max-width: 720px;
  }
`)
