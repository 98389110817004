import { useCallback, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, useModal } from '@carfluent/common'

import { CreditApplication } from 'constants/route_helper'
import type { FCHook } from 'types'
import { getHoursMinutesTime } from 'utils/parse_date'
import { getParsedToken } from 'services/storage.service'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import useEffectOnce from 'hooks/useEffectOnce'
import { parseAssignedUser } from 'pages/crm/LeadDetailsView/hook/parser'
import { getInitialTaskModeState, getTaskDueDateState } from 'pages/crm/LeadDetailsView/hook/utils'
import { FieldIds, getDefaultFormData } from 'pages/crm/LeadDetailsView/hook/constants'
import { reminderFormValidation, createFormValidation } from './validator'
import {
  type UseTaskFormReturn,
  type UseTaskProps,
  type OpenTaskFormData,
  type ErrTouchShortcuts,
  TaskMode,
  TaskType
} from './types'

const DEFAULT_FORM_DATA = getDefaultFormData()

const useTask: FCHook<UseTaskProps, UseTaskFormReturn> = ({
  leadId,
  task: {
    id,
    taskTypeId,
    dueDate,
    time,
    subject,
    assignedUser,
    description,
    taskPriority,
    dealId
  },
  removeTask,
  onSubmitTask,
  onDeleteTask,
  onViewEmailClick
}) => {
  const { showAlert } = useCustomSnackbar()
  const navigate = useNavigate()

  const [taskMode, setTaskMode] = useState<TaskMode>(getInitialTaskModeState(id, taskTypeId))
  const isReminder = taskTypeId === TaskType.Reminder || subject != null

  const reminderTaskType = subject?.name as keyof typeof TaskType

  const resolvedTaskType = isReminder
    ? reminderTaskType
    : (taskTypeId != null)
        ? TaskType[taskTypeId]
        : null

  const taskDueDateState = getTaskDueDateState(dueDate ?? new Date())

  const [isFormSubmitting, setIsFormSubmitting] = useState(false)

  const editFormValues: OpenTaskFormData = useMemo(() => {
    return {
      assignedUser: parseAssignedUser(assignedUser),
      dueDate,
      time: getHoursMinutesTime(dueDate),
      description,
      taskPriority,
      subject
    }
  }, [assignedUser, dueDate, description, taskPriority, subject])

  const {
    isModalOpen: isDeleteTaskModalOpen,
    onOpenModal: _onOpenModalDeleteTask,
    onCloseModal: _onCloseModalDeleteTask
  } = useModal()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const submitAction = useCallback(async (values: OpenTaskFormData) => {
    try {
      setIsFormSubmitting(true)
      await onSubmitTask(id, { ...values, taskTypeId })
      removeTask(id)

      if (id !== null) {
        showAlert('Changes successfully saved.', { variant: 'success' })
      }
    } finally {
      setIsFormSubmitting(false)
    }
  }, [onSubmitTask, id, taskTypeId, showAlert, removeTask])

  const onEditTaskClick = useCallback(() => {
    setTaskMode(TaskMode.EditTask)
  }, [])

  const deleteAction = useCallback(async (taskId) => {
    await onDeleteTask(taskId)
    removeTask(taskId)
  }, [onDeleteTask, removeTask])

  const onSubmitDeleteTask = useCallback(async () => {
    try {
      await deleteAction(id)
    } finally {
      _onCloseModalDeleteTask()
      showAlert('Task deleted.', { variant: 'success' })
    }
  }, [id, deleteAction, _onCloseModalDeleteTask, showAlert])

  const onActionResult = useCallback(() => {
    if (taskTypeId === TaskType['Web lead']) {
      return setTaskMode(TaskMode.ViewRespond)
    }

    if (taskTypeId === TaskType['Email received']) {
      return setTaskMode(TaskMode.EmailReceived)
    }

    if (taskTypeId === TaskType['Message received']) {
      return setTaskMode(TaskMode.MessageReceived)
    }

    setTaskMode(TaskMode.ViewTask)
  }, [taskTypeId])

  const onRedirectToCreditApp = (): void => {
    if (dealId == null) {
      return
    }

    navigate(CreditApplication(dealId))
  }

  const form = useForm<OpenTaskFormData, ErrTouchShortcuts>({
    baseValues: editFormValues ?? DEFAULT_FORM_DATA,
    validationRules: isReminder ? reminderFormValidation : createFormValidation,
    onActionResult,
    submitAction,
    deleteAction
  })

  const {
    values,
    errors,
    touched,
    onBlur,
    onDelete,
    onChange,
    onSubmit,
    resetForm
  } = form

  const onCloseTask = useCallback((taskId?: number | null, taskMode?: TaskMode) => {
    if (taskId != null) {
      if (taskMode === TaskMode.CompleteTask ||
        taskMode === TaskMode.ViewRespond ||
        taskMode === TaskMode.MessageReceived ||
        taskMode === TaskMode.EmailReceived
      ) {
        removeTask(taskId)
      }
      resetForm(editFormValues)

      if (taskTypeId === TaskType['Web lead']) {
        return setTaskMode(TaskMode.ViewRespond)
      }
      if (taskTypeId === TaskType['Message received']) {
        return setTaskMode(TaskMode.MessageReceived)
      }
      if (taskTypeId === TaskType['Email received']) {
        return setTaskMode(TaskMode.EmailReceived)
      }

      setTaskMode(TaskMode.ViewTask)
    } else {
      removeTask(null)
      setTaskMode(TaskMode.ViewTask)
    }
  }, [resetForm, editFormValues, removeTask, taskTypeId])

  const onDateChange = useCallback((id: string, date: Date | null) => {
    onChange(id, date)
  }, [onChange])

  const onTimeChange = useCallback((id: string, time: string) => {
    onChange(id, time)
  }, [onChange])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffectOnce(() => {
    const token = getParsedToken()
    const LOGGED_USER_OPTION = { name: String(token?.name), id: Number(token?.sub) }

    if (values.assignedUser == null || values.assignedUser.id === 0) {
      onChange(FieldIds.AssignedTo, LOGGED_USER_OPTION)
    }
    if (values.subject == null) {
      onChange(FieldIds.Subject, subject)
    }
  }, [values.assignedUser, values.subject, subject, onChange, taskMode])

  // ========================================== //

  return {
    isFormSubmitting,
    isReminder,
    taskDueDateState,
    dueDate,
    taskTypeId,
    assignedUser,
    description,
    taskMode,
    taskType: resolvedTaskType as keyof typeof TaskType,
    leadId,
    dateTimeInputsProps: {
      dueDate,
      time,
      onDateChange,
      onTimeChange
    },
    onCloseTask,
    onChangeTaskMode: setTaskMode,
    onDeleteTaskClick: _onOpenModalDeleteTask,
    onEditTaskClick,
    onViewEmailClick,
    isModalOpen: isDeleteTaskModalOpen,
    onCloseModal: _onCloseModalDeleteTask,
    onSubmitModal: onSubmitDeleteTask,
    values,
    errors,
    touched,
    onChange,
    onBlur,
    onDelete,
    onSubmit,
    onRedirectToCreditApp
  }
}

export default useTask
