import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'
import type { CostDetail, CostSummary, CostsTotalsParams, VehicleCostListItemDto } from 'api/types'

class Costs {
  costsSummary: CostSummary[] = []
  costsDetails: CostDetail[] = []
  costs: VehicleCostListItemDto[] = []
  costsTotals: CostsTotalsParams = { costs: [], otherInventoryCosts: null }

  setCosts = (costs: VehicleCostListItemDto[]): void => {
    this.costs = costs
  }

  setCostsTotals = (costsTotals: CostsTotalsParams): void => {
    this.costsTotals = costsTotals
  }

  setCostsDetails = (details: CostDetail[] = []): void => {
    this.costsDetails = details
  }

  setCostsSummary = (summary: CostSummary[]): void => {
    this.costsSummary = summary
  }

  deleteCost = (id: string | number): void => {
    const idx = this.costs.findIndex(c => c.id === id)

    if (idx > -1) {
      const costs = [...this.costs]
      costs.splice(idx, 1)

      this.costs = costs
    }
  }

  constructor () {
    makeAutoObservable(this)
  }
}

export const CostsInstance = new Costs()
export const CostsCTX = createContext(CostsInstance)
export default CostsCTX
