import type { FC } from 'react'
import { observer } from 'mobx-react-lite'

import NewTransactionDialog from './components/NewTransactionDialog'
import EditTransactionDialog from './components/EditTransactionDialog'
import useTransactionDetails from './hook'
import CLASS_NAME from './styles'

import {
  type TransactionCreationMode,
  type UseTransactionDetailsProps,
  type UseTransactionDetailsReturn
} from './hook/types'

export type { TransactionCreationMode }

export type TransactionDetailsProps = UseTransactionDetailsProps & {
  className?: string
}

/**
 * This wrapper allows to not execute root hook when modal is closed.
 */
function TransactionDetailsWrapper (props: TransactionDetailsProps): JSX.Element {
  const componentProps = useTransactionDetails(props)
  const Component: FC<UseTransactionDetailsReturn> = props.transactionId == null
    ? NewTransactionDialog
    : EditTransactionDialog

  return (<Component {...componentProps} />)
}

const MobXWrapper = observer(TransactionDetailsWrapper)

function TransactionDetails (props: TransactionDetailsProps): JSX.Element | null {
  if (props.isModalOpen) {
    return (<MobXWrapper className={CLASS_NAME} {...props} />)
  }

  return null
}

export default observer(TransactionDetails)
