import type { FC, KeyboardEvent } from 'react'
import { FormNumberInput, utils } from '@carfluent/common'

import Toggle from 'components/common/Toggle'
import FormFilesUpload from 'components/common/FormFilesUpload'
import DragHereMessage from 'components/common/DragHereMessage'
import { ImageList } from 'components/inventory/ImagesList'

import { EMPTY_IMAGE_VALUE, FILE_EXTENSIONS, MAX_FILE_NUMBER } from 'hooks/usePhotosUpload'

import { UseGalleryPromoReturn } from './hook'

const NoImagesYet: FC = () => (
  <div className='no-images-yet'>
    <p>No promo images yet</p>
    <p>Drag or upload files</p>
  </div>
)

const onKeyPress = (e: KeyboardEvent): void => {
  if (e.key === '-') {
    e.preventDefault()
  }
}

type ReducedFields = 'isPhotosUploading'
| 'onResetGalleryPromo'
| 'onUpdate'
| 'validatePromo'
| 'onToggleEditMode'
| 'setUploadImageError'
| 'isChanged'

interface GalleryPromoProps extends Omit<UseGalleryPromoReturn, ReducedFields> {}

const GalleryPromo: FC<GalleryPromoProps> = ({
  promoData,
  onToggleIsEnabled,
  frequencyError,
  onUpdateFrequency,
  onBlurFrequency,
  frequencyTouched,
  onUploadFiles,
  uploadImageError,
  photos,
  onDeleteFile,
  onReorderPhotos
}) => {
  return (
    <>
      <div className='promo-header'>
        <div className='promo-title'>
          <h2>Gallery promo</h2>
          <Toggle
            className='cf-toggle'
            inversed
            toggled={promoData.isEnabled}
            onClick={onToggleIsEnabled}
          />
        </div>
      </div>

      {
        promoData.isEnabled &&
          <div className='promo-content'>
            <FormNumberInput
              error={frequencyError}
              id='galleryFrequency'
              label='Every (# of car gallery)'
              min={0}
              max={100}
              onKeyPress={onKeyPress}
              onChange={onUpdateFrequency}
              onBlur={onBlurFrequency}
              touched={frequencyTouched}
              value={promoData.galleryFrequency}
              className='gallery-frequency'
            />

            <FormFilesUpload
              acceptedExtensions={null}
              id='files'
              maxFileSize={utils.UNLIMITED_FILES_SIZE}
              maxFilesTotalSize={utils.UNLIMITED_FILES_SIZE}
              onChange={(_, photos) => {
                void onUploadFiles(photos as File[])
              }}
              suggestedExtensions={FILE_EXTENSIONS}
              value={EMPTY_IMAGE_VALUE}
              dragHereMessage={<DragHereMessage />}
              maxFileNumber={MAX_FILE_NUMBER}
              errorMessage={uploadImageError}
              showError={uploadImageError != null && uploadImageError !== ''}
            >
              <div className='dropzone-container'>
                {(photos.length > 0)
                  ? <ImageList
                      images={photos}
                      mainImageUrl={null}
                      onDeleteFile={onDeleteFile}
                      onReorderPhotos={onReorderPhotos}
                    />
                  : <NoImagesYet />}
              </div>
            </FormFilesUpload>
          </div>
      }
    </>
  )
}

export default GalleryPromo
