import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  overflow: visible;
  background-color: #fff;

  .title-block {
    width: 100%;
    max-width: none!important;

    .MuiSkeleton-root {
      width: 128px;
      margin-top: 4px;
      margin-bottom: 20px;
    }
  }
  
  .image-block {
    width: 99px;

    .vehicle-image {
      border-radius: 4px;
    }
  
    .icon-exclamation-mark {
      right: 0;
    }

    .MuiSkeleton-text {
      height: 64px;
    }
  }
  
  .MuiSkeleton-text {
    height: 16px;
  }

  .cell-buyer-info {
    grid-area: cell2_2;
    min-height: var(--row-content-height);
  }

  .cell-two-lines {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .skeleton-80.is-loading { width: 80%; }

  .line {
    display: flex;
    color: #101010;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.5px;
    
    .property-name {
      min-width: 57px;
      font-size: 14px;
      line-height: 20px;
      padding-right: 4px;
      letter-spacing: 0.25px;
      color: rgba(33, 33, 33, 0.4);
    }
  }

  .title-wrapper {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    width: 100%;

    .vehicle-status-deposit-label {
      width: auto;
      max-width: none;
      margin-left: 8px;
      margin-right: 16px;
    }

    .badge {
      margin-top: 0;
      white-space: nowrap;
    }

    .cf-popover {
      width: 400px;
      display: flex;
      flex-wrap: wrap;
      z-index: 101;

      .badge { margin-top: 6px; }
    }

    .title {
      padding-right: 16px;
      max-width: calc(90% - 32px);
  
      :has(.with-label) {
        max-width: calc(90% - 132px);
      }
  
      .tooltip-content {
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        line-height: 24px;
        letter-spacing: 0.15px;
      }
    }  
  }
  
  .button-wrapper { position: relative; }
  
  .add-btn {
    width: 24px;
    height: 24px;
    padding: 2px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #458FFF;
    background: #F1F7FF;
    border-radius: 4px;
    margin-right: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    min-width: 24px;
    text-decoration: none;
    background: #F1F7FF;
    cursor: pointer;
  }

  .badge {
    margin-right: 8px;
  }

  .vehicle-info-wrapper {
    height: 64px;
    display: flex;
  }

  .vehicle-info-container {
    flex: 1;
    display: flex;
    padding: 8px 0 8px 24px;
    justify-content: space-between;
  }
  
  .cell-buyer-info {
    flex: 1;
    height: 48px;

    :not(:last-of-type) {
      margin-right: 16px;
    }

    &:nth-of-type(2) .property-name {
      min-width: 59px;
    }
  }
  
  .link {
    color: #458FFF;
    text-decoration: none;

    :hover { text-decoration: none; }
  }

  .cell-links {
    .property-name {
      min-width: 86px;
    }
  
    .property-value {
      min-width: 65px;

      @media (max-width: 1359px) {
        width: 60px;
      }
    }
  }

  .vehicle-info-panel-value {
    flex-direction: row;
  }

  .inventory-line {
    flex: 1;
    height: 100%;
    align-items: baseline;
    flex-direction: column;
    justify-content: space-between;

    .property-value {
      min-width: 136px;

      @media (max-width: 1359px) {
        max-width: 140px;
      }
    }
  }

  .property-value,
  .cf-tooltip-container .tooltip-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .block-skeleton {
    max-width: 240px;
  }

  .price-value .tooltip-content {
    font-weight: 500;
  }

  .vin-line {
    .property-value {
      max-width: calc(100% - 60px - 22px);

      @media (max-width: 1359px) {
        max-width: 133px;
      }
    }
  }

  @media (max-width: 1359px) {
    .stock-line {
      .property-value {
        max-width: 153px;
      }
    }
  }

  .milage-line {
    .property-value {
      min-width: 92px;

      @media (max-width: 1359px) {
        width: 86px;
      }
    }
  }

  @media (max-width: 1359px) {
    .price-line {
      .property-value {
        width: 86px;
      }
    }
  }
`)

export const getTruncatedClassName = (width: number | null): string => css(`
  white-space: nowrap;
  max-width: calc(100% - 30px - ${width ?? 0}px);
`)
