import { css } from '@emotion/css'

export default css`
  .cf-modal-root, .cf-modal-content {
    padding: 0!important;
  }
  
  .loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .cf-modal-content {
    width: 520px;
    height: calc(100vh - 48px);
    max-height: 845px;
    border-radius: 12px;
    overflow: hidden;
    background: #F3F3F3!important;
    display: flex;
    flex-direction: column;
    
    .messenger-header {
      background: #20C39E;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #FFF;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 1.15px;
      padding: 16px;
      
      svg {
        cursor: pointer;

        path {
          fill: white;
        }
      }
    }
    
    .messenger-content {
      display: flex;
      flex-direction: column-reverse;
      flex: 1;
      padding: 16px 16px 0;
      overflow-y: auto;
    }
  }
`
