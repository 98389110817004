import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { TimelineLogTypes } from 'api/types'
import TimelineIcons from 'components/inlineImages/TimelineIcon'
import { isGreyedRecord } from 'pages/crm/LeadDetailsView/components/Timeline/components/Item/utils'
import Avatar from 'components/common/NameWithAvatar'
import IconSVG from 'components/inlineImages'

import { type ItemHeaderProps } from './types'
import CLASS_NAME from './styles'

const ItemHeader: FC<ItemHeaderProps> = ({
  className,
  date,
  logTypeId,
  name,
  title
}) => {
  const isAvatarDisplayedAvatarInTitleLine = (name != null) &&
    ((TimelineLogTypes.LeadAssigned === logTypeId) || (TimelineLogTypes.Comment === logTypeId))

  // ========================================== //

  return (
    <div className={cnx(CLASS_NAME, 'timeline-item-header', className)}>
      <p className='timeline-item-title'>
        <TimelineIcons
          icon={logTypeId}
          color={isGreyedRecord(logTypeId) ? '#21212150' : '#212121cc'}
        />

        {title}

        {isAvatarDisplayedAvatarInTitleLine && (
          <Avatar
            showAvatar
            name={name}
            avatarIcon={name.toLowerCase() === 'system' ? <IconSVG.Bot/> : null}
          />
        )}
      </p>

      <p className='timeline-item-date'>{date}</p>
    </div>
  )
}

export default ItemHeader
