import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { NodeEnvironment } from 'constants/names'

const useGoogleAnalytics = (): void => {
  useEffect(() => {
    if (process.env.NODE_ENV === NodeEnvironment.Production) {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GOOGLE_ANALYTICS ?? ''
      }

      TagManager.initialize(tagManagerArgs)
    }
  }, [])
}

export default useGoogleAnalytics
