import { FileType } from './files.types'

export interface ErrorResponse {
  code: number | null
  message: string | null
  correlationId: string | null
  errors: { [key: string]: string[] } | null
  isError: boolean
}

export interface User {
  id: number
  firstName: string | null
  lastName: string | null
  phoneNumber: string | null
}

export enum ErrorCode {
  LockedAccountingPeriod = '10001',
  LockedLienholder = '01001',
  LinkedCustomer = '10002',
  LinkedVendor = '10003',
}

export enum DriverLicenseUploadStatusType {
  Completed = 'Completed',
  Failed = 'Failed'
}

export interface DocumentVerificationDetails {
  status: DriverLicenseUploadStatusType
  verificationFailedReasons: string[]
}

export interface DocumentDetails {
  id: number
  fileId: number
  fileTypeId: FileType
  fileName: string
  documentVerificationDetails: DocumentVerificationDetails | null
}

export interface DocumentDetailsDto extends DocumentDetails {
  createdDate: string | null
  uploadedByUser: {
    id: number
    userName: string
    email: string
    dealerFirstName: string
    dealerLastName: string
  } | null
  notarizeTransactionId: number | null
  fileName: string
}
