import { type KeyVal } from 'types'
import { joinPartsBySpace } from 'utils/view_helper'
import getKeyWithPrefix from 'utils/common/getKeyWithPrefix'
import getValueByKeys from 'utils/common/getValueByKeys'

const FIRST_NAME = 'FirstName'
const LAST_NAME = 'LastName'

function getPersonFullName (firstName: string | null, lastName: string | null): string | null
function getPersonFullName (data: KeyVal | null, prefix?: string): string | null
function getPersonFullName (firstArg: any, lastArg: string | null | undefined): string | null {
  if (firstArg == null) {
    return null
  }

  if (typeof firstArg === 'object') {
    const prefix = lastArg ?? ''
    const firstName = getValueByKeys(getKeyWithPrefix(FIRST_NAME, prefix), firstArg)
    const lastName = getValueByKeys(getKeyWithPrefix(LAST_NAME, prefix), firstArg)

    return joinPartsBySpace(firstName, lastName)
  }

  return joinPartsBySpace(firstArg, lastArg)
}

export default getPersonFullName
