import { css } from '@emotion/css'

export default css(`
  display: grid;
  padding: 16px;
  background: #FFF;
  grid-template-areas:
    'dueDate time'
    'assigned priority'
    'description description'
    'buttons buttons';
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 24px;
  border-radius: 12px;

  &.with-subject-dropdown {
    grid-template-areas:
      'dueDate time'
      'assigned priority'
      'subject subject'
      'description description'
      'buttons buttons';
    }

    .due-date { grid-area: dueDate; }

    .time {
      grid-area: time;

      input { text-transform: uppercase; }

      .MuiAutocomplete-endAdornment .MuiButtonBase-root {
        padding: 10px 12px;
        margin-right: 6px;
        width: 12px;

        :hover { background-color: transparent; }
      }
    }

    .assigned { grid-area: assigned; }
    .subject { grid-area: subject; }
    .description { grid-area: description; }

    .priority {
      grid-area: priority;

      /**
       *  TODO: remove this when dropdown's startAdornment in select mode with label will be fixed
       */
      .cf-start-adornment {
        visibility: visible;
        margin-left: -8px;
      }
      
      .cf-display { margin-left: 56px; }
        
      .task-priority-fires img {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }

    .time, .assigned, .priority, .subject  {
      label {
        letter-spacing: 0.4px;
        color: rgba(33, 33, 33, 0.8);
      }

      input {
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #101010;
      }
    }

    .delete-lead-btn {
      display: flex;
      justify-content: center;
    }

    .lead-tasks-action-buttons {
      margin-top: -8px;
      line-height: 22px;
      width: max-content;
      height: 26px;
      color: #000;
      
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
      }
    }

  label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`)
