import type { ValidationRule } from '@carfluent/common'
import type { VehiclesTabState } from './types'
import {
  correctRule,
  requiredField,
  vinField,
  vehicleYearField,
  makeField,
  modelField,
  trimField,
  mileageField
} from 'utils/validationPresets'

const tradeInDetailsFieldValidationRule = (validationRule: ValidationRule<unknown, string>) => (
  val: unknown, ctx?: VehiclesTabState
): string | null => {
  return ctx?.isTradeInSectionVisible === true ? validationRule(val, ctx) : correctRule()
}

export const DEPENDENT_VALIDATIONS = {
  tradeInDetails: [
    'tradeInDetails.vehicleYear', 'tradeInDetails.vehicleMake',
    'tradeInDetails.vehicleModel', 'tradeInDetails.vehicleTrim'
  ]
}

const validationRules = {
  actualMileage: mileageField(true),
  vehicle: requiredField,
  'tradeInDetails.vehicleVin': tradeInDetailsFieldValidationRule(vinField(true)),
  'tradeInDetails.vehicleYear': tradeInDetailsFieldValidationRule(vehicleYearField(true)),
  'tradeInDetails.vehicleMake': tradeInDetailsFieldValidationRule(makeField(true)),
  'tradeInDetails.vehicleModel': tradeInDetailsFieldValidationRule(modelField(true)),
  'tradeInDetails.vehicleTrim': tradeInDetailsFieldValidationRule(trimField(true)),
  'tradeInDetails.mileage': tradeInDetailsFieldValidationRule(mileageField(true)),
  'tradeInDetails.credit': tradeInDetailsFieldValidationRule(requiredField)
}

export default validationRules
