import type { FC, MouseEvent } from 'react'
import { ChangeEvent, useCallback, useState, useMemo, useRef, useEffect } from 'react'
import { cn } from '@carfluent/common'

import Popover from 'components/common/Popover'
import StatedIcon from 'components/common/StatedIcon'

import { CheckboxType } from 'types'
import filterIcon from 'assets/filter_icon.svg'
import filterActiveIcon from 'assets/filter_active_icon.svg'
import type { DictionaryItems } from 'api/types/utility.types'
import type { PlacementProps } from 'components/common/Popover/hook/types'
import { Coords } from 'components/common/Popover/hook/types'

import Content from './components/Content'
import ListOptionContent from './components/ListOptionContent'

import { mapToCheckboxes } from './utils'
import { createUseStyles } from './styles'

export interface FilterHeaderCellProps {
  title: string
  popUpTitle: string
  filters: number[]
  onChange: (val: number[]) => Promise<void>
  items: DictionaryItems<string> | null
  itemMapper?: (items: DictionaryItems<string>, values: number[]) => CheckboxType[]
  isSelectAllAllowed?: boolean
  isMultiSelect?: boolean
  isUniqItem?: boolean
  popoverClassName?: string
  popoverProps?: {
    minWidth?: number
    maxWidth?: number
    placement?: Partial<PlacementProps>
    transform?: Partial<Coords>
  }
  isDisablePortal?: boolean
  isDisabled?: boolean
}

// Was broken after integration overlay routing functionality
// TODO-OF create new popover with functionality which works with new overlay functionality
// TODO-OF refactor on new popover from lib and move to common component to use not only in table
// Created ticket for refactor https://dev.azure.com/carfluent/CarFluent/_workitems/edit/8442
export const FilterHeaderCell: FC<FilterHeaderCellProps> = ({
  title,
  popUpTitle,
  filters,
  onChange,
  items,
  itemMapper = mapToCheckboxes,
  isSelectAllAllowed = false,
  isUniqItem = false,
  popoverClassName = '',
  popoverProps = {},
  isDisablePortal = false,
  isDisabled = false,
  isMultiSelect = true
}) => {
  const initedFilters = useRef<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedFilters, setSelectedFilters] = useState<number[]>([])
  const [selectedItem, setSelectedItem] = useState<number | null>(null)
  const isFilterActive = useMemo(() => selectedFilters.length > 0, [selectedFilters])
  const isFilterPopoverOpen = useMemo(() => anchorEl != null, [anchorEl])
  const isFilterTitleShouldBeBold = isFilterActive || isFilterPopoverOpen
  const rootCls = useMemo(() => createUseStyles({ isFilterTitleShouldBeBold, isDisablePortal }), [isFilterTitleShouldBeBold, isDisablePortal])
  const filterItems = useMemo(() => itemMapper(items ?? [], selectedFilters), [selectedFilters, items])
  const isClosingRef = useRef(false)
  const isClosingRefTimeout = useRef<NodeJS.Timeout>()

  const isSelectedAll = useMemo(() => (
    (selectedFilters.length > 0) && (selectedFilters.length === items?.length)
  ), [items, selectedFilters])

  const isIndeterminate = useMemo(() => (
    (selectedFilters.length > 0) && filterItems.some(({ checked }) => !checked)
  ), [filterItems, selectedFilters])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onClick = useCallback((e: MouseEvent<HTMLElement>) => {
    if (isClosingRef.current || isDisabled) {
      return
    }

    if (anchorEl != null) {
      setAnchorEl(null)

      return
    }
    setAnchorEl(e.currentTarget)
  }, [anchorEl, isDisabled])

  const onClose = useCallback(() => {
    isClosingRef.current = true

    isClosingRefTimeout.current = setTimeout(() => {
      setAnchorEl(null)
      isClosingRef.current = false
    }, 0)
  }, [])

  const _onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    const id = Number(name)
    const nextValue = checked
      ? [...selectedFilters, id]
      : selectedFilters.filter(item => item !== id)

    setSelectedFilters(nextValue)
    void onChange(nextValue)
  }, [onChange, selectedFilters])

  const _onChangeAll = useCallback(
    () => {
      const isShouldSelectAll = selectedFilters.length === 0 || items?.length !== selectedFilters.length
      const itemsToCheck = isShouldSelectAll ? (items ?? []).map(({ id }) => id) : []

      setSelectedFilters(itemsToCheck)
      void onChange(itemsToCheck)
    },
    [selectedFilters, items]
  )

  const _onChangeForNoMultiItem = useCallback((id: number) => {
    setSelectedFilters([...selectedFilters, id])
    setSelectedItem(id)
    void onChange([id])
    onClose()
  }, [onChange, onClose, selectedFilters, selectedItem])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Setting default filters
   * */
  useEffect(() => {
    const isShouldInitFilters = !initedFilters.current &&
      selectedFilters.length !== filters.length

    if (isShouldInitFilters) {
      setSelectedFilters(filters)
      initedFilters.current = true
    }
  }, [filters, selectedFilters])

  useEffect(() => {
    return () => clearTimeout(isClosingRefTimeout.current)
  }, [])

  // ========================================== //

  return (
    <div className={rootCls}>
      <p className={cn('cf-title', isDisabled && 'cf-title-disabled')} onClick={onClick}>
        {title}
        <StatedIcon
          src={filterIcon}
          activeSrc={filterActiveIcon}
          isActive={isFilterTitleShouldBeBold || isDisabled}
        />
      </p>

      {isMultiSelect
        ? (
          <>
            {isDisablePortal
              ? anchorEl != null && (
                <Content
                  popUpTitle={popUpTitle}
                  isSelectAllAllowed={isSelectAllAllowed}
                  isIndeterminate={isIndeterminate}
                  isSelectedAll={isSelectedAll}
                  onChangeAll={_onChangeAll}
                  filterItems={filterItems}
                  onChange={_onChange}
                  isUniqItem={isUniqItem}
                  popoverClassName={popoverClassName}
                  maxWidth={popoverProps?.maxWidth}
                  isDisablePortal
                  onClose={onClose}
                />
              )
              : (
                <Popover
                  open={anchorEl != null}
                  onClose={onClose}
                  anchorEl={anchorEl}
                  minWidth={popoverProps.minWidth}
                  maxWidth={popoverProps.maxWidth}
                  placement={popoverProps.placement}
                  transform={popoverProps.transform}
                >
                  <Content
                    popUpTitle={popUpTitle}
                    isSelectAllAllowed={isSelectAllAllowed}
                    isIndeterminate={isIndeterminate}
                    isSelectedAll={isSelectedAll}
                    onChangeAll={_onChangeAll}
                    filterItems={filterItems}
                    onChange={_onChange}
                    isUniqItem={isUniqItem}
                    popoverClassName={popoverClassName}
                    maxWidth={popoverProps?.maxWidth}
                  />
                </Popover>
                )}
          </>
          )
        : (
          <Popover
            open={anchorEl != null}
            onClose={onClose}
            anchorEl={anchorEl}
            minWidth={popoverProps.minWidth}
            maxWidth={popoverProps.maxWidth}
            placement={popoverProps.placement}
            transform={popoverProps.transform}
          >
            <ListOptionContent
              filterItems={filterItems}
              selectedItem={selectedItem}
              onChange={_onChangeForNoMultiItem}
              popoverClassName={popoverClassName}
              maxWidth={popoverProps?.maxWidth}
            />
          </Popover>
          )}
    </div>
  )
}

export default (props: FilterHeaderCellProps) => () => <FilterHeaderCell {...props} />
