import { css } from '@emotion/css'

const COMMON_BORDER_STYLE = `
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`

export default css(`
  display: flex;
  flex-direction: column;

  &.grey-background {
    background: #F3F3F3;
    padding-bottom: 8px;

    :last-child { padding-bottom: 0; }
  }

  .schedule-log-task {
    display: flex;
    flex-direction: column;
    position:relative;
    background: #FFF;
    border-radius: 8px;

    .created-task {
      display: grid;
      grid-template-areas:
        'dueToDate assignedTo priority'
        'description description description'
        'buttons buttons buttons';
      grid-template-columns: 3fr 2fr 3fr;
      column-gap: 16px;
      row-gap: 12px;
      padding: 18px;

      .lead-tasks-result-block {
        .due-to {
          text-transform: uppercase;
          color: #101010;
        }

        .label {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: rgba(33, 33, 33, 0.5);
        }

        img {
          height: 25px;
          position: relative;
          right: 5px;
        }
      }

      .task-dueToDate { grid-area: dueToDate; }

      .task-assignedTo { grid-area: assignedTo; }

      .task-priority {
        grid-area: priority;

        .priority-value {
          display:flex;
          align-items: center;

          p {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color:  #101010;
            letter-spacing: 0.4px;
          }
        }
      }

      .task-description {
        grid-area: description;

        p {
          overflow-wrap: break-word;
          color: #101010;
        }
      }
    }

      .delete-lead-btn {
        display: flex;
        justify-content: center;
      }

      .lead-tasks-action-buttons {
        line-height: 22px;
        width: max-content;
        height: 26px;
        border-radius: 8px;
        padding: 12px 18px 10px 18px;
        color: #000;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 1.25px;
        }
      }
    }

    .horizontal-divider {
      height: 8px;
      background-color: #F3F3F3; 
      border: solid 1px #F3F3F3;
    }

    .task-border-color {
      border-left: 4px solid #FFCA28!important;
      ${COMMON_BORDER_STYLE}
    }

    .task-overdue-border-color {
      border-left: 4px solid #B00020!important;
      ${COMMON_BORDER_STYLE}
    }
  }
`)
