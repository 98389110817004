import { type Preset } from '@carfluent/common'

export const getDefaultPreset = ({
  presets,
  presetName
}: {
  presets: Preset[]
  presetName?: string
}): Preset | null =>
  presetName != null ? presets.find(p => p.name === presetName) ?? null : null
