import type { FC } from 'react'
import { formatCurrencyDecimal, formatDecimal, PRESET_LABELS } from '@carfluent/common'

import ReportLayout from 'components/reports/ReportLayout'
import Table from 'components/common/Table'

import type { ReportLeadBySourceItem } from 'api/types'

import StatusTypeFilter from './components/StatusTypeFilter'
import TotalColumn from './components/TotalColumn'
import GroupContent from './components/GroupContent'

import { useReportLeadBySource } from './hook'
import { PERCENTAGE_FORMAT } from './hook/constants'
import { columns } from './hook/columns'
import CLASS_NAME, { ROOT_CLASS_NAME } from './styles'

const EMPTY_FORMAT = { emptyValues: '-' }
const FORMAT_WITH_NEGATIVE = { ...EMPTY_FORMAT, allowNegative: true }
const EMPTY_ROWS: ReportLeadBySourceItem[] = []
const EXCLUDED_PRESETS = [
  PRESET_LABELS.ALL_TILL_TODAY,
  PRESET_LABELS.PREVIOUS_QUARTER,
  PRESET_LABELS.YEAR_TO_DATE,
  PRESET_LABELS.THIS_QUARTER
]

const ReportLeadBySource: FC = () => {
  const {
    onExportExcel,
    onChangeDatesFilter,
    sources,
    leadSources,
    setLeadSources,
    sorting,
    setSorting,
    rows,
    isLoading,
    reportInfo,
    groupBy
  } = useReportLeadBySource()

  return (
    <ReportLayout
      title='Leads by source'
      onExportExcel={onExportExcel}
      onChangeDatesFilter={onChangeDatesFilter}
      initialPresetName={PRESET_LABELS.THIS_MONTH}
      rootClassName={ROOT_CLASS_NAME}
      excludePresets={EXCLUDED_PRESETS}
      additionalFiltersNodes={
        <StatusTypeFilter
          selectedItems={leadSources}
          options={sources}
          onChange={setLeadSources}
        />
      }
    >
      <div className={CLASS_NAME}>
        <div className='total-panel'>
          <TotalColumn
            label='Total Leads:'
            value={reportInfo?.totalLeads}
          />

          <TotalColumn
            label='Overall conversion:'
            value={formatDecimal(
              reportInfo?.averageConversion != null ? reportInfo.averageConversion * 100 : null,
              PERCENTAGE_FORMAT
            )}
          />

          <TotalColumn
            label='Total front gross:'
            value={formatCurrencyDecimal(reportInfo?.totalFrontGross, FORMAT_WITH_NEGATIVE)}
          />

          <TotalColumn
            label='Total back gross:'
            value={formatCurrencyDecimal(reportInfo?.totalBackGross, FORMAT_WITH_NEGATIVE)}
          />

          <TotalColumn
            label='Total gross:'
            value={formatCurrencyDecimal(reportInfo?.totalGross, FORMAT_WITH_NEGATIVE)}
          />
        </div>

        <Table
          data={isLoading ? EMPTY_ROWS : rows}
          sorting={sorting}
          isLoading={isLoading}
          groupBy={groupBy}
          renderGroup={GroupContent}
          onSortingChange={setSorting}
          columns={columns}
          emptyTableMessage='No results found'
        />
      </div>
    </ReportLayout>
  )
}

export default ReportLeadBySource
