import { css } from '@emotion/css'

export default css(`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    
    .cf-timeline-button {
      display: flex;
      align-items: center;
      background: none;
      padding-right: 0;
      
      .cf-show-filters-button-label {
        text-decoration: underline;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        cursor: pointer;
        margin-left: 8px;
      }
    }
    
    .cf-timeline-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }
`)
