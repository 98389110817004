import type { FormValidation, ValidationRule } from '@carfluent/common'
import type { UpdateLeadFormData } from './types'

import {
  firstNameField,
  lastNameField,
  phoneField,
  emailField,
  dictionaryItemDropdownField,
  birthDate, Errors
} from 'utils/validationPresets'

const firstName = firstNameField(true)
const lastName = lastNameField(true)
const dictionaryItemField = dictionaryItemDropdownField(true)
const requiredPhoneField = phoneField(true)
const notRequiredPhoneField = phoneField()
const requiredEmailField = emailField(true)
const notRequiredEmailField = emailField()

export const DEPENDENT_VALIDATIONS = {
  email: 'phoneNumber',
  phoneNumber: 'email'
}

const isEmptyValue = (value?: string | null): boolean => {
  return value == null || value.trim() === ''
}

const phoneNumberRequired: ValidationRule<UpdateLeadFormData> = (
  value,
  values
) => {
  const isEmailEmpty = isEmptyValue(values?.email)
  const isPhoneEmpty = isEmptyValue(value)

  if (isEmailEmpty && isPhoneEmpty) {
    return Errors.EmailOrPhoneRequired
  }

  return isEmailEmpty
    ? requiredPhoneField(value)
    : notRequiredPhoneField(value)
}

const emailRequired: ValidationRule<UpdateLeadFormData> = (
  value,
  values
) => {
  const isEmailEmpty = isEmptyValue(value)
  const isPhoneEmpty = isEmptyValue(values?.phoneNumber)

  if (isEmailEmpty && isPhoneEmpty) {
    return Errors.EmailOrPhoneRequired
  }

  return isPhoneEmpty
    ? requiredEmailField(value)
    : notRequiredEmailField(value)
}

const validationRules: FormValidation<UpdateLeadFormData> = {
  firstName,
  lastName,
  phoneNumber: phoneNumberRequired,
  email: emailRequired,
  leadSource: dictionaryItemField,
  leadTemperature: dictionaryItemField,
  assignedUser: dictionaryItemField,
  birthDate: birthDate(false)
}

export default validationRules
