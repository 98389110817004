import type { FC } from 'react'
import { useRef, useMemo, useState } from 'react'
import { cnx, useClickOutside, noop } from '@carfluent/common'
import { CheckboxType } from 'types'

import createUseStyles from './styles'

interface ListOptionContentProps {
  filterItems: CheckboxType[]
  onChange: (id: number) => void
  popoverClassName: string
  maxWidth?: number
  selectedItem?: number | null
}

const ListOptionContent: FC<ListOptionContentProps> = ({
  filterItems,
  onChange: _onChange,
  popoverClassName,
  maxWidth,
  selectedItem
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null)
  const className = useMemo(() => createUseStyles({ maxWidth }), [])

  useClickOutside(contentRef, noop, { capture: true })

  const onChange = (id: number): void => {
    _onChange(id)
  }

  return (
    <div ref={contentRef} className={cnx(className, popoverClassName, 'content')}>
      {filterItems?.map(({ id, name }) => (
        <div
          key={id}
          className={cnx('item', selectedItem === id && 'active')}
          onClick={() => onChange(Number(id))}
        >
          {name}
        </div>
      ))}
    </div>
  )
}

export default ListOptionContent
