import { parsers, serializers } from '@carfluent/common'
import { isValid, parse } from 'date-fns'

import {
  DATE_US_12H_MIN_AMPM_FORMAT,
  TIME_12H_MIN_AMPM_FORMAT,
  TIME_12H_MIN_SPACE_AMPM_FORMAT,
  DATE_US_FORMAT
} from 'constants/constants'
import { formatDate } from 'utils/parse_date'
import { type DealerBusinessHours, type DictionaryItem } from 'api/types'
import { type TasksFormData } from 'types'

import {
  ScheduleButtonTaskType,
  ActivityButtonTaskType,
  TaskMode,
  TaskType,
  LogType
} from './types'

const { serializeDateTimeUTC } = serializers
const { parseDateStringUTC } = parsers

export const getInitialTaskModeState = (id: number | null, taskTypeId: number): TaskMode => {
  if (taskTypeId === TaskType['Web lead']) {
    return TaskMode.ViewRespond
  }

  if (taskTypeId === TaskType['Message received']) {
    return TaskMode.MessageReceived
  }

  if (taskTypeId === TaskType['Email received']) {
    return TaskMode.EmailReceived
  }

  return id !== null ? TaskMode.ViewTask : TaskMode.NewTask
}

export function mapPriorityNumberToObject (num: number | undefined): DictionaryItem {
  switch (num) {
    case 1:
      return { id: 1, name: 'Low' }
    case 2:
      return { id: 2, name: 'Medium' }
    case 3:
      return { id: 3, name: 'High' }
    default:
      return { id: 0, name: '' }
  }
}

export const calculateLeadPriority = (isTasksExist: boolean, leadTemperature: number): DictionaryItem => {
  if (isTasksExist && leadTemperature === 1) {
    return mapPriorityNumberToObject(2)
  } else if (leadTemperature === 1) {
    return mapPriorityNumberToObject(3)
  } else if (leadTemperature === 3 || leadTemperature === 2) {
    return mapPriorityNumberToObject(1)
  } else {
    return mapPriorityNumberToObject(leadTemperature)
  }
}

interface DefaultFieldsForNewTaskType {
  isTasksExist: boolean
  leadTemperature: number
}

export const getDefaultFieldsForNewTask = (props: DefaultFieldsForNewTaskType): Omit<TasksFormData, 'taskTypeId'> => {
  const {
    isTasksExist,
    leadTemperature
  } = props

  return {
    id: null,
    subject: null,
    dueDate: null,
    time: null,
    description: null,
    taskPriority: calculateLeadPriority(isTasksExist, leadTemperature),
    assignedUser: null,
    dealId: null
  }
}

export const taskTypeIdMap = {
  [ScheduleButtonTaskType.FollowUpCall]: TaskType['Follow up call'],
  [ScheduleButtonTaskType.Appointment]: TaskType.Appointment,
  [ScheduleButtonTaskType.Reminder]: TaskType.Reminder
}

export const activityTypeIdMap = {
  [ActivityButtonTaskType.FollowUpCall]: LogType.Call,
  [ActivityButtonTaskType.Visit]: LogType['Store visit'],
  [ActivityButtonTaskType.Other]: LogType.Other
}

export const combineDateTimeToISO = (dueDate: Date | null, time: string | null): string => {
  const date = (dueDate != null) ? dueDate : new Date()
  const _time = (time != null) ? time : formatDate(new Date(), TIME_12H_MIN_AMPM_FORMAT)

  let [hours, minutes] = _time?.split(':').map(t => parseInt(t))
  const [, period] = _time?.split(' ')

  if (period === 'PM' && hours !== 12) {
    hours += 12
  }
  if (period === 'AM' && hours === 12) {
    hours = 0
  }

  const localDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes)

  return serializeDateTimeUTC(localDate) ?? ''
}

export enum TaskDueDateState {
  Outdated = 'outdated',
  Today = 'today',
  Upcoming = 'upcoming',
}

export const getTaskDueDateState = (date: Date): TaskDueDateState => {
  const today = new Date()

  const compareToday = new Date(today.getFullYear(), today.getMonth(), today.getDate())
  const compareDueDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())

  if (compareDueDate.getTime() < compareToday.getTime()) {
    return TaskDueDateState.Outdated
  }

  if (compareDueDate.getTime() === compareToday.getTime()) {
    return TaskDueDateState.Today
  }

  return TaskDueDateState.Upcoming
}

const EMPTY_DATE_VALUE = '-'

export const getDate = (date: string, format: string = DATE_US_12H_MIN_AMPM_FORMAT): string => {
  const parsedDate = parseDateStringUTC(date)

  return parsedDate != null
    ? formatDate(parsedDate, format) ?? EMPTY_DATE_VALUE
    : EMPTY_DATE_VALUE
}

export const getLostCustomerMessage = (date: string): string => {
  const parsedDate = getDate(date)
  const dateValue = parsedDate !== EMPTY_DATE_VALUE ? ` ${parsedDate}` : ''

  return `Customer lost as of ${dateValue}. Do not call.`
}

export const getPausedMessage = (date: string, pausedByUserName: string | null): string => {
  const parsedDate = getDate(date, DATE_US_FORMAT)
  const dateValue = parsedDate !== EMPTY_DATE_VALUE ? ` ${parsedDate}` : ''

  return `All automated emails/messages are paused for this lead on ${dateValue} ${pausedByUserName != null ? `by ${pausedByUserName}` : ''}.`
}

const getDefaultTimeslots = (): [string, string] => ['09:00 AM', '07:00 PM']

export const extractEarliestAndLatestTime = (dealerBusinessHours: DealerBusinessHours | null): [string, string] => {
  if (dealerBusinessHours == null) {
    return getDefaultTimeslots()
  }

  let earliestTime: Date | null = null
  let latestTime: Date | null = null
  let isAnyDayChecked = false

  for (const key in dealerBusinessHours) {
    const timeValue = dealerBusinessHours[key as keyof DealerBusinessHours]

    if (timeValue != null) {
      isAnyDayChecked = true

      const parsedTime = parse(timeValue, TIME_12H_MIN_SPACE_AMPM_FORMAT, new Date())

      if (!isValid(parsedTime)) {
        continue
      }

      if (key.includes('Opening')) {
        if (earliestTime == null || parsedTime < earliestTime) {
          earliestTime = parsedTime
        }
      } else if (key.includes('Closing')) {
        if (latestTime == null || parsedTime > latestTime) {
          latestTime = parsedTime
        }
      }
    }
  }

  if (!isAnyDayChecked || earliestTime == null || latestTime == null) {
    return getDefaultTimeslots()
  }

  return [
    formatDate(earliestTime, TIME_12H_MIN_SPACE_AMPM_FORMAT),
    formatDate(latestTime, TIME_12H_MIN_SPACE_AMPM_FORMAT)
  ]
}
