import { type ColumnDef } from '@carfluent/common'

import { BankStatementTransactionState } from 'api/types'
import Badge from 'components/common/Badge'
import TextCellHOC from 'components/common/Table/cells/TextCell'
import { ValueCellHOC, type CellAdornmentProps } from 'components/common/Table/cells/ValueCell'
import { AmountCellHOC } from 'components/common/Table/cells/AmountCell'
import { DateCellHOC } from 'components/common/Table/cells/DateCell'

import { type BankStatementRowData } from './types'
import UndoActionCell from '../components/cells/UndoAction'
import TransactionLink from '../components/cells/TransactionLink'

export interface ColumnsConfig {
  transactionStateId: number
  onUndoExcludeClick: (id: number) => Promise<void>
  onOpenTransaction: (
    transactionId: number,
    typeId: number,
    costTypeId: number | null
  ) => void
}

const getColumnDefinitions = ({
  transactionStateId,
  onUndoExcludeClick,
  onOpenTransaction
}: ColumnsConfig): Array<ColumnDef<BankStatementRowData>> => {
  return [
    {
      accessorKey: 'date',
      cell: DateCellHOC({ className: 'date-cell', format: 'MM/dd/yyyy' }),
      header: TextCellHOC({ text: 'Date' }),
      maxSize: 120,
      size: 120
    },
    {
      accessorKey: 'description',
      cell: ValueCellHOC({
        className: 'cell-description',
        emptyValue: '',
        renderEndAdornment: ({ row }: CellAdornmentProps<BankStatementRowData>) => {
          const matchesNum = row.original.matches.length

          return (
            (matchesNum > 0) ? <Badge message={`${matchesNum} match${matchesNum > 1 ? 'es' : ''} found`} /> : null
          )
        }
      }),
      header: TextCellHOC({ text: 'Description' }),
      minSize: 460,
      sortable: false
    },
    {
      accessorKey: 'info',
      cell: ({ row }) => {
        return transactionStateId === BankStatementTransactionState.Categorized
          ? <TransactionLink
              onClick={() =>
                onOpenTransaction(row.original.clearedTransactionId, row.original.clearedTransactionTypeId, row.original.clearedCostTypeId)}
              title={row.original.info}
            />
          : (<div> </div>)
      },
      header: TextCellHOC({ text: 'Info' }),
      minSize: 280,
      sortable: false
    },
    {
      id: 'deposit',
      accessorFn: (row) => row.change > 0 ? row.change : null,
      cell: AmountCellHOC({ isAlignedRight: true }),
      header: TextCellHOC({ text: 'Deposit', isAlignedRight: true }),
      size: 200,
      sortable: false
    },
    {
      id: 'withdrawal',
      accessorFn: (row) => row.change < 0 ? Math.abs(row.change) : null,
      cell: AmountCellHOC({ isAlignedRight: true }),
      header: TextCellHOC({ text: 'Withdrawal', isAlignedRight: true }),
      size: 200,
      sortable: false
    },
    {
      id: 'action',
      accessorFn: () => null,
      cell: ({ row }) => transactionStateId === BankStatementTransactionState.ForReview
        // ? <ReviewActionCell />
        ? (<div> </div>)
        : <UndoActionCell
            key={row.original.id}
            disabled={row.original.isLocked || row.original.isReconciled}
            executeUndo={async () => await onUndoExcludeClick(row.original.id)}
            isReconciled={row.original.isReconciled}
          />,
      header: TextCellHOC({ text: '' }),
      size: 140,
      sortable: false
    }
  ]
}

export default getColumnDefinitions
