import { useCallback, useContext, useRef } from 'react'
import axios from 'axios'
import { SnackbarKey, SnackbarOrigin, useSnackbar } from 'notistack'

import { defaultContentRenderer } from 'components/snackbars/DefaultContent'

import AlertCTX from 'store/alert'
import type { KeyVal } from 'types'

type ShowAlertCallback = (
  data: any,
  options?: Alert.SnackbarOptions,
  key?: number
) => void

interface UseCustomSnackbarReturnType {
  showAlert: ShowAlertCallback
  shownAlerts: Set<number>
  showError: (message: string) => void
  showSuccess: (message: string) => void
  closeAlert: (key?: (SnackbarKey | undefined)) => void
}

const NON_DISPLAYABLE_ERRORS = new Set([401, 403])

const parseErrorsObject = (errors: KeyVal): string => {
  return Object.values(errors).reduce((acc: string, curr: any) => {
    if (typeof curr === 'string') {
      return ` ${acc}\n${curr}`
    } else if (Array.isArray(curr) && curr.length > 0 && typeof curr[0] === 'string') {
      return ` ${acc} ${curr[0]}`
    }

    return acc
  }, '')
}

const defaultOptions: Alert.SnackbarOptions & { variant: Alert.Type } = {
  variant: 'error',
  contentRenderer: null,
  closeAfter: 3200,
  onClose: () => {}
}

export const useCustomSnackbar = (anchorOrigin?: SnackbarOrigin): UseCustomSnackbarReturnType => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { removeAlert } = useContext(AlertCTX)

  const shownAlerts = useRef(new Set<number>())

  const showAlert: ShowAlertCallback = useCallback((data, options, key) => {
    // preventing to show empty or already shown alerts
    if (data == null || (key != null && shownAlerts.current.has(key))) {
      return
    }

    const { variant, contentRenderer, closeAfter, onClose, persist } = { ...defaultOptions, ...options }

    // if an alert has a key we add it to local cache to prevent duplicated display
    if (key != null) {
      shownAlerts.current.add(key)
    }

    // alert might be caused by changes in alertCTX state. So we always need to remove it from there
    const onCloseHandler = (): void => {
      if (key != null && shownAlerts.current.has(key)) {
        removeAlert(key)
        shownAlerts.current.delete(key)
      }

      onClose?.()
    }

    let message = ''
    if (typeof data === 'string') {
      message = data
    } else if (axios.isCancel(data)) {
      return
    } else if (
      // we show axios errors but not the cancelled ones and no those with status as in NON_DISPLAYABLE_ERRORS
      axios.isAxiosError(data) &&
      (data.response?.status != null && !NON_DISPLAYABLE_ERRORS.has(data.response?.status))
    ) {
      if (data?.response?.data?.errors != null) {
        message = parseErrorsObject(data?.response?.data?.errors)
      }

      // sometimes errors is just an empty object, so we might want to check all probable places of grabing an error string
      if (message === '') {
        try {
          message = JSON.parse(data.response?.data?.message ?? null)?.message ??
            data.response?.data?.message ??
            data.response?.statusText ??
            data.message
        } catch (err) {
          message = data.response?.data?.message ?? ''
        }
      }
    }

    if (message === '') {
      return
    }

    // passing imperative onCloseHandler in case snack is persistent so that user could close it manually
    const imperativeCloseHandler = (persist ?? false)
      ? () => closeSnackbar(key)
      : undefined

    enqueueSnackbar(message, {
      key,
      variant,
      autoHideDuration: closeAfter,
      onClose: onCloseHandler,
      persist,
      preventDuplicate: true,
      content: contentRenderer ?? defaultContentRenderer(variant, imperativeCloseHandler),
      anchorOrigin: anchorOrigin ?? { vertical: 'bottom', horizontal: 'center' }
    })
  }, [
    removeAlert,
    enqueueSnackbar,
    closeSnackbar,
    anchorOrigin
  ])

  const showSuccess = useCallback((message: any): void => {
    showAlert(message, { variant: 'success' })
  }, [showAlert])

  const showError = useCallback((message: any): void => {
    showAlert(message, { variant: 'error' })
  }, [showAlert])

  return {
    shownAlerts: shownAlerts.current,
    showAlert,
    showError,
    showSuccess,
    closeAlert: closeSnackbar
  }
}

export default useCustomSnackbar
