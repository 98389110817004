import { serializers } from '@carfluent/common'
import { DealFiltersState } from 'api/types'
import type { DealsListFilters, DealsListFiltersForApi } from './types'

const { serializeDate } = serializers

const serializeFilters = (filters: DealsListFilters): DealsListFiltersForApi => {
  const { date, dealPaidStatusId, ...restFilters } = filters
  const isSold = restFilters.workflowSearchState === DealFiltersState.Sold

  const dealPaidStatusIdValue = dealPaidStatusId?.[0] === 0 ? null : dealPaidStatusId?.[0]

  return {
    ...restFilters,
    dealPaidStatusId: dealPaidStatusIdValue ?? null,
    [isSold ? 'saleDate' : 'date']: {
      from: serializeDate(date?.from) ?? null,
      to: serializeDate(date?.to) ?? null
    }
  }
}

export default serializeFilters
