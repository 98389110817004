import { css } from '@emotion/css'

export interface CommentTextStyle {
  containerMaxHeight: string
  textMaxHeight: string
  textMaxLines: number
}

const INTERNAL_ROW_GAP = 4

export const TEXT_FONT_SIZE = 14
export const HEADER_HEIGHT = 16
export const MAX_CELL_HEIGHT = 340
export const PADDING_VERTICAL_HEIGHT = 16 // it is derived from table styles where this is used
export const MAX_TOTAL_HEIGHT = 340 - PADDING_VERTICAL_HEIGHT * 2

export const TEXT_LINE_HEIGHT = 20
export const ROW_GAP = 8
export const HEADER_WITH_PADDING_HEIGHT = HEADER_HEIGHT + INTERNAL_ROW_GAP

const MAX_HEIGHT_FOR_TEXT = MAX_TOTAL_HEIGHT - HEADER_WITH_PADDING_HEIGHT

export const getMaxTextLines = (height: number): number => Math.floor(height / TEXT_LINE_HEIGHT)
export const getMaxTextHeight = (height: number): number => height - height % TEXT_LINE_HEIGHT

export default css(`
  display: grid;
  grid-template-rows: auto;
  row-gap: ${ROW_GAP}px;

  .cf-recon-car-comment {
    display: grid;
    grid-row-gap: ${INTERNAL_ROW_GAP}px;
    grid-template-rows: 16px min-content;
    height: min-content;
    grid-template-areas:
      "name date"
      "text text";
  
    .cf-recon-car-comment-name {
      grid-area: name;
      font-weight: 700;
      font-size: 12px;
      line-height: ${HEADER_HEIGHT}px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .cf-recon-car-comment-date {
      grid-area: date;
      justify-self: end;
      color: #212121;
      font-size: 12px;
      line-height: ${HEADER_HEIGHT}px;
      opacity: 0.5;
    }

    .cf-recon-car-comment-text {
      grid-area: text;
      border-left: 2px solid #ddd;
      padding: 2px 8px;
      overflow: hidden;
      position: relative;

      p {
        color: #212121;
        opacity: 0.8;
        font-size: ${TEXT_FONT_SIZE}px;
        line-height: ${TEXT_LINE_HEIGHT}px;
        position: relative;
        margin: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;

        // default value -> might be overriden in js by style attribute as it is dynamic.
        max-height: ${getMaxTextHeight(MAX_HEIGHT_FOR_TEXT)}px;
        height: min-content;
        -webkit-line-clamp: ${getMaxTextLines(MAX_HEIGHT_FOR_TEXT)};
      }
    }
  }
`)
