import type { DeepRequired } from '@carfluent/common'
import { emptyAddress } from 'utils/address'
import type { CustomerFormData } from './types'

export enum Messages {
  SuccessCreate = 'Customer was created successfully.',
  SuccessUpdate = 'Customer was updated successfully.',
  SuccessDelete = 'Customer was deleted successfully.',
  ErrorLoad = 'Failed to load Customer data.'
}

export const getWindowTitle = (isEdit: boolean): string => isEdit ? 'Customer' : 'New customer'

const DEFAULT_FORM_DATA: DeepRequired<CustomerFormData> = {
  id: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  email: null,
  driverLicense: null,
  stateOfIssue: null,
  ssn: null,
  address: { ...emptyAddress },
  mailingAddress: { ...emptyAddress },
  isDifferentMailingAddress: false,
  customerTypeId: 1,
  ein: null,
  businessName: null
}

export const FIELD_TRANSFORMS = {
  email: (value: string) => value.trim()
}

export const getDefaultFormData = (): DeepRequired<CustomerFormData> => ({ ...DEFAULT_FORM_DATA })
