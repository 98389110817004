import { type FC } from 'react'
import email_sent from 'assets/email_sent.svg'
import CLASS_NAME from './styles'

const EmailSent: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <img src={email_sent} alt='email_sent' className='image-wrapper' />
      <h6 className='title'>
        Email sent
      </h6>
      <p className='text'>
        Check your email and open the link we sent to continue
      </p>
    </div>
  )
}

export default EmailSent
