import { css } from '@emotion/css'

export default css(`
  width: 100%;
  padding-left: 16px;
  min-width: 105px;
  max-width: 120px;

  :first-child {
    padding-left: 0;
    min-width: 108px;
    max-width: 128px;
  }

  .cf-disabled-text {
    color: rgba(33, 33, 33, 0.4);
  }

  &.cf-edit-mode-view {
    padding-left: 0;
    min-width: 105px;
    max-width: 120px;

    :first-child {
      min-width: 212px;
      max-width: 276px;
    }
  }

  .cf-input-container {
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    height: 48px;

    :focus, :hover {
      border: 1px solid #C99B86;
    }

    &.disabled {
      border-color: transparent;
      background: transparent;
      
      input {
        background: transparent;
        color: #101010;
      }
    }

    &.with-error {
      border-color: var(--cf-input-error-color);
    }

    input {
      background-color: #FFFFFF;
      height: 14px;

      :-webkit-autofill,
      :-webkit-autofill:hover, 
      :-webkit-autofill:focus, 
      :-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #FFFFFF inset !important;
      }
    }
  }
`)
