import { type FC } from 'react'

import OverwritableInput from 'components/deals/OverwritableInput'

import PercentOrAmountForm from './components/PercentOrAmountForm'
import { type PercentOrAmountInputProps } from './types'

export const PercentOrAmountInput: FC<PercentOrAmountInputProps> = ({
  formatFieldHelper,
  label,
  labelAmount = label,
  labelPercent = label,
  valueAmount,
  valuePercent,
  ...formProps
}) => {
  return (
    <OverwritableInput
      {...formProps}
      editButtonEl='Edit'
      formatFieldHelper={formatFieldHelper}
      getValue={() => valueAmount}
      id={`percent-or-amount-${formProps.fieldPathAmount}`}
      label={label}
      labelAmount={labelAmount}
      labelPercent={labelPercent}
      renderForm={PercentOrAmountForm}
      values={{
        amount: valueAmount,
        percent: valuePercent
      }}
    />
  )
}

export default PercentOrAmountInput
