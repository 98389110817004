import { css } from '@emotion/css'

export default css(`
  display: flex;
  min-width: 200px;
  gap: 4px;
  
  span {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
  
  &.text-bold {
    span {
      font-weight: 500;
    }
  }
  
  .title {
    color: #101010;
  }
  
  .value {
    color: rgba(33, 33, 33, 0.50);
  }
`)
