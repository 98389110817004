import { css } from '@emotion/css'

export default css(`
  padding: 8px;
  color: #458FFF;
  font-size: 14px;
  font-weight: 400;
  max-width: 460px;
  overflow: hidden;
  border-radius: 8px;
  font-family: Roboto;
  background: #F1F7FF;
  white-space: nowrap;
  text-overflow: ellipsis;
`)
