import { useCallback, useEffect, useMemo, useState } from 'react'
import { type Result, type UseFormReturn, getEnhancedResultHandler, isOk, useForm, useRefUpdater } from '@carfluent/common'

import CoverageApiProvider from 'api/coverage.api'
import { FIPackageFormProps } from 'pages/settings/FISettings/components/FIPackageSetup/hook/types'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import { type DealerProductCoverageMarkupDto, type FISetupMarkupsModel, type DealerDefaultCoverageDto } from 'api/types/responses'
import { CoverageDefault, type CoverageProducts } from 'types/coverage'
import { mergeProductPackages } from 'utils/coverage/mergeProductPackages'

import { FIELD_TRANSFORMS, Notifications, getDefaultFormData } from './constants'
import { DEPENDENT_VALIDATIONS, validationRules } from './validation'
import type { ErrTouchShortcuts, UseFIPackageSetupProps, UseFIPackageSetupReturn } from './types'

import { parseCoverageSections } from './parser'
import serializeFormData, { serializeFIProductsMarkups, serializeProductsName } from './serializer'

type SetValuesForm = UseFormReturn<FIPackageFormProps, ErrTouchShortcuts>['setValues']
type ValidateForm = UseFormReturn<FIPackageFormProps, ErrTouchShortcuts>['validateForm']

const DEFAULT_FORM_DATA = getDefaultFormData()

const useFIPackageSetup = ({
  isOpen,
  packages,
  onClose: _onClose,
  defaultCoverages,
  markups: _markups,
  onSubmit
}: UseFIPackageSetupProps): UseFIPackageSetupReturn => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [penPackages, setPenPackages] = useState<CoverageProducts>({})
  const [markups, setMarkups] = useState<FISetupMarkupsModel | null>(_markups)

  const { showAlert } = useCustomSnackbar()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onApplyMarkupChanges = useCallback((markup: DealerProductCoverageMarkupDto) => {
    setMarkups((prevMarkups) => ({
      ...prevMarkups,
      [markup.productTypeId]: markup
    }))
  }, [])

  const onActionResult = useMemo(() => {
    const action = (res: Result<FIPackageFormProps>): void => {
      if (isOk(res)) {
        onClose()
      }
      setIsSubmitting(false)
    }
    return getEnhancedResultHandler<FIPackageFormProps, ErrTouchShortcuts>(action, showAlert, undefined, Notifications.SaveSuccess)
  }, [showAlert, _onClose])

  const submitAction = useCallback(async (values: FIPackageFormProps): Promise<DealerDefaultCoverageDto[]> => {
    setIsSubmitting(true)
    const dataToSave = serializeFormData(values.coverageSections)
    const productNames = serializeProductsName(values)
    const markupsToSave = serializeFIProductsMarkups(markups as FISetupMarkupsModel)

    const [{ items }, updatedSettings] = await Promise.all([
      CoverageApiProvider.updateDefaultCoverage({ coverageDefaults: dataToSave }),
      CoverageApiProvider.updateFIProductsMarkups(markupsToSave)
    ])
    await onSubmit({
      defaultCoverages: items,
      productNames,
      markups: updatedSettings.markups
    })

    return items
  }, [onSubmit, markups])

  const form = useForm<FIPackageFormProps, ErrTouchShortcuts>({
    submitAction,
    onActionResult,
    validationRules,
    transforms: FIELD_TRANSFORMS,
    baseValues: DEFAULT_FORM_DATA,
    dependentValidations: DEPENDENT_VALIDATIONS
  })

  const { validateForm, resetForm, setFieldValue, setValues, onChange, setFieldTouched } = form

  const setValuesRef = useRefUpdater<SetValuesForm>(setValues)
  const validateFormRef = useRefUpdater<ValidateForm>(validateForm)

  const onInitFetch = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    try {
      const DEFAULT_COVERAGES = getDefaultFormData().coverageSections
      const [{ items }, { items: packageTypes }] = await Promise.all([
        CoverageApiProvider.getPenCoverageProducts(),
        CoverageApiProvider.getProductTypes()
      ])

      const penPackages = mergeProductPackages(packageTypes, items)
      const coverageSections = parseCoverageSections({
        penPackages,
        defaultCoverages,
        coverageSections: DEFAULT_COVERAGES
      })

      setPenPackages(penPackages)

      setValuesRef.current?.({
        coverageSections,
        firstPackageName: packages[0]?.packageName,
        secondPackageName: packages[1]?.packageName,
        thirdPackageName: packages[2]?.packageName
      })
      validateFormRef.current?.()
    } catch (e) {
      showAlert(e)
    } finally {
      setIsLoading(false)
    }
  }, [defaultCoverages, packages])

  const onClose = useCallback(() => {
    _onClose()
    setTimeout(resetForm, 0)
  }, [_onClose, resetForm, _markups])

  const onCancel = useCallback(() => {
    onClose()
    /**
     * OP-NOTE: added timeout to avoid UI refresh before modal is closed
     */
    setTimeout(() => {
      setMarkups(_markups)
    }, 300)
  }, [onClose, _markups])

  const onChangeManualVendor = useCallback((field: string, value: CoverageDefault) => {
    form.onChange(field, value)
    if (value.providerId === null) {
      const keys = Object.keys(value)
      keys.forEach(fieldId => {
        setFieldTouched(`${field}.${fieldId}`, false)
      })
    }
  }, [onChange, setFieldTouched])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  /**
   * Form initialization.
   */
  useEffect(() => {
    if (isOpen) {
      void onInitFetch()
    }
  }, [isOpen, onInitFetch])

  useEffect(() => {
    setMarkups(_markups)
  }, [_markups])

  // ========================================== //

  return {
    ...form,
    isOpen,
    markups,
    onCancel,
    penPackages,
    setFieldValue,
    onChangeManualVendor,
    onApplyMarkupChanges,
    isLoading: isLoading || isSubmitting
  }
}

export default useFIPackageSetup
