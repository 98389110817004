import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import BlockSkeleton from 'components/common/Skeletons/BlockSkeleton'
import { type TextWithNumberProps } from './types'
import CLASS_NAME from './styles'

const TextWithItemsNumber: FC<TextWithNumberProps> = ({
  className,
  isBold = false,
  isLoading = false,
  title,
  value
}) => {
  return (
    <BlockSkeleton
      className={cnx(CLASS_NAME, isBold && 'text-bold', className)}
      isLoading={isLoading}
    >
      <span className='title'>{title}</span>
      <span className='value'>({value ?? '-'})</span>
    </BlockSkeleton>
  )
}

export default TextWithItemsNumber
