import { css } from '@emotion/css'

// AZ-TODO: create a common ControlsList component
export const CONTROL_CLASS_NAME = css(`
  .cf-dropdown-listbox {
    min-width: 320px;
  }
  
  .cf-option-disabled {
    .cf-dropdown-option {
      opacity: 1;
    }
    
    .cf-option-name {
      color: rgba(33, 33, 33, 0.40);
    }
    
    .status {
      color: rgba(33, 33, 33, 0.40);
    }
    
    .name {
      color: rgba(33, 33, 33, 0.50);
    }
  }
`)
