import { type FormValidation } from '@carfluent/common'

import { type NewDealFormData } from 'types'
import { MAX_CAR_PRICE_VALUE } from 'constants/validation'
import {
  vehicleDropdownFull,
  dictionaryItemDropdownField,
  emailField,
  birthDate,
  phoneField,
  requiredField,
  priceField,
  vinField,
  businessNameByCtx,
  businessEinByCtxOptional,
  zipCodeField,
  customerDropdownField,
  sourceField,
  workflowTypeDependantFirstname,
  workflowTypeDependantLastname
} from 'utils/validationPresets'

import { type ErrTypeMap } from './types'

export interface ValidationConfig {
  isAddNewCustomer: boolean
}

export const DEPENDENT_VALIDATIONS = {
  'addressData.address': ['addressData.zipCode'],
  workflowType: ['customerFirstName', 'customerLastName', 'businessDetails.businessName', 'businessDetails.ein']
}

export const validationSchema: FormValidation<NewDealFormData, never, ErrTypeMap> = {
  customerFirstName: workflowTypeDependantFirstname,
  customerLastName: workflowTypeDependantLastname,
  customerPhoneNumber: phoneField(true),
  customerEmail: emailField(),
  customerBirthDate: birthDate(),
  salesperson: dictionaryItemDropdownField(true),
  'addressData.zipCode': zipCodeField(true),
  paymentType: dictionaryItemDropdownField(true),
  vehicle: vehicleDropdownFull(),
  'vehicle.mileage': requiredField,
  'vehicle.salePrice': priceField(MAX_CAR_PRICE_VALUE),
  'vehicle.vin': vinField(true),
  workflowType: requiredField,
  'businessDetails.businessName': businessNameByCtx,
  'businessDetails.ein': businessEinByCtxOptional,
  customer: customerDropdownField(),
  source: sourceField()
}

export const createValidationRules = ({
  isAddNewCustomer
}: ValidationConfig): FormValidation<NewDealFormData, never, ErrTypeMap> => ({
  ...validationSchema,
  customer: isAddNewCustomer
    ? (val) => customerDropdownField()(val, { isAddNewCustomer })
    : customerDropdownField()
})
