import { TransactionTypeId } from 'api/types/accounting.types'
import type { CostsPanelProps, UseCostPanelReturn } from '.'

type AllCostsDialogProps = Omit<
CostsPanelProps['costsPanelProps'],
'items' |
'costsBreakdown' |
'onRowClick' |
'onOpenModal' |
'onCloseModalJournalEntry' |
'onOpenModalJournalEntry' |
'selectedJournalEntry'>

type EditJournalEntryDialogProps = Pick<CostsPanelProps['costsPanelProps'],
'onOpenModalJournalEntry' |
'onCloseModalJournalEntry' |
'selectedJournalEntry'
>

type CommonProps = Pick<
UseCostPanelReturn,
'costsBreakdown' |
'costPanelProps' |
'costsPanelSummaryProps' |
'costsPanelDetailsProps' |
'handleOpenTransactionsDetails' |
'includePackAndCost'>

type CostPanelDetailsProps = Omit<
CostsPanelProps['costsPanelDetailsProps'],
'getDetails' |
'items' |
'onRowClick'>

export interface FormattedReturnProps {
  allCostsDialogProps: AllCostsDialogProps
  costsPanelDetailsProps: CostPanelDetailsProps
  commonProps: CommonProps
  isOpenNewTransactionDetails: boolean
  handleOpenTransactionsDetails: () => void
  onCloseModal: () => void
  onLoadTransactionList: () => Promise<void>
  editJournalEntryDialog: EditJournalEntryDialogProps
}

const generalTransactionTypeIds = [
  TransactionTypeId.JournalEntry,
  TransactionTypeId.Check,
  TransactionTypeId.PayBill,
  TransactionTypeId.Receivable,
  TransactionTypeId.Receive,
  TransactionTypeId.Payable
]

export const formattedReturnParams = ({
  commonProps,
  costsPanelDetailsProps,
  onLoadTransactionList,
  isOpenNewTransactionDetails,
  onCloseModal,
  editJournalEntryDialog,
  allCostsDialogProps: {
    defaultControlData,
    ...allCostsDialogProps
  }
}: FormattedReturnProps): UseCostPanelReturn => {
  if (editJournalEntryDialog.selectedJournalEntry != null) {
    return {
      ...commonProps,
      formControllerProps: {
        costsProps: null,
        transactionTypeId: TransactionTypeId.JournalEntry,
        transactionProps: {
          transactionId: editJournalEntryDialog.selectedJournalEntry,
          isModalOpen: true,
          onCloseModal: editJournalEntryDialog.onCloseModalJournalEntry,
          onSubmit: onLoadTransactionList
        }
      }
    }
  }

  if (allCostsDialogProps.isModalOpen) {
    const { onCloseModal, ...otherProps } = allCostsDialogProps

    return {
      ...commonProps,
      formControllerProps: {
        transactionProps: null,
        transactionTypeId: TransactionTypeId.Cost,
        costsProps: {
          onCloseModal,
          onOpenOtherCostJournalEntry: editJournalEntryDialog.onOpenModalJournalEntry,
          ...otherProps
        }
      }
    }
  }

  if (isOpenNewTransactionDetails) {
    return {
      ...commonProps,
      formControllerProps: {
        costsProps: null,
        transactionTypeId: TransactionTypeId.JournalEntry,
        transactionProps: {
          transactionId: null,
          preloadedControlData: defaultControlData,
          onCloseModal,
          isModalOpen: isOpenNewTransactionDetails,
          onSubmit: onLoadTransactionList
        }
      }
    }
  }

  if (costsPanelDetailsProps.isModalOpen) {
    const {
      typeId: id,
      costTypeId,
      selectedCostDetails,
      isModalOpen,
      onCostDetailsModalClose,
      onViewNextTransaction
    } = costsPanelDetailsProps

    return {
      ...commonProps,
      formControllerProps: {
        costTypeId,
        transactionTypeId: id as number,
        ...(((id != null && generalTransactionTypeIds.includes(id)))
          ? {
              costsProps: null,
              transactionProps: {
                transactionId: selectedCostDetails,
                isModalOpen,
                onCloseModal: onCostDetailsModalClose,
                onSubmit: onLoadTransactionList,
                onViewNextTransaction
              }
            }
          : {
              transactionProps: null,
              costsProps: {
                onDeleteCost: allCostsDialogProps.onDeleteCost,
                selectedCost: selectedCostDetails,
                isModalOpen,
                onCloseModal: onCostDetailsModalClose,
                onSubmit: onLoadTransactionList,
                costTypes: allCostsDialogProps.costTypes
              }
            }
        )
      }
    }
  }

  return {
    ...commonProps,
    formControllerProps: {
      transactionTypeId: TransactionTypeId.JournalEntry,
      transactionProps: null,
      costsProps: null
    }
  }
}
